import { ConversationQuestion } from '../../../../../../../../../../../interfaces/conversations';
import { IPersonality } from '../../../../../../../../../../../interfaces/personalities';
import { useMemo } from 'react';
import { textWrapEllipse } from '../../../../../../../../../../../helpers/utils/strings';
import { useTranslations } from '../../../../../../../../../../../hooks/useTranslations';
import { SingleEntry } from '../../../../../types';
import { useQuestionTitle } from '../../../../../../../../../../../hooks/data/useQuestionTitle';
import { useTypedContext } from '../../../../../../../../../../../hooks/useTypedContext';
import { AwareCaseContentDataContext } from '../../../../AwareCaseContent/types';
import { DashboardDefaults } from '../../../../../../../../../../../components/Dashboard/components/Dashboard/components/DashboardContent/defaults';

export const useSummaryItemData = (
  questions: ConversationQuestion[],
  members: IPersonality[]
): SingleEntry[] => {
  const { awareSession } = useTypedContext(AwareCaseContentDataContext);

  const getQuestionTitle = useQuestionTitle();

  const { attachPrefix, wrapWithIntl } = useTranslations(
    'Cases.CaseDetails.AwareCase.Summary.SummaryItems'
  );

  const notSpecifiedTranslation = useTranslations(
    DashboardDefaults.NOT_SPECIFIED_TRANSLATION_PATH
  );

  const notSpecified = useMemo(
    () =>
      notSpecifiedTranslation.wrapWithIntl(
        notSpecifiedTranslation.attachPrefix(
          DashboardDefaults.NOT_SPECIFIED_TRANSLATION_PREFIX
        )
      ),
    [notSpecifiedTranslation]
  );

  const member = useMemo(() => {
    return members.find((p) => p.id === awareSession?.member_id);
  }, [awareSession?.member_id, members]);

  const memberName = useMemo(() => {
    return textWrapEllipse(
      `${member?.last_name ?? ''} ${member?.first_name ?? ''}`,
      20
    );
  }, [member?.first_name, member?.last_name]);

  const questionItems = useMemo((): SingleEntry[] => {
    return (awareSession?.questions ?? []).map((question) => {
      const existingQuestion = questions.find(
        (p) => p.resource_id === question.id
      );

      return {
        name: existingQuestion ? getQuestionTitle(existingQuestion) : '',
        value: question.value,
      };
    });
  }, [awareSession?.questions, getQuestionTitle, questions]);

  return useMemo(() => {
    const nonEmptyQuestionItems = questionItems.filter((p) => p.name !== '');

    return [
      {
        name: wrapWithIntl(attachPrefix('doctor')),
        value: memberName ?? notSpecified,
      },
      ...nonEmptyQuestionItems,
    ];
  }, [attachPrefix, memberName, notSpecified, questionItems, wrapWithIntl]);
};
