import React from 'react';
import { useApplicableInput } from '../../../../../../../../../../../../components/ApplicableInput/hooks/useApplicableInput';
import ApplicableInput from '../../../../../../../../../../../../components/ApplicableInput';
import ControlledApplicableInput from '../../../../../../../../../../../../components/ApplicableInput/components/ControlledApplicableInput';
import { OrganizationAccessProfile } from '../../../../../../../../../../../../interfaces/accessProfiles';
import { useHandleAccept } from './hooks/useHandleAccept';

type NameFieldProps = {
  profile: OrganizationAccessProfile;
};

const NameField = ({ profile }: NameFieldProps) => {
  const handleAccept = useHandleAccept(profile.id);
  const control = useApplicableInput(profile.name, handleAccept);

  return (
    <div className="d-flex align-items-center gap-2">
      <ApplicableInput control={control}>
        {(props) => <ControlledApplicableInput {...props} />}
      </ApplicableInput>
    </div>
  );
};

export default NameField;
