import React from 'react';
import { FormGroup } from 'reactstrap';
import EditableInput from 'components/EditableInput/EditableInput';
import Skeleton from 'react-loading-skeleton';

const QuestionsItemPlaceholder = () => {
  return (
    <div>
      <FormGroup key={1} className="d-flex align-items-center gap-1">
        <EditableInput
          type="checkbox"
          checked={false}
          name="1"
          debounceEnabled={false}
        />
        <label htmlFor="1">
          <Skeleton width={150} />
        </label>
      </FormGroup>
      <FormGroup key={2} className="d-flex align-items-center gap-1">
        <EditableInput
          type="checkbox"
          checked={false}
          name="2"
          debounceEnabled={false}
        />
        <label htmlFor="2">
          <Skeleton width={150} />
        </label>
      </FormGroup>
      <FormGroup key={3} className="d-flex align-items-center gap-1">
        <EditableInput
          type="checkbox"
          checked={false}
          name="3"
          debounceEnabled={false}
        />
        <label htmlFor="3">
          <Skeleton width={150} />
        </label>
      </FormGroup>
    </div>
  );
};

export default QuestionsItemPlaceholder;
