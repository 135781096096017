import { useCallback, useEffect, useState } from 'react';
import {
  compareAsc,
  differenceInCalendarDays,
  differenceInHours,
} from 'date-fns';
import { useDispatch } from 'react-redux';
import { DateRange } from 'react-day-picker';

//helpers
import useTimelineDetails from 'api/queries/timelines/useTimelineDetails';

//interfaces
import { TimelineTabsProps, TimeRange } from 'interfaces/timeline';
import { TimelineTabsValues } from 'interfaces/ui';
import { CaseStatus } from 'interfaces/cases';

//store
import {
  nextPeriod,
  previousPeriod,
  setCustomDateRange,
  setTimelineDetailsTimeRange,
} from '../actions';
import {
  useTimelineDetailsDateRangeObject,
  useTimelineDetailsSelectedTab,
  useTimelineDetailsTimePeriod,
  useTimelineDetailsTimeRange,
} from 'store/reducerHooks';

//components
import TimePeriodFilter from 'components/TimePeriodFilter';

const THREE_DAYS_IN_HOURS = 3 * 24;
const DAY_IN_HOURS = 24;

interface TimelineTabsFiltersProps extends TimelineTabsProps {
  fetchTimeDetails: boolean;
}

const TimelineTabsFilters = ({
  timelineId,
  fetchTimeDetails,
  caseData,
}: TimelineTabsFiltersProps) => {
  const dispatch = useDispatch();

  const [lastActiveMode, setLastActiveMode] = useState<boolean>(
    !!(caseData && caseData.status === CaseStatus.Closed)
  );

  const {
    data: timelineDetails,
    isLoading: isLoadingTimelineDetails,
    isSuccess: isSuccessTimelineDetails,
  } = useTimelineDetails({
    params: { timelineId },
    options: { enabled: fetchTimeDetails },
  });

  const timeRange = useTimelineDetailsTimeRange();
  const dateRangeObject = useTimelineDetailsDateRangeObject();
  const timePeriod = useTimelineDetailsTimePeriod();

  const selectedTab = useTimelineDetailsSelectedTab();

  const getRelativeToDate = useCallback(() => {
    let relativeToDate: Date | null = null;

    if (lastActiveMode) {
      const lastFeedback = caseData?.participant_feedback_date;
      const lastSync = caseData?.participant_last_synchronization_date;

      if (lastFeedback) {
        relativeToDate = new Date(lastFeedback);
      }

      if (
        lastSync &&
        relativeToDate &&
        compareAsc(new Date(lastSync), relativeToDate)
      ) {
        relativeToDate = new Date(lastSync);
      }
    }

    return relativeToDate;
  }, [lastActiveMode, caseData]);

  const onTimeRangeChange = (timeRange: string) => {
    let relativeToDate = getRelativeToDate();

    dispatch(
      setTimelineDetailsTimeRange(timeRange as TimeRange, relativeToDate)
    );
  };

  const onDateRangeChange = (dateRange: DateRange) => {
    if (dateRange && dateRange.from && dateRange.to)
      dispatch(
        setCustomDateRange({
          from: dateRange.from,
          to: dateRange.to,
        })
      );
  };

  const onNextPeriod = () => {
    dispatch(nextPeriod());
  };

  const onPreviousPeriod = () => {
    dispatch(previousPeriod());
  };

  const calculateForDayDetails = useCallback(() => {
    const relativeToDate = getRelativeToDate();

    if (caseData?.default_setup?.time_range) {
      dispatch(
        setTimelineDetailsTimeRange(
          caseData.default_setup.time_range,
          relativeToDate
        )
      );
    } else if (!isLoadingTimelineDetails && isSuccessTimelineDetails) {
      if (timelineDetails?.created_at) {
        const todayDate = new Date();

        const differenceBetweenDatesInHours: number = differenceInHours(
          todayDate,
          new Date(timelineDetails.created_at)
        );

        if (differenceBetweenDatesInHours > THREE_DAYS_IN_HOURS) {
          dispatch(
            setTimelineDetailsTimeRange(TimeRange.DAYS_7, relativeToDate)
          );
        } else if (differenceBetweenDatesInHours > DAY_IN_HOURS) {
          dispatch(
            setTimelineDetailsTimeRange(TimeRange.DAYS_3, relativeToDate)
          );
        } else {
          dispatch(
            setTimelineDetailsTimeRange(TimeRange.HOURS_24, relativeToDate)
          );
        }
      } else {
        dispatch(setTimelineDetailsTimeRange(TimeRange.DAYS_7, relativeToDate));
      }
    }
  }, [
    isLoadingTimelineDetails,
    timelineDetails,
    isSuccessTimelineDetails,
    dispatch,
    caseData,
    getRelativeToDate,
  ]);

  const calculateForTrends = useCallback(() => {
    if (!isLoadingTimelineDetails && isSuccessTimelineDetails) {
      const dateFrom = new Date(timelineDetails.date_from);
      const dateTo = timelineDetails.date_to
        ? new Date(timelineDetails.date_to)
        : new Date();

      const differenceInDaysOnObservation = differenceInCalendarDays(
        dateTo,
        dateFrom
      );

      if (differenceInDaysOnObservation > 7) {
        dispatch(setTimelineDetailsTimeRange(TimeRange.DAYS_30, dateTo));
      } else if (differenceInDaysOnObservation > 3) {
        dispatch(setTimelineDetailsTimeRange(TimeRange.DAYS_7, dateTo));
      } else if (differenceInDaysOnObservation > 1) {
        dispatch(setTimelineDetailsTimeRange(TimeRange.DAYS_3, dateTo));
      } else {
        dispatch(setTimelineDetailsTimeRange(TimeRange.HOURS_24, dateTo));
      }
    }
  }, [
    isLoadingTimelineDetails,
    isSuccessTimelineDetails,
    timelineDetails,
    dispatch,
  ]);

  useEffect(() => {
    switch (selectedTab) {
      case TimelineTabsValues.TRENDS_TIMELINE:
        calculateForTrends();
        break;
      case TimelineTabsValues.CASE_DETAILS:
      case TimelineTabsValues.DAY_BY_DAY:
      case TimelineTabsValues.OVERVIEW:
        calculateForDayDetails();
        break;
    }
  }, [selectedTab, calculateForDayDetails, calculateForTrends]);

  useEffect(() => {
    if (timeRange) {
      let relativeToDate = getRelativeToDate();
      dispatch(setTimelineDetailsTimeRange(timeRange, relativeToDate));
    }
  }, [timeRange, getRelativeToDate, dispatch]);

  const toggleLastActiveMode = () => {
    setLastActiveMode((prev) => !prev);
  };

  return (
    <TimePeriodFilter
      onDateRangeChange={onDateRangeChange}
      onNextPeriod={onNextPeriod}
      onPreviousPeriod={onPreviousPeriod}
      onTimeRangeChange={onTimeRangeChange}
      toggleLastActiveMode={toggleLastActiveMode}
      timeRange={timeRange}
      selectedRange={dateRangeObject}
      timePeriod={timePeriod}
    />
  );
};

export default TimelineTabsFilters;
