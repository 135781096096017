import { FunctionalityCode } from 'interfaces/functionalities';
import {
  ITokenData,
  LoginMethod,
  TokenTypes,
  UserRoles,
  UserRolesInToken,
} from 'interfaces/membership';
import Cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';
import v from 'validator';
import {
  IBaseOrganization,
  IOrganization,
} from '../../interfaces/organizations';

export const getAccessToken = () => {
  return Cookie.get('access_token');
};

type setUserDataParams = {
  accessToken: string;
  validUntil: string;
  tokenType?: TokenTypes;
  id?: string;
  email?: string;
  organizations?: IOrganization[];
  loginAs: LoginMethod;
};

export const setUserData = ({
  accessToken,
  validUntil,
  id,
  email,
  organizations,
  tokenType,
  loginAs,
}: setUserDataParams) => {
  if (accessToken) {
    Cookie.set('access_token', accessToken, {
      expires: new Date(validUntil),
    });

    Cookie.set('token_expiration_date', new Date(validUntil).toISOString());
  }

  if (tokenType) {
    Cookie.set('token_type', tokenType);
  }

  if (id && email) {
    localStorage.setItem('user', id);
    localStorage.setItem('userEmail', email);
    localStorage.setItem('loginAs', loginAs);
  }

  if (organizations) {
    const filteredOrganizations = organizations?.filter(
      (singleOrg) =>
        singleOrg.role.includes('grasp_manager') ||
        singleOrg.role.includes('grasp_admin') ||
        singleOrg.role.includes('organization_admin')
    );

    if (filteredOrganizations) {
      localStorage.setItem('initOrg', JSON.stringify(filteredOrganizations));
    }
  }
};

export const deleteUserData = () => {
  Cookie.remove('access_token');
  Cookie.remove('token_expiration_date');
  Cookie.remove('token_type');
  localStorage.removeItem('user');
  localStorage.removeItem('userEmail');
  localStorage.removeItem('loginAs');
  localStorage.removeItem('initOrg');
  localStorage.removeItem('roles');
  localStorage.removeItem('last_organization');
  localStorage.removeItem('organization_functionalities');
};

export const setOrganizationFunctionalities = (
  organizationFunctionalities: FunctionalityCode[]
) => {
  localStorage.setItem(
    'organization_functionalities',
    JSON.stringify(organizationFunctionalities)
  );
};

export const getOrganizationPersonality = () => {
  const token = getAccessToken();
  const tokenData = getTokenData(token);

  if (!tokenData) return undefined;
  return tokenData['Grasp.Identity.PersonalityId'] ?? undefined;
};

export const getLocalOrganizationFunctionalities = () => {
  const token = getAccessToken();
  const tokenData = getTokenData(token);

  if (!tokenData) {
    const localStorageFunctionalities = localStorage.getItem(
      'organization_functionalities'
    );

    if (
      localStorageFunctionalities === null ||
      localStorageFunctionalities.length === 0
    )
      return [];

    return JSON.parse(localStorageFunctionalities) as FunctionalityCode[];
  }

  return (tokenData['Grasp.Identity.Organization.Functionality'] ??
    []) as FunctionalityCode[];
};

export const isAuthenticated = () => {
  const accessToken = getAccessToken();

  if (!accessToken) {
    deleteUserData();
  }
  return !!accessToken && v.isJWT(accessToken || '');
};

export const getUserRoles = () => {
  const localStorageRoles = localStorage.getItem('roles');
  if (localStorageRoles) return JSON.parse(localStorageRoles) as string[];
  else return null;
};

export const checkUserRole = (roleNeeded: UserRoles | undefined) => {
  const roles = getUserRoles();

  if (roles && roles?.includes(UserRoles.ORGANIZATION_ADMIN)) return true;

  if (roles && roleNeeded) {
    return roles.includes(roleNeeded);
  } else {
    if (!roleNeeded) return true;

    const token = getAccessToken();
    const tokenData = getTokenData(token);
    if (!tokenData) return false;

    return tokenData[
      'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
    ].includes(roleNeeded);
  }
};

export const isOrganizationAdminForOrganizationProvided = (
  organizationId: string | undefined
) => {
  if (organizationId === undefined) return false;

  const organizations = getOrganizations();

  if (organizations === null) return false;

  const existingOrganization = organizations.find(
    (p) => p.id === organizationId
  );

  if (existingOrganization === undefined) return false;

  return existingOrganization.role.includes(
    UserRolesInToken.ORGANIZATION_ADMIN
  );
};

const setUserRoles = (roles: string[]) => {
  localStorage.setItem('roles', JSON.stringify(roles));
};

export const getOrganizations = () => {
  const organizationsFromStorage = localStorage.getItem('initOrg');

  if (organizationsFromStorage) {
    return JSON.parse(organizationsFromStorage) as IOrganization[];
  } else {
    return null;
  }
};

export const setOrganizations = (organizations: IBaseOrganization[]) => {
  localStorage.setItem('initOrg', JSON.stringify(organizations));
};

export const getUserId = () => localStorage.getItem('user') || '';

export const getUserEmail = () => localStorage.getItem('userEmail') || '';

export const getTokenData = (token: string | null) => {
  if (token) {
    const decodedToken = jwtDecode<ITokenData>(token);

    const isOrganizationToken =
      !!decodedToken['Grasp.Identity.CurrentOrganization'];

    const expiryDateOfTokenTimestamp = decodedToken.exp * 1000;

    setUserData({
      id: decodedToken[
        'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'
      ],
      accessToken: token,
      validUntil: new Date(expiryDateOfTokenTimestamp).toISOString(),
      email: decodedToken.unique_name,
      tokenType: isOrganizationToken
        ? TokenTypes.ORGANIZATION
        : TokenTypes.PERSONAL,
      loginAs: localStorage.getItem('loginAs')
        ? LoginMethod[localStorage.getItem('loginAs')!]
        : LoginMethod.AsPatient,
    });

    const role =
      decodedToken[
        'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
      ];

    setUserRoles(Array.isArray(role) ? role : [role]);

    return decodedToken;
  } else return null;
};

export const getLoggedAs = () => localStorage.getItem('loginAs');
