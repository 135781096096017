import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import CaseGroupDetailsAnswers from '../components/CaseGroupDetailsAnswers';
import CaseGroupDetailsCases from '../components/CaseGroupDetailsCases';
import CaseGroupDetailsSessions from '../components/CaseGroupDetailsSessions';
import CaseGroupDetailsConversationSchedules from '../components/CaseGroupDetailsConversationSchedules';
import { ITabContainerItem } from '../../../../../components/Tabs/types/Tabs';

enum CaseGroupDetailsTabsValues {
  Answers = 'Answers',
  Cases = 'Cases',
  ConversationSchedule = 'ConversationSchedule',
  Sessions = 'Sessions',
}

export const mappedCaseGroupDetailsTabValues = [
  CaseGroupDetailsTabsValues.Answers,
  CaseGroupDetailsTabsValues.Cases,
  CaseGroupDetailsTabsValues.ConversationSchedule,
  CaseGroupDetailsTabsValues.Sessions,
];

export const useCaseGroupDetailsTabs = () => {
  const [selectedTab, setSelectedTab] = useState<CaseGroupDetailsTabsValues>(
    CaseGroupDetailsTabsValues.Answers
  );

  const tabItems: ITabContainerItem[] = useMemo(
    () => [
      {
        header: (
          <FormattedMessage id="CaseGroups.CaseGroupDetails.answersTab" />
        ),
        component: <CaseGroupDetailsAnswers />,
        shouldBeRendered: selectedTab === CaseGroupDetailsTabsValues.Answers,
      },
      {
        header: <FormattedMessage id="CaseGroups.CaseGroupDetails.casesTab" />,
        component: <CaseGroupDetailsCases />,
        shouldBeRendered: selectedTab === CaseGroupDetailsTabsValues.Cases,
      },
      {
        header: (
          <FormattedMessage id="CaseGroups.CaseGroupDetails.conversationSchedulesTab" />
        ),
        component: <CaseGroupDetailsConversationSchedules />,
        shouldBeRendered:
          selectedTab === CaseGroupDetailsTabsValues.ConversationSchedule,
      },
      {
        header: (
          <FormattedMessage id="CaseGroups.CaseGroupDetails.sessionsTab" />
        ),
        component: <CaseGroupDetailsSessions />,
        shouldBeRendered: selectedTab === CaseGroupDetailsTabsValues.Sessions,
      },
    ],
    [selectedTab]
  );

  const onTabChange = (index: number) => {
    setSelectedTab(mappedCaseGroupDetailsTabValues[index]);
  };

  return {
    tabItems,
    onTabChange,
  };
};
