import { useCommonData } from '../../../../../../../hooks/useCommonData';
import { useCallback, useContext } from 'react';
import { FormikProps } from 'formik';
import {
  MaterialModalContext,
  MaterialModalFormValues,
} from '../../../../../types';

export const useHandleClose = (form: FormikProps<MaterialModalFormValues>) => {
  const { toggle } = useContext(MaterialModalContext);

  const { educationMaterials, educationMaterialCategoriesTree } =
    useCommonData();

  return useCallback(async () => {
    await educationMaterials.refetch();
    await educationMaterialCategoriesTree.refetch();
    form.resetForm();
    toggle();
  }, [educationMaterialCategoriesTree, educationMaterials, form, toggle]);
};
