import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { editCaseHistory } from 'api/cases';
import { IMutationProps } from '../../../interfaces/ui';
import { IPatchCaseHistory } from '../../../interfaces/caseHistory';

type useCaseHistoryEditParams = {
  organizationId: string;
  caseId: string;
};

const useCaseHistoryEdit = (
  params: useCaseHistoryEditParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification<IPatchCaseHistory, void>({
    asyncApiFunction: async (body: IPatchCaseHistory) => {
      await editCaseHistory(
        params.organizationId,
        params.caseId,
        body.id,
        body.document
      );
    },
    onSuccess: () => {
      if (options.successFb) options.successFb();
      displaySuccessNotification('CaseCard.History.entryUpdated');
    },
    onErrorArray: (errorArray) => {
      if (options.errorFb) options.errorFb();
      displayErrorArrayNotifications(
        errorArray,
        'CaseCard.History.entryUpdateFail'
      );
    },
    resetOnResult: true,
  });
};

export default useCaseHistoryEdit;
