import { getSharingRequestsForUser } from 'api/patients';
import QueryKeys from 'api/queries/queryKeys';
import { UserQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { useQuery } from 'react-query';

function useUserSharingRequests({
  params,
  options,
}: IQueryRequest<UserQueryParams>) {
  const { userId } = params;
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getSharingRequestsForUser(userId);
      return data.data;
    },
    queryKey: [userId, QueryKeys.getSharingRequests],
  });
}

export default useUserSharingRequests;
