import React, { useContext } from 'react';
import { HoursContext } from '../../index';

const ScheduleEmptyRow = () => {
  const hours = useContext(HoursContext);

  return (
    <tr className="Schedule__row Schedule__row--empty">
      <td className="ScheduleRow__label">
        <div className="ScheduleRow__template-name"></div>
      </td>
      <td className="ScheduleRow__day-range">
        <div className="ScheduleDayRange">
          <div className="ScheduleDayRange__lines">
            {hours.map((hour) => (
              <div key={hour} className="ScheduleDayRange__line"></div>
            ))}
          </div>
          <div className="ScheduleDayRange__items"></div>
        </div>
      </td>
      <td className="ScheduleRow__actions"></td>
    </tr>
  );
};

export default ScheduleEmptyRow;
