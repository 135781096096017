import { useCaseGroupDetailsCommonData } from '../../../hooks/useCaseGroupDetailsCommonData';
import useTreatmentPhases from '../../../../../../../api/queries/conversations/useTreatmentPhases';
import useRequiredParams from '../../../../../../../hooks/useRequiredParams';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import CaseGroupConversationSchedule from '../components/CaseGroupConversationSchedule';
import { useTreatmentPhasesNames } from './useTreatmentPhasesNames';
import { useTreatmentPhaseIcon } from './useTreatmentPhaseIcon';
import SVG from 'react-inlinesvg';
import { ITabContainerItem } from '../../../../../../../components/Tabs/types/Tabs';
import { useQueryRefetch } from '../../../../../../../hooks/queries/useQueryRefetch';

export const useCaseGroupDetailsConversationSchedulesTabs = (
  onTabChangeAction?: (val: string) => void
) => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const { caseGroup } = useCaseGroupDetailsCommonData();

  const getPhaseName = useTreatmentPhasesNames();
  const getPhaseIcon = useTreatmentPhaseIcon();

  const treatmentPhasesQuery = useTreatmentPhases({
    params: {
      organizationId,
      treatmentPlanId: caseGroup.data?.case_category_id ?? '',
    },
    options: {
      enabled: false,
      staleTime: Infinity,
    },
  });

  const refetchQuery = useQueryRefetch();

  useEffect(() => {
    if (caseGroup.data?.case_category_id)
      refetchQuery(treatmentPhasesQuery).then((_) => {});
  }, [caseGroup.data?.case_category_id, refetchQuery, treatmentPhasesQuery]);

  const [selectedTab, setSelectedTab] = useState<string>(
    () => treatmentPhasesQuery.data?.at(0)?.id ?? ''
  );

  const shouldBeRendered = useCallback(
    (tab: string) => {
      return selectedTab === tab;
    },
    [selectedTab]
  );

  const onTabChange = useCallback(
    (idx: number) => {
      const existingPhase = treatmentPhasesQuery.data?.at(idx);

      if (!existingPhase) return;
      setSelectedTab(existingPhase.id);

      if (onTabChangeAction) onTabChangeAction(existingPhase.id);
    },
    [onTabChangeAction, treatmentPhasesQuery.data]
  );

  const tabItems: ITabContainerItem[] = useMemo(() => {
    if (!treatmentPhasesQuery.data) return [];

    let items: ITabContainerItem[] = [];

    treatmentPhasesQuery.data.forEach((phase) => {
      const groupSchedule = caseGroup.data?.group_schedule.find(
        (p) => p.phase_id === phase.id
      );

      const phaseIcon = getPhaseIcon(groupSchedule);
      const casesLength = caseGroup.data?.cases.filter(
        (p) => p.sub_category_id === phase.id
      )?.length;

      items.push({
        header: (
          <div className="me-0 d-flex align-items-center justify-content-between">
            <span>{getPhaseName(phase.id)}</span>
            <div className="me-0 ms-3 d-flex align-items-center gap-1">
              {phaseIcon && <SVG className="me-0" src={phaseIcon} />}
              <span>{casesLength ? casesLength : ''}</span>
            </div>
          </div>
        ),
        shouldBeRendered: shouldBeRendered(phase.id),
        component: <CaseGroupConversationSchedule phase={phase} />,
      });
    });

    return items;
  }, [
    caseGroup.data?.cases,
    caseGroup.data?.group_schedule,
    getPhaseIcon,
    getPhaseName,
    shouldBeRendered,
    treatmentPhasesQuery.data,
  ]);

  return {
    tabItems: tabItems,
    onTabChange: onTabChange,
    selectedTab: selectedTab,
    setSelectedTab: setSelectedTab,
  };
};
