import { FormikProps } from 'formik';
import { useCallback } from 'react';
import {
  DashboardTemplateSeriesAggregate,
  DashboardTemplateSeriesDataSource,
} from '../../../../../../../../../../../../../../../interfaces/dashboards';
import { FormValues } from '../types';

export const useManageRow = () => {
  const addRow = useCallback(
    (props: FormikProps<FormValues>) => () => {
      if (props.values.series.length === 0) return;

      props.setValues((prev) => ({
        ...prev,
        series: [
          ...prev.series,
          {
            data_source: DashboardTemplateSeriesDataSource.SessionCount,
            aggregate: DashboardTemplateSeriesAggregate.Sum,
            split_by: prev.series[0].split_by,
            series_split_by: prev.series[0].series_split_by,
          },
        ],
      }));
    },
    []
  );

  const removeRow = useCallback(
    (props: FormikProps<FormValues>, index: number) => () => {
      props.setValues((prev) => ({
        ...prev,
        series: prev.series.filter((_, idx) => idx !== index),
      }));
    },
    []
  );

  return { addRow, removeRow };
};
