import { InputOption } from 'interfaces/ui';

export namespace CreateOrganizationUserModalHelpers {
  export const SortCulturesOptions = (
    options: InputOption[],
    currentCode: string
  ) => {
    options.sort((a, _) => a.text.localeCompare(currentCode));
    return options;
  };
}
