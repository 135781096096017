import { format } from 'date-fns';
import { IObservation } from 'interfaces/timeline';
import { FormattedMessage } from 'react-intl';
import { Bar, ComposedChart, Line, TooltipProps } from 'recharts';
import { COLOR_DEFINITIONS } from './index';

interface SqueezeStrengthTooltipProps extends TooltipProps<string, string> {
  timelineObservations: IObservation[];
}

const SqueezeStrengthTooltip = ({
  label,
  timelineObservations,
}: SqueezeStrengthTooltipProps) => {
  const elementToRender = timelineObservations.find(
    (obs) => obs.timestamp === label
  );

  const formattedAmplitudes = elementToRender?.amplitudes.map((value) => ({
    name: 'amplitude',
    value,
  }));

  return (
    <div className="MomentarySessionDetailsTooltip">
      {elementToRender && (
        <>
          <div>
            <div>
              <FormattedMessage id="MomentarySession.time" />:{' '}
              {format(new Date(elementToRender.timestamp), 'HH:mm:ss')}
            </div>
            <div>
              <FormattedMessage
                id="MomentarySession.squeezeTime"
                values={{ value: elementToRender.duration_in_seconds }}
              />
            </div>
            <div>
              <FormattedMessage id="MomentarySession.plainScaleLevel" />:{' '}
              {elementToRender.level}
            </div>
          </div>
        </>
      )}
      <ComposedChart width={200} height={100} data={formattedAmplitudes}>
        <Bar dataKey="value" opacity={0.3} fill={COLOR_DEFINITIONS.BLUE} />
        <Line
          dot={false}
          type="natural"
          dataKey="value"
          stroke={COLOR_DEFINITIONS.BLUE}
          strokeWidth={3}
        />
      </ComposedChart>
      <p className="text-center mt-2">
        <FormattedMessage id="MomentarySession.clickToEnlarge" />
      </p>
    </div>
  );
};

export default SqueezeStrengthTooltip;
