import { IConversationTemplatePlainTextItem } from './conversations';
import { Sentiments } from './timeline';

export interface IAssessmentTemplate {
  code: string;
  title: string;
  description: string;
  showProgressIndicator: boolean;
  showTitle: boolean;
  showOnDayByDay: boolean;
  showOnDayPlan: boolean;
  renderAsStepInForm: boolean;
  questions: AssessmentQuestion[];
}

export interface IUserAssessments extends IAssessmentTemplate {
  userId: string;
  year: number;
  month: number;
  day: number;
  sequence: number;
  hour: string;
  lastCompletedHour: string | null;
  completed: boolean;
  completionStatus: AssessmentCompletionStatus;
  isAvailableToCompletion: boolean;
  contextType: AssignmentContextType;
  contextObjectId: string;
  expirationDate?: string;
  isEditableByOrganizationMembers: boolean;
}

export type BaseSentiments = Omit<Sentiments, 'NotSpecified'>;

export type AssessmentFormIdentification = {
  selectedSequence: number | null;
  contextType: string | null;
  contextObjectId: string | null;
};

export type AssessmentFormRedirectionState = {
  selectedDate: Date;
  sequence: number;
};

export type AssessmentAnswerOption = {
  sequence: number;
  title: string;
  title_nbno: string | undefined;
  description: string;
  description_nbno: string | undefined;
  sentiment_score: number;
  is_selected: boolean;
  guid: string;
  sentiment: BaseSentiments;
};

export type AssessmentAnswer = {
  numberValue?: number;
  textValue?: string;
  dateValue?: string;
};

export enum AssessmentQuestionType {
  CLOSED_LIST = 'ClosedList',
  OPEN = 'Open',
  RANGE = 'Range',
  HEADER = 'Header',
  EMAIL = 'Email',
  TIME = 'Time',
}

export type AssessmentQuestion = {
  answers: AssessmentAnswerOption[];
  answer: AssessmentAnswer | null;
  guid: string;
  sequence: number;
  title: string;
  description: string;
  icon: string;
  type: AssessmentQuestionType;
  showSentimentScale: boolean;
  legendDescriptions?: AssessmentLegendDescriptions[];
  maxNumberValue: number;
  minNumberValue: number;
  maxDateValue: null | string;
  minDateValue: null | string;
  showIcon: boolean | null;
  showTitle: boolean | null;
  showDescription: boolean | null;
  answerMaxLength: number | null;
  answerPlaceHolder: string | null;
  required: boolean | null;
  color: string | null;
  visible: string | null;
};

export type AssessmentLegendDescriptions = {
  value: number;
  label: string;
  label_NbNo: string | undefined;
};

export type AssessmentSummaryQuestion = {
  current_trend_sentiment: BaseSentiments;
  current_trend_length: number;
  current_trend_answer: {
    sequence: number;
    sentiment_score: number;
    title: string;
    description: string;
    sentiment: BaseSentiments;
  } | null;
  average_question_value_for_7_days_back: number;
  average_question_value_for_14_days_back: number;
  sequence: number;
  title: string;
  description: string;
  icon: string;
  type: AssessmentQuestionType;
  color: string | null;
};

export type AssessmentSummary = {
  code: string;
  title: string;
  isEmptySummary: boolean;
  questions: AssessmentSummaryQuestion[];
};

export enum AssessmentCompletionStatus {
  OPEN = 'Open',
  COMPLETED = 'Completed',
  OVERDUE = 'Overdue',
  UPCOMING = 'Upcoming',
}

export enum AssessmentStatus {
  POSITIVE = 'positive',
  NEGATIVE = 'negative',
  NEUTRAL = 'neutral',
  NOT_DEFINED = 'not_defined',
}

export interface IUserAssessmentUpsertBody {
  code: string;
  contextObjectId: string | null;
  title: string;
  questions: AssessmentQuestion[];
}

export interface ISubmissionUserAssessmentUpsertBody {
  code: string;
  contextObjectId: string | null;
  title: string;
  questions: (AssessmentQuestion | IConversationTemplatePlainTextItem)[];
}

export interface IAssessmentFormAssignment {
  id: string;
  contextObjectId: string;
  contextType: AssignmentContextType;
  dateFrom: Date;
  dateTo: Date;
  userId: string;
  scheduleInfo: ScheduleInfo;
  formCode: string;
  isEditableByOrganizationMembers: boolean;
  activeHoursAfter?: number;
  activeHoursBefore?: number;
}
export interface IAssignmentUpsertBody {
  userId: string;
  formCode: string;
  scheduleInfo: ScheduleInfo;
  contextType: AssignmentContextType;
  contextObjectId: string;
  dateFrom: Date;
  dateTo: Date | null;
  isEditableByOrganizationMembers: boolean;
  activeHoursBefore: number;
  activeHoursAfter: number;
}

export enum AssignmentContextType {
  Cases = 'CASES',
  Submissions = 'SUBMISSIONS',
  Timelines = 'TIMELINES',
}

export type ScheduleInfo = {
  weekly: WeeklyScheduleInfo[] | null;
  daily: DailyScheduleInfo | null;
  custom: CustomScheduleInfo[] | null;
};

export type ScheduleInfoWithCustomOrdered = {
  weekly: WeeklyScheduleInfo[] | null;
  daily: DailyScheduleInfo | null;
  custom: OrderedCustomScheduleInfo[] | null;
};

export type WeeklyScheduleInfo = {
  day: string;
  hours: string[];
};

export type DailyScheduleInfo = {
  dayInterval: number;
  hours: string[];
};

export type CustomScheduleInfo = {
  date: Date;
  hours: string[];
};

export type OrderedCustomScheduleInfo = {
  id: number;
  date: Date;
  hours: string[];
};

export interface IUserAssessmentUpsertBody {
  code: string;
  contextObjectId: string | null;
  title: string;
  questions: AssessmentQuestion[];
}

export interface IAssessmentFormAssignment {
  id: string;
  contextObjectId: string;
  contextType: AssignmentContextType;
  dateFrom: Date;
  dateTo: Date;
  userId: string;
  scheduleInfo: ScheduleInfo;
  formCode: string;
}

export interface IAssessmentPeriodInfo {
  isAvailableToCompletion: boolean;
  isEditableByOrganizationMembers: boolean;
  userId: string;
  code: string;
  year: number;
  month: number;
  day: number;
  hour: string;
  sequence: number;
  fillDate: null | string;
  title: string;
  description: string;
  completionStatus: AssessmentCompletionStatus;
  contextType: string | null;
  contextObjectId: string | null;
  showProgressIndicator: boolean;
  showTitle: boolean;
  showOnDayPlan: boolean;
  showOnDayByDay: boolean;
}

export type GetAssessmentFormsTemplatesQueryParams = {
  allowedForCaseId: string | null;
  questionsIncludedInTrends: boolean | null;
};
