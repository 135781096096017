import { IBaseOrganization } from '../interfaces/organizations';
import { BasePersonality } from '../interfaces/personalities';
import { textWrapEllipse } from './utils/strings';

export namespace FormatHelpers {
  export const FormatPersonality = <T extends BasePersonality>(
    personality: T
  ) => {
    return `${personality.last_name} ${personality.first_name}`;
  };

  export const FormatOrganization = <T extends IBaseOrganization>(
    organization: T,
    wrapEllipsis?: number
  ) => {
    return textWrapEllipse(
      organization.name,
      wrapEllipsis ? wrapEllipsis : organization.name.length + 1
    );
  };
}
