import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import FilterInput from 'components/Filters/FilterInput';
import useOpen from 'hooks/useOpen';
import { Formik, FormikProps } from 'formik';
import { useAddTooltipForm } from './hooks/useAddTooltipForm';
import { useTranslations } from 'hooks/useTranslations';
import AddTooltipForm from './components/AddTooltipForm';

const AddTooltip = () => {
  const { attachPrefix } = useTranslations(
    'Organizations.OrganizationDetails.TrendVisualizations.TrendVisualizationModal.FormHeading.FormHeadingActions'
  );
  const { isOpen, toggle } = useOpen();

  const { initialValues, handleSubmit, validation } = useAddTooltipForm();

  return (
    <Formik
      validate={validation}
      validateOnMount={false}
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={handleSubmit}
    >
      {(props: FormikProps<any>) => {
        const handleAccept = async () => {
          await props.submitForm();
          props.resetForm();
          toggle();
        };

        return (
          <FilterInput
            isOpen={isOpen}
            toggle={toggle}
            targetId="add-trend-visualization-chart-filter"
            resetButtonHidden
            resetFilters={props.handleReset}
            filterBody={<AddTooltipForm form={props} />}
            closeButton
            titleTranslationId={attachPrefix('addChart')}
            popoverClassName="FilterInput__popover AddTrendVisualizationChartFilterPopover"
            displayComponent={
              <Button
                id="add-trend-visualization-chart-filter"
                color="primary"
                outline={true}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            }
            acceptButtonDisabled={!props.isValid}
            onAcceptClick={handleAccept}
          />
        );
      }}
    </Formik>
  );
};

export default AddTooltip;
