import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { ICase } from '../../../../../../interfaces/cases';
import useOpen from '../../../../../../hooks/useOpen';
import useCasesEdit from '../../../../../../api/mutations/cases/useCasesEdit';
import IndeterminateCheckbox from '../../../../../../components/IndeterminateCheckbox';
import CaseItem from './components/CaseGroupDetailsCaseItem';
import CaseItemsLoading from './components/CaseGroupDetailsCasesLoading';
import CaseHistoryModal from '../../../../../Cases/modals/CaseHistoryModal';
import MoveToTreatmentPhaseModal from './modals/MoveToTreatmentPhaseModal';
import MoveCaseModal from '../../../../modals/MoveCaseModal';
import ConfirmModal from '../../../../../../components/ConfirmModal';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faExchange,
  faUnlink,
} from '@fortawesome/pro-light-svg-icons';
import SVG from 'react-inlinesvg';
import CustomTable from '../../../../../../components/CustomTable';
import useRequiredParams from '../../../../../../hooks/useRequiredParams';
import { useCaseGroupDetailsCommonData } from '../../hooks/useCaseGroupDetailsCommonData';

import projectFlowIcon from 'assets/images/phase.svg';

const CaseGroupDetailsCases = () => {
  const intl = useIntl();

  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const [selectedCases, setSelectedCases] = useState<ICase[]>([]);

  const { isOpen: isMoveToPhaseOpen, toggle: toggleMoveToPhase } = useOpen();
  const { isOpen: isSendMessageOpen, toggle: toggleSendMessage } = useOpen();
  const { isOpen: isMoveToGroupOpen, toggle: toggleMoveToGroup } = useOpen();
  const { isOpen: isUnlinkOpen, toggle: toggleUnlink } = useOpen();

  const { caseGroup, organization, caseCategories } =
    useCaseGroupDetailsCommonData();

  const refetchAndClear = async () => {
    setSelectedCases([]);
    await caseGroup.refetch();
  };

  const { mutateAsync: patchCases } = useCasesEdit(
    {
      organizationId: organizationId,
      caseIds: selectedCases.map((p) => p.id),
    },
    {
      successFb: refetchAndClear,
    }
  );

  const handleUnlinkCases = useCallback(async () => {
    if (caseGroup.data?.id === null) return;

    await patchCases([
      {
        op: 'replace',
        path: '/group_id',
        value: null,
      },
    ]);

    toggleUnlink();
  }, [caseGroup.data?.id, patchCases, toggleUnlink]);

  const handleToggleCases = () => {
    if (caseGroup.data === undefined) return;

    if (selectedCases.length !== caseGroup.data.cases.length) {
      setSelectedCases([...caseGroup.data.cases]);
      return;
    }

    if (selectedCases.length === caseGroup.data.cases.length)
      setSelectedCases([]);
  };

  const headerItems = [
    <div
      key="CaseGroups.CaseGroupDetails.CaseGroupDetailsCases.selection"
      className="d-flex align-items-center justify-content-center pb-1"
    >
      <IndeterminateCheckbox
        key="indeterminate-checkbox"
        checked={selectedCases.length === caseGroup.data?.cases.length}
        indeterminate={false}
        onChange={handleToggleCases}
      />
    </div>,
    'CaseGroups.CaseGroupDetails.CaseGroupDetailsCases.title',
    'CaseGroups.CaseGroupDetails.CaseGroupDetailsCases.description',
    'CaseGroups.CaseGroupDetails.CaseGroupDetailsCases.number',
    'CaseGroups.CaseGroupDetails.CaseGroupDetailsCases.category',
    'CaseGroups.CaseGroupDetails.CaseGroupDetailsCases.subCategory',
  ];

  const bodyRows = useMemo(() => {
    if (caseGroup.data === undefined) return [];

    return caseGroup.data.cases.map((caseDetails) => {
      return (
        <CaseItem
          key={caseDetails.id}
          organizationId={organizationId}
          caseDetails={caseDetails}
          caseCategories={caseCategories.data}
          selectedCases={selectedCases}
          setSelectedCases={setSelectedCases}
        />
      );
    });
  }, [caseCategories.data, caseGroup.data, organizationId, selectedCases]);

  const caseIds = selectedCases.map((p) => p.id);

  const isLoading =
    caseGroup.isLoading || organization.isLoading || caseCategories.isLoading;

  if (isLoading) return <CaseItemsLoading />;

  const handleCloseModal = () => {
    toggleSendMessage();
    setSelectedCases([]);
  };

  return (
    <>
      {isSendMessageOpen && caseIds.length > 0 && (
        <CaseHistoryModal
          caseIds={caseIds}
          organizationId={organizationId}
          isOpen={isSendMessageOpen}
          toggleModal={handleCloseModal}
          hidePraise={true}
          hideShare={true}
          extendedValues={{
            share_with_patient: true,
          }}
          customTitleTranslationId="CaseGroups.CaseGroupDetails.CaseGroupDetailsCases.sendMessage"
        />
      )}
      {isMoveToPhaseOpen && selectedCases.length > 0 && caseGroup.data && (
        <MoveToTreatmentPhaseModal
          organizationId={organizationId}
          refetch={caseGroup.refetch}
          isOpen={isMoveToPhaseOpen}
          toggle={toggleMoveToPhase}
          selectedCases={selectedCases}
          setSelectedCases={setSelectedCases}
        />
      )}
      {isMoveToGroupOpen && selectedCases.length > 0 && (
        <MoveCaseModal
          isOpen={isMoveToGroupOpen}
          toggle={toggleMoveToGroup}
          cases={selectedCases}
        />
      )}
      {isUnlinkOpen && (
        <ConfirmModal
          message={intl.formatMessage(
            {
              id: 'CaseGroups.CaseGroupDetails.CaseGroupDetailsCases.confirmUnlink',
            },
            { count: selectedCases.length }
          )}
          confirm={handleUnlinkCases}
          isOpen={isUnlinkOpen}
          toggle={toggleUnlink}
        />
      )}
      <div className="ManageGroupCases CaseCard__tab-container pt-3 px-3">
        <div className="ManageGroupCases__header">
          <div className="ManageGroupCases__actions">
            <Button
              color="primary"
              outline={true}
              onClick={toggleSendMessage}
              disabled={selectedCases.length === 0}
              className="d-flex align-items-center gap-1 px-3 py-2"
              title={intl.formatMessage({
                id: 'CaseGroups.CaseGroupDetails.CaseGroupDetailsCases.sendMessage',
              })}
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </Button>
            <Button
              color="primary"
              outline={true}
              onClick={toggleMoveToPhase}
              disabled={selectedCases.length === 0}
              className="d-flex align-items-center gap-1 px-3 py-2"
              title={intl.formatMessage({
                id: 'CaseGroups.CaseGroupDetails.CaseGroupDetailsCases.moveToConversationPhase',
              })}
            >
              <SVG src={projectFlowIcon} width={'1em'} height={'1em'} />
            </Button>
            <Button
              color="primary"
              outline={true}
              disabled={selectedCases.length === 0}
              className="d-flex align-items-center gap-1 px-3 py-2"
              onClick={toggleMoveToGroup}
              title={intl.formatMessage({
                id: 'CaseGroups.CaseGroupDetails.CaseGroupDetailsCases.moveCase',
              })}
            >
              <FontAwesomeIcon icon={faExchange} />
            </Button>
            <Button
              color="danger"
              outline={true}
              disabled={selectedCases.length === 0}
              className="d-flex align-items-center gap-1 px-3 py-2"
              onClick={toggleUnlink}
              title={intl.formatMessage({
                id: 'CaseGroups.CaseGroupDetails.CaseGroupDetailsCases.unlinkCase',
              })}
            >
              <FontAwesomeIcon icon={faUnlink} />
            </Button>
          </div>
        </div>
        <div>
          <CustomTable
            clickableRows={true}
            isResponsive={true}
            stretched={false}
            headerItems={headerItems}
            bodyRows={bodyRows}
          />
        </div>
      </div>
    </>
  );
};

export default CaseGroupDetailsCases;
