import { ODataParamType } from 'interfaces/request';

type oDataOptions = {
  startCharacter: string;
  logicalOperation?: 'and' | 'or';
  wrapInBracket?: boolean;
  isNotFirstFilter?: boolean;
};

export const getOdataFilterParams = (
  arrayParam: ODataParamType[],
  options?: oDataOptions
) => {
  const { startCharacter = '?' } = options || {};

  let paramString = arrayParam
    .map((param) => {
      const { key, operator, value } = param;
      return `${key} ${operator} ${value}`;
    })
    .join(
      options?.logicalOperation ? ` ${options.logicalOperation} ` : ' and '
    );

  if (options?.wrapInBracket && paramString) {
    paramString = `(${paramString})`;
  }

  const filterString = options && options.isNotFirstFilter ? '' : '$filter=';

  return paramString
    ? `${startCharacter}${filterString}${encodeURI(paramString)}`
    : '';
};
