import { DashboardTemplateSeriesAggregate } from '../../../../../../../interfaces/dashboards';

export namespace DashboardAggregationHelpers {
  export const AggregateValue = (
    items: number[],
    operator: DashboardTemplateSeriesAggregate | 'Count'
  ): number => {
    switch (operator) {
      case DashboardTemplateSeriesAggregate.Sum:
      case 'Count':
        return items.reduce((acc, curr) => acc + curr, 0);
      case DashboardTemplateSeriesAggregate.Min:
        return Math.min(...items);
      case DashboardTemplateSeriesAggregate.Max:
        return Math.max(...items);
      case DashboardTemplateSeriesAggregate.Avg:
        return parseFloat(
          (items.reduce((acc, curr) => acc + curr, 0) / items.length).toFixed(2)
        );
    }
  };
}
