import React, { useState } from 'react';
import EditableInput from '../../../../../../components/EditableInput/EditableInput';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faMinus,
  faPlus,
  faTrash,
} from '@fortawesome/pro-light-svg-icons';
import { iconColors } from '../../../../../../helpers/colors';
import { IUserOrganizationResponse } from 'interfaces/membership';
import { useOrganizationPathParams } from '../../../../../../hooks/paths/useOrganizationPathParams';
import {
  ConfirmationHookValues,
  UserDataToAddAccessProfile,
  UserDataToRemoveAccessProfile,
} from '../../types';
import { useOptions } from '../../../../../../hooks/useOptions';
import useOrganizationUserAccessProfiles from '../../../../../../api/queries/accessProfiles/useOrganizationUserAccessProfiles';
import { useInitQuery } from '../../../../../../hooks/queries/useInitQuery';
import { useAddOrganizationUserAccessProfile } from '../../../../../../api/mutations/accessProfiles/useAddOrganizationUserAccessProfile';
import { InfiniteData, QueryObserverResult } from 'react-query';
import { IError, IInfiniteResult } from '../../../../../../interfaces/response';

type OrganizationUsersItemProps = {
  record: IUserOrganizationResponse;
  deleteRole: ConfirmationHookValues<UserDataToRemoveAccessProfile | null>;
  deleteAllRoles: ConfirmationHookValues<string | null>;
  refetch: () => Promise<
    QueryObserverResult<
      InfiniteData<IInfiniteResult<IUserOrganizationResponse[]>>,
      IError
    >
  >;
};

const OrganizationUsersItem = ({
  record,
  refetch,
  deleteAllRoles,
  deleteRole,
}: OrganizationUsersItemProps) => {
  const { organizationId } = useOrganizationPathParams();

  const { accessProfilesOptions } = useOptions();

  const successFb = async () => await refetch();

  const { mutateAsync: addOrganizationUserAccessProfile } =
    useAddOrganizationUserAccessProfile({ organizationId }, { successFb });

  const accessProfiles = useOrganizationUserAccessProfiles({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(accessProfiles);

  const [userWithAccessProfileToAdd, setUserWithAccessProfileToAdd] =
    useState<UserDataToAddAccessProfile | null>(null);

  const profiles = record.access_profiles.map((profile) => {
    const handleRemoveAccessProfile = () => {
      deleteRole.setItem({
        userRecord: record,
        accessProfileId: profile.access_profile_id,
      });
    };

    return (
      <li key={`${profile.organization_id}-${profile.name}`}>
        {profile.name}
        <Button className="btn-transparent" onClick={handleRemoveAccessProfile}>
          <FontAwesomeIcon icon={faMinus} color={iconColors.normal} />
        </Button>
      </li>
    );
  });

  const handleAccessProfileSelect = (target: string, value: string) => {
    if (userWithAccessProfileToAdd === null) return;

    const existingProfile = accessProfilesOptions(accessProfiles).find(
      (p) => p.value === value
    );

    if (!existingProfile) return;

    setUserWithAccessProfileToAdd({
      userRecord: userWithAccessProfileToAdd.userRecord,
      accessProfileId: existingProfile.value,
    });
  };

  const handleAccessProfileConfirmAdd = async () => {
    if (!userWithAccessProfileToAdd?.accessProfileId) return;

    await addOrganizationUserAccessProfile({
      accessProfileId: userWithAccessProfileToAdd.accessProfileId,
      userId: userWithAccessProfileToAdd.userRecord.user.id,
    });

    setUserWithAccessProfileToAdd(null);
  };

  const handleSetUserToDelete = () => {
    deleteAllRoles.setItem(record.user.id);
  };

  const handleAccessProfileAdd = () => {
    setUserWithAccessProfileToAdd({
      userRecord: record,
      accessProfileId: null,
    });
  };

  return (
    <tr key={record.user.id}>
      <td>{record.user.email}</td>
      <td>
        {record.user.first_name} {record.user.last_name}
      </td>
      <td>
        <ul className="list-unstyled">
          {profiles}
          {userWithAccessProfileToAdd &&
          userWithAccessProfileToAdd.userRecord.user.id === record.user.id ? (
            <div className="d-flex align-items-center gap-2">
              <EditableInput
                type="select"
                value={userWithAccessProfileToAdd.accessProfileId ?? ''}
                name="assignment_rule"
                options={accessProfilesOptions(accessProfiles)}
                onEdit={handleAccessProfileSelect}
                debounceEnabled={false}
              />
              <Button
                color="primary"
                onClick={handleAccessProfileConfirmAdd}
                block={false}
              >
                <FontAwesomeIcon icon={faCheck} color={iconColors.white} />
              </Button>
            </div>
          ) : (
            <Button
              color="primary"
              outline={true}
              onClick={handleAccessProfileAdd}
              block={false}
            >
              <FontAwesomeIcon icon={faPlus} color={iconColors.edit} />
            </Button>
          )}
        </ul>
      </td>
      <td width="12.5%">
        <Button color="danger" onClick={handleSetUserToDelete} outline={true}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </td>
    </tr>
  );
};

export default OrganizationUsersItem;
