import { alpha, styled, TableRow } from '@mui/material';

const CustomTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: alpha(theme.palette.primary.main, 0.05),
  },
  '& td, & th': {
    border: 0,
  },
}));

export default CustomTableRow;
