import { Case, Switch } from 'components/SwitchCase';
import React from 'react';
import { CaseTrackingType } from '../../../../interfaces/cases';
import CaseDetailsProvider from './providers/CaseDetailsProvider';
import { CaseDetailsContext } from './types';
import AwareCaseDetailsView from './views/AwareCaseDetailsView';
import BuddyCaseDetailsView from './views/BuddyCaseDetailsView';
import ChangeCaseDetailsView from './views/ChangeCaseDetailsView';
import GenericCaseDetailsView from './views/GenericCaseDetailsView';
import MomentaryCaseDetailsView from './views/MomentaryCaseDetailsView';
import useUrlSearch from '../../../../hooks/useUrlSearch';
import OldAwareCaseDetailsView from './views/AwareCaseDetailsView/old_components';

const CaseDetailsPage = () => {
  const query = useUrlSearch();
  const version = query.get('version');

  return (
    <CaseDetailsProvider>
      {(caseDetails) => (
        <CaseDetailsContext.Provider value={caseDetails}>
          <Switch item={caseDetails.type}>
            <Case value={CaseTrackingType.Generic}>
              <GenericCaseDetailsView />
            </Case>
            <Case value={CaseTrackingType.Therapy}>
              <GenericCaseDetailsView />
            </Case>
            <Case value={CaseTrackingType.Research}>
              <GenericCaseDetailsView />
            </Case>
            <Case value={CaseTrackingType.MomentarySession}>
              <MomentaryCaseDetailsView />
            </Case>
            <Case value={CaseTrackingType.Change}>
              <ChangeCaseDetailsView />
            </Case>
            <Case value={CaseTrackingType.Buddy}>
              <BuddyCaseDetailsView />
            </Case>
            <Case value={CaseTrackingType.AwareSession}>
              {version === '2' ? (
                <AwareCaseDetailsView />
              ) : (
                <OldAwareCaseDetailsView />
              )}
            </Case>
          </Switch>
        </CaseDetailsContext.Provider>
      )}
    </CaseDetailsProvider>
  );
};

export default CaseDetailsPage;
