import React from 'react';
import useCaseProcedures from '../../../../../../../../api/queries/cases/useCaseProcedures';
import useRequiredParams from '../../../../../../../../hooks/useRequiredParams';
import { useInitQuery } from '../../../../../../../../hooks/queries/useInitQuery';
import { ICaseProcedureWithActivitiesLocalized } from '../../../../../../../../interfaces/cases';
import QueryLoaded from '../../../../../../../../components/QueryLoaded';

type CaseProceduresProviderProps = {
  children: (
    procedures: ICaseProcedureWithActivitiesLocalized[]
  ) => JSX.Element;
};

const CaseProceduresProvider = ({ children }: CaseProceduresProviderProps) => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const caseProceduresQuery = useCaseProcedures({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(caseProceduresQuery);

  return <QueryLoaded query={caseProceduresQuery}>{children}</QueryLoaded>;
};

export default CaseProceduresProvider;
