import React from 'react';
import FieldInput from '../../../FieldInput';
import { Field } from 'formik';
import InputFieldWithoutImage from '../../../InputFieldWithoutImage';
import AddScheduleTemplatePartOfADayFields from '../AddScheduleTemplatePartOfADayFields';

const AddScheduleTemplateSingleInstanceFields = () => {
  return (
    <>
      <div className="TreatmentPhaseAddTemplateModal__row">
        <InputFieldWithoutImage
          gray={true}
          noMargin={true}
          block={true}
          labelTranslationId="Projects.ProjectPhases.ProjectPhasesContent.ProjectPhase.ProjectPhaseAddTemplateModal.dayOfThePhase"
          inputId="specific_day_of_phase"
        >
          <Field
            id="specific_day_of_phase"
            name="schedule_templates[0].specific_day_of_phase"
            type="number"
            required={true}
            component={FieldInput}
          />
        </InputFieldWithoutImage>
      </div>
      <div className="TreatmentPhaseAddTemplateModal__row">
        <AddScheduleTemplatePartOfADayFields index={0} />
      </div>
    </>
  );
};

export default AddScheduleTemplateSingleInstanceFields;
