import { IAsyncFormHookValues } from '../../../../../interfaces/forms';
import {
  CategorizedEducationMaterial,
  MaterialModalFormValues,
  MaterialModalMode,
  MaterialModalStepsContent,
  MaterialProps,
} from '../types';
import { FormikErrors } from 'formik/dist/types';
import { useCallback, useMemo } from 'react';
import { useCommonParams } from '../../../hooks/useCommonParams';
import { IEducationMaterial } from '../../../../../interfaces/educationMaterials';
import { validateSubsetEmpty } from '../../../../../helpers/utils/validators';
import { useIntl } from 'react-intl';
import { useHandleAddEducationMaterial } from './useHandleAddEducationMaterial';
import { useCommonData } from '../../../hooks/useCommonData';
import { useInitQuery } from '../../../../../hooks/queries/useInitQuery';
import { CultureEntry } from '../../../../../interfaces/cultures';

const getEmptyEducationMaterial = (
  organizationName: string,
  initialCategoryId: string | undefined
): MaterialModalFormValues => {
  return {
    organization_name: organizationName,
    name: '',
    description: '',
    translations: [],
    material_category_id: initialCategoryId ?? '',
    case_category_id: '',
    is_premium: false,
    is_default: false,
    rules: [],
  };
};

type FormHookProps = MaterialProps & {
  mode: MaterialModalMode;
  setCreatedMaterial: (material: IEducationMaterial) => void;
  steps: MaterialModalStepsContent;
  toggle: () => void;
  initialCategoryId: string | undefined;
};

const appendNameTranslations = (
  material: CategorizedEducationMaterial,
  translations: CultureEntry<{
    name: string;
    description: string;
  }>[]
) => {
  material.name_translations.forEach((translation) => {
    const translationIndex = translations.findIndex(
      (p) => p.culture === translation.culture
    );

    if (translationIndex === -1) {
      translations.push({
        culture: translation.culture,
        value: { name: translation.value, description: '' },
      });

      return;
    }

    translations[translationIndex].value.name = translation.value;
  });

  return translations;
};

const appendDescriptionTranslations = (
  material: CategorizedEducationMaterial,
  translations: CultureEntry<{
    name: string;
    description: string;
  }>[]
) => {
  material.description_translations.forEach((translation) => {
    const translationIndex = translations.findIndex(
      (p) => p.culture === translation.culture
    );

    if (translationIndex === -1) {
      translations.push({
        culture: translation.culture,
        value: { name: '', description: translation.value },
      });

      return;
    }

    translations[translationIndex].value.description = translation.value;
  });

  return translations;
};

export const useForm = ({
  mode,
  material,
  setCreatedMaterial,
  steps,
  toggle,
  initialCategoryId,
}: FormHookProps): IAsyncFormHookValues<MaterialModalFormValues> => {
  const { organization } = useCommonData();
  useInitQuery(organization);

  const { organizationId } = useCommonParams();
  const intl = useIntl();

  const { addAsync } = useHandleAddEducationMaterial(
    mode,
    setCreatedMaterial,
    steps,
    toggle
  );

  const initialValues = useMemo((): MaterialModalFormValues => {
    if (mode === MaterialModalMode.AddMaterial || !material)
      return getEmptyEducationMaterial(
        organization.data?.name ?? '',
        initialCategoryId
      );

    let translations: CultureEntry<{
      name: string;
      description: string;
    }>[] = [];

    translations = appendNameTranslations(material, translations);
    translations = appendDescriptionTranslations(material, translations);

    return {
      organization_name: organization.data?.name ?? '',
      name: material.name,
      translations: translations,
      description: material.description,
      material_category_id: material.category_id,
      case_category_id: material.case_category_id,
      is_premium: material.is_premium,
      is_default: material.is_default,
      rules: material.rules,
    };
  }, [initialCategoryId, material, mode, organization.data?.name]);

  const handleSubmit = useCallback(
    async (values: MaterialModalFormValues) => {
      await addAsync({
        id: mode === MaterialModalMode.AddMaterial ? null : material?.id ?? '',
        organization_id: organizationId,
        case_category_id: values.case_category_id,
        name: values.name,
        name_translations: values.translations.map((p) => ({
          culture: p.culture,
          value: p.value.name,
        })),
        description: values.description,
        description_translations: values.translations.map((p) => ({
          culture: p.culture,
          value: p.value.description,
        })),
        rules: values.rules,
        is_default: values.is_default,
        is_premium: values.is_premium,
      });
    },
    [addAsync, material?.id, mode, organizationId]
  );

  const validation = useCallback(
    (
      values: MaterialModalFormValues
    ): FormikErrors<MaterialModalFormValues> => {
      let errors: FormikErrors<MaterialModalFormValues> =
        validateSubsetEmpty<MaterialModalFormValues>(
          values,
          ['organization_id', 'name', 'description', 'case_category_id'],
          intl
        );

      const emptyTranslations = values.translations.some(
        (p) =>
          !p.culture ||
          p.value.name.length === 0 ||
          p.value.description.length === 0
      );

      if (emptyTranslations) errors.translations = [];

      const emptyRules = values.rules.some(
        (p) =>
          !p.rule ||
          !p.case_category_id ||
          !p.case_sub_category_id ||
          !p.availability_status
      );

      if (emptyRules) errors.rules = [];
      return errors;
    },
    [intl]
  );

  return {
    initialValues,
    handleSubmit,
    validation,
  };
};
