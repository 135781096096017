import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import HourglassBottomOutlinedIcon from '@mui/icons-material/HourglassBottomOutlined';
import { Formik } from 'formik';
import useOpen from 'hooks/useOpen';
import React from 'react';
import IconButton from '../../../../../../../../components/IconButton';
import TabLayout, {
  TabLayoutType,
} from '../../../../../../../../components/layouts/TabLayout';
import OrganizationInfoForm from './forms/InfoForm';
import { useForm } from './hooks/useForm';

const InfoTab = () => {
  const editMode = useOpen();
  const { initialValues, handleSubmit, validation } = useForm(editMode);

  return (
    <Formik
      initialValues={initialValues}
      validate={validation}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({ values, setValues, submitForm, isSubmitting }) => (
        <TabLayout
          type={TabLayoutType.Breadcrumb}
          titlePrefix="Organizations.Info"
          actions={
            <div className="d-flex align-items-center gap-2">
              {editMode && (
                <>
                  {editMode?.isOpen ? (
                    <>
                      <IconButton
                        color="primary"
                        variant="outlined"
                        onClick={editMode.toggle}
                      >
                        <CloseOutlinedIcon />
                      </IconButton>
                      <IconButton
                        color="primary"
                        variant="contained"
                        onClick={submitForm}
                      >
                        {isSubmitting ? (
                          <HourglassBottomOutlinedIcon />
                        ) : (
                          <CheckOutlinedIcon />
                        )}
                      </IconButton>
                    </>
                  ) : (
                    <IconButton
                      color="primary"
                      onClick={editMode?.toggle}
                      variant="outlined"
                    >
                      <EditOutlinedIcon />
                    </IconButton>
                  )}
                </>
              )}
            </div>
          }
        >
          <OrganizationInfoForm
            values={values}
            setValues={setValues}
            editMode={editMode}
          />
        </TabLayout>
      )}
    </Formik>
  );
};

export default InfoTab;
