import { IValidationSchemaBasedFormHookValues } from '../../../../../../../../../interfaces/forms';
import { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { AnyObject, ObjectSchema } from 'yup';
import { useHandleInitialValues } from './useHandleInitialValues';
import { AwareCaseDetailsFormValues } from '../types';
import { OpenHookValues } from '../../../../../../../../../hooks/useOpen';
import { useTypedContext } from '../../../../../../../../../hooks/useTypedContext';
import { OperationalDataContext } from '../../../providers/OperationalDataProvider';
import { IUpdateAwareSessionData } from '../../../../../../../../../interfaces/awareSessions';
import { ValidConversationQuestionType } from '../../../../../../../../../interfaces/conversations';
import { useHandleSave } from './useHandleSave';
import { IUpdateCaseProcedureHistory } from '../../../../../../../../../interfaces/caseHistory';

export const useForm = (
  editMode: OpenHookValues
): IValidationSchemaBasedFormHookValues<AwareCaseDetailsFormValues> => {
  const handleInitialValues = useHandleInitialValues();

  const { questions } = useTypedContext(OperationalDataContext);

  const handleSave = useHandleSave(editMode);

  const initialValues = useMemo(() => {
    return handleInitialValues();
  }, [handleInitialValues]);

  const questionOptionSchema = Yup.object().shape({
    value: Yup.string().required(),
    text: Yup.string().required(),
  });

  const questionsSchema = Yup.object().shape({
    value: Yup.string(),
    label: Yup.string().required(),
    options: Yup.array().of(questionOptionSchema),
  });

  const procedureActivitySchema = Yup.object().shape({
    id: Yup.string().required(),
    entryId: Yup.string(),
    nameEn: Yup.string().required(),
    nameNo: Yup.string().required(),
    color: Yup.string().required(),
    timeStart: Yup.string().required(),
    timeEnd: Yup.string().required(),
  });

  const proceduresSchema = Yup.object().shape({
    id: Yup.string().required(),
    entryId: Yup.string(),
    nameEn: Yup.string().required(),
    nameNo: Yup.string().required(),
    timeStart: Yup.string().required(),
    timeEnd: Yup.string().required(),
    activities: Yup.array().of(procedureActivitySchema),
  });

  const validationSchema = Yup.object().shape({
    patient_number: Yup.string(),
    questions: Yup.array().of(questionsSchema),
    procedures: Yup.array().of(proceduresSchema),
  }) as ObjectSchema<AwareCaseDetailsFormValues, AnyObject>;

  const handleSubmit = useCallback(
    async (values: typeof initialValues) => {
      let updateAwareSessionData: Partial<IUpdateAwareSessionData> = {};
      let updateCaseProcedureHistory: Partial<IUpdateCaseProcedureHistory> = {};

      updateAwareSessionData.patient_number =
        values.patient_number !== ''
          ? parseInt(values.patient_number)
          : undefined;

      updateAwareSessionData.questions = [];
      updateCaseProcedureHistory.procedures = [];

      values.questions.forEach((question) => {
        const selectedQuestion = questions.find(
          (p) => p.resource_id === question.id
        )?.content;

        if (selectedQuestion?.type !== ValidConversationQuestionType.ClosedList)
          return;

        const selectedAnswer = selectedQuestion.answers.find(
          (p) => p.guid === question.value
        );

        if (!selectedAnswer) return;

        updateAwareSessionData.questions?.push({
          id: question.id,
          value: selectedAnswer.title,
        });
      });

      values.procedures.forEach((procedure) => {
        updateCaseProcedureHistory.procedures?.push({
          procedure_id: procedure.id,
          time_start: procedure.timeStart,
          time_end: procedure.timeEnd,
          activities: procedure.activities.map((activity) => ({
            procedure_activity_id: activity.id,
            time_start: activity.timeStart,
            time_end: activity.timeEnd,
          })),
        });
      });

      await handleSave(
        updateAwareSessionData as IUpdateAwareSessionData,
        updateCaseProcedureHistory as IUpdateCaseProcedureHistory
      );
    },
    [handleSave, questions]
  );

  return {
    initialValues,
    handleSubmit,
    validationSchema,
  };
};
