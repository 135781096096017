import React from 'react';
import { useCommonData } from '../../hooks/useCommonData';
import { useInitQuery } from '../../../../hooks/queries/useInitQuery';
import { useAddTemplate } from './hooks/useAddTemplate';
import ConversationTemplatesList from './components/ConversationTemplatesList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport, faPlus } from '@fortawesome/pro-light-svg-icons';
import { Button } from 'reactstrap';
import { useTranslations } from '../../../../hooks/useTranslations';
import useOpen from '../../../../hooks/useOpen';
import ImportAndEditTemplateModal from './modals/ImportAndEditTemplateModal';
import ConversationTemplateModal from './modals/ConversationTemplateModal';
import TabLayout, { TabLayoutType } from 'components/layouts/TabLayout';

const ConversationTemplatesTab = () => {
  const { wrapWithIntl, attachPrefix } = useTranslations(
    'ConversationsAndFeedbacks.ConversationTemplates'
  );

  const { conversationTemplates } = useCommonData();
  useInitQuery(conversationTemplates);

  const {
    enableAddTemplateMode,
    toggleAddTemplateModal,
    templateToAdd,
    isAddTemplateModalOpen,
  } = useAddTemplate();

  const importAndEditTemplateModal = useOpen();

  return (
    <TabLayout
      type={TabLayoutType.Generic}
      titlePrefix="Conversations.ConversationTemplates"
      actions={
        <>
          <Button
            onClick={enableAddTemplateMode}
            color="primary"
            outline={true}
          >
            <FontAwesomeIcon
              icon={faPlus}
              title={wrapWithIntl(attachPrefix('addConversationTemplate'))}
            />
          </Button>
          <Button
            onClick={importAndEditTemplateModal.toggle}
            color="primary"
            outline={true}
          >
            <FontAwesomeIcon
              icon={faFileImport}
              title={wrapWithIntl(attachPrefix('importTemplate'))}
            />
          </Button>
        </>
      }
    >
      <div>
        {templateToAdd && isAddTemplateModalOpen && (
          <ConversationTemplateModal
            isOpen={isAddTemplateModalOpen}
            toggle={toggleAddTemplateModal}
            template={templateToAdd}
          />
        )}
        <ImportAndEditTemplateModal
          isOpen={importAndEditTemplateModal.isOpen}
          toggle={importAndEditTemplateModal.toggle}
        />
        <div className="mt-4">
          <ConversationTemplatesList />
        </div>
      </div>
    </TabLayout>
  );
};

export default ConversationTemplatesTab;
