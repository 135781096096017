import React from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { useFunctionality } from '../../../../../../hooks/useFunctionality';
import { FunctionalityCode } from '../../../../../../interfaces/functionalities';
import { useLocation } from 'react-router';
import AccessDropdownItem from './components/AccessDropdownItem';
import { IOrganization } from '../../../../../../interfaces/organizations';

type DropdownProps = {
  organization: IOrganization;
};

const Dropdown = ({ organization }: DropdownProps) => {
  const organizationId = organization.id;

  const location = useLocation();

  const { hasFunctionality: educationMaterialsFunctionality } =
    useFunctionality(FunctionalityCode.CONVERSATIONS_EDUCATION_MATERIALS);

  const dropdownLinks = [
    `/my-organizations/${organizationId}`,
    `/organizations/${organizationId}/users-and-access`,
    `/organizations/${organizationId}/grasps`,
    `/organizations/${organizationId}/reports`,
    `/organizations/${organizationId}/resources-hub`,
    `/organizations/${organizationId}/education`,
    `/organizations/${organizationId}/treatment-plans`,
    `/organizations/${organizationId}/projects`,
  ];

  return (
    <UncontrolledDropdown
      nav
      inNavbar
      className="OrganizationNavigation__dropdown"
    >
      <DropdownToggle
        nav
        caret
        className={classNames({
          'OrganizationNavigation__dropdown-toggle p-0': true,
          'text-black': !dropdownLinks.includes(location.pathname),
          'text-primary active': dropdownLinks.includes(location.pathname),
        })}
      >
        <FormattedMessage id="OrganizationNavigation.settings.settings" />
      </DropdownToggle>
      <DropdownMenu end className="OrganizationNavigation__dropdown-menu">
        <DropdownItem className="OrganizationNavigation__dropdown-item p-0">
          <NavLink
            to={dropdownLinks[0]}
            className="OrganizationNavigation__dropdown-link"
          >
            <FormattedMessage id="OrganizationNavigation.settings.organizationDetails" />
          </NavLink>
        </DropdownItem>
        <AccessDropdownItem links={dropdownLinks} />
        <DropdownItem className="OrganizationNavigation__dropdown-item p-0">
          <NavLink
            to={dropdownLinks[2]}
            className="OrganizationNavigation__dropdown-link"
          >
            <FormattedMessage id="OrganizationNavigation.settings.grasps" />
          </NavLink>
        </DropdownItem>
        <DropdownItem className="OrganizationNavigation__dropdown-item p-0">
          <NavLink
            to={dropdownLinks[3]}
            className="OrganizationNavigation__dropdown-link"
          >
            <FormattedMessage id="OrganizationNavigation.settings.reports" />
          </NavLink>
        </DropdownItem>
        <DropdownItem className="OrganizationNavigation__dropdown-item p-0">
          <NavLink
            to={dropdownLinks[4]}
            className="OrganizationNavigation__dropdown-link"
          >
            <FormattedMessage id="OrganizationNavigation.settings.conversationsAndFeedbacks" />
          </NavLink>
        </DropdownItem>
        {educationMaterialsFunctionality && (
          <DropdownItem className="OrganizationNavigation__dropdown-item p-0">
            <NavLink
              to={dropdownLinks[5]}
              className="OrganizationNavigation__dropdown-link"
            >
              <FormattedMessage id="OrganizationNavigation.settings.education" />
            </NavLink>
          </DropdownItem>
        )}
        <DropdownItem className="OrganizationNavigation__dropdown-item p-0">
          <NavLink
            to={dropdownLinks[6]}
            className="OrganizationNavigation__dropdown-link"
          >
            <FormattedMessage id="OrganizationNavigation.settings.treatmentPlans" />
          </NavLink>
        </DropdownItem>
        <DropdownItem className="OrganizationNavigation__dropdown-item p-0">
          <NavLink
            to={dropdownLinks[7]}
            className="OrganizationNavigation__dropdown-link"
          >
            <FormattedMessage id="OrganizationNavigation.settings.projects" />
          </NavLink>
        </DropdownItem>
      </DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default Dropdown;
