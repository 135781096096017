import { useCallback } from 'react';
import { FormikProps } from 'formik';
import { AwareCaseDetailsFormValues } from '../../../../../forms/AwareCaseDetailsForm/types';
import { ICaseProcedureWithActivitiesLocalized } from '../../../../../../../../../../../interfaces/cases';
import { cloneDeep } from 'lodash';

export const useProcedureType = (
  form: FormikProps<AwareCaseDetailsFormValues>,
  procedureIndex: number,
  procedures: ICaseProcedureWithActivitiesLocalized[]
) => {
  const { setFieldValue, values } = form;

  return useCallback(
    (event: any) => {
      const selectedProcedure = procedures.find(
        (p) => p.id === event.target.value
      );

      if (!selectedProcedure) return;

      let selectedEntry = cloneDeep(values.procedures.at(procedureIndex));
      if (!selectedEntry) return;

      selectedEntry.id = selectedProcedure.id;
      selectedEntry.nameEn = selectedProcedure.name_en;
      selectedEntry.nameNo = selectedProcedure.name_no;
      selectedEntry.activities = [];

      setFieldValue(`procedures[${procedureIndex}]`, selectedEntry);
    },
    [procedureIndex, procedures, setFieldValue, values.procedures]
  );
};
