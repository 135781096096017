import { formatDate } from 'helpers/dates';
import { IUserConversationHistoryEntry } from 'interfaces/conversations';
import { useCallback } from 'react';
import { useCommonData } from '../../../../../hooks/useCommonData';
import { useCaseParticipant } from './useCaseParticipant';

export const useConversationAnswerHistoryLocalSearch = () => {
  const { conversationTemplatesQuery } = useCommonData();
  const { name } = useCaseParticipant();

  const filterByQuickSearch = useCallback(
    (value: string, entries: IUserConversationHistoryEntry[]) => {
      let _entries: IUserConversationHistoryEntry[] = [];

      if (value.length === 0) return entries;

      for (const entry of entries) {
        const templateDisplayCode =
          conversationTemplatesQuery.data?.find(
            (p) => p.id === entry.conversationTemplateId
          )?.display_code ?? '';

        const participantName = name;
        const formattedDate = formatDate(
          new Date(entry.year, entry.month - 1, entry.day).toISOString(),
          {
            ignoreTime: true,
          }
        );

        if (
          templateDisplayCode.includes(value) ||
          participantName.includes(value) ||
          formattedDate.includes(value)
        )
          _entries.push(entry);
      }

      return _entries;
    },
    [conversationTemplatesQuery.data, name]
  );

  return {
    filterByQuickSearch,
  };
};
