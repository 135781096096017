import { IOrganizationObservationMeaning } from '../../../interfaces/observationMeanings';
import { useQuestionTitle } from '../../../hooks/data/useQuestionTitle';
import { useMeaningName } from '../../../hooks/data/useMeaningName';
import { useCallback, useMemo } from 'react';
import { TrendsChartFormatter } from '../types';
import {
  genericDateFormatter,
  genericNumberFormatter,
  genericResourceFormatter,
} from '../helpers/formatters';
import { useTranslations } from '../../../hooks/useTranslations';
import { ConversationQuestion } from '../../../interfaces/conversations';

export const useTrendsChartGenericFormatters = (
  questions: ConversationQuestion[],
  meanings: IOrganizationObservationMeaning[]
) => {
  const { wrapWithIntl, attachPrefix } = useTranslations('TrendsChart');

  const getQuestionTitle = useQuestionTitle();
  const getMeaningName = useMeaningName();

  const getNotSpecifiedName = useCallback(
    () => wrapWithIntl(attachPrefix('notSpecified')),
    [attachPrefix, wrapWithIntl]
  );

  const getExerciseTimeName = useCallback(
    () => wrapWithIntl(attachPrefix('exerciseTime')),
    [attachPrefix, wrapWithIntl]
  );

  return useMemo((): TrendsChartFormatter[] => {
    return [
      {
        source: 'y-axis',
        callback: genericNumberFormatter,
      },
      {
        source: 'x-axis',
        callback: genericDateFormatter,
      },
      {
        source: 'name',
        callback: (value) =>
          genericResourceFormatter(
            value,
            getQuestionTitle,
            getMeaningName,
            getExerciseTimeName,
            getNotSpecifiedName,
            questions,
            meanings
          ),
      },
    ];
  }, [
    getExerciseTimeName,
    getMeaningName,
    getNotSpecifiedName,
    getQuestionTitle,
    meanings,
    questions,
  ]);
};
