import React from 'react';
import Flag from 'react-flagkit';
import { UncontrolledTooltip } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { CultureEntry } from '../../../../../../../../interfaces/cultures';

type EducationMaterialsItemTranslationProps = {
  sequence: string;
  translation: CultureEntry<{ name: string; description: string }>;
};

const EducationMaterialsItemTranslation = ({
  sequence,
  translation,
}: EducationMaterialsItemTranslationProps) => {
  return (
    <>
      <Flag
        id={`education-materials-item-input-target-${sequence}-${translation.culture}`}
        country={translation.culture.slice(-2).toUpperCase()}
      />
      <UncontrolledTooltip
        className="MaterialsTabContentItem__tooltip MaterialsTabContentItemTooltip"
        innerClassName="MaterialsTabContentItemTooltip__inner"
        placement="right"
        target={`education-materials-item-input-target-${sequence}-${translation.culture}`}
      >
        <div className="MaterialsTabContentItemTooltip__body MaterialsTabContentItemTooltipBody">
          <div className="MaterialsTabContentItemTooltipBody__item">
            <span className="MaterialsTabContentItemTooltipBodyItem__property">
              <FormattedMessage id="Education.EducationMaterialsTab.EducationMaterialsItem.name" />
            </span>
            <span className="MaterialsTabContentItemTooltipBodyItem__value">
              {translation.value.name ?? '--'}
            </span>
          </div>
          <div className="MaterialsTabContentItemTooltipBody__item MaterialsTabContentItemTooltipBodyItem">
            <span className="MaterialsTabContentItemTooltipBodyItem__property">
              <FormattedMessage id="Education.EducationMaterialsTab.EducationMaterialsItem.description" />
            </span>
            <span className="MaterialsTabContentItemTooltipBodyItem__value">
              {translation.value.description}
            </span>
          </div>
        </div>
      </UncontrolledTooltip>
    </>
  );
};

export default EducationMaterialsItemTranslation;
