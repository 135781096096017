import CustomCalendarInput from 'components/CustomCalendarInput';
import { IAssessmentTemplate } from 'interfaces/assessmentForms';
import React, { ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Alert,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  ModalBody,
  ModalFooter,
  Row,
} from 'reactstrap';

type AssessmentFormDefinitionProps = {
  date: string;
  time: string;
  selectedTemplate: string;
  templates: IAssessmentTemplate[];
  onDateChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onTimeChange: (e: ChangeEvent<HTMLInputElement>) => void;
  onTemplateChange: (e: ChangeEvent<HTMLInputElement>) => void;
  nextStep: () => void;
};

const AssessmentFormDefinition = ({
  date,
  time,
  templates,
  selectedTemplate,
  onDateChange,
  onTimeChange,
  onTemplateChange,
  nextStep,
}: AssessmentFormDefinitionProps) => {
  const isDateOrTimeWrong =
    isNaN(new Date(date).getTime()) ||
    !time ||
    isNaN(Number.parseFloat(time.split(':')[0])) ||
    isNaN(Number.parseFloat(time.split(':')[1]));

  const templatesOptions = templates?.map(({ code, title }) => {
    return (
      <option key={`option-${code}`} value={code}>
        {title}
      </option>
    );
  });

  const isDisabled = isDateOrTimeWrong || !selectedTemplate;

  return (
    <>
      <ModalBody>
        <div className="NonAssignedAssessmentModal__selection">
          {isDateOrTimeWrong && (
            <Alert color="danger">
              <FormattedMessage id="TimelineDetails.nonAssignedAssessmentModal.wrongDateOrTime" />
            </Alert>
          )}
        </div>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label htmlFor="date-new-assessment">
                <FormattedMessage id="General.Date" />
              </Label>
              <CustomCalendarInput
                id="date-new-assessment"
                onChange={onDateChange}
                value={date}
              />
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup>
              <Label htmlFor="time-new-assessment">
                <FormattedMessage id="General.Time" />
              </Label>
              <Input
                id="time-new-assessment"
                type="time"
                onChange={onTimeChange}
                value={time}
              />
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup>
              <Label htmlFor="template-new-assessment">
                <FormattedMessage id="TimelineDetails.nonAssignedAssessmentModal.assessmentForm" />
              </Label>
              <Input
                id="template-new-assessment"
                type="select"
                value={selectedTemplate}
                onChange={onTemplateChange}
              >
                <option value=""></option>
                {templatesOptions}
              </Input>
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          disabled={isDisabled}
          type="button"
          onClick={nextStep}
        >
          <FormattedMessage id="TimelineDetails.nonAssignedAssessmentModal.fillForm" />
        </Button>
      </ModalFooter>
    </>
  );
};

export default AssessmentFormDefinition;
