import SVG from 'react-inlinesvg';
import BuddyCaseSection from './BuddyCaseSection';
import BuddyCaseHourByHourContent from './BuddyCaseHourByHourContent';
import { useIntl } from 'react-intl';

import hourByHourIcon from 'assets/images/buddy_case/hour_by_hour_icon.svg';

type BuddyCaseHourByHourProps = {
  organizationId: string;
  caseId: string;
  timelineId: string;
  selectedDate: Date;
};

const BuddyCaseHourByHour = ({
  organizationId,
  caseId,
  timelineId,
  selectedDate,
}: BuddyCaseHourByHourProps) => {
  const intl = useIntl();

  return (
    <BuddyCaseSection
      icon={<SVG src={hourByHourIcon} />}
      title={intl.formatMessage({ id: 'BuddyCase.hourByHour' })}
      content={
        <BuddyCaseHourByHourContent
          organizationId={organizationId}
          caseId={caseId}
          timelineId={timelineId}
          selectedDate={selectedDate}
        />
      }
    />
  );
};

export default BuddyCaseHourByHour;
