import React, { lazy, Suspense } from 'react';
import { IUserTimeline } from '../../../../../../interfaces/timeline';
import useOpen from '../../../../../../hooks/useOpen';
import {
  generateRoute,
  RouteDefinitions,
} from '../../../../../../helpers/routeDefinitions';
import { NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import PersonalSpace from '../../../../../ContextSelector/PersonalSpace';

const CreateOrganizationModal = lazy(
  () => import('modules/Organizations/modals/CreateOrganizationModal')
);

type UserNavigationProps = {
  timeline: IUserTimeline | undefined;
};

const Content = ({ timeline }: UserNavigationProps) => {
  const { isOpen, open, close } = useOpen();

  const getTimelineRoute = (userTimeline: IUserTimeline) =>
    generateRoute(RouteDefinitions.USER_TIMELINE, {
      timelineId: userTimeline.id,
    });

  return (
    <>
      <Suspense fallback={null}>
        <CreateOrganizationModal isOpen={isOpen} closeModal={close} />
      </Suspense>
      {timeline && (
        <NavItem>
          <NavLink to={getTimelineRoute(timeline)}>
            <FormattedMessage id="Header.dayByDay" />
          </NavLink>
        </NavItem>
      )}
      <PersonalSpace />
      <NavItem>
        <NavLink to="/sharing-requests">
          <FormattedMessage id="Header.sharingRequests" />
        </NavLink>
      </NavItem>
      <NavItem onClick={open} className="cursor-pointer">
        <FormattedMessage id="MyOrganizations.createOrganization.header" />
      </NavItem>
    </>
  );
};

export default Content;
