import React from 'react';
import ItemPlaceholder from '../../../ItemPlaceholder';
import { Col } from 'reactstrap';

type TrendsTabPlaceholderProps = {
  items?: number;
};

const TrendsTabPlaceholder = ({ items = 3 }: TrendsTabPlaceholderProps) => {
  return (
    <>
      {[...Array(items).keys()].map((_) => (
        <Col md={12} lg={6} className="TrendsTab__item mb-lg-4">
          <ItemPlaceholder />
        </Col>
      ))}
    </>
  );
};

export default TrendsTabPlaceholder;
