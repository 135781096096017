import EditScheduleTemplateItemModal from 'components/EditScheduleTemplateItemModal';
import { ICaseConversationSchedule } from 'interfaces/conversationSchedules';
import React from 'react';
import { useEditTimeModalFormHandler } from './hooks/useEditTimeModalFormHandler';

type CaseConversationsScheduleEditTimeModalProps = {
  isOpen: boolean;
  toggle: () => void;
  schedule: ICaseConversationSchedule;
  selectedDate: string;
};

const CaseConversationsScheduleEditTimeModal = ({
  isOpen,
  toggle,
  schedule,
  selectedDate,
}: CaseConversationsScheduleEditTimeModalProps) => {
  const formHandler = useEditTimeModalFormHandler(
    new Date(selectedDate),
    schedule,
    toggle
  );

  return (
    <EditScheduleTemplateItemModal
      isOpen={isOpen}
      toggle={toggle}
      formHandler={formHandler}
      schedule={schedule}
    />
  );
};

export default CaseConversationsScheduleEditTimeModal;
