//helpers
import useEditEmail from 'api/mutations/membership/useEditEmail';
import useUserData from 'api/queries/membership/useUserData';

//images
import emailIcon from 'assets/images/email_icon.svg';
import passwordIcon from 'assets/images/password_icon.svg';
import FieldInput from 'components/FieldInput';
import InputFieldWithImage, {
  InputFieldWithImageColor,
} from 'components/InputFieldWithImage';
import LoadingButton from 'components/LoadingButton';
import { Field, Formik } from 'formik';
import { getUserId } from 'helpers/utils/auth';

//helpers
import { isEmail } from 'helpers/utils/validators';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

//components
import TabLayout from './TabLayout';

const initialValues = {
  newEmail: '',
  password: '',
};

const ChangeEmailTab = () => {
  const intl = useIntl();
  const userId = getUserId();

  const validateForm = (values: typeof initialValues) => {
    const keys = Object.keys(initialValues);
    let errors: Partial<typeof initialValues> = {};

    keys.forEach((key) => {
      if (values[key] === '')
        errors[key] = intl.formatMessage({
          id: 'CaseCard.create.errors.inputEmpty',
        });
    });

    if (!isEmail(values.newEmail)) {
      errors.newEmail = intl.formatMessage({
        id: 'CaseCard.create.errors.emailError',
      });
    }

    return errors;
  };

  const { refetch: refetchUserData } = useUserData({
    params: { userId },
    options: { enabled: false },
  });

  const onEditSuccess = async () => {
    await refetchUserData();
  };

  const { mutate: editEmailMutation, isLoading } = useEditEmail(
    userId,
    onEditSuccess
  );

  const onSubmit = ({ newEmail, password }: typeof initialValues) => {
    editEmailMutation({
      new_email: newEmail,
      password,
    });
  };

  return (
    <TabLayout
      imageSrc={emailIcon}
      title="MyProfile.Tabs.changeEmail"
      description={
        <FormattedMessage id="MyProfile.ChangeEmail.changeYourEmailAddress" />
      }
    >
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validateForm}
      >
        {({ isValid, submitForm }) => (
          <>
            <InputFieldWithImage
              labelTranslationId="MyProfile.emailChangeModal.newEmail"
              color={InputFieldWithImageColor.gray}
              image={<img src={emailIcon} alt="email_icon" />}
              inputId="newEmail"
            >
              <Field
                required
                id="newEmail"
                name="newEmail"
                type="text"
                placeholder={intl.formatMessage({
                  id: 'MyProfile.emailChangeModal.newEmail',
                })}
                component={FieldInput}
              />
            </InputFieldWithImage>
            <InputFieldWithImage
              labelTranslationId="MyProfile.emailChangeModal.password"
              color={InputFieldWithImageColor.primary}
              image={<img src={passwordIcon} alt="password_icon" />}
              inputId="password"
            >
              <Field
                required
                id="password"
                name="password"
                type="password"
                placeholder={intl.formatMessage({
                  id: 'MyProfile.emailChangeModal.password',
                })}
                component={FieldInput}
              />
            </InputFieldWithImage>

            <div className="d-flex justify-content-end">
              <LoadingButton
                isLoading={isLoading}
                disabled={!isValid}
                type="submit"
                onClick={submitForm}
              >
                <FormattedMessage id="General.saveChanges" />
              </LoadingButton>
            </div>
          </>
        )}
      </Formik>
    </TabLayout>
  );
};

export default ChangeEmailTab;
