import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import SmsIcon from '@mui/icons-material/Sms';
import { Button, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { FormikProps } from 'formik';
import React, { useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { HelpCentreFormInitialValues } from '../../../../../../forms/HelpCentreForm/hooks/useForm';
import { HelpContactForm } from '../../../../../../types';

type ContactSelectorProps = {
  form: FormikProps<HelpCentreFormInitialValues>;
};

const ContactSelector = ({ form }: ContactSelectorProps) => {
  const intl = useIntl();

  const handleSelectContactForm = useCallback(
    (value: HelpContactForm) => () =>
      form.setValues((prev) => ({
        ...prev,
        contactForm: value,
        contactContext: '',
      })),
    [form]
  );

  const handleIconForContactForm = useCallback((value: HelpContactForm) => {
    switch (value) {
      case HelpContactForm.Email:
        return (
          <div className="d-flex align-items-center gap-3">
            <FormattedMessage id="General.Email" />
            <EmailIcon />
          </div>
        );
      case HelpContactForm.Phone:
        return (
          <div className="d-flex align-items-center gap-3">
            <FormattedMessage id="General.PhoneNumber" />
            <PhoneIcon />
          </div>
        );
      case HelpContactForm.Sms:
        return (
          <div className="d-flex align-items-center gap-3">
            <FormattedMessage id="General.Sms" />
            <SmsIcon />
          </div>
        );
    }
  }, []);

  return (
    <>
      <div className="d-flex flex-column gap-2">
        <Typography variant="body1">
          <FormattedMessage id="Help.HowWouldYouLikeUsToContactYou" />
        </Typography>
        <div className="d-flex w-100 gap-2">
          {Object.entries(HelpContactForm).map(([key, val]) => (
            <Button
              key={key}
              sx={{
                paddingTop: '0.5rem',
                paddingBottom: '0.5rem',
              }}
              name="contactForm"
              value={val}
              className="w-100"
              variant={
                form.values.contactForm === val ? 'contained' : 'outlined'
              }
              onClick={handleSelectContactForm(val)}
              color="primary"
            >
              {handleIconForContactForm(val)}
            </Button>
          ))}
        </div>
      </div>
      <div>
        <TextField
          fullWidth
          size="small"
          rows={5}
          variant="outlined"
          type={
            form.values.contactForm === HelpContactForm.Email ? 'email' : 'text'
          }
          name="contactContext"
          placeholder={intl.formatMessage({
            id:
              form.values.contactForm === HelpContactForm.Email
                ? 'Help.EnterEmail'
                : 'Help.EnterPhoneNumber',
          })}
          value={form.values.contactContext}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          error={
            form.touched.contactContext && Boolean(form.errors.contactContext)
          }
          helperText={form.touched.contactContext && form.errors.contactContext}
        />
      </div>
    </>
  );
};

export default ContactSelector;
