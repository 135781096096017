import React, { createContext } from 'react';
import { ConversationScheduleType } from '../../interfaces/conversationSchedules';
import ScheduleEmpty from './components/ScheduleEmpty';
import ScheduleHours from './components/ScheduleHours';
import ScheduleLoading from './components/ScheduleLoading';
import ScheduleRowWrapper from './components/ScheduleRowWrapper';

export interface IAvailableFromTo {
  available_from_hour: string;
  available_to_hour: string;
}

export type ScheduleItemTemplatePartsOfADayDefinition = IAvailableFromTo & {
  onEdit?: () => void;
  onResponses?: () => void;
  is_dirty: boolean;
  is_deleted: boolean;
  is_completed?: boolean;
  notification_hour: string;
  completion_percent?: number;
};

export type ScheduleItemTemplateDefinition = {
  schedule_type: ConversationScheduleType;
  parts_of_a_day: ScheduleItemTemplatePartsOfADayDefinition[];
  start_day_offset: number | null;
  end_day_offset: number | null;
  recurring_interval: number | null;
  specific_day_of_phase: number | null;
};

export type ScheduleItemDefinition = {
  rowId: string;
  sequence: number;
  templateId: string;
  label?: string;
  priority?: number;
  completionPercent?: number;
  scheduleTemplate?: ScheduleItemTemplateDefinition;
  isLoading?: boolean;
  isCompleted?: boolean;
  onEdit?: () => void;
  onRemove?: () => void;
  onAdd?: () => void;
  onResponses?: () => void;
  disabled?: boolean;
};

type ScheduleProps = {
  hours: string[];
  items: ScheduleItemDefinition[];
  selectedDateString?: string;
  isLoading?: boolean;
};

export const HoursContext = createContext<string[]>([]);

const Schedule = ({
  items,
  hours,
  selectedDateString,
  isLoading = false,
}: ScheduleProps) => {
  if (isLoading)
    return (
      <HoursContext.Provider value={hours}>
        <ScheduleLoading />
      </HoursContext.Provider>
    );

  if (items.length === 0 && !isLoading)
    return (
      <HoursContext.Provider value={hours}>
        <ScheduleEmpty />
      </HoursContext.Provider>
    );

  return (
    <HoursContext.Provider value={hours}>
      <div className="d-flex flex-column pt-5">
        <table className="Schedule">
          <ScheduleHours />
          <tbody className="Schedule__body">
            {items.map((item) => (
              <ScheduleRowWrapper
                key={`${selectedDateString}|${item.rowId}|${item.sequence}|wrapper`}
                item={item}
                selectedDateString={selectedDateString ?? ''}
              />
            ))}
          </tbody>
        </table>
      </div>
    </HoursContext.Provider>
  );
};

export default Schedule;
