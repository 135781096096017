import React, { useCallback, useMemo } from 'react';
import useMobile from '../../../../../../hooks/useBreakpoint';
import { strToDateOnly } from '../../../../../../helpers/dates';

export const TrendsChartHorizontalTick = (props: any) => {
  const isMobile = useMobile();

  const isSelected = useMemo(
    () =>
      !!props.payload?.value &&
      !!props.item &&
      strToDateOnly(props.payload.value) === strToDateOnly(props.item),
    [props.item, props.payload?.value]
  );

  const handleClick = useCallback(() => {
    if (!props.payload?.value || !props.setItem) return;
    props.setItem(props.payload.value as string);
  }, [props]);

  const rectWidth = useMemo(
    () => props.width / props.visibleTicksCount,
    [props.visibleTicksCount, props.width]
  );

  const legendColor = useMemo(
    () => (isSelected ? '#fff' : '#000'),
    [isSelected]
  );

  return (
    <g
      transform={`translate(${props.x},${props.y})`}
      onClick={handleClick}
      style={{ cursor: 'pointer', zIndex: 100 }}
    >
      {!isMobile && isSelected && !!props.width && (
        <rect
          width={rectWidth}
          height={props.height - 12}
          transform={`translate(-${rectWidth / 2},-8)`}
          rx={4}
        />
      )}
      <text
        x={0}
        y={0}
        dy={15}
        textAnchor="middle"
        fill={legendColor}
        fontSize={12}
        fontWeight={400}
      >
        {props.formatter
          ? props.formatter(props.payload?.value)
          : props.payload?.value}
      </text>
    </g>
  );
};

export const TrendsChartVerticalTick = (props: any) => {
  return (
    <text
      x={props.x}
      y={props.y + 4}
      className="AwareCasesVerticalChartTick"
      fontSize={props.fontSize ?? 12}
      fill="#000"
      textAnchor="end"
    >
      {props.formatter
        ? props.formatter(props.payload?.value)
        : props.payload?.value}
    </text>
  );
};
