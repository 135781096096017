import { getGraspSharingList } from 'api/graspManagement';
import QueryKeys from 'api/queries/queryKeys';
import { IGraspSharing } from 'interfaces/grasps';
import {
  OrganizationQueryParams,
  PagingQueryParams,
  PersonalityQueryParams,
} from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { IError } from 'interfaces/response';
import { useInfiniteQuery } from 'react-query';

type IInfiniteSharingList = {
  data: IGraspSharing[];
  nextPage: number | null;
  previousPage: number | null;
};

type UseInfiniteListSharingParams = OrganizationQueryParams &
  PersonalityQueryParams &
  PagingQueryParams;

function useInfiniteListSharing({
  params,
  options,
}: IQueryRequest<UseInfiniteListSharingParams>) {
  const { organizationId, personalityId, pageSize = 20 } = params;
  const response = useInfiniteQuery<IInfiniteSharingList, IError>({
    ...options,
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getGraspSharingList(
        organizationId,
        personalityId,
        {
          page: pageParam,
          page_size: pageSize,
        }
      );

      const responseData: IInfiniteSharingList = {
        data: data.data,
        nextPage: data.meta.pagination.next_page,
        previousPage: data.meta.pagination.previous_page,
      };

      return responseData;
    },
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage;
    },
    getPreviousPageParam: (firstPage) => firstPage.previousPage,
    queryKey: [QueryKeys.getGraspSharingList, organizationId, personalityId],
  });

  const sharing: IGraspSharing[] = [];
  if (response.data) {
    response.data.pages.forEach((page) => {
      page.data.forEach((shareData) => sharing.push(shareData));
    });
  }

  return { ...response, sharing };
}

export default useInfiniteListSharing;
