import useModifyTreatmentPhase from '../../../../../../../api/mutations/conversations/useModifyTreatmentPhase';
import useRequiredParams from '../../../../../../../hooks/useRequiredParams';
import { useTreatmentPlanCommonData } from '../../../hooks/useTreatmentPlanCommonData';
import { ManagedTreatmentPhase } from '../../../types';
import { useTreatmentPhaseCode } from './useTreatmentPhaseCode';

export const useTreatmentPhasesSequences = () => {
  const { organizationId, treatmentPlanId } = useRequiredParams<{
    organizationId: string;
    treatmentPlanId: string;
  }>(['organizationId', 'treatmentPlanId']);

  const { treatmentPhases } = useTreatmentPlanCommonData();
  const getPhaseCode = useTreatmentPhaseCode();

  const { mutateAsync: modifyAsync } = useModifyTreatmentPhase(
    organizationId,
    treatmentPlanId,
    treatmentPhases.refetch
  );

  const handleDecrease = async (phase: ManagedTreatmentPhase) => {
    await modifyAsync({
      body: {
        organization_id: organizationId,
        empty_conversation_template_id:
          phase.phase.empty_conversation_template_id,
        content: phase.phase.content,
        days_duration: phase.phase.days_duration,
        is_default: phase.phase.is_default,
        next_phase_condition: phase.phase.next_phase_condition,
        display_code: phase.phase.display_code,
        sequence: phase.phase.sequence - 1,
        code: getPhaseCode(phase.phase.id),
      },
      treatmentPhaseId: phase.phase.id,
    });
  };

  const handleIncrease = async (phase: ManagedTreatmentPhase) => {
    await modifyAsync({
      body: {
        organization_id: organizationId,
        empty_conversation_template_id:
          phase.phase.empty_conversation_template_id,
        content: phase.phase.content,
        days_duration: phase.phase.days_duration,
        is_default: phase.phase.is_default,
        next_phase_condition: phase.phase.next_phase_condition,
        display_code: phase.phase.display_code,
        sequence: phase.phase.sequence + 1,
        code: getPhaseCode(phase.phase.id),
      },
      treatmentPhaseId: phase.phase.id,
    });
  };

  return {
    handleIncrease: handleIncrease,
    handleDecrease: handleDecrease,
  };
};
