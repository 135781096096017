import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage } from 'react-intl';
import { useTranslations } from '../../../../../../../../../../../../../../../../../../hooks/useTranslations';

const ReadyToComplete = () => {
  const { attachPrefix } = useTranslations(
    'ConversationsAndFeedbacks.ConversationTemplates.ImportTemplateModal.Content.ConflictsStep.Validation.Response.Conflicts.ReadyToComplete'
  );

  return (
    <div className="card d-flex align-items-center justify-content-center flex-column border shadow-sm text-muted py-4">
      <span className="fs-2">
        <FontAwesomeIcon icon={faCheck} />
      </span>
      <FormattedMessage id={attachPrefix('readyToComplete')} />
    </div>
  );
};

export default ReadyToComplete;
