import React from 'react';
import SVG from 'react-inlinesvg';
import personIcon from '../../../../../../../../../../assets/images/person.svg';
import { useTranslations } from '../../../../../../../../../../hooks/useTranslations';
import ItemContainer from '../../../ItemContainer';
import SummaryItems from '../SummaryItems';
import BesidesMembersProvider from './components/BesidesMembersProvider';
import MembersProvider from './components/MembersProvider';

const AggregatedSummary = () => {
  const { attachPrefix, wrapWithIntl } = useTranslations(
    'Cases.CaseDetails.AwareCase.Summary'
  );

  return (
    <BesidesMembersProvider>
      {(mapping, _, questions) => (
        <MembersProvider mapping={mapping}>
          {(members) => (
            <ItemContainer
              title={wrapWithIntl(attachPrefix('summary'))}
              icon={<SVG src={personIcon} />}
            >
              <div className="h-100">
                <SummaryItems members={members} questions={questions} />
              </div>
            </ItemContainer>
          )}
        </MembersProvider>
      )}
    </BesidesMembersProvider>
  );
};

export default AggregatedSummary;
