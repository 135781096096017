import { getOdataFilterParams } from 'helpers/utils/getOdataParams';
import { normalizeQueryParams } from 'helpers/utils/normalizeQueryParams';
import { ActivityTypes, IActivity } from 'interfaces/activities';
import { IParamsTypeInRequest } from 'interfaces/reducer';
import { ODataParamType } from 'interfaces/request';
import { IResponseWithPagination } from 'interfaces/response';
import { DateRange } from 'react-day-picker';
import request from './utils/request';

export const getTimelineActivity = (
  timelineId: string,
  params: Partial<IParamsTypeInRequest>,
  oDataParams?: ODataParamType[]
) => {
  return request<never, IResponseWithPagination<IActivity[]>>({
    url: `/timelines/${timelineId}/activities${normalizeQueryParams(params)}${
      oDataParams
        ? getOdataFilterParams(oDataParams, { startCharacter: '&' })
        : ''
    }`,
    method: 'GET',
  });
};

export const getResourceActivity = (
  resourceId: string,
  params: Partial<IParamsTypeInRequest>,
  dateRange?: DateRange,
  eventTypes?: ActivityTypes[]
) => {
  let createDateParams: ODataParamType[] = [];
  if (dateRange && dateRange.from && dateRange.to) {
    createDateParams = [
      {
        key: 'CreatedAt',
        operator: 'ge',
        value: dateRange.from.toISOString(),
      },
      {
        key: 'CreatedAt',
        operator: 'lt',
        value: dateRange.to.toISOString(),
      },
    ];
  }

  const selectedEventTypesParams: ODataParamType[] = [];
  if (eventTypes) {
    eventTypes.forEach((type) =>
      selectedEventTypesParams.push({
        key: 'EventType',
        operator: 'eq',
        value: `'${type}'`,
      })
    );
  }

  const oDataTypeFilterString =
    selectedEventTypesParams.length > 0
      ? getOdataFilterParams(selectedEventTypesParams, {
          startCharacter: '&',
          logicalOperation: 'or',
          wrapInBracket: true,
        })
      : '';

  const isNotFirstFilter = selectedEventTypesParams.length > 0;
  const oDataOtherFilterString = getOdataFilterParams(createDateParams, {
    startCharacter: isNotFirstFilter ? ' and ' : '&',
    logicalOperation: 'and',
    isNotFirstFilter,
  });

  return request<never, IResponseWithPagination<IActivity[]>>({
    url: `audit_log/${resourceId}${normalizeQueryParams(
      params
    )}${oDataTypeFilterString}${oDataOtherFilterString}`,
    method: 'GET',
  });
};
