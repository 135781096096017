import SaveIcon from '@mui/icons-material/Save';
import { Skeleton } from '@mui/material';
import FieldSelect from 'components/FieldSelect';
import IconButton from 'components/IconButton';
import QueryLoaded from 'components/QueryLoaded';
import { Field, Form, Formik } from 'formik';
import useCultures from 'hooks/cultures/useCultures';
import { useOrganizationPathParams } from 'hooks/paths/useOrganizationPathParams';
import { useInitQuery } from 'hooks/queries/useInitQuery';
import { useOptions } from 'hooks/useOptions';
import { BaseModalProps } from 'interfaces/ui';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import useOrganizationUserAccessProfiles from '../../../../../../api/queries/accessProfiles/useOrganizationUserAccessProfiles';
import FieldInput from '../../../../../../components/FieldInput';
import { CreateOrganizationUserModalHelpers } from './helpers';
import { useForm } from './hooks/useForm';

type CreateOrganizationUserModalProps = BaseModalProps & {
  refetch?: () => void;
};

const CreateOrganizationUserModal = ({
  isOpen,
  toggle,
  refetch,
}: CreateOrganizationUserModalProps) => {
  const intl = useIntl();

  const { organizationId } = useOrganizationPathParams();

  const { initialValues, handleSubmit, validation } = useForm(refetch, toggle);

  const { accessProfilesOptions } = useOptions();
  const { options: culturesOptions, currentCulture } = useCultures();

  const accessProfiles = useOrganizationUserAccessProfiles({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(accessProfiles);

  return (
    <Modal
      className="GenericModal BorderlessModal"
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>
        <FormattedMessage id="Organizations.CreateOrganizationUser" />
      </ModalHeader>
      <Formik
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validateOnMount={false}
        validate={validation}
      >
        {({ isValid, submitForm, dirty }) => (
          <>
            <ModalBody>
              <Form>
                <FormGroup>
                  <Label htmlFor="first_name" for="first_name">
                    <FormattedMessage id="General.FirstName" />
                  </Label>
                  <Field
                    required={true}
                    id="first_name"
                    name="first_name"
                    type="text"
                    placeholder={intl.formatMessage({
                      id: 'General.FirstName',
                    })}
                    component={FieldInput}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="last_name" for="last_name">
                    <FormattedMessage id="General.LastName" />
                  </Label>
                  <Field
                    required={true}
                    id="last_name"
                    name="last_name"
                    type="text"
                    placeholder={intl.formatMessage({
                      id: 'General.LastName',
                    })}
                    component={FieldInput}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="email" for="email">
                    <FormattedMessage id="General.Email" />
                  </Label>
                  <Field
                    required={true}
                    id="email"
                    name="email"
                    type="email"
                    placeholder={intl.formatMessage({
                      id: 'General.Email',
                    })}
                    component={FieldInput}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="user_name" for="user_name">
                    <FormattedMessage id="General.UserName" />
                  </Label>
                  <Field
                    required={true}
                    id="user_name"
                    name="user_name"
                    type="text"
                    placeholder={intl.formatMessage({
                      id: 'General.UserName',
                    })}
                    component={FieldInput}
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="access_profile_id" for="access_profile_id">
                    <FormattedMessage id="Organizations.AccessProfile" />
                  </Label>
                  <QueryLoaded
                    query={accessProfiles}
                    onLoading={<Skeleton height={60} />}
                  >
                    {(_) => (
                      <Field
                        required
                        id="access_profile_id"
                        name="access_profile_id"
                        type="select"
                        defaultText={intl.formatMessage({
                          id: 'Organizations.SelectAccessProfile',
                        })}
                        component={FieldSelect}
                        options={accessProfilesOptions(accessProfiles)}
                      />
                    )}
                  </QueryLoaded>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="culture" for="culture">
                    <FormattedMessage id="General.Culture" />
                  </Label>
                  <Field
                    required
                    id="culture"
                    name="culture"
                    type="select"
                    defaultText={intl.formatMessage({
                      id: 'General.SelectCulture',
                    })}
                    component={FieldSelect}
                    options={CreateOrganizationUserModalHelpers.SortCulturesOptions(
                      culturesOptions,
                      currentCulture.cultureCode
                    )}
                  />
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <IconButton
                color="primary"
                disabled={!isValid || !dirty}
                variant="outlined"
                onClick={submitForm}
              >
                <SaveIcon />
              </IconButton>
            </ModalFooter>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default CreateOrganizationUserModal;
