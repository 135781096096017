import { useCallback, useMemo } from 'react';
import { useLedLevelFractions } from '../../../components/GraspLedLevelsList/components/GraspLedLevelListItem/hooks/useLedLevelFractions';
import { IAsyncFormHookValues } from 'interfaces/forms';
import { InputOption } from 'interfaces/ui';
import { ICreateGraspLedLevel, IGraspLedLevel } from 'interfaces/grasps';
import { useCommonParams } from 'modules/Organizations/views/OrganizationDetailsView/hooks/useCommonParams';
import { useCommonData } from 'modules/Organizations/views/OrganizationDetailsView/hooks/useCommonData';
import { useInitQuery } from 'hooks/queries/useInitQuery';
import useAddGraspLedLevel from 'api/mutations/grasps/useAddGraspLedLevel';
import usePatchGraspLedLevel, {
  usePatchGraspLedLevelBody,
} from 'api/mutations/grasps/usePatchGraspLedLevel';
import { FormikErrors } from 'formik';

export interface IAddGraspSettingsModalFormInitialValues {
  organization_id: string;
  red_on: boolean;
  green_on: boolean;
  blue_on: boolean;
  amplitude_percentage: number;
}

interface IAddGraspSettingsModalForm
  extends IAsyncFormHookValues<IAddGraspSettingsModalFormInitialValues> {
  organizationOptions: InputOption[];
}

export const useAddGraspSettingsModalForm = (
  onSuccess: () => void,
  modified?: IGraspLedLevel
): IAddGraspSettingsModalForm => {
  const { organizationId } = useCommonParams();

  const { organizationDetails } = useCommonData();
  useInitQuery(organizationDetails);

  const handleSuccess = () => {
    onSuccess();
  };

  const { mutateAsync: createAsync } = useAddGraspLedLevel(
    {
      organizationId,
    },
    {
      successFb: handleSuccess,
    }
  );

  const { mutateAsync: updateAsync } = usePatchGraspLedLevel(
    {
      organizationId,
    },
    {
      successFb: handleSuccess,
    }
  );

  const fractionEnabled = useLedLevelFractions();

  const prepareInitialValues =
    useMemo((): IAddGraspSettingsModalFormInitialValues => {
      if (modified) {
        return {
          organization_id: modified.organization_id,
          amplitude_percentage: modified.amplitude_percentage,
          red_on: fractionEnabled(modified.color, 'r'),
          green_on: fractionEnabled(modified.color, 'g'),
          blue_on: fractionEnabled(modified.color, 'b'),
        };
      }

      return {
        organization_id: organizationId,
        red_on: false,
        green_on: false,
        blue_on: false,
        amplitude_percentage: 0,
      };
    }, [fractionEnabled, modified, organizationId]);

  const prepareCreateBody = useCallback(
    (values: IAddGraspSettingsModalFormInitialValues): ICreateGraspLedLevel => {
      let color: string = '#';

      color += values.red_on ? 'ff' : '00';
      color += values.green_on ? 'ff' : '00';
      color += values.blue_on ? 'ff' : '00';

      return {
        organization_id: values.organization_id,
        amplitude_percentage: values.amplitude_percentage,
        color: color,
      };
    },
    []
  );

  const prepareUpdateBody = useCallback(
    (
      id: string,
      values: IAddGraspSettingsModalFormInitialValues
    ): usePatchGraspLedLevelBody => {
      let color: string = '#';

      color += values.red_on ? 'ff' : '00';
      color += values.green_on ? 'ff' : '00';
      color += values.blue_on ? 'ff' : '00';

      return {
        id: id,
        patchData: [
          {
            op: 'replace',
            path: '/amplitude_percentage',
            value: values.amplitude_percentage,
          },
          {
            op: 'replace',
            path: '/color',
            value: color,
          },
        ],
      };
    },
    []
  );

  const handleSubmit = useCallback(
    async (values: IAddGraspSettingsModalFormInitialValues) => {
      if (!modified) {
        await createAsync(prepareCreateBody(values));
        return;
      }

      await updateAsync(prepareUpdateBody(modified.id, values));
    },
    [createAsync, modified, prepareCreateBody, prepareUpdateBody, updateAsync]
  );

  const validation = useCallback(
    (
      values: IAddGraspSettingsModalFormInitialValues
    ): FormikErrors<IAddGraspSettingsModalFormInitialValues> => {
      if (values.amplitude_percentage < 0 || values.amplitude_percentage > 100)
        return { [values.amplitude_percentage]: 'invalid' };

      return {};
    },
    []
  );

  const organizationOptions = useMemo((): InputOption[] => {
    if (!organizationDetails.data) return [];

    return [
      {
        text: organizationDetails.data.name,
        value: organizationId,
      },
    ];
  }, [organizationDetails.data, organizationId]);

  return {
    initialValues: prepareInitialValues,
    handleSubmit: handleSubmit,
    validation: validation,
    organizationOptions: organizationOptions,
  };
};
