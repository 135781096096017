export const textWrapEllipse = (text: string, count: number) => {
  if (text.length < count) return text;
  return `${text.substring(0, count)}...`;
};

export const isValidEmail = (email: string) => {
  return (
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.exec(
      email.toLowerCase()
    ) !== null
  );
};

export const isValidJson = (text: any) => {
  if (typeof text !== 'string') return false;

  try {
    const result = JSON.parse(text);
    const type = Object.prototype.toString.call(result);
    return type === '[object Object]' || type === '[object Array]';
  } catch (err) {
    return false;
  }
};

export const getSnakeCase = (str: string) =>
  str[0].toLowerCase() +
  str
    .slice(1, str.length)
    .replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);
