import { updateUserGrasp } from 'api/graspManagement';
import { IPatchObject } from 'interfaces/request';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

type useUserGraspUpdateProps = {
  userId: string;
  graspId: string;
  successFb?: () => void;
};

function useUserGraspUpdate({
  userId,
  graspId,
  successFb,
}: useUserGraspUpdateProps) {
  return useMutationWithResultNotification({
    asyncApiFunction: async (patchData: IPatchObject[]) => {
      await updateUserGrasp(userId, graspId, patchData);
    },
    resetOnResult: true,
    onSuccess: successFb,
  });
}

export default useUserGraspUpdate;
