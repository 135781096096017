import { getUserPersonalData } from 'api/patients';
import QueryKeys from 'api/queries/queryKeys';
import { getOrganizations, setOrganizations } from 'helpers/utils/auth';
import { UserQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { useQuery } from 'react-query';

function useUserData({ params, options }: IQueryRequest<UserQueryParams>) {
  const { userId } = params;

  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getUserPersonalData(userId);

      const organizationsInStorage = getOrganizations();

      if (!organizationsInStorage && data.data.organizations) {
        setOrganizations(data.data.organizations);
      }

      return data.data;
    },
    queryKey: [QueryKeys.getUserPersonalData, userId],
  });
}

export default useUserData;
