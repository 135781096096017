import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import useCaseContextTimelineObservations from '../../../../../../../api/queries/cases/useCaseContextTimelineObservations';
import { endOfDay, format, startOfDay } from 'date-fns';
import SVG from 'react-inlinesvg';
import { useObservationsGroupedByHour } from '../hooks/useObservationsGroupedByHour';

import graspSmallBlue from 'assets/images/grasp_small_blue.svg';
import bicepIcon from 'assets/images/buddy_case/bicep_icon.svg';
import { IObservation } from '../../../../../../../interfaces/timeline';

type BuddyCaseDaySummaryContentProps = {
  organizationId: string;
  caseId: string;
  timelineId: string;
  selectedDate: Date;
};

type BuddyCaseDaySummaryContentItemProps = {
  icon: JSX.Element;
  headerTranslationId: string;
  value: string;
};

const BuddyCaseDaySummaryContent = ({
  organizationId,
  caseId,
  timelineId,
  selectedDate,
}: BuddyCaseDaySummaryContentProps) => {
  const observationsDateRange = useMemo(
    () => ({ from: startOfDay(selectedDate), to: endOfDay(selectedDate) }),
    [selectedDate]
  );

  const queryEnabled = observationsDateRange.from <= new Date();
  const { data: observationsData } = useCaseContextTimelineObservations({
    params: {
      organizationId,
      caseId,
      timelineId,
      selectedDates: observationsDateRange,
      grouped: false,
    },
    options: { enabled: queryEnabled },
  });

  const observations = observationsData ?? [];

  observations.sort(localeAscTimestampComparing);
  const firstObservation = observations[0];
  const lastObservation = observations[observations.length - 1];

  const firstObservationHour = observationHour(firstObservation);
  const lastObservationHour = observationHour(lastObservation);

  const { mostActiveHour } = useObservationsGroupedByHour(observations);

  return (
    <div className="BuddyCaseDaySummaryContent">
      <BuddyCaseDaySummaryContentItem
        icon={<SVG src={graspSmallBlue} />}
        headerTranslationId="BuddyCase.firstSqueeze"
        value={firstObservationHour}
      />
      <BuddyCaseDaySummaryContentItem
        icon={<SVG src={graspSmallBlue} />}
        headerTranslationId="BuddyCase.lastSqueeze"
        value={lastObservationHour}
      />
      <BuddyCaseDaySummaryContentItem
        icon={<SVG src={bicepIcon} />}
        headerTranslationId="BuddyCase.mostActiveTime"
        value={mostActiveHour}
      />
    </div>
  );
};

const localeAscTimestampComparing = (a: IObservation, b: IObservation) =>
  a.timestamp.localeCompare(b.timestamp);

const observationHour = (obs: IObservation): string =>
  obs ? format(Date.parse(obs.timestamp), 'HH:mm') : '--:--';

const BuddyCaseDaySummaryContentItem = ({
  icon,
  headerTranslationId,
  value,
}: BuddyCaseDaySummaryContentItemProps) => {
  return (
    <div className="BuddyCaseDaySummaryContentItem">
      <div className="d-flex flex-column align-items-start">
        <div className="BuddyCaseDaySummaryContentItem__header">
          <div>{icon}</div>
          <span>
            <FormattedMessage id={headerTranslationId} />
          </span>
        </div>
        <div className="BuddyCaseDaySummaryContentItem__value">{value}</div>
      </div>
    </div>
  );
};

export default BuddyCaseDaySummaryContent;
