import useGraspDetails from 'api/queries/grasps/useGraspDetails';
import GraspDetailsInformation from './components/GraspDetailsInformation';
import DetailsHeader from 'components/DetailsHeader';
import GraspLogo from 'components/GraspLogo';
import { Col, Row } from 'reactstrap';
import ShareHistory from './components/ShareHistory';
import { Size } from 'interfaces/ui';
import useRequiredParams from 'hooks/useRequiredParams';
import PageHeader from '../../../../components/PageHeader';
import { BreadcrumbItemType } from '../../../../components/PageHeader/Breadcrumbs';
import QueryLoaded from '../../../../components/QueryLoaded';
import { useInitQuery } from '../../../../hooks/queries/useInitQuery';
import GraspErrorPlaceholder from './components/GraspErrorPlaceholder';

const GraspDetails = () => {
  const { organizationId, graspId } = useRequiredParams<{
    organizationId: string;
    graspId: string;
  }>(['organizationId', 'graspId']);

  const graspDetails = useGraspDetails({
    params: { organizationId, graspId },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(graspDetails);

  const breadcrumbs: BreadcrumbItemType[] = [
    {
      key: 'grasps',
      text: `Grasps`,
      link: `/organizations/${organizationId}/grasps`,
    },
    {
      key: 'grasp',
      text: graspDetails.data?.name || '',
      link: null,
    },
  ];

  return (
    <PageHeader
      isLoading={graspDetails.isLoading}
      breadcrumbs={breadcrumbs}
      content={
        <QueryLoaded
          query={graspDetails}
          onError={(error) => <GraspErrorPlaceholder error={error} />}
        >
          {(grasp) => (
            <>
              <div className="d-flex align-items-center">
                <GraspLogo
                  size={Size.MEDIUM}
                  color={grasp?.hex_color}
                  className="me-4"
                />
                <DetailsHeader
                  editButtons={{
                    edit: false,
                    delete: false,
                    editAndLock: false,
                  }}
                >
                  {grasp?.name}
                </DetailsHeader>
              </div>
              <Row>
                <Col md={4}>
                  <GraspDetailsInformation data={grasp} />
                </Col>
                <Col md={8}>
                  <ShareHistory />
                </Col>
              </Row>
            </>
          )}
        </QueryLoaded>
      }
    />
  );
};

export default GraspDetails;
