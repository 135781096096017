import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import { addTrendVisualizationTemplate } from 'api/trendVisualizations';
import { TrendVisualizationTemplate } from 'interfaces/trendVisualizations';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';

const useAddTrendVisualizationTemplate = (
  organizationId: string,
  successFb?: () => void
) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (data: TrendVisualizationTemplate) => {
      const template = await addTrendVisualizationTemplate(
        organizationId,
        data
      );
      return template.data;
    },
    resetOnResult: true,
    onSuccess: () => {
      displaySuccessNotification(
        'TimelineDetails.TrendsTimeline.SaveTrendsChartFilter.savingSuccessful'
      );
      successFb && successFb();
    },
    onErrorArray: (errors) => {
      displayErrorArrayNotifications(
        errors,
        'TimelineDetails.TrendsTimeline.SaveTrendsChartFilter.savingError'
      );
    },
  });
};

export default useAddTrendVisualizationTemplate;
