import React from 'react';
import InputFieldWithoutImage from '../../../InputFieldWithoutImage';
import { Field } from 'formik';
import FieldInput from '../../../FieldInput';
import { Button } from 'reactstrap';
import SVG from 'react-inlinesvg';

import trashIcon from 'assets/images/trash.svg';

type AddScheduleTemplatePartOfADayFieldsProps = {
  index: number;
  onRemove?: () => void;
};

const AddScheduleTemplatePartOfADayFields = ({
  index,
  onRemove,
}: AddScheduleTemplatePartOfADayFieldsProps) => {
  return (
    <>
      <InputFieldWithoutImage
        gray={true}
        block={true}
        noMargin={true}
        labelTranslationId={
          index > 0
            ? undefined
            : 'Projects.ProjectPhases.ProjectPhasesContent.ProjectPhase.ProjectPhaseAddTemplateModal.timeFrom'
        }
        inputId="time_from"
      >
        <Field
          id="time_from"
          name={`schedule_templates[0].parts_of_a_day[${index}].available_from_hour`}
          type="time"
          required={true}
          component={FieldInput}
        />
      </InputFieldWithoutImage>
      <InputFieldWithoutImage
        gray={true}
        block={true}
        noMargin={true}
        labelTranslationId={
          index > 0
            ? undefined
            : 'Projects.ProjectPhases.ProjectPhasesContent.ProjectPhase.ProjectPhaseAddTemplateModal.timeTo'
        }
        inputId="timeTo"
      >
        <Field
          id="timeTo"
          name={`schedule_templates[0].parts_of_a_day[${index}].available_to_hour`}
          type="time"
          required={true}
          component={FieldInput}
        />
      </InputFieldWithoutImage>
      <InputFieldWithoutImage
        gray={true}
        block={true}
        noMargin={true}
        labelTranslationId={
          index > 0
            ? undefined
            : 'Projects.ProjectPhases.ProjectPhasesContent.ProjectPhase.ProjectPhaseAddTemplateModal.notificationTime'
        }
        inputId="notificationTime"
      >
        <Field
          id="notificationTime"
          name={`schedule_templates[0].parts_of_a_day[${index}].notification_hour`}
          type="time"
          required={true}
          component={FieldInput}
        />
      </InputFieldWithoutImage>
      {onRemove && (
        <Button
          outline={true}
          color="secondary"
          onClick={onRemove}
          className="align-self-end"
        >
          <SVG src={trashIcon} title="delete" />
        </Button>
      )}
    </>
  );
};

export default AddScheduleTemplatePartOfADayFields;
