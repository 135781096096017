export type QueryParamsArrayItem = {
  [name: string]: any;
};

export const normalizeQueryParams = (options: QueryParamsArrayItem) => {
  let queryParams = '';

  for (const queryName in options) {
    const value = options[queryName];
    if (value !== '' && value !== null && value !== undefined) {
      const querySeparator = queryParams.length > 0 ? '&' : '?';
      queryParams += `${querySeparator}${queryName}=${value}`;
    }
  }

  return queryParams;
};

export const normalizeQueryParamsArray = (options: QueryParamsArrayItem[]) => {
  let queryParams = '';

  options.forEach((option) => {
    Object.entries(option).forEach(([key, value]) => {
      if (value !== '' && value !== null && value !== undefined) {
        const querySeparator = queryParams.length > 0 ? '&' : '?';
        queryParams += `${querySeparator}${key}=${value}`;
      }
    });
  });

  return queryParams;
};

export const arrayToQueryParamsArrayItems = (
  options: string[],
  queryName: string
): QueryParamsArrayItem[] => {
  let queryParamsOptions: QueryParamsArrayItem[] = [];

  options.forEach((option) => {
    let arrayItem: QueryParamsArrayItem = {};
    arrayItem[queryName] = option;
    queryParamsOptions.push(arrayItem);
  });

  return queryParamsOptions;
};
