import React, { useMemo } from 'react';
import CustomBarChart from 'components/CustomBarChart';
import CustomPieChart from 'components/CustomPieChart';
import { IObservation, ObservationSymptom } from 'interfaces/timeline';
import { useIntl } from 'react-intl';
import { meaningColors } from 'helpers/colors';

type MeaningSummaryProps = {
  observations: IObservation[] | undefined;
  meaningNames: ObservationSymptom[];
  width: number;
  height: number;
  innerRadius: number;
  summaryText: string;
};

const SIZE_OF_BAR = 20;

const MeaningSummary = ({
  observations,
  meaningNames,
  summaryText,
  ...chartProps
}: MeaningSummaryProps) => {
  const intl = useIntl();

  const formattedData = useMemo(() => {
    let flattenObservations: IObservation[] = [];
    let object = {};

    observations?.forEach((observation) => {
      flattenObservations.push(observation);
      flattenObservations.push(...observation.children_observations);
    });

    meaningNames.forEach((element) => {
      object[element] = 0;
    });

    flattenObservations?.forEach((element) => {
      const existingValue = object[element.symptom] || 0;

      object[element.symptom] = existingValue + 1;
    });

    const items = Object.keys(object).map((key) => {
      return {
        key,
        value: object[key],
      };
    });

    items.sort((prev, next) => {
      if (prev.value < next.value) return 1;
      if (prev.value > next.value) return -1;
      else return 0;
    });

    const colors = items.map(({ key }) => meaningColors[key]);

    const itemsWithCorrectName = items.map(({ key, value }) => {
      return {
        name: intl.formatMessage({ id: `TimelineDetails.meanings.${key}` }),
        value,
      };
    });

    return {
      total: items.reduce<number>((accumulator, currentValue) => {
        return accumulator + currentValue.value;
      }, 0),
      items: itemsWithCorrectName,
      colors,
    };
  }, [observations, intl, meaningNames]);

  const heightOfBarChart = SIZE_OF_BAR * meaningNames.length;

  return (
    <div className="mt-1 w-100 d-flex flex-wrap align-items-center justify-content-around">
      <CustomPieChart
        {...formattedData}
        marginForCircle={10}
        totalText={summaryText}
        {...chartProps}
        displayInside
      />
      <CustomBarChart
        {...formattedData}
        width={250}
        height={heightOfBarChart}
        sortByBiggest
      />
    </div>
  );
};

export default MeaningSummary;
