import { createNewReportExecution } from 'api/reports';
import { ICreateReportExecutionBody } from 'interfaces/reports';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

type useCreateReportExecutionProps = {
  successFb?: () => void;
};

const useCreateReportExecution = ({
  successFb,
}: useCreateReportExecutionProps) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (data: ICreateReportExecutionBody) => {
      await createNewReportExecution(data);
    },
    resetOnResult: true,
    onSuccess: () => {
      displaySuccessNotification('Reports.CreateReport.success');
      successFb && successFb();
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(errorArray, 'Reports.CreateReport.error');
    },
  });
};

export default useCreateReportExecution;
