import { getUserContextAssessmentFormsForDate } from 'api/graspManagement';
import QueryKeys from 'api/queries/queryKeys';
import { hourSort } from 'helpers/utils/assessmentForms';
import {
  TimelineDateQueryParams,
  UserQueryParams,
} from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { useQuery } from 'react-query';

type UseUserContextGroupedAssessmentFormsForDateParams =
  TimelineDateQueryParams & Partial<UserQueryParams>;

function useUserContextGroupedAssessmentFormsForDate({
  params,
  options,
}: IQueryRequest<UseUserContextGroupedAssessmentFormsForDateParams>) {
  const { userId, timelineId, selectedDate } = params;
  const { enabled, ...otherOptions } = options ?? { enabled: true };

  const year = selectedDate.getFullYear();
  const month = selectedDate.getMonth() + 1;
  const day = selectedDate.getDate();

  const isEnabled = enabled && !!userId && !!timelineId;

  return useQuery({
    ...otherOptions,
    enabled: isEnabled,
    queryKey: [
      QueryKeys.getGroupedUserAssessments,
      userId,
      timelineId,
      year,
      month,
      day,
    ],
    queryFn: async () => {
      const { data: responseData } = await getUserContextAssessmentFormsForDate(
        userId ?? '',
        timelineId,
        year,
        month,
        day
      );

      const sortedArray = [...responseData?.data];
      sortedArray.sort(hourSort);

      return sortedArray;
    },
  });
}

export default useUserContextGroupedAssessmentFormsForDate;
