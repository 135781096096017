import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { IMutationRequest, IPatchObject } from '../../../interfaces/request';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { OrganizationQueryParams } from '../../../interfaces/queryParams';
import { patchAsset } from '../../assets';

type PatchAssetQueryParams = OrganizationQueryParams & {
  assetId: string;
};

const usePatchAsset = ({
  params,
  options,
}: IMutationRequest<PatchAssetQueryParams>) => {
  return useMutationWithResultNotification<IPatchObject[], void>({
    asyncApiFunction: async (patchData: IPatchObject[]) => {
      await patchAsset(params.organizationId, params.assetId, patchData);
    },
    resetOnResult: true,
    onError: () => {
      displayErrorNotification('Api.Mutations.Assets.PatchAsset.failure');
      options.errorFb?.();
    },
    onSuccess: () => {
      displaySuccessNotification('Api.Mutations.Assets.PatchAsset.success');
      options.successFb?.();
    },
  });
};

export default usePatchAsset;
