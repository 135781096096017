import { activityOperations, EntityUpdateType } from 'interfaces/activities';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ListItemLayout from './ListItemLayout';

import sharingIcon from 'assets/images/timeline_details/sharing.svg';

type EntityUpdatedItemProps = {
  activityData: EntityUpdateType;
  timestamp: string;
};

const notificationTypeTranslationDefinition = {
  User: 'UserDashboard.Activity.theUser',
  Timeline: 'UserDashboard.Activity.theTimeline',
};

const EntityUpdatedItem = ({
  activityData,
  timestamp,
}: EntityUpdatedItemProps) => {
  const { entity_name, entity_type, operation } = activityData;
  const entityTypeText = (
    <FormattedMessage id={notificationTypeTranslationDefinition[entity_type]} />
  );

  //TODO: REPLACE ICONS
  switch (operation) {
    case activityOperations.DELETED:
      return (
        <ListItemLayout
          imageSrc={sharingIcon}
          timeStart={timestamp}
          textContent={
            <>
              {entityTypeText} <b>{entity_name}</b>{' '}
              <FormattedMessage id="UserDashboard.Activity.hasBeenDeleted" />
            </>
          }
        />
      );
    case activityOperations.ADDED:
      return (
        <ListItemLayout
          imageSrc={sharingIcon}
          timeStart={timestamp}
          textContent={
            <>
              {entityTypeText} <b>{entity_name}</b>{' '}
              <FormattedMessage id="UserDashboard.Activity.hasBeenCreated" />
            </>
          }
        />
      );
    case activityOperations.MODIFIED:
      return (
        <ListItemLayout
          imageSrc={sharingIcon}
          timeStart={timestamp}
          textContent={
            <>
              {entityTypeText} <b>{entity_name}</b>{' '}
              <FormattedMessage id="UserDashboard.Activity.hasBeenModified" />
            </>
          }
        />
      );
    default:
      return null;
  }
};

export default EntityUpdatedItem;
