import React, { useContext } from 'react';
import {
  ComposedChart,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  ChartDataContext,
  ChartDownloadContext,
  TrendsChartAxisConfigItem,
  TrendsChartData,
  TrendsChartFormatter,
} from 'components/TrendsChart/types';
import { ChartDownloadHookValues } from '../../hooks/useTrendsChartDownload';
import TrendsChartCursor from './components/TrendsChartCursor';
import { useRenderItems } from './hooks/useRenderItems';
import { useSelectItems } from './hooks/useSelectItems';
import { useTooltipContent } from './hooks/useTooltipContent';
import { useLegendContent } from './hooks/useLegendContent';
import { useAxisTicks } from './hooks/useAxisTicks';
import useMobile from '../../../../hooks/useBreakpoint';
import classNames from 'classnames';

type TrendsChartFigureProps = {
  formatters: TrendsChartFormatter[];
  xAxisConfig?: TrendsChartAxisConfigItem;
  yAxisConfig?: TrendsChartAxisConfigItem;
};

const TrendsChartFigure = ({
  formatters,
  xAxisConfig,
  yAxisConfig,
}: TrendsChartFigureProps) => {
  const isMobile = useMobile();

  const { data } = useContext(ChartDataContext) as TrendsChartData<
    string | number
  >;

  const { chartRef } = useContext(
    ChartDownloadContext
  ) as ChartDownloadHookValues;

  const tooltipContent = useTooltipContent(formatters);
  const selection = useSelectItems(data);

  const legendContent = useLegendContent(formatters, selection);

  const renderItems = useRenderItems(selection);

  const { yAxisTick, xAxisTick } = useAxisTicks(
    formatters,
    xAxisConfig,
    yAxisConfig
  );

  return (
    <div
      className={classNames('TrendsChartFigure', {
        'h-px-400': !isMobile,
        'h-px-300': isMobile,
      })}
      style={{
        position: 'relative',
      }}
    >
      <ResponsiveContainer width={'100%'} height={'100%'}>
        <ComposedChart data={data} ref={chartRef}>
          <YAxis
            axisLine={false}
            orientation="left"
            minTickGap={0}
            tick={yAxisTick}
            width={isMobile ? 25 : 40}
          />
          <XAxis
            axisLine={true}
            padding={{ left: isMobile ? 10 : 40, right: isMobile ? 10 : 40 }}
            height={50}
            dataKey="name"
            tick={xAxisTick}
            interval={xAxisConfig?.interval ?? 0}
          />
          {renderItems}
          <Tooltip content={tooltipContent} cursor={<TrendsChartCursor />} />
          <Legend content={legendContent} />
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default TrendsChartFigure;
