import React from 'react';
import {
  BaseModalProps,
  ViewTheme,
} from '../../../../../../../../interfaces/ui';
import { FormikProps } from 'formik';
import { AwareCaseDetailsFormValues } from '../../forms/AwareCaseDetailsForm/types';
import { Modal, ModalBody } from 'reactstrap';
import classNames from 'classnames';
import { useCookie } from '../../../../../../../../hooks/useCookie';
import { FormattedMessage } from 'react-intl';
import CaseProceduresProvider from '../../providers/CaseProceduresProvider';
import ProcedureRelatedFields from './components/ProcedureRelatedFields';

type ManageActivityModalProps = BaseModalProps & {
  procedureIndex: number;
  form: FormikProps<AwareCaseDetailsFormValues>;
};

const ManageProcedureModal = ({
  procedureIndex,
  form,
  toggle,
  isOpen,
}: ManageActivityModalProps) => {
  const themeCookie = useCookie<ViewTheme>('theme');

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      className={classNames('GenericModal BorderlessModal', {
        'GenericModal--dark': themeCookie === ViewTheme.Dark,
      })}
    >
      <ModalBody className="d-flex flex-column gap-3">
        <div className="flex items-center">
          <div
            className={classNames('w-full h-px', {
              'bg-black': themeCookie !== ViewTheme.Dark,
              'bg-white': themeCookie === ViewTheme.Dark,
            })}
          />
          <span className="px-1">
            <FormattedMessage id="Cases.Procedure" />
          </span>
          <div
            className={classNames('w-full h-px', {
              'bg-black': themeCookie !== ViewTheme.Dark,
              'bg-white': themeCookie === ViewTheme.Dark,
            })}
          />
        </div>
        <div>
          <CaseProceduresProvider>
            {(procedures) => (
              <ProcedureRelatedFields
                procedures={procedures}
                form={form}
                procedureIndex={procedureIndex}
                toggle={toggle}
              />
            )}
          </CaseProceduresProvider>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ManageProcedureModal;
