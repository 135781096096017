import { useCommonData } from './hooks/useCommonData';
import { useInitQuery } from 'hooks/queries/useInitQuery';
import QueryLoaded from '../../../../components/QueryLoaded';
import { OrganizationDetailsContext } from './types';
import Placeholders from '../../../../components/Placeholders';
import OrganizationDetails from './components/OrganizationDetails';
import PageHeader from '../../../../components/PageHeader';
import {
  BreadcrumbsHelpers,
  BreadcrumbsType,
} from '../../../../helpers/breadcrumbs';
import { useIntl } from 'react-intl';

const OrganizationDetailsView = () => {
  const intl = useIntl();

  const { organizationDetails } = useCommonData();
  useInitQuery(organizationDetails);

  const getBreadcrumbs = BreadcrumbsHelpers.GetBreadcrumbs(
    intl,
    BreadcrumbsType.OrganizationDetails
  );

  return (
    <QueryLoaded
      query={organizationDetails}
      onLoading={<Placeholders.Breadcrumbs count={3} />}
    >
      {(organization) => (
        <OrganizationDetailsContext.Provider value={organization}>
          <PageHeader
            breadcrumbs={getBreadcrumbs(organization)}
            content={<OrganizationDetails type={organization.type} />}
          />
        </OrganizationDetailsContext.Provider>
      )}
    </QueryLoaded>
  );
};

export default OrganizationDetailsView;
