import React from 'react';
import FieldInput from '../../../../../../../../../../components/FieldInput';
import { Field } from 'formik';
import { useTranslations } from '../../hooks/useTranslations';
import LoadingButton from '../../../../../../../../../../components/LoadingButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldCheck } from '@fortawesome/pro-light-svg-icons';
import { ValidConversationTemplateItem } from '../../../../../../../../../../interfaces/conversations';
import { useValidation } from './hooks/useValidation';
import { useTypedContext } from '../../../../../../../../../../hooks/useTypedContext';
import { FormikUtilsContext } from '../../../../types';

type ConditionItemEditProps = {
  index: number;
  item: ValidConversationTemplateItem;
};

const ConditionItemEdit = ({ index, item }: ConditionItemEditProps) => {
  const { providerInvariant } = useTranslations();

  const formikUtils = useTypedContext(FormikUtilsContext);

  const { validate, validating } = useValidation(index, item);

  return (
    <div className="d-flex align-items-center justify-content-between gap-1">
      <div className="w-100">
        <Field
          required={true}
          placeholder={providerInvariant.wrapWithIntl(
            providerInvariant.attachPrefix('conditionPlaceholder')
          )}
          label={providerInvariant.wrapWithIntl(
            providerInvariant.attachPrefix('condition')
          )}
          id={`content[${index}].condition`}
          name={`content[${index}].condition`}
          type="text"
          component={FieldInput}
        />
      </div>
      <LoadingButton
        disabled={!formikUtils.touched?.content?.at(index)?.condition}
        isLoading={validating}
        onClick={validate}
        outline={true}
        className="align-self-end"
        color="primary"
      >
        <FontAwesomeIcon icon={faShieldCheck} />
      </LoadingButton>
    </div>
  );
};

export default ConditionItemEdit;
