import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import DashboardCard from './DashboardCard';

//icons
import graspIcon from 'assets/images/timeline_details/grasp_icon.svg';
import bubbles from 'assets/images/timeline_details/bubbles.svg';

type TimelineElementProps = {
  name: string;
  firstActivity?: string | null;
  lastUpdateDate?: string | null;
  id: string;
  type?: string | null;
  alternativeLink?: string;
  description?: string;
  isDefault?: boolean;
  isMaster?: boolean;
  isShared?: boolean;
  isClickable?: boolean;
};

const TimelineElement = ({
  name,
  type = 'Generic',
  lastUpdateDate = null,
  firstActivity = null,
  id,
  alternativeLink,
  isShared,
  description,
  isClickable = true,
}: TimelineElementProps) => {
  const timeInformation = [
    {
      haveBorder: true,
      dateValue: firstActivity,
      label: <FormattedMessage id="TimelineDetails.firstObservation" />,
      iconSrc: graspIcon,
    },
    {
      haveBorder: true,
      dateValue: lastUpdateDate,
      label: <FormattedMessage id="TimelineDetails.lastObservation" />,
      iconSrc: graspIcon,
    },
  ];

  const timelineElement = (
    <DashboardCard
      cardType="timeline"
      description={description}
      title={name}
      isShared={isShared}
      timelineType={type}
      svgIconSrc={bubbles}
      timeInformation={timeInformation}
    />
  );

  if (!isClickable) return timelineElement;
  return (
    <Link to={alternativeLink ? alternativeLink : `/timelines/${id}`}>
      {timelineElement}
    </Link>
  );
};

export default TimelineElement;
