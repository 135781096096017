import { UseQueryResult } from 'react-query';
import { useCallback } from 'react';

export const useQueriesRefetch = (queries: UseQueryResult<any>[]) => {
  return useCallback(async () => {
    for (const query of queries) {
      await query.refetch();
    }
  }, [queries]);
};
