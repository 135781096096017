import { add, addDays, differenceInHours, sub } from 'date-fns';
import { AssessmentFormIdentification } from 'interfaces/assessmentForms';
import { Granularity, TimeRange } from 'interfaces/timeline';
import {
  SelectionTrendVisualizationTemplateInfo,
  TrendVisualizationTemplate,
} from 'interfaces/trendVisualizations';
import { TimelineTabsValues } from 'interfaces/ui';
import { DateRange } from 'react-day-picker';

const HOURS_IN_DAY = 24;

const timeRangeDaysDefinition = {
  [TimeRange.DAYS_3]: 2,
  [TimeRange.DAYS_7]: 6,
  [TimeRange.DAYS_30]: 29,
  [TimeRange.MONTHS_3]: 89,
  [TimeRange.MONTHS_6]: 181,
  [TimeRange.YEAR]: 364,
  [TimeRange.YEARS_3]: 1094,
};

const granularityTimeRangeMapping = {
  [TimeRange.HOURS_24]: Granularity.h1,
  [TimeRange.DAYS_3]: Granularity.h2,
  [TimeRange.DAYS_7]: Granularity.h4,
  [TimeRange.DAYS_30]: Granularity.d1,
  [TimeRange.MONTHS_3]: Granularity.d1,
  [TimeRange.MONTHS_6]: Granularity.d7,
  [TimeRange.YEAR]: Granularity.d7,
  [TimeRange.YEARS_3]: Granularity.m1,
};

type timelineDetailsStateType = {
  timeRange: TimeRange | null;
  dateRangeObject: DateRange | null;
  granularity: Granularity | null;
  timePeriod: TimeRange | null;
  tab: TimelineTabsValues;
  selectedDate: Date | null;
  tabsDisabled: boolean;
  assessmentFormIdentification: AssessmentFormIdentification | null;
  dayByDayFullSize: boolean;
  selectedTrendVisualizationTemplate: SelectionTrendVisualizationTemplateInfo | null;
  trendVisualizationTemplates: TrendVisualizationTemplate[];
};

const subDaysBaseOnTimeDifference = (date: Date, timeRange: TimeRange) => {
  let fromDate = new Date(date);

  if (timeRange !== TimeRange.HOURS_24) {
    fromDate = sub(date, {
      days: timeRangeDaysDefinition[timeRange],
    });
  }

  fromDate.setHours(0, 0, 0);
  return fromDate;
};

const addDaysBaseOnTimeDifference = (date: Date, timeRange: TimeRange) => {
  let toDate = new Date(date);

  if (timeRange !== TimeRange.HOURS_24) {
    toDate = add(date, {
      days: timeRangeDaysDefinition[timeRange],
    });
  }

  toDate.setHours(23, 59, 59);
  return toDate;
};

const returnFormattedDateRange = (
  timeRange: TimeRange,
  relativeDate: Date | null
) => {
  const todayDate = relativeDate ?? new Date();
  todayDate.setHours(23, 59, 59);

  let fromDate = new Date(todayDate);
  fromDate.setHours(0, 0, 0);

  if (timeRange !== TimeRange.HOURS_24) {
    fromDate = subDaysBaseOnTimeDifference(todayDate, timeRange);
  }

  return {
    from: fromDate,
    to: todayDate,
  };
};

const returnGranularityBaseOnDateRange = ({
  from,
  to,
}: {
  from: Date;
  to: Date;
}) => {
  const diff = differenceInHours(to, from);

  if (diff <= HOURS_IN_DAY) return Granularity.h1;
  if (diff <= HOURS_IN_DAY * 3) return Granularity.h2;
  if (diff <= HOURS_IN_DAY * 7) return Granularity.h4;
  if (diff <= HOURS_IN_DAY * 30) return Granularity.d1;
  if (diff <= HOURS_IN_DAY * timeRangeDaysDefinition[TimeRange.MONTHS_3])
    return Granularity.d1;
  if (diff <= HOURS_IN_DAY * timeRangeDaysDefinition[TimeRange.MONTHS_6])
    return Granularity.d1;
  if (diff <= HOURS_IN_DAY * timeRangeDaysDefinition[TimeRange.YEAR])
    return Granularity.d7;
  if (diff <= HOURS_IN_DAY * timeRangeDaysDefinition[TimeRange.YEARS_3])
    return Granularity.m1;

  return Granularity.h1;
};

const returnGranularityBaseOnTimeRange = (timeRange: TimeRange) => {
  return granularityTimeRangeMapping[timeRange];
};

const timeDifferenceBaseOnDateRange = ({
  from,
  to,
}: {
  from: Date;
  to: Date;
}) => {
  const diff = differenceInHours(to, from);

  if (diff <= HOURS_IN_DAY) return TimeRange.HOURS_24;
  else {
    for (const val of Object.values(TimeRange)) {
      if (diff <= timeRangeDaysDefinition[val] * HOURS_IN_DAY) return val;
    }
  }

  return TimeRange.HOURS_24;
};

//TODO: CLEAN REDUX
const initialDetailsDates: timelineDetailsStateType = {
  timeRange: null,
  dateRangeObject: null,
  granularity: null,
  timePeriod: null,
  tab: TimelineTabsValues.DAY_BY_DAY,
  selectedDate: null,
  tabsDisabled: false,
  assessmentFormIdentification: null,
  dayByDayFullSize: false,
  selectedTrendVisualizationTemplate: null,
  trendVisualizationTemplates: [],
};

export enum TimelineDetailsDatesAction {
  TIMELINE_DETAILS_RESET = 'TIMELINE_DETAILS_RESET',
  TIMELINE_DETAILS_SET_SELECTED_DATE = 'SET_TIMELINE_DETAILS_SELECTED_DATE',
  TIMELINE_DETAILS_SET_PREDEFINED_DATE_RANGE = 'SET_TIMELINE_DETAILS_PREDEFINED_RANGE',
  TIMELINE_DETAILS_SET_CUSTOM_DATE_RANGE = 'SET_TIMELINE_DETAILS_CUSTOM_RANGE',
  TIMELINE_DETAILS_PREVIOUS_PERIOD = 'TIMELINE_DETAILS_PREVIOUS_PERIOD',
  TIMELINE_DETAILS_NEXT_PERIOD = 'TIMELINE_DETAILS_NEXT_PERIOD',
  TIMELINE_DETAILS_TAB_CHANGE = 'TIMELINE_DETAILS_TAB_CHANGE',
  TIMELINE_DETAILS_SET_CASE_PERIOD_DATE_RANGE = 'TIMELINE_DETAILS_SET_CASE_PERIOD_DATE_RANGE',
  TIMELINE_DETAILS_DISABLE_TABS = 'TIMELINE_DETAILS_DISABLE_TABS',
  TIMELINE_DETAILS_SET_ASSESSMENT_FORM_IDENTIFICATION = 'TIMELINE_DETAILS_SET_ASSESSMENT_FORM_IDENTIFICATION',
  TIMELINE_DETAILS_SET_FULL_SIZE_DAY_BY_DAY = 'TIMELINE_DETAILS_SET_FULL_SIZE_DAY_BY_DAY',
  TIMELINE_DETAILS_SET_SELECTED_TREND_VISUALIZATION_TEMPLATE = 'TIMELINE_DETAILS_SET_SELECTED_TREND_VISUALIZATION_TEMPLATE',
}

type TimelineDetailsFilterAction =
  | {
      type: TimelineDetailsDatesAction.TIMELINE_DETAILS_SET_PREDEFINED_DATE_RANGE;
      timeRange: TimeRange;
      relativeToDate: Date | null;
    }
  | {
      type: TimelineDetailsDatesAction.TIMELINE_DETAILS_SET_SELECTED_DATE;
      selectedDate: Date | null;
    }
  | {
      type: TimelineDetailsDatesAction.TIMELINE_DETAILS_RESET;
    }
  | {
      type: TimelineDetailsDatesAction.TIMELINE_DETAILS_SET_CUSTOM_DATE_RANGE;
      dateRange: {
        from: Date;
        to: Date;
      };
    }
  | {
      type: TimelineDetailsDatesAction.TIMELINE_DETAILS_SET_CASE_PERIOD_DATE_RANGE;
      dateRange: {
        from: Date;
        to: Date;
      };
    }
  | {
      type: TimelineDetailsDatesAction.TIMELINE_DETAILS_PREVIOUS_PERIOD;
    }
  | {
      type: TimelineDetailsDatesAction.TIMELINE_DETAILS_NEXT_PERIOD;
    }
  | {
      type: TimelineDetailsDatesAction.TIMELINE_DETAILS_TAB_CHANGE;
      tab: TimelineTabsValues;
    }
  | {
      type: TimelineDetailsDatesAction.TIMELINE_DETAILS_DISABLE_TABS;
      tabsDisabled: boolean;
    }
  | {
      type: TimelineDetailsDatesAction.TIMELINE_DETAILS_SET_ASSESSMENT_FORM_IDENTIFICATION;
      identification: AssessmentFormIdentification | null;
    }
  | {
      type: TimelineDetailsDatesAction.TIMELINE_DETAILS_SET_FULL_SIZE_DAY_BY_DAY;
    }
  | {
      type: TimelineDetailsDatesAction.TIMELINE_DETAILS_SET_SELECTED_TREND_VISUALIZATION_TEMPLATE;
      template: SelectionTrendVisualizationTemplateInfo | null;
    };

const timelineDetailsReducer = (
  state = initialDetailsDates,
  action: TimelineDetailsFilterAction
) => {
  switch (action.type) {
    case TimelineDetailsDatesAction.TIMELINE_DETAILS_SET_PREDEFINED_DATE_RANGE:
      const dateRangeObject = returnFormattedDateRange(
        action.timeRange,
        action.relativeToDate
      );

      const toDate = new Date(dateRangeObject.to);
      toDate.setHours(0, 0, 0, 0);

      return {
        ...state,
        timeRange: action.timeRange,
        dateRangeObject,
        granularity: returnGranularityBaseOnTimeRange(action.timeRange),
        dataZoom: null,
        isInitialized: false,
        timePeriod: action.timeRange,
        selectedDate: toDate,
      };

    case TimelineDetailsDatesAction.TIMELINE_DETAILS_SET_CUSTOM_DATE_RANGE:
      const formattedObject = {
        from: action.dateRange.from,
        to: action.dateRange.to,
      };
      const toDateRange = new Date(formattedObject.to);
      toDateRange.setHours(0, 0, 0, 0);

      return {
        ...state,
        timeRange: null,
        dateRangeObject: action.dateRange,
        granularity: returnGranularityBaseOnDateRange(formattedObject),
        isInitialized: false,
        timePeriod: timeDifferenceBaseOnDateRange(formattedObject),
        selectedDate: toDateRange,
      };
    case TimelineDetailsDatesAction.TIMELINE_DETAILS_SET_CASE_PERIOD_DATE_RANGE:
      const casePeriodFormattedObject = {
        from: action.dateRange.from,
        to: action.dateRange.to,
      };
      const casePeriodToDateRange = new Date(casePeriodFormattedObject.to);
      casePeriodToDateRange.setHours(0, 0, 0, 0);

      return {
        ...state,
        timeRange: TimeRange.CASE_PERIOD,
        dateRangeObject: action.dateRange,
        granularity: returnGranularityBaseOnDateRange(
          casePeriodFormattedObject
        ),
        isInitialized: false,
        timePeriod: TimeRange.CASE_PERIOD,
        selectedDate: casePeriodToDateRange,
      };

    case TimelineDetailsDatesAction.TIMELINE_DETAILS_PREVIOUS_PERIOD:
      if (
        state.dateRangeObject?.from &&
        state.dateRangeObject?.to &&
        state.timePeriod
      ) {
        const newToDate = addDays(new Date(state.dateRangeObject.from), -1);
        newToDate.setHours(23, 59, 59);

        const newFrom = subDaysBaseOnTimeDifference(
          newToDate,
          state.timePeriod
        );

        const nextDateRangeObject = {
          from: newFrom,
          to: newToDate,
        };

        const selectedDate = new Date(newToDate);
        selectedDate.setHours(0, 0, 0, 0);

        return {
          ...state,
          dateRangeObject: nextDateRangeObject,
          timeRange: null,
          isInitialized: false,
          selectedDate,
        };
      }

      return state;
    case TimelineDetailsDatesAction.TIMELINE_DETAILS_NEXT_PERIOD:
      if (
        state.dateRangeObject?.from &&
        state.dateRangeObject?.to &&
        state.timePeriod
      ) {
        const newFrom = addDays(new Date(state.dateRangeObject.to), 1);
        newFrom.setHours(0, 0, 0, 0);

        const newToDate = addDaysBaseOnTimeDifference(
          newFrom,
          state.timePeriod
        );

        const nextDateRangeObject = {
          from: newFrom,
          to: newToDate,
        };

        const selectedDate = new Date(newToDate);
        selectedDate.setHours(0, 0, 0, 0);

        return {
          ...state,
          dateRangeObject: nextDateRangeObject,
          timeRange: null,
          isInitialized: false,
          selectedDate,
        };
      }

      return {
        ...state,
      };
    case TimelineDetailsDatesAction.TIMELINE_DETAILS_TAB_CHANGE:
      return {
        ...state,
        tab: action.tab,
      };
    case TimelineDetailsDatesAction.TIMELINE_DETAILS_RESET:
      return initialDetailsDates;
    case TimelineDetailsDatesAction.TIMELINE_DETAILS_DISABLE_TABS:
      return {
        ...state,
        tabsDisabled: action.tabsDisabled,
      };
    case TimelineDetailsDatesAction.TIMELINE_DETAILS_SET_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.selectedDate,
      };
    case TimelineDetailsDatesAction.TIMELINE_DETAILS_SET_ASSESSMENT_FORM_IDENTIFICATION:
      return {
        ...state,
        assessmentFormIdentification: action.identification,
      };
    case TimelineDetailsDatesAction.TIMELINE_DETAILS_SET_FULL_SIZE_DAY_BY_DAY:
      return {
        ...state,
        dayByDayFullSize: !state.dayByDayFullSize,
      };
    case TimelineDetailsDatesAction.TIMELINE_DETAILS_SET_SELECTED_TREND_VISUALIZATION_TEMPLATE:
      return {
        ...state,
        selectedTrendVisualizationTemplate: action.template,
      };
    default:
      return state;
  }
};

export default timelineDetailsReducer;
