import AddIcon from '@mui/icons-material/Add';
import IconButton from 'components/IconButton';
import { toDateOnly } from 'helpers/dates';
import React from 'react';
import { useIntl } from 'react-intl';
import useOpen from '../../../../../../../../../../../../hooks/useOpen';
import ScheduleLayout from '../../layouts/ScheduleLayout';
import CaseConversationsScheduleAddTemplateModal from '../../modals/CaseConversationsScheduleAddTemplateModal';
import DaySchedule from './components/DaySchedule';

type ConversationScheduleProps = {
  selectedDate: string;
};

const ConversationSchedule = ({ selectedDate }: ConversationScheduleProps) => {
  const intl = useIntl();
  const addTemplateModal = useOpen();

  return (
    <>
      {addTemplateModal.isOpen && (
        <CaseConversationsScheduleAddTemplateModal
          isOpen={addTemplateModal.isOpen}
          toggle={addTemplateModal.toggle}
        />
      )}
      <ScheduleLayout
        title={intl.formatMessage({ id: 'Schedules.ConversationSchedule' })}
        schedule={<DaySchedule selectedDate={selectedDate} />}
        actions={[
          <IconButton
            key="add-template"
            color="primary"
            variant="outlined"
            disabled={selectedDate <= toDateOnly(new Date())}
            onClick={addTemplateModal.toggle}
          >
            <AddIcon />
          </IconButton>,
        ]}
      />
    </>
  );
};

export default ConversationSchedule;
