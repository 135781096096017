import { useQuery } from 'react-query';
import { getEducationMaterialCategoriesTree } from '../../educationMaterials';
import QueryKeys from '../queryKeys';
import { OrganizationQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

const useEducationMaterialCategoriesTree = ({
  params,
  options,
}: IQueryRequest<OrganizationQueryParams>) => {
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getEducationMaterialCategoriesTree(
        params.organizationId
      );

      return data.data;
    },
    queryKey: [
      QueryKeys.getEducationMaterialCategoriesTree,
      params.organizationId,
    ],
  });
};

export default useEducationMaterialCategoriesTree;
