import { FormattedMessage, useIntl } from 'react-intl';

// COMPONENTS:
import ResetPasswordForm from './components/ResetPasswordForm';

// ACTIONS:
import useResetPassword from 'api/mutations/membership/useResetPassword';
import SVG from 'react-inlinesvg';
import logo from 'assets/images/logo-blue.svg';
import backArrow from 'assets/images/back_arrow.svg';
import { useNavigate } from 'react-router';
import MembershipLayout from 'components/layouts/MembershipLayout';

const initialValues = {
  email: '',
};

const ResetPassword = () => {
  const navigate = useNavigate();

  const intl = useIntl();

  const {
    mutate: resetPasswordMutation,
    isLoading,
    isError,
    isSuccess,
  } = useResetPassword();

  const handleSubmit = ({ email }: typeof initialValues) => {
    resetPasswordMutation(email);
  };

  const handleGoToLogin = () => {
    navigate('/login');
  };

  return (
    <MembershipLayout>
      <div className="ResetPassword h-100">
        <div className="ResetPassword__top-bar">
          <div
            className="ResetPassword__top-bar__back"
            onClick={handleGoToLogin}
          >
            <SVG src={backArrow} />
            <FormattedMessage id="Login.back" />
          </div>
          <div className="ResetPassword__top-bar__logo">
            <div className="ResetPassword__top-bar__logo__logo-wrapper">
              <SVG
                src={logo}
                title={intl.formatMessage({
                  id: 'MyOrganizations.Logo',
                })}
              />
            </div>
          </div>
        </div>
        <div className="ResetPassword__wrapper ResetPassword__wrapper--height-stretched">
          <div className="ResetPassword__wrapper__form d-flex align-items-center">
            <div className="ResetPassword__wrapper__form__header">
              <span className="ResetPassword__wrapper__form__header__log-in-as">
                <FormattedMessage id="ResetPassword.header" />
              </span>
            </div>
            <ResetPasswordForm
              isFailure={isError}
              isLoading={isLoading}
              isSuccess={isSuccess}
              handleSubmit={handleSubmit}
              initialValues={initialValues}
            />
          </div>
        </div>
      </div>
    </MembershipLayout>
  );
};

export default ResetPassword;
