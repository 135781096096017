import { getUserContextAssessmentFormsForPeriod } from 'api/graspManagement';
import { useQuery } from 'react-query';
import QueryKeys from '../../../queryKeys';
import {
  TimelineRangeQueryParams,
  UserQueryParams,
} from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

export type UseUserContextAssessmentFormsForPeriodParams =
  TimelineRangeQueryParams & Partial<UserQueryParams>;

function useUserContextAssessmentFormsForPeriod({
  params,
  options,
}: IQueryRequest<UseUserContextAssessmentFormsForPeriodParams>) {
  const { userId, timelineId, selectedDates } = params;
  const { enabled, ...otherOptions } = options ?? { enabled: true };

  const isEnabled = enabled && (!!selectedDates || !!userId || !!timelineId);

  return useQuery({
    ...otherOptions,
    enabled: isEnabled,
    queryKey: [
      QueryKeys.getUserContextAssessmentFormsForPeriod,
      userId,
      timelineId,
      selectedDates?.from,
      selectedDates?.to,
    ],
    queryFn: async () => {
      if (!userId || !selectedDates || !timelineId) return null;

      const { data: responseData } =
        await getUserContextAssessmentFormsForPeriod(
          userId,
          timelineId,
          selectedDates.from,
          selectedDates.to
        );

      return responseData.data;
    },
  });
}

export default useUserContextAssessmentFormsForPeriod;
