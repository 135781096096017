import useRequiredParams from '../../../hooks/useRequiredParams';

export const useCommonParams = () => {
  const { userId, caseId } = useRequiredParams<{
    userId: string;
    caseId: string;
  }>(['userId', 'caseId']);

  return { userId, caseId };
};
