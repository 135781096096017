import { Paper } from '@mui/material';
import React, { PropsWithChildren } from 'react';

type TabPanelProps = {
  index: number;
};

const TabPanel = ({ children }: PropsWithChildren<TabPanelProps>) => {
  return (
    <Paper className="w-100 h-100" elevation={0}>
      {children}
    </Paper>
  );
};

export default TabPanel;
