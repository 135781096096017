import React, { useMemo } from 'react';
import Circle from '../../../StaticFields/components/Circle';
import TextField from '@mui/material/TextField';
import { Typography } from '@mui/material';
import { FormikProps, getIn } from 'formik';
import {
  AwareCaseDetailsFormValues,
  QuestionField,
} from '../../../../forms/AwareCaseDetailsForm/types';
import { OpenHookValues } from '../../../../../../../../../../hooks/useOpen';
import MenuItem from '@mui/material/MenuItem/MenuItem';

type DynamicFieldProps = {
  question: QuestionField;
  sequence: number;
  form: FormikProps<AwareCaseDetailsFormValues>;
  editMode: OpenHookValues;
};

const DynamicField = ({
  editMode,
  sequence,
  question,
  form,
}: DynamicFieldProps) => {
  const selectedOption = useMemo(() => {
    return getIn(form.values, `questions[${sequence}].value`);
  }, [form.values, sequence]);

  const renderValue = useMemo(() => {
    let questionValue = getIn(form.values, `questions[${sequence}].value`);

    if (questionValue !== '') return undefined;

    return () => (
      <Typography sx={{ opacity: 0.4 }}>{question.label}</Typography>
    );
  }, [form.values, question.label, sequence]);

  return (
    <div className="flex items-center gap-3">
      {editMode.isOpen ? (
        <>
          <Circle />
          <TextField
            variant="standard"
            select={true}
            SelectProps={{
              displayEmpty: true,
              renderValue: renderValue,
            }}
            id={`questions[${sequence}].value`}
            name={`questions[${sequence}].value`}
            placeholder={question.label}
            value={getIn(form.values, `questions[${sequence}].value`)}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
            error={
              getIn(form.touched, `questions[${sequence}].value`) &&
              Boolean(getIn(form.errors, `questions[${sequence}].value`))
            }
          >
            {question.options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.text}
              </MenuItem>
            ))}
          </TextField>
        </>
      ) : (
        <>
          {form.values.questions
            .at(sequence)
            ?.options.find((p) => p.value === selectedOption)?.text && (
            <>
              <Circle />
              <Typography
                variant="subtitle1"
                sx={{
                  overflow: 'hidden',
                  textWrap: 'nowrap',
                }}
              >
                {form.values.questions
                  .at(sequence)
                  ?.options.find((p) => p.value === selectedOption)?.text ??
                  '-'}
              </Typography>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default DynamicField;
