import {
  DashboardDatePeriod,
  DashboardTemplate,
} from '../../../../../../../interfaces/dashboards';
import { DashboardFilterHelpers } from '../../DashboardContent/helpers/filters';

export namespace DashboardResolvingHelpers {
  export const ShouldTemplateBeResolved = (template: DashboardTemplate) => {
    let _shouldBeResolved = false;

    for (const filter of template.content.filters) {
      if (!DashboardFilterHelpers.IsIncludedExcluded(filter)) continue;
      if (filter.included.some((p) => _unresolvedReference(p))) return true;
      if (filter.excluded.some((p) => _unresolvedReference(p))) return true;
    }

    if (
      !template.content.date_selection ||
      template.content.date_selection?.date_period !==
        DashboardDatePeriod.CustomPeriod
    )
      return _shouldBeResolved;

    if (_unresolvedReference(template.content.date_selection.date_from))
      return true;

    if (_unresolvedReference(template.content.date_selection.date_to))
      return true;

    return _shouldBeResolved;
  };

  const _unresolvedReference = (reference: string) => {
    const handleBarsTemplate = /{{([\s\S]+?)}}/g;
    return handleBarsTemplate.test(reference);
  };
}
