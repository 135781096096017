import { DashboardDataStates } from '../components/DashboardContent/components/DashboardDataContent/store/state';

export namespace DashboardActionTypes {
  export enum DashboardActionType {
    ADD_TEMPLATE = 'ADD_TEMPLATE',
    EDIT_TEMPLATE = 'EDIT_TEMPLATE',
  }

  export interface IBaseDashboardAction {
    type: DashboardActionType;
  }

  export interface IAddTemplateDashboardAction
    extends IBaseDashboardAction,
      IPathTemplate {
    type: DashboardActionType.ADD_TEMPLATE;
  }

  export interface IEditTemplateDashboardAction
    extends IBaseDashboardAction,
      IPathTemplate {
    type: DashboardActionType.EDIT_TEMPLATE;
  }

  export type DashboardAction =
    | IAddTemplateDashboardAction
    | IEditTemplateDashboardAction;

  interface IPathTemplate {
    path: string;
    template: DashboardDataStates.DashboardDataState;
  }
}
