//image
import emailLogo from 'assets/images/register/email.svg';

//interfaces
import {
  AssessmentLegendDescriptions,
  AssessmentQuestionType,
} from 'interfaces/assessmentForms';
import SVG from 'react-inlinesvg';
import { FormGroup, Input, InputGroup, InputGroupText } from 'reactstrap';
import { getSmileIconBasedOnSentiment } from '../helpers/utils/icons';
import { BaseSentiments } from '../interfaces/timeline';
import { AVAILABLE_CULTURES } from './CultureSelector/CultureSelector';

//components
import InputRange from './InputRange';

type AnswerItem = {
  title: string;
  description: string;
  value: string;
  sentiment: BaseSentiments;
  sentiment_score: number;
};

type AnswersProps = {
  type?: string;
  items: AnswerItem[];
  answer: string;
  onAnswerChange: (value: string) => void;
  showSentimentScale: boolean | null;
  legendDescriptions: AssessmentLegendDescriptions[];
  name: string;
  guid: string;
  maxNumberValue?: number;
  minNumberValue?: number;
  canEdit: boolean;
  placeholder: string | null;
  maxLength: number | null;
  conversationTemplatePhoneView?: boolean;
  culture?: AVAILABLE_CULTURES;
};

const DEFAULT_MAX_LENGTH = 100;

const ModalAnswers = ({
  minNumberValue,
  maxNumberValue,
  guid,
  type,
  items,
  answer,
  name,
  showSentimentScale,
  legendDescriptions,
  canEdit,
  onAnswerChange,
  maxLength,
  placeholder,
  culture = AVAILABLE_CULTURES.EN,
}: AnswersProps) => {
  const renderAnswers = () => {
    let renderedAnswers: JSX.Element[] | JSX.Element | null = null;
    switch (type) {
      case AssessmentQuestionType.RANGE:
        let maxValue =
          !maxNumberValue && maxNumberValue !== 0
            ? DEFAULT_MAX_LENGTH
            : maxNumberValue;
        let minValue =
          !minNumberValue && minNumberValue !== 0 ? 0 : minNumberValue;

        let reversed: boolean = false;

        if (maxValue < minValue) {
          reversed = true;
        }

        renderedAnswers = (
          <div className="QuestionModal__answer-wrapper-slider">
            <InputRange
              culture={culture}
              legendDescriptions={legendDescriptions}
              key={guid}
              max={maxValue}
              min={minValue}
              onChange={(value: number) => {
                canEdit && onAnswerChange(value.toString());
              }}
              value={Number(Number(answer).toFixed(0))}
              step={1}
              showSentimentScale={showSentimentScale ?? false}
              iconFeedbackReversed={reversed}
            />
          </div>
        );
        break;
      case AssessmentQuestionType.OPEN:
        renderedAnswers = (
          <FormGroup>
            <Input
              disabled={!canEdit}
              height={200}
              type="textarea"
              onChange={(event) => {
                canEdit && onAnswerChange(event.target.value);
              }}
              className="QuestionModal__textarea"
              name={name}
              value={answer}
              placeholder={placeholder ?? ''}
              maxLength={maxLength ?? DEFAULT_MAX_LENGTH}
            />
          </FormGroup>
        );
        break;
      case AssessmentQuestionType.EMAIL:
        return (
          <FormGroup>
            <InputGroup className="InputFieldWithImage__input-group">
              <InputGroupText className="InputFieldWithImage__input-group__image-addon">
                <img src={emailLogo} alt="email_logo" />
              </InputGroupText>
              <Input
                type="email"
                placeholder={placeholder ?? ''}
                maxLength={maxLength ?? DEFAULT_MAX_LENGTH}
                name={name}
                value={answer}
                onChange={(event) => {
                  canEdit && onAnswerChange(event.target.value);
                }}
              />
            </InputGroup>
          </FormGroup>
        );
      default:
        renderedAnswers = items.map(
          ({ title, description, value, sentiment }) => {
            const isChecked = answer === value;
            return (
              <FormGroup
                disabled={!canEdit}
                key={value}
                onClick={canEdit ? () => onAnswerChange(value) : undefined}
                check
                className={
                  isChecked
                    ? 'QuestionModal__answer QuestionModal__answer--selected'
                    : 'QuestionModal__answer'
                }
              >
                <Input
                  disabled={!canEdit}
                  type="radio"
                  name={name}
                  className={'ms-1'}
                  checked={isChecked}
                  onChange={canEdit ? () => onAnswerChange(value) : undefined}
                  value={answer}
                />
                {showSentimentScale ? (
                  <div className="QuestionModal__answer__icon mx-3">
                    <SVG
                      src={getSmileIconBasedOnSentiment(sentiment)}
                      title={title}
                    />
                  </div>
                ) : (
                  <div className="me-2" />
                )}
                <div className="QuestionModal__answer__text-wrapper">
                  <div className="QuestionModal__answer__title">{title}</div>
                  <div className="QuestionModal__answer__description">
                    {description}
                  </div>
                </div>
              </FormGroup>
            );
          }
        );
    }

    return renderedAnswers;
  };

  return (
    <FormGroup tag="fieldset" className="QuestionModal__answer-wrapper">
      {renderAnswers()}
    </FormGroup>
  );
};

export default ModalAnswers;
