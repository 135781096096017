import useSendTimelineShareRequests from 'api/mutations/timelines/useTimelineSendShareRequest';
import useCaseDetails from 'api/queries/cases/useCaseDetails';
import useTimelineShareRequestsForCase from 'api/queries/cases/useTimelineShareRequestsForCase';
import timeline from 'assets/images/ic-timeline.svg';

//images
import sharingIcon from 'assets/images/timeline_details/sharing.svg';

//components
import ConfirmModal from 'components/ConfirmModal';
import TimelineElement from 'components/TimelineElement';

//helpers
import useOpen from 'hooks/useOpen';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'reactstrap';

type CaseTimelineProps = {
  organizationId: string;
  caseId: string;
  shouldFetchCase?: boolean;
};

export const MASTER_TIMELINE_NAME = 'Master';

const CaseTimelines: React.FC<CaseTimelineProps> = ({
  organizationId,
  caseId,
  shouldFetchCase = false,
}) => {
  const { isOpen, close: closeModal, open: openModal } = useOpen();
  const intl = useIntl();

  const { data: caseData } = useCaseDetails({
    params: { organizationId, caseId },
    options: { enabled: shouldFetchCase },
  });

  const timelines = useMemo(() => {
    if (caseData?.timelines) {
      const masterItem = caseData.timelines.find(
        (timeline) => timeline.name === MASTER_TIMELINE_NAME
      );

      if (masterItem) {
        const timelinesArray = caseData.timelines.filter(
          (timeline) => timeline.name !== MASTER_TIMELINE_NAME
        );

        return [masterItem, ...timelinesArray];
      } else return caseData.timelines;
    } else return [];
  }, [caseData]);

  const userName = `${caseData?.participants[0]?.personality.first_name} ${caseData?.participants[0]?.personality.last_name}`;

  const { refetch } = useTimelineShareRequestsForCase({
    params: { organizationId, caseId },
    options: { enabled: shouldFetchCase },
  });

  const successFb = async () => {
    await refetch();
  };

  const { mutate } = useSendTimelineShareRequests(organizationId, successFb);

  const onConfirmClick = (): void => {
    mutate(caseId);
    closeModal();
  };

  let renderedTimelines: JSX.Element | JSX.Element[];

  if (timelines.length > 0) {
    renderedTimelines = timelines.map((timeline) => {
      return (
        <TimelineElement
          key={timeline.id}
          description={timeline.description}
          firstActivity={timeline.first_observation_at}
          lastUpdateDate={timeline.last_observation_at}
          name={timeline.name}
          id={timeline.id}
          isDefault={timeline.is_default}
          isMaster={timeline.is_master}
          isShared={timeline.is_shared}
          isClickable={false}
          type={timeline.type}
        />
      );
    });
  } else
    renderedTimelines = (
      <div>
        <p>
          <FormattedMessage id="CaseCard.youDontHavePermissionToTimeline" />
        </p>
        <p>
          <b>
            <FormattedMessage id="CaseCard.clickButtonAboveToRequest" />
          </b>
        </p>
      </div>
    );

  return (
    <>
      <div className="cardContainer">
        <div className="cardContainer__header">
          <div className="cardContainer__header__title">
            <img
              src={timeline}
              className="cardContainer__header__title__image"
              alt={timeline}
            />
            <FormattedMessage id="CaseCard.caseTimelines" />
          </div>
          <div className="cardContainer__header__button-container">
            <Button
              color="primary"
              className="cardContainer__header__button-container__button"
              onClick={openModal}
            >
              <img src={sharingIcon} alt="Request access" />
            </Button>
            <div className="cardContainer__header__button-container__text">
              <FormattedMessage id="CaseCard.requestTimelineAccess" />
            </div>
          </div>
        </div>
        <div className="card-wrapper">{renderedTimelines}</div>
      </div>
      <ConfirmModal
        isOpen={isOpen}
        confirm={onConfirmClick}
        toggle={closeModal}
        message={
          <div className="requestConfirmationBody">
            <div className="w-100 text-center">
              <img src={sharingIcon} alt="sharing_icon" />
            </div>
            {`${intl.formatMessage({
              id: 'CaseCard.requestModal.body',
            })} to `}
            <span className="requestConfirmationBody__user">{userName}</span>?
          </div>
        }
      />
    </>
  );
};

export default CaseTimelines;
