import useOpen from '../../../../../../../../../hooks/useOpen';
import { useState } from 'react';

export const useManageProcedure = () => {
  const editProcedure = useOpen();

  const [procedureIndex, setProcedureIndex] = useState<number | null>(null);

  const handleOpen = (index: number) => {
    setProcedureIndex(index);
    editProcedure.open();
  };

  const handleToggle = () => {
    setProcedureIndex(null);
    editProcedure.close();
  };

  return {
    isOpen: editProcedure.isOpen,
    procedureIndex,
    handleOpen,
    handleToggle,
  };
};
