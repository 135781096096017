import {
  normalizeQueryParams,
  normalizeQueryParamsArray,
  QueryParamsArrayItem,
} from '../helpers/utils/normalizeQueryParams';
import {
  IAwareSessionData,
  IAwareSessionDataMapping,
  IScheduleAwareSessionData,
  IUpdateAwareSessionData,
} from '../interfaces/awareSessions';
import {
  IReferenced,
  IResponse,
  IResponseWithPagination,
  ITotalRowsPage,
} from '../interfaces/response';
import request from './utils/request';
import { IParamsTypeInRequest } from '../interfaces/reducer';

export const getOrganizationsAwareSessions = (
  organizationId: string,
  params: Partial<IParamsTypeInRequest>,
  aggregated?: boolean
) => {
  return request<void, IResponseWithPagination<IAwareSessionData[]>>({
    url: `/organizations/${organizationId}/aware_sessions${normalizeQueryParams(
      params
    )}&aggregated=${aggregated}`,
    method: 'GET',
  });
};

export const getOrganizationAwareSessions = (
  organizationId: string,
  params: QueryParamsArrayItem[]
) => {
  return request<undefined, IResponse<ITotalRowsPage<IAwareSessionData>>>({
    url: `organizations/${organizationId}/aware_sessions${normalizeQueryParamsArray(
      params
    )}`,
    method: 'GET',
  });
};

export const getAwareSessionsWithReferencePeriod = (
  organizationId: string,
  params: QueryParamsArrayItem[]
) => {
  return request<
    undefined,
    IResponse<IReferenced<ITotalRowsPage<IAwareSessionData>>>
  >({
    url: `organizations/${organizationId}/aware_sessions/reference_period${normalizeQueryParamsArray(
      params
    )}`,
    method: 'GET',
  });
};

export const getCaseAwareSession = (
  organizationId: string,
  caseId: string,
  aggregated: boolean
) => {
  return request<undefined, IResponse<IAwareSessionData>>({
    url: `organizations/${organizationId}/cases/${caseId}/aware_sessions?aggregated=${aggregated}`,
    method: 'GET',
  });
};

export const getAwareSessionsForPatientNumber = (
  organizationId: string,
  patientNumber: string,
  aggregated: boolean
) => {
  return request<undefined, IResponse<IAwareSessionData[]>>({
    url: `organizations/${organizationId}/participants/${patientNumber}/aware_sessions?aggregated=${aggregated}`,
    method: 'GET',
  });
};

export const getAwareSessionDataMapping = (organizationId: string) => {
  return request<undefined, IResponse<IAwareSessionDataMapping>>({
    url: `organizations/${organizationId}/aware_sessions/mapping`,
    method: 'GET',
  });
};

export const scheduleAwareSessionData = (
  organizationId: string,
  data: IScheduleAwareSessionData
) =>
  request<IScheduleAwareSessionData, void>({
    url: `organizations/${organizationId}/aware_sessions/schedule`,
    method: 'POST',
    data,
  });

export const fetchAwareSessionParameters = (
  organizationId: string,
  patientNumber: number,
  aggregated: boolean
) =>
  request<void, Record<string, any>>({
    url: `organizations/${organizationId}/patients/${patientNumber}/aware-params?aggregated=${aggregated}`,
    method: 'GET',
  });

export const updateAwareSessionData = (
  organizationId: string,
  caseId: string,
  data: IUpdateAwareSessionData
) =>
  request<IUpdateAwareSessionData, void>({
    url: `organizations/${organizationId}/aware_sessions/${caseId}`,
    method: 'PUT',
    data,
  });

export const deleteAwareSessionData = (
  organizationId: string,
  caseId: string
) =>
  request({
    url: `organizations/${organizationId}/aware_sessions/${caseId}`,
    method: 'DELETE',
  });
