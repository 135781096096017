import {
  ClosedListValidConversationQuestion,
  ConversationTemplate,
  DelimiterValidConversationTemplateItem,
  EducationValidConversationTemplateItem,
  EmailValidConversationQuestion,
  FeedbackValidConversationTemplateItem,
  GraspUploadValidConversationTemplateItem,
  HeaderValidConversationQuestion,
  MaterialsListValidConversationTemplateItem,
  NotificationsListValidConversationTemplateItem,
  OpenValidConversationQuestion,
  PlainTextValidConversationTemplateItem,
  QuestionValidConversationTemplateItem,
  RangeValidConversationQuestion,
  TimeValidConversationQuestion,
  ValidConversationQuestionType,
  ValidConversationTemplate,
  ValidConversationTemplateItemType,
} from '../../../../../../../interfaces/conversations';
import { IAsyncFormHookValues } from '../../../../../../../interfaces/forms';
import { useCallback, useMemo } from 'react';
import { useCommonParams } from '../../../../../hooks/useCommonParams';
import { getEmptyConversationTemplate } from '../helpers';
import { FormikErrors } from 'formik';
import useUpsertConversationTemplate from '../../../../../../../api/mutations/conversations/useUpsertConversationTemplate';
import { useCommonData } from '../../../../../hooks/useCommonData';

export const useForm = (
  template: ConversationTemplate | undefined,
  toggle: () => void
): IAsyncFormHookValues<ValidConversationTemplate> => {
  const { organizationId } = useCommonParams();

  const {
    conversationTemplates,
    groupedConversationQuestions,
    conversationQuestions,
  } = useCommonData();

  const handleSuccess = async () => {
    await conversationTemplates.refetch();
    await groupedConversationQuestions.refetch();
    await conversationQuestions.refetch();
    toggle();
  };

  const upsertConversationTemplate = useUpsertConversationTemplate({
    params: { organizationId },
    options: { successFb: handleSuccess },
  });

  const initialValues = useMemo((): ValidConversationTemplate => {
    if (template) return { ...template };
    return getEmptyConversationTemplate(organizationId);
  }, [organizationId, template]);

  const delimiterValidation = useCallback(
    (
      errors: FormikErrors<typeof initialValues>,
      _item: DelimiterValidConversationTemplateItem
    ) => {
      return errors;
    },
    []
  );

  const educationValidation = useCallback(
    (
      errors: FormikErrors<typeof initialValues>,
      item: EducationValidConversationTemplateItem
    ) => {
      if (item.resource_id.length === 0) errors.content = [];
      return errors;
    },
    []
  );

  const feedbackValidation = useCallback(
    (
      errors: FormikErrors<typeof initialValues>,
      item: FeedbackValidConversationTemplateItem
    ) => {
      if (item.group_name.length === 0) errors.content = [];
      return errors;
    },
    []
  );

  const graspUploadValidation = useCallback(
    (
      errors: FormikErrors<typeof initialValues>,
      _item: GraspUploadValidConversationTemplateItem
    ) => {
      return errors;
    },
    []
  );

  const materialsListValidation = useCallback(
    (
      errors: FormikErrors<typeof initialValues>,
      _item: MaterialsListValidConversationTemplateItem
    ) => {
      return errors;
    },
    []
  );

  const notificationsListValidation = useCallback(
    (
      errors: FormikErrors<typeof initialValues>,
      _item: NotificationsListValidConversationTemplateItem
    ) => {
      return errors;
    },
    []
  );

  const plainTextValidation = useCallback(
    (
      errors: FormikErrors<typeof initialValues>,
      item: PlainTextValidConversationTemplateItem
    ) => {
      if (!item.value) {
        errors.content = [];
        return errors;
      }

      if (!item.value.text || item.value.text.length === 0) errors.content = [];
      return errors;
    },
    []
  );

  const baseQuestionValidation = useCallback(
    (
      errors: FormikErrors<typeof initialValues>,
      item: QuestionValidConversationTemplateItem
    ) => {
      if (item.resource_id.length === 0) errors.content = [];
      if (item.resource_id !== item.value.guid) errors.content = [];
      if (item.value.sequence < 0) errors.content = [];
      if (
        item.value.title?.length === 0 ||
        item.value.short_title?.length === 0
      ) {
        errors.content = [];
      }

      return errors;
    },
    []
  );

  const headerValidation = useCallback(
    (
      errors: FormikErrors<typeof initialValues>,
      _item: HeaderValidConversationQuestion
    ) => {
      return errors;
    },
    []
  );

  const openValidation = useCallback(
    (
      errors: FormikErrors<typeof initialValues>,
      item: OpenValidConversationQuestion
    ) => {
      if (
        item.answer_max_length !== undefined &&
        item.answer_max_length !== null &&
        item.answer_max_length <= 0
      )
        errors.content = [];

      if (
        item.answer_placeholder !== undefined &&
        item.answer_placeholder !== null &&
        item.answer_placeholder?.length === 0
      )
        errors.content = [];

      if (!item.answer) errors.content = [];

      return errors;
    },
    []
  );

  const rangeValidation = useCallback(
    (
      errors: FormikErrors<typeof initialValues>,
      item: RangeValidConversationQuestion
    ) => {
      if (
        item.min_number_value !== undefined &&
        item.max_number_value !== undefined &&
        item.min_number_value > item.max_number_value
      )
        errors.content = [];

      if (
        item.min_date_value !== undefined &&
        item.max_date_value !== undefined &&
        item.min_date_value > item.max_date_value
      )
        errors.content = [];

      if (
        (item.min_number_value === undefined ||
          item.max_number_value === undefined) &&
        item.legend_descriptions?.length > 0
      )
        errors.content = [];

      item.legend_descriptions.forEach((legend) => {
        if (
          item.min_number_value !== undefined &&
          item.max_number_value !== undefined &&
          (legend.value < item.min_number_value ||
            legend.value > item.max_number_value)
        )
          errors.content = [];
      });

      return errors;
    },
    []
  );

  const emailValidation = useCallback(
    (
      errors: FormikErrors<typeof initialValues>,
      item: EmailValidConversationQuestion
    ) => {
      if (
        item.answer_max_length !== undefined &&
        item.answer_max_length !== null &&
        item.answer_max_length <= 0
      )
        errors.content = [];

      if (
        item.answer_placeholder !== undefined &&
        item.answer_placeholder !== null &&
        item.answer_placeholder?.length === 0
      )
        errors.content = [];

      if (!item.answer) errors.content = [];

      return errors;
    },
    []
  );

  const timeValidation = useCallback(
    (
      errors: FormikErrors<typeof initialValues>,
      item: TimeValidConversationQuestion
    ) => {
      if (
        item.answer_max_length !== undefined &&
        item.answer_max_length !== null &&
        item.answer_max_length <= 0
      )
        errors.content = [];

      if (
        item.answer_placeholder !== undefined &&
        item.answer_placeholder !== null &&
        item.answer_placeholder?.length === 0
      )
        errors.content = [];

      if (!item.answer) errors.content = [];

      if (
        item.min_time_value &&
        item.max_time_value &&
        item.min_time_value > item.max_time_value
      )
        errors.content = [];

      return errors;
    },
    []
  );

  const closedListValidation = useCallback(
    (
      errors: FormikErrors<typeof initialValues>,
      item: ClosedListValidConversationQuestion
    ) => {
      item.answers.forEach((answer) => {
        if (answer.guid?.length === 0) errors.content = [];
        if (answer.title?.length === 0) errors.content = [];
        if (answer.sequence < 0) errors.content = [];
      });

      return errors;
    },
    []
  );

  const questionValidation = useCallback(
    (
      errors: FormikErrors<typeof initialValues>,
      item: QuestionValidConversationTemplateItem
    ) => {
      errors = baseQuestionValidation(errors, item);

      switch (item.value.type) {
        case ValidConversationQuestionType.Header:
          errors = headerValidation(errors, item.value);
          break;
        case ValidConversationQuestionType.Open:
          errors = openValidation(errors, item.value);
          break;
        case ValidConversationQuestionType.Range:
          errors = rangeValidation(errors, item.value);
          break;
        case ValidConversationQuestionType.Email:
          errors = emailValidation(errors, item.value);
          break;
        case ValidConversationQuestionType.Time:
          errors = timeValidation(errors, item.value);
          break;
        case ValidConversationQuestionType.ClosedList:
          errors = closedListValidation(errors, item.value);
          break;
      }

      return errors;
    },
    [
      baseQuestionValidation,
      closedListValidation,
      emailValidation,
      headerValidation,
      openValidation,
      rangeValidation,
      timeValidation,
    ]
  );

  const validation = useCallback(
    (values: typeof initialValues) => {
      let errors: FormikErrors<typeof initialValues> = {};

      if (values.display_code.length === 0) errors.display_code = 'invalid';
      if (values.organization_id.length === 0)
        errors.organization_id = 'invalid';

      values.content.forEach((item) => {
        switch (item.type) {
          case ValidConversationTemplateItemType.Delimiter:
            errors = delimiterValidation(errors, item);
            break;
          case ValidConversationTemplateItemType.Education:
            errors = educationValidation(errors, item);
            break;
          case ValidConversationTemplateItemType.Feedback:
            errors = feedbackValidation(errors, item);
            break;
          case ValidConversationTemplateItemType.GraspUpload:
            errors = graspUploadValidation(errors, item);
            break;
          case ValidConversationTemplateItemType.MaterialsList:
            errors = materialsListValidation(errors, item);
            break;
          case ValidConversationTemplateItemType.NotificationsList:
            errors = notificationsListValidation(errors, item);
            break;
          case ValidConversationTemplateItemType.PlainText:
            errors = plainTextValidation(errors, item);
            break;
          case ValidConversationTemplateItemType.Question:
            errors = questionValidation(errors, item);
            break;
        }
      });

      return errors;
    },
    [
      delimiterValidation,
      educationValidation,
      feedbackValidation,
      graspUploadValidation,
      materialsListValidation,
      notificationsListValidation,
      plainTextValidation,
      questionValidation,
    ]
  );

  const handleSubmit = useCallback(
    async (values: typeof initialValues) => {
      await upsertConversationTemplate.mutateAsync({
        ...values,
        id: template?.id,
      });
    },
    [template?.id, upsertConversationTemplate]
  );

  return {
    initialValues,
    validation,
    handleSubmit,
  };
};
