import { getOrganizationLocationsWithPaging } from 'api/organization';
import {
  convertIntoObjectWithPageInfo,
  returnAllDataFromResponse,
} from 'helpers/utils/reactQueryHelpers';
import { LocationItem } from 'interfaces/membership';
import { IError, IInfiniteResult } from 'interfaces/response';
import { useInfiniteQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import {
  OrganizationQueryParams,
  SearchAndPagingQueryParams,
} from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

type UseInfiniteOrganizationLocationsParams = OrganizationQueryParams &
  SearchAndPagingQueryParams;

function useInfiniteOrganizationLocations({
  params,
  options,
}: IQueryRequest<UseInfiniteOrganizationLocationsParams>) {
  const { organizationId, pageSize, searchValue } = params;
  const response = useInfiniteQuery<IInfiniteResult<LocationItem[]>, IError>({
    ...options,
    queryKey: [QueryKeys.getOrganizationEndpoints, organizationId],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getOrganizationLocationsWithPaging(
        organizationId,
        {
          page: pageParam,
          page_size: pageSize,
          search: searchValue,
        }
      );

      return convertIntoObjectWithPageInfo<LocationItem[]>(data);
    },
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage;
    },
    getPreviousPageParam: (firstPage) => firstPage.previousPage,
  });

  return {
    ...response,
    locations: returnAllDataFromResponse<LocationItem>(response),
    hasMore: !response.isLoading && response.hasNextPage,
  };
}

export default useInfiniteOrganizationLocations;
