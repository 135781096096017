import { useTypedContext } from 'hooks/useTypedContext';
import React, { useMemo, useReducer } from 'react';
import {
  OrganizationRegisterViewSteps,
  OrganizationRegisterViewStepsContext,
} from '../../types';
import ConfirmationStep from './components/ConfirmationStep';
import FormsStep from './components/FormsStep';
import {
  InitialOrganizationRegisterSteps,
  OrganizationRegisterStepsReducer,
  OrganizationRegisterStepsReducerContext,
} from './store/reducers';

const OrganizationRegisterSteps = () => {
  const steps = useTypedContext(OrganizationRegisterViewStepsContext);

  const reducer = useReducer(
    OrganizationRegisterStepsReducer,
    InitialOrganizationRegisterSteps
  );

  const renderStep = useMemo(() => {
    switch (steps.step) {
      case OrganizationRegisterViewSteps.Forms:
        return <FormsStep />;
      case OrganizationRegisterViewSteps.Confirmation:
        return <ConfirmationStep />;
    }
  }, [steps.step]);

  return (
    <OrganizationRegisterStepsReducerContext.Provider value={reducer}>
      {renderStep}
    </OrganizationRegisterStepsReducerContext.Provider>
  );
};

export default OrganizationRegisterSteps;
