import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import { editPersonality } from 'api/patients';
import { IPatchObject } from 'interfaces/request';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from 'modules/Notifications';

function useEditPersonality(patientId: string, successFb?: () => void) {
  return useMutationWithResultNotification({
    asyncApiFunction: async (patchPatientData: IPatchObject[]) => {
      await editPersonality(patientId, patchPatientData);
    },
    onError: () => displayErrorNotification('Patient.editPatientError'),
    onSuccess: () => {
      if (successFb) successFb();
      displaySuccessNotification('Patient.editPatientSuccessful');
    },
    resetOnResult: true,
  });
}

export default useEditPersonality;
