import { IAsset, IVideoAsset } from '../interfaces/educationMaterials';
import request from './utils/request';
import { IResponse } from '../interfaces/response';
import { IPatchObject } from '../interfaces/request';

export const uploadAsset = (data: FormData) =>
  request<FormData, IResponse<IVideoAsset>>({
    url: `/assets`,
    data: data,
    headers: { Accept: '*/*', 'Content-Type': 'multipart/form-data' },
    method: 'POST',
  });

export const uploadAssetThumbnail = (
  organizationId: string,
  assetId: string,
  file: File
) => {
  const formData = new FormData();
  formData.append('thumbnail', file);

  return request<FormData, IResponse<IAsset>>({
    url: `/organizations/${organizationId}/assets/${assetId}/thumbnail`,
    method: 'POST',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const removeAssetThumbnail = (
  organizationId: string,
  assetId: string
) => {
  return request({
    url: `/organizations/${organizationId}/assets/${assetId}/thumbnail`,
    method: 'DELETE',
  });
};

export const deleteAsset = (organizationId: string, assetId: string) => {
  return request({
    url: `/organizations/${organizationId}/assets/${assetId}`,
    method: 'DELETE',
  });
};

export const patchAsset = (
  organizationId: string,
  assetId: string,
  patchData: IPatchObject[]
) =>
  request({
    url: `/organizations/${organizationId}/assets/${assetId}`,
    method: 'PATCH',
    data: patchData,
  });
