import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { DropdownToggle } from 'reactstrap';
import SVG from 'react-inlinesvg';
import { Link } from 'react-router-dom';

import caret from 'assets/images/caret.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faUser } from '@fortawesome/pro-solid-svg-icons';
import classNames from 'classnames';
import Image from 'components/Image';

type ContextDisplayProps = {
  selectedOrganization: any;
  dropdownOpen: boolean;
  isOrganizationContext: boolean;
  firstName: string;
  email: string;
};

const ContextDisplay = ({
  selectedOrganization,
  dropdownOpen,
  isOrganizationContext,
  firstName,
  email,
}: ContextDisplayProps) => {
  const logo = useMemo(() => {
    if (!isOrganizationContext) return <FontAwesomeIcon icon={faUser} />;

    if (selectedOrganization?.logo_url) {
      return (
        <Image
          src={selectedOrganization.logo_url}
          key={selectedOrganization.logo_url}
          alt={selectedOrganization.logo_url}
        />
      );
    }

    return <FontAwesomeIcon icon={faBuilding} />;
  }, [isOrganizationContext, selectedOrganization?.logo_url]);

  const label = useMemo(() => {
    return isOrganizationContext ? (
      <FormattedMessage id="General.organization" />
    ) : (
      `Hi! ${firstName}`
    );
  }, [firstName, isOrganizationContext]);

  const title = useMemo(() => {
    let nonOrganizationTitle: string | JSX.Element = dropdownOpen ? (
      email
    ) : (
      <FormattedMessage id="General.profile" />
    );

    return !isOrganizationContext
      ? nonOrganizationTitle
      : selectedOrganization?.name ?? '-';
  }, [dropdownOpen, email, isOrganizationContext, selectedOrganization?.name]);

  return (
    <DropdownToggle
      tag="div"
      className={classNames('ContextSelector__toggle', {
        'ContextSelector--toggled': dropdownOpen,
      })}
    >
      <div className="ContextSelector__context-information">
        <div className="ContextSelector__context-information__logo">{logo}</div>
        <div className="ContextSelector__context-information__description">
          <div
            className={classNames(
              'ContextSelector__context-information__description__label',
              {
                'ContextSelector__context-information__description__label--open':
                  dropdownOpen,
              }
            )}
          >
            {label}
          </div>
          <div
            className={classNames(
              'ContextSelector__context-information__description__title',
              {
                'ContextSelector__context-information__description__title--open':
                  dropdownOpen,
                'ContextSelector__context-information__description__title--open-organization':
                  dropdownOpen && !isOrganizationContext,
              }
            )}
          >
            {title}
            {!dropdownOpen && <SVG src={caret} className="ms-2" />}
          </div>
        </div>
      </div>
      {dropdownOpen && !isOrganizationContext && (
        <div className="ContextSelector__context-menu">
          <Link
            to="/settings/my-account"
            className="ContextSelector__context-menu__link"
          >
            <FormattedMessage id="General.myProfile" />
          </Link>
        </div>
      )}
    </DropdownToggle>
  );
};

export default ContextDisplay;
