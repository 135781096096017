import { useState } from 'react';
import { Form, Formik } from 'formik';
import { Button, ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

//types
import { AssessmentFormValues } from 'interfaces/ui';
import {
  IAssessmentTemplate,
  IUserAssessments,
} from 'interfaces/assessmentForms';

//helpers
import {
  checkIfAnswersAreEmpty,
  returnInitialValuesFromAssessmentForm,
} from 'helpers/utils/assessmentForms';

//components
import QuestionForm from 'components/QuestionForm';
import LoadingButton from 'components/LoadingButton';
import AssessmentSubmitSummary from 'components/QuestionForm/AssessmentSubmitSummary';

type QuestionModalFormProps = {
  closeModal: () => void;
  caseId?: string;
  isMobile: boolean;
  selectedForm: IUserAssessments | IAssessmentTemplate;
  isLoading: boolean;
  isFormSubmitted: boolean;
  onSubmit: (values: AssessmentFormValues) => void;
  canEdit: boolean;
};

export enum SetStepAction {
  NEXT = 'NEXT',
  PREVIOUS = 'PREVIOUS',
}

const QuestionModalForm = ({
  closeModal,
  isMobile,
  selectedForm,
  isLoading,
  isFormSubmitted,
  onSubmit,
  canEdit,
}: QuestionModalFormProps) => {
  const [step, setStep] = useState(0);
  const [lastSetStepAction, setLastSetStepAction] =
    useState<SetStepAction | null>(null);

  const initialFormValues = returnInitialValuesFromAssessmentForm(selectedForm);

  const increaseStep = () => {
    if (selectedForm && step + 1 <= selectedForm.questions.length) {
      setStep((prev) => prev + 1);
      setLastSetStepAction(SetStepAction.NEXT);
    }
  };

  const decreaseStep = () => {
    if (selectedForm && step - 1 >= 0) {
      setStep((prev) => prev - 1);
      setLastSetStepAction(SetStepAction.PREVIOUS);
    }
  };

  const renderFooter = (
    submitForm: () => void,
    isLoading: boolean,
    values: AssessmentFormValues
  ) => {
    if (isFormSubmitted)
      return (
        <Button outline color="primary" onClick={closeModal}>
          <FormattedMessage id="General.close" />
        </Button>
      );

    const keys = Object.keys(values);
    let valuesToCheck: Record<string, string> = {};
    for (const key of keys) {
      const question = selectedForm.questions.find((el) => el.guid === key);
      if (question && question.required !== false) {
        valuesToCheck[key] = values[key];
      }
    }

    const isSubmitDisabled = checkIfAnswersAreEmpty(valuesToCheck);

    if (isMobile || selectedForm.renderAsStepInForm)
      return renderMobileFooter(submitForm, isLoading, isSubmitDisabled);

    if (!canEdit) return null;

    return (
      <>
        <Button outline color="primary" onClick={closeModal}>
          <FormattedMessage id="CaseCard.AssessmentForms.cancel" />
        </Button>
        <LoadingButton
          color="primary"
          onClick={submitForm}
          disabled={isSubmitDisabled}
          isLoading={isLoading}
        >
          <FormattedMessage id="CaseCard.AssessmentForms.saveChanges" />
        </LoadingButton>
      </>
    );
  };

  //TODO: Add footer for doctor approve/reject
  const renderMobileFooter = (
    submitForm: () => void,
    isLoading: boolean,
    isSubmitDisabled: boolean
  ) => {
    const saveButton =
      canEdit && selectedForm && step === selectedForm.questions.length - 1 ? (
        <LoadingButton
          color="primary"
          onClick={submitForm}
          disabled={isSubmitDisabled}
          isLoading={isLoading}
        >
          <FormattedMessage id="CaseCard.AssessmentForms.saveChanges" />
        </LoadingButton>
      ) : null;

    const nextStep =
      selectedForm && step < selectedForm.questions.length - 1 ? (
        <Button color="primary" onClick={increaseStep}>
          <FormattedMessage id="CaseCard.AssessmentForms.nextQuestion" />
        </Button>
      ) : null;

    return (
      <>
        {step === 0 ? (
          <Button outline color="primary" onClick={closeModal}>
            <FormattedMessage id="CaseCard.AssessmentForms.cancel" />
          </Button>
        ) : (
          <Button outline color="primary" onClick={decreaseStep}>
            <FormattedMessage id="CaseCard.AssessmentForms.prevQuestion" />
          </Button>
        )}
        {saveButton}
        {nextStep}
      </>
    );
  };

  return (
    <>
      {!!selectedForm && !!initialFormValues && (
        <Formik
          enableReinitialize={true}
          initialValues={initialFormValues}
          onSubmit={onSubmit}
        >
          {({ submitForm, values, setValues }) => (
            <>
              <ModalBody>
                <Form className="QuestionModal__modal__form">
                  {isFormSubmitted ? (
                    <AssessmentSubmitSummary />
                  ) : (
                    <QuestionForm
                      canEdit={canEdit}
                      isMobile={isMobile}
                      values={values}
                      setValues={setValues}
                      form={selectedForm}
                      step={step}
                      setStep={setStep}
                      lastSetStepAction={lastSetStepAction}
                      canDisplayQuestionIndicator={
                        selectedForm.showProgressIndicator
                      }
                    />
                  )}
                </Form>
              </ModalBody>
              <ModalFooter>
                {renderFooter(submitForm, isLoading, values)}
              </ModalFooter>
            </>
          )}
        </Formik>
      )}
    </>
  );
};

export default QuestionModalForm;
