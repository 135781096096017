import { useCaseGroupsCommonData } from '../../../../../hooks/useCaseGroupsCommonData';
import { useInitQuery } from '../../../../../../../hooks/queries/useInitQuery';
import { ISummarizedBaseCaseGroup } from '../../../../../../../interfaces/caseGroups';
import { useMemo } from 'react';
import { translateHtmlStr } from '../../../../../../../helpers/utils/cultures';
import useCultures from '../../../../../../../hooks/cultures/useCultures';

export const useProjectsTitle = (caseGroup: ISummarizedBaseCaseGroup) => {
  const { projects } = useCaseGroupsCommonData();
  const { currentCulture } = useCultures();

  useInitQuery(projects);

  const projectsTitle = useMemo(() => {
    return projects.projects.find((p) => p.id === caseGroup.project_id)?.title;
  }, [caseGroup.project_id, projects.projects]);

  const projectsTitleTranslation = useMemo(() => {
    return translateHtmlStr(projectsTitle, currentCulture.culture, false);
  }, [currentCulture.culture, projectsTitle]);

  return {
    projectsTitle,
    projectsTitleTranslation,
  };
};
