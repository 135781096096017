import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import SVG from 'react-inlinesvg';

type HoverableButtonProps = {
  buttonCss: string;
  buttonImageSrc: string;
  actionNameTranslationId: string;
  onClick: () => void;
};

const HoverableButton = ({
  buttonCss,
  buttonImageSrc,
  actionNameTranslationId,
  onClick,
}: HoverableButtonProps) => {
  const buttonClassNames = `HoverableButton__button ${buttonCss}`;

  return (
    <div className="HoverableButton">
      <Button onClick={onClick} className={buttonClassNames}>
        <SVG src={buttonImageSrc} />
      </Button>
      <div className={`HoverableButton__label`}>
        <FormattedMessage id={actionNameTranslationId} />
      </div>
    </div>
  );
};

export default HoverableButton;
