import TimePeriodFilter from 'components/TimePeriodFilter';
import useOpen from 'hooks/useOpen';
import { TimeRange } from 'interfaces/timeline';
import {
  nextPeriod,
  previousPeriod,
  setCustomDateRange,
  setTimelineDetailsTimeRange,
} from 'modules/TimelineDetails/actions';
import React, { useEffect } from 'react';
import { DateRange } from 'react-day-picker';
import { useDispatch } from 'react-redux';
import {
  useTimelineDetailsDateRangeObject,
  useTimelineDetailsTimePeriod,
  useTimelineDetailsTimeRange,
} from 'store/reducerHooks';

const DateRangeSelection = () => {
  const dispatch = useDispatch();

  const timeRange = useTimelineDetailsTimeRange();
  const dateRangeObject = useTimelineDetailsDateRangeObject();
  const timePeriod = useTimelineDetailsTimePeriod();

  const { isOpen: lastActiveMode, toggle: toggleLastActiveMode } = useOpen();

  const onDateRangeChange = (dateRange: DateRange) => {
    if (dateRange?.from && dateRange?.to)
      dispatch(
        setCustomDateRange({
          from: dateRange.from,
          to: dateRange.to,
        })
      );
  };

  const onNextPeriod = () => {
    dispatch(nextPeriod());
  };

  const onPreviousPeriod = () => {
    dispatch(previousPeriod());
  };

  const onTimeRangeChange = (timeRange: string) => {
    dispatch(setTimelineDetailsTimeRange(timeRange as TimeRange, new Date()));
  };

  useEffect(() => {
    const dateTo = new Date();
    dispatch(setTimelineDetailsTimeRange(TimeRange.DAYS_7, dateTo));
  }, [dispatch]);

  return (
    <TimePeriodFilter
      toggleLastActiveMode={toggleLastActiveMode}
      lastActiveMode={lastActiveMode}
      onDateRangeChange={onDateRangeChange}
      onNextPeriod={onNextPeriod}
      onPreviousPeriod={onPreviousPeriod}
      timeRange={timeRange}
      selectedRange={dateRangeObject}
      onTimeRangeChange={onTimeRangeChange}
      timePeriod={timePeriod}
      noMargin
    />
  );
};

export default DateRangeSelection;
