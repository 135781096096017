import { Dispatch, SetStateAction, useState } from 'react';

export type OpenHookValues = {
  isOpen: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  toggle: () => void;
  close: () => void;
  open: () => void;
};

const useOpen = (initialOpenValue: boolean = false): OpenHookValues => {
  const [isOpen, setOpen] = useState<boolean>(initialOpenValue);

  const toggle = () => setOpen((prevValue) => !prevValue);
  const close = () => setOpen(false);
  const open = () => setOpen(true);

  return { isOpen, setOpen, toggle, close, open };
};

export default useOpen;
