import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const SingleSharingRequestPlaceholder = () => {
  const buttons =
    Math.random() > 0.5 ? (
      <div className="d-flex">
        <SkeletonTheme baseColor="#237fc0">
          <Skeleton width={50} className="me-2" height={20} />
        </SkeletonTheme>
        <SkeletonTheme baseColor="#dc3545">
          <Skeleton width={70} height={20} />
        </SkeletonTheme>
      </div>
    ) : (
      <SkeletonTheme baseColor="#28a745">
        <Skeleton width={70} height={20} />
      </SkeletonTheme>
    );

  return (
    <tr>
      <td>
        <Skeleton height={20} />
      </td>
      <td>
        <Skeleton height={20} />
      </td>
      <td>
        <Skeleton height={20} />
      </td>
      <td>
        <Skeleton height={20} />
      </td>
      <td>
        <Skeleton height={20} />
      </td>
      <td>
        <Skeleton />
      </td>
      <td>{buttons}</td>
    </tr>
  );
};

export default SingleSharingRequestPlaceholder;
