import React, { PropsWithChildren } from 'react';
import classNames from 'classnames';

type TabItemProps = {
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  actions?: JSX.Element;
  classNames?: string;
};

const TabItem = (props: PropsWithChildren<TabItemProps>) => {
  return (
    <div
      className={classNames('TabItem', {
        [`${props.classNames}`]: props.classNames,
      })}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex flex-column">
          {props.title && <span className="TabItem__title">{props.title}</span>}
          {props.subtitle && (
            <span className="TabItem__subtitle">{props.subtitle}</span>
          )}
        </div>
        {props.actions && <div>{props.actions}</div>}
      </div>
      <div className="TabItem__content w-100 h-100">{props.children}</div>
    </div>
  );
};

export default TabItem;
