import React from 'react';
import graspSmall from 'assets/images/grasp_small.svg';
import { FormattedMessage } from 'react-intl';

type EmptyInformationProps = {
  graspLogo?: boolean;
  translationId?: string;
  type: 'range' | 'day';
  noMargin?: boolean;
};

const EmptyInformation = ({
  type,
  noMargin = false,
  graspLogo = true,
  translationId = undefined,
}: EmptyInformationProps) => {
  let wrapperClassName = 'EmptyPlaceholder';
  if (noMargin) wrapperClassName += ' EmptyPlaceholder--no-margin';

  const typeMessageId =
    type === 'range' ? 'General.noDataForPeriod' : 'General.noDataForDay';

  const messageId = translationId !== undefined ? translationId : typeMessageId;

  return (
    <div className={wrapperClassName}>
      {graspLogo && <img src={graspSmall} alt="grasp_logo" />}
      <div className="EmptyPlaceholder__text">
        <FormattedMessage id={messageId} />
      </div>
    </div>
  );
};

export default EmptyInformation;
