import { faBuilding } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { iconColors } from 'helpers/colors';
import {
  isOrganizationWithRole,
  UserRolesInToken,
} from 'interfaces/membership';
import { IBaseOrganization, IOrganization } from 'interfaces/organizations';
import { IOption } from 'interfaces/ui';
import React from 'react';
import { useIntl } from 'react-intl';
import FieldSelect from './FieldSelect';
import InputFieldWithImage, {
  InputFieldWithImageColor,
} from './InputFieldWithImage';
import InputFieldWithoutImage from './InputFieldWithoutImage';

type UserOrganizationSelectFieldProps = {
  organizations?: IOrganization[] | IBaseOrganization[];
  image?: JSX.Element;
  labelTranslationId?: string;
  name: string;
  onlyAdminOrganizations?: boolean;
};

const UserOrganizationSelectField = ({
  organizations,
  image,
  labelTranslationId,
  name,
  onlyAdminOrganizations = false,
}: UserOrganizationSelectFieldProps) => {
  const intl = useIntl();

  let filteredOrganizations = organizations;
  if (onlyAdminOrganizations) {
    filteredOrganizations = organizations?.filter(
      (org) =>
        isOrganizationWithRole(org) &&
        org.role.includes(UserRolesInToken.ORGANIZATION_ADMIN)
    );
  }

  const organizationOptions =
    filteredOrganizations?.map(({ id, name }) => {
      const option: IOption = {
        value: id,
        text: name,
      };
      return option;
    }) ?? [];

  const field = (
    <FieldSelect
      required={false}
      name={name}
      id={name}
      options={organizationOptions}
      defaultText={intl.formatMessage({
        id: 'PopoverFilter.emptySelection',
      })}
    />
  );

  if (image) {
    return (
      <InputFieldWithImage
        color={InputFieldWithImageColor.gray}
        image={<FontAwesomeIcon icon={faBuilding} color={iconColors.edit} />}
        labelTranslationId={labelTranslationId}
        isOptional
        key={name}
      >
        {field}
      </InputFieldWithImage>
    );
  } else
    return (
      <InputFieldWithoutImage
        key={name}
        gray
        labelTranslationId={labelTranslationId}
      >
        {field}
      </InputFieldWithoutImage>
    );
};

export default UserOrganizationSelectField;
