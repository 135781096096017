import {
  faFacebookSquare,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FeedbackModal from 'components/FeedbackModal';
import useOpen from 'hooks/useOpen';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Row } from 'reactstrap';

//images
import heart from 'assets/images/heart.svg';
import smallLogo from 'assets/images/logo-horiz-white-small.png';

const Footer = () => {
  const { isOpen, toggle: toggleModal, open: openModal } = useOpen();

  return (
    <>
      <FeedbackModal isOpen={isOpen} toggle={toggleModal} />
      <footer className="Footer">
        <Row className="g-0 Footer__content Footer__content__mobile-links">
          <Col
            lg={{
              offset: 1,
              size: 3,
            }}
            md={4}
            sm={4}
            xs={4}
          >
            <img
              src={smallLogo}
              alt="logo-small"
              className="Footer__content__company-logo"
            />
            <div className="Footer__content__company-name">Grasp AS ©</div>
            <div className="Footer__content__company-details">
              <p>Øyro 55, 5200 Os, Norway</p>
              <p>Org.nr. 918 873 724</p>
            </div>
          </Col>
          <Col md={4} sm={4} xs={4} className="Footer__content__mobile-links">
            <ul>
              <li className="Footer__content__footer-row">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://grasp.global/privacy"
                >
                  <FormattedMessage id="Footer.aboutPrivacy" />
                </a>
              </li>
              <li className="Footer__content__footer-row">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://grasp.global/privacy-policy"
                >
                  <FormattedMessage id="Footer.privacyPolicy" />
                </a>
              </li>
              <li className="Footer__content__footer-row">
                <a
                  rel="noopener noreferrer"
                  target="_blank"
                  href="https://grasp.global/terms-and-conditions"
                >
                  <FormattedMessage id="Footer.termsAndConditions" />
                </a>
              </li>
            </ul>
          </Col>
          <Col
            lg={{
              size: 2,
              offset: 5,
            }}
            md={4}
            sm={4}
            xs={4}
          >
            <div className="Footer__contact-data">
              <h3>Contact</h3>
              <p>Phone: +47 93068585</p>
              <p>contact@grasp.global</p>
            </div>
            <div className="Footer__links">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.facebook.com/GraspAS/"
                className="Footer__social-link me-4"
              >
                <FontAwesomeIcon
                  size="2x"
                  icon={faFacebookSquare}
                  color="white"
                />
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.linkedin.com/company/grasp-as"
                className="Footer__social-link "
              >
                <FontAwesomeIcon size="2x" icon={faLinkedin} color="white" />
              </a>
            </div>
            <Button
              className={`bg-transparent btn-transparent p-0 Footer__feedback-button`}
              onClick={openModal}
            >
              <FormattedMessage id="Footer.FeedbackModal.submitFeedback" />
            </Button>
          </Col>
        </Row>
        <div className="Footer__info">
          <ul className="d-flex justify-content-between mb-0">
            <li className="Footer__content__footer-row ">
              <img
                src={smallLogo}
                alt="logo-small"
                className="Footer__content__company-logo-bottom"
              />
            </li>
            <li className="Footer__content__footer-row">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://grasp.global/privacy-policy"
              >
                <FormattedMessage id="Footer.privacyPolicy" />
              </a>
            </li>
            <li className="Footer__content__footer-row">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://grasp.global/privacy"
              >
                <FormattedMessage id="Footer.aboutPrivacy" />
              </a>
            </li>
            <li className="Footer__content__footer-row">
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://grasp.global/terms-and-conditions"
              >
                <FormattedMessage id="Footer.termsAndConditions" />
              </a>
            </li>
            <li>Copyright Grasp AS, All Rights Reserved</li>
            <li>
              Delivered with <img src={heart} alt="heart" /> by Grasp DevTeam
            </li>
          </ul>
        </div>
      </footer>
    </>
  );
};

export default Footer;
