import { CardContent } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import React, { ReactNode } from 'react';
import { useCultureEntry } from '../../../../../../../../../../../../../../hooks/cultures/useCultureEntry';
import { IChartDashboardTemplateVisualization } from '../../../../../../../../../../../../../../interfaces/dashboards';

type BasicChartWrapperProps = {
  visualization: IChartDashboardTemplateVisualization;
  children: ReactNode;
};

const BasicChartWrapper = ({
  visualization,
  children,
}: BasicChartWrapperProps) => {
  const translation = useCultureEntry(visualization.name);

  const containerStyle = visualization.container_style
    ? JSON.parse(visualization.container_style)
    : {};

  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: 'background.default',
        color: 'text.primary',
        position: 'relative',
        ...containerStyle,
      }}
      elevation={0}
    >
      <CardHeader
        title={
          <Typography sx={{ color: 'text.primary' }} variant="subtitle1">
            {translation ?? ''}
          </Typography>
        }
      />
      <CardContent
        sx={{
          width: '100%',
          height: '100%',
          flexGrow: 1,
          position: 'absolute',
          top: 0,
          left: 0,
          paddingTop: '60px',
        }}
      >
        {children}
      </CardContent>
    </Card>
  );
};

export default BasicChartWrapper;
