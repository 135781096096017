import React from 'react';
import Tree from '../../../../../../components/Tree';
import TreeFolder from '../../../../../../components/Tree/components/TreeFolder';
import TreeFile from '../../../../../../components/Tree/components/TreeFile';

const EducationMaterialCategoriesTreePlaceholder = () => {
  return (
    <Tree>
      <TreeFolder name={<span></span>} isLoading={true}>
        <TreeFile name={<span></span>} isLoading={true} />
      </TreeFolder>
      <TreeFolder name={<span></span>} isLoading={true}>
        <TreeFile name={<span></span>} isLoading={true} />
      </TreeFolder>
      <TreeFolder name={<span></span>} isLoading={true}>
        <TreeFile name={<span></span>} isLoading={true} />
      </TreeFolder>
      <TreeFolder name={<span></span>} isLoading={true}>
        <TreeFile name={<span></span>} isLoading={true} />
      </TreeFolder>
      <TreeFolder name={<span></span>} isLoading={true}>
        <TreeFile name={<span></span>} isLoading={true} />
      </TreeFolder>
      <TreeFolder name={<span></span>} isLoading={true}>
        <TreeFile name={<span></span>} isLoading={true} />
      </TreeFolder>
    </Tree>
  );
};

export default EducationMaterialCategoriesTreePlaceholder;
