import { CultureEntry } from './cultures';

export interface IBaseEducationMaterial {
  id: string;
  organization_id: string;
  case_category_id?: string;
  name: string;
  name_translations: CultureEntry<string>[];
  description: string;
  description_translations: CultureEntry<string>[];
  is_premium: boolean;
  is_default: boolean;
}

export interface IEducationMaterial extends IBaseEducationMaterial {
  assets: IAsset[];
  assets_with_cultures: CultureEntry<IAsset[]>[];
  culture_details: ICultureDetails[];
  rules: IBaseEducationMaterialRule[];
}

export interface IBaseEducationMaterialRule {
  case_category_id: string;
  case_sub_category_id: string;
  availability_status: AvailabilityStatus;
  rule: string | null;
}

export interface IBaseEducationMaterialCategory {
  id: string;
  organization_id: string;
  parent_category_id: string;
  name: string;
  name_translations: CultureEntry<string>[];
  description: string;
  description_translations: CultureEntry<string>[];
  icon_url: string;
  sequence: number;
  is_premium: boolean;
}

export interface IEducationMaterialCategoryTreeItem
  extends IBaseEducationMaterialCategory {
  categories: IEducationMaterialCategoryTreeItem[];
  materials: IEducationMaterial[];
}

export interface IUpsertEducationMaterialCategory {
  id: string | null;
  organization_id: string;
  parent_category_id: string | null;
  name: string;
  name_translations: CultureEntry<string>[];
  description: string;
  description_translations: CultureEntry<string>[];
  icon_url: string;
  is_premium: boolean;
  sequence: number;
}

export interface IUpsertEducationMaterial {
  id: string | null;
  organization_id: string;
  name: string;
  name_translations: CultureEntry<string>[];
  description: string;
  description_translations: CultureEntry<string>[];
  case_category_id: string | undefined;
  rules: ICreateEducationMaterialRule[];
  is_default: boolean;
  is_premium: boolean;
}

export interface ICultureDetails {
  culture: string;
  name: string;
  description: string;
}

export interface ICreateEducationMaterialRule
  extends IBaseEducationMaterialRule {}

export interface IAsset {
  id: string;
  organization_id: string;
  name: string;
  name_translations: CultureEntry<string>[];
  description: string;
  description_translations: CultureEntry<string>[];
  thumbnail_url: string | null;
  image_url: string | null;
  stream_url: IStreamUrl[] | null;
  type: AssetType;
  status: AssetStatus;
}

export enum AvailabilityStatus {
  Mandatory = 'Mandatory',
  Suggested = 'Suggested',
  Available = 'Available',
}

export enum AssetType {
  Video = 'Video',
  Image = 'Image',
  Attachment = 'Attachment',
}

export enum AssetStatus {
  ReadyToUploading = 'ReadyToUploading',
  Uploaded = 'Uploaded',
  ReadyToEncoding = 'ReadyToEncoding',
  Encoded = 'Encoded',
}

export interface IVideoAsset {
  id: string;
  name: string;
  description: string;
  case_category_id: string;
  stream_url: IStreamUrl[];
  thumbnail_url: string;
  blob_name: string;
  status: VideoAssetEncodingStatus;
}

export interface IStreamUrl {
  type: string;
  url: string;
}

export enum CreateRuleAvailabilityStatus {
  Mandatory = 'Mandatory',
  Suggested = 'Suggested',
}

export enum VideoAssetEncodingStatus {
  ReadyToEncoded = 'ReadyToEncoded',
  Encoded = 'Encoded',
}
