import { faCaretLeft, faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { useDateFormatting } from './hooks/useDateFormatting';
import { useNavigateDates } from './hooks/useNavigateDates';
import CustomDatePicker from '../../../../../../../../../CustomDatePicker';
import { useTypedContext } from '../../../../../../../../../../hooks/useTypedContext';
import { DashboardDataReducerContext } from '../../../../types';
import { useHandleCustomDateRange } from '../../../DashboardDateSelector/hooks/useHandleCustomDateRange';
import { DashboardDatePeriod } from '../../../../../../../../../../interfaces/dashboards';

const DashboardDateRangeFilter = () => {
  const dateFormatted = useDateFormatting();
  const { handlePrev, handleNext } = useNavigateDates();

  const [dashboardData] = useTypedContext(DashboardDataReducerContext);
  const handleChangeDateRange = useHandleCustomDateRange();

  return (
    <div className="AwareCasesDateRangeFilter">
      <FontAwesomeIcon
        className="mx-3"
        onClick={handlePrev}
        icon={faCaretLeft}
      />
      {dashboardData.template.date_selection.date_period !==
      DashboardDatePeriod.CustomPeriod ? (
        <span
          className="AwareCasesDateRangeFilter__content"
          dangerouslySetInnerHTML={{ __html: dateFormatted }}
        />
      ) : (
        <div className="AwareCasesDateRangeFilter__calendar">
          <CustomDatePicker
            inputIcon={false}
            hideTime={true}
            onDateAccept={handleChangeDateRange}
            selectedRange={{
              from: dashboardData.template.date_selection.date_from,
              to: dashboardData.template.date_selection.date_to,
            }}
            hideSummary={true}
            displayPredefinedTimeRange={false}
          />
        </div>
      )}
      <FontAwesomeIcon
        className="mx-3"
        onClick={handleNext}
        icon={faCaretRight}
      />
    </div>
  );
};

export default DashboardDateRangeFilter;
