import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { displaySuccessNotification } from '../../../modules/Notifications';
import { removeFeedbackResource } from '../../feedbackResources';

export type RemoveFeedbackResourceFunctionBody = {
  organizationId: string;
  groupCode: string;
  culture: string;
};

const useRemoveFeedbackResource = (successFb?: () => void) => {
  return useMutationWithResultNotification({
    onSuccess: () => {
      displaySuccessNotification('FeedbackResources.variantsRemoved');
      successFb && successFb();
    },
    asyncApiFunction: async (body: RemoveFeedbackResourceFunctionBody) => {
      await removeFeedbackResource(
        body.organizationId,
        body.groupCode,
        body.culture
      );
    },
    resetOnResult: true,
  });
};

export default useRemoveFeedbackResource;
