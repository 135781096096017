import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink } from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import CustomTable from '../../../../../../components/CustomTable';
import Skeleton from 'react-loading-skeleton';

const headerItems = [
  'CaseGroups.CaseGroupsList.project',
  'CaseGroups.CaseGroupsList.treatmentPlan',
  'CaseGroups.CaseGroupsList.startingDate',
  'CaseGroups.CaseGroupsList.groupSequence',
  'CaseGroups.CaseGroupsList.participantsCount',
  'CaseGroups.CaseGroupsList.maximumParticipantsCount',
  'CaseGroups.CaseGroupsList.actions',
];

const CaseGroupsListPlaceholder = () => {
  const bodyRows = [...Array.from(Array(5).keys())].map((sequence) => {
    return (
      <tr key={sequence}>
        <td valign="middle">
          <Skeleton width={120} />
        </td>
        <td valign="middle">
          <Skeleton width={150} />
        </td>
        <td valign="middle">
          <Skeleton width={150} />
        </td>
        <td valign="middle">
          <Skeleton width={30} />
        </td>
        <td valign="middle">
          <Skeleton width={30} />
        </td>
        <td valign="middle">
          <Skeleton width={30} />
        </td>
        <td valign="middle" width="160px">
          <Button color="link" className="d-flex align-items-center gap-1">
            <FontAwesomeIcon icon={faExternalLink} />
            <FormattedMessage id="CaseGroupItem.openDetails" />
          </Button>
        </td>
      </tr>
    );
  });

  return (
    <div className="mt-5">
      <CustomTable
        clickableRows={false}
        stretched={false}
        headerItems={headerItems}
        bodyRows={bodyRows}
      />
    </div>
  );
};

export default CaseGroupsListPlaceholder;
