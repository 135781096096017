import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { IMutationRequest } from '../../../interfaces/request';
import { OrganizationQueryParams } from '../../../interfaces/queryParams';
import { uploadOrganizationImage } from '../../organization';
import { OrganizationUploadTarget } from '../../../interfaces/organizations';

type UploadOrganizationImageBody = {
  file: File;
  target: OrganizationUploadTarget;
};

const useUploadOrganizationImage = ({
  params,
  options,
  silent = false,
}: IMutationRequest<OrganizationQueryParams>) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (body: UploadOrganizationImageBody) => {
      await uploadOrganizationImage(
        params.organizationId,
        body.target,
        body.file
      );
    },
    onSuccess: () => {
      if (!silent)
        displaySuccessNotification(
          'Api.Mutations.Organizations.UploadOrganizationImage.success'
        );

      options.successFb?.();
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'Api.Mutations.Organizations.UploadOrganizationImage.failure'
      );
      options.errorFb?.();
    },
    resetOnResult: false,
  });
};

export default useUploadOrganizationImage;
