import { useCallback, useMemo, useState } from 'react';

export type StepsHookValues<T extends {}> = {
  step: T;
  steps: T[];
  next: () => void;
  prev: () => void;
  index: number;
};

export const useSteps = <T extends string, TEnumValue extends string>(
  enumVariable: { [key in T]: TEnumValue },
  initialIndex: number = 0
): StepsHookValues<TEnumValue> => {
  const [step, setStep] = useState(Object.values(enumVariable)[initialIndex]);
  const steps = useMemo(() => Object.values(enumVariable), [enumVariable]);

  const [index, setIndex] = useState<number>(initialIndex);

  const next = useCallback(() => {
    const currentStepIndex = steps.findIndex((p) => p === step);

    if (currentStepIndex === -1 || currentStepIndex + 1 === steps.length)
      return;

    setStep(steps[currentStepIndex + 1]);
    setIndex(currentStepIndex + 1);
  }, [step, steps]);

  const prev = useCallback(() => {
    const currentStepIndex = steps.findIndex((p) => p === step);

    if (currentStepIndex === -1 || currentStepIndex - 1 < 0) return;

    setStep(steps[currentStepIndex - 1]);
    setIndex(currentStepIndex - 1);
  }, [step, steps]);

  return {
    steps: steps as TEnumValue[],
    step: step as TEnumValue,
    next,
    prev,
    index,
  };
};
