import React from 'react';
import submitSummaryIcon from 'assets/images/submit_summary.svg';
import { FormattedMessage } from 'react-intl';

const AssessmentSubmitSummary = () => {
  return (
    <div className="text-center AssessmentSummary">
      <div className="AssessmentSummary__title">
        <FormattedMessage id="General.thankYou" />
      </div>
      <img src={submitSummaryIcon} alt={submitSummaryIcon} />
      <div className="AssessmentSummary__description">
        <div className="AssessmentSummary__description__header">
          <FormattedMessage id="General.great" />!
        </div>
        <FormattedMessage id="CaseCard.AssessmentForms.filledInfo" />!
      </div>
    </div>
  );
};

export default AssessmentSubmitSummary;
