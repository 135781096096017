export type OrganizationFunctionality = {
  id: string;
  organization_id: string;
  functionality_id: string;
  functionality: Functionality;
};

export enum FunctionalityCode {
  ORGANIZATIONS_INVITE_USERS = 'Organizations.InviteUsers',
  ORGANIZATION_DASHBOARD_VIEW = 'Organizations.Dashboard',
  CASE_LIST_VIEW = 'CasesList.View',
  CASE_LIST_SEND_MESSAGE = 'CasesList.SendMessageIndividual',
  CASE_LIST_CHECK_PATIENT = 'CasesList.CheckPatient',
  CASE_LIST_SEND_MESSAGE_GROUP = 'CasesList.SendMessageGroup',
  PARTICIPANTS_LIST_VIEW = 'ParticipantsList.View',
  CASE_GROUPS_LIST_VIEW = 'CaseGroupsList.View',
  PROJECT_SUBMISSIONS_LIST_VIEW = 'ProjectSubmissionsList.View',
  CONVERSATIONS_EDUCATION_MATERIALS = 'Conversations.EducationMaterials',
  OrganizationTabsLocations = 'OrganizationTabs.Locations',
  OrganizationTabsGrasps = 'OrganizationTabs.Grasps',
  OrganizationTabsGraspSettings = 'OrganizationTabs.GraspSettings',
  OrganizationTabsTrendVisualizations = 'OrganizationTabs.TrendVisualizations',
  OrganizationTabsSymptoms = 'OrganizationTabs.Symptoms',
  OrganizationTabsFunctionalities = 'OrganizationTabs.Functionalities',
  OrganizationTabsCaseCategories = 'OrganizationTabs.CaseCategories',
  OrganizationTabsObservationMeanings = 'OrganizationTabs.ObservationMeanings',
  OrganizationTabsOtherDevices = 'OrganizationTabs.OtherDevices',
  OrganizationTabsUsers = 'OrganizationTabs.Users',
  OrganizationTabsAccessProfiles = 'OrganizationTabs.AccessProfiles',
  OrganizationTabsForms = 'OrganizationTabs.Forms',
  OrganizationTabsEducation = 'OrganizationTabs.Education',
  OrganizationTabsReportSchedules = 'OrganizationTabs.ReportSchedules',
}

export type Functionality = {
  id: string;
  name: string;
  name_no: string;
  code: FunctionalityCode;
  description: null | string;
  description_no: null | string;
};
