import React from 'react';
import {
  ClosedListValidConversationQuestion,
  ClosedListValidConversationQuestionAnswer,
} from '../../../../../../../../../../../../../../interfaces/conversations';
import { Button } from 'reactstrap';
import { useTypedContext } from '../../../../../../../../../../../../../../hooks/useTypedContext';
import { LanguageContext } from '../../../../../../../../types';
import { AVAILABLE_CULTURES } from '../../../../../../../../../../../../../../components/CultureSelector/CultureSelector';
import { format } from 'date-fns';

type ClosedListQuestionItemProps = {
  item: ClosedListValidConversationQuestion;
};

const ClosedListQuestionItem = ({ item }: ClosedListQuestionItemProps) => {
  const language = useTypedContext(LanguageContext);
  const currentHour = format(new Date(), 'HH:HH a');

  const title =
    language.culture === AVAILABLE_CULTURES.EN ? item.title : item.title_nb_no;

  const titleHtml =
    language.culture === AVAILABLE_CULTURES.EN
      ? item.title_html
      : item.title_html_nb_no;

  const getAnswerTitle = (answer: ClosedListValidConversationQuestionAnswer) =>
    language.culture === AVAILABLE_CULTURES.EN
      ? answer.title
      : answer.title_nb_no;

  return (
    <div className="ConversationTemplateRangeQuestionItem">
      <div className="ConversationTemplateRangeQuestionItem__question d-flex flex-column">
        {titleHtml ? (
          <span
            className="ConversationTemplatePlainTextItem__content__text"
            dangerouslySetInnerHTML={{ __html: titleHtml }}
          />
        ) : (
          <span className="ConversationTemplatePlainTextItem__content__text">
            {title}
          </span>
        )}
        <span className="ConversationTemplatePlainTextItem__content__hour">
          {currentHour}
        </span>
      </div>
      <div className="ConversationTemplateRangeQuestionItem__slider">
        <div className="ConversationTemplateRangeQuestionItem__question d-flex flex-column gap-2">
          {item.answers.map((answer) => (
            <Button
              key={answer.title}
              color="primary"
              block={true}
              outline={!answer.is_selected}
            >
              {getAnswerTitle(answer)}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ClosedListQuestionItem;
