import { useMemo } from 'react';
import { BreadcrumbItemType } from '../../../components/PageHeader/Breadcrumbs';
import { useIntl } from 'react-intl';
import { useCommonData } from './useCommonData';
import { useInitQuery } from '../../../hooks/queries/useInitQuery';
import { useCommonParams } from './useCommonParams';

export const useBreadcrumbs = () => {
  const intl = useIntl();

  const { userId, caseId } = useCommonParams();

  const { userData, userCaseDetails } = useCommonData();

  useInitQuery(userData);
  useInitQuery(userCaseDetails);

  const personalityName = useMemo(() => {
    const personalityFirstName = userData.data?.personality?.first_name;
    const personalityLastName = userData.data?.personality?.last_name;

    if (!personalityFirstName || !personalityLastName) return undefined;

    return `${personalityLastName} ${personalityFirstName}`;
  }, [
    userData.data?.personality?.first_name,
    userData.data?.personality?.last_name,
  ]);

  return useMemo((): BreadcrumbItemType[] => {
    return [
      {
        text: intl.formatMessage({ id: 'CaseDashboard.users' }),
        link: null,
        key: 'users',
      },
      {
        text: personalityName ?? '',
        link: `/dashboard`,
        key: 'user-name',
      },
      {
        text: intl.formatMessage({ id: 'CaseDashboard.cases' }),
        link: null,
        key: 'cases',
      },
      {
        text: userCaseDetails.data?.title ?? '',
        link: `/users/${userId}/cases/${caseId}`,
        key: 'case-dashboard',
      },
    ];
  }, [caseId, intl, personalityName, userCaseDetails.data?.title, userId]);
};
