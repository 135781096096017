import { IMutationProps } from '../../../interfaces/ui';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { deleteCaseGroupConversationSchedule } from '../../conversationSchedules';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

type useDeleteCaseGroupConversationSchedulesParams = {
  organizationId: string;
  caseGroupId: string;
};

const useDeleteCaseGroupConversationSchedule = (
  params: useDeleteCaseGroupConversationSchedulesParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (id: string) => {
      await deleteCaseGroupConversationSchedule(
        params.organizationId,
        params.caseGroupId,
        id
      );
    },
    onSuccess: () => {
      displaySuccessNotification(
        'Api.Mutations.ConversationSchedules.DeleteCaseGroupConversationSchedule.success'
      );

      if (options.successFb) options.successFb();
    },
    onErrorArray: () => {
      displayErrorNotification(
        'Api.Mutations.ConversationSchedules.DeleteCaseGroupConversationSchedule.failure'
      );

      if (options.errorFb) options.errorFb();
    },
    resetOnResult: true,
  });
};

export default useDeleteCaseGroupConversationSchedule;
