import { newAccessToken } from 'api/membership';
import useLoginRedirect from 'hooks/useLoginRedirect';
import { ILoginResponse, LoginMethod } from 'interfaces/membership';
import { IErrorWithCode } from 'interfaces/response';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

type UseNewAccessTokenProps = {
  redirectUrl?: string;
  loginAs: LoginMethod;
};

type UseNewAccessTokenAsyncFunctionProps = {
  token: string;
  provider?: string;
};

function useNewAccessToken({
  loginAs,
  redirectUrl = undefined,
}: UseNewAccessTokenProps) {
  const dispatch = useDispatch();
  const redirectFunction = useLoginRedirect();

  const result = useMutation<
    ILoginResponse,
    IErrorWithCode,
    UseNewAccessTokenAsyncFunctionProps
  >(async ({ token }) => {
    const { data } = await newAccessToken(token);

    return { ...data.data };
  });

  useEffect(() => {
    if (!result.isLoading && result.isSuccess && result.data) {
      if (redirectUrl) {
        redirectFunction(result.data, redirectUrl, loginAs);
      } else {
        redirectFunction(result.data, null, loginAs);
      }
    }
  }, [
    result.isSuccess,
    result.data,
    dispatch,
    result.isLoading,
    redirectFunction,
    redirectUrl,
    loginAs,
  ]);

  return result;
}

export default useNewAccessToken;
