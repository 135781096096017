import { cloneDeep } from 'lodash';
import { useMemo } from 'react';
import { ScheduleItemTemplatePartsOfADayDefinition } from '../../../../../index';
import { ScheduleBlockVariant } from '../index';

export const useScheduleBlockPosition = (
  partOfADay: ScheduleItemTemplatePartsOfADayDefinition
) => {
  const now = new Date();

  const startDate = cloneDeep(now);
  startDate.setHours(
    Number(partOfADay.available_from_hour.split(':')[0]),
    Number(partOfADay.available_from_hour.split(':')[1]),
    0
  );

  const endDate = cloneDeep(now);
  endDate.setHours(
    Number(partOfADay.available_to_hour.split(':')[0]),
    Number(partOfADay.available_to_hour.split(':')[1]),
    0
  );

  const diffInMs = endDate.getTime() - startDate.getTime();
  const diffInMin = Math.round(diffInMs / 60000);

  const leftOffset = useMemo(() => {
    let numberValueOfHour = Number(
      partOfADay.available_from_hour.split(':')[0]
    );
    numberValueOfHour +=
      Number(partOfADay.available_from_hour.split(':')[1]) / 60;
    return (numberValueOfHour / 24) * 100;
  }, [partOfADay.available_from_hour]);

  const variant = useMemo(() => {
    return diffInMin < 120
      ? ScheduleBlockVariant.Condensed
      : ScheduleBlockVariant.Standard;
  }, [diffInMin]);

  const positionStyle = useMemo(() => {
    return {
      position: 'absolute' as const,
      left: `${leftOffset}%`,
      minWidth: `${(diffInMin / 1440) * 100}%`,
    };
  }, [diffInMin, leftOffset]);

  return {
    variant: variant,
    positionStyle: positionStyle,
  };
};
