import { createContext } from 'react';
import { ReactState } from '../../../../../interfaces/ui';

export type SelectedDateContextType = {
  selected: ReactState<Date>;
  caseStart: Date;
  caseEnd: Date;
};

export const SelectedDateContext = createContext<
  SelectedDateContextType | undefined
>(undefined);
