import { formatDate, formatTime } from 'helpers/dates';
import React, { useMemo } from 'react';
import { ListGroupItem } from 'reactstrap';

type ActivityListItemLayoutProps = {
  imageSrc: string;
  textContent: string | JSX.Element;
  timeStart: string;
  timeEnd?: string;
};

const ListItemLayout: React.FC<ActivityListItemLayoutProps> = ({
  imageSrc,
  timeStart,
  timeEnd,
  textContent,
}) => {
  const startDateObject = useMemo(() => new Date(timeStart), [timeStart]);

  let dateString = useMemo(() => {
    if (!timeEnd) return formatTime(startDateObject);
    if (timeEnd && startDateObject.getTime() !== new Date(timeEnd).getTime())
      return `${formatDate(timeStart)} - ${formatDate(timeEnd)}`;

    return formatTime(startDateObject);
  }, [startDateObject, timeEnd, timeStart]);

  return (
    <ListGroupItem className="ActivityListItem">
      <div className="ActivityListItem__icon">
        {imageSrc ? <img src={imageSrc} alt="history_icon" /> : null}
      </div>
      <div className="ActivityListItem__icon__text-container">
        <div className="ActivityListItem__comment">{textContent}</div>
        <div className="ActivityListItem__date">{dateString}</div>
      </div>
    </ListGroupItem>
  );
};

export default ListItemLayout;
