import React from 'react';
import ReportsList from './components/ReportsList';
import useOpen from 'hooks/useOpen';
import ReportsHeader from './components/ReportsHeader';
import GenerateReportModal from './modals/GenerateReportModal';
import { useReportsFilters } from './hooks/useReportsFilters';
import { useCommonData } from './hooks/useCommonData';
import { useInitQuery } from '../../hooks/queries/useInitQuery';

const Reports = () => {
  const { isOpen: isGenerateReportOpen, toggle: toggleGenerateReport } =
    useOpen();

  const {
    filters,
    handleReportDateRange,
    handleReportTypeStatus,
    handleExecutionStatus,
  } = useReportsFilters();

  const { reports } = useCommonData(filters);

  useInitQuery(reports);

  return (
    <>
      {isGenerateReportOpen && (
        <GenerateReportModal
          refetchReports={reports.refetch}
          isOpen={isGenerateReportOpen}
          toggle={toggleGenerateReport}
        />
      )}
      <div className="Reports">
        <ReportsHeader
          filters={filters}
          handleReportDateRange={handleReportDateRange}
          handleExecutionStatus={handleExecutionStatus}
          handleReportTypeStatus={handleReportTypeStatus}
          toggleGenerateReport={toggleGenerateReport}
        />
        <ReportsList filters={filters} />
      </div>
    </>
  );
};

export default Reports;
