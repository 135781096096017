import {
  NonQuestionBasedTrendsChartElement,
  TrendsChartConfig,
  TrendsChartData,
} from '../../../../../types';
import { TrendVisualizationChartType } from '../../../../../../../interfaces/trendVisualizations';
import { getLineChartFigureItem } from './getLineChartFigureItem';
import { getBarChartFigureItem } from './getBarChartFigureItem';

export const getNonQuestionsFigureItem = (
  selectedData: TrendsChartData<string | number>,
  item: NonQuestionBasedTrendsChartElement,
  config: TrendsChartConfig,
  isSelected: (item: string) => boolean
) => {
  let elements: any[] = [];

  const stackNames = [
    ...new Set(
      selectedData.data
        .map((p) => {
          return Object.keys(p).filter((p) => p !== 'name');
        })
        .flatMap((p) => p)
    ),
  ];

  stackNames.forEach((stackName) => {
    const itemColor = config.observations.find(
      (p) => p.id === stackName
    )?.color;

    if (item.chart_type === TrendVisualizationChartType.LineChart) {
      elements.push(
        getLineChartFigureItem({
          lineKey: `line|${item.type}|${stackName}`,
          dataKey: stackName,
          connectNulls: item.generate_points_nearby,
          stroke: itemColor,
          hide: !isSelected(stackName),
        })
      );

      return;
    }

    elements.push(
      getBarChartFigureItem({
        barKey: `bar|${item.type}|${stackName}`,
        dataKey: stackName,
        stackId: `bar|${item.type}`,
        fill: itemColor,
        hide: !isSelected(stackName),
      })
    );
  });

  return elements;
};
