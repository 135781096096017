import { FormattedMessage } from 'react-intl';
import calandarIcon from 'assets/images/calendar_icon_bigger.svg';
import AssessmentsForms, {
  DisplayingComponent,
} from '../../TimelineDetails/components/tabs/DayByDayTab/components/DayDetails/tabs/AssessmentForms';
import { Spinner } from 'reactstrap';
import QuestionModal from 'modules/TimelineDetails/components/tabs/DayByDayTab/components/QuestionModal';
import { useDispatch } from 'react-redux';
import { setAssessmentFormIdentification } from 'modules/TimelineDetails/actions';
import {
  useCaseFormsDayByDayRefetchRequest,
  useTimelineDetailsAssessmentFormIdentification,
} from 'store/reducerHooks';

type DayScheduleProps = {
  userId: string;
  masterTimelineId: string | undefined;
  isLoading: boolean;
};

const DaySchedule = ({
  userId,
  masterTimelineId,
  isLoading,
}: DayScheduleProps) => {
  const selectedDate = new Date();
  const refetchRequest = useCaseFormsDayByDayRefetchRequest();

  const dispatch = useDispatch();

  const selectedFormIdentification =
    useTimelineDetailsAssessmentFormIdentification();

  const closeModal = () => {
    dispatch(setAssessmentFormIdentification(null));
  };

  return (
    <>
      {masterTimelineId && (
        <QuestionModal
          closeModal={closeModal}
          assessmentFormIdentification={selectedFormIdentification}
          selectedDate={selectedDate}
          userId={userId}
          timelineId={masterTimelineId}
        />
      )}
      <div className="SummaryCard card-container">
        <div className="cardContainer__header d-flex flex-column flex-md-row justify-content-between h-100">
          <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
            <div className="cardContainer__header__title">
              <img src={calandarIcon} alt="form_icon" />
              <FormattedMessage id="Summary.daySchedule" />
            </div>
            {isLoading || masterTimelineId === undefined ? (
              <div className="w-100 mt-4 d-flex justify-content-center">
                <Spinner color="primary" />
              </div>
            ) : (
              <AssessmentsForms
                selectedDate={selectedDate}
                userId={userId}
                timelineId={masterTimelineId}
                refetchRequest={refetchRequest}
                dashboard={true}
                displayingComponent={DisplayingComponent.DAY_SCHEDULE}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default DaySchedule;
