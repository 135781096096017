import { ReportParameter, ReportTemplateCode } from 'interfaces/reports';
import React from 'react';
import GenerateReportForm from './GenerateReportForm';

type GenerateSubjectiveDataReportFormProps = {
  fillInitialParameters: (
    parameters: ReportParameter[],
    code: ReportTemplateCode
  ) => void;
};

const GenerateSubjectiveDataForm = ({
  fillInitialParameters,
}: GenerateSubjectiveDataReportFormProps) => {
  return (
    <GenerateReportForm
      code={ReportTemplateCode.SUBJECTIVE_DATA}
      fillInitialParameters={fillInitialParameters}
    />
  );
};

export default GenerateSubjectiveDataForm;
