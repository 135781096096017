import React, { useContext } from 'react';
import MultiStateButton, {
  MultiStateButtonItemDefinition,
} from '../../../../../MultiStateButton';
import { ChartVisualizationContext } from '../../../../types';
import { TrendsChartVisualizationHookValues } from '../../../../hooks/useTrendsChartVisualization';
import { getIconForTemplateItemType } from '../../../../../../helpers/utils/icons';

const TrendsChartSwitch = () => {
  const visualization = useContext(
    ChartVisualizationContext
  ) as TrendsChartVisualizationHookValues;

  const elements: MultiStateButtonItemDefinition[] =
    visualization.visualization.elements.map((element) => {
      const handleSelectType = () => visualization.handleSelect(element.type);

      return {
        key: element.type,
        icon: getIconForTemplateItemType(element.type),
        onClick: handleSelectType,
      };
    });

  if (elements.length === 1) return <></>;

  return <MultiStateButton items={elements} />;
};

export default TrendsChartSwitch;
