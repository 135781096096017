import { useInfiniteQuery } from 'react-query';
import { IError, IInfiniteResult } from '../../../interfaces/response';
import { IProject } from '../../../interfaces/submissions';
import QueryKeys from '../queryKeys';
import { getProjectsWithPaging } from '../../submissions';
import {
  convertIntoObjectWithPageInfo,
  returnAllDataFromResponse,
} from '../../../helpers/utils/reactQueryHelpers';
import {
  OrganizationQueryParams,
  SearchAndPagingQueryParams,
} from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

type UseInfiniteProjectsParams = OrganizationQueryParams &
  SearchAndPagingQueryParams;

export const useInfiniteProjects = ({
  params,
  options,
}: IQueryRequest<UseInfiniteProjectsParams>) => {
  const { organizationId, searchValue, pageSize = 20 } = params;
  const response = useInfiniteQuery<IInfiniteResult<IProject[]>, IError>({
    ...options,
    queryKey: [QueryKeys.getProjects, organizationId, searchValue],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getProjectsWithPaging(organizationId, {
        page: pageParam,
        page_size: pageSize,
        search: searchValue,
      });

      return convertIntoObjectWithPageInfo<IProject[]>(data);
    },
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage;
    },
    getPreviousPageParam: (firstPage) => firstPage.previousPage,
  });

  return {
    ...response,
    totalItemsCount: response.data?.pages[0].totalItemsCount ?? 0,
    projects: returnAllDataFromResponse<IProject>(response),
  };
};
