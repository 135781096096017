import { faEdit, faExchange, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useInfiniteGraspList from 'api/queries/grasps/useInfiniteGraspList';
import useInfiniteOrganizationLocations from 'api/queries/organizations/useInfiniteOrganizationLocations';
import graspIcon from 'assets/images/grasp_svg_icon.svg';
import DebouncedInput from 'components/DebouncedInput';
import Guard from 'components/Guard';
import useOpen from 'hooks/useOpen';
import useRequiredParams from 'hooks/useRequiredParams';
import {
  GroupSelectionIdentifiers,
  useRowSelection,
} from 'hooks/useRowSelection';
import { GraspFilters, IInfiniteGraspOrganization } from 'interfaces/grasps';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, FormGroup, Label } from 'reactstrap';
import TabLayout, {
  TabLayoutType,
} from '../../../../../../../../components/layouts/TabLayout';
import { UserRoles } from '../../../../../../../../interfaces/membership';
import AddGraspModal from '../../../../modals/AddGraspModal';
import ManualCreateGraspModal from '../../../../modals/ManualCreateGraspModal';
import TransferGraspModal from '../../../../modals/TransferGraspModal';
import UploadGraspsModal from '../../../../modals/UploadGraspsModal';
import EditGraspModal from './components/EditGraspModal';
import GraspFilter from './components/GraspFilter';
import GraspInOrgMultipleUpdateModal from './components/GraspInOrgMultipleUpdateModal';
import GraspListInOrganizationTab from './components/GraspListInOrganizationTab';

const GraspsTab = () => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const [editedGrasp, setEditedGrasp] =
    useState<IInfiniteGraspOrganization | null>(null);

  const {
    isOpen: addGraspsOpen,
    toggle: addGraspsToggle,
    close: addGraspsClose,
  } = useOpen();

  const { isOpen: createGraspOpen, toggle: createGraspToggle } = useOpen();
  const { isOpen: uploadGraspsOpen, toggle: uploadGraspsToggle } = useOpen();
  const { isOpen: modifyMultipleOpen, toggle: toggleModifyMultiple } =
    useOpen();

  const transferGraspModal = useOpen();

  const onEditClick = (editedGrasp: IInfiniteGraspOrganization) => {
    setEditedGrasp(editedGrasp);
  };

  const closeEditModal = () => setEditedGrasp(null);

  const [filterValue, setFilterValue] = useState('');
  const onDebounce = (value: string) => setFilterValue(value);

  const [filters, setFilters] = useState<GraspFilters>({});

  const handleCreateGraspMode = () => {
    addGraspsClose();
    createGraspToggle();
  };

  const handleUploadGraspsMode = () => {
    addGraspsClose();
    uploadGraspsToggle();
  };

  const { locations } = useInfiniteOrganizationLocations({
    params: {
      organizationId,
      pageSize: 100,
    },
    options: { enabled: false },
  });

  const {
    grasps,
    fetchNextPage,
    isLoading,
    hasNextPage,
    refetch,
    totalItemsCount,
  } = useInfiniteGraspList({
    params: {
      organizationId,
      searchValue: filterValue,
      pageSize: 25,
      filters,
    },
  });

  const hasMore = hasNextPage ? !isLoading && hasNextPage : false;

  const rowSelectionProps =
    useRowSelection<GroupSelectionIdentifiers>(totalItemsCount);

  const isTransferToOrganizationDisabled =
    rowSelectionProps.rowsSelectedCount <= 0;

  return (
    <>
      <TransferGraspModal
        isOpen={transferGraspModal.isOpen}
        toggle={transferGraspModal.toggle}
      />
      <AddGraspModal
        selectCreateGraspMode={handleCreateGraspMode}
        selectUploadGraspsMode={handleUploadGraspsMode}
        isOpen={addGraspsOpen}
        toggleModal={addGraspsToggle}
      />
      <ManualCreateGraspModal
        refetchGrasps={refetch}
        isOpen={createGraspOpen}
        toggleModal={createGraspToggle}
        organizationId={organizationId}
      />
      <UploadGraspsModal
        isOpen={uploadGraspsOpen}
        toggleModal={uploadGraspsToggle}
      />
      <EditGraspModal
        editGraspData={editedGrasp}
        organizationId={organizationId}
        closeModal={closeEditModal}
        refetchGrasps={refetch}
      />
      <GraspInOrgMultipleUpdateModal
        search={filterValue}
        organizationId={organizationId}
        included={rowSelectionProps.included}
        excluded={rowSelectionProps.excluded}
        isAllSelected={
          rowSelectionProps.isSelectAllChecked ||
          rowSelectionProps.isSelectAllIndeterminate
        }
        filters={filters}
        isOpen={modifyMultipleOpen}
        toggleModal={toggleModifyMultiple}
        refetchGrasps={refetch}
        endpoints={locations}
      />
      <TabLayout
        type={TabLayoutType.Generic}
        titlePrefix="Organizations.Grasps"
        actions={
          <>
            <Guard roleNeeded={UserRoles.GLOBAL_ADMIN}>
              <Button
                color="primary"
                outline={true}
                onClick={transferGraspModal.toggle}
              >
                <FontAwesomeIcon icon={faExchange} />
              </Button>
            </Guard>
            <Button
              color="primary"
              outline={true}
              onClick={toggleModifyMultiple}
              disabled={isTransferToOrganizationDisabled}
            >
              <FontAwesomeIcon icon={faEdit} />
            </Button>
            <Button color="primary" outline={true} onClick={addGraspsToggle}>
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </>
        }
      >
        <div>
          <div className="infinite-list-header__container mt-4">
            <div className="infinite-list-header__filters">
              <DebouncedInput
                inputIconSrc={graspIcon}
                labelTranslationId="OrganizationGrasps.searchText"
                name="search_grasp"
                onDebounce={onDebounce}
              />
              <FormGroup>
                <Label>
                  <FormattedMessage id="Projects.usersList.filterByStatus" />
                </Label>
                <GraspFilter
                  endpoints={locations}
                  filters={filters}
                  onFilterChange={setFilters}
                />
              </FormGroup>
            </div>
          </div>
          <GraspListInOrganizationTab
            onEditClick={onEditClick}
            organizationId={organizationId}
            fetchNextPage={fetchNextPage}
            grasps={grasps}
            hasMore={hasMore}
            refetch={refetch}
            rowSelectionProps={rowSelectionProps}
          />
        </div>
      </TabLayout>
    </>
  );
};

export default GraspsTab;
