import request from './utils/request';
import { IResponse } from '../interfaces/response';
import {
  ConversationQuestion,
  ConversationTemplate,
  ConversationTemplateContext,
  ConversationTemplateTriggerType,
  ConversationTemplateValidationBody,
  ConversationTemplateValidationResult,
  GroupedConversationQuestion,
  IAddTreatmentPlan,
  IConversationQuestionCondition,
  IFormConversation,
  IQuestionConditionValidation,
  ISummarizedTreatmentPhase,
  ITreatmentPlan,
  IUpsertConversationTemplate,
  IUpsertTreatmentPhase,
  IUserConversationHistoryEntry,
  UserConversationHistoryContextType,
} from '../interfaces/conversations';

import { NIL } from 'uuid';
import { normalizeQueryParamsArray } from '../helpers/utils/normalizeQueryParams';

export const getConversationTemplates = (
  organizationsId: string,
  context: ConversationTemplateContext | null,
  triggerType: ConversationTemplateTriggerType | null
) =>
  request<void, IResponse<ConversationTemplate[]>>({
    url: `/organizations/${organizationsId}/conversation_templates?context=${context}&triggerType=${triggerType}`,
    method: 'GET',
  });

export const getFormConversation = (
  organizationId: string,
  templateId: string,
  acceptLanguage: string
) =>
  request<void, IResponse<IFormConversation>>({
    url: `/organizations/${organizationId}/conversation_templates/${templateId}/form-conversation?userId=${NIL}`,
    method: 'GET',
    headers: {
      'Accept-Language': acceptLanguage,
    },
  });

export const getConversationQuestionValidation = (
  organizationId: string,
  questionId: string,
  condition: string
) =>
  request<
    IConversationQuestionCondition,
    IResponse<IQuestionConditionValidation>
  >({
    url: `/organizations/${organizationId}/conversation_questions/${questionId}/validate-condition`,
    method: 'POST',
    data: {
      condition: condition,
    },
  });

export const getTreatmentPlans = (organizationId: string) =>
  request<void, IResponse<ITreatmentPlan[]>>({
    url: `/organizations/${organizationId}/treatment_plans`,
    method: 'GET',
  });

export const getTreatmentPhases = (
  organizationId: string,
  treatmentPlanId: string
) =>
  request<void, IResponse<ISummarizedTreatmentPhase[]>>({
    url: `/organizations/${organizationId}/treatment_plans/${treatmentPlanId}/treatment_phases`,
    method: 'GET',
  });

export const getConversationQuestions = (organizationId: string) =>
  request<void, IResponse<ConversationQuestion[]>>({
    url: `/organizations/${organizationId}/conversation_questions`,
    method: 'GET',
  });

export const getUserCaseConversationQuestions = (
  userId: string,
  caseId: string
) =>
  request<void, IResponse<ConversationQuestion[]>>({
    url: `/users/${userId}/cases/${caseId}/conversation_questions`,
    method: 'GET',
  });

export const getUserCaseCurrentConversation = (
  userId: string,
  caseId: string
) =>
  request<void, IResponse<IFormConversation>>({
    url: `/users/${userId}/cases/${caseId}/conversations/current`,
    method: 'GET',
  });

export const getGroupedConversationQuestions = (organizationId: string) =>
  request<void, IResponse<GroupedConversationQuestion[]>>({
    url: `/organizations/${organizationId}/conversation_questions/grouped`,
    method: 'GET',
  });

export const getConversationAnswerHistory = (
  organizationId: string,
  caseId: string,
  personalityId: string,
  templateIds: string[],
  contextType?: UserConversationHistoryContextType
) => {
  let queryParams: Record<string, string>[] = [];

  if (contextType) queryParams.push({ context_type: contextType });

  templateIds.forEach((templateId) => {
    queryParams.push({ 'template_ids[]': templateId });
  });

  return request<void, IResponse<IUserConversationHistoryEntry[]>>({
    url: `/organizations/${organizationId}/cases/${caseId}/personalities/${personalityId}/conversation_history${normalizeQueryParamsArray(
      queryParams
    )}`,
    method: 'GET',
  });
};

export const modifyConversationTemplate = (
  organizationId: string,
  body: IUpsertConversationTemplate
) =>
  request<IUpsertConversationTemplate, void>({
    url: `/organizations/${organizationId}/conversation_templates`,
    method: 'PUT',
    data: body,
  });

export const addTreatmentPlan = (
  organizationId: string,
  body: IAddTreatmentPlan
) =>
  request<IAddTreatmentPlan, void>({
    url: `/organizations/${organizationId}/treatment_plans`,
    method: 'POST',
    data: body,
  });

export const cloneTreatmentPlan = (
  organizationId: string,
  planId: string,
  name: string
) =>
  request<void, IResponse<ITreatmentPlan>>({
    url: `/organizations/${organizationId}/treatment_plans/${planId}/clone/${name}`,
    method: 'POST',
  });

export const modifyTreatmentPhase = (
  organizationId: string,
  treatmentPlanId: string,
  treatmentPhaseId: string,
  body: IUpsertTreatmentPhase
) =>
  request<IUpsertTreatmentPhase, void>({
    url: `/organizations/${organizationId}/treatment_plans/${treatmentPlanId}/treatment_phases/${treatmentPhaseId}`,
    method: 'PUT',
    data: body,
  });

export const pushCasesToTreatmentPhase = (
  organizationId: string,
  caseCategoryId: string,
  caseSubCategoryId: string,
  body: string[]
) =>
  request<string[], void>({
    url: `/organizations/${organizationId}/treatment_plans/${caseCategoryId}/treatment_phases/${caseSubCategoryId}/push_cases`,
    method: 'POST',
    data: body,
  });

export const regenerateAwareSessionData = (
  organizationId: string,
  templateId: string
) =>
  request({
    url: `/organizations/${organizationId}/conversation_templates/${templateId}/aware_session_data/regenerate`,
    method: 'POST',
  });

export const validateImportedConversationTemplate = (
  organizationId: string,
  body: ConversationTemplateValidationBody
) =>
  request<
    ConversationTemplateValidationBody,
    IResponse<ConversationTemplateValidationResult>
  >({
    url: `/organizations/${organizationId}/conversation_templates/validate`,
    method: 'POST',
    data: body,
  });
