import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { isAuthenticated, getAccessToken } from 'helpers/utils/auth';
import { getUrlFromDiscoveryService } from '../membership';
import { refreshTokenInterceptor } from './refreshTokenInterceptor';

const LOCAL_STORAGE_API_KEY = 'api_url';

const defaultValues: AxiosRequestConfig = {
  baseURL: `${localStorage.getItem('api_url')}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
};

const client = axios.create(defaultValues);

export const getApiUrl = () => {
  if (process.env.NODE_ENV === 'test')
    return process.env.REACT_APP_TEST_API_URL;

  if (localStorage.getItem(LOCAL_STORAGE_API_KEY)) {
    return localStorage.getItem(LOCAL_STORAGE_API_KEY);
  } else {
    return process.env.REACT_APP_LOCAL_BE_URL;
  }
};

const setApiUrl = (url: string) => {
  localStorage.setItem(LOCAL_STORAGE_API_KEY, url);
};

export const deleteApiUrl = () =>
  localStorage.removeItem(LOCAL_STORAGE_API_KEY);

const request = async <D, R>(
  options: AxiosRequestConfig<D>
): Promise<AxiosResponse<R>> => {
  let apiUrl = getApiUrl();

  if (!apiUrl) {
    const { data } = await getUrlFromDiscoveryService();

    if (data.url) {
      setApiUrl(data.url);
      apiUrl = data.url;
    }
  }

  client.defaults.baseURL = `${apiUrl}/api`;

  if (isAuthenticated()) {
    client.defaults.headers.common.Authorization = `Bearer ${getAccessToken()}`;
  } else {
    client.defaults.headers.common.Authorization = ``;
  }

  const onSuccess = (response): AxiosResponse<R> => response;
  const onError = (error) => {
    /* eslint-disable no-console */
    if (error.response && process.env.NODE_ENV === 'development') {
      // Request was made but server responded with something
      // other than 2xx
      console.error('Status:', error.response.status);
      console.error('Data:', error.response.data);
      console.error('Headers:', error.response.headers);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error('Error Message:', error.message);
    }

    return Promise.reject(error.response || error.message);
  };

  return client(options).then(onSuccess).catch(onError);
};

refreshTokenInterceptor(client);

export default request;
