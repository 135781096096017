import { addDays } from 'date-fns';
import { useCallback } from 'react';
import { strToDateOnly } from '../../../../../../../../../helpers/dates';
import { ICaseGroupSchedule } from '../../../../../../../../../interfaces/caseGroups';
import { IConversationTreatmentPhaseContentItem } from '../../../../../../../../../interfaces/conversations';
import {
  ConversationScheduleType,
  ICreateCaseGroupConversationSchedule,
} from '../../../../../../../../../interfaces/conversationSchedules';

export const useCaseGroupDetailsAddTemplatePayload = () => {
  const getDateBasedOnGroupSchedule = useCallback(
    (day: number | null, groupSchedule: ICaseGroupSchedule) => {
      if (day === null) return null;
      const targetDate = addDays(new Date(groupSchedule.date_from), day);
      return targetDate.toISOString();
    },
    []
  );

  const normalizeIsoDate = useCallback((str: string) => {
    const dateStr = strToDateOnly(str);
    const date = new Date(dateStr);

    return date.toISOString();
  }, []);

  const prepareSimplePayload = useCallback(
    (
      values: IConversationTreatmentPhaseContentItem,
      phaseId: string,
      type:
        | ConversationScheduleType.Once
        | ConversationScheduleType.NonTimeBased
    ): ICreateCaseGroupConversationSchedule => {
      return {
        template_id: values.template_id,
        priority: values.priority,
        phase_id: phaseId,
        notification_hour: null,
        available_from_hour: null,
        available_to_hour: null,
        recurring_interval: null,
        date_from: null,
        date_to: null,
        date: null,
        recurring_origin_id: null,
        type: type,
        is_dirty: false,
      };
    },
    []
  );

  const prepareSingleInstancePayload = useCallback(
    (
      values: IConversationTreatmentPhaseContentItem,
      phaseId: string,
      groupSchedule: ICaseGroupSchedule
    ): ICreateCaseGroupConversationSchedule => {
      let date = getDateBasedOnGroupSchedule(
        values.schedule_templates[0].specific_day_of_phase,
        groupSchedule
      );

      if (date) date = normalizeIsoDate(date);

      return {
        template_id: values.template_id,
        priority: values.priority,
        phase_id: phaseId,
        notification_hour:
          values.schedule_templates[0].parts_of_a_day[0].notification_hour,
        available_from_hour:
          values.schedule_templates[0].parts_of_a_day[0].available_from_hour,
        available_to_hour:
          values.schedule_templates[0].parts_of_a_day[0].available_to_hour,
        recurring_interval: null,
        date_from: null,
        date_to: null,
        date: date,
        type: ConversationScheduleType.SingleInstance,
        recurring_origin_id: null,
        is_dirty: false,
      };
    },
    [getDateBasedOnGroupSchedule, normalizeIsoDate]
  );

  const prepareRecurringPayload = useCallback(
    (
      values: IConversationTreatmentPhaseContentItem,
      phaseId: string,
      groupSchedule: ICaseGroupSchedule
    ): ICreateCaseGroupConversationSchedule[] => {
      const partsOfADay = values.schedule_templates[0].parts_of_a_day;
      let schedules: ICreateCaseGroupConversationSchedule[] = [];

      for (const partOfADay of partsOfADay) {
        let dateFrom = getDateBasedOnGroupSchedule(
          values.schedule_templates[0].start_day_offset,
          groupSchedule
        );
        let dateTo = getDateBasedOnGroupSchedule(
          values.schedule_templates[0].end_day_offset,
          groupSchedule
        );

        if (dateFrom) dateFrom = normalizeIsoDate(dateFrom);
        if (dateTo) dateTo = normalizeIsoDate(dateTo);

        schedules.push({
          template_id: values.template_id,
          priority: values.priority,
          phase_id: phaseId,
          notification_hour: partOfADay.notification_hour,
          available_from_hour: partOfADay.available_from_hour,
          available_to_hour: partOfADay.available_to_hour,
          recurring_interval: values.schedule_templates[0].recurring_interval,
          date_from: dateFrom,
          date_to: dateTo,
          date: null,
          type: ConversationScheduleType.Recurring,
          recurring_origin_id: null,
          is_dirty: false,
        });
      }

      return schedules;
    },
    [getDateBasedOnGroupSchedule, normalizeIsoDate]
  );

  return {
    simplePayload: prepareSimplePayload,
    singleInstancePayload: prepareSingleInstancePayload,
    recurringPayload: prepareRecurringPayload,
  };
};
