import React, { PropsWithChildren } from 'react';
import useUrlSearch from 'hooks/useUrlSearch';
import Placeholders from 'components/Placeholders';

const MainOrganizationFilter = ({ children }: PropsWithChildren) => {
  const query = useUrlSearch();
  const mainOrganizationId = query.get('main_id') as string;

  if (!mainOrganizationId) return <Placeholders.InvalidUrlPath />;
  return <>{children}</>;
};

export default MainOrganizationFilter;
