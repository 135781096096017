import { ConversationTemplate } from '../../../../../../../../../interfaces/conversations';
import { useCommonParams } from '../../../../../../../hooks/useCommonParams';
import useRegenerateAwareSessionData from '../../../../../../../../../api/mutations/conversations/useRegenerateAwareSessionData';
import { useCommonData } from '../../../../../../../hooks/useCommonData';
import { useCallback } from 'react';

export const useHandleAwareSessionDataRegenerate = (
  template: ConversationTemplate
) => {
  const { organizationId } = useCommonParams();

  const { conversationTemplates } = useCommonData();

  const { mutateAsync: regenerateAsync, isLoading: regenerateLoading } =
    useRegenerateAwareSessionData({
      params: { organizationId },
      options: { successFb: conversationTemplates.refetch },
    });

  const handleAsync = useCallback(async () => {
    await regenerateAsync(template.id);
  }, [regenerateAsync, template.id]);

  return {
    handleAsync,
    isLoading: regenerateLoading,
  };
};
