import React from 'react';
import { useHandleAdd } from './hooks/useHandleAdd';
import { UserRoles } from '../../../../../../../../../../../../interfaces/membership';
import Pills from 'components/Pills';
import { useHandleRemove } from './hooks/useHandleRemove';

type RolesSelectorProps = {
  accessProfileId: string;
  roles: UserRoles[];
};

const RolesSelector = ({ accessProfileId, roles }: RolesSelectorProps) => {
  const accessProfilesItems = Object.values(roles).map((role) => ({
    id: role,
    name: role,
  }));

  const allAccessProfiles = Object.values(UserRoles).map((role) => ({
    id: role,
    name: role,
  }));

  const accessProfilesItemsIds = accessProfilesItems.map((p) => p.id);

  const options = allAccessProfiles.filter(
    (p) => !accessProfilesItemsIds.includes(p.id)
  );

  const addAsync = useHandleAdd();
  const removeAsync = useHandleRemove();

  const handleAdd = async (id: string) => {
    await addAsync({
      accessProfileId: accessProfileId,
      roles: [id as UserRoles],
    });
  };

  const handleDelete = async (id: string) => {
    await removeAsync({
      accessProfileId: accessProfileId,
      roles: [id as UserRoles],
    });
  };

  return (
    <div className="w-100">
      <Pills
        items={accessProfilesItems}
        outline={true}
        onAdd={handleAdd}
        onDelete={handleDelete}
        options={options}
        size="small"
      />
    </div>
  );
};

export default RolesSelector;
