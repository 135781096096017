import { useCallback, useMemo, useState } from 'react';
import useOpen from '../../../../../../../../../../../../../../../hooks/useOpen';
import { CaseNotificationSchedule } from '../../../../../../../../../../../../../../../interfaces/notificationSchedules';

export const useEditSchedule = () => {
  const editNotificationModal = useOpen();

  const [schedule, setSchedule] = useState<CaseNotificationSchedule | null>(
    null
  );

  const handleEdit = useCallback(
    (schedule: CaseNotificationSchedule) => {
      setSchedule(schedule);
      editNotificationModal.toggle();
    },
    [editNotificationModal]
  );

  const handleCloseEdit = useCallback(() => {
    setSchedule(null);
    editNotificationModal.toggle();
  }, [editNotificationModal]);

  const editMode = useMemo(() => {
    return !!schedule && editNotificationModal.isOpen;
  }, [editNotificationModal.isOpen, schedule]);

  return {
    handleEdit,
    handleCloseEdit,
    editMode,
    schedule,
    isOpen: editNotificationModal.isOpen,
  };
};
