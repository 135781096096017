import {
  IDeleteObservationsGroup,
  IObservation,
  IUpdateObservationsGroup,
} from 'interfaces/timeline';
import request from './utils/request';
import { IResponse } from '../interfaces/response';

export const createSimpleCaseObservation = (userId: string, caseId: string) =>
  request({
    url: `/users/${userId}/cases/${caseId}/hardcoded_observation`,
    method: 'POST',
  });

export const organizationContextUpdateObservationsGroup = (
  organizationId: string,
  caseId: string,
  timelineId: string,
  data: IUpdateObservationsGroup
) =>
  request<IUpdateObservationsGroup, any>({
    url: `/organizations/${organizationId}/cases/${caseId}/timelines/${timelineId}/observations_group`,
    method: 'PUT',
    data: {
      description: data.description,
      meaning: data.meaning,
      observation_ids: data.observation_ids,
    },
  });

export const userContextUpdateObservationsGroup = (
  userId: string,
  timelineId: string,
  data: IUpdateObservationsGroup
) =>
  request<IUpdateObservationsGroup, any>({
    url: `/users/${userId}/timelines/${timelineId}/observations_group`,
    method: 'PUT',
    data: {
      description: data.description,
      meaning: data.meaning,
      observation_ids: data.observation_ids,
    },
  });

export const organizationContextDeleteObservationsGroup = (
  organizationId: string,
  caseId: string,
  timelineId: string,
  data: IDeleteObservationsGroup
) =>
  request<IDeleteObservationsGroup, any>({
    url: `/organizations/${organizationId}/cases/${caseId}/timelines/${timelineId}/observations_group`,
    method: 'DELETE',
    data: {
      observation_ids: data.observation_ids,
    },
  });

export const userContextDeleteObservationsGroup = (
  userId: string,
  timelineId: string,
  data: IDeleteObservationsGroup
) =>
  request<IDeleteObservationsGroup, any>({
    url: `/users/${userId}/timelines/${timelineId}/observations_group`,
    method: 'DELETE',
    data: {
      observation_ids: data.observation_ids,
    },
  });

export const getTimelineObservationsForPeriod = (
  timelineId: string,
  from: Date,
  to: Date
) => {
  return request<void, IResponse<IObservation[]>>({
    url: `/timelines/${timelineId}/observations/period?from=${from.toISOString()}&to=${to.toISOString()}`,
    method: 'GET',
  });
};
