import { IEducationMaterial } from '../../../../../interfaces/educationMaterials';
import { useModalMode } from './useModalMode';
import {
  MaterialModalMode,
  MaterialModalSteps,
  MaterialModalStepsContent,
} from '../types';
import { useCallback, useMemo, useState } from 'react';

const addModalSteps: MaterialModalSteps[] = [
  MaterialModalSteps.FillInfo,
  MaterialModalSteps.AddRules,
  MaterialModalSteps.UploadAssets,
];

const editModalSteps: MaterialModalSteps[] = [
  MaterialModalSteps.FillInfo,
  MaterialModalSteps.AddRules,
];

export const useSteps = (
  material: IEducationMaterial | undefined,
  initialStepIndex: number
): MaterialModalStepsContent => {
  const mode = useModalMode(material);

  const [step, setStep] = useState<MaterialModalSteps>(
    Object.values(MaterialModalSteps)[initialStepIndex]
  );

  const steps = useMemo(
    () =>
      mode === MaterialModalMode.AddMaterial ? addModalSteps : editModalSteps,
    [mode]
  );

  const next = useCallback(() => {
    const currentStepIndex = steps.findIndex((p) => p === step);

    if (currentStepIndex === -1 || currentStepIndex + 1 === steps.length)
      return;

    setStep(steps[currentStepIndex + 1]);
  }, [step, steps]);

  const prev = useCallback(() => {
    const currentStepIndex = steps.findIndex((p) => p === step);

    if (currentStepIndex === -1 || currentStepIndex - 1 < 0) return;

    setStep(steps[currentStepIndex - 1]);
  }, [step, steps]);

  return { steps, step, next, prev };
};
