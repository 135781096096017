import useOrganizationAssessmentFormsTemplates from 'api/queries/assessmentForms/templates/useOrganizationAssessmentFormTemplates';
import { formatDate } from 'helpers/dates';
import { getUserId } from 'helpers/utils/auth';
import { returnIconIndicatorForCompletionStatus } from 'helpers/utils/icons';
import {
  AssessmentCompletionStatus,
  AssessmentQuestionType,
} from 'interfaces/assessmentForms';
import QuestionVisualization from 'modules/TimelineDetails/components/tabs/DayByDayTab/components/DayDetails/tabs/AssessmentForms/QuestionVisualization';
import SVG from 'react-inlinesvg';
import pencilFormIcon from 'assets/images/edit_icon.svg';
import { AssessmentBaseProps } from './FillOtherFormModal';

type OtherAssessmentFormProps = {
  scheduleDate: Date;
  scheduleTime: string;
  organizationId: string;
  formCode: string;
  setFormToEdit: (assessmentData: AssessmentBaseProps) => void;
};

const OtherAssessmentForm = ({
  scheduleDate,
  organizationId,
  formCode,
  scheduleTime,
  setFormToEdit,
}: OtherAssessmentFormProps) => {
  const { data: templatesData } = useOrganizationAssessmentFormsTemplates({
    params: {
      organizationId,
      allowedForCaseId: null,
      questionsIncludedInTrends: false,
    },
  });

  const foundTemplate = templatesData?.find((el) => el.code === formCode);

  const renderQuestions = () => {
    const templatesQuestionsWithoutHeader = foundTemplate?.questions.filter(
      (p) => p.type !== AssessmentQuestionType.HEADER
    );

    return templatesQuestionsWithoutHeader?.map((question, index) => (
      <QuestionVisualization
        completionStatus={AssessmentCompletionStatus.OPEN}
        formCode={formCode}
        index={index}
        question={question}
        questionsLength={templatesQuestionsWithoutHeader.length}
        toggled={false}
        userId={getUserId()}
      />
    ));
  };

  const onEditClick = () => {
    setFormToEdit({
      dateObject: scheduleDate,
      selectedTemplate: formCode,
      time: scheduleTime,
    });
  };

  return (
    <div
      className={`DayDetails__assessment-form DayDetails__assessment-form--${AssessmentCompletionStatus.OPEN.toLowerCase()}`}
    >
      <div
        className={`DayDetails__assessment-form__title DayDetails__assessment-form__title__${AssessmentCompletionStatus.OPEN.toLowerCase()}`}
      >
        {foundTemplate?.title}
        <span className="DayDetails__assessment-form__completion-hour">
          {scheduleDate
            ? formatDate(new Date(scheduleDate).toISOString(), {
                ignoreTime: true,
              })
            : '-'}
          {', '}
          {scheduleTime}
        </span>
      </div>
      <div className="DayDetails__assessment-form__icons">
        {renderQuestions()}
        <div className="ms-auto">
          <button
            className="AssessmentFormBanner__content__complete__button"
            onClick={onEditClick}
          >
            <SVG src={pencilFormIcon} title="edit-icon" />
          </button>
        </div>
      </div>
      <SVG
        src={returnIconIndicatorForCompletionStatus(
          AssessmentCompletionStatus.OPEN,
          true
        )}
        title={foundTemplate?.title}
        className="DayDetails__assessment-form__indicator"
      />
    </div>
  );
};

export default OtherAssessmentForm;
