import React, { InputHTMLAttributes } from 'react';
import useCaseCategories from 'api/queries/cases/useCaseCategories';
import { Input } from 'reactstrap';
import InputWithDisabledDivDisplay from 'components/InputWithDisabledDivDisplay';

export enum CategoryIdentifier {
  CategoryCode = 'CategoryCode',
  CategoryId = 'CategoryId',
}

interface CategorySelectProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type | onChange'> {
  onEdit?: (target: string, value: string) => void;
  organizationId: string;
  isDefaultInput: boolean;
  name: string;
  value: string;
  emptyValueFirst?: boolean;
  identifier?: CategoryIdentifier;
}

const CategorySelect = ({
  organizationId,
  isDefaultInput,
  onEdit,
  name,
  emptyValueFirst,
  identifier = CategoryIdentifier.CategoryCode,
  ...props
}: CategorySelectProps) => {
  const { data } = useCaseCategories({
    params: { organizationId },
    options: { enabled: false },
  });

  const options =
    data?.map(({ id, code, name }) => ({
      value: identifier === CategoryIdentifier.CategoryId ? id : code,
      text: name,
    })) ?? [];

  const modifiedOptions = emptyValueFirst
    ? [{ value: '', text: '' }, ...options]
    : options;

  if (isDefaultInput)
    return (
      <Input {...props} type="select" name={name}>
        {modifiedOptions?.map(({ value, text }) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))}
      </Input>
    );
  else if (onEdit) {
    return (
      <InputWithDisabledDivDisplay
        name={name}
        onEdit={onEdit}
        {...props}
        inputCssClass={props.className}
        type="select"
        options={options}
      />
    );
  } else return null;
};

export default CategorySelect;
