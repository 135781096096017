import { FormattedMessage } from 'react-intl';
import stackIcon from 'assets/images/stack.svg';
import HeaderSummaryDashboard from 'modules/TimelineDetails/components/HeaderSummaryDashboard';
import { Spinner } from 'reactstrap';

type StatusProps = {
  userId: string;
  masterTimelineId: string | undefined;
  isLoading: boolean;
};

const Status = ({ userId, masterTimelineId, isLoading }: StatusProps) => {
  return (
    <div className="SummaryCard card-container">
      <div className="cardContainer__header d-flex flex-column flex-md-row justify-content-between h-100">
        <div className="d-flex flex-wrap justify-content-between w-100 h-100">
          <div className="cardContainer__header__title">
            <img src={stackIcon} alt="form_icon" />
            <FormattedMessage id="Summary.status" />
          </div>
          {isLoading || masterTimelineId === undefined ? (
            <div className="w-100 h-100 mt-4 d-flex justify-content-center">
              <Spinner color="primary" />
            </div>
          ) : (
            <HeaderSummaryDashboard
              title="Summary"
              timelineId={masterTimelineId}
              userId={userId}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Status;
