import { Grid, ThemeProvider } from '@mui/material';
import React, { useContext, useEffect } from 'react';
import useHelpCategories from '../../../../../../api/queries/help/useHelpCategories';
import QueryLoaded from '../../../../../../components/QueryLoaded';
import useCultures from '../../../../../../hooks/cultures/useCultures';
import useUrlSearch from '../../../../../../hooks/useUrlSearch';
import { ThemesOptions } from '../../../../../../themes/options';
import { ChangeLanguageContext } from '../../../../../../translations/utils/LanguageProvider';
import HelpCentreForm from './forms/HelpCentreForm';

const HelpCentreView = () => {
  const helpCategoriesQuery = useHelpCategories();
  const { onLanguageChange } = useContext(ChangeLanguageContext);

  const { defaultCulture, cultures } = useCultures();

  const query = useUrlSearch();
  const culture = query.get('culture');

  useEffect(() => {
    if (!culture || culture === defaultCulture.cultureCode) return;
    const existingCulture = cultures.find((p) => p.cultureCode === culture);
    if (!existingCulture) return;
    onLanguageChange(existingCulture.translationCode);
  }, [culture, cultures, defaultCulture.cultureCode, onLanguageChange]);

  return (
    <ThemeProvider theme={ThemesOptions.awareThemeOptions}>
      <Grid
        container
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100vh',
        }}
      >
        <Grid item xs={12}>
          <QueryLoaded query={helpCategoriesQuery}>
            {(categories) => <HelpCentreForm categories={categories} />}
          </QueryLoaded>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default HelpCentreView;
