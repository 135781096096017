import {
  GridRenderCellParams,
  GridRenderEditCellParams,
} from '@mui/x-data-grid';
import React from 'react';
import { FormatHelpers } from '../../../../../../../../../../../../helpers/format';
import { OrganizationAccessProfile } from '../../../../../../../../../../../../interfaces/accessProfiles';
import { DashboardReportDestinationType } from '../../../../../../../../../../../../interfaces/dashboards';
import { IPersonality } from '../../../../../../../../../../../../interfaces/personalities';
import TextFieldCell from '../TextFieldCell';
import AccessProfileEditCell from './components/AccessProfileEditCell';
import MemberEditCell from './components/MemberEditCell';

export type DestinationCellParams = GridRenderCellParams & {
  accessProfiles: OrganizationAccessProfile[];
  members: IPersonality[];
};

const DestinationCell = (params: DestinationCellParams) => {
  const destinationType = params.row.destination_type;

  if (destinationType === DashboardReportDestinationType.Email)
    return <span>{params.row['destination_id']}</span>;

  if (destinationType === DashboardReportDestinationType.AccessProfile) {
    const accessProfile = params.accessProfiles.find(
      (p) => p.id === params.row['destination_id']
    );

    return <span>{accessProfile?.name ?? ''}</span>;
  }

  const member = params.members.find(
    (p) => p.id === params.row['destination_id']
  );

  return <span>{member ? FormatHelpers.FormatPersonality(member) : ''}</span>;
};

type DestinationEditCellProps = GridRenderEditCellParams & {
  accessProfiles: OrganizationAccessProfile[];
  members: IPersonality[];
};

export const DestinationEditCell = (params: DestinationEditCellProps) => {
  if (params.row.destination_type === DashboardReportDestinationType.Email)
    return <TextFieldCell type="email" {...params} />;

  if (
    params.row.destination_type === DashboardReportDestinationType.AccessProfile
  )
    return <AccessProfileEditCell {...params} />;

  return <MemberEditCell {...params} />;
};

export default DestinationCell;
