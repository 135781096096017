import React, { memo } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage } from 'react-intl';
import { useTranslations } from '../../../../../../../../../../../../../../../../../../hooks/useTranslations';
import {
  ConversationTemplateValidationConflict,
  ConversationTemplateValidationConflictType,
} from '../../../../../../../../../../../../../../../../../../interfaces/conversations';
import AssignOrganizationAction from './components/AssignOrganizationAction';
import QuestionActions from './components/QuestionActions';
import SelectMaterialAction from './components/SelectMaterialAction';
import SelectFeedbackAction from './components/SelectFeedbackAction';
import useOpen from '../../../../../../../../../../../../../../../../../../hooks/useOpen';

type ConflictProps = {
  conflict: ConversationTemplateValidationConflict;
};

const Conflict = ({ conflict }: ConflictProps) => {
  const { isOpen: resolved, toggle: resolve } = useOpen();

  const { attachPrefix, wrapWithIntl } = useTranslations(
    'ConversationsAndFeedbacks.ConversationTemplates.ImportTemplateModal.Content.ConflictsStep.Validation.Response.Conflicts'
  );

  let conflictInfo: string = '';
  let conflictActions: JSX.Element = <></>;

  switch (conflict.type) {
    case ConversationTemplateValidationConflictType.EmptyTemplate:
      conflictInfo = wrapWithIntl(attachPrefix('emptyTemplate'));
      break;
    case ConversationTemplateValidationConflictType.ExistingQuestion:
      conflictInfo = wrapWithIntl(attachPrefix('existingQuestion'), {
        id: conflict.question_id,
      });
      break;
    case ConversationTemplateValidationConflictType.InvalidOrganization:
      conflictInfo = wrapWithIntl(attachPrefix('invalidOrganization'), {
        id: conflict.organization_id,
      });
      break;
    case ConversationTemplateValidationConflictType.NonExistingMaterial:
      conflictInfo = wrapWithIntl(attachPrefix('nonExistingMaterial'), {
        id: conflict.material_id,
      });
      break;
    case ConversationTemplateValidationConflictType.NonExistingFeedback:
      conflictInfo = wrapWithIntl(attachPrefix('nonExistingFeedback'), {
        id: conflict.group_name,
      });
  }

  switch (conflict.type) {
    case ConversationTemplateValidationConflictType.EmptyTemplate:
      conflictActions = <></>;
      break;
    case ConversationTemplateValidationConflictType.InvalidOrganization:
      conflictActions = <AssignOrganizationAction onResolve={resolve} />;
      break;
    case ConversationTemplateValidationConflictType.ExistingQuestion:
      conflictActions = (
        <QuestionActions id={conflict.question_id} onResolve={resolve} />
      );
      break;
    case ConversationTemplateValidationConflictType.NonExistingMaterial:
      conflictActions = (
        <SelectMaterialAction id={conflict.material_id} onResolve={resolve} />
      );
      break;
    case ConversationTemplateValidationConflictType.NonExistingFeedback:
      conflictActions = (
        <SelectFeedbackAction
          groupName={conflict.group_name}
          onResolve={resolve}
        />
      );
  }

  return (
    <div className={classNames('card p-3', { 'opacity-50': resolved })}>
      <div className="d-flex align-items-center justify-content-between gap-2">
        <div className="d-flex flex-column">
          <span className={classNames('fs-5', { 'text-muted': resolved })}>
            {wrapWithIntl(attachPrefix(conflict.type))}
          </span>
          <span className="fs-6 text-muted">{conflictInfo}</span>
        </div>
        <div
          className={classNames(
            'd-flex align-items-center justify-content-center',
            {
              'text-primary': !resolved,
              'text-muted': resolved,
            }
          )}
        >
          {resolved ? (
            <div className="d-flex align-items-center gap-1">
              <FontAwesomeIcon icon={faCheck} />
              <FormattedMessage id={attachPrefix('resolved')} />
            </div>
          ) : (
            conflictActions
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(Conflict);
