import { ChartsData } from '../types';
import { cloneDeep } from 'lodash';
import { StrictDateRange } from '../../../interfaces/dates';
import { addDays } from 'date-fns';

export const removeRedundant = (
  data: ChartsData<string | number>[],
  propertyName: string
) => {
  const hasEmptyProperty = data.every(
    (p) => Object.hasOwn(p, propertyName) && p[propertyName] === 0
  );

  if (!hasEmptyProperty) return data;

  data = data.map((p) => {
    let pClone = cloneDeep(p);
    delete pClone[propertyName];

    return pClone;
  });

  return data;
};

export const assignEmptyDates = (
  data: ChartsData<string | number>[],
  dateRange: StrictDateRange
) => {
  const dataDates = data.map((p) => p['name'] as string);
  let rangeDates: string[] = [];
  let date = dateRange.from;

  while (date <= dateRange.to) {
    date.setHours(0, 0, 0, 0);
    rangeDates.push(date.toISOString());
    date = addDays(date, 1);
  }

  let nonAssignedDates = cloneDeep(rangeDates);
  nonAssignedDates = nonAssignedDates.filter((p) => !dataDates.includes(p));

  nonAssignedDates.forEach((nonAssignedDate) => {
    data.push({
      name: nonAssignedDate,
    });
  });

  return data;
};

export const sortByDates = (data: ChartsData<string | number>[]) => {
  data.sort((a, b) => (a['name'] as string).localeCompare(b['name'] as string));
  return data;
};
