import { getUserRoles } from 'helpers/utils/auth';
import { UserRoles } from 'interfaces/membership';

type GuardProps = {
  roleNeeded: UserRoles;
  children: JSX.Element;
};

const Guard = ({ roleNeeded, children }: GuardProps) => {
  let roles = getUserRoles();
  const isRoleMatched = roles?.includes(roleNeeded);

  if (isRoleMatched) return children;
  else return null;
};

export default Guard;
