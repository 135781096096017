import { strToDateOnly, toDateOnly } from 'helpers/dates';
import { ScheduleItemDefinition } from '../../../../../../../../../../../../../../../components/Schedule';
import { ICasePhaseHistory } from '../../../../../../../../../../../../../../../interfaces/cases';
import {
  CaseNotificationSchedule,
  ISingleNotificationSchedule,
} from '../../../../../../../../../../../../../../../interfaces/notificationSchedules';
import { DayScheduleMergingHelpers } from './rowMerging';
import { DayScheduleSchedulesHelpers } from './schedules';

export namespace DayScheduleGeneratorsHelpers {
  export const GenerateDefinition = (
    items: ScheduleItemDefinition[],
    schedule: ISingleNotificationSchedule,
    schedules: CaseNotificationSchedule[],
    casePhaseHistory: ICasePhaseHistory[],
    date: Date,
    index: number,
    handleEdit: (schedule: CaseNotificationSchedule) => void
  ): ScheduleItemDefinition[] => {
    const singleInstanceDateStr = strToDateOnly(schedule.utc_timezone_time);
    const selectedDateStr = strToDateOnly(date.toISOString());

    if (singleInstanceDateStr !== selectedDateStr) return items;

    if (schedule.origin_id)
      return _generateRecurredSingleInstance(
        items,
        schedule,
        schedules,
        date,
        index,
        handleEdit,
        casePhaseHistory
      );

    return _generateSimpleSingleInstance(
      items,
      schedule,
      casePhaseHistory,
      date,
      index,
      handleEdit
    );
  };

  const _generateRecurredSingleInstance = (
    items: ScheduleItemDefinition[],
    schedule: ISingleNotificationSchedule,
    schedules: CaseNotificationSchedule[],
    date: Date,
    index: number,
    handleEdit: (schedule: CaseNotificationSchedule) => void,
    casePhaseHistory: ICasePhaseHistory[]
  ): ScheduleItemDefinition[] => {
    const selectedDateStr = strToDateOnly(date.toISOString());

    const recurringParent = DayScheduleSchedulesHelpers.GetRecurringParent(
      schedules,
      schedule.origin_id ?? ''
    );

    if (!recurringParent) return items;

    const singleInstancePartOfADay =
      DayScheduleSchedulesHelpers.GetSingleInstancePartOfADay(
        schedule,
        handleEdit
      );

    const rowToMergeWith = DayScheduleMergingHelpers.GetRowToMergeWith(
      items,
      schedule,
      recurringParent,
      handleEdit
    );

    if (rowToMergeWith !== null) {
      return DayScheduleMergingHelpers.MergeSingleInstanceWithRow(
        items,
        rowToMergeWith.sequence,
        singleInstancePartOfADay,
        selectedDateStr < toDateOnly(new Date())
      );
    }

    items.push(
      DayScheduleSchedulesHelpers.GetRecurredSingleInstance(
        schedule,
        recurringParent,
        casePhaseHistory,
        selectedDateStr,
        index,
        handleEdit
      )
    );

    return items;
  };

  const _generateSimpleSingleInstance = (
    items: ScheduleItemDefinition[],
    schedule: ISingleNotificationSchedule,
    casePhaseHistory: ICasePhaseHistory[],
    date: Date,
    index: number,
    handleEdit: (schedule: CaseNotificationSchedule) => void
  ): ScheduleItemDefinition[] => {
    const selectedDateStr = strToDateOnly(date.toISOString());

    items.push(
      DayScheduleSchedulesHelpers.GetSimpleSingleInstance(
        schedule,
        casePhaseHistory,
        date.toISOString(),
        selectedDateStr < toDateOnly(new Date()),
        index,
        handleEdit
      )
    );

    return items;
  };
}
