import useBaseCultures, {
  AvailableCulture,
} from '../../../../../../../hooks/cultures/useCultures';
import { useCallback, useMemo, useState } from 'react';
import { flatten } from 'flat';
import { translations } from '../../../../../../../translations/utils/LanguageProvider';
import { returnFormattedLang } from '../../../../../../../translations/utils/cultures';

export const useCultures = () => {
  const { currentCulture, flagCodes, flagLabels, cultures } = useBaseCultures();

  const [languageContext, setLanguageContext] =
    useState<AvailableCulture>(currentCulture);

  const selectedFlagCode = useMemo(() => {
    const availableCulture = cultures.find(
      (p) => p.culture === languageContext.culture
    );

    if (availableCulture === undefined) return currentCulture.upperCountryCode;
    return availableCulture.upperCountryCode;
  }, [cultures, currentCulture.upperCountryCode, languageContext.culture]);

  const selectFlagCode = useCallback(
    (countryCode: string) => {
      const availableCulture = cultures.find(
        (p) => p.upperCountryCode === countryCode
      );

      if (availableCulture === undefined) return;
      setLanguageContext(availableCulture);
    },
    [cultures]
  );

  const messages = useMemo(() => {
    return flatten(
      translations[returnFormattedLang(languageContext.cultureCode)]
    ) as Record<string, any>;
  }, [languageContext.cultureCode]);

  return {
    flagCodes,
    flagLabels,
    selectedFlagCode,
    selectFlagCode,
    languageContext,
    messages,
  };
};
