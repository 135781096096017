import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormGroup, InputGroup, InputGroupText, Label } from 'reactstrap';
import { useCookie } from '../hooks/useCookie';
import { ViewTheme } from '../interfaces/ui';

export enum InputFieldWithImageColor {
  primary = 'primary',
  success = 'success',
  danger = 'danger',
  gray = 'gray',
  dark = 'dark',
}

type InputFieldWithImageProps = {
  image: JSX.Element;
  children: JSX.Element;
  errorInfo?: JSX.Element;
  additionalInfo?: JSX.Element;
  labelTranslationId?: string;
  disabled?: boolean;
  isOptional?: boolean;
  color?: InputFieldWithImageColor;
  noMargin?: boolean;
  arrowIcon?: boolean;
  clearFunction?: () => void;
  canClear?: boolean;
  formGroupId?: string;
  inputId?: string;
  block?: boolean;
  appendGroupContent?: JSX.Element;
};

const InputFieldWithImage = ({
  image,
  children,
  labelTranslationId,
  disabled,
  errorInfo,
  additionalInfo,
  isOptional = false,
  color = InputFieldWithImageColor.primary,
  arrowIcon = false,
  noMargin = false,
  clearFunction,
  canClear = false,
  inputId,
  formGroupId,
  block = false,
  appendGroupContent = undefined,
}: InputFieldWithImageProps) => {
  const theme = useCookie<ViewTheme>('theme');

  return (
    <FormGroup
      id={formGroupId}
      className={classNames('InputFieldWithImage', {
        'form-group-no-margin': noMargin,
        'w-100': block,
      })}
    >
      {labelTranslationId && (
        <Label className="d-flex" htmlFor={inputId}>
          <FormattedMessage id={labelTranslationId} />
          {isOptional && (
            <span className="ms-1 newMembersSection__secondLink__desc">
              (<FormattedMessage id="General.optional" />){' '}
            </span>
          )}
        </Label>
      )}
      <InputGroup
        className={classNames('InputFieldWithImage__input-group', {
          'InputFieldWithImage__input-group--primary':
            color === InputFieldWithImageColor.primary,
          'InputFieldWithImage__input-group--gray':
            color === InputFieldWithImageColor.gray,
          'InputFieldWithImage__input-group--dark':
            color === InputFieldWithImageColor.dark,
          'InputFieldWithImage__input-group--danger':
            color === InputFieldWithImageColor.danger,
          'InputFieldWithImage__input-group--success':
            color === InputFieldWithImageColor.success,
          'InputFieldWithImage__input-group--arrow-icon': arrowIcon,
          '!bg-transparent !rounded-full !text-neutral-50':
            theme === ViewTheme.Dark,
        })}
      >
        <InputGroupText
          className={classNames(
            'InputFieldWithImage__input-group__image-addon',
            {
              'InputFieldWithImage__input-group__image-addon--disabled':
                disabled,
              '!bg-transparent': theme === ViewTheme.Dark,
            }
          )}
        >
          {image}
        </InputGroupText>
        {React.cloneElement(children, { disabled })}
        {appendGroupContent && (
          <InputGroupText
            className={classNames(
              'InputFieldWithImage__input-group__image-addon',
              'cursor-pointer',
              'InputFieldWithImage__input-group__clear',
              {
                'InputFieldWithImage__input-group__image-addon--disabled':
                  disabled,
                '!bg-transparent': theme === ViewTheme.Dark,
              }
            )}
          >
            {appendGroupContent}
          </InputGroupText>
        )}
        {clearFunction && (
          <InputGroupText
            className={classNames(
              'InputFieldWithImage__input-group__image-addon',
              'cursor-pointer',
              'InputFieldWithImage__input-group__clear',
              {
                'InputFieldWithImage__input-group__image-addon--disabled':
                  disabled,
                '!bg-transparent': theme === ViewTheme.Dark,
              }
            )}
          >
            {canClear && (
              <FontAwesomeIcon icon={faTimes} onClick={clearFunction} />
            )}
          </InputGroupText>
        )}
      </InputGroup>
      {errorInfo}
      {additionalInfo}
    </FormGroup>
  );
};

export default InputFieldWithImage;
