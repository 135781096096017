import { TrendsChartFormatter } from '../../../types';
import React, { useCallback } from 'react';
import TrendsChartLegend from '../components/TrendsChartLegend';
import { SelectItemsHookValues } from './useSelectItems';

export const useLegendContent = (
  formatters: TrendsChartFormatter[],
  selection: SelectItemsHookValues
) => {
  return useCallback(
    (props: any) => (
      <TrendsChartLegend
        formatters={formatters}
        selection={selection}
        innerProps={props}
      />
    ),
    [formatters, selection]
  );
};
