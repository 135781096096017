import ItemPlaceholder from 'modules/CaseDashboard/components/CaseDashboardTabs/components/ItemPlaceholder';
import React from 'react';
import { Col } from 'reactstrap';

type TrendsPlaceholderProps = {
  items?: number;
};

const TrendsPlaceholder = ({ items = 3 }: TrendsPlaceholderProps) => {
  return (
    <>
      {[...Array(items).keys()].map((_) => (
        <Col xs={12} className="TrendsTab__item mb-lg-4">
          <ItemPlaceholder height={300} />
        </Col>
      ))}
    </>
  );
};

export default TrendsPlaceholder;
