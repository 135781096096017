import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getTimelineRelatedTrendVisualizationTemplates } from '../../trendVisualizations';
import { TimelineQueryParams, UserQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

interface UseTimelineRelatedTrendVisualizationTemplatesParams
  extends UserQueryParams,
    TimelineQueryParams {
  dateFrom: string | null;
  dateTo: string | null;
}

const useTimelineRelatedTrendVisualizationTemplates = ({
  params,
  options,
}: IQueryRequest<UseTimelineRelatedTrendVisualizationTemplatesParams>) => {
  const { userId, timelineId, dateFrom, dateTo } = params;
  return useQuery({
    ...options,
    queryKey: [
      QueryKeys.getTrendVisualizationTemplates,
      userId,
      timelineId,
      dateFrom,
      dateTo,
    ],
    queryFn: async () => {
      const { data } = await getTimelineRelatedTrendVisualizationTemplates(
        userId,
        timelineId,
        dateFrom,
        dateTo
      );
      return data.data;
    },
  });
};

export default useTimelineRelatedTrendVisualizationTemplates;
