import { useMemo } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

export const usePathContext = () => {
  const context = useParams();
  const [searchParams] = useSearchParams();

  return useMemo(() => {
    let pathContext: Record<string, string | undefined> = context;

    searchParams.forEach((value, key) => {
      pathContext[key] = value;
    });

    return pathContext;
  }, [context, searchParams]);
};
