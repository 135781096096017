import { getOrganizationFunctionalities } from 'api/functionalities';
import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { OrganizationQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

function useOrganizationFunctionalities({
  params,
  options,
}: IQueryRequest<OrganizationQueryParams>) {
  const { organizationId } = params;
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getOrganizationFunctionalities(organizationId);

      return data.data;
    },
    queryKey: [QueryKeys.getOrganizationFunctionalities, organizationId],
  });
}

export default useOrganizationFunctionalities;
