import { format } from 'date-fns';
import { formatDate } from 'helpers/dates';
import { useInitQuery } from 'hooks/queries/useInitQuery';
import {
  ConversationTemplateContext,
  ConversationTemplateTriggerType,
  IUserConversationHistoryEntry,
} from 'interfaces/conversations';
import { useContext, useMemo } from 'react';
import { useCommonData } from '../../../../../../../hooks/useCommonData';
import { useCommonParams } from '../../../hooks/useCommonParams';
import {
  ChangeCaseConversationModalsContext,
  ChangeCaseConversationSelectionContext,
} from '../../../index';

const formatConversationHistoryHour = (hour: string) => {
  let isoString = new Date().toISOString();
  isoString = isoString.substring(0, isoString.indexOf('T'));
  const date = new Date(`${isoString}T${hour.replace('.', ':')}:00`);

  date.setHours(date.getHours() + 2);

  return format(date, 'HH:mm');
};

export const useConversationItemUtils = (
  conversation: IUserConversationHistoryEntry
) => {
  const { organizationId } = useCommonParams();
  const { conversationTemplatesQuery } = useCommonData();

  useInitQuery(conversationTemplatesQuery);

  const { template, date } = useContext(ChangeCaseConversationSelectionContext);
  const { phoneModal } = useContext(ChangeCaseConversationModalsContext);

  const conversationTemplateDisplayCode = useMemo(() => {
    return (
      conversationTemplatesQuery.data?.find(
        (p) => p.id === conversation.conversationTemplateId
      )?.display_code ?? ''
    );
  }, [conversation.conversationTemplateId, conversationTemplatesQuery.data]);

  const formatConversationHistoryDate = useMemo(() => {
    return formatDate(
      new Date(
        conversation.year,
        conversation.month - 1,
        conversation.day
      ).toISOString(),
      {
        ignoreTime: true,
      }
    );
  }, [conversation.day, conversation.month, conversation.year]);

  const handleSelectConversation = () => {
    template.setItem({
      id: conversation.conversationTemplateId,
      displayCode: '',
      triggerType: ConversationTemplateTriggerType.Time,
      context: ConversationTemplateContext.UserConversation,
      organizationId: organizationId,
      lastResortTemplate: false,
      content: conversation.conversationContent.map((item) => ({
        type: item.type,
        applyTypingEffect: item.applyTypingEffect,
        resourceId: item.resourceId,
        persist: true,
        concernNotificationPreferences: null,
        condition: '',
        value: item.value,
      })),
    });

    date.setItem(
      `${formatDate(
        new Date(
          conversation.year,
          conversation.month,
          conversation.day
        ).toISOString(),
        { ignoreTime: true }
      )} ${conversation.hour}`
    );

    phoneModal.toggle();
  };

  return {
    conversationTemplateDisplayCode: conversationTemplateDisplayCode,
    formatConversationHistoryHour: formatConversationHistoryHour,
    formatConversationHistoryDate: formatConversationHistoryDate,
    handleSelectConversation: handleSelectConversation,
  };
};
