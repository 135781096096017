import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from 'reactstrap';
import LoadingButton from './LoadingButton';

type ButtonWithConfirmationModalProps = {
  body: string | JSX.Element;
  header: string | JSX.Element;
  className?: string;
  onConfirmClick: () => void;
  isLoading: boolean;
  color?: string;
  outline?: boolean;
};

const ButtonWithConfirmationModal = ({
  body,
  header,
  className,
  onConfirmClick,
  children,
  color = 'primary',
  isLoading = false,
  outline = false,
}: React.PropsWithChildren<ButtonWithConfirmationModalProps>) => {
  const [isOpen, setOpen] = useState(false);

  const toggle = () => setOpen(!isOpen);
  const onConfirm = () => {
    setOpen(false);
    onConfirmClick();
  };

  return (
    <>
      <LoadingButton
        outline={outline}
        className={className}
        onClick={toggle}
        isLoading={isLoading}
        color={color}
      >
        {children}
      </LoadingButton>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="ButtonWithConfirmationModal__modal"
      >
        <ModalHeader>{header}</ModalHeader>
        <ModalBody>{body}</ModalBody>
        <ModalFooter>
          <Button onClick={toggle} color="danger">
            <FormattedMessage id="General.decline" />
          </Button>
          <LoadingButton
            onClick={onConfirm}
            color="primary"
            isLoading={isLoading}
          >
            <FormattedMessage id="General.confirm" />
          </LoadingButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ButtonWithConfirmationModal;
