import { useCallback } from 'react';
import {
  ISeriesSqueezeChart,
  ISqueezeChartData,
  Meaning,
} from '../../../../../interfaces/timeline';
import { useSeries } from './useSeries';
import { UseQueryResult } from 'react-query';
import { IErrorWithCode } from '../../../../../interfaces/response';

export const useUpdateExistingEntry = (
  observationQuery: UseQueryResult<ISqueezeChartData, IErrorWithCode>
) => {
  const { getSumOfCustomEntries } = useSeries(observationQuery.data);

  return useCallback(
    (
      seriesData: any[],
      index: number,
      entryName: string,
      entryValue: number,
      uncategorizedSqueezes: ISeriesSqueezeChart | undefined,
      notSpecifiedEntryValue: any
    ) => {
      const existingEntry = seriesData.find((p) => p.name === index);

      if (existingEntry) {
        existingEntry[entryName] = entryValue;

        if (!uncategorizedSqueezes) return { seriesData, omitted: true };

        let sumOfCustomEntries = getSumOfCustomEntries(existingEntry);
        existingEntry[Meaning.NotSpecified] =
          notSpecifiedEntryValue === sumOfCustomEntries
            ? 0
            : notSpecifiedEntryValue;

        return { seriesData, omitted: true };
      }

      return { seriesData, omitted: false };
    },
    [getSumOfCustomEntries]
  );
};
