import {
  AllowToChooseGroup,
  FieldDefinition,
  IProject,
} from '../../../../../interfaces/submissions';
import { useCallback, useMemo, useState } from 'react';
import { cloneDeep } from 'lodash';
import { useIntl } from 'react-intl';
import { IFormHookValues } from '../../../../../interfaces/forms';
import { CreateProjectFormValues } from '../types/ProjectModal';
import { FormikErrors } from 'formik';
import { useProjectModalDetailsFormValidation } from '../components/ProjectModalDetailsForm/hooks/useProjectModalDetailsFormValidation';
import { useProjectModalOverviewFormValidation } from '../components/ProjectModalOverviewForm/hooks/useProjectModalOverviewFormValidation';
import { useProjectModalDetailsForm } from '../components/ProjectModalDetailsForm/hooks/useProjectModalDetailsForm';
import useCreateProject from '../../../../../api/mutations/projects/useCreateProject';
import useRequiredParams from '../../../../../hooks/useRequiredParams';
import useModifyProject from '../../../../../api/mutations/projects/useModifyProject';
import { useProjectModalTransformations } from './useProjectModalTransformations';
import useCultures from '../../../../../hooks/cultures/useCultures';

export enum ProjectModalFormMode {
  AddProject = 'AddProject',
  EditProject = 'EditProject',
}

interface IProjectModalFormTranslations {
  formTitle: string;
  getTranslationForStep: (step: ProjectModalFormStep) => string;
}

interface IUseProjectModalFormValues
  extends IFormHookValues<CreateProjectFormValues> {
  mode: ProjectModalFormMode;
  step: ProjectModalFormStep;
  setStep: (step: ProjectModalFormStep) => void;
  translations: IProjectModalFormTranslations;
}

export enum ProjectModalFormStep {
  Overview = 'Overview',
  Details = 'Details',
  ProjectLinks = 'ProjectLinks',
  RegistrationFields = 'RegistrationFields',
  SubmissionSetup = 'SubmissionSetup',
}

const emptyInitialValues: CreateProjectFormValues = {
  date_from: null,
  date_to: null,
  contact_email: null,
  allow_to_choose_group: AllowToChooseGroup.No,
  code: '',
  case_sub_category_id: null,
  case_category_id: '',
  default_culture: '',
  description: {},
  fields_definition: {
    address: FieldDefinition.Optional,
    email: FieldDefinition.Optional,
    birth_date: FieldDefinition.Optional,
    ssn: FieldDefinition.Optional,
    gender: FieldDefinition.Optional,
    first_name: FieldDefinition.Optional,
    last_name: FieldDefinition.Optional,
    password: FieldDefinition.Optional,
    phone_number: FieldDefinition.Optional,
    timezone_id: FieldDefinition.Optional,
  },
  for_who: null,
  mobile_header: {},
  owner_personality_id: '',
  place: null,
  participation_consent: '',
  post_register_message: {},
  privacy_policy: '',
  short_description: {},
  successful_submission_message: {},
  template_code: '',
  title: {},
  terms_of_service: '',
  unsuccessful_submission_message: {},
};

export const useProjectModalForm = (
  project?: IProject,
  projectRefetch?: () => void,
  toggle?: () => void
): IUseProjectModalFormValues => {
  const intl = useIntl();

  const { currentCulture } = useCultures();

  const { validate: validateDetails } = useProjectModalDetailsFormValidation();
  const { validate: validateOverview } =
    useProjectModalOverviewFormValidation();

  const {
    transformProjectToInitialValues,
    transformInitialValuesToCreateProject,
    transformCreateProjectToPatchObject,
  } = useProjectModalTransformations();

  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const handleRefetch = () => {
    if (projectRefetch) projectRefetch();
    if (toggle) toggle();
  };

  const { mutateAsync: createAsync } = useCreateProject(
    organizationId,
    handleRefetch
  );
  const { mutateAsync: modifyAsync } = useModifyProject(
    organizationId,
    handleRefetch
  );

  const { options: detailsOptions } = useProjectModalDetailsForm();

  const [formStep, setFormStep] = useState<ProjectModalFormStep>(
    ProjectModalFormStep.Overview
  );

  const mode = useMemo(() => {
    return project !== undefined
      ? ProjectModalFormMode.EditProject
      : ProjectModalFormMode.AddProject;
  }, [project]);

  const getEditModeInitialValues = useCallback(
    (data: IProject): typeof initialValues => {
      let dataCopy = cloneDeep(data);
      return transformProjectToInitialValues(dataCopy);
    },
    [transformProjectToInitialValues]
  );

  const getEmptyInitialValues = useCallback((): typeof initialValues => {
    let empty = cloneDeep(emptyInitialValues);

    if (detailsOptions.organizationMembers.length > 0)
      empty.owner_personality_id = detailsOptions.organizationMembers[0].value;

    empty.default_culture = currentCulture.cultureCode;

    return empty;
  }, [currentCulture.cultureCode, detailsOptions.organizationMembers]);

  const initialValues: CreateProjectFormValues = useMemo(() => {
    return project
      ? getEditModeInitialValues(project)
      : getEmptyInitialValues();
  }, [getEditModeInitialValues, getEmptyInitialValues, project]);

  const handleSubmit = async (values: typeof initialValues) => {
    let createProjectBody = transformInitialValuesToCreateProject(values);

    if (!project) {
      await createAsync({ data: createProjectBody });
      return;
    }

    const patch = transformCreateProjectToPatchObject(
      createProjectBody,
      initialValues
    );
    await modifyAsync({ projectId: project.id, data: patch });
  };

  const validation = (
    values: typeof initialValues
  ): FormikErrors<typeof initialValues> => {
    let errors: FormikErrors<typeof initialValues> = {};

    errors = validateDetails(values, errors);
    errors = validateOverview(values, errors);

    return errors;
  };

  const formTitle = useMemo(() => {
    return intl.formatMessage({
      id:
        mode === ProjectModalFormMode.AddProject
          ? 'Projects.ProjectModal.addProject'
          : 'Projects.ProjectModal.editProject',
    });
  }, [intl, mode]);

  const getTranslationForStep = useCallback(
    (step: ProjectModalFormStep) => {
      return intl.formatMessage({ id: `Projects.ProjectModal.${step}` });
    },
    [intl]
  );

  return {
    mode: mode,
    initialValues: initialValues,
    handleSubmit: handleSubmit,
    validation: validation,
    translations: {
      formTitle: formTitle,
      getTranslationForStep: getTranslationForStep,
    },
    step: formStep,
    setStep: setFormStep,
  };
};
