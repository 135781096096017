import { flatten } from 'flat';
import React, { createContext, useCallback, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';
import enTranslationMessages from 'translations/en.json';
import noTranslationMessages from 'translations/no.json';
import { getLangWithoutRegion, getSpecifiedLang } from './cultures';

export const translations = {
  en: enTranslationMessages,
  no: noTranslationMessages,
};

const EMPTY_LANGUAGE_CHANGE = () => {
  return;
};

type ChangeLanguageContextType = {
  onLanguageChange: (lang: string) => void;
};

export const ChangeLanguageContext = createContext<ChangeLanguageContextType>({
  onLanguageChange: EMPTY_LANGUAGE_CHANGE,
});

const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(() => getLangWithoutRegion());
  const [messages, setMessages] = useState<Record<string, any>>(() =>
    flatten(translations[getLangWithoutRegion()])
  );

  const onLanguageChange = useCallback((lang: string) => {
    const newLanguage = getSpecifiedLang(lang);
    const newMessages = flatten(translations[newLanguage]) as Record<
      string,
      any
    >;
    setLanguage(newLanguage);
    setMessages(newMessages);
  }, []);

  const languageContext = useMemo(() => {
    return { onLanguageChange: onLanguageChange };
  }, [onLanguageChange]);

  return (
    <IntlProvider locale={language} messages={messages}>
      <ChangeLanguageContext.Provider value={languageContext}>
        {React.Children.only(children)}
      </ChangeLanguageContext.Provider>
    </IntlProvider>
  );
};

export default LanguageProvider;
