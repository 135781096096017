export enum UiActions {
  SET_IS_BROWSER = 'SET_IS_BROWSER',
}

const initialUiState = {
  isBrowser: true,
};

type UiActionTypes = {
  type: UiActions.SET_IS_BROWSER;
  isBrowser: boolean;
};

const uiReducer = (state = initialUiState, action: UiActionTypes) => {
  switch (action.type) {
    case UiActions.SET_IS_BROWSER:
      return {
        isBrowser: action.isBrowser,
      };
    default:
      return state;
  }
};

export default uiReducer;
