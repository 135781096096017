import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import { addCaseSymptoms } from 'api/symptoms';
import { ICreateCaseSymptoms } from 'interfaces/symptoms';
import { IMutationProps } from '../../../interfaces/ui';

interface ICreateCaseSymptomsBody extends ICreateCaseSymptoms {
  caseId: string;
}

type useAddCaseSymptomParams = {
  organizationId: string;
};

const useAddCaseSymptom = (
  params: useAddCaseSymptomParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async ({
      definitions,
      caseId,
    }: ICreateCaseSymptomsBody) => {
      await addCaseSymptoms(params.organizationId, caseId, {
        definitions,
      });
    },
    onSuccess: () => {
      if (options.successFb) options.successFb();
    },
    onErrorArray: (_) => {
      if (options.errorFb) options.errorFb();
    },
    resetOnResult: true,
  });
};

export default useAddCaseSymptom;
