import EventPropagationConstraint from 'components/EventPropagationConstraint';
import React, { useState } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import useCultures from '../../hooks/cultures/useCultures';

type EditableCultureInputProps = {
  value?: string;
  name: string;
  onEdit: (name: string, val: string) => void;
  isDisabled: boolean;
};

const EditableCultureInput = ({
  name,
  value,
  onEdit,
  isDisabled,
}: EditableCultureInputProps) => {
  const {
    currentCulture,
    flagCodes,
    flagLabels,
    cultures,
    getAvailableCulture,
  } = useCultures();

  const selectedCulture = getAvailableCulture(
    value ?? currentCulture.cultureCode
  );

  const handleSelect = (countryCode: string) => {
    const availableCulture = cultures.find(
      (p) => p.upperCountryCode === countryCode
    );

    if (availableCulture === undefined) return;

    setInternalSelected(availableCulture.upperCountryCode);
    onEdit(name, availableCulture.cultureCode);
  };

  const [internalSelected, setInternalSelected] = useState<string>(
    selectedCulture?.upperCountryCode ?? currentCulture.upperCountryCode
  );

  return (
    <EventPropagationConstraint>
      <ReactFlagsSelect
        className="react-flag-select"
        countries={flagCodes}
        customLabels={flagLabels}
        selected={internalSelected}
        disabled={isDisabled}
        alignOptionsToRight={true}
        fullWidth={true}
        onSelect={handleSelect}
      />
    </EventPropagationConstraint>
  );
};

export default EditableCultureInput;
