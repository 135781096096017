import * as fns from 'date-fns';
import _ from 'lodash';
import { AvailableCulture } from '../hooks/cultures/useCultures';
import { formatDate } from './dates';

export namespace Expressions {
  export const Evaluate = (expression: string, scope: any) => {
    // eslint-disable-next-line no-new-func
    return new Function(`with (this) { return (${expression}); }`).call(scope);
  };

  export const HandlebarsEvaluate = (
    expression: string,
    scope: any,
    culture: AvailableCulture
  ) => {
    try {
      const compiled = _.template(expression, {
        interpolate: /{{([\s\S]+?)}}/g,
        evaluate: /{{([\s\S]+?)}}/g,
        escape: /{{([\s\S]+?)}}/g,
        imports: {
          fns,
          formatDate,
        },
      });
      return compiled({ ...scope, currentCulture: culture });
    } catch (e) {
      return expression;
    }
  };
}
