import { useState } from 'react';
import { Row } from 'reactstrap';
import SVG from 'react-inlinesvg';

//types
import {
  AssessmentQuestionType,
  IUserAssessments,
} from 'interfaces/assessmentForms';

//helpers
import { returnIconIndicatorForCompletionStatus } from 'helpers/utils/icons';

//components
import SingleAssessmentFormBanner from './SingleAssessmentFormBanner';
import QuestionVisualization from './QuestionVisualization';

type SingleAssessmentFormProps = {
  form: IUserAssessments;
  isOwner: boolean;
  userId: string;
  forceEdit?: boolean;
};

const SingleAssessmentForm = ({
  form,
  isOwner,
  userId,
  forceEdit = false,
}: SingleAssessmentFormProps) => {
  const [toggled, setToggled] = useState(false);

  const toggleAssessment = () => {
    setToggled(!toggled);
  };

  const canEdit =
    (isOwner || form.isEditableByOrganizationMembers) &&
    form.isAvailableToCompletion;

  const questionsWithoutHeader = form.questions.filter(
    (p) => p.type !== AssessmentQuestionType.HEADER
  );

  const renderedQuestions = questionsWithoutHeader.map((question, index) => (
    <QuestionVisualization
      key={question.guid}
      completionStatus={form.completionStatus}
      formCode={form.code}
      index={index}
      question={question}
      questionsLength={questionsWithoutHeader.length}
      toggled={toggled}
      userId={userId}
    />
  ));

  return (
    <>
      {toggled ? (
        <div
          className={`DayDetails__assessment-form DayDetails__assessment-form--toggled DayDetails__assessment-form--${form.completionStatus.toLowerCase()}`}
        >
          <div onClick={toggleAssessment}>
            <SingleAssessmentFormBanner
              hour={form.hour}
              status={form.completionStatus}
              sequence={form.sequence}
              canEdit={canEdit}
              contextType={form.contextType}
              contextObjectId={form.contextObjectId}
              forceEdit={forceEdit}
            />
          </div>
          <Row className="DayDetails__assessment-form__container">
            {renderedQuestions}
          </Row>
          <SVG
            src={returnIconIndicatorForCompletionStatus(
              form.completionStatus,
              true
            )}
            title={form.title}
            className="DayDetails__assessment-form__indicator"
          />
        </div>
      ) : (
        <div
          className={`DayDetails__assessment-form DayDetails__assessment-form--${form.completionStatus.toLowerCase()}`}
          onClick={toggleAssessment}
        >
          <div
            className={`DayDetails__assessment-form__title DayDetails__assessment-form__title__${form.completionStatus.toLowerCase()}`}
          >
            {form.title}
            <span className="DayDetails__assessment-form__completion-hour">
              {form.hour}
            </span>
          </div>
          <div className="DayDetails__assessment-form__icons">
            {renderedQuestions}
          </div>
          <SVG
            src={returnIconIndicatorForCompletionStatus(
              form.completionStatus,
              true
            )}
            title={form.title}
            className="DayDetails__assessment-form__indicator"
          />
        </div>
      )}
    </>
  );
};

export default SingleAssessmentForm;
