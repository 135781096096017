import React, { memo } from 'react';
import SkeletonPlaceholderOnLoad from '../../../../../../../../../../../../../../../../../../../../components/SkeletonPlaceholderOnLoad';
import EditableInput from '../../../../../../../../../../../../../../../../../../../../components/EditableInput/EditableInput';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { useCommonData } from '../../../../../../../../../../../../../../../../../../hooks/useCommonData';
import { useInitQuery } from '../../../../../../../../../../../../../../../../../../../../hooks/queries/useInitQuery';
import { useOptions } from '../../../../../../../../../../../../../../../../../../../../hooks/useOptions';
import { useControlledValue } from '../../../../../../../../../../../../../../../../../../../../hooks/useControlledValue';
import { useHandleApply } from './hooks/useHandleApply';
import Skeleton from 'react-loading-skeleton';
import { useTranslations } from '../../../../../../../../../../../../../../../../../../../../hooks/useTranslations';

type SelectFeedbackActionProps = {
  groupName: string;
  onResolve: () => void;
};

const SelectFeedbackAction = ({
  groupName,
  onResolve,
}: SelectFeedbackActionProps) => {
  const { attachPrefix, wrapWithIntl } = useTranslations(
    'ConversationsAndFeedbacks.ConversationTemplates.ImportTemplateModal.Content.ConflictsStep.Validation.Response.Conflicts.SelectFeedbackAction'
  );

  const { feedbackResources } = useCommonData();
  useInitQuery(feedbackResources);

  const options = useOptions();
  const feedbackResourcesOptions = options.feedbackResources(feedbackResources);

  const { value, handleChange } = useControlledValue<string>();

  const handleApply = useHandleApply(groupName, onResolve);

  const onEdit = (_: string, value: string) => {
    handleChange(value);
  };

  const handleApplyGroupName = () => value && handleApply(value);

  return (
    <div className="d-flex align-items-center gap-2">
      <SkeletonPlaceholderOnLoad
        isLoading={feedbackResources.isLoading}
        placeholder={<Skeleton width={200} height={30} />}
      >
        <EditableInput
          type="select"
          displayEmpty={true}
          required={true}
          placeholder={wrapWithIntl(attachPrefix('selectFeedback'))}
          options={feedbackResourcesOptions}
          value={value ?? ''}
          onEdit={onEdit}
          debounceEnabled={false}
        />
      </SkeletonPlaceholderOnLoad>
      <Button
        disabled={!value}
        color="primary"
        outline={true}
        onClick={handleApplyGroupName}
      >
        <FontAwesomeIcon icon={faCheck} />
      </Button>
    </div>
  );
};

export default memo(SelectFeedbackAction);
