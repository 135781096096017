import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import { activateCaseSymptom, deactivateCaseSymptom } from 'api/symptoms';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';

type ToggleArchiveMutationParams = {
  shouldBeArchived: boolean;
  caseMeaningId: string;
};

function useToggleArchiveCaseSymptom(
  organizationId: string,
  caseId: string,
  successFb?: () => void
) {
  return useMutationWithResultNotification({
    asyncApiFunction: async ({
      caseMeaningId,
      shouldBeArchived,
    }: ToggleArchiveMutationParams) => {
      if (shouldBeArchived)
        await activateCaseSymptom(organizationId, caseId, caseMeaningId);
      else await deactivateCaseSymptom(organizationId, caseId, caseMeaningId);
    },
    resetOnResult: true,
    onSuccess: () => {
      displaySuccessNotification(
        'ObservationMeaning.changeArchiveStatus.success'
      );
      if (successFb) successFb();
    },
    onErrorArray: (errors) => {
      displayErrorArrayNotifications(
        errors,
        'ObservationMeaning.changeArchiveStatus.errors'
      );
    },
  });
}

export default useToggleArchiveCaseSymptom;
