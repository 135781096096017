import { VisualizationDataContextType } from '../../components/Visualizations/types';
import AwareSessionDataProvider from './providers/AwareSessionDataProvider';
import DashboardReportsProvider from './providers/DashboardReportsProvider';
import QueryBasedDataProvider from './providers/QueryBasedDataProvider';

export type VisualizationDataProviderProps = {
  children: (data: VisualizationDataContextType) => JSX.Element;
};

const VisualizationDataProvider = ({
  children,
}: VisualizationDataProviderProps) => {
  return (
    <AwareSessionDataProvider>
      {(sessions) => (
        <DashboardReportsProvider>
          {(reports) => (
            <QueryBasedDataProvider>
              {(items) => children({ sessions, reports, items })}
            </QueryBasedDataProvider>
          )}
        </DashboardReportsProvider>
      )}
    </AwareSessionDataProvider>
  );
};

export default VisualizationDataProvider;
