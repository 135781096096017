import React from 'react';
import { useCaseAndSessionsDetails } from '../providers/SessionBasedDataProvider/hooks/useCaseAndSessionsDetails';
import { useInitQueries } from '../../../../../../../hooks/queries/useInitQuery';
import { useBreadcrumbs } from '../hooks/useBreadcrumbs';
import QueriesLoaded from 'components/QueriesLoaded';
import PageHeader from 'components/PageHeader';
import FullScreenSpinner from 'components/Spinners/FullScreenSpinner';
import AwareCaseContent from './AwareCaseContent';
import { ICaseDetails } from 'interfaces/cases';
import { IAwareSessionData } from 'interfaces/awareSessions';

const OldAwareCaseDetailsView = () => {
  const queries = useCaseAndSessionsDetails();
  useInitQueries(queries);

  const { breadcrumbs, isLoading } = useBreadcrumbs();

  return (
    <PageHeader
      breadcrumbs={breadcrumbs}
      isLoading={isLoading}
      content={
        <QueriesLoaded onLoading={<FullScreenSpinner />} queries={queries}>
          {(data) => (
            <AwareCaseContent
              details={data[0] as ICaseDetails}
              awareSession={data[2] as IAwareSessionData}
            />
          )}
        </QueriesLoaded>
      }
    />
  );
};

export default OldAwareCaseDetailsView;
