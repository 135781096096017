import { Box, Grid, Paper, ThemeProvider, Typography } from '@mui/material';
import awareGraspDevice from 'assets/images/aware_grasp_device.svg';

import logoHeader from 'assets/images/logo-header.svg';
import React, { PropsWithChildren } from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';
import { ThemeGenerators } from 'themes/generators';
import { ThemesOptions } from '../../../themes/options';

const OrganizationRegisterLayout = ({ children }: PropsWithChildren) => {
  const options = [
    ThemesOptions.baseThemeOptions,
    ThemesOptions.darkThemeOptions,
    ThemesOptions.awareThemeOptions,
  ];

  return (
    <ThemeProvider theme={ThemeGenerators.MixedThemeGenerator(...options)}>
      <Box className="h-vh-100">
        <Grid container spacing={0} className="h-100">
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <Paper
              square={true}
              className="d-flex w-100 h-100 position-relative justify-content-center"
              sx={{ backgroundColor: 'common.black' }}
            >
              <div className="position-absolute top-px-30 left-px-30">
                <SVG src={logoHeader} height={64} width={114} />
              </div>
              <div className="d-flex flex-column align-self-center align-items-center w-px-490">
                <Typography
                  variant="h4"
                  gutterBottom
                  sx={{
                    fontSize: '40px',
                    fontFamily: 'Ubuntu',
                    marginBottom: '120px',
                  }}
                >
                  <FormattedMessage id="Organizations.AwareWelcomeMessage" />
                </Typography>
                <SVG src={awareGraspDevice} height={200} width={270} />
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} lg={6} xl={6}>
            <Paper
              square={true}
              className="d-flex w-100 h-100 align-items-center justify-content-center"
            >
              <div className="d-flex h-60 w-75">
                {React.Children.only(children)}
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </ThemeProvider>
  );
};

export default OrganizationRegisterLayout;
