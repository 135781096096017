import { addCaseHistory } from 'api/cases';
import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from 'modules/Notifications';
import { IMutationProps } from '../../../interfaces/ui';
import { ICaseHistoryBody } from '../../../interfaces/caseHistory';

type useAddCaseHistoryParams = {
  organizationId: string;
  caseId: string;
};

const useAddCaseHistory = (
  params: useAddCaseHistoryParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (data: ICaseHistoryBody) => {
      await addCaseHistory(params.organizationId, params.caseId, data);
    },
    onSuccess: () => {
      if (options.successFb) options.successFb();
      displaySuccessNotification('CaseCard.History.newEntryAdded');
    },
    onError: () => {
      if (options.errorFb) options.errorFb();
      displayErrorNotification('CaseCard.History.newEntryAddFailed');
    },
    resetOnResult: true,
  });
};

export default useAddCaseHistory;
