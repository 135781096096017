import {
  AssetType,
  IAsset,
} from '../../../../../../../../interfaces/educationMaterials';
import { textWrapEllipse } from '../../../../../../../../helpers/utils/strings';
import AssetThumbnailUploadButton from '../AssetThumbnailUploadButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExcel,
  faImage,
  faVideo,
} from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import AssetThumbnailRemoveButton from '../AssetThumbnailRemoveButton';

type AssetThumbnailRowProps = {
  asset: IAsset;
};

const AssetThumbnailUploadRow = ({ asset }: AssetThumbnailRowProps) => {
  return (
    <tr>
      <td>
        {asset.thumbnail_url ? (
          <img
            style={{ maxWidth: '100px' }}
            className="img-thumbnail"
            src={`${asset.thumbnail_url}?${Date.now()}`}
            alt={asset.name}
          />
        ) : (
          <>
            {asset.type === AssetType.Video && (
              <FontAwesomeIcon
                className="text-primary opacity-25"
                size="6x"
                icon={faVideo}
              />
            )}
            {asset.type === AssetType.Image && (
              <FontAwesomeIcon
                className="text-primary opacity-25"
                size="6x"
                icon={faImage}
              />
            )}
            {asset.type === AssetType.Attachment && (
              <FontAwesomeIcon
                className="text-primary opacity-25"
                size="6x"
                icon={faFileExcel}
              />
            )}
          </>
        )}
      </td>
      <td>{textWrapEllipse(asset.thumbnail_url ?? '--', 50)}</td>
      <td>
        <div className="AssetThumbnailUploadItem__actions">
          <AssetThumbnailUploadButton asset={asset} />
          <AssetThumbnailRemoveButton assetId={asset.id} />
        </div>
      </td>
    </tr>
  );
};

export default AssetThumbnailUploadRow;
