import { updateAccount } from 'api/membership';
import { IPersonality } from 'interfaces/personalities';
import { IPatchObject } from 'interfaces/request';
import { IErrorWithCode } from 'interfaces/response';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';

function useUserEdit(userId: string, successFb?: () => void) {
  const dispatch = useDispatch();
  const result = useMutation<IPersonality, IErrorWithCode, IPatchObject[]>(
    async (data) => {
      const { data: responseData } = await updateAccount(userId, data);
      return responseData.data;
    }
  );

  useEffect(() => {
    if (result.isSuccess && result.data) {
      displaySuccessNotification('General.accountUpdated', {
        autoClose: false,
      });

      result.reset();
      successFb && successFb();
    }

    if (result.isError && result.error) {
      const errorArray = result.error?.data?.meta?.errors || [];

      displayErrorArrayNotifications(
        errorArray,
        'Register.notifications.errors'
      );
      result.reset();
    }
  }, [result, dispatch, successFb]);

  return result;
}

export default useUserEdit;
