import React, { ChangeEvent } from 'react';
import { Button, Form, FormGroup, Input } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik } from 'formik';
import { deletionReasonsOptions } from '../../../../helpers/utils/translationObject';
import classNames from 'classnames';
import noteBlueIcon from '../../../../assets/images/timeline_details/note_blue.svg';
import {
  AccountDeletionData,
  AccountDeletionStep,
} from './AccountDeletionModal';
import { validateEmpty } from '../../../../helpers/utils/validators';
import { MyProfileTabs } from '../index';

type AccountDeletionFormProps = {
  toggle: () => void;
  accountDeletionData: AccountDeletionData;
  setAccountDeletionData: (data: AccountDeletionData) => void;
  setAccountDeletionStep: (step: AccountDeletionStep) => void;
  setSelectedTab: (tab: MyProfileTabs) => void;
};

export enum AccountDeletionReason {
  ToManyNotifications = 'ToManyNotifications',
  NotGettingValue = 'NotGettingValue',
  PrivacyConcern = 'PrivacyConcern',
  Other = 'Other',
}

type AccountDeletionFormInitialValues = {
  deletion_reasons: AccountDeletionReason[];
  newsletter_sign_out: boolean;
  feedback: string | null;
};

const AccountDeletionForm = ({
  toggle,
  accountDeletionData,
  setAccountDeletionData,
  setAccountDeletionStep,
  setSelectedTab,
}: AccountDeletionFormProps) => {
  const intl = useIntl();

  const initialValues: AccountDeletionFormInitialValues = {
    newsletter_sign_out: false,
    deletion_reasons: [],
    feedback: null,
  };

  const handleSubmitForm = ({
    deletion_reasons,
    feedback,
  }: typeof initialValues) => {
    setAccountDeletionData({
      ...accountDeletionData,
      deletion_reasons: deletion_reasons,
      feedback: feedback,
    });

    setAccountDeletionStep(AccountDeletionStep.VerifyPassword);
  };

  const renderDeletionReasons = (
    values: typeof initialValues,
    setValues: (values: typeof initialValues) => void
  ) => {
    return deletionReasonsOptions.map((reason) => {
      const handleChangeReason = () => {
        if (
          values.deletion_reasons.includes(
            reason.value as AccountDeletionReason
          )
        ) {
          setValues({
            ...values,
            deletion_reasons: values.deletion_reasons.filter(
              (p) => p !== reason.value
            ),
          });

          return;
        }

        setValues({
          ...values,
          deletion_reasons: [
            ...values.deletion_reasons,
            reason.value as AccountDeletionReason,
          ],
        });
      };

      const handleGoToNotifications = () => {
        setSelectedTab(MyProfileTabs.NOTIFICATIONS);
      };

      return (
        <>
          <FormGroup
            key={reason.value}
            onClick={handleChangeReason}
            check
            className={classNames('QuestionModal__answer', {
              'QuestionModal__answer--selected':
                values.deletion_reasons.includes(
                  reason.value as AccountDeletionReason
                ),
            })}
          >
            <Input
              type="checkbox"
              name="deletionReason"
              checked={values.deletion_reasons.includes(
                reason.value as AccountDeletionReason
              )}
              onChange={handleChangeReason}
              value={reason.value}
              className="ms-1"
            />
            <div className="QuestionModal__answer__text-wrapper">
              <div className="QuestionModal__answer__description">
                <FormattedMessage id={reason.translationId} />
              </div>
            </div>
          </FormGroup>
          {reason.value === AccountDeletionReason.ToManyNotifications &&
            values.deletion_reasons.includes(
              AccountDeletionReason.ToManyNotifications
            ) && (
              <div className="AccountDeletionModal__go-to-notifications">
                <FormattedMessage id="MyProfile.AccountDeletion.AccountDeletionModal.didYouKnow" />
                <span onClick={handleGoToNotifications}>
                  <FormattedMessage id="MyProfile.AccountDeletion.AccountDeletionModal.notificationSettings" />
                </span>
                <FormattedMessage id="MyProfile.AccountDeletion.AccountDeletionModal.toPreventThat" />
              </div>
            )}
        </>
      );
    });
  };

  const renderFeedbackField = (
    values: typeof initialValues,
    setValues: (values: typeof initialValues) => void
  ) => {
    const handleFeedbackChange = (event: ChangeEvent<HTMLInputElement>) => {
      setValues({
        ...values,
        feedback: event.target.value,
      });
    };

    return (
      <FormGroup className="AccountDeletionModal__textarea">
        <img
          className="AccountDeletionModal__textarea__addon"
          src={noteBlueIcon}
          alt="note_icon"
        />
        <Input
          type="textarea"
          onChange={handleFeedbackChange}
          className="QuestionModal__textarea AccountDeletionModal__textarea__text"
          name="feedback"
          value={values.feedback ?? ''}
          placeholder={intl.formatMessage({
            id: 'MyProfile.AccountDeletion.AccountDeletionModal.feedbackPlaceholder',
          })}
        />
      </FormGroup>
    );
  };

  const deletion_reasonsValidator = (values: typeof initialValues) => {
    const validationConstraints = validateEmpty<typeof initialValues>(
      values,
      intl
    );

    if (values.deletion_reasons.length === 0) {
      validationConstraints.deletion_reasons = [AccountDeletionReason.Other];
    }

    return validationConstraints;
  };

  return (
    <div>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={handleSubmitForm}
        validate={deletion_reasonsValidator}
        validateOnMount={true}
      >
        {({ submitForm, isValid, values, setValues }) => (
          <>
            <Form>
              <span className="AccountDeletionModal__options-header">
                <FormattedMessage id="MyProfile.AccountDeletion.AccountDeletionModal.beforeYouGo" />
              </span>
              {renderDeletionReasons(values, setValues)}
              {renderFeedbackField(values, setValues)}
            </Form>
            <div className="AccountDeletionModal__buttons">
              <Button
                outline
                color="primary"
                onClick={toggle}
                className="AccountDeletionModal__button"
              >
                <FormattedMessage id="General.cancel" />
              </Button>
              <Button
                className="AccountDeletionModal__button"
                disabled={!isValid}
                type="submit"
                color="primary"
                onClick={submitForm}
              >
                <FormattedMessage id="General.next" />
              </Button>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default AccountDeletionForm;
