import React, { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

interface TextMaskInputProps {
  mask: string;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const TextMaskInput = forwardRef<HTMLInputElement, TextMaskInputProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, mask, ...other } = props;

    const handleAccept = (value: any) =>
      onChange({ target: { name: props.name, value } });

    return (
      <IMaskInput
        {...other}
        mask="00:00:00"
        inputRef={ref}
        onAccept={handleAccept}
        overwrite
      />
    );
  }
);

export default TextMaskInput;
