import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Image from 'components/Image';
import React from 'react';

type OrganizationListItemProps = {
  name?: string | JSX.Element;
  image?: string;
  icon?: IconProp;
  subtitle?: string;
  onClick?: () => void;
};

const OrganizationListItem = ({
  name,
  image,
  subtitle,
  icon,
  onClick,
}: OrganizationListItemProps) => {
  return (
    <li
      className="ContextSelector__dropdown-item"
      onClick={onClick}
      aria-label={`list_item_${subtitle}`}
    >
      <div className="ContextSelector__dropdown-item__thumbnail">
        {image && <Image src={image} alt={image} />}
        {icon && <FontAwesomeIcon icon={icon} />}
      </div>
      <div className="d-flex flex-column ms-2">
        {name && (
          <span className="ContextSelector__dropdown-item__title">{name}</span>
        )}
        {subtitle && (
          <span className="ContextSelector__dropdown-item__sub-title">
            {subtitle}
          </span>
        )}
      </div>
    </li>
  );
};

export default OrganizationListItem;
