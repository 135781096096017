export enum DentalOrganizationTabsValues {
  Info = 'Info',
  Grasps = 'Grasps',
  GraspSettings = 'GraspSettings',
  CaseCategories = 'CaseCategories',
  OtherDevices = 'OtherDevices',
  Users = 'Users',
  AccessProfiles = 'AccessProfiles',
  TrendVisualizations = 'TrendVisualizations',
  Symptoms = 'Symptoms',
  Functionalities = 'Functionalities',
  ObservationMeanings = 'ObservationMeanings',
  Forms = 'Forms',
  Education = 'Education',
  ReportSchedules = 'ReportSchedules',
}
