import { ErrorMessage } from 'formik';
import React from 'react';

type FormikErrorMessageProps = {
  name: string;
};

const FormikErrorMessage: React.FC<FormikErrorMessageProps> = ({ name }) => {
  return (
    <ErrorMessage
      name={name}
      render={(msg) => (
        <span className="formik_error">
          <small>{msg}</small>
        </span>
      )}
    />
  );
};

export default FormikErrorMessage;
