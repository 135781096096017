import React, { useState } from 'react';
import Editor from './components/Editor';
import Conflicts from './components/Conflicts';
import { ValidationContext } from '../../types';
import { ValidationResult } from '../../../../../../types';

type ResponseProps = {
  response: ValidationResult;
};

const Response = ({ response }: ResponseProps) => {
  const responseStateTuple = useState<ValidationResult>(response);

  return (
    <div className="d-flex flex-column justify-content-center gap-2">
      <ValidationContext.Provider value={responseStateTuple}>
        <Editor />
        <Conflicts />
      </ValidationContext.Provider>
    </div>
  );
};

export default Response;
