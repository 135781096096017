import { combineReducers } from 'redux';

import membershipReducer from 'modules/membership/reducer';
import timelineDetailsReducer from 'modules/TimelineDetails/reducer';
import caseReducer from 'modules/Cases/store/reducer';
import uiReducer from './ui/reducer';
import submissionReducer from 'modules/ProjectSubmissions/store/reducer';
import dashboardReducer from '../components/Dashboard/components/Dashboard/store/reducer';

const createRootReducer = () =>
  combineReducers({
    membership: membershipReducer,
    timelineDetails: timelineDetailsReducer,
    cases: caseReducer,
    submissions: submissionReducer,
    dashboard: dashboardReducer,
    ui: uiReducer,
  });

export default createRootReducer;
