import React, { useMemo, useState } from 'react';
import CaseGroupCollectionItem from '../../../../../../../../components/CaseGroupCollectionItem';
import { IBaseCaseGroupSession } from 'interfaces/caseGroups';
import { formatDate } from 'helpers/dates';
import { useIntl } from 'react-intl';
import {
  faEye,
  faEyeSlash,
  faLink,
  faLock,
  faUsersClass,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type CaseGroupDetailsSessionsItemProps = {
  session: IBaseCaseGroupSession;
};

const CaseGroupDetailsSessionsItem = ({
  session,
}: CaseGroupDetailsSessionsItemProps) => {
  const intl = useIntl();

  const [protectedMode, setProtectedMode] = useState<boolean>(true);

  const meetingTitle = useMemo(() => {
    const plannedAsMessage = intl.formatMessage({
      id: 'CaseGroupItem.meetingScheduledFor',
    });

    return `${plannedAsMessage} ${formatDate(session.meetings_date)}`;
  }, [intl, session.meetings_date]);

  const protectedPinCode = useMemo(() => {
    if (!protectedMode) return '';

    return [...Array(session.meetings_pin_code.length).keys()]
      .map((_) => '*')
      .join('');
  }, [protectedMode, session.meetings_pin_code.length]);

  const toggleProtectedMode = () => {
    setProtectedMode((prev) => !prev);
  };

  return (
    <CaseGroupCollectionItem
      title={meetingTitle}
      icon={<FontAwesomeIcon icon={faUsersClass} />}
      info={
        <>
          {session.meetings_link && (
            <div className="d-flex align-items-center">
              <FontAwesomeIcon icon={faLink} className="me-2" />
              <span
                className="text-decoration-underline d-inline-block"
                style={{
                  maxWidth: '150px',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {session.meetings_link}
              </span>
            </div>
          )}
          {session.meetings_pin_code && (
            <div className="d-flex align-items-center gap-1">
              <FontAwesomeIcon icon={faLock} className="me-2" />
              <span>
                {protectedMode ? protectedPinCode : session.meetings_pin_code}
              </span>
              <FontAwesomeIcon
                onClick={toggleProtectedMode}
                icon={protectedMode ? faEye : faEyeSlash}
              />
            </div>
          )}
        </>
      }
      actions={[]}
    />
  );
};

export default CaseGroupDetailsSessionsItem;
