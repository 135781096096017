import React from 'react';
import { HeaderValidConversationQuestion } from '../../../../../../../../../../../../../../interfaces/conversations';
import { useTypedContext } from '../../../../../../../../../../../../../../hooks/useTypedContext';
import { LanguageContext } from '../../../../../../../../types';
import { format } from 'date-fns';
import { AVAILABLE_CULTURES } from '../../../../../../../../../../../../../../components/CultureSelector/CultureSelector';

type HeaderQuestionItemProps = {
  item: HeaderValidConversationQuestion;
};

const HeaderQuestionItem = ({ item }: HeaderQuestionItemProps) => {
  const language = useTypedContext(LanguageContext);
  const currentHour = format(new Date(), 'HH:HH a');

  const title =
    language.culture === AVAILABLE_CULTURES.EN ? item.title : item.title_nb_no;

  const titleHtml =
    language.culture === AVAILABLE_CULTURES.EN
      ? item.title_html
      : item.title_html_nb_no;

  return (
    <div className="ConversationTemplateRangeQuestionItem">
      <div className="ConversationTemplateRangeQuestionItem__question d-flex flex-column">
        {titleHtml ? (
          <span
            className="ConversationTemplatePlainTextItem__content__text"
            dangerouslySetInnerHTML={{ __html: titleHtml }}
          />
        ) : (
          <span className="ConversationTemplatePlainTextItem__content__text">
            {title}
          </span>
        )}
        <span className="ConversationTemplatePlainTextItem__content__hour">
          {currentHour}
        </span>
      </div>
    </div>
  );
};

export default HeaderQuestionItem;
