import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getUsersDataDumpStatus } from '../../membership';
import { UserQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

function useUserDataDumpStatus({
  params,
  options,
}: IQueryRequest<UserQueryParams>) {
  const { userId } = params;
  return useQuery<any>({
    ...options,
    queryFn: async () => {
      const { data } = await getUsersDataDumpStatus(userId);
      return data.data;
    },
    queryKey: [QueryKeys.getUsersDataDumpStatus, userId],
  });
}

export default useUserDataDumpStatus;
