import { FormikErrors } from 'formik';
import { cloneDeep } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useTypedContext } from '../../../../../../../../../../../../../../../hooks/useTypedContext';
import {
  DashboardTemplateChartType,
  IChartDashboardTemplateVisualization,
} from '../../../../../../../../../../../../../../../interfaces/dashboards';
import { IFormHookValues } from '../../../../../../../../../../../../../../../interfaces/forms';
import { DashboardDataReducerContext } from '../../../../../../../../../types';
import { DashboardDataActions } from '../../../../../../../../DashboardDataContent/store/actions';
import { getSplitByObject, getSplitByValue } from '../helpers';
import { FormValues } from '../types';

export const useForm = (
  visualization: IChartDashboardTemplateVisualization,
  toggle: () => void
): IFormHookValues<FormValues> => {
  const intl = useIntl();

  const [dashboardData, dispatch] = useTypedContext(
    DashboardDataReducerContext
  );

  const initialValues = useMemo((): FormValues => {
    return {
      name: visualization.name,
      accumulative: visualization.series.at(0)?.accumulative ?? false,
      chart_type: visualization.chart_type,
      date_granularity: !visualization.date_granularity
        ? 'Auto'
        : visualization.date_granularity,
      series: visualization.series.map((series) => ({
        data_source: series.data_source,
        aggregate: series.aggregate,
        series_split_by: getSplitByValue(series.series_split_by),
        split_by: getSplitByValue(series.split_by),
      })),
    };
  }, [visualization]);

  const handleSubmit = useCallback(
    (values: typeof initialValues) => {
      let templateCopy = cloneDeep(dashboardData.template);
      let visualizationCopy = templateCopy.visualizations[
        visualization.sequence
      ] as IChartDashboardTemplateVisualization;

      visualizationCopy.name = values.name;
      visualizationCopy.chart_type = values.chart_type;
      visualizationCopy.date_granularity =
        !values.date_granularity || values.date_granularity === 'Auto'
          ? undefined
          : values.date_granularity;

      visualizationCopy.series = values.series.map((series) => {
        return {
          accumulative: values.accumulative,
          data_source: series.data_source,
          aggregate: series.aggregate,
          series_split_by: getSplitByObject(series.series_split_by),
          split_by: getSplitByObject(series.split_by),
        };
      });

      templateCopy.visualizations[visualization.sequence] = visualizationCopy;

      dispatch({
        type: DashboardDataActions.DashboardDataActionType.EditTemplate,
        payload: templateCopy,
      });
      toggle();
    },
    [dashboardData.template, dispatch, toggle, visualization.sequence]
  );

  const validation = useCallback(
    (values: typeof initialValues): FormikErrors<typeof initialValues> => {
      let errors: FormikErrors<typeof initialValues> = {};

      if (values.series.length === 0)
        errors.series = intl.formatMessage({ id: 'Dashboards.EmptySeries' });

      if (
        values.chart_type === DashboardTemplateChartType.PieChart &&
        values.series.length > 1
      )
        errors.series = intl.formatMessage({ id: 'Dashboards.TooMuchSeries' });

      values.series.forEach((series) => {
        if (!series.aggregate) {
          errors.series = intl.formatMessage({
            id: 'Dashboards.NotCompletedSeries',
          });
          return;
        }

        if (!series.data_source) {
          errors.series = intl.formatMessage({
            id: 'Dashboards.NotCompletedSeries',
          });
        }
      });

      return errors;
    },
    [intl]
  );

  return {
    initialValues,
    handleSubmit,
    validation,
  };
};
