import React, { useCallback, useMemo } from 'react';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import notepad from '../../../../assets/images/notepad.svg';
import Skeleton from 'react-loading-skeleton';
import CustomTable from '../../../../components/CustomTable';
import IndeterminateCheckbox from '../../../../components/IndeterminateCheckbox';
import { wrapWithSequenceIdentified } from '../../../../helpers/utils/data';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/pro-light-svg-icons';

const ProjectUsersListPlaceholder = () => {
  const headerItems = [
    <IndeterminateCheckbox
      key="project-users-list-placeholder-checkbox"
      checked={false}
      indeterminate={false}
    />,
    'Projects.usersList.name',
    'Projects.usersList.email',
    'Projects.usersList.phone',
    'Projects.usersList.date',
    'Projects.usersList.project',
    'Projects.usersList.score',
    'Projects.usersList.status',
    'Projects.usersList.closeSubmission',
    null,
  ];

  const singleRow = useCallback((key: string) => {
    return (
      <tr key={key} className="w-100">
        <td>
          <input type="checkbox" readOnly={true} checked={false} />
        </td>
        <td>
          <Skeleton width={200} />
        </td>
        <td>
          <Skeleton width={100} />
        </td>
        <td>
          <Skeleton width={100} />
        </td>
        <td>
          <Skeleton width={100} />
        </td>
        <td>
          <Skeleton width={100} />
        </td>
        <td className="ProjectUsersList__table__points">
          <Skeleton width={200} />
        </td>
        <td>
          <Skeleton width={200} />
        </td>
        <td>
          <Button
            color={'primary'}
            disabled={true}
            className="ProjectUsersList__table__close-submission"
          >
            <FormattedMessage id="Projects.usersList.closeSubmission" />
          </Button>
        </td>
        <td>
          <Dropdown
            direction="start"
            isOpen={false}
            className="ProjectUsersList__table__actions"
          >
            <DropdownToggle color="primary" outline={true}>
              <FontAwesomeIcon icon={faEllipsisH} />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem>
                <img src={notepad} alt="notepad" />
                <FormattedMessage id="Projects.usersList.seeFormAnswers" />
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </td>
      </tr>
    );
  }, []);

  const renderRows = useMemo(() => {
    return wrapWithSequenceIdentified([...Array(5)]).map((item) =>
      singleRow(item.sequence)
    );
  }, [singleRow]);

  return (
    <CustomTable
      isResponsive={true}
      bodyRows={renderRows}
      headerItems={headerItems}
    />
  );
};

export default ProjectUsersListPlaceholder;
