import useTimelineAnnotations from 'api/queries/timelines/useTimelineAnnotations';
import { isValid } from 'date-fns';
import useOpen from 'hooks/useOpen';
import { useEffect, useMemo, useState } from 'react';
import { Navigate, useParams } from 'react-router';
import TimelineDetailsAnnotationProxyModal from './TimelineDetailsAnnotationProxyModal';

export enum TimelineDetailsAnnotationErrors {
  AnnotationNotFound = 'AnnotationNotFound',
  InvalidDateParams = 'InvalidDateParams',
}

type TimelineDetailsAnnotationProxyPathParams = {
  timelineId?: string;
  year?: string;
  month?: string;
  day?: string;
  annotationId?: string;
};

const TimelineDetailsAnnotationProxy = () => {
  const { isOpen, toggle: toggleModal, open: openModal } = useOpen();

  const { timelineId, year, month, day, annotationId } =
    useParams<TimelineDetailsAnnotationProxyPathParams>();

  const [validationCompleted, setValidationCompleted] = useState(false);

  const selectedDate = useMemo(
    () => new Date(parseInt(year!), parseInt(month!) - 1, parseInt(day!)),
    [year, month, day]
  );

  const [annotationError, setAnnotationError] =
    useState<TimelineDetailsAnnotationErrors | null>(null);

  useEffect(() => {
    if (!isValid(selectedDate)) {
      setAnnotationError(TimelineDetailsAnnotationErrors.InvalidDateParams);
    }
  }, [selectedDate]);

  const { data, isFetched } = useTimelineAnnotations({
    params: { timelineId: timelineId! },
  });

  useEffect(() => {
    if (isFetched) {
      if (!data || data.length === 0) {
        setAnnotationError(TimelineDetailsAnnotationErrors.AnnotationNotFound);
        return;
      }

      const selectedAnnotation = data.find((p) => p.id === annotationId);

      if (!selectedAnnotation) {
        setAnnotationError(TimelineDetailsAnnotationErrors.AnnotationNotFound);
        return;
      }
      setValidationCompleted(true);
    }
  }, [data, isFetched, annotationId]);

  useEffect(() => {
    if (annotationError) {
      openModal();
    }
  }, [annotationError, openModal]);

  return (
    <>
      {annotationError ? (
        <TimelineDetailsAnnotationProxyModal
          isOpen={isOpen}
          toggleModal={toggleModal}
          errorType={annotationError}
          timelineId={timelineId!}
        />
      ) : (
        <>
          {validationCompleted && (
            <Navigate
              state={{
                annotation: {
                  annotationId: annotationId,
                },
              }}
              to={`/timelines/${timelineId}`}
            />
          )}
        </>
      )}
    </>
  );
};

export default TimelineDetailsAnnotationProxy;
