import Button from '@mui/material/Button';
import { Formik } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useOpen from '../../../../../../../../hooks/useOpen';
import { IHelpCategory } from '../../../../../../../../interfaces/help';
import HelpCentreConfirmation from '../../components/HelpCentreConfirmation';
import { handleClose } from '../../helpers';
import HelpCentreTabs from '../../tabs/HelpCentreTabs';
import { useForm } from './hooks/useForm';

type HelpCentreFormProps = {
  categories: IHelpCategory[];
};

const HelpCentreForm = ({ categories }: HelpCentreFormProps) => {
  const confirmation = useOpen();
  const formHandler = useForm(confirmation);

  return (
    <Formik
      initialValues={formHandler.initialValues}
      validationSchema={formHandler.validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={formHandler.handleSubmit}
    >
      {(form) => (
        <div className="d-flex flex-column gap-2 p-5 p-sm-4 h-100">
          {confirmation.isOpen ? (
            <HelpCentreConfirmation />
          ) : (
            <>
              <HelpCentreTabs categories={categories} form={form} />
              <div className="d-flex justify-content-end align-items-center gap-2">
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleClose}
                  sx={{ minWidth: '120px' }}
                >
                  <FormattedMessage id="General.Close" />
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ minWidth: '120px' }}
                  type="submit"
                  disabled={!form.isValid}
                  onClick={form.submitForm}
                >
                  <FormattedMessage id="General.Send" />
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </Formik>
  );
};

export default HelpCentreForm;
