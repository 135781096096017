import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Form, FormikProps } from 'formik';
import { Alert } from 'reactstrap';
import LoadingButton from 'components/LoadingButton';
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import { LoginHandleSubmitFunctionParams } from './LoginForm';
import { GraspColors, graspColorsValues } from '../../../../helpers/colors';

type LoginFormFormikProps = {
  props: FormikProps<LoginHandleSubmitFunctionParams>;
  loginErrorCode: string | null;
  handleReportIssue: () => void;
  isLoading: boolean;
  isSuccess: boolean;
  isFailure: boolean;
};

const LoginFormFormik = ({
  props,
  loginErrorCode,
  handleReportIssue,
  isFailure,
  isLoading,
  isSuccess,
}: LoginFormFormikProps) => {
  return (
    <Form className="LoginForm">
      <div className="d-flex flex-column gap-4 my-4">
        <div>
          <TextField
            variant="outlined"
            sx={{
              '& label.Mui-focused': {
                color: graspColorsValues[GraspColors.LightBlue600],
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: '#B2BAC2',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#E0E3E7',
                },
                '&:hover fieldset': {
                  borderColor: '#B2BAC2',
                },
                '&.Mui-focused fieldset': {
                  borderColor: graspColorsValues[GraspColors.LightBlue600],
                },
              },
            }}
            required={true}
            fullWidth
            id="username"
            name="username"
            label={<FormattedMessage id="General.UserName" />}
            value={props.values.username}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            error={props.touched.username && Boolean(props.errors.username)}
            helperText={props.touched.username && props.errors.username}
          />
        </div>
        <div>
          <TextField
            variant="outlined"
            sx={{
              '& label.Mui-focused': {
                color: '#AFD0D6',
              },
              '& .MuiInput-underline:after': {
                borderBottomColor: '#B2BAC2',
              },
              '& .MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: '#E0E3E7',
                },
                '&:hover fieldset': {
                  borderColor: '#B2BAC2',
                },
                '&.Mui-focused fieldset': {
                  borderColor: '#AFD0D6',
                },
              },
            }}
            color="primary"
            required={true}
            fullWidth
            id="password"
            name="password"
            type="password"
            label={<FormattedMessage id="General.Password" />}
            value={props.values.password}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            error={props.touched.password && Boolean(props.errors.password)}
            helperText={props.touched.password && props.errors.password}
          />
        </div>
      </div>
      {loginErrorCode && (
        <Alert
          className="newMembersSection__error-alert LoginErrorAlert"
          color="danger"
        >
          <div className="LoginErrorAlert__content">
            <div className="LoginErrorAlert__content__message">
              <FormattedMessage id="Login.LoginForm.errorCode.message" />
            </div>
            <div className="LoginErrorAlert__content__code">
              <FormattedMessage id="Login.LoginForm.errorCode.name" />
              <span className="ms-1">{loginErrorCode}</span>
            </div>
            <div className="LoginErrorAlert__content__report-issue">
              <FormattedMessage id="Login.LoginForm.errorCode.reportIssue.message" />
              <div className="alert-link ms-1" onClick={handleReportIssue}>
                <FormattedMessage id="Login.LoginForm.errorCode.reportIssue.clickHere" />
              </div>
            </div>
          </div>
        </Alert>
      )}
      <div className="LoginForm__send-wrapper d-flex justify-content-center">
        <LoadingButton
          isLoading={isLoading}
          isSuccess={isSuccess}
          isFailure={isFailure}
          value="Login.login"
          type="submit"
          className="LoginForm__send-wrapper__button btn btn-login"
        ></LoadingButton>
      </div>
      <div className="LoginForm__forgot-password">
        <Link to="/password-reset" className="newMembersSection__secondLink">
          <FormattedMessage id="Login.LoginForm.forgotPassword" />
        </Link>
      </div>
      <div className="LoginForm__dont-have-account">
        <div className="d-flex justify-content-center align-items-center">
          <span className="newMembersSection__secondLink__desc mt-0 me-1">
            <FormattedMessage id="Login.LoginForm.dontHAveAccount" />
          </span>
          <Link to="/register" className="newMembersSection__secondLink">
            <FormattedMessage id="Login.LoginForm.signUpHere" />
          </Link>
        </div>
      </div>
    </Form>
  );
};

export default LoginFormFormik;
