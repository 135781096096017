import useRequiredParams from '../../../../../../../../../../../hooks/useRequiredParams';

export const useCommonParams = () => {
  const { caseId, organizationId } = useRequiredParams<{
    organizationId: string;
    caseId: string;
  }>(['caseId', 'organizationId']);

  return { organizationId, caseId };
};
