import { differenceInHours, format } from 'date-fns';
import { isIsoDate } from '../../../../../../../../../../../../../../helpers/dates';
import { textWrapEllipse } from '../../../../../../../../../../../../../../helpers/utils/strings';
import { DateGranularity } from '../../../../../../../../../../../../../../interfaces/dashboards';
import { StrictDateRange } from '../../../../../../../../../../../../../../interfaces/dates';

const getFormatBasedOnDateRange = (dateRange: StrictDateRange) => {
  const hoursDiff = differenceInHours(dateRange.to, dateRange.from);
  if (hoursDiff < 24 * 3) return 'HH:mm';
  if (hoursDiff < 24 * 30 * 3) return 'dd.MM';
  return 'MMM yyyy';
};

const getFormatBasedOnGranularity = (dateGranularity: DateGranularity) => {
  let format: string = 'dd.MM.yyyy';

  switch (dateGranularity) {
    case DateGranularity.h1:
    case DateGranularity.h2:
    case DateGranularity.h4:
      format = 'HH:mm';
      break;
    case DateGranularity.d1:
      format = 'dd.MM';
      break;
    case DateGranularity.d7:
      format = 'wo';
      break;
    case DateGranularity.m1:
      format = 'MMM yyyy';
      break;
    case DateGranularity.y1:
      format = 'yyyy';
      break;
  }

  return format;
};

const appendWeekIfNeeded = (
  formattedStr: string,
  granularity?: DateGranularity
) => {
  if (granularity !== DateGranularity.d7) return formattedStr;
  return `Week ${formattedStr}`;
};

export const TickFormatter = (
  value: any,
  index: number,
  dateRange: StrictDateRange,
  dateGranularity?: DateGranularity
) => {
  if (isIsoDate(value as string))
    return appendWeekIfNeeded(
      format(
        new Date(value as string),
        dateGranularity
          ? getFormatBasedOnGranularity(dateGranularity)
          : getFormatBasedOnDateRange(dateRange)
      ),
      dateGranularity
    );

  return textWrapEllipse(value?.toString() ?? '', 15);
};
