import { useState } from 'react';
import squeezeTrendsIcon from 'assets/images/trends_timeline/squeeze_trends_icon.svg';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { TrendVisualizationChartType } from 'interfaces/trendVisualizations';
import caretIcon from 'assets/images/caret.svg';
import SVG from 'react-inlinesvg';
import useTimelineSqueezeDataFetch from '../../../../../api/queries/timelines/useTimelineSqueezeDataFetch';
import SqueezeQuantityChart from './components/SqueezeQuantityChart/SqueezeQuantityChart';
import SqueezeStrengthChart from './components/SqueezeStrengthChart/SqueezeStrengthChart';
import LoadingDataChartsPlaceholder from './components/ChartPlaceholders/LoadingDataChartPlaceholder';
import NoDataTrendsChartPlaceholder from './components/ChartPlaceholders/NoDataTrendsChartPlaceholder';
import {
  useTimelineDetailsDateRangeObject,
  useTimelineDetailsGranularity,
} from 'store/reducerHooks';

export enum TrendsSummarySqueezeChartType {
  SqueezeStrength = 'SqueezeStrength',
  SqueezeQuantity = 'SqueezeQuantity',
}

type TrendsSummarySqueezeChartProps = {
  squeezeType: TrendsSummarySqueezeChartType;
  chartType: TrendVisualizationChartType;
  chartTypeName: string | null;
  timelineId: string;
  removeChart: ((name: string) => void) | null;
};

const TrendsSummarySqueezeChart = ({
  squeezeType,
  chartType,
  chartTypeName,
  timelineId,
  removeChart,
}: TrendsSummarySqueezeChartProps) => {
  const [expanded, setExpanded] = useState(true);

  const toggleChart = () => {
    setExpanded((prev) => !prev);
  };

  const dateRangeObject = useTimelineDetailsDateRangeObject();
  const granularity = useTimelineDetailsGranularity();

  const { data, isLoading } = useTimelineSqueezeDataFetch({
    params: {
      timelineId: timelineId,
      granularity: granularity,
      dateRangeObject: dateRangeObject,
    },
  });

  return (
    <div className="cardContainer d-flex flex-column TimelineSqueezeSummary w-100">
      <div className="d-flex justify-content-between align-items-center mb-2 flex-wrap">
        <div className="d-flex cardContainer__header__title w-100">
          <div className="d-flex align-items-start TimelineSqueezeSummary__title">
            <img className="me-2" src={squeezeTrendsIcon} alt="header_icon" />
            <FormattedMessage
              id={
                squeezeType === TrendsSummarySqueezeChartType.SqueezeQuantity
                  ? 'TimelineDetails.TrendsTimeline.SqueezeQuantityChart.chartName'
                  : 'TimelineDetails.TrendsTimeline.SqueezeStrengthChart.chartName'
              }
            />
          </div>
          <div className="ms-auto TimelineSqueezeSummary__title__buttons">
            {removeChart && chartTypeName && (
              <Button
                color="primary"
                outline
                className="me-3"
                onClick={() => removeChart(chartTypeName)}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            )}
            <SVG
              onClick={toggleChart}
              src={caretIcon}
              transform={expanded ? 'rotate(180)' : ''}
              title="Expand chart"
            />
          </div>
        </div>
      </div>
      {expanded && (
        <div className="mt-3">
          {isLoading ? (
            <LoadingDataChartsPlaceholder />
          ) : (
            <>
              {data ? (
                <>
                  {squeezeType ===
                  TrendsSummarySqueezeChartType.SqueezeQuantity ? (
                    <SqueezeQuantityChart
                      squeezeData={data}
                      chartType={chartType}
                    />
                  ) : (
                    <SqueezeStrengthChart
                      squeezeData={data}
                      chartType={chartType}
                    />
                  )}
                </>
              ) : (
                <NoDataTrendsChartPlaceholder />
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default TrendsSummarySqueezeChart;
