import React, { useMemo } from 'react';
import { color } from 'd3-color';
import { IObservation } from 'interfaces/timeline';

//helpers
import { useIntl } from 'react-intl';

//components
import CustomBarChart from 'components/CustomBarChart';
import CustomPieChart from 'components/CustomPieChart';
import ChartPlaceholder from './ChartPlaceholder';

const DEFAULT_COLOR = '#8EB3D6';
const TRANSPARENCY_STEP = 0.3;

const generateArrayOfColor = (colorValue: string) => {
  const value = color(colorValue);

  const colors: string[] = [];
  for (let i = 0; i <= 2; i++) {
    const opacity = 1 - i * TRANSPARENCY_STEP;
    const newColor = value?.copy({ opacity });

    if (newColor) {
      colors.push(newColor.toString());
    }
  }

  return colors;
};

type ObservationSummaryProps = {
  data: IObservation[] | undefined;
  baseColor: string;
};

const ObservationSummary = ({
  data,
  baseColor = DEFAULT_COLOR,
}: ObservationSummaryProps) => {
  const intl = useIntl();

  const formattedData = useMemo(() => {
    if (data) {
      let total = 0;
      let low = 0;
      let medium = 0;
      let hard = 0;

      data.forEach(({ level_category }) => {
        total++;

        if (level_category === 'Medium') medium++;
        if (level_category === 'Hard') hard++;
        if (level_category === 'Low') low++;
      });

      return {
        total,
        items: [
          {
            name: intl.formatMessage({ id: 'TimelineDetails.dayDetails.hard' }),
            value: hard,
          },
          {
            name: intl.formatMessage({
              id: 'TimelineDetails.dayDetails.medium',
            }),
            value: medium,
          },
          {
            name: intl.formatMessage({ id: 'TimelineDetails.dayDetails.low' }),
            value: low,
          },
        ],
      };
    } else
      return {
        total: 0,
        items: [],
      };
  }, [data, intl]);

  const mappedColors = generateArrayOfColor(baseColor);

  return (
    <div className="ChartContainer">
      <div className={`ChartContainer__wrapper`}>
        {formattedData.total > 0 ? (
          <>
            <CustomPieChart
              {...formattedData}
              innerRadius={20}
              colors={mappedColors}
              width={40}
              height={40}
              marginForCircle={7}
              totalText="Observations"
            />
            <CustomBarChart
              {...formattedData}
              colors={mappedColors}
              width={250}
              height={50}
            />
          </>
        ) : (
          <ChartPlaceholder text="No Observations..." />
        )}
      </div>
    </div>
  );
};

export default ObservationSummary;
