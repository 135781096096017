import {
  IObservation,
  ObservationCategory,
  ObservationSymptom,
} from 'interfaces/timeline';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  YAxis,
} from 'recharts';
import {
  COLOR_DEFINITIONS,
  LEVEL_CATEGORY_COLORS,
  TimelineObservationProps,
} from './index';

type BarChartRecord = {
  name: string;
  Low?: number;
  Medium?: number;
  High?: number;
};

const CHART_HEIGHT = 500;

const MAXIMUM_AMPLITUDE = 255;

const CustomizedLabel = (props) => {
  const { x, y, fill, value, width } = props;
  return (
    <text
      x={x + width / 2}
      y={y}
      dy={-4}
      fill={fill}
      textAnchor="middle"
      className="recharts-text recharts-label"
      style={{
        fontSize: '14px',
      }}
    >
      <tspan>{Math.round(value)}%</tspan>
    </text>
  );
};

const categories = Object.values(ObservationCategory).filter(
  (cat) => cat !== ObservationCategory.GraspUpload
);

const ChartLegend = () => {
  return (
    <div className="d-flex flex-wrap w-100 justify-content-center">
      {categories.map((val) => {
        return (
          <div key={val} className="d-flex align-items-center ps-2 pe-2">
            <div
              style={{
                width: '14px',
                marginRight: '8px',
                border: `1px dashed ${LEVEL_CATEGORY_COLORS[val]}`,
              }}
            />
            <FormattedMessage id={`MomentarySession.${val}`} />
          </div>
        );
      })}
    </div>
  );
};

interface ChartLegendProps extends TimelineObservationProps {
  setSelectedObservations: (observation: IObservation[]) => void;
}

const CalibrationSummary = ({
  timelineObservations,
  setSelectedObservations,
}: ChartLegendProps) => {
  const filteredObservations = timelineObservations.filter(
    (obs) => obs.symptom === ObservationSymptom.Calibration
  );

  const formattedData = useMemo(() => {
    const data: BarChartRecord[] = [];

    categories.forEach((category) => {
      const observationsWithCategory = filteredObservations.filter(
        (obs) => obs.category === category
      );

      let maxValue = 0;

      observationsWithCategory.forEach((obsWithCategory) => {
        const localMaxValue = Math.max(...obsWithCategory.amplitudes);

        if (localMaxValue > maxValue) maxValue = localMaxValue;
      });

      data.push({
        name: category,
        [category]: (maxValue / MAXIMUM_AMPLITUDE) * 100,
      });
    });

    return data;
  }, [filteredObservations]);

  const renderReferenceLines = () => {
    return categories.map((category, index) => (
      <ReferenceLine
        key={category}
        y={formattedData[index][category]}
        stroke={LEVEL_CATEGORY_COLORS[category]}
        strokeDasharray="1"
      />
    ));
  };

  const renderBars = () => {
    return categories.map((category, index) => (
      <Bar
        key={category}
        dataKey={category}
        fill={LEVEL_CATEGORY_COLORS[category]}
        xAxisId={index}
        opacity={
          [
            ObservationCategory.CalibrationMax,
            ObservationCategory.CalibrationMin,
          ].includes(category)
            ? 1
            : 0.5
        }
        label={<CustomizedLabel />}
      />
    ));
  };

  const yAxisFormatter = (val) => `${val} %`;

  const selectObservation = (props) => {
    if (
      props &&
      (props.activeLabel !== undefined || props.activeLabel !== null)
    ) {
      const selectedActivePayload = props.activePayload[props.activeLabel];
      const selectedCategory = selectedActivePayload.dataKey;

      setSelectedObservations(
        filteredObservations.filter((el) => el.category === selectedCategory)
      );
    }
  };

  return (
    <div className="MomentarySessionDetailsContainer">
      <div className="w-100 d-flex mb-4">
        <h3 className="MomentarySessionDetails__title">
          <FormattedMessage id="MomentarySession.calibrationSummary" />
        </h3>
      </div>
      <ResponsiveContainer height={CHART_HEIGHT}>
        <BarChart data={formattedData} onMouseDown={selectObservation}>
          <CartesianGrid vertical={false} strokeDasharray="4" />
          <YAxis
            tickFormatter={yAxisFormatter}
            dataKey="value"
            allowDataOverflow={false}
            type="number"
            domain={[0, 100]}
            tickCount={11}
            interval="preserveStartEnd"
            tickLine={{ stroke: COLOR_DEFINITIONS.WHITE }}
            axisLine={{ stroke: COLOR_DEFINITIONS.WHITE }}
            padding={{
              top: 20,
            }}
          />
          <Tooltip cursor={{ fill: '#e0e0e0' }} content={() => null} />
          {renderBars()}
          {renderReferenceLines()}
          <Legend
            verticalAlign="bottom"
            height={36}
            iconType="line"
            wrapperStyle={{
              paddingTop: '10px',
            }}
            content={ChartLegend}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CalibrationSummary;
