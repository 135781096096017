import { useCallback } from 'react';
import {
  ICaseGroupConversationSchedule,
  ISingleInstanceCaseGroupConversationSchedule,
} from '../../../../../../../../../interfaces/conversationSchedules';
import {
  ScheduleItemDefinition,
  ScheduleItemTemplatePartsOfADayDefinition,
} from '../../../../../../../../../components/Schedule';
import { getRowToMergeWith as _getRowToMergeWith } from '../../../../../../../../../helpers/utils/conversationSchedules';
import { toDateOnly } from '../../../../../../../../../helpers/dates';

export const usePrepareSchedulesUtils = (
  setSchedule: (schedule: ICaseGroupConversationSchedule | null) => void,
  setResponsesId: (responsesId: string | null) => void,
  toggleEditModal: () => void,
  toggleResponses: () => void
) => {
  const getSingleInstancePartOfADay = useCallback(
    (
      currentSchedule: ICaseGroupConversationSchedule,
      recurringSchedule: ICaseGroupConversationSchedule,
      selectedDate?: Date
    ): ScheduleItemTemplatePartsOfADayDefinition => {
      const singleInstanceSchedule =
        currentSchedule as ISingleInstanceCaseGroupConversationSchedule;

      let onResponse: (() => void) | undefined = undefined;

      if (selectedDate && toDateOnly(selectedDate) < toDateOnly(new Date())) {
        onResponse = () => {
          setResponsesId(singleInstanceSchedule.id);
          toggleResponses();
        };
      }

      return {
        notification_hour: singleInstanceSchedule.notification_hour,
        available_from_hour: singleInstanceSchedule.available_from_hour,
        available_to_hour: singleInstanceSchedule.available_to_hour,
        completion_percent: singleInstanceSchedule.completion_percent,
        is_deleted: false,
        is_dirty: singleInstanceSchedule.is_dirty,
        onEdit: () => {
          setSchedule(recurringSchedule);
          toggleEditModal();
        },
        onResponses: onResponse,
      };
    },
    [setResponsesId, setSchedule, toggleEditModal, toggleResponses]
  );

  const getRowToMergeWith = useCallback(
    (
      items: ScheduleItemDefinition[],
      currentSchedule: ICaseGroupConversationSchedule,
      recurringSchedule: ICaseGroupConversationSchedule,
      selectedDate: Date
    ) => {
      return _getRowToMergeWith(
        items,
        currentSchedule,
        recurringSchedule,
        selectedDate,
        getSingleInstancePartOfADay
      );
    },
    [getSingleInstancePartOfADay]
  );

  return {
    getSingleInstancePartOfADay: getSingleInstancePartOfADay,
    getRowToMergeWith: getRowToMergeWith,
  };
};
