import { IMutationRequest } from '../../../interfaces/request';
import { OrganizationQueryParams } from '../../../interfaces/queryParams';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { linkEducationMaterialWithCategory } from '../../educationMaterials';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';

type LinkEducationMaterialWithCategoryParams = OrganizationQueryParams & {
  categoryId: string;
};

const useLinkEducationMaterialWithCategory = ({
  params,
  options,
}: IMutationRequest<LinkEducationMaterialWithCategoryParams>) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (materialId: string) => {
      await linkEducationMaterialWithCategory(
        params.organizationId,
        params.categoryId,
        materialId
      );
    },
    onSuccess: () => {
      displaySuccessNotification(
        'Api.Mutations.EducationMaterials.LinkEducationMaterialWithCategory.success'
      );
      options.successFb?.();
    },
    onError: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'Api.Mutations.EducationMaterials.LinkEducationMaterialWithCategory.failure'
      );
      options.errorFb?.();
    },
    resetOnResult: true,
  });
};

export default useLinkEducationMaterialWithCategory;
