import React, { useEffect, useState } from 'react';
import useAwareSessionsWithReferencePeriod from '../../../../../../../../../../../../api/queries/awareSessions/useAwareSessionsWithReferencePeriod';
import { AwareSessionFilterQueryParams } from '../../../../../../../../../../../../api/queries/awareSessions/useOrganizationAwareSessions';
import { useOrganizationPathParams } from '../../../../../../../../../../../../hooks/paths/useOrganizationPathParams';
import { useInitQuery } from '../../../../../../../../../../../../hooks/queries/useInitQuery';
import { useTypedContext } from '../../../../../../../../../../../../hooks/useTypedContext';
import { IAwareSessionData } from '../../../../../../../../../../../../interfaces/awareSessions';
import { DashboardTemplateDataSource } from '../../../../../../../../../../../../interfaces/dashboards';
import {
  IReferenced,
  ITotalRowsPage,
} from '../../../../../../../../../../../../interfaces/response';
import QueryLoaded from '../../../../../../../../../../../QueryLoaded';
import {
  DashboardDataReducerContext,
  DashboardQueriesContext,
} from '../../../../../../types';
import { DashboardLayoutFilterHelpers } from '../../../../helpers/filters';

type AwareSessionDataProviderProps = {
  children: (
    sessions: IReferenced<ITotalRowsPage<IAwareSessionData>>
  ) => JSX.Element;
};

const AwareSessionDataProvider = ({
  children,
}: AwareSessionDataProviderProps) => {
  const [dashboardData] = useTypedContext(DashboardDataReducerContext);

  if (
    dashboardData.data_source !==
      DashboardTemplateDataSource.AwareSessionData &&
    dashboardData.data_source !==
      DashboardTemplateDataSource.AggregatedAwareSessionData
  )
    return children({
      current: { data: [], total_rows_count: 0 },
      reference: { data: [], total_rows_count: 0 },
    });

  return <AwareSessionDataQueryProvider children={children} />;
};

const AwareSessionDataQueryProvider = ({
  children,
}: AwareSessionDataProviderProps) => {
  const { organizationId } = useOrganizationPathParams();

  const [dashboardData] = useTypedContext(DashboardDataReducerContext);
  const dashboardQueries = useTypedContext(DashboardQueriesContext);

  const [currentDataFetched, setCurrentDataFetched] = useState<IReferenced<
    ITotalRowsPage<IAwareSessionData>
  > | null>(null);

  const [currentFilterParams, setCurrentFilterParams] =
    useState<AwareSessionFilterQueryParams | null>(null);

  const awareSessionsQuery = useAwareSessionsWithReferencePeriod({
    params: {
      organizationId,
      aggregated:
        dashboardData.data_source ===
        DashboardTemplateDataSource.AggregatedAwareSessionData,
      dateRange: {
        from: dashboardData.template.date_selection.date_from,
        to: dashboardData.template.date_selection.date_to,
      },
      filters:
        currentFilterParams ??
        DashboardLayoutFilterHelpers.GetEmptyQueryFilters(),
    },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(awareSessionsQuery);

  useEffect(() => {
    if (!awareSessionsQuery.isFetched || !awareSessionsQuery.data) return;
    setCurrentDataFetched(awareSessionsQuery.data);
  }, [awareSessionsQuery.data, awareSessionsQuery.isFetched]);

  useEffect(() => {
    setCurrentFilterParams((prev) =>
      DashboardLayoutFilterHelpers.GenerateFilterParams(
        currentDataFetched?.current.data.length,
        prev,
        dashboardData.template.filters,
        dashboardQueries.questions
      )
    );
  }, [
    currentDataFetched,
    currentDataFetched?.current.data.length,
    dashboardData.template.filters,
    dashboardQueries.questions,
  ]);

  return (
    <QueryLoaded query={awareSessionsQuery}>
      {(data) => children(data)}
    </QueryLoaded>
  );
};

export default AwareSessionDataProvider;
