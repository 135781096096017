import useRegisterSubmissionUser from 'api/mutations/submissions/useRegisterSubmissionUser';
import { AVAILABLE_CULTURES } from 'components/CultureSelector/CultureSelector';
import IFrameFullPage from 'components/IFrameFullPage';
import LoadingButton from 'components/LoadingButton';
import { PasswordComplexityConstraints } from 'components/PasswordComplexity';
import { Form, Formik, FormikProps } from 'formik';
import {
  arePasswordsEqual,
  getPasswordConstraintsFulfilled,
} from 'helpers/utils/validators';
import useOpen from 'hooks/useOpen';
import useRequiredParams from 'hooks/useRequiredParams';
import { RegisterFormKeys } from 'interfaces/enums';
import RegisterFields from 'modules/membership/Register/components/RegisterFields';
import { displayErrorNotification } from 'modules/Notifications';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormGroup, Input } from 'reactstrap';

type RegisterInProjectFormProps = {
  organizationCode: string;
  selectedCulture: AVAILABLE_CULTURES;
  termsOfService?: string;
  privacyPolicy?: string;
  participationConsent?: string;
  hiddenFields: RegisterFormKeys[];
  mandatoryFields: RegisterFormKeys[];
  optionalFields: RegisterFormKeys[];
  onRegistrationSuccess: () => void;
  isAlertOnClose: boolean;
  submissionId: string;
};

const RegisterInProjectForm = ({
  selectedCulture,
  organizationCode,
  participationConsent,
  privacyPolicy,
  termsOfService,
  optionalFields,
  mandatoryFields,
  onRegistrationSuccess,
  isAlertOnClose,
  submissionId,
}: RegisterInProjectFormProps) => {
  const intl = useIntl();

  const initialValues = {
    first_name: '',
    last_name: '',
    address: '',
    birthdate: '',
    password: '',
    passwordRepeat: '',
    timezone_id: Intl.DateTimeFormat().resolvedOptions().timeZone,
    token: null,
    email: '',
    gender: '',
    ssn: '',
    phone_number: '',
    culture: '',
  };

  const { projectCode } = useRequiredParams<{
    projectCode: string;
  }>(['projectCode']);

  const acceptTerms = useOpen();
  const acceptConsent = useOpen();
  const acceptPrivacy = useOpen();

  const [passwordConstraints, setPasswordConstraints] = useState<
    PasswordComplexityConstraints[]
  >([]);

  const { mutate: registerSubmissionUser, isLoading } =
    useRegisterSubmissionUser(
      projectCode,
      organizationCode,
      submissionId,
      onRegistrationSuccess
    );

  const formikRef = useRef<FormikProps<typeof initialValues> | null>(null);

  const validate = (values: typeof initialValues) => {
    const errors = {};
    for (const key of mandatoryFields) {
      if (!values[key] || values[key].trim().length === 0) {
        errors[key] = intl.formatMessage({
          id: 'CaseCard.create.errors.inputEmpty',
        });
      }
    }

    const constraints = getPasswordConstraintsFulfilled(values.password);

    if (constraints !== passwordConstraints) {
      setPasswordConstraints(constraints);
    }

    return errors;
  };

  const handleSubmit = (values: typeof initialValues) => {
    const isPasswordNeeded =
      mandatoryFields.includes(RegisterFormKeys.PASSWORD) ||
      (optionalFields.includes(RegisterFormKeys.PASSWORD) &&
        values.password !== '' &&
        values.passwordRepeat !== '');

    if (
      !isPasswordNeeded ||
      (isPasswordNeeded &&
        arePasswordsEqual(values.password, values.passwordRepeat))
    ) {
      values.phone_number =
        values.phone_number.length < 5 ? '' : values.phone_number;

      if (selectedCulture !== AVAILABLE_CULTURES.EN) {
        values.culture = selectedCulture;
        registerSubmissionUser(values);
        return;
      }

      values.culture = Object.entries(AVAILABLE_CULTURES).find(
        ([key]) => key.toLowerCase() === intl.locale
      )![1];

      registerSubmissionUser(values);
      return;
    }

    displayErrorNotification(
      'Register.notifications.errors.different_passwords'
    );
  };

  useEffect(() => {
    const alertUser = (ev) => {
      const confirmationMessage = '';
      ev.returnValue = confirmationMessage;

      return confirmationMessage;
    };

    if (isAlertOnClose && typeof window !== 'undefined') {
      window.addEventListener('beforeunload', alertUser);
    }

    return () => {
      if (isAlertOnClose && typeof window !== 'undefined') {
        window.removeEventListener('beforeunload', alertUser);
      }
    };
  }, [isAlertOnClose]);

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setValues({
        ...formikRef.current.values,
        phone_number: selectedCulture === AVAILABLE_CULTURES.NO ? '+47' : '+1',
      });
    }
  }, [selectedCulture]);

  const {
    isOpen: isPrivacyPolicyOpen,
    close: closePrivacyPolicy,
    open: openPrivacyPolicy,
  } = useOpen(false);
  const {
    isOpen: isTermsOfServiceOpen,
    close: closeTermsOfService,
    open: openTermsOfService,
  } = useOpen(false);
  const {
    isOpen: isParticipantConsentOpen,
    close: closeParticipantConsent,
    open: openParticipantConsent,
  } = useOpen(false);

  return (
    <>
      {privacyPolicy && (
        <IFrameFullPage
          src={privacyPolicy}
          toggleIFrame={closePrivacyPolicy}
          isOpen={isPrivacyPolicyOpen}
        />
      )}
      {termsOfService && (
        <IFrameFullPage
          src={termsOfService}
          toggleIFrame={closeTermsOfService}
          isOpen={isTermsOfServiceOpen}
        />
      )}
      {participationConsent && (
        <IFrameFullPage
          src={participationConsent}
          toggleIFrame={closeParticipantConsent}
          isOpen={isParticipantConsentOpen}
        />
      )}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validate={validate}
        innerRef={formikRef}
      >
        {(form) => (
          <Form className="registerForm">
            <RegisterFields
              formik={form}
              passwordComplexityConstraints={passwordConstraints}
              mandatoryFields={mandatoryFields}
              optionalFields={optionalFields}
            />
            {participationConsent && (
              <FormGroup check className="mb-2 mt-4">
                <Input
                  id="acceptConsent"
                  type="checkbox"
                  name="acceptConsent"
                  required
                  checked={acceptConsent.isOpen}
                  onClick={acceptConsent.toggle}
                />
                <label
                  className="newMembersSection__secondLink__desc"
                  htmlFor="acceptConsent"
                >
                  <FormattedMessage
                    id="Register.readAndAgree"
                    values={{
                      subject: (
                        <span
                          onClick={openParticipantConsent}
                          className="newMembersSection__secondLink ms-1"
                        >
                          {intl.formatMessage({
                            id: 'Project.ProjectParticipationConsent',
                          })}
                        </span>
                      ),
                    }}
                  />
                </label>
              </FormGroup>
            )}
            {privacyPolicy && (
              <FormGroup check className="mb-2">
                <Input
                  id="acceptPrivacy"
                  type="checkbox"
                  name="acceptPrivacy"
                  required
                  checked={acceptPrivacy.isOpen}
                  onClick={acceptPrivacy.toggle}
                />
                <label
                  className="newMembersSection__secondLink__desc"
                  htmlFor="acceptPrivacy"
                >
                  <FormattedMessage
                    id="Register.readAndAgree"
                    values={{
                      subject: (
                        <span
                          onClick={openPrivacyPolicy}
                          className="newMembersSection__secondLink ms-1"
                        >
                          {intl.formatMessage({
                            id: 'General.PrivacyPolicy',
                          })}
                        </span>
                      ),
                    }}
                  />
                </label>
              </FormGroup>
            )}
            {termsOfService && (
              <FormGroup check className="mb-2">
                <Input
                  id="acceptTerms"
                  type="checkbox"
                  name="acceptTerms"
                  required
                  checked={acceptTerms.isOpen}
                  onClick={acceptTerms.toggle}
                />
                <label
                  className="newMembersSection__secondLink__desc"
                  htmlFor="acceptTerms"
                >
                  <FormattedMessage
                    id="Register.readAndAgree"
                    values={{
                      subject: (
                        <span
                          onClick={openTermsOfService}
                          className="newMembersSection__secondLink ms-1"
                        >
                          {intl.formatMessage({
                            id: 'General.TermsOfService',
                          })}
                        </span>
                      ),
                    }}
                  />
                </label>
              </FormGroup>
            )}
            <div className="newMembersSection__register mt-4 d-flex justify-content-center">
              <LoadingButton
                outline
                disabled={
                  !form.isValid ||
                  !acceptTerms ||
                  (!!participationConsent && !acceptConsent)
                }
                value="Projects.Register.registerAccount"
                type="submit"
                className="btn btn-login m-0"
                isLoading={isLoading}
              />
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default RegisterInProjectForm;
