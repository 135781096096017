import React from 'react';

const HourByHourChartLabel = (props: any) => {
  const { x, y, value } = props;

  if (value === 0) return <></>;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={3} y={0} dy={-5} fontSize={10} textAnchor="center" fill="#000">
        {value}
      </text>
    </g>
  );
};

export default HourByHourChartLabel;
