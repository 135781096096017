import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import HourglassBottomOutlinedIcon from '@mui/icons-material/HourglassBottomOutlined';
import PhotoLibraryOutlinedIcon from '@mui/icons-material/PhotoLibraryOutlined';
import React from 'react';
import IconButton from '../../../../../../components/IconButton';
import { textWrapEllipse } from '../../../../../../helpers/utils/strings';
import useOpen from '../../../../../../hooks/useOpen';
import { IEducationMaterial } from '../../../../../../interfaces/educationMaterials';
import AssetsModal from '../../../../modals/AssetsModal';
import MaterialModal from '../../../../modals/MaterialModal';
import EducationMaterialsItemRule from './components/EducationMaterialsItemRule';
import EducationMaterialsItemTranslation from './components/EducationMaterialsItemTranslation';
import { useEducationMaterialItem } from './hooks/useEducationMaterialItem';
import { useMaterialTranslations } from './hooks/useMaterialTranslations';
import { useRemoveEducationMaterial } from './hooks/useRemoveEducationMaterial';

type EducationMaterialsItemProps = {
  material: IEducationMaterial;
};

const EducationMaterialsItem = ({ material }: EducationMaterialsItemProps) => {
  const { getCategoryName } = useEducationMaterialItem();

  const { isOpen: editMaterialOpen, toggle: toggleEditMaterial } = useOpen();
  const { isOpen: editAssetsOpen, toggle: toggleEditAssets } = useOpen();
  const { isDeleting, handleDelete } = useRemoveEducationMaterial(material.id);

  const materialTranslations = useMaterialTranslations(
    material.name_translations,
    material.description_translations
  );

  return (
    <>
      {editMaterialOpen && (
        <MaterialModal
          isOpen={editMaterialOpen}
          toggle={toggleEditMaterial}
          material={material}
        />
      )}
      {editAssetsOpen && (
        <AssetsModal
          isOpen={editAssetsOpen}
          toggle={toggleEditAssets}
          material={material}
        />
      )}
      <tr
        className="MaterialsTabContent__item MaterialsTabContentItem"
        key={material.id}
      >
        <td>{textWrapEllipse(material.name, 30)}</td>
        <td>{textWrapEllipse(material.description, 50)}</td>
        <td>{getCategoryName(material.case_category_id)}</td>
        <td className="MaterialsTabContentItem__translations">
          <div className="d-flex align-items-center gap-2">
            {materialTranslations.map((translation) => (
              <EducationMaterialsItemTranslation
                key={translation.culture}
                translation={translation}
                sequence={material.id}
              />
            ))}
          </div>
        </td>
        <td>
          {material.rules.map((rule) => (
            <EducationMaterialsItemRule
              key={`${rule.case_category_id}|${rule.case_sub_category_id}|${rule.availability_status}`}
              rule={rule}
            />
          ))}
        </td>
        <td>
          <div className="d-flex align-items-center gap-2">
            <IconButton
              color="primary"
              variant="outlined"
              onClick={toggleEditAssets}
            >
              <PhotoLibraryOutlinedIcon />
            </IconButton>
            <IconButton
              color="primary"
              variant="outlined"
              onClick={toggleEditMaterial}
            >
              <CreateOutlinedIcon />
            </IconButton>
            <IconButton
              color="primary"
              variant="outlined"
              onClick={handleDelete}
            >
              {isDeleting ? (
                <HourglassBottomOutlinedIcon />
              ) : (
                <DeleteOutlineOutlinedIcon />
              )}
            </IconButton>
          </div>
        </td>
      </tr>
    </>
  );
};

export default EducationMaterialsItem;
