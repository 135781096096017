import { getOrganizationContextAssessmentFormSummary } from 'api/graspManagement';
import QueryKeys from 'api/queries/queryKeys';
import { CaseQueryParams, TimelineQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { useQuery } from 'react-query';

type UseOrganizationContextAssessmentFormSummaryParams = CaseQueryParams &
  TimelineQueryParams;

const useOrganizationContextAssessmentFormSummary = ({
  params,
  options,
}: IQueryRequest<UseOrganizationContextAssessmentFormSummaryParams>) => {
  const { organizationId, caseId, timelineId } = params;

  return useQuery({
    ...options,
    queryKey: [
      QueryKeys.getOrganizationContextAssessmentFormSummary,
      organizationId,
      caseId,
      timelineId,
    ],
    queryFn: async () => {
      const { data: responseData } =
        await getOrganizationContextAssessmentFormSummary(
          organizationId,
          caseId,
          timelineId
        );

      return responseData.data;
    },
  });
};

export default useOrganizationContextAssessmentFormSummary;
