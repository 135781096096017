import { useMemo } from 'react';
import { useTypedContext } from '../../../../../../../../../../../../../hooks/useTypedContext';
import { DashboardTemplateDataSource } from '../../../../../../../../../../../../../interfaces/dashboards';
import { DashboardDataReducerContext } from '../../../../../../../types';
import { VisualizationDataContext } from '../../../types';

export const useDataLimitSource = () => {
  const [dashboardData] = useTypedContext(DashboardDataReducerContext);
  const visualizationData = useTypedContext(VisualizationDataContext);

  return useMemo(() => {
    if (
      dashboardData.data_source === DashboardTemplateDataSource.DashboardReports
    )
      return visualizationData.reports.current;

    if (
      dashboardData.data_source ===
        DashboardTemplateDataSource.AwareSessionData ||
      dashboardData.data_source ===
        DashboardTemplateDataSource.AggregatedAwareSessionData
    )
      return visualizationData.sessions.current;

    return visualizationData.items.current;
  }, [
    dashboardData.data_source,
    visualizationData.items,
    visualizationData.reports,
    visualizationData.sessions,
  ]);
};
