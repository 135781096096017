import {
  addTimelineToAcceptedRequest,
  removeTimelineFromAcceptedRequest,
} from 'api/graspManagement';
import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';

type ShareTimelineParamsType = {
  requestId: string;
  timelineId: string;
  shouldAccept: boolean;
};

function useTimelineModification(
  userId: string,
  onSuccess: () => void,
  onError: () => void
) {
  return useMutationWithResultNotification<ShareTimelineParamsType, void>({
    asyncApiFunction: async ({ shouldAccept, timelineId, requestId }) => {
      if (shouldAccept)
        await addTimelineToAcceptedRequest(userId, requestId, [timelineId]);
      else
        await removeTimelineFromAcceptedRequest(userId, requestId, timelineId);
    },
    onSuccess,
    onError,
    resetOnResult: true,
  });
}

export default useTimelineModification;
