import { useMemo } from 'react';
import { ISelectableDashboardTemplateVisualization } from '../../../../../../../../../../../../../../../interfaces/dashboards';

export const useInitialSelectedIndex = (
  listViewData: any[],
  visualization: ISelectableDashboardTemplateVisualization
) => {
  return useMemo(() => {
    if (visualization.selected_first) return 0;
    if (visualization.selected_last) return listViewData.length - 1;
    if (visualization.selected_index) return visualization.selected_index;

    return undefined;
  }, [
    listViewData.length,
    visualization.selected_first,
    visualization.selected_index,
    visualization.selected_last,
  ]);
};
