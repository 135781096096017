import React from 'react';
import EventPoint from '../EventPoint';
import {
  AwareCaseDetailsFormValues,
  ProcedureActivity,
} from '../../../../forms/AwareCaseDetailsForm/types';
import { FormikProps } from 'formik';

type EventPointGroupProps = {
  startDate: string;
  group: number[];
  events: ProcedureActivity[];
  eventsPositions: number[];
  form: FormikProps<AwareCaseDetailsFormValues>;
};

const EventPointGroup = ({
  startDate,
  eventsPositions,
  group,
  events,
  form,
}: EventPointGroupProps) => {
  return (
    <>
      {group.map((sequence, idx) => (
        <EventPoint
          key={sequence}
          startDate={startDate}
          sequence={sequence}
          procedureEvent={events[sequence]}
          eventsPositions={eventsPositions}
          level={group.length - idx}
          form={form}
        />
      ))}
    </>
  );
};

export default EventPointGroup;
