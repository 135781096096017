import Box from '@mui/material/Box';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import React from 'react';

type ScheduleLayoutProps<TIcon extends typeof SvgIcon> = {
  title: string;
  icon?: TIcon;
  actions?: JSX.Element[];
  schedule: JSX.Element;
};

const ScheduleLayout = <TIcon extends typeof SvgIcon>({
  title,
  icon,
  actions = [],
  schedule,
}: ScheduleLayoutProps<TIcon>) => {
  return (
    <Box className="d-flex flex-column gap-3">
      <Box className="d-flex align-items-center justify-content-between">
        <Box>
          <>
            {!!icon && icon}
            <Typography variant="h6">{title}</Typography>
          </>
        </Box>
        {actions && (
          <Box className="d-flex align-items-center gap-2">{actions}</Box>
        )}
      </Box>
      <Box>{schedule}</Box>
    </Box>
  );
};

export default ScheduleLayout;
