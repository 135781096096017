import React from 'react';
import useRequiredParams from '../../../../hooks/useRequiredParams';
import PageHeader from '../../../../components/PageHeader';
import { FormattedMessage } from 'react-intl';
import { useTreatmentPlansCommonData } from '../../hooks/useTreatmentPlansCommonData';
import { useInitQuery } from '../../../../hooks/queries/useInitQuery';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CaseGroupBreadcrumbAction } from '../../../CaseGroups/components/CaseGroupsBreadcrumbs';

type TreatmentPlansPageHeaderProps = {
  actions?: CaseGroupBreadcrumbAction[];
};

const TreatmentPlansPageHeader = ({
  actions,
}: TreatmentPlansPageHeaderProps) => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const { organization } = useTreatmentPlansCommonData();

  useInitQuery(organization);

  return (
    <PageHeader
      breadcrumbs={[
        {
          text: <FormattedMessage id="Header.myOrganizations" />,
          link: null,
          key: 'my-organizations',
        },
        {
          text: organization.data?.name ?? '',
          link: `/my-organizations/${organizationId}`,
          key: 'name',
        },
        {
          text: <FormattedMessage id="Header.treatmentPlans" />,
          link: null,
          key: 'treatment-plans',
        },
      ]}
      content={
        <div className="d-flex justify-content-between mt-5">
          <span className="OrganizationPageHeader__text">
            <FormattedMessage id="TreatmentPlans.TreatmentPlansBreadcrumbs.treatmentPlans" />
          </span>
          <div className="d-flex align-items-center gap-2">
            {actions?.map((action) => (
              <Button
                key={action.translationId}
                color="primary"
                outline={true}
                onClick={action.onAction}
              >
                <FontAwesomeIcon icon={action.icon} />
                {action.translationId && (
                  <FormattedMessage id={action.translationId} />
                )}
              </Button>
            ))}
          </div>
        </div>
      }
    />
  );
};

export default TreatmentPlansPageHeader;
