import useNewAccessToken from 'api/mutations/membership/useNewAccessToken';
import useExternalAuthentication from 'api/queries/membership/useExternalAuthentication';
import useUrlSearch from 'hooks/useUrlSearch';
import React, { useEffect, useMemo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LoginForm, { discoveryService } from './components/LoginForm';
import SVG from 'react-inlinesvg';
import { ExternalProvider, returnIconBasedOnExternalProvider } from '.';

import workMarkBlue from '../../../assets/images/work_marks/work_mark_blue.svg';
import backArrow from 'assets/images/back_arrow.svg';

import { LoginMethod } from 'interfaces/membership';
import { useNavigate } from 'react-router';
import MembershipLayout from 'components/layouts/MembershipLayout';

const B2CLogin = () => {
  const navigate = useNavigate();

  const intl = useIntl();

  const query = useUrlSearch();
  const authCode = query.get('auth_code');
  const loginErrorCode = query.get('error_code');

  const { mutate: newTokenMutation, isError: isErrorNewToken } =
    useNewAccessToken({
      loginAs: LoginMethod.AsPatient,
    });

  const { data: externalAuthenticationSchemes } = useExternalAuthentication({
    enabled: true,
  });

  const loginAs: LoginMethod = LoginMethod.AsPatient;

  const formRef = useRef<HTMLFormElement | null>(null);

  const form = (
    <form
      style={{
        display: 'hidden',
      }}
      ref={formRef}
      method="post"
    ></form>
  );

  const onExternalLoginClick = async (name: string) => {
    const redirectionPath = `${window.location.origin}/login/b2b`;

    await discoveryService(() => {
      if (formRef && formRef.current) {
        formRef.current.action = `${localStorage.getItem(
          'api_url'
        )}/api/users/external_login/${name}?returnUrl=${encodeURIComponent(
          redirectionPath
        )}`;
        formRef.current.submit();
      }
    });
  };

  const b2cSchemes = useMemo(() => {
    if (externalAuthenticationSchemes === undefined) return [];
    return externalAuthenticationSchemes?.filter((p) => !p.is_b2_b);
  }, [externalAuthenticationSchemes]);

  const renderAuthSchemes = b2cSchemes.map((scheme) => {
    const handleExternalLogin = async () => {
      await onExternalLoginClick(scheme.name);
    };

    return (
      <button
        key={scheme.display_name}
        color="primary"
        className="PatientsLogin__wrapper__form__providers__provider"
        onClick={handleExternalLogin}
      >
        {returnIconBasedOnExternalProvider(ExternalProvider[scheme.name])}
        <span>{scheme.display_name}</span>
      </button>
    );
  });

  const handleGoToLogin = () => {
    navigate('/login');
  };

  useEffect(() => {
    if (authCode) {
      newTokenMutation({
        token: authCode,
      });
    }
  }, [authCode, newTokenMutation]);

  if (authCode && !isErrorNewToken) return null;

  return (
    <MembershipLayout>
      <div className="PatientsLogin">
        <div className="PatientsLogin__top-bar">
          <div
            className="PatientsLogin__top-bar__back"
            onClick={handleGoToLogin}
          >
            <SVG src={backArrow} />
            <FormattedMessage id="Login.back" />
          </div>
          <div className="PatientsLogin__top-bar__logo">
            <div className="PatientsLogin__top-bar__logo__logo-wrapper">
              <div className="PatientsLogin__wrapper__form__header PatientsLogin__wrapper__form__header--landscape ms-2">
                <span className="PatientsLogin__wrapper__form__header__log-in-as">
                  <FormattedMessage id="Login.loginAs" />
                </span>
                <span className="PatientsLogin__wrapper__form__header__log-in-as PatientsLogin__wrapper__form__header__log-in-as--bold">
                  <FormattedMessage id="Login.asAPatient" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="PatientsLogin__wrapper">
          <div className="PatientsLogin__wrapper__form">
            <div className="PatientsLogin__wrapper__form__icon">
              <SVG
                src={workMarkBlue}
                title={intl.formatMessage({
                  id: 'MyOrganizations.Logo',
                })}
              />
            </div>
            <div className="MedicalPersonnelLogin__wrapper__form__header">
              <span className="MedicalPersonnelLogin__wrapper__form__header__log-in-as">
                <FormattedMessage id="Login.loginAs" />
              </span>
              <span className="MedicalPersonnelLogin__wrapper__form__header__log-in-as MedicalPersonnelLogin__wrapper__form__header__log-in-as--caption">
                <FormattedMessage id="Login.asPatient" />
              </span>
            </div>
            {b2cSchemes.length > 0 && (
              <>
                <span className="PatientsLogin__wrapper__form__subheader">
                  <FormattedMessage id="Login.useProviders" />
                </span>
                <div className="PatientsLogin__wrapper__form__providers">
                  {renderAuthSchemes}
                  {form}
                </div>
              </>
            )}
            <span className="PatientsLogin__wrapper__form__use-organization-email">
              <div className="PatientsLogin__wrapper__form__use-organization-email__dash"></div>
              <FormattedMessage
                id={
                  b2cSchemes.length > 0
                    ? 'Login.usePersonalEmail'
                    : 'Login.usePersonalEmailWithoutProviders'
                }
              />
              <div className="PatientsLogin__wrapper__form__use-organization-email__dash"></div>
            </span>
            <div className="PatientsLogin__wrapper__form__login">
              <LoginForm loginErrorCode={loginErrorCode} loginAs={loginAs} />
            </div>
          </div>
        </div>
      </div>
    </MembershipLayout>
  );
};

export default B2CLogin;
