import { useCallback } from 'react';
import { OpenHookValues } from '../../../../../../../../../../../hooks/useOpen';
import { ContentIndexHookValues } from '../../../hooks/useContentIndex';

export const useHandleAddSchedule = (
  contentIndex: ContentIndexHookValues,
  addNotificationModal: OpenHookValues
) => {
  return useCallback(() => {
    contentIndex.clearIndex();
    addNotificationModal.toggle();
  }, [addNotificationModal, contentIndex]);
};
