import { IEditableDivProps } from 'interfaces/ui';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Input } from 'reactstrap';

const InputWithDisabledDivDisplay = ({
  value,
  type,
  options,
  disabled,
  name,
  onEdit,
  inputCssClass,
  divCssClass = inputCssClass || '',
  ...inputProps
}: IEditableDivProps) => {
  const [inputValue, setInputValue] = useState('');

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    onEdit(name, event.target.value);
    setInputValue(event.target.value);
  };

  const sharedProps = {
    id: name,
    onChange,
    value: inputValue,
    className: inputCssClass || '',
    name,
    type,
  };

  //when something is no longer edited
  useEffect(() => {
    if (disabled) setInputValue(value);
  }, [value, disabled]);

  //when new value provided
  useEffect(() => {
    setInputValue(value);
  }, [value]);

  if (disabled) {
    let valueToDisplay: string = !!value ? value : '-';
    if (type === 'select') {
      const optionWithValue = options?.find((option) => option.value === value);
      if (optionWithValue) valueToDisplay = optionWithValue.text;
    }

    return <div className={divCssClass}>{valueToDisplay}</div>;
  } else {
    if (type === 'select')
      return (
        <Input {...inputProps} {...sharedProps}>
          {options?.map(({ text, value }) => (
            <option key={value} value={value}>
              {text}
            </option>
          ))}
        </Input>
      );

    return <Input {...inputProps} {...sharedProps} />;
  }
};

export default InputWithDisabledDivDisplay;
