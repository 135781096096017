import React, { useMemo, useState } from 'react';
import { TrendVisualizationTemplate } from 'interfaces/trendVisualizations';
import SavedViewsItem from './components/SavedViewsItem';
import { FormattedMessage } from 'react-intl';
import useMobile from 'hooks/useBreakpoint';
import SkeletonPlaceholderOnLoad from 'components/SkeletonPlaceholderOnLoad';
import SavedViewsItemPlaceholder from './components/SavedViewsItem/components/SavedViewsItemPlaceholder';
import { useInitQuery } from 'hooks/queries/useInitQuery';
import { useCommonData } from 'modules/Organizations/views/OrganizationDetailsView/hooks/useCommonData';

type TrendVisualizationsSavedViewsListProps = {
  handleEditChart: (template: TrendVisualizationTemplate) => void;
};

const SavedViewsList = ({
  handleEditChart,
}: TrendVisualizationsSavedViewsListProps) => {
  const isMobile = useMobile();
  const rowElements = useMemo(() => (isMobile ? 2 : 3), [isMobile]);

  const { trendVisualizationTemplates } = useCommonData();
  useInitQuery(trendVisualizationTemplates);

  const [displayedElements, setDisplayedElements] = useState<number>(
    rowElements * 2
  );

  const handleSeeMore = () => {
    setDisplayedElements((prev) => prev + rowElements);
  };

  const templates = useMemo(() => {
    return trendVisualizationTemplates.data ?? [];
  }, [trendVisualizationTemplates.data]);

  return (
    <div className="TrendVisualizationsSavedViewsList">
      <div className="TrendVisualizationsSavedViewsList__list">
        <SkeletonPlaceholderOnLoad
          isLoading={trendVisualizationTemplates.isLoading}
          placeholder={<SavedViewsItemPlaceholder />}
        >
          <>
            {templates.slice(0, displayedElements).map((template) => (
              <SavedViewsItem
                key={template.id}
                handleEditChart={handleEditChart}
                template={template}
              />
            ))}
          </>
        </SkeletonPlaceholderOnLoad>
      </div>
      {displayedElements < templates.length && (
        <div
          className="TrendVisualizationsSavedViewsList__see-more"
          onClick={handleSeeMore}
        >
          <FormattedMessage id="MyOrganizations.trendVisualizations.seeMore" />
        </div>
      )}
      <hr className="TrendVisualizationsSavedViewsList__separator" />
    </div>
  );
};

export default SavedViewsList;
