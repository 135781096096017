import React from 'react';
import { FormattedMessage } from 'react-intl';
import SVG from 'react-inlinesvg';
import checkedIcon from 'assets/images/assessment_forms_indicators/completed_indicator.svg';
import uncheckedIcon from 'assets/images/assessment_forms_indicators/upcoming_indicator.svg';

export enum PasswordComplexityConstraints {
  SixCharacters = 'SixCharacters',
  OneNonAlphanumeric = 'OneNonAlphanumeric',
  OneDigit = 'OneDigit',
  OneUppercase = 'OneUppercase',
}

type PasswordComplexityProps = {
  constraints: PasswordComplexityConstraints[];
};

const PasswordComplexity = ({ constraints }: PasswordComplexityProps) => {
  return (
    <div className="mt-2 PasswordHelperList">
      <p className="mb-1">
        <FormattedMessage id="Register.Password.header" />
        {': '}
      </p>
      <ul className="PasswordHelperList__constraints">
        <li>
          {constraints.includes(PasswordComplexityConstraints.SixCharacters) ? (
            <SVG src={checkedIcon} title="passed" />
          ) : (
            <SVG src={uncheckedIcon} title="failed" />
          )}
          <FormattedMessage id="Register.Password.sixCharacters" />
        </li>
        <li>
          {constraints.includes(
            PasswordComplexityConstraints.OneNonAlphanumeric
          ) ? (
            <SVG src={checkedIcon} title="passed" />
          ) : (
            <SVG src={uncheckedIcon} title="failed" />
          )}
          <FormattedMessage id="Register.Password.oneNonAlphanumeric" />
        </li>
        <li>
          {constraints.includes(PasswordComplexityConstraints.OneDigit) ? (
            <SVG src={checkedIcon} title="passed" />
          ) : (
            <SVG src={uncheckedIcon} title="failed" />
          )}
          <FormattedMessage id="Register.Password.oneDigit" />
        </li>
        <li>
          {constraints.includes(PasswordComplexityConstraints.OneUppercase) ? (
            <SVG src={checkedIcon} title="passed" />
          ) : (
            <SVG src={uncheckedIcon} title="failed" />
          )}
          <FormattedMessage id="Register.Password.oneUppercase" />
        </li>
      </ul>
    </div>
  );
};

export default PasswordComplexity;
