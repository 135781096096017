import React from 'react';
import ItemContainer from '../ItemContainer';
import { FormikProps } from 'formik';
import { OpenHookValues } from '../../../../../../../../hooks/useOpen';
import PatientNumberField from './components/PatientNumberField';
import { AwareCaseDetailsFormValues } from '../../forms/AwareCaseDetailsForm/types';
import DynamicField from './components/DynamicField';
import MedicalPersonnelField from './components/MedicalPersonnelField';
import useMobile from '../../../../../../../../hooks/useBreakpoint';
import classNames from 'classnames';
import { useEnableEdit } from '../../forms/AwareCaseDetailsForm/hooks/useEnableEdit';

export type DynamicFieldsProps = {
  editMode: OpenHookValues;
  form: FormikProps<AwareCaseDetailsFormValues>;
};

const DynamicFields = ({ form, editMode }: DynamicFieldsProps) => {
  const isMobile = useMobile(830);
  const isXs = useMobile(576);

  useEnableEdit(editMode, form);

  return (
    <ItemContainer>
      <div
        className={classNames(
          'flex justify-between items-center flex-wrap gap-2',
          {
            'flex-col': isMobile,
          }
        )}
      >
        <div
          className={classNames('flex items-center gap-3', {
            'flex-wrap mb-2': isXs,
          })}
        >
          <PatientNumberField form={form} editMode={editMode} />
          {form.values.questions.map((question, index) => (
            <DynamicField
              key={question.id}
              question={question}
              editMode={editMode}
              form={form}
              sequence={index}
            />
          ))}
        </div>
        <MedicalPersonnelField />
      </div>
    </ItemContainer>
  );
};

export default DynamicFields;
