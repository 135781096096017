import { useCallback } from 'react';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';

export const useIconDefinitionCheck = () => {
  return useCallback(
    (icon: IconDefinition | string): icon is IconDefinition => {
      return (icon as IconDefinition).iconName !== undefined;
    },
    []
  );
};
