import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import Dropzone from 'react-dropzone';
import SVG from 'react-inlinesvg';
import graspsFile from 'assets/images/add_grasps_modal/file.svg';
import useExcelFileUpload from 'api/mutations/grasps/useExcelFileUpload';
import { displayErrorNotification } from 'modules/Notifications';
import useRequiredParams from 'hooks/useRequiredParams';

type UploadGraspsModalProps = {
  isOpen: boolean;
  toggleModal: () => void;
};

const UploadGraspsModal = ({ isOpen, toggleModal }: UploadGraspsModalProps) => {
  const intl = useIntl();

  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const { mutate, isLoading, isSuccess } = useExcelFileUpload(organizationId);

  const onDrop = (acceptedFiles: File[]) => {
    if (acceptedFiles.length === 0) {
      displayErrorNotification(
        intl.formatMessage({
          id: 'MyOrganizations.grasps.uploadGraspsModal.incorrectFormat',
        }),
        {
          autoClose: 3000,
        }
      );
      return;
    }
    mutate(acceptedFiles[0]);
  };

  const dropzoneSuccessId = isSuccess
    ? 'MyOrganizations.grasps.uploadGraspsModal.dropzoneUploaded'
    : 'MyOrganizations.grasps.uploadGraspsModal.dropzoneText';

  const uploadedStatusId = isLoading
    ? 'MyOrganizations.grasps.uploadGraspsModal.dropzoneLoading'
    : dropzoneSuccessId;

  return (
    <Modal
      size="lg"
      className="UploadGraspsModal"
      isOpen={isOpen}
      toggle={toggleModal}
    >
      <ModalHeader className="UploadGraspsModal__header">
        <FormattedMessage id="MyOrganizations.grasps.uploadGraspsModal.header" />
      </ModalHeader>
      <ModalBody className="UploadGraspsModal__body">
        <Dropzone
          multiple={false}
          onDrop={onDrop}
          accept={{
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
              ['.xls', '.xlsx'],
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <div
              className="UploadGraspsModal__body__drop-zone"
              {...getRootProps()}
            >
              <input
                className="UploadGraspsModal__body__drop-zone__input"
                {...getInputProps()}
              />
              <div className="UploadGraspsModal__body__drop-zone__content">
                <SVG src={graspsFile} />
                <span className="UploadGraspsModal__body__drop-zone__content__text">
                  <FormattedMessage id={uploadedStatusId} />
                </span>
              </div>
            </div>
          )}
        </Dropzone>
      </ModalBody>
      <ModalFooter className="UploadGraspsModal__footer">
        <Button color="primary" outline onClick={toggleModal}>
          <FormattedMessage id="General.close" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UploadGraspsModal;
