import useTimelines from 'api/queries/timelines/useTimelines';
import { getUserId } from 'helpers/utils/auth';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

//images
import formIcon from 'assets/images/notepad.svg';
import { ListGroup } from 'reactstrap';
import { useNavigate } from 'react-router';
import MedicalRecord from 'components/MedicalRecord';
import { CaseStatus } from '../../../interfaces/cases';
import { IUserTimeline } from '../../../interfaces/timeline';

export const sortByLastObservationDate = (
  a: IUserTimeline,
  b: IUserTimeline
) => {
  const left = a.last_observation_at
    ? Date.parse(a.last_observation_at)
    : Date.parse('');
  const right = b.last_observation_at
    ? Date.parse(b.last_observation_at)
    : Date.parse('');

  return right - left;
};

const MedicalRecords = () => {
  const userId = getUserId();
  const navigate = useNavigate();

  const { data } = useTimelines({ params: { userId } });

  const sortByCaseStatus = (a: IUserTimeline, b: IUserTimeline) => {
    return (
      (b.case_status ? Object.keys(CaseStatus).indexOf(b.case_status) : -1) -
      (a.case_status ? Object.keys(CaseStatus).indexOf(a.case_status) : -1)
    );
  };

  const dataSortedByLastObservationDate = useMemo(() => {
    if (!data) return [];
    return data.sort(sortByLastObservationDate);
  }, [data]);

  const dataSorted = useMemo(() => {
    const sortedByCaseStatus = [...dataSortedByLastObservationDate].sort(
      sortByCaseStatus
    );

    const masterMedicalRecord = dataSortedByLastObservationDate.find(
      (p) => p.is_master
    );

    if (masterMedicalRecord) {
      return [
        masterMedicalRecord,
        ...sortedByCaseStatus.filter((p) => p.id !== masterMedicalRecord.id),
      ];
    }

    return sortedByCaseStatus;
  }, [dataSortedByLastObservationDate]);

  const renderMedicalRecords = useMemo(() => {
    return dataSorted.map((timeline) => {
      const redirect = () => navigate(`/timelines/${timeline.id}`);

      return (
        <MedicalRecord
          key={timeline.id}
          description={timeline.description}
          id={timeline.id}
          members={
            timeline.case_owner_personality
              ? [timeline.case_owner_personality]
              : []
          }
          title={timeline.name}
          onClick={redirect}
          status={timeline.case_status}
          type={timeline.type}
          lastUpdate={timeline.last_observation_at}
        />
      );
    });
  }, [dataSorted, navigate]);

  return (
    <div className="card-container mt-4 PatientCasesList">
      <div className="d-flex justify-content-between w-100">
        <div className="d-flex align-items-center card-title">
          <img src={formIcon} alt="form_icon" />
          <FormattedMessage id="Summary.myMedicalRecords" />
        </div>
      </div>
      <div className="PatientCasesList__table--dashboard">
        <ListGroup>{renderMedicalRecords}</ListGroup>
      </div>
    </div>
  );
};

export default MedicalRecords;
