import useInfiniteOrganizationPatientList from 'api/queries/patients/useInfinitePatientList';
import { DEFAULT_PATIENT_PAGE_SIZE } from 'helpers/constants';
import { generateParticipantUrl } from 'helpers/routeDefinitions';
import useRequiredParams from 'hooks/useRequiredParams';
import { IPersonality } from 'interfaces/personalities';
import { useNavigate } from 'react-router';
import AccessRequestModal from '../../../../components/AccessRequestModal';
import PatientTableRenderer from './components/PatientTableRenderer';

type PatientListProps = {
  searchValue?: string;
  isRequestAccessOpen: boolean;
  toggleRequestAccess: () => void;
};

const ParticipantsList = ({
  searchValue,
  isRequestAccessOpen,
  toggleRequestAccess,
}: PatientListProps) => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const navigate = useNavigate();

  const { patients, fetchNextPage, isLoading, hasNextPage, refetch } =
    useInfiniteOrganizationPatientList({
      params: {
        organizationId,
        searchValue,
        pageSize: DEFAULT_PATIENT_PAGE_SIZE,
      },
    });

  const onRowClick = (personality: IPersonality) => {
    navigate(
      generateParticipantUrl({ organizationId, personalityId: personality.id })
    );
  };

  return (
    <>
      <AccessRequestModal
        isOpen={isRequestAccessOpen}
        toggle={toggleRequestAccess}
        organizationId={organizationId}
        refetchPatientList={refetch}
        translationIdTitle="PatientAccess.searchForNewPatient"
      />
      <PatientTableRenderer
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isLoading={isLoading}
        onRowClick={onRowClick}
        patients={patients}
      />
    </>
  );
};

export default ParticipantsList;
