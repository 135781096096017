import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { format } from 'date-fns';
import { GraspColors, graspColorsValues } from 'helpers/colors';
import React from 'react';

type DayDetailsSwitchProps = {
  date: Date;
  handlePrev: () => void;
  handleNext: () => void;
};

const DayDetailsSwitch = ({
  date,
  handlePrev,
  handleNext,
}: DayDetailsSwitchProps) => {
  return (
    <div className="CaseDashboardHeaderDate d-flex align-items-center justify-content-center gap-3 my-2">
      <FontAwesomeIcon
        icon={faChevronLeft}
        onClick={handlePrev}
        color={graspColorsValues[GraspColors.BLUE]}
        size="2x"
      />
      <div className="CaseDashboardHeaderDate__content CaseDashboardHeaderDateContent">
        <span className="CaseDashboardHeaderDateContent__date">
          {format(date, 'dd.MM.yyyy')}
        </span>
      </div>
      <FontAwesomeIcon
        icon={faChevronRight}
        onClick={handleNext}
        color={graspColorsValues[GraspColors.BLUE]}
        size="2x"
      />
    </div>
  );
};

export default DayDetailsSwitch;
