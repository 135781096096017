import { GroupSelectionIdentifiers } from 'hooks/useRowSelection';
import { IPersonality } from './personalities';

export interface IGrasp {
  id: string;
  name?: string;
  status: string;
  physical_grasp_uuid: string;
  organization_id?: string;
  organization_name?: string;
  organization_logo_url?: string;
  hex_color?: string;
  battery_current_level: number;
  battery_warning_level: number;
  up_time: number;
  firmware_version?: string;
  number_tag?: string;
  flash_date?: Date;
  serial_number?: string;
  battery_last_synchronization?: Date;
  data_last_synchronization?: Date;
  data_too_long_without_synchronization: boolean;
  firmware_update_allowed: boolean;
  data_upload_allowed: boolean;
  setup_id?: number;
  last_observation_sequence?: number;
}

export interface IGraspPossession {
  grasp_id: string;
  user_id: string;
  grasp_name: string;
  date_from: string;
  date_to: string | null;
  default_timeline_id: string;
  default_timeline_name: string;
  grasp_uuid: string;
  grasp_hex_color: string;
  grasp_firmware_version: string;
  grasp_battery_current_level: number;
  grasp_battery_warning_level: number;
  grasp_data_too_long_without_synchronization: boolean;
  grasp_data_last_synchronization: string | null;
  grasp_organization_id: string | null;
  grasp_organization_name: string | null;
}

export interface IActiveGrasp
  extends Omit<
    IGraspPossession,
    'default_timeline_id' | 'default_timeline_name'
  > {}

export interface IBaseGraspOrganization {
  id: string;
  grasp_id: string;
  name: string;
  physical_grasp_uuid: string;
  hex_color: string;
}

export type OrganizationMember = {
  email: string;
  first_name: string;
  id: string;
  last_name: string;
};

export type OrganizationPatient = {
  email: string;
  first_name: string;
  id: string;
  last_name: string;
  prescription_date: string | null;
};
export interface IInfiniteGraspOrganization extends IBaseGraspOrganization {
  location_name: string | null;
  location_id: string | null;
  patient: OrganizationPatient | null;
  organization_member: OrganizationMember | null;
  battery_last_synchronization: Date | null;
  battery_level: number;
  battery_warning_level: number;
  status: GraspStatus;
  serial_number: string | null;
  created_at: string | null;
}
export interface IGraspOrganization extends IBaseGraspOrganization {
  status: string;
  organization_id: string;
  organization_name: string;
  battery_level: number;
  uptime: number;
  firmware_version: string;
  created_at: string;
  updated_at: string;
  created_by: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
  };
  serial_number: string;
}
export interface IGraspFound {
  id: string;
  name: string;
  physical_grasp_uuid: string;
  hex_color: string;
}

export enum GraspInfoMode {
  USER = 'user',
  DOCTOR = 'doctor',
}

export interface ILinkGraspBody {
  grasp_uuid: string;
  grasp_name: string;
  date_from: string;
  default_timeline_id?: string;
}
export interface IGraspSharing {
  grasp_id: string;
  grasp_name: string;
  grasp_uuid: string;
  date_from: string;
  date_to: string;
  personality: IPersonality;
}

export type FindGraspFormType = {
  link_code: string;
};

export type GraspFeedbackBody = {
  subject: string;
  description: string;
  email_contact: string;
  phone_number_contact: string;
  metadata: GraspFeedbackMetadata[];
  category: 'Bug' | 'Improvement' | 'HelpRequest';
  importance: 'Low' | 'Medium' | 'High' | 'Critical';
};

export type GraspFeedbackMetadata = {
  item: string;
  value: string | undefined;
};

export type GraspCreateBody = {
  physical_grasp_uuid: string;
  hex_color: string;
  serial_number: string;
};

export enum GraspModalActions {
  FIND = 'find',
  SHARE = 'share',
  UNSHARE = 'unshare',
}

export enum GraspStatus {
  SCRAPPED = 'scrapped',
  UNKNOWN = 'unknown',
  READY_FOR_SALE = 'readyForSale',
  FLASHED = 'flashed',
  ELECTRONICS_ONLY = 'electronicsOnly',
  ENCASED = 'encased',
  IN_PRODUCTION = 'inProduction',
}

export const mappedStatus = {
  [GraspStatus.SCRAPPED]: -1,
  [GraspStatus.UNKNOWN]: 0,
  [GraspStatus.READY_FOR_SALE]: 1,
  [GraspStatus.FLASHED]: 2,
  [GraspStatus.ELECTRONICS_ONLY]: 3,
  [GraspStatus.ENCASED]: 4,
  [GraspStatus.IN_PRODUCTION]: 5,
};

export type GraspFilters = {
  fromDate?: string;
  toDate?: string;
  statuses?: GraspStatus[];
  endpoint?: string;
};

export type GraspSelection = {
  blind_search?: string;
  statuses?: GraspStatus[];
  endpoint_id?: string;
  date_from?: string;
  date_to?: string;
  all_selected: boolean;
  selected: string[];
  excluded: string[];
};

export type SelectionModalProps = {
  search?: string;
  filters: GraspFilters;
  included: GroupSelectionIdentifiers[];
  excluded: GroupSelectionIdentifiers[];
  isAllSelected: boolean;
  organizationId: string;
};

export interface IOrganizationGraspSettings {
  id: string;
  organization_id: string;
  led_levels: IGraspLedLevel[];
  squeeze_end_signalling: boolean;
  connection_signalling: boolean;
  charging_signalling: boolean;
  strength_feedback_duration: number;
  full_battery_percentage: number;
  low_battery_percentage: number;
}

export interface IGraspLedLevel {
  id: string;
  organization_id: string;
  color: string;
  amplitude_percentage: number;
}

export interface ICreateGraspLedLevel {
  organization_id: string;
  color: string;
  amplitude_percentage: number;
}
