import { createContext } from 'react';
import { StepsHookValues } from '../../../../../../../hooks/useSteps';
import { HandleUploadHookValues } from '../components/Content/hooks/useHandleUpload';
import { ConversationTemplate } from '../../../../../../../interfaces/conversations';

export enum Steps {
  UploadFile = 'UploadFile',
  Conflicts = 'Conflicts',
}

export type OnCompleteCallback = (template: ConversationTemplate) => void;

export const StepsContext = createContext<StepsHookValues<Steps> | undefined>(
  undefined
);
export const HandleUploadContext = createContext<
  HandleUploadHookValues | undefined
>(undefined);

export const OnCompleteCallbackContext = createContext<
  OnCompleteCallback | undefined
>(undefined);
