import useCultures from '../cultures/useCultures';
import { useCallback } from 'react';
import { ClosedListValidConversationQuestionAnswer } from '../../interfaces/conversations';

export const useAnswerTitle = () => {
  const { currentCulture, defaultCulture } = useCultures();

  return useCallback(
    (answer: ClosedListValidConversationQuestionAnswer): string => {
      const translatedTitle =
        answer.title_nb_no && answer.title_nb_no.length > 0
          ? answer.title_nb_no
          : undefined;

      return currentCulture.cultureCode === defaultCulture.cultureCode
        ? answer.title
        : translatedTitle ?? answer.title;
    },
    [currentCulture.cultureCode, defaultCulture.cultureCode]
  );
};
