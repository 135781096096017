import { DashboardDatePeriod } from '../../../../../../../../../interfaces/dashboards';
import { DashboardDatesHelpers } from '../../../helpers/dates';
import { DashboardDataActions } from './actions';
import { DashboardDataStates } from './state';

export namespace DashboardDataActionResolvers {
  export const EditTemplateResolver = (
    state: DashboardDataStates.DashboardDataState,
    action: DashboardDataActions.EditTemplateAction,
    registerEntry: (
      path: string,
      template: DashboardDataStates.DashboardDataState
    ) => void
  ) => {
    const newState = { ...state, template: action.payload };
    registerEntry(newState.path, newState);
    return newState;
  };

  export const EditDatePeriodResolver = (
    state: DashboardDataStates.DashboardDataState,
    action: DashboardDataActions.EditDatePeriodAction,
    registerEntry: (
      path: string,
      template: DashboardDataStates.DashboardDataState
    ) => void
  ) => {
    const dateRange = DashboardDatesHelpers.GetDateRangeFromPeriod(
      action.payload
    );

    const newState = {
      ...state,
      template: {
        ...state.template,
        date_selection: {
          ...state.template.date_selection,
          date_period: action.payload,
          date_from: dateRange.from,
          date_to: dateRange.to,
        },
      },
    };

    registerEntry(newState.path, newState);

    return newState;
  };

  export const EditDateRangeResolver = (
    state: DashboardDataStates.DashboardDataState,
    action: DashboardDataActions.EditDateRangeAction,
    registerEntry: (
      path: string,
      template: DashboardDataStates.DashboardDataState
    ) => void
  ) => {
    const newState = {
      ...state,
      template: {
        ...state.template,
        date_selection: {
          ...state.template.date_selection,
          date_period: DashboardDatePeriod.CustomPeriod,
          date_from: action.payload.from,
          date_to: action.payload.to,
        },
      },
    };

    registerEntry(newState.path, newState);
    return newState;
  };
}
