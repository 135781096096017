import SyncAltIcon from '@mui/icons-material/SyncAlt';
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import GraspLogo from '../../../../../../../../../components/GraspLogo';
import { useOrganizationPathParams } from '../../../../../../../../../hooks/paths/useOrganizationPathParams';
import { IGrasp } from '../../../../../../../../../interfaces/grasps';
import { Size } from '../../../../../../../../../interfaces/ui';
import { useHandleTransferGrasp } from './useHandleTransferGrasp';

export const useColumns = (refetch: () => void) => {
  const { organizationId } = useOrganizationPathParams();
  const intl = useIntl();

  const handleTransfer = useHandleTransferGrasp(refetch);

  const handleTransferGrasp = useCallback(
    (row: IGrasp) => async () => {
      await handleTransfer(row.organization_id, row.id);
    },
    [handleTransfer]
  );

  return useMemo((): GridColDef<IGrasp>[] => {
    return [
      {
        field: 'hex_color',
        headerName: '',
        renderCell: (params: GridRenderCellParams<any, string>) => {
          return (
            <GraspLogo size={Size.SMALL} color={params.value} width={45} />
          );
        },
        width: 75,
      },
      {
        field: 'name',
        headerName: intl.formatMessage({ id: 'Organizations.DeviceName' }),
        flex: 1,
        editable: false,
      },
      {
        field: 'organization_name',
        headerName: intl.formatMessage({ id: 'Grasps.AssignedTo' }),
        renderCell: (params: GridRenderCellParams<any, string | null>) => {
          return params.value ? params.value : '--';
        },
        flex: 1,
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: '',
        cellClassName: 'actions',
        getActions: ({ id, row }) => {
          if (row.organization_id === organizationId) return [];

          return [
            <GridActionsCellItem
              key={`transfer-${id}`}
              icon={<SyncAltIcon />}
              label="Save"
              sx={{
                color: 'primary.main',
              }}
              onClick={handleTransferGrasp(row)}
            />,
          ];
        },
      },
    ];
  }, [handleTransferGrasp, intl, organizationId]);
};
