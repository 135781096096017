import { updatePersonality } from 'api/membership';
import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import { IPatchObject } from 'interfaces/request';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';

type PatchPersonalityParams = {
  data: IPatchObject[];
  personalityId: string;
};

function usePatchPersonality(successFb?: () => void) {
  return useMutationWithResultNotification({
    asyncApiFunction: async ({
      data,
      personalityId,
    }: PatchPersonalityParams) => {
      await updatePersonality(personalityId, data);
    },
    resetOnResult: true,
    onSuccess: () => {
      successFb && successFb();
      displaySuccessNotification('General.accountUpdated');
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'Register.notifications.errors'
      );
    },
  });
}

export default usePatchPersonality;
