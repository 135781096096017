import { ConversationQuestion } from 'interfaces/conversations';
import { DashboardTemplate } from 'interfaces/dashboards';
import { IOrganizationMembers } from 'interfaces/organizations';
import React from 'react';
import { IGrasp } from '../../../../../../interfaces/grasps';
import { useDashboardTemplateEntry } from '../../store/hooks';
import DashboardDataContent from './components/DashboardDataContent';
import { useInitDashboardData } from './hooks/useInitDashboardData';

export type DashboardContentProps = {
  template: DashboardTemplate;
  grasps: IGrasp[];
  questions: ConversationQuestion[];
  organizationsMembers: IOrganizationMembers[];
};

const DashboardContent = ({
  template,
  grasps,
  questions,
  organizationsMembers,
}: DashboardContentProps) => {
  const templateEntry = useDashboardTemplateEntry(template.path);

  const handleInit = useInitDashboardData(
    template,
    questions,
    grasps,
    organizationsMembers.flatMap((p) => p.members),
    organizationsMembers.map((p) => p.organization)
  );

  const initialDashboardDataState = templateEntry
    ? templateEntry.template
    : handleInit();

  return (
    <DashboardDataContent
      key={JSON.stringify(initialDashboardDataState)}
      initialDashboardData={initialDashboardDataState}
      organizationsMembers={organizationsMembers}
      questions={questions}
      grasps={grasps}
    />
  );
};

export default DashboardContent;
