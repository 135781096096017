import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEyeSlash } from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage } from 'react-intl';
import { useTranslations } from '../../../../../../../../hooks/useTranslations';

const NotAvailablePreview = () => {
  const { attachPrefix } = useTranslations(
    'Education.AssetModal.AssetPreview.NotAvailablePreview'
  );

  return (
    <div className="NotAvailablePreview">
      <FontAwesomeIcon size="2x" icon={faEyeSlash} />
      <FormattedMessage id={attachPrefix('previewNotAvailable')} />
    </div>
  );
};

export default NotAvailablePreview;
