import { getTimelines } from 'api/graspManagement';
import QueryKeys from 'api/queries/queryKeys';
import { UserQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { IUserTimeline } from 'interfaces/timeline';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';

function returnElement(timeline: IUserTimeline, intl) {
  if (timeline.is_master) {
    return {
      ...timeline,
      name: intl.formatMessage({
        id: 'General.masterTimeline',
      }),
      description: intl.formatMessage({
        id: 'General.masterTimelineDescription',
      }),
    };
  } else return timeline;
}

function useTimelines({ params, options }: IQueryRequest<UserQueryParams>) {
  const intl = useIntl();
  const { userId } = params;

  return useQuery({
    ...options,
    queryKey: [QueryKeys.getUserTimelines, userId],
    queryFn: async () => {
      const { data } = await getTimelines(userId);
      let timelines = data.data;

      const sortedArray: IUserTimeline[] = [];
      const sharedTimelines = timelines.filter((el) => el.is_shared);
      const masterTimelines = timelines.filter(
        (el) => !el.is_shared && el.is_master
      );
      const otherTimelines = timelines.filter(
        (el) => !el.is_shared && !el.is_master
      );

      sharedTimelines?.forEach((element) => {
        if (element.is_shared) sortedArray.push(returnElement(element, intl));
      });

      masterTimelines?.forEach((element) => {
        sortedArray.push({
          ...element,
          name: intl.formatMessage({
            id: 'General.masterTimeline',
          }),
          description: intl.formatMessage({
            id: 'General.masterTimelineDescription',
          }),
        });
      });

      otherTimelines?.forEach((element) => {
        if (!element.is_master) sortedArray.push(element);
      });

      return sortedArray;
    },
  });
}

export default useTimelines;
