import { IAsyncFormHookValues } from '../../../../../../../interfaces/forms';
import { useCallback, useMemo } from 'react';
import { FormikErrors } from 'formik';
import useRequiredParams from '../../../../../../../hooks/useRequiredParams';
import { useCommonData } from '../../../../../hooks/useCommonData';
import { InputOption } from '../../../../../../../interfaces/ui';
import { useInitQuery } from '../../../../../../../hooks/queries/useInitQuery';
import { useTranslations } from './useTranslations';
import { IBaseEducationMaterialCategory } from '../../../../../../../interfaces/educationMaterials';
import useLinkEducationMaterialWithCategory from '../../../../../../../api/mutations/educationMaterials/useLinkEducationMaterialWithCategory';

type LinkEducationWithCategoryModalFormValues = {
  category_id: string;
  material_id: string;
};

type LinkEducationWithCategoryModalFormHookValues =
  IAsyncFormHookValues<LinkEducationWithCategoryModalFormValues> & {
    submitState: {
      isError: boolean;
      isLoading: boolean;
      isSuccess: boolean;
    };
    options: {
      categories: InputOption[];
      materials: InputOption[];
    };
  };

export const useLinkEducationWithCategoryModal = (
  category: IBaseEducationMaterialCategory,
  toggle: () => void
): LinkEducationWithCategoryModalFormHookValues => {
  const translations = useTranslations();

  const {
    educationMaterials,
    educationMaterialCategoriesTree,
    educationMaterialCategories,
  } = useCommonData();

  useInitQuery(educationMaterialCategories);

  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const handleSuccess = async () => {
    toggle();
    await educationMaterialCategories.refetch();
    await educationMaterialCategoriesTree.refetch();
  };

  const { mutateAsync, isError, isLoading, isSuccess } =
    useLinkEducationMaterialWithCategory({
      params: { organizationId, categoryId: category.id },
      options: { successFb: handleSuccess },
    });

  const initialValues =
    useMemo((): LinkEducationWithCategoryModalFormValues => {
      return {
        category_id: category.id,
        material_id: '',
      };
    }, [category]);

  const validation = useCallback(
    (values: LinkEducationWithCategoryModalFormValues) => {
      let errors: FormikErrors<LinkEducationWithCategoryModalFormValues> = {};

      if (values.category_id.length === 0)
        errors.category_id = translations.invalidCategory;
      if (values.material_id.length === 0)
        errors.material_id = translations.invalidMaterial;

      return errors;
    },
    [translations.invalidCategory, translations.invalidMaterial]
  );

  const handleSubmit = useCallback(
    async (values: LinkEducationWithCategoryModalFormValues) => {
      await mutateAsync(values.material_id);
    },
    [mutateAsync]
  );

  const categoriesOptions = useMemo((): InputOption[] => {
    let categories = educationMaterialCategories.data ?? [];

    categories = categories.filter((p) => p.id === category.id);

    return categories.map((category) => ({
      value: category.id,
      text: category.name,
    }));
  }, [category.id, educationMaterialCategories.data]);

  const materialsOptions = useMemo((): InputOption[] => {
    let materials = educationMaterials.data ?? [];

    return materials.map((material) => ({
      value: material.id,
      text: material.name,
    }));
  }, [educationMaterials.data]);

  return {
    initialValues: initialValues,
    validation: validation,
    handleSubmit: handleSubmit,
    submitState: {
      isError,
      isLoading,
      isSuccess,
    },
    options: {
      categories: categoriesOptions,
      materials: materialsOptions,
    },
  };
};
