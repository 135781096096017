import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';

const stepsTranslationIds = [
  'PatientCheck.steps.transferringData',
  'PatientCheck.steps.userForms',
  'PatientCheck.steps.additionalInformation',
];

type StatusStepIndicatorProps = {
  currentStep: number;
};

const StatusStepIndicator = ({ currentStep }: StatusStepIndicatorProps) => {
  const renderedSteps = stepsTranslationIds.map((translationId, step) => (
    <div
      key={translationId}
      className={classNames('StatusCheck__indicator__step', {
        'StatusCheck__indicator__step--active': step === currentStep,
      })}
    >
      <FormattedMessage id={translationId} />
      <div
        className={classNames('StatusCheck__indicator__step__visualization', {
          'StatusCheck__indicator__step--active__visualization':
            step === currentStep,
        })}
      />
    </div>
  ));

  return <div className="StatusCheck__indicator">{renderedSteps}</div>;
};

export default StatusStepIndicator;
