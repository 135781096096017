import { ThemeOptions } from '@mui/material';
import { GraspColors, graspColorsValues } from 'helpers/colors';

export namespace ThemesOptions {
  export const baseThemeOptions: ThemeOptions = {
    palette: { primary: { main: graspColorsValues[GraspColors.BLUE] } },
    components: {
      MuiButton: {
        styleOverrides: {
          root: { textTransform: 'unset' },
        },
        defaultProps: {
          disableElevation: true,
        },
      },
      MuiIconButton: {
        styleOverrides: {
          root: { borderRadius: '0.25rem', padding: '0.375rem' },
          sizeSmall: { padding: '0.125rem' },
        },
      },
      MuiSvgIcon: {
        styleOverrides: {
          root: { padding: '0.125rem' },
        },
      },
      MuiPaper: {
        defaultProps: { elevation: 0 },
      },
      MuiTabs: {
        styleOverrides: {
          indicator: { height: '5px' },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: { textTransform: 'unset' },
        },
      },
    },
  };

  export const awareThemeOptions: ThemeOptions = {
    components: {
      MuiButtonBase: {
        defaultProps: {
          disableRipple: true,
        },
      },
      MuiButton: {
        styleOverrides: {
          root: { borderRadius: '50px', textTransform: 'unset' },
          outlinedPrimary: ({ theme }) => ({
            color: theme.palette.text.primary,
            borderColor: theme.palette.text.primary,
          }),
        },
      },
      MuiIconButton: {
        styleOverrides: { root: { borderRadius: '100%' } },
      },
      MuiTab: {
        styleOverrides: { root: { textTransform: 'unset' } },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: { borderRadius: '50px' },
          multiline: { borderRadius: '20px' },
          notchedOutline: ({ theme }) => ({
            borderColor: theme.palette.text.primary,
          }),
        },
      },
    },
  };

  export const darkThemeOptions: ThemeOptions = {
    palette: {
      mode: 'dark',
    },
  };
  export const lightThemeOptions: ThemeOptions = {};
}
