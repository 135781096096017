import React, { useCallback, useMemo } from 'react';
import { ICase, ICategory } from '../../../../../../../../interfaces/cases';
import { cloneDeep } from 'lodash';
import IndeterminateCheckbox from '../../../../../../../../components/IndeterminateCheckbox';
import { Link } from 'react-router-dom';

type CaseItemProps = {
  organizationId: string;
  caseDetails: ICase;
  selectedCases: ICase[];
  caseCategories?: ICategory[];
  setSelectedCases: (cases: ICase[]) => void;
};

const CaseGroupDetailsCaseItem = ({
  organizationId,
  caseDetails,
  caseCategories,
  selectedCases,
  setSelectedCases,
}: CaseItemProps) => {
  const toggleCase = useCallback(() => {
    let selectedCasesCopy = cloneDeep(selectedCases);
    const existingCase = selectedCasesCopy.find((p) => p.id === caseDetails.id);

    if (existingCase === undefined) {
      setSelectedCases([...selectedCases, caseDetails]);
      return {};
    }

    selectedCasesCopy = selectedCasesCopy.filter(
      (p) => p.id !== caseDetails.id
    );
    setSelectedCases(selectedCasesCopy);

    return {};
  }, [caseDetails, selectedCases, setSelectedCases]);

  const caseCategory = useMemo(() => {
    return caseCategories?.find((p) => p.id === caseDetails.category_id);
  }, [caseCategories, caseDetails.category_id]);

  const caseSubCategory = useMemo(() => {
    return caseCategories
      ?.flatMap((p) => p.sub_categories)
      .find((p) => p.id === caseDetails.sub_category_id);
  }, [caseCategories, caseDetails.sub_category_id]);

  return (
    <>
      <tr onClick={toggleCase} key={caseDetails.id}>
        <td valign="middle" align="center">
          <IndeterminateCheckbox
            checked={
              selectedCases.find((p) => p.id === caseDetails.id) !== undefined
            }
            indeterminate={false}
            onChange={toggleCase}
          />
        </td>
        <td valign="middle">
          <Link to={`/organizations/${organizationId}/cases/${caseDetails.id}`}>
            <b>{caseDetails.title}</b>
          </Link>
        </td>
        <td valign="middle">{caseDetails.description}</td>
        <td valign="middle">{caseDetails.number}</td>
        <td valign="middle">{caseCategory?.name ?? '-'}</td>
        <td valign="middle">{caseSubCategory?.name ?? '-'}</td>
      </tr>
    </>
  );
};

export default CaseGroupDetailsCaseItem;
