import {
  ConversationTemplate,
  ConversationTemplateTriggerType,
} from '../interfaces/conversations';
import { useCallback } from 'react';
import { InputOption } from '../interfaces/ui';
import { cloneDeep } from 'lodash';
import { conversationTemplateTriggerTypeOptions } from '../helpers/utils/translationObject';
import { useIntl } from 'react-intl';

export const useTemplateCharacteristics = (
  conversationTemplates: ConversationTemplate[]
) => {
  const intl = useIntl();

  const isTemplateTimeBased = useCallback(
    (templateId: string): boolean => {
      const existingTemplate = conversationTemplates.find(
        (p) => p.id === templateId
      );

      if (!existingTemplate) return false;

      return (
        existingTemplate.trigger_type === ConversationTemplateTriggerType.Time
      );
    },
    [conversationTemplates]
  );

  const isTemplateDefault = useCallback(
    (templateId: string): boolean => {
      const existingTemplate = conversationTemplates.find(
        (p) => p.id === templateId
      );

      if (!existingTemplate) return false;

      return (
        existingTemplate.trigger_type ===
        ConversationTemplateTriggerType.PhaseLastResort
      );
    },
    [conversationTemplates]
  );

  const conversationTemplatesOptions = useCallback(
    (
      defaultTemplateMode: boolean,
      excludeDefault: boolean = false
    ): InputOption[] => {
      if (conversationTemplates === undefined) return [];

      let templates = cloneDeep(conversationTemplates);

      if (defaultTemplateMode)
        templates = templates.filter(
          (p) =>
            p.trigger_type === ConversationTemplateTriggerType.PhaseLastResort
        );

      if (excludeDefault)
        templates = templates.filter(
          (p) =>
            p.trigger_type !== ConversationTemplateTriggerType.PhaseLastResort
        );

      return templates.map((template) => ({
        text: `${template.display_code} (${intl.formatMessage({
          id:
            conversationTemplateTriggerTypeOptions.find(
              (p) => p.value === template.trigger_type
            )?.translationId ?? '',
        })})`,
        value: template.id,
      }));
    },
    [conversationTemplates, intl]
  );

  return {
    isTemplateTimeBased: isTemplateTimeBased,
    isTemplateDefault: isTemplateDefault,
    conversationTemplatesOptions: conversationTemplatesOptions,
  };
};
