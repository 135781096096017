import { IProjectSubmissionFilters } from 'interfaces/submissions';
import { SubmissionActionName } from './reducer';

export const setSubmissionsListFilters = (
  filters: IProjectSubmissionFilters | null
) => {
  return {
    type: SubmissionActionName.SUBMISSION_SET_LIST_FILTERS,
    filters: filters,
  };
};
