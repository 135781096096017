import React from 'react';
import SVG from 'react-inlinesvg';
import editIcon from 'assets/images/edit_icon_transparent.svg';
import classNames from 'classnames';

type EditButtonProps = {
  onClick: () => void;
  className?: string;
};

const EditButton = ({ onClick, className }: EditButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={classNames(['edit-button', className])}
      aria-label="edit"
    >
      <SVG src={editIcon} />
    </button>
  );
};

export default EditButton;
