import React, { useState } from 'react';
import { Button } from 'reactstrap';
import EditableInput from '../../../../../../../../../../../../components/EditableInput/EditableInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTrash } from '@fortawesome/pro-light-svg-icons';
import {
  ClosedListValidConversationQuestion,
  ClosedListValidConversationQuestionAnswer,
  QuestionValidConversationTemplateItem,
  ValidConversationTemplate,
} from '../../../../../../../../../../../../interfaces/conversations';
import { getEmptyClosedListAnswer } from './helpers';
import useOpen from '../../../../../../../../../../../../hooks/useOpen';
import { AVAILABLE_CULTURES } from '../../../../../../../../../../../../components/CultureSelector/CultureSelector';
import { useTypedContext } from '../../../../../../../../../../../../hooks/useTypedContext';
import { LanguageContext } from '../../../../types';
import { Field } from 'formik';
import FieldInput from '../../../../../../../../../../../../components/FieldInput';
import { useTranslations } from '../../../../hooks/useTranslations';
import { FormikUtilsContext } from '../../../../../../types';
import { cloneDeep } from 'lodash';
import { useGeneratePlaceholder } from '../../../../hooks/useGeneratePlaceholder';

type ClosedListProps = {
  index: number;
  values: ValidConversationTemplate;
};

const ClosedList = ({ index, values }: ClosedListProps) => {
  const selectedItem = values.content[index];

  const selectedQuestion =
    selectedItem as QuestionValidConversationTemplateItem;

  const selectedClosedListQuestion =
    selectedQuestion.value as ClosedListValidConversationQuestion;

  const [appendedAnswer, setAppendedAnswer] =
    useState<ClosedListValidConversationQuestionAnswer>(
      getEmptyClosedListAnswer(selectedClosedListQuestion.answers.length)
    );

  const { isOpen: isAddAnswerMode, toggle: toggleAddAnswerMode } = useOpen();

  const { providerInvariant } = useTranslations();

  const language = useTypedContext(LanguageContext);

  const isDefaultCulture = language.culture === AVAILABLE_CULTURES.EN;

  const formikUtils = useTypedContext(FormikUtilsContext);

  const getPlaceholder = useGeneratePlaceholder(isDefaultCulture);

  const resetAnswerMode = () => {
    setAppendedAnswer(
      getEmptyClosedListAnswer(selectedClosedListQuestion.answers.length)
    );
    toggleAddAnswerMode();
  };

  const handleEditAnswer = (target: string, value: string) => {
    if (target.endsWith('title'))
      setAppendedAnswer(
        isDefaultCulture
          ? { ...appendedAnswer, title: value }
          : { ...appendedAnswer, title_nb_no: value }
      );

    if (target.endsWith('description'))
      setAppendedAnswer(
        isDefaultCulture
          ? { ...appendedAnswer, description: value }
          : { ...appendedAnswer, description_nb_no: value }
      );
  };

  const handleAddAnswer = () => {
    let valuesCopy = cloneDeep(values);

    (
      (valuesCopy.content[index] as QuestionValidConversationTemplateItem)
        .value as ClosedListValidConversationQuestion
    ).answers = [
      ...(
        (valuesCopy.content[index] as QuestionValidConversationTemplateItem)
          .value as ClosedListValidConversationQuestion
      ).answers,
      appendedAnswer,
    ];

    formikUtils.setValues(valuesCopy);
    resetAnswerMode();
  };

  return (
    <>
      <div className="d-flex flex-column gap-2">
        {selectedClosedListQuestion.answers.map((answer, idx) => {
          const handleRemoveAnswer = () => {
            let valuesCopy = cloneDeep(values);

            (
              (
                valuesCopy.content[
                  index
                ] as QuestionValidConversationTemplateItem
              ).value as ClosedListValidConversationQuestion
            ).answers = (
              (
                valuesCopy.content[
                  index
                ] as QuestionValidConversationTemplateItem
              ).value as ClosedListValidConversationQuestion
            ).answers.filter((_, i) => i !== idx);

            formikUtils.setValues(valuesCopy);
          };

          return (
            <div
              key={answer.guid}
              className="d-flex flex-nowrap align-items-center gap-2"
            >
              <div className="w-50">
                <Field
                  required={true}
                  placeholder={getPlaceholder('title', answer.title)}
                  label={
                    idx > 0
                      ? undefined
                      : providerInvariant.wrapWithIntl(
                          providerInvariant.attachPrefix('title')
                        )
                  }
                  id={`content[${index}].value.answers[${idx}].${
                    isDefaultCulture ? 'title' : 'title_nb_no'
                  }`}
                  name={`content[${index}].value.answers[${idx}].${
                    isDefaultCulture ? 'title' : 'title_nb_no'
                  }`}
                  type="text"
                  component={FieldInput}
                />
              </div>
              <div className="w-50">
                <Field
                  required={false}
                  placeholder={getPlaceholder(
                    'description',
                    answer.description
                  )}
                  label={
                    idx > 0
                      ? undefined
                      : providerInvariant.wrapWithIntl(
                          providerInvariant.attachPrefix('description')
                        )
                  }
                  id={`content[${index}].value.answers[${idx}].${
                    isDefaultCulture ? 'description' : 'description_nb_no'
                  }`}
                  name={`content[${index}].value.answers[${idx}].${
                    isDefaultCulture ? 'description' : 'description_nb_no'
                  }`}
                  type="text"
                  component={FieldInput}
                />
              </div>
              <Button
                color="danger"
                outline={true}
                className="align-self-end"
                onClick={handleRemoveAnswer}
              >
                <FontAwesomeIcon icon={faTrash} />
              </Button>
            </div>
          );
        })}
      </div>
      {isAddAnswerMode ? (
        <div className="d-flex align-items-center gap-2">
          <div className="w-50">
            <EditableInput
              required={true}
              type="text"
              value={appendedAnswer.title}
              name="title"
              onEdit={handleEditAnswer}
              debounceEnabled={false}
            />
          </div>
          <div className="w-50">
            <EditableInput
              required={false}
              type="text"
              value={appendedAnswer.description}
              name="description"
              onEdit={handleEditAnswer}
              debounceEnabled={false}
            />
          </div>
          <div className="d-flex align-items-center justify-content-end gap-2">
            <Button color="danger" outline={true} onClick={resetAnswerMode}>
              <FontAwesomeIcon icon={faTrash} />
            </Button>
            <Button color="primary" outline={true} onClick={handleAddAnswer}>
              <FontAwesomeIcon icon={faCheck} />
            </Button>
          </div>
        </div>
      ) : (
        <Button
          color="primary"
          outline={true}
          block={true}
          disabled={isAddAnswerMode}
          onClick={toggleAddAnswerMode}
        >
          {providerInvariant.wrapWithIntl(
            providerInvariant.attachPrefix('addAnswer')
          )}
        </Button>
      )}
    </>
  );
};

export default ClosedList;
