import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import HourglassBottomOutlinedIcon from '@mui/icons-material/HourglassBottomOutlined';
import SyncOutlinedIcon from '@mui/icons-material/SyncOutlined';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import React from 'react';
import IconButton from '../../../../../../../../components/IconButton';
import {
  ConversationTemplate,
  ConversationTemplateTriggerType,
} from '../../../../../../../../interfaces/conversations';
import { useHandleAwareSessionDataRegenerate } from './hooks/useHandleAwareSessionDataRegenerate';
import { useHandleTemplateExport } from './hooks/useHandleTemplateExport';
import { useTemplateCharacteristicsTranslations } from './hooks/useTemplateCharacteristicsTranslations';

type ConversationTemplatesListItemProps = {
  template: ConversationTemplate;
  editTemplate: (template: ConversationTemplate) => void;
};

const ConversationTemplatesListItem = ({
  template,
  editTemplate,
}: ConversationTemplatesListItemProps) => {
  const { getTriggerTypeTranslation, getContextTranslation } =
    useTemplateCharacteristicsTranslations();

  const { handleAsync, isLoading } =
    useHandleAwareSessionDataRegenerate(template);

  const handleEditTemplate = () => editTemplate(template);

  const handleExport = useHandleTemplateExport(template);

  return (
    <tr key={template.id}>
      <td>{template.display_code}</td>
      <td>{getContextTranslation(template.context)}</td>
      <td>{getTriggerTypeTranslation(template.trigger_type)}</td>
      <td>
        <div className="d-flex align-items-center justify-content-end gap-2">
          {(template.trigger_type ===
            ConversationTemplateTriggerType.AwareSession ||
            template.trigger_type ===
              ConversationTemplateTriggerType.UserSatisfactionFeedback) && (
            <IconButton
              variant="outlined"
              color="primary"
              onClick={handleAsync}
            >
              {isLoading ? (
                <HourglassBottomOutlinedIcon />
              ) : (
                <SyncOutlinedIcon />
              )}
            </IconButton>
          )}
          <IconButton variant="outlined" color="primary" onClick={handleExport}>
            <UploadFileOutlinedIcon />
          </IconButton>
          <IconButton
            variant="outlined"
            color="primary"
            onClick={handleEditTemplate}
          >
            <CreateOutlinedIcon />
          </IconButton>
        </div>
      </td>
    </tr>
  );
};

export default ConversationTemplatesListItem;
