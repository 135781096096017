import { useCallback } from 'react';
import { ConversationQuestion } from '../../../../../../../interfaces/conversations';
import {
  DashboardFilter,
  DashboardTemplateFilter,
} from '../../../../../../../interfaces/dashboards';
import { IGrasp } from '../../../../../../../interfaces/grasps';
import { IBaseOrganization } from '../../../../../../../interfaces/organizations';
import { BasePersonality } from '../../../../../../../interfaces/personalities';
import { DashboardFilterHelpers } from '../helpers/filters';
import { useClosedListOptions } from './useClosedListOptions';
import { useGraspsOptions } from './useGraspsOptions';
import { useMemberOptions } from './useMemberOptions';
import { useOrganizationOptions } from './useOrganizationOptions';
import { useRangeOptions } from './useRangeOptions';
import { useSourcePeriodOptions } from './useSourcePeriodOptions';

export const useHandlePrepareFilters = (
  questions: ConversationQuestion[],
  members: BasePersonality[],
  grasps: IGrasp[],
  organizations: IBaseOrganization[]
) => {
  const organizationOptions = useOrganizationOptions(organizations);
  const closedListOptions = useClosedListOptions(questions);
  const memberOptions = useMemberOptions(members);
  const graspOptions = useGraspsOptions(grasps);
  const rangeOptions = useRangeOptions(questions);
  const sourcePeriodOptions = useSourcePeriodOptions();

  return useCallback(
    (content: DashboardTemplateFilter[]): DashboardFilter[] => {
      let filters: DashboardFilter[] = [];

      content.forEach((item) => {
        if (DashboardFilterHelpers.IsOrganization(item)) {
          let organizationFilter = DashboardFilterHelpers.OrganizationFilter(
            item.visible
          );
          organizationFilter = organizationOptions.append(organizationFilter);
          organizationFilter = organizationOptions.select(
            organizationFilter,
            item
          );

          filters.push(organizationFilter);
          return;
        }

        if (DashboardFilterHelpers.IsClosedList(item)) {
          let closedListFilter = DashboardFilterHelpers.ClosedListFilter(item);
          closedListFilter = closedListOptions.append(closedListFilter, item);
          closedListFilter = closedListOptions.select(closedListFilter, item);

          filters.push(closedListFilter);
          return;
        }

        if (DashboardFilterHelpers.IsMember(item)) {
          let memberFilter = DashboardFilterHelpers.MemberFilter(item.visible);
          memberFilter = memberOptions.append(memberFilter);
          memberFilter = memberOptions.select(memberFilter, item);

          filters.push(memberFilter);
          return;
        }

        if (DashboardFilterHelpers.IsGrasp(item)) {
          let graspFilter = DashboardFilterHelpers.GraspFilter(item.visible);
          graspFilter = graspOptions.append(graspFilter);
          graspFilter = graspOptions.select(graspFilter, item);

          filters.push(graspFilter);
          return;
        }

        if (DashboardFilterHelpers.IsRange(item)) {
          let rangeFilter = DashboardFilterHelpers.RangeFilter(item);
          rangeFilter = rangeOptions.select(rangeFilter, item);

          filters.push(rangeFilter);
          return;
        }

        if (DashboardFilterHelpers.IsSourcePeriod(item)) {
          let sourcePeriodFilter = DashboardFilterHelpers.SourcePeriodFilter(
            item.visible
          );
          sourcePeriodFilter = sourcePeriodOptions.append(sourcePeriodFilter);
          sourcePeriodFilter = sourcePeriodOptions.select(
            sourcePeriodFilter,
            item
          );

          filters.push(sourcePeriodFilter);
        }
      });

      return filters;
    },
    [
      closedListOptions,
      graspOptions,
      memberOptions,
      organizationOptions,
      rangeOptions,
      sourcePeriodOptions,
    ]
  );
};
