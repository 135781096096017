import LoadingButton from 'components/LoadingButton';
import { Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Container, Row } from 'reactstrap';
import imageAccessibility from 'assets/images/ic-accessibility.svg';
import { Link, useNavigate } from 'react-router-dom';
import usePatientCreateForOrganization from 'api/queries/patients/usePatientCreateForOrganization';
import { getCulture } from 'translations/utils/cultures';
import { isEmail } from 'helpers/utils/validators';
import useSendPatientInvite from 'api/mutations/patients/usePatientSendInvite';
import ParticipantsFormCreateUser from './components/ParticipantsFormCreateUser';
import { RegisterFormKeys } from 'interfaces/enums';
import useRequiredParams from 'hooks/useRequiredParams';
import { generateOrganizationPersonalitiesUrl } from 'helpers/routeDefinitions';

const initialValues = {
  first_name: '',
  last_name: '',
  birthdate: '',
  gender: '',
  address: '',
  ssn: '',
  email: '',
  phone_number: '',
};

const CreateParticipant = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const onSuccess = () => {
    navigate(generateOrganizationPersonalitiesUrl({ organizationId }));
  };

  const { mutate: patientCreateMutation, isLoading } =
    usePatientCreateForOrganization({ params: { organizationId, onSuccess } });

  const { mutate: sendInviteMutation, isLoading: isSendInviteLoading } =
    useSendPatientInvite(organizationId);

  const handleSubmit = ({ email, ...rest }: typeof initialValues) => {
    patientCreateMutation({
      culture: getCulture(),
      email: email.length > 0 ? email : null,
      ...rest,
      is_participant: true,
    });
  };

  const validate = (values: typeof initialValues) => {
    const errors: Partial<typeof initialValues> = {};
    [RegisterFormKeys.FIRST_NAME, RegisterFormKeys.LAST_NAME].forEach((key) => {
      if (!values[key])
        errors[key] = intl.formatMessage({
          id: 'CaseCard.create.errors.inputEmpty',
        });
    });

    if (values.email && !isEmail(values.email))
      errors.email = intl.formatMessage({
        id: 'CaseCard.create.errors.emailError',
      });

    return errors;
  };

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
    >
      {(form) => (
        <Form>
          <div className="border-bottom">
            <Container>
              <div className="mt-5 pb-2 font-weight-bold ">
                <div className="d-flex">
                  <Col sm={6} md={6} lg={4}>
                    <h3 style={{ fontSize: '24px', fontWeight: 500 }}>
                      <FormattedMessage id="UserDashboard.newPatient" />
                    </h3>
                  </Col>
                  <Col sm={6} md={6} lg={5}>
                    <div className="d-flex">
                      <span
                        className="newPatientActive"
                        style={{ fontSize: '21px', fontWeight: 600 }}
                      >
                        <img
                          src={imageAccessibility}
                          className="iconNewPatientColor"
                          alt="imageAccessibility"
                        />
                        <FormattedMessage id="Patient.patientInformation" />
                      </span>
                    </div>
                  </Col>
                </div>
              </div>
            </Container>
          </div>
          <Container className="mt-4 mb-4">
            <ParticipantsFormCreateUser
              formik={form}
              displayInvitePatientButton
              inviteDisabled={!form.values.email || !isEmail(form.values.email)}
              onInviteClick={() => sendInviteMutation(form.values.email)}
              isInviteLoading={isSendInviteLoading}
            />
            <Row>
              <Col
                sm={12}
                lg={{
                  offset: 1,
                  size: 10,
                }}
              >
                <div className="d-flex align-items-center mt-4">
                  <div>
                    <Link
                      to={`/organizations/${organizationId}/patients`}
                      className="btn btn-cancel btn-short mb-0"
                    >
                      <FormattedMessage id="General.cancel" />
                    </Link>
                  </div>
                  <LoadingButton
                    disabled={!(form.isValid && form.dirty)}
                    isLoading={isLoading}
                    outline
                    value="UserDashboard.createPatient"
                    type="submit"
                    className="btn btn-login btn-short mb-0 ms-auto"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </Form>
      )}
    </Formik>
  );
};

export default CreateParticipant;
