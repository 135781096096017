import AddScheduleTemplateModal from 'components/AddScheduleTemplateModal';
import { BaseModalProps } from 'interfaces/ui';
import React from 'react';
import { useAddTemplateModalFormHandler } from './hooks/useAddTemplateModalFormHandler';

const CaseConversationsScheduleAddTemplateModal = ({
  isOpen,
  toggle,
}: BaseModalProps) => {
  const formHandler = useAddTemplateModalFormHandler(toggle);

  return (
    <AddScheduleTemplateModal
      isOpen={isOpen}
      toggle={toggle}
      formHandler={formHandler}
    />
  );
};

export default CaseConversationsScheduleAddTemplateModal;
