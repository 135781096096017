import React from 'react';
import Image from '../../../../../../../../components/Image';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/pro-solid-svg-icons';
import { iconColors } from '../../../../../../../../helpers/colors';
import { useTypedContext } from '../../../../../../../../hooks/useTypedContext';
import { OpenHookValues } from '../../../../../../../../hooks/useOpen';
import { OrganizationDetailsContext } from '../../../../types';

type OrganizationHeaderProps = {
  editMode: OpenHookValues;
};

const OrganizationHeader = ({ editMode }: OrganizationHeaderProps) => {
  const organization = useTypedContext(OrganizationDetailsContext);

  return (
    <div className="d-flex align-items-center header mb-4">
      <Image
        src={organization.logo_url ?? ''}
        alt="logo"
        style={{
          width: '60px',
        }}
        className="me-2"
      />
      <h1 className="ms-4 me-2">{organization.name}</h1>
      {!editMode.isOpen && (
        <Button
          onClick={editMode.toggle}
          className="bg-transparent btn-transparent ms-2"
        >
          <FontAwesomeIcon
            icon={faEdit}
            color={iconColors.edit}
            style={{
              cursor: 'pointer',
            }}
          />
        </Button>
      )}
    </div>
  );
};

export default OrganizationHeader;
