import MenuItem from '@mui/material/MenuItem/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
  GridRenderCellParams,
  GridRenderEditCellParams,
  useGridApiContext,
} from '@mui/x-data-grid';
import React, { useLayoutEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { DashboardReportDestinationType } from '../../../../../../../../../../../../interfaces/dashboards';
import { ReportScheduleTableTypes } from '../../types';

const DestinationTypeCell = (
  props: GridRenderCellParams<ReportScheduleTableTypes.ReportScheduleTableEntry>
) => {
  return (
    <span>
      <FormattedMessage
        id={`Enums.DashboardReportDestinationType.${props.value}`}
      />
    </span>
  );
};

export const DestinationTypeEditCell = (
  props: GridRenderEditCellParams<ReportScheduleTableTypes.ReportScheduleTableEntry>
) => {
  const intl = useIntl();
  const apiRef = useGridApiContext();
  const ref = useRef<any>();

  const items = Object.values(DashboardReportDestinationType).map((item) => ({
    id: item,
  }));

  const valueGetter = (item: { id: string }) => item.id;

  const textGetter = (item: { id: string }) =>
    intl.formatMessage({
      id: `Enums.DashboardReportDestinationType.${item.id}`,
    });

  useLayoutEffect(() => {
    if (!props.hasFocus || !ref.current) return;
    ref.current.focus();
  }, [props.hasFocus]);

  const handleValueChange = (event: SelectChangeEvent<any>) => {
    const newValue = event.target.value;
    apiRef.current.setEditCellValue({
      id: props.row['id'],
      field: props.field,
      value: newValue,
    });

    apiRef.current.setEditCellValue({
      id: props.row['id'],
      field: 'destination_id',
      value: '',
    });
  };

  return (
    <Select fullWidth={true} value={props.value} onChange={handleValueChange}>
      {items.map((item) => (
        <MenuItem key={valueGetter(item)} value={valueGetter(item)}>
          {textGetter(item)}
        </MenuItem>
      ))}
    </Select>
  );
};

export default DestinationTypeCell;
