import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Input,
  InputGroup,
  InputGroupText,
  Popover,
  PopoverBody,
  PopoverHeader,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { DateRange } from 'react-day-picker';
import { differenceInCalendarDays } from 'date-fns';

//components
import Calendar from 'components/Calendar';

//helpers
import { formatDate, getISOStringUTCNonAware } from 'helpers/dates';

//helpers
import useOpen from 'hooks/useOpen';

//images
import caret from 'assets/images/chrome-native-caret.png';
import filterIcon from 'assets/images/filter_icon.svg';

type DateRangePopoverProps = {
  onDateChange: (dateRange: DateRange) => void;
  selectedDates: DateRange;
};

const DateRangePopover = ({
  selectedDates,
  onDateChange,
}: DateRangePopoverProps) => {
  const intl = useIntl();
  const { isOpen, toggle } = useOpen(false);

  const onAcceptClick = () => {
    onDateChange(dates);
    toggle();
  };

  const onClearAllClick = () => {
    onDateChange({
      from: undefined,
      to: undefined,
    });
    toggle();
  };

  const [dates, setDates] = useState<DateRange>(selectedDates);

  useEffect(() => {
    setDates(selectedDates);
  }, [selectedDates]);

  const onChange = (dateRange: DateRange) => setDates(dateRange);

  const renderDates = useMemo(() => {
    if (selectedDates?.from && selectedDates.to) {
      return differenceInCalendarDays(selectedDates.to, selectedDates.from) > 0
        ? `${formatDate(getISOStringUTCNonAware(selectedDates.from), {
            ignoreTime: true,
          })}, to: ${formatDate(getISOStringUTCNonAware(selectedDates.to), {
            ignoreTime: true,
          })}`
        : `For: ${formatDate(getISOStringUTCNonAware(selectedDates.from), {
            ignoreTime: true,
          })}`;
    } else
      return intl.formatMessage({
        id: 'Reports.ReportsList.selectDateRange',
      });
  }, [selectedDates, intl]);

  return (
    <div className="DateRangePopover">
      <InputGroup className="DateRangePopover__display" id="input-target">
        <InputGroupText>
          <img src={filterIcon} alt="filter-icon" />
        </InputGroupText>
        <Input className="FilterInput" disabled value={renderDates} />
        <InputGroupText>
          <img src={caret} alt="dropdown_icon" />
        </InputGroupText>
      </InputGroup>
      <Popover
        flip
        placement="bottom"
        isOpen={isOpen}
        target="input-target"
        toggle={toggle}
        className="DateRangePopover__popover"
      >
        <PopoverHeader className="DateRangePopover__header">
          <FormattedMessage id="Projects.filter.filters" />
        </PopoverHeader>
        <button
          className="DateRangePopover__header__clear ms-auto"
          onClick={onClearAllClick}
        >
          <FormattedMessage id="Projects.filter.clearAll" />
        </button>
        <PopoverBody className="px-0">
          <Calendar
            onDateChange={onChange}
            defaultValueForRange={dates || undefined}
            rangeOnly
          />
          <div className="DateRangePopover__btn-container">
            <Button color="primary" onClick={onAcceptClick}>
              <FormattedMessage id="General.accept" />
            </Button>
            <Button color="primary" outline onClick={toggle}>
              <FormattedMessage id="General.close" />
            </Button>
          </div>
        </PopoverBody>
      </Popover>
    </div>
  );
};

export default DateRangePopover;
