import React from 'react';
import CustomDataGrid from '../../../../../../../../../../components/CustomDataGrid';
import { CustomMaterialTableHelpers } from '../../../../../../../../../../components/CustomDataGrid/helpers';
import { OrganizationAccessProfile } from '../../../../../../../../../../interfaces/accessProfiles';
import { IUserOrganizationResponse } from '../../../../../../../../../../interfaces/membership';
import { UsersTabHelpers } from '../../helpers';
import { useColumns } from './hooks/useColumns';

type UsersTableProps = {
  usersInOrganization: IUserOrganizationResponse[];
  accessProfiles: OrganizationAccessProfile[];
};

const UsersTable = ({
  usersInOrganization,
  accessProfiles,
}: UsersTableProps) => {
  const rows = UsersTabHelpers.FillDataWithRowId(usersInOrganization);
  const columns = useColumns(accessProfiles);

  return (
    <CustomDataGrid
      onCellDoubleClick={CustomMaterialTableHelpers.DefaultMuiPreventedEvent}
      onCellEditStart={CustomMaterialTableHelpers.DefaultMuiPreventedEvent}
      onCellEditStop={CustomMaterialTableHelpers.DefaultMuiPreventedEvent}
      onCellKeyDown={CustomMaterialTableHelpers.DefaultMuiPreventedEvent}
      onCellClick={CustomMaterialTableHelpers.DefaultMuiPreventedEvent}
      getRowHeight={CustomMaterialTableHelpers.GetRowHeight}
      rows={rows}
      columns={columns}
      sx={{
        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
          py: '0.5rem',
        },
      }}
    />
  );
};

export default UsersTable;
