import React from 'react';
import useDashboardTemplateOrganizationsGrasps from '../../../../../../../../api/queries/dashboards/useDashboardTemplateOrganizationsGrasps';
import useDashboardTemplateOrganizationsMembers from '../../../../../../../../api/queries/dashboards/useDashboardTemplateOrganizationsMembers';
import { useOrganizationPathParams } from '../../../../../../../../hooks/paths/useOrganizationPathParams';
import { useInitQueries } from '../../../../../../../../hooks/queries/useInitQuery';
import { ConversationQuestion } from '../../../../../../../../interfaces/conversations';
import { IGrasp } from '../../../../../../../../interfaces/grasps';
import { IOrganizationMembers } from '../../../../../../../../interfaces/organizations';
import QueriesLoaded from '../../../../../../../QueriesLoaded';
import FullScreenSpinner from '../../../../../../../Spinners/FullScreenSpinner';
import { useCommonData } from '../../../../hooks/useCommonData';
import DashboardContent from '../../../DashboardContent';
import { DashboardContentContainerProps } from '../../types';

const DataMappingInvariantContainer = ({
  template,
}: DashboardContentContainerProps) => {
  const { organizationId } = useOrganizationPathParams();

  const { conversationQuestions } = useCommonData();

  const organizationsMembers = useDashboardTemplateOrganizationsMembers({
    params: { organizationId, templateId: template.id },
    options: { enabled: false, staleTime: Infinity },
  });

  const organizationsGrasps = useDashboardTemplateOrganizationsGrasps({
    params: { organizationId, templateId: template.id },
    options: { enabled: false, staleTime: Infinity },
  });

  const queries = [
    conversationQuestions,
    organizationsMembers,
    organizationsGrasps,
  ];

  useInitQueries(queries);

  return (
    <QueriesLoaded onLoading={<FullScreenSpinner />} queries={queries}>
      {(data: any[]) => (
        <DashboardContent
          template={template}
          organizationsMembers={data[1] as IOrganizationMembers[]}
          grasps={data[2] as IGrasp[]}
          questions={data[0] as ConversationQuestion[]}
        />
      )}
    </QueriesLoaded>
  );
};

export default DataMappingInvariantContainer;
