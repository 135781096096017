enum QueryKeys {
  getCaseDetails = 'getCaseDetails',
  getUserCaseDetails = 'getUserCaseDetails',
  getUserCases = 'getUserCases',
  organizationPersonalities = 'organizationPersonalities',
  getSharingRequests = 'getSharingRequests',
  getUserTimelines = 'getUserTimelines',
  getMasterTimeline = 'getMasterTimeline',
  getCategories = 'getCategories',
  getUserGrasp = 'getUserGrasp',
  getTimelineDetails = 'getTimelineDetails',
  getTimelineSharingResponses = 'getTimelineSharingResponses',
  getUserActivities = 'getUserActivities',
  getTimelineActivities = 'getTimelineActivities',
  getTimelineObservations = 'getTimelineObservation',
  getTimelineDataForSqueezeChart = 'getTimelineDataForSqueezeChart',
  getTimelineAnnotations = 'getTimelineAnnotations',
  getGraspDetails = 'getGraspDetails',
  getGraspsSettings = 'getGraspsSettings',
  getCaseHistory = 'getCaseHistory',
  getOrganizationUserActiveGrasp = 'getOrganizationUserActiveGrasp',
  getGraspSharingList = 'getGraspSharingList',
  getInvitationDetails = 'getInvitationDetails',
  getPatientList = 'getPatientList',
  getOrganizationCases = 'getOrganizationCases',
  getOrganizationGraspsList = 'getOrganizationGraspsList',
  getGrasps = 'getGrasps',
  getOneTimeToken = 'getOneTimeToken',
  getUserPersonalData = 'getUserPersonalData',
  getUserOrganizations = 'getUserOrganizations',
  getOrganizationDetails = 'geOrganizationDetails',
  getOrganizationTrackingTypes = 'getOrganizationTrackingTypes',
  getUsersInOrganization = 'getUsersInOrganization',
  getOrganizationEndpoints = 'getOrganizationEndpoints',
  getUserAssessments = 'getUserAssessments',
  getOrganizationContextAssessmentFormsForPeriod = 'getOrganizationContextAssessmentFormsForPeriod',
  getOrganizationContextAssessmentFormsForDate = 'getOrganizationContextAssessmentFormsForDate',
  getUserContextAssessmentFormsForPeriod = 'getUserContextAssessmentFormsForPeriod',
  getGroupedUserAssessments = 'getGroupedUserAssessments',
  getUserContextAssessmentFormSummary = 'getUserContextAssessmentFormSummary',
  getOrganizationContextAssessmentFormSummary = 'getOrganizationContextAssessmentFormSummary',
  getInfiniteProjectUsers = 'getInfiniteProjectUsers',
  getAssessmentFormsAssignments = 'getAssessmentFormsAssignments',
  getAssessmentFormsTemplate = 'getAssessmentFormsTemplate',
  getOrganizationAssessmentFormsTemplate = 'getOrganizationAssessmentFormsTemplate',
  getProjectAssessmentForms = 'getProjectAssessmentForms',
  getProject = 'getProject',
  getOrganizationReportsList = 'getOrganizationReportsList',
  getOrganizationReport = 'getOrganizationReport',
  getReportTemplate = 'getReportTemplate',
  getExternalAuthenticationSchemes = 'getExternalAuthenticationSchemes',
  getTrendVisualizationTemplates = 'getTrendVisualizationTemplates',
  getUserContextAssessmentFormQuestionsHistory = 'getUserContextAssessmentFormQuestionsHistory',
  getUserExternalLogins = 'getUserExternalLogins',
  getSymptoms = 'getSymptoms',
  getOrganizationSymptoms = 'getOrganizationSymptoms',
  getCaseSymptoms = 'getCaseSymptoms',
  getOrganizationPatientDetails = 'getOrganizationPatientDetails',
  getOrganizationMemberList = 'getOrganizationMemberList',
  getMembersInOrganizations = 'getMembersInOrganizations',
  getStrictOrganizationPersonalities = 'getStrictOrganizationPersonalities',
  getOrganizationMemberRequests = 'getOrganizationMemberRequests',
  getOrganizationCasesRequests = 'getOrganizationCasesRequests',
  getUsersDataDumpStatus = 'getUsersDataDumpStatus',
  getUsersDataDumpFile = 'getUsersDataDumpFile',
  getCaseQuestionHistoryInDay = 'getCaseQuestionHistoryInDay',
  getUserQuestionHistoryInDay = 'getUserQuestionHistoryInDay',
  getFeedbackMessages = 'getFeedbackMessages',
  getCaseMembers = 'getCaseMembers',
  getFunctionalities = 'getFunctionalities',
  getOrganizationFunctionalities = 'getOrganizationFunctionalities',
  getEventLogs = 'getEventLogs',
  getFeedbackResources = 'getFeedbackResources',
  getOrganizationUserAccessProfiles = 'getOrganizationUserAccessProfiles',
  getConversationTemplates = 'getConversationTemplates',
  getEducationMaterials = 'getEducationMaterials',
  getEducationMaterial = 'getEducationMaterial',
  getEducationMaterialCategories = 'getEducationMaterialCategories',
  getEducationMaterialCategoriesTree = 'getEducationMaterialCategoriesTree',
  getProjects = 'getProjects',
  getTreatmentPhases = 'getTreatmentPhases',
  getConversationQuestions = 'getConversationQuestions',
  getUserCaseConversationQuestions = 'getUserCaseConversationQuestions',
  getUserCaseCurrentConversation = 'getUserCaseCurrentConversation',
  getGroupedConversationQuestions = 'getGroupedConversationQuestions',
  getFormConversations = 'getFormConversations',
  getOrganizationsCaseDefaultSetups = 'getOrganizationsCaseDefaultSetups',
  getDashboardTemplates = 'getDashboardTemplates',
  getDashboardReportSchedules = 'getDashboardReportSchedules',
  getDashboardTemplate = 'getDashboardTemplate',
  getDashboardDataQuery = 'getDashboardDataQuery',
  getDashboardTemplateOrganizationsMembers = 'getDashboardTemplateOrganizationsMembers',
  getDashboardTemplateOrganizationsGrasps = 'getDashboardTemplateOrganizationsGrasps',
  getOrganizationAwareSessions = 'getOrganizationAwareSessions',
  getAwareSessionsWithReferencePeriod = 'getAwareSessionsWithReferencePeriod',
  getCaseAwareSession = 'getCaseAwareSession',
  getAwareSessionsForPatientNumber = 'getAwareSessionsForPatientNumber',
  getAwareSessionDataMapping = 'getAwareSessionDataMapping',
  getUserQuestionsAnswerHistory = 'getUserQuestionsAnswerHistory',
  getCaseGroups = 'getCaseGroups',
  getCaseGroup = 'getCaseGroup',
  getProjectSubmissions = 'getProjectSubmissions',
  generateGroupFeedbackData = 'generateGroupFeedbackData',
  getConversationAnswerHistory = 'getConversationAnswerHistory',
  getCasePhaseHistory = 'getCasePhaseHistory',
  getCaseGroupConversationSchedules = 'getCaseGroupConversationSchedules',
  getCaseGroupConversationScheduleResponses = 'getCaseGroupConversationScheduleResponses',
  getCaseConversationSchedules = 'getCaseConversationSchedules',
  getCaseNotificationSchedules = 'getCaseNotificationSchedules',
  getOrganizationObservationMeanings = 'getOrganizationObservationMeanings',
  getUserCaseObservationMeanings = 'getUserCaseObservationMeanings',
  getUngroupedCasesCaseGroup = 'getUngroupedCasesCaseGroup',
  getTreatmentPlans = 'getTreatmentPlans',
  getExerciseSessions = 'getExerciseSessions',
  getTimelineObservationsForPeriod = 'getTimelineObservationsForPeriod',
  getOrganizationDevices = 'getOrganizationDevices',
  getMemberDashboardReportsWithReferencePeriod = 'getMemberDashboardReportsWithReferencePeriod',
  getHelpCategories = 'getHelpCategories',
  getInfiniteAwareSessionData = 'getInfiniteAwareSessionData',
  getCaseProcedureHistory = 'getCaseProcedureHistory',
  getCaseProcedures = 'getCaseProcedures',
}

export default QueryKeys;
