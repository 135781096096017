import React, { useMemo } from 'react';
import { useCommonData } from '../../../../../Organizations/views/OrganizationDetailsView/hooks/useCommonData';
import { useInitQuery } from '../../../../../../hooks/queries/useInitQuery';
import CustomTable from '../../../../../../components/CustomTable';
import ConversationTemplatesListItem from './components/ConversationTemplatesListitem';
import { useSelectedTemplate } from './hooks/useSelectedTemplate';
import ConversationTemplateModal from '../../modals/ConversationTemplateModal';

const headerItems = [
  'ConversationsAndFeedbacks.ConversationTemplates.ConversationTemplatesList.name',
  'ConversationsAndFeedbacks.ConversationTemplates.ConversationTemplatesList.context',
  'ConversationsAndFeedbacks.ConversationTemplates.ConversationTemplatesList.triggerType',
  'ConversationsAndFeedbacks.ConversationTemplates.ConversationTemplatesList.actions',
];

const ConversationTemplatesList = () => {
  const { conversationTemplates } = useCommonData();
  useInitQuery(conversationTemplates);

  const { selectedTemplate, isOpen, toggle, editTemplate } =
    useSelectedTemplate();

  const renderBodyRows = useMemo(() => {
    const templates = (conversationTemplates.data ?? []).sort((a, b) =>
      a.display_code.localeCompare(b.display_code)
    );

    return templates.map((template) => {
      return (
        <ConversationTemplatesListItem
          key={template.id}
          template={template}
          editTemplate={editTemplate}
        />
      );
    });
  }, [conversationTemplates.data, editTemplate]);

  return (
    <div className="w-100">
      {isOpen && selectedTemplate && (
        <ConversationTemplateModal
          isOpen={isOpen}
          toggle={toggle}
          template={selectedTemplate}
        />
      )}
      <CustomTable
        isLoading={
          conversationTemplates.isLoading || conversationTemplates.isRefetching
        }
        headerItems={headerItems}
        bodyRows={renderBodyRows}
      />
    </div>
  );
};

export default ConversationTemplatesList;
