import React, { useMemo } from 'react';
import useEducationMaterials from '../../../../../../../../../../api/queries/educationMaterials/useEducationMaterials';
import { useCommonParams } from '../../../../../../../../hooks/useCommonParams';
import { useInitQuery } from '../../../../../../../../../../hooks/queries/useInitQuery';
import { useOptions } from '../../../../../../../../../../hooks/useOptions';
import Skeleton from 'react-loading-skeleton';
import SkeletonPlaceholderOnLoad from '../../../../../../../../../../components/SkeletonPlaceholderOnLoad';
import { Field } from 'formik';
import FieldSelect from '../../../../../../../../../../components/FieldSelect';
import { useTranslations } from '../../hooks/useTranslations';

type EducationItemEditProps = {
  index: number;
};

const EducationItemEdit = ({ index }: EducationItemEditProps) => {
  const { organizationId } = useCommonParams();

  const educationMaterialsQuery = useEducationMaterials({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(educationMaterialsQuery);

  const { educationMaterials } = useOptions();

  const { providerInvariant } = useTranslations();

  const educationMaterialsOptions = useMemo(() => {
    return educationMaterials(educationMaterialsQuery);
  }, [educationMaterials, educationMaterialsQuery]);

  const placeholder = (
    <div className="w-100">
      <Skeleton width="40%" />
      <Skeleton width="100%" height={30} />
    </div>
  );

  return (
    <>
      <SkeletonPlaceholderOnLoad
        isLoading={educationMaterialsQuery.isLoading}
        placeholder={placeholder}
      >
        <Field
          required={true}
          displayEmpty={true}
          placeholder={providerInvariant.wrapWithIntl(
            providerInvariant.attachPrefix('materialPlaceholder')
          )}
          label={providerInvariant.wrapWithIntl(
            providerInvariant.attachPrefix('material')
          )}
          id={`content[${index}].resource_id`}
          name={`content[${index}].resource_id`}
          type="select"
          options={educationMaterialsOptions}
          component={FieldSelect}
        />
      </SkeletonPlaceholderOnLoad>
    </>
  );
};

export default EducationItemEdit;
