import { CultureEntry } from '../../interfaces/cultures';
import useCultures from './useCultures';
import { useCallback } from 'react';

export const useGetCultureEntry = () => {
  const { currentCulture, defaultCulture } = useCultures();

  return useCallback(
    <T>(entry: CultureEntry<T>[]): T | undefined => {
      const currentEntry = entry.find(
        (p) => p.culture === currentCulture.cultureCode
      );

      if (currentEntry) return currentEntry.value;

      const defaultEntry = entry.find(
        (p) => p.culture === defaultCulture.cultureCode
      );

      if (defaultEntry) return defaultEntry.value;

      return undefined;
    },
    [currentCulture.cultureCode, defaultCulture.cultureCode]
  );
};
