import { useTranslations as useBaseTranslations } from 'hooks/useTranslations';
import { useTypedContext } from '../../../../../../../../../hooks/useTypedContext';
import { IntlObjectContext } from '../../../types';

export const useTranslations = () => {
  const intlObject = useTypedContext(IntlObjectContext);

  const providerBased = useBaseTranslations(
    'ConversationsAndFeedbacks.ConversationTemplates.ConversationTemplateModal.ItemEdit'
  );

  const providerInvariant = useBaseTranslations(
    'ConversationsAndFeedbacks.ConversationTemplates.ConversationTemplateModal.ItemEdit',
    intlObject
  );

  return { providerBased, providerInvariant };
};
