import { useCallback, useEffect } from 'react';

const useAsyncEventListen = (
  name: string,
  onReceived: (e: Event) => Promise<void>,
  oneTimeListen: boolean = false
) => {
  const onEventReceived = useCallback(
    async (e: Event) => {
      await onReceived(e);
      if (oneTimeListen) window.removeEventListener(name, onEventReceived);
    },
    [name, onReceived, oneTimeListen]
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener(name, onEventReceived);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener(name, onEventReceived);
      }
    };
  }, [name, onEventReceived]);
};

export default useAsyncEventListen;
