import { uniq } from 'lodash';
import {
  DashboardFilterType,
  IGraspDashboardFilter,
  IMemberDashboardFilter,
  INonQuestionFilter,
  IOptionsFilter,
  IOrganizationDashboardFilter,
} from '../../../../../../../../../../../../interfaces/dashboards';
import { DashboardSelectionHelpers } from '../../../../../../helpers/selection';
import { DashboardQueriesContextType } from '../../../../../../types';
import { DashboardDataStates } from '../../../../../DashboardDataContent/store/state';
import { RenderFilterFunction } from './types';

export const everySelected = (filter: IOptionsFilter) => {
  return filter.options.every((p) => p.selected);
};

export const noneSelected = (filter: IOptionsFilter) => {
  return filter.options.every((p) => !p.selected);
};

export const firstFilterOfType = (
  data: DashboardDataStates.DashboardDataState,
  type: DashboardFilterType
) => {
  return data.template.filters.find((p) => p.type === type);
};

export const renderMemberFilter = (
  data: DashboardDataStates.DashboardDataState,
  queries: DashboardQueriesContextType,
  filter: IMemberDashboardFilter,
  renderFunction: RenderFilterFunction<IMemberDashboardFilter>
) => {
  const organizationFilter = firstFilterOfType(
    data,
    DashboardFilterType.Organization
  ) as IOrganizationDashboardFilter | undefined;

  const graspFilter = firstFilterOfType(data, DashboardFilterType.Grasp) as
    | IGraspDashboardFilter
    | undefined;

  const organizationResult = _tryRenderApartFromOrganization(
    data,
    filter,
    renderFunction
  );

  const graspResult = _tryRenderApartFromGrasp(data, filter, renderFunction);

  if (organizationResult && graspResult) return organizationResult;

  const selectedGraspOptions = (graspFilter?.options ?? [])
    .filter((p) => p.selected)
    .map((p) => p.id);

  const selectedGraspOrganizations = queries.grasps
    .filter((p) => selectedGraspOptions.includes(p.id))
    .map((p) => p.organization_id ?? '');

  let selectedOrganizations = (organizationFilter?.options ?? [])
    .filter((p) => p.selected)
    .map((p) => p.id);

  selectedOrganizations = uniq([
    ...selectedOrganizations,
    ...selectedGraspOrganizations,
  ]);

  const selectedMembers = queries.membersInOrganizations
    .filter((p) => selectedOrganizations.includes(p.organizationId))
    .map((p) => p.memberId);

  return renderFunction(
    filter,
    filter.options.filter((p) => selectedMembers.includes(p.id))
  );
};

export const renderGraspFilter = (
  data: DashboardDataStates.DashboardDataState,
  queries: DashboardQueriesContextType,
  filter: IGraspDashboardFilter,
  renderFunction: RenderFilterFunction<IGraspDashboardFilter>
) => {
  const organizationFilter = firstFilterOfType(
    data,
    DashboardFilterType.Organization
  ) as IOrganizationDashboardFilter | undefined;

  const memberFilter = firstFilterOfType(data, DashboardFilterType.Member) as
    | IMemberDashboardFilter
    | undefined;

  const organizationResult = _tryRenderApartFromOrganization(
    data,
    filter,
    renderFunction
  );
  const memberResult = _tryRenderApartFromMember(data, filter, renderFunction);

  if (organizationResult && memberResult) return organizationResult;

  const selectedMembers = (memberFilter?.options ?? [])
    .filter((p) => p.selected)
    .map((p) => p.id);

  const selectedMembersOrganizations = queries.membersInOrganizations
    .filter((p) => selectedMembers.includes(p.memberId))
    .map((p) => p.organizationId);

  let selectedOrganizations = (organizationFilter?.options ?? [])
    .filter((p) => p.selected)
    .map((p) => p.id);

  selectedOrganizations = uniq([
    ...selectedOrganizations,
    ...selectedMembersOrganizations,
  ]);

  const selectedGrasps = queries.grasps
    .filter((p) => selectedOrganizations.includes(p.organization_id ?? ''))
    .map((p) => p.id);

  return renderFunction(
    filter,
    filter.options.filter((p) => selectedGrasps.includes(p.id))
  );
};

export const renderOrganizationFilter = (
  data: DashboardDataStates.DashboardDataState,
  queries: DashboardQueriesContextType,
  filter: IOrganizationDashboardFilter,
  renderFunction: RenderFilterFunction<IOrganizationDashboardFilter>
) => {
  const memberFilter = firstFilterOfType(data, DashboardFilterType.Member) as
    | IMemberDashboardFilter
    | undefined;

  const graspFilter = firstFilterOfType(data, DashboardFilterType.Grasp) as
    | IGraspDashboardFilter
    | undefined;

  const memberResult = _tryRenderApartFromMember(data, filter, renderFunction);
  const graspResult = _tryRenderApartFromGrasp(data, filter, renderFunction);

  if (memberResult && graspResult) return memberResult;

  const selectedMemberOptions = (memberFilter?.options ?? [])
    .filter((p) => p.selected)
    .map((p) => p.id);

  const selectedGraspOptions = (graspFilter?.options ?? [])
    .filter((p) => p.selected)
    .map((p) => p.id);

  const selectedMembers = queries.membersInOrganizations.filter((p) =>
    selectedMemberOptions.includes(p.memberId)
  );

  const selectedGrasps = queries.grasps.filter((p) =>
    selectedGraspOptions.includes(p.id)
  );

  let membersOrganizations = selectedMembers.map((p) => p.organizationId);
  membersOrganizations.push(
    ...selectedGrasps.map((p) => p.organization_id ?? '')
  );

  membersOrganizations = uniq(membersOrganizations);

  return renderFunction(
    filter,
    filter.options.filter((p) => membersOrganizations.includes(p.id))
  );
};

const _tryRenderApartFromOrganization = <T extends DashboardFilterType>(
  data: DashboardDataStates.DashboardDataState,
  filter: INonQuestionFilter<T>,
  renderFunction: RenderFilterFunction<INonQuestionFilter<T>>
) => {
  const organizationFilter = firstFilterOfType(
    data,
    DashboardFilterType.Organization
  ) as IOrganizationDashboardFilter | undefined;

  const globalSelected = DashboardSelectionHelpers.IsGlobalSelected(
    data.template.filters
  );

  if (
    !organizationFilter ||
    (everySelected(organizationFilter) && globalSelected) ||
    (noneSelected(organizationFilter) && !globalSelected)
  ) {
    return renderFunction(filter, filter.options);
  }

  return undefined;
};

const _tryRenderApartFromMember = <T extends DashboardFilterType>(
  data: DashboardDataStates.DashboardDataState,
  filter: INonQuestionFilter<T>,
  renderFunction: RenderFilterFunction<INonQuestionFilter<T>>
) => {
  const memberFilter = firstFilterOfType(data, DashboardFilterType.Member) as
    | IMemberDashboardFilter
    | undefined;

  const globalSelected = DashboardSelectionHelpers.IsGlobalSelected(
    data.template.filters
  );

  if (
    !memberFilter ||
    (everySelected(memberFilter) && globalSelected) ||
    (noneSelected(memberFilter) && !globalSelected)
  )
    return renderFunction(filter, filter.options);

  return undefined;
};

const _tryRenderApartFromGrasp = <T extends DashboardFilterType>(
  data: DashboardDataStates.DashboardDataState,
  filter: INonQuestionFilter<T>,
  renderFunction: RenderFilterFunction<INonQuestionFilter<T>>
) => {
  const graspFilter = firstFilterOfType(data, DashboardFilterType.Grasp) as
    | IGraspDashboardFilter
    | undefined;

  const globalSelected = DashboardSelectionHelpers.IsGlobalSelected(
    data.template.filters
  );

  if (
    !graspFilter ||
    (everySelected(graspFilter) && globalSelected) ||
    (noneSelected(graspFilter) && !globalSelected)
  )
    return renderFunction(filter, filter.options);

  return undefined;
};
