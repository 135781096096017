import React from 'react';
import CaseGroupCollectionItem from '../../../../../../../../components/CaseGroupCollectionItem';
import Skeleton from 'react-loading-skeleton';

const CaseGroupDetailsSessionsLoading = () => {
  return (
    <div className="ManageGroupCases CaseCard__tab-container pt-3 px-3">
      <div className="ManageGroupCases__header">
        <div className="ManageGroupCases__actions"></div>
      </div>
      <div className="ManageGroupCases__cases-list">
        <CaseGroupCollectionItem
          icon={<Skeleton width={50} height={50} />}
          title={<Skeleton width={250} />}
          info={<Skeleton width={150} />}
          actions={[]}
        />
        <CaseGroupCollectionItem
          icon={<Skeleton width={50} height={50} />}
          title={<Skeleton width={250} />}
          info={<Skeleton width={150} />}
          actions={[]}
        />
        <CaseGroupCollectionItem
          icon={<Skeleton width={50} height={50} />}
          title={<Skeleton width={250} />}
          info={<Skeleton width={150} />}
          actions={[]}
        />
        <CaseGroupCollectionItem
          icon={<Skeleton width={50} height={50} />}
          title={<Skeleton width={250} />}
          info={<Skeleton width={150} />}
          actions={[]}
        />
        <CaseGroupCollectionItem
          icon={<Skeleton width={50} height={50} />}
          title={<Skeleton width={250} />}
          info={<Skeleton width={150} />}
          actions={[]}
        />
        <CaseGroupCollectionItem
          icon={<Skeleton width={50} height={50} />}
          title={<Skeleton width={250} />}
          info={<Skeleton width={150} />}
          actions={[]}
        />
      </div>
    </div>
  );
};

export default CaseGroupDetailsSessionsLoading;
