import { faSearch } from '@fortawesome/pro-light-svg-icons';
import DebouncedInput from 'components/DebouncedInput';
import { ReactStateTuple } from 'interfaces/ui';
import React from 'react';

type SearchConversationInputProps = {
  quickSearchState: ReactStateTuple<string | undefined>;
};

const SearchConversationInput = ({
  quickSearchState,
}: SearchConversationInputProps) => {
  const [, setQuickSearch] = quickSearchState;
  const onDebounce = (value: string) => setQuickSearch(value);

  return (
    <DebouncedInput
      inputIconSrc={faSearch}
      labelTranslationId="Cases.ChangeCase.ChangeCaseConversations.ChangeCaseConversationsFilters.searchConversation"
      name="search"
      onDebounce={onDebounce}
    />
  );
};

export default SearchConversationInput;
