import React from 'react';
import TrendsChartDownload from './components/TrendsChartDownload';
import TrendsChartSwitch from './components/TrendsChartSwitch';
import {
  TrendsChartConfigAction,
  TrendsTemplateConfigAction,
} from '../../types';
import TrendsChartTemplateAction from './components/TrendsChartTemplateAction';

type TrendsChartActionsProps = {
  config?: TrendsChartConfigAction[];
};

const TrendsChartActions = ({ config }: TrendsChartActionsProps) => {
  const downloadAction = config?.find((p) => p.type === 'download');

  const trendsTemplateActions = (config?.filter((p) => p.type !== 'download') ??
    []) as TrendsTemplateConfigAction[];

  return (
    <div className="d-flex align-items-center gap-2">
      <div className="TrendsChartSwitch">
        <TrendsChartSwitch />
      </div>
      {downloadAction && <TrendsChartDownload />}
      {trendsTemplateActions.map((action) => (
        <TrendsChartTemplateAction action={action} />
      ))}
    </div>
  );
};

export default TrendsChartActions;
