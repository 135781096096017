import { useCaseGroupConversationScheduleData } from './useCaseGroupConversationScheduleData';
import { useCallback, useMemo } from 'react';
import { ScheduleItemDefinition } from '../../../../../../../../../components/Schedule';
import { useCaseGroupDetailsCommonData } from '../../../../../hooks/useCaseGroupDetailsCommonData';
import { usePrepareSchedules } from './usePrepareSchedules';
import { ICaseGroupConversationSchedule } from '../../../../../../../../../interfaces/conversationSchedules';
import { cloneDeep } from 'lodash';
import { useInitQuery } from '../../../../../../../../../hooks/queries/useInitQuery';
import { filterSchedulesForDate } from '../../../../../../../../../helpers/utils/conversationSchedules';

export const useCaseGroupConversationScheduleItems = (
  phaseId: string,
  selectedDate: Date,
  setSchedule: (schedule: ICaseGroupConversationSchedule | null) => void,
  setResponsesId: (responsesId: string | null) => void,
  toggleEditTime: () => void,
  toggleResponses: () => void
) => {
  const { caseGroup, conversationTemplates } = useCaseGroupDetailsCommonData();

  const { caseGroupConversationSchedules } =
    useCaseGroupConversationScheduleData(phaseId);

  const { prepareOnceSchedule, prepareSingleInstanceSchedule } =
    usePrepareSchedules(
      setSchedule,
      setResponsesId,
      toggleEditTime,
      toggleResponses
    );

  useInitQuery(caseGroupConversationSchedules);

  const schedulesForDateFilter = useCallback(
    (scheduleProvided: ICaseGroupConversationSchedule) => {
      return filterSchedulesForDate(scheduleProvided, selectedDate);
    },
    [selectedDate]
  );

  const scheduleItems = useMemo(() => {
    if (!caseGroupConversationSchedules.data) return [];

    let _items: ScheduleItemDefinition[] = [];

    const groupSchedules = caseGroup.data?.group_schedule ?? [];

    const phaseGroupSchedule = groupSchedules.filter(
      (p) => p.phase_id === phaseId
    )[0];

    let caseGroupSchedulesData = cloneDeep(caseGroupConversationSchedules.data);
    caseGroupSchedulesData = caseGroupSchedulesData.filter(
      schedulesForDateFilter
    );

    caseGroupSchedulesData.forEach((schedule, index) => {
      _items = prepareOnceSchedule(_items, schedule, selectedDate, index);
      _items = prepareSingleInstanceSchedule(
        _items,
        schedule,
        caseGroupConversationSchedules.data,
        phaseGroupSchedule,
        selectedDate,
        index
      );
    });

    _items.sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0));
    return _items;
  }, [
    caseGroup.data?.group_schedule,
    caseGroupConversationSchedules.data,
    phaseId,
    prepareOnceSchedule,
    prepareSingleInstanceSchedule,
    schedulesForDateFilter,
    selectedDate,
  ]);

  return {
    items: scheduleItems,
    isLoading:
      caseGroup.isLoading ||
      conversationTemplates.isLoading ||
      caseGroupConversationSchedules.isLoading,
  };
};
