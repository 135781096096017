import { getUserExternalLogins } from 'api/membership';
import QueryKeys from 'api/queries/queryKeys';
import { UserQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { useQuery } from 'react-query';

const useUserExternalLogins = ({
  params,
  options,
}: IQueryRequest<UserQueryParams>) => {
  const { userId } = params;
  return useQuery<any>({
    ...options,
    queryFn: async () => {
      const { data } = await getUserExternalLogins(userId);
      return data.data;
    },
    queryKey: [QueryKeys.getUserExternalLogins, userId],
  });
};

export default useUserExternalLogins;
