import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { faPlus, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Form } from 'formik';
import {
  Alert,
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';

//interfaces
import { CaseFormErrors, CaseFormType } from 'interfaces/cases';

//helpers
import useOrganizationSymptoms from 'api/queries/symptoms/useOrganizationSymptoms';

//components
import { FormattedMessage } from 'react-intl';
import { ICaseSymptomDefinitionWithDefaultInformation } from 'interfaces/symptoms';
import SwitchController from 'components/SwitchController';
import classNames from 'classnames';
import { CUSTOM_MEANINGS_ERRORS } from '../index';
import { isNorwegian } from 'translations/utils/cultures';
import useRequiredParams from 'hooks/useRequiredParams';

type ObservationMeaningStepProps = {
  setValues: (data: CaseFormType) => void;
  values: CaseFormType;
  errors: Partial<CaseFormErrors>;
};

const SymptomStep = ({
  setValues,
  values,
  errors,
}: ObservationMeaningStepProps) => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const [observationMeaningToAdd, setObservationMeaningToAdd] = useState('');

  const onObservationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setObservationMeaningToAdd(e.target.value);
  };

  const { data: organizationObservationMeanings } = useOrganizationSymptoms({
    params: { organizationId },
  });

  const options = organizationObservationMeanings?.map(
    ({ symptom_id, symptom }) => (
      <option key={symptom_id} value={symptom_id}>
        {isNorwegian() ? symptom.name_no : symptom.name}
      </option>
    )
  );

  const onAddItem = () => {
    const selectedMeaning = organizationObservationMeanings?.find(
      (el) => el.symptom_id === observationMeaningToAdd
    );

    const definitions = [
      ...values.definitions,
      {
        symptom_id: observationMeaningToAdd,
        patient_symptom: isNorwegian()
          ? selectedMeaning?.symptom.name_no ?? ''
          : selectedMeaning?.symptom.name ?? '',
        isDefault: false,
      },
    ];

    setValues({
      ...values,
      definitions,
    });
  };

  const onRemoveItem = useCallback(
    (index: number) => {
      const copyOfDefinitions = [...values.definitions];

      const filteredCopyOfDefinitions = copyOfDefinitions.filter(
        (_e, indexOfElement) => index !== indexOfElement
      );

      setValues({
        ...values,
        definitions: filteredCopyOfDefinitions,
      });
    },
    [values, setValues]
  );

  const onSymptomNameChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
      const copyOfValues = {
        ...values,
      };

      copyOfValues.definitions[index] = {
        symptom_id: copyOfValues.definitions[index].symptom_id,
        patient_symptom: e.target.value,
        isDefault: copyOfValues.definitions[index].isDefault,
        isToggled: copyOfValues.definitions[index].isToggled,
      };

      setValues(copyOfValues);
    },
    [values, setValues]
  );

  const onMeaningChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
      const copyOfValues = {
        ...values,
      };

      copyOfValues.definitions[index] = {
        symptom_id: e.target.value,
        patient_symptom: copyOfValues.definitions[index].patient_symptom,
        isDefault: copyOfValues.definitions[index].isDefault,
        isToggled: copyOfValues.definitions[index].isToggled,
      };

      setValues(copyOfValues);
    },
    [values, setValues]
  );

  const { additionalRows, defaultRows } = useMemo(() => {
    const defaultRows: JSX.Element[] = [];
    const additionalRows: JSX.Element[] = [];

    values.definitions.forEach(
      (
        { symptom_id, patient_symptom, isToggled = false, isDefault },
        index
      ) => {
        const onToggle = () => {
          const copyOfDefinitions = [...values.definitions];

          copyOfDefinitions[index].isToggled =
            !copyOfDefinitions[index].isToggled;

          setValues({
            ...values,
            definitions: copyOfDefinitions,
          });
        };

        const rowElement = (
          <Row className="p-2" key={symptom_id}>
            <Col
              md={2}
              className={classNames({
                'text-center': !isDefault,
              })}
            >
              {isDefault ? (
                <SwitchController isToggled={isToggled} onToggle={onToggle} />
              ) : (
                <Button color="danger" onClick={() => onRemoveItem(index)}>
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              )}
            </Col>
            <Col md={5}>
              <Input
                type="select"
                value={symptom_id}
                disabled={isDefault}
                onChange={(e) => onMeaningChange(e, index)}
                className={classNames({
                  ObservationMeaning__inactive: isDefault,
                })}
              >
                <option value=""></option>
                {options}
              </Input>
            </Col>
            <Col md={5}>
              <Input
                value={patient_symptom}
                onChange={(e) => onSymptomNameChange(e, index)}
                disabled={isDefault && !isToggled}
                className={classNames({
                  ObservationMeaning__inactive: isDefault && !isToggled,
                })}
              />
            </Col>
          </Row>
        );

        if (isDefault) {
          defaultRows.push(rowElement);
        } else {
          additionalRows.push(rowElement);
        }
      }
    );

    return { defaultRows, additionalRows };
  }, [
    values,
    setValues,
    onRemoveItem,
    onSymptomNameChange,
    options,
    onMeaningChange,
  ]);

  useEffect(() => {
    if (!values.isDefinitionInitialized && !!organizationObservationMeanings) {
      const initialDefinitions: ICaseSymptomDefinitionWithDefaultInformation[] =
        organizationObservationMeanings?.map((el) => {
          return {
            symptom_id: el.symptom_id,
            patient_symptom: isNorwegian()
              ? el.symptom.name_no
              : el.symptom.name,
            isDefault: true,
            isToggled: true,
          };
        }) ?? [];

      const modifiedInitialValues = {
        ...values,
        definitions: initialDefinitions,
        isDefinitionInitialized: true,
      };

      setValues(modifiedInitialValues);
    }
  }, [organizationObservationMeanings, values, setValues]);

  let errorAlert: JSX.Element | null = null;

  if (errors.definitions === CUSTOM_MEANINGS_ERRORS.NO_DUPLICATE_NAMES) {
    errorAlert = (
      <Alert color="danger">
        <FormattedMessage id="ObservationMeaning.duplicateSymptomName" />
      </Alert>
    );
  } else if (errors.definitions === CUSTOM_MEANINGS_ERRORS.NO_EMPTY) {
    errorAlert = (
      <Alert color="danger">
        <FormattedMessage id="ObservationMeaning.emptySymptomNameOrMeaning" />
      </Alert>
    );
  }

  return (
    <Container className="mb-4">
      {errorAlert}
      <h3 className="ObservationMeaning__header">
        <FormattedMessage id="ObservationMeaning.defaultObservationMeanings" />
      </h3>
      {defaultRows && defaultRows.length > 0 ? (
        <>
          <Row>
            <Col md={2} className="text-center ObservationMeaning__row-heading">
              <FormattedMessage id="ObservationMeaning.headers.active" />
            </Col>
            <Col md={5} className="text-center ObservationMeaning__row-heading">
              <FormattedMessage id="ObservationMeaning.headers.observationMeaning" />
            </Col>
            <Col md={5} className="text-center ObservationMeaning__row-heading">
              <FormattedMessage id="ObservationMeaning.headers.symptomName" />
            </Col>
          </Row>
          {defaultRows}
        </>
      ) : (
        <Alert color="primary">
          <FormattedMessage id="ObservationMeaning.noObservationMeaningSelected" />
        </Alert>
      )}
      <h3 className="ObservationMeaning__header">
        <FormattedMessage id="ObservationMeaning.additionalObservationMeanings" />
      </h3>
      {additionalRows && additionalRows.length > 0 ? (
        <>
          <Row>
            <Col md={2} className="text-center ObservationMeaning__row-heading">
              <FormattedMessage id="ObservationMeaning.headers.delete" />
            </Col>
            <Col md={5} className="text-center ObservationMeaning__row-heading">
              <FormattedMessage id="ObservationMeaning.headers.observationMeaning" />
            </Col>
            <Col md={5} className="text-center ObservationMeaning__row-heading">
              <FormattedMessage id="ObservationMeaning.headers.symptomName" />
            </Col>
          </Row>
          {additionalRows}
        </>
      ) : (
        <Alert color="primary">
          <FormattedMessage id="ObservationMeaning.noObservationMeaningSelected" />
        </Alert>
      )}
      <Form className="mt-4">
        <FormGroup>
          <Label>
            <FormattedMessage id="ObservationMeaning.selectObservationMeaningToAdd" />
          </Label>
          <Row>
            <Col md={9}>
              <Input
                type="select"
                value={observationMeaningToAdd}
                onChange={onObservationChange}
              >
                <option value={''}></option>
                {options}
              </Input>
            </Col>
            <Col
              md={{
                offset: 1,
                size: 1,
              }}
            >
              <Button
                color="primary"
                onClick={onAddItem}
                type="button"
                disabled={observationMeaningToAdd === ''}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </Col>
          </Row>
        </FormGroup>
      </Form>
    </Container>
  );
};

export default SymptomStep;
