import React from 'react';
import InlineDaySelector from '../../../../../../../../../../../../components/DateSelector/InlineDaySelector';
import { useCommonData } from '../../hooks/useCommonData';
import CaseConversationsScheduleDayDescription from './components/CaseConversationsScheduleDayDescription';
import { useCaseConversationsScheduleDateNavigation } from './hooks/useCaseConversationsScheduleDateNavigation';
import { useCaseConversationsScheduleDatePhaseDescription } from './hooks/useCaseConversationsScheduleDatePhaseDescription';

type CaseConversationsScheduleHeaderProps = {
  selectedDate: string;
  selectDate: React.Dispatch<React.SetStateAction<string>>;
};

const ScheduleHeader = ({
  selectedDate,
  selectDate,
}: CaseConversationsScheduleHeaderProps) => {
  const { handleNext, handlePrev } = useCaseConversationsScheduleDateNavigation(
    selectedDate,
    selectDate
  );

  const phaseDescription =
    useCaseConversationsScheduleDatePhaseDescription(selectedDate);

  const { casePhaseHistory } = useCommonData();

  return (
    <div className="CaseConversationsScheduleContent__header mt-2">
      <div className="CaseConversationsScheduleContent__day-selection">
        <InlineDaySelector
          handlePrev={handlePrev}
          handleNext={handleNext}
          date={selectedDate}
          isLoading={casePhaseHistory.isLoading}
        />
        <div className="d-flex gap-2">
          {(phaseDescription.length > 2
            ? [phaseDescription[0], phaseDescription[1]]
            : phaseDescription
          ).map((item, index) => (
            <CaseConversationsScheduleDayDescription
              key={item.displayCode}
              definition={item}
              showMore={phaseDescription.length > 2 && index === 1}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScheduleHeader;
