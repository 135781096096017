import { useMemo } from 'react';
import { IAwareSessionData } from '../../../../../../../../../interfaces/awareSessions';
import { ConversationQuestion } from '../../../../../../../../../interfaces/conversations';
import { useQuestionTitle } from '../../../../../../../../../hooks/data/useQuestionTitle';

export const useHeaderItems = (
  awareSessions: IAwareSessionData[] | undefined,
  questions: ConversationQuestion[]
) => {
  const getQuestionTitle = useQuestionTitle();

  return useMemo(() => {
    if (!awareSessions || awareSessions.length === 0) return [];

    let headers: string[] = ['Cases.ScheduledData', 'Cases.PatientNumber'];

    const firstAwareSession = awareSessions[0];

    firstAwareSession.questions.forEach((question) => {
      const existingQuestion = questions.find(
        (p) => p.resource_id === question.id
      );

      if (!existingQuestion) {
        headers.push(question.id);
        return;
      }

      headers.push(getQuestionTitle(existingQuestion));
    });

    return headers;
  }, [awareSessions, getQuestionTitle, questions]);
};
