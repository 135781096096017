import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTranslations } from '../../../../../../../../../../../../hooks/useTranslations';
import { useTypedContext } from '../../../../../../../../../../../../hooks/useTypedContext';
import {
  DashboardFilter,
  DashboardFilterType,
  IClosedListDashboardFilter,
  INonQuestionFilter,
} from '../../../../../../../../../../../../interfaces/dashboards';
import {
  DashboardDataReducerContext,
  DashboardQueriesContext,
  DashboardTheme,
} from '../../../../../../types';
import { useToggleOptionItem } from '../../hooks/useToggleOptionItem';
import AwareCasesFilterItem from '../FilterItem';
import {
  renderGraspFilter,
  renderMemberFilter,
  renderOrganizationFilter,
} from './helpers';
import { useFilterItemDescription } from './hooks/useFilterItemDescription';
import { useHandleClearAll } from './hooks/useHandleClearAll';

type FilterBodyProps = {
  filter: DashboardFilter;
};

const FilterBody = ({ filter }: FilterBodyProps) => {
  const [dashboardData] = useTypedContext(DashboardDataReducerContext);
  const queries = useTypedContext(DashboardQueriesContext);

  const { attachPrefix } = useTranslations(
    'Organizations.OrganizationDashboard.AwareCasesDashboard.DashboardFilters.DashboardFilter.FilterBody'
  );

  const { getFilterItemDescription } = useFilterItemDescription();
  const { toggle } = useToggleOptionItem();

  const handleClearAll = useHandleClearAll(filter);

  const getNonQuestionFilter = useCallback(
    (
      filter: INonQuestionFilter<any>,
      options: {
        id: string;
        selected: boolean;
      }[]
    ) => {
      return options.map((option) => {
        const description = getFilterItemDescription(filter, option.id);
        const handleToggle = () => toggle(filter.type, undefined, option.id);

        return (
          <AwareCasesFilterItem
            key={option.id}
            selected={option.selected}
            onToggle={handleToggle}
          >
            {description}
          </AwareCasesFilterItem>
        );
      });
    },
    [getFilterItemDescription, toggle]
  );

  const getClosedListFilter = useCallback(
    (
      filter: IClosedListDashboardFilter,
      options: {
        id: string;
        selected: boolean;
      }[]
    ) => {
      return options.map((option) => {
        const description = getFilterItemDescription(filter, option.id);
        const handleToggle = () => toggle(filter.type, filter.id, option.id);

        return (
          <AwareCasesFilterItem
            key={option.id}
            selected={option.selected}
            onToggle={handleToggle}
          >
            {description}
          </AwareCasesFilterItem>
        );
      });
    },
    [getFilterItemDescription, toggle]
  );

  const renderOptions = useMemo(() => {
    if (filter.type === DashboardFilterType.Range) return [];

    if (filter.type === DashboardFilterType.ClosedList)
      return getClosedListFilter(filter, filter.options);

    if (filter.type === DashboardFilterType.Member)
      return renderMemberFilter(
        dashboardData,
        queries,
        filter,
        getNonQuestionFilter
      );

    if (filter.type === DashboardFilterType.Grasp)
      return renderGraspFilter(
        dashboardData,
        queries,
        filter,
        getNonQuestionFilter
      );

    if (filter.type === DashboardFilterType.Organization)
      return renderOrganizationFilter(
        dashboardData,
        queries,
        filter,
        getNonQuestionFilter
      );

    if (filter.type === DashboardFilterType.SourcePeriod)
      return getNonQuestionFilter(filter, filter.options);
  }, [
    dashboardData,
    filter,
    getClosedListFilter,
    getNonQuestionFilter,
    queries,
  ]);

  if (filter.type === DashboardFilterType.Range)
    return <div className="AwareCasesFilterBody"></div>;

  return (
    <div
      className={classNames('AwareCasesFilterBody', {
        'AwareCasesFilterBody--dark':
          dashboardData.theme === DashboardTheme.Dark,
      })}
    >
      <div className="AwareCasesFilterBody__options">
        {renderOptions}
        <span
          className="AwareCasesFilterBody__clear-all"
          onClick={handleClearAll}
        >
          <FormattedMessage id={attachPrefix('clearAll')} />
        </span>
      </div>
    </div>
  );
};

export default FilterBody;
