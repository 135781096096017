import { DashboardTemplate } from '../../../../../../../../../../interfaces/dashboards';
import { DashboardTemplateContainerProps } from '../../../../index';
import useBasePath from '../../../../../../../../../../hooks/paths/useBasePath';

type FromTemplateProps = DashboardTemplateContainerProps & {
  templates: DashboardTemplate[];
};

const FromTemplate = ({
  templates,
  onNotFound,
  children,
}: FromTemplateProps) => {
  const basePath = useBasePath();

  const currentTemplate = templates.find(
    (p) => p.path.toLowerCase() === (basePath ?? '').toLowerCase()
  );

  if (!currentTemplate) return <>{onNotFound}</>;
  return children(currentTemplate);
};

export default FromTemplate;
