import {
  IConversationTreatmentPhaseContentItem,
  TreatmentPhaseContentItemType,
} from 'interfaces/conversations';
import {
  ConversationScheduleType,
  ICreateCaseConversationSchedule,
} from 'interfaces/conversationSchedules';
import { useCallback } from 'react';
import { useModalsUtils } from '../../../hooks/useModalsUtils';

export const useAddTemplateModalPayload = () => {
  const { getDateBasedOnCasePhaseHistory, normalizeIsoDate } = useModalsUtils();

  const prepareInitialValues = (): IConversationTreatmentPhaseContentItem => {
    return {
      type: TreatmentPhaseContentItemType.ConversationSchedule,
      template_id: '',
      priority: 0,
      schedule_templates: [
        {
          schedule_type: ConversationScheduleType.Once,
          end_day_offset: null,
          recurring_interval: null,
          start_day_offset: null,
          specific_day_of_phase: null,
          parts_of_a_day: [],
        },
      ],
    };
  };

  const prepareSimplePayload = useCallback(
    (
      values: IConversationTreatmentPhaseContentItem,
      type:
        | ConversationScheduleType.Once
        | ConversationScheduleType.NonTimeBased
    ): ICreateCaseConversationSchedule => {
      return {
        template_id: values.template_id,
        priority: values.priority,
        notification_hour: null,
        available_from_hour: null,
        available_to_hour: null,
        recurring_interval: null,
        date_from: null,
        date_to: null,
        date: null,
        recurring_origin_id: null,
        type: type,
        is_dirty: false,
        is_completed: false,
      };
    },
    []
  );

  const prepareSingleInstancePayload = useCallback(
    (
      values: IConversationTreatmentPhaseContentItem
    ): ICreateCaseConversationSchedule => {
      let date = getDateBasedOnCasePhaseHistory(
        values.schedule_templates[0].specific_day_of_phase
      );

      if (date) date = normalizeIsoDate(date);

      return {
        template_id: values.template_id,
        priority: values.priority,
        notification_hour:
          values.schedule_templates[0].parts_of_a_day[0].notification_hour,
        available_from_hour:
          values.schedule_templates[0].parts_of_a_day[0].available_from_hour,
        available_to_hour:
          values.schedule_templates[0].parts_of_a_day[0].available_to_hour,
        recurring_interval: null,
        date_from: null,
        date_to: null,
        date: date,
        type: ConversationScheduleType.SingleInstance,
        recurring_origin_id: null,
        is_dirty: false,
        is_completed: false,
      };
    },
    [getDateBasedOnCasePhaseHistory, normalizeIsoDate]
  );

  const prepareRecurringPayload = useCallback(
    (
      values: IConversationTreatmentPhaseContentItem
    ): ICreateCaseConversationSchedule[] => {
      const partsOfADay = values.schedule_templates[0].parts_of_a_day;
      let schedules: ICreateCaseConversationSchedule[] = [];

      for (const partOfADay of partsOfADay) {
        let dateFrom = getDateBasedOnCasePhaseHistory(
          values.schedule_templates[0].start_day_offset
        );
        let dateTo = getDateBasedOnCasePhaseHistory(
          values.schedule_templates[0].end_day_offset
        );

        if (dateFrom) dateFrom = normalizeIsoDate(dateFrom);
        if (dateTo) dateTo = normalizeIsoDate(dateTo);

        schedules.push({
          template_id: values.template_id,
          priority: values.priority,
          notification_hour: partOfADay.notification_hour,
          available_from_hour: partOfADay.available_from_hour,
          available_to_hour: partOfADay.available_to_hour,
          recurring_interval: values.schedule_templates[0].recurring_interval,
          date_from: dateFrom,
          date_to: dateTo,
          date: null,
          type: ConversationScheduleType.Recurring,
          recurring_origin_id: null,
          is_dirty: false,
          is_completed: false,
        });
      }

      return schedules;
    },
    [getDateBasedOnCasePhaseHistory, normalizeIsoDate]
  );

  return {
    prepareInitialValues: prepareInitialValues,
    prepareSimplePayload: prepareSimplePayload,
    prepareSingleInstancePayload: prepareSingleInstancePayload,
    prepareRecurringPayload: prepareRecurringPayload,
  };
};
