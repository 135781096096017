import { FormikProps } from 'formik';
import { AwareCaseDetailsFormValues } from '../../../../../forms/AwareCaseDetailsForm/types';
import { useMemo } from 'react';

export const useProcedureDisabled = (
  form: FormikProps<AwareCaseDetailsFormValues>,
  procedureIndex: number
) => {
  return useMemo(() => {
    return (
      (form.values.procedures[procedureIndex]?.activities?.length ?? 0) > 0
    );
  }, [form.values.procedures, procedureIndex]);
};
