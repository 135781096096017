import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import useDeleteLocationInOrganization from 'api/mutations/organizations/useDeleteLocationInOrganization';
import useInfiniteOrganizationLocations from 'api/queries/organizations/useInfiniteOrganizationLocations';
import ConfirmModal from 'components/ConfirmModal';
import CustomTable from 'components/CustomTable';
import useOpen from 'hooks/useOpen';
import useRequiredParams from 'hooks/useRequiredParams';
import { EditedEndpointItem } from 'interfaces/membership';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { useIntl } from 'react-intl';
import { Spinner } from 'reactstrap';
import IconButton from '../../../../../../components/IconButton';
import TabLayout from '../../../../../../components/layouts/TabLayout';
import { CommonTabProps } from '../types';
import LocationModal from './components/LocationModal';

const headerItems = [
  'MyOrganizations.endpoints.name',
  'MyOrganizations.endpoints.graspNumber',
  null,
];

const LocationsTab = ({ type }: CommonTabProps) => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const intl = useIntl();
  const { open: openModal, isOpen, close: closeModal } = useOpen();

  const [editedEndpointItem, setEditedEndpointItem] =
    useState<EditedEndpointItem | null>(null);
  const [endpointIdToDelete, setEndpointIdToDelete] = useState<string | null>(
    null
  );

  const { locations, hasMore, fetchNextPage, refetch } =
    useInfiniteOrganizationLocations({
      params: {
        organizationId,
        pageSize: 100,
      },
    });

  const onDeleteSuccess = async () => {
    await refetch();
  };
  const { mutate: deleteLocationMutation } = useDeleteLocationInOrganization(
    organizationId,
    {
      successFb: onDeleteSuccess,
    }
  );

  const onCloseDeleteModal = () => setEndpointIdToDelete(null);
  const onConfirm = () => {
    endpointIdToDelete && deleteLocationMutation(endpointIdToDelete);
    onCloseDeleteModal();
  };
  const toggleEndpointModal = () => {
    setEditedEndpointItem(null);
    closeModal();
  };

  const bodyRows = locations.map(({ name, grasps_count, id, description }) => {
    return (
      <tr key={id}>
        <td>{name}</td>
        <td>{grasps_count}</td>
        <td>
          <IconButton
            aria-label="deleteButton"
            color="primary"
            variant="outlined"
            onClick={() => setEndpointIdToDelete(id)}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
          <IconButton
            aria-label="editButton"
            variant="outlined"
            color="primary"
            onClick={() => {
              setEditedEndpointItem({ id, name, description });
              openModal();
            }}
          >
            <EditOutlinedIcon />
          </IconButton>
        </td>
      </tr>
    );
  });

  const isDeleteOpen = !!endpointIdToDelete;

  const loadMore = () => fetchNextPage();

  return (
    <>
      <ConfirmModal
        isOpen={isDeleteOpen}
        confirm={onConfirm}
        message={intl.formatMessage({
          id: 'MyOrganizations.endpoints.deleteMessage',
        })}
        toggle={onCloseDeleteModal}
      />
      <LocationModal
        organizationId={organizationId}
        toggleModal={toggleEndpointModal}
        isOpen={isOpen}
        editedValues={editedEndpointItem}
      />
      <TabLayout
        type={type}
        titlePrefix="Organizations.Locations"
        actions={
          <IconButton color="primary" variant="outlined" onClick={openModal}>
            <AddIcon />
          </IconButton>
        }
      >
        <div>
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={hasMore}
            loader={<Spinner key="spinner" />}
            threshold={100}
            initialLoad={false}
          >
            <div className="d-flex flex-column mt-3" style={{ padding: 0 }}>
              <CustomTable headerItems={headerItems} bodyRows={bodyRows} />
            </div>
          </InfiniteScroll>
        </div>
      </TabLayout>
    </>
  );
};

export default LocationsTab;
