import { useTheme } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import { useTranslations } from '../../../../../../../../../../../../../../hooks/useTranslations';
import { useTypedContext } from '../../../../../../../../../../../../../../hooks/useTypedContext';
import {
  DashboardDataReducerContext,
  DashboardTheme,
} from '../../../../../../../../types';

const LEGEND_LENGTH_CONSTRAINT = 9;

export type VerticalLegendProps = {
  sequence: number;
  [prop: string]: any;
};

const VerticalLegend = <T extends VerticalLegendProps>(props: T) => {
  const theme = useTheme();
  const [dashboardData] = useTypedContext(DashboardDataReducerContext);

  const { attachPrefix, wrapWithIntl } = useTranslations(
    'Organizations.OrganizationDashboard.AwareCasesDashboard.AwareCasesContent.Visualizations.ChartView.VerticalLegend'
  );

  const sortedLegend = props.payload ?? [];

  return (
    <div className="AwareCasesVerticalLegend">
      <div className="AwareCasesVerticalLegend__container">
        {sortedLegend.map((item, index) => {
          if (index >= LEGEND_LENGTH_CONSTRAINT) return <></>;

          return (
            <div
              key={item.value}
              style={{ color: theme.palette.text.primary }}
              className="AwareCasesVerticalLegend__item AwareCasesVerticalLegendItem"
            >
              <div
                className="AwareCasesVerticalLegendItem__circle"
                style={{ background: item.color }}
              />
              <span className="AwareCasesVerticalLegendItem__title">
                {item.value}
              </span>
            </div>
          );
        })}
        {sortedLegend.length > LEGEND_LENGTH_CONSTRAINT && (
          <>
            <div
              className="AwareCasesVerticalLegend__item AwareCasesVerticalLegendItem"
              style={{ color: theme.palette.text.primary }}
              id={`AwareCasesVerticalLegendItemPopover-${props.sequence}`}
            >
              <div
                style={{
                  border: '2px solid',
                  borderColor: theme.palette.text.primary,
                }}
                className="AwareCasesVerticalLegendItem__circle AwareCasesVerticalLegendItem__circle--outline"
              />
              <span
                style={{ color: theme.palette.text.primary }}
                className="AwareCasesVerticalLegendItem__title"
              >
                {wrapWithIntl(attachPrefix('more'), {
                  more: sortedLegend.length - LEGEND_LENGTH_CONSTRAINT,
                })}
              </span>
            </div>
            <UncontrolledPopover
              className={classNames('GenericPopover', {
                'GenericPopover--dark':
                  dashboardData.theme === DashboardTheme.Dark,
              })}
              placement="bottom"
              target={`AwareCasesVerticalLegendItemPopover-${props.sequence}`}
            >
              <PopoverHeader>
                {wrapWithIntl(attachPrefix('remainingItems'))}
              </PopoverHeader>
              <PopoverBody className="AwareCasesVerticalLegend__container gap-2">
                {sortedLegend.map((item, index) => {
                  if (index < LEGEND_LENGTH_CONSTRAINT) return <></>;

                  return (
                    <div
                      key={item.value}
                      className="d-flex gap-3 align-items-center"
                    >
                      <div
                        style={{
                          background: theme.palette.background.default,
                          width: '1rem',
                          height: '1rem',
                          borderRadius: '1rem',
                        }}
                      />
                      <span
                        style={{ color: theme.palette.text.primary }}
                        className="AwareCasesVerticalLegendItem__title"
                      >
                        {item.value}
                      </span>
                    </div>
                  );
                })}
              </PopoverBody>
            </UncontrolledPopover>
          </>
        )}
      </div>
    </div>
  );
};

export default VerticalLegend;
