import TrendsChartTooltip from '../components/TrendsChartTooltip';
import React, { useCallback } from 'react';
import { TrendsChartFormatter } from '../../../types';

export const useTooltipContent = (formatters: TrendsChartFormatter[]) => {
  return useCallback(
    (props: any) => (
      <TrendsChartTooltip formatters={formatters} innerProps={props} />
    ),
    [formatters]
  );
};
