import { CasesParticipantFeedbackStatus } from 'interfaces/cases';
import React from 'react';
import SVG from 'react-inlinesvg';

import exclamationIcon from 'assets/images/exclamation_mark.svg';
import { FormattedMessage } from 'react-intl';

type CasesListLastFeedbackLabelProps = {
  status: CasesParticipantFeedbackStatus;
};

const CasesListLastFeedbackLabel = ({
  status,
}: CasesListLastFeedbackLabelProps) => {
  return (
    <div className="CasesListLastFeedbackLabel">
      {status !== CasesParticipantFeedbackStatus.NotSpecified &&
        status !== CasesParticipantFeedbackStatus.UpToDate && (
          <>
            <SVG
              className={`CasesListLastFeedbackLabel__icon--${status}`}
              src={exclamationIcon}
              title="info"
            />
            <span className={`CasesListLastFeedbackLabel__text--${status}`}>
              <FormattedMessage
                id={`OrganizationCases.FeedbackStatus.${status}`}
              />
            </span>
          </>
        )}
    </div>
  );
};

export default CasesListLastFeedbackLabel;
