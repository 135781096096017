import { IBaseOrganization } from './organizations';
import { BasePersonality } from './personalities';

export enum activityOperations {
  ADDED = 'Added',
  MODIFIED = 'Modified',
  DELETED = 'Deleted',
}

export enum ActivityTypes {
  observationDataAssignedToTimeline = 'ObservationDataAssignedToTimeline',
  observationDataUploaded = 'ObservationDataUploaded',
  entityUpdated = 'EntityUpdated',
  timelineSharingChanged = 'TimelineSharingChanged',
  CaseFormsAssignmentsAdded = 'CaseFormsAssignmentsAdded',
  CaseFormsAssignmentsModified = 'CaseFormsAssignmentsModified',
  CaseFormsAssignmentsDeleted = 'CaseFormsAssignmentsDeleted',
  CaseOpened = 'CaseOpened',
  CaseModified = 'CaseModified',
  CaseDeleted = 'CaseDeleted',
  CaseHistoryAdded = 'CaseHistoryAdded',
  PatientSubjectiveDataFormSubmitted = 'PatientSubjectiveDataFormSubmitted',
}

export type EntityUpdateType = {
  entity_name: string;
  entity_type: string;
  operation: string;
};

export type TimelineSharingChanged = {
  timeline_name: string;
  request_description: string;
  is_shared: false;
};

interface IActivityData extends EntityUpdateType, TimelineSharingChanged {}

export interface IActivity {
  time_stamp: string;
  activity_type: string;
  activity_data: IActivityData;
  user: BasePersonality;
  organization: IBaseOrganization;
}
