import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import useUpsertDashboardReportSchedule from '../../../../../../../../../../../../../api/mutations/dashboards/useUpsertDashboardReportSchedule';
import useOrganizationUserAccessProfiles from '../../../../../../../../../../../../../api/queries/accessProfiles/useOrganizationUserAccessProfiles';
import useDashboardReportSchedules from '../../../../../../../../../../../../../api/queries/dashboards/useDashboardReportSchedules';
import useDashboardTemplates from '../../../../../../../../../../../../../api/queries/dashboards/useDashboardTemplates';
import useOrganizationMembers from '../../../../../../../../../../../../../api/queries/organizations/useOrganizationMembers';
import { FormatHelpers } from '../../../../../../../../../../../../../helpers/format';
import { useGetCultureEntry } from '../../../../../../../../../../../../../hooks/cultures/useGetCultureEntry';
import { useOrganizationPathParams } from '../../../../../../../../../../../../../hooks/paths/useOrganizationPathParams';
import {
  DashboardReportDestinationType,
  DashboardReportPeriod,
  IUpsertDashboardReportSchedule,
} from '../../../../../../../../../../../../../interfaces/dashboards';
import { IValidationSchemaBasedFormHookValues } from '../../../../../../../../../../../../../interfaces/forms';
import {
  BaseModalProps,
  InputOption,
} from '../../../../../../../../../../../../../interfaces/ui';

type AddReportScheduleFormValues = Omit<
  IUpsertDashboardReportSchedule,
  'date_from'
> & {
  date_from?: string;
};

type AddReportScheduleFormHookValues =
  IValidationSchemaBasedFormHookValues<AddReportScheduleFormValues> & {
    dashboardTemplatesOptions: InputOption[];
    membersOptions: InputOption[];
    accessProfilesOptions: InputOption[];
  };

export const useForm = (
  modal: BaseModalProps
): AddReportScheduleFormHookValues => {
  const intl = useIntl();
  const getCultureEntry = useGetCultureEntry();
  const { organizationId } = useOrganizationPathParams();

  const dashboardReportSchedules = useDashboardReportSchedules({
    params: { organizationId },
    options: { staleTime: Infinity, enabled: false },
  });

  const handleRefetch = async () => {
    await dashboardReportSchedules.refetch();
    modal.toggle();
  };

  const { mutateAsync: upsertAsync } = useUpsertDashboardReportSchedule({
    params: { organizationId },
    options: { successFb: handleRefetch },
  });

  const invalidFieldValue = intl.formatMessage({
    id: 'General.InvalidFieldValue',
  });

  const dashboardTemplates = useDashboardTemplates({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const members = useOrganizationMembers({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const accessProfiles = useOrganizationUserAccessProfiles({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const initialValues = useMemo((): AddReportScheduleFormValues => {
    return {
      id: undefined,
      organization_id: organizationId,
      dashboard_id: '',
      destination_id: '',
      date_from: undefined,
      destination_type: DashboardReportDestinationType.Email,
      one_time_access: false,
      period: DashboardReportPeriod.Week,
    };
  }, [organizationId]);

  const dashboardTemplatesOptions = useMemo((): InputOption[] => {
    return (dashboardTemplates.data ?? []).map((template) => ({
      text: getCultureEntry(template.name) ?? '',
      value: template.id,
    }));
  }, [dashboardTemplates.data, getCultureEntry]);

  const membersOptions = useMemo((): InputOption[] => {
    return (members.data ?? []).map((member) => ({
      text: FormatHelpers.FormatPersonality(member),
      value: member.id,
    }));
  }, [members.data]);

  const accessProfilesOptions = useMemo((): InputOption[] => {
    return (accessProfiles.data ?? []).map((profile) => ({
      text: profile.name,
      value: profile.id,
    }));
  }, [accessProfiles.data]);

  const validationSchema = Yup.object().shape({
    id: Yup.string().optional(),
    organization_id: Yup.string().required(),
    dashboard_id: Yup.string().required(invalidFieldValue),
    destination_id: Yup.string().required(invalidFieldValue),
    date_from: Yup.string().required(),
    destination_type: Yup.mixed<DashboardReportDestinationType>()
      .oneOf(Object.values(DashboardReportDestinationType))
      .required(),
    one_time_access: Yup.boolean().required(),
    period: Yup.mixed<DashboardReportPeriod>()
      .oneOf(Object.values(DashboardReportPeriod))
      .required(),
  });

  const handleSubmit = useCallback(
    async (values: typeof initialValues) => {
      await upsertAsync({
        ...values,
        date_from: new Date(values.date_from ?? '').toISOString(),
      });
    },
    [upsertAsync]
  );

  return {
    initialValues,
    validationSchema,
    handleSubmit,
    dashboardTemplatesOptions,
    membersOptions,
    accessProfilesOptions,
  };
};
