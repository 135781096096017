import classNames from 'classnames';
import React, { useMemo } from 'react';
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from 'reactstrap';
import { useTabContainer } from '../../hooks/useTabContainer';
import { ITabContainerItem } from '../../types/Tabs';

type TabContainerProps = {
  header?: JSX.Element;
  items: ITabContainerItem[];
  onTabChange?: (index: number) => void;
  tabsDisabled?: boolean;
  additionalContent?: JSX.Element;
  tabClassNames?: string;
  paneClassNames?: string;
  containerClassNames?: string;
  rowClassNames?: string;
  noMargin?: boolean;
  justified?: boolean;
};

const TabContainer = ({
  header,
  items,
  onTabChange,
  tabsDisabled = false,
  additionalContent,
  rowClassNames,
  tabClassNames,
  paneClassNames,
  containerClassNames,
  noMargin = false,
  justified = true,
}: TabContainerProps) => {
  const { activeTab, toggle, defaultSelectedItems } =
    useTabContainer<ITabContainerItem>({ items, onTabChange });

  const panes = useMemo(() => {
    let internalPanes: JSX.Element[] = [];

    defaultSelectedItems.forEach(
      ({ header, component, shouldBeRendered }, index) => {
        internalPanes.push(
          <TabPane
            className={classNames(paneClassNames)}
            key={`pane-${index}`}
            tabId={index}
          >
            {shouldBeRendered ? component : <></>}
          </TabPane>
        );
      }
    );

    return internalPanes;
  }, [defaultSelectedItems, paneClassNames]);

  const tabs = useMemo(() => {
    let internalTabs: JSX.Element[] = [];

    defaultSelectedItems.forEach(
      ({ header, component, shouldBeRendered }, index) => {
        internalTabs.push(
          <NavItem
            key={`header-${index}`}
            className={classNames(
              'text-center',
              'cursor-pointer',
              { active: activeTab === index },
              tabClassNames
            )}
          >
            <NavLink
              disabled={tabsDisabled}
              onClick={() => {
                toggle(index);
              }}
              className="w-100"
            >
              {header}
            </NavLink>
          </NavItem>
        );
      }
    );

    return internalTabs;
  }, [activeTab, defaultSelectedItems, tabClassNames, tabsDisabled, toggle]);

  return (
    <Row
      className={classNames(
        'TabContainer',
        {
          'TabContainer--no-margin': noMargin,
        },
        rowClassNames
      )}
    >
      {header && (
        <Col sm={12} className="mt-4 mb-4">
          <div className="d-flex align-items-center">
            <span className="TimelineElement__content__header mb-4">
              {header}
            </span>
          </div>
        </Col>
      )}
      <Nav
        tabs
        justified={justified}
        className={classNames('TabContainer__tabs', containerClassNames)}
      >
        {tabs}
      </Nav>
      {additionalContent && <>{additionalContent}</>}
      <TabContent activeTab={activeTab} className="w-100">
        {panes}
      </TabContent>
    </Row>
  );
};

export default TabContainer;
