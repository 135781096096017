import { isAuthenticated } from 'helpers/utils/auth';

import React from 'react';
import { Navigate, Outlet } from 'react-router';
import LanguageProvider from '../../translations/utils/LanguageProvider';

type ProtectedRouteProps = {
  redirectTo?: string;
};

const AuthRouteGuard = ({ redirectTo = '/login' }: ProtectedRouteProps) => {
  const canRenderPage = isAuthenticated();

  if (canRenderPage) {
    return (
      <LanguageProvider>
        <Outlet />
      </LanguageProvider>
    );
  }

  return <Navigate to={redirectTo} />;
};

export default AuthRouteGuard;
