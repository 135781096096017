import { getUserContextAssessmentFormsForDate } from 'api/graspManagement';
import QueryKeys from 'api/queries/queryKeys';
import { TimelineQueryParams, UserQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { useQuery } from 'react-query';

interface UseUserContextAssessmentFormsForDateParams
  extends Partial<UserQueryParams>,
    Partial<TimelineQueryParams> {
  selectedDate: Date;
}
function useUserContextAssessmentFormsForDate({
  params,
  options,
}: IQueryRequest<UseUserContextAssessmentFormsForDateParams>) {
  const { selectedDate, userId, timelineId } = params;

  const year = selectedDate.getFullYear();
  const month = selectedDate.getMonth() + 1;
  const day = selectedDate.getDate();

  return useQuery({
    ...options,
    queryKey: [
      QueryKeys.getUserAssessments,
      userId,
      timelineId,
      selectedDate.toISOString(),
    ],
    queryFn: async () => {
      if (!userId || !timelineId) return null;

      const { data: responseData } = await getUserContextAssessmentFormsForDate(
        userId,
        timelineId,
        year,
        month,
        day
      );

      return responseData.data;
    },
  });
}

export default useUserContextAssessmentFormsForDate;
