import FormGroupDescription from 'components/FormGroupDescription';
import { UserDataFormProps } from 'interfaces/ui';
import React from 'react';
import { useIntl } from 'react-intl';
import ParticipantsForm from './ParticipantsForm';

const ParticipantsFormCreateUser = <T,>(props: UserDataFormProps<T>) => {
  const intl = useIntl();
  return (
    <FormGroupDescription
      header={intl.formatMessage({
        id: 'CaseCard.create.userData.header',
      })}
      description={intl.formatMessage({
        id: 'CaseCard.create.userData.description',
      })}
      descriptionLargeSize={4}
      inputLargeSize={8}
    >
      <ParticipantsForm {...props} />
    </FormGroupDescription>
  );
};

export default ParticipantsFormCreateUser;
