import { ICasePhaseHistory } from '../../interfaces/cases';
import { strToDateOnly } from '../dates';

export const getCurrentCasePhaseHistoryItems = (
  history: ICasePhaseHistory[],
  date: string
) => {
  const dateOnly = strToDateOnly(date);

  return history.filter(
    (p) =>
      (p.date_to &&
        dateOnly >= strToDateOnly(p.date_from) &&
        dateOnly <= strToDateOnly(p.date_to)) ||
      (!p.date_to && dateOnly >= strToDateOnly(p.date_from))
  );
};
