import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import classNames from 'classnames';
import { useTranslations } from 'hooks/useTranslations';
import React, { useMemo } from 'react';
import Flag from 'react-flagkit';
import { AVAILABLE_CULTURES } from '../CultureSelector/CultureSelector';
import EditableInput from '../EditableInput/EditableInput';
import IconButton from '../IconButton';
import { ApplicableInputHookValues } from './hooks/useApplicableInput';

export type ApplicableInputProps = {
  control: ApplicableInputHookValues;
  culture?: AVAILABLE_CULTURES;
  disabledForm?: 'plaintext' | 'input';
  textClassNames?: string;
  undo?: boolean;
  children?: (props: Omit<ApplicableInputProps, 'children'>) => JSX.Element;
};

const ApplicableInput: React.FC<ApplicableInputProps> = ({
  culture,
  control,
  textClassNames,
  disabledForm = 'plaintext',
  children,
  undo = false,
}) => {
  const { attachPrefix, wrapWithIntl } = useTranslations('ApplicableInput');

  const cultureFlag = culture && <Flag country={culture.slice(-2)} />;

  const editableChildren = useMemo(
    () =>
      children?.({
        control,
        textClassNames,
        disabledForm,
      }),
    [children, control, disabledForm, textClassNames]
  );

  const handleAccept = () =>
    control.onAccept(control.value, culture ?? AVAILABLE_CULTURES.EN);

  return (
    <div>
      {control.editMode ? (
        <div className="d-flex align-items-center gap-3">
          {cultureFlag}
          <>{editableChildren}</>
          <div className="d-flex align-items-center gap-1">
            <IconButton
              color="primary"
              onClick={handleAccept}
              variant="contained"
            >
              <CheckOutlinedIcon />
            </IconButton>
            {undo && (
              <IconButton
                color="primary"
                onClick={control.onUndo}
                variant="outlined"
              >
                <ReplayOutlinedIcon />
              </IconButton>
            )}
            <IconButton
              color="primary"
              onClick={control.onClose}
              variant="outlined"
            >
              <CloseOutlinedIcon />
            </IconButton>
          </div>
        </div>
      ) : (
        <div className="d-flex align-items-center gap-2">
          {disabledForm === 'plaintext' ? (
            <>
              {cultureFlag}
              <span
                className={classNames({
                  [`${textClassNames}`]: !!textClassNames,
                })}
              >
                {control.value}
              </span>
            </>
          ) : (
            <>
              {cultureFlag}
              <EditableInput
                disabled={true}
                type="text"
                placeholder={wrapWithIntl(attachPrefix('placeholder'))}
                value={control.value}
                name={control.value}
                debounceEnabled={false}
              />
            </>
          )}
          <IconButton
            color="primary"
            onClick={control.toggle}
            variant="outlined"
          >
            <EditOutlinedIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default ApplicableInput;
