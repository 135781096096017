import React, { useMemo, useState } from 'react';
import { IGroupFeedbackQuestion } from '../../../../../../interfaces/caseGroups';
import { useCaseGroupDetailsCommonData } from '../../hooks/useCaseGroupDetailsCommonData';
import { useTemplatesBasedOnGroupFeedbackOptions } from './hooks/useTemplatesBasedOnGroupFeedbackOptions';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import EmptyGroupFeedback from './components/EmptyGroupFeedback';
import EmptyGroupFeedbackLoading from './components/EmptyGroupFeedbackLoading';
import GroupFeedbackItem from './components/GroupFeedbackItem';
import { cloneDeep } from 'lodash';
import { useInitQuery } from '../../../../../../hooks/queries/useInitQuery';
import { wrapWithSequenceIdentified } from '../../../../../../helpers/utils/data';

export type SelectedGroupFeedback = {
  conversationTemplateId: string;
  questions: IGroupFeedbackQuestion[];
  selectedIndex: number;
};

const CaseGroupDetailsAnswers = () => {
  const { groupFeedback, conversationTemplates } =
    useCaseGroupDetailsCommonData();

  const templatesOptions = useTemplatesBasedOnGroupFeedbackOptions();

  useInitQuery(groupFeedback);
  useInitQuery(conversationTemplates);

  const [selectedFeedback, setSelectedFeedback] = useState<
    SelectedGroupFeedback[]
  >([]);

  const [noQuestionsAvailableMode, setNoQuestionsAvailableMode] =
    useState<boolean>(false);

  const handleAddGroupFeedback = () => {
    if (groupFeedback.data === undefined) return;

    const firstGroupFeedbackWithQuestions = groupFeedback.data?.find(
      (p) => p.questions.length > 0
    );

    if (firstGroupFeedbackWithQuestions === undefined) {
      setNoQuestionsAvailableMode(true);
      return;
    }

    setSelectedFeedback([
      ...selectedFeedback,
      {
        conversationTemplateId:
          firstGroupFeedbackWithQuestions.conversationTemplateId,
        questions: firstGroupFeedbackWithQuestions.questions,
        selectedIndex: 0,
      },
    ]);
  };

  const renderFeedback = useMemo(() => {
    return wrapWithSequenceIdentified(selectedFeedback).map(
      (feedback, index) => {
        const handleRemoveTemplate = (templateId: string) => {
          let feedbackCopy = cloneDeep(selectedFeedback);
          const templateIndex = feedbackCopy.findIndex(
            (p) => p.conversationTemplateId === templateId
          );

          feedbackCopy.splice(templateIndex, 1);
          setSelectedFeedback(feedbackCopy);
        };

        const handleChangeTemplate = (templateId: string) => {
          let feedbackCopy = cloneDeep(selectedFeedback);

          const feedbackForNewTemplate = groupFeedback.data?.find(
            (p) => p.conversationTemplateId === templateId
          );

          if (feedbackForNewTemplate === undefined) return;

          feedbackCopy[index].conversationTemplateId = templateId;
          feedbackCopy[index].questions = feedbackForNewTemplate.questions;
          feedbackCopy[index].selectedIndex = 0;

          setSelectedFeedback(feedbackCopy);
        };

        const handleSelectedQuestion = (questionId: string) => {
          let feedbackCopy = cloneDeep(selectedFeedback);

          const newQuestionIndex = feedbackCopy[index].questions.findIndex(
            (p) => p.questionId === questionId
          );

          if (newQuestionIndex === -1) return;

          feedbackCopy[index].selectedIndex = newQuestionIndex;
          setSelectedFeedback(feedbackCopy);
        };

        return (
          <div
            key={`${feedback.sequence}|${selectedFeedback[index].selectedIndex}|${selectedFeedback[index].conversationTemplateId}`}
            className="my-4"
          >
            <GroupFeedbackItem
              onTemplateRemove={handleRemoveTemplate}
              onTemplateChange={handleChangeTemplate}
              onQuestionChange={handleSelectedQuestion}
              templateId={feedback.conversationTemplateId}
              selectedFeedback={feedback}
              templatesOptions={templatesOptions(
                groupFeedback.data,
                conversationTemplates.data
              )}
            />
          </div>
        );
      }
    );
  }, [
    conversationTemplates.data,
    groupFeedback.data,
    selectedFeedback,
    templatesOptions,
  ]);

  const isFetched = groupFeedback.isFetched && conversationTemplates.isFetched;
  const isLoading = groupFeedback.isLoading || conversationTemplates.isLoading;

  if (isLoading) {
    return (
      <div className="ManageGroupCases CaseCard__tab-container pt-3 px-3">
        <div>
          <EmptyGroupFeedbackLoading />
        </div>
      </div>
    );
  }

  return (
    <div className="ManageGroupCases CaseCard__tab-container pt-3 px-3">
      {selectedFeedback.length !== 0 && (
        <div className="ManageGroupCases__header my-4 pt-2">
          <div className="ManageGroupCases__actions">
            <Button
              color="primary"
              className="d-flex align-items-center gap-1 p-3"
              outline={true}
              onClick={handleAddGroupFeedback}
            >
              <FontAwesomeIcon icon={faPlus} />
              <FormattedMessage id="Cases.CaseGroupDetails.addFeedbackItem" />
            </Button>
          </div>
        </div>
      )}
      <div>
        {selectedFeedback.length === 0 ? (
          <>
            {isFetched && (
              <EmptyGroupFeedback
                refetch={groupFeedback.refetch}
                noAvailableQuestionsMode={noQuestionsAvailableMode}
                setNoAvailableQuestionsMode={setNoQuestionsAvailableMode}
                addGroupFeedback={handleAddGroupFeedback}
              />
            )}
          </>
        ) : (
          <>{renderFeedback}</>
        )}
      </div>
    </div>
  );
};

export default CaseGroupDetailsAnswers;
