import { formatTime } from 'helpers/dates';
import { returnIconBasedOnObservationMeaningAndType } from 'helpers/utils/icons';
import { observationMeaningOptions } from 'helpers/utils/translationObject';
import { GraspEventItemData, GraspEventItemType } from 'interfaces/timeline';
import { FormattedMessage } from 'react-intl';
import SVG from 'react-inlinesvg';

type GraspObservationItemProps = {
  observation: GraspEventItemData;
};

const GraspObservationItem = ({ observation }: GraspObservationItemProps) => {
  const time = observation.beginDate
    ? formatTime(observation.beginDate, true)
    : '-';

  return (
    <div className="GraspEventItem" onClick={observation.onEditClick}>
      <div className="GraspEventItem__icon">
        <div
          className={`GraspEventItem__icon__container GraspEventItem__icon__container--${observation.meaning}`}
        >
          <SVG
            src={returnIconBasedOnObservationMeaningAndType(
              GraspEventItemType.OBSERVATION,
              observation.meaning
            )}
          />
        </div>
      </div>
      <div className="GraspEventItem__description">
        <div className="GraspEventItem__description__title">
          <span
            className={`GraspEventItem__description__title GraspEventItem__description__title--${observation.meaning}`}
          >
            <FormattedMessage
              id={
                observationMeaningOptions.filter(
                  (p) => p.value === observation.meaning
                )[0].translationId
              }
            />
          </span>
        </div>
        {observation.level && observation.duration_in_seconds && (
          <span className="GraspEventItem__description__event-properties">
            Level: {observation.level.toFixed(2)}, Duration:{' '}
            {observation.duration_in_seconds.toFixed(2)}s
          </span>
        )}
      </div>
      <div className="GraspEventItem__hour-range">
        <div className="GraspEventItem__hour-range__start">{time}</div>
      </div>
    </div>
  );
};

export default GraspObservationItem;
