import React, { useEffect, useMemo } from 'react';
import { Col, Row } from 'reactstrap';
import MobileFooter from 'components/Footer/MobileFooter';
import graspWhiteBigLogo from 'assets/images/logo-horiz-white.png';
import { useNavigate } from 'react-router';
import { format } from 'date-fns';
import ChangeLanguageSelector, {
  AVAILABLE_CULTURES,
} from 'components/CultureSelector/CultureSelector';

//images
import logo from 'assets/images/project_submissions/grasp_logo.png';
import light from 'assets/images/project_submissions/light.png';
import iceLogo from 'assets/images/project_submissions/ice_grasp_logo.png';
import { IProject } from 'interfaces/submissions';
import classNames from 'classnames';
import { translateHtmlStr } from '../../helpers/utils/cultures';

enum ProjectDefinitions {
  CHANGE = 'change',
  ICE_BUCKET = 'icebucket',
}

type ProjectSubmissionLayoutProps = {
  data?: IProject;
  children: JSX.Element;
  projectId: string;
  isFetched: boolean;
  selectedCulture: AVAILABLE_CULTURES;
  setSelectedCulture: (val: AVAILABLE_CULTURES) => void;
};

const ProjectSubmissionLayout = ({
  children,
  data,
  projectId,
  isFetched,
  selectedCulture,
  setSelectedCulture,
}: ProjectSubmissionLayoutProps) => {
  const navigate = useNavigate();

  const description = translateHtmlStr(data?.description, selectedCulture);
  const title = translateHtmlStr(data?.title, selectedCulture);

  const renderImageBaseOnProjectId = useMemo(() => {
    switch (projectId) {
      case ProjectDefinitions.ICE_BUCKET:
        return (
          <img
            className="w-100 img-fluid  mem-content__logoSection__logo mem-content__logoSection__logo--no-padding"
            src={iceLogo}
            alt="icelogo"
          />
        );
      case ProjectDefinitions.CHANGE:
      default:
        return (
          <>
            <img
              alt="light"
              src={light}
              className="img-fluid mem-content__logoSection__logo mem-content__logoSection__logo--project mem-content__logoSection__logo--shadow"
            />
            <img
              className="img-fluid mem-content__logoSection__logo mem-content__logoSection__logo--project"
              src={logo}
              alt="logo"
            />
          </>
        );
    }
  }, [projectId]);

  const returnMobileImgBaseOnProjectId = () => {
    switch (projectId) {
      case ProjectDefinitions.ICE_BUCKET:
        return (
          <img
            className="img-fluid mem-content__mobile-header__logo img-fluid mem-content__mobile-header__logo--icebucket"
            src={iceLogo}
            alt="logo"
          />
        );
      case ProjectDefinitions.CHANGE:
      default:
        return (
          <img
            className="img-fluid mem-content__mobile-header__logo"
            src={logo}
            alt="logo"
          />
        );
    }
  };

  useEffect(() => {
    if (!data && isFetched) {
      navigate('/');
    }
  }, [data, isFetched, navigate]);

  const renderMobileDescription = useMemo(() => {
    let renderedDescription: JSX.Element | null = null;

    if (data?.description) {
      renderedDescription = (
        <>
          <div className="mem-content__mobile-header__description__date">
            {format(new Date(), 'PPP')}
          </div>
          <div
            className="mem-content__mobile-header__description__text"
            dangerouslySetInnerHTML={{
              __html: description,
            }}
          />
        </>
      );
    }

    return (
      <div
        className={classNames('mem-content__mobile-header__description', {
          'mem-content__mobile-header__description--divider':
            !!data?.description,
        })}
      >
        {renderedDescription}
      </div>
    );
  }, [data?.description, description]);

  const renderedDesktopDescription = useMemo(() => {
    if (!data?.description || !data?.title) return null;

    return (
      <div className="mem-content__logoSection__project-data">
        <div className="mem-content__logoSection__project-data__date">
          {format(new Date(), 'PPP')}
        </div>
        <div
          className="mem-content__logoSection__project-data__title"
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        ></div>
        <div
          className="mem-content__logoSection__project-data__description"
          dangerouslySetInnerHTML={{
            __html: description,
          }}
        ></div>
      </div>
    );
  }, [data?.description, data?.title, description, title]);

  return (
    <div className="mem-wrapper">
      <div className="mem-content">
        <Row className="d-flex w-100 h-100 g-0">
          <Col sm={12} xs={12} className="mem-content__mobile-header-col">
            <div className="mem-content__mobile-header">
              <img
                alt="logo"
                src={graspWhiteBigLogo}
                className="img-fluid mem-content__logoSection__grasp-logo"
              />
              <div className="d-flex justify-content-end pt-1 text-white">
                <ChangeLanguageSelector
                  white={true}
                  selectedCulture={selectedCulture}
                  setSelectedCulture={setSelectedCulture}
                />
              </div>
              <h1
                className="mem-content__mobile-header__title"
                dangerouslySetInnerHTML={{
                  __html: translateHtmlStr(
                    data?.mobile_header,
                    selectedCulture
                  ),
                }}
              />
              {returnMobileImgBaseOnProjectId()}
            </div>
            {renderMobileDescription}
          </Col>
          <Col lg={5} xl={5} className="d-none d-lg-block mem-sticky">
            <div className="mem-content__logoSection mem-content__logoSection--project">
              <img
                alt="logo"
                src={graspWhiteBigLogo}
                className="img-fluid mem-content__logoSection__grasp-logo"
              />
              {renderedDesktopDescription}
              {renderImageBaseOnProjectId}
            </div>
          </Col>
          <Col
            xs={12}
            sm={12}
            lg={{ size: 7, offset: 5 }}
            xl={{ size: 7, offset: 5 }}
            className="d-flex align-items-center justify-content-center"
          >
            {React.Children.only(children)}
          </Col>
          <Col xs={12} sm={12} className="mem-footer-mobile-col Footer">
            <MobileFooter />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ProjectSubmissionLayout;
