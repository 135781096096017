import React from 'react';
import {
  BaseModalProps,
  ViewTheme,
} from '../../../../../../../../interfaces/ui';
import { FormikProps } from 'formik';
import { AwareCaseDetailsFormValues } from '../../forms/AwareCaseDetailsForm/types';
import classNames from 'classnames';
import { Modal } from 'reactstrap';
import { useCookie } from '../../../../../../../../hooks/useCookie';
import CaseProceduresProvider from '../../providers/CaseProceduresProvider';
import ManageActivityForm from './forms/ManageActivityForm';

type ManageActivityModalProps = BaseModalProps & {
  msPoint: number;
  activityIndex: number | undefined;
  form: FormikProps<AwareCaseDetailsFormValues>;
};

const ManageActivityModal = ({
  activityIndex,
  form,
  msPoint,
  isOpen,
  toggle,
}: ManageActivityModalProps) => {
  const themeCookie = useCookie<ViewTheme>('theme');

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      className={classNames('GenericModal BorderlessModal', {
        'GenericModal--dark': themeCookie === ViewTheme.Dark,
      })}
    >
      <CaseProceduresProvider>
        {(procedures) => (
          <ManageActivityForm
            msPoint={msPoint}
            activityIndex={activityIndex}
            form={form}
            procedures={procedures}
            onAction={toggle}
          />
        )}
      </CaseProceduresProvider>
    </Modal>
  );
};

export default ManageActivityModal;
