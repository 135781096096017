import { organizationContextUpdateSubmissionAssessmentForm } from 'api/graspManagement';
import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { AssessmentQuestion } from 'interfaces/assessmentForms';
import { IConversationTemplatePlainTextItem } from '../../../../interfaces/conversations';
import { IMutationProps } from '../../../../interfaces/ui';

type MutationParam = {
  assessmentBody: (AssessmentQuestion | IConversationTemplatePlainTextItem)[];
  code: string;
  title: string;
};

type useOrganizationContextUpdateSubmissionAssessmentFormParams = {
  organizationId: string;
  caseId: string;
  submissionId: string | null;
  date: Date;
  sequence: number;
};

const useOrganizationContextUpdateSubmissionAssessmentForm = (
  params: useOrganizationContextUpdateSubmissionAssessmentFormParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification<MutationParam, void>({
    asyncApiFunction: async ({ code, title, assessmentBody }) => {
      await organizationContextUpdateSubmissionAssessmentForm(
        params.organizationId,
        params.caseId,
        params.submissionId,
        code,
        title,
        params.date,
        params.sequence,
        assessmentBody
      );
    },
    onSuccess: () => {
      displaySuccessNotification('TimelineDetails.assessmentUpdateSuccess');
      if (options.successFb) options.successFb();
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'TimelineDetails.assessmentUpdateError'
      );

      if (options.errorFb) options.errorFb();
    },
    resetOnResult: true,
  });
};

export default useOrganizationContextUpdateSubmissionAssessmentForm;
