import { IErrorDefinition, IErrorWithCode } from 'interfaces/response';
import { useEffect } from 'react';
import { useMutation } from 'react-query';

type useMutationWithResultNotificationParams<P, R> = {
  asyncApiFunction: (param: P) => Promise<R>;
  onError?: (error?) => void;
  onErrorArray?: (errorArray: IErrorDefinition[]) => void;
  onSuccess?: () => void;
  resetOnResult?: boolean;
};

export function useMutationWithResultNotification<P, T>({
  asyncApiFunction,
  onError,
  onErrorArray,
  onSuccess,
  resetOnResult,
}: useMutationWithResultNotificationParams<P, T>) {
  const mutationResponse = useMutation<T, IErrorWithCode, P>(asyncApiFunction);
  const { isSuccess, isError, reset, error } = mutationResponse;

  useEffect(() => {
    if (isSuccess) {
      onSuccess && onSuccess();
      resetOnResult && reset();
    }
  }, [isSuccess, reset, onSuccess, resetOnResult]);

  useEffect(() => {
    if (isError) {
      onError && onError(error);
      resetOnResult && reset();
    }
  }, [isError, onError, resetOnResult, reset, error]);

  useEffect(() => {
    if (isError) {
      onErrorArray && onErrorArray(error?.data?.meta?.errors || []);
      resetOnResult && reset();
    }
  }, [isError, onErrorArray, resetOnResult, reset, error]);

  return mutationResponse;
}
