import { ConversationTemplateValidationResult } from '../../../../../../../../../../../../../../../../../interfaces/conversations';
import { ValidationContext } from '../../../../../types';
import { IErrorDefinition } from '../../../../../../../../../../../../../../../../../interfaces/response';
import useValidateImportedConversationTemplate from '../../../../../../../../../../../../../../../../../api/mutations/conversations/useValidateImportedConversationTemplate';
import { useTypedContext } from '../../../../../../../../../../../../../../../../../hooks/useTypedContext';
import { ReactStateTuple } from '../../../../../../../../../../../../../../../../../interfaces/ui';
import { displayErrorNotification } from '../../../../../../../../../../../../../../../../Notifications';
import { useCommonParams } from '../../../../../../../../../../../../../../../hooks/useCommonParams';
import { ValidationResult } from '../../../../../../../../../types';

export const useRevalidateTemplate = (
  setError: (error: string | null) => void
) => {
  const { organizationId } = useCommonParams();

  const [, setValidation] =
    useTypedContext<ReactStateTuple<ValidationResult>>(ValidationContext);

  const handleSuccess = (body: ConversationTemplateValidationResult) => {
    setValidation({
      conflicts: body.conflicts,
      template: body.template,
      ready_to_complete: body.conflicts.length === 0,
    });
  };

  const handleError = (data: string | IErrorDefinition[]) => {
    if (typeof data === 'string') {
      displayErrorNotification(data);
      return;
    }

    const firstReason = data.at(0)?.reasons?.at(1);

    if (!firstReason) {
      displayErrorNotification(
        data.at(0)?.code ??
          'Api.Mutations.Conversations.ValidateImportedConversationTemplate.failure.invalid_operation_for_resource'
      );
      return;
    }

    setError(firstReason);
  };

  const { mutateAsync: validateAsync, isLoading: validating } =
    useValidateImportedConversationTemplate({
      params: { organizationId },
      options: { successFb: handleSuccess, errorFb: handleError },
      silent: true,
    });

  return { revalidate: validateAsync, validating };
};
