import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getOrganizationObservationMeanings } from '../../observationMeanings';
import { OrganizationQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

const useObservationMeanings = ({
  params,
  options,
}: IQueryRequest<OrganizationQueryParams>) => {
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getOrganizationObservationMeanings(
        params.organizationId
      );

      return data.data;
    },
    queryKey: [
      QueryKeys.getOrganizationObservationMeanings,
      params.organizationId,
    ],
  });
};

export default useObservationMeanings;
