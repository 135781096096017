import { ICase } from './cases';
import { AssessmentQuestionType } from './assessmentForms';

export interface IBaseCaseGroup {
  id: string;
  organization_id: string;
  project_id: string | null;
  case_category_id: string;
  type: CaseGroupType;
  starting_date: string;
  sequence: number;
  maximum_members_allowed: number;
  group_schedule: ICaseGroupSchedule[];
}

export interface ICaseGroup extends IBaseCaseGroup {
  cases: ICase[];
  sessions: IBaseCaseGroupSession[];
}

export interface ISummarizedBaseCaseGroup extends IBaseCaseGroup {
  cases_count: number;
  sessions_count: number;
}

export enum CaseGroupType {
  Permanent = 'Permanent',
  Virtual = 'Virtual',
}

export interface IBaseCaseGroupSession {
  id: string;
  meetings_date: string;
  meetings_link: string;
  meetings_pin_code: string;
}

export interface ICreateCaseGroupSession {
  id: string;
  meetings_date: string;
  meetings_link: string;
  meetings_pin_code: string;
}

export interface ICreateCaseGroup {
  case_category_id: string;
  starting_date: string;
  maximum_members_allowed: number;
  cases: string[];
  sessions: ICreateCaseGroupSession[];
  group_schedule: ICaseGroupSchedule[];
}

export interface ICaseGroupSchedule {
  phase_id: string;
  date_from: string;
  date_to: string;
}

export interface ICreateCaseGroupWithCaseCategory extends ICreateCaseGroup {
  project_id: string;
}

export interface IGroupFeedback {
  conversationTemplateId: string;
  questions: IGroupFeedbackQuestion[];
}

export interface IGroupFeedbackQuestion {
  questionId: string;
  questionTitle: string;
  questionType:
    | AssessmentQuestionType.RANGE
    | AssessmentQuestionType.CLOSED_LIST;
  answers:
    | IGroupFeedbackRangeQuestionAnswer[]
    | IGroupFeedbackClosedListQuestionAnswer[];
}

export interface IGroupFeedbackRangeQuestionAnswer {
  userId: string;
  displayName: string | null;
  value?: number;
}

export interface IGroupFeedbackClosedListQuestionAnswer {
  answerTitle: string;
  groupParticipants?: number;
}
