import React from 'react';
import CustomDataGrid from '../../../../../../../../../../components/CustomDataGrid';
import { CustomMaterialTableHelpers } from '../../../../../../../../../../components/CustomDataGrid/helpers';
import { OrganizationAccessProfile } from '../../../../../../../../../../interfaces/accessProfiles';
import { useColumns } from './hooks/useColumns';

type AccessProfilesTableProps = {
  accessProfiles: OrganizationAccessProfile[];
};

const AccessProfilesTable = ({ accessProfiles }: AccessProfilesTableProps) => {
  const columns = useColumns();

  return (
    <CustomDataGrid
      {...CustomMaterialTableHelpers.DisableRowEvents}
      getRowHeight={CustomMaterialTableHelpers.GetRowHeight}
      rows={accessProfiles}
      columns={columns}
      sx={{
        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
          py: '0.5rem',
        },
      }}
    />
  );
};

export default AccessProfilesTable;
