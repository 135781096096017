import React, { ButtonHTMLAttributes, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { iconColors } from 'helpers/colors';

interface LoadingButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  isFailure?: boolean;
  isSuccess?: boolean;
  isLoading?: boolean;
  showIcons?: boolean;
  outline?: boolean;
  block?: boolean;
  size?: string;
}

const LoadingButton = ({
  value,
  color = 'primary',
  disabled = false,
  isLoading = false,
  className,
  isSuccess = false,
  isFailure = false,
  children,
  showIcons = true,
  block = false,
  type = 'submit',
  ...rest
}: LoadingButtonProps) => {
  const [successState, setSuccessState] = useState(false);
  const [failureState, setFailureState] = useState(false);

  useEffect(() => {
    let timeout: undefined | ReturnType<typeof setTimeout>;
    if (!isLoading && isSuccess) {
      setSuccessState(true);

      timeout = setTimeout(() => {
        setSuccessState(false);
      }, 700);
    }

    if (!isLoading && isFailure) {
      setFailureState(true);

      timeout = setTimeout(() => {
        setFailureState(false);
      }, 700);
    }
    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [isLoading, isSuccess, isFailure]);

  const opacity = successState || failureState || isLoading ? 0 : 1;

  return (
    <Button
      className={`d-inline-flex justify-content-center align-items-center
          ${className || ''}`}
      color={color}
      disabled={disabled || isLoading}
      type={type}
      block={block}
      {...rest}
    >
      {/* DISPLAY LOADING ICON */}
      {(isLoading || (!showIcons && (successState || failureState))) && (
        <div className="position-absolute">
          <FontAwesomeIcon icon={faSpinner} spin color={iconColors.normal} />
        </div>
      )}

      {/* DISPLAY SUCCESS ICON */}
      {showIcons && successState && (
        <span className="position-absolute">
          <FontAwesomeIcon icon={faCheck} />
        </span>
      )}

      {/* DISPLAY FAILURE ICON */}
      {showIcons && failureState && (
        <span className="position-absolute">
          <FontAwesomeIcon icon={faTimes} />
        </span>
      )}

      {/* HIDE TEXT DURING STATE CHANGES */}
      {value && typeof value === 'string' && (
        <span style={{ opacity }}>
          <FormattedMessage id={value} />
        </span>
      )}

      {children && <div style={{ opacity }}>{children}</div>}
    </Button>
  );
};

export default LoadingButton;
