import { addDays, subDays } from 'date-fns';
import { cloneDeep } from 'lodash';
import { changeSelectedDate } from 'modules/TimelineDetails/actions';
import { useDispatch } from 'react-redux';
import {
  useTimelineDetailsDateRangeObject,
  useTimelineDetailsSelectedDate,
} from 'store/reducerHooks';

export const useDayDetailsDate = () => {
  const dispatch = useDispatch();
  const dateRangeObject = useTimelineDetailsDateRangeObject();
  const selectedDate = useTimelineDetailsSelectedDate() ?? new Date();
  const handleNext = () => {
    if (!dateRangeObject) return;

    if (!dateRangeObject.to) {
      dispatch(changeSelectedDate(addDays(selectedDate, 1)));
      return;
    }

    const dateCopy = cloneDeep(addDays(selectedDate, 1));

    if (dateCopy > dateRangeObject.to) return;

    dispatch(changeSelectedDate(addDays(selectedDate, 1)));
  };
  const handlePrev = () => {
    if (!dateRangeObject) return;

    if (!dateRangeObject.from) {
      dispatch(changeSelectedDate(subDays(selectedDate, 1)));
      return;
    }

    const dateCopy = cloneDeep(subDays(selectedDate, 1));

    if (dateCopy < dateRangeObject.from) return;

    dispatch(changeSelectedDate(subDays(selectedDate, 1)));
  };

  return { handleNext, handlePrev, selectedDate };
};
