import React, { useContext, useState } from 'react';
import { ISummarizedTreatmentPhase } from '../../../../../../../../interfaces/conversations';
import { ConversationScheduleDateContext } from '../../index';
import Schedule from '../../../../../../../../components/Schedule';
import { defaultHours } from '../../../../../../../../helpers/utils/dates';
import { useCaseGroupConversationScheduleItems } from './hooks/useCaseGroupConversationScheduleItems';
import useOpen from '../../../../../../../../hooks/useOpen';
import CaseGroupDetailsConversationSchedulesEditTimeModal from '../../modals/CaseGroupDetailsConversationSchedulesEditTimeModal';
import { ICaseGroupConversationSchedule } from '../../../../../../../../interfaces/conversationSchedules';
import CaseGroupDetailsConversationSchedulesResponsesModal from '../../modals/ResponsesModal';

type CaseGroupConversationScheduleProps = {
  phase: ISummarizedTreatmentPhase;
};

const CaseGroupConversationSchedule = ({
  phase,
}: CaseGroupConversationScheduleProps) => {
  const selectedDate = useContext(ConversationScheduleDateContext);

  const { isOpen: editTimeOpen, toggle: toggleEditTime } = useOpen();
  const { isOpen: responsesOpen, toggle: toggleResponses } = useOpen();

  const [schedule, setSchedule] =
    useState<ICaseGroupConversationSchedule | null>(null);

  const [responsesId, setResponsesId] = useState<string | null>(null);

  const { items, isLoading } = useCaseGroupConversationScheduleItems(
    phase.id,
    new Date(selectedDate),
    setSchedule,
    setResponsesId,
    toggleEditTime,
    toggleResponses
  );

  return (
    <>
      {editTimeOpen && schedule && (
        <CaseGroupDetailsConversationSchedulesEditTimeModal
          isOpen={editTimeOpen}
          toggle={toggleEditTime}
          schedule={schedule}
          phaseId={phase.id}
        />
      )}
      {responsesOpen && responsesId && (
        <CaseGroupDetailsConversationSchedulesResponsesModal
          scheduleId={responsesId}
          isOpen={responsesOpen}
          toggle={toggleResponses}
        />
      )}
      <Schedule
        isLoading={isLoading}
        hours={defaultHours}
        items={items}
        selectedDateString={selectedDate}
      />
    </>
  );
};

export default CaseGroupConversationSchedule;
