import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBooksMedical, faFolderTree } from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage } from 'react-intl';
import { useTranslations } from './hooks/useTranslations';
import { IBaseEducationMaterialCategory } from '../../../../../../interfaces/educationMaterials';
import useOpen from '../../../../../../hooks/useOpen';
import EducationMaterialCategoryModal from '../EducationMaterialCategoryModal';
import MaterialModal from '../../../../modals/MaterialModal';

type AddEducationMaterialItemModalProps = {
  isOpen: boolean;
  toggle: () => void;
  category: IBaseEducationMaterialCategory;
};

const AddEducationMaterialItemModal = ({
  isOpen,
  toggle,
  category,
}: AddEducationMaterialItemModalProps) => {
  const { isOpen: addChildCategoryOpen, toggle: toggleAddChildCategory } =
    useOpen();

  const {
    isOpen: addEducationMaterialOpen,
    toggle: toggleAddEducationMaterial,
  } = useOpen();

  const translations = useTranslations();

  const handleToggleChildCategory = () => {
    toggleAddChildCategory();
    toggle();
  };

  const handleToggleEducationMaterial = () => {
    toggleAddEducationMaterial();
    toggle();
  };

  return (
    <>
      {addChildCategoryOpen && (
        <EducationMaterialCategoryModal
          isOpen={addChildCategoryOpen}
          toggle={handleToggleChildCategory}
          parentCategoryId={category.id}
        />
      )}
      {addEducationMaterialOpen && (
        <MaterialModal
          isOpen={addEducationMaterialOpen}
          toggle={handleToggleEducationMaterial}
          initialCategoryId={category.id}
        />
      )}
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="GenericModal BorderlessModal"
        size="lg"
      >
        <ModalHeader toggle={toggle}>
          <FontAwesomeIcon icon={faBooksMedical} />
          <FormattedMessage id={translations.addItem} />
        </ModalHeader>
        <ModalBody>
          <div
            className={'d-flex align-items-center justify-content-center gap-1'}
          >
            <div
              className="RequestAccessModal__option__image-container w-100 d-flex flex-column align-items-center justify-content-center text-primary"
              onClick={toggleAddChildCategory}
            >
              <FontAwesomeIcon icon={faFolderTree} size="4x" />
              <FormattedMessage id={translations.addChildCategory} />
            </div>
            <div
              className="RequestAccessModal__option__image-container w-100 d-flex flex-column align-items-center justify-content-center text-primary"
              onClick={toggleAddEducationMaterial}
            >
              <FontAwesomeIcon icon={faBooksMedical} size="4x" />
              <FormattedMessage id={translations.addEducationMaterial} />
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AddEducationMaterialItemModal;
