import { IMutationRequest } from '../../../interfaces/request';
import { OrganizationQueryParams } from '../../../interfaces/queryParams';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { createOrganizationUser } from '../../organization';
import { ICreateOrganizationUser } from '../../../interfaces/organizations';

const useCreateOrganizationUser = ({
  params,
  options,
  silent = false,
}: IMutationRequest<OrganizationQueryParams>) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (body: ICreateOrganizationUser) => {
      await createOrganizationUser(params.organizationId, body);
    },
    onSuccess: () => {
      if (!silent)
        displaySuccessNotification(
          'Api.Mutations.Organizations.CreateOrganizationUser.success'
        );

      options.successFb?.();
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'Api.Mutations.Organizations.CreateOrganizationUser.failure'
      );
      options.errorFb?.();
    },
    resetOnResult: true,
  });
};

export default useCreateOrganizationUser;
