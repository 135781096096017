import { IMutationProps } from '../../../interfaces/ui';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import {
  displayErrorArrayNotificationsWithoutTranslations,
  displayErrorNotification,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { ICreateCaseGroupConversationSchedule } from '../../../interfaces/conversationSchedules';
import { createCaseGroupConversationSchedule } from '../../conversationSchedules';
import { IErrorDefinition } from '../../../interfaces/response';

type useCreateCaseGroupConversationScheduleParams = {
  organizationId: string;
  caseGroupId: string;
};

const useCreateCaseGroupConversationSchedule = (
  params: useCreateCaseGroupConversationScheduleParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (data: ICreateCaseGroupConversationSchedule) => {
      await createCaseGroupConversationSchedule(
        params.organizationId,
        params.caseGroupId,
        data
      );
    },
    onSuccess: () => {
      displaySuccessNotification(
        'Api.Mutations.ConversationSchedules.CreateCaseGroupConversationSchedule.success'
      );

      if (options.successFb) options.successFb();
    },
    onErrorArray: (errors: IErrorDefinition[]) => {
      displayErrorNotification(
        'Api.Mutations.ConversationSchedules.CreateCaseGroupConversationSchedule.failure'
      );

      displayErrorArrayNotificationsWithoutTranslations(errors);

      if (options.errorFb) options.errorFb();
    },
    resetOnResult: true,
  });
};

export default useCreateCaseGroupConversationSchedule;
