import { formatDate } from 'helpers/dates';
import React from 'react';
import { useIntl } from 'react-intl';
import { PhaseDayDefinition } from '../../../../types';

type CaseConversationsScheduleDayDescriptionProps = {
  definition: PhaseDayDefinition;
  showMore?: boolean;
};

const CaseConversationsScheduleDayDescription = ({
  definition,
  showMore = false,
}: CaseConversationsScheduleDayDescriptionProps) => {
  const intl = useIntl();

  const translationId = `Cases.CaseConversationsSchedule.CaseConversationsScheduleContent.CaseConversationsScheduleHeader.phaseDescription${
    definition.endDate ? 'Expanded' : 'Simple'
  }`;

  return (
    <>
      <div className="CaseConversationsScheduleContent__description-item user-select-none">
        <span
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage(
              {
                id: translationId,
              },
              {
                phaseName: `<b class="text-muted">${definition.displayCode}</b>`,
                day: `<b class="text-muted">${definition.nthDay}</b>`,
                dayOf: definition.daysDuration,
                startDate: formatDate(definition.startDate, {
                  ignoreTime: false,
                  daysLeadingZeros: true,
                }),
                endDate: definition.endDate
                  ? formatDate(definition.endDate, {
                      ignoreTime: false,
                      daysLeadingZeros: true,
                    })
                  : definition.endDate,
              }
            ),
          }}
        ></span>
      </div>
      {showMore && (
        <div className="CaseConversationsScheduleContent__description-item user-select-none">
          <span>...</span>
        </div>
      )}
    </>
  );
};

export default CaseConversationsScheduleDayDescription;
