import { useCallback } from 'react';
import { IEducationTranslatable } from '../index';

export const useUtils = <T extends IEducationTranslatable>(
  values: T,
  setValues: (data: any) => void
) => {
  const handleAddTranslation = useCallback(() => {
    setValues({
      ...values,
      translations: [
        ...values.translations,
        {
          culture: '',
          value: {
            name: '',
            description: '',
          },
        },
      ],
    });
  }, [setValues, values]);

  const areTranslationsEmpty = useCallback((values: T) => {
    let isAnyEmpty: boolean = false;

    for (const translation of values.translations) {
      if (
        translation.culture.length === 0 ||
        translation.value.name.length === 0 ||
        translation.value.description.length === 0
      ) {
        isAnyEmpty = true;
        break;
      }
    }

    return isAnyEmpty;
  }, []);

  return { handleAddTranslation, areTranslationsEmpty };
};
