import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import {
  displayErrorArrayNotificationsWithoutTranslations,
  displayErrorNotification,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { IMutationProps } from '../../../interfaces/ui';
import { IUpdateCaseGroupConversationSchedule } from '../../../interfaces/conversationSchedules';
import { updateCaseGroupConversationSchedule } from '../../conversationSchedules';
import { IErrorDefinition } from '../../../interfaces/response';

type useUpdateCaseGroupConversationScheduleParams = {
  organizationId: string;
  caseGroupId: string;
};

export type useUpdateCaseGroupConversationScheduleData = {
  id: string;
  data: IUpdateCaseGroupConversationSchedule;
};

const useUpdateCaseGroupConversationSchedule = (
  params: useUpdateCaseGroupConversationScheduleParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (
      data: useUpdateCaseGroupConversationScheduleData
    ) => {
      await updateCaseGroupConversationSchedule(
        params.organizationId,
        params.caseGroupId,
        data.id,
        data.data
      );
    },
    onSuccess: () => {
      displaySuccessNotification(
        'Api.Mutations.ConversationSchedules.UpdateCaseGroupConversationSchedule.success'
      );

      if (options.successFb) options.successFb();
    },
    onErrorArray: (errors: IErrorDefinition[]) => {
      displayErrorNotification(
        'Api.Mutations.ConversationSchedules.UpdateCaseGroupConversationSchedule.failure'
      );

      displayErrorArrayNotificationsWithoutTranslations(errors);

      if (options.errorFb) options.errorFb();
    },
    resetOnResult: true,
  });
};

export default useUpdateCaseGroupConversationSchedule;
