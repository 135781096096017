import React from 'react';
import { DashboardTemplate } from '../../../../../../interfaces/dashboards';
import { useResolveDateSelection } from '../useResolveDateSelection';
import { useResolveFilters } from '../useResolveFilters';
import { usePathContext } from './hooks/usePathContext';

type DashboardTemplatePathResolverProps = {
  template: DashboardTemplate;
  children: (resolvedTemplate: DashboardTemplate) => JSX.Element;
};

const PathResolver = ({
  template,
  children,
}: DashboardTemplatePathResolverProps) => {
  const pathContext = usePathContext();
  const filtersResolvedTemplate = useResolveFilters(template, pathContext);
  const dateSelectionResolvedTemplate = useResolveDateSelection(
    filtersResolvedTemplate,
    pathContext
  );

  return <div>{children(dateSelectionResolvedTemplate)}</div>;
};

export default PathResolver;
