import React from 'react';
import { FormattedMessage } from 'react-intl';
import PageHeader from '../../../../components/PageHeader';
import { FormatHelpers } from '../../../../helpers/format';
import {
  generateRoute,
  RouteDefinitions,
} from '../../../../helpers/routeDefinitions';
import TreatmentPhases from './components/TreatmentPhases';
import { useGetTreatmentPlanName } from './hooks/useGetTreatmentPlanName';
import TreatmentPlanDataProvider from './providers/TreatmentPlanDataProvider';

const TreatmentPlan = () => {
  const getPlanName = useGetTreatmentPlanName();

  return (
    <TreatmentPlanDataProvider>
      {(organization, categories) => (
        <PageHeader
          breadcrumbs={[
            {
              text: <FormattedMessage id="Header.myOrganizations" />,
              link: null,
              key: 'my-organizations',
            },
            {
              text: FormatHelpers.FormatOrganization(organization),
              link: generateRoute(RouteDefinitions.MY_ORGANIZATION, {
                organizationId: organization.id,
              }),
              key: 'name',
            },
            {
              text: <FormattedMessage id="Header.treatmentPlans" />,
              link: null,
              key: 'treatment-plans',
            },
            {
              text: getPlanName(categories),
              link: null,
              key: 'treatment-plan',
            },
          ]}
          content={<TreatmentPhases />}
        />
      )}
    </TreatmentPlanDataProvider>
  );
};

export default TreatmentPlan;
