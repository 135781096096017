import React, { useContext, useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faSpellCheck, faUndo } from '@fortawesome/pro-light-svg-icons';
import JsonEditor from '../../../../../../../../../../../../../../../../components/JsonEditor';
import { useControlledValue } from '../../../../../../../../../../../../../../../../hooks/useControlledValue';
import { useTypedContext } from '../../../../../../../../../../../../../../../../hooks/useTypedContext';
import { ReactStateTuple } from '../../../../../../../../../../../../../../../../interfaces/ui';
import { ValidationContext } from '../../../../types';
import LoadingButton from '../../../../../../../../../../../../../../../../components/LoadingButton';
import { useRevalidateTemplate } from './hooks/useRevalidateTemplate';
import { ValidationResult } from '../../../../../../../../types';
import { OnCompleteCallbackContext } from '../../../../../../../../../../types';
import { v4 as uuid } from 'uuid';

const Editor = () => {
  const [validation, setValidation] =
    useTypedContext<ReactStateTuple<ValidationResult>>(ValidationContext);

  const onComplete = useContext(OnCompleteCallbackContext);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const { value, handleChange, handleReset } = useControlledValue<string>(
    JSON.stringify(validation.template, null, 2)
  );

  const { revalidate, validating } = useRevalidateTemplate(setErrorMessage);

  const isInitial = useMemo(
    () => value === JSON.stringify(validation.template, null, 2),
    [validation.template, value]
  );

  const resetEditor = () => {
    handleReset();
    setErrorMessage(null);
  };

  const onEditorChange = (payload: string) => {
    handleChange(payload);

    if (!validation.ready_to_complete) return;
    setValidation((prev) => ({ ...prev, ready_to_complete: false }));
  };

  const handleRevalidate = async () =>
    await revalidate({
      conflicts: validation.conflicts,
      content: value ?? '',
    });

  const handleComplete = () =>
    onComplete?.({ ...validation.template, id: uuid() });

  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex flex-column gap-2">
        <JsonEditor
          height="300px"
          value={value ?? ''}
          onChange={onEditorChange}
          onError={setErrorMessage}
        />
        {errorMessage && (
          <div className="d-flex align-items-center bg-danger rounded shadow-sm">
            <span
              className="text-white py-2 px-2 fs-6"
              style={{ fontSize: '0.8vw' }}
            >
              {errorMessage}
            </span>
          </div>
        )}
      </div>
      <div className="d-flex align-items-center gap-2">
        <Button
          color="primary"
          disabled={isInitial}
          onClick={resetEditor}
          outline={true}
          block={true}
        >
          <FontAwesomeIcon icon={faUndo} />
        </Button>
        <LoadingButton
          isLoading={validating}
          onClick={handleRevalidate}
          disabled={!!errorMessage}
          color="primary"
          outline={true}
          block={true}
        >
          <FontAwesomeIcon icon={faSpellCheck} />
        </LoadingButton>
        <Button
          onClick={handleComplete}
          disabled={
            !!errorMessage || !isInitial || !validation.ready_to_complete
          }
          color="primary"
          outline={true}
          block={true}
        >
          <FontAwesomeIcon icon={faSave} />
        </Button>
      </div>
    </div>
  );
};

export default Editor;
