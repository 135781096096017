import EventLogScreen from 'components/EventLogScreen';
import useRequiredParams from 'hooks/useRequiredParams';
import { ActivityTypes } from 'interfaces/activities';

const AVAILABLE_EVENT_TYPES = [
  ActivityTypes.CaseDeleted,
  ActivityTypes.CaseModified,
  ActivityTypes.CaseOpened,
  ActivityTypes.CaseFormsAssignmentsAdded,
  ActivityTypes.CaseFormsAssignmentsDeleted,
  ActivityTypes.CaseFormsAssignmentsModified,
  ActivityTypes.CaseHistoryAdded,
];

const EventLogsTab = () => {
  const { caseId } = useRequiredParams<{
    caseId: string;
  }>(['caseId']);

  return (
    <div className="CaseCard__tab-container px-3">
      <EventLogScreen
        availableEventTypes={AVAILABLE_EVENT_TYPES}
        resourceId={caseId}
      />
    </div>
  );
};

export default EventLogsTab;
