import { useTheme } from '@mui/material';
import {
  DashboardTemplateChartType,
  DashboardTemplateSeries,
  DashboardTemplateSplitType,
  DateGranularity,
} from 'interfaces/dashboards';
import React, { useCallback, useMemo, useRef } from 'react';
import {
  Legend,
  Line,
  LineChart as ReLineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useTypedContext } from '../../../../../../../../../../../../../../hooks/useTypedContext';
import { useSeriesColor } from '../../../../hooks/useSeriesColor';
import { VisualizationDataContext } from '../../../../types';
import { ChartViewHelpers } from '../../helpers';
import { useChartWidthAxisInterval } from '../../hooks/useChartWidthAxisInterval';
import { useNonPieChartAccumulation } from '../../hooks/useNonPieChartAccumulation';
import { useNonPieChartEmptyPoints } from '../../hooks/useNonPieChartEmptyPoints';
import { useNonPieChartFormattedData } from '../../hooks/useNonPieChartFormattedData';
import ChartPlaceholder from '../ChartPlaceholder';
import { ChartTooltip } from '../ChartTooltip';
import HorizontalLegend from '../HorizontalLegend';
import { useUtils } from './hooks/useUtils';

type LineChartProps = {
  config: DashboardTemplateSeries[];
  sequence: number;
  dateGranularity?: DateGranularity;
};

const LineChart = ({ config, sequence, dateGranularity }: LineChartProps) => {
  const theme = useTheme();
  const chartRef = useRef<HTMLDivElement>(null);
  const chartsData = useTypedContext(VisualizationDataContext);

  let formattedData = useNonPieChartFormattedData(
    chartsData.sessions.current.data,
    config,
    dateGranularity,
    config.at(0)?.split_by?.type === DashboardTemplateSplitType.Question ||
      config.at(0)?.split_by?.type === DashboardTemplateSplitType.Doctor ||
      config.at(0)?.split_by?.type === DashboardTemplateSplitType.Grasp
  );

  const nonEmptyData = useNonPieChartEmptyPoints(formattedData);

  const data = useNonPieChartAccumulation(nonEmptyData, config);

  const { getHorizontalChartTick, getVerticalChartTick } =
    useUtils(dateGranularity);

  const getWidthAxisInterval = useChartWidthAxisInterval();
  const { getColor } = useSeriesColor();

  const renderLines = useMemo(() => {
    return ChartViewHelpers.GetDataKeys(data).map((key, index) => {
      return (
        <Line
          key={key}
          connectNulls={true}
          type="monotone"
          dataKey={key}
          stroke={getColor(index)}
          fill={getColor(index)}
        />
      );
    });
  }, [data, getColor]);

  const renderHorizontalLegend = useCallback(
    (props: any) => <HorizontalLegend {...props} sequence={sequence} />,
    [sequence]
  );

  if (ChartViewHelpers.AreEmptyFormattedData(data))
    return <ChartPlaceholder type={DashboardTemplateChartType.LineChart} />;

  return (
    <ResponsiveContainer
      ref={chartRef}
      className="AwareCasesLineChart"
      width="100%"
      height="100%"
    >
      <ReLineChart data={data} margin={{ top: 40, right: 50 }}>
        <XAxis
          dataKey="name"
          tick={getHorizontalChartTick}
          tickLine={{ stroke: theme.palette.text.primary }}
          tickMargin={15}
          interval={getWidthAxisInterval(data, chartRef)}
          padding={{ left: 10 }}
          axisLine={{ stroke: theme.palette.text.primary }}
        />
        <YAxis
          axisLine={false}
          tickMargin={4}
          padding={{ bottom: 8 }}
          tick={getVerticalChartTick}
          tickLine={{ stroke: theme.palette.text.primary }}
        />
        <Tooltip
          cursor={{ fill: 'transparent', stroke: theme.palette.text.primary }}
          content={ChartTooltip}
          wrapperStyle={{ zIndex: 10 }}
        />
        <Legend
          className="AwareCasesLineChart__legend"
          iconType="circle"
          verticalAlign="top"
          align="center"
          layout="horizontal"
          wrapperStyle={{ top: 10, left: 30 }}
          content={renderHorizontalLegend}
        />
        {renderLines}
      </ReLineChart>
    </ResponsiveContainer>
  );
};

export default LineChart;
