import React, { SetStateAction } from 'react';
import { Field } from 'formik';
import { Badge, Col, Form as ReactstrapForm, Label, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import FieldInput from '../../../../../../../../../../components/FieldInput';
import { returnAllDataFromResponseData } from '../../../../../../../../../../helpers/utils/reactQueryHelpers';
import QueryLoaded from '../../../../../../../../../../components/QueryLoaded';
import useInfiniteUserInOrganizationsWithRoles from '../../../../../../../../../../api/queries/organizations/useInfiniteUserInOrganizationsWithRoles';
import { useInitQuery } from '../../../../../../../../../../hooks/queries/useInitQuery';
import { useOrganizationPathParams } from '../../../../../../../../../../hooks/paths/useOrganizationPathParams';
import ImageUpload from '../../components/ImageUpload';
import { OpenHookValues } from '../../../../../../../../../../hooks/useOpen';
import { OrganizationInfoTabTypes } from '../../types';

type InfoFormProps = {
  editMode: OpenHookValues;
  values: OrganizationInfoTabTypes.FormValues;
  setValues: (
    values: SetStateAction<OrganizationInfoTabTypes.FormValues>,
    shouldValidate?: boolean
  ) => void;
};

const InfoForm = ({ editMode, setValues, values }: InfoFormProps) => {
  const intl = useIntl();
  const { organizationId } = useOrganizationPathParams();

  const userInOrganizationsWithRoles = useInfiniteUserInOrganizationsWithRoles({
    params: { organizationId, pageSize: 1000 },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(userInOrganizationsWithRoles);

  return (
    <QueryLoaded query={userInOrganizationsWithRoles}>
      {(data) => (
        <Row>
          <Col xs={6}>
            <ReactstrapForm className="d-flex flex-column gap-3">
              <div className="row align-items-center mb-0">
                <Label for="companyLogo" xs={5}>
                  <FormattedMessage id="Organizations.CompanyLogo" />
                </Label>
                <Col xs={7}>
                  <ImageUpload
                    editMode={editMode}
                    values={values}
                    setValues={setValues}
                    name="logo"
                  />
                </Col>
              </div>
              <div className="row align-items-center mb-0">
                <Label for="name" xs={5}>
                  <FormattedMessage id="General.Name" />
                </Label>
                <Col xs={7}>
                  {editMode.isOpen ? (
                    <Field
                      required
                      id="name"
                      name="name"
                      placeholder={intl.formatMessage({
                        id: 'Organizations.InsertOrganizationName',
                      })}
                      component={FieldInput}
                    />
                  ) : (
                    <span>{values.name}</span>
                  )}
                </Col>
              </div>
              <div className="row align-items-center mb-0">
                <Label for="description" xs={5}>
                  <FormattedMessage id="General.Description" />
                </Label>
                <Col xs={7}>
                  {editMode.isOpen ? (
                    <Field
                      required
                      id="description"
                      name="description"
                      placeholder={intl.formatMessage({
                        id: 'Organizations.InsertOrganizationDescription',
                      })}
                      component={FieldInput}
                    />
                  ) : (
                    <span>{values.description}</span>
                  )}
                </Col>
              </div>
              <div className="row align-items-center mb-0">
                <Label for="license" xs={5}>
                  <FormattedMessage id="Organizations.License" />
                </Label>
                <Col xs={7}>
                  <Badge
                    color="primary"
                    className="fs-4 rounded-pill text-uppercase"
                  >
                    <FormattedMessage id="Organizations.StandardLicense" />
                  </Badge>
                </Col>
              </div>
              <div className="row align-items-center mb-0">
                <Label for="numberOfUsers" xs={5}>
                  <FormattedMessage id="Organizations.NumberOfUsers" />
                </Label>
                <Col xs={7}>
                  <span>{returnAllDataFromResponseData(data).length}</span>
                </Col>
              </div>
              <div className="row align-items-center mb-0">
                <Label for="image" xs={5}>
                  <FormattedMessage id="General.Image" />
                </Label>
                <Col xs={7}>
                  <ImageUpload
                    editMode={editMode}
                    values={values}
                    setValues={setValues}
                    name="image"
                  />
                </Col>
              </div>
            </ReactstrapForm>
          </Col>
        </Row>
      )}
    </QueryLoaded>
  );
};

export default InfoForm;
