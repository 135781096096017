import React from 'react';
import TabContainer from '../../../../../../components/Tabs/components/TabContainer';
import { useTabs } from './hooks/useTabs';
import useOpen from '../../../../../../hooks/useOpen';
import OrganizationHeader from './components/OrganizationHeader';
import OrganizationDetailsForm from './forms/OrganizationDetailsForm';

const GenericOrganizationDetails = () => {
  const editMode = useOpen(false);
  const { tabItems, onTabChange } = useTabs();

  return (
    <>
      <OrganizationHeader editMode={editMode} />
      <OrganizationDetailsForm editMode={editMode} />
      <div className="mt-4">
        <TabContainer items={tabItems} onTabChange={onTabChange} />
      </div>
    </>
  );
};

export default GenericOrganizationDetails;
