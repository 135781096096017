import React from 'react';
import { FormikValuesProps } from '../../../../../../../../interfaces/forms';
import { MaterialModalFormValues } from '../../../../types';
import InputFieldWithoutImage from '../../../../../../../../components/InputFieldWithoutImage';
import { Field } from 'formik';
import FieldInput from '../../../../../../../../components/FieldInput';
import FieldSelect from '../../../../../../../../components/FieldSelect';
import { Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import MaterialTranslationsForm from '../../../../../components/MaterialTranslationsForm';
import { useTranslations } from '../../../../../../../../hooks/useTranslations';
import { useFormOptions } from './hooks/useFormOptions';

type FillInfoStepProps = FormikValuesProps<MaterialModalFormValues> & {
  selectCategory: boolean;
};

const FillInfoStep = ({
  values,
  setValues,
  selectCategory,
}: FillInfoStepProps) => {
  const { wrapWithIntl, attachPrefix } = useTranslations(
    'Education.MaterialModal.MaterialModalBody.FillInfoStep'
  );

  const { caseCategoriesOptions, materialCategoriesOptions } = useFormOptions();

  const readOnlyCategory = !!values.material_category_id && !selectCategory;

  return (
    <div className="d-flex flex-column gap-3">
      <InputFieldWithoutImage
        gray={true}
        noMargin={true}
        disabled={true}
        labelTranslationId={attachPrefix('organizationId')}
        inputId="organizationId"
      >
        <Field
          required={true}
          placeholder={wrapWithIntl(attachPrefix('organizationId'))}
          id="organizationId"
          name="organization_name"
          type="text"
          disabled={true}
          component={FieldInput}
        />
      </InputFieldWithoutImage>
      <InputFieldWithoutImage
        gray={true}
        noMargin={true}
        labelTranslationId={attachPrefix('name')}
        inputId="name"
      >
        <Field
          required={true}
          placeholder={wrapWithIntl(attachPrefix('name'))}
          id="name"
          name="name"
          type="text"
          component={FieldInput}
        />
      </InputFieldWithoutImage>
      <InputFieldWithoutImage
        unstyled={true}
        noMargin={true}
        labelTranslationId={attachPrefix('description')}
        inputId="description"
      >
        <Field
          required={true}
          id="description"
          name="description"
          type="textarea"
          rows={5}
          placeholder={wrapWithIntl(attachPrefix('description'))}
          component={FieldInput}
        />
      </InputFieldWithoutImage>
      {readOnlyCategory && (
        <InputFieldWithoutImage
          gray={true}
          noMargin={true}
          labelTranslationId={attachPrefix('materialCategoryId')}
          inputId="materialCategoryId"
        >
          <Field
            required={true}
            disabled={true}
            id="materialCategoryId"
            name="material_category_id"
            defaultText={wrapWithIntl(attachPrefix('materialCategoryId'))}
            type="select"
            options={materialCategoriesOptions}
            component={FieldSelect}
          />
        </InputFieldWithoutImage>
      )}
      {selectCategory && (
        <InputFieldWithoutImage
          gray={true}
          noMargin={true}
          labelTranslationId={attachPrefix('materialCategoryId')}
          inputId="materialCategoryId"
        >
          <Field
            required={true}
            id="materialCategoryId"
            name="material_category_id"
            defaultText={wrapWithIntl(attachPrefix('materialCategoryId'))}
            type="select"
            options={materialCategoriesOptions}
            component={FieldSelect}
          />
        </InputFieldWithoutImage>
      )}
      <InputFieldWithoutImage
        gray={true}
        noMargin={true}
        labelTranslationId={attachPrefix('caseCategoryId')}
        inputId="caseCategoryId"
      >
        <Field
          required={true}
          id="caseCategoryId"
          name="case_category_id"
          defaultText={wrapWithIntl(attachPrefix('caseCategoryId'))}
          type="select"
          options={caseCategoriesOptions}
          component={FieldSelect}
        />
      </InputFieldWithoutImage>
      <div className="d-flex align-items-center gap-2">
        <Field
          id="is_premium"
          name="is_premium"
          type="checkbox"
          checked={values.is_premium}
          component={FieldInput}
        />
        <Label htmlFor="is_premium" className="mb-0">
          <FormattedMessage id={attachPrefix('isPremium')} />
        </Label>
      </div>
      <div className="d-flex align-items-center gap-2">
        <Field
          id="is_default"
          name="is_default"
          type="checkbox"
          checked={values.is_default}
          component={FieldInput}
        />
        <Label htmlFor="is_default" className="mb-0">
          <FormattedMessage id={attachPrefix('isDefault')} />
        </Label>
      </div>
      <MaterialTranslationsForm values={values} setValues={setValues} />
    </div>
  );
};

export default FillInfoStep;
