import React from 'react';
import { FormattedMessage } from 'react-intl';

const EmptyTable = () => {
  return (
    <div
      className="EmptyTable w-100 h-100 d-flex flex-column align-items-center justify-content-center"
      style={{
        zIndex: 1,
      }}
    >
      <div className="ChangeCase__chart-placeholder__bubble" />
      <div className="ChangeCase__chart-placeholder__text">
        <FormattedMessage id="CustomTable.defaultTranslation" />
      </div>
    </div>
  );
};

export default EmptyTable;
