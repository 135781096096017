import classNames from 'classnames';
import React from 'react';
import SVG from 'react-inlinesvg';
import { Label } from 'reactstrap';

type QuestionProps = {
  imageSrc: string;
  header: string;
  question: string;
  showIcon: boolean | null;
  showTitle: boolean | null;
  showDescription: boolean | null;
  bigQuestion?: boolean;
};

const ModalQuestion = ({
  imageSrc,
  header,
  question,
  showIcon,
  showDescription,
  showTitle,
  bigQuestion = false,
}: QuestionProps) => {
  const canShowIcon = showIcon === null || showIcon;
  const canShowDescription = showDescription === null || showDescription;
  const canShowTitle = showTitle === null || showTitle;

  return (
    <div className="QuestionModal__question">
      {canShowIcon && !bigQuestion && <SVG src={imageSrc} />}
      <div
        className={classNames('QuestionModal__question__text-wrapper w-100')}
      >
        {canShowTitle && (
          <div
            className={classNames({
              'QuestionModal__question__text-wrapper--big': bigQuestion,
            })}
          >
            {header}
          </div>
        )}
        {canShowDescription &&
          question &&
          (bigQuestion ? (
            <Label className="mt-4">{question}:</Label>
          ) : (
            <div className="QuestionModal__question__text-wrapper__question-text">
              {question}
            </div>
          ))}
      </div>
    </div>
  );
};

export default ModalQuestion;
