import React, { useEffect, useState } from 'react';

//helpers
import useTimelineAnnotations from 'api/queries/timelines/useTimelineAnnotations';
import useTimelinesDayByDay from 'hooks/useTimelinesDayByDay';
import useInfiniteCaseHistory from 'api/queries/cases/useInfiniteCaseHistory';

//helpers
import { HISTORY_PAGE_SIZE } from 'helpers/constants';
import { getUserId } from 'helpers/utils/auth';

//types
import { AmplitudeModalData } from 'interfaces/timeline';

//components
import BubbleChart from './BubbleChart';
import AmplitudeChartModal from '../AmplitudeChartModal';
import useUserContextAssessmentFormsForPeriod from 'api/queries/assessmentForms/forms/period/useUserContextAssessmentFormsForPeriod';
import useOrganizationContextAssessmentFormsForPeriod from 'api/queries/assessmentForms/forms/period/useOrganizationContextAssessmentFormsForPeriod';
import useUserPathBasedBrowsingContext from 'hooks/useUserPathBasedBrowsingContext';
import { useDispatch } from 'react-redux';
import { setFormsDayByDayViewRefetchRequest } from 'modules/Cases/store/actions';
import useCaseContextTimelineObservations from 'api/queries/cases/useCaseContextTimelineObservations';
import useUserContextTimelineObservations from 'api/queries/timelines/useUserContextTimelineObservations';

type DayByDayProps = {
  timelineId: string;
  selectedDates: {
    from: Date;
    to: Date;
  };
  userId?: string;
  refetchRequest: boolean;
};

const DayByDayChart = ({
  timelineId,
  selectedDates,
  userId,
  refetchRequest,
}: DayByDayProps) => {
  const loggedUserId = getUserId();
  const dispatch = useDispatch();

  const {
    organizationId = '',
    caseId = '',
    isOrganizationContext,
    isRegularUserContext,
  } = useUserPathBasedBrowsingContext();

  const organizationContextTimelineObservations =
    useCaseContextTimelineObservations({
      params: {
        organizationId,
        caseId,
        timelineId,
        selectedDates,
        grouped: true,
      },
      options: { enabled: isOrganizationContext },
    });

  const userContextTimelineObservations = useUserContextTimelineObservations({
    params: {
      timelineId,
      selectedDates,
      grouped: true,
    },
    options: { enabled: isRegularUserContext },
  });

  const timelineObservationsQuery = isRegularUserContext
    ? userContextTimelineObservations
    : organizationContextTimelineObservations;

  const { data: timelineAnnotations } = useTimelineAnnotations({
    params: { timelineId },
  });

  const [amplitudesModalData, setAmplitudesModalData] =
    useState<AmplitudeModalData | null>(null);

  const selectAmplitudes = (data: AmplitudeModalData) =>
    setAmplitudesModalData(data);
  const closeModal = () => setAmplitudesModalData(null);

  const isCaseFetchEnabled = !!caseId;
  const { history: historyData } = useInfiniteCaseHistory({
    params: {
      caseId,
      organizationId,
      pageSize: HISTORY_PAGE_SIZE,
      selectedDates,
    },
    options: { enabled: isCaseFetchEnabled },
  });

  const userContextAssessmentQueryResult =
    useUserContextAssessmentFormsForPeriod({
      params: {
        userId,
        timelineId,
        selectedDates,
      },
      options: { enabled: isRegularUserContext },
    });

  const organizationContextAssessmentQueryResult =
    useOrganizationContextAssessmentFormsForPeriod({
      params: {
        organizationId,
        caseId,
        timelineId,
        selectedDates,
      },
      options: { enabled: isOrganizationContext },
    });

  const assessmentQueryResult = isRegularUserContext
    ? userContextAssessmentQueryResult
    : organizationContextAssessmentQueryResult;

  useEffect(() => {
    if (refetchRequest) {
      assessmentQueryResult.refetch();
      dispatch(setFormsDayByDayViewRefetchRequest(false));
    }
  }, [refetchRequest, assessmentQueryResult, dispatch]);

  const bubbleData = useTimelinesDayByDay(
    selectedDates,
    timelineObservationsQuery.data,
    timelineAnnotations,
    historyData,
    assessmentQueryResult && assessmentQueryResult.data
      ? assessmentQueryResult.data
      : undefined,
    loggedUserId
  );

  return (
    <div className="cardContainer h-100">
      <BubbleChart
        selectAmplitudes={selectAmplitudes}
        data={bubbleData}
        dateRange={selectedDates}
        isLoading={
          timelineObservationsQuery.isFetching ||
          assessmentQueryResult.isFetching
        }
      />
      <AmplitudeChartModal closeModal={closeModal} data={amplitudesModalData} />
    </div>
  );
};

export default DayByDayChart;
