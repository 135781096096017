import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { IAssignmentUpsertBody } from 'interfaces/assessmentForms';
import { organizationContextUpdateFormAssignment } from 'api/graspManagement';
import { IMutationProps } from '../../../../interfaces/ui';

interface IUpdateAssignment
  extends Omit<
    IAssignmentUpsertBody,
    'userId' | 'contextType' | 'contextObjectId'
  > {}

type useOrganizationContextUpdateFormAssignmentParams = {
  organizationId: string;
  caseId: string;
  timelineId: string;
  userId: string;
  assignmentId: string;
};

const useOrganizationContextUpdateFormAssignment = (
  params: useOrganizationContextUpdateFormAssignmentParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification<IUpdateAssignment, void>({
    asyncApiFunction: async ({
      dateTo,
      formCode,
      scheduleInfo,
      dateFrom,
      isEditableByOrganizationMembers,
      activeHoursAfter,
      activeHoursBefore,
    }) => {
      await organizationContextUpdateFormAssignment(
        params.organizationId,
        params.userId,
        formCode,
        params.caseId,
        params.timelineId,
        params.assignmentId,
        scheduleInfo,
        dateTo,
        dateFrom,
        isEditableByOrganizationMembers,
        activeHoursBefore,
        activeHoursAfter
      );
    },
    onSuccess: () => {
      displaySuccessNotification('CaseDetails.assignmentUpdateSuccess');

      if (options.successFb) options.successFb();
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'CaseDetails.assignmentUpdateError'
      );

      if (options.errorFb) options.errorFb();
    },
    resetOnResult: true,
  });
};

export default useOrganizationContextUpdateFormAssignment;
