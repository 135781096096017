import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { generateGroupFeedbackData } from '../../caseGroups';
import { OrganizationQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

interface GenerateGroupFeedbackDataParams extends OrganizationQueryParams {
  projectId: string;
  groupId: string;
}

const useGenerateGroupFeedbackData = ({
  params,
  options,
}: IQueryRequest<GenerateGroupFeedbackDataParams>) => {
  const { organizationId, projectId, groupId } = params;

  return useQuery({
    ...options,
    queryKey: [
      QueryKeys.generateGroupFeedbackData,
      organizationId,
      projectId,
      groupId,
    ],
    queryFn: async () => {
      const { data } = await generateGroupFeedbackData(
        organizationId,
        projectId,
        groupId
      );

      return data.data;
    },
  });
};

export default useGenerateGroupFeedbackData;
