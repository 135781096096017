import { useState } from 'react';

enum SelectionMode {
  INCLUDING = 'Including',
  EXCLUDING = 'Excluding',
}

export type GroupSelectionIdentifiers = {
  id: string;
};

export interface IRowSelection<T extends GroupSelectionIdentifiers> {
  included: T[];
  excluded: T[];
  toggleRow: ({ ...args }: T) => void;
  toggleAllRows: () => void;
  isRowSelected: (id: string) => boolean;
  resetSelection: () => void;
  rowsSelectedCount: number;
  isSelectAllChecked: boolean;
  isSelectAllIndeterminate: boolean;
  isEmptySelection: boolean;
  selectionStatusText: string;
}

export const useRowSelection = <T extends GroupSelectionIdentifiers>(
  totalItems: number
) => {
  const [selectionMode, setSelectionMode] = useState<SelectionMode>(
    SelectionMode.INCLUDING
  );
  const [selectedAll, setSelectedAll] = useState<boolean>(false);

  const [included, setIncluded] = useState<T[]>([]);
  const [excluded, setExcluded] = useState<T[]>([]);

  const toggleRow = ({ ...args }: T) => {
    if (selectionMode === SelectionMode.EXCLUDING) {
      const existingInExcluded = excluded.find((p) => p.id === args.id);

      if (existingInExcluded) {
        setExcluded((state) =>
          state.filter((p) => p.id !== existingInExcluded.id)
        );
      } else {
        setExcluded([
          ...excluded,
          {
            ...args,
          },
        ]);
      }
    } else {
      const existingInIncluded = included.find((p) => p.id === args.id);

      if (existingInIncluded) {
        setIncluded((state) =>
          state.filter((p) => p.id !== existingInIncluded.id)
        );
      } else {
        setIncluded([
          ...included,
          {
            ...args,
          },
        ]);
      }
    }
  };

  const resetSelection = () => {
    setSelectedAll(false);
    setSelectionMode(SelectionMode.INCLUDING);
    setIncluded([]);
    setExcluded([]);
  };

  const isSelectAllChecked = selectedAll && excluded.length === 0;
  const isSelectAllIndeterminate =
    selectedAll && excluded.length !== 0 && totalItems !== excluded.length;

  const rowsSelectedCount =
    selectionMode === SelectionMode.EXCLUDING
      ? totalItems
        ? totalItems - excluded.length
        : 0
      : included.length;

  const isEmptySelection = rowsSelectedCount === 0;

  const toggleAllRows = () => {
    if (selectionMode === SelectionMode.INCLUDING) {
      setSelectionMode(SelectionMode.EXCLUDING);
    } else {
      setSelectionMode(SelectionMode.INCLUDING);
    }

    setIncluded([]);
    setExcluded([]);

    setSelectedAll((selectedAll) => !selectedAll);
  };

  const selectionStatusText = `Selected ${rowsSelectedCount} / ${totalItems}`;

  const isRowSelected = (id: string) => {
    let existingItem: T | undefined;

    if (selectionMode === SelectionMode.INCLUDING) {
      existingItem = included.find((p) => p.id === id);
    } else {
      existingItem = excluded.find((p) => p.id === id);
    }

    return selectionMode === SelectionMode.INCLUDING
      ? existingItem !== undefined
      : existingItem === undefined;
  };

  let rowSelection: IRowSelection<T> = {
    included,
    excluded,
    toggleRow,
    toggleAllRows,
    isRowSelected,
    resetSelection,
    rowsSelectedCount,
    isSelectAllChecked,
    isSelectAllIndeterminate,
    isEmptySelection,
    selectionStatusText,
  };

  return rowSelection;
};
