import { useCallback, useMemo, useRef } from 'react';
import {
  AssessmentQuestionType,
  AssessmentSummary,
} from '../../../../interfaces/assessmentForms';
import sortBySequence from '../../../../helpers/utils/sortBySequence';
import HeaderDetail from '../../../../components/HeaderDetail';
import { returnMeaningIcon } from '../../../../helpers/utils/icons';
import useContainer from 'hooks/useContainer';
import classNames from 'classnames';

type HeaderSummaryDashboardContentProps = {
  data?: AssessmentSummary[];
  isLoading: boolean;
  selectedIndex: number;
};

const BREAKPOINT_CONTAINER_WIDTH = 360;

const HeaderSummaryDashboardContent = ({
  data,
  isLoading,
  selectedIndex,
}: HeaderSummaryDashboardContentProps) => {
  const divRef = useRef<null | HTMLDivElement>(null);
  const { containerWidth } = useContainer(divRef, {
    isDayByDayScreen: false,
  });

  const selectedIndexOfLowerSummary = useMemo(() => {
    if (selectedIndex === 0) return selectedIndex + 1;

    return selectedIndex * 2 + 1;
  }, [selectedIndex]);

  const selectedIndexOfUpperSummary = useMemo(() => {
    if (selectedIndex === 0) return selectedIndex;

    return selectedIndex * 2;
  }, [selectedIndex]);

  const renderForm = useCallback(
    (form: AssessmentSummary) => {
      let orderQuestions = sortBySequence(form.questions);

      return orderQuestions.map((question, index) => {
        return (
          <div
            className="HeaderSummaryDashboard__grid-item"
            key={`${question.title}-${index}`}
          >
            <HeaderDetail
              noMaxWidth
              key={`questionIndex-${question.sequence}`}
              header={question.title}
              isLoading={isLoading}
              iconSrc={returnMeaningIcon(question.icon)}
              status={question.current_trend_sentiment}
              statusAnswer={question.current_trend_answer?.sentiment}
              color={question.color}
            >
              {question.type !== AssessmentQuestionType.RANGE ? (
                question.current_trend_answer?.title ?? ''
              ) : (
                <>
                  {!!question.average_question_value_for_7_days_back &&
                    question.average_question_value_for_7_days_back.toFixed(2)}
                </>
              )}
            </HeaderDetail>
          </div>
        );
      });
    },
    [isLoading]
  );

  const renderHeaderSummarySection = useCallback(
    (index: number) => {
      if (!data || !data[index]) {
        return <></>;
      }

      const isHigherThanBreakpoint =
        BREAKPOINT_CONTAINER_WIDTH < containerWidth;

      return (
        <div className="mt-2">
          <div className="HeaderSummaryDashboard__header">
            {data[index].title}
          </div>
          <div className="w-100 d-flex flex-column">
            <div
              className={classNames('HeaderSummaryDashboard__grid', {
                'HeaderSummaryDashboard__grid--columns-3':
                  isHigherThanBreakpoint,
                'HeaderSummaryDashboard__grid--columns-2':
                  !isHigherThanBreakpoint,
              })}
              data-testid="status-container"
            >
              {renderForm(data[index])}
            </div>
          </div>
        </div>
      );
    },
    [data, renderForm, containerWidth]
  );

  return (
    <div className="d-flex flex-column h-100" ref={divRef}>
      {renderHeaderSummarySection(selectedIndexOfUpperSummary)}
      <div className="mt-5">
        {renderHeaderSummarySection(selectedIndexOfLowerSummary)}
      </div>
    </div>
  );
};

export default HeaderSummaryDashboardContent;
