import useCaseDetails from 'api/queries/cases/useCaseDetails';
import useTimelineShareRequestsForCase from 'api/queries/cases/useTimelineShareRequestsForCase';
import Breadcrumbs from 'components/PageHeader/Breadcrumbs';

import {
  generateOrganizationPersonalitiesUrl,
  generateParticipantCheckRoute,
  generatePersonalityUrl,
  RedirectSource,
} from 'helpers/routeDefinitions';
import { ITimelineExtended } from 'interfaces/timeline';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Row } from 'reactstrap';
import { useOrganizationCasePathParams } from '../../../../../../hooks/paths/useOrganizationCasePathParams';
import CaseDetailsHeader from './components/CaseDetailsHeader';
import CaseTabContainer from './components/CaseTabContainer';
import RequestAlert from './components/RequestAlert';

export type SelectedTimelinesInfo = {
  selectedTimeline: null | ITimelineExtended;
  caseAggregatedTimeline: null | ITimelineExtended;
  dedicatedTimeline: null | ITimelineExtended;
};

const selectedTimelineInfoInitialData: SelectedTimelinesInfo = {
  caseAggregatedTimeline: null,
  dedicatedTimeline: null,
  selectedTimeline: null,
};

const GenericCaseDetailsView = () => {
  const { caseId, organizationId } = useOrganizationCasePathParams();

  const [selectedTimelinesInfo, setSelectedTimelinesInfo] =
    useState<SelectedTimelinesInfo>(selectedTimelineInfoInitialData);

  const onTimelineChange = (e: ChangeEvent<HTMLInputElement>) => {
    const timelineId = e.target.value;
    const changedTimeline = data?.timelines.find((p) => p.id === timelineId);

    setSelectedTimelinesInfo((prev) => ({
      ...prev,
      selectedTimeline: changedTimeline ?? null,
    }));
  };

  const { data: timelineRequests } = useTimelineShareRequestsForCase({
    params: { organizationId, caseId },
  });

  const {
    data,
    isLoading: isLoadingDetails,
    isSuccess: isCaseSuccess,
    refetch,
  } = useCaseDetails({
    params: { organizationId, caseId },
    options: { enabled: false },
  });

  const participant = data?.participants[0].personality;
  const name = `${participant?.first_name ?? ''} ${
    participant?.last_name ?? ''
  }`;

  const breadcrumbs = [
    {
      text: <FormattedMessage id="General.patients" />,
      link: generateOrganizationPersonalitiesUrl({ organizationId }),
      key: 'patients',
    },
    {
      text: name,
      link: participant
        ? generatePersonalityUrl({
            organizationId,
            personalityId: participant?.id,
          })
        : null,
      key: name,
    },
    {
      text: <FormattedMessage id="UserDashboard.cases" />,
      link: `/organizations/${organizationId}/cases`,
      key: 'cases',
    },
    {
      text: data?.title ?? '',
      link: null,
      key: 'title',
    },
  ];

  const renderedRequests = useMemo(() => {
    let requests: JSX.Element[] | null = null;
    if (timelineRequests && timelineRequests.length > 0) {
      const filteredData = timelineRequests?.filter((singleData) =>
        ['Rejected', 'Sent'].includes(singleData.status)
      );
      requests = filteredData.map((data) => (
        <RequestAlert key={data.id} data={data} />
      ));
    }
    return requests;
  }, [timelineRequests]);

  useEffect(() => {
    if (isCaseSuccess && data && data.timelines.length > 0) {
      const aggregateTimeline = data.timelines.find(
        (singleTimeline) => singleTimeline.is_master
      );

      const dedicatedTimeline = data.timelines.find(
        (timeline) => timeline.id === data?.participants[0]?.timeline?.id
      );

      setSelectedTimelinesInfo({
        caseAggregatedTimeline: aggregateTimeline ?? null,
        dedicatedTimeline: dedicatedTimeline ?? null,
        selectedTimeline: aggregateTimeline
          ? aggregateTimeline
          : dedicatedTimeline
          ? dedicatedTimeline
          : data.timelines[0],
      });
    } else setSelectedTimelinesInfo(selectedTimelineInfoInitialData);
  }, [isCaseSuccess, data]);

  return (
    <>
      <Row className="d-flex align-items-center pe-3 mb-1 w-100">
        <Breadcrumbs items={breadcrumbs} autoWidth />
        <Link
          to={generateParticipantCheckRoute(
            {
              organizationId,
              caseId,
            },
            RedirectSource.caseDetails
          )}
          className="btn btn-primary ms-auto w-auto"
        >
          <FormattedMessage id="PatientCheck.checkStatus" />
        </Link>
      </Row>
      {renderedRequests}
      <CaseDetailsHeader
        isLoading={isLoadingDetails}
        data={data}
        caseId={caseId}
        organizationId={organizationId}
        refetchCase={refetch}
        selectedTimeline={selectedTimelinesInfo.selectedTimeline}
        onTimelineChange={onTimelineChange}
      />
      {data && selectedTimelinesInfo.selectedTimeline && (
        <CaseTabContainer
          caseDetails={data}
          selectedTimelinesInfo={selectedTimelinesInfo}
        />
      )}
    </>
  );
};

export default GenericCaseDetailsView;
