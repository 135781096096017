import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import React, { ChangeEvent, SetStateAction, useCallback } from 'react';
import { Input } from 'reactstrap';
import IconButton from '../../../../../../../../../../components/IconButton';
import { OpenHookValues } from '../../../../../../../../../../hooks/useOpen';
import { OrganizationInfoTabTypes } from '../../types';

type ImageUploadProps = {
  name: string;
  values: OrganizationInfoTabTypes.FormValues;
  editMode: OpenHookValues;
  setValues: (
    values: SetStateAction<OrganizationInfoTabTypes.FormValues>,
    shouldValidate?: boolean
  ) => void;
};

const imageAcceptType = 'image/png, image/jpeg';

const ImageUpload = ({
  name,
  values,
  setValues,
  editMode,
}: ImageUploadProps) => {
  const onFileChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) return;
      setValues((prev) => ({
        ...prev,
        [`${name}`]: e.target.files?.item(0),
      }));
    },
    [name, setValues]
  );

  const onClear = useCallback(() => {
    setValues((prev) => ({
      ...prev,
      [`${name}`]: null,
    }));
  }, [name, setValues]);

  const imageUrlAccessor = `${name}_url`;

  return (
    <div className="d-flex align-items-center gap-4">
      {values[imageUrlAccessor] && (
        <div>
          {values[`${name}`] && (
            <div className="pending-request-indicator align-self-start" />
          )}
          <div
            className="img-thumbnail rounded-circle h-px-100 w-px-100 p-2"
            style={{
              background: `url("${values[imageUrlAccessor]}")`,
              backgroundSize: 'contain',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundOrigin: 'content-box',
            }}
          ></div>
        </div>
      )}
      {editMode.isOpen && (
        <div className="d-flex align-items-center gap-2">
          <div className="image-upload">
            <label htmlFor={`image-upload-${name}`}>
              <IconButton color="primary" variant="outlined">
                <CloudUploadOutlinedIcon />
              </IconButton>
            </label>
            <Input
              id={`image-upload-${name}`}
              className="d-none"
              type="file"
              onChange={onFileChange}
              accept={imageAcceptType}
            />
          </div>
          {values[`${name}`] && (
            <IconButton color="primary" variant="outlined" onClick={onClear}>
              <ReplayOutlinedIcon />
            </IconButton>
          )}
        </div>
      )}
    </div>
  );
};

export default ImageUpload;
