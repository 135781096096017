import { useCallback } from 'react';
import { useTypedContext } from '../../../../../../../../../../../hooks/useTypedContext';
import { HandleUploadContext } from '../../../../../types';

export const useHandleAccept = () => {
  const handleUpload = useTypedContext(HandleUploadContext);

  return useCallback(
    async (files: File[]) => {
      if (files.length === 0) return;
      await handleUpload.onUpload(files[0]);
    },
    [handleUpload]
  );
};
