import { modifyTreatmentPhase } from 'api/conversations';
import { IUpsertTreatmentPhase } from '../../../interfaces/conversations';
import { IErrorDefinition } from '../../../interfaces/response';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

export type UpsertTreatmentPhaseProps = {
  treatmentPhaseId: string;
  body: IUpsertTreatmentPhase;
};

const useModifyTreatmentPhase = (
  organizationId: string,
  treatmentPlanId: string,
  successFb?: () => void
) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (data: UpsertTreatmentPhaseProps) => {
      await modifyTreatmentPhase(
        organizationId,
        treatmentPlanId,
        data.treatmentPhaseId,
        data.body
      );
    },
    onSuccess: () => {
      displaySuccessNotification(
        'Api.Mutations.Conversations.ModifyConversationPhase.success'
      );

      if (!successFb) return;
      successFb();
    },
    onError: (_) => {
      displayErrorNotification(
        'Api.Mutations.Conversations.ModifyConversationPhase.failure'
      );
    },
    onErrorArray: (errorArray: IErrorDefinition[]) => {
      errorArray.forEach((item) => {
        displayErrorNotification(
          item.reasons.at(0) ??
            'Api.Mutations.Conversations.ModifyConversationPhase.failure'
        );
      });
    },
    resetOnResult: true,
  });
};

export default useModifyTreatmentPhase;
