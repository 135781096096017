import React from 'react';
import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ChartPlaceholder = ({ text }) => {
  return (
    <div>
      <FontAwesomeIcon icon={faTimes} className="me-2" />
      {text}
    </div>
  );
};

export default ChartPlaceholder;
