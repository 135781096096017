import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { unlinkVideoAssetFromMaterial } from '../../educationMaterials';

type UnlinkVideoAssetFromEducationMaterialProps = {
  materialId: string;
  assetId: string;
  culture: string;
};

const useUnlinkVideoAssetFromEducationMaterial = (
  organizationId: string,
  onSuccess?: () => void
) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (
      body: UnlinkVideoAssetFromEducationMaterialProps
    ) => {
      await unlinkVideoAssetFromMaterial(
        organizationId,
        body.assetId,
        body.materialId,
        body.culture
      );
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'Api.Mutations.EducationMaterials.UnlinkVideoAssetFromEducationMaterial.failure'
      );
    },
    onSuccess: () => {
      onSuccess?.();
      displaySuccessNotification(
        'Api.Mutations.EducationMaterials.UnlinkVideoAssetFromEducationMaterial.success'
      );
    },
    resetOnResult: true,
  });
};

export default useUnlinkVideoAssetFromEducationMaterial;
