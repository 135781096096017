import { DashboardDataStates } from '../components/DashboardContent/components/DashboardDataContent/store/state';

export namespace DashboardState {
  export const InitialState: DashboardStateType = {
    templates: [],
  };

  export type DashboardPathTemplateEntry = {
    path: string;
    template: DashboardDataStates.DashboardDataState;
  };

  export type DashboardStateType = {
    templates: DashboardPathTemplateEntry[];
  };
}
