import { useMemo } from 'react';
import { endOfDay, format, isSameDay, startOfDay } from 'date-fns';
import useCaseScheduledActivitityPeriods from 'api/queries/cases/useCaseScheduledActivityPeriods';
import useTimelineSqueezeDataFetch from 'api/queries/timelines/useTimelineSqueezeDataFetch';
import { Granularity, ISqueezeChartData } from 'interfaces/timeline';
import {
  ICaseScheduledActivity,
  ICaseSecheduledActivityPeriod,
} from 'interfaces/caseScheduledActivities';

type SqueezesChartData = {
  name: string;
  Squeezes: number;
};

export interface UseHourByHourChartDataProps {
  organizationId: string;
  caseId: string;
  timelineId: string;
  selectedDate: Date;
}

export function useHourByHourChartData({
  organizationId,
  caseId,
  timelineId,
  selectedDate,
}: UseHourByHourChartDataProps) {
  const observationsDateRange = useMemo(
    () => ({ from: startOfDay(selectedDate), to: endOfDay(selectedDate) }),
    [selectedDate]
  );

  const queryEnabled = observationsDateRange.from < new Date();
  const { data: hourlySqueezes, isLoading: hourlySqueezesLoading } =
    useTimelineSqueezeDataFetch({
      params: {
        timelineId: timelineId,
        granularity: Granularity.h1,
        dateRangeObject: observationsDateRange,
      },
      options: { enabled: queryEnabled },
    });

  const chartData = useMemo(
    () => prepareChartData(hourlySqueezes),
    [hourlySqueezes]
  );

  const { data: scheduledActivityPeriods } = useCaseScheduledActivitityPeriods({
    params: { organizationId, caseId },
  });

  const activities = (scheduledActivityPeriods ?? []).filter((p) =>
    isSameDay(new Date(p.date_time_from), selectedDate)
  );

  activities.forEach(
    (a) => (a.activities = [...a.activities].sort(compareActivities))
  );

  if (activities.length <= 0) {
    return {
      chartData,
      activityPeriods: activities,
      dataStart: 0,
      dataLength: chartData.length,
      isLoading: hourlySqueezesLoading,
    };
  }

  if (chartData.length <= 0) {
    const [dataStart, dataEnd] = getActivitiesHourRange(activities);
    const generatedData = generateChartData(dataStart, dataEnd);

    return {
      chartData: generatedData,
      activityPeriods: activities,
      dataStart,
      dataLength: generatedData.length,
      isLoading: hourlySqueezesLoading,
    };
  }

  const [activitiesStart, activitiesEnd] = getActivitiesHourRange(activities);
  const [squeezesStart, squeezesEnd] = getSqueezesHourRange(chartData);

  const dataStart = Math.min(squeezesStart, activitiesStart);
  const dataEnd = Math.max(squeezesEnd, activitiesEnd) + 1;
  const dataLength = dataEnd - dataStart;

  return {
    chartData: chartData.slice(dataStart, dataEnd),
    activityPeriods: activities,
    dataStart,
    dataLength,
    isLoading: hourlySqueezesLoading,
  };
}

function compareActivities(
  a: ICaseScheduledActivity,
  b: ICaseScheduledActivity
) {
  return (
    new Date(a.date_time_from).getTime() - new Date(b.date_time_from).getTime()
  );
}

function prepareChartData(hourlySqueezes: ISqueezeChartData | undefined) {
  let data: SqueezesChartData[] = [];

  if (!hourlySqueezes) return data;

  const hasData = hourlySqueezes.series[3].data.find((d) => d > 0);
  if (!hasData) return data;

  hourlySqueezes.x_axis.forEach((date, idx) => {
    let formattedDate = format(new Date(date), "yyyy-MM-dd'T'HH:mm:ss");

    data.push({
      name: formattedDate.slice(
        formattedDate.indexOf('T') + 1,
        formattedDate.indexOf('T') + 6
      ),
      Squeezes: hourlySqueezes.series[3].data[idx],
    });
  });

  data.push({
    name: '24:00',
    Squeezes: 0,
  });

  return data;
}

function generateChartData(start: number, end: number) {
  let data: SqueezesChartData[] = [];

  for (let i = start; i <= end; ++i) {
    data.push({
      name: `${String(i).padStart(2, '0')}:00`,
      Squeezes: 0,
    });
  }

  return data;
}

function getActivitiesHourRange(activities: ICaseSecheduledActivityPeriod[]) {
  let activitiesStart = 24;
  let activitiesEnd = 0;
  for (const period of activities) {
    const hourFrom = new Date(period.date_time_from).getHours();
    const hourTo = new Date(period.date_time_to).getHours() || 24;

    activitiesStart = Math.min(activitiesStart, hourFrom);
    activitiesEnd = Math.max(activitiesEnd, hourTo);
  }

  return [activitiesStart, activitiesEnd];
}

function getSqueezesHourRange(chartData: SqueezesChartData[]) {
  let squeezesStart = 24;
  let squeezesEnd = 0;
  for (let i = 0; i < chartData.length; ++i) {
    const data = chartData[i];

    if (data.Squeezes > 0) {
      squeezesEnd = i + 1;
      if (squeezesStart > i) {
        squeezesStart = i;
      }
    }
  }

  return [squeezesStart, squeezesEnd];
}
