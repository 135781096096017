import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { uploadAsset } from '../../assets';
import { AssetType } from '../../../interfaces/educationMaterials';
import { CultureEntry } from '../../../interfaces/cultures';

export type UploadAssetData = {
  name: string;
  nameTranslations: CultureEntry<string>[];
  description: string;
  descriptionTranslations: CultureEntry<string>[];
  type: AssetType;
  file: File;
};

const useUploadAsset = (
  organizationId: string,
  onSuccess?: (assetId: string) => void
) => {
  const mutationData = useMutationWithResultNotification({
    asyncApiFunction: async (body: UploadAssetData) => {
      const formData = new FormData();
      formData.append('name', body.name);
      formData.append(
        'name_translations',
        JSON.stringify(body.nameTranslations)
      );
      formData.append('description', body.description);
      formData.append(
        'description_translations',
        JSON.stringify(body.descriptionTranslations)
      );
      formData.append('asset_type', body.type);
      formData.append('organization_id', organizationId);
      formData.append('file_name', body.file);

      const { data } = await uploadAsset(formData);
      return data.data;
    },
    resetOnResult: false,
    onErrorArray: () => {
      displayErrorNotification('Api.Mutations.Assets.UploadAsset.failure');
    },
    onSuccess: () => {
      if (mutationData.data) {
        onSuccess?.(mutationData.data.id);
        mutationData.reset();
      }

      displaySuccessNotification('Api.Mutations.Assets.UploadAsset.success');
    },
  });

  return mutationData;
};

export default useUploadAsset;
