import useUserGraspUpdate from 'api/mutations/grasps/useUserGraspUpdate';
import useTimelines from 'api/queries/timelines/useTimelines';
import FieldInput from 'components/FieldInput';
import LoadingButton from 'components/LoadingButton';
import { Field, Form, Formik } from 'formik';
import { formatDate } from 'helpers/dates';

import { IGraspPossession } from 'interfaces/grasps';
import { IPatchObject } from 'interfaces/request';
import React, { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

//REDUX
import { QueryObserverResult, RefetchOptions } from 'react-query';
import { Button, FormGroup, Modal, ModalBody, ModalHeader } from 'reactstrap';

type EditGraspModalProps = {
  toggle: () => void;
  className?: string;
  isOpen: boolean;
  graspData: IGraspPossession | null;
  userId: string;
  refetchGrasps: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<IGraspPossession[]>>;
};

const EditMyGraspModal: React.FC<EditGraspModalProps> = ({
  className,
  isOpen,
  toggle,
  graspData,
  userId,
  refetchGrasps,
}) => {
  const intl = useIntl();

  const { data: initTimelines } = useTimelines({ params: { userId } });

  const successFb = async () => {
    toggle();
    await refetchGrasps();
  };

  const {
    mutate: updateGraspMutation,
    isSuccess,
    isError,
    isLoading,
  } = useUserGraspUpdate({
    userId,
    graspId: graspData?.grasp_id as string,
    successFb,
  });

  const initialValues = {
    name: graspData?.grasp_name ?? '',
    timeline_id: graspData?.default_timeline_id ?? '',
  };

  const handleSubmit = (values) => {
    const valuesArray: IPatchObject[] = [
      {
        value: values.name,
        path: '/grasp_name',
        op: 'replace',
      },
      {
        value: values.timeline_id,
        path: '/default_timeline_id',
        op: 'replace',
      },
    ];

    graspData && updateGraspMutation(valuesArray);
  };

  useEffect(() => {
    if (isSuccess) {
    }
  }, [isSuccess, refetchGrasps, toggle]);

  const filteredTimelines = initTimelines?.filter(
    (timeline) => !timeline.is_master
  );

  const renderedTimelines = filteredTimelines?.map(
    (el) =>
      (
        <option key={el.id} value={el.id}>
          {el.name}
        </option>
      ) ?? null
  );

  renderedTimelines?.unshift(
    <option key="placeholder" value="">
      {intl.formatMessage({ id: 'UserDashboard.noTimelineSelected' })}
    </option>
  );

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="md" className={className}>
      <ModalHeader toggle={toggle}>
        <FormattedMessage id="General.editGrasp" />
      </ModalHeader>
      <ModalBody>
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ dirty }) => (
            <Form>
              <FormGroup>
                <label className="newMembersSection__label" htmlFor="name">
                  <FormattedMessage id="UserDashboard.graspName" />
                </label>
                <Field required id="name" name="name" component={FieldInput} />
              </FormGroup>
              <FormGroup>
                <label
                  className="newMembersSection__label"
                  htmlFor="timeline_id"
                >
                  <FormattedMessage id="UserDashboard.timelineName" />
                </label>
                <Field
                  component={FieldInput}
                  type="select"
                  id="timeline_id"
                  name="timeline_id"
                  multiple={false}
                  required
                >
                  {renderedTimelines}
                </Field>
              </FormGroup>
              <label className="newMembersSection__label">
                First activity :{' '}
                {graspData ? formatDate(graspData.date_from) : '-'}
              </label>
              <div className="d-flex justify-content-end mt-2">
                <div className="d-flex align-items-center">
                  <Button
                    onClick={toggle}
                    className="btn btn-cancel btn-short mb-0 me-2"
                  >
                    <FormattedMessage id="General.cancel" />
                  </Button>
                  <LoadingButton
                    isLoading={isLoading}
                    isSuccess={isSuccess}
                    isFailure={isError}
                    disabled={!dirty}
                    outline
                    value="General.update"
                    type="submit"
                    className="btn btn-login btn-short mb-0"
                  />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default EditMyGraspModal;
