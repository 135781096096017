import useTimelineAnnotationCreate from 'api/mutations/timelines/useTimelineAnnotationCreate';
import useDeleteAnnotation from 'api/mutations/timelines/useTimelineAnnotationDelete';
import useTimelineAnnotationEdit from 'api/mutations/timelines/useTimelineAnnotationEdit';
import useTimelineAnnotations from 'api/queries/timelines/useTimelineAnnotations';
import calendarIcon from 'assets/images/calendar_icon.svg';

//icons
import HeaderAnnotationIcon from 'assets/images/timeline_details/header_annotation_icon.svg';
import FieldInput from 'components/FieldInput';
import LoadingButton from 'components/LoadingButton';
import TimeInput from 'components/TimeInput';
import { isSameDay } from 'date-fns';
import { Field, Formik } from 'formik';
import { formatObjectToIPatch } from 'helpers/utils/formatData';
import { returnSelectedDateString } from 'helpers/utils/timelineHelpers';
import { validateEmpty } from 'helpers/utils/validators';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { useTimelineDetailsSelectedDate } from 'store/reducerHooks';

type AnnotationObservationModalProps = {
  timelineId: string;
  annotationId?: string | null;
  isOpen: boolean;
  closeModal: () => void;
};

const predefinedInitialValues = {
  description: '',
  from: '',
};

const AnnotationModal = ({
  timelineId,
  annotationId,
  closeModal,
  isOpen,
}: AnnotationObservationModalProps) => {
  const intl = useIntl();

  const { refetch: refetchAnnotations, data: annotationData } =
    useTimelineAnnotations({
      params: { timelineId },
      options: { enabled: false },
    });

  const selectedDate = useTimelineDetailsSelectedDate();

  const singleAnnotationData = annotationData?.find((annotation) => {
    return annotation.id === annotationId;
  });

  let from = '';
  if (singleAnnotationData) {
    from = singleAnnotationData.time_start;
  } else if (selectedDate) {
    const today = new Date();
    const isToday = isSameDay(selectedDate, today);

    from = isToday ? today.toISOString() : selectedDate?.toISOString();
  }

  const initialValues: typeof predefinedInitialValues = {
    description: singleAnnotationData?.text ?? '',
    from,
  };
  const isEditMode = !!annotationId;

  const annotationDisabled =
    singleAnnotationData && singleAnnotationData.is_members;

  const successFb = async () => {
    await refetchAnnotations();
    closeModal();
  };

  const {
    mutate: createAnnotation,
    isLoading,
    isSuccess,
    isError,
  } = useTimelineAnnotationCreate(timelineId, successFb);

  const { mutate: editAnnotation } = useTimelineAnnotationEdit(successFb);

  const { mutate: deleteAnnotationMutation, isLoading: isDeleteLoading } =
    useDeleteAnnotation(successFb);
  const onDelete = () => {
    annotationId && deleteAnnotationMutation(annotationId);
  };

  const renderButtons = (submitForm: () => void, isValid: boolean) => {
    let buttons = (
      <LoadingButton
        className="AnnotationModal__add"
        onClick={submitForm}
        isLoading={isLoading}
        isSuccess={isSuccess}
        isFailure={isError}
        disabled={!isValid}
      >
        <FormattedMessage id="TimelineDetails.AnnotationModal.addAnnotation" />
      </LoadingButton>
    );

    if (isEditMode)
      buttons = (
        <>
          <LoadingButton
            disabled={annotationDisabled}
            className="AnnotationModal__delete"
            outline
            type="button"
            onClick={onDelete}
            isLoading={isDeleteLoading}
          >
            <FormattedMessage id="TimelineDetails.AnnotationModal.deleteAnnotation" />
          </LoadingButton>
          <LoadingButton
            className="AnnotationModal__edit"
            onClick={submitForm}
            isLoading={isLoading}
            isSuccess={isSuccess}
            isFailure={isError}
            disabled={!isValid || annotationDisabled}
          >
            <FormattedMessage id="TimelineDetails.AnnotationModal.editAnnotation" />
          </LoadingButton>
        </>
      );

    return buttons;
  };

  const submitAction = ({
    description,
    from,
  }: typeof predefinedInitialValues) => {
    const dates = {
      time_start: new Date(from).toISOString(),
      time_end: new Date(from).toISOString(),
    };

    if (isEditMode && annotationId) {
      editAnnotation({
        annotationId,
        patchObjects: formatObjectToIPatch({
          ...dates,
          text: description,
        }),
      });
    } else {
      createAnnotation({
        text: description,
        ...dates,
      });
    }
  };

  const validation = (values: typeof predefinedInitialValues) => {
    return validateEmpty(values, intl);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
      className="AnnotationModal"
      size="lg"
    >
      <ModalHeader toggle={closeModal}>
        <img
          src={HeaderAnnotationIcon}
          alt="annotation_icon"
          className="me-2"
        />
        {isEditMode ? (
          <FormattedMessage id="TimelineDetails.AnnotationModal.editHeader" />
        ) : (
          <FormattedMessage id="TimelineDetails.AnnotationModal.createHeader" />
        )}
        <div className="DayDetails__date">
          <img src={calendarIcon} alt="calendar_icon" />
          {returnSelectedDateString(selectedDate)}
        </div>
      </ModalHeader>
      <Formik
        initialValues={initialValues}
        onSubmit={submitAction}
        validate={validation}
      >
        {({ submitForm, isValid }) => (
          <>
            <ModalBody>
              <Form>
                <FormGroup>
                  <label htmlFor="from">
                    <FormattedMessage id="TimelineDetails.AnnotationModal.dateAndTime" />
                  </label>
                  <Field
                    disabled={annotationDisabled}
                    required
                    aria-label="from"
                    name="from"
                    id="from"
                    component={TimeInput}
                  />
                </FormGroup>
                <FormGroup>
                  <label htmlFor="description">
                    <FormattedMessage id="TimelineDetails.AnnotationModal.description" />
                  </label>
                  <Field
                    disabled={annotationDisabled}
                    required
                    aria-label="description"
                    name="description"
                    id="description"
                    type="textarea"
                    rows={5}
                    placeholder={intl.formatMessage({
                      id: 'TimelineDetails.AnnotationModal.descriptionPlaceholder',
                    })}
                    component={FieldInput}
                  />
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>{renderButtons(submitForm, isValid)}</ModalFooter>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default AnnotationModal;
