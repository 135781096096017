import React, { useContext } from 'react';
import { HandleUploadContext, Steps, StepsContext } from '../../types';
import UploadStep from './components/UploadStep';
import ConflictsStep from './components/ConflictsStep';
import { useHandleUpload } from './hooks/useHandleUpload';
import { StepsHookValues } from '../../../../../../../../hooks/useSteps';

const Content = () => {
  const steps = useContext(StepsContext) as StepsHookValues<Steps>;
  const handleUpload = useHandleUpload(steps);

  return (
    <HandleUploadContext.Provider value={handleUpload}>
      {steps.step === Steps.UploadFile && <UploadStep />}
      {steps.step === Steps.Conflicts && <ConflictsStep />}
    </HandleUploadContext.Provider>
  );
};

export default Content;
