import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import AdditionalInformation from './components/AdditionalInformation';
import PatientAssessmentForms from './components/PatientAssessmentForms';
import StatusStepIndicator from './components/StatusStepIndicator';
import TransferGraspData from './components/TransferGraspData';
import Breadcrumbs from 'components/PageHeader/Breadcrumbs';
import {
  generateCaseDetailsUrl,
  generateOrganizationCaseListUrl,
  generateOrganizationPersonalitiesUrl,
  generatePersonalityUrl,
  RedirectSource,
} from 'helpers/routeDefinitions';
import useOrganizationPatientDetails from 'api/queries/patients/useOrganizationPatientDetails';
import GraspSyncInfo from './components/GraspSyncInfo';

//helpers
import useRequiredParams from 'hooks/useRequiredParams';
import useCaseDetails from 'api/queries/cases/useCaseDetails';
import useOrganizationDetails from 'api/queries/organizations/useOrganizationDetails';

//icons
import GraspLogo from 'assets/images/patient_check/grasp.svg';
import Forms from 'assets/images/patient_check/forms.svg';
import AdditionalInfo from 'assets/images/patient_check/additional_info.svg';
import { useLocation, useNavigate } from 'react-router';

const headerIcons = [GraspLogo, Forms, AdditionalInfo];

const headerTranslations = [
  'PatientCheck.header.transferData',
  'PatientCheck.header.assessmentForms',
  'PatientCheck.header.additionalInformation',
];

enum StatusCheckStep {
  DataTransform,
  UserForms,
  AdditionalInfo,
}

const StatusCheck = () => {
  const { search } = useLocation();
  const navigate = useNavigate();

  const { organizationId, caseId } = useRequiredParams<{
    organizationId: string;
    caseId: string;
  }>(['organizationId', 'caseId']);

  const { data: caseData } = useCaseDetails({
    params: { organizationId, caseId },
  });

  const personalityId = caseData?.participants[0]?.personality?.id ?? '';

  const { data: patientDetailsData } = useOrganizationPatientDetails({
    params: { organizationId, personalityId },
    options: { enabled: !!personalityId },
  });

  const { data: organizationData } = useOrganizationDetails({
    params: { organizationId },
  });

  const redirectToSource = () => {
    const searchParams = new URLSearchParams(search);
    const source =
      searchParams.get('redirectSource') || RedirectSource.caseList;

    switch (source) {
      case RedirectSource.caseDetails:
        navigate(
          generateCaseDetailsUrl({
            caseId,
            organizationId,
          })
        );
        break;
      case RedirectSource.memberDetails:
        if (personalityId)
          navigate(
            generatePersonalityUrl({
              organizationId,
              personalityId,
            })
          );
        break;
      case RedirectSource.caseList:
        navigate(
          generateOrganizationCaseListUrl({
            organizationId,
          })
        );
        break;
    }
  };

  const [currentStep, setCurrentStep] = useState<StatusCheckStep>(
    StatusCheckStep.DataTransform
  );

  const onPrevious = () => {
    if (currentStep > 0) setCurrentStep((step) => step - 1);
  };

  const onNext = () => {
    if (currentStep < StatusCheckStep.AdditionalInfo)
      setCurrentStep((step) => step + 1);
  };

  const rendedStep = () => {
    switch (currentStep) {
      case StatusCheckStep.UserForms:
        if (personalityId) {
          return (
            <PatientAssessmentForms
              previousClick={onPrevious}
              nextClick={onNext}
              caseId={caseId}
              organizationId={organizationId}
              personalityId={personalityId}
            />
          );
        }
        return null;
      case StatusCheckStep.AdditionalInfo:
        return (
          <AdditionalInformation
            previousClick={onPrevious}
            caseId={caseId}
            organizationId={organizationId}
            backToRoute={redirectToSource}
          />
        );
      case StatusCheckStep.DataTransform:
      default:
        if (personalityId) {
          return (
            <TransferGraspData
              nextClick={onNext}
              cancelClick={redirectToSource}
            />
          );
        }

        return null;
    }
  };

  const renderHeader = () => {
    return (
      <div className="w-100 mb-4">
        <div className="mt-3">
          <StatusStepIndicator currentStep={currentStep} />
        </div>
        <div className="StatusCheck__header">
          <div className="StatusCheck__header__icon">
            <img
              src={headerIcons[currentStep]}
              alt={headerIcons[currentStep]}
            />
          </div>
          <div>
            <FormattedMessage id="General.step" />
            {` ${currentStep + 1}`}
            {': '}
            <FormattedMessage id={headerTranslations[currentStep]} />
          </div>
        </div>
      </div>
    );
  };

  const breadcrumbs = [
    {
      text: `${organizationData?.name || ''}`,
      link: null,
      key: 'organization',
    },
    {
      text: <FormattedMessage id="General.patients" />,
      link: generateOrganizationPersonalitiesUrl({ organizationId }),
      key: 'patients',
    },
    {
      text: `${patientDetailsData?.first_name || ''} ${
        patientDetailsData?.last_name || ''
      }`,
      link: generatePersonalityUrl({
        organizationId,
        personalityId,
      }),
      key: 'personality',
    },
  ];

  return (
    <div className="flex-grow-1 position-relative">
      <Breadcrumbs items={breadcrumbs} />
      {renderHeader()}
      {personalityId && (
        <GraspSyncInfo
          organizationId={organizationId}
          personalityId={personalityId}
          caseId={caseId}
        />
      )}
      {rendedStep()}
    </div>
  );
};

export default StatusCheck;
