import React, { useRef, useState } from 'react';
import ItemContainer from '../ItemContainer';
import {
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import SqueezeTimelineXAxisTick from './components/SqueezeTimelineXAxisTick';
import { useFlattenObservations } from '../../../../../../../../hooks/data/observations/useFlattenObservations';
import { useFormattedData } from './hooks/useFormattedData';
import { useYAxisDomain } from './hooks/useYAxisDomain';
import { useTypedContext } from '../../../../../../../../hooks/useTypedContext';
import { OperationalDataContext } from '../../providers/OperationalDataProvider';
import { FormikProps } from 'formik';
import { AwareCaseDetailsFormValues } from '../../forms/AwareCaseDetailsForm/types';
import ProcedureEventsTimeline from '../ProcedureEventsTimeline';
import ProcedureRanges from '../ProcedureRanges';
import { CategoricalChartState } from 'recharts/types/chart/types';
import { useMsDiffs } from '../ProcedureEventsTimeline/hooks/useMsDiffs';
import useOpen, { OpenHookValues } from '../../../../../../../../hooks/useOpen';
import ManageActivityModal from '../../modals/ManageActivityModal';
import { secondsToDurationString } from '../../../../../../../../helpers/dates';
import { useCookie } from '../../../../../../../../hooks/useCookie';
import { ViewTheme } from '../../../../../../../../interfaces/ui';

type SqueezesTimelineProps = {
  form: FormikProps<AwareCaseDetailsFormValues>;
  editMode: OpenHookValues;
};

const SqueezesTimeline = ({ form, editMode }: SqueezesTimelineProps) => {
  const { observations, awareSessionDetails } = useTypedContext(
    OperationalDataContext
  );

  const theme = useCookie<ViewTheme>('theme');

  const manageActivity = useOpen();

  const [msPoint, setMsPoint] = useState<number | null>(null);

  const { baseMsDiff } = useMsDiffs(
    awareSessionDetails.start_date,
    awareSessionDetails.end_date
  );

  const ref = useRef<HTMLDivElement>(null);

  const flattenObservations = useFlattenObservations(observations);
  const formattedData = useFormattedData(flattenObservations);
  const yAxisDomain = useYAxisDomain();

  const handleActivityOpen = (_: CategoricalChartState, event: any) => {
    if (!editMode.isOpen) return;

    const bounds = event.target.getBoundingClientRect();
    const currentPoint = event.clientX - bounds.left;

    const maxPoint = ref.current?.clientWidth;
    if (!maxPoint) return;

    setMsPoint((currentPoint * baseMsDiff) / maxPoint);
    manageActivity.open();
  };

  const handleActivityToggle = () => {
    setMsPoint(null);
    manageActivity.close();
  };

  const tooltipLabelFormatter = (milliseconds: number) =>
    secondsToDurationString(milliseconds / 1000);

  return (
    <ItemContainer>
      {manageActivity.isOpen && msPoint && (
        <ManageActivityModal
          isOpen={manageActivity.isOpen}
          toggle={handleActivityToggle}
          msPoint={msPoint}
          activityIndex={undefined}
          form={form}
        />
      )}
      <div className="mt-3" style={{ paddingLeft: 30, paddingRight: 30 }}>
        <ProcedureRanges
          startDate={awareSessionDetails.start_date}
          endDate={awareSessionDetails.end_date}
          form={form}
        />
      </div>
      <div ref={ref} className="h-px-200 mt-3">
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart data={formattedData} onClick={handleActivityOpen}>
            <YAxis
              yAxisId="awareCaseChartYAxis"
              hide={true}
              orientation="left"
              dataKey="value"
              interval={'preserveStartEnd'}
              type="number"
              domain={[0, 255]}
              tickCount={10}
              tickLine={false}
              axisLine={false}
            />
            <XAxis
              interval={'preserveStartEnd'}
              dataKey="name"
              type="number"
              hide={true}
              tickLine={false}
              axisLine={false}
              tickCount={20}
              padding={{ left: 30, right: 30 }}
              domain={yAxisDomain}
              tick={<SqueezeTimelineXAxisTick />}
            />
            <defs>
              <linearGradient
                id="colorUv"
                gradientUnits="userSpaceOnUse"
                gradientTransform="rotate(90)"
              >
                <stop stopColor="#FF8080" />
                <stop offset="0.15" stopColor="#0F87D7" />
                <stop offset="1" stopColor="#0F87D7" />
              </linearGradient>
            </defs>
            <Line
              connectNulls={true}
              yAxisId="awareCaseChartYAxis"
              strokeWidth={3}
              type="basis"
              dataKey={'value'}
              dot={false}
              stroke="url(#colorUv)"
            />
            {!editMode.isOpen && (
              <Tooltip
                contentStyle={{
                  background: theme === ViewTheme.Dark ? 'black' : 'white',
                  color: theme === ViewTheme.Dark ? 'white' : 'black',
                }}
                labelFormatter={tooltipLabelFormatter}
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </div>
      <div className="mt-2" style={{ paddingLeft: 30, paddingRight: 30 }}>
        <ProcedureEventsTimeline
          startDate={awareSessionDetails.start_date}
          endDate={awareSessionDetails.end_date}
          form={form}
        />
      </div>
    </ItemContainer>
  );
};

export default SqueezesTimeline;
