import { useQuery } from 'react-query';
import { TemplateQueryParams } from '../../../interfaces/queryParams';
import { IQueryRequest } from '../../../interfaces/request';
import { getDashboardTemplateOrganizationsMembers } from '../../dashboards';
import QueryKeys from '../queryKeys';

const useDashboardTemplateOrganizationsMembers = ({
  params,
  options,
}: IQueryRequest<TemplateQueryParams>) => {
  const { organizationId, templateId } = params;

  return useQuery({
    ...options,
    queryKey: [
      QueryKeys.getDashboardTemplateOrganizationsMembers,
      organizationId,
      templateId,
    ],
    queryFn: async () => {
      const { data } = await getDashboardTemplateOrganizationsMembers(
        organizationId,
        templateId
      );
      return data.data;
    },
  });
};

export default useDashboardTemplateOrganizationsMembers;
