import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  generateRoute,
  RouteDefinitions,
} from '../../../../../helpers/routeDefinitions';
import { useOrganizationPathParams } from '../../../../../hooks/paths/useOrganizationPathParams';
import { RootState } from '../../../../../store';
import { DashboardDataStates } from '../components/DashboardContent/components/DashboardDataContent/store/state';
import { DashboardActionTypes } from './actionTypes';
import { DashboardState } from './state';

export const useRegisterEntry = () => {
  const dispatch = useDispatch();

  const { organizationId } = useOrganizationPathParams();
  const { dashboardId } = useParams<{ dashboardId: string }>();

  const templates = useDashboardTemplateEntries();

  return useCallback(
    (path: string, template: DashboardDataStates.DashboardDataState) => {
      const existingTemplate = templates.find(
        (p) =>
          p.path ===
          generateRoute(path as RouteDefinitions, {
            organizationId,
            dashboardId: dashboardId ?? '',
          })
      );

      dispatch({
        type: existingTemplate
          ? DashboardActionTypes.DashboardActionType.EDIT_TEMPLATE
          : DashboardActionTypes.DashboardActionType.ADD_TEMPLATE,
        path: generateRoute(path as RouteDefinitions, {
          organizationId,
          dashboardId: dashboardId ?? '',
        }),
        template,
      });
    },
    [dashboardId, dispatch, organizationId, templates]
  );
};

export const useDashboardTemplateEntry = (path: string) => {
  const { organizationId } = useOrganizationPathParams();
  const { dashboardId } = useParams<{ dashboardId: string }>();

  const templates = useDashboardTemplateEntries();

  return templates.find(
    (p) =>
      p.path ===
      generateRoute(path as RouteDefinitions, {
        organizationId,
        dashboardId: dashboardId ?? '',
      })
  );
};

export const useDashboardTemplateEntries =
  (): DashboardState.DashboardPathTemplateEntry[] =>
    useSelector((state: RootState) => state.dashboard.templates);
