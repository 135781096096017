import { createContext } from 'react';
import { ICasePhaseHistory } from '../../../../../../../../../../interfaces/cases';
import { IOrganizationDetails } from '../../../../../../../../../../interfaces/membership';

export type PhaseDayDefinition = {
  displayCode: string;
  startDate: string;
  endDate: string | null;
  daysDuration: number | null;
  nthDay: number;
};

export const OrganizationContext = createContext<
  IOrganizationDetails | undefined
>(undefined);

export const CasePhaseHistoryContext = createContext<
  ICasePhaseHistory[] | undefined
>(undefined);
