import { useCaseGroupDetailsCommonData } from './useCaseGroupDetailsCommonData';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ICaseGroup } from '../../../../../interfaces/caseGroups';
import { cloneDeep } from 'lodash';
import { FormattedMessage } from 'react-intl';
import usersGroupIcon from '../../../../../assets/images/users-group.svg';
import identityIcon from '../../../../../assets/images/case_group_header/identity.svg';
import calendarIcon from '../../../../../assets/images/case_group_header/calendar.svg';
import { formatDate } from '../../../../../helpers/dates';
import disketteIcon from '../../../../../assets/images/floppy-disc.svg';
import revertArrowIcon from '../../../../../assets/images/revert-arrow.svg';
import pencilIcon from '../../../../../assets/images/pencil.svg';
import trashIcon from '../../../../../assets/images/trash.svg';
import usePatchCaseGroup from '../../../../../api/mutations/caseGroups/usePatchCaseGroup';
import useRequiredParams from '../../../../../hooks/useRequiredParams';
import { useCaseGroupDetailsPatchObject } from './useCaseGroupDetailsPatchObject';
import { useInitQuery } from '../../../../../hooks/queries/useInitQuery';
import {
  ThinHeaderActionDefinition,
  ThinHeaderItemDefinition,
} from '../../../../../components/ThinHeader/types/ThinHeader';

export const useCaseGroupDetailsThinHeader = () => {
  const { organizationId, projectId, groupId } = useRequiredParams<{
    organizationId: string;
    projectId: string;
    groupId: string;
  }>(['organizationId', 'projectId', 'groupId']);

  const { caseGroup, caseCategories, options } =
    useCaseGroupDetailsCommonData();

  const [editMode, setEditMode] = useState<boolean>(false);
  const [managedCaseGroup, setManagedCaseGroup] = useState<
    ICaseGroup | undefined
  >(() => caseGroup.data);

  const handleRefetch = async () => {
    await caseGroup.refetch();
    setEditMode(false);
  };

  const { mutateAsync: patchAsync } = usePatchCaseGroup(
    {
      organizationId: organizationId,
      projectId: projectId,
      groupId: groupId,
    },
    {
      successFb: handleRefetch,
    }
  );

  const getCaseGroupDetailsPatchObject = useCaseGroupDetailsPatchObject();

  useInitQuery(caseGroup);
  useInitQuery(caseCategories);

  useEffect(() => {
    setManagedCaseGroup(caseGroup.data);
  }, [caseGroup.data]);

  const handleEnableEditMode = () => {
    setEditMode(true);
  };

  const handleRevert = useCallback(() => {
    setManagedCaseGroup(cloneDeep(caseGroup.data));
    setEditMode(false);
  }, [caseGroup.data]);

  const handleEdit = useCallback(
    (target: string, value: string) => {
      let groupCopy = cloneDeep(managedCaseGroup);

      if (!groupCopy) return;

      groupCopy[target] = value;

      if (target === 'maximum_members_allowed')
        groupCopy[target] = parseInt(value);

      if (target === 'starting_date')
        groupCopy[target] = new Date(value).toISOString();

      setManagedCaseGroup(groupCopy);
    },
    [managedCaseGroup]
  );

  const handleSave = useCallback(async () => {
    let managedCopy = cloneDeep(managedCaseGroup);

    if (!managedCopy) return;

    managedCopy.starting_date = managedCopy.starting_date.replace('Z', '');

    let patchObject = getCaseGroupDetailsPatchObject(
      managedCopy,
      caseGroup.data
    );

    await patchAsync(patchObject);
  }, [
    caseGroup.data,
    getCaseGroupDetailsPatchObject,
    managedCaseGroup,
    patchAsync,
  ]);

  const headerItems = useMemo((): ThinHeaderItemDefinition[] => {
    let items: ThinHeaderItemDefinition[] = [
      {
        key: 'group-sequence',
        header: (
          <FormattedMessage id="CaseGroups.CaseGroupDetails.groupSequence" />
        ),
        icon: usersGroupIcon,
        title:
          managedCaseGroup?.sequence !== undefined
            ? managedCaseGroup.sequence.toString()
            : '',
      },
      {
        key: 'case-category',
        header: (
          <FormattedMessage id="CaseGroups.CaseGroupDetails.caseCategory" />
        ),
        icon: identityIcon,
        title:
          caseCategories.data?.find(
            (p) => p.id === managedCaseGroup?.case_category_id
          )?.name_en ?? '',
      },
      {
        key: 'start-date',
        header: <FormattedMessage id="CaseGroups.CaseGroupDetails.startDate" />,
        icon: calendarIcon,
        title: managedCaseGroup?.starting_date
          ? formatDate(managedCaseGroup.starting_date, { ignoreTime: true })
          : '',
      },
      {
        key: 'max-participants',
        header: (
          <FormattedMessage id="CaseGroups.CaseGroupDetails.maximumMembersAllowed" />
        ),
        icon: usersGroupIcon,
        title: managedCaseGroup?.maximum_members_allowed?.toString() ?? '',
      },
    ];

    if (editMode) {
      items[1].editable = {
        name: 'case_category_id',
        value: managedCaseGroup?.case_category_id,
        inputType: 'select',
        inputOptions: options.caseCategories,
        onEdit: handleEdit,
      };

      items[2].editable = {
        name: 'starting_date',
        value: managedCaseGroup?.starting_date
          ? managedCaseGroup.starting_date.substring(
              0,
              managedCaseGroup.starting_date.indexOf('T')
            )
          : '',
        inputType: 'date',
        onEdit: handleEdit,
      };

      items[3].editable = {
        name: 'maximum_members_allowed',
        value: managedCaseGroup?.maximum_members_allowed,
        inputType: 'number',
        onEdit: handleEdit,
      };
    }

    return items;
  }, [
    caseCategories.data,
    editMode,
    handleEdit,
    managedCaseGroup?.case_category_id,
    managedCaseGroup?.maximum_members_allowed,
    managedCaseGroup?.sequence,
    managedCaseGroup?.starting_date,
    options.caseCategories,
  ]);

  const headerActions = useMemo((): ThinHeaderActionDefinition[] => {
    if (editMode) {
      return [
        {
          key: 'save',
          color: 'primary',
          icon: disketteIcon,
          onAction: handleSave,
        },
        {
          key: 'reset',
          color: 'primary',
          icon: revertArrowIcon,
          onAction: handleRevert,
        },
      ];
    }

    return [
      {
        key: 'edit',
        color: 'primary',
        icon: pencilIcon,
        onAction: handleEnableEditMode,
      },
      {
        key: 'delete',
        color: 'secondary',
        icon: trashIcon,
        disabled: true,
      },
    ];
  }, [editMode, handleRevert, handleSave]);

  return {
    items: headerItems,
    actions: headerActions,
  };
};
