import { createPersonalityForOrganization } from 'api/cases';
import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import { ICreatePersonalityBody } from 'interfaces/personalities';
import { OrganizationQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';

interface UsePatientCreateForOrganizationParams
  extends OrganizationQueryParams {
  onSuccess?: () => void;
}

function usePatientCreateForOrganization({
  params,
  options,
}: IQueryRequest<UsePatientCreateForOrganizationParams>) {
  const { organizationId, onSuccess } = params;
  return useMutationWithResultNotification({
    ...options,
    asyncApiFunction: async (personalityData: ICreatePersonalityBody) => {
      const { data: responseData } = await createPersonalityForOrganization(
        organizationId,
        personalityData
      );
      return responseData;
    },
    resetOnResult: true,
    onSuccess: () => {
      displaySuccessNotification('CaseCard.create.personalitySuccess');
      onSuccess && onSuccess();
    },
    onErrorArray: (errors) => {
      displayErrorArrayNotifications(errors, 'CaseCard.create.errors');
    },
  });
}

export default usePatientCreateForOrganization;
