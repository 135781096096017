import SVG from 'react-inlinesvg';
import useMobile from 'hooks/useBreakpoint';
import { ICaseSecheduledActivityPeriod } from 'interfaces/caseScheduledActivities';

type BuddyCaseActivitiesProps = {
  activities: ICaseSecheduledActivityPeriod[] | undefined;
  dataStart: number;
  dataLength: number;
};

export function BuddyCaseActivities({
  activities,
  dataStart,
  dataLength,
}: BuddyCaseActivitiesProps) {
  if (!activities) return <></>;

  const gridTemplateColumns = `repeat(${dataLength - 1}, 1fr)`;
  const padding = Math.ceil(50 / dataLength) + 1;

  const activitiesContent = activities.map((g) => {
    return <ActivityPeriod key={g.id} {...g} dataStart={dataStart} />;
  });

  return (
    <div
      className="BuddyCaseHourByHourContent__activities"
      style={{
        gridTemplateColumns,
        paddingLeft: `calc(${padding}% + 0.5rem)`,
        paddingRight: `calc(${padding}% + 0.5rem)`,
      }}
    >
      {activitiesContent}
    </div>
  );
}

type ActivitiesGroupProps = ICaseSecheduledActivityPeriod & {
  dataStart: number;
};

const ActivityPeriod = (props: ActivitiesGroupProps) => {
  const isMobile = useMobile();
  const { activities, date_time_from, date_time_to, dataStart } = props;
  const hourFrom = new Date(date_time_from).getHours() - dataStart;
  const hourTo = (new Date(date_time_to).getHours() || 24) - dataStart;

  const gridColumn = `${hourFrom + 1} / span ${hourTo - hourFrom}`;

  return (
    <div
      className="BuddyCaseHourByHourContent__activities-container"
      style={{ gridColumn }}
    >
      {activities.map((a) => (
        <div key={a.id} className="BuddyCaseHourByHourContent__activities-item">
          {a.icon_url && (
            <SVG src={a.icon_url} title={a.name} height={20} width={20} />
          )}
          <span style={{ lineHeight: '20px', fontSize: isMobile ? 12 : 18 }}>
            {a.name}
          </span>
        </div>
      ))}
      <div className="BuddyCaseHourByHourContent__activities-pad" />
    </div>
  );
};

export default BuddyCaseActivities;
