import { FunctionalityCode } from '../interfaces/functionalities';
import { getLocalOrganizationFunctionalities } from '../helpers/utils/auth';

export const useFunctionality = (code: FunctionalityCode) => {
  let hasFunctionality = false;

  const functionalities = getLocalOrganizationFunctionalities();

  if (functionalities.includes(code)) hasFunctionality = true;

  return {
    hasFunctionality: hasFunctionality,
  };
};

export const useHasFunctionality = () => {
  const functionalities = getLocalOrganizationFunctionalities();
  return (code: FunctionalityCode) => functionalities.includes(code);
};
