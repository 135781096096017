import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getFormConversation } from '../../conversations';
import { OrganizationQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

interface UseFormConversationParams extends OrganizationQueryParams {
  templateId: string;
  selectedCulture: string;
}

const useFormConversation = ({
  params,
  options,
}: IQueryRequest<UseFormConversationParams>) => {
  const { organizationId, templateId, selectedCulture } = params;
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getFormConversation(
        organizationId,
        templateId,
        selectedCulture
      );

      return data.data;
    },
    queryKey: [
      QueryKeys.getFormConversations,
      organizationId,
      templateId,
      selectedCulture,
    ],
  });
};

export default useFormConversation;
