import useOpen from 'hooks/useOpen';
import {
  AssessmentQuestion,
  AssessmentQuestionType,
} from 'interfaces/assessmentForms';
import { TrendVisualizationChartType } from 'interfaces/trendVisualizations';
import AssessmentFormChart, {
  YAxisAssessmentFormChartType,
} from 'modules/TimelineDetails/components/tabs/TrendsSummary/components/AssessmentFormChart/AssessmentFormChart';
import NoDataTrendsChartPlaceholder from 'modules/TimelineDetails/components/tabs/TrendsSummary/components/ChartPlaceholders/NoDataTrendsChartPlaceholder';
import React, { useMemo, useState } from 'react';
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import SVG from 'react-inlinesvg';
import assessmentFormChartIcon from 'assets/images/trends_timeline/assessment_form_chart_icon.svg';
import Arrow from 'assets/images/single_arrow.svg';
import { FormattedMessage } from 'react-intl';
import CustomDatePicker from 'components/CustomDatePicker';
import { DateRange } from 'react-day-picker';
import { TimeRange } from 'interfaces/timeline';
import { addDays, subDays, subMonths, subYears } from 'date-fns';
import useUserContextAssessmentFormsQuestionHistory from 'api/queries/assessmentForms/questionHistory/useUserContextAssessmentFormsQuestionHistory';
import useAssessmentFormsTemplate from '../../../../../../../../../api/queries/assessmentForms/templates/useAssessmentFormsTemplate';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../../../../store';

const CHART_TYPE = TrendVisualizationChartType.LineChart;

type AssessmentFormQuestionStatsProps = {
  formCode: string;
  question: AssessmentQuestion;
  userId: string;
};

const AssessmentFormQuestionStats = ({
  formCode,
  question,
  userId,
}: AssessmentFormQuestionStatsProps) => {
  const { isOpen, toggle } = useOpen(false);

  const dateRangeObject = useSelector(
    (state: RootState) => state.timelineDetails.dateRangeObject
  );

  const [dateRange, setDateRange] = useState<DateRange>({
    from:
      dateRangeObject?.from && dateRangeObject?.to
        ? dateRangeObject.from
        : subDays(new Date(), 7),
    to:
      dateRangeObject?.from && dateRangeObject?.to
        ? dateRangeObject.to
        : new Date(),
  });

  const { data } = useUserContextAssessmentFormsQuestionHistory({
    params: {
      userId,
      forms: [
        {
          formCode: formCode,
          questions: [question.guid],
        },
      ],
      dateFrom: dateRange.from?.toISOString() ?? null,
      dateTo: dateRange.to?.toISOString() ?? null,
    },
    options: { enabled: isOpen },
  });

  const { data: templateData } = useAssessmentFormsTemplate({
    params: { formCode },
  });

  const formData = data?.find((p) => p.formCode === formCode);

  const onDateRangeChange = (dateRange: DateRange) => {
    if (dateRange && dateRange.from && dateRange.to) {
      setDateRange({
        from: dateRange.from,
        to: dateRange.to,
      });
    }
  };

  const onPredefinedValueClick = (value: string) => {
    const timeRange = value as TimeRange;

    let fromDate = new Date();

    switch (timeRange) {
      case TimeRange.HOURS_24:
        fromDate = subDays(fromDate, 1);
        break;
      case TimeRange.DAYS_3:
        fromDate = subDays(fromDate, 3);
        break;
      case TimeRange.DAYS_7:
        fromDate = subDays(fromDate, 7);
        break;
      case TimeRange.DAYS_30:
        fromDate = subMonths(fromDate, 1);
        break;
      case TimeRange.MONTHS_3:
        fromDate = subMonths(fromDate, 3);
        break;
      case TimeRange.MONTHS_6:
        fromDate = subMonths(fromDate, 6);
        break;
      case TimeRange.YEAR:
        fromDate = subYears(fromDate, 1);
        break;
      case TimeRange.YEARS_3:
        fromDate = subYears(fromDate, 3);
        break;
    }
    setDateRange({
      from: fromDate,
      to: new Date(),
    });
  };

  const onNextPeriod = () => {
    if (dateRange && dateRange.from && dateRange.to) {
      setDateRange((previous) => ({
        from: addDays(previous.from!, 7),
        to: addDays(previous.to!, 7),
      }));
    }
  };

  const onPreviousPeriod = () => {
    if (dateRange && dateRange.from && dateRange.to) {
      setDateRange((previous) => ({
        from: subDays(previous.from!, 7),
        to: subDays(previous.to!, 7),
      }));
    }
  };

  const preferredYAxisType = useMemo(() => {
    if (formData && templateData) {
      const selectedQuestions = templateData.questions.filter((p) =>
        formData.questions
          .map((q) => q.questionId.toLowerCase())
          .includes(p.guid.toLowerCase())
      );

      if (
        selectedQuestions.some((p) => p.type !== AssessmentQuestionType.RANGE)
      ) {
        return YAxisAssessmentFormChartType.ICONS;
      }

      return YAxisAssessmentFormChartType.NUMERIC;
    }

    return YAxisAssessmentFormChartType.ICONS;
  }, [formData, templateData]);

  return (
    <>
      <div
        className="StatusItem__content__options__toggle"
        onClick={toggle}
      ></div>
      {isOpen && (
        <Modal
          className="AssessmentFormQuestionStatsModal"
          size="xl"
          isOpen={isOpen}
          toggle={toggle}
        >
          <ModalHeader
            className="AssessmentFormQuestionStatsModal__header"
            toggle={toggle}
          />
          <ModalBody className="AssessmentFormQuestionStatsModal__body">
            <div className="AssessmentFormQuestionStatsModal__body__date-range">
              <div className="TimelineTabsFilters__date-select AssessmentFormQuestionStatsModal__body__date-range__date-select">
                <Label>
                  <FormattedMessage id="General.selectDates" />
                </Label>
                <CustomDatePicker
                  hideTime
                  onDateAccept={onDateRangeChange}
                  onPredefineValueClick={onPredefinedValueClick}
                  selectedRange={dateRange}
                  oneColumn
                />
              </div>
              <div className="AssessmentFormQuestionStatsModal__body__date-range__navigation">
                <Button
                  outline
                  color="primary"
                  className="TimelineTabsFilters__range-button me-2"
                  onClick={onPreviousPeriod}
                >
                  <img src={Arrow} alt="arrow_left1" />
                  <img src={Arrow} alt="arrow_right1" className="me-2" />
                  <FormattedMessage id="General.previous" />{' '}
                  <FormattedMessage id="UserDashboard.SqueezeSummary.timeRange.Days7" />
                </Button>
                <Button
                  outline
                  color="primary"
                  className="TimelineTabsFilters__range-button"
                  onClick={onNextPeriod}
                >
                  <FormattedMessage id="General.next" />{' '}
                  <FormattedMessage id="UserDashboard.SqueezeSummary.timeRange.Days7" />
                  <div className="invert">
                    <img src={Arrow} alt="arrow_left2" />
                    <img src={Arrow} alt="arrow_right2" className="me-2" />
                  </div>
                </Button>
              </div>
            </div>
            {formData && (
              <>
                {formData.questions.length === 0 ? (
                  <NoDataTrendsChartPlaceholder />
                ) : (
                  <div>
                    <div className="AssessmentFormQuestionStatsModal__body__title__questions mb-5 ms-4">
                      <SVG
                        src={assessmentFormChartIcon}
                        title="header_icon"
                        className="me-2"
                      />
                      <ul className="AssessmentFormQuestionStatsModal__body__title__questions__items">
                        {formData.questions.map((question, index) => (
                          <li key={`question-${question.questionId}-${index}`}>
                            {`${question.questionTitle}`}
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      {templateData && (
                        <AssessmentFormChart
                          template={templateData}
                          chartType={CHART_TYPE}
                          questions={formData.questions}
                          legend={false}
                          dateRange={dateRange}
                          yAxisType={preferredYAxisType}
                        />
                      )}
                    </div>
                  </div>
                )}
              </>
            )}
          </ModalBody>
          <ModalFooter className="AssessmentFormQuestionStatsModal__footer">
            <div className="d-flex justify-content-end">
              <Button outline color="primary" onClick={toggle}>
                <FormattedMessage id="General.close" />
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      )}
    </>
  );
};

export default AssessmentFormQuestionStats;
