import { formatDate } from 'helpers/dates';
import React from 'react';
import { TooltipProps } from 'recharts';
import { AssessmentQuestionHistoryEntryItem } from '../../../../../../../interfaces/cases';

interface AssessmentFormChartTooltipProps extends TooltipProps<string, string> {
  question: AssessmentQuestionHistoryEntryItem | undefined;
  invertBy?: number;
}

const AssessmentFormChartTooltip = ({
  question,
  payload,
  label,
  invertBy,
}: AssessmentFormChartTooltipProps) => {
  if (payload && payload.length > 0) {
    const title = question?.answerHistory.find(
      (p) => p.value === Number(payload[0].value)
    );

    const renderValue = () => {
      if (title?.title) {
        return title.title;
      }

      if (invertBy && Number(payload[0].value)) {
        return invertBy - Number(payload[0].value);
      }

      return payload[0].value;
    };

    return (
      <div className="AssessmentFormChartTooltip">
        <span className="AssessmentFormChartTooltip__date">{`Date: ${formatDate(
          new Date(label as number).toISOString(),
          { addSeconds: false }
        )}`}</span>
        <span className="AssessmentFormChartTooltip__value">{`Value: ${renderValue()}`}</span>
        <span className="AssessmentFormChartTooltip__type">{`Form Question: ${payload[0].dataKey}`}</span>
      </div>
    );
  }

  return <></>;
};

export default AssessmentFormChartTooltip;
