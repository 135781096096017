import { useGroupScheduleData } from '../../GroupSchedule/hooks/useGroupScheduleData';
import { useGenerateGroupSchedule } from '../../GroupSchedule/hooks/useGenerateGroupSchedule';
import React, { useCallback } from 'react';
import { ICaseGroupFormValues } from '../../../hooks/useCreateCaseGroupForm';

export const useCreateCaseGroupStartingDateField = (
  values: ICaseGroupFormValues,
  setValues: (
    values: React.SetStateAction<ICaseGroupFormValues>,
    shouldValidate?: boolean | undefined
  ) => void
) => {
  const { treatmentPhases } = useGroupScheduleData(values.case_category_id);

  const generateGroupSchedule = useGenerateGroupSchedule();

  const handleStartingDateChanged = useCallback(
    (target: string, value: string) => {
      if (!treatmentPhases.data || !values.case_category_id) {
        setValues((prev) => ({
          ...prev,
          starting_date: value,
        }));

        return;
      }

      setValues((prev) => ({
        ...prev,
        starting_date: value,
        group_schedule: generateGroupSchedule(
          new Date(value),
          treatmentPhases.data
        ),
      }));
    },
    [
      generateGroupSchedule,
      setValues,
      treatmentPhases.data,
      values.case_category_id,
    ]
  );

  return {
    handleChange: handleStartingDateChanged,
  };
};
