import { IMutationProps } from '../../../interfaces/ui';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { ICreateOrganizationObservationMeaning } from '../../../interfaces/observationMeanings';
import { addOrganizationObservationMeaning } from '../../observationMeanings';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { IErrorDefinition } from '../../../interfaces/response';

type useAddObservationMeaningParams = {
  organizationId: string;
};

export const useAddObservationMeaning = (
  params: useAddObservationMeaningParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (body: ICreateOrganizationObservationMeaning) => {
      await addOrganizationObservationMeaning(params.organizationId, body);
    },
    onSuccess: () => {
      if (options.successFb) options.successFb();

      displaySuccessNotification(
        'Api.Mutations.ObservationMeanings.AddObservationMeaning.success'
      );
    },
    onErrorArray: (errors: IErrorDefinition[]) => {
      if (options.errorFb) options.errorFb();

      displayErrorArrayNotifications(
        errors,
        'Api.Mutations.ObservationMeanings.AddObservationMeaning.failure'
      );
    },
    resetOnResult: true,
  });
};
