import { Case, Switch } from 'components/SwitchCase';
import React from 'react';
import {
  DashboardTemplateVisualization,
  DashboardTemplateVisualizationType,
  IChartDashboardTemplateVisualization,
  IDashboardDashboardTemplateVisualization,
  IListDashboardTemplateVisualization,
  ISelectableDashboardTemplateVisualization,
  ITableDashboardTemplateVisualization,
  ITextDashboardTemplateVisualization,
} from '../../../../../../../../../../../../interfaces/dashboards';
import ChartView from '../ChartView';
import DashboardView from '../DashboardView';
import ListView from '../ListView';
import SelectableListView from '../ListView/variants/SelectableListView';
import TableView from '../TableView';
import TextView from '../TextView';
import VisualizationGridWrapper from './wrappers/VisualizationGridWrapper';
import VisualizationThemeWrapper from './wrappers/VisualizationThemeWrapper';

type VisualizationProps = {
  visualization: DashboardTemplateVisualization;
};

const Visualization = ({ visualization }: VisualizationProps) => {
  return (
    <VisualizationThemeWrapper visualization={visualization}>
      <VisualizationGridWrapper visualization={visualization}>
        <Switch item={visualization.type}>
          <Case value={DashboardTemplateVisualizationType.ChartView}>
            <ChartView
              visualization={
                visualization as IChartDashboardTemplateVisualization
              }
            />
          </Case>
          <Case value={DashboardTemplateVisualizationType.TableView}>
            <TableView
              visualization={
                visualization as ITableDashboardTemplateVisualization
              }
            />
          </Case>
          <Case value={DashboardTemplateVisualizationType.ListView}>
            <ListView
              visualization={
                visualization as IListDashboardTemplateVisualization
              }
            />
          </Case>
          <Case value={DashboardTemplateVisualizationType.SelectableListView}>
            <SelectableListView
              visualization={
                visualization as ISelectableDashboardTemplateVisualization
              }
            />
          </Case>
          <Case value={DashboardTemplateVisualizationType.DashboardView}>
            <DashboardView
              visualization={
                visualization as IDashboardDashboardTemplateVisualization
              }
            />
          </Case>
          <Case value={DashboardTemplateVisualizationType.TextView}>
            <TextView
              visualization={
                visualization as ITextDashboardTemplateVisualization
              }
            />
          </Case>
        </Switch>
      </VisualizationGridWrapper>
    </VisualizationThemeWrapper>
  );
};

export default Visualization;
