import { formatDate, formattedDateToDateInput } from 'helpers/dates';
import React, { useState } from 'react';

interface CustomCalendarInputRequiredProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface CustomCalendarInputOptionalProps {
  defaultDateString?: string;
  disabled?: boolean;
  value?: string | null;
  id?: string;
  name?: string;
}

interface CustomCalendarInputProps
  extends CustomCalendarInputRequiredProps,
    CustomCalendarInputOptionalProps {}

const defaultProps: CustomCalendarInputOptionalProps = {
  defaultDateString: undefined,
  disabled: false,
};

const CustomCalendarInput = ({
  onChange,
  defaultDateString,
  disabled,
  value: propsValue,
  id,
  name = 'custom-date',
}: CustomCalendarInputProps) => {
  const formattedDefaultValue =
    defaultDateString !== undefined
      ? formattedDateToDateInput(
          formatDate(defaultDateString, { ignoreTime: true, addSeconds: false })
        )
      : undefined;

  const [currentValue, setCurrentValue] = useState<string | undefined>(
    formattedDefaultValue
  );

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e);
    setCurrentValue(e.target.value);
  };

  let value = currentValue;
  if (propsValue !== undefined) {
    value = propsValue || '';
  }

  return (
    <input
      id={id}
      disabled={disabled}
      max="9999-12-31"
      minLength={8}
      type="date"
      name={name}
      className="CustomCalendar"
      onChange={handleChange}
      value={value}
      defaultValue={formattedDefaultValue}
    />
  );
};

CustomCalendarInput.defaultProps = defaultProps;

export default CustomCalendarInput;
