import { useOrganizationPathParams } from '../../../../../../../../../../../hooks/paths/useOrganizationPathParams';
import { useCallback } from 'react';
import useOrganizationDevices from '../../../../../../../../../../../api/queries/organizations/useOrganizationDevices';
import usePatchOrganizationDevice from '../../../../../../../../../../../api/mutations/organizations/usePatchOrganizationDevice';

export const useHandleSave = (onSave: () => void) => {
  const { organizationId } = useOrganizationPathParams();

  const organizationDevices = useOrganizationDevices({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const onSuccess = useCallback(async () => {
    onSave();
    await organizationDevices.refetch();
  }, [onSave, organizationDevices]);

  const { mutateAsync: patchAsync, isLoading } = usePatchOrganizationDevice({
    params: { organizationId },
    options: { successFb: onSuccess },
  });

  return { saveAsync: patchAsync, isSaving: isLoading };
};
