import { IValidationSchemaBasedFormHookValues } from '../../../../../../../../../interfaces/forms';
import {
  IAwareSessionDataQuestion,
  IScheduleAwareSessionData,
} from '../../../../../../../../../interfaces/awareSessions';
import { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { format, subMonths } from 'date-fns';
import { ConversationTemplate } from '../../../../../../../../../interfaces/conversations';
import { getUserId } from '../../../../../../../../../helpers/utils/auth';

export type ScheduleFormHookValues = {
  patient_number?: number;
  date: string;
  time: string;
  questions: IAwareSessionDataQuestion[];
};

export const useForm = (
  template: ConversationTemplate,
  onSave: (body: IScheduleAwareSessionData) => Promise<void>
): IValidationSchemaBasedFormHookValues<ScheduleFormHookValues> => {
  const user = getUserId();

  const initialValues = useMemo((): ScheduleFormHookValues => {
    const now = new Date();

    return {
      date: format(now, 'yyyy-MM-dd'),
      time: format(now, 'HH:mm'),
      patient_number: undefined,
      questions: [],
    };
  }, []);

  const validationSchema = Yup.object().shape({
    date: Yup.string().required(),
    time: Yup.string().required(),
    patient_number: Yup.number().optional(),
    questions: Yup.array().required(),
  });

  const handleSubmit = useCallback(
    async (values: typeof initialValues) => {
      const dateElements = values.date.split('-').map((el) => parseInt(el));
      const timeElements = values.time.split(':').map((el) => parseInt(el));

      let timeStart = new Date(
        dateElements[0],
        dateElements[1],
        dateElements[2],
        timeElements[0],
        timeElements[1],
        0
      );

      timeStart = subMonths(timeStart, 1);

      const questionAnswers = values.questions.map((question) => ({
        id: question.id.split('|')[0],
        value: question.id.split('|')[1],
      }));

      await onSave({
        patient_number: values.patient_number,
        start_date: timeStart.toISOString(),
        end_date: timeStart.toISOString(),
        member_id: user,
        questions: questionAnswers,
      });
    },
    [onSave, user]
  );

  return {
    initialValues,
    validationSchema,
    handleSubmit,
  };
};
