import { useCallback } from 'react';
import { FormikProps } from 'formik';
import { ManageActivityFormValues } from './useForm';

export const useHandleActivity = () => {
  return useCallback(
    (id: string, form: FormikProps<ManageActivityFormValues>) => () => {
      form.setFieldValue('activityId', id);
    },
    []
  );
};
