import useOpen from '../../../../../../../../../hooks/useOpen';

export const useGraspModalMode = () => {
  const addGraspModal = useOpen();
  const createGraspModal = useOpen();
  const uploadGraspModal = useOpen();

  const handleCreate = () => {
    addGraspModal.toggle();
    createGraspModal.toggle();
  };

  const handleUpload = () => {
    addGraspModal.toggle();
    uploadGraspModal.toggle();
  };

  return {
    addGraspModal,
    createGraspModal,
    uploadGraspModal,
    handleCreate,
    handleUpload,
  };
};
