import CustomTable from 'components/CustomTable';
import { IPersonality } from 'interfaces/personalities';
import React from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import PatientCustomTablePlaceholder from './PatientCustomTablePlaceholder';
import EmptyInformation from '../../../../TimelineDetails/EmptyInformation';
import InfiniteListLayoutLoader from '../../../../../components/InfiniteList/InfiniteListLayoutLoader';
import useMobile from '../../../../../hooks/useBreakpoint';
import { formatDate } from '../../../../../helpers/dates';

type PatientTableRendererProps = {
  patients: IPersonality[];
  isLoading: boolean;
  hasNextPage?: boolean;
  fetchNextPage: () => void;
  onRowClick: (el: IPersonality) => void;
};

const PatientTableRenderer = ({
  patients,
  isLoading,
  hasNextPage = false,
  fetchNextPage,
  onRowClick,
}: PatientTableRendererProps) => {
  const hasMore = !isLoading && hasNextPage;

  const isMobile = useMobile(576);

  const bodyRows = patients.map((el: IPersonality) => {
    const columns: JSX.Element[] = [
      <td key={`${el.id}-first_name`}>
        {isMobile ? `${el.first_name} ${el.last_name}` : el.first_name}
      </td>,
      <td key={`${el.id}-last_name`}>{el.last_name}</td>,
      <td key={`${el.id}-email`}>{el.email}</td>,
      <td key={`${el.id}-phone_number`}>{el.phone_number}</td>,
      <td key={`${el.id}-patient_number`}>{el.number}</td>,
      <td key={`${el.id}-gender`}>{el.gender}</td>,
      <td key={`${el.id}-birthdate`}>
        {formatDate(el.birthdate, {
          ignoreTime: true,
        })}
      </td>,
    ];

    return (
      <tr
        key={el.id}
        onClick={() => onRowClick(el as any)}
        className="cursor-pointer"
      >
        {columns}
      </tr>
    );
  });

  const tableHeaders = [
    'Patient.patientList.name',
    'Patient.patientList.lastName',
    'Login.LoginForm.email',
    'Register.RegisterForm.phoneNumber',
    'Patient.patientNumber',
    'Participants.ParticipantsList.PatientTableRenderer.gender',
    'Participants.ParticipantsList.PatientTableRenderer.birthdate',
  ];

  if (isLoading) {
    return (
      <PatientCustomTablePlaceholder headerItems={tableHeaders} rowsCount={5} />
    );
  }

  if (patients.length === 0 && hasNextPage !== true) {
    return (
      <EmptyInformation
        type={'range'}
        graspLogo={false}
        translationId={'Patient.patientList.emptyList'}
      />
    );
  }

  return (
    <InfiniteScroll
      loadMore={fetchNextPage}
      hasMore={hasMore}
      loader={
        <InfiniteListLayoutLoader
          key="patient-table-renderer-loader"
          translationId="Participants.ParticipantsList.loadMore"
        />
      }
      threshold={100}
      initialLoad={false}
    >
      {patients && (
        <CustomTable
          headerItems={tableHeaders}
          bodyRows={bodyRows}
          responsiveConfig={[
            {
              breakpoint: 999,
              hiddenColumns: [5, 6],
            },
            {
              breakpoint: 576,
              hiddenColumns: [1, 2, 3, 5, 6],
            },
          ]}
        />
      )}
    </InfiniteScroll>
  );
};

export default PatientTableRenderer;
