import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/pro-light-svg-icons';
import { GraspColors, graspColorsValues } from 'helpers/colors';
import { getIconForTemplateItemType } from 'helpers/utils/icons';
import Skeleton from 'react-loading-skeleton';
import { TemplateItemType } from 'interfaces/change';

const SavedViewsItemPlaceholder = () => {
  const renderPlaceholder = () => {
    return (
      <div className="card shadow-sm d-flex flex-column gap-1 p-3">
        <div className="d-flex justify-content-between">
          <span className="fs-5">
            <Skeleton width={200} />
          </span>
          <div>
            <Button color="primary" outline={true}>
              <FontAwesomeIcon
                icon={faEllipsisH}
                color={graspColorsValues[GraspColors.BLUE]}
              />
            </Button>
          </div>
        </div>
        <span className="fs-6 text-muted">
          <Skeleton width={100} />
        </span>
        <div className="d-flex align-items-center gap-1">
          <div className="d-flex align-items-center gap-2">
            {Object.values(TemplateItemType).map((itemType) =>
              getIconForTemplateItemType(itemType)
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      {renderPlaceholder()}
      {renderPlaceholder()}
      {renderPlaceholder()}
      {renderPlaceholder()}
      {renderPlaceholder()}
      {renderPlaceholder()}
    </>
  );
};

export default SavedViewsItemPlaceholder;
