import React, { useState } from 'react';
import { Button, Form, FormGroup, Label } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, Formik } from 'formik';
import FieldInput from '../../../../components/FieldInput';
import FormikErrorMessage from '../../../../components/FormikErrorMessage';
import showPasswordIcon from '../../../../assets/images/show_password_icon.svg';
import passwordLogo from '../../../../assets/images/register/password.svg';
import {
  AccountDeletionData,
  AccountDeletionStep,
} from './AccountDeletionModal';
import { validateEmpty } from '../../../../helpers/utils/validators';
import useVerifyUsersPassword from '../../../../api/mutations/membership/useVerifyUsersPassword';
import { IResponse } from '../../../../interfaces/response';
import { ILoginResponse } from '../../../../interfaces/membership';
import useUserDelete from '../../../../api/mutations/membership/useUserDelete';

type AccountDeletionPasswordVerificationProps = {
  toggle: () => void;
  accountDeletionData: AccountDeletionData;
  setAccountDeletionData: (data: AccountDeletionData) => void;
  setAccountDeletionStep: (step: AccountDeletionStep) => void;
};

type PasswordVerificationInitialValues = {
  password?: string;
  newsletter_sign_out: boolean;
};

const AccountDeletionPasswordVerification = ({
  accountDeletionData,
  setAccountDeletionData,
  setAccountDeletionStep,
  toggle,
}: AccountDeletionPasswordVerificationProps) => {
  const intl = useIntl();

  const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
  const initialValues: PasswordVerificationInitialValues = {
    password: undefined,
    newsletter_sign_out: false,
  };

  const { mutateAsync } = useVerifyUsersPassword();
  const { mutate: userDeletionMutate } = useUserDelete();

  const handleSubmitForm = async (values: typeof initialValues) => {
    setAccountDeletionData({
      ...accountDeletionData,
      newsletter_sign_out: values.newsletter_sign_out,
    });

    const data = await mutateAsync({
      password: values.password ?? '',
    });

    return data.data;
  };

  const handleSubmitWithSetValues = async (
    values: typeof initialValues,
    handleSubmit: (
      values: typeof initialValues
    ) => Promise<IResponse<ILoginResponse>>,
    setValues: (values: typeof initialValues) => void
  ) => {
    try {
      await handleSubmit(values);
    } catch (Exception) {
      setValues({
        ...values,
        password: '',
      });
      return;
    }

    userDeletionMutate({
      newsletter_sign_out: values.newsletter_sign_out,
      deletion_reasons: accountDeletionData.deletion_reasons,
      feedback: accountDeletionData.feedback,
    });

    setAccountDeletionStep(AccountDeletionStep.Confirmation);
  };

  const togglePasswordVisible = () => setPasswordVisible((visible) => !visible);

  const validation = (values: typeof initialValues) => {
    const validationConstraints = validateEmpty<typeof initialValues>(
      values,
      intl
    );

    if (!values.password || (values.password && values.password.length === 0)) {
      validationConstraints.password = intl.formatMessage({
        id: 'MyProfile.AccountDeletion.AccountDeletionModal.wrongPassword',
      });
    }

    return validationConstraints;
  };

  return (
    <div>
      <div className="AccountDeletionPasswordVerification__container">
        <div className="AccountDeletionPasswordVerification__container mb-3">
          <span className="AccountDeletionPasswordVerification__text">
            <FormattedMessage id="MyProfile.AccountDeletion.AccountDeletionModal.byClickingYes" />
          </span>
          <span className="AccountDeletionPasswordVerification__text">
            <FormattedMessage id="MyProfile.AccountDeletion.AccountDeletionModal.inTheMeantime" />
          </span>
        </div>
        <span className="AccountDeletionPasswordVerification__enter-password">
          <FormattedMessage id="MyProfile.AccountDeletion.AccountDeletionModal.enterPassword" />
        </span>
      </div>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validateOnMount={true}
        validate={validation}
        onSubmit={handleSubmitForm}
      >
        {({ submitForm, isValid, values, setValues }) => (
          <>
            <Form>
              <FormGroup>
                <Label htmlFor="password">
                  <FormattedMessage id="SetPassword.SetPasswordForm.password" />
                </Label>
                <Field
                  id="password"
                  name="password"
                  type={passwordVisible ? 'text' : 'password'}
                  placeholder="Provide your master password"
                  addon={<img src={passwordLogo} alt="password_logo" />}
                  rightHandedAddon={
                    <img
                      src={showPasswordIcon}
                      alt="Show password"
                      onClick={togglePasswordVisible}
                    />
                  }
                  component={FieldInput}
                />
                <div className="d-flex flex-column">
                  <>
                    <FormikErrorMessage name="password" />
                  </>
                </div>
              </FormGroup>
              <FormGroup className="AccountDeletionModal__newsletter">
                <Field
                  id="newsletter_sign_out"
                  name="newsletter_sign_out"
                  type="checkbox"
                  component={FieldInput}
                />
                <Label htmlFor="newsletter_sign_out">
                  <FormattedMessage id="MyProfile.AccountDeletion.AccountDeletionModal.newsletter" />
                </Label>
              </FormGroup>
            </Form>
            <div className="AccountDeletionModal__buttons">
              <Button
                outline
                color="primary"
                onClick={toggle}
                className="AccountDeletionModal__button"
              >
                <FormattedMessage id="General.cancel" />
              </Button>
              <Button
                className="AccountDeletionModal__button"
                disabled={!isValid}
                color="primary"
                onClick={() =>
                  handleSubmitWithSetValues(values, submitForm, setValues)
                }
              >
                <FormattedMessage id="MyProfile.AccountDeletion.AccountDeletionModal.yesDelete" />
              </Button>
            </div>
          </>
        )}
      </Formik>
    </div>
  );
};

export default AccountDeletionPasswordVerification;
