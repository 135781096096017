import React, { useMemo, useState } from 'react';
import { FormGroup } from 'reactstrap';
import EditableInput from '../../../../../../../../../../../components/EditableInput/EditableInput';
import { FormattedMessage } from 'react-intl';
import { useFunctionality } from '../../../../../../../../../../../hooks/useFunctionality';
import { FunctionalityCode } from '../../../../../../../../../../../interfaces/functionalities';
import { useConversationQuestionsData } from './useConversationQuestionsData';
import { addNewItemOptions } from '../types';

export const useAvailableItems = () => {
  const { hasFunctionality: hasEducationMaterialFunctionality } =
    useFunctionality(FunctionalityCode.CONVERSATIONS_EDUCATION_MATERIALS);

  const { groupOptions } = useConversationQuestionsData();

  const [selectedOption, setSelectedOption] = useState<string>(
    addNewItemOptions[0]
  );

  const [selectedQuestion, setSelectedQuestion] = useState<string | null>(null);

  const onItemSelect = (_: string, value: string) => {
    setSelectedOption(value);
    setSelectedQuestion(null);
  };

  const onQuestionSelect = (_: string, value: string) => {
    setSelectedQuestion(value);
  };

  const renderedItems = useMemo(() => {
    return Array.from(Array(addNewItemOptions.length).keys()).map((element) => {
      if (
        (addNewItemOptions[element] === 'educationMaterialsList' ||
          addNewItemOptions[element] === 'educationMaterialsItem') &&
        !hasEducationMaterialFunctionality
      )
        return <></>;

      return (
        <FormGroup key={addNewItemOptions[element]}>
          <div className="d-flex align-items-center gap-2">
            <EditableInput
              type="radio"
              name="item-type"
              checked={addNewItemOptions[element] === selectedOption}
              value={addNewItemOptions[element]}
              id={`ConversationTemplatePhoneAddItem${addNewItemOptions[element]}`}
              onEdit={onItemSelect}
              debounceEnabled={false}
            />
            <label
              htmlFor={`ConversationTemplatePhoneAddItem${addNewItemOptions[element]}`}
            >
              <FormattedMessage
                id={`ConversationsAndFeedbacks.ConversationTemplatePhoneAddItem.${addNewItemOptions[element]}`}
              />
            </label>
          </div>
          {addNewItemOptions[element] === selectedOption &&
            (selectedOption === 'createQuestionBasedOnExisting' ||
              selectedOption === 'useExistingQuestion') && (
              <EditableInput
                displayEmpty={true}
                style={{ fontSize: '0.8rem' }}
                className="mt-2"
                type="select"
                onEdit={onQuestionSelect}
                groups={groupOptions}
                debounceEnabled={false}
              />
            )}
        </FormGroup>
      );
    });
  }, [groupOptions, hasEducationMaterialFunctionality, selectedOption]);

  return {
    renderedItems,
    selectedOption,
    selectedQuestion,
  };
};
