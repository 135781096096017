import useSendFeedback from 'api/mutations/membership/useSendFeedback';
import FieldInput from 'components/FieldInput';
import FieldTextarea from 'components/FieldTextarea';
import FormikErrorMessage from 'components/FormikErrorMessage';
import LoadingButton from 'components/LoadingButton';
import { Field, Formik } from 'formik';
import { GraspFeedbackBody } from 'interfaces/grasps';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import ImportanceInput from './ImportanceInput';

export interface GraspFeedbackBodyImportanceWithNumber
  extends Omit<GraspFeedbackBody, 'importance'> {
  importance: number;
}

const initialValues: GraspFeedbackBodyImportanceWithNumber = {
  subject: '',
  description: '',
  importance: 1,
  category: 'Improvement',
  email_contact: '',
  phone_number_contact: '',
  metadata: [],
};

type FeedbackModalProps = {
  isOpen: boolean;
  toggle: () => void;
  initialValues?: Partial<GraspFeedbackBodyImportanceWithNumber>;
};

const FeedbackModal = ({
  isOpen,
  toggle,
  initialValues: initialValuesProps,
}: FeedbackModalProps) => {
  const intl = useIntl();

  const { mutate, isLoading } = useSendFeedback();

  const onSubmit = (values: typeof initialValues) => {
    const { importance, ...resOfValues } = values;

    let selectedImportance: 'Low' | 'Medium' | 'High' | 'Critical' = 'Low';
    switch (importance) {
      case 1:
      default:
        selectedImportance = 'Low';
        break;
      case 2:
        selectedImportance = 'Medium';
        break;
      case 3:
        selectedImportance = 'High';
        break;
      case 4:
        selectedImportance = 'Critical';
        break;
    }

    mutate({ ...resOfValues, importance: selectedImportance });
    toggle();
  };

  const validation = (values: GraspFeedbackBodyImportanceWithNumber) => {
    const errors: Partial<GraspFeedbackBodyImportanceWithNumber> = {};

    ['subject', 'email', 'description'].forEach((key) => {
      if (values[key] === '')
        errors[key] = intl.formatMessage({
          id: 'CaseCard.create.errors.inputEmpty',
        });
    });

    return errors;
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader>
        <FormattedMessage id="Footer.FeedbackModal.header" />
      </ModalHeader>
      <Formik
        validate={validation}
        onSubmit={onSubmit}
        initialValues={{ ...initialValues, ...initialValuesProps }}
      >
        {({ isValid, submitForm, values }) => (
          <>
            <ModalBody>
              <Form>
                <FormGroup>
                  <Label htmlFor="subject">
                    <FormattedMessage id="Footer.FeedbackModal.subject" />
                  </Label>
                  <Field
                    required
                    name="subject"
                    id="subject"
                    placeholder={intl.formatMessage({
                      id: 'Footer.FeedbackModal.subjectPlaceholder',
                    })}
                    component={FieldInput}
                  />
                  <FormikErrorMessage name="subject" />
                </FormGroup>
                <FormGroup role="group" aria-labelledby="my-radio-group">
                  <Label className="me-2">
                    <Field
                      type="radio"
                      name="category"
                      value="Bug"
                      checked={values.category === 'Bug'}
                    />
                    <span className="ms-1">
                      <FormattedMessage id="Footer.FeedbackModal.bug" />
                    </span>
                  </Label>
                  <Label className="me-2">
                    <Field
                      type="radio"
                      name="category"
                      value="Improvement"
                      checked={values.category === 'Improvement'}
                    />
                    <span className="ms-1">
                      <FormattedMessage id="Footer.FeedbackModal.improvement" />
                    </span>
                  </Label>
                </FormGroup>
                {values.category === 'Bug' && (
                  <FormGroup role="group" aria-labelledby="my-radio-group">
                    <Label htmlFor="importance">
                      <FormattedMessage id="Footer.FeedbackModal.importance" />
                    </Label>
                    <Field
                      name="importance"
                      id="importance"
                      component={ImportanceInput}
                      rows="4"
                    />
                  </FormGroup>
                )}
                <FormGroup>
                  <Label htmlFor="description">
                    <FormattedMessage id="UserDashboard.description" />
                  </Label>
                  <Field
                    name="description"
                    id="description"
                    placeholder={intl.formatMessage({
                      id: 'Footer.FeedbackModal.descriptionPlaceholder',
                    })}
                    component={FieldTextarea}
                    rows="4"
                  />
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="email_contact">
                    <FormattedMessage id="Footer.FeedbackModal.emailContact" />
                  </Label>
                  <Field
                    required
                    type="email"
                    id="email_contact"
                    name="email_contact"
                    placeholder={intl.formatMessage({
                      id: 'Footer.FeedbackModal.emailContactPlaceholder',
                    })}
                    component={FieldInput}
                  />
                  <FormikErrorMessage name="email" />
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button onClick={toggle}>
                <FormattedMessage id="Footer.FeedbackModal.cancel" />
              </Button>
              <LoadingButton
                isLoading={isLoading}
                disabled={!isValid}
                onClick={submitForm}
              >
                <FormattedMessage id="Footer.FeedbackModal.send" />
              </LoadingButton>
            </ModalFooter>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default FeedbackModal;
