import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormGroup, InputGroup, Label } from 'reactstrap';

type InputFieldWithImageProps = {
  children: JSX.Element;
  errorInfo?: JSX.Element;
  additionalInfo?: JSX.Element;
  labelTranslationId?: string;
  disabled?: boolean;
  isOptional?: boolean;
  gray?: boolean;
  arrowIcon?: boolean;
  inputId?: string;
  noMargin?: boolean;
  block?: boolean;
  unstyled?: boolean;
};

const InputFieldWithoutImage = ({
  children,
  labelTranslationId,
  disabled,
  errorInfo,
  additionalInfo,
  isOptional = false,
  gray = false,
  arrowIcon = false,
  noMargin = false,
  block = false,
  unstyled = false,
  inputId,
}: InputFieldWithImageProps) => {
  return (
    <FormGroup
      className={classNames({
        InputFieldWithImage: true,
        'InputFieldWithImage--no-margin': noMargin,
        'w-100': block,
      })}
    >
      {labelTranslationId && (
        <Label htmlFor={inputId} className="mb-1 d-flex gap-1">
          <FormattedMessage id={labelTranslationId} />
          {isOptional && (
            <span className="newMembersSection__secondLink__desc">
              (<FormattedMessage id="General.optional" />){' '}
            </span>
          )}
        </Label>
      )}
      {unstyled ? (
        <InputGroup className="InputFieldWithImage__input-group">
          {React.cloneElement(children, { disabled })}
        </InputGroup>
      ) : (
        <InputGroup
          className={classNames('InputFieldWithImage__input-group', {
            'InputFieldWithImage__input-group--primary': !gray,
            'InputFieldWithImage__input-group--gray': gray,
            'InputFieldWithImage__input-group--arrow-icon': arrowIcon,
          })}
        >
          {React.cloneElement(children, { disabled })}
        </InputGroup>
      )}
      {errorInfo}
      {additionalInfo}
    </FormGroup>
  );
};

export default InputFieldWithoutImage;
