import { TrendVisualizationTemplate } from '../../../../../../../interfaces/trendVisualizations';
import { cloneDeep } from 'lodash';
import { TemplateItemType } from '../../../../../../../interfaces/change';

export const filterExerciseBasedVisualizationElements = (
  template: TrendVisualizationTemplate
): TrendVisualizationTemplate => {
  let templateCopy = cloneDeep(template);

  templateCopy.visualizations = templateCopy.visualizations.map(
    (visualization) => {
      return {
        ...visualization,
        elements: visualization.elements.filter(
          (p) =>
            p.type === TemplateItemType.AllObservations ||
            p.type === TemplateItemType.Questions
        ),
      };
    }
  );

  templateCopy.visualizations = templateCopy.visualizations.filter(
    (p) => p.elements.length > 0
  );
  return templateCopy;
};
