import React from 'react';
import { useTypedContext } from '../../../../../../../../../../../../../../../../hooks/useTypedContext';
import { ReactStateTuple } from '../../../../../../../../../../../../../../../../interfaces/ui';
import { ValidationContext } from '../../../../types';
import { ValidationResult } from '../../../../../../../../types';
import Conflict from './components/Conflict';
import ReadyToComplete from './components/ReadyToComplete';

const Conflicts = () => {
  const [validation] =
    useTypedContext<ReactStateTuple<ValidationResult>>(ValidationContext);

  return (
    <div className="d-flex flex-column gap-3">
      {validation.ready_to_complete && validation.conflicts.length === 0 ? (
        <ReadyToComplete />
      ) : (
        <>
          {validation.conflicts.map((conflict) => (
            <Conflict key={JSON.stringify(conflict)} conflict={conflict} />
          ))}
        </>
      )}
    </div>
  );
};

export default Conflicts;
