import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import { IUpsertCaseDefaultSetup } from 'interfaces/trendVisualizations';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { upsertCaseDefaultSetups } from '../../caseDefaultSetups';
import { IMutationProps } from '../../../interfaces/ui';

const useUpsertCaseDefaultSetups = (options: IMutationProps) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (data: IUpsertCaseDefaultSetup[]) => {
      await upsertCaseDefaultSetups(data);
    },
    resetOnResult: true,
    onSuccess: () => {
      options.successFb?.();
      displaySuccessNotification(
        'MyOrganizations.trendVisualizations.setAsDefaultSuccess'
      );
    },
    onErrorArray: (errors) => {
      options.errorFb?.();
      displayErrorArrayNotifications(
        errors,
        'MyOrganizations.trendVisualizations.setAsDefaultFailure'
      );
    },
  });
};

export default useUpsertCaseDefaultSetups;
