import { useRemoveOrganizationUserAccessProfile } from '../../../../../../../../../../../api/mutations/accessProfiles/useRemoveOrganizationUserAccessProfile';
import { useOrganizationPathParams } from '../../../../../../../../../../../hooks/paths/useOrganizationPathParams';
import useInfiniteUserInOrganizationsWithRoles from '../../../../../../../../../../../api/queries/organizations/useInfiniteUserInOrganizationsWithRoles';

export const useHandleRemove = () => {
  const { organizationId } = useOrganizationPathParams();

  const userInOrganizationsWithRoles = useInfiniteUserInOrganizationsWithRoles({
    params: { organizationId, pageSize: 1000 },
    options: { enabled: false, staleTime: Infinity },
  });

  const { mutateAsync: removeAsync, isLoading } =
    useRemoveOrganizationUserAccessProfile(
      { organizationId },
      { successFb: userInOrganizationsWithRoles.refetch }
    );

  return { removeAsync, isRemoving: isLoading };
};
