import { EditorView, Extension } from '@uiw/react-codemirror';
import { json } from '@codemirror/lang-json';

export const useExtensions = (provided: Extension[]) => {
  const Theme = EditorView.theme({
    '&': {
      fontSize: '10pt',
      border: '1px solid #407dbb',
      borderRadius: '0.5rem',
    },
    '&.cm-editor': {
      borderRadius: '0.5rem',
    },
    '&.cm-editor.cm-focused': {
      outline: 'none',
    },
    '.cm-scroller': {
      borderRadius: '0.5rem',
    },
    '.cm-lineNumbers, .cm-foldGutter': {
      color: '#407dbb',
    },
  });

  const ThemeExtension: Extension = [Theme];

  if (provided.length > 0)
    return [json(), EditorView.lineWrapping, ...provided];

  return [json(), EditorView.lineWrapping, ThemeExtension];
};
