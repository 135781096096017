import { useDeleteAccessProfile } from '../../../../../../../../../../../api/mutations/accessProfiles/useDeleteAccessProfile';
import { useOrganizationPathParams } from '../../../../../../../../../../../hooks/paths/useOrganizationPathParams';
import useOrganizationUserAccessProfiles from '../../../../../../../../../../../api/queries/accessProfiles/useOrganizationUserAccessProfiles';

export const useHandleDelete = () => {
  const { organizationId } = useOrganizationPathParams();

  const accessProfiles = useOrganizationUserAccessProfiles({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const { mutateAsync: deleteAsync } = useDeleteAccessProfile(
    { organizationId },
    { successFb: accessProfiles.refetch }
  );

  return deleteAsync;
};
