import { IQueryRequest } from '../../../interfaces/request';
import { OrganizationQueryParams } from '../../../interfaces/queryParams';
import { useQuery } from 'react-query';
import { getOrganizationDevices } from '../../organization';
import QueryKeys from '../queryKeys';

const useOrganizationDevices = ({
  params,
  options,
}: IQueryRequest<OrganizationQueryParams>) => {
  const { organizationId } = params;
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getOrganizationDevices(organizationId);
      return data.data;
    },
    queryKey: [QueryKeys.getOrganizationDevices, organizationId],
  });
};

export default useOrganizationDevices;
