import DashboardContentContainer from 'components/Dashboard/components/Dashboard/components/DashboardContentContainer';
import useEventListen from 'hooks/events/useEventListen';
import React, { useState } from 'react';
import { IDashboardDashboardTemplateVisualization } from '../../../../../../../../../../../../interfaces/dashboards';
import DashboardTemplateProvider from './providers/DashboardTemplateProvider';
import EventDataResolver from './resolvers/EventDataResolver';

type DashboardViewProps = {
  visualization: IDashboardDashboardTemplateVisualization;
};

const DashboardView = ({ visualization }: DashboardViewProps) => {
  const [resolvingContext, setResolvingContext] = useState<any>(undefined);

  useEventListen('dashboard-template-params-received', (event: Event) => {
    setResolvingContext((event as CustomEvent).detail);
  });

  return (
    <DashboardTemplateProvider
      context={resolvingContext}
      visualization={visualization}
    >
      {(template) => (
        <EventDataResolver template={template} context={resolvingContext}>
          {(resolvedTemplate) => (
            <DashboardContentContainer template={resolvedTemplate} />
          )}
        </EventDataResolver>
      )}
    </DashboardTemplateProvider>
  );
};

export default DashboardView;
