import checkIcon from 'assets/images/check.svg';

import filterIcon from 'assets/images/filter_icon.svg';
import classNames from 'classnames';
import React from 'react';
import SVG from 'react-inlinesvg';
import {
  GraspColors,
  graspColorsValues,
} from '../../../../../../../../../../../../helpers/colors';
import { useTypedContext } from '../../../../../../../../../../../../hooks/useTypedContext';
import { DashboardFilter } from '../../../../../../../../../../../../interfaces/dashboards';
import { SequenceIdentified } from '../../../../../../../../../../../../interfaces/ui';
import { DashboardSelectionHelpers } from '../../../../../../helpers/selection';
import { DashboardDataReducerContext } from '../../../../../../types';
import { useFilterSelected } from './hooks/useFilterSelected';

type AwareCasesFilterDisplayProps = {
  filter: SequenceIdentified<DashboardFilter>;
  targetId: string;
  filterString: string;
};

const AwareCasesFilterDisplay = ({
  filter,
  filterString,
  targetId,
}: AwareCasesFilterDisplayProps) => {
  const [dashboardData] = useTypedContext(DashboardDataReducerContext);

  const globalSelected = DashboardSelectionHelpers.IsGlobalSelected(
    dashboardData.template.filters
  );

  const isSelected = useFilterSelected(filter);

  const selectedMode = isSelected && !globalSelected;

  return (
    <button
      className={classNames('AwareCasesFilterDisplay', {
        'AwareCasesFilterDisplay--selected': selectedMode,
      })}
      id={targetId}
    >
      {selectedMode ? (
        <SVG
          src={checkIcon}
          fill={graspColorsValues[GraspColors.BLUE]}
          width={20}
          height={20}
        />
      ) : (
        <SVG src={filterIcon} fill={graspColorsValues[GraspColors.BLUE]} />
      )}
      <span>{filterString}</span>
    </button>
  );
};

export default AwareCasesFilterDisplay;
