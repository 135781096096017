import { useInitQueries } from 'hooks/queries/useInitQuery';
import { useQueriesLoading } from 'hooks/queries/useQueriesLoading';
import { ICasePhaseHistory } from 'interfaces/cases';
import { cloneDeep } from 'lodash';
import { setCasePeriodDateRange } from 'modules/TimelineDetails/actions';
import { useCallback, useEffect, useMemo } from 'react';
import { DateRange } from 'react-day-picker';
import { useDispatch } from 'react-redux';
import { useCommonData } from '../../../../../hooks/useCommonData';

export const useManagePeriodRange = (
  dateRange: { from: Date; to: Date } | DateRange | null
) => {
  const { caseDetails, casePhaseHistory } = useCommonData();
  useInitQueries([caseDetails, casePhaseHistory]);

  const isLoading = useQueriesLoading([caseDetails, casePhaseHistory]);

  const dispatch = useDispatch();

  const selectedItems = useMemo((): ICasePhaseHistory[] => {
    if (!casePhaseHistory.data || casePhaseHistory.data.length === 0) return [];

    let selectedHistoryItems: ICasePhaseHistory[] = [];

    casePhaseHistory.data.forEach((item) => {
      if (item.date_from === null || !dateRange?.from) return;

      const nowDate = new Date();
      let dateTo = item.date_to ? new Date(item.date_to) : nowDate;
      let rangeObjectDateTo = dateRange?.to ?? nowDate;

      dateTo = new Date(dateTo.toDateString());
      rangeObjectDateTo = new Date(rangeObjectDateTo.toDateString());

      if (
        new Date(item.date_from) >= dateRange.from &&
        dateTo <= rangeObjectDateTo
      )
        selectedHistoryItems.push(cloneDeep(item));
    });

    return selectedHistoryItems;
  }, [casePhaseHistory, dateRange?.from, dateRange?.to]);

  const handleReset = useCallback(() => {
    if (!casePhaseHistory.data || casePhaseHistory.data.length === 0) return [];

    let startDate = new Date(casePhaseHistory.data[0].date_from ?? '');
    startDate.setHours(0, 0, 0);

    const isLastPhaseCompleted =
      casePhaseHistory.data[casePhaseHistory.data.length - 1].date_from !==
        null &&
      casePhaseHistory.data[casePhaseHistory.data.length - 1].date_to !== null;

    let endDate = isLastPhaseCompleted
      ? new Date(
          casePhaseHistory.data[casePhaseHistory.data.length - 1].date_to!
        )
      : new Date();

    endDate.setHours(23, 59, 59);

    const customDateRange = {
      from: startDate,
      to: endDate,
    };

    dispatch(setCasePeriodDateRange(customDateRange));
  }, [casePhaseHistory, dispatch]);

  useEffect(() => {
    const fromStr = dateRange?.from?.toISOString() ?? '';
    const toStr = dateRange?.to?.toISOString() ?? '';

    if (fromStr !== toStr || fromStr === '') return;
    handleReset();
  }, [dateRange?.from, dateRange?.to, handleReset]);

  useEffect(() => {
    if (!casePhaseHistory.data || casePhaseHistory.data.length === 0) return;

    let startDate = new Date(casePhaseHistory.data[0].date_from ?? '');
    startDate.setHours(0, 0, 0);

    const isLastPhaseCompleted =
      casePhaseHistory.data[casePhaseHistory.data.length - 1].date_from !==
        null &&
      casePhaseHistory.data[casePhaseHistory.data.length - 1].date_to !== null;

    let endDate = isLastPhaseCompleted
      ? new Date(
          casePhaseHistory.data[casePhaseHistory.data.length - 1].date_to!
        )
      : new Date();

    endDate.setHours(23, 59, 59);

    const customDateRange = {
      from: startDate,
      to: endDate,
    };

    dispatch(setCasePeriodDateRange(customDateRange));
  }, [casePhaseHistory.data, dispatch]);

  return {
    selectedItems,
    handleReset,
    isLoading,
    caseDetails: caseDetails.data,
    casePhaseHistory: casePhaseHistory.data ?? [],
  };
};
