import { normalizeQueryParams } from '../helpers/utils/normalizeQueryParams';
import {
  ICreateReportExecutionBody,
  IReport,
  IReportTemplate,
  ReportExecutionQueryParams,
} from '../interfaces/reports';
import { IResponse } from '../interfaces/response';
import request from './utils/request';

export const getOrganizationReportsList = (
  organizationId: string,
  params: Partial<ReportExecutionQueryParams>
) => {
  return request<undefined, IResponse<IReport[]>>({
    url: `organizations/${organizationId}/report_executions${normalizeQueryParams(
      params
    )}`,
    method: 'GET',
  });
};

export const downloadReport = (organizationId: string, id: string) => {
  return request<undefined, ArrayBuffer>({
    url: `organizations/${organizationId}/report_executions/${id}/download`,
    method: 'GET',
    responseType: 'arraybuffer',
    headers: { 'Content-Type': 'blob' },
  });
};

export const getReportTemplate = (code: string) => {
  return request<undefined, IResponse<IReportTemplate>>({
    url: `report_templates/${code}`,
    method: 'GET',
  });
};

export const createNewReportExecution = (data: ICreateReportExecutionBody) =>
  request<ICreateReportExecutionBody, void>({
    url: `/organizations/${data.organization_id}/report_executions`,
    method: 'POST',
    data,
  });
