import { unlinkGrasp } from 'api/graspManagement';
import { IErrorWithCode } from 'interfaces/response';
import { displayErrorNotification } from 'modules/Notifications';
import { useEffect } from 'react';
import { useMutation } from 'react-query';

const ERROR_CODE_FOR_NOT_OWNER = 'invalid_operation_for_resource';

function useUnlinkGrasp(
  userId: string,
  successFb?: () => void,
  cancelFb?: () => void
) {
  const mutationResponse = useMutation<void, IErrorWithCode, string>(
    async (graspId: string) => {
      await unlinkGrasp(userId, graspId);
    }
  );
  const { isSuccess, isError, reset, error } = mutationResponse;

  useEffect(() => {
    if (isSuccess) {
      successFb && successFb();
      reset();
    }
  }, [isSuccess, reset, successFb]);

  useEffect(() => {
    if (isError && error) {
      const errors = error?.data?.meta?.errors;
      const userError = errors.find(
        (singleErr) => singleErr.code === ERROR_CODE_FOR_NOT_OWNER
      );
      if (userError) {
        displayErrorNotification('OrganizationGrasps.errors.notOwnerGrasp');
      } else {
        displayErrorNotification('Errors.default');
      }

      reset();
      cancelFb && cancelFb();
    }
  }, [isError, error, cancelFb, reset]);

  return mutationResponse;
}

export default useUnlinkGrasp;
