import React from 'react';
import useOpen from '../../hooks/useOpen';
import CreateCaseGroupModal from './modals/CreateCaseGroupModal';
import CaseGroupsBreadcrumbs from './components/CaseGroupsBreadcrumbs';

import CaseGroupsContent from './components/CaseGroupsContent';
import { faPlus } from '@fortawesome/pro-light-svg-icons';

const CaseGroups = () => {
  const { isOpen, toggle } = useOpen();

  return (
    <>
      {isOpen && <CreateCaseGroupModal isOpen={isOpen} toggle={toggle} />}
      <CaseGroupsBreadcrumbs
        actions={[
          {
            onAction: toggle,
            icon: faPlus,
          },
        ]}
      />
      <CaseGroupsContent />
    </>
  );
};

export default CaseGroups;
