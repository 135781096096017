import { OrganizationQueryParams } from '../../../interfaces/queryParams';
import { IMutationRequest } from '../../../interfaces/request';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { unlinkEducationMaterialFromCategory } from '../../educationMaterials';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';

type UnlinkEducationMaterialFromCategoryParams = OrganizationQueryParams & {
  categoryId: string;
};

const useUnlinkEducationMaterialFromCategory = ({
  params,
  options,
}: IMutationRequest<UnlinkEducationMaterialFromCategoryParams>) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (materialId: string) => {
      await unlinkEducationMaterialFromCategory(
        params.organizationId,
        params.categoryId,
        materialId
      );
    },
    onSuccess: () => {
      displaySuccessNotification(
        'Api.Mutations.EducationMaterials.UnlinkEducationMaterialFromCategory.success'
      );
      options.successFb?.();
    },
    onError: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'Api.Mutations.EducationMaterials.UnlinkEducationMaterialFromCategory.failure'
      );
      options.errorFb?.();
    },
    resetOnResult: true,
  });
};

export default useUnlinkEducationMaterialFromCategory;
