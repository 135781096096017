import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useQuestionTitle } from '../../../../../../../../../../../../../../../hooks/data/useQuestionTitle';
import { useTypedContext } from '../../../../../../../../../../../../../../../hooks/useTypedContext';
import {
  DashboardFilterType,
  DashboardTemplateSplitType,
  IClosedListDashboardFilter,
} from '../../../../../../../../../../../../../../../interfaces/dashboards';
import { InputOption } from '../../../../../../../../../../../../../../../interfaces/ui';
import {
  DashboardDataReducerContext,
  DashboardQueriesContext,
} from '../../../../../../../../../types';

export const useSplitByOptions = () => {
  const intl = useIntl();

  const [dashboardData] = useTypedContext(DashboardDataReducerContext);
  const getQuestionTitle = useQuestionTitle();
  const { questions } = useTypedContext(DashboardQueriesContext);

  return useMemo((): InputOption[] => {
    let options: InputOption[] = [
      {
        text: intl.formatMessage({
          id: `Enums.DashboardTemplateSplitType.${DashboardTemplateSplitType.Doctor}`,
        }),
        value: DashboardTemplateSplitType.Doctor,
      },
      {
        text: intl.formatMessage({
          id: `Enums.DashboardTemplateSplitType.${DashboardTemplateSplitType.Grasp}`,
        }),
        value: DashboardTemplateSplitType.Doctor,
      },
      {
        text: intl.formatMessage({
          id: `Enums.DashboardTemplateSplitType.${DashboardTemplateSplitType.Organization}`,
        }),
        value: DashboardTemplateSplitType.Organization,
      },
    ];

    const filters = dashboardData.template.filters;

    const questionFilters = filters.filter(
      (p) => p.type === DashboardFilterType.ClosedList
    ) as IClosedListDashboardFilter[];

    questionFilters.forEach((filter) => {
      const questionId = filter.id;
      const question = questions.find((p) => p.resource_id === questionId);

      if (!question) return;

      const questionTitle = getQuestionTitle(question);
      options.push({ text: questionTitle, value: questionId });
    });

    return options;
  }, [dashboardData.template.filters, getQuestionTitle, intl, questions]);
};
