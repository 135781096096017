import classNames from 'classnames';
import { formatDate } from 'helpers/dates';
import { CaseStatus } from 'interfaces/cases';
import {
  DoctorPersonalityInTimeline,
  IPersonality,
} from 'interfaces/personalities';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ListGroupItem, ListGroupItemHeading } from 'reactstrap';
import MedicalPersonnelInfo from './MedicalPersonnelInfo';

type MedicalRecordProps = {
  title: string;
  description: string;
  status?: CaseStatus | null;
  type?: string | null;
  lastUpdate?: string | null;
  number?: number;
  additionalContent?: React.ReactNode;
  members: IPersonality[] | DoctorPersonalityInTimeline[];
  id: string;
  onClick?: () => void;
};

const MedicalRecord = ({
  title,
  status,
  type,
  lastUpdate,
  number,
  description,
  additionalContent,
  id,
  members,
  onClick,
}: MedicalRecordProps) => {
  return (
    <ListGroupItem
      onClick={onClick}
      className={classNames('PersonalityCaseListItem', {
        'cursor-pointer': !!onClick,
      })}
    >
      <div className="d-flex flex-wrap">
        <div className="d-flex flex-wrap PersonalityCaseListItem__case-data-wrapper">
          <ListGroupItemHeading className="PersonalityCaseListItem__header w-100">
            {title}
          </ListGroupItemHeading>
          <div className="d-flex align-items-center me-4">
            <label className="PersonalityCaseListItem__label">
              <FormattedMessage id="CaseCard.status" />
            </label>
            {status ? (
              <>
                <div
                  className={`PersonalityCaseListItem__status-indicator PersonalityCaseListItem__status-indicator--${status}`}
                />
                {status}
              </>
            ) : (
              '-'
            )}
          </div>
          <div className="d-flex align-items-center  me-4">
            <label className="PersonalityCaseListItem__label">Type</label>
            {type || '-'}
          </div>
          <div className="d-flex align-items-center  me-4">
            <label className="PersonalityCaseListItem__label">
              <FormattedMessage id="General.lastUpdate" />
            </label>
            {lastUpdate ? formatDate(lastUpdate) : '-'}
          </div>
          {number && (
            <div className="d-flex align-items-center  me-4">
              <label className="PersonalityCaseListItem__label">
                <FormattedMessage id="CaseCard.caseNumber" />
              </label>
              {number}
            </div>
          )}
          <div className="w-100 mt-2">
            <label className="PersonalityCaseListItem__label pb-1">
              <FormattedMessage id="General.description" />
            </label>
            <p className="overflow-hidden me-4">{description || '-'}</p>
          </div>
        </div>
        <div className="d-flex justify-content-between PersonalityCaseListItem__members-wrapper  align-items-center">
          <div>
            <p className="PersonalityCaseListItem__members-wrapper__title">
              <FormattedMessage id="Patient.assignedMedicalPersonnel" />
            </p>
            <MedicalPersonnelInfo members={members} groupKey={id} />
          </div>
          {additionalContent}
        </div>
      </div>
    </ListGroupItem>
  );
};

export default MedicalRecord;
