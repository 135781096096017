import DeleteIcon from '@mui/icons-material/Delete';
import { Box, IconButton } from '@mui/material';
import Button from '@mui/material/Button';
import { alpha } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { FormikProps } from 'formik';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormValues } from '../../types';
import { useHandleAddEmptyNotification } from './hooks/useHandleAddEmptyNotification';
import { useHandleRemoveNotification } from './hooks/useHandleRemoveNotification';

type RecurringNotificationFormProps = {
  form: FormikProps<FormValues>;
};

const RecurringNotificationForm = ({
  form,
}: RecurringNotificationFormProps) => {
  const intl = useIntl();

  const handleAddEmptyNotification = useHandleAddEmptyNotification(form);
  const handleRemoveNotification = useHandleRemoveNotification(form);

  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex w-100 align-items-center gap-1">
        <TextField
          fullWidth
          variant="outlined"
          type="number"
          name="schedule_templates[0].start_day_offset"
          placeholder={intl.formatMessage({
            id: 'General.StartDayOffset',
          })}
          label={intl.formatMessage({
            id: 'General.StartDayOffset',
          })}
          value={form.values.schedule_templates[0].start_day_offset}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ min: 1 }}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
        />
        <TextField
          fullWidth
          variant="outlined"
          type="number"
          name="schedule_templates[0].end_day_offset"
          placeholder={intl.formatMessage({
            id: 'General.EndDayOffset',
          })}
          label={intl.formatMessage({
            id: 'General.EndDayOffset',
          })}
          InputLabelProps={{
            shrink: true,
          }}
          value={form.values.schedule_templates[0].end_day_offset}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          inputProps={{ min: 1 }}
        />
        <TextField
          fullWidth
          variant="outlined"
          type="number"
          name="schedule_templates[0].recurring_interval"
          placeholder={intl.formatMessage({
            id: 'General.RecurringInterval',
          })}
          label={intl.formatMessage({
            id: 'General.RecurringInterval',
          })}
          value={form.values.schedule_templates[0].recurring_interval}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          inputProps={{ min: 1 }}
        />
      </div>
      <Box
        className="d-flex flex-column p-3 gap-3 rounded-2"
        sx={(theme) => ({ background: alpha(theme.palette.primary.main, 0.1) })}
      >
        {form.values.schedule_templates[0].parts_of_a_day.map(
          (partOfADay, index) => (
            <div className="d-flex w-100 align-items-center gap-2">
              <TextField
                fullWidth
                variant="outlined"
                type="time"
                name={`schedule_templates[0].parts_of_a_day[${index}].available_from_hour`}
                placeholder={intl.formatMessage({
                  id: 'General.AvailableFrom',
                })}
                label={intl.formatMessage({
                  id: 'General.AvailableFrom',
                })}
                value={partOfADay.available_from_hour}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="time"
                name={`schedule_templates[0].parts_of_a_day[${index}].notification_hour`}
                placeholder={intl.formatMessage({
                  id: 'Notifications.NotificationHour',
                })}
                label={intl.formatMessage({
                  id: 'Notifications.NotificationHour',
                })}
                value={partOfADay.notification_hour}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
              />
              <TextField
                fullWidth
                variant="outlined"
                type="time"
                name={`schedule_templates[0].parts_of_a_day[${index}].available_to_hour`}
                placeholder={intl.formatMessage({
                  id: 'General.AvailableTo',
                })}
                label={intl.formatMessage({
                  id: 'General.AvailableTo',
                })}
                value={partOfADay.available_to_hour}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
              />
              <IconButton
                onClick={handleRemoveNotification(index)}
                color="primary"
              >
                <DeleteIcon />
              </IconButton>
            </div>
          )
        )}
        <Button
          onClick={handleAddEmptyNotification}
          color="primary"
          variant="outlined"
        >
          <FormattedMessage id="Notifications.AddNotification" />
        </Button>
      </Box>
    </div>
  );
};

export default RecurringNotificationForm;
