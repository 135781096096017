import React, { useState } from 'react';
import { Button } from 'reactstrap';

//components
import GraspsList from './components/GraspList/GraspList';
import InfiniteListLayout from 'components/InfiniteList/InfiniteListLayout';
import { GraspModalActions } from 'interfaces/grasps';
import useRequiredParams from 'hooks/useRequiredParams';

//images
import graspIcon from 'assets/images/grasp_svg_icon.svg';
import { useBrowserState } from 'store/reducerHooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/pro-light-svg-icons';

const ActionGraspModal = React.lazy(
  () => import('modules/Grasps/components/ActionGraspModal')
);

const Grasps = () => {
  const [isShareGraspModalOpen, setShareGraspModalOpen] = useState(false);
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const isBrowser = useBrowserState();

  const toggleShareGraspModal = () => {
    if (isBrowser) setShareGraspModalOpen((isOpen) => !isOpen);
    else {
      window.dispatchEvent(
        new CustomEvent('grasp-find-request', {
          detail: {
            organization_id: organizationId,
          },
        })
      );
    }
  };

  return (
    <>
      {isBrowser && (
        <React.Suspense fallback={null}>
          <ActionGraspModal
            isOpen={isShareGraspModalOpen}
            toggle={toggleShareGraspModal}
            organizationId={organizationId}
            action={GraspModalActions.FIND}
          />
        </React.Suspense>
      )}
      <InfiniteListLayout
        titleTranslationId="UserDashboard.grasps"
        additionalRightContent={
          <div className="mb-3">
            <Button
              color="primary"
              outline={true}
              onClick={toggleShareGraspModal}
            >
              <FontAwesomeIcon icon={faLink} />
            </Button>
          </div>
        }
        labelTranslationId="OrganizationGrasps.searchText"
        inputIconSrc={graspIcon}
      >
        <GraspsList />
      </InfiniteListLayout>
    </>
  );
};

export default Grasps;
