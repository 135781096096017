import React, { useContext, useMemo, useState } from 'react';
import CustomTable from '../../../../components/CustomTable';
import { useInitQuery } from '../../../../hooks/queries/useInitQuery';
import { useQueriesLoading } from '../../../../hooks/queries/useQueriesLoading';
import useOpen from '../../../../hooks/useOpen';
import { useProjectsCommonData } from '../../../Projects/hooks/useProjectsCommonData';
import { useTreatmentPlansCommonData } from '../../hooks/useTreatmentPlansCommonData';
import { AddPlanModalContext } from '../../index';
import TreatmentPlanListItem from './components/TreatmentPlanListItem';
import AddTreatmentPlanModal from './modals/AddTreatmentPlanModal';
import CloneTreatmentPlanModal from './modals/CloneTreatmentPlanModal';

const headerItems = [
  'TreatmentPlans.TreatmentPlansList.name',
  'TreatmentPlans.TreatmentPlansList.projectsAssigned',
  'TreatmentPlans.TreatmentPlansList.treatmentPhases',
  'TreatmentPlans.TreatmentPlansList.actions',
];

const TreatmentPlansList = () => {
  const { isOpen: clonePlanIsOpen, toggle: clonePlanToggle } = useOpen();

  const {
    isOpen: isAddTreatmentPlanModalOpen,
    toggle: toggleAddTreatmentPlanModal,
  } = useContext(AddPlanModalContext);

  const [clonedTreatmentPlan, setClonedTreatmentPlan] = useState<string | null>(
    null
  );

  const { treatmentPlans, caseCategories } = useTreatmentPlansCommonData();
  const { projects } = useProjectsCommonData();

  useInitQuery(treatmentPlans);
  useInitQuery(caseCategories);
  useInitQuery(projects);

  const isLoading = useQueriesLoading([
    treatmentPlans,
    caseCategories,
    projects,
  ]);

  const bodyRows = useMemo(() => {
    return (treatmentPlans.data ?? []).map((plan) => {
      const handleClone = () => {
        setClonedTreatmentPlan(plan.id);
        clonePlanToggle();
      };

      return (
        <TreatmentPlanListItem
          key={plan.id}
          plan={plan}
          handleClone={handleClone}
        />
      );
    });
  }, [clonePlanToggle, treatmentPlans.data]);

  const handleToggle = () => {
    setClonedTreatmentPlan(null);
    clonePlanToggle();
  };

  return (
    <div className="d-flex flex-column gap-2">
      {clonePlanIsOpen && clonedTreatmentPlan && (
        <CloneTreatmentPlanModal
          isOpen={clonePlanIsOpen}
          toggle={handleToggle}
          planId={clonedTreatmentPlan}
        />
      )}
      {isAddTreatmentPlanModalOpen && (
        <AddTreatmentPlanModal
          isOpen={isAddTreatmentPlanModalOpen}
          toggle={toggleAddTreatmentPlanModal}
        />
      )}
      {headerItems && bodyRows && (
        <div className="mt-5">
          <CustomTable
            clickableRows={false}
            isLoading={isLoading}
            isResponsive={true}
            stretched={false}
            headerItems={headerItems}
            bodyRows={bodyRows}
          />
        </div>
      )}
    </div>
  );
};

export default TreatmentPlansList;
