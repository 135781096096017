import React from 'react';

import useTimelineModification from 'api/mutations/timelines/useTimelineModification';
import { ISharing } from 'interfaces/personalities';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from 'modules/Notifications';
import { Modal, ModalBody, ModalHeader, Table } from 'reactstrap';
import TimelinesRows from './TimelinesRows';
import { FormattedMessage } from 'react-intl';

type ShareRequestModalProps = {
  requests: ISharing[] | undefined;
  requestId: string | null;
  timelines: any;
  refetchFunction: any;
  userId: string;
  onClose: () => void;
};

const EditShareRequestModal = ({
  requestId,
  timelines,
  userId,
  refetchFunction,
  onClose,
  requests,
}: ShareRequestModalProps) => {
  const request = requestId
    ? requests?.find((singleRequest) => singleRequest.id === requestId)
    : null;

  const { mutate: timelineModificationMutation, isLoading } =
    useTimelineModification(
      userId,
      () => {
        displaySuccessNotification(
          'TimelineDetails.timelineShareModificationSuccess'
        );
        refetchFunction();
      },
      () => {
        displayErrorNotification(
          'TimelineDetails.timelineShareModificationError'
        );
      }
    );

  const timelinesChecked =
    request?.timelines?.map((timeline) => timeline.id) ?? [];

  const onTimelineChange = (timelineId: string) => {
    if (request) {
      const requestedIncludesTimelineId = timelinesChecked.includes(timelineId);
      timelineModificationMutation({
        requestId: request.id,
        shouldAccept: !requestedIncludesTimelineId,
        timelineId,
      });
    }
  };

  const isOpen = !!request;

  return (
    <Modal isOpen={isOpen} toggle={onClose}>
      <ModalHeader>
        <FormattedMessage id="SharingRequests.editAcceptedSharingRequested" />
      </ModalHeader>
      <ModalBody className="p-0">
        <Table>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="SharingRequests.timelineName" />
              </th>
              <th>
                <FormattedMessage id="SharingRequests.isShared" />
              </th>
            </tr>
          </thead>
          <tbody>
            <TimelinesRows
              disabled={isLoading}
              timelines={timelines}
              timelinesChecked={timelinesChecked}
              onTimelineChange={onTimelineChange}
            />
          </tbody>
        </Table>
      </ModalBody>
    </Modal>
  );
};

export default EditShareRequestModal;
