import useTreatmentPhases from '../../../../../../../api/queries/conversations/useTreatmentPhases';
import { useEffect } from 'react';
import useRequiredParams from '../../../../../../../hooks/useRequiredParams';
import { useQueryRefetch } from '../../../../../../../hooks/queries/useQueryRefetch';

export const useGroupScheduleData = (treatmentPlanId: string) => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const treatmentPhasesQuery = useTreatmentPhases({
    params: {
      organizationId,
      treatmentPlanId,
    },
    options: { enabled: false, staleTime: Infinity },
  });

  const queryRefetch = useQueryRefetch();

  useEffect(() => {
    if (treatmentPlanId === '' || !treatmentPlanId) return;
    queryRefetch(treatmentPhasesQuery).then((_) => {});
  }, [treatmentPhasesQuery, treatmentPlanId, queryRefetch]);

  return {
    treatmentPhases: treatmentPhasesQuery,
  };
};
