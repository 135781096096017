import { useMemo } from 'react';
import { useTypedContext } from '../../../../../../../../../hooks/useTypedContext';
import { OperationalDataContext } from '../../../providers/OperationalDataProvider';
import { useMsDiffs } from '../../../components/ProcedureEventsTimeline/hooks/useMsDiffs';
import { FormikProps } from 'formik';
import { AwareCaseDetailsFormValues } from '../../../forms/AwareCaseDetailsForm/types';

export const useProceduresDiffs = (
  form: FormikProps<AwareCaseDetailsFormValues>
) => {
  const { awareSessionDetails } = useTypedContext(OperationalDataContext);

  const { getMsDiff } = useMsDiffs(
    awareSessionDetails.start_date,
    awareSessionDetails.end_date
  );

  return useMemo(() => {
    const diffs = form.values.procedures.map((procedure, index) => ({
      index,
      startDiff: getMsDiff(awareSessionDetails.start_date, procedure.timeStart),
      endDiff: getMsDiff(awareSessionDetails.start_date, procedure.timeEnd),
    }));

    diffs.sort((a, b) => a.startDiff - b.startDiff);
    return diffs;
  }, [awareSessionDetails.start_date, form.values.procedures, getMsDiff]);
};
