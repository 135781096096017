import { useCallback, useMemo, useState } from 'react';
import { TemplateElement, TemplateItemType } from '../../../interfaces/change';
import { TrendsChartElement, TrendsChartVisualization } from '../types';

export type TrendsChartVisualizationHookValues = {
  visualization: TrendsChartVisualization;
  selected: TrendsChartElement;
  handleSelect: (type: TemplateItemType) => void;
};

export const useTrendsChartVisualization = (
  elements: TemplateElement[],
  onSelect?: (type: TemplateItemType) => void
): TrendsChartVisualizationHookValues => {
  const [selectedType, setSelectedType] = useState<TemplateItemType>(
    elements.at(0)?.type ?? TemplateItemType.AllObservations
  );

  const visualization = useMemo((): TrendsChartVisualization => {
    let _visualization: TrendsChartVisualization = {
      elements: [],
    };

    const nonQuestionElements = elements.filter(
      (p) => p.type !== TemplateItemType.Questions
    );

    nonQuestionElements.forEach((element) => {
      _visualization.elements.push({
        type: element.type,
        chart_type: element.chart_type,
        generate_points_nearby: element.generate_points_nearby,
      });
    });

    const questionElements = elements.filter(
      (p) => p.type === TemplateItemType.Questions
    );

    let questionIds = [...new Set(questionElements.map((p) => p.question_id))];
    questionIds = questionIds.filter((p) => p !== null);

    if (questionIds.length > 0 && questionElements.length > 0) {
      _visualization.elements.push({
        type: TemplateItemType.Questions,
        generate_points_nearby: questionElements.map((element) => ({
          question_id: element.question_id ?? '',
          enabled: element.generate_points_nearby,
        })),
        chart_type: questionElements[0].chart_type,
        questionIds: questionIds as string[],
      });
    }

    return _visualization;
  }, [elements]);

  const selected = useMemo(() => {
    return (
      visualization.elements.find((p) => p.type === selectedType) ??
      visualization.elements[0]
    );
  }, [selectedType, visualization.elements]);

  const handleSelect = useCallback(
    (type: TemplateItemType) => {
      if (!visualization.elements.map((p) => p.type).includes(type)) return;
      setSelectedType(type);
      onSelect?.(type);
    },
    [onSelect, visualization.elements]
  );

  return { visualization, selected, handleSelect };
};
