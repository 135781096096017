import { GridRowModesModel } from '@mui/x-data-grid';
import { useCallback, useState } from 'react';

export const useRowModesModel = () => {
  const [rowModesModel, setRowModesModel] = useState<GridRowModesModel>({});

  const handleRowModesModelChange = useCallback(
    (newRowModesModel: GridRowModesModel) => {
      setRowModesModel(newRowModesModel);
    },
    []
  );

  return { rowModesModel, setRowModesModel, handleRowModesModelChange };
};
