import React from 'react';
import { FormattedMessage } from 'react-intl';

// ACTION STATES:
import { resendEmailStateActions } from './reducer';

// COMPONENST:
import Sending from './components/Sending';
import ToastBodyWithCallback from 'components/ToastBodyWithCallback';

// API:
import { resendEmail } from 'api/membership';

// NOTIFICATIONS:
import { toast } from 'react-toastify';
import { displayNotification } from 'modules/Notifications';

export const resendEmailAction = (email, closeToast) => async (dispatch) => {
  const toastId = displayNotification(
    <Sending id="ResendEmail.notifications.pending" />,
    {
      type: toast.TYPE.INFO,
      autoClose: false,
      closeOnClick: false,
    }
  );

  try {
    dispatch(resendEmailStateActions.pendingAction());
    closeToast && closeToast();

    await resendEmail({ email });

    dispatch(resendEmailStateActions.fullfiledAction());
    toast.update(toastId, {
      render: <FormattedMessage id="ResendEmail.notifications.success" />,
      type: toast.TYPE.SUCCESS,
      autoClose: 5000,
    });
  } catch (error) {
    dispatch(resendEmailStateActions.rejectedAction());
    toast.update(toastId, {
      render: (
        <ToastBodyWithCallback
          text="ResendEmail.notifications.errors.text"
          buttonText="ResendEmail.notifications.errors.buttonText"
          handleClick={(closeToast) =>
            dispatch(resendEmailAction(email, closeToast))
          }
        />
      ),
      type: toast.TYPE.ERROR,
      autoClose: false,
      closeOnClick: false,
    });
  }
};
