//helpers
import useFindGrasp from 'api/queries/grasps/useFindGrasp';

//types
import { FindGraspFormType, GraspModalActions } from 'interfaces/grasps';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Modal } from 'reactstrap';

//components
import FindGrasp from './FindGrasp';
import GraspDetails from './GraspDetails';

type ActionGraspModalProps = {
  isOpen: boolean;
  toggle: () => void;
  organizationId: string;
  redirectOnFind?: boolean;
  personalityId?: string;
  graspIdToCompare?: string;
  refetchFallback?: () => void;
  action: GraspModalActions;
};

enum ModalStates {
  FIND = 'find',
  DETAILS = 'details',
}

const ActionGraspModal = ({
  isOpen,
  toggle,
  organizationId,
  redirectOnFind,
  personalityId,
  graspIdToCompare,
  refetchFallback,
  action,
}: ActionGraspModalProps) => {
  const navigate = useNavigate();

  const [modalState, setModalState] = useState(ModalStates.FIND);

  const {
    mutate: findGraspDetails,
    isSuccess: isSuccessFindGrasp,
    reset: resetFindGrasp,
    data: graspFound,
    isLoading,
    isError,
  } = useFindGrasp(graspIdToCompare as string);

  const handleFind = ({ link_code }: FindGraspFormType) => {
    findGraspDetails(link_code);
  };

  const toggleHandler = () => {
    toggle();
    resetFindGrasp();
    setModalState(ModalStates.FIND);
  };

  useEffect(() => {
    if (isSuccessFindGrasp && graspFound) {
      switch (action) {
        case GraspModalActions.FIND:
          resetFindGrasp();
          navigate(`/organizations/${organizationId}/grasps/${graspFound.id}`);
          break;
        case GraspModalActions.SHARE:
        case GraspModalActions.UNSHARE:
          setModalState(ModalStates.DETAILS);
          break;
        default:
          break;
      }
    }
  }, [
    action,
    isSuccessFindGrasp,
    navigate,
    resetFindGrasp,
    graspFound,
    organizationId,
    redirectOnFind,
  ]);

  return (
    <Modal isOpen={isOpen} toggle={toggleHandler}>
      {modalState === ModalStates.FIND && (
        <FindGrasp
          onFind={handleFind}
          isLoading={isLoading}
          isError={isError}
          isSuccess={isSuccessFindGrasp}
        />
      )}
      {modalState === ModalStates.DETAILS && personalityId && (
        <GraspDetails
          action={action}
          data={graspFound}
          onClose={toggleHandler}
          organizationId={organizationId}
          personalityId={personalityId}
          refetchCallback={refetchFallback}
        />
      )}
    </Modal>
  );
};

export default ActionGraspModal;
