import useConversationTemplates from '../../../api/queries/conversations/useConversationTemplates';
import useRequiredParams from '../../../hooks/useRequiredParams';

export const useSchedulesCommonData = () => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const conversationTemplatesQuery = useConversationTemplates({
    params: {
      organizationId,
    },
    options: {
      enabled: false,
      staleTime: Infinity,
    },
  });

  return {
    conversationTemplates: conversationTemplatesQuery,
  };
};
