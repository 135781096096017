import React, { useState } from 'react';
import useFeedbackResources from '../../../../api/queries/feedbackResources/useFeedbackResources';
import { Spinner } from 'reactstrap';
import FeedbackResourcesEditor from './components/FeedbackResourcesEditor';
import { ConversationsAndFeedbacksTabProps } from '../../index';
import { IFeedbackResourceItem } from '../../../../interfaces/feedbackResources';
import TabLayout, {
  TabLayoutType,
} from '../../../../components/layouts/TabLayout';
import { useInitQuery } from '../../../../hooks/queries/useInitQuery';
import QueryLoaded from '../../../../components/QueryLoaded';

type FeedbackResourcesTabProps = ConversationsAndFeedbacksTabProps;

export type FeedbackResourcesSelectionInfo = {
  selectedGroupCode: string | null;
  selectedCulture: string | null;
  selectedSequence: number | null;
  variantItems: IFeedbackResourceItem[];
};

const feedbackResourcesInitialSelection: FeedbackResourcesSelectionInfo = {
  selectedGroupCode: null,
  selectedCulture: null,
  selectedSequence: null,
  variantItems: [],
};

const FeedbackResourcesTab = ({
  organizationId,
}: FeedbackResourcesTabProps) => {
  const feedbackResources = useFeedbackResources({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(feedbackResources);

  const [selectionInfo, setSelectionInfo] = useState(
    feedbackResourcesInitialSelection
  );

  return (
    <TabLayout
      type={TabLayoutType.Generic}
      titlePrefix="Conversations.FeedbackResources"
    >
      <div className="FeedbackResources">
        <QueryLoaded
          query={feedbackResources}
          onLoading={
            <div className="d-flex justify-content-center my-5">
              <Spinner color="primary" />
            </div>
          }
        >
          {(data) => (
            <FeedbackResourcesEditor
              organizationId={organizationId}
              feedbackResources={data}
              selectionInfo={selectionInfo}
              setSelectionInfo={setSelectionInfo}
              refetchFeedbackResources={feedbackResources.refetch}
            />
          )}
        </QueryLoaded>
      </div>
    </TabLayout>
  );
};

export default FeedbackResourcesTab;
