import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getTrendsQuestionHistory } from '../../change';
import { UserQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { cloneDeep } from 'lodash';

interface UseUserQuestionAnswerHistoryParams extends UserQueryParams {
  dateFrom: Date | null;
  dateTo: Date | null;
  questionIds: string[];
}

const useUserQuestionAnswerHistory = ({
  params,
  options,
}: IQueryRequest<UseUserQuestionAnswerHistoryParams>) => {
  const { userId, dateFrom, dateTo, questionIds } = params;
  return useQuery({
    ...options,
    queryKey: [
      QueryKeys.getUserQuestionsAnswerHistory,
      userId,
      dateFrom,
      dateTo,
      questionIds,
    ],
    queryFn: async () => {
      const { data } = await getTrendsQuestionHistory(userId, {
        dateFrom: dateFrom,
        dateTo: dateTo,
        questionIds: questionIds,
      });

      let dataQuestions = data.data.questions.map((question) => {
        let questionCopy = cloneDeep(question);

        questionCopy.answer_history = questionCopy.answer_history.map(
          (history) => ({
            ...history,
            date: history.date.replace('Z', ''),
          })
        );

        return questionCopy;
      });

      return {
        ...data.data,
        questions: dataQuestions,
      };
    },
  });
};

export default useUserQuestionAnswerHistory;
