import React from 'react';
import { Alert } from 'reactstrap';

type ErrorAlertProps = {
  title: string;
  description: string;
};

const ErrorAlert = ({ title, description }: ErrorAlertProps) => {
  return (
    <Alert color="danger">
      <span className="fs-4">{title}</span>
      <hr />
      <p className="mb-0">{description}</p>
    </Alert>
  );
};

export default ErrorAlert;
