import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import IconButton from '../../../../../../../../../../components/IconButton';
import Schedule from '../../../../../../../../../../components/Schedule';
import { defaultHours } from '../../../../../../../../../../helpers/utils/dates';
import useOpen from '../../../../../../../../../../hooks/useOpen';
import { ISummarizedTreatmentPhase } from '../../../../../../../../../../interfaces/conversations';
import { useContentIndex } from '../../hooks/useContentIndex';
import AddNotificationModal from '../../modals/AddNotificationModal';
import { useHandleAddSchedule } from './hooks/useHandleAddSchedule';
import { useNotificationSchedules } from './hooks/useNotificationSchedules';

type NotificationSchedulesProps = {
  phase: ISummarizedTreatmentPhase;
};

const NotificationSchedules = ({ phase }: NotificationSchedulesProps) => {
  const addNotificationModal = useOpen();
  const contentIndex = useContentIndex();

  const notificationItems = useNotificationSchedules(
    phase,
    contentIndex,
    addNotificationModal
  );

  const handleAddSchedule = useHandleAddSchedule(
    contentIndex,
    addNotificationModal
  );

  return (
    <>
      {addNotificationModal.isOpen && (
        <AddNotificationModal
          isOpen={addNotificationModal.isOpen}
          toggle={addNotificationModal.toggle}
          phase={phase}
          contentIndex={contentIndex.index}
        />
      )}
      <div className="d-flex flex-column gap-2">
        <div className="d-flex align-items-center justify-content-between">
          <Typography variant="h6">
            <FormattedMessage id="Conversations.NotificationSchedule" />
          </Typography>
          <IconButton
            onClick={handleAddSchedule}
            color="primary"
            variant="outlined"
          >
            <AddIcon />
          </IconButton>
        </div>
        <Schedule hours={defaultHours} items={notificationItems} />
      </div>
    </>
  );
};

export default NotificationSchedules;
