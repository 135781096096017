import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getTreatmentPlans } from '../../conversations';
import { OrganizationQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

const useTreatmentPlans = ({
  params,
  options,
}: IQueryRequest<OrganizationQueryParams>) => {
  return useQuery({
    ...options,
    queryKey: [QueryKeys.getTreatmentPlans, params.organizationId],
    queryFn: async () => {
      const responseData = await getTreatmentPlans(params.organizationId);
      const { data } = responseData;
      return data.data;
    },
  });
};

export default useTreatmentPlans;
