import { useOrganizationPathParams } from '../../../../../hooks/paths/useOrganizationPathParams';
import useOrganizationDetails from '../../../../../api/queries/organizations/useOrganizationDetails';
import useConversationQuestions from '../../../../../api/queries/conversations/useConversationQuestions';
import useOrganizationMembers from '../../../../../api/queries/organizations/useOrganizationMembers';
import useAwareSessionDataMappings from '../../../../../api/queries/awareSessions/useAwareSessionDataMapping';

export const useCommonData = () => {
  const { organizationId } = useOrganizationPathParams();

  const organizationDetails = useOrganizationDetails({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const conversationQuestions = useConversationQuestions({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const organizationMembers = useOrganizationMembers({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const awareSessionDataMapping = useAwareSessionDataMappings({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  return {
    organizationDetails,
    conversationQuestions,
    organizationMembers,
    awareSessionDataMapping,
  };
};
