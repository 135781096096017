import { faEdit, faLock, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import React from 'react';
import { Button } from 'reactstrap';

//helpers
import useOpen from 'hooks/useOpen';

//helpers
import { iconColors } from 'helpers/colors';

//components
import ConfirmModal from './ConfirmModal';

type DetailsHeaderProps = {
  onEditButtonClick?: () => void;
  onDeleteButtonClick?: () => void;
  deleteMessage?: string;
  editDisabled?: boolean;
  noMargin?: boolean;
  editButtons: {
    edit?: boolean;
    delete?: boolean;
    editAndLock?: boolean;
  };
};

const DetailsHeader = ({
  children,
  onEditButtonClick,
  onDeleteButtonClick,
  editDisabled,
  editButtons,
  deleteMessage,
  noMargin = false,
}: React.PropsWithChildren<DetailsHeaderProps>) => {
  const { isOpen, close: closeModal, open: openModal } = useOpen();

  let renderedEditButton: JSX.Element[] = [];

  if (editButtons.delete) {
    renderedEditButton.push(
      <Button
        key="deleteButton"
        id="deleteButton"
        onClick={openModal}
        className={`bg-transparent btn-transparent ms-1 p-2`}
      >
        <FontAwesomeIcon
          icon={faTrash}
          color={iconColors.delete}
          style={{
            cursor: 'pointer',
          }}
        />
      </Button>
    );
  }

  if (editButtons.edit && editDisabled) {
    renderedEditButton.push(
      <Button
        key="editButton"
        aria-label="edit"
        onClick={onEditButtonClick}
        className="bg-transparent btn-transparent ms-2"
      >
        <FontAwesomeIcon icon={faEdit} color={iconColors.edit} />
      </Button>
    );
  } else if (editButtons.editAndLock) {
    renderedEditButton.push(
      <Button
        key="editAndLock"
        onClick={onEditButtonClick}
        className="bg-transparent btn-transparent ms-2"
      >
        <FontAwesomeIcon
          icon={editDisabled ? faEdit : faLock}
          color={iconColors.edit}
          style={{
            cursor: 'pointer',
          }}
        />
      </Button>
    );
  }

  return (
    <>
      <div className={`${!noMargin && 'mb-4 mt-4'} pb-2 font-weight-bold`}>
        <h3 style={{ fontSize: '24px' }}>
          {children}
          {renderedEditButton}
        </h3>
      </div>
      {editButtons.delete && onDeleteButtonClick && deleteMessage && (
        <ConfirmModal
          isOpen={isOpen}
          message={deleteMessage}
          toggle={closeModal}
          confirm={onDeleteButtonClick}
        />
      )}
    </>
  );
};

export default DetailsHeader;
