import useTimelineModification from 'api/mutations/timelines/useTimelineModification';
import useSharingTimelineResponses from 'api/queries/timelines/useSharingTimelineResponses';
import useUserSharingRequests from 'api/queries/timelines/useUserSharingRequests';
import ArrowIcon from 'assets/images/arrow_icon.svg';

//icon
import SharingIcon from 'assets/images/timeline_details/sharing.svg';

//helpers
import useOpen from 'hooks/useOpen';
import { StatusEnum } from 'interfaces/enums';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from 'modules/Notifications';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, ModalBody, ModalHeader, Table } from 'reactstrap';

//components
import HeaderDetail from '../../../components/HeaderDetail';

type TimelineSharingRequestsProps = {
  timelineId: string;
  userId: string;
};

const TimelineSharingRequests = ({
  timelineId,
  userId,
}: TimelineSharingRequestsProps) => {
  const intl = useIntl();
  const { isOpen, toggle: toggleModal } = useOpen();

  const { data: sharingResponses, refetch: refetchSharingResponses } =
    useSharingTimelineResponses({
      params: { userId, timelineId },
    });

  const {
    data: requests,
    refetch: refetchRequests,
    isLoading: isRequestLoading,
  } = useUserSharingRequests({ params: { userId } });

  const filteredRequests = requests?.filter(
    (requests) => requests.status !== StatusEnum.REJECTED
  );

  const {
    mutate: modifyTimelineAcceptMutation,
    isLoading: isModificationLoading,
  } = useTimelineModification(
    userId,
    async () => {
      displaySuccessNotification(
        'TimelineDetails.timelineShareModificationSuccess'
      );
      await refetchRequests();
      await refetchSharingResponses();
    },
    () => {
      displayErrorNotification(
        'TimelineDetails.timelineShareModificationError'
      );
    }
  );

  const sharingRequestsRows = filteredRequests?.map(
    ({ organization_name, title, timelines, id: requestId }) => {
      const isTimelineAlreadyAccepted =
        timelines.filter((timeline) => timeline.id === timelineId).length > 0;

      return (
        <tr key={requestId}>
          <td className="text-center">{organization_name}</td>
          <td className="text-center">{title}</td>
          <td className="text-center">
            <input
              readOnly
              type="checkbox"
              checked={isTimelineAlreadyAccepted}
              disabled={isModificationLoading || isRequestLoading}
              onClick={() => {
                modifyTimelineAcceptMutation({
                  timelineId: timelineId,
                  requestId,
                  shouldAccept: !isTimelineAlreadyAccepted,
                });
              }}
            />
          </td>
        </tr>
      );
    }
  );
  const numberOfSharingResponses = sharingResponses?.length ?? 0;
  let description: JSX.Element = (
    <div className="HeaderDetail__description">
      <FormattedMessage id="TimelineDetails.noSharingRequests" />
    </div>
  );
  if (numberOfSharingResponses > 0)
    description = (
      <div
        className="HeaderDetail__description HeaderDetail__description--clickable"
        onClick={toggleModal}
      >
        {`${numberOfSharingResponses} ${intl.formatMessage({
          id: 'TimelineDetails.sharingRequests',
        })}`}
        <img src={ArrowIcon} alt="arrow_icon" className="ms-2" />
      </div>
    );

  return (
    <>
      <HeaderDetail
        isLoading={isRequestLoading}
        iconSrc={SharingIcon}
        header="Sharing requests"
        onDetailsClick={toggleModal}
      >
        <div className="HeaderDetail__description">{description}</div>
      </HeaderDetail>
      <Modal isOpen={isOpen} toggle={toggleModal} size="lg">
        <ModalHeader>
          <FormattedMessage id="TimelineDetails.timelineIsCurrentlySharedWithRequests" />
        </ModalHeader>
        <ModalBody className="p-0">
          <Table className="pb-4">
            <thead>
              <tr>
                <th className="text-center">
                  <FormattedMessage id="TimelineDetails.organization" />
                </th>
                <th className="text-center">
                  <FormattedMessage id="TimelineDetails.title" />
                </th>
                <th className="text-center">
                  <FormattedMessage id="TimelineDetails.timelineSharingStatus" />
                </th>
              </tr>
            </thead>
            <tbody>{sharingRequestsRows}</tbody>
          </Table>
        </ModalBody>
      </Modal>
    </>
  );
};

export default TimelineSharingRequests;
