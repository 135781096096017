import { getOrganizationContextAssessmentFormsAssignments } from 'api/graspManagement';
import QueryKeys from 'api/queries/queryKeys';
import { CaseQueryParams, TimelineQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { useQuery } from 'react-query';

type UseOrganizationContextAssessmentFormsAssignmentsParams = CaseQueryParams &
  TimelineQueryParams;

function useOrganizationContextAssessmentFormsAssignments({
  params,
  options,
}: IQueryRequest<UseOrganizationContextAssessmentFormsAssignmentsParams>) {
  const { organizationId, caseId, timelineId } = params;
  return useQuery({
    ...options,
    queryKey: [
      QueryKeys.getAssessmentFormsAssignments,
      organizationId,
      caseId,
      timelineId,
    ],
    queryFn: async () => {
      const { data: responseData } =
        await getOrganizationContextAssessmentFormsAssignments(
          organizationId,
          caseId,
          timelineId
        );

      return responseData.data;
    },
  });
}

export default useOrganizationContextAssessmentFormsAssignments;
