import React from 'react';
import useFetchAwareSessionParameters from '../../../../../../../../../../api/mutations/awareSessions/useFetchAwareSessionParameters';
import { ScheduleFormHookValues } from '../../hooks/useForm';
import { FormikProps } from 'formik';
import { useOrganizationPathParams } from '../../../../../../../../../../hooks/paths/useOrganizationPathParams';
import useUrlSearch from '../../../../../../../../../../hooks/useUrlSearch';
import { IconButton, Tooltip } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import { useIntl } from 'react-intl';
import { cloneDeep } from 'lodash';
import {
  ConversationTemplate,
  QuestionValidConversationTemplateItem,
  ValidConversationQuestionType,
  ValidConversationTemplateItemType,
} from '../../../../../../../../../../interfaces/conversations';

type FetchParametersFieldProps = {
  form: FormikProps<ScheduleFormHookValues>;
  template: ConversationTemplate;
};

const FetchParametersField = ({
  form,
  template,
}: FetchParametersFieldProps) => {
  const { organizationId } = useOrganizationPathParams();
  const intl = useIntl();

  let awareQuestions = template.content.filter(
    (p) => p.type === ValidConversationTemplateItemType.Question
  ) as QuestionValidConversationTemplateItem[];

  awareQuestions = awareQuestions.filter(
    (p) => p.value.type === ValidConversationQuestionType.ClosedList
  );

  const query = useUrlSearch();
  const aggregated = query.get('aggregated');

  const handleSuccess = (data: Record<string, any>) => {
    const valuesCopy = cloneDeep(form.values.questions);

    Object.entries(data).forEach(([key, value]) => {
      const questionIndex = awareQuestions.findIndex(
        (p) => p.resource_id === key
      );

      if (questionIndex === -1) return;
      valuesCopy[questionIndex] = { id: `${key}|${value}`, value: undefined };
    });

    form.setFieldValue('questions', valuesCopy);
  };

  const { mutateAsync } = useFetchAwareSessionParameters({
    params: { organizationId, aggregated: !!aggregated },
    options: { successFb: handleSuccess },
    silent: true,
  });

  const handleFetch = async () => {
    if (!form.values.patient_number) return;
    await mutateAsync(form.values.patient_number);
  };

  return (
    <Tooltip title={intl.formatMessage({ id: 'cases.fetchDataFromHistory' })}>
      <IconButton onClick={handleFetch}>
        <HistoryIcon />
      </IconButton>
    </Tooltip>
  );
};

export default FetchParametersField;
