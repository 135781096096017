import {
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns';
import { DatesHelpers } from '../../../../../../../helpers/dates';
import {
  DashboardDatePeriod,
  DashboardDateSelection,
  DashboardTemplateDateSelection,
  ICustomDateSelection,
  IPeriodDateSelection,
} from '../../../../../../../interfaces/dashboards';
import { StrictDateRange } from '../../../../../../../interfaces/dates';

export namespace DashboardDatesHelpers {
  export const GetDateSelectionBasedOnTemplate = (
    dateSelection: DashboardTemplateDateSelection | undefined
  ): DashboardDateSelection => {
    if (!dateSelection) return GetEmptyDateSelection();

    if (dateSelection.date_period === DashboardDatePeriod.CustomPeriod)
      return GetCustomPeriodDateSelection(dateSelection);

    return GetPeriodDateSelection(dateSelection);
  };

  const GetEmptyDateSelection = (): DashboardDateSelection => {
    return {
      date_period: DashboardDatePeriod.CustomPeriod,
      date_from: DatesHelpers.MinDate,
      date_to: DatesHelpers.MaxDate,
      visible: false,
    };
  };

  const GetCustomPeriodDateSelection = (
    custom: ICustomDateSelection
  ): DashboardDateSelection => {
    return {
      date_period: custom.date_period,
      date_from: new Date(custom.date_from),
      date_to: new Date(custom.date_to),
      visible: custom.visible,
    };
  };

  const GetPeriodDateSelection = (
    period: IPeriodDateSelection
  ): DashboardDateSelection => {
    const datePeriod = period.date_period as unknown as DashboardDatePeriod;
    const dateRange = GetDateRangeFromPeriod(datePeriod);

    return {
      date_period: datePeriod,
      date_from: dateRange.from,
      date_to: dateRange.to,
      visible: period.visible,
    };
  };

  export const GetDateRangeFromPeriod = (
    period: DashboardDatePeriod,
    customNow?: Date
  ): StrictDateRange => {
    switch (period) {
      case DashboardDatePeriod.Today:
        return GetToday(customNow);
      case DashboardDatePeriod.CurrentWeek:
        return GetCurrentWeek(customNow);
      case DashboardDatePeriod.PreviousWeek:
        return GetPreviousWeek(customNow);
      case DashboardDatePeriod.Last7Days:
        return GetLastNDays(7, customNow);
      case DashboardDatePeriod.CurrentMonth:
        return GetCurrentMonth(customNow);
      case DashboardDatePeriod.Last30Days:
        return GetLastNDays(30, customNow);
      case DashboardDatePeriod.CurrentYear:
        return GetCurrentYear(customNow);
      case DashboardDatePeriod.Last12Months:
        return GetLast12Months(customNow);
      case DashboardDatePeriod.CustomPeriod:
        return GetLastNDays(7, customNow);
    }
  };

  export const GetToday = (customNow?: Date): StrictDateRange => {
    let now = customNow ?? new Date();
    let startDate = startOfDay(now);
    let endDate = endOfDay(now);

    return { from: startDate, to: endDate };
  };

  export const GetCurrentWeek = (customNow?: Date): StrictDateRange => {
    const now = customNow ?? new Date();
    const startDate = startOfWeek(now, { weekStartsOn: 1 });
    const endDate = endOfWeek(now, { weekStartsOn: 1 });

    return { from: startDate, to: endDate };
  };

  export const GetPreviousWeek = (customNow?: Date): StrictDateRange => {
    const now = customNow ?? new Date();
    const startDate = startOfWeek(subWeeks(now, 1), { weekStartsOn: 1 });
    const endDate = endOfWeek(subWeeks(now, 1), { weekStartsOn: 1 });

    return { from: startDate, to: endDate };
  };

  export const GetLastNDays = (
    days: number,
    customNow?: Date
  ): StrictDateRange => {
    const now = customNow ?? new Date();
    const endDate = endOfDay(now);
    const startDate = startOfDay(subDays(endDate, days - 1));

    return { from: startDate, to: endDate };
  };

  export const GetLast12Months = (customNow?: Date): StrictDateRange => {
    const now = customNow ?? new Date();
    const endDate = endOfDay(now);
    const startDate = startOfDay(subMonths(endDate, 12));

    return { from: startDate, to: endDate };
  };

  export const GetCurrentMonth = (customNow?: Date): StrictDateRange => {
    let now = customNow ?? new Date();

    const startDate = startOfMonth(now);
    const endDate = endOfMonth(now);

    return { from: startDate, to: endDate };
  };

  export const GetCurrentYear = (customNow?: Date): StrictDateRange => {
    let now = customNow ?? new Date();

    const startDate = startOfYear(now);
    const endDate = endOfYear(now);

    return { from: startDate, to: endDate };
  };
}
