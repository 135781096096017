import useGraspShareWithPersonality from 'api/mutations/grasps/useGraspShareWithPersonality';
import useGraspUnshareWithPersonality from 'api/mutations/grasps/useGraspUnshareWithPersonality';
import useOrganizationUserActiveGrasps from 'api/queries/grasps/useOrganizationUserActiveGrasps';
import GraspLogo from 'components/GraspLogo';
import LoadingButton from 'components/LoadingButton';
import { GraspModalActions, IGraspFound } from 'interfaces/grasps';
import { Size } from 'interfaces/ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

type GraspDetailsProps = {
  data: IGraspFound | undefined;
  personalityId: string;
  organizationId: string;
  onClose: () => void;
  refetchCallback?: () => void;
  action: GraspModalActions;
};

const GraspDetails: React.FC<GraspDetailsProps> = ({
  data,
  personalityId,
  organizationId,
  onClose,
  refetchCallback,
  action,
}) => {
  const { refetch } = useOrganizationUserActiveGrasps({
    params: { organizationId, personalityId },
    options: { enabled: false },
  });

  const successFb = async () => {
    onClose();
    await refetch();
    refetchCallback?.();
  };

  const {
    mutate: shareGrasp,
    isLoading: shareIsLoading,
    isSuccess: shareIsSuccess,
  } = useGraspShareWithPersonality(successFb);

  const {
    mutate: unlinkGraspMutation,
    isLoading: unlinkIsLoading,
    isSuccess: unlinkIsSuccess,
  } = useGraspUnshareWithPersonality(successFb);

  const onShare = () => {
    if (data?.physical_grasp_uuid) {
      shareGrasp({
        grasp_uuid: data?.physical_grasp_uuid,
        organizationId,
        personalityId,
      });
    }
  };

  const onUnlink = () => {
    if (data)
      unlinkGraspMutation({
        graspId: data.id,
        personalityId,
        organizationId,
      });
  };

  return (
    <>
      <ModalHeader>
        <FormattedMessage id="Grasps.graspDetails" />
      </ModalHeader>
      <ModalBody>
        <div className="ms-3">
          <GraspLogo color={data?.hex_color} size={Size.MEDIUM} />
          <span className="ms-3 sharedGraspsPatient__tableName">
            {data?.name}
          </span>
        </div>
      </ModalBody>
      <ModalFooter>
        {action === GraspModalActions.SHARE && (
          <LoadingButton
            disabled={unlinkIsLoading}
            isLoading={shareIsLoading}
            isSuccess={shareIsSuccess}
            onClick={onShare}
          >
            <FormattedMessage id="Grasps.shareGrasp" />
          </LoadingButton>
        )}
        {action === GraspModalActions.UNSHARE && (
          <LoadingButton
            disabled={shareIsLoading}
            isLoading={unlinkIsLoading}
            isSuccess={unlinkIsSuccess}
            onClick={onUnlink}
          >
            <FormattedMessage id="Grasps.unlinkGrasp" />
          </LoadingButton>
        )}
      </ModalFooter>
    </>
  );
};

export default GraspDetails;
