import { getDifferenceOfTimestamps } from 'helpers/dates';

export const MAXIMUM_DIFFERENCE_TO_DISPLAY_HOURS = 7;

function useXAxisFormatting(xAxis: string[]) {
  const datesFromXAxis = xAxis.map((val) => new Date(val).getTime());

  const differenceInDays = getDifferenceOfTimestamps(datesFromXAxis);

  return (value: string | number) => {
    if (typeof value === 'number') return '';

    if (value === 'auto') return '';

    let yearRemoved = value.replace(/.\d{4},/, '');
    let leadingZeroForDay =
      yearRemoved.indexOf('.') === 1 ? '0' + yearRemoved : yearRemoved;

    if (differenceInDays > MAXIMUM_DIFFERENCE_TO_DISPLAY_HOURS) {
      return ` ${leadingZeroForDay.replace(/ \d{2}:\d{2}/, '')} `;
    }

    return ` ${leadingZeroForDay} `;
  };
}

export default useXAxisFormatting;
