import { useMemo } from 'react';
import { useTypedContext } from '../../../../../../../../../../../hooks/useTypedContext';
import { DashboardDataReducerContext } from '../../../../../types';

export const useInvalidLayout = () => {
  const [dashboardData] = useTypedContext(DashboardDataReducerContext);

  return useMemo(() => {
    if ((dashboardData.template.layout?.row_heights?.length ?? 0) === 0)
      return true;

    let invalidLayout: boolean = false;

    for (const visualization of dashboardData.template.visualizations) {
      if (visualization.positions.length === 0) {
        invalidLayout = true;
        break;
      }

      const startingPositions = visualization.positions.map(
        (position) => position.start
      );

      const endingPositions = visualization.positions.map(
        (position) => position.end
      );

      if (
        startingPositions.some((arr) => arr.length !== 2) ||
        endingPositions.some((arr) => arr.length !== 2)
      ) {
        invalidLayout = true;
        break;
      }
    }

    return invalidLayout;
  }, [
    dashboardData.template.layout?.row_heights?.length,
    dashboardData.template.visualizations,
  ]);
};
