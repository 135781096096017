import React from 'react';
import { Col, Row } from 'reactstrap';

type FormGroupDescriptionProps = {
  borderTop?: boolean;
  header: string;
  description: string;
  descriptionLargeSize?: number;
  inputLargeSize?: number;
};

const FormGroupDescription = ({
  children,
  header,
  description,
  borderTop = false,
  descriptionLargeSize = 4,
  inputLargeSize = 5,
}: React.PropsWithChildren<FormGroupDescriptionProps>) => {
  return (
    <>
      {borderTop && <div className="border-top mb-4 mt-2" />}
      <Row className="w-100">
        <Col
          sm={6}
          md={6}
          lg={descriptionLargeSize}
          className="d-flex flex-column justify-content-between"
        >
          <div style={{ width: '65%' }}>
            <p style={{ fontSize: '20px', fontWeight: 500 }}>{header}</p>
            <p className="createPatientForm__personalDescriptionText">
              {description}
            </p>
          </div>
        </Col>
        <Col
          sm={6}
          md={6}
          lg={inputLargeSize}
          className="d-flex flex-column justify-content-between"
        >
          {children}
        </Col>
      </Row>
    </>
  );
};

export default FormGroupDescription;
