import { useSelector } from 'react-redux';
import { RootState } from 'store';

//global
export const useBrowserState = () =>
  useSelector((state: RootState) => state.ui.isBrowser);

//timelineDetails
export const useTimelineDetailsSelectedDate = () =>
  useSelector((state: RootState) => state.timelineDetails.selectedDate);

export const useTimelineDetailsGranularity = () =>
  useSelector((state: RootState) => state.timelineDetails.granularity);

export const useTimelineDetailsDateRangeObject = () =>
  useSelector((state: RootState) => state.timelineDetails.dateRangeObject);

export const useTimelineDetailsSelectedTab = () =>
  useSelector((state: RootState) => state.timelineDetails.tab);

export const useTimelineDetailsTimePeriod = () => {
  return useSelector((state: RootState) => state.timelineDetails.timePeriod);
};

export const useTimelineDetailsTimeRange = () =>
  useSelector((state: RootState) => state.timelineDetails.timeRange);

export const useTimelineDetailsTabsDisabled = () =>
  useSelector((state: RootState) => state.timelineDetails.tabsDisabled);

export const useTimelineDetailsAssessmentFormIdentification = () =>
  useSelector(
    (store: RootState) => store.timelineDetails.assessmentFormIdentification
  );

export const useTimelineDetailsDayByDayFullSize = () =>
  useSelector((state: RootState) => state.timelineDetails.dayByDayFullSize);

//submission
export const useSubmissionListFilters = () =>
  useSelector((state: RootState) => state.submissions.submissionListFilters);

//cases
export const useCaseListFilters = () =>
  useSelector((state: RootState) => state.cases.caseListFilters);

export const useCaseFormsDayDetailsRefetchRequest = () =>
  useSelector((state: RootState) => state.cases.formsDayDetailsRefetchRequest);

export const useCaseFormsDayByDayRefetchRequest = () =>
  useSelector((state: RootState) => state.cases.formsDayByDayRefetchRequest);
