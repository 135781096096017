import { useQuery } from 'react-query';
import QueryKeys from '../../queryKeys';
import { getSingleAssessmentTemplate } from '../../../graspManagement';
import { IQueryRequest } from 'interfaces/request';

type UseAssessmentFormsTemplateParams = {
  formCode: string;
};

const useAssessmentFormsTemplate = ({
  params,
  options,
}: IQueryRequest<UseAssessmentFormsTemplateParams>) => {
  const { formCode } = params;
  return useQuery({
    ...options,
    queryKey: [QueryKeys.getAssessmentFormsTemplate, formCode],
    queryFn: async () => {
      const { data: responseData } = await getSingleAssessmentTemplate(
        formCode
      );
      return responseData.data;
    },
  });
};

export default useAssessmentFormsTemplate;
