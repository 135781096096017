import React from 'react';
import { Formik } from 'formik';
import { useForm } from './hooks/useForm';
import SqueezesTimeline from '../../components/SqueezesTimeline';
import DynamicFields from '../../components/DynamicFields';
import StaticFields from '../../components/StaticFields';
import useOpen from '../../../../../../../../hooks/useOpen';
import Actions from '../../components/Actions';

const AwareCaseDetailsForm = () => {
  const editMode = useOpen();

  const { initialValues, handleSubmit, validationSchema } = useForm(editMode);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {(form) => (
        <div id="aware-case-details-root" className="flex flex-col gap-4">
          <DynamicFields form={form} editMode={editMode} />
          <SqueezesTimeline form={form} editMode={editMode} />
          <StaticFields />
          <Actions editMode={editMode} form={form} />
        </div>
      )}
    </Formik>
  );
};

export default AwareCaseDetailsForm;
