import { useMemo } from 'react';
import { addNewItemOptions } from '../types';

export const useAddItemDisabled = (
  selectedOption: string,
  selectedQuestion: string | null
) => {
  return useMemo(() => {
    if (
      selectedOption !== addNewItemOptions[2] &&
      selectedOption !== addNewItemOptions[3]
    )
      return false;

    return !selectedQuestion;
  }, [selectedOption, selectedQuestion]);
};
