import React from 'react';
import { useCaseGroupsCommonData } from '../../hooks/useCaseGroupsCommonData';
import CaseGroupsListPlaceholder from '../CaseGroupsList/components/CaseGroupsListPlaceholder';
import CaseGroupsList from '../CaseGroupsList';
import CaseGroupsUnassignedCases from '../CaseGroupsUnassignedCases';
import { CaseGroupType } from '../../../../interfaces/caseGroups';
import { useInitQuery } from '../../../../hooks/queries/useInitQuery';

const CaseGroupsContent = () => {
  const { caseGroups, projects } = useCaseGroupsCommonData();

  useInitQuery(caseGroups);
  useInitQuery(projects);

  if (caseGroups.isLoading) return <CaseGroupsListPlaceholder />;

  if (!caseGroups.data) return <></>;

  const permanentCaseGroups = caseGroups.data.filter(
    (p) => p.type === CaseGroupType.Permanent
  );

  return (
    <div className="d-flex flex-column gap-5">
      <CaseGroupsList caseGroups={permanentCaseGroups} />
      <CaseGroupsUnassignedCases />
    </div>
  );
};

export default CaseGroupsContent;
