import AddIcon from '@mui/icons-material/Add';
import { cloneDeep } from 'lodash';
import React, { useMemo } from 'react';
import CustomTable from '../../../../components/CustomTable';
import IconButton from '../../../../components/IconButton';
import TabLayout, {
  TabLayoutType,
} from '../../../../components/layouts/TabLayout';
import { useInitQuery } from '../../../../hooks/queries/useInitQuery';
import useOpen from '../../../../hooks/useOpen';
import { useCommonData } from '../../hooks/useCommonData';
import MaterialModal from '../../modals/MaterialModal';
import EducationMaterialsItem from './components/EducationMaterialsItem';

const headerItems = [
  'Education.EducationMaterialsTab.name',
  'Education.EducationMaterialsTab.description',
  'Education.EducationMaterialsTab.treatmentPlan',
  'Education.EducationMaterialsTab.culturesDefined',
  'Education.EducationMaterialsTab.accessRules',
  'Education.EducationMaterialsTab.assets',
];

type EducationMaterialsTabProps = {
  showTitle?: boolean;
  showSubtitle?: boolean;
};

const EducationMaterialsTab = ({
  showTitle = true,
  showSubtitle = true,
}: EducationMaterialsTabProps) => {
  const { educationMaterials, caseCategories } = useCommonData();

  const { isOpen, toggle } = useOpen();

  useInitQuery(educationMaterials);
  useInitQuery(caseCategories);

  const renderBodyRows = useMemo(() => {
    let materialsCopy = cloneDeep(educationMaterials.data ?? []);
    materialsCopy.sort((a, b) => a.name.localeCompare(b.name));

    return materialsCopy.map((material) => (
      <EducationMaterialsItem key={material.id} material={material} />
    ));
  }, [educationMaterials.data]);

  return (
    <>
      {isOpen && (
        <MaterialModal isOpen={isOpen} toggle={toggle} selectCategory={true} />
      )}
      <TabLayout
        type={TabLayoutType.Generic}
        titlePrefix={showTitle ? 'Education.EducationMaterials' : undefined}
        subHeader={showSubtitle}
        actions={
          <IconButton color="primary" variant="outlined" onClick={toggle}>
            <AddIcon />
          </IconButton>
        }
      >
        <div className="MaterialsTab__content MaterialsTabContent">
          <CustomTable
            headerItems={headerItems}
            bodyRows={renderBodyRows}
            isLoading={educationMaterials.isLoading || caseCategories.isLoading}
          />
        </div>
      </TabLayout>
    </>
  );
};

export default EducationMaterialsTab;
