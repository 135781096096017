import { useRef } from 'react';
import quillEmoji from 'react-quill-emoji';
import ReactQuill, { Quill } from 'react-quill';

export const useConfig = () => {
  const editorRef = useRef<ReactQuill>(null);

  const toolbarOptions = [
    [{ header: [1, 2, 3, 4, false] }],
    ['bold', 'italic', 'underline', 'blockquote'],
    [
      { list: 'ordered' },
      { list: 'bullet' },
      { indent: '-1' },
      { indent: '+1' },
    ],
    ['link', 'image'],
    ['clean', 'emoji'],
  ];

  const modules = {
    toolbar: {
      container: toolbarOptions,
    },
    'emoji-toolbar': true,
    'emoji-textarea': false,
    'emoji-shortname': true,
  };

  Quill.register(
    {
      'formats/emoji': quillEmoji.EmojiBlot,
      'modules/emoji-toolbar': quillEmoji.ToolbarEmoji,
      'modules/emoji-textarea': quillEmoji.TextAreaEmoji,
      'modules/emoji-shortname': quillEmoji.ShortNameEmoji,
    },
    true
  );

  return { ref: editorRef, modules };
};
