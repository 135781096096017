import { useCallback } from 'react';
import {
  ConversationTemplateContext,
  ConversationTemplateTriggerType,
} from '../../../../../../../../../interfaces/conversations';
import { useIntl } from 'react-intl';
import {
  conversationTemplateContextOptions,
  conversationTemplateTriggerTypeOptions,
} from '../../../../../../../../../helpers/utils/translationObject';

export const useTemplateCharacteristicsTranslations = () => {
  const intl = useIntl();

  const getContextTranslation = useCallback(
    (context: ConversationTemplateContext | null) => {
      if (context == null) return '';

      const contextOption = conversationTemplateContextOptions.find(
        (p) => p.value === context
      );

      if (!contextOption) return '';
      return intl.formatMessage({ id: contextOption.translationId });
    },
    [intl]
  );

  const getTriggerTypeTranslation = useCallback(
    (triggerType: ConversationTemplateTriggerType | null) => {
      if (triggerType == null) return '';

      const triggerTypeOption = conversationTemplateTriggerTypeOptions.find(
        (p) => p.value === triggerType
      );

      if (!triggerTypeOption) return '';
      return intl.formatMessage({ id: triggerTypeOption.translationId });
    },
    [intl]
  );

  return {
    getContextTranslation,
    getTriggerTypeTranslation,
  };
};
