import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import SVG from 'react-inlinesvg';
import { FormattedMessage, useIntl } from 'react-intl';
import generateReportModalIcon from 'assets/images/generate_report_modal.svg';
import { Formik } from 'formik';
import LoadingButton from 'components/LoadingButton';
import useUserData from 'api/queries/membership/useUserData';
import { getUserId } from 'helpers/utils/auth';
import {
  ICreateReportExecutionBody,
  ReportParameter,
  ReportParameterType,
  ReportTemplateCode,
  ReportType,
} from 'interfaces/reports';
import FieldInput from 'components/FieldInput';
import FieldSelect from 'components/FieldSelect';
import GenerateObservationDataReportForm from './GenerateObservationDataReportForm';
import GenerateSubjectiveDataForm from './GenerateSubjectiveDataForm';
import GenerateReportModalGenericInput from './GenerateReportModalGenericInput';
import { differenceInCalendarDays } from 'date-fns';
import useCreateReportExecution from 'api/mutations/reports/useCreateReportExecution';
import GenerateMomentarySessionExportForm from './GenerateMomentarySessionExportForm';
import { useCommonParams } from '../../hooks/useCommonParams';
import GenerateParticipantFeedbackForm from './GenerateParticipantFeedbackForm';

type GenerateReportModalProps = {
  isOpen: boolean;
  toggle: () => void;
  refetchReports: () => void;
};

export type GenerateReportModalInitialValues = {
  organizationId: string;
  requested_for?: string;
  selected_report?: ReportType;
  selected_template_code?: ReportTemplateCode;
  parameters: ReportParameter[];
};

const GenerateReportModal = ({
  isOpen,
  toggle,
  refetchReports,
}: GenerateReportModalProps) => {
  const intl = useIntl();

  const { organizationId } = useCommonParams();

  const { data: userData } = useUserData({
    params: { userId: getUserId() },
    options: { enabled: true, refetchOnMount: false },
  });

  const handleSuccessfulReportCreation = () => {
    toggle();
    refetchReports();
  };

  const { mutate } = useCreateReportExecution({
    successFb: handleSuccessfulReportCreation,
  });

  const onSubmit = (values: GenerateReportModalInitialValues) => {
    let completedParams = values.parameters;

    for (const param of completedParams) {
      if (
        param.type === ReportParameterType.TrueFalseOption &&
        typeof param.value !== 'boolean'
      ) {
        param.value = false;
      }

      if (param.type === ReportParameterType.DateTime) {
        const dateString = (param.value as Date).toString();
        param.value = new Date(dateString).toISOString();
      }
    }

    const now = new Date();

    if (values.selected_template_code === undefined) return;

    const createReportExecutionBody: ICreateReportExecutionBody = {
      day: now.getDate(),
      month: now.getMonth() + 1,
      year: now.getFullYear(),
      organization_id: values.organizationId,
      requester_first_name: userData?.personality.first_name ?? '',
      requester_last_name: userData?.personality.last_name ?? '',
      parameters: completedParams,
      template_code: values.selected_template_code,
    };

    mutate(createReportExecutionBody);
  };

  const validate = (values: GenerateReportModalInitialValues) => {
    const errors = {};
    const validatedParams = values.parameters.filter(
      (p) => p.type !== ReportParameterType.TrueFalseOption
    );
    const dateTimeParams = values.parameters.filter(
      (p) => p.type === ReportParameterType.DateTime
    );

    if (values.selected_report === undefined) {
      errors['selected_report'] = intl.formatMessage({
        id: 'Reports.GenerateReportModal.emptySelectedReport',
      });
    }
    for (const parameter of validatedParams) {
      if (
        parameter.value === undefined ||
        (Array.isArray(parameter.value) && parameter.value.length === 0)
      ) {
        errors['parameters'] = intl.formatMessage({
          id: 'Reports.GenerateReportModal.emptyParameter',
        });
      }
    }

    const startDate = dateTimeParams.find((p) => p.name.includes('Start'));
    const endDate = dateTimeParams.find((p) => p.name.includes('End'));

    if (startDate?.value && endDate?.value) {
      const daysDiff = differenceInCalendarDays(
        new Date(endDate?.value as string),
        new Date(startDate?.value as string)
      );

      if (daysDiff < 0) {
        errors['parameters'] = intl.formatMessage({
          id: 'Reports.GenerateReportModal.emptyParameter',
        });
      }
    }

    return errors;
  };

  const [initialParameters, setInitialParameters] = useState<ReportParameter[]>(
    []
  );

  const [initialSelectedReport, setInitialSelectedReport] = useState<
    ReportType | undefined
  >(undefined);

  const [initialSelectedTemplateCode, setInitialSelectedTemplateCode] =
    useState<ReportTemplateCode | undefined>(undefined);

  let initialValues: GenerateReportModalInitialValues = {
    organizationId: organizationId,
    requested_for: `${userData?.personality.first_name} ${userData?.personality.last_name}`,
    selected_report: initialSelectedReport,
    parameters: initialParameters,
    selected_template_code: initialSelectedTemplateCode,
  };

  const fillInitialParameters = (
    parameters: ReportParameter[],
    code: ReportTemplateCode
  ) => {
    setInitialParameters(parameters);
    setInitialSelectedReport(ReportType[code]);
    setInitialSelectedTemplateCode(code);
  };

  const generateReportTypeOptions = Object.entries(ReportType).map(
    ([key, val]) => ({
      text: val,
      value: ReportType[key],
    })
  );

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
      className="GenerateReportModal"
    >
      <ModalHeader className="GenerateReportModal__header" toggle={toggle}>
        <div className="GenerateReportModal__header__title">
          <SVG src={generateReportModalIcon} title="generateReport" />
          <FormattedMessage id="Reports.ReportsList.generateReport" />
        </div>
      </ModalHeader>
      <Formik
        initialValues={initialValues}
        validate={validate}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({ values, submitForm, isValid }) => (
          <>
            <ModalBody className="GenerateReportModal__body">
              <GenerateReportModalGenericInput
                required
                disabled
                id="requested_for"
                name="requested_for"
                labelTranslationId="Reports.GenerateReportModal.requestedFor"
                component={FieldInput}
              />
              <GenerateReportModalGenericInput
                required
                labelTranslationId="Reports.GenerateReportModal.selectedReport"
                options={generateReportTypeOptions}
                id="selected_report"
                name="selected_report"
                placeholderTranslationId="Reports.GenerateReportModal.reportType"
                component={FieldSelect}
              />
              {values.selected_report === ReportType.OBSERVATION_DATA && (
                <GenerateObservationDataReportForm
                  fillInitialParameters={fillInitialParameters}
                />
              )}
              {values.selected_report === ReportType.SUBJECTIVE_DATA && (
                <GenerateSubjectiveDataForm
                  fillInitialParameters={fillInitialParameters}
                />
              )}
              {values.selected_report === ReportType.MOMENTARY_SESSION && (
                <GenerateMomentarySessionExportForm
                  fillInitialParameters={fillInitialParameters}
                />
              )}
              {values.selected_report === ReportType.PARTICIPANT_FEEDBACK && (
                <GenerateParticipantFeedbackForm
                  fillInitialParameters={fillInitialParameters}
                />
              )}
            </ModalBody>
            <ModalFooter
              className="GenerateReportModal__footer"
              toggle={toggle}
            >
              <div>
                <Button outline color="primary" onClick={toggle}>
                  <FormattedMessage id="CaseCard.AssessmentForms.cancel" />
                </Button>
                <LoadingButton
                  color="primary"
                  onClick={submitForm}
                  disabled={!isValid}
                >
                  <FormattedMessage id="Reports.ReportsList.generateReport" />
                </LoadingButton>
              </div>
            </ModalFooter>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default GenerateReportModal;
