import PageHeader from 'components/PageHeader';
import React from 'react';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import CaseDashboardTabs from './components/CaseDashboardTabs';
import { useCommonParams } from './hooks/useCommonParams';
import PendingExerciseSessionsProxy from '../../components/Proxies/PendingExerciseSessionsProxy';

const CaseDashboard = () => {
  const { userId, caseId } = useCommonParams();
  const breadcrumbs = useBreadcrumbs();

  return (
    <PendingExerciseSessionsProxy userId={userId} caseId={caseId}>
      <PageHeader breadcrumbs={breadcrumbs} content={<CaseDashboardTabs />} />
    </PendingExerciseSessionsProxy>
  );
};

export default CaseDashboard;
