import { DashboardTemplateVisualizationPalette } from 'interfaces/dashboards';
import { useMemo } from 'react';

export const useObjectPalette = (
  palette: DashboardTemplateVisualizationPalette[]
) => {
  return useMemo(() => {
    let _palette: Record<string, string | string[] | undefined> = {};

    palette.forEach((item) => {
      _palette[item.name] = item.hex ?? item.hexes;
    });

    return _palette;
  }, [palette]);
};
