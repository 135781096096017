import { useQueryRefetch } from './useQueryRefetch';
import { useEffect } from 'react';
import { UseQueryResult } from 'react-query';

export const useInitQuery = (
  query: UseQueryResult<any>,
  enabled: boolean = true
) => {
  const queryRefetch = useQueryRefetch();

  useEffect(() => {
    if (!enabled) return;
    queryRefetch(query).then((_) => {});
  }, [enabled, query, queryRefetch]);
};

export const useInitQueries = (queries: UseQueryResult<any>[]) => {
  const queryRefetch = useQueryRefetch();

  useEffect(() => {
    for (const query of queries) queryRefetch(query).then((_) => {});
  }, [queries, queryRefetch]);
};
