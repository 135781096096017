import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getProjectSubmission } from '../../submissions';
import { OrganizationQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

interface UseProjectSubmissionParams extends OrganizationQueryParams {
  submissionId: string;
}

const useProjectSubmission = ({
  params,
  options,
}: IQueryRequest<UseProjectSubmissionParams>) => {
  const { organizationId, submissionId } = params;
  return useQuery({
    ...options,
    queryKey: [QueryKeys.getProjectSubmissions, submissionId],
    queryFn: async () => {
      const { data } = await getProjectSubmission(organizationId, submissionId);
      return data.data;
    },
  });
};

export default useProjectSubmission;
