import React, { useContext, useMemo } from 'react';
import { HoursContext, ScheduleItemDefinition } from '../../index';
import ScheduleRowLabel from './components/ScheduleRowLabel';
import { Button } from 'reactstrap';
import SVG from 'react-inlinesvg';

import pencilIcon from 'assets/images/pencil.svg';
import trashIcon from 'assets/images/trash.svg';
import plusIcon from 'assets/images/plus.svg';

import ScheduleBlock from './components/ScheduleBlock';
import { ConversationScheduleType } from '../../../../interfaces/conversationSchedules';

export type ScheduleRowProps = {
  item: ScheduleItemDefinition;
  selectedDateString: string;
};

const ScheduleRow = ({ item, selectedDateString }: ScheduleRowProps) => {
  const hours = useContext(HoursContext);

  const handleEdit = () => {
    if (!item.onEdit) return;
    item.onEdit();
  };

  const handleRemove = () => {
    if (!item.onRemove) return;
    item.onRemove();
  };

  const handleAdd = () => {
    if (!item.onAdd) return;
    item.onAdd();
  };

  const dayRangeItems = useMemo((): JSX.Element[] => {
    if (
      !item.scheduleTemplate ||
      item.scheduleTemplate.schedule_type === ConversationScheduleType.Once ||
      item.scheduleTemplate.schedule_type ===
        ConversationScheduleType.NonTimeBased
    )
      return [];

    return item.scheduleTemplate.parts_of_a_day.map((partOfADay) => {
      const caseScheduleCompletion = partOfADay.is_completed ? 100 : 0;

      return (
        <ScheduleBlock
          key={`${item.templateId}|${item.priority}|${partOfADay.available_from_hour}|${partOfADay.available_to_hour}`}
          partOfADay={partOfADay}
          onEdit={partOfADay.onEdit}
          onResponses={partOfADay.onResponses}
          disabled={item.disabled ?? false}
          completionPercent={
            partOfADay.completion_percent ?? caseScheduleCompletion
          }
        />
      );
    });
  }, [item.disabled, item.priority, item.scheduleTemplate, item.templateId]);

  return (
    <tr
      key={`${selectedDateString}|${item.rowId}|${item.sequence}`}
      className="Schedule__row Schedule__row--active"
    >
      <td className="ScheduleRow__label">
        <ScheduleRowLabel item={item} />
      </td>
      <td className="ScheduleRow__day-range">
        <div className="ScheduleDayRange">
          <div className="ScheduleDayRange__lines">
            {hours.map((hour) => (
              <div key={hour} className="ScheduleDayRange__line"></div>
            ))}
          </div>
          <div className="ScheduleDayRange__items">{dayRangeItems}</div>
        </div>
      </td>
      <td className="ScheduleRow__actions">
        <div className="w-100 d-flex justify-content-end gap-1">
          {item.onEdit && (
            <Button
              disabled={item.disabled}
              onClick={handleEdit}
              color="primary"
              outline={true}
            >
              <SVG src={pencilIcon} title="edit" />
            </Button>
          )}
          {item.onRemove && (
            <Button
              disabled={item.disabled}
              onClick={handleRemove}
              color="secondary"
              outline={true}
            >
              <SVG src={trashIcon} title="remove" />
            </Button>
          )}
          {item.onAdd && (
            <Button
              disabled={item.disabled}
              onClick={handleAdd}
              color="primary"
              outline={true}
            >
              <SVG src={plusIcon} title="add" />
            </Button>
          )}
        </div>
      </td>
    </tr>
  );
};

export default ScheduleRow;
