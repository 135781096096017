import { isEmail } from 'helpers/utils/validators';
import useSendPatientInvite from 'api/mutations/patients/usePatientSendInvite';
import { CaseFormType } from 'interfaces/cases';
import React, { useEffect } from 'react';
import { initialValues } from '../index';
import ParticipantsFormCreateUser from 'modules/Participants/components/CreateParticipant/components/ParticipantsFormCreateUser';
import { FormikProps } from 'formik';

type PatientCreateStepProps<T> = {
  formik: FormikProps<T>;
  setValues: (data: CaseFormType) => void;
  organizationId: string;
  emailValue: string;
};

const PatientCreateStep = <T,>({
  formik,
  setValues,
  organizationId,
  emailValue,
}: PatientCreateStepProps<T>) => {
  useEffect(() => {
    setValues(initialValues);
  }, [setValues]);

  const { mutate: sendInviteMutation, isLoading: isSendInviteLoading } =
    useSendPatientInvite(organizationId);

  const onInviteClick = () => {
    sendInviteMutation(emailValue);
  };

  return (
    <ParticipantsFormCreateUser
      formik={formik}
      displayInvitePatientButton
      isInviteLoading={isSendInviteLoading}
      onInviteClick={onInviteClick}
      inviteDisabled={!emailValue || !isEmail(emailValue)}
    />
  );
};

export default PatientCreateStep;
