import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getCaseGroupsForOrganization } from '../../caseGroups';
import { OrganizationQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

const useCaseGroups = ({
  params,
  options,
}: IQueryRequest<OrganizationQueryParams>) => {
  const { organizationId } = params;

  return useQuery({
    ...options,
    queryKey: [QueryKeys.getCaseGroups, organizationId],
    queryFn: async () => {
      const { data } = await getCaseGroupsForOrganization(organizationId);

      return data.data;
    },
  });
};

export default useCaseGroups;
