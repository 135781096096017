import {
  TrendsChartAxisConfigItem,
  TrendsChartFormatter,
} from 'components/TrendsChart/types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  TrendsChartHorizontalTick,
  TrendsChartVerticalTick,
} from '../components/TrendsChartTicks';

export const useAxisTicks = (
  formatters: TrendsChartFormatter[],
  xAxisConfig?: TrendsChartAxisConfigItem,
  yAxisConfig?: TrendsChartAxisConfigItem
) => {
  const [xAxisItem, setXAxisItem] = useState<string | undefined>(
    xAxisConfig?.initialValue
  );

  const [yAxisItem, setYAxisItem] = useState<string | undefined>(
    yAxisConfig?.initialValue
  );

  useEffect(() => {
    setXAxisItem(xAxisConfig?.initialValue);
  }, [xAxisConfig?.initialValue]);

  useEffect(() => {
    setXAxisItem(yAxisConfig?.initialValue);
  }, [yAxisConfig?.initialValue]);

  const xAxisFormatter = useMemo(
    () => formatters.find((p) => p.source === 'x-axis')?.callback,
    [formatters]
  );

  const yAxisFormatter = useMemo(
    () => formatters.find((p) => p.source === 'y-axis')?.callback,
    [formatters]
  );

  const handleXAxisTick = useCallback(
    (payload: string) => {
      setXAxisItem(payload);
      xAxisConfig?.onTick?.(payload);
    },
    [xAxisConfig]
  );

  const handleYAxisTick = useCallback(
    (payload: string) => {
      setYAxisItem(payload);
      yAxisConfig?.onTick?.(payload);
    },
    [yAxisConfig]
  );

  const xAxisTick = useCallback(
    (props: any) => {
      return (
        <TrendsChartHorizontalTick
          item={xAxisItem ?? xAxisConfig?.initialValue}
          setItem={handleXAxisTick}
          formatter={xAxisFormatter}
          {...props}
        />
      );
    },
    [handleXAxisTick, xAxisConfig?.initialValue, xAxisFormatter, xAxisItem]
  );

  const yAxisTick = useCallback(
    (props: any) => {
      return (
        <TrendsChartVerticalTick
          item={yAxisItem ?? yAxisConfig?.initialValue}
          setItem={handleYAxisTick}
          formatter={yAxisFormatter}
          {...props}
        />
      );
    },
    [handleYAxisTick, yAxisConfig?.initialValue, yAxisFormatter, yAxisItem]
  );

  return { xAxisTick, yAxisTick };
};
