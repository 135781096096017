import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Table } from 'reactstrap';
import { useMemo } from 'react';
import { useInnerWidth } from '../../hooks/useInnerWidth';
import CustomTablePlaceholder from './components/CustomTablePlaceholder';
import { useCookie } from '../../hooks/useCookie';
import { ViewTheme } from '../../interfaces/ui';

export type headerItem = string | JSX.Element | null;

export type CustomTableResponsiveConfig = {
  breakpoint: number;
  hiddenColumns: number[];
};

type CustomTableProps = {
  headerItems: headerItem[];
  bodyRows: JSX.Element[];
  isResponsive?: boolean;
  className?: string;
  clickableRows?: boolean;
  id?: string;
  stretched?: boolean;
  responsiveConfig?: CustomTableResponsiveConfig[];
  isLoading?: boolean;
};

const CustomTable = ({
  bodyRows,
  headerItems,
  isResponsive = false,
  className,
  clickableRows = true,
  id,
  stretched = true,
  responsiveConfig = undefined,
  isLoading = false,
}: CustomTableProps) => {
  const themeCookie = useCookie<ViewTheme>('theme');

  const width = useInnerWidth();

  const filteredHeaderItems = useMemo(() => {
    if (!responsiveConfig) return headerItems;

    let hiddenColumns: number[] = [];

    for (const configItem of responsiveConfig) {
      if (width > configItem.breakpoint) continue;
      hiddenColumns = configItem.hiddenColumns;
    }

    return headerItems.filter((p, idx) => !hiddenColumns.includes(idx));
  }, [headerItems, responsiveConfig, width]);

  const filteredBodyRows = useMemo(() => {
    if (!responsiveConfig) return bodyRows;

    let hiddenColumns: number[] = [];

    for (const configItem of responsiveConfig) {
      if (width > configItem.breakpoint) continue;
      hiddenColumns = configItem.hiddenColumns;
    }

    return bodyRows.map((bodyRow) => ({
      ...bodyRow,
      props: {
        ...bodyRow.props,
        children: bodyRow.props.children.filter(
          (child, idx) => !hiddenColumns.includes(idx)
        ),
      },
    }));
  }, [bodyRows, responsiveConfig, width]);

  const renderedHeaders = filteredHeaderItems.map((headerItem, index) => {
    if (typeof headerItem === 'string') {
      return (
        <th key={headerItem}>
          {headerItem.length ? <FormattedMessage id={headerItem} /> : ''}
        </th>
      );
    } else return <th key={`row-element-${index}`}>{headerItem}</th>;
  });

  return (
    <Table
      id={id}
      borderless
      responsive={isResponsive}
      className={classNames({
        CustomTable: true,
        'CustomTable--stretched': stretched,
        'CustomTable--dark': themeCookie === ViewTheme.Dark,
        className: className !== undefined,
      })}
    >
      <thead>
        <tr>{renderedHeaders}</tr>
      </thead>
      <tbody
        className={classNames({
          'CustomTable__tbody--unclickable':
            !clickableRows || filteredBodyRows.length === 0,
        })}
      >
        {filteredBodyRows.length === 0 || isLoading ? (
          <CustomTablePlaceholder
            columns={headerItems.length}
            isLoading={isLoading}
          />
        ) : (
          filteredBodyRows
        )}
      </tbody>
    </Table>
  );
};

export default CustomTable;
