import { IAwareSessionData } from '../../../../../../../../../../../../../../interfaces/awareSessions';
import { ConversationQuestion } from '../../../../../../../../../../../../../../interfaces/conversations';
import {
  DashboardTemplateSeriesAggregate,
  DashboardTemplateSeriesDataSource,
  DashboardTemplateSeriesSplitBy,
  DashboardTemplateSplitType,
} from '../../../../../../../../../../../../../../interfaces/dashboards';
import { DashboardAggregationHelpers } from '../../../../../../../../helpers/aggregation';
import { DashboardSessionsHelpers } from '../../../../../../../../helpers/sessions';
import { NamedAndSplitByValues, SplitByValues } from '../../types';
import { PieChartsData, ValueField } from './types';

export namespace PieChartHelpers {
  export const ProvideSessions = (
    source: DashboardTemplateSeriesDataSource,
    sessions: IAwareSessionData[],
    notSpecifiedTranslation: string
  ): ValueField<IAwareSessionData>[] => {
    let valueSessions = _wrapWithValueField(sessions);

    valueSessions = valueSessions.map((session) => ({
      ..._valueSessionBasedOnDataSource(
        session,
        source,
        notSpecifiedTranslation
      ),
    }));

    return valueSessions;
  };

  export const SplitSessions = (
    sessions: ValueField<IAwareSessionData>[],
    splitBy: DashboardTemplateSeriesSplitBy,
    questions: ConversationQuestion[],
    notSpecified: string
  ): SplitByValues<ValueField<IAwareSessionData>>[] => {
    switch (splitBy.type) {
      case DashboardTemplateSplitType.Question:
        return DashboardSessionsHelpers.SplitValueSessionsByQuestion(
          sessions,
          splitBy,
          questions,
          notSpecified
        );
      case DashboardTemplateSplitType.Doctor:
        return DashboardSessionsHelpers.SplitValueSessionsByProperty(
          sessions,
          'member_id'
        );
      case DashboardTemplateSplitType.Grasp:
        return DashboardSessionsHelpers.SplitValueSessionsByProperty(
          sessions,
          'grasp_id'
        );
      case DashboardTemplateSplitType.Organization:
        return DashboardSessionsHelpers.SplitValueSessionsByProperty(
          sessions,
          'organization_id'
        );
    }
  };

  export const AggregateSessions = (
    sessions: NamedAndSplitByValues<ValueField<IAwareSessionData>>[],
    operator: DashboardTemplateSeriesAggregate | 'Count'
  ): PieChartsData[] => {
    let data: PieChartsData[] = [];

    sessions.forEach((session) => {
      data.push({
        name: session.name,
        value: DashboardAggregationHelpers.AggregateValue(
          session.items.map((p) => p.value),
          operator
        ),
      });
    });

    return data;
  };

  export const FilterEmptySessions = (
    sessions: PieChartsData[]
  ): PieChartsData[] => {
    return sessions.filter((p) => p.value !== 0);
  };

  const _wrapWithValueField = (
    arr: IAwareSessionData[]
  ): ValueField<IAwareSessionData>[] => {
    return arr.map((item) => ({
      ...item,
      value: 0,
    }));
  };

  const _valueSessionBasedOnDataSource = (
    session: ValueField<IAwareSessionData>,
    source: DashboardTemplateSeriesDataSource,
    notSpecifiedTranslation: string
  ): ValueField<IAwareSessionData> => {
    switch (source) {
      case DashboardTemplateSeriesDataSource.SessionCount:
        return { ...session, value: 1 };
      case DashboardTemplateSeriesDataSource.AlarmsCount:
        return { ...session, value: session.alarm_incidents };
      case DashboardTemplateSeriesDataSource.SqueezesCount:
        return { ...session, value: session.total_squeezes };
      case DashboardTemplateSeriesDataSource.FeedbacksCount:
        return {
          ...session,
          value: DashboardSessionsHelpers.IsSessionConsideredAsFeedback(
            session,
            notSpecifiedTranslation
          )
            ? 1
            : 0,
        };
      case DashboardTemplateSeriesDataSource.SessionDuration:
        return {
          ...session,
          value: DashboardSessionsHelpers.GetSessionDuration(session),
        };
    }
  };
}
