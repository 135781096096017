import { TrendsChartGenericDataQueries } from 'components/TrendsChart/types';
import { ConversationQuestion } from 'interfaces/conversations';
import { IOrganizationObservationMeaning } from 'interfaces/observationMeanings';
import { TrendVisualization } from 'interfaces/trendVisualizations';
import React, { useContext } from 'react';
import TrendsChart from '../../../../../../../../../../../../../../components/TrendsChart';
import { useTrendsChartGenericData } from '../../../../../../../../../../../../../../components/TrendsChart/hooks/useTrendsChartGenericData';
import { useTrendsChartGenericFormatters } from '../../../../../../../../../../../../../../components/TrendsChart/hooks/useTrendsChartGenericFormatters';
import TabItem from '../../../../../../../../../../../../../CaseDashboard/components/CaseDashboardTabs/components/TabItem';
import { ChangeCaseDashboardDateRangeContext } from '../../../../index';
import TrendsPlaceholder from '../TrendsPlaceholder';
import { useChartConfig } from './hooks/useChartConfig';

type TrendsItemProps = {
  visualization: TrendVisualization;
  questions: ConversationQuestion[];
  meanings: IOrganizationObservationMeaning[];
  queries: TrendsChartGenericDataQueries;
};

const TrendsItem = ({
  visualization,
  questions,
  meanings,
  queries,
}: TrendsItemProps) => {
  let config = useChartConfig(visualization, questions, meanings);
  const dateRangeObject = useContext(ChangeCaseDashboardDateRangeContext);

  const { data, isLoading } = useTrendsChartGenericData(
    visualization,
    queries,
    {
      from: dateRangeObject?.from ?? new Date(),
      to: dateRangeObject?.to ?? new Date(),
    }
  );

  const formatters = useTrendsChartGenericFormatters(questions, meanings);

  if (isLoading) return <TrendsPlaceholder items={1} />;

  return (
    <TabItem>
      <TrendsChart
        elements={visualization.elements}
        formatters={formatters}
        config={config}
        data={data}
      />
    </TabItem>
  );
};

export default TrendsItem;
