import { useCaseGroupDetailsCommonData } from '../../../hooks/useCaseGroupDetailsCommonData';
import { useCallback } from 'react';

export const useTreatmentPhasesNames = () => {
  const { caseGroup, caseCategories } = useCaseGroupDetailsCommonData();

  return useCallback(
    (phaseId: string) => {
      if (!caseGroup.data || !caseCategories.data) return undefined;

      const subCategories =
        caseCategories.data.find(
          (p) => p.id === caseGroup.data.case_category_id
        )?.sub_categories ?? [];

      return subCategories.find((p) => p.id === phaseId)?.name_en;
    },
    [caseCategories.data, caseGroup.data]
  );
};
