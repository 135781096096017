export const HISTORY_PAGE_SIZE = 1000;
export const MIN_SEARCH_LENGTH = 3;
export const DEFAULT_COUNTRY_PREFIX = 'no';
export const MINIMUM_PAIN_LEVEL = 0;
export const MAXIMUM_PAIN_LEVEL = 10;
export const DAY_IN_MILLISECONDS = 86400 * 1000;
export const HOUR_IN_MILLISECONDS = 60 * 1000;
export const DEFAULT_DEBOUNCE_TIME = 1000;
export const DEFAULT_CASE_PAGE_SIZE = 25;
export const DEFAULT_PATIENT_PAGE_SIZE = 25;
export const WRONG_PASSWORD_ERROR_CODE = 'email_or_password_is_wrong_error';
export const REQUEST_PAGE_SIZE = 10000;
