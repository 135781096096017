import React from 'react';
import useUserCaseCurrentConversation from '../../../../../../api/queries/conversations/useUserCaseCurrentConversation';
import { useCommonParams } from '../../../../hooks/useCommonParams';
import { useInitQuery } from '../../../../../../hooks/queries/useInitQuery';
import QueryLoaded from '../../../../../../components/QueryLoaded';
import Conversation from '../../../../../ConversationsAndFeedbacks/components/ConversationTemplates/components/ConversationTemplatePhone/components/Conversation';
import {
  ConversationTemplatePhoneItemActions,
  ItemActionsContext,
  LanguageContext,
} from '../../../../../ConversationsAndFeedbacks/components/ConversationTemplates/components/ConversationTemplatePhone/types';
import { useHandleConvertToTemplate } from './hooks/useHandleConvertToTemplate';
import useCultures from 'hooks/cultures/useCultures';

const defaultConversationActions: ConversationTemplatePhoneItemActions = {};

const SimpleDefaultConversation = () => {
  const { userId, caseId } = useCommonParams();

  const { currentCulture } = useCultures();

  const userCaseQuery = useUserCaseCurrentConversation({
    params: { userId, caseId },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(userCaseQuery);

  const convertToTemplate = useHandleConvertToTemplate();

  return (
    <QueryLoaded query={userCaseQuery}>
      {(conversation) => (
        <LanguageContext.Provider value={currentCulture}>
          <ItemActionsContext.Provider value={defaultConversationActions}>
            <Conversation
              value={convertToTemplate(conversation)}
              avatars={[]}
              actionsDisabled={true}
            />
          </ItemActionsContext.Provider>
        </LanguageContext.Provider>
      )}
    </QueryLoaded>
  );
};

export default SimpleDefaultConversation;
