import { FormikErrors } from 'formik';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import {
  IBaseConversationScheduleTemplate,
  IConversationScheduleTemplateItem,
  IConversationTreatmentPhaseContentItem,
} from '../../../../../../../../../../../interfaces/conversations';
import { displayErrorNotification } from '../../../../../../../../../../Notifications';

export const useFormValidation = () => {
  const intl = useIntl();

  const genericValidation = useCallback(
    (
      errors: FormikErrors<IConversationTreatmentPhaseContentItem>,
      defaultTemplateMode: boolean,
      values: IConversationTreatmentPhaseContentItem,
      emptyConversationTemplateId?: string
    ) => {
      if (
        defaultTemplateMode &&
        values.template_id === emptyConversationTemplateId
      ) {
        errors.template_id = intl.formatMessage({
          id: 'TreatmentPlans.TreatmentPlan.TreatmentPlanContent.TreatmentPhase.TreatmentPhaseAddTemplateModal.validationError',
        });
        return errors;
      }

      if (values.template_id === '') {
        errors.template_id = intl.formatMessage({
          id: 'TreatmentPlans.TreatmentPlan.TreatmentPlanContent.TreatmentPhase.TreatmentPhaseAddTemplateModal.validationError',
        });

        return errors;
      }

      if (values.priority <= 0) {
        errors.priority = intl.formatMessage({
          id: 'TreatmentPlans.TreatmentPlan.TreatmentPlanContent.TreatmentPhase.TreatmentPhaseAddTemplateModal.validationError',
        });

        return errors;
      }

      return errors;
    },
    [intl]
  );

  const isPartsOfADayValid = (partsOfADay: IConversationScheduleTemplateItem) =>
    (partsOfADay?.notification_hour ?? -1000) >=
      (partsOfADay?.available_from_hour ?? 0) &&
    (partsOfADay?.notification_hour ?? 1000) <=
      (partsOfADay?.available_to_hour ?? 0);

  const singleInstanceValidation = (
    errors: FormikErrors<IConversationTreatmentPhaseContentItem>,
    schedule: IBaseConversationScheduleTemplate,
    daysDuration?: number
  ) => {
    if ((schedule.specific_day_of_phase ?? -1) < 1)
      errors.schedule_templates = [];

    if (!isPartsOfADayValid(schedule.parts_of_a_day[0]))
      errors.schedule_templates = [];

    if (
      daysDuration !== undefined &&
      (schedule.specific_day_of_phase ?? -1) > daysDuration
    ) {
      displayErrorNotification(
        'TreatmentPlans.TreatmentPlan.TreatmentPlanContent.TreatmentPhase.TreatmentPhaseAddTemplateModal.daysDurationConstraint',
        { days: daysDuration }
      );
      errors.schedule_templates = [];
      return errors;
    }

    return errors;
  };

  const recurringValidation = (
    errors: FormikErrors<IConversationTreatmentPhaseContentItem>,
    schedule: IBaseConversationScheduleTemplate,
    daysDuration?: number
  ) => {
    if (
      !schedule.start_day_offset ||
      !schedule.end_day_offset ||
      !schedule.recurring_interval
    ) {
      errors.schedule_templates = [];
      return errors;
    }

    if (schedule.start_day_offset > schedule.end_day_offset) {
      errors.schedule_templates = [];
      return errors;
    }

    if (
      daysDuration !== undefined &&
      schedule.end_day_offset > daysDuration + 1
    ) {
      errors.schedule_templates = [];
      return errors;
    }

    if (
      schedule.parts_of_a_day.find(
        (p) =>
          !p.available_from_hour || !p.available_to_hour || !p.notification_hour
      )
    )
      errors.schedule_templates = [];

    if (schedule.parts_of_a_day.find((p) => !isPartsOfADayValid(p)))
      errors.schedule_templates = [];

    return errors;
  };

  return {
    genericValidation: genericValidation,
    singleInstanceValidation: singleInstanceValidation,
    recurringValidation: recurringValidation,
  };
};
