import useAddEducationMaterial from '../../../../../api/mutations/educationMaterials/useAddEducationMaterial';
import { useCommonParams } from '../../../hooks/useCommonParams';
import { IEducationMaterial } from '../../../../../interfaces/educationMaterials';
import { useCallback } from 'react';
import { MaterialModalMode, MaterialModalStepsContent } from '../types';
import { useCommonData } from '../../../hooks/useCommonData';

export const useHandleAddEducationMaterial = (
  mode: MaterialModalMode,
  setCreatedMaterial: (material: IEducationMaterial) => void,
  steps: MaterialModalStepsContent,
  toggle: () => void
) => {
  const { organizationId } = useCommonParams();
  const { educationMaterials } = useCommonData();

  const handleSuccess = useCallback(
    async (material: IEducationMaterial) => {
      await educationMaterials.refetch();

      if (mode === MaterialModalMode.AddMaterial) {
        setCreatedMaterial(material);
        steps.next();
        return;
      }

      if (mode === MaterialModalMode.EditMaterial) toggle();
    },
    [educationMaterials, mode, setCreatedMaterial, steps, toggle]
  );

  const { mutateAsync: addAsync } = useAddEducationMaterial({
    params: { organizationId },
    options: { successFb: handleSuccess },
  });

  return { addAsync };
};
