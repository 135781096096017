import {
  IconButton as MaterialIconButton,
  iconButtonClasses,
  IconButtonProps,
  PaletteColor,
  Theme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import classNames from 'classnames';

const getColoredButtonStyles = (color: PaletteColor, theme: Theme) => {
  return {
    backgroundColor: color.main,
    color: theme.palette.common.white,
  };
};

const getOutlinedButtonStyles = (color: PaletteColor, theme: Theme) => {
  return {
    backgroundColor: theme.palette.common.white,
    color: color.main,
    border: `1px solid ${color.main}`,
  };
};

const getDisabledButtonStyles = (theme: Theme) => {
  return {
    color: `${theme.palette.action.disabled} !important`,
    border: `1px solid ${theme.palette.action.disabledBackground} !important`,
  };
};

const CustomIconButton = styled(MaterialIconButton)(({ theme }) => ({
  [`&.${iconButtonClasses.colorPrimary}`]: getColoredButtonStyles(
    theme.palette.primary,
    theme
  ),
  [`&.${iconButtonClasses.colorInfo}`]: getColoredButtonStyles(
    theme.palette.info,
    theme
  ),
  [`&.${iconButtonClasses.colorError}`]: getColoredButtonStyles(
    theme.palette.error,
    theme
  ),
  [`&.${iconButtonClasses.colorSuccess}`]: getColoredButtonStyles(
    theme.palette.success,
    theme
  ),
  [`&.${iconButtonClasses.colorSecondary}`]: getColoredButtonStyles(
    theme.palette.secondary,
    theme
  ),
  [`&.${iconButtonClasses.colorWarning}`]: getColoredButtonStyles(
    theme.palette.warning,
    theme
  ),
  [`&.${iconButtonClasses.colorPrimary}.outlined`]: getOutlinedButtonStyles(
    theme.palette.primary,
    theme
  ),
  [`&.${iconButtonClasses.colorInfo}.outlined`]: getOutlinedButtonStyles(
    theme.palette.info,
    theme
  ),
  [`&.${iconButtonClasses.colorError}.outlined`]: getOutlinedButtonStyles(
    theme.palette.error,
    theme
  ),
  [`&.${iconButtonClasses.colorSuccess}.outlined`]: getOutlinedButtonStyles(
    theme.palette.success,
    theme
  ),
  [`&.${iconButtonClasses.colorSecondary}.outlined`]: getOutlinedButtonStyles(
    theme.palette.secondary,
    theme
  ),
  [`&.${iconButtonClasses.colorWarning}.outlined`]: getOutlinedButtonStyles(
    theme.palette.warning,
    theme
  ),
  [`&.${iconButtonClasses.disabled}`]: getDisabledButtonStyles(theme),
}));

type CustomIconButtonProps = IconButtonProps & {
  variant: 'contained' | 'outlined';
};

export const IconButton = ({ variant, ...rest }: CustomIconButtonProps) => {
  return (
    <CustomIconButton
      {...rest}
      className={classNames({ outlined: variant === 'outlined' })}
    />
  );
};

export default IconButton;
