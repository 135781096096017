import { useInnerWidth } from '../../../hooks/useInnerWidth';
import { useMemo } from 'react';
import type { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { CustomTableResponsiveConfig } from '../../CustomTable';

export const useFilterColumns = (
  columns: GridColDef[],
  responsiveConfig?: CustomTableResponsiveConfig[]
): GridColDef[] => {
  const width = useInnerWidth();

  return useMemo(() => {
    if (!responsiveConfig) return columns;

    let hiddenColumns: number[] = [];

    for (const configItem of responsiveConfig) {
      if (width > configItem.breakpoint) continue;
      hiddenColumns = configItem.hiddenColumns;
    }

    return columns.filter((_, index) => !hiddenColumns.includes(index));
  }, [columns, responsiveConfig, width]);
};
