import React from 'react';
import { TrendsTemplateConfigAction } from '../../../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import { getActionIcon } from './helpers';

type TrendsChartTemplateActionProps = {
  action: TrendsTemplateConfigAction;
};

const TrendsChartTemplateAction = ({
  action,
}: TrendsChartTemplateActionProps) => {
  return (
    <Button
      color="primary"
      outline={true}
      id="download"
      onClick={action.callback}
    >
      <FontAwesomeIcon icon={getActionIcon(action.type)} />
    </Button>
  );
};

export default TrendsChartTemplateAction;
