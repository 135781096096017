export enum RouteDefinitions {
  PARTICIPANT_CHECK = '/organizations/:organizationId/cases/:caseId/participant-check',
  DASHBOARD = '/dashboard',
  CASE_DASHBOARD = '/users/:userId/cases/:caseId/dashboard',
  CASE_EXERCISE_SESSION = '/users/:userId/cases/:caseId/exercise',
  ORGANIZATION_PERSONALITIES = '/organizations/:organizationId/personalities',
  ORGANIZATION_PERSONALITY = '/organization/:organizationId/personalities/:personalityId',
  CREATE_PERSONALITY_IN_ORGANIZATION = '/organization/:organizationId/personalities/create',
  MY_ACCOUNT = '/settings/my-account',
  LOGIN = '/login',
  LOGIN_B2B = '/login/b2b',
  LOGIN_B2C = '/login/b2c',
  REGISTER = '/register',
  REGISTER_IN_PROJECT = 'register/:organizationCode/:projectCode',
  ASK_FOR_HELP = '/ask-for-help',
  USER_TIMELINE = '/timelines/:timelineId',
  SHARING_REQUESTS = '/sharing-requests',
  MY_ORGANIZATION = '/my-organizations/:organizationId',
  ORGANIZATION_REGISTER = '/register-organization',
  ORGANIZATION_PROJECT_SUBMISSIONS = '/organizations/:organizationId/project-submissions',
  ORGANIZATION_EDUCATION = '/organizations/:organizationId/education',
  ORGANIZATION_PROJECTS = '/organizations/:organizationId/projects',
  ORGANIZATION_REPORTS = '/organizations/:organizationId/reports',
  ORGANIZATION_CASES = '/organizations/:organizationId/cases',
  ORGANIZATION_AWARE_CASES = '/organizations/:organizationId/aware-cases',
  ORGANIZATION_CASES_LIST = '/organizations/:organizationId/cases-list',
  ORGANIZATION_ENTERPRISE_CASES = '/organizations/:organizationId/enterprise/cases',
  ORGANIZATIONS_OVERVIEW = '/organizations/:organizationId/overview',
  TEMP_ORGANIZATIONS_OVERVIEW = '/organizations/:organizationId/organization-overview',
  MEMBER_REPORTS = '/organizations/:organizationId/members/:memberId/reports',
  ORGANIZATION_DASHBOARDS = '/organizations/:organizationId/dashboard',
  ORGANIZATION_CASE = '/organizations/:organizationId/cases/:caseId',
  ORGANIZATION_CASE_GROUPS = '/organizations/:organizationId/case-groups',
  ORGANIZATION_CASE_LOGS = '/organizations/:organizationId/cases/:caseId/activity-log',
  ORGANIZATION_CASE_CONVERSATIONS = '/organizations/:organizationId/cases/:caseId/conversations',
  ORGANIZATION_CASE_SCHEDULE = '/organizations/:organizationId/cases/:caseId/schedule',
  ORGANIZATION_GRASPS = '/organizations/:organizationId/grasps',
  ORGANIZATION_USERS_AND_ACCESS = '/organizations/:organizationId/users-and-access',
  ORGANIZATION_GRASP = '/organizations/:organizationId/grasps/:graspId',
  EMAIL_CONFIRMATION = '/email-confirmation/:id/:code',
  PASSWORD_RESET = '/password-reset',
  SET_PASSWORD = '/set-password/:token',
  ORGANIZATION_INVITATION = '/organizations/:organizationId/invitation_requests/:invitationId',
  ORGANIZATION_PATIENTS = '/organizations/:organizationId/patients',
  ORGANIZATION_PARTICIPANTS = '/organizations/:organizationId/participants',
  ORGANIZATION_PARTICIPANT = '/organizations/:organizationId/participants/:personalityId',
  ORGANIZATION_DASHBOARD = '/organizations/:organizationId/dashboards/:dashboardId',
  DENTAL_ORGANIZATION = '/organizations/:organizationId/dental',
  TIMELINE_DETAILS_ASSESSMENT_PROXY = '/timelines/:timelineId/:year/:month/:day/assessment-forms/:sequence',
  TIMELINE_DETAILS_ANNOTATION_PROXY = '/timelines/:timelineId/:year/:month/:day/annotations/:annotationId',
  ORGANIZATION_CONVERSATIONS_AND_FEEDBACKS = '/organizations/:organizationId/resources-hub',
  TREATMENT_PLANS = '/organizations/:organizationId/treatment-plans',
  TREATMENT_PLAN = '/organizations/:organizationId/treatment-plans/:treatmentPlanId',
  CASE_GROUP_DETAILS = '/organizations/:organizationId/projects/:projectId/case-groups/:groupId',
}

export const generateRoute = (
  routeDefinition: RouteDefinitions,
  values: Record<string, string> = {}
) => {
  const keys = Object.keys(values);

  let modifiedString = routeDefinition.toString();
  keys.forEach((key) => {
    const regExp = new RegExp(`:${key}`);
    modifiedString = modifiedString.replace(regExp, values[key]);
  });

  return modifiedString;
};

export enum RedirectSource {
  memberDetails = 'memberDetails',
  caseList = 'caseList',
  caseDetails = 'caseDetails',
}

export const generateParticipantCheckRoute = (
  params: {
    organizationId: string;
    caseId: string;
  },
  redirectSource?: RedirectSource
) => {
  const redirectQuery = redirectSource
    ? `?redirectSource=${redirectSource}`
    : '';

  return `${generateRoute(
    RouteDefinitions.PARTICIPANT_CHECK,
    params
  )}${redirectQuery}`;
};

export const generateOrganizationPersonalitiesUrl = (params: {
  organizationId: string;
}) => generateRoute(RouteDefinitions.ORGANIZATION_PERSONALITIES, params);

export const generatePersonalityUrl = (params: {
  organizationId: string;
  personalityId: string;
}) => generateRoute(RouteDefinitions.ORGANIZATION_PERSONALITY, params);

export const generateParticipantUrl = (params: {
  organizationId: string;
  personalityId: string;
}) => generateRoute(RouteDefinitions.ORGANIZATION_PARTICIPANT, params);

export const generateOrganizationPersonalityCreateUrl = (params: {
  organizationId: string;
}) =>
  generateRoute(RouteDefinitions.CREATE_PERSONALITY_IN_ORGANIZATION, params);

export const generateOrganizationCaseListUrl = (params: {
  organizationId: string;
}) => generateRoute(RouteDefinitions.ORGANIZATION_CASES, params);

export const generateCaseDetailsUrl = (params: {
  organizationId: string;
  caseId: string;
}) => generateRoute(RouteDefinitions.ORGANIZATION_CASE, params);
