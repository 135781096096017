import { register } from 'api/membership';
import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import { IRegisterForm } from 'interfaces/membership';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { getCulture } from 'translations/utils/cultures';

function useRegisterUser(
  onSuccess?: () => void,
  onSuccessAsync?: () => void,
  resetOnResult: boolean = true,
  silent: boolean = false
) {
  return useMutationWithResultNotification({
    asyncApiFunction: async ({
      email,
      last_name,
      first_name,
      user_name,
      password,
      birthdate,
      gender,
      address,
      ssn,
      token,
      phone_number,
      timezone_id,
      culture,
      project,
      external_login_provider,
    }: IRegisterForm) => {
      //TODO: REMOVE ANY - pre demo quick solution
      let registerBody: any = {
        email,
        user_name,
        culture: culture ? culture : getCulture(),
        first_name,
        last_name,
        password,
        timezone_id,
        token,
        personality: {
          email,
          short_name: user_name,
          culture: culture ? culture : getCulture(),
          first_name,
          last_name,
          birthdate: birthdate ? new Date(birthdate).toISOString() : undefined,
          gender: gender.length ? gender : undefined,
          address: address.length ? address : undefined,
          ssn: ssn.length ? ssn : undefined,
          phone_number,
        },
        external_login_provider: external_login_provider ?? null,
      };

      if (project !== undefined) {
        registerBody = {
          ...registerBody,
          project: project,
        };
      }

      await register(registerBody);
    },
    resetOnResult: resetOnResult,
    onSuccess: () => {
      if (!silent)
        displaySuccessNotification('Register.notifications.success.registered');

      onSuccess?.();
      onSuccessAsync?.();
    },
    onErrorArray: (errorArray) => {
      if (errorArray.length)
        displayErrorArrayNotifications(
          errorArray,
          'Register.notifications.errors'
        );
    },
  });
}

export default useRegisterUser;
