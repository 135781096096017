import { TimeRange } from './timeline';
import { TrendVisualizationTemplate } from './trendVisualizations';

export interface IBaseCaseDefaultSetup {
  id: string;
  origin: CaseDefaultSetupOrigin;
  origin_id: string;
  default_selected: boolean;
  expected_feedback_frequency_in_hours: number | null;
  expected_synchronization_frequency_in_hours: number | null;
  form_assignments_template_id: string | null;
  default_visible_time_range: TimeRange | null;
}

export interface ICaseDefaultSetup extends IBaseCaseDefaultSetup {
  member_trend_visualization_template: TrendVisualizationTemplate;
  patient_trend_visualization_template: TrendVisualizationTemplate;
}

export enum CaseDefaultSetupOrigin {
  Organization = 'Organization',
  CaseCategory = 'CaseCategory',
  CaseSubCategory = 'CaseSubCategory',
}
