import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { IErrorDefinition } from '../../../interfaces/response';
import { addTreatmentPlan } from '../../conversations';
import { IAddTreatmentPlan } from '../../../interfaces/conversations';

const useAddTreatmentPlan = (
  organizationId: string,
  successFb?: () => void
) => {
  return useMutationWithResultNotification({
    onSuccess: () => {
      displaySuccessNotification(
        'Api.Mutations.Conversations.AddTreatmentPlan.success'
      );

      if (!successFb) return;
      successFb();
    },
    onError: (_) => {
      displayErrorNotification(
        'Api.Mutations.Conversations.AddTreatmentPlan.failure'
      );
    },
    onErrorArray: (errorArray: IErrorDefinition[]) => {
      errorArray.forEach((item) => {
        displayErrorNotification(
          item.reasons.at(0) ??
            'Api.Mutations.Conversations.AddTreatmentPlan.failure'
        );
      });
    },
    asyncApiFunction: async (data: IAddTreatmentPlan) => {
      await addTreatmentPlan(organizationId, data);
    },
    resetOnResult: true,
  });
};

export default useAddTreatmentPlan;
