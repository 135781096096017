import { faCommentsAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import SaveIcon from '@mui/icons-material/Save';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Formik } from 'formik';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import IconButton from '../../../../../../../../../../../../../../components/IconButton';
import { useOptions } from '../../../../../../../../../../../../../../hooks/useOptions';
import { ConversationScheduleType } from '../../../../../../../../../../../../../../interfaces/conversationSchedules';
import { BaseModalProps } from '../../../../../../../../../../../../../../interfaces/ui';
import RecurringNotificationForm from './forms/RecurringNotificationForm';
import SingleNotificationForm from './forms/SingleNotificationForm';
import { useForm } from './hooks/useForm';
import { useHandleScheduleType } from './hooks/useHandleScheduleType';

type AddNotificationModalProps = BaseModalProps & {
  selectedDate: string;
};

const AddNotificationModal = ({
  isOpen,
  toggle,
  selectedDate,
}: AddNotificationModalProps) => {
  const { caseNotificationScheduleTypesOptions } = useOptions();

  const intl = useIntl();
  const formHandler = useForm(selectedDate, toggle);

  const handleScheduleType = useHandleScheduleType();

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="GenericModal BorderlessModal"
      size="lg"
    >
      <ModalHeader toggle={toggle}>
        <FontAwesomeIcon icon={faCommentsAlt} />
        <FormattedMessage id="Notifications.AddNotification" />
      </ModalHeader>
      <Formik
        initialValues={formHandler.initialValues}
        validationSchema={formHandler.validationSchema}
        enableReinitialize={true}
        validateOnMount={true}
        onSubmit={formHandler.handleSubmit}
      >
        {(form) => (
          <>
            <ModalBody>
              <div className="d-flex flex-column gap-4">
                <TextField
                  variant="outlined"
                  select
                  name="notification_type"
                  placeholder={intl.formatMessage({
                    id: 'Notifications.NotificationType',
                  })}
                  label={intl.formatMessage({
                    id: 'Notifications.NotificationType',
                  })}
                  value={form.values.notification_type}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  error={
                    form.touched.notification_type &&
                    Boolean(form.errors.notification_type)
                  }
                  helperText={
                    form.touched.notification_type &&
                    form.errors.notification_type
                  }
                >
                  {caseNotificationScheduleTypesOptions().map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      <span>{option.text}</span>
                    </MenuItem>
                  ))}
                </TextField>
                <div className="d-flex flex-column gap-1 mb-2">
                  <Typography
                    sx={{ paddingLeft: '0.75rem', color: 'text.secondary' }}
                    variant="caption"
                  >
                    <FormattedMessage id="Schedules.ScheduleType" />
                  </Typography>
                  <ToggleButtonGroup
                    fullWidth={true}
                    color="primary"
                    value={form.values.schedule_type}
                    exclusive
                    onChange={handleScheduleType(form)}
                  >
                    <ToggleButton
                      value={ConversationScheduleType.SingleInstance}
                    >
                      <FormattedMessage id="Enums.ConversationScheduleType.SingleInstance" />
                    </ToggleButton>
                    <ToggleButton value={ConversationScheduleType.Recurring}>
                      <FormattedMessage id="Enums.ConversationScheduleType.Recurring" />
                    </ToggleButton>
                  </ToggleButtonGroup>
                </div>
                {form.values.schedule_type ===
                  ConversationScheduleType.SingleInstance && (
                  <SingleNotificationForm form={form} />
                )}
                {form.values.schedule_type ===
                  ConversationScheduleType.Recurring && (
                  <RecurringNotificationForm form={form} />
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="d-flex align-items-center gap-2">
                <IconButton
                  onClick={form.handleReset}
                  variant="outlined"
                  color="primary"
                >
                  <RestartAltIcon />
                </IconButton>
                <IconButton
                  disabled={!form.isValid}
                  onClick={form.submitForm}
                  variant="contained"
                  color={form.isValid ? 'primary' : 'default'}
                >
                  <SaveIcon />
                </IconButton>
              </div>
            </ModalFooter>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default AddNotificationModal;
