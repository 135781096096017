import { OrganizationQueryParams } from '../../../interfaces/queryParams';
import { IMutationRequest } from '../../../interfaces/request';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { deleteDashboardReportSchedule } from '../../dashboards';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

const useDeleteDashboardReportSchedule = ({
  params,
  options,
}: IMutationRequest<OrganizationQueryParams>) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (scheduleId: string) => {
      await deleteDashboardReportSchedule(params.organizationId, scheduleId);
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'Api.Mutations.Dashboards.DeleteDashboardReportSchedule.failure'
      );
      options.errorFb?.();
    },
    onSuccess: () => {
      displaySuccessNotification(
        'Api.Mutations.Dashboards.DeleteDashboardReportSchedule.success'
      );
      options.successFb?.();
    },
    resetOnResult: true,
  });
};

export default useDeleteDashboardReportSchedule;
