import { ICaseDetails } from '../../interfaces/cases';

export namespace CasesHelpers {
  export const GetUserIdBasedOnCase = (caseDetails: ICaseDetails) => {
    return caseDetails.participants && caseDetails.participants.length > 0
      ? caseDetails.participants[0].personality.user_id
      : null;
  };

  export const GetCaseDefaultParticipant = (caseDetails: ICaseDetails) => {
    return caseDetails?.participants.at(0);
  };

  export const GetCaseDefaultPersonality = (caseDetails: ICaseDetails) => {
    return caseDetails?.participants.at(0)?.personality;
  };
}
