import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import SVG from 'react-inlinesvg';

import createGraspManually from 'assets/images/add_grasps_modal/create_grasp_by_hand.svg';
import uploadGraspsFromFile from 'assets/images/add_grasps_modal/upload_grasps_from_file.svg';

type AddGraspModalProps = {
  isOpen: boolean;
  toggleModal: () => void;
  selectCreateGraspMode: () => void;
  selectUploadGraspsMode: () => void;
};

const AddGraspModal = ({
  isOpen,
  toggleModal,
  selectCreateGraspMode,
  selectUploadGraspsMode,
}: AddGraspModalProps) => {
  return (
    <Modal
      size="lg"
      className="AddGraspsModal"
      isOpen={isOpen}
      toggle={toggleModal}
    >
      <ModalHeader className="AddGraspsModal__header">
        <FormattedMessage id="MyOrganizations.grasps.modal.headerAdd" />
      </ModalHeader>
      <ModalBody className="AddGraspsModal__body">
        <h1 className="AddGraspsModal__body__title">
          <FormattedMessage id="MyOrganizations.grasps.addGraspsModal.chooseAddingMode" />
        </h1>
        <div className="AddGraspsModal__body__choose-adding-mode">
          <div
            className="AddGraspsModal__body__choose-adding-mode__mode"
            onClick={selectCreateGraspMode}
          >
            <div className="AddGraspsModal__body__choose-adding-mode__mode__image">
              <SVG src={createGraspManually} />
            </div>
            <span className="AddGraspsModal__body__choose-adding-mode__mode__text">
              <FormattedMessage id="MyOrganizations.grasps.addGraspsModal.createGrasp" />
            </span>
          </div>
          <div
            className="AddGraspsModal__body__choose-adding-mode__mode"
            onClick={selectUploadGraspsMode}
          >
            <div className="AddGraspsModal__body__choose-adding-mode__mode__image">
              <SVG src={uploadGraspsFromFile} />
            </div>
            <span className="AddGraspsModal__body__choose-adding-mode__mode__text">
              <FormattedMessage id="MyOrganizations.grasps.addGraspsModal.uploadGrasps" />
            </span>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="AddGraspsModal__footer">
        <Button color="primary" outline onClick={toggleModal}>
          <FormattedMessage id="General.close" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddGraspModal;
