import React, { useMemo } from 'react';
import {
  ValidConversationTemplateItem,
  ValidConversationTemplateItemType,
} from '../../../../../../../../../../interfaces/conversations';
import { useItemActions } from './hooks/useItemActions';
import ItemBase from './components/ItemBase';
import DelimiterItem from './components/DelimiterItem';
import PlainTextItem from './components/PlainTextItem';
import EducationItem from './components/EducationItem';
import FeedbackItem from './components/FeedbackItem';
import GraspUploadItem from './components/GraspUploadItem';
import MaterialsListItem from './components/MaterialsListItem';
import NotificationsListItem from './components/NotificationsListItem';
import QuestionItem from './components/QuestionItem';

type ConversationItemProps = {
  isFirst: boolean;
  isLast: boolean;
  index: number;
  item: ValidConversationTemplateItem;
  avatars: string[] | undefined;
  actionsDisabled: boolean;
};

const ConversationItem = ({
  item,
  index,
  isFirst,
  isLast,
  avatars,
  actionsDisabled,
}: ConversationItemProps) => {
  const actions = useItemActions(index, isFirst, isLast);

  const renderTypedItem = useMemo(() => {
    switch (item.type) {
      case ValidConversationTemplateItemType.PlainText:
        return <PlainTextItem item={item} avatars={avatars} />;
      case ValidConversationTemplateItemType.Delimiter:
        return <DelimiterItem item={item} />;
      case ValidConversationTemplateItemType.Education:
        return <EducationItem item={item} />;
      case ValidConversationTemplateItemType.Feedback:
        return <FeedbackItem item={item} avatars={avatars} />;
      case ValidConversationTemplateItemType.GraspUpload:
        return <GraspUploadItem />;
      case ValidConversationTemplateItemType.MaterialsList:
        return <MaterialsListItem />;
      case ValidConversationTemplateItemType.NotificationsList:
        return <NotificationsListItem />;
      case ValidConversationTemplateItemType.Question:
        return <QuestionItem item={item} />;
    }
  }, [item, avatars]);

  return (
    <ItemBase item={item} actions={actions} actionsDisabled={actionsDisabled}>
      {renderTypedItem}
    </ItemBase>
  );
};

export default ConversationItem;
