import { useInitQueries } from 'hooks/queries/useInitQuery';
import { useCommonData } from 'modules/Organizations/views/OrganizationDetailsView/hooks/useCommonData';
import React from 'react';
import TabLayout from '../../../../../../components/layouts/TabLayout';
import { CommonTabProps } from '../types';
import Form from './components/Form';
import SavedViews from './components/SavedViews';
import Tooltip from './components/Tooltip';

const TrendVisualizationsTab = ({ type }: CommonTabProps) => {
  const {
    organizationDetails,
    caseDefaultSetups,
    trendVisualizationTemplates,
    caseCategories,
  } = useCommonData();

  useInitQueries([
    organizationDetails,
    caseCategories,
    caseDefaultSetups,
    trendVisualizationTemplates,
  ]);

  return (
    <TabLayout
      type={type}
      titlePrefix="Organizations.TrendsVisualizations"
      tooltip={{
        element: <Tooltip />,
        targetId: 'trendVisualizationsTitleTooltip',
      }}
    >
      <SavedViews />
      <Form />
    </TabLayout>
  );
};

export default TrendVisualizationsTab;
