import { CardContent, ThemeProvider } from '@mui/material';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import React, { PropsWithChildren } from 'react';
import { ThemeGenerators } from '../../../../../../../../themes/generators';

type ItemContainerProps = {
  title: string;
  icon: JSX.Element;
};

const ItemContainer = ({
  title,
  icon,
  children,
}: PropsWithChildren<ItemContainerProps>) => {
  return (
    <ThemeProvider theme={ThemeGenerators.AwareThemeGenerator()}>
      <Card
        sx={{
          borderRadius: 0,
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'background.default',
          color: 'text.primary',
          position: 'relative',
        }}
        elevation={0}
      >
        <CardHeader
          avatar={icon}
          title={
            <Typography sx={{ color: 'text.primary' }} variant="subtitle1">
              {title}
            </Typography>
          }
        />
        <CardContent
          sx={{
            width: '100%',
            height: '100%',
            flexGrow: 1,
            position: 'absolute',
            top: 0,
            left: 0,
            paddingTop: '60px',
          }}
        >
          {children}
        </CardContent>
      </Card>
    </ThemeProvider>
  );
};

export default ItemContainer;
