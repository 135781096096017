import React, { useCallback, useMemo } from 'react';
import GraspLogo from '../../../../components/GraspLogo';
import { Size } from '../../../../interfaces/ui';
import { GraspColors, graspColorsValues } from '../../../../helpers/colors';
import Skeleton from 'react-loading-skeleton';
import CustomTable from '../../../../components/CustomTable';
import { wrapWithSequenceIdentified } from '../../../../helpers/utils/data';

const headerItems = [
  null,
  'OrganizationGrasps.deviceName',
  'OrganizationGrasps.status',
  'OrganizationGrasps.prescribedTo',
  'OrganizationGrasps.prescribedDate',
  'OrganizationGrasps.createdAt',
  'OrganizationGrasps.batteryLevel',
  'OrganizationGrasps.lastBatterySync',
];

const GraspListPlaceholder = () => {
  const singleRow = useCallback((key: string) => {
    return (
      <tr className="cursor-pointer" key={key}>
        <td>
          <GraspLogo
            size={Size.SMALL}
            color={graspColorsValues[GraspColors.BLUE]}
            width={45}
          />
        </td>
        <td>
          <Skeleton width={150} />
        </td>
        <td>
          <Skeleton width={150} />
        </td>
        <td>
          <Skeleton width={150} />
        </td>
        <td>
          <Skeleton width={150} />
        </td>
        <td>
          <Skeleton width={150} />
        </td>
        <td>
          <Skeleton width={150} />
        </td>
        <td>
          <Skeleton width={150} />
        </td>
      </tr>
    );
  }, []);

  const renderRows = useMemo(() => {
    return wrapWithSequenceIdentified([...Array(5)]).map((item) =>
      singleRow(item.sequence)
    );
  }, [singleRow]);

  return <CustomTable headerItems={headerItems} bodyRows={renderRows} />;
};

export default GraspListPlaceholder;
