import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { ICreateCaseGroupWithCaseCategory } from '../../../interfaces/caseGroups';
import { createCaseGroup } from '../../caseGroups';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { IMutationProps } from '../../../interfaces/ui';

type useCreateCaseGroupParams = {
  organizationId: string;
};

const useCreateCaseGroup = (
  params: useCreateCaseGroupParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (body: ICreateCaseGroupWithCaseCategory) => {
      await createCaseGroup(params.organizationId, body.project_id, body);
    },
    onSuccess: () => {
      if (options.successFb) options.successFb();
      displaySuccessNotification('CaseGroupItem.createSessionSuccess');
    },
    onError: (errorArray) => {
      if (options.errorFb) options.errorFb();

      displayErrorArrayNotifications(
        errorArray,
        'CaseGroupItem.createSessionError'
      );
    },
    resetOnResult: true,
  });
};

export default useCreateCaseGroup;
