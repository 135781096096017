import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { sendProjectFormConversation } from '../../submissions';
import { displayErrorArrayNotifications } from '../../../modules/Notifications';
import { FormConversationAnswer } from '../../../interfaces/conversations';
import { SubmissionStatus } from '../../../interfaces/submissions';

const useSendProjectFormConversation = (
  organizationCode: string,
  projectCode: string,
  templateId: string,
  successFb?: (submissionId: string) => void,
  rejectFb?: () => void
) => {
  const mutationData = useMutationWithResultNotification({
    asyncApiFunction: async (answer: FormConversationAnswer) => {
      const { data } = await sendProjectFormConversation(
        organizationCode,
        projectCode,
        templateId,
        answer
      );

      return data.data;
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'ProjectSubmissions.sendProjectFormError'
      );
    },
    onSuccess: () => {
      if (mutationData.data) {
        if (mutationData.data.status === SubmissionStatus.APPROVED) {
          successFb && successFb(mutationData.data.id);
        } else {
          rejectFb && rejectFb();
        }

        mutationData.reset();
      }
    },
    resetOnResult: false,
  });

  return mutationData;
};

export default useSendProjectFormConversation;
