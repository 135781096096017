import { IPatchObject } from '../../../interfaces/request';
import { OrganizationQueryParams } from '../../../interfaces/queryParams';
import { IMutationProps } from '../../../interfaces/ui';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { patchGraspSettings } from '../../graspManagement';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';

export type usePatchGraspSettingsBody = {
  patchData: IPatchObject[];
  id: string;
};

const usePatchGraspSettings = (
  params: OrganizationQueryParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (data: usePatchGraspSettingsBody) => {
      await patchGraspSettings(params.organizationId, data.id, data.patchData);
    },
    resetOnResult: true,
    onSuccess: () => {
      displaySuccessNotification('OrganizationGrasps.updateSuccess');
      if (options.successFb) options.successFb();
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'OrganizationGrasps.updateError'
      );
    },
  });
};

export default usePatchGraspSettings;
