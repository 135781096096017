import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getOrganizationsUserAccessProfiles } from '../../accessProfiles';
import { OrganizationQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

const useOrganizationUserAccessProfiles = ({
  params,
  options,
}: IQueryRequest<OrganizationQueryParams>) => {
  const { organizationId } = params;
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getOrganizationsUserAccessProfiles(organizationId);
      return data.data;
    },
    queryKey: [QueryKeys.getOrganizationUserAccessProfiles, organizationId],
  });
};

export default useOrganizationUserAccessProfiles;
