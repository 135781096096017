import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { Alert, ListGroup, ListGroupItem, Spinner } from 'reactstrap';
import { useCookie } from '../hooks/useCookie';
import { ViewTheme } from '../interfaces/ui';
import IconButton from './IconButton';

export type RowItem = {
  title: string;
  id: string;
  description?: string | null;
};

export interface RowItemWithDelete extends RowItem {
  deleteId: string;
}

type ListWithRemoveAddActionsProps = {
  onAddItem: (id: string) => void;
  onRemoveItem: (id: string) => void;
  emptyAddRowInfoTranslation: string;
  emptyRemoveRowInfoTranslation: string;
  addedItems: RowItemWithDelete[];
  removedItems: RowItem[];
  isLoading: boolean;
  isActionLoading: boolean;
  addedHeaderTranslation: string;
  removedHeaderTranslation: string;
};

const ListWithRemoveAddActions = ({
  addedItems,
  emptyAddRowInfoTranslation,
  emptyRemoveRowInfoTranslation,
  onRemoveItem,
  onAddItem,
  removedItems,
  isLoading,
  isActionLoading,
  addedHeaderTranslation,
  removedHeaderTranslation,
}: ListWithRemoveAddActionsProps) => {
  const theme = useCookie<ViewTheme>('theme');

  const hasItemsToRemove = addedItems.length > 0;
  const hasItemsToAdd = removedItems.length > 0;

  const renderedAddedItems = addedItems.map(({ id, title, deleteId }) => {
    const onClick = () => onRemoveItem(deleteId);
    return (
      <ListGroupItem
        className={classNames(
          'd-flex align-items-center justify-content-between',
          {
            'bg-dark text-white': theme === ViewTheme.Dark,
          }
        )}
        key={id}
      >
        {title}
        <IconButton
          variant="contained"
          color="error"
          onClick={onClick}
          className="ms-auto"
          disabled={isActionLoading}
        >
          <RemoveIcon />
        </IconButton>
      </ListGroupItem>
    );
  });

  const renderedRemovedItems = removedItems.map(({ id, title }) => {
    const onClick = () => onAddItem(id);
    return (
      <ListGroupItem
        key={id}
        className={classNames(
          'd-flex align-items-center justify-content-between',
          {
            'bg-dark text-white': theme === ViewTheme.Dark,
          }
        )}
      >
        {title}
        <IconButton
          variant="contained"
          color="primary"
          onClick={onClick}
          className="ms-auto"
          disabled={isActionLoading}
        >
          <AddIcon />
        </IconButton>
      </ListGroupItem>
    );
  });

  if (isLoading) return <Spinner size="xl" />;

  return (
    <div className="d-flex flex-column gap-2">
      <div>
        <h3 className="ObservationMeaning__header">
          <FormattedMessage id={addedHeaderTranslation} />
        </h3>
        {hasItemsToRemove ? (
          <ListGroup>{renderedAddedItems}</ListGroup>
        ) : (
          <Alert color="primary">
            <FormattedMessage id={emptyAddRowInfoTranslation} />
          </Alert>
        )}
      </div>
      <div>
        <h3 className="ObservationMeaning__header mt-4">
          <FormattedMessage id={removedHeaderTranslation} />
        </h3>
        {hasItemsToAdd ? (
          <ListGroup>{renderedRemovedItems}</ListGroup>
        ) : (
          <Alert color="primary">
            <FormattedMessage id={emptyRemoveRowInfoTranslation} />
          </Alert>
        )}
      </div>
    </div>
  );
};

export default ListWithRemoveAddActions;
