import { getOrganizationUserActiveGrasp } from 'api/graspManagement';
import QueryKeys from 'api/queries/queryKeys';
import {
  OrganizationQueryParams,
  PersonalityQueryParams,
} from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { useQuery } from 'react-query';

type UseOrganizationUserActiveGraspsParams = OrganizationQueryParams &
  PersonalityQueryParams;

function useOrganizationUserActiveGrasps({
  params,
  options,
}: IQueryRequest<UseOrganizationUserActiveGraspsParams>) {
  const { organizationId, personalityId } = params;
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getOrganizationUserActiveGrasp(
        organizationId,
        personalityId
      );
      return data.data;
    },
    queryKey: [
      QueryKeys.getOrganizationUserActiveGrasp,
      organizationId,
      personalityId,
    ],
  });
}

export default useOrganizationUserActiveGrasps;
