import React, { useMemo, useState } from 'react';
import TabItem from '../../modules/CaseDashboard/components/CaseDashboardTabs/components/TabItem';
import { ReactState } from '../../interfaces/ui';
import HourByHourSwitch from './components/HourByHourSwitch';
import { useTranslations } from '../../hooks/useTranslations';
import HourByHourChartFigure from './components/HourByHourChartFigure';
import { UseQueryResult } from 'react-query';
import { ICaseDetails } from '../../interfaces/cases';

export enum HourByHourMode {
  AllIncludingExercises = 'AllIncludingExercises',
  ExercisesOnly = 'ExercisesOnly',
}

type HourByHourProps = {
  date: Date;
  caseDetails: UseQueryResult<ICaseDetails>;
};

const HourByHourChart = ({ date, caseDetails }: HourByHourProps) => {
  const { attachPrefix, wrapWithIntl } = useTranslations(
    'CaseDashboard.CaseDashboardTabs.DayDetailsTab.HourByHour'
  );

  const [mode, setMode] = useState<HourByHourMode>(
    HourByHourMode.AllIncludingExercises
  );

  const modeState = useMemo((): ReactState<HourByHourMode> => {
    return { item: mode, setItem: setMode };
  }, [mode]);

  const modeBasedSubTitle = wrapWithIntl(
    attachPrefix(
      mode === HourByHourMode.AllIncludingExercises
        ? 'includingExercises'
        : 'exercisesOnly'
    )
  );

  const subTitle = caseDetails.data?.exercises_enabled
    ? modeBasedSubTitle
    : undefined;

  return (
    <TabItem
      title={wrapWithIntl(attachPrefix('hourByHour'))}
      subtitle={subTitle}
      actions={
        caseDetails.data?.exercises_enabled ? (
          <HourByHourSwitch mode={modeState} />
        ) : undefined
      }
    >
      <HourByHourChartFigure
        date={date}
        mode={modeState.item}
        caseDetails={caseDetails}
      />
    </TabItem>
  );
};

export default HourByHourChart;
