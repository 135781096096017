import React from 'react';
import { useOrganizationCasePathParams } from '../../../../../../../../hooks/paths/useOrganizationCasePathParams';
import useOpen from '../../../../../../../../hooks/useOpen';
import { CaseTrackingType } from '../../../../../../../../interfaces/cases';
import CaseHistoryModal from '../../../../../../modals/CaseHistoryModal';
import ChangeCaseHeader from './components/ChangeCaseHeader';
import { useTabs } from './hooks/useTabs';

const ChangeCaseDetailsTabs = () => {
  const { organizationId, caseId } = useOrganizationCasePathParams();
  const caseHistoryModal = useOpen();

  const { onTabChange, CurrentTab, selectedTab } = useTabs();

  return (
    <>
      <CaseHistoryModal
        caseId={caseId}
        organizationId={organizationId}
        isOpen={caseHistoryModal.isOpen}
        toggleModal={caseHistoryModal.toggle}
        hidePraise={true}
        hideShare={true}
        extendedValues={{
          share_with_patient: true,
        }}
        customTitleTranslationId="Cases.ChangeCase.sendMessage"
      />
      <ChangeCaseHeader
        caseId={caseId}
        organizationId={organizationId}
        openHistoryModal={caseHistoryModal.open}
        onTabChange={onTabChange}
        selectedTab={selectedTab}
        caseTrackingType={CaseTrackingType.Change}
      />
      <CurrentTab />
    </>
  );
};

export default ChangeCaseDetailsTabs;
