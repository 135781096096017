import { IMutationRequest } from '../../../interfaces/request';
import { OrganizationQueryParams } from '../../../interfaces/queryParams';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { deleteEducationMaterialCategory } from '../../educationMaterials';

const useDeleteEducationMaterialCategory = ({
  params,
  options,
}: IMutationRequest<OrganizationQueryParams>) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (id: string) => {
      await deleteEducationMaterialCategory(params.organizationId, id);
    },
    onSuccess: () => {
      displaySuccessNotification(
        'Api.Mutations.EducationMaterials.DeleteEducationMaterialCategory.success'
      );
      options.successFb?.();
    },
    onError: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'Api.Mutations.EducationMaterials.DeleteEducationMaterialCategory.failure'
      );
      options.errorFb?.();
    },
    resetOnResult: true,
  });
};

export default useDeleteEducationMaterialCategory;
