import React from 'react';
import { Document, Image, Page, Text, View } from '@react-pdf/renderer';
import headerBg from 'assets/images/aware_session_details_pdf/header-bg.png';
import footerBg from 'assets/images/aware_session_details_pdf/footer-bg.png';
import { format } from 'date-fns';

type AwareSessionDetailsPdfProps = {
  imageBase64: string;
  date: Date;
  title: string;
};

const AwareSessionDetailsPdf = ({
  imageBase64,
  date,
  title,
}: AwareSessionDetailsPdfProps) => {
  return (
    <Document>
      <Page size="A4" style={{ backgroundColor: '#000' }}>
        <View style={{ position: 'relative' }}>
          <Image src={headerBg} />
          <View
            style={{
              position: 'absolute',
              top: 80,
              left: 50,
              display: 'flex',
              flexDirection: 'column',
              gap: 3,
              color: '#fff',
            }}
          >
            <Text style={{ fontWeight: 600, fontSize: 14 }}>{title}</Text>
            <Text style={{ fontWeight: 600, fontSize: 14 }}>
              {format(date, 'dd.MM.yyyy HH:mm')}
            </Text>
          </View>
        </View>
        <View style={{ padding: 10 }}>
          <Image src={imageBase64} />
        </View>
        <View style={{ position: 'relative' }}>
          <Image src={footerBg} />
        </View>
      </Page>
    </Document>
  );
};

export default AwareSessionDetailsPdf;
