import useCreateOrganizationLocation from 'api/mutations/organizations/useCreateOrganizationLocation';
import useEditLocation from 'api/mutations/organizations/useEditLocation';
import useInfiniteOrganizationLocations from 'api/queries/organizations/useInfiniteOrganizationLocations';
import FieldInput from 'components/FieldInput';
import LoadingButton from 'components/LoadingButton';
import { Formik, Form, Field } from 'formik';
import { validateEmpty } from 'helpers/utils/validators';
import { EditedEndpointItem } from 'interfaces/membership';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

const initialValuesCreate = {
  name: '',
  description: '',
};

type LocationModalModalProps = {
  organizationId: string;
  editedValues?: EditedEndpointItem | null;
  isOpen: boolean;
  toggleModal: () => void;
};

const LocationModal = ({
  organizationId,
  toggleModal,
  isOpen,
  editedValues,
}: LocationModalModalProps) => {
  const intl = useIntl();
  const isEdit = !!editedValues;

  const { refetch } = useInfiniteOrganizationLocations({
    params: { organizationId },
    options: { enabled: false },
  });

  const initialValues =
    isEdit && editedValues
      ? { name: editedValues.name, description: editedValues.description }
      : initialValuesCreate;

  const successFb = () => refetch();

  const { mutate: createLocationMutation, isLoading: isCreateLoading } =
    useCreateOrganizationLocation(organizationId, {
      successFb,
    });

  const { mutate: editLocationMutation, isLoading: isEditLoading } =
    useEditLocation(organizationId, { successFb });

  const validation = (values: typeof initialValues) =>
    validateEmpty<typeof initialValues>(values, intl);

  const onSubmit = (values: typeof initialValues) => {
    if (editedValues) {
      editLocationMutation({
        endpointId: editedValues.id,
        name: values.name,
        description: values.description,
      });
    } else {
      createLocationMutation({
        name: values.name,
        description: values.description,
      });
    }
    toggleModal();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>
        {isEdit ? (
          <FormattedMessage id="MyOrganizations.endpoints.modal.headerEdit" />
        ) : (
          <FormattedMessage id="MyOrganizations.endpoints.modal.headerCreate" />
        )}
      </ModalHeader>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validation}
      >
        {({ isValid, submitForm }) => (
          <>
            <ModalBody>
              <Form>
                <FormGroup>
                  <Label htmlFor="endpointName">
                    <FormattedMessage id="MyOrganizations.endpoints.modal.name" />
                  </Label>
                  <Field
                    required
                    id="endpointName"
                    name="name"
                    type="text"
                    component={FieldInput}
                  />
                  <FormGroup>
                    <Label htmlFor="endpointDescription">
                      <FormattedMessage id="MyOrganizations.endpoints.modal.description" />
                    </Label>
                    <Field
                      required
                      id="endpointDescription"
                      name="description"
                      type="text"
                      component={FieldInput}
                    />
                  </FormGroup>
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <LoadingButton
                disabled={!isValid}
                type="submit"
                onClick={submitForm}
                isLoading={isEditLoading || isCreateLoading}
              >
                <FormattedMessage id="General.confirm" />
              </LoadingButton>
            </ModalFooter>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default LocationModal;
