import { FormikErrors } from 'formik';
import { cloneDeep } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { FieldInlineSelectOptionDefinition } from '../../../../../../../../../../../components/Fields/Formik/FieldInlineSelect';
import { getIconForConversationScheduleType } from '../../../../../../../../../../../helpers/utils/icons';
import {
  IConversationTreatmentPhaseContentItem,
  ISummarizedTreatmentPhase,
  TreatmentPhaseContentItemType,
} from '../../../../../../../../../../../interfaces/conversations';
import { ConversationScheduleType } from '../../../../../../../../../../../interfaces/conversationSchedules';
import { InputOption } from '../../../../../../../../../../../interfaces/ui';
import { useApplyPhase } from '../../../hooks/useApplyPhase';
import { useFormValidation } from './useFormValidation';
import { usePhaseCharacteristics } from './usePhaseCharacteristics';

export const useAddTemplateForm = (
  phase: ISummarizedTreatmentPhase,
  toggle: () => void,
  defaultTemplateMode: boolean,
  contentTemplateIndex?: number
) => {
  const intl = useIntl();

  const {
    isTemplateTimeBased: isTimeBased,
    isTemplateDefault: isDefault,
    conversationTemplatesOptions: templatesOptions,
  } = usePhaseCharacteristics();

  const { genericValidation, singleInstanceValidation, recurringValidation } =
    useFormValidation();

  const initialValues = useMemo((): IConversationTreatmentPhaseContentItem => {
    if (defaultTemplateMode) {
      return {
        type: TreatmentPhaseContentItemType.ConversationSchedule,
        template_id: phase.empty_conversation_template_id ?? '',
        schedule_templates: [],
        priority: 0,
      };
    }

    if (contentTemplateIndex !== undefined) {
      const existingTemplate = cloneDeep(
        phase.content[contentTemplateIndex]
      ) as IConversationTreatmentPhaseContentItem;

      existingTemplate.schedule_templates =
        existingTemplate.schedule_templates.map((template) => ({
          parts_of_a_day: template.parts_of_a_day,
          schedule_type: template.schedule_type,
          start_day_offset:
            template.start_day_offset === null
              ? null
              : template.start_day_offset + 1,
          end_day_offset:
            template.end_day_offset === null
              ? null
              : template.end_day_offset + 1,
          specific_day_of_phase:
            template.specific_day_of_phase === null
              ? null
              : template.specific_day_of_phase + 1,
          recurring_interval: template.recurring_interval,
        }));

      return {
        type: TreatmentPhaseContentItemType.ConversationSchedule,
        template_id: existingTemplate.template_id,
        priority: existingTemplate.priority,
        schedule_templates: [
          existingTemplate.schedule_templates[0] ?? {
            schedule_type: ConversationScheduleType.NonTimeBased,
            parts_of_a_day: [],
            specific_day_of_phase: null,
            end_day_offset: null,
            start_day_offset: null,
            recurring_interval: null,
          },
        ],
      };
    }

    return {
      type: TreatmentPhaseContentItemType.ConversationSchedule,
      template_id: '',
      schedule_templates: [
        {
          schedule_type: ConversationScheduleType.Once,
          recurring_interval: null,
          specific_day_of_phase: null,
          end_day_offset: null,
          start_day_offset: null,
          parts_of_a_day: [],
        },
      ],
      priority: 0,
    };
  }, [
    contentTemplateIndex,
    defaultTemplateMode,
    phase.content,
    phase.empty_conversation_template_id,
  ]);

  const scheduleTypeOptions =
    useMemo((): FieldInlineSelectOptionDefinition[] => {
      return Object.values(ConversationScheduleType)
        .filter((p) => p !== ConversationScheduleType.NonTimeBased)
        .map((val) => ({
          value: val,
          title: intl.formatMessage({
            id: `Enums.ConversationScheduleType.${val}`,
          }),
          icon: getIconForConversationScheduleType(val) ?? '',
        }));
    }, [intl]);

  const isTemplateTimeBased = useCallback(
    (templateId: string): boolean => isTimeBased(templateId),
    [isTimeBased]
  );

  const isTemplateDefault = useCallback(
    (templateId: string): boolean => isDefault(templateId),
    [isDefault]
  );

  const conversationTemplatesOptions = useMemo(
    (): InputOption[] => templatesOptions(defaultTemplateMode),
    [defaultTemplateMode, templatesOptions]
  );

  const validate = useCallback(
    (values: typeof initialValues) => {
      let errors: FormikErrors<typeof initialValues> = {};

      if (isTemplateDefault(values.template_id)) return errors;

      errors = genericValidation(
        errors,
        defaultTemplateMode,
        values,
        phase.empty_conversation_template_id ?? undefined
      );

      if (!isTimeBased(values.template_id)) return errors;

      if (values.schedule_templates.length === 0)
        errors.schedule_templates = [];

      const firstScheduleTemplate = values.schedule_templates.at(0);

      if (!firstScheduleTemplate?.schedule_type) {
        errors.schedule_templates = [];
        return errors;
      }

      if (firstScheduleTemplate.schedule_type === ConversationScheduleType.Once)
        return errors;

      if (
        firstScheduleTemplate.schedule_type ===
        ConversationScheduleType.SingleInstance
      ) {
        errors = singleInstanceValidation(
          errors,
          firstScheduleTemplate,
          phase.days_duration
        );
        return errors;
      }

      errors = recurringValidation(
        errors,
        firstScheduleTemplate,
        phase.days_duration
      );
      return errors;
    },
    [
      defaultTemplateMode,
      genericValidation,
      isTemplateDefault,
      isTimeBased,
      phase,
      recurringValidation,
      singleInstanceValidation,
    ]
  );

  const { templateForm } = useApplyPhase(false, toggle);

  const handleSubmit = async (values: typeof initialValues) => {
    await templateForm(
      values,
      phase,
      isTemplateDefault,
      defaultTemplateMode,
      contentTemplateIndex
    );
  };

  return {
    initialValues: initialValues,
    validate: validate,
    handleSubmit: handleSubmit,
    options: {
      conversationTemplates: conversationTemplatesOptions,
      scheduleTypes: scheduleTypeOptions,
    },
    helpers: {
      isTemplateDefault: isTemplateDefault,
      isTemplateTimeBased: isTemplateTimeBased,
    },
  };
};
