import React, { useMemo } from 'react';
import FieldSelect from '../../../../../../../../../../components/FieldSelect';
import { Field } from 'formik';
import { useTranslations } from '../../hooks/useTranslations';
import { useOptions } from '../../../../../../../../../../hooks/useOptions';
import useFeedbackResources from '../../../../../../../../../../api/queries/feedbackResources/useFeedbackResources';
import { useInitQuery } from '../../../../../../../../../../hooks/queries/useInitQuery';
import { useCommonParams } from '../../../../../../../../hooks/useCommonParams';
import Skeleton from 'react-loading-skeleton';
import SkeletonPlaceholderOnLoad from '../../../../../../../../../../components/SkeletonPlaceholderOnLoad';

type FeedbackItemEditProps = {
  index: number;
};

const FeedbackItemEdit = ({ index }: FeedbackItemEditProps) => {
  const { organizationId } = useCommonParams();

  const { providerInvariant } = useTranslations();

  const feedbackResourcesQuery = useFeedbackResources({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(feedbackResourcesQuery);

  const { feedbackResources } = useOptions();

  const feedbackResourcesOptions = useMemo(() => {
    return feedbackResources(feedbackResourcesQuery);
  }, [feedbackResources, feedbackResourcesQuery]);

  const placeholder = (
    <div className="w-100">
      <Skeleton width="40%" />
      <Skeleton width="100%" height={30} />
    </div>
  );

  return (
    <>
      <SkeletonPlaceholderOnLoad
        isLoading={feedbackResourcesQuery.isLoading}
        placeholder={placeholder}
      >
        <Field
          required={true}
          displayEmpty={true}
          placeholder={providerInvariant.wrapWithIntl(
            providerInvariant.attachPrefix('groupNamePlaceholder')
          )}
          label={providerInvariant.wrapWithIntl(
            providerInvariant.attachPrefix('groupName')
          )}
          id={`content[${index}].group_name`}
          name={`content[${index}].group_name`}
          type="select"
          options={feedbackResourcesOptions}
          component={FieldSelect}
        />
      </SkeletonPlaceholderOnLoad>
    </>
  );
};

export default FeedbackItemEdit;
