import React from 'react';
import SVG from 'react-inlinesvg';

import bellIcon from 'assets/images/bell.svg';
import { useScheduleBlockPosition } from './hooks/useScheduleBlockPosition';
import { ScheduleItemTemplatePartsOfADayDefinition } from '../../../../index';
import arrowRepeatCrossedOutIcon from 'assets/images/arrow-repeat-crossed-out.svg';
import { Progress } from 'reactstrap';

type ScheduleBlockProps = {
  partOfADay: ScheduleItemTemplatePartsOfADayDefinition;
  onEdit?: () => void;
  onResponses?: () => void;
  disabled: boolean;
  completionPercent?: number;
};

export enum ScheduleBlockVariant {
  Standard = 'Standard',
  Condensed = 'Condensed',
}

const ScheduleBlock = ({
  partOfADay,
  onEdit,
  onResponses,
  disabled,
  completionPercent = 0,
}: ScheduleBlockProps) => {
  const { variant, positionStyle } = useScheduleBlockPosition(partOfADay);

  const handleClick = () => {
    if (disabled) return;

    if (onResponses) {
      onResponses();
      return;
    }

    if (onEdit) onEdit();
  };

  if (variant === ScheduleBlockVariant.Condensed) {
    return (
      <div
        className="ScheduleBlock ScheduleBlock--condensed"
        style={{
          ...positionStyle,
          cursor: onEdit ? 'pointer' : 'default',
        }}
        onClick={handleClick}
      >
        <SVG
          className="ScheduleBlock__icon"
          src={bellIcon}
          title="notification"
        />
        <div className="ScheduleBlock__progress">
          <Progress color="primary" value={completionPercent} />
        </div>
      </div>
    );
  }

  return (
    <div
      className="ScheduleBlock"
      style={{
        ...positionStyle,
        cursor: onEdit ? 'pointer' : 'default',
      }}
      onClick={handleClick}
    >
      {partOfADay.is_dirty && (
        <div className="ScheduleBlock__dirty-badge">
          <SVG src={arrowRepeatCrossedOutIcon} />
        </div>
      )}
      <span className="ScheduleBlock__hours-range">
        {`${partOfADay.available_from_hour} - ${partOfADay.available_to_hour}`}
      </span>
      <div className="ScheduleBlock__notification">
        <SVG
          className="ScheduleBlock__icon"
          src={bellIcon}
          title="notification"
        />
        <span className="ScheduleBlock__notification-time">
          {partOfADay.notification_hour}
        </span>
      </div>
      <div className="ScheduleBlock__progress">
        <Progress
          style={{ height: '5px' }}
          color="primary"
          value={completionPercent}
        />
      </div>
    </div>
  );
};

export default ScheduleBlock;
