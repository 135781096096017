import React from 'react';
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';

//types
import {
  IOrganizationUserSubmission,
  SubmissionStatus,
} from 'interfaces/submissions';

//helpers
import { formatDate } from 'helpers/dates';

//helpers
import useOpen from 'hooks/useOpen';
import useModifySubmissionStatus from 'api/mutations/submissions/useModifySubmissionStatus';
import useModifySubmissionCompletedInfo from 'api/mutations/submissions/useModifySubmissionCompletedInfo';

//components
import { AssessmentModalFormPropsWithUserId } from './ProjectUsersList';
import ProjectUsersListRowStatusLabel from './ProjectUsersListRowStatusLabel';
import ConfirmModal from 'components/ConfirmModal';
import ProjectUsersListStatusDropdown from './ProjectUsersListStatusDropdown';

//images
import notepad from 'assets/images/notepad.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons';

type ProjectUsersListItemProps = {
  submission: IOrganizationUserSubmission;
  organizationId: string;
  rowSelectionProps: any;
  changeFormSequence: (value: number | null) => void;
  changeAssessmentFormDataProps: (
    value: AssessmentModalFormPropsWithUserId | null
  ) => void;
  refetch: () => void;
};

enum ScoreStatus {
  POSITIVE = 'positive',
  NEUTRAL = 'neutral',
  NEGATIVE = 'negative',
}

const returnScoreColor = (score: number) => {
  if (score >= 70) return ScoreStatus.POSITIVE;
  else if (score > 50) return ScoreStatus.NEUTRAL;
  else return ScoreStatus.NEGATIVE;
};

const MAXIMUM_SCORE_POINTS = 100;

const ProjectUsersListItem = ({
  submission,
  organizationId,
  changeFormSequence,
  changeAssessmentFormDataProps,
  refetch,
  rowSelectionProps,
}: ProjectUsersListItemProps) => {
  const { isOpen: confirmationModalOpen, toggle: confirmationModalToggle } =
    useOpen();

  const handleModifyCompletion = () => {
    refetch();
    confirmationModalToggle();
  };

  const { mutate: modifyStatusMutation } = useModifySubmissionStatus(
    organizationId,
    refetch
  );

  const { mutate: modifyCompletionMutation } = useModifySubmissionCompletedInfo(
    organizationId,
    handleModifyCompletion
  );

  const handleCloseSubmission = () => {
    modifyCompletionMutation({
      submissionId: submission.id,
      completed: true,
    });
  };

  const handleStatusChange = (status: SubmissionStatus) => {
    modifyStatusMutation({
      submissionId: submission.id,
      status: status,
    });
  };

  const handleToggleItem = () => {
    rowSelectionProps.toggleRow({
      id: submission.id,
      email: submission.personality.email,
      first_name: submission.personality.first_name,
      personality_id: submission.personality_id,
      user_id: submission.user_id,
    });
  };

  const onViewClick = () => {
    changeAssessmentFormDataProps({
      userId: submission.user_id,
      submissionId: submission.id,
      selectedDate: new Date(submission.date_from),
    });
    changeFormSequence(0);
  };

  const { toggle: toggleDropdown, isOpen: isDropdownOpen } = useOpen(false);

  return (
    <>
      <tr key={submission.id}>
        <td>
          <input
            type="checkbox"
            checked={rowSelectionProps.isRowSelected(submission.id)}
            onChange={handleToggleItem}
          />
        </td>
        <td>
          {submission.personality?.first_name}{' '}
          {submission.personality?.last_name}
        </td>
        <td>{submission.personality?.email}</td>
        <td>{submission.personality?.phone_number}</td>
        <td>
          {formatDate(submission.sign_up_date, {
            ignoreTime: true,
          })}
        </td>
        <td>{submission.project.title}</td>
        <td
          className={`ProjectUsersList__table__points ProjectUsersList__table__points--${returnScoreColor(
            submission.score
          )}`}
        >
          {submission.score} / {MAXIMUM_SCORE_POINTS}
        </td>

        <td>
          {submission.completed ? (
            <ProjectUsersListRowStatusLabel status={submission.status} />
          ) : (
            <ProjectUsersListStatusDropdown
              submission={submission}
              handleStatusChange={handleStatusChange}
            />
          )}
        </td>
        <td>
          <Button
            color={submission.completed ? 'secondary' : 'primary'}
            disabled={submission.completed}
            onClick={confirmationModalToggle}
            className="ProjectUsersList__table__close-submission"
          >
            {submission.completed ? (
              <FormattedMessage id="Projects.usersList.closedSubmission" />
            ) : (
              <FormattedMessage id="Projects.usersList.closeSubmission" />
            )}
          </Button>
        </td>
        <td>
          <Dropdown
            direction="start"
            isOpen={isDropdownOpen}
            toggle={toggleDropdown}
            className="ProjectUsersList__table__actions"
          >
            <DropdownToggle color="primary" outline={true}>
              <FontAwesomeIcon icon={faEllipsisV} />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={onViewClick}>
                <img src={notepad} alt="notepad" />
                <FormattedMessage id="Projects.usersList.seeFormAnswers" />
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </td>
      </tr>
      <ConfirmModal
        message={
          <FormattedMessage id="Projects.usersList.closingConfirmation" />
        }
        isOpen={confirmationModalOpen}
        toggle={confirmationModalToggle}
        confirm={handleCloseSubmission}
      />
    </>
  );
};

export default ProjectUsersListItem;
