import { formatDate } from 'helpers/dates';
import { IAssessmentFormAssignment } from 'interfaces/assessmentForms';
import { useIntl } from 'react-intl';

type AssignmentInfoProps = {
  assignment?: IAssessmentFormAssignment;
  title: string;
};

const AssignmentHeaderInfo = ({ assignment, title }: AssignmentInfoProps) => {
  const intl = useIntl();
  let schedule = '';
  let time = '';

  if (assignment?.scheduleInfo.custom) {
    schedule = assignment?.scheduleInfo.custom
      .map((scheduleInfo) => {
        return `${formatDate(new Date(scheduleInfo.date).toISOString(), {
          ignoreTime: true,
        })} ${intl.formatMessage({
          id: 'General.at',
        })} ${scheduleInfo.hours.join(', ')}`;
      })
      .join(', ');
  } else if (assignment?.scheduleInfo.daily) {
    schedule = `${intl.formatMessage({
      id: 'General.everydayAt',
    })} `;
    time = assignment.scheduleInfo.daily.hours.join(', ');
  } else if (assignment?.scheduleInfo.weekly) {
    schedule = `${assignment?.scheduleInfo.weekly.length} ${intl.formatMessage({
      id: 'General.timesAWeek',
    })} `;
    time = assignment.scheduleInfo.weekly
      .map((weekly) => weekly.hours)
      .join(` ${intl.formatMessage({ id: 'General.or' })} `);
  }

  return (
    <div className="StatusCheck__assessment-forms__group__header">
      <h3 className="StatusCheck__assessment-forms__group__header__title">
        {title}
      </h3>
      <div className="StatusCheck__assessment-forms__group__header__time">
        {schedule}
        {time}
      </div>
    </div>
  );
};

export default AssignmentHeaderInfo;
