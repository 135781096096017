import {
  OrganizationQueryParams,
  SearchAndPagingQueryParams,
} from '../../../interfaces/queryParams';
import { IQueryRequest } from '../../../interfaces/request';
import { useInfiniteQuery } from 'react-query';
import { IError, IInfiniteResult } from '../../../interfaces/response';
import {
  convertIntoObjectWithPageInfo,
  returnAllDataFromResponse,
} from '../../../helpers/utils/reactQueryHelpers';
import QueryKeys from '../queryKeys';
import { IAwareSessionData } from '../../../interfaces/awareSessions';
import { getOrganizationsAwareSessions } from '../../awareSessions';

type InfiniteAwareSessionsParams = OrganizationQueryParams &
  SearchAndPagingQueryParams & {
    aggregated: boolean;
  };

function useInfiniteAwareSessions({
  params,
  options,
}: IQueryRequest<InfiniteAwareSessionsParams>) {
  const {
    organizationId,
    searchValue = '',
    pageSize = 20,
    aggregated,
  } = params;

  const response = useInfiniteQuery<
    IInfiniteResult<IAwareSessionData[]>,
    IError
  >({
    ...options,
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getOrganizationsAwareSessions(
        organizationId,
        {
          page_size: pageSize,
          page: pageParam,
          search: searchValue,
          sort: 'CreationDesc',
        },
        aggregated
      );

      return convertIntoObjectWithPageInfo<IAwareSessionData[]>(data);
    },
    queryKey: [
      QueryKeys.getInfiniteAwareSessionData,
      organizationId,
      pageSize,
      searchValue,
      aggregated,
    ],
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage;
    },
    getPreviousPageParam: (firstPage) => firstPage.previousPage,
  });

  return {
    awareSessions: returnAllDataFromResponse<IAwareSessionData>(response),
    totalItemsCount: response.data?.pages[0].totalItemsCount ?? 0,
    ...response,
  };
}

export default useInfiniteAwareSessions;
