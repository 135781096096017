import React from 'react';
import { BaseModalProps } from '../../../../interfaces/ui';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { useTranslations } from '../../../../hooks/useTranslations';
import {
  IAsset,
  IEducationMaterial,
} from '../../../../interfaces/educationMaterials';
import { usePreview } from './hooks/usePreview';
import AssetPreview from './components/AssetPreview';
import AssetThumbnailUpload from './components/AssetThumbnailUpload';
import AssetForm from './components/AssetForm';
import { useCommonData } from '../../hooks/useCommonData';
import classNames from 'classnames';

type AssetModalProps = BaseModalProps & {
  asset: IAsset;
  material: IEducationMaterial;
};

const AssetModal = ({ isOpen, toggle, asset, material }: AssetModalProps) => {
  const { attachPrefix } = useTranslations('Education.AssetModal');
  const preview = usePreview(asset);

  const { educationMaterials } = useCommonData();

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="GenericModal BorderlessModal Education__asset-modal AssetModal"
      size="lg"
    >
      <ModalHeader toggle={toggle}>
        <FormattedMessage
          id={attachPrefix('asset')}
          values={{ title: asset.name }}
        />
      </ModalHeader>
      <ModalBody
        className={classNames('AssetModal__body', {
          'opacity-25': educationMaterials.isRefetching,
        })}
      >
        <AssetPreview>{preview}</AssetPreview>
        <AssetThumbnailUpload asset={asset} />
        <AssetForm asset={asset} material={material} />
      </ModalBody>
    </Modal>
  );
};

export default AssetModal;
