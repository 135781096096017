import { ChangeEvent, useState } from 'react';
import useTimelineDetails from 'api/queries/timelines/useTimelineDetails';
import { formatDate, formattedDateToDateInput } from 'helpers/dates';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalHeader, Spinner } from 'reactstrap';
import AssessmentFormDefinition from './AssessmentFormDefinition';
import AssessmentFormFill from './AssessmentFormFill';
import notepadIcon from 'assets/images/notepad.svg';
import {
  setFormsDayByDayViewRefetchRequest,
  setFormsDayDetailsViewRefetchRequest,
} from '../../../../../../Cases/store/actions';
import { useDispatch } from 'react-redux';
import UseOrganizationAssessmentFormsTemplates from 'api/queries/assessmentForms/templates/useOrganizationAssessmentFormTemplates';

type NonAssignmentAssessmentFormModalProps = {
  isOpen: boolean;
  toggleModal: () => void;
  timelineId: string;
  organizationId: string;
  selectedDates: {
    from: Date;
    to: Date;
  };
};

export const returnTodayInputStringValue = () =>
  formattedDateToDateInput(
    formatDate(new Date().toISOString(), {
      ignoreTime: true,
      addSeconds: false,
    })
  );

export const returnTodayTimeValue = () => {
  const dateObj = new Date();
  return `${dateObj.getHours().toString().padStart(2, '0')}:${dateObj
    .getMinutes()
    .toString()
    .padStart(2, '0')}`;
};

enum NonAssignedAssessmentSteps {
  DEFINITION = 'definition',
  FORM_FILL = 'formFill',
}

const NonAssignmentAssessmentFormModal = ({
  isOpen,
  toggleModal,
  timelineId,
  organizationId,
}: NonAssignmentAssessmentFormModalProps) => {
  const dispatch = useDispatch();

  const { data: templates } = UseOrganizationAssessmentFormsTemplates({
    params: {
      allowedForCaseId: null,
      questionsIncludedInTrends: null,
      organizationId,
    },
  });

  const [step, setStep] = useState(NonAssignedAssessmentSteps.DEFINITION);

  const [selectedTemplate, setSelectedTemplate] = useState('');

  const onTemplateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelectedTemplate(e.target.value);
  };

  const [date, setDate] = useState(() => returnTodayInputStringValue());
  const onDateChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDate(e.target.value);
  };
  const dateObject = new Date(date);

  const [time, setTime] = useState(() => returnTodayTimeValue());
  const onTimeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setTime(e.target.value);
  };

  const { data: timelineDetails, isLoading: isTimelineLoading } =
    useTimelineDetails({ params: { timelineId } });

  const refetchDayByDayData = () => {
    dispatch(setFormsDayDetailsViewRefetchRequest(true));
    dispatch(setFormsDayByDayViewRefetchRequest(true));
  };

  const onModalClose = () => {
    setSelectedTemplate('');
    setDate(returnTodayInputStringValue());
    setTime(returnTodayTimeValue());
    setStep(NonAssignedAssessmentSteps.DEFINITION);
    toggleModal();
  };

  const nextStep = () => {
    setStep(NonAssignedAssessmentSteps.FORM_FILL);
  };

  const renderModalContent = () => {
    if (!templates)
      return (
        <div className="mt-4 mb-4 w-100 d-flex justify-content-center">
          <Spinner size="xl" />
        </div>
      );

    switch (step) {
      case NonAssignedAssessmentSteps.DEFINITION:
        return (
          <AssessmentFormDefinition
            date={date}
            onDateChange={onDateChange}
            onTemplateChange={onTemplateChange}
            onTimeChange={onTimeChange}
            selectedTemplate={selectedTemplate}
            templates={templates}
            time={time}
            nextStep={nextStep}
          />
        );
      case NonAssignedAssessmentSteps.FORM_FILL:
        if (isTimelineLoading) return <Spinner />;

        return (
          <AssessmentFormFill
            closeModal={onModalClose}
            dateObject={dateObject}
            selectedTemplate={selectedTemplate}
            templates={templates}
            time={time}
            userId={timelineDetails?.user_id ?? ''}
            timelineId={timelineId}
            refetchDayByDayData={refetchDayByDayData}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      toggle={onModalClose}
      className="NonAssignedAssessmentModal"
    >
      <ModalHeader toggle={onModalClose}>
        <img src={notepadIcon} alt={notepadIcon} className="me-2" />
        <FormattedMessage id="TimelineDetails.nonAssignedAssessmentModal.addAssessmentForm" />
      </ModalHeader>
      {renderModalContent()}
    </Modal>
  );
};

export default NonAssignmentAssessmentFormModal;
