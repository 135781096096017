import arrowsWidthIcon from 'assets/images/arrows_width.svg';
import { useTranslations } from 'hooks/useTranslations';
import React from 'react';
import SVG from 'react-inlinesvg';
import { Button } from 'reactstrap';
import { useTimelineDetailsDateRangeObject } from 'store/reducerHooks';
import PeriodRangeSelectionPlaceholder from './components/PeriodRangeSelectionPlaceholder';
import { useManagePeriodRange } from './hooks/useManagePeriodRange';
import { useRenderPeriodRangeItems } from './hooks/useRenderPeriodRangeItems';

const PeriodRangeSelection = () => {
  const { attachPrefix } = useTranslations(
    'Cases.ChangeCase.ChangeCaseDashboard.DateSelectionWrapper.PeriodRangeSelection'
  );

  const dateRangeObject = useTimelineDetailsDateRangeObject();

  const {
    selectedItems,
    casePhaseHistory,
    caseDetails,
    handleReset,
    isLoading,
  } = useManagePeriodRange(dateRangeObject);

  const renderedHistoryItems = useRenderPeriodRangeItems(
    caseDetails,
    casePhaseHistory,
    selectedItems
  );

  if (isLoading || !caseDetails) return <PeriodRangeSelectionPlaceholder />;

  return (
    <div className="ChangeCasePeriodSelector">
      <div className="ChangeCasePeriodSelector__items">
        {renderedHistoryItems}
      </div>
      <div className="ChangeCasePeriodSelector__actions">
        <Button
          className="ChangeCasePeriodSelector__revert"
          color="primary"
          outline={true}
          onClick={handleReset}
        >
          <SVG src={arrowsWidthIcon} title={attachPrefix('revertSelection')} />
        </Button>
      </div>
    </div>
  );
};

export default PeriodRangeSelection;
