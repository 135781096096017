import React from 'react';
import { ValidationStatus } from './types';
import { useValidateTemplate } from './hooks/useValidateTemplate';
import Validating from './placeholders/Validating';
import Response from './components/Response';
import Errors from './components/Errors';

type ValidationProps = {
  file: string;
};

const Validation = ({ file }: ValidationProps) => {
  const { status, error, response, validating } = useValidateTemplate(file);

  if (validating || status === ValidationStatus.Idle) return <Validating />;

  if (status === ValidationStatus.Success && response !== null)
    return <Response response={response} />;

  if (status === ValidationStatus.Error && error !== null)
    return <Errors error={error} />;

  return <></>;
};

export default Validation;
