import useRequiredParams from '../../../hooks/useRequiredParams';

export const useCommonParams = () => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  return {
    organizationId,
  };
};
