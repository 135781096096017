import { AnswerHistoryQuestion } from '../../../../../../../interfaces/change';
import { useMemo } from 'react';

export const useAnswerHistoryAverage = (question: AnswerHistoryQuestion) => {
  return useMemo(() => {
    let avg: number = 0;

    question.answer_history.forEach((history) => {
      avg += history.value ?? 0;
    });

    if (question.answer_history.length > 0)
      avg = avg / question.answer_history.length;

    return avg;
  }, [question.answer_history]);
};
