import { useCallback } from 'react';
import { useCookie } from '../../../../../../../hooks/useCookie';
import { ConversationQuestion } from '../../../../../../../interfaces/conversations';
import {
  DashboardDateSelection,
  DashboardFilter,
  DashboardTemplate,
  IDashboardTemplateContent,
} from '../../../../../../../interfaces/dashboards';
import { IGrasp } from '../../../../../../../interfaces/grasps';
import { IBaseOrganization } from '../../../../../../../interfaces/organizations';
import { BasePersonality } from '../../../../../../../interfaces/personalities';
import { ViewTheme } from '../../../../../../../interfaces/ui';
import { DashboardDataStates } from '../components/DashboardDataContent/store/state';
import { DashboardDatesHelpers } from '../helpers/dates';
import { DashboardTheme } from '../types';
import { useHandlePrepareFilters } from './useHandlePrepareFilters';

export const useInitDashboardData = (
  template: DashboardTemplate,
  questions: ConversationQuestion[],
  grasps: IGrasp[],
  members: BasePersonality[],
  organizations: IBaseOrganization[]
): (() => DashboardDataStates.DashboardDataState) => {
  const themeCookie = useCookie<ViewTheme>('theme');

  const handlePrepareFilters = useHandlePrepareFilters(
    questions,
    members,
    grasps,
    organizations
  );

  const prepareTheme = useCallback(() => {
    return themeCookie === ViewTheme.Dark
      ? DashboardTheme.Dark
      : DashboardTheme.Light;
  }, [themeCookie]);

  const prepareDataSource = useCallback(() => {
    return template.data_source;
  }, [template.data_source]);

  const prepareDashboardTemplate = useCallback((): IDashboardTemplateContent<
    DashboardFilter,
    DashboardDateSelection
  > => {
    const filters = handlePrepareFilters(template.content.filters);
    const dateSelection = DashboardDatesHelpers.GetDateSelectionBasedOnTemplate(
      template.content.date_selection
    );

    return {
      ...template.content,
      filters: filters,
      date_selection: dateSelection,
    };
  }, [handlePrepareFilters, template.content]);

  return useCallback(() => {
    return {
      id: template.id,
      path: template.path,
      template: prepareDashboardTemplate(),
      data_source: prepareDataSource(),
      theme: prepareTheme(),
    };
  }, [
    prepareDashboardTemplate,
    prepareDataSource,
    prepareTheme,
    template.id,
    template.path,
  ]);
};
