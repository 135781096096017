import useTimelineObservationFetch from 'api/queries/timelines/useUserContextTimelineObservations';
import { differenceInDays } from 'date-fns';
import {
  returnObservationsGroupedByMeaning,
  returnUniqueMeanings,
} from 'helpers/utils/timelineHelpers';
import React from 'react';
import MeaningItem from '../../DayByDayTab/components/DayDetails/tabs/Summary/MeaningItem';
import MeaningSummary from '../../DayByDayTab/components/DayDetails/tabs/Summary/MeaningSummary';
import SqueezeSummaryEmpty from './SqueezeSummaryEmpty';
import SqueezeSummaryPlaceholder from './SqueezeSummaryPlaceholder';

type PeriodSummaryChartProps = {
  timelineId: string;
  selectedDates: {
    from: Date;
    to: Date;
  };
};

const PeriodSummaryChart = ({
  timelineId,
  selectedDates,
}: PeriodSummaryChartProps) => {
  const isBiggerThan30Days =
    differenceInDays(selectedDates.to, selectedDates.from) > 30;

  const { data: observations, isLoading } = useTimelineObservationFetch({
    params: {
      timelineId,
      selectedDates,
      grouped: isBiggerThan30Days,
    },
  });

  const uniqueMeanings = returnUniqueMeanings(observations);

  const groupByMeaning = returnObservationsGroupedByMeaning(
    observations,
    uniqueMeanings
  );

  const renderedMeanings = groupByMeaning.map(({ data, name }) => {
    return <MeaningItem key={name} name={name} data={data} oneRow />;
  });

  const hasAnyValue = !isLoading && observations && observations.length > 0;

  let chartElement: JSX.Element | null;
  if (isLoading) chartElement = <SqueezeSummaryPlaceholder />;
  else if (hasAnyValue) {
    chartElement = (
      <MeaningSummary
        summaryText="Total Observations"
        innerRadius={100}
        width={280}
        height={280}
        meaningNames={uniqueMeanings}
        observations={observations}
      />
    );
  } else
    chartElement = (
      <SqueezeSummaryEmpty
        innerRadius={100}
        width={280}
        height={280}
        summaryText="Total Observations"
      />
    );

  return (
    <div className="DayDetails__container">
      {chartElement}
      {renderedMeanings}
    </div>
  );
};

export default PeriodSummaryChart;
