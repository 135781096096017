import React, { useMemo } from 'react';
import { AssessmentQuestionHistoryEntryItem } from '../../../../../../../interfaces/cases';
import classNames from 'classnames';

type AssessmentFormChartLegendProps = {
  questions: AssessmentQuestionHistoryEntryItem[];
  displayedQuestions: string[];
  setDisplayedQuestions: (displayed: string[]) => void;
};

const AssessmentFormChartLegend = ({
  questions,
  setDisplayedQuestions,
  displayedQuestions,
}: AssessmentFormChartLegendProps) => {
  const renderQuestions = useMemo(() => {
    return questions.map((question) => {
      const isDisplayed = displayedQuestions.includes(question.questionId);

      const handleDisplayQuestion = () => {
        if (isDisplayed) {
          const filtered = displayedQuestions.filter(
            (p) => p !== question.questionId
          );

          if (filtered.length !== 0) {
            setDisplayedQuestions(filtered);
          }

          return;
        }

        setDisplayedQuestions([...displayedQuestions, question.questionId]);
      };

      return (
        <li
          className={classNames({
            AssessmentFormChartLegend__item: true,
            'AssessmentFormChartLegend__item--active': isDisplayed,
          })}
          onClick={handleDisplayQuestion}
        >
          <div
            className={`AssessmentFormChartLegend__item__indicator`}
            style={{ backgroundColor: question.questionColor }}
          ></div>
          <div>{question.questionTitle}</div>
        </li>
      );
    });
  }, [displayedQuestions, questions, setDisplayedQuestions]);

  return (
    <div className="d-flex flex-column align-items-center mt-4 ms-1">
      <ul className="AssessmentFormChartLegend">{renderQuestions}</ul>
    </div>
  );
};

export default AssessmentFormChartLegend;
