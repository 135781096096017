import classNames from 'classnames';
import React from 'react';
import { useTranslations } from '../../../../../../../../hooks/useTranslations';
import { useTypedContext } from '../../../../../../../../hooks/useTypedContext';
import EditableInput from '../../../../../../../EditableInput/EditableInput';
import InputFieldWithoutImage from '../../../../../../../InputFieldWithoutImage';
import { DashboardDataReducerContext, DashboardTheme } from '../../types';
import { useDateSelectorOptions } from './hooks/useDateSelectorOptions';
import { useHandleChangeDatePeriod } from './hooks/useHandleChangeDatePeriod';
import { DashboardDatePeriod } from '../../../../../../../../interfaces/dashboards';
import DashboardDateRangeFilter from '../DashboardFilters/components/DashboardDateRangeFilter';

const DashboardDateSelector = () => {
  const [dashboardData] = useTypedContext(DashboardDataReducerContext);

  const { attachPrefix, wrapWithIntl } = useTranslations(
    'Organizations.OrganizationDashboard.AwareCasesDashboard.AwareCasesDateSelector'
  );

  const options = useDateSelectorOptions();
  const handleChangeDatePeriod = useHandleChangeDatePeriod();

  return (
    <div
      className={classNames('AwareCasesDateSelector', {
        'AwareCasesDateSelector--dark':
          dashboardData.theme === DashboardTheme.Dark,
      })}
    >
      <InputFieldWithoutImage gray={true} noMargin={true}>
        <EditableInput
          className="AwareCasesDateSelector__selector"
          type="select"
          options={options}
          onEdit={handleChangeDatePeriod}
          value={dashboardData.template.date_selection.date_period}
          placeholder={wrapWithIntl(attachPrefix('selectPeriod'))}
          debounceEnabled={false}
        />
      </InputFieldWithoutImage>
      {dashboardData.template.date_selection.date_period ===
        DashboardDatePeriod.CustomPeriod && (
        <div className="AwareCasesFilters d-flex d-md-none">
          <div className="AwareCasesFilters__date-range">
            <DashboardDateRangeFilter />
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardDateSelector;
