import { BasePersonality } from './personalities';

export interface IBaseOrganization {
  id: string;
  name: string;
  code: string;
  logo_url: string;
  image_url: string;
  description: string | null;
  status: OrganizationStatus;
  is_admin_organization: boolean;
  created_at: string;
}

export interface IOrganization extends IBaseOrganization {
  role: string[];
  type: OrganizationType;
  functionalities: string[];
}

export interface IOrganizationDevice {
  id: string;
  name: string;
  type: OrganizationDeviceType;
  serial_number: string;
  apple_id: string;
  apple_password: string;
  screen_lock_code: string;
}

export enum OrganizationDeviceType {
  Ipad = 'Ipad',
  Speaker = 'Speaker',
  Other = 'Other',
}

export enum OrganizationStatus {
  Active = 'Active',
  NotActive = 'NotActive',
}

export enum OrganizationLicense {
  Standard = 'Standard',
}

export enum OrganizationUploadTarget {
  Image = 'Image',
  Logo = 'Logo',
}

export enum OrganizationType {
  HealthcareProvider = 'healthcare_provider',
  EducationalInstitute = 'educational_institute',
  NonHealthcareBusinessOrCorporation = 'non_healthcare_business_or_corporation',
  Other = 'other',
  DentalClinic = 'dental_clinic',
}

export interface ICreateOrganizationDevice {
  name: string;
  type: OrganizationDeviceType;
  serial_number: string;
  apple_id: string;
  apple_password: string;
  screen_lock_code: string;
}

export interface ICreateOrganizationUser {
  first_name: string;
  last_name: string;
  user_name: string;
  culture: string;
  email: string;
  access_profile_id: string;
  owner_organization_id?: string;
}

export interface IOrganizationMembers {
  organization: IBaseOrganization;
  members: BasePersonality[];
}
