import React, { useCallback, useMemo, useState } from 'react';
import DayDetailsTab from '../components/DayDetailsTab';
import TrendsTab from '../components/TrendsTab';

export enum CaseDashboardTabsValues {
  Trends = 'Trends',
  DayDetails = 'DayDetails',
}

export const mappedCaseDashboardTabsValues = [
  CaseDashboardTabsValues.Trends,
  CaseDashboardTabsValues.DayDetails,
];

export const useTabs = () => {
  const [selectedTab, setSelectedTab] = useState<CaseDashboardTabsValues>(
    CaseDashboardTabsValues.DayDetails
  );

  const shouldBeRendered = useCallback(
    (tab: CaseDashboardTabsValues) => {
      return selectedTab === tab;
    },
    [selectedTab]
  );

  const CurrentTab = useMemo((): React.FC => {
    if (shouldBeRendered(CaseDashboardTabsValues.Trends)) return TrendsTab;

    if (shouldBeRendered(CaseDashboardTabsValues.DayDetails))
      return DayDetailsTab;

    return React.Fragment;
  }, [shouldBeRendered]);

  const onTabChange = (val: number) => {
    setSelectedTab(mappedCaseDashboardTabsValues[val]);
  };

  return {
    onTabChange,
    CurrentTab,
    selectedTab,
  };
};
