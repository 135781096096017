import AddIcon from '@mui/icons-material/Add';
import IconButton from 'components/IconButton';
import React, { useMemo } from 'react';
import TabLayout, {
  TabLayoutType,
} from '../../../../components/layouts/TabLayout';
import { useInitQuery } from '../../../../hooks/queries/useInitQuery';
import { useCommonData } from '../../hooks/useCommonData';
import { useCommonParams } from '../../hooks/useCommonParams';
import EducationMaterialCategoriesTree from './components/EducationMaterialCategoriesTree';
import EducationMaterialCategoriesTreeEmpty from './components/EducationMaterialCategoriesTreeEmpty';
import EducationMaterialCategoriesTreePlaceholder from './components/EducationMaterialCategoriesTreePlacholder';
import { useManageEducationMaterialCategory } from './hooks/useManageEducationMaterialCategory';
import AddEducationMaterialItemModal from './modals/AddEducationMaterialItemModal';
import EducationMaterialCategoryModal from './modals/EducationMaterialCategoryModal';
import LinkMaterialWithCategoryModal from './modals/LinkMaterialWithCategoryModal';

type EducationMaterialCategoriesTabProps = {
  showTitle?: boolean;
  showSubtitle?: boolean;
};

const EducationMaterialCategoriesTab = ({
  showTitle = true,
  showSubtitle = true,
}: EducationMaterialCategoriesTabProps) => {
  const { educationMaterialCategoriesTree, educationMaterialCategories } =
    useCommonData();

  const { organizationId } = useCommonParams();

  const { edit, remove, addItem, link } = useManageEducationMaterialCategory();

  useInitQuery(educationMaterialCategoriesTree);
  useInitQuery(educationMaterialCategories);

  const renderTree = useMemo(() => {
    if (
      educationMaterialCategoriesTree.isFetching &&
      !educationMaterialCategoriesTree.isRefetching
    )
      return <EducationMaterialCategoriesTreePlaceholder />;

    if (
      educationMaterialCategoriesTree.isFetched &&
      educationMaterialCategoriesTree.data?.length === 0
    )
      return <EducationMaterialCategoriesTreeEmpty />;

    return educationMaterialCategoriesTree.data?.map((item) => (
      <EducationMaterialCategoriesTree
        key={item.id}
        item={item}
        handleEditCategory={
          organizationId !== item.organization_id ? undefined : edit.handle
        }
        handleDeleteCategory={
          organizationId !== item.organization_id ? undefined : remove.handle
        }
        deleteCategoryLoading={remove.isLoading}
        handleAddItem={
          organizationId !== item.organization_id ? undefined : addItem.handle
        }
        handleLinkMaterial={
          organizationId !== item.organization_id ? undefined : link.handle
        }
      />
    ));
  }, [
    addItem.handle,
    edit.handle,
    educationMaterialCategoriesTree.data,
    educationMaterialCategoriesTree.isFetched,
    educationMaterialCategoriesTree.isFetching,
    educationMaterialCategoriesTree.isRefetching,
    link.handle,
    organizationId,
    remove.handle,
    remove.isLoading,
  ]);

  return (
    <>
      {edit.isOpen && (
        <EducationMaterialCategoryModal
          isOpen={edit.isOpen}
          toggle={edit.toggle}
          category={edit.category}
        />
      )}
      {addItem.isOpen && addItem.category && (
        <AddEducationMaterialItemModal
          isOpen={addItem.isOpen}
          toggle={addItem.toggle}
          category={addItem.category}
        />
      )}
      {link.isOpen && link.category && (
        <LinkMaterialWithCategoryModal
          isOpen={link.isOpen}
          toggle={link.toggle}
          category={link.category}
        />
      )}
      <TabLayout
        type={TabLayoutType.Generic}
        titlePrefix={
          showTitle ? 'Education.EducationMaterialCategories' : undefined
        }
        subHeader={showSubtitle}
        actions={
          <IconButton
            variant="outlined"
            color="primary"
            disabled={educationMaterialCategoriesTree.isLoading}
            onClick={edit.toggle}
          >
            <AddIcon />
          </IconButton>
        }
      >
        <div className="mt-3 pe-4">{renderTree}</div>
      </TabLayout>
    </>
  );
};

export default EducationMaterialCategoriesTab;
