import React from 'react';
import { FormattedMessage } from 'react-intl';
import SVG from 'react-inlinesvg';
import phoneFrame from '../../../../../../assets/images/conversation_templates/phone_frame.svg';
import { ConversationTemplate } from '../../../../../../interfaces/conversations';
import Header from './components/Header';
import Conversation from './components/Conversation';
import { useTranslations } from '../../../../../../hooks/useTranslations';
import { AvailableCulture } from '../../../../../../hooks/cultures/useCultures';
import {
  ConversationTemplatePhoneItemActions,
  ItemActionsContext,
  LanguageContext,
} from './types';

type ConversationTemplatePhoneProps = {
  value: ConversationTemplate;
  language: AvailableCulture;
  demonstrative?: boolean;
  actions?: ConversationTemplatePhoneItemActions;
};

const ConversationTemplatePhone = ({
  value,
  language,
  actions,
  demonstrative = false,
}: ConversationTemplatePhoneProps) => {
  const { attachPrefix } = useTranslations(
    'ConversationsAndFeedbacks.ConversationTemplates.ConversationTemplatePhone'
  );

  return (
    <div className="ConversationTemplatePhone">
      <LanguageContext.Provider value={language}>
        <div className="ConversationTemplatePhone__view ConversationTemplatePhoneView">
          <SVG src={phoneFrame} />
          <div className="ConversationTemplatePhoneView__header">
            <Header />
          </div>
          <div className="ConversationTemplatePhoneView__conversation">
            <ItemActionsContext.Provider value={actions}>
              <Conversation value={value} />
            </ItemActionsContext.Provider>
          </div>
        </div>
        {demonstrative && (
          <span className="ConversationTemplatePhone__text">
            <FormattedMessage id={attachPrefix('demonstrativeView')} />
          </span>
        )}
      </LanguageContext.Provider>
    </div>
  );
};

export default ConversationTemplatePhone;
