import { useCallback } from 'react';
import { useCommonParams } from '../../../../../../../../../../../../../../../../../../../hooks/useCommonParams';
import { useTypedContext } from '../../../../../../../../../../../../../../../../../../../../../hooks/useTypedContext';
import { ReactStateTuple } from '../../../../../../../../../../../../../../../../../../../../../interfaces/ui';
import { ValidationResult } from '../../../../../../../../../../../../../types';
import { ValidationContext } from '../../../../../../../../../types';
import { cloneDeep } from 'lodash';

export const useHandleAssign = (onResolve: () => void) => {
  const { organizationId } = useCommonParams();

  const [validation, setValidation] =
    useTypedContext<ReactStateTuple<ValidationResult>>(ValidationContext);

  return useCallback(() => {
    let validationCopy = cloneDeep(validation);

    validationCopy.template = {
      ...validationCopy.template,
      organization_id: organizationId,
    };

    validationCopy.ready_to_complete = false;
    setValidation(validationCopy);
    onResolve();
  }, [onResolve, organizationId, setValidation, validation]);
};
