import {
  ScheduleInfo,
  ScheduleInfoWithCustomOrdered,
} from 'interfaces/assessmentForms';
import React from 'react';
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';

import calendarIcon from 'assets/images/calendar_icon.svg';
import { FormattedMessage } from 'react-intl';
import { isValid, parseISO } from 'date-fns';
import CustomCalendarInput from 'components/CustomCalendarInput';

export enum DateSelectionType {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  CUSTOM = 'Custom',
}

const DATE_TO_NAME = 'date-to';
const DATE_FROM_NAME = 'date-from';

type DateSelectorProps = {
  info?: ScheduleInfoWithCustomOrdered;
  setInfo: (info: ScheduleInfoWithCustomOrdered) => void;
  dateTo: Date | null;
  dateFrom: Date;
  setDateTo: (date: Date | null) => void;
  setDateFrom: (date: Date) => void;
};

const DateSelector = ({
  info,
  setInfo,
  dateTo,
  dateFrom,
  setDateTo,
  setDateFrom,
}: DateSelectorProps) => {
  const getDateSelectionTypeBasedOnSchedule = (
    schedule: ScheduleInfo | undefined
  ) => {
    if (schedule) {
      if (schedule.custom !== null) {
        return DateSelectionType.CUSTOM;
      }

      if (schedule.daily !== null) {
        return DateSelectionType.DAILY;
      }

      if (schedule.weekly !== null) {
        return DateSelectionType.WEEKLY;
      }
    }
  };

  const handleSelectionType = (type: DateSelectionType) => {
    switch (type) {
      case DateSelectionType.CUSTOM:
        setInfo({
          daily: null,
          weekly: null,
          custom: [],
        });
        return;
      case DateSelectionType.DAILY:
        setInfo({
          daily: {
            dayInterval: 1,
            hours: [],
          },
          weekly: null,
          custom: null,
        });
        return;
      case DateSelectionType.WEEKLY:
        setInfo({
          daily: null,
          custom: null,
          weekly: [],
        });
        return;
    }
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const valid = isValid(parseISO(e.target.value));

    if (valid) {
      const date = new Date(e.target.value);

      if (e.target.name === DATE_TO_NAME) {
        setDateTo(date);
      } else {
        setDateFrom(date);
      }
    }
  };

  return (
    <Row className="DateSelector mb-2">
      <Col md={4}>
        <UncontrolledDropdown className="DateSelector__form">
          <DropdownToggle
            tag="div"
            caret
            block
            className="DateSelector__form__container"
          >
            <span className="DateSelector__form__container__label">
              <FormattedMessage id="CaseCard.FormsAssignmentsModal.repeat" />
            </span>
            <div className="DateSelector__form__container__input">
              {info === undefined ? (
                <>
                  <img src={calendarIcon} alt="Date Selection Type" />
                  <span>
                    <FormattedMessage id="CaseCard.FormsAssignmentsModal.completionFrequencyInput" />
                  </span>
                </>
              ) : (
                <>
                  <img
                    src={calendarIcon}
                    alt={getDateSelectionTypeBasedOnSchedule(info)}
                  />
                  <span>{getDateSelectionTypeBasedOnSchedule(info)}</span>
                </>
              )}
            </div>
          </DropdownToggle>
          <DropdownMenu>
            {Object.values(DateSelectionType).map((type) => (
              <DropdownItem onClick={() => handleSelectionType(type)}>
                {type}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </Col>
      <Col md={4}>
        {info !== undefined && info.custom === null && (
          <div className="DateSelector__date">
            <label className="DateSelector__date__label" htmlFor="since_when">
              <FormattedMessage id="CaseCard.FormsAssignmentsModal.sinceWhen" />
            </label>
            <div className="DateSelector__date__input">
              <CustomCalendarInput
                onChange={handleDateChange}
                id="since_when"
                disabled={true}
                defaultDateString={dateFrom.toString()}
                name={DATE_FROM_NAME}
              />
            </div>
          </div>
        )}
      </Col>
      <Col md={4}>
        {info !== undefined && info.custom === null && (
          <div className="DateSelector__date">
            <label className="DateSelector__date__label" htmlFor="to_date">
              <FormattedMessage id="CaseCard.FormsAssignmentsModal.forHowLong" />
            </label>
            <div className="DateSelector__date__input">
              <CustomCalendarInput
                onChange={handleDateChange}
                id="to_date"
                defaultDateString={
                  dateTo !== null ? dateTo.toString() : undefined
                }
                name={DATE_TO_NAME}
              />
            </div>
          </div>
        )}
      </Col>
    </Row>
  );
};

export default DateSelector;
