import CustomTable from 'components/CustomTable';
import IndeterminateCheckbox from 'components/IndeterminateCheckbox';

//types
import {
  AssessmentModalFormProps,
  IOrganizationUserSubmission,
  IProjectSubmissionFilters,
  SubmissionAssessmentModalModes,
} from 'interfaces/submissions';

//components
import ProjectAssessmentModal from 'modules/ProjectSubmissions/components/ProjectAssessmentModal/ProjectAssessmentModal';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import useInfiniteProjectUsers from '../../../../api/queries/projects/useInfiniteProjectUsers';
import InfiniteListLayoutLoader from '../../../../components/InfiniteList/InfiniteListLayoutLoader';
import { GroupSelectionWithParticipantsInfo } from '../../../../components/SendMessageToGroupModal';
import { IRowSelection } from '../../../../hooks/useRowSelection';
import ProjectUsersListItem from './ProjectUsersListItem';
import ProjectUsersListPlaceholder from './ProjectUsersListPlaceholder';

export interface AssessmentModalFormPropsWithUserId
  extends Omit<AssessmentModalFormProps, 'sequence'> {
  userId: string;
}

type ProjectUsersListProps = {
  organizationId: string;
  searchValue?: string;
  globalFilters?: IProjectSubmissionFilters | null;
  setTotalItemsCount: React.Dispatch<React.SetStateAction<number>>;
  setSubmissions: React.Dispatch<
    React.SetStateAction<IOrganizationUserSubmission[]>
  >;
  rowSelectionProps: IRowSelection<GroupSelectionWithParticipantsInfo>;
};

const ProjectUsersList = ({
  searchValue,
  organizationId,
  globalFilters,
  setTotalItemsCount,
  setSubmissions,
  rowSelectionProps,
}: ProjectUsersListProps) => {
  const {
    submissions,
    isLoading,
    refetch,
    hasNextPage,
    fetchNextPage,
    totalItemsCount,
  } = useInfiniteProjectUsers({
    params: {
      organizationId: organizationId,
      searchValue: searchValue,
      pageSize: 25,
      filters: globalFilters ?? null,
    },
  });

  const [submissionPassed, setSubmissionPassed] = useState<boolean>(
    () => false
  );

  useEffect(() => {
    if (submissionPassed) return;

    setSubmissions(submissions);
    setTotalItemsCount(totalItemsCount);
    setSubmissionPassed(true);
  }, [
    setSubmissions,
    setTotalItemsCount,
    submissionPassed,
    submissions,
    totalItemsCount,
  ]);

  const [formSequence, setFormSequence] = useState<number | null>(null);

  const changeFormSequence = (val: number | null) => {
    setFormSequence(val);
  };

  const [assessmentFormDataProps, setAssessmentFormDataProps] =
    useState<AssessmentModalFormPropsWithUserId | null>(null);

  const changeAssessmentFormDataProps = (
    val: AssessmentModalFormPropsWithUserId | null
  ) => {
    setAssessmentFormDataProps(val);
  };

  const closeModal = () => {
    setFormSequence(null);
    setAssessmentFormDataProps(null);
  };

  const loadMore = () => fetchNextPage();

  if (isLoading) return <ProjectUsersListPlaceholder />;

  const userRows = submissions?.map((submission) => {
    return (
      <ProjectUsersListItem
        key={submission.id}
        changeFormSequence={changeFormSequence}
        changeAssessmentFormDataProps={changeAssessmentFormDataProps}
        organizationId={organizationId}
        refetch={refetch}
        submission={submission}
        rowSelectionProps={rowSelectionProps}
      />
    );
  });

  const headerItems = [
    <IndeterminateCheckbox
      key="project-users-list-checkbox"
      checked={rowSelectionProps.isSelectAllChecked}
      indeterminate={rowSelectionProps.isSelectAllIndeterminate}
      onChange={rowSelectionProps.toggleAllRows}
    />,
    'Projects.usersList.name',
    'Projects.usersList.email',
    'Projects.usersList.phone',
    'Projects.usersList.date',
    'Projects.usersList.project',
    'Projects.usersList.score',
    'Projects.usersList.status',
    'Projects.usersList.closeSubmission',
    null,
  ];

  return (
    <>
      <InfiniteScroll
        threshold={100}
        initialLoad={false}
        loader={
          <InfiniteListLayoutLoader
            key="project-users-list-loader"
            translationId="ProjectSubmissions.loadMore"
          />
        }
        hasMore={!isLoading && hasNextPage}
        loadMore={loadMore}
      >
        <span className="ListSelectionStatusText">
          {rowSelectionProps.selectionStatusText}
        </span>
        <CustomTable
          isResponsive={true}
          bodyRows={userRows}
          headerItems={headerItems}
        />
        {assessmentFormDataProps && formSequence !== null && (
          <ProjectAssessmentModal
            sequence={formSequence}
            fetchEnabled={true}
            closeModal={closeModal}
            mode={SubmissionAssessmentModalModes.DOCTOR}
            changeFormSequence={changeFormSequence}
            {...assessmentFormDataProps}
          />
        )}
      </InfiniteScroll>
    </>
  );
};

export default ProjectUsersList;
