import { useCallback } from 'react';
import { DateRange } from 'react-day-picker';
import { useTypedContext } from '../../../../../../../../../hooks/useTypedContext';
import { DashboardDataReducerContext } from '../../../types';
import { DashboardDataActions } from '../../DashboardDataContent/store/actions';

export const useHandleCustomDateRange = () => {
  const [, dispatch] = useTypedContext(DashboardDataReducerContext);

  return useCallback(
    (range: DateRange) => {
      if (!range.from || !range.to) return;

      dispatch({
        type: DashboardDataActions.DashboardDataActionType.EditDateRange,
        payload: { from: range.from, to: range.to },
      });
    },
    [dispatch]
  );
};
