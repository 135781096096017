import React from 'react';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';

const PeriodRangeSelectionPlaceholder = () => {
  return (
    <div className="ChangeCaseRangeSelector">
      <div className="ChangeCaseRangeSelector__select-type">
        <span
          className={classNames({
            'ChangeCaseRangeSelector__select-stage': true,
            'ChangeCaseRangeSelector__select-active': true,
          })}
        >
          <FormattedMessage id="Cases.ChangeCase.selectStage" />
        </span>
        <span
          className={classNames({
            'ChangeCaseRangeSelector__select-dates': true,
          })}
        >
          <FormattedMessage id="Cases.ChangeCase.selectDates" />
        </span>
      </div>
      <div className="ChangeCaseRangeSelector__select-range d-flex align-items-center gap-3">
        <Skeleton width={'20vw'} height={32} />
        <Skeleton width={'20vw'} height={32} />
        <Skeleton width={'20vw'} height={32} />
        <Skeleton width={'20vw'} height={32} />
      </div>
    </div>
  );
};

export default PeriodRangeSelectionPlaceholder;
