import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field } from 'formik';
import { cloneDeep } from 'lodash';
import React, { useEffect, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import { wrapWithSequenceIdentified } from '../../../../helpers/utils/data';
import { IConversationTreatmentPhaseContentItem } from '../../../../interfaces/conversations';
import FieldInput from '../../../FieldInput';
import InputFieldWithoutImage from '../../../InputFieldWithoutImage';
import ProjectPhaseAddTemplatePartOfADayFields from '../AddScheduleTemplatePartOfADayFields';

type AddScheduleTemplateRecurringFieldsProps = {
  values: IConversationTreatmentPhaseContentItem;
  setValues: (values: IConversationTreatmentPhaseContentItem) => void;
};

const AddScheduleTemplateRecurringFields = ({
  values,
  setValues,
}: AddScheduleTemplateRecurringFieldsProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const partsOfADay = useMemo(() => {
    return values.schedule_templates.at(0)?.parts_of_a_day ?? [];
  }, [values.schedule_templates]);

  const handleAddPartOfADay = () => {
    let valuesCopy = cloneDeep(values);

    if (!valuesCopy.schedule_templates.at(0)) return;

    valuesCopy.schedule_templates[0].parts_of_a_day = [
      ...valuesCopy.schedule_templates[0].parts_of_a_day,
      {
        notification_hour: '00:00',
        available_from_hour: '00:00',
        available_to_hour: '00:00',
      },
    ];

    setValues(valuesCopy);
  };

  const renderPartsOfADay = wrapWithSequenceIdentified(partsOfADay).map(
    (partOfADay, index) => {
      const handleRemove = () => {
        let valuesCopy = cloneDeep(values);
        let firstScheduleTemplate = valuesCopy.schedule_templates.at(0);

        if (!firstScheduleTemplate) return;

        valuesCopy.schedule_templates[0].parts_of_a_day =
          valuesCopy.schedule_templates[0].parts_of_a_day.filter(
            (p, idx) => idx !== index
          );
        setValues(valuesCopy);
      };

      return (
        <div
          key={partOfADay.sequence}
          className="TreatmentPhaseAddTemplateModal__row justify-content-evenly my-3"
        >
          <ProjectPhaseAddTemplatePartOfADayFields
            index={index}
            onRemove={partsOfADay.length === 1 ? undefined : handleRemove}
          />
        </div>
      );
    }
  );

  useEffect(() => {
    wrapperRef?.current?.scrollIntoView({ behavior: 'smooth' });
  }, [partsOfADay]);

  return (
    <>
      <div className="TreatmentPhaseAddTemplateModal__row">
        <InputFieldWithoutImage
          gray={true}
          noMargin={true}
          labelTranslationId="Projects.ProjectPhases.ProjectPhasesContent.ProjectPhase.ProjectPhaseAddTemplateModal.startDay"
          inputId="startDay"
        >
          <Field
            id="startDay"
            name="schedule_templates[0].start_day_offset"
            type="number"
            required={true}
            component={FieldInput}
          />
        </InputFieldWithoutImage>
        <InputFieldWithoutImage
          gray={true}
          noMargin={true}
          labelTranslationId="Projects.ProjectPhases.ProjectPhasesContent.ProjectPhase.ProjectPhaseAddTemplateModal.endDay"
          inputId="endDay"
        >
          <Field
            id="endDay"
            name="schedule_templates[0].end_day_offset"
            type="number"
            required={true}
            component={FieldInput}
          />
        </InputFieldWithoutImage>
        <InputFieldWithoutImage
          gray={true}
          noMargin={true}
          labelTranslationId="Projects.ProjectPhases.ProjectPhasesContent.ProjectPhase.ProjectPhaseAddTemplateModal.intervalInDays"
          inputId="intervalInDays"
        >
          <Field
            id="intervalInDays"
            name="schedule_templates[0].recurring_interval"
            type="number"
            required={true}
            component={FieldInput}
          />
        </InputFieldWithoutImage>
      </div>
      <div className="TreatmentPhaseAddTemplateModal__parts-of-a-day">
        <div className="TreatmentPhaseAddTemplateModal__parts-of-a-day-wrapper">
          {renderPartsOfADay}
          <div ref={wrapperRef}></div>
        </div>
        <div className="TreatmentPhaseAddTemplateModal__row mb-1">
          <Button
            color="primary"
            className="d-flex align-items-center gap-1"
            onClick={handleAddPartOfADay}
          >
            <FontAwesomeIcon icon={faPlus} />
            <FormattedMessage
              tagName="span"
              id="Projects.ProjectPhases.ProjectPhasesContent.ProjectPhase.ProjectPhaseAddTemplateModal.ProjectPhaseAddTemplateRecurringFields.addTime"
            />
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddScheduleTemplateRecurringFields;
