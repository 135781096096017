import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { IMutationRequest, IPatchObject } from '../../../interfaces/request';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { UserQueryParams } from '../../../interfaces/queryParams';
import { updateExerciseSession } from '../../exerciseSessions';

type UpdateExerciseSessionQueryParams = IMutationRequest<UserQueryParams> & {
  silent?: boolean;
};

const useUpdateExerciseSession = ({
  params,
  options,
  silent = false,
}: UpdateExerciseSessionQueryParams) => {
  return useMutationWithResultNotification<
    { id: string; patch: IPatchObject[] },
    void
  >({
    asyncApiFunction: async (updateData) => {
      await updateExerciseSession(
        params.userId,
        updateData.id,
        updateData.patch
      );
    },
    onError: () => {
      if (!silent) {
        displayErrorNotification(
          'Api.Mutations.ExerciseSessions.UpdateExerciseSession.failure'
        );
      }
      options.errorFb?.();
    },
    onSuccess: () => {
      if (!silent) {
        displaySuccessNotification(
          'Api.Mutations.ExerciseSessions.UpdateExerciseSession.success'
        );
      }
      options.successFb?.();
    },
    resetOnResult: true,
  });
};

export default useUpdateExerciseSession;
