import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AssessmentQuestion,
  AssessmentStatus,
} from 'interfaces/assessmentForms';
import { Sentiments } from 'interfaces/timeline';
import React from 'react';
import SVG from 'react-inlinesvg';

type StatusItemProps = {
  description: string;
  iconSrc: string;
  status: AssessmentStatus | Sentiments;
  question: AssessmentQuestion;
  optionsComponent?: JSX.Element;
};

const StatusItem = ({
  description,
  iconSrc,
  status,
  question,
  optionsComponent = undefined,
}: StatusItemProps) => {
  return (
    <div className="StatusItem">
      {optionsComponent && (
        <div className="StatusItem__content__options">{optionsComponent}</div>
      )}
      <div className={`StatusItem__icon StatusItem__icon--${status}`}>
        <SVG src={iconSrc} title={question.title} />
      </div>
      <div className="StatusItem__content">
        <div className="StatusItem__content__title">
          {question.title}
          <FontAwesomeIcon className="ms-2" icon={faArrowRight} />
        </div>
        <div className="StatusItem__content__description">{description}</div>
      </div>
    </div>
  );
};

export default StatusItem;
