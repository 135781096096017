import { RangeValidConversationQuestionLegendDescription } from '../../../../../../../../../../../../../interfaces/conversations';

export const getEmptyLegendDescription =
  (): RangeValidConversationQuestionLegendDescription => {
    return {
      value: 0,
      label: '',
      label_nb_no: '',
    };
  };
