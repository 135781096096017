import React from 'react';
import NotAvailablePreview from '../NotAvailablePreview';

type ImagePreviewProps = {
  url: string | null;
};

const ImagePreview = ({ url }: ImagePreviewProps) => {
  if (!url) return <NotAvailablePreview />;
  return (
    <div
      style={{
        backgroundImage: `url('${url}')`,
        backgroundColor: '#1d1d1d',
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    />
  );
};

export default ImagePreview;
