import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import IconButton from '../../../../../../../../../../components/IconButton';
import Schedule from '../../../../../../../../../../components/Schedule';
import { defaultHours } from '../../../../../../../../../../helpers/utils/dates';
import useOpen from '../../../../../../../../../../hooks/useOpen';
import { useTypedContext } from '../../../../../../../../../../hooks/useTypedContext';
import { ISummarizedTreatmentPhase } from '../../../../../../../../../../interfaces/conversations';
import { TreatmentPlanDataContext } from '../../../../../../types';
import { useContentIndex } from '../../hooks/useContentIndex';
import AddTemplateModal from '../../modals/AddTemplateModal';
import NoDefaultTemplatePlaceholder from '../../placeholders/NoDefaultTemplatePlaceholder';
import NoInitialPhaseSelectedPlaceholder from '../../placeholders/NoInitialPhaseSelectedPlaceholder';
import { useConversationSchedules } from './hooks/useConversationSchedules';
import { useHandleAddTemplate } from './hooks/useHandleAddTemplate';

type ConversationSchedulesProps = {
  phase: ISummarizedTreatmentPhase;
};

const ConversationSchedules = ({ phase }: ConversationSchedulesProps) => {
  const { treatmentPhases } = useTypedContext(TreatmentPlanDataContext);

  const addTemplateModal = useOpen();
  const defaultTemplateMode = useOpen();

  const contentIndex = useContentIndex();

  const handleAddTemplate = useHandleAddTemplate(
    defaultTemplateMode,
    addTemplateModal,
    contentIndex
  );

  const conversationItems = useConversationSchedules(
    phase,
    contentIndex,
    defaultTemplateMode,
    addTemplateModal
  );

  return (
    <>
      {addTemplateModal.isOpen && (
        <AddTemplateModal
          isOpen={addTemplateModal.isOpen}
          toggle={addTemplateModal.toggle}
          phase={phase}
          defaultTemplateMode={defaultTemplateMode.isOpen}
          contentIndex={contentIndex.index}
        />
      )}
      <div className="d-flex flex-column gap-2">
        <div className="d-flex align-items-center justify-content-between">
          <Typography variant="h6">
            <FormattedMessage id="Conversations.ConversationSchedule" />
          </Typography>
          <IconButton
            onClick={handleAddTemplate}
            color="primary"
            variant="outlined"
          >
            <AddIcon />
          </IconButton>
        </div>
        <div>
          <Schedule hours={defaultHours} items={conversationItems} />
          {!phase.empty_conversation_template_id && (
            <div className="mt-4">
              <NoDefaultTemplatePlaceholder />
            </div>
          )}
          {!treatmentPhases.find((p) => p.is_default) && (
            <div className="mt-4">
              <NoInitialPhaseSelectedPlaceholder />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ConversationSchedules;
