import React from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar } from '@fortawesome/pro-light-svg-icons';

const EmptyData = () => {
  const { attachPrefix, wrapWithIntl } = useTranslations(
    'Organizations.OrganizationDetails.TrendVisualizations.TrendVisualizationModal.FormCharts.EmptyData'
  );

  return (
    <div className="card shadow-sm d-flex align-items-center justify-content-center gap-1 h-px-350 text-muted">
      <div className="crossed-out crossed-out--left crossed-out--size-4 opacity-25">
        <span className="fs-2">
          <FontAwesomeIcon icon={faChartBar} size="3x" />
        </span>
      </div>
      <span className="fs-5 fw-300 text-muted opacity-50">
        {wrapWithIntl(attachPrefix('emptyVisualizations'))}
      </span>
    </div>
  );
};

export default EmptyData;
