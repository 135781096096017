import useOpen from 'hooks/useOpen';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import imageAccessibility from 'assets/images/ic-accessibility.svg';
import { Formik, FormikProps } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressCard } from '@fortawesome/pro-solid-svg-icons';
import CaseDescriptionForm from './components/CaseDescriptionForm';
import { PatientFormKeys } from 'interfaces/personalities';
import PatientListStep from './components/PatientListStep';
import PatientInfoStep from './components/PatientInfoStep';
import {
  CaseFormErrors,
  CaseFormType,
  DescriptionFormKeys,
  ShareMode,
} from 'interfaces/cases';
import { isEmail } from 'helpers/utils/validators';
import { useCreateCase } from 'hooks/cases/useCreateCase';
import { usePatientCreate } from 'hooks/cases/usePatientCreate';
import PatientCreateStep from './components/PatientCreateStep';

//images
import SymptomStep from './components/SymptomStep';
import {
  hasDuplicateSymptomNames,
  hasEmptyMeanings,
} from 'helpers/utils/symptoms';
import LoadingButton from 'components/LoadingButton';
import useRequiredParams from 'hooks/useRequiredParams';
import { faPlus } from '@fortawesome/pro-light-svg-icons';

export enum CUSTOM_MEANINGS_ERRORS {
  NO_EMPTY = 'noEmpty',
  NO_DUPLICATE_NAMES = 'noDuplicates',
}

enum ModalSteps {
  PATIENT_SELECT = 'patientSelect',
  PATIENT_INFO = 'patientInfo',
  PATIENT_CREATE = 'createPatient',
  OBSERVATION_MEANINGS = 'observationMeanings',
  CASE = 'case',
}

export const initialValues: CaseFormType = {
  caseDescription: '',
  first_name: '',
  last_name: '',
  birthdate: '',
  gender: '',
  address: '',
  ssn: '',
  email: '',
  caseTitle: '',
  socialSecurity: false,
  caseCategory: '',
  caseSubCategory: '',
  shareMode: ShareMode.SHARE_DATA,
  phone_number: '',
  definitions: [],
  isDefinitionInitialized: false,
};

type CaseCardModalProps = {
  defaultPatientId?: string;
  successFb?: () => void;
};

const CaseCardModal = ({ defaultPatientId, successFb }: CaseCardModalProps) => {
  const intl = useIntl();

  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const defaultStep = useMemo(
    () => (defaultPatientId ? ModalSteps.CASE : ModalSteps.PATIENT_SELECT),
    [defaultPatientId]
  );
  const defaultExistingPatientId = useMemo(
    () => defaultPatientId ?? null,
    [defaultPatientId]
  );

  const { isOpen, toggle: toggleModal } = useOpen();
  const [step, setStep] = useState(defaultStep);

  const [existingPatientId, setExistingPatientId] = useState<string | null>(
    defaultExistingPatientId
  );

  const validate = (values: typeof initialValues) => {
    const errors: Partial<CaseFormErrors> = {};

    if (step === ModalSteps.CASE) {
      Object.values(DescriptionFormKeys).forEach((key) => {
        if (!values[key])
          errors[key] = intl.formatMessage({
            id: 'CaseCard.create.errors.inputEmpty',
          });
      });
    }
    if (step === ModalSteps.OBSERVATION_MEANINGS) {
      if (hasDuplicateSymptomNames(values.definitions)) {
        errors.definitions = CUSTOM_MEANINGS_ERRORS.NO_DUPLICATE_NAMES;
      }
      if (hasEmptyMeanings(values.definitions)) {
        errors.definitions = CUSTOM_MEANINGS_ERRORS.NO_EMPTY;
      }
    } else if (step === ModalSteps.PATIENT_CREATE) {
      [PatientFormKeys.FIRST_NAME, PatientFormKeys.LAST_NAME].forEach((key) => {
        if (!values[key])
          errors[key] = intl.formatMessage({
            id: 'CaseCard.create.errors.inputEmpty',
          });
      });

      if (values.email && !isEmail(values.email))
        errors.email = intl.formatMessage({
          id: 'CaseCard.create.errors.emailError',
        });
    }

    return errors;
  };

  const onPrevious = () => {
    switch (step) {
      case ModalSteps.PATIENT_INFO:
      case ModalSteps.PATIENT_CREATE:
        setStep(ModalSteps.PATIENT_SELECT);
        break;
      case ModalSteps.CASE:
        setStep(ModalSteps.PATIENT_INFO);
        break;
      case ModalSteps.OBSERVATION_MEANINGS:
        setStep(ModalSteps.CASE);
        break;
    }
  };

  const onPatientPick = (patientId: string) => {
    setExistingPatientId(patientId);
  };
  const onPatientConfirm = () => setStep(ModalSteps.CASE);
  const onPatientPickContinue = () => setStep(ModalSteps.PATIENT_INFO);
  const onCaseDefinitionConfirm = () =>
    setStep(ModalSteps.OBSERVATION_MEANINGS);

  const onCreatePatient = (patientId: string) => {
    setExistingPatientId(patientId);
    setStep(ModalSteps.CASE);
  };

  const onCreatePatientClick = () => {
    setExistingPatientId(null);
    setStep(ModalSteps.PATIENT_CREATE);
  };

  const modalCloseFallback = () => {
    toggleModal();
    if (!successFb) return;
    successFb();
  };

  const { createCaseHandler, isCaseLoading } = useCreateCase(
    organizationId,
    existingPatientId,
    modalCloseFallback
  );

  const { patientPickHandler } = usePatientCreate(
    organizationId,
    onCreatePatient
  );

  const onFormSubmit = (formData: typeof initialValues) => {
    if (step === ModalSteps.PATIENT_CREATE) {
      patientPickHandler(formData);
    } else {
      createCaseHandler(formData);
    }
  };

  const renderHeader = () => {
    switch (step) {
      case ModalSteps.PATIENT_SELECT:
        return (
          <>
            <FormattedMessage id="CaseCard.create.pickPatient" />
            <img
              src={imageAccessibility}
              className="iconNewPatientColor"
              alt="imageAccessibility"
            />
          </>
        );
      case ModalSteps.PATIENT_INFO:
        return (
          <>
            <FormattedMessage id="CaseCard.create.patientDetails" />
            <img
              src={imageAccessibility}
              className="iconNewPatientColor"
              alt="imageAccessibility"
            />
          </>
        );
      case ModalSteps.PATIENT_CREATE:
        return (
          <>
            <FormattedMessage id="UserDashboard.createPatient" />
            <img
              src={imageAccessibility}
              className="iconNewPatientColor"
              alt="imageAccessibility"
            />
          </>
        );
      case ModalSteps.CASE:
        return (
          <>
            <FormattedMessage id="CaseCard.create.caseDefinition" />
            <FontAwesomeIcon
              icon={faAddressCard}
              className="ms-2"
              color="#237fc0"
            />
          </>
        );
      case ModalSteps.OBSERVATION_MEANINGS:
        return (
          <>
            <FormattedMessage id="ObservationMeaning.observationMeanings" />
            <img
              src={imageAccessibility}
              className="iconNewPatientColor"
              alt="imageAccessibility"
            />
          </>
        );
      default:
        return null;
    }
  };

  const renderBody = useCallback(
    (form: FormikProps<typeof initialValues>) => {
      switch (step) {
        case ModalSteps.PATIENT_SELECT:
          return (
            <PatientListStep
              onCreateButtonClick={onCreatePatientClick}
              selectedId={existingPatientId}
              onRowSelect={onPatientPick}
            />
          );
        case ModalSteps.PATIENT_INFO:
          return existingPatientId ? (
            <PatientInfoStep
              formik={form}
              personalityId={existingPatientId}
              setValues={form.setValues}
            />
          ) : null;
        case ModalSteps.PATIENT_CREATE:
          return (
            <PatientCreateStep
              formik={form}
              organizationId={organizationId}
              emailValue={form.values.email}
              setValues={form.setValues}
            />
          );
        case ModalSteps.CASE:
          return (
            <CaseDescriptionForm
              organizationId={organizationId}
              setValues={form.setValues}
              values={form.values}
            />
          );
        case ModalSteps.OBSERVATION_MEANINGS:
          return (
            <SymptomStep
              setValues={form.setValues}
              values={form.values}
              errors={form.errors as Partial<CaseFormErrors>}
            />
          );
        default:
          return null;
      }
    },
    [existingPatientId, organizationId, step]
  );

  const renderFooter = (submitForm: () => void, isValid: boolean) => {
    switch (step) {
      case ModalSteps.PATIENT_SELECT:
        return (
          <Button
            onClick={onPatientPickContinue}
            disabled={!existingPatientId}
            color="primary"
          >
            <FormattedMessage id="CaseCard.create.pickPatient" />
          </Button>
        );
      case ModalSteps.PATIENT_INFO:
        return (
          <>
            <Button onClick={onPrevious} color="secondary" className="me-2">
              <FormattedMessage id="CaseCard.create.previous" />
            </Button>
            <Button type="button" onClick={onPatientConfirm} color="primary">
              <FormattedMessage id="CaseCard.create.confirmPatient" />
            </Button>
          </>
        );
      case ModalSteps.PATIENT_CREATE:
        return (
          <>
            <Button onClick={onPrevious} color="secondary" className="me-2">
              <FormattedMessage id="CaseCard.create.previous" />
            </Button>
            <Button
              type="submit"
              color="primary"
              disabled={!isValid}
              onClick={submitForm}
            >
              <FormattedMessage id="UserDashboard.createPatient" />
            </Button>
          </>
        );
      case ModalSteps.CASE:
        return (
          <>
            {!defaultPatientId && (
              <Button onClick={onPrevious} color="secondary" className="me-2">
                <FormattedMessage id="CaseCard.create.previous" />
              </Button>
            )}
            <Button
              onClick={onCaseDefinitionConfirm}
              type="submit"
              color="primary"
              disabled={!isValid}
            >
              <FormattedMessage id="CaseCard.create.confirmCaseDefinition" />
            </Button>
          </>
        );
      case ModalSteps.OBSERVATION_MEANINGS:
        return (
          <>
            <Button onClick={onPrevious} color="secondary" className="me-2">
              <FormattedMessage id="CaseCard.create.previous" />
            </Button>
            <LoadingButton
              type="submit"
              onClick={submitForm}
              color="primary"
              disabled={!isValid}
              isLoading={isCaseLoading}
            >
              <FormattedMessage id="CaseCard.create.createCase" />
            </LoadingButton>
          </>
        );
    }
  };

  return (
    <>
      <Button onClick={toggleModal} color="primary" outline={true}>
        <FontAwesomeIcon icon={faPlus} />
      </Button>
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={onFormSubmit}
        validate={validate}
      >
        {(form) => (
          <Modal
            isOpen={isOpen}
            toggle={toggleModal}
            size="lg"
            onClosed={() => {
              setStep(defaultStep);
              form.resetForm();
              setExistingPatientId(defaultExistingPatientId);
            }}
          >
            <ModalHeader toggle={toggleModal}>
              <div className="d-flex">
                <div
                  className="me-2 pe-1"
                  style={{
                    borderRight: '1px solid black',
                  }}
                >
                  <FormattedMessage id="CaseCard.create.newCase" />
                </div>
                <div>{renderHeader()}</div>
              </div>
            </ModalHeader>
            <ModalBody>{renderBody(form)}</ModalBody>
            <ModalFooter>
              <Button onClick={toggleModal} color="danger">
                <FormattedMessage id="General.cancel" />
              </Button>
              {renderFooter(form.submitForm, form.isValid)}
            </ModalFooter>
          </Modal>
        )}
      </Formik>
    </>
  );
};

export default CaseCardModal;
