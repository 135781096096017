import { useCommonData } from '../../../../../hooks/useCommonData';
import { useInitQuery } from '../../../../../../../hooks/queries/useInitQuery';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';

export const useEducationMaterialItem = () => {
  const intl = useIntl();

  const { caseCategories } = useCommonData();
  useInitQuery(caseCategories);

  const getCategoryName = useCallback(
    (id: string | undefined) => {
      return (
        caseCategories.data?.find((p) => p.id === id)?.name ??
        intl.formatMessage({
          id: 'Education.EducationMaterialsTab.EducationMaterialsItem.none',
        })
      );
    },
    [caseCategories.data, intl]
  );

  const getSubCategoryName = useCallback(
    (id: string | undefined) => {
      const allSubCategories =
        caseCategories.data?.flatMap((p) => p.sub_categories) ?? [];

      return (
        allSubCategories.find((p) => p.id === id)?.name ??
        intl.formatMessage({
          id: 'Education.EducationMaterialsTab.EducationMaterialsItem.none',
        })
      );
    },
    [caseCategories.data, intl]
  );

  return {
    getCategoryName: getCategoryName,
    getSubCategoryName: getSubCategoryName,
  };
};
