import React, { useState } from 'react';
import { useTreatmentPlansCommonData } from '../../../../hooks/useTreatmentPlansCommonData';
import useCloneTreatmentPlan from '../../../../../../api/mutations/conversations/useCloneTreatmentPlan';
import useRequiredParams from '../../../../../../hooks/useRequiredParams';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import InputFieldWithoutImage from '../../../../../../components/InputFieldWithoutImage';
import EditableInput from '../../../../../../components/EditableInput/EditableInput';
import { FormattedMessage } from 'react-intl';

type CloneTreatmentPlanModalProps = {
  isOpen: boolean;
  toggle: () => void;
  planId: string;
};

const CloneTreatmentPlanModal = ({
  isOpen,
  toggle,
  planId,
}: CloneTreatmentPlanModalProps) => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const { treatmentPlans, caseCategories } = useTreatmentPlansCommonData();

  const [name, setName] = useState<string>('');

  const handleSuccess = async () => {
    await treatmentPlans.refetch();
    await caseCategories.refetch();
    toggle();
  };

  const { mutateAsync: cloneAsync } = useCloneTreatmentPlan(
    organizationId,
    handleSuccess
  );

  const editName = (target: string, value: string) => setName(value);

  const handleClone = async () => {
    await cloneAsync({
      name: name,
      planId: planId,
    });
  };

  return (
    <Modal
      centered={true}
      isOpen={isOpen}
      toggle={toggle}
      className="ConfirmModal"
      size="lg"
    >
      <ModalBody>
        <InputFieldWithoutImage
          gray={true}
          noMargin={true}
          labelTranslationId="TreatmentPlans.TreatmentPlansList.CloneTreatmentPlanModal.name"
          inputId="name"
        >
          <EditableInput
            type="text"
            value={name}
            onEdit={editName}
            name="name"
            debounceEnabled={false}
          />
        </InputFieldWithoutImage>
      </ModalBody>
      <ModalFooter>
        <Button
          className="ConfirmModal__confirm"
          outline={true}
          color="primary"
          onClick={toggle}
        >
          <FormattedMessage id="TreatmentPlans.TreatmentPlansList.CloneTreatmentPlanModal.cancel" />
        </Button>
        <Button
          className="ConfirmModal__confirm"
          disabled={name === ''}
          outline={false}
          color="primary"
          onClick={handleClone}
        >
          <FormattedMessage id="TreatmentPlans.TreatmentPlansList.CloneTreatmentPlanModal.clone" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CloneTreatmentPlanModal;
