import { createTimelineAnnotation } from 'api/graspManagement';
import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import { IAnnotationBody, ITimelineAnnotation } from 'interfaces/timeline';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';

function useCreateTimelineAnnotation(
  timelineId: string,
  successFallback?: () => void
) {
  return useMutationWithResultNotification<
    IAnnotationBody,
    ITimelineAnnotation[]
  >({
    asyncApiFunction: async (body) => {
      const { data } = await createTimelineAnnotation(timelineId, body);
      return data.data;
    },
    onSuccess: () => {
      displaySuccessNotification('TimelineDetails.annotationSuccessfullyAdded');
      if (successFallback) successFallback();
    },
    onErrorArray: (errorArray) =>
      displayErrorArrayNotifications(errorArray, 'TimelineDetails.errors'),
    resetOnResult: true,
  });
}

export default useCreateTimelineAnnotation;
