import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FormattedMessage } from 'react-intl';
import { faFolderOpen } from '@fortawesome/pro-light-svg-icons';

const EducationMaterialCategoriesTreeEmpty = () => {
  return (
    <div className="CaseSubCategoryEmpty">
      <FontAwesomeIcon icon={faFolderOpen} size="3x" />
      <span>
        <FormattedMessage id="Education.EducationMaterialCategoriesTab.EducationMaterialCategoriesTreeEmpty.emptyTree" />
      </span>
    </div>
  );
};

export default EducationMaterialCategoriesTreeEmpty;
