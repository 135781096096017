import React from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';
import phaseIcon from '../../../../../../../../assets/images/phase.svg';

const NoPhasesPlaceholder = () => {
  return (
    <div className="TreatmentPhaseConversationSchedulesPlaceholder mt-4">
      <SVG
        className="TreatmentPhaseConversationSchedulesPlaceholder__icon"
        src={phaseIcon}
      />
      <FormattedMessage
        tagName="span"
        id="TreatmentPlans.TreatmentPlan.TreatmentPlanContent.TreatmentPlansContentPlaceholder.content"
      />
    </div>
  );
};

export default NoPhasesPlaceholder;
