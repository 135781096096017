import { differenceInCalendarDays } from 'date-fns';
import { strToDateOnly } from 'helpers/dates';
import { getCurrentCasePhaseHistoryItems } from 'helpers/utils/casePhaseHistory';
import { useMemo } from 'react';
import { useCommonData } from '../../../hooks/useCommonData';
import { PhaseDayDefinition } from '../../../types';

export const useCaseConversationsScheduleDatePhaseDescription = (
  selectedDate: string
) => {
  const { casePhaseHistory, caseDetails } = useCommonData();

  return useMemo((): PhaseDayDefinition[] => {
    if (
      !casePhaseHistory.data ||
      !caseDetails.data ||
      casePhaseHistory.data?.length === 0
    )
      return [];

    const firstPhase = casePhaseHistory.data[0];

    const startDate = firstPhase.date_from;

    if (strToDateOnly(selectedDate) < strToDateOnly(startDate)) return [];

    let currentPhases = getCurrentCasePhaseHistoryItems(
      casePhaseHistory.data,
      selectedDate
    );

    return currentPhases.map((currentPhase) => {
      let daysDurationSuggestedTo = currentPhase.suggested_date_to
        ? differenceInCalendarDays(
            new Date(currentPhase.suggested_date_to),
            new Date(currentPhase.date_from)
          ) + 1
        : null;

      let daysDurationTo = currentPhase.date_to
        ? differenceInCalendarDays(
            new Date(currentPhase.date_to),
            new Date(currentPhase.date_from)
          ) + 1
        : daysDurationSuggestedTo;

      return {
        displayCode: currentPhase.display_code,
        startDate: currentPhase.date_from,
        endDate: currentPhase.date_to
          ? currentPhase.date_to
          : currentPhase.suggested_date_to &&
            strToDateOnly(selectedDate) >
              strToDateOnly(currentPhase.suggested_date_to)
          ? null
          : currentPhase.suggested_date_to,
        daysDuration: daysDurationTo,
        nthDay:
          differenceInCalendarDays(
            new Date(selectedDate),
            new Date(currentPhase.date_from)
          ) + 1,
      };
    });
  }, [caseDetails.data, casePhaseHistory.data, selectedDate]);
};
