import React from 'react';
import { communicationChannelOptions } from 'helpers/utils/translationObject';
import { FieldProps } from 'formik';
import { FormattedMessage } from 'react-intl';
import SVG from 'react-inlinesvg';

import email from 'assets/images/communication_channels/email.svg';
import sms from 'assets/images/communication_channels/sms.svg';
import push from 'assets/images/communication_channels/push.svg';
import whatsapp from 'assets/images/communication_channels/whatsapp.svg';
import { CommunicationChannels } from 'interfaces/enums';

interface CommunicationChannelOptionsProps extends FieldProps {
  requiredChannels?: CommunicationChannels[];
}

const renderCommunicationChannelIcon = (value: string) => {
  switch (value) {
    case CommunicationChannels.Email:
      return <SVG src={email} title={CommunicationChannels.Email} />;
    case CommunicationChannels.SMS:
      return <SVG src={sms} title={CommunicationChannels.SMS} />;
    case CommunicationChannels.PUSH:
      return <SVG src={push} title={CommunicationChannels.PUSH} />;
    case CommunicationChannels.Whatsapp:
      return <SVG src={whatsapp} title={CommunicationChannels.Whatsapp} />;
    default:
      return <></>;
  }
};

const CommunicationChannelOptions = ({
  form,
  field,
  requiredChannels = [],
}: CommunicationChannelOptionsProps) => {
  const selectedCommunicationChannel = field.value;

  const renderedCommunicationChannels = communicationChannelOptions.map(
    ({ translationId, value }) => {
      const isSelected = selectedCommunicationChannel.includes(value);

      const isRequired = requiredChannels.includes(
        value as CommunicationChannels
      );

      return (
        <div
          key={`communication-option-${value}`}
          className={`CommunicationChannelOptions__item ${
            isSelected
              ? isRequired
                ? 'CommunicationChannelOptions__item--required'
                : 'CommunicationChannelOptions__item--active'
              : ''
          }`}
          onClick={() => {
            if (!isRequired) {
              let newFieldValue = field.value;

              if (isSelected) {
                newFieldValue = newFieldValue.filter((p) => p !== value);
              } else {
                newFieldValue = [...newFieldValue, value];
              }
              form.setValues({
                ...form.values,
                [field.name]: newFieldValue,
              });
            }
          }}
        >
          <div className={`CommunicationChannelOptions__item__icon`}>
            {renderCommunicationChannelIcon(value)}
          </div>
          <div className="CommunicationChannelOptions__item__text">
            {isRequired ? (
              <>
                <FormattedMessage id={translationId} />
                {'*'}
              </>
            ) : (
              <FormattedMessage id={translationId} />
            )}
          </div>
        </div>
      );
    }
  );

  return (
    <div className="CommunicationChannelOptions">
      {renderedCommunicationChannels}
    </div>
  );
};

export default CommunicationChannelOptions;
