import { createContext } from 'react';
import { AvailableCulture } from '../../../../../../../hooks/cultures/useCultures';
import { ValidConversationTemplateItem } from '../../../../../../../interfaces/conversations';

export type ConversationTemplatePhoneItemActions = {
  onSelectItem?: (sequence: number) => void;
  onDeleteItem?: (sequence: number) => void;
  onMoveItemUp?: (sequence: number) => void;
  onMoveItemDown?: (sequence: number) => void;
  onAddItem?: (item: ValidConversationTemplateItem) => void;
};

export const ItemActionsContext = createContext<
  ConversationTemplatePhoneItemActions | undefined
>(undefined);

export const LanguageContext = createContext<AvailableCulture | undefined>(
  undefined
);
