import { IPatchObject } from '../interfaces/request';
import request from './utils/request';
import {
  ICreateCaseCategory,
  ICreateCaseSubCategory,
} from '../interfaces/caseCategories';

export const editCaseCategory = (
  organizationId: string,
  categoryId: string,
  patchData: IPatchObject[]
) =>
  request({
    url: `/organizations/${organizationId}/case_categories/${categoryId}`,
    method: 'PATCH',
    data: patchData,
  });

export const editCaseSubCategory = (
  organizationId: string,
  subCategoryId: string,
  patchData: IPatchObject[]
) =>
  request({
    url: `/organizations/${organizationId}/case_sub_categories/${subCategoryId}`,
    method: 'PATCH',
    data: patchData,
  });

export const createCaseCategory = (
  organizationId: string,
  data: ICreateCaseCategory
) =>
  request({
    url: `/organizations/${organizationId}/case_categories`,
    method: 'POST',
    data: data,
  });

export const createCaseSubCategory = (
  organizationId: string,
  data: ICreateCaseSubCategory
) =>
  request({
    url: `/organizations/${organizationId}/case_sub_categories`,
    method: 'POST',
    data: data,
  });
