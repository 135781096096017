import TextField from '@mui/material/TextField';
import { FormikProps } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { CaseNotificationSchedule } from '../../../../../../../../../../../../../../../../../../interfaces/notificationSchedules';
import { FormValues } from '../../types';

type SingleEventFormProps = {
  schedule: CaseNotificationSchedule;
  form: FormikProps<FormValues>;
};

const SingleEventForm = ({ schedule, form }: SingleEventFormProps) => {
  const intl = useIntl();

  return (
    <>
      <TextField
        fullWidth
        variant="outlined"
        type="number"
        name="specific_day_of_phase"
        disabled={!!schedule.origin_id}
        placeholder={intl.formatMessage({
          id: 'Treatment.SpecificDayOfPhase',
        })}
        label={intl.formatMessage({
          id: 'Treatment.SpecificDayOfPhase',
        })}
        value={form.values.specific_day_of_phase}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{ min: 1 }}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
      />
      <TextField
        fullWidth
        variant="outlined"
        type="time"
        name="notification_hour"
        placeholder={intl.formatMessage({
          id: 'Notifications.NotificationHour',
        })}
        label={intl.formatMessage({
          id: 'Notifications.NotificationHour',
        })}
        value={form.values.notification_hour}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
      />
    </>
  );
};

export default SingleEventForm;
