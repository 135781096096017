import React from 'react';
import { FormikProps, getIn } from 'formik';
import { ScheduleFormHookValues } from '../../hooks/useForm';
import {
  ClosedListValidConversationQuestion,
  ConversationTemplate,
  QuestionValidConversationTemplateItem,
  ValidConversationQuestionType,
  ValidConversationTemplateItemType,
} from '../../../../../../../../../../interfaces/conversations';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import TextField from '@mui/material/TextField';
import { useQuestionTitle } from '../../../../../../../../../../hooks/data/useQuestionTitle';
import { useAnswerTitle } from '../../../../../../../../../../hooks/data/useAnswerTitle';

type QuestionFieldsProps = {
  form: FormikProps<ScheduleFormHookValues>;
  template: ConversationTemplate;
};

const QuestionFields = ({ template, form }: QuestionFieldsProps) => {
  const getQuestionTitle = useQuestionTitle();
  const getAnswerTitle = useAnswerTitle();

  let awareQuestions = template.content.filter(
    (p) => p.type === ValidConversationTemplateItemType.Question
  ) as QuestionValidConversationTemplateItem[];

  awareQuestions = awareQuestions.filter(
    (p) => p.value.type === ValidConversationQuestionType.ClosedList
  );

  return (
    <>
      {awareQuestions.map((question, index) => (
        <TextField
          key={question.resource_id}
          variant="standard"
          fullWidth
          select={true}
          id={`questions[${index}].id`}
          name={`questions[${index}].id`}
          label={getQuestionTitle({
            content: question.value,
            resource_id: question.resource_id,
            organization_id: '',
          })}
          placeholder={getQuestionTitle({
            content: question.value,
            resource_id: question.resource_id,
            organization_id: '',
          })}
          value={getIn(form.values, `questions[${index}].id`)}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
        >
          {(question.value as ClosedListValidConversationQuestion).answers.map(
            (answer) => (
              <MenuItem
                key={answer.guid}
                value={`${question.resource_id}|${answer.title}`}
              >
                {getAnswerTitle(answer)}
              </MenuItem>
            )
          )}
        </TextField>
      ))}
    </>
  );
};

export default QuestionFields;
