import { useTranslations } from './useTranslations';
import { useCallback } from 'react';

export const useGeneratePlaceholder = (isDefaultLanguage: boolean) => {
  const { providerInvariant } = useTranslations();

  return useCallback(
    (prefix: string, target: any) => {
      const cultureBasedPlaceholder = providerInvariant.wrapWithIntl(
        providerInvariant.attachPrefix('provideValueFor'),
        { target: target }
      );

      const defaultTitlePlaceholder = providerInvariant.wrapWithIntl(
        providerInvariant.attachPrefix(`${prefix}Placeholder`)
      );

      return isDefaultLanguage
        ? defaultTitlePlaceholder
        : cultureBasedPlaceholder;
    },
    [isDefaultLanguage, providerInvariant]
  );
};
