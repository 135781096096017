import { SingleEntry } from '../types';

export const useDivideSingleEntries = (
  items: SingleEntry[],
  chunkSize: number
) => {
  return items.reduce((resultArray, item, index) => {
    const chunkIndex = Math.floor(index / chunkSize);
    if (!resultArray[chunkIndex]) resultArray[chunkIndex] = [] as SingleEntry[];
    resultArray[chunkIndex].push(item);
    return resultArray;
  }, [] as SingleEntry[][]);
};
