import { IObservation } from '../../../../../../../../../interfaces/timeline';
import { IntensityTimelineData } from '../types';
import { useMemo } from 'react';
import { differenceInMilliseconds } from 'date-fns';
import { useTypedContext } from '../../../../../../../../../hooks/useTypedContext';
import { AwareCaseContentDataContext } from '../../AwareCaseContent/types';

export const useAlarmIncidents = (
  observations: IObservation[]
): IntensityTimelineData[] => {
  const { details, awareSession } = useTypedContext(
    AwareCaseContentDataContext
  );

  const alarmObservations = useMemo(() => {
    return observations.filter((p) => p.is_alarm_incident);
  }, [observations]);

  const dateFrom = useMemo(() => {
    const undefinedStartDate = details?.start_date ?? undefined;
    const startDate = awareSession?.start_date ?? undefinedStartDate;

    return startDate ? new Date(startDate) : undefined;
  }, [awareSession?.start_date, details?.start_date]);

  return useMemo((): IntensityTimelineData[] => {
    if (!dateFrom) return [];

    return alarmObservations.map((observation) => {
      return {
        time: differenceInMilliseconds(
          new Date(observation.timestamp),
          dateFrom
        ),
        session: 0,
        duration: observation.duration_in_seconds,
        visibleDuration: 0.22,
        level: observation.level,
      };
    });
  }, [alarmObservations, dateFrom]);
};
