import { IMutationProps } from '../../../interfaces/ui';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { deleteGraspLedLevel } from '../../graspManagement';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { OrganizationQueryParams } from '../../../interfaces/queryParams';

const useRemoveGraspLedLevel = (
  params: OrganizationQueryParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (ledLevelId: string) => {
      await deleteGraspLedLevel(params.organizationId, ledLevelId);
    },
    resetOnResult: true,
    onSuccess: () => {
      displaySuccessNotification('MyOrganizations.grasps.deleteSuccess');
      if (options.successFb) options.successFb();
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'MyOrganizations.grasps.deleteErrors'
      );
    },
  });
};

export default useRemoveGraspLedLevel;
