import { useCallback } from 'react';
import { ICaseProcedureWithActivitiesLocalized } from '../../../../../../../../../interfaces/cases';

export const useGetSelectedProcedure = (
  procedures: ICaseProcedureWithActivitiesLocalized[]
) => {
  return useCallback(
    (id: string) => {
      return procedures.find((p) => p.id === id);
    },
    [procedures]
  );
};
