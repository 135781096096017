import { ISummarizedTreatmentPhase } from '../../../../../../../interfaces/conversations';

export const useEmptyTreatmentPhase = () => {
  return (
    organizationId: string,
    treatmentPlanId: string,
    sequence: number = 0
  ): ISummarizedTreatmentPhase => {
    return {
      organization_id: organizationId,
      empty_conversation_template_id: null,
      sequence: sequence,
      content: [],
      id: '',
      next_phase_condition: '',
      treatment_plan_id: treatmentPlanId,
      days_duration: 0,
      is_default: false,
      display_code: null,
      users_count: 0,
    };
  };
};
