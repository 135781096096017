import React from 'react';
import PageHeader from '../../../../../../components/PageHeader';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import SessionBasedDataProvider from './providers/SessionBasedDataProvider';
import ObservationsProvider from './providers/ObservationsProvider';
import OperationalDataProvider from './providers/OperationalDataProvider';
import AwareCaseDetailsForm from './forms/AwareCaseDetailsForm';
import { ThemesOptions } from '../../../../../../themes/options';
import { ThemeProvider } from '@mui/material';
import classNames from 'classnames';
import { useBrowserState } from '../../../../../../store/reducerHooks';

const AwareCaseDetailsView = () => {
  const isBrowser = useBrowserState();
  const { breadcrumbs, isLoading } = useBreadcrumbs();

  return (
    <ThemeProvider theme={ThemesOptions.awareThemeOptions}>
      <PageHeader
        breadcrumbs={breadcrumbs}
        isLoading={isLoading}
        className={classNames('w-full', {
          'mt-10': !isBrowser,
        })}
        content={
          <SessionBasedDataProvider>
            {(
              caseDetails,
              caseProcedureHistory,
              awareSessionDetails,
              questions,
              members
            ) => (
              <ObservationsProvider
                caseDetails={caseDetails}
                awareSessionDetails={awareSessionDetails}
              >
                {(observations) => (
                  <OperationalDataProvider
                    observations={observations}
                    awareSessionDetails={awareSessionDetails}
                    caseProcedureHistory={caseProcedureHistory}
                    caseDetails={caseDetails}
                    members={members}
                    questions={questions}
                  >
                    <AwareCaseDetailsForm />
                  </OperationalDataProvider>
                )}
              </ObservationsProvider>
            )}
          </SessionBasedDataProvider>
        }
      />
    </ThemeProvider>
  );
};

export default AwareCaseDetailsView;
