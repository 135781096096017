import React, { InputHTMLAttributes } from 'react';
import { MutationOptions, QueryObserverOptions } from 'react-query';
import { InputType } from 'reactstrap/types/lib/Input';
import { ICaseHistory } from './caseHistory';
import { IErrorDefinition } from './response';
import { FormikProps } from 'formik';

export type InputOption = {
  value: string;
  text: string;
};

export type NameValueTuple<TName, TValue> = {
  name: TName;
  value: TValue;
};

export interface IOption {
  text?: string;
  value: string;
  translationId?: string;
  icon?: JSX.Element;
}

export interface INameLocalized {
  name: string;
}

export type UserDataFormProps<T> = {
  formik: FormikProps<T>;
  readOnlyInputs?: string[];
  displayInvitePatientButton?: boolean;
  inviteDisabled?: boolean;
  onInviteClick?: () => void;
  isInviteLoading?: boolean;
};

export interface IQueryOptions
  extends Pick<
    QueryObserverOptions,
    'enabled' | 'staleTime' | 'refetchOnMount' | 'refetchOnWindowFocus'
  > {
  refetchOnMount?: boolean;
  refetchOnWindowFocus?: boolean;
}

export type ListWithSearchComponent = {
  searchValue?: string;
};

export interface IMutationProps extends MutationOptions {
  successFb?: () => void;
  errorFb?: () => void;
}

export interface IIdentifiedMutationProps extends MutationOptions {
  successFb?: (id: string) => void;
  errorFb?: () => void;
}

export interface ITypedMutationProps<T> extends MutationOptions {
  successFb?: (body: T) => void;
  errorFb?: (body: IErrorDefinition[] | string) => void;
}

export enum TimelineTabsValues {
  DAY_BY_DAY = 'DAY_BY_DAY',
  OVERVIEW = 'OVERVIEW',
  TRENDS_TIMELINE = 'TRENDS_TIMELINE',
  CASE_DETAILS = 'CASE_DETAILS',
}

export enum ResourcesHubTabsValues {
  CONVERSATION_TEMPLATES = 'CONVERSATION_TEMPLATES',
  EDUCATION_ITEMS = 'EDUCATION_ITEMS',
  FEEDBACK_RESOURCES = 'FEEDBACK_RESOURCES',
}

export interface IEditableDivProps
  extends Omit<
    InputHTMLAttributes<HTMLInputElement | HTMLTextAreaElement>,
    'className | value'
  > {
  type?: InputType;
  options?: InputOption[];
  onEdit: (target: string, value: string) => void;
  name: string;
  divCssClass?: string;
  inputCssClass?: string;
  value: string;
}

export type AssessmentFormValues = Record<string, string>;

export enum Size {
  BIG = 'big',
  MEDIUM = 'medium',
  SMALL = 'small',
}

export enum UserBrowsingContext {
  RegularUserContext = 'RegularUserContext',
  OrganizationContext = 'OrganizationContext',
}

export enum RequestAccessStep {
  CHOOSE_TYPE = 'chooseType',
  SEARCH = 'search',
  SELECT = 'select',
}

export type ParticipantAccessRequest = {
  participant_id?: string;
  cases_id: string[];
};

export type ParticipantAccessSelection = {
  cases_id: string[];
  participant_id: string;
  participant_already_shared: boolean;
};

export type ObservationListProps = {
  timelineId: string;
  selectedDates: FromToDates;
  isOwnerOfTimeline: boolean;
  historyFromCase?: ICaseHistory[];
  shouldFetchTimelineAnnotations?: boolean;
};

export type FromToDates = {
  from: Date;
  to: Date;
};

export type SequenceIdentified<T> = T & {
  sequence: number;
};

export type ReactState<T> = {
  item: T;
  setItem: React.Dispatch<React.SetStateAction<T>>;
};

export type ReactStateTuple<T> = [T, React.Dispatch<React.SetStateAction<T>>];

export type BaseModalProps = {
  isOpen: boolean;
  toggle: () => void;
};

export enum BootstrapBreakpoint {
  Xs = 'Xs',
  Sm = 'Sm',
  Md = 'Md',
  Lg = 'Lg',
  Xl = 'Xl',
  XXl = 'XXl',
}

export enum BootstrapBreakpointMapping {
  Xs = 576,
  Sm = 768,
  Md = 992,
  Lg = 1200,
  Xl = 1400,
  XXl = 10000,
}

export enum ViewTheme {
  Dark = 'dark',
  Hardcoded = 'hardcoded',
}
