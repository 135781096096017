import AddIcon from '@mui/icons-material/Add';
import IconButton from 'components/IconButton';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { QueryObserverResult } from 'react-query';
import { Container } from 'reactstrap';
import useRequiredParams from '../../../../../../../hooks/useRequiredParams';
import {
  IBaseCategory,
  ICategory,
} from '../../../../../../../interfaces/cases';
import CaseCategoryItem from './CaseCategoryItem';
import CaseSubCategoryEmpty from './CaseSubCategoryEmpty';
import CaseSubCategoryItem from './CaseSubCategoryItem';

type CaseCategoriesListProps = {
  refetch: () => Promise<QueryObserverResult<any, any>>;
  categories: ICategory[];
};

const CaseCategoriesList = ({
  categories,
  refetch,
}: CaseCategoriesListProps) => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const [addCategoryMode, setAddCategoryMode] = useState<boolean>(false);
  const [addSubCategoryMode, setAddSubCategoryMode] = useState<boolean>(false);

  const [selectedCategory, setSelectedCategory] = useState<ICategory | null>(
    null
  );

  const [modifiedCategory, setModifiedCategory] = useState<ICategory | null>(
    null
  );

  const [idToReopen, setIdToReopen] = useState<string | null>(null);

  const emptyNewCategory: ICategory = {
    id: '',
    sub_categories: [],
    icon: '',
    name_en: 'NEW_CATEGORY',
    name_no: 'NEW_CATEGORY',
    code: 'NEW_CATEGORY',
    name: '',
  };

  const emptyNewSubCategory: IBaseCategory = {
    id: '',
    name: '',
    name_en: 'NEW_SUB_CATEGORY',
    name_no: 'NEW_SUB_CATEGORY',
    code: 'NEW_SUB_CATEGORY',
    icon: '',
  };

  const [modifiedSubCategory, setModifiedSubCategory] =
    useState<IBaseCategory | null>(null);

  const handleAddCategoryMode = () => {
    setAddCategoryMode(true);
    setSelectedCategory(emptyNewCategory);
    setModifiedCategory(emptyNewCategory);
  };

  const disableAddCategoryMode = () => {
    setAddCategoryMode(false);
    setSelectedCategory(null);
    setModifiedCategory(null);
  };

  const handleAddSubCategoryMode = () => {
    setAddSubCategoryMode(true);
    setModifiedSubCategory(emptyNewSubCategory);
  };

  const disableAddSubCategoryMode = () => {
    setAddSubCategoryMode(false);
    setModifiedSubCategory(null);
  };

  useEffect(() => {
    if (idToReopen === null) return;

    const category = categories.find((p) => p.id === idToReopen);

    if (category === undefined) return;

    setSelectedCategory({
      ...category,
    });
  }, [categories, idToReopen]);

  return (
    <Container>
      <div className="d-flex justify-content-evenly align-items-start gap-3">
        <div style={{ flexBasis: '45%' }}>
          <div>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <span className="CaseCategoriesList__header">
                <FormattedMessage id="MyOrganizations.caseCategories.categories" />
              </span>
              <IconButton
                variant="outlined"
                color="primary"
                disabled={addCategoryMode}
                onClick={handleAddCategoryMode}
              >
                <AddIcon />
              </IconButton>
            </div>
            <div className="CaseCategoriesList__items">
              {addCategoryMode ? (
                <>
                  <div>
                    <CaseCategoryItem
                      organizationId={organizationId}
                      category={emptyNewCategory}
                      selectedCategory={selectedCategory}
                      modifiedCategory={modifiedCategory}
                      setSelectedCategory={setSelectedCategory}
                      setModifiedCategory={setModifiedCategory}
                      refetch={refetch}
                      disableAddCategoryMode={disableAddCategoryMode}
                    />
                  </div>
                  <div className="CaseCategoriesList__disabled">
                    {categories.map((category) => (
                      <CaseCategoryItem
                        key={category.code}
                        refetch={refetch}
                        organizationId={organizationId}
                        category={category}
                        selectedCategory={null}
                        modifiedCategory={null}
                        setSelectedCategory={setSelectedCategory}
                        setModifiedCategory={setModifiedCategory}
                        disableAddCategoryMode={disableAddCategoryMode}
                      />
                    ))}
                  </div>
                </>
              ) : (
                <>
                  {categories.length === 0 ? (
                    <CaseSubCategoryEmpty />
                  ) : (
                    <>
                      {categories.map((category) => (
                        <CaseCategoryItem
                          key={category.code}
                          refetch={refetch}
                          organizationId={organizationId}
                          category={category}
                          selectedCategory={selectedCategory}
                          modifiedCategory={modifiedCategory}
                          setSelectedCategory={setSelectedCategory}
                          setModifiedCategory={setModifiedCategory}
                          disableAddCategoryMode={disableAddCategoryMode}
                        />
                      ))}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div style={{ flexBasis: '45%' }}>
          <div>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <span className="CaseCategoriesList__header">
                <FormattedMessage id="MyOrganizations.caseCategories.subcategories" />
              </span>
              {selectedCategory !== null && !addCategoryMode && (
                <IconButton
                  variant="outlined"
                  color="primary"
                  disabled={addSubCategoryMode}
                  onClick={handleAddSubCategoryMode}
                >
                  <AddIcon />
                </IconButton>
              )}
            </div>
            <div className="CaseCategoriesList__items">
              {selectedCategory !== null && (
                <>
                  {addSubCategoryMode ? (
                    <>
                      <div>
                        <CaseSubCategoryItem
                          category={selectedCategory}
                          refetch={refetch}
                          organizationId={organizationId}
                          subCategory={emptyNewSubCategory}
                          modifiedSubCategory={modifiedSubCategory}
                          setModifiedSubCategory={setModifiedSubCategory}
                          disableAddSubCategoryMode={disableAddSubCategoryMode}
                          setIdToReopen={setIdToReopen}
                        />
                      </div>
                      <div className="CaseCategoriesList__disabled">
                        {selectedCategory.sub_categories.map((subCategory) => (
                          <CaseSubCategoryItem
                            key={subCategory.code}
                            category={selectedCategory}
                            refetch={refetch}
                            organizationId={organizationId}
                            subCategory={subCategory}
                            modifiedSubCategory={null}
                            setModifiedSubCategory={setModifiedSubCategory}
                            disableAddSubCategoryMode={
                              disableAddSubCategoryMode
                            }
                            setIdToReopen={setIdToReopen}
                          />
                        ))}
                      </div>
                    </>
                  ) : (
                    <>
                      {selectedCategory.sub_categories.length === 0 ? (
                        <CaseSubCategoryEmpty />
                      ) : (
                        <>
                          {selectedCategory.sub_categories.map(
                            (subCategory) => (
                              <CaseSubCategoryItem
                                key={subCategory.code}
                                category={selectedCategory}
                                refetch={refetch}
                                organizationId={organizationId}
                                subCategory={subCategory}
                                modifiedSubCategory={modifiedSubCategory}
                                setModifiedSubCategory={setModifiedSubCategory}
                                disableAddSubCategoryMode={
                                  disableAddSubCategoryMode
                                }
                                setIdToReopen={setIdToReopen}
                              />
                            )
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default CaseCategoriesList;
