import { useTypedContext } from 'hooks/useTypedContext';
import { IValidationSchemaBasedFormHookValues } from 'interfaces/forms';
import { OrganizationLicense } from 'interfaces/organizations';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import useUrlSearch from '../../../../../../../../../../../hooks/useUrlSearch';
import { OrganizationTypeContext } from '../../../../../../../types';
import {
  OrganizationRegisterStepsActionType,
  OrganizationRegisterStepsReducerContext,
} from '../../../../../store/reducers';
import { FormsNavigationContext } from '../../types';

export type CreateOrganizationFormValues = {
  name: string;
  description?: string;
  license: OrganizationLicense;
  tosAndPolicy: boolean;
};

export const useForm =
  (): IValidationSchemaBasedFormHookValues<CreateOrganizationFormValues> => {
    const intl = useIntl();

    const query = useUrlSearch();
    const mainOrganizationId = query.get('main_id') as string;

    const navigation = useTypedContext(FormsNavigationContext);
    const organizationType = useTypedContext(OrganizationTypeContext);

    const [state, dispatch] = useTypedContext(
      OrganizationRegisterStepsReducerContext
    );

    const invalidFieldValue = intl.formatMessage({
      id: 'General.InvalidFieldValue',
    });

    const validationSchema = Yup.object().shape({
      name: Yup.string().min(2, invalidFieldValue).required(invalidFieldValue),
      description: Yup.string().min(2, invalidFieldValue).optional(),
      license: Yup.mixed<OrganizationLicense>()
        .oneOf(Object.values(OrganizationLicense))
        .required(),
      tosAndPolicy: Yup.boolean().required().isTrue(),
    });

    const initialValues = useMemo((): CreateOrganizationFormValues => {
      return {
        name: state.createOrganization.name,
        description: state.createOrganization.description,
        license: OrganizationLicense.Standard,
        tosAndPolicy: !!state.createOrganization.name,
      };
    }, [state.createOrganization.description, state.createOrganization.name]);

    const handleSubmit = useCallback(
      (values: typeof initialValues) => {
        dispatch({
          type: OrganizationRegisterStepsActionType.SaveCreateOrganization,
          payload: {
            ...values,
            code: values.name.trim(),
            type: organizationType,
            main_organization_id: mainOrganizationId,
            logo_url: '',
            image_url: '',
            allow_self_grasp_provisioning: false,
          },
        });

        navigation.next();
      },
      [dispatch, mainOrganizationId, navigation, organizationType]
    );

    return {
      initialValues,
      handleSubmit,
      validationSchema,
    };
  };
