import QueryKeys from 'api/queries/queryKeys';
import { getProjectByCodes } from 'api/submissions';
import { IQueryRequest } from 'interfaces/request';
import { useQuery } from 'react-query';

interface UseProjectByCodeParams {
  organizationCode: string;
  projectCode: string;
}

const useProjectByCode = ({
  params,
  options,
}: IQueryRequest<UseProjectByCodeParams>) => {
  const { organizationCode, projectCode } = params;
  return useQuery({
    ...options,
    queryKey: [QueryKeys.getProject, organizationCode, projectCode],
    queryFn: async () => {
      const { data } = await getProjectByCodes(organizationCode, projectCode);
      return data.data;
    },
  });
};

export default useProjectByCode;
