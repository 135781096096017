//helpers
import useEditPersonality from 'api/mutations/membership/usePersonalityEdit';

//images
import editIcon from 'assets/images/edit_icon_transparent.svg';
import EditableInput from 'components/EditableInput/EditableInput';
import EditablePhoneInput from 'components/EditableInput/EditablePhoneInput';
import SkeletonPlaceholderOnLoad from 'components/SkeletonPlaceholderOnLoad';

//helpers
import { formatDateToInputDateString } from 'helpers/dates';
import { genderOptions } from 'helpers/utils/translationObject';
import useDescriptionEdit from 'hooks/useDescriptionEdit';

//types
import {
  IOrganizationPatientDetails,
  IPersonality,
  PatientFormKeys,
} from 'interfaces/personalities';
import { IPatchObject } from 'interfaces/request';
import React from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage, useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { QueryObserverResult, RefetchOptions } from 'react-query';
import { Button, Col, Row } from 'reactstrap';
import EditableCultureInput from '../../../../../components/EditableInput/EditableCultureInput';

//components
import SingleDescription from '../../../../../components/SingleDescription';
import PersonalityMainData from './PersonalityMainData';

type PatientDescriptionProps = {
  data: IOrganizationPatientDetails | undefined;
  isLoading: boolean;
  personalityId?: string;
  editDisabled?: boolean;
  toggleEdit?: () => void;
  refetchFunction?: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<IPersonality>>;
};

export const EmptyWrapper = ({ value, editDisabled, children }) => {
  if (editDisabled) {
    return <p>{value || '-'}</p>;
  } else {
    return children;
  }
};

const genderOptionsWithUnspecified = [
  {
    value: '',
    translationId: `Register.gender.Unspecified`,
  },
  ...genderOptions,
];

const PatientHeader: React.FC<PatientDescriptionProps> = ({
  data,
  isLoading,
  personalityId,
  editDisabled = true,
  toggleEdit,
  refetchFunction,
}) => {
  const intl = useIntl();

  const formattedGenderOptions = genderOptionsWithUnspecified.map((option) => {
    return {
      text: intl.formatMessage({ id: option.translationId }),
      value: option.value,
    };
  });

  const { mutate } = useEditPersonality(personalityId as string, async () => {
    await refetchFunction?.();
  });

  const submitFunction = (params: IPatchObject[]) => {
    mutate(params);
    if (toggleEdit) toggleEdit();
  };

  const { onCancel, onEdit, onSubmit } = useDescriptionEdit({
    submitFunction,
    cancelFb: toggleEdit,
  });

  return (
    <Row className="sharedGraspsPatient h-auto">
      <PersonalityMainData
        editDisabled={editDisabled}
        isLoading={isLoading}
        onEdit={onEdit}
        data={data}
      />
      <Col md={9} className="PersonalityHeader__container">
        <Row>
          {editDisabled && (
            <div className="w-100">
              <Button
                onClick={toggleEdit}
                color="primary"
                className="ms-auto edit-button"
              >
                <SVG src={editIcon} />
              </Button>
            </div>
          )}
          <Col sm={12} md={6} className="pe-4">
            <SingleDescription
              label={'Patient.FormLabels.gender'}
              labelFor={PatientFormKeys.GENDER}
            >
              <SkeletonPlaceholderOnLoad
                isLoading={isLoading}
                placeholder={<Skeleton width={100} />}
              >
                <EmptyWrapper value={data?.gender} editDisabled={editDisabled}>
                  <EditableInput
                    type="select"
                    disabled={editDisabled}
                    value={data?.gender}
                    options={formattedGenderOptions}
                    name={PatientFormKeys.GENDER}
                    onEdit={onEdit}
                    debounceEnabled={false}
                  />
                </EmptyWrapper>
              </SkeletonPlaceholderOnLoad>
            </SingleDescription>
            <SingleDescription
              label={'Patient.FormLabels.phoneNumber'}
              labelFor={PatientFormKeys.PHONE_NUMBER}
            >
              <SkeletonPlaceholderOnLoad
                isLoading={isLoading}
                placeholder={<Skeleton width={100} />}
              >
                <EmptyWrapper
                  value={data?.phone_number}
                  editDisabled={editDisabled}
                >
                  <EditablePhoneInput
                    name={PatientFormKeys.PHONE_NUMBER}
                    isDisabled={editDisabled}
                    value={data?.phone_number}
                    onEdit={onEdit}
                  />
                </EmptyWrapper>
              </SkeletonPlaceholderOnLoad>
            </SingleDescription>
            <SingleDescription label={'Patient.FormLabels.patientNumber'}>
              <SkeletonPlaceholderOnLoad
                isLoading={isLoading}
                placeholder={<Skeleton width={100} />}
              >
                <div>{data?.number}</div>
              </SkeletonPlaceholderOnLoad>
            </SingleDescription>
            <SingleDescription
              label={'Patient.FormLabels.culture'}
              labelFor={PatientFormKeys.CULTURE}
            >
              <SkeletonPlaceholderOnLoad
                isLoading={isLoading}
                placeholder={<Skeleton width={100} />}
              >
                <EmptyWrapper value={data?.culture} editDisabled={editDisabled}>
                  <EditableCultureInput
                    isDisabled={editDisabled}
                    value={data?.culture}
                    name={PatientFormKeys.CULTURE}
                    onEdit={onEdit}
                  />
                </EmptyWrapper>
              </SkeletonPlaceholderOnLoad>
            </SingleDescription>
          </Col>
          <Col sm={12} md={6}>
            <SingleDescription
              label={'Patient.FormLabels.birthDate'}
              labelFor={PatientFormKeys.BIRTH_DATE}
            >
              <SkeletonPlaceholderOnLoad
                isLoading={isLoading}
                placeholder={<Skeleton width={100} />}
              >
                <EmptyWrapper
                  value={
                    data?.birthdate
                      ? formatDateToInputDateString(new Date(data.birthdate))
                      : ''
                  }
                  editDisabled={editDisabled}
                >
                  <EditableInput
                    disabled={editDisabled}
                    type="date"
                    value={
                      data?.birthdate
                        ? formatDateToInputDateString(new Date(data.birthdate))
                        : ''
                    }
                    name={PatientFormKeys.BIRTH_DATE}
                    style={{
                      color: 'black !important',
                    }}
                    onEdit={onEdit}
                    debounceEnabled={false}
                  />
                </EmptyWrapper>
              </SkeletonPlaceholderOnLoad>
            </SingleDescription>
            <SingleDescription
              label={'Patient.FormLabels.address'}
              labelFor={PatientFormKeys.ADDRESS}
            >
              <SkeletonPlaceholderOnLoad
                isLoading={isLoading}
                placeholder={<Skeleton width={100} />}
              >
                <EmptyWrapper value={data?.address} editDisabled={editDisabled}>
                  <EditableInput
                    disabled={editDisabled}
                    value={data?.address}
                    name={PatientFormKeys.ADDRESS}
                    onEdit={onEdit}
                    type="textarea"
                    style={{
                      height: '80px',
                    }}
                    debounceEnabled={false}
                  />
                </EmptyWrapper>
              </SkeletonPlaceholderOnLoad>
            </SingleDescription>
            <SingleDescription
              label={'Patient.FormLabels.socialSecurityNumber'}
              labelFor={PatientFormKeys.SOCIAL_SECURITY_NUMBER}
            >
              <SkeletonPlaceholderOnLoad
                isLoading={isLoading}
                placeholder={<Skeleton width={100} />}
              >
                <EmptyWrapper value={data?.ssn} editDisabled={editDisabled}>
                  <EditableInput
                    disabled={editDisabled}
                    value={data?.ssn}
                    name={PatientFormKeys.SOCIAL_SECURITY_NUMBER}
                    onEdit={onEdit}
                    debounceEnabled={false}
                  />
                </EmptyWrapper>
              </SkeletonPlaceholderOnLoad>
            </SingleDescription>
          </Col>
          {!editDisabled && (
            <div className="w-100 d-flex justify-content-end mt-2">
              <Button className="me-2" color="danger" onClick={onCancel}>
                <FormattedMessage id="CaseCard.cancel" />
              </Button>
              <Button onClick={onSubmit}>
                <FormattedMessage id="CaseCard.submit" />
              </Button>
            </div>
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default PatientHeader;
