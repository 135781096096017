import React from 'react';
import { useParams } from 'react-router';
import { DashboardTemplate } from '../../../../../../interfaces/dashboards';
import FromPathParam from './components/FromPathParam';
import FromTemplates from './components/FromTemplates';
import { useDashboardLocationState } from './hooks/useDashboardLocationState';

export type DashboardPathParams = { dashboardId: string };

export type DashboardTemplateContainerProps = {
  onNotFound?: JSX.Element;
  children: (template: DashboardTemplate) => JSX.Element;
};

const DashboardTemplateContainer = ({
  onNotFound = <></>,
  children,
}: DashboardTemplateContainerProps) => {
  const locationState = useDashboardLocationState();
  const { dashboardId } = useParams<DashboardPathParams>();

  if (locationState) return children(locationState);

  if (dashboardId)
    return (
      <FromPathParam
        onNotFound={<span>Template not found - from path</span>}
        dashboardId={dashboardId}
        children={children}
      />
    );

  return (
    <FromTemplates
      onNotFound={<span>Template not found - from templates</span>}
      children={children}
    />
  );
};

export default DashboardTemplateContainer;
