import React from 'react';
import useTreatmentPhases from '../../../../../../../../api/queries/conversations/useTreatmentPhases';
import AddScheduleTemplateModal from '../../../../../../../../components/AddScheduleTemplateModal';
import { useInitQuery } from '../../../../../../../../hooks/queries/useInitQuery';
import useRequiredParams from '../../../../../../../../hooks/useRequiredParams';
import { IConversationTreatmentPhaseContentItem } from '../../../../../../../../interfaces/conversations';
import { useCaseGroupDetailsCommonData } from '../../../../hooks/useCaseGroupDetailsCommonData';
import { useCaseGroupDetailsAddTemplateForm } from './hooks/useCaseGroupDetailsAddTemplateForm';

type CaseGroupDetailsConversationSchedulesAddTemplateModalProps = {
  isOpen: boolean;
  toggle: () => void;
  phaseId: string;
  item?: IConversationTreatmentPhaseContentItem;
};

const CaseGroupDetailsConversationSchedulesAddTemplateModal = ({
  isOpen,
  toggle,
  phaseId,
  item,
}: CaseGroupDetailsConversationSchedulesAddTemplateModalProps) => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const { caseGroup } = useCaseGroupDetailsCommonData();

  const treatmentPhaseQuery = useTreatmentPhases({
    params: {
      organizationId,
      treatmentPlanId: caseGroup.data?.case_category_id ?? '',
    },
    options: {
      enabled: false,
      staleTime: Infinity,
    },
  });

  useInitQuery(treatmentPhaseQuery);

  const formHandler = useCaseGroupDetailsAddTemplateForm(
    phaseId,
    toggle,
    item,
    treatmentPhaseQuery?.data?.find((p) => p.id === phaseId)
  );

  return (
    <AddScheduleTemplateModal
      isOpen={isOpen}
      toggle={toggle}
      formHandler={formHandler}
    />
  );
};

export default CaseGroupDetailsConversationSchedulesAddTemplateModal;
