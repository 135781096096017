import React from 'react';
import MaterialTranslationsFormItem from './components/MaterialTranslationsFormItem';
import { Button, Label } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import useCultures from '../../../../../hooks/cultures/useCultures';
import { useUtils } from './hooks/useUtils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLanguage, faPlus } from '@fortawesome/pro-light-svg-icons';
import { GraspColors, graspColorsValues } from '../../../../../helpers/colors';
import { CultureEntry } from '../../../../../interfaces/cultures';

export interface IEducationTranslatable {
  translations: CultureEntry<{ name: string; description: string }>[];
}

type MaterialTranslationsFormProps<T> = {
  values: T;
  setValues: (data: any) => void;
};

const MaterialTranslationsForm = <T extends IEducationTranslatable>({
  values,
  setValues,
}: MaterialTranslationsFormProps<T>) => {
  const { defaultCulture, cultures } = useCultures();
  const { areTranslationsEmpty, handleAddTranslation } = useUtils(
    values,
    setValues
  );

  return (
    <div>
      <Label className="d-flex align-items-center gap-1">
        <FontAwesomeIcon
          icon={faLanguage}
          color={graspColorsValues[GraspColors.BLUE]}
        />
        <FormattedMessage id="ResourcesHub.educationItems.addMaterialModal.translations" />
        <div className="d-flex justify-content-end w-100">
          {values.translations.filter(
            (p) => p.culture !== defaultCulture.cultureCode
          ).length <
            cultures.length - 1 && (
            <Button
              disabled={areTranslationsEmpty(values)}
              color="primary"
              outline={true}
              onClick={handleAddTranslation}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          )}
        </div>
      </Label>
      {values.translations.map((translation, index) => (
        <MaterialTranslationsFormItem
          key={translation.culture}
          values={values}
          setValues={setValues}
          index={index}
          translation={translation}
        />
      ))}
    </div>
  );
};

export default MaterialTranslationsForm;
