import useCaseCategories from 'api/queries/cases/useCaseCategories';
import FieldInput from 'components/FieldInput';
import { Field, FieldProps } from 'formik';
import React from 'react';
import _ from 'lodash';

interface SubCategorySelectProps extends FieldProps {
  organizationId: string;
  caseCategoryPropName?: string;
  name: string;
}

const SubCategorySelect = ({
  field,
  form,
  organizationId,
  caseCategoryPropName = 'caseCategory',
}: SubCategorySelectProps) => {
  const { data } = useCaseCategories({ params: { organizationId } });

  const selectedCategoryId = _.get(form.values, caseCategoryPropName);

  const category = data?.find((item) => item.id === selectedCategoryId);

  const subCategoriesOptions =
    category?.sub_categories?.map(({ id, name }) => {
      return {
        value: id,
        text: name,
      };
    }) ?? [];

  const isDisabled = !selectedCategoryId || subCategoriesOptions.length === 0;

  return (
    <Field
      component={FieldInput}
      type={'select'}
      multiple={false}
      disabled={isDisabled}
      id={field.name}
      {...field}
    >
      <option value=""></option>
      {subCategoriesOptions &&
        subCategoriesOptions.map(({ value, text }) => {
          return (
            <option key={value} value={value}>
              {text}
            </option>
          );
        })}
    </Field>
  );
};

export default SubCategorySelect;
