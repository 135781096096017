import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { IPatchObject } from '../../../interfaces/request';
import { modifyAccessProfile } from '../../accessProfiles';
import { IMutationProps } from '../../../interfaces/ui';

export type ModifyAccessProfileBody = {
  accessProfileId: string;
  patch: IPatchObject[];
};

type useModifyAccessProfileParams = {
  organizationId: string;
};

export const useModifyAccessProfile = (
  params: useModifyAccessProfileParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (body: ModifyAccessProfileBody) => {
      await modifyAccessProfile(
        params.organizationId,
        body.accessProfileId,
        body.patch
      );
    },
    onSuccess: () => {
      if (options.successFb) options.successFb();
    },
    onErrorArray: (_) => {
      if (options.errorFb) options.errorFb();
    },
    resetOnResult: true,
  });
};
