import useCaseCategories from '../../api/queries/cases/useCaseCategories';
import { useMemo } from 'react';

export const useSubCategoriesOptions = (
  organizationId: string,
  caseCategoryId: string
) => {
  const { data: caseCategories } = useCaseCategories({
    params: { organizationId },
  });

  const selectedCategory = useMemo(() => {
    return caseCategories?.find((p) => p.id === caseCategoryId);
  }, [caseCategories, caseCategoryId]);

  return useMemo(() => {
    if (!selectedCategory) return [];

    return selectedCategory.sub_categories.map((p) => ({
      value: p.id,
      text: p.name,
    }));
  }, [selectedCategory]);
};
