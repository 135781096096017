import { IQueryRequest } from '../../../interfaces/request';
import { OrganizationQueryParams } from '../../../interfaces/queryParams';
import { useQuery } from 'react-query';
import { getGroupedConversationQuestions } from '../../conversations';
import QueryKeys from '../queryKeys';

const useGroupedConversationQuestions = ({
  params,
  options,
}: IQueryRequest<OrganizationQueryParams>) => {
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getGroupedConversationQuestions(
        params.organizationId
      );

      return data.data;
    },
    queryKey: [
      QueryKeys.getGroupedConversationQuestions,
      params.organizationId,
    ],
  });
};

export default useGroupedConversationQuestions;
