import { deleteCaseHistory } from 'api/cases';
import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from 'modules/Notifications';
import { IMutationProps } from '../../../interfaces/ui';

type useCaseHistoryDeleteParams = {
  organizationId: string;
  caseId: string;
};

const useCaseHistoryDelete = (
  params: useCaseHistoryDeleteParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (historyId: string) => {
      await deleteCaseHistory(params.organizationId, params.caseId, historyId);
    },
    onSuccess: () => {
      if (options.successFb) options.successFb();
      displaySuccessNotification('CaseCard.History.entryDeleted');
    },
    onError: () => {
      if (options.errorFb) options.errorFb();
      displayErrorNotification('CaseCard.History.entryDeletedFail');
    },
    resetOnResult: true,
  });
};

export default useCaseHistoryDelete;
