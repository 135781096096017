import React from 'react';
import { useIntl } from 'react-intl';
import SVG from 'react-inlinesvg';
import tooltipInfo from 'assets/images/tooltip_info.svg';

export type TabHeaderTooltip = {
  element: JSX.Element;
  targetId: string;
};

type TabHeaderProps = {
  header: string;
  subHeader: string;
  showSubHeader?: boolean;
  tooltip?: TabHeaderTooltip;
  actions?: JSX.Element;
};

const TabHeader = ({
  header,
  subHeader,
  showSubHeader = true,
  tooltip = undefined,
  actions = undefined,
}: TabHeaderProps) => {
  const intl = useIntl();

  return (
    <div className="OrganizationPageHeader">
      <div className="OrganizationPageHeader__header">
        <div className="OrganizationPageHeader__title">
          <span className="OrganizationPageHeader__text">{header}</span>
          {tooltip && (
            <div className="OrganizationPageHeader__tooltip">
              <div id={tooltip.targetId}>
                <SVG
                  src={tooltipInfo}
                  title={intl.formatMessage({
                    id: 'MyOrganizations.headerSectionInfo',
                  })}
                />
              </div>
              {tooltip.element}
            </div>
          )}
        </div>
        {showSubHeader && (
          <span className="OrganizationPageHeader__description">
            {subHeader}
          </span>
        )}
      </div>
      {actions && (
        <div className="OrganizationPageHeader__actions">{actions}</div>
      )}
    </div>
  );
};

export default TabHeader;
