import useNewAccessToken from 'api/mutations/membership/useNewAccessToken';
import useExternalAuthentication from 'api/queries/membership/useExternalAuthentication';
import backArrow from 'assets/images/back_arrow.svg';

import workMarkBlue from 'assets/images/work_marks/work_mark_blue.svg';
import MembershipLayout from 'components/layouts/MembershipLayout';
import useUrlSearch from 'hooks/useUrlSearch';
import { LoginMethod } from 'interfaces/membership';
import React, { useEffect, useRef } from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { ExternalProvider, returnIconBasedOnExternalProvider } from '.';
import LoginForm, { discoveryService } from './components/LoginForm';

const B2BLogin = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const query = useUrlSearch();
  const authCode = query.get('auth_code');
  const loginErrorCode = query.get('error_code');

  const { mutate: newTokenMutation, isError: isErrorNewToken } =
    useNewAccessToken({
      loginAs: LoginMethod.AsMedicalPersonnel,
    });

  const { data: externalAuthenticationSchemes } = useExternalAuthentication({
    enabled: true,
  });

  const loginAs = LoginMethod.AsMedicalPersonnel;

  const formRef = useRef<HTMLFormElement | null>(null);
  const form = (
    <form
      style={{
        display: 'hidden',
      }}
      ref={formRef}
      method="post"
    ></form>
  );

  const onExternalLoginClick = async (name: string) => {
    const redirectionPath = `${window.location.origin}/login/b2b`;

    await discoveryService(() => {
      if (formRef && formRef.current) {
        formRef.current.action = `${localStorage.getItem(
          'api_url'
        )}/api/users/external_login/${name}?returnUrl=${encodeURIComponent(
          redirectionPath
        )}`;
        formRef.current.submit();
      }
    });
  };

  const handleGoToLogin = () => {
    navigate('/login');
  };

  const b2bSchemes =
    externalAuthenticationSchemes?.filter((p) => p.is_b2_b) ?? [];

  useEffect(() => {
    if (authCode) {
      newTokenMutation({
        token: authCode,
      });
    }
  }, [authCode, newTokenMutation]);

  if (authCode && !isErrorNewToken) return null;

  const renderAuthSchemes = b2bSchemes.map((scheme) => {
    const handleExternalLogin = async () => {
      await onExternalLoginClick(scheme.name);
    };

    return (
      <button
        key={scheme.name}
        className="PatientsLogin__wrapper__form__providers__provider"
        onClick={handleExternalLogin}
      >
        {returnIconBasedOnExternalProvider(ExternalProvider[scheme.name])}
        <span>{scheme.display_name}</span>
      </button>
    );
  });

  return (
    <MembershipLayout>
      <div className="MedicalPersonnelLogin">
        <div className="MedicalPersonnelLogin__top-bar">
          <div
            className="MedicalPersonnelLogin__top-bar__back"
            onClick={handleGoToLogin}
          >
            <SVG src={backArrow} />
            <FormattedMessage id="Login.back" />
          </div>
          <div className="MedicalPersonnelLogin__top-bar__logo">
            <div className="MedicalPersonnelLogin__top-bar__logo__logo-wrapper">
              <div className="MedicalPersonnelLogin__wrapper__form__header MedicalPersonnelLogin__wrapper__form__header--landscape ms-2">
                <span className="MedicalPersonnelLogin__wrapper__form__header__log-in-as">
                  <FormattedMessage id="Login.loginAs" />
                </span>
                <span className="MedicalPersonnelLogin__wrapper__form__header__log-in-as MedicalPersonnelLogin__wrapper__form__header__log-in-as--bold">
                  <FormattedMessage id="Login.asAMedicalPersonnel" />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="MedicalPersonnelLogin__wrapper">
          <div className="MedicalPersonnelLogin__wrapper__form">
            <div className="MedicalPersonnelLogin__wrapper__form__icon">
              <SVG
                src={workMarkBlue}
                title={intl.formatMessage({
                  id: 'MyOrganizations.Logo',
                })}
              />
            </div>
            <div className="MedicalPersonnelLogin__wrapper__form__header">
              <span className="MedicalPersonnelLogin__wrapper__form__header__log-in-as">
                <FormattedMessage id="Login.loginAs" />
              </span>
              <span className="MedicalPersonnelLogin__wrapper__form__header__log-in-as MedicalPersonnelLogin__wrapper__form__header__log-in-as--caption">
                <FormattedMessage id="Login.asAMedicalPersonnel" />
              </span>
            </div>
            {b2bSchemes.length > 0 && (
              <>
                <span className="MedicalPersonnelLogin__wrapper__form__subheader">
                  <FormattedMessage id="Login.useProviders" />
                </span>
                <div className="MedicalPersonnelLogin__wrapper__form__providers">
                  {renderAuthSchemes}
                  {form}
                </div>
              </>
            )}
            <div className="MedicalPersonnelLogin__wrapper__form__use-organization-email">
              <div className="MedicalPersonnelLogin__wrapper__form__use-organization-email__dash"></div>
              <span className="MedicalPersonnelLogin__wrapper__form__use-organization-email__text">
                <FormattedMessage
                  id={
                    b2bSchemes.length > 0
                      ? 'Login.useOrganizationEmail'
                      : 'Login.useOrganizationEmailWithoutProviders'
                  }
                />
              </span>
              <div className="MedicalPersonnelLogin__wrapper__form__use-organization-email__dash"></div>
            </div>
            <div className="MedicalPersonnelLogin__wrapper__form__login">
              <LoginForm loginAs={loginAs} loginErrorCode={loginErrorCode} />
            </div>
          </div>
        </div>
      </div>
    </MembershipLayout>
  );
};

export default B2BLogin;
