import React from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage, useIntl } from 'react-intl';

type TrendVisualizationTabSectionHeaderProps = {
  icon: string;
  textId: string;
  additionalContent?: JSX.Element;
};

const ItemSection = ({
  icon,
  textId,
  additionalContent,
}: TrendVisualizationTabSectionHeaderProps) => {
  const intl = useIntl();

  return (
    <div className="TrendVisualizationTabSectionHeader">
      <div className="TrendVisualizationTabSectionHeader__content">
        <SVG
          src={icon}
          title={intl.formatMessage({
            id: textId,
          })}
          className="TrendVisualizationTabSectionHeader__icon"
        />
        <span className="TrendVisualizationTabSectionHeader__text">
          <FormattedMessage id={textId} />
        </span>
      </div>
      {additionalContent && (
        <div className="TrendVisualizationTabSectionHeader__additional-content">
          {additionalContent}
        </div>
      )}
    </div>
  );
};

export default ItemSection;
