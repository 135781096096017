import React from 'react';
import { Spinner } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroller';

//helpers
import useRefreshOrganizationToken from 'api/mutations/membership/useRefreshOrganizationToken';
import useInfiniteUserOrganizations from 'api/queries/organizations/useInfiniteUserOrganizations';

//components
import Image from 'components/Image';

//helpers
import { getUserId } from 'helpers/utils/auth';

//components
import TabLayout from './TabLayout';
import CustomTable from 'components/CustomTable';

const headerItems = [
  'MyOrganizations.logo',
  'MyOrganizations.name',
  'MyOrganizations.description',
  'MyOrganizations.status',
];

const MyOrganizationTab = () => {
  const userId = getUserId();

  const { organizations, hasMore, fetchNextPage } =
    useInfiniteUserOrganizations({ params: { userId } });

  const { mutate: changeOrganizationTokenMutation } =
    useRefreshOrganizationToken({ myOrganizationScreen: true });

  const onOrganizationRedirect = (organizationId: string) => {
    changeOrganizationTokenMutation(organizationId);
  };

  const bodyRows = organizations.map(({ organization }) => {
    return (
      <tr
        key={organization.id}
        onClick={() => onOrganizationRedirect(organization.id)}
      >
        <td>
          <Image
            src={organization.logo_url}
            alt={`organization_${organization.name}_logo`}
            style={{
              width: '60px',
            }}
          />
        </td>
        <td>{organization.name}</td>
        <td>{organization.description}</td>
        <td>{organization.status}</td>
      </tr>
    );
  });

  const loadMore = () => fetchNextPage();

  return (
    <TabLayout title="MyProfile.Tabs.myOrganizations">
      <InfiniteScroll
        loadMore={loadMore}
        hasMore={hasMore}
        loader={<Spinner aria-label="loading" key="infinite-loader" />}
        threshold={100}
        initialLoad={false}
      >
        <CustomTable headerItems={headerItems} bodyRows={bodyRows} />
      </InfiniteScroll>
    </TabLayout>
  );
};

export default MyOrganizationTab;
