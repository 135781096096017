import React from 'react';
import ItemContainer from '../ItemContainer';
import donutIcon from 'assets/images/donut.svg';
import SVG from 'react-inlinesvg';
import { useSegmentSelection } from '../../../../../../../../hooks/charts/pieChart/useSegmentSelection';
import {
  Cell,
  Label,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
} from 'recharts';
import PieChartActiveShape from '../../../../../../../../components/Charts/PieChart/PieChartActiveShape';
import { useFormattedData } from './hooks/useFormattedData';
import { useGetDataColor } from './hooks/useGetDataColor';
import { usePieChartSum } from '../../../../../../../../hooks/charts/pieChart/usePieChartSum';
import { useTranslations } from '../../../../../../../../hooks/useTranslations';
import { secondsToDurationString } from '../../../../../../../../helpers/dates';
import { useCookie } from '../../../../../../../../hooks/useCookie';
import { AwareCaseObservationsProps } from '../AwareCaseContent/types';
import { ViewTheme } from '../../../../../../../../interfaces/ui';

const Duration = ({ observations }: AwareCaseObservationsProps) => {
  const { selectedSegmentIndex, handleSelectSegment, handleResetSegment } =
    useSegmentSelection();

  const formattedData = useFormattedData(observations);
  const getColor = useGetDataColor();
  const totalSum = usePieChartSum(formattedData);

  const theme = useCookie<ViewTheme>('theme');

  const { attachPrefix, wrapWithIntl } = useTranslations(
    'Cases.CaseDetails.AwareCase.Duration'
  );

  const durationPieChartActiveShape = (props: any) =>
    PieChartActiveShape({ ...props, valueFormatter: secondsToDurationString });

  return (
    <ItemContainer
      title={wrapWithIntl(attachPrefix('duration'))}
      icon={<SVG src={donutIcon} />}
    >
      <ResponsiveContainer width="100%" height="100%">
        <PieChart style={{ strokeWidth: 8 }}>
          <Pie
            activeIndex={selectedSegmentIndex}
            activeShape={durationPieChartActiveShape}
            cy="50%"
            cx="50%"
            data={formattedData}
            labelLine={false}
            innerRadius="50%"
            outerRadius="85%"
            stroke={theme === ViewTheme.Dark ? '#212529' : '#fff'}
            dataKey="value"
            strokeWidth={5}
            cornerRadius={3}
            onMouseEnter={handleSelectSegment}
            onMouseLeave={handleResetSegment}
          >
            {formattedData.map((entry) => (
              <Cell key={`cell-${entry.name}`} fill={getColor(entry)} />
            ))}
            {selectedSegmentIndex === undefined && (
              <Label
                value={secondsToDurationString(totalSum)}
                offset={0}
                position="center"
                fontSize={20}
                fill={theme === ViewTheme.Dark ? '#fff' : '#313131'}
              />
            )}
          </Pie>
          <Legend
            layout="horizontal"
            align="center"
            verticalAlign="bottom"
            iconType="circle"
          />
        </PieChart>
      </ResponsiveContainer>
    </ItemContainer>
  );
};

export default Duration;
