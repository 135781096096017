import { IUpsertDashboardReportSchedule } from '../../../interfaces/dashboards';
import { OrganizationQueryParams } from '../../../interfaces/queryParams';
import { IMutationRequest } from '../../../interfaces/request';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { upsertDashboardReportSchedule } from '../../dashboards';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

const useUpsertDashboardReportSchedule = ({
  params,
  options,
  silent = false,
}: IMutationRequest<OrganizationQueryParams>) => {
  return useMutationWithResultNotification<
    IUpsertDashboardReportSchedule,
    void
  >({
    asyncApiFunction: async (upsertSchedule) => {
      await upsertDashboardReportSchedule(
        params.organizationId,
        upsertSchedule
      );
    },
    onError: () => {
      if (!silent) {
        displayErrorNotification(
          'Api.Mutations.Dashboards.UpsertDashboardReportSchedule.failure'
        );
      }
      options.errorFb?.();
    },
    onSuccess: () => {
      if (!silent) {
        displaySuccessNotification(
          'Api.Mutations.Dashboards.UpsertDashboardReportSchedule.success'
        );
      }
      options.successFb?.();
    },
    resetOnResult: true,
  });
};

export default useUpsertDashboardReportSchedule;
