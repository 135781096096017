import { Field, FieldProps } from 'formik';
import { IOption } from 'interfaces/ui';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import FieldInput from './FieldInput';

interface FieldSelectProps extends Partial<FieldProps> {
  name: string;
  defaultText: string;
  isLoading?: boolean;
  required?: boolean;
  options: IOption[];
  disabled?: boolean;
  id?: string;
  displayEmpty?: boolean;
  addon?: JSX.Element;
}

const FieldSelect = ({
  name,
  options,
  defaultText,
  disabled = false,
  isLoading = false,
  required = false,
  displayEmpty = true,
  addon,
  ...rest
}: FieldSelectProps) => {
  const intl = useIntl();

  const renderedOptions = useMemo(() => {
    const selectOptions: JSX.Element[] = [];
    if (displayEmpty) {
      selectOptions.push(
        <option key="empty" disabled={required} value="">
          {defaultText}
        </option>
      );
    }

    options.forEach(({ translationId, value, text, icon }) => {
      selectOptions.push(
        <option key={value} value={value ?? undefined}>
          {icon}
          {translationId &&
            intl.formatMessage({
              id: translationId,
            })}
          {text ?? ''}
        </option>
      );
    });

    return selectOptions;
  }, [displayEmpty, options, required, defaultText, intl]);

  return (
    <>
      <Field
        component={FieldInput}
        type={'select'}
        name={name}
        multiple={false}
        required={required}
        disabled={disabled}
        isLoading={isLoading}
        addon={addon}
        {...rest}
      >
        {renderedOptions}
      </Field>
    </>
  );
};

export default FieldSelect;
