import React from 'react';
import {
  IAsset,
  IEducationMaterial,
} from '../../../../../interfaces/educationMaterials';
import AssetCultureSelector from './components/AssetCultureSelector';
import useCultures from '../../../../../hooks/cultures/useCultures';

type AssetCulturesSelectorProps = {
  asset: IAsset;
  material: IEducationMaterial;
  onLink?: (culture: string) => void;
  onUnlink?: (culture: string) => void;
};

const AssetCulturesSelector = ({
  material,
  asset,
  onLink,
  onUnlink,
}: AssetCulturesSelectorProps) => {
  const { cultures } = useCultures();

  return (
    <>
      {cultures.map((culture) => (
        <AssetCultureSelector
          key={`${material.id}|${asset.id}|${culture.cultureCode}`}
          material={material}
          asset={asset}
          culture={culture}
          onLink={onLink}
          onUnlink={onUnlink}
        />
      ))}
    </>
  );
};

export default AssetCulturesSelector;
