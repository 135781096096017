import React from 'react';
import { OrganizationType } from 'interfaces/organizations';
import useUrlSearch from 'hooks/useUrlSearch';
import Placeholders from 'components/Placeholders';

type OrganizationTypeProviderProps = {
  children: (type: OrganizationType) => JSX.Element;
};

const OrganizationTypeProvider = ({
  children,
}: OrganizationTypeProviderProps) => {
  const query = useUrlSearch();
  const organizationType = query.get('type') as string;

  const organizationValues = Object.values(OrganizationType).map((p) =>
    p.toString()
  );

  if (!organizationValues.includes(organizationType))
    return <Placeholders.InvalidUrlPath />;

  return children(organizationType as OrganizationType);
};

export default OrganizationTypeProvider;
