import React, { useMemo } from 'react';
import {
  Cell,
  Label,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
} from 'recharts';
import ItemPlaceholder from '../../../../modules/CaseDashboard/components/CaseDashboardTabs/components/ItemPlaceholder';
import { FormattedMessage } from 'react-intl';
import AllSqueezesChartLabel from './components/AllSqueezesChartLabel';
import { useAllSqueezesChart } from './hooks/useAllSqueezesChart';
import { textWrapEllipse } from '../../../../helpers/utils/strings';
import useBreakpoint from '../../../../hooks/useBreakpoint';
import { AllSqueezesMode, AllSqueezesQueries } from '../../types';

type AllSqueezesChartFigureProps = {
  date: Date;
  mode: AllSqueezesMode;
  queries: AllSqueezesQueries;
};

const AllSqueezesChartFigure = ({
  date,
  mode,
  queries,
}: AllSqueezesChartFigureProps) => {
  const { formattedData, isLoading, totalSum, getColorForMeaning } =
    useAllSqueezesChart(date, mode, queries);

  const isMobileLg = useBreakpoint(550);
  const containerHeight = useMemo(() => (isMobileLg ? 200 : 300), [isMobileLg]);

  const legendFormatter = (props: any) => textWrapEllipse(props, 20);

  if (isLoading) return <ItemPlaceholder title={false} />;

  return (
    <div
      className="AllSqueezesChart d-flex justify-content-center align-items-center w-100"
      style={{ height: containerHeight }}
    >
      <div className="w-100 h-100">
        {totalSum === 0 ? (
          <div className="d-flex h-100 flex-column align-items-center justify-content-center">
            <div
              style={{
                zIndex: 1,
              }}
            >
              <div className="ChangeCase__chart-placeholder__bubble" />
            </div>
            <div className="ChangeCase__chart-placeholder__text">
              <FormattedMessage id="Cases.ChangeCase.noDataYet" />
            </div>
          </div>
        ) : (
          <ResponsiveContainer width="100%" height="100%">
            <PieChart style={{ strokeWidth: 8 }}>
              <Pie
                cy="50%"
                cx="50%"
                data={formattedData}
                labelLine={false}
                label={<AllSqueezesChartLabel />}
                innerRadius="40%"
                outerRadius="75%"
                fill="#8884d8"
                dataKey="value"
                cornerRadius={8}
              >
                {formattedData.map((entry) => (
                  <Cell
                    key={`cell-${entry.name}`}
                    fill={getColorForMeaning(entry)}
                  />
                ))}
                <Label
                  value={totalSum}
                  fontSize={30}
                  offset={0}
                  position="center"
                />
              </Pie>
              <Legend
                layout="vertical"
                align="left"
                iconType="circle"
                verticalAlign="middle"
                formatter={legendFormatter}
                wrapperStyle={{
                  top: '60%',
                  left: '25%',
                  transform: 'translate(-50%, -50%)',
                }}
              />
            </PieChart>
          </ResponsiveContainer>
        )}
      </div>
    </div>
  );
};

export default AllSqueezesChartFigure;
