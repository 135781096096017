import { useCommonData } from '../../../../../hooks/useCommonData';
import { useCommonParams } from '../../../../../hooks/useCommonParams';
import useDeleteAsset from '../../../../../../../api/mutations/assets/useDeleteAsset';

export const useRemoveAsset = (assetId: string) => {
  const { organizationId } = useCommonParams();
  const { educationMaterials } = useCommonData();

  const handleSuccess = async () => {
    await educationMaterials.refetch();
  };

  const { mutateAsync: removeAssetAsync, isLoading: isRemoving } =
    useDeleteAsset({
      params: { organizationId },
      options: { successFb: handleSuccess },
    });

  const handleRemove = async () => {
    await removeAssetAsync(assetId);
  };

  return { handleRemove, isRemoving };
};
