import { ITimelineExtended } from 'interfaces/timeline';
import React from 'react';
import { Input } from 'reactstrap';

type TimelinesRowsProps = {
  timelinesChecked: string[];
  onTimelineChange: (timelineId: string) => void;
  disabled?: boolean;
  timelines: ITimelineExtended[] | undefined;
};

const TimelinesRows = ({
  timelines,
  timelinesChecked,
  onTimelineChange,
  disabled,
}: TimelinesRowsProps) => {
  const renderedTimelines =
    timelines && timelines.length > 0
      ? timelines.map((timeline) => (
          <tr key={timeline.id}>
            <td>
              <label htmlFor={`checkbox_${timeline.id}`}>{timeline.name}</label>
            </td>
            <td className="d-flex justify-content-center">
              <Input
                id={`checkbox_${timeline.id}`}
                disabled={disabled}
                onChange={() => onTimelineChange(timeline.id)}
                type="checkbox"
                checked={timelinesChecked.includes(timeline.id)}
              />
            </td>
          </tr>
        ))
      : null;

  return <>{renderedTimelines}</>;
};

export default TimelinesRows;
