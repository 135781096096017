import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { IPatchObject } from '../../../interfaces/request';
import { editCaseSubCategory } from '../../caseCategories';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { IMutationProps } from '../../../interfaces/ui';

type usePatchCaseSubCategoryParams = {
  organizationId: string;
  subCategoryId: string;
};

const usePatchCaseSubCategory = (
  params: usePatchCaseSubCategoryParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification<IPatchObject[], void>({
    asyncApiFunction: async (patchData) => {
      await editCaseSubCategory(
        params.organizationId,
        params.subCategoryId,
        patchData
      );
    },
    resetOnResult: true,
    onError: () => {
      displayErrorNotification('MyOrganizations.caseCategories.patchFailure');
    },
    onSuccess: () => {
      if (options.successFb) options.successFb();

      displaySuccessNotification('MyOrganizations.caseCategories.patchSuccess');
    },
  });
};

export default usePatchCaseSubCategory;
