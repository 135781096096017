// components
import CustomBrowserRouter from 'components/CustomBrowserRouter';
import SimpleHeaderLayout from 'components/layouts/SimpleHeaderLayout';
import NotAuthorized from 'components/NotAuthorized';
import TimelineDetailsAnnotationProxy from 'components/Proxies/TimelineDetailsAnnotationProxy';

// proxies
import TimelineDetailsAssessmentProxy from 'components/Proxies/TimelineDetailsAssessmentProxy';
import AuthRouteGuard from 'components/routes/AuthRouteGuard';
import NonAuthRouteGuard from 'components/routes/NonAuthRouteGuard';
import RequiredParamsRouteGuard from 'components/routes/RequiredParamsRouteGuard';
import RoleRouteGuard from 'components/routes/RoleRouteGuard';
import history from 'helpers/history';
import { RouteDefinitions } from 'helpers/routeDefinitions';
import { getAccessToken, getTokenData } from 'helpers/utils/auth';

//helpers
import { checkBrowser } from 'helpers/utils/ui';

//helpers
import useHandleShareGraspEvent from 'hooks/useHandleShareGraspEvent';

//types
import { UserRoles } from 'interfaces/membership';
import ConversationsTab from 'modules/Cases/pages/CaseDetailsPage/views/ChangeCaseDetailsView/tabs/ChangeCaseDetailsTabs/tabs/ConversationsTab';
import ScheduleTab from 'modules/Cases/pages/CaseDetailsPage/views/ChangeCaseDetailsView/tabs/ChangeCaseDetailsTabs/tabs/ScheduleTab';
import GraspDetails from 'modules/Grasps/components/OrganizationGraspDetails';
import InvitationScreen from 'modules/membership/Invitation';
import B2BLogin from 'modules/membership/Login/B2BLogin';
import B2CLogin from 'modules/membership/Login/B2CLogin';
import SetPassword from 'modules/membership/SetPassword';
import OrganizationDetailsView from 'modules/Organizations/views/OrganizationDetailsView';
import StatusCheck from 'modules/Participants/components/StatusCheck';
import OrganizationProjectUsers from 'modules/ProjectSubmissions';
import ProjectSubmissionRegister from 'modules/ProjectSubmissions/components/ProjectSubmissionRegister';
import SharingRequests from 'modules/Requests/SharingRequests';
import TreatmentPlan from 'modules/TreatmentPlans/components/TreatmentPlan';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { setBrowser } from 'store/ui/action';
import DashboardProxy from './components/Dashboard/components/DashboardProxy';
import DentalOrganizationProxy from './components/Proxies/DentalOrganizationProxy';
import FunctionalityRouteGuard from './components/routes/FunctionalityRouteGuard';
import ScrollToTop from './helpers/ScrollToTop';
import { FunctionalityCode } from './interfaces/functionalities';
import CaseDashboard from './modules/CaseDashboard';
import CaseGroupsTab from './modules/CaseGroups';
import CaseGroupDetails from './modules/CaseGroups/components/CaseGroupDetails';
import CaseDetailsPage from './modules/Cases/pages/CaseDetailsPage';
import EventLogsTab from './modules/Cases/pages/CaseDetailsPage/views/ChangeCaseDetailsView/tabs/ChangeCaseDetailsTabs/tabs/EventLogsTab';
import CasesListView from './modules/Cases/pages/CasesListPage/views/CasesListView';
import ConversationsAndFeedbacks from './modules/ConversationsAndFeedbacks';
import DashboardsList from './modules/Dashboards/components/DashboardsList';
import Education from './modules/Education';
import ExerciseSession from './modules/ExerciseSession';
import OrganizationGrasps from './modules/Grasps';
import HelpCentreView from './modules/Help/pages/HelpCentrePage/views/HelpCentreView';
import ConfirmEmail from './modules/membership/ConfirmEmail';

// pages
import Login from './modules/membership/Login';
import MyAccount from './modules/membership/MyAccount';
import Register from './modules/membership/Register';
import ResetPassword from './modules/membership/ResetPassword';
import OrganizationRegister from './modules/Organizations/views/OrganizationRegisterView';
import Participants from './modules/Participants';
import CreateParticipant from './modules/Participants/components/CreateParticipant';
import PersonalityDetails from './modules/Participants/components/OrganizationPersonality';
import Projects from './modules/Projects';
import Reports from './modules/Reports';
import TimelineDetails from './modules/TimelineDetails';
import TreatmentPlans from './modules/TreatmentPlans';
import Dashboard from './modules/UserDashboard';
import UsersAndAccess from './modules/UsersAndAccess';
import AwareCasesListView from './modules/Cases/pages/CasesListPage/views/AwareCasesListView';

const App = () => {
  const dispatch = useDispatch();
  useHandleShareGraspEvent();

  useEffect(() => {
    const isBrowser = checkBrowser();
    dispatch(setBrowser(isBrowser));
  }, [dispatch]);

  useEffect(() => {
    const token = getAccessToken();
    getTokenData(token);
  }, []);

  return (
    <CustomBrowserRouter history={history}>
      <ScrollToTop />
      <div id="screen-root">
        <Routes>
          <Route element={<NonAuthRouteGuard />}>
            <Route path={RouteDefinitions.LOGIN_B2B} element={<B2BLogin />} />
            <Route path={RouteDefinitions.LOGIN_B2C} element={<B2CLogin />} />
            <Route path={RouteDefinitions.LOGIN} element={<Login />} />
            <Route
              path={RouteDefinitions.REGISTER_IN_PROJECT}
              element={<ProjectSubmissionRegister />}
            />
            <Route path={RouteDefinitions.REGISTER} element={<Register />} />
            <Route
              path={RouteDefinitions.ORGANIZATION_REGISTER}
              element={
                <RequiredParamsRouteGuard params={['main_id', 'type']}>
                  <OrganizationRegister />
                </RequiredParamsRouteGuard>
              }
            />
            <Route
              path={RouteDefinitions.EMAIL_CONFIRMATION}
              element={<ConfirmEmail />}
            />
            <Route
              path={RouteDefinitions.PASSWORD_RESET}
              element={<ResetPassword />}
            />
            <Route
              path={RouteDefinitions.SET_PASSWORD}
              element={<SetPassword />}
            />
          </Route>
          <Route
            path={RouteDefinitions.ASK_FOR_HELP}
            element={<HelpCentreView />}
          />
          <Route
            path={RouteDefinitions.ORGANIZATION_INVITATION}
            element={<InvitationScreen />}
          />
          <Route element={<AuthRouteGuard />}>
            <Route
              path={RouteDefinitions.DASHBOARD}
              element={
                <SimpleHeaderLayout>
                  <Dashboard />
                </SimpleHeaderLayout>
              }
            />
            <Route
              path={RouteDefinitions.CASE_DASHBOARD}
              element={
                <SimpleHeaderLayout>
                  <CaseDashboard />
                </SimpleHeaderLayout>
              }
            />
            <Route
              path={RouteDefinitions.CASE_EXERCISE_SESSION}
              element={
                <SimpleHeaderLayout>
                  <ExerciseSession />
                </SimpleHeaderLayout>
              }
            />
            <Route
              path={RouteDefinitions.MY_ACCOUNT}
              element={
                <SimpleHeaderLayout>
                  <MyAccount />
                </SimpleHeaderLayout>
              }
            />
            <Route
              path={RouteDefinitions.ORGANIZATION_CONVERSATIONS_AND_FEEDBACKS}
              element={
                <SimpleHeaderLayout>
                  <ConversationsAndFeedbacks />
                </SimpleHeaderLayout>
              }
            />
            <Route
              path={RouteDefinitions.USER_TIMELINE}
              element={
                <SimpleHeaderLayout>
                  <TimelineDetails />
                </SimpleHeaderLayout>
              }
            />
            <Route
              path={RouteDefinitions.SHARING_REQUESTS}
              element={
                <SimpleHeaderLayout>
                  <SharingRequests />
                </SimpleHeaderLayout>
              }
            />
            <Route
              path={RouteDefinitions.TREATMENT_PLANS}
              element={
                <RoleRouteGuard
                  role={UserRoles.ORGANIZATION_ADMIN}
                  redirectTo={RouteDefinitions.ORGANIZATION_CASES}
                />
              }
            >
              <Route
                path={RouteDefinitions.TREATMENT_PLANS}
                element={
                  <SimpleHeaderLayout>
                    <TreatmentPlans />
                  </SimpleHeaderLayout>
                }
              />
            </Route>
            <Route
              path={RouteDefinitions.TREATMENT_PLAN}
              element={
                <RoleRouteGuard
                  role={UserRoles.ORGANIZATION_ADMIN}
                  redirectTo={RouteDefinitions.ORGANIZATION_CASES}
                />
              }
            >
              <Route
                path={RouteDefinitions.TREATMENT_PLAN}
                element={
                  <SimpleHeaderLayout containerClassNames="mb-1">
                    <TreatmentPlan />
                  </SimpleHeaderLayout>
                }
              />
            </Route>
            <Route
              path={RouteDefinitions.MY_ORGANIZATION}
              element={
                <RoleRouteGuard
                  role={UserRoles.ORGANIZATION_ADMIN}
                  fallback={<NotAuthorized />}
                />
              }
            >
              <Route
                path={RouteDefinitions.MY_ORGANIZATION}
                element={
                  <SimpleHeaderLayout
                    allowToDarkMode={true}
                    embeddedContainerClassNames="m-0"
                    embeddedLayoutClassNames="p-md-0 pb-md-4"
                  >
                    <OrganizationDetailsView />
                  </SimpleHeaderLayout>
                }
              />
            </Route>
            <Route
              path={RouteDefinitions.ORGANIZATION_PROJECT_SUBMISSIONS}
              element={
                <FunctionalityRouteGuard
                  code={FunctionalityCode.PROJECT_SUBMISSIONS_LIST_VIEW}
                >
                  <SimpleHeaderLayout>
                    <OrganizationProjectUsers />
                  </SimpleHeaderLayout>
                </FunctionalityRouteGuard>
              }
            />
            <Route
              path={RouteDefinitions.ORGANIZATION_EDUCATION}
              element={
                <SimpleHeaderLayout>
                  <Education />
                </SimpleHeaderLayout>
              }
            />
            <Route
              path={RouteDefinitions.ORGANIZATION_PROJECTS}
              element={
                <SimpleHeaderLayout>
                  <Projects />
                </SimpleHeaderLayout>
              }
            />
            <Route element={<RoleRouteGuard role={UserRoles.GRASP_MANAGER} />}>
              <Route
                path={RouteDefinitions.ORGANIZATION_GRASPS}
                element={
                  <SimpleHeaderLayout>
                    <OrganizationGrasps />
                  </SimpleHeaderLayout>
                }
              />
              <Route
                path={RouteDefinitions.ORGANIZATION_USERS_AND_ACCESS}
                element={
                  <SimpleHeaderLayout>
                    <UsersAndAccess />
                  </SimpleHeaderLayout>
                }
              />
              <Route
                path={RouteDefinitions.ORGANIZATION_PERSONALITIES}
                element={
                  <FunctionalityRouteGuard
                    code={FunctionalityCode.PARTICIPANTS_LIST_VIEW}
                  >
                    <SimpleHeaderLayout>
                      <Participants />
                    </SimpleHeaderLayout>
                  </FunctionalityRouteGuard>
                }
              />
              <Route
                path={RouteDefinitions.ORGANIZATION_CASE_GROUPS}
                element={
                  <FunctionalityRouteGuard
                    code={FunctionalityCode.CASE_GROUPS_LIST_VIEW}
                  >
                    <SimpleHeaderLayout>
                      <CaseGroupsTab />
                    </SimpleHeaderLayout>
                  </FunctionalityRouteGuard>
                }
              />
              <Route
                path={RouteDefinitions.ORGANIZATION_PATIENTS}
                element={
                  <FunctionalityRouteGuard
                    code={FunctionalityCode.PARTICIPANTS_LIST_VIEW}
                  >
                    <SimpleHeaderLayout>
                      <Participants />
                    </SimpleHeaderLayout>
                  </FunctionalityRouteGuard>
                }
              />
              <Route
                path={RouteDefinitions.ORGANIZATION_PARTICIPANTS}
                element={
                  <FunctionalityRouteGuard
                    code={FunctionalityCode.PARTICIPANTS_LIST_VIEW}
                  >
                    <SimpleHeaderLayout>
                      <Participants />
                    </SimpleHeaderLayout>
                  </FunctionalityRouteGuard>
                }
              />
              <Route
                path={RouteDefinitions.DENTAL_ORGANIZATION}
                element={
                  <SimpleHeaderLayout
                    allowToDarkMode={true}
                    embeddedContainerClassNames="m-0"
                    embeddedLayoutClassNames="p-md-0 pb-md-4"
                  >
                    <DentalOrganizationProxy />
                  </SimpleHeaderLayout>
                }
              />
              <Route
                path={RouteDefinitions.CREATE_PERSONALITY_IN_ORGANIZATION}
                element={
                  <SimpleHeaderLayout>
                    <CreateParticipant />
                  </SimpleHeaderLayout>
                }
              />
              <Route
                path={RouteDefinitions.ORGANIZATION_PERSONALITY}
                element={
                  <SimpleHeaderLayout>
                    <PersonalityDetails />
                  </SimpleHeaderLayout>
                }
              />
              <Route
                path={RouteDefinitions.ORGANIZATION_PARTICIPANT}
                element={
                  <SimpleHeaderLayout>
                    <PersonalityDetails />
                  </SimpleHeaderLayout>
                }
              />
              <Route
                path={RouteDefinitions.ORGANIZATION_GRASP}
                element={
                  <SimpleHeaderLayout>
                    <GraspDetails />
                  </SimpleHeaderLayout>
                }
              />
              <Route
                path={RouteDefinitions.MEMBER_REPORTS}
                element={
                  <SimpleHeaderLayout
                    allowToDarkMode={true}
                    embeddedContainerClassNames="m-0"
                    embeddedLayoutClassNames="p-md-0 pb-md-4"
                  >
                    <DashboardProxy />
                  </SimpleHeaderLayout>
                }
              />
              <Route
                path={RouteDefinitions.ORGANIZATION_ENTERPRISE_CASES}
                element={
                  <FunctionalityRouteGuard
                    code={FunctionalityCode.CASE_LIST_VIEW}
                  >
                    <SimpleHeaderLayout allowToDarkMode={true}>
                      <DashboardProxy />
                    </SimpleHeaderLayout>
                  </FunctionalityRouteGuard>
                }
              />
              <Route
                path={RouteDefinitions.ORGANIZATIONS_OVERVIEW}
                element={
                  <SimpleHeaderLayout allowToDarkMode={true}>
                    <DashboardProxy />
                  </SimpleHeaderLayout>
                }
              />
              <Route
                path={RouteDefinitions.TEMP_ORGANIZATIONS_OVERVIEW}
                element={
                  <SimpleHeaderLayout allowToDarkMode={true}>
                    <DashboardProxy />
                  </SimpleHeaderLayout>
                }
              />
              <Route
                path={RouteDefinitions.ORGANIZATION_CASES}
                element={
                  <FunctionalityRouteGuard
                    code={FunctionalityCode.CASE_LIST_VIEW}
                  >
                    <SimpleHeaderLayout allowToDarkMode={true}>
                      <CasesListView />
                    </SimpleHeaderLayout>
                  </FunctionalityRouteGuard>
                }
              />
              <Route
                path={RouteDefinitions.ORGANIZATION_AWARE_CASES}
                element={
                  <FunctionalityRouteGuard
                    code={FunctionalityCode.CASE_LIST_VIEW}
                  >
                    <SimpleHeaderLayout allowToDarkMode={true}>
                      <AwareCasesListView />
                    </SimpleHeaderLayout>
                  </FunctionalityRouteGuard>
                }
              />
              <Route
                path={RouteDefinitions.ORGANIZATION_CASES_LIST}
                element={
                  <FunctionalityRouteGuard
                    code={FunctionalityCode.CASE_LIST_VIEW}
                  >
                    <SimpleHeaderLayout allowToDarkMode={true}>
                      <DashboardProxy />
                    </SimpleHeaderLayout>
                  </FunctionalityRouteGuard>
                }
              />
              <Route
                path={RouteDefinitions.ORGANIZATION_DASHBOARDS}
                element={
                  <SimpleHeaderLayout allowToDarkMode={true}>
                    <DashboardsList />
                  </SimpleHeaderLayout>
                }
              />
              <Route
                path={RouteDefinitions.ORGANIZATION_DASHBOARD}
                element={
                  <SimpleHeaderLayout
                    allowToDarkMode={true}
                    embeddedContainerClassNames="m-0"
                    embeddedLayoutClassNames="p-md-0 pb-md-4"
                  >
                    <DashboardProxy />
                  </SimpleHeaderLayout>
                }
              />
              <Route
                path={RouteDefinitions.CASE_GROUP_DETAILS}
                element={
                  <SimpleHeaderLayout>
                    <CaseGroupDetails />
                  </SimpleHeaderLayout>
                }
              />
              <Route
                path={RouteDefinitions.ORGANIZATION_CASE}
                element={
                  <SimpleHeaderLayout allowToDarkMode={true}>
                    <CaseDetailsPage />
                  </SimpleHeaderLayout>
                }
              />
              <Route
                path={RouteDefinitions.ORGANIZATION_CASE_LOGS}
                element={
                  <SimpleHeaderLayout>
                    <EventLogsTab />
                  </SimpleHeaderLayout>
                }
              />
              <Route
                path={RouteDefinitions.ORGANIZATION_CASE_CONVERSATIONS}
                element={
                  <SimpleHeaderLayout>
                    <ConversationsTab />
                  </SimpleHeaderLayout>
                }
              />
              <Route
                path={RouteDefinitions.ORGANIZATION_CASE_SCHEDULE}
                element={
                  <SimpleHeaderLayout>
                    <ScheduleTab />
                  </SimpleHeaderLayout>
                }
              />
              <Route
                path={RouteDefinitions.PARTICIPANT_CHECK}
                element={
                  <SimpleHeaderLayout flexInDiv={true}>
                    <StatusCheck />
                  </SimpleHeaderLayout>
                }
              />

              <Route
                path={RouteDefinitions.ORGANIZATION_REPORTS}
                element={
                  <SimpleHeaderLayout>
                    <Reports />
                  </SimpleHeaderLayout>
                }
              />
            </Route>
            <Route
              element={
                <RoleRouteGuard
                  role={UserRoles.GRASP_MANAGER}
                  withBackwardRedirection
                />
              }
            >
              <Route
                path={RouteDefinitions.TIMELINE_DETAILS_ASSESSMENT_PROXY}
                element={
                  <SimpleHeaderLayout>
                    <TimelineDetailsAssessmentProxy />
                  </SimpleHeaderLayout>
                }
              />
              <Route
                path={RouteDefinitions.TIMELINE_DETAILS_ANNOTATION_PROXY}
                element={
                  <SimpleHeaderLayout>
                    <TimelineDetailsAnnotationProxy />
                  </SimpleHeaderLayout>
                }
              />
            </Route>
          </Route>
          <Route
            path="/"
            element={<Navigate to={RouteDefinitions.DASHBOARD} />}
          />
        </Routes>
      </div>
    </CustomBrowserRouter>
  );
};

export default App;
