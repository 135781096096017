import { format } from 'date-fns';
import { IOrganizationObservationMeaning } from '../../../interfaces/observationMeanings';
import { TemplateItemType } from '../../../interfaces/change';
import { ObservationSymptom } from '../../../interfaces/timeline';
import { ConversationQuestion } from '../../../interfaces/conversations';

export const genericNumberFormatter = (value: any) => {
  const numberValue = parseFloat(value);
  if (!isNaN(numberValue)) return numberValue.toFixed(0).toString();
  return value?.toString() ?? '';
};

export const genericDateFormatter = (value: any) => {
  if (!value) return '';

  const valueDate = new Date(value);

  if (isNaN(valueDate.getTime())) return '';
  return format(valueDate, 'dd.MM');
};

export const genericResourceFormatter = (
  value: any,
  getQuestionTitle: (question: ConversationQuestion) => string,
  getMeaningName: (meaning: IOrganizationObservationMeaning) => string,
  getExerciseTimeName: () => string,
  getNotSpecifiedName: () => string,
  questions: ConversationQuestion[],
  meanings: IOrganizationObservationMeaning[]
) => {
  if (!value) return '';

  const existingQuestion = questions.find((p) => p.resource_id === value);
  if (existingQuestion) return getQuestionTitle(existingQuestion);

  const existingMeaning = meanings.find((p) => p.id === value);
  if (existingMeaning) return getMeaningName(existingMeaning);

  if (value === TemplateItemType.ExerciseTime) return getExerciseTimeName();
  if (value === ObservationSymptom.NotSpecified) return getNotSpecifiedName();

  return value;
};
