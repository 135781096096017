import { cloneDeep } from 'lodash';
import useRequiredParams from '../../../../../../../hooks/useRequiredParams';
import { ManagedTreatmentPhase } from '../../../types';
import { useEmptyTreatmentPhase } from './useEmptyTreatmentPhase';

export const useAddTreatmentPhase = (
  managedPhases: ManagedTreatmentPhase[],
  setManagedPhases: (phases: ManagedTreatmentPhase[]) => void,
  setSelectedTab: (tab: string) => void
) => {
  const { organizationId, treatmentPlanId } = useRequiredParams<{
    organizationId: string;
    treatmentPlanId: string;
  }>(['organizationId', 'treatmentPlanId']);

  const getEmptyPhase = useEmptyTreatmentPhase();

  return () => {
    let managedPhasesCopy = cloneDeep(managedPhases);
    managedPhasesCopy = managedPhasesCopy.filter((p) => !p.isAdding);

    let emptyPhase = getEmptyPhase(
      organizationId,
      treatmentPlanId,
      managedPhasesCopy.length
    );

    setManagedPhases([
      ...managedPhases,
      {
        isAdding: true,
        phase: emptyPhase,
      },
    ]);

    setSelectedTab(emptyPhase.id);
  };
};
