import request from 'api/utils/request';
import { IResponse } from 'interfaces/response';
import {
  ICaseSymptom,
  ICreateCaseSymptoms,
  IOrganizationSymptom,
  ISymptom,
} from '../interfaces/symptoms';

export const getSymptoms = () => {
  return request<undefined, IResponse<ISymptom[]>>({
    url: `/symptoms`,
    method: 'GET',
  });
};

export const getOrganizationSymptoms = (organizationId: string) => {
  return request<undefined, IResponse<IOrganizationSymptom[]>>({
    url: `/organizations/${organizationId}/symptoms`,
    method: 'GET',
  });
};

export const addSymptomToOrganization = (
  organizationId: string,
  symptomId: string
) => {
  return request<
    {
      symptom_id: string;
    },
    IResponse<IOrganizationSymptom>
  >({
    url: `/organizations/${organizationId}/symptoms`,
    method: 'POST',
    data: {
      symptom_id: symptomId,
    },
  });
};

export const deleteOrganizationSymptoms = (
  organizationId: string,
  organizationSymptomId: string
) => {
  return request<undefined, void>({
    url: `/organizations/${organizationId}/symptoms/${organizationSymptomId}`,
    method: 'DELETE',
  });
};

export const getCaseSymptoms = (organizationId: string, caseId: string) => {
  return request<undefined, IResponse<ICaseSymptom[]>>({
    url: `/organizations/${organizationId}/cases/${caseId}/symptoms`,
    method: 'GET',
  });
};

export const addCaseSymptoms = (
  organizationId: string,
  caseId: string,
  data: ICreateCaseSymptoms
) => {
  return request<ICreateCaseSymptoms, ICaseSymptom>({
    url: `/organizations/${organizationId}/cases/${caseId}/symptoms`,
    method: 'POST',
    data,
  });
};

export const activateCaseSymptom = (organizationId, caseId, caseSymptomId) => {
  return request<undefined, null>({
    url: `/organizations/${organizationId}/cases/${caseId}/symptoms/${caseSymptomId}/activate`,
    method: 'PATCH',
  });
};

export const deactivateCaseSymptom = (
  organizationId,
  caseId,
  caseSymptomId
) => {
  return request<undefined, null>({
    url: `/organizations/${organizationId}/cases/${caseId}/symptoms/${caseSymptomId}/deactivate`,
    method: 'PATCH',
  });
};
