import React from 'react';

const RADIAN = Math.PI / 180;

const AllSqueezesChartLabel = (props: any) => {
  const { cx, cy, midAngle, innerRadius, outerRadius, payload } = props;

  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      style={{ fontWeight: 'semibold', fontSize: 'md' }}
      x={x}
      y={y}
      fill="white"
      textAnchor="middle"
      dominantBaseline="middle"
    >
      {payload.value ? payload.value : ''}
    </text>
  );
};

export default AllSqueezesChartLabel;
