import React from 'react';
import SVG from 'react-inlinesvg';

import { useIntl } from 'react-intl';
import { getSmileIcons } from '../helpers/utils/icons';

type RangeQuestionIconFeedbackHorizontalListProps = {
  reversed?: boolean;
};

const RangeQuestionIconFeedbackHorizontalList = ({
  reversed = false,
}: RangeQuestionIconFeedbackHorizontalListProps) => {
  const intl = useIntl();

  const iconsList = getSmileIcons(reversed);

  return (
    <div className="RangeQuestionIconFeedbackHorizontalList">
      {iconsList.map((icon) => (
        <SVG
          src={icon}
          title={intl.formatMessage({
            id: 'CaseCard.AssessmentForms.iconFeedback',
          })}
        />
      ))}
    </div>
  );
};

export default RangeQuestionIconFeedbackHorizontalList;
