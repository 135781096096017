import { Typography } from '@mui/material';
import { TabTitleContent } from '../../hooks/useTabTitle';

export namespace BreadcrumbTabLayoutHelpers {
  export const GetBreadcrumbs = (tabTitle: TabTitleContent) => {
    let breadcrumbs: JSX.Element[] = [];

    if (tabTitle.header)
      breadcrumbs.push(<Typography key="3">{tabTitle.header}</Typography>);

    if (tabTitle.subHeader)
      breadcrumbs.push(<Typography key="3">{tabTitle.subHeader}</Typography>);

    return breadcrumbs;
  };
}
