import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { deleteFormAssignment } from 'api/graspManagement';
import { IMutationProps } from '../../../../interfaces/ui';

type useOrganizationContextDeleteFormAssignmentParams = {
  caseId: string;
};

const useOrganizationContextDeleteFormAssignment = (
  params: useOrganizationContextDeleteFormAssignmentParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification<string, void>({
    asyncApiFunction: async (assignmentId) => {
      await deleteFormAssignment(params.caseId, assignmentId);
    },
    onSuccess: () => {
      displaySuccessNotification('CaseDetails.assignmentDeleteSuccess');

      if (options.successFb) options.successFb();
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'CaseDetails.assignmentDeleteError'
      );

      if (options.errorFb) options.errorFb();
    },
    resetOnResult: true,
  });
};

export default useOrganizationContextDeleteFormAssignment;
