import { useTypedContext } from 'hooks/useTypedContext';
import { useMemo } from 'react';
import { CaseDetailsContext } from '../../../../../../../types';

export const useCaseParticipant = () => {
  const caseDetails = useTypedContext(CaseDetailsContext);

  const personality = useMemo(() => {
    return caseDetails.participants[0].personality;
  }, [caseDetails.participants]);

  const name = useMemo(() => {
    return `${personality?.first_name ?? ''} ${personality?.last_name ?? ''}`;
  }, [personality?.first_name, personality?.last_name]);

  return {
    personality,
    name,
  };
};
