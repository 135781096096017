import { cloneDeep } from 'lodash';
import { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { UpsertTreatmentPhaseProps } from '../../../../../../../../../../../api/mutations/conversations/useModifyTreatmentPhase';
import {
  IConversationScheduleTemplateItem,
  ISummarizedTreatmentPhase,
  TreatmentPhaseContentItemType,
} from '../../../../../../../../../../../interfaces/conversations';
import { ConversationScheduleType } from '../../../../../../../../../../../interfaces/conversationSchedules';
import { IValidationSchemaBasedFormHookValues } from '../../../../../../../../../../../interfaces/forms';
import { CaseNotificationScheduleType } from '../../../../../../../../../../../interfaces/notificationSchedules';
import { useTreatmentPhaseCode } from '../../../../../hooks/useTreatmentPhaseCode';
import { useHandleUpsert } from '../../../hooks/useHandleUpsert';
import {
  getEmptyInitialValues,
  getFormValuesBasedOnItem,
  validatePartOfADayHours,
} from '../helpers';
import { FormValues, ScheduleTemplate } from '../types';

export const useForm = (
  phase: ISummarizedTreatmentPhase,
  contentIndex: number | undefined,
  toggle: () => void
): IValidationSchemaBasedFormHookValues<FormValues> => {
  const handleSave = useHandleUpsert(toggle);
  const getPhaseCode = useTreatmentPhaseCode();

  const initialValues = useMemo(() => {
    if (!contentIndex) return getEmptyInitialValues();

    const contentItem = phase.content[contentIndex];
    if (contentItem.type !== TreatmentPhaseContentItemType.NotificationSchedule)
      throw Error(
        'Cannot modify non-notification schedule inside notifications section'
      );

    return getFormValuesBasedOnItem(contentItem);
  }, [contentIndex, phase.content]);

  const handleSubmit = useCallback(
    async (values: typeof initialValues) => {
      const result = validatePartOfADayHours(values);

      if (!result) return;
      let contentCopy = cloneDeep(phase.content);

      if (contentIndex !== undefined) {
        contentCopy[contentIndex] = {
          type: TreatmentPhaseContentItemType.NotificationSchedule,
          notification_type: values.notification_type,
          schedule_templates: values.schedule_templates.map((template) => ({
            specific_day_of_phase: template.specific_day_of_phase
              ? template.specific_day_of_phase - 1
              : null,
            start_day_offset: template.start_day_offset
              ? template.start_day_offset - 1
              : null,
            end_day_offset: template.end_day_offset
              ? template.end_day_offset - 1
              : null,
            recurring_interval: template.recurring_interval ?? null,
            schedule_type: template.schedule_type,
            parts_of_a_day: template.parts_of_a_day,
          })),
        };
      } else {
        contentCopy.push({
          type: TreatmentPhaseContentItemType.NotificationSchedule,
          notification_type: values.notification_type,
          schedule_templates: values.schedule_templates.map((template) => ({
            specific_day_of_phase: template.specific_day_of_phase
              ? template.specific_day_of_phase - 1
              : null,
            start_day_offset: template.start_day_offset
              ? template.start_day_offset - 1
              : null,
            end_day_offset: template.end_day_offset
              ? template.end_day_offset - 1
              : null,
            recurring_interval: template.recurring_interval ?? null,
            schedule_type: template.schedule_type,
            parts_of_a_day: template.parts_of_a_day,
          })),
        });
      }

      let upsert: UpsertTreatmentPhaseProps = {
        treatmentPhaseId: phase.id,
        body: {
          next_phase_condition: phase.next_phase_condition,
          display_code: phase.display_code,
          sequence: phase.sequence,
          empty_conversation_template_id: phase.empty_conversation_template_id,
          is_default: phase.is_default,
          days_duration: phase.days_duration,
          organization_id: phase.organization_id,
          content: contentCopy,
          code: getPhaseCode(phase.id),
        },
      };

      await handleSave(upsert);
    },
    [
      contentIndex,
      getPhaseCode,
      handleSave,
      phase.content,
      phase.days_duration,
      phase.display_code,
      phase.empty_conversation_template_id,
      phase.id,
      phase.is_default,
      phase.next_phase_condition,
      phase.organization_id,
      phase.sequence,
    ]
  );

  const validationSchema = Yup.object<FormValues>().shape({
    type: Yup.mixed<TreatmentPhaseContentItemType.NotificationSchedule>()
      .oneOf([TreatmentPhaseContentItemType.NotificationSchedule])
      .required(),
    notification_type: Yup.mixed<CaseNotificationScheduleType>()
      .oneOf(Object.values(CaseNotificationScheduleType))
      .required(),
    schedule_templates: Yup.array<ScheduleTemplate>()
      .of(
        Yup.object<ScheduleTemplate>().shape({
          schedule_type: Yup.mixed<
            | ConversationScheduleType.SingleInstance
            | ConversationScheduleType.Recurring
          >()
            .oneOf([
              ConversationScheduleType.SingleInstance,
              ConversationScheduleType.Recurring,
            ])
            .required(),
          parts_of_a_day: Yup.array<IConversationScheduleTemplateItem>()
            .when('schedule_type', {
              is: ConversationScheduleType.Recurring,
              then: (schema) =>
                schema.min(1).of(
                  Yup.object<IConversationScheduleTemplateItem>().shape({
                    notification_hour: Yup.string().required(),
                    available_from_hour: Yup.string().required(),
                    available_to_hour: Yup.string().required(),
                  })
                ),
              otherwise: (schema) => schema.optional(),
            })
            .required(),
          start_day_offset: Yup.number().when('schedule_type', {
            is: ConversationScheduleType.Recurring,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.optional(),
          }),
          end_day_offset: Yup.number().when('schedule_type', {
            is: ConversationScheduleType.Recurring,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.optional(),
          }),
          recurring_interval: Yup.number().when('schedule_type', {
            is: ConversationScheduleType.Recurring,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.optional(),
          }),
          specific_day_of_phase: Yup.number().when('schedule_type', {
            is: ConversationScheduleType.SingleInstance,
            then: (schema) => schema.required(),
            otherwise: (schema) => schema.optional(),
          }),
        })
      )
      .required(),
  });

  return {
    initialValues,
    handleSubmit,
    validationSchema,
  };
};
