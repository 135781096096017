import { getFeedbackMessages } from 'api/change';
import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { IQueryOptions } from 'interfaces/ui';

function useGetFeedbackMessages(options?: IQueryOptions) {
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getFeedbackMessages();

      return data.data;
    },
    queryKey: [QueryKeys.getFeedbackMessages],
  });
}

export default useGetFeedbackMessages;
