import {
  OrganizationQueryParams,
  UserQueryParams,
} from '../../../interfaces/queryParams';
import { IPatchObject } from '../../../interfaces/request';
import { IMutationProps } from '../../../interfaces/ui';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { patchOrganizationUser } from '../../organization';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

type PatchOrganizationUserQueryParams = OrganizationQueryParams &
  UserQueryParams;

const usePatchOrganizationUser = (
  params: PatchOrganizationUserQueryParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification<IPatchObject[], void>({
    asyncApiFunction: async (patchData) => {
      await patchOrganizationUser(
        params.organizationId,
        params.userId,
        patchData
      );
    },
    resetOnResult: true,
    onError: () => {
      displayErrorNotification(
        'Api.Mutations.Organizations.PatchOrganizationUser.failure'
      );
    },
    onSuccess: () => {
      if (options.successFb) options.successFb();
      displaySuccessNotification(
        'Api.Mutations.Organizations.PatchOrganizationUser.success'
      );
    },
  });
};

export default usePatchOrganizationUser;
