import useOrganizationContextUpdateNonAssignedAssessmentForm from 'api/mutations/assessmentForms/forms/useOrganizationContextUpdateNonAssignedAssessmentForm';
import useUserContextUpdateNonAssignedAssessmentForm from 'api/mutations/assessmentForms/forms/useUserContextUpdateNonAssignedAssessmentForm';
import { createFormObjectToSent } from 'helpers/utils/assessmentForms';
import useMobile from 'hooks/useBreakpoint';
import useUserPathBasedBrowsingContext from 'hooks/useUserPathBasedBrowsingContext';
import {
  AssessmentQuestion,
  IAssessmentTemplate,
} from 'interfaces/assessmentForms';
import { AssessmentFormValues } from 'interfaces/ui';
import { useState } from 'react';
import QuestionModalForm from '../QuestionModal/QuestionModalForm';

type AssessmentFormFillProps = {
  closeModal: () => void;
  templates: IAssessmentTemplate[];
  selectedTemplate: string;
  userId: string;
  timelineId: string;
  dateObject: Date;
  time: string;
  refetchDayByDayData?: () => void;
};

const AssessmentFormFill = ({
  closeModal,
  templates,
  selectedTemplate,
  userId,
  timelineId,
  dateObject,
  time,
  refetchDayByDayData,
}: AssessmentFormFillProps) => {
  const { caseId, organizationId, isOrganizationContext } =
    useUserPathBasedBrowsingContext();

  const [isSubmitted, setSubmitted] = useState(false);

  const isMobile = useMobile();

  const selectedAssessment = templates.find(
    (el) => el.code === selectedTemplate
  );

  const onSuccess = () => {
    refetchDayByDayData && refetchDayByDayData();
    setSubmitted(true);
  };

  const organizationContextUpdateNonAssignedFormMutation =
    useOrganizationContextUpdateNonAssignedAssessmentForm(
      {
        organizationId: organizationId ?? '',
        caseId: caseId ?? '',
        timelineId: timelineId,
      },
      {
        successFb: onSuccess,
      }
    );

  const userContextUpdateNonAssignedFormMutation =
    useUserContextUpdateNonAssignedAssessmentForm(
      {
        timelineId: timelineId,
      },
      {
        successFb: onSuccess,
      }
    );

  const updateNonAssignedFormMutation = isOrganizationContext
    ? organizationContextUpdateNonAssignedFormMutation
    : userContextUpdateNonAssignedFormMutation;

  const onSubmit = (values: AssessmentFormValues) => {
    if (dateObject && userId && selectedAssessment) {
      const questions = selectedAssessment.questions;
      const assessmentBody = createFormObjectToSent(
        values,
        questions,
        selectedAssessment
      ) as AssessmentQuestion[];

      updateNonAssignedFormMutation.mutate({
        assessmentBody,
        code: selectedTemplate,
        date: dateObject,
        userId,
        title: selectedAssessment.title,
        hour: time,
      });
    }
  };

  if (selectedAssessment)
    return (
      <QuestionModalForm
        canEdit
        isFormSubmitted={isSubmitted}
        closeModal={closeModal}
        isLoading={updateNonAssignedFormMutation.isLoading}
        isMobile={isMobile}
        onSubmit={onSubmit}
        selectedForm={selectedAssessment}
      />
    );
  else return null;
};

export default AssessmentFormFill;
