import { changeSelectedDate } from 'modules/TimelineDetails/actions';
import { useDispatch } from 'react-redux';
import { Rectangle } from 'recharts';

const TrendsChartCursor = (props) => {
  const dispatch = useDispatch();
  const { top, height, points, payload, width } = props;

  if (points && points.length > 0 && payload && payload.length > 0) {
    const selectedDate = payload[0].payload.name;

    const onClick = () => dispatch(changeSelectedDate(new Date(selectedDate)));

    return (
      <g onClick={onClick} style={{ cursor: 'pointer' }}>
        <Rectangle
          fill="rgba(0, 0, 0, 0.1)"
          stroke="rgba(0, 0, 0, 0.1)"
          x={points[0].x}
          y={top}
          width={2}
          height={height}
        />
        <Rectangle
          fill="transparent"
          stroke="transparent"
          x={0}
          y={0}
          width={width + 50}
          height={height}
        />
      </g>
    );
  }

  return null;
};

export default TrendsChartCursor;
