import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import LockIcon from '@mui/icons-material/Lock';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import PersonIcon from '@mui/icons-material/Person';
import BadgeIcon from '@mui/icons-material/Badge';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { Formik } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useTypedContext } from '../../../../../../../../../../hooks/useTypedContext';
import FormContainer from '../../components/FormContainer';
import { FormsNavigationContext } from '../types';
import { useForm } from './hooks/useForm';
import { useHandleEmailChange } from './hooks/useHandleEmailChange';

const SignUpForm = () => {
  const formHandler = useForm();
  const navigation = useTypedContext(FormsNavigationContext);
  const handleEmailChange = useHandleEmailChange();

  return (
    <Formik
      initialValues={formHandler.initialValues}
      validationSchema={formHandler.validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={formHandler.handleSubmit}
    >
      {(form) => (
        <FormContainer
          fields={
            <>
              <TextField
                variant="standard"
                fullWidth
                id="firstName"
                name="firstName"
                label={<FormattedMessage id="General.FirstName" />}
                InputProps={{
                  startAdornment: (
                    <InputAdornment color="primary" position="start">
                      <PersonIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
                value={form.values.firstName}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                error={form.touched.firstName && Boolean(form.errors.firstName)}
                helperText={form.touched.firstName && form.errors.firstName}
              />
              <TextField
                variant="standard"
                fullWidth
                id="lastName"
                name="lastName"
                label={<FormattedMessage id="General.LastName" />}
                InputProps={{
                  startAdornment: (
                    <InputAdornment color="primary" position="start">
                      <PersonOutlineOutlinedIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
                value={form.values.lastName}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                error={form.touched.lastName && Boolean(form.errors.lastName)}
                helperText={form.touched.lastName && form.errors.lastName}
              />
              <TextField
                variant="standard"
                fullWidth
                id="email"
                name="email"
                label={<FormattedMessage id="General.Email" />}
                type="email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment color="primary" position="start">
                      <EmailOutlinedIcon color="primary" />
                    </InputAdornment>
                  ),
                  autoComplete: 'new-password',
                }}
                value={form.values.email}
                onChange={handleEmailChange(form)}
                onBlur={form.handleBlur}
                error={form.touched.email && Boolean(form.errors.email)}
                helperText={form.touched.email && form.errors.email}
              />
              <TextField
                variant="standard"
                fullWidth
                id="userName"
                name="userName"
                label={<FormattedMessage id="General.UserName" />}
                InputProps={{
                  startAdornment: (
                    <InputAdornment color="primary" position="start">
                      <BadgeIcon color="primary" />
                    </InputAdornment>
                  ),
                }}
                value={form.values.userName}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                error={form.touched.userName && Boolean(form.errors.userName)}
                helperText={form.touched.userName && form.errors.userName}
              />
              <TextField
                variant="standard"
                fullWidth
                id="password"
                name="password"
                type="password"
                label={<FormattedMessage id="General.Password" />}
                InputProps={{
                  startAdornment: (
                    <InputAdornment color="primary" position="start">
                      <LockIcon color="primary" />
                    </InputAdornment>
                  ),
                  autoComplete: 'new-password',
                }}
                value={form.values.password}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                error={form.touched.password && Boolean(form.errors.password)}
                helperText={form.touched.password && form.errors.password}
              />
              <TextField
                variant="standard"
                fullWidth
                id="confirmPassword"
                name="confirmPassword"
                type="password"
                label={<FormattedMessage id="General.ConfirmPassword" />}
                InputProps={{
                  startAdornment: (
                    <InputAdornment color="primary" position="start">
                      <LockOutlinedIcon color="primary" />
                    </InputAdornment>
                  ),
                  autoComplete: 'new-password',
                }}
                value={form.values.confirmPassword}
                onChange={form.handleChange}
                onBlur={form.handleBlur}
                error={
                  form.touched.confirmPassword &&
                  Boolean(form.errors.confirmPassword)
                }
                helperText={
                  form.touched.confirmPassword && form.errors.confirmPassword
                }
              />
            </>
          }
          buttons={
            <>
              <Button variant="outlined" onClick={navigation.prev}>
                <FormattedMessage id="General.GoBack" />
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={form.dirty && !form.isValid}
                onClick={form.submitForm}
              >
                <FormattedMessage id="General.Next" />
              </Button>
            </>
          }
        />
      )}
    </Formik>
  );
};

export default SignUpForm;
