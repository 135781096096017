import classNames from 'classnames';
import { FieldMetaProps, FieldProps } from 'formik';
import React from 'react';
import { Input, InputGroup, InputGroupText, Label, Spinner } from 'reactstrap';
import { InputType } from 'reactstrap/types/lib/Input';

interface InputTypeProps {
  type: InputType;
}

export interface FieldInputProps extends Partial<FieldProps> {
  id?: string;
  isLoading?: boolean;
  disabled?: boolean;
  addon?: JSX.Element;
  addonOnTop?: boolean;
  label?: string;
  rightHandedAddon?: JSX.Element;
}

const FieldInput = ({
  id,
  field,
  form,
  disabled,
  isLoading,
  addon,
  rightHandedAddon,
  addonOnTop,
  label,
  ...props
}: FieldInputProps) => {
  const typedProps = props as {
    meta: FieldMetaProps<any> | undefined;
  } & InputTypeProps;

  const fieldMeta: FieldMetaProps<any> | undefined = field?.name
    ? form?.getFieldMeta(field.name)
    : undefined;

  const standardInput = (
    <Input
      id={id ?? field?.name ?? ''}
      className="mt-0"
      disabled={disabled}
      {...field}
      value={field?.value === null ? '' : field?.value ?? undefined}
      {...props}
    />
  );

  const invalidInput = (
    <Input
      id={field?.name ?? ''}
      className="mt-0"
      disabled={disabled}
      invalid
      {...field}
      value={field?.value === null ? '' : field?.value ?? undefined}
      {...props}
    />
  );

  const input = fieldMeta?.error ? invalidInput : standardInput;

  if (isLoading)
    return withLabel(
      <InputGroup>
        {input}
        <InputGroupText>
          <Spinner color="dark" size="sm" />
        </InputGroupText>
      </InputGroup>,
      typedProps?.type ?? 'text',
      field?.name ?? '',
      label
    );

  if (addon) {
    return withLabel(
      <InputGroup
        className={classNames({
          inputGroup: true,
          'inputGroup--disabled': disabled,
        })}
      >
        <InputGroupText
          className={classNames('transparent-group-text', {
            'd-flex align-items-start': addonOnTop,
          })}
        >
          {addon}
        </InputGroupText>
        {input}
        {rightHandedAddon && (
          <InputGroupText className="transparent-group-text">
            {rightHandedAddon}
          </InputGroupText>
        )}
      </InputGroup>,
      typedProps?.type ?? 'text',
      field?.name ?? '',
      label
    );
  }

  return withLabel(input, typedProps?.type ?? 'text', field?.name ?? '', label);
};

const withLabel = (
  element: JSX.Element,
  type: InputType,
  id?: string,
  label?: string
) => {
  if (!label || !id) return element;

  if (type === 'radio' || type === 'checkbox') {
    return (
      <div className="d-flex align-items-center gap-2">
        {element}
        <Label check={true} htmlFor={id}>
          {label}
        </Label>
      </div>
    );
  }

  return (
    <div>
      <Label value={label} htmlFor={id}>
        {label}
      </Label>
      {element}
    </div>
  );
};

export default FieldInput;
