import { IOrganizationObservationMeaning } from '../../../interfaces/observationMeanings';
import { TrendsChartConfig } from '../types';
import { useMemo } from 'react';
import { useQuestionTitle } from 'hooks/data/useQuestionTitle';
import { useMeaningName } from 'hooks/data/useMeaningName';
import { ConversationQuestion } from '../../../interfaces/conversations';

export const useTrendsChartConfig = (
  questions: ConversationQuestion[],
  meanings: IOrganizationObservationMeaning[]
): TrendsChartConfig => {
  const getQuestionTitle = useQuestionTitle();
  const getMeaningName = useMeaningName();

  return useMemo(
    () => ({
      questions: questions.map((question) => ({
        id: question.resource_id,
        color: question.content.color,
        title: getQuestionTitle(question),
      })),
      observations: meanings.map((meaning) => ({
        id: meaning.id,
        color: meaning.color,
        title: getMeaningName(meaning),
      })),
      actions: [],
      header: {},
      legend: {},
      switch: {},
    }),
    [getMeaningName, getQuestionTitle, meanings, questions]
  );
};
