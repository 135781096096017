import { useRemoveAccessProfileRoles } from '../../../../../../../../../../../../../api/mutations/accessProfiles/useRemoveAccessProfileRoles';
import { useOrganizationPathParams } from '../../../../../../../../../../../../../hooks/paths/useOrganizationPathParams';
import useOrganizationUserAccessProfiles from '../../../../../../../../../../../../../api/queries/accessProfiles/useOrganizationUserAccessProfiles';

export const useHandleRemove = () => {
  const { organizationId } = useOrganizationPathParams();

  const accessProfiles = useOrganizationUserAccessProfiles({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const { mutateAsync: removeAsync } = useRemoveAccessProfileRoles(
    { organizationId },
    { successFb: accessProfiles.refetch }
  );

  return removeAsync;
};
