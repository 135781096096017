import { useTheme } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslateLegend } from '../../../../../hooks/useTranslateLegend';

export const useLegendText = () => {
  const theme = useTheme();
  const { getLegendTranslation } = useTranslateLegend();

  return useCallback(
    (value: string) => {
      return (
        <span
          style={{ color: theme.palette.text.primary }}
          className="AwareCasesLineChart__legend-item"
        >
          {getLegendTranslation(value)}
        </span>
      );
    },
    [getLegendTranslation, theme.palette.text.primary]
  );
};
