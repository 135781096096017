import { resetPassword } from 'api/membership';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

function useResetPassword() {
  return useMutationWithResultNotification({
    asyncApiFunction: async (email: string) => {
      await resetPassword({ user_email: email });
    },
    resetOnResult: true,
    onSuccess: () => {
      displaySuccessNotification('ResetPassword.notifications.success', {
        autoClose: false,
      });
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'ResetPassword.notifications.errors'
      );
    },
  });
}

export default useResetPassword;
