import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import HourglassBottomOutlinedIcon from '@mui/icons-material/HourglassBottomOutlined';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import IconButton from '../../../../../../../../../../../components/IconButton';
import CellEditableInput from '../../../../../../CellEditableInput';
import { useEditingRow } from './useEditingRow';

export const useColumns = () => {
  const intl = useIntl();

  const { handleSave, isSaving, onEdit, handleEdit, isCellInEditMode } =
    useEditingRow();

  return useMemo(
    (): GridColDef[] => [
      {
        field: 'type',
        headerName: intl.formatMessage({ id: 'General.Type' }),
        flex: 1,
      },
      {
        field: 'name',
        headerName: intl.formatMessage({ id: 'Organizations.DeviceName' }),
        flex: 1,
        renderCell: (params: GridRenderCellParams) => (
          <CellEditableInput
            onEdit={onEdit}
            value={params.row.name}
            editMode={isCellInEditMode(params.row.id)}
            name="name"
            type="text"
          />
        ),
      },
      {
        field: 'serial_number',
        headerName: intl.formatMessage({ id: 'Organizations.SerialNumber' }),
        flex: 1,
      },
      {
        field: 'apple_id',
        headerName: intl.formatMessage({ id: 'Organizations.AppleId' }),
        flex: 1,
      },
      {
        field: 'apple_password',
        headerName: intl.formatMessage({ id: 'Organizations.ApplePassword' }),
        flex: 1,
      },
      {
        field: 'screen_lock_code',
        headerName: intl.formatMessage({ id: 'Organizations.ScreenLockCode' }),
        flex: 1,
        renderCell: (params: GridRenderCellParams) => (
          <CellEditableInput
            onEdit={onEdit}
            value={params.row.screen_lock_code}
            editMode={isCellInEditMode(params.row.id)}
            name="screen_lock_code"
            type="text"
          />
        ),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        cellClassName: 'actions',
        renderCell: (params: GridRenderCellParams) => {
          const editMode = isCellInEditMode(params.id as string);

          if (editMode) {
            return (
              <IconButton
                color="primary"
                variant="contained"
                onClick={handleSave}
              >
                {isSaving ? (
                  <HourglassBottomOutlinedIcon />
                ) : (
                  <CheckOutlinedIcon />
                )}
              </IconButton>
            );
          }

          return (
            <IconButton
              color="primary"
              variant="outlined"
              onClick={handleEdit(params.row)}
            >
              <EditOutlinedIcon />
            </IconButton>
          );
        },
      },
    ],
    [handleEdit, handleSave, intl, isCellInEditMode, isSaving, onEdit]
  );
};
