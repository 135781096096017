import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TreeElementActionDefinition } from '../../types/Tree';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

type TreeActionProps = {
  action: TreeElementActionDefinition;
};

const TreeAction = ({ action }: TreeActionProps) => {
  const handleExecute = async (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    await action.onAsyncExecute?.();
    action.onExecute?.();
  };

  return (
    <div onClick={handleExecute}>
      {action.onAsyncLoading ? (
        <FontAwesomeIcon icon={faSpinner} spin />
      ) : (
        <FontAwesomeIcon icon={action.icon} />
      )}
    </div>
  );
};

export default TreeAction;
