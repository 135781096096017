import { useMemo } from 'react';
import { useQuestionTitle } from '../../../../../../../hooks/data/useQuestionTitle';
import { ConversationQuestion } from '../../../../../../../interfaces/conversations';

export const useFeedbackChartQuestionTitle = (
  id: string,
  questions: ConversationQuestion[]
) => {
  const getQuestionTitle = useQuestionTitle();

  return useMemo(() => {
    const question = questions.find((p) => p.resource_id === id);
    if (!question) return '';
    return getQuestionTitle(question);
  }, [getQuestionTitle, id, questions]);
};
