import { useCommonParams } from './useCommonParams';
import useOrganizationReportsList, {
  UseOrganizationReportsListParams,
} from '../../../api/queries/reports/useOrganizationReportsList';
import useOrganizationDetails from '../../../api/queries/organizations/useOrganizationDetails';

export const useCommonData = (
  requestParams: UseOrganizationReportsListParams
) => {
  const { organizationId } = useCommonParams();

  const reportsQuery = useOrganizationReportsList({
    params: {
      organizationId: organizationId,
      executionStatus: requestParams.executionStatus,
      reportType: requestParams.reportType,
      requestedAtFrom: requestParams.requestedAtFrom,
      requestedAtTo: requestParams.requestedAtTo,
    },
    options: { enabled: false, staleTime: Infinity },
  });

  const organizationQuery = useOrganizationDetails({
    params: { organizationId },
    options: {
      enabled: false,
      staleTime: Infinity,
    },
  });

  return {
    organization: organizationQuery,
    reports: reportsQuery,
  };
};
