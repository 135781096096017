import React from 'react';
import { useTranslations } from '../../../../../../../../../../../../../../hooks/useTranslations';
import { format } from 'date-fns';

const TimeQuestionItem = () => {
  const { wrapWithIntl, attachPrefix } = useTranslations(
    'ConversationsAndFeedbacks.ConversationTemplates.ConversationTemplatePhone.Conversation.ConversationItem.QuestionItem'
  );

  const currentHour = format(new Date(), 'HH:HH a');

  return (
    <div className="ConversationTemplateRangeQuestionItem">
      <div className="ConversationTemplateRangeQuestionItem__question d-flex flex-column">
        <span className="ConversationTemplatePlainTextItem__content__text">
          {wrapWithIntl(attachPrefix('timeQuestionItem'))}
        </span>
        <span className="ConversationTemplatePlainTextItem__content__hour">
          {currentHour}
        </span>
      </div>
    </div>
  );
};

export default TimeQuestionItem;
