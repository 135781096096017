import { TemplateItemType } from '../../../interfaces/change';
import { TrendsChartData } from '../types';
import {
  filterExerciseObservations,
  getExercisesPeriods,
} from '../../../helpers/exercises';
import { useMemo } from 'react';
import {
  completeChartData,
  groupObservationsByTimeStamp,
  normalizeTimestamps,
} from '../helpers/exerciseObservations';
import { UseQueryResult } from 'react-query';
import { IBaseExerciseSession } from '../../../interfaces/exerciseSessions';
import { IObservation } from '../../../interfaces/timeline';

export const useExerciseObservations = (
  enabled: boolean,
  allObservations: TrendsChartData<string | number>,
  exercises: UseQueryResult<IBaseExerciseSession[]>,
  observationInTimelines: UseQueryResult<IObservation[]>
): TrendsChartData<string | number> => {
  return useMemo(() => {
    if (!enabled)
      return { type: TemplateItemType.ExerciseObservations, data: [] };

    let exercisesPeriods = getExercisesPeriods(exercises.data ?? []);
    let filteredObservations = filterExerciseObservations(
      exercisesPeriods,
      observationInTimelines.data ?? []
    );

    filteredObservations = normalizeTimestamps(filteredObservations);

    const groupedObservations =
      groupObservationsByTimeStamp(filteredObservations);

    return {
      type: TemplateItemType.ExerciseObservations,
      data: completeChartData(groupedObservations, allObservations),
    };
  }, [allObservations, enabled, exercises.data, observationInTimelines.data]);
};
