import useCreateNotificationSchedule from '../../../../../../../../../../../../../../../api/mutations/notificationSchedules/useCreateNotificationSchedule';
import { CasesHelpers } from '../../../../../../../../../../../../../../../helpers/data/cases';
import { useOrganizationCasePathParams } from '../../../../../../../../../../../../../../../hooks/paths/useOrganizationCasePathParams';
import { useTypedContext } from '../../../../../../../../../../../../../../../hooks/useTypedContext';
import { CaseDetailsContext } from '../../../../../../../../../../../types';
import { useNotificationSchedulesQuery } from '../../../hooks/useNotificationSchedulesQuery';

export const useHandleCreate = (selectedDate: string, toggle: () => void) => {
  const caseDetails = useTypedContext(CaseDetailsContext);
  const { caseId } = useOrganizationCasePathParams();

  const userId = CasesHelpers.GetUserIdBasedOnCase(caseDetails);

  const notificationSchedules = useNotificationSchedulesQuery(selectedDate);

  const handleSuccess = async () => {
    await notificationSchedules.refetch();
    toggle();
  };

  const { mutateAsync: handleCreateAsync } = useCreateNotificationSchedule({
    params: { caseId, userId: userId ?? '' },
    options: { successFb: handleSuccess },
  });

  return handleCreateAsync;
};
