import { IPatchObject } from 'interfaces/request';

export function formatObjectToIPatch<T extends object>(object: T) {
  const patches: IPatchObject[] = [];

  const keys = Object.keys(object);

  for (const key of keys) {
    if (key !== 'id') {
      const value = object[key];

      patches.push({
        op: 'replace',
        path: `/${key}`,
        value: value,
      });
    }
  }

  return patches;
}
