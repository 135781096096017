import Cookies from 'js-cookie';
import { cloneDeep } from 'lodash';
import { BaseModalProps } from '../../interfaces/ui';

const EMPTY_MODAL_CONTEXT_VALUES: BaseModalProps = {
  isOpen: false,
  toggle: () => {},
};

export function checkBrowser() {
  if (typeof navigator !== 'undefined') {
    const embeddedMode = Cookies.get('embeddedMode') || '';
    const isEmbeddedMode = embeddedMode === 'true';

    return !isEmbeddedMode;
  } else return true;
}

export const getEmptyModalContextValues = () => {
  return cloneDeep(EMPTY_MODAL_CONTEXT_VALUES);
};
