import { FormikProps } from 'formik';
import HorizontalTabContainer from '../../../../../../../../components/Tabs/components/HorizontalTabContainer';
import { IHelpCategory } from '../../../../../../../../interfaces/help';
import { HelpCentreFormInitialValues } from '../../forms/HelpCentreForm/hooks/useForm';
import { useTabs } from './hooks/useTabs';

type HelpCentreTabsProps = {
  form: FormikProps<HelpCentreFormInitialValues>;
  categories: IHelpCategory[];
};

const HelpCentreTabs = ({ categories, form }: HelpCentreTabsProps) => {
  const { tabItems, onTabChange } = useTabs(categories, form);
  return <HorizontalTabContainer items={tabItems} onTabChange={onTabChange} />;
};

export default HelpCentreTabs;
