import { getTimelines } from 'api/graspManagement';
import QueryKeys from 'api/queries/queryKeys';
import { UserQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { useQuery } from 'react-query';

function useGetMasterTimeline({
  params,
  options,
}: IQueryRequest<UserQueryParams>) {
  const { userId } = params;

  return useQuery({
    ...options,
    queryKey: [QueryKeys.getMasterTimeline, userId],
    queryFn: async () => {
      const { data } = await getTimelines(userId);
      let timelines = data.data;

      return timelines.find((element) => element.is_master);
    },
  });
}

export default useGetMasterTimeline;
