import {
  assignParticipantToCase,
  createCase,
  postShareRequests,
} from 'api/cases';
import { CaseFormType, ICreateCaseBody, ShareMode } from 'interfaces/cases';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router';
import { addCaseSymptoms } from '../../api/symptoms';

export function useCreateCase(
  organizationId: string,
  existingPatientId: string | null,
  modalCloseFallback: () => void
) {
  const navigate = useNavigate();

  const onCreateCase = async (formData: CaseFormType) => {
    try {
      const {
        caseTitle,
        caseDescription,
        caseCategory,
        shareMode,
        caseSubCategory,
      } = formData;

      if (existingPatientId) {
        const caseBody: ICreateCaseBody = {
          title: caseTitle,
          description: caseDescription,
          category_id: caseCategory,
          sub_category_id: caseSubCategory,
          status: 0,
          type: 0,
          expected_feedback_frequency_in_hours: 0,
        };

        const {
          data: { data: caseData },
        } = await createCase(organizationId, caseBody);
        const automaticallyShare = shareMode === ShareMode.SHARE_DATA;

        const filteredMeaningDefinitions = formData.definitions.filter(
          (el) => (el.isDefault && el.isToggled) || !el.isDefault
        );

        if (filteredMeaningDefinitions.length > 0) {
          await addCaseSymptoms(organizationId, caseData.id, {
            definitions: filteredMeaningDefinitions,
          });
        }

        await assignParticipantToCase(
          organizationId,
          caseData.id,
          existingPatientId,
          {
            addMasterTimeline: automaticallyShare,
          }
        );

        const shouldSendRequest = shareMode === ShareMode.SEND_REQUEST;
        if (shouldSendRequest) {
          await postShareRequests(organizationId, caseData.id);
        }

        displaySuccessNotification('CaseCard.create.success');
        navigate(`/organizations/${organizationId}/cases/${caseData.id}`);
        modalCloseFallback();
      } else throw Error();
    } catch (error: any) {
      const errorArray = error?.data?.meta?.errors ?? [null];
      displayErrorArrayNotifications(errorArray, 'CaseCard.create.errors');
    }
  };

  const { mutate: createCaseHandler, isLoading: isCaseLoading } =
    useMutation(onCreateCase);

  return { createCaseHandler, isCaseLoading };
}
