import React from 'react';
import trendsChartPlaceholder from 'assets/images/timeline_details/trends_chart_placeholder.png';
import { FormattedMessage } from 'react-intl';

const NoDataTrendsChartPlaceholder = () => {
  return (
    <div className="TrendsChartPlaceholder">
      <div className="TrendsChartPlaceholder__image">
        <img src={trendsChartPlaceholder} alt="placeholder_image" />
      </div>
      <span className="TrendsChartPlaceholder__no-data">
        <FormattedMessage id="TimelineDetails.TrendsTimeline.TrendsChartPlaceholder.noData" />
      </span>
    </div>
  );
};

export default NoDataTrendsChartPlaceholder;
