import React, { PropsWithChildren } from 'react';
import useUrlSearch from '../../hooks/useUrlSearch';
import Placeholders from '../Placeholders';

type RequiredParamsRouteGuardProps = {
  params: string[];
};

const RequiredParamsRouteGuard = ({
  children,
  params,
}: PropsWithChildren<RequiredParamsRouteGuardProps>) => {
  const query = useUrlSearch();
  const available = params.every((p) => query.get(p) !== null);

  if (available) return <>{children}</>;
  return <Placeholders.InvalidUrlPath />;
};

export default RequiredParamsRouteGuard;
