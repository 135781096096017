import React from 'react';
import ThinHeaderItem from './components/ThinHeaderItem';
import ThinHeaderAction from './components/ThinHeaderAction';
import {
  ThinHeaderActionDefinition,
  ThinHeaderItemDefinition,
} from './types/ThinHeader';

type ThinHeaderProps = {
  items: ThinHeaderItemDefinition[];
  actions: ThinHeaderActionDefinition[];
};

const ThinHeader = ({ items, actions }: ThinHeaderProps) => {
  return (
    <div className="ThinHeader">
      <div className="ThinHeader__items">
        {items.map((item) => (
          <ThinHeaderItem key={item.key} item={item} />
        ))}
      </div>
      <div className="ThinHeader__actions">
        {actions.map((action) => (
          <ThinHeaderAction key={action.key} action={action} />
        ))}
      </div>
    </div>
  );
};

export default ThinHeader;
