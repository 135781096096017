import { putOrganizationCaseMembersAccess } from 'api/cases';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { IMutationProps } from '../../../interfaces/ui';
import { IEditCaseMemberAccess } from '../../../interfaces/caseAccesses';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';

type useModifyCaseMemberAccessParams = {
  organizationId: string;
};

const useModifyCaseMemberAccess = (
  params: useModifyCaseMemberAccessParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification<IEditCaseMemberAccess, void>({
    asyncApiFunction: async (body: IEditCaseMemberAccess) => {
      await putOrganizationCaseMembersAccess(
        params.organizationId,
        body.caseId,
        body.caseAccesses
      );
    },
    onSuccess: () => {
      if (options.successFb) options.successFb();
      displaySuccessNotification(
        'Api.Mutations.CaseGroupDetailsCases.ModifyCaseMemberAccess.success'
      );
    },
    onErrorArray: (errorArray) => {
      if (options.errorFb) options.errorFb();
      displayErrorArrayNotifications(
        errorArray,
        'Api.Mutations.CaseGroupDetailsCases.ModifyCaseMemberAccess.failure'
      );
    },
    resetOnResult: true,
  });
};

export default useModifyCaseMemberAccess;
