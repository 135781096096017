import { IEditableDivProps } from 'interfaces/ui';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import Clamp from 'react-multiline-clamp';
import { FormattedMessage } from 'react-intl';

interface IExpandableTextareaFieldProps
  extends Omit<IEditableDivProps, 'type'> {}

const ExpandableTextareaField = ({
  divCssClass,
  inputCssClass,
  value,
  disabled,
  onEdit,
  name,
  ...props
}: IExpandableTextareaFieldProps) => {
  const [inputValue, setInputValue] = useState('');

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    onEdit(name, event.target.value);
    setInputValue(event.target.value);
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  if (disabled) {
    return (
      <Clamp
        withTooltip
        lines={2}
        withToggle
        showMoreElement={({ toggle }) => (
          <button type="button" onClick={toggle} className="show-more-button">
            <FormattedMessage id="General.showMore" />
          </button>
        )}
        showLessElement={({ toggle }) => (
          <button type="button" onClick={toggle} className="show-more-button">
            <FormattedMessage id="General.showLess" />
          </button>
        )}
      >
        <div className={divCssClass}>{value}</div>
      </Clamp>
    );
  } else {
    return (
      <Input
        {...props}
        type="textarea"
        onChange={onChange}
        name={name}
        value={inputValue}
        className={inputCssClass}
      />
    );
  }
};

export default ExpandableTextareaField;
