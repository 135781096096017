import { editOrganizationGrasp } from 'api/graspManagement';
import { IPatchObject } from 'interfaces/request';
import { IMutationProps } from 'interfaces/ui';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

type PatchParams = {
  graspId: string;
  patchData: IPatchObject[];
};

function useEditOrganizationGrasp(
  organizationId: string,
  options?: IMutationProps
) {
  const { successFb } = options || {};

  return useMutationWithResultNotification({
    asyncApiFunction: async ({ graspId, patchData }: PatchParams) => {
      await editOrganizationGrasp(organizationId, graspId, patchData);
    },
    resetOnResult: true,
    onSuccess: () => {
      displaySuccessNotification('MyOrganizations.grasps.editSuccess');
      successFb && successFb();
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'MyOrganizations.grasps.editErrors'
      );
    },
  });
}

export default useEditOrganizationGrasp;
