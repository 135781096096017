import { DateRange } from 'react-day-picker';
import { useQuery } from 'react-query';
import {
  arrayToQueryParamsArrayItems,
  QueryParamsArrayItem,
} from '../../../helpers/utils/normalizeQueryParams';
import { OrganizationQueryParams } from '../../../interfaces/queryParams';
import { IQueryRequest } from '../../../interfaces/request';
import { getOrganizationAwareSessions } from '../../awareSessions';
import QueryKeys from '../queryKeys';

export type AwareSessionFilterQueryParams = {
  organization_id?: string[];
  member_id?: string[];
  questions?: string[];
};

export type AwareSessionsQueryParams = OrganizationQueryParams & {
  dateRange: DateRange;
  aggregated: boolean;
  filters: AwareSessionFilterQueryParams;
};

export const applyAwareSessionParams = (
  params: AwareSessionsQueryParams
): QueryParamsArrayItem[] => {
  return [
    ...arrayToQueryParamsArrayItems(
      params.filters.organization_id ?? [],
      'organization_id'
    ),
    ...arrayToQueryParamsArrayItems(
      params.filters.member_id ?? [],
      'member_id'
    ),
    ...arrayToQueryParamsArrayItems(
      params.filters.questions ?? [],
      'questions'
    ),
    {
      aggregated: params.aggregated,
      from: params.dateRange.from?.toISOString(),
      to: params.dateRange.to?.toISOString(),
    },
  ];
};

const useOrganizationAwareSessions = ({
  params,
  options,
}: IQueryRequest<AwareSessionsQueryParams>) => {
  const { organizationId, aggregated } = params;
  return useQuery({
    ...options,
    queryKey: [
      QueryKeys.getOrganizationAwareSessions,
      organizationId,
      aggregated,
      params.dateRange.from?.toISOString(),
      params.dateRange.to?.toISOString(),
      Object.values(params.filters).join('|'),
    ],
    queryFn: async () => {
      const queryParams = applyAwareSessionParams(params);
      const { data } = await getOrganizationAwareSessions(
        organizationId,
        queryParams
      );
      return data.data;
    },
  });
};

export default useOrganizationAwareSessions;
