import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'reactstrap';
import logo from 'assets/images/graspLogin.png';

const ScrollableMembershipLayout = ({ children }: React.PropsWithChildren) => (
  <div className="mem-wrapper">
    <div className="mem-content">
      <Row className="d-flex w-100 h-100 no-gutters">
        <Col xs={12} sm={12} lg={4} xl={4} className="d-none d-lg-block">
          <Col xs={12} sm={12} lg={4} xl={4} className="mem-sticky">
            <div className="mem-content__logoSection">
              <div className="mem-content__logoSection__LogoTitle d-flex flex-column">
                <span>
                  <FormattedMessage id="Register.WelcomeTo" />
                </span>
                <span>
                  <FormattedMessage id="Register.Graspinsights" />
                </span>
              </div>
              <div>
                <img
                  className="img-fluid mem-content__logoSection__logo"
                  src={logo}
                  alt="logo"
                />
              </div>
            </div>
          </Col>
        </Col>
        <Col xs={12} sm={12} lg={8} xl={8}>
          {children}
        </Col>
      </Row>
    </div>
  </div>
);

export default ScrollableMembershipLayout;
