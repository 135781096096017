import React from 'react';
import { useInitQueries } from '../../../../../../../../../../../../hooks/queries/useInitQuery';
import { useQueriesLoading } from '../../../../../../../../../../../../hooks/queries/useQueriesLoading';
import { useCommonData } from '../../hooks/useCommonData';
import TrendsItem from './components/TrendsItem';
import TrendsPlaceholder from './components/TrendsPlaceholder';
import TrendsQueriesWrapper from './components/TrendsQueriesWrapper';

const Trends = () => {
  const {
    caseDetails,
    trendVisualizationTemplate,
    conversationQuestions,
    observationMeanings,
  } = useCommonData();

  const queries = [
    caseDetails,
    trendVisualizationTemplate,
    conversationQuestions,
    observationMeanings,
  ];

  useInitQueries(queries);

  const isQueriesLoading = useQueriesLoading(queries);

  if (isQueriesLoading) return <TrendsPlaceholder />;

  return (
    <div className="d-flex flex-column gap-2">
      {trendVisualizationTemplate.data && (
        <TrendsQueriesWrapper template={trendVisualizationTemplate.data}>
          {(props) => (
            <div className="d-flex flex-column gap-2">
              {trendVisualizationTemplate.data.visualizations.map(
                (visualization) => (
                  <TrendsItem
                    key={visualization.sequence}
                    visualization={visualization}
                    questions={conversationQuestions.data ?? []}
                    meanings={observationMeanings.data ?? []}
                    queries={props}
                  />
                )
              )}
            </div>
          )}
        </TrendsQueriesWrapper>
      )}
    </div>
  );
};

export default Trends;
