import React, { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import useOrganizationUserAccessProfiles from '../../../../api/queries/accessProfiles/useOrganizationUserAccessProfiles';
import { useRemoveAccessProfileRoles } from 'api/mutations/accessProfiles/useRemoveAccessProfileRoles';
import { useDeleteAccessProfile } from 'api/mutations/accessProfiles/useDeleteAccessProfile';
import useOpen from '../../../../hooks/useOpen';
import { UserRoles } from 'interfaces/membership';
import { Button, Row } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import AddAccessProfileModal from './components/AddAccessProfileModal';
import ConfirmModal from '../../../../components/ConfirmModal';
import CustomTable from '../../../../components/CustomTable';
import AccessProfileItem from './components/AccessProfileItem';
import { useOrganizationPathParams } from 'hooks/paths/useOrganizationPathParams';
import TabLayout, {
  TabLayoutType,
} from '../../../../components/layouts/TabLayout';

const headerItems = [
  'UsersAndAccess.AccessProfilesTab.accessProfileName',
  'UsersAndAccess.AccessProfilesTab.accessProfileRoles',
  'UsersAndAccess.AccessProfilesTab.accessProfileActions',
];

export type AccessProfileRoleToDelete = {
  accessProfileId: string;
  accessProfileRole: UserRoles;
};

export type AccessProfileToRename = {
  accessProfileId: string;
  name: string;
};

export type AccessProfileRoleToAdd = {
  accessProfileId: string;
  role: UserRoles | null;
};

const AccessProfilesTab = () => {
  const { organizationId } = useOrganizationPathParams();

  const intl = useIntl();

  const { data: accessProfiles, refetch } = useOrganizationUserAccessProfiles({
    params: { organizationId },
  });

  const { mutateAsync: removeRolesMutation } = useRemoveAccessProfileRoles(
    {
      organizationId: organizationId,
    },
    {
      successFb: refetch,
    }
  );

  const { mutateAsync: deleteMutation } = useDeleteAccessProfile(
    {
      organizationId: organizationId,
    },
    {
      successFb: refetch,
    }
  );

  const { isOpen, toggle } = useOpen();

  const [accessProfileToDelete, setAccessProfileToDelete] = useState<
    string | null
  >(null);

  const [accessProfileRoleToDelete, setAccessProfileRoleToDelete] =
    useState<AccessProfileRoleToDelete | null>(null);

  const [accessProfileToRename, setAccessProfileToRename] =
    useState<AccessProfileToRename | null>(null);

  const [accessProfileRoleToAdd, setAccessProfileRoleToAdd] =
    useState<AccessProfileRoleToAdd | null>(null);

  const deleteAccessProfileIsOpen = accessProfileToDelete !== null;
  const deleteAccessProfileClose = () => {
    setAccessProfileToDelete(null);
  };
  const deleteAccessProfileMessage = intl.formatMessage({
    id: 'MyOrganizations.accessProfiles.deleteAccessProfileMessage',
  });

  const deleteAccessProfileConfirm = async () => {
    if (accessProfileToDelete === null) return;

    const existingAccessProfile = accessProfiles?.find(
      (p) => p.id === accessProfileToDelete
    );

    if (existingAccessProfile === undefined) return;

    await deleteMutation(existingAccessProfile.id);
    deleteAccessProfileClose();
  };

  const modifyAccessProfileIsOpen = accessProfileRoleToDelete !== null;
  const modifyAccessProfileClose = () => {
    setAccessProfileRoleToDelete(null);
  };
  const modifyAccessProfileMessage = intl.formatMessage({
    id: 'MyOrganizations.accessProfiles.modifyAccessProfileMessage',
  });

  const modifyAccessProfileConfirm = async () => {
    if (accessProfileRoleToDelete === null) return;

    const existingAccessProfile = accessProfiles?.find(
      (p) => p.id === accessProfileRoleToDelete.accessProfileId
    );

    if (existingAccessProfile === undefined) return;

    await removeRolesMutation({
      accessProfileId: accessProfileRoleToDelete.accessProfileId,
      roles: [accessProfileRoleToDelete.accessProfileRole],
    });

    modifyAccessProfileClose();
  };

  const renderAccessProfiles = useMemo(() => {
    if (accessProfiles === undefined) return [];

    return accessProfiles.map((profile) => (
      <AccessProfileItem
        key={profile.id}
        setAccessProfileRoleToDelete={setAccessProfileRoleToDelete}
        organizationId={organizationId}
        accessProfileRoleToAdd={accessProfileRoleToAdd}
        accessProfiles={accessProfiles}
        accessProfilesRefetch={refetch}
        accessProfileToRename={accessProfileToRename}
        setAccessProfileRoleToAdd={setAccessProfileRoleToAdd}
        setAccessProfileToRename={setAccessProfileToRename}
        profile={profile}
        setAccessProfileToDelete={setAccessProfileToDelete}
      />
    ));
  }, [
    accessProfileRoleToAdd,
    accessProfileToRename,
    accessProfiles,
    organizationId,
    refetch,
  ]);

  return (
    <>
      <AddAccessProfileModal
        toggle={toggle}
        refetch={refetch}
        isOpen={isOpen}
        organizationId={organizationId}
      />
      <ConfirmModal
        isOpen={deleteAccessProfileIsOpen}
        toggle={deleteAccessProfileClose}
        message={deleteAccessProfileMessage}
        confirm={deleteAccessProfileConfirm}
      />
      <ConfirmModal
        isOpen={modifyAccessProfileIsOpen}
        toggle={modifyAccessProfileClose}
        message={modifyAccessProfileMessage}
        confirm={modifyAccessProfileConfirm}
      />
      <TabLayout
        type={TabLayoutType.Generic}
        titlePrefix="Access.AccessProfiles"
        actions={
          <Button color="primary" outline={true} onClick={toggle}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        }
      >
        <div>
          <Row className="mt-4">
            {accessProfiles && (
              <CustomTable
                headerItems={headerItems}
                bodyRows={renderAccessProfiles}
              />
            )}
          </Row>
        </div>
      </TabLayout>
    </>
  );
};

export default AccessProfilesTab;
