import { useCallback } from 'react';
import useModifyTreatmentPhase, {
  UpsertTreatmentPhaseProps,
} from '../../../../../../../../../api/mutations/conversations/useModifyTreatmentPhase';
import { useOrganizationPathParams } from '../../../../../../../../../hooks/paths/useOrganizationPathParams';
import { useQueriesRefetch } from '../../../../../../../../../hooks/queries/useQueriesRefetch';
import useRequiredParams from '../../../../../../../../../hooks/useRequiredParams';
import { useTreatmentPlanCommonData } from '../../../../../hooks/useTreatmentPlanCommonData';

export const useHandleUpsert = (toggle?: () => void) => {
  const { organizationId } = useOrganizationPathParams();

  const { treatmentPlanId } = useRequiredParams<{
    treatmentPlanId: string;
  }>(['treatmentPlanId']);

  const { caseCategories, treatmentPhases } = useTreatmentPlanCommonData();

  const queriesRefetch = useQueriesRefetch([caseCategories, treatmentPhases]);

  const onSuccess = async () => {
    await queriesRefetch();
    toggle?.();
  };

  const { mutateAsync: modifyAsync } = useModifyTreatmentPhase(
    organizationId,
    treatmentPlanId,
    onSuccess
  );

  return useCallback(
    async (upsert: UpsertTreatmentPhaseProps) => {
      await modifyAsync(upsert);
    },
    [modifyAsync]
  );
};
