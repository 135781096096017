import React from 'react';
import EditableInput from '../../../../../../components/EditableInput/EditableInput';
import { InputType } from 'reactstrap/types/lib/Input';

type CellEditableInputProps = {
  editMode: boolean;
  type: InputType;
  name: string;
  value: string;
  onEdit: (target: string, value: string) => void;
};

const CellEditableInput = ({
  editMode,
  value,
  name,
  type,
  onEdit,
}: CellEditableInputProps) => {
  if (!editMode) return <span>{value}</span>;

  return (
    <EditableInput
      type={type}
      name={name}
      value={value}
      onEdit={onEdit}
      debounceEnabled={false}
    />
  );
};

export default CellEditableInput;
