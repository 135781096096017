import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { IErrorDefinition } from '../../../interfaces/response';
import { cloneTreatmentPlan } from '../../conversations';

type useCloneTreatmentPlanParams = {
  planId: string;
  name: string;
};

const useCloneTreatmentPlan = (
  organizationId: string,
  successFb?: () => void
) => {
  const mutationData = useMutationWithResultNotification({
    onSuccess: () => {
      displaySuccessNotification(
        'Api.Mutations.Conversations.CloneTreatmentPlan.success'
      );

      if (successFb) successFb();
      mutationData.reset();
    },
    onError: (_) => {
      displayErrorNotification(
        'Api.Mutations.Conversations.CloneTreatmentPlan.failure'
      );
      mutationData.reset();
    },
    onErrorArray: (errorArray: IErrorDefinition[]) => {
      errorArray.forEach((item) => {
        displayErrorNotification(
          item.reasons.at(0) ??
            'Api.Mutations.Conversations.CloneTreatmentPlan.failure'
        );
      });
      mutationData.reset();
    },
    asyncApiFunction: async (params: useCloneTreatmentPlanParams) => {
      const { data } = await cloneTreatmentPlan(
        organizationId,
        params.planId,
        params.name
      );
      return data.data;
    },
    resetOnResult: false,
  });

  return mutationData;
};

export default useCloneTreatmentPlan;
