import React, { useState } from 'react';
import ThinHeader from '../../../../../../../../../../components/ThinHeader';
import { ISummarizedTreatmentPhase } from '../../../../../../../../../../interfaces/conversations';
import { useThinHeader } from './hooks/useThinHeader';

type PhaseHeaderProps = {
  phase: ISummarizedTreatmentPhase;
  addMode: boolean;
};

const PhaseHeader = ({ phase, addMode }: PhaseHeaderProps) => {
  const [managedPhase, setManagedPhase] = useState<ISummarizedTreatmentPhase>(
    () => phase
  );

  const { items, actions } = useThinHeader(
    managedPhase,
    setManagedPhase,
    phase,
    addMode
  );

  return <ThinHeader items={items} actions={actions} />;
};

export default PhaseHeader;
