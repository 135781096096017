import React from 'react';
import { Typography } from '@mui/material';

type EntryProps = {
  header: string;
  value: string;
};

const Entry = ({ header, value }: EntryProps) => {
  return (
    <div className="flex flex-col items-center">
      <Typography variant="subtitle1">{header}</Typography>
      <Typography variant="subtitle2">{value}</Typography>
    </div>
  );
};

export default Entry;
