import { FormikProps } from 'formik';
import { AwareCaseDetailsFormValues } from '../../../forms/AwareCaseDetailsForm/types';
import { useTypedContext } from '../../../../../../../../../hooks/useTypedContext';
import { OperationalDataContext } from '../../../providers/OperationalDataProvider';
import { useMsDiffs } from '../../../components/ProcedureEventsTimeline/hooks/useMsDiffs';
import { useCallback } from 'react';

export const useActivitiesDiffs = (
  form: FormikProps<AwareCaseDetailsFormValues>
) => {
  const { awareSessionDetails } = useTypedContext(OperationalDataContext);

  const { getMsDiff } = useMsDiffs(
    awareSessionDetails.start_date,
    awareSessionDetails.end_date
  );

  return useCallback(
    (procedureIndex: number) => {
      return form.values.procedures[procedureIndex].activities.map(
        (activity, index) => ({
          index,
          startDiff: getMsDiff(
            awareSessionDetails.start_date,
            activity.timeStart
          ),
          endDiff: getMsDiff(awareSessionDetails.start_date, activity.timeEnd),
        })
      );
    },
    [awareSessionDetails.start_date, form.values.procedures, getMsDiff]
  );
};
