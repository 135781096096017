import AddIcon from '@mui/icons-material/Add';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import { useAddObservationMeaning } from 'api/mutations/observationMeanings/useAddObservationMeaning';
import { useModifyObservationMeaning } from 'api/mutations/observationMeanings/useModifyObservationMeaning';
import { useRemoveObservationMeaning } from 'api/mutations/observationMeanings/useRemoveObservationMeaning';
import useObservationMeanings from 'api/queries/observationMeanings/useObservationMeanings';
import CustomTable from 'components/CustomTable';
import EditableInput from 'components/EditableInput/EditableInput';
import useRequiredParams from 'hooks/useRequiredParams';
import {
  ICreateOrganizationObservationMeaning,
  IOrganizationObservationMeaning,
} from 'interfaces/observationMeanings';
import { IPatchObject } from 'interfaces/request';
import { cloneDeep } from 'lodash';
import React, { useMemo, useState } from 'react';
import { No, Us } from 'react-flags-select';
import { FormattedMessage } from 'react-intl';
import IconButton from '../../../../../../components/IconButton';
import TabLayout from '../../../../../../components/layouts/TabLayout';
import { CommonTabProps } from '../types';
import ObservationMeaningFieldWrapper from './components/ObservationMeaningFieldWrapper';

const createPatchObjectFromData = (
  organizationObservationMeaning: IOrganizationObservationMeaning
): IPatchObject[] => {
  let patchObjects: IPatchObject[] = [];

  for (const [key, value] of Object.entries(organizationObservationMeaning)) {
    if (['id', 'organization_id', 'organization'].includes(key)) continue;

    patchObjects.push({
      op: 'replace',
      path: `/${key}`,
      value: value,
    });
  }

  return patchObjects;
};

const ObservationMeaningsTab = ({ type }: CommonTabProps) => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const headerItems = [
    <div
      key="observation-meanings-name-en"
      className="d-flex align-items-center gap-1"
    >
      <Us />
      <FormattedMessage id="Organizations.OrganizationDetails.ObservationMeanings.name" />
    </div>,
    <div
      key="observation-meanings-name-no"
      className="d-flex align-items-center gap-1"
    >
      <No />
      <FormattedMessage id="Organizations.OrganizationDetails.ObservationMeanings.name" />
    </div>,
    'Organizations.OrganizationDetails.ObservationMeanings.assignmentRule',
    'Organizations.OrganizationDetails.ObservationMeanings.visualizationColor',
    'Organizations.OrganizationDetails.ObservationMeanings.actions',
  ];

  const {
    data: organizationObservationMeanings,
    refetch: refetchOrganizationObservationMeanings,
  } = useObservationMeanings({ params: { organizationId } });

  const [addNewEntryMode, setAddNewEntryMode] =
    useState<ICreateOrganizationObservationMeaning | null>(null);
  const [modifiedEntry, setModifiedEntry] =
    useState<IOrganizationObservationMeaning | null>(null);

  const handleSuccess = async () => {
    await refetchOrganizationObservationMeanings();
    setAddNewEntryMode(null);
    setModifiedEntry(null);
  };

  const { mutateAsync: addObservationMeaning } = useAddObservationMeaning(
    { organizationId: organizationId },
    { successFb: handleSuccess }
  );

  const { mutateAsync: modifyObservationMeaning } = useModifyObservationMeaning(
    { organizationId: organizationId },
    { successFb: handleSuccess }
  );

  const { mutateAsync: removeObservationMeaning } = useRemoveObservationMeaning(
    { organizationId: organizationId },
    { successFb: handleSuccess }
  );

  const handleEnableNewEntryMode = () => {
    setAddNewEntryMode({
      name_en: '',
      name_no: '',
      assignment_rule: '',
      color: '#000000',
    });
  };

  const renderBodyRows = useMemo(() => {
    if (organizationObservationMeanings === undefined) return [];

    let rows = organizationObservationMeanings.map(
      (organizationObservationMeaning) => {
        const handleRemoveItem = async () => {
          await removeObservationMeaning(organizationObservationMeaning.id);
        };

        const handleEditItem = () => {
          setModifiedEntry({
            ...organizationObservationMeaning,
          });
        };

        const handleResetItem = () => {
          setModifiedEntry(null);
        };

        const handlePatchItem = async () => {
          if (modifiedEntry === null) return;

          const patch = createPatchObjectFromData(modifiedEntry);

          await modifyObservationMeaning({
            meaning_id: modifiedEntry.id,
            document: patch,
          });
        };

        const onEdit = (target: string, value: string) => {
          let modifiedEntryCopy = cloneDeep(modifiedEntry);

          if (modifiedEntryCopy == null) return;

          modifiedEntryCopy[target] = value;
          setModifiedEntry(modifiedEntryCopy);
        };

        return (
          <tr key={organizationObservationMeaning.id}>
            <td valign="middle">
              <ObservationMeaningFieldWrapper
                modifiedEntry={modifiedEntry}
                meaningId={organizationObservationMeaning.id}
                onEdit={onEdit}
                inputType="text"
                value={organizationObservationMeaning.name_en}
                name="name_en"
              />
            </td>
            <td valign="middle">
              <ObservationMeaningFieldWrapper
                modifiedEntry={modifiedEntry}
                meaningId={organizationObservationMeaning.id}
                onEdit={onEdit}
                inputType="text"
                value={organizationObservationMeaning.name_no}
                name="name_no"
              />
            </td>
            <td valign="middle">
              <ObservationMeaningFieldWrapper
                modifiedEntry={modifiedEntry}
                meaningId={organizationObservationMeaning.id}
                onEdit={onEdit}
                inputType="text"
                value={organizationObservationMeaning.assignment_rule}
                name="assignment_rule"
              />
            </td>
            <td valign="middle">
              <ObservationMeaningFieldWrapper
                modifiedEntry={modifiedEntry}
                meaningId={organizationObservationMeaning.id}
                onEdit={onEdit}
                inputType="color"
                value={organizationObservationMeaning.color}
                name="color"
              />
            </td>
            <td valign="middle" align="center" width="10%">
              <div className="d-flex align-items-center justify-content-center gap-2">
                {modifiedEntry &&
                modifiedEntry.id === organizationObservationMeaning.id ? (
                  <>
                    <IconButton
                      color="primary"
                      variant="outlined"
                      onClick={handlePatchItem}
                    >
                      <CheckOutlinedIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      variant="contained"
                      onClick={handleResetItem}
                    >
                      <ReplayOutlinedIcon />
                    </IconButton>
                  </>
                ) : (
                  <>
                    <IconButton
                      color="primary"
                      disabled={!!addNewEntryMode}
                      variant="contained"
                      onClick={handleEditItem}
                    >
                      <EditOutlinedIcon />
                    </IconButton>
                    <IconButton
                      color="primary"
                      variant="outlined"
                      onClick={handleRemoveItem}
                    >
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </>
                )}
              </div>
            </td>
          </tr>
        );
      }
    );

    if (addNewEntryMode !== null) {
      const onEdit = (target: string, value: string) => {
        let addEntryModeCopy = cloneDeep(addNewEntryMode);
        addEntryModeCopy[target] = value;
        setAddNewEntryMode(addEntryModeCopy);
      };

      const handleAddItem = async () => {
        await addObservationMeaning({
          name_en: addNewEntryMode.name_en,
          name_no: addNewEntryMode.name_no,
          color: addNewEntryMode.color,
          assignment_rule: addNewEntryMode.assignment_rule,
        });
      };

      const handleResetItem = () => {
        setAddNewEntryMode(null);
      };

      rows.push(
        <tr key="observation-meanings-new-entry">
          <td valign="middle">
            <EditableInput
              type="text"
              value={addNewEntryMode.name_en}
              name="name_en"
              onEdit={onEdit}
              debounceEnabled={false}
            />
          </td>
          <td valign="middle">
            <EditableInput
              type="text"
              value={addNewEntryMode.name_no}
              name="name_no"
              onEdit={onEdit}
              debounceEnabled={false}
            />
          </td>
          <td valign="middle">
            <EditableInput
              type="text"
              value={addNewEntryMode.assignment_rule}
              name="assignment_rule"
              onEdit={onEdit}
              debounceEnabled={false}
            />
          </td>
          <td valign="middle">
            <div className="d-flex align-items-center gap-2">
              <EditableInput
                type="color"
                value={addNewEntryMode.color}
                name="color"
                onEdit={onEdit}
                debounceEnabled={false}
              />
            </div>
          </td>
          <td valign="middle" align="center" width="10%">
            <div className="d-flex align-items-center justify-content-center gap-2">
              <IconButton
                color="primary"
                variant="outlined"
                onClick={handleAddItem}
              >
                <CheckOutlinedIcon />
              </IconButton>
              <IconButton
                color="primary"
                variant="outlined"
                onClick={handleResetItem}
              >
                <ReplayOutlinedIcon />
              </IconButton>
            </div>
          </td>
        </tr>
      );
    }

    return rows;
  }, [
    addNewEntryMode,
    addObservationMeaning,
    modifiedEntry,
    modifyObservationMeaning,
    organizationObservationMeanings,
    removeObservationMeaning,
  ]);

  return (
    <TabLayout
      type={type}
      titlePrefix="Organizations.ObservationMeanings"
      actions={
        <IconButton
          color="primary"
          variant="outlined"
          disabled={addNewEntryMode !== null}
          onClick={handleEnableNewEntryMode}
        >
          <AddIcon />
        </IconButton>
      }
    >
      <div>
        <CustomTable headerItems={headerItems} bodyRows={renderBodyRows} />
      </div>
    </TabLayout>
  );
};

export default ObservationMeaningsTab;
