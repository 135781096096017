import React from 'react';
import EditableInput from './EditableInput/EditableInput';
import { DateRange, DayModifiers, DayPicker } from 'react-day-picker';
import { Alert, Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

type CustomOneColumnDatePickerProps = {
  oneColumn: boolean;
  calendarWrapperClasses: string;
  inputContainerClasses: string;
  validation: (dateString: string) => boolean;
  startDateInput?: string;
  startTimeInput?: string;
  disableDate?: boolean;
  hideTime?: boolean;
  toDateInput?: string;
  toTimeInput?: string;
  onDateEdit: (target: string, val: string) => void;
  onTimeEdit: (target: string, val: string) => void;
  displayPredefinedTimeRange?: boolean;
  hideLastActive?: boolean;
  selectedDays: DateRange;
  modifiers: DayModifiers;
  onDayClick: (pickedDate: Date) => void;
  disabledDays: Date[];
  isRangeCorrect?: boolean;
  lastActiveDays: boolean;
  renderPredefinedRanges: JSX.Element[];
  onCancel: () => void;
  onAccept: () => void;
  toggleLastActive: () => void;
};

const CustomOneColumnDatePicker = ({
  calendarWrapperClasses,
  inputContainerClasses,
  validation,
  startDateInput,
  startTimeInput,
  disableDate,
  hideTime,
  toDateInput,
  toTimeInput,
  onDateEdit,
  onTimeEdit,
  displayPredefinedTimeRange,
  hideLastActive,
  onDayClick,
  selectedDays,
  modifiers,
  disabledDays,
  onAccept,
  onCancel,
  isRangeCorrect,
  toggleLastActive,
  lastActiveDays,
  renderPredefinedRanges,
  oneColumn,
}: CustomOneColumnDatePickerProps) => {
  if (!oneColumn) return <></>;

  return (
    <>
      <div className="TimeFilterDropdown__container">
        <div className={calendarWrapperClasses}>
          <div className="w-100 d-flex flex-column">
            <div className={inputContainerClasses}>
              <span>Start date</span>
              <div className="TimeFilterDropdown__date-input-container__inputs">
                <EditableInput
                  validation={validation}
                  withoutRefetch={true}
                  value={startDateInput}
                  disabled={disableDate}
                  name="from"
                  onEdit={onDateEdit}
                  debounceTime={2000}
                  type="date"
                  className="TimeFilterDropdown__date-input-container__inputs__date"
                />
                {!hideTime && (
                  <EditableInput
                    withoutRefetch={true}
                    value={startTimeInput}
                    onEdit={onTimeEdit}
                    name="time_start"
                    type="time"
                    className="TimeFilterDropdown__date-input-container__inputs__time"
                  />
                )}
              </div>
            </div>
            <div className={inputContainerClasses}>
              <span>End date</span>
              <div className="TimeFilterDropdown__date-input-container__inputs">
                <EditableInput
                  validation={validation}
                  withoutRefetch={true}
                  value={toDateInput}
                  disabled={disableDate}
                  name="to"
                  onEdit={onDateEdit}
                  debounceTime={2000}
                  type="date"
                  className="TimeFilterDropdown__date-input-container__inputs__date"
                />
                {!hideTime && (
                  <EditableInput
                    withoutRefetch={true}
                    value={toTimeInput}
                    onEdit={onTimeEdit}
                    name="time_end"
                    type="time"
                    className="TimeFilterDropdown__date-input-container__inputs__time"
                  />
                )}
              </div>
            </div>
          </div>
          <DayPicker
            className="Selectable"
            numberOfMonths={1}
            selected={selectedDays}
            modifiers={modifiers}
            onDayClick={onDayClick}
            disableNavigation={!disableDate}
            disabled={disabledDays}
          />
          {!isRangeCorrect && (
            <Alert className="m-4 text-center" color="danger">
              <FormattedMessage id="General.wrongDate" />
            </Alert>
          )}
          <div className="ms-4 mb-4 d-flex">
            <Button className="TimeFilterDropdown__cancel" onClick={onCancel}>
              <FormattedMessage id="General.cancel" />
            </Button>
            <Button
              disabled={!isRangeCorrect}
              onClick={onAccept}
              className="ms-2 TimeFilterDropdown__confirm"
            >
              <FormattedMessage id="General.confirm" />
            </Button>
          </div>
        </div>
        {displayPredefinedTimeRange && (
          <div className="TimeFilterDropdown__predefined TimeFilterDropdown__predefined--one-column">
            {!hideLastActive && (
              <div className="TimeFilterDropdown__predefined__toggle">
                <div
                  className={classNames({
                    TimeFilterDropdown__predefined__toggle__item: true,
                    'TimeFilterDropdown__predefined__toggle__item--inverted':
                      lastActiveDays,
                  })}
                  onClick={toggleLastActive}
                >
                  <FormattedMessage id="UserDashboard.SqueezeSummary.last" />
                </div>
                <div
                  className={classNames({
                    TimeFilterDropdown__predefined__toggle__item: true,
                    'TimeFilterDropdown__predefined__toggle__item--inverted':
                      !lastActiveDays,
                  })}
                  onClick={toggleLastActive}
                >
                  <FormattedMessage id="UserDashboard.SqueezeSummary.lastActive" />
                </div>
              </div>
            )}
            <div className="TimeFilterDropdown__predefined__items-wrapper">
              {renderPredefinedRanges}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CustomOneColumnDatePicker;
