import { ThemeOptions } from '@mui/material';
import { ThemeTypes } from './types';
import { deepmerge } from '@mui/utils';
import { ThemesOptions } from './options';

export namespace ThemeUtils {
  export const mergeVariant = (
    base: ThemeOptions,
    variant: ThemeTypes.ThemeVariant
  ): ThemeOptions => {
    switch (variant) {
      case ThemeTypes.ThemeVariant.Light:
        return deepmerge(base, ThemesOptions.lightThemeOptions);
      case ThemeTypes.ThemeVariant.Dark:
        return deepmerge(base, ThemesOptions.darkThemeOptions);
    }
  };
}
