import Avatar from '@mui/material/Avatar';
import Icon from '@mui/material/Icon';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import React from 'react';
import { IListDashboardTemplateVisualization } from '../../../../../../../../../../../../interfaces/dashboards';
import ForEach from '../../../../../../../../../../../ForEach';
import EmptyList from './components/EmptyList';
import ListItemTextGenerator from './components/ListItemTextGenerator';
import { useListViewData } from './hooks/useListViewData';

type ListViewProps = {
  visualization: IListDashboardTemplateVisualization;
};

const ListView = ({ visualization }: ListViewProps) => {
  const listViewData = useListViewData();

  return (
    <>
      {listViewData.length === 0 && <EmptyList />}
      <List>
        <ForEach items={listViewData as any[]}>
          {(item) => (
            <ListItem
              sx={visualization.style ? JSON.parse(visualization.style) : {}}
            >
              {visualization.start_icon && (
                <ListItemAvatar>
                  <Avatar>
                    <Icon>{visualization.start_icon}</Icon>
                  </Avatar>
                </ListItemAvatar>
              )}
              <ListItemText
                primary={
                  <ListItemTextGenerator
                    item={item}
                    text={visualization.primary_text}
                  />
                }
                secondary={
                  visualization.secondary_text ? (
                    <ListItemTextGenerator
                      item={item}
                      text={visualization.secondary_text}
                    />
                  ) : undefined
                }
              />
              {visualization.end_icon && <Icon>{visualization.end_icon}</Icon>}
            </ListItem>
          )}
        </ForEach>
      </List>
    </>
  );
};

export default ListView;
