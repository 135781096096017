import React from 'react';
import { ModalBody } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, FormikProps } from 'formik';
import { useForm } from '../../hooks/useForm';
import { AwareCaseDetailsFormValues } from '../../../../forms/AwareCaseDetailsForm/types';
import { ICaseProcedureWithActivitiesLocalized } from '../../../../../../../../../../interfaces/cases';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import TextField from '@mui/material/TextField';
import TextMaskInput from '../../../../../../../../../../components/TextMaskInput';
import { useGetSelectedProcedure } from '../../hooks/useGetSelectedProcedure';
import { Chip } from '@mui/material';
import { useCookie } from '../../../../../../../../../../hooks/useCookie';
import { ViewTheme } from '../../../../../../../../../../interfaces/ui';
import { useHandleActivity } from '../../hooks/useHandleActivity';
import Button from '@mui/material/Button';
import classNames from 'classnames';
import {
  GraspColors,
  graspColorsValues,
} from '../../../../../../../../../../helpers/colors';
import useCultures from 'hooks/cultures/useCultures';

type ManageActivityFormProps = {
  msPoint: number;
  activityIndex: number | undefined;
  form: FormikProps<AwareCaseDetailsFormValues>;
  procedures: ICaseProcedureWithActivitiesLocalized[];
  onAction: () => void;
};

const ManageActivityForm = ({
  activityIndex,
  procedures,
  form,
  msPoint,
  onAction,
}: ManageActivityFormProps) => {
  const { currentCulture, defaultCulture } = useCultures();
  const intl = useIntl();
  const themeCookie = useCookie<ViewTheme>('theme');

  const { initialValues, validation, handleSubmit, handleDelete } = useForm(
    msPoint,
    activityIndex,
    form,
    procedures,
    onAction
  );

  const handleActivity = useHandleActivity();

  const getSelectedProcedure = useGetSelectedProcedure(procedures);

  const defaultColor =
    themeCookie !== ViewTheme.Dark
      ? graspColorsValues[GraspColors.BLACK]
      : graspColorsValues[GraspColors.WHITE];

  return (
    <Formik
      initialValues={initialValues}
      validate={validation}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <ModalBody className="d-flex flex-column gap-3">
          <div className="flex items-center">
            <div
              className={classNames('w-full h-px', {
                'bg-black': themeCookie !== ViewTheme.Dark,
                'bg-white': themeCookie === ViewTheme.Dark,
              })}
            />
            <span className="px-1">
              <FormattedMessage id="Cases.Procedure" />
            </span>
            <div
              className={classNames('w-full h-px', {
                'bg-black': themeCookie !== ViewTheme.Dark,
                'bg-white': themeCookie === ViewTheme.Dark,
              })}
            />
          </div>
          <div className="flex flex-col gap-4">
            <TextField
              className="mt-3"
              variant="standard"
              disabled={props.values.procedureDisabled}
              fullWidth
              select={true}
              id="procedureId"
              name="procedureId"
              value={props.values.procedureId}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              error={
                props.touched.procedureId && Boolean(props.errors.procedureId)
              }
              helperText={props.touched.procedureId && props.errors.procedureId}
            >
              {procedures.map((option) => (
                <MenuItem key={option.id} value={option.id}>
                  {currentCulture.cultureCode === defaultCulture.cultureCode
                    ? option.name_en
                    : option.name_no}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              variant="standard"
              type="text"
              id="startMs"
              name="startMs"
              placeholder={intl.formatMessage({
                id: 'General.TimeStart',
              })}
              value={props.values.startMs}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              error={props.touched.startMs && Boolean(props.errors.startMs)}
              helperText={props.touched.startMs && props.errors.startMs}
              InputProps={{
                inputComponent: TextMaskInput as any,
              }}
            />
            {getSelectedProcedure(props.values.procedureId) && (
              <div className="flex flex-col items-center gap-2">
                {(
                  getSelectedProcedure(props.values.procedureId)?.activities ??
                  []
                ).map((activity) => (
                  <Chip
                    key={activity.id}
                    onClick={handleActivity(activity.id, props)}
                    sx={{
                      paddingX: '0.5rem',
                      backgroundColor: 'transparent',
                      color:
                        props.values.activityId === activity.id
                          ? '#407dbb'
                          : 'inherit',
                      border: `1px solid ${
                        props.values.activityId === activity.id
                          ? '#407dbb'
                          : defaultColor
                      }`,
                    }}
                    avatar={
                      <div
                        className="!w-2 !h-2 rounded-full"
                        style={{ backgroundColor: activity.hex_color }}
                      />
                    }
                    label={activity.name}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="flex w-full items-center gap-3">
            {activityIndex !== undefined && (
              <Button
                onClick={handleDelete}
                sx={{ minWidth: 120, width: '100%' }}
                variant="outlined"
                color="primary"
              >
                <FormattedMessage id="General.Delete" />
              </Button>
            )}
            <Button
              disabled={!props.isValid}
              onClick={props.submitForm}
              sx={{ minWidth: 120, width: '100%' }}
              variant="outlined"
              color="primary"
            >
              <FormattedMessage id="General.Save" />
            </Button>
          </div>
        </ModalBody>
      )}
    </Formik>
  );
};

export default ManageActivityForm;
