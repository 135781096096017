import React from 'react';
import {
  RangeValidConversationQuestion,
  RangeValidConversationQuestionLegendDescription,
} from '../../../../../../../../../../../../../../interfaces/conversations';
import { useTypedContext } from '../../../../../../../../../../../../../../hooks/useTypedContext';
import { LanguageContext } from '../../../../../../../../types';
import { format } from 'date-fns';
import { AVAILABLE_CULTURES } from '../../../../../../../../../../../../../../components/CultureSelector/CultureSelector';
import Slider from '@mui/material/Slider/Slider';
import { useControlledValue } from '../../../../../../../../../../../../../../hooks/useControlledValue';
import { textWrapEllipse } from '../../../../../../../../../../../../../../helpers/utils/strings';

type RangeQuestionItemProps = {
  item: RangeValidConversationQuestion;
};

const RangeQuestionItem = ({ item }: RangeQuestionItemProps) => {
  const language = useTypedContext(LanguageContext);
  const currentHour = format(new Date(), 'HH:HH a');

  const { value, handleChange } = useControlledValue<number>(
    item.answer.number_value ?? item.min_number_value
  );

  const title =
    language.culture === AVAILABLE_CULTURES.EN ? item.title : item.title_nb_no;

  const titleHtml =
    language.culture === AVAILABLE_CULTURES.EN
      ? item.title_html
      : item.title_html_nb_no;

  const getLabel = (
    legendDescription: RangeValidConversationQuestionLegendDescription
  ) => {
    return language.culture === AVAILABLE_CULTURES.EN
      ? legendDescription.label
      : legendDescription.label_nb_no;
  };

  const handleChangeSlider = (_event: Event, value: number | number[]) => {
    if (typeof value !== 'number') return;
    handleChange(value);
  };

  return (
    <div className="ConversationTemplateRangeQuestionItem">
      <div className="ConversationTemplateRangeQuestionItem__question d-flex flex-column">
        {titleHtml ? (
          <span
            className="ConversationTemplatePlainTextItem__content__text"
            dangerouslySetInnerHTML={{ __html: titleHtml }}
          />
        ) : (
          <span className="ConversationTemplatePlainTextItem__content__text">
            {title}
          </span>
        )}
        <span className="ConversationTemplatePlainTextItem__content__hour">
          {currentHour}
        </span>
      </div>
      <div className="ConversationTemplateRangeQuestionItem__slider py-1 px-3">
        <Slider
          color="primary"
          step={1}
          value={value ?? 0}
          onChange={handleChangeSlider}
          min={item.min_number_value ?? 0}
          max={item.max_number_value ?? 100}
          valueLabelDisplay="auto"
          marks={item.legend_descriptions.map((description) => ({
            value: description.value,
            label: (
              <RangeQuestionItemSliderLabel
                label={textWrapEllipse(getLabel(description) ?? '', 10)}
              />
            ),
          }))}
        />
      </div>
    </div>
  );
};

const RangeQuestionItemSliderLabel = ({ label }: { label: string }) => {
  return (
    <div
      style={{
        height: '100px',
        inlineSize: '50px',
        overflow: 'hidden',
        textAlign: 'center',
        whiteSpace: 'pre-wrap',
        fontSize: '12px',
        color: '#000000',
      }}
    >
      {label}
    </div>
  );
};

export default RangeQuestionItem;
