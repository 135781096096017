import React, { useMemo } from 'react';
import { Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import { useTypedContext } from '../../../../../../../../../../hooks/useTypedContext';
import { OperationalDataContext } from '../../../../providers/OperationalDataProvider';

const MedicalPersonnelField = () => {
  const intl = useIntl();

  const { members, awareSessionDetails } = useTypedContext(
    OperationalDataContext
  );

  const currentMember = useMemo(() => {
    const selectedMember = members.find(
      (p) => p.id === awareSessionDetails.member_id
    );

    if (!selectedMember) return '-';
    return `${selectedMember.last_name} ${selectedMember.first_name}`;
  }, [awareSessionDetails.member_id, members]);

  return (
    <div>
      <div className="flex items-center gap-2">
        <Typography
          variant="body1"
          sx={{
            overflow: 'hidden',
            textWrap: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          <FormattedMessage
            id="General.SemicolonLabel"
            values={{
              label: intl.formatMessage({ id: 'Cases.MedicalPersonnel' }),
            }}
          />
        </Typography>
        <Typography
          sx={{
            overflow: 'hidden',
            textWrap: 'nowrap',
            textOverflow: 'ellipsis',
          }}
          variant="subtitle1"
        >
          {currentMember}
        </Typography>
      </div>
    </div>
  );
};

export default MedicalPersonnelField;
