import InvalidUrlPath from './InvalidUrlPath';
import EmptyTable from './EmptyTable';
import Breadcrumbs, { BreadcrumbsProps } from './Breadcrumbs';

const Placeholders = () => {
  return null;
};

Placeholders.InvalidUrlPath = () => <InvalidUrlPath />;
Placeholders.EmptyTable = () => <EmptyTable />;
Placeholders.Breadcrumbs = (props: BreadcrumbsProps) => (
  <Breadcrumbs {...props} />
);

export default Placeholders;
