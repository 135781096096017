import Schedule from 'components/Schedule';
import React from 'react';
import { defaultHours } from '../../../../../../../../../../../../../../helpers/utils/dates';
import { useNotificationSchedulesQuery } from '../../hooks/useNotificationSchedulesQuery';
import { useEditSchedule } from './hooks/useEditSchedule';
import { useItems } from './hooks/useItems';
import EditNotificationModal from './modals/EditNotificationModal';
import SchedulesProvider from './providers/SchedulesProvider';

type DayScheduleProps = {
  selectedDate: string;
};

const DaySchedule = ({ selectedDate }: DayScheduleProps) => {
  const caseNotificationSchedules = useNotificationSchedulesQuery(selectedDate);

  const { handleCloseEdit, handleEdit, editMode, schedule, isOpen } =
    useEditSchedule();

  const getItems = useItems(new Date(selectedDate), handleEdit);

  return (
    <SchedulesProvider selectedDate={selectedDate}>
      {(schedules) => (
        <>
          {editMode && (
            <EditNotificationModal
              isOpen={isOpen}
              toggle={handleCloseEdit}
              schedule={schedule!}
              selectedDate={selectedDate}
            />
          )}
          <Schedule
            isLoading={caseNotificationSchedules.isLoading}
            hours={defaultHours}
            items={getItems(schedules)}
            selectedDateString={selectedDate}
          />
        </>
      )}
    </SchedulesProvider>
  );
};

export default DaySchedule;
