import * as d3 from 'd3';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';

type ChartItem = {
  value: number;
  name: string;
};

const defaultColors = ['#ACACAC'];

type CustomPieChartProps = {
  total: number;
  colors?: string[];
  items: ChartItem[];
  width: number;
  height: number;
  marginForCircle: number;
  totalText: string;
  innerRadius: number;
  displayInside?: boolean;
};

const CustomPieChart = ({
  total,
  items,
  colors = defaultColors,
  width,
  height,
  marginForCircle,
  totalText,
  innerRadius,
  displayInside = false,
}: CustomPieChartProps) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  const values = useMemo(() => {
    return items.map((val) => val.value);
  }, [items]);

  const radius = Math.min(width, height) / 2 - marginForCircle;

  const renderChartFunction = useCallback(
    (svg: d3.Selection<SVGSVGElement, any, any, any>) => {
      svg.selectAll('*').remove();
      const chart = svg
        .append('g')
        .attr('transform', `translate(${width / 2},${height / 2})`);

      const pie = d3.pie().value((d) => {
        return d as any;
      });
      const data_ready = pie(values);

      chart
        .selectAll('whatever')
        .data(data_ready)
        .join('path')
        .attr('d', d3.arc().innerRadius(innerRadius).outerRadius(radius) as any)
        .attr('fill', (d, i) => {
          return colors[i];
        });
    },
    [values, radius, colors, height, width, innerRadius]
  );

  useEffect(() => {
    if (svgRef.current) {
      renderChartFunction(d3.select(svgRef.current));
    }
  }, [renderChartFunction]);

  let summaryText: JSX.Element | null;
  if (displayInside) {
    summaryText = (
      <div
        style={{
          position: 'absolute',
          top: 'calc(50% - 25px)',
          left: 0,
          width: '100%',
          textAlign: 'center',
        }}
      >
        <div className="CustomPieChart__total-container__value CustomPieChart__total-container__value--inside">
          {total}
        </div>
        <div className="CustomPieChart__total-container__text">{totalText}</div>
      </div>
    );
  } else {
    summaryText = (
      <div className="CustomPieChart__total-container">
        <div className="CustomPieChart__total-container__text">{totalText}</div>
        <div className="CustomPieChart__total-container__value">{total}</div>
      </div>
    );
  }

  return (
    <div className="d-flex CustomPieChart">
      <svg ref={svgRef} width={width} height={height} />
      {summaryText}
    </div>
  );
};

export default CustomPieChart;
