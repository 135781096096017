import { TimelineTabsProps } from 'interfaces/timeline';
import { Col, Row } from 'reactstrap';
import PatientActivity from './components/PatientActivity';
import SqueezeSummaryPieChart from './components/SqueezeSummaryPieChart';
import { useTimelineDetailsDateRangeObject } from 'store/reducerHooks';

const OverviewSummary = (props: TimelineTabsProps) => {
  const dateRangeObject = useTimelineDetailsDateRangeObject();
  return (
    <Row>
      <Col xl={7} md={12} className="mt-4">
        <SqueezeSummaryPieChart {...props} dateRangeObject={dateRangeObject} />
      </Col>
      <Col xl={5} md={12} className="mt-4">
        <PatientActivity {...props} dateRangeObject={dateRangeObject} />
      </Col>
    </Row>
  );
};

export default OverviewSummary;
