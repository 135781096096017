import {
  returnFormattedHourSelection,
  returnIntervalBaseOnDates,
  returnIntervalDatesArray,
} from 'helpers/dates';
import { useBrowserState } from 'store/reducerHooks';

type XAxisProps = {
  currentScale: number;
  xZoomFunction;
  xScaleFunction;
  chartWidth: number;
  hoursRange: number[];
  position: 'top' | 'bottom';
};

const XAxis = ({
  currentScale,
  xZoomFunction,
  chartWidth,
  xScaleFunction,
  hoursRange,
  position,
}: XAxisProps) => {
  const isBrowser = useBrowserState();

  let interval: number;
  let startTimeStamp: number;
  let endTimeStamp: number;

  if (!isBrowser && currentScale > 1) {
    const { start, end } = returnFormattedHourSelection(
      Math.round(xZoomFunction.invert(0)),
      Math.round(xZoomFunction.invert(chartWidth))
    );

    startTimeStamp = start;
    endTimeStamp = end;
  } else {
    startTimeStamp = hoursRange[0];
    endTimeStamp = hoursRange[1];
  }

  interval = returnIntervalBaseOnDates(startTimeStamp, endTimeStamp);
  const calcInt = interval;
  const displaySecond = calcInt <= 30;

  const valArray = returnIntervalDatesArray(
    startTimeStamp,
    endTimeStamp,
    calcInt
  );

  const renderedArray = valArray.map((val) => {
    let xPosition: number;
    if (!isBrowser && xZoomFunction) {
      xPosition = xZoomFunction(val);
    } else {
      xPosition = xScaleFunction(val);
    }

    const dateVal = new Date(val);

    return (
      <div
        key={`xaxis-${val}`}
        className={`BubbleChart__xaxis__item--${position}`}
        style={{
          left: xPosition + 'px',
        }}
      >
        {dateVal.getHours()}:{dateVal.getMinutes().toString().padStart(2, '0')}
        {displaySecond &&
          `:${dateVal.getSeconds().toString().padStart(2, '0')}`}
      </div>
    );
  });

  return (
    <div
      className="BubbleChart__xaxis"
      style={{
        width: chartWidth + 25 + 'px',
        height: '30px',
        overflowX: 'hidden',
        position: 'relative',
      }}
    >
      {renderedArray}
      <div
        className={`BubbleChart__xaxis__line BubbleChart__xaxis__line--${position}`}
      />
    </div>
  );
};

export default XAxis;
