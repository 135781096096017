import React from 'react';
import { IOrganization } from '../../../../../../interfaces/organizations';
import { useFunctionality } from '../../../../../../hooks/useFunctionality';
import { FunctionalityCode } from '../../../../../../interfaces/functionalities';
import { NavItem } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Dropdown from '../Dropdown';

type GenericProps = {
  organization: IOrganization;
};

const Generic = ({ organization }: GenericProps) => {
  const organizationId = organization.id;

  const { hasFunctionality: caseListViewFunctionality } = useFunctionality(
    FunctionalityCode.CASE_LIST_VIEW
  );

  const { hasFunctionality: caseGroupsListViewFunctionality } =
    useFunctionality(FunctionalityCode.CASE_GROUPS_LIST_VIEW);

  const { hasFunctionality: participantsListViewFunctionality } =
    useFunctionality(FunctionalityCode.PARTICIPANTS_LIST_VIEW);

  const { hasFunctionality: projectSubmissionsListViewFunctionality } =
    useFunctionality(FunctionalityCode.PROJECT_SUBMISSIONS_LIST_VIEW);

  return (
    <>
      {participantsListViewFunctionality && (
        <NavItem>
          <NavLink to={`/organizations/${organizationId}/participants`}>
            <FormattedMessage id="OrganizationNavigation.participants" />
          </NavLink>
        </NavItem>
      )}
      {projectSubmissionsListViewFunctionality && (
        <NavItem>
          <NavLink
            end
            to={`/organizations/${organizationId}/project-submissions`}
          >
            <FormattedMessage id="OrganizationNavigation.projectSubmissions" />
          </NavLink>
        </NavItem>
      )}
      {caseGroupsListViewFunctionality && (
        <NavItem>
          <NavLink to={`/organizations/${organizationId}/case-groups`}>
            <FormattedMessage id="OrganizationNavigation.caseGroups" />
          </NavLink>
        </NavItem>
      )}
      {caseListViewFunctionality && (
        <NavItem>
          <NavLink to={`/organizations/${organizationId}/cases`}>
            <FormattedMessage id="OrganizationNavigation.cases" />
          </NavLink>
        </NavItem>
      )}
      <Dropdown organization={organization} />
    </>
  );
};

export default Generic;
