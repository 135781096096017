import React, { useContext } from 'react';
import AddTooltip from './components/AddTooltip';
import { useHeadingActions } from './hooks/useHeadingActions';
import { TrendVisualizationModalFormikContext } from '../../../../types';
import { FormikProps } from 'formik';
import { TrendVisualizationTemplate } from 'interfaces/trendVisualizations';
import LoadingButton from 'components/LoadingButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTrash } from '@fortawesome/pro-light-svg-icons';

type FormHeadingActionsProps = {
  toggle: () => void;
};

const FormHeadingActions = ({ toggle }: FormHeadingActionsProps) => {
  const { templateDelete } = useHeadingActions(toggle);

  const { isValid, isSubmitting, submitForm, values } = useContext(
    TrendVisualizationModalFormikContext
  ) as FormikProps<TrendVisualizationTemplate>;

  return (
    <div className="d-flex align-items-center gap-2">
      <AddTooltip />
      <LoadingButton
        isLoading={isSubmitting}
        color="primary"
        outline={true}
        onClick={submitForm}
        disabled={!isValid}
      >
        <FontAwesomeIcon icon={faSave} />
      </LoadingButton>
      <LoadingButton
        disabled={isSubmitting || !values.id}
        isLoading={templateDelete.isExecuting}
        color="danger"
        outline={true}
        onClick={templateDelete.callback}
      >
        <FontAwesomeIcon icon={faTrash} />
      </LoadingButton>
    </div>
  );
};

export default FormHeadingActions;
