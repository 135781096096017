import React, { useRef } from 'react';
import classNames from 'classnames';
import { useCookie } from '../../../../../../../../../../hooks/useCookie';
import { ViewTheme } from '../../../../../../../../../../interfaces/ui';

type ProcedureRangeProps = {
  name: string;
  startOffset: number;
  width: number;
  onClick: () => void;
};

const arrowLeftStyles = (size: number, color: string) => ({
  width: 0,
  height: 0,
  borderTop: `${size}px solid transparent`,
  borderBottom: `${size}px solid transparent`,
  borderRight: `${size}px solid ${color}`,
});

const arrowRightStyles = (size: number, color: string) => ({
  width: 0,
  height: 0,
  borderTop: `${size}px solid transparent`,
  borderBottom: `${size}px solid transparent`,
  borderLeft: `${size}px solid ${color}`,
});

const ProcedureRange = ({
  name,
  startOffset,
  width,
  onClick,
}: ProcedureRangeProps) => {
  const themeCookie = useCookie<ViewTheme>('theme');
  const spanRef = useRef<HTMLSpanElement>(null);

  const arrowLeftStyle = arrowLeftStyles(
    5,
    themeCookie === ViewTheme.Dark ? '#fff' : '#000'
  );

  const arrowRightStyle = arrowRightStyles(
    5,
    themeCookie === ViewTheme.Dark ? '#fff' : '#000'
  );

  const currentWidth = width * 100;
  const labelHidden = currentWidth < 3;

  return (
    <div
      className="absolute cursor-pointer"
      onClick={onClick}
      style={{
        left: `${startOffset * 100}%`,
        width: `${currentWidth}%`,
      }}
    >
      <div
        onClick={onClick}
        className="relative w-full h-full flex items-center justify-between"
      >
        <div
          className={classNames({
            'absolute top-1/2 -translate-y-1/2 left-0 w-full h-px': true,
            'bg-black': themeCookie !== ViewTheme.Dark,
            'bg-white': themeCookie === ViewTheme.Dark,
          })}
        ></div>
        <div style={arrowRightStyle} />
        <span
          ref={spanRef}
          className={classNames(
            'text-sm p-1 z-10 min-w-5 overflow-hidden overflow-ellipsis text-nowrap',
            {
              'bg-white': themeCookie !== ViewTheme.Dark,
              'bg-materialCardDark': themeCookie === ViewTheme.Dark,
              'min-w-0 hidden': labelHidden,
            }
          )}
        >
          {name}
        </span>
        <div style={arrowLeftStyle} />
      </div>
    </div>
  );
};

export default ProcedureRange;
