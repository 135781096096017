import { editTimeline } from 'api/graspManagement';
import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import QueryKeys from 'api/queries/queryKeys';
import { IPatchObject } from 'interfaces/request';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useQueryClient } from 'react-query';

function useTimelineEdit(userId: string, timelineId: string) {
  const queryClient = useQueryClient();

  return useMutationWithResultNotification<IPatchObject[], void>({
    asyncApiFunction: async (patchObject) => {
      await editTimeline(userId, timelineId, patchObject);
    },
    onSuccess: async () => {
      displaySuccessNotification('TimelineDetails.successfullyUpdated');
      await queryClient.refetchQueries([
        QueryKeys.getTimelineDetails,
        timelineId,
      ]);
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(errorArray, 'TimelineDetails.errors');
    },
    resetOnResult: true,
  });
}

export default useTimelineEdit;
