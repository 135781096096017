export const addNewItemOptions: string[] = [
  'createPlainText',
  'createQuestionFromScratch',
  'createQuestionBasedOnExisting',
  'useExistingQuestion',
  'graspUpload',
  'supervisorNotification',
  'automatedFeedback',
  'delimiter',
  'educationMaterialsList',
  'educationMaterialsItem',
];
