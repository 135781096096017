import React from 'react';
import useUrlSearch from '../../../../../../../../hooks/useUrlSearch';
import AggregatedSummary from './components/AggregatedSummary';
import BasicSummary from './components/BasicSummary';

const Summary = () => {
  const query = useUrlSearch();
  const aggregated = query.get('aggregated');

  if (aggregated) return <AggregatedSummary />;
  return <BasicSummary />;
};

export default Summary;
