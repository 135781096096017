import { createContext } from 'react';
import { ReactStateTuple } from '../../../../../../../../../../../../../interfaces/ui';
import { ValidationResult } from '../../../../../types';

export enum ValidationStatus {
  Idle = 'Idle',
  Success = 'Success',
  Error = 'Error',
}

export const ValidationContext = createContext<
  ReactStateTuple<ValidationResult> | undefined
>(undefined);
