import { getOrganizationCasesRequests } from 'api/cases';
import {
  convertIntoObjectWithPageInfo,
  returnAllDataFromResponse,
} from 'helpers/utils/reactQueryHelpers';
import { StatusEnum } from 'interfaces/enums';
import { ICaseAccessRequest } from 'interfaces/personalities';
import { IError, IInfiniteResult } from 'interfaces/response';
import { useInfiniteQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import {
  OrganizationQueryParams,
  SearchAndPagingQueryParams,
} from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

interface UseGetInfiniteCaseMemberRequestsParams
  extends OrganizationQueryParams,
    SearchAndPagingQueryParams {
  status: StatusEnum | null;
}

function useGetInfiniteCaseMemberRequests({
  params,
  options,
}: IQueryRequest<UseGetInfiniteCaseMemberRequestsParams>) {
  const { organizationId, searchValue, pageSize, status } = params;
  const response = useInfiniteQuery<
    IInfiniteResult<ICaseAccessRequest[]>,
    IError
  >({
    ...options,
    queryKey: [
      QueryKeys.getOrganizationCasesRequests,
      organizationId,
      searchValue,
      pageSize,
      status,
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getOrganizationCasesRequests(
        organizationId,
        {
          search: searchValue,
          page_size: pageSize,
          page: pageParam,
        },
        status
      );

      return convertIntoObjectWithPageInfo<ICaseAccessRequest[]>(data);
    },
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage;
    },
    getPreviousPageParam: (firstPage) => firstPage.previousPage,
  });

  return {
    ...response,
    caseRequests: returnAllDataFromResponse<ICaseAccessRequest>(response),
  };
}

export default useGetInfiniteCaseMemberRequests;
