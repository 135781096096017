import { getOrganizationReportsList } from 'api/reports';
import { getISOStringUTCNonAware } from 'helpers/dates';
import { OrganizationQueryParams } from 'interfaces/queryParams';
import {
  ReportExecution,
  ReportExecutionQueryParams,
  ReportTemplateCode,
  ReportType,
} from 'interfaces/reports';
import { IQueryRequest } from 'interfaces/request';
import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';

export type UseOrganizationReportsListParams = OrganizationQueryParams & {
  executionStatus?: ReportExecution;
  reportType?: ReportType;
  requestedAtFrom?: Date | null;
  requestedAtTo?: Date | null;
};

const useOrganizationReportsList = ({
  params,
  options,
}: IQueryRequest<UseOrganizationReportsListParams>) => {
  return useQuery({
    ...options,
    queryKey: [
      QueryKeys.getOrganizationReportsList,
      params.organizationId,
      params.executionStatus,
      params.reportType,
      params.requestedAtFrom,
      params.requestedAtTo,
    ],
    queryFn: async () => {
      const templateCodeKey = Object.entries(ReportType).find(
        ([_, value]) => value === params.reportType
      )?.[0];

      let templateCode = null;

      if (templateCodeKey) templateCode = ReportTemplateCode[templateCodeKey];

      const organizationReportsQueryParams: Partial<ReportExecutionQueryParams> =
        {
          executionStatus: params.executionStatus ?? null,
          templateCode: templateCode ?? null,
        };

      if (params.requestedAtFrom) {
        organizationReportsQueryParams.requestedAtFrom =
          getISOStringUTCNonAware(params.requestedAtFrom);
      }

      if (params.requestedAtTo) {
        organizationReportsQueryParams.requestedAtTo = getISOStringUTCNonAware(
          params.requestedAtTo
        );
      }

      const { data } = await getOrganizationReportsList(
        params.organizationId,
        organizationReportsQueryParams
      );

      return data.data;
    },
  });
};

export default useOrganizationReportsList;
