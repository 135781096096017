import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { InputType } from 'reactstrap/types/lib/Input';
import EditableInput from 'components/EditableInput/EditableInput';
import { IOrganizationObservationMeaning } from 'interfaces/observationMeanings';
import { InputOption } from 'interfaces/ui';

type ObservationMeaningFieldWrapperProps = {
  modifiedEntry: IOrganizationObservationMeaning | null;
  meaningId: string;
  onEdit: (target: string, value: string) => void;
  inputType: InputType;
  value: string | number | readonly string[] | undefined;
  options?: InputOption[];
  labelTranslationId?: string;
  name: string;
};

const ObservationMeaningFieldWrapper = ({
  modifiedEntry,
  meaningId,
  inputType,
  options = undefined,
  labelTranslationId = undefined,
  value,
  name,
  onEdit,
}: ObservationMeaningFieldWrapperProps) => {
  const label = useMemo(() => {
    if (inputType === 'color') {
      return (
        <div className="d-flex align-items-center gap-2">
          <div
            style={{
              width: 30,
              height: 30,
              borderRadius: '100%',
              background: value as string,
            }}
          />
          <span>{value}</span>
        </div>
      );
    }

    if (labelTranslationId) return <FormattedMessage id={labelTranslationId} />;
    return value;
  }, [inputType, labelTranslationId, value]);

  return (
    <>
      {modifiedEntry && modifiedEntry.id === meaningId ? (
        <EditableInput
          type={inputType}
          value={value}
          options={options}
          name={name}
          onEdit={onEdit}
          debounceEnabled={false}
        />
      ) : (
        label
      )}
    </>
  );
};

export default ObservationMeaningFieldWrapper;
