import { GridEventListener } from '@mui/x-data-grid';
import type { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import { DataGridProps } from '@mui/x-data-grid/models/props/DataGridProps';
import React from 'react';
import { CustomTableResponsiveConfig } from '../CustomTable';
import Placeholders from '../Placeholders';
import StripedDataGrid from './components/StripedDataGrid';
import { useFilterColumns } from './hooks/useFilterColumns';

type CustomMaterialTableProps = DataGridProps & {
  onRowClick?: GridEventListener<'rowClick'>;
  rows: any[];
  columns: GridColDef[];
  responsiveConfig?: CustomTableResponsiveConfig[];
};

const CustomDataGrid = ({
  onRowClick,
  columns,
  rows,
  responsiveConfig,
  ...rest
}: CustomMaterialTableProps) => {
  const filteredColumns = useFilterColumns(columns, responsiveConfig);

  return (
    <StripedDataGrid
      autoHeight
      slots={{
        noRowsOverlay: Placeholders.EmptyTable,
      }}
      sx={{ '--DataGrid-overlayHeight': '300px' }}
      onRowClick={onRowClick}
      rows={rows}
      columns={filteredColumns}
      disableColumnFilter={true}
      hideFooter={true}
      rowHeight={36}
      columnHeaderHeight={36}
      getRowClassName={(params) =>
        params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'
      }
      {...rest}
    />
  );
};

export default CustomDataGrid;
