import { faArrowLeft } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useOpen from 'hooks/useOpen';
import { GraspEventItemData } from 'interfaces/timeline';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import GraspObservationItem from './GraspObservationItem';
import ManageObservationGroupModal from './ManageObservationGroupModal';

type GraspEventItemChildrenProps = {
  children_observations: GraspEventItemData[];
  startDateText: string;
  endDateText: string;
  timelineId: string;
  selectedDates: {
    from: Date;
    to: Date;
  };
};

const OBSERVATIONS_EXPANDED = 3;

const GraspEventItemChildren = ({
  children_observations,
  startDateText,
  endDateText,
  timelineId,
  selectedDates,
}: GraspEventItemChildrenProps) => {
  const { isOpen, toggle } = useOpen(false);

  const sortChildrenObservationsByDate = (
    a: GraspEventItemData,
    b: GraspEventItemData
  ) => a.beginDate.getTime() - b.beginDate.getTime();

  const renderChildrenObservations = useMemo(() => {
    return children_observations
      .sort(sortChildrenObservationsByDate)
      .slice(0, OBSERVATIONS_EXPANDED)
      .map((child) => <GraspObservationItem observation={child} />);
  }, [children_observations]);

  return (
    <div>
      <div className="GraspEventItemChildren mb-5">
        <div className="GraspEventItemChildren__list">
          <div onClick={toggle} className="GraspEventItem">
            <div className="GraspEventItem__icon">
              <div
                className={`GraspEventItem__icon__container GraspEventItem__icon__container--case-history`}
              >
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="GraspEventItem__icon__container__back-arrow"
                />
              </div>
            </div>
            <div className="GraspEventItem__description">
              <div
                className={`GraspEventItem__description__title GraspEventItem__description__title--case-history`}
              >
                Categorize observations
              </div>
              <span className="GraspEventItem__description__event-properties">
                Assign specified meaning and provide description
              </span>
            </div>
          </div>
          {renderChildrenObservations}
        </div>
        {children_observations.length > OBSERVATIONS_EXPANDED && (
          <div className="GraspEventItemChildren__show-more" onClick={toggle}>
            <FormattedMessage id="TimelineDetails.dayDetails.showMore" />
          </div>
        )}
      </div>
      <ManageObservationGroupModal
        startDateText={startDateText}
        endDateText={endDateText}
        selectedDates={selectedDates}
        observations={children_observations}
        isOpen={isOpen}
        toggleModal={toggle}
        timelineId={timelineId}
      />
    </div>
  );
};

export default GraspEventItemChildren;
