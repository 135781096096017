import TransferIcon from 'assets/images/transfer_icon.svg';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { Button } from 'reactstrap';
import { RootState } from 'store';
import GraspShare from 'assets/images/patient_check/grasp_share.svg';

type TransferGraspDataProps = {
  nextClick: () => void;
  cancelClick: () => void;
};

const TransferGraspData = ({
  cancelClick,
  nextClick,
}: TransferGraspDataProps) => {
  const isBrowser = useSelector((state: RootState) => state.ui.isBrowser);

  const onButtonShareClick = () => {
    window.dispatchEvent(new CustomEvent('grasp-connect-event'));
  };

  const renderTextContent = () => {
    if (isBrowser) {
      return (
        <p className="mt-4">
          <FormattedMessage id="PatientCheck.useYourMobileDevice" />
        </p>
      );
    } else {
      return (
        <div className="mt-4">
          <p>
            <FormattedMessage id="PatientCheck.patientDeviceNearby" />
          </p>
          <Button onClick={onButtonShareClick} color="primary" outline>
            <img src={GraspShare} alt="grasp_connect" className="me-3" />
            <FormattedMessage id="PatientCheck.connectGrasp" />
          </Button>
        </div>
      );
    }
  };

  return (
    <>
      <div className="text-center StatusCheck__transfer-text">
        <img src={TransferIcon} alt="transfer-icon" />
        {renderTextContent()}
      </div>
      <div className="StatusCheck__button-container">
        <Button onClick={cancelClick} color="primary" outline>
          <FormattedMessage id="General.cancel" />
        </Button>
        <Button onClick={nextClick} color="primary">
          <FormattedMessage id="General.next" />
        </Button>
      </div>
    </>
  );
};

export default TransferGraspData;
