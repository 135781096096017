import { useEffect } from 'react';
import { useNavigate } from 'react-router';

type NavigateToExerciseSessionProps = {
  userId: string;
  caseId: string;
  id: string;
  duration: number;
};

const NavigateToExerciseSession = ({
  id,
  caseId,
  duration,
  userId,
}: NavigateToExerciseSessionProps) => {
  const navigate = useNavigate();

  const path = `/users/${userId}/cases/${caseId}/exercise?sessionId=${id}&initCount=${duration}`;

  useEffect(() => {
    navigate(path);
  }, [navigate, path]);

  return null;
};

export default NavigateToExerciseSession;
