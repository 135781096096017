import { registerSubmissionUser } from 'api/submissions';
import { IRegisterForm } from 'interfaces/membership';
import { displayErrorArrayNotifications } from 'modules/Notifications';
import { getCulture } from 'translations/utils/cultures';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

function useRegisterSubmissionUser(
  projectCode: string,
  organizationCode: string,
  submissionId: string,
  successFb?: () => void
) {
  return useMutationWithResultNotification({
    asyncApiFunction: async (userData: Partial<IRegisterForm>) => {
      const culture = getCulture();

      const personalityData = {
        first_name: userData.first_name || undefined,
        last_name: userData.last_name || undefined,
        timezone_id: userData.timezone_id,
        email: userData.email || undefined,
        culture: userData.culture?.length === 0 ? culture : userData.culture,
      };

      const registerBody = {
        ...personalityData,
        user_name: userData.email ?? undefined,
        password: userData.password ?? undefined,
        personality: {
          ...personalityData,
          short_name: userData.email ?? undefined,
          birthdate: userData.birthdate
            ? new Date(userData.birthdate).toISOString()
            : undefined,
          gender: userData.gender?.length ? userData.gender : undefined,
          address: userData.address?.length ? userData.address : undefined,
          ssn: userData?.ssn?.length ? userData.ssn : undefined,
          phone_number: userData.phone_number,
          culture: userData.culture?.length === 0 ? culture : userData.culture,
        },
      };

      if (userData.password) registerBody.password = userData.password;

      return registerSubmissionUser(
        projectCode,
        organizationCode,
        submissionId,
        registerBody
      );
    },
    onSuccess: () => {
      successFb && successFb();
    },
    onErrorArray: (arr) => {
      displayErrorArrayNotifications(arr, 'ProjectSubmissions.RegisterError');
    },
    resetOnResult: true,
  });
}

export default useRegisterSubmissionUser;
