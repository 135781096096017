import { IMutationRequest, IPatchObject } from '../../../interfaces/request';
import { OrganizationQueryParams } from '../../../interfaces/queryParams';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { editOrganizationDevice } from '../../organization';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';

type PatchOrganizationDeviceBody = {
  id: string;
  patch: IPatchObject[];
};

const usePatchOrganizationDevice = ({
  params,
  options,
  silent = false,
}: IMutationRequest<OrganizationQueryParams>) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (body: PatchOrganizationDeviceBody) => {
      await editOrganizationDevice(params.organizationId, body.id, body.patch);
    },
    onSuccess: () => {
      if (!silent)
        displaySuccessNotification(
          'Api.Mutations.Organizations.PatchOrganizationDevice.success'
        );

      options.successFb?.();
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'Api.Mutations.Organizations.PatchOrganizationDevice.failure'
      );
      options.errorFb?.();
    },
    resetOnResult: true,
  });
};

export default usePatchOrganizationDevice;
