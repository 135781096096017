import { useCallback, useState } from 'react';

export enum UsersAndAccessTabsValues {
  OrganizationUsers = 'OrganizationUsers',
  AccessProfiles = 'AccessProfiles',
  AccessRequests = 'AccessRequests',
}

export const mappedUsersAndAccessTabsValues = [
  UsersAndAccessTabsValues.OrganizationUsers,
  UsersAndAccessTabsValues.AccessProfiles,
  UsersAndAccessTabsValues.AccessRequests,
];

export const useUserAndAccessTabs = () => {
  const [selectedTab, setSelectedTab] = useState<UsersAndAccessTabsValues>(
    UsersAndAccessTabsValues.OrganizationUsers
  );

  const shouldBeRendered = useCallback(
    (tab: UsersAndAccessTabsValues) => {
      return selectedTab === tab;
    },
    [selectedTab]
  );

  const onTabChange = (val: number) => {
    setSelectedTab(mappedUsersAndAccessTabsValues[val]);
  };

  return {
    shouldBeRendered,
    onTabChange,
  };
};
