//helpers
import useOneTimeToken from 'api/queries/membership/useOneTimeToken';

//images
import keyImage from 'assets/images/key_icon.svg';
import InputFieldWithImage, {
  InputFieldWithImageColor,
} from 'components/InputFieldWithImage';

//components
import LoadingButton from 'components/LoadingButton';

//helpers
import { formatDate } from 'helpers/dates';
import React, { useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Input } from 'reactstrap';
import TabLayout from './TabLayout';

type OneTimeTokenTabProps = {
  userId: string;
};

const OneTimeTokenTab = ({ userId }: OneTimeTokenTabProps) => {
  const intl = useIntl();
  const { data, isLoading, refetch } = useOneTimeToken({ params: { userId } });

  const tokenInputRef = useRef<null | HTMLInputElement>(null);

  const onClick = async () => {
    await refetch();
  };

  const description = data?.valid_to
    ? `${intl.formatMessage({
        id: 'MyProfile.OneTimeToken.tokenValidUntil',
      })}: ${formatDate(data?.valid_to as string)}`
    : '';

  const onClipboardCopy = async () => {
    if (typeof navigator !== 'undefined') {
      const textValue = tokenInputRef.current?.value ?? '';

      await navigator.clipboard.writeText(textValue);
    }
  };

  return (
    <TabLayout
      imageSrc={keyImage}
      title="MyProfile.Tabs.generateOneTimeToken"
      description={description}
    >
      <div>
        <InputFieldWithImage
          color={InputFieldWithImageColor.gray}
          image={<img src={keyImage} alt="token_icon" />}
          labelTranslationId="MyProfile.OneTimeToken.yourToken"
          inputId="your_token"
        >
          <Input
            value={data?.token}
            type="text"
            innerRef={tokenInputRef}
            id="your_token"
          />
        </InputFieldWithImage>
        <div className="d-flex justify-content-end">
          <Button
            outline
            color="primary"
            onClick={onClipboardCopy}
            className="me-2"
          >
            <FormattedMessage id="MyProfile.OneTimeToken.copyToken" />
          </Button>
          <LoadingButton isLoading={isLoading} onClick={onClick}>
            <FormattedMessage id="MyProfile.OneTimeToken.generateNewToken" />
          </LoadingButton>
        </div>
      </div>
    </TabLayout>
  );
};

export default OneTimeTokenTab;
