import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import {
  GridActionsCellItem,
  GridColDef,
  GridRowId,
  GridRowModes,
  GridRowModesModel,
} from '@mui/x-data-grid';
import React, { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import useDeleteDashboardReportSchedule from '../../../../../../../../../../../api/mutations/dashboards/useDeleteDashboardReportSchedule';
import useDashboardReportSchedules from '../../../../../../../../../../../api/queries/dashboards/useDashboardReportSchedules';
import { useOrganizationPathParams } from '../../../../../../../../../../../hooks/paths/useOrganizationPathParams';
import { OrganizationAccessProfile } from '../../../../../../../../../../../interfaces/accessProfiles';
import {
  DashboardReportPeriod,
  DashboardTemplate,
} from '../../../../../../../../../../../interfaces/dashboards';
import { IPersonality } from '../../../../../../../../../../../interfaces/personalities';
import DashboardCell, { DashboardEditCell } from '../components/DashboardCell';
import DateFromCell, { DateFromEditCell } from '../components/DateFromCell';
import DestinationCell, {
  DestinationEditCell,
} from '../components/DestinationCell';
import DestinationTypeCell, {
  DestinationTypeEditCell,
} from '../components/DestinationTypeCell';
import { ReportScheduleTableTypes } from '../types';

export const useColumns = (
  rows: any[],
  setRows: Dispatch<SetStateAction<any[]>>,
  rowModesModel: GridRowModesModel,
  setRowModesModel: Dispatch<SetStateAction<GridRowModesModel>>,
  templates: DashboardTemplate[],
  accessProfiles: OrganizationAccessProfile[],
  members: IPersonality[]
) => {
  const { organizationId } = useOrganizationPathParams();
  const intl = useIntl();

  const dashboardReportSchedules = useDashboardReportSchedules({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const handleRefetch = async () => {
    await dashboardReportSchedules.refetch();
  };

  const { mutateAsync: deleteAsync } = useDeleteDashboardReportSchedule({
    params: { organizationId },
    options: { successFb: handleRefetch, errorFb: handleRefetch },
  });

  const handleEditClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    },
    [rowModesModel, setRowModesModel]
  );

  const handleSaveClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    },
    [rowModesModel, setRowModesModel]
  );

  const handleDeleteClick = useCallback(
    (id: GridRowId) => async () => {
      setRows(rows.filter((row) => row.id !== id));
      await deleteAsync(id as string);
    },
    [deleteAsync, rows, setRows]
  );

  const handleCancelClick = useCallback(
    (id: GridRowId) => () => {
      setRowModesModel({
        ...rowModesModel,
        [id]: { mode: GridRowModes.View, ignoreModifications: true },
      });

      const editedRow = rows.find((row) => row.id === id);

      if (editedRow?.isNew) setRows(rows.filter((row) => row.id !== id));
    },
    [rowModesModel, rows, setRowModesModel, setRows]
  );

  return useMemo(
    (): GridColDef<ReportScheduleTableTypes.ReportScheduleTableEntry>[] => [
      {
        field: 'dashboard_id',
        flex: 1,
        headerName: intl.formatMessage({ id: 'Dashboards.DashboardTemplate' }),
        editable: true,
        renderCell: (params) => (
          <DashboardCell {...params} templates={templates} />
        ),
        renderEditCell: (params) => (
          <DashboardEditCell {...params} templates={templates} />
        ),
      },
      {
        field: 'date_from',
        flex: 1,
        headerName: intl.formatMessage({ id: 'General.DateFrom' }),
        editable: true,
        renderCell: (params) => <DateFromCell {...params} />,
        renderEditCell: (params) => <DateFromEditCell {...params} />,
      },
      {
        field: 'date_last_generated',
        flex: 1,
        headerName: intl.formatMessage({ id: 'Dashboards.DateLastGenerated' }),
        editable: false,
        renderCell: (params) => <DateFromCell {...params} />,
      },
      {
        field: 'period',
        flex: 1,
        headerName: intl.formatMessage({ id: 'General.Period' }),
        editable: true,
        type: 'singleSelect',
        valueOptions: Object.values(DashboardReportPeriod),
      },
      {
        field: 'one_time_access',
        flex: 1,
        headerName: intl.formatMessage({ id: 'Dashboards.OneTimeAccess' }),
        editable: true,
        type: 'boolean',
      },
      {
        field: 'destination_type',
        flex: 1,
        headerName: intl.formatMessage({ id: 'Dashboards.TargetType' }),
        editable: true,
        renderCell: (params) => <DestinationTypeCell {...params} />,
        renderEditCell: (params) => <DestinationTypeEditCell {...params} />,
      },
      {
        field: 'destination_id',
        flex: 1,
        headerName: intl.formatMessage({ id: 'Dashboards.Target' }),
        editable: true,
        renderCell: (params) => (
          <DestinationCell
            {...params}
            accessProfiles={accessProfiles}
            members={members}
          />
        ),
        renderEditCell: (params) => (
          <DestinationEditCell
            {...params}
            accessProfiles={accessProfiles}
            members={members}
          />
        ),
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: intl.formatMessage({ id: 'General.Actions' }),
        cellClassName: 'actions',
        getActions: ({ id }) => {
          const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

          if (isInEditMode) {
            return [
              <GridActionsCellItem
                key={`save-${id}`}
                icon={<SaveIcon />}
                label="Save"
                sx={{
                  color: 'primary.main',
                }}
                onClick={handleSaveClick(id)}
              />,
              <GridActionsCellItem
                key={`cancel-${id}`}
                icon={<CancelIcon />}
                label="Cancel"
                className="textPrimary"
                onClick={handleCancelClick(id)}
                sx={{
                  color: 'primary.main',
                }}
              />,
            ];
          }

          return [
            <GridActionsCellItem
              key={`edit-${id}`}
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              sx={{
                color: 'primary.main',
              }}
            />,
            <GridActionsCellItem
              key={`delete-${id}`}
              icon={<DeleteIcon />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              sx={{
                color: 'primary.main',
              }}
            />,
          ];
        },
      },
    ],
    [
      accessProfiles,
      handleCancelClick,
      handleDeleteClick,
      handleEditClick,
      handleSaveClick,
      intl,
      members,
      rowModesModel,
      templates,
    ]
  );
};
