import { MaterialModalSteps } from '../../../types';
import { useCallback, useMemo } from 'react';

export const useStepTranslations = (
  step: MaterialModalSteps,
  steps: MaterialModalSteps[]
) => {
  const generateSteps = useCallback(() => {
    return steps.map((step, index) => ({
      step: `step${index + 1}`,
      stepName: step,
    }));
  }, [steps]);

  return useMemo(() => {
    const stepNames = generateSteps();
    const currentStepIndex = stepNames.findIndex((p) => p.stepName === step);

    if (currentStepIndex === -1)
      return { sequence: 'step1', stepName: steps[0] };

    return {
      sequence: stepNames[currentStepIndex].step,
      stepName: stepNames[currentStepIndex].stepName,
    };
  }, [generateSteps, step, steps]);
};
