import { getOrganizationPatientsWithPaging } from 'api/patients';
import QueryKeys from 'api/queries/queryKeys';
import {
  convertIntoObjectWithPageInfo,
  returnAllDataFromResponse,
} from 'helpers/utils/reactQueryHelpers';
import { IPersonality } from 'interfaces/personalities';
import {
  OrganizationQueryParams,
  SearchAndPagingQueryParams,
} from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { IError, IInfiniteResult } from 'interfaces/response';
import { useInfiniteQuery } from 'react-query';

type UseInfiniteOrganizationPatientListParams = OrganizationQueryParams &
  SearchAndPagingQueryParams;

function useInfiniteOrganizationPatientList({
  params,
  options,
}: IQueryRequest<UseInfiniteOrganizationPatientListParams>) {
  const { organizationId, searchValue, pageSize = 20 } = params;
  const response = useInfiniteQuery<IInfiniteResult<IPersonality[]>, IError>({
    ...options,
    queryKey: [QueryKeys.getPatientList, organizationId, searchValue],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getOrganizationPatientsWithPaging(organizationId, {
        page: pageParam,
        page_size: pageSize,
        search: searchValue,
      });

      return convertIntoObjectWithPageInfo<IPersonality[]>(data);
    },
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage;
    },
    getPreviousPageParam: (firstPage) => firstPage.previousPage,
  });

  return {
    ...response,
    patients: returnAllDataFromResponse<IPersonality>(response),
  };
}

export default useInfiniteOrganizationPatientList;
