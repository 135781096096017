import React from 'react';
import InputFieldWithoutImage from '../../../../../../components/InputFieldWithoutImage';
import FormikErrorMessage from '../../../../../../components/FormikErrorMessage';
import { Field, Formik } from 'formik';
import FieldInput from '../../../../../../components/FieldInput';
import { useTranslations } from '../../../../../../hooks/useTranslations';
import { useForm } from './hooks/useForm';
import {
  IAsset,
  IEducationMaterial,
} from '../../../../../../interfaces/educationMaterials';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faUndo } from '@fortawesome/pro-light-svg-icons';
import { Button } from 'reactstrap';
import LoadingButton from '../../../../../../components/LoadingButton';
import AssetCulturesSelector from '../../../components/AssetCulturesSelector';
import MaterialTranslationsForm from '../../../components/MaterialTranslationsForm';

type AssetFormProps = {
  material: IEducationMaterial;
  asset: IAsset;
};

const AssetForm = ({ material, asset }: AssetFormProps) => {
  const { wrapWithIntl, attachPrefix } = useTranslations(
    'Education.AssetModal.AssetForm'
  );

  const { initialValues, handleSubmit, validation } = useForm(asset);

  return (
    <Formik
      initialValues={initialValues}
      validate={validation}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {({
        values,
        setValues,
        submitForm,
        resetForm,
        isValid,
        dirty,
        isSubmitting,
      }) => {
        const handleReset = () => resetForm();

        return (
          <div className="AssetForm">
            <InputFieldWithoutImage
              gray={true}
              noMargin={true}
              labelTranslationId={attachPrefix('name')}
              inputId="name"
              errorInfo={<FormikErrorMessage name="name" />}
            >
              <Field
                id="name"
                name="name"
                placeholder={wrapWithIntl(attachPrefix('name'))}
                type="text"
                component={FieldInput}
              />
            </InputFieldWithoutImage>
            <InputFieldWithoutImage
              gray={true}
              noMargin={true}
              labelTranslationId={attachPrefix('description')}
              inputId="description"
              errorInfo={<FormikErrorMessage name="description" />}
            >
              <Field
                id="description"
                name="description"
                placeholder={wrapWithIntl(attachPrefix('description'))}
                type="text"
                component={FieldInput}
              />
            </InputFieldWithoutImage>
            <MaterialTranslationsForm values={values} setValues={setValues} />
            <InputFieldWithoutImage
              gray={true}
              noMargin={true}
              unstyled={true}
              labelTranslationId={attachPrefix('availableIn')}
              inputId="availableIn"
              errorInfo={<FormikErrorMessage name="availableIn" />}
            >
              <div className="AssetForm__available-in">
                <AssetCulturesSelector asset={asset} material={material} />
              </div>
            </InputFieldWithoutImage>
            <div className="AssetForm__actions">
              <LoadingButton
                isLoading={isSubmitting}
                disabled={!isValid}
                color="primary"
                onClick={submitForm}
                outline={true}
              >
                <FontAwesomeIcon icon={faSave} />
              </LoadingButton>
              <Button
                disabled={!dirty}
                color="primary"
                outline={true}
                onClick={handleReset}
              >
                <FontAwesomeIcon icon={faUndo} />
              </Button>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default AssetForm;
