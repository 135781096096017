const initialState = {
  isLoading: false,
  isSuccess: false,
  isFailure: false,
  error: [],
};

export const withLoadingStateActions = (name) => ({
  pendingAction: () => ({ type: `${name}_PENDING` }),
  fullfiledAction: () => ({ type: `${name}_FULLFILLED` }),
  rejectedAction: () => ({ type: `${name}_REJECTED` }),
  resetAction: () => ({ type: `RESET_${name}_STORE` }),
});

export const withLoadingReducer =
  (name, reducerState = initialState) =>
  (state = reducerState, action) => {
    switch (action.type) {
      case `${name}_PENDING`:
        return {
          ...state,
          isLoading: true,
          isSuccess: false,
          isFailure: false,
        };
      case `${name}_FULLFILLED`:
        return {
          ...state,
          isLoading: false,
          isSuccess: true,
          isFailure: false,
        };

      case `${name}_REJECTED`:
        return {
          ...state,
          isLoading: false,
          isFailure: true,
          error: action.errorMessage,
        };

      case `RESET_${name}_STORE`:
        return {
          ...initialState,
        };

      default:
        return state;
    }
  };
