import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { ICaseHistoryBody } from '../../../interfaces/caseHistory';
import { addMultipleCaseHistory } from '../../cases';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { IMutationProps } from '../../../interfaces/ui';

type useAddMultipleCaseHistoryParams = {
  organizationId: string;
  caseIds: string[];
};

const useAddMultipleCaseHistory = (
  params: useAddMultipleCaseHistoryParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (data: ICaseHistoryBody) => {
      await addMultipleCaseHistory(params.organizationId, params.caseIds, data);
    },
    onSuccess: () => {
      if (options.successFb) options.successFb();
      displaySuccessNotification('CaseCard.History.newEntryAdded');
    },
    onError: () => {
      if (options.errorFb) options.errorFb();
      displayErrorNotification('CaseCard.History.newEntryAddFailed');
    },
    resetOnResult: true,
  });
};

export default useAddMultipleCaseHistory;
