import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import useOrganizationUserAccessProfiles from '../../../../../../../../api/queries/accessProfiles/useOrganizationUserAccessProfiles';
import IconButton from '../../../../../../../../components/IconButton';
import TabLayout, {
  TabLayoutType,
} from '../../../../../../../../components/layouts/TabLayout';
import QueryLoaded from '../../../../../../../../components/QueryLoaded';
import { useOrganizationPathParams } from '../../../../../../../../hooks/paths/useOrganizationPathParams';
import { useInitQuery } from '../../../../../../../../hooks/queries/useInitQuery';
import useOpen from '../../../../../../../../hooks/useOpen';
import AddAccessProfileModal from '../../../../../../../UsersAndAccess/components/AccessProfilesTab/components/AddAccessProfileModal';
import AccessProfilesTable from './tables/AccessProfilesTable';

const AccessProfiles = () => {
  const { organizationId } = useOrganizationPathParams();
  const addAccessProfileModal = useOpen();

  const accessProfiles = useOrganizationUserAccessProfiles({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(accessProfiles);

  return (
    <>
      <AddAccessProfileModal
        toggle={addAccessProfileModal.toggle}
        isOpen={addAccessProfileModal.isOpen}
        refetch={accessProfiles.refetch}
        organizationId={organizationId}
      />
      <TabLayout
        type={TabLayoutType.Breadcrumb}
        titlePrefix="Organizations.AccessProfiles"
        actions={
          <IconButton
            color="primary"
            variant="outlined"
            onClick={addAccessProfileModal.toggle}
          >
            <AddIcon />
          </IconButton>
        }
      >
        <QueryLoaded query={accessProfiles}>
          {(data) => <AccessProfilesTable accessProfiles={data} />}
        </QueryLoaded>
      </TabLayout>
    </>
  );
};

export default AccessProfiles;
