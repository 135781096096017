import React from 'react';
import { Spinner } from 'reactstrap';

const LoadingOverlay = () => {
  return (
    <div className="LoadingOverlay">
      <Spinner color="light" size="xl" />
    </div>
  );
};

export default LoadingOverlay;
