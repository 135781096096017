import React from 'react';
import { FormikValuesProps } from '../../../../../../../../interfaces/forms';
import { MaterialModalFormValues } from '../../../../types';
import { Button } from 'reactstrap';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { useHandleAddRule } from './hooks/useHandleAddRule';
import AddRulesStepItem from './components/AddRulesStepItem';
import { useTranslations } from '../../../../../../../../hooks/useTranslations';

type AddRulesStepProps = FormikValuesProps<MaterialModalFormValues>;

const AddRulesStep = ({ values, setValues }: AddRulesStepProps) => {
  const handleAddRule = useHandleAddRule(values, setValues);

  const { attachPrefix } = useTranslations(
    'Education.MaterialModal.MaterialModalBody.AddRulesStep'
  );

  return (
    <div className="d-flex flex-column">
      {values.rules.length > 0 && (
        <div className="w-100">
          {values.rules.map((rule, index) => (
            <AddRulesStepItem
              key={rule.case_sub_category_id}
              index={index}
              values={values}
              setValues={setValues}
            />
          ))}
        </div>
      )}
      <Button
        color="primary"
        className={classNames({
          'align-self-center': true,
          'mt-3': values.rules.length !== 0,
          'my-5': values.rules.length === 0,
        })}
        onClick={handleAddRule}
      >
        <FormattedMessage id={attachPrefix('addRule')} />
      </Button>
    </div>
  );
};

export default AddRulesStep;
