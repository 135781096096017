import React from 'react';
import { useTranslations } from 'hooks/useTranslations';
import { Spinner } from 'reactstrap';

const RelatedDataLoading = () => {
  const { attachPrefix, wrapWithIntl } = useTranslations(
    'Organizations.OrganizationDetails.TrendVisualizations.TrendVisualizationModal.FormCharts.RelatedDataLoading'
  );

  return (
    <div className="card shadow-sm d-flex align-items-center justify-content-center gap-1 h-px-350 text-muted">
      <div className="opacity-25">
        <Spinner />
      </div>
      <span className="fs-5 fw-300 text-muted opacity-50">
        {wrapWithIntl(attachPrefix('dataLoading'))}
      </span>
    </div>
  );
};

export default RelatedDataLoading;
