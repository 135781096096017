import { ICaseDetails } from '../../../../../../../../../interfaces/cases';
import { createContext } from 'react';
import { IObservation } from '../../../../../../../../../interfaces/timeline';
import { IAwareSessionData } from '../../../../../../../../../interfaces/awareSessions';

export type AwareCaseContentDataType = {
  details: ICaseDetails;
  awareSession: IAwareSessionData;
};

export type AwareCaseObservationsProps = {
  observations: IObservation[];
};

export const AwareCaseContentDataContext = createContext<
  AwareCaseContentDataType | undefined
>(undefined);
