import plusIcon from 'assets/images/plus.svg';
import { WeeklyScheduleInfo } from 'interfaces/assessmentForms';
import React, { useRef, useState } from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import { SelectionDetailsProps } from '../index';

export enum FULL_WEEKDAYS {
  MON = 'Mondays',
  TUE = 'Tuesdays',
  WED = 'Wednesdays',
  THU = 'Thursdays',
  FRI = 'Fridays',
  SAT = 'Saturdays',
  SUN = 'Sundays',
}

export enum WEEKDAYS {
  MON = 'Mon',
  TUE = 'Tue',
  WED = 'Wed',
  THU = 'Thu',
  FRI = 'Fri',
  SAT = 'Sat',
  SUN = 'Sun',
}

const WeeklySelectionDetails = ({ info, setInfo }: SelectionDetailsProps) => {
  const timeRef = useRef<HTMLInputElement>(null);

  const [addHourModeForDay, setAddHourModeForDay] = useState<string | null>(
    null
  );

  const handleSpecificDaySelection = (day: string) => {
    const existingEntry = info.weekly!.find(
      (p) => p.day.substring(0, 3).toUpperCase() === day
    );

    if (existingEntry) {
      setInfo({
        ...info,
        weekly: [
          ...info.weekly!.filter(
            (p) => p.day.substring(0, 3).toUpperCase() !== day
          ),
        ].sort(sortByWeekdays),
      });
    } else {
      setInfo({
        ...info,
        weekly: [
          ...info.weekly!,
          {
            day: FULL_WEEKDAYS[day].slice(0, -1),
            hours: [],
          },
        ].sort(sortByWeekdays),
      });
    }
  };

  const sortByWeekdays = (a: WeeklyScheduleInfo, b: WeeklyScheduleInfo) =>
    Object.keys(FULL_WEEKDAYS).indexOf(a.day.substring(0, 3).toUpperCase()) -
    Object.keys(FULL_WEEKDAYS).indexOf(b.day.substring(0, 3).toUpperCase());

  const removeHourFromSchedule = (day: string, hour: string) => {
    const weeklyItem = info.weekly!.find(
      (p) =>
        p.day.substring(0, 3).toUpperCase() ===
        day.substring(0, 3).toUpperCase()
    );

    if (weeklyItem) {
      const item = {
        ...weeklyItem,
      };
      item.hours = weeklyItem.hours.filter((p) => p !== hour);

      if (weeklyItem.hours.length === 0) {
        setInfo({
          ...info,
          weekly: [
            ...info.weekly!.filter(
              (p) =>
                p.day.substring(0, 3).toUpperCase() !==
                day.substring(0, 3).toUpperCase()
            ),
          ].sort(sortByWeekdays),
        });
      } else {
        setInfo({
          ...info,
          weekly: [
            ...info.weekly!.filter(
              (p) =>
                p.day.substring(0, 3).toUpperCase() !==
                day.substring(0, 3).toUpperCase()
            ),
            item,
          ].sort(sortByWeekdays),
        });
      }
    }
  };

  const addHourToSchedule = (day: string) => {
    if (timeRef.current) {
      const weeklyItem = info.weekly!.find(
        (p) =>
          p.day.substring(0, 3).toUpperCase() ===
          day.substring(0, 3).toUpperCase()
      );

      if (weeklyItem) {
        const item = {
          ...weeklyItem,
        };

        item.hours = [...item.hours, timeRef.current.value].filter(
          (v, i, a) => a.indexOf(v) === i
        );

        setInfo({
          ...info,
          weekly: [
            ...info.weekly!.filter(
              (p) =>
                p.day.substring(0, 3).toUpperCase() !==
                day.substring(0, 3).toUpperCase()
            ),
            item,
          ].sort(sortByWeekdays),
        });

        setAddHourModeForDay(null);
      }
    }
  };

  const disableAddHourModeForDate = () => {
    setAddHourModeForDay(null);
  };

  const handleHourChangeForKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === 'Enter' && e.currentTarget.value.length === 5) {
      addHourToSchedule(addHourModeForDay!);
    }
  };

  return (
    <div className="WeeklySelectionDetails">
      <div className="WeeklySelectionDetails__days">
        <span className="WeeklySelectionDetails__days__label">
          <FormattedMessage id="CaseCard.FormsAssignments.chooseDays" />
        </span>
        <Form className="WeeklySelectionDetails__form">
          {Object.keys(WEEKDAYS).map((day) => (
            <FormGroup
              className="WeeklySelectionDetails__form__item"
              check
              inline
            >
              <Input
                id={day}
                checked={
                  !!info.weekly!.find(
                    (p) => p.day.substring(0, 3).toUpperCase() === day
                  )
                }
                onChange={() => handleSpecificDaySelection(day)}
                type="checkbox"
              />
              <Label htmlFor={day} check>
                {WEEKDAYS[day]}
              </Label>
            </FormGroup>
          ))}
        </Form>
        {info.weekly!.map((item) => (
          <div className="DailySelectionDetails">
            <div className="DailySelectionDetails__frequency">
              <span>
                {FULL_WEEKDAYS[item.day.substring(0, 3).toUpperCase()]}
              </span>
            </div>
            <div className="DailySelectionDetails__hours">
              {item.hours.map((hour) => (
                <div className="DailySelectionDetails__hours__hour">
                  <span className="DailySelectionDetails__hours__hour__text">
                    {hour}
                  </span>
                  <button
                    onClick={() => removeHourFromSchedule(item.day, hour)}
                    className="DailySelectionDetails__hours__hour__btn"
                  >
                    &times;
                  </button>
                </div>
              ))}
              {addHourModeForDay !== null &&
                addHourModeForDay.substring(0, 3).toUpperCase() ===
                  item.day.substring(0, 3).toUpperCase() && (
                  <>
                    <input
                      ref={timeRef}
                      type="time"
                      name="add-hour"
                      className="HourSelectionDetails__add-hour-input"
                      onKeyDown={handleHourChangeForKeyPress}
                      required
                      aria-label="Add hour"
                    />
                    <button
                      onClick={() => addHourToSchedule(item.day)}
                      className="HourSelectionDetails__add-hour-confirm"
                      name="confirm"
                    >
                      <SVG src={plusIcon} title="confirm" />
                    </button>
                    <button
                      onClick={disableAddHourModeForDate}
                      className="HourSelectionDetails__add-hour-reject"
                    >
                      &times;
                    </button>
                  </>
                )}
              {addHourModeForDay === null && (
                <button
                  onClick={() => setAddHourModeForDay(item.day)}
                  className="HourSelectionDetails__add-hour-btn"
                >
                  <SVG src={plusIcon} title="add" />
                  <span>
                    <FormattedMessage id="CaseCard.FormsAssignments.HourSelection.addHour" />
                  </span>
                </button>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default WeeklySelectionDetails;
