import React from 'react';
import { AnswerHistoryQuestion } from '../../../../../../interfaces/change';
import { textWrapEllipse } from '../../../../../../helpers/utils/strings';
import { Progress } from 'reactstrap';
import useMobile from '../../../../../../hooks/useBreakpoint';
import { useAnswerHistoryAverage } from './hooks/useAnswerHistoryAverage';
import { useFeedbackChartQuestionTitle } from './hooks/useFeedbackChartQuestionTitle';
import { ConversationQuestion } from '../../../../../../interfaces/conversations';

type FeedbackChartFigureItemProps = {
  questions: ConversationQuestion[];
  question: AnswerHistoryQuestion;
};

const FeedbackChartFigureItem = ({
  question,
  questions,
}: FeedbackChartFigureItemProps) => {
  const isMobile = useMobile();
  const historyAverage = useAnswerHistoryAverage(question);

  const questionName = useFeedbackChartQuestionTitle(
    question.question_id,
    questions
  );

  return (
    <div
      key={question.question_id}
      className="d-flex align-items-center justify-content-between"
    >
      <div className="d-flex align-items-center gap-3">
        <div
          style={{
            background: question.question_color,
            width: 15,
            height: 15,
            borderRadius: 15,
          }}
        />
        <span className="Feedback__question-title">
          {textWrapEllipse(questionName ?? '', 20)}
        </span>
      </div>
      <div
        className="d-flex align-items-center justify-content-between"
        style={{ width: isMobile ? 140 : 200 }}
      >
        <Progress
          style={{
            width: isMobile ? 100 : 160,
            height: 5,
            marginRight: 15,
          }}
          barStyle={{ background: question.question_color }}
          value={historyAverage}
        />
        {question.answer_history.length > 0 ? historyAverage : 'N/A'}
      </div>
    </div>
  );
};

export default FeedbackChartFigureItem;
