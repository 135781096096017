import request from './utils/request';
import { IResponse } from '../interfaces/response';
import {
  OrganizationAccessProfile,
  UserAccessProfileEntry,
} from '../interfaces/accessProfiles';
import { IPatchObject } from '../interfaces/request';
import { CreateAccessProfileBody } from './mutations/accessProfiles/useCreateAccessProfile';
import { UserRoles } from 'interfaces/membership';

export const getOrganizationsUserAccessProfiles = (organizationId: string) =>
  request<void, IResponse<OrganizationAccessProfile[]>>({
    url: `/organizations/${organizationId}/access_profiles`,
    method: 'GET',
  });

export const deleteAccessProfile = (
  organizationId: string,
  accessProfileId: string
) =>
  request({
    url: `/organizations/${organizationId}/access_profiles/${accessProfileId}`,
    method: 'DELETE',
  });

export const modifyAccessProfile = (
  organizationId: string,
  accessProfileId: string,
  body: IPatchObject[]
) =>
  request({
    url: `/organizations/${organizationId}/access_profiles/${accessProfileId}`,
    method: 'PATCH',
    data: body,
  });

export const createAccessProfile = (
  organizationId: string,
  body: CreateAccessProfileBody
) =>
  request<CreateAccessProfileBody, void>({
    url: `/organizations/${organizationId}/access_profiles`,
    method: 'POST',
    data: body,
  });

export const addAccessProfileRoles = (
  organizationId: string,
  accessProfileId: string,
  body: UserRoles[]
) =>
  request<UserRoles[], void>({
    url: `/organizations/${organizationId}/access_profiles/${accessProfileId}/roles`,
    method: 'POST',
    data: body,
  });

export const removeAccessProfileRoles = (
  organizationId: string,
  accessProfileId: string,
  body: UserRoles[]
) =>
  request<UserRoles[], void>({
    url: `/organizations/${organizationId}/access_profiles/${accessProfileId}/roles`,
    method: 'DELETE',
    data: body,
  });

export const removeOrganizationUserAccessProfile = (
  organizationId: string,
  body: UserAccessProfileEntry
) =>
  request<UserAccessProfileEntry, void>({
    url: `/organizations/${organizationId}/user_access_profiles`,
    method: 'DELETE',
    data: body,
  });

export const addOrganizationUserAccessProfile = (
  organizationId: string,
  body: UserAccessProfileEntry
) =>
  request<UserAccessProfileEntry, void>({
    url: `/organizations/${organizationId}/user_access_profiles`,
    method: 'POST',
    data: body,
  });
