import { useInitQueries } from 'hooks/queries/useInitQuery';
import { useQueriesFetched } from 'hooks/queries/useQueriesFetched';
import { StrictDateRange } from 'interfaces/dates';
import DateSelectionPlaceholder from 'modules/Cases/pages/CaseDetailsPage/views/ChangeCaseDetailsView/tabs/ChangeCaseDetailsTabs/tabs/DashboardTab/components/DateSelectionWrapper/placeholders/DateSelectionPlaceholder';
import React from 'react';
import { DateRange } from 'react-day-picker';
import { useCommonData } from '../../hooks/useCommonData';
import DateSelection from './components/DateSelection';

type DateSelectionWrapperProps = {
  children: (props: StrictDateRange | DateRange | null) => JSX.Element;
};

const DateSelectionWrapper = ({ children }: DateSelectionWrapperProps) => {
  const { casePhaseHistory, caseDetails } = useCommonData();
  useInitQueries([casePhaseHistory, caseDetails]);

  const isFetched = useQueriesFetched([casePhaseHistory, caseDetails]);

  if (!isFetched) return <DateSelectionPlaceholder />;
  return <DateSelection children={children} />;
};

export default DateSelectionWrapper;
