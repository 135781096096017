import React, { useRef } from 'react';
import { TrendVisualizationTemplate } from 'interfaces/trendVisualizations';
import { useTemplateSummary } from './hooks/useTemplateSummary';
import { useTemplateActions } from './hooks/useTemplateActions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisH } from '@fortawesome/pro-light-svg-icons';
import SavedViewsItemActions from './components/SavedViewsItemActions';
import { GraspColors, graspColorsValues } from 'helpers/colors';
import { useTranslations } from 'hooks/useTranslations';
import { useTemplateItemTypes } from './hooks/useTemplateItemTypes';
import { getIconForTemplateItemType } from 'helpers/utils/icons';
import { textWrapEllipse } from 'helpers/utils/strings';
import { Button } from 'reactstrap';

type TrendVisualizationsSavedViewsListItemProps = {
  template: TrendVisualizationTemplate;
  handleEditChart: (template: TrendVisualizationTemplate) => void;
};

const SavedViewsItem = ({
  template,
  handleEditChart,
}: TrendVisualizationsSavedViewsListItemProps) => {
  const { wrapWithIntl, attachPrefix } = useTranslations(
    'MyOrganizations.trendVisualizations'
  );

  const moreButtonRef = useRef<HTMLButtonElement>(null);

  const templateSummary = useTemplateSummary(template);
  const { getHandleForRef, handleDelete, handleEdit } = useTemplateActions(
    template,
    handleEditChart
  );

  const itemTypes = useTemplateItemTypes(template);

  return (
    <div
      className="card shadow-sm d-flex flex-column gap-1 p-3"
      onClick={getHandleForRef(moreButtonRef)}
    >
      <div className="d-flex justify-content-between">
        <span className="fs-5">{textWrapEllipse(template.name, 20)}</span>
        <div>
          <Button
            innerRef={moreButtonRef}
            id={`TrendVisualizationsSavedViewsListItemActions-${template.id}`}
            color="primary"
            outline={true}
          >
            <FontAwesomeIcon
              icon={faEllipsisH}
              color={graspColorsValues[GraspColors.BLUE]}
            />
          </Button>
          <SavedViewsItemActions
            targetId={`TrendVisualizationsSavedViewsListItemActions-${template.id}`}
            onEditClick={handleEdit}
            onDeleteClick={handleDelete}
          />
        </div>
      </div>
      <span className="fs-6 text-muted">{templateSummary}</span>
      <div className="d-flex align-items-center gap-1">
        <span className="fs-6 text-muted">
          {wrapWithIntl(attachPrefix('templateContext'))}
        </span>
        <div className="d-flex align-items-center gap-2">
          {itemTypes.map((itemType) => getIconForTemplateItemType(itemType))}
        </div>
      </div>
    </div>
  );
};

export default SavedViewsItem;
