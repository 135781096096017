import useInfiniteGetCaseEventLogs from 'api/queries/cases/useInfiniteGetCaseEventLogs';
import { formatDate } from 'helpers/dates';
import { FormattedMessage } from 'react-intl';
import { SharedEventTypeProps } from '.';
import InfiniteScroll from 'react-infinite-scroller';
import CustomTable from 'components/CustomTable';
import InfiniteListLayoutLoader from '../InfiniteList/InfiniteListLayoutLoader';

const headerItems = [
  'EventScreen.header.action',
  'EventScreen.header.actionBy',
  'EventScreen.header.organization',
  'EventScreen.header.date',
];

const EventLogTable = ({
  search,
  selectedEventTypes,
  dateRange,
  resourceId,
}: SharedEventTypeProps) => {
  const { logs, hasNextPage, fetchNextPage, isLoading } =
    useInfiniteGetCaseEventLogs({
      params: {
        resourceId,
        searchValue: search,
        pageSize: 25,
        dateRange,
        selectedEventTypes,
      },
    });

  const renderedLogs = logs.map((log) => (
    <tr key={log.time_stamp}>
      <td>
        <FormattedMessage id={`EventScreen.eventType.${log.activity_type}`} />
      </td>
      <td>{log.user && `${log.user.first_name} ${log.user.last_name}`}</td>
      <td>{log.organization && log.organization.name}</td>
      <td>{log.time_stamp && formatDate(log.time_stamp)}</td>
    </tr>
  ));

  const hasMore = !isLoading && hasNextPage;
  const loadMore = () => fetchNextPage();

  return (
    <InfiniteScroll
      loadMore={loadMore}
      hasMore={hasMore}
      loader={
        <InfiniteListLayoutLoader
          key="infinite-loader"
          translationId="OrganizationCases.CasesList.fetching"
        />
      }
      threshold={100}
      initialLoad={false}
    >
      <CustomTable
        headerItems={headerItems}
        bodyRows={renderedLogs}
        clickableRows={false}
      />
    </InfiniteScroll>
  );
};

export default EventLogTable;
