import { useCallback } from 'react';
import { ICaseGroupSchedule } from '../../../../../../../interfaces/caseGroups';
import { strToDateOnly, toDateOnly } from '../../../../../../../helpers/dates';

import pendingIcon from 'assets/images/pending.svg';
import checkedIcon from 'assets/images/checked_icon.svg';

export const useTreatmentPhaseIcon = () => {
  return useCallback((groupSchedule: ICaseGroupSchedule | undefined) => {
    if (!groupSchedule) return undefined;

    const nowDate = toDateOnly(new Date());
    const startDate = strToDateOnly(groupSchedule.date_from);
    const endDate = strToDateOnly(groupSchedule.date_to);

    if (nowDate < startDate) return undefined;
    if (nowDate >= startDate && nowDate <= endDate) return pendingIcon;
    return checkedIcon;
  }, []);
};
