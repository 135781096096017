import { useCommonData } from '../../../../../../hooks/useCommonData';
import { useInitQuery } from '../../../../../../../../hooks/queries/useInitQuery';
import { useCommonParams } from '../../../../../../hooks/useCommonParams';
import useUnlinkVideoAssetFromEducationMaterial from '../../../../../../../../api/mutations/educationMaterials/useUnlinkVideoAssetFromEducationMaterial';
import useLinkVideoAssetWithEducationMaterial from '../../../../../../../../api/mutations/educationMaterials/useLinkVideoAssetWithEducationMaterial';
import { useCallback, useMemo } from 'react';

export const useAssetLinking = (
  assetId: string,
  materialId: string,
  culture: string,
  onLink?: (culture: string) => void,
  onUnlink?: (culture: string) => void
) => {
  const { educationMaterials } = useCommonData();
  useInitQuery(educationMaterials);

  const { organizationId } = useCommonParams();

  const onLinkingSuccess = useCallback(async () => {
    await educationMaterials.refetch();
    onLink?.(culture);
  }, [culture, educationMaterials, onLink]);

  const onUnlinkingSuccess = useCallback(async () => {
    await educationMaterials.refetch();
    onUnlink?.(culture);
  }, [culture, educationMaterials, onUnlink]);

  const { mutateAsync: unlinkAsync, isLoading: unlinkLoading } =
    useUnlinkVideoAssetFromEducationMaterial(
      organizationId,
      onUnlinkingSuccess
    );

  const { mutateAsync: linkAsync, isLoading: linkLoading } =
    useLinkVideoAssetWithEducationMaterial(organizationId, onLinkingSuccess);

  const handleUnlink = useCallback(async () => {
    await unlinkAsync({
      assetId: assetId,
      materialId: materialId,
      culture: culture,
    });
  }, [assetId, culture, materialId, unlinkAsync]);

  const handleLink = useCallback(async () => {
    await linkAsync({
      assetId: assetId,
      materialId: materialId,
      culture: culture,
    });
  }, [assetId, culture, linkAsync, materialId]);

  const isLoading = useMemo(
    () => unlinkLoading || linkLoading || educationMaterials.isRefetching,
    [educationMaterials.isRefetching, linkLoading, unlinkLoading]
  );

  return { handleUnlink, handleLink, isLoading };
};
