import React from 'react';
import { IBaseEducationMaterialRule } from '../../../../../../../../interfaces/educationMaterials';
import { useEducationMaterialItem } from '../../hooks/useEducationMaterialItem';
import { FormattedMessage } from 'react-intl';

type EducationMaterialsItemRuleProps = {
  rule: IBaseEducationMaterialRule;
};

const EducationMaterialsItemRule = ({
  rule,
}: EducationMaterialsItemRuleProps) => {
  const { getSubCategoryName } = useEducationMaterialItem();

  return (
    <div className="d-flex align-items-center gap-1">
      <span>{rule.availability_status}</span>
      <FormattedMessage id="Education.EducationMaterialsTab.EducationMaterialsItem.EducationMaterialsItemRule.inside" />
      <div>{getSubCategoryName(rule.case_sub_category_id)}</div>
    </div>
  );
};

export default EducationMaterialsItemRule;
