import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import {
  faArrowDown,
  faArrowUp,
  faTrash,
} from '@fortawesome/pro-light-svg-icons';

export const getActionIcon = (
  type: 'move-up' | 'move-down' | 'delete'
): IconDefinition => {
  switch (type) {
    case 'move-down':
      return faArrowDown;
    case 'move-up':
      return faArrowUp;
    case 'delete':
      return faTrash;
  }
};
