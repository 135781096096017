import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton, Paper } from '@mui/material';
import React from 'react';
import { useBrowserState } from '../../../../../../../../../../store/reducerHooks';
import { useDivideSingleEntries } from '../../../../hooks/useDivideSingleEntries';
import { useCarousel } from '../../../Summary/components/SummaryItems/hooks/useCarousel';
import { useSessionInfoEntries } from './hooks/useSessionInfoEntries';

const SessionInfoEntries = () => {
  const isBrowser = useBrowserState();
  const sessionInfoEntries = useSessionInfoEntries();
  const dividedSessionInfoEntries = useDivideSingleEntries(
    sessionInfoEntries,
    isBrowser ? 5 : 7
  );

  const { next, slides, previous, items } = useCarousel(
    dividedSessionInfoEntries
  );

  return (
    <Paper className="h-100" sx={{ position: 'relative' }}>
      {slides}
      {items.length > 1 && (
        <div
          className="d-block position-absolute"
          style={{ top: '50%', left: 0, transform: 'translateY(-50%)' }}
        >
          <IconButton size="large" aria-label="previous" onClick={previous}>
            <ArrowBackIosNewIcon />
          </IconButton>
        </div>
      )}
      {items.length > 1 && (
        <div
          className="d-block position-absolute"
          style={{ top: '50%', right: 0, transform: 'translateY(-50%)' }}
        >
          <IconButton size="large" aria-label="next" onClick={next}>
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
      )}
    </Paper>
  );
};

export default SessionInfoEntries;
