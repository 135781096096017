import { FormikProps } from 'formik';
import {
  DashboardTemplateChartType,
  DashboardTemplateSeriesSplitBy,
  DashboardTemplateSplitType,
} from '../../../../../../../../../../../../../../interfaces/dashboards';
import { FormValues } from './types';

export const isPieChart = (props: FormikProps<FormValues>): boolean => {
  return props.values.chart_type === DashboardTemplateChartType.PieChart;
};

export const hasEmptySplitBy = (props: FormikProps<FormValues>): boolean => {
  return props.values.series.every((p) => !p.split_by);
};

export const getSplitByValue = (
  splitBy?: DashboardTemplateSeriesSplitBy
): string | undefined => {
  if (!splitBy) return undefined;
  if (splitBy.type === DashboardTemplateSplitType.Question) return splitBy.id;
  return splitBy.type;
};

export const getSplitByObject = (
  splitBy?: string
): DashboardTemplateSeriesSplitBy | undefined => {
  if (!splitBy) return undefined;

  if (splitBy === DashboardTemplateSplitType.Doctor)
    return { type: DashboardTemplateSplitType.Doctor };

  if (splitBy === DashboardTemplateSplitType.Organization)
    return { type: DashboardTemplateSplitType.Organization };

  if (splitBy === DashboardTemplateSplitType.Grasp)
    return { type: DashboardTemplateSplitType.Grasp };

  return { type: DashboardTemplateSplitType.Question, id: splitBy };
};
