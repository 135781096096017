import Card from '@mui/material/Card';
import React, { PropsWithChildren } from 'react';

const ItemContainer = ({ children }: PropsWithChildren) => {
  return (
    <Card
      sx={{
        paddingX: '1.5rem',
        paddingY: '1rem',
        borderRadius: '1rem',
      }}
      variant="outlined"
    >
      {children}
    </Card>
  );
};

export default ItemContainer;
