import useCaseContextTimelineObservations from 'api/queries/cases/useCaseContextTimelineObservations';
import useCaseDetails from 'api/queries/cases/useCaseDetails';
import useOrganizationDetails from 'api/queries/organizations/useOrganizationDetails';
import Breadcrumbs from 'components/PageHeader/Breadcrumbs';
import { generateOrganizationPersonalitiesUrl } from 'helpers/routeDefinitions';
import { IObservation, ObservationCategory } from 'interfaces/timeline';
import { useState } from 'react';
import { useOrganizationCasePathParams } from '../../../../../../hooks/paths/useOrganizationCasePathParams';
import CalibrationSummary from './CalibrationSummary';
import MomentaryAmplitudeModal from './MomentaryAmplitudeModal';
import SqueezeStrengthInTime from './SqueezeStrengthInTime';

export type TimelineObservationProps = {
  timelineObservations: IObservation[];
};

export const COLOR_DEFINITIONS = {
  BLUE: '#407DBB',
  WHITE: '#ffffff',
  GRAY: '#e0e0e0',
};

export const LEVEL_CATEGORY_COLORS = {
  [ObservationCategory.CalibrationMax]: '#FF8080',
  [ObservationCategory.CalibrationMin]: '#50EF90',
  [ObservationCategory.CalibrationSoft]: '#50EF90',
  [ObservationCategory.CalibrationMedium]: '#F1C04F',
  [ObservationCategory.CalibrationHard]: '#FF8080',
};

const MomentaryCaseDetailsView = () => {
  const { organizationId, caseId } = useOrganizationCasePathParams();

  const { data, isLoading } = useCaseDetails({
    params: { organizationId, caseId },
    options: { enabled: false },
  });

  const { data: organizationData } = useOrganizationDetails({
    params: { organizationId },
  });

  const masterTimeline = data?.timelines.find((el) => el.is_master)?.id ?? '';
  const canFetch = !isLoading || !!masterTimeline;

  const { data: timelineObservations } = useCaseContextTimelineObservations({
    params: {
      organizationId,
      caseId,
      timelineId: masterTimeline,
      selectedDates: null,
      grouped: false,
    },
    options: { enabled: canFetch },
  });

  const [selectedObservations, setSelectedObservations] = useState<
    IObservation[] | null
  >(null);

  const closeAmplitudeModal = () => {
    setSelectedObservations(null);
  };

  if (!timelineObservations || timelineObservations?.length === 0) return null;

  const breadcrumbs = [
    {
      text: `${organizationData?.name ?? ''}`,
      link: null,
      key: 'organization',
    },
    {
      key: 'Participants',
      link: generateOrganizationPersonalitiesUrl({
        organizationId,
      }),
      text: 'Participants',
    },
    {
      key: 'session-name',
      link: null,
      text: data?.title ?? '',
    },
  ];

  return (
    <>
      <Breadcrumbs items={breadcrumbs} />
      <SqueezeStrengthInTime
        caseTitle={data?.title ?? ''}
        timelineObservations={timelineObservations}
        setSelectedObservations={setSelectedObservations}
      />
      <CalibrationSummary
        timelineObservations={timelineObservations}
        setSelectedObservations={setSelectedObservations}
      />
      <MomentaryAmplitudeModal
        selectedObservations={selectedObservations}
        close={closeAmplitudeModal}
      />
    </>
  );
};

export default MomentaryCaseDetailsView;
