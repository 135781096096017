import {
  IBaseEducationMaterialRule,
  IEducationMaterial,
} from '../../../../../interfaces/educationMaterials';
import { createContext } from 'react';
import { IAsyncFormHookValues } from '../../../../../interfaces/forms';
import { BaseModalProps, ReactStateTuple } from '../../../../../interfaces/ui';
import { CultureEntry } from 'interfaces/cultures';

export enum MaterialModalSteps {
  FillInfo = 'FillInfo',
  AddRules = 'AddRules',
  UploadAssets = 'UploadAssets',
}

export enum MaterialModalMode {
  AddMaterial = 'AddMaterial',
  EditMaterial = 'EditMaterial',
}

export type CategorizedEducationMaterial = IEducationMaterial & {
  category_id?: string;
};

export type MaterialProps = {
  material?: CategorizedEducationMaterial;
};

export type MaterialModalStepsContent = {
  steps: MaterialModalSteps[];
  step: MaterialModalSteps;
  next: () => void;
  prev: () => void;
};

const emptyMaterialModalStepsContent: MaterialModalStepsContent = {
  steps: [],
  step: MaterialModalSteps.FillInfo,
  next: () => {},
  prev: () => {},
};

export type MaterialModalFormValues = {
  organization_name: string;
  name: string;
  description: string;
  translations: CultureEntry<{ name: string; description: string }>[];
  material_category_id?: string;
  case_category_id?: string;
  is_premium: boolean;
  is_default: boolean;
  rules: IBaseEducationMaterialRule[];
};

export const MaterialModalStepsContext =
  createContext<MaterialModalStepsContent>(emptyMaterialModalStepsContent);

export const MaterialModalModeContext = createContext<MaterialModalMode>(
  MaterialModalMode.AddMaterial
);

export const MaterialModalFormContext = createContext<
  Partial<IAsyncFormHookValues<MaterialModalFormValues>>
>({});

export const MaterialModalContext = createContext<BaseModalProps>({
  isOpen: false,
  toggle: () => {},
});

export const CreatedMaterialContext = createContext<
  ReactStateTuple<IEducationMaterial | undefined>
>([undefined, () => {}]);
