import {
  ConversationScheduleType,
  ICaseGroupConversationSchedule,
  IConversationScheduleHours,
  IRecurringCaseGroupConversationSchedule,
  ISchedulesEditTimeFormInitialValues,
  ISingleInstanceCaseGroupConversationSchedule,
  UpdateRangeMode,
} from '../../../../../../../../../interfaces/conversationSchedules';
import { useCallback, useMemo } from 'react';
import { useCaseGroupDetailsCommonData } from '../../../../../hooks/useCaseGroupDetailsCommonData';
import { differenceInCalendarDays } from 'date-fns';
import { FormikErrors } from 'formik';
import { useIntl } from 'react-intl';
import { FieldInlineSelectOptionDefinition } from '../../../../../../../../../components/Fields/Formik/FieldInlineSelect';

import arrowRepeatIcon from 'assets/images/arrow-repeat.svg';
import arrowRepeatCrossedOutIcon from 'assets/images/arrow-repeat-crossed-out.svg';
import { useCaseGroupDetailsTemplateCharacteristics } from '../../CaseGroupDetailsConversationSchedulesAddTemplateModal/hooks/useCaseGroupDetailsTemplateCharacterictics';
import useRequiredParams from '../../../../../../../../../hooks/useRequiredParams';
import { useCaseGroupConversationScheduleData } from '../../../components/CaseGroupConversationSchedule/hooks/useCaseGroupConversationScheduleData';
import useDeleteCaseGroupConversationSchedule from '../../../../../../../../../api/mutations/conversationSchedules/useDeleteCaseGroupConversationSchedule';
import {
  strToDateOnly,
  toDateOnly,
} from '../../../../../../../../../helpers/dates';
import useUpdateCaseGroupConversationSchedule from '../../../../../../../../../api/mutations/conversationSchedules/useUpdateCaseGroupConversationSchedule';
import { useCaseGroupDetailsEditTimeFormPayload } from './useCaseGroupDetailsEditTimeFormPayload';
import useCreateCaseGroupConversationSchedule from '../../../../../../../../../api/mutations/conversationSchedules/useCreateCaseGroupConversationSchedule';
import { IEditScheduleTemplateItemFormHandler } from '../../../../../../../../../components/EditScheduleTemplateItemModal';
import { areEmptyConversationScheduleHours } from '../../../../../../../../../helpers/utils/conversationSchedules';
import { useEditScheduleTemplateItemValidation } from '../../../../../../../../../components/EditScheduleTemplateItemModal/hooks/useEditScheduleTemplateItemValidation';

export const useCaseGroupDetailsEditTimeForm = (
  schedule: ICaseGroupConversationSchedule,
  selectedDate: Date,
  toggle: () => void,
  phaseId: string
): IEditScheduleTemplateItemFormHandler<ISchedulesEditTimeFormInitialValues> => {
  const intl = useIntl();

  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const { caseGroup } = useCaseGroupDetailsCommonData();

  const { conversationTemplatesOptions } =
    useCaseGroupDetailsTemplateCharacteristics();

  const { singleInstanceValidation, outlierValidation, recurringValidation } =
    useEditScheduleTemplateItemValidation();

  const { caseGroupConversationSchedules } =
    useCaseGroupConversationScheduleData(phaseId);

  const {
    nonTimeBasedUpdatePayload,
    simpleSingleInstanceUpdatePayload,
    recurringUpdatePayload,
    recurringAddPayload,
    dirtySingleInstanceAddPayload,
  } = useCaseGroupDetailsEditTimeFormPayload();

  const handleSuccess = async () => {
    await caseGroupConversationSchedules.refetch();
    toggle();
  };

  const { mutateAsync: createAsync } = useCreateCaseGroupConversationSchedule(
    {
      organizationId: organizationId,
      caseGroupId: caseGroup.data?.id ?? '',
    },
    {
      successFb: handleSuccess,
    }
  );

  const { mutateAsync: updateAsync } = useUpdateCaseGroupConversationSchedule(
    {
      organizationId: organizationId,
      caseGroupId: caseGroup.data?.id ?? '',
    },
    {
      successFb: handleSuccess,
    }
  );

  const { mutateAsync: deleteAsync } = useDeleteCaseGroupConversationSchedule(
    {
      organizationId: organizationId,
      caseGroupId: caseGroup.data?.id ?? '',
    },
    {
      successFb: handleSuccess,
    }
  );

  const getOnceInitialValues = useCallback(
    (
      onceSchedule: ICaseGroupConversationSchedule
    ): ISchedulesEditTimeFormInitialValues => {
      return {
        template_id: onceSchedule.template_id,
        priority: onceSchedule.priority,
        specific_day_of_phase: null,
        end_day_offset: null,
        recurring_interval: null,
        start_day_offset: null,
        update_mode: null,
        available_from_hour: null,
        available_to_hour: null,
        notification_hour: null,
      };
    },
    []
  );

  const getSingleInstanceInitialValues = useCallback(
    (
      singleInstance: ISingleInstanceCaseGroupConversationSchedule
    ): ISchedulesEditTimeFormInitialValues => {
      if (singleInstance.is_dirty) {
        return {
          template_id: singleInstance.template_id,
          priority: singleInstance.priority,
          update_mode: UpdateRangeMode.SingleEvent,
          available_from_hour: singleInstance.available_from_hour,
          available_to_hour: singleInstance.available_to_hour,
          notification_hour: singleInstance.notification_hour,
          start_day_offset: null,
          recurring_interval: null,
          end_day_offset: null,
          specific_day_of_phase: null,
        };
      }

      const groupSchedule = caseGroup.data?.group_schedule.find(
        (p) => p.phase_id === singleInstance.phase_id
      )!;

      const specificDayOfPhase = differenceInCalendarDays(
        new Date(singleInstance.date),
        new Date(groupSchedule.date_from)
      );

      return {
        template_id: singleInstance.template_id,
        priority: singleInstance.priority,
        specific_day_of_phase: specificDayOfPhase + 1,
        available_from_hour: singleInstance.available_from_hour,
        available_to_hour: singleInstance.available_to_hour,
        notification_hour: singleInstance.notification_hour,
        start_day_offset: null,
        end_day_offset: null,
        recurring_interval: null,
        update_mode: null,
      };
    },
    [caseGroup.data?.group_schedule]
  );

  const getRecurringInitialValues = useCallback(
    (
      recurringSchedule: IRecurringCaseGroupConversationSchedule
    ): ISchedulesEditTimeFormInitialValues => {
      const groupSchedule = caseGroup.data?.group_schedule.find(
        (p) => p.phase_id === recurringSchedule.phase_id
      )!;

      const startDayOffset = differenceInCalendarDays(
        new Date(recurringSchedule.date_from),
        new Date(groupSchedule.date_from)
      );

      const endDayOffset = differenceInCalendarDays(
        new Date(recurringSchedule.date_to),
        new Date(groupSchedule.date_from)
      );

      return {
        template_id: recurringSchedule.template_id,
        priority: recurringSchedule.priority,
        update_mode: UpdateRangeMode.AllEvents,
        recurring_interval: recurringSchedule.recurring_interval,
        available_from_hour: recurringSchedule.available_from_hour,
        available_to_hour: recurringSchedule.available_to_hour,
        notification_hour: recurringSchedule.notification_hour,
        specific_day_of_phase: null,
        start_day_offset: startDayOffset + 1,
        end_day_offset: endDayOffset + 1,
      };
    },
    [caseGroup.data?.group_schedule]
  );

  const initialValues = useMemo(() => {
    if (
      schedule.type === ConversationScheduleType.Once ||
      schedule.type === ConversationScheduleType.NonTimeBased
    )
      return getOnceInitialValues(schedule);

    if (schedule.type === ConversationScheduleType.SingleInstance)
      return getSingleInstanceInitialValues(
        schedule as ISingleInstanceCaseGroupConversationSchedule
      );

    return getRecurringInitialValues(
      schedule as IRecurringCaseGroupConversationSchedule
    );
  }, [
    getOnceInitialValues,
    getRecurringInitialValues,
    getSingleInstanceInitialValues,
    schedule,
  ]);

  const isPlusMode = useCallback(
    (scheduleProvided: IConversationScheduleHours) => {
      return areEmptyConversationScheduleHours(scheduleProvided);
    },
    []
  );

  const handleSubmit = useCallback(
    async (values: typeof initialValues) => {
      const groupSchedule = caseGroup.data?.group_schedule.find(
        (p) => p.phase_id === phaseId
      );

      if (!groupSchedule) return;

      if (
        schedule.type === ConversationScheduleType.Once ||
        schedule.type === ConversationScheduleType.NonTimeBased
      ) {
        await updateAsync(nonTimeBasedUpdatePayload(schedule, values));
        return;
      }

      if (
        schedule.type === ConversationScheduleType.SingleInstance &&
        schedule.recurring_origin_id === null
      ) {
        await updateAsync(
          simpleSingleInstanceUpdatePayload(schedule, values, groupSchedule)
        );

        return;
      }

      const plusMode = isPlusMode(schedule as IConversationScheduleHours);

      if (plusMode) {
        if (values.update_mode === UpdateRangeMode.AllEvents) {
          await createAsync(recurringAddPayload(values, groupSchedule));

          return;
        }

        await createAsync(
          dirtySingleInstanceAddPayload(
            schedule,
            values,
            groupSchedule,
            selectedDate.toISOString()
          )
        );
        return;
      }

      if (values.update_mode === UpdateRangeMode.AllEvents) {
        await updateAsync(
          recurringUpdatePayload(schedule, values, groupSchedule)
        );

        return;
      }

      let singleInstance = caseGroupConversationSchedules.data?.find(
        (p) => p.recurring_origin_id === schedule.id && !p.is_deleted
      );

      if (!singleInstance) return;

      await updateAsync(
        simpleSingleInstanceUpdatePayload(singleInstance, values, groupSchedule)
      );
    },
    [
      caseGroup.data?.group_schedule,
      caseGroupConversationSchedules.data,
      createAsync,
      dirtySingleInstanceAddPayload,
      isPlusMode,
      nonTimeBasedUpdatePayload,
      phaseId,
      recurringAddPayload,
      recurringUpdatePayload,
      schedule,
      selectedDate,
      simpleSingleInstanceUpdatePayload,
      updateAsync,
    ]
  );

  const validate = useCallback(
    (values: typeof initialValues): FormikErrors<typeof initialValues> => {
      let errors: FormikErrors<typeof initialValues> = {};

      const groupSchedule = caseGroup.data?.group_schedule.find(
        (p) => p.phase_id === phaseId
      );

      if (!groupSchedule) return errors;

      if (
        schedule.type === ConversationScheduleType.Once ||
        schedule.type === ConversationScheduleType.NonTimeBased
      ) {
        if (values.priority <= 0) {
          errors.priority = 'priority';
          return errors;
        }
      }

      if (
        schedule.type === ConversationScheduleType.SingleInstance &&
        !schedule.recurring_origin_id
      ) {
        errors = singleInstanceValidation(
          errors,
          values,
          groupSchedule.date_from,
          groupSchedule.date_to
        );
        return errors;
      }

      if (
        (schedule.type === ConversationScheduleType.SingleInstance &&
          schedule.is_dirty) ||
        values.update_mode === UpdateRangeMode.SingleEvent
      ) {
        errors = outlierValidation(errors, values);
        return errors;
      }

      if (values.update_mode === UpdateRangeMode.AllEvents) {
        errors = recurringValidation(
          errors,
          values,
          groupSchedule.date_from,
          groupSchedule.date_to
        );
      }

      return errors;
    },
    [
      caseGroup.data?.group_schedule,
      outlierValidation,
      phaseId,
      recurringValidation,
      schedule.is_dirty,
      schedule.recurring_origin_id,
      schedule.type,
      singleInstanceValidation,
    ]
  );

  const updateRangeOptions = useCallback(
    (optionsDisabled: boolean): FieldInlineSelectOptionDefinition[] => {
      let rangeValues = Object.values(UpdateRangeMode);

      return rangeValues.map((rangeMode) => ({
        value: rangeMode,
        title: intl.formatMessage({
          id: `Enums.UpdateRangeMode.${rangeMode}`,
        }),
        icon:
          rangeMode === UpdateRangeMode.AllEvents
            ? arrowRepeatIcon
            : arrowRepeatCrossedOutIcon,
        disabled: optionsDisabled,
      }));
    },
    [intl]
  );

  const templatesOptions = useMemo(() => {
    return conversationTemplatesOptions(false);
  }, [conversationTemplatesOptions]);

  const handleRemove = useCallback(
    async (values: typeof initialValues) => {
      if (
        schedule.type === ConversationScheduleType.Recurring &&
        values.update_mode === UpdateRangeMode.SingleEvent
      ) {
        const singleInstanceSchedule =
          caseGroupConversationSchedules.data?.find(
            (p) =>
              p.recurring_origin_id === schedule.id &&
              p.date !== null &&
              toDateOnly(selectedDate) === strToDateOnly(p.date)
          );

        if (!singleInstanceSchedule) return;

        await deleteAsync(singleInstanceSchedule.id);
        return;
      }

      await deleteAsync(schedule.id);
    },
    [
      caseGroupConversationSchedules.data,
      deleteAsync,
      schedule.id,
      schedule.type,
      selectedDate,
    ]
  );

  return {
    initialValues: initialValues,
    handleSubmit: handleSubmit,
    handleRemove: handleRemove,
    validate: validate,
    options: {
      updateRange: updateRangeOptions,
      conversationTemplates: templatesOptions,
    },
    helpers: {
      isPlusMode: isPlusMode,
    },
  };
};
