import { getUserOrganizationsWithPaging } from 'api/membership';
import QueryKeys from 'api/queries/queryKeys';
import {
  convertIntoObjectWithPageInfo,
  returnAllDataFromResponse,
} from 'helpers/utils/reactQueryHelpers';
import { IUserOrganizationResponse } from 'interfaces/membership';
import {
  SearchAndPagingQueryParams,
  UserQueryParams,
} from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { IError, IInfiniteResult } from 'interfaces/response';
import { useInfiniteQuery } from 'react-query';

type UseInfiniteUserOrganizationsParams = UserQueryParams &
  SearchAndPagingQueryParams;

function useInfiniteUserOrganizations({
  params,
  options,
}: IQueryRequest<UseInfiniteUserOrganizationsParams>) {
  const { userId, searchValue, pageSize = 20 } = params;
  const response = useInfiniteQuery<
    IInfiniteResult<IUserOrganizationResponse[]>,
    IError
  >({
    ...options,
    queryKey: [QueryKeys.getUserOrganizations, userId, searchValue],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getUserOrganizationsWithPaging(userId, {
        page: pageParam,
        page_size: pageSize,
        search: searchValue,
      });

      return convertIntoObjectWithPageInfo<IUserOrganizationResponse[]>(data);
    },
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage;
    },
    getPreviousPageParam: (firstPage) => firstPage.previousPage,
  });

  return {
    ...response,
    organizations:
      returnAllDataFromResponse<IUserOrganizationResponse>(response),
    hasMore: !response.isLoading && response.hasNextPage,
  };
}

export default useInfiniteUserOrganizations;
