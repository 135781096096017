import { useCallback, useState } from 'react';
import useDeleteEducationMaterialCategory from '../../../../../api/mutations/educationMaterials/useDeleteEducationMaterialCategory';
import { useCommonData } from '../../../hooks/useCommonData';
import { useInitQuery } from '../../../../../hooks/queries/useInitQuery';
import useRequiredParams from '../../../../../hooks/useRequiredParams';
import { IBaseEducationMaterialCategory } from '../../../../../interfaces/educationMaterials';
import useOpen from '../../../../../hooks/useOpen';

export const useManageEducationMaterialCategory = () => {
  const { isOpen: editOpen, toggle: toggleEdit } = useOpen();
  const { isOpen: addItemOpen, toggle: toggleAddItem } = useOpen();
  const { isOpen: linkOpen, toggle: toggleLink } = useOpen();

  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const { educationMaterialCategoriesTree, educationMaterialCategories } =
    useCommonData();

  useInitQuery(educationMaterialCategoriesTree);
  useInitQuery(educationMaterialCategories);

  const handleSuccess = async () => {
    await educationMaterialCategories.refetch();
    await educationMaterialCategoriesTree.refetch();
  };

  const { mutateAsync, isLoading } = useDeleteEducationMaterialCategory({
    params: { organizationId },
    options: { successFb: handleSuccess },
  });

  const [editMaterialCategory, setEditMaterialCategory] = useState<
    IBaseEducationMaterialCategory | undefined
  >();

  const [addItemMaterialCategory, setAddItemMaterialCategory] = useState<
    IBaseEducationMaterialCategory | undefined
  >();

  const [linkMaterialCategory, setLinkMaterialCategory] = useState<
    IBaseEducationMaterialCategory | undefined
  >();

  const handleDelete = useCallback(
    async (id: string) => {
      await mutateAsync(id);
    },
    [mutateAsync]
  );

  const handleEdit = useCallback(
    (id: string) => {
      const category = educationMaterialCategories.data?.find(
        (p) => p.id === id
      );

      if (!category) return;

      setEditMaterialCategory(category);
      toggleEdit();
    },
    [educationMaterialCategories.data, toggleEdit]
  );

  const handleAddItem = useCallback(
    (id: string) => {
      const category = educationMaterialCategories.data?.find(
        (p) => p.id === id
      );
      if (!category) return;

      setAddItemMaterialCategory(category);
      toggleAddItem();
    },
    [educationMaterialCategories.data, toggleAddItem]
  );

  const handleLink = useCallback(
    (id: string) => {
      const category = educationMaterialCategories.data?.find(
        (p) => p.id === id
      );
      if (!category) return;

      setLinkMaterialCategory(category);
      toggleLink();
    },
    [educationMaterialCategories.data, toggleLink]
  );

  const handleClean = () => {
    setAddItemMaterialCategory(undefined);
    setEditMaterialCategory(undefined);
    setLinkMaterialCategory(undefined);
  };

  const withHandleClean = (handleToggle: () => void) => () => {
    handleToggle();
    handleClean();
  };

  return {
    edit: {
      handle: handleEdit,
      isOpen: editOpen,
      toggle: withHandleClean(toggleEdit),
      category: editMaterialCategory,
    },
    remove: {
      handle: handleDelete,
      isLoading: isLoading,
    },
    addItem: {
      handle: handleAddItem,
      isOpen: addItemOpen,
      toggle: withHandleClean(toggleAddItem),
      category: addItemMaterialCategory,
    },
    link: {
      handle: handleLink,
      isOpen: linkOpen,
      toggle: withHandleClean(toggleLink),
      category: linkMaterialCategory,
    },
  };
};
