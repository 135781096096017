import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import HourglassBottomOutlinedIcon from '@mui/icons-material/HourglassBottomOutlined';
import LockOpenOutlinedIcon from '@mui/icons-material/LockOpenOutlined';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import IconButton from '../../../../../../../../../../../components/IconButton';
import { useOrganizationPathParams } from '../../../../../../../../../../../hooks/paths/useOrganizationPathParams';
import { OrganizationAccessProfile } from '../../../../../../../../../../../interfaces/accessProfiles';
import { IUserOrganizationResponse } from '../../../../../../../../../../../interfaces/membership';
import CellEditableInput from '../../../../../../CellEditableInput';
import AccessProfilesSelector from '../../../components/AccessProfilesSelector';
import { useEditingRow } from './useEditingRow';

export const useColumns = (accessProfiles: OrganizationAccessProfile[]) => {
  const { organizationId } = useOrganizationPathParams();

  const intl = useIntl();

  const {
    handleSave,
    handleCancel,
    handleEdit,
    isSaving,
    isCellInEditMode,
    onEdit,
  } = useEditingRow();

  return useMemo(
    (): GridColDef[] => [
      {
        field: 'first_name',
        headerName: intl.formatMessage({ id: 'General.FirstName' }),
        flex: 0.3,
        renderCell: (params: GridRenderCellParams) => (
          <CellEditableInput
            onEdit={onEdit}
            value={params.row.user.first_name}
            editMode={isCellInEditMode(params.row.id)}
            name="first_name"
            type="text"
          />
        ),
      },
      {
        field: 'last_name',
        headerName: intl.formatMessage({ id: 'General.LastName' }),
        flex: 0.4,
        renderCell: (params: GridRenderCellParams) => (
          <CellEditableInput
            onEdit={onEdit}
            value={params.row.user.last_name}
            editMode={isCellInEditMode(params.row.id)}
            name="last_name"
            type="text"
          />
        ),
      },
      {
        field: 'user_name',
        headerName: intl.formatMessage({ id: 'General.UserName' }),
        flex: 0.55,
        renderCell: (params: GridRenderCellParams) => (
          <CellEditableInput
            onEdit={onEdit}
            value={params.row.user.user_name}
            editMode={isCellInEditMode(params.row.id)}
            name="user_name"
            type="text"
          />
        ),
      },
      {
        field: 'email',
        headerName: intl.formatMessage({ id: 'General.Email' }),
        flex: 0.55,
        renderCell: (params: GridRenderCellParams) => (
          <CellEditableInput
            onEdit={onEdit}
            value={params.row.user.email}
            editMode={isCellInEditMode(params.row.id)}
            name="email"
            type="email"
          />
        ),
      },
      {
        field: 'user_access_profiles',
        headerName: intl.formatMessage({ id: 'Organizations.AccessProfiles' }),
        flex: 1,
        renderCell: (params: GridRenderCellParams) => (
          <AccessProfilesSelector
            row={params.row as IUserOrganizationResponse}
            accessProfiles={accessProfiles}
          />
        ),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        cellClassName: 'actions',
        width: 125,
        renderCell: (params: GridRenderCellParams) => {
          const editMode = isCellInEditMode(params.id as string);

          if (editMode) {
            return (
              <div className="d-flex gap-2">
                <IconButton
                  color="primary"
                  variant="contained"
                  onClick={handleSave}
                >
                  {isSaving ? (
                    <HourglassBottomOutlinedIcon />
                  ) : (
                    <CheckOutlinedIcon />
                  )}
                </IconButton>
                <IconButton
                  color="primary"
                  variant="outlined"
                  onClick={handleCancel}
                >
                  <LockOpenOutlinedIcon />
                </IconButton>
              </div>
            );
          }

          return (
            <IconButton
              disabled={
                params.row.user.owner_organization_id !== organizationId
              }
              color="primary"
              variant="outlined"
              onClick={handleEdit(params.row)}
            >
              <EditOutlinedIcon />
            </IconButton>
          );
        },
      },
    ],
    [
      accessProfiles,
      handleCancel,
      handleEdit,
      handleSave,
      intl,
      isCellInEditMode,
      isSaving,
      onEdit,
      organizationId,
    ]
  );
};
