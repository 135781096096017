import { IQueryRequest } from '../../../interfaces/request';
import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { TimelineQueryParams } from '../../../interfaces/queryParams';
import { getTimelineObservationsForPeriod } from '../../observations';

type TimelineObservationsForPeriodQueryParams = TimelineQueryParams & {
  from: Date;
  to: Date;
};

const useTimelineObservationsForPeriod = ({
  params,
  options,
}: IQueryRequest<TimelineObservationsForPeriodQueryParams>) => {
  return useQuery({
    ...options,
    queryKey: [
      QueryKeys.getTimelineObservationsForPeriod,
      params.timelineId,
      params.from.toISOString(),
      params.to.toISOString(),
    ],
    queryFn: async () => {
      const { data } = await getTimelineObservationsForPeriod(
        params.timelineId,
        params.from,
        params.to
      );

      return data.data;
    },
  });
};

export default useTimelineObservationsForPeriod;
