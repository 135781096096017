import React from 'react';
import FieldInput from '../../../../../../../../../../components/FieldInput';
import { Field } from 'formik';
import { useTranslations } from '../../hooks/useTranslations';

type DelimiterItemEditProps = {
  index: number;
};

const DelimiterItemEdit = ({ index }: DelimiterItemEditProps) => {
  const { providerInvariant } = useTranslations();

  return (
    <>
      <Field
        required={true}
        id={`content[${index}].persist`}
        name={`content[${index}].persist`}
        label={providerInvariant.wrapWithIntl(
          providerInvariant.attachPrefix('persist')
        )}
        type="checkbox"
        component={FieldInput}
      />
    </>
  );
};

export default DelimiterItemEdit;
