import useCaseNotificationSchedules from 'api/queries/notificationSchedules/useCaseNotificationSchedules';
import { useOrganizationCasePathParams } from 'hooks/paths/useOrganizationCasePathParams';
import { useMemo } from 'react';
import { CasesHelpers } from '../../../../../../../../../../../../../helpers/data/cases';
import { useInitQuery } from '../../../../../../../../../../../../../hooks/queries/useInitQuery';
import { useTypedContext } from '../../../../../../../../../../../../../hooks/useTypedContext';
import { CaseDetailsContext } from '../../../../../../../../../types';

export const useNotificationSchedulesQuery = (selectedDate: string) => {
  const { caseId } = useOrganizationCasePathParams();

  const year = useMemo(() => {
    const date = new Date(selectedDate);
    return date.getFullYear();
  }, [selectedDate]);

  const month = useMemo(
    () => new Date(selectedDate).getMonth() + 1,
    [selectedDate]
  );

  const day = useMemo(() => new Date(selectedDate).getDate(), [selectedDate]);

  const caseDetails = useTypedContext(CaseDetailsContext);

  const personality = CasesHelpers.GetCaseDefaultPersonality(caseDetails);

  const query = useCaseNotificationSchedules({
    params: {
      userId: personality?.user_id ?? '',
      caseId,
      year,
      month,
      day,
    },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(query, !!personality?.user_id);

  return query;
};
