import React from 'react';
import ScheduleHours from '../ScheduleHours';
import ScheduleRow from '../ScheduleRow';
import ScheduleEmptyRow from '../ScheduleEmptyRow';
import { strToDateOnly } from '../../../../helpers/dates';
import { ScheduleItemDefinition } from '../../index';
import { v4 as uuid } from 'uuid';
import { ConversationScheduleType } from '../../../../interfaces/conversationSchedules';

const ScheduleLoading = () => {
  const selectedDateString = strToDateOnly(new Date().toISOString());

  const item: ScheduleItemDefinition = {
    rowId: uuid(),
    isLoading: true,
    scheduleTemplate: {
      schedule_type: ConversationScheduleType.Once,
      start_day_offset: null,
      specific_day_of_phase: null,
      recurring_interval: null,
      end_day_offset: null,
      parts_of_a_day: [],
    },
    sequence: 0,
    templateId: '',
  };

  return (
    <div className="d-flex flex-column pt-5">
      <table className="Schedule">
        <ScheduleHours />
        <tbody className="Schedule__body">
          <ScheduleRow selectedDateString={selectedDateString} item={item} />
          <ScheduleEmptyRow />
          <ScheduleRow selectedDateString={selectedDateString} item={item} />
          <ScheduleEmptyRow />
          <ScheduleRow selectedDateString={selectedDateString} item={item} />
          <ScheduleEmptyRow />
        </tbody>
      </table>
    </div>
  );
};

export default ScheduleLoading;
