import { faFileAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createTheme, ThemeProvider } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalHeader } from 'reactstrap';
import { BaseModalProps } from '../../../../../../../../../../interfaces/ui';
import AddReportScheduleForm from './forms/AddReportScheduleForm';

type AddReportScheduleModalProps = BaseModalProps;

const AddReportScheduleModal = ({
  isOpen,
  toggle,
}: AddReportScheduleModalProps) => {
  return (
    <ThemeProvider
      theme={createTheme({
        components: {
          MuiSvgIcon: {
            styleOverrides: {
              root: { padding: 0 },
            },
          },
        },
      })}
    >
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="GenericModal BorderlessModal"
        size="md"
      >
        <ModalHeader toggle={toggle}>
          <FontAwesomeIcon icon={faFileAlt} />
          <FormattedMessage id="Dashboard.AddReportSchedule" />
        </ModalHeader>
        <AddReportScheduleForm modal={{ isOpen, toggle }} />
      </Modal>
    </ThemeProvider>
  );
};

export default AddReportScheduleModal;
