import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import SVG from 'react-inlinesvg';
import handSqueezing from '../../../../assets/images/hand_squeezing.svg';
import LoadingButton from '../../../../components/LoadingButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStop } from '@fortawesome/pro-solid-svg-icons';
import { useNavigate } from 'react-router';
import { useCommonParams } from '../../hooks/useCommonParams';
import { useTimer } from '../../hooks/useTimer';
import { useSearchParams } from 'react-router-dom';
import useCreateExerciseSession from '../../../../api/mutations/exerciseSessions/useCreateExerciseSession';
import useUpdateExerciseSession from '../../../../api/mutations/exerciseSessions/useUpdateExerciseSession';
import { ExerciseSessionStatus } from '../../../../interfaces/exerciseSessions';
import useExerciseSessions from '../../../../api/queries/exerciseSessions/useExerciseSessions';

enum ExerciseSteps {
  Exercising = 'Exercising',
  AfterExercise = 'AfterExercise',
}

const ExerciseSessionContent = () => {
  const navigate = useNavigate();
  const { userId, caseId } = useCommonParams();

  const { currentTime, stop, play, init } = useTimer();

  const [sessionId, setSessionId] = useState<string | null>(null);
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  const [exerciseStatus, setExerciseStatus] = useState<ExerciseSteps>(
    ExerciseSteps.Exercising
  );

  const exerciseSessions = useExerciseSessions({
    params: { userId },
    options: { enabled: false, staleTime: Infinity },
  });

  const [searchParams] = useSearchParams();

  const handleSessionCreated = useCallback(
    (id: string, initCount: number = 0) => {
      init(initCount);
      play();
      setSessionId(id);
      setIsInitialized(true);
    },
    [init, play]
  );

  const handleSessionUpdated = useCallback(async () => {
    if (sessionId === null) return;
    await exerciseSessions.refetch();
  }, [exerciseSessions, sessionId]);

  const { mutateAsync: createAsync, isLoading } = useCreateExerciseSession({
    params: { userId },
    options: { successFb: handleSessionCreated },
    silent: true,
  });

  const { mutateAsync: updateAsync, isLoading: isUpdating } =
    useUpdateExerciseSession({
      params: { userId },
      options: { successFb: handleSessionUpdated },
      silent: true,
    });

  const onStop = async () => {
    setExerciseStatus(ExerciseSteps.AfterExercise);
    stop();

    if (sessionId === null) return;

    await updateAsync({
      id: sessionId,
      patch: [
        {
          path: '/status',
          value: ExerciseSessionStatus.Closed,
          op: 'replace',
        },
      ],
    });
  };

  const onDone = () => {
    window.dispatchEvent(new CustomEvent('grasp-synchronization-request'));
    navigate(
      `/users/${userId}/cases/${caseId}/dashboard?synchronization_requested=true`
    );
  };

  useEffect(() => {
    (async () => {
      if (isInitialized) return;

      const sessionId = searchParams.get('sessionId');
      const initCount = searchParams.get('initCount');

      if (sessionId) {
        const initCountNumber = parseInt(initCount ?? '0');
        handleSessionCreated(
          sessionId,
          isNaN(initCountNumber) ? 0 : initCountNumber
        );
        return;
      }

      await createAsync(caseId);
    })();
  }, [caseId, createAsync, handleSessionCreated, isInitialized, searchParams]);

  return (
    <div className="ExerciseSession">
      <div className="ExerciseSession__info">
        <FormattedMessage
          tagName="span"
          id={
            exerciseStatus === ExerciseSteps.AfterExercise
              ? 'ExerciseSession.greatJob'
              : 'ExerciseSession.makeSureThatYourSqueeze'
          }
        />
      </div>
      <div className="ExerciseSession__squeezing-hand">
        <SVG src={handSqueezing} />
      </div>
      <div className="ExerciseSession__counter">
        <span>{currentTime}</span>
      </div>
      <div className="ExerciseSession__stop-exercising w-100">
        {exerciseStatus === ExerciseSteps.Exercising ? (
          <LoadingButton
            isLoading={isLoading}
            disabled={isLoading}
            className="w-75 d-flex align-items-center justify-content-center gap-2"
            color="primary"
            onClick={onStop}
          >
            <FontAwesomeIcon icon={faStop} size="sm" className="me-2" />
            <FormattedMessage id="ExerciseSession.stopExercising" />
          </LoadingButton>
        ) : (
          <LoadingButton
            isLoading={isUpdating}
            disabled={isUpdating}
            className="w-75"
            color="primary"
            onClick={onDone}
          >
            <FormattedMessage id="ExerciseSession.done" />
          </LoadingButton>
        )}
      </div>
    </div>
  );
};

export default ExerciseSessionContent;
