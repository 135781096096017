import { useMemo } from 'react';
import { differenceInMilliseconds } from 'date-fns';
import { useTypedContext } from '../../../../../../../../../hooks/useTypedContext';
import { OperationalDataContext } from '../../../providers/OperationalDataProvider';

export const useYAxisDomain = () => {
  const { awareSessionDetails } = useTypedContext(OperationalDataContext);

  return useMemo(() => {
    if (!awareSessionDetails?.end_date || !awareSessionDetails?.start_date)
      return [0, 0];

    return [
      0,
      differenceInMilliseconds(
        new Date(awareSessionDetails.end_date),
        new Date(awareSessionDetails.start_date)
      ),
    ];
  }, [awareSessionDetails.end_date, awareSessionDetails.start_date]);
};
