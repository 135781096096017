import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Formik, Field, Form } from 'formik';
import { FormGroup } from 'reactstrap';

// COMPONENTS:
import LoadingButton from 'components/LoadingButton';
import FieldInput from 'components/FieldInput';

type ResetPasswordProps = {
  isFailure: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  initialValues: { email: string };
  handleSubmit: (params: { email: string }) => void;
};

const ResetPasswordForm = ({
  isFailure,
  isLoading,
  isSuccess,
  handleSubmit,
  initialValues,
}: ResetPasswordProps) => {
  const intl = useIntl();
  const { formatMessage } = intl || {};

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {() => (
        <Form>
          <FormGroup>
            <label className="newMembersSection__label">
              <FormattedMessage id="ResetPassword.ResetPasswordForm.email" />
            </label>
            <Field
              required
              name="email"
              type="email"
              placeholder={formatMessage({
                id: 'ResetPassword.ResetPasswordForm.email',
              })}
              component={FieldInput}
            />
          </FormGroup>
          <div className="newMembersSection__buttonWrapper d-flex justify-content-center mt-4">
            <LoadingButton
              outline
              isLoading={isLoading}
              isSuccess={isSuccess}
              isFailure={isFailure}
              value="ResetPassword.ResetPasswordForm.buttonText"
              type="submit"
              className="btn btn-login"
            />
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ResetPasswordForm;
