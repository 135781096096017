import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import PageHeader from '../../../../../../components/PageHeader';
import { useCaseGroupDetailsCommonData } from '../../hooks/useCaseGroupDetailsCommonData';
import { useInitQuery } from '../../../../../../hooks/queries/useInitQuery';

const CaseGroupDetailsBreadcrumbs = () => {
  const { organization, caseGroup } = useCaseGroupDetailsCommonData();

  useInitQuery(organization);
  useInitQuery(caseGroup);

  const breadcrumbs = useMemo(
    () => [
      {
        text: <FormattedMessage id="Header.myOrganizations" />,
        link: null,
        key: 'my-organizations',
      },
      {
        text: organization.data?.name ?? '',
        link: `/my-organizations/${organization.data?.id}`,
        key: 'name',
      },
      {
        text: <FormattedMessage id="Header.caseGroups" />,
        link: `/organizations/${organization.data?.id}/case-groups`,
        key: 'case-groups',
      },
      {
        text:
          (
            <FormattedMessage
              id="Header.caseGroupItem"
              values={{ sequence: caseGroup.data?.sequence }}
            />
          ) ?? '',
        link: null,
        key: 'case-group-name',
      },
    ],
    [caseGroup.data?.sequence, organization.data?.id, organization.data?.name]
  );

  return <PageHeader breadcrumbs={breadcrumbs} />;
};

export default CaseGroupDetailsBreadcrumbs;
