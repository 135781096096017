import { IFormHookValues } from '../../../../../../../../../interfaces/forms';
import { FormikErrors, FormikProps } from 'formik';
import { AwareCaseDetailsFormValues } from '../../../forms/AwareCaseDetailsForm/types';
import { useInitialValues } from './useInitialValues';
import { ICaseProcedureWithActivitiesLocalized } from '../../../../../../../../../interfaces/cases';
import { useCallback } from 'react';
import { useProceduresDiffs } from './useProceduresDiffs';
import { useActivitiesDiffs } from './useActivitiesDiffs';
import { useTypedContext } from '../../../../../../../../../hooks/useTypedContext';
import { OperationalDataContext } from '../../../providers/OperationalDataProvider';
import { useMsDiffs } from '../../../components/ProcedureEventsTimeline/hooks/useMsDiffs';
import { addMilliseconds } from 'date-fns';
import { validateSubsetEmpty } from '../../../../../../../../../helpers/utils/validators';
import { useIntl } from 'react-intl';
import { _getMsFromTimeInterval } from '../../ManageProcedureModal/components/ProcedureRelatedFields/hooks/useValidate';
import { cloneDeep } from 'lodash';

export interface IManageActivityFormHookValues
  extends IFormHookValues<ManageActivityFormValues> {
  handleDelete: () => void;
}

export type ManageActivityFormValues = {
  procedureId: string;
  procedureDisabled: boolean;
  startMs: string;
  activityId?: string;
};

export const useForm = (
  msPoint: number,
  activityIndex: number | undefined,
  form: FormikProps<AwareCaseDetailsFormValues>,
  procedures: ICaseProcedureWithActivitiesLocalized[],
  onAction: () => void
): IManageActivityFormHookValues => {
  const intl = useIntl();

  const initialValues = useInitialValues(
    msPoint,
    activityIndex,
    form,
    procedures
  );

  const { awareSessionDetails } = useTypedContext(OperationalDataContext);

  const { getMsDiff } = useMsDiffs(
    awareSessionDetails.start_date,
    awareSessionDetails.end_date
  );

  const proceduresDiffs = useProceduresDiffs(form);
  const getActivitiesDiffs = useActivitiesDiffs(form);

  const validation = useCallback(
    (values: typeof initialValues): FormikErrors<ManageActivityFormValues> => {
      const emptyValues = validateSubsetEmpty<typeof initialValues>(
        values,
        ['procedureId', 'startMs'],
        intl
      );

      if (Object.keys(emptyValues).length !== 0) return emptyValues;

      const startMsDiff = getMsDiff(
        awareSessionDetails.start_date,
        values.startMs
      );

      if (values.procedureDisabled) {
        if (values.activityId === undefined) return { activityId: '' };

        const procedureIndex = proceduresDiffs.find(
          (p) => p.startDiff <= msPoint && p.endDiff >= msPoint
        )?.index;

        if (procedureIndex === undefined)
          return { procedureId: '' } as FormikErrors<ManageActivityFormValues>;

        let activitiesDiffs = getActivitiesDiffs(procedureIndex);

        if (activityIndex !== undefined)
          activitiesDiffs = activitiesDiffs.filter(
            (_, idx) => idx !== activityIndex
          );

        return activitiesDiffs.some((p) => p.startDiff === startMsDiff)
          ? ({ startMs: '' } as FormikErrors<ManageActivityFormValues>)
          : ({} as FormikErrors<ManageActivityFormValues>);
      }

      return proceduresDiffs.some(
        (p) => p.startDiff <= startMsDiff && p.endDiff >= startMsDiff
      )
        ? ({ procedureId: '' } as FormikErrors<ManageActivityFormValues>)
        : {};
    },
    [
      activityIndex,
      awareSessionDetails.start_date,
      getActivitiesDiffs,
      getMsDiff,
      intl,
      msPoint,
      proceduresDiffs,
    ]
  );

  const handleDelete = useCallback(() => {
    const procedureIndex = proceduresDiffs.find(
      (p) => p.startDiff <= msPoint && p.endDiff >= msPoint
    )?.index;

    if (procedureIndex === undefined || activityIndex === undefined) return;

    let proceduresCopy = cloneDeep(form.values.procedures);

    proceduresCopy = proceduresCopy.map((item, idx) => {
      if (idx !== procedureIndex) return item;

      return {
        ...item,
        activities: item.activities.filter(
          (_, activityIdx) => activityIdx !== activityIndex
        ),
      };
    });

    form.setFieldValue('procedures', proceduresCopy);
    onAction();
  }, [form, proceduresDiffs, msPoint, activityIndex, onAction]);

  const handleSubmit = useCallback(
    (values: typeof initialValues) => {
      const startMsDiff = _getMsFromTimeInterval(values.startMs);

      if (values.procedureDisabled) {
        const procedureIndex = proceduresDiffs.find(
          (p) => p.startDiff <= msPoint && p.endDiff >= msPoint
        )?.index;

        if (procedureIndex === undefined) return;

        const procedureId = form.values.procedures[procedureIndex].id;
        const procedure = procedures.find((p) => p.id === procedureId);

        if (!procedure) return;

        const procedureActivities = procedure.activities;
        const selectedActivity = procedureActivities.find(
          (p) => p.id === values.activityId
        );

        if (!selectedActivity) return;

        if (activityIndex !== undefined) {
          form.setFieldValue(
            `procedures[${procedureIndex}].activities[${activityIndex}]`,
            {
              id: selectedActivity.id,
              nameEn: selectedActivity.name_en,
              nameNo: selectedActivity.name_no,
              color: selectedActivity.hex_color,
              timeStart: addMilliseconds(
                new Date(awareSessionDetails.start_date),
                startMsDiff
              ).toISOString(),
              timeEnd: addMilliseconds(
                new Date(awareSessionDetails.start_date),
                startMsDiff
              ).toISOString(),
            }
          );

          onAction();
          return;
        }

        form.setFieldValue(`procedures[${procedureIndex}]`, {
          ...form.values.procedures[procedureIndex],
          activities: [
            ...form.values.procedures[procedureIndex].activities,
            {
              id: selectedActivity.id,
              nameEn: selectedActivity.name_en,
              nameNo: selectedActivity.name_no,
              color: selectedActivity.hex_color,
              timeStart: addMilliseconds(
                new Date(awareSessionDetails.start_date),
                startMsDiff
              ).toISOString(),
              timeEnd: addMilliseconds(
                new Date(awareSessionDetails.start_date),
                startMsDiff
              ).toISOString(),
            },
          ],
        });

        onAction();
        return;
      }

      const closestNextProcedure = proceduresDiffs.find(
        (p) => p.startDiff > startMsDiff
      );

      const selectedProcedure = procedures.find(
        (p) => p.id === values.procedureId
      );

      const selectedActivity = selectedProcedure?.activities.find(
        (p) => p.id === values.activityId
      );
      if (!selectedProcedure) return;

      form.setFieldValue(`procedures`, [
        ...form.values.procedures,
        {
          id: selectedProcedure.id,
          nameEn: selectedProcedure.name_en,
          nameNo: selectedProcedure.name_no,
          timeStart: addMilliseconds(
            new Date(awareSessionDetails.start_date),
            startMsDiff
          ).toISOString(),
          timeEnd: closestNextProcedure
            ? addMilliseconds(
                new Date(awareSessionDetails.start_date),
                closestNextProcedure.startDiff - 1
              ).toISOString()
            : awareSessionDetails.end_date,
          activities: !selectedActivity
            ? []
            : [
                {
                  id: selectedActivity.id,
                  nameEn: selectedActivity.name_en,
                  nameNo: selectedActivity.name_no,
                  color: selectedActivity.hex_color,
                  timeStart: addMilliseconds(
                    new Date(awareSessionDetails.start_date),
                    startMsDiff
                  ).toISOString(),
                  timeEnd: addMilliseconds(
                    new Date(awareSessionDetails.start_date),
                    startMsDiff
                  ).toISOString(),
                },
              ],
        },
      ]);
      onAction();
    },
    [
      activityIndex,
      awareSessionDetails.end_date,
      awareSessionDetails.start_date,
      form,
      msPoint,
      onAction,
      procedures,
      proceduresDiffs,
    ]
  );

  return {
    initialValues,
    validation,
    handleSubmit,
    handleDelete,
  };
};
