import { useCallback } from 'react';
import { FormikProps } from 'formik';
import { AwareCaseDetailsFormValues } from '../../../../../forms/AwareCaseDetailsForm/types';
import { _getMsFromTimeInterval, useValidate } from './useValidate';
import { cloneDeep } from 'lodash';
import { useTypedContext } from '../../../../../../../../../../../hooks/useTypedContext';
import { OperationalDataContext } from '../../../../../providers/OperationalDataProvider';
import { addMilliseconds } from 'date-fns';

export const useHandleSave = (
  timeStart: string,
  timeEnd: string,
  form: FormikProps<AwareCaseDetailsFormValues>,
  procedureIndex: number,
  onError: (translationId: string) => void,
  onSave: () => void
) => {
  const { awareSessionDetails } = useTypedContext(OperationalDataContext);

  const handleValidate = useValidate(
    timeStart,
    timeEnd,
    form.values.procedures,
    procedureIndex
  );

  const { setFieldValue } = form;

  return useCallback(() => {
    const errorId = handleValidate();
    if (errorId) return onError(errorId);

    let selectedProcedure = cloneDeep(
      form.values.procedures.at(procedureIndex)
    );

    if (!selectedProcedure) return onError('Cases.ProcedureNotFound');

    const startMsToAdd = _getMsFromTimeInterval(timeStart);
    const endMsToAdd = _getMsFromTimeInterval(timeEnd);

    selectedProcedure.timeStart = addMilliseconds(
      new Date(awareSessionDetails.start_date),
      startMsToAdd
    ).toISOString();

    selectedProcedure.timeEnd = addMilliseconds(
      new Date(awareSessionDetails.start_date),
      endMsToAdd
    ).toISOString();

    const newTimeStart = selectedProcedure.timeStart;
    const newTimeEnd = selectedProcedure.timeEnd;

    selectedProcedure.activities = selectedProcedure.activities.filter(
      (p) => new Date(p.timeStart) >= new Date(newTimeStart)
    );

    selectedProcedure.activities = selectedProcedure.activities.filter(
      (p) => new Date(p.timeStart) <= new Date(newTimeEnd)
    );

    if (selectedProcedure.activities.length > 0) {
      selectedProcedure.activities[
        selectedProcedure.activities.length - 1
      ].timeEnd = newTimeEnd;
    }

    setFieldValue(`procedures[${procedureIndex}]`, selectedProcedure);
    onSave();
  }, [
    awareSessionDetails.start_date,
    form.values.procedures,
    handleValidate,
    onError,
    onSave,
    procedureIndex,
    setFieldValue,
    timeEnd,
    timeStart,
  ]);
};
