import { getUrlFromDiscoveryService } from 'api/membership';
import { getApiUrl } from 'api/utils/request';

// COMPONENTS:
import FeedbackModal, {
  GraspFeedbackBodyImportanceWithNumber,
} from 'components/FeedbackModal';
import { Formik } from 'formik';

//helpers
import useOpen from 'hooks/useOpen';
import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import useLogin from '../../../../api/mutations/membership/useLogin';
import { LoginMethod } from '../../../../interfaces/membership';
import LoginFormFormik from './LoginFormFormik';

export const discoveryService = async (fb: () => void) => {
  let apiUrl = getApiUrl();

  if (apiUrl && !localStorage.getItem('api_url')) {
    localStorage.setItem('api_url', apiUrl);
  }

  if (!apiUrl) {
    const { data } = await getUrlFromDiscoveryService();
    if (data.url) localStorage.setItem('api_url', data.url);
  }

  fb();
};

export type LoginHandleSubmitFunctionParams = {
  username: string;
  password: string;
};

const initialValues: LoginHandleSubmitFunctionParams = {
  username: '',
  password: '',
};

type LoginFormProps = {
  loginErrorCode: string | null;
  loginAs: LoginMethod;
};

const LoginForm = ({ loginErrorCode, loginAs }: LoginFormProps) => {
  const intl = useIntl();

  const {
    mutate: loginMutation,
    isSuccess,
    isLoading,
    isError,
  } = useLogin({
    loginAs: loginAs,
  });

  const handleSubmit = (values: LoginHandleSubmitFunctionParams) => {
    loginMutation({
      username: values.username,
      password: values.password,
    });
  };

  const { isOpen, toggle } = useOpen();

  const feedbackModalInitialValues: Partial<GraspFeedbackBodyImportanceWithNumber> =
    useMemo(
      () => ({
        category: 'Bug',
        description: `${intl.formatMessage({
          id: 'Login.LoginForm.errorCode.name',
        })} ${loginErrorCode}`,
        importance: 2,
        email_contact: '',
        subject: intl.formatMessage({
          id: 'Login.LoginForm.errorCode.message',
        }),
      }),
      [loginErrorCode, intl]
    );

  return (
    <>
      <FeedbackModal
        isOpen={isOpen}
        toggle={toggle}
        initialValues={feedbackModalInitialValues}
      />
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(props) => (
          <LoginFormFormik
            props={props}
            handleReportIssue={toggle}
            isFailure={isError}
            isLoading={isLoading}
            isSuccess={isSuccess}
            loginErrorCode={loginErrorCode}
          />
        )}
      </Formik>
    </>
  );
};

export default LoginForm;
