import { IQueryRequest } from '../../../interfaces/request';
import { OrganizationQueryParams } from '../../../interfaces/queryParams';
import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getDashboardTemplates } from '../../dashboards';

type DashboardTemplatesQueryParams = OrganizationQueryParams & {
  isAvailableOnMobile?: boolean;
};

const useDashboardTemplates = ({
  params,
  options,
}: IQueryRequest<DashboardTemplatesQueryParams>) => {
  const { organizationId, isAvailableOnMobile } = params;

  return useQuery({
    ...options,
    queryKey: [
      QueryKeys.getDashboardTemplates,
      organizationId,
      isAvailableOnMobile,
    ],
    queryFn: async () => {
      const { data } = await getDashboardTemplates(
        organizationId,
        isAvailableOnMobile ?? null
      );
      return data.data;
    },
  });
};

export default useDashboardTemplates;
