import useOpen from '../../../../../hooks/useOpen';
import { useCallback } from 'react';
import _ from 'lodash';

export const useFieldApplicableInput = <T>(
  initialValues: T,
  setField: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void,
  propertyName: string
) => {
  const { isOpen: isEditMode, toggle: toggleEditMode } = useOpen();

  const onUndo = useCallback(() => {
    setField(propertyName, _.get(initialValues, propertyName));
  }, [initialValues, propertyName, setField]);

  const onClose = useCallback(() => {
    toggleEditMode();
  }, [toggleEditMode]);

  return {
    isEditMode,
    toggleEditMode,
    onUndo,
    onClose,
  };
};
