import React from 'react';
import { Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import TextField from '@mui/material/TextField';
import { OpenHookValues } from '../../../../../../../../../../hooks/useOpen';
import { FormikProps } from 'formik';
import { AwareCaseDetailsFormValues } from '../../../../forms/AwareCaseDetailsForm/types';

type PatientNumberFieldProps = {
  editMode: OpenHookValues;
  form: FormikProps<AwareCaseDetailsFormValues>;
};

const PatientNumberField = ({ editMode, form }: PatientNumberFieldProps) => {
  const intl = useIntl();

  return (
    <div className="flex items-center gap-2">
      <Typography variant="body1">
        <FormattedMessage
          id="General.SemicolonLabel"
          values={{ label: intl.formatMessage({ id: 'Patient.Patient' }) }}
        />
      </Typography>
      {editMode.isOpen ? (
        <TextField
          variant="standard"
          type="number"
          id="patient_number"
          name="patient_number"
          placeholder={intl.formatMessage({
            id: 'General.InsertNumber',
          })}
          value={form.values.patient_number}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          error={
            form.touched.patient_number && Boolean(form.errors.patient_number)
          }
        />
      ) : (
        <Typography
          variant="subtitle1"
          sx={{
            overflow: 'hidden',
            textWrap: 'nowrap',
            textOverflow: 'ellipsis',
          }}
        >
          {!!form.values.patient_number ? form.values.patient_number : '--'}
        </Typography>
      )}
    </div>
  );
};

export default PatientNumberField;
