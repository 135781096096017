import { useCallback } from 'react';
import { ProcedureField } from '../../../../../forms/AwareCaseDetailsForm/types';
import { useTypedContext } from '../../../../../../../../../../../hooks/useTypedContext';
import { OperationalDataContext } from '../../../../../providers/OperationalDataProvider';
import { useMsDiffs } from '../../../../../components/ProcedureEventsTimeline/hooks/useMsDiffs';

const timeValueRegex = /^\d\d:\d\d:\d\d$/i;

export const _getMsFromTimeInterval = (time: string) => {
  const timeSplit = time.split(':');

  const seconds =
    parseInt(timeSplit[0]) * 3600 +
    parseInt(timeSplit[1]) * 60 +
    parseInt(timeSplit[2]);

  return seconds * 1000;
};

export const useValidate = (
  timeStart: string,
  timeEnd: string,
  procedures: ProcedureField[],
  index: number
) => {
  const { awareSessionDetails } = useTypedContext(OperationalDataContext);
  const { getMsDiff, baseMsDiff } = useMsDiffs(
    awareSessionDetails.start_date,
    awareSessionDetails.end_date
  );

  const isOverlapping = useCallback(
    (procedure: ProcedureField) => {
      const startMsDiff = getMsDiff(
        awareSessionDetails.start_date,
        procedure.timeStart
      );
      const endMsDiff = getMsDiff(
        awareSessionDetails.start_date,
        procedure.timeStart
      );

      const timeStartMsDiff = _getMsFromTimeInterval(timeStart);
      const timeEndMsDiff = _getMsFromTimeInterval(timeEnd);

      return !(timeEndMsDiff < startMsDiff || timeStartMsDiff > endMsDiff);
    },
    [awareSessionDetails.start_date, getMsDiff, timeEnd, timeStart]
  );

  return useCallback((): string | undefined => {
    if (!timeValueRegex.test(timeStart) || !timeValueRegex.test(timeEnd))
      return 'General.InvalidFieldValue';

    const startMsPoint = _getMsFromTimeInterval(timeStart);
    const endMsPoint = _getMsFromTimeInterval(timeEnd);

    if (
      startMsPoint < 0 ||
      endMsPoint > baseMsDiff ||
      startMsPoint >= endMsPoint
    )
      return 'Cases.DatesOutOfRange';

    const remainingProcedures = procedures.filter((_, idx) => idx !== index);

    for (const remainingProcedure of remainingProcedures) {
      if (!isOverlapping(remainingProcedure)) continue;
      return 'Cases.RangesOverlaps';
    }

    return undefined;
  }, [baseMsDiff, index, isOverlapping, procedures, timeEnd, timeStart]);
};
