import React from 'react';
import useCaseDetails from '../../../../../../api/queries/cases/useCaseDetails';
import QueryLoaded from '../../../../../../components/QueryLoaded';
import FullScreenSpinner from '../../../../../../components/Spinners/FullScreenSpinner';
import { useOrganizationCasePathParams } from '../../../../../../hooks/paths/useOrganizationCasePathParams';
import { useInitQuery } from '../../../../../../hooks/queries/useInitQuery';
import { ICaseDetails } from '../../../../../../interfaces/cases';

type CaseDetailsProviderProps = {
  children: (data: ICaseDetails) => JSX.Element;
};

const CaseDetailsProvider = ({ children }: CaseDetailsProviderProps) => {
  const { organizationId, caseId } = useOrganizationCasePathParams();

  const query = useCaseDetails({
    params: { organizationId, caseId },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(query);

  return (
    <QueryLoaded query={query} onLoading={<FullScreenSpinner />}>
      {(details) => children(details)}
    </QueryLoaded>
  );
};

export default CaseDetailsProvider;
