import CustomBarChart from 'components/CustomBarChart';
import CustomPieChart from 'components/CustomPieChart';
import React from 'react';
import EmptyInformation from '../../../../EmptyInformation';

type SqueezeSummaryEmptyProps = {
  width: number;
  height: number;
  innerRadius: number;
  summaryText: string;
};

const SqueezeSummaryEmpty = ({
  summaryText,
  ...chartProps
}: SqueezeSummaryEmptyProps) => {
  return (
    <>
      <div className="mt-1 w-100 d-flex flex-wrap align-items-center justify-content-around">
        <CustomPieChart
          marginForCircle={10}
          totalText={summaryText}
          {...chartProps}
          total={0}
          items={[
            {
              name: 'No data',
              value: 1,
            },
          ]}
          displayInside
        />
        <CustomBarChart
          total={0}
          items={[
            {
              name: 'No data',
              value: 0,
            },
          ]}
          width={200}
          height={20}
          isEmpty
        />
        <EmptyInformation type="range" />
      </div>
    </>
  );
};

export default SqueezeSummaryEmpty;
