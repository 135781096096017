import InputFieldWithImage, {
  InputFieldWithImageColor,
} from 'components/InputFieldWithImage';
//images
import caseIcon from 'assets/images/case_icon.svg';
import { FormGroup, Input, Label } from 'reactstrap';
import { ActivityTypes } from 'interfaces/activities';
import { FormattedMessage, useIntl } from 'react-intl';
import FilterInput from 'components/Filters/FilterInput';
import useOpen from 'hooks/useOpen';
import { useCallback, useMemo, useState } from 'react';
import FilterDisplay from 'components/Filters/FilterDisplay';
import { DateRange } from 'react-day-picker';
import CustomDatePicker from 'components/CustomDatePicker';
import { TimeRange } from 'interfaces/timeline';
import { returnNewDateBaseOnTimeRange } from 'helpers/utils/dates';

type EventLogFiltersProps = {
  search: string;
  setSearch: (val: string) => void;
  selectedEventTypes: ActivityTypes[];
  setSelectedEventTypes: (activities: ActivityTypes[]) => void;
  resetEventTypes: () => void;
  availableEventTypes: ActivityTypes[];
  selectedDates: DateRange;
  setSelectedDates: (dateRange: DateRange) => void;
  displaySearch: boolean;
};

const EventLogFilters = ({
  setSearch,
  search,
  resetEventTypes,
  availableEventTypes,
  selectedDates,
  setSelectedDates,
  selectedEventTypes,
  setSelectedEventTypes,
  displaySearch,
}: EventLogFiltersProps) => {
  const [localEventTypes, setLocalEventTypes] =
    useState<ActivityTypes[]>(availableEventTypes);

  const [selectedTimeRange, setSelectedTimeRange] = useState<TimeRange | null>(
    null
  );

  const onPredefineValueClick = (value: string) => {
    const timeRange = value as TimeRange;

    setSelectedTimeRange(timeRange);

    setSelectedDates(returnNewDateBaseOnTimeRange(timeRange));
  };

  const toggleEventType = useCallback((type: ActivityTypes) => {
    setLocalEventTypes((currentSelected) => {
      if (currentSelected.includes(type))
        return [...currentSelected].filter((selected) => selected !== type);

      return [...currentSelected, type];
    });
  }, []);

  const intl = useIntl();

  const { isOpen, toggle } = useOpen(false);

  const clearFunction = () => setSearch('');

  const onChange = (ev) => {
    setSearch(ev.target.value);
  };

  const renderedFilterBody = useMemo(() => {
    return (
      <FormGroup>
        {availableEventTypes.map((eventType) => {
          const handleToggleEventType = () => {
            toggleEventType(eventType);
          };

          return (
            <FormGroup key={eventType} check>
              <Input
                id={`checkbox-${eventType}`}
                type="checkbox"
                checked={localEventTypes.includes(eventType)}
                onChange={handleToggleEventType}
              />
              <Label check>
                <FormattedMessage id={`EventScreen.eventType.${eventType}`} />
              </Label>
            </FormGroup>
          );
        })}
      </FormGroup>
    );
  }, [availableEventTypes, toggleEventType, localEventTypes]);

  const onAcceptClick = () => {
    setSelectedEventTypes(localEventTypes);
    toggle();
  };

  const filtersLength = selectedEventTypes.length;
  const filterString = selectedEventTypes
    .map((eventType) =>
      intl.formatMessage({ id: `EventScreen.eventType.${eventType}` })
    )
    .join(', ');

  return (
    <div className="w-100 d-flex">
      {displaySearch && (
        <div className="me-2">
          <InputFieldWithImage
            color={InputFieldWithImageColor.gray}
            image={<img src={caseIcon} alt="search_icon" />}
            labelTranslationId="CaseCard.searchText"
            canClear={true}
            clearFunction={clearFunction}
          >
            <Input
              className="form-control"
              onChange={onChange}
              placeholder={intl.formatMessage({
                id: 'CaseCard.searchText',
              })}
              value={search}
            />
          </InputFieldWithImage>
        </div>
      )}
      <FormGroup className="me-2">
        <Label className="d-flex">Selected events</Label>
        <FilterInput
          toggle={toggle}
          isOpen={isOpen}
          targetId="cases-event-filter-input-target"
          displayComponent={
            <FilterDisplay
              targetId="cases-event-filter-input-target"
              filterString={filterString}
              filtersLength={filtersLength}
            />
          }
          titleTranslationId="Projects.filter.filters"
          filterBody={renderedFilterBody}
          onAcceptClick={onAcceptClick}
          resetFilters={resetEventTypes}
        />
      </FormGroup>
      <FormGroup>
        <Label>Date filter</Label>
        <CustomDatePicker
          hideTime
          onDateAccept={setSelectedDates}
          selectedRange={selectedDates}
          selectedTimeRange={selectedTimeRange}
          onPredefineValueClick={onPredefineValueClick}
          oneColumn
          hideLastActive
          defaultLastActive={false}
        />
      </FormGroup>
    </div>
  );
};

export default EventLogFilters;
