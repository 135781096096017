import React from 'react';
import PageHeader from '../../../../../../components/PageHeader';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';
import ChangeCaseDetailsTabs from './tabs/ChangeCaseDetailsTabs';

const ChangeCaseDetailsView = () => {
  const breadcrumbs = useBreadcrumbs();

  return (
    <PageHeader breadcrumbs={breadcrumbs} content={<ChangeCaseDetailsTabs />} />
  );
};

export default ChangeCaseDetailsView;
