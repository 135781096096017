import { ThemeProvider } from '@mui/material';
import React from 'react';
import NotAuthorized from '../../../../../../components/NotAuthorized';
import {
  checkUserRole,
  isOrganizationAdminForOrganizationProvided,
} from '../../../../../../helpers/utils/auth';
import { useOrganizationPathParams } from '../../../../../../hooks/paths/useOrganizationPathParams';
import { UserRoles } from '../../../../../../interfaces/membership';
import { OrganizationType } from '../../../../../../interfaces/organizations';
import { ThemeGenerators } from '../../../../../../themes/generators';
import DentalOrganizationDetails from '../DentalOrganizationDetails';
import GenericOrganizationDetails from '../GenericOrganizationDetails';

type OrganizationDetailsProps = {
  type: OrganizationType;
};

const OrganizationDetails = ({ type }: OrganizationDetailsProps) => {
  const { organizationId } = useOrganizationPathParams();
  let organizationDetailsContent = <GenericOrganizationDetails />;

  if (type === OrganizationType.DentalClinic) {
    organizationDetailsContent = (
      <ThemeProvider theme={ThemeGenerators.AwareThemeGenerator()}>
        <DentalOrganizationDetails />
      </ThemeProvider>
    );
  }

  const isRoleMatched = checkUserRole(UserRoles.ORGANIZATION_ADMIN);

  if (
    !isRoleMatched ||
    !isOrganizationAdminForOrganizationProvided(organizationId)
  )
    return <NotAuthorized />;

  return <div className="mt-5">{organizationDetailsContent}</div>;
};

export default OrganizationDetails;
