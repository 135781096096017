import React from 'react';
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { Field, Formik } from 'formik';
import { IBaseEducationMaterialCategory } from '../../../../../../interfaces/educationMaterials';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBooksMedical,
  faEdit,
  faSave,
  faUndo,
} from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage } from 'react-intl';
import LoadingButton from '../../../../../../components/LoadingButton';
import FieldInput from '../../../../../../components/FieldInput';
import InputFieldWithoutImage from '../../../../../../components/InputFieldWithoutImage';
import FormikErrorMessage from '../../../../../../components/FormikErrorMessage';
import FieldSelect from '../../../../../../components/FieldSelect';
import { useEducationMaterialCategoryModal } from './hooks/useEducationMaterialCategoryModal';
import MaterialTranslationsForm from '../../../../modals/components/MaterialTranslationsForm';
import { useTranslations } from '../../../../../../hooks/useTranslations';

type EducationMaterialCategoryModalProps = {
  isOpen: boolean;
  toggle: () => void;
  category?: IBaseEducationMaterialCategory;
  parentCategoryId?: string;
};

const EducationMaterialCategoryModal = ({
  isOpen,
  toggle,
  category,
  parentCategoryId,
}: EducationMaterialCategoryModalProps) => {
  const { attachPrefix, wrapWithIntl } = useTranslations(
    'Education.EducationMaterialCategoriesTab.EducationMaterialCategoryModal'
  );

  const { initialValues, handleSubmit, validation, submitState, options } =
    useEducationMaterialCategoryModal(category, parentCategoryId, toggle);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="GenericModal BorderlessModal"
      size="lg"
    >
      <ModalHeader toggle={toggle}>
        {!category ? (
          <>
            <FontAwesomeIcon icon={faBooksMedical} />
            <FormattedMessage id={attachPrefix('addCategory')} />
          </>
        ) : (
          <>
            <FontAwesomeIcon icon={faEdit} />
            <FormattedMessage id={attachPrefix('editCategory')} />
          </>
        )}
      </ModalHeader>
      <Formik
        validate={validation}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({
          submitForm,
          isValid,
          values,
          setValues,
          resetForm,
          dirty,
          isSubmitting,
        }) => {
          const handleReset = () => resetForm();

          return (
            <>
              <ModalBody className="d-flex flex-column gap-3">
                <InputFieldWithoutImage
                  gray={true}
                  noMargin={true}
                  labelTranslationId={attachPrefix('nameLabel')}
                  inputId="name"
                  errorInfo={<FormikErrorMessage name="name" />}
                >
                  <Field
                    id="name"
                    name="name"
                    placeholder={wrapWithIntl(attachPrefix('namePlaceholder'))}
                    type="text"
                    component={FieldInput}
                  />
                </InputFieldWithoutImage>
                <InputFieldWithoutImage
                  gray={true}
                  noMargin={true}
                  labelTranslationId={attachPrefix('descriptionLabel')}
                  inputId="description"
                  errorInfo={<FormikErrorMessage name="description" />}
                >
                  <Field
                    id="description"
                    name="description"
                    placeholder={wrapWithIntl(
                      attachPrefix('descriptionPlaceholder')
                    )}
                    type="text"
                    component={FieldInput}
                  />
                </InputFieldWithoutImage>
                <InputFieldWithoutImage
                  gray={true}
                  noMargin={true}
                  labelTranslationId={attachPrefix('sequenceLabel')}
                  inputId="sequence"
                  errorInfo={<FormikErrorMessage name="sequence" />}
                >
                  <Field
                    id="sequence"
                    name="sequence"
                    placeholder={wrapWithIntl(
                      attachPrefix('sequencePlaceholder')
                    )}
                    type="number"
                    component={FieldInput}
                  />
                </InputFieldWithoutImage>
                <InputFieldWithoutImage
                  gray={true}
                  noMargin={true}
                  labelTranslationId={attachPrefix('iconUrlLabel')}
                  inputId="icon_url"
                  errorInfo={<FormikErrorMessage name="icon_url" />}
                >
                  <Field
                    id="icon_url"
                    name="icon_url"
                    placeholder={wrapWithIntl(
                      attachPrefix('iconUrlPlaceholder')
                    )}
                    type="text"
                    component={FieldInput}
                  />
                </InputFieldWithoutImage>
                <InputFieldWithoutImage
                  gray={true}
                  noMargin={true}
                  labelTranslationId={attachPrefix('parentCategoryLabel')}
                  inputId="parent_category_id"
                  errorInfo={<FormikErrorMessage name="parent_category_id" />}
                >
                  <Field
                    id="parent_category_id"
                    name="parent_category_id"
                    type="select"
                    defaultText={wrapWithIntl(
                      attachPrefix('parentCategoryPlaceholder')
                    )}
                    options={options.categories}
                    required={false}
                    component={FieldSelect}
                  />
                </InputFieldWithoutImage>
                <div className="d-flex align-items-center gap-2">
                  <Field
                    id="is_premium"
                    name="is_premium"
                    type="checkbox"
                    checked={values.is_premium}
                    component={FieldInput}
                  />
                  <Label htmlFor="is_premium" className="mb-0">
                    <FormattedMessage id={attachPrefix('isPremiumLabel')} />
                  </Label>
                </div>
                <div className="mt-5">
                  <MaterialTranslationsForm
                    values={values}
                    setValues={setValues}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <LoadingButton
                  isLoading={isSubmitting}
                  isSuccess={submitState.isSuccess}
                  isFailure={submitState.isError}
                  disabled={!isValid || !dirty}
                  color="primary"
                  onClick={submitForm}
                >
                  <FontAwesomeIcon icon={faSave} />
                </LoadingButton>
                <Button color="primary" outline onClick={handleReset}>
                  <FontAwesomeIcon icon={faUndo} />
                </Button>
              </ModalFooter>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default EducationMaterialCategoryModal;
