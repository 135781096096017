import React from 'react';
import { Row } from 'reactstrap';
import classNames from 'classnames';
import { useRenderCharts } from './hooks/useRenderCharts';
import { useCommonData } from '../../../../hooks/useCommonData';
import { useBrowserState } from '../../../../../../store/reducerHooks';

export enum TrendsTabMode {
  AllIncludingExercises = 'AllIncludingExercises',
  ExercisesOnly = 'ExercisesOnly',
}

const TrendsTab = () => {
  const { userCaseDetails } = useCommonData();
  const renderedTrendsChart = useRenderCharts();
  const isBrowser = useBrowserState();

  if (userCaseDetails.isLoading || !userCaseDetails.data) return <></>;

  return (
    <div>
      <Row
        className={classNames(
          'CaseDashboardTabs__trends-tab TrendsTab mt-3 gap-2 gap-lg-0',
          {
            'mb-5 pb-5': !isBrowser,
          }
        )}
      >
        {renderedTrendsChart}
      </Row>
    </div>
  );
};

export default TrendsTab;
