import { useCallback, useEffect, useState } from 'react';
import { useCommonParams } from '../../../../../hooks/useCommonParams';
import useDownloadReport from '../../../../../../../api/queries/reports/useDownloadReport';
import { IReport } from '../../../../../../../interfaces/reports';

export const useHandleDownloadReport = (report: IReport) => {
  const { organizationId } = useCommonParams();

  const [selectedExecutionId, setSelectedExecutionId] = useState<
    string | undefined
  >(undefined);

  const downloadReportQuery = useDownloadReport({
    params: {
      organizationId,
      executionId: selectedExecutionId ?? '',
    },
    options: {
      enabled: false,
      staleTime: Infinity,
    },
  });

  useEffect(() => {
    (async () => {
      if (!selectedExecutionId) return;
      await downloadReportQuery.refetch();
      setSelectedExecutionId(undefined);
    })();
  }, [selectedExecutionId, downloadReportQuery]);

  const handleDownloadReport = useCallback(() => {
    setSelectedExecutionId(report.id);
  }, [report.id]);

  return {
    downloadReport: downloadReportQuery,
    handleDownloadReport,
    selectedExecutionId,
  };
};
