import { useInitQueries } from '../../../../../../../hooks/queries/useInitQuery';
import { useMemo } from 'react';
import { useCommonData } from './useCommonData';
import { useTranslations } from '../../../../../../../hooks/useTranslations';

export const useBreadcrumbs = () => {
  const { organizationDetails, caseDetails } = useCommonData();
  useInitQueries([organizationDetails, caseDetails]);

  const { attachPrefix, wrapWithIntl } = useTranslations('Cases.AwareCase');

  const breadcrumbs = useMemo(
    () => [
      {
        text: wrapWithIntl(attachPrefix('organization')),
        link: null,
        key: 'organization',
      },
      {
        text: organizationDetails.data?.name ?? '',
        link: `/my-organizations/${organizationDetails.data?.id}`,
        key: 'organization-name',
      },
      {
        text: wrapWithIntl(attachPrefix('cases')),
        link: null,
        key: 'cases',
      },
      {
        text: caseDetails.data?.title ?? '',
        link: null,
        key: 'case',
      },
    ],
    [
      attachPrefix,
      caseDetails.data?.title,
      organizationDetails.data?.id,
      organizationDetails.data?.name,
      wrapWithIntl,
    ]
  );

  return {
    breadcrumbs,
    isLoading:
      organizationDetails.isLoading || organizationDetails.isRefetching,
  };
};
