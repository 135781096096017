import { useCallback, useState } from 'react';

export type ContentIndexHookValues = {
  index: number | undefined;
  clearIndex: () => void;
  selectIndex: (index: number) => void;
};

export const useContentIndex = (): ContentIndexHookValues => {
  const [contentIndex, setContentIndex] = useState<number | undefined>(
    undefined
  );

  const clearContentIndex = useCallback(() => {
    setContentIndex(undefined);
  }, []);

  const selectContentIndex = useCallback((index: number) => {
    setContentIndex(index);
  }, []);

  return {
    index: contentIndex,
    clearIndex: clearContentIndex,
    selectIndex: selectContentIndex,
  };
};
