import React from 'react';
import SVG from 'react-inlinesvg';
import pencilFormIcon from 'assets/images/edit_icon.svg';
import { useTranslations } from '../../hooks/useTranslations';

const ItemEditPlaceholder = () => {
  const { providerInvariant } = useTranslations();

  return (
    <div className="ConversationTemplateItemEditPlaceholder">
      <SVG
        src={pencilFormIcon}
        title="Edit"
        className="ConversationTemplateItemEditPlaceholder__icon"
      />
      <span className="ConversationTemplateItemEditPlaceholder__text">
        {providerInvariant.wrapWithIntl(
          'MyOrganizations.conversationTemplates.phone.editItemPlaceholder'
        )}
      </span>
    </div>
  );
};

export default ItemEditPlaceholder;
