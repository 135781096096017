import { useInitQueries } from 'hooks/queries/useInitQuery';
import { StrictDateRange } from 'interfaces/dates';
import React, { createContext } from 'react';
import { DateRange } from 'react-day-picker';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'reactstrap';
import DateSelectionWrapper from './components/DateSelectionWrapper';
import DayDetails from './components/DayDetails';
import MessagesSent from './components/MessagesSent';
import Trends from './components/Trends';
import { useCommonData } from './hooks/useCommonData';

export const ChangeCaseDashboardDateRangeContext = createContext<
  StrictDateRange | DateRange | null
>(null);

const DashboardTab = () => {
  const {
    caseDetails,
    casePhaseHistory,
    conversationQuestions,
    observationMeanings,
    trendVisualizationTemplate,
  } = useCommonData();

  useInitQueries([
    caseDetails,
    casePhaseHistory,
    conversationQuestions,
    observationMeanings,
    trendVisualizationTemplate,
  ]);

  return (
    <div>
      <Row>
        <Col xs={12}>
          <DateSelectionWrapper>
            {(props) => (
              <ChangeCaseDashboardDateRangeContext.Provider value={props}>
                <Row className="mt-3">
                  <Col lg={8} md={12}>
                    <div className="d-flex flex-column gap-2">
                      <span className="fs-4 mt-3">
                        <FormattedMessage id="Cases.ChangeCase.ChangeCaseDashboard.trends" />
                      </span>
                      <Trends />
                    </div>
                  </Col>
                  <Col lg={4} md={12}>
                    <DayDetails />
                  </Col>
                </Row>
                <Row className="p-4">
                  <Col lg={6} md={12}>
                    <MessagesSent />
                  </Col>
                </Row>
              </ChangeCaseDashboardDateRangeContext.Provider>
            )}
          </DateSelectionWrapper>
        </Col>
      </Row>
    </div>
  );
};

export default DashboardTab;
