import {
  AssessmentCompletionStatus,
  AssessmentFormRedirectionState,
} from './assessmentForms';
import { CaseStatus, ICaseDetails } from './cases';
import { IBaseOrganization } from './organizations';
import { DoctorPersonalityInTimeline } from './personalities';
import { TimelineTabsValues } from './ui';

export interface ITimeline {
  id: string;
  name: string;
  description: string;
}

export type ITimelineObservationDates = {
  first_observation_at: string | null;
  last_observation_at: string | null;
};

export interface ITimelineExtended extends ITimeline {
  first_observation_at: string | null;
  last_observation_at: string | null;
  type: string | null;
  is_master?: boolean;
  is_shared: boolean;
  is_default: boolean;
  organization: IBaseOrganization;
}

export interface IUserTimeline extends ITimelineExtended {
  case_owner_personality: DoctorPersonalityInTimeline | null;
  case_status: CaseStatus | null;
}

export interface ITimelineDetails extends ITimelineExtended {
  tags: string[];
  user_id: string;
  created_at: string | null;
  date_to: string | null;
  date_from: string;
}

export interface IObservation {
  id: string;
  user_id: string | null;
  timestamp: string;
  amplitudes: number[];
  level: number;
  level_category: 'Hard' | 'Medium' | 'Low';
  description: string;
  duration_in_seconds: number;
  sample_rate_in_hz: number;
  children_observations: IObservation[];
  meaning_id: string | null;
  meaning_name: string | null;
  meaning_color: string | null;
  is_group: boolean;
  symptom: ObservationSymptom;
  category: ObservationCategory;
  source: ObservationSource;
  is_alarm_incident: boolean;
}

export enum ObservationSymptom {
  NotSpecified = 'NotSpecified',
  SymptomPain = 'SymptomPain',
  SymptomSeverePain = 'SymptomSeverePain',
  SymptomTiredness = 'SymptomTiredness',
  SymptomDrowsiness = 'SymptomDrowsiness',
  SymptomNausea = 'SymptomNausea',
  SymptomAppetiteLack = 'SymptomAppetiteLack',
  SymptomBreathShortness = 'SymptomBreathShortness',
  SymptomDepression = 'SymptomDepression',
  SymptomAnxiety = 'SymptomAnxiety',
  MicroChoiceTemptation = 'MicroChoiceTemptation',
  MicroChoiceChoiceMade = 'MicroChoiceChoiceMade',
  Calibration = 'Calibration',
  Other = 'Other',
}

export enum Granularity {
  h1 = 'h1',
  h2 = 'h2',
  h4 = 'h4',
  d1 = 'd1',
  d7 = 'd7',
  m1 = 'm1',
}

export enum TimeRange {
  HOURS_24 = 'Day1',
  DAYS_3 = 'Days3',
  DAYS_7 = 'Days7',
  DAYS_30 = 'Days30',
  MONTHS_3 = 'Months3',
  MONTHS_6 = 'Months6',
  YEAR = 'Year',
  YEARS_3 = 'Years3',
  CASE_PERIOD = 'CasePeriod',
}

export type paramsForTimelineSqueezeChart = {
  granularity: Granularity;
  from?: string;
  to?: string;
  groupBy?: SqueezeQuantityGrouping;
};

export type GetTimelineObservationsQueryParams = {
  grouped: boolean;
  granularity?: Granularity;
};

export type ISeriesSqueezeChart =
  | ISeriesTypeBasedSqueezeChart
  | ISeriesMeaningBasedSqueezeChart;

export interface ISeriesTypeBasedSqueezeChart extends IBaseSeriesSqueezeChart {
  meaning_id: null;
  meaning_name_en: null;
  meaning_name_no: null;
  meaning_color: null;
}

export interface ISeriesMeaningBasedSqueezeChart
  extends IBaseSeriesSqueezeChart {
  meaning_id: string;
  meaning_name_en: string;
  meaning_name_no: string;
  meaning_color: string;
}

export interface IBaseSeriesSqueezeChart {
  name: string;
  mode: string;
  data: number[];
}

export interface ISqueezeChartData {
  x_axis: string[];
  x_axis_label: string;
  y_axis_label: string;
  series: ISeriesSqueezeChart[];
}

export enum Meaning {
  NotSpecified = 'NotSpecified',
  SymptomPain = 'SymptomPain',
  SymptomSeverePain = 'SymptomSeverePain',
  SymptomTiredness = 'SymptomTiredness',
  SymptomDrowsiness = 'SymptomDrowsiness',
  SymptomNausea = 'SymptomNausea',
  SymptomAppetiteLack = 'SymptomAppetiteLack',
  SymptomBreathShortness = 'SymptomBreathShortness',
  SymptomDepression = 'SymptomDepression',
  SymptomAnxiety = 'SymptomAnxiety',
  MicroChoiceTemptation = 'MicroChoiceTemptation',
  MicroChoiceChoiceMade = 'MicroChoiceChoiceMade',
  Calibration = 'Calibration',
  Other = 'Other',
}

export enum ObservationCategory {
  GraspUpload = 'GraspUpload',
  CalibrationMax = 'CalibrationMax',
  CalibrationMin = 'CalibrationMin',
  CalibrationSoft = 'CalibrationSoft',
  CalibrationMedium = 'CalibrationMedium',
  CalibrationHard = 'CalibrationHard',
}

export enum ObservationSource {
  Grasp = 'Grasp',
  Manual = 'Manual',
}

export enum Sentiments {
  NotSpecified = 'NotSpecified',
  Negative = 'Negative',
  Neutral = 'Neutral',
  Positive = 'Positive',
}

export type BaseSentiments = Omit<Sentiments, 'NotSpecified'>;

export interface ITimelineAnnotation {
  id: string;
  is_members: boolean;
  timeline: {
    id: string;
    name: string;
    description: string;
  };
  text: string;
  time_start: string;
  time_end: string;
}

export type AnnotationRedirectionState = {
  annotationId: string;
};

export interface IAnnotationBody {
  text: string;
  time_start: string;
  time_end: string;
}

export interface ITimelineObservationSummary {
  name: string;
  value: number;
}

export type TimelineTabsProps = {
  timelineId: string;
  caseDedicatedTimelineId?: string;
  formRedirectionState?: AssessmentFormRedirectionState;
  annotationRedirectionState?: AnnotationRedirectionState;
  caseData?: ICaseDetails;
};

export enum GraspEventItemType {
  ANNOTATION = 'annotation',
  CASE_HISTORY = 'caseHistory',
  OBSERVATION = 'observation',
}

export type GraspEventItemData = {
  type: GraspEventItemType;
  beginDate: Date;
  endDate: Date;
  meaning?: Meaning;
  id: string;
  text: string;
  description: string;
  is_group: boolean;
  children_observations: GraspEventItemData[];
  level?: number;
  duration_in_seconds?: number;
  onEditClick?: () => void;
};

export const mappedTimelinesValues = [
  TimelineTabsValues.DAY_BY_DAY,
  TimelineTabsValues.OVERVIEW,
  TimelineTabsValues.TRENDS_TIMELINE,
  TimelineTabsValues.CASE_DETAILS,
];

export interface IObservationCreateBody {
  timestamp: string;
  level: number;
  description: string;
  meaning: string;
}

export enum BubbleType {
  HISTORY_ENTRY = 'historyEntry',
  ANNOTATION = 'annotation',
  OBSERVATION = 'observation',
  FORM = 'form',
}

type BubbleData = {
  x: number;
  y: number;
  z: number;
  date: Date;
  type: BubbleType;
};

export interface ExtendedBubbleData extends BubbleData {
  text: string;
  endDate: Date;
}
export interface ObservationBubbleData extends BubbleData {
  amplitudes: number[];
  text: string;
  painScaleLevel: number;
  painScaleCategory: 'Hard' | 'Medium' | 'Low';
  symptom: ObservationSymptom;
}

export function instanceOfObservationBubbleData(
  object: any
): object is ObservationBubbleData {
  return object.symptom !== undefined;
}

export function instanceOfExtendedBubbleData(
  object: any
): object is ExtendedBubbleData {
  return object.endDate !== undefined && object.text !== undefined;
}

export function instanceOfFormBubbleData(
  object: any
): object is FormBubbleData {
  return (
    object.title &&
    //sequence 0 is false value
    object.sequence !== undefined &&
    //text can be null
    object.text !== undefined &&
    object.completionStatus !== undefined &&
    object.canOpen !== undefined &&
    object.canEdit !== undefined &&
    object.isCompleted !== undefined
  );
}

export interface AnnotationBubbleData extends ExtendedBubbleData {}

export interface HistoryBubbleData extends ExtendedBubbleData {}
export interface FormBubbleData extends BubbleData {
  text: string;
  isCompleted: boolean;
  canOpen: boolean;
  canEdit: boolean;
  completionStatus: AssessmentCompletionStatus;
  title: string;
  sequence: number;
  contextType: string | null;
  contextObjectId: string | null;
}

export type SingleDataBubbleValue =
  | ObservationBubbleData
  | AnnotationBubbleData
  | HistoryBubbleData
  | FormBubbleData;

export type AmplitudeModalData = {
  amplitudes: number[];
  painLevel: number;
  duration: number;
};

export type TimeInfo = {
  haveBorder: boolean;
  dateValue: string | null;
  label: string | JSX.Element;
  iconSrc: string;
};

export interface IUpdateObservationsGroup {
  description: string;
  meaning: Meaning;
  observation_ids: string[];
}

export interface IDeleteObservationsGroup {
  observation_ids: string[];
}

export enum SqueezeQuantityGrouping {
  Strength = 'strength',
  Meaning = 'meaning',
}
