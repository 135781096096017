import { useQuery } from 'react-query';
import { getConversationAnswerHistory } from '../../conversations';
import QueryKeys from '../queryKeys';
import {
  CaseQueryParams,
  PersonalityQueryParams,
} from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { cloneDeep } from 'lodash';
import { UserConversationHistoryContextType } from '../../../interfaces/conversations';

type UseConversationAnswerHistoryParams = CaseQueryParams &
  PersonalityQueryParams & {
    searchValue: string;
    templateIds: string[];
    contextType?: UserConversationHistoryContextType;
  };

const useConversationAnswerHistory = ({
  params,
  options,
}: IQueryRequest<UseConversationAnswerHistoryParams>) => {
  const { organizationId, caseId, personalityId, contextType } = params;

  let orderedTemplateIds = cloneDeep(params.templateIds);
  orderedTemplateIds.sort((a, b) => a.localeCompare(b));

  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getConversationAnswerHistory(
        organizationId,
        caseId,
        personalityId,
        orderedTemplateIds,
        contextType
      );

      return data.data;
    },
    queryKey: [
      QueryKeys.getConversationAnswerHistory,
      organizationId,
      caseId,
      personalityId,
      orderedTemplateIds,
      contextType,
    ],
  });
};

export default useConversationAnswerHistory;
