import {
  normalizeQueryParams,
  normalizeQueryParamsArray,
  QueryParamsArrayItem,
} from '../helpers/utils/normalizeQueryParams';
import {
  DashboardReportSchedule,
  DashboardTemplate,
  IMemberDashboardReport,
  IUpsertDashboardReportSchedule,
} from '../interfaces/dashboards';
import { IGrasp } from '../interfaces/grasps';
import { IOrganizationMembers } from '../interfaces/organizations';
import { IReferenced, IResponse, ITotalRowsPage } from '../interfaces/response';
import request from './utils/request';

export const getDashboardReportSchedules = (organizationId: string) => {
  return request<undefined, IResponse<DashboardReportSchedule[]>>({
    url: `organizations/${organizationId}/dashboard_report_schedules`,
    method: 'GET',
  });
};

export const getDashboardTemplates = (
  organizationId: string,
  isAvailableOnMobile: boolean | null
) => {
  return request<undefined, IResponse<DashboardTemplate[]>>({
    url: `organizations/${organizationId}/dashboard_templates${normalizeQueryParams(
      { is_available_on_mobile: isAvailableOnMobile }
    )}`,
    method: 'GET',
  });
};

export const getDashboardTemplate = (
  organizationId: string,
  templateId: string
) => {
  return request<undefined, IResponse<DashboardTemplate>>({
    url: `organizations/${organizationId}/dashboard_templates/${templateId}`,
    method: 'GET',
  });
};

export const getDashboardDataQuery = (
  organizationId: string,
  templateId: string,
  params: QueryParamsArrayItem[]
) => {
  return request<undefined, IResponse<IReferenced<ITotalRowsPage<any>>>>({
    url: `organizations/${organizationId}/dashboard_templates/${templateId}/data_query${normalizeQueryParamsArray(
      params
    )}`,
    method: 'GET',
  });
};

export const getMemberDashboardReportsWithReferencePeriod = (
  organizationId: string,
  memberId: string,
  params: QueryParamsArrayItem[]
) => {
  return request<
    undefined,
    IResponse<IReferenced<ITotalRowsPage<IMemberDashboardReport>>>
  >({
    url: `organizations/${organizationId}/members/${memberId}/dashboard_reports/reference_period${normalizeQueryParamsArray(
      params
    )}`,
    method: 'GET',
  });
};

export const getDashboardTemplateOrganizationsMembers = (
  organizationId: string,
  templateId: string
) => {
  return request<undefined, IResponse<IOrganizationMembers[]>>({
    url: `organizations/${organizationId}/dashboard_templates/${templateId}/organization_members`,
    method: 'GET',
  });
};

export const getDashboardTemplateOrganizationsGrasps = (
  organizationId: string,
  templateId: string
) => {
  return request<undefined, IResponse<IGrasp[]>>({
    url: `organizations/${organizationId}/dashboard_templates/${templateId}/organization_grasps`,
    method: 'GET',
  });
};

export const upsertDashboardReportSchedule = (
  organizationId: string,
  body: IUpsertDashboardReportSchedule
) =>
  request<IUpsertDashboardReportSchedule, void>({
    url: `/organizations/${organizationId}/dashboard_report_schedules`,
    data: body,
    method: 'PUT',
  });

export const deleteDashboardReportSchedule = (
  organizationId: string,
  id: string
) =>
  request({
    url: `/organizations/${organizationId}/dashboard_report_schedules/${id}`,
    method: 'DELETE',
  });
