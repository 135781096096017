import { deleteAnnotation } from 'api/graspManagement';
import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';

function useDeleteAnnotation(successFunction?: () => void) {
  return useMutationWithResultNotification({
    asyncApiFunction: async (annotationId: string) => {
      await deleteAnnotation(annotationId);
    },
    resetOnResult: true,
    onSuccess: () => {
      successFunction && successFunction();
      displaySuccessNotification(
        'TimelineDetails.AnnotationEdit.deleteSuccess'
      );
    },
    onErrorArray: (errors) =>
      displayErrorArrayNotifications(
        errors,
        'TimelineDetails.AnnotationEdit.errors'
      ),
  });
}

export default useDeleteAnnotation;
