import { getUserContextAssessmentFormQuestionsHistory } from 'api/cases';
import QueryKeys from 'api/queries/queryKeys';
import { UserQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { useQuery } from 'react-query';

export type UseUserContextAssessmentFormsQuestionHistoryForm = {
  formCode: string;
  questions: string[];
};

interface UseUserContextAssessmentFormsQuestionHistoryParams
  extends UserQueryParams {
  dateFrom: string | null;
  dateTo: string | null;
  forms: UseUserContextAssessmentFormsQuestionHistoryForm[];
}

const useUserContextAssessmentFormsQuestionHistory = ({
  params,
  options,
}: IQueryRequest<UseUserContextAssessmentFormsQuestionHistoryParams>) => {
  const { userId, dateFrom, dateTo, forms } = params;
  return useQuery({
    ...options,
    queryKey: [
      QueryKeys.getUserContextAssessmentFormQuestionsHistory,
      userId,
      dateFrom,
      dateTo,
      ...forms.map((form) => form.formCode),
      ...forms.map((form) => form.questions.join(',')),
    ],
    queryFn: async () => {
      const { data: responseData } =
        await getUserContextAssessmentFormQuestionsHistory(userId, {
          dateFrom: dateFrom,
          dateTo: dateTo,
          forms: forms,
        });

      return responseData.data;
    },
  });
};

export default useUserContextAssessmentFormsQuestionHistory;
