import React from 'react';
import CustomTable, { headerItem } from '../../../../../components/CustomTable';
import Skeleton from 'react-loading-skeleton';

type PatientCustomTablePlaceholderProps = {
  headerItems: headerItem[];
  rowsCount: number;
};

const PatientCustomTablePlaceholder = ({
  headerItems,
  rowsCount,
}: PatientCustomTablePlaceholderProps) => {
  const bodyRows = [...Array(rowsCount).keys()].map((idx) => {
    const columns: JSX.Element[] = [
      <td key={`${idx}-first_name`}>
        <Skeleton width={'100%'} height={20} />
      </td>,
      <td key={`${idx}-last_name`}>
        <Skeleton width={'100%'} height={20} />
      </td>,
      <td key={`${idx}-email`}>
        <Skeleton width={'100%'} height={20} />
      </td>,
      <td key={`${idx}-phone_number`}>
        <Skeleton width={'100%'} height={20} />
      </td>,
      <td key={`${idx}-patient_number`}>
        <Skeleton width={'100%'} height={20} />
      </td>,
      <td key={`${idx}-gender`}>
        <Skeleton width={'100%'} height={20} />
      </td>,
      <td key={`${idx}-date_of_birth`}>
        <Skeleton width={'100%'} height={20} />
      </td>,
    ];

    return (
      <tr key={idx} className="cursor-pointer">
        {columns}
      </tr>
    );
  });

  return <CustomTable headerItems={headerItems} bodyRows={bodyRows} />;
};

export default PatientCustomTablePlaceholder;
