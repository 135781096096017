import { ReportParameter, ReportTemplateCode } from 'interfaces/reports';
import React from 'react';
import GenerateReportForm from './GenerateReportForm';

type GenerateObservationDataReportFormProps = {
  fillInitialParameters: (
    parameters: ReportParameter[],
    code: ReportTemplateCode
  ) => void;
};

const GenerateObservationDataReportForm = ({
  fillInitialParameters,
}: GenerateObservationDataReportFormProps) => {
  return (
    <GenerateReportForm
      code={ReportTemplateCode.OBSERVATION_DATA}
      fillInitialParameters={fillInitialParameters}
    />
  );
};

export default GenerateObservationDataReportForm;
