import React from 'react';
import { DashboardTemplateContainerProps } from '../../index';
import { useOrganizationPathParams } from '../../../../../../../../hooks/paths/useOrganizationPathParams';
import { useInitQuery } from '../../../../../../../../hooks/queries/useInitQuery';
import QueryLoaded from '../../../../../../../QueryLoaded';
import useDashboardTemplates from '../../../../../../../../api/queries/dashboards/useDashboardTemplates';
import FromTemplate from './components/FromTemplate';

type FromTemplatesProps = DashboardTemplateContainerProps;

const FromTemplates = ({ onNotFound, children }: FromTemplatesProps) => {
  const { organizationId } = useOrganizationPathParams();

  const dashboardTemplates = useDashboardTemplates({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(dashboardTemplates);

  const errorHandler = (_: any) => <>{onNotFound}</>;

  return (
    <QueryLoaded query={dashboardTemplates} onError={errorHandler}>
      {(templates) => (
        <FromTemplate
          templates={templates}
          onNotFound={onNotFound}
          children={children}
        />
      )}
    </QueryLoaded>
  );
};

export default FromTemplates;
