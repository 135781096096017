import { faUser } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';

type PersonalityImageProps = {
  targetId?: string;
  tooltipContent?: string;
  size?: 'big' | 'small';
};

const PersonalityImage = ({
  tooltipContent,
  targetId,
  size = 'small',
}: PersonalityImageProps) => {
  return (
    <>
      <div
        className={`personality-image personality-image--${size}`}
        id={targetId}
      >
        <FontAwesomeIcon icon={faUser} />
      </div>
      {targetId && (
        <UncontrolledTooltip target={targetId} placement="top">
          {tooltipContent}
        </UncontrolledTooltip>
      )}
    </>
  );
};

export default PersonalityImage;
