import React from 'react';
import { FormHookProps } from '../../../../../../../../interfaces/forms';
import { MaterialModalFormValues } from '../../../../types';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import { useHandleClose } from './hooks/useHandleClose';

type UploadAssetsStepProps = FormHookProps<MaterialModalFormValues>;

const UploadAssetsStep = ({ form }: UploadAssetsStepProps) => {
  const handleClose = useHandleClose(form);

  return (
    <>
      <Button color="primary" outline={true} onClick={handleClose}>
        <FontAwesomeIcon icon={faSave} />
      </Button>
    </>
  );
};

export default UploadAssetsStep;
