import { Button } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import React, { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import useLoginData from '../../../../../../../../api/mutations/membership/useLoginData';
import useRegisterUser from '../../../../../../../../api/mutations/membership/useRegisterUser';
import useCreateOrganization from '../../../../../../../../api/mutations/organizations/useCreateOrganization';
import HorizontalTabContainer from '../../../../../../../../components/Tabs/components/HorizontalTabContainer';
import { useTypedContext } from '../../../../../../../../hooks/useTypedContext';
import { ILoginResponse } from '../../../../../../../../interfaces/membership';
import { OrganizationRegisterStepsReducerContext } from '../../store/reducers';

const ConfirmationStep = () => {
  const [state] = useTypedContext(OrganizationRegisterStepsReducerContext);

  const navigate = useNavigate();
  const openInsights = () => navigate('/login');

  const { mutateAsync: createAsync, isLoading: isCreating } =
    useCreateOrganization({
      options: {},
      params: {},
      silent: true,
    });

  const onLoginAsync = async (data: ILoginResponse) => {
    await createAsync({
      ...state.createOrganization,
      code: state.createOrganization.code.replaceAll(' ', ''),
      author_id: data.user.id,
    });
  };

  const { mutateAsync: loginDataAsync, isLoading: isLogging } = useLoginData({
    params: {},
    options: {
      successFb: onLoginAsync,
    },
    silent: true,
  });

  const onRegisterAsync = useCallback(async () => {
    await loginDataAsync({
      username: state.signUp.email,
      password: state.signUp.password,
    });
  }, [loginDataAsync, state.signUp.email, state.signUp.password]);

  const { mutateAsync: registerAsync, isLoading: isRegistering } =
    useRegisterUser(undefined, onRegisterAsync, true, true);

  const handleCreate = useCallback(async () => {
    await registerAsync(state.signUp);
  }, [registerAsync, state.signUp]);

  const isLoading = [isCreating, isLogging, isRegistering].some((p) => p);

  useEffect(() => {
    handleCreate().then((_) => {});
  }, [handleCreate]);

  if (isLoading)
    return (
      <div className="d-flex align-items-center justify-content-center flex-column gap-2 w-100 h-100">
        <CircularProgress />
        <Typography variant="h5" gutterBottom>
          <FormattedMessage id="Organizations.RegisteringOrganization" />
        </Typography>
      </div>
    );

  return (
    <HorizontalTabContainer
      items={[
        {
          header: (
            <FormattedMessage
              id="General.HiUser"
              values={{
                firstName: state.signUp.first_name,
                lastName: state.signUp.last_name,
              }}
            />
          ),
          component: (
            <div className="d-flex flex-column justify-content-between h-100">
              <div className="d-flex flex-column gap-4 h-100 pt-4 mt-3">
                <Typography variant="body1" gutterBottom>
                  <FormattedMessage id="Organizations.YouHaveCreatedOrganization" />
                </Typography>
                <Typography
                  variant="h3"
                  sx={{ fontFamily: 'Ubuntu' }}
                  gutterBottom
                >
                  {state.createOrganization.name}
                </Typography>
                <Typography variant="body1" gutterBottom>
                  <FormattedMessage id="Organizations.YouMayNowExploreOrganization" />
                </Typography>
              </div>
              <div className="d-flex justify-content-end">
                <Button onClick={openInsights} variant="contained">
                  <FormattedMessage id="Organizations.OpenInsights" />
                </Button>
              </div>
            </div>
          ),
          shouldBeRendered: true,
        },
      ]}
    />
  );
};

export default ConfirmationStep;
