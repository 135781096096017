import { createContext } from 'react';
import { IAsyncFormHookValues } from 'interfaces/forms';
import { TrendVisualizationTemplate } from 'interfaces/trendVisualizations';
import { FormikProps } from 'formik';

export const TrendVisualizationModalFormContext = createContext<
  IAsyncFormHookValues<TrendVisualizationTemplate> | undefined
>(undefined);

export const TrendVisualizationModalFormikContext = createContext<
  FormikProps<TrendVisualizationTemplate> | undefined
>(undefined);
