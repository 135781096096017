import { IPatchObject } from '../interfaces/request';

export const getPatchObject = <T extends {}>(
  data: T,
  initialData: T,
  excludedKeys: string[] = []
) => {
  let patchObject: IPatchObject[] = [];

  Object.entries(data).forEach(([key, val]) => {
    if (val === initialData[key] || excludedKeys.includes(key)) return;

    patchObject.push({
      op: 'replace',
      path: `/${key}`,
      value: val as any,
    });
  });

  return patchObject;
};
