import { useMemo } from 'react';
import { StrictDateRange } from '../../../../../../../interfaces/dates';
import { differenceInDays, subDays } from 'date-fns';
import { SelectedDateContext } from '../../../types';
import { useTypedContext } from '../../../../../../../hooks/useTypedContext';

export const useDates = () => {
  const dateContext = useTypedContext(SelectedDateContext);

  return useMemo((): StrictDateRange => {
    const caseStartDaysDiff = differenceInDays(
      dateContext.selected.item,
      dateContext.caseStart
    );

    if (caseStartDaysDiff >= 14 && caseStartDaysDiff <= 45)
      return { from: dateContext.caseStart, to: dateContext.selected.item };

    if (caseStartDaysDiff < 14)
      return {
        from: subDays(dateContext.selected.item, 14),
        to: dateContext.selected.item,
      };

    return {
      from: subDays(dateContext.selected.item, 45),
      to: dateContext.selected.item,
    };
  }, [dateContext.caseStart, dateContext.selected.item]);
};
