import {
  QuestionBasedTrendsChartElement,
  TrendsChartConfig,
} from '../../../../../types';
import { TrendVisualizationChartType } from '../../../../../../../interfaces/trendVisualizations';
import { getLineChartFigureItem } from './getLineChartFigureItem';
import { getBarChartFigureItem } from './getBarChartFigureItem';

export const getQuestionsFigureItem = (
  item: QuestionBasedTrendsChartElement,
  config: TrendsChartConfig,
  isSelected: (item: string) => boolean,
  mobile: boolean
) => {
  let elements: any[] = [];

  item.questionIds.forEach((questionId) => {
    const questionColor = config.questions.find(
      (p) => p.id === questionId
    )?.color;

    if (item.chart_type === TrendVisualizationChartType.LineChart) {
      elements.push(
        getLineChartFigureItem({
          lineKey: `line|${item.type}|${questionId}`,
          dataKey: questionId,
          connectNulls: item.generate_points_nearby.find(
            (p) => p.question_id === questionId
          )?.enabled,
          stroke: questionColor,
          hide: !isSelected(questionId),
          dot: !mobile,
        })
      );

      return;
    }

    elements.push(
      getBarChartFigureItem({
        barKey: `bar|${item.type}|${questionId}`,
        dataKey: questionId,
        fill: questionColor,
        hide: !isSelected(questionId),
      })[0]
    );
  });

  return elements;
};
