import { ChartsData, TrendsChartData } from '../types';
import { TemplateItemType } from '../../../interfaces/change';
import { useMemo } from 'react';

export const useNonExerciseObservations = (
  enabled: boolean,
  allObservations: TrendsChartData<string | number>,
  exerciseObservations: TrendsChartData<string | number>
): TrendsChartData<string | number> => {
  return useMemo(() => {
    if (!enabled)
      return { type: TemplateItemType.NonExerciseObservations, data: [] };

    let _data: ChartsData<string | number>[] = [];

    allObservations.data.forEach((item, index) => {
      let entry: ChartsData<string | number> = {};
      entry.name = item.name;

      const nonNameEntries = Object.entries(item).filter(([k]) => k !== 'name');

      nonNameEntries.forEach(([itemKey, itemVal]) => {
        const exerciseItem = exerciseObservations.data.at(index)?.[itemKey];
        entry[itemKey] = (itemVal as number) - ((exerciseItem ?? 0) as number);
      });

      _data.push(entry);
    });

    return { type: TemplateItemType.NonExerciseObservations, data: _data };
  }, [allObservations.data, enabled, exerciseObservations.data]);
};
