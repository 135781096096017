import { differenceInSeconds, intervalToDuration } from 'date-fns';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { UseQueryResult } from 'react-query';
import { Col, Row } from 'reactstrap';
import useExerciseSessions from '../../../../api/queries/exerciseSessions/useExerciseSessions';
import { CasesHelpers } from '../../../../helpers/data/cases';
import { getDateRangeForDate } from '../../../../helpers/dates';
import useAsyncEventListen from '../../../../hooks/events/useAsyncEventListen';
import { useInitQuery } from '../../../../hooks/queries/useInitQuery';
import { useTranslations } from '../../../../hooks/useTranslations';
import { ICaseDetails } from '../../../../interfaces/cases';
import ExerciseTimePlaceholder from '../ExerciseTimePlaceholder';

type ExerciseTimeProgressProps = {
  date: Date;
  caseDetails: UseQueryResult<ICaseDetails>;
};

const ExerciseTimeProgress = ({
  date,
  caseDetails,
}: ExerciseTimeProgressProps) => {
  const dateRange = getDateRangeForDate(date);

  const { attachPrefix } = useTranslations(
    'CaseDashboard.CaseDashboardTabs.DayDetailsTab.ExerciseTime.ExerciseTimeProgress'
  );

  const userId = caseDetails.data
    ? CasesHelpers.GetUserIdBasedOnCase(caseDetails.data)
    : null;

  const exercisesQuery = useExerciseSessions({
    params: { userId: userId ?? '', from: dateRange.from, to: dateRange.to },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(caseDetails);
  useInitQuery(exercisesQuery, !!userId);

  useAsyncEventListen(
    'exercise-time-refetch-requested',
    async () => {
      await exercisesQuery.refetch();
    },
    true
  );

  const exercisesActivitySeconds = useMemo(() => {
    let seconds: number = 0;

    if (!exercisesQuery.data) return 0;

    exercisesQuery.data.forEach((exercise) => {
      seconds += differenceInSeconds(
        new Date(exercise.time_end),
        new Date(exercise.time_start)
      );
    });

    return seconds;
  }, [exercisesQuery.data]);

  const duration = (seconds: number) =>
    intervalToDuration({ start: 0, end: seconds * 1000 });

  const activityDuration = useMemo(
    () => duration(exercisesActivitySeconds),
    [exercisesActivitySeconds]
  );

  if (exercisesQuery.isLoading) return <ExerciseTimePlaceholder />;

  return (
    <div className="d-flex flex-column justify-content-center align-items-start w-100 h-100 mt-4 py-3 mt-md-0">
      <Row className="w-100 py-3 py-md-0">
        <Col xs={6} className="d-flex flex-column align-items-center gap-1">
          <span className="ExerciseTimeProgress__value">
            {exercisesQuery.data?.length ?? 0}
          </span>
          <span className="ExerciseTimeProgress__subtitle">
            <FormattedMessage id={attachPrefix('numberOfSessions')} />
          </span>
        </Col>
        <Col xs={6} className="d-flex flex-column align-items-center gap-1">
          <span className="ExerciseTimeProgress__value">
            <FormattedMessage
              id={attachPrefix('time')}
              values={{
                hours:
                  activityDuration?.hours?.toString().padStart(2, '0') ?? '00',
                minutes:
                  activityDuration?.minutes?.toString().padStart(2, '0') ??
                  '00',
                seconds:
                  activityDuration?.seconds?.toString().padStart(2, '0') ??
                  '00',
              }}
            />
          </span>
          <span className="ExerciseTimeProgress__subtitle">
            <FormattedMessage id={attachPrefix('totalTime')} />
          </span>
        </Col>
      </Row>
    </div>
  );
};

export default ExerciseTimeProgress;
