import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { createCaseGroupSession } from '../../caseGroups';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { ICreateCaseGroupSession } from '../../../interfaces/caseGroups';
import { IMutationProps } from '../../../interfaces/ui';

type useCreateCaseGroupSessionParams = {
  organizationId: string;
  projectId: string;
  groupId: string;
};

const useCreateCaseGroupSession = (
  params: useCreateCaseGroupSessionParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (body: ICreateCaseGroupSession) => {
      await createCaseGroupSession(
        params.organizationId,
        params.projectId,
        params.groupId,
        body
      );
    },
    onSuccess: () => {
      if (options.successFb) options.successFb();

      displaySuccessNotification('CaseGroupItem.createSessionSuccess');
    },
    onError: (errorArray) => {
      if (options.errorFb) options.errorFb();

      displayErrorArrayNotifications(
        errorArray,
        'CaseGroupItem.createSessionError'
      );
    },
    resetOnResult: true,
  });
};

export default useCreateCaseGroupSession;
