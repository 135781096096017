import { editLocation } from 'api/membership';
import { IMutationProps } from 'interfaces/ui';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

type PatchParams = {
  endpointId: string;
  name: string;
  description: string;
};

function useEditLocation(organizationId: string, options?: IMutationProps) {
  const { successFb } = options || {};

  return useMutationWithResultNotification({
    asyncApiFunction: async ({
      endpointId,
      name,
      description,
    }: PatchParams) => {
      await editLocation(organizationId, endpointId, name, description);
    },
    resetOnResult: true,
    onSuccess: () => {
      displaySuccessNotification('MyOrganizations.endpoints.editSuccess');
      successFb && successFb();
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'MyOrganizations.endpoints.editErrors'
      );
    },
  });
}

export default useEditLocation;
