import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { uploadAssetThumbnail } from '../../assets';
import { IAsset } from '../../../interfaces/educationMaterials';

const useUploadAssetThumbnail = (
  organizationId: string,
  assetId: string,
  onSuccess?: (asset: IAsset) => void
) => {
  const mutationData = useMutationWithResultNotification({
    asyncApiFunction: async (file: File) => {
      const { data } = await uploadAssetThumbnail(
        organizationId,
        assetId,
        file
      );

      return data.data;
    },
    resetOnResult: false,
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'Api.Mutations.Assets.UploadAssetThumbnail.failure'
      );
    },
    onSuccess: () => {
      if (mutationData.data) {
        onSuccess?.(mutationData.data);
        mutationData.reset();
      }

      displaySuccessNotification(
        'Api.Mutations.Assets.UploadAssetThumbnail.success'
      );
    },
  });

  return mutationData;
};

export default useUploadAssetThumbnail;
