import { cloneDeep } from 'lodash';
import { useMemo } from 'react';
import { DatesHelpers } from '../../../../../helpers/dates';
import { Expressions } from '../../../../../helpers/expressions';
import useCultures from '../../../../../hooks/cultures/useCultures';
import {
  DashboardDatePeriod,
  DashboardTemplate,
  DashboardTemplateDatePeriod,
} from '../../../../../interfaces/dashboards';

export const useResolveDateSelection = (
  template: DashboardTemplate,
  scope: any
) => {
  const { currentCulture } = useCultures();

  const _scope = useMemo(() => {
    return {
      ...scope,
      dateMin: DatesHelpers.MinDate.toISOString(),
      dateMax: DatesHelpers.MaxDate.toISOString(),
    };
  }, [scope]);

  return useMemo(() => {
    let _template = cloneDeep(template);

    const dateSelection = _template.content.date_selection;

    if (!dateSelection) return _template;

    if (dateSelection.date_period === DashboardDatePeriod.CustomPeriod) {
      dateSelection.date_from = Expressions.HandlebarsEvaluate(
        dateSelection.date_from,
        _scope,
        currentCulture
      );
      dateSelection.date_to = Expressions.HandlebarsEvaluate(
        dateSelection.date_to,
        _scope,
        currentCulture
      );

      _template.content.date_selection = dateSelection;
      return _template;
    }

    dateSelection.date_period = Expressions.HandlebarsEvaluate(
      dateSelection.date_period,
      _scope,
      currentCulture
    ) as DashboardTemplateDatePeriod;

    _template.content.date_selection = dateSelection;
    return _template;
  }, [_scope, currentCulture, template]);
};
