import {
  IError,
  IInfiniteResult,
  IResponseWithPagination,
} from 'interfaces/response';
import { InfiniteData, UseInfiniteQueryResult } from 'react-query';

export const returnAllDataFromResponseData = <T>(
  data: InfiniteData<IInfiniteResult<T[]>> | undefined
) => {
  const allData: T[] = [];

  if (!data) return [];

  data.pages.forEach((page) => {
    page.data.forEach((shareData) => allData.push(shareData));
  });

  return allData;
};

export const returnAllDataFromResponse = <T>(
  param: UseInfiniteQueryResult<IInfiniteResult<T[]>, IError>
) => {
  const allData: T[] = [];
  if (param.data) {
    param.data.pages.forEach((page) => {
      page.data.forEach((shareData) => allData.push(shareData));
    });
  }

  return allData;
};

export const convertIntoObjectWithPageInfo = <T>(
  param: IResponseWithPagination<T>
) => {
  const result: IInfiniteResult<T> = {
    data: param.data,
    totalItemsCount: param.meta.pagination.total_items_count,
    nextPage: param.meta.pagination.next_page,
    previousPage: param.meta.pagination.previous_page,
  };

  return result;
};
