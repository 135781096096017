import React from 'react';
import { FieldProps } from 'formik';
import 'react-quill-emoji/dist/quill-emoji.css';
import Standalone, { FieldHtmlValue } from '../../Standalone/FieldHtmlEditor';
import { Label } from 'reactstrap';

interface FieldHtmlEditorProps extends Partial<FieldProps> {
  id?: string;
  placeholder?: string;
  disabled?: boolean;
  nonHtmlName?: string;
  condensed?: boolean;
  label?: string;
  className?: string;
}

const FieldHtmlEditor = ({
  id,
  field,
  form,
  disabled = false,
  label,
  className = '',
  nonHtmlName,
  placeholder = '',
  condensed = true,
}: FieldHtmlEditorProps) => {
  const onModify = (value: FieldHtmlValue) => {
    if (!form?.setFieldValue || !field?.name) return;
    form.setFieldValue(field.name, value.html, true);

    if (!nonHtmlName) return;
    form.setFieldValue(nonHtmlName, value.plain, true);
  };

  const nonHtmlField = form?.getFieldProps(nonHtmlName ?? '');

  const value: FieldHtmlValue = {
    html: field?.value ?? nonHtmlField?.value,
    plain: nonHtmlField?.value,
  };

  return withLabel(
    <div id={id}>
      <Standalone
        className={className}
        condensed={condensed}
        placeholder={placeholder}
        disabled={disabled}
        initialValue={value}
        onModify={onModify}
      />
    </div>,
    id,
    label
  );
};

const withLabel = (element: JSX.Element, id?: string, label?: string) => {
  if (!label || !id) return element;

  return (
    <div>
      <Label value={label} htmlFor={id}>
        {label}
      </Label>
      {element}
    </div>
  );
};

export default FieldHtmlEditor;
