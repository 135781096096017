import React from 'react';
import { useOrganizationPathParams } from '../../../../hooks/paths/useOrganizationPathParams';
import { useBrowserState } from '../../../../store/reducerHooks';
import useDashboardTemplates from '../../../../api/queries/dashboards/useDashboardTemplates';
import QueryLoaded from '../../../../components/QueryLoaded';
import DashboardSelection from './components/DashboardSelection';
import { useInitQuery } from '../../../../hooks/queries/useInitQuery';

const DashboardsList = () => {
  const { organizationId } = useOrganizationPathParams();
  const isBrowser = useBrowserState();
  const isAvailableOnMobile = isBrowser ? undefined : true;

  const dashboardTemplates = useDashboardTemplates({
    params: {
      organizationId,
      isAvailableOnMobile: isAvailableOnMobile,
    },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(dashboardTemplates);

  return (
    <QueryLoaded query={dashboardTemplates}>
      {(templates) => <DashboardSelection templates={templates} />}
    </QueryLoaded>
  );
};

export default DashboardsList;
