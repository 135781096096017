import { editAnnotation } from 'api/graspManagement';
import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import { IPatchObject } from 'interfaces/request';
import { IAnnotationBody } from 'interfaces/timeline';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';

type EditAnnotationParams = {
  annotationId: string;
  patchObjects: IPatchObject[];
};

function useTimelineAnnotationEdit(successFunction?: () => void) {
  return useMutationWithResultNotification<
    EditAnnotationParams,
    IAnnotationBody
  >({
    asyncApiFunction: async ({ annotationId, patchObjects }) => {
      const { data } = await editAnnotation(annotationId, patchObjects);
      return data.data;
    },
    onSuccess: () => {
      successFunction && successFunction();
      displaySuccessNotification('TimelineDetails.AnnotationEdit.editSuccess');
    },
    onErrorArray: (errors) =>
      displayErrorArrayNotifications(
        errors,
        'TimelineDetails.AnnotationEdit.errors'
      ),
    resetOnResult: true,
  });
}

export default useTimelineAnnotationEdit;
