import React, { useEffect, useState } from 'react';
import { Input } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import {
  AssetType,
  IAsset,
  IEducationMaterial,
} from '../../../../../../../../../../../../interfaces/educationMaterials';
import { useAssetIcon } from '../../hooks/useAssetIcon';
import { useTranslations } from '../../../../../../../../../../../../hooks/useTranslations';
import useCultures, {
  AvailableCulture,
} from '../../../../../../../../../../../../hooks/cultures/useCultures';
import EditModeItemCulture from './components/EditModeItemCulture';
import { useAssetUpload } from './hooks/useAssetUpload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faSpinnerThird,
  faTimes,
} from '@fortawesome/pro-light-svg-icons';

type EditModeItemProps = {
  asset: IAsset;
  material: IEducationMaterial;
  setMaterial: (material: IEducationMaterial) => void;
  disable: () => void;
};

const EditModeItem = ({
  asset,
  material,
  setMaterial,
  disable,
}: EditModeItemProps) => {
  const { cultures } = useCultures();

  const [modifiedAsset, setModifiedAsset] = useState<IAsset>(asset);

  useEffect(() => {
    setModifiedAsset(asset);
  }, [asset]);

  const { attachPrefix } = useTranslations(
    'Education.MaterialModal.MaterialModalBody.UploadAssetsStep.UploadAssetsStepItem.EditModeItem'
  );

  const assetIcon = useAssetIcon(
    modifiedAsset.type,
    modifiedAsset.thumbnail_url ?? undefined
  );

  const [selectedCultures, setSelectedCultures] =
    useState<AvailableCulture[]>(cultures);

  const {
    acceptType,
    fileString,
    transcoding,
    toggleTranscoding,
    onFileChange,
    isUploading,
    handleUpload,
  } = useAssetUpload(
    modifiedAsset,
    material,
    setMaterial,
    selectedCultures,
    disable
  );

  return (
    <div className="d-flex align-items-center border shadow rounded p-2 pt-3">
      <div className="py-3 px-4">{assetIcon}</div>
      <div className="w-100 d-flex flex-column">
        <div className="d-flex align-items-center gap-2">
          {cultures.map((culture) => (
            <EditModeItemCulture
              key={culture.cultureCode}
              culture={culture}
              modifiedAsset={modifiedAsset}
              setModifiedAsset={setModifiedAsset}
              cultures={selectedCultures}
              setCultures={setSelectedCultures}
            />
          ))}
        </div>
        <div className="mt-3">
          <div className="d-flex align-items-center gap-2">
            <Input
              className="w-auto"
              type="file"
              onChange={onFileChange}
              value={fileString}
              accept={acceptType}
            />
            {modifiedAsset.type === AssetType.Video && (
              <div className="d-flex gap-2 my-3">
                <Input
                  id="upload-video-asset-transcoding"
                  name="upload-video-asset-transcoding"
                  type="checkbox"
                  checked={transcoding}
                  onChange={toggleTranscoding}
                />
                <label htmlFor="upload-video-asset-transcoding">
                  <FormattedMessage id={attachPrefix('transcoding')} />
                </label>
              </div>
            )}
          </div>
          <span className="small">
            <FormattedMessage id={attachPrefix('uploadFileHint')} />
          </span>
        </div>
      </div>
      <div className="d-flex align-items-center gap-2 me-3 text-muted">
        {isUploading ? (
          <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin={true} />
        ) : (
          <FontAwesomeIcon icon={faCheck} size="lg" onClick={handleUpload} />
        )}
        <FontAwesomeIcon icon={faTimes} size="lg" onClick={disable} />
      </div>
    </div>
  );
};

export default EditModeItem;
