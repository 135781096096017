import { deleteGraspFromOrganization } from 'api/graspManagement';
import { IMutationProps } from 'interfaces/ui';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

function useDeleteGraspFromOrganization(
  organizationId: string,
  { successFb }: IMutationProps
) {
  return useMutationWithResultNotification({
    asyncApiFunction: async (graspsId: string) => {
      await deleteGraspFromOrganization(organizationId, graspsId);
    },
    resetOnResult: true,
    onSuccess: () => {
      successFb && successFb();
      displaySuccessNotification('MyOrganizations.grasps.deleteSuccess');
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'MyOrganizations.grasps.deleteErrors'
      );
    },
  });
}

export default useDeleteGraspFromOrganization;
