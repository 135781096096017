import { TrendsChartData, TrendsChartElement } from '../types';
import { useMemo } from 'react';

export const useSelectedData = (
  data: TrendsChartData<string | number>[],
  item: TrendsChartElement
) => {
  return useMemo(() => {
    return data.find((p) => p.type === item.type);
  }, [data, item.type]);
};
