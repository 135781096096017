import useRequiredParams from '../../../../../hooks/useRequiredParams';
import useOrganizationDetails from '../../../../../api/queries/organizations/useOrganizationDetails';
import useCaseGroup from '../../../../../api/queries/caseGroups/useCaseGroup';
import useCaseCategories from '../../../../../api/queries/cases/useCaseCategories';
import { useMemo } from 'react';
import useGenerateGroupFeedbackData from '../../../../../api/queries/caseGroups/useGenerateGroupFeedbackData';
import useConversationTemplates from '../../../../../api/queries/conversations/useConversationTemplates';

export const useCaseGroupDetailsCommonData = () => {
  const { organizationId, projectId, groupId } = useRequiredParams<{
    organizationId: string;
    projectId: string;
    groupId: string;
  }>(['organizationId', 'projectId', 'groupId']);

  const organizationQuery = useOrganizationDetails({
    params: { organizationId },
    options: {
      enabled: false,
      staleTime: Infinity,
    },
  });

  const caseGroupQuery = useCaseGroup({
    params: { organizationId, projectId, groupId },
    options: { enabled: false, staleTime: Infinity },
  });

  const caseCategoriesQuery = useCaseCategories({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const groupFeedbackQuery = useGenerateGroupFeedbackData({
    params: { organizationId, projectId, groupId },
    options: {
      enabled: false,
      staleTime: Infinity,
    },
  });

  const conversationTemplatesQuery = useConversationTemplates({
    params: {
      organizationId,
    },
    options: { enabled: false, staleTime: Infinity },
  });

  const caseCategoriesOptions = useMemo(() => {
    if (!caseCategoriesQuery.data) return [];

    return caseCategoriesQuery.data.map((category) => ({
      value: category.id,
      text: category.name,
    }));
  }, [caseCategoriesQuery.data]);

  return {
    organization: organizationQuery,
    caseGroup: caseGroupQuery,
    caseCategories: caseCategoriesQuery,
    groupFeedback: groupFeedbackQuery,
    conversationTemplates: conversationTemplatesQuery,
    options: {
      caseCategories: caseCategoriesOptions,
    },
  };
};
