import { faUser } from '@fortawesome/pro-solid-svg-icons';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';

//helpers
import useDeleteOrganizationToken from 'api/mutations/membership/useDeleteOrganizationToken';
import useLogout from 'api/mutations/membership/useLogout';
import useRefreshOrganizationToken from 'api/mutations/membership/useRefreshOrganizationToken';
import useUserData from 'api/queries/membership/useUserData';

//helpers
import { getLoggedAs, getOrganizations, getUserId } from 'helpers/utils/auth';

//interfaces
import { LoginMethod } from 'interfaces/membership';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown, DropdownMenu, NavItem } from 'reactstrap';
import IconButton from '../IconButton';
import ContextDisplay from './ContextDisplay';

//components
import OrganizationListItem from './OrganizationListItem';

const ContextSelector = () => {
  const { organizationId } = useParams<{
    organizationId: string;
  }>();

  const { mutate: logoutMutation } = useLogout();
  const logout = () => logoutMutation();

  const navigate = useNavigate();

  const organizations = getOrganizations();

  const loggedAs = getLoggedAs() ?? LoginMethod.AsPatient;

  const { data: userData } = useUserData({
    params: { userId: getUserId() },
    options: {
      enabled: true,
      refetchOnMount: false,
    },
  });

  const organizationsBesideSelected = organizations?.filter(
    ({ id }) => id !== organizationId
  );

  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen((value) => !value);

  const { mutate: refreshOrganizationTokenMutation } =
    useRefreshOrganizationToken({ myOrganizationScreen: false });

  const { mutate: deleteOrganizationTokenMutation } =
    useDeleteOrganizationToken();

  const changeOrganizationToken = (organizationId) => {
    refreshOrganizationTokenMutation(organizationId);
    toggle();
  };

  const deleteOrganizationToken = () => {
    toggle();

    if (organizationId) {
      deleteOrganizationTokenMutation(organizationId);
      return;
    }

    navigate('/dashboard');
  };

  const userHaveOrganizations =
    organizationsBesideSelected && organizationsBesideSelected?.length > 0;

  const selectedOrganization = organizations?.find(
    (org) => org.id === organizationId
  );

  const isOrganizationContext = !!selectedOrganization;

  const canDropdownMenuBeDisplayed =
    isOrganizationContext || userHaveOrganizations;

  const renderedOrganizationOptions = organizationsBesideSelected?.map(
    (organizationOption) => {
      return (
        <OrganizationListItem
          key={organizationOption.id}
          subtitle={organizationOption.name}
          image={organizationOption.logo_url}
          onClick={() => changeOrganizationToken(organizationOption.id)}
        />
      );
    }
  );

  return (
    <>
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        className="ContextSelector"
        nav
        inNavbar
      >
        <ContextDisplay
          firstName={userData?.personality.first_name ?? ''}
          email={userData?.personality.email ?? ''}
          dropdownOpen={dropdownOpen}
          selectedOrganization={selectedOrganization}
          isOrganizationContext={isOrganizationContext}
        />
        {canDropdownMenuBeDisplayed && (
          <DropdownMenu className="ContextSelector__dropdown-menu">
            <ul className="p-0" aria-label="organization_list">
              {isOrganizationContext && loggedAs === LoginMethod.AsPatient && (
                <OrganizationListItem
                  name={<FormattedMessage id="General.personalSpace" />}
                  subtitle={userData?.personality.email ?? ''}
                  icon={faUser}
                  onClick={deleteOrganizationToken}
                />
              )}
              {loggedAs === LoginMethod.AsMedicalPersonnel &&
                renderedOrganizationOptions}
            </ul>
          </DropdownMenu>
        )}
      </Dropdown>
      <NavItem>
        <IconButton
          sx={{ borderRadius: '100%' }}
          variant="contained"
          color="primary"
          onClick={logout}
        >
          <PowerSettingsNewOutlinedIcon />
        </IconButton>
      </NavItem>
    </>
  );
};

export default ContextSelector;
