import useCaseContextTimelineObservations from 'api/queries/cases/useCaseContextTimelineObservations';
import useUserContextTimelineObservations from 'api/queries/timelines/useUserContextTimelineObservations';
import { isSameDay } from 'date-fns';
import useUserPathBasedBrowsingContext from 'hooks/useUserPathBasedBrowsingContext';
import { IObservation } from 'interfaces/timeline';
import EmptyInformation from 'modules/TimelineDetails/EmptyInformation';

import React, { useMemo } from 'react';
import MeaningItem from './MeaningItem';
import MeaningSummary from './MeaningSummary';

type SummaryProps = {
  timelineId: string;
  selectedDate: Date;
  selectedDates: {
    from: Date;
    to: Date;
  };
};

const Summary = ({ timelineId, selectedDate, selectedDates }: SummaryProps) => {
  const {
    organizationId = '',
    caseId = '',
    isRegularUserContext,
    isOrganizationContext,
  } = useUserPathBasedBrowsingContext();

  const userContextTimelineObservations = useUserContextTimelineObservations({
    params: {
      timelineId,
      selectedDates,
      grouped: true,
    },
    options: { enabled: isRegularUserContext },
  });

  const organizationContextTimelineObservations =
    useCaseContextTimelineObservations({
      params: {
        organizationId,
        caseId,
        timelineId,
        selectedDates,
        grouped: true,
      },
      options: { enabled: isOrganizationContext },
    });

  const timelineObservationsQuery = isRegularUserContext
    ? userContextTimelineObservations
    : organizationContextTimelineObservations;

  const allMeanings =
    timelineObservationsQuery.data?.map((observation) => observation.symptom) ??
    [];

  const uniqueMeanings = [...new Set(allMeanings)];

  const filteredObservations = timelineObservationsQuery.data?.filter(
    (singleObservation) => {
      return isSameDay(new Date(singleObservation.timestamp), selectedDate);
    }
  );

  const groupByMeaning = useMemo(() => {
    let flattenObservations: IObservation[] = [];

    filteredObservations?.forEach((observation) => {
      flattenObservations.push(observation);
      flattenObservations.push(...observation.children_observations);
    });

    const meaningInDay =
      flattenObservations?.map((observation) => observation.symptom) ?? [];

    const uniqueMeaningInPeriod = [...new Set(meaningInDay)];

    return uniqueMeaningInPeriod.map((meaningName) => {
      return {
        name: meaningName,
        data: flattenObservations?.filter(
          (data) => data.symptom === meaningName
        ),
      };
    });
  }, [filteredObservations]);

  const renderedItems = groupByMeaning.map(({ data, name }) => {
    return <MeaningItem key={name} name={name} data={data} />;
  });

  const canDisplay = groupByMeaning.length > 0;

  return (
    <div className="w-100 DayDetails__scrollable-group pt-2">
      {canDisplay ? (
        <>
          <MeaningSummary
            summaryText="Observations"
            innerRadius={50}
            width={220}
            height={150}
            meaningNames={uniqueMeanings}
            observations={filteredObservations}
          />
          <div>{renderedItems}</div>
        </>
      ) : (
        <EmptyInformation type="day" />
      )}
    </div>
  );
};

export default Summary;
