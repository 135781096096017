import React, { useCallback, useEffect, useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import previousPhaseIcon from '../../../../../../../assets/images/arrow-left-circle.svg';
import nextPhaseIcon from '../../../../../../../assets/images/arrow-right-circle.svg';
import EditableInput from '../../../../../../../components/EditableInput/EditableInput';
import { ITabContainerItem } from '../../../../../../../components/Tabs/types/Tabs';
import { useTreatmentPlanCommonData } from '../../../hooks/useTreatmentPlanCommonData';
import { ManagedTreatmentPhase } from '../../../types';
import TreatmentPhase from '../components/TreatmentPhase';
import { useTreatmentPhaseCode } from './useTreatmentPhaseCode';
import { useTreatmentPhasesSequences } from './useTreatmentPhasesSequences';

export const useTreatmentPlansTabs = (
  managedTreatmentPhases: ManagedTreatmentPhase[],
  managedTreatmentPhasesInit: boolean
) => {
  const { caseCategories } = useTreatmentPlanCommonData();
  const { handleIncrease, handleDecrease } = useTreatmentPhasesSequences();
  const getPhaseCode = useTreatmentPhaseCode();

  const [selectedTab, setSelectedTab] = useState<string>(
    () => managedTreatmentPhases.at(0)?.phase.id ?? ''
  );

  useEffect(() => {
    if (!managedTreatmentPhasesInit || selectedTab === '') {
      if (selectedTab === '') {
        setSelectedTab(
          managedTreatmentPhases.at(managedTreatmentPhases.length - 1)?.phase
            .id ?? ''
        );
        return;
      }

      setSelectedTab(managedTreatmentPhases.at(0)?.phase.id ?? '');
    }
  }, [managedTreatmentPhases, managedTreatmentPhasesInit, selectedTab]);

  const shouldBeRendered = useCallback(
    (tab: string) => {
      return selectedTab === tab;
    },
    [selectedTab]
  );

  const onTabChange = useCallback(
    (idx: number) => {
      const existingPhase = managedTreatmentPhases.at(idx);

      if (!existingPhase) return;
      setSelectedTab(existingPhase.phase.id);
    },
    [managedTreatmentPhases]
  );

  const tabItems: ITabContainerItem[] = useMemo(() => {
    if (!caseCategories.data) return [];

    let items: ITabContainerItem[] = [];

    const isAddingExists =
      managedTreatmentPhases.find((p) => p.isAdding) !== undefined;

    managedTreatmentPhases.forEach((phase, index) => {
      const handleIncreaseOrder = async () => await handleIncrease(phase);
      const handleDecreaseOrder = async () => await handleDecrease(phase);

      items.push({
        header: (
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <span>{`${index + 1}. ${getPhaseCode(phase.phase.id)}`}</span>
              {phase.phase.is_default && (
                <EditableInput
                  className="mt-0 ms-2"
                  disabled={true}
                  type="checkbox"
                  checked={true}
                  name="isDefault"
                  debounceEnabled={false}
                />
              )}
            </div>
            {!phase.isAdding && (
              <div className="d-flex gap-1 ms-4">
                {index > 0 && (
                  <SVG
                    onClick={handleDecreaseOrder}
                    className="me-0"
                    src={previousPhaseIcon}
                    title="Decrease order"
                  />
                )}
                {index <
                  (isAddingExists
                    ? managedTreatmentPhases.length - 2
                    : managedTreatmentPhases.length - 1) && (
                  <SVG
                    onClick={handleIncreaseOrder}
                    className="me-0"
                    src={nextPhaseIcon}
                    title="Increase order"
                  />
                )}
              </div>
            )}
          </div>
        ),
        component: (
          <TreatmentPhase
            key={phase.phase.sequence}
            phase={phase.phase}
            addMode={phase.isAdding}
          />
        ),
        shouldBeRendered: shouldBeRendered(phase.phase.id),
      });
    });

    return items;
  }, [
    caseCategories.data,
    getPhaseCode,
    handleDecrease,
    handleIncrease,
    managedTreatmentPhases,
    shouldBeRendered,
  ]);

  return {
    items: tabItems,
    onTabChange: onTabChange,
    selectedTab: selectedTab,
    setSelectedTab: setSelectedTab,
  };
};
