import { useTranslations } from 'hooks/useTranslations';
import React from 'react';
import TabItem from '../../modules/CaseDashboard/components/CaseDashboardTabs/components/TabItem';
import FeedbackChartFigure from './components/FeedbackChartFigure';
import { UseQueryResult } from 'react-query';
import { ICaseDetails } from '../../interfaces/cases';

type FeedbackProps = {
  date: Date;
  caseDetails: UseQueryResult<ICaseDetails>;
};

const FeedbackChart = ({ date, caseDetails }: FeedbackProps) => {
  const { attachPrefix, wrapWithIntl } = useTranslations(
    'CaseDashboard.CaseDashboardTabs.DayDetailsTab.Feedback'
  );

  return (
    <TabItem title={wrapWithIntl(attachPrefix('feedback'))}>
      <FeedbackChartFigure date={date} caseDetails={caseDetails} />
    </TabItem>
  );
};

export default FeedbackChart;
