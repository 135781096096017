import { IMutationProps } from '../../../interfaces/ui';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { IPatchObject } from '../../../interfaces/request';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { editCaseGroup } from '../../caseGroups';

type usePatchCaseGroupParams = {
  organizationId: string;
  projectId: string;
  groupId: string;
};

const usePatchCaseGroup = (
  params: usePatchCaseGroupParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (data: IPatchObject[]) => {
      await editCaseGroup(
        params.organizationId,
        params.projectId,
        params.groupId,
        data
      );
    },
    onSuccess: () => {
      displaySuccessNotification(
        'Api.Mutations.CaseGroups.PatchCaseGroup.success'
      );

      if (options.successFb) options.successFb();
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'Api.Mutations.CaseGroups.PatchCaseGroup.failure'
      );

      if (options.errorFb) options.errorFb();
    },
    resetOnResult: true,
  });
};

export default usePatchCaseGroup;
