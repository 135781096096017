import { Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { FormikProps } from 'formik';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { HelpCentreFormInitialValues } from '../../../../../../forms/HelpCentreForm/hooks/useForm';

type ProblemDescriptionProps = {
  form: FormikProps<HelpCentreFormInitialValues>;
};

const ProblemDescription = ({ form }: ProblemDescriptionProps) => {
  const intl = useIntl();

  return (
    <div>
      <Typography variant="body1" className="pb-3">
        <FormattedMessage id="Help.PleaseDescribeWhatIsHappening" />
      </Typography>
      <TextField
        fullWidth
        multiline
        rows={5}
        variant="outlined"
        name="description"
        placeholder={intl.formatMessage({
          id: 'Help.PleaseDescribeWhatIsHappening',
        })}
        value={form.values.description}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
        error={form.touched.description && Boolean(form.errors.description)}
        helperText={form.touched.description && form.errors.description}
      />
    </div>
  );
};

export default ProblemDescription;
