import classNames from 'classnames';
import React from 'react';
import { Col, Row } from 'reactstrap';
import AllSqueezesChart from '../../../../../../components/AllSqueezesChart';
import ExerciseTimeChart from '../../../../../../components/ExerciseTimeChart';
import FeedbackChart from '../../../../../../components/FeedbackChart';
import HourByHourChart from '../../../../../../components/HourByHourChart';
import useAsyncEventListen from '../../../../../../hooks/events/useAsyncEventListen';
import { useTypedContext } from '../../../../../../hooks/useTypedContext';
import { useBrowserState } from '../../../../../../store/reducerHooks';
import { useCommonData } from '../../../../hooks/useCommonData';
import { SelectedDateContext } from '../../types';

const DayDetailsTab = () => {
  const { userCaseDetails, observationMeanings } = useCommonData();

  const selectedDate = useTypedContext(SelectedDateContext);

  const isBrowser = useBrowserState();

  useAsyncEventListen(
    'day-details-refetch-requested',
    async () => {
      window.dispatchEvent(new CustomEvent('all-squeezes-refetch-requested'));
      window.dispatchEvent(new CustomEvent('exercise-time-refetch-requested'));
      window.dispatchEvent(
        new CustomEvent('hour-by-hour-time-refetch-requested')
      );
      window.dispatchEvent(new CustomEvent('feedback-time-refetch-requested'));
    },
    true
  );

  if (!userCaseDetails.data) return <></>;

  return (
    <Row
      className={classNames(
        'CaseDashboardTabs__day-details-tab DayDetailsTab mt-3 gap-2 gap-lg-0',
        {
          'mb-5 pb-5': !isBrowser,
        }
      )}
    >
      <Col md={12} lg={6} className={classNames('DayDetailsTab__item mb-lg-4')}>
        <AllSqueezesChart
          date={selectedDate.selected.item}
          queries={{ caseDetails: userCaseDetails, observationMeanings }}
        />
      </Col>
      {userCaseDetails.data.exercises_enabled && (
        <Col
          md={12}
          lg={6}
          className={classNames('DayDetailsTab__item mb-lg-4')}
        >
          <ExerciseTimeChart
            date={selectedDate.selected.item}
            caseDetails={userCaseDetails}
          />
        </Col>
      )}
      <Col md={12} lg={6} className={classNames('DayDetailsTab__item mb-lg-4')}>
        <HourByHourChart
          date={selectedDate.selected.item}
          caseDetails={userCaseDetails}
        />
      </Col>
      <Col md={12} lg={6} className={classNames('DayDetailsTab__item mb-lg-4')}>
        <FeedbackChart
          date={selectedDate.selected.item}
          caseDetails={userCaseDetails}
        />
      </Col>
    </Row>
  );
};

export default DayDetailsTab;
