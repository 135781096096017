import SVG from 'react-inlinesvg';
import BuddyCaseSection from './BuddyCaseSection';
import BuddyCaseDaySummaryContent from './BuddyCaseDaySummaryContent';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';

import daySummaryIcon from 'assets/images/buddy_case/day_summary_icon.svg';

type BuddyCaseDaySummaryProps = {
  organizationId: string;
  caseId: string;
  timelineId: string;
  selectedDate: Date;
};

const BuddyCaseDaySummary = ({
  selectedDate,
  caseId,
  organizationId,
  timelineId,
}: BuddyCaseDaySummaryProps) => {
  const intl = useIntl();

  const dateLabel = format(selectedDate, 'EEE, dd.MM.yyyy');

  return (
    <BuddyCaseSection
      icon={<SVG src={daySummaryIcon} />}
      title={intl.formatMessage({ id: 'BuddyCase.dayDetails' })}
      additional={
        <span className="BuddyCaseSection__additional">{dateLabel}</span>
      }
      content={
        <BuddyCaseDaySummaryContent
          organizationId={organizationId}
          caseId={caseId}
          timelineId={timelineId}
          selectedDate={selectedDate}
        />
      }
    />
  );
};

export default BuddyCaseDaySummary;
