import useOpen from '../../../hooks/useOpen';
import { DropEvent, FileRejection } from 'react-dropzone';
import { useCallback } from 'react';
import {
  DropFileAcceptCallback,
  DropFileCallback,
  DropFileRejectCallback,
} from '../types';

export const useHandleDrop = (
  onDrop: DropFileCallback | undefined,
  onAccept: DropFileAcceptCallback | undefined,
  onReject: DropFileRejectCallback | undefined
) => {
  const { isOpen: isUploading, toggle: toggleUpload } = useOpen();

  const handleDrop = useCallback(
    async (accepted: File[], rejections: FileRejection[], event: DropEvent) => {
      toggleUpload();
      await onDrop?.(accepted, rejections, event);
      toggleUpload();
    },
    [onDrop, toggleUpload]
  );

  const handleAccept = useCallback(
    async (files: File[], event: DropEvent) => {
      await onAccept?.(files, event);
    },
    [onAccept]
  );

  const handleReject = useCallback(
    async (fileRejections: FileRejection[], event: DropEvent) => {
      await onReject?.(fileRejections, event);
    },
    [onReject]
  );

  return { handleDrop, handleAccept, handleReject, isUploading };
};
