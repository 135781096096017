import { Link, useParams } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import classNames from 'classnames';
import Skeleton from 'react-loading-skeleton';
import SkeletonPlaceholderOnLoad from '../SkeletonPlaceholderOnLoad';

export type BreadcrumbItemType = {
  text: string | JSX.Element;
  link: string | null;
  state?: any;
  key: string;
};

type BreadcrumbsProps = {
  items: BreadcrumbItemType[];
  includeHome?: boolean;
  isLoading?: boolean;
  autoWidth?: boolean;
};

const homeBreadcrumb: BreadcrumbItemType = {
  text: 'Home',
  link: '/',
  key: 'home',
};

const inactiveHomeBreadcrumb: BreadcrumbItemType = {
  text: 'Home',
  link: null,
  key: 'home',
};

const Breadcrumbs = ({
  items,
  includeHome = true,
  isLoading = false,
  autoWidth = false,
}: BreadcrumbsProps) => {
  const { organizationId } = useParams<{
    organizationId: string;
  }>();

  const isOrganization = !!organizationId;
  const home = isOrganization ? inactiveHomeBreadcrumb : homeBreadcrumb;

  const breadcrumbsToRender = includeHome ? [home, ...items] : [...items];

  const renderedBreadCrumbItems = breadcrumbsToRender.map(
    ({ text, link, state, key }: BreadcrumbItemType) => {
      if (!!link) {
        return (
          <BreadcrumbItem key={key}>
            <SkeletonPlaceholderOnLoad
              isLoading={isLoading}
              placeholder={<Skeleton width={100} />}
            >
              <Link to={link} state={state}>
                {text}
              </Link>
            </SkeletonPlaceholderOnLoad>
          </BreadcrumbItem>
        );
      }
      return (
        <BreadcrumbItem key={key} active>
          <SkeletonPlaceholderOnLoad
            isLoading={isLoading}
            placeholder={<Skeleton width={100} />}
          >
            <>{text}</>
          </SkeletonPlaceholderOnLoad>
        </BreadcrumbItem>
      );
    }
  );

  return (
    <Breadcrumb
      className={classNames({ 'w-auto': autoWidth })}
      listClassName={'ps-0 mb-0 breadcrumb--transparent'}
    >
      {renderedBreadCrumbItems}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
