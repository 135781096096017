import { ValidConversationQuestion } from './conversations';

export interface IAwareSessionData {
  id: string;
  organization_id: string;
  grasp_id: string;
  case_id: string;
  member_id: string;
  start_date: string;
  end_date: string;
  total_squeezes: number;
  squeezes_per_minute: number;
  average_intensity: number;
  alarm_incidents: number;
  patient_number?: string;
  questions: IAwareSessionDataQuestion[];
}

export enum AwareSessionDataSource {
  AwareSessionData = 'AwareSessionData',
  AggregatedAwareSessionData = 'AggregatedAwareSessionData',
}

export interface IAwareSessionDataQuestion {
  id: string;
  value: any;
}

export interface IAwareSessionDataMapping {
  organization_id: string;
  organizations: string[];
  questions: AwareSessionDataMappingQuestion[];
}

export type AwareSessionDataMappingQuestion = ValidConversationQuestion & {
  sub_questions: IAwareSessionDataMappingSubQuestion;
};

export interface IAwareSessionDataMappingSubQuestion {
  organization_id: string;
  question_id: string;
}

export interface IScheduleAwareSessionData {
  patient_number?: number;
  start_date: string;
  end_date: string;
  member_id: string;
  questions: IAwareSessionDataQuestion[];
}

export interface IUpdateAwareSessionData {
  patient_number?: number;
  questions: IAwareSessionDataQuestion[];
}
