import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope,
  faExchange,
  faUnlink,
} from '@fortawesome/pro-light-svg-icons';
import SVG from 'react-inlinesvg';
import projectsFlow from '../../../../../../../../assets/images/projects/project-flow.svg';
import CustomTable from '../../../../../../../../components/CustomTable';
import { useIntl } from 'react-intl';
import IndeterminateCheckbox from '../../../../../../../../components/IndeterminateCheckbox';
import { v4 as uuid } from 'uuid';
import Skeleton from 'react-loading-skeleton';

const CaseGroupDetailsCasesLoading = () => {
  const intl = useIntl();

  const handleChange = () => {
    return;
  };

  const headerItems = [
    <div
      key="CaseGroupItem.casesHeader.selection"
      className="d-flex align-items-center justify-content-center pb-1"
    >
      <IndeterminateCheckbox
        key="indeterminate-checkbox"
        checked={false}
        indeterminate={false}
        onChange={handleChange}
      />
    </div>,
    'CaseGroupItem.casesHeader.title',
    'CaseGroupItem.casesHeader.description',
    'CaseGroupItem.casesHeader.number',
    'CaseGroupItem.casesHeader.category',
    'CaseGroupItem.casesHeader.subCategory',
  ];

  const bodyRows = [...Array(5)].map((_) => (
    <tr key={uuid.toString()}>
      <td valign="middle" align="center">
        <IndeterminateCheckbox
          checked={false}
          indeterminate={false}
          onChange={handleChange}
        />
      </td>
      <td valign="middle">
        <Skeleton width={200} />
      </td>
      <td valign="middle">
        <Skeleton width={200} />
      </td>
      <td valign="middle">
        <Skeleton width={50} />
      </td>
      <td valign="middle">
        <Skeleton width={100} />
      </td>
      <td valign="middle">
        <Skeleton width={100} />
      </td>
    </tr>
  ));

  return (
    <div className="ManageGroupCases CaseCard__tab-container pt-3 px-3">
      <div className="ManageGroupCases__header">
        <div className="ManageGroupCases__actions">
          <Button
            color="primary"
            outline={true}
            className="d-flex align-items-center gap-1 px-3 py-2"
            title={intl.formatMessage({
              id: 'ChangeCase.sendMessage',
            })}
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </Button>
          <Button
            color="primary"
            outline={true}
            className="d-flex align-items-center gap-1 px-3 py-2"
            title={intl.formatMessage({
              id: 'CaseGroupDetailsCases.CaseGroupDetails.moveToConversationPhase',
            })}
          >
            <SVG src={projectsFlow} width={'1em'} height={'1em'} />
          </Button>
          <Button
            color="primary"
            outline={true}
            className="d-flex align-items-center gap-1 px-3 py-2"
            title={intl.formatMessage({
              id: 'CaseGroupItem.moveCase',
            })}
          >
            <FontAwesomeIcon icon={faExchange} />
          </Button>
          <Button
            color="danger"
            outline={true}
            className="d-flex align-items-center gap-1 px-3 py-2"
            title={intl.formatMessage({
              id: 'CaseGroupItem.unlinkCase',
            })}
          >
            <FontAwesomeIcon icon={faUnlink} />
          </Button>
        </div>
      </div>
      <div>
        <CustomTable
          clickableRows={true}
          isResponsive={true}
          stretched={false}
          headerItems={headerItems}
          bodyRows={bodyRows}
        />
      </div>
    </div>
  );
};

export default CaseGroupDetailsCasesLoading;
