import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useAddCaseObservationMeanings from 'api/mutations/symptoms/useAddCaseSymptom';
import useCaseSymptoms from 'api/queries/symptoms/useCaseSymptoms';
import useOrganizationSymptoms from 'api/queries/symptoms/useOrganizationSymptoms';
import editIcon from 'assets/images/edit_icon_transparent.svg';
import addEntryIcon from 'assets/images/timeline_details/add_entry.svg';
import formsAssignmentsIcon from 'assets/images/timeline_details/forms_assignments_icon.svg';
import {
  hasDuplicateSymptomNames,
  hasEmptyMeanings,
} from 'helpers/utils/symptoms';
import useOpen from 'hooks/useOpen';
import useRequiredParams from 'hooks/useRequiredParams';
import { ICaseSymptom, ICaseSymptomDefinition } from 'interfaces/symptoms';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert, Button, Col, Row } from 'reactstrap';
import SymptomRow from './SymptomRow';

export const caseObservationMeaningSortFunction = (
  caseMeaningPrev: ICaseSymptom,
  caseMeaningNext: ICaseSymptom
) => {
  if (caseMeaningPrev.symptom.name < caseMeaningNext.symptom.name) return 1;
  if (caseMeaningPrev.symptom.name > caseMeaningNext.symptom.name) return -1;

  return 0;
};

const Symptoms = () => {
  const { caseId, organizationId } = useRequiredParams<{
    caseId: string;
    organizationId: string;
  }>(['caseId', 'organizationId']);

  const { data: caseObservationMeaningsData, refetch: refetchCases } =
    useCaseSymptoms({ params: { organizationId, caseId } });

  useOrganizationSymptoms({ params: { organizationId } });

  const { isOpen: isEditOpen, toggle: toggleEdit } = useOpen(false);

  const onSuccess = async () => await refetchCases();

  const [addedMeanings, setAddedMeanings] = useState<ICaseSymptomDefinition[]>(
    []
  );

  const onAddSuccess = async () => {
    await refetchCases();
    setAddedMeanings([]);
  };

  const { mutate } = useAddCaseObservationMeanings(
    {
      organizationId: organizationId,
    },
    {
      successFb: onAddSuccess,
    }
  );

  const onAddClick = () => {
    setAddedMeanings((currentValue) => [
      ...currentValue,
      {
        symptom_id: '',
        patient_symptom: '',
      },
    ]);
  };

  const onAddedRowChange = (
    index: number,
    definition: ICaseSymptomDefinition
  ) => {
    setAddedMeanings((currentValue) => {
      const copyOfCurrentValue = [...currentValue];
      copyOfCurrentValue[index] = definition;
      return copyOfCurrentValue;
    });
  };

  const onAddedRowDelete = (rowIndex: number) => {
    setAddedMeanings((currentValue) =>
      currentValue.filter((_el, index) => index !== rowIndex)
    );
  };

  const onSaveClick = () => {
    mutate({
      caseId,
      definitions: addedMeanings,
    });
  };

  const orderedCaseList = caseObservationMeaningsData
    ? [
        ...caseObservationMeaningsData
          .filter((el) => el.active)
          .sort(caseObservationMeaningSortFunction),
        ...caseObservationMeaningsData
          .filter((el) => !el.active)
          .sort(caseObservationMeaningSortFunction),
      ]
    : [];

  const caseMeaningsRows =
    orderedCaseList?.map((el) => {
      return (
        <SymptomRow
          key={el.symptom_id}
          onToggleSuccess={onSuccess}
          selectedObservationMeaning={el.symptom_id}
          symptomName={el.patient_symptom}
          caseMeaningId={el.id}
          isActive={el.active}
          isEditable={isEditOpen}
        />
      );
    }) ?? [];

  const renderMeaningsToAdd = addedMeanings.map(
    ({ symptom_id, patient_symptom }, index) => {
      return (
        <SymptomRow
          key={symptom_id}
          onToggleSuccess={onSuccess}
          selectedObservationMeaning={symptom_id}
          symptomName={patient_symptom}
          onRowChange={(definition) => onAddedRowChange(index, definition)}
          onRowDelete={() => onAddedRowDelete(index)}
          isEditable={isEditOpen}
        />
      );
    }
  );

  const definitionsToValidate = caseObservationMeaningsData
    ? [...addedMeanings, ...caseObservationMeaningsData]
    : [...addedMeanings];

  const hasDuplicates = hasDuplicateSymptomNames(definitionsToValidate);
  const hasEmpty = hasEmptyMeanings(definitionsToValidate);

  let errorAlert: JSX.Element | null = null;
  if (hasDuplicates) {
    errorAlert = (
      <Alert color="danger">
        <FormattedMessage id="ObservationMeaning.duplicateSymptomName" />
      </Alert>
    );
  } else if (hasEmpty) {
    errorAlert = (
      <Alert color="danger">
        <FormattedMessage id="ObservationMeaning.emptySymptomNameOrMeaning" />
      </Alert>
    );
  }

  const hasErrors = hasDuplicates || hasEmpty;
  const meaningRowSize = isEditOpen ? 5 : 6;

  return (
    <>
      <div className="cardContainer h100-above-xl mt-4">
        <div className="cardContainer__header mb-4">
          <div className="cardContainer__header__title">
            <img
              src={formsAssignmentsIcon}
              className="cardContainer__header__title__image"
              alt={formsAssignmentsIcon}
            />
            <FormattedMessage id="ObservationMeaning.caseObservationMeanings" />
          </div>
          <div className="d-flex">
            {isEditOpen && (
              <div className="cardContainer__header__button-container">
                <Button
                  color="primary"
                  className="cardContainer__header__button-container__button"
                  onClick={onAddClick}
                >
                  <img src={addEntryIcon} alt="Add case meanings" />
                </Button>
                <div className="cardContainer__header__button-container__text">
                  <FormattedMessage id="ObservationMeaning.addObservationMeaning" />
                </div>
              </div>
            )}
            <div className="cardContainer__header__button-container ms-4">
              <Button
                color="primary"
                className="cardContainer__header__button-container__button ObservationMeaning__edit"
                onClick={toggleEdit}
              >
                {isEditOpen ? (
                  <FontAwesomeIcon icon={faTimes} />
                ) : (
                  <img src={editIcon} alt="Edit case meanings" />
                )}
              </Button>
              <div className="cardContainer__header__button-container__text">
                {isEditOpen ? (
                  <FormattedMessage id="ObservationMeaning.disableEdit" />
                ) : (
                  <FormattedMessage id="ObservationMeaning.enableEdit" />
                )}
              </div>
            </div>
          </div>
        </div>
        {isEditOpen && errorAlert}
        {caseMeaningsRows.length > 0 || renderMeaningsToAdd.length > 0 ? (
          <Row className="pb-4">
            {isEditOpen && (
              <Col
                md={1}
                className="text-center ObservationMeaning__row-heading"
              >
                <FormattedMessage id="ObservationMeaning.headers.active" />
              </Col>
            )}
            <Col
              md={meaningRowSize}
              className="text-center ObservationMeaning__row-heading"
            >
              <FormattedMessage id="ObservationMeaning.headers.observationMeaning" />
            </Col>
            <Col md={6} className="text-center ObservationMeaning__row-heading">
              <FormattedMessage id="ObservationMeaning.headers.symptomName" />
            </Col>
          </Row>
        ) : (
          <Alert color="primary">
            <FormattedMessage id="ObservationMeaning.noObservationMeaningSelected" />
          </Alert>
        )}
        {caseMeaningsRows}
        {isEditOpen && renderMeaningsToAdd}
        <div className="d-flex p-1 justify-content-end">
          {addedMeanings.length > 0 && isEditOpen && (
            <Button disabled={hasErrors} color="primary" onClick={onSaveClick}>
              <FormattedMessage id="General.save" />
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default Symptoms;
