import { StrictDateRange } from '../../../../../../../../../../interfaces/dates';
import {
  addDays,
  addMonths,
  addYears,
  endOfDay,
  endOfMonth,
  endOfYear,
  getMonth,
  getYear,
  startOfDay,
  startOfMonth,
  startOfYear,
  subDays,
  subMonths,
  subYears,
} from 'date-fns';

export const shiftDays = (
  dateRange: StrictDateRange,
  days: number
): StrictDateRange => {
  return {
    from:
      days < 0
        ? subDays(dateRange.from, Math.abs(days))
        : addDays(dateRange.from, Math.abs(days)),
    to:
      days < 0
        ? subDays(dateRange.to, Math.abs(days))
        : addDays(dateRange.to, Math.abs(days)),
  };
};

export const isMonthSelected = (dateRange: StrictDateRange) => {
  const startMonth = getMonth(dateRange.from);
  const endMonth = getMonth(dateRange.to);

  if (startMonth !== endMonth) return false;

  return (
    startOfDay(dateRange.from).toISOString() ===
      startOfDay(startOfMonth(dateRange.from)).toISOString() &&
    endOfDay(dateRange.to).toISOString() ===
      endOfDay(endOfMonth(dateRange.to)).toISOString()
  );
};

export const isYearSelected = (dateRange: StrictDateRange) => {
  const startYear = getYear(dateRange.from);
  const endYear = getYear(dateRange.to);

  if (startYear !== endYear) return false;

  return (
    startOfDay(dateRange.from).toISOString() ===
      startOfDay(startOfYear(dateRange.from)).toISOString() &&
    endOfDay(dateRange.to).toISOString() ===
      endOfDay(endOfYear(dateRange.to)).toISOString()
  );
};

export const getAnotherMonth = (
  dateRange: StrictDateRange
): StrictDateRange => {
  return {
    from: startOfDay(addMonths(startOfMonth(dateRange.from), 1)),
    to: endOfDay(endOfMonth(addMonths(endOfMonth(dateRange.to), 1))),
  };
};

export const getPreviousMonth = (
  dateRange: StrictDateRange
): StrictDateRange => {
  return {
    from: startOfDay(startOfMonth(subMonths(startOfMonth(dateRange.from), 1))),
    to: endOfDay(endOfMonth(subMonths(endOfMonth(dateRange.to), 1))),
  };
};

export const getAnotherYear = (dateRange: StrictDateRange): StrictDateRange => {
  return {
    from: startOfDay(addYears(startOfYear(dateRange.from), 1)),
    to: endOfDay(endOfYear(addYears(endOfYear(dateRange.to), 1))),
  };
};

export const getPreviousYear = (
  dateRange: StrictDateRange
): StrictDateRange => {
  return {
    from: startOfDay(startOfYear(subYears(startOfYear(dateRange.from), 1))),
    to: endOfDay(endOfYear(subYears(endOfYear(dateRange.to), 1))),
  };
};
