import { useCurrentPng } from 'recharts-to-png';
import React, { useCallback } from 'react';

export type ChartDownloadHookValues = {
  chartRef: React.MutableRefObject<any>;
  handleDownload: () => void;
};

export const useTrendsChartDownload = (): ChartDownloadHookValues => {
  const [getPng, { ref: chartRef }] = useCurrentPng();

  const handleDownload = useCallback(async () => {
    const png = await getPng();
    if (png) {
      const download = document.createElement('a');
      download.href = png;
      download.download = 'change_chart.png';
      download.click();
    }
  }, [getPng]);

  return { chartRef, handleDownload };
};
