import { IRequestShareTimelineResponse } from 'interfaces/cases';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Alert } from 'reactstrap';
import bellIcon from 'assets/images/case_details/bell.svg';

type RequestAlertProps = {
  data: IRequestShareTimelineResponse;
};

const RequestAlert: React.FC<RequestAlertProps> = ({
  data: { status, participant },
}) => {
  let color: string = 'light';
  let text: JSX.Element | null = null;

  const userName = `${participant?.first_name ?? '-'} ${
    participant?.last_name ?? '-'
  }`;

  if (status === 'Sent') {
    color = 'warning';
    text = (
      <>
        <FormattedMessage id="CaseCard.requestAlert.youSentRequest" />{' '}
        {userName}
        {'. '}
        <FormattedMessage id="CaseCard.requestAlert.waitForApproval" />
      </>
    );
  } else if (status === 'Rejected') {
    color = 'danger';
    text = (
      <>
        <FormattedMessage id="CaseCard.requestAlert.yourRequestRejectedBy" />{' '}
        {userName}
      </>
    );
  }

  return (
    <Alert color={color} className={`RequestAlert RequestAlert--${color}`}>
      <div className={`RequestAlert__icon RequestAlert__icon--${color}`}>
        <img src={bellIcon} alt="alert_icon" />
      </div>
      <div className="RequestAlert__text">{text}</div>
    </Alert>
  );
};

export default RequestAlert;
