import { useMemo } from 'react';
import { IObservation } from '../../../interfaces/timeline';
import { addMilliseconds } from 'date-fns';

export const useFlattenObservations = (observations: IObservation[]) => {
  return useMemo(() => {
    let _flattenObservations: IObservation[] = [];

    observations.forEach((observation) => {
      if (observation.amplitudes.length === 0) return;

      observation.amplitudes.forEach((amplitude) => {
        _flattenObservations.push({
          ...observation,
          timestamp: addMilliseconds(
            new Date(observation.timestamp),
            200
          ).toISOString(),
          amplitudes: [amplitude],
          duration_in_seconds:
            observation.duration_in_seconds / observation.amplitudes.length,
        });
      });
    });

    return _flattenObservations;
  }, [observations]);
};
