import { getOrganizationContextAssessmentFormsForDate } from 'api/graspManagement';
import QueryKeys from 'api/queries/queryKeys';
import { hourSort } from 'helpers/utils/assessmentForms';
import {
  CaseQueryParams,
  TimelineDateQueryParams,
} from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { useQuery } from 'react-query';

type UseOrganizationContextGroupedAssessmentFormsForDateParams =
  CaseQueryParams & TimelineDateQueryParams;

function useOrganizationContextGroupedAssessmentFormsForDate({
  params,
  options,
}: IQueryRequest<UseOrganizationContextGroupedAssessmentFormsForDateParams>) {
  const { organizationId, caseId, timelineId, selectedDate } = params;

  const year = selectedDate.getFullYear();
  const month = selectedDate.getMonth() + 1;
  const day = selectedDate.getDate();

  return useQuery({
    ...options,
    queryKey: [
      QueryKeys.getOrganizationContextAssessmentFormsForDate,
      organizationId,
      caseId,
      timelineId,
      year,
      month,
      day,
    ],
    queryFn: async () => {
      const { data: responseData } =
        await getOrganizationContextAssessmentFormsForDate(
          organizationId,
          caseId,
          timelineId,
          year,
          month,
          day
        );

      const sortedArray = [...responseData?.data];
      sortedArray.sort(hourSort);

      return sortedArray;
    },
  });
}

export default useOrganizationContextGroupedAssessmentFormsForDate;
