import { useCallback, useContext, useMemo } from 'react';
import { cloneDeep } from 'lodash';
import { TrendsChartConfigAction } from 'components/TrendsChart/types';
import { TrendVisualizationModalFormikContext } from '../../../../../types';
import { FormikProps } from 'formik';
import {
  TrendVisualization,
  TrendVisualizationTemplate,
} from 'interfaces/trendVisualizations';
import { AVAILABLE_CULTURES } from 'components/CultureSelector/CultureSelector';

export const useChartActions = (visualization: TrendVisualization) => {
  const { setFieldValue, values, setValues } = useContext(
    TrendVisualizationModalFormikContext
  ) as FormikProps<TrendVisualizationTemplate>;

  const onTitleAccept = useCallback(
    (title: string, culture: AVAILABLE_CULTURES) => {
      if (culture === AVAILABLE_CULTURES.EN) {
        setFieldValue(`visualizations[${visualization.sequence}].title`, title);
        setFieldValue(
          `visualizations[${visualization.sequence}].title_translations[0].value`,
          title
        );
        return;
      }

      setFieldValue(
        `visualizations[${visualization.sequence}].title_translations[1].value`,
        title
      );
    },
    [setFieldValue, visualization.sequence]
  );

  const onMoveUp = useCallback(() => {
    if (visualization.sequence === 0) return;

    let valuesCopy = cloneDeep(values);
    valuesCopy.visualizations[visualization.sequence].sequence -= 1;
    valuesCopy.visualizations[visualization.sequence - 1].sequence += 1;

    valuesCopy.visualizations.sort((a, b) => a.sequence - b.sequence);

    setValues(valuesCopy);
  }, [setValues, values, visualization.sequence]);

  const onMoveDown = useCallback(() => {
    if (visualization.sequence === values.visualizations.length - 1) return;

    let valuesCopy = cloneDeep(values);
    valuesCopy.visualizations[visualization.sequence].sequence += 1;
    valuesCopy.visualizations[visualization.sequence + 1].sequence -= 1;

    valuesCopy.visualizations.sort((a, b) => a.sequence - b.sequence);

    setValues(valuesCopy);
  }, [setValues, values, visualization.sequence]);

  const onDelete = useCallback(() => {
    let valuesCopy = cloneDeep(values);
    valuesCopy.visualizations.splice(visualization.sequence, 1);

    for (
      let i = visualization.sequence;
      i < valuesCopy.visualizations.length;
      i++
    ) {
      valuesCopy.visualizations[i].sequence -= 1;
    }

    valuesCopy.visualizations.sort((a, b) => a.sequence - b.sequence);

    setValues(valuesCopy);
  }, [setValues, values, visualization.sequence]);

  const actions = useMemo((): TrendsChartConfigAction[] => {
    return [
      { type: 'download' },
      { type: 'move-up', callback: onMoveUp },
      {
        type: 'move-down',
        callback: onMoveDown,
      },
      { type: 'delete', callback: onDelete },
    ];
  }, [onDelete, onMoveDown, onMoveUp]);

  return { actions, onTitleAccept };
};
