import useTimelines from 'api/queries/timelines/useTimelines';
import { getUserId } from 'helpers/utils/auth';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { TimelineDetailsAssessmentFormsErrors } from '../TimelineDetailsAssessmentProxy';
import AlreadyCompletedErrorModalBody from './AlreadyCompletedErrorModalBody';
import FormNotFoundErrorModalBody from './FormNotFoundErrorModalBody';
import InvalidDateParamsErrorModalBody from './InvalidDateParamsErrorModalBody';

type TimelineDetailsAssessmentProxyModalProps = {
  errorType: TimelineDetailsAssessmentFormsErrors;
  timelineId: string;
  isOpen: boolean;
  toggleModal: () => void;
};

const TimelineDetailsAssessmentProxyModal = ({
  errorType,
  isOpen,
  toggleModal,
  timelineId,
}: TimelineDetailsAssessmentProxyModalProps) => {
  const navigate = useNavigate();

  const { data: timelinesData } = useTimelines({
    params: { userId: getUserId() },
  });

  const redirectToDashboard = () => {
    navigate('/dashboard');
  };

  const redirectToTimeline = () => {
    navigate(`/timelines/${timelineId}`);
  };

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      toggle={toggleModal}
      className="TimelineDetailsProxy__modal"
    >
      <ModalHeader className="w-100" toggle={redirectToDashboard}>
        <FormattedMessage id="ProxyComponents.TimelineDetails.ModalHeaderError" />
      </ModalHeader>
      <ModalBody>
        {errorType ===
          TimelineDetailsAssessmentFormsErrors.AlreadyCompleted && (
          <AlreadyCompletedErrorModalBody />
        )}
        {errorType === TimelineDetailsAssessmentFormsErrors.FormNotFound && (
          <FormNotFoundErrorModalBody />
        )}
        {errorType ===
          TimelineDetailsAssessmentFormsErrors.InvalidDateParams && (
          <InvalidDateParamsErrorModalBody />
        )}
      </ModalBody>
      <ModalFooter>
        <Button outline color="primary" onClick={redirectToDashboard}>
          <FormattedMessage id="ProxyComponents.TimelineDetails.GoToDashboard" />
        </Button>
        {timelinesData?.find((p) => p.id === timelineId) && (
          <Button color="primary" onClick={redirectToTimeline}>
            <FormattedMessage id="ProxyComponents.TimelineDetails.GoToTimeline" />
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default TimelineDetailsAssessmentProxyModal;
