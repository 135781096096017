import FilterDisplay from 'components/Filters/FilterDisplay';
import FilterInput from 'components/Filters/FilterInput';
import useOpen from 'hooks/useOpen';
import { ReactStateTuple } from 'interfaces/ui';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormGroup, Label } from 'reactstrap';
import ConversationTemplatesFilterBody from './components/ConversationTemplatesFilterBody';
import SearchConversationInput from './components/SearchConversationsInput';
import { useChangeCaseConversationFilters } from './hooks/useChangeCaseConversationFilters';

type ChangeCaseConversationsFiltersProps = {
  quickSearchState: ReactStateTuple<string | undefined>;
  templateIdsState: ReactStateTuple<string[] | undefined>;
  _templateIdsState: ReactStateTuple<string[] | undefined>;
};

const ChangeCaseConversationsFilters = ({
  quickSearchState,
  templateIdsState,
  _templateIdsState,
}: ChangeCaseConversationsFiltersProps) => {
  const { filterString, filtersLength, handleAccept, handleReset } =
    useChangeCaseConversationFilters(templateIdsState, _templateIdsState);

  const {
    isOpen: isConversationsFiltersOpen,
    toggle: toggleConversationFilter,
  } = useOpen();

  const handleApplyFilter = () => {
    handleAccept();
    toggleConversationFilter();
  };

  return (
    <div className="d-flex align-items-center gap-2 w-100">
      <SearchConversationInput quickSearchState={quickSearchState} />
      <FormGroup className="me-2">
        <Label className="d-flex">
          <FormattedMessage id="Cases.ChangeCase.ChangeCaseConversations.ChangeCaseConversationsFilters.selectedTemplates" />
        </Label>
        <FilterInput
          toggle={toggleConversationFilter}
          isOpen={isConversationsFiltersOpen}
          targetId="cases-conversations-filter-input-target"
          displayComponent={
            <FilterDisplay
              targetId="cases-conversations-filter-input-target"
              filterString={filterString}
              filtersLength={filtersLength}
            />
          }
          filterBody={
            <ConversationTemplatesFilterBody
              templateIdsState={_templateIdsState}
            />
          }
          titleTranslationId="Projects.filter.filters"
          onAcceptClick={handleApplyFilter}
          resetFilters={handleReset}
        />
      </FormGroup>
    </div>
  );
};

export default ChangeCaseConversationsFilters;
