import { GridRowModel } from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';
import useUpsertDashboardReportSchedule from '../../../../../../../../../../../api/mutations/dashboards/useUpsertDashboardReportSchedule';
import useDashboardReportSchedules from '../../../../../../../../../../../api/queries/dashboards/useDashboardReportSchedules';
import { useOrganizationPathParams } from '../../../../../../../../../../../hooks/paths/useOrganizationPathParams';
import {
  DashboardReportDestinationType,
  DashboardReportSchedule,
} from '../../../../../../../../../../../interfaces/dashboards';
import { ReportScheduleTableTypes } from '../types';

const getReportScheduleEntries = (
  schedules: DashboardReportSchedule[]
): ReportScheduleTableTypes.ReportScheduleTableEntry[] => {
  let entries: ReportScheduleTableTypes.ReportScheduleTableEntry[] = [];

  schedules.forEach((schedule) => {
    if (schedule.destination_type === DashboardReportDestinationType.Email)
      entries.push({ ...schedule, destination_id: schedule.email });

    if (schedule.destination_type === DashboardReportDestinationType.Member)
      entries.push({ ...schedule, destination_id: schedule.member_id });

    if (
      schedule.destination_type === DashboardReportDestinationType.AccessProfile
    )
      entries.push({ ...schedule, destination_id: schedule.access_profile_id });
  });

  entries = entries.map((entry) => ({
    ...entry,
    date_from: entry.date_from.slice(0, entry.date_from.indexOf('T')),
  }));

  return entries;
};

export const useRows = (schedules: DashboardReportSchedule[]) => {
  const { organizationId } = useOrganizationPathParams();

  const [rows, setRows] = useState<
    ReportScheduleTableTypes.ReportScheduleTableEntry[]
  >(getReportScheduleEntries(schedules));

  useEffect(() => {
    setRows(getReportScheduleEntries(schedules));
  }, [schedules]);

  const dashboardReportSchedules = useDashboardReportSchedules({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const handleRefetch = async () => {
    await dashboardReportSchedules.refetch();
  };

  const { mutateAsync: upsertAsync } = useUpsertDashboardReportSchedule({
    params: { organizationId },
    options: { successFb: handleRefetch, errorFb: handleRefetch },
  });

  const processRowUpdate = useCallback(
    async (
      newRow: GridRowModel<ReportScheduleTableTypes.ReportScheduleTableEntry>
    ) => {
      const updatedRow = { ...newRow, isNew: false };
      setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
      await upsertAsync({
        ...updatedRow,
        date_from: new Date(updatedRow.date_from).toISOString(),
        organization_id: organizationId,
      });
      return updatedRow;
    },
    [organizationId, rows, upsertAsync]
  );

  return { rows, setRows, processRowUpdate };
};
