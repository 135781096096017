import { useCallback } from 'react';
import {
  ConversationQuestion,
  RangeValidConversationQuestion,
} from '../../../../../../../interfaces/conversations';
import {
  IRangeDashboardFilter,
  IRangeDashboardTemplateFilter,
} from '../../../../../../../interfaces/dashboards';

const INVALID_RANGE_ERROR = `Range filter selection invalid: 'selected_all' false and 'selected' null`;

export const useRangeOptions = (questions: ConversationQuestion[]) => {
  const select = useCallback(
    (
      dashboardFilter: IRangeDashboardFilter,
      filter: IRangeDashboardTemplateFilter
    ) => {
      if (!filter.selected_all) {
        if (!filter.selected) throw new Error(INVALID_RANGE_ERROR);
        dashboardFilter.options = filter.selected;
        return dashboardFilter;
      }

      const conversationQuestion = questions?.find(
        (p) => p.resource_id === filter.id
      );

      const questionContent = conversationQuestion?.content as
        | RangeValidConversationQuestion
        | undefined;

      const minNumberValue = questionContent?.min_number_value ?? 0;
      const maxNumberValue = questionContent?.max_number_value ?? 100;

      dashboardFilter.options = {
        min_number_value: minNumberValue,
        max_number_value: maxNumberValue,
      };
      return dashboardFilter;
    },
    [questions]
  );

  return {
    select,
  };
};
