import { Meaning, ObservationSymptom } from 'interfaces/timeline';

export const iconColors = {
  delete: '#dc3545',
  normal: '#222222',
  edit: '#237fc0',
  time: '#237fc0',
  white: '#ffffff',
};

export enum GraspColors {
  GREEN = 'green',
  BLUE = 'blue',
  ORANGE = 'orange',
  WHITE = 'white',
  BLACK = 'black',
  Flow600 = 'Flow600',
  Flow500 = 'Flow500',
  Flow400 = 'Flow400',
  Beige600 = 'Beige600',
  Beige500 = 'Beige500',
  Beige400 = 'Beige400',
  LightBlue600 = 'LightBlue600',
  LightBlue500 = 'LightBlue500',
  Warm600 = 'Warm600',
  Warm500 = 'Warm500',
  Silver600 = 'Silver600',
  Silver500 = 'Silver500',
  Ocean600 = 'Ocean600',
  Ocean500 = 'Ocean500',
}

export const graspColorsValues = {
  [GraspColors.BLUE]: '#407DBB',
  [GraspColors.GREEN]: '#48A447',
  [GraspColors.ORANGE]: '#F5842A',
  [GraspColors.WHITE]: '#FFFFFF',
  [GraspColors.BLACK]: '#000000',
  [GraspColors.Flow600]: '#263E5E',
  [GraspColors.Flow500]: '#53647F',
  [GraspColors.Flow400]: '#929EB2',
  [GraspColors.Beige600]: '#EEECE7',
  [GraspColors.Beige500]: '#F4F3F2',
  [GraspColors.Beige400]: '#F7F7F7',
  [GraspColors.LightBlue600]: '#AFD0D6',
  [GraspColors.LightBlue500]: '#C1E3EA',
  [GraspColors.Warm600]: '#FF896B',
  [GraspColors.Warm500]: '#FFAF96',
  [GraspColors.Silver600]: '#D0D8D8',
  [GraspColors.Silver500]: '#E7EEEE',
  [GraspColors.Ocean600]: '#054F80',
  [GraspColors.Ocean500]: '#0B6BAA',
};

export const meaningColors = {
  [Meaning.SymptomAnxiety]: '#C2E0A2',
  [Meaning.SymptomAppetiteLack]: '#FEB3FF',
  [Meaning.MicroChoiceChoiceMade]: '#6FD7A7',
  [Meaning.SymptomDepression]: '#1B8798',
  [Meaning.SymptomDrowsiness]: '#EC4C7E',
  [Meaning.SymptomNausea]: '#6F6CFF',
  [Meaning.SymptomPain]: '#407DBB',
  [Meaning.SymptomBreathShortness]: '#84F3FA',
  [Meaning.MicroChoiceTemptation]: '#FFBABB',
  [Meaning.SymptomTiredness]: '#605473',
  [Meaning.NotSpecified]: '#8EB3D6',
  [Meaning.Other]: '#004242',
};

export const observationSymptomColors = {
  [ObservationSymptom.SymptomAnxiety]: '#C2E0A2',
  [ObservationSymptom.SymptomAppetiteLack]: '#FEB3FF',
  [ObservationSymptom.MicroChoiceChoiceMade]: '#6FD7A7',
  [ObservationSymptom.SymptomDepression]: '#1B8798',
  [ObservationSymptom.SymptomDrowsiness]: '#EC4C7E',
  [ObservationSymptom.SymptomNausea]: '#6F6CFF',
  [ObservationSymptom.SymptomPain]: '#407DBB',
  [ObservationSymptom.SymptomBreathShortness]: '#84F3FA',
  [ObservationSymptom.MicroChoiceTemptation]: '#FFBABB',
  [ObservationSymptom.SymptomTiredness]: '#605473',
  [ObservationSymptom.NotSpecified]: '#8EB3D6',
  [ObservationSymptom.Other]: '#004242',
};

export const returnColorBaseOnMeaning = (meaning: Meaning) => {
  return meaningColors[meaning];
};

export const returnColorBaseOnSymptom = (symptom: ObservationSymptom) => {
  return observationSymptomColors[symptom];
};

export const lighten = (color: string, amount: number) => {
  return (
    '#' +
    color
      .replace(/^#/, '')
      .replace(/../g, (color) =>
        (
          '0' +
          Math.min(255, Math.max(0, parseInt(color, 16) + amount)).toString(16)
        ).slice(-2)
      )
  );
};

export const hexToRgba = (hex: string, alpha: number = 1) => {
  const [r, g, b] = hex?.match(/\w\w/g)?.map((x) => parseInt(x, 16)) ?? [
    0, 0, 0,
  ];
  return `rgba(${r},${g},${b},${alpha})`;
};
