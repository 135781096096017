import React, { useContext } from 'react';
import {
  MaterialModalMode,
  MaterialModalModeContext,
  MaterialModalStepsContext,
} from '../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhotoVideo } from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage } from 'react-intl';
import { useTranslations } from '../../../../../../hooks/useTranslations';
import { useStepTranslations } from './hooks/useStepTranslations';

const MaterialModalHeader = () => {
  const { attachPrefix } = useTranslations(
    'Education.MaterialModal.MaterialModalHeader'
  );

  const mode = useContext(MaterialModalModeContext);
  const { step, steps } = useContext(MaterialModalStepsContext);

  const { sequence, stepName } = useStepTranslations(step, steps);

  return (
    <div className="AddEducationMaterialModal__title">
      <FontAwesomeIcon icon={faPhotoVideo} />
      <div>
        <FormattedMessage
          id={attachPrefix(
            mode === MaterialModalMode.AddMaterial
              ? 'addMaterial'
              : 'editMaterial'
          )}
        />
      </div>
      <span>|</span>
      <div className="d-flex gap-1">
        <div>
          <FormattedMessage id={attachPrefix(sequence)} />
        </div>
        <div>
          <FormattedMessage id={attachPrefix(stepName)} />
        </div>
      </div>
    </div>
  );
};

export default MaterialModalHeader;
