import { useMemo } from 'react';
import useTimelineObservationsForPeriod from '../../../../../api/queries/timelines/useTimelineObservationsForPeriod';
import { getDateRangeForDate } from '../../../../../helpers/dates';
import { getCaseMasterTimeline } from '../../../../../helpers/timelines';
import useAsyncEventListen from '../../../../../hooks/events/useAsyncEventListen';
import { useInitQuery } from '../../../../../hooks/queries/useInitQuery';
import { AllSqueezesQueries } from '../../../types';

export const useObservationsQuery = (
  date: Date,
  queries: AllSqueezesQueries
) => {
  useInitQuery(queries.caseDetails);

  const masterTimelineId = useMemo(
    () => getCaseMasterTimeline(queries.caseDetails.data),
    [queries.caseDetails.data]
  );

  const dateRange = getDateRangeForDate(date);

  const observationsForPeriodQuery = useTimelineObservationsForPeriod({
    params: {
      timelineId: masterTimelineId ?? '',
      from: dateRange.from,
      to: dateRange.to,
    },
    options: { enabled: !!masterTimelineId },
  });

  useAsyncEventListen(
    'all-squeezes-refetch-requested',
    async () => {
      await observationsForPeriodQuery.refetch();
    },
    true
  );

  return { observationsForPeriodQuery };
};
