import React from 'react';
import { Container } from 'reactstrap';
import { useCaseGroupsCommonData } from '../../hooks/useCaseGroupsCommonData';
import { FormattedMessage } from 'react-intl';
import SVG from 'react-inlinesvg';

import exclamationMarkIcon from 'assets/images/exclamation_mark.svg';
import CaseGroupsUnassignedCasesItem from './components/CaseGroupsUnassignedCasesItem';
import { useInitQuery } from '../../../../hooks/queries/useInitQuery';

const CaseGroupsUnassignedCases = () => {
  const { ungroupedCasesCaseGroup } = useCaseGroupsCommonData();

  useInitQuery(ungroupedCasesCaseGroup);

  return (
    <Container fluid={true} className="UnassignedCasesList">
      <span className="UnassignedCasesList__title">
        <FormattedMessage id="CaseGroups.UnassignedCasesList.title" />
        <SVG src={exclamationMarkIcon} />
      </span>
      <div className="CaseGroupCollection CaseGroupCollection--grid">
        {(ungroupedCasesCaseGroup.data?.cases ?? []).map((caseDetails) => (
          <CaseGroupsUnassignedCasesItem
            key={caseDetails.id}
            groupId={null}
            caseDetails={caseDetails}
          />
        ))}
      </div>
    </Container>
  );
};

export default CaseGroupsUnassignedCases;
