import React, { useState } from 'react';
import { Alert, Button, Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import classNames from 'classnames';

//types
import { IPatchObject } from 'interfaces/request';
import { ITimelineDetails } from 'interfaces/timeline';

//helpers
import useTimelineDelete from 'api/mutations/timelines/useTimelineDelete';
import useTimelineEdit from 'api/mutations/timelines/useTimelineEdit';
import useDescriptionEdit from 'hooks/useDescriptionEdit';
import useUserData from 'api/queries/membership/useUserData';

//helpers
import { formatDate } from 'helpers/dates';
import { returnTimelineTypes } from 'helpers/utils/translationObject';
import { getUserId } from 'helpers/utils/auth';

//components
import ConfirmModal from 'components/ConfirmModal';
import TimelineSharingRequests from './TimelineSharingRequests';
import SkeletonPlaceholderOnLoad from 'components/SkeletonPlaceholderOnLoad';
import HeaderDetail from '../../../components/HeaderDetail';
import HeaderSummary from './HeaderSummary';
import InputWithDisabledDivDisplay from 'components/InputWithDisabledDivDisplay';
import ExpandableTextareaField from 'components/ExpandableTextareaField';
import CaseTimelineHeader from 'components/CaseTimelineHeader';

//icons
import GraspIcon from 'assets/images/timeline_details/grasp_icon.svg';
import Circles from 'assets/images/timeline_details/circles.svg';
import DefaultAvatar from 'assets/images/default_avatar.svg';

type TimelineDetailsHeaderProps = {
  timelineDetails: ITimelineDetails | undefined;
  isLoading: boolean;
  timelineId: string;
};

const TimelineDetailsHeader = ({
  timelineDetails,
  isLoading,
  timelineId,
}: TimelineDetailsHeaderProps) => {
  const intl = useIntl();
  const userId = getUserId();
  const [isEdited, setIsEdit] = useState(false);

  const toggleEdit = () => setIsEdit((currentEditState) => !currentEditState);
  const isOwner = userId === timelineDetails?.user_id;

  const isUserEnabled = !!timelineDetails && !isLoading;

  const { data: userData } = useUserData({
    params: { userId: timelineDetails?.user_id ?? '' },
    options: { enabled: isUserEnabled },
  });

  const { mutate: editTimelineMutation } = useTimelineEdit(
    userId,
    timelineDetails?.id as string
  );

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const toggleConfirmModal = () => {
    setIsDeleteModalOpen((isModalOpen) => !isModalOpen);
  };

  const { mutate: removeTimelineMutation } = useTimelineDelete(
    userId,
    toggleConfirmModal
  );

  const confirmRemoveTimeline = () => {
    removeTimelineMutation(timelineDetails?.id as string);
  };

  const onSaveClick = (params: IPatchObject[]) => {
    editTimelineMutation(params);
    toggleEdit();
  };

  const { onCancel, onEdit, onSubmit } = useDescriptionEdit({
    cancelFb: toggleEdit,
    submitFunction: onSaveClick,
    notClearOnSubmit: true,
  });

  const timelineTypes = returnTimelineTypes(intl);
  const isDisabled = !isOwner || !isEdited;
  const isMasterTimeline = timelineDetails?.is_master;

  const [isHeaderHidden, setHeaderHidden] = useState(true);
  const onHideClick = () => setHeaderHidden((val) => !val);

  const hiddenClass = isHeaderHidden ? 'CaseTimelineHeader__hidden' : '';
  const name = `${userData?.personality?.first_name ?? ''}  ${
    userData?.personality?.last_name ?? ''
  }`;

  const renderTimelineHeader = () => {
    if (isHeaderHidden) {
      return (
        <div className="d-flex align-items-center">
          <div className="TimelineHeader__thumbnail-container TimelineHeader__thumbnail-container--hidden">
            <div className="TimelineHeader__thumbnail-container__thumbnail">
              <img src={DefaultAvatar} alt="avatar" />
            </div>
            <div className="TimelineHeader__thumbnail-container__icon">
              <img src={Circles} alt="circles" />
            </div>
          </div>
          <SkeletonPlaceholderOnLoad isLoading={isLoading}>
            <div className="TimelineHeader__user-name TimelineHeader__user-name--hidden">
              {name}
            </div>
          </SkeletonPlaceholderOnLoad>
          <SkeletonPlaceholderOnLoad isLoading={isLoading}>
            <div className="TimelineHeader__title TimelineHeader__title--hidden">
              {timelineDetails?.name}
            </div>
          </SkeletonPlaceholderOnLoad>
        </div>
      );
    } else {
      return (
        <>
          <Col md={{ size: 11 }} lg={{ size: 2 }}>
            <div className="d-flex align-items-center">
              <div className="TimelineHeader__thumbnail-container">
                <div className="TimelineHeader__thumbnail-container__thumbnail">
                  <img src={DefaultAvatar} alt="avatar" />
                </div>
                <div className="TimelineHeader__thumbnail-container__icon">
                  <img src={Circles} alt="circles" />
                </div>
              </div>
              <div className="TimelineHeader__user-name">{name}</div>
            </div>
            <SkeletonPlaceholderOnLoad
              isLoading={isLoading}
              placeholder={<Skeleton height={50} className="mb-2" />}
            >
              <InputWithDisabledDivDisplay
                disabled={isDisabled}
                value={timelineDetails?.name ?? ''}
                name="name"
                inputCssClass="TimelineHeader__title"
                onEdit={onEdit}
              />
            </SkeletonPlaceholderOnLoad>
            <div className={hiddenClass}>
              <SkeletonPlaceholderOnLoad isLoading={isLoading}>
                <InputWithDisabledDivDisplay
                  disabled={isDisabled}
                  value={timelineDetails?.type ?? ''}
                  name="type"
                  type="select"
                  options={timelineTypes}
                  inputCssClass="TimelineHeader__type"
                  onEdit={onEdit}
                />
              </SkeletonPlaceholderOnLoad>
              <SkeletonPlaceholderOnLoad
                isLoading={isLoading}
                placeholder={<Skeleton height={150} />}
              >
                <ExpandableTextareaField
                  divCssClass="TimelineHeader__description"
                  inputCssClass="TimelineHeader_description--textarea"
                  disabled={isDisabled}
                  value={timelineDetails?.description ?? ''}
                  name="description"
                  onEdit={onEdit}
                />
              </SkeletonPlaceholderOnLoad>
            </div>
          </Col>
          <Col md={6} lg={3} className={hiddenClass}>
            <HeaderDetail
              isLoading={isLoading}
              iconSrc={GraspIcon}
              header="First observation"
            >
              {timelineDetails?.first_observation_at
                ? formatDate(timelineDetails.first_observation_at)
                : '-'}
            </HeaderDetail>
            <HeaderDetail
              isLoading={isLoading}
              iconSrc={GraspIcon}
              header="Last observation"
            >
              {timelineDetails?.last_observation_at
                ? formatDate(timelineDetails.last_observation_at)
                : '-'}
            </HeaderDetail>
            {isOwner && (
              <TimelineSharingRequests
                timelineId={timelineId}
                userId={userId}
              />
            )}
            <div className="w-100 d-flex justify-content-end">
              {isEdited && (
                <div className="mt-1">
                  <Button
                    className="me-2 transparent-with-border"
                    color="primary"
                    outline
                    onClick={onCancel}
                  >
                    <FormattedMessage id="General.cancel" />
                  </Button>
                  <Button color="primary" onClick={onSubmit}>
                    <FormattedMessage id="General.save" />
                  </Button>
                </div>
              )}
            </div>
          </Col>
          <Col md={6} className={hiddenClass}>
            {timelineDetails && (
              <HeaderSummary
                title="Summary"
                timelineId={timelineDetails.id}
                userId={timelineDetails.user_id}
              />
            )}
          </Col>
        </>
      );
    }
  };

  return (
    <>
      <CaseTimelineHeader
        headerClass={classNames('TimelineHeader', {
          'TimelineHeader--hidden': isHeaderHidden,
        })}
        isDisabled={!!isMasterTimeline}
        isEdited={isEdited}
        onDeleteClick={toggleConfirmModal}
        canEdit={isOwner}
        onEditClick={toggleEdit}
        onHideClick={onHideClick}
        isHidden={isHeaderHidden}
      >
        <Row>{renderTimelineHeader()}</Row>
      </CaseTimelineHeader>
      <ConfirmModal
        isOpen={isDeleteModalOpen}
        message={
          <div>
            {timelineDetails?.is_default && (
              <Alert color="danger">
                <FormattedMessage id="TimelineDetails.itsGraspDefaultTimeline" />
              </Alert>
            )}
            {timelineDetails?.is_shared && (
              <Alert color="danger">
                <FormattedMessage id="TimelineDetails.itsSharedTimeline" />
              </Alert>
            )}
            <FormattedMessage id="General.doYouRemoveTimeline" />
          </div>
        }
        toggle={toggleConfirmModal}
        confirm={confirmRemoveTimeline}
      />
    </>
  );
};

export default TimelineDetailsHeader;
