import { useState } from 'react';
import { Col, Row } from 'reactstrap';

//types
import { GraspInfoMode } from 'interfaces/grasps';

//helpers
import useOrganizationPatientDetails from 'api/queries/patients/useOrganizationPatientDetails';
import useRequiredParams from 'hooks/useRequiredParams';

//components
import PatientHeader from './components/PatientHeader';
import PersonalityCasesList from './components/PersonalityCasesList';
import GraspsInfo from 'modules/Grasps/components/GraspsInfo';
import PatientMedicalPersonnel from './components/PatientMedicalPersonnel';

const OrganizationPersonality = () => {
  const { organizationId, personalityId } = useRequiredParams<{
    organizationId: string;
    personalityId: string;
  }>(['organizationId', 'personalityId']);

  const [editDisabled, setEditDisabled] = useState(true);

  const toggleEdit = () => setEditDisabled((value) => !value);

  const { data, isLoading, refetch } = useOrganizationPatientDetails({
    params: {
      organizationId,
      personalityId,
    },
  });

  return (
    <Row>
      <Col sm={12} md={12} xl={8} className="patient-col mt-4">
        <PatientHeader
          personalityId={personalityId}
          data={data}
          isLoading={isLoading}
          editDisabled={editDisabled}
          toggleEdit={toggleEdit}
          refetchFunction={refetch}
        />
      </Col>
      <Col sm={12} md={12} xl={4} className="patient-col mt-4">
        <PatientMedicalPersonnel
          isLoading={isLoading}
          personalityId={personalityId}
          organizationId={organizationId}
          patientMedicalPersonnel={data?.members_with_access}
          refetchFunction={refetch}
        />
      </Col>
      <Col sm={12} md={12} xl={8} className="patient-col px-0">
        <PersonalityCasesList
          organizationId={organizationId}
          personalityId={personalityId}
          cases={data?.cases}
          refetchFunction={refetch}
        />
      </Col>
      <Col sm={12} md={12} xl={4} className="patient-col">
        {data && (
          <GraspsInfo
            personalityId={personalityId}
            mode={GraspInfoMode.DOCTOR}
            organizationId={organizationId}
          />
        )}
      </Col>
    </Row>
  );
};

export default OrganizationPersonality;
