import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import { invitationReject } from 'api/patients';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';

function useInvitationReject(invitationId: string, successFb?: () => void) {
  return useMutationWithResultNotification({
    asyncApiFunction: async (userId: string) => {
      await invitationReject(userId, invitationId);
    },
    resetOnResult: true,
    onSuccess: () => {
      successFb && successFb();
      displaySuccessNotification('InvitationScreen.successfullyReject');
    },
    onErrorArray: (errors) => {
      displayErrorArrayNotifications(errors, 'InvitationScreen.errors');
    },
  });
}

export default useInvitationReject;
