import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faPencil,
  faTimes,
  faUndo,
} from '@fortawesome/pro-light-svg-icons';
import { useFieldApplicableInput } from './hooks/useFieldApplicableInput';

type FieldApplicableInputProps<T> = {
  initialValues: T;
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void;
  name: string;
  field: React.ReactNode;
};

const FieldApplicableInput = <T,>({
  initialValues,
  setFieldValue,
  name,
  field,
}: FieldApplicableInputProps<T>) => {
  const { isEditMode, toggleEditMode, onUndo, onClose } =
    useFieldApplicableInput(initialValues, setFieldValue, name);

  return (
    <>
      {isEditMode ? (
        <div className="d-flex w-100 align-items-end gap-3">
          <div className="w-100">{field}</div>
          <div className="d-flex align-items-center gap-1">
            <Button onClick={toggleEditMode} color="primary" outline={true}>
              <FontAwesomeIcon icon={faCheck} />
            </Button>
            <Button onClick={onUndo} color="primary" outline={true}>
              <FontAwesomeIcon icon={faUndo} />
            </Button>
            <Button onClick={onClose} color="primary" outline={true}>
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </div>
        </div>
      ) : (
        <div className="d-flex w-100 align-items-end gap-2">
          <div className="w-100">
            {React.cloneElement(field as React.ReactElement, {
              disabled: true,
            })}
          </div>
          <Button onClick={toggleEditMode} color="primary" outline={true}>
            <FontAwesomeIcon icon={faPencil} />
          </Button>
        </div>
      )}
    </>
  );
};

export default FieldApplicableInput;
