import { DashboardTemplateDataSource } from 'interfaces/dashboards';
import React from 'react';
import PathResolver from '../../resolvers/PathResolver';
import DataMappingBasedContainer from './components/DataMappingBasedContainer';
import DataMappingInvariantContainer from './components/DataMappingInvariantContainer';
import { DashboardContentContainerProps } from './types';

const DashboardContentContainer = ({
  template,
}: DashboardContentContainerProps) => {
  return (
    <PathResolver template={template}>
      {(resolvedTemplate) => (
        <>
          {resolvedTemplate.data_source ===
          DashboardTemplateDataSource.AggregatedAwareSessionData ? (
            <DataMappingBasedContainer template={resolvedTemplate} />
          ) : (
            <DataMappingInvariantContainer template={resolvedTemplate} />
          )}
        </>
      )}
    </PathResolver>
  );
};

export default DashboardContentContainer;
