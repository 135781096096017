import request from 'api/utils/request';
import { getOdataFilterParams } from 'helpers/utils/getOdataParams';
import { normalizeQueryParams } from 'helpers/utils/normalizeQueryParams';
import {
  AssessmentQuestion,
  AssessmentSummary,
  AssignmentContextType,
  GetAssessmentFormsTemplatesQueryParams,
  IAssessmentFormAssignment,
  IAssessmentPeriodInfo,
  IAssessmentTemplate,
  IAssignmentUpsertBody,
  ISubmissionUserAssessmentUpsertBody,
  IUserAssessments,
  IUserAssessmentUpsertBody,
  ScheduleInfo,
} from 'interfaces/assessmentForms';
import { IRequestShareTimelineResponse } from 'interfaces/cases';
import {
  GraspCreateBody,
  GraspFeedbackBody,
  GraspFilters,
  GraspSelection,
  IActiveGrasp,
  ICreateGraspLedLevel,
  IGrasp,
  IGraspFound,
  IGraspLedLevel,
  IGraspOrganization,
  IGraspPossession,
  IGraspSharing,
  IInfiniteGraspOrganization,
  ILinkGraspBody,
  IOrganizationGraspSettings,
} from 'interfaces/grasps';
import { IParamsTypeInRequest } from 'interfaces/reducer';
import { IPatchObject, ODataParamType } from 'interfaces/request';
import { IResponse, IResponseWithPagination } from 'interfaces/response';
import {
  GetTimelineObservationsQueryParams,
  IAnnotationBody,
  IObservation,
  IObservationCreateBody,
  ISqueezeChartData,
  ITimelineAnnotation,
  ITimelineDetails,
  IUserTimeline,
  paramsForTimelineSqueezeChart,
} from 'interfaces/timeline';
import { IConversationTemplatePlainTextItem } from '../interfaces/conversations';

export const findGrasp = (token) =>
  request<never, IResponse<IGraspFound>>({
    url: `/grasps/find/${token}`,
    method: 'GET',
  });

export const getGraspFromOrganization = (organizationId, graspId) =>
  request<never, IResponse<IGraspOrganization>>({
    url: `/organizations/${organizationId}/grasps/${graspId}`,
    method: 'GET',
  });

export const updateUserGrasp = (userId, graspId, data) =>
  request({
    url: `/users/${userId}/grasps/${graspId}`,
    method: 'PATCH',
    data,
  });

export const getUserGrasp = (userId: string) =>
  request<never, IResponse<IGraspPossession[]>>({
    url: `/users/${userId}/grasps/active?pageSize=100`,
    method: 'GET',
  });

export const getOrganizationUserActiveGrasp = (
  organizationId: string,
  personalityId: string
) =>
  request<never, IResponse<IActiveGrasp[]>>({
    url: `/organizations/${organizationId}/personalities/${[
      personalityId,
    ]}/grasps/active`,
    method: 'GET',
  });

export const unlinkGrasp = (userId, graspId) =>
  request({
    url: `/users/${userId}/grasps/${graspId}`,
    method: 'DELETE',
  });

export const unlinkTimeline = (userId: string, timelineId: string) =>
  request({
    url: `/users/${userId}/timelines/${timelineId}`,
    method: 'DELETE',
  });

export const editTimeline = (
  userId: string,
  timelineId: string,
  patchData: IPatchObject[]
) =>
  request({
    url: `/users/${userId}/timelines/${timelineId}`,
    method: 'PATCH',
    data: patchData,
  });

export const getTimelines = (userId: string) =>
  request<undefined, IResponse<IUserTimeline[]>>({
    url: `/users/${userId}/timelines`,
    method: 'GET',
  });

export const getGrasps = (
  params: Partial<IParamsTypeInRequest>,
  filters?: GraspFilters
) => {
  const { filterParams, oDataFilterString } = _getStatusFilter(filters);
  const otherFilters = _getOtherFilters(filters);

  const isNotFirstFilter = filterParams.length > 0;
  const oDataOtherFilterString = getOdataFilterParams(otherFilters, {
    startCharacter: isNotFirstFilter ? ' and ' : '&',
    logicalOperation: 'and',
    isNotFirstFilter,
  });

  return request<never, IResponseWithPagination<IGrasp[]>>({
    url: `/grasps${normalizeQueryParams(
      params
    )}${oDataFilterString}${oDataOtherFilterString}`,
    method: 'GET',
  });
};

export const getOrganizationGraspsList = (
  organizationId: string,
  params: Partial<IParamsTypeInRequest>,
  filters?: GraspFilters
) => {
  const { filterParams, oDataFilterString } = _getStatusFilter(filters);
  const otherFilters = _getOtherFilters(filters);

  const isNotFirstFilter = filterParams.length > 0;
  const oDataOtherFilterString = getOdataFilterParams(otherFilters, {
    startCharacter: isNotFirstFilter ? ' and ' : '&',
    logicalOperation: 'and',
    isNotFirstFilter,
  });

  return request<never, IResponseWithPagination<IInfiniteGraspOrganization[]>>({
    url: `/organizations/${organizationId}/grasps${normalizeQueryParams(
      params
    )}${oDataFilterString}${oDataOtherFilterString}`,
    method: 'GET',
  });
};

export const getTimelineDetails = (timelineId: string) =>
  request<never, IResponse<ITimelineDetails>>({
    url: `/timelines/${timelineId}`,
    method: 'GET',
  });

export const getTimelineDataForSqueezeChart = (
  timelineId: string,
  queryParams: paramsForTimelineSqueezeChart
) =>
  request<never, IResponse<ISqueezeChartData>>({
    url: `/timelines/${timelineId}/observations/timeline${normalizeQueryParams(
      queryParams
    )}`,
    method: 'GET',
  });

export const getUserContextTimelineObservations = (
  timelineId: string,
  queryParams: GetTimelineObservationsQueryParams,
  oDataQueryParams: ODataParamType[]
) => {
  return request<never, IResponse<IObservation[]>>({
    url: `/timelines/${timelineId}/observations${normalizeQueryParams(
      queryParams
    )}${getOdataFilterParams(oDataQueryParams, { startCharacter: '&' })}`,
    method: 'GET',
  });
};

export const getCaseContextTimelineObservations = (
  organizationId: string,
  caseId: string,
  timelineId: string,
  queryParams: GetTimelineObservationsQueryParams,
  oDataQueryParams: ODataParamType[]
) => {
  return request<never, IResponse<IObservation[]>>({
    url: `/organizations/${organizationId}/cases/${caseId}/timelines/${timelineId}/observations${normalizeQueryParams(
      queryParams
    )}${getOdataFilterParams(oDataQueryParams, { startCharacter: '&' })}`,
    method: 'GET',
  });
};

export const getTimelineShareResponses = (userId: string, timelineId: string) =>
  request<ILinkGraspBody, IResponse<IRequestShareTimelineResponse[]>>({
    url: `/users/${userId}/timelines/${timelineId}/timeline_requests`,
    method: 'GET',
  });

export const addTimelineToAcceptedRequest = (
  userId: string,
  requestId: string,
  timelines: string[]
) =>
  request<{ timelines: string[] }, unknown>({
    url: `/users/${userId}/timeline_requests/${requestId}/timelines`,
    method: 'POST',
    data: { timelines },
  });

export const removeTimelineFromAcceptedRequest = (
  userId: string,
  requestId: string,
  timelineId: string
) =>
  request({
    url: `/users/${userId}/timeline_requests/${requestId}/timelines/${timelineId}`,
    method: 'DELETE',
  });

export const getTimelineAnnotations = (
  timelineId: string,
  oDataParams: ODataParamType[]
) =>
  request<never, IResponse<ITimelineAnnotation[]>>({
    url: `/timelines/${timelineId}/annotations?page_size=1000${getOdataFilterParams(
      oDataParams,
      {
        startCharacter: '&',
      }
    )}`,
    method: 'GET',
  });

export const createTimelineAnnotation = (
  timelineId: string,
  data: IAnnotationBody
) =>
  request<IAnnotationBody, IResponse<ITimelineAnnotation[]>>({
    url: `/timelines/${timelineId}/annotations`,
    method: 'POST',
    data,
  });

export const deleteAnnotation = (annotationId: string) =>
  request({
    url: `/annotations/${annotationId}`,
    method: 'DELETE',
  });

export const editAnnotation = (
  annotationId: string,
  patchData: IPatchObject[]
) =>
  request<IPatchObject[], IResponse<IAnnotationBody>>({
    url: `/annotations/${annotationId}`,
    method: 'PATCH',
    data: patchData,
  });

export const shareGraspWithOrganizationUser = (
  organizationId: string,
  personalityId: string,
  data: {
    date_from?: string;
    grasp_uuid: string;
  }
) =>
  request({
    url: `/organizations/${organizationId}/personalities/${personalityId}/grasps`,
    method: 'POST',
    data,
  });

export const unshareGraspWithOrganizationUser = (
  organizationId: string,
  personalityId: string,
  graspId: string
) =>
  request({
    url: `/organizations/${organizationId}/personalities/${personalityId}/grasps/${graspId}`,
    method: 'DELETE',
  });

export const getGraspSharingList = (
  organizationId: string,
  graspId: string,
  pagination: Partial<IParamsTypeInRequest>
) =>
  request<never, IResponseWithPagination<IGraspSharing[]>>({
    url: `/organizations/${organizationId}/grasps/${graspId}/sharing${normalizeQueryParams(
      pagination
    )}`,
    method: 'GET',
  });

export const sendFeedback = (data: GraspFeedbackBody) =>
  request<GraspFeedbackBody, void>({
    url: `/feedback`,
    method: 'POST',
    data,
  });

export const createGraspForOrganization = (
  organizationId: string,
  data: GraspCreateBody
) =>
  request<GraspCreateBody, void>({
    url: `/organizations/${organizationId}/grasps`,
    method: 'POST',
    data,
  });

export const deleteGraspFromOrganization = (
  organizationId: string,
  graspId: string
) =>
  request<void, void>({
    url: `/organizations/${organizationId}/grasps/${graspId}`,
    method: 'DELETE',
  });

export const editOrganizationGrasp = (
  organizationId: string,
  graspId: string,
  data: IPatchObject[]
) =>
  request<IPatchObject[], void>({
    url: `/organizations/${organizationId}/grasps/${graspId}`,
    method: 'PATCH',
    data,
  });

export const addTimelineObservation = (
  timelineId: string,
  data: IObservationCreateBody
) =>
  request<IObservationCreateBody, void>({
    url: `/timelines/${timelineId}/observations`,
    method: 'POST',
    data,
  });

export const deleteTimelineObservation = (
  timelineId: string,
  observationId: string
) =>
  request<IObservationCreateBody, void>({
    url: `/timelines/${timelineId}/observations/${observationId}`,
    method: 'DELETE',
  });

export const editTimelineObservation = (
  timelineId: string,
  observationId: string,
  patchData: IPatchObject[]
) =>
  request<IPatchObject[], IResponse<IObservation>>({
    url: `/timelines/${timelineId}/observations/${observationId}`,
    method: 'PATCH',
    data: patchData,
  });

export const getUserContextAssessmentFormsForDate = (
  userId: string,
  timelineId: string,
  year: number,
  month: number,
  day: number
) => {
  return request<void, IResponse<IUserAssessments[]>>({
    url: `/users/${userId}/timelines/${timelineId}/assessment_forms/${year}/${month}/${day}`,
    method: 'GET',
  });
};

export const getOrganizationContextAssessmentFormsForDate = (
  organizationId: string,
  caseId: string,
  timelineId: string,
  year: number,
  month: number,
  day: number
) => {
  return request<void, IResponse<IUserAssessments[]>>({
    url: `/organizations/${organizationId}/cases/${caseId}/timelines/${timelineId}/assessment_forms/${year}/${month}/${day}`,
    method: 'GET',
  });
};

export const getUserContextAssessmentFormsForPeriod = (
  userId: string,
  timelineId: string,
  startDate: Date,
  endDate: Date
) => {
  return request<void, IResponse<IAssessmentPeriodInfo[]>>({
    url: `/users/${userId}/timelines/${timelineId}/assessment_forms/period?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
    method: 'GET',
  });
};

export const getOrganizationContextAssessmentFormsForPeriod = (
  organizationId: string,
  caseId: string,
  timelineId: string,
  startDate: Date,
  endDate: Date
) => {
  return request<void, IResponse<IAssessmentPeriodInfo[]>>({
    url: `/organizations/${organizationId}/cases/${caseId}/timelines/${timelineId}/assessment_forms/period?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`,
    method: 'GET',
  });
};

export const getOrganizationContextAssessmentFormsAssignments = (
  organizationId: string,
  caseId: string,
  timelineId: string
) => {
  return request<void, IResponse<IAssessmentFormAssignment[]>>({
    url: `/organizations/${organizationId}/cases/${caseId}/timelines/${timelineId}/assessment_forms_assignments`,
    method: 'GET',
  });
};

export const getSingleAssessmentTemplate = (code: string) => {
  return request<void, IResponse<IAssessmentTemplate>>({
    url: `/assessment_forms_templates/${code}`,
    method: 'GET',
  });
};

export const getUserContextAssessmentFormSummary = (
  userId: string,
  timelineId: string
) => {
  return request<void, IResponse<AssessmentSummary[]>>({
    url: `/users/${userId}/timelines/${timelineId}/assessment_forms/summary`,
    method: 'GET',
  });
};

export const getOrganizationContextAssessmentFormSummary = (
  organizationId: string,
  caseId: string,
  timelineId: string
) => {
  return request<void, IResponse<AssessmentSummary[]>>({
    url: `/organizations/${organizationId}/cases/${caseId}/timelines/${timelineId}/assessment_forms/summary`,
    method: 'GET',
  });
};

export const userContextUpdateAssessmentForm = (
  userId: string,
  contextObjectId: string | null,
  code: string,
  title: string,
  date: Date,
  sequence: number,
  updateData: AssessmentQuestion[]
) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return request<IUserAssessmentUpsertBody, void>({
    url: `/users/${userId}/timelines/${contextObjectId}/assessment_forms/${year}/${month}/${day}/${code}/${sequence}`,
    method: 'PUT',
    data: {
      code,
      title,
      contextObjectId,
      questions: updateData,
    },
  });
};

export const userContextUpdateSubmissionAssessmentForm = (
  userId: string,
  contextObjectId: string | null,
  code: string,
  title: string,
  date: Date,
  sequence: number,
  updateData: (AssessmentQuestion | IConversationTemplatePlainTextItem)[]
) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return request<ISubmissionUserAssessmentUpsertBody, void>({
    url: `/users/${userId}/submissions/${contextObjectId}/assessment_forms/${year}/${month}/${day}/${code}/${sequence}`,
    method: 'PUT',
    data: {
      code,
      title,
      contextObjectId,
      questions: updateData,
    },
  });
};

export const organizationContextUpdateAssessmentForm = (
  organizationId: string,
  caseId: string,
  contextObjectId: string | null,
  code: string,
  title: string,
  date: Date,
  sequence: number,
  updateData: AssessmentQuestion[]
) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return request<IUserAssessmentUpsertBody, void>({
    url: `/organizations/${organizationId}/cases/${caseId}/timelines/${contextObjectId}/assessment_forms/${year}/${month}/${day}/${code}/${sequence}`,
    method: 'PUT',
    data: {
      code,
      title,
      contextObjectId,
      questions: updateData,
    },
  });
};

export const organizationContextUpdateSubmissionAssessmentForm = (
  organizationId: string,
  caseId: string,
  contextObjectId: string | null,
  code: string,
  title: string,
  date: Date,
  sequence: number,
  updateData: (AssessmentQuestion | IConversationTemplatePlainTextItem)[]
) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return request<ISubmissionUserAssessmentUpsertBody, void>({
    url: `/organizations/${organizationId}/cases/${caseId}/submissions/${contextObjectId}/assessment_forms/${year}/${month}/${day}/${code}/${sequence}`,
    method: 'PUT',
    data: {
      code,
      title,
      contextObjectId,
      questions: updateData,
    },
  });
};

export const userContextUpdateNonAssignedAssessmentForm = (
  userId: string,
  timelineId: string | null,
  code: string,
  title: string,
  date: Date,
  hour: string,
  questions: AssessmentQuestion[]
) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return request<any, void>({
    url: `/users/${userId}/timelines/${timelineId}/assessment_forms/${year}/${month}/${day}/${code}`,
    method: 'PUT',
    data: {
      code,
      title,
      timelineId,
      questions,
      hour,
    },
  });
};

export const organizationContextUpdateNonAssignedAssessmentForm = (
  organizationId: string,
  caseId: string,
  timelineId: string | null,
  code: string,
  title: string,
  date: Date,
  hour: string,
  questions: AssessmentQuestion[]
) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();

  return request<any, void>({
    url: `/organizations/${organizationId}/cases/${caseId}/timelines/${timelineId}/assessment_forms/${year}/${month}/${day}/${code}`,
    method: 'PUT',
    data: {
      code,
      title,
      timelineId,
      questions,
      hour,
    },
  });
};

export const organizationContextUpdateFormAssignment = (
  organizationId: string,
  userId: string,
  formCode: string,
  caseId: string,
  timelineId: string,
  assignmentId: string,
  updateData: ScheduleInfo,
  dateTo: Date | null,
  dateFrom: Date,
  isEditableByOrganizationMembers: boolean,
  activeHoursBefore: number,
  activeHoursAfter: number
) => {
  return request<IAssignmentUpsertBody, void>({
    url: `/organizations/${organizationId}/cases/${caseId}/timelines/${timelineId}/assessment_forms_assignments/${assignmentId}`,
    method: 'PUT',
    data: {
      userId: userId,
      formCode: formCode,
      contextType: AssignmentContextType.Timelines,
      contextObjectId: timelineId,
      dateTo: dateTo,
      dateFrom: dateFrom,
      scheduleInfo: updateData,
      isEditableByOrganizationMembers: isEditableByOrganizationMembers,
      activeHoursBefore,
      activeHoursAfter,
    },
  });
};

export const organizationContextAddFormAssignment = (
  organizationId: string,
  timelineId: string,
  userId: string,
  formCode: string,
  caseId: string,
  updateData: ScheduleInfo,
  dateTo: Date | null,
  isEditableByOrganizationMembers: boolean,
  activeHoursBefore: number,
  activeHoursAfter: number,
  dateFrom?: Date
) => {
  return request<IAssignmentUpsertBody, void>({
    url: `/organizations/${organizationId}/cases/${caseId}/timelines/${timelineId}/assessment_forms_assignments`,
    method: 'POST',
    data: {
      userId: userId,
      formCode: formCode,
      contextType: AssignmentContextType.Timelines,
      contextObjectId: timelineId,
      dateTo: dateTo,
      dateFrom: dateFrom || new Date(),
      scheduleInfo: updateData,
      isEditableByOrganizationMembers,
      activeHoursBefore,
      activeHoursAfter,
    },
  });
};

export const deleteFormAssignment = (caseId: string, assignmentId: string) => {
  return request<IAssignmentUpsertBody, void>({
    url: `/cases/${caseId}/assessment_forms_assignments/${assignmentId}`,
    method: 'DELETE',
  });
};

export const uploadExcelGraspFile = (organizationId: string, file: File) => {
  const formData = new FormData();
  formData.append('File', file);

  return request<FormData, void>({
    url: `/organizations/${organizationId}/grasps/upload_file`,
    method: 'POST',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const addGraspToOrganization = (
  organizationId: string,
  graspId: string
) => {
  return request<void, IInfiniteGraspOrganization>({
    url: `/organizations/${organizationId}/grasps/${graspId}/add_grasp_to_organization`,
    method: 'PUT',
  });
};

export const transferGraspToOrganization = (
  organizationId: string,
  targetOrganizationId: string,
  selection: GraspSelection
) => {
  return request<GraspSelection, IInfiniteGraspOrganization>({
    url: `/organizations/${organizationId}/grasps/transfer_to_organization/${targetOrganizationId}`,
    method: 'POST',
    data: selection,
  });
};

export const updateMultipleOrganizationGrasps = (
  organizationId: string,
  selection: GraspSelection,
  location_id?: string,
  status?: string
) => {
  return request<
    {
      selection: GraspSelection;
      location_id?: string;
      status?: string;
    },
    IInfiniteGraspOrganization
  >({
    url: `/organizations/${organizationId}/grasps`,
    method: 'PUT',
    data: {
      selection,
      location_id,
      status,
    },
  });
};

export const addTemplateCodeIntoOrganization = (
  organization_id: string,
  form_code: string
) => {
  return request<
    {
      form_code: string;
      organization_id: string;
    },
    any
  >({
    url: `/organizations/${organization_id}/assessment_forms_templates`,
    method: 'POST',
    data: {
      form_code,
      organization_id,
    },
  });
};

export const removeTemplateCodeFromOrganization = (
  organizationId: string,
  template_code: string
) => {
  return request<void, any>({
    url: `/organizations/${organizationId}/assessment_forms_templates/${template_code}`,
    method: 'DELETE',
  });
};

export const getOrganizationAssessmentFormsTemplates = (
  organizationId: string,
  params: GetAssessmentFormsTemplatesQueryParams
) => {
  return request<void, IResponse<IAssessmentTemplate[]>>({
    url: `/organizations/${organizationId}/assessment_forms_templates${normalizeQueryParams(
      params
    )}`,
    method: 'GET',
  });
};

export const getGraspsSettings = (organizationId: string) => {
  return request<void, IResponse<IOrganizationGraspSettings>>({
    url: `/organizations/${organizationId}/grasps/settings`,
    method: 'GET',
  });
};

export const createGraspLedLevel = (
  organizationId: string,
  createGraspLedLevel: ICreateGraspLedLevel
) => {
  return request<ICreateGraspLedLevel, IResponse<IGraspLedLevel>>({
    url: `/organizations/${organizationId}/grasps/led_levels`,
    method: 'POST',
    data: createGraspLedLevel,
  });
};

export const deleteGraspLedLevel = (
  organizationId: string,
  ledLevelId: string
) => {
  return request<void, void>({
    url: `/organizations/${organizationId}/grasps/led_levels/${ledLevelId}`,
    method: 'DELETE',
  });
};

export const patchGraspLedLevel = (
  organizationId: string,
  ledLevelId: string,
  patchData: IPatchObject[]
) => {
  return request<IPatchObject[], IResponse<IGraspLedLevel>>({
    url: `/organizations/${organizationId}/grasps/led_levels/${ledLevelId}`,
    method: 'PATCH',
    data: patchData,
  });
};

export const patchGraspSettings = (
  organizationId: string,
  id: string,
  patchData: IPatchObject[]
) => {
  return request<IPatchObject[], IResponse<IOrganizationGraspSettings>>({
    url: `/organizations/${organizationId}/grasps/settings/${id}`,
    method: 'PATCH',
    data: patchData,
  });
};

export const _getStatusFilter = (filters?: GraspFilters) => {
  let statusFilterParams: ODataParamType[] = [];
  if (filters?.statuses && filters?.statuses.length > 0) {
    for (const singleStatus of filters?.statuses) {
      const upperCaseStatus =
        singleStatus.charAt(0).toUpperCase() + singleStatus.slice(1);
      statusFilterParams.push({
        key: 'Grasp/Status',
        operator: 'eq',
        value: `'${upperCaseStatus}'`,
      });
    }
  }
  const oDataStatusFilterString =
    statusFilterParams.length > 0
      ? getOdataFilterParams(statusFilterParams, {
          startCharacter: '&',
          logicalOperation: 'or',
          wrapInBracket: true,
        })
      : '';

  return {
    filterParams: statusFilterParams,
    oDataFilterString: oDataStatusFilterString,
  };
};

export const _getOtherFilters = (filters?: GraspFilters) => {
  let otherFilters: ODataParamType[] = [];
  if (filters?.fromDate) {
    otherFilters.push({
      key: 'CreatedAt',
      operator: 'gt',
      value: filters.fromDate,
    });
  }

  if (filters?.toDate) {
    otherFilters.push({
      key: 'CreatedAt',
      operator: 'lt',
      value: filters.toDate,
    });
  }

  if (filters?.endpoint) {
    otherFilters.push({
      key: 'EndpointId',
      operator: 'eq',
      value: filters.endpoint,
    });
  }

  return otherFilters;
};
