import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { addOrganizationUserAccessProfile } from '../../accessProfiles';
import { IMutationProps } from '../../../interfaces/ui';

export type AddOrganizationUserAccessProfileProps = {
  userId: string;
  accessProfileId: string;
};

type useAddOrganizationUserAccessProfileParams = {
  organizationId: string;
};

export const useAddOrganizationUserAccessProfile = (
  params: useAddOrganizationUserAccessProfileParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (body: AddOrganizationUserAccessProfileProps) => {
      await addOrganizationUserAccessProfile(params.organizationId, {
        user_id: body.userId,
        access_profile_id: body.accessProfileId,
      });
    },
    onSuccess: () => {
      if (options.successFb) options.successFb();
    },
    onErrorArray: (_) => {
      if (options.errorFb) options.errorFb();
    },
    resetOnResult: true,
  });
};
