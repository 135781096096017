import { OrganizationQueryParams } from '../../../interfaces/queryParams';
import { IQueryRequest } from '../../../interfaces/request';
import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getAwareSessionsForPatientNumber } from '../../awareSessions';

type CaseAwareSessionsQueryParams = OrganizationQueryParams & {
  patientNumber: string;
  aggregated: boolean;
};

const useAwareSessionsForPatientNumber = ({
  params,
  options,
}: IQueryRequest<CaseAwareSessionsQueryParams>) => {
  const { organizationId, patientNumber, aggregated } = params;
  return useQuery({
    ...options,
    queryKey: [
      QueryKeys.getAwareSessionsForPatientNumber,
      organizationId,
      patientNumber,
    ],
    queryFn: async () => {
      const { data } = await getAwareSessionsForPatientNumber(
        organizationId,
        patientNumber,
        aggregated
      );

      return data.data;
    },
  });
};

export default useAwareSessionsForPatientNumber;
