import { downloadReport } from 'api/reports';
import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { OrganizationQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

const OCTET_STREAM_TYPE = 'application/octet-stream';

interface UseDownloadReportsParams extends OrganizationQueryParams {
  executionId: string;
}

function useDownloadReports({
  params,
  options,
}: IQueryRequest<UseDownloadReportsParams>) {
  const { organizationId, executionId } = params;
  return useQuery<any>({
    ...options,
    queryKey: [QueryKeys.getOrganizationReport, organizationId, executionId],
    queryFn: async () => {
      const response = await downloadReport(organizationId, executionId);
      const url = window.URL.createObjectURL(
        new Blob([response.data], {
          type: OCTET_STREAM_TYPE,
        })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `report-${executionId}-${Date.now()}.xlsx`);
      document.body.appendChild(link);
      link.click();
    },
  });
}

export default useDownloadReports;
