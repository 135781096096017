import useCaseCategories from 'api/queries/cases/useCaseCategories';
import useCaseDetails from 'api/queries/cases/useCaseDetails';
import useCasePhaseHistory from 'api/queries/cases/useCasePhaseHistory';
import useConversationTemplates from 'api/queries/conversations/useConversationTemplates';
import useCaseConversationSchedules from 'api/queries/conversationSchedules/useCaseConversationSchedules';
import useOrganizationDetails from 'api/queries/organizations/useOrganizationDetails';
import { useOrganizationCasePathParams } from 'hooks/paths/useOrganizationCasePathParams';

export const useCommonData = () => {
  const { organizationId, caseId } = useOrganizationCasePathParams();

  const organizationQuery = useOrganizationDetails({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const caseDetailsQuery = useCaseDetails({
    params: { organizationId, caseId },
    options: { enabled: false, staleTime: Infinity },
  });

  const casePhaseHistoryQuery = useCasePhaseHistory({
    params: { organizationId, caseId },
    options: { enabled: false, staleTime: Infinity },
  });

  const caseCategoriesQuery = useCaseCategories({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const conversationTemplatesQuery = useConversationTemplates({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const caseConversationSchedulesQuery = useCaseConversationSchedules({
    params: { organizationId, caseId },
    options: { enabled: false, staleTime: Infinity },
  });

  return {
    organization: organizationQuery,
    caseDetails: caseDetailsQuery,
    casePhaseHistory: casePhaseHistoryQuery,
    caseCategories: caseCategoriesQuery,
    conversationTemplates: conversationTemplatesQuery,
    caseConversationSchedules: caseConversationSchedulesQuery,
  };
};
