import { useCallback, useMemo } from 'react';
import { useTypedContext } from '../../../../../../../../../../../../../hooks/useTypedContext';
import {
  DashboardTemplateDataSource,
  ITableDashboardTemplateVisualization,
} from '../../../../../../../../../../../../../interfaces/dashboards';
import { ITotalRowsPage } from '../../../../../../../../../../../../../interfaces/response';
import {
  DashboardDataReducerContext,
  DashboardQueriesContext,
} from '../../../../../../../types';
import { VisualizationDataContextType } from '../../../types';
import { TableViewHelpers } from '../helpers';
import { useColumns } from './useColumns';

export const useRows = (
  visualization: ITableDashboardTemplateVisualization,
  data: VisualizationDataContextType
): Record<any, any>[] => {
  const [dashboardData] = useTypedContext(DashboardDataReducerContext);
  const columns = useColumns(visualization);
  const queriesData = useTypedContext(DashboardQueriesContext);

  const appendData = useCallback(
    (
      rows: Record<any, any>[],
      visualizationEntries: ITotalRowsPage<any>,
      idRef?: string
    ) => {
      visualizationEntries.data.forEach((item) => {
        let dataRow: Record<any, any> = {};

        if (idRef) dataRow.id = item[idRef];

        columns.forEach((column, index) => {
          dataRow[column.field] = TableViewHelpers.GetColumnValue(
            queriesData,
            item,
            visualization.columns[index]
          );
        });

        rows.push(dataRow);
      });

      return rows;
    },
    [columns, queriesData, visualization.columns]
  );

  return useMemo(() => {
    let rows: Record<any, any>[] = [];

    if (
      dashboardData.data_source === DashboardTemplateDataSource.DashboardReports
    )
      return appendData(rows, data.reports.current, 'id');

    if (
      dashboardData.data_source ===
        DashboardTemplateDataSource.AwareSessionData ||
      dashboardData.data_source ===
        DashboardTemplateDataSource.AggregatedAwareSessionData
    )
      return appendData(rows, data.sessions.current, 'case_id');

    return appendData(rows, data.items.current, 'id');
  }, [
    appendData,
    dashboardData.data_source,
    data.items,
    data.reports,
    data.sessions,
  ]);
};
