import { cloneDeep, sum } from 'lodash';
import { useMemo } from 'react';
import { DashboardTemplateSeries } from '../../../../../../../../../../../../../interfaces/dashboards';

export const useNonPieChartAccumulation = (
  formattedData: any[],
  series: DashboardTemplateSeries[]
): any[] => {
  return useMemo(() => {
    if (series.length === 0) return formattedData;

    const shouldBeAccumulated = series[0].accumulative;

    if (!shouldBeAccumulated) return formattedData;

    let plainFormattedData = cloneDeep(formattedData);

    return formattedData.map((item, index) => {
      const itemKeys = Object.keys(item).filter((p) => p !== 'name');

      itemKeys.forEach((itemKey) => {
        const youngerElements = plainFormattedData.filter(
          (p, idx) => Object.keys(p).includes(itemKey) && idx <= index
        );
        const valueToSumUp = youngerElements.map((p) => p[itemKey] as number);
        item[itemKey] = parseFloat(sum(valueToSumUp).toFixed(2));
      });

      return item;
    });
  }, [formattedData, series]);
};
