import React, { useMemo } from 'react';
import { TrendsChartFormatter } from '../../../../types';
import { SelectItemsHookValues } from '../../hooks/useSelectItems';
import { useTranslations } from '../../../../../../hooks/useTranslations';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

type TrendsChartLegendProps = {
  formatters: TrendsChartFormatter[];
  selection: SelectItemsHookValues;
  onLegendItemRemove?: (value: string) => void;
  innerProps: any;
};

const TrendsChartLegend = ({
  selection,
  formatters,
  onLegendItemRemove,
  innerProps,
}: TrendsChartLegendProps) => {
  const { attachPrefix } = useTranslations(
    'TrendsChart.TrendsChartFigure.TrendsChartLegend'
  );

  const nameFormatter = useMemo(
    () => formatters.find((p) => p.source === 'name')?.callback,
    [formatters]
  );

  return (
    <div className="TrendsChartLegend">
      <div className="TrendsChartLegend__items">
        {(innerProps.payload as any[]).length > 0 && (
          <div
            className={classNames(
              'TrendsChartLegend__item TrendsChartLegendItem',
              {
                'TrendsChartLegendItem--selected': selection.isAllSelected,
              }
            )}
            onClick={selection.selectAll}
          >
            <span className="TrendsChartLegendItem__label">
              <FormattedMessage id={attachPrefix('allSelected')} />
            </span>
          </div>
        )}
        {(innerProps.payload as any[])
          .filter((p) => selection.initial.includes(p.value))
          .map((item, index) => {
            const handleClick = () => selection.toggleSelect(item.value);
            const handleRemove = () => onLegendItemRemove?.(item.value);

            return (
              <div
                className={classNames(
                  'TrendsChartLegend__item TrendsChartLegendItem',
                  {
                    'TrendsChartLegendItem--selected':
                      selection.isSelected(item.value) &&
                      !selection.isAllSelected,
                  }
                )}
                onClick={handleClick}
              >
                <div
                  className="TrendsChartLegendItem__color"
                  style={{
                    width: 15,
                    height: 15,
                    borderRadius: '100%',
                    background: item.color,
                  }}
                />
                <span className="TrendsChartLegendItem__label">
                  {nameFormatter
                    ? nameFormatter(item.value, index)
                    : item.value}
                  {onLegendItemRemove && (
                    <FontAwesomeIcon icon={faTimes} onClick={handleRemove} />
                  )}
                </span>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default TrendsChartLegend;
