import React from 'react';
import { useIntl } from 'react-intl';
import { TooltipProps } from 'recharts';
interface SqueezeStrengthChartTooltipProps
  extends TooltipProps<string, string> {
  translationId: string;
}

const SqueezeStrengthChartTooltip = ({
  active,
  payload,
  label,
  translationId,
}: SqueezeStrengthChartTooltipProps) => {
  const intl = useIntl();

  if (active && payload && payload.length) {
    return (
      <div className="SqueezeStrengthChartTooltip">
        <span className="SqueezeStrengthChartTooltip__date">{`Date: ${label}`}</span>
        <span className="SqueezeStrengthChartTooltip__value">{`Value: ${payload[0].value}`}</span>
        <span className="SqueezeStrengthChartTooltip__type">{`Strength type: ${intl.formatMessage(
          {
            id: translationId,
          }
        )}`}</span>
      </div>
    );
  }

  return null;
};

export default SqueezeStrengthChartTooltip;
