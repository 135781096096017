import { getUserId } from 'helpers/utils/auth';
import { UserBrowsingContext } from 'interfaces/ui';
import { useParams } from 'react-router';

type UserPathBasedBrowsingContext = {
  organizationId: string | undefined;
  caseId: string | undefined;
  userId: string | undefined;
  isOrganizationContext: boolean;
  isRegularUserContext: boolean;
  pathBasedBrowsingContext: UserBrowsingContext;
};

const useUserPathBasedBrowsingContext = (): UserPathBasedBrowsingContext => {
  const userId = getUserId();

  const { organizationId, caseId } = useParams<{
    organizationId: string;
    caseId: string;
  }>();

  const pathBasedBrowsingContext =
    organizationId && caseId
      ? UserBrowsingContext.OrganizationContext
      : UserBrowsingContext.RegularUserContext;

  return {
    caseId: caseId,
    organizationId: organizationId,
    userId: userId,
    isOrganizationContext:
      pathBasedBrowsingContext === UserBrowsingContext.OrganizationContext,
    isRegularUserContext:
      pathBasedBrowsingContext === UserBrowsingContext.RegularUserContext,
    pathBasedBrowsingContext: pathBasedBrowsingContext,
  };
};

export default useUserPathBasedBrowsingContext;
