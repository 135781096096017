import { addDays } from 'date-fns';
import { strToDateOnly } from 'helpers/dates';
import React, { useCallback } from 'react';
import { useCommonData } from '../../../hooks/useCommonData';

export const useCaseConversationsScheduleDateNavigation = (
  selectedDate: string,
  setSelectedDate: React.Dispatch<React.SetStateAction<string>>
) => {
  const { casePhaseHistory } = useCommonData();

  const handleNext = useCallback(() => {
    const nowDate = new Date(selectedDate);
    const nowDatePlusOne = addDays(nowDate, 1);
    const nowDatePlusOneIsoString = nowDatePlusOne.toISOString();

    setSelectedDate(nowDatePlusOneIsoString);
  }, [selectedDate, setSelectedDate]);

  const handlePrev = useCallback(() => {
    const startDate = casePhaseHistory.data?.at(0)?.date_from;

    if (!startDate) return;

    const dayMinusOne = addDays(new Date(selectedDate), -1).toISOString();

    if (strToDateOnly(dayMinusOne) < strToDateOnly(startDate)) return;

    setSelectedDate(dayMinusOne);
  }, [casePhaseHistory.data, selectedDate, setSelectedDate]);

  return {
    handleNext: handleNext,
    handlePrev: handlePrev,
  };
};
