import { useModifyAccessProfile } from '../../../../../../../../../../../../../api/mutations/accessProfiles/useModifyAccessProfile';
import useOrganizationUserAccessProfiles from '../../../../../../../../../../../../../api/queries/accessProfiles/useOrganizationUserAccessProfiles';
import { useOrganizationPathParams } from '../../../../../../../../../../../../../hooks/paths/useOrganizationPathParams';

export const useHandleAccept = (profileId: string) => {
  const { organizationId } = useOrganizationPathParams();

  const accessProfiles = useOrganizationUserAccessProfiles({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const { mutateAsync: patchAsync } = useModifyAccessProfile(
    { organizationId },
    { successFb: accessProfiles.refetch }
  );

  return async (value: string) => {
    await patchAsync({
      accessProfileId: profileId,
      patch: [
        {
          op: 'replace',
          path: '/name',
          value: value,
        },
      ],
    });
  };
};
