import { AVAILABLE_CULTURES } from '../../components/CultureSelector/CultureSelector';
import { ICultureValueObject } from '../../interfaces/cultures';
import { AvailableCulture } from '../../hooks/cultures/useCultures';

export const translateHtmlStr = (
  html: string | null | undefined,
  selectedCulture: AVAILABLE_CULTURES,
  asHtml: boolean = true
): string => {
  const domParser = new DOMParser();

  if (html === null || html === undefined) {
    return '';
  }

  const dom = domParser.parseFromString(html, 'text/html');

  const defaultElement = dom.querySelector('body > * > *');
  const translatedElement = dom.querySelector(
    `[data-culture="${selectedCulture}"]`
  );

  if (translatedElement !== null) {
    return asHtml
      ? translatedElement.innerHTML
      : translatedElement.innerHTML.replaceAll(/<\/?[^>]+(>|$)/gi, '');
  }

  if (defaultElement !== null) {
    return asHtml
      ? defaultElement.innerHTML
      : defaultElement.innerHTML.replaceAll(/<\/?[^>]+(>|$)/gi, '');
  }

  const mainElement = dom.querySelector('*')?.innerHTML;

  return asHtml
    ? mainElement ?? ''
    : mainElement?.replaceAll(/<\/?[^>]+(>|$)/gi, '') ?? '';
};

export const getCultureValueBasedOnHtml = (
  html: string | null,
  currentCulture: AvailableCulture
) => {
  let cultureValueObject: ICultureValueObject<string> = {};

  if (html === null) return cultureValueObject;

  const domParser = new DOMParser();
  const dom = domParser.parseFromString(html, 'text/html');
  const cultureElements = dom.querySelectorAll('body > * > *');

  if (cultureElements.length === 0) {
    cultureValueObject[currentCulture.cultureCode] = html;
    return cultureValueObject;
  }

  cultureElements.forEach((element) => {
    if (!element.hasAttributes()) {
      cultureValueObject[currentCulture.cultureCode] = element.innerHTML;
      return;
    }

    const cultureAttr = element.attributes.getNamedItem('data-culture');

    if (cultureAttr === null) return;

    cultureValueObject[cultureAttr.value] = element.innerHTML;
  });

  return cultureValueObject;
};

export function getHtmlBasedOnCultureValue<T>(
  valueObject: ICultureValueObject<T>
) {
  let resultHtml = '<div>';

  Object.entries(valueObject).forEach(([key, val]) => {
    resultHtml += `<div data-culture="${key}">${val}</div>`;
  });

  resultHtml += '</div>';
  return resultHtml;
}
