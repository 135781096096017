import React, { memo } from 'react';
import { useTranslations } from '../../../../../../../../../../../../hooks/useTranslations';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';

const MaterialsListItem = () => {
  const { attachPrefix } = useTranslations(
    'ConversationsAndFeedbacks.ConversationTemplates.ConversationTemplatePhone.Conversation.ConversationItem.MaterialsListItem'
  );

  const currentHour = format(new Date(), 'HH:HH a');

  return (
    <div className="ConversationTemplateRangeQuestionItem">
      <div className="ConversationTemplateRangeQuestionItem__question d-flex flex-column">
        <span className="ConversationTemplatePlainTextItem__content__text">
          <FormattedMessage id={attachPrefix('materialsList')} />
        </span>
        <span className="ConversationTemplatePlainTextItem__content__hour">
          {currentHour}
        </span>
      </div>
    </div>
  );
};

export default memo(MaterialsListItem);
