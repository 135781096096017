import React from 'react';
import CodeMirror, { Extension, ViewUpdate } from '@uiw/react-codemirror';
import { jsonParseLinter } from '@codemirror/lang-json';
import { useExtensions } from './hooks/useExtensions';

type JsonEditorProps = {
  value: string;
  editable?: boolean;
  height?: string;
  extensions?: Extension[];
  onChange?: (value: string) => void;
  onError?: (message: string | null) => void;
};

const JsonEditor = ({
  value,
  editable = true,
  height = '200px',
  extensions = [],
  onChange,
  onError,
}: JsonEditorProps) => {
  const _extensions = useExtensions(extensions);

  const onModify = (value: string, viewUpdate: ViewUpdate) => {
    const verifyJson = jsonParseLinter();
    const verification = verifyJson(viewUpdate.view);

    if (verification.length > 0) {
      onError?.(verification[0].message);
      onChange?.(value);
      return;
    }

    onError?.(null);
    onChange?.(value);
  };

  return (
    <CodeMirror
      value={value ?? ''}
      height={height}
      editable={editable}
      onChange={onModify}
      extensions={_extensions}
    />
  );
};

export default JsonEditor;
