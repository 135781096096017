import { Field } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { Col, Row } from 'reactstrap';
import FieldInput from '../../../../../../components/FieldInput';
import FieldCultureSelect from '../../../../../../components/Fields/Formik/FieldCultureSelect';
import FieldSelect from '../../../../../../components/FieldSelect';
import InputFieldWithoutImage from '../../../../../../components/InputFieldWithoutImage';
import { useProjectModalDetailsForm } from './hooks/useProjectModalDetailsForm';

const ProjectModalDetailsForm = () => {
  const intl = useIntl();
  const { options } = useProjectModalDetailsForm();

  return (
    <div className="d-flex flex-column gap-4">
      <Row>
        <Col xs={6}>
          <InputFieldWithoutImage
            gray={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.place"
            inputId="place"
          >
            <Field
              id="place"
              name="place"
              type="text"
              placeholder={intl.formatMessage({
                id: 'Projects.ProjectModal.ProjectModalOverviewForm.placePlaceholder',
              })}
              component={FieldInput}
            />
          </InputFieldWithoutImage>
        </Col>
        <Col xs={6}>
          <InputFieldWithoutImage
            gray={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.forWho"
            inputId="for_who"
          >
            <Field
              id="for_who"
              name="for_who"
              type="text"
              placeholder={intl.formatMessage({
                id: 'Projects.ProjectModal.ProjectModalOverviewForm.forWhoPlaceholder',
              })}
              component={FieldInput}
            />
          </InputFieldWithoutImage>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <InputFieldWithoutImage
            gray={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.contactEmail"
            inputId="contact_email"
          >
            <Field
              id="contact_email"
              name="contact_email"
              type="email"
              placeholder={intl.formatMessage({
                id: 'Projects.ProjectModal.ProjectModalOverviewForm.contactEmailPlaceholder',
              })}
              component={FieldInput}
            />
          </InputFieldWithoutImage>
        </Col>
        <Col xs={6}>
          <InputFieldWithoutImage
            gray={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.projectOwner"
            inputId="owner_personality_id"
          >
            <Field
              id="owner_personality_id"
              name="owner_personality_id"
              placeholder={intl.formatMessage({
                id: 'Projects.ProjectModal.ProjectModalOverviewForm.projectOwnerPlaceholder',
              })}
              required={true}
              displayEmpty={false}
              options={options.organizationMembers}
              component={FieldSelect}
            />
          </InputFieldWithoutImage>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <InputFieldWithoutImage
            gray={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.startDate"
            inputId="date_from"
          >
            <Field
              id="date_from"
              name="date_from"
              placeholder={intl.formatMessage({
                id: 'Projects.ProjectModal.ProjectModalOverviewForm.startDatePlaceholder',
              })}
              type="date"
              component={FieldInput}
            />
          </InputFieldWithoutImage>
        </Col>
        <Col xs={6}>
          <InputFieldWithoutImage
            gray={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.endDate"
            inputId="date_to"
          >
            <Field
              id="date_to"
              name="date_to"
              placeholder={intl.formatMessage({
                id: 'Projects.ProjectModal.ProjectModalOverviewForm.endDatePlaceholder',
              })}
              type="date"
              component={FieldInput}
            />
          </InputFieldWithoutImage>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <InputFieldWithoutImage
            unstyled={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.defaultCultureCode"
            inputId="default_culture"
          >
            <Field
              id="default_culture"
              name="default_culture"
              placeholder={intl.formatMessage({
                id: 'Projects.ProjectModal.ProjectModalOverviewForm.defaultCultureCodePlaceholder',
              })}
              displayEmpty={true}
              component={FieldCultureSelect}
            />
          </InputFieldWithoutImage>
        </Col>
        <Col xs={6}>
          <InputFieldWithoutImage
            gray={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.allowToChooseGroup"
            inputId="allow_to_choose_group"
          >
            <Field
              id="allow_to_choose_group"
              name="allow_to_choose_group"
              required={true}
              displayEmpty={false}
              options={options.allowToChooseGroup}
              component={FieldSelect}
            />
          </InputFieldWithoutImage>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <InputFieldWithoutImage
            gray={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.treatmentPlan"
            inputId="case_category_id"
          >
            <Field
              id="case_category_id"
              name="case_category_id"
              required={true}
              placeholder={intl.formatMessage({
                id: 'Projects.ProjectModal.ProjectModalOverviewForm.treatmentPlanPlaceholder',
              })}
              options={options.caseCategories}
              component={FieldSelect}
            />
          </InputFieldWithoutImage>
        </Col>
      </Row>
    </div>
  );
};

export default ProjectModalDetailsForm;
