import { FieldProps } from 'formik';
import { ChangeEvent } from 'react';
import { Input } from 'reactstrap';
import classNames from 'classnames';

function toLocalISOString(d: Date) {
  d = d || new Date();

  function pad(n) {
    return (n < 10 ? '0' : '') + n;
  }

  return (
    d.getFullYear() +
    '-' +
    pad(d.getMonth() + 1) +
    '-' +
    pad(d.getDate()) +
    'T' +
    pad(d.getHours()) +
    ':' +
    pad(d.getMinutes())
  );
}

type DateTimeInputProps = Partial<FieldProps> & {
  margin?: boolean;
};

const DateTimeInput = ({ form, field, margin = true }: DateTimeInputProps) => {
  const dateObject = new Date(field?.value);

  const localDateTime = toLocalISOString(dateObject);

  const onTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const time = event.target.value;
    const newDate = new Date(time);

    form?.setValues({
      ...form.values,
      [field?.name as string]: newDate.toISOString(),
    });
  };

  return (
    <div
      className={classNames({
        DateTimeInput: true,
        'mt-0': !margin,
      })}
    >
      <Input
        type="datetime-local"
        onChange={onTimeChange}
        value={localDateTime}
        id={field?.name}
      />
    </div>
  );
};

export default DateTimeInput;
