import useCreateSimpleCaseObservation from '../../../../../api/mutations/observations/useCreateSimpleCaseObservation';
import { useCommonParams } from '../../../hooks/useCommonParams';
import { useCallback } from 'react';

export const useTriggerManualObservation = () => {
  const { userId, caseId } = useCommonParams();

  const handleSuccess = () => {
    window.dispatchEvent(new CustomEvent('day-details-refetch-requested'));
  };

  const { mutateAsync: createAsync, isLoading } =
    useCreateSimpleCaseObservation({
      params: { caseId, userId },
      options: { successFb: handleSuccess },
    });

  const handleCreate = useCallback(async () => {
    await createAsync({});
  }, [createAsync]);

  return { handleCreate, isCreating: isLoading };
};
