import { addMinutes } from 'date-fns';
import { cloneDeep } from 'lodash';
import {
  ScheduleItemDefinition,
  ScheduleItemTemplatePartsOfADayDefinition,
} from '../../../../../../../../../../../../../../../components/Schedule';
import {
  strToDateOnly,
  toDateOnly,
} from '../../../../../../../../../../../../../../../helpers/dates';
import { getCurrentCasePhaseHistoryItems } from '../../../../../../../../../../../../../../../helpers/utils/casePhaseHistory';
import { getDayOffset } from '../../../../../../../../../../../../../../../helpers/utils/dates';
import { ICasePhaseHistory } from '../../../../../../../../../../../../../../../interfaces/cases';
import { ConversationScheduleType } from '../../../../../../../../../../../../../../../interfaces/conversationSchedules';
import {
  CaseNotificationSchedule,
  IRecurringNotificationSchedule,
  ISingleNotificationSchedule,
} from '../../../../../../../../../../../../../../../interfaces/notificationSchedules';
import { DaySchedulesFormatHelpers } from './format';

export namespace DayScheduleSchedulesHelpers {
  export const GetRecurringParent = (
    schedules: CaseNotificationSchedule[],
    originId: string
  ) => {
    return schedules.find((p) => p.id === originId);
  };

  export const GetSingleInstancePartOfADay = (
    current: CaseNotificationSchedule,
    handleEdit: (schedule: CaseNotificationSchedule) => void
  ): ScheduleItemTemplatePartsOfADayDefinition => {
    const singleInstance = current as ISingleNotificationSchedule;

    return {
      notification_hour: DaySchedulesFormatHelpers.FormatHourString(
        singleInstance.user_timezone_time
      ),
      available_from_hour: DaySchedulesFormatHelpers.FormatHourString(
        singleInstance.user_timezone_time
      ),
      available_to_hour: DaySchedulesFormatHelpers.FormatHourString(
        singleInstance.user_timezone_time
      ),
      is_completed: current.is_sent,
      is_deleted: false,
      is_dirty: false,
      onEdit: () => {
        handleEdit(singleInstance);
      },
    };
  };

  export const GetSimpleSingleInstance = (
    current: CaseNotificationSchedule,
    casePhaseHistory: ICasePhaseHistory[],
    selectedDate: string,
    disabled: boolean,
    index: number,
    handleEdit: (schedule: CaseNotificationSchedule) => void
  ): ScheduleItemDefinition => {
    const singleInstance = current as ISingleNotificationSchedule;

    const currentCasePhaseHistoryItems = getCurrentCasePhaseHistoryItems(
      casePhaseHistory,
      selectedDate
    );

    let dayOffset: number = 1;

    if (currentCasePhaseHistoryItems.length === 0)
      return _getSimpleSingleInstance(
        singleInstance,
        dayOffset,
        index,
        disabled,
        handleEdit
      );

    if (currentCasePhaseHistoryItems.length === 1) {
      const normalizedUtc =
        DaySchedulesFormatHelpers.NormalizeUtc(singleInstance);

      dayOffset = getDayOffset(
        strToDateOnly(currentCasePhaseHistoryItems[0].date_from),
        strToDateOnly(normalizedUtc)
      );

      return _getSimpleSingleInstance(
        singleInstance,
        dayOffset,
        index,
        disabled,
        handleEdit
      );
    }

    for (const item of currentCasePhaseHistoryItems) {
      const normalizedUtc =
        DaySchedulesFormatHelpers.NormalizeUtc(singleInstance);

      if (
        (!item.date_to && normalizedUtc >= item.date_from) ||
        (item.date_to &&
          normalizedUtc >= item.date_from &&
          normalizedUtc <= item.date_from)
      ) {
        dayOffset = getDayOffset(
          strToDateOnly(item.date_from),
          strToDateOnly(normalizedUtc)
        );
        break;
      }
    }

    return _getSimpleSingleInstance(
      singleInstance,
      dayOffset,
      index,
      disabled,
      handleEdit
    );
  };

  export const GetRecurredSingleInstance = (
    current: CaseNotificationSchedule,
    recurring: CaseNotificationSchedule,
    casePhaseHistory: ICasePhaseHistory[],
    selectedDate: string,
    index: number,
    handleEdit: (schedule: CaseNotificationSchedule) => void
  ): ScheduleItemDefinition => {
    const singleInstance = current as ISingleNotificationSchedule;

    const singleInstancePartOfADay = GetSingleInstancePartOfADay(
      current,
      handleEdit
    );

    const offsets = DaySchedulesFormatHelpers.FormatRecurredDayOffsets(
      casePhaseHistory,
      selectedDate,
      recurring,
      singleInstance.utc_timezone_time
    );

    let startDayOffset = offsets.startOffset;
    let endDayOffset = offsets.endOffset;

    return _getRecurredSingleInstance(
      singleInstance,
      recurring as IRecurringNotificationSchedule,
      singleInstancePartOfADay,
      startDayOffset,
      endDayOffset,
      index,
      selectedDate < toDateOnly(new Date()),
      handleEdit
    );
  };

  const _getSimpleSingleInstance = (
    singleInstance: ISingleNotificationSchedule,
    dayOffset: number,
    index: number,
    disabled: boolean,
    handleEdit: (schedule: CaseNotificationSchedule) => void
  ) => {
    const timezoneTime = new Date(singleInstance.user_timezone_time);

    let dateFrom = cloneDeep(timezoneTime);
    dateFrom = addMinutes(dateFrom, -15);
    const dateFromString = dateFrom.toISOString();

    let dateTo = cloneDeep(timezoneTime);
    dateTo = addMinutes(dateTo, 15);
    const dateToString = dateTo.toISOString();

    return {
      rowId: singleInstance.id,
      sequence: index,
      templateId: singleInstance.notification_type,
      scheduleTemplate: {
        schedule_type: ConversationScheduleType.SingleInstance,
        recurring_interval: null,
        start_day_offset: null,
        end_day_offset: null,
        specific_day_of_phase: dayOffset,
        parts_of_a_day: [
          {
            available_to_hour:
              DaySchedulesFormatHelpers.FormatHourString(dateToString),
            available_from_hour:
              DaySchedulesFormatHelpers.FormatHourString(dateFromString),
            notification_hour: DaySchedulesFormatHelpers.FormatHourString(
              singleInstance.user_timezone_time
            ),
            is_completed: singleInstance.is_sent,
            is_dirty: false,
            is_deleted: false,
            disabled: disabled,
            onEdit: () => {
              handleEdit(singleInstance);
            },
          },
        ],
      },
      disabled: disabled,
    };
  };

  const _getRecurredSingleInstance = (
    singleInstance: ISingleNotificationSchedule,
    recurring: IRecurringNotificationSchedule,
    partOfADay: ScheduleItemTemplatePartsOfADayDefinition,
    startDayOffset: number,
    endDayOffset: number,
    index: number,
    disabled: boolean,
    handleEdit: (schedule: CaseNotificationSchedule) => void
  ): ScheduleItemDefinition => {
    return {
      rowId: singleInstance.origin_id ?? '',
      sequence: index,
      priority: undefined,
      templateId: singleInstance.notification_type,
      scheduleTemplate: {
        schedule_type: ConversationScheduleType.Recurring,
        recurring_interval: recurring.recurring_interval,
        specific_day_of_phase: null,
        parts_of_a_day: [partOfADay],
        start_day_offset: startDayOffset,
        end_day_offset: endDayOffset,
      },
      disabled: disabled,
      onEdit: () => {
        handleEdit(recurring);
      },
    };
  };
}
