import { IQueryRequest } from '../../../interfaces/request';
import { UserCaseQueryParams } from '../../../interfaces/queryParams';
import { useQuery } from 'react-query';
import { getUserCaseCurrentConversation } from '../../conversations';
import QueryKeys from '../queryKeys';

const useUserCaseCurrentConversation = ({
  params,
  options,
}: IQueryRequest<UserCaseQueryParams>) => {
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getUserCaseCurrentConversation(
        params.userId,
        params.caseId
      );
      return data.data;
    },
    queryKey: [
      QueryKeys.getUserCaseCurrentConversation,
      params.userId,
      params.caseId,
    ],
  });
};

export default useUserCaseCurrentConversation;
