import { participantFeedbackStatuses } from 'helpers/utils/translationObject';
import React from 'react';
import { useIntl } from 'react-intl';
import { Input, InputProps } from 'reactstrap';

const CaseFeedbackStatusesDropdown = (props: InputProps) => {
  const intl = useIntl();

  const options = participantFeedbackStatuses.map(
    ({ translationId, value }) => (
      <option key={translationId} value={value}>
        {intl.formatMessage({ id: translationId })}
      </option>
    )
  );

  return (
    <Input type="select" {...props}>
      <option value=""></option>
      {options}
    </Input>
  );
};

export default CaseFeedbackStatusesDropdown;
