import useAddCaseHistory from 'api/mutations/cases/useCaseHistoryAdd';
import LoadingButton from 'components/LoadingButton';
import { Formik } from 'formik';
import CaseHistoryForm from 'modules/Cases/modals/CaseHistoryModal/components/CaseHistoryForm';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';

const initialValues = {
  text: '',
  time_start: new Date().toISOString(),
  praise_level: null,
  share_with_patient: false,
};

type AdditionalInformationProps = {
  caseId: string;
  organizationId: string;
  previousClick: () => void;
  backToRoute: () => void;
};

const AdditionalInformation = ({
  caseId,
  organizationId,
  previousClick,
  backToRoute,
}: AdditionalInformationProps) => {
  const { mutate: addHistoryEntryMutation, isLoading } = useAddCaseHistory(
    {
      organizationId: organizationId,
      caseId: caseId,
    },
    {
      successFb: backToRoute,
    }
  );

  const onSubmit = (data: typeof initialValues) => {
    addHistoryEntryMutation({
      new_category_id: null,
      new_status: null,
      new_sub_category_id: null,
      text: data.text,
      time_start: new Date(data.time_start).toISOString(),
      praise_level: data.praise_level,
      share_with_patient: data.share_with_patient,
      message_owner: null,
      sender_ids: [],
    });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={onSubmit}>
      {({ submitForm, isValid, values, setValues }) => (
        <div className="StatusCheck__additional_info">
          <CaseHistoryForm
            values={values}
            hidePraise={false}
            hideShare={false}
            hidePredefinedMessages={true}
            hideOwnerSelect={true}
            setValues={setValues}
            organizationId={organizationId}
            caseId={caseId}
          />
          <div className="StatusCheck__button-container">
            <Button onClick={previousClick} color="primary" outline>
              <FormattedMessage id="General.previous" />
            </Button>
            <LoadingButton
              isLoading={isLoading}
              disabled={!isValid}
              onClick={submitForm}
            >
              <FormattedMessage id="CaseCard.History.addEntry" />
            </LoadingButton>
          </div>
        </div>
      )}
    </Formik>
  );
};

export default AdditionalInformation;
