import OrganizationRegisterLayout from 'components/layouts/OrganizationRegisterLayout';
import { useSteps } from 'hooks/useSteps';
import React from 'react';
import OrganizationRegisterStep from './components/OrganizationRegisterSteps';
import MainOrganizationFilter from './filters/MainOrganizationFilter';
import OrganizationTypeProvider from './providers/OrganizationTypeProvider';
import {
  OrganizationRegisterViewSteps,
  OrganizationRegisterViewStepsContext,
  OrganizationTypeContext,
} from './types';

const OrganizationRegister = () => {
  const steps = useSteps(OrganizationRegisterViewSteps);

  return (
    <MainOrganizationFilter>
      <OrganizationTypeProvider>
        {(type) => (
          <OrganizationRegisterLayout>
            <OrganizationRegisterViewStepsContext.Provider value={steps}>
              <OrganizationTypeContext.Provider value={type}>
                <OrganizationRegisterStep />
              </OrganizationTypeContext.Provider>
            </OrganizationRegisterViewStepsContext.Provider>
          </OrganizationRegisterLayout>
        )}
      </OrganizationTypeProvider>
    </MainOrganizationFilter>
  );
};

export default OrganizationRegister;
