import { useCallback, useState } from 'react';
import { ConversationTemplate } from '../../../../../../../interfaces/conversations';
import useOpen from '../../../../../../../hooks/useOpen';
import { useCommonData } from '../../../../../../Organizations/views/OrganizationDetailsView/hooks/useCommonData';
import { useInitQuery } from '../../../../../../../hooks/queries/useInitQuery';

export const useSelectedTemplate = () => {
  const { isOpen, toggle } = useOpen();

  const { conversationTemplates, groupedConversationQuestions } =
    useCommonData();
  useInitQuery(conversationTemplates);

  const [selectedTemplate, setSelectedTemplate] =
    useState<ConversationTemplate | null>(null);

  const editTemplate = useCallback(
    (template: ConversationTemplate) => {
      setSelectedTemplate(template);
      toggle();
    },
    [toggle]
  );

  const onTemplateEdited = useCallback(async () => {
    setSelectedTemplate(null);
    toggle();
    await conversationTemplates.refetch();
    await groupedConversationQuestions.refetch();
  }, [conversationTemplates, groupedConversationQuestions, toggle]);

  return {
    selectedTemplate,
    isOpen,
    toggle,
    editTemplate,
    onTemplateEdited,
  };
};
