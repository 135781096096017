import { ICaseFilters } from 'interfaces/cases';
import { CaseActionName } from './reducer';

export const setCasesListFilters = (filters: ICaseFilters | null) => {
  return {
    type: CaseActionName.CASE_SET_LIST_FILTERS,
    filters: filters,
  };
};

export const setFormsDayDetailsViewRefetchRequest = (request: boolean) => {
  return {
    type: CaseActionName.CASE_SET_FORMS_DAY_DETAILS_REFETCH_REQUEST,
    request: request,
  };
};

export const setFormsDayByDayViewRefetchRequest = (request: boolean) => {
  return {
    type: CaseActionName.CASE_SET_FORMS_DAY_BY_DAY_REFETCH_REQUEST,
    request: request,
  };
};
