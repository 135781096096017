import { IObservation, ObservationSymptom } from 'interfaces/timeline';
import { formatDate } from 'helpers/dates';
import { AssessmentCompletionStatus } from 'interfaces/assessmentForms';

export function returnObservationsGroupedByMeaning(
  observations: IObservation[] | undefined,
  meaningNames: ObservationSymptom[]
) {
  return meaningNames.map((name) => {
    return {
      name,
      data: observations?.filter((data) => data.symptom === name),
    };
  });
}

export function returnUniqueMeanings(observations: IObservation[] | undefined) {
  const allMeanings =
    observations?.map((observation) => observation.symptom) ?? [];
  return [...new Set(allMeanings)];
}

export function returnSelectedDateString(selectedDate: Date | null) {
  return selectedDate
    ? formatDate(selectedDate?.toISOString(), {
        ignoreTime: true,
      })
    : '';
}

export function returnFormBannerTranslationId(
  status: AssessmentCompletionStatus,
  canEdit: boolean
) {
  return `TimelineDetails.dayDetailsTabs.assessmentFormsCompletionInfo.${status.toLowerCase()}.${
    canEdit ? 'header' : 'organizationMemberHeader'
  }`;
}

export function returnFormBannerSubHeaderTranslationId(
  status: AssessmentCompletionStatus,
  canEdit: boolean
) {
  return `TimelineDetails.dayDetailsTabs.assessmentFormsCompletionInfo.${status.toLowerCase()}.${
    canEdit ? 'subheader' : 'organizationMemberSubheader'
  }`;
}
