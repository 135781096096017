import React from 'react';
import TabItem from '../TabItem';
import Skeleton from 'react-loading-skeleton';

type ItemPlaceholderProps = {
  height?: number;
  title?: boolean;
};

const ItemPlaceholder = ({
  title = true,
  height = 200,
}: ItemPlaceholderProps) => {
  return (
    <TabItem title={title ? <Skeleton width={150} /> : ''}>
      <Skeleton width="100%" height={height} />
    </TabItem>
  );
};

export default ItemPlaceholder;
