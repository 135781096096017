import classNames from 'classnames';
import LoadingButton from 'components/LoadingButton';
import QuestionForm from 'components/QuestionForm';
import { Formik } from 'formik';
import {
  createFormObjectToSent,
  removeSkippedItems,
  returnInitialValuesFromFormConversation,
  verifyQuestionsVisibility,
} from 'helpers/utils/assessmentForms';
import useRequiredParams from 'hooks/useRequiredParams';
import useStepInForm from 'hooks/useStepInForm';
import { AssessmentFormValues } from 'interfaces/ui';
import { FormattedMessage } from 'react-intl';
import { Button, Spinner } from 'reactstrap';
import useFormConversation from '../../../../../api/queries/conversations/useFormConversation';
import useSendProjectFormConversation from '../../../../../api/mutations/submissions/useSendProjectFormConversation';
import {
  FormConversationAnswer,
  IFormConversationTemplate,
} from '../../../../../interfaces/conversations';
import { useCallback, useMemo, useState } from 'react';
import { AVAILABLE_CULTURES } from '../../../../../components/CultureSelector/CultureSelector';
import { AssessmentQuestion } from '../../../../../interfaces/assessmentForms';

type QualificationQuestionsProps = {
  code: string;
  organizationCode: string;
  onSuccess: (submissionId: string) => void;
  onReject: () => void;
  selectedCulture: AVAILABLE_CULTURES;
};

const QualificationQuestions = ({
  code,
  organizationCode,
  onSuccess,
  onReject,
  selectedCulture,
}: QualificationQuestionsProps) => {
  const { projectCode } = useRequiredParams<{
    projectCode: string;
  }>(['projectCode']);

  const {
    data: conversationData,
    isFetched,
    isLoading,
  } = useFormConversation({
    params: {
      organizationId: organizationCode,
      templateId: code,
      selectedCulture,
    },
  });

  const [nextAsFinish, setNextAsFinish] = useState<boolean>(false);

  const initialConversationData =
    returnInitialValuesFromFormConversation(conversationData);

  const { mutateAsync, isLoading: mutationExecuting } =
    useSendProjectFormConversation(
      organizationCode,
      projectCode,
      code,
      onSuccess,
      onReject
    );

  const questionsLength = conversationData?.items?.length;

  const formData: IFormConversationTemplate | undefined = useMemo(() => {
    if (!conversationData) return undefined;

    return {
      code: '',
      title: '',
      description: '',
      showProgressIndicator: false,
      showTitle: true,
      showOnDayByDay: false,
      showOnDayPlan: false,
      renderAsStepInForm: true,
      questions: [...conversationData?.items.map((p) => p.value)],
    };
  }, [conversationData]);

  const { step, setStep, increaseSteps, decreaseStep, lastSetStepAction } =
    useStepInForm(questionsLength);

  const handleNextStep = async (values: AssessmentFormValues) => {
    if (!conversationData) return;

    let nextPotentialStep = step + 1;
    let shouldFinish: boolean = false;

    while (nextPotentialStep < conversationData.items.length) {
      const nextFormConversationItem =
        conversationData?.items[nextPotentialStep];

      if (!nextFormConversationItem) {
        shouldFinish = true;
        break;
      }

      const condition = nextFormConversationItem.condition;

      if (condition === null || condition === '' || condition === undefined)
        break;

      const completedQuestions = createFormObjectToSent(
        values,
        conversationData.items.map((p) => p.value),
        formData ?? null
      );

      const nextItemValidated: boolean = verifyQuestionsVisibility(
        nextFormConversationItem,
        completedQuestions
      );

      if (nextItemValidated) break;

      nextPotentialStep++;
    }

    if (nextPotentialStep >= conversationData.items.length || shouldFinish) {
      setNextAsFinish(true);
      await onSubmit(values);
      return;
    }

    const stepsDiff = nextPotentialStep - step;
    increaseSteps(stepsDiff);
  };

  const renderSteps = (values: AssessmentFormValues) => {
    const ids = formData?.questions.map((question) => question.guid);

    return questionsLength
      ? [...new Array(questionsLength)].map((_, index) => {
          let isActive = false;

          if (ids && ids[index]) {
            const id = ids[index];

            isActive = values[id] !== '' && index <= step;
          }

          return (
            <div
              className={classNames('newMembersSection__form-indicator__step', {
                'newMembersSection__form-indicator__step--active': isActive,
              })}
              style={{
                width: `${100 / questionsLength}%`,
              }}
            />
          );
        })
      : [];
  };

  const onSubmit = async (values: typeof initialConversationData) => {
    if (!conversationData) return;

    const completedQuestions = createFormObjectToSent(
      values,
      conversationData.items.map((p) => p.value),
      formData ?? null
    );

    let answer: FormConversationAnswer = {
      currentPhaseCode: conversationData.currentPhaseCode,
      currentConversationCode: conversationData.currentConversationCode,
      currentConversationHour: conversationData.currentConversationHour,
      conversationEnded: true,
      items: conversationData.items.map((item, index) => {
        item.value = completedQuestions[index] ?? item.value;
        return item;
      }),
    };

    answer = removeSkippedItems(answer, completedQuestions);
    await mutateAsync(answer);
  };

  const verifyNextDisabled = useCallback(
    (values: typeof initialConversationData, step: number) => {
      const currentConversationItemId = Object.keys(values).at(step);

      if (currentConversationItemId === 'NotSpecified') return false;

      if (
        currentConversationItemId &&
        values[currentConversationItemId] !== '' &&
        values[currentConversationItemId] !== null &&
        values[currentConversationItemId] !== undefined
      )
        return false;

      if (currentConversationItemId === undefined) return false;

      const currentConversationItem = formData?.questions?.find(
        (p) => p.guid === currentConversationItemId
      ) as AssessmentQuestion | undefined;

      if (currentConversationItem === undefined) return false;

      return currentConversationItem.required ?? false;
    },
    [formData?.questions]
  );

  if (isLoading)
    return (
      <div className="w-100 text-center">
        <Spinner />
      </div>
    );

  if (!isFetched) return null;

  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialConversationData}
      onSubmit={onSubmit}
    >
      {({ values, setValues, handleSubmit }) => {
        const submitQualificationQuestions = () => {
          handleSubmit();
        };

        const onNextStep = async () => {
          await handleNextStep(values);
        };

        return (
          <>
            {formData?.showProgressIndicator && (
              <div className="newMembersSection__form-indicator">
                {renderSteps(values)}
              </div>
            )}
            <QuestionForm
              canEdit={true}
              isStep={true}
              values={values}
              setValues={setValues}
              form={formData}
              step={step}
              setStep={setStep}
              bigQuestion={true}
              canDisplayQuestionIndicator={formData?.showProgressIndicator}
              lastSetStepAction={lastSetStepAction}
            />
            <div className="newMembersSection__submit-buttons">
              <Button
                outline
                color="secondary"
                onClick={decreaseStep}
                disabled={step === 0}
              >
                <FormattedMessage id="CaseCard.AssessmentForms.prevQuestion" />
              </Button>
              {questionsLength && step === questionsLength - 1 ? (
                <LoadingButton
                  color="primary"
                  type="submit"
                  onClick={submitQualificationQuestions}
                  isLoading={mutationExecuting}
                >
                  <FormattedMessage id="ProjectSubmissions.Qualification.finish" />
                </LoadingButton>
              ) : (
                <Button
                  color="primary"
                  disabled={verifyNextDisabled(values, step)}
                  onClick={onNextStep}
                >
                  <FormattedMessage
                    id={
                      nextAsFinish
                        ? 'ProjectSubmissions.Qualification.finish'
                        : 'CaseCard.AssessmentForms.nextQuestion'
                    }
                  />
                </Button>
              )}
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default QualificationQuestions;
