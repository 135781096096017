import { useInitQuery } from 'hooks/queries/useInitQuery';
import { useEffect, useState } from 'react';
import { useCommonData } from '../../../hooks/useCommonData';

export const useCaseConversationsScheduleDate = () => {
  const [internalDate, setInternalDate] = useState<string>(
    new Date().toISOString()
  );

  const { caseDetails, casePhaseHistory, caseCategories } = useCommonData();

  useInitQuery(caseCategories);

  useEffect(() => {
    const nowStr = new Date().toISOString();

    if (!caseDetails.data || !casePhaseHistory.data) {
      setInternalDate(nowStr);
      return;
    }

    if (casePhaseHistory.data.length === 0) {
      setInternalDate(nowStr);
      return;
    }

    const lastPhaseHistory =
      casePhaseHistory.data[casePhaseHistory.data.length - 1];

    if (caseDetails.data.status === 'Closed') {
      setInternalDate(
        lastPhaseHistory.date_to
          ? lastPhaseHistory.date_to
          : lastPhaseHistory.date_from
      );

      return;
    }

    setInternalDate(nowStr);
  }, [caseDetails.data, casePhaseHistory.data]);

  return {
    selectedDate: internalDate,
    selectDate: setInternalDate,
  };
};
