import { useTheme } from '@mui/material';
import { useCallback } from 'react';
import { isIsoDate } from '../../../../../../../../../../../../../../../helpers/dates';
import { DashboardTemplateChartType } from '../../../../../../../../../../../../../../../interfaces/dashboards';
import { ChartViewHelpers } from '../../../helpers';
import { useDateRange } from '../../../hooks/useDateRange';
import { HorizontalChartTick, VerticalChartTick } from '../../ChartTicks';
import { TickFormatter } from '../../TickFormatter';
import { BarChartType } from '../index';

export const useUtils = () => {
  const theme = useTheme();
  const dateRange = useDateRange();

  const getHorizontalChartTick = useCallback(
    (props: any) =>
      HorizontalChartTick({
        ...props,
        fontSize: 12,
        fill: theme.palette.text.primary,
        formatter: (value: any, index: number) =>
          TickFormatter(value, index, dateRange),
      }),
    [dateRange, theme.palette.text.primary]
  );

  const getVerticalChartTick = useCallback(
    (props: any) =>
      VerticalChartTick({
        ...props,
        fontSize: 12,
        fill: theme.palette.text.primary,
        formatter: (value: any, index: number) =>
          TickFormatter(value, index, dateRange),
      }),
    [dateRange, theme.palette.text.primary]
  );

  const getAxisInterval = useCallback(
    (chartsData: any[]) => {
      if (chartsData.some((p) => isIsoDate(p.name)))
        return ChartViewHelpers.GetChartsInterval(dateRange);

      return 0;
    },
    [dateRange]
  );

  const getDashboardChartType = useCallback((type: BarChartType) => {
    return type === BarChartType.Vertical
      ? DashboardTemplateChartType.VerticalBarChart
      : DashboardTemplateChartType.HorizontalBarChart;
  }, []);

  return {
    getHorizontalChartTick,
    getVerticalChartTick,
    getAxisInterval,
    getDashboardChartType,
  };
};
