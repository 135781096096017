import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { Button } from 'reactstrap';
import CustomTable from '../../../../../../../../../components/CustomTable';
import { headerItemType } from './CasesTableRenderer';

type CasesCustomTablePlaceholderProps = {
  headerItems: headerItemType[];
  rowsCount: number;
};

const CasesCustomTablePlaceholder = ({
  headerItems,
  rowsCount,
}: CasesCustomTablePlaceholderProps) => {
  const bodyRows = [...new Array(rowsCount).keys()].map((idx) => {
    return (
      <tr className="cursor-pointer CasesListItem" key={idx}>
        <td>
          <input type="checkbox" defaultChecked={false} />
        </td>
        <td>
          <Skeleton width={'100%'} height={20} />
        </td>
        <td>
          <Skeleton width={'100%'} height={20} />
        </td>
        <td>
          <Skeleton width={'100%'} height={20} />
        </td>
        <td>
          <div>
            <Skeleton width={'100%'} height={20} />
            <div>
              <Skeleton width={'100%'} height={20} />
            </div>
          </div>
        </td>
        <td>
          <div>
            <Skeleton width={'100%'} height={20} />
            <div>
              <Skeleton width={'100%'} height={20} />
            </div>
          </div>
        </td>
        <td className="CasesListItem__category">
          <span className="CasesListItem__category__main">
            <Skeleton width={'100%'} height={20} />
          </span>
          <span className="CasesListItem__category__sub">
            <Skeleton width={'100%'} height={20} />
          </span>
        </td>
        <td>
          <Skeleton width={'100%'} height={20} />
        </td>
        <td>
          <Button color="link" className="ProjectUsersList__table__view-btn">
            <FontAwesomeIcon icon={faEnvelope} />
          </Button>
        </td>
      </tr>
    );
  });

  return <CustomTable headerItems={headerItems} bodyRows={bodyRows} />;
};

export default CasesCustomTablePlaceholder;
