import classNames from 'classnames';
import React from 'react';
import { Col, Row } from 'reactstrap';
import { ConversationQuestion } from '../../../../../../../../interfaces/conversations';
import { IGrasp } from '../../../../../../../../interfaces/grasps';
import { IOrganizationMembers } from '../../../../../../../../interfaces/organizations';
import { DashboardFilterHelpers } from '../../helpers/filters';
import { useQueriesData } from '../../hooks/useQueriesData';
import {
  DashboardDataReducerContext,
  DashboardQueriesContext,
  DashboardTheme,
} from '../../types';
import DashboardDateSelector from '../DashboardDateSelector';
import DashboardFilters from '../DashboardFilters';
import DashboardLayout from '../DashboardLayout';
import { useDashboardDataReducer } from './hooks/useDashboardDataReducer';
import { DashboardDataStates } from './store/state';

type DashboardDataContentProps = {
  questions: ConversationQuestion[];
  grasps: IGrasp[];
  organizationsMembers: IOrganizationMembers[];
  initialDashboardData: DashboardDataStates.DashboardDataState;
};

const DashboardDataContent = ({
  questions,
  grasps,
  organizationsMembers,
  initialDashboardData,
}: DashboardDataContentProps) => {
  const dashboardDataReducer = useDashboardDataReducer(initialDashboardData);

  const [state] = dashboardDataReducer;
  const queriesData = useQueriesData(questions, grasps, organizationsMembers);

  return (
    <div
      className={classNames('AwareCasesDashboard', {
        'AwareCasesDashboard--dark': state.theme === DashboardTheme.Dark,
      })}
    >
      <DashboardDataReducerContext.Provider value={dashboardDataReducer}>
        <DashboardQueriesContext.Provider value={queriesData}>
          <Row>
            <Col xs={12} className="AwareCasesDashboardRow">
              {state.template.date_selection.visible && (
                <DashboardDateSelector />
              )}
              <DashboardFilters />
            </Col>
          </Row>
          <Row
            className={classNames({
              'mt-5': !DashboardFilterHelpers.AreFiltersHidden(
                state.template.filters
              ),
            })}
          >
            <Col xs={12}>
              <DashboardLayout />
            </Col>
          </Row>
        </DashboardQueriesContext.Provider>
      </DashboardDataReducerContext.Provider>
    </div>
  );
};

export default DashboardDataContent;
