import React from 'react';
import QueriesLoaded from '../../../../../../../../components/QueriesLoaded';
import { AwareSessionHelpers } from '../../../../../../../../helpers/data/awareSessions';
import { returnAllDataFromResponseData } from '../../../../../../../../helpers/utils/reactQueryHelpers';
import { useOrganizationPathParams } from '../../../../../../../../hooks/paths/useOrganizationPathParams';
import { useInitQueries } from '../../../../../../../../hooks/queries/useInitQuery';
import { IAwareSessionDataMapping } from '../../../../../../../../interfaces/awareSessions';
import { ConversationQuestion } from '../../../../../../../../interfaces/conversations';
import { IBaseOrganization } from '../../../../../../../../interfaces/organizations';
import { useCommonData } from '../../hooks/useCommonData';

type BesidesMembersProviderProps = {
  children: (
    mapping: IAwareSessionDataMapping,
    organizations: IBaseOrganization[],
    questions: ConversationQuestion[]
  ) => JSX.Element;
};

const BesidesMembersProvider = ({ children }: BesidesMembersProviderProps) => {
  const { organizationId } = useOrganizationPathParams();

  const { awareSessionDataMapping, userOrganizations } = useCommonData();
  useInitQueries([awareSessionDataMapping, userOrganizations]);

  return (
    <QueriesLoaded queries={[awareSessionDataMapping, userOrganizations]}>
      {(data) =>
        children(
          data[0] as IAwareSessionDataMapping,
          AwareSessionHelpers.GetAggregatedOrganizations(
            data[0] as IAwareSessionDataMapping,
            returnAllDataFromResponseData(data[1])
          ),
          AwareSessionHelpers.GetAggregatedQuestions(
            data[0] as IAwareSessionDataMapping,
            organizationId
          )
        )
      }
    </QueriesLoaded>
  );
};

export default BesidesMembersProvider;
