import useRequiredParams from '../../../hooks/useRequiredParams';
import useOrganizationDetails from '../../../api/queries/organizations/useOrganizationDetails';
import useTreatmentPlans from '../../../api/queries/conversations/useTreatmentPlans';
import useCaseCategories from '../../../api/queries/cases/useCaseCategories';

export const useTreatmentPlansCommonData = () => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const organizationQuery = useOrganizationDetails({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const treatmentPlansQuery = useTreatmentPlans({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const caseCategoriesQuery = useCaseCategories({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  return {
    organization: organizationQuery,
    treatmentPlans: treatmentPlansQuery,
    caseCategories: caseCategoriesQuery,
  };
};
