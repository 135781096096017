import { getUsersInOrganizationWithPaging } from 'api/membership';
import {
  convertIntoObjectWithPageInfo,
  returnAllDataFromResponse,
} from 'helpers/utils/reactQueryHelpers';
import { IUserOrganizationResponse } from 'interfaces/membership';
import { IError, IInfiniteResult } from 'interfaces/response';
import { useInfiniteQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import {
  OrganizationQueryParams,
  SearchAndPagingQueryParams,
} from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

type UseInfiniteUserInOrganizationsWithRolesParams = OrganizationQueryParams &
  SearchAndPagingQueryParams;

function useInfiniteUserInOrganizationsWithRoles({
  params,
  options,
}: IQueryRequest<UseInfiniteUserInOrganizationsWithRolesParams>) {
  const { organizationId, searchValue = '', pageSize = 20 } = params;
  const response = useInfiniteQuery<
    IInfiniteResult<IUserOrganizationResponse[]>,
    IError
  >({
    ...options,
    queryKey: [QueryKeys.getUsersInOrganization, organizationId, searchValue],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getUsersInOrganizationWithPaging(organizationId, {
        page: pageParam,
        page_size: pageSize,
        search: searchValue,
      });

      return convertIntoObjectWithPageInfo<IUserOrganizationResponse[]>(data);
    },
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage;
    },
    getPreviousPageParam: (firstPage) => firstPage.previousPage,
  });

  return {
    ...response,
    userRecord: returnAllDataFromResponse<IUserOrganizationResponse>(response),
    hasMore: !response.isLoading && response.hasNextPage,
  };
}

export default useInfiniteUserInOrganizationsWithRoles;
