import { useCallback } from 'react';
import {
  ConversationTemplate,
  ConversationTemplateContext,
  ConversationTemplateItemType,
  ConversationTemplateTriggerType,
  IFormConversation,
  IFormConversationItem,
  ValidConversationQuestionType,
  ValidConversationTemplateItem,
  ValidConversationTemplateItemType,
} from '../../../../../../../interfaces/conversations';
import { v4 as uuidv4 } from 'uuid';
import {
  getCaseInvariantPropertyValue,
  getCaseInvariantPropertyValues,
  getValidConversationQuestion,
} from '../../../../../../../helpers/data/conversations';

const getTemplateItem = (
  item: IFormConversationItem
): ValidConversationTemplateItem => {
  switch (item.type) {
    case ConversationTemplateItemType.Delimiter:
      return {
        type: ValidConversationTemplateItemType.Delimiter,
        condition: item.condition,
        visible: true,
        persist: false,
        apply_typing_effect: false,
      };
    case ConversationTemplateItemType.Education:
      return {
        type: ValidConversationTemplateItemType.Education,
        condition: item.condition,
        visible: true,
        apply_typing_effect: false,
        resource_id: item.resourceId ?? '',
      };
    case ConversationTemplateItemType.Feedback:
      return {
        type: ValidConversationTemplateItemType.Feedback,
        condition: item.condition,
        visible: true,
        apply_typing_effect: false,
        group_name:
          getCaseInvariantPropertyValue(item.value, 'feedbackGroupName') ?? '',
      };
    case ConversationTemplateItemType.GraspUpload:
      return {
        type: ValidConversationTemplateItemType.GraspUpload,
        condition: item.condition,
        visible: true,
        apply_typing_effect: false,
      };
    case ConversationTemplateItemType.MaterialsList:
      return {
        type: ValidConversationTemplateItemType.MaterialsList,
        condition: item.condition,
        visible: true,
        apply_typing_effect: false,
      };
    case ConversationTemplateItemType.Question:
      return {
        type: ValidConversationTemplateItemType.Question,
        apply_typing_effect: false,
        visible: true,
        condition: item.condition,
        resource_id: getCaseInvariantPropertyValue(item.value, 'guid') ?? '',
        value: getValidConversationQuestion(
          item.value.type as ValidConversationQuestionType,
          item.value
        ),
      };
    case ConversationTemplateItemType.NotificationsList:
      return {
        type: ValidConversationTemplateItemType.NotificationsList,
        condition: item.condition,
        visible: true,
        apply_typing_effect: false,
      };
    case ConversationTemplateItemType.PlainText:
      return {
        type: ValidConversationTemplateItemType.PlainText,
        condition: item.condition,
        apply_typing_effect: false,
        visible: true,
        value: {
          text: getCaseInvariantPropertyValue(item.value, 'text') ?? '',
          text_nb_no:
            getCaseInvariantPropertyValues(item.value, [
              'text_NbNo',
              'text_nbNo',
              'text_nbno',
            ]) ?? '',
          text_html: getCaseInvariantPropertyValue(item.value, 'textHtml'),
          text_html_nb_no:
            getCaseInvariantPropertyValues(item.value, [
              'textHtml_NbNo',
              'textHtml_nbNo',
              'textHtml_nbno',
            ]) ?? '',
        },
      };
  }
};

export const useHandleConvertToTemplate = () => {
  return useCallback(
    (conversation: IFormConversation): ConversationTemplate => {
      return {
        id: uuidv4(),
        context: ConversationTemplateContext.UserConversation,
        last_resort_template: true,
        display_code: '',
        organization_id: '',
        trigger_type: ConversationTemplateTriggerType.PhaseLastResort,
        content: conversation.items.map((item) => getTemplateItem(item)),
      };
    },
    []
  );
};
