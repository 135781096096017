import { useCallback } from 'react';
import { cloneDeep } from 'lodash';

export const useLedLevelFractions = () => {
  return useCallback((hexColor: string, fraction: 'r' | 'g' | 'b') => {
    let hexColorCopy = cloneDeep(hexColor);
    hexColorCopy = hexColorCopy.replace('#', '').toLowerCase();

    switch (fraction) {
      case 'r':
        return hexColorCopy.startsWith('ff');
      case 'g':
        return `${hexColorCopy[2]}${hexColorCopy[3]}` === 'ff';
      case 'b':
        return `${hexColorCopy[4]}${hexColorCopy[5]}` === 'ff';
    }
  }, []);
};
