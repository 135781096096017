import { IBaseExerciseSession } from '../interfaces/exerciseSessions';
import { StrictDateRange } from '../interfaces/dates';
import { IObservation } from '../interfaces/timeline';
import { cloneDeep } from 'lodash';
import { differenceInSeconds } from 'date-fns';
import { groupByProperty } from './utils/groupByProperty';

export const getExercisesPeriods = (
  exercises: IBaseExerciseSession[]
): StrictDateRange[] => {
  let periods: StrictDateRange[] = [];

  exercises.forEach((exercise) => {
    periods.push({
      from: new Date(exercise.time_start),
      to: new Date(exercise.time_end),
    });
  });

  return periods;
};

export const filterExerciseObservations = (
  periods: StrictDateRange[],
  observations: IObservation[]
) => {
  return observations.filter((observation) => {
    const timestampDate = new Date(observation.timestamp);
    return periods.some(
      (p) => timestampDate >= p.from && timestampDate <= p.to
    );
  });
};

export const getDateOnlyExerciseSessions = (
  exercises: IBaseExerciseSession[]
): (IBaseExerciseSession & {
  durationInMinutes: number;
})[] => {
  return exercises.map((exercise) => {
    let exerciseCopy = cloneDeep(exercise);

    let timeStartDate = new Date(exerciseCopy.time_start);
    timeStartDate.setHours(0, 0, 0);

    let timeEndDate = new Date(exerciseCopy.time_end);
    timeEndDate.setHours(0, 0, 0);

    exerciseCopy.time_start = timeStartDate.toISOString();
    exerciseCopy.time_end = timeEndDate.toISOString();

    return {
      ...exerciseCopy,
      durationInMinutes:
        differenceInSeconds(
          new Date(exercise.time_end),
          new Date(exercise.time_start)
        ) / 60,
    };
  });
};

export const groupByTimeStart = (
  sessions: (IBaseExerciseSession & { durationInMinutes: number })[]
) => {
  const _groupByTimeStart = groupByProperty('time_start');
  return _groupByTimeStart(sessions);
};

export const sumOfDuration = (
  sessions: (IBaseExerciseSession & { durationInMinutes: number })[]
) => {
  let sum = 0;

  sessions.forEach((item) => {
    sum += item.durationInMinutes;
  });

  return sum;
};
