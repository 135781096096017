import { getUserContextAssessmentFormSummary } from 'api/graspManagement';
import QueryKeys from 'api/queries/queryKeys';
import { TimelineQueryParams, UserQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { useQuery } from 'react-query';

type UseUserContextAssessmentFormSummaryParams = UserQueryParams &
  TimelineQueryParams;

const useUserContextAssessmentFormSummary = ({
  params,
  options,
}: IQueryRequest<UseUserContextAssessmentFormSummaryParams>) => {
  const { userId, timelineId } = params;

  return useQuery({
    ...options,
    queryKey: [
      QueryKeys.getUserContextAssessmentFormSummary,
      userId,
      timelineId,
    ],
    queryFn: async () => {
      const { data: responseData } = await getUserContextAssessmentFormSummary(
        userId,
        timelineId
      );

      return responseData.data;
    },
  });
};

export default useUserContextAssessmentFormSummary;
