import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import {
  IInfiniteGraspOrganization,
  OrganizationPatient,
} from 'interfaces/grasps';
import { Size } from 'interfaces/ui';
import GraspLogo from 'components/GraspLogo';
import React from 'react';
import { useIntl } from 'react-intl';
import { formatDate } from 'helpers/dates';
import DeviceNameField from '../components/DeviceNameField';

export const useColumns = (): GridColDef<IInfiniteGraspOrganization>[] => {
  const intl = useIntl();

  return [
    {
      field: 'hex_color',
      headerName: '',
      renderCell: (params: GridRenderCellParams<any, string>) => {
        return <GraspLogo size={Size.SMALL} color={params.value} width={45} />;
      },
      cellClassName: 'text-muted',
      width: 75,
    },
    {
      field: 'name',
      headerName: intl.formatMessage({ id: 'Organizations.DeviceName' }),
      cellClassName: 'text-muted',
      width: 300,
      editable: false,
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <DeviceNameField params={params} />
      ),
    },
    {
      field: 'battery_level',
      headerName: intl.formatMessage({ id: 'Grasps.BatteryLevel' }),
      cellClassName: 'text-muted',
      renderCell: (params: GridRenderCellParams<any, number>) => {
        if (!params.value) return '--';

        if (params.value < 30)
          return <span className="text-danger">{params.value}</span>;

        return params.value;
      },
      width: 100,
    },
    {
      field: 'battery_last_synchronization',
      headerName: intl.formatMessage({ id: 'Grasps.LastBatterySync' }),
      renderCell: (params: GridRenderCellParams<any, string>) => {
        return !params.value ? '--' : formatDate(params.value);
      },
      cellClassName: 'text-muted',
      width: 200,
    },
    {
      field: 'patient',
      headerName: intl.formatMessage({ id: 'Grasps.LastUsedBy' }),
      renderCell: (
        params: GridRenderCellParams<any, OrganizationPatient | null>
      ) => {
        const formattedPatient = `${params.value?.last_name ?? ''} ${
          params.value?.first_name ?? ''
        }`;

        if (formattedPatient.trim().length === 0) return '--';
        return formattedPatient;
      },
      cellClassName: 'text-muted',
      width: 150,
    },
    {
      field: 'serial_number',
      headerName: intl.formatMessage({ id: 'Grasps.SerialNumber' }),
      renderCell: (params: GridRenderCellParams<any, string | null>) => {
        return params.value ? params.value : '--';
      },
      cellClassName: 'text-muted',
      width: 150,
    },
    {
      field: 'physical_grasp_uuid',
      headerName: intl.formatMessage({ id: 'Grasps.PhysicalGraspUuid' }),
      renderCell: (params: GridRenderCellParams<any, string | null>) => {
        return params.value ? params.value : '--';
      },
      cellClassName: 'text-muted',
      flex: 1,
    },
  ];
};
