import React from 'react';
import ItemSection from '../../../components/ItemSection';
import caseCategoriesTrends from 'assets/images/trends_timeline/case_trends.svg';
import SelectSection from '../../../components/SelectSection';
import { useCommonData } from 'modules/Organizations/views/OrganizationDetailsView/hooks/useCommonData';
import { useInitQueries } from 'hooks/queries/useInitQuery';

const CaseTrends = () => {
  const { caseCategories, trendVisualizationTemplates } = useCommonData();
  useInitQueries([caseCategories, trendVisualizationTemplates]);

  return (
    <>
      {caseCategories && (
        <div className="TrendVisualizationsOrganizationsTrends">
          <div className="TrendVisualizationsOrganizationsTrends__header">
            <ItemSection
              icon={caseCategoriesTrends}
              textId={
                'MyOrganizations.trendVisualizations.trendsRelatedToTheCaseCategories'
              }
            />
          </div>
          <div className="TrendVisualizationsOrganizationsTrends__sections">
            {(caseCategories.data ?? []).map((category, index) => (
              <SelectSection
                key={category.id}
                title={category.name}
                type={category.id}
                templates={trendVisualizationTemplates.data ?? []}
                childItems={category.sub_categories.map((subCategory) => ({
                  type: category.id,
                  subtype: subCategory.id,
                  title: subCategory.name,
                }))}
                hasHeader={index === 0}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default CaseTrends;
