import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Formik } from 'formik';
import { Col, FormGroup, Input, Row } from 'reactstrap';
import { Link } from 'react-router-dom'; // COMPONENTS:
import LoadingButton from 'components/LoadingButton';
import { IRegisterForm } from 'interfaces/membership';
import RegisterFields from './RegisterFields';
import { PasswordComplexityConstraints } from 'components/PasswordComplexity';
import { getPasswordConstraintsFulfilled } from 'helpers/utils/validators';
import { RegisterFormKeys } from 'interfaces/enums';
import { useLocation } from 'react-router';
import { RouteDefinitions } from '../../../../helpers/routeDefinitions';

const VALIDATED_KEYS = [
  RegisterFormKeys.FIRST_NAME,
  RegisterFormKeys.LAST_NAME,
  RegisterFormKeys.EMAIL,
  RegisterFormKeys.PASSWORD,
  RegisterFormKeys.TIMEZONE_ID,
  RegisterFormKeys.PASSWORD_REPEAT,
];

type RegisterFormProps = {
  isLoading: boolean;
  handleSubmit: (values: IRegisterForm) => void;
  externalIdentity?: string;
  externalProvider?: string;
};

const initialValues: IRegisterForm = {
  first_name: '',
  last_name: '',
  address: '',
  birthdate: '',
  culture: '',
  password: '',
  timezone_id: Intl.DateTimeFormat().resolvedOptions().timeZone,
  passwordRepeat: '',
  token: null,
  email: '',
  user_name: '',
  gender: '',
  ssn: '',
  phone_number: '',
  external_login_provider: null,
};

const RegisterForm = ({
  isLoading,
  handleSubmit,
  externalIdentity = undefined,
  externalProvider = undefined,
}: RegisterFormProps) => {
  const intl = useIntl();
  const [emailFromUrl, setEmailFromUrl] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);

  const location = useLocation();

  const [passwordConstraints, setPasswordConstraints] = useState<
    PasswordComplexityConstraints[]
  >([]);

  useEffect(() => {
    const queryString = window.location.search;

    if (location.pathname === RouteDefinitions.ORGANIZATION_REGISTER) {
      setEmailFromUrl(false);
      return;
    }

    if (queryString) {
      const urlParams = new URLSearchParams(queryString);
      const email = urlParams.get('encodedEmail');
      const token = urlParams.get('token');

      if (email) initialValues.email = atob(email);
      if (token) initialValues.token = token;
      setEmailFromUrl(true);
    }

    if (externalIdentity && externalProvider) {
      initialValues.email = externalIdentity;
      initialValues.external_login_provider = externalProvider ?? null;
    }
  }, [externalIdentity, externalProvider, location.pathname]);

  const validate = (values: IRegisterForm) => {
    const errors = {};
    for (const key of VALIDATED_KEYS) {
      if (!values[key] || (values[key] as string).trim().length === 0) {
        errors[key] = intl.formatMessage({
          id: 'CaseCard.create.errors.inputEmpty',
        });
      }
    }

    setPasswordConstraints(getPasswordConstraintsFulfilled(values.password));
    return errors;
  };

  const onTermsAccept = () => {
    setAcceptTerms((acceptTerms) => !acceptTerms);
  };

  const mandatoryFields = [
    RegisterFormKeys.FIRST_NAME,
    RegisterFormKeys.LAST_NAME,
    RegisterFormKeys.TIMEZONE_ID,
    RegisterFormKeys.EMAIL,
    RegisterFormKeys.PASSWORD,
  ];

  const optionFields = [
    RegisterFormKeys.ADDRESS,
    RegisterFormKeys.GENDER,
    RegisterFormKeys.SSN,
    RegisterFormKeys.GENDER,
    RegisterFormKeys.PHONE_NUMBER,
    RegisterFormKeys.BIRTH_DATE,
  ];

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validate={validate}
    >
      {(form) => (
        <Form className="registerForm">
          <RegisterFields
            formik={form}
            mandatoryFields={mandatoryFields}
            optionalFields={optionFields}
            disabledFields={emailFromUrl ? [RegisterFormKeys.EMAIL] : []}
            passwordComplexityConstraints={passwordConstraints}
          />
          <Row className="mt-4">
            <Col xl={12}>
              <FormGroup check>
                <Input
                  id="acceptTerms"
                  type="checkbox"
                  name="acceptTerms"
                  required
                  checked={acceptTerms}
                  onChange={onTermsAccept}
                />
                <label
                  className="newMembersSection__secondLink__desc d-flex align-items-center gap-1"
                  htmlFor="acceptTerms"
                >
                  <span>
                    <FormattedMessage id="Register.readAndAgree" />
                  </span>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://grasp.global/terms-and-conditions"
                    className="newMembersSection__secondLink mt-0"
                  >
                    <FormattedMessage id="Register.RegisterForm.termsService" />
                  </a>
                  <span>
                    <FormattedMessage id="General.and" />
                  </span>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href="https://grasp.global/privacy-policy"
                    className="newMembersSection__secondLink mt-0"
                  >
                    <FormattedMessage id="Register.RegisterForm.privacyPolicy" />
                  </a>
                </label>
              </FormGroup>
            </Col>
          </Row>
          <div className="d-flex mt-4 align-items-end justify-content-between">
            <LoadingButton
              outline
              disabled={!form.isValid || !acceptTerms}
              isLoading={isLoading || form.isSubmitting}
              value="Register.SignUp"
              type="submit"
            />
            <div className="text-right">
              <span className="newMembersSection__secondLink__desc">
                <FormattedMessage id="Register.RegisterForm.haveAccount" />{' '}
              </span>
              <Link to="/login" className="newMembersSection__secondLink">
                <FormattedMessage id="Register.RegisterForm.goLoginPage" />
              </Link>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default RegisterForm;
