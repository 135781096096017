import React, { useMemo, useState } from 'react';
import { ICaseGroupConversationScheduleResponse } from '../../../../../../../../../../interfaces/conversationSchedules';
import CustomTable from '../../../../../../../../../../components/CustomTable';
import { Button } from 'reactstrap';
import { formatDate } from '../../../../../../../../../../helpers/dates';
import emailIcon from 'assets/images/email_icon.svg';
import caseIcon from 'assets/images/case_icon.svg';
import SVG from 'react-inlinesvg';
import { useNavigate } from 'react-router';
import useRequiredParams from '../../../../../../../../../../hooks/useRequiredParams';
import CaseHistoryModal from '../../../../../../../../../Cases/modals/CaseHistoryModal';
import useOpen from '../../../../../../../../../../hooks/useOpen';

type CaseGroupDetailsConversationSchedulesParticipantsListProps = {
  responses: ICaseGroupConversationScheduleResponse[];
};

const headerItems = [
  'CaseGroups.CaseGroupDetails.CaseGroupDetailsConversationSchedules.CaseGroupDetailsConversationSchedulesResponsesModal.participant',
  'CaseGroups.CaseGroupDetails.CaseGroupDetailsConversationSchedules.CaseGroupDetailsConversationSchedulesResponsesModal.lastFeedback',
  '',
];

const ResponsesModalParticipantsList = ({
  responses,
}: CaseGroupDetailsConversationSchedulesParticipantsListProps) => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const navigate = useNavigate();

  const { isOpen, toggle } = useOpen();

  const [caseIds, setCaseIds] = useState<string[]>([]);

  const missingResponses = useMemo(() => {
    return responses.filter((p) => p.is_completed);
  }, [responses]);

  const renderBodyRows = useMemo(() => {
    return missingResponses.map((response) => {
      const handleMoveToCase = () => {
        navigate(`/organizations/${organizationId}/cases/${response.case_id}`);
      };

      const handleSendMessage = () => {
        setCaseIds([response.case_id]);
        toggle();
      };

      return (
        <tr key={response.case_id}>
          <td valign="middle">
            <span>{`${response.participant_first_name} ${response.participant_last_name}`}</span>
          </td>
          <td valign="middle">
            <span>
              {!response.case_last_feedback
                ? '-'
                : formatDate(response.case_last_feedback)}
            </span>
          </td>
          <td valign="middle" align="right">
            <div className="d-flex align-items-center gap-2">
              <Button
                onClick={handleSendMessage}
                outline={true}
                color="primary"
              >
                <SVG src={emailIcon} />
              </Button>
              <Button onClick={handleMoveToCase} outline={true} color="primary">
                <SVG src={caseIcon} />
              </Button>
            </div>
          </td>
        </tr>
      );
    });
  }, [missingResponses, navigate, organizationId, toggle]);

  return (
    <div className="mt-4">
      {isOpen && caseIds.length > 0 && (
        <CaseHistoryModal
          caseIds={caseIds}
          organizationId={organizationId}
          isOpen={isOpen}
          toggleModal={toggle}
          hidePraise={true}
          hideShare={true}
          extendedValues={{
            share_with_patient: true,
          }}
          customTitleTranslationId="CaseGroups.CaseGroupDetails.CaseGroupDetailsCases.sendMessage"
        />
      )}
      <CustomTable headerItems={headerItems} bodyRows={renderBodyRows} />
    </div>
  );
};

export default ResponsesModalParticipantsList;
