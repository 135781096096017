import InputFieldWithImage, {
  InputFieldWithImageColor,
} from 'components/InputFieldWithImage';
import { ChangeEvent, useCallback, useState } from 'react';
import {
  Button,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Input,
  UncontrolledDropdown,
} from 'reactstrap';

import SVG from 'react-inlinesvg';

import calendarIcon from 'assets/images/calendar_icon.svg';
import deleteIcon from 'assets/images/delete_icon.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

type FeedbackFrequencyRowProps = {
  expectedFrequency: number;
  disableAssignmentMode: () => void;
  updateFrequency: (frequency: number) => void;
  labelTranslationId: string;
  readOnly: boolean;
  inputId: string;
};

export enum FeedbackFrequencyTypes {
  HOURS = 'Hours',
  DAYS = 'Days',
  WEEKS = 'Weeks',
  MONTHS = 'Months',
}

export const FrequencyMultiplier = {
  [FeedbackFrequencyTypes.HOURS]: 1,
  [FeedbackFrequencyTypes.DAYS]: 24,
  [FeedbackFrequencyTypes.WEEKS]: 168,
  [FeedbackFrequencyTypes.MONTHS]: 5040,
};

const PatientResponseFrequencyRow = ({
  expectedFrequency,
  disableAssignmentMode,
  updateFrequency,
  labelTranslationId,
  readOnly,
  inputId,
}: FeedbackFrequencyRowProps) => {
  const getFrequencyInfoBasedOnHours = useCallback(() => {
    const result = {
      hours: expectedFrequency,
      range: FeedbackFrequencyTypes.HOURS,
    };

    if (expectedFrequency % FrequencyMultiplier.Months === 0) {
      return {
        hours: expectedFrequency / FrequencyMultiplier.Months,
        range: FeedbackFrequencyTypes.MONTHS,
      };
    }

    if (expectedFrequency % FrequencyMultiplier.Weeks === 0) {
      return {
        hours: expectedFrequency / FrequencyMultiplier.Weeks,
        range: FeedbackFrequencyTypes.WEEKS,
      };
    }

    if (expectedFrequency % FrequencyMultiplier.Days === 0) {
      return {
        hours: expectedFrequency / FrequencyMultiplier.Days,
        range: FeedbackFrequencyTypes.DAYS,
      };
    }

    return result;
  }, [expectedFrequency]);

  const [hoursValue, setHoursValue] = useState(
    getFrequencyInfoBasedOnHours().hours
  );
  const [dropdownValue, setDropdownValue] = useState(
    getFrequencyInfoBasedOnHours().range
  );

  const handleHoursValue = (e: ChangeEvent<HTMLInputElement>) => {
    setHoursValue(e.currentTarget.valueAsNumber);
  };

  const handleUpdateFrequency = () => {
    const multiplier = FrequencyMultiplier[dropdownValue];

    updateFrequency(hoursValue * multiplier);
  };

  const handleRemoveFrequency = () => {
    updateFrequency(0);
    disableAssignmentMode();
  };

  const renderDropdownItems = Object.entries(FeedbackFrequencyTypes).map(
    ([key, type]) => {
      const handleDropdownItem = () => {
        setDropdownValue(type);
      };

      return (
        <DropdownItem key={key} onClick={handleDropdownItem}>
          {type}
        </DropdownItem>
      );
    }
  );

  return (
    <div className="FeedbackFrequencyRow">
      <div className="FeedbackFrequencyRow__label">
        <FormattedMessage id={labelTranslationId} />
      </div>
      <div className="FeedbackFrequencyRow__container">
        <div
          className={classNames('FeedbackFrequencyRow__container__input', {
            'FeedbackFrequencyRow__container__input--disabled': readOnly,
          })}
        >
          <div className="FeedbackFrequencyRow__container__input__field mt-3">
            <InputFieldWithImage
              color={InputFieldWithImageColor.gray}
              image={<img src={calendarIcon} alt="search_icon" />}
              disabled={readOnly}
            >
              <Input
                id={inputId}
                type="number"
                min={1}
                value={hoursValue}
                className="form-control"
                onChange={handleHoursValue}
                disabled={readOnly}
              />
            </InputFieldWithImage>
          </div>
          <div className="FeedbackFrequencyRow__container__input__dropdown">
            <UncontrolledDropdown disabled={readOnly}>
              <DropdownToggle tag="div" caret>
                <span data-testid="selected-multiplier">{dropdownValue}</span>
              </DropdownToggle>
              <DropdownMenu>{renderDropdownItems}</DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
        <div className="FeedbackFrequencyRow__container__actions">
          <Button
            className="FeedbackFrequencyRow__container__actions__save"
            color="success"
            disabled={hoursValue <= 0 || isNaN(hoursValue) || readOnly}
            onClick={handleUpdateFrequency}
            aria-label="confirm"
          >
            <FontAwesomeIcon icon={faCheck} />
          </Button>
          <Button
            className="FeedbackFrequencyRow__container__actions__remove"
            color="danger"
            onClick={handleRemoveFrequency}
            disabled={readOnly}
            aria-label="delete"
          >
            <SVG src={deleteIcon} title="delete" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default PatientResponseFrequencyRow;
