import { userContextUpdateAssessmentForm } from 'api/graspManagement';
import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { AssessmentQuestion } from 'interfaces/assessmentForms';
import { IMutationProps } from '../../../../interfaces/ui';

type MutationParam = {
  assessmentBody: AssessmentQuestion[];
  code: string;
  title: string;
};

type useUserContextUpdateAssessmentFormParams = {
  userId: string;
  timelineId: string | null;
  date: Date;
  sequence: number;
};

const useUserContextUpdateAssessmentForm = (
  params: useUserContextUpdateAssessmentFormParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification<MutationParam, void>({
    asyncApiFunction: async ({ code, title, assessmentBody }) => {
      await userContextUpdateAssessmentForm(
        params.userId,
        params.timelineId,
        code,
        title,
        params.date,
        params.sequence,
        assessmentBody
      );
    },
    onSuccess: () => {
      displaySuccessNotification('TimelineDetails.assessmentUpdateSuccess');
      if (options.successFb) options.successFb();
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'TimelineDetails.assessmentUpdateError'
      );

      if (options.errorFb) options.errorFb();
    },
    resetOnResult: true,
  });
};

export default useUserContextUpdateAssessmentForm;
