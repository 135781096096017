import React, { useState } from 'react';
import { FieldProps } from 'formik';
import FlagCultureSelector from '../../../CultureSelector/FlagCultureSelector';
import useCultures, {
  AvailableCulture,
} from '../../../../hooks/cultures/useCultures';

interface FieldCultureSelectProps extends Partial<FieldProps> {
  placeholder?: string;
  disabled?: boolean;
  searchable?: boolean;
}

const FieldCultureSelect = ({
  form,
  field,
  disabled,
  placeholder,
  searchable,
}: FieldCultureSelectProps) => {
  const { getAvailableCulture } = useCultures();

  const [selectedCulture, setSelectedCulture] = useState<
    AvailableCulture | undefined
  >(getAvailableCulture(field?.value as string) ?? undefined);

  const handleSelectCulture = (culture: AvailableCulture) => {
    setSelectedCulture(culture);

    if (!form || !field) return;
    form.setFieldValue(field.name, culture.cultureCode, true);
  };

  return (
    <FlagCultureSelector
      selectedCulture={selectedCulture}
      setSelectedCulture={handleSelectCulture}
      placeholder={placeholder ?? ''}
      disabled={disabled ?? false}
      searchable={searchable ?? false}
    />
  );
};

export default FieldCultureSelect;
