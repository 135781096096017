import {
  ILoginResponse,
  LoginMethod,
  TokenTypes,
} from '../../../interfaces/membership';
import { setUserData } from '../../../helpers/utils/auth';

export const useApplyUserData = () => {
  return (
    data: ILoginResponse,
    loginAs: LoginMethod,
    tokenType: TokenTypes
  ) => {
    const {
      token,
      valid_until,
      organizations,
      user: { id, email },
    } = data;

    setUserData({
      accessToken: token,
      email,
      id,
      loginAs: loginAs,
      organizations,
      tokenType,
      validUntil: valid_until,
    });
  };
};
