import React from 'react';
import VerticalTabContainer from '../../../../../../components/Tabs/components/VerticalTabContainer';
import { useTabs } from './hooks/useTabs';

const DentalOrganizationDetails = () => {
  const { tabItems, onTabChange } = useTabs();
  return <VerticalTabContainer items={tabItems} onTabChange={onTabChange} />;
};

export default DentalOrganizationDetails;
