import { ConversationScheduleType } from './conversationSchedules';

interface ICaseNotificationSchedule {
  id: string;
  user_id: string;
  case_id: string;
  origin_id?: string;
  notification_type: CaseNotificationScheduleType;
  schedule_type:
    | ConversationScheduleType.SingleInstance
    | ConversationScheduleType.Recurring;
  is_sent: boolean;
  is_dirty: boolean;
  is_deleted: boolean;
}

export type CaseNotificationSchedule =
  | ISingleNotificationSchedule
  | IRecurringNotificationSchedule;

export interface ISingleNotificationSchedule extends ICaseNotificationSchedule {
  schedule_type: ConversationScheduleType.SingleInstance;
  utc_timezone_time: string;
  user_timezone_time: string;
}

export interface IRecurringNotificationSchedule
  extends ICaseNotificationSchedule {
  schedule_type: ConversationScheduleType.Recurring;
  utc_timezone_from: string;
  utc_timezone_to: string;
  recurring_interval: number;
}

export enum CaseNotificationScheduleType {
  GraspFlashing = 'GraspFlashing',
}

export interface ICreateNotificationSchedule {
  origin_id?: string;
  notification_type: CaseNotificationScheduleType;
  schedule_type:
    | ConversationScheduleType.SingleInstance
    | ConversationScheduleType.Recurring;
  recurring_interval?: number;
  date?: string;
  date_from?: string;
  date_to?: string;
}

export interface IUpdateNotificationSchedule {
  notification_type?: CaseNotificationScheduleType;
  recurring_interval?: number;
  date?: string;
  date_from?: string;
  date_to?: string;
}
