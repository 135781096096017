import { useTheme } from '@mui/material';
import React, { useMemo, useRef } from 'react';
import {
  Bar,
  BarChart as ReBarChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useTypedContext } from '../../../../../../../../../../../../../../hooks/useTypedContext';
import {
  DashboardTemplateSeries,
  DashboardTemplateSplitType,
  DateGranularity,
} from '../../../../../../../../../../../../../../interfaces/dashboards';
import { useSeriesColor } from '../../../../hooks/useSeriesColor';
import { VisualizationDataContext } from '../../../../types';
import { ChartViewHelpers } from '../../helpers';
import { useChartWidthAxisInterval } from '../../hooks/useChartWidthAxisInterval';
import { useNonPieChartAccumulation } from '../../hooks/useNonPieChartAccumulation';
import { useNonPieChartEmptyPoints } from '../../hooks/useNonPieChartEmptyPoints';
import { useNonPieChartFormattedData as useFormattedData } from '../../hooks/useNonPieChartFormattedData';
import ChartPlaceholder from '../ChartPlaceholder';
import { ChartTooltip } from '../ChartTooltip';
import { useUtils } from './hooks/useUtils';

export enum BarChartType {
  Horizontal = 'Horizontal',
  Vertical = 'Vertical',
}

type BarChartProps = {
  config: DashboardTemplateSeries[];
  type: BarChartType;
  dateGranularity?: DateGranularity;
};

const BarChart = ({ type, config, dateGranularity }: BarChartProps) => {
  const theme = useTheme();
  const chartRef = useRef<HTMLDivElement>(null);
  const chartsData = useTypedContext(VisualizationDataContext);

  let formattedData = useFormattedData(
    chartsData.sessions.current.data,
    config,
    dateGranularity,
    config.at(0)?.split_by?.type === DashboardTemplateSplitType.Question ||
      config.at(0)?.split_by?.type === DashboardTemplateSplitType.Doctor ||
      config.at(0)?.split_by?.type === DashboardTemplateSplitType.Grasp
  );

  const nonEmptyData = useNonPieChartEmptyPoints(formattedData);
  const data = useNonPieChartAccumulation(nonEmptyData, config);

  const {
    getHorizontalChartTick,
    getVerticalChartTick,
    getDashboardChartType,
    getAxisInterval,
  } = useUtils();

  const getWidthAxisInterval = useChartWidthAxisInterval();

  const { getColor } = useSeriesColor();

  const renderBars = useMemo(() => {
    return ChartViewHelpers.GetDataKeys(data).map((key, index) => {
      return (
        <Bar
          key={key}
          dataKey={key}
          fill={getColor(index)}
          radius={4}
          barSize={20}
          label={
            type === BarChartType.Horizontal
              ? undefined
              : {
                  position: 'right',
                  fontSize: 14,
                  fill: theme.palette.text.primary,
                }
          }
        />
      );
    });
  }, [data, getColor, theme.palette.text.primary, type]);

  const chartType = getDashboardChartType(type);

  if (ChartViewHelpers.AreEmptyFormattedData(data))
    return <ChartPlaceholder type={chartType} />;

  return (
    <ResponsiveContainer
      ref={chartRef}
      width="100%"
      className="AwareCasesBarChart"
    >
      <ReBarChart
        data={data}
        layout={type.toLowerCase() as 'horizontal' | 'vertical'}
        margin={{
          top: 10,
          left: 20,
          right: 20,
        }}
      >
        {type === BarChartType.Vertical ? (
          <>
            <XAxis
              type="number"
              axisLine={false}
              hide={true}
              padding={{ left: 8 }}
              tick={getHorizontalChartTick}
              tickLine={{ stroke: theme.palette.text.primary }}
            />
            <YAxis
              type="category"
              dataKey="name"
              axisLine={false}
              tickMargin={4}
              interval={getAxisInterval(data)}
              tick={getVerticalChartTick}
              tickLine={{ stroke: theme.palette.text.primary }}
            />
          </>
        ) : (
          <>
            <XAxis
              dataKey="name"
              axisLine={false}
              tickMargin={15}
              interval={getWidthAxisInterval(data, chartRef)}
              tick={getHorizontalChartTick}
              tickLine={{ stroke: theme.palette.text.primary }}
            />
            <YAxis
              width={10}
              axisLine={false}
              padding={{ bottom: 8 }}
              tick={getVerticalChartTick}
              interval="preserveStartEnd"
              tickLine={{ stroke: theme.palette.text.primary }}
            />
          </>
        )}
        <Tooltip
          cursor={{ fill: 'transparent' }}
          content={ChartTooltip}
          wrapperStyle={{ zIndex: 10 }}
        />
        {renderBars}
      </ReBarChart>
    </ResponsiveContainer>
  );
};

export default BarChart;
