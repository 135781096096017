import { AssetType } from '../../../../../../../../../../../interfaces/educationMaterials';
import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  GraspColors,
  graspColorsValues,
} from '../../../../../../../../../../../helpers/colors';
import {
  faFileExcel,
  faImage,
  faVideo,
} from '@fortawesome/pro-light-svg-icons';

export const useAssetIcon = (type: AssetType, thumbnailUrl?: string) => {
  return useMemo(() => {
    if (thumbnailUrl)
      return <img className="AssetThumbnail" src={thumbnailUrl} alt={type} />;

    switch (type) {
      case AssetType.Video:
        return (
          <FontAwesomeIcon
            color={graspColorsValues[GraspColors.BLUE]}
            icon={faVideo}
            size="2x"
          />
        );
      case AssetType.Image:
        return (
          <FontAwesomeIcon
            color={graspColorsValues[GraspColors.BLUE]}
            size="2x"
            icon={faImage}
          />
        );
      case AssetType.Attachment:
        return (
          <FontAwesomeIcon
            color={graspColorsValues[GraspColors.BLUE]}
            size="2x"
            icon={faFileExcel}
          />
        );
    }
  }, [thumbnailUrl, type]);
};
