import React from 'react';
import {
  Button,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
} from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import useOpen from '../../../../../../../../../../hooks/useOpen';
import { useTranslations } from '../../../../../../../../../../hooks/useTranslations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { useAvailableItems } from './hooks/useAvailableItems';
import { useAddItemDisabled } from './hooks/useAddItemDisabled';
import { ValidConversationTemplateItem } from '../../../../../../../../../../interfaces/conversations';
import { useHandleAddItem } from './hooks/useHandleAddItem';

type AddConversationItemProps = {
  onAdd: (item: ValidConversationTemplateItem) => void;
};

const AddConversationItem = ({ onAdd }: AddConversationItemProps) => {
  const { isOpen, toggle } = useOpen();

  const { renderedItems, selectedQuestion, selectedOption } =
    useAvailableItems();

  const addItemDisabled = useAddItemDisabled(selectedOption, selectedQuestion);

  const handleAddItem = useHandleAddItem(
    selectedOption,
    selectedQuestion,
    onAdd,
    toggle
  );

  const { attachPrefix } = useTranslations(
    'ConversationsAndFeedbacks.ConversationTemplates.ConversationTemplatePhone.Conversation.AddConversationItem'
  );

  return (
    <>
      <div className="ConversationTemplatePhoneView__add-item">
        <Button
          className="mt-4 mb-3 d-flex justify-content-center align-items-center"
          id="ConversationTemplatePhoneAddItemPopover"
          outline={true}
          onClick={toggle}
          disabled={isOpen}
          block={true}
          color="primary"
        >
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </div>
      <UncontrolledPopover
        isOpen={isOpen}
        className="AddConversationItemPopover"
        target="ConversationTemplatePhoneAddItemPopover"
        placement="auto"
      >
        <PopoverHeader>
          <FormattedMessage id={attachPrefix('addConversationItem')} />
        </PopoverHeader>
        <PopoverBody>
          <div className="d-flex flex-column gap-2">
            {renderedItems}
            <div className="d-flex align-items-center gap-2">
              <Button onClick={toggle} color="danger" block>
                <FormattedMessage id="General.close" />
              </Button>
              <Button
                onClick={handleAddItem}
                disabled={addItemDisabled}
                color="primary"
                block
              >
                <FormattedMessage id="General.add" />
              </Button>
            </div>
          </div>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

export default AddConversationItem;
