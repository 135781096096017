import React from 'react';
import useDashboardTemplate from '../../../../../../../../../../../../../../api/queries/dashboards/useDashboardTemplate';
import { useOrganizationPathParams } from '../../../../../../../../../../../../../../hooks/paths/useOrganizationPathParams';
import { useInitQuery } from '../../../../../../../../../../../../../../hooks/queries/useInitQuery';
import {
  DashboardTemplate,
  IDashboardDashboardTemplateVisualization,
} from '../../../../../../../../../../../../../../interfaces/dashboards';
import QueryLoaded from '../../../../../../../../../../../../../QueryLoaded';
import FullScreenSpinner from '../../../../../../../../../../../../../Spinners/FullScreenSpinner';
import DataNotProvided from '../../components/DataNotProvided';

const shouldDashboardIdBeResolved = (dashboardId: string) =>
  dashboardId.includes('{{') || dashboardId.includes('}}');

type DashboardTemplateProviderProps = {
  context: any;
  visualization: IDashboardDashboardTemplateVisualization;
  children: (template: DashboardTemplate) => JSX.Element;
};

const DashboardTemplateProvider = ({
  context,
  visualization,
  children,
}: DashboardTemplateProviderProps) => {
  if (!shouldDashboardIdBeResolved(visualization.dashboard_id)) {
    return (
      <DashboardTemplateQueryProvider
        children={children}
        visualization={visualization}
      />
    );
  }

  if (context === undefined || !context.dashboardId) return <DataNotProvided />;

  return (
    <DashboardTemplateQueryProvider
      children={children}
      visualization={{ ...visualization, dashboard_id: context.dashboardId }}
    />
  );
};

const DashboardTemplateQueryProvider = ({
  visualization,
  children,
}: Omit<DashboardTemplateProviderProps, 'context'>) => {
  const { organizationId } = useOrganizationPathParams();

  const dashboardTemplate = useDashboardTemplate({
    params: { organizationId, templateId: visualization.dashboard_id },
    options: { staleTime: Infinity, enabled: false },
  });

  useInitQuery(dashboardTemplate);

  return (
    <QueryLoaded query={dashboardTemplate} onLoading={<FullScreenSpinner />}>
      {(template) => children(template)}
    </QueryLoaded>
  );
};

export default DashboardTemplateProvider;
