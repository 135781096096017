import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import TabContainer from 'components/Tabs/components/TabContainer';
import {
  changeTab,
  setSelectedTrendVisualizationTemplate,
} from 'modules/TimelineDetails/actions';
import { TimelineTabsValues } from 'interfaces/ui';
import { useDispatch } from 'react-redux';
import { mappedTimelinesValues } from 'interfaces/timeline';
import TimelineTabsFilters from 'modules/TimelineDetails/components/TimelineTabsFilters';

//tabs
import CaseDetailsTab from './CaseDetailsTab';
import DayByDayTab from 'modules/TimelineDetails/components/tabs/DayByDayTab';
import OverviewSummary from 'modules/TimelineDetails/components/tabs/OverviewSummary';
import TrendsSummary from 'modules/TimelineDetails/components/tabs/TrendsSummary';
import { ICaseDetails } from 'interfaces/cases';
import { SelectedTimelinesInfo } from '../index';
import {
  useTimelineDetailsSelectedTab,
  useTimelineDetailsTabsDisabled,
} from 'store/reducerHooks';
import { ITabContainerItem } from '../../../../../../../components/Tabs/types/Tabs';

type CaseTabContainerProps = {
  selectedTimelinesInfo: SelectedTimelinesInfo;
  caseDetails: ICaseDetails;
};

const CaseTabContainer = ({
  selectedTimelinesInfo,
  caseDetails,
}: CaseTabContainerProps) => {
  const dispatch = useDispatch();

  const tabsDisabled = useTimelineDetailsTabsDisabled();

  const currentTab = useTimelineDetailsSelectedTab();

  const tabItems: ITabContainerItem[] = [
    {
      header: <FormattedMessage id="TimelineDetails.tabs.dayByDay" />,

      component: selectedTimelinesInfo.selectedTimeline?.id ? (
        <DayByDayTab
          caseDedicatedTimelineId={selectedTimelinesInfo.dedicatedTimeline?.id}
          timelineId={selectedTimelinesInfo.selectedTimeline.id}
          formRedirectionState={undefined}
          annotationRedirectionState={undefined}
        />
      ) : null,
      shouldBeRendered: currentTab === TimelineTabsValues.DAY_BY_DAY,
    },
    {
      header: <FormattedMessage id="TimelineDetails.tabs.overviewSummary" />,
      component: selectedTimelinesInfo.selectedTimeline?.id ? (
        <OverviewSummary
          caseDedicatedTimelineId={selectedTimelinesInfo.dedicatedTimeline?.id}
          timelineId={selectedTimelinesInfo.selectedTimeline.id}
          formRedirectionState={undefined}
          annotationRedirectionState={undefined}
        />
      ) : null,
      shouldBeRendered: currentTab === TimelineTabsValues.OVERVIEW,
    },
    {
      header: <FormattedMessage id="TimelineDetails.tabs.trendsTimeline" />,
      component: selectedTimelinesInfo.selectedTimeline?.id ? (
        <TrendsSummary
          caseDedicatedTimelineId={selectedTimelinesInfo.dedicatedTimeline?.id}
          timelineId={selectedTimelinesInfo.selectedTimeline.id}
          formRedirectionState={undefined}
          annotationRedirectionState={undefined}
        />
      ) : null,
      shouldBeRendered: currentTab === TimelineTabsValues.TRENDS_TIMELINE,
    },
    {
      header: <FormattedMessage id="CaseCard.tabHeader" />,
      component: selectedTimelinesInfo.selectedTimeline?.id ? (
        <CaseDetailsTab selectedTimelinesInfo={selectedTimelinesInfo} />
      ) : null,
      shouldBeRendered: currentTab === TimelineTabsValues.CASE_DETAILS,
    },
  ];

  const onTabChange = (val: number) => {
    if (val <= mappedTimelinesValues.length) {
      const timelineTab = mappedTimelinesValues[val];

      if (timelineTab) dispatch(changeTab(timelineTab));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(changeTab(TimelineTabsValues.DAY_BY_DAY));
      dispatch(setSelectedTrendVisualizationTemplate(null));
    };
  }, [dispatch]);

  return (
    <>
      <TabContainer
        additionalContent={
          selectedTimelinesInfo.selectedTimeline?.id ? (
            <TimelineTabsFilters
              caseDedicatedTimelineId={
                selectedTimelinesInfo.dedicatedTimeline?.id
              }
              timelineId={selectedTimelinesInfo.selectedTimeline.id}
              formRedirectionState={undefined}
              annotationRedirectionState={undefined}
              fetchTimeDetails={true}
              caseData={caseDetails}
            />
          ) : undefined
        }
        items={tabItems}
        onTabChange={onTabChange}
        tabsDisabled={tabsDisabled}
      />
    </>
  );
};

export default CaseTabContainer;
