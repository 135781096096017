import Schedule from 'components/Schedule';
import { defaultHours } from 'helpers/utils/dates';
import { ICaseConversationSchedule } from 'interfaces/conversationSchedules';
import React, { useState } from 'react';
import useOpen from '../../../../../../../../../../../../../../hooks/useOpen';
import CaseConversationsScheduleEditTimeModal from '../../../../modals/CaseConversationsScheduleEditTimeModal';
import { useItems } from './hooks/useItems';

type DayScheduleProps = {
  selectedDate: string;
};

const DaySchedule = ({ selectedDate }: DayScheduleProps) => {
  const editTimeModal = useOpen();

  const [schedule, setSchedule] = useState<ICaseConversationSchedule | null>(
    null
  );

  const { items, isLoading } = useItems(
    new Date(selectedDate),
    setSchedule,
    editTimeModal.toggle
  );

  return (
    <>
      {editTimeModal.isOpen && schedule && (
        <CaseConversationsScheduleEditTimeModal
          isOpen={editTimeModal.isOpen}
          toggle={editTimeModal.toggle}
          schedule={schedule}
          selectedDate={selectedDate}
        />
      )}
      <Schedule
        isLoading={isLoading}
        hours={defaultHours}
        items={items}
        selectedDateString={selectedDate}
      />
    </>
  );
};

export default DaySchedule;
