import { groupByProperty } from '../../../helpers/utils/groupByProperty';
import { ChartsData } from '../types';
import {
  GroupedAverageSimpleQuestionRecord,
  GroupedSimpleQuestionRecord,
  SimpleQuestionRecord,
} from '../../../modules/CaseDashboard/components/CaseDashboardTabs/components/TrendsTab/components/TrendsTabItem/types/questions';
import { AnswerHistoryQuestion } from '../../../interfaces/change';

export const getSimpleQuestionRecords = (data: {
  questions: AnswerHistoryQuestion[];
  userId: string;
}) => {
  return data.questions.map((p) => ({
    id: p.question_id,
    answers: p.answer_history.map((q) => ({
      date: q.date,
      value: q.value,
    })),
  }));
};

export const normalizeAnswerDates = (records: SimpleQuestionRecord[]) => {
  return records.map((record) => ({
    id: record.id,
    answers: record.answers.map((answer) => {
      let answerDate = new Date(answer.date);
      answerDate.setHours(0, 0, 0, 0);

      return {
        ...answer,
        date: answerDate.toISOString(),
      };
    }),
  }));
};

export const groupAnswersByDate = (
  records: SimpleQuestionRecord[]
): GroupedSimpleQuestionRecord[] => {
  return records.map((record) => {
    const groupByDate = groupByProperty('date');
    return {
      id: record.id,
      answers: groupByDate(record.answers),
    };
  });
};

export const averageAnswersByDate = (
  records: GroupedSimpleQuestionRecord[]
): GroupedAverageSimpleQuestionRecord[] => {
  return records.map((record) => {
    let averagedAnswers: Record<string, number> = {};

    Object.entries(record.answers).forEach(([k, v]) => {
      averagedAnswers[k] =
        v.reduce((acc: number, curr: any) => acc + (curr.value ?? 0), 0) /
        v.length;
    });

    return {
      id: record.id,
      answers: averagedAnswers,
    };
  });
};

export const assignRecordsToChartsData = (
  data: ChartsData<string | number>[],
  records: GroupedAverageSimpleQuestionRecord[]
) => {
  records.forEach((record) => {
    Object.entries(record.answers).forEach((recordAnswer) => {
      const [date, value] = recordAnswer;

      const existingDataEntryIndex = data.findIndex((p) => p['name'] === date);

      if (existingDataEntryIndex === -1) {
        data.push({ name: date, [record.id]: value });
        return;
      }

      data[existingDataEntryIndex][record.id] = value;
    });
  });

  return data;
};
