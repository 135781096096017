import useScheduleAwareSessionData from '../../../../../../../../../api/mutations/awareSessions/useScheduleAwareSessionData';
import { useOrganizationPathParams } from '../../../../../../../../../hooks/paths/useOrganizationPathParams';
import { useCallback } from 'react';
import { IScheduleAwareSessionData } from '../../../../../../../../../interfaces/awareSessions';

export const useHandleSave = (refetch: () => void, toggle: () => void) => {
  const { organizationId } = useOrganizationPathParams();

  const handleSuccess = () => {
    refetch();
    toggle();
  };

  const { mutateAsync } = useScheduleAwareSessionData({
    params: { organizationId },
    options: { successFb: handleSuccess },
  });

  return useCallback(
    async (body: IScheduleAwareSessionData) => {
      await mutateAsync(body);
    },
    [mutateAsync]
  );
};
