import { ISqueezeChartData } from 'interfaces/timeline';
import React, { useCallback, useMemo } from 'react';
import { Bar } from 'recharts';
import HourByHourChartLabel from '../components/HourByHourChartLabel';

export const useHourByHourChartBars = (
  observations: ISqueezeChartData | undefined,
  formattedData: any[]
) => {
  const getColorBasedOnDataKey = useCallback(
    (dataKey: string) => {
      const series =
        observations?.series.filter((p) => p.mode === 'SqueezesQuantity') ?? [];

      let colorReturned = '#407DBB';

      series.forEach((entry) => {
        if (entry.name !== dataKey) return;

        colorReturned = entry.meaning_color ?? colorReturned;
      });

      return colorReturned;
    },
    [observations?.series]
  );

  return useMemo(() => {
    let renderedBars: JSX.Element[] = [];

    let dataKeys: string[] = [];

    formattedData.forEach((entry: any) => {
      const keys = Object.keys(entry).filter((p) => p !== 'name');

      for (const key of keys) {
        if (dataKeys.includes(key)) continue;

        if (keys.indexOf(key) === keys.length - 1) {
          renderedBars.push(
            <Bar
              key={key}
              dataKey={key}
              stackId="a"
              fill={getColorBasedOnDataKey(key)}
              isAnimationActive={false}
              label={<HourByHourChartLabel />}
            />
          );

          dataKeys.push(key);
          continue;
        }

        renderedBars.push(
          <Bar
            key={key}
            dataKey={key}
            stackId="a"
            fill={getColorBasedOnDataKey(key)}
            isAnimationActive={false}
          />
        );

        dataKeys.push(key);
      }
    });

    return renderedBars;
  }, [formattedData, getColorBasedOnDataKey]);
};
