import { useQuery } from 'react-query';
import { StrictDateRange } from '../../../interfaces/dates';
import { MemberQueryParams } from '../../../interfaces/queryParams';
import { IQueryRequest } from '../../../interfaces/request';
import { getMemberDashboardReportsWithReferencePeriod } from '../../dashboards';
import QueryKeys from '../queryKeys';

export type MemberDashboardReportsQueryParams = MemberQueryParams &
  StrictDateRange;

const useMemberDashboardReportsWithReferencePeriod = ({
  params,
  options,
}: IQueryRequest<MemberDashboardReportsQueryParams>) => {
  const { organizationId, memberId, from, to } = params;

  return useQuery({
    ...options,
    queryKey: [
      QueryKeys.getMemberDashboardReportsWithReferencePeriod,
      organizationId,
      memberId,
      from.toISOString(),
      to.toISOString(),
    ],
    queryFn: async () => {
      const { data } = await getMemberDashboardReportsWithReferencePeriod(
        organizationId,
        memberId,
        [
          {
            from: from.toISOString(),
            to: to.toISOString(),
          },
        ]
      );

      return data.data;
    },
  });
};

export default useMemberDashboardReportsWithReferencePeriod;
