import React from 'react';
import { UseQueryResult } from 'react-query';
import { Spinner } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

type QueryLoadedProps<TData, TError = unknown> = {
  query: UseQueryResult<TData, TError>;
  onLoading?: JSX.Element;
  onError?: (error: TError) => JSX.Element;
  children: (data: TData) => JSX.Element;
};

const QueryLoaded = <TData, TError>({
  query,
  onLoading,
  onError,
  children,
}: QueryLoadedProps<TData, TError>) => {
  const genericLoading = (
    <div className="d-flex w-100 h-100 align-items-center justify-content-center">
      <Spinner color="primary" />
    </div>
  );

  const genericError = <FormattedMessage id="QueryLoaded.Error" />;

  if (query.isLoading) return onLoading ?? genericLoading;

  if (query.isFetched && query.isError)
    return onError?.(query.error) ?? genericError;

  if (query.isFetched && query.isSuccess && query.data)
    return children(query.data);

  return <></>;
};

export default QueryLoaded;
