import React, { useMemo } from 'react';
import CustomTable from '../../../../components/CustomTable';
import ProjectsListItem from './components/ProjectListItem';
import { useInfiniteProjects } from '../../../../api/queries/projects/useInfiniteProjects';
import { Spinner } from 'reactstrap';
import InfiniteScroll from 'react-infinite-scroller';
import { useLocation } from 'react-router';
import { IProject } from '../../../../interfaces/submissions';
import useOpen from '../../../../hooks/useOpen';
import ProjectModal from '../../modals/ProjectModal';

const ProjectList = (props) => {
  const location = useLocation();

  const projectData = location.state as IProject | undefined;

  const { isOpen: internalOpen, toggle: internalToggle } = useOpen(
    !!projectData
  );

  const { projects, hasNextPage, isLoading, fetchNextPage, refetch } =
    useInfiniteProjects({
      params: {
        organizationId: props.organizationId,
        pageSize: 100,
        searchValue: props.searchValue,
      },
    });

  const projectRows = useMemo(() => {
    return projects?.map((project) => (
      <ProjectsListItem
        projectsRefetch={refetch}
        key={project.code}
        project={project}
        organizationId={props.organizationId}
      />
    ));
  }, [props.organizationId, projects, refetch]);

  const headerItems = [
    'Projects.list.code',
    'Projects.list.title',
    'Projects.list.description',
    'Projects.list.shortDescription',
    'Projects.list.links',
    'Projects.list.location',
    'Projects.list.caseCategoryCode',
    'Projects.list.caseSubCategoryCode',
    'Projects.list.dateFrom',
    'Projects.list.dateTo',
    null,
  ];

  const loadMore = () => fetchNextPage();

  return (
    <>
      {projectData && (
        <ProjectModal
          isOpen={internalOpen}
          toggle={internalToggle}
          project={projectData}
          projectRefetch={refetch}
        />
      )}
      {props.isModalOpen && (
        <ProjectModal
          isOpen={props.isModalOpen}
          toggle={props.modalToggle}
          projectRefetch={refetch}
        />
      )}
      <InfiniteScroll
        threshold={100}
        initialLoad={false}
        loader={<Spinner aria-label="loading" key="infinite-loader" />}
        hasMore={!isLoading && hasNextPage}
        loadMore={loadMore}
      >
        <CustomTable headerItems={headerItems} bodyRows={projectRows} />
      </InfiniteScroll>
    </>
  );
};

export default ProjectList;
