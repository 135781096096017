import { IQueryRequest } from '../../../interfaces/request';
import { UserCaseQueryParams } from '../../../interfaces/queryParams';
import { useQuery } from 'react-query';
import { getUserCaseObservationMeanings } from '../../observationMeanings';
import QueryKeys from '../queryKeys';

const useUserCaseObservationMeanings = ({
  params,
  options,
}: IQueryRequest<UserCaseQueryParams>) => {
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getUserCaseObservationMeanings(
        params.userId,
        params.caseId
      );

      return data.data;
    },
    queryKey: [
      QueryKeys.getUserCaseObservationMeanings,
      params.userId,
      params.caseId,
    ],
  });
};

export default useUserCaseObservationMeanings;
