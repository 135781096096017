import AddIcon from '@mui/icons-material/Add';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import useOrganizationUserAccessProfiles from 'api/queries/accessProfiles/useOrganizationUserAccessProfiles';
import useInfiniteUserInOrganizationsWithRoles from 'api/queries/organizations/useInfiniteUserInOrganizationsWithRoles';
import QueriesLoaded from 'components/QueriesLoaded';
import FunctionalityRouteGuard from 'components/routes/FunctionalityRouteGuard';
import { returnAllDataFromResponseData } from 'helpers/utils/reactQueryHelpers';
import { useOrganizationPathParams } from 'hooks/paths/useOrganizationPathParams';
import { useInitQueries } from 'hooks/queries/useInitQuery';
import useOpen from 'hooks/useOpen';
import { FunctionalityCode } from 'interfaces/functionalities';
import CreateOrganizationUserModal from 'modules/UsersAndAccess/components/OrganizationUsersTab/modals/CreateOrganizationUserModal';
import UserInvitationModal from 'modules/UsersAndAccess/components/OrganizationUsersTab/modals/UserInvitationModal';
import React from 'react';
import IconButton from '../../../../../../../../components/IconButton';
import TabLayout, {
  TabLayoutType,
} from '../../../../../../../../components/layouts/TabLayout';
import UsersTable from './tables/UsersTable';

const UsersTab = () => {
  const { organizationId } = useOrganizationPathParams();

  const invitationModal = useOpen();
  const createOrganizationUserModal = useOpen();

  const userInOrganizationsWithRoles = useInfiniteUserInOrganizationsWithRoles({
    params: { organizationId, pageSize: 1000 },
    options: { enabled: false, staleTime: Infinity },
  });

  const accessProfiles = useOrganizationUserAccessProfiles({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQueries([userInOrganizationsWithRoles, accessProfiles]);

  return (
    <TabLayout
      type={TabLayoutType.Breadcrumb}
      titlePrefix="Organizations.Users"
      actions={
        <div className="d-flex align-items-center gap-2">
          <IconButton
            color="primary"
            variant="outlined"
            onClick={createOrganizationUserModal.toggle}
          >
            <AddIcon />
          </IconButton>
          <FunctionalityRouteGuard
            code={FunctionalityCode.ORGANIZATIONS_INVITE_USERS}
          >
            <IconButton
              onClick={invitationModal.toggle}
              color="primary"
              variant="outlined"
            >
              <EmailOutlinedIcon />
            </IconButton>
          </FunctionalityRouteGuard>
        </div>
      }
    >
      <QueriesLoaded queries={[userInOrganizationsWithRoles, accessProfiles]}>
        {(data) => (
          <div className="mt-4 text-muted">
            <UsersTable
              usersInOrganization={returnAllDataFromResponseData(data[0])}
              accessProfiles={data[1]}
            />
            <UserInvitationModal
              isOpen={invitationModal.isOpen}
              toggle={invitationModal.toggle}
              accessProfiles={data[1]}
            />
            <CreateOrganizationUserModal
              isOpen={createOrganizationUserModal.isOpen}
              toggle={createOrganizationUserModal.toggle}
              refetch={userInOrganizationsWithRoles.refetch}
            />
          </div>
        )}
      </QueriesLoaded>
    </TabLayout>
  );
};

export default UsersTab;
