import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { BreadcrumbItemType } from '../../../../../../../components/PageHeader/Breadcrumbs';
import { useOrganizationPathParams } from '../../../../../../../hooks/paths/useOrganizationPathParams';
import { useTypedContext } from '../../../../../../../hooks/useTypedContext';
import { CaseDetailsContext } from '../../../types';

export const useBreadcrumbs = () => {
  const { organizationId } = useOrganizationPathParams();
  const intl = useIntl();

  const caseDetails = useTypedContext(CaseDetailsContext);

  return useMemo((): BreadcrumbItemType[] => {
    return [
      {
        key: 'cases',
        link: `/organizations/${organizationId}/cases`,
        text: intl.formatMessage({ id: 'Cases.Cases' }),
      },
      {
        key: 'case-name',
        link: null,
        text: caseDetails.title,
      },
    ];
  }, [caseDetails.title, intl, organizationId]);
};
