import React, { useState } from 'react';
import { BaseModalProps } from '../../../../../../interfaces/ui';
import useOpen from '../../../../../../hooks/useOpen';
import ImportTemplateModal from '../ImportTemplateModal';
import ConversationTemplateModal from '../ConversationTemplateModal';
import { ConversationTemplate } from '../../../../../../interfaces/conversations';

type ImportAndEditTemplateModalProps = BaseModalProps;

const ImportAndEditTemplateModal = ({
  isOpen,
  toggle,
}: ImportAndEditTemplateModalProps) => {
  const [templateToEdit, setTemplateToEdit] =
    useState<ConversationTemplate | null>(null);

  const editTemplateModal = useOpen(false);

  const onComplete = (template: ConversationTemplate) => {
    setTemplateToEdit(template);
    editTemplateModal.toggle();
    toggle();
  };

  return (
    <>
      {isOpen && (
        <ImportTemplateModal
          isOpen={isOpen}
          toggle={toggle}
          onComplete={onComplete}
        />
      )}
      {editTemplateModal.isOpen && templateToEdit && (
        <ConversationTemplateModal
          isOpen={editTemplateModal.isOpen}
          toggle={editTemplateModal.toggle}
          template={templateToEdit}
        />
      )}
    </>
  );
};

export default ImportAndEditTemplateModal;
