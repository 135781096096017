import { useQuery } from 'react-query';
import { StrictDateRange } from '../../../interfaces/dates';
import { OrganizationQueryParams } from '../../../interfaces/queryParams';
import { IQueryRequest } from '../../../interfaces/request';
import { getDashboardDataQuery } from '../../dashboards';
import QueryKeys from '../queryKeys';

type DashboardDataQueryParams = OrganizationQueryParams & {
  templateId: string;
  range: StrictDateRange;
};

const useDashboardDataQuery = ({
  params,
  options,
}: IQueryRequest<DashboardDataQueryParams>) => {
  const { organizationId, templateId, range } = params;

  return useQuery({
    ...options,
    queryKey: [
      QueryKeys.getDashboardDataQuery,
      organizationId,
      templateId,
      range.from.toISOString(),
      range.to.toISOString(),
    ],
    queryFn: async () => {
      const { data } = await getDashboardDataQuery(organizationId, templateId, [
        {
          from: range.from.toISOString(),
          to: range.to.toISOString(),
        },
      ]);
      return data.data;
    },
  });
};

export default useDashboardDataQuery;
