import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import { addSymptomToOrganization } from 'api/symptoms';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';

function useAddSymptomToOrganization(
  organizationId: string,
  successFb?: () => void
) {
  return useMutationWithResultNotification({
    asyncApiFunction: async (observationMeaningId: string) => {
      await addSymptomToOrganization(organizationId, observationMeaningId);
    },
    resetOnResult: true,
    onSuccess: () => {
      displaySuccessNotification(
        'ObservationMeaning.addObservationMeaningToOrganization.success'
      );
      if (successFb) successFb();
    },
    onErrorArray: (errors) => {
      displayErrorArrayNotifications(
        errors,
        'ObservationMeaning.addObservationMeaningToOrganization.errors'
      );
    },
  });
}

export default useAddSymptomToOrganization;
