import { useCallback } from 'react';
import { CreateProjectFormValues } from '../../../types/ProjectModal';
import { FormikErrors } from 'formik';
import { useIntl } from 'react-intl';

export const useProjectModalDetailsFormValidation = () => {
  const intl = useIntl();

  const validate = useCallback(
    (
      initialValues: CreateProjectFormValues,
      errors: FormikErrors<CreateProjectFormValues>
    ) => {
      if (initialValues.owner_personality_id.length === 0)
        errors['owner_personality_id'] = intl.formatMessage({
          id: 'Projects.ProjectModal.ProjectModalDetailsForm.invalidProjectOwner',
        });

      if (
        initialValues.date_from &&
        initialValues.date_to &&
        initialValues.date_from > initialValues.date_to
      ) {
        errors['date_from'] = intl.formatMessage({
          id: 'Projects.ProjectModal.ProjectModalDetailsForm.invalidDateRange',
        });
        errors['date_to'] = intl.formatMessage({
          id: 'Projects.ProjectModal.ProjectModalDetailsForm.invalidDateRange',
        });
      }

      if (initialValues.case_category_id.length === 0)
        errors['case_category_id'] = intl.formatMessage({
          id: 'Projects.ProjectModal.ProjectModalDetailsForm.invalidCaseCategory',
        });

      return errors;
    },
    [intl]
  );

  return {
    validate: validate,
  };
};
