import Button from '@mui/material/Button';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { BaseModalProps } from '../../../../../../interfaces/ui';
import TransferGraspTable from './tables/TransferGraspTable';

const TransferGraspModal = ({ isOpen, toggle }: BaseModalProps) => {
  return (
    <Modal
      size="lg"
      className="GenericModal BorderlessModal"
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>
        <FormattedMessage id="Grasps.TransferGraspToCurrentOrganization" />
      </ModalHeader>
      <ModalBody>
        <TransferGraspTable />
      </ModalBody>
      <ModalFooter>
        <Button variant="contained" color="primary" onClick={toggle}>
          <FormattedMessage id="General.Close" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TransferGraspModal;
