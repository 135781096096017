import { cloneDeep, isEmpty } from 'lodash';
import {
  ScheduleItemDefinition,
  ScheduleItemTemplateDefinition,
  ScheduleItemTemplatePartsOfADayDefinition,
} from '../../../../../../../../../../../../../../../components/Schedule';
import {
  itemOverlapping,
  mergeSingleInstanceWithRow,
  schedulesOverlapping,
} from '../../../../../../../../../../../../../../../helpers/utils/conversationSchedules';
import { ConversationScheduleType } from '../../../../../../../../../../../../../../../interfaces/conversationSchedules';
import {
  CaseNotificationSchedule,
  ISingleNotificationSchedule,
} from '../../../../../../../../../../../../../../../interfaces/notificationSchedules';
import { SingleScheduleMergingGroup } from '../types';
import { DayScheduleSchedulesHelpers } from './schedules';

export namespace DayScheduleMergingHelpers {
  export const MergeSingleInstances = (
    items: ScheduleItemDefinition[],
    schedules: CaseNotificationSchedule[]
  ) => {
    const mergingGroups = _getMergingGroups(items, schedules);

    const mergedItemsIds = mergingGroups
      .flatMap((p) => p.items)
      .map((p) => p.rowId);

    items = items.filter((p) => !mergedItemsIds.includes(p.rowId));

    mergingGroups.forEach((group) => {
      items.push(..._mergeSingleInstances(group));
    });

    return items;
  };

  const _getMergingGroups = (
    items: ScheduleItemDefinition[],
    schedules: CaseNotificationSchedule[]
  ) => {
    let groups: SingleScheduleMergingGroup[] = [];

    items.forEach((item) => {
      const schedule = schedules.find((p) => p.id === item.rowId);

      if (
        schedule?.schedule_type !== ConversationScheduleType.SingleInstance ||
        schedule.origin_id !== null
      )
        return;

      const notificationType = schedule.notification_type;
      const specificDayOfPhase =
        item.scheduleTemplate?.specific_day_of_phase ?? -1;

      const existingGroupIndex = groups.findIndex(
        (p) =>
          p.notification_type === notificationType &&
          p.specific_day_of_phase === specificDayOfPhase
      );

      if (existingGroupIndex !== -1) {
        groups[existingGroupIndex].items.push(item);
        return;
      }

      groups.push({
        notification_type: notificationType,
        specific_day_of_phase: specificDayOfPhase,
        items: [item],
      });
    });

    return groups;
  };

  const _mergeSingleInstances = (
    group: SingleScheduleMergingGroup
  ): ScheduleItemDefinition[] => {
    let items: ScheduleItemDefinition[] = [];
    let outliers: ScheduleItemDefinition[] = [...group.items];

    while (!isEmpty(outliers)) {
      const overlapping = _getOverlapping(outliers);
      const overlappingIds = overlapping.map((p) => p.rowId);
      const nonOverlapping = outliers.filter(
        (p) => !overlappingIds.includes(p.rowId)
      );

      items.push(_generateMergedItem(nonOverlapping));
      outliers = overlapping;
    }

    return items;
  };

  const _getOverlapping = (
    items: ScheduleItemDefinition[]
  ): ScheduleItemDefinition[] => {
    let overlapping: ScheduleItemDefinition[] = [];
    let itemsCopy = cloneDeep(items);

    itemsCopy.sort((a, b) =>
      _getNotificationHour(a).localeCompare(_getNotificationHour(b))
    );

    itemsCopy.forEach((item, index) => {
      const nextItem = itemsCopy.at(index + 1);

      if (!nextItem) return;

      const partOfADay = item.scheduleTemplate?.parts_of_a_day?.at(0);
      const nextPartOfADay = nextItem.scheduleTemplate?.parts_of_a_day?.at(0);

      if (!partOfADay || !nextPartOfADay) return;

      const itemsOverlapping = itemOverlapping(partOfADay, nextPartOfADay);

      if (!itemsOverlapping) return;

      overlapping.push(item);
    });

    return overlapping;
  };

  const _getNotificationHour = (item: ScheduleItemDefinition) => {
    return (
      item.scheduleTemplate?.parts_of_a_day?.at(0)?.notification_hour ?? '00:00'
    );
  };

  const _generateMergedItem = (
    items: ScheduleItemDefinition[]
  ): ScheduleItemDefinition => {
    if (isEmpty(items)) throw Error('Cannot generate empty merged row');

    let firstItem = items[0];
    let rest = items.filter((_, idx) => idx > 0);

    const partsOfADay = firstItem.scheduleTemplate?.parts_of_a_day;

    if (!partsOfADay) throw Error('Cannot generate empty merged row');

    const restOfParts = rest.map((p) =>
      p.scheduleTemplate?.parts_of_a_day.at(0)
    );

    const validParts = restOfParts.filter(
      (p) => !!p
    ) as ScheduleItemTemplatePartsOfADayDefinition[];

    partsOfADay.push(...validParts);

    (
      firstItem.scheduleTemplate as ScheduleItemTemplateDefinition
    ).parts_of_a_day = partsOfADay;

    return firstItem;
  };

  export const GetRowToMergeWith = (
    items: ScheduleItemDefinition[],
    current: CaseNotificationSchedule,
    recurring: CaseNotificationSchedule,
    handleEdit: (schedule: CaseNotificationSchedule) => void
  ) => {
    return _getRowToMergeWith(items, current, recurring, handleEdit);
  };

  export const MergeSingleInstanceWithRow = (
    items: ScheduleItemDefinition[],
    sequence: number,
    partOfADay: ScheduleItemTemplatePartsOfADayDefinition,
    disabled: boolean
  ): ScheduleItemDefinition[] => {
    return mergeSingleInstanceWithRow(items, sequence, partOfADay, disabled);
  };

  export const _getRowToMergeWith = (
    items: ScheduleItemDefinition[],
    current: CaseNotificationSchedule,
    recurring: CaseNotificationSchedule,
    handleEdit: (schedule: CaseNotificationSchedule) => void
  ) => {
    const singleInstanceSchedule = current as ISingleNotificationSchedule;

    const existingRows = items.filter(
      (p) => p.label === singleInstanceSchedule.notification_type
    );

    let _rowToMergeWith: ScheduleItemDefinition | null = null;

    for (const existingRow of existingRows) {
      const partsOverlapping = schedulesOverlapping(
        existingRow.scheduleTemplate?.parts_of_a_day ?? [],
        DayScheduleSchedulesHelpers.GetSingleInstancePartOfADay(
          current,
          handleEdit
        )
      );

      if (partsOverlapping) continue;

      if (_rowToMergeWith === null) {
        _rowToMergeWith = existingRow;
        continue;
      }

      const existingRowLength = (
        existingRow.scheduleTemplate?.parts_of_a_day ?? []
      ).length;
      const rowToMergeWithLength = (
        _rowToMergeWith.scheduleTemplate?.parts_of_a_day ?? []
      ).length;

      if (existingRowLength <= rowToMergeWithLength) continue;
      _rowToMergeWith = existingRow;
    }

    return _rowToMergeWith;
  };
}
