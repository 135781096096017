import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import TextField from '@mui/material/TextField';
import IconButton from 'components/IconButton';
import { FormikProps } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useCultures from '../../../../hooks/cultures/useCultures';
import { CultureEntry } from '../../../../interfaces/cultures';
import { isCultureOptionDisabled } from './helpers';
import { useHandleAdd } from './hooks/useHandleAdd';
import { useHandleChange } from './hooks/useHandleChange';
import { useHandleRemove } from './hooks/useHandleRemove';

type FieldCultureEntriesProps<T> = {
  props: FormikProps<T>;
  name: string;
  label: string;
};

const FieldCultureEntries = <T,>({
  name,
  props,
  label,
}: FieldCultureEntriesProps<T>) => {
  const { cultures } = useCultures();

  const cultureEntriesProps = props.getFieldProps(name);
  const cultureEntries = cultureEntriesProps.value as CultureEntry<string>[];

  const { changeName, changeCulture } = useHandleChange(props, name);
  const handleRemove = useHandleRemove(props, name);
  const handleAdd = useHandleAdd(props, name);

  return (
    <div className="d-flex flex-column gap-2">
      {cultureEntries.map((entry, index) => (
        <div className="d-flex gap-2">
          <TextField
            key={entry.culture}
            sx={{ width: 120 }}
            variant="outlined"
            select={true}
            id={`${name}[${index}].culture`}
            name={`${name}[${index}].culture`}
            label={<FormattedMessage id="General.Culture" />}
            value={entry.culture}
            onChange={changeCulture(index)}
            onBlur={props.handleBlur}
          >
            {cultures.map((culture) => (
              <MenuItem
                disabled={isCultureOptionDisabled(
                  cultureEntries,
                  culture.cultureCode
                )}
                key={culture.cultureCode}
                value={culture.cultureCode}
              >
                {culture.icon}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            variant="outlined"
            fullWidth
            id={`${name}[${index}].value`}
            name={`${name}[${index}].value`}
            label={label}
            value={entry.value}
            onChange={changeName(index)}
            onBlur={props.handleBlur}
          />
          <IconButton
            color="primary"
            variant="outlined"
            onClick={handleRemove(index)}
            sx={{ paddingLeft: '1rem', paddingRight: '1rem' }}
          >
            <DeleteIcon sx={{ fontSize: '1.5rem' }} />
          </IconButton>
        </div>
      ))}
      <Button
        disabled={cultureEntries.length === cultures.length}
        startIcon={<AddIcon />}
        onClick={handleAdd}
        variant="contained"
        color="primary"
      >
        <FormattedMessage id="General.AddCultureEntry" />
      </Button>
    </div>
  );
};

export default FieldCultureEntries;
