import React from 'react';
import { IUserOrganizationResponse } from '../../../../../../../../../../interfaces/membership';
import Pills from '../../../../../../../../../../components/Pills';
import { OrganizationAccessProfile } from '../../../../../../../../../../interfaces/accessProfiles';
import { useHandleAdd } from './hooks/useHandleAdd';
import { useHandleRemove } from './hooks/useHandleRemove';

type AccessProfilesSelectorProps = {
  row: IUserOrganizationResponse;
  accessProfiles: OrganizationAccessProfile[];
};

const AccessProfilesSelector = ({
  row,
  accessProfiles,
}: AccessProfilesSelectorProps) => {
  const accessProfilesItems = row.access_profiles.map((profile) => ({
    id: profile.access_profile_id,
    name: profile.name,
  }));

  const accessProfilesItemsIds = accessProfilesItems.map((p) => p.id);

  const options = accessProfiles.filter(
    (p) => !accessProfilesItemsIds.includes(p.id)
  );

  const { addAsync } = useHandleAdd();
  const { removeAsync } = useHandleRemove();

  const handleAdd = async (id: string) => {
    await addAsync({ userId: row.user.id, accessProfileId: id });
  };

  const handleDelete = async (id: string) => {
    await removeAsync({ userId: row.user.id, accessProfileId: id });
  };

  return (
    <div className="w-100">
      <Pills
        items={accessProfilesItems}
        outline={true}
        onAdd={handleAdd}
        onDelete={handleDelete}
        options={options}
        size="small"
      />
    </div>
  );
};

export default AccessProfilesSelector;
