import { IQueryRequest } from '../../../interfaces/request';
import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getExerciseSessions } from '../../exerciseSessions';
import { ExerciseSessionStatus } from '../../../interfaces/exerciseSessions';

type ExerciseSessionParams = {
  userId: string;
  from?: Date;
  to?: Date;
  status?: ExerciseSessionStatus;
};

const useExerciseSessions = ({
  params,
  options,
}: IQueryRequest<ExerciseSessionParams>) => {
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getExerciseSessions(
        params.userId,
        params.from,
        params.to,
        params.status
      );

      return data.data;
    },
    queryKey: [
      QueryKeys.getExerciseSessions,
      params.userId,
      params.from,
      params.to,
      params.status,
    ],
  });
};

export default useExerciseSessions;
