import React, { useCallback, useMemo, useState } from 'react';
import { ITabContainerItem } from '../../../components/Tabs/types/Tabs';
import { FormattedMessage } from 'react-intl';
import ConversationTemplatesTab from '../components/ConversationTemplates';
import FeedbackResourcesTab from '../components/FeedbackResources';
import { useCommonParams } from './useCommonParams';

export enum ConversationsAndFeedbacksTabsValues {
  ConversationTemplates = 'ConversationTemplates',
  FeedbackResources = 'FeedbackResources',
  EducationItems = 'EducationItems',
}

export const mappedConversationsAndFeedbacksTabsValues = [
  ConversationsAndFeedbacksTabsValues.ConversationTemplates,
  ConversationsAndFeedbacksTabsValues.FeedbackResources,
  ConversationsAndFeedbacksTabsValues.EducationItems,
];

export const useTabs = () => {
  const { organizationId } = useCommonParams();

  const [selectedTab, setSelectedTab] =
    useState<ConversationsAndFeedbacksTabsValues>(
      ConversationsAndFeedbacksTabsValues.ConversationTemplates
    );

  const shouldBeRendered = useCallback(
    (tab: ConversationsAndFeedbacksTabsValues) => {
      return selectedTab === tab;
    },
    [selectedTab]
  );

  const tabItems: ITabContainerItem[] = useMemo(() => {
    return [
      {
        header: (
          <FormattedMessage id="ConversationsAndFeedbacks.tabs.conversationTemplates" />
        ),
        component: <ConversationTemplatesTab />,
        shouldBeRendered: shouldBeRendered(
          ConversationsAndFeedbacksTabsValues.ConversationTemplates
        ),
      },
      {
        header: (
          <FormattedMessage id="ConversationsAndFeedbacks.tabs.feedbackResources" />
        ),
        component: <FeedbackResourcesTab organizationId={organizationId} />,
        shouldBeRendered: shouldBeRendered(
          ConversationsAndFeedbacksTabsValues.FeedbackResources
        ),
      },
    ];
  }, [organizationId, shouldBeRendered]);

  const onTabChange = (val: number) => {
    setSelectedTab(mappedConversationsAndFeedbacksTabsValues[val]);
  };

  return {
    onTabChange,
    tabItems,
  };
};
