import { userContextDeleteObservationsGroup } from 'api/observations';
import { IDeleteObservationsGroup } from 'interfaces/timeline';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

type useUpdateObservationsGroupProps = {
  userId: string;
  timelineId: string;
  successFb?: () => void;
};

function useUserContextDeleteObservationsGroup({
  userId,
  timelineId,
  successFb,
}: useUpdateObservationsGroupProps) {
  return useMutationWithResultNotification({
    asyncApiFunction: async (data: IDeleteObservationsGroup) => {
      await userContextDeleteObservationsGroup(userId, timelineId, data);
    },
    resetOnResult: true,
    onSuccess: () => {
      displaySuccessNotification(
        'TimelineDetails.ManageObservationGroupModal.success'
      );
      successFb && successFb();
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'TimelineDetails.ManageObservationGroupModal.error'
      );
    },
  });
}

export default useUserContextDeleteObservationsGroup;
