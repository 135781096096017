import { FormikProps } from 'formik';
import { cloneDeep } from 'lodash';
import { ChangeEvent, useCallback } from 'react';
import { CultureEntry } from '../../../../../interfaces/cultures';

export const useHandleChange = <T>(props: FormikProps<T>, name: string) => {
  const changeName = useCallback(
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const currentValue = props.getFieldProps(name)
        .value as CultureEntry<string>[];

      let currentCopy = cloneDeep(currentValue);

      if (currentCopy.at(index))
        currentCopy[index].value = event.target.value as unknown as string;

      props.setFieldValue(name, currentCopy);
    },
    [name, props]
  );

  const changeCulture = useCallback(
    (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
      const currentValue = props.getFieldProps(name)
        .value as CultureEntry<string>[];
      let currentCopy = cloneDeep(currentValue);

      if (currentCopy.at(index))
        currentCopy[index].culture = event.target.value as unknown as string;

      props.setFieldValue(name, currentCopy);
    },
    [name, props]
  );

  return { changeName, changeCulture };
};
