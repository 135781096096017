import {
  faFacebookSquare,
  faLinkedin,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'reactstrap';

const MobileFooter = () => {
  return (
    <Row className="pt-4 g-0">
      <Col sm={4} xs={4}>
        <p className="Footer__company-container__company">Grasp AS ©</p>
      </Col>
      <Col sm={4} xs={4}>
        <ul className="Footer__link_list">
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              className="Footer__link Footer__link--mobile"
              href="https://grasp.global/privacy"
            >
              <FormattedMessage id="Footer.aboutPrivacy" />
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              className="Footer__link Footer__link--mobile"
              href="https://grasp.global/privacy-policy"
            >
              <FormattedMessage id="Footer.privacyPolicy" />
            </a>
          </li>
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              className="Footer__link Footer__link--mobile"
              href="https://grasp.global/terms-and-conditions"
            >
              <FormattedMessage id="Footer.termsAndConditions" />
            </a>
          </li>
        </ul>
      </Col>
      <Col
        sm={4}
        xs={4}
        className="order-last order-md-last d-flex justify-content-center align-items-center"
      >
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.linkedin.com/company/grasp-as"
          className="Footer__social-link me-4"
        >
          <FontAwesomeIcon size="2x" icon={faLinkedin} />
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.facebook.com/GraspAS/"
          className="Footer__social-link "
        >
          <FontAwesomeIcon size="2x" icon={faFacebookSquare} />
        </a>
      </Col>
    </Row>
  );
};

export default MobileFooter;
