import { useMemo } from 'react';
import { getCaseMasterTimeline } from '../../../../../../../../../helpers/timelines';
import useTimelineObservationsForPeriod from '../../../../../../../../../api/queries/timelines/useTimelineObservationsForPeriod';
import { useInitQuery } from '../../../../../../../../../hooks/queries/useInitQuery';
import { ICaseDetails } from '../../../../../../../../../interfaces/cases';
import { IAwareSessionData } from '../../../../../../../../../interfaces/awareSessions';

export const useObservations = (
  caseDetails: ICaseDetails,
  awareSession: IAwareSessionData
) => {
  const masterTimelineId = useMemo(() => {
    return getCaseMasterTimeline(caseDetails);
  }, [caseDetails]);

  const dateFrom = useMemo(() => {
    const undefinedStartDate = caseDetails?.start_date ?? undefined;
    const startDate = awareSession?.start_date ?? undefinedStartDate;

    return startDate ? new Date(startDate) : new Date();
  }, [awareSession?.start_date, caseDetails?.start_date]);

  const dateTo = useMemo(() => {
    const undefinedEndDate = caseDetails?.end_date ?? undefined;
    const endDate = awareSession?.end_date ?? undefinedEndDate;

    return endDate ? new Date(endDate) : new Date();
  }, [awareSession?.end_date, caseDetails?.end_date]);

  const observations = useTimelineObservationsForPeriod({
    params: {
      timelineId: masterTimelineId ?? '',
      from: dateFrom,
      to: dateTo,
    },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(observations, masterTimelineId !== null);

  return observations;
};
