import React from 'react';
import { DropzoneOptions, useDropzone } from 'react-dropzone';
import {
  DEFAULT_DROP_FILE_OPTIONS,
  DEFAULT_DROP_FILE_TEXT_TRANSLATION,
  DEFAULT_REJECTED_DROP_FILE_TEXT_TRANSLATION,
  DEFAULT_UPLOADED_DROP_FILE_TEXT_TRANSLATION,
  DEFAULT_UPLOADING_DROP_FILE_TEXT_TRANSLATION,
  DropFileAcceptCallback,
  DropFileCallback,
  DropFileRejectCallback,
} from './types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faFileImport,
  faTimes,
} from '@fortawesome/pro-light-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { useHandleDrop } from './hooks/useHandleDrop';
import { Spinner } from 'reactstrap';

type DropFileProps = {
  options?: DropzoneOptions;
  className?: string;
  rootClassName?: string;
  inputClassName?: string;
  icon?: IconProp;
  translationId?: string;
  uploadingTranslationId?: string;
  rejectedTranslationId?: string;
  uploadedTranslationId?: string;
  onAccept?: DropFileAcceptCallback;
  onReject?: DropFileRejectCallback;
  onDrop?: DropFileCallback;
};

const DropFile = ({
  onAccept,
  onReject,
  onDrop,
  options = DEFAULT_DROP_FILE_OPTIONS,
  className = undefined,
  rootClassName = undefined,
  inputClassName = undefined,
  icon = faFileImport,
  translationId = DEFAULT_DROP_FILE_TEXT_TRANSLATION,
  uploadingTranslationId = DEFAULT_UPLOADING_DROP_FILE_TEXT_TRANSLATION,
  rejectedTranslationId = DEFAULT_REJECTED_DROP_FILE_TEXT_TRANSLATION,
  uploadedTranslationId = DEFAULT_UPLOADED_DROP_FILE_TEXT_TRANSLATION,
}: DropFileProps) => {
  const { handleDrop, handleAccept, handleReject, isUploading } = useHandleDrop(
    onDrop,
    onAccept,
    onReject
  );

  const { getInputProps, getRootProps, acceptedFiles, fileRejections } =
    useDropzone({
      ...options,
      useFsAccessApi: false,
      onDropAccepted: handleAccept,
      onDropRejected: handleReject,
      onDrop: handleDrop,
    });

  const isUploaded = acceptedFiles.length > 0 && !isUploading;
  const isRejected = fileRejections.length > 0 && !isUploading;
  const readyToUpload = !isUploaded && !isUploading;

  return (
    <div
      className={classNames('DropFile', {
        [`${className}`]: className,
      })}
    >
      <div
        className={classNames('DropFile__root', {
          [`${rootClassName}`]: rootClassName,
        })}
        {...getRootProps()}
      >
        <input
          className={classNames('DropFile__input', {
            [`${inputClassName}`]: inputClassName,
          })}
          {...getInputProps()}
        />
        <div className="DropFile__content">
          <div className="DropFile__icon">
            <FontAwesomeIcon icon={icon} size="2x" />
          </div>
          <div className="DropFile__status">
            {isUploading && (
              <>
                <Spinner size="sm" />
                <FormattedMessage tagName="span" id={uploadingTranslationId} />
              </>
            )}
            {isUploaded && (
              <>
                <FontAwesomeIcon icon={faCheck} />
                <FormattedMessage tagName="span" id={uploadedTranslationId} />
              </>
            )}
            {isRejected && (
              <>
                <FontAwesomeIcon icon={faTimes} />
                <FormattedMessage tagName="span" id={rejectedTranslationId} />
              </>
            )}
            {readyToUpload && (
              <FormattedMessage tagName="span" id={translationId} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropFile;
