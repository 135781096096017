import { DecisionDefinition } from '../../../../../../../components/DecisionModal';
import { useIntl } from 'react-intl';
import React, { useCallback, useState } from 'react';
import EditableInput from '../../../../../../../components/EditableInput/EditableInput';
import InputFieldWithoutImage from '../../../../../../../components/InputFieldWithoutImage';
import useAddTreatmentPlan from '../../../../../../../api/mutations/conversations/useAddTreatmentPlan';
import useRequiredParams from '../../../../../../../hooks/useRequiredParams';
import { useTreatmentPlansCommonData } from '../../../../../hooks/useTreatmentPlansCommonData';

export const useAddTreatmentPlanModal = (toggle: () => void) => {
  const intl = useIntl();

  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const { treatmentPlans, caseCategories } = useTreatmentPlansCommonData();

  const handleSuccess = async () => {
    await treatmentPlans.refetch();
    await caseCategories.refetch();
    toggle();
  };

  const { mutateAsync: addAsync } = useAddTreatmentPlan(
    organizationId,
    handleSuccess
  );

  const [planName, setPlanName] = useState<string>('');

  const handleEditName = useCallback((target: string, value: string) => {
    setPlanName(value);
  }, []);

  const modalMessage = (
    <InputFieldWithoutImage
      gray={true}
      noMargin={true}
      labelTranslationId="TreatmentPlans.TreatmentPlansList.CloneTreatmentPlanModal.name"
      inputId="name"
    >
      <EditableInput
        type="text"
        value={planName}
        onEdit={handleEditName}
        name="name"
        debounceEnabled={false}
      />
    </InputFieldWithoutImage>
  );

  const handleAddTreatmentPlan = async () => {
    if (planName === '') return;
    await addAsync({ name: planName });
  };

  const decisions: DecisionDefinition[] = [
    {
      handle: toggle,
      outline: true,
      text: intl.formatMessage({
        id: 'TreatmentPlans.TreatmentPlansList.AddTreatmentPlanModal.cancel',
      }),
    },
    {
      handle: handleAddTreatmentPlan,
      outline: false,
      text: intl.formatMessage({
        id: 'TreatmentPlans.TreatmentPlansList.AddTreatmentPlanModal.addPlan',
      }),
    },
  ];

  return {
    decisions: decisions,
    modalMessage: modalMessage,
  };
};
