import * as React from 'react';
import { useEffect, useRef } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

type VideoPlayerProps = {
  options: videojs.PlayerOptions;
};

const initialOptions: videojs.PlayerOptions = {
  controls: true,
  fluid: true,
  controlBar: {
    volumePanel: {
      inline: false,
    },
  },
};

const VideoPlayer = ({ options }: VideoPlayerProps) => {
  const videoNode = useRef<HTMLVideoElement>(null);
  const player = useRef<videojs.Player>(null);

  useEffect(() => {
    player.current = videojs(videoNode.current, {
      ...initialOptions,
      ...options,
    });

    return () => {
      if (!player.current) return;
      player.current.dispose();
    };
  }, [options]);

  return <video ref={videoNode} className="video-js vjs-big-play-centered" />;
};

export default VideoPlayer;
