import { useLocation, useParams } from 'react-router';

const useBasePath = () => {
  const location = useLocation();
  const params = useParams<Record<string, string>>();

  return Object.entries(params).reduce(
    (path, [paramKey, paramVal]) =>
      path?.replace(`/${paramVal}`, `/:${paramKey}`),
    location.pathname
  );
};

export default useBasePath;
