import { IMutationProps } from '../../../interfaces/ui';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { IModifyOrganizationObservationMeaning } from '../../../interfaces/observationMeanings';
import { patchOrganizationObservationMeaning } from '../../observationMeanings';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { IErrorDefinition } from '../../../interfaces/response';

type useModifyObservationMeaningParams = {
  organizationId: string;
};

export const useModifyObservationMeaning = (
  params: useModifyObservationMeaningParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (body: IModifyOrganizationObservationMeaning) => {
      await patchOrganizationObservationMeaning(
        params.organizationId,
        body.meaning_id,
        body.document
      );
    },
    onSuccess: () => {
      if (options.successFb) options.successFb();

      displaySuccessNotification(
        'Api.Mutations.ObservationMeanings.ModifyObservationMeaning.success'
      );
    },
    onErrorArray: (errors: IErrorDefinition[]) => {
      if (options.errorFb) options.errorFb();

      displayErrorArrayNotifications(
        errors,
        'Api.Mutations.ObservationMeanings.ModifyObservationMeaning.failure'
      );
    },
    resetOnResult: true,
  });
};
