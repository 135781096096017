import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useQuestionTitle } from '../../../../../../../../../../../hooks/data/useQuestionTitle';
import { useTypedContext } from '../../../../../../../../../../../hooks/useTypedContext';
import {
  DashboardTemplateSeriesAggregate,
  DashboardTemplateSeriesDataSource,
} from '../../../../../../../../../../../interfaces/dashboards';
import { DashboardFormatHelpers } from '../../../../../helpers/format';
import { DashboardQueriesContext } from '../../../../../types';

const _isValueTranslatable = (value: string) => {
  return (value ?? '').includes('|');
};

const _splitLegend = (value: string) => {
  let split = (value ?? '').split('|');
  split = split.map((item) => item.replace(' ', ''));
  split = split.filter((p) => p !== '');

  return split;
};

export const useTranslateLegend = () => {
  const intl = useIntl();

  const getQuestionTitle = useQuestionTitle();

  const dashboardQueries = useTypedContext(DashboardQueriesContext);

  const _tryResolveAsDataSource = useCallback(
    (element: string) => {
      if (!Object.keys(DashboardTemplateSeriesDataSource).includes(element))
        return undefined;

      return intl.formatMessage({
        id: `Enums.DashboardTemplateSeriesDataSource.${element}`,
      });
    },
    [intl]
  );

  const _tryResolveAsAggregation = useCallback(
    (element: string) => {
      if (!Object.keys(DashboardTemplateSeriesAggregate).includes(element))
        return undefined;

      return intl.formatMessage({
        id: `Enums.DashboardTemplateSeriesAggregate.${element}`,
      });
    },
    [intl]
  );

  const _tryResolveAsDataProperty = useCallback(
    (element: string) => {
      const existingGrasp = dashboardQueries.grasps.find(
        (p) => p.id === element
      );

      if (existingGrasp)
        return DashboardFormatHelpers.FormatGrasp(existingGrasp);

      const existingMember = dashboardQueries.members.find(
        (p) => p.id === element
      );

      if (existingMember)
        return DashboardFormatHelpers.FormatOrganizationMember(existingMember);

      const existingOrganization = dashboardQueries.organizations.find(
        (p) => p.id === element
      );

      if (existingOrganization)
        return DashboardFormatHelpers.FormatOrganization(existingOrganization);

      const existingQuestion = dashboardQueries.questions.find(
        (p) => p.resource_id === element
      );

      if (existingQuestion) return getQuestionTitle(existingQuestion);

      return undefined;
    },
    [
      dashboardQueries.grasps,
      dashboardQueries.members,
      dashboardQueries.organizations,
      dashboardQueries.questions,
      getQuestionTitle,
    ]
  );

  const getLegendTranslation = useCallback(
    (value: string) => {
      if (!_isValueTranslatable(value)) return value;

      let splitLegend = _splitLegend(value);

      if (isEmpty(splitLegend)) return value;

      let legendText: string = '';

      splitLegend.forEach((element) => {
        const asDataSource = _tryResolveAsDataSource(element);

        if (asDataSource) {
          legendText += `${asDataSource} `;
          return;
        }

        const asAggregation = _tryResolveAsAggregation(element);

        if (asAggregation) {
          legendText += `${asAggregation} `;
          return;
        }

        const asDataProperty = _tryResolveAsDataProperty(element);

        if (!asDataProperty) {
          legendText += `${element} `;
          return;
        }

        legendText += `${asDataProperty} `;
      });

      return legendText;
    },
    [
      _tryResolveAsAggregation,
      _tryResolveAsDataProperty,
      _tryResolveAsDataSource,
    ]
  );

  return { getLegendTranslation };
};
