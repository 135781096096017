import React from 'react';
import useAwareSessionDataMapping from '../../../../../../../../api/queries/awareSessions/useAwareSessionDataMapping';
import { useOrganizationPathParams } from '../../../../../../../../hooks/paths/useOrganizationPathParams';
import useUrlSearch from '../../../../../../../../hooks/useUrlSearch';
import { AwareSessionHelpers } from '../../../../../../../../helpers/data/awareSessions';
import { ConversationQuestion } from '../../../../../../../../interfaces/conversations';
import useConversationQuestions from '../../../../../../../../api/queries/conversations/useConversationQuestions';
import QueryLoaded from '../../../../../../../../components/QueryLoaded';
import { useInitQuery } from '../../../../../../../../hooks/queries/useInitQuery';

type ConversationQuestionsProviderProps = {
  children: (questions: ConversationQuestion[]) => JSX.Element;
};

const ConversationQuestionsProvider = ({
  children,
}: ConversationQuestionsProviderProps) => {
  const query = useUrlSearch();
  const aggregated = query.get('aggregated');

  if (aggregated) return <AggregatedProvider>{children}</AggregatedProvider>;
  return <NonAggregatedProvider>{children}</NonAggregatedProvider>;
};

const AggregatedProvider = ({
  children,
}: ConversationQuestionsProviderProps) => {
  const { organizationId } = useOrganizationPathParams();

  const awareSessionDataMapping = useAwareSessionDataMapping({
    params: {
      organizationId,
    },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(awareSessionDataMapping);

  return (
    <QueryLoaded query={awareSessionDataMapping}>
      {(data) =>
        children(
          AwareSessionHelpers.GetAggregatedQuestions(data, organizationId)
        )
      }
    </QueryLoaded>
  );
};

const NonAggregatedProvider = ({
  children,
}: ConversationQuestionsProviderProps) => {
  const { organizationId } = useOrganizationPathParams();

  const conversationQuestions = useConversationQuestions({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(conversationQuestions);

  return <QueryLoaded query={conversationQuestions}>{children}</QueryLoaded>;
};

export default ConversationQuestionsProvider;
