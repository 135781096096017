import { format } from 'date-fns';
import { strToDateOnly } from '../../../../../../../../../../../../../../../helpers/dates';
import { getCurrentCasePhaseHistoryItems } from '../../../../../../../../../../../../../../../helpers/utils/casePhaseHistory';
import { getDayOffset } from '../../../../../../../../../../../../../../../helpers/utils/dates';
import { ICasePhaseHistory } from '../../../../../../../../../../../../../../../interfaces/cases';
import {
  CaseNotificationSchedule,
  IRecurringNotificationSchedule,
  ISingleNotificationSchedule,
} from '../../../../../../../../../../../../../../../interfaces/notificationSchedules';

export namespace DaySchedulesFormatHelpers {
  export const NormalizeUtc = (singleInstance: ISingleNotificationSchedule) => {
    return singleInstance.utc_timezone_time.slice(
      0,
      singleInstance.utc_timezone_time.indexOf('+')
    );
  };

  export const FormatHourString = (isoDate: string) => {
    return format(new Date(isoDate), 'HH:mm');
  };

  export const FormatRecurredDayOffsets = (
    casePhaseHistory: ICasePhaseHistory[],
    dateStr: string,
    recurring: CaseNotificationSchedule,
    utcDateTime: string
  ) => {
    let startOffset = 1;
    let endOffset = 1;

    const recurringSchedule = recurring as IRecurringNotificationSchedule;

    const historyFiltered = getCurrentCasePhaseHistoryItems(
      casePhaseHistory,
      dateStr
    );

    switch (historyFiltered.length) {
      case 0:
        break;
      case 1:
        startOffset = getDayOffset(
          strToDateOnly(historyFiltered[0].date_from),
          recurringSchedule.utc_timezone_from
        );
        endOffset = recurringSchedule.utc_timezone_to
          ? getDayOffset(
              strToDateOnly(historyFiltered[0].date_from),
              recurringSchedule.utc_timezone_to
            )
          : 1;
        break;
      default:
        for (const item of historyFiltered) {
          const normalizedUtc = utcDateTime.slice(0, utcDateTime.indexOf('+'));

          if (
            (!item.date_to && normalizedUtc >= item.date_from) ||
            (item.date_to &&
              normalizedUtc >= item.date_from &&
              normalizedUtc <= item.date_from)
          ) {
            startOffset = getDayOffset(item.date_from, dateStr);
            endOffset = item.date_to ? getDayOffset(item.date_to, dateStr) : 1;
            break;
          }
        }
    }

    return {
      startOffset,
      endOffset,
    };
  };
}
