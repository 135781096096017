import React, { ChangeEvent } from 'react';
import classNames from 'classnames';
import { FormGroup, Input } from 'reactstrap';

//images
import UrgentIcon from 'assets/images/access/urgent.png';
import SelectedUrgentIcon from 'assets/images/access/urgent_selected.png';
import RequestIcon from 'assets/images/access/request.png';
import SelectedRequestIcon from 'assets/images/access/request_selected.png';
import { FormattedMessage } from 'react-intl';
import { PossibleAccessType } from '.';
import { AccessType } from 'interfaces/enums';

type AccessRequestTypeSelectProps = {
  selectedAccessType: PossibleAccessType;
  setAccessType: (val: PossibleAccessType) => void;
  reason: string;
  setReason: (newReason: string) => void;
};

const AccessRequestTypeSelect = ({
  selectedAccessType,
  setAccessType,
  reason,
  setReason,
}: AccessRequestTypeSelectProps) => {
  const isUrgentSelected = selectedAccessType === AccessType.EMERGENCY_ACCESS;
  const isRequestSelected = selectedAccessType === AccessType.REQUEST;

  const onUrgentClick = () => {
    setAccessType(AccessType.EMERGENCY_ACCESS);
  };

  const onRequestClick = () => {
    setAccessType(AccessType.REQUEST);
  };

  const onReasonChange = (e: ChangeEvent<HTMLInputElement>) => {
    setReason(e.target.value);
  };

  return (
    <div>
      <div className="d-flex w-100 justify-content-center">
        <div
          className="RequestAccessModal__option text-center"
          onClick={onUrgentClick}
        >
          <div
            className={classNames(
              'RequestAccessModal__option__image-container w-100',
              {
                'RequestAccessModal__option__image-container--urgent-selected':
                  isUrgentSelected,
              }
            )}
          >
            <img
              className="RequestAccessModal__option__image"
              src={isUrgentSelected ? SelectedUrgentIcon : UrgentIcon}
              alt="urgent_icon"
            />
          </div>

          <div>
            <FormattedMessage id="PatientAccess.urgentAccess" />
          </div>
        </div>
        <div
          className="RequestAccessModal__option  text-center align-self-stretch"
          onClick={onRequestClick}
        >
          <div
            className={classNames(
              'RequestAccessModal__option__image-container w-100',
              {
                'RequestAccessModal__option__image-container--request-selected':
                  isRequestSelected,
              }
            )}
          >
            <img
              className="RequestAccessModal__option__image"
              src={isRequestSelected ? SelectedRequestIcon : RequestIcon}
              alt="request_icon"
            />
          </div>
          <div>
            {' '}
            <FormattedMessage id="PatientAccess.request" />
          </div>
        </div>
      </div>
      <FormGroup className="RequestAccessModal__reason">
        <label htmlFor="reason-input">
          <FormattedMessage id="PatientAccess.reason" />*
        </label>
        <Input id="reason-input" value={reason} onChange={onReasonChange} />
      </FormGroup>
    </div>
  );
};

export default AccessRequestTypeSelect;
