import React from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartBar,
  faPlus,
  faSadTear,
} from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage } from 'react-intl';
import { useCaseGroupDetailsCommonData } from '../../../hooks/useCaseGroupDetailsCommonData';

type EmptyGroupFeedbackProps = {
  addGroupFeedback: () => void;
  setNoAvailableQuestionsMode: (mode: boolean) => void;
  refetch: () => void;
  noAvailableQuestionsMode: boolean;
};

const EmptyGroupFeedback = ({
  addGroupFeedback,
  setNoAvailableQuestionsMode,
  noAvailableQuestionsMode,
}: EmptyGroupFeedbackProps) => {
  const { groupFeedback } = useCaseGroupDetailsCommonData();

  const handleRefetchAndTryAgain = async () => {
    await groupFeedback.refetch();
    setNoAvailableQuestionsMode(false);
  };

  if (noAvailableQuestionsMode) {
    return (
      <div className="EmptyGroupFeedback">
        <FontAwesomeIcon icon={faSadTear} size="5x" />
        <span className="EmptyGroupFeedback__text EmptyGroupFeedback__text--small d-flex flex-column align-items-center">
          <FormattedMessage
            tagName="span"
            id="Cases.CaseGroupDetails.thereIsNoGroupFeedbackWithQuestions"
          />
          <FormattedMessage
            tagName="span"
            id="Cases.CaseGroupDetails.thereIsNoGroupFeedbackWithQuestionsTwo"
          />
        </span>
        <Button
          outline={true}
          className="px-3 py-2 d-flex gap-1 align-items-center"
          color="primary"
          onClick={handleRefetchAndTryAgain}
        >
          <FontAwesomeIcon icon={faPlus} />
          <FormattedMessage id="Cases.CaseGroupDetails.tryAgain" />
        </Button>
      </div>
    );
  }

  return (
    <div className="EmptyGroupFeedback">
      <FontAwesomeIcon icon={faChartBar} size="5x" />
      <span className="EmptyGroupFeedback__text">
        <FormattedMessage id="Cases.CaseGroupDetails.emptyGroupFeedback" />
      </span>
      <Button
        outline={true}
        className="px-3 py-2 d-flex gap-1 align-items-center"
        color="primary"
        onClick={addGroupFeedback}
      >
        <FontAwesomeIcon icon={faPlus} />
        <FormattedMessage id="Cases.CaseGroupDetails.addFeedbackItem" />
      </Button>
    </div>
  );
};

export default EmptyGroupFeedback;
