import { getOrganizationDetails } from 'api/membership';
import QueryKeys from 'api/queries/queryKeys';
import { OrganizationQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { useQuery } from 'react-query';

const useOrganizationDetails = ({
  params,
  options,
}: IQueryRequest<OrganizationQueryParams>) => {
  const { organizationId } = params;
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getOrganizationDetails(organizationId);
      return data.data;
    },
    queryKey: [QueryKeys.getOrganizationDetails, organizationId],
  });
};

export default useOrganizationDetails;
