import request from 'api/utils/request';
import { getOdataFilterParams } from 'helpers/utils/getOdataParams';
import { normalizeQueryParams } from 'helpers/utils/normalizeQueryParams';
import {
  AssessmentQuestion,
  IUserAssessments,
} from 'interfaces/assessmentForms';
import { PointComparison } from 'interfaces/enums';
import { IParamsTypeInRequest } from 'interfaces/reducer';
import { IPatchObject, ODataParamType } from 'interfaces/request';
import { IResponse, IResponseWithPagination } from 'interfaces/response';
import {
  IOrganizationUserSubmission,
  IProject,
  IProjectSubmission,
  IProjectSubmissionFilters,
  ISendMessageBody,
  SubmissionResponse,
} from 'interfaces/submissions';
import { FormConversationAnswer } from '../interfaces/conversations';

export const getSubmissionForms = (userId: string, submissionId: string) => {
  return request<undefined, IResponse<IUserAssessments[]>>({
    url: `users/${userId}/SUBMISSIONS/${submissionId}/assessment_forms`,
    method: 'GET',
  });
};

export const getProjectByCodes = (
  organizationCode: string,
  projectCode: string
) => {
  return request<undefined, IResponse<IProject>>({
    url: `organizations/${organizationCode}/projects/${projectCode}`,
    method: 'GET',
  });
};

export const getProjectSubmission = (
  organizationId: string,
  submissionId: string
) => {
  return request<undefined, IResponse<IProjectSubmission>>({
    url: `organizations/${organizationId}/project_submissions/${submissionId}`,
    method: 'GET',
  });
};

export const getProjectsWithPaging = async (
  organizationId: string,
  reduxParams: Partial<IParamsTypeInRequest>
) => {
  return request<undefined, IResponseWithPagination<IProject[]>>({
    url: `/organizations/${organizationId}/projects${normalizeQueryParams(
      reduxParams
    )}`,
    method: 'GET',
  });
};

export const getOrganizationSubmissionsWithPaging = async (
  organizationId: string,
  reduxParams: Partial<IParamsTypeInRequest>,
  filters: IProjectSubmissionFilters | null
) => {
  let statusFilterParams: ODataParamType[] = [];
  if (filters?.statuses && filters?.statuses.length > 0) {
    for (const singleStatus of filters?.statuses) {
      statusFilterParams.push({
        key: 'Status',
        operator: 'eq',
        value: `'${singleStatus}'`,
      });
    }
  }

  const otherFilters: ODataParamType[] = [];
  if (filters?.pointsFilter) {
    switch (filters.pointsFilter.compare) {
      case PointComparison.ABOVE:
        otherFilters.push({
          key: 'Score',
          operator: 'gt',
          value: filters.pointsFilter.value.toString(),
        });
        break;
      case PointComparison.BELOW:
        otherFilters.push({
          key: 'Score',
          operator: 'lt',
          value: filters.pointsFilter.value.toString(),
        });
        break;
      case PointComparison.BETWEEN:
        if (filters.pointsFilter.maxValue) {
          otherFilters.push({
            key: 'Score',
            operator: 'gt',
            value: filters.pointsFilter.value.toString(),
          });
          otherFilters.push({
            key: 'Score',
            operator: 'lt',
            value: filters.pointsFilter.maxValue.toString(),
          });
        }
        break;
    }
  }

  if (filters?.completed !== undefined) {
    otherFilters.push({
      key: 'Completed',
      operator: 'eq',
      value: filters.completed.toString(),
    });
  }

  const oDataStatusString =
    statusFilterParams.length > 0
      ? getOdataFilterParams(statusFilterParams, {
          startCharacter: '&',
          logicalOperation: 'or',
          wrapInBracket: true,
        })
      : '';

  const isNotFirstFilter = statusFilterParams.length > 0;

  const oDatataOtherFilterString = getOdataFilterParams(otherFilters, {
    startCharacter: isNotFirstFilter ? ' and ' : '&',
    logicalOperation: 'and',
    isNotFirstFilter,
  });

  return request<
    undefined,
    IResponseWithPagination<IOrganizationUserSubmission[]>
  >({
    url: `/organizations/${organizationId}/project_submissions${normalizeQueryParams(
      reduxParams
    )}${oDataStatusString}${oDatataOtherFilterString}`,
    method: 'GET',
  });
};

export const sendMessageToProjectParticipant = (
  organizationId: string,
  data: ISendMessageBody<IProjectSubmissionFilters | null>
) =>
  request<ISendMessageBody<IProjectSubmissionFilters | null>, void>({
    url: `/organizations/${organizationId}/project_submissions/send_message`,
    method: 'POST',
    data,
  });

export const patchSubmission = (
  organizationId: string,
  submissionId: string,
  patchData: IPatchObject[]
) => {
  return request({
    url: `organizations/${organizationId}/project_submissions/${submissionId}`,
    method: 'PATCH',
    data: patchData,
  });
};

export const sendProjectFormConversation = (
  organizationCode: string,
  projectCode: string,
  templateId: string,
  answer: FormConversationAnswer
) => {
  return request<FormConversationAnswer, IResponse<SubmissionResponse>>({
    url: `organizations/${organizationCode}/projects/${projectCode}/conversation_templates/${templateId}/submissions`,
    method: 'POST',
    data: answer,
  });
};

export const sendProjectSubmissionForm = (
  projectCode: string,
  organizationCode: string,
  formCode: string,
  questions: AssessmentQuestion[]
) => {
  return request<
    {
      questions: AssessmentQuestion[];
    },
    IResponse<SubmissionResponse>
  >({
    url: `organizations/${organizationCode}/projects/${projectCode}/submissions/forms/${formCode}`,
    method: 'POST',
    data: {
      questions,
    },
  });
};

export const registerSubmissionUser = (
  projectCode: string,
  organizationCode: string,
  submissionId: string,
  formData: any
) => {
  if (submissionId && submissionId !== '') {
    return request({
      url: `projects/${projectCode}/submissions/${submissionId}/user`,
      method: 'POST',
      data: formData,
    });
  }

  return request({
    url: `organizations/${organizationCode}/projects/${projectCode}/submissions`,
    method: 'POST',
    data: formData,
  });
};
