import { FormikProps } from 'formik';
import { SignUpFormValues } from './useForm';
import { useCallback } from 'react';

export const useHandleEmailChange = () => {
  return useCallback(
    (form: FormikProps<SignUpFormValues>) => (e: object) => {
      const newValue = e['target']['value'];
      form.setFieldValue('email', newValue);
      if (form.touched.userName) return;
      form.setFieldValue('userName', newValue);
    },
    []
  );
};
