import { acceptShareRequest } from 'api/patients';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

type useTimelineShareRequestAcceptProps = {
  userId: string;
  shareRequestId: string;
  successFb?: () => void;
  errorFb?: () => void;
};

function useTimelineShareRequestAccept({
  userId,
  shareRequestId,
  errorFb,
  successFb,
}: useTimelineShareRequestAcceptProps) {
  return useMutationWithResultNotification({
    asyncApiFunction: async (timelines: string[]) => {
      await acceptShareRequest(userId, shareRequestId, timelines);
    },
    resetOnResult: true,
    onError: errorFb,
    onSuccess: successFb,
  });
}

export default useTimelineShareRequestAccept;
