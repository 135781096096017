import { useMemo } from 'react';
import { useIntl } from 'react-intl';

export type TabTitleContent = {
  header: string;
  subHeader: string;
};

export const useTabTitle = (prefix?: string) => {
  const intl = useIntl();

  return useMemo((): TabTitleContent => {
    if (!prefix) return { header: '', subHeader: '' };

    return {
      header: intl.formatMessage({ id: `${prefix}TabHeader` }),
      subHeader: intl.formatMessage({ id: `${prefix}TabSubHeader` }),
    };
  }, [intl, prefix]);
};
