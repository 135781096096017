import { useCallback, useEffect, useMemo, useState } from 'react';
import { ITabContainerItem } from '../types/Tabs';

type useTabContainerParams<TItem extends ITabContainerItem> = {
  onTabChange?: (index: number) => void;
  items: TItem[];
};

export function useTabContainer<T extends ITabContainerItem>({
  onTabChange,
  items,
}: useTabContainerParams<T>) {
  const [activeTab, setActiveTab] = useState<number>(0);

  const toggle = useCallback(
    (tab: number) => {
      if (activeTab !== tab) {
        setActiveTab(tab);
        if (onTabChange) onTabChange(tab);
      }
    },
    [activeTab, onTabChange]
  );

  const defaultSelectedItems: T[] = useMemo(() => {
    return items.map((item) =>
      item.shouldBeRendered === undefined
        ? {
            ...item,
            shouldBeRendered: true,
          }
        : item
    );
  }, [items]);

  useEffect(() => {
    const shouldBeRenderedTabItemIndex = items.findIndex(
      (p) => p.shouldBeRendered
    );

    if (shouldBeRenderedTabItemIndex === -1) return;

    setActiveTab(shouldBeRenderedTabItemIndex);
  }, [items]);

  return {
    activeTab: activeTab,
    toggle: toggle,
    defaultSelectedItems: defaultSelectedItems,
  };
}
