import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { ListGroup, ListGroupItem } from 'reactstrap';
import Dashboard from '../../../../../../components/Dashboard/components/Dashboard';
import {
  generateRoute,
  RouteDefinitions,
} from '../../../../../../helpers/routeDefinitions';
import { useGetCultureEntry } from '../../../../../../hooks/cultures/useGetCultureEntry';
import { useOrganizationPathParams } from '../../../../../../hooks/paths/useOrganizationPathParams';
import { DashboardTemplate } from '../../../../../../interfaces/dashboards';
import { useBrowserState } from '../../../../../../store/reducerHooks';

type DashboardSelectionProps = {
  templates: DashboardTemplate[];
};

const DashboardSelection = ({ templates }: DashboardSelectionProps) => {
  const { organizationId } = useOrganizationPathParams();
  const isBrowser = useBrowserState();
  const navigate = useNavigate();

  const getCultureValue = useGetCultureEntry();

  if (templates.length === 0) return <></>;

  if (!isBrowser)
    return (
      <Dashboard
        template={templates.sort((a, b) => a.sequence - b.sequence)[0]}
      />
    );

  const handleRedirect = (template: DashboardTemplate) => () => {
    const templateRoute = generateRoute(template.path as RouteDefinitions, {
      organizationId: organizationId,
      dashboardId: template.id,
    });

    navigate(templateRoute);
  };

  return (
    <div className="d-flex flex-column gap-4 align-items-center justify-content-center mt-5">
      <span className="text-muted fs-4">
        <FormattedMessage id="Dashboards.DashboardsList.DashboardSelection.select" />
      </span>
      <ListGroup>
        {templates.map((template) => (
          <ListGroupItem
            className="text-primary cursor-pointer p-3"
            onClick={handleRedirect(template)}
          >
            {getCultureValue(template.name)}
          </ListGroupItem>
        ))}
      </ListGroup>
    </div>
  );
};

export default DashboardSelection;
