import React, { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/pro-solid-svg-icons';

type ItemAvatarProps = {
  url?: string;
};

const ItemAvatar = ({ url }: ItemAvatarProps) => {
  return (
    <div className="bg-white rounded-circle w-px-30 h-px-30 d-flex align-items-center justify-content-center shadow-sm">
      {url ? (
        <img src={url} className="img-fluid" alt="avatar" />
      ) : (
        <FontAwesomeIcon icon={faUser} />
      )}
    </div>
  );
};

export default memo(ItemAvatar);
