import React, { useRef } from 'react';
import {
  ChartConfigContext,
  ChartDataContext,
  ChartDownloadContext,
  ChartVisualizationContext,
  TrendsChartConfig,
  TrendsChartData,
  TrendsChartFormatter,
} from './types';
import TrendsChartHeader from './components/TrendsChartHeader';
import TrendsChartActions from './components/TrendsChartActions';
import { useTrendsChartVisualization } from './hooks/useTrendsChartVisualization';
import { useSelectedData } from './hooks/useSelectedData';
import NoDataSource from './placeholders/NoDataSource';
import TrendsChartFigure from './components/TrendsChartFigure';
import { useTrendsChartDownload } from './hooks/useTrendsChartDownload';
import { TemplateElement } from '../../interfaces/change';

type TrendsChartProps = {
  elements: TemplateElement[];
  config: TrendsChartConfig;
  data: TrendsChartData<string | number>[];
  formatters?: TrendsChartFormatter[];
};

const TrendsChart = ({
  elements,
  config,
  data,
  formatters = [],
}: TrendsChartProps) => {
  const divRef = useRef<HTMLDivElement | null>(null);
  const chartDownload = useTrendsChartDownload();

  const chartVisualization = useTrendsChartVisualization(elements);

  const selectedData = useSelectedData(data, chartVisualization.selected);

  if (!selectedData) return <NoDataSource />;

  return (
    <div
      className="TrendsChart"
      style={{
        position: 'relative',
      }}
      ref={divRef}
    >
      <ChartDownloadContext.Provider value={chartDownload}>
        <ChartVisualizationContext.Provider value={chartVisualization}>
          <ChartDataContext.Provider value={selectedData}>
            <ChartConfigContext.Provider value={config}>
              <div className="d-flex flex-column gap-2">
                <div className="d-flex w-100 mb-4 justify-content-between">
                  <TrendsChartHeader
                    type={chartVisualization.selected.type}
                    config={config.header}
                  />
                  <TrendsChartActions config={config.actions} />
                </div>
                <div>
                  <TrendsChartFigure
                    formatters={formatters}
                    xAxisConfig={config.axis?.find((p) => p.axis === 'x-axis')}
                    yAxisConfig={config.axis?.find((p) => p.axis === 'y-axis')}
                  />
                </div>
              </div>
            </ChartConfigContext.Provider>
          </ChartDataContext.Provider>
        </ChartVisualizationContext.Provider>
      </ChartDownloadContext.Provider>
    </div>
  );
};

export default TrendsChart;
