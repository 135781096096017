import { deleteLocationInOrganization } from 'api/membership';
import { IMutationProps } from 'interfaces/ui';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

function useDeleteLocationInOrganization(
  organizationId: string,
  { successFb }: IMutationProps
) {
  return useMutationWithResultNotification({
    asyncApiFunction: async (endpointId: string) => {
      await deleteLocationInOrganization(organizationId, endpointId);
    },
    resetOnResult: true,
    onSuccess: () => {
      displaySuccessNotification('MyOrganizations.endpoints.deleteSuccess');
      successFb && successFb();
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'MyOrganizations.endpoints.deleteErrors'
      );
    },
  });
}

export default useDeleteLocationInOrganization;
