import { useCallback } from 'react';
import { useOrganizationCasePathParams } from '../../../../../../../../../hooks/paths/useOrganizationCasePathParams';
import { IUpdateAwareSessionData } from '../../../../../../../../../interfaces/awareSessions';
import { IUpdateCaseProcedureHistory } from '../../../../../../../../../interfaces/caseHistory';
import { useCommonData } from '../../../hooks/useCommonData';
import useUrlSearch from '../../../../../../../../../hooks/useUrlSearch';
import useUpdateAwareCase from '../../../../../../../../../api/mutations/cases/useUpdateAwareCase';
import { OpenHookValues } from '../../../../../../../../../hooks/useOpen';

export const useHandleSave = (editMode: OpenHookValues) => {
  const { organizationId, caseId } = useOrganizationCasePathParams();
  const query = useUrlSearch();
  const aggregated = query.get('aggregated');

  const { awareSessionData, aggregatedAwareSessionData, caseProcedureHistory } =
    useCommonData();

  const handleRefetch = async () => {
    await caseProcedureHistory.refetch();
    if (aggregated) {
      await aggregatedAwareSessionData.refetch();
      editMode.toggle();
      return;
    }

    await awareSessionData.refetch();
    editMode.toggle();
  };

  const { mutateAsync: updateAwareCase } = useUpdateAwareCase({
    params: { organizationId, caseId },
    options: { successFb: handleRefetch, errorFb: handleRefetch },
  });

  return useCallback(
    async (
      awareSessionData: IUpdateAwareSessionData,
      procedures: IUpdateCaseProcedureHistory
    ) => {
      await updateAwareCase({
        session_data: awareSessionData,
        procedures_data: procedures,
      });
    },
    [updateAwareCase]
  );
};
