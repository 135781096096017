import classNames from 'classnames';
import React from 'react';
import UncontrolledFilterInput from '../../../../../../../../../../components/Filters/UncontrolledFilterInput';
import { useTypedContext } from '../../../../../../../../../../hooks/useTypedContext';
import { DashboardFilter as DashboardFilterType } from '../../../../../../../../../../interfaces/dashboards';
import { SequenceIdentified } from '../../../../../../../../../../interfaces/ui';
import { DashboardDataReducerContext, DashboardTheme } from '../../../../types';
import FilterBody from './components/FilterBody';
import AwareCasesFilterDisplay from './components/FilterDisplay';
import { useFilterDisplayInfo } from './hooks/useFilterDisplayInfo';

type DashboardFilterProps = {
  filter: SequenceIdentified<DashboardFilterType>;
};

const DashboardFilter = ({ filter }: DashboardFilterProps) => {
  const { filterString } = useFilterDisplayInfo(filter);
  const [dashboardData] = useTypedContext(DashboardDataReducerContext);

  if (!filter.visible) return <></>;

  return (
    <div className="AwareCasesFilter">
      <UncontrolledFilterInput
        targetId={`aware-cases-filter-input-target-${filter.sequence}`}
        resetFilters={null}
        filterBody={<FilterBody filter={filter} />}
        displayComponent={
          <AwareCasesFilterDisplay
            filter={filter}
            filterString={filterString ?? ''}
            targetId={`aware-cases-filter-input-target-${filter.sequence}`}
          />
        }
        popoverClassName={classNames('AwareCasesFilterPopover', {
          'AwareCasesFilterPopover--dark':
            dashboardData.theme === DashboardTheme.Dark,
        })}
        popoverBodyClassName="AwareCasesFilterPopover__body"
        onAcceptClick={null}
      />
    </div>
  );
};

export default DashboardFilter;
