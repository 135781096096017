import { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { ConversationScheduleType } from '../../../../../../../../../../../../../../../../../interfaces/conversationSchedules';
import { IValidationSchemaBasedFormHookValues } from '../../../../../../../../../../../../../../../../../interfaces/forms';
import {
  CaseNotificationSchedule,
  CaseNotificationScheduleType,
} from '../../../../../../../../../../../../../../../../../interfaces/notificationSchedules';
import { FormEditMode, FormValues } from '../types';
import { useHandleDates } from './useHandleDates';
import { useHandleUpdate } from './useHandleUpdate';

export const useForm = (
  selectedDate: string,
  schedule: CaseNotificationSchedule,
  toggle: () => void
): IValidationSchemaBasedFormHookValues<FormValues> => {
  const { getNthDayOfPhase, getNotificationTime, getDate } = useHandleDates();

  const handleUpdate = useHandleUpdate(selectedDate, schedule, toggle);

  const initialValues = useMemo((): FormValues => {
    return {
      notification_type: schedule.notification_type,
      editing_mode:
        schedule.schedule_type === ConversationScheduleType.Recurring
          ? FormEditMode.AllEvents
          : FormEditMode.SingleEvent,
      specific_day_of_phase:
        schedule.schedule_type !== ConversationScheduleType.SingleInstance
          ? undefined
          : getNthDayOfPhase(schedule.utc_timezone_time),
      start_day_offset:
        schedule.schedule_type !== ConversationScheduleType.Recurring
          ? undefined
          : getNthDayOfPhase(schedule.utc_timezone_from),
      end_day_offset:
        schedule.schedule_type !== ConversationScheduleType.Recurring
          ? undefined
          : getNthDayOfPhase(schedule.utc_timezone_to),
      recurring_interval:
        schedule.schedule_type !== ConversationScheduleType.Recurring
          ? undefined
          : schedule.recurring_interval,
      notification_hour:
        schedule.schedule_type === ConversationScheduleType.SingleInstance
          ? getNotificationTime(schedule.utc_timezone_time)
          : getNotificationTime(schedule.utc_timezone_from),
    };
  }, [getNotificationTime, getNthDayOfPhase, schedule]);

  const handleSubmit = useCallback(
    async (values: typeof initialValues) => {
      const payload = {
        notification_type: values.notification_type,
        recurring_interval: values.recurring_interval,
        date: values.specific_day_of_phase
          ? getDate(values.specific_day_of_phase, values.notification_hour)
          : undefined,
        date_from: values.start_day_offset
          ? getDate(values.start_day_offset, values.notification_hour)
          : undefined,
        date_to: values.end_day_offset
          ? getDate(values.end_day_offset, values.notification_hour)
          : undefined,
      };

      await handleUpdate(payload);
    },
    [getDate, handleUpdate]
  );

  const validationSchema = Yup.object<FormValues>().shape({
    notification_type: Yup.mixed<CaseNotificationScheduleType>()
      .oneOf(Object.values(CaseNotificationScheduleType))
      .required(),
    editing_mode: Yup.mixed<FormEditMode>()
      .oneOf(Object.values(FormEditMode))
      .required(),
    start_day_offset: Yup.number().when('editing_mode', {
      is: FormEditMode.AllEvents,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    }),
    end_day_offset: Yup.number().when('editing_mode', {
      is: FormEditMode.AllEvents,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    }),
    recurring_interval: Yup.number().when('editing_mode', {
      is: FormEditMode.AllEvents,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    }),
    specific_day_of_phase: Yup.number().when('editing_mode', {
      is: FormEditMode.SingleEvent,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    }),
    notification_hour: Yup.string().required(),
  });

  return {
    initialValues,
    handleSubmit,
    validationSchema,
  };
};
