import { useMemo, useState } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import notepadIcon from 'assets/images/notepad.svg';
import QuestionModalForm from './QuestionModalForm';
import useMobile from 'hooks/useBreakpoint';
import { formatDate } from 'helpers/dates';
import {
  AssessmentCompletionStatus,
  AssessmentFormIdentification,
  AssessmentQuestion,
  IUserAssessments,
} from 'interfaces/assessmentForms';
import { createPortal } from 'react-dom';
import LoadingOverlay from 'components/LoadingOverlay';
import { AssessmentFormValues } from 'interfaces/ui';
import {
  createFormObjectToSent,
  getAssessmentFormBasedOnIdentification,
} from 'helpers/utils/assessmentForms';
import { getUserId } from 'helpers/utils/auth';
import useUserContextGroupedAssessmentFormsForDate from 'api/queries/assessmentForms/forms/date/useUserContextGroupedAssessmentFormsForDate';
import useOrganizationContextGroupedAssessmentFormsForDate from 'api/queries/assessmentForms/forms/date/useOrganizationContextGroupedAssessmentFormsForDate';
import useUserPathBasedBrowsingContext from 'hooks/useUserPathBasedBrowsingContext';
import useOrganizationContextUpdateAssessmentForm from 'api/mutations/assessmentForms/forms/useOrganizationContextUpdateAssessmentForm';
import useUserContextUpdateAssessmentForm from 'api/mutations/assessmentForms/forms/useUserContextUpdateAssessmentForm';

export type QuestionModalProps = {
  selectedDate: Date;
  userId: string;
  timelineId: string;
  assessmentFormIdentification: AssessmentFormIdentification | null;
  closeModal: () => void;
  ableToSave?: boolean;
  refetchCallback?: () => void;
  enabled?: boolean;
  forceEdit?: boolean;
};

const QuestionModal = ({
  userId,
  timelineId,
  selectedDate,
  assessmentFormIdentification,
  closeModal,
  refetchCallback,
  enabled = false,
  forceEdit = false,
}: QuestionModalProps) => {
  const {
    organizationId = '',
    caseId = '',
    isOrganizationContext,
  } = useUserPathBasedBrowsingContext();

  const isMobile = useMobile();

  const isOpen =
    assessmentFormIdentification !== null &&
    assessmentFormIdentification.selectedSequence !== null;

  const loggedUserId = getUserId();

  const userContextGroupedFormsQuery =
    useUserContextGroupedAssessmentFormsForDate({
      params: { userId, timelineId, selectedDate },
      options: { enabled: !!userId && enabled },
    });

  const organizationContextGroupedFormsQuery =
    useOrganizationContextGroupedAssessmentFormsForDate({
      params: { organizationId, caseId, timelineId, selectedDate },
      options: { enabled: !!organizationId && !!caseId && enabled },
    });

  const groupedFormsQuery = isOrganizationContext
    ? organizationContextGroupedFormsQuery
    : userContextGroupedFormsQuery;

  const allForms = useMemo(() => {
    let allForms: IUserAssessments[] = [];

    if (groupedFormsQuery.data) {
      for (const data of groupedFormsQuery.data) {
        allForms.push(data);
      }
    }

    return allForms;
  }, [groupedFormsQuery.data]);

  const userAssessments = getAssessmentFormBasedOnIdentification(
    allForms,
    assessmentFormIdentification
  );

  const [isFormSubmitted, setFormSubmitted] = useState(false);

  const refetchOnSuccess = async () => {
    setFormSubmitted(true);
    await groupedFormsQuery.refetch();

    if (refetchCallback) refetchCallback();
  };

  const organizationContextUpdateAssessmentFormMutation =
    useOrganizationContextUpdateAssessmentForm(
      {
        organizationId: organizationId ?? '',
        caseId: caseId ?? '',
        timelineId: userAssessments?.contextObjectId ?? '',
        date: selectedDate,
        sequence: assessmentFormIdentification?.selectedSequence ?? 0,
      },
      {
        successFb: refetchOnSuccess,
      }
    );

  const userContextUpdateAssessmentFormMutation =
    useUserContextUpdateAssessmentForm(
      {
        userId: userId,
        timelineId: userAssessments?.contextObjectId ?? '',
        date: selectedDate,
        sequence: assessmentFormIdentification?.selectedSequence ?? 0,
      },
      {
        successFb: refetchOnSuccess,
      }
    );

  const updateAssessmentFormMutation = isOrganizationContext
    ? organizationContextUpdateAssessmentFormMutation
    : userContextUpdateAssessmentFormMutation;

  const onSubmit = (values: AssessmentFormValues) => {
    if (!!userAssessments && !!values) {
      const questions = userAssessments.questions;
      const assessmentBody = createFormObjectToSent(
        values,
        questions,
        userAssessments
      ) as AssessmentQuestion[];

      updateAssessmentFormMutation.mutate({
        code: userAssessments.code,
        title: userAssessments.title,
        assessmentBody,
      });
    }
  };

  const onModalCloseWithSubmitInfo = () => {
    setFormSubmitted(false);
    closeModal();
  };

  const canEdit = userAssessments
    ? loggedUserId === userAssessments.userId ||
      userAssessments.isEditableByOrganizationMembers ||
      (userAssessments.completionStatus !==
        AssessmentCompletionStatus.COMPLETED &&
        forceEdit)
    : false;

  if (
    updateAssessmentFormMutation.isLoading &&
    assessmentFormIdentification?.selectedSequence
  ) {
    const node = document.getElementById('loading-overlay');

    if (node) return createPortal(<LoadingOverlay />, node);
  }

  if (!userAssessments) return null;
  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      toggle={closeModal}
      className="QuestionModal__modal"
    >
      <ModalHeader className="w-100" toggle={closeModal}>
        <div className="d-flex w-100 align-items-center">
          <div className="d-flex w-100 align-items-center">
            <div>
              <img src={notepadIcon} alt="observation_icon" className="me-4" />
            </div>
            <span>Assessment Forms</span>
            <div className="DayDetails__date DayDetails__date--black">
              {formatDate(selectedDate?.toISOString(), {
                ignoreTime: true,
              })}{' '}
              {userAssessments.hour}
            </div>
          </div>
        </div>
      </ModalHeader>
      <QuestionModalForm
        canEdit={canEdit}
        onSubmit={onSubmit}
        isMobile={isMobile}
        closeModal={onModalCloseWithSubmitInfo}
        selectedForm={userAssessments}
        isLoading={updateAssessmentFormMutation.isLoading}
        isFormSubmitted={isFormSubmitted}
      />
    </Modal>
  );
};

export default QuestionModal;
