import UseOrganizationAssessmentFormsTemplates from 'api/queries/assessmentForms/templates/useOrganizationAssessmentFormTemplates';
import useRequiredParams from 'hooks/useRequiredParams';
import AssessmentFormFill from 'modules/TimelineDetails/components/tabs/DayByDayTab/components/NonAssignmentAssessmentFormModal/AssessmentFormFill';
import { Modal, ModalHeader } from 'reactstrap';
import notepadIcon from 'assets/images/notepad.svg';
import { FormattedMessage } from 'react-intl';
import { formatDate } from 'helpers/dates';

export type AssessmentBaseProps = {
  dateObject: Date;
  time: string;
  selectedTemplate: string;
};

interface FillOtherFormModalProps extends AssessmentBaseProps {
  closeModal: () => void;
  timelineId: string;
  userId: string;
}

const FillOtherFormModal = (props: FillOtherFormModalProps) => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const { data: templatesData } = UseOrganizationAssessmentFormsTemplates({
    params: {
      organizationId,
      allowedForCaseId: null,
      questionsIncludedInTrends: false,
    },
    options: { enabled: false },
  });

  return (
    <Modal
      size="xl"
      className="QuestionModal__modal"
      isOpen={true}
      toggle={props.closeModal}
    >
      <ModalHeader className="w-100" toggle={props.closeModal}>
        <div className="d-flex w-100 align-items-center">
          <div className="d-flex w-100 align-items-center">
            <div>
              <img src={notepadIcon} alt="observation_icon" className="me-4" />
            </div>
            <span>
              <FormattedMessage id="General.assessmentForms" />
            </span>
            <div className="DayDetails__date DayDetails__date--black">
              {formatDate(props.dateObject?.toISOString(), {
                ignoreTime: true,
              })}{' '}
              {props.time}
            </div>
          </div>
        </div>
      </ModalHeader>
      {templatesData && (
        <AssessmentFormFill templates={templatesData} {...props} />
      )}
    </Modal>
  );
};

export default FillOtherFormModal;
