import {
  ConversationQuestion,
  DelimiterValidConversationTemplateItem,
  EducationValidConversationTemplateItem,
  FeedbackValidConversationTemplateItem,
  GraspUploadValidConversationTemplateItem,
  MaterialsListValidConversationTemplateItem,
  NotificationsListValidConversationTemplateItem,
  PlainTextValidConversationTemplateItem,
  QuestionValidConversationTemplateItem,
  RangeValidConversationQuestion,
  ValidConversationQuestionType,
  ValidConversationTemplateItemType,
} from '../../../../../../../../../../../interfaces/conversations';

import { v4 as uuid } from 'uuid';
import { ValueInterpretationType } from '../../../../../../../../../../../interfaces/change';
import { getValidConversationQuestion } from '../../../../../../../../../../../helpers/data/conversations';

export const getEmptyPlainTextItem =
  (): PlainTextValidConversationTemplateItem => {
    return {
      type: ValidConversationTemplateItemType.PlainText,
      condition: null,
      visible: true,
      apply_typing_effect: true,
      value: {
        text: '',
      },
    };
  };

export const getEmptyQuestionItem =
  (): QuestionValidConversationTemplateItem => {
    const resourceId = uuid();

    const rangeQuestion: RangeValidConversationQuestion = {
      guid: resourceId,
      type: ValidConversationQuestionType.Range,
      title: '',
      short_title: '',
      answer: {
        date_value: null,
        number_value: null,
      },
      value_interpretation: ValueInterpretationType.NoInterpretation,
      show_description: true,
      show_title: true,
      color: '#000',
      sequence: 0,
      included_in_trends: true,
      required: true,
      legend_descriptions: [],
    };

    return {
      type: ValidConversationTemplateItemType.Question,
      apply_typing_effect: true,
      visible: true,
      condition: null,
      resource_id: resourceId,
      value: rangeQuestion,
    };
  };

export const getEmptyGraspUploadItem =
  (): GraspUploadValidConversationTemplateItem => {
    return {
      type: ValidConversationTemplateItemType.GraspUpload,
      condition: null,
      visible: true,
      apply_typing_effect: true,
    };
  };

export const getEmptyNotificationsListItem =
  (): NotificationsListValidConversationTemplateItem => {
    return {
      type: ValidConversationTemplateItemType.NotificationsList,
      condition: null,
      visible: true,
      apply_typing_effect: true,
    };
  };

export const getEmptyFeedbackItem =
  (): FeedbackValidConversationTemplateItem => {
    return {
      type: ValidConversationTemplateItemType.Feedback,
      condition: null,
      visible: true,
      apply_typing_effect: true,
      group_name: '',
    };
  };

export const getEmptyDelimiterItem =
  (): DelimiterValidConversationTemplateItem => {
    return {
      type: ValidConversationTemplateItemType.Delimiter,
      condition: null,
      visible: true,
      apply_typing_effect: true,
      persist: false,
    };
  };

export const getEmptyMaterialsListItem =
  (): MaterialsListValidConversationTemplateItem => {
    return {
      type: ValidConversationTemplateItemType.MaterialsList,
      condition: null,
      visible: true,
      apply_typing_effect: true,
    };
  };

export const getEmptyEducationItem =
  (): EducationValidConversationTemplateItem => {
    return {
      type: ValidConversationTemplateItemType.Education,
      condition: null,
      visible: true,
      apply_typing_effect: true,
      resource_id: '',
    };
  };

export const getItemBasedOnConversationQuestion = (
  question: ConversationQuestion,
  assignNewId: boolean
): QuestionValidConversationTemplateItem => {
  const newId = uuid();

  let validQuestion = getValidConversationQuestion(
    question.content.type,
    question.content
  );

  if (assignNewId) validQuestion.guid = newId;

  return {
    type: ValidConversationTemplateItemType.Question,
    apply_typing_effect: true,
    visible: true,
    condition: null,
    resource_id: assignNewId ? newId : question.resource_id,
    value: validQuestion,
  };
};
