import { ICaseDetails } from 'interfaces/cases';
import { useEffect, useMemo, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { SelectedDateContextType } from '../types';

export const useSelectedDateContext = (
  userCaseDetails: UseQueryResult<ICaseDetails>
) => {
  const caseSelectedDate = useMemo(() => {
    const endDate = userCaseDetails.data?.end_date;
    const now = new Date();

    if (endDate && new Date(endDate) <= now) return new Date(endDate);
    return now;
  }, [userCaseDetails.data]);

  const caseStartDate = useMemo(() => {
    const createdAtDate = userCaseDetails.data?.created_at
      ? new Date(userCaseDetails.data?.created_at)
      : new Date();

    return userCaseDetails.data?.start_date
      ? new Date(userCaseDetails.data.start_date)
      : createdAtDate;
  }, [userCaseDetails.data?.created_at, userCaseDetails.data?.start_date]);

  const caseEndDate = useMemo(
    () =>
      userCaseDetails.data?.end_date
        ? new Date(userCaseDetails.data.end_date)
        : new Date(),
    [userCaseDetails.data?.end_date]
  );

  const [selectedDate, setSelectedDate] = useState<Date>(caseSelectedDate);

  const selectedDateContext: SelectedDateContextType = useMemo(() => {
    return {
      selected: {
        item: selectedDate,
        setItem: setSelectedDate,
      },
      caseStart: caseStartDate,
      caseEnd: caseEndDate,
    };
  }, [caseEndDate, caseStartDate, selectedDate]);

  useEffect(() => {
    setSelectedDate(caseSelectedDate);
  }, [caseSelectedDate]);

  return selectedDateContext;
};
