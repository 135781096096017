import React from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import Skeleton from 'react-loading-skeleton';
import { wrapWithSequenceIdentified } from '../../../helpers/utils/data';

export type BreadcrumbsProps = {
  count: number;
};

const Breadcrumbs = ({ count }: BreadcrumbsProps) => {
  return (
    <Breadcrumb listClassName="ps-0 mb-0 breadcrumb--transparent">
      {wrapWithSequenceIdentified([...new Array(count)]).map((item) => (
        <BreadcrumbItem key={item.sequence}>
          <Skeleton width={100} />
        </BreadcrumbItem>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
