import { IUserOrganizationResponse } from '../../../../../../../../interfaces/membership';

export namespace UsersTabHelpers {
  export const FillDataWithRowId = (response: IUserOrganizationResponse[]) => {
    return response.map((item) => ({
      ...item,
      id: item.user.id,
    }));
  };
}
