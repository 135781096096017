import React from 'react';
import { History } from 'history';
import { BrowserRouterProps, Router } from 'react-router-dom';

export interface CustomBrowserRouterProps
  extends Omit<BrowserRouterProps, 'window'> {
  history: History;
}

//INFO
//Its temporary solution
//https://reactrouter.com/docs/en/v6/routers/history-router
//wait for stable history router
export const CustomBrowserRouter: React.FC<CustomBrowserRouterProps> =
  React.memo((props) => {
    const { history, ...restProps } = props;
    const [state, setState] = React.useState({
      action: history.action,
      location: history.location,
    });

    React.useLayoutEffect(() => history.listen(setState), [history]);

    return (
      <Router
        {...restProps}
        location={state.location}
        navigationType={state.action}
        navigator={history}
      />
    );
  });

export default CustomBrowserRouter;
