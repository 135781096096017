import { useTheme } from '@mui/material';
import { useCallback } from 'react';
import { DateGranularity } from '../../../../../../../../../../../../../../../interfaces/dashboards';
import { useDateRange } from '../../../hooks/useDateRange';
import { HorizontalChartTick, VerticalChartTick } from '../../ChartTicks';
import { TickFormatter } from '../../TickFormatter';

export const useUtils = (dateGranularity?: DateGranularity) => {
  const theme = useTheme();
  const dateRange = useDateRange();

  const getHorizontalChartTick = useCallback(
    (props: any) =>
      HorizontalChartTick({
        ...props,
        fontSize: 12,
        fill: theme.palette.text.primary,
        formatter: (value: any, index: number) =>
          TickFormatter(value, index, dateRange, dateGranularity),
      }),
    [dateGranularity, dateRange, theme.palette.text.primary]
  );

  const getVerticalChartTick = useCallback(
    (props: any) =>
      VerticalChartTick({
        ...props,
        fill: theme.palette.text.primary,
      }),
    [theme.palette.text.primary]
  );

  return {
    getHorizontalChartTick,
    getVerticalChartTick,
  };
};
