import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getFeedbackResources } from '../../feedbackResources';
import { groupByProperty } from '../../../helpers/utils/groupByProperty';
import { IFeedbackResource } from '../../../interfaces/feedbackResources';
import { OrganizationQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

function useFeedbackResources({
  params,
  options,
}: IQueryRequest<OrganizationQueryParams>) {
  const { organizationId } = params;
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getFeedbackResources(organizationId);

      let groupedByCode = groupByProperty('groupCode')(data.data) as Record<
        string,
        IFeedbackResource[]
      >;

      let groupedByCulture: Record<string, any[]> = {};

      Object.entries(groupedByCode).forEach(([groupCode, feedbacks]) => {
        groupedByCulture[groupCode] = groupByProperty('culture')(
          feedbacks.filter(
            (value, index, array) =>
              array.findIndex(
                (p) =>
                  p.sequence === value.sequence && p.culture === value.culture
              ) === index
          )
        );
      });

      return groupedByCulture;
    },
    queryKey: [QueryKeys.getFeedbackResources, organizationId],
  });
}

export default useFeedbackResources;
