import {
  AnswerHistory,
  FeedbackMessageGroup,
  QuestionHistoryMutationBody,
  SummarizedAnswerHistory,
} from 'interfaces/change';
import { IResponse } from 'interfaces/response';
import request from './utils/request';

export const getTrendsQuestionHistoryInDay = (
  organizationId: string,
  caseId: string,
  year: number,
  month: number,
  day: number
) =>
  request<void, IResponse<SummarizedAnswerHistory>>({
    url: `/organizations/${organizationId}/cases/${caseId}/questions/${year}/${month}/${day}`,
    method: 'GET',
  });

export const getUserTrendsQuestionHistoryInDay = (
  userId: string,
  caseId: string,
  year: number,
  month: number,
  day: number
) =>
  request<void, IResponse<SummarizedAnswerHistory>>({
    url: `/users/${userId}/cases/${caseId}/questions/${year}/${month}/${day}`,
    method: 'GET',
  });

export const getTrendsQuestionHistory = (
  userId: string,
  data: QuestionHistoryMutationBody
) =>
  request<QuestionHistoryMutationBody, IResponse<AnswerHistory>>({
    url: `/users/${userId}/questions_answer_history`,
    method: 'POST',
    data,
  });

export const getFeedbackMessages = () =>
  request<void, IResponse<FeedbackMessageGroup[]>>({
    url: `/feedback_messages_templates`,
    method: 'GET',
  });
