import React, { useState } from 'react';
import { ISummarizedBaseCaseGroup } from '../../../../../interfaces/caseGroups';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CaseGroupCollectionItem, {
  CaseGroupCollectionStyle,
} from '../../../../../components/CaseGroupCollectionItem';
import { faUsersClass } from '@fortawesome/pro-light-svg-icons';
import { formatDate } from '../../../../../helpers/dates';
import RadioLikeCaption from '../../../../membership/Login/components/RadioLikeCaption';
import useAddSubmissionsCaseToGroup from '../../../../../api/mutations/caseGroups/useAddSubmissionsCaseToGroup';

type CaseGroupSelectFromAvailableProps = {
  organizationCode: string;
  projectId: string;
  submissionId: string;
  onSuccess: () => void;
  caseGroups: ISummarizedBaseCaseGroup[];
};

const CaseGroupSelectFromAvailable = ({
  projectId,
  organizationCode,
  submissionId,
  onSuccess,
  caseGroups,
}: CaseGroupSelectFromAvailableProps) => {
  const { mutateAsync: addSubmissionsCaseToGroup } =
    useAddSubmissionsCaseToGroup(
      {
        organizationCode: organizationCode,
        projectId: projectId,
      },
      {
        successFb: onSuccess,
      }
    );

  const [selectedCaseGroup, setSelectedCaseGroup] =
    useState<ISummarizedBaseCaseGroup | null>(null);

  const renderAvailableGroups = caseGroups.map((group, index) => {
    const caseGroupTitle = (
      <span>
        <FormattedMessage id="CaseGroups.CaseGroupSelectFromAvailable.list.item.groupNo" />
        <span>{index + 1}</span>
      </span>
    );

    const caseGroupInfo = (
      <span>
        <FormattedMessage id="CaseGroups.CaseGroupSelectFromAvailable.list.item.startingDate" />
        <span>
          {formatDate(group.starting_date, {
            addSeconds: false,
          })}
        </span>
      </span>
    );

    const handleSelectCaseGroup = () => {
      setSelectedCaseGroup(group);
    };

    return (
      <CaseGroupCollectionItem
        key={group.id}
        onClick={handleSelectCaseGroup}
        style={
          selectedCaseGroup?.id === group.id
            ? CaseGroupCollectionStyle.PrimaryOutline
            : CaseGroupCollectionStyle.Default
        }
        title={caseGroupTitle}
        icon={<FontAwesomeIcon icon={faUsersClass} />}
        info={caseGroupInfo}
        actions={[
          {
            key: 'CaseGroupSelectFromAvailable_handleSelectCaseGroup',
            icon: (
              <RadioLikeCaption
                translationId=""
                selected={selectedCaseGroup?.id === group.id}
              />
            ),
            onClick: handleSelectCaseGroup,
          },
        ]}
      />
    );
  });

  const handleAddCaseToGroup = async () => {
    if (selectedCaseGroup === null) return;

    await addSubmissionsCaseToGroup({
      groupId: selectedCaseGroup.id,
      submissionId: submissionId,
    });
  };

  return (
    <div className="CaseGroupSelect">
      <div className="CaseGroupSelect__header">
        <h3 className="CaseGroupSelect__title">
          <FormattedMessage id="CaseGroups.CaseGroupSelectFromAvailable.header.title" />
        </h3>
        <span className="CaseGroupSelect__subtitle">
          <FormattedMessage id="CaseGroups.CaseGroupSelectFromAvailable.header.subtitle" />
        </span>
      </div>
      <div className="CaseGroupSelect__list CaseGroupCollection">
        {renderAvailableGroups}
      </div>
      <div className="CaseGroupSelect__actions">
        <Button
          className="CaseGroupSelect__action"
          disabled={selectedCaseGroup === null}
          color="primary"
          onClick={handleAddCaseToGroup}
        >
          <FormattedMessage id="CaseGroups.CaseGroupSelectFromAvailable.apply.text" />
        </Button>
      </div>
    </div>
  );
};

export default CaseGroupSelectFromAvailable;
