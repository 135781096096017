import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getCaseGroupsForProject } from '../../caseGroups';
import { IQueryRequest } from 'interfaces/request';

interface UseCaseGroupsForProjectParams {
  organizationCode: string;
  projectId: string;
}

const useCaseGroupsForProject = ({
  params,
  options,
}: IQueryRequest<UseCaseGroupsForProjectParams>) => {
  const { organizationCode, projectId } = params;
  return useQuery({
    ...options,
    queryKey: [QueryKeys.getCaseGroups, organizationCode, projectId],
    queryFn: async () => {
      const { data } = await getCaseGroupsForProject(
        organizationCode,
        projectId
      );

      return data.data;
    },
  });
};

export default useCaseGroupsForProject;
