import { FormikProps } from 'formik';
import { useCallback } from 'react';
import { FormValues } from '../../../types';

const emptyItem = {
  notification_hour: '00:00',
  available_from_hour: '00:00',
  available_to_hour: '00:00',
};

export const useHandleAddEmptyNotification = (
  form: FormikProps<FormValues>
) => {
  return useCallback(() => {
    form.setValues((prev) => ({
      ...prev,
      schedule_templates: [
        {
          ...prev.schedule_templates[0],
          parts_of_a_day: [
            ...prev.schedule_templates[0].parts_of_a_day,
            emptyItem,
          ],
        },
      ],
    }));
  }, [form]);
};
