import { GridRenderEditCellParams } from '@mui/x-data-grid';
import React from 'react';
import { FormatHelpers } from '../../../../../../../../../../../../../../helpers/format';
import { IPersonality } from '../../../../../../../../../../../../../../interfaces/personalities';
import SelectCell from '../../../SelectCell';

type MemberEditCellProps = GridRenderEditCellParams & {
  members: IPersonality[];
};

const MemberEditCell = ({ members, ...inner }: MemberEditCellProps) => {
  const valueGetter = (member: IPersonality) => member.id;
  const textGetter = (member: IPersonality) =>
    FormatHelpers.FormatPersonality(member);

  return (
    <SelectCell
      items={members}
      valueGetter={valueGetter}
      textGetter={textGetter}
      {...inner}
    />
  );
};

export default MemberEditCell;
