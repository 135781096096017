import { uploadExcelGraspFile } from 'api/graspManagement';
import {
  displayErrorArrayNotifications,
  displayErrorNotification,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

function useExcelFileUpload(organizationId: string) {
  return useMutationWithResultNotification({
    asyncApiFunction: async (file: File) => {
      await uploadExcelGraspFile(organizationId, file);
    },
    resetOnResult: true,
    onErrorArray: (errArr) => {
      if (errArr.length > 0) {
        displayErrorArrayNotifications(errArr, 'Grasps');
      } else {
        displayErrorNotification('Grasps.importError');
      }
    },
    onSuccess: () => {
      displaySuccessNotification('Grasps.importSuccess');
    },
  });
}

export default useExcelFileUpload;
