import { FormikProps } from 'formik';
import React from 'react';
import { ConversationScheduleType } from '../../../../../../../../../../../interfaces/conversationSchedules';
import { FormValues } from '../types';

export const useHandleScheduleType = () => {
  return (form: FormikProps<FormValues>) =>
    (_: React.MouseEvent<HTMLElement>, value: any) => {
      form.setValues((prev) => ({
        ...prev,
        schedule_templates: [
          {
            parts_of_a_day: [],
            specific_day_of_phase: undefined,
            recurring_interval: undefined,
            end_day_offset: undefined,
            start_day_offset: undefined,
            schedule_type: value as
              | ConversationScheduleType.SingleInstance
              | ConversationScheduleType.Recurring,
          },
        ],
      }));
    };
};
