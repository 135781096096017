import React from 'react';
import { FormattedMessage } from 'react-intl';

type CustomTablePlaceholderProps = {
  translationId?: string;
  loadingTranslationId?: string;
  isLoading: boolean;
  height?: number;
  columns: number;
};

const CustomTablePlaceholder = ({
  translationId = 'CustomTable.defaultTranslation',
  loadingTranslationId = 'CustomTable.defaultLoadingTranslation',
  isLoading,
  height = 350,
  columns,
}: CustomTablePlaceholderProps) => {
  return (
    <tr style={{ height: height }}>
      <td colSpan={columns} align={'center'} valign={'middle'}>
        <div
          className="w-100 d-flex align-items-center justify-content-center"
          style={{
            zIndex: 1,
          }}
        >
          <div className="ChangeCase__chart-placeholder__bubble" />
        </div>
        <div className="ChangeCase__chart-placeholder__text">
          <FormattedMessage
            id={isLoading ? loadingTranslationId : translationId}
          />
        </div>
      </td>
    </tr>
  );
};

export default CustomTablePlaceholder;
