import { useMemo, useState } from 'react';
import { ITabContainerItem } from '../../../../../../../components/Tabs/types/Tabs';
import { FormattedMessage } from 'react-intl';
import LocationsTab from '../../../tabs/LocationsTab';
import GraspsTab from '../tabs/GraspsTab';
import FunctionalitiesTab from '../../../tabs/FunctionalitiesTab';
import ObservationMeaningsTab from '../../../tabs/ObservationMeaningsTab';
import CaseCategoriesTab from '../../../tabs/CaseCategoriesTab';
import { TabLayoutType } from '../../../../../../../components/layouts/TabLayout';
import GraspSettingsTab from '../../../tabs/GraspSettingsTab';
import TrendVisualizationsTab from '../../../tabs/TrendVisualizationsTab';
import SymptomsTab from '../../../tabs/SymptomsTab';
import {
  GenericOrganizationTabsValues,
  mappedGenericOrganizationTabsValues,
} from '../types';

export const useTabs = () => {
  const [selectedTab, setSelectedTab] = useState<GenericOrganizationTabsValues>(
    GenericOrganizationTabsValues.LOCATIONS
  );

  const tabItems: ITabContainerItem[] = useMemo(
    () => [
      {
        header: <FormattedMessage id="Organizations.Locations" />,
        component: <LocationsTab type={TabLayoutType.Generic} />,
        shouldBeRendered:
          selectedTab === GenericOrganizationTabsValues.LOCATIONS,
      },
      {
        header: <FormattedMessage id="Organizations.Grasps" />,
        component: <GraspsTab />,
        shouldBeRendered: selectedTab === GenericOrganizationTabsValues.GRASPS,
      },
      {
        header: <FormattedMessage id="Organizations.GraspSettings" />,
        component: <GraspSettingsTab type={TabLayoutType.Generic} />,
        shouldBeRendered:
          selectedTab === GenericOrganizationTabsValues.GRASP_SETTINGS,
      },
      {
        header: <FormattedMessage id="Organizations.TrendsVisualizations" />,
        component: <TrendVisualizationsTab type={TabLayoutType.Generic} />,
        shouldBeRendered:
          selectedTab === GenericOrganizationTabsValues.TREND_VISUALIZATIONS,
      },
      {
        header: <FormattedMessage id="Organizations.Symptoms" />,
        component: <SymptomsTab type={TabLayoutType.Generic} />,
        shouldBeRendered:
          selectedTab === GenericOrganizationTabsValues.SYMPTOMS,
      },
      {
        header: <FormattedMessage id="Organizations.Functionalities" />,
        component: <FunctionalitiesTab type={TabLayoutType.Generic} />,
        shouldBeRendered:
          selectedTab === GenericOrganizationTabsValues.FUNCTIONALITIES,
      },
      {
        header: <FormattedMessage id="Organizations.CaseCategories" />,
        component: <CaseCategoriesTab type={TabLayoutType.Generic} />,
        shouldBeRendered:
          selectedTab === GenericOrganizationTabsValues.CASE_CATEGORIES,
      },
      {
        header: <FormattedMessage id="Organizations.ObservationMeanings" />,
        component: <ObservationMeaningsTab type={TabLayoutType.Generic} />,
        shouldBeRendered:
          selectedTab === GenericOrganizationTabsValues.OBSERVATION_MEANINGS,
      },
    ],
    [selectedTab]
  );

  const onTabChange = (val: number) => {
    setSelectedTab(mappedGenericOrganizationTabsValues[val]);
  };

  return { tabItems, onTabChange };
};
