import React from 'react';
import { Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import LoadingButton from 'components/LoadingButton';
import { UserDataFormProps } from 'interfaces/ui';
import {
  AddressField,
  BirthdateField,
  EmailField,
  FirstNameField,
  GenderField,
  LastNameField,
  PhoneNumberField,
  SsnField,
} from 'modules/membership/Register/components/FieldDefinitions';
import { RegisterFormKeys } from 'interfaces/enums';

const RowWrapper = ({ children }: React.PropsWithChildren) => {
  return (
    <Row>
      <Col md={12} sm={12} lg={8}>
        {children}
      </Col>
    </Row>
  );
};

const ParticipantsForm = <T,>({
  formik,
  readOnlyInputs = [],
  displayInvitePatientButton,
  onInviteClick,
  inviteDisabled,
  isInviteLoading,
}: UserDataFormProps<T>) => {
  return (
    <>
      <Row>
        <Col md={12} sm={12} lg={8}>
          <EmailField
            formik={formik}
            isOptional
            isDisabled={readOnlyInputs.includes(RegisterFormKeys.EMAIL)}
          />
        </Col>
        {displayInvitePatientButton && (
          <Col md={12} sm={12} lg={4}>
            <LoadingButton
              color="primary"
              className="CaseCard__invite_button"
              disabled={inviteDisabled}
              onClick={onInviteClick}
              isLoading={isInviteLoading}
            >
              <FormattedMessage id="CaseCard.create.sendInvite" />
            </LoadingButton>
          </Col>
        )}
      </Row>
      <RowWrapper>
        <FirstNameField
          formik={formik}
          isDisabled={readOnlyInputs.includes(RegisterFormKeys.FIRST_NAME)}
        />
      </RowWrapper>
      <RowWrapper>
        <LastNameField
          formik={formik}
          isDisabled={readOnlyInputs.includes(RegisterFormKeys.LAST_NAME)}
        />
      </RowWrapper>
      <RowWrapper>
        <BirthdateField
          formik={formik}
          isDisabled={readOnlyInputs.includes(RegisterFormKeys.BIRTH_DATE)}
        />
      </RowWrapper>
      <RowWrapper>
        <GenderField
          formik={formik}
          isDisabled={readOnlyInputs.includes(RegisterFormKeys.GENDER)}
        />
      </RowWrapper>
      <RowWrapper>
        <AddressField
          formik={formik}
          isDisabled={readOnlyInputs.includes(RegisterFormKeys.ADDRESS)}
        />
      </RowWrapper>
      <RowWrapper>
        <PhoneNumberField
          formik={formik}
          isDisabled={readOnlyInputs.includes(RegisterFormKeys.PHONE_NUMBER)}
        />
      </RowWrapper>
      <RowWrapper>
        <SsnField
          formik={formik}
          isDisabled={readOnlyInputs.includes(RegisterFormKeys.SSN)}
        />
      </RowWrapper>
    </>
  );
};

export default ParticipantsForm;
