import { GridColDef } from '@mui/x-data-grid';
import { useGetCultureEntry } from '../../../../../../../../../../../../../hooks/cultures/useGetCultureEntry';
import { useQuestionTitle } from '../../../../../../../../../../../../../hooks/data/useQuestionTitle';
import { useTypedContext } from '../../../../../../../../../../../../../hooks/useTypedContext';
import { ITableDashboardTemplateVisualization } from '../../../../../../../../../../../../../interfaces/dashboards';
import { DashboardQueriesContext } from '../../../../../../../types';

export const useColumns = (
  visualization: ITableDashboardTemplateVisualization
): GridColDef[] => {
  const getCultureEntry = useGetCultureEntry();
  const queriesData = useTypedContext(DashboardQueriesContext);
  const getQuestionTitle = useQuestionTitle();

  return visualization.columns.map((column) => {
    const columnValue = getCultureEntry<string>(column.name) ?? '';
    const existingQuestion = queriesData.questions.find(
      (p) => p.resource_id === columnValue
    );

    if (!existingQuestion)
      return {
        field: columnValue,
        headerName: columnValue,
        flex: 1,
      };

    return {
      field: columnValue,
      headerName: getQuestionTitle(existingQuestion),
      flex: 1,
    };
  });
};
