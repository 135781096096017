import useOpen from 'hooks/useOpen';
import React from 'react';
import { FallbackProps } from 'react-error-boundary';
import { Button, Col, Row } from 'reactstrap';
import ErrorLayout from './layouts/ErrorLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBomb } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import FeedbackModal from './FeedbackModal';

const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const { isOpen, toggle: toggleModal, open: openModal } = useOpen();

  return (
    <ErrorLayout>
      <>
        <FeedbackModal
          isOpen={isOpen}
          toggle={toggleModal}
          initialValues={{
            importance: 3,
            category: 'Bug',
            description: `${error.message || ''}`,
            subject: `Application error screen - ${new Date().toISOString()}`,
          }}
        />
        <Row className="w-100 mt-4 text-center">
          <Col md={{ size: 12 }}>
            <Row>
              <Col md={12} lg={{ size: 3, offset: 3 }}>
                <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                  <FontAwesomeIcon icon={faBomb} color="#dc3545" size="6x" />
                </div>
              </Col>
              <Col md={12} lg={6} className="ErrorScreen__description">
                <p className="ErrorScreen__description__big">
                  <FormattedMessage id="ErrorScreen.sorry" />
                </p>
                <p className="ErrorScreen__description__big">
                  <FormattedMessage id="ErrorScreen.itsNotYou" />
                </p>
                <p className="ErrorScreen__description__big mb-2">
                  <FormattedMessage id="ErrorScreen.itsUs" />
                </p>
                <p>
                  <FormattedMessage id="ErrorScreen.weExperienceInternalProblem" />
                </p>
                <p>
                  <FormattedMessage id="ErrorScreen.reportOrRestart" />
                </p>
                <pre>{error.message ?? ''}</pre>
                <pre>{error.stack ?? ''}</pre>
                <div className="mt-4">
                  <Button onClick={openModal} className="me-4">
                    <FormattedMessage id="ErrorScreen.reportIssue" />
                  </Button>
                  <Button color="primary" onClick={resetErrorBoundary}>
                    <FormattedMessage id="ErrorScreen.resetApplication" />
                  </Button>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    </ErrorLayout>
  );
};

export default ErrorFallback;
