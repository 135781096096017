import { FormikErrors } from 'formik';
import { cloneDeep } from 'lodash';
import { useCallback, useMemo } from 'react';
import useCreateCaseGroupConversationSchedule from '../../../../../../../../../api/mutations/conversationSchedules/useCreateCaseGroupConversationSchedule';
import useCreateMultipleCaseGroupConversationSchedule from '../../../../../../../../../api/mutations/conversationSchedules/useCreateMultipleCaseGroupConversationSchedule';
import { AddScheduleTemplateFormHandler } from '../../../../../../../../../components/AddScheduleTemplateModal';
import { useOptions } from '../../../../../../../../../hooks/useOptions';
import useRequiredParams from '../../../../../../../../../hooks/useRequiredParams';
import {
  IConversationTreatmentPhaseContentItem,
  ISummarizedTreatmentPhase,
  TreatmentPhaseContentItemType,
} from '../../../../../../../../../interfaces/conversations';
import { ConversationScheduleType } from '../../../../../../../../../interfaces/conversationSchedules';
import { useFormValidation } from '../../../../../../../../TreatmentPlans/components/TreatmentPlan/components/TreatmentPhases/components/TreatmentPhase/modals/AddTemplateModal/hooks/useFormValidation';
import { useCaseGroupDetailsCommonData } from '../../../../../hooks/useCaseGroupDetailsCommonData';
import { useCaseGroupConversationScheduleData } from '../../../components/CaseGroupConversationSchedule/hooks/useCaseGroupConversationScheduleData';
import { useCaseGroupDetailsAddTemplatePayload } from './useCaseGroupDetailsAddTemplatePayload';
import { useCaseGroupDetailsTemplateCharacteristics } from './useCaseGroupDetailsTemplateCharacterictics';

const emptyInitialValues: IConversationTreatmentPhaseContentItem = {
  type: TreatmentPhaseContentItemType.ConversationSchedule,
  template_id: '',
  priority: 0,
  schedule_templates: [
    {
      schedule_type: ConversationScheduleType.Once,
      recurring_interval: null,
      start_day_offset: null,
      end_day_offset: null,
      parts_of_a_day: [],
      specific_day_of_phase: null,
    },
  ],
};

export const useCaseGroupDetailsAddTemplateForm = (
  phaseId: string,
  toggle: () => void,
  item?: IConversationTreatmentPhaseContentItem,
  treatmentPhase?: ISummarizedTreatmentPhase
): AddScheduleTemplateFormHandler => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const validation = useFormValidation();
  const {
    isTemplateTimeBased,
    isTemplateDefault,
    conversationTemplatesOptions,
  } = useCaseGroupDetailsTemplateCharacteristics();

  const { scheduleTypes } = useOptions();

  const { simplePayload, singleInstancePayload, recurringPayload } =
    useCaseGroupDetailsAddTemplatePayload();

  const getEmptyInitialValues = useCallback(() => {
    return cloneDeep(emptyInitialValues);
  }, []);

  const { caseGroup } = useCaseGroupDetailsCommonData();

  const { caseGroupConversationSchedules } =
    useCaseGroupConversationScheduleData(phaseId);

  const handleSuccess = async () => {
    await caseGroupConversationSchedules.refetch();
    toggle();
  };

  const { mutateAsync: createAsync } = useCreateCaseGroupConversationSchedule(
    {
      organizationId: organizationId,
      caseGroupId: caseGroup.data?.id ?? '',
    },
    {
      successFb: handleSuccess,
    }
  );

  const { mutateAsync: createMultipleAsync } =
    useCreateMultipleCaseGroupConversationSchedule(
      {
        organizationId: organizationId,
        caseGroupId: caseGroup.data?.id ?? '',
      },
      {
        successFb: handleSuccess,
      }
    );

  const getInitialValuesBasedOnItem = useCallback(
    (
      initialItem: IConversationTreatmentPhaseContentItem
    ): IConversationTreatmentPhaseContentItem => {
      return {
        type: TreatmentPhaseContentItemType.ConversationSchedule,
        template_id: initialItem.template_id,
        priority: initialItem.priority,
        schedule_templates: [
          {
            schedule_type: initialItem.schedule_templates[0].schedule_type,
            start_day_offset:
              initialItem.schedule_templates[0].start_day_offset,
            end_day_offset: initialItem.schedule_templates[0].end_day_offset,
            parts_of_a_day: [],
            specific_day_of_phase: null,
            recurring_interval:
              initialItem.schedule_templates[0].recurring_interval,
          },
        ],
      };
    },
    []
  );

  const initialValues = useMemo(() => {
    if (!item) return getEmptyInitialValues();
    return getInitialValuesBasedOnItem(item);
  }, [getEmptyInitialValues, getInitialValuesBasedOnItem, item]);

  const validate = useCallback(
    (values: typeof initialValues) => {
      let errors: FormikErrors<typeof initialValues> = {};

      if (!treatmentPhase) {
        errors.schedule_templates = [];
        return errors;
      }

      errors = validation.genericValidation(
        errors,
        false,
        values,
        treatmentPhase.empty_conversation_template_id ?? undefined
      );

      if (!isTemplateTimeBased(values.template_id)) return errors;

      if (values.schedule_templates.length === 0)
        errors.schedule_templates = [];

      const firstScheduleTemplate = values.schedule_templates.at(0);

      if (!firstScheduleTemplate?.schedule_type) {
        errors.schedule_templates = [];
        return errors;
      }

      if (firstScheduleTemplate.schedule_type === ConversationScheduleType.Once)
        return errors;

      if (
        firstScheduleTemplate.schedule_type ===
        ConversationScheduleType.SingleInstance
      ) {
        errors = validation.singleInstanceValidation(
          errors,
          firstScheduleTemplate,
          treatmentPhase.days_duration
        );
        return errors;
      }

      errors = validation.recurringValidation(
        errors,
        firstScheduleTemplate,
        treatmentPhase.days_duration
      );
      return errors;
    },
    [isTemplateTimeBased, treatmentPhase, validation]
  );

  const templatesOptions = useMemo(
    () => conversationTemplatesOptions(false, true),
    [conversationTemplatesOptions]
  );

  const scheduleTypesOptions = useMemo(
    () => scheduleTypes(true),
    [scheduleTypes]
  );

  const handleSubmit = useCallback(
    async (values: typeof initialValues) => {
      const isTimeBased = isTemplateTimeBased(values.template_id);

      if (
        !isTimeBased ||
        values.schedule_templates.at(0)?.schedule_type ===
          ConversationScheduleType.Once
      ) {
        await createAsync(
          simplePayload(
            values,
            phaseId,
            isTimeBased
              ? ConversationScheduleType.Once
              : ConversationScheduleType.NonTimeBased
          )
        );

        return;
      }

      const scheduleType = values.schedule_templates.at(0)?.schedule_type;
      const groupSchedule = caseGroup.data?.group_schedule.find(
        (p) => p.phase_id === phaseId
      );

      if (!scheduleType || !groupSchedule) return;

      if (scheduleType === ConversationScheduleType.SingleInstance) {
        await createAsync(
          singleInstancePayload(values, phaseId, groupSchedule)
        );
        return;
      }

      await createMultipleAsync(
        recurringPayload(values, phaseId, groupSchedule)
      );
    },
    [
      caseGroup.data?.group_schedule,
      createAsync,
      createMultipleAsync,
      isTemplateTimeBased,
      phaseId,
      recurringPayload,
      simplePayload,
      singleInstancePayload,
    ]
  );

  return {
    initialValues: initialValues,
    validate: validate,
    options: {
      conversationTemplates: templatesOptions,
      scheduleTypes: scheduleTypesOptions,
    },
    helpers: {
      isTemplateTimeBased: isTemplateTimeBased,
      isTemplateDefault: isTemplateDefault,
    },
    handleSubmit: handleSubmit,
  };
};
