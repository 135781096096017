import { useState } from 'react';
import { useIntl } from 'react-intl';

export const useSaveError = () => {
  const intl = useIntl();
  const [error, setError] = useState<string | null>(null);

  const handleSetError = (translationId: string) => {
    setError(intl.formatMessage({ id: translationId }));
  };

  const handleReset = () => {
    setError(null);
  };

  return { error, handleSetError, handleReset };
};
