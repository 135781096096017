import React from 'react';
import {
  ValidConversationTemplate,
  ValidConversationTemplateItemType,
} from '../../../../../../../../interfaces/conversations';
import { AvailableCulture } from '../../../../../../../../hooks/cultures/useCultures';
import { AVAILABLE_CULTURES } from '../../../../../../../../components/CultureSelector/CultureSelector';
import { useTranslations } from './hooks/useTranslations';
import ResourceIdItemEdit from './components/ResourceIdItemEdit';
import BasicPlainTextItemEdit from './components/BasicPlainTextItemEdit';
import BasicQuestionItemEdit from './components/BasicQuestionItemEdit';
import ConditionItemEdit from './components/ConditionItemEdit';
import FieldInput from '../../../../../../../../components/FieldInput';
import { Field } from 'formik';
import QuestionItemEdit from './components/QuestionItemEdit';
import { LanguageContext } from './types';
import DelimiterItemEdit from './components/DelimiterItemEdit';
import FeedbackItemEdit from './components/FeedbackItemEdit';
import EducationItemEdit from './components/EducationItemEdit';

type ItemEditProps = {
  index: number;
  language: AvailableCulture;
  values: ValidConversationTemplate;
};

const ItemEdit = ({ index, values, language }: ItemEditProps) => {
  const selectedItem = values.content[index];
  const { providerInvariant } = useTranslations();
  const isDefaultLanguage = language.culture === AVAILABLE_CULTURES.EN;

  return (
    <div className="ItemEdit d-flex flex-column w-100 gap-4 px-1 pt-2">
      <LanguageContext.Provider value={language}>
        <ResourceIdItemEdit item={selectedItem} index={index} />
        {selectedItem.type === ValidConversationTemplateItemType.PlainText && (
          <BasicPlainTextItemEdit
            index={index}
            values={values}
            isDefaultLanguage={isDefaultLanguage}
          />
        )}
        {selectedItem.type === ValidConversationTemplateItemType.Question && (
          <BasicQuestionItemEdit
            index={index}
            values={values}
            isDefaultLanguage={isDefaultLanguage}
          />
        )}
        <ConditionItemEdit item={selectedItem} index={index} />
        <div className="d-flex flex-column gap-1">
          <Field
            required={false}
            id={`content[${index}].apply_typing_effect`}
            name={`content[${index}].apply_typing_effect`}
            label={providerInvariant.wrapWithIntl(
              providerInvariant.attachPrefix('applyTypingEffect')
            )}
            type="checkbox"
            component={FieldInput}
          />
          <Field
            required={false}
            id={`content[${index}].visible`}
            name={`content[${index}].visible`}
            label={providerInvariant.wrapWithIntl(
              providerInvariant.attachPrefix('visible')
            )}
            type="checkbox"
            component={FieldInput}
          />
        </div>
        <TypeBasedItemEdit index={index} values={values} />
      </LanguageContext.Provider>
    </div>
  );
};

const TypeBasedItemEdit = ({
  index,
  values,
}: Omit<ItemEditProps, 'language'>) => {
  const selectedItem = values.content[index];

  switch (selectedItem.type) {
    case ValidConversationTemplateItemType.Question:
      return <QuestionItemEdit values={values} index={index} />;
    case ValidConversationTemplateItemType.Feedback:
      return <FeedbackItemEdit index={index} />;
    case ValidConversationTemplateItemType.Education:
      return <EducationItemEdit index={index} />;
    case ValidConversationTemplateItemType.Delimiter:
      return <DelimiterItemEdit index={index} />;
    case ValidConversationTemplateItemType.PlainText:
    case ValidConversationTemplateItemType.NotificationsList:
    case ValidConversationTemplateItemType.MaterialsList:
    case ValidConversationTemplateItemType.GraspUpload:
      return <></>;
  }
};

export default ItemEdit;
