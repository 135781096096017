import { useState } from 'react';

export const useFormNavigation = (itemsCount: number, onLast?: () => void) => {
  const [activeTab, setActiveTab] = useState<number>(0);

  const next = () => {
    if (activeTab === itemsCount - 1) {
      onLast?.();
      return;
    }

    setActiveTab((prev) => {
      if (prev + 1 > itemsCount - 1) return prev;
      return prev + 1;
    });
  };

  const prev = () => {
    setActiveTab((prev) => {
      if (prev - 1 < 0) return prev;
      return prev - 1;
    });
  };

  return { activeTab, prev, next };
};
