import useRequiredParams from '../../../../../hooks/useRequiredParams';
import useGraspSettings from '../../../../../api/queries/grasps/useGraspSettings';
import useOrganizationDetails from '../../../../../api/queries/organizations/useOrganizationDetails';
import useConversationTemplates from '../../../../../api/queries/conversations/useConversationTemplates';
import useOrganizationRelatedCaseDefaultSetups from '../../../../../api/queries/caseDefaultSetups/useOrganizationRelatedCaseDefaultSetups';
import useTrendVisualizationTemplates from '../../../../../api/queries/trendVisualizations/useTrendVisualizationTemplates';
import useCaseCategories from '../../../../../api/queries/cases/useCaseCategories';
import { useQueriesRefetch } from '../../../../../hooks/queries/useQueriesRefetch';
import useGroupedConversationQuestions from '../../../../../api/queries/conversations/useGroupedConversationQuestions';
import useObservationMeanings from '../../../../../api/queries/observationMeanings/useObservationMeanings';

export const useCommonData = () => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const organizationDetails = useOrganizationDetails({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const graspSettings = useGraspSettings({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const conversationTemplates = useConversationTemplates({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const groupedConversationQuestions = useGroupedConversationQuestions({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const caseDefaultSetups = useOrganizationRelatedCaseDefaultSetups({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const trendVisualizationTemplates = useTrendVisualizationTemplates({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const organizationObservationMeanings = useObservationMeanings({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const caseCategories = useCaseCategories({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const queriesRefetch = useQueriesRefetch([
    caseDefaultSetups,
    trendVisualizationTemplates,
    caseCategories,
  ]);

  return {
    organizationObservationMeanings,
    organizationDetails,
    graspSettings,
    conversationTemplates,
    groupedConversationQuestions,
    caseDefaultSetups,
    trendVisualizationTemplates,
    caseCategories,
    queriesRefetch,
  };
};
