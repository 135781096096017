import { IMutationRequest } from '../../../interfaces/request';
import { UserCaseQueryParams } from '../../../interfaces/queryParams';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { createSimpleCaseObservation } from '../../observations';

const useCreateSimpleCaseObservation = ({
  params,
  options,
}: IMutationRequest<UserCaseQueryParams>) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async () => {
      await createSimpleCaseObservation(params.userId, params.caseId);
    },
    resetOnResult: true,
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'Api.Mutations.Observations.CreateSimpleCaseObservation.failure'
      );
      options.errorFb?.();
    },
    onSuccess: () => {
      displaySuccessNotification(
        'Api.Mutations.Observations.CreateSimpleCaseObservation.success'
      );
      options.successFb?.();
    },
  });
};

export default useCreateSimpleCaseObservation;
