import useCultures from '../../../../../../../../../hooks/cultures/useCultures';
import { useCallback } from 'react';
import { ProcedureActivity } from '../../../forms/AwareCaseDetailsForm/types';

export const useCurrentEventName = () => {
  const { currentCulture, defaultCulture } = useCultures();

  return useCallback(
    (procedureEvent: ProcedureActivity) => {
      return currentCulture.cultureCode === defaultCulture.cultureCode
        ? procedureEvent.nameEn
        : procedureEvent.nameNo;
    },
    [currentCulture.cultureCode, defaultCulture.cultureCode]
  );
};
