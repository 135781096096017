import { changePassword } from 'api/membership';
import { IChangePasswordBody } from 'interfaces/membership';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

function useEditPassword(successFb?: () => void) {
  return useMutationWithResultNotification({
    asyncApiFunction: async (data: IChangePasswordBody) => {
      await changePassword(data);
    },
    resetOnResult: true,
    onSuccess: () => {
      displaySuccessNotification('MyProfile.edit.passwordChangeSuccess');
      successFb && successFb();
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'MyProfile.edit.passwordChangeError'
      );
    },
  });
}

export default useEditPassword;
