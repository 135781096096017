import { useMemo, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import classNames from 'classnames';

//COMPONENTS
import NotificationsTab from './components/NotificationsTab';
import ProfileSettingsTab from './components/ProfileSettingsTab';
import ChangeEmailTab from './components/ChangeEmailTab';
import ChangePasswordTab from './components/ChangePasswordTab';
import OneTimeTokenTab from './components/OneTimeTokenTab';
import PageHeader from 'components/PageHeader';
import { getOrganizations, getUserId } from 'helpers/utils/auth';
import { UserRolesInToken } from 'interfaces/membership';
import MyOrganizationTab from './components/MyOrganizationTab';
import GenerateDataDumpTab from './components/GenerateDataDumpTab';

export enum MyProfileTabs {
  PROFILE_SETTINGS = 'profileSettings',
  EMAIL = 'email',
  NOTIFICATIONS = 'notifications',
  PASSWORD = 'password',
  ONE_TIME_TOKEN = 'oneTimeToken',
  MY_ORGANIZATION = 'myOrganization',
  GENERATE_DATA_DUMP = 'generateDataDump',
}

let tabTranslations = [
  {
    text: 'MyProfile.Tabs.profileSettings',
    value: MyProfileTabs.PROFILE_SETTINGS,
  },
  {
    text: 'MyProfile.Tabs.changePassword',
    value: MyProfileTabs.PASSWORD,
  },
  {
    text: 'MyProfile.Tabs.changeEmail',
    value: MyProfileTabs.EMAIL,
  },
  {
    text: 'MyProfile.Tabs.generateOneTimeToken',
    value: MyProfileTabs.ONE_TIME_TOKEN,
  },
  {
    text: 'MyProfile.Tabs.notifications',
    value: MyProfileTabs.NOTIFICATIONS,
  },
  {
    text: 'MyProfile.Tabs.generateDataDump',
    value: MyProfileTabs.GENERATE_DATA_DUMP,
  },
];

const MyAccount = () => {
  const userId = getUserId();
  const intl = useIntl();

  const [selectedTab, setSelectedTab] = useState(
    MyProfileTabs.PROFILE_SETTINGS
  );

  const organizations = getOrganizations();

  const adminOrganizations = organizations?.filter((item) => {
    return item.role && item.role.includes(UserRolesInToken.ORGANIZATION_ADMIN);
  });
  const myOrganizationsVisible =
    adminOrganizations && adminOrganizations?.length > 0;

  const tabs = useMemo(() => {
    const myOrganizationTab = {
      text: 'MyProfile.Tabs.myOrganizations',
      value: MyProfileTabs.MY_ORGANIZATION,
    };

    if (myOrganizationsVisible) return [...tabTranslations, myOrganizationTab];

    return tabTranslations;
  }, [myOrganizationsVisible]);

  const tabList = tabs.map(({ text, value }) => {
    const tabText = intl.formatMessage({ id: text });

    return (
      <li
        key={value}
        className={classNames('tabs__item', {
          'tabs__item--active': value === selectedTab,
        })}
        onClick={() => setSelectedTab(value)}
        aria-label={tabText}
      >
        {tabText}
      </li>
    );
  });

  const renderContent = () => {
    switch (selectedTab) {
      case MyProfileTabs.PROFILE_SETTINGS:
        return (
          <ProfileSettingsTab setSelectedTab={setSelectedTab} userId={userId} />
        );
      case MyProfileTabs.EMAIL:
        return <ChangeEmailTab />;
      case MyProfileTabs.PASSWORD:
        return <ChangePasswordTab />;
      case MyProfileTabs.ONE_TIME_TOKEN:
        return <OneTimeTokenTab userId={userId} />;
      case MyProfileTabs.NOTIFICATIONS:
        return <NotificationsTab />;
      case MyProfileTabs.MY_ORGANIZATION:
        return <MyOrganizationTab />;
      case MyProfileTabs.GENERATE_DATA_DUMP:
        return <GenerateDataDumpTab userId={userId} />;
    }
  };

  return (
    <>
      <PageHeader
        breadcrumbs={[
          {
            key: 'my_account',
            link: null,
            text: <FormattedMessage id="General.myAccount" />,
          },
        ]}
      />
      <Row>
        <Col md={3}>
          <ul className="tabs">{tabList}</ul>
        </Col>
        <Col md={9}>{renderContent()}</Col>
      </Row>
    </>
  );
};

export default MyAccount;
