import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { linkExternalUsersProvider } from 'api/membership';

type UseUnlinkUsersExternalProviderProps = {
  userId: string;
  loginProvider: string;
  providerKey: string;
};

const useLinkUsersExternalProvider = (successFb?: () => void) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async ({
      userId,
      loginProvider,
      providerKey,
    }: UseUnlinkUsersExternalProviderProps) => {
      await linkExternalUsersProvider(userId, loginProvider, providerKey);
    },
    resetOnResult: true,
    onSuccess: () => {
      successFb && successFb();
      displaySuccessNotification(
        'MyProfile.ProfileSettings.successfullyLinkedExternalUsersProvider'
      );
    },
    onErrorArray: (errors) => {
      displayErrorArrayNotifications(
        errors,
        'MyProfile.ProfileSettings.linkingExternalUsersProviderWithError'
      );
    },
  });
};

export default useLinkUsersExternalProvider;
