import React from 'react';

const IntensityTimelineTooltip = (props: any) => {
  const { active, payload, valueFormatter, nameFormatter, data } = props;

  if (active && payload?.length) {
    const availablePayload = (payload as any[]).filter(
      (p) => p.name !== 'session'
    );

    return (
      <div className="d-flex flex-column gap-2 card p-3 shadow">
        <div className="d-flex flex-column gap-2">
          {availablePayload.map((item) => {
            let value = item.value;

            const timePayload = payload.find((p) => p.name === 'time');

            if (item.name === 'visibleDuration') {
              const availableData = data.find(
                (p) => p.time === timePayload?.value
              );

              if (availableData !== undefined) value = availableData.duration;
            }

            return (
              <div key={item.value} className="d-flex align-items-center gap-3">
                <div>{`${
                  nameFormatter ? nameFormatter(item.name) : item.name
                } : ${
                  valueFormatter ? valueFormatter(value, item.name) : value
                }`}</div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  return null;
};

export default IntensityTimelineTooltip;
