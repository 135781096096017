import { useRowSelection } from 'hooks/useRowSelection';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FormGroup, Label } from 'reactstrap';

//types
import {
  IOrganizationUserSubmission,
  IProjectSubmissionFilters,
} from 'interfaces/submissions';

//helpers
//helpers
//images
import patientsIcon from 'assets/images/patients_icon.svg';

//components
import StatusSummary from './components/ProjectUsersList/StatusSummary';
import ProjectUsersFilter from './components/ProjectUsersList/ProjectUsersFilter';
import { useDispatch } from 'react-redux';
import { setSubmissionsListFilters } from './store/actions';
import useRequiredParams from 'hooks/useRequiredParams';
import { useSubmissionListFilters } from 'store/reducerHooks';
import { GroupSelectionWithParticipantsInfo } from '../../components/SendMessageToGroupModal';
import InfiniteListLayout from '../../components/InfiniteList/InfiniteListLayout';
import ProjectUsersList from './components/ProjectUsersList/ProjectUsersList';

const ProjectSubmissions = () => {
  const dispatch = useDispatch();

  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const [totalItemsCount, setTotalItemsCount] = useState<number>(0);
  const [submissions, setSubmissions] = useState<IOrganizationUserSubmission[]>(
    []
  );

  const globalFilters = useSubmissionListFilters();

  const rowSelectionProps =
    useRowSelection<GroupSelectionWithParticipantsInfo>(totalItemsCount);

  const handleProjectFilters = (projectFilters: IProjectSubmissionFilters) => {
    rowSelectionProps.resetSelection();
    dispatch(setSubmissionsListFilters(projectFilters));
  };

  return (
    <InfiniteListLayout
      labelTranslationId={'ProjectSubmissions.findSubmission'}
      titleTranslationId={'Projects.usersList.projectSubmissions'}
      inputIconSrc={patientsIcon}
      additionalLeftContent={
        <FormGroup>
          <Label>
            <FormattedMessage id="Projects.usersList.filterByStatus" />
          </Label>
          <ProjectUsersFilter
            filters={globalFilters}
            setFilters={handleProjectFilters}
          />
        </FormGroup>
      }
      additionalRightContent={
        <FormGroup>
          <StatusSummary data={submissions} />
        </FormGroup>
      }
    >
      <ProjectUsersList
        setTotalItemsCount={setTotalItemsCount}
        setSubmissions={setSubmissions}
        organizationId={organizationId}
        rowSelectionProps={rowSelectionProps}
      />
    </InfiniteListLayout>
  );
};

export default ProjectSubmissions;
