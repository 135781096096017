import useTimelines from 'api/queries/timelines/useTimelines';
import { getUserId } from 'helpers/utils/auth';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { TimelineDetailsAnnotationErrors } from '../TimelineDetailsAnnotationProxy';
import FormNotFoundErrorModalBody from '../TimelineDetailsAssessmentProxyModal.tsx/FormNotFoundErrorModalBody';
import InvalidDateParamsErrorModalBody from '../TimelineDetailsAssessmentProxyModal.tsx/InvalidDateParamsErrorModalBody';

type TimelineDetailsAnnotationProxyModalProps = {
  errorType: TimelineDetailsAnnotationErrors;
  timelineId: string;
  isOpen: boolean;
  toggleModal: () => void;
};

const TimelineDetailsAnnotationProxyModal = ({
  errorType,
  timelineId,
  isOpen,
  toggleModal,
}: TimelineDetailsAnnotationProxyModalProps) => {
  const navigate = useNavigate();

  const { data: timelinesData } = useTimelines({
    params: { userId: getUserId() },
  });

  const redirectToDashboard = () => {
    navigate('/dashboard');
  };

  const redirectToTimeline = () => {
    navigate(`/timelines/${timelineId}`);
  };

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      toggle={toggleModal}
      className="TimelineDetailsProxy__modal"
    >
      <ModalHeader className="w-100" toggle={redirectToDashboard}>
        <FormattedMessage id="ProxyComponents.TimelineDetails.ModalHeaderError" />
      </ModalHeader>
      <ModalBody>
        {errorType === TimelineDetailsAnnotationErrors.AnnotationNotFound && (
          <FormNotFoundErrorModalBody />
        )}
        {errorType === TimelineDetailsAnnotationErrors.InvalidDateParams && (
          <InvalidDateParamsErrorModalBody />
        )}
      </ModalBody>
      <ModalFooter>
        <Button outline color="primary" onClick={redirectToDashboard}>
          <FormattedMessage id="ProxyComponents.TimelineDetails.GoToDashboard" />
        </Button>
        {timelinesData?.find((p) => p.id === timelineId) && (
          <Button color="primary" onClick={redirectToTimeline}>
            <FormattedMessage id="ProxyComponents.TimelineDetails.GoToTimeline" />
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};

export default TimelineDetailsAnnotationProxyModal;
