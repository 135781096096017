import { unlinkTimeline } from 'api/graspManagement';

import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useNavigate } from 'react-router';

import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

function useTimelineDelete(userId: string, successFb?: () => void) {
  const navigate = useNavigate();

  return useMutationWithResultNotification({
    asyncApiFunction: async (timelineId: string) => {
      await unlinkTimeline(userId, timelineId);
    },
    onSuccess: () => {
      successFb && successFb();
      displaySuccessNotification('TimelineDetails.timelineSuccessfullyDeleted');
      navigate(`/dashboard`);
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(errorArray, 'TimelineDetails.errors');
    },
    resetOnResult: true,
  });
}

export default useTimelineDelete;
