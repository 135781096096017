import { deleteAllUserRolesInOrganization } from 'api/membership';
import { IMutationProps } from 'interfaces/ui';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

function useDeleteAllUserRolesInOrganization(
  organizationId: string,
  { successFb }: IMutationProps
) {
  return useMutationWithResultNotification({
    asyncApiFunction: async (userId: string) => {
      await deleteAllUserRolesInOrganization(organizationId, userId);
    },
    resetOnResult: true,
    onSuccess: () => {
      displaySuccessNotification('MyOrganizations.users.deleteAllRolesSuccess');
      successFb && successFb();
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'MyOrganizations.users.deleteAllRolesErrors'
      );
    },
  });
}

export default useDeleteAllUserRolesInOrganization;
