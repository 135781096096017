import useRequiredParams from '../../../hooks/useRequiredParams';
import useOrganizationDetails from '../../../api/queries/organizations/useOrganizationDetails';
import useCaseGroups from '../../../api/queries/caseGroups/useCaseGroups';
import useUngroupedCasesCaseGroup from '../../../api/queries/caseGroups/useUngroupedCasesCaseGroup';
import { useInfiniteProjects } from '../../../api/queries/projects/useInfiniteProjects';
import useCaseCategories from '../../../api/queries/cases/useCaseCategories';
import useTreatmentPlans from '../../../api/queries/conversations/useTreatmentPlans';

export const useCaseGroupsCommonData = () => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const organizationQuery = useOrganizationDetails({
    params: { organizationId },
    options: {
      enabled: false,
      staleTime: Infinity,
    },
  });

  const caseGroupsQuery = useCaseGroups({
    params: { organizationId },
    options: {
      enabled: false,
      staleTime: Infinity,
    },
  });

  const ungroupedCasesCaseGroupQuery = useUngroupedCasesCaseGroup({
    params: { organizationId },
    options: {
      enabled: false,
      staleTime: Infinity,
    },
  });

  const projectsQuery = useInfiniteProjects({
    params: {
      organizationId,
      pageSize: 5000,
    },
    options: { enabled: false, staleTime: Infinity },
  });

  const caseCategoriesQuery = useCaseCategories({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const treatmentPlansQuery = useTreatmentPlans({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  return {
    organization: organizationQuery,
    caseGroups: caseGroupsQuery,
    ungroupedCasesCaseGroup: ungroupedCasesCaseGroupQuery,
    projects: projectsQuery,
    caseCategories: caseCategoriesQuery,
    treatmentPlans: treatmentPlansQuery,
  };
};
