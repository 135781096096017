import ModalQuestion from 'components/ModalQuestion';
import ModalAnswers from 'components/ModalAnswers';
import {
  AssessmentQuestion,
  IAssessmentTemplate,
  IUserAssessments,
} from 'interfaces/assessmentForms';
import { returnIconForQuestion } from 'helpers/utils/icons';
import StepsQuestionForm from './StepsQuestionForm';
import { AssessmentFormValues } from 'interfaces/ui';
import { FormGroup } from 'reactstrap';
import {
  createFormObjectToSent,
  verifyVisibleExpression,
} from '../../helpers/utils/assessmentForms';
import { SetStepAction } from '../../modules/TimelineDetails/components/tabs/DayByDayTab/components/QuestionModal/QuestionModalForm';
import {
  IConversationTemplatePlainTextItem,
  IFormConversationTemplate,
} from '../../interfaces/conversations';

type QuestionFormProps = {
  values: AssessmentFormValues;
  setValues: (values: any) => void;
  form:
    | IUserAssessments
    | IAssessmentTemplate
    | IFormConversationTemplate
    | undefined;
  isMobile?: boolean;
  isStep?: boolean;
  step: number;
  setStep: (val: number) => void;
  canEdit: boolean;
  bigQuestion?: boolean;
  canDisplayQuestionIndicator?: boolean;
  lastSetStepAction: SetStepAction | null;
};

const QuestionForm = ({
  form,
  setValues,
  values,
  isMobile = false,
  isStep = false,
  step,
  setStep,
  canEdit,
  lastSetStepAction,
  bigQuestion = false,
  canDisplayQuestionIndicator,
}: QuestionFormProps) => {
  const formData: JSX.Element[] = [];

  const questions = form?.questions as
    | (AssessmentQuestion | IConversationTemplatePlainTextItem)[]
    | undefined;

  if ((isMobile || isStep || form?.renderAsStepInForm) && !!values) {
    return (
      <StepsQuestionForm
        step={step}
        setStep={setStep}
        values={values}
        setValues={setValues}
        form={form}
        isMobile={isMobile}
        canEdit={canEdit}
        bigQuestion={bigQuestion}
        canDisplayQuestionIndicator={canDisplayQuestionIndicator}
        lastSetStepAction={lastSetStepAction}
      />
    );
  }

  if (values !== undefined) {
    questions?.forEach((question) => {
      if (question['Text'] !== undefined) {
        const formGroup = (
          <div className="ConversationPlainText">
            <span>{question['Text']}</span>
          </div>
        );
        formData.push(formGroup);
        return;
      }

      const answers = (question as AssessmentQuestion).answers.map(
        (answerItem) => {
          return {
            title: answerItem.title,
            description: answerItem.description,
            value: answerItem.guid,
            sentiment: answerItem.sentiment,
            sentiment_score: answerItem.sentiment_score,
          };
        }
      );

      if (
        form === undefined ||
        verifyVisibleExpression((question as AssessmentQuestion).visible, {
          ...form,
          questions: createFormObjectToSent(
            values,
            form.questions as (
              | AssessmentQuestion
              | IConversationTemplatePlainTextItem
            )[],
            null
          ),
        })
      ) {
        const handleAnswerChange = (value: string) => {
          setValues({
            ...values,
            [question.guid]: value,
          });
        };

        const formGroup = (
          <FormGroup style={{ border: '1px solid #407dbb', padding: '2rem' }}>
            <ModalQuestion
              key={'question--' + question.guid}
              imageSrc={returnIconForQuestion(question.icon)}
              header={question.title}
              question={question.description}
              showDescription={(question as AssessmentQuestion).showDescription}
              showIcon={(question as AssessmentQuestion).showIcon}
              showTitle={(question as AssessmentQuestion).showTitle}
              bigQuestion={bigQuestion}
            />
            <ModalAnswers
              legendDescriptions={
                (question as AssessmentQuestion).legendDescriptions ?? []
              }
              canEdit={canEdit}
              guid={question.guid}
              type={question.type}
              key={'answers--' + question.guid}
              name={question.title}
              items={answers}
              showSentimentScale={
                (question as AssessmentQuestion).showSentimentScale
              }
              answer={values[question.guid]}
              minNumberValue={(question as AssessmentQuestion).minNumberValue}
              placeholder={question.answerPlaceHolder}
              maxLength={(question as AssessmentQuestion).answerMaxLength}
              maxNumberValue={(question as AssessmentQuestion).maxNumberValue}
              onAnswerChange={handleAnswerChange}
            />
          </FormGroup>
        );
        formData.push(formGroup);
      }
    });
  }

  return <>{formData}</>;
};

export default QuestionForm;
