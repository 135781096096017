import React from 'react';
import { ICaseGroupFormValues } from '../../hooks/useCreateCaseGroupForm';
import InputFieldWithoutImage from '../../../../../../components/InputFieldWithoutImage';
import EditableInput from '../../../../../../components/EditableInput/EditableInput';
import { useCreateCaseGroupStartingDateField } from './hooks/useCreateCaseGroupStartingDateField';

type CreateCaseGroupStartingDateProps = {
  values: ICaseGroupFormValues;
  setValues: (
    values: React.SetStateAction<ICaseGroupFormValues>,
    shouldValidate?: boolean | undefined
  ) => void;
  translations: any;
};

const CreateCaseGroupStartingDateField = ({
  values,
  setValues,
  translations,
}: CreateCaseGroupStartingDateProps) => {
  const { handleChange } = useCreateCaseGroupStartingDateField(
    values,
    setValues
  );

  return (
    <InputFieldWithoutImage
      gray={true}
      noMargin={true}
      labelTranslationId={translations.startingDateLabel}
      inputId="starting_date"
    >
      <EditableInput
        type="date"
        required={true}
        placeholder={translations.startingDatePlaceholder}
        id="starting_date"
        name="starting_date"
        value={values.starting_date}
        onEdit={handleChange}
        debounceEnabled={false}
      />
    </InputFieldWithoutImage>
  );
};

export default CreateCaseGroupStartingDateField;
