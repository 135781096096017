import { TabLayoutType } from '../../../../../../../../components/layouts/TabLayout';
import CaseCategoriesTab from '../../../../tabs/CaseCategoriesTab';
import FunctionalitiesTab from '../../../../tabs/FunctionalitiesTab';
import GraspSettingsTab from '../../../../tabs/GraspSettingsTab';
import ObservationMeaningsTab from '../../../../tabs/ObservationMeaningsTab';
import SymptomsTab from '../../../../tabs/SymptomsTab';
import TrendVisualizationsTab from '../../../../tabs/TrendVisualizationsTab';
import AccessProfilesTab from '../../tabs/AccessProfilesTab';
import EducationTab from '../../tabs/EducationTab';
import FormsTab from '../../tabs/FormsTab';
import GraspsTab from '../../tabs/GraspsTab';
import InfoTab from '../../tabs/InfoTab';
import OtherDevicesTab from '../../tabs/OtherDevicesTab';
import ReportSchedulesTab from '../../tabs/ReportSchedulesTab';
import UsersTab from '../../tabs/UsersTab';
import { DentalOrganizationTabsValues } from '../../types';

type TabSelectorProps = {
  value: DentalOrganizationTabsValues;
};

const TabSelector = ({ value }: TabSelectorProps) => {
  switch (value) {
    case DentalOrganizationTabsValues.Info:
      return <InfoTab />;
    case DentalOrganizationTabsValues.Grasps:
      return <GraspsTab />;
    case DentalOrganizationTabsValues.GraspSettings:
      return <GraspSettingsTab type={TabLayoutType.Breadcrumb} />;
    case DentalOrganizationTabsValues.CaseCategories:
      return <CaseCategoriesTab type={TabLayoutType.Breadcrumb} />;
    case DentalOrganizationTabsValues.OtherDevices:
      return <OtherDevicesTab />;
    case DentalOrganizationTabsValues.Users:
      return <UsersTab />;
    case DentalOrganizationTabsValues.AccessProfiles:
      return <AccessProfilesTab />;
    case DentalOrganizationTabsValues.TrendVisualizations:
      return <TrendVisualizationsTab type={TabLayoutType.Breadcrumb} />;
    case DentalOrganizationTabsValues.Symptoms:
      return <SymptomsTab type={TabLayoutType.Breadcrumb} />;
    case DentalOrganizationTabsValues.ObservationMeanings:
      return <ObservationMeaningsTab type={TabLayoutType.Breadcrumb} />;
    case DentalOrganizationTabsValues.Functionalities:
      return <FunctionalitiesTab type={TabLayoutType.Breadcrumb} />;
    case DentalOrganizationTabsValues.Forms:
      return <FormsTab />;
    case DentalOrganizationTabsValues.Education:
      return <EducationTab innerTitle={false} innerSubtitle={false} />;
    case DentalOrganizationTabsValues.ReportSchedules:
      return <ReportSchedulesTab />;
  }
};

export default TabSelector;
