import { getUserActivity } from 'api/patients';
import QueryKeys from 'api/queries/queryKeys';
import { format } from 'date-fns';
import { IActivity } from 'interfaces/activities';
import { UserQueryParams } from 'interfaces/queryParams';
import { IQueryRequest, ODataParamType } from 'interfaces/request';
import { DateRange } from 'react-day-picker';
import { useQuery } from 'react-query';

interface UseUserActivitiesParams extends UserQueryParams {
  dateRangeObject: DateRange | null;
}

function useUserActivities({
  params,
  options,
}: IQueryRequest<UseUserActivitiesParams>) {
  const { userId, dateRangeObject } = params;
  return useQuery({
    ...options,
    queryFn: async () => {
      let oDataQueryParams: ODataParamType[] = [];

      if (dateRangeObject?.from && dateRangeObject?.to) {
        oDataQueryParams = [
          {
            key: 'CreatedAt',
            operator: 'ge',
            value: dateRangeObject.from.toISOString(),
          },
          {
            key: 'CreatedAt',
            operator: 'lt',
            value: dateRangeObject.to.toISOString(),
          },
        ];
      }

      const { data } = await getUserActivity(
        userId,
        {
          page: 1,
          page_size: 1000,
          sort: 'CreatedAt',
        },
        oDataQueryParams
      );

      let formattedActivities: {
        [key: string]: IActivity[];
      } = {};

      const orderedArray = data.data.sort((prevActivity, nextActivity) => {
        if (prevActivity.time_stamp > nextActivity.time_stamp) return -1;
        if (prevActivity.time_stamp < nextActivity.time_stamp) return 1;
        return 0;
      });

      orderedArray.forEach((activity) => {
        const dateWithoutHours = format(
          new Date(activity.time_stamp),
          'dd.MM.yyyy'
        );

        const existingObject = formattedActivities[dateWithoutHours]
          ? formattedActivities[dateWithoutHours]
          : [];

        formattedActivities[dateWithoutHours] = [...existingObject, activity];
      });

      return formattedActivities;
    },
    queryKey: [QueryKeys.getUserActivities, userId, dateRangeObject],
  });
}

export default useUserActivities;
