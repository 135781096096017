import { GroupSelectionIdentifiers } from '../hooks/useRowSelection';
import { MemberCaseAccess } from './caseAccesses';
import { IBaseCaseGroup } from './caseGroups';
import { RegisterFormKeys } from './enums';
import { IPersonality } from './personalities';
import { MessageCommunicationChannel } from './submissions';
import { ICaseSymptomDefinitionWithDefaultInformation } from './symptoms';
import {
  ITimeline,
  ITimelineExtended,
  ITimelineObservationDates,
  TimeRange,
} from './timeline';
import { TrendVisualizationTemplate } from './trendVisualizations';
import { ICaseProcedure, ICaseProcedureActivity } from './caseHistory';
import { INameLocalized } from './ui';

type CategoryType = {
  code: string;
  name: string;
};

type DefaultInCase = {
  member_visualization_template: TrendVisualizationTemplate | null;
  patient_visualization_template: TrendVisualizationTemplate | null;
  time_range: TimeRange | null;
  feedback_average_summary_visible: boolean;
};

export interface IStrictCase {
  id: string;
  title: string;
  description: string;
  already_shared: boolean;
  already_requested: boolean;
}

export interface ICaseDetails {
  id: string;
  title: string;
  description: string;
  type: CaseTrackingType;
  created_at: string;
  category: IBaseCategory;
  category_id: string;
  sub_category: IBaseCategory | null;
  sub_category_id: string | null;
  status: string;
  last_update: string;
  timelines: ITimelineExtended[];
  owner: IPersonality;
  participants: ICaseParticipant[];
  dashboard_enabled: boolean;
  dashboard_as_default_conversation: boolean;
  exercises_enabled: boolean;
  triggered_observation_enabled: boolean;
  expected_feedback_frequency: number;
  expected_synchronization_frequency: number;
  participant_feedback_date: string | null;
  participant_last_synchronization_date: string | null;
  default_setup: DefaultInCase;
  participant_synchronization_status: CasesParticipantFeedbackStatus;
  project_submission_id: string | null;
  updated_at: string;
  start_date: string | null;
  end_date: string | null;
  daily_exercise_duration_in_minutes: number;
}

export interface ICasePhaseHistory {
  case_id: string;
  treatment_plan_id: string;
  treatment_phase_id: string;
  display_code: string;
  date_from: string;
  date_to: string | null;
  suggested_date_to: string | null;
  sequence: number;
}

export enum CaseTrackingType {
  Therapy = 'Therapy',
  Change = 'Change',
  Research = 'Research',
  MomentarySession = 'MomentarySession',
  Generic = 'Generic',
  Buddy = 'Buddy',
  AwareSession = 'AwareSession',
}

export interface ICaseParticipant {
  personality: IPersonality;
  timeline: ITimeline;
}

export interface ICreateCaseBody {
  title: string;
  description: string;
  category_id: string;
  sub_category_id: string;
  expected_feedback_frequency_in_hours: number;
  status: number;
  type: number;
}

export interface ICase {
  id: string;
  title: string;
  description: string;
  type: string;
  status: CaseStatus;
  number: number;
  project_submission_id: string | null;
  category_id: string;
  sub_category_id: string | null;
}

export interface IBaseCategory {
  id: string;
  code: string;
  name: string;
  name_no: string;
  name_en: string;
  icon: string;
}
export interface ICategory extends IBaseCategory {
  sub_categories: IBaseCategory[];
}
export interface IRequestShareTimelineResponse {
  id: string;
  organization_name: string;
  status: string;
  title: string;
  participant: IPersonality;
}

export type assignParticipantToCaseBodyType = {
  addMasterTimeline: boolean;
};

export enum DescriptionFormKeys {
  SUB_CATEGORY = 'caseSubCategory',
  DESCRIPTION = 'caseDescription',
  CATEGORY = 'caseCategory',
  TITLE = 'caseTitle',
  SHARE_MODE = 'shareMode',
}

export type CaseFormType = {
  [RegisterFormKeys.FIRST_NAME]: string;
  [RegisterFormKeys.LAST_NAME]: string;
  [RegisterFormKeys.EMAIL]: string;
  [RegisterFormKeys.ADDRESS]: string;
  [RegisterFormKeys.GENDER]: string;
  [RegisterFormKeys.BIRTH_DATE]: string;
  [RegisterFormKeys.SSN]: string;
  [RegisterFormKeys.PHONE_NUMBER]: string;
  [DescriptionFormKeys.DESCRIPTION]: string;
  [DescriptionFormKeys.TITLE]: string;
  [DescriptionFormKeys.CATEGORY]: string;
  [DescriptionFormKeys.SHARE_MODE]: string;
  [DescriptionFormKeys.SUB_CATEGORY]: string;
  socialSecurity: boolean;
  definitions: ICaseSymptomDefinitionWithDefaultInformation[];
  isDefinitionInitialized: boolean;
};

export type CaseFormErrors = {
  [RegisterFormKeys.FIRST_NAME]: string;
  [RegisterFormKeys.LAST_NAME]: string;
  [RegisterFormKeys.EMAIL]: string;
  [RegisterFormKeys.ADDRESS]: string;
  [RegisterFormKeys.GENDER]: string;
  [RegisterFormKeys.BIRTH_DATE]: string;
  [RegisterFormKeys.SSN]: string;
  [RegisterFormKeys.PHONE_NUMBER]: string;
  [DescriptionFormKeys.DESCRIPTION]: string;
  [DescriptionFormKeys.TITLE]: string;
  [DescriptionFormKeys.CATEGORY]: string;
  [DescriptionFormKeys.SHARE_MODE]: string;
  [DescriptionFormKeys.SUB_CATEGORY]: string;
  socialSecurity: string;
  meanings: string;
  definitions: string;
};

export interface ICaseListItem extends ICase {
  created: string;
  last_update: string;
  participants: ICaseParticipant[];
  status: CaseStatus;
  case_group: IBaseCaseGroup | null;
  category: CategoryType;
  sub_category: CategoryType | null;
  participant_feedback_date: Date | null;
  participant_feedback_status: CasesParticipantFeedbackStatus;
  participant_last_synchronization_date: Date | null;
  participant_synchronization_status: CasesParticipantFeedbackStatus;
  timelines: ITimelineObservationDates[];
  number: number;
}

export enum ShareMode {
  SHARE_DATA = 'shareData',
  SEND_REQUEST = 'sendRequest',
}

export enum CaseStatus {
  Open = 'Open',
  Pending = 'Pending',
  Closed = 'Closed',
}

export enum CasesParticipantFeedbackStatus {
  NotSpecified = 'NotSpecified',
  Missing = 'Missing',
  Overdue = 'Overdue',
  AlmostUpToDate = 'AlmostUpToDate',
  UpToDate = 'UpToDate',
}

export interface ICaseFilters {
  statuses?: string[];
  categoryId?: string;
  subCategoryId?: string;
  feedbackStatus?: CasesParticipantFeedbackStatus;
  synchronizationStatus?: CasesParticipantFeedbackStatus;
  caseGroupId?: string;
}

export interface ICaseFiltersWithBlindSearch extends ICaseFilters {
  blind_search?: string;
}

type ICaseFilterWithoutCategory = Omit<
  ICaseFiltersWithBlindSearch,
  | 'categoryId'
  | 'subCategoryId'
  | 'feedbackStatus'
  | 'synchronizationStatus'
  | 'caseGroupId'
>;
export interface ICaseFiltersRequest extends ICaseFilterWithoutCategory {
  category_id?: string;
  sub_category_id?: string;
  group_id?: string;
  participant_feedback_status?: CasesParticipantFeedbackStatus;
  participant_synchronization_status?: CasesParticipantFeedbackStatus;
}

type AssessmentFormsQuestionHistoryFormBody = {
  formCode: string;
  questions: string[];
};

export interface IGenerateAssessmentFormsQuestionsHistoryDataBody {
  dateFrom: string | null;
  dateTo: string | null;
  forms: AssessmentFormsQuestionHistoryFormBody[];
}

export interface IAssessmentQuestionHistoryEntry {
  userId: string;
  formCode: string;
  formTitle: string;
  questions: AssessmentQuestionHistoryEntryItem[];
}

export type AssessmentQuestionHistoryEntryItem = {
  questionId: string;
  questionTitle: string;
  questionColor: string;
  answerHistory: AssessmentQuestionHistoryEntryAnswer[];
};

export type AssessmentQuestionHistoryEntryAnswer = {
  title: string | null;
  date: string;
  value?: number;
};

export interface ISendMessageToCaseParticipant {
  selected_all: boolean;
  included: GroupSelectionIdentifiers[];
  excluded: GroupSelectionIdentifiers[];
  messageTitle: string | null;
  messageBody: string;
  communicationChannel: MessageCommunicationChannel[] | null;
  filters: ICaseFiltersWithBlindSearch | null;
}

export type CaseHeaderWithMembers = {
  id: string;
  number: number;
  title: string;
  description: string;
  type: string;
  last_update: string;
  status: CaseStatus;
  members_with_access: MemberCaseAccess[];
};

export interface ICaseProcedureWithActivitiesLocalized
  extends ICaseProcedure,
    INameLocalized {
  short_description: string;
  activities: ICaseProcedureActivityLocalized[];
}

export interface ICaseProcedureActivityLocalized
  extends ICaseProcedureActivity,
    INameLocalized {}
