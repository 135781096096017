import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { useTypedContext } from '../../../../../../../../../../../../hooks/useTypedContext';
import { DashboardSelectionHelpers } from '../../../../../../helpers/selection';
import { DashboardDataReducerContext } from '../../../../../../types';

type AwareCasesFilterItemProps = {
  selected?: boolean;
  labeled?: boolean;
  onToggle?: () => void;
  onAsyncToggle?: () => Promise<void>;
  onRemove?: () => void;
  onAsyncRemove?: () => Promise<void>;
};

const AwareCasesFilterItem = ({
  children,
  selected = false,
  labeled = false,
  onToggle = undefined,
  onAsyncToggle = undefined,
  onRemove = undefined,
  onAsyncRemove = undefined,
}: PropsWithChildren<AwareCasesFilterItemProps>) => {
  const [dashboardData] = useTypedContext(DashboardDataReducerContext);
  const globalSelected = DashboardSelectionHelpers.IsGlobalSelected(
    dashboardData.template.filters
  );

  const selectedMode = selected && !globalSelected;

  const handleRemove = async () => {
    onRemove?.();
    await onAsyncRemove?.();
  };

  const handleToggle = async () => {
    onToggle?.();
    await onAsyncToggle?.();
  };

  return (
    <div
      onClick={handleToggle}
      className={classNames('AwareCasesFilterItem', {
        'AwareCasesFilterItem--selected': selectedMode,
        'AwareCasesFilterItem--labeled': labeled,
      })}
    >
      <div className="text-nowrap">{children}</div>
      {(onRemove || onAsyncRemove) && (
        <FontAwesomeIcon onClick={handleRemove} icon={faTimes} />
      )}
    </div>
  );
};

export default AwareCasesFilterItem;
