import useEditOrganizationGrasp from 'api/mutations/grasps/useEditOrganizationGrasp';
import useInfiniteOrganizationLocations from 'api/queries/organizations/useInfiniteOrganizationLocations';
import FieldInput from 'components/FieldInput';
import FieldSelect from 'components/FieldSelect';
import LoadingButton from 'components/LoadingButton';
import { Formik, Field } from 'formik';
import {
  colorOptions,
  graspStatusOptions,
} from 'helpers/utils/translationObject';
import { validateEmpty } from 'helpers/utils/validators';
import { IInfiniteGraspOrganization, mappedStatus } from 'interfaces/grasps';
import { IPatchObject } from 'interfaces/request';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

type CreateGraspModalProps = {
  organizationId: string;
  closeModal: () => void;
  editGraspData: IInfiniteGraspOrganization | null;
  refetchGrasps: () => void;
};

const EditGraspModal = ({
  organizationId,
  closeModal,
  editGraspData,
  refetchGrasps,
}: CreateGraspModalProps) => {
  const intl = useIntl();

  const isOpen = !!editGraspData;

  const successFb = () => refetchGrasps();

  const { mutate: editGraspMutation, isLoading: isEditLoading } =
    useEditOrganizationGrasp(organizationId, {
      successFb,
    });

  const { locations } = useInfiniteOrganizationLocations({
    params: {
      organizationId,
      pageSize: 100,
    },
  });

  const endpointOptions = locations.map(({ id, name }) => {
    return {
      value: id,
      text: name,
    };
  });

  const initialValues = {
    physical_grasp_uuid: editGraspData?.physical_grasp_uuid ?? '',
    hex_color: editGraspData?.hex_color?.toUpperCase() ?? '',
    name: editGraspData?.name ?? '',
    status: editGraspData?.status ?? '',
    serial_number: editGraspData?.serial_number ?? '',
    location_id: editGraspData?.location_id ?? '',
  };

  const validation = (values: typeof initialValues) => {
    const { location_id, ...restOfValues } = values;

    return validateEmpty<typeof restOfValues>(restOfValues, intl);
  };

  const onSubmit = (values: typeof initialValues) => {
    const patchObjects: IPatchObject[] = [];

    const keys = Object.keys(values);

    keys.forEach((singleKey) => {
      if (
        values[singleKey] &&
        editGraspData &&
        editGraspData[singleKey] !== values[singleKey]
      ) {
        let value = values[singleKey];
        if (singleKey === 'status' && values[singleKey]) {
          value = mappedStatus[values[singleKey]];
        }

        patchObjects.push({
          value: value,
          op: 'replace',
          path: `/${singleKey}`,
        });
      }
    });

    editGraspData &&
      editGraspMutation({
        graspId: editGraspData.grasp_id,
        patchData: patchObjects,
      });

    closeModal();
  };

  return (
    <Modal isOpen={isOpen} toggle={closeModal}>
      <ModalHeader>
        <FormattedMessage id="MyOrganizations.grasps.modal.headerEdit" />
      </ModalHeader>
      <Formik
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validation}
      >
        {({ isValid, submitForm }) => (
          <>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="grasp_name">
                  <FormattedMessage id="MyOrganizations.grasps.modal.name" />
                </Label>
                <Field
                  required
                  id="grasp_name"
                  name="name"
                  type="text"
                  component={FieldInput}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="serial_number">
                  <FormattedMessage id="MyOrganizations.grasps.modal.serialNumber" />
                </Label>
                <Field
                  required
                  id="serial_number"
                  name="serial_number"
                  type="text"
                  component={FieldInput}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="physical_grasp_uuid">
                  <FormattedMessage id="MyOrganizations.grasps.modal.physicalGraspUuid" />
                </Label>
                <Field
                  required
                  id="physical_grasp_uuid"
                  name="physical_grasp_uuid"
                  type="text"
                  component={FieldInput}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="hex_color">
                  <FormattedMessage id="MyOrganizations.grasps.modal.color" />
                </Label>
                <Field
                  required
                  id="hex_color"
                  name="hex_color"
                  component={FieldSelect}
                  options={colorOptions}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="grasp_status">
                  <FormattedMessage id="MyOrganizations.grasps.modal.status" />
                </Label>
                <Field
                  required
                  id="grasp_status"
                  name="status"
                  component={FieldSelect}
                  displayEmpty
                  options={graspStatusOptions}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="location_id">
                  <FormattedMessage id="MyOrganizations.grasps.modal.endpoint" />
                </Label>
                <Field
                  id="location_id"
                  name="location_id"
                  component={FieldSelect}
                  displayEmpty
                  options={endpointOptions}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <LoadingButton
                disabled={!isValid}
                type="submit"
                onClick={submitForm}
                isLoading={isEditLoading}
              >
                <FormattedMessage id="General.confirm" />
              </LoadingButton>
            </ModalFooter>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default EditGraspModal;
