import { IAwareSessionData } from '../../../../../../../../../../../interfaces/awareSessions';
import useRequiredParams from '../../../../../../../../../../../hooks/useRequiredParams';
import useUrlSearch from '../../../../../../../../../../../hooks/useUrlSearch';
import useAwareSessionsForPatientNumber from '../../../../../../../../../../../api/queries/awareSessions/useAwareSessionsForPatientNumber';
import { useEffect } from 'react';

export const useSessionChildren = (
  session: IAwareSessionData,
  selected: boolean
) => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const query = useUrlSearch();
  const aggregated = query.get('aggregated');

  const { data: childrenData, refetch: patientNumberQueryRefetch } =
    useAwareSessionsForPatientNumber({
      params: {
        organizationId,
        patientNumber: session.patient_number ?? '',
        aggregated: !!aggregated,
      },
      options: { enabled: false, staleTime: Infinity },
    });

  useEffect(() => {
    (async () => {
      if (!selected) return;
      await patientNumberQueryRefetch();
    })();
  }, [patientNumberQueryRefetch, selected]);

  return !childrenData
    ? undefined
    : childrenData.sort(
        (a, b) =>
          new Date(b.start_date).getTime() - new Date(a.start_date).getTime()
      );
};
