import { useMemo } from 'react';
import {
  DashboardFilter,
  DashboardFilterType,
  IRangeDashboardFilter,
} from '../../../../../../../../../../../../../interfaces/dashboards';
import { SequenceIdentified } from '../../../../../../../../../../../../../interfaces/ui';
import { DashboardFilterHelpers } from '../../../../../../../helpers/filters';

export const useFilterSelected = (
  filter: SequenceIdentified<DashboardFilter>
): boolean => {
  return useMemo(() => {
    if (DashboardFilterHelpers.IsOptionsBased(filter))
      return filter.options.some((p) => p.selected);

    if (filter.type === DashboardFilterType.Range) {
      const rangeFilter = filter as IRangeDashboardFilter;
      return rangeFilter.options !== null;
    }

    return false;
  }, [filter]);
};
