import { useEffect, useState } from 'react';
import { useCommonData } from '../../../hooks/useCommonData';

export enum RangeSelectionMode {
  PeriodRange = 'PeriodRange',
  DateRange = 'DateRange',
}

export const useSelectionMode = () => {
  const { casePhaseHistory } = useCommonData();

  const [mode, setMode] = useState<RangeSelectionMode>(
    RangeSelectionMode.DateRange
  );

  const handleSelectPeriod = () => {
    setMode(RangeSelectionMode.PeriodRange);
  };

  const handleSelectRange = () => {
    setMode(RangeSelectionMode.DateRange);
  };

  useEffect(() => {
    if (!casePhaseHistory.data) return;

    setMode(
      casePhaseHistory.data.length === 0
        ? RangeSelectionMode.DateRange
        : RangeSelectionMode.PeriodRange
    );
  }, [casePhaseHistory.data]);

  return {
    mode,
    handleSelectPeriod,
    handleSelectRange,
  };
};
