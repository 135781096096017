import React, { useMemo } from 'react';
import ItemSection from '../../../components/ItemSection';
import organizationTrends from 'assets/images/trends_timeline/organization_trends.svg';
import SelectSection from '../../../components/SelectSection';
import { useIntl } from 'react-intl';
import { useCommonData } from 'modules/Organizations/views/OrganizationDetailsView/hooks/useCommonData';
import { useInitQueries } from 'hooks/queries/useInitQuery';

const OrganizationsTrends = () => {
  const intl = useIntl();

  const { organizationDetails, trendVisualizationTemplates } = useCommonData();
  useInitQueries([organizationDetails, trendVisualizationTemplates]);

  const organizationTitle = useMemo(() => {
    return intl.formatMessage({
      id: 'MyOrganizations.trendVisualizations.organization',
    });
  }, [intl]);

  const organizationDescription = useMemo(() => {
    return `${intl.formatMessage({
      id: 'MyOrganizations.trendVisualizations.organizationDescription',
    })} + ${organizationDetails.data?.name ?? ''}`;
  }, [intl, organizationDetails.data?.name]);

  return (
    <div className="TrendVisualizationsOrganizationsTrends">
      <div className="TrendVisualizationsOrganizationsTrends__header">
        <ItemSection
          icon={organizationTrends}
          textId={
            'MyOrganizations.trendVisualizations.trendsRelatedToTheOrganization'
          }
        />
      </div>
      <div className="TrendVisualizationsOrganizationsTrends__sections">
        <SelectSection
          title={organizationTitle}
          description={organizationDescription}
          type="organization"
          templates={trendVisualizationTemplates.data ?? []}
          hasHeader={true}
        />
      </div>
    </div>
  );
};

export default OrganizationsTrends;
