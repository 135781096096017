import useAddOrganizationFunctionality from 'api/mutations/functionalities/useAddOrganizationFunctionality';
import useDeleteOrganizationFunctionality from 'api/mutations/functionalities/useDeleteOrganizationFunctionality';
import useFunctionalities from 'api/queries/functionalities/useFunctionalities';
import useOrganizationFunctionalities from 'api/queries/functionalities/useOrganizationFunctionality';
import ListWithRemoveAddActions, {
  RowItem,
  RowItemWithDelete,
} from 'components/ListWithRemoveAddActions';
import React, { useMemo } from 'react';
import { isNorwegian } from 'translations/utils/cultures';
import useRequiredParams from '../../../../../../hooks/useRequiredParams';
import useRefreshOrganizationToken from '../../../../../../api/mutations/membership/useRefreshOrganizationToken';
import TabLayout from '../../../../../../components/layouts/TabLayout';
import { CommonTabProps } from '../types';

const FunctionalitiesTab = ({ type }: CommonTabProps) => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const { data: functionalitiesData, isLoading: isFunctionalitiesLoading } =
    useFunctionalities();

  const { mutateAsync: refreshToken, isLoading: isRefreshingToken } =
    useRefreshOrganizationToken({
      myOrganizationScreen: false,
    });

  const {
    data: organizationFunctionalitiesData,
    refetch: refetchOrgFunctionalities,
    isLoading: isOrganizationFunctionalitiesLoading,
  } = useOrganizationFunctionalities({ params: { organizationId } });

  const onSuccess = async () => {
    await refetchOrgFunctionalities();
    await refreshToken(organizationId);
  };

  const { mutateAsync: addMutation, isLoading: isAddLoading } =
    useAddOrganizationFunctionality(organizationId, onSuccess);

  const { mutateAsync: deleteMutation, isLoading: isDeleteLoading } =
    useDeleteOrganizationFunctionality(organizationId, onSuccess);

  const onAddClick = async (functionalityId: string) => {
    await addMutation(functionalityId);
  };

  const onRemoveClick = async (functionalityId: string) => {
    await deleteMutation(functionalityId);
  };

  const rowAddInfo = useMemo(() => {
    const addedItems: RowItemWithDelete[] = [];
    const removedItems: RowItem[] = [];

    functionalitiesData?.forEach((el) => {
      const orgFunctionality = organizationFunctionalitiesData?.find(
        (orgEl) => orgEl.functionality_id === el.id
      );

      const row: RowItem = {
        id: el.id,
        title: isNorwegian() ? el.name_no : el.name,
        description: isNorwegian() ? el.description_no : el.description,
      };

      if (orgFunctionality)
        addedItems.push({
          ...row,
          deleteId: orgFunctionality.functionality_id,
        });
      else removedItems.push(row);
    });

    return { addedItems, removedItems };
  }, [functionalitiesData, organizationFunctionalitiesData]);

  return (
    <TabLayout type={type} titlePrefix="Organizations.Functionalities">
      <ListWithRemoveAddActions
        addedItems={rowAddInfo.addedItems}
        removedItems={rowAddInfo.removedItems}
        onAddItem={onAddClick}
        onRemoveItem={onRemoveClick}
        emptyAddRowInfoTranslation="MyOrganizations.functionalities.noTemplatesToRemove"
        emptyRemoveRowInfoTranslation="MyOrganizations.functionalities.noFunctionalityToAdd"
        isLoading={
          isOrganizationFunctionalitiesLoading || isFunctionalitiesLoading
        }
        isActionLoading={isAddLoading || isDeleteLoading || isRefreshingToken}
        addedHeaderTranslation="MyOrganizations.functionalities.organizationFunctionalities"
        removedHeaderTranslation="MyOrganizations.functionalities.functionalitiesNotUsed"
      />
    </TabLayout>
  );
};

export default FunctionalitiesTab;
