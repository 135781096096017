import { useCallback } from 'react';
import { CreateProjectFormValues } from '../../../types/ProjectModal';
import { FormikErrors } from 'formik';
import { useIntl } from 'react-intl';

export const useProjectModalOverviewFormValidation = () => {
  const intl = useIntl();

  const validate = useCallback(
    (
      initialValues: CreateProjectFormValues,
      errors: FormikErrors<CreateProjectFormValues>
    ) => {
      if (initialValues.code.length === 0)
        errors['code'] = intl.formatMessage({
          id: 'Projects.ProjectModal.ProjectModalDetailsForm.invalidCode',
        });

      Object.entries(initialValues.title).forEach(([key, value]) => {
        if (value.length === 0) {
          if (!errors['title']) errors['title'] = {};

          errors['title'][key] = intl.formatMessage({
            id: 'Projects.ProjectModal.ProjectModalDetailsForm.invalidTitle',
          });
        }
      });

      Object.entries(initialValues.description).forEach(([key, value]) => {
        if (value.length === 0) {
          if (!errors['description']) errors['description'] = {};

          errors['description'][key] = intl.formatMessage({
            id: 'Projects.ProjectModal.ProjectModalDetailsForm.invalidDescription',
          });
        }
      });

      return errors;
    },
    [intl]
  );

  return {
    validate: validate,
  };
};
