import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

type YAxisProps = {
  height: number;
  dates: Date[];
  selectedDate: Date | null;
  selectDate: (date: Date) => void;
  yScaleFunction;
};

const YAxis = ({
  height,
  dates,
  selectedDate,
  selectDate,
  yScaleFunction,
}: YAxisProps) => {
  const intl = useIntl();

  const getDayWithTranslation = useCallback(
    (indexOfDay: number) => {
      const days = [
        intl.formatMessage({ id: 'General.weekDays.sun' }),
        intl.formatMessage({ id: 'General.weekDays.mon' }),
        intl.formatMessage({ id: 'General.weekDays.tue' }),
        intl.formatMessage({ id: 'General.weekDays.wed' }),
        intl.formatMessage({ id: 'General.weekDays.thu' }),
        intl.formatMessage({ id: 'General.weekDays.fri' }),
        intl.formatMessage({ id: 'General.weekDays.sat' }),
      ];

      return days[indexOfDay];
    },
    [intl]
  );

  return (
    <div
      className="BubbleChart__yaxis"
      style={{
        height: height + 'px',
        position: 'relative',
      }}
    >
      {dates.map((singleDate, index) => {
        const isDateSelected =
          selectedDate &&
          new Date(selectedDate).getTime() === new Date(singleDate).getTime();

        let yAxisClassName = 'BubbleChart__yaxis__item';
        if (isDateSelected)
          yAxisClassName += ' BubbleChart__yaxis__item--selected';

        const dateText = (
          <div>
            {`${singleDate.getDate().toString().padStart(2, '0')}.${(
              singleDate.getMonth() + 1
            )
              .toString()
              .padStart(2, '0')}`}
            <div
              className={`w-100 ${
                isDateSelected
                  ? 'BubbleChart__yaxis__item--selected'
                  : 'BubbleChart__yaxis__item'
              }__day`}
            >
              {getDayWithTranslation(singleDate.getDay())}
            </div>
          </div>
        );

        return (
          <div
            onClick={() => {
              selectDate(singleDate);
            }}
            key={`yaxis-${singleDate.getTime()}--${index}`}
            className={yAxisClassName}
            style={{
              position: 'absolute',
              top: yScaleFunction(singleDate.getTime()) + 'px',
            }}
          >
            {dateText}
          </div>
        );
      })}
    </div>
  );
};

export default YAxis;
