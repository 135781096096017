import React from 'react';
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap';
import useOpen from 'hooks/useOpen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/pro-light-svg-icons';

type AdditionalActionsDropdownProps = {
  dropdownItems: JSX.Element;
};

const AdditionalActionsDropdown = ({
  dropdownItems,
}: AdditionalActionsDropdownProps) => {
  const { toggle: toggleDropdown, isOpen: isDropdownOpen } = useOpen(false);

  return (
    <Dropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
      <DropdownToggle color="primary" outline={true}>
        <FontAwesomeIcon icon={faEllipsisV} />
      </DropdownToggle>
      <DropdownMenu container="body">{dropdownItems}</DropdownMenu>
    </Dropdown>
  );
};

export default AdditionalActionsDropdown;
