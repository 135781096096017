import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Col, Row } from 'reactstrap';
import usePatchOrganization from '../../../../../../../../api/mutations/organizations/usePatchOrganization';
import useOrganizationDetails from '../../../../../../../../api/queries/organizations/useOrganizationDetails';
import EditableInput from '../../../../../../../../components/EditableInput/EditableInput';
import SingleDescription from '../../../../../../../../components/SingleDescription';
import { getOrganizations } from '../../../../../../../../helpers/utils/auth';
import {
  organizationStatusOptions,
  organizationTypeOptions,
} from '../../../../../../../../helpers/utils/translationObject';
import useDescriptionEdit from '../../../../../../../../hooks/useDescriptionEdit';
import { OpenHookValues } from '../../../../../../../../hooks/useOpen';
import useRequiredParams from '../../../../../../../../hooks/useRequiredParams';
import { UserRolesInToken } from '../../../../../../../../interfaces/membership';
import { OrganizationType } from '../../../../../../../../interfaces/organizations';
import { IPatchObject } from '../../../../../../../../interfaces/request';
import { InputOption } from '../../../../../../../../interfaces/ui';

type OrganizationDetailsFormProps = {
  editMode: OpenHookValues;
};

export const mappedOrganizationTypes = {
  [OrganizationType.HealthcareProvider]: 1,
  [OrganizationType.EducationalInstitute]: 2,
  [OrganizationType.NonHealthcareBusinessOrCorporation]: 3,
  [OrganizationType.Other]: 4,
};

const OrganizationDetailsForm = ({
  editMode,
}: OrganizationDetailsFormProps) => {
  const intl = useIntl();

  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const { data: organizationData, refetch } = useOrganizationDetails({
    params: { organizationId },
    options: { enabled: false },
  });

  const successFb = async () => {
    await refetch();
  };

  const { mutate: patchOrganization } = usePatchOrganization(organizationId, {
    successFb,
  });

  const submitFunction = (params: IPatchObject[]) => {
    const formattedParams = params.map((el) => {
      if (el.path.includes('type')) {
        return {
          ...el,
          value: mappedOrganizationTypes[el.value as OrganizationType],
        };
      } else {
        return el;
      }
    });

    patchOrganization(formattedParams);
    editMode.toggle();
  };

  const { onCancel, onEdit, onSubmit } = useDescriptionEdit({
    cancelFb: editMode.toggle,
    submitFunction,
  });

  const organizationType = [
    { value: '', text: '' },
    ...organizationTypeOptions.map(({ value, translationId }) => ({
      value,
      text: intl.formatMessage({ id: translationId }),
    })),
  ];

  const organizationStatus = [
    { value: '', text: '' },
    ...organizationStatusOptions.map(({ value, translationId }) => ({
      value,
      text: intl.formatMessage({ id: translationId }),
    })),
  ];

  const organizations = getOrganizations();

  const filteredOrganizations = organizations?.filter(
    (org) =>
      org.role.includes(UserRolesInToken.ORGANIZATION_ADMIN) &&
      org.id !== organizationId
  );

  const validateCode = (value: string) => !value.includes(' ');

  const organizationNameOptions =
    filteredOrganizations?.map(({ id, name }) => {
      const option: InputOption = {
        value: id,
        text: name,
      };
      return option;
    }) ?? [];

  const editDisabled = !editMode.isOpen;

  return (
    <div className="sharedGraspsPatient h-auto p-4">
      <Row>
        <Col md={6} className="graspDetailsContainer__line">
          <SingleDescription label={'MyOrganizations.name'} labelFor="name">
            <EditableInput
              disabled={editDisabled}
              value={organizationData?.name ?? ''}
              name="name"
              onEdit={onEdit}
              debounceEnabled={false}
            />
          </SingleDescription>
          <SingleDescription label={'MyOrganizations.code'} labelFor="code">
            <EditableInput
              validation={validateCode}
              disabled={editDisabled}
              value={organizationData?.code ?? ''}
              name="code"
              onEdit={onEdit}
              debounceEnabled={false}
            />
          </SingleDescription>
          <SingleDescription
            label={'MyOrganizations.description'}
            labelFor="description"
          >
            <EditableInput
              disabled={editDisabled}
              value={organizationData?.description ?? ''}
              name="description"
              onEdit={onEdit}
              debounceEnabled={false}
            />
          </SingleDescription>
          <SingleDescription
            label={'MyOrganizations.MainOrganization'}
            labelFor="main_organization_id"
          >
            <EditableInput
              disabled={editDisabled}
              type="select"
              options={[{ text: '', value: '' }, ...organizationNameOptions]}
              name="main_organization_id"
              value={organizationData?.main_organization_id ?? ''}
              onEdit={onEdit}
              debounceEnabled={false}
            />
          </SingleDescription>
          <SingleDescription
            label={'MyOrganizations.AzureAdTenantId'}
            labelFor="active_directory_tenant_id"
          >
            <EditableInput
              disabled={editDisabled}
              value={organizationData?.active_directory_tenant_id ?? ''}
              name="active_directory_tenant_id"
              onEdit={onEdit}
              debounceEnabled={false}
            />
          </SingleDescription>
        </Col>
        <Col>
          <SingleDescription label="MyOrganizations.logo" labelFor="logo_url">
            <EditableInput
              disabled={editDisabled}
              value={organizationData?.logo_url ?? ''}
              name="logo_url"
              onEdit={onEdit}
              debounceEnabled={false}
            />
          </SingleDescription>
          <SingleDescription
            label="MyOrganizations.image"
            labelFor="imarge_url"
          >
            <EditableInput
              disabled={editDisabled}
              value={organizationData?.image_url ?? ''}
              name="image_url"
              onEdit={onEdit}
              debounceEnabled={false}
            />
          </SingleDescription>
          <SingleDescription label={'MyOrganizations.status'} labelFor="status">
            <EditableInput
              disabled={editDisabled}
              type="select"
              value={organizationData?.status}
              name="status"
              onEdit={onEdit}
              options={organizationStatus}
              debounceEnabled={false}
            />
          </SingleDescription>
          <SingleDescription label={'MyOrganizations.type'} labelFor="type">
            <EditableInput
              disabled={editDisabled}
              type="select"
              value={organizationData?.type}
              name="type"
              onEdit={onEdit}
              options={organizationType}
              debounceEnabled={false}
            />
          </SingleDescription>
          {!editDisabled && (
            <div className="d-flex justify-content-end mt-2">
              <Button className="me-2" color="danger" onClick={onCancel}>
                <FormattedMessage id="CaseCard.cancel" />
              </Button>
              <Button onClick={onSubmit}>
                <FormattedMessage id="CaseCard.submit" />
              </Button>
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default OrganizationDetailsForm;
