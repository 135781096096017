import { faCheck, faEdit, faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useTimelineShareRequestReject from 'api/mutations/timelines/useTimelineShareRequestReject';
import ButtonWithConfirmationModal from 'components/ButtonWithConfirmationModal';
import StatusInfo from 'components/StatusInfo';
import { StatusEnum } from 'interfaces/enums';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from 'modules/Notifications';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { QueryObserverResult, RefetchOptions } from 'react-query';
import { Button } from 'reactstrap';
import { ISharing } from '../../../../interfaces/personalities';

type SingleSharingRequestProps = {
  data: ISharing;
  onAccept: (caseId: ISharing) => void;
  onEdit: (caseId: string) => void;
  userId: string;
  refetchFunction: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<ISharing[]>>;
};

const SingleSharingRequestRow: React.FC<SingleSharingRequestProps> = ({
  data,
  onAccept,
  userId,
  refetchFunction,
  onEdit,
}) => {
  const intl = useIntl();

  const onAcceptButtonClick = () => onAccept(data);
  const onEditButtonClick = () => onEdit(data.id);

  const { mutate: reject, isLoading } = useTimelineShareRequestReject({
    userId,
    errorFb: () => {
      displayErrorNotification(
        `${intl.formatMessage({
          id: 'SharingRequests.rejectActionError',
        })}: ${data.title}`
      );
    },
    successFb: async () => {
      displaySuccessNotification(
        `${intl.formatMessage({
          id: 'SharingRequests.rejectActionFinishedSuccessfully',
        })}: ${data.title}`
      );
      await refetchFunction();
    },
  });

  const isButtonVisible = data.status !== StatusEnum.REJECTED;
  const isAlreadyAccepted = data.status === StatusEnum.APPROVED;

  const renderedTimelines = data.timelines
    .map((timeline) => timeline.name)
    .join(' , ');

  const onModalConfirmationReject = () => reject(data.id);

  return (
    <>
      <tr>
        <td>{data.organization_name}</td>
        <td>
          {data.created_by.first_name} {data.created_by.last_name}
        </td>
        <td>{data.title}</td>
        <td>
          <StatusInfo status={data.status} />
        </td>
        <td
          style={{
            width: '25%',
          }}
        >
          {renderedTimelines}
        </td>
        <td className="text-center">
          {isButtonVisible && (
            <>
              {!isAlreadyAccepted && (
                <Button
                  className="me-2 mb-2"
                  color="success"
                  onClick={onAcceptButtonClick}
                >
                  <FontAwesomeIcon icon={faCheck} className="me-1" />
                  <FormattedMessage id="SharingRequests.approve" />
                </Button>
              )}
              {isAlreadyAccepted && (
                <Button
                  className="me-2 mb-2"
                  color="primary"
                  onClick={onEditButtonClick}
                >
                  <FontAwesomeIcon icon={faEdit} className="me-1" />
                  <FormattedMessage id="General.edit" />
                </Button>
              )}
              <ButtonWithConfirmationModal
                color="danger"
                className="mb-2"
                header={
                  <FormattedMessage id="SharingRequests.rejectConfirmation" />
                }
                body={`${intl.formatMessage({
                  id: 'SharingRequests.rejectionConfirmationDescription',
                })}: ${data.title} ${intl.formatMessage({
                  id: 'SharingRequests.inOrganization',
                })}: ${data.organization_name}?`}
                onConfirmClick={onModalConfirmationReject}
                isLoading={isLoading}
              >
                <FontAwesomeIcon icon={faTimes} className="me-1" />
                <FormattedMessage id="SharingRequests.reject" />
              </ButtonWithConfirmationModal>
            </>
          )}
        </td>
      </tr>
    </>
  );
};

export default SingleSharingRequestRow;
