import { useCallback } from 'react';
import { useInitQuery } from '../../../../../../../hooks/queries/useInitQuery';
import { ConversationTemplateTriggerType } from '../../../../../../../interfaces/conversations';
import { useSchedulesCommonData } from '../../../../../hooks/useSchedulesCommonData';

export const useConversationTemplateDisplayNames = () => {
  const { conversationTemplates } = useSchedulesCommonData();

  useInitQuery(conversationTemplates);

  const getTemplateDisplayCode = useCallback(
    (templateId: string) => {
      return (
        conversationTemplates.data?.find((p) => p.id === templateId)
          ?.display_code ?? templateId
      );
    },
    [conversationTemplates]
  );

  const getTemplateTriggerType = useCallback(
    (templateId: string) => {
      const existingTemplate = conversationTemplates.data?.find(
        (p) => p.id === templateId
      );

      if (existingTemplate === undefined)
        return ConversationTemplateTriggerType.Time;

      return existingTemplate.trigger_type;
    },
    [conversationTemplates]
  );

  return {
    getTemplateDisplayCode: getTemplateDisplayCode,
    getTemplateTriggerType: getTemplateTriggerType,
  };
};
