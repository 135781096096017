import React from 'react';
import { Button, Col, FormGroup, Label, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { Field } from 'formik';
import SubCategorySelect from '../../../../../../../../../Cases/modals/CaseCardModal/components/SubCategoryModalSelect';
import FieldSelect from '../../../../../../../../../../components/FieldSelect';
import FieldInput from '../../../../../../../../../../components/FieldInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { MaterialModalFormValues } from '../../../../../../types';
import { useCommonParams } from '../../../../../../../../hooks/useCommonParams';
import { useTranslations } from '../../../../../../../../../../hooks/useTranslations';
import { useHandleRemoveRule } from './hooks/useHandleRemoveRule';
import { useOptions } from '../../../../../../../../../../hooks/useOptions';

type AddRulesStepItemProps = {
  index: number;
  values: MaterialModalFormValues;
  setValues: (data: MaterialModalFormValues) => void;
};

const AddRulesStepItem = ({
  index,
  values,
  setValues,
}: AddRulesStepItemProps) => {
  const { organizationId } = useCommonParams();
  const { wrapWithIntl, attachPrefix } = useTranslations(
    'Education.MaterialModal.MaterialModalBody.AddRulesStep.AddRulesStepItem'
  );

  const { availabilityStatus } = useOptions();

  const handleRemoveRule = useHandleRemoveRule(index, values, setValues);

  return (
    <Row className="w-100">
      <Col xs={4}>
        <FormGroup>
          <Label htmlFor={`rules[${index}].case_sub_category_id`}>
            <FormattedMessage id={attachPrefix('caseSubCategoryId')} />
          </Label>
          <Field
            id={`rules[${index}].case_sub_category_id`}
            name={`rules[${index}].case_sub_category_id`}
            caseCategoryPropName={`rules[${index}].case_category_id`}
            organizationId={organizationId}
            component={SubCategorySelect}
          />
        </FormGroup>
      </Col>
      <Col xs={4}>
        <FormGroup>
          <Label htmlFor={`rules[${index}].availability_status`}>
            <FormattedMessage id={attachPrefix('ruleAvailabilityStatus')} />
          </Label>
          <Field
            required={true}
            placeholder={wrapWithIntl(attachPrefix('ruleAvailabilityStatus'))}
            id={`rules[${index}].availability_status`}
            name={`rules[${index}].availability_status`}
            options={availabilityStatus()}
            displayEmpty={true}
            component={FieldSelect}
          />
        </FormGroup>
      </Col>
      <Col xs={3}>
        <FormGroup>
          <Label htmlFor={`rules[${index}].rule`}>
            <FormattedMessage id={attachPrefix('ruleContent')} />
          </Label>
          <Field
            required={true}
            placeholder={wrapWithIntl(attachPrefix('ruleContent'))}
            id={`rules[${index}].rule`}
            name={`rules[${index}].rule`}
            component={FieldInput}
          />
        </FormGroup>
      </Col>
      <Col xs={1} className="d-flex align-items-end">
        <Button
          className="mb-3"
          color="danger"
          outline={true}
          onClick={handleRemoveRule}
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </Col>
    </Row>
  );
};

export default AddRulesStepItem;
