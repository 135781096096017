import { MotionPathPlugin } from 'gsap/MotionPathPlugin';

export type Point = {
  x: number;
  y: number;
};

const startPoint = { x: 90, y: 100 };
const endPoint = { x: 50, y: 11 };

export type UseMountainPathProps = {
  values: number[];
  currentIdx: number;
  initialIdx: number;
};

export function useMountainPath({
  values,
  currentIdx,
  initialIdx,
}: UseMountainPathProps) {
  const pointsCount = values.length > 0 ? values.length : 7;
  const pointIdx = Math.min(currentIdx + 1, pointsCount);
  const endIdx = Math.min(initialIdx + 1, pointsCount);
  const points = generatePoints(pointsCount, startPoint, endPoint);
  const pathString = createPathString(points);
  const subPathString = createSubPathString(points, points[endIdx]);

  return { pointIdx, endIdx, pathString, subPathString, points };
}

// https://greensock.com/forums/topic/24223-draw-line-between-points-in-react-js/?do=findComment&comment=114894

function createPathString(points: Point[]): string {
  const rawPath = createRawPath(points);
  return MotionPathPlugin.rawPathToString(rawPath);
}

function createSubPathString(points: Point[], endPoint: Point): string {
  const pathString = createPathString(points);
  if (endPoint === points[points.length - 1]) return pathString;

  const endPointString = `${endPoint.x},${endPoint.y}`;
  const index = pathString.indexOf(endPointString);

  return pathString.substring(0, index + endPointString.length);
}

function createRawPath(points: Point[]): number[][] {
  return MotionPathPlugin.arrayToRawPath(points, { curviness: 0.2 });
}

function generatePoints(
  pointsCount: number,
  startPoint: Point,
  endPoint: Point
): Point[] {
  if (pointsCount < 2) return [startPoint, endPoint];

  const incrementX = Math.floor(50 / pointsCount);
  const incrementY = Math.floor(90 / (pointsCount + 1));

  const result: Point[] = [startPoint];
  for (let i = 0; i < pointsCount - 1; ++i) {
    const y = getY(incrementY, result[i].y, i);
    const x = getX(incrementX, result[i].x, i);
    result.push({ x, y });
  }

  return [...result, endPoint];
}

function getX(increment: number, previousX: number, index: number): number {
  let incrementX = increment;
  if (index % 3 === 1) incrementX = increment + 2;
  else if (index % 3 === 2) incrementX = increment - 2;
  else if (index === 0) incrementX = 0;

  if (previousX > 50) {
    const x = 100 - previousX + incrementX;
    return Math.min(x, 47);
  }
  const x = 100 - previousX - incrementX;
  return Math.max(x, 53);
}

function getY(increment: number, previousY: number, index: number): number {
  let incrementY = increment;
  if (index % 3 === 1) incrementY = increment + 2;
  else if (index % 3 === 2) incrementY = increment - 2;

  return previousY - incrementY;
}
