import { useMemo } from 'react';
import useOrganizationSymptoms from 'api/queries/symptoms/useOrganizationSymptoms';
import useAddSymptomToOrganization from 'api/mutations/symptoms/useAddSymptomToOrganization';
import useRemoveSymptomInOrganization from 'api/mutations/symptoms/useRemoveSymptomInOrganization';
import { isNorwegian } from 'translations/utils/cultures';
import ListWithRemoveAddActions, {
  RowItem,
  RowItemWithDelete,
} from 'components/ListWithRemoveAddActions';
import useRequiredParams from 'hooks/useRequiredParams';
import { CommonTabProps } from '../types';
import useSymptoms from '../../../../../../api/queries/symptoms/useSymptoms';
import TabLayout from '../../../../../../components/layouts/TabLayout';

const SymptomsTab = ({ type }: CommonTabProps) => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const { data: observationMeaningData, isLoading: observationMeaningLoading } =
    useSymptoms();

  const {
    data: organizationMeaningObservationData,
    refetch,
    isLoading: organizationObservationLoading,
  } = useOrganizationSymptoms({ params: { organizationId } });

  const onSuccess = () => refetch();

  const { mutate: addToOrganizationMutation, isLoading: isAddLoading } =
    useAddSymptomToOrganization(organizationId, onSuccess);

  const { mutate: removeFromOrganizationMutation, isLoading: isRemoveLoading } =
    useRemoveSymptomInOrganization(organizationId, onSuccess);

  const onRemoveClick = (id: string) => removeFromOrganizationMutation(id);
  const onAddClick = (id: string) => addToOrganizationMutation(id);

  const rowAddInfo = useMemo(() => {
    const addedItems: RowItemWithDelete[] = [];
    const removedItems: RowItem[] = [];

    observationMeaningData?.forEach((el) => {
      const meaningInOrganization = organizationMeaningObservationData?.find(
        (orgEl) => orgEl.symptom_id === el.id
      );

      const row: RowItem = {
        id: el.id,
        title: isNorwegian() ? el.name_no : el.name,
      };

      if (meaningInOrganization) {
        addedItems.push({ ...row, deleteId: meaningInOrganization.symptom_id });
        return;
      }

      removedItems.push(row);
    });

    return { addedItems, removedItems };
  }, [observationMeaningData, organizationMeaningObservationData]);

  return (
    <TabLayout type={type} titlePrefix="Organizations.Symptoms">
      <ListWithRemoveAddActions
        addedItems={rowAddInfo.addedItems}
        removedItems={rowAddInfo.removedItems}
        onAddItem={onAddClick}
        onRemoveItem={onRemoveClick}
        emptyAddRowInfoTranslation="Organizations.OrganizationDetails.Symptoms.noSymptomsToRemove"
        emptyRemoveRowInfoTranslation="Organizations.OrganizationDetails.Symptoms.noSymptomsToAdd"
        isLoading={organizationObservationLoading || observationMeaningLoading}
        isActionLoading={isAddLoading || isRemoveLoading}
        addedHeaderTranslation="Organizations.OrganizationDetails.Symptoms.symptomsInOrganization"
        removedHeaderTranslation="Organizations.OrganizationDetails.Symptoms.symptomsNotUsed"
      />
    </TabLayout>
  );
};

export default SymptomsTab;
