import { changeSelectedDate } from 'modules/TimelineDetails/actions';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTimelineDetailsSelectedDate } from 'store/reducerHooks';

export const useAxisTicks = () => {
  const dispatch = useDispatch();

  const selectedDate = useTimelineDetailsSelectedDate() ?? new Date();

  const onXAxisTick = useCallback(
    (payload: string) => {
      const datePayload = new Date(payload);
      if (isNaN(datePayload.getTime())) return;
      dispatch(changeSelectedDate(datePayload));
    },
    [dispatch]
  );

  return { initialValue: selectedDate, onXAxisTick };
};
