import { Bar, BarChart, ResponsiveContainer, XAxis } from 'recharts';
import blueSmileIcon from '../../../../../../../assets/images/buddy_case/blue_smile.svg';
import SVG from 'react-inlinesvg';
import {
  GraspColors,
  graspColorsValues,
} from '../../../../../../../helpers/colors';
import { Spinner } from 'reactstrap';
import BuddyCaseHourByHourChartNoDataPlaceholder from './BuddyCaseHourByHourChartNoDataPlaceholder';
import useMobile from '../../../../../../../hooks/useBreakpoint';
import {
  UseHourByHourChartDataProps,
  useHourByHourChartData,
} from '../hooks/useHourByHourChartData';
import BuddyCaseActivities from './BuddyCaseActivities';

type BuddyCaseHourByHourContentProps = UseHourByHourChartDataProps;

const BuddyCaseHourByHourContent = (props: BuddyCaseHourByHourContentProps) => {
  const { chartData, activityPeriods, dataStart, dataLength, isLoading } =
    useHourByHourChartData(props);

  if (isLoading)
    return (
      <div className="BuddyCaseHourByHourContent">
        <Spinner />
      </div>
    );

  const dataEmpty = chartData.length <= 0;

  if (dataEmpty) return <BuddyCaseHourByHourChartNoDataPlaceholder />;

  return (
    <div className="BuddyCaseHourByHourContent">
      <BuddyCaseActivities
        activities={activityPeriods}
        dataStart={dataStart}
        dataLength={dataLength}
      />
      <ResponsiveContainer width="98%" height={360}>
        <BarChart data={chartData} barGap={1} barCategoryGap="5%">
          <XAxis dataKey="name" tick={<SmilesTick />} interval={0} />
          <Bar dataKey="Squeezes" shape={<SmilesBar />} />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

const SmilesBar = (props) => {
  const isMobile = useMobile();
  const { fill, x, width, Squeezes } = props;

  const smileWidth = Math.min(20, width);
  const smileHeight = smileWidth + 2;

  const smileX = x + width - 5;
  const squeezesCount = parseInt(Squeezes) > 10 ? 10 : parseInt(Squeezes);

  return (
    <svg x={smileX} y={-12} width={width} height={360} fill={fill}>
      {[...Array(squeezesCount).keys()].map((smile, idx) => (
        <SVG
          key={smile}
          src={blueSmileIcon}
          x={0}
          y={310 - idx * smileHeight}
          width={smileWidth}
        />
      ))}
      {parseInt(Squeezes) > 10 && (
        <text
          x={-3}
          y={305 - 9 * smileHeight}
          fontWeight={600}
          fontSize={isMobile ? 12 : 16}
          fill={graspColorsValues[GraspColors.BLUE]}
        >
          {`+${parseInt(Squeezes) - 10}`}
        </text>
      )}
    </svg>
  );
};

const SmilesTick = (props) => {
  const isMobile = useMobile(1200);
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={isMobile ? -7 : -20}
        y={0}
        dy={25}
        fill="#000"
        fontWeight={500}
        fontSize={isMobile ? 13 : 15}
      >
        {isMobile
          ? payload.value.slice(0, payload.value.indexOf(':'))
          : payload.value}
      </text>
    </g>
  );
};

export default BuddyCaseHourByHourContent;
