import React, { PropsWithChildren } from 'react';
import { useCurrentBreakpoint } from '../../../../../../../../../../../../../../hooks/useCurrentBreakpoint';
import { useTypedContext } from '../../../../../../../../../../../../../../hooks/useTypedContext';
import {
  DashboardTemplateVisualization,
  DashboardTemplateVisualizationType,
} from '../../../../../../../../../../../../../../interfaces/dashboards';
import { DashboardDataReducerContext } from '../../../../../../../../types';
import { useTemplateGrid } from '../../../../hooks/useTemplateGrid';

type VisualizationGridWrapperProps = {
  visualization: DashboardTemplateVisualization;
};

const VisualizationGridWrapper = ({
  children,
  visualization,
}: PropsWithChildren<VisualizationGridWrapperProps>) => {
  const [dashboardData] = useTypedContext(DashboardDataReducerContext);
  const breakpoint = useCurrentBreakpoint();

  const { getGridColumn, getGridRow, getCurrentPosition } = useTemplateGrid(
    dashboardData.template,
    breakpoint
  );

  const currentPosition = getCurrentPosition(visualization);

  return (
    <div
      key={visualization.sequence}
      style={{
        gridColumn: getGridColumn(currentPosition),
        gridRow: getGridRow(currentPosition),
        overflow:
          visualization.type === DashboardTemplateVisualizationType.ChartView
            ? 'initial'
            : 'hidden',
      }}
    >
      {children}
    </div>
  );
};

export default VisualizationGridWrapper;
