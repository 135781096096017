import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ThinHeaderInput from '../ThinHeaderInput';
import SVG from 'react-inlinesvg';
import { useIconDefinitionCheck } from '../../hooks/useIconDefinitionCheck';
import classNames from 'classnames';
import { GraspColors, graspColorsValues } from '../../../../helpers/colors';
import { ThinHeaderItemDefinition } from '../../types/ThinHeader';

type ThinHeaderItemProps = {
  item: ThinHeaderItemDefinition;
};

const ThinHeaderItem = ({ item }: ThinHeaderItemProps) => {
  const isIconDefinition = useIconDefinitionCheck();

  const itemTitle = (
    <>
      {item.title && (
        <span className="ThinHeaderItem__title">{item.title}</span>
      )}
    </>
  );
  const itemHeader = (
    <>
      {item.header && (
        <span className="ThinHeaderItem__header">{item.header}</span>
      )}
    </>
  );

  return (
    <div className="ThinHeader__item ThinHeaderItem">
      <div
        className={classNames({
          ThinHeaderItem__icon: item.icon,
        })}
        style={{
          background: item.color ?? graspColorsValues[GraspColors.BLUE],
        }}
      >
        {item.icon && (
          <>
            {isIconDefinition(item.icon) ? (
              <FontAwesomeIcon icon={item.icon} width={15} height={15} />
            ) : (
              <SVG
                src={item.icon}
                title={item.title ?? item.key}
                width={15}
                height={15}
              />
            )}
          </>
        )}
      </div>
      <div className="ThinHeaderItem__content">
        {itemHeader}
        {item.editable ? (
          <ThinHeaderInput
            ExternalEditComponent={item.editable.ExternalEditComponent}
            inputOptions={item.editable.inputOptions}
            inputChecked={item.editable.value as boolean}
            inputType={item.editable.inputType}
            onEdit={item.editable.onEdit}
            name={item.editable.name}
            value={item.editable.value}
          />
        ) : (
          itemTitle
        )}
      </div>
    </div>
  );
};

export default ThinHeaderItem;
