import React from 'react';
import InfiniteListLayout from '../../../../../../components/InfiniteList/InfiniteListLayout';
import useOpen from '../../../../../../hooks/useOpen';
import { Button, ThemeProvider } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import ConversationQuestionsProvider from './providers/ConversationQuestionsProvider';
import AwareCasesList from './lists/AwareCasesList';
import { ThemesOptions } from '../../../../../../themes/options';
import { faSearch } from '@fortawesome/pro-solid-svg-icons';

const AwareCasesListView = () => {
  const scheduleSession = useOpen();

  return (
    <ThemeProvider theme={ThemesOptions.awareThemeOptions}>
      <ConversationQuestionsProvider>
        {(questions) => (
          <InfiniteListLayout
            titleTranslationId="Cases.YourPatients"
            additionalRightContent={
              <div className="mb-3">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={scheduleSession.toggle}
                >
                  <FormattedMessage id="Cases.NewSession" />
                </Button>
              </div>
            }
            labelTranslationId="Cases.SearchPatient"
            inputIconSrc={faSearch}
            hideLabel={true}
          >
            <AwareCasesList
              questions={questions}
              scheduleSession={scheduleSession}
            />
          </InfiniteListLayout>
        )}
      </ConversationQuestionsProvider>
    </ThemeProvider>
  );
};

export default AwareCasesListView;
