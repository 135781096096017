import React, { useRef } from 'react';
import { FormikProps } from 'formik';
import { AwareCaseDetailsFormValues } from '../../forms/AwareCaseDetailsForm/types';
import { useProceduresOffsets } from './hooks/useProceduresOffsets';
import ProcedureRange from './components/ProcedureRange';
import ManageProcedureModal from '../../modals/ManageProcedureModal';
import { useManageProcedure } from './hooks/useManageProcedure';

type ProcedureRangesProps = {
  startDate: string;
  endDate: string;
  form: FormikProps<AwareCaseDetailsFormValues>;
};

const ProcedureRanges = ({
  form,
  startDate,
  endDate,
}: ProcedureRangesProps) => {
  const { procedureIndex, isOpen, handleOpen, handleToggle } =
    useManageProcedure();

  const containerRef = useRef<HTMLDivElement>(null);

  const offsets = useProceduresOffsets(
    startDate,
    endDate,
    form.values.procedures,
    handleOpen
  );

  return (
    <div ref={containerRef} className="relative flex items-center w-full">
      {isOpen && procedureIndex !== null && (
        <ManageProcedureModal
          procedureIndex={procedureIndex}
          form={form}
          isOpen={isOpen}
          toggle={handleToggle}
        />
      )}
      {offsets.map((offset) => (
        <ProcedureRange
          key={`${offset.sequence}|${offset.startOffset}`}
          name={offset.name}
          startOffset={offset.startOffset}
          width={offset.width}
          onClick={offset.onClick}
        />
      ))}
    </div>
  );
};

export default ProcedureRanges;
