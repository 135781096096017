import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { inviteUserToOrganization } from 'api/membership';
import { InvitationBody } from 'interfaces/membership';
import { IMutationProps } from 'interfaces/ui';

function useInviteUserToOrganization(
  organizationId: string,
  { successFb }: IMutationProps
) {
  return useMutationWithResultNotification({
    asyncApiFunction: async (body: InvitationBody) => {
      await inviteUserToOrganization(organizationId, body);
    },
    resetOnResult: true,
    onSuccess: () => {
      successFb && successFb();
      displaySuccessNotification('MyOrganizations.invitation.success');
    },
    onErrorArray: (errors) => {
      displayErrorArrayNotifications(
        errors,
        'MyOrganizations.invitation.errors'
      );
    },
  });
}

export default useInviteUserToOrganization;
