import { useInitQuery } from '../../../../../../../../../../../hooks/queries/useInitQuery';
import { useTemplateCharacteristics } from '../../../../../../../../../../../hooks/useTemplateCharacteristics';
import { useTreatmentPlanCommonData } from '../../../../../../../hooks/useTreatmentPlanCommonData';

export const usePhaseCharacteristics = () => {
  const { conversationTemplates } = useTreatmentPlanCommonData();
  useInitQuery(conversationTemplates);

  return useTemplateCharacteristics(conversationTemplates?.data ?? []);
};
