import { editTimelineObservation } from 'api/graspManagement';
import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import { IPatchObject } from 'interfaces/request';
import { IObservation } from 'interfaces/timeline';
import {
  displayErrorArrayNotificationsWithoutTranslations,
  displaySuccessNotification,
} from 'modules/Notifications';

type EditAnnotationParams = {
  observationId: string;
  patchObjects: IPatchObject[];
};

function useEditTimelineObservation(
  timelineId: string,
  successFunction?: () => void
) {
  return useMutationWithResultNotification<EditAnnotationParams, IObservation>({
    asyncApiFunction: async ({ observationId, patchObjects }) => {
      const { data } = await editTimelineObservation(
        timelineId,
        observationId,
        patchObjects
      );
      return data.data;
    },
    onSuccess: () => {
      successFunction && successFunction();
      displaySuccessNotification('TimelineDetails.ObservationEdit.editSuccess');
    },
    onErrorArray: (errors) => {
      displayErrorArrayNotificationsWithoutTranslations(errors);
    },
    resetOnResult: true,
  });
}

export default useEditTimelineObservation;
