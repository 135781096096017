import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import {
  displayErrorArrayNotifications,
  displayErrorNotification,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { IErrorDefinition } from '../../../interfaces/response';
import { uploadCaseDetailsPdf } from '../../cases';
import { ITypedMutationRequest } from '../../../interfaces/request';
import { IOrganizationCasePathParams } from '../../../interfaces/paths';
import { IUploadUriResult } from '../../../interfaces/uploading';

const useUploadCaseDetailsPdf = ({
  params,
  options,
  silent,
}: ITypedMutationRequest<IOrganizationCasePathParams, IUploadUriResult>) => {
  const { organizationId, caseId } = params;

  const mutationData = useMutationWithResultNotification({
    asyncApiFunction: async (file: File) => {
      const { data } = await uploadCaseDetailsPdf(organizationId, caseId, file);
      return data.data;
    },
    onSuccess: () => {
      if (!silent)
        displaySuccessNotification(
          'Api.Mutations.Cases.UploadCaseDetailsPdf.success'
        );

      if (!mutationData.data) return;

      options.successFb?.(mutationData.data);
    },
    onError: (error: any) => {
      displayErrorNotification(
        'Api.Mutations.Cases.UploadCaseDetailsPdf.failure'
      );

      options.errorFb?.(error);
    },
    onErrorArray: (errorArray: IErrorDefinition[]) => {
      displayErrorArrayNotifications(
        errorArray,
        'Api.Mutations.Cases.UploadCaseDetailsPdf.failure'
      );

      options.errorFb?.(errorArray);
    },
    resetOnResult: true,
  });

  return mutationData;
};

export default useUploadCaseDetailsPdf;
