import { useCallback, useEffect, useState } from 'react';
import { BootstrapBreakpoint } from '../interfaces/ui';

export const useCurrentBreakpoint = () => {
  const getBootstrapBreakpoint = useCallback(
    (width: number): BootstrapBreakpoint | null => {
      if (width < 576) return BootstrapBreakpoint.Xs;
      if (width < 768) return BootstrapBreakpoint.Sm;
      if (width < 992) return BootstrapBreakpoint.Md;
      if (width < 1200) return BootstrapBreakpoint.Lg;
      if (width < 1400) return BootstrapBreakpoint.Xl;

      return null;
    },
    []
  );

  const [breakpoint, setBreakpoint] = useState<BootstrapBreakpoint | null>(
    () => {
      return typeof window !== 'undefined'
        ? getBootstrapBreakpoint(window.innerWidth)
        : null;
    }
  );

  const onResize = useCallback(
    (e) => {
      setBreakpoint(getBootstrapBreakpoint(e.target.outerWidth));
    },
    [getBootstrapBreakpoint]
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onResize);
      window.addEventListener('fullscreenchange', onResize);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', onResize);
        window.removeEventListener('fullscreenchange', onResize);
      }
    };
  }, [onResize]);

  return breakpoint;
};
