import React, { useMemo } from 'react';
import { Col, Row } from 'reactstrap';

//components
import CaseTimelines from './components/CaseTimelines';
import CaseHistory from './components/CaseHistory';
import PatientSubjectiveData from './components/PatientSubjectiveData';
import Symptoms from './components/Symptoms';

//helpers
import useRequiredParams from 'hooks/useRequiredParams';
import { SelectedTimelinesInfo } from '../../index';

type CaseDetailsTabProps = {
  selectedTimelinesInfo: SelectedTimelinesInfo;
};

const CaseDetailsTab = ({ selectedTimelinesInfo }: CaseDetailsTabProps) => {
  const { organizationId, caseId } = useRequiredParams<{
    organizationId: string;
    caseId: string;
  }>(['organizationId', 'caseId']);

  const isSelectedTimelineCaseAggregated = useMemo(() => {
    return (
      selectedTimelinesInfo.selectedTimeline?.id ===
        selectedTimelinesInfo.caseAggregatedTimeline?.id &&
      selectedTimelinesInfo.selectedTimeline !== null
    );
  }, [selectedTimelinesInfo]);

  const isSelectedTimelineDiffersFromAggregatedAndDedicated = useMemo(() => {
    return (
      selectedTimelinesInfo.selectedTimeline?.id !==
        selectedTimelinesInfo.dedicatedTimeline?.id &&
      selectedTimelinesInfo.selectedTimeline?.id !==
        selectedTimelinesInfo.caseAggregatedTimeline?.id
    );
  }, [selectedTimelinesInfo]);

  const timelineIdForAssignments = useMemo(() => {
    if (
      isSelectedTimelineCaseAggregated &&
      selectedTimelinesInfo.dedicatedTimeline !== null
    ) {
      return selectedTimelinesInfo.dedicatedTimeline.id;
    }

    return selectedTimelinesInfo.selectedTimeline?.id;
  }, [isSelectedTimelineCaseAggregated, selectedTimelinesInfo]);

  return (
    <>
      {selectedTimelinesInfo.selectedTimeline && (
        <>
          <Row>
            <Col md={12}>
              <CaseTimelines caseId={caseId} organizationId={organizationId} />
            </Col>
          </Row>
          <Row>
            <Col md={12} xl={6} className="mt-4">
              <CaseHistory />
            </Col>
            <Col md={12} xl={6} className="mt-4">
              <PatientSubjectiveData
                timelineId={timelineIdForAssignments!}
                readOnly={isSelectedTimelineDiffersFromAggregatedAndDedicated}
              />
            </Col>
            <Col md={12} xl={12}>
              <Symptoms />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default CaseDetailsTab;
