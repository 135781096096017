import { faCog } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import TextField from '@mui/material/TextField';
import classNames from 'classnames';
import FieldCultureEntries from 'components/Fields/Formik/FieldCultureEntries';
import IconButton from 'components/IconButton';
import { Formik } from 'formik';
import { wrapWithSequenceIdentified } from 'helpers/utils/data';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useOptions } from '../../../../../../../../../../../../../../hooks/useOptions';
import { useTypedContext } from '../../../../../../../../../../../../../../hooks/useTypedContext';
import {
  DashboardTemplateChartType,
  IChartDashboardTemplateVisualization,
} from '../../../../../../../../../../../../../../interfaces/dashboards';
import { BaseModalProps } from '../../../../../../../../../../../../../../interfaces/ui';
import {
  DashboardDataReducerContext,
  DashboardTheme,
} from '../../../../../../../../types';
import { hasEmptySplitBy, isPieChart } from './helpers';
import { useForm } from './hooks/useForm';
import { useManageRow } from './hooks/useManageRow';
import { useSplitByOptions } from './hooks/useSplitByOptions';

type ChartSettingsModalProps = BaseModalProps & {
  visualization: IChartDashboardTemplateVisualization;
};

const ChartSettingsModal = ({
  visualization,
  isOpen,
  toggle,
}: ChartSettingsModalProps) => {
  const intl = useIntl();

  const [dashboardData] = useTypedContext(DashboardDataReducerContext);

  const { initialValues, handleSubmit, validation } = useForm(
    visualization,
    toggle
  );

  const {
    chartTypeOptions,
    seriesAggregateOptions,
    dashboardDataSourceOptions,
    dateGranularityOptions,
  } = useOptions();

  const splitByOptions = useSplitByOptions();

  const { addRow, removeRow } = useManageRow();

  return (
    <Formik
      initialValues={initialValues}
      validate={validation}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={handleSubmit}
    >
      {(props) => {
        return (
          <Modal
            isOpen={isOpen}
            toggle={toggle}
            size="lg"
            className={classNames('GenericModal BorderlessModal', {
              'GenericModal--dark': dashboardData.theme === DashboardTheme.Dark,
            })}
          >
            <ModalHeader toggle={toggle}>
              <FontAwesomeIcon icon={faCog} />
              <FormattedMessage id="Dashboards.ChartSettings" />
            </ModalHeader>
            <ModalBody className="d-flex flex-column gap-3">
              <FieldCultureEntries
                props={props}
                name="name"
                label={intl.formatMessage({ id: 'General.Name' })}
              />
              <TextField
                className="mt-3"
                variant="outlined"
                fullWidth
                select={true}
                id="chart_type"
                name="chart_type"
                label={<FormattedMessage id="Dashboards.ChartType" />}
                value={props.values.chart_type}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
                error={
                  props.touched.chart_type && Boolean(props.errors.chart_type)
                }
                helperText={props.touched.chart_type && props.errors.chart_type}
              >
                {chartTypeOptions().map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
              </TextField>
              {!isPieChart(props) && (
                <FormControlLabel
                  control={
                    <Checkbox
                      name="accumulative"
                      checked={props.values.accumulative}
                      onChange={props.handleChange}
                    />
                  }
                  label={intl.formatMessage({
                    id: 'Dashboards.Accumulative',
                  })}
                />
              )}
              {wrapWithSequenceIdentified(props.values.series).map(
                (series, index) => (
                  <div key={series.sequence} className="d-flex gap-2 mt-3">
                    <TextField
                      variant="outlined"
                      fullWidth
                      select={true}
                      id={`series[${index}].data_source`}
                      name={`series[${index}].data_source`}
                      label={<FormattedMessage id="Dashboards.DataSource" />}
                      value={series.data_source}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    >
                      {dashboardDataSourceOptions().map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.text}
                        </MenuItem>
                      ))}
                    </TextField>
                    {!isPieChart(props) && (
                      <TextField
                        variant="outlined"
                        fullWidth
                        select={true}
                        disabled={index > 0}
                        id={`series[${index}].series_split_by`}
                        name={`series[${index}].series_split_by`}
                        label={<FormattedMessage id="Dashboards.Series" />}
                        value={series.series_split_by}
                        onChange={props.handleChange}
                        onBlur={props.handleBlur}
                      >
                        {splitByOptions.map((option) => (
                          <MenuItem key={option.value} value={option.value}>
                            {option.text}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                    <TextField
                      variant="outlined"
                      fullWidth
                      select={true}
                      disabled={index > 0}
                      id={`series[${index}].split_by`}
                      name={`series[${index}].split_by`}
                      label={
                        <FormattedMessage
                          id={
                            isPieChart(props)
                              ? 'Dashboards.SplitBy'
                              : 'Dashboards.XAxis'
                          }
                        />
                      }
                      value={series.split_by}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    >
                      {splitByOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.text}
                        </MenuItem>
                      ))}
                    </TextField>
                    <TextField
                      variant="outlined"
                      fullWidth
                      select={true}
                      id={`series[${index}].aggregate`}
                      name={`series[${index}].aggregate`}
                      label={<FormattedMessage id="Dashboards.Aggregate" />}
                      value={series.aggregate}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    >
                      {seriesAggregateOptions().map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.text}
                        </MenuItem>
                      ))}
                    </TextField>
                    <IconButton
                      variant="outlined"
                      color="primary"
                      onClick={removeRow(props, index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                    {index === props.values.series.length - 1 && (
                      <IconButton
                        onClick={addRow(props)}
                        disabled={
                          props.values.chart_type ===
                          DashboardTemplateChartType.PieChart
                        }
                        variant="outlined"
                        color="primary"
                      >
                        <AddIcon />
                      </IconButton>
                    )}
                  </div>
                )
              )}
              {!isPieChart(props) && hasEmptySplitBy(props) && (
                <TextField
                  variant="outlined"
                  fullWidth
                  select={true}
                  id="date_granularity"
                  name="date_granularity"
                  label={<FormattedMessage id="General.Granularity" />}
                  value={props.values.date_granularity}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  error={
                    props.touched.date_granularity &&
                    Boolean(props.errors.date_granularity)
                  }
                  helperText={
                    props.touched.date_granularity &&
                    props.errors.date_granularity
                  }
                >
                  {dateGranularityOptions().map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.text}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </ModalBody>
            <ModalFooter>
              <div className="d-flex gap-2">
                <IconButton variant="outlined" color="primary" onClick={toggle}>
                  <UndoIcon />
                </IconButton>
                <IconButton
                  onClick={props.submitForm}
                  disabled={!props.dirty || !props.isValid}
                  variant="outlined"
                  color="primary"
                >
                  <SaveIcon />
                </IconButton>
              </div>
            </ModalFooter>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default ChartSettingsModal;
