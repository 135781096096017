import React from 'react';
import PendingExerciseSessionsProxy from '../../components/Proxies/PendingExerciseSessionsProxy';
import { useCommonParams } from './hooks/useCommonParams';
import ExerciseSessionContent from './components/ExerciseSessionContent';

const ExerciseSession = () => {
  const { userId, caseId } = useCommonParams();

  return (
    <PendingExerciseSessionsProxy userId={userId} caseId={caseId}>
      <ExerciseSessionContent />
    </PendingExerciseSessionsProxy>
  );
};

export default ExerciseSession;
