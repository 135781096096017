import { Typography } from '@mui/material';
import { FormikProps } from 'formik';
import React from 'react';
import { CultureHelpers } from '../../../../../../../../../../../../helpers/cultures';
import { IHelpCategoryItem } from '../../../../../../../../../../../../interfaces/help';
import { HelpCentreFormInitialValues } from '../../../../../../forms/HelpCentreForm/hooks/useForm';

type ProblemHintProps = {
  item?: IHelpCategoryItem;
  form: FormikProps<HelpCentreFormInitialValues>;
};

const ProblemHint = ({ item, form }: ProblemHintProps) => {
  if (!item) return <></>;

  return (
    <div>
      <Typography
        variant="body1"
        dangerouslySetInnerHTML={{
          __html: CultureHelpers.getValueAccordingToCulture(
            item.hint_translations,
            form.initialValues.culture
          ),
        }}
      />
    </div>
  );
};

export default ProblemHint;
