import React, { useState } from 'react';
import assessmentFormChartIcon from '../../../../../assets/images/trends_timeline/assessment_form_chart_icon.svg';
import { FormattedMessage } from 'react-intl';
import SVG from 'react-inlinesvg';
import caretIcon from '../../../../../assets/images/caret.svg';
import LoadingDataChartsPlaceholder from './components/ChartPlaceholders/LoadingDataChartPlaceholder';

const TrendsSummaryQuestionChartPlaceholder = () => {
  const [expanded, setExpanded] = useState<boolean>(true);

  const toggleExpanded = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <div className="cardContainer d-flex flex-column TimelineSqueezeSummary w-100">
      <div className="d-flex justify-content-between align-items-center mb-2 flex-wrap">
        <div className="d-flex cardContainer__header__title w-100">
          <div className="d-flex align-items-start TimelineSqueezeSummary__title">
            <img
              className="me-2"
              src={assessmentFormChartIcon}
              alt="header_icon"
            />
            <FormattedMessage id="TimelineDetails.TrendsTimeline.QuestionChart.chartName" />
          </div>
          <div className="ms-auto TimelineSqueezeSummary__title__buttons">
            <SVG
              onClick={toggleExpanded}
              src={caretIcon}
              transform={expanded ? 'rotate(180)' : ''}
              title="Expand chart"
            />
          </div>
        </div>
      </div>
      {expanded && (
        <div className="mt-3">
          <LoadingDataChartsPlaceholder />
        </div>
      )}
    </div>
  );
};

export default TrendsSummaryQuestionChartPlaceholder;
