import { ICreateProject } from '../../../interfaces/submissions';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { createProject } from '../../projects';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';

type CreateProjectProps = {
  data: ICreateProject;
};

const useCreateProject = (organizationId: string, successFb?: () => void) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async ({ data }: CreateProjectProps) => {
      await createProject(organizationId, data);
    },
    resetOnResult: true,
    onSuccess: () => {
      successFb && successFb();
      displaySuccessNotification('General.projectUpdated');
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(errorArray, 'General.projectUpdatedError');
    },
  });
};

export default useCreateProject;
