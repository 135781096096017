import { useTheme } from '@mui/material';
import PieChartActiveShape from 'components/Charts/PieChart/PieChartActiveShape';
import React, { useCallback } from 'react';
import {
  Cell,
  Label,
  Legend,
  Pie,
  PieChart as RePieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { useSegmentSelection } from '../../../../../../../../../../../../../../hooks/charts/pieChart/useSegmentSelection';
import useMobile from '../../../../../../../../../../../../../../hooks/useBreakpoint';
import { useTypedContext } from '../../../../../../../../../../../../../../hooks/useTypedContext';
import {
  DashboardTemplateChartType,
  DashboardTemplateSeries,
} from '../../../../../../../../../../../../../../interfaces/dashboards';
import { useSeriesColor } from '../../../../hooks/useSeriesColor';
import { VisualizationDataContext } from '../../../../types';
import { ChartViewHelpers } from '../../helpers';
import AwareCasesChartPlaceholder from '../ChartPlaceholder';
import { ChartTooltip } from '../ChartTooltip';
import VerticalLegend from '../VerticalLegend';
import { useFormattedData } from './hooks/useFormattedData';
import { useTotalSum } from './hooks/useTotalSum';

type PieChartProps = {
  config: DashboardTemplateSeries;
  sequence: number;
};

const PieChart = ({ config, sequence }: PieChartProps) => {
  const theme = useTheme();
  const isMobile = useMobile();

  const chartsData = useTypedContext(VisualizationDataContext);

  const formattedData = useFormattedData(
    chartsData.sessions.current.data,
    config
  );

  const totalSum = useTotalSum(formattedData);
  const { getColor } = useSeriesColor();

  const { selectedSegmentIndex, handleSelectSegment, handleResetSegment } =
    useSegmentSelection();

  const renderVerticalLegend = useCallback(
    (props: any) => <VerticalLegend {...props} sequence={sequence} />,
    [sequence]
  );

  if (ChartViewHelpers.AreEmptyFormattedData(formattedData, false))
    return (
      <AwareCasesChartPlaceholder type={DashboardTemplateChartType.PieChart} />
    );

  return (
    <ResponsiveContainer width="100%" height="100%">
      <RePieChart style={{ strokeWidth: 8 }}>
        <Pie
          activeIndex={selectedSegmentIndex}
          activeShape={PieChartActiveShape}
          cy="50%"
          cx="45%"
          data={formattedData}
          labelLine={false}
          innerRadius={isMobile ? '55%' : '45%'}
          outerRadius={isMobile ? '90%' : '75%'}
          dataKey="value"
          strokeWidth={0}
          cornerRadius={3}
          paddingAngle={3}
          onMouseEnter={handleSelectSegment}
          onMouseLeave={handleResetSegment}
        >
          {formattedData.map((entry, index) => (
            <Cell
              width="100%"
              height="100%"
              key={`cell-${entry.name}`}
              fill={getColor(index)}
            />
          ))}
          {selectedSegmentIndex === undefined && (
            <Label
              fill={theme.palette.text.primary}
              value={totalSum?.toFixed(2) ?? 0}
              fontSize={isMobile ? '14px' : '16px'}
              offset={0}
              position="center"
            />
          )}
        </Pie>
        {selectedSegmentIndex === undefined && (
          <Tooltip content={ChartTooltip} />
        )}
        <Legend
          layout="vertical"
          align="right"
          verticalAlign="middle"
          wrapperStyle={{
            width: '45%',
            top: '50%',
            left: '77%',
            transform: 'translate(-50%, -50%)',
          }}
          content={renderVerticalLegend}
        />
      </RePieChart>
    </ResponsiveContainer>
  );
};

export default PieChart;
