import { FormattedMessage, useIntl } from 'react-intl';

// COMPONENTS:
import SetPasswordForm from './components/SetPasswordForm';

// ACTION:
import { useNavigate } from 'react-router';
import useSetPassword from 'api/mutations/membership/useSetPassword';

import SVG from 'react-inlinesvg';

import logo from 'assets/images/logo-blue.svg';
import backArrow from 'assets/images/back_arrow.svg';
import useRequiredParams from 'hooks/useRequiredParams';
import MembershipLayout from 'components/layouts/MembershipLayout';

const initialValues = {
  password: '',
  passwordRepeat: '',
};

const SetPassword = () => {
  const intl = useIntl();
  const { token } = useRequiredParams<{ token: string }>(['token']);
  const navigate = useNavigate();

  const {
    mutate: setPasswordMutation,
    isLoading,
    isError,
    isSuccess,
  } = useSetPassword();

  const handleSubmit = (values: typeof initialValues) => {
    setPasswordMutation({ ...values, token });
  };

  const handleGoToLogin = () => {
    navigate('/login');
  };

  return (
    <MembershipLayout>
      <div className="SetNewPassword h-100">
        <div className="SetNewPassword__top-bar">
          <div
            className="SetNewPassword__top-bar__back"
            onClick={handleGoToLogin}
          >
            <SVG src={backArrow} />
            <FormattedMessage id="Login.back" />
          </div>
          <div className="SetNewPassword__top-bar__logo">
            <div className="SetNewPassword__top-bar__logo__logo-wrapper">
              <SVG
                src={logo}
                title={intl.formatMessage({
                  id: 'MyOrganizations.Logo',
                })}
              />
            </div>
          </div>
        </div>
        <div className="SetNewPassword__wrapper SetNewPassword__wrapper--height-stretched SetNewPassword__wrapper--center-aligned">
          <div className="SetNewPassword__wrapper__form d-flex align-items-center">
            <div className="SetNewPassword__wrapper__form__header">
              <span className="SetNewPassword__wrapper__form__header__log-in-as mb-3">
                <FormattedMessage id="SetPassword.header" />
              </span>
            </div>
            <SetPasswordForm
              isFailure={isError}
              isLoading={isLoading}
              isSuccess={isSuccess}
              handleSubmit={handleSubmit}
              initialValues={initialValues}
            />
          </div>
        </div>
      </div>
    </MembershipLayout>
  );
};

export default SetPassword;
