import React from 'react';
import HorizontalItems from './components/HorizontalItems';
import HorizontalMore from './components/HorizontalMore';

const LEGEND_LENGTH_CONSTRAINT = 6;

export type HorizontalLegendProps = {
  sequence: number;
  [prop: string]: any;
};

const HorizontalLegend = <T extends HorizontalLegendProps>(props: T) => {
  const payload = props.payload as any[];

  return (
    <div className="AwareCasesHorizontalLegend">
      <div className="AwareCasesHorizontalLegend__container">
        <HorizontalItems
          payload={payload}
          lengthConstraint={LEGEND_LENGTH_CONSTRAINT}
        />
        {payload.length > LEGEND_LENGTH_CONSTRAINT && (
          <HorizontalMore
            payload={payload}
            lengthConstraint={LEGEND_LENGTH_CONSTRAINT}
          />
        )}
      </div>
    </div>
  );
};

export default HorizontalLegend;
