import React from 'react';
import { Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { useTranslations } from '../../../../hooks/useTranslations';
import Skeleton from 'react-loading-skeleton';

const ExerciseTimePlaceholder = () => {
  const { attachPrefix } = useTranslations(
    'CaseDashboard.CaseDashboardTabs.DayDetailsTab.ExerciseTime.ExerciseTimeProgress'
  );

  return (
    <div className="d-flex flex-column justify-content-center align-items-start w-100 h-100 mt-4 mt-md-0">
      <Row className="w-100 py-3 py-md-0">
        <Col xs={6} className="d-flex flex-column align-items-center gap-1">
          <span className="ExerciseTimeProgress__value">
            <Skeleton width={150} height={25} />
          </span>
          <span className="ExerciseTimeProgress__subtitle">
            <FormattedMessage id={attachPrefix('numberOfSessions')} />
          </span>
        </Col>
        <Col xs={6} className="d-flex flex-column align-items-center gap-1">
          <span className="ExerciseTimeProgress__value">
            <Skeleton width={150} height={25} />
          </span>
          <span className="ExerciseTimeProgress__subtitle">
            <FormattedMessage id={attachPrefix('totalTime')} />
          </span>
        </Col>
      </Row>
    </div>
  );
};

export default ExerciseTimePlaceholder;
