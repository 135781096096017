//helpers
import useStrictOrganizationPatientList from 'api/queries/patients/useStrictOrganizationPatientList';
import { IStrictPersonality } from 'interfaces/personalities';

//types
import { ParticipantAccessSelection } from 'interfaces/ui';
import React from 'react';
import { ListGroup, Spinner } from 'reactstrap';

//components
import PersonSelectionRow from './PersonSelectionRow';

type AccessSelectStepProps = {
  fullName?: string;
  patientNumber?: number;
  organizationId: string;
  selection: ParticipantAccessSelection | null;
  setSelection: React.Dispatch<
    React.SetStateAction<ParticipantAccessSelection | null>
  >;
};

const AccessSelectStep = ({
  organizationId,
  fullName,
  patientNumber,
  selection,
  setSelection,
}: AccessSelectStepProps) => {
  const { data: patients, isLoading } = useStrictOrganizationPatientList({
    params: {
      organizationId,
      fullName,
      patientNumber,
    },
  });

  const onCheckPerson = (participant: IStrictPersonality) => {
    setSelection((currentSelection) => {
      if (
        currentSelection != null &&
        currentSelection.participant_id === participant.id
      )
        return null;
      else
        return {
          participant_id: participant.id,
          cases_id: [],
          participant_already_shared: participant.already_shared,
        };
    });
  };

  const onCheckCase = (caseId: string, participant: IStrictPersonality) => {
    setSelection((currentSelection) => {
      if (
        currentSelection &&
        currentSelection.participant_id === participant.id
      ) {
        const caseIdInPersonality = currentSelection.cases_id.find(
          (caseEl) => caseEl === caseId
        );

        let newCases: string[];
        if (caseIdInPersonality) {
          newCases = currentSelection.cases_id.filter(
            (caseEl) => caseEl !== caseId
          );
        } else {
          newCases = [...currentSelection.cases_id, caseId];
        }

        return {
          ...currentSelection,
          cases_id: newCases,
        };
      } else
        return {
          participant_id: participant.id,
          cases_id: [caseId],
          participant_already_shared: participant.already_shared,
        };
    });
  };

  const patientToRequestList = patients?.map((patient) => {
    const selectionMatched =
      selection && selection?.participant_id === patient.id;

    return (
      <PersonSelectionRow
        key={patient.id}
        casesChecked={selectionMatched ? selection?.cases_id : undefined}
        isPersonalityChecked={!!selectionMatched}
        data={patient}
        onCheckCase={onCheckCase}
        onCheckPersonality={onCheckPerson}
      />
    );
  });

  return (
    <div>
      {isLoading && <Spinner />}
      <ListGroup>{patientToRequestList}</ListGroup>
    </div>
  );
};

export default AccessSelectStep;
