import {
  AssetStatus,
  IAsset,
  IEducationMaterial,
} from '../../../../../../../../../../../../../interfaces/educationMaterials';
import { ChangeEvent, useState } from 'react';
import useUploadAsset from '../../../../../../../../../../../../../api/mutations/assets/useUploadAsset';
import { useCommonParams } from '../../../../../../../../../../../hooks/useCommonParams';
import { displayErrorNotification } from '../../../../../../../../../../../../Notifications';
import { useTranslations } from '../../../../../../../../../../../../../hooks/useTranslations';
import { AvailableCulture } from '../../../../../../../../../../../../../hooks/cultures/useCultures';
import useLinkVideoAssetWithEducationMaterial from '../../../../../../../../../../../../../api/mutations/educationMaterials/useLinkVideoAssetWithEducationMaterial';
import { cloneDeep } from 'lodash';
import { useAssetAcceptType } from '../../../../../hooks/useAssetAcceptType';
import { CultureEntry } from 'interfaces/cultures';

export const useAssetUpload = (
  asset: IAsset,
  material: IEducationMaterial,
  setMaterial: (material: IEducationMaterial) => void,
  cultures: AvailableCulture[],
  disable: () => void
) => {
  const { organizationId } = useCommonParams();

  const { attachPrefix } = useTranslations(
    'Education.MaterialModal.MaterialModalBody.UploadAssetsStep.UploadAssetsStepItem.EditModeItem'
  );

  const [fileString, setFileString] = useState<string>('');
  const [transcoding, setTranscoding] = useState<boolean>(true);
  const [file, setFile] = useState<File | null>(null);

  const acceptType = useAssetAcceptType(asset.type);

  const toggleTranscoding = () => setTranscoding((prev) => !prev);

  const onFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;

    setFile(e.target.files[0]);
    setFileString(e.target.value);
  };

  const { mutateAsync: linkAsync } =
    useLinkVideoAssetWithEducationMaterial(organizationId);

  const handleSuccess = async (assetId: string) => {
    let assetCopy = cloneDeep(asset);
    assetCopy.id = assetId;
    assetCopy.status = AssetStatus.Uploaded;

    let assetsWithCulture: CultureEntry<IAsset[]>[] = [];

    for (const culture of cultures) {
      await linkAsync({
        assetId: assetId,
        materialId: material.id,
        culture: culture.cultureCode,
      });

      const cultureIndex = assetsWithCulture.findIndex(
        (p) => p.culture === culture.cultureCode
      );

      if (cultureIndex === -1) {
        assetsWithCulture.push({
          culture: culture.cultureCode,
          value: [assetCopy],
        });
        continue;
      }

      assetsWithCulture[cultureIndex].value.push(assetCopy);
    }

    let existingAssetsWithCultureCopy = cloneDeep(
      material.assets_with_cultures
    );

    assetsWithCulture.forEach((entry) => {
      const cultureIndex = existingAssetsWithCultureCopy.findIndex(
        (p) => p.culture === entry.culture
      );

      if (cultureIndex === -1) {
        existingAssetsWithCultureCopy.push({
          culture: entry.culture,
          value: entry.value,
        });
        return;
      }

      existingAssetsWithCultureCopy[cultureIndex].value.push(...entry.value);
    });

    setMaterial({
      ...material,
      assets_with_cultures: existingAssetsWithCultureCopy,
    });

    disable();
  };

  const { mutateAsync: uploadAsset, isLoading: isUploading } = useUploadAsset(
    organizationId,
    handleSuccess
  );

  const handleUpload = async () => {
    if (asset.name.length === 0)
      asset.name = asset.name_translations.at(0)?.value ?? '';

    if (asset.description.length === 0)
      asset.description = asset.description_translations.at(0)?.value ?? '';

    if (
      asset.name.length === 0 ||
      asset.description.length === 0 ||
      isUploading
    ) {
      displayErrorNotification(attachPrefix('emptyMetadata'));
      return;
    }

    if (file === null) {
      displayErrorNotification(attachPrefix('fileNotSelected'));
      return;
    }

    await uploadAsset({
      name: asset.name,
      description: asset.description,
      type: asset.type,
      file: file,
      nameTranslations: asset.name_translations,
      descriptionTranslations: asset.description_translations,
    });
  };

  return {
    acceptType,
    fileString,
    transcoding,
    toggleTranscoding,
    onFileChange,
    handleUpload,
    isUploading,
  };
};
