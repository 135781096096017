import React, { useContext } from 'react';
import {
  MaterialModalFormValues,
  MaterialModalSteps,
  MaterialModalStepsContext,
} from '../../types';
import FillInfoStep from './components/FillInfoStep';
import AddRulesStep from './components/AddRulesStep';
import UploadAssetsStep from './components/UploadAssetsStep';
import { FormHookProps } from '../../../../../../interfaces/forms';

type MaterialModalFooterProps = FormHookProps<MaterialModalFormValues>;

const MaterialModalFooter = ({ form }: MaterialModalFooterProps) => {
  const { step } = useContext(MaterialModalStepsContext);

  let component: JSX.Element = <></>;

  if (step === MaterialModalSteps.FillInfo)
    component = <FillInfoStep form={form} />;

  if (step === MaterialModalSteps.AddRules)
    component = <AddRulesStep form={form} />;

  if (step === MaterialModalSteps.UploadAssets)
    component = <UploadAssetsStep form={form} />;

  return <div className="d-flex align-items-center gap-2">{component}</div>;
};

export default MaterialModalFooter;
