import { useQuery } from 'react-query';
import { getConversationTemplates } from '../../conversations';
import QueryKeys from '../queryKeys';
import {
  ConversationTemplateContext,
  ConversationTemplateTriggerType,
} from '../../../interfaces/conversations';
import { OrganizationQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

type UseConversationTemplatesParams = OrganizationQueryParams & {
  context?: ConversationTemplateContext;
  triggerType?: ConversationTemplateTriggerType;
};

const useConversationTemplates = ({
  params,
  options,
}: IQueryRequest<UseConversationTemplatesParams>) => {
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getConversationTemplates(
        params.organizationId,
        params.context ?? null,
        params.triggerType ?? null
      );

      return data.data;
    },
    queryKey: [
      QueryKeys.getConversationTemplates,
      params.organizationId,
      params.context,
    ],
  });
};

export default useConversationTemplates;
