import React, { useContext } from 'react';
import { ICaseGroupConversationSchedule } from '../../../../../../../../interfaces/conversationSchedules';
import { useCaseGroupDetailsEditTimeForm } from './hooks/useCaseGroupDetailsEditTimeForm';
import { ConversationScheduleDateContext } from '../../index';
import EditScheduleTemplateItemModal from '../../../../../../../../components/EditScheduleTemplateItemModal';

type CaseGroupDetailsConversationSchedulesEditTimeModalProps = {
  isOpen: boolean;
  toggle: () => void;
  schedule: ICaseGroupConversationSchedule;
  phaseId: string;
};

const CaseGroupDetailsConversationSchedulesEditTimeModal = ({
  isOpen,
  toggle,
  schedule,
  phaseId,
}: CaseGroupDetailsConversationSchedulesEditTimeModalProps) => {
  const selectedDate = useContext(ConversationScheduleDateContext);

  const formHandler = useCaseGroupDetailsEditTimeForm(
    schedule,
    new Date(selectedDate),
    toggle,
    phaseId
  );

  return (
    <EditScheduleTemplateItemModal
      isOpen={isOpen}
      toggle={toggle}
      formHandler={formHandler}
      schedule={schedule}
    />
  );
};

export default CaseGroupDetailsConversationSchedulesEditTimeModal;
