import CustomDataGrid from 'components/CustomDataGrid';
import { CustomMaterialTableHelpers } from 'components/CustomDataGrid/helpers';
import { IOrganizationDevice } from 'interfaces/organizations';
import React from 'react';
import { useColumns } from './hooks/useColumns';

type OtherDevicesTableProps = {
  devices: IOrganizationDevice[];
};

const OtherDevicesTable = ({ devices }: OtherDevicesTableProps) => {
  const columns = useColumns();

  return (
    <CustomDataGrid
      onCellDoubleClick={CustomMaterialTableHelpers.DefaultMuiPreventedEvent}
      onCellEditStart={CustomMaterialTableHelpers.DefaultMuiPreventedEvent}
      onCellEditStop={CustomMaterialTableHelpers.DefaultMuiPreventedEvent}
      onCellKeyDown={CustomMaterialTableHelpers.DefaultMuiPreventedEvent}
      onCellClick={CustomMaterialTableHelpers.DefaultMuiPreventedEvent}
      getRowHeight={CustomMaterialTableHelpers.GetRowHeight}
      rows={devices}
      columns={columns}
      sx={{
        '&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell': {
          py: '0.5rem',
        },
      }}
    />
  );
};

export default OtherDevicesTable;
