import { createOrganizationLocation } from 'api/membership';
import { IMutationProps } from 'interfaces/ui';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

type CreateLocationBody = {
  name: string;
  description: string;
};

function useCreateOrganizationLocation(
  organizationId: string,
  props?: IMutationProps
) {
  return useMutationWithResultNotification({
    asyncApiFunction: async ({ name, description }: CreateLocationBody) => {
      await createOrganizationLocation(organizationId, name, description);
    },
    resetOnResult: true,
    onSuccess: () => {
      props?.successFb && props.successFb();
      displaySuccessNotification('MyOrganizations.endpoints.createSuccess');
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'MyOrganizations.endpoints.createErrors'
      );
    },
  });
}

export default useCreateOrganizationLocation;
