import { faArrows } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AssessmentQuestionHistoryEntryItem } from 'interfaces/cases';
import { TrendVisualizationChartType } from 'interfaces/trendVisualizations';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import AssessmentFormChartLegend from './AssessmentFormChartLegend';
import AssessmentFormChartTooltip from './AssessmentFormChartTooltip';
import {
  alignDateToClosestForSecondsGranularity,
  getDifferenceOfTimestamps,
  returnIntervalBaseOnDates,
} from '../../../../../../../helpers/dates';
import { addHours, addSeconds, format } from 'date-fns';
import {
  AssessmentQuestionType,
  IAssessmentTemplate,
} from '../../../../../../../interfaces/assessmentForms';
import AssessmentFormChartYAxisTick from './AssessmentFormChartYAxisTick';
import { MAXIMUM_DIFFERENCE_TO_DISPLAY_HOURS } from 'hooks/useXAxisFormatting';

export enum YAxisAssessmentFormChartType {
  NUMERIC = 'NUMERIC',
  ICONS = 'ICONS',
}

export type AssessmentFormChartProps = {
  questions: AssessmentQuestionHistoryEntryItem[];
  chartType: TrendVisualizationChartType;
  legend?: boolean;
  leftStretched?: boolean;
  dateRange: DateRange | null;
  template: IAssessmentTemplate;
  yAxisType: YAxisAssessmentFormChartType;
};

export type ZoomModeConfig = {
  left?: number;
  right?: number;
  leftClosestIndex?: number;
  rightClosestIndex?: number;
  refAreaLeft?: string;
  refAreaRight?: string;
  animation: boolean;
};

const initialZoomModeConfig: ZoomModeConfig = {
  left: undefined,
  right: undefined,
  refAreaLeft: undefined,
  refAreaRight: undefined,
  animation: true,
  leftClosestIndex: undefined,
  rightClosestIndex: undefined,
};

const AssessmentFormChart = ({
  questions,
  chartType,
  legend = true,
  leftStretched = false,
  dateRange,
  template,
  yAxisType,
}: AssessmentFormChartProps) => {
  const [zoomModeConfig, setZoomModeConfig] = useState<ZoomModeConfig>(
    initialZoomModeConfig
  );

  const [displayedQuestions, setDisplayedQuestions] = useState<string[]>([]);

  const selectedQuestions = useMemo(() => {
    return template.questions.filter((p) =>
      questions
        .map((q) => q.questionId.toLowerCase())
        .includes(p.guid.toLowerCase())
    );
  }, [questions, template]);

  useEffect(() => {
    setDisplayedQuestions(questions.map((q) => q.questionId));
  }, [questions]);

  const dateRangeInterval = useMemo(() => {
    if (dateRange && dateRange.from && dateRange.to) {
      return returnIntervalBaseOnDates(
        dateRange.from.getTime(),
        dateRange.to.getTime()
      );
    }

    return 3600;
  }, [dateRange]);

  const fillSeries = useCallback(
    (startDate: Date, endDate: Date, series: any[]) => {
      while (addSeconds(startDate, dateRangeInterval) <= endDate) {
        series.push({
          name: new Date(startDate).getTime(),
          '': '',
        });
        startDate = addSeconds(startDate, dateRangeInterval);
      }

      return series;
    },
    [dateRangeInterval]
  );

  const formattedChartData = useMemo(() => {
    let series: any[] = [];

    if (dateRange && dateRange.from && dateRange.to) {
      let startDate = alignDateToClosestForSecondsGranularity(
        dateRangeInterval,
        dateRange.from.getTime()
      );
      let endDate = alignDateToClosestForSecondsGranularity(
        dateRangeInterval,
        dateRange.to.getTime(),
        true
      );

      series = fillSeries(startDate, endDate, series);
    }

    questions
      .filter((p) => displayedQuestions.includes(p.questionId))
      .forEach((question) => {
        question.answerHistory.forEach((answer) => {
          let inverted = false;

          const selectedQuestionInfo = selectedQuestions.find(
            (p) => p.guid.toLowerCase() === question.questionId.toLowerCase()
          );

          if (
            selectedQuestionInfo &&
            selectedQuestionInfo.maxNumberValue <
              selectedQuestionInfo.minNumberValue &&
            yAxisType !== YAxisAssessmentFormChartType.NUMERIC
          ) {
            inverted = true;
          }

          if (answer.value) {
            series.push({
              name: new Date(answer.date).getTime(),
              [question.questionTitle]:
                inverted && selectedQuestionInfo
                  ? selectedQuestionInfo.minNumberValue - answer.value
                  : answer.value,
            });
          }
        });
      });

    return series.sort((a, b) => a.name - b.name);
  }, [
    dateRange,
    dateRangeInterval,
    displayedQuestions,
    fillSeries,
    questions,
    selectedQuestions,
    yAxisType,
  ]);

  const hoursRange = useMemo(() => {
    if (
      zoomModeConfig.left !== undefined &&
      zoomModeConfig.right !== undefined
    ) {
      return [zoomModeConfig.left, zoomModeConfig.right];
    }

    return [];
  }, [zoomModeConfig.left, zoomModeConfig.right]);

  const secondsInterval = useMemo(() => {
    if (hoursRange && hoursRange.length === 2) {
      return returnIntervalBaseOnDates(hoursRange[0], hoursRange[1]);
    }

    return 7200;
  }, [hoursRange]);

  const renderLines = useMemo(() => {
    return questions
      .filter((p) => displayedQuestions.includes(p.questionId))
      .map((question) => (
        <Line
          key={question.questionId}
          connectNulls
          type="monotone"
          strokeWidth={3}
          dataKey={question.questionTitle}
          stroke={question.questionColor}
          activeDot={{ r: 8 }}
          yAxisId="1"
        />
      ));
  }, [displayedQuestions, questions]);

  const renderBars = useMemo(() => {
    return questions
      .filter((p) => displayedQuestions.includes(p.questionId))
      .map((question) => (
        <Bar
          dataKey={question.questionTitle}
          fill={question.questionColor}
          yAxisId="1"
        />
      ));
  }, [displayedQuestions, questions]);

  const zoom = () => {
    if (
      zoomModeConfig.refAreaLeft !== undefined &&
      zoomModeConfig.refAreaRight !== undefined
    ) {
      let leftIndex = formattedChartData.findIndex(
        (p) => p.name === zoomModeConfig.refAreaLeft
      );

      let rightIndex = formattedChartData.findIndex(
        (p) => p.name === zoomModeConfig.refAreaRight
      );

      if (rightIndex < leftIndex) {
        const temp = rightIndex;
        rightIndex = leftIndex;
        leftIndex = temp;
      }

      if (leftIndex !== -1 && rightIndex !== -1 && leftIndex !== rightIndex) {
        const interval = returnIntervalBaseOnDates(
          formattedChartData[leftIndex].name,
          formattedChartData[rightIndex].name
        );

        const leftApproximatedDate = alignDateToClosestForSecondsGranularity(
          interval,
          formattedChartData[leftIndex].name
        );
        const rightApproximatedDate = alignDateToClosestForSecondsGranularity(
          interval,
          formattedChartData[rightIndex].name,
          true
        );

        setZoomModeConfig((prev) => ({
          ...prev,
          leftClosestIndex: leftIndex,
          rightClosestIndex: rightIndex,
          left: leftApproximatedDate.getTime(),
          right: rightApproximatedDate.getTime(),
          refAreaLeft: undefined,
          refAreaRight: undefined,
        }));
      }
    }
  };

  const renderAssessmentFormChartTooltip = (
    props: TooltipProps<string, string>
  ) => {
    const payload = props.payload;
    let question: AssessmentQuestionHistoryEntryItem | undefined = undefined;

    if (payload !== undefined && payload !== null && payload.length > 0)
      question = questions.find((p) => p.questionTitle === payload[0].dataKey);

    const selectedQuestionInfo = selectedQuestions.find(
      (p) => p.guid.toLowerCase() === question?.questionId.toLowerCase()
    );

    if (
      selectedQuestionInfo &&
      selectedQuestionInfo.type === AssessmentQuestionType.RANGE &&
      selectedQuestionInfo.maxNumberValue <
        selectedQuestionInfo.minNumberValue &&
      yAxisType !== YAxisAssessmentFormChartType.NUMERIC
    ) {
      return (
        <AssessmentFormChartTooltip
          invertBy={selectedQuestionInfo.minNumberValue}
          question={question}
          {...props}
        />
      );
    }

    return <AssessmentFormChartTooltip question={question} {...props} />;
  };

  const handleMouseDown = (e: any) => {
    if (e && e.activeLabel) {
      setZoomModeConfig((prev) => ({
        ...prev,
        refAreaLeft: e.activeLabel,
      }));
    }
  };

  const handleMouseMove = (e: any) => {
    if (zoomModeConfig.refAreaLeft && e && e.activeLabel) {
      setZoomModeConfig((prev) => ({
        ...prev,
        refAreaRight: e.activeLabel,
      }));
    }
  };

  const handleResetZoom = () => {
    setZoomModeConfig((prev) => ({
      ...prev,
      left: undefined,
      right: undefined,
      refAreaLeft: undefined,
      refAreaRight: undefined,
      leftClosestIndex: undefined,
      rightClosestIndex: undefined,
    }));
  };

  const chartDomain = useMemo(() => {
    let domainArray: any[] = [];

    if (zoomModeConfig.left) {
      domainArray.push(zoomModeConfig.left);
    } else {
      if (dateRange && dateRange.from) {
        domainArray.push(dateRange.from.getTime());
      } else {
        domainArray.push('dataMin');
      }
    }

    if (zoomModeConfig.right) {
      domainArray.push(zoomModeConfig.right);
    } else {
      if (dateRange && dateRange.to) {
        domainArray.push(dateRange.to.getTime());
      } else {
        domainArray.push('dataMax');
      }
    }

    return domainArray;
  }, [dateRange, zoomModeConfig.left, zoomModeConfig.right]);

  const ticks = useMemo(() => {
    let ticksArray: number[] = [];

    if (zoomModeConfig && zoomModeConfig.left && zoomModeConfig.right) {
      let startDate = new Date();
      startDate.setTime(zoomModeConfig.left);

      const endDate = new Date();
      endDate.setTime(zoomModeConfig.right);

      while (addSeconds(startDate, secondsInterval) <= endDate) {
        ticksArray.push(startDate.getTime());
        startDate = addSeconds(startDate, secondsInterval);
      }

      ticksArray.push(endDate.getTime());

      return ticksArray;
    }

    if (dateRange && dateRange.from && dateRange.to) {
      const interval = returnIntervalBaseOnDates(
        dateRange.from.getTime(),
        dateRange.to.getTime()
      );
      let startDate = alignDateToClosestForSecondsGranularity(
        interval,
        dateRange.from.getTime()
      );
      let endDate = alignDateToClosestForSecondsGranularity(
        interval,
        dateRange.to.getTime(),
        true
      );

      while (addSeconds(startDate, interval) <= endDate) {
        ticksArray.push(startDate.getTime());
        startDate = addSeconds(startDate, interval);
      }

      ticksArray.push(addHours(endDate, -1).getTime());
    }

    return ticksArray;
  }, [dateRange, secondsInterval, zoomModeConfig]);

  const differenceInDays = getDifferenceOfTimestamps(ticks);

  const xAxisTickFormatter = (value: any) => {
    if (differenceInDays > MAXIMUM_DIFFERENCE_TO_DISPLAY_HOURS) {
      return ` ${format(value, 'dd.MM')} `;
    }
    return ` ${format(value, 'dd.MM HH:mm')} `;
  };

  let yAxisTicks: number[] | undefined;
  yAxisTicks = useMemo(() => {
    if (selectedQuestions && selectedQuestions.length > 0) {
      let minValue: number;
      let maxValue: number;
      const rangeQuestion =
        selectedQuestions[0].type === AssessmentQuestionType.RANGE;

      if (rangeQuestion) {
        minValue =
          selectedQuestions[0].minNumberValue >
          selectedQuestions[0].maxNumberValue
            ? selectedQuestions[0].maxNumberValue
            : selectedQuestions[0].minNumberValue;
        maxValue =
          selectedQuestions[0].minNumberValue >
          selectedQuestions[0].maxNumberValue
            ? selectedQuestions[0].minNumberValue
            : selectedQuestions[0].maxNumberValue;
      } else {
        const selectedSentimentScores = selectedQuestions
          .map((q) => q.answers)
          .flat()
          .map((p) => p.sentiment_score);

        minValue = Math.min(...selectedSentimentScores);
        maxValue = Math.max(...selectedSentimentScores);
      }

      const interval = (maxValue - minValue) / 4;

      const ticks: number[] = [];

      for (let i = 0; i < 5; i++) {
        ticks.push(minValue + i * interval);
      }

      return ticks;
    }

    return undefined;
  }, [selectedQuestions]);

  const questionNumericDomain = useMemo(() => {
    const questionsIncludedInChart = template.questions.filter((p) =>
      questions
        .map((p) => p.questionId.toLowerCase())
        .includes(p.guid.toLowerCase())
    );

    const minNumberValues = questionsIncludedInChart.map(
      (p) => p.minNumberValue
    );
    const maxNumberValues = questionsIncludedInChart.map(
      (p) => p.maxNumberValue
    );

    return [
      Math.min(...[...minNumberValues, ...maxNumberValues]),
      Math.max(...[...minNumberValues, ...maxNumberValues]),
    ];
  }, [questions, template.questions]);

  return (
    <div className="d-flex flex-column" style={{ userSelect: 'none' }}>
      {zoomModeConfig.left !== undefined && zoomModeConfig.right !== undefined && (
        <Button
          color="primary"
          outline
          className="me-3 align-self-end"
          onClick={handleResetZoom}
        >
          <FontAwesomeIcon icon={faArrows} className="me-1" />
          <FormattedMessage id="TimelineDetails.TrendsTimeline.zoomOut" />
        </Button>
      )}
      <ResponsiveContainer
        className="AssessmentFormChart"
        width={'100%'}
        height={400}
      >
        {chartType === TrendVisualizationChartType.BarChart ? (
          <BarChart
            data={
              zoomModeConfig.leftClosestIndex &&
              zoomModeConfig.rightClosestIndex
                ? formattedChartData.slice(
                    zoomModeConfig.leftClosestIndex,
                    zoomModeConfig.rightClosestIndex
                  )
                : formattedChartData
            }
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={zoom}
            margin={
              leftStretched
                ? {
                    left: -30,
                  }
                : {
                    left: 5,
                    bottom: 5,
                    right: 5,
                    top: 25,
                  }
            }
          >
            <CartesianGrid
              strokeDasharray="1 1"
              className="recharts-padded-grid"
              vertical={false}
            />
            <XAxis
              allowDataOverflow={true}
              dataKey="name"
              type="number"
              domain={chartDomain}
              ticks={ticks}
              tickMargin={15}
              tickFormatter={xAxisTickFormatter}
              padding={{ left: 30, right: 30 }}
            />
            {yAxisType === YAxisAssessmentFormChartType.NUMERIC ? (
              <YAxis
                type="number"
                axisLine={true}
                interval="preserveStartEnd"
                tickCount={questionNumericDomain[1] ?? 'dataMax'}
                domain={[
                  questionNumericDomain.at(0) ?? 'dataMin',
                  questionNumericDomain.at(1) ?? 'dataMax',
                ]}
                padding={{ top: 20 }}
                yAxisId="1"
              />
            ) : (
              <YAxis
                ticks={yAxisTicks}
                interval={0}
                tickMargin={30}
                axisLine={false}
                tick={(props) => (
                  <AssessmentFormChartYAxisTick ticks={yAxisTicks} {...props} />
                )}
                domain={['dataMin', 'dataMax']}
                padding={{ top: 20 }}
                yAxisId="1"
              />
            )}
            <Tooltip
              content={renderAssessmentFormChartTooltip}
              cursor={{ fill: '#e0e0e0' }}
            />
            {legend && (
              <Legend
                wrapperStyle={{ paddingTop: '20px' }}
                content={
                  <AssessmentFormChartLegend
                    questions={questions}
                    displayedQuestions={displayedQuestions}
                    setDisplayedQuestions={setDisplayedQuestions}
                  />
                }
              />
            )}
            {renderBars}
            {zoomModeConfig.refAreaLeft && zoomModeConfig.refAreaRight ? (
              <ReferenceArea
                yAxisId="1"
                x1={zoomModeConfig.refAreaLeft}
                x2={zoomModeConfig.refAreaRight}
                strokeOpacity={0.3}
              />
            ) : null}
          </BarChart>
        ) : (
          <LineChart
            data={
              zoomModeConfig.leftClosestIndex &&
              zoomModeConfig.rightClosestIndex
                ? formattedChartData.slice(
                    zoomModeConfig.leftClosestIndex,
                    zoomModeConfig.rightClosestIndex + 1
                  )
                : formattedChartData
            }
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={zoom}
            margin={
              leftStretched
                ? {
                    left: -30,
                  }
                : {
                    left: 5,
                    bottom: 5,
                    right: 5,
                    top: 25,
                  }
            }
          >
            <CartesianGrid
              className="recharts-padded-grid"
              strokeDasharray="1 1"
              vertical={false}
            />
            <XAxis
              allowDataOverflow={true}
              dataKey="name"
              type="number"
              domain={chartDomain}
              ticks={ticks}
              tickMargin={15}
              tickFormatter={xAxisTickFormatter}
              padding={{ left: 30, right: 30 }}
            />
            {yAxisType === YAxisAssessmentFormChartType.NUMERIC ? (
              <YAxis
                type="number"
                axisLine={true}
                interval="preserveStartEnd"
                tickCount={questionNumericDomain[1] ?? 'dataMax'}
                domain={[
                  questionNumericDomain[0] !== undefined
                    ? questionNumericDomain[0]
                    : 'dataMin',
                  questionNumericDomain[1] !== undefined
                    ? questionNumericDomain[1]
                    : 'dataMax',
                ]}
                padding={{ top: 20 }}
                yAxisId="1"
              />
            ) : (
              <YAxis
                ticks={yAxisTicks}
                interval={0}
                tickMargin={30}
                axisLine={false}
                tick={(props) => (
                  <AssessmentFormChartYAxisTick ticks={yAxisTicks} {...props} />
                )}
                domain={['dataMin', 'dataMax']}
                padding={{ top: 20 }}
                yAxisId="1"
              />
            )}
            <Tooltip
              content={renderAssessmentFormChartTooltip}
              cursor={{ fill: '#e0e0e0' }}
            />
            {legend && (
              <Legend
                wrapperStyle={{ paddingTop: '20px' }}
                content={
                  <AssessmentFormChartLegend
                    questions={questions}
                    displayedQuestions={displayedQuestions}
                    setDisplayedQuestions={setDisplayedQuestions}
                  />
                }
              />
            )}
            {renderLines}
            {zoomModeConfig.refAreaLeft && zoomModeConfig.refAreaRight ? (
              <ReferenceArea
                yAxisId="1"
                x1={zoomModeConfig.refAreaLeft}
                x2={zoomModeConfig.refAreaRight}
                strokeOpacity={0.3}
              />
            ) : null}
          </LineChart>
        )}
      </ResponsiveContainer>
    </div>
  );
};

export default AssessmentFormChart;
