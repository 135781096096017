import { useTabs } from 'modules/Education/hooks/useTabs';
import React from 'react';
import TabLayout, {
  TabLayoutType,
} from '../../../../../../../../components/layouts/TabLayout';
import TabContainer from '../../../../../../../../components/Tabs/components/TabContainer';

type EducationTabProps = {
  innerTitle?: boolean;
  innerSubtitle?: boolean;
};

const EducationTab = ({
  innerTitle = true,
  innerSubtitle = true,
}: EducationTabProps) => {
  const { tabItems, onTabChange } = useTabs(innerTitle, innerSubtitle);

  return (
    <TabLayout
      type={TabLayoutType.Breadcrumb}
      titlePrefix="Organizations.Education"
    >
      <div className="Education">
        <TabContainer items={tabItems} onTabChange={onTabChange} />
      </div>
    </TabLayout>
  );
};

export default EducationTab;
