import { IObservation } from '../../../../../interfaces/timeline';
import { useMemo } from 'react';
import useCultures from '../../../../../hooks/cultures/useCultures';
import { AVAILABLE_CULTURES } from '../../../../CultureSelector/CultureSelector';
import { AllSqueezesQueries } from '../../../types';

export const useObservationsWithMeanings = (
  observations: IObservation[],
  queries: AllSqueezesQueries
) => {
  const { currentCulture } = useCultures();

  const observationsWithMeanings = useMemo(() => {
    if (
      currentCulture.culture === AVAILABLE_CULTURES.EN ||
      !queries.observationMeanings.data
    )
      return observations;

    return observations.map((observation) => {
      const meaningNameNo = queries.observationMeanings.data?.find(
        (p) => p.name_en === observation.meaning_name
      )?.name_no;

      return {
        ...observation,
        meaning_name: meaningNameNo ?? observation.meaning_name,
      };
    });
  }, [currentCulture.culture, queries.observationMeanings.data, observations]);

  return {
    observationsWithMeanings,
    isLoading: queries.observationMeanings.isLoading,
  };
};
