import { format } from 'date-fns';
import { useCallback, useRef } from 'react';
import { Button } from 'reactstrap';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import {
  COLOR_DEFINITIONS,
  LEVEL_CATEGORY_COLORS,
  TimelineObservationProps,
} from './index';
import SqueezeStrengthTooltip from './SqueezeStrengthTooltip';
import { useCurrentPng } from 'recharts-to-png';
import SVG from 'react-inlinesvg';

//images
import downloadIcon from 'assets/images/timeline_details/download.svg';
import { FormattedMessage } from 'react-intl';
import {
  IObservation,
  ObservationCategory,
  ObservationSymptom,
} from 'interfaces/timeline';
import CustomYAxis from './CustomYAxis';
import useContainer from 'hooks/useContainer';
import { calculateVoltage } from './MomentaryAmplitudeModal';
import { wrapWithSequenceIdentified } from '../../../../../../helpers/utils/data';

interface SqueezeStrengthInTimeProps extends TimelineObservationProps {
  setSelectedObservations: (observation: IObservation[]) => void;
  caseTitle: string;
}

const CHART_HEIGHT = 500;
const BAR_SIZE = 100;
const Y_AXIS_WIDTH = 60;

const ChartLegend = () => {
  return (
    <div className="d-flex flex-wrap w-100 justify-content-center">
      <div key="symptomLow" className="d-flex align-items-center ps-2 pe-2">
        <div
          style={{
            width: '14px',
            marginRight: '8px',
            border: `1px solid ${
              LEVEL_CATEGORY_COLORS[ObservationCategory.CalibrationMin]
            }`,
          }}
        />
        <FormattedMessage id="Cases.MomentarySessionDetails.SqueezeStrengthInTime.symptomLow" />
      </div>
      <div key="symptomMedium" className="d-flex align-items-center ps-2 pe-2">
        <div
          style={{
            width: '14px',
            marginRight: '8px',
            border: `1px solid ${COLOR_DEFINITIONS.BLUE}`,
          }}
        />
        <FormattedMessage id="Cases.MomentarySessionDetails.SqueezeStrengthInTime.symptomMedium" />
      </div>
      <div key="symptomHigh" className="d-flex align-items-center ps-2 pe-2">
        <div
          style={{
            width: '14px',
            marginRight: '8px',
            border: `1px solid ${
              LEVEL_CATEGORY_COLORS[ObservationCategory.CalibrationHard]
            }`,
          }}
        />
        <FormattedMessage id="Cases.MomentarySessionDetails.SqueezeStrengthInTime.symptomHigh" />
      </div>
    </div>
  );
};

const SqueezeStrengthInTime = ({
  timelineObservations,
  setSelectedObservations,
  caseTitle,
}: SqueezeStrengthInTimeProps) => {
  const filteredObservations = timelineObservations.filter(
    (obs) => obs.symptom !== ObservationSymptom.Calibration
  );

  const mappedObservations = filteredObservations?.map((el) => ({
    name: el.timestamp,
    value: el.level,
  }));

  const formatXAxis = (val: string) => {
    const dateObj = new Date(val);

    return format(dateObj, 'HH:mm:ss');
  };

  const renderTooltip = (props) => {
    return (
      <SqueezeStrengthTooltip
        timelineObservations={filteredObservations}
        observations={mappedObservations}
        {...props}
      />
    );
  };

  const [getPng, { ref: chartRef }] = useCurrentPng();

  const handleDownload = useCallback(async () => {
    const png = await getPng();
    if (png) {
      const download = document.createElement('a');
      download.href = png;
      download.download = 'chart.png';
      download.click();
    }
  }, [getPng]);

  const selectObservation = (props) => {
    if (props?.activeLabel) {
      const elementToRender = filteredObservations.find(
        (obs) => obs.timestamp === props.activeLabel
      );

      elementToRender && setSelectedObservations([elementToRender]);
    }
  };

  const ticks = [...Array(11)].map((_val, index) => index);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const { containerWidth } = useContainer(containerRef, {
    isDayByDayScreen: false,
  });

  const containerWidthMinusYAxis = containerWidth - 50;
  const width = mappedObservations.length * BAR_SIZE;

  const calculatedWidth =
    width > containerWidthMinusYAxis ? width : containerWidthMinusYAxis;

  const customLabel = (props) => {
    const { x, y, stroke, value, width } = props;

    const observationSelected = filteredObservations.find(
      (obs) => obs.timestamp === props.name
    );

    const amplitudes = observationSelected?.amplitudes ?? [];

    const maxAmplitude = Math.max(...amplitudes);

    return (
      <text
        x={x + width / 2}
        y={y}
        dy={-4}
        fill={stroke}
        textAnchor="middle"
        style={{ fontSize: '14px' }}
      >
        {value}
        {` (${Math.round(calculateVoltage(maxAmplitude) * 100) / 100}V)`}
      </text>
    );
  };

  const getColorBasedOnSymptom = (value: number) => {
    if (value <= 4)
      return LEVEL_CATEGORY_COLORS[ObservationCategory.CalibrationMin];

    if (value <= 7) return COLOR_DEFINITIONS.BLUE;

    return LEVEL_CATEGORY_COLORS[ObservationCategory.CalibrationMax];
  };

  return (
    <div className="MomentarySessionDetailsContainer">
      <div className="w-100 d-flex justify-content-between align-items-center">
        <h3 className="MomentarySessionDetails__title">
          <FormattedMessage id="MomentarySession.session" /> {caseTitle}
        </h3>
        <div className="d-flex w-100 justify-content-end align-items-center mt-1">
          <div className="BubbleChart__button-wrapper">
            <Button
              onClick={handleDownload}
              className="BubbleChart__button BubbleChart__button--action"
            >
              <SVG src={downloadIcon} />
            </Button>
            <label>
              <FormattedMessage id="MomentarySession.download" />
            </label>
          </div>
        </div>
      </div>
      <div className="MomentarySessionChartWrapper" ref={containerRef}>
        <CustomYAxis
          height={CHART_HEIGHT}
          ticks={ticks}
          paddingTop={50}
          paddingBottom={36}
          width={Y_AXIS_WIDTH}
        />
        <BarChart
          data={mappedObservations}
          onMouseDown={selectObservation}
          ref={chartRef}
          height={CHART_HEIGHT}
          width={calculatedWidth}
          margin={{
            top: 0,
            right: 0,
            left: 0,
            bottom: 0,
          }}
        >
          <XAxis
            dataKey="name"
            orientation="top"
            tickFormatter={formatXAxis}
            tickLine={{ stroke: COLOR_DEFINITIONS.GRAY }}
            axisLine={{ stroke: COLOR_DEFINITIONS.WHITE }}
          />
          <YAxis
            dataKey="value"
            allowDataOverflow={false}
            type="number"
            domain={[0, 10]}
            tickCount={11}
            interval="preserveStart"
            padding={{ top: 20 }}
            tickLine={{ stroke: COLOR_DEFINITIONS.WHITE }}
            axisLine={{ stroke: COLOR_DEFINITIONS.WHITE }}
            hide
          />
          <Tooltip
            content={renderTooltip}
            cursor={{ fill: COLOR_DEFINITIONS.GRAY }}
          />
          <CartesianGrid vertical={false} strokeDasharray="4" />
          <Bar
            dataKey="value"
            fill={LEVEL_CATEGORY_COLORS[ObservationCategory.CalibrationMin]}
            label={customLabel}
          >
            {wrapWithSequenceIdentified(mappedObservations).map((entry) => (
              <Cell
                key={entry.sequence}
                fill={getColorBasedOnSymptom(entry.value)}
              />
            ))}
          </Bar>
          <Legend
            verticalAlign="bottom"
            height={36}
            iconType="line"
            wrapperStyle={{
              paddingTop: '10px',
            }}
            content={ChartLegend}
          />
        </BarChart>
      </div>
    </div>
  );
};

export default SqueezeStrengthInTime;
