import { getTimelineShareResponses } from 'api/graspManagement';
import QueryKeys from 'api/queries/queryKeys';
import { TimelineQueryParams, UserQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { useQuery } from 'react-query';

type UseSharingTimelineResponsesParams = UserQueryParams & TimelineQueryParams;

function useSharingTimelineResponses({
  params,
  options,
}: IQueryRequest<UseSharingTimelineResponsesParams>) {
  const { userId, timelineId } = params;
  return useQuery({
    ...options,
    queryKey: [QueryKeys.getTimelineSharingResponses, timelineId],
    queryFn: async () => {
      const { data } = await getTimelineShareResponses(userId, timelineId);
      return data.data;
    },
  });
}

export default useSharingTimelineResponses;
