import { DEFAULT_COUNTRY_PREFIX } from 'helpers/constants';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import PhoneInput, { CountryData } from 'react-phone-input-2';

type EditablePhoneInputProps = {
  value?: string;
  name: string;
  onEdit: (name: string, val: string) => void;
  isDisabled: boolean;
};

const EditablePhoneInput = ({
  value,
  name,
  onEdit,
  isDisabled,
}: EditablePhoneInputProps) => {
  const intl = useIntl();
  const [input, setInput] = useState<typeof value | null>(null);

  useEffect(() => {
    setInput(value);
  }, [value]);

  const onEditPhoneNumber = (
    _value: string,
    _data: {} | CountryData,
    _event: React.ChangeEvent<HTMLInputElement>,
    formattedValue: string
  ) => {
    onEdit(name, formattedValue);
  };

  return (
    <PhoneInput
      onChange={onEditPhoneNumber}
      inputProps={{
        id: name,
        name,
        disabled: isDisabled,
        placeholder: intl.formatMessage({ id: 'General.phoneNumber' }),
      }}
      value={input || undefined}
      countryCodeEditable={false}
      country={DEFAULT_COUNTRY_PREFIX}
      inputClass="EditablePhoneInput"
    />
  );
};

export default EditablePhoneInput;
