import EditableInput from 'components/EditableInput/EditableInput';
import PersonalityImage from 'components/PersonalityImage';
import SingleDescription from 'components/SingleDescription';
import SkeletonPlaceholderOnLoad from 'components/SkeletonPlaceholderOnLoad';
import { IPersonality, PatientFormKeys } from 'interfaces/personalities';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { Col } from 'reactstrap';
import { EmptyWrapper } from './PatientHeader';

type PersonalityMainDataProps = {
  data?: IPersonality;
  isLoading: boolean;
  editDisabled: boolean;
  onEdit: (target: string, value: string) => void;
};

const PersonalityMainData = ({
  data,
  isLoading,
  editDisabled,
  onEdit,
}: PersonalityMainDataProps) => {
  if (editDisabled) {
    return (
      <Col
        md={3}
        className="PersonalityHeader__main-data PersonalityHeader__container text-center d-flex justify-content-center align-items-center"
      >
        <div>
          <div className="d-flex justify-content-center">
            <PersonalityImage size="big" />
          </div>
          <label className="PersonalityHeader__main-data__label">
            <FormattedMessage id="Patient.fullName" />
          </label>
          <SkeletonPlaceholderOnLoad isLoading={isLoading} block>
            <div className="PersonalityHeader__main-data__full-name">
              {data?.first_name} {data?.last_name}
            </div>
          </SkeletonPlaceholderOnLoad>
          <SkeletonPlaceholderOnLoad isLoading={isLoading} block>
            <div className="PersonalityHeader__main-data__email">
              {data?.email}
            </div>
          </SkeletonPlaceholderOnLoad>
        </div>
      </Col>
    );
  } else
    return (
      <Col
        md={3}
        className="PersonalityHeader__container PersonalityHeader__main-data--edit"
      >
        <SingleDescription label={'Patient.firstName'} labelFor="first_name">
          <SkeletonPlaceholderOnLoad
            isLoading={isLoading}
            placeholder={<Skeleton width={100} />}
          >
            <EmptyWrapper value={data?.first_name} editDisabled={editDisabled}>
              <EditableInput
                value={data?.first_name}
                name="first_name"
                onEdit={onEdit}
                debounceEnabled={false}
              />
            </EmptyWrapper>
          </SkeletonPlaceholderOnLoad>
        </SingleDescription>
        <SingleDescription label={'Patient.lastName'} labelFor="last_name">
          <SkeletonPlaceholderOnLoad
            isLoading={isLoading}
            placeholder={<Skeleton width={100} />}
          >
            <EmptyWrapper value={data?.last_name} editDisabled={editDisabled}>
              <EditableInput
                value={data?.last_name}
                name="last_name"
                onEdit={onEdit}
                debounceEnabled={false}
              />
            </EmptyWrapper>
          </SkeletonPlaceholderOnLoad>
        </SingleDescription>
        <SingleDescription
          label={'Patient.FormLabels.email'}
          labelFor={PatientFormKeys.EMAIL}
        >
          <SkeletonPlaceholderOnLoad
            isLoading={isLoading}
            placeholder={<Skeleton width={100} />}
          >
            <EmptyWrapper value={data?.email} editDisabled={editDisabled}>
              <EditableInput
                value={data?.email}
                name={PatientFormKeys.EMAIL}
                onEdit={onEdit}
                debounceEnabled={false}
              />
            </EmptyWrapper>
          </SkeletonPlaceholderOnLoad>
        </SingleDescription>
      </Col>
    );
};

export default PersonalityMainData;
