import request from 'api/utils/request';
import { getOdataFilterParams } from 'helpers/utils/getOdataParams';
import { normalizeQueryParams } from 'helpers/utils/normalizeQueryParams';
import { IActivity } from 'interfaces/activities';
import { RequestActions, StatusEnum } from 'interfaces/enums';
import {
  IOneTimePasswordResponse,
  IPersonalityDetails,
} from 'interfaces/membership';
import {
  IInvitationDetails,
  IMemberAccessRequest,
  IOrganizationPatientDetails,
  IPersonality,
  ISharing,
  IStrictPersonality,
  MemberWithAccess,
} from 'interfaces/personalities';
import { IParamsTypeInRequest } from 'interfaces/reducer';
import {
  IPatchObject,
  IRequestAccessRequestBody,
  ODataParamType,
} from 'interfaces/request';
import { IResponse, IResponseWithPagination } from 'interfaces/response';

export const editCase = (
  organizationId: string,
  caseId: string,
  patchData: IPatchObject[]
) =>
  request({
    url: `/organizations/${organizationId}/cases/${caseId}`,
    method: 'PATCH',
    data: patchData,
  });

export const deleteCase = (organizationId: string, caseId: string) =>
  request({
    url: `/organizations/${organizationId}/cases/${caseId}`,
    method: 'DELETE',
  });

export const getSharingRequestsForUser = (userId: string) =>
  request<undefined, IResponse<ISharing[]>>({
    url: `/users/${userId}/timeline_requests`,
    method: 'GET',
  });

export const acceptShareRequest = (
  userId: string,
  requestId: string,
  timelines: string[]
) =>
  request<{ timelines: string[] }, undefined>({
    url: `/users/${userId}/timeline_requests/${requestId}/approve`,
    method: 'POST',
    data: {
      timelines,
    },
  });

export const rejectShareRequest = (userId: string, requestId: string) =>
  request<never, undefined>({
    url: `/users/${userId}/timeline_requests/${requestId}/reject`,
    method: 'POST',
  });

export const editPersonality = (personalityId: string, data: IPatchObject[]) =>
  request<IPatchObject[], IResponse<IPersonality>>({
    url: `/personalities/${personalityId}`,
    method: 'PATCH',
    data,
  });

export const getPersonalityWithId = (personalityId: string) =>
  request<never, IPersonality>({
    url: `/personalities/${personalityId}`,
    method: 'GET',
  });

export const getUserActivity = (
  userId: string,
  params: Partial<IParamsTypeInRequest>,
  oDataParams?: ODataParamType[]
) => {
  return request<never, IResponseWithPagination<IActivity[]>>({
    url: `/users/${userId}/activities${normalizeQueryParams(params)}${
      oDataParams
        ? getOdataFilterParams(oDataParams, { startCharacter: '&' })
        : ''
    }`,
    method: 'GET',
  });
};

export const getInvitationDetails = (
  organizationId: string,
  invitationId: string
) =>
  request<never, IResponse<IInvitationDetails>>({
    method: 'GET',
    url: `organizations/${organizationId}/invitation_requests/${invitationId}`,
  });

export const invitationAccept = (userId: string, invitationId: string) =>
  request({
    method: 'POST',
    url: `/users/${userId}/requests/${invitationId}/approve`,
  });

export const invitationReject = (userId: string, invitationId: string) =>
  request({
    method: 'POST',
    url: `/users/${userId}/requests/${invitationId}/reject`,
  });

export const sendPatientInvite = (organizationId: string, userEmail: string) =>
  request({
    url: `/organizations/${organizationId}/invitation_requests${normalizeQueryParams(
      { userEmail }
    )}`,
    method: 'POST',
  });

export const getOrganizationPatientsWithPaging = async (
  organizationId: string,
  reduxParams: Partial<IParamsTypeInRequest>
) => {
  return request<undefined, IResponseWithPagination<IPersonality[]>>({
    url: `/organizations/${organizationId}/participants${normalizeQueryParams(
      reduxParams
    )}`,
    method: 'GET',
  });
};

export const getOneTimeToken = (userId: string) => {
  return request<undefined, IResponse<IOneTimePasswordResponse>>({
    url: `users/${userId}/oneTimePassword`,
    method: 'GET',
  });
};

export const getUserPersonalData = (userId: string) => {
  return request<undefined, IResponse<IPersonalityDetails>>({
    url: `users/${userId}`,
    method: 'GET',
  });
};

export const requestPatientAccess = (
  organizationId: string,
  data: IRequestAccessRequestBody
) => {
  return request<IRequestAccessRequestBody, void>({
    url: `/organizations/${organizationId}/participants/member_accesses/requests`,
    method: 'POST',
    data,
  });
};

export const getOrganizationPatientDetails = (
  organizationId: string,
  participantId: string
) => {
  return request<undefined, IResponse<IOrganizationPatientDetails>>({
    url: `/organizations/${organizationId}/participants/${participantId}`,
    method: 'GET',
  });
};

export const putOrganizationPatientMembersAccess = (
  organizationId: string,
  participantId: string,
  participant_accesses: MemberWithAccess[]
) => {
  return request<{ participant_accesses: MemberWithAccess[] }, void>({
    url: `/organizations/${organizationId}/participants/${participantId}/member_accesses`,
    method: 'PUT',
    data: {
      participant_accesses,
    },
  });
};

export const getStrictPersonalities = (
  organizationId: string,
  fullName?: string,
  patientNumber?: number
) => {
  return request<void, IResponse<IStrictPersonality[]>>({
    url: `/organizations/${organizationId}/participants_strict${normalizeQueryParams(
      { fullName, patientNumber }
    )}`,
    method: 'GET',
  });
};

export const getOrganizationMemberRequests = (
  organizationId: string,
  params: Partial<IParamsTypeInRequest>,
  status: StatusEnum | null
) => {
  let oDataFilterParams: ODataParamType[] = [];
  if (status) {
    oDataFilterParams.push({
      key: 'Status',
      operator: 'eq',
      value: `'${status}'`,
    });
  }

  return request<void, IResponseWithPagination<IMemberAccessRequest[]>>({
    url: `/organizations/${organizationId}/participants/member_accesses/requests/${normalizeQueryParams(
      params
    )}${getOdataFilterParams(oDataFilterParams, {
      startCharacter: '&',
    })}`,
    method: 'GET',
  });
};

export const modifyMemberRequest = (
  organizationId: string,
  requestId: string,
  action: RequestActions
) => {
  return request<void, void>({
    url: `organizations/${organizationId}/participants/member_accesses/requests/${requestId}/${action}`,
    method: 'POST',
  });
};
