import { ICreateNotificationSchedule } from '../../../interfaces/notificationSchedules';
import { UserCaseQueryParams } from '../../../interfaces/queryParams';
import { IMutationRequest } from '../../../interfaces/request';
import { IErrorDefinition } from '../../../interfaces/response';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { createNotificationSchedule } from '../../notificationSchedules';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

const useCreateNotificationSchedule = ({
  params,
  options,
}: IMutationRequest<UserCaseQueryParams>) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (createSchedule: ICreateNotificationSchedule) => {
      await createNotificationSchedule(
        params.userId,
        params.caseId,
        createSchedule
      );
    },
    onSuccess: () => {
      displaySuccessNotification(
        'Api.Mutations.NotificationSchedules.CreateNotificationSchedule.success'
      );
      options.successFb?.();
    },
    onErrorArray: (errors: IErrorDefinition[]) => {
      displayErrorArrayNotifications(
        errors,
        'Api.Mutations.NotificationSchedules.CreateNotificationSchedule.failure'
      );
      options.errorFb?.();
    },
    resetOnResult: true,
  });
};

export default useCreateNotificationSchedule;
