import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import calendarIcon from 'assets/images/calendar_icon.svg';
import { cloneDeep } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import floppyDiscIcon from '../../../../../../../../../../../assets/images/floppy-disc.svg';
import pencilIcon from '../../../../../../../../../../../assets/images/pencil.svg';
import phaseIcon from '../../../../../../../../../../../assets/images/phase.svg';
import revertArrowIcon from '../../../../../../../../../../../assets/images/revert-arrow.svg';
import thumbtackIcon from '../../../../../../../../../../../assets/images/thumbtack.svg';
import trashIcon from '../../../../../../../../../../../assets/images/trash.svg';
import usersGroupIcon from '../../../../../../../../../../../assets/images/users-group.svg';
import EditableInput from '../../../../../../../../../../../components/EditableInput/EditableInput';
import {
  ThinHeaderActionDefinition,
  ThinHeaderItemDefinition,
} from '../../../../../../../../../../../components/ThinHeader/types/ThinHeader';
import { textWrapEllipse } from '../../../../../../../../../../../helpers/utils/strings';
import useOpen from '../../../../../../../../../../../hooks/useOpen';
import { ISummarizedTreatmentPhase } from '../../../../../../../../../../../interfaces/conversations';
import { useTreatmentPhaseCode } from '../../../../../hooks/useTreatmentPhaseCode';
import { useApplyPhase } from '../../../hooks/useApplyPhase';
import NextPhaseConditionModal from '../../../modals/NextPhaseConditionModal';

export const useThinHeader = (
  managedPhase: ISummarizedTreatmentPhase,
  setManagedPhase: (phase: ISummarizedTreatmentPhase) => void,
  initialPhase: ISummarizedTreatmentPhase,
  addMode: boolean
) => {
  const intl = useIntl();

  const editMode = useOpen(addMode);

  const getPhaseCode = useTreatmentPhaseCode();

  const handleEditBasicInfo = useCallback(
    (target: string, value: string) => {
      let managedPhaseCopy = cloneDeep(managedPhase);
      managedPhaseCopy[target] = value;
      setManagedPhase(managedPhaseCopy);
    },
    [managedPhase, setManagedPhase]
  );

  const handleEditDefaultPhase = useCallback(() => {
    let managedPhaseCopy = cloneDeep(managedPhase);
    managedPhaseCopy.is_default = !managedPhaseCopy.is_default;
    setManagedPhase(managedPhaseCopy);
  }, [managedPhase, setManagedPhase]);

  const handleRevert = useCallback(() => {
    setManagedPhase(cloneDeep(initialPhase));
    editMode.toggle();
  }, [editMode, initialPhase, setManagedPhase]);

  const { thinHeader: handleThinHeaderApply, isUpdating } = useApplyPhase(
    addMode,
    editMode.toggle
  );

  const handleApply = useCallback(async () => {
    await handleThinHeaderApply(initialPhase, managedPhase);
  }, [handleThinHeaderApply, initialPhase, managedPhase]);

  const nonePlaceholder = intl.formatMessage({
    id: 'TreatmentPlans.TreatmentPlan.TreatmentPlanContent.TreatmentPhase.none',
  });

  const thinHeaderItems = useMemo((): ThinHeaderItemDefinition[] => {
    const items: ThinHeaderItemDefinition[] = [
      {
        key: 'code',
        header: intl.formatMessage({
          id: 'TreatmentPlans.TreatmentPlan.TreatmentPlanContent.TreatmentPhase.code',
        }),
        title: getPhaseCode(managedPhase.id),
        icon: thumbtackIcon,
      },
      {
        key: 'display-code',
        header: intl.formatMessage({
          id: 'TreatmentPlans.TreatmentPlan.TreatmentPlanContent.TreatmentPhase.displayCode',
        }),
        title: managedPhase.display_code ?? nonePlaceholder,
        icon: thumbtackIcon,
      },
      {
        key: 'days-duration',
        header: intl.formatMessage({
          id: 'TreatmentPlans.TreatmentPlan.TreatmentPlanContent.TreatmentPhase.daysDuration',
        }),
        title: managedPhase.days_duration.toString(),
        icon: calendarIcon,
      },
      {
        key: 'next-phase-condition',
        header: intl.formatMessage({
          id: 'TreatmentPlans.TreatmentPlan.TreatmentPlanContent.TreatmentPhase.nextPhaseCondition',
        }),
        title: managedPhase.next_phase_condition
          ? textWrapEllipse(managedPhase.next_phase_condition, 30)
          : nonePlaceholder,
        icon: phaseIcon,
      },
      {
        key: 'active-participants',
        header: intl.formatMessage({
          id: 'TreatmentPlans.TreatmentPlan.TreatmentPlanContent.TreatmentPhase.activeParticipants',
        }),
        title: managedPhase.users_count.toString(),
        icon: usersGroupIcon,
      },
      {
        key: 'is-default',
        header: (
          <div className="d-flex align-items-center gap-2">
            {!editMode.isOpen && (
              <EditableInput
                type="checkbox"
                disabled={true}
                checked={managedPhase.is_default}
                name="isDefault"
                debounceEnabled={false}
              />
            )}
            <FormattedMessage
              tagName="span"
              id="TreatmentPlans.TreatmentPlan.TreatmentPlanContent.TreatmentPhase.initialPhase"
            />
          </div>
        ),
      },
    ];

    if (editMode.isOpen) {
      items[0].editable = {
        name: 'id',
        value: getPhaseCode(managedPhase.id),
        inputType: 'text',
        onEdit: handleEditBasicInfo,
      };

      items[1].editable = {
        name: 'display_code',
        value: managedPhase.display_code,
        inputType: 'text',
        onEdit: handleEditBasicInfo,
      };

      items[2].editable = {
        name: 'days_duration',
        value: managedPhase.days_duration,
        inputType: 'number',
        onEdit: handleEditBasicInfo,
      };

      items[3].editable = {
        name: 'next_phase_condition',
        value: managedPhase.next_phase_condition,
        inputType: 'text',
        onEdit: handleEditBasicInfo,
        ExternalEditComponent: NextPhaseConditionModal,
      };

      items[5].editable = {
        name: 'is_default',
        value: managedPhase.is_default,
        inputType: 'checkbox',
        onEdit: handleEditDefaultPhase,
      };
    }

    return items;
  }, [
    intl,
    getPhaseCode,
    managedPhase.id,
    managedPhase.display_code,
    managedPhase.days_duration,
    managedPhase.next_phase_condition,
    managedPhase.users_count,
    managedPhase.is_default,
    nonePlaceholder,
    editMode.isOpen,
    handleEditBasicInfo,
    handleEditDefaultPhase,
  ]);

  const thinHeaderActions = useMemo((): ThinHeaderActionDefinition[] => {
    const editModeActions: ThinHeaderActionDefinition[] = [
      {
        key: 'apply',
        icon: isUpdating ? faSpinner : floppyDiscIcon,
        isLoading: isUpdating,
        onAction: handleApply,
      },
      {
        key: 'revert',
        icon: revertArrowIcon,
        onAction: handleRevert,
      },
    ];

    const displayModeActions: ThinHeaderActionDefinition[] = [
      {
        key: 'edit',
        icon: pencilIcon,
        onAction: editMode.toggle,
      },
      {
        key: 'delete',
        disabled: true,
        icon: trashIcon,
      },
    ];

    return editMode.isOpen ? editModeActions : displayModeActions;
  }, [isUpdating, handleApply, handleRevert, editMode.toggle, editMode.isOpen]);

  return {
    items: thinHeaderItems,
    actions: thinHeaderActions,
  };
};
