import React from 'react';
import CaseCategoriesListLoading from './CaseCategoriesListLoading';
import CaseCategoriesListEmpty from './CaseCategoriesListEmpty';
import CaseCategoriesList from './CaseCategoriesList';
import { useCommonData } from '../../../hooks/useCommonData';
import { useInitQuery } from '../../../../../../../hooks/queries/useInitQuery';
import QueryLoaded from '../../../../../../../components/QueryLoaded';

const CaseCategoriesListView = () => {
  const { caseCategories } = useCommonData();
  useInitQuery(caseCategories);

  return (
    <QueryLoaded
      query={caseCategories}
      onLoading={<CaseCategoriesListLoading />}
    >
      {(data) => (
        <>
          {data.length === 0 ? (
            <CaseCategoriesListEmpty />
          ) : (
            <CaseCategoriesList
              refetch={caseCategories.refetch}
              categories={data}
            />
          )}
        </>
      )}
    </QueryLoaded>
  );
};

export default CaseCategoriesListView;
