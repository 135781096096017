import { useCallback } from 'react';
import { IAwareSessionData } from '../../../../../../../../../../../interfaces/awareSessions';

export const useSessionStart = () => {
  return useCallback(
    (session: IAwareSessionData) => () => {
      window.dispatchEvent(
        new CustomEvent('aware-session-started-request', {
          detail: {
            ...session,
          },
        })
      );
    },
    []
  );
};
