import { ChartsData, TrendsChartData } from '../types';
import { TemplateItemType } from '../../../interfaces/change';
import { useMemo } from 'react';
import {
  appendDataFromSeries,
  removeRedundantNotSpecified,
} from '../helpers/allObservations';
import { UseQueryResult } from 'react-query';
import { ISqueezeChartData } from '../../../interfaces/timeline';

export const useAllObservations = (
  enabled: boolean,
  observationsQuery: UseQueryResult<ISqueezeChartData>
): TrendsChartData<string | number> => {
  return useMemo((): TrendsChartData<string | number> => {
    if (!enabled) return { type: TemplateItemType.AllObservations, data: [] };

    const observations = observationsQuery.data;

    if (!observations)
      return { type: TemplateItemType.AllObservations, data: [] };

    let _data: ChartsData<string | number>[] = [];

    _data = appendDataFromSeries(observations, _data);
    _data = removeRedundantNotSpecified(_data);

    return { type: TemplateItemType.AllObservations, data: _data };
  }, [enabled, observationsQuery.data]);
};
