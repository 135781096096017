import { CaseGroupType } from '../../../../../interfaces/caseGroups';
import { useMemo } from 'react';
import { InputOption } from '../../../../../interfaces/ui';
import { formatDate } from '../../../../../helpers/dates';
import { ICase } from '../../../../../interfaces/cases';
import { validateEmpty } from '../../../../../helpers/utils/validators';
import { useIntl } from 'react-intl';
import { useCaseGroupsCommonData } from '../../../hooks/useCaseGroupsCommonData';

type MoveCaseModalFormInitialValues = {
  case_ids: string[];
  group_id: string;
};

export const useMoveCaseModalForm = (cases: ICase[]) => {
  const intl = useIntl();

  const initialValues: MoveCaseModalFormInitialValues = {
    case_ids: cases.map((p) => p.id),
    group_id: '',
  };

  const { caseGroups } = useCaseGroupsCommonData();

  const validation = (values: typeof initialValues) => {
    return validateEmpty<typeof initialValues>(values, intl);
  };

  const caseGroupsOptions: InputOption[] = useMemo(() => {
    if (!caseGroups.data) return [];

    const permanentCaseGroups = caseGroups.data.filter(
      (p) =>
        p.type === CaseGroupType.Permanent &&
        p.case_category_id === cases[0]?.category_id
    );

    return permanentCaseGroups.map((caseGroup) => {
      return {
        value: caseGroup.id,
        text: `Case group no: ${caseGroup.sequence}. Starting at: ${formatDate(
          caseGroup.starting_date
        )}`,
      };
    });
  }, [caseGroups, cases]);

  return {
    validation: validation,
    initialValues: initialValues,
    fieldsOptions: {
      caseGroups: caseGroupsOptions,
    },
  };
};
