type CustomYAxisProps = {
  height: number;
  ticks: number[];
  paddingBottom?: number;
  paddingTop?: number;
  width: number;
};

const CustomYAxis = ({
  height,
  ticks,
  paddingBottom = 0,
  paddingTop = 0,
  width,
}: CustomYAxisProps) => {
  const revertedTicks = [...ticks].reverse();
  const maxValue = ticks[ticks.length - 1];

  const calculatedHeight = height - paddingBottom - paddingTop;

  const mappedYAxis = revertedTicks.map((tick, index) => {
    let tickHeight = calculatedHeight - (tick / maxValue) * calculatedHeight;
    let textHeight = tickHeight;
    if (index === 0) {
      textHeight = textHeight + 6;
    }

    return (
      <g className="recharts-layer recharts-cartesian-axis-tick">
        <line
          className="recharts-cartesian-axis-tick-line"
          width={width}
          height={calculatedHeight}
          x="0"
          y={tickHeight}
          stroke="#666"
          fill="none"
          x1="54"
          y1={tickHeight}
          x2="60"
          y2={tickHeight}
        />
        <text
          width={width}
          height={calculatedHeight}
          x="52"
          y={textHeight}
          stroke="none"
          fill="#666"
          className="recharts-text recharts-cartesian-axis-tick-value"
          textAnchor="end"
        >
          <tspan x="52" dy="0.355em">
            {tick}
          </tspan>
        </text>
      </g>
    );
  });

  return (
    <div
      style={{
        position: 'sticky',
        left: 0,
        zIndex: 3,
        background: 'white',
        paddingTop,
      }}
    >
      <div></div>
      <svg width="60" height={height}>
        {mappedYAxis}
      </svg>
    </div>
  );
};

export default CustomYAxis;
