import React from 'react';
import useOpen from '../../../../../../../../hooks/useOpen';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersClass } from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage } from 'react-intl';
import CreateCaseGroupSessionModal from '../../modals/CreateCaseGroupSessionModal';

const CreateCaseGroupSessionItem = () => {
  const { isOpen, toggle } = useOpen();

  return (
    <>
      {isOpen && (
        <CreateCaseGroupSessionModal isOpen={isOpen} toggle={toggle} />
      )}
      <div
        className="CaseGroupCollectionItem CaseGroupCollectionItem--muted"
        onClick={toggle}
      >
        <div className="CaseGroupCollectionItem__icon CaseGroupCollectionItem__icon--muted">
          <FontAwesomeIcon icon={faUsersClass} />
        </div>
        <div className="CaseGroupCollectionItem__description CaseGroupCollectionItem__description--muted">
          <div className="CaseGroupCollectionItem__info">
            <FormattedMessage id="CaseGroupItem.addCaseGroupSession" />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateCaseGroupSessionItem;
