import React from 'react';
import { ICaseGroupFormValues } from '../../hooks/useCreateCaseGroupForm';
import InputFieldWithoutImage from '../../../../../../components/InputFieldWithoutImage';
import { useCreateCaseGroupFormTranslations } from '../../hooks/useCreateCaseGroupFormTranslations';
import { Field } from 'formik';
import FieldInput from '../../../../../../components/FieldInput';
import { FormattedMessage } from 'react-intl';
import { differenceInCalendarDays } from 'date-fns';
import { useCaseGroupsCommonData } from '../../../../hooks/useCaseGroupsCommonData';
import { useGroupScheduleData } from './hooks/useGroupScheduleData';
import { Spinner } from 'reactstrap';

type GroupScheduleProps = {
  values: ICaseGroupFormValues;
};

const GroupSchedule = ({ values }: GroupScheduleProps) => {
  const { caseCategories } = useCaseGroupsCommonData();
  const { treatmentPhases } = useGroupScheduleData(values.case_category_id);

  const translations = useCreateCaseGroupFormTranslations();

  const subCategories = caseCategories.data?.flatMap((p) => p.sub_categories);

  if (treatmentPhases.isLoading) {
    return (
      <div className="d-flex align-items-center justify-content-center gap-2">
        <Spinner color="primary" />
        <FormattedMessage id="CaseGroups.CreateCaseGroupModal.GroupSchedule.scheduleLoading" />
      </div>
    );
  }

  return (
    <>
      {values.group_schedule.map((schedule, index) => (
        <div
          className="CreateCaseGroupModalBodyGroupSchedule__item"
          key={schedule.phase_id}
        >
          <span className="CreateCaseGroupModalBodyGroupSchedule__item-title">
            {subCategories?.find((p) => p.id === schedule.phase_id)?.name_en ??
              ''}
          </span>
          <InputFieldWithoutImage
            gray={true}
            noMargin={true}
            labelTranslationId={translations.fromLabel}
            inputId={`group_schedule[${index}].date_from`}
          >
            <Field
              id={`group_schedule[${index}].date_from`}
              name={`group_schedule[${index}].date_from`}
              placeholder={translations.fromPlaceholder}
              type="date"
              component={FieldInput}
            />
          </InputFieldWithoutImage>
          <InputFieldWithoutImage
            gray={true}
            noMargin={true}
            labelTranslationId={translations.toLabel}
            inputId={`group_schedule[${index}].date_to`}
          >
            <Field
              id={`group_schedule[${index}].date_to`}
              name={`group_schedule[${index}].date_to`}
              placeholder={translations.toPlaceholder}
              type="date"
              component={FieldInput}
            />
          </InputFieldWithoutImage>
          <div className="align-self-end d-flex justify-content-center pb-2 w-50">
            <FormattedMessage
              id="CaseGroups.CreateCaseGroupModal.GroupSchedule.nDays"
              tagName="span"
              values={{
                days:
                  differenceInCalendarDays(
                    new Date(schedule.date_to),
                    new Date(schedule.date_from)
                  ) + 1,
              }}
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default GroupSchedule;
