import { createContext } from 'react';
import { ICategory } from '../../../../interfaces/cases';
import { ISummarizedTreatmentPhase } from '../../../../interfaces/conversations';
import { IOrganizationDetails } from '../../../../interfaces/membership';

export type ManagedTreatmentPhase = {
  isAdding: boolean;
  phase: ISummarizedTreatmentPhase;
};

export type TreatmentPlanDataContextType = {
  organization: IOrganizationDetails;
  categories: ICategory[];
  treatmentPhases: ISummarizedTreatmentPhase[];
};

export const TreatmentPlanDataContext = createContext<
  TreatmentPlanDataContextType | undefined
>(undefined);
