import React, { useContext } from 'react';
import FormHeadingActions from './components/FormHeadingActions';
import { useTranslations } from 'hooks/useTranslations';
import FieldApplicableInput from 'components/Fields/Formik/FieldApplicableInput';
import { TrendVisualizationModalFormikContext } from '../../types';
import { Field, FormikProps } from 'formik';
import { TrendVisualizationTemplate } from 'interfaces/trendVisualizations';
import FieldInput from 'components/FieldInput';

type FormHeadingProps = {
  toggle: () => void;
};

const FormHeading = ({ toggle }: FormHeadingProps) => {
  const { wrapWithIntl, attachPrefix } = useTranslations(
    'Organizations.OrganizationDetails.TrendVisualizations.TrendVisualizationModal.FormHeading'
  );

  const { initialValues, setFieldValue } = useContext(
    TrendVisualizationModalFormikContext
  ) as FormikProps<TrendVisualizationTemplate>;

  return (
    <div className="d-flex align-items-center justify-content-between flex-nowrap">
      <div>
        <FieldApplicableInput
          initialValues={initialValues}
          setFieldValue={setFieldValue}
          name="name"
          field={
            <Field
              required={true}
              placeholder={wrapWithIntl(attachPrefix('insertTemplateName'))}
              id="trend-visualization-template-name"
              name="name"
              type="text"
              component={FieldInput}
            />
          }
        />
      </div>
      <FormHeadingActions toggle={toggle} />
    </div>
  );
};

export default FormHeading;
