import { createContext } from 'react';
import { IAwareSessionData } from '../../../../../../../../../../interfaces/awareSessions';
import { IMemberDashboardReport } from '../../../../../../../../../../interfaces/dashboards';
import {
  IReferenced,
  ITotalRowsPage,
} from '../../../../../../../../../../interfaces/response';

export type VisualizationDataContextType = {
  sessions: IReferenced<ITotalRowsPage<IAwareSessionData>>;
  reports: IReferenced<ITotalRowsPage<IMemberDashboardReport>>;
  items: IReferenced<ITotalRowsPage<any>>;
};

export const VisualizationDataContext = createContext<
  VisualizationDataContextType | undefined
>(undefined);
