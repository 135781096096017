import { rejectShareRequest } from 'api/patients';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

type useTimelineShareRequestRejectProps = {
  userId: string;
  successFb?: () => void;
  errorFb?: () => void;
};

function useTimelineShareRequestReject({
  userId,
  errorFb,
  successFb,
}: useTimelineShareRequestRejectProps) {
  return useMutationWithResultNotification({
    asyncApiFunction: async (shareRequestId: string) => {
      await rejectShareRequest(userId, shareRequestId);
    },
    resetOnResult: true,
    onSuccess: successFb,
    onError: errorFb,
  });
}

export default useTimelineShareRequestReject;
