import FieldInput from 'components/FieldInput';
import LoadingButton from 'components/LoadingButton';
import { Field, Formik } from 'formik';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Form,
  FormGroup,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { FindGraspFormType } from 'interfaces/grasps';

const initialValues = {
  link_code: '',
};

type FindGraspProps = {
  onFind: (data: FindGraspFormType) => void;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
};

const FindGrasp: React.FC<FindGraspProps> = ({
  onFind,
  isLoading,
  isError,
  isSuccess,
}) => {
  const intl = useIntl();

  return (
    <Formik initialValues={initialValues} onSubmit={onFind}>
      {({ submitForm, isValid }) => (
        <>
          <ModalHeader>
            <FormattedMessage id="Grasps.findGrasp" />
          </ModalHeader>
          <ModalBody>
            <Form className="registerForm">
              <FormGroup>
                <label className="newMembersSection__label" htmlFor="link_code">
                  <FormattedMessage id="Grasps.linkCode" />
                </label>
                <Field
                  required
                  id="link_code"
                  name="link_code"
                  placeholder={intl.formatMessage({
                    id: 'Grasps.link',
                  })}
                  component={FieldInput}
                />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <LoadingButton
              disabled={!isValid}
              onClick={submitForm}
              outline
              isLoading={isLoading}
              isSuccess={isSuccess}
              isFailure={isError}
              type="submit"
              className="btn btn-login btn-short mb-0"
            >
              <FormattedMessage id="Grasps.findGrasp" />
            </LoadingButton>
          </ModalFooter>
        </>
      )}
    </Formik>
  );
};

export default FindGrasp;
