import { Field } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { Col, Row } from 'reactstrap';
import FieldInput from '../../../../../../components/FieldInput';
import InputFieldWithoutImage from '../../../../../../components/InputFieldWithoutImage';

const ProjectModalProjectLinksForm = () => {
  const intl = useIntl();

  return (
    <div className="d-flex flex-column gap-4">
      <Row>
        <Col xs={12}>
          <InputFieldWithoutImage
            gray={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.termsOfService"
            inputId="terms_of_service"
          >
            <Field
              id="terms_of_service"
              name="terms_of_service"
              type="text"
              placeholder={intl.formatMessage({
                id: 'Projects.ProjectModal.ProjectModalOverviewForm.linkPlaceholder',
              })}
              required={false}
              component={FieldInput}
            />
          </InputFieldWithoutImage>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <InputFieldWithoutImage
            gray={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.privacyPolicy"
            inputId="privacy_policy"
          >
            <Field
              id="privacy_policy"
              name="privacy_policy"
              type="text"
              placeholder={intl.formatMessage({
                id: 'Projects.ProjectModal.ProjectModalOverviewForm.linkPlaceholder',
              })}
              required={false}
              component={FieldInput}
            />
          </InputFieldWithoutImage>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          <InputFieldWithoutImage
            gray={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.participationConsent"
            inputId="participation_consent"
          >
            <Field
              id="participation_consent"
              name="participation_consent"
              type="text"
              placeholder={intl.formatMessage({
                id: 'Projects.ProjectModal.ProjectModalOverviewForm.linkPlaceholder',
              })}
              required={false}
              component={FieldInput}
            />
          </InputFieldWithoutImage>
        </Col>
      </Row>
    </div>
  );
};

export default ProjectModalProjectLinksForm;
