import React from 'react';
import { getSmileIcons } from '../../../../../../../helpers/utils/icons';
import SVG from 'react-inlinesvg';

type AssessmentFormChartYAxisTickProps = {
  x: number;
  y: number;
  ticks: number[];
  payload: AssessmentFormChartYAxisTickPayloadProps;
};

type AssessmentFormChartYAxisTickPayloadProps = {
  coordinate: number;
  offset: number;
  value: number;
};

const AssessmentFormChartYAxisTick = ({
  x,
  y,
  ticks,
  payload,
}: AssessmentFormChartYAxisTickProps) => {
  const icons = getSmileIcons();
  const index = ticks.findIndex((p) => p === payload?.value);

  if (index < 0 || index > 5)
    return <SVG x={x} y={y - 15} src={icons[2]} title="title" />;
  return <SVG x={x} y={y - 15} src={icons[index]} title="title" />;
};

export default AssessmentFormChartYAxisTick;
