import { differenceInSeconds } from 'date-fns';
import React, { PropsWithChildren } from 'react';
import { useSearchParams } from 'react-router-dom';
import useExerciseSessions from '../../../api/queries/exerciseSessions/useExerciseSessions';
import { useInitQuery } from '../../../hooks/queries/useInitQuery';
import FullScreenSpinner from '../../Spinners/FullScreenSpinner';
import NavigateToExerciseSession from './components/NavigateToExerciseSession';
import { getSessionToRestore } from './helpers/helpers';

type PendingExerciseSessionsProxyProps = {
  userId: string;
  caseId: string;
};

const PendingExerciseSessionsProxy = ({
  userId,
  caseId,
  children,
}: PropsWithChildren<PendingExerciseSessionsProxyProps>) => {
  const [searchParams] = useSearchParams();

  const exerciseSessions = useExerciseSessions({
    params: { userId },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(exerciseSessions);

  if (!exerciseSessions.data) return <FullScreenSpinner />;

  if (searchParams.get('sessionId')) {
    return <>{children}</>;
  }

  const sessionToRestore = getSessionToRestore(exerciseSessions.data, caseId);

  if (sessionToRestore) {
    const duration = Math.abs(
      differenceInSeconds(new Date(sessionToRestore.time_start), new Date())
    );

    return (
      <NavigateToExerciseSession
        userId={userId}
        caseId={caseId}
        id={sessionToRestore.id}
        duration={duration}
      />
    );
  }

  return <>{children}</>;
};

export default PendingExerciseSessionsProxy;
