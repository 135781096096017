import React from 'react';
import { useIntl } from 'react-intl';
import { TooltipProps } from 'recharts';

interface SqueezeQuantityChartTooltipProps
  extends TooltipProps<string, string> {
  translationId: string;
}

const SqueezeQuantityChartTooltip = ({
  active,
  payload,
  label,
  translationId,
}: SqueezeQuantityChartTooltipProps) => {
  const intl = useIntl();

  if (active && payload && payload.length) {
    return (
      <div className="SqueezeQuantityChartTooltip">
        <span className="SqueezeQuantityChartTooltip__date">{`Date: ${label}`}</span>
        <span className="SqueezeQuantityChartTooltip__value">{`Value: ${payload[0].value}`}</span>
        <span className="SqueezeQuantityChartTooltip__type">{`Quantity type: ${intl.formatMessage(
          {
            id: translationId,
          }
        )}`}</span>
      </div>
    );
  }

  return null;
};

export default SqueezeQuantityChartTooltip;
