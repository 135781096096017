import { useTranslations } from '../../../../../../../hooks/useTranslations';
import { useMemo } from 'react';

export const useHeaderItems = () => {
  const { attachPrefix } = useTranslations(
    'Education.AssetModal.AssetThumbnailUpload'
  );

  const headerItems = useMemo(
    () => [
      attachPrefix('preview'),
      attachPrefix('url'),
      attachPrefix('actions'),
    ],
    [attachPrefix]
  );

  return { headerItems };
};
