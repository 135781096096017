import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import useInfiniteUserInOrganizationsWithRoles from '../../../../api/queries/organizations/useInfiniteUserInOrganizationsWithRoles';
import { Button, Col, Row, Spinner } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPlus } from '@fortawesome/pro-light-svg-icons';
import ConfirmModal from '../../../../components/ConfirmModal';
import DebouncedInput from '../../../../components/DebouncedInput';
import patientsIcon from '../../../../assets/images/patients_icon.svg';
import UserInvitationModal from './modals/UserInvitationModal';
import CustomTable from '../../../../components/CustomTable';
import InfiniteScroll from 'react-infinite-scroller';
import useOpen from '../../../../hooks/useOpen';
import { useDeleteAllRolesConfirmation } from './hooks/useDeleteAllRolesConfirmation';
import { useDeleteRoleConfirmation } from './hooks/useDeleteRoleConfirmation';
import { useOrganizationPathParams } from 'hooks/paths/useOrganizationPathParams';
import OrganizationUsersItem from './components/OrganizationUsersItem';
import useOrganizationUserAccessProfiles from '../../../../api/queries/accessProfiles/useOrganizationUserAccessProfiles';
import { useInitQuery } from 'hooks/queries/useInitQuery';
import CreateOrganizationUserModal from './modals/CreateOrganizationUserModal';
import TabLayout, { TabLayoutType } from 'components/layouts/TabLayout';

const headerItems = [
  'UsersAndAccess.OrganizationUsersTab.email',
  'UsersAndAccess.OrganizationUsersTab.name',
  'UsersAndAccess.OrganizationUsersTab.roles',
  'UsersAndAccess.OrganizationUsersTab.actions',
];

const OrganizationUsersTab = () => {
  const { organizationId } = useOrganizationPathParams();

  const intl = useIntl();

  const invitationModal = useOpen();
  const createOrganizationUserModal = useOpen();

  const accessProfiles = useOrganizationUserAccessProfiles({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(accessProfiles);

  const [filterValue, setFilterValue] = useState('');

  const userInOrganizationsWithRoles = useInfiniteUserInOrganizationsWithRoles({
    params: { organizationId, searchValue: filterValue, pageSize: 100 },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(userInOrganizationsWithRoles);

  const deleteAllRoles = useDeleteAllRolesConfirmation(
    userInOrganizationsWithRoles.refetch
  );

  const deleteRole = useDeleteRoleConfirmation(
    userInOrganizationsWithRoles.refetch
  );

  const renderUsers = userInOrganizationsWithRoles.userRecord.map((record) => {
    return (
      <OrganizationUsersItem
        key={record.user.id}
        record={record}
        refetch={userInOrganizationsWithRoles.refetch}
        deleteRole={deleteRole}
        deleteAllRoles={deleteAllRoles}
      />
    );
  });

  const hasUsers = userInOrganizationsWithRoles.userRecord?.length > 0;

  const loadMore = () => userInOrganizationsWithRoles.fetchNextPage();

  const onDebounce = (value: string) => setFilterValue(value);

  return (
    <>
      <ConfirmModal
        isOpen={deleteAllRoles.isOpen}
        toggle={deleteAllRoles.close}
        message={intl.formatMessage({
          id: 'MyOrganizations.users.deleteAllRolesConfirmationMessage',
        })}
        confirm={deleteAllRoles.onConfirm}
      />
      <ConfirmModal
        isOpen={deleteRole.isOpen}
        toggle={deleteRole.close}
        message={intl.formatMessage({
          id: 'MyOrganizations.users.deleteRoleConfirmationMessage',
        })}
        confirm={deleteRole.onConfirm}
      />
      <TabLayout
        type={TabLayoutType.Generic}
        titlePrefix="Access.OrganizationsUsers"
        actions={
          <>
            <Button
              onClick={createOrganizationUserModal.toggle}
              color="primary"
              outline={true}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
            <Button
              onClick={invitationModal.toggle}
              color="primary"
              outline={true}
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </Button>
          </>
        }
      >
        <div>
          <Row className="mb-4">
            <Col md={6}>
              <DebouncedInput
                inputIconSrc={patientsIcon}
                labelTranslationId="MyOrganizations.users.search"
                name="search"
                onDebounce={onDebounce}
              />
            </Col>
            <Col md={6}>
              <div className="h-100 d-flex justify-content-end align-items-center">
                <UserInvitationModal
                  isOpen={invitationModal.isOpen}
                  toggle={invitationModal.toggle}
                  accessProfiles={accessProfiles.data ?? []}
                />
                <CreateOrganizationUserModal
                  isOpen={createOrganizationUserModal.isOpen}
                  toggle={createOrganizationUserModal.toggle}
                  refetch={userInOrganizationsWithRoles.refetch}
                />
              </div>
            </Col>
          </Row>
          <InfiniteScroll
            loadMore={loadMore}
            hasMore={userInOrganizationsWithRoles.hasMore}
            loader={<Spinner key="spinner" />}
            threshold={100}
            initialLoad={false}
          >
            {hasUsers && !userInOrganizationsWithRoles.isLoading ? (
              <CustomTable headerItems={headerItems} bodyRows={renderUsers} />
            ) : (
              <FormattedMessage id="General.nothingToDisplay" />
            )}
          </InfiniteScroll>
        </div>
      </TabLayout>
    </>
  );
};

export default OrganizationUsersTab;
