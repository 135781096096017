import { useMemo } from 'react';
import { useLocation } from 'react-router';

//USE ONLY INSIDE
function useUrlSearch() {
  const { search } = useLocation();
  return useMemo(() => {
    return new URLSearchParams(search);
  }, [search]);
}

export default useUrlSearch;
