import { useMemo } from 'react';
import { addDays, subDays } from 'date-fns';
import { cloneDeep } from 'lodash';
import { ChartsData, TrendsChartData } from 'components/TrendsChart/types';
import { IOrganizationObservationMeaning } from 'interfaces/observationMeanings';
import { ObservationSymptom } from 'interfaces/timeline';
import { TemplateElement, TemplateItemType } from 'interfaces/change';
import { ConversationQuestion } from 'interfaces/conversations';

const generateAllObservationsData = (
  min: number,
  max: number,
  meanings: IOrganizationObservationMeaning[]
): ChartsData<string | number>[] => {
  const nowDate = new Date();

  const startDate = subDays(nowDate, 30);
  const endDate = cloneDeep(nowDate);

  let tempDate = cloneDeep(startDate);

  let chartsData: ChartsData<string | number>[] = [];

  while (tempDate < endDate) {
    let item: Record<string, string | number> = {};
    item.name = tempDate.toISOString();

    meanings.forEach((meaning) => {
      item[meaning.id] = Math.random() * (max - min) + min;
    });

    item[ObservationSymptom.NotSpecified] = Math.random() * (max - min) + min;

    chartsData.push(item);
    tempDate = addDays(tempDate, 1);
  }

  return chartsData;
};

const generateExerciseObservationsData = (
  meanings: IOrganizationObservationMeaning[]
): ChartsData<string | number>[] => {
  return generateAllObservationsData(1, 15, meanings);
};

const generateNonExerciseObservationsData = (
  meanings: IOrganizationObservationMeaning[]
): ChartsData<string | number>[] => {
  return generateAllObservationsData(1, 30, meanings);
};

const generateQuestionsData = (
  elements: TemplateElement[],
  questions: ConversationQuestion[]
) => {
  const questionIds = elements.map((p) => p.question_id);
  const nonNullableQuestionIds = questionIds.filter(
    (p) => p !== null
  ) as string[];

  const filteredQuestions = nonNullableQuestionIds.map((p) =>
    questions.find((q) => q.resource_id === p)
  );

  const nonNullableFilteredQuestions = filteredQuestions.filter(
    (p) => !!p
  ) as ConversationQuestion[];

  const nowDate = new Date();

  const startDate = subDays(nowDate, 30);
  const endDate = cloneDeep(nowDate);

  let tempDate = cloneDeep(startDate);

  let chartsData: ChartsData<string | number>[] = [];

  while (tempDate < endDate) {
    let chartsDataItem: ChartsData<string | number> = {
      name: tempDate.toISOString(),
    };

    nonNullableFilteredQuestions.forEach((question) => {
      chartsDataItem[question.resource_id] = Math.random() * 100;
    });

    chartsData.push(chartsDataItem);
    tempDate = addDays(tempDate, 1);
  }

  return chartsData;
};

const generateExerciseTimeData = (): ChartsData<string | number>[] => {
  const nowDate = new Date();

  const startDate = subDays(nowDate, 30);
  const endDate = cloneDeep(nowDate);

  let tempDate = cloneDeep(startDate);

  let chartsData: ChartsData<string | number>[] = [];

  while (tempDate < endDate) {
    chartsData.push({
      name: tempDate.toISOString(),
      [TemplateItemType.ExerciseTime]: Math.random() * (1440 - 1) + 1,
    });

    tempDate = addDays(tempDate, 1);
  }

  return chartsData;
};

export const useGenerateRandomFormData = (
  elements: TemplateElement[],
  questions: ConversationQuestion[],
  meanings: IOrganizationObservationMeaning[]
) => {
  return useMemo((): TrendsChartData<string | number>[] => {
    return [
      {
        type: TemplateItemType.AllObservations,
        data: generateAllObservationsData(1, 33, meanings),
      },
      {
        type: TemplateItemType.ExerciseObservations,
        data: generateExerciseObservationsData(meanings),
      },
      {
        type: TemplateItemType.NonExerciseObservations,
        data: generateNonExerciseObservationsData(meanings),
      },
      {
        type: TemplateItemType.Questions,
        data: generateQuestionsData(elements, questions),
      },
      {
        type: TemplateItemType.ExerciseTime,
        data: generateExerciseTimeData(),
      },
    ];
  }, [elements, meanings, questions]);
};
