import { FormikProps } from 'formik';
import { AwareCaseDetailsFormValues } from '../../../../../forms/AwareCaseDetailsForm/types';
import { useState } from 'react';
import { useTimeValue } from './useTimeValue';

export const useTimeEnd = (
  procedureIndex: number,
  form: FormikProps<AwareCaseDetailsFormValues>,
  onChanged: () => void
) => {
  const getTimeValue = useTimeValue();

  const [timeEnd, setTimeEnd] = useState(
    () => getTimeValue(form.values.procedures.at(procedureIndex)?.timeEnd) ?? ''
  );

  const handleEndChange = (event: any) => {
    setTimeEnd(event.target.value);
    onChanged();
  };

  return { timeEnd, handleEndChange };
};
