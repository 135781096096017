import request from './utils/request';
import { IResponse } from '../interfaces/response';
import {
  IBaseEducationMaterial,
  IBaseEducationMaterialCategory,
  IEducationMaterial,
  IEducationMaterialCategoryTreeItem,
  IUpsertEducationMaterial,
  IUpsertEducationMaterialCategory,
} from '../interfaces/educationMaterials';

export const getEducationMaterials = (organizationId: string) =>
  request<void, IResponse<IEducationMaterial[]>>({
    url: `/organizations/${organizationId}/education_materials`,
    method: 'GET',
  });

export const getEducationMaterial = (
  organizationId: string,
  materialId: string
) =>
  request<void, IResponse<IEducationMaterial>>({
    url: `/organizations/${organizationId}/education_materials/${materialId}`,
    method: 'GET',
  });

export const getEducationMaterialCategories = (organizationId: string) =>
  request<void, IResponse<IBaseEducationMaterialCategory[]>>({
    url: `/organizations/${organizationId}/education_material_categories`,
    method: 'GET',
  });

export const getEducationMaterialCategoriesTree = (organizationId: string) =>
  request<void, IResponse<IEducationMaterialCategoryTreeItem[]>>({
    url: `/organizations/${organizationId}/education_material_categories/tree`,
    method: 'GET',
  });

export const upsertEducationMaterialCategory = (
  organizationId: string,
  body: IUpsertEducationMaterialCategory
) =>
  request<IUpsertEducationMaterialCategory, void>({
    url: `/organizations/${organizationId}/education_material_categories`,
    data: body,
    method: 'POST',
  });

export const linkEducationMaterialWithCategory = (
  organizationId: string,
  categoryId: string,
  materialId: string
) =>
  request({
    url: `/organizations/${organizationId}/education_material_categories/${categoryId}/education_materials/${materialId}`,
    method: 'PUT',
  });

export const unlinkEducationMaterialFromCategory = (
  organizationId: string,
  categoryId: string,
  materialId: string
) =>
  request({
    url: `/organizations/${organizationId}/education_material_categories/${categoryId}/education_materials/${materialId}`,
    method: 'DELETE',
  });

export const deleteEducationMaterialCategory = (
  organizationId: string,
  id: string
) =>
  request({
    url: `/organizations/${organizationId}/education_material_categories/${id}`,
    method: 'DELETE',
  });

export const addEducationMaterial = (
  organizationId: string,
  body: IUpsertEducationMaterial
) =>
  request<IUpsertEducationMaterial, IResponse<IBaseEducationMaterial>>({
    url: `/organizations/${organizationId}/education_materials`,
    data: body,
    method: 'POST',
  });

export const deleteEducationMaterial = (
  organizationId: string,
  materialId: string
) =>
  request({
    url: `/organizations/${organizationId}/education_materials/${materialId}`,
    method: 'DELETE',
  });

export const linkVideoAssetWithMaterial = (
  organizationId: string,
  assetId: string,
  materialId: string,
  culture: string
) =>
  request<void, void>({
    url: `/organizations/${organizationId}/education_materials/${materialId}/assets/${assetId}?culture=${culture}`,
    method: 'POST',
  });

export const unlinkVideoAssetFromMaterial = (
  organizationId: string,
  assetId: string,
  materialId: string,
  culture: string
) =>
  request<void, void>({
    url: `/organizations/${organizationId}/education_materials/${materialId}/assets/${assetId}?culture=${culture}`,
    method: 'DELETE',
  });
