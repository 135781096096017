import React from 'react';

//types
import { Size } from 'interfaces/ui';

//helpers
import { GraspColors, graspColorsValues } from 'helpers/colors';

//images
import GraspBlueBig from 'assets/images/colored_grasps/blue_grasp_big.png';
import GraspBlueMid from 'assets/images/colored_grasps/blue_grasp_mid.png';
import GraspBlueSmall from 'assets/images/colored_grasps/blue_grasp_small.png';

import GraspGreenBig from 'assets/images/colored_grasps/green_grasp_big.png';
import GraspGreenMid from 'assets/images/colored_grasps/green_grasp_mid.png';
import GraspGreenSmall from 'assets/images/colored_grasps/green_grasp_small.png';

import GraspOrangeBig from 'assets/images/colored_grasps/orange_grasp_big.png';
import GraspOrangeMid from 'assets/images/colored_grasps/orange_grasp_mid.png';
import GraspOrangeSmall from 'assets/images/colored_grasps/orange_grasp_small.png';

import GraspBlackBig from 'assets/images/colored_grasps/black_grasp_big.png';
import GraspBlackMid from 'assets/images/colored_grasps/black_grasp_mid.png';
import GraspBlackSmall from 'assets/images/colored_grasps/black_grasp_small.png';

import GraspWhiteBig from 'assets/images/colored_grasps/white_grasp_big.png';
import GraspWhiteMid from 'assets/images/colored_grasps/white_grasp_mid.png';
import GraspWhiteSmall from 'assets/images/colored_grasps/white_grasp_small.png';
import { TestIds } from 'test/testIds';

export type GraspLogoProps = {
  width?: number;
  height?: number;
  size: Size;
  color?: string;
  className?: string;
};

const GraspLogo = ({
  width,
  height,
  color = graspColorsValues.blue,
  className = '',
  size,
}: GraspLogoProps) => {
  const upperCaseColor = color?.toUpperCase();

  const returnUrlBaseOnSize = ([big, mid, small]: string[]) => {
    if (size === Size.MEDIUM) {
      return mid;
    } else if (size === Size.BIG) {
      return big;
    } else {
      return small;
    }
  };

  let Grasp;
  switch (upperCaseColor) {
    case graspColorsValues[GraspColors.GREEN]:
      Grasp = returnUrlBaseOnSize([
        GraspGreenBig,
        GraspGreenMid,
        GraspGreenSmall,
      ]);
      break;
    case graspColorsValues[GraspColors.ORANGE]:
      Grasp = returnUrlBaseOnSize([
        GraspOrangeBig,
        GraspOrangeMid,
        GraspOrangeSmall,
      ]);
      break;
    case graspColorsValues[GraspColors.BLACK]:
      Grasp = returnUrlBaseOnSize([
        GraspBlackBig,
        GraspBlackMid,
        GraspBlackSmall,
      ]);
      break;
    case graspColorsValues[GraspColors.WHITE]:
      Grasp = returnUrlBaseOnSize([
        GraspWhiteBig,
        GraspWhiteMid,
        GraspWhiteSmall,
      ]);
      break;
    case graspColorsValues[GraspColors.BLUE]:
    default:
      Grasp = returnUrlBaseOnSize([GraspBlueBig, GraspBlueMid, GraspBlueSmall]);
      break;
  }

  return (
    <img
      data-testid={TestIds.GraspLogo}
      className={className}
      src={Grasp}
      alt="Grasp"
      style={{
        width,
        height,
        objectFit: 'contain',
      }}
    />
  );
};

export default GraspLogo;
