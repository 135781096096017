import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { useTypedContext } from '../../../../../../../../../../../../../hooks/useTypedContext';
import {
  DashboardFilter,
  DashboardFilterType,
} from '../../../../../../../../../../../../../interfaces/dashboards';
import { DashboardSelectionHelpers } from '../../../../../../../helpers/selection';
import {
  DashboardDataReducerContext,
  DashboardSelectableOption,
} from '../../../../../../../types';
import { DashboardDataActions } from '../../../../../../DashboardDataContent/store/actions';

export const useHandleClearAll = (filter: DashboardFilter) => {
  const [dashboardData, dispatch] = useTypedContext(
    DashboardDataReducerContext
  );

  return useCallback(() => {
    let filtersCopy = cloneDeep(dashboardData.template.filters);
    let currentFilterIndex: number = -1;

    filtersCopy.forEach((p, idx) => {
      if (p.type === DashboardFilterType.Range) return;

      if (
        filter.type === DashboardFilterType.Member &&
        p.type === DashboardFilterType.Member
      )
        currentFilterIndex = idx;

      if (
        filter.type === DashboardFilterType.Grasp &&
        p.type === DashboardFilterType.Grasp
      )
        currentFilterIndex = idx;

      if (
        filter.type === DashboardFilterType.Organization &&
        p.type === DashboardFilterType.Organization
      )
        currentFilterIndex = idx;

      if (
        filter.type === DashboardFilterType.SourcePeriod &&
        p.type === DashboardFilterType.SourcePeriod
      )
        currentFilterIndex = idx;

      if (
        filter.type === DashboardFilterType.ClosedList &&
        p.type === DashboardFilterType.ClosedList &&
        p.id === filter.id
      )
        currentFilterIndex = idx;
    });

    if (currentFilterIndex === -1) return;

    filtersCopy[currentFilterIndex].options = (
      filtersCopy[currentFilterIndex].options as DashboardSelectableOption[]
    ).map((option) => ({
      ...option,
      selected: false,
    }));

    if (DashboardSelectionHelpers.SelectionCount(filtersCopy) === 0) {
      dispatch({
        type: DashboardDataActions.DashboardDataActionType.EditTemplate,
        payload: {
          ...dashboardData.template,
          filters: DashboardSelectionHelpers.ToggleAll(filtersCopy, 'select'),
        },
      });
      return;
    }

    dispatch({
      type: DashboardDataActions.DashboardDataActionType.EditTemplate,
      payload: {
        ...dashboardData.template,
        filters: filtersCopy,
      },
    });
  }, [dashboardData.template, dispatch, filter]);
};
