import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';

export type DecisionDefinition = {
  text: string;
  outline: boolean;
  handle: () => void;
};

type DecisionModalProps = {
  isOpen: boolean;
  message: string | JSX.Element;
  decisions: DecisionDefinition[];
  toggle: () => void;
};

const DecisionModal = ({
  isOpen,
  decisions,
  toggle,
  message,
}: DecisionModalProps) => {
  return (
    <Modal
      centered={true}
      isOpen={isOpen}
      toggle={toggle}
      className="ConfirmModal"
      size="lg"
    >
      <ModalBody>{message}</ModalBody>
      <ModalFooter>
        {decisions.map((decision) => (
          <Button
            key={decision.text}
            className="ConfirmModal__confirm"
            outline={decision.outline}
            color="primary"
            onClick={decision.handle}
          >
            {decision.text}
          </Button>
        ))}
      </ModalFooter>
    </Modal>
  );
};

export default DecisionModal;
