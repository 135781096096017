import React from 'react';
import { Field } from 'formik';
import FieldInput from '../../../../../../../../../../../../components/FieldInput';
import { useTranslations } from '../../../../hooks/useTranslations';
import {
  QuestionValidConversationTemplateItem,
  RangeValidConversationQuestion,
  ValidConversationTemplate,
} from '../../../../../../../../../../../../interfaces/conversations';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import { useTypedContext } from '../../../../../../../../../../../../hooks/useTypedContext';
import { LanguageContext } from '../../../../types';
import { AVAILABLE_CULTURES } from '../../../../../../../../../../../../components/CultureSelector/CultureSelector';
import { cloneDeep } from 'lodash';
import { getEmptyLegendDescription } from './helpers';
import { FormikUtilsContext } from '../../../../../../types';
import { useGeneratePlaceholder } from '../../../../hooks/useGeneratePlaceholder';
import { wrapWithSequenceIdentified } from '../../../../../../../../../../../../helpers/utils/data';

type RangeProps = {
  index: number;
  values: ValidConversationTemplate;
};

const Range = ({ index, values }: RangeProps) => {
  const selectedItem = values.content[index];

  const selectedQuestion =
    selectedItem as QuestionValidConversationTemplateItem;

  const selectedRangeQuestion =
    selectedQuestion.value as RangeValidConversationQuestion;

  const { providerInvariant } = useTranslations();

  const language = useTypedContext(LanguageContext);
  const isDefaultCulture = language.culture === AVAILABLE_CULTURES.EN;

  const formikUtils = useTypedContext(FormikUtilsContext);

  const getPlaceholder = useGeneratePlaceholder(isDefaultCulture);

  const handleAddLegend = () => {
    let valuesCopy = cloneDeep(values);

    (
      (valuesCopy.content[index] as QuestionValidConversationTemplateItem)
        .value as RangeValidConversationQuestion
    ).legend_descriptions = [
      ...(
        (valuesCopy.content[index] as QuestionValidConversationTemplateItem)
          .value as RangeValidConversationQuestion
      ).legend_descriptions,
      getEmptyLegendDescription(),
    ];

    formikUtils.setValues(valuesCopy);
  };

  return (
    <>
      <Field
        required={false}
        placeholder={getPlaceholder(
          'minNumberValue',
          selectedRangeQuestion.min_number_value
        )}
        label={providerInvariant.wrapWithIntl(
          providerInvariant.attachPrefix('minNumberValue')
        )}
        id={`content[${index}].value.min_number_value`}
        name={`content[${index}].value.min_number_value`}
        type="number"
        component={FieldInput}
      />
      <Field
        required={false}
        placeholder={getPlaceholder(
          'maxNumberValue',
          selectedRangeQuestion.max_number_value
        )}
        label={providerInvariant.wrapWithIntl(
          providerInvariant.attachPrefix('maxNumberValue')
        )}
        id={`content[${index}].value.max_number_value`}
        name={`content[${index}].value.max_number_value`}
        type="number"
        component={FieldInput}
      />
      <div className="d-flex flex-column gap-1">
        {wrapWithSequenceIdentified(
          selectedRangeQuestion.legend_descriptions
        ).map((legend, idx) => {
          const handleRemove = () => {
            let valuesCopy = cloneDeep(values);

            (
              (
                valuesCopy.content[
                  index
                ] as QuestionValidConversationTemplateItem
              ).value as RangeValidConversationQuestion
            ).legend_descriptions = (
              (
                valuesCopy.content[
                  index
                ] as QuestionValidConversationTemplateItem
              ).value as RangeValidConversationQuestion
            ).legend_descriptions.filter((_, i) => i !== idx);

            formikUtils.setValues(valuesCopy);
          };

          return (
            <div
              key={legend.sequence}
              className="d-flex flex-nowrap align-items-center gap-2"
            >
              <div className="w-50">
                <Field
                  block
                  required={true}
                  placeholder={getPlaceholder('label', legend.label)}
                  label={providerInvariant.wrapWithIntl(
                    providerInvariant.attachPrefix('label')
                  )}
                  id={
                    isDefaultCulture
                      ? `content[${index}].value.legend_descriptions[${idx}].label`
                      : `content[${index}].value.legend_descriptions[${idx}].label_nb_no`
                  }
                  name={
                    isDefaultCulture
                      ? `content[${index}].value.legend_descriptions[${idx}].label`
                      : `content[${index}].value.legend_descriptions[${idx}].label_nb_no`
                  }
                  type="text"
                  component={FieldInput}
                />
              </div>
              <div className="w-50">
                <Field
                  block
                  required={true}
                  placeholder={getPlaceholder('value', legend.value)}
                  label={providerInvariant.wrapWithIntl(
                    providerInvariant.attachPrefix('value')
                  )}
                  id={`content[${index}].value.legend_descriptions[${idx}].value`}
                  name={`content[${index}].value.legend_descriptions[${idx}].value`}
                  type="number"
                  min={selectedRangeQuestion.min_number_value ?? 0}
                  max={selectedRangeQuestion.max_number_value ?? 100}
                  component={FieldInput}
                />
              </div>
              <div className="d-flex align-self-end">
                <Button color="danger" outline={true} onClick={handleRemove}>
                  <FontAwesomeIcon icon={faTrash} />
                </Button>
              </div>
            </div>
          );
        })}
      </div>
      <Button
        color="primary"
        outline={true}
        block={true}
        onClick={handleAddLegend}
      >
        {providerInvariant.wrapWithIntl(
          providerInvariant.attachPrefix('addLegend')
        )}
      </Button>
    </>
  );
};

export default Range;
