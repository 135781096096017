import React, { useCallback, useMemo, useState } from 'react';
import { Alert, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import userAccountDeletionIcon from '../../../../assets/images/user_account_deletion_icon.svg';
import AccountDeletionForm, {
  AccountDeletionReason,
} from './AccountDeletionForm';
import AccountDeletionPasswordVerification from './AccountDeletionPasswordVerification';
import AccountDeletionConfirmation from './AccountDeletionConfirmation';
import SVG from 'react-inlinesvg';
import { MyProfileTabs } from '../index';

export enum AccountDeletionStep {
  DeletionForm = 'DeletionForm',
  VerifyPassword = 'VerifyPassword',
  Confirmation = 'Confirmation',
}

type AccountDeletionProps = {
  isOpen: boolean;
  toggle: () => void;
  setSelectedTab: (tab: MyProfileTabs) => void;
};

export type AccountDeletionData = {
  deletion_reasons: AccountDeletionReason[];
  newsletter_sign_out: boolean;
  feedback: string | null;
};

const accountDeletionInitialData: AccountDeletionData = {
  feedback: null,
  deletion_reasons: [],
  newsletter_sign_out: false,
};

const AccountDeletionModal = ({
  isOpen,
  toggle,
  setSelectedTab,
}: AccountDeletionProps) => {
  const intl = useIntl();

  const [accountDeletionData, setAccountDeletionData] =
    useState<AccountDeletionData>(accountDeletionInitialData);

  const [accountDeletionStep, setAccountDeletionStep] =
    useState<AccountDeletionStep>(AccountDeletionStep.DeletionForm);

  const modalToggle = useCallback(() => {
    setAccountDeletionData(accountDeletionInitialData);
    setAccountDeletionStep(AccountDeletionStep.DeletionForm);
    toggle();
  }, [toggle]);

  const renderAccountDeletionStep = useMemo(() => {
    if (accountDeletionStep === AccountDeletionStep.DeletionForm)
      return (
        <AccountDeletionForm
          setSelectedTab={setSelectedTab}
          accountDeletionData={accountDeletionData}
          setAccountDeletionData={setAccountDeletionData}
          setAccountDeletionStep={setAccountDeletionStep}
          toggle={modalToggle}
        />
      );

    if (accountDeletionStep === AccountDeletionStep.VerifyPassword)
      return (
        <AccountDeletionPasswordVerification
          toggle={modalToggle}
          accountDeletionData={accountDeletionData}
          setAccountDeletionData={setAccountDeletionData}
          setAccountDeletionStep={setAccountDeletionStep}
        />
      );

    if (accountDeletionStep === AccountDeletionStep.Confirmation)
      return <AccountDeletionConfirmation />;
  }, [accountDeletionData, accountDeletionStep, modalToggle, setSelectedTab]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={modalToggle}
      className="AccountDeletionModal"
      size="lg"
    >
      <ModalHeader
        className="AccountDeletionModal__header"
        toggle={modalToggle}
        close={<></>}
      >
        <FormattedMessage id="MyProfile.AccountDeletion.AccountDeletionModal.deleteImmediately" />
      </ModalHeader>
      <ModalBody className="AccountDeletionModal__body ps-4 pe-4">
        {accountDeletionStep !== AccountDeletionStep.Confirmation && (
          <div className="AccountDeletionModal__header-alert">
            <Alert className="AccountDeletionModal__alert" color="danger">
              <div className={`AccountDeletionModal__alert__icon-container`}>
                <SVG
                  className="AccountDeletionModal__alert__icon AccountDeletionModal__alert__icon--red"
                  src={userAccountDeletionIcon}
                  title={intl.formatMessage({
                    id: 'MyProfile.AccountDeletion.accountDeletion',
                  })}
                />
              </div>
              <div className="AccountDeletionModal__alert__text">
                <FormattedMessage id="MyProfile.AccountDeletion.AccountDeletionModal.caution" />
              </div>
            </Alert>
          </div>
        )}
        {renderAccountDeletionStep}
      </ModalBody>
    </Modal>
  );
};

export default AccountDeletionModal;
