import { FieldProps } from 'formik';
import React from 'react';
import Select from 'react-select';
import { OptionsType, ValueType } from 'react-select/lib/types';

interface Option {
  label: string;
  value: string;
}

interface FieldMultiSelectProps extends FieldProps {
  options: OptionsType<Option>;
  disabled?: boolean;
  isMulti?: boolean;
  isLoading?: boolean;
  className?: string;
  placeholder?: string;
}

const customStyles = {
  control: (base: any) => ({
    ...base,
    height: 35,
    minHeight: 35,
  }),
};

export const FieldMultiSelect = ({
  placeholder,
  field,
  form,
  options,
  disabled = false,
  isMulti = false,
  isLoading = false,
}: FieldMultiSelectProps) => {
  const onChange = (option: ValueType<Option | Option[]>) => {
    form.setFieldValue(
      field.name,
      isMulti
        ? (option as Option[]).map((item: Option) => item.value)
        : (option as Option).value
    );
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter(
            (option) => field.value && field.value.indexOf(option.value) >= 0
          )
        : options.find((option) => option.value === field.value);
    } else {
      return isMulti ? [] : ('' as any);
    }
  };

  return (
    <Select
      classNamePrefix="FieldMultiSelect"
      isDisabled={disabled}
      name={field.name}
      value={getValue()}
      onChange={onChange}
      placeholder={placeholder}
      isSearchable={true}
      options={options}
      isMulti={isMulti}
      isLoading={isLoading}
      styles={customStyles}
    />
  );
};

export default FieldMultiSelect;
