import useCreateCaseConversationSchedule from 'api/mutations/conversationSchedules/useCreateCaseConversationSchedule';
import useCreateMultipleCaseConversationSchedule from 'api/mutations/conversationSchedules/useCreateMultipleCaseConversationSchedule';
import { FormikErrors } from 'formik';
import { useOptions } from 'hooks/useOptions';
import { IConversationTreatmentPhaseContentItem } from 'interfaces/conversations';
import {
  ConversationScheduleType,
  ICreateCaseConversationSchedule,
} from 'interfaces/conversationSchedules';
import { useCallback, useMemo } from 'react';
import { AddScheduleTemplateFormHandler } from '../../../../../../../../../../../../../components/AddScheduleTemplateModal';
import { useOrganizationCasePathParams } from '../../../../../../../../../../../../../hooks/paths/useOrganizationCasePathParams';
import { useFormValidation } from '../../../../../../../../../../../../TreatmentPlans/components/TreatmentPlan/components/TreatmentPhases/components/TreatmentPhase/modals/AddTemplateModal/hooks/useFormValidation';
import { useCommonData } from '../../../hooks/useCommonData';
import { useConversationTemplateCharacteristics } from '../../../hooks/useConversationTemplateCharacteristics';
import { useAddTemplateModalPayload } from './useAddTemplateModalPayload';

export const useAddTemplateModalFormHandler = (toggle: () => void) => {
  const {
    prepareInitialValues,
    prepareSimplePayload,
    prepareSingleInstancePayload,
    prepareRecurringPayload,
  } = useAddTemplateModalPayload();

  const {
    isTemplateDefault,
    conversationTemplatesOptions,
    isTemplateTimeBased,
  } = useConversationTemplateCharacteristics();

  const validation = useFormValidation();

  const { scheduleTypes } = useOptions();

  const { organizationId, caseId } = useOrganizationCasePathParams();

  const { caseConversationSchedules } = useCommonData();

  const handleSuccess = async () => {
    await caseConversationSchedules.refetch();
    toggle();
  };

  const { mutateAsync: createAsync } = useCreateCaseConversationSchedule(
    {
      organizationId,
      caseId,
    },
    {
      successFb: handleSuccess,
    }
  );

  const { mutateAsync: createMultipleAsync } =
    useCreateMultipleCaseConversationSchedule(
      {
        organizationId,
        caseId,
      },
      {
        successFb: handleSuccess,
      }
    );

  const initialValues = useMemo((): IConversationTreatmentPhaseContentItem => {
    return prepareInitialValues();
  }, [prepareInitialValues]);

  const handleSubmit = useCallback(
    async (values: IConversationTreatmentPhaseContentItem) => {
      let payload: ICreateCaseConversationSchedule[] = [];

      switch (values.schedule_templates.at(0)?.schedule_type) {
        case ConversationScheduleType.Once:
          payload = [
            prepareSimplePayload(
              values,
              isTemplateTimeBased(values.template_id)
                ? ConversationScheduleType.Once
                : ConversationScheduleType.NonTimeBased
            ),
          ];
          break;
        case ConversationScheduleType.NonTimeBased:
          payload = [
            prepareSimplePayload(values, ConversationScheduleType.NonTimeBased),
          ];
          break;
        case ConversationScheduleType.SingleInstance:
          payload = [prepareSingleInstancePayload(values)];
          break;
        case ConversationScheduleType.Recurring:
          payload = prepareRecurringPayload(values);
      }

      if (!payload.length) return;

      if (payload.length === 1) {
        await createAsync(payload[0]);
        return;
      }

      await createMultipleAsync(payload);
    },
    [
      createAsync,
      createMultipleAsync,
      isTemplateTimeBased,
      prepareRecurringPayload,
      prepareSimplePayload,
      prepareSingleInstancePayload,
    ]
  );

  const validate = useCallback(
    (
      values: IConversationTreatmentPhaseContentItem
    ): FormikErrors<IConversationTreatmentPhaseContentItem> => {
      let errors: FormikErrors<typeof initialValues> = {};

      errors = validation.genericValidation(errors, false, values);

      if (!isTemplateTimeBased(values.template_id)) return errors;

      if (values.schedule_templates.length === 0)
        errors.schedule_templates = [];

      const firstScheduleTemplate = values.schedule_templates.at(0);

      if (!firstScheduleTemplate?.schedule_type) {
        errors.schedule_templates = [];
        return errors;
      }

      if (firstScheduleTemplate.schedule_type === ConversationScheduleType.Once)
        return errors;

      if (
        firstScheduleTemplate.schedule_type ===
        ConversationScheduleType.SingleInstance
      ) {
        errors = validation.singleInstanceValidation(
          errors,
          firstScheduleTemplate
        );
        return errors;
      }

      errors = validation.recurringValidation(errors, firstScheduleTemplate);
      return errors;
    },
    [isTemplateTimeBased, validation]
  );

  return useMemo((): AddScheduleTemplateFormHandler => {
    return {
      initialValues: initialValues,
      handleSubmit: handleSubmit,
      validate: validate,
      options: {
        conversationTemplates: conversationTemplatesOptions(false, true),
        scheduleTypes: scheduleTypes(true),
      },
      helpers: {
        isTemplateTimeBased: isTemplateTimeBased,
        isTemplateDefault: isTemplateDefault,
      },
    };
  }, [
    conversationTemplatesOptions,
    handleSubmit,
    initialValues,
    isTemplateDefault,
    isTemplateTimeBased,
    scheduleTypes,
    validate,
  ]);
};
