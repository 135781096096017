import { confirmEmail } from 'api/membership';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useNavigate } from 'react-router-dom';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

type ConfirmMailMutationParams = {
  id: string;
  email_confirmation_code: string;
};

function useConfirmMail() {
  const navigate = useNavigate();

  return useMutationWithResultNotification({
    asyncApiFunction: async ({
      id,
      email_confirmation_code,
    }: ConfirmMailMutationParams) => {
      await confirmEmail({ id, email_confirmation_code });
    },
    resetOnResult: true,
    onSuccess: () => {
      displaySuccessNotification('ConfirmEmail.notifications.success');
      navigate('/login');
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'ConfirmEmail.notifications.errors'
      );
    },
  });
}

export default useConfirmMail;
