import { Field } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { Col, Row } from 'reactstrap';
import FieldInput from '../../../../../../components/FieldInput';
import FieldHtmlEditor from '../../../../../../components/Fields/Formik/FieldHtmlEditor';
import InputFieldWithoutImage from '../../../../../../components/InputFieldWithoutImage';
import { AvailableCulture } from '../../../../../../hooks/cultures/useCultures';

type ProjectModalOverviewFormProps = {
  selectedCulture: AvailableCulture;
};

const ProjectModalOverviewForm = ({
  selectedCulture,
}: ProjectModalOverviewFormProps) => {
  const intl = useIntl();

  return (
    <div className="d-flex flex-column gap-4">
      <Row>
        <Col xs={6}>
          <InputFieldWithoutImage
            gray={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.projectCode"
            inputId="code"
          >
            <Field
              id="code"
              name="code"
              placeholder={intl.formatMessage({
                id: 'Projects.ProjectModal.ProjectModalOverviewForm.projectCodePlaceholder',
              })}
              required={true}
              condensed={false}
              component={FieldInput}
            />
          </InputFieldWithoutImage>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <InputFieldWithoutImage
            unstyled={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.title"
            inputId={`title.${selectedCulture.cultureCode}`}
          >
            <Field
              id={`title.${selectedCulture.cultureCode}`}
              name={`title.${selectedCulture.cultureCode}`}
              required={true}
              placeholder={intl.formatMessage({
                id: 'Projects.ProjectModal.ProjectModalOverviewForm.titlePlaceholder',
              })}
              editorClassNames="w-100 h-100"
              condensed={false}
              component={FieldHtmlEditor}
            />
          </InputFieldWithoutImage>
        </Col>
        <Col xs={6}>
          <InputFieldWithoutImage
            unstyled={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.mobileHeader"
            inputId={`mobile_header.${selectedCulture.cultureCode}`}
          >
            <Field
              id={`mobile_header.${selectedCulture.cultureCode}`}
              name={`mobile_header.${selectedCulture.cultureCode}`}
              placeholder={intl.formatMessage({
                id: 'Projects.ProjectModal.ProjectModalOverviewForm.mobileHeaderPlaceholder',
              })}
              editorClassNames="w-100 h-100"
              condensed={false}
              component={FieldHtmlEditor}
            />
          </InputFieldWithoutImage>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <InputFieldWithoutImage
            unstyled={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.description"
            inputId={`description.${selectedCulture.cultureCode}`}
          >
            <Field
              id={`description.${selectedCulture.cultureCode}`}
              name={`description.${selectedCulture.cultureCode}`}
              required={true}
              placeholder={intl.formatMessage({
                id: 'Projects.ProjectModal.ProjectModalOverviewForm.descriptionPlaceholder',
              })}
              editorClassNames="w-100 h-100"
              condensed={false}
              component={FieldHtmlEditor}
            />
          </InputFieldWithoutImage>
        </Col>
        <Col xs={6}>
          <InputFieldWithoutImage
            unstyled={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.shortDescription"
            inputId={`short_description.${selectedCulture.cultureCode}`}
          >
            <Field
              id={`short_description.${selectedCulture.cultureCode}`}
              name={`short_description.${selectedCulture.cultureCode}`}
              placeholder={intl.formatMessage({
                id: 'Projects.ProjectModal.ProjectModalOverviewForm.shortDescriptionPlaceholder',
              })}
              editorClassNames="w-100 h-100"
              condensed={false}
              component={FieldHtmlEditor}
            />
          </InputFieldWithoutImage>
        </Col>
      </Row>
    </div>
  );
};

export default ProjectModalOverviewForm;
