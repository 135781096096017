import useCaseCategories from 'api/queries/cases/useCaseCategories';
import FieldInput from 'components/FieldInput';
import FieldSelect from 'components/FieldSelect';
import FormGroupDescription from 'components/FormGroupDescription';
import FormikErrorMessage from 'components/FormikErrorMessage';
import { Field, Form } from 'formik';
import { CaseFormType, DescriptionFormKeys, ShareMode } from 'interfaces/cases';
import React, { useLayoutEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Container, FormGroup, Label } from 'reactstrap';
import SubCategorySelect from './SubCategoryModalSelect';

type CaseDescriptionFormProps = {
  organizationId: string;
  setValues: (data: CaseFormType) => void;
  values: CaseFormType;
};

const CaseDescriptionForm = ({
  organizationId,
  setValues,
  values,
}: CaseDescriptionFormProps) => {
  const intl = useIntl();

  const { data } = useCaseCategories({ params: { organizationId } });

  const categoryOptions =
    data?.map(({ id, name }) => {
      return {
        value: id,
        text: name,
      };
    }) ?? [];

  const onCaseChange = (event) => {
    const category = data?.find(
      (singleData) => singleData.id === event.target.value
    );

    if (category) {
      let isOnlyOneSubCategory = category.sub_categories?.length === 1;

      setValues({
        ...values,
        caseCategory: event.target.value,
        caseSubCategory: isOnlyOneSubCategory
          ? category.sub_categories[0].id
          : '',
      });
    }
  };

  const firstFieldRef = useRef<HTMLInputElement | null>(null);
  useLayoutEffect(() => {
    if (firstFieldRef.current) {
      firstFieldRef.current.focus();
    }
  }, []);

  return (
    <Container className="mb-4">
      <Form>
        <FormGroupDescription
          header={intl.formatMessage({
            id: 'CaseCard.create.case.caseDefinition',
          })}
          description={intl.formatMessage({
            id: 'CaseCard.create.case.caseDescriptionMessage',
          })}
        >
          <FormGroup>
            <Label htmlFor={DescriptionFormKeys.TITLE}>
              <FormattedMessage id="CaseCard.create.case.title" />
            </Label>
            <Field
              innerRef={firstFieldRef}
              required
              id={DescriptionFormKeys.TITLE}
              name={DescriptionFormKeys.TITLE}
              placeholder={intl.formatMessage({
                id: 'CaseCard.create.case.elaborateOnCase',
              })}
              component={FieldInput}
            />
            <FormikErrorMessage name={DescriptionFormKeys.TITLE} />
          </FormGroup>
          <FormGroup>
            <Label htmlFor={DescriptionFormKeys.DESCRIPTION}>
              <FormattedMessage id="CaseCard.create.case.description" />
            </Label>
            <Field
              required
              id={DescriptionFormKeys.DESCRIPTION}
              name={DescriptionFormKeys.DESCRIPTION}
              type="textarea"
              rows={5}
              placeholder={intl.formatMessage({
                id: 'CaseCard.create.case.elaborateOnCase',
              })}
              component={FieldInput}
            />
            <FormikErrorMessage name={DescriptionFormKeys.DESCRIPTION} />
          </FormGroup>
          <FormGroup>
            <Label htmlFor={DescriptionFormKeys.CATEGORY}>
              <FormattedMessage id="CaseCard.category" />
            </Label>
            <Field
              required
              id={DescriptionFormKeys.CATEGORY}
              name={DescriptionFormKeys.CATEGORY}
              options={categoryOptions}
              placeholder={
                <FormattedMessage id="CaseCard.create.selectCategory" />
              }
              component={FieldSelect}
              onChange={onCaseChange}
            />
            <FormikErrorMessage name={DescriptionFormKeys.CATEGORY} />
          </FormGroup>
          <FormGroup>
            <Label htmlFor={DescriptionFormKeys.SUB_CATEGORY}>
              <FormattedMessage id="CaseCard.subCategory" />
            </Label>
            <Field
              component={SubCategorySelect}
              organizationId={organizationId}
              name={DescriptionFormKeys.SUB_CATEGORY}
            />
            <FormikErrorMessage name={DescriptionFormKeys.SUB_CATEGORY} />
          </FormGroup>
          <div role="group">
            <FormGroup check>
              <Label check>
                <Field
                  checked={values.shareMode === ShareMode.SEND_REQUEST}
                  type="radio"
                  name={DescriptionFormKeys.SHARE_MODE}
                  value={ShareMode.SEND_REQUEST}
                  component={FieldInput}
                />
                <FormattedMessage id="CaseCard.create.case.askPatient" />
              </Label>
            </FormGroup>
            <FormGroup check>
              <Label check>
                <Field
                  checked={values.shareMode === ShareMode.SHARE_DATA}
                  type="radio"
                  name={DescriptionFormKeys.SHARE_MODE}
                  value={ShareMode.SHARE_DATA}
                  component={FieldInput}
                />
                <FormattedMessage id="CaseCard.create.case.patientConsent" />
              </Label>
            </FormGroup>
          </div>
        </FormGroupDescription>
      </Form>
    </Container>
  );
};

export default CaseDescriptionForm;
