import { organizationContextDeleteObservationsGroup } from 'api/observations';
import { IDeleteObservationsGroup } from 'interfaces/timeline';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

type useUpdateObservationsGroupProps = {
  organizationId: string;
  caseId: string;
  timelineId: string;
  successFb?: () => void;
};

function useOrganizationContextDeleteObservationsGroup({
  organizationId,
  caseId,
  timelineId,
  successFb,
}: useUpdateObservationsGroupProps) {
  return useMutationWithResultNotification({
    asyncApiFunction: async (data: IDeleteObservationsGroup) => {
      await organizationContextDeleteObservationsGroup(
        organizationId,
        caseId,
        timelineId,
        data
      );
    },
    resetOnResult: true,
    onSuccess: () => {
      displaySuccessNotification(
        'TimelineDetails.ManageObservationGroupModal.success'
      );
      successFb && successFb();
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'TimelineDetails.ManageObservationGroupModal.error'
      );
    },
  });
}

export default useOrganizationContextDeleteObservationsGroup;
