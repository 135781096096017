import { days } from 'helpers/utils/translationObject';
import React from 'react';

type ActivitiesDateProps = {
  date: string;
  children: JSX.Element[];
};

const ActivitiesPerDate = ({ children, date }: ActivitiesDateProps) => {
  const dateArray = date.split('.');
  const dayInWeek = new Date(
    `${dateArray[2]}-${dateArray[1]}-${dateArray[0]}`
  ).getDay();

  return (
    <>
      <div className="PatientActivity__list__date">
        {days[dayInWeek]}, {date}
      </div>
      <div>{children}</div>
    </>
  );
};

export default ActivitiesPerDate;
