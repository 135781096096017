import { useMemo } from 'react';
import { differenceInMilliseconds } from 'date-fns';
import { SqueezeTimelineData } from '../types';
import { IObservation } from '../../../../../../../../../interfaces/timeline';
import { useTypedContext } from '../../../../../../../../../hooks/useTypedContext';
import { OperationalDataContext } from '../../../providers/OperationalDataProvider';

export const useFormattedData = (observations: IObservation[]) => {
  const { caseDetails, awareSessionDetails } = useTypedContext(
    OperationalDataContext
  );

  const dateFrom = useMemo(() => {
    const undefinedStartDate = caseDetails?.start_date ?? undefined;
    const startDate = awareSessionDetails?.start_date ?? undefinedStartDate;

    return startDate ? new Date(startDate) : undefined;
  }, [awareSessionDetails?.start_date, caseDetails?.start_date]);

  return useMemo((): SqueezeTimelineData[] => {
    if (!dateFrom) return [];

    return observations.map((observation) => {
      return {
        name: differenceInMilliseconds(
          new Date(observation.timestamp),
          dateFrom
        ),
        value: observation.amplitudes[0],
        duration: observation.duration_in_seconds,
      };
    });
  }, [dateFrom, observations]);
};
