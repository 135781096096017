import React, { useEffect, useMemo, useState } from 'react';
import { IEducationMaterial } from '../../../../../../../../interfaces/educationMaterials';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExcel,
  faImage,
  faVideo,
} from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage } from 'react-intl';
import UploadAssetsStepItem, {
  UploadAssetsStepItemMode,
} from './components/UploadAssetsStepItem';
import { useHandleAddAsset } from './hooks/useHandleAddAsset';
import { useTranslations } from '../../../../../../../../hooks/useTranslations';
import { cloneDeep } from 'lodash';

type UploadAssetsStepProps = {
  material: IEducationMaterial;
};

const UploadAssetsStep = ({ material }: UploadAssetsStepProps) => {
  const [modifiedMaterial, setModifiedMaterial] =
    useState<IEducationMaterial>(material);

  const materialAssets = useMemo(() => {
    let assets = cloneDeep(
      modifiedMaterial.assets_with_cultures.flatMap((p) => p.value)
    );

    const map = new Map(assets.map((p) => [p.id, p]));
    return [...map.values()];
  }, [modifiedMaterial.assets_with_cultures]);

  const { attachPrefix } = useTranslations(
    'Education.MaterialModal.MaterialModalBody.UploadAssetsStep'
  );

  useEffect(() => {
    setModifiedMaterial(material);
  }, [material]);

  const {
    handleAddAttachmentAsset,
    handleAddImageAsset,
    handleAddVideoAsset,
    assetToAdd,
    handleRemoveAsset,
  } = useHandleAddAsset();

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column gap-3">
        {materialAssets.map((asset) => (
          <UploadAssetsStepItem
            key={asset.id}
            asset={asset}
            material={modifiedMaterial}
            setMaterial={setModifiedMaterial}
            mode={UploadAssetsStepItemMode.ReadOnly}
          />
        ))}
        {assetToAdd && (
          <UploadAssetsStepItem
            material={modifiedMaterial}
            setMaterial={setModifiedMaterial}
            asset={assetToAdd}
            mode={UploadAssetsStepItemMode.EditMode}
            disable={handleRemoveAsset}
          />
        )}
      </div>
      <div
        className={classNames(
          'd-flex align-items-center justify-content-center gap-3',
          {
            'mt-5': materialAssets.length > 0,
          }
        )}
      >
        <div
          style={{
            minWidth: 150,
          }}
          className="d-flex flex-column gap-1 p-2 text-primary align-items-center border shadow rounded-3"
          onClick={handleAddVideoAsset}
        >
          <FontAwesomeIcon icon={faVideo} size="4x" />
          <FormattedMessage id={attachPrefix('addVideo')} />
        </div>
        <div
          style={{
            minWidth: 150,
          }}
          className="d-flex flex-column gap-1 p-2 text-primary align-items-center border shadow rounded-3"
          onClick={handleAddImageAsset}
        >
          <FontAwesomeIcon icon={faImage} size="4x" />
          <FormattedMessage id={attachPrefix('addImage')} />
        </div>
        <div
          style={{
            minWidth: 150,
          }}
          className="d-flex flex-column gap-1 p-2 text-primary align-items-center border shadow rounded-3"
          onClick={handleAddAttachmentAsset}
        >
          <FontAwesomeIcon icon={faFileExcel} size="4x" />
          <FormattedMessage id={attachPrefix('addAttachment')} />
        </div>
      </div>
    </div>
  );
};

export default UploadAssetsStep;
