import { GridCellParams, MuiEvent } from '@mui/x-data-grid';

export namespace CustomMaterialTableHelpers {
  export const GetRowHeight = (): 'auto' => 'auto';

  export const DefaultMuiPreventedEvent = (
    _: GridCellParams,
    event: MuiEvent<any>
  ) => {
    event.defaultMuiPrevented = true;
  };

  export const DisableRowEvents = () => {
    return {
      onCellDoubleClick: DefaultMuiPreventedEvent,
      onCellEditStart: DefaultMuiPreventedEvent,
      onCellEditStop: DefaultMuiPreventedEvent,
      onCellKeyDown: DefaultMuiPreventedEvent,
      onCellClick: DefaultMuiPreventedEvent,
    };
  };
}
