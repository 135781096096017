import { useMemo } from 'react';
import { differenceInMilliseconds } from 'date-fns';
import { useTypedContext } from '../../../../../../../../../hooks/useTypedContext';
import { AwareCaseContentDataContext } from '../../AwareCaseContent/types';

export const useXAxisDomain = () => {
  const { awareSession } = useTypedContext(AwareCaseContentDataContext);

  return useMemo(() => {
    if (!awareSession?.end_date || !awareSession?.start_date) return [0, 0];

    return [
      0,
      differenceInMilliseconds(
        new Date(awareSession.end_date),
        new Date(awareSession.start_date)
      ),
    ];
  }, [awareSession.end_date, awareSession.start_date]);
};
