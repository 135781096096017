import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getCasePhaseHistory } from '../../cases';
import { CaseQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

const ZERO_DATE: string = '0001-01-01T00:00:00+00:00';

const useCasePhaseHistory = ({
  params,
  options,
}: IQueryRequest<CaseQueryParams>) => {
  const { organizationId, caseId } = params;

  return useQuery({
    ...options,
    queryKey: [QueryKeys.getCasePhaseHistory, organizationId, caseId],
    queryFn: async () => {
      const { data } = await getCasePhaseHistory(organizationId, caseId);

      return data.data?.map((item) => {
        if (item.date_to === ZERO_DATE) item.date_to = null;
        return item;
      });
    },
  });
};

export default useCasePhaseHistory;
