import { useQueryRefetch } from 'hooks/queries/useQueryRefetch';
import { IUserConversationHistoryEntry } from 'interfaces/conversations';
import { useEffect } from 'react';
import { UseQueryResult } from 'react-query';
import { useCaseParticipant } from './useCaseParticipant';

export const useConversationAnswerHistoryInitQuery = (
  conversationHistory: UseQueryResult<IUserConversationHistoryEntry[]>,
  templateIds: string[] | undefined
) => {
  const { personality } = useCaseParticipant();

  const queryRefetch = useQueryRefetch();

  useEffect(() => {
    if (personality && templateIds !== undefined)
      queryRefetch(conversationHistory).then((_) => {});
  }, [conversationHistory, personality, queryRefetch, templateIds]);
};
