import { useEffect, useState } from 'react';

export const useControlledValue = <T>(
  initial?: T,
  onChange?: (value: T) => void
) => {
  const [value, setValue] = useState<T | null>(initial ?? null);

  useEffect(() => {
    setValue(initial ?? null);
  }, [initial]);

  const handleChange = (payload: T) => {
    setValue(payload);
    onChange?.(payload);
  };

  const handleReset = () => {
    setValue(initial ?? null);
  };

  return { value, handleChange, handleReset };
};
