import { IPatchObject } from '../interfaces/request';
import request from './utils/request';
import { ICreateProject } from '../interfaces/submissions';

export const updateProject = (
  organizationId: string,
  projectId: string,
  data: IPatchObject[]
) =>
  request<IPatchObject[], void>({
    url: `/organizations/${organizationId}/projects/${projectId}`,
    method: 'PATCH',
    data,
  });

export const createProject = (organizationId: string, data: ICreateProject) =>
  request<ICreateProject, undefined>({
    url: `/organizations/${organizationId}/projects`,
    method: 'POST',
    data: data,
  });
