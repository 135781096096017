import { shareGraspWithOrganizationUser } from 'api/graspManagement';
import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import {
  displayErrorArrayNotifications,
  displayErrorNotification,
  displaySuccessNotification,
} from 'modules/Notifications';

type MutationShareUnshareParams = {
  grasp_uuid: string;
  organizationId: string;
  personalityId: string;
};

function useGraspShareWithPersonality(successFb?: () => void) {
  return useMutationWithResultNotification<MutationShareUnshareParams, void>({
    asyncApiFunction: async ({ grasp_uuid, organizationId, personalityId }) => {
      await shareGraspWithOrganizationUser(organizationId, personalityId, {
        grasp_uuid,
      });
    },
    resetOnResult: true,
    onSuccess: () => {
      displaySuccessNotification('Grasps.shareSuccess');
      successFb && successFb();
    },
    onErrorArray: (errArr) => {
      if (errArr.length > 0) {
        displayErrorArrayNotifications(errArr, 'Grasps.errors');
      } else {
        displayErrorNotification('Grasps.shareError');
      }
    },
  });
}

export default useGraspShareWithPersonality;
