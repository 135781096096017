import { useMemo } from 'react';
import { UseQueryResult } from 'react-query';
import useExerciseSessions from '../../../../../api/queries/exerciseSessions/useExerciseSessions';
import useTimelineObservationsForPeriod from '../../../../../api/queries/timelines/useTimelineObservationsForPeriod';
import useTimelineSqueezeDataFetch from '../../../../../api/queries/timelines/useTimelineSqueezeDataFetch';
import { CasesHelpers } from '../../../../../helpers/data/cases';
import { getDateRangeForDate } from '../../../../../helpers/dates';
import { getCaseMasterTimeline } from '../../../../../helpers/timelines';
import useAsyncEventListen from '../../../../../hooks/events/useAsyncEventListen';
import { useInitQuery } from '../../../../../hooks/queries/useInitQuery';
import { useQueriesLoading } from '../../../../../hooks/queries/useQueriesLoading';
import { ICaseDetails } from '../../../../../interfaces/cases';
import {
  Granularity,
  SqueezeQuantityGrouping,
} from '../../../../../interfaces/timeline';

export const useObservationData = (
  date: Date,
  caseDetails: UseQueryResult<ICaseDetails>
) => {
  useInitQuery(caseDetails);

  const userId = caseDetails.data
    ? CasesHelpers.GetUserIdBasedOnCase(caseDetails.data)
    : null;

  const timelineId = useMemo(
    () => getCaseMasterTimeline(caseDetails.data),
    [caseDetails.data]
  );

  const dateRange = getDateRangeForDate(date);

  const observationQuery = useTimelineSqueezeDataFetch({
    params: {
      timelineId: timelineId ?? '',
      granularity: Granularity.h1,
      dateRangeObject: dateRange,
      groupBy: SqueezeQuantityGrouping.Meaning,
    },
    options: { enabled: !!timelineId },
  });

  const observationInTimelinesQuery = useTimelineObservationsForPeriod({
    params: {
      timelineId: timelineId ?? '',
      from: dateRange.from,
      to: dateRange.to,
    },
    options: { enabled: !!timelineId },
  });

  const exercisesQuery = useExerciseSessions({
    params: { userId: userId ?? '', from: dateRange.from, to: dateRange.to },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(exercisesQuery, !!userId);

  useAsyncEventListen(
    'hour-by-hour-time-refetch-requested',
    async () => {
      await observationQuery.refetch();
      await observationInTimelinesQuery.refetch();
      await exercisesQuery.refetch();
    },
    true
  );

  const isLoading = useQueriesLoading([
    caseDetails,
    observationQuery,
    observationInTimelinesQuery,
    exercisesQuery,
  ]);

  return {
    observationQuery,
    observationInTimelinesQuery,
    exercisesQuery,
    isLoading,
  };
};
