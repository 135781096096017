import SVG from 'react-inlinesvg';
import { GraspColors, graspColorsValues } from 'helpers/colors';
import {
  Point,
  UseMountainPathProps,
  useMountainPath,
} from '../hooks/useMountainPath';

import buddyCaseMountain from 'assets/images/buddy_case/buddy_case_mountain.svg';
import mountainFlag from 'assets/images/buddy_case/mountain_flag.svg';
import mountainBase from 'assets/images/buddy_case/mountain_base.svg';

const blue = graspColorsValues[GraspColors.BLUE];
const lightBlue = '#f9fbfd';
const gray = '#d9d9d9';

type BuddyCaseMountainProps = UseMountainPathProps & {
  onPointClick?: (pointIdx: number) => void;
};

const BuddyCaseMountain = ({
  values,
  currentIdx,
  initialIdx,
  onPointClick,
}: BuddyCaseMountainProps) => {
  const { pointIdx, endIdx, pathString, subPathString, points } =
    useMountainPath({ values, currentIdx, initialIdx });

  const sum = values.reduce(
    (result, curr, currIdx) => (currIdx < endIdx ? result + curr : result),
    0
  );

  const flagColor = endIdx === points.length - 1 ? blue : gray;

  const bases = points.map((s, index) => {
    const valueIndex = index - 1;
    if (index === 0) return undefined;

    const active = index <= endIdx;
    const selected = index === pointIdx && index === points.length - 1;
    const withPin = index === pointIdx && index !== points.length - 1;

    const onClick = () => onPointClick?.(valueIndex);

    return (
      <Base
        key={`base${s.x},${s.y}`}
        point={s}
        value={values[valueIndex]}
        active={active}
        selected={selected}
        withPin={withPin}
        onClick={onClick}
      />
    );
  });

  return (
    <div className="BuddyCaseMountain">
      <SVG src={buddyCaseMountain} className="BuddyCaseMountain__mountain" />
      <div className="BuddyCaseMountain__elements">
        <Path pathString={pathString} color={gray} />
        <Path pathString={subPathString} color={blue} />
        <Flag count={sum} color={flagColor} />
        {bases}
      </div>
    </div>
  );
};

interface PathProps {
  pathString: string;
  color: string;
}

const Path = ({ pathString, color }: PathProps) => {
  return (
    <svg
      className="BuddyCaseMountain__path"
      viewBox="0 0 100 100"
      preserveAspectRatio="none"
    >
      <path d={pathString} fill="none" stroke={color} />
    </svg>
  );
};

interface BaseProps {
  point: Point;
  value: number;
  active: boolean;
  selected: boolean;
  withPin: boolean;
  onClick?: () => void;
}

const Base = ({
  point,
  value,
  active,
  selected,
  withPin,
  onClick,
}: BaseProps) => {
  const sideClass = point.x > 50 ? 'baseRight' : 'baseLeft';
  const pinClass = withPin ? 'withPin' : '';

  const pinClasses = `BuddyCaseMountain__base-pin ${sideClass}`;
  const valueClasses = `BuddyCaseMountain__base-value ${sideClass} ${pinClass}`;

  const top = `${point.y}%`;
  const left = `${point.x}%`;

  const color = active ? blue : gray;

  const onClickCallback = () => onClick?.();

  return (
    <div
      className="BuddyCaseMountain__base"
      style={{ top, left }}
      onClick={onClickCallback}
    >
      {withPin && (
        <SVG
          className={pinClasses}
          src={mountainBase}
          style={{ fill: color }}
        />
      )}
      <svg viewBox="0 0 100 100">
        <circle
          className="BuddyCaseMountain__base-circle"
          cx="50%"
          cy="50%"
          r="20%"
          fill={color}
        />
        {selected && (
          <circle
            className="BuddyCaseMountain__base-circle"
            cx="50%"
            cy="50%"
            r="5%"
            fill={lightBlue}
          />
        )}
      </svg>
      <span className={valueClasses} style={{ color }}>
        {value}
      </span>
    </div>
  );
};

interface FlagProps {
  color: string;
  count: number;
}

const Flag = ({ color, count }: FlagProps) => {
  return (
    <div className="BuddyCaseMountain__flag">
      <SVG
        className="BuddyCaseMountain__flag-icon"
        src={mountainFlag}
        style={{ fill: color, stroke: color }}
      />
      {!!count && (
        <span className="BuddyCaseMountain__flag-count">{count}</span>
      )}
    </div>
  );
};

export default BuddyCaseMountain;
