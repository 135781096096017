import React from 'react';
import FieldSelect from '../../../../../../../../../../components/FieldSelect';
import { Field } from 'formik';
import { useTranslations } from '../../hooks/useTranslations';
import { useOptions } from '../../../../../../../../../../hooks/useOptions';
import {
  QuestionValidConversationTemplateItem,
  ValidConversationQuestionType,
  ValidConversationTemplate,
} from '../../../../../../../../../../interfaces/conversations';
import ClosedList from './components/ClosedList';
import FieldInput from '../../../../../../../../../../components/FieldInput';
import Email from './components/Email';
import Time from './components/Time';
import Range from './components/Range';
import Open from './components/Open';
import EditableInput from '../../../../../../../../../../components/EditableInput/EditableInput';
import { useHandleChangeType } from './hooks/useHandleChangeType';

type QuestionItemEditProps = {
  index: number;
  values: ValidConversationTemplate;
};

const QuestionItemEdit = ({ index, values }: QuestionItemEditProps) => {
  const { providerInvariant } = useTranslations();

  const selectedItem = values.content[index];

  const selectedQuestion =
    selectedItem as QuestionValidConversationTemplateItem;

  const options = useOptions();
  const types = options.conversationQuestionTypes();
  const valueInterpretations =
    options.conversationQuestionValueInterpretations();

  const handleChange = useHandleChangeType(index, values);

  return (
    <>
      <EditableInput
        type="select"
        displayEmpty={true}
        required={true}
        placeholder={providerInvariant.wrapWithIntl(
          providerInvariant.attachPrefix('typePlaceholder')
        )}
        label={providerInvariant.wrapWithIntl(
          providerInvariant.attachPrefix('type')
        )}
        name={`content[${index}].value.type`}
        options={types}
        value={selectedQuestion.value.type}
        onEdit={handleChange}
        debounceEnabled={false}
      />
      <Field
        required={true}
        displayEmpty={true}
        placeholder={providerInvariant.wrapWithIntl(
          providerInvariant.attachPrefix('valueInterpretationPlaceholder')
        )}
        label={providerInvariant.wrapWithIntl(
          providerInvariant.attachPrefix('valueInterpretation')
        )}
        id={`content[${index}].value.value_interpretation`}
        name={`content[${index}].value.value_interpretation`}
        type="select"
        options={valueInterpretations}
        component={FieldSelect}
      />
      <div className="d-flex flex-column gap-1">
        <Field
          required={true}
          id={`content[${index}].value.included_in_trends`}
          name={`content[${index}].value.included_in_trends`}
          label={providerInvariant.wrapWithIntl(
            providerInvariant.attachPrefix('includedInTrends')
          )}
          type="checkbox"
          component={FieldInput}
        />
        <Field
          required={true}
          id={`content[${index}].value.show_title`}
          name={`content[${index}].value.show_title`}
          label={providerInvariant.wrapWithIntl(
            providerInvariant.attachPrefix('showTitle')
          )}
          type="checkbox"
          component={FieldInput}
        />
        <Field
          required={true}
          id={`content[${index}].value.show_description`}
          name={`content[${index}].value.show_description`}
          label={providerInvariant.wrapWithIntl(
            providerInvariant.attachPrefix('showDescription')
          )}
          type="checkbox"
          component={FieldInput}
        />
        <Field
          required={true}
          id={`content[${index}].value.required`}
          name={`content[${index}].value.required`}
          label={providerInvariant.wrapWithIntl(
            providerInvariant.attachPrefix('required')
          )}
          type="checkbox"
          component={FieldInput}
        />
      </div>
      <TypeBasedQuestionItemEdit values={values} index={index} />
    </>
  );
};

const TypeBasedQuestionItemEdit = ({
  index,
  values,
}: QuestionItemEditProps) => {
  const selectedItem = values.content[index];
  const selectedQuestion =
    selectedItem as QuestionValidConversationTemplateItem;

  switch (selectedQuestion.value.type) {
    case ValidConversationQuestionType.ClosedList:
      return <ClosedList index={index} values={values} />;
    case ValidConversationQuestionType.Time:
      return <Time index={index} values={values} />;
    case ValidConversationQuestionType.Range:
      return <Range index={index} values={values} />;
    case ValidConversationQuestionType.Open:
      return <Open index={index} values={values} />;
    case ValidConversationQuestionType.Header:
      return <></>;
    case ValidConversationQuestionType.Email:
      return <Email index={index} values={values} />;
  }
};

export default QuestionItemEdit;
