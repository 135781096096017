import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  ConversationTemplate,
  ValidConversationTemplateItem,
} from '../../../../../../../../interfaces/conversations';
import ConversationItem from './components/ConversationItem';
import { ItemActionsContext } from '../../types';
import AddConversationItem from './components/AddConversationItem';
import { wrapWithSequenceIdentified } from '../../../../../../../../helpers/utils/data';

type ConversationProps = {
  value: ConversationTemplate;
  avatars?: string[];
  actionsDisabled?: boolean;
};

const Conversation = ({
  value,
  avatars = [],
  actionsDisabled = false,
}: ConversationProps) => {
  const actions = useContext(ItemActionsContext);
  const conversationWrapperEndRef = useRef<HTMLDivElement>(null);

  const [itemAdded, setItemAdded] = useState<boolean>(() => false);

  const addAction = (item: ValidConversationTemplateItem) => {
    actions?.onAddItem?.(item);
    setItemAdded(true);
  };

  useEffect(() => {
    if (itemAdded) {
      conversationWrapperEndRef?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
      setItemAdded(false);
    }
  }, [itemAdded, value.content.length]);

  return (
    <>
      <div className="ConversationTemplatePhoneView__conversation__wrapper">
        {wrapWithSequenceIdentified(value.content).map((item, index) => (
          <ConversationItem
            key={item.sequence}
            index={index}
            item={item}
            isFirst={index === 0}
            isLast={index === value.content.length - 1}
            avatars={avatars}
            actionsDisabled={actionsDisabled}
          />
        ))}
        <div ref={conversationWrapperEndRef} />
      </div>
      {actions?.onAddItem && <AddConversationItem onAdd={addAction} />}
    </>
  );
};

export default Conversation;
