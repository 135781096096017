import React, { useContext } from 'react';
import { Button, Tooltip } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownload } from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage } from 'react-intl';
import useOpen from '../../../../../../hooks/useOpen';
import { ChartDownloadContext } from '../../../../types';
import { ChartDownloadHookValues } from '../../../../hooks/useTrendsChartDownload';
import { useTranslations } from '../../../../../../hooks/useTranslations';

const TrendsChartDownload = () => {
  const { isOpen, toggle } = useOpen();

  const { handleDownload } = useContext(
    ChartDownloadContext
  ) as ChartDownloadHookValues;

  const { attachPrefix } = useTranslations(
    'TrendsChart.TrendsChartActions.TrendsChartDownload'
  );

  return (
    <div>
      <Button
        color="primary"
        outline={true}
        id="TrendsChartDownload"
        onClick={handleDownload}
      >
        <FontAwesomeIcon icon={faCloudDownload} />
      </Button>
      <Tooltip
        placement="top"
        target="TrendsChartDownload"
        toggle={toggle}
        isOpen={isOpen}
      >
        <FormattedMessage id={attachPrefix('downloadChart')} />
      </Tooltip>
    </div>
  );
};

export default TrendsChartDownload;
