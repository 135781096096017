import TextField from '@mui/material/TextField';
import { FormikProps } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { FormValues } from '../../types';

type SingleNotificationFormProps = {
  form: FormikProps<FormValues>;
};

const SingleNotificationForm = ({ form }: SingleNotificationFormProps) => {
  const intl = useIntl();

  return (
    <TextField
      variant="outlined"
      type="number"
      name="schedule_templates[0].specific_day_of_phase"
      placeholder={intl.formatMessage({
        id: 'Treatment.SpecificDayOfPhase',
      })}
      label={intl.formatMessage({
        id: 'Treatment.SpecificDayOfPhase',
      })}
      inputProps={{ min: 1 }}
      InputLabelProps={{
        shrink: true,
      }}
      value={form.values.schedule_templates[0].specific_day_of_phase}
      onChange={form.handleChange}
      onBlur={form.handleBlur}
    />
  );
};

export default SingleNotificationForm;
