import { ICaseGroup } from '../../../../../../../interfaces/caseGroups';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { addDays, differenceInCalendarDays } from 'date-fns';
import { strToDateOnly, toDateOnly } from 'helpers/dates';

export const useCaseGroupDetailsConversationSchedulesDay = (
  caseGroup: ICaseGroup | undefined
) => {
  const [internalDate, setInternalDate] = useState<string>(
    toDateOnly(new Date())
  );

  useEffect(() => {
    const nowStr = toDateOnly(new Date());

    if (!caseGroup) {
      setInternalDate(nowStr);
      return;
    }

    if (nowStr < strToDateOnly(caseGroup.starting_date)) {
      setInternalDate(strToDateOnly(caseGroup.starting_date));
      return;
    }

    const lastCaseGroupDate = caseGroup.group_schedule.at(
      caseGroup.group_schedule.length - 1
    );

    if (
      lastCaseGroupDate &&
      nowStr > strToDateOnly(lastCaseGroupDate.date_to)
    ) {
      setInternalDate(strToDateOnly(lastCaseGroupDate.date_to));
      return;
    }

    setInternalDate(nowStr);
  }, [caseGroup]);

  const handleNext = useCallback(() => {
    const endDate = caseGroup?.group_schedule.at(
      caseGroup.group_schedule.length - 1
    )?.date_to;

    if (!endDate) return;

    const dayPlusOne = addDays(new Date(internalDate), 1).toISOString();

    if (strToDateOnly(dayPlusOne) > strToDateOnly(endDate)) return;

    setInternalDate(strToDateOnly(dayPlusOne));
  }, [caseGroup?.group_schedule, internalDate]);

  const handlePrev = useCallback(() => {
    const startDate = caseGroup?.starting_date;

    if (!startDate) return;

    const dayMinusOne = addDays(new Date(internalDate), -1).toISOString();

    if (strToDateOnly(dayMinusOne) < strToDateOnly(startDate)) return;

    setInternalDate(strToDateOnly(dayMinusOne));
  }, [caseGroup?.starting_date, internalDate]);

  const selectDateForPhase = useCallback(
    (phaseId: string) => {
      const existingGroupSchedule = caseGroup?.group_schedule.find(
        (p) => p.phase_id === phaseId
      );

      if (!existingGroupSchedule) return;

      setInternalDate(strToDateOnly(existingGroupSchedule.date_from));
    },
    [caseGroup?.group_schedule]
  );

  const phaseDescription = useMemo(() => {
    if (!caseGroup) return null;

    const startDate = strToDateOnly(caseGroup.starting_date);
    let endDate =
      caseGroup.group_schedule.at(caseGroup.group_schedule.length - 1)
        ?.date_to ?? new Date().toISOString();

    endDate = strToDateOnly(endDate);

    if (internalDate < startDate || internalDate > endDate) return null;

    const currentGroupScheduleItem = caseGroup.group_schedule.find(
      (p) =>
        internalDate >= strToDateOnly(p.date_from) &&
        internalDate <= strToDateOnly(p.date_to)
    );

    if (!currentGroupScheduleItem) return null;

    return {
      id: currentGroupScheduleItem.phase_id,
      startDate: currentGroupScheduleItem.date_from,
      endDate: currentGroupScheduleItem.date_to,
      daysDuration:
        differenceInCalendarDays(
          new Date(currentGroupScheduleItem.date_to),
          new Date(currentGroupScheduleItem.date_from)
        ) + 1,
      nthDay:
        differenceInCalendarDays(
          new Date(internalDate),
          new Date(currentGroupScheduleItem.date_from)
        ) + 1,
    };
  }, [caseGroup, internalDate]);

  return {
    selectedDate: internalDate,
    selectPhase: selectDateForPhase,
    handleNext: handleNext,
    handlePrev: handlePrev,
    phaseDescription: phaseDescription,
  };
};
