import useUpdateNotificationSchedule from '../../../../../../../../../../../../../../../../../api/mutations/notificationSchedules/useUpdateNotificationSchedule';
import { CaseNotificationSchedule } from '../../../../../../../../../../../../../../../../../interfaces/notificationSchedules';
import { useNotificationSchedulesQuery } from '../../../../../hooks/useNotificationSchedulesQuery';

export const useHandleUpdate = (
  selectedDate: string,
  schedule: CaseNotificationSchedule,
  toggle: () => void
) => {
  const notificationSchedules = useNotificationSchedulesQuery(selectedDate);

  const handleSuccess = async () => {
    await notificationSchedules.refetch();
    toggle();
  };

  const { mutateAsync: handleUpdateAsync } = useUpdateNotificationSchedule({
    params: {
      caseId: schedule.case_id,
      userId: schedule.user_id,
      scheduleId: schedule.id,
    },
    options: { successFb: handleSuccess },
  });

  return handleUpdateAsync;
};
