import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { regenerateAwareSessionData } from '../../conversations';
import { IMutationProps } from '../../../interfaces/ui';
import { OrganizationQueryParams } from '../../../interfaces/queryParams';

type RegenerateAwareSessionDataProps = {
  params: RegenerateAwareSessionDataParams;
  options: IMutationProps;
};

type RegenerateAwareSessionDataParams = OrganizationQueryParams;

const useRegenerateAwareSessionData = (
  props: RegenerateAwareSessionDataProps
) => {
  return useMutationWithResultNotification({
    onErrorArray: (_) => {
      displayErrorNotification(
        'Api.Mutations.Conversations.RegenerateAwareSessionData.failure'
      );

      props.options.errorFb?.();
    },
    onSuccess: () => {
      displaySuccessNotification(
        'Api.Mutations.Conversations.RegenerateAwareSessionData.success'
      );

      props.options.successFb?.();
    },
    asyncApiFunction: async (templateId: string) => {
      await regenerateAwareSessionData(props.params.organizationId, templateId);
    },
  });
};

export default useRegenerateAwareSessionData;
