import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar } from '@fortawesome/pro-light-svg-icons';
import Skeleton from 'react-loading-skeleton';

const EmptyGroupFeedbackLoading = () => {
  return (
    <div className="EmptyGroupFeedback">
      <FontAwesomeIcon icon={faChartBar} size="5x" />
      <span className="EmptyGroupFeedback__text">
        <Skeleton width={300} />
      </span>
      <Skeleton width={50} />
    </div>
  );
};

export default EmptyGroupFeedbackLoading;
