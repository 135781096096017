import { faClock } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FieldProps } from 'formik';
import { iconColors } from 'helpers/colors';
import { formatDateToInputDateString } from 'helpers/dates';
import { ChangeEvent } from 'react';
import { Input, InputGroup, InputGroupText } from 'reactstrap';

const TimeInput = ({ form, field }: Partial<FieldProps>) => {
  const dateObject = new Date(field?.value);

  const formattedDate = formatDateToInputDateString(dateObject);
  const formattedTime = `${dateObject
    .getHours()
    .toString()
    .padStart(2, '0')}:${dateObject.getMinutes().toString().padStart(2, '0')}`;

  const onTimeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const time = event.target.value;
    const timeArray = time.split(':');

    const newDate = new Date(formattedDate);
    newDate.setHours(parseInt(timeArray[0]), parseInt(timeArray[1]));

    form?.setValues({
      ...form.values,
      [field?.name as string]: newDate.toISOString(),
    });
  };

  return (
    <div className="DateTimeInput">
      <InputGroup size="lg" className="DateTimeInput__time">
        <InputGroupText className="transparent-group-text">
          <FontAwesomeIcon icon={faClock} color={iconColors.time} />
        </InputGroupText>
        <Input
          type="time"
          onChange={onTimeChange}
          value={formattedTime}
          id={field?.name}
        />
      </InputGroup>
    </div>
  );
};

export default TimeInput;
