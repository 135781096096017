import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getCaseGroup } from '../../caseGroups';
import { OrganizationQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

interface CaseGroupProps extends OrganizationQueryParams {
  projectId: string;
  groupId: string;
}

const useCaseGroup = ({ params, options }: IQueryRequest<CaseGroupProps>) => {
  const { organizationId, projectId, groupId } = params;
  return useQuery({
    ...options,
    queryKey: [QueryKeys.getCaseGroup, organizationId, projectId, groupId],
    queryFn: async () => {
      const { data } = await getCaseGroup(organizationId, projectId, groupId);
      return data.data;
    },
  });
};

export default useCaseGroup;
