import { differenceInCalendarDays } from 'date-fns';
import {
  ICaseConversationSchedule,
  ICaseSchedulesEditTimeFormInitialValues,
  IRecurringCaseConversationSchedule,
  ISingleInstanceCaseConversationSchedule,
  UpdateRangeMode,
} from 'interfaces/conversationSchedules';
import { useCallback } from 'react';
import { useCommonData } from '../../../hooks/useCommonData';

export const useEditTimeModalFormInitialValues = () => {
  const { casePhaseHistory } = useCommonData();

  const prepareBasic = useCallback(
    (
      schedule: ICaseConversationSchedule
    ): ICaseSchedulesEditTimeFormInitialValues => {
      return {
        template_id: schedule.template_id,
        priority: schedule.priority,
        specific_day_of_phase: null,
        end_day_offset: null,
        recurring_interval: null,
        start_day_offset: null,
        update_mode: null,
        available_from_hour: null,
        available_to_hour: null,
        notification_hour: null,
        is_completed: schedule.is_completed,
      };
    },
    []
  );

  const prepareSingleInstance = useCallback(
    (
      schedule: ICaseConversationSchedule
    ): ICaseSchedulesEditTimeFormInitialValues => {
      const singleInstance =
        schedule as ISingleInstanceCaseConversationSchedule;

      if (singleInstance.is_dirty) {
        return {
          template_id: singleInstance.template_id,
          priority: singleInstance.priority,
          update_mode: UpdateRangeMode.SingleEvent,
          available_from_hour: singleInstance.available_from_hour,
          available_to_hour: singleInstance.available_to_hour,
          notification_hour: singleInstance.notification_hour,
          start_day_offset: null,
          recurring_interval: null,
          end_day_offset: null,
          specific_day_of_phase: null,
          is_completed: singleInstance.is_completed,
        };
      }

      let casePhaseHistoryData = casePhaseHistory.data ?? [];
      casePhaseHistoryData.sort((a, b) => b.sequence - a.sequence);
      let currentCasePhaseHistory = casePhaseHistoryData[0];

      const specificDayOfPhase = differenceInCalendarDays(
        new Date(singleInstance.date),
        new Date(currentCasePhaseHistory.date_from)
      );

      return {
        template_id: singleInstance.template_id,
        priority: singleInstance.priority,
        specific_day_of_phase: specificDayOfPhase + 1,
        available_from_hour: singleInstance.available_from_hour,
        available_to_hour: singleInstance.available_to_hour,
        notification_hour: singleInstance.notification_hour,
        start_day_offset: null,
        end_day_offset: null,
        recurring_interval: null,
        update_mode: null,
        is_completed: singleInstance.is_completed,
      };
    },
    [casePhaseHistory.data]
  );

  const prepareRecurring = useCallback(
    (
      schedule: ICaseConversationSchedule
    ): ICaseSchedulesEditTimeFormInitialValues => {
      const recurringSchedule = schedule as IRecurringCaseConversationSchedule;

      let casePhaseHistoryData = casePhaseHistory.data ?? [];
      casePhaseHistoryData.sort((a, b) => b.sequence - a.sequence);
      let currentCasePhaseHistory = casePhaseHistoryData[0];

      const startDayOffset = differenceInCalendarDays(
        new Date(recurringSchedule.date_from),
        new Date(currentCasePhaseHistory.date_from)
      );

      const endDayOffset = differenceInCalendarDays(
        new Date(recurringSchedule.date_to),
        new Date(currentCasePhaseHistory.date_from)
      );

      return {
        template_id: recurringSchedule.template_id,
        priority: recurringSchedule.priority,
        update_mode: UpdateRangeMode.AllEvents,
        recurring_interval: recurringSchedule.recurring_interval,
        available_from_hour: recurringSchedule.available_from_hour,
        available_to_hour: recurringSchedule.available_to_hour,
        notification_hour: recurringSchedule.notification_hour,
        specific_day_of_phase: null,
        start_day_offset: startDayOffset + 1,
        end_day_offset: endDayOffset + 1,
        is_completed: recurringSchedule.is_completed,
      };
    },
    [casePhaseHistory.data]
  );

  return {
    prepareBasic: prepareBasic,
    prepareSingleInstance: prepareSingleInstance,
    prepareRecurring: prepareRecurring,
  };
};
