import { IObservation, ObservationSymptom } from '../../../interfaces/timeline';
import { groupByProperty } from '../../../helpers/utils/groupByProperty';
import { ChartsData, TrendsChartData } from '../types';

export const normalizeTimestamps = (observations: IObservation[]) => {
  return observations.map((observation) => {
    let timestamp = new Date(observation.timestamp);
    timestamp.setHours(0, 0, 0, 0);

    return {
      ...observation,
      timestamp: timestamp.toISOString(),
    };
  });
};

export const groupObservationsByTimeStamp = (observations: IObservation[]) => {
  const groupByTimestamp = groupByProperty('timestamp');
  return groupByTimestamp(observations) as Record<string, IObservation[]>;
};

export const completeChartData = (
  groupedObservations: Record<string, IObservation[]>,
  allObservations: TrendsChartData<string | number>
) => {
  const dataKeys = allObservations.data.map((p) => p.name) as string[];

  let _data: ChartsData<string | number>[] = [];

  dataKeys.forEach((key) => {
    let entry: ChartsData<string | number> = {};
    entry.name = key;

    const observationGroup = Object.entries(groupedObservations).find(
      ([k]) =>
        k.substring(0, k.indexOf('T')) === key.substring(0, key.indexOf('T'))
    );

    if (!observationGroup) {
      _data.push(entry);
      return;
    }

    const [, observations] = observationGroup;

    for (const observation of observations) {
      if (!observation.meaning_id) {
        entry = incrementPropertyField(entry, ObservationSymptom.NotSpecified);
        return;
      }
      entry = incrementPropertyField(entry, observation.meaning_id);
    }

    _data.push(entry);
  });

  return _data;
};

const incrementPropertyField = (
  entry: ChartsData<string | number>,
  objectKey: string
) => {
  if (Object.hasOwn(entry, objectKey)) {
    entry[objectKey] = (entry[objectKey] as number) + 1;
    return entry;
  }

  entry[objectKey] = 1;
  return entry;
};
