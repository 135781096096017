import { useMemo } from 'react';
import { cloneDeep } from 'lodash';
import { GroupedConversationQuestion } from 'interfaces/conversations';

export const useQuestionsIncludedInTrends = (
  groupedQuestions: GroupedConversationQuestion[]
): GroupedConversationQuestion[] => {
  return useMemo(() => {
    let questionsCopy = cloneDeep(groupedQuestions);

    questionsCopy = questionsCopy.map((item) => {
      return {
        template_ids: item.template_ids,
        questions: item.questions.filter(
          (question) => question.content.included_in_trends
        ),
      };
    });

    questionsCopy = questionsCopy.filter((p) => p.questions.length !== 0);
    return questionsCopy;
  }, [groupedQuestions]);
};
