import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/pro-solid-svg-icons';
import { formatDate } from '../../../helpers/dates';
import InlineDaySelectorPlaceholder from './components/InlineDaySelectorPlaceholder';

type InlineDateSelectorProps = {
  handlePrev: () => void;
  handleNext: () => void;
  date: string;
  isLoading?: boolean;
};

const InlineDaySelector = ({
  date,
  handlePrev,
  handleNext,
  isLoading = false,
}: InlineDateSelectorProps) => {
  if (isLoading) return <InlineDaySelectorPlaceholder />;

  return (
    <div className="InlineDaySelector">
      <span onClick={handlePrev}>
        <FontAwesomeIcon icon={faCaretLeft} />
      </span>
      <span className="user-select-none" onClick={handlePrev}>
        {formatDate(date, {
          ignoreTime: true,
          daysLeadingZeros: true,
        })}
      </span>
      <span onClick={handleNext}>
        <FontAwesomeIcon icon={faCaretRight} />
      </span>
    </div>
  );
};

export default InlineDaySelector;
