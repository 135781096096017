import {
  ICaseSymptomDefinition,
  ICaseSymptomDefinitionWithDefaultInformation,
} from '../../interfaces/symptoms';

export function hasDuplicateSymptomNames(
  definitions:
    | ICaseSymptomDefinition[]
    | ICaseSymptomDefinitionWithDefaultInformation[]
) {
  let hasDuplicate = false;
  definitions.every((element, index) => {
    const copyOfArray = [...definitions];
    copyOfArray.splice(index, 1);

    const otherElementWithSameName = copyOfArray.find(
      (otherDef) => otherDef.patient_symptom === element.patient_symptom
    );

    if (otherElementWithSameName !== undefined) {
      hasDuplicate = true;
      return false;
    }

    return true;
  });

  return hasDuplicate;
}

export function hasEmptyMeanings(
  definitions:
    | ICaseSymptomDefinition[]
    | ICaseSymptomDefinitionWithDefaultInformation[]
) {
  let hasEmpty = false;
  definitions.every((el) => {
    if (el.symptom_id === '' || el.patient_symptom.trim() === '') {
      hasEmpty = true;
      return false;
    }

    return true;
  });

  return hasEmpty;
}
