import { useTranslations } from '../../../../../../../../../hooks/useTranslations';
import { useCallback, useMemo } from 'react';
import { NameValueTuple } from '../../../../../../../../../interfaces/ui';
import {
  GraspColors,
  graspColorsValues,
} from '../../../../../../../../../helpers/colors';
import { IPieChartData } from '../../../../../../../../../interfaces/charts';

export const useGetDataColor = () => {
  const { attachPrefix, wrapWithIntl } = useTranslations(
    'Cases.CaseDetails.AwareCase.Duration'
  );

  const colorDefinition = useMemo((): NameValueTuple<string, string>[] => {
    return [
      {
        name: wrapWithIntl(attachPrefix('idleTime')),
        value: graspColorsValues[GraspColors.LightBlue600],
      },
      {
        name: wrapWithIntl(attachPrefix('squeezingTime')),
        value: graspColorsValues[GraspColors.Ocean500],
      },
    ];
  }, [attachPrefix, wrapWithIntl]);

  return useCallback(
    (data: IPieChartData) => {
      const existingColorDefinition = colorDefinition.find(
        (p) => p.name === data.name
      );

      if (!existingColorDefinition) return '#000';
      return existingColorDefinition.value;
    },
    [colorDefinition]
  );
};
