import classNames from 'classnames';
import ModalAnswers from 'components/ModalAnswers';
import ModalQuestion from 'components/ModalQuestion';
import { returnIconForQuestion } from 'helpers/utils/icons';
import {
  AssessmentQuestion,
  AssessmentQuestionType,
  IAssessmentTemplate,
  IUserAssessments,
} from 'interfaces/assessmentForms';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  createFormObjectToSent,
  verifyVisibleExpression,
} from '../../helpers/utils/assessmentForms';
import {
  IConversationTemplatePlainTextItem,
  IFormConversationTemplate,
} from '../../interfaces/conversations';
import { SetStepAction } from '../../modules/TimelineDetails/components/tabs/DayByDayTab/components/QuestionModal/QuestionModalForm';

type InitialFormValuesType = {
  [key: string]: string;
};

type MobileQuestionFormProps = {
  values: InitialFormValuesType;
  setValues: (values: any) => void;
  form:
    | IUserAssessments
    | IAssessmentTemplate
    | IFormConversationTemplate
    | undefined;
  step: number;
  setStep: (val: number) => void;
  canEdit: boolean;
  isMobile: boolean;
  bigQuestion?: boolean;
  canDisplayQuestionIndicator?: boolean;
  lastSetStepAction: SetStepAction | null;
};

const StepsQuestionForm = ({
  form,
  values,
  setValues,
  step,
  setStep,
  canEdit,
  bigQuestion,
  isMobile,
  lastSetStepAction,
  canDisplayQuestionIndicator = true,
}: MobileQuestionFormProps) => {
  const selectedQuestion = form?.questions[step];

  const isFormQuestionsDynamicallyConstrained = useMemo(() => {
    return form?.questions && form.questions.some((p) => p.visible !== null);
  }, [form]);

  const answers = selectedQuestion?.answers
    ? (selectedQuestion as AssessmentQuestion).answers.map((answerItem) => {
        return {
          title: answerItem.title,
          description: answerItem.description,
          value: answerItem.guid,
          sentiment: answerItem.sentiment,
          sentiment_score: answerItem.sentiment_score,
        };
      })
    : [];

  const renderQuestionIndicator = () => {
    return (
      <div className="QuestionModal__modal__mobile-form__step">
        <FormattedMessage id="General.question" /> {step + 1} /{' '}
        {form?.questions.length}
      </div>
    );
  };

  const skipStep = () => {
    if (lastSetStepAction === null) return;

    if (
      lastSetStepAction === SetStepAction.NEXT &&
      form &&
      step + 1 <= form.questions.length
    ) {
      setStep(step + 1);
    }

    if (lastSetStepAction === SetStepAction.PREVIOUS && form && step - 1 >= 0) {
      setStep(step - 1);
    }
  };

  if (
    selectedQuestion &&
    (form === undefined ||
      verifyVisibleExpression(selectedQuestion.visible, {
        ...form,
        questions: createFormObjectToSent(values, form.questions, null),
      }))
  ) {
    const handleAnswerChange = (value: string) => {
      const questionIndex = Object.keys(values).findIndex(
        (p) => p === selectedQuestion.guid
      );

      if (questionIndex === -1 || !isFormQuestionsDynamicallyConstrained) {
        changeSingleValue(selectedQuestion.guid, value);
        return;
      }

      if (form) {
        const questionsToErase = getQuestionsToErase(questionIndex);

        if (Object.keys(questionsToErase).length > 0) {
          setValues({
            ...values,
            ...questionsToErase,
            [selectedQuestion.guid]: value,
          });

          return;
        }
      }

      changeSingleValue(selectedQuestion.guid, value);
    };

    const getQuestionsToErase = (fromIndex: number) => {
      let questionsToErase: any = {};

      Object.entries(values)
        .slice(fromIndex + 1)
        .forEach(([key, _]) => {
          const currentQuestion = form.questions.find((p) => p.guid === key);
          if (currentQuestion) {
            if (currentQuestion.type === AssessmentQuestionType.RANGE) {
              questionsToErase[key] =
                currentQuestion.minNumberValue > currentQuestion.maxNumberValue
                  ? currentQuestion.maxNumberValue
                  : currentQuestion.minNumberValue;
              return;
            }

            questionsToErase[key] = '';
          }
        });

      return questionsToErase;
    };

    const changeSingleValue = (guid: string, value: string) => {
      setValues({
        ...values,
        [guid]: value,
      });
    };

    return (
      <div
        className={classNames(
          {
            'QuestionModal__modal__mobile-form': isMobile,
          },
          {
            'mt-4': !isMobile,
          }
        )}
      >
        {canDisplayQuestionIndicator && renderQuestionIndicator()}
        <>
          {selectedQuestion.title ? (
            <ModalQuestion
              key={'question--' + selectedQuestion.guid}
              imageSrc={returnIconForQuestion(selectedQuestion.icon)}
              header={selectedQuestion.title}
              question={selectedQuestion.description}
              showDescription={
                (selectedQuestion as AssessmentQuestion).showDescription
              }
              showIcon={(selectedQuestion as AssessmentQuestion).showIcon}
              showTitle={(selectedQuestion as AssessmentQuestion).showTitle}
              bigQuestion={bigQuestion}
            />
          ) : (
            <ModalQuestion
              key={
                'question--' +
                (selectedQuestion as IConversationTemplatePlainTextItem)['Guid']
              }
              imageSrc={returnIconForQuestion('')}
              header={
                (selectedQuestion as IConversationTemplatePlainTextItem)['Text']
              }
              question={''}
              showDescription={false}
              showIcon={false}
              showTitle={true}
              bigQuestion={bigQuestion}
            />
          )}
        </>
        {answers && (
          <ModalAnswers
            canEdit={canEdit}
            guid={selectedQuestion.guid}
            type={selectedQuestion.type}
            key={'answers--' + selectedQuestion.guid}
            name={selectedQuestion.title}
            minNumberValue={
              (selectedQuestion as AssessmentQuestion).minNumberValue
            }
            maxNumberValue={
              (selectedQuestion as AssessmentQuestion).maxNumberValue
            }
            showSentimentScale={
              (selectedQuestion as AssessmentQuestion).showSentimentScale
            }
            items={answers}
            placeholder={selectedQuestion.answerPlaceHolder}
            maxLength={(selectedQuestion as AssessmentQuestion).answerMaxLength}
            answer={values[selectedQuestion.guid]}
            legendDescriptions={
              (selectedQuestion as AssessmentQuestion).legendDescriptions ?? []
            }
            onAnswerChange={handleAnswerChange}
          />
        )}
      </div>
    );
  }

  return <>{skipStep()}</>;
};

export default StepsQuestionForm;
