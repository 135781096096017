import { useCallback } from 'react';
import usePatchOrganizationUser from '../../../../../../../../../../../api/mutations/organizations/usePatchOrganizationUser';
import useInfiniteUserInOrganizationsWithRoles from '../../../../../../../../../../../api/queries/organizations/useInfiniteUserInOrganizationsWithRoles';
import { useOrganizationPathParams } from '../../../../../../../../../../../hooks/paths/useOrganizationPathParams';
import { ReactStateTuple } from '../../../../../../../../../../../interfaces/ui';
import { UsersTableTypes } from '../types';

export const useHandleSave = (
  rowState: ReactStateTuple<UsersTableTypes.EditingRow | null>
) => {
  const { organizationId } = useOrganizationPathParams();

  const [row, setRow] = rowState;

  const userInOrganizationsWithRoles = useInfiniteUserInOrganizationsWithRoles({
    params: { organizationId, pageSize: 1000 },
    options: { enabled: false, staleTime: Infinity },
  });

  const onSuccess = useCallback(async () => {
    setRow(null);
    await userInOrganizationsWithRoles.refetch();
  }, [setRow, userInOrganizationsWithRoles]);

  const { mutateAsync: patchAsync, isLoading } = usePatchOrganizationUser(
    {
      organizationId: organizationId,
      userId: row?.id ?? '',
    },
    { successFb: onSuccess }
  );

  return { saveAsync: patchAsync, isSaving: isLoading };
};
