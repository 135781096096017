import { parse } from 'date-fns';
import {
  AssessmentAnswer,
  AssessmentAnswerOption,
  AssessmentFormIdentification,
  AssessmentQuestion,
  AssessmentQuestionType,
  IAssessmentTemplate,
  IUserAssessments,
} from 'interfaces/assessmentForms';
import { AssessmentFormValues } from 'interfaces/ui';
import _, { cloneDeep } from 'lodash';
import {
  ConversationTemplateItemType,
  FormConversationAnswer,
  IConversationTemplatePlainTextItem,
  IFormConversation,
  IFormConversationItem,
  IFormConversationTemplate,
} from '../../interfaces/conversations';

export function returnInitialValuesFromFormConversation(
  conversation?: IFormConversation
) {
  let localArrayInitValues: AssessmentFormValues[] = [];

  const questions = conversation?.items?.filter(
    (p) => p.value.type !== AssessmentQuestionType.HEADER
  );

  questions?.forEach((question) => {
    if (question.type !== ConversationTemplateItemType.Question) {
      localArrayInitValues.push({
        [question.resourceId!]: 'NotSpecified',
      });

      return;
    }

    const markedAnswer = (
      question.value.answers as AssessmentAnswerOption[]
    ).find((questionItem) => questionItem.is_selected);

    let answer = '';

    if (markedAnswer) answer = markedAnswer.guid;

    if (question.value.type === AssessmentQuestionType.OPEN) {
      answer =
        (question.value.answer as AssessmentAnswer | undefined)?.textValue ??
        '';
    } else if (question.value.type === AssessmentQuestionType.RANGE) {
      if ((question.value.answer as AssessmentAnswer | undefined)?.numberValue)
        answer =
          (
            question.value.answer as AssessmentAnswer | undefined
          )?.numberValue?.toString() ?? '';
      else answer = '0';
    }

    localArrayInitValues.push({
      [question.value.guid]: answer,
    });
  });

  return Object.assign({}, ...localArrayInitValues) as AssessmentFormValues;
}

export function returnInitialValuesFromAssessmentForm(
  form?: IUserAssessments | IAssessmentTemplate
) {
  let localArrayInitValues: AssessmentFormValues[] = [];

  const questions = form?.questions.filter(
    (p) => p.type !== AssessmentQuestionType.HEADER
  );

  questions?.forEach((question) => {
    const markedAnswer = question.answers.find(
      (questionItem) => questionItem.is_selected
    );

    let answer = '';
    if (markedAnswer) answer = markedAnswer.guid;
    if (question.type === AssessmentQuestionType.OPEN) {
      answer = question.answer?.textValue ?? '';
    } else if (question.type === AssessmentQuestionType.RANGE) {
      if (question.answer?.numberValue)
        answer = question.answer?.numberValue.toString();
      else answer = '0';
    }

    localArrayInitValues.push({
      [question.guid]: answer,
    });
  });

  return Object.assign({}, ...localArrayInitValues) as AssessmentFormValues;
}

export function createFormObjectToSent(
  values: AssessmentFormValues,
  assessmentBody: (AssessmentQuestion | IConversationTemplatePlainTextItem)[],
  form:
    | IUserAssessments
    | IAssessmentTemplate
    | IFormConversationTemplate
    | null
) {
  let copyOfBody = [...assessmentBody];

  for (const key of Object.keys(values)) {
    const indexOfQuestion = copyOfBody.findIndex(
      (answer) => answer.guid === key
    );

    if (indexOfQuestion !== -1) {
      let element = copyOfBody[indexOfQuestion];

      if (element['Text'] !== undefined) continue;

      element = element as AssessmentQuestion;

      const answerIndex = element.answers.findIndex(
        (answer) => answer.guid === values[key]
      );

      const parsedNumber = parseInt(values[key]);

      if (
        [AssessmentQuestionType.OPEN, AssessmentQuestionType.EMAIL].includes(
          element.type
        )
      ) {
        copyOfBody[indexOfQuestion].answer = {
          textValue: values[key],
        };
      } else if (
        copyOfBody[indexOfQuestion].type === AssessmentQuestionType.RANGE &&
        !isNaN(parsedNumber)
      ) {
        copyOfBody = handleRangeQuestion(
          form as IUserAssessments | IAssessmentTemplate | null,
          copyOfBody,
          indexOfQuestion,
          parsedNumber
        );
      } else if (answerIndex !== -1) {
        const newAnswers = [...element.answers];

        newAnswers.forEach((answer, index) => {
          newAnswers[index] = {
            ...newAnswers[index],
            is_selected: index === answerIndex,
          };
        });

        copyOfBody[indexOfQuestion].answers = newAnswers;
      }
    }
  }

  return copyOfBody;
}

function handleRangeQuestion(
  form: IUserAssessments | IAssessmentTemplate | null,
  copyOfBody: any,
  indexOfQuestion: number,
  parsedNumber: number
) {
  if (form === null) {
    copyOfBody[indexOfQuestion].answer = {
      numberValue: parsedNumber,
    };
  } else {
    const verifyVisibility = verifyVisibleExpression(
      copyOfBody[indexOfQuestion].visible,
      form
    );

    copyOfBody[indexOfQuestion].answer = !verifyVisibility
      ? null
      : {
          numberValue: parsedNumber,
        };
  }

  return copyOfBody;
}

export const verifyQuestionsVisibility = (
  nextItem: IFormConversationItem,
  context: (AssessmentQuestion | IConversationTemplatePlainTextItem)[]
) => {
  const nextItemCondition = nextItem.condition;

  if (
    nextItemCondition === null ||
    nextItemCondition?.toLowerCase() === 'true' ||
    nextItemCondition === ''
  )
    return true;

  const subjectId = nextItemCondition.slice(
    nextItemCondition.indexOf('[') + 1,
    nextItemCondition.lastIndexOf(']')
  );

  const subject = context.find(
    (p) => p.guid === subjectId
  ) as AssessmentQuestion;

  if (!subject) return false;

  const subjectAnswer =
    subject.answer?.numberValue ??
    subject.answers.find((p) => p.is_selected)?.sentiment_score;

  if (!subjectAnswer) return false;

  const expressionSplit = splitByComparisons(nextItemCondition);
  const comparingValue = expressionSplit[expressionSplit.length - 1];
  const comparingValueNumber = parseFloat(comparingValue);

  if (isNaN(comparingValueNumber)) return false;

  if (nextItemCondition.includes('=='))
    return subjectAnswer === comparingValueNumber;

  if (nextItemCondition.includes('!='))
    return subjectAnswer !== comparingValueNumber;

  if (nextItemCondition.includes('>'))
    return subjectAnswer > comparingValueNumber;

  if (nextItemCondition.includes('>='))
    return subjectAnswer >= comparingValueNumber;

  if (nextItemCondition.includes('<'))
    return subjectAnswer < comparingValueNumber;

  if (nextItemCondition.includes('<='))
    return subjectAnswer <= comparingValueNumber;

  return false;
};

const splitByComparisons = (condition: string) => {
  const comparisons = ['==', '!=', '>=', '<=', '>', '<'];

  const tempChar = comparisons[0];

  for (let i = 1; i < comparisons.length; i++)
    condition = condition.split(comparisons[i]).join(tempChar);

  return condition.split(tempChar);
};

export function checkIfAnswersAreEmpty(values: AssessmentFormValues) {
  return Object.values(values).some((p) => p === '');
}

export function getAssessmentFormBasedOnIdentification(
  forms: IUserAssessments[],
  identification: AssessmentFormIdentification | null
) {
  let filteredForms = forms;

  if (identification?.selectedSequence) {
    filteredForms = filteredForms.filter(
      (p) => p.sequence === identification.selectedSequence
    );
  }

  if (identification?.contextObjectId) {
    filteredForms = filteredForms.filter(
      (p) => p.contextObjectId === identification.contextObjectId
    );
  }

  if (identification?.contextType) {
    filteredForms = filteredForms.filter(
      (p) => p.contextType === identification.contextType
    );
  }

  return filteredForms.length === 0 ? undefined : filteredForms[0];
}

export const removeSkippedItems = (
  answer: FormConversationAnswer,
  completedQuestions: (
    | AssessmentQuestion
    | IConversationTemplatePlainTextItem
  )[]
): FormConversationAnswer => {
  let items = answer.items;
  let returnedItems: IFormConversationItem[] = [];

  for (const item of items) {
    const isVisible = verifyQuestionsVisibility(item, completedQuestions);
    if (isVisible) returnedItems.push(cloneDeep(item));
  }

  return {
    ...answer,
    items: returnedItems,
  };
};

export const verifyVisibleExpression = (
  expression: string | null,
  form: IUserAssessments | IAssessmentTemplate | IFormConversationTemplate
) => {
  if (expression === null || expression === undefined) return true;

  if (expression.toString().toLowerCase() === 'true') return true;

  const expressionParts = expression.split('|');

  if (expressionParts.length !== 3) return false;

  const leftHandSideExpressionValue = evaluateObjectExpression(
    expressionParts[0],
    form
  );
  const rightHandSideExpressionValue = evaluateObjectExpression(
    expressionParts[2],
    form
  );

  return compareValuesWithStringOperator(
    leftHandSideExpressionValue,
    expressionParts[1],
    rightHandSideExpressionValue
  );
};

const evaluateObjectExpression: any = (
  expression: string,
  form: IUserAssessments
) => {
  if (!isNaN(parseInt(expression))) {
    return parseInt(expression);
  }

  if (!isNaN(Date.parse(expression))) {
    return Date.parse(expression);
  }

  if (!new RegExp(['.', '\\]', '\\['].join('|')).test(expression)) {
    return expression;
  }

  let evaluatingExpression = expression;
  let questions = form;

  if (evaluatingExpression.startsWith('form.')) {
    evaluatingExpression = evaluatingExpression.substring(5);
  }

  return _.get(questions, evaluatingExpression, null);
};

const compareValuesWithStringOperator = (
  lhs: any,
  comparer: string,
  rhs: any
) => {
  if (lhs === null || rhs === null) {
    return false;
  }

  if (typeof lhs !== typeof rhs) {
    return false;
  }

  switch (comparer) {
    case '<':
      return lhs < rhs;
    case '<=':
      return lhs <= rhs;
    case '>':
      return lhs > rhs;
    case '>=':
      return lhs >= rhs;
    case '==':
      return lhs === rhs;
    case '===':
      return lhs === rhs;
    default:
      return false;
  }
};

export const hourSort = (a: IUserAssessments, b: IUserAssessments) =>
  parse(a.hour, 'HH:mm', new Date()).getTime() -
  parse(b.hour, 'HH:mm', new Date()).getTime();
