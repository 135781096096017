import request from './utils/request';
import { IResponse, IResponseWithPagination } from '../interfaces/response';
import { normalizeQueryParams } from '../helpers/utils/normalizeQueryParams';
import {
  ExerciseSessionStatus,
  IBaseExerciseSession,
  ICreateExerciseSession,
} from '../interfaces/exerciseSessions';
import { getOdataFilterParams } from '../helpers/utils/getOdataParams';
import { IPatchObject, ODataParamType } from '../interfaces/request';
import { IParamsTypeInRequest } from '../interfaces/reducer';

export const getExerciseSessions = async (
  userId: string,
  from?: Date,
  to?: Date,
  status?: ExerciseSessionStatus
) => {
  let queryParams: Partial<IParamsTypeInRequest> = {};

  queryParams.page = 1;
  queryParams.page_size = 5000;

  let oDataParamTypes: ODataParamType[] = [];

  if (from) {
    oDataParamTypes.push({
      key: 'timeStart',
      operator: 'ge',
      value: from.toISOString(),
    });
  }

  if (to) {
    oDataParamTypes.push({
      key: 'timeEnd',
      operator: 'lt',
      value: to.toISOString(),
    });
  }

  if (status) {
    oDataParamTypes.push({
      key: 'status',
      operator: 'eq',
      value: status.toString(),
    });
  }

  const oDataFilterParams = getOdataFilterParams(oDataParamTypes, {
    startCharacter: '&',
  });

  return request<undefined, IResponseWithPagination<IBaseExerciseSession[]>>({
    url: `/users/${userId}/exercise_sessions${normalizeQueryParams(
      queryParams
    )}${oDataFilterParams}`,
    method: 'GET',
  });
};

export const createExerciseSession = (userId: string, caseId: string) =>
  request<ICreateExerciseSession, IResponse<IBaseExerciseSession>>({
    url: `/users/${userId}/exercise_sessions`,
    data: { case_id: caseId },
    method: 'POST',
  });

export const updateExerciseSession = (
  userId: string,
  sessionId: string,
  data: IPatchObject[]
) =>
  request<IPatchObject[], void>({
    url: `/users/${userId}/exercise_sessions/${sessionId}`,
    method: 'PATCH',
    data,
  });
