import React from 'react';
import ScheduleRow, { ScheduleRowProps } from '../ScheduleRow';
import ScheduleEmptyRow from '../ScheduleEmptyRow';

const ScheduleRowWrapper = ({ item, selectedDateString }: ScheduleRowProps) => {
  return (
    <>
      <ScheduleRow selectedDateString={selectedDateString} item={item} />
      <ScheduleEmptyRow />
    </>
  );
};

export default ScheduleRowWrapper;
