import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getConversationQuestions } from '../../conversations';
import { IQueryRequest } from 'interfaces/request';
import { OrganizationQueryParams } from 'interfaces/queryParams';

const useConversationQuestions = ({
  params,
  options,
}: IQueryRequest<OrganizationQueryParams>) => {
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getConversationQuestions(params.organizationId);
      return data.data;
    },
    queryKey: [QueryKeys.getConversationQuestions, params.organizationId],
  });
};

export default useConversationQuestions;
