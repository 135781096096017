import { patchSubmission } from 'api/submissions';
import { IPatchObject } from 'interfaces/request';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

type ModifySubmissionsCompletedInfoParams = {
  submissionId: string;
  completed: boolean;
};

function useModifySubmissionCompletedInfo(
  organizationId: string,
  onSuccess?: () => void
) {
  return useMutationWithResultNotification({
    asyncApiFunction: async ({
      submissionId,
      completed,
    }: ModifySubmissionsCompletedInfoParams) => {
      const patchObject: IPatchObject[] = [
        {
          op: 'replace',
          path: '/completed',
          value: completed,
        },
      ];

      await patchSubmission(organizationId, submissionId, patchObject);
    },
    onSuccess: () => {
      displaySuccessNotification('Projects.submissionStatusChangeSuccess');
      onSuccess && onSuccess();
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'Projects.submissionStatusChangeErrors'
      );
    },
    resetOnResult: true,
  });
}

export default useModifySubmissionCompletedInfo;
