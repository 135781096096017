import React from 'react';

export const HorizontalChartTick = (props: any) => {
  return (
    <text
      x={props.x}
      y={props.y}
      fill={props.fill}
      fontSize={props.fontSize ?? 12}
      textAnchor="middle"
    >
      {props.formatter
        ? props.formatter(props.payload?.value)
        : props.payload?.value}
    </text>
  );
};

export const VerticalChartTick = (props: any) => {
  return (
    <text
      x={props.x}
      y={props.y + 2}
      fontSize={props.fontSize ?? 12}
      fill={props.fill}
      textAnchor="end"
    >
      {props.formatter
        ? props.formatter(props.payload?.value)
        : props.payload?.value}
    </text>
  );
};
