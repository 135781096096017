import React, { useCallback } from 'react';
import { IEducationMaterialCategoryTreeItem } from '../../../../../../interfaces/educationMaterials';
import TreeFolder from '../../../../../../components/Tree/components/TreeFolder';
import { TreeElementActionDefinition } from '../../../../../../components/Tree/types/Tree';
import { useIntl } from 'react-intl';
import {
  faEdit,
  faLink,
  faPlus,
  faTrash,
} from '@fortawesome/pro-light-svg-icons';
import EducationMaterialTreeItem from './components/EducationMaterialTreeItem';
import { useCommonData } from '../../../../hooks/useCommonData';

type EducationMaterialCategoriesTreeProps = {
  item: IEducationMaterialCategoryTreeItem;
  handleEditCategory?: (id: string) => void;
  handleDeleteCategory?: (id: string) => Promise<void>;
  deleteCategoryLoading: boolean;
  handleAddItem?: (id: string) => void;
  handleLinkMaterial?: (id: string) => void;
};

const EducationMaterialCategoriesTree = ({
  item,
  handleEditCategory,
  handleDeleteCategory,
  deleteCategoryLoading,
  handleAddItem,
  handleLinkMaterial,
}: EducationMaterialCategoriesTreeProps) => {
  const intl = useIntl();

  const { educationMaterialCategoriesTree, educationMaterialCategories } =
    useCommonData();

  const onEditCategory = useCallback(() => {
    handleEditCategory?.(item.id);
  }, [handleEditCategory, item.id]);

  const onDeleteCategory = useCallback(async () => {
    await handleDeleteCategory?.(item.id);
  }, [handleDeleteCategory, item.id]);

  const onAddItem = useCallback(() => {
    handleAddItem?.(item.id);
  }, [handleAddItem, item.id]);

  const onLinkMaterial = useCallback(() => {
    handleLinkMaterial?.(item.id);
  }, [handleLinkMaterial, item.id]);

  const folderActions: TreeElementActionDefinition[] = [
    {
      name: intl.formatMessage({
        id: 'Education.EducationMaterialCategoriesTab.EducationMaterialCategoriesTree.editCategory',
      }),
      icon: faEdit,
      onExecute: onEditCategory,
    },
    {
      name: intl.formatMessage({
        id: 'Education.EducationMaterialCategoriesTab.EducationMaterialCategoriesTree.removeCategory',
      }),
      icon: faTrash,
      onAsyncExecute: onDeleteCategory,
      onAsyncLoading: deleteCategoryLoading,
    },
    {
      name: intl.formatMessage({
        id: 'Education.EducationMaterialCategoriesTab.EducationMaterialCategoriesTree.createMaterial',
      }),
      icon: faPlus,
      onExecute: onAddItem,
    },
    {
      name: intl.formatMessage({
        id: 'Education.EducationMaterialCategoriesTab.EducationMaterialCategoriesTree.attachMaterial',
      }),
      icon: faLink,
      onExecute: onLinkMaterial,
    },
  ];

  const renderMaterials = item.materials.map((material) => (
    <EducationMaterialTreeItem
      key={`${item.id}|${material.id}`}
      categoryId={item.id}
      material={material}
    />
  ));

  return (
    <TreeFolder
      name={<span>{item.name}</span>}
      isLoading={
        educationMaterialCategories.isRefetching ||
        educationMaterialCategoriesTree.isRefetching
      }
      actions={folderActions}
    >
      {item.categories.map((category) => (
        <EducationMaterialCategoriesTree
          key={category.id}
          item={category}
          handleEditCategory={handleEditCategory}
          handleDeleteCategory={handleDeleteCategory}
          deleteCategoryLoading={false}
          handleAddItem={handleAddItem}
          handleLinkMaterial={handleLinkMaterial}
        />
      ))}
      {renderMaterials}
    </TreeFolder>
  );
};

export default EducationMaterialCategoriesTree;
