import { IAsyncFormHookValues } from '../../../../../../../interfaces/forms';
import { IBaseEducationMaterialCategory } from '../../../../../../../interfaces/educationMaterials';
import { useCallback, useMemo } from 'react';
import useUpsertEducationMaterialCategory from '../../../../../../../api/mutations/educationMaterials/useUpsertEducationMaterialCategory';
import useRequiredParams from '../../../../../../../hooks/useRequiredParams';
import { useCommonData } from '../../../../../hooks/useCommonData';
import { InputOption } from '../../../../../../../interfaces/ui';
import { useInitQuery } from '../../../../../../../hooks/queries/useInitQuery';
import { buildMaterialTranslations } from '../../../../../helpers';
import { validateSubsetEmpty } from '../../../../../../../helpers/utils/validators';
import { useIntl } from 'react-intl';
import { CultureEntry } from '../../../../../../../interfaces/cultures';

export type EducationMaterialCategoryModalFormValues = {
  name: string;
  description: string;
  sequence: number;
  is_premium: boolean;
  icon_url: string;
  parent_category_id?: string;
  translations: CultureEntry<{ name: string; description: string }>[];
};

type EducationMaterialCategoryModalFormHookValues =
  IAsyncFormHookValues<EducationMaterialCategoryModalFormValues> & {
    submitState: {
      isError: boolean;
      isLoading: boolean;
      isSuccess: boolean;
    };
    options: {
      categories: InputOption[];
    };
  };

export const useEducationMaterialCategoryModal = (
  category: IBaseEducationMaterialCategory | undefined,
  parentCategoryId: string | undefined,
  toggle: () => void
): EducationMaterialCategoryModalFormHookValues => {
  const intl = useIntl();

  const { educationMaterialCategoriesTree, educationMaterialCategories } =
    useCommonData();

  useInitQuery(educationMaterialCategories);

  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const handleSuccess = async () => {
    toggle();
    await educationMaterialCategories.refetch();
    await educationMaterialCategoriesTree.refetch();
  };

  const { mutateAsync, isError, isLoading, isSuccess } =
    useUpsertEducationMaterialCategory({
      params: { organizationId },
      options: { successFb: handleSuccess },
    });

  const initialValues =
    useMemo((): EducationMaterialCategoryModalFormValues => {
      if (category) {
        return {
          name: category.name,
          description: category.description,
          icon_url: category.icon_url,
          parent_category_id: category.parent_category_id,
          translations: buildMaterialTranslations(
            category.name_translations,
            category.description_translations
          ),
          sequence: category.sequence,
          is_premium: category.is_premium,
        };
      }

      return {
        name: '',
        description: '',
        icon_url: '',
        parent_category_id: parentCategoryId,
        translations: [],
        sequence: 0,
        is_premium: false,
      };
    }, [category, parentCategoryId]);

  const validation = useCallback(
    (values: typeof initialValues) => {
      return validateSubsetEmpty<typeof initialValues>(
        values,
        ['name', 'description', 'icon_url', 'sequence'],
        intl
      );
    },
    [intl]
  );

  const handleSubmit = useCallback(
    async (values: EducationMaterialCategoryModalFormValues) => {
      await mutateAsync({
        id: category ? category.id : null,
        organization_id: organizationId,
        description: values.description,
        description_translations: values.translations.map((p) => ({
          culture: p.culture,
          value: p.value.description,
        })),
        name: values.name,
        name_translations: values.translations.map((p) => ({
          culture: p.culture,
          value: p.value.name,
        })),
        icon_url: values.icon_url,
        parent_category_id: values.parent_category_id ?? null,
        sequence: values.sequence,
        is_premium: values.is_premium,
      });
    },
    [category, mutateAsync, organizationId]
  );

  const categoriesOptions = useMemo((): InputOption[] => {
    let categories = educationMaterialCategories.data ?? [];

    if (category) categories = categories.filter((p) => p.id !== category.id);

    return categories.map((category) => ({
      value: category.id,
      text: category.name,
    }));
  }, [category, educationMaterialCategories.data]);

  return {
    initialValues: initialValues,
    validation: validation,
    handleSubmit: handleSubmit,
    submitState: {
      isError,
      isLoading,
      isSuccess,
    },
    options: {
      categories: categoriesOptions,
    },
  };
};
