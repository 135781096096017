import { useTypedContext } from '../../../../../../../../../../../../../../../../../../../../../../../hooks/useTypedContext';
import { ReactStateTuple } from '../../../../../../../../../../../../../../../../../../../../../../../interfaces/ui';
import { ValidationResult } from '../../../../../../../../../../../../../../../types';
import { ValidationContext } from '../../../../../../../../../../../types';
import { useCallback } from 'react';
import { cloneDeep } from 'lodash';
import { ValidConversationTemplateItemType } from '../../../../../../../../../../../../../../../../../../../../../../../interfaces/conversations';

export const useHandleApply = (id: string, onResolve: () => void) => {
  const [validation, setValidation] =
    useTypedContext<ReactStateTuple<ValidationResult>>(ValidationContext);

  return useCallback(() => {
    let validationCopy = cloneDeep(validation);

    validationCopy.template.content = validationCopy.template.content.map(
      (item) => {
        if (
          item.type !== ValidConversationTemplateItemType.Question ||
          item.value.guid !== id
        )
          return item;

        return {
          ...item,
          resource_id: id,
          value: {
            ...item.value,
            guid: id,
          },
        };
      }
    );

    validationCopy.ready_to_complete = false;
    setValidation(validationCopy);
    onResolve();
  }, [id, onResolve, setValidation, validation]);
};
