import { Dispatch, SetStateAction, useCallback } from 'react';
import { Expressions } from '../../../../../../../../../../../../../../../helpers/expressions';
import { ISelectableDashboardTemplateVisualization } from '../../../../../../../../../../../../../../../interfaces/dashboards';

export const useHandleSelectIndex = (
  visualization: ISelectableDashboardTemplateVisualization,
  setSelectedIndex: Dispatch<SetStateAction<number | undefined>>
) => {
  return useCallback(
    (item: any, index: number) => () => {
      setSelectedIndex(index);
      if (!visualization.on_click || !item) return;
      Expressions.Evaluate(visualization.on_click, { item });
    },
    [setSelectedIndex, visualization.on_click]
  );
};
