import React from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { BaseModalProps } from '../../../../interfaces/ui';
import { IEducationMaterial } from '../../../../interfaces/educationMaterials';
import { useTranslations } from '../../../../hooks/useTranslations';
import { FormattedMessage } from 'react-intl';
import CustomTable from '../../../../components/CustomTable';
import { useTable } from './hooks/useTable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import useOpen from '../../../../hooks/useOpen';
import MaterialModal from '../MaterialModal';

type EducationMaterialAssetsModalProps = BaseModalProps & {
  material: IEducationMaterial;
};

const EducationMaterialAssetsModal = ({
  isOpen,
  toggle,
  material,
}: EducationMaterialAssetsModalProps) => {
  const { attachPrefix } = useTranslations(
    'Education.EducationMaterialAssetsModal'
  );

  const { headerItems, bodyRows } = useTable(material);

  const { isOpen: isAddAssetModalOpen, toggle: toggleAddAssetModal } =
    useOpen();

  return (
    <>
      {isAddAssetModalOpen && (
        <MaterialModal
          isOpen={isAddAssetModalOpen}
          toggle={toggleAddAssetModal}
          material={material}
          initialStepIndex={2}
        />
      )}
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="GenericModal BorderlessModal Education__assets-modal AssetsModal"
        size="lg"
      >
        <ModalHeader toggle={toggle}>
          <div className="w-100 d-flex justify-content-between">
            <FormattedMessage
              id={attachPrefix('materialAssets')}
              values={{ title: material.name }}
            />
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex flex-column gap-2">
            <CustomTable headerItems={headerItems} bodyRows={bodyRows} />
            <Button
              block={true}
              color="primary"
              outline={true}
              onClick={toggleAddAssetModal}
            >
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EducationMaterialAssetsModal;
