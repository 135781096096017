import { IUserBasicInfo } from './membership';
import { ICase } from './cases';

export enum ExerciseSessionStatus {
  Pending = 'Pending',
  Closed = 'Closed',
}

export interface IBaseExerciseSession {
  id: string;
  user_id: string;
  user: IUserBasicInfo;
  case_id: string;
  case: ICase;
  time_start: string;
  time_end: string;
  status: ExerciseSessionStatus;
}

export interface ICreateExerciseSession {
  case_id: string;
}
