import { useEffect, useState } from 'react';
import { ISummarizedTreatmentPhase } from '../../../../../../../interfaces/conversations';
import { ManagedTreatmentPhase } from '../../../types';

export const useManagedTreatmentPhases = (
  treatmentPhases: ISummarizedTreatmentPhase[]
) => {
  const [managedTreatmentPhasesInit, setManagedTreatmentPhasesInit] =
    useState<boolean>(() => false);

  const [managedTreatmentPhases, setManagedTreatmentPhases] = useState<
    ManagedTreatmentPhase[]
  >(() =>
    treatmentPhases.map((phase) => ({
      isAdding: false,
      phase: phase,
    }))
  );

  useEffect(() => {
    setManagedTreatmentPhases(
      treatmentPhases.map((phase) => ({
        isAdding: false,
        phase: phase,
      }))
    );
  }, [treatmentPhases]);

  useEffect(() => {
    if (!managedTreatmentPhasesInit && managedTreatmentPhases.length > 0)
      setManagedTreatmentPhasesInit(true);
  }, [managedTreatmentPhases.length, managedTreatmentPhasesInit]);

  return {
    managedTreatmentPhases,
    setManagedTreatmentPhases,
    managedTreatmentPhasesInit,
  };
};
