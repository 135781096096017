import React from 'react';
import useDeleteOrganizationToken from 'api/mutations/membership/useDeleteOrganizationToken';
import { useNavigate, useParams } from 'react-router';
import { NavItem } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

const PersonalSpace = () => {
  const { organizationId } = useParams<{
    organizationId: string;
  }>();

  const navigate = useNavigate();

  const { mutate: deleteOrganizationTokenMutation } =
    useDeleteOrganizationToken();

  const onClick = (e) => {
    e.preventDefault();

    if (organizationId) deleteOrganizationTokenMutation(organizationId);
    else navigate('/dashboard');
  };

  return (
    <NavItem>
      <NavLink to="/dashboard" onClick={onClick}>
        <FormattedMessage id="Summary.ScreenTitle" />
      </NavLink>
    </NavItem>
  );
};

export default PersonalSpace;
