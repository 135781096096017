import React from 'react';
import useDashboardTemplateOrganizationsGrasps from '../../../../../../../../api/queries/dashboards/useDashboardTemplateOrganizationsGrasps';
import useDashboardTemplateOrganizationsMembers from '../../../../../../../../api/queries/dashboards/useDashboardTemplateOrganizationsMembers';
import { AwareSessionHelpers } from '../../../../../../../../helpers/data/awareSessions';
import { useOrganizationPathParams } from '../../../../../../../../hooks/paths/useOrganizationPathParams';
import { useInitQueries } from '../../../../../../../../hooks/queries/useInitQuery';
import { IAwareSessionDataMapping } from '../../../../../../../../interfaces/awareSessions';
import { IGrasp } from '../../../../../../../../interfaces/grasps';
import { IOrganizationMembers } from '../../../../../../../../interfaces/organizations';
import QueriesLoaded from '../../../../../../../QueriesLoaded';
import FullScreenSpinner from '../../../../../../../Spinners/FullScreenSpinner';
import { useCommonData } from '../../../../hooks/useCommonData';
import DashboardContent from '../../../DashboardContent';
import { DashboardContentContainerProps } from '../../types';

const DataMappingBasedContainer = ({
  template,
}: DashboardContentContainerProps) => {
  const { organizationId } = useOrganizationPathParams();

  const { awareSessionDataMapping } = useCommonData();

  const organizationMembers = useDashboardTemplateOrganizationsMembers({
    params: { organizationId, templateId: template.id },
    options: { enabled: false, staleTime: Infinity },
  });

  const organizationsGrasps = useDashboardTemplateOrganizationsGrasps({
    params: { organizationId, templateId: template.id },
    options: { enabled: false, staleTime: Infinity },
  });

  const queries = [
    awareSessionDataMapping,
    organizationMembers,
    organizationsGrasps,
  ];

  useInitQueries(queries);

  return (
    <QueriesLoaded onLoading={<FullScreenSpinner />} queries={queries}>
      {(data: any[]) => (
        <DashboardContent
          template={template}
          questions={AwareSessionHelpers.GetAggregatedQuestions(
            data[0] as IAwareSessionDataMapping,
            organizationId
          )}
          grasps={data[2] as IGrasp[]}
          organizationsMembers={data[1] as IOrganizationMembers[]}
        />
      )}
    </QueriesLoaded>
  );
};

export default DataMappingBasedContainer;
