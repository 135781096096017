import request from './utils/request';
import { IResponse } from '../interfaces/response';
import {
  ICreateOrganizationObservationMeaning,
  IOrganizationObservationMeaning,
} from '../interfaces/observationMeanings';
import { IPatchObject } from '../interfaces/request';

export const getOrganizationObservationMeanings = (organizationId: string) => {
  return request<undefined, IResponse<IOrganizationObservationMeaning[]>>({
    url: `/organizations/${organizationId}/observation_meanings`,
    method: 'GET',
  });
};

export const getUserCaseObservationMeanings = (
  userId: string,
  caseId: string
) => {
  return request<undefined, IResponse<IOrganizationObservationMeaning[]>>({
    url: `/users/${userId}/cases/${caseId}/observation_meanings`,
    method: 'GET',
  });
};

export const addOrganizationObservationMeaning = (
  organizationId: string,
  body: ICreateOrganizationObservationMeaning
) => {
  return request<
    ICreateOrganizationObservationMeaning,
    IResponse<IOrganizationObservationMeaning>
  >({
    url: `/organizations/${organizationId}/observation_meanings`,
    method: 'POST',
    data: body,
  });
};

export const patchOrganizationObservationMeaning = (
  organizationId: string,
  meaningId: string,
  data: IPatchObject[]
) =>
  request<IPatchObject[], void>({
    url: `/organizations/${organizationId}/observation_meanings/${meaningId}`,
    method: 'PATCH',
    data,
  });

export const deleteOrganizationObservationMeaning = (
  organizationId: string,
  meaningId: string
) => {
  return request({
    url: `/organizations/${organizationId}/observation_meanings/${meaningId}`,
    method: 'DELETE',
  });
};
