import React from 'react';
import { StepsContext } from '../../types';
import { FormattedMessage } from 'react-intl';
import { useTranslations } from '../../../../../../../../hooks/useTranslations';
import { faFileImport } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTypedContext } from '../../../../../../../../hooks/useTypedContext';

const Header = () => {
  const steps = useTypedContext(StepsContext);

  const { attachPrefix, wrapWithIntl } = useTranslations(
    'ConversationsAndFeedbacks.ConversationTemplates.ImportTemplateModal.Header'
  );

  return (
    <div className="d-flex align-items-center gap-2">
      <FontAwesomeIcon icon={faFileImport} />
      <FormattedMessage
        id={attachPrefix(steps.step)}
        values={{
          title: wrapWithIntl(attachPrefix('importTemplate')),
          sequence: steps.index + 1,
        }}
      />
    </div>
  );
};

export default Header;
