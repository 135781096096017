import React from 'react';
import { useNavigate } from 'react-router';
import InfiniteScroll from 'react-infinite-scroller';
import { Spinner } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

//types
import { GraspFilters } from 'interfaces/grasps';
import { Size } from 'interfaces/ui';

//helpers
import useInfiniteGraspList from 'api/queries/grasps/useInfiniteGraspList';

//helpers
import { formatDate } from 'helpers/dates';

//components
import CustomTable from 'components/CustomTable';
import GraspLogo from 'components/GraspLogo';
import useRequiredParams from 'hooks/useRequiredParams';
import GraspListPlaceholder from './GraspListPlaceholder';

const headerItems = [
  null,
  'OrganizationGrasps.deviceName',
  'OrganizationGrasps.status',
  'OrganizationGrasps.prescribedTo',
  'OrganizationGrasps.prescribedDate',
  'OrganizationGrasps.createdAt',
  'OrganizationGrasps.batteryLevel',
  'OrganizationGrasps.lastBatterySync',
];

type GraspListProps = {
  searchValue?: string;
  displayPlaceholder?: boolean;
  filters?: GraspFilters;
};

const GraspList = ({
  searchValue,
  displayPlaceholder = false,
  filters,
}: GraspListProps) => {
  const navigate = useNavigate();
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const { grasps, fetchNextPage, isLoading, hasNextPage } =
    useInfiniteGraspList({
      params: {
        organizationId,
        searchValue,
        pageSize: 25,
        filters,
      },
    });

  const onRowClick = (id: string) => {
    navigate(`/organizations/${organizationId}/grasps/${id}`);
  };

  const renderedGraspList = grasps?.map((graspData) => {
    const {
      id,
      hex_color,
      name,
      patient,
      battery_level,
      battery_last_synchronization,
      battery_warning_level,
      created_at,
      status,
      grasp_id,
    } = graspData;

    return (
      <tr
        key={id}
        onClick={() => onRowClick(grasp_id)}
        className="cursor-pointer"
      >
        <td>
          <GraspLogo size={Size.SMALL} color={hex_color} width={45} />
        </td>
        <td>
          <b>{name || '--'}</b>
        </td>
        <td>
          <FormattedMessage id={`MyOrganizations.grasps.statuses.${status}`} />
        </td>
        <td>
          {' '}
          {patient ? `${patient.first_name} ${patient.last_name}` : '--'}
        </td>
        <td>
          {patient?.prescription_date
            ? formatDate(patient.prescription_date)
            : '--'}
        </td>
        <td>{created_at ? formatDate(created_at) : '--'}</td>
        <td
          className={classNames({
            'row-warning-color':
              battery_last_synchronization &&
              battery_level &&
              battery_warning_level &&
              battery_level < battery_warning_level,
          })}
        >
          {battery_level || '--'}
        </td>
        <td>
          {battery_last_synchronization
            ? formatDate(battery_last_synchronization.toString())
            : '--'}
        </td>
      </tr>
    );
  });

  if (isLoading) return <GraspListPlaceholder />;

  const hasMore = !isLoading && hasNextPage;

  const isPlaceholderDisplayed = displayPlaceholder && grasps?.length === 0;

  const loadMore = () => fetchNextPage();

  return (
    <>
      {isPlaceholderDisplayed ? (
        <div>
          <FormattedMessage id="General.nothingToDisplay" />
        </div>
      ) : (
        <InfiniteScroll
          loadMore={loadMore}
          hasMore={hasMore}
          loader={<Spinner key="spinner" />}
          threshold={100}
          initialLoad={false}
        >
          <CustomTable
            headerItems={headerItems}
            bodyRows={renderedGraspList}
            responsiveConfig={[
              {
                breakpoint: 1400,
                hiddenColumns: [2, 4, 5],
              },
              {
                breakpoint: 576,
                hiddenColumns: [2, 4, 5, 6, 7],
              },
            ]}
          />
        </InfiniteScroll>
      )}
    </>
  );
};

export default GraspList;
