import React from 'react';
import { faPlusSquare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslations } from '../../../../../../../../../../../../../../../../../../../../../../hooks/useTranslations';
import { Button, Tooltip } from 'reactstrap';
import useOpen from '../../../../../../../../../../../../../../../../../../../../../../hooks/useOpen';
import { FormattedMessage } from 'react-intl';
import { useHandleApply } from './hooks/useHandleApply';

type QuestionFromScratchActionProps = {
  id: string;
  onResolve: () => void;
};

const QuestionFromScratchAction = ({
  id,
  onResolve,
}: QuestionFromScratchActionProps) => {
  const { isOpen, toggle } = useOpen();

  const { attachPrefix } = useTranslations(
    'ConversationsAndFeedbacks.ConversationTemplates.ImportTemplateModal.Content.ConflictsStep.Validation.Response.Conflicts.QuestionActions.QuestionFromScratchAction'
  );

  const handleApply = useHandleApply(id, onResolve);

  return (
    <>
      <Button
        id={`question-from-scratch-${id}`}
        color="primary"
        outline={true}
        onClick={handleApply}
      >
        <FontAwesomeIcon icon={faPlusSquare} />
      </Button>
      <Tooltip
        placement="top"
        isOpen={isOpen}
        target={`question-from-scratch-${id}`}
        toggle={toggle}
      >
        <FormattedMessage id={attachPrefix('fromScratch')} />
      </Tooltip>
    </>
  );
};

export default QuestionFromScratchAction;
