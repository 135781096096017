import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import { AVAILABLE_CULTURES } from '../../../../../components/CultureSelector/CultureSelector';
import { translateHtmlStr } from '../../../../../helpers/utils/cultures';

type QualificationResultProps = {
  description: string | null;
  isQualified: boolean;
  goToRegistration?: () => void;
  selectedCulture: AVAILABLE_CULTURES;
};

const QualificationResult = ({
  description,
  isQualified,
  goToRegistration,
  selectedCulture,
}: QualificationResultProps) => {
  const onButtonClick = () => {
    goToRegistration && goToRegistration();
  };

  const submissionMessage = translateHtmlStr(description, selectedCulture);

  return (
    <div className="JoinConsent">
      <div
        className="text-center"
        dangerouslySetInnerHTML={{
          __html: submissionMessage,
        }}
      />
      {isQualified && (
        <div className="w-100 d-flex justify-content-center mt-4">
          <Button color="primary" onClick={onButtonClick}>
            <FormattedMessage id="ProjectSubmissions.JoinConsent.registerAccount" />
          </Button>
        </div>
      )}
    </div>
  );
};

export default QualificationResult;
