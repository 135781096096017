import { useBrowserState } from 'store/reducerHooks';
import Breadcrumbs, { BreadcrumbItemType } from './Breadcrumbs';
import classNames from 'classnames';

type PageHeaderProps = {
  isLoading?: boolean;
  breadcrumbs?: BreadcrumbItemType[];
  includeHome?: boolean;
  content?: JSX.Element;
  className?: string;
};

const PageHeader = ({
  breadcrumbs = [],
  content,
  includeHome = true,
  isLoading = false,
  className = undefined,
}: PageHeaderProps) => {
  const isBrowser = useBrowserState();

  return (
    <div className={classNames(className)}>
      {isBrowser && (
        <Breadcrumbs
          items={breadcrumbs}
          includeHome={includeHome}
          isLoading={isLoading}
        />
      )}
      {content}
    </div>
  );
};

export default PageHeader;
