import React from 'react';
import { strToDateOnly } from '../../../../helpers/dates';
import { ScheduleItemDefinition } from '../../index';
import { v4 as uuid } from 'uuid';
import ScheduleHours from '../ScheduleHours';
import ScheduleRow from '../ScheduleRow';
import ScheduleEmptyRow from '../ScheduleEmptyRow';
import { ConversationScheduleType } from '../../../../interfaces/conversationSchedules';
import { FormattedMessage } from 'react-intl';

const ScheduleEmpty = () => {
  const selectedDateString = strToDateOnly(new Date().toISOString());

  const item: ScheduleItemDefinition = {
    rowId: uuid(),
    isLoading: false,
    label: ' ',
    scheduleTemplate: {
      schedule_type: ConversationScheduleType.NonTimeBased,
      start_day_offset: null,
      specific_day_of_phase: null,
      recurring_interval: null,
      end_day_offset: null,
      parts_of_a_day: [],
    },
    sequence: 0,
    templateId: '',
  };

  return (
    <div className="d-flex flex-column pt-5">
      <table className="Schedule">
        <ScheduleHours />
        <tbody className="Schedule__body">
          <ScheduleRow selectedDateString={selectedDateString} item={item} />
          <ScheduleEmptyRow />
          <ScheduleRow selectedDateString={selectedDateString} item={item} />
          <ScheduleEmptyRow />
          <ScheduleRow selectedDateString={selectedDateString} item={item} />
          <ScheduleEmptyRow />
          <tr className="Schedule__overlay" style={{ height: 350 }}>
            <td
              style={{ height: '100%' }}
              className="d-flex flex-column w-100 align-items-center justify-content-center"
            >
              <div
                className="w-100 d-flex align-items-center justify-content-center"
                style={{
                  zIndex: 1,
                }}
              >
                <div className="ChangeCase__chart-placeholder__bubble" />
              </div>
              <div className="ChangeCase__chart-placeholder__text">
                <FormattedMessage id="CustomTable.defaultTranslation" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ScheduleEmpty;
