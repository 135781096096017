import React from 'react';
import TabItem from '../../modules/CaseDashboard/components/CaseDashboardTabs/components/TabItem';
import ExerciseTimeProgress from './components/ExerciseTimeProgress';
import useBreakpoint from '../../hooks/useBreakpoint';
import { useTranslations } from '../../hooks/useTranslations';
import { UseQueryResult } from 'react-query';
import { ICaseDetails } from '../../interfaces/cases';

type ExerciseTimeProps = {
  date: Date;
  caseDetails: UseQueryResult<ICaseDetails>;
};

const ExerciseTimeChart = ({ date, caseDetails }: ExerciseTimeProps) => {
  const isMobile = useBreakpoint(992);

  const { attachPrefix, wrapWithIntl } = useTranslations(
    'CaseDashboard.CaseDashboardTabs.DayDetailsTab.ExerciseTime'
  );

  return (
    <TabItem
      title={wrapWithIntl(attachPrefix('exercises'))}
      classNames={!isMobile ? 'h-100' : ''}
    >
      <ExerciseTimeProgress date={date} caseDetails={caseDetails} />
    </TabItem>
  );
};

export default ExerciseTimeChart;
