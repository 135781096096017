import transformDataIntoBubbles from 'helpers/bubbleChart/transformDataIntoBubbles';
import { IAssessmentPeriodInfo } from 'interfaces/assessmentForms';
import { ICaseHistory } from 'interfaces/caseHistory';
import {
  IObservation,
  ITimelineAnnotation,
  SingleDataBubbleValue,
} from 'interfaces/timeline';
import { useEffect, useState } from 'react';

function useTimelinesDayByDay(
  dateRange: {
    from: Date;
    to: Date;
  },
  observations: IObservation[] | undefined,
  annotations: ITimelineAnnotation[] | undefined,
  history: ICaseHistory[] | undefined,
  formsInfo: IAssessmentPeriodInfo[] | undefined,
  loggedUserId: string
) {
  const [bubbleData, setBubbleData] = useState<SingleDataBubbleValue[]>([]);

  useEffect(() => {
    const newBubbleData = transformDataIntoBubbles(
      dateRange,
      observations,
      annotations,
      history,
      formsInfo,
      loggedUserId
    );

    setBubbleData(newBubbleData);
  }, [
    dateRange,
    observations,
    annotations,
    history,
    setBubbleData,
    formsInfo,
    loggedUserId,
  ]);

  return bubbleData;
}

export default useTimelinesDayByDay;
