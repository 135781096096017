import { useMemo } from 'react';
import useExerciseSessions from '../../../../../api/queries/exerciseSessions/useExerciseSessions';
import { CasesHelpers } from '../../../../../helpers/data/cases';
import { getDateRangeForDate } from '../../../../../helpers/dates';
import { useInitQuery } from '../../../../../hooks/queries/useInitQuery';
import { StrictDateRange } from '../../../../../interfaces/dates';
import { IObservation } from '../../../../../interfaces/timeline';
import { AllSqueezesMode, AllSqueezesQueries } from '../../../types';

export const useObservationData = (
  observations: IObservation[],
  date: Date,
  mode: AllSqueezesMode,
  queries: AllSqueezesQueries
) => {
  const dateRange = getDateRangeForDate(date);

  useInitQuery(queries.caseDetails);

  const userId =
    queries.caseDetails.data !== undefined
      ? CasesHelpers.GetUserIdBasedOnCase(queries.caseDetails.data)
      : null;

  const exercisesQuery = useExerciseSessions({
    params: { userId: userId ?? '', from: dateRange.from, to: dateRange.to },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(
    exercisesQuery,
    mode === AllSqueezesMode.ExercisesOnly && !!userId
  );

  const observationIsInRange = (
    observation: IObservation,
    exercisePeriods: StrictDateRange[]
  ) => {
    const timestampDate = new Date(observation.timestamp);
    return exercisePeriods.some(
      (p) => timestampDate >= p.from && timestampDate <= p.to
    );
  };

  return useMemo((): IObservation[] => {
    if (mode === AllSqueezesMode.AllIncludingExercises) return observations;

    if (!exercisesQuery.data) return [];

    const exercises = exercisesQuery.data.filter(
      (p) =>
        new Date(p.time_start).toLocaleDateString() ===
        date.toLocaleDateString()
    );

    const exercisesPeriods: StrictDateRange[] = exercises.map((exercise) => ({
      from: new Date(exercise.time_start),
      to: new Date(exercise.time_end),
    }));

    let exerciseObservations: IObservation[] = [];

    observations.forEach((observation) => {
      if (!observationIsInRange(observation, exercisesPeriods)) return;
      exerciseObservations.push(observation);
    });

    return exerciseObservations;
  }, [date, exercisesQuery.data, mode, observations]);
};
