import React from 'react';
import { HourByHourMode } from '../../index';
import { ReactState } from '../../../../interfaces/ui';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faDumbbell } from '@fortawesome/pro-light-svg-icons';
import MultiStateButton from '../../../MultiStateButton';

type HourByHourSwitchProps = {
  mode: ReactState<HourByHourMode>;
};

const HourByHourSwitch = ({ mode }: HourByHourSwitchProps) => {
  const toggle = () =>
    mode.setItem((prev) =>
      prev === HourByHourMode.AllIncludingExercises
        ? HourByHourMode.ExercisesOnly
        : HourByHourMode.AllIncludingExercises
    );

  return (
    <MultiStateButton
      items={[
        {
          key: HourByHourMode.AllIncludingExercises,
          icon: <FontAwesomeIcon icon={faChartBar} />,
          onClick: toggle,
        },
        {
          key: HourByHourMode.ExercisesOnly,
          icon: <FontAwesomeIcon icon={faDumbbell} />,
          onClick: toggle,
        },
      ]}
    />
  );
};

export default HourByHourSwitch;
