import React, { useMemo } from 'react';
import useCultures, {
  AvailableCulture,
} from '../../hooks/cultures/useCultures';
import ReactFlagsSelect from 'react-flags-select';
import { useIntl } from 'react-intl';
import EventPropagationConstraint from '../EventPropagationConstraint';

type FlagLanguageSelectorProps = {
  selectedCulture?: AvailableCulture;
  setSelectedCulture?: (culture: AvailableCulture) => void;
  placeholder?: string;
  disabled?: boolean;
  searchable?: boolean;
};

const FlagCultureSelector = ({
  selectedCulture,
  setSelectedCulture,
  placeholder,
  disabled = false,
  searchable = false,
}: FlagLanguageSelectorProps) => {
  const intl = useIntl();

  const { flagLabels, flagCodes, cultures, currentCulture } = useCultures();

  const culturePlaceholder =
    placeholder ??
    intl.formatMessage({
      id: 'CultureSelector.FlagCultureSelector.defaultPlaceholder',
    });

  const selected = useMemo(() => {
    const availableCulture = cultures.find(
      (p) => p.culture === selectedCulture?.culture
    );

    if (availableCulture === undefined) return currentCulture.upperCountryCode;

    return availableCulture.upperCountryCode;
  }, [cultures, currentCulture.upperCountryCode, selectedCulture?.culture]);

  const onSelect = (countryCode: string) => {
    const availableCulture = cultures.find(
      (p) => p.upperCountryCode === countryCode
    );

    if (availableCulture === undefined) return;

    if (setSelectedCulture) setSelectedCulture(availableCulture);
  };

  return (
    <div className="FlagCultureSelector w-100">
      <EventPropagationConstraint>
        <ReactFlagsSelect
          placeholder={culturePlaceholder}
          disabled={disabled}
          searchable={searchable}
          fullWidth={true}
          className="react-flag-select"
          countries={flagCodes}
          customLabels={flagLabels}
          selected={selected}
          onSelect={onSelect}
        />
      </EventPropagationConstraint>
    </div>
  );
};

export default FlagCultureSelector;
