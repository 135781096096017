import {
  add,
  addSeconds,
  differenceInCalendarDays,
  differenceInDays,
  differenceInSeconds,
  formatDuration,
  intervalToDuration,
  sub,
} from 'date-fns';
import { DateRange } from 'react-day-picker';
import { StrictDateRange } from '../interfaces/dates';

export namespace DatesHelpers {
  const _minDateIsoString = '0001-01-01T00:00:00Z';
  const _maxDateIsoString = '9999-12-31T23:59:59Z';

  export const MinDate = new Date(_minDateIsoString);
  export const MaxDate = new Date(_maxDateIsoString);
}

export const padDate = (number: number) => {
  return number.toString().padStart(2, '0');
};

export function isSameDate(startDate: Date, beginDate: Date) {
  return startDate.getTime() === beginDate.getTime();
}

export const formatTime = (date: Date, addSeconds: boolean = false) => {
  let timeString = `${date.getHours().toString().padStart(2, '0')}:${date
    .getMinutes()
    .toString()
    .padStart(2, '0')}`;
  if (addSeconds)
    timeString += `:${date.getSeconds().toString().padStart(2, '0')}`;

  return timeString;
};

type FormatDateOptions = {
  ignoreTime?: boolean;
  addSeconds?: boolean;
  daysLeadingZeros?: boolean;
};

export const formatDate = (date: string, options?: FormatDateOptions) => {
  const dateObject = new Date(date);

  const {
    ignoreTime = false,
    addSeconds = false,
    daysLeadingZeros = false,
  } = options ?? {};

  let day = daysLeadingZeros
    ? dateObject.getDate().toString().padStart(2, '0')
    : dateObject.getDate();

  let dateString = `${day}.${padDate(
    dateObject.getMonth() + 1
  )}.${dateObject.getFullYear()}`;

  if (!ignoreTime) dateString += `, ${formatTime(dateObject)}`;
  if (addSeconds)
    dateString += `:${dateObject.getSeconds().toString().padStart(2, '0')}`;

  return dateString;
};

export const formatDateMonth = (date: string) => {
  const dateObject = new Date(date);

  return `${dateObject.getDate()}.${padDate(dateObject.getMonth() + 1)}`;
};

export const formattedDateToDateInput = (date: string) => {
  const dateSplitted = date.split('.');
  if (dateSplitted.length !== 3) {
    return '';
  }

  return `${dateSplitted[2]}-${dateSplitted[1]}-${('0' + dateSplitted[0]).slice(
    -2
  )}`;
};

export const formatDateToInputDateString = (date: Date) => {
  const month = date.getMonth() + 1;

  return `${date.getFullYear()}-${month.toString().padStart(2, '0')}-${date
    .getDate()
    .toString()
    .padStart(2, '0')}`;
};

export const getTodayDateRange = () => {
  const todayDate = new Date();
  todayDate.setHours(0, 0, 0);
  const tomorrowDate = new Date(
    todayDate.getFullYear(),
    todayDate.getMonth(),
    todayDate.getDate(),
    23,
    59,
    59
  );

  return {
    from: todayDate,
    to: tomorrowDate,
  } as DateRange;
};

const MAXIMUM_DATE_DIFFERENCE = 30;
export const formatDateToLast30days = (
  date:
    | {
        from: Date;
        to: Date;
      }
    | DateRange
    | null
) => {
  if (date?.from && date.to) {
    const diff = differenceInDays(date.to, date.from);
    if (diff > MAXIMUM_DATE_DIFFERENCE) {
      const newFromDate = sub(date.to, { days: MAXIMUM_DATE_DIFFERENCE });
      return {
        from: newFromDate,
        to: date.to,
      };
    } else return date;
  } else return date;
};

const SECONDS_IN_MINUTES = 60;
const SECONDS_IN_HOUR = 3600;

export function returnIntervalDatesArray(
  startDate: number,
  endDate: number,
  intervalInSeconds: number
) {
  const secondsDifference = differenceInSeconds(endDate, startDate);
  const numberOfTicks = secondsDifference / intervalInSeconds;

  const timestampArray: number[] = [];
  for (let i = 0; i <= numberOfTicks; i++) {
    timestampArray.push(
      add(new Date(startDate), {
        seconds: intervalInSeconds * i,
      }).getTime()
    );
  }

  return timestampArray;
}

const returnTimestampWithoutMinutes = (date: number) => {
  const newDate = new Date(date);
  newDate.setMinutes(0, 0, 0);

  return newDate.getTime();
};

export const returnIntervalBaseOnDates = (start: number, end: number) => {
  let secondsInterval = 1;

  const { hours, minutes, seconds, days } = intervalToDuration({
    end,
    start,
  });

  if (days) {
    secondsInterval = 2 * SECONDS_IN_HOUR;
  } else if (hours) {
    secondsInterval = returnIntervalBasedOnHours(hours);
  } else if (minutes) {
    secondsInterval = returnIntervalBasedOnMinutes(minutes);
  } else if (seconds) {
    secondsInterval = returnIntervalBasedOnSeconds(seconds);
  }

  return secondsInterval;
};

const returnIntervalBasedOnHours = (hours: number) => {
  let secondsInterval: number = 1;

  if (hours >= 12) secondsInterval = 2 * SECONDS_IN_HOUR;
  else if (hours >= 6) secondsInterval = SECONDS_IN_HOUR;
  else if (hours >= 3) {
    secondsInterval = 30 * SECONDS_IN_MINUTES;
  } else if (hours >= 2) secondsInterval = 15 * SECONDS_IN_MINUTES;
  else if (hours >= 1) secondsInterval = 10 * SECONDS_IN_MINUTES;

  return secondsInterval;
};

const returnIntervalBasedOnMinutes = (minutes: number) => {
  let secondsInterval: number = 1;

  if (minutes >= 30) secondsInterval = 5 * SECONDS_IN_MINUTES;
  else if (minutes >= 10) secondsInterval = 2 * SECONDS_IN_MINUTES;
  else if (minutes >= 5) secondsInterval = SECONDS_IN_MINUTES;
  else if (minutes >= 2) secondsInterval = 30;
  else if (minutes >= 1) secondsInterval = 10;

  return secondsInterval;
};

const returnIntervalBasedOnSeconds = (seconds: number) => {
  let secondsInterval: number = 1;

  if (seconds >= 30) secondsInterval = 5;
  else if (seconds >= 10) secondsInterval = 2;

  return secondsInterval;
};

const MAXIMUM_MS_VALUE = 86395000;
export const returnFormattedHourSelection = (start: number, end: number) => {
  let modifiedStart = start;
  let modifiedEnd = end;

  if (differenceInSeconds(end, start) <= 5) {
    modifiedEnd = add(start, {
      seconds: 5,
    }).getTime();
  }

  if (MAXIMUM_MS_VALUE < start) {
    modifiedStart = MAXIMUM_MS_VALUE;
  }

  const secondsInterval = returnIntervalBaseOnDates(modifiedStart, modifiedEnd);

  const startRange = returnTimestampWithoutMinutes(modifiedStart);
  const endRange = returnTimestampWithoutMinutes(
    add(new Date(end), { hours: 1 }).getTime()
  );

  const intervalDates = returnIntervalDatesArray(
    startRange,
    endRange,
    secondsInterval
  );

  const biggerStartIndex = intervalDates.findIndex(
    (timestampVal) => timestampVal > modifiedStart
  );
  const biggerEndIndex = intervalDates.findIndex(
    (timestampVal) => timestampVal > modifiedEnd
  );

  return {
    start: intervalDates[biggerStartIndex - 1],
    end: intervalDates[
      biggerEndIndex !== -1 ? biggerEndIndex - 1 : intervalDates.length - 1
    ],
  };
};

export const getISOStringUTCNonAware = (date: Date) => {
  return new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  ).toISOString();
};

export const alignDateToClosestForSecondsGranularity = (
  secondsGranularity: number,
  time: number,
  addExtraInterval: boolean = false
) => {
  const providedDate = new Date();
  providedDate.setTime(time);

  let currentDate = new Date();
  currentDate.setTime(time);
  currentDate.setHours(0, 0, 0, 0);

  while (addSeconds(currentDate, secondsGranularity) < providedDate) {
    currentDate = addSeconds(currentDate, secondsGranularity);
  }

  if (addExtraInterval)
    currentDate = addSeconds(currentDate, secondsGranularity);

  return currentDate;
};

export const getDifferenceOfTimestamps = (timestamps: number[]) => {
  const minDate = Math.min(...timestamps);
  const maxDate = Math.max(...timestamps);
  return differenceInCalendarDays(maxDate, minDate);
};

export const getDateRangeForDate = (date: Date) => {
  const from = new Date(date.toISOString());
  from.setHours(0, 0, 0, 0);

  const to = new Date(date.toISOString());
  to.setHours(23, 59, 59);

  return {
    from,
    to,
  };
};

export const strToDateOnly = (date: string) => {
  if (!date.includes('T')) return date;
  return date.slice(0, date.indexOf('T'));
};

export const toDateOnly = (date: Date) => {
  let str = date.toISOString();
  return strToDateOnly(str);
};

export const isIsoDate = (str: string) => {
  if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(str)) return false;
  const d = new Date(str);
  return !isNaN(d.getTime()) && d.toISOString() === str;
};

export const getChartIntervalBasedOnDateRange = (
  range: StrictDateRange,
  ticksFactor: number = 1,
  daysDiffFactor: number = 15
): number | 'preserveStartEnd' | 'preserveStart' | 'preserveEnd' => {
  const daysDiff = differenceInDays(range.to, range.from);

  if (daysDiff < daysDiffFactor) return 0;
  if (daysDiff < daysDiffFactor * 2) return ticksFactor;
  if (daysDiff < daysDiffFactor * 3) return 2 * ticksFactor;
  if (daysDiff < daysDiffFactor * 4) return 3 * ticksFactor;
  if (daysDiff < daysDiffFactor * 6) return 4 * ticksFactor;
  if (daysDiff < daysDiffFactor * 15) return 6 * ticksFactor;

  return 'preserveStartEnd';
};

export const secondsToDurationString = (seconds: number) => {
  const secondsInt = parseInt(String(seconds));
  const duration = intervalToDuration({ start: 0, end: secondsInt * 1000 });
  const zeroPad = (num: number) => String(num).padStart(2, '0');

  return formatDuration(duration, {
    format: ['hours', 'minutes', 'seconds'],
    zero: true,
    delimiter: ':',
    locale: {
      formatDistance: (_token, count) => zeroPad(count),
    },
  });
};
