import {
  withLoadingReducer,
  withLoadingStateActions,
} from 'helpers/HOR/withLoadingReducer';

const MODULE_NAME = 'RESEND_EMAIL';

export const resendEmailStateActions = withLoadingStateActions(MODULE_NAME);
const resendEmailReducer = withLoadingReducer(MODULE_NAME);

export default resendEmailReducer;
