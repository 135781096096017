import React from 'react';
import { IErrorDefinition } from '../../../../../../../../../../../../../../interfaces/response';
import { useTranslations } from '../../../../../../../../../../../../../../hooks/useTranslations';
import ErrorAlert from './components/ErrorAlert';
import { useTypedContext } from '../../../../../../../../../../../../../../hooks/useTypedContext';
import { HandleUploadContext } from '../../../../../../../../types';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt, faUpload } from '@fortawesome/pro-light-svg-icons';
import { wrapWithSequenceIdentified } from '../../../../../../../../../../../../../../helpers/utils/data';

type ErrorsProps = {
  error: string | IErrorDefinition[];
};

const Errors = ({ error }: ErrorsProps) => {
  const handleUpload = useTypedContext(HandleUploadContext);

  const { attachPrefix, wrapWithIntl } = useTranslations(
    'ConversationsAndFeedbacks.ConversationTemplates.ImportTemplateModal.Content.Validation.Errors'
  );

  const renderErrorAlert = () => {
    if (typeof error === 'string') {
      return (
        <ErrorAlert
          title={wrapWithIntl(attachPrefix('validationError'))}
          description={error}
        />
      );
    }

    return (
      <>
        {wrapWithSequenceIdentified(error).map((errorDefinition) => (
          <ErrorAlert
            key={`${errorDefinition.code}|${errorDefinition.sequence}`}
            title={
              errorDefinition.reasons.length > 1
                ? errorDefinition.reasons[0]
                : errorDefinition.code
            }
            description={
              errorDefinition.reasons.length > 1
                ? errorDefinition.reasons[errorDefinition.reasons.length - 1]
                : errorDefinition.reasons.join(', ')
            }
          />
        ))}
      </>
    );
  };

  return (
    <div className="d-flex flex-column gap-2">
      {renderErrorAlert()}
      <Button
        color="primary"
        outline={true}
        block={true}
        onClick={handleUpload.resetUpload}
      >
        <FontAwesomeIcon icon={faUpload} className="me-2" />
        <FontAwesomeIcon icon={faSyncAlt} />
      </Button>
    </div>
  );
};

export default Errors;
