import React from 'react';
import CaseGroupDetailsBreadcrumbs from './components/CaseGroupDetailsBreadcrumbs';
import ThinHeader from '../../../../components/ThinHeader';
import { useCaseGroupDetailsTabs } from './hooks/useCaseGroupDetailsTabs';
import { useCaseGroupDetailsThinHeader } from './hooks/useCaseGroupDetailsThinHeader';
import TabContainer from '../../../../components/Tabs/components/TabContainer';

const CaseGroupDetails = () => {
  const { tabItems, onTabChange } = useCaseGroupDetailsTabs();
  const { items: headerItems, actions: headerActions } =
    useCaseGroupDetailsThinHeader();

  return (
    <>
      <CaseGroupDetailsBreadcrumbs />
      <div className="mt-4">
        <ThinHeader items={headerItems} actions={headerActions} />
      </div>
      <div className="mt-4">
        <TabContainer items={tabItems} onTabChange={onTabChange} />
      </div>
    </>
  );
};

export default CaseGroupDetails;
