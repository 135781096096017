import { ConversationQuestion } from 'interfaces/conversations';
import { IBaseOrganization } from 'interfaces/organizations';
import { BasePersonality } from 'interfaces/personalities';
import { createContext } from 'react';
import { IGrasp } from '../../../../../../interfaces/grasps';
import { DashboardDataActions } from './components/DashboardDataContent/store/actions';
import { DashboardDataStates } from './components/DashboardDataContent/store/state';

export interface IMemberInOrganization {
  memberId: string;
  organizationId: string;
}

export interface IMemberRelated {
  member_id: string;
}

export interface IGraspRelated {
  grasp_id: string;
}

export interface IOrganizationRelated {
  organization_id: string;
}

export interface TDashboardFilterOption {
  options: { id: string; selected: boolean }[];
}

export interface TDashboardTemplateOption {
  selected_all: boolean;
  included: string[];
  excluded: string[];
}

export enum DashboardTheme {
  Dark = 'Dark',
  Light = 'Light',
}

export type DashboardSelectableOption = {
  id: string;
  selected: boolean;
};

export type DashboardQueriesContextType = {
  questions: ConversationQuestion[];
  members: BasePersonality[];
  grasps: IGrasp[];
  organizations: IBaseOrganization[];
  membersInOrganizations: IMemberInOrganization[];
};

export const DashboardQueriesContext = createContext<
  DashboardQueriesContextType | undefined
>(undefined);

export const DashboardDataReducerContext = createContext<
  | [
      DashboardDataStates.DashboardDataState,
      (action: DashboardDataActions.DashboardDataAction) => void
    ]
  | undefined
>(undefined);
