import React from 'react';
import { useCaseAndSessionsDetails } from './hooks/useCaseAndSessionsDetails';
import { useInitQueries } from '../../../../../../../../hooks/queries/useInitQuery';
import FullScreenSpinner from '../../../../../../../../components/Spinners/FullScreenSpinner';
import { ICaseDetails } from '../../../../../../../../interfaces/cases';
import { IAwareSessionData } from '../../../../../../../../interfaces/awareSessions';
import QueriesLoaded from '../../../../../../../../components/QueriesLoaded';
import { ConversationQuestion } from '../../../../../../../../interfaces/conversations';
import { IPersonality } from '../../../../../../../../interfaces/personalities';
import useUrlSearch from '../../../../../../../../hooks/useUrlSearch';
import MembersProvider from '../MembersProvider';
import BesidesMembersProvider from '../BesidesMembersProvider';
import { useCommonData } from '../../hooks/useCommonData';
import { ICaseProcedureHistory } from '../../../../../../../../interfaces/caseHistory';

type SessionBasedDataProviderProps = {
  children: (
    caseDetails: ICaseDetails,
    caseProcedureHistory: ICaseProcedureHistory[],
    awareSessionDetails: IAwareSessionData,
    questions: ConversationQuestion[],
    members: IPersonality[]
  ) => JSX.Element;
};

const SessionBasedDataProvider = ({
  children,
}: SessionBasedDataProviderProps) => {
  const queries = useCaseAndSessionsDetails();

  useInitQueries(queries);

  return (
    <QueriesLoaded onLoading={<FullScreenSpinner />} queries={queries}>
      {(data) => (
        <AggregatedBasedDataProvider
          awareSessionDetails={data[2]}
          caseProcedureHistory={data[1]}
          caseDetails={data[0]}
        >
          {children}
        </AggregatedBasedDataProvider>
      )}
    </QueriesLoaded>
  );
};

type AggregatedBasedDataProviderProps = SessionBasedDataProviderProps & {
  caseDetails: ICaseDetails;
  caseProcedureHistory: ICaseProcedureHistory[];
  awareSessionDetails: IAwareSessionData;
};

const AggregatedBasedDataProvider = ({
  caseDetails,
  awareSessionDetails,
  caseProcedureHistory,
  children,
}: AggregatedBasedDataProviderProps) => {
  const query = useUrlSearch();
  const aggregated = query.get('aggregated');

  if (aggregated)
    return (
      <AggregatedProvider>
        {(questions, members) =>
          children(
            caseDetails,
            caseProcedureHistory,
            awareSessionDetails,
            questions,
            members
          )
        }
      </AggregatedProvider>
    );

  return (
    <NonAggregatedProvider>
      {(questions, members) =>
        children(
          caseDetails,
          caseProcedureHistory,
          awareSessionDetails,
          questions,
          members
        )
      }
    </NonAggregatedProvider>
  );
};

type AggregatedProviderProps = {
  children: (
    questions: ConversationQuestion[],
    members: IPersonality[]
  ) => JSX.Element;
};

const AggregatedProvider = ({ children }: AggregatedProviderProps) => {
  return (
    <BesidesMembersProvider>
      {(mapping, _, questions) => (
        <MembersProvider mapping={mapping}>
          {(members) => children(questions, members)}
        </MembersProvider>
      )}
    </BesidesMembersProvider>
  );
};

const NonAggregatedProvider = ({ children }: AggregatedProviderProps) => {
  const { conversationQuestions, organizationMembers } = useCommonData();
  useInitQueries([conversationQuestions, organizationMembers]);

  return (
    <QueriesLoaded queries={[conversationQuestions, organizationMembers]}>
      {(data) => children(data[0], data[1])}
    </QueriesLoaded>
  );
};

export default SessionBasedDataProvider;
