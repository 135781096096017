import { OrganizationQueryParams } from '../../../interfaces/queryParams';
import { IMutationRequest } from '../../../interfaces/request';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { addGraspToOrganization } from '../../graspManagement';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

const useAddGraspToOrganization = ({
  params,
  options,
  silent,
}: IMutationRequest<OrganizationQueryParams>) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (graspId: string) => {
      await addGraspToOrganization(params.organizationId, graspId);
    },
    onErrorArray: (errorArray) => {
      if (!silent) {
        displayErrorArrayNotifications(
          errorArray,
          'Api.Mutations.Grasps.AddGraspToOrganization.failure'
        );
      }
      options.errorFb?.();
    },
    onSuccess: () => {
      if (!silent) {
        displaySuccessNotification(
          'Api.Mutations.Grasps.AddGraspToOrganization.success'
        );
      }
      options.successFb?.();
    },
    resetOnResult: true,
  });
};

export default useAddGraspToOrganization;
