import { ITypedMutationRequest } from '../../../interfaces/request';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { fetchAwareSessionParameters } from '../../awareSessions';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { IErrorDefinition } from '../../../interfaces/response';

type FetchAwareSessionParametersParams = {
  organizationId: string;
  aggregated: boolean;
};

const useFetchAwareSessionParameters = ({
  params,
  options,
  silent,
}: ITypedMutationRequest<
  FetchAwareSessionParametersParams,
  Record<string, any>
>) => {
  const mutationData = useMutationWithResultNotification({
    asyncApiFunction: async (patientNumber: number) => {
      const { data } = await fetchAwareSessionParameters(
        params.organizationId,
        patientNumber,
        params.aggregated
      );
      return data.data;
    },
    onSuccess: () => {
      if (!silent)
        displaySuccessNotification(
          'Api.Mutations.AwareSessions.FetchAwareSessionParameters.success'
        );

      if (!mutationData.data) return;

      options.successFb?.(mutationData.data);
    },
    onErrorArray: (errors: IErrorDefinition[]) => {
      displayErrorArrayNotifications(
        errors,
        'Api.Mutations.AwareSessions.FetchAwareSessionParameters.failure'
      );
      options.errorFb?.(errors);
    },
    resetOnResult: true,
  });

  return mutationData;
};

export default useFetchAwareSessionParameters;
