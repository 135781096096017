import { updateMultipleOrganizationGrasps } from 'api/graspManagement';
import { GraspSelection } from 'interfaces/grasps';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

type MultipleOrganizationGraspMutationParams = {
  selection: GraspSelection;
  locationId?: string;
  status?: string;
};

function useUpdateMultipleOrganizationGrasps(
  organizationId: string,
  successFb?: () => void
) {
  return useMutationWithResultNotification({
    asyncApiFunction: async ({
      selection,
      locationId,
      status,
    }: MultipleOrganizationGraspMutationParams) => {
      await updateMultipleOrganizationGrasps(
        organizationId,
        selection,
        locationId,
        status
      );
    },
    onErrorArray: (errArr) => {
      displayErrorArrayNotifications(errArr, 'OrganizationGrasps.err');
    },
    onSuccess: () => {
      successFb && successFb();
      displaySuccessNotification('OrganizationGrasps.updateSuccess');
    },
    resetOnResult: true,
  });
}

export default useUpdateMultipleOrganizationGrasps;
