import request from 'api/utils/request';
import { getOdataFilterParams } from 'helpers/utils/getOdataParams';
import {
  normalizeQueryParams,
  normalizeQueryParamsArray,
  QueryParamsArrayItem,
} from 'helpers/utils/normalizeQueryParams';
import {
  ICaseHistory,
  ICaseHistoryBody,
  ICaseProcedureHistory,
  IMultipleCaseHistoryBody,
  IUpdateAwareCase,
  IUpdateCaseProcedureHistory,
} from 'interfaces/caseHistory';
import {
  assignParticipantToCaseBodyType,
  IAssessmentQuestionHistoryEntry,
  ICaseDetails,
  ICaseFilters,
  ICaseFiltersRequest,
  ICaseListItem,
  ICasePhaseHistory,
  ICaseProcedureWithActivitiesLocalized,
  ICategory,
  ICreateCaseBody,
  IGenerateAssessmentFormsQuestionsHistoryDataBody,
  IRequestShareTimelineResponse,
} from 'interfaces/cases';
import { ICaseSecheduledActivityPeriod } from 'interfaces/caseScheduledActivities';
import { RequestActions, StatusEnum } from 'interfaces/enums';
import {
  ICaseAccessRequest,
  ICreatePersonalityBody,
  ICreatePersonalityResponse,
  IPersonality,
} from 'interfaces/personalities';
import { IParamsTypeInRequest } from 'interfaces/reducer';
import { IPatchObject, ODataParamType } from 'interfaces/request';
import { IResponse, IResponseWithPagination } from 'interfaces/response';
import { ISendMessageBody } from 'interfaces/submissions';
import { MemberCaseAccess } from '../interfaces/caseAccesses';
import { IUploadUriResult } from '../interfaces/uploading';

export const createPersonalityForOrganization = (
  organizationId: string,
  data: ICreatePersonalityBody
) =>
  request<ICreatePersonalityBody, IResponse<ICreatePersonalityResponse>>({
    url: `/organizations/${organizationId}/personalities`,
    method: 'POST',
    data,
  });

export const createCase = (organizationId: string, caseData: ICreateCaseBody) =>
  request<ICreateCaseBody, IResponse<ICaseDetails>>({
    url: `/organizations/${organizationId}/cases`,
    method: 'POST',
    data: caseData,
  });

export const assignParticipantToCase = (
  organizationId: string,
  caseId: string,
  participantId: string,
  data: assignParticipantToCaseBodyType
) =>
  request<assignParticipantToCaseBodyType, undefined>({
    url: `/organizations/${organizationId}/cases/${caseId}/participants/${participantId}${normalizeQueryParams(
      data
    )}`,
    method: 'POST',
  });

export const editCases = (
  organizationId: string,
  caseIds: QueryParamsArrayItem[],
  patchData: IPatchObject[]
) =>
  request({
    url: `/organizations/${organizationId}/cases${normalizeQueryParamsArray(
      caseIds
    )}`,
    method: 'PATCH',
    data: patchData,
  });

export const getCaseDetails = (organizationId: string, caseId: string) =>
  request<undefined, IResponse<ICaseDetails>>({
    url: `/organizations/${organizationId}/cases/${caseId}`,
    method: 'GET',
  });

export const getUserCaseDetails = (userId: string, caseId: string) =>
  request<undefined, IResponse<ICaseDetails>>({
    url: `/users/${userId}/cases/${caseId}`,
    method: 'GET',
  });

export const getUserCases = (userId: string) =>
  request<undefined, IResponse<ICaseDetails[]>>({
    url: `/users/${userId}/cases`,
    method: 'GET',
  });

export const getCasePhaseHistory = (organizationId: string, caseId: string) =>
  request<undefined, IResponse<ICasePhaseHistory[]>>({
    url: `/organizations/${organizationId}/cases/${caseId}/phase_history`,
    method: 'GET',
  });

export const postShareRequests = (organizationId: string, caseId: string) =>
  request<undefined, IResponse<IRequestShareTimelineResponse[]>>({
    url: `organizations/${organizationId}/cases/${caseId}/timeline_requests`,
    method: 'POST',
  });

export const getShareRequests = (organizationId: string, caseId: string) =>
  request<undefined, IResponse<IRequestShareTimelineResponse[]>>({
    url: `organizations/${organizationId}/cases/${caseId}/timeline_requests`,
    method: 'GET',
  });

export const getCategories = (organizationId: string) =>
  request<undefined, IResponse<ICategory[]>>({
    url: `/organizations/${organizationId}/case_categories`,
    method: 'GET',
  });

export const getCaseHistory = (
  organizationId: string,
  caseId: string,
  dateRange: {
    from: Date;
    to: Date;
  } | null,
  pagination: Partial<IParamsTypeInRequest>
) => {
  const oDataFilterParams = dateRange
    ? getOdataFilterParams(
        [
          {
            key: 'timeStart',
            operator: 'ge',
            value: dateRange?.from.toISOString(),
          },
          {
            key: 'timeStart',
            operator: 'lt',
            value: dateRange?.to.toISOString(),
          },
        ],
        { startCharacter: '&' }
      )
    : '';

  return request<never, IResponseWithPagination<ICaseHistory[]>>({
    url: `/organizations/${organizationId}/cases/${caseId}/history${normalizeQueryParams(
      pagination
    )}${oDataFilterParams}`,
    method: 'GET',
  });
};

export const deleteCaseHistory = (
  organizationId: string,
  caseId: string,
  historyId: string
) =>
  request<void, void>({
    url: `/organizations/${organizationId}/cases/${caseId}/history/${historyId}`,
    method: 'DELETE',
  });

export const editCaseHistory = (
  organizationId: string,
  caseId: string,
  historyId: string,
  data: IPatchObject[]
) =>
  request<IPatchObject[], IResponse<ICaseHistory>>({
    url: `/organizations/${organizationId}/cases/${caseId}/history/${historyId}`,
    method: 'PATCH',
    data,
  });

export const addCaseHistory = (
  organizationId: string,
  caseId: string,
  data: ICaseHistoryBody
) =>
  request<ICaseHistoryBody, IResponse<ICaseHistory>>({
    url: `/organizations/${organizationId}/cases/${caseId}/history`,
    method: 'POST',
    data,
  });

export const addMultipleCaseHistory = (
  organizationId: string,
  caseIds: string[],
  data: ICaseHistoryBody
) =>
  request<IMultipleCaseHistoryBody, IResponse<ICaseHistory>>({
    url: `/organizations/${organizationId}/case_history`,
    method: 'POST',
    data: {
      ...data,
      case_ids: caseIds,
    },
  });

interface OrganizationCaseParams extends Partial<IParamsTypeInRequest> {
  feedbackStatus?: string;
  synchronizationStatus?: string;
}

export const getOrganizationCasesWithPaging = (
  organizationId: string,
  params: OrganizationCaseParams,
  filters: ICaseFilters | null
) => {
  let statusFilterParams: ODataParamType[] = [];
  if (filters?.statuses && filters?.statuses.length > 0) {
    for (const singleStatus of filters.statuses) {
      statusFilterParams.push({
        key: 'Status',
        operator: 'eq',
        value: `'${singleStatus}'`,
      });
    }
  }

  let oDataStatusFilterString =
    statusFilterParams.length > 0
      ? getOdataFilterParams(statusFilterParams, {
          startCharacter: '&',
          logicalOperation: 'or',
          wrapInBracket: true,
        })
      : '';

  const otherFilters: ODataParamType[] = [];
  if (filters?.categoryId) {
    otherFilters.push({
      key: 'CategoryId',
      operator: 'eq',
      value: `${filters.categoryId}`,
    });

    //this got no sense if we will filter ONLY by subcategory
    if (filters?.subCategoryId) {
      otherFilters.push({
        key: 'SubCategoryId',
        operator: 'eq',
        value: `${filters.subCategoryId}`,
      });
    }
  }

  if (filters?.caseGroupId) {
    if (filters?.caseGroupId === 'unassigned') {
      otherFilters.push({
        key: 'GroupId',
        operator: 'eq',
        value: `null`,
      });
    } else {
      otherFilters.push({
        key: 'GroupId',
        operator: 'eq',
        value: `${filters.caseGroupId}`,
      });
    }
  }

  const isNotFirstFilter = statusFilterParams.length > 0;
  const oDataOtherFilterString = getOdataFilterParams(otherFilters, {
    startCharacter: isNotFirstFilter ? ' and ' : '&',
    logicalOperation: 'and',
    isNotFirstFilter,
  });

  return request<never, IResponseWithPagination<ICaseListItem[]>>({
    url: `/organizations/${organizationId}/cases${normalizeQueryParams(
      params
    )}${oDataStatusFilterString}${oDataOtherFilterString}`,
    method: 'GET',
  });
};

export const sendMessageToCasesParticipants = (
  organizationId: string,
  data: ISendMessageBody<ICaseFiltersRequest | null>
) =>
  request<ISendMessageBody<ICaseFiltersRequest | null>, void>({
    url: `/organizations/${organizationId}/cases/send_message`,
    method: 'POST',
    data,
  });

export const getUserContextAssessmentFormQuestionsHistory = (
  userId: string,
  data: IGenerateAssessmentFormsQuestionsHistoryDataBody
) =>
  request<
    IGenerateAssessmentFormsQuestionsHistoryDataBody,
    IResponse<IAssessmentQuestionHistoryEntry[]>
  >({
    url: `/users/${userId}/assessment_question_history`,
    method: 'POST',
    data,
  });

export const putOrganizationCaseMembersAccess = (
  organizationId: string,
  caseId: string,
  case_accesses: MemberCaseAccess[]
) => {
  return request<{ case_accesses: MemberCaseAccess[] }, void>({
    url: `/organizations/${organizationId}/cases/${caseId}/member_accesses`,
    method: 'PUT',
    data: {
      case_accesses,
    },
  });
};

export const getOrganizationCasesRequests = (
  organizationId: string,
  params: Partial<IParamsTypeInRequest>,
  status: StatusEnum | null
) => {
  let oDataFilterParams: ODataParamType[] = [];
  if (status) {
    oDataFilterParams.push({
      key: 'Status',
      operator: 'eq',
      value: `'${status}'`,
    });
  }

  return request<void, IResponseWithPagination<ICaseAccessRequest[]>>({
    url: `/organizations/${organizationId}/cases/member_accesses/requests${normalizeQueryParams(
      params
    )}${getOdataFilterParams(oDataFilterParams, {
      startCharacter: '&',
    })}`,
    method: 'GET',
  });
};

export const modifyCaseMemberRequest = (
  organizationId: string,
  requestId: string,
  action: RequestActions
) => {
  return request<void, void>({
    url: `organizations/${organizationId}/cases/member_accesses/requests/${requestId}/${action}`,
    method: 'POST',
  });
};

export const getCaseMemberPersonalities = (
  organizationId: string,
  caseId: string
) => {
  return request<void, IResponse<IPersonality[]>>({
    url: `organizations/${organizationId}/cases/${caseId}/members`,
    method: 'GET',
  });
};

export const getCaseScheduledActivityPeriods = (
  organizationId: string,
  caseId: string
) => {
  return request<void, IResponse<ICaseSecheduledActivityPeriod[]>>({
    url: `organizations/${organizationId}/cases/${caseId}/scheduled_activity_periods`,
    method: 'GET',
  });
};

export const getCaseProcedureHistory = (
  organizationId: string,
  caseId: string
) => {
  return request<undefined, IResponse<ICaseProcedureHistory[]>>({
    url: `organizations/${organizationId}/cases/${caseId}/procedure_history`,
    method: 'GET',
  });
};

export const getCaseProcedures = (organizationId: string) => {
  return request<undefined, IResponse<ICaseProcedureWithActivitiesLocalized[]>>(
    {
      url: `organizations/${organizationId}/case_procedures`,
      method: 'GET',
    }
  );
};

export const updateCaseProcedureHistory = (
  organizationId: string,
  caseId: string,
  data: IUpdateCaseProcedureHistory
) =>
  request<IUpdateCaseProcedureHistory, void>({
    url: `organizations/${organizationId}/cases/${caseId}/procedure_history`,
    method: 'PUT',
    data,
  });

export const updateAwareCase = (
  organizationId: string,
  caseId: string,
  data: IUpdateAwareCase
) =>
  request<IUpdateAwareCase, void>({
    url: `organizations/${organizationId}/aware-cases/${caseId}`,
    method: 'PUT',
    data,
  });

export const uploadCaseDetailsPdf = (
  organizationId: string,
  caseId: string,
  file: File
) => {
  const formData = new FormData();
  formData.append('uploadFile', file);

  return request<FormData, IResponse<IUploadUriResult>>({
    url: `/organizations/${organizationId}/cases/${caseId}/upload-pdf`,
    method: 'POST',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};
