import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';
import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { handleClose } from '../../helpers';

const HelpCentreConfirmation = () => {
  return (
    <Box
      sx={{ color: 'text.secondary' }}
      className="d-flex flex-column align-items-center justify-content-center w-100 h-100 gap-3"
    >
      <MarkEmailReadIcon sx={{ fontSize: 150 }} />
      <Typography variant="h5">
        <FormattedMessage id="Help.MessageSent" />
      </Typography>
      <Button
        sx={{ minWidth: '120px' }}
        size="large"
        variant="contained"
        onClick={handleClose}
      >
        <FormattedMessage id="General.Ok" />
      </Button>
    </Box>
  );
};

export default HelpCentreConfirmation;
