import { OpenHookValues } from '../../../../../../../../../hooks/useOpen';
import { FormikProps } from 'formik';
import { AwareCaseDetailsFormValues } from '../types';
import { useEffect } from 'react';

export const useEnableEdit = (
  editMode: OpenHookValues,
  form: FormikProps<AwareCaseDetailsFormValues>
) => {
  useEffect(() => {
    if (!form.dirty) return;
    editMode.open();
  }, [editMode, form.dirty]);
};
