import { IQueryRequest } from '../../../interfaces/request';
import { UserQueryParams } from '../../../interfaces/queryParams';
import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getUserCases } from '../../cases';

const useUserCases = ({ params, options }: IQueryRequest<UserQueryParams>) => {
  const { userId } = params;

  return useQuery({
    ...options,
    queryKey: [QueryKeys.getUserCases, userId],
    queryFn: async () => {
      const { data } = await getUserCases(userId);
      return data.data;
    },
  });
};

export default useUserCases;
