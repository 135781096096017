import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getCaseRelatedTrendVisualizationTemplates } from '../../trendVisualizations';
import { CaseQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

const useCaseRelatedTrendVisualizationTemplates = ({
  params,
  options,
}: IQueryRequest<CaseQueryParams>) => {
  const { organizationId, caseId } = params;
  return useQuery({
    ...options,
    queryKey: [
      QueryKeys.getTrendVisualizationTemplates,
      organizationId,
      caseId,
    ],
    queryFn: async () => {
      const { data } = await getCaseRelatedTrendVisualizationTemplates(
        organizationId,
        caseId
      );
      return data.data;
    },
  });
};

export default useCaseRelatedTrendVisualizationTemplates;
