import useCultures from '../cultures/useCultures';
import { useCallback } from 'react';
import { ConversationQuestion } from '../../interfaces/conversations';

export const useQuestionTitle = () => {
  const { currentCulture, defaultCulture } = useCultures();

  return useCallback(
    (question: ConversationQuestion): string => {
      const shortTitleNbNo =
        question.content.short_title_nb_no &&
        question.content.short_title_nb_no.length > 0
          ? question.content.short_title_nb_no
          : undefined;

      const titleNbNo =
        question.content.title_nb_no && question.content.title_nb_no.length > 0
          ? question.content.title_nb_no
          : undefined;

      const translatedTitle = shortTitleNbNo ?? titleNbNo ?? '';

      const defaultTitle =
        question.content.short_title.length > 0
          ? question.content.short_title
          : question.content.title;

      return currentCulture.cultureCode === defaultCulture.cultureCode
        ? defaultTitle
        : translatedTitle ?? defaultTitle;
    },
    [currentCulture.cultureCode, defaultCulture.cultureCode]
  );
};
