import request from 'api/utils/request';
import {
  Functionality,
  OrganizationFunctionality,
} from 'interfaces/functionalities';
import { IResponse } from 'interfaces/response';

export const getOrganizationFunctionalities = (organizationId: string) => {
  return request<undefined, IResponse<OrganizationFunctionality[]>>({
    url: `/organizations/${organizationId}/available_functionalities`,
    method: 'GET',
  });
};

export const getFunctionalities = () => {
  return request<undefined, IResponse<Functionality[]>>({
    url: `/available_functionalities`,
    method: 'GET',
  });
};

export const deleteOrganizationFunctionality = (
  organizationId: string,
  functionalityId: string
) => {
  return request<undefined, IResponse<Functionality[]>>({
    url: `/organizations/${organizationId}/available_functionalities/${functionalityId}`,
    method: 'DELETE',
  });
};

export const addOrganizationFunctionality = (
  organizationId: string,
  functionalityId: string
) => {
  return request<undefined, IResponse<Functionality[]>>({
    url: `/organizations/${organizationId}/available_functionalities/${functionalityId}`,
    method: 'POST',
  });
};
