import { schedulesOverlapping } from '../../../../../../../../../../helpers/utils/conversationSchedules';
import {
  IConversationScheduleTemplateItem,
  INotificationTreatmentPhaseContentItem,
  TreatmentPhaseContentItemType,
} from '../../../../../../../../../../interfaces/conversations';
import { ConversationScheduleType } from '../../../../../../../../../../interfaces/conversationSchedules';
import { CaseNotificationScheduleType } from '../../../../../../../../../../interfaces/notificationSchedules';
import { displayErrorNotification } from '../../../../../../../../../Notifications';
import { FormValues } from './types';

export const getEmptyInitialValues = (): FormValues => {
  return {
    type: TreatmentPhaseContentItemType.NotificationSchedule,
    notification_type: CaseNotificationScheduleType.GraspFlashing,
    schedule_templates: [
      {
        schedule_type: ConversationScheduleType.SingleInstance,
        start_day_offset: undefined,
        end_day_offset: undefined,
        parts_of_a_day: [],
        recurring_interval: undefined,
        specific_day_of_phase: undefined,
      },
    ],
  };
};

export const getFormValuesBasedOnItem = (
  item: INotificationTreatmentPhaseContentItem
): FormValues => {
  const firstTemplate = item.schedule_templates[0];

  if (
    firstTemplate.schedule_type !== ConversationScheduleType.SingleInstance &&
    firstTemplate.schedule_type !== ConversationScheduleType.Recurring
  )
    throw Error('Notification schedule has invalid type assigned');

  return {
    type: TreatmentPhaseContentItemType.NotificationSchedule,
    notification_type: item.notification_type,
    schedule_templates: [
      {
        schedule_type: firstTemplate.schedule_type,
        start_day_offset:
          firstTemplate.start_day_offset !== null
            ? firstTemplate.start_day_offset + 1
            : undefined,
        end_day_offset:
          firstTemplate.end_day_offset !== null
            ? firstTemplate.end_day_offset + 1
            : undefined,
        recurring_interval: firstTemplate.recurring_interval ?? undefined,
        specific_day_of_phase:
          firstTemplate.specific_day_of_phase !== null
            ? firstTemplate.specific_day_of_phase + 1
            : undefined,
        parts_of_a_day: firstTemplate.parts_of_a_day ?? [],
      },
    ],
  };
};

export const validatePartOfADayHours = (values: FormValues) => {
  const partsOfADay = values.schedule_templates[0].parts_of_a_day;

  if (!partsOfADay.length) return true;

  for (const partOfADay of partsOfADay) {
    const isValid = _isPartOfADayValid(partOfADay);

    if (isValid) continue;

    displayErrorNotification('Notifications.NotificationHoursInvalidOrder');
    return false;
  }

  for (let i = 0; i < partsOfADay.length; i++) {
    const partOfADay = values.schedule_templates[0].parts_of_a_day[i];

    const rest = values.schedule_templates[0].parts_of_a_day.filter(
      (_, idx) => idx !== i
    );
    const overlapping = schedulesOverlapping(rest, partOfADay);

    if (overlapping) {
      displayErrorNotification('Notifications.NotificationsCannotOverlap');
      return false;
    }
  }

  return true;
};

const _isPartOfADayValid = (partOfADay: IConversationScheduleTemplateItem) =>
  (partOfADay?.notification_hour ?? -1000) >=
    (partOfADay?.available_from_hour ?? 0) &&
  (partOfADay?.notification_hour ?? 1000) <=
    (partOfADay?.available_to_hour ?? 0);
