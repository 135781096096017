import { useBrowserState } from '../../store/reducerHooks';
import UserCases from './components/UserCases';
import UserDashboard from './components/UserDashboard';

const Dashboard = () => {
  const isBrowser = useBrowserState();

  if (!isBrowser) {
    return <UserCases />;
  }

  return <UserDashboard />;
};

export default Dashboard;
