import {
  checkUserRole,
  isOrganizationAdminForOrganizationProvided,
} from 'helpers/utils/auth';
import { UserRoles } from 'interfaces/membership';
import React from 'react';
import { Navigate, Outlet, useLocation, useParams } from 'react-router';

type RoleGuardProps = {
  role: UserRoles;
  redirectTo?: string;
  fallback?: JSX.Element;
  withBackwardRedirection?: boolean;
};

const RoleRouteGuard = ({
  role,
  redirectTo = '/login',
  fallback,
  withBackwardRedirection = false,
}: RoleGuardProps) => {
  const isRoleMatched = checkUserRole(role);
  const location = useLocation();
  const params = useParams();

  if (role === UserRoles.ORGANIZATION_ADMIN) {
    if (!params.organizationId) return <Navigate to="/login" />;

    if (isOrganizationAdminForOrganizationProvided(params.organizationId))
      return <Outlet />;

    return (
      <Navigate
        to={redirectTo?.replace(':organizationId', params.organizationId)}
      />
    );
  }

  if (isRoleMatched) return <Outlet />;
  if (fallback) return fallback;

  return withBackwardRedirection ? (
    <Navigate to={`${redirectTo}?back=${location?.pathname}`} />
  ) : (
    <Navigate to={redirectTo} />
  );
};

export default RoleRouteGuard;
