import React from 'react';
import { Field, Form, Formik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';

//helpers
import useEditPassword from 'api/mutations/membership/useEditPassword';

//helpers
import { arePasswordsEqual } from 'helpers/utils/validators';
import { displayErrorNotification } from 'modules/Notifications';

//components
import TabLayout from './TabLayout';
import FieldInput from 'components/FieldInput';
import LoadingButton from 'components/LoadingButton';
import InputFieldWithImage, {
  InputFieldWithImageColor,
} from 'components/InputFieldWithImage';

//images
import passwordIcon from 'assets/images/password_icon.svg';

const initialValues = {
  old_password: '',
  new_password: '',
  confirm_password: '',
};

const passwordIconImage = <img src={passwordIcon} alt="password_icon" />;

const ChangePasswordTab = () => {
  const intl = useIntl();
  const { mutate: editPasswordMutation, isLoading } = useEditPassword();

  const onSubmit = (values: typeof initialValues) => {
    if (arePasswordsEqual(values.new_password, values.confirm_password)) {
      editPasswordMutation({
        new_password: values.new_password,
        old_password: values.old_password,
      });
    } else {
      displayErrorNotification(
        'Register.notifications.errors.different_passwords'
      );
    }
  };

  const validateForm = (values: typeof initialValues) => {
    const keys = Object.keys(initialValues);
    let errors: Partial<typeof initialValues> = {};

    keys.forEach((key) => {
      if (values[key] === '')
        errors[key] = intl.formatMessage({
          id: 'CaseCard.create.errors.inputEmpty',
        });
    });

    return errors;
  };

  return (
    <TabLayout title="MyProfile.Tabs.changePassword" imageSrc={passwordIcon}>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validate={validateForm}
      >
        {({ isValid }) => (
          <Form>
            <InputFieldWithImage
              image={passwordIconImage}
              color={InputFieldWithImageColor.gray}
              labelTranslationId="MyProfile.passwordChangeModal.oldPassword"
              inputId="old_password"
            >
              <Field
                required
                id="old_password"
                name="old_password"
                type="password"
                placeholder={intl.formatMessage({
                  id: 'MyProfile.passwordChangeModal.oldPassword',
                })}
                component={FieldInput}
              />
            </InputFieldWithImage>
            <InputFieldWithImage
              image={passwordIconImage}
              color={InputFieldWithImageColor.gray}
              labelTranslationId="MyProfile.passwordChangeModal.newPassword"
              inputId="new_password"
            >
              <Field
                required
                id="new_password"
                name="new_password"
                type="password"
                placeholder={intl.formatMessage({
                  id: 'MyProfile.passwordChangeModal.newPassword',
                })}
                component={FieldInput}
              />
            </InputFieldWithImage>
            <InputFieldWithImage
              image={passwordIconImage}
              color={InputFieldWithImageColor.gray}
              labelTranslationId="MyProfile.passwordChangeModal.confirmPassword"
              inputId="confirm_password"
            >
              <Field
                required
                id="confirm_password"
                name="confirm_password"
                type="password"
                placeholder={intl.formatMessage({
                  id: 'MyProfile.passwordChangeModal.confirmPassword',
                })}
                component={FieldInput}
              />
            </InputFieldWithImage>
            <div className="d-flex justify-content-end">
              <LoadingButton
                isLoading={isLoading}
                disabled={!isValid}
                type="submit"
              >
                <FormattedMessage id="General.saveChanges" />
              </LoadingButton>
            </div>
          </Form>
        )}
      </Formik>
    </TabLayout>
  );
};

export default ChangePasswordTab;
