import useRequiredParams from '../../../../../../../../../hooks/useRequiredParams';
import useCaseGroupConversationScheduleResponses from '../../../../../../../../../api/queries/conversationSchedules/useCaseGroupConversationScheduleResponses';

export const useCommonData = (scheduleId: string) => {
  const { organizationId, groupId } = useRequiredParams<{
    organizationId: string;
    groupId: string;
  }>(['organizationId', 'groupId']);

  const caseGroupScheduleResponsesQuery =
    useCaseGroupConversationScheduleResponses({
      params: { organizationId, caseGroupId: groupId, scheduleId },
      options: {
        enabled: false,
        staleTime: Infinity,
      },
    });

  return {
    caseGroupScheduleResponses: caseGroupScheduleResponsesQuery,
  };
};
