import React, { createContext, useEffect } from 'react';
import { useCaseGroupDetailsConversationSchedulesDay } from './hooks/useCaseGroupDetailsConversationSchedulesDay';
import { useCaseGroupDetailsCommonData } from '../../hooks/useCaseGroupDetailsCommonData';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import useOpen from '../../../../../../hooks/useOpen';
import SVG from 'react-inlinesvg';

import plusIcon from 'assets/images/plus.svg';
import { formatDate } from '../../../../../../helpers/dates';
import { useTreatmentPhasesNames } from './hooks/useTreatmentPhasesNames';
import { useCaseGroupDetailsConversationSchedulesTabs } from './hooks/useCaseGroupDetailsConversationSchedulesTabs';
import CaseGroupDetailsConversationSchedulesAddTemplateModal from './modals/CaseGroupDetailsConversationSchedulesAddTemplateModal';
import TabContainer from '../../../../../../components/Tabs/components/TabContainer';
import InlineDateSelector from '../../../../../../components/DateSelector/InlineDaySelector';

export const ConversationScheduleDateContext = createContext<string>(
  new Date().toISOString()
);

const CaseGroupDetailsConversationSchedules = () => {
  const intl = useIntl();
  const { caseGroup } = useCaseGroupDetailsCommonData();

  const {
    selectedDate,
    handlePrev,
    handleNext,
    phaseDescription,
    selectPhase,
  } = useCaseGroupDetailsConversationSchedulesDay(caseGroup.data);

  const getName = useTreatmentPhasesNames();

  const { isOpen: addTemplateOpen, toggle: toggleAddTemplate } = useOpen();

  const { onTabChange, tabItems, setSelectedTab, selectedTab } =
    useCaseGroupDetailsConversationSchedulesTabs(selectPhase);

  useEffect(() => {
    if (phaseDescription?.id) setSelectedTab(phaseDescription.id);
  }, [phaseDescription?.id, setSelectedTab]);

  return (
    <>
      {addTemplateOpen && (
        <CaseGroupDetailsConversationSchedulesAddTemplateModal
          isOpen={addTemplateOpen}
          toggle={toggleAddTemplate}
          phaseId={selectedTab}
        />
      )}
      <div className="CaseGroupDetailsConversationSchedules CaseCard__tab-container pt-3 px-3">
        <div className="CaseGroupDetailsConversationSchedules__header">
          <div className="CaseGroupDetailsConversationSchedules__day-selection">
            <InlineDateSelector
              handlePrev={handlePrev}
              handleNext={handleNext}
              date={selectedDate}
            />
            {phaseDescription && (
              <div className="user-select-none">
                <span
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage(
                      {
                        id: 'CaseGroups.CaseGroupDetails.CaseGroupDetailsConversationSchedules.phaseDescription',
                      },
                      {
                        phaseName: `<b class="text-muted">${
                          getName(phaseDescription.id) ?? ''
                        }</b>`,
                        day: `<b class="text-muted">${phaseDescription.nthDay}</b>`,
                        dayOf: phaseDescription.daysDuration,
                        startDate: formatDate(phaseDescription.startDate, {
                          ignoreTime: true,
                          daysLeadingZeros: true,
                        }),
                        endDate: formatDate(phaseDescription.endDate, {
                          ignoreTime: true,
                          daysLeadingZeros: true,
                        }),
                      }
                    ),
                  }}
                ></span>
              </div>
            )}
          </div>
          <Button
            color="primary"
            className="CaseGroupDetailsConversationSchedules__add-template"
            onClick={toggleAddTemplate}
          >
            <SVG
              src={plusIcon}
              title={intl.formatMessage({
                id: 'CaseGroups.CaseGroupDetails.CaseGroupDetailsConversationSchedules.addTemplate',
              })}
            />
            <FormattedMessage id="CaseGroups.CaseGroupDetails.CaseGroupDetailsConversationSchedules.addTemplate" />
          </Button>
        </div>
        <div className="CaseGroupDetailsConversationSchedules__tabs">
          <ConversationScheduleDateContext.Provider value={selectedDate}>
            <TabContainer
              items={tabItems}
              onTabChange={onTabChange}
              justified={false}
            />
          </ConversationScheduleDateContext.Provider>
        </div>
      </div>
    </>
  );
};

export default CaseGroupDetailsConversationSchedules;
