import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { linkVideoAssetWithMaterial } from '../../educationMaterials';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';

type LinkVideoAssetWithEducationMaterialProps = {
  assetId: string;
  materialId: string;
  culture: string;
};

const useLinkVideoAssetWithEducationMaterial = (
  organizationId: string,
  onSuccess?: () => void
) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (
      body: LinkVideoAssetWithEducationMaterialProps
    ) => {
      await linkVideoAssetWithMaterial(
        organizationId,
        body.assetId,
        body.materialId,
        body.culture
      );
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'Api.Mutations.EducationMaterials.LinkVideoAssetWithEducationMaterial.failure'
      );
    },
    onSuccess: () => {
      onSuccess?.();
      displaySuccessNotification(
        'Api.Mutations.EducationMaterials.LinkVideoAssetWithEducationMaterial.success'
      );
    },
    resetOnResult: true,
  });
};

export default useLinkVideoAssetWithEducationMaterial;
