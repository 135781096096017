import { getOrganizationContextAssessmentFormsForPeriod } from 'api/graspManagement';
import { useQuery } from 'react-query';
import QueryKeys from '../../../queryKeys';
import {
  CaseQueryParams,
  TimelineRangeQueryParams,
} from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

export type UseOrganizationContextAssessmentFormsForPeriodParams =
  CaseQueryParams & TimelineRangeQueryParams;

function useOrganizationContextAssessmentFormsForPeriod({
  params,
  options,
}: IQueryRequest<UseOrganizationContextAssessmentFormsForPeriodParams>) {
  const { organizationId, caseId, timelineId, selectedDates } = params;
  const { enabled, ...otherOptions } = options ?? { enabled: true };

  const isEnabled =
    enabled &&
    (!!selectedDates || !!organizationId || !!caseId || !!timelineId);

  return useQuery({
    ...otherOptions,
    enabled: isEnabled,
    queryKey: [
      QueryKeys.getOrganizationContextAssessmentFormsForPeriod,
      organizationId,
      caseId,
      timelineId,
      selectedDates?.from.setSeconds(0, 0),
      selectedDates?.to.setSeconds(0, 0),
    ],
    queryFn: async () => {
      if (!organizationId || !caseId || !selectedDates || !timelineId)
        return null;

      const { data: responseData } =
        await getOrganizationContextAssessmentFormsForPeriod(
          organizationId,
          caseId,
          timelineId,
          selectedDates.from,
          selectedDates.to
        );

      return responseData.data;
    },
  });
}

export default useOrganizationContextAssessmentFormsForPeriod;
