import {
  DashboardDateSelection,
  DashboardTemplateVisualization,
  DashboardTemplateVisualizationPosition,
  IDashboardTemplateContent,
} from 'interfaces/dashboards';
import { useCallback, useMemo } from 'react';
import { DashboardFilter } from '../../../../../../../../../../../interfaces/dashboards';
import { BootstrapBreakpoint } from '../../../../../../../../../../../interfaces/ui';

export const useTemplateGrid = (
  template: IDashboardTemplateContent<DashboardFilter, DashboardDateSelection>,
  breakpoint: BootstrapBreakpoint | null
) => {
  const rowHeights = useMemo(() => {
    return (
      template.layout.row_heights.find((p) => p.breakpoint === breakpoint) ??
      template.layout.row_heights[0]
    );
  }, [breakpoint, template.layout.row_heights]);

  const templateColumns = 'repeat(12, 1fr)';

  const templateRows = useMemo(() => {
    if (rowHeights.heights.length === 1) return '1fr';
    return rowHeights.heights.map((height) => `${height * 100}vh`).join(' ');
  }, [rowHeights.heights]);

  const getCurrentPosition = useCallback(
    (visualization: DashboardTemplateVisualization) => {
      return (
        visualization.positions.find((p) => p.breakpoint === breakpoint) ??
        visualization.positions[0]
      );
    },
    [breakpoint]
  );

  const getGridColumn = useCallback(
    (position: DashboardTemplateVisualizationPosition) => {
      return `${position.start[0] + 1} / ${position.end[0] + 1}`;
    },
    []
  );

  const getGridRow = useCallback(
    (position: DashboardTemplateVisualizationPosition) => {
      return `${position.start[1] + 1} / ${position.end[1] + 1}`;
    },
    []
  );

  const columnGap = useMemo(
    () => template.layout.column_gap ?? 0,
    [template.layout.column_gap]
  );

  const rowGap = useMemo(
    () => template.layout.row_gap ?? 0,
    [template.layout.row_gap]
  );

  return {
    templateColumns,
    templateRows,
    getCurrentPosition,
    getGridRow,
    getGridColumn,
    columnGap,
    rowGap,
  };
};
