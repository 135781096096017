import React, { useMemo } from 'react';
import { ITreatmentPlanPhase } from '../../../../../../interfaces/conversations';
import { cloneDeep } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faEllipsisH } from '@fortawesome/pro-light-svg-icons';
import { useTreatmentPlansCommonData } from '../../../../hooks/useTreatmentPlansCommonData';
import { FormattedMessage, useIntl } from 'react-intl';

type TreatmentPlanListItemPhasesProps = {
  phases: ITreatmentPlanPhase[];
  renderedPhasesCount?: number;
};

const TreatmentPlanListItemPhases = ({
  phases,
  renderedPhasesCount = 3,
}: TreatmentPlanListItemPhasesProps) => {
  const intl = useIntl();

  const { caseCategories } = useTreatmentPlansCommonData();

  const subCategories = useMemo(() => {
    return (caseCategories?.data ?? []).flatMap((p) => p.sub_categories);
  }, [caseCategories?.data]);

  const renderedPhases = useMemo(() => {
    if (renderedPhasesCount >= phases.length) return cloneDeep(phases);

    let _renderedPhases: (ITreatmentPlanPhase | null)[] = phases
      .slice(0, renderedPhasesCount)
      .map((phase) => cloneDeep(phase));

    _renderedPhases.push(null);
    _renderedPhases.push(phases[phases.length - 1]);

    return _renderedPhases;
  }, [phases, renderedPhasesCount]);

  return (
    <div className="TreatmentPlanListItemPhases">
      {renderedPhases.map((renderedPhase, index) => (
        <div
          key={`${renderedPhase?.treatment_phase_id}|${renderedPhase?.sequence}`}
          className="d-flex align-items-center gap-3"
        >
          <div className="TreatmentPlanListItemPhases__phase">
            {renderedPhase === null ? (
              <FontAwesomeIcon icon={faEllipsisH} />
            ) : (
              <>
                <div className="TreatmentPlanListItemPhases__phase-content">
                  <div className="TreatmentPlanListItemPhases__days-block">
                    <FormattedMessage
                      id="TreatmentPlans.TreatmentPlansList.TreatmentPlansListItemPhases.nDays"
                      values={{ days: renderedPhase.days_duration }}
                    />
                  </div>
                  <div className="TreatmentPlanListItemPhases__name">
                    {subCategories.find(
                      (p) => p.id === renderedPhase.treatment_phase_id
                    )?.name_en ??
                      intl.formatMessage({
                        id: 'TreatmentPlans.TreatmentPlansList.TreatmentPlansListItemPhases.unknownPhase',
                      })}
                  </div>
                </div>
              </>
            )}
          </div>
          {index < renderedPhases.length - 1 && (
            <div className="TreatmentPlanListItemPhases__phase TreatmentPlanListItemPhases__next">
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default TreatmentPlanListItemPhases;
