import { useState } from 'react';
import { InfiniteData, QueryObserverResult } from 'react-query';
import useDeleteAllUserRolesInOrganization from '../../../../../api/mutations/organizations/useDeleteAllUserRolesInOrganization';
import { useOrganizationPathParams } from '../../../../../hooks/paths/useOrganizationPathParams';
import { IError, IInfiniteResult } from '../../../../../interfaces/response';
import { IUserOrganizationResponse } from '../../../../../interfaces/membership';
import { ConfirmationHookValues } from '../types';

export const useDeleteAllRolesConfirmation = (
  refetch: () => Promise<
    QueryObserverResult<
      InfiniteData<IInfiniteResult<IUserOrganizationResponse[]>>,
      IError
    >
  >
): ConfirmationHookValues<string | null> => {
  const { organizationId } = useOrganizationPathParams();

  const [userId, setUserId] = useState<null | string>(null);
  const close = () => setUserId(null);
  const isOpen = !!userId;

  const successFb = async () => await refetch();

  const { mutateAsync: deleteAsync } = useDeleteAllUserRolesInOrganization(
    organizationId,
    { successFb }
  );

  const onConfirm = async () => {
    if (!userId) return;
    await deleteAsync(userId);
    setUserId(null);
  };

  return { isOpen, close, onConfirm, item: userId, setItem: setUserId };
};
