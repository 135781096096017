import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage } from 'react-intl';

const CaseSubCategoryEmpty = () => {
  return (
    <div className="CaseSubCategoryEmpty">
      <FontAwesomeIcon icon={faPencil} size="3x" />
      <span>
        <FormattedMessage id="MyOrganizations.caseCategories.emptyCategories" />
      </span>
    </div>
  );
};

export default CaseSubCategoryEmpty;
