import React from 'react';
import TabLayout from '../../../../../../components/layouts/TabLayout';
import CaseCategoriesListView from './components/CaseCategoriesListView';
import { CommonTabProps } from '../types';

const CaseCategoriesTab = ({ type }: CommonTabProps) => {
  return (
    <TabLayout type={type} titlePrefix="Organizations.CaseCategories">
      <CaseCategoriesListView />
    </TabLayout>
  );
};

export default CaseCategoriesTab;
