import React from 'react';
import { useTranslations } from '../../../../../../../../../../../../../../hooks/useTranslations';
import { Spinner } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

const Validating = () => {
  const { wrapWithIntl, attachPrefix } = useTranslations(
    'ConversationsAndFeedbacks.ConversationTemplates.ImportTemplateModal.Content.ConflictsStep.Validation.Validating'
  );

  return (
    <div className="w-100 h-px-250 d-flex align-items-center justify-content-center">
      <div className="d-flex flex-column align-items-center text-primary gap-2">
        <Spinner title={wrapWithIntl(attachPrefix('isValidating'))} />
        <span className="fs-5">
          <FormattedMessage id={attachPrefix('isValidating')} />
        </span>
      </div>
    </div>
  );
};

export default Validating;
