import useCultures from 'hooks/cultures/useCultures';
import { IValidationSchemaBasedFormHookValues } from 'interfaces/forms';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import { useTypedContext } from '../../../../../../../../../../../hooks/useTypedContext';
import {
  OrganizationRegisterStepsActionType,
  OrganizationRegisterStepsReducerContext,
} from '../../../../../store/reducers';
import { FormsNavigationContext } from '../../types';

export type SignUpFormValues = {
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  password: string;
  confirmPassword: string;
};

export const useForm =
  (): IValidationSchemaBasedFormHookValues<SignUpFormValues> => {
    const intl = useIntl();

    const invalidFieldValue = intl.formatMessage({
      id: 'General.InvalidFieldValue',
    });

    const passwordMismatch = intl.formatMessage({
      id: 'General.PasswordMismatch',
    });

    const navigation = useTypedContext(FormsNavigationContext);

    const [state, dispatch] = useTypedContext(
      OrganizationRegisterStepsReducerContext
    );

    const { currentCulture } = useCultures();

    const validationSchema = Yup.object().shape({
      firstName: Yup.string()
        .min(2, invalidFieldValue)
        .required(invalidFieldValue),
      lastName: Yup.string()
        .min(2, invalidFieldValue)
        .required(invalidFieldValue),
      userName: Yup.string()
        .min(2, invalidFieldValue)
        .required(invalidFieldValue),
      email: Yup.string()
        .min(2, invalidFieldValue)
        .email(invalidFieldValue)
        .required(invalidFieldValue),
      password: Yup.string().required(invalidFieldValue),
      confirmPassword: Yup.string()
        .required(invalidFieldValue)
        .oneOf([Yup.ref('password')], passwordMismatch),
    });

    const initialValues = useMemo((): SignUpFormValues => {
      return {
        firstName: state.signUp.first_name,
        lastName: state.signUp.last_name,
        email: state.signUp.email,
        userName: state.signUp.user_name,
        password: state.signUp.password,
        confirmPassword: state.signUp.passwordRepeat,
      };
    }, [
      state.signUp.email,
      state.signUp.first_name,
      state.signUp.last_name,
      state.signUp.password,
      state.signUp.passwordRepeat,
      state.signUp.user_name,
    ]);

    const handleSubmit = useCallback(
      (values: typeof initialValues) => {
        dispatch({
          type: OrganizationRegisterStepsActionType.SaveSignUp,
          payload: {
            first_name: values.firstName,
            last_name: values.lastName,
            address: '',
            birthdate: '',
            culture: currentCulture.cultureCode,
            password: values.password,
            timezone_id: Intl.DateTimeFormat().resolvedOptions().timeZone,
            passwordRepeat: values.confirmPassword,
            token: null,
            email: values.email,
            user_name: values.userName,
            gender: '',
            ssn: '',
            phone_number: '',
            external_login_provider: null,
          },
        });

        navigation.next();
      },
      [currentCulture.cultureCode, dispatch, navigation]
    );

    return {
      initialValues,
      handleSubmit,
      validationSchema,
    };
  };
