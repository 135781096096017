import useRefreshOrganizationToken from 'api/mutations/membership/useRefreshOrganizationToken';
import { ILoginResponse, LoginMethod, TokenTypes } from 'interfaces/membership';
import { useNavigate } from 'react-router';
import { useApplyUserData } from '../api/mutations/membership/useApplyUserData';

function useLoginRedirect() {
  const { mutate: refreshOrganizationTokenMutation } =
    useRefreshOrganizationToken({ myOrganizationScreen: false });

  const applyUserData = useApplyUserData();

  const navigate = useNavigate();

  return (
    data: ILoginResponse,
    redirectUrl: string | null = null,
    loginAs: LoginMethod
  ) => {
    const {
      organizations,
      user: { default_organization_id },
    } = data;

    let filteredOrganizations = organizations.filter(
      (singleOrg) =>
        singleOrg.role.includes('grasp_admin') ||
        singleOrg.role.includes('grasp_manager') ||
        singleOrg.role.includes('organization_admin')
    );

    const hasAtLeastOneOrganization =
      filteredOrganizations && filteredOrganizations.length > 0;

    let organizationRedirectId: undefined | string;

    if (loginAs === LoginMethod.AsMedicalPersonnel) {
      if (!!default_organization_id) {
        const organizationFound = filteredOrganizations.find(
          (org) => org.id === default_organization_id
        );
        if (organizationFound) {
          organizationRedirectId = default_organization_id;
        }
      } else if (hasAtLeastOneOrganization) {
        organizationRedirectId = filteredOrganizations[0].id;
      }
    }

    const tokenType =
      (hasAtLeastOneOrganization || redirectUrl) &&
      loginAs === LoginMethod.AsMedicalPersonnel
        ? TokenTypes.ORGANIZATION
        : TokenTypes.PERSONAL;

    applyUserData(data, loginAs, tokenType);

    if (redirectUrl) {
      navigate(redirectUrl);

      localStorage.removeItem('redirectUrl');
    } else {
      if (filteredOrganizations && organizationRedirectId) {
        refreshOrganizationTokenMutation(organizationRedirectId);
      } else {
        navigate('/');
      }
    }
  };
}

export default useLoginRedirect;
