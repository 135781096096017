export namespace DashboardDefaults {
  export const NOT_SPECIFIED_TRANSLATION_PREFIX: string = 'notSpecified';
  export const NOT_SPECIFIED_TRANSLATION_PATH: string =
    'Organizations.OrganizationDashboard.AwareCasesDashboard';
  export const MAX_FETCHING_DATA_LIMIT: number = 10000;
  export const DEFAULT_COLOR_SCHEME = [
    '#054F80',
    '#0B6BAA',
    '#FF896B',
    '#AFD0D6',
    '#C1E3EA',
    '#FFAF96',
    '#D0D8D8',
    '#E7EEEE',
  ];
}
