import graspLogoBlue from 'assets/images/logo-blue.svg';
import SVG from 'react-inlinesvg';
import { translateHtmlStr } from '../../../../../helpers/utils/cultures';
import { AVAILABLE_CULTURES } from '../../../../../components/CultureSelector/CultureSelector';
import { FormattedMessage } from 'react-intl';

type RegistrationSuccessProps = {
  title: string | null;
  description: string | null;
  selectedCulture: AVAILABLE_CULTURES;
};

const RegistrationSuccess = ({
  description,
  title,
  selectedCulture,
}: RegistrationSuccessProps) => {
  const translatedDescriptionElement = translateHtmlStr(
    description,
    selectedCulture
  );

  return (
    <div className="JoinConsent">
      <SVG
        className="JoinConsent__logo"
        src={graspLogoBlue}
        title="Grasp Logo"
      />
      <h2 className="JoinConsent__title">
        <FormattedMessage
          values={{ title: translateHtmlStr(title, selectedCulture, false) }}
          id="ProjectSubmissions.RegistrationSuccess.header"
        />
      </h2>
      <p
        className="text-center"
        dangerouslySetInnerHTML={{
          __html: translatedDescriptionElement,
        }}
      />
    </div>
  );
};

export default RegistrationSuccess;
