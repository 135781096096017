import BuddyCaseSection from './BuddyCaseSection';
import { useIntl } from 'react-intl';
import SVG from 'react-inlinesvg';
import { format } from 'date-fns';
import { useTimelineDetailsDateRangeObject } from 'store/reducerHooks';

import todaySqueezesIcon from 'assets/images/buddy_case/today_squeezes_icon.svg';
import BuddyCaseTodaySqueezesContent from './BuddyCaseTodaySqueezesContent';

type BuddyCaseTodaySqueezesProps = {
  timelineId: string;
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
};

const BuddyCaseTodaySqueezes = ({
  timelineId,
  selectedDate,
  setSelectedDate,
}: BuddyCaseTodaySqueezesProps) => {
  const intl = useIntl();
  const dateRangeObject = useTimelineDetailsDateRangeObject();

  const from = dateRangeObject?.from
    ? format(dateRangeObject?.from, 'EEE, dd.MM.yyyy')
    : '';
  const to = dateRangeObject?.to
    ? format(dateRangeObject?.to, 'EEE, dd.MM.yyyy')
    : '';

  const additional =
    from && to ? (
      <span className="BuddyCaseSection__additional">{`${from} - ${to}`}</span>
    ) : undefined;

  return (
    <BuddyCaseSection
      icon={<SVG src={todaySqueezesIcon} />}
      title={intl.formatMessage({ id: 'BuddyCase.yourProgress' })}
      additional={additional}
      content={
        <BuddyCaseTodaySqueezesContent
          timelineId={timelineId}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
        />
      }
    />
  );
};

export default BuddyCaseTodaySqueezes;
