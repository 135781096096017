import React from 'react';

import { FormattedMessage } from 'react-intl';
import { Button, Label } from 'reactstrap';
import {
  IOrganizationUserSubmission,
  SubmissionStatus,
} from 'interfaces/submissions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCheck, faUserTimes } from '@fortawesome/pro-light-svg-icons';

type StatusSummaryProps = {
  data: IOrganizationUserSubmission[];
};

const StatusSummary = ({ data }: StatusSummaryProps) => {
  const total = data?.length ?? 0;

  const acceptedNumber =
    data?.filter(
      (userSubmission) => userSubmission.status === SubmissionStatus.APPROVED
    ).length ?? 0;

  const rejectedNumber =
    data?.filter(
      (userSubmission) => userSubmission.status === SubmissionStatus.REJECTED
    ).length ?? 0;

  return (
    <div className="ProjectUsersList__status">
      <Label className="ProjectUsersList__status__label">
        <FormattedMessage id="Projects.usersList.status" />
      </Label>
      <div className="ProjectUsersList__status__status-wrapper gap-2">
        <Button color="success" outline>
          <FontAwesomeIcon icon={faUserCheck} /> {acceptedNumber} / {total}
        </Button>
        <Button color="danger" outline>
          <FontAwesomeIcon icon={faUserTimes} /> {rejectedNumber}
        </Button>
      </div>
    </div>
  );
};

export default StatusSummary;
