import React from 'react';
import { useQueries } from '../../hooks/useQueries';
import { useQueriesLoading } from '../../../../../../../../hooks/queries/useQueriesLoading';
import TrendsTabPlaceholder from '../TrendsTabPlaceholder';
import { TrendsChartGenericDataQueries } from '../../../../../../../../components/TrendsChart/types';

type TrendsTabQueriesWrapperProps = {
  children: (props: TrendsChartGenericDataQueries) => JSX.Element;
};

const TrendsTabQueriesWrapper = ({
  children,
}: TrendsTabQueriesWrapperProps) => {
  const queries = useQueries();

  const isLoading = useQueriesLoading([
    queries.exercisesQuery,
    queries.observationsQuery,
    queries.observationInTimelinesQuery,
    queries.questionAnswerHistoryQuery,
  ]);

  if (isLoading) return <TrendsTabPlaceholder />;

  return (
    <>
      {children({
        exercises: queries.exercisesQuery,
        questionAnswerHistory: queries.questionAnswerHistoryQuery,
        observations: queries.observationsQuery,
        observationInTimelines: queries.observationInTimelinesQuery,
      })}
    </>
  );
};

export default TrendsTabQueriesWrapper;
