import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { ICreateCaseCategory } from '../../../interfaces/caseCategories';
import { createCaseCategory } from '../../caseCategories';
import { IMutationProps } from '../../../interfaces/ui';

type useCreateCaseCategoryParams = {
  organizationId: string;
};

const useCreateCaseCategory = (
  params: useCreateCaseCategoryParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification<ICreateCaseCategory, void>({
    asyncApiFunction: async (data) => {
      await createCaseCategory(params.organizationId, data);
    },
    resetOnResult: true,
    onErrorArray: (errors) => {
      displayErrorArrayNotifications(
        errors,
        'MyOrganizations.caseCategories.create'
      );
    },
    onSuccess: () => {
      if (options.successFb) options.successFb();

      displaySuccessNotification(
        'MyOrganizations.caseCategories.create.success'
      );
    },
  });
};

export default useCreateCaseCategory;
