import React from 'react';
import { ITreatmentPlan } from '../../../../../../interfaces/conversations';
import SkeletonPlaceholderOnLoad from '../../../../../../components/SkeletonPlaceholderOnLoad';
import Skeleton from 'react-loading-skeleton';
import { FormattedMessage } from 'react-intl';
import { translateHtmlStr } from '../../../../../../helpers/utils/cultures';
import { AVAILABLE_CULTURES } from '../../../../../../components/CultureSelector/CultureSelector';
import TreatmentPlanListItemPhases from '../TreatmentPlanListItemPhases';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faEdit } from '@fortawesome/pro-light-svg-icons';
import { useTreatmentPlanListItem } from './hooks/useTreatmentPlanListItem';

type TreatmentPlanListItemProps = {
  plan: ITreatmentPlan;
  handleClone: () => void;
};

const TreatmentPlanListItem = ({
  plan,
  handleClone,
}: TreatmentPlanListItemProps) => {
  const { dataLoading, data, actions } = useTreatmentPlanListItem(
    plan,
    handleClone
  );

  return (
    <tr key={plan.id}>
      <td valign="middle">
        <SkeletonPlaceholderOnLoad
          isLoading={dataLoading}
          placeholder={<Skeleton height={20} width={100} />}
        >
          <b>{data.planName}</b>
        </SkeletonPlaceholderOnLoad>
      </td>
      <td valign="middle">
        <SkeletonPlaceholderOnLoad
          isLoading={dataLoading}
          placeholder={<Skeleton height={20} width={200} count={4} />}
        >
          <div className="d-flex flex-column gap-2">
            {data.projectsAssigned.length === 0 ? (
              <FormattedMessage id="TreatmentPlans.TreatmentPlansList.none" />
            ) : (
              <>
                {data.projectsAssigned.map((project) => (
                  <span key={project.id}>
                    {translateHtmlStr(
                      project.title,
                      AVAILABLE_CULTURES.EN,
                      false
                    )}
                  </span>
                ))}
              </>
            )}
          </div>
        </SkeletonPlaceholderOnLoad>
      </td>
      <td valign="middle">
        <SkeletonPlaceholderOnLoad
          isLoading={dataLoading}
          placeholder={<Skeleton height={20} width={200} count={4} />}
        >
          <TreatmentPlanListItemPhases
            phases={plan.phases}
            renderedPhasesCount={3}
          />
        </SkeletonPlaceholderOnLoad>
      </td>
      <td valign="middle" className="d-flex align-items-center gap-2">
        <Button
          color="primary"
          outline={true}
          onClick={actions.cloneTreatmentPlan}
        >
          <FontAwesomeIcon icon={faCopy} />
        </Button>
        <Button
          color="primary"
          outline={true}
          onClick={actions.navigateToTreatmentPlan}
        >
          <FontAwesomeIcon icon={faEdit} />
        </Button>
      </td>
    </tr>
  );
};

export default TreatmentPlanListItem;
