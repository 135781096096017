import { ChartsData, TrendsChartData } from '../types';
import { TemplateItemType } from '../../../interfaces/change';
import { addDays } from 'date-fns';
import { useMemo } from 'react';
import {
  getDateOnlyExerciseSessions,
  groupByTimeStart,
  sumOfDuration,
} from '../../../helpers/exercises';
import { UseQueryResult } from 'react-query';
import { IBaseExerciseSession } from '../../../interfaces/exerciseSessions';
import { StrictDateRange } from '../../../interfaces/dates';
import { assignEmptyDates, sortByDates } from '../helpers/common';

export const useExerciseTime = (
  enabled: boolean,
  exercisesQuery: UseQueryResult<IBaseExerciseSession[]>,
  dateRange: StrictDateRange
): TrendsChartData<string | number> => {
  return useMemo(() => {
    if (!enabled) return { type: TemplateItemType.ExerciseTime, data: [] };

    const exercises = exercisesQuery.data ?? [];

    let dates = exercises.map((p) => new Date(p.time_start));
    dates.sort((a, b) => a.getTime() - b.getTime());

    if (dates.length === 0)
      return { type: TemplateItemType.ExerciseTime, data: [] };

    const dateFrom = dates[0];
    const dateTo = dates[dates.length - 1];

    const dateOnlyExerciseSessions = getDateOnlyExerciseSessions(exercises);
    const groupedByTimeStart = groupByTimeStart(dateOnlyExerciseSessions);

    let _data: ChartsData<string | number>[] = [];
    let iterationDate: Date = dateFrom;

    while (iterationDate <= dateTo) {
      iterationDate.setHours(0, 0, 0, 0);
      const iterationDateString = iterationDate.toISOString();

      const dateExist = Object.hasOwn(groupedByTimeStart, iterationDateString);

      _data.push({
        name: iterationDateString,
        [TemplateItemType.ExerciseTime]: dateExist
          ? sumOfDuration(groupedByTimeStart[iterationDateString])
          : 0,
      });

      iterationDate = addDays(iterationDate, 1);
    }

    _data = assignEmptyDates(_data, dateRange);
    _data = sortByDates(_data);

    return { type: TemplateItemType.ExerciseTime, data: _data };
  }, [dateRange, enabled, exercisesQuery.data]);
};
