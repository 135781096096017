import { useInitQuery } from '../../../hooks/queries/useInitQuery';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useCommonData } from './useCommonData';

export const useBreadcrumbs = () => {
  const { organization } = useCommonData();
  useInitQuery(organization);

  const breadcrumbs = useMemo(
    () => [
      {
        text: <FormattedMessage id="Education.organization" />,
        link: null,
        key: 'organization',
      },
      {
        text: organization.data?.name ?? '',
        link: `/my-organizations/${organization.data?.id}`,
        key: 'organization-name',
      },
      {
        text: <FormattedMessage id="Education.education" />,
        link: null,
        key: 'education',
      },
    ],
    [organization.data?.id, organization.data?.name]
  );

  return {
    breadcrumbs,
    isLoading: organization.isLoading || organization.isRefetching,
  };
};
