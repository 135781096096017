import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { createAccessProfile } from '../../accessProfiles';
import { UserRoles } from '../../../interfaces/membership';
import { IMutationProps } from '../../../interfaces/ui';

export type CreateAccessProfileBody = {
  name: string;
  roles: UserRoles[];
};

type useCreateAccessProfileParams = {
  organizationId: string;
};

export const useCreateAccessProfile = (
  params: useCreateAccessProfileParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (body: CreateAccessProfileBody) => {
      await createAccessProfile(params.organizationId, body);
    },
    onSuccess: () => {
      if (options.successFb) options.successFb();
    },
    onErrorArray: (_) => {
      if (options.errorFb) options.errorFb();
    },
    resetOnResult: true,
  });
};
