import { Typography } from '@mui/material';
import { FormikProps } from 'formik';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { CultureHelpers } from '../../../../../../../../../../helpers/cultures';
import { wrapWithSequenceIdentified } from '../../../../../../../../../../helpers/utils/data';
import { IHelpCategory } from '../../../../../../../../../../interfaces/help';
import { HelpCentreFormInitialValues } from '../../../../forms/HelpCentreForm/hooks/useForm';
import { useOtherTranslation } from '../../hooks/useOtherTranslation';
import ContactSelector from './components/ContactSelector';
import HelpCategoryItem from './components/HelpCategoryItem';
import OtherItem from './components/OtherItem';
import ProblemDescription from './components/ProblemDescription';
import ProblemHint from './components/ProblemHint';

type HelpCategoryTabProps = {
  category: IHelpCategory;
  form: FormikProps<HelpCentreFormInitialValues>;
};

const HelpCategoryTab = ({ category, form }: HelpCategoryTabProps) => {
  const otherTranslation = useOtherTranslation();

  const getItemForTopic = useCallback(
    (topic: string) => {
      return category.items.find(
        (p) =>
          CultureHelpers.getValueAccordingToCulture(
            p.name_translations,
            form.initialValues.culture
          ) === topic
      );
    },
    [category.items, form.initialValues.culture]
  );

  return (
    <div className="d-flex gap-4 flex-nowrap pt-4">
      <div className="w-25">
        <Typography variant="body1" className="pb-3">
          <FormattedMessage id="Help.WhatDoYouNeedAssistanceWith" />
        </Typography>
        <div className="d-flex flex-column w-100 gap-3">
          {wrapWithSequenceIdentified(category.items).map((item) => (
            <HelpCategoryItem key={item.sequence} form={form} item={item} />
          ))}
          <OtherItem form={form} />
        </div>
      </div>
      {form.values.topic && (
        <div className="w-75">
          <div className="d-flex flex-column gap-4">
            {form.values.topic === otherTranslation ? (
              <ProblemDescription form={form} />
            ) : (
              <ProblemHint
                item={getItemForTopic(form.values.topic)}
                form={form}
              />
            )}
            <ContactSelector form={form} />
          </div>
        </div>
      )}
    </div>
  );
};

export default HelpCategoryTab;
