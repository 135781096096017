import { useTranslations } from '../../../../../../../../../hooks/useTranslations';
import { useCallback } from 'react';
import { secondsToDurationString } from '../../../../../../../../../helpers/dates';

export const useFormatters = () => {
  const { attachPrefix, wrapWithIntl } = useTranslations(
    'Cases.CaseDetails.AwareCase.IntensityTimeline'
  );

  const nameFormatter = useCallback(
    (name: string) => {
      return wrapWithIntl(attachPrefix(name));
    },
    [attachPrefix, wrapWithIntl]
  );

  const valueFormatter = useCallback((value: number, name: string) => {
    if (name === 'time') return secondsToDurationString(value / 1000);
    return value ? value.toFixed(2) : '';
  }, []);

  return { nameFormatter, valueFormatter };
};
