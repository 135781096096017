import useOrganizationContextUpdateSubmissionAssessmentForm from 'api/mutations/assessmentForms/forms/useOrganizationContextUpdateSubmissionAssessmentForm';
import useUserContextUpdateSubmissionAssessmentForm from 'api/mutations/assessmentForms/forms/useUserContextUpdateSubmissionAssessmentForm';
import useProjectAssessmentForms from 'api/queries/submissions/useProjectAssessmentForms';
import classNames from 'classnames';
import LoadingButton from 'components/LoadingButton';
import QuestionForm from 'components/QuestionForm';
import { Form, Formik } from 'formik';

//helpers
import {
  checkIfAnswersAreEmpty,
  createFormObjectToSent,
  returnInitialValuesFromAssessmentForm,
} from 'helpers/utils/assessmentForms';

//helpers
import useMobile from 'hooks/useBreakpoint';
import useStepInForm from 'hooks/useStepInForm';
import useUserPathBasedBrowsingContext from 'hooks/useUserPathBasedBrowsingContext';

//interfaces
import { SubmissionAssessmentModalModes } from 'interfaces/submissions';
import { AssessmentFormValues } from 'interfaces/ui';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

//components
import ProjectAssessmentCompletedModal from './ProjectAssessmentCompletedModal';

type UsersAnswerModalProps = {
  closeModal: () => void;
  mode: SubmissionAssessmentModalModes;
  userId: string;
  submissionId: string;
  sequence: number;
  selectedDate: Date;
  fetchEnabled?: boolean;
  changeFormSequence?: (seq: number) => void;
};

const ProjectAssessmentModal = ({
  closeModal,
  selectedDate,
  sequence,
  submissionId,
  userId,
  fetchEnabled = false,
  mode,
  changeFormSequence,
}: UsersAnswerModalProps) => {
  const [assessmentCompleted, setAssessmentCompleted] = useState(false);

  const { organizationId, caseId, isOrganizationContext } =
    useUserPathBasedBrowsingContext();

  const isMobile = useMobile();

  let { data, refetch } = useProjectAssessmentForms({
    params: { userId, submissionId },
    options: { enabled: fetchEnabled },
  });

  const assessmentFormData = data?.find(
    (assessment, index) => index === sequence
  );

  const { decreaseStep, increaseStep, step, setStep, lastSetStepAction } =
    useStepInForm(assessmentFormData?.questions?.length);

  const initialFormData =
    returnInitialValuesFromAssessmentForm(assessmentFormData);

  const onCloseModal = () => {
    closeModal();
  };

  const onMutationSuccess = async () => {
    await refetch();
    onCloseModal();
  };

  const handleAssessmentCompleted = () => {
    setAssessmentCompleted(true);
  };

  const userContextUpdateAssessmentFormMutation =
    useUserContextUpdateSubmissionAssessmentForm(
      {
        userId: userId,
        submissionId: submissionId,
        date: selectedDate,
        sequence: assessmentFormData?.sequence as number,
      },
      {
        successFb: handleAssessmentCompleted,
      }
    );

  const organizationContextUpdateAssessmentFormMutation =
    useOrganizationContextUpdateSubmissionAssessmentForm(
      {
        organizationId: organizationId ?? '',
        caseId: caseId ?? '',
        submissionId: submissionId,
        date: selectedDate,
        sequence: assessmentFormData?.sequence as number,
      },
      {
        successFb: handleAssessmentCompleted,
      }
    );

  const updateAssessmentFormMutation = isOrganizationContext
    ? organizationContextUpdateAssessmentFormMutation
    : userContextUpdateAssessmentFormMutation;

  const onSubmit = (values: typeof initialFormData) => {
    if (assessmentFormData) {
      const questions = assessmentFormData.questions;
      const assessmentBody = createFormObjectToSent(
        values,
        questions,
        assessmentFormData
      );

      updateAssessmentFormMutation.mutate({
        code: assessmentFormData.code,
        title: assessmentFormData.title,
        assessmentBody,
      });
    }
  };

  const renderedAssessmentNames = data?.map((assessment, index) => {
    const onNameClick = () => {
      if (changeFormSequence) {
        changeFormSequence(index);
      }
    };

    return (
      <>
        <div
          //TODO: remove inline style
          style={{ cursor: 'pointer' }}
          onClick={onNameClick}
          className={classNames('AssessmentFormSelector__groups__item', {
            'AssessmentFormSelector__groups__item--toggled': index === sequence,
          })}
        >
          {assessment.title}
        </div>
      </>
    );
  });

  const renderFooter = (
    submitForm: () => void,
    isLoading: boolean,
    values: AssessmentFormValues
  ) => {
    if (isMobile) return renderMobileFooter(submitForm, isLoading, values);

    return (
      <>
        <Button outline color="primary" onClick={onCloseModal}>
          <FormattedMessage id="CaseCard.AssessmentForms.cancel" />
        </Button>
        <LoadingButton
          color="primary"
          onClick={submitForm}
          disabled={checkIfAnswersAreEmpty(values)}
          isLoading={isLoading}
        >
          <FormattedMessage id="CaseCard.AssessmentForms.saveChanges" />
        </LoadingButton>
      </>
    );
  };

  //TODO: Add footer for doctor approve/reject
  const renderMobileFooter = (
    submitForm: () => void,
    isLoading: boolean,
    values: AssessmentFormValues
  ) => {
    return (
      <>
        {step === 0 ? (
          <Button outline color="primary" onClick={onCloseModal}>
            <FormattedMessage id="CaseCard.AssessmentForms.cancel" />
          </Button>
        ) : (
          <Button outline color="primary" onClick={decreaseStep}>
            <FormattedMessage id="CaseCard.AssessmentForms.prevQuestion" />
          </Button>
        )}
        {assessmentFormData &&
        step === assessmentFormData.questions.length - 1 ? (
          <>
            <LoadingButton
              color="primary"
              onClick={submitForm}
              disabled={checkIfAnswersAreEmpty(values)}
              isLoading={isLoading}
            >
              <FormattedMessage id="CaseCard.AssessmentForms.saveChanges" />
            </LoadingButton>
          </>
        ) : (
          <>
            <Button color="primary" onClick={increaseStep}>
              <FormattedMessage id="CaseCard.AssessmentForms.nextQuestion" />
            </Button>
          </>
        )}
      </>
    );
  };

  const isUser = mode === SubmissionAssessmentModalModes.USER;

  if (assessmentCompleted) {
    return (
      <ProjectAssessmentCompletedModal
        isOpen={assessmentCompleted}
        toggle={onMutationSuccess}
        title={assessmentFormData?.title}
      />
    );
  }

  return (
    <Modal
      size="xl"
      isOpen={!!data}
      toggle={onCloseModal}
      className="QuestionModal__modal"
    >
      <ModalHeader toggle={onCloseModal}>
        {assessmentFormData?.title}
      </ModalHeader>
      <Formik
        enableReinitialize={true}
        initialValues={initialFormData}
        onSubmit={onSubmit}
      >
        {({ values, setValues, submitForm }) => (
          <>
            <ModalBody>
              {!isUser && renderedAssessmentNames}
              <Form className="QuestionModal__modal__form">
                <div className="QuestionModal__modal__form-content">
                  <QuestionForm
                    canEdit={true}
                    isMobile={isMobile}
                    values={values}
                    setValues={setValues}
                    form={assessmentFormData}
                    step={step}
                    setStep={setStep}
                    lastSetStepAction={lastSetStepAction}
                    canDisplayQuestionIndicator={
                      assessmentFormData !== undefined
                        ? assessmentFormData.showProgressIndicator
                        : false
                    }
                  />
                </div>
              </Form>
            </ModalBody>
            <ModalFooter>
              {isUser
                ? renderFooter(
                    submitForm,
                    updateAssessmentFormMutation.isLoading,
                    values
                  )
                : null}
            </ModalFooter>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default ProjectAssessmentModal;
