import { getOrganizationMembersWithPaging } from 'api/organization';
import QueryKeys from 'api/queries/queryKeys';
import {
  convertIntoObjectWithPageInfo,
  returnAllDataFromResponse,
} from 'helpers/utils/reactQueryHelpers';
import { IPersonality } from 'interfaces/personalities';
import { OrganizationQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { IError, IInfiniteResult } from 'interfaces/response';
import { useInfiniteQuery } from 'react-query';

const MEMBER_PAGE_SIZE = 1000;

function useInfiniteOrganizationMembers({
  params,
  options,
}: IQueryRequest<OrganizationQueryParams>) {
  const { organizationId } = params;
  const response = useInfiniteQuery<IInfiniteResult<IPersonality[]>, IError>({
    ...options,
    queryKey: [QueryKeys.getOrganizationMemberList, organizationId],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getOrganizationMembersWithPaging(organizationId, {
        page: pageParam,
        page_size: MEMBER_PAGE_SIZE,
        search: '',
      });

      return convertIntoObjectWithPageInfo<IPersonality[]>(data);
    },
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage;
    },
    getPreviousPageParam: (firstPage) => firstPage.previousPage,
  });

  return {
    ...response,
    members: returnAllDataFromResponse<IPersonality>(response),
  };
}

export default useInfiniteOrganizationMembers;
