import { AssessmentFormIdentification } from 'interfaces/assessmentForms';
import { TimeRange } from 'interfaces/timeline';
import { SelectionTrendVisualizationTemplateInfo } from 'interfaces/trendVisualizations';
import { TimelineTabsValues } from 'interfaces/ui';
import { TimelineDetailsDatesAction } from './reducer';

export const setCustomDateRange = (dateRange: { from: Date; to: Date }) => {
  return {
    type: TimelineDetailsDatesAction.TIMELINE_DETAILS_SET_CUSTOM_DATE_RANGE,
    dateRange,
  };
};

export const setCasePeriodDateRange = (dateRange: { from: Date; to: Date }) => {
  return {
    type: TimelineDetailsDatesAction.TIMELINE_DETAILS_SET_CASE_PERIOD_DATE_RANGE,
    dateRange,
  };
};

export const setTimelineDetailsTimeRange = (
  timeRange: TimeRange,
  relativeToDate: Date | null
) => {
  return {
    type: TimelineDetailsDatesAction.TIMELINE_DETAILS_SET_PREDEFINED_DATE_RANGE,
    timeRange,
    relativeToDate,
  };
};

export const nextPeriod = () => {
  return {
    type: TimelineDetailsDatesAction.TIMELINE_DETAILS_NEXT_PERIOD,
  };
};

export const previousPeriod = () => {
  return {
    type: TimelineDetailsDatesAction.TIMELINE_DETAILS_PREVIOUS_PERIOD,
  };
};

export const changeTab = (tabName: TimelineTabsValues) => {
  return {
    type: TimelineDetailsDatesAction.TIMELINE_DETAILS_TAB_CHANGE,
    tab: tabName,
  };
};

export const changeSelectedDate = (selectedDate: Date | null) => {
  return {
    type: TimelineDetailsDatesAction.TIMELINE_DETAILS_SET_SELECTED_DATE,
    selectedDate,
  };
};

export const setAssessmentFormIdentification = (
  identification: AssessmentFormIdentification | null
) => {
  return {
    type: TimelineDetailsDatesAction.TIMELINE_DETAILS_SET_ASSESSMENT_FORM_IDENTIFICATION,
    identification,
  };
};

export const setDayByDayFullSize = () => {
  return {
    type: TimelineDetailsDatesAction.TIMELINE_DETAILS_SET_FULL_SIZE_DAY_BY_DAY,
  };
};

export const setSelectedTrendVisualizationTemplate = (
  template: SelectionTrendVisualizationTemplateInfo | null
) => {
  return {
    type: TimelineDetailsDatesAction.TIMELINE_DETAILS_SET_SELECTED_TREND_VISUALIZATION_TEMPLATE,
    template: template,
  };
};
