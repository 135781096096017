import { useCallback } from 'react';
import useRequiredParams from '../../../../../hooks/useRequiredParams';
import { ICategory } from '../../../../../interfaces/cases';

export const useGetTreatmentPlanName = () => {
  const { treatmentPlanId } = useRequiredParams<{
    treatmentPlanId: string;
  }>(['treatmentPlanId']);

  return useCallback(
    (categories: ICategory[]) => {
      return categories.find((p) => p.id === treatmentPlanId)?.name_en ?? '';
    },
    [treatmentPlanId]
  );
};
