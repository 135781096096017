import { deleteOrganizationFunctionality } from 'api/functionalities';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

function useDeleteOrganizationFunctionality(
  organizationId: string,
  successFb?: () => void
) {
  return useMutationWithResultNotification({
    asyncApiFunction: async (functionalityId: string) => {
      await deleteOrganizationFunctionality(organizationId, functionalityId);
    },
    onSuccess: () => {
      successFb && successFb();
    },
    resetOnResult: true,
  });
}

export default useDeleteOrganizationFunctionality;
