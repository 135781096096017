import React from 'react';
import { Slide, toast, ToastContainer, ToastOptions } from 'react-toastify';
import { FormattedMessage } from 'react-intl';
import { IErrorDefinition } from 'interfaces/response';
import { isValidJson } from '../../helpers/utils/strings';
import Cookies from 'js-cookie';
import { ViewTheme } from '../../interfaces/ui';

export const displaySuccessNotification = (
  value: JSX.Element | string,
  options: ToastOptions = {}
) => {
  const cookieValue = Cookies.get('theme');
  const viewTheme = cookieValue as ViewTheme;

  if (viewTheme === ViewTheme.Dark) options.theme = 'dark';

  window.dispatchEvent(
    new CustomEvent('notification-success', {
      detail: {
        value,
      },
    })
  );

  if (typeof value === 'string') {
    return toast.success(
      <FormattedMessage id={value} defaultMessage={value} />,
      {
        ...options,
      }
    );
  }
  return toast.success(value, {
    ...options,
  });
};

export const displayErrorNotification = (
  value: JSX.Element | string,
  values?: any,
  options: ToastOptions = {}
) => {
  const cookieValue = Cookies.get('theme');
  const viewTheme = cookieValue as ViewTheme;

  if (viewTheme === ViewTheme.Dark) options.theme = 'dark';

  window.dispatchEvent(
    new CustomEvent('notification-error', {
      detail: {
        value,
      },
    })
  );

  if (typeof value === 'string') {
    return toast.error(
      <FormattedMessage id={value} values={values} defaultMessage={value} />,
      {
        ...options,
      }
    );
  }
  return toast.error(value, {
    ...options,
  });
};

export const displayNotification = (
  value: JSX.Element | string,
  options: ToastOptions = {}
) => {
  const cookieValue = Cookies.get('theme');
  const viewTheme = cookieValue as ViewTheme;

  if (viewTheme === ViewTheme.Dark) options.theme = 'dark';

  window.dispatchEvent(
    new CustomEvent('notification', {
      detail: {
        value,
      },
    })
  );

  if (typeof value === 'string') {
    return toast(<FormattedMessage id={value} defaultMessage={value} />, {
      ...options,
    });
  }
  return toast(value, {
    ...options,
  });
};

export const displayErrorArrayNotifications = (
  errorArray: IErrorDefinition[],
  translationRoot: string,
  silent: boolean = false
) => {
  if (silent) return;
  const filteredErrorArray = errorArray.filter((item) => !!item?.code);

  if (filteredErrorArray.length === 0) {
    return displayErrorNotification(`Errors.default`);
  } else {
    errorArray.map((singleError) => {
      return displayErrorNotification(`${translationRoot}.${singleError.code}`);
    });
  }
};

const assignErrorMessageFromJson = (
  errorMessage: string,
  singleErrorItem: string
) => {
  let errorObject = JSON.parse(singleErrorItem);

  if (Object.hasOwn(errorObject, 'errors')) {
    errorMessage =
      (Object.values(errorObject['errors']).at(0) as string) ?? singleErrorItem;
    return errorMessage;
  }

  if (Object.hasOwn(errorObject, 'title')) {
    errorMessage = errorObject['title'] as string;
    return errorMessage;
  }

  return errorMessage;
};

export const displayErrorArrayNotificationsWithoutTranslations = (
  errorArray: IErrorDefinition[]
) => {
  const filteredErrorArray = errorArray.filter((item) => !!item?.code);

  if (filteredErrorArray.length === 0)
    return displayErrorNotification(`Errors.default`);

  let errorLine: string = '';

  for (const singleError of errorArray) {
    if (singleError.reasons.length === 0) continue;

    const singleErrorItem = singleError.reasons[singleError.reasons.length - 1];

    if (isValidJson(singleErrorItem))
      errorLine = assignErrorMessageFromJson(errorLine, singleErrorItem);
    else errorLine = singleErrorItem;
  }

  return displayErrorNotification(errorLine.toString() ?? 'Errors.default');
};

const Notifications = () => (
  <ToastContainer
    closeOnClick
    hideProgressBar
    autoClose={5000}
    newestOnTop
    position="top-right"
    transition={Slide}
    bodyClassName="mx-1"
  />
);

export default Notifications;
