import useOrganizationUserAccessProfiles from '../../../../../../../../../api/queries/accessProfiles/useOrganizationUserAccessProfiles';
import useDashboardReportSchedules from '../../../../../../../../../api/queries/dashboards/useDashboardReportSchedules';
import useDashboardTemplates from '../../../../../../../../../api/queries/dashboards/useDashboardTemplates';
import useOrganizationMembers from '../../../../../../../../../api/queries/organizations/useOrganizationMembers';
import { useOrganizationPathParams } from '../../../../../../../../../hooks/paths/useOrganizationPathParams';

export const useQueries = () => {
  const { organizationId } = useOrganizationPathParams();

  const dashboardReportSchedules = useDashboardReportSchedules({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const dashboardTemplates = useDashboardTemplates({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const accessProfiles = useOrganizationUserAccessProfiles({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const members = useOrganizationMembers({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  return {
    dashboardReportSchedules,
    dashboardTemplates,
    accessProfiles,
    members,
  };
};
