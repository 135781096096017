import { useCallback, useMemo } from 'react';
import { differenceInMilliseconds } from 'date-fns';

export const useMsDiffs = (startDate: string, endDate: string) => {
  const getMsDiff = useCallback((start: string, end: string) => {
    return differenceInMilliseconds(new Date(end), new Date(start));
  }, []);

  const baseMsDiff = useMemo(() => {
    return getMsDiff(startDate, endDate);
  }, [endDate, getMsDiff, startDate]);

  return { getMsDiff, baseMsDiff };
};
