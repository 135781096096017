import { TemplateItemType } from '../../../interfaces/change';
import { useQueriesFetched } from '../../../hooks/queries/useQueriesFetched';
import { useAllObservations } from './useAllObservations';
import { useExerciseObservations } from './useExerciseObservations';
import { useNonExerciseObservations } from './useNonExerciseObservations';
import { useQuestions } from './useQuestions';
import { useExerciseTime } from './useExerciseTime';
import { TrendVisualization } from '../../../interfaces/trendVisualizations';
import { TrendsChartGenericDataQueries } from '../types';
import { StrictDateRange } from '../../../interfaces/dates';

const visualizationInclude = (
  visualization: TrendVisualization,
  type: TemplateItemType
) => {
  const visualizationTypes = visualization.elements.map((p) => p.type);
  return visualizationTypes.includes(type);
};

const visualizationIncludes = (
  visualization: TrendVisualization,
  types: TemplateItemType[]
) => {
  const visualizationTypes = visualization.elements.map((p) => p.type);
  return types.some((p) => visualizationTypes.includes(p));
};

export const useTrendsChartGenericData = (
  visualization: TrendVisualization,
  queries: TrendsChartGenericDataQueries,
  dateRange: StrictDateRange
) => {
  const isFetched = useQueriesFetched([
    queries.exercises,
    queries.observations,
    queries.observationInTimelines,
    queries.questionAnswerHistory,
  ]);

  const observationsConstraint = visualizationIncludes(visualization, [
    TemplateItemType.AllObservations,
    TemplateItemType.ExerciseObservations,
    TemplateItemType.NonExerciseObservations,
  ]);

  const allObservationsData = useAllObservations(
    isFetched && observationsConstraint,
    queries.observations
  );

  const exerciseObservationsData = useExerciseObservations(
    isFetched && observationsConstraint,
    allObservationsData,
    queries.exercises,
    queries.observationInTimelines
  );

  const nonExerciseObservationsData = useNonExerciseObservations(
    isFetched && observationsConstraint,
    allObservationsData,
    exerciseObservationsData
  );

  const questionsData = useQuestions(
    isFetched &&
      visualizationInclude(visualization, TemplateItemType.Questions),
    queries.questionAnswerHistory,
    dateRange
  );

  const exerciseTimeData = useExerciseTime(
    isFetched &&
      visualizationInclude(visualization, TemplateItemType.ExerciseTime),
    queries.exercises,
    dateRange
  );

  return {
    data: [
      allObservationsData,
      exerciseObservationsData,
      nonExerciseObservationsData,
      questionsData,
      exerciseTimeData,
    ],
    isLoading: !isFetched,
  };
};
