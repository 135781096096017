import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FormGroup } from 'reactstrap';
import { Field, Form, Formik } from 'formik';

//components
import TabLayout from './TabLayout';
import LoadingButton from 'components/LoadingButton';
import CommunicationChannelOptions from 'components/CommunicationChannelOptions';

//images
import notificationIcon from 'assets/images/notification_icon.svg';
import { CommunicationChannels } from 'interfaces/enums';

const initialValues: {
  preferred: CommunicationChannels[];
  additional: CommunicationChannels[];
} = {
  preferred: [],
  additional: [CommunicationChannels.Email],
};

const NotificationsTab = () => {
  const onSubmit = (values: typeof initialValues) => console.log(values);

  return (
    <TabLayout
      title="MyProfile.Tabs.notifications"
      imageSrc={notificationIcon}
      description={
        <FormattedMessage id="MyProfile.Notifications.notificationsDescription" />
      }
    >
      <>
        <div className="NotificationsHeader">
          <Formik initialValues={initialValues} onSubmit={onSubmit}>
            {({ isValid, submitForm }) => (
              <Form className="mt-4">
                <FormGroup
                  tag="fieldset"
                  className="MyAccount__notification__group"
                >
                  <legend>
                    <FormattedMessage id="MyProfile.Notifications.preferredCommunicationChannel" />
                  </legend>
                  <p className="MyAccount__notification__group__description">
                    <FormattedMessage id="MyProfile.Notifications.preferredCommunicationChannelDescription" />
                  </p>
                  <Field
                    required
                    aria-label="communicationChannel"
                    name="preferred"
                    type="select"
                    component={CommunicationChannelOptions}
                  />
                </FormGroup>
                <FormGroup
                  tag="fieldset"
                  className="MyAccount__notification__group"
                >
                  <legend>
                    <FormattedMessage id="MyProfile.Notifications.additionalCommunicationChannel" />
                  </legend>
                  <p className="MyAccount__notification__group__description">
                    <FormattedMessage id="MyProfile.Notifications.additionalCommunicationChannelDescription" />
                  </p>
                  <Field
                    required
                    aria-label="communicationChannel"
                    name="additional"
                    type="select"
                    requiredChannels={[CommunicationChannels.Email]}
                    component={CommunicationChannelOptions}
                  />
                </FormGroup>
                <div className="d-flex justify-content-end">
                  <LoadingButton
                    disabled={!isValid}
                    type="submit"
                    onClick={submitForm}
                  >
                    <FormattedMessage id="General.saveChanges" />
                  </LoadingButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </>
    </TabLayout>
  );
};

export default NotificationsTab;
