import React from 'react';
import Skeleton from 'react-loading-skeleton';

const SqueezeSummaryPlaceholder = () => {
  return (
    <div className="d-flex flex-column align-items-center">
      <Skeleton circle={true} height={350} width={350} />
      <div className=" mt-4 d-flex flex-wrap">
        {[...new Array(3)].map((item, index) => (
          <div
            key={`placeholder-description-${index}`}
            className="d-flex align-items-center w-100 mt-1"
          >
            <Skeleton circle width={20} height={20} className="me-2" />
            <Skeleton width={300} height={20} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SqueezeSummaryPlaceholder;
