import { IUpdateNotificationSchedule } from '../../../interfaces/notificationSchedules';
import { UserCaseQueryParams } from '../../../interfaces/queryParams';
import { IMutationRequest } from '../../../interfaces/request';
import { IErrorDefinition } from '../../../interfaces/response';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { updateNotificationSchedule } from '../../notificationSchedules';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

type UpdateNotificationScheduleQueryParams = UserCaseQueryParams & {
  scheduleId: string;
};

const useUpdateNotificationSchedule = ({
  params,
  options,
}: IMutationRequest<UpdateNotificationScheduleQueryParams>) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (updateSchedule: IUpdateNotificationSchedule) => {
      await updateNotificationSchedule(
        params.userId,
        params.caseId,
        params.scheduleId,
        updateSchedule
      );
    },
    onSuccess: () => {
      displaySuccessNotification(
        'Api.Mutations.NotificationSchedules.UpdateNotificationSchedule.success'
      );
      options.successFb?.();
    },
    onErrorArray: (errors: IErrorDefinition[]) => {
      displayErrorArrayNotifications(
        errors,
        'Api.Mutations.NotificationSchedules.UpdateNotificationSchedule.failure'
      );
      options.errorFb?.();
    },
    resetOnResult: true,
  });
};

export default useUpdateNotificationSchedule;
