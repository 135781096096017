import { postShareRequests } from 'api/cases';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

function useSendTimelineShareRequests(
  organizationId: string,
  successFb: () => void
) {
  return useMutationWithResultNotification({
    resetOnResult: true,
    asyncApiFunction: async (caseId: string) => {
      const { data } = await postShareRequests(organizationId, caseId);

      return data;
    },
    onSuccess: () => {
      successFb && successFb();
    },
  });
}

export default useSendTimelineShareRequests;
