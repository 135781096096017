import { OrganizationQueryParams } from '../../../interfaces/queryParams';
import { IQueryRequest } from '../../../interfaces/request';
import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getCaseProcedures } from '../../cases';

type CaseProceduresQueryParams = OrganizationQueryParams;

const useCaseProcedures = ({
  params,
  options,
}: IQueryRequest<CaseProceduresQueryParams>) => {
  return useQuery({
    ...options,
    queryKey: [QueryKeys.getCaseProcedures, params.organizationId],
    queryFn: async () => {
      const { data } = await getCaseProcedures(params.organizationId);
      return data.data;
    },
  });
};

export default useCaseProcedures;
