import { signOut } from 'api/membership';
import { getInvitationDetails } from 'api/patients';
import QueryKeys from 'api/queries/queryKeys';
import { deleteUserData } from 'helpers/utils/auth';
import { IInvitationDetails } from 'interfaces/personalities';
import { OrganizationQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { IError } from 'interfaces/response';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useDispatch } from 'react-redux';

async function asyncSilentLogout(successFb: () => void) {
  await signOut();
  successFb();
}

interface UseInvitationDetailsParams extends OrganizationQueryParams {
  invitationId: string;
}

function useInvitationDetails({
  params,
  options,
}: IQueryRequest<UseInvitationDetailsParams>) {
  const { organizationId, invitationId } = params;
  const dispatch = useDispatch();

  const response = useQuery<IInvitationDetails, IError, IInvitationDetails>({
    ...options,
    queryFn: async () => {
      const { data } = await getInvitationDetails(organizationId, invitationId);
      return data.data;
    },
    queryKey: [QueryKeys.getInvitationDetails, organizationId, invitationId],
  });

  useEffect(() => {
    if (
      !response.isFetching &&
      response.isError &&
      response.error.status === 403
    ) {
      asyncSilentLogout(() => {
        deleteUserData();
        response.refetch();
      });
    }
  }, [response, dispatch]);

  return response;
}

export default useInvitationDetails;
