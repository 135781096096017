import React, { createContext, useMemo } from 'react';
import TreatmentPlansPageHeader from './components/TreatmentPlansPageHeader';
import TreatmentPlansList from './components/TreatmentPlansList';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import useOpen from '../../hooks/useOpen';
import { BaseModalProps } from '../../interfaces/ui';
import { getEmptyModalContextValues } from '../../helpers/utils/ui';

export const AddPlanModalContext = createContext<BaseModalProps>(
  getEmptyModalContextValues()
);

const TreatmentPlans = () => {
  const {
    isOpen: isAddTreatmentPlanModalOpen,
    toggle: toggleAddTreatmentPlanModal,
  } = useOpen();

  const addPlanModalContextValues = useMemo(() => {
    return {
      isOpen: isAddTreatmentPlanModalOpen,
      toggle: toggleAddTreatmentPlanModal,
    };
  }, [isAddTreatmentPlanModalOpen, toggleAddTreatmentPlanModal]);

  return (
    <div>
      <div>
        <TreatmentPlansPageHeader
          actions={[
            {
              icon: faPlus,
              onAction: toggleAddTreatmentPlanModal,
            },
          ]}
        />
      </div>
      <div>
        <AddPlanModalContext.Provider value={addPlanModalContextValues}>
          <TreatmentPlansList />
        </AddPlanModalContext.Provider>
      </div>
    </div>
  );
};

export default TreatmentPlans;
