import { useOrganizationPathParams } from 'hooks/paths/useOrganizationPathParams';
import useEditOrganizationGrasp from 'api/mutations/grasps/useEditOrganizationGrasp';
import { useCallback } from 'react';

export const useHandleAccept = (id: string) => {
  const { organizationId } = useOrganizationPathParams();

  const { mutateAsync: editAsync } = useEditOrganizationGrasp(organizationId);

  return useCallback(
    async (value: string) => {
      await editAsync({
        graspId: id,
        patchData: [
          {
            path: '/name',
            value: value,
            op: 'replace',
          },
        ],
      });
    },
    [editAsync, id]
  );
};
