import { returnIconForQuestion } from 'helpers/utils/icons';
import {
  AssessmentCompletionStatus,
  AssessmentQuestion,
  AssessmentQuestionType,
  AssessmentStatus,
} from 'interfaces/assessmentForms';
import { Sentiments } from 'interfaces/timeline';
import { Col } from 'reactstrap';
import AssessmentFormQuestionStats from './AssessmentFormQuestionStats';
import StatusItem from './StatusItem';
import SVG from 'react-inlinesvg';
import { getUserId } from 'helpers/utils/auth';

type QuestionVisualizationProps = {
  question: AssessmentQuestion;
  toggled: boolean;
  index: number;
  questionsLength: number;
  completionStatus: AssessmentCompletionStatus;
  formCode: string;
  userId: string;
};

const MAXIMUM_ICON_LENGTH = 5;

const QuestionVisualization = ({
  question,
  toggled,
  index,
  completionStatus,
  formCode,
  questionsLength,
  userId,
}: QuestionVisualizationProps) => {
  let iconSrc = returnIconForQuestion(question.icon);

  let description = 'No data';
  let status: AssessmentStatus | Sentiments = AssessmentStatus.NOT_DEFINED;

  switch (question.type) {
    case AssessmentQuestionType.CLOSED_LIST:
      let answer = question.answers.find(
        (questionItem) => questionItem.is_selected
      );
      if (answer?.title) description = answer.title;
      if (answer?.sentiment)
        status = AssessmentStatus[answer.sentiment.toUpperCase()];
      break;
    case AssessmentQuestionType.RANGE:
      const rangeAnswer = question.answer?.numberValue;
      if (rangeAnswer != null) description = rangeAnswer.toString();
      status = AssessmentStatus.NOT_DEFINED;
      break;
    case AssessmentQuestionType.OPEN:
      if (question.answer?.textValue != null) {
        description =
          question.answer?.textValue.length < 30
            ? question.answer?.textValue
            : description.substring(0, 30) + ' (...)';
      }
      break;
  }

  const renderIcon = () => {
    if (index < MAXIMUM_ICON_LENGTH) {
      return (
        <div
          key={index}
          className={`StatusItem__icon StatusItem__icon--${status}`}
        >
          <SVG src={iconSrc} title={question.title} />
        </div>
      );
    } else if (index === MAXIMUM_ICON_LENGTH) {
      return (
        <div
          key={index}
          className={`StatusItem__icon StatusItem__icon--${completionStatus.toLowerCase()}`}
        >
          <span
            className={`StatusItem__more StatusItem__more--${completionStatus.toLowerCase()}`}
          >
            {`+${questionsLength - MAXIMUM_ICON_LENGTH}`}
          </span>
        </div>
      );
    }

    return null;
  };

  if (toggled) {
    return (
      <Col className="my-2" md={6} key={'column-assessment-' + index}>
        <StatusItem
          status={status}
          iconSrc={iconSrc}
          description={description}
          question={question}
          optionsComponent={
            <AssessmentFormQuestionStats
              formCode={formCode}
              question={question}
              key={'column-assessment-stats' + index}
              userId={userId ?? getUserId()}
            />
          }
        />
      </Col>
    );
  }

  return renderIcon();
};

export default QuestionVisualization;
