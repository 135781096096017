import { DashboardActionResolvers } from './actionResolvers';
import { DashboardActionTypes } from './actionTypes';
import { DashboardState } from './state';

const dashboardReducer = (
  state: DashboardState.DashboardStateType = DashboardState.InitialState,
  action: DashboardActionTypes.DashboardAction
) => {
  switch (action.type) {
    case DashboardActionTypes.DashboardActionType.ADD_TEMPLATE:
      return DashboardActionResolvers.AddTemplateResolver(state, action);
    case DashboardActionTypes.DashboardActionType.EDIT_TEMPLATE:
      return DashboardActionResolvers.EditTemplateResolver(state, action);
    default:
      return state;
  }
};

export default dashboardReducer;
