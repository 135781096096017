import React from 'react';
import DecisionModal from '../../../../../../components/DecisionModal';
import { useAddTreatmentPlanModal } from './hooks/useAddTreatmentPlanModal';

type AddTreatmentPlanModalProps = {
  isOpen: boolean;
  toggle: () => void;
};

const AddTreatmentPlanModal = ({
  isOpen,
  toggle,
}: AddTreatmentPlanModalProps) => {
  const { decisions, modalMessage } = useAddTreatmentPlanModal(toggle);

  return (
    <DecisionModal
      isOpen={isOpen}
      message={modalMessage}
      decisions={decisions}
      toggle={toggle}
    />
  );
};

export default AddTreatmentPlanModal;
