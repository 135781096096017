import useCaseEdit from 'api/mutations/cases/useCaseEdit';
import useOrganizationContextAssessmentFormsAssignments from 'api/queries/assessmentForms/assignments/useOrganizationContextAssessmentFormsAssignments';
import UseOrganizationAssessmentFormsTemplates from 'api/queries/assessmentForms/templates/useOrganizationAssessmentFormTemplates';

//helpers
import useCaseDetails from 'api/queries/cases/useCaseDetails';
import plusIcon from 'assets/images/plus.svg';
import editAssignmentsIcon from 'assets/images/timeline_details/edit_form.svg';

//images
import formsAssignmentsIcon from 'assets/images/timeline_details/forms_assignments_icon.svg';

//helpers
import classNames from 'classnames';
import useRequiredParams from 'hooks/useRequiredParams';

//interfaces
import { IPatchObject } from 'interfaces/request';
import {
  setFormsDayByDayViewRefetchRequest,
  setFormsDayDetailsViewRefetchRequest,
} from 'modules/Cases/store/actions';
import { useEffect, useState } from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';

import { useDispatch } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import { useTimelineDetailsSelectedDate } from 'store/reducerHooks';

//components
import FormsAssignmentsRow from './FormsAssignmentsRow';
import PatientResponseFrequencyRow from './PatientResponseFrequencyRow';

const MIN_FEEDBACK_FREQ_IF_NOT_ASSIGNED = 1;

type PatientSubjectiveDataProps = {
  readOnly: boolean;
  timelineId: string;
  shouldFetchCase?: boolean;
};

const PatientSubjectiveData = ({
  readOnly,
  timelineId,
  shouldFetchCase = false,
}: PatientSubjectiveDataProps) => {
  const { organizationId, caseId } = useRequiredParams<{
    organizationId: string;
    caseId: string;
  }>(['organizationId', 'caseId']);

  const { data: assignments, refetch } =
    useOrganizationContextAssessmentFormsAssignments({
      params: {
        organizationId,
        caseId,
        timelineId,
      },
    });
  const dispatch = useDispatch();

  const selectedDate = useTimelineDetailsSelectedDate();

  const assignmentsAndFormsRefetch = async () => {
    await refetch();

    if (selectedDate !== null) {
      dispatch(setFormsDayDetailsViewRefetchRequest(true));
      dispatch(setFormsDayByDayViewRefetchRequest(true));
    }
  };

  const { data: templates } = UseOrganizationAssessmentFormsTemplates({
    params: {
      allowedForCaseId: null,
      questionsIncludedInTrends: null,
      organizationId,
    },
  });

  const {
    data: caseDetails,
    refetch: caseRefetch,
    isFetched: isCaseDetailsFetched,
  } = useCaseDetails({
    params: { organizationId, caseId },
    options: { enabled: shouldFetchCase },
  });

  const { mutate: editCase } = useCaseEdit(
    {
      organizationId: organizationId,
      caseId: caseId,
    },
    {
      successFb: caseRefetch,
    }
  );

  const [addAssignmentMode, setAddAssignmentMode] = useState(false);

  const [feedbackFrequencyAssignmentMode, setFeedbackFrequencyAssignmentMode] =
    useState(false);

  const [
    synchronizationFrequencyAssignmentMode,
    setSynchronizationFrequencyAssignmentMode,
  ] = useState(false);

  const [IsCaseDetailsInitialized, setCaseDetailsInitialized] = useState(false);

  useEffect(() => {
    if (caseDetails) {
      setFeedbackFrequencyAssignmentMode(
        caseDetails.expected_feedback_frequency !== 0
      );

      setSynchronizationFrequencyAssignmentMode(
        caseDetails.expected_synchronization_frequency !== 0
      );

      setCaseDetailsInitialized(true);
    }
  }, [caseDetails]);

  const enableAddAssignmentMode = () => {
    setAddAssignmentMode(true);
  };

  const enableAssignFeedbackFrequency = () => {
    setFeedbackFrequencyAssignmentMode(true);
  };

  const disableAssignFeedbackFrequency = () => {
    setFeedbackFrequencyAssignmentMode(false);
  };

  const enableAssignSyncFrequency = () => {
    setSynchronizationFrequencyAssignmentMode(true);
  };

  const disableAssignSyncFrequency = () => {
    setSynchronizationFrequencyAssignmentMode(false);
  };

  const updateExpectedFeedbackFrequency = (frequency: number) => {
    const patchObject: IPatchObject[] = [
      {
        op: 'replace',
        path: `/expected_feedback_frequency`,
        value: frequency,
      },
    ];

    editCase(patchObject);
  };

  const updateExpectedSynchronizationFrequency = (frequency: number) => {
    const patchObject: IPatchObject[] = [
      {
        op: 'replace',
        path: `/expected_synchronization_frequency`,
        value: frequency,
      },
    ];

    editCase(patchObject);
  };

  return (
    <>
      <div className="cardContainer h100-above-xl">
        <div className="cardContainer__header">
          <div className="cardContainer__header__title">
            <img
              src={formsAssignmentsIcon}
              className="cardContainer__header__title__image"
              alt={formsAssignmentsIcon}
            />
            <FormattedMessage id="CaseCard.patientSubjectiveData" />
          </div>
          <div className="cardContainer__header__button-container">
            <Button
              color="primary"
              className="cardContainer__header__button-container__button"
              onClick={enableAddAssignmentMode}
            >
              <img src={editAssignmentsIcon} alt="Add form" />
            </Button>
            <div className="cardContainer__header__button-container__text">
              <FormattedMessage id="CaseCard.AssessmentForms.addForm" />
            </div>
          </div>
        </div>
        <div className="cardContainer__section">
          <div
            className={classNames({
              cardContainer__section__label: true,
              'mb-5': assignments && assignments.length > 0,
            })}
          >
            <FormattedMessage id="CaseCard.SubjectiveData.assignAssessmentForms" />
          </div>
          <div className="FormAssignments ms-3 mt-3">
            {caseDetails &&
              assignments?.map((assignment, idx) => (
                <FormsAssignmentsRow
                  key={assignment.id}
                  readOnly={readOnly}
                  assignment={assignment}
                  templates={templates ?? []}
                  showFieldsDescription={idx === 0}
                  setAddAssignmentMode={setAddAssignmentMode}
                  userId={caseDetails.participants[0].personality.user_id}
                  caseId={caseId}
                  organizationId={organizationId}
                  timelineId={timelineId}
                  refetch={assignmentsAndFormsRefetch}
                />
              ))}
            {addAssignmentMode && caseDetails && (
              <FormsAssignmentsRow
                readOnly={readOnly}
                assignment={undefined}
                setAddAssignmentMode={setAddAssignmentMode}
                userId={caseDetails.participants[0].personality.user_id}
                templates={templates ?? []}
                caseId={caseId}
                organizationId={organizationId}
                timelineId={timelineId}
                refetch={assignmentsAndFormsRefetch}
                showFieldsDescription={true}
              />
            )}
            <Row className="mt-3">
              <Col md={12}>
                <Button
                  color="primary"
                  className="FormAssignments__add-assignment DashedPrimaryButton"
                  onClick={enableAddAssignmentMode}
                  block
                  disabled={readOnly}
                >
                  <SVG src={plusIcon} title="add" />
                  <FormattedMessage id="CaseCard.FormsAssignments.selectAnotherForm" />
                </Button>
              </Col>
            </Row>
          </div>
        </div>
        <div className="cardContainer__section mt-5">
          <label
            className="cardContainer__section__label"
            htmlFor="feedbackFrequency"
          >
            <FormattedMessage id="CaseCard.SubjectiveData.feedbackFrequency.header" />
          </label>
          <Row>
            <Col md={12}>
              {feedbackFrequencyAssignmentMode ? (
                <div className="mt-2 ms-3">
                  <PatientResponseFrequencyRow
                    inputId="feedbackFrequency"
                    readOnly={readOnly}
                    updateFrequency={updateExpectedFeedbackFrequency}
                    labelTranslationId="CaseCard.SubjectiveData.feedbackFrequency.inputLabel"
                    disableAssignmentMode={disableAssignFeedbackFrequency}
                    expectedFrequency={
                      caseDetails && caseDetails.expected_feedback_frequency > 0
                        ? caseDetails.expected_feedback_frequency
                        : MIN_FEEDBACK_FREQ_IF_NOT_ASSIGNED
                    }
                  />
                </div>
              ) : (
                <div className="mt-3 ms-3">
                  <Button
                    color="primary"
                    className="FormAssignments__add-assignment DashedPrimaryButton"
                    onClick={enableAssignFeedbackFrequency}
                    block
                    disabled={
                      readOnly ||
                      !isCaseDetailsFetched ||
                      !IsCaseDetailsInitialized
                    }
                    data-testid="patient-response-add"
                  >
                    <SVG src={plusIcon} title="add" />
                    <FormattedMessage id="General.selectTime" />
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </div>
        <div className="cardContainer__section mt-5">
          <label
            className="cardContainer__section__label"
            htmlFor="synchronizationFrequency"
          >
            <FormattedMessage id="CaseCard.SubjectiveData.synchronizationFrequency.header" />
          </label>
          <Row>
            <Col md={12}>
              {synchronizationFrequencyAssignmentMode ? (
                <div className="mt-2 ms-3">
                  <PatientResponseFrequencyRow
                    inputId="synchronizationFrequency"
                    readOnly={readOnly}
                    updateFrequency={updateExpectedSynchronizationFrequency}
                    labelTranslationId="CaseCard.SubjectiveData.synchronizationFrequency.inputLabel"
                    disableAssignmentMode={disableAssignSyncFrequency}
                    expectedFrequency={
                      caseDetails &&
                      caseDetails.expected_synchronization_frequency > 0
                        ? caseDetails.expected_synchronization_frequency
                        : MIN_FEEDBACK_FREQ_IF_NOT_ASSIGNED
                    }
                  />
                </div>
              ) : (
                <div className="mt-3 ms-3">
                  <Button
                    color="primary"
                    className="FormAssignments__add-assignment DashedPrimaryButton"
                    onClick={enableAssignSyncFrequency}
                    block
                    disabled={
                      readOnly ||
                      !isCaseDetailsFetched ||
                      !IsCaseDetailsInitialized
                    }
                    data-testid="patient-synchronization-add"
                  >
                    <SVG src={plusIcon} title="add" />
                    <FormattedMessage id="General.selectTime" />
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default PatientSubjectiveData;
