import React from 'react';
import QueriesLoaded from '../../../../../../components/QueriesLoaded';
import { useInitQueries } from '../../../../../../hooks/queries/useInitQuery';
import { ICategory } from '../../../../../../interfaces/cases';
import { ISummarizedTreatmentPhase } from '../../../../../../interfaces/conversations';
import { IOrganizationDetails } from '../../../../../../interfaces/membership';
import { useTreatmentPlanCommonData } from '../../hooks/useTreatmentPlanCommonData';
import { TreatmentPlanDataContext } from '../../types';

type TreatmentPlanDataProviderProps = {
  children: (
    organization: IOrganizationDetails,
    categories: ICategory[],
    treatmentPhases: ISummarizedTreatmentPhase[]
  ) => JSX.Element;
};

const TreatmentPlanDataProvider = ({
  children,
}: TreatmentPlanDataProviderProps) => {
  const { organization, caseCategories, treatmentPhases } =
    useTreatmentPlanCommonData();
  const queries = [organization, caseCategories, treatmentPhases];

  useInitQueries(queries);

  return (
    <QueriesLoaded queries={queries}>
      {(data) => (
        <TreatmentPlanDataContext.Provider
          value={{
            organization: data[0],
            categories: data[1],
            treatmentPhases: data[2],
          }}
        >
          {children(data[0], data[1], data[2])}
        </TreatmentPlanDataContext.Provider>
      )}
    </QueriesLoaded>
  );
};

export default TreatmentPlanDataProvider;
