import React, { useMemo, useState } from 'react';
import { useTranslations } from '../../hooks/useTranslations';
import { ReactState } from '../../interfaces/ui';
import TabItem from '../../modules/CaseDashboard/components/CaseDashboardTabs/components/TabItem';
import AllSqueezesChartFigure from './components/AllSqueezesChartFigure';
import AllSqueezesSwitch from './components/AllSqueezesSwitch';
import { AllSqueezesMode, AllSqueezesQueries } from './types';

type AllSqueezesChartProps = {
  date: Date;
  queries: AllSqueezesQueries;
  classNames?: string;
};

const AllSqueezesChart = ({
  date,
  queries,
  classNames,
}: AllSqueezesChartProps) => {
  const { attachPrefix, wrapWithIntl } = useTranslations(
    'CaseDashboard.CaseDashboardTabs.DayDetailsTab.AllSqueezes'
  );

  const [mode, setMode] = useState<AllSqueezesMode>(
    AllSqueezesMode.AllIncludingExercises
  );

  const modeState = useMemo((): ReactState<AllSqueezesMode> => {
    return { item: mode, setItem: setMode };
  }, [mode]);

  const modeBasedSubTitle = wrapWithIntl(
    attachPrefix(
      mode === AllSqueezesMode.AllIncludingExercises
        ? 'includingExercises'
        : 'exercisesOnly'
    )
  );

  const subTitle = queries.caseDetails.data?.exercises_enabled
    ? modeBasedSubTitle
    : undefined;

  return (
    <TabItem
      title={wrapWithIntl(attachPrefix('todaysSqueezes'))}
      subtitle={subTitle}
      classNames={classNames}
      actions={
        queries.caseDetails.data?.exercises_enabled ? (
          <AllSqueezesSwitch mode={modeState} />
        ) : undefined
      }
    >
      <AllSqueezesChartFigure
        date={date}
        mode={modeState.item}
        queries={queries}
      />
    </TabItem>
  );
};

export default AllSqueezesChart;
