import { UseQueryResult } from 'react-query';
import { ICaseDetails } from '../../../interfaces/cases';
import { IOrganizationObservationMeaning } from '../../../interfaces/observationMeanings';

export enum AllSqueezesMode {
  AllIncludingExercises = 'AllIncludingExercises',
  ExercisesOnly = 'ExercisesOnly',
}

export type AllSqueezesQueries = {
  caseDetails: UseQueryResult<ICaseDetails>;
  observationMeanings: UseQueryResult<IOrganizationObservationMeaning[]>;
};
