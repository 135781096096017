import { createContext } from 'react';
import { IntlShape } from 'react-intl';
import { createFormikUtilsContext } from '../../../../../../../interfaces/forms';
import { ValidConversationTemplate } from '../../../../../../../interfaces/conversations';

export enum MoveDirection {
  Up,
  Down,
}

export const IntlObjectContext = createContext<IntlShape | undefined>(
  undefined
);

export const FormikUtilsContext =
  createFormikUtilsContext<ValidConversationTemplate>();
