import { useCallback } from 'react';
import { DashboardTemplate } from '../../../../../../../../../interfaces/dashboards';
import useCultures from '../../../../../../../../../hooks/cultures/useCultures';

export const useHandleTemplateName = () => {
  const { currentCulture } = useCultures();

  return useCallback(
    (template: DashboardTemplate) => {
      return (
        template.name.find((p) => p.culture === currentCulture.cultureCode)
          ?.value ?? ''
      );
    },
    [currentCulture.cultureCode]
  );
};
