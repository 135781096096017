import React from 'react';
import { Field, FormikProps } from 'formik';
import { AddTooltipFormValues } from '../../hooks/useAddTooltipForm';
import FieldInput from 'components/FieldInput';
import { TemplateItemType } from 'interfaces/change';
import { useTranslations } from 'hooks/useTranslations';
import NonQuestionsItem from './components/NonQuestionsItem';
import QuestionsItem from './components/QuestionsItem';

type AddTooltipFormProps = {
  form: FormikProps<AddTooltipFormValues>;
};

const AddTooltipForm = (props: AddTooltipFormProps) => {
  const { attachPrefix, wrapWithIntl } = useTranslations(
    'Organizations.OrganizationDetails.TrendVisualizations.TrendVisualizationModal.FormHeading.FormHeadingActions.AddTooltip.AddTooltipForm'
  );

  const renderTemplateItemTypeField = (type: TemplateItemType) => {
    return (
      <>
        <Field
          type="checkbox"
          name={`${type}.enabled`}
          checked={props.form.values[type].enabled}
          label={wrapWithIntl(attachPrefix(type))}
          component={FieldInput}
        />
        {props.form.values[type].enabled && (
          <>
            {type === TemplateItemType.Questions ? (
              <QuestionsItem form={props.form} />
            ) : (
              <NonQuestionsItem type={type} form={props.form} />
            )}
          </>
        )}
      </>
    );
  };

  return (
    <div className="d-flex flex-column gap-3">
      {renderTemplateItemTypeField(TemplateItemType.AllObservations)}
      {renderTemplateItemTypeField(TemplateItemType.ExerciseObservations)}
      {renderTemplateItemTypeField(TemplateItemType.NonExerciseObservations)}
      {renderTemplateItemTypeField(TemplateItemType.Questions)}
      {renderTemplateItemTypeField(TemplateItemType.ExerciseTime)}
    </div>
  );
};

export default AddTooltipForm;
