import useRequiredParams from '../useRequiredParams';
import { IOrganizationPathParams } from '../../interfaces/paths';

export const useOrganizationPathParams = (): IOrganizationPathParams => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  return { organizationId };
};
