import { IBaseOrganization } from '../interfaces/organizations';
import { IntlShape } from 'react-intl';
import { BreadcrumbItemType } from '../components/PageHeader/Breadcrumbs';

export enum BreadcrumbsType {
  OrganizationDetails = 'OrganizationDetails',
}

export namespace BreadcrumbsHelpers {
  export const GetBreadcrumbs = (intl: IntlShape, type: BreadcrumbsType) => {
    if (type === BreadcrumbsType.OrganizationDetails)
      return (organization: IBaseOrganization) =>
        OrganizationDetails(organization, intl);

    return () => [];
  };

  const OrganizationDetails = (
    organization: IBaseOrganization,
    intl: IntlShape
  ): BreadcrumbItemType[] => {
    return [
      {
        text: intl.formatMessage({ id: 'Organizations.MyOrganizations' }),
        link: null,
        key: 'my-organizations',
      },
      {
        text: organization.name,
        link: null,
        key: 'name',
      },
    ];
  };
}
