import useOrganizationContextDeleteObservationsGroup from 'api/mutations/observations/useOrganizationContextDeleteObservationsGroup';
import useOrganizationContextUpdateObservationsGroup from 'api/mutations/observations/useOrganizationContextUpdateObservationsGroup';
import useUserContextDeleteObservationsGroup from 'api/mutations/observations/useUserContextDeleteObservationsGroup';
import useUserContextUpdateObservationsGroup from 'api/mutations/observations/useUserContextUpdateObservationsGroup';
import useCaseContextTimelineObservations from 'api/queries/cases/useCaseContextTimelineObservations';
import useUserContextTimelineObservations from 'api/queries/timelines/useUserContextTimelineObservations';
import FieldSelect from 'components/FieldSelect';
import FieldTextarea from 'components/FieldTextarea';
import LoadingButton from 'components/LoadingButton';
import { Field, Formik } from 'formik';
import { getUserId } from 'helpers/utils/auth';
import {
  meaningOptions,
  observationMeaningOptions,
} from 'helpers/utils/translationObject';
import useUserPathBasedBrowsingContext from 'hooks/useUserPathBasedBrowsingContext';
import { GraspEventItemData, Meaning } from 'interfaces/timeline';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import GraspObservationItem from './GraspObservationItem';
import ButtonWithConfirmationModal from '../../../../../../../../../components/ButtonWithConfirmationModal';

type ManageObservationGroupModalProps = {
  isOpen: boolean;
  toggleModal: () => void;
  observations: GraspEventItemData[];
  startDateText: string;
  endDateText: string;
  selectedDates: {
    from: Date;
    to: Date;
  };
  timelineId: string;
};

type ManageObservationGroupModalInitialValues = {
  description?: string;
  meaning?: Meaning;
};

const ManageObservationGroupModal = ({
  isOpen,
  toggleModal,
  observations,
  startDateText,
  endDateText,
  selectedDates,
  timelineId,
}: ManageObservationGroupModalProps) => {
  const {
    organizationId = '',
    caseId = '',
    isOrganizationContext,
  } = useUserPathBasedBrowsingContext();

  const userId = getUserId();

  const userContextTimelineObservations = useUserContextTimelineObservations({
    params: {
      timelineId,
      selectedDates,
      grouped: true,
    },
    options: { enabled: false },
  });

  const organizationContextTimelineObservations =
    useCaseContextTimelineObservations({
      params: {
        organizationId,
        caseId,
        timelineId,
        selectedDates,
        grouped: true,
      },
      options: { enabled: false },
    });

  const timelineObservationsQuery = isOrganizationContext
    ? organizationContextTimelineObservations
    : userContextTimelineObservations;

  const refetch = async () => {
    await timelineObservationsQuery.refetch();
    toggleModal();
  };

  const userContextUpdateObservationGroupMutation =
    useUserContextUpdateObservationsGroup({
      userId: userId,
      timelineId: timelineId,
      successFb: refetch,
    });

  const organizationContextUpdateObservationGroupMutation =
    useOrganizationContextUpdateObservationsGroup({
      organizationId: organizationId ?? '',
      caseId: caseId ?? '',
      timelineId: timelineId,
      successFb: refetch,
    });

  const updateObservationGroupMutation = isOrganizationContext
    ? organizationContextUpdateObservationGroupMutation
    : userContextUpdateObservationGroupMutation;

  const userContextDeleteObservationGroupMutation =
    useUserContextDeleteObservationsGroup({
      userId: userId,
      timelineId: timelineId,
      successFb: refetch,
    });

  const organizationContextDeleteObservationGroupMutation =
    useOrganizationContextDeleteObservationsGroup({
      organizationId: organizationId ?? '',
      caseId: caseId ?? '',
      timelineId: timelineId,
      successFb: refetch,
    });

  const deleteObservationGroupMutation = isOrganizationContext
    ? organizationContextDeleteObservationGroupMutation
    : userContextDeleteObservationGroupMutation;

  const initialValues: ManageObservationGroupModalInitialValues = {
    description: observations[0].description,
    meaning: observations[0].meaning,
  };

  const onSubmit = ({ description, meaning }: typeof initialValues) => {
    if (description && meaning) {
      updateObservationGroupMutation.mutate({
        description: description,
        meaning: meaning,
        observation_ids: observations.map((p) => p.id),
      });
    }
  };

  const handleDeleteGroup = () => {
    deleteObservationGroupMutation.mutate({
      observation_ids: observations.map((p) => p.id),
    });
  };

  return (
    <Modal
      size="xl"
      isOpen={isOpen}
      toggle={toggleModal}
      className="ManageObservationGroupModal"
    >
      <ModalHeader
        toggle={toggleModal}
        className="ManageObservationGroupModal__header"
      >
        <div className="ManageObservationGroupModal__header__group-info">
          <FormattedMessage id="TimelineDetails.ManageObservationGroupModal.group" />
          <FormattedMessage
            id={
              observationMeaningOptions.filter(
                (p) => p.value === observations[0].meaning
              )[0].translationId
            }
          />
        </div>
        <div className="ManageObservationGroupModal__header__group-details">
          <span>{observations.length}</span>
          <FormattedMessage id="TimelineDetails.ManageObservationGroupModal.observations" />
          <span>{`(${startDateText} - ${endDateText})`}</span>
        </div>
      </ModalHeader>
      <Formik
        enableReinitialize
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ isValid, submitForm, initialValues: formValues }) => (
          <>
            <ModalBody className="ManageObservationGroupModal__body">
              <FormGroup>
                <Label htmlFor="observation_group_meaning">
                  <FormattedMessage id="TimelineDetails.ManageObservationGroupModal.meaning" />
                </Label>
                <Field
                  required
                  id="observation_group_meaning"
                  name="meaning"
                  component={FieldSelect}
                  options={
                    formValues.meaning !== undefined &&
                    formValues.meaning !== Meaning.NotSpecified
                      ? meaningOptions.filter((p) => p.value !== 'NotSpecified')
                      : meaningOptions
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="observation_group_description">
                  <FormattedMessage id="TimelineDetails.ManageObservationGroupModal.description" />
                </Label>
                <Field
                  required
                  id="observation_group_description"
                  name="description"
                  type="text"
                  component={FieldTextarea}
                />
              </FormGroup>
              <div className="ManageObservationGroupModal__body__observations">
                {observations.map((observation) => (
                  <GraspObservationItem
                    key={observation.id}
                    observation={observation}
                  />
                ))}
              </div>
            </ModalBody>
            <ModalFooter className="ManageObservationGroupModal__footer">
              <ButtonWithConfirmationModal
                outline
                className="ManageObservationGroupModal__footer__deleteGroup"
                body={
                  <FormattedMessage id="TimelineDetails.ManageObservationGroupModal.confirmDeletionText" />
                }
                header={
                  <FormattedMessage id="TimelineDetails.ManageObservationGroupModal.confirmDeletion" />
                }
                onConfirmClick={handleDeleteGroup}
                isLoading={deleteObservationGroupMutation.isLoading}
              >
                <FormattedMessage id="TimelineDetails.ManageObservationGroupModal.deleteGroup" />
                <span className="ms-1">{`(${observations.length})`}</span>
              </ButtonWithConfirmationModal>
              <LoadingButton
                disabled={!isValid}
                type="submit"
                onClick={submitForm}
                isLoading={false}
              >
                <FormattedMessage id="General.save" />
              </LoadingButton>
            </ModalFooter>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default ManageObservationGroupModal;
