import { useQuery } from 'react-query';
import { IQueryOptions } from '../../../interfaces/ui';
import { getHelpCategories } from '../../help';
import QueryKeys from '../queryKeys';

type HelpCategoriesQueryParams = {
  options?: IQueryOptions;
};

const useHelpCategories = (queryParams?: HelpCategoriesQueryParams) => {
  return useQuery({
    ...queryParams?.options,
    queryFn: async () => {
      const { data } = await getHelpCategories();
      return data.data;
    },
    queryKey: [QueryKeys.getHelpCategories],
  });
};

export default useHelpCategories;
