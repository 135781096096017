import HourglassBottomOutlinedIcon from '@mui/icons-material/HourglassBottomOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import useInviteUserToOrganization from 'api/mutations/organizations/useInviteUserToOrganization';
import FieldInput from 'components/FieldInput';
import FieldSelect from 'components/FieldSelect';
import { Field, Form, Formik } from 'formik';
import { isEmail, validateEmpty } from 'helpers/utils/validators';
import { OrganizationAccessProfile } from 'interfaces/accessProfiles';
import { BaseModalProps, InputOption } from 'interfaces/ui';

//helpers
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

//helpers
import { getCulture } from 'translations/utils/cultures';
import IconButton from '../../../../../../components/IconButton';
import useRequiredParams from '../../../../../../hooks/useRequiredParams';

const initialValues = {
  email: '',
  access_profile: '',
};

type UserInvitationModalProps = BaseModalProps & {
  accessProfiles: OrganizationAccessProfile[];
};

const UserInvitationModal = ({
  isOpen,
  toggle,
  accessProfiles,
}: UserInvitationModalProps) => {
  const intl = useIntl();

  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const { mutate, isLoading } = useInviteUserToOrganization(organizationId, {
    successFb: toggle,
  });

  const onSubmit = (values: typeof initialValues) => {
    mutate({ ...values, culture: getCulture() });
  };

  const validateForm = (values: typeof initialValues) => {
    let errors = validateEmpty<typeof initialValues>(values, intl);
    if (values.email && !isEmail(values.email))
      errors.email = intl.formatMessage({
        id: 'CaseCard.create.errors.emailError',
      });

    if (values.access_profile.length === 0) {
      errors[values.access_profile.toString()] = intl.formatMessage({
        id: 'CaseCard.create.errors.inputEmpty',
      });
    }

    return errors;
  };

  const accessProfilesOptions = useMemo((): InputOption[] => {
    if (accessProfiles === undefined) return [];

    return accessProfiles.map((profile) => ({
      text: profile.name,
      value: profile.id,
    }));
  }, [accessProfiles]);

  return (
    <>
      <Modal
        className="GenericModal BorderlessModal"
        isOpen={isOpen}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>
          <FormattedMessage id="MyOrganizations.invitation.header" />
        </ModalHeader>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validateOnMount={true}
          validate={validateForm}
        >
          {({ isValid, submitForm }) => (
            <>
              <ModalBody>
                <Form>
                  <FormGroup>
                    <Label htmlFor="userEmail" for="userEmail">
                      <FormattedMessage id="MyOrganizations.invitation.userEmail" />
                    </Label>
                    <Field
                      required
                      id="userEmail"
                      name="email"
                      type="text"
                      placeholder={intl.formatMessage({
                        id: 'MyOrganizations.invitation.userEmail',
                      })}
                      component={FieldInput}
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="access_profile" for="access_profile">
                      <FormattedMessage id="MyOrganizations.invitation.accessProfile" />
                    </Label>
                    <Field
                      required
                      id="access_profile"
                      name="access_profile"
                      type="select"
                      component={FieldSelect}
                      options={accessProfilesOptions}
                    />
                  </FormGroup>
                </Form>
              </ModalBody>
              <ModalFooter>
                <IconButton
                  disabled={!isValid}
                  onClick={submitForm}
                  variant="outlined"
                  color="primary"
                >
                  {isLoading ? (
                    <HourglassBottomOutlinedIcon />
                  ) : (
                    <SaveOutlinedIcon />
                  )}
                </IconButton>
              </ModalFooter>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default UserInvitationModal;
