import { useAddOrganizationUserAccessProfile } from '../../../../../../../../../../../api/mutations/accessProfiles/useAddOrganizationUserAccessProfile';
import { useOrganizationPathParams } from '../../../../../../../../../../../hooks/paths/useOrganizationPathParams';
import useInfiniteUserInOrganizationsWithRoles from '../../../../../../../../../../../api/queries/organizations/useInfiniteUserInOrganizationsWithRoles';

export const useHandleAdd = () => {
  const { organizationId } = useOrganizationPathParams();

  const userInOrganizationsWithRoles = useInfiniteUserInOrganizationsWithRoles({
    params: { organizationId, pageSize: 1000 },
    options: { enabled: false, staleTime: Infinity },
  });

  const { mutateAsync: addAsync, isLoading } =
    useAddOrganizationUserAccessProfile(
      { organizationId },
      { successFb: userInOrganizationsWithRoles.refetch }
    );

  return { addAsync, isAdding: isLoading };
};
