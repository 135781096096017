import { sendFeedback } from 'api/graspManagement';
import { GraspFeedbackBody } from 'interfaces/grasps';
import { IErrorWithCode } from 'interfaces/response';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useEffect } from 'react';
import { useMutation } from 'react-query';

function useSendFeedback(onSuccess?: () => void) {
  const result = useMutation<
    'Bug' | 'Improvement' | 'HelpRequest',
    IErrorWithCode,
    GraspFeedbackBody
  >(async (body: GraspFeedbackBody) => {
    await sendFeedback(body);
    return body.category;
  });

  useEffect(() => {
    if (result.isSuccess && result.data) {
      if (result.data === 'Bug') {
        displaySuccessNotification('Footer.success.bug');
      } else if (result.data === 'Improvement') {
        displaySuccessNotification('Footer.success.improvement');
      }
      onSuccess?.();
      result.reset();
    }

    if (result.isError && result.error) {
      const errorArray = result.error?.data?.meta?.errors || [];

      displayErrorArrayNotifications(
        errorArray,
        'Register.notifications.errors'
      );
      result.reset();
    }
  }, [onSuccess, result]);

  return result;
}

export default useSendFeedback;
