import React, { useState } from 'react';
import { ICategory } from '../../../../../../../interfaces/cases';
import CaseGroupCollectionItem, {
  CaseGroupCollectionItemAction,
  CaseGroupCollectionStyle,
} from '../../../../../../../components/CaseGroupCollectionItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faUndo } from '@fortawesome/pro-solid-svg-icons';
import {
  faArrowRight,
  faFolderOpen,
  faSave,
} from '@fortawesome/pro-light-svg-icons';
import { No, Us } from 'react-flags-select';
import { cloneDeep } from 'lodash';
import EditableInput from '../../../../../../../components/EditableInput/EditableInput';
import { displayErrorNotification } from '../../../../../../Notifications';
import usePatchCaseCategory from '../../../../../../../api/mutations/caseCategories/usePatchCaseCategory';
import { IPatchObject } from '../../../../../../../interfaces/request';
import useCreateCaseCategory from '../../../../../../../api/mutations/caseCategories/useCreateCaseCategory';
import { QueryObserverResult } from 'react-query/types/core/types';

type CaseCategoryItemProps = {
  organizationId: string;
  category: ICategory;
  selectedCategory: ICategory | null;
  modifiedCategory: ICategory | null;
  setSelectedCategory: (category: ICategory | null) => void;
  setModifiedCategory: (category: ICategory | null) => void;
  disableAddCategoryMode: () => void;
  refetch: () => Promise<QueryObserverResult<any, any>>;
};

const CaseCategoryItem = ({
  organizationId,
  category,
  selectedCategory,
  modifiedCategory,
  setSelectedCategory,
  setModifiedCategory,
  disableAddCategoryMode,
  refetch,
}: CaseCategoryItemProps) => {
  const handleRefetch = async () => {
    setModifiedCategory(null);
    setSelectedCategory(null);
    disableAddCategoryMode();
    await refetch();
  };

  const { mutateAsync: patchCategory } = usePatchCaseCategory(
    {
      organizationId: organizationId,
      categoryId: category.id,
    },
    {
      successFb: handleRefetch,
    }
  );

  const { mutateAsync: addCategory } = useCreateCaseCategory(
    {
      organizationId: organizationId,
    },
    {
      successFb: handleRefetch,
    }
  );

  const [modifiedCode, setModifiedCode] = useState<string>(
    cloneDeep(category).code
  );
  const [modifiedNameNo, setModifiedNameNo] = useState<string>(
    cloneDeep(category).name_no
  );
  const [modifiedNameEn, setModifiedNameEn] = useState<string>(
    cloneDeep(category).name_en
  );

  const modifiedMode = modifiedCategory?.id === category.id;
  const selectedMode = selectedCategory?.id === category.id;

  const handleSaveCategory = async () => {
    if (
      modifiedCode.length === 0 ||
      modifiedNameEn.length === 0 ||
      modifiedNameNo.length === 0
    ) {
      displayErrorNotification('MyOrganizations.caseCategories.emptyFields');
      return;
    }

    if (category.id === '') {
      await addCategory({
        name_no: modifiedNameNo,
        name_en: modifiedNameEn,
        code: modifiedCode,
        icon: '',
      });

      return;
    }

    let patchObject: IPatchObject[] = [];

    if (modifiedCode !== category.code) {
      patchObject.push({
        op: 'replace',
        path: '/code',
        value: modifiedCode,
      });
    }

    if (modifiedNameNo !== category.name_no) {
      patchObject.push({
        op: 'replace',
        path: '/name_no',
        value: modifiedNameNo,
      });
    }

    if (modifiedNameEn !== category.name_en) {
      patchObject.push({
        op: 'replace',
        path: '/name_en',
        value: modifiedNameEn,
      });
    }

    if (patchObject.length === 0) return;

    await patchCategory(patchObject);
  };

  const handleSelectCategory = () => {
    if (selectedCategory !== null && selectedCategory.id === category.id) {
      setSelectedCategory(null);
      setModifiedCategory(null);
      return;
    }

    setSelectedCategory(category);
    setModifiedCategory(null);
  };

  const handleEditCategory = () => {
    setSelectedCategory(category);
    setModifiedCategory(category);
  };

  const handleRevert = () => {
    setModifiedCategory(null);
    setModifiedCode(cloneDeep(category).code);
    setModifiedNameNo(cloneDeep(category).name_no);
    setModifiedNameEn(cloneDeep(category).name_en);

    if (category.id === '') disableAddCategoryMode();
  };

  let categoryActions: CaseGroupCollectionItemAction[] = [
    {
      key: `${category.code}|edit`,
      onClick: handleEditCategory,
      icon: <FontAwesomeIcon icon={faPencil} />,
    },
  ];

  if (modifiedMode) {
    categoryActions.push({
      key: `${category.code}|revert`,
      onClick: handleRevert,
      icon: <FontAwesomeIcon icon={faUndo} />,
    });

    categoryActions.push({
      key: `${category.code}|save`,
      onClick: handleSaveCategory,
      icon: <FontAwesomeIcon icon={faSave} />,
    });

    categoryActions = categoryActions.filter(
      (p) => p.key !== `${category.code}|edit`
    );
  } else {
    categoryActions.push({
      key: `${category.code}|select`,
      onClick: handleSelectCategory,
      icon: <FontAwesomeIcon icon={faArrowRight} />,
    });
  }

  const handleModifyNameEn = (target: string, value: string) => {
    setModifiedNameEn(value);
  };

  const handleModifyNameNo = (target: string, value: string) => {
    setModifiedNameNo(value);
  };

  const handleModifyCode = (target: string, value: string) => {
    setModifiedCode(value);
  };

  return (
    <CaseGroupCollectionItem
      style={
        selectedMode
          ? CaseGroupCollectionStyle.PrimaryOutline
          : CaseGroupCollectionStyle.Default
      }
      infoMonochrome={false}
      icon={<FontAwesomeIcon icon={faFolderOpen} />}
      title={
        modifiedMode ? (
          <EditableInput
            className="mb-1"
            value={modifiedCode}
            onEdit={handleModifyCode}
          />
        ) : (
          <>{modifiedCode}</>
        )
      }
      info={
        <div className="CaseCategoriesListItem">
          <div className="d-flex align-items-center gap-1">
            <Us width={30} />
            {modifiedMode ? (
              <EditableInput
                className="mb-1"
                value={modifiedNameEn}
                onEdit={handleModifyNameEn}
              />
            ) : (
              <span>{modifiedNameEn}</span>
            )}
          </div>
          <div className="d-flex align-items-center gap-1">
            <No width={30} />
            {modifiedMode ? (
              <EditableInput
                width="100%"
                className="mb-1"
                value={modifiedNameNo}
                onEdit={handleModifyNameNo}
              />
            ) : (
              <span>{modifiedNameNo}</span>
            )}
          </div>
        </div>
      }
      actions={categoryActions}
    />
  );
};

export default CaseCategoryItem;
