import { faCommentsAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConversationTemplatePhone from 'modules/ConversationsAndFeedbacks/components/ConversationTemplates/components/ConversationTemplatePhone';
import React from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { FormattedMessage, IntlProvider } from 'react-intl';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import EventPropagationConstraint from '../../../../../../../../../../../../components/EventPropagationConstraint';
import { ConversationTemplate } from '../../../../../../../../../../../../interfaces/conversations';
import { returnFormattedLang } from '../../../../../../../../../../../../translations/utils/cultures';
import { useHandleCultures } from './hooks/useHandleCultures';

type ConversationTemplatePhoneModalProps = {
  participant: string;
  date: string;
  template: ConversationTemplate;
  isOpen: boolean;
  toggle: () => void;
  defaultCulture?: string;
};

const ConversationTemplatePhoneModal = ({
  participant,
  date,
  isOpen,
  template,
  toggle,
  defaultCulture,
}: ConversationTemplatePhoneModalProps) => {
  const {
    flagLabels,
    flagCodes,
    countryCodeBasedOnContext,
    handleChangeContext,
    messages,
    languageContext,
  } = useHandleCultures(defaultCulture);

  return (
    <Modal size="lg" isOpen={isOpen} toggle={toggle}>
      <ModalHeader
        toggle={toggle}
        className="ConversationTemplateModal__header"
      >
        <div className="d-flex align-items-center gap-2">
          <FontAwesomeIcon icon={faCommentsAlt} />
          <FormattedMessage
            id="Cases.ChangeCase.ConversationTemplatePhoneModal.title"
            values={{
              conversationParticipant: participant,
              conversationDate: date,
            }}
          />
          <div className="mx-auto">
            <EventPropagationConstraint>
              <ReactFlagsSelect
                className="react-flag-select"
                countries={flagCodes}
                customLabels={flagLabels}
                selected={countryCodeBasedOnContext}
                onSelect={handleChangeContext}
              />
            </EventPropagationConstraint>
          </div>
        </div>
      </ModalHeader>
      <ModalBody className="ConversationTemplateModal__body d-flex align-items-center justify-content-center">
        <div className="mt-4">
          <IntlProvider
            locale={returnFormattedLang(languageContext.cultureCode)}
            messages={messages}
          >
            <ConversationTemplatePhone
              value={template}
              language={languageContext}
              demonstrative={true}
            />
          </IntlProvider>
        </div>
      </ModalBody>
      <ModalFooter className="ConversationTemplateModal__footer">
        <Button color="primary" outline onClick={toggle}>
          <FormattedMessage id="General.close" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConversationTemplatePhoneModal;
