import SingleDescription from 'components/SingleDescription';
import { IGraspOrganization } from 'interfaces/grasps';
import React from 'react';
import { Container } from 'reactstrap';

type GraspDetailsInformationProps = {
  data: IGraspOrganization | undefined;
};

const GraspDetailsInformation = ({ data }: GraspDetailsInformationProps) => {
  return (
    <div className="sharedGraspsPatient ms-2 mt-4">
      <Container className="pt-4">
        <SingleDescription label="OrganizationGrasps.details.name">
          <p>{data?.name}</p>
        </SingleDescription>
        <SingleDescription label="OrganizationGrasps.details.physicalId">
          <p>{data?.physical_grasp_uuid}</p>
        </SingleDescription>
      </Container>
    </div>
  );
};

export default GraspDetailsInformation;
