import React, { useContext, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import { ChangeLanguageContext } from 'translations/utils/LanguageProvider';

export enum AVAILABLE_CULTURES {
  NO = 'nb-NO',
  EN = 'en-US',
}

type ChangeLanguageSelectorProps = {
  selectedCulture: AVAILABLE_CULTURES;
  setSelectedCulture: (culture: AVAILABLE_CULTURES) => void;
  noLabel?: boolean;
  white?: boolean;
};

const CultureSelector = ({
  selectedCulture,
  setSelectedCulture,
  noLabel = false,
  white = false,
}: ChangeLanguageSelectorProps) => {
  const changeLanguage = useRef(useContext(ChangeLanguageContext));

  useEffect(() => {
    const selectedLang = Object.entries(AVAILABLE_CULTURES)
      .find(([_, val]) => val === selectedCulture)![0]
      .toLowerCase();

    changeLanguage.current.onLanguageChange(selectedLang);
  }, [selectedCulture]);

  return (
    <div
      className={`ChangeLanguage ${noLabel ? 'ChangeLanguage--no-label' : ''} ${
        white ? 'ChangeLanguage--white' : ''
      }`}
    >
      <span className="ChangeLanguage__label">
        <FormattedMessage id="Projects.Register.languageSelectionDropdownLabel" />
      </span>
      <UncontrolledDropdown
        className="ChangeLanguage__dropdown"
        setActiveFromChild
      >
        <DropdownToggle tag="a" className="nav-link" caret>
          {
            Object.entries(AVAILABLE_CULTURES).find(
              ([_, val]) => val === selectedCulture
            )![0]
          }
        </DropdownToggle>
        <DropdownMenu end>
          {Object.entries(AVAILABLE_CULTURES).map(([key, val]) => (
            <DropdownItem
              key={key}
              active={val === selectedCulture}
              onClick={() => setSelectedCulture(val)}
            >
              {key}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export default CultureSelector;
