import { useMemo } from 'react';
import { useQuestionTitle } from '../../../../../../../../../../../hooks/data/useQuestionTitle';
import { useTranslations } from '../../../../../../../../../../../hooks/useTranslations';
import { useTypedContext } from '../../../../../../../../../../../hooks/useTypedContext';
import {
  DashboardFilter,
  DashboardFilterType,
} from '../../../../../../../../../../../interfaces/dashboards';
import { DashboardQueriesContext } from '../../../../../types';

export const useFilterDisplayInfo = (filter: DashboardFilter) => {
  const { wrapWithIntl, attachPrefix } = useTranslations(
    'Organizations.OrganizationDashboard.AwareCasesDashboard.DashboardFilters.DashboardFilter'
  );

  const getTitle = useQuestionTitle();

  const { questions } = useTypedContext(DashboardQueriesContext);

  const filterString = useMemo(() => {
    if (
      filter.type === DashboardFilterType.Range ||
      filter.type === DashboardFilterType.ClosedList
    ) {
      const conversationQuestion = questions.find(
        (p) => p.resource_id === filter.id
      );

      if (conversationQuestion) return getTitle(conversationQuestion);
      return '';
    }

    if (filter.type === DashboardFilterType.Organization)
      return wrapWithIntl(attachPrefix('organization'));

    if (filter.type === DashboardFilterType.Member)
      return wrapWithIntl(attachPrefix('doctor'));

    if (filter.type === DashboardFilterType.Grasp)
      return wrapWithIntl(attachPrefix('grasp'));

    if (filter.type === DashboardFilterType.SourcePeriod)
      return wrapWithIntl(attachPrefix('period'));

    return '';
  }, [filter, wrapWithIntl, attachPrefix, questions, getTitle]);

  return { filterString };
};
