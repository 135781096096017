import React from 'react';
import {
  DashboardTemplateChartType,
  IChartDashboardTemplateVisualization,
} from '../../../../../../../../../../../../interfaces/dashboards';
import { Case, Switch } from '../../../../../../../../../../../SwitchCase';
import AwareCasesBarChart, { BarChartType } from './components/BarChart';
import AwareCasesLineChart from './components/LineChart';
import AwareCasesPieChart from './components/PieChart';
import ChartViewWrapper from './wrappers/ChartViewWrapper';

type ChartViewProps = {
  visualization: IChartDashboardTemplateVisualization;
};

const ChartView = ({ visualization }: ChartViewProps) => {
  return (
    <ChartViewWrapper visualization={visualization}>
      <Switch item={visualization.chart_type}>
        <Case value={DashboardTemplateChartType.PieChart}>
          <AwareCasesPieChart
            config={visualization.series[0]}
            sequence={visualization.sequence}
          />
        </Case>
        <Case value={DashboardTemplateChartType.LineChart}>
          <AwareCasesLineChart
            config={visualization.series}
            sequence={visualization.sequence}
            dateGranularity={visualization.date_granularity}
          />
        </Case>
        <Case value={DashboardTemplateChartType.HorizontalBarChart}>
          <AwareCasesBarChart
            config={visualization.series}
            type={BarChartType.Horizontal}
            dateGranularity={visualization.date_granularity}
          />
        </Case>
        <Case value={DashboardTemplateChartType.VerticalBarChart}>
          <AwareCasesBarChart
            config={visualization.series}
            type={BarChartType.Vertical}
            dateGranularity={visualization.date_granularity}
          />
        </Case>
      </Switch>
    </ChartViewWrapper>
  );
};

export default ChartView;
