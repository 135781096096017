import React, { memo } from 'react';
import { PlainTextValidConversationTemplateItem } from '../../../../../../../../../../../../interfaces/conversations';
import ItemAvatar from '../ItemAvatar';
import { useTypedContext } from '../../../../../../../../../../../../hooks/useTypedContext';
import { LanguageContext } from '../../../../../../types';
import { AVAILABLE_CULTURES } from '../../../../../../../../../../../../components/CultureSelector/CultureSelector';
import { format } from 'date-fns';

type PlainTextItemProps = {
  item: PlainTextValidConversationTemplateItem;
  avatars: string[] | undefined;
};

const PlainTextItem = ({ item, avatars }: PlainTextItemProps) => {
  const language = useTypedContext(LanguageContext);

  const currentHour = format(new Date(), 'HH:HH a');

  const title =
    language.culture === AVAILABLE_CULTURES.EN
      ? item.value.text
      : item.value.text_nb_no;

  const titleHtml =
    language.culture === AVAILABLE_CULTURES.EN
      ? item.value.text_html
      : item.value.text_html_nb_no;

  return (
    <div className="ConversationTemplatePlainTextItem">
      <div className="ConversationTemplatePlainTextItem__avatars me-2">
        {avatars ? (
          avatars.map((avatar) => <ItemAvatar key={avatar} url={avatar} />)
        ) : (
          <>
            <ItemAvatar />
            <ItemAvatar />
          </>
        )}
      </div>
      <div className="ConversationTemplatePlainTextItem__content">
        {titleHtml ? (
          <div
            className="ConversationTemplatePlainTextItem__content__text"
            dangerouslySetInnerHTML={{ __html: titleHtml }}
          />
        ) : (
          <div className="ConversationTemplatePlainTextItem__content__text">
            {title}
          </div>
        )}
        <div className="ConversationTemplatePlainTextItem__content__hour">
          {currentHour}
        </div>
      </div>
    </div>
  );
};

export default memo(PlainTextItem);
