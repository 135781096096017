import { modifyCaseMemberRequest } from 'api/cases';
import { RequestActions } from 'interfaces/enums';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

export type ModifyCaseMemberRequestParams = {
  requestId: string;
  action: RequestActions;
};

function useModifyCaseMemberRequest(
  organizationId: string,
  successFb?: () => void
) {
  return useMutationWithResultNotification({
    asyncApiFunction: async ({
      action,
      requestId,
    }: ModifyCaseMemberRequestParams) => {
      await modifyCaseMemberRequest(organizationId, requestId, action);
    },
    resetOnResult: true,
    onSuccess: () => {
      successFb && successFb();
      displaySuccessNotification('PatientAccess.statusSuccess');
    },
    onError: () => {
      displayErrorNotification('PatientAccess.statusError');
    },
  });
}

export default useModifyCaseMemberRequest;
