import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { OrganizationAccessProfile } from '../../../../../interfaces/accessProfiles';
import { UserRoles } from '../../../../../interfaces/membership';
import { Button, Input } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faEdit,
  faMinus,
  faPlus,
  faTrash,
} from '@fortawesome/pro-light-svg-icons';
import { iconColors } from '../../../../../helpers/colors';
import { roleTypesOptions } from './AddAccessProfileModal';
import {
  AccessProfileRoleToAdd,
  AccessProfileRoleToDelete,
  AccessProfileToRename,
} from '..';
import { useModifyAccessProfile } from '../../../../../api/mutations/accessProfiles/useModifyAccessProfile';
import { useAddAccessProfileRoles } from '../../../../../api/mutations/accessProfiles/useAddAccessProfileRoles';
import EditableInput from '../../../../../components/EditableInput/EditableInput';
import { InputOption } from '../../../../../interfaces/ui';

type AccessProfileItemProps = {
  organizationId: string;
  accessProfilesRefetch: () => void;
  profile: OrganizationAccessProfile;
  setAccessProfileToDelete: (id: string | null) => void;
  setAccessProfileToRename: (profile: AccessProfileToRename | null) => void;
  setAccessProfileRoleToAdd: (profile: AccessProfileRoleToAdd | null) => void;
  setAccessProfileRoleToDelete: (
    profile: AccessProfileRoleToDelete | null
  ) => void;
  accessProfileToRename: AccessProfileToRename | null;
  accessProfileRoleToAdd: AccessProfileRoleToAdd | null;
  accessProfiles: OrganizationAccessProfile[] | undefined;
};

const AccessProfileItem = ({
  organizationId,
  profile,
  accessProfilesRefetch,
  setAccessProfileToDelete,
  setAccessProfileToRename,
  accessProfileToRename,
  setAccessProfileRoleToAdd,
  accessProfileRoleToAdd,
  setAccessProfileRoleToDelete,
  accessProfiles,
}: AccessProfileItemProps) => {
  const { mutateAsync: addRolesMutation } = useAddAccessProfileRoles(
    {
      organizationId: organizationId,
    },
    {
      successFb: accessProfilesRefetch,
    }
  );

  const { mutateAsync: modifyMutation } = useModifyAccessProfile(
    {
      organizationId: organizationId,
    },
    {
      successFb: accessProfilesRefetch,
    }
  );

  const deleteAccessProfile = () => {
    setAccessProfileToDelete(profile.id);
  };

  const handleAccessProfileRename = (e: ChangeEvent<HTMLInputElement>) => {
    if (accessProfileToRename === null) return;

    setAccessProfileToRename({
      accessProfileId: accessProfileToRename.accessProfileId,
      name: e.currentTarget.value,
    });
  };

  const handleAccessProfileModify = async () => {
    if (accessProfileToRename === null) return;

    await modifyMutation({
      accessProfileId: accessProfileToRename.accessProfileId,
      patch: [
        {
          op: 'replace',
          path: '/name',
          value: accessProfileToRename.name,
        },
      ],
    });

    setAccessProfileToRename(null);
  };

  const toggleAccessProfileModification = () => {
    setAccessProfileToRename({
      accessProfileId: profile.id,
      name: profile.name,
    });
  };

  const toggleAccessProfileRoleAdd = () => {
    setAccessProfileRoleToAdd({
      accessProfileId: profile.id,
      role: null,
    });
  };

  const handleAccessProfileRoleAdd = async () => {
    if (accessProfileRoleToAdd === null || accessProfileRoleToAdd.role === null)
      return;

    const existingAccessProfile = accessProfiles?.find(
      (p) => p.id === accessProfileRoleToAdd.accessProfileId
    );

    if (existingAccessProfile === undefined) return;

    await addRolesMutation({
      accessProfileId: accessProfileRoleToAdd.accessProfileId,
      roles: [accessProfileRoleToAdd.role],
    });

    setAccessProfileRoleToAdd(null);
  };

  const handleAccessProfileRoleSelect = (_: string, value: string) => {
    if (accessProfileRoleToAdd === null) return;

    setAccessProfileRoleToAdd({
      accessProfileId: accessProfileRoleToAdd.accessProfileId,
      role: value as UserRoles,
    });
  };

  const renderAccessProfileRole = useCallback(
    (roles: UserRoles[], accessProfile: OrganizationAccessProfile) => {
      return roles.map((role) => {
        const deleteAccessProfileRole = () => {
          setAccessProfileRoleToDelete({
            accessProfileId: accessProfile.id,
            accessProfileRole: role,
          });
        };

        return (
          <li key={role}>
            {role}
            <Button
              className="btn-transparent"
              onClick={deleteAccessProfileRole}
            >
              <FontAwesomeIcon icon={faMinus} color={iconColors.normal} />
            </Button>
          </li>
        );
      });
    },
    [setAccessProfileRoleToDelete]
  );

  const roleTypesInputOptions = useMemo((): InputOption[] => {
    return roleTypesOptions.map(({ label, value }) => ({
      value: value,
      text: label,
    }));
  }, []);

  return (
    <tr key={`profile-${profile.id}`}>
      <td>
        <div className="d-flex align-items-center gap-2">
          {accessProfileToRename &&
          accessProfileToRename.accessProfileId === profile.id ? (
            <div className="d-flex align-items-center gap-2">
              <Input
                type="text"
                name={profile.name}
                onChange={handleAccessProfileRename}
                value={accessProfileToRename.name}
              />
              <Button
                color="primary"
                onClick={handleAccessProfileModify}
                block={false}
              >
                <FontAwesomeIcon icon={faCheck} color={iconColors.white} />
              </Button>
            </div>
          ) : (
            <>
              <span>{profile.name}</span>
              <FontAwesomeIcon
                onClick={toggleAccessProfileModification}
                icon={faEdit}
                color={iconColors.normal}
              />
            </>
          )}
        </div>
      </td>
      <td>
        <ul className="list-unstyled mb-0">
          {renderAccessProfileRole(profile.access_profile_role_types, profile)}
        </ul>
        {accessProfileRoleToAdd &&
        accessProfileRoleToAdd.accessProfileId === profile.id ? (
          <div className="d-flex align-items-center gap-2">
            <EditableInput
              type="select"
              value={accessProfileRoleToAdd.role ?? ''}
              name="accessProfileRoleToAdd.accessProfileId"
              options={roleTypesInputOptions}
              onEdit={handleAccessProfileRoleSelect}
              debounceEnabled={false}
            />
            <Button
              color="primary"
              onClick={handleAccessProfileRoleAdd}
              block={false}
            >
              <FontAwesomeIcon icon={faCheck} color={iconColors.white} />
            </Button>
          </div>
        ) : (
          <Button
            color="primary"
            outline={true}
            onClick={toggleAccessProfileRoleAdd}
            block={false}
          >
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        )}
      </td>
      <td width="12.5%">
        <Button color="danger" outline={true} onClick={deleteAccessProfile}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </td>
    </tr>
  );
};

export default AccessProfileItem;
