import TextField from '@mui/material/TextField';
import { FormikProps } from 'formik';
import React from 'react';
import { useIntl } from 'react-intl';
import { FormValues } from '../../types';

type AllEventsFormProps = {
  form: FormikProps<FormValues>;
};

const AllEventsForm = ({ form }: AllEventsFormProps) => {
  const intl = useIntl();

  return (
    <div className="d-flex flex-column gap-3">
      <div className="d-flex w-100 align-items-center gap-1">
        <TextField
          fullWidth
          variant="outlined"
          type="number"
          name="start_day_offset"
          placeholder={intl.formatMessage({
            id: 'General.StartDayOffset',
          })}
          label={intl.formatMessage({
            id: 'General.StartDayOffset',
          })}
          value={form.values.start_day_offset}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{ min: 1 }}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
        />
        <TextField
          fullWidth
          variant="outlined"
          type="number"
          name="end_day_offset"
          placeholder={intl.formatMessage({
            id: 'General.EndDayOffset',
          })}
          label={intl.formatMessage({
            id: 'General.EndDayOffset',
          })}
          InputLabelProps={{
            shrink: true,
          }}
          value={form.values.end_day_offset}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          inputProps={{ min: 1 }}
        />
        <TextField
          fullWidth
          variant="outlined"
          type="number"
          name="recurring_interval"
          placeholder={intl.formatMessage({
            id: 'General.RecurringInterval',
          })}
          label={intl.formatMessage({
            id: 'General.RecurringInterval',
          })}
          value={form.values.recurring_interval}
          InputLabelProps={{
            shrink: true,
          }}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          inputProps={{ min: 1 }}
        />
      </div>
      <TextField
        fullWidth
        variant="outlined"
        type="time"
        name="notification_hour"
        placeholder={intl.formatMessage({
          id: 'Notifications.NotificationHour',
        })}
        label={intl.formatMessage({
          id: 'Notifications.NotificationHour',
        })}
        value={form.values.notification_hour}
        InputLabelProps={{
          shrink: true,
        }}
        onChange={form.handleChange}
        onBlur={form.handleBlur}
      />
    </div>
  );
};

export default AllEventsForm;
