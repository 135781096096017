import React from 'react';
import scheduleDeletionConfirmationIcon from 'assets/images/schedule_deletion_confirmation.svg';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import useLogout from '../../../../api/mutations/membership/useLogout';

const AccountDeletionConfirmation = () => {
  const { mutate: logoutMutation } = useLogout();

  const handleBackToStart = () => {
    logoutMutation();
  };

  return (
    <div className="AccountDeletionConfirmation">
      <SVG src={scheduleDeletionConfirmationIcon} />
      <div className="d-flex flex-column align-items-center">
        <FormattedMessage id="MyProfile.AccountDeletion.AccountDeletionModal.sadToSeeYouGo" />
        <span className="AccountDeletionConfirmation__scheduled-date">
          <FormattedMessage id="MyProfile.AccountDeletion.AccountDeletionModal.youAreWelcomeBack" />
        </span>
        <Button className="mt-4" color="primary" onClick={handleBackToStart}>
          <FormattedMessage id="MyProfile.AccountDeletion.AccountDeletionModal.backToStart" />
        </Button>
      </div>
    </div>
  );
};

export default AccountDeletionConfirmation;
