import { IQueryRequest } from '../../../interfaces/request';
import { UserCaseQueryParams } from '../../../interfaces/queryParams';
import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getUserCaseConversationQuestions } from '../../conversations';

const useUserCaseConversationQuestions = ({
  params,
  options,
}: IQueryRequest<UserCaseQueryParams>) => {
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getUserCaseConversationQuestions(
        params.userId,
        params.caseId
      );
      return data.data;
    },
    queryKey: [
      QueryKeys.getUserCaseConversationQuestions,
      params.userId,
      params.caseId,
    ],
  });
};

export default useUserCaseConversationQuestions;
