import React from 'react';
import FieldHtmlEditor from '../../../../../../../../../../components/Fields/Formik/FieldHtmlEditor';
import { Field } from 'formik';
import { useTranslations } from '../../hooks/useTranslations';
import FieldInput from '../../../../../../../../../../components/FieldInput';
import {
  QuestionValidConversationTemplateItem,
  ValidConversationTemplate,
} from '../../../../../../../../../../interfaces/conversations';
import { useGeneratePlaceholder } from '../../hooks/useGeneratePlaceholder';

type BasicQuestionItemEditProps = {
  index: number;
  isDefaultLanguage: boolean;
  values: ValidConversationTemplate;
};

const BasicQuestionItemEdit = ({
  isDefaultLanguage,
  index,
  values,
}: BasicQuestionItemEditProps) => {
  const { providerInvariant } = useTranslations();

  const questionItem = values.content[
    index
  ] as QuestionValidConversationTemplateItem;

  const getPlaceholder = useGeneratePlaceholder(isDefaultLanguage);

  return (
    <>
      <Field
        required={true}
        placeholder={getPlaceholder('title', questionItem.value.title)}
        id={`content[${index}].value.title`}
        name={`content[${index}].value.${
          isDefaultLanguage ? 'title_html' : 'title_html_nb_no'
        }`}
        nonHtmlName={`content[${index}].value.${
          isDefaultLanguage ? 'title' : 'title_nb_no'
        }`}
        label={providerInvariant.wrapWithIntl(
          providerInvariant.attachPrefix('title')
        )}
        type="text"
        component={FieldHtmlEditor}
      />
      <Field
        required={true}
        placeholder={getPlaceholder(
          'shortTitle',
          questionItem.value.short_title
        )}
        id={`content[${index}].value.short_title`}
        name={`content[${index}].value.${
          isDefaultLanguage ? 'short_title_html' : 'short_title_html_nb_no'
        }`}
        nonHtmlName={`content[${index}].value.${
          isDefaultLanguage ? 'short_title' : 'short_title_nb_no'
        }`}
        label={providerInvariant.wrapWithIntl(
          providerInvariant.attachPrefix('shortTitle')
        )}
        type="text"
        component={FieldHtmlEditor}
      />
      <Field
        required={true}
        placeholder={getPlaceholder(
          'description',
          questionItem.value.description
        )}
        id={`content[${index}].value.description`}
        name={`content[${index}].value.${
          isDefaultLanguage ? 'description_html' : 'description_html_nb_no'
        }`}
        nonHtmlName={`content[${index}].value.${
          isDefaultLanguage ? 'description' : 'description_nb_no'
        }`}
        label={providerInvariant.wrapWithIntl(
          providerInvariant.attachPrefix('description')
        )}
        type="text"
        component={FieldHtmlEditor}
      />
      <Field
        required={true}
        id={`content[${index}].value.color`}
        name={`content[${index}].value.color`}
        label={providerInvariant.wrapWithIntl(
          providerInvariant.attachPrefix('color')
        )}
        type="color"
        component={FieldInput}
      />
    </>
  );
};

export default BasicQuestionItemEdit;
