import React from 'react';

type FormContainerProps = {
  fields: JSX.Element;
  agreement?: JSX.Element;
  buttons: JSX.Element;
};

const FormContainer = ({ buttons, fields, agreement }: FormContainerProps) => {
  return (
    <div className="d-flex flex-column justify-content-between h-100">
      <div className="d-flex flex-column gap-3 pt-4 mt-3">{fields}</div>
      {agreement && <div>{agreement}</div>}
      <div className="d-flex gap-2 justify-content-end">{buttons}</div>
    </div>
  );
};

export default FormContainer;
