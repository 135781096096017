import {
  DoctorPersonalityInTimeline,
  IPersonality,
} from 'interfaces/personalities';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { UncontrolledTooltip } from 'reactstrap';
import PersonalityImage from './PersonalityImage';

type MedicalPersonnelInfoProps = {
  members: IPersonality[] | DoctorPersonalityInTimeline[];
  groupKey: string;
};

const MAXIMUM_DOCTORS_LENGTH = 3;

const MedicalPersonnelInfo = ({
  members,
  groupKey,
}: MedicalPersonnelInfoProps) => {
  if (members.length === 0) {
    return (
      <div className="PersonalityCaseListItem__member-info PersonalityCaseListItem__member-info--empty">
        <FormattedMessage id="Patient.noAssignedMedicalPersonnel" />
      </div>
    );
  }

  if (members.length === 1) {
    return (
      <div className="PersonalityCaseListItem__member-info">
        <div className="d-flex align-items-center">
          <PersonalityImage />
          <div className="MedicalPersonnelAssignModal__member-item__data">
            <div className="MedicalPersonnelAssignModal__member-item__data__name">
              {members[0].first_name} {members[0].last_name}
            </div>
            <div className="MedicalPersonnelAssignModal__member-item__data__email">
              {members[0].email}
            </div>
          </div>
        </div>
      </div>
    );
  }

  let membersToRender = [...members];

  if (membersToRender.length > MAXIMUM_DOCTORS_LENGTH)
    membersToRender = members.slice(0, MAXIMUM_DOCTORS_LENGTH);

  const renderedMembers = membersToRender.map((memberPersonality) => {
    const key = `${groupKey}-${memberPersonality.id}`;

    const idOfImage = `image-${key}`;
    return (
      <div key={`el-${key}`}>
        <PersonalityImage
          targetId={idOfImage}
          tooltipContent={`${memberPersonality.first_name} ${memberPersonality?.last_name}`}
        />
      </div>
    );
  });

  const otherDoctorsCount = members.length - MAXIMUM_DOCTORS_LENGTH;
  const isMoreThanMaximumDoctors = otherDoctorsCount > 0;

  const otherDoctorNames = isMoreThanMaximumDoctors
    ? members.slice(MAXIMUM_DOCTORS_LENGTH).map((elPersonality) => (
        <p>
          {elPersonality?.first_name} {elPersonality?.last_name}
        </p>
      ))
    : null;

  const targetId = `additional-doctors-${groupKey}`;

  return (
    <div className="d-flex align-items-center">
      {renderedMembers}
      {isMoreThanMaximumDoctors && (
        <>
          <UncontrolledTooltip target={targetId} placement="top">
            {otherDoctorNames}
          </UncontrolledTooltip>
          <div
            className="PersonalityCaseListItem__member-info ps-2 cursor-pointer"
            id={targetId}
          >
            +{otherDoctorsCount}
          </div>
        </>
      )}
    </div>
  );
};

export default MedicalPersonnelInfo;
