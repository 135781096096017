import { addOrganizationFunctionality } from 'api/functionalities';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

function useAddOrganizationFunctionality(
  organizationId: string,
  successFb?: () => void
) {
  return useMutationWithResultNotification({
    asyncApiFunction: async (functionalityId: string) => {
      await addOrganizationFunctionality(organizationId, functionalityId);
    },
    onSuccess: () => {
      successFb && successFb();
    },
    resetOnResult: true,
  });
}

export default useAddOrganizationFunctionality;
