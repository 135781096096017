import { getGraspFromOrganization } from 'api/graspManagement';
import QueryKeys from 'api/queries/queryKeys';
import { OrganizationQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { useQuery } from 'react-query';
import { IGraspOrganization } from '../../../interfaces/grasps';
import { IErrorWithCode } from '../../../interfaces/response';

interface UseGraspDetailsParams extends OrganizationQueryParams {
  graspId: string;
}

function useGraspDetails({
  params,
  options,
}: IQueryRequest<UseGraspDetailsParams>) {
  const { organizationId, graspId } = params;
  return useQuery<IGraspOrganization, IErrorWithCode, IGraspOrganization>({
    ...options,
    queryFn: async () => {
      const { data } = await getGraspFromOrganization(organizationId, graspId);
      return data.data;
    },
    queryKey: [QueryKeys.getGraspDetails, organizationId, graspId],
  });
}

export default useGraspDetails;
