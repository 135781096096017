import React, { useMemo } from 'react';
import { SingleDataBubbleValue } from 'interfaces/timeline';
import { isSameDay } from 'date-fns';
import classNames from 'classnames';
import CustomBarChart from 'components/CustomBarChart';
type SummaryYAxisProps = {
  dates: Date[];
  selectedDate: Date | null;
  height: number;
  width: number;
  observations: SingleDataBubbleValue[];
  yScaleFunction;
};

const SummaryYAxis = ({
  dates,
  selectedDate,
  height,
  width,
  observations,
  yScaleFunction,
}: SummaryYAxisProps) => {
  const calculatedValues = useMemo(() => {
    let values: Record<string, number> = {};

    for (const singleDate of dates) {
      const filteredValues = observations.filter((obs) =>
        isSameDay(obs.date, singleDate)
      );

      values = {
        ...values,
        [singleDate.getTime()]: filteredValues.length,
      };
    }

    return values;
  }, [dates, observations]);

  const maxObservation = useMemo(() => {
    const values = Object.values(calculatedValues);
    const maxValue = Math.max(...values);

    return maxValue < 1 ? 1 : maxValue;
  }, [calculatedValues]);

  const renderedSummary = Object.keys(calculatedValues).map((date) => {
    const isDateSelected =
      selectedDate && new Date(selectedDate).getTime() === parseFloat(date);

    return (
      <div
        key={`y-axis-${date}`}
        className={classNames('BubbleChart__summary__item', {
          'BubbleChart__summary__item--selected': isDateSelected,
        })}
        style={{
          position: 'absolute',
          top: yScaleFunction(date) + 'px',
        }}
      >
        <div className="BubbleChart__summary__item__container">
          <span className="BubbleChart__summary__item__value">
            {calculatedValues[date]}
          </span>
          <CustomBarChart
            height={8}
            items={[
              {
                name: 'sum',
                value: calculatedValues[date],
              },
            ]}
            colors={['#E0E0E0']}
            total={maxObservation}
            width={width}
            hideLabel
          />
        </div>
      </div>
    );
  });

  return (
    <div
      className="BubbleChart__summary"
      style={{
        height: height + 'px',
        position: 'relative',
      }}
    >
      {renderedSummary}
    </div>
  );
};

export default SummaryYAxis;
