import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/pro-light-svg-icons';
import useRemoveAssetThumbnail from '../../../../../../../../api/mutations/assets/useRemoveAssetThumbnail';
import { useCommonParams } from '../../../../../../hooks/useCommonParams';
import { useCommonData } from '../../../../../../hooks/useCommonData';
import LoadingButton from '../../../../../../../../components/LoadingButton';

type AssetThumbnailRemoveButtonProps = {
  assetId: string;
};

const AssetThumbnailRemoveButton = ({
  assetId,
}: AssetThumbnailRemoveButtonProps) => {
  const { organizationId } = useCommonParams();
  const { educationMaterials } = useCommonData();

  const handleSuccess = async () => {
    await educationMaterials.refetch();
  };

  const { mutateAsync: removeAsync, isLoading: isRemoving } =
    useRemoveAssetThumbnail({
      params: { organizationId },
      options: { successFb: handleSuccess },
    });

  const handleRemove = async () => {
    await removeAsync(assetId);
  };

  return (
    <LoadingButton
      isLoading={isRemoving}
      color="primary"
      outline={true}
      onClick={handleRemove}
    >
      <FontAwesomeIcon icon={faTrash} />
    </LoadingButton>
  );
};

export default AssetThumbnailRemoveButton;
