import useConversationTemplates from '../../../../../../../api/queries/conversations/useConversationTemplates';
import { ConversationTemplateContext } from '../../../../../../../interfaces/conversations';
import useRequiredParams from '../../../../../../../hooks/useRequiredParams';
import { useMemo } from 'react';

export const useProjectModalSubmissionSetupForm = () => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const { data: conversationFormTemplates } = useConversationTemplates({
    params: {
      organizationId: organizationId,
      context: ConversationTemplateContext.Form,
    },
  });

  const conversationTemplatesInputOptions = useMemo(() => {
    if (!conversationFormTemplates) return [];

    return conversationFormTemplates.map((template) => ({
      value: template.id,
      text: template.display_code,
    }));
  }, [conversationFormTemplates]);

  return {
    options: {
      conversationTemplates: conversationTemplatesInputOptions,
    },
  };
};
