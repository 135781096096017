import React from 'react';
import { FormattedMessage } from 'react-intl';
import { wrapWithSequenceIdentified } from '../../../../../../../../helpers/utils/data';
import { useTranslations } from '../../../../../../../../hooks/useTranslations';
import { useTypedContext } from '../../../../../../../../hooks/useTypedContext';
import { DashboardFilterHelpers } from '../../helpers/filters';
import { DashboardDataReducerContext } from '../../types';
import DashboardDateRangeFilter from './components/DashboardDateRangeFilter';
import AwareCasesFilter from './components/DashboardFilter';
import { useHandleClearAll } from './hooks/useHandleClearAll';

const DashboardFilters = () => {
  const [dashboardData] = useTypedContext(DashboardDataReducerContext);

  const { attachPrefix } = useTranslations(
    'Organizations.OrganizationDashboard.AwareCasesDashboard.DashboardFilters'
  );

  const handleClearAll = useHandleClearAll();

  const filtersHidden = DashboardFilterHelpers.AreFiltersHidden(
    dashboardData.template.filters
  );

  return (
    <div className="AwareCasesFilters">
      {!filtersHidden && (
        <>
          <div className="AwareCasesFilters__date-range d-none d-md-flex">
            <DashboardDateRangeFilter />
          </div>
          <div className="AwareCasesFilters__filters">
            {dashboardData.template.filters.length > 0 && (
              <span className="AwareCasesFilters__clear-all">
                <FormattedMessage id={attachPrefix('filters')} />
              </span>
            )}
            {wrapWithSequenceIdentified(dashboardData.template.filters).map(
              (filter) => (
                <AwareCasesFilter key={filter.sequence} filter={filter} />
              )
            )}
            {dashboardData.template.filters.length > 0 && (
              <span
                className="AwareCasesFilters__clear-all"
                onClick={handleClearAll}
              >
                <FormattedMessage id={attachPrefix('clearAll')} />
              </span>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DashboardFilters;
