import { useCallback } from 'react';
import { useTypedContext } from '../../../../../../../../../hooks/useTypedContext';
import { displayErrorNotification } from '../../../../../../../../Notifications';
import { TreatmentPlanDataContext } from '../../../../../types';

export const useCodeVerification = () => {
  const { categories } = useTypedContext(TreatmentPlanDataContext);

  return useCallback(
    (
      managedPhaseId: string,
      initialPhaseId: string,
      treatmentPlanId: string,
      addMode: boolean
    ) => {
      if (addMode || managedPhaseId === initialPhaseId) return true;

      let subCategories =
        categories.find((p) => p.id === treatmentPlanId)?.sub_categories ?? [];

      subCategories = subCategories.filter((p) => p.id !== initialPhaseId);

      if (subCategories.map((p) => p.code).includes(managedPhaseId)) {
        displayErrorNotification(
          'Projects.ProjectPhases.ProjectPhasesContent.ProjectPhase.TreatmentPhaseAddTemplateModal.duplicatedCodes'
        );

        return false;
      }

      return true;
    },
    [categories]
  );
};
