import useOpen from '../../../../../hooks/useOpen';
import { useState } from 'react';
import { ConversationTemplate } from '../../../../../interfaces/conversations';
import { cloneDeep } from 'lodash';
import { useCommonParams } from '../../../hooks/useCommonParams';
import { getEmptyConversationTemplate } from '../../../../../helpers/data/conversations';

export const useAddTemplate = () => {
  const { organizationId } = useCommonParams();
  const { isOpen, toggle } = useOpen();

  const [templateToAdd, setTemplateToAdd] =
    useState<ConversationTemplate | null>(null);

  const enableAddTemplateMode = () => {
    setTemplateToAdd(cloneDeep(getEmptyConversationTemplate(organizationId)));
    toggle();
  };

  const disableAddTemplateMode = () => {
    setTemplateToAdd(null);
    toggle();
  };

  const toggleAddTemplateMode = () => {
    isOpen ? disableAddTemplateMode() : enableAddTemplateMode();
  };

  return {
    isAddTemplateModalOpen: isOpen,
    toggleAddTemplateModal: toggleAddTemplateMode,
    enableAddTemplateMode: enableAddTemplateMode,
    disableAddTemplateMode: disableAddTemplateMode,
    templateToAdd: templateToAdd,
  };
};
