import { useEffect, useMemo, useState } from 'react';
import {
  AnnotationRedirectionState,
  TimelineTabsProps,
} from 'interfaces/timeline';
import {
  AssessmentFormIdentification,
  AssessmentFormRedirectionState,
} from 'interfaces/assessmentForms';
import { useDispatch } from 'react-redux';
import { Col, Row } from 'reactstrap';
import DayByDayChart from './components/DayByDayChart';
import DayDetails from './components/DayDetails';
import useTimelineDetails from 'api/queries/timelines/useTimelineDetails';
import { useParams } from 'react-router';
import { formatDateToLast30days } from 'helpers/dates';
import TimelineButtonGroup from './components/TimelineButtonGroup';
import { setAssessmentFormIdentification } from 'modules/TimelineDetails/actions';
import useOpen from 'hooks/useOpen';
import AnnotationModal from './components/AnnotationModal';
import { getUserId } from 'helpers/utils/auth';
import OrganizationContextQuestionModal from './components/QuestionModal/OrganizationContextQuestionModal';
import UserContextQuestionModal from './components/QuestionModal/UserContextQuestionModal';
import {
  useCaseFormsDayDetailsRefetchRequest,
  useTimelineDetailsAssessmentFormIdentification,
  useTimelineDetailsDateRangeObject,
  useTimelineDetailsDayByDayFullSize,
  useTimelineDetailsSelectedDate,
} from 'store/reducerHooks';

const DayByDayTab = ({
  timelineId,
  caseDedicatedTimelineId,
  formRedirectionState,
  annotationRedirectionState,
}: TimelineTabsProps) => {
  const dispatch = useDispatch();

  const {
    isOpen: annotationIsOpen,
    close: annotationCloseModal,
    open: annotationOpenModal,
  } = useOpen();

  const [assessmentFormRedirectionState, setAssessmentFormRedirectionState] =
    useState<AssessmentFormRedirectionState | undefined>(formRedirectionState);

  const [userAnnotationRedirectionState, setUserAnnotationRedirectionState] =
    useState<AnnotationRedirectionState | undefined>(
      annotationRedirectionState
    );

  const userId = getUserId();

  const { caseId, organizationId } = useParams<{
    caseId: string;
    organizationId: string;
  }>();

  const selectedDate = useTimelineDetailsSelectedDate() ?? new Date();
  const dateRangeObject = useTimelineDetailsDateRangeObject();
  const selectedFormIdentification =
    useTimelineDetailsAssessmentFormIdentification();
  const refetchRequest = useCaseFormsDayDetailsRefetchRequest();

  const closeModal = () => {
    dispatch(setAssessmentFormIdentification(null));
    setAssessmentFormRedirectionState(undefined);
  };

  const closeModalAndClearAnnotationRedirection = () => {
    annotationCloseModal();
    setUserAnnotationRedirectionState(undefined);
  };

  const { data: timelineDetails } = useTimelineDetails({
    params: { timelineId },
    options: { enabled: false },
  });

  const selectedDates = useMemo(() => {
    if (!dateRangeObject) return undefined;

    const formattedDate = formatDateToLast30days(dateRangeObject);
    if (!formattedDate?.from || !formattedDate?.to) return undefined;

    return {
      from: formattedDate.from,
      to: formattedDate.to,
    };
  }, [dateRangeObject]);

  const isOwner = userId === timelineDetails?.user_id;

  useEffect(() => {
    if (assessmentFormRedirectionState) {
      dispatch(
        setAssessmentFormIdentification({
          selectedSequence: assessmentFormRedirectionState.sequence,
          contextObjectId: null,
          contextType: null,
        })
      );
    }
  }, [assessmentFormRedirectionState, dispatch]);

  useEffect(() => {
    if (userAnnotationRedirectionState) {
      annotationOpenModal();
    }
  }, [userAnnotationRedirectionState, annotationOpenModal]);

  const isFullSize = useTimelineDetailsDayByDayFullSize();
  const modalProps = {
    closeModal,
    assessmentFormIdentification: assessmentFormRedirectionState
      ? {
          contextObjectId: null,
          contextType: null,
          selectedSequence: assessmentFormRedirectionState.sequence,
        }
      : (selectedFormIdentification as AssessmentFormIdentification),
    selectedDate: assessmentFormRedirectionState
      ? assessmentFormRedirectionState.selectedDate
      : selectedDate,
    timelineId: timelineId,
  };

  const renderAssessmentModal = () => {
    if (!timelineDetails) return null;

    if (caseId && organizationId) {
      return (
        <OrganizationContextQuestionModal
          {...modalProps}
          caseId={caseId}
          organizationId={organizationId}
          userId={timelineDetails.user_id}
        />
      );
    } else {
      return (
        <UserContextQuestionModal
          {...modalProps}
          userId={timelineDetails.user_id}
        />
      );
    }
  };

  return (
    <>
      <Row>
        {selectedDates && (
          <>
            <Col lg={12} xl={isFullSize ? 12 : 8}>
              <DayByDayChart
                refetchRequest={refetchRequest}
                timelineId={timelineId}
                selectedDates={selectedDates}
                userId={timelineDetails?.user_id}
              />
            </Col>
            <Col lg={12} xl={isFullSize ? 12 : 4}>
              <DayDetails
                timelineId={timelineId}
                selectedDates={selectedDates}
              />
            </Col>
          </>
        )}
      </Row>
      {selectedDate && timelineDetails && (
        <TimelineButtonGroup
          caseDedicatedTimelineId={caseDedicatedTimelineId}
          isTimelineUsersMaster={
            timelineDetails.is_master === true &&
            timelineDetails.user_id !== null
          }
          isOwner={isOwner}
          timelineId={timelineId}
          selectedDates={selectedDates}
          selectedDate={selectedDate}
        />
      )}
      {renderAssessmentModal()}
      {userAnnotationRedirectionState && (
        <AnnotationModal
          annotationId={userAnnotationRedirectionState.annotationId}
          timelineId={timelineId}
          isOpen={annotationIsOpen}
          closeModal={closeModalAndClearAnnotationRedirection}
        />
      )}
    </>
  );
};

export default DayByDayTab;
