import { signIn } from 'api/membership';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { getUserEmail } from '../../../helpers/utils/auth';

type VerifyUserPasswordBody = {
  password: string;
};

function useVerifyUsersPassword(successFb?: () => void, errorFb?: () => void) {
  return useMutationWithResultNotification({
    asyncApiFunction: async (body: VerifyUserPasswordBody) => {
      const loggedUserEmail = getUserEmail();

      return signIn({
        email: loggedUserEmail,
        password: body.password,
        application_type: 'Web',
      });
    },
    resetOnResult: true,
    onSuccess: () => {
      successFb && successFb();
    },
    onError: () => {
      errorFb && errorFb();
    },
  });
}

export default useVerifyUsersPassword;
