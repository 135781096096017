import { OpenHookValues } from 'hooks/useOpen';
import React, { PropsWithChildren } from 'react';
import BreadcrumbTabLayout from './components/BreadcrumbTabLayout';
import GenericTabLayout from './components/GenericTabLayout';
import { TabHeaderTooltip } from './components/GenericTabLayout/components/TabHeader';

export enum TabLayoutType {
  Generic = 'Generic',
  Breadcrumb = 'Breadcrumb',
}

type TabLayoutProps = {
  type: TabLayoutType;
  titlePrefix?: string;
  subHeader?: boolean;
  tooltip?: TabHeaderTooltip;
  actions?: JSX.Element;
  editMode?: OpenHookValues;
};

const TabLayout = ({ type, ...props }: PropsWithChildren<TabLayoutProps>) => {
  if (type === TabLayoutType.Breadcrumb)
    return <BreadcrumbTabLayout {...props} />;
  return <GenericTabLayout {...props} />;
};

export default TabLayout;
