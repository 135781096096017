import { addTimelineObservation } from 'api/graspManagement';
import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import { IObservationCreateBody } from 'interfaces/timeline';
import {
  displayErrorArrayNotificationsWithoutTranslations,
  displaySuccessNotification,
} from 'modules/Notifications';

function useAddTimelineObservation(timelineId: string, successFb?: () => void) {
  return useMutationWithResultNotification({
    asyncApiFunction: async (data: IObservationCreateBody) => {
      await addTimelineObservation(timelineId, data);
    },
    resetOnResult: true,
    onSuccess: () => {
      displaySuccessNotification('TimelineDetails.ObservationAdd.addSuccess');
      successFb && successFb();
    },
    onErrorArray: (errors) => {
      displayErrorArrayNotificationsWithoutTranslations(errors);
    },
  });
}

export default useAddTimelineObservation;
