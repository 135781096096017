import useUserContextAssessmentFormsForPeriod from 'api/queries/assessmentForms/forms/period/useUserContextAssessmentFormsForPeriod';
import { getUserId } from 'helpers/utils/auth';
import React from 'react';
import QuestionModal, { QuestionModalProps } from '.';

interface UserContextQuestionModalProps extends QuestionModalProps {
  selectedDates?: {
    from: Date;
    to: Date;
  };
}

const UserContextQuestionModal = ({
  selectedDates,
  ...props
}: UserContextQuestionModalProps) => {
  const userId = getUserId();

  const { refetch } = useUserContextAssessmentFormsForPeriod({
    params: {
      userId: userId,
      selectedDates,
      timelineId: props.timelineId,
    },
    options: { enabled: false },
  });

  return <QuestionModal {...props} refetchCallback={refetch} />;
};

export default UserContextQuestionModal;
