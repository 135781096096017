import React from 'react';
import { useTranslations } from '../../hooks/useTranslations';
import { Spinner } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { useQueriesLoading } from '../../hooks/queries/useQueriesLoading';
import { useQueriesFetched } from '../../hooks/queries/useQueriesFetched';
import { useQueriesFailure } from '../../hooks/queries/useQueriesFailure';
import { useQueriesData } from '../../hooks/queries/useQueriesData';

export interface IQueryLike<T> {
  isLoading: boolean;
  isFetched: boolean;
  isError: boolean;
  data: T | undefined;
}

type QueriesLoadedProps = {
  queries: IQueryLike<any>[];
  onLoading?: JSX.Element;
  onError?: JSX.Element;
  children: (data: any[]) => JSX.Element;
};

const QueriesLoaded = ({
  queries,
  onLoading,
  onError,
  children,
}: QueriesLoadedProps) => {
  const { attachPrefix } = useTranslations('QueriesLoaded');

  const genericLoading = (
    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
      <Spinner color="primary" />
    </div>
  );

  const genericError = <FormattedMessage id={attachPrefix('error')} />;

  const isLoading = useQueriesLoading(queries);
  const isFetched = useQueriesFetched(queries);
  const isError = useQueriesFailure(queries);
  const hasData = useQueriesData(queries);

  if (isLoading) return onLoading ?? genericLoading;
  if (isFetched && isError) return onError ?? genericError;
  if (isFetched && hasData) return children(queries.map((query) => query.data));

  return <></>;
};

export default QueriesLoaded;
