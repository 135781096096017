import React from 'react';
import { useTypedContext } from '../../../../../../../../../../hooks/useTypedContext';
import ForEach from '../../../../../../../../../ForEach';
import { DashboardDataReducerContext } from '../../../../types';
import DataLimitConstraint from './components/DataLimitConstraint';
import Visualization from './components/Visualization';
import VisualizationLayout from './layouts/VisualizationLayout';

const Visualizations = () => {
  const [dashboardData] = useTypedContext(DashboardDataReducerContext);

  return (
    <div className="d-flex flex-column gap-2">
      <DataLimitConstraint />
      <VisualizationLayout>
        <ForEach items={dashboardData.template.visualizations}>
          {(visualization) => (
            <Visualization
              key={visualization.sequence}
              visualization={visualization}
            />
          )}
        </ForEach>
      </VisualizationLayout>
    </div>
  );
};

export default Visualizations;
