import React from 'react';
import { formatDate } from '../../../../../../helpers/dates';
import {
  IReport,
  ReportExecution,
  ReportType,
} from '../../../../../../interfaces/reports';
import SVG from 'react-inlinesvg';
import reportProcessing from '../../../../../../assets/images/report_processing.svg';
import reportDone from '../../../../../../assets/images/report_done.svg';
import download from '../../../../../../assets/images/download_report.svg';
import { FormattedMessage } from 'react-intl';
import { useHandleDownloadReport } from './hooks/useHandleDownloadReport';

type ReportsListItemProps = {
  item: IReport;
};

const ReportsListItem = ({ item }: ReportsListItemProps) => {
  const { downloadReport, handleDownloadReport, selectedExecutionId } =
    useHandleDownloadReport(item);

  const reportSrc =
    item.execution_status === ReportExecution.Processing
      ? reportProcessing
      : reportDone;

  return (
    <tr key={item.id} className="ReportsList__row">
      <td>
        <b>{formatDate(item.requested_at.toString())}</b>
      </td>
      <td>{`${item.requester_first_name} ${item.requester_last_name}`}</td>
      <td>{ReportType[item.code]}</td>
      <td
        className={`ReportsList__row__execution ReportsList__row__execution--${item.execution_status}`}
      >
        <SVG src={reportSrc} title="ReportIndicator" />
        <span>{item.execution_status}</span>
      </td>
      <td className="ReportsList__row__download">
        {item.blob ? (
          <div className="ReportsList__row__download__item ReportsList__row__download__item--active">
            <SVG src={download} title="Download" />
            {downloadReport.data !== undefined &&
            item.id === selectedExecutionId ? (
              <a href={downloadReport.data}>
                <FormattedMessage id="Reports.ReportsList.clickToDownload" />
              </a>
            ) : (
              <span onClick={handleDownloadReport}>
                <FormattedMessage id="Reports.ReportsList.clickToDownload" />
              </span>
            )}
          </div>
        ) : (
          <div className="ReportsList__row__download__item ReportsList__row__download__item--disabled">
            <SVG src={download} title="Download" />
            <FormattedMessage id="Reports.ReportsList.unableToDownload" />
          </div>
        )}
      </td>
    </tr>
  );
};

export default ReportsListItem;
