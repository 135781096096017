import useInfiniteCaseHistory from 'api/queries/cases/useInfiniteCaseHistory';

import receivedImg from 'assets/images/change/received.svg';
import PersonalityImage from 'components/PersonalityImage';
import { format } from 'date-fns';
import { ICaseHistory } from 'interfaces/caseHistory';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTimelineDetailsDateRangeObject } from 'store/reducerHooks';
import { useOrganizationCasePathParams } from '../../../../../../../../../../../../hooks/paths/useOrganizationCasePathParams';
import { useCommonData } from '../../hooks/useCommonData';

type MessageData = {
  message: string;
  date: Date;
  received: boolean;
  dateReceived: Date | null;
};

const sortHistory = (prev: ICaseHistory, next: ICaseHistory) => {
  if (prev.time_start > next.time_start) return -1;
  if (prev.time_start < next.time_start) return 1;
  return 0;
};

const MessagesSent = () => {
  const { organizationId, caseId } = useOrganizationCasePathParams();

  const { casePhaseHistory } = useCommonData();

  const dateRangeObject = useTimelineDetailsDateRangeObject();

  const selectedDates =
    !!dateRangeObject && !!dateRangeObject.to && !!dateRangeObject.from
      ? { from: dateRangeObject.from, to: dateRangeObject.to }
      : null;

  const { history } = useInfiniteCaseHistory({
    params: {
      caseId,
      organizationId,
      pageSize: 1000,
      selectedDates,
    },
    options: { enabled: !!dateRangeObject && casePhaseHistory.isFetched },
  });

  const messages = useMemo(() => {
    const filteredHistory = history.filter(
      (historyItem) => historyItem.share_with_patient && !!historyItem.text
    );

    let messagesObj: {
      [key: string]: MessageData[];
    } = {};

    const orderedHistory = [...filteredHistory].sort(sortHistory);

    orderedHistory.forEach((history) => {
      const dateWithoutHours = format(
        new Date(history.time_start),
        'dd.MM.yyyy'
      );

      const messageData: MessageData = {
        message: history.text,
        date: new Date(history.time_start),
        received: history.participant_received,
        dateReceived: history.updated_at ? new Date(history.updated_at) : null,
      };

      const existingObject = messagesObj[dateWithoutHours]
        ? messagesObj[dateWithoutHours]
        : [];

      messagesObj[dateWithoutHours] = [...existingObject, messageData];
    });

    return messagesObj;
  }, [history]);

  const dates = Object.keys(messages);

  const renderedMessages = useMemo(
    () =>
      dates.map((date) => {
        const messagesInDay = messages[date];
        const dateSplit = date.split('.');
        const dateValue = new Date(
          parseInt(dateSplit[2]),
          parseInt(dateSplit[1]) - 1,
          parseInt(dateSplit[0])
        );

        return (
          <div key={`message-container-${date.toString()}`}>
            <div className="ChangeCase__date-header">
              {format(dateValue, 'dd.MM.yyyy')}
            </div>
            {messagesInDay.map(({ date, message, received, dateReceived }) => (
              <div className="ChangeCase__message-item" key={message}>
                <PersonalityImage />
                <div className="ChangeCase__message-item__message">
                  <div
                    className="ChangeCase__message-item__message__text"
                    dangerouslySetInnerHTML={{ __html: message }}
                  ></div>
                  <div className="w-100 d-flex justify-content-between">
                    <div className="ChangeCase__message-item__message__date">
                      {format(new Date(date), 'HH:mm aa')}
                    </div>
                    {received && !!dateReceived && (
                      <div className="ChangeCase__message-item__message__date ms-4">
                        <img
                          src={receivedImg}
                          alt={receivedImg}
                          className="me-1"
                        />
                        <span className="me-1">
                          <FormattedMessage id="Cases.ChangeCase.Messages.readAt" />
                        </span>
                        {format(new Date(dateReceived), 'HH:mm aa')}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        );
      }),
    [dates, messages]
  );

  const isAnyMessage = renderedMessages.length > 0;

  return (
    <div className="mt-4">
      <div className="ChangeCase__messages-wrapper__content">
        <h3 className="ChangeCase__messages-wrapper__header">
          <FormattedMessage id="Cases.ChangeCase.messagesSent" />:
        </h3>
        {isAnyMessage && renderedMessages}
        {!isAnyMessage && (
          <p className="ChangeCase__messages-wrapper__placeholder">
            <FormattedMessage id="Cases.ChangeCase.noMessagesToDisplay" />
          </p>
        )}
      </div>
    </div>
  );
};

export default MessagesSent;
