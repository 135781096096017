import { DashboardTemplate } from 'interfaces/dashboards';
import React from 'react';
import PageHeader from '../../../PageHeader';
import DashboardContentContainer from './components/DashboardContentContainer';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';

type DashboardProps = {
  template: DashboardTemplate;
};

const Dashboard = ({ template }: DashboardProps) => {
  const { breadcrumbs, isLoading } = useBreadcrumbs(template);

  return (
    <PageHeader
      breadcrumbs={breadcrumbs}
      isLoading={isLoading}
      content={<DashboardContentContainer template={template} />}
    />
  );
};

export default Dashboard;
