import { useMemo } from 'react';
import { TrendVisualizationTemplate } from 'interfaces/trendVisualizations';
import { useTranslations } from 'hooks/useTranslations';

export const useTemplateSummary = (template: TrendVisualizationTemplate) => {
  const { wrapWithIntl, attachPrefix } = useTranslations(
    'MyOrganizations.trendVisualizations'
  );

  return useMemo(() => {
    return wrapWithIntl(attachPrefix('templateSummary'), {
      visualizations: template.visualizations.length,
    });
  }, [attachPrefix, template.visualizations.length, wrapWithIntl]);
};
