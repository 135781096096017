import { faMobileAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createTheme, ThemeProvider } from '@mui/material';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import TextField from '@mui/material/TextField';
import { Formik } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { OrganizationDeviceType } from '../../../../../../../../../../interfaces/organizations';
import { BaseModalProps } from '../../../../../../../../../../interfaces/ui';
import { useForm } from './hooks/useForm';

type AddDeviceModalProps = BaseModalProps;

const AddDeviceModal = (props: AddDeviceModalProps) => {
  const formHandler = useForm(props);

  return (
    <ThemeProvider
      theme={createTheme({
        components: {
          MuiSvgIcon: {
            styleOverrides: {
              root: { padding: 0 },
            },
          },
        },
      })}
    >
      <Modal
        isOpen={props.isOpen}
        toggle={props.toggle}
        className="GenericModal BorderlessModal"
        size="md"
      >
        <ModalHeader toggle={props.toggle}>
          <FontAwesomeIcon icon={faMobileAlt} />
          <FormattedMessage id="Organizations.AddDevice" />
        </ModalHeader>
        <Formik
          initialValues={formHandler.initialValues}
          validationSchema={formHandler.validationSchema}
          enableReinitialize={true}
          validateOnMount={true}
          onSubmit={formHandler.handleSubmit}
        >
          {({
            values,
            handleChange,
            handleBlur,
            touched,
            errors,
            submitForm,
            isValid,
          }) => (
            <>
              <ModalBody className="d-flex flex-column gap-3">
                <TextField
                  variant="outlined"
                  fullWidth
                  id="name"
                  name="name"
                  label={<FormattedMessage id="General.Name" />}
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.name && Boolean(errors.name)}
                  helperText={touched.name && errors.name}
                />
                <TextField
                  variant="outlined"
                  fullWidth
                  id="serial_number"
                  name="serial_number"
                  label={<FormattedMessage id="Organizations.SerialNumber" />}
                  value={values.serial_number}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.serial_number && Boolean(errors.serial_number)}
                  helperText={touched.serial_number && errors.serial_number}
                />
                <TextField
                  variant="outlined"
                  fullWidth
                  select={true}
                  id="type"
                  name="type"
                  label={<FormattedMessage id="General.Type" />}
                  value={values.type}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.type && Boolean(errors.type)}
                  helperText={touched.type && errors.type}
                >
                  {Object.entries(OrganizationDeviceType).map(([key, val]) => (
                    <MenuItem key={key} value={val}>
                      <FormattedMessage
                        id={`Enums.OrganizationDeviceType.${val}`}
                      />
                    </MenuItem>
                  ))}
                </TextField>
                {values.type === OrganizationDeviceType.Ipad && (
                  <>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="apple_id"
                      name="apple_id"
                      label={<FormattedMessage id="Organizations.AppleId" />}
                      value={values.apple_id}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={touched.apple_id && Boolean(errors.apple_id)}
                      helperText={touched.apple_id && errors.apple_id}
                    />
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="apple_password"
                      name="apple_password"
                      label={
                        <FormattedMessage id="Organizations.ApplePassword" />
                      }
                      value={values.apple_password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={
                        touched.apple_password && Boolean(errors.apple_password)
                      }
                      helperText={
                        touched.apple_password && errors.apple_password
                      }
                    />
                  </>
                )}
                {(values.type === OrganizationDeviceType.Ipad ||
                  values.type === OrganizationDeviceType.Other) && (
                  <TextField
                    variant="outlined"
                    fullWidth
                    id="screen_lock_code"
                    name="screen_lock_code"
                    label={
                      <FormattedMessage id="Organizations.ScreenLockCode" />
                    }
                    value={values.screen_lock_code}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={
                      touched.screen_lock_code &&
                      Boolean(errors.screen_lock_code)
                    }
                    helperText={
                      touched.screen_lock_code && errors.screen_lock_code
                    }
                  />
                )}
              </ModalBody>
              <ModalFooter>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={!isValid}
                  onClick={submitForm}
                >
                  <FormattedMessage id="General.Save" />
                </Button>
              </ModalFooter>
            </>
          )}
        </Formik>
      </Modal>
    </ThemeProvider>
  );
};

export default AddDeviceModal;
