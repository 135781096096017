import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import PageHeader from '../../../../components/PageHeader';
import { useCaseGroupsCommonData } from '../../hooks/useCaseGroupsCommonData';
import { Button } from 'reactstrap';
import { useInitQuery } from '../../../../hooks/queries/useInitQuery';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type CaseGroupBreadcrumbAction = {
  icon: IconDefinition;
  translationId?: string;
  onAction: () => any;
};

type CaseGroupBreadcrumbsProps = {
  actions?: CaseGroupBreadcrumbAction[];
};

const CaseGroupBreadcrumbs = ({ actions }: CaseGroupBreadcrumbsProps) => {
  const { organization } = useCaseGroupsCommonData();

  useInitQuery(organization);

  const breadcrumbs = useMemo(
    () => [
      {
        text: <FormattedMessage id="CaseGroups.breadcrumbs.organization" />,
        link: null,
        key: 'organization',
      },
      {
        text: organization.data?.name ?? '',
        link: `/my-organizations/${organization.data?.id}`,
        key: 'organization-name',
      },
      {
        text: <FormattedMessage id="CaseGroups.breadcrumbs.caseGroups" />,
        link: null,
        key: 'case-groups',
      },
    ],
    [organization.data?.id, organization.data?.name]
  );

  return (
    <PageHeader
      breadcrumbs={breadcrumbs}
      content={
        <div className="d-flex justify-content-between mt-5">
          <span className="OrganizationPageHeader__text">
            <FormattedMessage id="MyOrganizations.caseGroups.header" />
          </span>
          <div className="d-flex align-items-center gap-2">
            {actions?.map((action) => (
              <Button
                key={action.translationId}
                color="primary"
                outline={true}
                onClick={action.onAction}
              >
                <FontAwesomeIcon icon={action.icon} />
                {action.translationId && (
                  <FormattedMessage id={action.translationId} />
                )}
              </Button>
            ))}
          </div>
        </div>
      }
    />
  );
};

export default CaseGroupBreadcrumbs;
