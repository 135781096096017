import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { useIntl } from 'react-intl';
import useModifyTreatmentPhase from '../../../../../../../../../../../api/mutations/conversations/useModifyTreatmentPhase';
import { ScheduleItemDefinition } from '../../../../../../../../../../../components/Schedule';
import { OpenHookValues } from '../../../../../../../../../../../hooks/useOpen';
import useRequiredParams from '../../../../../../../../../../../hooks/useRequiredParams';
import {
  ISummarizedTreatmentPhase,
  TreatmentPhaseContentItemType,
} from '../../../../../../../../../../../interfaces/conversations';
import { ConversationScheduleType } from '../../../../../../../../../../../interfaces/conversationSchedules';
import { useTreatmentPlanCommonData } from '../../../../../../../hooks/useTreatmentPlanCommonData';
import { useTreatmentPhaseCode } from '../../../../../hooks/useTreatmentPhaseCode';
import { ContentIndexHookValues } from '../../../hooks/useContentIndex';

export const useConversationSchedules = (
  initialPhase: ISummarizedTreatmentPhase,
  contentIndex: ContentIndexHookValues,
  defaultTemplateMode: OpenHookValues,
  addTemplateModal: OpenHookValues
) => {
  const intl = useIntl();

  let items: ScheduleItemDefinition[] = [];

  const { organizationId, treatmentPlanId } = useRequiredParams<{
    organizationId: string;
    treatmentPlanId: string;
  }>(['organizationId', 'treatmentPlanId']);

  const getPhaseCode = useTreatmentPhaseCode();

  const { caseCategories, treatmentPhases } = useTreatmentPlanCommonData();

  const onSuccess = async () => {
    if (addTemplateModal.isOpen) addTemplateModal.toggle();
    await caseCategories.refetch();
    await treatmentPhases.refetch();
  };

  const { mutateAsync: modifyAsync } = useModifyTreatmentPhase(
    organizationId,
    treatmentPlanId,
    onSuccess
  );

  const handleRemoveScheduleItem = useCallback(
    async (index: number) => {
      let phaseCopy = cloneDeep(initialPhase);
      phaseCopy.content = phaseCopy.content.filter((p, idx) => idx !== index);

      await modifyAsync({
        treatmentPhaseId: phaseCopy.id,
        body: {
          next_phase_condition: phaseCopy.next_phase_condition,
          display_code: phaseCopy.display_code,
          sequence: phaseCopy.sequence,
          empty_conversation_template_id:
            phaseCopy.empty_conversation_template_id,
          content: phaseCopy.content,
          is_default: phaseCopy.is_default,
          days_duration: phaseCopy.days_duration,
          organization_id: organizationId,
          code: getPhaseCode(phaseCopy.id),
        },
      });
    },
    [getPhaseCode, initialPhase, modifyAsync, organizationId]
  );

  const handleEditDefaultTemplate = useCallback(() => {
    defaultTemplateMode.open();
    contentIndex.clearIndex();
    addTemplateModal.toggle();
  }, [addTemplateModal, contentIndex, defaultTemplateMode]);

  initialPhase.content.forEach((template, index) => {
    if (template.type !== TreatmentPhaseContentItemType.ConversationSchedule)
      return;

    const handleRemove = async () => {
      await handleRemoveScheduleItem(index);
    };

    const handleEdit = async () => {
      defaultTemplateMode.close();
      contentIndex.selectIndex(index);
      addTemplateModal.toggle();
    };

    items.push({
      rowId: index.toString(),
      sequence: index,
      templateId: template.template_id,
      priority: template.priority,
      scheduleTemplate: template.schedule_templates.at(0)
        ? {
            schedule_type: template.schedule_templates[0].schedule_type,
            parts_of_a_day: template.schedule_templates[0].parts_of_a_day.map(
              (partOfADay) => ({
                available_from_hour: partOfADay.available_from_hour,
                available_to_hour: partOfADay.available_to_hour,
                notification_hour: partOfADay.notification_hour,
                is_deleted: false,
                is_dirty: false,
              })
            ),
            end_day_offset: template.schedule_templates[0].end_day_offset,
            recurring_interval:
              template.schedule_templates[0].recurring_interval,
            specific_day_of_phase:
              template.schedule_templates[0].specific_day_of_phase,
            start_day_offset: template.schedule_templates[0].start_day_offset,
          }
        : {
            schedule_type: ConversationScheduleType.NonTimeBased,
            recurring_interval: null,
            start_day_offset: null,
            end_day_offset: null,
            specific_day_of_phase: null,
            parts_of_a_day: [],
          },
      onRemove: handleRemove,
      onEdit: handleEdit,
    });
  });

  items = items.sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0));

  if (initialPhase.empty_conversation_template_id)
    items.push({
      rowId: '-1',
      sequence: -1,
      templateId: initialPhase.empty_conversation_template_id,
      label: intl.formatMessage({
        id: 'ConversationsAndFeedbacks.ConversationTemplateModal.options.triggerType.PhaseLastResort',
      }),
      onEdit: handleEditDefaultTemplate,
    });

  return items;
};
