import { IProjectSubmissionFilters } from 'interfaces/submissions';

type ProjectSubmissionState = {
  submissionListFilters: IProjectSubmissionFilters;
  submissionListSearchFieldValue: string;
};

const initialSubmissionState: ProjectSubmissionState = {
  submissionListFilters: {
    completed: false,
  },
  submissionListSearchFieldValue: '',
};

export enum SubmissionActionName {
  SUBMISSION_SET_LIST_FILTERS = 'SUBMISSION_SET_LIST_FILTERS',
  SUBMISSION_SET_LIST_SEARCH_FIELD_VALUE = 'SUBMISSION_SET_LIST_SEARCH_FIELD_VALUE',
}

type SubmissionAction =
  | {
      type: SubmissionActionName.SUBMISSION_SET_LIST_FILTERS;
      filters: IProjectSubmissionFilters;
    }
  | {
      type: SubmissionActionName.SUBMISSION_SET_LIST_SEARCH_FIELD_VALUE;
      fieldValue: string;
    };

const submissionReducer = (
  state = initialSubmissionState,
  action: SubmissionAction
) => {
  switch (action.type) {
    case SubmissionActionName.SUBMISSION_SET_LIST_FILTERS:
      return {
        ...state,
        submissionListFilters: action.filters,
      };
    case SubmissionActionName.SUBMISSION_SET_LIST_SEARCH_FIELD_VALUE:
      return {
        ...state,
        submissionListSearchFieldValue: action.fieldValue,
      };
    default:
      return state;
  }
};

export default submissionReducer;
