import { useInitQuery } from 'hooks/queries/useInitQuery';
import { useTemplateCharacteristics } from 'hooks/useTemplateCharacteristics';
import { useCommonData } from './useCommonData';

export const useConversationTemplateCharacteristics = () => {
  const { conversationTemplates } = useCommonData();

  useInitQuery(conversationTemplates);
  return useTemplateCharacteristics(conversationTemplates?.data ?? []);
};
