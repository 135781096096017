import { AssetType } from '../../../../../../../../../interfaces/educationMaterials';
import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExcel,
  faImage,
  faVideo,
} from '@fortawesome/pro-light-svg-icons';

export const useAssetIcon = () => {
  const getAssetIcon = useCallback((type: AssetType) => {
    switch (type) {
      case AssetType.Video:
        return <FontAwesomeIcon icon={faVideo} />;
      case AssetType.Image:
        return <FontAwesomeIcon icon={faImage} />;
      case AssetType.Attachment:
        return <FontAwesomeIcon icon={faFileExcel} />;
    }
  }, []);

  return {
    getAssetIcon,
  };
};
