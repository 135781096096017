import { Container } from 'reactstrap';

//components
import Header from 'components/Header';
import Footer from 'components/Footer';

//store
import classNames from 'classnames';
import useMobile from '../../hooks/useBreakpoint';
import { useCookie } from '../../hooks/useCookie';
import { useBrowserState } from '../../store/reducerHooks';
import { ViewTheme } from '../../interfaces/ui';

type SimpleHeaderLayoutProps = {
  children: JSX.Element;
  layoutClassNames?: string;
  embeddedLayoutClassNames?: string;
  containerClassNames?: string;
  embeddedContainerClassNames?: string;
  allowToDarkMode?: boolean;
  flexInDiv?: boolean;
};

const SimpleHeaderLayout = ({
  children,
  layoutClassNames = undefined,
  embeddedLayoutClassNames = undefined,
  containerClassNames = undefined,
  embeddedContainerClassNames = undefined,
  allowToDarkMode = false,
  flexInDiv = false,
}: SimpleHeaderLayoutProps) => {
  const theme = useCookie<ViewTheme>('theme');
  const isBrowser = useBrowserState();

  const isMobile = useMobile();

  return (
    <main
      className={classNames({
        'min-vh-100': true,
        'simple-header-layout': true,
        'simple-header-layout--dark':
          allowToDarkMode && theme === ViewTheme.Dark,
      })}
    >
      <div
        className={classNames({
          'd-flex min-vh-100 flex-column overflow-hidden position-relative':
            true,
          'simple-header-layout--narrow': !isMobile,
          [`${layoutClassNames}`]: !!layoutClassNames,
          [`${embeddedLayoutClassNames}`]:
            !!embeddedLayoutClassNames && !isBrowser,
        })}
      >
        <Header />
        <Container
          className={classNames(
            'flex-grow-1',
            {
              'layout-with-margin': isBrowser,
            },
            {
              'embedded-layout': !isBrowser,
            },
            {
              'd-flex': flexInDiv,
            },
            {
              [`${containerClassNames}`]: !!containerClassNames,
              [`${embeddedContainerClassNames}`]:
                !!embeddedContainerClassNames && !isBrowser,
            }
          )}
          fluid
        >
          {children}
        </Container>
      </div>
      {isBrowser && <Footer />}
    </main>
  );
};

export default SimpleHeaderLayout;
