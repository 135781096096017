export enum StatusEnum {
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  SENT = 'Sent',
}

export enum CommunicationChannels {
  Email = 'Email',
  SMS = 'SMS',
  PUSH = 'PUSH',
  Whatsapp = 'Whatsapp',
}

export enum PointComparison {
  ABOVE = 'above',
  BELOW = 'below',
  BETWEEN = 'between',
}

export enum RegisterFormKeys {
  EMAIL = 'email',
  PASSWORD = 'password',
  PASSWORD_REPEAT = 'passwordRepeat',
  TIMEZONE_ID = 'timezone_id',
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  GENDER = 'gender',
  BIRTH_DATE = 'birthdate',
  ADDRESS = 'address',
  PHONE_NUMBER = 'phone_number',
  SSN = 'ssn',
}

export enum AccessType {
  OWNER = 'owner',
  REQUEST = 'request',
  EMERGENCY_ACCESS = 'emergency_access',
  GRANTED = 'granted',
}

export enum RequestActions {
  APPROVE = 'approve',
  REJECT = 'reject',
}
