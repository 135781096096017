import useDeleteTrendVisualizationTemplate from 'api/mutations/trendVisualizations/useDeleteTrendVisualizationTemplate';
import { useCallback, useContext } from 'react';
import { TrendVisualizationModalFormikContext } from '../../../../../types';
import { TrendVisualizationTemplate } from 'interfaces/trendVisualizations';
import { FormikProps } from 'formik';
import { useCommonParams } from 'modules/Organizations/views/OrganizationDetailsView/hooks/useCommonParams';
import { useCommonData } from 'modules/Organizations/views/OrganizationDetailsView/hooks/useCommonData';

export const useHeadingActions = (toggle: () => void) => {
  const { organizationId } = useCommonParams();
  const { trendVisualizationTemplates } = useCommonData();

  const { values } = useContext(
    TrendVisualizationModalFormikContext
  ) as FormikProps<TrendVisualizationTemplate>;

  const handleDeleteTemplate = async () => {
    await trendVisualizationTemplates.refetch();
    toggle();
  };

  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useDeleteTrendVisualizationTemplate(handleDeleteTemplate);

  const handleDelete = useCallback(async () => {
    if (!values.id || values.id.length === 0) return;

    const templateId = values.id;

    await deleteAsync({
      organizationId,
      templateId,
    });
  }, [deleteAsync, organizationId, values.id]);

  return {
    templateDelete: {
      callback: handleDelete,
      isExecuting: isDeleting,
    },
  };
};
