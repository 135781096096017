import React from 'react';
import { useCurrentBreakpoint } from '../../../../../../../../../../../../hooks/useCurrentBreakpoint';
import { useTypedContext } from '../../../../../../../../../../../../hooks/useTypedContext';
import { DashboardDataReducerContext } from '../../../../../../types';
import { useTemplateGrid } from '../../hooks/useTemplateGrid';

type VisualizationLayoutProps = {
  children: JSX.Element;
};

const VisualizationLayout = ({ children }: VisualizationLayoutProps) => {
  const [dashboardData] = useTypedContext(DashboardDataReducerContext);
  const breakpoint = useCurrentBreakpoint();

  const { templateRows, templateColumns, columnGap, rowGap } = useTemplateGrid(
    dashboardData.template,
    breakpoint
  );

  return (
    <div
      className="AwareCasesCharts"
      style={{
        gridTemplateRows: templateRows,
        gridTemplateColumns: templateColumns,
        columnGap: columnGap,
        rowGap: rowGap,
      }}
    >
      {children}
    </div>
  );
};

export default VisualizationLayout;
