import { useMemo } from 'react';
import { useTypedContext } from '../../../../../../../../../../../../../hooks/useTypedContext';
import { DashboardDataReducerContext } from '../../../../../../../types';

export const useDateRange = () => {
  const [dashboardData] = useTypedContext(DashboardDataReducerContext);

  return useMemo(() => {
    return {
      from: dashboardData.template.date_selection.date_from,
      to: dashboardData.template.date_selection.date_to,
    };
  }, [
    dashboardData.template.date_selection.date_from,
    dashboardData.template.date_selection.date_to,
  ]);
};
