import {
  ValidConversationTemplate,
  ValidConversationTemplateItem,
} from '../../../../../../../interfaces/conversations';
import React, { useCallback } from 'react';
import { cloneDeep } from 'lodash';
import { useControlledValue } from '../../../../../../../hooks/useControlledValue';
import { ConversationTemplatePhoneItemActions } from '../../../components/ConversationTemplatePhone/types';
import { onMoveDown, onMoveUp } from '../helpers';

export const useActions = () => {
  const { value: selectedItemIndex, handleChange } = useControlledValue<
    number | null
  >(null);

  const onDelete = useCallback(
    (
      index: number,
      values: ValidConversationTemplate,
      setValues: (
        values: React.SetStateAction<ValidConversationTemplate>,
        shouldValidate?: boolean | undefined
      ) => void
    ) => {
      let valuesCopy = cloneDeep(values);
      valuesCopy.content.splice(index, 1);

      handleChange(null);
      setValues(valuesCopy);
    },
    [handleChange]
  );

  const onEdit = useCallback(
    (index: number) => handleChange(index),
    [handleChange]
  );

  const onAdd = useCallback(
    (
      item: ValidConversationTemplateItem,
      values: ValidConversationTemplate,
      setValues: (
        values: React.SetStateAction<ValidConversationTemplate>,
        shouldValidate?: boolean | undefined
      ) => void
    ) => {
      const valuesContentLength = values.content.length;

      setValues({
        ...values,
        content: [...values.content, item],
      });

      handleChange(valuesContentLength);
    },
    [handleChange]
  );

  const getActions = useCallback(
    (
      values: ValidConversationTemplate,
      setValues: (
        values: React.SetStateAction<ValidConversationTemplate>,
        shouldValidate?: boolean | undefined
      ) => void
    ): ConversationTemplatePhoneItemActions => {
      return {
        onDeleteItem: (index: number) => onDelete(index, values, setValues),
        onSelectItem: (index: number) => onEdit(index),
        onMoveItemDown: (index: number) => onMoveDown(index, values, setValues),
        onMoveItemUp: (index: number) => onMoveUp(index, values, setValues),
        onAddItem: (item: ValidConversationTemplateItem) =>
          onAdd(item, values, setValues),
      };
    },
    [onAdd, onDelete, onEdit]
  );

  return {
    getActions,
    selectedItemIndex,
  };
};
