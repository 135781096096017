import React from 'react';
import { useObservations } from './hooks/useObservations';
import FullScreenSpinner from '../../../../../../../../components/Spinners/FullScreenSpinner';
import QueryLoaded from '../../../../../../../../components/QueryLoaded';
import { ICaseDetails } from '../../../../../../../../interfaces/cases';
import { IAwareSessionData } from '../../../../../../../../interfaces/awareSessions';
import { IObservation } from '../../../../../../../../interfaces/timeline';

type ObservationsProviderProps = {
  caseDetails: ICaseDetails;
  awareSessionDetails: IAwareSessionData;
  children: (observations: IObservation[]) => JSX.Element;
};

const ObservationsProvider = ({
  children,
  awareSessionDetails,
  caseDetails,
}: ObservationsProviderProps) => {
  const observations = useObservations(caseDetails, awareSessionDetails);

  return (
    <QueryLoaded onLoading={<FullScreenSpinner />} query={observations}>
      {children}
    </QueryLoaded>
  );
};

export default ObservationsProvider;
