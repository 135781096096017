import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { IIdentifiedMutationRequest } from '../../../interfaces/request';
import { UserQueryParams } from '../../../interfaces/queryParams';
import { createExerciseSession } from '../../exerciseSessions';

const useCreateExerciseSession = ({
  params,
  options,
  silent = false,
}: IIdentifiedMutationRequest<UserQueryParams>) => {
  const mutationData = useMutationWithResultNotification({
    asyncApiFunction: async (caseId: string) => {
      const { data } = await createExerciseSession(params.userId, caseId);
      return data.data;
    },
    onSuccess: () => {
      if (!silent)
        displaySuccessNotification(
          'Api.Mutations.ExerciseSessions.CreateExerciseSession.success'
        );

      if (mutationData.data) {
        options.successFb?.(mutationData.data.id);
        mutationData.reset();
      }
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'Api.Mutations.ExerciseSessions.CreateExerciseSession.failure'
      );
      options.errorFb?.();
    },
    resetOnResult: false,
  });

  return mutationData;
};

export default useCreateExerciseSession;
