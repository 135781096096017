import React, { memo } from 'react';
import { EducationValidConversationTemplateItem } from '../../../../../../../../../../../../interfaces/conversations';
import { format } from 'date-fns';
import genericThumbnail from '../../../../../../../../../../../../assets/images/generic-thumbnail.png';
import { useCommonData } from '../../../../../../../../../../hooks/useCommonData';
import { useInitQuery } from '../../../../../../../../../../../../hooks/queries/useInitQuery';
import SkeletonPlaceholderOnLoad from '../../../../../../../../../../../../components/SkeletonPlaceholderOnLoad';
import Skeleton from 'react-loading-skeleton';
import { useTranslations } from '../../../../../../../../../../../../hooks/useTranslations';

type EducationItemProps = {
  item: EducationValidConversationTemplateItem;
};

const EducationItem = ({ item }: EducationItemProps) => {
  const { attachPrefix, wrapWithIntl } = useTranslations(
    'ConversationsAndFeedbacks.ConversationTemplates.ConversationTemplatePhone.Conversation.ConversationItem.EducationItem'
  );

  const { educationMaterials } = useCommonData();
  useInitQuery(educationMaterials);

  const currentDate = new Date();
  const currentHour = format(currentDate, 'HH:HH a');

  return (
    <div className="ConversationTemplateRangeQuestionItem">
      <div className="ConversationTemplateRangeQuestionItem__question d-flex flex-column">
        <SkeletonPlaceholderOnLoad
          isLoading={educationMaterials.isLoading}
          placeholder={<Skeleton width={100} />}
        >
          <span className="ConversationTemplatePlainTextItem__content__text">
            {educationMaterials.data?.find((p) => p.id === item.resource_id)
              ?.name ?? ''}
          </span>
        </SkeletonPlaceholderOnLoad>
        <span className="ConversationTemplatePlainTextItem__content__hour">
          {currentHour}
        </span>
      </div>
      <div className="ConversationTemplateEducationItem__thumbnail">
        <img
          className="img-fluid"
          src={genericThumbnail}
          alt={wrapWithIntl(attachPrefix('thumbnail'))}
        />
      </div>
    </div>
  );
};

export default memo(EducationItem);
