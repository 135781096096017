import { PropsWithChildren, ReactNode } from 'react';
import { IChartDashboardTemplateVisualization } from '../../../../../../../../../../../../../../interfaces/dashboards';
import {
  Case,
  Switch,
} from '../../../../../../../../../../../../../SwitchCase';
import BasicChartWrapper from '../BasicChartWrapper';
import ChartSettingsWrapper from '../ChartSettingsWrapper';

type ChartViewWrapperProps = {
  visualization: IChartDashboardTemplateVisualization;
  children: ReactNode;
};

const ChartViewWrapper = ({
  visualization,
  children,
}: PropsWithChildren<ChartViewWrapperProps>) => {
  return (
    <Switch item={visualization.settings_available}>
      <Case value={true}>
        <ChartSettingsWrapper
          visualization={visualization}
          children={children}
        />
      </Case>
      <Case value={false}>
        <BasicChartWrapper visualization={visualization} children={children} />
      </Case>
    </Switch>
  );
};

export default ChartViewWrapper;
