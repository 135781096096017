import React from 'react';
import {
  ReportParameter,
  ReportTemplateCode,
} from '../../../../interfaces/reports';
import GenerateReportForm from './GenerateReportForm';

type GenerateMomentarySessionExportFormProps = {
  fillInitialParameters: (
    parameters: ReportParameter[],
    code: ReportTemplateCode
  ) => void;
};

const GenerateMomentarySessionExportForm = ({
  fillInitialParameters,
}: GenerateMomentarySessionExportFormProps) => {
  return (
    <GenerateReportForm
      code={ReportTemplateCode.MOMENTARY_SESSION}
      fillInitialParameters={fillInitialParameters}
    />
  );
};

export default GenerateMomentarySessionExportForm;
