import Paper from '@mui/material/Paper';
import QueriesLoaded from 'components/QueriesLoaded';
import { useInitQueries } from 'hooks/queries/useInitQuery';
import React from 'react';
import ConversationSchedule from './components/ConversationSchedule';
import NotificationSchedule from './components/NotificationSchedule';
import ScheduleHeader from './components/ScheduleHeader';
import { useCaseConversationsScheduleDate } from './components/ScheduleHeader/hooks/useCaseConversationsScheduleDate';
import { useCommonData } from './hooks/useCommonData';
import { CasePhaseHistoryContext, OrganizationContext } from './types';

const ScheduleTab = () => {
  const { organization, casePhaseHistory } = useCommonData();

  const queries = [organization, casePhaseHistory];
  useInitQueries(queries);

  const { selectedDate, selectDate } = useCaseConversationsScheduleDate();

  return (
    <Paper className="d-flex flex-column gap-4 p-3">
      <QueriesLoaded queries={queries}>
        {(data) => (
          <OrganizationContext.Provider value={data[0]}>
            <CasePhaseHistoryContext.Provider value={data[1]}>
              <ScheduleHeader
                selectedDate={selectedDate}
                selectDate={selectDate}
              />
              <div className="p-3">
                <ConversationSchedule selectedDate={selectedDate} />
                <NotificationSchedule selectedDate={selectedDate} />
              </div>
            </CasePhaseHistoryContext.Provider>
          </OrganizationContext.Provider>
        )}
      </QueriesLoaded>
    </Paper>
  );
};

export default ScheduleTab;
