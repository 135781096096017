import deleteIcon from 'assets/images/delete_icon.svg';
import plusIcon from 'assets/images/plus.svg';
import CustomCalendarInput from 'components/CustomCalendarInput';
import { isValid, parseISO } from 'date-fns';
import { OrderedCustomScheduleInfo } from 'interfaces/assessmentForms';
import React, { useRef, useState } from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import { SelectionDetailsProps } from '../index';

const CustomSelectionDetails = ({ info, setInfo }: SelectionDetailsProps) => {
  const timeRef = useRef<HTMLInputElement>(null);

  const [addHourModeForDate, setAddHourModeForDate] = useState<Date | null>(
    null
  );

  const sortCustomById = (
    a: OrderedCustomScheduleInfo,
    b: OrderedCustomScheduleInfo
  ) => a.id - b.id;

  const removeHourFromSchedule = (date: Date, hour: string) => {
    const existingDate = info.custom!.find((p) => p.date === date);

    if (existingDate) {
      const dateCopy = {
        ...existingDate,
      };
      dateCopy.hours = dateCopy.hours.filter((p) => p !== hour);

      setInfo({
        ...info,
        custom: [...info.custom!.filter((p) => p.date !== date), dateCopy].sort(
          sortCustomById
        ),
      });
    }
  };

  const addHourToSchedule = () => {
    const existingDate = info.custom!.find(
      (p) => p.date === addHourModeForDate
    );

    if (timeRef.current?.value) {
      if (existingDate) {
        const dateCopy = {
          ...existingDate,
        };

        dateCopy.hours = [...dateCopy.hours, timeRef.current.value].filter(
          (v, i, a) => a.indexOf(v) === i
        );

        setInfo({
          ...info,
          custom: [
            ...info.custom!.filter((p) => p.date !== addHourModeForDate),
            dateCopy,
          ].sort(sortCustomById),
        });
      } else {
        setInfo({
          ...info,
          custom: [
            ...info.custom!,
            {
              id: info.custom!.length,
              date: addHourModeForDate!,
              hours: [...timeRef.current.value],
            },
          ].sort(sortCustomById),
        });
      }

      setAddHourModeForDate(null);
    }
  };

  const handleAddDate = () => {
    setInfo({
      ...info,
      custom: [
        ...info.custom!,
        {
          id: info.custom!.length,
          date: new Date(),
          hours: [],
        },
      ].sort(sortCustomById),
    });
  };

  const handleItemDateChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    date: Date
  ) => {
    const existingItem = info.custom!.find((p) => p.date === date);
    const valid = isValid(parseISO(e.target.value));
    const validYear = parseInt(e.target.value[0]) !== 0;

    if (existingItem && valid && validYear) {
      const item = {
        ...existingItem,
      };
      item.date = new Date(e.target.value);

      setInfo({
        ...info,
        custom: [...info.custom!.filter((p) => p.date !== date), item].sort(
          sortCustomById
        ),
      });
    }
  };

  const handleItemDelete = (date: Date) => {
    const existingItem = info.custom!.find((p) => p.date === date);

    if (existingItem && info.custom!.length > 1) {
      setInfo({
        ...info,
        custom: [...info.custom!.filter((p) => p.date !== date)].sort(
          sortCustomById
        ),
      });
    }
  };

  const disableAddHourModeForDate = () => {
    setAddHourModeForDate(null);
  };

  const handleHourChangeForKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === 'Enter' && e.currentTarget.value.length === 5) {
      addHourToSchedule();
    }
  };

  return (
    <div className="CustomSelectionDetails">
      {info.custom!.map((item) => (
        <div className="CustomSelectionDetails__item">
          <div className="CustomSelectionDetails__item__date">
            <label
              className="CustomSelectionDetails__item__date__label"
              htmlFor="date"
            >
              <FormattedMessage id="CaseCard.FormsAssignments.date" />
            </label>
            <div className="CustomSelectionDetails__item__date__calendar">
              <CustomCalendarInput
                id="date"
                onChange={(e) => handleItemDateChange(e, item.date)}
                defaultDateString={item.date.toString()}
              />
            </div>
          </div>
          <div className="CustomSelectionDetails__item__hours">
            {item.hours.map((hour) => (
              <div className="CustomSelectionDetails__item__hours__hour">
                <span className="CustomSelectionDetails__item__hours__hour__text">
                  {hour}
                </span>
                <button
                  onClick={() => removeHourFromSchedule(item.date, hour)}
                  className="CustomSelectionDetails__item__hours__hour__btn"
                >
                  &times;
                </button>
              </div>
            ))}
            {addHourModeForDate !== null && addHourModeForDate === item.date && (
              <>
                <input
                  ref={timeRef}
                  type="time"
                  name="add-hour"
                  className="HourSelectionDetails__add-hour-input"
                  onKeyDown={handleHourChangeForKeyPress}
                  required
                  aria-label="Add hour"
                />
                <button
                  onClick={addHourToSchedule}
                  className="HourSelectionDetails__add-hour-confirm"
                  name="confirm"
                >
                  <SVG src={plusIcon} title="confirm" />
                </button>
                <button
                  onClick={disableAddHourModeForDate}
                  className="HourSelectionDetails__add-hour-reject"
                >
                  &times;
                </button>
              </>
            )}
            {addHourModeForDate === null && (
              <button
                onClick={() => setAddHourModeForDate(item.date)}
                className="HourSelectionDetails__add-hour-btn"
              >
                <SVG src={plusIcon} title="add" />
                <span>
                  <FormattedMessage id="CaseCard.FormsAssignments.HourSelection.addHour" />
                </span>
              </button>
            )}
          </div>
          <div className="CustomSelectionDetails__item__delete">
            <Button
              disabled={info.custom!.length <= 1}
              onClick={() => handleItemDelete(item.date)}
              color="danger"
              block
            >
              <SVG src={deleteIcon} title="add" />
            </Button>
          </div>
        </div>
      ))}
      <Button
        color="primary"
        className="CustomSelectionDetails__item__add-day DashedPrimaryButton"
        onClick={handleAddDate}
        block
      >
        <SVG src={plusIcon} title="add" />
        <FormattedMessage id="CaseCard.FormsAssignments.addDay" />
      </Button>
    </div>
  );
};

export default CustomSelectionDetails;
