import useCreateCaseConversationSchedule from 'api/mutations/conversationSchedules/useCreateCaseConversationSchedule';
import useDeleteCaseConversationSchedule from 'api/mutations/conversationSchedules/useDeleteCaseConversationSchedule';
import useUpdateCaseConversationSchedule from 'api/mutations/conversationSchedules/useUpdateCaseConversationSchedule';
import { FormikErrors } from 'formik';
import { strToDateOnly, toDateOnly } from 'helpers/dates';
import { areEmptyConversationScheduleHours } from 'helpers/utils/conversationSchedules';
import { useOptions } from 'hooks/useOptions';
import {
  ConversationScheduleType,
  ICaseConversationSchedule,
  ICaseSchedulesEditTimeFormInitialValues,
  IConversationScheduleHours,
  ISingleInstanceCaseConversationSchedule,
  UpdateRangeMode,
} from 'interfaces/conversationSchedules';
import { useCallback, useMemo } from 'react';
import { IEditScheduleTemplateItemFormHandler } from '../../../../../../../../../../../../../components/EditScheduleTemplateItemModal';
import { useEditScheduleTemplateItemValidation } from '../../../../../../../../../../../../../components/EditScheduleTemplateItemModal/hooks/useEditScheduleTemplateItemValidation';
import { useOrganizationCasePathParams } from '../../../../../../../../../../../../../hooks/paths/useOrganizationCasePathParams';
import { useCommonData } from '../../../hooks/useCommonData';
import { useConversationTemplateCharacteristics } from '../../../hooks/useConversationTemplateCharacteristics';
import { useEditTimeModalFormInitialValues } from './useEditTimeModalFormInitialValues';
import { useEditTimeModalFormPayload } from './useEditTimeModalFormPayload';

export const useEditTimeModalFormHandler = (
  selectedDate: Date,
  schedule: ICaseConversationSchedule,
  toggle: () => void
): IEditScheduleTemplateItemFormHandler<ICaseSchedulesEditTimeFormInitialValues> => {
  const { organizationId, caseId } = useOrganizationCasePathParams();

  const { prepareBasic, prepareSingleInstance, prepareRecurring } =
    useEditTimeModalFormInitialValues();

  const {
    basicUpdatePayload,
    simpleSingleInstanceUpdatePayload,
    dateSimpleSingleInstanceUpdatePayload,
    recurringUpdatePayload,
    recurringCreatePayload,
    dirtySingleInstanceCreatePayload,
  } = useEditTimeModalFormPayload();

  const { updateRange } = useOptions();

  const { casePhaseHistory, caseConversationSchedules } = useCommonData();

  const handleSuccess = async () => {
    await caseConversationSchedules.refetch();
    toggle();
  };

  const { mutateAsync: deleteAsync } = useDeleteCaseConversationSchedule(
    {
      organizationId,
      caseId,
    },
    {
      successFb: handleSuccess,
    }
  );

  const { mutateAsync: updateAsync } = useUpdateCaseConversationSchedule(
    {
      organizationId,
      caseId,
    },
    {
      successFb: handleSuccess,
    }
  );

  const { mutateAsync: createAsync } = useCreateCaseConversationSchedule(
    {
      organizationId,
      caseId,
    },
    {
      successFb: handleSuccess,
    }
  );

  const { conversationTemplatesOptions } =
    useConversationTemplateCharacteristics();

  const { singleInstanceValidation, outlierValidation, recurringValidation } =
    useEditScheduleTemplateItemValidation();

  const initialValues = useMemo((): ICaseSchedulesEditTimeFormInitialValues => {
    switch (schedule.type) {
      case ConversationScheduleType.Once:
      case ConversationScheduleType.NonTimeBased:
        return prepareBasic(schedule);
      case ConversationScheduleType.SingleInstance:
        return prepareSingleInstance(schedule);
      case ConversationScheduleType.Recurring:
        return prepareRecurring(schedule);
    }
  }, [prepareBasic, prepareRecurring, prepareSingleInstance, schedule]);

  const isPlusMode = useCallback(
    (scheduleProvided: IConversationScheduleHours) => {
      return areEmptyConversationScheduleHours(scheduleProvided);
    },
    []
  );

  const handleSubmit = useCallback(
    async (values: ICaseSchedulesEditTimeFormInitialValues) => {
      if (
        schedule.type === ConversationScheduleType.Once ||
        schedule.type === ConversationScheduleType.NonTimeBased
      ) {
        await updateAsync(basicUpdatePayload(schedule, values));
        return;
      }

      if (
        schedule.type === ConversationScheduleType.SingleInstance &&
        schedule.recurring_origin_id === null
      ) {
        await updateAsync(simpleSingleInstanceUpdatePayload(schedule, values));
        return;
      }

      const plusMode = isPlusMode(schedule as IConversationScheduleHours);

      if (plusMode) {
        if (values.update_mode === UpdateRangeMode.AllEvents) {
          await createAsync(recurringCreatePayload(values));
          return;
        }

        await createAsync(
          dirtySingleInstanceCreatePayload(
            schedule,
            values,
            selectedDate.toISOString()
          )
        );
        return;
      }

      if (values.update_mode === UpdateRangeMode.AllEvents) {
        await updateAsync(recurringUpdatePayload(schedule, values));
        return;
      }

      let singleInstance = caseConversationSchedules.data?.find(
        (p) =>
          p.recurring_origin_id === schedule.id &&
          !p.is_deleted &&
          p.date &&
          strToDateOnly(p.date) === toDateOnly(selectedDate)
      );

      if (!singleInstance) return;

      let singleInstanceDate = (
        singleInstance as ISingleInstanceCaseConversationSchedule
      ).date;

      await updateAsync(
        dateSimpleSingleInstanceUpdatePayload(
          singleInstance,
          values,
          singleInstanceDate
        )
      );
    },
    [
      basicUpdatePayload,
      caseConversationSchedules.data,
      createAsync,
      dateSimpleSingleInstanceUpdatePayload,
      dirtySingleInstanceCreatePayload,
      isPlusMode,
      recurringCreatePayload,
      recurringUpdatePayload,
      schedule,
      selectedDate,
      simpleSingleInstanceUpdatePayload,
      updateAsync,
    ]
  );

  const handleRemove = useCallback(
    async (values: ICaseSchedulesEditTimeFormInitialValues) => {
      if (
        schedule.type === ConversationScheduleType.Recurring &&
        values.update_mode === UpdateRangeMode.SingleEvent
      ) {
        const singleInstanceSchedule = caseConversationSchedules.data?.find(
          (p) =>
            p.recurring_origin_id === schedule.id &&
            p.date !== null &&
            toDateOnly(selectedDate) === strToDateOnly(p.date)
        );

        if (!singleInstanceSchedule) return;

        await deleteAsync(singleInstanceSchedule.id);
        return;
      }

      await deleteAsync(schedule.id);
    },
    [
      caseConversationSchedules.data,
      deleteAsync,
      schedule.id,
      schedule.type,
      selectedDate,
    ]
  );

  const validate = useCallback(
    (
      values: ICaseSchedulesEditTimeFormInitialValues
    ): FormikErrors<ICaseSchedulesEditTimeFormInitialValues> => {
      let errors: FormikErrors<typeof initialValues> = {};

      let casePhaseHistoryData = casePhaseHistory.data ?? [];
      casePhaseHistoryData.sort((a, b) => b.sequence - a.sequence);
      let currentCasePhaseHistory = casePhaseHistoryData.at(0);

      if (!currentCasePhaseHistory) return errors;

      if (
        schedule.type === ConversationScheduleType.Once ||
        schedule.type === ConversationScheduleType.NonTimeBased
      ) {
        if (values.priority <= 0) {
          errors.priority = 'priority';
          return errors;
        }
      }

      if (
        schedule.type === ConversationScheduleType.SingleInstance &&
        !schedule.recurring_origin_id
      ) {
        errors = singleInstanceValidation(
          errors,
          values,
          currentCasePhaseHistory.date_from,
          currentCasePhaseHistory.date_to
        );
        return errors;
      }

      if (
        (schedule.type === ConversationScheduleType.SingleInstance &&
          schedule.is_dirty) ||
        values.update_mode === UpdateRangeMode.SingleEvent
      ) {
        errors = outlierValidation(errors, values);
        return errors;
      }

      if (values.update_mode === UpdateRangeMode.AllEvents) {
        errors = recurringValidation(
          errors,
          values,
          currentCasePhaseHistory.date_from,
          currentCasePhaseHistory.date_to
        );
      }

      return errors;
    },
    [
      casePhaseHistory.data,
      outlierValidation,
      recurringValidation,
      schedule.is_dirty,
      schedule.recurring_origin_id,
      schedule.type,
      singleInstanceValidation,
    ]
  );

  return {
    initialValues: initialValues,
    handleSubmit: handleSubmit,
    handleRemove: handleRemove,
    validate: validate,
    options: {
      updateRange: updateRange,
      conversationTemplates: conversationTemplatesOptions(false, true),
    },
    helpers: {
      isPlusMode: isPlusMode,
    },
  };
};
