import React, { useMemo, useState } from 'react';
import { useTimelineDetailsDateRangeObject } from '../../../../../store/reducerHooks';
import useConversationQuestions from '../../../../../api/queries/conversations/useConversationQuestions';
import { AssessmentQuestionHistoryEntryItem } from '../../../../../interfaces/cases';
import { FormattedMessage } from 'react-intl';
import SVG from 'react-inlinesvg';
import caretIcon from '../../../../../assets/images/caret.svg';
import NoDataTrendsChartPlaceholder from './components/ChartPlaceholders/NoDataTrendsChartPlaceholder';
import assessmentFormChartIcon from '../../../../../assets/images/trends_timeline/assessment_form_chart_icon.svg';
import QuestionsChart from './components/QuestionsChart/QuestionsChart';
import { TemplateElement } from '../../../../../interfaces/change';

type TrendsSummaryQuestionChartProps = {
  organizationId: string;
  elements: TemplateElement[];
  questions: AssessmentQuestionHistoryEntryItem[];
};

const TrendsSummaryQuestionChart = ({
  organizationId,
  elements,
  questions,
}: TrendsSummaryQuestionChartProps) => {
  const [expanded, setExpanded] = useState<boolean>(true);

  const toggleExpanded = () => {
    setExpanded((prev) => !prev);
  };

  const dateRangeObject = useTimelineDetailsDateRangeObject();

  const { data: conversationQuestions } = useConversationQuestions({
    params: { organizationId },
  });

  const isQuestionDataAvailable = useMemo(() => {
    if (questions.length === 0) return false;

    const notEmptyQuestionHistory = questions.find(
      (p) => p.answerHistory.length > 0
    );

    return notEmptyQuestionHistory !== undefined;
  }, [questions]);

  if (!conversationQuestions) return <></>;

  return (
    <div className="cardContainer d-flex flex-column TimelineSqueezeSummary w-100">
      <div className="d-flex justify-content-between align-items-center mb-2 flex-wrap">
        <div className="d-flex cardContainer__header__title w-100">
          <div className="d-flex align-items-start TimelineSqueezeSummary__title">
            <img
              className="me-2"
              src={assessmentFormChartIcon}
              alt="header_icon"
            />
            <FormattedMessage id="TimelineDetails.TrendsTimeline.QuestionChart.chartName" />
          </div>
          <div className="ms-auto TimelineSqueezeSummary__title__buttons">
            <SVG
              onClick={toggleExpanded}
              src={caretIcon}
              transform={expanded ? 'rotate(180)' : ''}
              title="Expand chart"
            />
          </div>
        </div>
      </div>
      {expanded && (
        <div className="mt-3">
          {isQuestionDataAvailable ? (
            <QuestionsChart
              dateRange={dateRangeObject}
              questions={questions}
              elements={elements}
              conversationQuestions={conversationQuestions}
            />
          ) : (
            <NoDataTrendsChartPlaceholder />
          )}
        </div>
      )}
    </div>
  );
};

export default TrendsSummaryQuestionChart;
