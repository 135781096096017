import {
  ClosedListValidConversationQuestion,
  EmailValidConversationQuestion,
  HeaderValidConversationQuestion,
  OpenValidConversationQuestion,
  RangeValidConversationQuestion,
  TimeValidConversationQuestion,
  ValidConversationQuestionType,
} from '../../../../../../../../../../../../../../../../../../../../../../../interfaces/conversations';
import { ValueInterpretationType } from '../../../../../../../../../../../../../../../../../../../../../../../interfaces/change';

export const getEmptyQuestionBasedOnType = (
  id: string,
  type: ValidConversationQuestionType
) => {
  switch (type) {
    case ValidConversationQuestionType.Header:
      return getEmptyHeaderQuestion(id);
    case ValidConversationQuestionType.Open:
      return getEmptyOpenQuestion(id);
    case ValidConversationQuestionType.Range:
      return getEmptyRangeQuestion(id);
    case ValidConversationQuestionType.Email:
      return getEmptyEmailQuestion(id);
    case ValidConversationQuestionType.Time:
      return getEmptyTimeQuestion(id);
    case ValidConversationQuestionType.ClosedList:
      return getEmptyClosedListQuestion(id);
  }
};

const getEmptyHeaderQuestion = (
  id: string
): HeaderValidConversationQuestion => {
  return {
    guid: id,
    type: ValidConversationQuestionType.Header,
    color: '#000000',
    required: true,
    sequence: 0,
    title: ValidConversationQuestionType.Header,
    short_title: ValidConversationQuestionType.Header,
    included_in_trends: true,
    show_title: true,
    show_description: true,
    value_interpretation: ValueInterpretationType.NoInterpretation,
  };
};

const getEmptyOpenQuestion = (id: string): OpenValidConversationQuestion => {
  return {
    guid: id,
    type: ValidConversationQuestionType.Open,
    color: '#000000',
    required: true,
    sequence: 0,
    title: ValidConversationQuestionType.Open,
    short_title: ValidConversationQuestionType.Open,
    included_in_trends: true,
    show_title: true,
    show_description: true,
    value_interpretation: ValueInterpretationType.NoInterpretation,
    answer: {
      text_value: null,
    },
  };
};

const getEmptyRangeQuestion = (id: string): RangeValidConversationQuestion => {
  return {
    guid: id,
    type: ValidConversationQuestionType.Range,
    color: '#000000',
    required: true,
    sequence: 0,
    title: ValidConversationQuestionType.Range,
    short_title: ValidConversationQuestionType.Range,
    included_in_trends: true,
    show_title: true,
    show_description: true,
    value_interpretation: ValueInterpretationType.NoInterpretation,
    legend_descriptions: [],
    answer: {
      number_value: null,
      date_value: null,
    },
  };
};

const getEmptyEmailQuestion = (id: string): EmailValidConversationQuestion => {
  return {
    guid: id,
    type: ValidConversationQuestionType.Email,
    color: '#000000',
    required: true,
    sequence: 0,
    title: ValidConversationQuestionType.Email,
    short_title: ValidConversationQuestionType.Email,
    included_in_trends: true,
    show_title: true,
    show_description: true,
    value_interpretation: ValueInterpretationType.NoInterpretation,
    answer: {
      text_value: null,
    },
  };
};

const getEmptyTimeQuestion = (id: string): TimeValidConversationQuestion => {
  return {
    guid: id,
    type: ValidConversationQuestionType.Time,
    color: '#000000',
    required: true,
    sequence: 0,
    title: ValidConversationQuestionType.Time,
    short_title: ValidConversationQuestionType.Time,
    included_in_trends: true,
    show_title: true,
    show_description: true,
    value_interpretation: ValueInterpretationType.NoInterpretation,
    answer: {
      time_value: null,
    },
  };
};

const getEmptyClosedListQuestion = (
  id: string
): ClosedListValidConversationQuestion => {
  return {
    guid: id,
    type: ValidConversationQuestionType.ClosedList,
    color: '#000000',
    required: true,
    sequence: 0,
    title: ValidConversationQuestionType.ClosedList,
    short_title: ValidConversationQuestionType.ClosedList,
    included_in_trends: true,
    show_title: true,
    show_description: true,
    value_interpretation: ValueInterpretationType.NoInterpretation,
    answers: [],
  };
};
