import { UserRoles } from '../../../interfaces/membership';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { addAccessProfileRoles } from '../../accessProfiles';
import { IMutationProps } from '../../../interfaces/ui';

export type AddAccessProfileRolesBody = {
  accessProfileId: string;
  roles: UserRoles[];
};

type useAddAccessProfileRolesParams = {
  organizationId: string;
};

export const useAddAccessProfileRoles = (
  params: useAddAccessProfileRolesParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (body: AddAccessProfileRolesBody) => {
      await addAccessProfileRoles(
        params.organizationId,
        body.accessProfileId,
        body.roles
      );
    },
    onSuccess: () => {
      if (options.successFb) options.successFb();
    },
    onErrorArray: (_) => {
      if (options.errorFb) options.errorFb();
    },
    resetOnResult: true,
  });
};
