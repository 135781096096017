import useGraspShareWithPersonality from 'api/mutations/grasps/useGraspShareWithPersonality';
import useGraspUnshareWithPersonality from 'api/mutations/grasps/useGraspUnshareWithPersonality';
import QueryKeys from 'api/queries/queryKeys';
import history from 'helpers/history';

import { queryClient } from 'helpers/queryClient';

import { useCallback, useEffect, useState } from 'react';

type HandleShareActionProps = {
  grasp_uuid: string;
  grasp_id: string;
  personality_id: string;
  organization_id: string;
};

type HandleFindActionProps = {
  grasp_id: string;
  organization_id: string;
};

type CustomEventType = {
  detail: null | HandleShareActionProps;
};

type CustomFindEventType = {
  detail: null | HandleFindActionProps;
};

function useHandleShareGraspEvent() {
  const {
    mutate: shareMutation,
    isSuccess: isShareSuccess,
    reset: resetShare,
  } = useGraspShareWithPersonality();

  const {
    mutate: unshareMutation,
    isSuccess: isUnshareSuccess,
    reset: resetUnshare,
  } = useGraspUnshareWithPersonality();

  const [passedParams, setPassedParams] =
    useState<HandleShareActionProps | null>(null);

  const handleShareEvent = useCallback(
    (e: CustomEventType) => {
      if (e.detail) {
        setPassedParams(e.detail);

        shareMutation({
          grasp_uuid: e.detail.grasp_uuid,
          organizationId: e.detail.organization_id,
          personalityId: e.detail.personality_id,
        });
      }
    },
    [shareMutation]
  );

  const handleFindEvent = useCallback((e: CustomFindEventType) => {
    if (e.detail) {
      history.push(
        `/organizations/${e.detail.organization_id}/grasps/${e.detail.grasp_id}`
      );
    }
  }, []);

  const handleUnshareEvent = useCallback(
    (e: CustomEventType) => {
      if (e.detail) {
        setPassedParams(e.detail);

        unshareMutation({
          organizationId: e.detail.organization_id,
          personalityId: e.detail.personality_id,
          graspId: e.detail.grasp_id,
        });
      }
    },
    [unshareMutation]
  );

  const handleRefetch = async (params: HandleShareActionProps) => {
    const activeGraspQueryKeys = [
      QueryKeys.getOrganizationUserActiveGrasp,
      params.organization_id,
      params.personality_id,
    ];

    const organizationGraspListKeys = [
      QueryKeys.getGraspSharingList,
      params.organization_id,
      params.personality_id,
    ];

    const activeGraspQueryState =
      queryClient.getQueryState(activeGraspQueryKeys);
    const graspListQueryState = queryClient.getQueryState(
      organizationGraspListKeys
    );
    //prevent unnecessary fetch
    if (graspListQueryState?.status !== 'loading')
      await queryClient.resetQueries(organizationGraspListKeys);

    //prevent unnecessary fetch
    if (activeGraspQueryState?.status !== 'loading') {
      await queryClient.resetQueries(activeGraspQueryKeys);
    }

    setPassedParams(null);
  };

  useEffect(() => {
    if (isShareSuccess && passedParams) {
      handleRefetch(passedParams);
      resetShare();
    }
  }, [isShareSuccess, resetShare, passedParams]);

  useEffect(() => {
    if (isUnshareSuccess && passedParams) {
      handleRefetch(passedParams);
      resetUnshare();
    }
  }, [isUnshareSuccess, resetUnshare, passedParams]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      //@ts-ignore strictFunctionTypes
      window.addEventListener('grasp-sharing-response', handleShareEvent);
      //@ts-ignore strictFunctionTypes
      window.addEventListener('grasp-unsharing-response', handleUnshareEvent);
      //@ts-ignore strictFunctionTypes
      window.addEventListener('grasp-find-response', handleFindEvent);
    }

    return () => {
      if (typeof window !== 'undefined') {
        //@ts-ignore strictFunctionTypes
        window.removeEventListener('grasp-sharing-response', handleShareEvent);
        //@ts-ignore strictFunctionTypes
        window.removeEventListener(
          'grasp-unsharing-response',
          handleShareEvent
        );
        //@ts-ignore strictFunctionTypes
        window.removeEventListener('grasp-find-response', handleFindEvent);
      }
    };
  }, [handleShareEvent, handleUnshareEvent, handleFindEvent]);
}

export default useHandleShareGraspEvent;
