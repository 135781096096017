import React from 'react';
import { Col, Row } from 'reactstrap';
import { PasswordComplexityConstraints } from 'components/PasswordComplexity';
import { RegisterFormKeys } from 'interfaces/enums';
import {
  AddressField,
  BirthdateField,
  EmailField,
  FirstNameField,
  GenderField,
  LastNameField,
  PasswordField,
  PhoneNumberField,
  SsnField,
  TimezoneField,
} from './FieldDefinitions';
import { FormikProps } from 'formik';

type RegisterFieldsProps<T> = {
  formik: FormikProps<T>;
  disabledFields?: RegisterFormKeys[];
  hideFields?: RegisterFormKeys[];
  mandatoryFields?: RegisterFormKeys[];
  optionalFields?: RegisterFormKeys[];
  passwordComplexityConstraints?: PasswordComplexityConstraints[];
};

const RegisterFields = <T,>({
  formik,
  disabledFields = [],
  mandatoryFields = [],
  optionalFields = [],
  passwordComplexityConstraints = [],
}: RegisterFieldsProps<T>) => {
  const isFieldDisabled = (key) => disabledFields.includes(key);

  const allFields: RegisterFormKeys[] = mandatoryFields?.concat(optionalFields);

  const fieldsRendered: JSX.Element[] = [];

  const registerFormKeys = Object.values(RegisterFormKeys);

  const tryAddToFields = (key: RegisterFormKeys, jsxElement: JSX.Element) => {
    if (allFields.includes(key)) {
      fieldsRendered.push(jsxElement);
    }
  };

  registerFormKeys.forEach((key) => {
    let jsxElement: JSX.Element | null = null;

    switch (key) {
      case RegisterFormKeys.FIRST_NAME:
        jsxElement = (
          <Col xs={12} sm={12} lg={6} xl={6}>
            <FirstNameField
              formik={formik}
              isOptional={optionalFields?.includes(RegisterFormKeys.FIRST_NAME)}
              isDisabled={isFieldDisabled(RegisterFormKeys.FIRST_NAME)}
            />
          </Col>
        );

        break;
      case RegisterFormKeys.LAST_NAME:
        jsxElement = (
          <Col xs={12} sm={12} lg={6} xl={6}>
            <LastNameField
              formik={formik}
              isOptional={optionalFields?.includes(RegisterFormKeys.LAST_NAME)}
              isDisabled={isFieldDisabled(RegisterFormKeys.LAST_NAME)}
            />
          </Col>
        );
        break;
      case RegisterFormKeys.EMAIL:
        jsxElement = (
          <Col xs={12} sm={12} lg={6} xl={6}>
            <EmailField
              formik={formik}
              isOptional={optionalFields?.includes(RegisterFormKeys.EMAIL)}
              isDisabled={disabledFields.includes(RegisterFormKeys.EMAIL)}
            />
          </Col>
        );
        break;
      case RegisterFormKeys.TIMEZONE_ID:
        jsxElement = (
          <Col xs={12} sm={12} lg={6} xl={6}>
            <TimezoneField
              formik={formik}
              isOptional={optionalFields?.includes(
                RegisterFormKeys.TIMEZONE_ID
              )}
              isDisabled={isFieldDisabled(RegisterFormKeys.TIMEZONE_ID)}
            />
          </Col>
        );

        break;
      case RegisterFormKeys.BIRTH_DATE:
        jsxElement = (
          <Col xs={12} sm={12} lg={6} xl={6}>
            <BirthdateField
              formik={formik}
              isOptional={optionalFields?.includes(RegisterFormKeys.BIRTH_DATE)}
              isDisabled={disabledFields.includes(RegisterFormKeys.BIRTH_DATE)}
            />
          </Col>
        );
        break;
      case RegisterFormKeys.GENDER:
        jsxElement = (
          <Col xs={12} sm={12} lg={6} xl={6}>
            <GenderField
              formik={formik}
              isOptional={optionalFields?.includes(RegisterFormKeys.GENDER)}
              isDisabled={disabledFields.includes(RegisterFormKeys.GENDER)}
            />
          </Col>
        );
        break;
      case RegisterFormKeys.SSN:
        jsxElement = (
          <Col xs={12} sm={12} lg={6} xl={6}>
            <SsnField
              formik={formik}
              isOptional={optionalFields?.includes(RegisterFormKeys.SSN)}
              isDisabled={disabledFields.includes(RegisterFormKeys.SSN)}
            />
          </Col>
        );
        break;
      case RegisterFormKeys.ADDRESS:
        jsxElement = (
          <Col xs={12} sm={12} lg={6} xl={6}>
            <AddressField
              formik={formik}
              isOptional={optionalFields?.includes(RegisterFormKeys.ADDRESS)}
              isDisabled={disabledFields.includes(RegisterFormKeys.ADDRESS)}
            />
          </Col>
        );

        break;
      case RegisterFormKeys.PHONE_NUMBER:
        jsxElement = (
          <Col xs={12} sm={12} lg={6} xl={6}>
            <PhoneNumberField
              formik={formik}
              isOptional={optionalFields?.includes(
                RegisterFormKeys.PHONE_NUMBER
              )}
              isDisabled={disabledFields.includes(
                RegisterFormKeys.PHONE_NUMBER
              )}
            />
          </Col>
        );
        break;
      case RegisterFormKeys.PASSWORD:
        jsxElement = (
          <>
            <Col xs={12} sm={12} lg={6} xl={6}>
              <PasswordField formik={formik} repeat={false} />
            </Col>
            <Col xs={12} sm={12} lg={6} xl={6}>
              <PasswordField formik={formik} repeat={true} />
            </Col>
          </>
        );
        break;
    }

    if (jsxElement) tryAddToFields(key, jsxElement);
  });

  return (
    <div className="RegisterFields">
      <Row style={{ rowGap: '1.5rem' }}>{fieldsRendered}</Row>
    </div>
  );
};

export default RegisterFields;
