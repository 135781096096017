import React, { PropsWithChildren } from 'react';

const EventPropagationConstraint = (props: PropsWithChildren) => {
  const handleStopPropagation = (e: React.MouseEvent<HTMLLabelElement>) =>
    e.stopPropagation();

  return <label onClick={handleStopPropagation}>{props.children}</label>;
};

export default EventPropagationConstraint;
