import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import graspIcon from 'assets/images/grasp_small_blue.svg';
import FieldInput from 'components/FieldInput';
import FieldSelect from 'components/FieldSelect';
import InputFieldWithoutImage from 'components/InputFieldWithoutImage';
import { Field, Formik } from 'formik';
import { IGraspLedLevel } from 'interfaces/grasps';
import React from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';
import {
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import IconButton from '../../../../../../../../components/IconButton';
import { useAddGraspSettingsModalForm } from './hooks/useAddGraspSettingsModalForm';

type AddGraspSettingsModalProps = {
  isOpen: boolean;
  toggle: () => void;
  onSuccess?: () => Promise<void>;
  modified?: IGraspLedLevel;
};

const AddGraspSettingsModal = ({
  isOpen,
  toggle,
  modified,
  onSuccess,
}: AddGraspSettingsModalProps) => {
  const handleSuccess = async () => {
    if (onSuccess) await onSuccess();
  };

  const { initialValues, handleSubmit, validation, organizationOptions } =
    useAddGraspSettingsModalForm(handleSuccess, modified);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="lg"
      className="AddGraspSettingsModal GenericModal BorderlessModal"
    >
      <ModalHeader className="AddGraspSettingsModal__header" toggle={toggle}>
        <SVG
          src={graspIcon}
          className="me-2"
          title="Organizations.OrganizationDetails.GraspSettings.AddGraspSettingsModal.title"
        />
        <FormattedMessage id="Organizations.OrganizationDetails.GraspSettings.AddGraspSettingsModal.title" />
      </ModalHeader>
      <Formik
        validate={validation}
        isInitialValid={false}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isValid, values, resetForm }) => {
          const handleReset = () => {
            resetForm();
            toggle();
          };

          return (
            <>
              <ModalBody className="d-flex flex-column gap-2">
                <InputFieldWithoutImage
                  gray={true}
                  noMargin={true}
                  disabled={true}
                  labelTranslationId="Organizations.OrganizationDetails.GraspSettings.AddGraspSettingsModal.organizationId"
                  inputId="organization_id"
                >
                  <Field
                    id="organization_id"
                    name="organization_id"
                    type="select"
                    options={organizationOptions}
                    displayEmpty={false}
                    disabled={true}
                    component={FieldSelect}
                  />
                </InputFieldWithoutImage>
                <div className="p-3 d-flex flex-column">
                  <FormGroup check>
                    <Label check className="d-flex align-items-center gap-2">
                      <Field
                        type="checkbox"
                        name="red_on"
                        checked={values.red_on}
                        component={FieldInput}
                      />
                      <FormattedMessage id="Organizations.OrganizationDetails.GraspSettings.AddGraspSettingsModal.red_on" />
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check className="d-flex align-items-center gap-2">
                      <Field
                        type="checkbox"
                        name="green_on"
                        checked={values.green_on}
                        component={FieldInput}
                      />
                      <FormattedMessage id="Organizations.OrganizationDetails.GraspSettings.AddGraspSettingsModal.green_on" />
                    </Label>
                  </FormGroup>
                  <FormGroup check>
                    <Label check className="d-flex align-items-center gap-2">
                      <Field
                        type="checkbox"
                        name="blue_on"
                        checked={values.blue_on}
                        component={FieldInput}
                      />
                      <FormattedMessage id="Organizations.OrganizationDetails.GraspSettings.AddGraspSettingsModal.blue_on" />
                    </Label>
                  </FormGroup>
                </div>
                <InputFieldWithoutImage
                  gray={true}
                  noMargin={true}
                  labelTranslationId="Organizations.OrganizationDetails.GraspSettings.AddGraspSettingsModal.amplitude_percentage"
                  inputId="amplitude_percentage"
                >
                  <Field
                    id="amplitude_percentage"
                    name="amplitude_percentage"
                    type="number"
                    min={0}
                    max={100}
                    component={FieldInput}
                  />
                </InputFieldWithoutImage>
              </ModalBody>
              <ModalFooter className="AddGraspSettingsModal__footer AddGraspSettingsModalFooter gap-2">
                <IconButton
                  color="primary"
                  variant="outlined"
                  onClick={submitForm}
                  disabled={!isValid}
                >
                  <SaveOutlinedIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  variant="outlined"
                  onClick={handleReset}
                >
                  <ReplayOutlinedIcon />
                </IconButton>
              </ModalFooter>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddGraspSettingsModal;
