import { displayErrorNotification } from 'modules/Notifications';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { ILoginResponse } from 'interfaces/membership';
import { IErrorWithCode } from 'interfaces/response';
import { deleteOrganizationToken } from 'api/membership';
import { getTokenData } from 'helpers/utils/auth';
import { IMutationProps } from 'interfaces/ui';
import { useNavigate } from 'react-router';

interface IUseDeleteOrganizationTokenProps extends IMutationProps {
  isRedirect: boolean;
}

function useDeleteOrganizationToken(props?: IUseDeleteOrganizationTokenProps) {
  const navigate = useNavigate();
  const { isRedirect = true, successFb } = props || {};

  const result = useMutation<ILoginResponse, IErrorWithCode, string>(
    async (organizationId) => {
      const { data } = await deleteOrganizationToken(organizationId);

      return data.data;
    }
  );

  useEffect(() => {
    if (result.isSuccess && result.data) {
      const { token } = result.data;

      getTokenData(token);

      isRedirect && navigate(`/dashboard`);
      successFb && successFb();

      result.reset();
    }

    if (result.isError) {
      displayErrorNotification('Errors.default');
      result.reset();
    }
  }, [result, isRedirect, successFb, navigate]);

  return result;
}

export default useDeleteOrganizationToken;
