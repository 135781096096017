import React from 'react';
import Skeleton from 'react-loading-skeleton';

export const GraspRowPlaceholder = () => {
  return (
    <li className="d-flex align-items-center ms-2 mb-2">
      <Skeleton circle width={50} height={25} className="me-2" />
      <Skeleton width={150} height={20} className="me-2" />
      <Skeleton circle width={20} height={20} className="me-2" />
      <Skeleton circle width={20} height={20} />
    </li>
  );
};

type MultipleGraspRowPlaceholdersProps = {
  numberOfPlaceholders: number;
};

export const MultipleGraspRowPlaceholders = ({
  numberOfPlaceholders,
}: MultipleGraspRowPlaceholdersProps) => {
  return (
    <>
      {[...new Array(numberOfPlaceholders)].map((el, index) => (
        <GraspRowPlaceholder key={`grasp-placeholder-${index}`} />
      ))}
    </>
  );
};
