import { CultureEntry } from '../../interfaces/cultures';
import useCultures from './useCultures';

export const useCultureEntry = <T>(entry: CultureEntry<T>[]): T | undefined => {
  const { currentCulture, defaultCulture } = useCultures();

  const currentEntry = entry.find(
    (p) => p.culture === currentCulture.cultureCode
  );

  if (currentEntry) return currentEntry.value;

  const defaultEntry = entry.find(
    (p) => p.culture === defaultCulture.cultureCode
  );

  if (defaultEntry) return defaultEntry.value;

  return undefined;
};
