import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import IconButton from '../../../../../../../../components/IconButton';
import TabLayout, {
  TabLayoutType,
} from '../../../../../../../../components/layouts/TabLayout';
import QueriesLoaded from '../../../../../../../../components/QueriesLoaded';
import { useInitQueries } from '../../../../../../../../hooks/queries/useInitQuery';
import useOpen from '../../../../../../../../hooks/useOpen';
import { OrganizationAccessProfile } from '../../../../../../../../interfaces/accessProfiles';
import {
  DashboardReportSchedule,
  DashboardTemplate,
} from '../../../../../../../../interfaces/dashboards';
import { IPersonality } from '../../../../../../../../interfaces/personalities';
import { useQueries } from './hooks/useQueries';
import AddReportScheduleModal from './modals/AddReportScheduleModal';
import ReportScheduleTable from './tables/ReportScheduleTable';

const ReportSchedulesTab = () => {
  const addReportScheduleModal = useOpen();

  const {
    dashboardReportSchedules,
    dashboardTemplates,
    accessProfiles,
    members,
  } = useQueries();

  useInitQueries([
    dashboardReportSchedules,
    dashboardTemplates,
    accessProfiles,
    members,
  ]);

  return (
    <TabLayout
      type={TabLayoutType.Breadcrumb}
      titlePrefix="Organizations.DashboardReportSchedules"
      actions={
        <IconButton
          color="primary"
          variant="outlined"
          onClick={addReportScheduleModal.toggle}
        >
          <AddIcon />
        </IconButton>
      }
    >
      <QueriesLoaded
        queries={[
          dashboardReportSchedules,
          dashboardTemplates,
          accessProfiles,
          members,
        ]}
      >
        {(data) => (
          <>
            <AddReportScheduleModal
              isOpen={addReportScheduleModal.isOpen}
              toggle={addReportScheduleModal.toggle}
            />
            <ReportScheduleTable
              schedules={data[0] as DashboardReportSchedule[]}
              templates={data[1] as DashboardTemplate[]}
              accessProfiles={data[2] as OrganizationAccessProfile[]}
              members={data[3] as IPersonality[]}
            />
          </>
        )}
      </QueriesLoaded>
    </TabLayout>
  );
};

export default ReportSchedulesTab;
