import { useCallback } from 'react';
import { useTypedContext } from '../../../../../../../../../hooks/useTypedContext';
import { OperationalDataContext } from '../../../providers/OperationalDataProvider';
import { AwareCaseDetailsFormValues, ProcedureField } from '../types';
import { ValidConversationQuestionType } from '../../../../../../../../../interfaces/conversations';
import { useQuestionTitle } from '../../../../../../../../../hooks/data/useQuestionTitle';
import useCultures from '../../../../../../../../../hooks/cultures/useCultures';

export const useHandleInitialValues = () => {
  const getQuestionTitle = useQuestionTitle();

  const { currentCulture, defaultCulture } = useCultures();

  const { awareSessionDetails, questions, caseProcedureHistory } =
    useTypedContext(OperationalDataContext);

  return useCallback(() => {
    let initialValues: Partial<AwareCaseDetailsFormValues> = {};

    initialValues.questions = [];
    initialValues.patient_number = awareSessionDetails.patient_number ?? '';

    awareSessionDetails.questions.forEach((question) => {
      const existingResource = questions.find(
        (p) => p.resource_id === question.id
      );

      const existingQuestion = existingResource?.content;

      if (existingQuestion?.type !== ValidConversationQuestionType.ClosedList)
        return;

      const existingAnswer =
        existingQuestion.answers.find(
          (p) => p.title === question.value || p.title_nb_no === question.value
        )?.guid ?? '';

      initialValues.questions?.push({
        id: existingQuestion.guid,
        value: existingAnswer,
        label: existingResource ? getQuestionTitle(existingResource) : '',
        options: existingQuestion.answers.map((answer) => ({
          value: answer.guid,
          text:
            currentCulture.cultureCode === defaultCulture.cultureCode
              ? answer.title
              : answer.title_nb_no ?? answer.title,
        })),
      });
    });

    let procedures: ProcedureField[] = [];

    const procedureEntries = caseProcedureHistory.filter(
      (p) => p.procedure !== null && !p.procedure_activity
    );

    procedureEntries.forEach((entry) => {
      const activityEntries = caseProcedureHistory.filter(
        (p) =>
          p.procedure_activity !== null &&
          p.procedure.id === entry.procedure.id &&
          p.time_start >= entry.time_start &&
          p.time_start <= entry.time_end
      );

      procedures.push({
        id: entry.procedure.id,
        entryId: entry.id,
        timeStart:
          entry.time_start < awareSessionDetails.start_date
            ? awareSessionDetails.start_date
            : entry.time_start,
        timeEnd:
          entry.time_end > awareSessionDetails.end_date
            ? awareSessionDetails.end_date
            : entry.time_end,
        nameEn: entry.procedure.name_en,
        nameNo: entry.procedure.name_no,
        activities: activityEntries.map((activity) => ({
          id: activity.procedure_activity.id,
          entryId: activity.id,
          nameEn: activity.procedure_activity.name_en,
          nameNo: activity.procedure_activity.name_no,
          color: activity.procedure_activity.hex_color,
          timeStart: activity.time_start,
          timeEnd: activity.time_end,
        })),
      });
    });

    initialValues.procedures = procedures;
    return initialValues as AwareCaseDetailsFormValues;
  }, [
    awareSessionDetails.end_date,
    awareSessionDetails.patient_number,
    awareSessionDetails.questions,
    awareSessionDetails.start_date,
    caseProcedureHistory,
    currentCulture.cultureCode,
    defaultCulture.cultureCode,
    getQuestionTitle,
    questions,
  ]);
};
