import { transferGraspToOrganization } from 'api/graspManagement';
import { GraspSelection } from 'interfaces/grasps';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

type TransferParams = {
  sourceOrganizationId: string;
  targetOrganizationId: string;
  selection: GraspSelection;
};

function useTransferGraspsToOrganization(successFb?: () => void) {
  return useMutationWithResultNotification({
    asyncApiFunction: async ({
      selection,
      sourceOrganizationId,
      targetOrganizationId,
    }: TransferParams) => {
      await transferGraspToOrganization(
        sourceOrganizationId,
        targetOrganizationId,
        selection
      );
    },
    onErrorArray: (errArr) => {
      displayErrorArrayNotifications(errArr, 'OrganizationGrasps.err');
    },
    onSuccess: () => {
      successFb && successFb();
      displaySuccessNotification('OrganizationGrasps.transferSuccess');
    },
    resetOnResult: true,
  });
}

export default useTransferGraspsToOrganization;
