import { organizationContextUpdateNonAssignedAssessmentForm } from 'api/graspManagement';
import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { AssessmentQuestion } from 'interfaces/assessmentForms';
import { IMutationProps } from '../../../../interfaces/ui';

type MutationParam = {
  assessmentBody: AssessmentQuestion[];
  code: string;
  title: string;
  date: Date;
  userId: string;
  hour: string;
};

type useOrganizationContextUpdateNonAssignedAssessmentFormParams = {
  organizationId: string;
  caseId: string;
  timelineId: string;
};

const useOrganizationContextUpdateNonAssignedAssessmentForm = (
  params: useOrganizationContextUpdateNonAssignedAssessmentFormParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification<MutationParam, void>({
    asyncApiFunction: async ({ code, title, assessmentBody, date, hour }) => {
      await organizationContextUpdateNonAssignedAssessmentForm(
        params.organizationId,
        params.caseId,
        params.timelineId,
        code,
        title,
        date,
        hour,
        assessmentBody
      );
    },
    onSuccess: () => {
      displaySuccessNotification('TimelineDetails.assessmentUpdateSuccess');
      if (options.successFb) options.successFb();
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'TimelineDetails.assessmentUpdateError'
      );

      if (options.errorFb) options.errorFb();
    },
    resetOnResult: true,
  });
};

export default useOrganizationContextUpdateNonAssignedAssessmentForm;
