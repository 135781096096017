import React from 'react';
import useGetMasterTimeline from '../../../../api/queries/timelines/useGetMasterTimeline';
import { useInitQuery } from '../../../../hooks/queries/useInitQuery';
import { useCommonParams } from '../../hooks/useCommonParams';
import QueryLoaded from '../../../QueryLoaded';
import Content from './components/Content';
import NavigationPlaceholder from '../NavigationPlaceholder';

const UserNavigation = () => {
  const { userId } = useCommonParams();

  const masterTimeline = useGetMasterTimeline({
    params: { userId },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(masterTimeline);

  return (
    <QueryLoaded query={masterTimeline} onLoading={<NavigationPlaceholder />}>
      {(data) => <Content timeline={data} />}
    </QueryLoaded>
  );
};

export default UserNavigation;
