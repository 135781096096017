import React from 'react';
import SVG from 'react-inlinesvg';
import exclamationMarkIcon from 'assets/images/exclamation_mark.svg';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'reactstrap';

type CaseGroupUnableToSelectProps = {
  onSuccess: () => void;
};

const CaseGroupUnableToSelect = ({
  onSuccess,
}: CaseGroupUnableToSelectProps) => {
  const intl = useIntl();

  return (
    <div className="CaseGroupUnableToSelect">
      <SVG
        src={exclamationMarkIcon}
        title={intl.formatMessage({
          id: 'CaseGroups.CaseGroupUnableToSelect.exclamation',
        })}
      />
      <span>
        <FormattedMessage id="CaseGroups.CaseGroupUnableToSelect.warning" />
      </span>
      <Button color="primary" onClick={onSuccess}>
        <FormattedMessage id="CaseGroups.CaseGroupUnableToSelect.iAgree" />
      </Button>
    </div>
  );
};

export default CaseGroupUnableToSelect;
