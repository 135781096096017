import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { addSubmissionsCaseToGroup } from '../../caseGroups';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { IMutationProps } from '../../../interfaces/ui';

type useAddSubmissionsCaseToGroupParams = {
  organizationCode: string;
  projectId: string;
};

export interface IAddSubmissionsCaseToGroup {
  submissionId: string;
  groupId: string;
}

const useAddSubmissionsCaseToGroup = (
  params: useAddSubmissionsCaseToGroupParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (body: IAddSubmissionsCaseToGroup) => {
      await addSubmissionsCaseToGroup(
        params.organizationCode,
        params.projectId,
        body.groupId,
        body.submissionId
      );
    },
    onSuccess: () => {
      if (options.successFb) options.successFb();
      displaySuccessNotification('CaseGroupItem.addCaseToGroupSuccess');
    },
    onError: (errorArray) => {
      if (options.errorFb) options.errorFb();

      displayErrorArrayNotifications(
        errorArray,
        'CaseGroupItem.addCaseToGroupError'
      );
    },
    resetOnResult: true,
  });
};

export default useAddSubmissionsCaseToGroup;
