import React from 'react';
import useDashboardTemplates from '../../../api/queries/dashboards/useDashboardTemplates';
import { useOrganizationPathParams } from '../../../hooks/paths/useOrganizationPathParams';
import { useInitQuery } from '../../../hooks/queries/useInitQuery';
import QueryLoaded from '../../QueryLoaded';
import FullScreenSpinner from '../../Spinners/FullScreenSpinner';
import Redirect from './components/Redirect';

const DentalOrganizationProxy = () => {
  const { organizationId } = useOrganizationPathParams();

  const dashboardTemplates = useDashboardTemplates({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(dashboardTemplates);

  return (
    <QueryLoaded query={dashboardTemplates} onLoading={<FullScreenSpinner />}>
      {(templates) => <Redirect templates={templates} />}
    </QueryLoaded>
  );
};

export default DentalOrganizationProxy;
