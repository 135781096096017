import React from 'react';
import { Field, Formik, FormikErrors } from 'formik';
import { InputOption } from '../../interfaces/ui';
import FieldInlineSelect, {
  FieldInlineSelectOptionDefinition,
} from '../Fields/Formik/FieldInlineSelect';
import {
  Button,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from 'reactstrap';
import SVG from 'react-inlinesvg';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  ConversationScheduleType,
  ICaseConversationSchedule,
  ICaseGroupConversationSchedule,
  IConversationScheduleHours,
  IUpdateModeInitialValues,
  UpdateRangeMode,
} from '../../interfaces/conversationSchedules';
import InputFieldWithoutImage from '../InputFieldWithoutImage';
import FieldSelect from '../FieldSelect';
import FieldInput from '../FieldInput';
import floppyDiscIcon from '../../assets/images/floppy-disc.svg';
import revertArrowIcon from '../../assets/images/revert-arrow.svg';
import trashIcon from '../../assets/images/trash.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/pro-light-svg-icons';

export interface IEditScheduleTemplateItemFormHandler<T> {
  initialValues: T;
  handleSubmit: (values: T) => Promise<void>;
  handleRemove: (values: T) => Promise<void>;
  validate: (values: T) => FormikErrors<T>;
  options: {
    updateRange: (
      optionsDisabled: boolean
    ) => FieldInlineSelectOptionDefinition[];
    conversationTemplates: InputOption[];
  };
  helpers: {
    isPlusMode: (scheduleProvided: IConversationScheduleHours) => boolean;
  };
}

interface EditScheduleTemplateItemModalProps<T> {
  isOpen: boolean;
  toggle: () => void;
  formHandler: IEditScheduleTemplateItemFormHandler<T>;
  schedule: ICaseGroupConversationSchedule | ICaseConversationSchedule;
}

const EditScheduleTemplateItemModal = <T extends IUpdateModeInitialValues>({
  isOpen,
  toggle,
  formHandler,
  schedule,
}: EditScheduleTemplateItemModalProps<T>) => {
  const intl = useIntl();

  return (
    <Formik
      initialValues={formHandler.initialValues}
      validate={formHandler.validate}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={formHandler.handleSubmit}
    >
      {({ values, submitForm, resetForm, isValid, dirty }) => {
        const handleCancel = () => {
          resetForm();
          toggle();
        };

        const onRemove = async () => {
          await formHandler.handleRemove(values);
        };

        return (
          <Modal
            className="CaseGroupDetailsConversationSchedulesEditTimeModal"
            size="md"
            isOpen={isOpen}
            toggle={toggle}
          >
            <ModalHeader className="text-primary">
              <div className="d-flex align-items-center gap-2">
                <FontAwesomeIcon
                  icon={faClock}
                  title={intl.formatMessage({
                    id: 'CaseGroups.CaseGroupDetails.CaseGroupDetailsConversationSchedules.CaseGroupDetailsConversationSchedulesEditTimeModal.editTime',
                  })}
                />
                <FormattedMessage id="CaseGroups.CaseGroupDetails.CaseGroupDetailsConversationSchedules.CaseGroupDetailsConversationSchedulesEditTimeModal.editTime" />
              </div>
            </ModalHeader>
            <ModalBody className="CaseGroupDetailsConversationSchedulesEditTimeModal__body">
              <Row>
                <Col
                  xs={
                    schedule.is_dirty ||
                    schedule.type === ConversationScheduleType.Recurring
                      ? 12
                      : 8
                  }
                >
                  <InputFieldWithoutImage
                    gray={true}
                    noMargin={true}
                    disabled={true}
                    labelTranslationId="CaseGroups.CaseGroupDetails.CaseGroupDetailsConversationSchedules.CaseGroupDetailsConversationSchedulesEditTimeModal.template"
                    inputId="template_id"
                  >
                    <Field
                      id="template_id"
                      name="template_id"
                      required={true}
                      defaultText={intl.formatMessage({
                        id: 'CaseGroups.CaseGroupDetails.CaseGroupDetailsConversationSchedules.CaseGroupDetailsConversationSchedulesEditTimeModal.template',
                      })}
                      disabled={true}
                      options={formHandler.options.conversationTemplates}
                      component={FieldSelect}
                    />
                  </InputFieldWithoutImage>
                </Col>
                {!schedule.is_dirty &&
                  schedule.type !== ConversationScheduleType.Recurring && (
                    <Col xs={4}>
                      <InputFieldWithoutImage
                        gray={true}
                        noMargin={true}
                        disabled={formHandler.helpers.isPlusMode(
                          schedule as IConversationScheduleHours
                        )}
                        labelTranslationId="CaseGroups.CaseGroupDetails.CaseGroupDetailsConversationSchedules.CaseGroupDetailsConversationSchedulesEditTimeModal.priority"
                        inputId="priority"
                      >
                        <Field
                          id="priority"
                          name="priority"
                          type="number"
                          required={true}
                          component={FieldInput}
                        />
                      </InputFieldWithoutImage>
                    </Col>
                  )}
              </Row>
              <Row>
                {schedule.type === ConversationScheduleType.SingleInstance &&
                  !schedule.is_dirty && (
                    <Col xs={8}>
                      <InputFieldWithoutImage
                        gray={true}
                        noMargin={true}
                        labelTranslationId="CaseGroups.CaseGroupDetails.CaseGroupDetailsConversationSchedules.CaseGroupDetailsConversationSchedulesEditTimeModal.specificDayOfPhase"
                        inputId="specific_day_of_phase"
                      >
                        <Field
                          id="specific_day_of_phase"
                          name="specific_day_of_phase"
                          type="number"
                          required={true}
                          component={FieldInput}
                        />
                      </InputFieldWithoutImage>
                    </Col>
                  )}
                {values.update_mode !== null && (
                  <Col xs={8}>
                    <InputFieldWithoutImage
                      gray={true}
                      noMargin={true}
                      labelTranslationId="CaseGroups.CaseGroupDetails.CaseGroupDetailsConversationSchedules.CaseGroupDetailsConversationSchedulesEditTimeModal.updateRange"
                      inputId="update_mode"
                    >
                      <Field
                        id="update_mode"
                        name="update_mode"
                        type="select"
                        options={formHandler.options.updateRange(
                          schedule.type ===
                            ConversationScheduleType.SingleInstance &&
                            schedule.is_dirty === true
                        )}
                        required={true}
                        component={FieldInlineSelect}
                      />
                    </InputFieldWithoutImage>
                  </Col>
                )}
                {schedule.is_dirty ||
                  (schedule.type === ConversationScheduleType.Recurring && (
                    <Col xs={4}>
                      <InputFieldWithoutImage
                        gray={true}
                        noMargin={true}
                        disabled={formHandler.helpers.isPlusMode(
                          schedule as IConversationScheduleHours
                        )}
                        labelTranslationId="CaseGroups.CaseGroupDetails.CaseGroupDetailsConversationSchedules.CaseGroupDetailsConversationSchedulesEditTimeModal.priority"
                        inputId="priority"
                      >
                        <Field
                          id="priority"
                          name="priority"
                          type="number"
                          required={true}
                          component={FieldInput}
                        />
                      </InputFieldWithoutImage>
                    </Col>
                  ))}
              </Row>
              {values.update_mode === UpdateRangeMode.AllEvents && (
                <Row>
                  <Col xs={4}>
                    <InputFieldWithoutImage
                      gray={true}
                      noMargin={true}
                      disabled={formHandler.helpers.isPlusMode(
                        schedule as IConversationScheduleHours
                      )}
                      labelTranslationId="CaseGroups.CaseGroupDetails.CaseGroupDetailsConversationSchedules.CaseGroupDetailsConversationSchedulesEditTimeModal.startDay"
                      inputId="start_day_offset"
                    >
                      <Field
                        id="start_day_offset"
                        name="start_day_offset"
                        type="number"
                        required={true}
                        component={FieldInput}
                      />
                    </InputFieldWithoutImage>
                  </Col>
                  <Col xs={4}>
                    <InputFieldWithoutImage
                      gray={true}
                      noMargin={true}
                      disabled={formHandler.helpers.isPlusMode(
                        schedule as IConversationScheduleHours
                      )}
                      labelTranslationId="CaseGroups.CaseGroupDetails.CaseGroupDetailsConversationSchedules.CaseGroupDetailsConversationSchedulesEditTimeModal.endDay"
                      inputId="end_day_offset"
                    >
                      <Field
                        id="end_day_offset"
                        name="end_day_offset"
                        type="number"
                        required={true}
                        component={FieldInput}
                      />
                    </InputFieldWithoutImage>
                  </Col>
                  <Col xs={4}>
                    <InputFieldWithoutImage
                      gray={true}
                      noMargin={true}
                      disabled={formHandler.helpers.isPlusMode(
                        schedule as IConversationScheduleHours
                      )}
                      labelTranslationId="CaseGroups.CaseGroupDetails.CaseGroupDetailsConversationSchedules.CaseGroupDetailsConversationSchedulesEditTimeModal.intervalInDays"
                      inputId="recurring_interval"
                    >
                      <Field
                        id="recurring_interval"
                        name="recurring_interval"
                        type="number"
                        required={true}
                        component={FieldInput}
                      />
                    </InputFieldWithoutImage>
                  </Col>
                </Row>
              )}
              {schedule.type !== ConversationScheduleType.Once &&
                schedule.type !== ConversationScheduleType.NonTimeBased && (
                  <Row>
                    <Col xs={4}>
                      <InputFieldWithoutImage
                        gray={true}
                        noMargin={true}
                        labelTranslationId="CaseGroups.CaseGroupDetails.CaseGroupDetailsConversationSchedules.CaseGroupDetailsConversationSchedulesEditTimeModal.timeFrom"
                        inputId="available_from_hour"
                      >
                        <Field
                          id="available_from_hour"
                          name="available_from_hour"
                          type="time"
                          required={true}
                          component={FieldInput}
                        />
                      </InputFieldWithoutImage>
                    </Col>
                    <Col xs={4}>
                      <InputFieldWithoutImage
                        gray={true}
                        noMargin={true}
                        labelTranslationId="CaseGroups.CaseGroupDetails.CaseGroupDetailsConversationSchedules.CaseGroupDetailsConversationSchedulesEditTimeModal.timeTo"
                        inputId="available_to_hour"
                      >
                        <Field
                          id="available_to_hour"
                          name="available_to_hour"
                          type="time"
                          required={true}
                          component={FieldInput}
                        />
                      </InputFieldWithoutImage>
                    </Col>
                    <Col xs={4}>
                      <InputFieldWithoutImage
                        gray={true}
                        noMargin={true}
                        labelTranslationId="CaseGroups.CaseGroupDetails.CaseGroupDetailsConversationSchedules.CaseGroupDetailsConversationSchedulesEditTimeModal.notificationTime"
                        inputId="notification_hour"
                      >
                        <Field
                          id="notification_hour"
                          name="notification_hour"
                          type="time"
                          required={true}
                          component={FieldInput}
                        />
                      </InputFieldWithoutImage>
                    </Col>
                  </Row>
                )}
            </ModalBody>
            <ModalFooter className="CaseGroupDetailsConversationSchedulesEditTimeModal__footer">
              <Button
                disabled={!isValid || !dirty}
                color="primary"
                onClick={submitForm}
                outline={true}
              >
                <SVG src={floppyDiscIcon} title="save" />
              </Button>
              <Button
                disabled={!dirty}
                color="primary"
                onClick={handleCancel}
                outline={true}
              >
                <SVG src={revertArrowIcon} title="cancel" />
              </Button>
              <Button color="secondary" onClick={onRemove} outline={true}>
                <SVG src={trashIcon} title="remove" />
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default EditScheduleTemplateItemModal;
