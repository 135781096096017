import React, { PropsWithChildren } from 'react';
import { ValidConversationTemplate } from '../../../../../../../../interfaces/conversations';
import { FormikUtilsContextValues } from '../../../../../../../../interfaces/forms';
import { FormikUtilsContext } from '../../types';

type FormikUtilsContainerProps = {
  value: FormikUtilsContextValues<ValidConversationTemplate>;
};

const FormikUtilsContainer = ({
  value,
  children,
}: PropsWithChildren<FormikUtilsContainerProps>) => {
  return (
    <FormikUtilsContext.Provider value={value}>
      {children}
    </FormikUtilsContext.Provider>
  );
};

export default FormikUtilsContainer;
