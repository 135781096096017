import PeopleIcon from '@mui/icons-material/People';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LockIcon from '@mui/icons-material/Lock';
import LanguageIcon from '@mui/icons-material/Language';
import WcIcon from '@mui/icons-material/Wc';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import BusinessIcon from '@mui/icons-material/Business';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';

//components
import { Field, FormikProps } from 'formik';
import { DEFAULT_COUNTRY_PREFIX } from 'helpers/constants'; //helpers
import {
  extendedGenderOptions,
  genderOptions,
} from 'helpers/utils/translationObject'; //interfaces
import { RegisterFormKeys } from 'interfaces/enums';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PhoneInput from 'react-phone-input-2';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import { allTimezones, useTimezoneSelect } from 'react-timezone-select';
import MenuItem from '@mui/material/MenuItem/MenuItem';

type FieldProps<T> = {
  formik: FormikProps<T>;
  isDisabled?: boolean;
  isGray?: boolean;
  isOptional?: boolean;
};

export const SsnField = <T,>({
  formik,
  isDisabled = false,
  isOptional = true,
}: FieldProps<T>) => {
  const intl = useIntl();

  return (
    <TextField
      variant="outlined"
      disabled={isDisabled}
      required={!isOptional}
      fullWidth
      id="ssn"
      name="ssn"
      label={<FormattedMessage id="General.Ssn" />}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MedicalInformationIcon />
          </InputAdornment>
        ),
      }}
      placeholder={intl.formatMessage({ id: 'General.Ssn' })}
      value={formik.values['ssn']}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched['ssn'] && Boolean(formik.errors['ssn'])}
      helperText={formik.touched['ssn'] && formik.errors['ssn']}
    />
  );
};

export const AddressField = <T,>({
  formik,
  isDisabled = false,
  isOptional = true,
}: FieldProps<T>) => {
  const intl = useIntl();

  return (
    <TextField
      variant="outlined"
      disabled={isDisabled}
      required={!isOptional}
      fullWidth
      id="address"
      name="address"
      label={<FormattedMessage id="General.Address" />}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <BusinessIcon />
          </InputAdornment>
        ),
      }}
      placeholder={intl.formatMessage({ id: 'General.Address' })}
      value={formik.values['address']}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched['address'] && Boolean(formik.errors['address'])}
      helperText={formik.touched['address'] && formik.errors['address']}
    />
  );
};

export const PhoneNumberField = <T,>({
  isDisabled = false,
  isOptional = false,
}: FieldProps<T>) => {
  const intl = useIntl();

  return (
    <Field name={RegisterFormKeys.PHONE_NUMBER} required={!isOptional}>
      {({ field, form }) => {
        return (
          <PhoneInput
            inputClass="w-100 py-3"
            specialLabel={intl.formatMessage({ id: 'General.PhoneNumber' })}
            inputProps={{
              name: field?.name,
              id: field?.name,
            }}
            value={field?.value}
            countryCodeEditable={false}
            disabled={isDisabled}
            country={DEFAULT_COUNTRY_PREFIX}
            {...field}
            onChange={(phone, country, e, formattedValue) => {
              form.setValues({
                ...form.values,
                [field.name]: formattedValue,
              });
            }}
          />
        );
      }}
    </Field>
  );
};

interface GenderFieldProps<T> extends FieldProps<T> {
  displayUnspecified?: boolean;
}

export const GenderField = <T,>({
  formik,
  isDisabled = false,
  isOptional = true,
  displayUnspecified = false,
}: GenderFieldProps<T>) => {
  const intl = useIntl();

  const options = displayUnspecified ? extendedGenderOptions : genderOptions;

  return (
    <TextField
      variant="outlined"
      fullWidth
      disabled={isDisabled}
      required={!isOptional}
      select={true}
      id="gender"
      name="gender"
      label={<FormattedMessage id="General.Gender" />}
      value={formik.values['gender']}
      onChange={formik.handleChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <WcIcon />
          </InputAdornment>
        ),
      }}
      onBlur={formik.handleBlur}
      error={formik.touched['gender'] && Boolean(formik.errors['gender'])}
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {intl.formatMessage({ id: option.translationId })}
        </MenuItem>
      ))}
    </TextField>
  );
};

export const BirthdateField = <T,>({
  formik,
  isDisabled = false,
  isOptional = true,
}: FieldProps<T>) => {
  const intl = useIntl();

  return (
    <TextField
      variant="outlined"
      disabled={isDisabled}
      required={!isOptional}
      fullWidth
      type="date"
      id="birthdate"
      name="birthdate"
      label={<FormattedMessage id="General.Birthdate" />}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <CalendarTodayIcon />
          </InputAdornment>
        ),
      }}
      placeholder={intl.formatMessage({ id: 'General.Birthdate' })}
      value={formik.values['birthdate']}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched['birthdate'] && Boolean(formik.errors['birthdate'])}
      helperText={formik.touched['birthdate'] && formik.errors['birthdate']}
    />
  );
};

interface EmailFieldProps<T> extends FieldProps<T> {
  isOptional?: boolean;
}

export const EmailField = <T,>({
  formik,
  isDisabled = false,
  isOptional = false,
}: EmailFieldProps<T>) => {
  const intl = useIntl();

  return (
    <TextField
      variant="outlined"
      disabled={isDisabled}
      required={!isOptional}
      fullWidth
      type="email"
      id="email"
      name="email"
      label={<FormattedMessage id="General.Email" />}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <MailOutlineIcon />
          </InputAdornment>
        ),
      }}
      placeholder={intl.formatMessage({ id: 'General.Email' })}
      value={formik.values['email']}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched['email'] && Boolean(formik.errors['email'])}
      helperText={formik.touched['email'] && formik.errors['email']}
    />
  );
};

export const FirstNameField = <T,>({
  formik,
  isDisabled = false,
  isOptional = false,
}: FieldProps<T>) => {
  const intl = useIntl();

  return (
    <TextField
      variant="outlined"
      disabled={isDisabled}
      required={!isOptional}
      fullWidth
      id="first_name"
      name="first_name"
      label={<FormattedMessage id="General.FirstName" />}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <PeopleIcon />
          </InputAdornment>
        ),
      }}
      placeholder={intl.formatMessage({ id: 'General.FirstName' })}
      value={formik.values['first_name']}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={
        formik.touched['first_name'] && Boolean(formik.errors['first_name'])
      }
      helperText={formik.touched['first_name'] && formik.errors['first_name']}
    />
  );
};

export const LastNameField = <T,>({
  formik,
  isDisabled = false,
  isOptional = false,
}: FieldProps<T>) => {
  const intl = useIntl();

  return (
    <TextField
      variant="outlined"
      disabled={isDisabled}
      required={!isOptional}
      fullWidth
      id="last_name"
      name="last_name"
      label={<FormattedMessage id="General.LastName" />}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <PeopleOutlineIcon />
          </InputAdornment>
        ),
      }}
      placeholder={intl.formatMessage({ id: 'General.LastName' })}
      value={formik.values['last_name']}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={formik.touched['last_name'] && Boolean(formik.errors['last_name'])}
      helperText={formik.touched['last_name'] && formik.errors['last_name']}
    />
  );
};

export const TimezoneField = <T,>({
  formik,
  isDisabled = false,
  isOptional = false,
}: FieldProps<T>) => {
  const { parseTimezone, options } = useTimezoneSelect({
    timezones: allTimezones,
    labelStyle: 'original',
    displayValue: 'GMT',
    currentDatetime: new Date(),
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue('timezone_id', parseTimezone(e.target.value).value);
  };

  return (
    <TextField
      variant="outlined"
      fullWidth
      disabled={isDisabled}
      required={!isOptional}
      select={true}
      id="timezone_id"
      name="timezone_id"
      label={<FormattedMessage id="General.Timezone" />}
      value={parseTimezone(formik.values['timezone_id']).value}
      onChange={onChange}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <LanguageIcon />
          </InputAdornment>
        ),
      }}
      onBlur={formik.handleBlur}
      error={
        formik.touched['timezone_id'] && Boolean(formik.errors['timezone_id'])
      }
    >
      {options.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </TextField>
  );
};

type PasswordFieldProps<T> = {
  formik: FormikProps<T>;
  repeat: boolean;
};

export const PasswordField = <T,>({
  formik,
  repeat = false,
}: PasswordFieldProps<T>) => {
  const intl = useIntl();

  return (
    <TextField
      variant="outlined"
      required={true}
      fullWidth
      type="password"
      id={repeat ? 'passwordRepeat' : 'password'}
      name={repeat ? 'passwordRepeat' : 'password'}
      label={
        <FormattedMessage
          id={repeat ? 'General.RepeatPassword' : 'General.Password'}
        />
      }
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <LockIcon />
          </InputAdornment>
        ),
      }}
      placeholder={intl.formatMessage({
        id: repeat ? 'General.RepeatPassword' : 'General.Password',
      })}
      value={formik.values[repeat ? 'passwordRepeat' : 'password']}
      onChange={formik.handleChange}
      onBlur={formik.handleBlur}
      error={
        formik.touched[repeat ? 'passwordRepeat' : 'password'] &&
        Boolean(formik.errors[repeat ? 'passwordRepeat' : 'password'])
      }
      helperText={
        formik.touched[repeat ? 'passwordRepeat' : 'password'] &&
        formik.errors[repeat ? 'passwordRepeat' : 'password']
      }
    />
  );
};
