import { useCallback, useState } from 'react';
import { IPatchObject } from '../../../../../../../../../../../interfaces/request';
import { OtherDevicesTableTypes } from '../types';
import { IOrganizationDevice } from '../../../../../../../../../../../interfaces/organizations';
import { useHandleSave } from './useHandleSave';

export const useEditingRow = () => {
  const [editingRow, setEditingRow] =
    useState<OtherDevicesTableTypes.EditingRow | null>(null);

  const onSave = () => setEditingRow(null);

  const { saveAsync, isSaving } = useHandleSave(onSave);

  const handleSave = useCallback(async () => {
    if (!editingRow) return;

    const excludedKeys = ['id'];
    let patchObject: IPatchObject[] = [];

    Object.entries(editingRow).forEach(([key, val]) => {
      if (excludedKeys.includes(key)) return;

      patchObject.push({
        op: 'replace',
        path: `/${key}`,
        value: val,
      });
    });

    await saveAsync({ id: editingRow.id, patch: patchObject });
  }, [editingRow, saveAsync]);

  const handleEdit = useCallback(
    (row: IOrganizationDevice) => () => {
      setEditingRow({
        id: row.id,
        name: row.name,
        screen_lock_code: row.screen_lock_code,
      });
    },
    []
  );

  const onEdit = useCallback((target: string, value: string) => {
    setEditingRow((prev) => {
      if (!prev) return prev;
      return { ...prev, [target]: value };
    });
  }, []);

  const handleCancel = useCallback(() => setEditingRow(null), []);

  const isCellInEditMode = useCallback(
    (id: string) => {
      return !!editingRow && editingRow?.id === id;
    },
    [editingRow]
  );

  return {
    handleSave,
    handleEdit,
    handleCancel,
    isSaving,
    isCellInEditMode,
    onEdit,
  };
};
