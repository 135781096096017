import { useTheme } from '@mui/material';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import { useTypedContext } from '../../../../../../../../../../../../../../../../hooks/useTypedContext';
import {
  DashboardDataReducerContext,
  DashboardTheme,
} from '../../../../../../../../../../types';
import { useLegendText } from '../../hooks/useLegendText';

type HorizontalMoreProps = {
  payload: any[];
  lengthConstraint: number;
};

const HorizontalMore = ({ payload, lengthConstraint }: HorizontalMoreProps) => {
  const theme = useTheme();
  const [dashboardData] = useTypedContext(DashboardDataReducerContext);

  const getLegendText = useLegendText();

  const payloadSlice = payload.slice(lengthConstraint + 1);

  return (
    <>
      <div
        className="AwareCasesHorizontalLegend__item AwareCasesHorizontalLegendItem"
        id="AwareCasesHorizontalLegendItemPopover"
      >
        <div
          style={{
            border: '2px solid',
            borderColor: theme.palette.text.primary,
          }}
          className="AwareCasesHorizontalLegendItem__circle AwareCasesHorizontalLegendItem__circle--outline"
        />
        <span
          style={{ color: theme.palette.text.primary }}
          className="AwareCasesHorizontalLegendItem__title"
        >
          <FormattedMessage
            id="General.PlusNMore"
            values={{ n: payload.length - lengthConstraint - 1 }}
          />
        </span>
      </div>
      <UncontrolledPopover
        className={classNames('GenericPopover', {
          'GenericPopover--dark': dashboardData.theme === DashboardTheme.Dark,
        })}
        placement="bottom"
        target="AwareCasesHorizontalLegendItemPopover"
      >
        <PopoverHeader>
          <FormattedMessage id="General.RemainingItems" />
        </PopoverHeader>
        <PopoverBody className="AwareCasesHorizontalLegend__container gap-2">
          {payloadSlice.map((item, index) => (
            <div key={item.value} className="d-flex gap-3 align-items-center">
              <div
                style={{
                  background: theme.palette.background.default,
                  width: '1rem',
                  height: '1rem',
                  borderRadius: '1rem',
                }}
              />
              <span
                style={{ color: theme.palette.text.primary }}
                className="AwareCasesHorizontalLegendItem__title"
              >
                {getLegendText((item.value as string) ?? '')}
              </span>
            </div>
          ))}
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
};

export default HorizontalMore;
