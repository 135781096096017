import LockIcon from '@mui/icons-material/Lock';
import { Box, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const NotAuthorized = () => {
  return (
    <Box
      sx={{ color: 'text.secondary' }}
      className="position-absolute top-0 left-0 d-flex flex-column align-items-center justify-content-center vh-100 vw-100"
    >
      <LockIcon sx={{ fontSize: 150 }} />
      <Typography variant="h5">
        <FormattedMessage id="Auth.YouAreNotAuthorizedToSeeThisView" />
      </Typography>
    </Box>
  );
};

export default NotAuthorized;
