import React from 'react';
import Visualizations from './components/Visualizations';
import { VisualizationDataContext } from './components/Visualizations/types';
import VisualizationDataProvider from './providers/VisualizationDataProvider';
import VisualizationFiltersProvider from './providers/VisualizationFiltersProvider';
import DashboardLayoutValidator from './validators/DashboardLayoutValidator';

const DashboardLayout = () => {
  return (
    <DashboardLayoutValidator>
      <VisualizationDataProvider>
        {(data) => (
          <VisualizationFiltersProvider data={data}>
            {(filteredData) => (
              <VisualizationDataContext.Provider value={filteredData}>
                <Visualizations />
              </VisualizationDataContext.Provider>
            )}
          </VisualizationFiltersProvider>
        )}
      </VisualizationDataProvider>
    </DashboardLayoutValidator>
  );
};

export default DashboardLayout;
