import { useTranslations } from '../../../../../hooks/useTranslations';
import { useInitQuery } from '../../../../../hooks/queries/useInitQuery';
import { useMemo } from 'react';
import { useCommonData } from './useCommonData';
import { DashboardTemplate } from '../../../../../interfaces/dashboards';
import { useCultureEntry } from '../../../../../hooks/cultures/useCultureEntry';

export const useBreadcrumbs = (template: DashboardTemplate) => {
  const { attachPrefix, wrapWithIntl } = useTranslations(
    'Organizations.OrganizationDashboard'
  );

  const templateName = useCultureEntry(template.name);

  const { organizationDetails } = useCommonData();
  useInitQuery(organizationDetails);

  const breadcrumbs = useMemo(
    () => [
      {
        text: wrapWithIntl(attachPrefix('organization')),
        link: null,
        key: 'organization',
      },
      {
        text: organizationDetails.data?.name ?? '',
        link: `/my-organizations/${organizationDetails.data?.id}`,
        key: 'organization-name',
      },
      {
        text: templateName ?? '',
        link: null,
        key: 'organization-dashboard',
      },
    ],
    [
      attachPrefix,
      organizationDetails.data?.id,
      organizationDetails.data?.name,
      templateName,
      wrapWithIntl,
    ]
  );

  return {
    breadcrumbs,
    isLoading: organizationDetails.isLoading,
  };
};
