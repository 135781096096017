import { getResourceActivity } from 'api/activities';
import {
  convertIntoObjectWithPageInfo,
  returnAllDataFromResponse,
} from 'helpers/utils/reactQueryHelpers';
import { ActivityTypes, IActivity } from 'interfaces/activities';
import { IError, IInfiniteResult } from 'interfaces/response';
import { DateRange } from 'react-day-picker';
import { useInfiniteQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { SearchAndPagingQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

interface UseInfiniteGetResourceEventLogsParams
  extends SearchAndPagingQueryParams {
  resourceId: string;
  dateRange?: DateRange;
  selectedEventTypes?: ActivityTypes[];
}

function useInfiniteGetResourceEventLogs({
  params,
  options,
}: IQueryRequest<UseInfiniteGetResourceEventLogsParams>) {
  const {
    resourceId,
    searchValue = '',
    pageSize = 20,
    dateRange,
    selectedEventTypes,
  } = params;

  const response = useInfiniteQuery<IInfiniteResult<IActivity[]>, IError>({
    ...options,
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getResourceActivity(
        resourceId,
        {
          page_size: pageSize,
          page: pageParam,
          search: searchValue,
          sort: 'CreationDesc',
        },
        dateRange,
        selectedEventTypes
      );

      return convertIntoObjectWithPageInfo<IActivity[]>(data);
    },
    queryKey: [
      QueryKeys.getEventLogs,
      resourceId,
      pageSize,
      searchValue,
      dateRange,
      selectedEventTypes,
    ],
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage;
    },
    getPreviousPageParam: (firstPage) => firstPage.previousPage,
  });

  return {
    logs: returnAllDataFromResponse<IActivity>(response),
    totalItemsCount: response.data?.pages[0].totalItemsCount ?? 0,
    ...response,
  };
}

export default useInfiniteGetResourceEventLogs;
