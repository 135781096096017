import useCultures from '../cultures/useCultures';
import { useCallback } from 'react';
import { IOrganizationObservationMeaning } from '../../interfaces/observationMeanings';

export const useMeaningName = () => {
  const { currentCulture, defaultCulture } = useCultures();

  return useCallback(
    (meaning: IOrganizationObservationMeaning) => {
      return currentCulture.cultureCode === defaultCulture.cultureCode
        ? meaning.name_en
        : meaning.name_no;
    },
    [currentCulture.cultureCode, defaultCulture.cultureCode]
  );
};
