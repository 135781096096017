import { ICaseDefaultSetup } from '../interfaces/caseDefaultSetups';
import { IPatchObject } from '../interfaces/request';
import { IResponse } from '../interfaces/response';
import {
  ICreateCaseDefaultSetup,
  IUpsertCaseDefaultSetup,
} from '../interfaces/trendVisualizations';
import request from './utils/request';

export const getOrganizationsCaseDefaultSetups = (organizationId: string) => {
  return request<undefined, IResponse<ICaseDefaultSetup[]>>({
    url: `organizations/${organizationId}/case_default_setups`,
    method: 'GET',
  });
};

export const addCaseDefaultSetup = (data: ICreateCaseDefaultSetup) =>
  request<ICreateCaseDefaultSetup, IResponse<ICaseDefaultSetup>>({
    url: `/case_default_setups`,
    method: 'POST',
    data,
  });

export const updateTrendVisualizationTemplateWithinSetup = (
  setupId: string,
  patchData: IPatchObject[]
) =>
  request<IPatchObject[], IResponse<ICaseDefaultSetup>>({
    url: `/case_default_setups/${setupId}`,
    method: 'PATCH',
    data: patchData,
  });

export const upsertCaseDefaultSetups = (data: IUpsertCaseDefaultSetup[]) =>
  request<IUpsertCaseDefaultSetup[], IResponse<string>>({
    url: `/case_default_setups`,
    method: 'PUT',
    data,
  });
