import {
  faCalendar,
  faChartLine,
  faCommentsAlt,
  faFileSearch,
} from '@fortawesome/pro-light-svg-icons';
import { useMemo } from 'react';
import { FieldInlineSelectOptionDefinition } from '../../../../../../../../../../../components/Fields/Formik/FieldInlineSelect';
import { ChangeCaseTabsValues } from '../../../hooks/useTabs';

export const useChangeCaseTabsOptions = (
  onTabChange: (val: number) => void,
  selectedTab: ChangeCaseTabsValues
) => {
  return useMemo((): FieldInlineSelectOptionDefinition[] => {
    return [
      {
        icon: faChartLine,
        title: '',
        value: 1,
        highlight: selectedTab === ChangeCaseTabsValues.Dashboard,
        handleChange: () => onTabChange(0),
      },
      {
        icon: faCalendar,
        title: '',
        value: 2,
        highlight: selectedTab === ChangeCaseTabsValues.Schedule,
        handleChange: () => onTabChange(1),
      },
      {
        icon: faCommentsAlt,
        title: '',
        value: 3,
        highlight: selectedTab === ChangeCaseTabsValues.Conversations,
        handleChange: () => onTabChange(2),
      },
      {
        icon: faFileSearch,
        title: '',
        value: 4,
        highlight: selectedTab === ChangeCaseTabsValues.EventLog,
        handleChange: () => onTabChange(3),
      },
    ];
  }, [onTabChange, selectedTab]);
};
