import { useInitQuery } from 'hooks/queries/useInitQuery';
import { sortBy } from 'lodash';
import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { NavItem } from 'reactstrap';
import {
  checkUserRole,
  isOrganizationAdminForOrganizationProvided,
} from '../../../../../../helpers/utils/auth';
import { useOrganizationPathParams } from '../../../../../../hooks/paths/useOrganizationPathParams';
import { DashboardTemplate } from '../../../../../../interfaces/dashboards';
import { UserRoles } from '../../../../../../interfaces/membership';
import { IOrganization } from '../../../../../../interfaces/organizations';
import QueryLoaded from '../../../../../QueryLoaded';
import NavigationPlaceholder from '../../../NavigationPlaceholder';
import { useCommonData } from '../../hooks/useCommonData';
import DentalClinicItem from './components/DentalClinicItem';

type DentalClinicProps = {
  organization: IOrganization;
};

const DentalClinic = ({ organization }: DentalClinicProps) => {
  const { organizationId } = useOrganizationPathParams();
  const { dashboardTemplates } = useCommonData();
  useInitQuery(dashboardTemplates);

  const getTemplatesForNavigation = useCallback(
    (templates: DashboardTemplate[]) => {
      let _templates = sortBy(templates, (p) => p.sequence);
      _templates = _templates.filter((p) => p.is_available_on_nav);
      return _templates;
    },
    []
  );

  const isRoleMatched = checkUserRole(UserRoles.ORGANIZATION_ADMIN);

  return (
    <QueryLoaded
      query={dashboardTemplates}
      onLoading={<NavigationPlaceholder />}
    >
      {(templates) => (
        <>
          {getTemplatesForNavigation(templates).map((template) => (
            <DentalClinicItem key={template.id} template={template} />
          ))}
          {isRoleMatched &&
            isOrganizationAdminForOrganizationProvided(organizationId) && (
              <NavItem>
                <NavLink to={`/my-organizations/${organization.id}`}>
                  <FormattedMessage id="Organizations.MyOrganization" />
                </NavLink>
              </NavItem>
            )}
        </>
      )}
    </QueryLoaded>
  );
};

export default DentalClinic;
