import React from 'react';
import { useIntl } from 'react-intl';
import ItemContainer from '../ItemContainer';
import Entry from './components/Entry';
import { useTypedContext } from '../../../../../../../../hooks/useTypedContext';
import { OperationalDataContext } from '../../providers/OperationalDataProvider';
import { useDuration } from './hooks/useDuration';
import Circle from './components/Circle';
import { Box } from '@mui/material';
import useMobile from '../../../../../../../../hooks/useBreakpoint';
import classNames from 'classnames';

const StaticFields = () => {
  const intl = useIntl();

  const isMobile = useMobile();

  const operationalData = useTypedContext(OperationalDataContext);

  const duration = useDuration(operationalData.awareSessionDetails);

  return (
    <ItemContainer>
      <Box
        className={classNames('grid items-center', {
          'grid-rows-1': !isMobile,
          'grid-columns-1 gap-3': isMobile,
        })}
        sx={{
          gridTemplateColumns: isMobile
            ? '1fr'
            : 'minmax(0, 1fr) auto minmax(0, 1fr) auto minmax(0, 1fr) auto minmax(0, 1fr)',
        }}
      >
        <Entry
          header={intl.formatMessage({ id: 'Cases.ProcedureMode' })}
          value={operationalData.caseDetails.category?.name ?? '-'}
        />
        {!isMobile && <Circle />}
        <Entry
          header={intl.formatMessage({ id: 'Cases.Duration' })}
          value={duration}
        />
        {!isMobile && <Circle />}
        <Entry
          header={intl.formatMessage({ id: 'Cases.AllSqueezes' })}
          value={operationalData.awareSessionDetails.total_squeezes.toString()}
        />
        {!isMobile && <Circle />}
        <Entry
          header={intl.formatMessage({ id: 'Cases.Alarms' })}
          value={operationalData.awareSessionDetails.alarm_incidents.toString()}
        />
      </Box>
    </ItemContainer>
  );
};

export default StaticFields;
