import React from 'react';
import {
  IAsset,
  IEducationMaterial,
} from '../../../../../../../interfaces/educationMaterials';
import { Badge, Input } from 'reactstrap';
import classNames from 'classnames';
import Flag from 'react-flagkit';
import { useCultures } from './hooks/useCultures';
import { useAssetLinking } from './hooks/useAssetLinking';
import { AvailableCulture } from '../../../../../../../hooks/cultures/useCultures';

type AssetCultureSelectorProps = {
  asset: IAsset;
  material: IEducationMaterial;
  culture: AvailableCulture;
  onLink?: (culture: string) => void;
  onUnlink?: (culture: string) => void;
};

const AssetCultureSelector = ({
  culture,
  asset,
  material,
  onLink,
  onUnlink,
}: AssetCultureSelectorProps) => {
  const { isLoading } = useAssetLinking(
    asset.id,
    material.id,
    culture.cultureCode
  );

  const { assetCultures, toggleCulture } = useCultures(
    asset.id,
    material,
    culture,
    onLink,
    onUnlink
  );

  return (
    <Badge
      className={classNames('d-flex align-items-center gap-2', {
        'opacity-25': isLoading,
      })}
      key={culture.cultureCode}
      color="light"
    >
      <Input
        checked={assetCultures.includes(culture.cultureCode)}
        type="checkbox"
        onClick={toggleCulture}
      />
      <span className="text-dark" style={{ fontSize: '0.8rem' }}>
        {culture.cultureCode}
      </span>
      <Flag size={20} country={culture.upperCountryCode} />
    </Badge>
  );
};

export default AssetCultureSelector;
