import {
  addDays,
  addHours,
  addMinutes,
  differenceInCalendarDays,
  format,
  setHours,
  setMinutes,
  setSeconds,
} from 'date-fns';
import { useCallback } from 'react';
import { getISOStringUTCNonAware } from '../../../../../../../../../../../../../../../../../helpers/dates';
import { useTypedContext } from '../../../../../../../../../../../../../../../../../hooks/useTypedContext';
import { CasePhaseHistoryContext } from '../../../../../../../types';

export const useHandleDates = () => {
  const casePhaseHistory = useTypedContext(CasePhaseHistoryContext);

  const getNthDayOfPhase = (dateString: string) => {
    casePhaseHistory.sort((a, b) => b.sequence - a.sequence);
    let currentCasePhaseHistory = casePhaseHistory[0];

    return (
      differenceInCalendarDays(
        new Date(dateString),
        new Date(currentCasePhaseHistory.date_from)
      ) + 1
    );
  };

  const getDate = useCallback(
    (day: number | undefined, hour: string) => {
      if (!day) return undefined;

      casePhaseHistory.sort((a, b) => b.sequence - a.sequence);
      let currentCasePhaseHistory = casePhaseHistory.at(0);

      if (!currentCasePhaseHistory) return undefined;

      let targetDate = addDays(
        new Date(currentCasePhaseHistory.date_from),
        day - 1
      );

      targetDate = setHours(targetDate, 0);
      targetDate = setMinutes(targetDate, 0);
      targetDate = setSeconds(targetDate, 0);

      const hourSplit = hour.split(':');

      targetDate = addHours(targetDate, parseInt(hourSplit[0]));
      targetDate = addMinutes(targetDate, parseInt(hourSplit[1]));

      return getISOStringUTCNonAware(targetDate);
    },
    [casePhaseHistory]
  );

  const getNotificationTime = (dateString: string) => {
    return format(new Date(dateString), 'HH:mm');
  };

  return {
    getNthDayOfPhase,
    getNotificationTime,
    getDate,
  };
};
