import { SequenceIdentified } from '../../interfaces/ui';

export const wrapWithSequenceIdentified = <T extends object>(
  arr: T[]
): SequenceIdentified<T>[] => {
  return arr.map((item, index) => ({
    ...item,
    sequence: index,
  }));
};
