import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { IUpsertFeedbackResource } from '../../../interfaces/feedbackResources';
import { modifyFeedbackResource } from '../../feedbackResources';
import { displaySuccessNotification } from '../../../modules/Notifications';

type ModifyFeedbackResourceFunctionBody = IUpsertFeedbackResource & {
  organizationId: string;
  groupCode: string;
};

function useModifyFeedbackResource(successFb?: () => void) {
  return useMutationWithResultNotification({
    onSuccess: () => {
      displaySuccessNotification('FeedbackResources.variantModified');
      successFb && successFb();
    },
    asyncApiFunction: async (body: ModifyFeedbackResourceFunctionBody) => {
      await modifyFeedbackResource(body.organizationId, body.groupCode, {
        culture: body.culture,
        items: body.items,
        sequence: body.sequence,
      });
    },
    resetOnResult: true,
  });
}

export default useModifyFeedbackResource;
