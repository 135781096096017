import {
  ICreateOrganizationBody,
  IOrganizationDetails,
} from 'interfaces/membership';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { ITypedMutationRequest } from '../../../interfaces/request';
import { createNewOrganization } from '../../membership';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

function useCreateOrganization({
  options,
  silent,
}: ITypedMutationRequest<any, IOrganizationDetails>) {
  const mutationData = useMutationWithResultNotification({
    asyncApiFunction: async (data: ICreateOrganizationBody) => {
      const { main_organization_id: mainOrganizationParam, ...rest } = data;

      const body = {
        ...rest,
        main_organization_id: mainOrganizationParam
          ? mainOrganizationParam
          : null,
      };

      const createdOrganization = await createNewOrganization(body);
      return createdOrganization.data;
    },
    resetOnResult: false,
    onSuccess: () => {
      if (!silent)
        displaySuccessNotification(
          'MyOrganizations.createOrganization.success'
        );

      if (mutationData.data) {
        options.successFb?.(mutationData.data.data);
        mutationData.reset();
      }
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'MyOrganizations.createOrganization.errors'
      );
    },
  });

  return mutationData;
}

export default useCreateOrganization;
