import React, { useMemo, useState } from 'react';
import { ISummarizedBaseCaseGroup } from '../../../../../interfaces/caseGroups';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import { formatDate } from '../../../../../helpers/dates';
import CaseGroupCollectionItem, {
  CaseGroupCollectionStyle,
} from '../../../../../components/CaseGroupCollectionItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersClass } from '@fortawesome/pro-light-svg-icons';
import RadioLikeCaption from '../../../../membership/Login/components/RadioLikeCaption';
import useAddSubmissionsCaseToGroup from '../../../../../api/mutations/caseGroups/useAddSubmissionsCaseToGroup';

type CaseGroupSelectOrPostponeProps = {
  organizationCode: string;
  projectId: string;
  submissionId: string;
  onSuccess: () => void;
  caseGroups: ISummarizedBaseCaseGroup[];
};

const CaseGroupSelectOrPostpone = ({
  organizationCode,
  projectId,
  submissionId,
  onSuccess,
  caseGroups,
}: CaseGroupSelectOrPostponeProps) => {
  const { mutateAsync: addSubmissionsCaseToGroup } =
    useAddSubmissionsCaseToGroup(
      {
        organizationCode: organizationCode,
        projectId: projectId,
      },
      {
        successFb: onSuccess,
      }
    );

  const [selectedGroupIndex, setSelectedGroupIndex] = useState<number>(0);

  const renderSelectedGroup = useMemo(() => {
    const selectedGroup = caseGroups[selectedGroupIndex];

    const caseGroupTitle = (
      <span>
        <FormattedMessage id="CaseGroups.CaseGroupSelectFromAvailable.list.item.groupNo" />
        <span>{selectedGroupIndex + 1}</span>
      </span>
    );

    const caseGroupInfo = (
      <span>
        <FormattedMessage id="CaseGroups.CaseGroupSelectFromAvailable.list.item.startingDate" />
        <span>
          {formatDate(selectedGroup.starting_date, {
            addSeconds: false,
          })}
        </span>
      </span>
    );

    return (
      <CaseGroupCollectionItem
        style={CaseGroupCollectionStyle.PrimaryOutline}
        title={caseGroupTitle}
        icon={<FontAwesomeIcon icon={faUsersClass} />}
        info={caseGroupInfo}
        actions={[
          {
            key: 'CaseGroupSelectOrPostpone_selected',
            icon: <RadioLikeCaption translationId="" selected={true} />,
            onClick: undefined,
          },
        ]}
      />
    );
  }, [caseGroups, selectedGroupIndex]);

  const handleAddCaseToGroup = async () => {
    if (selectedGroupIndex < 0 || selectedGroupIndex >= caseGroups.length)
      return;

    await addSubmissionsCaseToGroup({
      groupId: caseGroups[selectedGroupIndex].id,
      submissionId: submissionId,
    });
  };
  const handlePostpone = () => {
    setSelectedGroupIndex((prev) => prev + 1);
  };

  return (
    <div className="CaseGroupSelectFromAvailable">
      <div className="CaseGroupSelect__header">
        <h3 className="CaseGroupSelect__title">
          <FormattedMessage id="CaseGroups.CaseGroupSelectOrPostpone.header.title" />
        </h3>
        <span className="CaseGroupSelect__subtitle">
          <FormattedMessage id="CaseGroups.CaseGroupSelectOrPostpone.header.subtitle" />
        </span>
      </div>
      <div className="CaseGroupSelect__list CaseGroupCollection">
        {renderSelectedGroup}
      </div>
      <div className="mb-4">
        <span className="CaseGroupSelect__subtitle text-muted">
          <FormattedMessage id="CaseGroups.CaseGroupSelectOrPostpone.header.ifYouAreNotAbleToJoin" />
        </span>
      </div>
      <div className="CaseGroupSelect__actions">
        <Button
          className="CaseGroupSelect__action"
          color="primary"
          onClick={handleAddCaseToGroup}
        >
          <FormattedMessage id="CaseGroups.CaseGroupSelectOrPostpone.apply.text" />
        </Button>
        <Button
          className="CaseGroupSelect__action"
          outline={true}
          disabled={selectedGroupIndex === caseGroups.length - 1}
          color="primary"
          onClick={handlePostpone}
        >
          <FormattedMessage id="CaseGroups.CaseGroupSelectOrPostpone.postpone.text" />
        </Button>
      </div>
    </div>
  );
};

export default CaseGroupSelectOrPostpone;
