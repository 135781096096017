import { textWrapEllipse } from '../../../../helpers/utils/strings';
import { Sector } from 'recharts';
import React from 'react';

const PieChartActiveShape = (props: any) => {
  const {
    cx,
    cy,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    value,
    percent,
    valueFormatter,
  } = props;

  return (
    <g>
      <text x={cx} y={cy} fontSize={15} textAnchor="middle" fill={fill}>
        {textWrapEllipse(payload.name, 15)}
      </text>
      <text x={cx} y={cy} dy={15} fontSize={13} textAnchor="middle" fill={fill}>
        {`${valueFormatter ? valueFormatter(value) : value} | ${(
          percent * 100
        ).toFixed(2)}%`}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
    </g>
  );
};

export default PieChartActiveShape;
