import { IOrganizationDetails } from '../../../interfaces/membership';
import { FormattedMessage } from 'react-intl';
import React from 'react';

export const getBreadcrumbs = (organization: IOrganizationDetails) => [
  {
    text: (
      <FormattedMessage id="ConversationsAndFeedbacks.breadcrumbs.organization" />
    ),
    link: null,
    key: 'organization',
  },
  {
    text: organization.name,
    link: `/my-organizations/${organization.id}`,
    key: 'organization-name',
  },
  {
    text: (
      <FormattedMessage id="ConversationsAndFeedbacks.breadcrumbs.conversationsAndFeedbacks" />
    ),
    link: null,
    key: 'conversations-and-feedbacks',
  },
];
