import { useCallback } from 'react';
import useAddGraspToOrganization from '../../../../../../../../../api/mutations/grasps/useAddGraspToOrganization';
import useTransferGraspsToOrganization from '../../../../../../../../../api/mutations/grasps/useTransferGraspsToOrganization';
import { useOrganizationPathParams } from '../../../../../../../../../hooks/paths/useOrganizationPathParams';

export const useHandleTransferGrasp = (refetch: () => void) => {
  const { organizationId: targetOrganizationId } = useOrganizationPathParams();

  const { mutateAsync: transferAsync } =
    useTransferGraspsToOrganization(refetch);

  const { mutateAsync: addAsync } = useAddGraspToOrganization({
    params: { organizationId: targetOrganizationId },
    options: {
      successFb: refetch,
    },
  });

  return useCallback(
    async (sourceOrganizationId: string | undefined, graspId: string) => {
      if (sourceOrganizationId) {
        await transferAsync({
          sourceOrganizationId,
          targetOrganizationId,
          selection: { all_selected: false, selected: [graspId], excluded: [] },
        });
        return;
      }

      await addAsync(graspId);
    },
    [addAsync, targetOrganizationId, transferAsync]
  );
};
