import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Paper } from '@mui/material';

export enum CaseGroupCollectionStyle {
  Default = 'Default',
  PrimaryOutline = 'PrimaryOutline',
}

export type CaseGroupCollectionItemAction = {
  key: string;
  onClick?: () => void;
  icon: JSX.Element;
};

type CaseGroupCollectionItemProps = {
  icon: JSX.Element;
  title: string | JSX.Element;
  titleUrl?: string;
  info: string | JSX.Element;
  infoMonochrome?: boolean;
  actions: CaseGroupCollectionItemAction[];
  style?: CaseGroupCollectionStyle;
  onClick?: () => void;
};

const CaseGroupCollectionItem = ({
  info,
  titleUrl = undefined,
  onClick = undefined,
  infoMonochrome = true,
  style = CaseGroupCollectionStyle.Default,
  title,
  actions,
  icon,
}: CaseGroupCollectionItemProps) => {
  const renderActions = actions.map((action) => {
    const handleActionOnClick = () => {
      !!action.onClick && action.onClick();
    };

    return (
      <div
        key={action.key}
        className="CaseGroupCollectionItem__action"
        onClick={handleActionOnClick}
      >
        {action.icon}
      </div>
    );
  });

  const handleDivOnClick = () => {
    !!onClick && onClick();
  };

  return (
    <Paper
      onClick={handleDivOnClick}
      className={classNames({
        CaseGroupCollectionItem: true,
        'CaseGroupCollectionItem--primaryOutline':
          style === CaseGroupCollectionStyle.PrimaryOutline,
      })}
    >
      <div className="CaseGroupCollectionItem__icon">{icon}</div>
      <div className="CaseGroupCollectionItem__description">
        <span className="CaseGroupCollectionItem__title">
          {!!titleUrl ? <Link to={titleUrl}>{title}</Link> : <>{title}</>}
        </span>
        <span
          className={classNames({
            CaseGroupCollectionItem__info: true,
            'CaseGroupCollectionItem__info--monochrome': infoMonochrome,
          })}
        >
          {info}
        </span>
      </div>
      <div className="CaseGroupCollectionItem__actions">{renderActions}</div>
    </Paper>
  );
};

export default CaseGroupCollectionItem;
