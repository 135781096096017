import { useCommonData } from '../../../../../../../hooks/useCommonData';
import { useInitQuery } from '../../../../../../../../../hooks/queries/useInitQuery';
import { useOptions } from '../../../../../../../../../hooks/useOptions';
import { useMemo } from 'react';

export const useFormOptions = () => {
  const { caseCategories, educationMaterialCategories } = useCommonData();

  useInitQuery(caseCategories);
  useInitQuery(educationMaterialCategories);

  const {
    caseCategories: getCaseCategoriesOptions,
    materialCategories: getMaterialCategoriesOptions,
  } = useOptions();

  const caseCategoriesOptions = useMemo(() => {
    return getCaseCategoriesOptions(caseCategories);
  }, [caseCategories, getCaseCategoriesOptions]);

  const materialCategoriesOptions = useMemo(() => {
    return getMaterialCategoriesOptions(educationMaterialCategories);
  }, [educationMaterialCategories, getMaterialCategoriesOptions]);

  return { caseCategoriesOptions, materialCategoriesOptions };
};
