import React from 'react';
import { useInitQuery } from 'hooks/queries/useInitQuery';
import QuestionsItemPlaceholder from './components/QuestionsItemPlaceholder';
import { translateTemplateIds } from 'helpers/utils/conversationTemplates';
import { TemplateItemType } from 'interfaces/change';
import { useQueriesLoading } from 'hooks/queries/useQueriesLoading';
import { useTranslations } from 'hooks/useTranslations';
import FieldInput from 'components/FieldInput';
import { Field, FormikProps } from 'formik';
import { AddTooltipFormValues } from '../../../../hooks/useAddTooltipForm';
import { useQuestionTitle } from 'hooks/data/useQuestionTitle';
import { textWrapEllipse } from 'helpers/utils/strings';
import { useQuestionsIncludedInTrends } from './hooks/useQuestionsIncludedInTrends';
import { useCommonData } from 'modules/Organizations/views/OrganizationDetailsView/hooks/useCommonData';

type QuestionsItemProps = {
  form: FormikProps<AddTooltipFormValues>;
};

const QuestionsItem = ({ form }: QuestionsItemProps) => {
  const { groupedConversationQuestions, conversationTemplates } =
    useCommonData();

  useInitQuery(groupedConversationQuestions);
  useInitQuery(conversationTemplates);

  const questionsGroups = useQuestionsIncludedInTrends(
    groupedConversationQuestions.data ?? []
  );

  const getTitle = useQuestionTitle();

  const { attachPrefix, wrapWithIntl } = useTranslations(
    'Organizations.OrganizationDetails.TrendVisualizations.TrendVisualizationModal.FormHeading.FormHeadingActions.AddTooltip.AddTooltipForm'
  );

  const isLoading = useQueriesLoading([
    groupedConversationQuestions,
    conversationTemplates,
  ]);

  if (isLoading) return <QuestionsItemPlaceholder />;

  return (
    <div style={{ maxHeight: 150 }} className="overflow-scroll ms-2">
      {questionsGroups.map((group) => (
        <div className="mt-2" key={group.template_ids.join('|')}>
          <span className="text-black-50">
            {textWrapEllipse(
              translateTemplateIds(
                group.template_ids,
                conversationTemplates.data ?? [],
                wrapWithIntl(attachPrefix('notAssignedToAnyTemplate'))
              ),
              30
            )}
          </span>
          <div className="mt-2 ms-2">
            {group.questions.map((question) => (
              <Field
                key={question.resource_id}
                type="checkbox"
                name={`${TemplateItemType.Questions}.questions[${question.resource_id}].enabled`}
                checked={
                  form.values[TemplateItemType.Questions].questions[
                    question.resource_id
                  ].enabled
                }
                label={textWrapEllipse(getTitle(question), 25)}
                component={FieldInput}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default QuestionsItem;
