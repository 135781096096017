import { getOrganizationGraspsList } from 'api/graspManagement';
import QueryKeys from 'api/queries/queryKeys';
import {
  convertIntoObjectWithPageInfo,
  returnAllDataFromResponse,
} from 'helpers/utils/reactQueryHelpers';
import { GraspFilters, IInfiniteGraspOrganization } from 'interfaces/grasps';
import {
  OrganizationQueryParams,
  SearchAndPagingQueryParams,
} from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { IError, IInfiniteResult } from 'interfaces/response';
import { useInfiniteQuery } from 'react-query';

type UseInfiniteGraspListParams = OrganizationQueryParams &
  SearchAndPagingQueryParams & {
    filters?: GraspFilters;
  };

function useInfiniteGraspList({
  params,
  options,
}: IQueryRequest<UseInfiniteGraspListParams>) {
  const { organizationId, searchValue = '', pageSize = 20, filters } = params;
  const response = useInfiniteQuery<
    IInfiniteResult<IInfiniteGraspOrganization[]>,
    IError
  >({
    ...options,
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getOrganizationGraspsList(
        organizationId,
        {
          page_size: pageSize,
          page: pageParam,
          search: searchValue,
        },
        filters
      );

      return convertIntoObjectWithPageInfo<IInfiniteGraspOrganization[]>(data);
    },
    queryKey: [
      QueryKeys.getOrganizationGraspsList,
      organizationId,
      pageSize,
      searchValue,
      filters,
    ],
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage;
    },
    getPreviousPageParam: (firstPage) => firstPage.previousPage,
  });

  return {
    grasps: returnAllDataFromResponse<IInfiniteGraspOrganization>(response),
    totalItemsCount: response.data?.pages[0].totalItemsCount ?? 0,
    ...response,
  };
}

export default useInfiniteGraspList;
