import { faIdCardAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useRegisterUser from 'api/mutations/membership/useRegisterUser';
import useExternalAuthentication from 'api/queries/membership/useExternalAuthentication';
import ScrollableMembershipLayout from 'components/layouts/ScrollableMembershipLayout';
import { arePasswordsEqual } from 'helpers/utils/validators';
import useUrlSearch from 'hooks/useUrlSearch';
import { IRegisterForm } from 'interfaces/membership';
import Cookies from 'js-cookie';
import { displayErrorNotification } from 'modules/Notifications';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router';
import { Button, Col } from 'reactstrap';
import { discoveryService } from '../Login/components/LoginForm';

// COMPONENTS:
import RegisterForm from './components/RegisterForm';

const Register = () => {
  const navigate = useNavigate();

  const onSuccess = () => navigate('/login');

  const { mutate: registerUserMutation, isLoading } =
    useRegisterUser(onSuccess);

  const { data: authenticationSchemes } = useExternalAuthentication({});

  const [registerFormStep, setRegisterFormStep] = useState<boolean | null>(
    null
  );

  const query = useUrlSearch();
  const externalIdentityCode = query.get('external_identity');
  const externalProvider = query.get('external_provider');

  useEffect(() => {
    const externalIdentityRegistration = Cookies.get(
      'externalIdentityAuthenticationDuringRegister'
    );
    if (externalIdentityCode && externalProvider) {
      if (externalIdentityRegistration) {
        Cookies.remove('externalIdentityAuthenticationDuringRegister');
        setRegisterFormStep(true);
      } else {
        navigate('/register');
      }
    }
  }, [externalIdentityCode, externalProvider, navigate]);

  const handleSubmit = (values: IRegisterForm) => {
    if (arePasswordsEqual(values.password, values.passwordRepeat)) {
      values.phone_number =
        values.phone_number.length < 5 ? '' : values.phone_number.trim();
      registerUserMutation({ ...values, user_name: values.email });
    } else
      displayErrorNotification(
        'Register.notifications.errors.different_passwords'
      );
  };

  const handleRegisterFormStep = () => {
    setRegisterFormStep(true);
  };

  const formRef = useRef<HTMLFormElement | null>(null);
  const form = (
    <form
      style={{
        display: 'hidden',
      }}
      ref={formRef}
      method="post"
    ></form>
  );

  const onExternalLoginClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    name: string
  ) => {
    e.preventDefault();

    discoveryService(() => {
      const registerReturnUrl = `${window.location.origin}/register`;

      if (formRef && formRef.current) {
        Cookies.set('externalIdentityAuthenticationDuringRegister', true);
        formRef.current.action = `${localStorage.getItem(
          'api_url'
        )}/api/users/external_identity/${name}?returnUrl=${encodeURIComponent(
          registerReturnUrl
        )}`;
        formRef.current.submit();
      }
    });
  };

  const renderAuthenticationSchemes = authenticationSchemes
    ?.filter((p) => !p.is_b2_b)
    .map((scheme) => {
      const handleAuthenticationScheme = (
        e: React.MouseEvent<HTMLButtonElement>
      ) => {
        onExternalLoginClick(e, scheme.name);
      };

      return (
        <Button
          type="submit"
          className="btn-login btn-login--outline mb-3"
          key={scheme.display_name}
          onClick={handleAuthenticationScheme}
        >
          {scheme.display_name}
        </Button>
      );
    });

  return (
    <ScrollableMembershipLayout>
      {authenticationSchemes && (
        <>
          {registerFormStep ? (
            <div className="newMembersSection newMembersSection--smallPadding mb-4">
              <Col xs={12} sm={10} lg={9} xl={9} className="d-flex flex-column">
                <h2 className="newMembersSection__header">
                  <FormattedMessage id="Register.SignUp" />
                </h2>
                <RegisterForm
                  isLoading={isLoading}
                  handleSubmit={handleSubmit}
                  externalIdentity={externalIdentityCode ?? undefined}
                  externalProvider={externalProvider ?? undefined}
                />
              </Col>
            </div>
          ) : (
            <div className="newMembersSection pt-0 align-items-center">
              <div className="newMembersSection__register-options">
                <h2 className="newMembersSection__register-options__header">
                  <FormattedMessage id="Register.ChooseProvider" />
                </h2>
                <div className="newMembersSection__register-options__icon">
                  <FontAwesomeIcon icon={faIdCardAlt} />
                </div>
                <div className="newMembersSection__register-options__text">
                  <FormattedMessage id="Register.registerOptions.header" />
                </div>
                <div className="newMembersSection__register-options__container">
                  <Button
                    type="submit"
                    color="primary"
                    className="btn-login btn-login my-3"
                    onClick={handleRegisterFormStep}
                  >
                    <FormattedMessage id="General.register.internalRegister" />
                  </Button>
                  {renderAuthenticationSchemes}
                  {form}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </ScrollableMembershipLayout>
  );
};

export default Register;
