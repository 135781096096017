import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';

//types
import { AssessmentCompletionStatus } from 'interfaces/assessmentForms';

//helpers
import { returnIconIndicatorForCompletionStatus } from 'helpers/utils/icons';
import {
  returnFormBannerSubHeaderTranslationId,
  returnFormBannerTranslationId,
} from 'helpers/utils/timelineHelpers';

//components
import { setAssessmentFormIdentification } from 'modules/TimelineDetails/actions';

//images
import pencilFormIcon from 'assets/images/edit_icon.svg';
import arrow from 'assets/images/timeline_details/arrow.svg';

type SingleAssessmentFormBannerProps = {
  status: AssessmentCompletionStatus;
  hour: string;
  sequence: number;
  canEdit: boolean;
  contextType: string;
  contextObjectId: string;
  forceEdit?: boolean;
};

const SingleAssessmentFormBanner = ({
  status,
  hour,
  sequence,
  canEdit,
  contextObjectId,
  contextType,
  forceEdit = false,
}: SingleAssessmentFormBannerProps) => {
  const dispatch = useDispatch();

  const titleElement: JSX.Element[] = [];

  titleElement.push(
    <span
      key={`title-banner-${status}-${hour}-${sequence}`}
      className={`AssessmentFormBanner__content__title__header AssessmentFormBanner__content__title__header--${status.toLowerCase()}`}
    >
      <FormattedMessage id={returnFormBannerTranslationId(status, canEdit)} />
    </span>
  );

  if (
    status === AssessmentCompletionStatus.OVERDUE ||
    status === AssessmentCompletionStatus.OPEN
  ) {
    titleElement.push(
      <span
        key={`title-banner-status-${status}-${hour}-${sequence}`}
        className="AssessmentFormBanner__content__title__subheader"
      >
        <FormattedMessage
          id={returnFormBannerSubHeaderTranslationId(status, canEdit)}
        />
      </span>
    );
  }

  const dispatchOpenAssessment = () => {
    dispatch(
      setAssessmentFormIdentification({
        selectedSequence: sequence,
        contextObjectId: contextObjectId,
        contextType: contextType,
      })
    );
  };

  const canRenderEdit =
    (forceEdit && status !== AssessmentCompletionStatus.COMPLETED) ||
    (!forceEdit && status !== AssessmentCompletionStatus.UPCOMING && canEdit);

  const canRenderOpen =
    status === AssessmentCompletionStatus.COMPLETED && (forceEdit || !canEdit);

  return (
    <div
      className={`AssessmentFormBanner AssessmentFormBanner--${status.toLowerCase()}`}
    >
      <div className="AssessmentFormBanner__content">
        <div className={`AssessmentFormBanner__content__icon`}>
          <SVG
            src={returnIconIndicatorForCompletionStatus(status, false)}
            title={status.toLowerCase()}
          />
        </div>
        <div className="AssessmentFormBanner__content__title">
          {titleElement}
        </div>
        {canRenderEdit && (
          <div className="AssessmentFormBanner__content__complete">
            <button
              className="AssessmentFormBanner__content__complete__button"
              onClick={dispatchOpenAssessment}
            >
              <SVG src={pencilFormIcon} title="edit-icon" />
            </button>
          </div>
        )}
        {canRenderOpen && (
          <div className="AssessmentFormBanner__content__complete">
            <button
              className="AssessmentFormBanner__content__complete__button"
              onClick={dispatchOpenAssessment}
            >
              <SVG src={arrow} title="open-icon" />
            </button>
          </div>
        )}
      </div>
      <div className="AssessmentFormBanner__hour">{hour}</div>
    </div>
  );
};

export default SingleAssessmentFormBanner;
