import {
  arrayToQueryParamsArrayItems,
  normalizeQueryParams,
  normalizeQueryParamsArray,
} from 'helpers/utils/normalizeQueryParams';
import { IOrganizationDetails, LocationItem } from 'interfaces/membership';
import { IPersonality } from 'interfaces/personalities';
import { IParamsTypeInRequest } from 'interfaces/reducer';
import { IResponse, IResponseWithPagination } from 'interfaces/response';
import {
  ICreateOrganizationDevice,
  ICreateOrganizationUser,
  IOrganizationDevice,
  IOrganizationMembers,
  OrganizationUploadTarget,
} from '../interfaces/organizations';
import { IPatchObject } from '../interfaces/request';
import request from './utils/request';

export const getOrganizationWithPaging = (
  queryParams: Partial<IParamsTypeInRequest>
) =>
  request<void, IResponseWithPagination<IOrganizationDetails[]>>({
    url: `/organizations${normalizeQueryParams(queryParams)}`,
    method: 'GET',
  });

export const getOrganizationLocationsWithPaging = (
  organizationId: string,
  queryParams: Partial<IParamsTypeInRequest>
) =>
  request<void, IResponseWithPagination<LocationItem[]>>({
    url: `/organizations/${organizationId}/locations${normalizeQueryParams(
      queryParams
    )}`,
    method: 'GET',
  });

export const getOrganizationMembers = async (organizationId: string) => {
  return request<undefined, IResponse<IPersonality[]>>({
    url: `/organizations/${organizationId}/members`,
    method: 'GET',
  });
};

export const getOrganizationMembersWithPaging = async (
  organizationId: string,
  reduxParams: Partial<IParamsTypeInRequest>
) => {
  return request<undefined, IResponseWithPagination<IPersonality[]>>({
    url: `/organizations/${organizationId}/members${normalizeQueryParams(
      reduxParams
    )}`,
    method: 'GET',
  });
};

export const getMembersInOrganizations = async (
  invokedOrganizationId: string,
  desiredOrganizationIds: string[]
) => {
  return request<undefined, IResponse<IOrganizationMembers[]>>({
    url: `/organizations/${invokedOrganizationId}/members/organizations${normalizeQueryParamsArray(
      [...arrayToQueryParamsArrayItems(desiredOrganizationIds, 'organizations')]
    )}`,
    method: 'GET',
  });
};

export const createOrganizationUser = (
  organizationId: string,
  body: ICreateOrganizationUser
) =>
  request<ICreateOrganizationUser, void>({
    url: `/organizations/${organizationId}/users`,
    data: body,
    method: 'POST',
  });

export const createOrganizationDevice = (
  organizationId: string,
  body: ICreateOrganizationDevice
) =>
  request<ICreateOrganizationDevice, void>({
    url: `/organizations/${organizationId}/devices`,
    data: body,
    method: 'POST',
  });

export const uploadOrganizationImage = (
  organizationId: string,
  target: OrganizationUploadTarget,
  file: File
) => {
  const formData = new FormData();
  formData.append('uploadFile', file);

  return request<FormData, void>({
    url: `/organizations/${organizationId}/upload/${target}`,
    method: 'PATCH',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' },
  });
};

export const getOrganizationDevices = async (organizationId: string) => {
  return request<undefined, IResponse<IOrganizationDevice[]>>({
    url: `/organizations/${organizationId}/devices`,
    method: 'GET',
  });
};

export const editOrganizationDevice = (
  organizationId: string,
  deviceId: string,
  patchData: IPatchObject[]
) =>
  request({
    url: `/organizations/${organizationId}/devices/${deviceId}`,
    method: 'PATCH',
    data: patchData,
  });

export const patchOrganizationUser = (
  organizationId: string,
  userId: string,
  patchData: IPatchObject[]
) =>
  request({
    url: `/organizations/${organizationId}/users/${userId}`,
    method: 'PATCH',
    data: patchData,
  });
