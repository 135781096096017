import { Box, Tabs } from '@mui/material';
import { useTabContainer } from 'components/Tabs/hooks/useTabContainer';
import { useTabHeaders } from 'components/Tabs/hooks/useTabHeaders';
import { useTabPanels } from 'components/Tabs/hooks/useTabPanels';
import { ITabContainerItem } from 'components/Tabs/types/Tabs';
import React from 'react';

type HorizontalTabContainerProps = {
  items: ITabContainerItem[];
  onTabChange?: (index: number) => void;
  controlled?: boolean;
  children?: (toggle: (tab: number) => void) => JSX.Element;
};

const HorizontalTabContainer = ({
  onTabChange,
  items,
  controlled,
  children,
}: HorizontalTabContainerProps) => {
  const { activeTab, toggle, defaultSelectedItems } =
    useTabContainer<ITabContainerItem>({
      items,
      onTabChange,
    });

  const tabHeaders = useTabHeaders(defaultSelectedItems);
  const tabPanels = useTabPanels(defaultSelectedItems);

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    if (controlled) return;
    toggle(newValue);
    onTabChange?.(newValue);
  };

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Tabs
        orientation="horizontal"
        variant="fullWidth"
        value={activeTab}
        onChange={handleChange}
      >
        {tabHeaders}
      </Tabs>
      {tabPanels}
      {children?.(toggle)}
    </Box>
  );
};

export default HorizontalTabContainer;
