import { ScheduleItemDefinition } from 'components/Schedule';
import { filterSchedulesForDate } from 'helpers/utils/conversationSchedules';
import { useInitQuery } from 'hooks/queries/useInitQuery';
import { ICaseConversationSchedule } from 'interfaces/conversationSchedules';
import { cloneDeep } from 'lodash';
import React, { useCallback, useMemo } from 'react';
import { useTypedContext } from '../../../../../../../../../../../../../../../hooks/useTypedContext';
import { useCommonData } from '../../../../../hooks/useCommonData';
import { CasePhaseHistoryContext } from '../../../../../types';
import { usePrepareSchedules } from './usePrepareSchedules';

export const useItems = (
  date: Date,
  setSchedule: React.Dispatch<
    React.SetStateAction<ICaseConversationSchedule | null>
  >,
  toggle: () => void
) => {
  const { caseConversationSchedules, conversationTemplates } = useCommonData();

  const casePhaseHistory = useTypedContext(CasePhaseHistoryContext);
  useInitQuery(caseConversationSchedules);

  const { prepareBasicSchedule, prepareSingleInstanceSchedule } =
    usePrepareSchedules(setSchedule, toggle);

  const dateFilter = useCallback(
    (scheduleProvided: ICaseConversationSchedule) => {
      return filterSchedulesForDate(scheduleProvided, date);
    },
    [date]
  );

  const scheduleItems = useMemo(() => {
    if (!caseConversationSchedules.data) return [];

    let _items: ScheduleItemDefinition[] = [];

    let caseSchedulesData = cloneDeep(caseConversationSchedules.data);

    caseSchedulesData = caseSchedulesData.filter(dateFilter);

    caseSchedulesData.forEach((schedule, index) => {
      _items = prepareBasicSchedule(_items, schedule, date, index);
      _items = prepareSingleInstanceSchedule(
        _items,
        schedule,
        caseConversationSchedules.data,
        casePhaseHistory,
        date,
        index
      );
    });

    _items.sort((a, b) => (b.priority ?? 0) - (a.priority ?? 0));
    return _items;
  }, [
    caseConversationSchedules.data,
    casePhaseHistory,
    date,
    dateFilter,
    prepareBasicSchedule,
    prepareSingleInstanceSchedule,
  ]);

  return {
    items: scheduleItems,
    isLoading:
      caseConversationSchedules.isLoading || conversationTemplates.isLoading,
  };
};
