import React, { useMemo } from 'react';
import { IProject } from '../../../../../../interfaces/submissions';
import {
  Button,
  PopoverBody,
  UncontrolledPopover,
  UncontrolledTooltip,
} from 'reactstrap';
import projectsFlow from 'assets/images/projects/project-flow.svg';
import pencilFormIcon from 'assets/images/edit_icon.svg';
import SVG from 'react-inlinesvg';
import { FormattedMessage, useIntl } from 'react-intl';
import { formatDate } from '../../../../../../helpers/dates';
import useOpen from '../../../../../../hooks/useOpen';
import useCaseCategories from '../../../../../../api/queries/cases/useCaseCategories';
import { textWrapEllipse } from '../../../../../../helpers/utils/strings';
import { useNavigate } from 'react-router';
import ProjectModal from '../../../../modals/ProjectModal';
import { translateHtmlStr } from '../../../../../../helpers/utils/cultures';
import { AVAILABLE_CULTURES } from '../../../../../../components/CultureSelector/CultureSelector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faLink } from '@fortawesome/pro-light-svg-icons';
import EditableInput from '../../../../../../components/EditableInput/EditableInput';

type ProjectListItemProps = {
  project: IProject;
  organizationId: string;
  projectsRefetch: () => void;
};

const ProjectListItem = ({
  project,
  organizationId,
  projectsRefetch,
}: ProjectListItemProps) => {
  const intl = useIntl();

  const navigate = useNavigate();

  const origin = window.location?.origin;

  let registerLink = `/register/${project.organization.code}/${project.code}`;
  registerLink = origin ? `${origin}${registerLink}` : registerLink;

  const { data: caseCategories } = useCaseCategories({
    params: { organizationId },
  });

  const planNavigation = () => {
    navigate(
      `/organizations/${organizationId}/treatment-plans/${project.case_category_id}`
    );
  };

  const handleEditLink = () => {
    return;
  };

  const { isOpen, toggle } = useOpen(false);

  const projectsCaseCategory = useMemo(() => {
    if (!caseCategories) return '';

    return (
      caseCategories.find((p) => p.id === project.case_category_id)?.name ?? ''
    );
  }, [caseCategories, project.case_category_id]);

  const projectsCaseSubCategory = useMemo(() => {
    if (!caseCategories) return '';

    const existingCategory = caseCategories.find(
      (p) => p.id === project.case_category_id
    );

    if (!existingCategory) return '';

    const subCategory = existingCategory.sub_categories.find(
      (p) => p.id === project.case_sub_category_id
    );

    if (!subCategory) return '';

    return subCategory.name;
  }, [caseCategories, project.case_category_id, project.case_sub_category_id]);

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(registerLink);
  };

  return (
    <>
      {isOpen && (
        <ProjectModal
          project={project}
          isOpen={isOpen}
          toggle={toggle}
          projectRefetch={projectsRefetch}
        />
      )}
      <tr className="ProjectsListItem" key={project.code}>
        <td>{project.code}</td>
        <td>{translateHtmlStr(project.title, AVAILABLE_CULTURES.EN, false)}</td>
        <td>{textWrapEllipse(project.description, 15)}</td>
        <td>
          {project.short_description
            ? textWrapEllipse(project.short_description, 15)
            : ''}
        </td>
        <td>
          <ul>
            {project.terms_of_service && project.terms_of_service.length > 0 && (
              <li>
                <FormattedMessage id="Projects.list.termsOfService" />
                <span className="btn-link">
                  {textWrapEllipse(project.terms_of_service, 20)}
                </span>
              </li>
            )}
            {project.privacy_policy && project.privacy_policy.length > 0 && (
              <li>
                <FormattedMessage id="Projects.list.privacyPolicy" />
                <span className="btn-link">
                  {textWrapEllipse(project.privacy_policy, 20)}
                </span>
              </li>
            )}
            {project.participation_consent &&
              project.participation_consent.length > 0 && (
                <li>
                  <FormattedMessage id="Projects.list.participationConsent" />
                  <span className="btn-link">
                    {textWrapEllipse(project.participation_consent, 20)}
                  </span>
                </li>
              )}
          </ul>
        </td>
        <td>
          <ul>
            <li>
              <FormattedMessage id="Projects.list.place" />
              <span>
                {project.place && project.place.length > 0
                  ? project.place
                  : intl.formatMessage({
                      id: 'Projects.list.notDefined',
                    })}
              </span>
            </li>
            <li>
              <FormattedMessage id="Projects.list.forWho" />
              <span>
                {project.for_who && project.for_who.length > 0
                  ? project.for_who
                  : intl.formatMessage({
                      id: 'Projects.list.notDefined',
                    })}
              </span>
            </li>
          </ul>
        </td>
        <td>{projectsCaseCategory}</td>
        <td>{projectsCaseSubCategory}</td>
        <td>
          {project.date_from
            ? formatDate(project.date_from.toString(), { ignoreTime: true })
            : intl.formatMessage({
                id: 'Projects.list.notDefined',
              })}
        </td>
        <td>
          {project.date_to
            ? formatDate(project.date_to.toString(), { ignoreTime: true })
            : intl.formatMessage({
                id: 'Projects.list.notDefined',
              })}
        </td>
        <td className="d-flex gap-2">
          <div>
            <Button
              id={`project-list-item-link-popover-${project.id}`}
              color="primary"
              className="ProjectsListItem__flow-btn"
              outline={true}
            >
              <FontAwesomeIcon
                icon={faLink}
                title={intl.formatMessage({
                  id: 'Projects.list.projectFlow',
                })}
              />
            </Button>
            <UncontrolledPopover
              placement="top"
              target={`project-list-item-link-popover-${project.id}`}
              trigger="click"
            >
              <PopoverBody className="d-flex align-items-center gap-2">
                <EditableInput
                  type="text"
                  value={registerLink}
                  onEdit={handleEditLink}
                  name={`project-list-item-input-popover-${project.id}`}
                  debounceEnabled={false}
                />
                <UncontrolledTooltip
                  placement="top"
                  target={`project-list-item-input-tooltip-${project.id}`}
                  trigger="click"
                  autohide={true}
                >
                  <FormattedMessage id="Projects.ProjectsList.ProjectListItem.copiedToClipboard" />
                </UncontrolledTooltip>
                <Button
                  id={`project-list-item-input-tooltip-${project.id}`}
                  color="primary"
                  outline={true}
                  onClick={copyToClipboard}
                >
                  <FontAwesomeIcon icon={faCopy} />
                </Button>
              </PopoverBody>
            </UncontrolledPopover>
          </div>
          <Button
            color="primary"
            className="ProjectsListItem__flow-btn"
            outline={true}
            onClick={planNavigation}
          >
            <SVG
              src={projectsFlow}
              title={intl.formatMessage({
                id: 'Projects.list.projectFlow',
              })}
            />
          </Button>
          <Button
            color="primary"
            className="ProjectsListItem__flow-btn"
            outline={true}
            onClick={toggle}
          >
            <SVG
              src={pencilFormIcon}
              title={intl.formatMessage({
                id: 'Projects.list.projectFlow',
              })}
            />
          </Button>
        </td>
      </tr>
    </>
  );
};

export default ProjectListItem;
