import React from 'react';
import { DashboardTemplate } from '../../../../../interfaces/dashboards';
import NotFound from '../NotFound';
import { Navigate } from 'react-router';
import {
  generateRoute,
  RouteDefinitions,
} from '../../../../../helpers/routeDefinitions';
import { useOrganizationPathParams } from '../../../../../hooks/paths/useOrganizationPathParams';
import { minBy } from 'lodash';

type RedirectProps = {
  templates: DashboardTemplate[];
};

const Redirect = ({ templates }: RedirectProps) => {
  const { organizationId } = useOrganizationPathParams();
  const firstDashboard = minBy(templates, (p) => p.sequence);

  if (!firstDashboard) return <NotFound />;

  const templateRoute = generateRoute(firstDashboard.path as RouteDefinitions, {
    organizationId: organizationId,
    dashboardId: firstDashboard.id,
  });

  return <Navigate to={templateRoute} state={{ template: firstDashboard }} />;
};

export default Redirect;
