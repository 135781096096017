import { useCallback } from 'react';
import useCultures from '../../../../../../../../../../../../../hooks/cultures/useCultures';
import { useTranslations } from '../../../../../../../../../../../../../hooks/useTranslations';
import { useTypedContext } from '../../../../../../../../../../../../../hooks/useTypedContext';
import {
  ClosedListValidConversationQuestion,
  ValidConversationQuestionType,
} from '../../../../../../../../../../../../../interfaces/conversations';
import {
  DashboardFilter,
  DashboardFilterType,
  DashboardReportPeriod,
} from '../../../../../../../../../../../../../interfaces/dashboards';
import { DashboardDefaults } from '../../../../../../../defaults';
import { DashboardFormatHelpers } from '../../../../../../../helpers/format';
import { DashboardQueriesContext } from '../../../../../../../types';

export const useFilterItemDescription = () => {
  const { currentCulture } = useCultures();

  const { questions, members, grasps, organizations } = useTypedContext(
    DashboardQueriesContext
  );

  const { wrapWithIntl, attachPrefix } = useTranslations(
    DashboardDefaults.NOT_SPECIFIED_TRANSLATION_PATH
  );

  const getFilterItemDescription = useCallback(
    (filter: DashboardFilter, id: string) => {
      const notSpecifiedTranslation = wrapWithIntl(
        attachPrefix(DashboardDefaults.NOT_SPECIFIED_TRANSLATION_PREFIX)
      );

      if (filter.type === DashboardFilterType.ClosedList) {
        const conversationQuestion = questions.find(
          (p) =>
            p.resource_id === filter.id &&
            p.content.type === ValidConversationQuestionType.ClosedList
        );

        if (
          filter.options.find((p) => p.id === id)?.id ===
          notSpecifiedTranslation
        )
          return notSpecifiedTranslation;

        return DashboardFormatHelpers.FormatQuestionAnswer(
          conversationQuestion?.content as
            | ClosedListValidConversationQuestion
            | undefined,
          id,
          currentCulture.culture
        );
      }

      if (filter.type === DashboardFilterType.Member) {
        const member = members.find((p) => p.id === id);

        if (
          filter.options.find((p) => p.id === id)?.id ===
          notSpecifiedTranslation
        )
          return notSpecifiedTranslation;

        return DashboardFormatHelpers.FormatOrganizationMember(member);
      }

      if (filter.type === DashboardFilterType.Grasp) {
        const grasp = grasps.find((p) => p.id === id);

        if (
          filter.options.find((p) => p.id === id)?.id ===
          notSpecifiedTranslation
        )
          return notSpecifiedTranslation;

        return DashboardFormatHelpers.FormatGrasp(grasp);
      }

      if (filter.type === DashboardFilterType.Organization) {
        const organization = organizations?.find((p) => p.id === id);

        if (
          filter.options.find((p) => p.id === id)?.id ===
          notSpecifiedTranslation
        )
          return notSpecifiedTranslation;

        return DashboardFormatHelpers.FormatOrganization(organization);
      }

      if (filter.type === DashboardFilterType.SourcePeriod) {
        const reportPeriod = Object.entries(DashboardReportPeriod).find(
          ([key]) => key === id
        );

        if (!reportPeriod) return '';
        const [, val] = reportPeriod;

        return DashboardFormatHelpers.FormatSourcePeriod(val);
      }

      return '';
    },
    [
      attachPrefix,
      currentCulture.culture,
      grasps,
      members,
      organizations,
      questions,
      wrapWithIntl,
    ]
  );

  return { getFilterItemDescription };
};
