import { ConversationTemplate } from '../../../../../../../../../interfaces/conversations';
import { useCallback } from 'react';

export const useHandleTemplateExport = (template: ConversationTemplate) => {
  return useCallback(() => {
    const element = document.createElement('a');
    const file = new Blob([JSON.stringify(template)], {
      type: 'application/json',
    });
    element.href = URL.createObjectURL(file);
    element.download = `template-${template.id}.json`;
    document.body.appendChild(element);
    element.click();
  }, [template]);
};
