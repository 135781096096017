import React, { useEffect } from 'react';
import ReactQuill from 'react-quill';

export const usePlaceholder = (
  ref: React.RefObject<ReactQuill>,
  placeholder: string
) => {
  useEffect(() => {
    if (!ref.current) return;
    ref.current.getEditor().root.dataset.placeholder = placeholder ?? '';
  }, [placeholder, ref]);
};
