import { FormikProps } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { ITabContainerItem } from '../../../../../../../../../components/Tabs/types/Tabs';
import { CultureHelpers } from '../../../../../../../../../helpers/cultures';
import { IHelpCategory } from '../../../../../../../../../interfaces/help';
import { HelpCentreFormInitialValues } from '../../../forms/HelpCentreForm/hooks/useForm';
import HelpCategoryTab from '../components/HelpCategoryTab';

export const useTabs = (
  categories: IHelpCategory[],
  form: FormikProps<HelpCentreFormInitialValues>
) => {
  const [selectedTab, setSelectedTab] = useState<string>(
    categories.find((p) => p.code === form.initialValues.category)?.code ??
      categories.at(0)?.code ??
      ''
  );

  const shouldBeRendered = useCallback(
    (tab: string) => {
      return selectedTab === tab;
    },
    [selectedTab]
  );

  const tabItems: ITabContainerItem[] = useMemo(() => {
    return categories.map((category) => ({
      header: CultureHelpers.getValueAccordingToCulture(
        category.name_translations,
        form.initialValues.culture
      ),
      component: <HelpCategoryTab category={category} form={form} />,
      shouldBeRendered: shouldBeRendered(category.code),
    }));
  }, [categories, form, shouldBeRendered]);

  const onTabChange = useCallback(
    (val: number) => {
      const selectedCategory = categories.at(val);
      if (!selectedCategory) throw Error('Help category out of range');
      setSelectedTab(selectedCategory.code);
      form.setValues((prev) => ({
        ...prev,
        category: selectedCategory.code,
        topic: '',
      }));
    },
    [categories, form]
  );

  return {
    onTabChange,
    tabItems,
  };
};
