export const useTranslations = () => {
  const localizationPrefix =
    'Education.EducationMaterialCategoriesTab.AddEducationMaterialItemModal.';

  const attachPrefix = (id: string) => `${localizationPrefix}${id}`;

  const addItem = attachPrefix('addItem');
  const addChildCategory = attachPrefix('addChildCategory');
  const addEducationMaterial = attachPrefix('addEducationMaterial');

  return {
    addItem,
    addChildCategory,
    addEducationMaterial,
  };
};
