import {
  AnswerHistoryQuestion,
  TemplateElement,
  TemplateItemType,
} from '../../../interfaces/change';
import { createContext } from 'react';
import { ChartDownloadHookValues } from '../hooks/useTrendsChartDownload';
import { TrendsChartVisualizationHookValues } from '../hooks/useTrendsChartVisualization';
import { UseQueryResult } from 'react-query';
import { IBaseExerciseSession } from '../../../interfaces/exerciseSessions';
import { IObservation, ISqueezeChartData } from '../../../interfaces/timeline';
import { AVAILABLE_CULTURES } from '../../CultureSelector/CultureSelector';

export type ChartsData<T> = Record<string, T>;

export type TrendsChartData<TData> = {
  type: TemplateItemType;
  data: ChartsData<TData>[];
};

export type TrendsChartVisualization = {
  elements: TrendsChartElement[];
};

export type TrendsChartElement =
  | NonQuestionBasedTrendsChartElement
  | QuestionBasedTrendsChartElement;

export type NonQuestionBasedTrendsChartElement = Omit<
  TemplateElement,
  'question_id'
>;

export type QuestionBasedTrendsChartElement = Omit<
  NonQuestionBasedTrendsChartElement,
  'type' | 'generate_points_nearby'
> & {
  type: TemplateItemType.Questions;
  questionIds: string[];
  generate_points_nearby: { question_id: string; enabled: boolean }[];
};

export type TrendsChartFormatter = {
  source: 'x-axis' | 'y-axis' | 'name';
  callback: (value: any, index: number) => string;
};

export type TrendsChartConfig = {
  questions: TrendsChartConfigItem[];
  observations: TrendsChartConfigItem[];
  actions?: TrendsChartConfigAction[];
  switch?: TrendsChartConfigSwitch;
  header?: TrendsChartConfigHeader;
  legend?: TrendsChartConfigLegend;
  axis?: TrendsChartAxisConfigItem[];
};

export type TrendsChartConfigSwitch = {
  onChange?: (type: TemplateItemType) => void;
};

export type TrendsChartConfigLegend = {
  onItemRemove?: (value: string) => void;
};

export type TrendsChartAxisConfigItem = {
  axis: 'x-axis' | 'y-axis';
  interval?: number | 'preserveEnd' | 'preserveStart' | 'preserveStartEnd';
  onTick?: (payload: string) => void;
  initialValue?: string;
};

export type TrendsChartConfigHeader = {
  title?: string;
  titleTranslation?: string;
  titleEnabled?: boolean;
  subtitle?: string;
  subtitleEnabled?: boolean;
  onEdit?: (title: string, culture: AVAILABLE_CULTURES) => void;
  onAccept?: (title: string, culture: AVAILABLE_CULTURES) => void;
};

export type TrendsChartConfigAction =
  | TrendsTemplateConfigAction
  | DownloadConfigAction;

export type TrendsTemplateConfigAction = {
  type: 'move-up' | 'move-down' | 'delete';
  callback: () => void;
};

export type DownloadConfigAction = {
  type: 'download';
};

export type TrendsChartConfigItem = {
  id: string;
  title: string;
  color: string;
};

export type TrendsChartGenericDataQueries = {
  exercises: UseQueryResult<IBaseExerciseSession[]>;
  observations: UseQueryResult<ISqueezeChartData>;
  observationInTimelines: UseQueryResult<IObservation[]>;
  questionAnswerHistory: UseQueryResult<{
    questions: AnswerHistoryQuestion[];
    userId: string;
  }>;
};

export const ChartDownloadContext = createContext<
  ChartDownloadHookValues | undefined
>(undefined);

export const ChartVisualizationContext = createContext<
  TrendsChartVisualizationHookValues | undefined
>(undefined);

export const ChartDataContext = createContext<
  TrendsChartData<string | number> | undefined
>(undefined);

export const ChartConfigContext = createContext<TrendsChartConfig | undefined>(
  undefined
);
