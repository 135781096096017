import { ClosedListValidConversationQuestionAnswer } from '../../../../../../../../../../../../../interfaces/conversations';
import { v4 as uuid } from 'uuid';

export const getEmptyClosedListAnswer = (
  sequence: number
): ClosedListValidConversationQuestionAnswer => {
  return {
    is_selected: false,
    title: '',
    guid: uuid(),
    sequence,
  };
};
