import commentIcon from 'assets/images/case_details/comment.svg';

//icons
import sharingIcon from 'assets/images/timeline_details/sharing.svg';
import { ICaseHistory } from 'interfaces/caseHistory';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ListItemLayout from './ListItemLayout';

const CaseActivityListItem = ({
  text,
  new_category,
  new_status,
  new_sub_category,
  time_start,
  time_end,
}: ICaseHistory) => {
  let textContent: JSX.Element | string | null;
  let imageSrc: string | null;

  if (!new_status && !new_category && !new_sub_category) {
    textContent = text;
    imageSrc = commentIcon;
  } else {
    imageSrc = sharingIcon;
    textContent = (
      <>
        {new_status && (
          <>
            <FormattedMessage id="CaseCard.History.statusWasChangedTo" />{' '}
            <b>{new_status}</b>
          </>
        )}
        {new_category && (
          <>
            {new_status && ', '}
            <FormattedMessage id="CaseCard.History.categoryWasChangedTo" />{' '}
            <b>{new_category.name}</b>
          </>
        )}
        {new_sub_category && (
          <>
            {(new_status || new_category) && ', '}
            <FormattedMessage id="CaseCard.History.subCategoryChangedTo" />{' '}
            <b>{new_sub_category.name}</b>
          </>
        )}{' '}
        <FormattedMessage id="CaseCard.History.withComment" /> <b>{text}</b>
      </>
    );
  }

  return (
    <ListItemLayout
      textContent={textContent}
      imageSrc={imageSrc}
      timeEnd={time_end}
      timeStart={time_start}
    />
  );
};

export default CaseActivityListItem;
