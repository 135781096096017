import { getOrganizationPatientDetails } from 'api/patients';
import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import {
  OrganizationQueryParams,
  PersonalityQueryParams,
} from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

type UseOrganizationPatientDetailsParams = OrganizationQueryParams &
  PersonalityQueryParams;

function useOrganizationPatientDetails({
  params,
  options,
}: IQueryRequest<UseOrganizationPatientDetailsParams>) {
  const { organizationId, personalityId } = params;
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getOrganizationPatientDetails(
        organizationId,
        personalityId
      );
      return data.data;
    },
    queryKey: [
      QueryKeys.getOrganizationPatientDetails,
      organizationId,
      personalityId,
    ],
  });
}

export default useOrganizationPatientDetails;
