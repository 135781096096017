import assessment_form_bad from 'assets/images/timeline_details/assessment_form_bad.svg';
import assessment_form_filled from 'assets/images/timeline_details/assessment_form_filled.svg';
import assessment_form_good from 'assets/images/timeline_details/assessment_form_good.svg';
import assessment_form_pending from 'assets/images/timeline_details/assessment_form_pending.svg';

//icons
import annotationIcon from 'assets/images/timeline_details/chart_annotation.svg';
import historyIcon from 'assets/images/timeline_details/chart_history.svg';
import SkeletonPlaceholderOnLoad from 'components/SkeletonPlaceholderOnLoad';
import * as d3 from 'd3';
import { add, eachDayOfInterval, isSameDay, sub } from 'date-fns';
import { returnColorBaseOnMeaning } from 'helpers/colors';
import {
  DAY_IN_MILLISECONDS,
  HOUR_IN_MILLISECONDS,
  MAXIMUM_PAIN_LEVEL,
  MINIMUM_PAIN_LEVEL,
} from 'helpers/constants';
import {
  formatDate,
  isSameDate,
  returnFormattedHourSelection,
} from 'helpers/dates';
import {
  returnFormBannerSubHeaderTranslationId,
  returnFormBannerTranslationId,
} from 'helpers/utils/timelineHelpers';

//helpers
import { observationSymptomOptions } from 'helpers/utils/translationObject';

//helpers
import useContainer from 'hooks/useContainer';
import useOpen from 'hooks/useOpen';
import { AssessmentCompletionStatus } from 'interfaces/assessmentForms';

//interfaces
import {
  AmplitudeModalData,
  BubbleType,
  FormBubbleData,
  instanceOfExtendedBubbleData,
  instanceOfFormBubbleData,
  instanceOfObservationBubbleData,
  Meaning,
  ObservationSymptom,
  SingleDataBubbleValue,
} from 'interfaces/timeline';

//redux
import {
  changeSelectedDate,
  setAssessmentFormIdentification,
} from 'modules/TimelineDetails/actions';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import Skeleton from 'react-loading-skeleton';
import { useDispatch } from 'react-redux';
import {
  useBrowserState,
  useTimelineDetailsDayByDayFullSize,
  useTimelineDetailsSelectedDate,
} from 'store/reducerHooks';
import ChartHeader from '../DayByDayHeader';
import BubbleChartLegend from './BubbleChartLegend';
import SummaryYAxis from './SummaryYAxis';
import XAxis from './XAxis';

//components
import YAxis from './YAxis';

//ANNOTATIONS_AND_HISTORY
const IMAGE_SIZE = 32;

//OBSERVATIONS
const MIN_OBSERVATION_RADIUS = 10;
const MAX_OBSERVATION_RADIUS = 30;

//SELECTED OBSERVATION
const MIN_SELECTED_OBSERVATION_RADIUS = 10;
const MAX_SELECTED_OBSERVATION_RADIUS = 22;
const HEIGHT_MOD = MAX_SELECTED_OBSERVATION_RADIUS + 5;

//ZOOM
const BASE_ZOOM_VALUE = 1;
const MAX_ZOOM_VALUE = 1000;

//CHART_OPTIONS
const ANIMATION_DURATION = 200;
const WIDTH_OF_Y_AXIS = 60;
const WIDTH_OF_SUMMARY = 60;
const SUM_OF_RESERVED_WIDTH = WIDTH_OF_Y_AXIS + WIDTH_OF_SUMMARY;

const HEIGHT_OF_LINE = 100;
const TOOLTIP_IDLE_POSITION = '2000px';
const Z_DOMAIN = {
  min: MINIMUM_PAIN_LEVEL,
  max: MAXIMUM_PAIN_LEVEL,
};
const iconRadius = 16;

const returnOpacityBaseOnSqueeze = (
  squeeze: 'Hard' | 'Medium' | 'Low' | undefined
) => {
  switch (squeeze) {
    case 'Hard':
      return '0.9';
    case 'Medium':
      return '0.5';
    case 'Low':
      return '0.3';
    default:
      return 1;
  }
};

const showTooltip = (
  event: any,
  d: SingleDataBubbleValue,
  tooltipDiv: d3.Selection<HTMLDivElement, any, any, any>,
  containerY: number,
  intl: IntlShape
) => {
  tooltipDiv.transition().duration(ANIMATION_DURATION);
  let headerText = formatDate(d.date.toISOString());

  if (
    instanceOfExtendedBubbleData(d) &&
    d.endDate &&
    !isSameDate(d.date, d.endDate) &&
    d.endDate.getFullYear() !== 1
  )
    headerText += `<br/>${formatDate(d.endDate.toISOString())}`;

  let bodyText = '';
  if (d.type === BubbleType.ANNOTATION && instanceOfExtendedBubbleData(d)) {
    headerText = formatDate(d.date.toISOString(), {
      addSeconds: true,
    });

    bodyText = `Annotation text: ${d.text || ''}`;
  } else if (
    d.type === BubbleType.HISTORY_ENTRY &&
    instanceOfExtendedBubbleData(d)
  ) {
    headerText = formatDate(d.date.toISOString(), {
      addSeconds: true,
    });

    bodyText = `Case text: ${d.text || ''}`;
  } else if (d.type === BubbleType.FORM && instanceOfFormBubbleData(d)) {
    bodyText = `<div>${d.title}</div>`;
    const statusText = intl.formatMessage({
      id: returnFormBannerTranslationId(d.completionStatus, d.canEdit),
    });

    let additionalContent = '';
    if (
      d.completionStatus === AssessmentCompletionStatus.OVERDUE ||
      d.completionStatus === AssessmentCompletionStatus.OPEN
    ) {
      additionalContent = intl.formatMessage({
        id: returnFormBannerSubHeaderTranslationId(
          d.completionStatus,
          d.canEdit
        ),
      });
    }

    if (d.text) bodyText += `<div>${d.text}</div>`;

    bodyText += `<br/><div>${statusText}</div><div>${additionalContent}</div>`;
  } else {
    if (!instanceOfObservationBubbleData(d)) return;

    headerText = formatDate(d.date.toISOString(), {
      addSeconds: true,
    });

    bodyText = `Squeeze duration: ${d.z} sec<br />Pain scale level: ${
      d.painScaleLevel || 0
    }`;

    if (d.text) bodyText += `<br />Observation text: ${d.text}`;
    if (d.symptom) {
      const translationObject = observationSymptomOptions.find(
        (option) => option.value === d.symptom
      );
      bodyText += `<br />Meaning: ${
        translationObject
          ? intl.formatMessage({ id: translationObject.translationId })
          : d.symptom
      }`;
    }
  }

  const textContent = `<div style='font-size: 14px;color: black;font-weight: 500;margin-bottom: 16px'>${headerText}</div>
  <div style='font-size: 14px;color: #747474;font-weight: 400;'>${bodyText}</div`;

  tooltipDiv.style('opacity', 1).html(textContent);
  moveTooltip(event, d, tooltipDiv, containerY);
};

const moveTooltip = (
  event: any,
  d: SingleDataBubbleValue,
  tooltipDiv: d3.Selection<HTMLDivElement, any, any, any>,
  containerY: number
) => {
  const position = {
    x: event.x + 20,
    y: event.clientY - containerY + 30,
  };

  tooltipDiv.style('left', position.x + 'px').style('top', position.y + 'px');
};

const hideTooltip = (
  tooltipDiv: d3.Selection<HTMLDivElement, any, any, any>
) => {
  tooltipDiv
    .transition()
    .duration(ANIMATION_DURATION)
    .style('opacity', 0)
    .transition()
    .duration(0)
    .style('top', TOOLTIP_IDLE_POSITION);
};

type BubbleChartProps = {
  dateRange: {
    from: Date;
    to: Date;
  };
  data: SingleDataBubbleValue[];
  width?: number;
  selectAmplitudes: (data: AmplitudeModalData) => void;
  isLoading: boolean;
};

//COMPONENT
const BubbleChart = ({
  data,
  dateRange,
  selectAmplitudes,
  isLoading,
}: BubbleChartProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const isFullSize = useTimelineDetailsDayByDayFullSize();
  const selectedDate = useTimelineDetailsSelectedDate();

  const [selectedMeanings, setSelectedMeanings] = useState<
    ObservationSymptom[]
  >([]);

  const { isOpen: isAnnotationVisible, toggle: toggleAnnotations } =
    useOpen(true);
  const { isOpen: isHistoryVisible, toggle: toggleHistory } = useOpen(true);
  const { isOpen: isFormVisible, toggle: toggleForm } = useOpen(true);
  const visibilityProps = {
    isAnnotationVisible,
    toggleAnnotations,
    isHistoryVisible,
    toggleHistory,
    isFormVisible,
    toggleForm,
  };

  const svgRef = useRef<SVGSVGElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const dateOffset = new Date(0).getTimezoneOffset();
  const offsetInMilliseconds = dateOffset * HOUR_IN_MILLISECONDS;

  const endOfDateHourInMilliseconds =
    DAY_IN_MILLISECONDS + offsetInMilliseconds;

  const [hoursRange, setHourRange] = useState([
    offsetInMilliseconds,
    endOfDateHourInMilliseconds,
  ]);

  //zoom
  const [xZoomFunction, setXZoomFunction] = useState<any>();
  const [currentScale, setCurrentScale] = useState(BASE_ZOOM_VALUE);

  const isBrowser = useBrowserState();

  const selectDate = useCallback(
    (date: Date) => {
      dispatch(changeSelectedDate(date));
    },
    [dispatch]
  );

  const datesBetween = useMemo(
    () =>
      eachDayOfInterval({
        start: dateRange.from,
        end: dateRange.to,
      }),
    [dateRange]
  );

  const rescaledYValues = useMemo(() => {
    return {
      min: add(datesBetween[datesBetween.length - 1], { hours: 12 }),
      max: sub(datesBetween[0], { hours: 12 }),
    };
  }, [datesBetween]);

  //calculating height
  const height = useMemo(() => {
    return datesBetween.length * HEIGHT_OF_LINE;
  }, [datesBetween]);

  const yScaleFunction = useMemo(
    () =>
      d3
        .scaleLinear()
        .domain([rescaledYValues.max, rescaledYValues.min])
        .range([height, 0]),
    [height, rescaledYValues]
  );

  const bubbleSelect = useCallback(
    (event: any, d: SingleDataBubbleValue) => {
      dispatch(changeSelectedDate(new Date(d.y)));

      if (instanceOfFormBubbleData(d) && (d.canOpen || d.canEdit)) {
        dispatch(
          setAssessmentFormIdentification({
            selectedSequence: d.sequence,
            contextObjectId: d.contextObjectId,
            contextType: d.contextType,
          })
        );
      }

      if (
        instanceOfObservationBubbleData(d) &&
        d.amplitudes &&
        d.painScaleLevel
      )
        selectAmplitudes({
          amplitudes: d.amplitudes,
          duration: d.z,
          painLevel: d.painScaleLevel,
        });
    },
    [dispatch, selectAmplitudes]
  );

  //calculating width and y location
  const { containerWidth, containerY } = useContainer(containerRef, {
    stateChanged: isFullSize,
    isDayByDayScreen: true,
  });
  const chartWidth = useMemo(
    () => containerWidth - SUM_OF_RESERVED_WIDTH,
    [containerWidth]
  );

  const xScaleFunction = useMemo(() => {
    return d3
      .scaleLinear()
      .domain([hoursRange[0], hoursRange[1]])
      .range([0, chartWidth]);
  }, [chartWidth, hoursRange]);

  const zScaleFunction = d3
    .scaleLinear()
    .domain([Z_DOMAIN.min, Z_DOMAIN.max])
    .range([MIN_OBSERVATION_RADIUS, MAX_OBSERVATION_RADIUS]);

  const zSelectedScaleFunction = d3
    .scaleLinear()
    .domain([Z_DOMAIN.min, Z_DOMAIN.max])
    .range([MIN_SELECTED_OBSERVATION_RADIUS, MAX_SELECTED_OBSERVATION_RADIUS]);

  const renderLines = useCallback(
    (lineChart) => {
      lineChart
        .append('g')
        .attr('class', 'lines')
        .selectAll('dots')
        .data(datesBetween)
        .join('line')
        .attr('x1', xScaleFunction(offsetInMilliseconds))
        .attr('y1', (d) => yScaleFunction(d.getTime()))
        .attr('x2', xScaleFunction(DAY_IN_MILLISECONDS))
        .attr('y2', (d) => yScaleFunction(d.getTime()))
        .attr('stroke', (d) => {
          if (selectedDate?.getTime() === d.getTime()) {
            return '#313131';
          } else {
            return '#e0e0e0';
          }
        })
        .style('stroke-width', '1px')
        .style('stroke-dasharray', (d) => {
          if (selectedDate?.getTime() === d.getTime()) {
            return 10;
          } else return 1;
        });
    },
    [
      datesBetween,
      xScaleFunction,
      offsetInMilliseconds,
      yScaleFunction,
      selectedDate,
    ]
  );

  const renderOthers = useCallback(
    (tooltip, lineChart) => {
      const filters: BubbleType[] = [];
      if (isAnnotationVisible) filters.push(BubbleType.ANNOTATION);
      if (isHistoryVisible) filters.push(BubbleType.HISTORY_ENTRY);
      if (isFormVisible) filters.push(BubbleType.FORM);

      let otherThanObservation = data.filter((singleData) =>
        filters.includes(singleData.type)
      );

      lineChart
        .selectAll('image')
        .data(otherThanObservation)
        .enter()
        .append('svg:image')
        .attr('xlink:href', (d: FormBubbleData) => {
          if (d.type === BubbleType.FORM) {
            switch (d.completionStatus) {
              case AssessmentCompletionStatus.COMPLETED:
                return assessment_form_filled;
              case AssessmentCompletionStatus.OVERDUE:
                return assessment_form_bad;
              case AssessmentCompletionStatus.UPCOMING:
                return assessment_form_pending;
              case AssessmentCompletionStatus.OPEN:
              default:
                return assessment_form_good;
            }
          }
          if (d.type === BubbleType.HISTORY_ENTRY) return historyIcon;
          else return annotationIcon;
        })
        .attr('x', (d) => {
          return xZoomFunction
            ? xZoomFunction(d.x) - iconRadius
            : xScaleFunction(d.x) - iconRadius;
        })
        .attr('y', (d) => {
          if (selectedDate && isSameDay(d.y, selectedDate))
            return yScaleFunction(d.y) - (IMAGE_SIZE + 5);
          else return yScaleFunction(d.y) - IMAGE_SIZE / 2;
        })
        .attr('width', IMAGE_SIZE)
        .attr('height', IMAGE_SIZE)
        .on('mouseover', (event, d) =>
          showTooltip(event, d, tooltip, containerY, intl)
        )
        .on('mousemove', (event, d) =>
          moveTooltip(event, d, tooltip, containerY)
        )
        .on('mouseleave', () => hideTooltip(tooltip))
        .on('click', bubbleSelect);
    },
    [
      data,
      intl,
      bubbleSelect,
      containerY,
      xScaleFunction,
      xZoomFunction,
      selectedDate,
      yScaleFunction,
      isAnnotationVisible,
      isFormVisible,
      isHistoryVisible,
    ]
  );

  //OBSERVATIONS
  const observations = useMemo(
    () =>
      data.filter(
        (singleData) =>
          instanceOfObservationBubbleData(singleData) &&
          singleData.symptom &&
          selectedMeanings.includes(singleData.symptom) &&
          singleData.type === BubbleType.OBSERVATION
      ),
    [data, selectedMeanings]
  );

  const renderObservations = useCallback(
    (tooltip, lineChart) => {
      lineChart
        .selectAll()
        .data(observations)
        .join('circle')
        .attr('cx', (d) => {
          return xZoomFunction ? xZoomFunction(d.x) : xScaleFunction(d.x);
        })
        .attr('cy', (d) => {
          const additionalHeight =
            selectedDate && isSameDay(d.y, selectedDate) ? HEIGHT_MOD : 0;

          return yScaleFunction(d.y) + additionalHeight;
        })
        .attr('r', (d) => {
          return selectedDate && isSameDay(d.y, selectedDate)
            ? zSelectedScaleFunction(d.z)
            : zScaleFunction(d.z);
        })
        .style('fill', (d) =>
          returnColorBaseOnMeaning(d.meaning || Meaning.NotSpecified)
        )
        .style('opacity', (d) =>
          returnOpacityBaseOnSqueeze(d.painScaleCategory)
        )
        .style('stroke-width', '2px')
        .on('mouseover', (event, d) =>
          showTooltip(event, d, tooltip, containerY, intl)
        )
        .on('mousemove', (event, d) =>
          moveTooltip(event, d, tooltip, containerY)
        )
        .on('mouseleave', () => hideTooltip(tooltip))
        .on('click', bubbleSelect);
    },
    [
      bubbleSelect,
      containerY,
      intl,
      xScaleFunction,
      zScaleFunction,
      xZoomFunction,
      selectedDate,
      yScaleFunction,
      observations,
      zSelectedScaleFunction,
    ]
  );

  const calculateHourRangeBaseOnChartX = useCallback(
    (start: number, end: number) => {
      return returnFormattedHourSelection(
        Math.round(xScaleFunction.invert(start)),
        Math.round(xScaleFunction.invert(end))
      );
    },
    [xScaleFunction]
  );

  const handleClip = useCallback(
    (extent, brushMove, lineChart) => {
      if (extent) {
        const { start, end } = calculateHourRangeBaseOnChartX(
          extent[0],
          extent[1]
        );

        if (start && end) {
          setHourRange([start, end]);
          xScaleFunction.domain([start, end]);
        }

        // This remove the grey brush area as soon as the selection has been done
        lineChart.select('.brush').call(brushMove, null);
      }
    },
    [xScaleFunction, calculateHourRangeBaseOnChartX]
  );

  const zoomDef = d3
    .zoom()
    .scaleExtent([BASE_ZOOM_VALUE, MAX_ZOOM_VALUE])
    .extent([
      [0, 0],
      [chartWidth, height],
    ])
    .translateExtent([
      [0, -Infinity],
      [chartWidth, Infinity],
    ]);

  const onZoomEvent = useCallback(
    (event, svg) => {
      if (event.transform) {
        const newX = event.transform.rescaleX(xScaleFunction);
        setXZoomFunction(() => newX);
        setCurrentScale(event.transform.k);

        svg.selectAll('circle').attr('cx', (d: any) => newX(d.x));
        svg.selectAll('image').attr('x', (d: any) => newX(d.x));
      }
    },
    [xScaleFunction]
  );

  const renderSelectedDateBackground = useCallback(
    (svg) => {
      if (selectedDate) {
        svg
          .append('rect')
          .attr('x', 0)
          .attr('y', yScaleFunction(selectedDate.getTime()) - 50)
          .attr('width', chartWidth)
          .attr('height', HEIGHT_OF_LINE)
          .attr('fill', '#F9F9F9');
      }
    },
    [selectedDate, yScaleFunction, chartWidth]
  );

  const renderChartFunction = useCallback(
    (svg: d3.Selection<SVGSVGElement, any, any, any>) => {
      svg.selectAll('*').remove();
      d3.select('#tooltip-container').select('div').remove();

      const tooltip = d3
        .select('#tooltip-container')
        .append('div')
        .style('opacity', 0)
        .style('top', TOOLTIP_IDLE_POSITION)
        .attr('class', 'BubbleChart__tooltip');

      renderSelectedDateBackground(svg);

      if (isBrowser) {
        const brushZoom = d3.brushX().extent([
          [0, 0],
          [chartWidth, height],
        ]);

        svg.attr('clip-path', 'url(#clip)');
        svg
          .append('defs')
          .append('clipPath')
          .attr('id', 'clip')
          .append('rect')
          .attr('width', chartWidth)
          .attr('height', height)
          .attr('x', 0)
          .attr('y', 0);

        brushZoom.on('end', (e) =>
          handleClip(e.selection, brushZoom.move, svg)
        );

        svg.append('g').attr('class', 'brush').call(brushZoom);
      } else {
        zoomDef.on('zoom', (e) => onZoomEvent(e, svg));
        svg.call(zoomDef as any);
      }

      renderLines(svg);
      renderObservations(tooltip, svg);
      renderOthers(tooltip, svg);
    },
    [
      chartWidth,
      height,
      isBrowser,
      renderOthers,
      renderObservations,
      renderLines,
      handleClip,
      onZoomEvent,
      zoomDef,
      renderSelectedDateBackground,
    ]
  );

  const onResetClick = () => {
    if (svgRef.current) {
      xScaleFunction.domain([
        offsetInMilliseconds,
        endOfDateHourInMilliseconds,
      ]);

      d3.select(svgRef.current)
        .select('#lineChart')
        .selectAll('circle')
        .data(data)
        .transition()
        .duration(1000)
        .attr('cx', (d) => {
          return xScaleFunction(d.x);
        })
        .attr('cy', (d) => {
          return yScaleFunction(d.y);
        });

      d3.select(svgRef.current).call(zoomDef.transform as any, d3.zoomIdentity);
      setXZoomFunction(undefined);
      setHourRange([offsetInMilliseconds, endOfDateHourInMilliseconds]);
      setCurrentScale(1);
    }
  };

  useEffect(() => {
    if (svgRef.current) {
      renderChartFunction(d3.select(svgRef.current));
    }
  }, [renderChartFunction]);

  const reversedDate = useMemo(() => {
    const copyOfDatesBetween = [...datesBetween];
    copyOfDatesBetween.reverse();

    return copyOfDatesBetween;
  }, [datesBetween]);

  return (
    <div className="BubbleChart w-100" ref={containerRef}>
      <ChartHeader onResetClick={onResetClick} />
      <div id="tooltip-container" />
      <SkeletonPlaceholderOnLoad
        isLoading={isLoading}
        placeholder={<Skeleton height={400} />}
      >
        <div id="divToExport">
          <XAxis
            chartWidth={chartWidth}
            currentScale={currentScale}
            hoursRange={hoursRange}
            xScaleFunction={xScaleFunction}
            xZoomFunction={xZoomFunction}
            position="top"
          />
          <div className="BubbleChart__container">
            <div className="d-flex">
              <YAxis
                dates={reversedDate}
                height={height}
                selectDate={selectDate}
                selectedDate={selectedDate}
                yScaleFunction={yScaleFunction}
              />
              <div
                style={{
                  width: chartWidth + 'px',
                }}
              >
                <svg ref={svgRef} width={chartWidth} height={height} />
              </div>
              <SummaryYAxis
                width={WIDTH_OF_SUMMARY}
                observations={observations}
                dates={reversedDate}
                selectedDate={selectedDate}
                height={height}
                yScaleFunction={yScaleFunction}
              />
            </div>
          </div>
          <XAxis
            position="bottom"
            chartWidth={chartWidth}
            currentScale={currentScale}
            hoursRange={hoursRange}
            xScaleFunction={xScaleFunction}
            xZoomFunction={xZoomFunction}
          />
          <BubbleChartLegend
            data={data}
            selectedMeanings={selectedMeanings}
            setSelectedMeanings={setSelectedMeanings}
            {...visibilityProps}
          />
        </div>
      </SkeletonPlaceholderOnLoad>
    </div>
  );
};

export default BubbleChart;
