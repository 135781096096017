import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import { deleteTrendVisualizationTemplates } from 'api/trendVisualizations';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';

type DeleteTrendVisualizationTemplateRequestParams = {
  organizationId: string;
  templateId: string;
};

const useDeleteTrendVisualizationTemplate = (successFb?: () => void) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (
      data: DeleteTrendVisualizationTemplateRequestParams
    ) => {
      await deleteTrendVisualizationTemplates(
        data.organizationId,
        data.templateId
      );
    },
    resetOnResult: true,
    onSuccess: () => {
      displaySuccessNotification(
        'TimelineDetails.TrendsTimeline.SaveTrendsChartFilter.savingSuccessful'
      );
      successFb && successFb();
    },
    onErrorArray: (errors) => {
      displayErrorArrayNotifications(
        errors,
        'TimelineDetails.TrendsTimeline.SaveTrendsChartFilter.savingError'
      );
    },
  });
};

export default useDeleteTrendVisualizationTemplate;
