import { useState } from 'react';
import { SetStepAction } from '../modules/TimelineDetails/components/tabs/DayByDayTab/components/QuestionModal/QuestionModalForm';

function useStepInForm(stepsLength: number | undefined) {
  const [step, setStep] = useState(0);
  const [lastSetStepAction, setLastSetStepAction] =
    useState<SetStepAction | null>(null);
  const [lastStepsDelta, setLastStepsDelta] = useState<number>(1);

  const increaseStep = () => {
    if (stepsLength && step + 1 <= stepsLength) {
      setStep((prev) => prev + 1);
      setLastStepsDelta(1);
      setLastSetStepAction(SetStepAction.NEXT);
    }
  };

  const increaseSteps = (num: number) => {
    if (stepsLength && step + num <= stepsLength) {
      setStep((prev) => prev + num);
      setLastStepsDelta(num);
      setLastSetStepAction(SetStepAction.NEXT);
    }
  };

  const decreaseStep = () => {
    if (step - lastStepsDelta >= 0) {
      setStep((prev) => prev - lastStepsDelta);
      setLastStepsDelta(1);
      setLastSetStepAction(SetStepAction.PREVIOUS);
    }
  };

  return {
    step,
    setStep,
    increaseStep,
    increaseSteps,
    decreaseStep,
    lastSetStepAction,
  };
}

export default useStepInForm;
