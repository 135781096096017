import { faHandshakeSlash, faHistory } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//helpers
import useInfiniteListSharing from 'api/queries/timelines/useInfiniteListSharing';

//helpers
import { formatDate } from 'helpers/dates';
import useRequiredParams from 'hooks/useRequiredParams';

//types
import { GraspModalActions } from 'interfaces/grasps';

//components
import ActionGraspModal from 'modules/Grasps/components/ActionGraspModal';
import { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { FormattedMessage } from 'react-intl';
import { Button, Col, ListGroup, ListGroupItem, Row } from 'reactstrap';
import { useBrowserState } from 'store/reducerHooks';

const ShareHistory = () => {
  const [selectedPersonalityId, setSelectedPersonalityId] = useState<
    null | string
  >(null);

  const { organizationId, graspId } = useRequiredParams<{
    organizationId: string;
    graspId: string;
  }>(['graspId', 'organizationId']);

  const { sharing, hasNextPage, isLoading, fetchNextPage, refetch } =
    useInfiniteListSharing({
      params: { organizationId, personalityId: graspId },
    });

  const reset = async () => {
    await refetch();
  };

  const onUnshareClick = (personalityId: string) => {
    if (isBrowser) {
      setSelectedPersonalityId(personalityId);
    } else {
      window.dispatchEvent(
        new CustomEvent('grasp-unsharing-request', {
          detail: {
            personality_id: personalityId,
            organization_id: organizationId,
            grasp_id: graspId,
          },
        })
      );
    }
  };

  const renderedHistory: JSX.Element[] = sharing.map((element, index) => {
    const isStillOwned = element.date_to === null;

    const toDateRendered = isStillOwned ? (
      <FormattedMessage id="OrganizationGrasps.details.now" />
    ) : (
      formatDate(element.date_to)
    );

    return (
      <ListGroupItem key={`${element.grasp_id}-${index}`}>
        <Row>
          <Col>
            <big>
              {element?.personality?.first_name || ''}{' '}
              {element?.personality?.last_name || ''}
            </big>
          </Col>
        </Row>
        <Row className="mt-4">
          <Col md={6}>
            <p className="p-0 m-0">
              <label>
                <FormattedMessage id="OrganizationGrasps.details.from" />:
              </label>{' '}
              {formatDate(element.date_from)}
            </p>
            <p>
              <label>
                <FormattedMessage id="OrganizationGrasps.details.to" />:
              </label>{' '}
              {toDateRendered}
            </p>
          </Col>
          <Col md={6} className="d-flex align-items-center justify-content-end">
            {isStillOwned && (
              <Button
                color="primary"
                onClick={() => {
                  onUnshareClick(element.personality.id);
                }}
              >
                <>
                  <FontAwesomeIcon icon={faHandshakeSlash} className="me-2" />
                  <FormattedMessage id="OrganizationGrasps.details.unshare" />
                </>
              </Button>
            )}
          </Col>
        </Row>
      </ListGroupItem>
    );
  });

  const isSharedGraspModalOPen = !!selectedPersonalityId;
  const toggleShareGraspModal = () => setSelectedPersonalityId(null);

  const isBrowser = useBrowserState();

  const loadMore = () => fetchNextPage();

  return (
    <>
      {isBrowser && selectedPersonalityId && (
        <ActionGraspModal
          isOpen={isSharedGraspModalOPen}
          toggle={toggleShareGraspModal}
          organizationId={organizationId}
          personalityId={selectedPersonalityId}
          refetchFallback={reset}
          graspIdToCompare={graspId}
          action={GraspModalActions.UNSHARE}
        />
      )}
      <div className="sharedGraspsPatient ms-2 mt-4">
        <div className="sharedGraspsPatient__header d-flex align-items-center">
          <FontAwesomeIcon color="#237fc0" icon={faHistory} className="me-2" />
          <FormattedMessage id="OrganizationGrasps.details.shareHistory" />
        </div>
        <div className="sharedGraspsPatient__list">
          <ListGroup>
            <InfiniteScroll
              threshold={100}
              initialLoad={false}
              hasMore={!isLoading && hasNextPage}
              loadMore={loadMore}
            >
              {renderedHistory}
            </InfiniteScroll>
          </ListGroup>
        </div>
      </div>
    </>
  );
};

export default ShareHistory;
