import { useCallback, useEffect, useState } from 'react';

const BREAKPOINT_WIDTH = 999;

function useMobile(breakpointWidth: number = BREAKPOINT_WIDTH) {
  const [isMobile, setMobile] = useState(() => {
    if (typeof window !== 'undefined')
      return window.innerWidth < breakpointWidth;
    return false;
  });

  const onResize = useCallback(
    (e) => {
      setMobile(e.target.outerWidth < breakpointWidth);
    },
    [breakpointWidth]
  );

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onResize);
      window.addEventListener('fullscreenchange', onResize);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', onResize);
        window.removeEventListener('fullscreenchange', onResize);
      }
    };
  }, [onResize]);

  return isMobile;
}

export default useMobile;
