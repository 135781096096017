import {
  DashboardFilter,
  DashboardFilterType,
  IClosedListDashboardFilter,
  IClosedListDashboardTemplateFilter,
  IGraspDashboardFilter,
  IGraspDashboardTemplateFilter,
  IIncludedExcludedFilter,
  IMemberDashboardFilter,
  IMemberDashboardTemplateFilter,
  IOptionsFilter,
  IOrganizationDashboardFilter,
  IOrganizationDashboardTemplateFilter,
  IRangeDashboardFilter,
  IRangeDashboardTemplateFilter,
  ISourcePeriodDashboardFilter,
  ISourcePeriodDashboardTemplateFilter,
} from '../../../../../../../interfaces/dashboards';

export namespace DashboardFilterHelpers {
  export const AreFiltersHidden = (filters: DashboardFilter[]) => {
    return filters.every((p) => !p.visible);
  };

  export const MemberFilter = (visible: boolean): IMemberDashboardFilter => {
    return {
      type: DashboardFilterType.Member,
      options: [],
      visible: visible,
    };
  };

  export const GraspFilter = (visible: boolean): IGraspDashboardFilter => {
    return {
      type: DashboardFilterType.Grasp,
      options: [],
      visible: visible,
    };
  };

  export const OrganizationFilter = (
    visible: boolean
  ): IOrganizationDashboardFilter => {
    return {
      type: DashboardFilterType.Organization,
      options: [],
      visible: visible,
    };
  };

  export const SourcePeriodFilter = (
    visible: boolean
  ): ISourcePeriodDashboardFilter => {
    return {
      type: DashboardFilterType.SourcePeriod,
      options: [],
      visible: visible,
    };
  };

  export const ClosedListFilter = (
    filter: IClosedListDashboardTemplateFilter
  ): IClosedListDashboardFilter => {
    return {
      id: filter.id,
      type: DashboardFilterType.ClosedList,
      options: [],
      visible: filter.visible,
    };
  };

  export const RangeFilter = (
    filter: IRangeDashboardTemplateFilter
  ): IRangeDashboardFilter => {
    return {
      id: filter.id,
      type: DashboardFilterType.Range,
      options: null,
      visible: filter.visible,
    };
  };

  export const IsOptionsBased = (filter: any): filter is IOptionsFilter => {
    return (filter as IOptionsFilter).options !== undefined;
  };

  export const IsOrganization = (
    filter: any
  ): filter is IOrganizationDashboardTemplateFilter => {
    return (
      (filter as IOrganizationDashboardTemplateFilter).type ===
      DashboardFilterType.Organization
    );
  };

  export const IsClosedList = (
    filter: any
  ): filter is IClosedListDashboardTemplateFilter => {
    return (
      (filter as IClosedListDashboardTemplateFilter).type ===
      DashboardFilterType.ClosedList
    );
  };

  export const IsRange = (
    filter: any
  ): filter is IRangeDashboardTemplateFilter => {
    return (
      (filter as IRangeDashboardTemplateFilter).type ===
      DashboardFilterType.Range
    );
  };

  export const IsMember = (
    filter: any
  ): filter is IMemberDashboardTemplateFilter => {
    return (
      (filter as IMemberDashboardTemplateFilter).type ===
      DashboardFilterType.Member
    );
  };

  export const IsGrasp = (
    filter: any
  ): filter is IGraspDashboardTemplateFilter => {
    return (
      (filter as IGraspDashboardTemplateFilter).type ===
      DashboardFilterType.Grasp
    );
  };

  export const IsSourcePeriod = (
    filter: any
  ): filter is ISourcePeriodDashboardTemplateFilter => {
    return (
      (filter as ISourcePeriodDashboardTemplateFilter).type ===
      DashboardFilterType.SourcePeriod
    );
  };

  export const IsIncludedExcluded = (
    filter: any
  ): filter is IIncludedExcludedFilter => {
    return (
      (filter as IIncludedExcludedFilter).included !== undefined &&
      (filter as IIncludedExcludedFilter).excluded !== undefined
    );
  };
}
