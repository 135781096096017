import { textWrapEllipse } from '../../../../../../../helpers/utils/strings';
import { ClosedListValidConversationQuestion } from '../../../../../../../interfaces/conversations';
import { DashboardReportPeriod } from '../../../../../../../interfaces/dashboards';
import { IGrasp } from '../../../../../../../interfaces/grasps';
import { IBaseOrganization } from '../../../../../../../interfaces/organizations';
import { BasePersonality } from '../../../../../../../interfaces/personalities';
import { AVAILABLE_CULTURES } from '../../../../../../CultureSelector/CultureSelector';

export namespace DashboardFormatHelpers {
  export const FormatQuestionAnswer = (
    question: ClosedListValidConversationQuestion | undefined,
    answerId: string,
    culture: AVAILABLE_CULTURES
  ) => {
    const selectedAnswer = question?.answers?.find((p) => p.guid === answerId);
    const nonCultureBasedTitle = selectedAnswer?.title ?? '';

    if (culture === AVAILABLE_CULTURES.NO)
      return selectedAnswer?.title_nb_no ?? nonCultureBasedTitle;

    return nonCultureBasedTitle;
  };

  export const FormatOrganizationMember = (
    member: BasePersonality | undefined
  ) => {
    return member ? `${member.last_name} ${member.first_name}` : '';
  };

  export const FormatGrasp = (grasp: IGrasp | undefined) => {
    return grasp?.name ?? '';
  };

  export const FormatOrganization = (
    organization: IBaseOrganization | undefined
  ) => {
    return organization ? textWrapEllipse(organization.name, 15) : '';
  };

  export const FormatSourcePeriod = (
    period: DashboardReportPeriod | undefined
  ) => {
    return period ? textWrapEllipse(period, 15) : '';
  };
}
