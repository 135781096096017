import { ChartsData } from 'components/TrendsChart/types';
import { useCallback, useEffect, useMemo, useState } from 'react';

export type SelectItemsHookValues = {
  isSelected: (label: string) => boolean;
  isAllSelected: boolean;
  toggleSelect: (label: string) => void;
  selectAll: () => void;
  selected: string[];
  initial: string[];
};

export const useSelectItems = (
  data: ChartsData<string | number>[]
): SelectItemsHookValues => {
  const generateLabels = (data: ChartsData<string | number>[]) => {
    let _labels = data.flatMap((p) => Object.keys(p));
    _labels = _labels.filter((p) => p !== 'name');
    return [...new Set(_labels)];
  };

  const initial = useMemo(() => generateLabels(data), [data]);

  const [selected, setSelected] = useState<string[]>(generateLabels(data));

  useEffect(() => {
    setSelected(generateLabels(data));
  }, [data]);

  const isAllSelected = useMemo(() => {
    return selected.length === initial.length;
  }, [initial.length, selected.length]);

  const toggleSelect = useCallback(
    (label: string) => {
      if (selected.includes(label)) {
        if (isAllSelected) {
          setSelected([label]);
          return;
        }

        setSelected((prev) => prev.filter((p) => p !== label));
        return;
      }

      setSelected((prev) => [...prev, label]);
    },
    [isAllSelected, selected]
  );

  const isSelected = useCallback(
    (label: string) => {
      return selected.includes(label);
    },
    [selected]
  );

  const selectAll = useCallback(() => {
    setSelected(initial);
  }, [initial]);

  return {
    toggleSelect,
    isSelected,
    isAllSelected,
    selectAll,
    selected,
    initial,
  };
};
