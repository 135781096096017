import { useCallback, useMemo } from 'react';
import { useAssetLinking } from './useAssetLinking';
import { IEducationMaterial } from '../../../../../../../../interfaces/educationMaterials';
import { AvailableCulture } from '../../../../../../../../hooks/cultures/useCultures';

export const useCultures = (
  assetId: string,
  material: IEducationMaterial,
  culture: AvailableCulture,
  onLink?: (culture: string) => void,
  onUnlink?: (culture: string) => void
) => {
  const { handleUnlink, handleLink } = useAssetLinking(
    assetId,
    material.id,
    culture.cultureCode,
    onLink,
    onUnlink
  );

  const assetCultures = useMemo(() => {
    let cultures: string[] = [];

    for (const entry of material.assets_with_cultures) {
      if (!entry.value.find((p) => p.id === assetId)) continue;
      cultures.push(entry.culture);
    }

    return cultures;
  }, [assetId, material.assets_with_cultures]);

  const toggleCulture = useCallback(async () => {
    const selected = assetCultures.includes(culture.cultureCode);

    if (selected) {
      await handleUnlink();
      return;
    }

    await handleLink();
  }, [assetCultures, culture, handleLink, handleUnlink]);

  return { assetCultures, toggleCulture };
};
