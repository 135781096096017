import useCaseDetails from '../../../../../../../api/queries/cases/useCaseDetails';
import useCasePhaseHistory from '../../../../../../../api/queries/cases/useCasePhaseHistory';
import useConversationTemplates from '../../../../../../../api/queries/conversations/useConversationTemplates';
import { useOrganizationCasePathParams } from '../../../../../../../hooks/paths/useOrganizationCasePathParams';

export const useCommonData = () => {
  const { organizationId, caseId } = useOrganizationCasePathParams();

  const caseDetailsQuery = useCaseDetails({
    params: { organizationId, caseId },
    options: { enabled: false, staleTime: Infinity },
  });

  const conversationTemplatesQuery = useConversationTemplates({
    params: {
      organizationId,
    },
    options: { enabled: false, staleTime: Infinity },
  });

  const casePhaseHistoryQuery = useCasePhaseHistory({
    params: { organizationId, caseId },
    options: { enabled: false, staleTime: Infinity },
  });

  return {
    conversationTemplatesQuery,
    casePhaseHistoryQuery,
    caseDetailsQuery,
  };
};
