export enum GenericOrganizationTabsValues {
  LOCATIONS = 'LOCATIONS',
  GRASPS = 'GRASPS',
  GRASP_SETTINGS = 'GRASP_SETTINGS',
  TREND_VISUALIZATIONS = 'TREND_VISUALIZATIONS',
  SYMPTOMS = 'SYMPTOMS',
  FUNCTIONALITIES = 'FUNCTIONALITIES',
  CASE_CATEGORIES = 'CASE_CATEGORIES',
  OBSERVATION_MEANINGS = 'OBSERVATION_MEANINGS',
}

export const mappedGenericOrganizationTabsValues = [
  GenericOrganizationTabsValues.LOCATIONS,
  GenericOrganizationTabsValues.GRASPS,
  GenericOrganizationTabsValues.GRASP_SETTINGS,
  GenericOrganizationTabsValues.TREND_VISUALIZATIONS,
  GenericOrganizationTabsValues.SYMPTOMS,
  GenericOrganizationTabsValues.FUNCTIONALITIES,
  GenericOrganizationTabsValues.CASE_CATEGORIES,
  GenericOrganizationTabsValues.OBSERVATION_MEANINGS,
];
