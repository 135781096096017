import React from 'react';
import { NavLink } from 'react-router-dom';
import { NavItem } from 'reactstrap';
import {
  generateRoute,
  RouteDefinitions,
} from '../../../../../../../../helpers/routeDefinitions';
import { getOrganizationPersonality } from '../../../../../../../../helpers/utils/auth';
import { useOrganizationPathParams } from '../../../../../../../../hooks/paths/useOrganizationPathParams';
import { DashboardTemplate } from '../../../../../../../../interfaces/dashboards';
import { useHandleTemplateName } from './hooks/useHandleTemplateName';

type DentalClinicItemProps = {
  template: DashboardTemplate;
};

const DentalClinicItem = ({ template }: DentalClinicItemProps) => {
  const { organizationId } = useOrganizationPathParams();
  const memberId = getOrganizationPersonality();

  const templateRoute = generateRoute(template.path as RouteDefinitions, {
    organizationId: organizationId,
    dashboardId: template.id,
    memberId: memberId ?? '',
  });

  const getTemplateName = useHandleTemplateName();
  const templateName = getTemplateName(template);

  return (
    <NavItem>
      <NavLink to={templateRoute} state={{ template: template }}>
        {templateName}
      </NavLink>
    </NavItem>
  );
};

export default DentalClinicItem;
