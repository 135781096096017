import { useCallback } from 'react';
import { useTranslations } from '../../../../../../../hooks/useTranslations';
import {
  ClosedListValidConversationQuestion,
  ConversationQuestion,
  ValidConversationQuestionType,
} from '../../../../../../../interfaces/conversations';
import {
  IClosedListDashboardFilter,
  IClosedListDashboardTemplateFilter,
} from '../../../../../../../interfaces/dashboards';
import { DashboardDefaults } from '../defaults';
import { DashboardSelectionHelpers } from '../helpers/selection';

export const useClosedListOptions = (questions: ConversationQuestion[]) => {
  const { wrapWithIntl, attachPrefix } = useTranslations(
    DashboardDefaults.NOT_SPECIFIED_TRANSLATION_PATH
  );

  const append = useCallback(
    (
      dashboardFilter: IClosedListDashboardFilter,
      filter: IClosedListDashboardTemplateFilter
    ): IClosedListDashboardFilter => {
      const existingQuestion = questions.find(
        (p) =>
          p.resource_id === filter.id &&
          p.content.type === ValidConversationQuestionType.ClosedList
      );

      const existingClosedList = existingQuestion?.content as
        | ClosedListValidConversationQuestion
        | undefined;

      const questionAnswers = existingClosedList?.answers;

      if (!questionAnswers) return dashboardFilter;

      questionAnswers.forEach((answer) => {
        dashboardFilter.options.push({
          id: answer.guid,
          selected: false,
        });
      });

      dashboardFilter.options.push({
        id: wrapWithIntl(
          attachPrefix(DashboardDefaults.NOT_SPECIFIED_TRANSLATION_PREFIX)
        ),
        selected: false,
      });

      return dashboardFilter;
    },
    [attachPrefix, questions, wrapWithIntl]
  );

  return {
    append,
    select: DashboardSelectionHelpers.SelectIncludedExcluded<
      IClosedListDashboardFilter,
      IClosedListDashboardTemplateFilter
    >,
  };
};
