export enum ReportExecution {
  Processing = 'Processing',
  Ready = 'Ready',
}

export interface ReportExecutionQueryParams {
  executionStatus: ReportExecution | null;
  templateCode: ReportTemplateCode | null;
  requestedAtFrom: string | null;
  requestedAtTo: string | null;
}

export enum ReportType {
  SUBJECTIVE_DATA = 'Subjective Data Over Period',
  OBSERVATION_DATA = 'Observations Over Period',
  MOMENTARY_SESSION = 'Momentary Session Export',
  PARTICIPANT_FEEDBACK = 'Participants feedback',
}

export enum ReportTemplateCode {
  SUBJECTIVE_DATA = 'SUBJECTIVE_DATA',
  OBSERVATION_DATA = 'OBSERVATION_DATA',
  MOMENTARY_SESSION = 'MOMENTARY_SESSION',
  PARTICIPANT_FEEDBACK = 'PARTICIPANT_FEEDBACK',
}

export interface IReport {
  id: string;
  year: number;
  month: number;
  day: number;
  requested_at: string;
  organizationId: string;
  requester_first_name: string;
  requester_last_name: string;
  code: ReportType;
  execution_status: ReportExecution;
  blob: string | null;
  parameters: ReportParameter[];
}

export type ReportParameter = {
  name: string;
  type: ReportParameterType;
  options: string[];
  value?: string | string[] | Date | boolean;
};

export type ReportParameterWithIndex = ReportParameter & { index: number };

export enum ReportParameterType {
  DateTime = 'DateTime',
  MultiSelect = 'MultiSelect',
  SingleSelect = 'SingleSelect',
  TrueFalseOption = 'TrueFalseOption',
}

type ReportTemplateParameter = Omit<ReportParameter, 'value'>;

export interface IReportTemplate {
  id: string;
  code: string;
  name: string;
  parameters: ReportTemplateParameter[];
}

export interface ICreateReportExecutionBody {
  organization_id: string;
  requester_first_name: string;
  requester_last_name: string;
  parameters: ReportParameter[];
  template_code: ReportTemplateCode;
  day: number;
  month: number;
  year: number;
}
