import { IQueryOptions } from '../../../interfaces/ui';
import { useQuery } from 'react-query';
import QueryKeys from '../../queries/queryKeys';
import { downloadUsersDataDumpFile } from '../../membership';

const USERS_DATA_DUMP_CONTENT_TYPE = 'application/json';

function useDownloadUsersDataDumpFile(
  userId: string,
  fileName: string,
  { enabled, refetchOnMount, refetchOnWindowFocus }: IQueryOptions
) {
  return useQuery<any>({
    queryKey: [QueryKeys.getUsersDataDumpFile, userId, fileName],
    queryFn: async () => {
      const response = await downloadUsersDataDumpFile(userId, fileName);

      return window.URL.createObjectURL(
        new Blob([JSON.stringify(response.data)], {
          type: USERS_DATA_DUMP_CONTENT_TYPE,
        })
      );
    },
    enabled,
    refetchOnMount,
    refetchOnWindowFocus,
  });
}

export default useDownloadUsersDataDumpFile;
