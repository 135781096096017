import React, { useMemo } from 'react';
import {
  AllowToChooseGroup,
  IProject,
} from '../../../../../interfaces/submissions';
import CaseGroupSelectOrPostpone from './CaseGroupSelectOrPostpone';
import CaseGroupSelectFromAvailable from './CaseGroupSelectFromAvailable';
import { Spinner } from 'reactstrap';
import CaseGroupUnableToSelect from './CaseGroupUnableToSelect';
import { CaseGroupType } from '../../../../../interfaces/caseGroups';
import useCaseGroupsForProject from '../../../../../api/queries/caseGroups/useCaseGroupsForProject';

type CaseGroupSelectionProps = {
  submissionId: string;
  organizationCode: string;
  project: IProject | undefined;
  onSelectionSuccess: () => void;
};

const CaseGroupSelection = ({
  submissionId,
  organizationCode,
  project,
  onSelectionSuccess,
}: CaseGroupSelectionProps) => {
  const { data: caseGroups, isLoading: caseGroupsIsLoading } =
    useCaseGroupsForProject({
      params: { organizationCode, projectId: project?.id ?? '' },
      options: { enabled: !!project?.id },
    });

  const availableGroups = useMemo(() => {
    return (
      caseGroups?.filter(
        (p) =>
          p.maximum_members_allowed > p.cases_count &&
          p.type === CaseGroupType.Permanent
      ) ?? []
    );
  }, [caseGroups]);

  if (!project || !caseGroups || caseGroupsIsLoading)
    return (
      <div className="w-100 text-center">
        <Spinner />
      </div>
    );

  if (availableGroups.length === 0)
    return <CaseGroupUnableToSelect onSuccess={onSelectionSuccess} />;

  switch (project.allow_to_choose_group) {
    case AllowToChooseGroup.SelectOrPostpone:
      return (
        <CaseGroupSelectOrPostpone
          organizationCode={organizationCode}
          projectId={project.id}
          submissionId={submissionId}
          caseGroups={availableGroups}
          onSuccess={onSelectionSuccess}
        />
      );
    case AllowToChooseGroup.SelectFromAvailable:
      return (
        <CaseGroupSelectFromAvailable
          organizationCode={organizationCode}
          projectId={project.id}
          submissionId={submissionId}
          caseGroups={availableGroups}
          onSuccess={onSelectionSuccess}
        />
      );
    default:
      return <></>;
  }
};

export default CaseGroupSelection;
