import {
  BaseSentiments,
  GraspEventItemType,
  Meaning,
  Sentiments,
} from '../../interfaces/timeline';
import { ConversationTemplateTriggerType } from '../../interfaces/conversations';
import annotationIcon from '../../assets/images/annotation_icon.svg';
import doctorsNoteIcon from '../../assets/images/doctors_note.svg';
import painIcon from '../../assets/images/meaning_icon/pain.svg';
import tirednessIcon from '../../assets/images/meaning_icon/tiredness.svg';
import drowsinessIcon from '../../assets/images/meaning_icon/drowsiness.svg';
import nauseaIcon from '../../assets/images/meaning_icon/nausea.svg';
import appetiteLackIcon from '../../assets/images/meaning_icon/appetite_lack.svg';
import breathShortnessIcon from '../../assets/images/meaning_icon/short_breath.svg';
import depressionIcon from '../../assets/images/meaning_icon/depression.svg';
import anxietyIcon from '../../assets/images/meaning_icon/anxiety.svg';
import temptationIcon from '../../assets/images/meaning_icon/temptation.svg';
import choiceMadeIcon from '../../assets/images/meaning_icon/choice_made.svg';
import graspSmallBlueIcon from '../../assets/images/grasp_small_blue.svg';
import sleepIcon from '../../assets/images/timeline_details/status_sleep.svg';
import statusIcon from '../../assets/images/timeline_details/status_symptom.svg';
import statusFunctioning from '../../assets/images/timeline_details/status_functioning.svg';
import { AssessmentCompletionStatus } from '../../interfaces/assessmentForms';
import completedIndicator from '../../assets/images/assessment_forms_indicators/completed_indicator.svg';
import completedIndicatorBig from '../../assets/images/assessment_forms_indicators/completed_indicator_big.svg';
import overdueIndicator from '../../assets/images/assessment_forms_indicators/overdue_indicator.svg';
import overdueIndicatorBig from '../../assets/images/assessment_forms_indicators/overdue_indicator_big.svg';
import openIndicator from '../../assets/images/assessment_forms_indicators/open_indicator.svg';
import openIndicatorBig from '../../assets/images/assessment_forms_indicators/open_indicator_big.svg';
import upcomingIndicator from '../../assets/images/assessment_forms_indicators/upcoming_indicator.svg';
import upcomingIndicatorBig from '../../assets/images/assessment_forms_indicators/upcoming_indicator_big.svg';
import negativeSmileIcon from 'assets/images/forms_smiles/negative.svg';
import partiallyNegativeSmileIcon from 'assets/images/forms_smiles/partially_negative.svg';
import neutralSmileIcon from 'assets/images/forms_smiles/neutral.svg';
import partiallyPositiveSmileIcon from 'assets/images/forms_smiles/partially_positive.svg';
import positiveSmileIcon from 'assets/images/forms_smiles/positive.svg';
import onceScheduleIcon from 'assets/images/once-schedule.svg';
import singleInstanceScheduleIcon from 'assets/images/single-intance-schedule.svg';
import recurringScheduleIcon from 'assets/images/recurring-schedule.svg';
import notepadIcon from 'assets/images/notepad.svg';
import bookmarkNotepadIcon from 'assets/images/bookmark-notepad.svg';
import outlineBookmarkNotepadIcon from 'assets/images/outline-bookmark-notepad.svg';
import stethoscopeIcon from 'assets/images/stethoscope.svg';

import { ConversationScheduleType } from '../../interfaces/conversationSchedules';
import { TemplateItemType } from '../../interfaces/change';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChartBar,
  faChartLine,
  faDumbbell,
  faStopwatch,
} from '@fortawesome/pro-light-svg-icons';
import React from 'react';

export const returnIconBasedOnObservationMeaningAndType = (
  type: GraspEventItemType,
  meaning?: Meaning
) => {
  if (type === GraspEventItemType.ANNOTATION) {
    return annotationIcon;
  }

  if (type === GraspEventItemType.CASE_HISTORY) {
    return doctorsNoteIcon;
  }

  switch (meaning) {
    case Meaning.SymptomPain:
      return painIcon;
    case Meaning.SymptomTiredness:
      return tirednessIcon;
    case Meaning.SymptomDrowsiness:
      return drowsinessIcon;
    case Meaning.SymptomNausea:
      return nauseaIcon;
    case Meaning.SymptomAppetiteLack:
      return appetiteLackIcon;
    case Meaning.SymptomBreathShortness:
      return breathShortnessIcon;
    case Meaning.SymptomDepression:
      return depressionIcon;
    case Meaning.SymptomAnxiety:
      return anxietyIcon;
    case Meaning.MicroChoiceTemptation:
      return temptationIcon;
    case Meaning.MicroChoiceChoiceMade:
      return choiceMadeIcon;
    default:
      return graspSmallBlueIcon;
  }
};

export const returnIconForQuestion = (name: string) => {
  if (name === 'Sleep') return sleepIcon;
  else if (['SymptomComfort', 'SymptomChange', 'SymptomControl'].includes(name))
    return statusIcon;
  else if (name === 'DailyFunctioning') return statusFunctioning;
  else return statusIcon;
};

export const returnIconIndicatorForCompletionStatus = (
  status: AssessmentCompletionStatus,
  small: boolean
) => {
  switch (status) {
    case AssessmentCompletionStatus.COMPLETED:
      return small ? completedIndicator : completedIndicatorBig;
    case AssessmentCompletionStatus.OVERDUE:
      return small ? overdueIndicator : overdueIndicatorBig;
    case AssessmentCompletionStatus.OPEN:
      return small ? openIndicator : openIndicatorBig;
    case AssessmentCompletionStatus.UPCOMING:
      return small ? upcomingIndicator : upcomingIndicatorBig;
  }
};

export const returnMeaningIcon = (meaning: string | Meaning) => {
  switch (meaning) {
    case Meaning.SymptomAppetiteLack:
      return appetiteLackIcon;
    case Meaning.SymptomAnxiety:
      return anxietyIcon;
    case Meaning.MicroChoiceChoiceMade:
      return choiceMadeIcon;
    case Meaning.SymptomDepression:
      return depressionIcon;
    case Meaning.SymptomNausea:
      return nauseaIcon;
    case Meaning.SymptomPain:
      return painIcon;
    case Meaning.MicroChoiceTemptation:
      return temptationIcon;
    case Meaning.SymptomTiredness:
      return tirednessIcon;
    case Meaning.SymptomBreathShortness:
      return breathShortnessIcon;
    case Meaning.SymptomDrowsiness:
      return drowsinessIcon;
    default:
      return statusIcon;
  }
};

export const getSmileIconBasedOnSentiment = (sentiment: BaseSentiments) => {
  switch (sentiment) {
    case Sentiments.Negative:
      return negativeSmileIcon;
    case Sentiments.Neutral:
      return neutralSmileIcon;
    case Sentiments.Positive:
      return positiveSmileIcon;
    default:
      return neutralSmileIcon;
  }
};

export const getSmileIcons = (reversed: boolean = false) => {
  const iconsArray = [
    negativeSmileIcon,
    partiallyNegativeSmileIcon,
    neutralSmileIcon,
    partiallyPositiveSmileIcon,
    positiveSmileIcon,
  ];

  return reversed ? iconsArray.reverse() : iconsArray;
};

export const getIconForConversationScheduleType = (
  scheduleType?: ConversationScheduleType,
  templateTriggerType?: ConversationTemplateTriggerType
) => {
  if (scheduleType) {
    switch (scheduleType) {
      case ConversationScheduleType.Once:
        return onceScheduleIcon;
      case ConversationScheduleType.SingleInstance:
        return singleInstanceScheduleIcon;
      case ConversationScheduleType.Recurring:
        return recurringScheduleIcon;
    }
  }

  if (templateTriggerType) {
    switch (templateTriggerType) {
      case ConversationTemplateTriggerType.PhaseLastResort:
        return notepadIcon;
      case ConversationTemplateTriggerType.ClinicianNotification:
        return stethoscopeIcon;
      case ConversationTemplateTriggerType.MandatoryEducation:
        return bookmarkNotepadIcon;
      case ConversationTemplateTriggerType.SuggestedEducation:
        return outlineBookmarkNotepadIcon;
    }
  }

  return undefined;
};

export const getIconForTemplateItemType = (type: TemplateItemType) => {
  switch (type) {
    case TemplateItemType.AllObservations:
      return <FontAwesomeIcon icon={faChartBar} />;
    case TemplateItemType.ExerciseObservations:
      return <FontAwesomeIcon icon={faDumbbell} />;
    case TemplateItemType.ExerciseTime:
      return <FontAwesomeIcon icon={faStopwatch} />;
    case TemplateItemType.Questions:
      return <FontAwesomeIcon icon={faChartLine} />;
    case TemplateItemType.NonExerciseObservations:
      return (
        <div className="crossed-out">
          <FontAwesomeIcon icon={faDumbbell} />
        </div>
      );
  }
};
