import React, { useMemo } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Progress,
} from 'reactstrap';
import SVG from 'react-inlinesvg';
import { FormattedMessage, useIntl } from 'react-intl';
import eventLogsIcon from 'assets/images/event_logs_icon.svg';
import { useCommonData } from './hooks/useCommonData';
import { useInitQuery } from '../../../../../../../../hooks/queries/useInitQuery';
import { useCaseGroupDetailsCommonData } from '../../../../hooks/useCaseGroupDetailsCommonData';
import CaseGroupDetailsConversationSchedulesParticipantsList from './components/ResponsesModalParticipantsList';
import { formatDate } from '../../../../../../../../helpers/dates';
import { format, parseISO } from 'date-fns';

type ResponsesModalProps = {
  scheduleId: string;
  isOpen: boolean;
  toggle: () => void;
};

const ResponsesModal = ({
  scheduleId,
  isOpen,
  toggle,
}: ResponsesModalProps) => {
  const intl = useIntl();

  const { conversationTemplates } = useCaseGroupDetailsCommonData();
  const { caseGroupScheduleResponses } = useCommonData(scheduleId);

  useInitQuery(conversationTemplates);
  useInitQuery(caseGroupScheduleResponses);

  const template = useMemo(() => {
    return conversationTemplates.data?.find(
      (p) => p.id === caseGroupScheduleResponses.data?.schedule.template_id
    );
  }, [
    caseGroupScheduleResponses.data?.schedule.template_id,
    conversationTemplates.data,
  ]);

  const scheduleDate = useMemo(() => {
    return intl.formatMessage(
      {
        id: 'CaseGroups.CaseGroupDetails.CaseGroupDetailsConversationSchedules.CaseGroupDetailsConversationSchedulesResponsesModal.scheduledTimeFormatted',
      },
      {
        date: caseGroupScheduleResponses.data?.schedule.date
          ? formatDate(caseGroupScheduleResponses.data.schedule.date)
          : '',
        hourFrom:
          caseGroupScheduleResponses.data?.schedule.available_from_hour ?? '',
        hourTo:
          caseGroupScheduleResponses.data?.schedule.available_to_hour ?? '',
        day: caseGroupScheduleResponses.data?.schedule.date
          ? format(
              parseISO(caseGroupScheduleResponses.data.schedule.date),
              'eeee'
            )
          : '',
      }
    );
  }, [
    caseGroupScheduleResponses.data?.schedule.available_from_hour,
    caseGroupScheduleResponses.data?.schedule.available_to_hour,
    caseGroupScheduleResponses.data?.schedule.date,
    intl,
  ]);

  return (
    <Modal
      className="CaseGroupDetailsConversationSchedulesResponsesModal"
      size="md"
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalHeader
        className="CaseGroupDetailsConversationSchedulesResponsesModal__header"
        toggle={toggle}
      >
        <SVG
          src={eventLogsIcon}
          title={intl.formatMessage({
            id: 'CaseGroups.CaseGroupDetails.CaseGroupDetailsConversationSchedules.CaseGroupDetailsConversationSchedulesResponsesModal.responses',
          })}
          width={14}
          height={14}
        />
        <FormattedMessage
          tagName="span"
          id="CaseGroups.CaseGroupDetails.CaseGroupDetailsConversationSchedules.CaseGroupDetailsConversationSchedulesResponsesModal.responses"
        />
      </ModalHeader>
      <ModalBody className="CaseGroupDetailsConversationSchedulesResponsesModal__body">
        <div className="CaseGroupDetailsConversationSchedulesResponsesModal__inline-info">
          <FormattedMessage id="CaseGroups.CaseGroupDetails.CaseGroupDetailsConversationSchedules.CaseGroupDetailsConversationSchedulesResponsesModal.template" />
          <span className="CaseGroupDetailsConversationSchedulesResponsesModal__inline-text">
            {template?.display_code ?? '-'}
          </span>
        </div>
        <div className="CaseGroupDetailsConversationSchedulesResponsesModal__inline-info">
          <FormattedMessage id="CaseGroups.CaseGroupDetails.CaseGroupDetailsConversationSchedules.CaseGroupDetailsConversationSchedulesResponsesModal.scheduledTime" />
          <span className="CaseGroupDetailsConversationSchedulesResponsesModal__inline-text">
            {scheduleDate}
          </span>
        </div>
        <div className="CaseGroupDetailsConversationSchedulesResponsesModal__progress">
          <Progress
            style={{ height: '10px', width: '100%' }}
            value={
              caseGroupScheduleResponses.data?.schedule?.completion_percent ?? 0
            }
          />
          {caseGroupScheduleResponses.data?.schedule && (
            <span className="CaseGroupDetailsConversationSchedulesResponsesModal__summary">{`${caseGroupScheduleResponses.data.schedule.completed_schedules} / ${caseGroupScheduleResponses.data.schedule.assigned_schedules}`}</span>
          )}
        </div>
        <div className="CaseGroupDetailsConversationSchedulesResponsesModal__responses">
          <span className="CaseGroupDetailsConversationSchedulesResponsesModal__missing-responses">
            <FormattedMessage id="CaseGroups.CaseGroupDetails.CaseGroupDetailsConversationSchedules.CaseGroupDetailsConversationSchedulesResponsesModal.missingResponses" />
          </span>
          <CaseGroupDetailsConversationSchedulesParticipantsList
            responses={caseGroupScheduleResponses.data?.responses ?? []}
          />
        </div>
      </ModalBody>
      <ModalFooter></ModalFooter>
    </Modal>
  );
};

export default ResponsesModal;
