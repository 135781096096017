import { formatDate } from 'helpers/dates';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import SVG from 'react-inlinesvg';
import { TimeInfo } from 'interfaces/timeline';

//images
import arrow_icon from 'assets/images/arrow_icon.svg';

type DashboardCardProps = {
  title: string;
  description: string | undefined;
  cardType: 'timeline' | 'assessment';
  button?: JSX.Element;
  isShared?: boolean;
  timeInformation?: TimeInfo[];
  timelineType?: string | null;
  svgIconSrc?: string;
};

const DashboardCard = ({
  title,
  description,
  isShared = false,
  cardType,
  timeInformation = [],
  timelineType,
  button,
  svgIconSrc,
}: DashboardCardProps) => {
  const renderedTimeInformation = timeInformation.map(
    ({ haveBorder, label, dateValue, iconSrc }, index) => {
      let iconClasses = 'DashboardCard__date-container__icon';
      if (haveBorder)
        iconClasses += ' DashboardCard__date-container__icon--border';

      return (
        <div
          key={`${label}-${index}`}
          className="DashboardCard__date-container"
        >
          <div className={iconClasses}>
            <img src={iconSrc} alt="date_icon" />
          </div>
          <div className="DashboardCard__date-container__text">
            <div className="DashboardCard__date-container__text__label">
              {label}
            </div>
            <div className="DashboardCard__date-container__text__date">
              {dateValue ? formatDate(dateValue) : '---'}
            </div>
          </div>
        </div>
      );
    }
  );

  return (
    <div className={`DashboardCard DashboardCard--${cardType}`}>
      <div className="d-flex flex-column">
        <div className="d-flex justify-content-between">
          <div className="DashboardCard__header">
            {svgIconSrc && (
              <SVG src={svgIconSrc} className="DashboardCard__header__icon" />
            )}
            {title}
            {cardType === 'assessment' && (
              <SVG src={arrow_icon} className="ms-4" />
            )}
          </div>
          {isShared && cardType === 'timeline' && (
            <div className="DashboardCard__tag">
              <FormattedMessage id="UserDashboard.shared" />
            </div>
          )}
          {button}
        </div>
        <div className="DashboardCard__description">
          {timelineType && cardType === 'timeline' && (
            <span className="DashboardCard__description__type">
              <FormattedMessage
                id={`Dashboard.timelineTypeOptions.${timelineType}`}
              />{' '}
              <FormattedMessage id="General.type" />
            </span>
          )}
          <span className="DashboardCard__description__text">
            {description}
          </span>
        </div>
        <div className="w-100 d-flex flex-wrap">{renderedTimeInformation}</div>
      </div>
    </div>
  );
};

export default DashboardCard;
