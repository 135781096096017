import html2canvas from 'html2canvas';
import SVG from 'react-inlinesvg';
import { Button, Col, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

//images
import downloadIcon from 'assets/images/timeline_details/download.svg';
import zoomOut from 'assets/images/zoom_out.svg';
import dayByDayIcon from 'assets/images/timeline_details/day_by_day.svg';
import fullSize from 'assets/images/timeline_details/full_size.svg';

//components
import { useDispatch } from 'react-redux';
import { setDayByDayFullSize } from 'modules/TimelineDetails/actions';

interface ChartHeaderProps {
  onResetClick: () => void;
}

const ChartHeader = ({ onResetClick }: ChartHeaderProps) => {
  const dispatch = useDispatch();

  const onToggleFullSize = () => dispatch(setDayByDayFullSize());

  const exportToImage = () => {
    const htmlElement = document.querySelector('#divToExport');
    const container = htmlElement?.querySelector(
      '.BubbleChart__container'
    ) as HTMLElement;

    if (container)
      container.setAttribute('class', 'BubbleChart__container--download');

    if (htmlElement)
      html2canvas(htmlElement as HTMLElement).then((canvas) => {
        const image = new Image();
        image.src = canvas.toDataURL('image/png');
        image.width = 1000;

        const openedWindow = window.open('');
        if (openedWindow) openedWindow.document.write(image.outerHTML);
      });

    if (container) container.setAttribute('class', 'BubbleChart__container');
  };

  return (
    <Row>
      <Col xs={6} sd={6} md={6}>
        <div className="cardContainer__header__title">
          <img src={dayByDayIcon} alt={dayByDayIcon} className="me-2" />
          <FormattedMessage id={'TimelineDetails.tabs.dayByDay'} />
        </div>
      </Col>
      <Col xs={6} sd={6} md={6}>
        <div className="d-flex w-100 justify-content-end align-items-center mt-1">
          <div className="BubbleChart__button-wrapper">
            <Button
              onClick={onResetClick}
              className="BubbleChart__button BubbleChart__button--chart"
            >
              <SVG src={zoomOut} />
            </Button>
            <br />
            <label>
              <FormattedMessage id="General.reset" />
            </label>
          </div>
          <div className="BubbleChart__line" />
          <div className="BubbleChart__button-wrapper">
            <button
              onClick={exportToImage}
              className="BubbleChart__button BubbleChart__button--action"
            >
              <SVG src={downloadIcon} />
            </button>
            <br />
            <label>
              <FormattedMessage id="General.download" />
            </label>
          </div>
          <div className="BubbleChart__button-wrapper">
            <button
              onClick={onToggleFullSize}
              className="BubbleChart__button BubbleChart__button--action"
            >
              <SVG src={fullSize} />
            </button>
            <br />
            <label>
              <FormattedMessage id="TimelineDetails.fullSize" />
            </label>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default ChartHeader;
