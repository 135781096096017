import React from 'react';
import { FormattedMessage } from 'react-intl';
import { isMoreThanLimit } from '../../helpers/data';
import { useDataLimitSource } from './hooks/useDataLimitSource';

const DataLimitConstraint = () => {
  const dataLimitSource = useDataLimitSource();

  return (
    <div className="d-flex w-100 justify-content-center">
      {isMoreThanLimit(dataLimitSource) && (
        <span className="opacity-25 fs-6">
          <FormattedMessage
            id="Dashboards.ShowingDataBasedOnNRows"
            values={{
              rows: dataLimitSource.data.length,
            }}
          />
        </span>
      )}
    </div>
  );
};

export default DataLimitConstraint;
