import { useMemo } from 'react';
import { ITableDashboardTemplateVisualization } from '../../../../../../../../../../../../../interfaces/dashboards';
import {
  BootstrapBreakpoint,
  BootstrapBreakpointMapping,
} from '../../../../../../../../../../../../../interfaces/ui';
import { CustomTableResponsiveConfig } from '../../../../../../../../../../../../CustomTable';

export const useResponsive = (
  visualization: ITableDashboardTemplateVisualization
): CustomTableResponsiveConfig[] => {
  return useMemo(() => {
    let config: CustomTableResponsiveConfig[] = Object.keys(
      BootstrapBreakpoint
    ).map((key) => ({
      breakpoint: BootstrapBreakpointMapping[key],
      hiddenColumns: [],
    }));

    visualization.responsive.forEach((item) => {
      const configIndex = config.findIndex(
        (p) => p.breakpoint === BootstrapBreakpointMapping[item.breakpoint]
      );

      if (configIndex === -1) return;
      config[configIndex].hiddenColumns = item.hidden;
    });

    config = config.filter((p) => p.hiddenColumns.length > 0);
    config.sort((a, b) => b.breakpoint - a.breakpoint);

    return config;
  }, [visualization.responsive]);
};
