import { FormikProps } from 'formik';
import { useCallback } from 'react';
import { FormValues } from '../../../types';

export const useHandleRemoveNotification = (form: FormikProps<FormValues>) => {
  return useCallback(
    (index: number) => () => {
      form.setValues((prev) => ({
        ...prev,
        schedule_templates: [
          {
            ...prev.schedule_templates[0],
            parts_of_a_day: prev.schedule_templates[0].parts_of_a_day.filter(
              (_, idx) => idx !== index
            ),
          },
        ],
      }));
    },
    [form]
  );
};
