import { FormikProps } from 'formik';
import { AwareCaseDetailsFormValues } from '../../../forms/AwareCaseDetailsForm/types';
import { useMemo } from 'react';
import { useProceduresDiffs } from './useProceduresDiffs';
import { ManageActivityFormValues } from './useForm';
import { ICaseProcedureWithActivitiesLocalized } from '../../../../../../../../../interfaces/cases';
import { secondsToDurationString } from '../../../../../../../../../helpers/dates';
import { useTypedContext } from '../../../../../../../../../hooks/useTypedContext';
import { OperationalDataContext } from '../../../providers/OperationalDataProvider';
import { useMsDiffs } from '../../../components/ProcedureEventsTimeline/hooks/useMsDiffs';

export const useInitialValues = (
  msPoint: number,
  activityIndex: number | undefined,
  form: FormikProps<AwareCaseDetailsFormValues>,
  procedures: ICaseProcedureWithActivitiesLocalized[]
): ManageActivityFormValues => {
  const proceduresDiffs = useProceduresDiffs(form);
  const { awareSessionDetails } = useTypedContext(OperationalDataContext);

  const { getMsDiff } = useMsDiffs(
    awareSessionDetails.start_date,
    awareSessionDetails.end_date
  );

  return useMemo((): ManageActivityFormValues => {
    const procedureIndex = proceduresDiffs.find(
      (p) => p.startDiff <= msPoint && p.endDiff >= msPoint
    )?.index;

    if (procedureIndex === undefined) {
      return {
        procedureId: procedures.at(0)?.id ?? '',
        procedureDisabled: false,
        startMs: secondsToDurationString(msPoint / 1000),
        activityId: undefined,
      };
    }

    const selectedProcedure = form.values.procedures[procedureIndex];

    if (activityIndex !== undefined) {
      const selectedActivity = selectedProcedure.activities[activityIndex];

      const startActivityDiff = getMsDiff(
        awareSessionDetails.start_date,
        selectedActivity.timeStart
      );

      return {
        procedureId: selectedProcedure.id,
        procedureDisabled: true,
        startMs: secondsToDurationString(startActivityDiff / 1000),
        activityId:
          form.values.procedures[procedureIndex].activities[activityIndex].id,
      };
    }

    return {
      procedureId: selectedProcedure.id,
      procedureDisabled: true,
      startMs: secondsToDurationString(msPoint / 1000),
      activityId: undefined,
    };
  }, [
    activityIndex,
    awareSessionDetails.start_date,
    form.values.procedures,
    getMsDiff,
    msPoint,
    procedures,
    proceduresDiffs,
  ]);
};
