import useUserQuestionAnswerHistory from 'api/queries/change/useUserQuestionAnswerHistory';
import useExerciseSessions from 'api/queries/exerciseSessions/useExerciseSessions';
import useTimelineObservationsForPeriod from 'api/queries/timelines/useTimelineObservationsForPeriod';
import useTimelineSqueezeDataFetch from 'api/queries/timelines/useTimelineSqueezeDataFetch';
import { TrendsChartGenericDataQueries } from 'components/TrendsChart/types';
import { CasesHelpers } from 'helpers/data/cases';
import { getCaseMasterTimeline } from 'helpers/timelines';
import { useInitQuery } from 'hooks/queries/useInitQuery';
import { Granularity, SqueezeQuantityGrouping } from 'interfaces/timeline';
import { TrendVisualizationTemplate } from 'interfaces/trendVisualizations';
import { useContext, useMemo } from 'react';
import { useCommonData } from '../../../../../hooks/useCommonData';
import { ChangeCaseDashboardDateRangeContext } from '../../../../../index';

const getQuestionIdsFromTemplate = (template: TrendVisualizationTemplate) => {
  const nullableQuestionIds =
    template.visualizations
      .flatMap((p) => p.elements)
      .map((p) => p.question_id) ?? [];
  return nullableQuestionIds.filter((p) => p !== null).map((p) => p ?? '');
};

export const useQueries = (
  template: TrendVisualizationTemplate
): TrendsChartGenericDataQueries => {
  const { caseDetails } = useCommonData();

  const dateRangeObject = useContext(ChangeCaseDashboardDateRangeContext);

  const caseMasterTimelineId = getCaseMasterTimeline(caseDetails.data);

  const observationsQuery = useTimelineSqueezeDataFetch({
    params: {
      timelineId: caseMasterTimelineId ?? '',
      granularity: Granularity.d1,
      dateRangeObject: dateRangeObject,
      groupBy: SqueezeQuantityGrouping.Meaning,
    },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(
    observationsQuery,
    caseMasterTimelineId !== undefined && caseDetails.data !== undefined
  );

  const questionIds = getQuestionIdsFromTemplate(template);

  const userId = useMemo(
    () =>
      caseDetails.data
        ? CasesHelpers.GetUserIdBasedOnCase(caseDetails.data)
        : null,
    [caseDetails.data]
  );

  const questionAnswerHistoryQuery = useUserQuestionAnswerHistory({
    params: {
      userId: userId ?? '',
      dateFrom: dateRangeObject?.from ?? null,
      dateTo: dateRangeObject?.to ?? null,
      questionIds: questionIds,
    },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(questionAnswerHistoryQuery, !!userId);

  const exercisesQuery = useExerciseSessions({
    params: {
      userId: userId ?? '',
      from: dateRangeObject?.from ?? new Date(),
      to: dateRangeObject?.to ?? new Date(),
    },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(exercisesQuery, !!dateRangeObject?.from && !!userId);

  const observationInTimelinesQuery = useTimelineObservationsForPeriod({
    params: {
      timelineId: caseMasterTimelineId ?? '',
      from: dateRangeObject?.from ?? new Date(),
      to: dateRangeObject?.to ?? new Date(),
    },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(
    observationInTimelinesQuery,
    caseMasterTimelineId !== undefined && caseDetails.data !== undefined
  );

  return {
    observations: observationsQuery,
    questionAnswerHistory: questionAnswerHistoryQuery,
    exercises: exercisesQuery,
    observationInTimelines: observationInTimelinesQuery,
  };
};
