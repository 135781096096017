import _ from 'lodash';
import React from 'react';
import { wrapWithSequenceIdentified } from '../../helpers/utils/data';

type ForEachProps<T extends object> = {
  items: T[];
  children: (item: T, index: number, array: T[]) => JSX.Element;
  itemKey?: string;
};

const ForEach = <T extends object>({
  items,
  children,
  itemKey = undefined,
}: ForEachProps<T>) => {
  return (
    <>
      {wrapWithSequenceIdentified(items).map((item, index, array) => (
        <React.Fragment key={itemKey ? _.get(item, itemKey) : item.sequence}>
          {children(item, index, array)}
        </React.Fragment>
      ))}
    </>
  );
};

export default ForEach;
