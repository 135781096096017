import {
  faAngry,
  faBomb,
  faFrown,
  faGrin,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Input } from 'reactstrap';

const ImportanceInput = ({ field, form, isLoading, ...props }) => {
  const renderedInfo = useMemo(() => {
    switch (field.value) {
      default:
      case 1:
        return (
          <div className="Feedback__importance Feedback__importance--not_important">
            <FontAwesomeIcon icon={faGrin} className="me-2" />
            <FormattedMessage id="Footer.FeedbackModal.notImportant" />
          </div>
        );
      case 2:
        return (
          <div className="Feedback__importance Feedback__importance--important">
            <FontAwesomeIcon icon={faFrown} className="me-2" />
            <FormattedMessage id="Footer.FeedbackModal.important" />
          </div>
        );
      case 3:
        return (
          <div className="Feedback__importance Feedback__importance--very_important">
            <FontAwesomeIcon icon={faAngry} className="me-2" />
            <FormattedMessage id="Footer.FeedbackModal.veryImportant" />
          </div>
        );
      case 4:
        return (
          <div className="Feedback__importance Feedback__importance--critical">
            <FontAwesomeIcon icon={faBomb} className="me-2" />
            <FormattedMessage id="Footer.FeedbackModal.critical" />
          </div>
        );
    }
  }, [field.value]);

  return (
    <div className="w-100">
      {renderedInfo}
      <Input type="range" min={1} max={4} {...field} {...props} />
    </div>
  );
};

export default ImportanceInput;
