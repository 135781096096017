import React, { useCallback } from 'react';
import { IPersonality } from '../../../../../../../../interfaces/personalities';
import { useOrganizationPathParams } from '../../../../../../../../hooks/paths/useOrganizationPathParams';
import useMembersInOrganizations from '../../../../../../../../api/queries/organizations/useMembersInOrganizations';
import { useInitQuery } from '../../../../../../../../hooks/queries/useInitQuery';
import QueryLoaded from '../../../../../../../../components/QueryLoaded';
import { IAwareSessionDataMapping } from '../../../../../../../../interfaces/awareSessions';
import { IOrganizationMembers } from '../../../../../../../../interfaces/organizations';

type MembersProviderProps = {
  mapping: IAwareSessionDataMapping;
  children: (members: IPersonality[]) => JSX.Element;
};

const MembersProvider = ({ mapping, children }: MembersProviderProps) => {
  const { organizationId } = useOrganizationPathParams();

  const membersInOrganizations = useMembersInOrganizations({
    params: {
      organizationId,
      organization_ids: mapping.organizations,
    },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(membersInOrganizations);

  const prepareMembers = useCallback(
    (organizationMembers: IOrganizationMembers[]) => {
      return organizationMembers.flatMap((p) => p.members) as IPersonality[];
    },
    []
  );

  return (
    <QueryLoaded query={membersInOrganizations}>
      {(members) => children(prepareMembers(members))}
    </QueryLoaded>
  );
};

export default MembersProvider;
