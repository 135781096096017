import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink, faSave, faUndo } from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage } from 'react-intl';
import { Field, Formik } from 'formik';
import InputFieldWithoutImage from '../../../../../../components/InputFieldWithoutImage';
import FormikErrorMessage from '../../../../../../components/FormikErrorMessage';
import FieldSelect from '../../../../../../components/FieldSelect';
import LoadingButton from '../../../../../../components/LoadingButton';
import { useTranslations } from './hooks/useTranslations';
import { useLinkEducationWithCategoryModal } from './hooks/useLinkMaterialWithCategoryModal';
import { IBaseEducationMaterialCategory } from '../../../../../../interfaces/educationMaterials';

type LinkMaterialWithCategoryModalProps = {
  isOpen: boolean;
  toggle: () => void;
  category: IBaseEducationMaterialCategory;
};

const LinkMaterialWithCategoryModal = ({
  isOpen,
  toggle,
  category,
}: LinkMaterialWithCategoryModalProps) => {
  const translations = useTranslations();

  const { initialValues, handleSubmit, submitState, options, validation } =
    useLinkEducationWithCategoryModal(category, toggle);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="GenericModal BorderlessModal"
      size="lg"
    >
      <ModalHeader toggle={toggle}>
        <FontAwesomeIcon icon={faLink} />
        <FormattedMessage id={translations.linkMaterialWithCategory} />
      </ModalHeader>
      <Formik
        validate={validation}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isValid, resetForm, dirty, isSubmitting }) => {
          const handleReset = () => resetForm();

          return (
            <>
              <ModalBody className="d-flex flex-column gap-2">
                <InputFieldWithoutImage
                  gray={true}
                  noMargin={true}
                  labelTranslationId={translations.categoryLabel}
                  inputId="category_id"
                  errorInfo={<FormikErrorMessage name="category_id" />}
                >
                  <Field
                    id="category_id"
                    name="category_id"
                    disabled={true}
                    required={true}
                    defaultText={translations.categoryPlaceholder}
                    options={options.categories}
                    type="select"
                    component={FieldSelect}
                  />
                </InputFieldWithoutImage>
                <InputFieldWithoutImage
                  gray={true}
                  noMargin={true}
                  labelTranslationId={translations.materialLabel}
                  inputId="material_id"
                  errorInfo={<FormikErrorMessage name="material_id" />}
                >
                  <Field
                    id="material_id"
                    name="material_id"
                    type="select"
                    defaultText={translations.materialPlaceholder}
                    options={options.materials}
                    required={false}
                    component={FieldSelect}
                  />
                </InputFieldWithoutImage>
              </ModalBody>
              <ModalFooter>
                <LoadingButton
                  isLoading={isSubmitting}
                  isSuccess={submitState.isSuccess}
                  isFailure={submitState.isError}
                  disabled={!isValid || !dirty}
                  color="primary"
                  onClick={submitForm}
                >
                  <FontAwesomeIcon icon={faSave} />
                </LoadingButton>
                <Button color="primary" outline onClick={handleReset}>
                  <FontAwesomeIcon icon={faUndo} />
                </Button>
              </ModalFooter>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default LinkMaterialWithCategoryModal;
