import jwtDecode from 'jwt-decode';
import React, { PropsWithChildren } from 'react';
import { getAccessToken } from '../../helpers/utils/auth';
import { useOrganizationPathParams } from '../../hooks/paths/useOrganizationPathParams';
import { ITokenData } from '../../interfaces/membership';
import NotAuthorized from '../NotAuthorized';

type CurrentOrganizationGuardProps = {
  fallback?: JSX.Element;
};

const CurrentOrganizationGuard = ({
  fallback = <NotAuthorized />,
  children,
}: PropsWithChildren<CurrentOrganizationGuardProps>) => {
  const { organizationId } = useOrganizationPathParams();

  const token = getAccessToken();
  if (!token) return fallback;

  const decodedToken = jwtDecode<ITokenData>(token);

  return decodedToken['Grasp.Identity.CurrentOrganization'] ===
    organizationId ? (
    <>{children}</>
  ) : (
    fallback
  );
};

export default CurrentOrganizationGuard;
