import React, { useState } from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

//helpers
import useRequestPatientAccesses from 'api/mutations/patients/useRequestPatientAccesses';

//types
import { ParticipantAccessSelection, RequestAccessStep } from 'interfaces/ui';
import { AccessType } from 'interfaces/enums';

//components
import AccessSearchStep from './AccessSearchStep';
import AccessSelectStep from './AccessSelectStep';
import AccessRequestTypeSelect from 'components/AccessRequestModal/AccessRequestTypeSelect';
import LoadingButton from 'components/LoadingButton';

//images
import nameLogo from 'assets/images/register/name.svg';

interface PatientAccessRequestModalProps {
  organizationId: string;
  toggle: () => void;
  isOpen: boolean;
  refetchPatientList: () => void;
  translationIdTitle: string;
}

export type PossibleAccessType =
  | AccessType.REQUEST
  | AccessType.EMERGENCY_ACCESS
  | undefined;

const AccessRequestModal = ({
  toggle,
  isOpen,
  organizationId,
  refetchPatientList,
  translationIdTitle,
}: PatientAccessRequestModalProps) => {
  const [step, setStep] = useState<RequestAccessStep>(
    RequestAccessStep.CHOOSE_TYPE
  );
  const [accessType, setAccessType] = useState<PossibleAccessType>();
  const [reason, setReason] = useState<string>('');
  const [fullName, setFullName] = useState<string | undefined>();
  const [patientNumber, setPatientNumber] = useState<number | undefined>();
  const [selection, setSelection] = useState<ParticipantAccessSelection | null>(
    null
  );

  const resetModal = () => {
    setAccessType(undefined);
    setReason('');
    setFullName(undefined);
    setPatientNumber(undefined);
    setStep(RequestAccessStep.CHOOSE_TYPE);
    setSelection(null);
  };

  const onModalClose = () => {
    toggle();

    resetModal();
  };

  const onSuccess = () => {
    refetchPatientList();
    onModalClose();
  };

  const { mutate: requestMutation, isLoading: isMutationLoading } =
    useRequestPatientAccesses(organizationId, onSuccess);

  const onRequestSend = () => {
    if (accessType && selection) {
      requestMutation({
        access_request: selection,
        access_type: accessType,
        reason,
      });
    }
  };

  const renderBody = () => {
    switch (step) {
      case RequestAccessStep.CHOOSE_TYPE:
        return (
          <AccessRequestTypeSelect
            setAccessType={setAccessType}
            setReason={setReason}
            reason={reason}
            selectedAccessType={accessType}
          />
        );
      case RequestAccessStep.SEARCH:
        return (
          <AccessSearchStep
            setFullName={setFullName}
            fullName={fullName}
            patientNumber={patientNumber}
            setPatientNumber={setPatientNumber}
          />
        );
      case RequestAccessStep.SELECT:
        return (
          <AccessSelectStep
            selection={selection}
            setSelection={setSelection}
            fullName={fullName}
            patientNumber={patientNumber}
            organizationId={organizationId}
          />
        );
    }
  };

  const renderFooter = () => {
    let continueText: JSX.Element | null = null;
    let continueAction: (() => void) | undefined;
    let isContinueDisabled = false;
    let isLoading = false;

    switch (step) {
      case RequestAccessStep.CHOOSE_TYPE:
        isContinueDisabled = !accessType || !reason;
        continueAction = () => setStep(RequestAccessStep.SEARCH);
        continueText = <FormattedMessage id="General.next" />;
        break;
      case RequestAccessStep.SEARCH:
        isContinueDisabled = !fullName && !patientNumber;
        continueAction = () => setStep(RequestAccessStep.SELECT);
        continueText = <FormattedMessage id="General.search" />;
        break;
      case RequestAccessStep.SELECT:
        isContinueDisabled = !selection;
        continueAction = onRequestSend;
        continueText = <FormattedMessage id="PatientAccess.sendRequest" />;
        isLoading = isMutationLoading;
        break;
    }

    return (
      <div>
        <Button className="me-2" color="primary" outline onClick={onModalClose}>
          <FormattedMessage id="General.cancel" />
        </Button>
        <LoadingButton
          type="submit"
          disabled={isContinueDisabled}
          color="primary"
          onClick={continueAction}
          isLoading={isLoading}
        >
          {continueText}
        </LoadingButton>
      </div>
    );
  };

  return (
    <Modal isOpen={isOpen} toggle={onModalClose}>
      <ModalHeader
        toggle={onModalClose}
        className="modal-without-borders__header"
      >
        <img src={nameLogo} alt="name_icon" className="me-2" />
        <FormattedMessage id={translationIdTitle} />
      </ModalHeader>
      <ModalBody className="modal-without-borders__body">
        <p>
          <FormattedMessage id="PatientAccess.accessDescription" />
        </p>
        {renderBody()}
      </ModalBody>
      <ModalFooter className="modal-without-borders__footer">
        <div className="d-flex justify-content-center">{renderFooter()}</div>
      </ModalFooter>
    </Modal>
  );
};

export default AccessRequestModal;
