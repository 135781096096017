import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { StatusEnum } from 'interfaces/enums';
import { useDebounce } from 'use-debounce';
import { MIN_SEARCH_LENGTH, REQUEST_PAGE_SIZE } from 'helpers/constants';
import useGetOrganizationMemberRequests from '../../../../api/queries/requests/useGetInfiniteOrganizationMemberRequests';
import useGetInfiniteCaseMemberRequests from '../../../../api/queries/requests/useGetInfiniteCaseMemberRequests';
import MemberAccessRequestRow from './components/MemberAccessRequestRow';
import CaseAccessRequestRow from './components/CaseAccessRequestRow';
import InputFieldWithImage, {
  InputFieldWithImageColor,
} from '../../../../components/InputFieldWithImage';
import patientsIcon from '../../../../assets/images/patients_icon.svg';
import { FormGroup, Input, Label } from 'reactstrap';
import { useInitQueries } from 'hooks/queries/useInitQuery';
import { useOrganizationPathParams } from 'hooks/paths/useOrganizationPathParams';
import TabLayout, {
  TabLayoutType,
} from '../../../../components/layouts/TabLayout';
import CustomTable from 'components/CustomTable';

const headerItems = [
  'UsersAndAccess.AccessRequestsTab.medicalPersonnel',
  'UsersAndAccess.AccessRequestsTab.participant',
  'UsersAndAccess.AccessRequestsTab.cases',
  'UsersAndAccess.AccessRequestsTab.reason',
  'UsersAndAccess.AccessRequestsTab.requestType',
  'UsersAndAccess.AccessRequestsTab.status',
  'UsersAndAccess.AccessRequestsTab.actions',
];

const AccessRequestsTab = () => {
  const { organizationId } = useOrganizationPathParams();
  const intl = useIntl();

  const [searchValue, setSearchValue] = useState('');
  const [filterStatus, setFilterStatus] = useState<StatusEnum | null>(
    StatusEnum.SENT
  );

  const [value] = useDebounce(searchValue, 500, {
    equalityFn: (_, newValue) => {
      if (newValue.length === 0) return false;
      return newValue.length < MIN_SEARCH_LENGTH;
    },
  });

  const requestsQuery = useGetOrganizationMemberRequests({
    params: {
      organizationId,
      pageSize: REQUEST_PAGE_SIZE,
      searchValue: value,
      status: filterStatus,
    },
    options: { enabled: false, staleTime: Infinity },
  });

  const caseRequestsQuery = useGetInfiniteCaseMemberRequests({
    params: {
      organizationId,
      pageSize: REQUEST_PAGE_SIZE,
      searchValue: value,
      status: filterStatus,
    },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQueries([requestsQuery, caseRequestsQuery]);

  const renderedList = useMemo(() => {
    const reqList =
      requestsQuery.requests?.map((el) => (
        <MemberAccessRequestRow
          key={el.id}
          data={el}
          onSuccess={requestsQuery.refetch}
          organizationId={organizationId}
        />
      )) ?? [];

    const caseList = caseRequestsQuery.caseRequests?.map((el) => (
      <CaseAccessRequestRow
        key={el.id}
        data={el}
        onSuccess={caseRequestsQuery.refetch}
        organizationId={organizationId}
      />
    ));

    return [...reqList, ...caseList];
  }, [
    requestsQuery.requests,
    requestsQuery.refetch,
    caseRequestsQuery.caseRequests,
    caseRequestsQuery.refetch,
    organizationId,
  ]);

  const canClearInput = !!searchValue && searchValue.trim().length > 0;

  const clearFunction = () => setSearchValue('');

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearchValue(event.target.value);

  const onStatusChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as StatusEnum | null;
    if (value) {
      setFilterStatus(value);
    } else setFilterStatus(null);
  };

  const statusOptions = Object.values(StatusEnum).map((el) => (
    <option key={el} value={el}>
      {el}
    </option>
  ));

  return (
    <TabLayout type={TabLayoutType.Generic} titlePrefix="Access.AccessRequests">
      <div className="infinite-list-header__container">
        <div className="infinite-list-header__filters">
          <InputFieldWithImage
            color={InputFieldWithImageColor.gray}
            image={<img src={patientsIcon} alt="search_icon" />}
            labelTranslationId="PatientAccess.searchLabel"
            canClear={canClearInput}
            clearFunction={clearFunction}
          >
            <Input
              className="form-control"
              onChange={onInputChange}
              placeholder={intl.formatMessage({
                id: 'PatientAccess.searchText',
              })}
              value={searchValue}
            />
          </InputFieldWithImage>
          <FormGroup>
            <Label>
              <FormattedMessage id="Projects.usersList.filterByStatus" />
            </Label>
            <Input
              type="select"
              value={filterStatus?.toString() ?? ''}
              onChange={onStatusChange}
            >
              <option value={''}>
                {intl.formatMessage({ id: 'General.all' })}
              </option>
              {statusOptions}
            </Input>
          </FormGroup>
        </div>
      </div>
      <CustomTable bodyRows={renderedList} headerItems={headerItems} />
    </TabLayout>
  );
};

export default AccessRequestsTab;
