import React, { useMemo, useState } from 'react';
import { Button, ButtonGroup } from 'reactstrap';
import classNames from 'classnames';

export type MultiStateButtonItemDefinition = {
  key: string;
  icon: JSX.Element;
  onClick?: () => void;
  onAsyncClick?: () => Promise<void>;
};

type MultiStateButtonProps = {
  items: MultiStateButtonItemDefinition[];
  selectedIndex?: number;
  disabled?: boolean;
};

const MultiStateButton = ({
  items,
  selectedIndex = 0,
  disabled = false,
}: MultiStateButtonProps) => {
  const [index, setIndex] = useState<number>(selectedIndex);

  const renderItems = useMemo(() => {
    return items.map((item, idx) => {
      const handleSelect = async () => {
        if (disabled) return;

        item.onClick?.();
        await item.onAsyncClick?.();
        setIndex(idx);
      };

      return (
        <Button
          key={item.key}
          color="primary"
          outline={index !== idx}
          onClick={handleSelect}
          active={index === idx}
          className={classNames({
            'bg-primary border-0': index === idx,
          })}
        >
          {item.icon}
        </Button>
      );
    });
  }, [disabled, index, items]);

  return <ButtonGroup className="MultiStateButton">{renderItems}</ButtonGroup>;
};

export default MultiStateButton;
