import { TrendVisualization } from 'interfaces/trendVisualizations';
import { ConversationQuestion } from 'interfaces/conversations';
import { IOrganizationObservationMeaning } from 'interfaces/observationMeanings';
import { useTrendsChartConfig } from 'components/TrendsChart/hooks/useTrendsChartConfig';
import { AVAILABLE_CULTURES } from 'components/CultureSelector/CultureSelector';
import { useChartActions } from './useChartActions';

export const useChartConfig = (
  visualization: TrendVisualization,
  questions: ConversationQuestion[],
  meanings: IOrganizationObservationMeaning[]
) => {
  const { actions, onTitleAccept } = useChartActions(visualization);

  let config = useTrendsChartConfig(questions, meanings);

  config.header = {
    title: visualization.title,
    titleTranslation: visualization.title_translations.find(
      (p) => p.culture === AVAILABLE_CULTURES.NO
    )?.value,
    onAccept: onTitleAccept,
  };

  config.actions = actions;

  return config;
};
