import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';

//types
import {
  CompleteStatuses,
  IProjectSubmissionFilters,
  SubmissionPointsFilter,
} from 'interfaces/submissions';
import { PointComparison } from 'interfaces/enums';

//helpers
import useOpen from 'hooks/useOpen';

//helpers
import {
  pointsComparisonOptions,
  projectSubmissionStatuses,
  returnSubmissionCompletionStatusString,
  submissionStatusOptions,
} from 'helpers/utils/translationObject';
import FilterInput from 'components/Filters/FilterInput';
import FilterDisplay from 'components/Filters/FilterDisplay';

const FIRST_INPUT_NAME = 'first-input';
const SECOND_INPUT_NAME = 'second-input';

type ProjectUsersFilterProps = {
  filters: IProjectSubmissionFilters | null;
  setFilters: (value: IProjectSubmissionFilters) => void;
};

const ProjectUsersFilter = ({
  filters,
  setFilters,
}: ProjectUsersFilterProps) => {
  const intl = useIntl();
  const { isOpen, toggle } = useOpen(false);

  //status filters
  const [statusFilters, setStatusFilters] = useState<string[]>([]);

  const toggleFilter = (filterName: string) => {
    setStatusFilters((currentLocalFilters) => {
      if (currentLocalFilters.includes(filterName))
        return currentLocalFilters.filter((val) => val !== filterName);
      else return [...currentLocalFilters, filterName];
    });
  };

  const onChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    toggleFilter(e.target.value);
  };

  const renderedStatusOptions = submissionStatusOptions.map(
    ({ translationId, value }) => (
      <FormGroup check key={value}>
        <Input
          id={value}
          value={value}
          checked={statusFilters.includes(value)}
          onChange={onChangeStatus}
          type="checkbox"
        />
        <Label check>{intl.formatMessage({ id: translationId })}</Label>
      </FormGroup>
    )
  );

  //points filters
  const [firstValue, setFirstValue] = useState<number | undefined>();
  const [secondValue, setSecondValue] = useState<number | undefined>();

  const [compareOption, setCompareOption] = useState<
    PointComparison | undefined
  >();

  const resetValues = useCallback(() => {
    setFirstValue(undefined);
    setSecondValue(undefined);
  }, [setFirstValue, setSecondValue]);

  const resetPoints = useCallback(() => {
    resetValues();
    setCompareOption(undefined);
  }, [resetValues, setCompareOption]);

  const onPointSelectChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    resetValues();

    if (e.target instanceof HTMLInputElement) {
      setCompareOption(e.target.value as PointComparison);
    }
  };

  const onInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const parsedValue = parseFloat(e.target.value);
    const canChangeValue =
      e.target.value === '' ||
      (!isNaN(parsedValue) &&
        parseFloat(e.target.min) <= parsedValue &&
        parseFloat(e.target.max) >= parsedValue);

    if (canChangeValue) {
      e.target.name === FIRST_INPUT_NAME && setFirstValue(parsedValue);
      e.target.name === SECOND_INPUT_NAME && setFirstValue(parsedValue);
    }
  };

  const renderedPointsOptions = pointsComparisonOptions.map(
    ({ translationId, value }) => {
      let additionalContent: JSX.Element | null = null;

      const isOptionSelected = compareOption === value;

      if (value === PointComparison.BETWEEN) {
        additionalContent = (
          <>
            <FormattedMessage id="General.and" />
            <Input
              name={SECOND_INPUT_NAME}
              type="number"
              value={isOptionSelected ? secondValue : ''}
              className="ProjectUsersFilter__option__input"
              onChange={onInputValueChange}
              disabled={!isOptionSelected}
            />
          </>
        );
      }

      return (
        <FormGroup
          className="mt-2 mb-2 d-flex align-items-center"
          key={`form-group-${value}`}
          check
        >
          <Input
            className="mt-0"
            type="radio"
            name="radio-points"
            value={value}
            checked={isOptionSelected}
            onChange={onPointSelectChange}
          />
          <Label check className="ProjectUsersFilter__option">
            <div className="d-flex align-items-center">
              <FormattedMessage id={translationId} />
              <Input
                innerRef={(input) => input && input.focus()}
                min={0}
                max={100}
                name={FIRST_INPUT_NAME}
                type="number"
                value={isOptionSelected ? firstValue : ''}
                className="ProjectUsersFilter__option__input me-1"
                onChange={onInputValueChange}
                disabled={!isOptionSelected}
              />
              {additionalContent}
            </div>
          </Label>
        </FormGroup>
      );
    }
  );

  //close filter
  const [closedFilter, setClosedFilter] = useState<
    CompleteStatuses | undefined
  >(undefined);

  const onCompleteStatusFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (e.target instanceof HTMLInputElement) {
      setClosedFilter(e.target.value as CompleteStatuses);
    }
  };

  const renderClosedOptions = projectSubmissionStatuses.map(
    ({ translationId, value }) => {
      const isOptionSelected = closedFilter === value;

      return (
        <FormGroup check key={`form-group-${value}`}>
          <Label check>
            <Input
              type="radio"
              name="radio1"
              value={value}
              checked={isOptionSelected}
              onChange={onCompleteStatusFilterChange}
            />{' '}
            <FormattedMessage id={translationId} />
          </Label>
        </FormGroup>
      );
    }
  );

  //render
  const resetFilters = () => {
    setStatusFilters([]);
    setFirstValue(undefined);
    setSecondValue(undefined);
    setCompareOption(undefined);
    setClosedFilter(CompleteStatuses.Open);
  };

  const onAcceptClick = () => {
    let pointsFilter: undefined | SubmissionPointsFilter;
    if (
      compareOption &&
      [PointComparison.ABOVE, PointComparison.BELOW].includes(compareOption) &&
      firstValue
    ) {
      pointsFilter = {
        compare: compareOption,
        value: firstValue,
      };
    } else if (
      compareOption &&
      compareOption === PointComparison.BETWEEN &&
      firstValue &&
      secondValue
    ) {
      pointsFilter = {
        compare: compareOption,
        value: firstValue,
        maxValue: secondValue,
      };
    }

    let completed: boolean | undefined;

    switch (closedFilter) {
      case CompleteStatuses.Closed:
        completed = true;
        break;
      case CompleteStatuses.Open:
        completed = false;
        break;
    }

    setFilters({
      statuses: statusFilters,
      pointsFilter,
      completed,
    });
    toggle();
  };

  const { filtersLength, filterString } = useMemo(() => {
    let pointsFilters = 0;

    let statusFiltersArray = [...(filters?.statuses ?? [])];

    if (filters?.pointsFilter) {
      pointsFilters++;
      const translationId = `General.valueCheck.${filters.pointsFilter.compare}`;

      let additionalString =
        filters.pointsFilter.compare === PointComparison.BETWEEN
          ? `${intl.formatMessage({
              id: 'General.and',
            })} ${filters.pointsFilter.maxValue}`
          : '';

      statusFiltersArray.push(
        `${intl.formatMessage({ id: translationId })} ${
          filters.pointsFilter.value
        } ${additionalString}`
      );
    }

    if (filters?.completed !== undefined)
      statusFiltersArray.push(
        intl.formatMessage({
          id: `ProjectSubmissions.CompleteStatuses.${returnSubmissionCompletionStatusString(
            filters?.completed
          )}`,
        })
      );

    return {
      filtersLength: (filters?.statuses?.length || 0) + pointsFilters,
      filterString: statusFiltersArray.join(', '),
    };
  }, [filters, intl]);

  const calculateValuesBaseOnPropFilters = useCallback(() => {
    if (filters) {
      filters.statuses &&
        filters.statuses?.length > 0 &&
        setStatusFilters(filters.statuses);

      if (filters.pointsFilter) {
        setFirstValue(filters.pointsFilter.value);
        setCompareOption(filters.pointsFilter.compare);

        if (filters.pointsFilter.compare === PointComparison.BETWEEN) {
          setSecondValue(filters.pointsFilter.maxValue);
        }
      } else {
        resetPoints();
      }

      setClosedFilter(
        returnSubmissionCompletionStatusString(filters.completed)
      );
    }
  }, [filters, resetPoints]);

  useEffect(() => {
    if (isOpen) {
      calculateValuesBaseOnPropFilters();
    }
  }, [isOpen, calculateValuesBaseOnPropFilters]);

  const filterBody = (
    <>
      <Label for="exampleCheckbox" className="FilterInput__group-header">
        <FormattedMessage id="General.status" />
      </Label>
      <div>{renderedStatusOptions}</div>
      <FormGroup tag="fieldset" className="mt-2">
        <legend className="FilterInput__group-header">
          <FormattedMessage id="Projects.filter.points" />
        </legend>
        {renderedPointsOptions}
      </FormGroup>
      <FormGroup tag="fieldset">
        <legend className="FilterInput__group-header">
          <FormattedMessage id="Projects.filter.submissionCompletion" />
        </legend>
        {renderClosedOptions}
      </FormGroup>
    </>
  );

  return (
    <FilterInput
      filterBody={filterBody}
      targetId="project-users-input-target"
      displayComponent={
        <FilterDisplay
          targetId="project-users-input-target"
          filterString={filterString}
          filtersLength={filtersLength}
        />
      }
      titleTranslationId="Projects.filter.filters"
      isOpen={isOpen}
      toggle={toggle}
      onAcceptClick={onAcceptClick}
      resetFilters={resetFilters}
    />
  );
};

export default ProjectUsersFilter;
