import { IPatchObject } from 'interfaces/request';
import { useState } from 'react';

type DescriptionParams = {
  cancelFb?: () => void;
  submitFunction: (params: IPatchObject[]) => void;
  notClearOnSubmit?: boolean;
};

function useDescriptionEdit({
  cancelFb,
  submitFunction,
  notClearOnSubmit = false,
}: DescriptionParams) {
  const [patchValues, setPatchValues] = useState<IPatchObject[]>([]);

  const clearPatchValues = () => {
    setPatchValues([]);
  };

  const onEdit = (target: string, value: string) => {
    setPatchValues((values) => {
      const valuesCopy = [...values];
      const indexOfTarget = values.findIndex(
        (value) => value.path === `/${target}`
      );

      if (indexOfTarget === -1) {
        return [
          ...valuesCopy,
          {
            op: 'replace',
            path: `/${target}`,
            value,
          },
        ];
      } else {
        valuesCopy[indexOfTarget] = {
          op: 'replace',
          path: `/${target}`,
          value,
        };

        return valuesCopy;
      }
    });
  };

  const onSubmit = () => {
    submitFunction(patchValues);
    !notClearOnSubmit && setPatchValues([]);
  };

  const onCancel = () => {
    setPatchValues([]);
    cancelFb && cancelFb();
  };

  return { onSubmit, onCancel, onEdit, patchValues, clearPatchValues };
}

export default useDescriptionEdit;
