import ReplayOutlinedIcon from '@mui/icons-material/ReplayOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import FieldInput from 'components/FieldInput';
import InputFieldWithoutImage from 'components/InputFieldWithoutImage';
import { Field, Formik } from 'formik';
import { useInitQuery } from 'hooks/queries/useInitQuery';
import { useCommonData } from 'modules/Organizations/views/OrganizationDetailsView/hooks/useCommonData';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Label } from 'reactstrap';
import IconButton from '../../../../../../../../components/IconButton';
import { useGraspGenericSettingsForm } from './hooks/useGraspGenericSettingsForm';

const GraspGenericSettingsForm = () => {
  const { graspSettings } = useCommonData();
  useInitQuery(graspSettings);

  const { initialValues, handleSubmit, validation } =
    useGraspGenericSettingsForm();

  return (
    <div className="GraspGenericSettingsForm">
      <Formik
        validate={validation}
        validateOnMount={true}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isValid, dirty, resetForm }) => {
          const handleReset = () => {
            resetForm();
          };

          return (
            <>
              <div className="d-flex flex-column">
                <div className="d-flex flex-column gap-1">
                  <div>
                    <Label className="d-flex align-items-center gap-2">
                      <Field
                        id="squeeze_end_signalling"
                        name="squeeze_end_signalling"
                        type="checkbox"
                        component={FieldInput}
                      />
                      <FormattedMessage id="Organizations.OrganizationDetails.GraspSettings.GraspGenericSettingsForm.squeeze_end_signalling" />
                    </Label>
                  </div>
                  <div>
                    <Label className="d-flex align-items-center gap-2">
                      <Field
                        id="connection_signalling"
                        name="connection_signalling"
                        type="checkbox"
                        component={FieldInput}
                      />
                      <FormattedMessage id="Organizations.OrganizationDetails.GraspSettings.GraspGenericSettingsForm.connection_signalling" />
                    </Label>
                  </div>
                  <div>
                    <Label className="d-flex align-items-center gap-2">
                      <Field
                        id="charging_signalling"
                        name="charging_signalling"
                        type="checkbox"
                        component={FieldInput}
                      />
                      <FormattedMessage id="Organizations.OrganizationDetails.GraspSettings.GraspGenericSettingsForm.charging_signalling" />
                    </Label>
                  </div>
                </div>
              </div>
              <InputFieldWithoutImage
                gray={true}
                noMargin={true}
                labelTranslationId="Organizations.OrganizationDetails.GraspSettings.GraspGenericSettingsForm.strength_feedback_duration"
                inputId="strength_feedback_duration"
              >
                <Field
                  id="strength_feedback_duration"
                  name="strength_feedback_duration"
                  type="number"
                  component={FieldInput}
                />
              </InputFieldWithoutImage>
              <InputFieldWithoutImage
                gray={true}
                noMargin={true}
                labelTranslationId="Organizations.OrganizationDetails.GraspSettings.GraspGenericSettingsForm.full_battery_percentage"
                inputId="full_battery_percentage"
              >
                <Field
                  id="full_battery_percentage"
                  name="full_battery_percentage"
                  type="number"
                  component={FieldInput}
                />
              </InputFieldWithoutImage>
              <InputFieldWithoutImage
                gray={true}
                noMargin={true}
                labelTranslationId="Organizations.OrganizationDetails.GraspSettings.GraspGenericSettingsForm.low_battery_percentage"
                inputId="low_battery_percentage"
              >
                <Field
                  id="low_battery_percentage"
                  name="low_battery_percentage"
                  type="number"
                  component={FieldInput}
                />
              </InputFieldWithoutImage>
              <div className="d-flex align-items-center gap-2">
                <IconButton
                  color="primary"
                  variant="outlined"
                  onClick={submitForm}
                  disabled={!isValid || !dirty}
                >
                  <SaveOutlinedIcon />
                </IconButton>
                <IconButton
                  color="primary"
                  variant="outlined"
                  onClick={handleReset}
                  disabled={!dirty}
                >
                  <ReplayOutlinedIcon />
                </IconButton>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default GraspGenericSettingsForm;
