import { useCallback, useState } from 'react';
import { IUserOrganizationResponse } from '../../../../../../../../../../../interfaces/membership';
import { IPatchObject } from '../../../../../../../../../../../interfaces/request';
import { UsersTableTypes } from '../types';
import { useHandleSave } from './useHandleSave';

export const useEditingRow = () => {
  const [editingRow, setEditingRow] =
    useState<UsersTableTypes.EditingRow | null>(null);

  const { saveAsync, isSaving } = useHandleSave([editingRow, setEditingRow]);

  const handleSave = useCallback(async () => {
    if (!editingRow) return;

    const excludedKeys = ['id'];
    let patchObject: IPatchObject[] = [];

    Object.entries(editingRow).forEach(([key, val]) => {
      if (excludedKeys.includes(key)) return;

      patchObject.push({
        op: 'replace',
        path: `/${key === 'user_name' ? 'short_name' : key}`,
        value: val,
      });
    });

    await saveAsync(patchObject);
  }, [editingRow, saveAsync]);

  const handleEdit = useCallback(
    (row: IUserOrganizationResponse) => () => {
      setEditingRow({
        id: row.user.id,
        first_name: row.user.first_name,
        last_name: row.user.last_name,
        email: row.user.email,
      });
    },
    []
  );

  const onEdit = useCallback((target: string, value: string) => {
    setEditingRow((prev) => {
      if (!prev) return prev;
      return { ...prev, [target]: value };
    });
  }, []);

  const handleCancel = useCallback(() => setEditingRow(null), []);

  const isCellInEditMode = useCallback(
    (id: string) => {
      return !!editingRow && editingRow?.id === id;
    },
    [editingRow]
  );

  return {
    handleSave,
    handleEdit,
    handleCancel,
    isSaving,
    isCellInEditMode,
    onEdit,
  };
};
