import { IObservation, ObservationSymptom } from 'interfaces/timeline';
import React from 'react';
import ObservationSummary from './ObservationSummary';

import { meaningOptions } from 'helpers/utils/translationObject';
import { meaningColors } from 'helpers/colors';
import { FormattedMessage } from 'react-intl';
import { returnMeaningIcon } from '../../../../../../../../../helpers/utils/icons';

type MeaningItemProps = {
  name: ObservationSymptom;
  data: IObservation[] | undefined;
  oneRow?: boolean;
};

const MeaningItem = ({ name, data, oneRow = false }: MeaningItemProps) => {
  const color = meaningColors[name];

  const translationObject = meaningOptions.find(
    (option) => option.value === name
  );

  let contentCss = 'MeaningItem__content';
  if (oneRow) contentCss += ' MeaningItem__content--one-row';

  const icon = returnMeaningIcon(name);

  return (
    <div className="MeaningItem">
      <div className="MeaningItem__header">
        {icon ? (
          <img
            className="MeaningItem__header__icon"
            src={icon}
            alt="symptom_icon"
          />
        ) : (
          <div className="MeaningItem__header__icon" />
        )}
        <div className="MeaningItem__header__title">
          {translationObject ? (
            <FormattedMessage id={translationObject.translationId} />
          ) : (
            name
          )}
        </div>
      </div>
      <div className={contentCss}>
        <ObservationSummary baseColor={color} data={data} />
      </div>
    </div>
  );
};

export default MeaningItem;
