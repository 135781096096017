import AllSqueezesChart from 'components/AllSqueezesChart';
import ExerciseTimeChart from 'components/ExerciseTimeChart';
import FeedbackChart from 'components/FeedbackChart';
import HourByHourChart from 'components/HourByHourChart';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useCommonData } from '../../hooks/useCommonData';
import DayDetailsSwitch from './components/DayDetailsSwitch';
import { useDayDetailsDate } from './hooks/useDayDetailsDate';

const DayDetails = () => {
  const { caseDetails, observationMeanings } = useCommonData();
  const { selectedDate, handlePrev, handleNext } = useDayDetailsDate();

  return (
    <div className="d-flex flex-column gap-2">
      <div className="d-flex align-items-center justify-content-between">
        <span className="fs-4">
          <FormattedMessage id="Cases.ChangeCase.ChangeCaseDashboard.DayDetails.dayDetails" />
        </span>
        <DayDetailsSwitch
          date={selectedDate}
          handleNext={handleNext}
          handlePrev={handlePrev}
        />
      </div>
      <AllSqueezesChart
        date={selectedDate}
        queries={{ caseDetails, observationMeanings }}
      />
      {caseDetails.data?.exercises_enabled && (
        <ExerciseTimeChart date={selectedDate} caseDetails={caseDetails} />
      )}
      <HourByHourChart date={selectedDate} caseDetails={caseDetails} />
      <FeedbackChart date={selectedDate} caseDetails={caseDetails} />
    </div>
  );
};

export default DayDetails;
