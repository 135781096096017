import React, { useMemo, useRef, useState } from 'react';
import { FormikProps } from 'formik';
import {
  AwareCaseDetailsFormValues,
  ProcedureActivity,
} from '../../forms/AwareCaseDetailsForm/types';
import OverlappingGroupsProvider from './providers/OverlappingGroupsProvider';
import { useMsDiffs } from './hooks/useMsDiffs';
import EventPointGroup from './components/EventPointGroup';
import { maxBy } from 'lodash';
import useOpen from '../../../../../../../../hooks/useOpen';
import ManageActivityModal from '../../modals/ManageActivityModal';

type ProcedureEventsTimelineProps = {
  startDate: string;
  endDate: string;
  form: FormikProps<AwareCaseDetailsFormValues>;
};

const ProcedureEventsTimeline = ({
  startDate,
  endDate,
  form,
}: ProcedureEventsTimelineProps) => {
  const ref = useRef<HTMLDivElement>(null);

  const { baseMsDiff, getMsDiff } = useMsDiffs(startDate, endDate);

  const manageActivity = useOpen();

  const [msPoint, setMsPoint] = useState<number | null>(null);

  const events: ProcedureActivity[] = useMemo(() => {
    let _events = form.values.procedures.flatMap((p) => p.activities);

    _events.sort((a, b) => a.timeStart.localeCompare(b.timeStart));
    return _events;
  }, [form.values.procedures]);

  const eventsPositions = useMemo(() => {
    return events.map(
      (ev) => (getMsDiff(startDate, ev.timeStart) / baseMsDiff) * 100
    );
  }, [baseMsDiff, events, getMsDiff, startDate]);

  const handleActivityOpen = (event: any) => {
    const bounds = event.target.getBoundingClientRect();
    const currentPoint = event.clientX - bounds.left;
    const maxPoint = ref.current?.clientWidth;
    if (!maxPoint) return;

    setMsPoint((currentPoint * baseMsDiff) / maxPoint);
    manageActivity.open();
  };

  const handleActivityToggle = () => {
    setMsPoint(null);
    manageActivity.close();
  };

  return (
    <>
      {manageActivity.isOpen && msPoint && (
        <ManageActivityModal
          isOpen={manageActivity.isOpen}
          toggle={handleActivityToggle}
          msPoint={msPoint}
          activityIndex={undefined}
          form={form}
        />
      )}
      <div
        ref={ref}
        onClick={handleActivityOpen}
        className="relative cursor-pointer"
      >
        <div className="bg-gray-300 w-full !h-0.5 cursor-pointer" />
        <OverlappingGroupsProvider
          startDate={startDate}
          endDate={endDate}
          procedureEvents={events}
          divRef={ref}
        >
          {(groups) => (
            <div
              className="w-full"
              style={{
                height:
                  (maxBy(groups, (group) => group.length)?.length ?? 1) * 40,
              }}
            >
              <div className="flex flex-col gap-10">
                {groups.map((group) => (
                  <EventPointGroup
                    key={group.join('')}
                    startDate={startDate}
                    group={group}
                    events={events}
                    eventsPositions={eventsPositions}
                    form={form}
                  />
                ))}
              </div>
            </div>
          )}
        </OverlappingGroupsProvider>
      </div>
    </>
  );
};

export default ProcedureEventsTimeline;
