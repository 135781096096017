import useCaseGroupConversationSchedules from '../../../../../../../../../api/queries/conversationSchedules/useCaseGroupConversationSchedules';
import useRequiredParams from '../../../../../../../../../hooks/useRequiredParams';

export const useCaseGroupConversationScheduleData = (
  treatmentPhaseId: string,
  dateFrom?: Date,
  dateTo?: Date
) => {
  const { organizationId, groupId } = useRequiredParams<{
    organizationId: string;
    groupId: string;
  }>(['organizationId', 'groupId']);

  const caseGroupSchedulesQuery = useCaseGroupConversationSchedules({
    params: {
      organizationId,
      groupId,
      dateFrom,
      dateTo,
      phaseId: treatmentPhaseId,
    },
    options: {
      enabled: false,
      staleTime: Infinity,
    },
  });

  return {
    caseGroupConversationSchedules: caseGroupSchedulesQuery,
  };
};
