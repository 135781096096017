import { IAsset } from '../../../../../../../interfaces/educationMaterials';
import { IAsyncFormHookValues } from '../../../../../../../interfaces/forms';
import { FormikErrors } from 'formik/dist/types';
import { useCallback, useMemo } from 'react';
import { buildMaterialTranslations } from '../../../../../helpers';
import { validateSubsetEmpty } from '../../../../../../../helpers/utils/validators';
import { useIntl } from 'react-intl';
import { formatObjectToIPatch } from '../../../../../../../helpers/utils/formatData';
import usePatchAsset from '../../../../../../../api/mutations/assets/usePatchAsset';
import { useCommonParams } from '../../../../../hooks/useCommonParams';
import { useCommonData } from '../../../../../hooks/useCommonData';
import { CultureEntry } from '../../../../../../../interfaces/cultures';

export type AssetModalFormValues = {
  name: string;
  description: string;
  translations: CultureEntry<{ name: string; description: string }>[];
};

export const useForm = (
  asset: IAsset
): IAsyncFormHookValues<AssetModalFormValues> => {
  const { organizationId } = useCommonParams();
  const { educationMaterials } = useCommonData();

  const intl = useIntl();

  const handleSuccess = async () => {
    await educationMaterials.refetch();
  };

  const { mutateAsync: patchAsync } = usePatchAsset({
    params: { organizationId, assetId: asset.id },
    options: { successFb: handleSuccess },
  });

  const initialValues: AssetModalFormValues = useMemo(() => {
    return {
      name: asset.name,
      description: asset.description,
      translations: buildMaterialTranslations(
        asset.name_translations,
        asset.description_translations
      ),
    };
  }, [
    asset.description,
    asset.description_translations,
    asset.name,
    asset.name_translations,
  ]);

  const handleSubmit = useCallback(
    async (values: typeof initialValues) => {
      let nameTranslations = values.translations.map((p) => ({
        culture: p.culture,
        value: p.value.name,
      }));
      let defaultCultureIndex = nameTranslations.findIndex(
        (p) => p.culture === 'en-US'
      );

      if (defaultCultureIndex !== -1) {
        nameTranslations[defaultCultureIndex].value = values.name;
      } else {
        nameTranslations.push({ culture: 'en-US', value: values.name });
      }

      let descriptionTranslations = values.translations.map((p) => ({
        culture: p.culture,
        value: p.value.description,
      }));

      defaultCultureIndex = descriptionTranslations.findIndex(
        (p) => p.culture === 'en-US'
      );

      if (defaultCultureIndex !== -1) {
        descriptionTranslations[defaultCultureIndex].value = values.description;
      } else {
        descriptionTranslations.push({
          culture: 'en-US',
          value: values.description,
        });
      }

      await patchAsync(
        formatObjectToIPatch({
          name: values.name,
          name_translations: JSON.stringify(nameTranslations),
          description: values.description,
          description_translations: JSON.stringify(descriptionTranslations),
        })
      );
    },
    [patchAsync]
  );

  const validation = useCallback(
    (values: typeof initialValues): FormikErrors<typeof initialValues> => {
      return validateSubsetEmpty<typeof initialValues>(
        values,
        ['name', 'description'],
        intl
      );
    },
    [intl]
  );

  return {
    initialValues,
    handleSubmit,
    validation,
  };
};
