import React from 'react';
import { BaseModalProps } from '../../../../../../interfaces/ui';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useSteps } from '../../../../../../hooks/useSteps';
import {
  OnCompleteCallback,
  OnCompleteCallbackContext,
  Steps,
  StepsContext,
} from './types';
import Header from './components/Header';
import Content from './components/Content';

type ImportTemplateModalProps = BaseModalProps & {
  onComplete?: OnCompleteCallback;
};

const ImportTemplateModal = ({
  isOpen,
  toggle,
  onComplete,
}: ImportTemplateModalProps) => {
  const steps = useSteps(Steps);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="GenericModal BorderlessModal"
      size="lg"
    >
      <StepsContext.Provider value={steps}>
        <ModalHeader toggle={toggle}>
          <Header />
        </ModalHeader>
        <OnCompleteCallbackContext.Provider value={onComplete}>
          <ModalBody>
            <Content />
          </ModalBody>
        </OnCompleteCallbackContext.Provider>
      </StepsContext.Provider>
    </Modal>
  );
};

export default ImportTemplateModal;
