import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/pro-light-svg-icons';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { TreeElementActionDefinition } from '../../types/Tree';
import SkeletonPlaceholderOnLoad from '../../../SkeletonPlaceholderOnLoad';
import Skeleton from 'react-loading-skeleton';
import TreeAction from '../TreeAction';
import { wrapWithSequenceIdentified } from '../../../../helpers/utils/data';

type TreeFileProps = {
  name: JSX.Element;
  isLoading?: boolean;
  icon?: IconDefinition;
  actions?: TreeElementActionDefinition[];
};

const TreeFile = ({
  name,
  isLoading = false,
  actions = [],
  icon = faFile,
}: TreeFileProps) => {
  const renderActions = wrapWithSequenceIdentified(actions).map((action) => (
    <TreeAction key={action.sequence} action={action} />
  ));

  return (
    <div className="Tree__file TreeFile">
      <div className="TreeFile__label ps-1">
        <FontAwesomeIcon icon={icon} />
        <SkeletonPlaceholderOnLoad
          isLoading={isLoading}
          placeholder={<Skeleton width={200} />}
        >
          <span>{name}</span>
        </SkeletonPlaceholderOnLoad>
      </div>
      <div className="TreeFile__actions pe-1">{renderActions}</div>
    </div>
  );
};

export default TreeFile;
