import {
  IOrganizationUserSubmission,
  SubmissionStatus,
} from 'interfaces/submissions';
import React, { useMemo } from 'react';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import ProjectUsersListRowStatusLabel from './ProjectUsersListRowStatusLabel';

type ProjectUsersListStatusDropdownProps = {
  submission: IOrganizationUserSubmission;
  handleStatusChange: (status: SubmissionStatus) => void;
};

const ProjectUsersListStatusDropdown = ({
  submission,
  handleStatusChange,
}: ProjectUsersListStatusDropdownProps) => {
  const renderSubmissionStatuses = useMemo(() => {
    return Object.values(SubmissionStatus)
      .filter((p) => p !== submission.status)
      .map((status) => {
        const handleChange = () => {
          handleStatusChange(status);
        };

        return (
          <DropdownItem onClick={handleChange} key={status}>
            <ProjectUsersListRowStatusLabel status={status} />
          </DropdownItem>
        );
      });
  }, [handleStatusChange, submission.status]);

  return (
    <UncontrolledDropdown>
      <DropdownToggle
        className="ProjectUsersList__table__dropdown d-flex align-items-center justify-content-between"
        color="link"
        block
        caret
      >
        <ProjectUsersListRowStatusLabel
          status={
            Object.values(SubmissionStatus).find(
              (p) => p === submission.status
            )!
          }
        />
      </DropdownToggle>
      <DropdownMenu>{renderSubmissionStatuses}</DropdownMenu>
    </UncontrolledDropdown>
  );
};

export default ProjectUsersListStatusDropdown;
