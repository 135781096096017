import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import IconButton from '../../../../../../../../../../../components/IconButton';
import NameField from '../components/NameField';
import RolesSelector from '../components/RolesSelector';
import { useHandleDelete } from './useHandleDelete';

export const useColumns = () => {
  const intl = useIntl();

  const deleteAsync = useHandleDelete();

  const handleDelete = useCallback(
    (id: string) => async () => await deleteAsync(id),
    [deleteAsync]
  );

  return useMemo(
    (): GridColDef[] => [
      {
        field: 'access_profile_name',
        headerName: intl.formatMessage({
          id: 'Organizations.AccessProfileName',
        }),
        flex: 1,
        renderCell: (params: GridRenderCellParams) => (
          <NameField profile={params.row} />
        ),
      },
      {
        field: 'access_profile_roles',
        headerName: intl.formatMessage({
          id: 'Organizations.AccessProfileRoles',
        }),
        flex: 1,
        renderCell: (params: GridRenderCellParams) => (
          <RolesSelector
            roles={params.row.access_profile_role_types}
            accessProfileId={params.row.id}
          />
        ),
      },
      {
        field: 'actions',
        headerName: 'Actions',
        cellClassName: 'actions',
        renderCell: (params: GridRenderCellParams) => (
          <IconButton
            color="primary"
            variant="outlined"
            onClick={handleDelete(params.row.id)}
          >
            <DeleteOutlineOutlinedIcon />
          </IconButton>
        ),
      },
    ],
    [handleDelete, intl]
  );
};
