import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import {
  predefinedChartModes,
  SqueezeStrengthType,
} from './SqueezeStrengthChart';

type SqueezeStrengthChartLegendProps = {
  selectedSqueezeStrengthType: SqueezeStrengthType;
  setSelectedSqueezeStrengthType: (type: SqueezeStrengthType) => void;
};

const SqueezeStrengthChartLegend = ({
  selectedSqueezeStrengthType,
  setSelectedSqueezeStrengthType,
}: SqueezeStrengthChartLegendProps) => {
  const returnLegendItem = Object.entries(SqueezeStrengthType).map(
    ([_, value], index) => {
      const handleChangeStrengthType = () => {
        setSelectedSqueezeStrengthType(value);
      };

      const getChartLegendIndicatorClassName = () => {
        switch (value) {
          case SqueezeStrengthType.MIN_STRENGTH:
            return 'min';
          case SqueezeStrengthType.AVG_STRENGTH:
            return 'avg';
          case SqueezeStrengthType.MAX_STRENGTH:
            return 'max';
        }
      };

      return (
        <li
          onClick={handleChangeStrengthType}
          className={classNames(
            { SqueezeStrengthChartLegend__item: true },
            {
              'SqueezeStrengthChartLegend__item--active':
                selectedSqueezeStrengthType === value,
            }
          )}
        >
          <div
            className={`SqueezeStrengthChartLegend__item__indicator SqueezeStrengthChartLegend__item__indicator--${getChartLegendIndicatorClassName()}`}
          ></div>
          <FormattedMessage id={predefinedChartModes[index].translationId} />
        </li>
      );
    }
  );

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="mb-2">
        <FormattedMessage id="TimelineDetails.TrendsTimeline.SqueezeStrengthChart.xAxis" />
      </div>
      <ul className="SqueezeStrengthChartLegend">{returnLegendItem}</ul>
    </div>
  );
};

export default SqueezeStrengthChartLegend;
