import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTools } from '@fortawesome/pro-light-svg-icons';
import { useHandleAssign } from './hooks/useHandleAssign';
import { Button } from 'reactstrap';

type AssignOrganizationActionProps = {
  onResolve: () => void;
};

const AssignOrganizationAction = ({
  onResolve,
}: AssignOrganizationActionProps) => {
  const handleAssign = useHandleAssign(onResolve);

  return (
    <Button color="primary" outline={true} onClick={handleAssign}>
      <FontAwesomeIcon icon={faTools} />
    </Button>
  );
};

export default AssignOrganizationAction;
