import CustomCalendarInput from 'components/CustomCalendarInput';
import FilterDisplay from 'components/Filters/FilterDisplay';
import FilterInput from 'components/Filters/FilterInput';
import { formatDate, formattedDateToDateInput } from 'helpers/dates';
import { graspStatusOptions } from 'helpers/utils/translationObject';
import useOpen from 'hooks/useOpen';
import { GraspFilters, GraspStatus } from 'interfaces/grasps';
import { LocationItem } from 'interfaces/membership';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormGroup, Input, Label } from 'reactstrap';

type GraspFilterProps = {
  filters: GraspFilters;
  onFilterChange: (filters: GraspFilters) => void;
  endpoints: LocationItem[];
};

const GraspFilter = ({
  filters,
  onFilterChange,
  endpoints,
}: GraspFilterProps) => {
  const intl = useIntl();
  const { isOpen, toggle } = useOpen(false);

  const [statuses, setStatuses] = useState<GraspStatus[]>([]);
  const [fromDate, setFromDate] = useState<null | string>(null);
  const [endDate, setEndDate] = useState<null | string>(null);
  const [endpoint, setEndpoint] = useState<null | string>(null);

  const toggleFilter = (filterName: string) => {
    setStatuses((currentLocalFilters) => {
      let newStatus = filterName as GraspStatus;

      if (currentLocalFilters.includes(newStatus))
        return currentLocalFilters.filter((val) => val !== newStatus);
      else return [...currentLocalFilters, newStatus];
    });
  };

  const onChangeStatus = (e: ChangeEvent<HTMLInputElement>) => {
    toggleFilter(e.target.value);
  };

  const renderedStatusOptions = graspStatusOptions.map(
    ({ translationId, value }) => {
      return (
        <FormGroup check key={value}>
          <Input
            id={value}
            value={value}
            checked={statuses.includes(value as GraspStatus)}
            onChange={onChangeStatus}
            type="checkbox"
          />
          <Label check>{intl.formatMessage({ id: translationId })}</Label>
        </FormGroup>
      );
    }
  );

  const resetFilters = () => {
    setStatuses([]);
    setFromDate(null);
    setEndDate(null);
  };

  const onEndChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
  };

  const onFromChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFromDate(e.target.value);
  };

  const onEndpointChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEndpoint(e.target.value);
  };

  const endpointOptions = endpoints.map(({ id, name }) => (
    <option key={id} value={id}>
      {name}
    </option>
  ));

  const filterBody = (
    <>
      <FormGroup>
        <Label className="FilterInput__group-header">
          <FormattedMessage id="General.status" />
        </Label>
        {renderedStatusOptions}
      </FormGroup>
      <FormGroup>
        <Label className="FilterInput__group-header">
          <FormattedMessage id="General.dateFrom" />
        </Label>
        <CustomCalendarInput onChange={onFromChange} value={fromDate} />
      </FormGroup>
      <FormGroup>
        <Label className="FilterInput__group-header">
          {' '}
          <FormattedMessage id="General.dateTo" />
        </Label>
        <CustomCalendarInput onChange={onEndChange} value={endDate} />
      </FormGroup>
      <FormGroup>
        <Label>Endpoint</Label>
        <Input type="select" value={endpoint ?? ''} onChange={onEndpointChange}>
          <option value=""></option>
          {endpointOptions}
        </Input>
      </FormGroup>
    </>
  );

  const filterArray = useMemo(() => {
    const filterString: string[] =
      filters?.statuses?.map((el) =>
        intl.formatMessage({
          id: `MyOrganizations.grasps.statuses.${el}`,
        })
      ) ?? [];

    if (filters.fromDate) filterString.push(`From: ${filters.fromDate}`);
    if (filters.toDate) filterString.push(` To: ${filters.toDate}`);

    return filterString;
  }, [filters, intl]);

  const filtersLength = filterArray.length;
  const filterString = filterArray.join(',');

  const onAcceptClick = () => {
    let localFilters: GraspFilters = {};

    if (statuses.length > 0) {
      localFilters = {
        statuses,
      };
    }

    if (fromDate) {
      localFilters = {
        ...localFilters,
        fromDate: new Date(fromDate).toISOString(),
      };
    }

    if (endDate) {
      localFilters = {
        ...localFilters,
        toDate: new Date(endDate).toISOString(),
      };
    }

    if (endpoint) {
      localFilters = {
        ...localFilters,
        endpoint,
      };
    }

    onFilterChange(localFilters);
    toggle();
  };

  const calculateValuesBaseOnPropFilters = useCallback(() => {
    if (filters) {
      filters.statuses &&
        filters.statuses?.length > 0 &&
        setStatuses(filters.statuses);

      filters.fromDate &&
        setFromDate(
          formattedDateToDateInput(
            formatDate(filters.fromDate, {
              addSeconds: false,
              ignoreTime: true,
            })
          )
        );

      filters.toDate &&
        setFromDate(
          formattedDateToDateInput(
            formatDate(filters.toDate, {
              addSeconds: false,
              ignoreTime: true,
            })
          )
        );
    }
  }, [filters]);

  useEffect(() => {
    if (isOpen) {
      calculateValuesBaseOnPropFilters();
    }
  }, [isOpen, calculateValuesBaseOnPropFilters]);

  return (
    <FilterInput
      isOpen={isOpen}
      toggle={toggle}
      resetFilters={resetFilters}
      filterBody={filterBody}
      targetId="grasp-input-target"
      displayComponent={
        <FilterDisplay
          targetId="grasp-input-target"
          filterString={filterString}
          filtersLength={filtersLength}
        />
      }
      titleTranslationId="Projects.filter.filters"
      onAcceptClick={onAcceptClick}
    />
  );
};

export default GraspFilter;
