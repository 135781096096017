import {
  ISqueezeChartData,
  ObservationSymptom,
} from '../../../interfaces/timeline';
import { ChartsData } from '../types';
import { removeRedundant } from './common';

export const appendDataFromSeries = (
  observations: ISqueezeChartData,
  data: ChartsData<string | number>[]
): ChartsData<string | number>[] => {
  const xAxis = observations.x_axis;
  const allObservationsSeries = observations.series[3].data as number[];
  const seriesWithMeaningId = observations.series.filter(
    (p) => p.meaning_id !== null
  );

  xAxis.forEach((date, index) => {
    const meaningsValues = seriesWithMeaningId.map(
      (series) => series.data[index] as number
    );

    const sumOfMeaningsValues = meaningsValues.reduce(
      (acc: number, curr: any) => acc + curr,
      0
    );

    const notSpecifiedDiff = allObservationsSeries[index] - sumOfMeaningsValues;
    const notSpecifiedValue = notSpecifiedDiff > 0 ? 0 : notSpecifiedDiff;

    let entry: ChartsData<string | number> = {
      name: date,
      [ObservationSymptom.NotSpecified]: notSpecifiedValue,
    };

    seriesWithMeaningId.forEach((series) => {
      entry[series.meaning_id as string] = series.data[index] as number;
    });

    data.push(entry);
  });

  return data;
};

export const removeRedundantNotSpecified = (
  data: ChartsData<string | number>[]
) => {
  return removeRedundant(data, ObservationSymptom.NotSpecified);
};
