import {
  ClosedListValidConversationQuestion,
  ClosedListValidConversationQuestionAnswer,
  ConversationTemplate,
  ConversationTemplateContext,
  ConversationTemplateItemType,
  ConversationTemplateTriggerType,
  EmailValidConversationQuestion,
  HeaderValidConversationQuestion,
  IConversationTemplate,
  IConversationTemplateItem,
  IUpsertConversationTemplateItem,
  OpenValidConversationQuestion,
  RangeValidConversationQuestion,
  RangeValidConversationQuestionLegendDescription,
  TimeValidConversationQuestion,
  ValidConversationQuestion,
  ValidConversationQuestionType,
  ValidConversationTemplateItem,
  ValidConversationTemplateItemType,
} from '../../interfaces/conversations';
import { ValueInterpretationType } from '../../interfaces/change';
import { getSnakeCase } from '../utils/strings';
import { v4 as uuid } from 'uuid';
import { cloneDeep } from 'lodash';
import {
  lowercaseKeys,
  rebuildConversationQuestionsBody,
} from '../utils/conversationTemplates';

export const toConversationTemplate = (
  template: IConversationTemplate
): ConversationTemplate => {
  return {
    id: template.id,
    organization_id: template.organizationId,
    last_resort_template: template.lastResortTemplate,
    context: template.context ?? ConversationTemplateContext.UserConversation,
    trigger_type: template.triggerType ?? ConversationTemplateTriggerType.Time,
    display_code: template.displayCode,
    content: getConversationTemplateContent(template.content),
  };
};

const getConversationTemplateContent = (
  content: IConversationTemplateItem[]
) => {
  let items = cloneDeep(content);
  let questionRebuildItems = rebuildConversationQuestionsBody(items);
  let rebuildItems = rebuildPlainTextValueBody(questionRebuildItems);

  return convertToValidTemplateItems(rebuildItems);
};

const convertToValidTemplateItems = (
  items: IUpsertConversationTemplateItem[]
): ValidConversationTemplateItem[] => {
  let validItems: ValidConversationTemplateItem[] = [];

  items.forEach((item) => {
    switch (item.type) {
      case ConversationTemplateItemType.Delimiter:
        validItems.push({
          type: ValidConversationTemplateItemType.Delimiter,
          condition: item.condition,
          visible: true,
          persist: false,
          apply_typing_effect: item.applyTypingEffect,
        });
        break;
      case ConversationTemplateItemType.Education:
        validItems.push({
          type: ValidConversationTemplateItemType.Education,
          condition: item.condition,
          visible: true,
          apply_typing_effect: item.applyTypingEffect,
          resource_id: item.resourceId ?? '',
        });
        break;
      case ConversationTemplateItemType.Feedback:
        validItems.push({
          type: ValidConversationTemplateItemType.Feedback,
          condition: item.condition,
          visible: true,
          apply_typing_effect: item.applyTypingEffect,
          group_name:
            getCaseInvariantPropertyValue(item.value, 'feedbackGroupName') ??
            '',
        });
        break;
      case ConversationTemplateItemType.GraspUpload:
        validItems.push({
          type: ValidConversationTemplateItemType.GraspUpload,
          condition: item.condition,
          visible: true,
          apply_typing_effect: item.applyTypingEffect,
        });
        break;
      case ConversationTemplateItemType.MaterialsList:
        validItems.push({
          type: ValidConversationTemplateItemType.MaterialsList,
          condition: item.condition,
          visible: true,
          apply_typing_effect: item.applyTypingEffect,
        });
        break;
      case ConversationTemplateItemType.Question:
        validItems.push({
          type: ValidConversationTemplateItemType.Question,
          apply_typing_effect: item.applyTypingEffect,
          visible: true,
          condition: item.condition,
          resource_id: getCaseInvariantPropertyValue(item.value, 'guid') ?? '',
          value: getValidConversationQuestion(
            item.value.type as ValidConversationQuestionType,
            item.value
          ),
        });
        break;
      case ConversationTemplateItemType.NotificationsList:
        validItems.push({
          type: ValidConversationTemplateItemType.NotificationsList,
          condition: item.condition,
          visible: true,
          apply_typing_effect: item.applyTypingEffect,
        });
        break;
      case ConversationTemplateItemType.PlainText:
        validItems.push({
          type: ValidConversationTemplateItemType.PlainText,
          condition: item.condition,
          apply_typing_effect: item.applyTypingEffect,
          visible: true,
          value: {
            text: getCaseInvariantPropertyValue(item.value, 'text') ?? '',
            text_nb_no:
              getCaseInvariantPropertyValues(item.value, [
                'text_NbNo',
                'text_nbNo',
                'text_nbno',
              ]) ?? '',
            text_html: getCaseInvariantPropertyValue(item.value, 'textHtml'),
            text_html_nb_no:
              getCaseInvariantPropertyValues(item.value, [
                'textHtml_NbNo',
                'textHtml_nbNo',
                'textHtml_nbno',
              ]) ?? '',
          },
        });
        break;
    }
  });

  return validItems;
};

const rebuildPlainTextValueBody = (
  items: IConversationTemplateItem[] | IUpsertConversationTemplateItem[]
) => {
  items = items.map(
    (item: IConversationTemplateItem | IUpsertConversationTemplateItem) => {
      if (item.type !== ConversationTemplateItemType.PlainText) return item;
      item.value = lowercaseKeys(item.value);

      return item;
    }
  );

  return items;
};

export const getValidConversationQuestion = (
  type: ValidConversationQuestionType,
  content: any
): ValidConversationQuestion => {
  switch (type) {
    case ValidConversationQuestionType.ClosedList:
      return getValidClosedListQuestion(content);
    case ValidConversationQuestionType.Time:
      return getValidTimeQuestion(content);
    case ValidConversationQuestionType.Email:
      return getValidEmailQuestion(content);
    case ValidConversationQuestionType.Range:
      return getValidRangeQuestion(content);
    case ValidConversationQuestionType.Open:
      return getValidOpenQuestion(content);
    case ValidConversationQuestionType.Header:
      return getValidHeaderQuestion(content);
  }
};

export const getEmptyConversationTemplate = (
  organizationId: string
): ConversationTemplate => {
  return {
    id: uuid(),
    organization_id: organizationId,
    trigger_type: ConversationTemplateTriggerType.Time,
    context: ConversationTemplateContext.UserConversation,
    display_code: '',
    content: [],
    last_resort_template: false,
  };
};

const getValidBaseQuestion = (
  content: any
): Omit<ValidConversationQuestion, 'type'> => {
  return {
    guid: getCaseInvariantPropertyValue(content, 'guid') ?? '',
    sequence: getCaseInvariantPropertyValue(content, 'sequence') ?? 0,
    title: getCaseInvariantPropertyValue(content, 'title') ?? '',
    title_nb_no:
      getCaseInvariantPropertyValue(content, 'titleNbNo') ??
      getCaseInvariantPropertyValue(content, 'title_NbNo'),
    title_html: getCaseInvariantPropertyValue(content, 'titleHtml'),
    title_html_nb_no:
      getCaseInvariantPropertyValue(content, 'titleHtmlNbNo') ??
      getCaseInvariantPropertyValue(content, 'titleHtml_NbNo'),
    short_title: getCaseInvariantPropertyValue(content, 'shortTitle') ?? '',
    short_title_nb_no: getCaseInvariantPropertyValue(content, 'shortTitleNbNo'),
    short_title_html: getCaseInvariantPropertyValue(content, 'shortTitleHtml'),
    short_title_html_nb_no: getCaseInvariantPropertyValue(
      content,
      'shortTitleHtmlNbNo'
    ),
    description: getCaseInvariantPropertyValue(content, 'description'),
    description_nb_no: getCaseInvariantPropertyValue(
      content,
      'descriptionNbNo'
    ),
    description_html_nb_no: getCaseInvariantPropertyValue(
      content,
      'descriptionHtmlNbNo'
    ),
    description_html: getCaseInvariantPropertyValue(content, 'descriptionHtml'),
    color: getCaseInvariantPropertyValue(content, 'color') ?? '#000',
    included_in_trends:
      getCaseInvariantPropertyValue(content, 'includedInTrends') ?? true,
    value_interpretation:
      getCaseInvariantPropertyValue(content, 'valueInterpretation') ??
      ValueInterpretationType.NoInterpretation,
    show_title: getCaseInvariantPropertyValue(content, 'showTitle') ?? true,
    show_description:
      getCaseInvariantPropertyValue(content, 'showDescription') ?? true,
    required: getCaseInvariantPropertyValue(content, 'required') ?? true,
  };
};

const getValidClosedListQuestion = (
  content: any
): ClosedListValidConversationQuestion => {
  const answerValues = getCaseInvariantPropertyValue<any[]>(content, 'answers');

  const answers: ClosedListValidConversationQuestionAnswer[] = (
    answerValues ?? []
  ).map(
    (item): ClosedListValidConversationQuestionAnswer => ({
      guid: getCaseInvariantPropertyValue(item, 'guid') ?? '',
      title: getCaseInvariantPropertyValue(item, 'title') ?? '',
      sequence: getCaseInvariantPropertyValue(item, 'sequence') ?? 0,
      is_selected: getCaseInvariantPropertyValue(item, 'isSelected') ?? false,
      title_nb_no:
        getCaseInvariantPropertyValues(item, [
          'titleNbNo',
          'title_NbNo',
          'title_nbno',
        ]) ?? '',
      description: getCaseInvariantPropertyValue(item, 'description'),
      description_nb_no:
        getCaseInvariantPropertyValues(item, [
          'descriptionNbNo',
          'description_NbNo',
          'description_nbno',
        ]) ?? '',
    })
  );

  return {
    type: ValidConversationQuestionType.ClosedList,
    ...getValidBaseQuestion(content),
    answers: answers,
  };
};

const getValidTimeQuestion = (content: any): TimeValidConversationQuestion => {
  return {
    type: ValidConversationQuestionType.Time,
    ...getValidBaseQuestion(content),
    min_time_value: getCaseInvariantPropertyValue(content, 'minTimeValue'),
    max_time_value: getCaseInvariantPropertyValue(content, 'maxTimeValue'),
    answer_max_length: getCaseInvariantPropertyValue(
      content,
      'answerMaxLength'
    ),
    answer_placeholder: getCaseInvariantPropertyValue(
      content,
      'answerPlaceholder'
    ),
    answer: {
      time_value: content.answer
        ? getCaseInvariantPropertyValue(content.answer, 'timeValue') ?? null
        : null,
    },
  };
};

const getValidEmailQuestion = (
  content: any
): EmailValidConversationQuestion => {
  return {
    type: ValidConversationQuestionType.Email,
    ...getValidBaseQuestion(content),
    answer_max_length: getCaseInvariantPropertyValue(
      content,
      'answerMaxLength'
    ),
    answer_placeholder: getCaseInvariantPropertyValue(
      content,
      'answerPlaceholder'
    ),
    answer: {
      text_value: content.answer
        ? getCaseInvariantPropertyValue(content.answer, 'textValue') ?? null
        : null,
    },
  };
};

const getValidRangeQuestion = (
  content: any
): RangeValidConversationQuestion => {
  const legendDescriptionValues = getCaseInvariantPropertyValue<any[]>(
    content,
    'legendDescriptions'
  );

  const legendDescriptions = (legendDescriptionValues ?? []).map(
    (item): RangeValidConversationQuestionLegendDescription => ({
      label: getCaseInvariantPropertyValue(item, 'label') ?? '',
      value: getCaseInvariantPropertyValue(item, 'value') ?? 0,
      label_nb_no:
        getCaseInvariantPropertyValue(item, 'labelNbNo') ??
        getCaseInvariantPropertyValue(item, 'label_NbNo') ??
        '',
    })
  );

  return {
    type: ValidConversationQuestionType.Range,
    ...getValidBaseQuestion(content),
    min_number_value: getCaseInvariantPropertyValue(content, 'minNumberValue'),
    max_number_value: getCaseInvariantPropertyValue(content, 'maxNumberValue'),
    min_date_value: getCaseInvariantPropertyValue(content, 'minDateValue'),
    max_date_value: getCaseInvariantPropertyValue(content, 'maxDateValue'),
    legend_descriptions: legendDescriptions,
    answer: {
      number_value: content.answer
        ? getCaseInvariantPropertyValue(content.answer, 'numberValue') ?? null
        : null,
      date_value: content.answer
        ? getCaseInvariantPropertyValue(content.answer, 'dateValue') ?? null
        : null,
    },
  };
};

const getValidOpenQuestion = (content: any): OpenValidConversationQuestion => {
  return {
    type: ValidConversationQuestionType.Open,
    ...getValidBaseQuestion(content),
    answer_max_length: getCaseInvariantPropertyValue(
      content,
      'answerMaxLength'
    ),
    answer_placeholder: getCaseInvariantPropertyValue(
      content,
      'answerPlaceholder'
    ),
    answer: {
      text_value: content.answer
        ? getCaseInvariantPropertyValue(content.answer, 'textValue') ?? null
        : null,
    },
  };
};

const getValidHeaderQuestion = (
  content: any
): HeaderValidConversationQuestion => {
  return {
    type: ValidConversationQuestionType.Header,
    ...getValidBaseQuestion(content),
  };
};

export const getCaseInvariantPropertyValues = <T>(
  content: any,
  camelCaseVariations: string[]
): T | undefined => {
  let caseInvariantValue: T | undefined = undefined;

  for (let variation of camelCaseVariations) {
    caseInvariantValue = getCaseInvariantPropertyValue(content, variation);
    if (caseInvariantValue) return caseInvariantValue;
  }

  return caseInvariantValue;
};

export const getCaseInvariantPropertyValue = <T>(
  content: any,
  camelCaseName: string
): T | undefined => {
  const snakeCaseName = getSnakeCase(camelCaseName);
  const upperCamelCaseName = `${camelCaseName[0].toUpperCase()}${camelCaseName.substring(
    1
  )}`;

  if (content[snakeCaseName]) return content[snakeCaseName];
  if (content[camelCaseName]) return content[camelCaseName];
  return content[upperCamelCaseName];
};
