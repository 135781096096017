import useOpen from '../../../hooks/useOpen';
import { useCallback, useState } from 'react';
import { AVAILABLE_CULTURES } from '../../CultureSelector/CultureSelector';

export type ApplicableInputHookValues = {
  editMode: boolean;
  onAccept: (value: string, culture: AVAILABLE_CULTURES) => void;
  toggle: () => void;
  onChange: (value: string, target: string) => void;
  onClose: () => void;
  onUndo: () => void;
  value: string;
};

export const useApplicableInput = (
  initialValue?: string,
  onAccept?: (value: string, culture: AVAILABLE_CULTURES) => void
): ApplicableInputHookValues => {
  const [inputValue, setInputValue] = useState<string>(initialValue ?? '');

  const { isOpen: isEditMode, toggle: toggleEditMode } = useOpen();

  const onUndo = useCallback(
    () => setInputValue(initialValue ?? ''),
    [initialValue]
  );

  const onClose = useCallback(() => {
    onUndo();
    toggleEditMode();
  }, [onUndo, toggleEditMode]);

  const accept = useCallback(
    (value: string, culture: AVAILABLE_CULTURES) => {
      toggleEditMode();
      onAccept?.(value, culture);
    },
    [onAccept, toggleEditMode]
  );

  const onChange = useCallback((target: string, value: string) => {
    setInputValue(value);
  }, []);

  return {
    editMode: isEditMode,
    onAccept: accept,
    toggle: toggleEditMode,
    onChange,
    onClose,
    onUndo,
    value: inputValue,
  };
};
