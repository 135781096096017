import React from 'react';
import { FormattedMessage } from 'react-intl';
import { DateRange } from 'react-day-picker';
import { TimelineTabsProps } from 'interfaces/timeline';
import PeriodSummaryChart from './PeriodSummaryChart';

//icons
import SqueezeHeaderIcon from 'assets/images/timeline_details/squeeze_header.svg';

interface SqueezePieChartProps extends TimelineTabsProps {
  dateRangeObject: DateRange | null;
  minHeight?: string;
}

const SqueezeSummaryPieChart = ({
  dateRangeObject,
  timelineId,
}: SqueezePieChartProps) => {
  return (
    <>
      <div className="cardContainer d-flex flex-column">
        <div className="d-flex align-items-center">
          <div className="cardContainer__header__title">
            <img
              src={SqueezeHeaderIcon}
              alt="squeeze_header_icon"
              className="me-2"
            />
            <FormattedMessage id="General.squeezeSummary" />
          </div>
        </div>
        <div className="SqueezeSummary__container">
          {dateRangeObject?.from && dateRangeObject.to && (
            <PeriodSummaryChart
              selectedDates={{
                from: dateRangeObject.from,
                to: dateRangeObject.to,
              }}
              timelineId={timelineId}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default SqueezeSummaryPieChart;
