import useCaseDelete from 'api/mutations/cases/useCaseDelete';
import useCaseEdit from 'api/mutations/cases/useCaseEdit';
import useCaseCategories from 'api/queries/cases/useCaseCategories';
import bellIcon from 'assets/images/case_details/bell.svg';
import bubbles from 'assets/images/timeline_details/bubbles.svg';
import classNames from 'classnames';
import CaseTimelineHeader from 'components/CaseTimelineHeader';
import ConfirmModal from 'components/ConfirmModal';
import ExpandableTextareaField from 'components/ExpandableTextareaField';

//components
import HeaderDetail from 'components/HeaderDetail';
import InputWithDisabledDivDisplay from 'components/InputWithDisabledDivDisplay';
import SkeletonPlaceholderOnLoad from 'components/SkeletonPlaceholderOnLoad';

//helpers
import { statusDefinition } from 'helpers/utils/translationObject';

//helpers
import useDescriptionEdit from 'hooks/useDescriptionEdit';
import useOpen from 'hooks/useOpen';
import { ICaseDetails } from 'interfaces/cases';

//types
import { IPatchObject } from 'interfaces/request';
import { ITimelineExtended } from 'interfaces/timeline';
import HeaderSummary from 'modules/TimelineDetails/components/HeaderSummary';
import React, { ChangeEvent, useEffect, useState } from 'react';

//images
import SVG from 'react-inlinesvg';
import { FormattedMessage, useIntl } from 'react-intl';
import { QueryObserverResult, RefetchOptions } from 'react-query';
import { useNavigate } from 'react-router';
import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Row,
} from 'reactstrap';
import CaseHistoryModal from '../../../../../../modals/CaseHistoryModal';
import CaseDetailName from './components/CaseDetailName';
import CategorySelect from './components/CategorySelect';
import SubCategorySelect from './components/SubCategorySelect';

type CaseDetailsHeaderProps = {
  organizationId: string;
  caseId: string;
  data: ICaseDetails | undefined;
  isLoading: boolean;
  refetchCase: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<ICaseDetails>>;
  selectedTimeline: ITimelineExtended | null;
  onTimelineChange: (e: ChangeEvent<HTMLInputElement>) => void;
};

function convertEditedValuesToModalProps(
  params: IPatchObject[],
  selectedCategory: string
) {
  let values: Object = {};

  params.forEach((param) => {
    const { value } = param;

    if (param.path === '/category') {
      values = {
        ...values,
        new_category: value,
      };
      return;
    }

    if (param.path === '/sub_category') {
      values = {
        ...values,
        category_reference: selectedCategory,
        new_sub_category: value,
      };
      return;
    }

    if (param.path === '/status') {
      values = {
        ...values,
        new_status: value,
      };
    }
  });

  return values;
}

const CaseDetailsHeader = ({
  organizationId,
  caseId,
  data,
  isLoading,
  refetchCase,
  selectedTimeline,
  onTimelineChange,
}: CaseDetailsHeaderProps) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const { data: caseCategories } = useCaseCategories({
    params: { organizationId },
  });

  const deleteSuccessFb = () => {
    navigate(`/organizations/${organizationId}/cases`);
  };

  const { mutate: deleteMutation } = useCaseDelete(
    {
      organizationId: organizationId,
      caseId: caseId,
    },
    {
      successFb: deleteSuccessFb,
    }
  );

  const { toggle: toggleDeleteModal, isOpen: isDeleteModalOpen } =
    useOpen(false);

  const onDelete = () => {
    deleteMutation();
  };

  //edit
  const { isOpen: isEdited, toggle: toggleEdit, close: cancelEdit } = useOpen();

  const [selectedCategory, setSelectedCategory] = useState(
    data?.category.code ?? ''
  );
  useEffect(() => {
    if (data) setSelectedCategory(data?.category.code);
  }, [data]);

  const {
    isOpen: isEditModalOpen,
    open: openEditModal,
    close: closeEditModal,
  } = useOpen();
  const [editedValues, setEditedValues] = useState({});

  const onSaveClick = (params: IPatchObject[]) => {
    if (selectedCategory) {
      let values = convertEditedValuesToModalProps(params, selectedCategory);
      setEditedValues(values);
      openEditModal();
    }
  };

  const {
    onCancel,
    onEdit,
    onSubmit: triggerSave,
    patchValues,
    clearPatchValues,
  } = useDescriptionEdit({
    cancelFb: toggleEdit,
    submitFunction: onSaveClick,
    notClearOnSubmit: true,
  });

  const onEditCancel = () => {
    onCancel();
    closeEditModal();
  };

  const editSuccessFb = async () => {
    clearPatchValues();
    await refetchCase();
    cancelEdit();
  };

  const errorFb = () => {
    if (data) setSelectedCategory(data.category.code);
  };

  const { mutate: editMutation } = useCaseEdit(
    {
      organizationId,
      caseId,
    },
    {
      successFb: editSuccessFb,
      errorFb: errorFb,
    }
  );

  const onEditConfirm = () => {
    const fixedParams = patchValues.map((param) => {
      if (param.path === '/category')
        return {
          ...param,
          path: '/category_id',
        };
      else if (param.path === '/sub_category') {
        return {
          ...param,
          path: '/sub_category_id',
        };
      } else return param;
    });

    editMutation(fixedParams);
  };

  const onCategoryEdit = (target: string, value: string) => {
    setSelectedCategory(value);
    onEdit(target, value);

    const selectedCategory = caseCategories?.find(
      (category) => category.code === value
    );
    if (selectedCategory) {
      let isOnlyOneSubCategory = selectedCategory.sub_categories.length === 1;

      onEdit(
        'sub_category',
        isOnlyOneSubCategory ? selectedCategory.sub_categories[0].code : ''
      );
    }
  };

  //render
  const participant = data?.participants[0].personality;
  const participant_name = `${participant?.first_name} ${participant?.last_name}`;

  const timelinesOptions = data?.timelines.map(
    ({ id, name, organization, is_master }) => {
      if (is_master) {
        return (
          <option value={id} key={id}>
            {intl.formatMessage({
              id: 'CaseDetails.header.aggregateTimelineDescription',
            })}
          </option>
        );
      }

      if (organization) {
        return (
          <option value={id} key={id}>
            {`${name} at ${organization.name}`}
          </option>
        );
      }

      return (
        <option value={id} key={id}>
          {name}
        </option>
      );
    }
  );

  const [isHeaderHidden, setHeaderHidden] = useState(true);
  const onHideClick = () => setHeaderHidden((val) => !val);

  const hiddenClass = isHeaderHidden ? 'CaseTimelineHeader__hidden' : '';

  const onSave = () => {
    triggerSave();
  };

  const renderHeaderContent = () => {
    if (isHeaderHidden) {
      return (
        <CaseDetailName
          isLoading={isLoading}
          isHeaderHidden={isHeaderHidden}
          caseTitle={data?.title}
          userName={participant_name}
          patientId={participant?.id}
        />
      );
    } else {
      return (
        <>
          <Col md={6} sm={12}>
            <Row>
              <Col md={12} lg={6} xl={{ size: 5 }}>
                <CaseDetailName
                  isLoading={isLoading}
                  isHeaderHidden={isHeaderHidden}
                  caseTitle={data?.title}
                  userName={participant_name}
                  patientId={participant?.id}
                />
                <SkeletonPlaceholderOnLoad isLoading={isLoading}>
                  <InputWithDisabledDivDisplay
                    divCssClass="CaseHeader__title"
                    inputCssClass="mt-1"
                    disabled={!isEdited}
                    type="text"
                    value={data?.title ?? ''}
                    name="title"
                    aria-label="title"
                    onEdit={onEdit}
                  />
                </SkeletonPlaceholderOnLoad>
                <FormGroup className={hiddenClass}>
                  <SkeletonPlaceholderOnLoad isLoading={isLoading}>
                    <ExpandableTextareaField
                      divCssClass="CaseHeader__description"
                      inputCssClass="CaseHeader__description--textarea"
                      disabled={!isEdited}
                      value={data?.description ?? ''}
                      name="description"
                      aria-label="description"
                      onEdit={onEdit}
                    />
                  </SkeletonPlaceholderOnLoad>
                </FormGroup>
                <FormGroup>
                  <Label htmlFor="selected-timeline">
                    <FormattedMessage id="CaseCard.selectTimeline" />
                  </Label>
                  <InputGroup className="CaseHeader__timeline">
                    <InputGroupText className="transparent-group-text">
                      <SVG src={bubbles} title="timeline_icon" />
                    </InputGroupText>
                    <Input
                      id="selected-timeline"
                      type="select"
                      value={selectedTimeline?.id ?? ''}
                      onChange={onTimelineChange}
                    >
                      {timelinesOptions}
                    </Input>
                  </InputGroup>
                </FormGroup>
                <FormGroup className={hiddenClass}>
                  <SkeletonPlaceholderOnLoad isLoading={isLoading}>
                    <ExpandableTextareaField
                      divCssClass="CaseHeader__description"
                      inputCssClass="CaseHeader__description--textarea"
                      disabled={!isEdited}
                      value={selectedTimeline?.description ?? ''}
                      name="description"
                      onEdit={onEdit}
                    />
                  </SkeletonPlaceholderOnLoad>
                </FormGroup>
              </Col>
              <Col md={12} lg={6} xl={{ size: 5 }}>
                <HeaderDetail
                  iconSrc={bellIcon}
                  isLoading={isLoading}
                  header={intl.formatMessage({ id: 'CaseCard.status' })}
                  color="yellow"
                  htmlFor="status"
                >
                  <InputWithDisabledDivDisplay
                    disabled={!isEdited}
                    type="select"
                    value={data?.status ?? ''}
                    name="status"
                    id="status"
                    options={statusDefinition}
                    onEdit={onEdit}
                    inputCssClass="CaseHeader__input"
                  />
                </HeaderDetail>
                <HeaderDetail
                  noMaxWidth
                  isLoading={isLoading}
                  header={intl.formatMessage({ id: 'CaseCard.category' })}
                  htmlFor="category"
                >
                  <CategorySelect
                    organizationId={organizationId}
                    value={data?.category.code ?? ''}
                    name="category"
                    id="category"
                    disabled={!isEdited}
                    onEdit={onCategoryEdit}
                    className="CaseHeader__input"
                    isDefaultInput={false}
                  />
                </HeaderDetail>
                <HeaderDetail
                  noMaxWidth
                  isLoading={isLoading}
                  header={intl.formatMessage({ id: 'CaseCard.subCategory' })}
                  htmlFor="sub_category"
                >
                  <SubCategorySelect
                    organizationId={organizationId}
                    isDefaultInput={false}
                    categoryId={selectedCategory || ''}
                    value={data?.sub_category?.code ?? ''}
                    name="sub_category"
                    id="sub_category"
                    disabled={!isEdited}
                    onEdit={onEdit}
                    className="CaseHeader__input"
                  />
                </HeaderDetail>
              </Col>
              <div className="w-100 d-flex justify-content-end">
                {isEdited && (
                  <>
                    <Button
                      className="me-2 transparent-with-border"
                      color="primary"
                      outline
                      onClick={onCancel}
                    >
                      <FormattedMessage id="General.cancel" />
                    </Button>
                    <Button color="primary" onClick={onSave}>
                      <FormattedMessage id="General.save" />
                    </Button>
                  </>
                )}
              </div>
            </Row>
          </Col>
          <Col md={6} sm={12} className={hiddenClass}>
            {participant && selectedTimeline && (
              <HeaderSummary
                title="Patient's summary"
                userId={participant.user_id}
                timelineId={selectedTimeline.id}
                organizationId={organizationId}
                caseId={caseId}
              />
            )}
          </Col>
        </>
      );
    }
  };

  return (
    <>
      <CaseHistoryModal
        extendedValues={editedValues}
        isOpen={isEditModalOpen}
        toggleModal={onEditCancel}
        onSaveConfirm={onEditConfirm}
        caseId={caseId}
        organizationId={organizationId}
        hidePredefinedMessages={true}
        hideOwnerSelect={true}
      />
      <CaseTimelineHeader
        canEdit={true}
        onEditClick={toggleEdit}
        onDeleteClick={toggleDeleteModal}
        headerClass={classNames('CaseHeader', {
          'CaseHeader--hidden': isHeaderHidden,
        })}
        isEdited={isEdited}
        onHideClick={onHideClick}
        isHidden={isHeaderHidden}
      >
        <Row className="CaseTimelineHeader__row-container">
          {renderHeaderContent()}
        </Row>
      </CaseTimelineHeader>
      <ConfirmModal
        message={intl.formatMessage({
          id: 'CaseCard.delete.modalMessage',
        })}
        confirm={onDelete}
        isOpen={isDeleteModalOpen}
        toggle={toggleDeleteModal}
      />
    </>
  );
};

export default CaseDetailsHeader;
