import { useTreatmentPlansCommonData } from '../../../../../hooks/useTreatmentPlansCommonData';
import { useProjectsCommonData } from '../../../../../../Projects/hooks/useProjectsCommonData';
import { useCallback, useMemo } from 'react';
import { ITreatmentPlan } from '../../../../../../../interfaces/conversations';
import { useNavigate } from 'react-router';
import useRequiredParams from '../../../../../../../hooks/useRequiredParams';

export const useTreatmentPlanListItem = (
  plan: ITreatmentPlan,
  handleClone: () => void
) => {
  const { treatmentPlans, caseCategories } = useTreatmentPlansCommonData();
  const { projects } = useProjectsCommonData();

  const navigate = useNavigate();

  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const isLoading = useMemo(
    () =>
      treatmentPlans.isLoading ||
      caseCategories.isLoading ||
      projects.isLoading,
    [caseCategories.isLoading, projects.isLoading, treatmentPlans.isLoading]
  );

  const planName = useMemo(() => {
    return caseCategories.data?.find((p) => p.id === plan.id)?.name_en ?? '';
  }, [caseCategories.data, plan.id]);

  const projectsAssigned = useMemo(() => {
    return (projects ?? []).projects.filter(
      (p) => p.case_category_id === plan.id
    );
  }, [plan.id, projects]);

  const navigateToTreatmentPlan = useCallback(() => {
    navigate(`/organizations/${organizationId}/treatment-plans/${plan.id}`);
  }, [navigate, organizationId, plan.id]);

  const cloneTreatmentPlan = useCallback(() => {
    handleClone();
  }, [handleClone]);

  return {
    dataLoading: isLoading,
    data: {
      planName: planName,
      projectsAssigned: projectsAssigned,
    },
    actions: {
      navigateToTreatmentPlan: navigateToTreatmentPlan,
      cloneTreatmentPlan: cloneTreatmentPlan,
    },
  };
};
