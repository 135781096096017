import { createTheme, ThemeProvider } from '@mui/material';
import React from 'react';
import CustomDataGrid from '../../../../../../../../../../components/CustomDataGrid';
import { OrganizationAccessProfile } from '../../../../../../../../../../interfaces/accessProfiles';
import {
  DashboardReportSchedule,
  DashboardTemplate,
} from '../../../../../../../../../../interfaces/dashboards';
import { IPersonality } from '../../../../../../../../../../interfaces/personalities';
import { useColumns } from './hooks/useColumns';
import { useHandleRowEditStop } from './hooks/useHandleRowEditStop';
import { useRowModesModel } from './hooks/useRowModesModel';
import { useRows } from './hooks/useRows';

export type ReportScheduleTableProps = {
  schedules: DashboardReportSchedule[];
  templates: DashboardTemplate[];
  accessProfiles: OrganizationAccessProfile[];
  members: IPersonality[];
};

const ReportScheduleTable = ({
  schedules,
  templates,
  members,
  accessProfiles,
}: ReportScheduleTableProps) => {
  const { rows, setRows, processRowUpdate } = useRows(schedules);
  const { rowModesModel, setRowModesModel, handleRowModesModelChange } =
    useRowModesModel();
  const handleRowEditStop = useHandleRowEditStop();

  const columns = useColumns(
    rows,
    setRows,
    rowModesModel,
    setRowModesModel,
    templates,
    accessProfiles,
    members
  );

  return (
    <div className="mt-4 text-muted">
      <ThemeProvider
        theme={createTheme({
          components: {
            MuiSvgIcon: {
              styleOverrides: {
                root: { padding: 0 },
              },
            },
          },
        })}
      >
        <CustomDataGrid
          rows={rows}
          columns={columns}
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={handleRowModesModelChange}
          onRowEditStop={handleRowEditStop}
          processRowUpdate={processRowUpdate}
          rowHeight={64}
        />
      </ThemeProvider>
    </div>
  );
};

export default ReportScheduleTable;
