import { cloneDeep } from 'lodash';
import { useMemo } from 'react';
import { Expressions } from '../../../../../helpers/expressions';
import useCultures from '../../../../../hooks/cultures/useCultures';
import { DashboardTemplate } from '../../../../../interfaces/dashboards';
import { DashboardFilterHelpers } from '../components/DashboardContent/helpers/filters';

export const useResolveFilters = <T>(
  template: DashboardTemplate,
  context: T
) => {
  const { currentCulture } = useCultures();

  return useMemo(() => {
    let _template = cloneDeep(template);

    _template.content.filters = _template.content.filters.map((filter) => {
      let _filter = cloneDeep(filter);

      if (!DashboardFilterHelpers.IsIncludedExcluded(_filter)) return _filter;

      _filter.included = _filter.included.map((option) =>
        Expressions.HandlebarsEvaluate(option, context, currentCulture)
      );

      _filter.excluded = _filter.excluded.map((option) =>
        Expressions.HandlebarsEvaluate(option, context, currentCulture)
      );

      return _filter;
    });

    return _template;
  }, [context, currentCulture, template]);
};
