import { CaseHeaderWithMembers } from './caseAccesses';
import { ICaseDetails, IStrictCase } from './cases';
import { AccessType, StatusEnum } from './enums';
import { RequestType } from './request';
import { ITimeline } from './timeline';

export interface BasePersonality {
  id: string;
  first_name: string;
  last_name: string;
  avatar: string | null;
  email: string;
}

export interface DoctorPersonalityInTimeline extends BasePersonality {
  organization_id: string;
  user_id: string;
}
export interface IStrictPersonality extends BasePersonality {
  already_shared: boolean;
  already_requested: boolean;
  cases: IStrictCase[];
}
export interface IPersonality extends BasePersonality {
  user_id: string;
  organization_id: string;
  ssn: string;
  culture: string;
  gender: string;
  address: string;
  birthdate: string;
  phone_number?: string;
  default_organization_id?: string | null;
  number: number;
}

export interface ICreatePersonality {
  first_name?: string;
  last_name?: string;
  email?: string;
  culture?: string;
  address?: string;
  birthdate?: string;
  ssn?: string;
  gender?: string;
  phone_number?: string;
}

export interface ICreatePersonalityBody {
  first_name: string;
  last_name: string;
  email: string | null;
  culture: string;
  address: string;
  birthdate: string;
  ssn: string;
  gender: string;
  phone_number: string;
  is_participant: boolean;
}

export interface ICreatePersonalityResponse {
  password: string;
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  user_id: string;
  organization_id: string;
}

export interface ISharing {
  timelines: ITimeline[];
  id: string;
  status: StatusEnum;
  organization_name: string;
  title: string;
  created_by: IPersonality;
  participant: IPersonality;
}

export enum PatientFormKeys {
  FIRST_NAME = 'first_name',
  LAST_NAME = 'last_name',
  BIRTH_DATE = 'birthdate',
  GENDER = 'gender',
  ADDRESS = 'address',
  SOCIAL_SECURITY_NUMBER = 'ssn',
  EMAIL = 'email',
  PHONE_NUMBER = 'phone_number',
  CULTURE = 'culture',
  NUMBER = 'number',
}

export type InvitationStatus = 'Approved' | 'Rejected' | 'Sent';
export type InvitationPersonality = {
  culture: string;
  address: string;
  birthdate: string;
  ssn: string;
  gender: string;
  phone_number: string;
  is_participant: boolean;
  is_member: boolean;
  confirmed: boolean;
  number: number;
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  user_id: string;
  organization_id: string;
};
export interface IInvitationDetails {
  organization_id: string;
  organization_name: string;
  email: string;
  status: InvitationStatus;
  personality: InvitationPersonality | null;
}

export interface MemberWithAccess {
  assign_date: string;
  participant_personality_id: string;
  reason: string;
  access_type: AccessType;
  expiration_date: string | null;
  member_personality_id: string;
  member_personality: IPersonality | null;
}
export interface IOrganizationPatientDetails extends IPersonality {
  members_with_access: MemberWithAccess[];
  cases: CaseHeaderWithMembers[];
  number: number;
}

export interface IAccessRequest {
  created_by: IPersonality;
  participant: IPersonality;
  id: string;
  organization_name: string;
  reason: string;
  type: RequestType;
  status: StatusEnum;
  title: string;
}

export interface IMemberAccessRequest extends IAccessRequest {
  child_requests: IAccessRequest[];
}

interface CaseResponseDetails extends Omit<ICaseDetails, 'participants'> {
  personalities: IPersonality[];
}
export interface ICaseAccessRequest extends IAccessRequest {
  case: CaseResponseDetails;
}
