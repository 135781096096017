import { removeAccessProfileRoles } from 'api/accessProfiles';
import { UserRoles } from 'interfaces/membership';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { IMutationProps } from '../../../interfaces/ui';

export type RemoveAccessProfileRolesBody = {
  accessProfileId: string;
  roles: UserRoles[];
};

type useRemoveAccessProfileRolesParams = {
  organizationId: string;
};

export const useRemoveAccessProfileRoles = (
  params: useRemoveAccessProfileRolesParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (body: RemoveAccessProfileRolesBody) => {
      await removeAccessProfileRoles(
        params.organizationId,
        body.accessProfileId,
        body.roles
      );
    },
    onSuccess: () => {
      if (options.successFb) options.successFb();
    },
    onErrorArray: (_) => {
      if (options.errorFb) options.errorFb();
    },
    resetOnResult: true,
  });
};
