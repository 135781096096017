import React, { useCallback, useMemo, useState } from 'react';
import ConversationsTab from '../tabs/ConversationsTab';
import DashboardTab from '../tabs/DashboardTab';
import EventLogsTab from '../tabs/EventLogsTab';
import ScheduleTab from '../tabs/ScheduleTab';

export enum ChangeCaseTabsValues {
  Dashboard = 'Dashboard',
  Schedule = 'Schedule',
  Conversations = 'Conversations',
  EventLog = 'EventLog',
}

export const mappedChangeCaseTabsValues = [
  ChangeCaseTabsValues.Dashboard,
  ChangeCaseTabsValues.Schedule,
  ChangeCaseTabsValues.Conversations,
  ChangeCaseTabsValues.EventLog,
];

export const useTabs = () => {
  const [selectedTab, setSelectedTab] = useState<ChangeCaseTabsValues>(
    ChangeCaseTabsValues.Dashboard
  );

  const shouldBeRendered = useCallback(
    (tab: ChangeCaseTabsValues) => {
      return selectedTab === tab;
    },
    [selectedTab]
  );

  const onTabChange = (val: number) => {
    setSelectedTab(mappedChangeCaseTabsValues[val]);
  };

  const CurrentTab = useMemo((): React.FC => {
    if (shouldBeRendered(ChangeCaseTabsValues.Dashboard)) return DashboardTab;
    if (shouldBeRendered(ChangeCaseTabsValues.Schedule)) return ScheduleTab;
    if (shouldBeRendered(ChangeCaseTabsValues.EventLog)) return EventLogsTab;

    if (shouldBeRendered(ChangeCaseTabsValues.Conversations))
      return ConversationsTab;

    return React.Fragment;
  }, [shouldBeRendered]);

  return {
    CurrentTab,
    onTabChange,
    selectedTab,
  };
};
