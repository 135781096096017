import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { IUpsertEducationMaterialCategory } from '../../../interfaces/educationMaterials';
import { upsertEducationMaterialCategory } from '../../educationMaterials';
import { IMutationRequest } from '../../../interfaces/request';
import { OrganizationQueryParams } from '../../../interfaces/queryParams';

const useUpsertEducationMaterialCategory = ({
  params,
  options,
}: IMutationRequest<OrganizationQueryParams>) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (body: IUpsertEducationMaterialCategory) => {
      await upsertEducationMaterialCategory(params.organizationId, body);
    },
    onSuccess: () => {
      displaySuccessNotification(
        'Api.Mutations.EducationMaterials.UpsertEducationMaterialCategory.success'
      );
      options.successFb?.();
    },
    onError: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'Api.Mutations.EducationMaterials.UpsertEducationMaterialCategory.failure'
      );
      options.errorFb?.();
    },
    resetOnResult: true,
  });
};

export default useUpsertEducationMaterialCategory;
