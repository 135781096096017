import { getConversationQuestionValidation } from '../../conversations';
import { OrganizationQueryParams } from 'interfaces/queryParams';
import { ITypedMutationRequest } from 'interfaces/request';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { IQuestionConditionValidation } from '../../../interfaces/conversations';

interface OrganizationQuestionQueryParams extends OrganizationQueryParams {
  questionId: string;
}

const useConversationQuestionValidation = ({
  params,
  options,
  silent,
}: ITypedMutationRequest<
  OrganizationQuestionQueryParams,
  IQuestionConditionValidation
>) => {
  const mutationData = useMutationWithResultNotification({
    asyncApiFunction: async (condition: string) => {
      const { data } = await getConversationQuestionValidation(
        params.organizationId,
        params.questionId,
        condition
      );

      return data.data;
    },
    onErrorArray: (errorArray) => {
      if (!silent) {
        displayErrorArrayNotifications(
          errorArray,
          'Api.Mutations.Conversations.ConversationQuestionValidation.failure'
        );
      }

      options.errorFb?.(errorArray);
    },
    onSuccess: () => {
      if (!silent) {
        displaySuccessNotification(
          'Api.Mutations.Conversations.ConversationQuestionValidation.success'
        );
      }

      mutationData.data && options.successFb?.(mutationData.data);
      mutationData.reset();
    },
    resetOnResult: false,
  });

  return mutationData;
};

export default useConversationQuestionValidation;
