import notepadIcon from 'assets/images/notepad.svg';
import React from 'react';
import SVG from 'react-inlinesvg';
import { useTranslations } from '../../../../../../../../hooks/useTranslations';
import ItemContainer from '../ItemContainer';
import SessionInfoEntries from './components/SessionInfoEntries';

const SessionInfo = () => {
  const { attachPrefix, wrapWithIntl } = useTranslations(
    'Cases.CaseDetails.AwareCase.SessionInfo'
  );

  return (
    <ItemContainer
      title={wrapWithIntl(attachPrefix('sessionInfo'))}
      icon={<SVG src={notepadIcon} />}
    >
      <div className="h-100">
        <SessionInfoEntries />
      </div>
    </ItemContainer>
  );
};

export default SessionInfo;
