import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { CaseQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { getCaseScheduledActivityPeriods } from 'api/cases';

const useCaseScheduledActivitityPeriods = ({
  params,
  options,
}: IQueryRequest<CaseQueryParams>) => {
  const { organizationId, caseId } = params;

  return useQuery({
    ...options,
    queryKey: [QueryKeys.getCaseMembers, organizationId, caseId],
    queryFn: async () => {
      const { data } = await getCaseScheduledActivityPeriods(
        organizationId,
        caseId
      );
      return data?.data;
    },
  });
};

export default useCaseScheduledActivitityPeriods;
