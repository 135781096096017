import React, { useMemo } from 'react';
import { InputOption } from '../../../../../../../interfaces/ui';
import { SelectedGroupFeedback } from '../index';
import { Button, FormGroup } from 'reactstrap';
import EditableInput from '../../../../../../../components/EditableInput/EditableInput';
import { FormattedMessage } from 'react-intl';
import { AssessmentQuestionType } from '../../../../../../../interfaces/assessmentForms';
import GroupFeedbackRangeChart from './GroupFeedbackRangeChart';
import GroupFeedbackClosedListChart from './GroupFeedbackClosedListChart';
import {
  IGroupFeedbackClosedListQuestionAnswer,
  IGroupFeedbackRangeQuestionAnswer,
} from '../../../../../../../interfaces/caseGroups';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

type GroupFeedbackItemProps = {
  templateId: string;
  templatesOptions: InputOption[];
  selectedFeedback: SelectedGroupFeedback;
  onTemplateChange: (templateId: string) => void;
  onTemplateRemove: (templateId: string) => void;
  onQuestionChange: (questionId: string) => void;
};

const GroupFeedbackItem = ({
  templateId,
  selectedFeedback,
  templatesOptions,
  onTemplateChange,
  onQuestionChange,
  onTemplateRemove,
}: GroupFeedbackItemProps) => {
  const questionsOptions: InputOption[] = useMemo(() => {
    return selectedFeedback.questions.map((feedback) => ({
      value: feedback.questionId,
      text: feedback.questionTitle,
    }));
  }, [selectedFeedback.questions]);

  const onTemplateEdit = (target: string, value: string) => {
    onTemplateChange(value);
  };

  const onQuestionEdit = (target: string, value: string) => {
    onQuestionChange(value);
  };

  const onTemplateDelete = () => {
    onTemplateRemove(templateId);
  };

  return (
    <div className="GroupFeedbackItem">
      <div className="GroupFeedbackItem__selection">
        <div className="d-flex align-items-end justify-content-between gap-2">
          <div className="w-100">
            <FormGroup>
              <label htmlFor="template">
                <FormattedMessage id="Cases.CaseGroupDetails.selectedTemplate" />
              </label>
              <EditableInput
                type="select"
                value={templateId}
                options={templatesOptions}
                name="template"
                onEdit={onTemplateEdit}
                debounceEnabled={false}
              />
            </FormGroup>
          </div>
          <div className="w-100">
            <FormGroup>
              <label htmlFor="question">
                <FormattedMessage id="Cases.CaseGroupDetails.selectedQuestion" />
              </label>
              <EditableInput
                type="select"
                value={
                  selectedFeedback.questions[selectedFeedback.selectedIndex]
                    .questionId
                }
                options={questionsOptions}
                name="question"
                onEdit={onQuestionEdit}
                debounceEnabled={false}
              />
            </FormGroup>
          </div>
          <div>
            <Button
              className="mb-3"
              color="primary"
              onClick={onTemplateDelete}
              outline={true}
            >
              <FontAwesomeIcon icon={faTimes} />
            </Button>
          </div>
        </div>
      </div>
      <div className="GroupFeedbackItem__chart">
        {selectedFeedback.questions[selectedFeedback.selectedIndex]
          .questionType === AssessmentQuestionType.RANGE ? (
          <GroupFeedbackRangeChart
            questionTitle={
              selectedFeedback.questions[selectedFeedback.selectedIndex]
                .questionTitle
            }
            answers={
              selectedFeedback.questions[selectedFeedback.selectedIndex]
                .answers as IGroupFeedbackRangeQuestionAnswer[]
            }
          />
        ) : (
          <GroupFeedbackClosedListChart
            answers={
              selectedFeedback.questions[selectedFeedback.selectedIndex]
                .answers as IGroupFeedbackClosedListQuestionAnswer[]
            }
          />
        )}
      </div>
    </div>
  );
};

export default GroupFeedbackItem;
