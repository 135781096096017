import React from 'react';
import { Container } from 'reactstrap';

const ErrorLayout = ({ children }) => {
  return (
    <div className="min-vh-100 ErrorScreen d-flex flex-column overflow-hidden position-relative">
      <Container className="flex-grow-1 d-flex align-items-center">
        {React.Children.only(children)}
      </Container>
    </div>
  );
};

export default ErrorLayout;
