import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { IPatchObject } from '../../../interfaces/request';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { editCases } from '../../cases';
import { IMutationProps } from '../../../interfaces/ui';

type useCasesEditPrams = {
  organizationId: string;
  caseIds: string[];
};

const useCasesEdit = (params: useCasesEditPrams, options: IMutationProps) => {
  return useMutationWithResultNotification<IPatchObject[], void>({
    asyncApiFunction: async (patchData) => {
      const queryItems = params.caseIds.map((caseId) => ({
        case_ids: caseId,
      }));

      await editCases(params.organizationId, queryItems, patchData);
    },
    resetOnResult: true,
    onError: () => {
      if (options.errorFb) options.errorFb();

      displayErrorNotification('CaseCard.edit.error');
    },
    onSuccess: () => {
      if (options.successFb) options.successFb();

      displaySuccessNotification('CaseCard.edit.success');
    },
  });
};

export default useCasesEdit;
