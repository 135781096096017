import React, { useContext } from 'react';
import { HoursContext } from '../../index';

const ScheduleHours = () => {
  const hours = useContext(HoursContext);

  return (
    <thead className="Schedule__head">
      <tr>
        <th className="ScheduleRow__label"></th>
        <th className="ScheduleRow__day-range">
          <div className="ScheduleHours">
            {hours.map((hour) => (
              <div key={hour} className="ScheduleHours__item">
                <div className="ScheduleHours__line">
                  <span className="ScheduleHours__hour">{hour}</span>
                </div>
              </div>
            ))}
          </div>
        </th>
        <th className="ScheduleRow__actions"></th>
      </tr>
    </thead>
  );
};

export default ScheduleHours;
