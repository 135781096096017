import { useCallback, useMemo } from 'react';
import { ICaseGroup } from '../../../../../interfaces/caseGroups';
import { IPatchObject } from '../../../../../interfaces/request';

export const useCaseGroupDetailsPatchObject = () => {
  const excludedParams = useMemo(() => {
    return ['organization', 'cases', 'sessions', 'case_category'];
  }, []);

  return useCallback(
    (data: ICaseGroup | undefined, initialData: ICaseGroup | undefined) => {
      let patch: IPatchObject[] = [];

      if (!data || !initialData) return patch;

      Object.entries(data).forEach(([key, val]) => {
        if (val === initialData[key] || excludedParams.includes(key)) return;

        patch.push({
          op: 'replace',
          path: `/${key}`,
          value: val,
        });
      });

      return patch;
    },
    [excludedParams]
  );
};
