import { ScheduleItemDefinition } from 'components/Schedule';
import {
  CaseNotificationSchedule,
  ISingleNotificationSchedule,
} from 'interfaces/notificationSchedules';
import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { useTypedContext } from '../../../../../../../../../../../../../../../hooks/useTypedContext';
import { CasePhaseHistoryContext } from '../../../../../types';
import { DayScheduleFilterHelpers } from '../helpers/filters';
import { DayScheduleGeneratorsHelpers } from '../helpers/generators';
import { DayScheduleMergingHelpers } from '../helpers/rowMerging';

export const useItems = (
  date: Date,
  handleEdit: (schedule: CaseNotificationSchedule) => void
) => {
  const casePhaseHistory = useTypedContext(CasePhaseHistoryContext);

  return useCallback(
    (schedules: CaseNotificationSchedule[]): ScheduleItemDefinition[] => {
      let _items: ScheduleItemDefinition[] = [];

      let schedulesCopy = cloneDeep(schedules);

      schedulesCopy = schedulesCopy.filter((p) =>
        DayScheduleFilterHelpers.DateFilter(p, date)
      );

      schedulesCopy = schedulesCopy.filter(
        DayScheduleFilterHelpers.RecurringFilter
      );

      schedulesCopy.forEach((schedule, index) => {
        _items = DayScheduleGeneratorsHelpers.GenerateDefinition(
          _items,
          schedule as ISingleNotificationSchedule,
          schedules,
          casePhaseHistory,
          date,
          index,
          handleEdit
        );
      });

      _items = DayScheduleMergingHelpers.MergeSingleInstances(
        _items,
        schedules
      );
      return _items;
    },
    [casePhaseHistory, date, handleEdit]
  );
};
