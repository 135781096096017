import classNames from 'classnames';
import { InputOption } from 'interfaces/ui';
import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import { Input, Label } from 'reactstrap';
import { InputType } from 'reactstrap/types/lib/Input';
import { useDebouncedCallback } from 'use-debounce';

export interface EditableInputInputProps
  extends InputHTMLAttributes<HTMLInputElement> {
  type?: InputType;
  options?: InputOption[];
  groups?: Record<string, InputOption[]>;
  onEdit?: (target: string, value: string) => void;
  debounceTime?: number;
  displayEmpty?: boolean;
  withoutRefetch?: boolean;
  debounceEnabled?: boolean;
  label?: string;
  validation?: (value: string) => boolean;
}

const EditableInput: React.FC<EditableInputInputProps> = ({
  type = 'text',
  onEdit,
  value,
  options,
  groups,
  disabled,
  debounceTime = 1000,
  withoutRefetch = false,
  displayEmpty = false,
  className,
  debounceEnabled = true,
  label = undefined,
  validation,
  ...inputProps
}) => {
  const [input, setInput] = useState<typeof value>(value);

  const debouncedCallback = useDebouncedCallback((name, value) => {
    if (onEdit) onEdit(name, value);
  }, debounceTime);

  const callbackWithoutDebounce = (name, value) => {
    if (onEdit) onEdit(name, value);
  };

  const onChange = (event) => {
    if (validation && !validation(event.target.value)) return;
    setInput(event.target.value);

    if (debounceEnabled)
      debouncedCallback(event.target.name, event.target.value);
    else callbackWithoutDebounce(event.target.name, event.target.value);
  };

  const sharedInputProps = {
    type,
    className: classNames({
      debouncedInput: debounceEnabled,
      [className?.toString() ?? '']: className,
    }),
    value: input,
    onChange,
  };

  useEffect(() => {
    if (!debounceEnabled) setInput(value);
    if (debounceEnabled && disabled) setInput(value);
    if (withoutRefetch) setInput(value);
  }, [disabled, value, withoutRefetch, debounceEnabled]);

  if (type === 'select') {
    if (options) {
      return withLabel(
        <Input
          disabled={disabled}
          {...inputProps}
          {...sharedInputProps}
          id={inputProps.name}
        >
          {displayEmpty && (
            <option disabled={inputProps.required} key="display-empty" value="">
              {inputProps.placeholder ?? ''}
            </option>
          )}
          {options.map(({ text, value }) => (
            <option key={value} value={value}>
              {text}
            </option>
          ))}
        </Input>,
        inputProps.name,
        label
      );
    }

    if (groups) {
      return withLabel(
        <Input
          disabled={disabled}
          {...inputProps}
          {...sharedInputProps}
          id={inputProps.name}
        >
          {displayEmpty && (
            <option disabled={inputProps.required} key="display-empty" value="">
              {inputProps.placeholder ?? ''}
            </option>
          )}
          {Object.entries(groups).map(([groupKey, options]) => (
            <optgroup key={groupKey} label={groupKey}>
              {options.map(({ text, value }) => (
                <option key={value} value={value}>
                  {text}
                </option>
              ))}
            </optgroup>
          ))}
        </Input>,
        inputProps.name,
        label
      );
    }

    return <></>;
  }

  const placeholderText =
    disabled && !value && inputProps.placeholder
      ? inputProps.placeholder
      : input ?? '';

  return withLabel(
    <Input
      id={inputProps.name}
      disabled={disabled}
      {...inputProps}
      {...sharedInputProps}
      value={placeholderText}
    />,
    inputProps.name,
    label
  );
};

const withLabel = (element: JSX.Element, id?: string, label?: string) => {
  if (!label || !id) return element;

  return (
    <div>
      <Label value={label} htmlFor={id}>
        {label}
      </Label>
      {element}
    </div>
  );
};

export default EditableInput;
