import {
  differenceInCalendarDays,
  isAfter,
  isBefore,
  isSameDay,
  subDays,
  subMonths,
  subYears,
} from 'date-fns';
import { TimeRange } from 'interfaces/timeline';
import { DateRange } from 'react-day-picker';

const formatDateToStart = (date: Date | number) => {
  const from = new Date(date);
  from.setHours(0, 0, 0, 0);

  return from;
};

const formatDateToEnd = (date: Date | number) => {
  const to = new Date(date);
  to.setHours(23, 59, 59, 0);

  return to;
};

const returnRangeFromDayStartToEnd = (date: Date): DateRange => {
  const from = formatDateToStart(date);
  const to = formatDateToEnd(date);

  return {
    from,
    to,
  };
};

export const returnNextRange = (newDate: Date, currentDateRange: DateRange) => {
  const beginDate = formatDateToStart(newDate);
  const endDate = formatDateToEnd(newDate);

  if (isPossibleToInvokeFromDayStartToEnd(currentDateRange, newDate))
    return returnRangeFromDayStartToEnd(newDate);

  if (currentDateRange.to && currentDateRange.from) {
    if (isBefore(newDate, currentDateRange.from)) {
      return {
        from: beginDate,
        to: currentDateRange.to,
      };
    }

    return {
      from: currentDateRange.from,
      to: endDate,
    };
  }

  if (!currentDateRange.from && currentDateRange.to) {
    const isBiggerThanEnd = isAfter(newDate, currentDateRange.to);

    return {
      from: getFromDateWhenNullableCurrent(
        newDate,
        currentDateRange.to,
        beginDate
      ),
      to: isBiggerThanEnd ? endDate : currentDateRange.to,
    };
  }

  if (!currentDateRange.to && currentDateRange.from) {
    const isBiggerThanStart = isAfter(newDate, currentDateRange.from);

    return {
      from: isBiggerThanStart ? currentDateRange.from : beginDate,
      to: getEndDateWhenNullableCurrent(
        newDate,
        currentDateRange.from,
        endDate
      ),
    };
  }

  return returnRangeFromDayStartToEnd(newDate);
};

const getFromDateWhenNullableCurrent = (
  newDate: Date,
  dateTo: Date,
  beginDate: Date
) => {
  return isAfter(newDate, dateTo) ? formatDateToStart(dateTo) : beginDate;
};

const getEndDateWhenNullableCurrent = (
  newDate: Date,
  dateFrom: Date,
  endDate: Date
) => {
  return isAfter(newDate, dateFrom) ? endDate : formatDateToEnd(dateFrom);
};

const isPossibleToInvokeFromDayStartToEnd = (
  currentDateRange: DateRange,
  newDate: Date
) => {
  return (
    (!currentDateRange?.from && !currentDateRange?.to) ||
    (currentDateRange.to && isSameDay(currentDateRange.to, newDate)) ||
    (currentDateRange.from && isSameDay(currentDateRange.from, newDate))
  );
};

export const returnNewDateBaseOnTimeRange = (timeRange: TimeRange) => {
  let fromDate: Date = new Date();

  switch (timeRange) {
    case TimeRange.HOURS_24:
      fromDate = subDays(fromDate, 1);
      break;
    case TimeRange.DAYS_3:
      fromDate = subDays(fromDate, 3);
      break;
    case TimeRange.DAYS_7:
      fromDate = subDays(fromDate, 7);
      break;
    case TimeRange.DAYS_30:
      fromDate = subMonths(fromDate, 1);
      break;
    case TimeRange.MONTHS_3:
      fromDate = subMonths(fromDate, 3);
      break;
    case TimeRange.MONTHS_6:
      fromDate = subMonths(fromDate, 6);
      break;
    case TimeRange.YEAR:
      fromDate = subYears(fromDate, 1);
      break;
    case TimeRange.YEARS_3:
      fromDate = subYears(fromDate, 3);
      break;
  }

  return {
    from: fromDate,
    to: new Date(),
  };
};

export const getDayOffset = (from: string, to: string) => {
  return differenceInCalendarDays(new Date(to), new Date(from));
};

export const defaultHours: string[] = [
  '00:00',
  '02:00',
  '04:00',
  '06:00',
  '08:00',
  '10:00',
  '12:00',
  '14:00',
  '16:00',
  '18:00',
  '20:00',
  '22:00',
  '24:00',
];
