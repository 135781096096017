import React from 'react';
import {
  IErrorDefinition,
  IErrorWithCode,
} from '../../../../../interfaces/response';
import { useTranslations } from '../../../../../hooks/useTranslations';
import { last } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-light-svg-icons';

type GraspErrorPlaceholderProps = {
  error: IErrorWithCode;
};

const GraspErrorPlaceholder = ({ error }: GraspErrorPlaceholderProps) => {
  const { attachPrefix, wrapWithIntl } = useTranslations('General');

  const firstError: IErrorDefinition = error?.data?.meta.errors?.at(0) ?? {
    reasons: [wrapWithIntl(attachPrefix('error'))],
    code: '',
  };

  return (
    <div className="vh-100 vw-100 d-flex align-items-center justify-content-center position-absolute top-0 start-0">
      <div className="d-flex flex-column text-black opacity-25 align-items-center gap-2">
        <FontAwesomeIcon icon={faLock} size="6x" />
        <span className="fs-4">
          {last(firstError.reasons) ?? wrapWithIntl(attachPrefix('error'))}
        </span>
      </div>
    </div>
  );
};

export default GraspErrorPlaceholder;
