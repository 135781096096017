import { IObservation } from '../../../../../../../interfaces/timeline';
import { useMemo } from 'react';

type ObservationsGroupedByHour = {
  hour: number;
  observations: IObservation[];
  count: number;
};

export const useObservationsGroupedByHour = (observations: IObservation[]) => {
  const groupedObservations = useMemo(() => {
    let grouped = new Map<number, ObservationsGroupedByHour>();

    for (const observation of observations) {
      const hour = new Date(observation.timestamp).getHours();

      const group = grouped.get(hour);
      if (group) {
        group.observations.push(observation);
        group.count += 1;
      } else {
        grouped.set(hour, {
          count: 1,
          hour,
          observations: [observation],
        });
      }
    }

    return grouped;
  }, [observations]);

  const mostActiveHour = useMemo(() => {
    const sorted = [...groupedObservations.values()].sort(countAscCompare);
    const mostActiveGroup = sorted[0];

    if (!mostActiveGroup) return '--:-- / --:--';

    const hour = mostActiveGroup.hour;
    const start = String(hour).padStart(2, '0');
    const end = String((hour + 1) % 24).padStart(2, '0');
    return `${start}:00 - ${end}:00`;
  }, [groupedObservations]);

  return {
    groupedObservations: groupedObservations,
    mostActiveHour: mostActiveHour,
  };
};

const countAscCompare = (
  a: ObservationsGroupedByHour,
  b: ObservationsGroupedByHour
) => b.count - a.count;
