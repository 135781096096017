import HeaderDetail from 'components/HeaderDetail';
import React, { useMemo, useRef, useState } from 'react';
import { returnIconForQuestion } from 'helpers/utils/icons';
import sortBySequence from 'helpers/utils/sortBySequence';
import {
  AssessmentQuestionType,
  AssessmentSummaryQuestion,
} from 'interfaces/assessmentForms';
import { FormattedMessage, useIntl } from 'react-intl';
import CarrouselController from 'components/CarrouselController';
import useUserContextAssessmentFormSummary from 'api/queries/assessmentForms/summary/useUserContextAssessmentFormSummary';
import useOrganizationContextAssessmentFormSummary from 'api/queries/assessmentForms/summary/useOrganizationContextAssessmentFormSummary';
import useContainer from 'hooks/useContainer';
import classNames from 'classnames';

type HeaderSummaryProps = {
  title: string;
  userId: string;
  timelineId: string;
  organizationId?: string;
  caseId?: string;
};

const MID_CONTAINER_WIDTH = 250;
const MAX_CONTAINER_WIDTH = 560;

const HeaderSummary = ({
  userId,
  timelineId,
  organizationId = '',
  caseId = '',
}: HeaderSummaryProps) => {
  const intl = useIntl();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const {
    data: organizationContextData,
    isFetching: organizationContextIsLoading,
  } = useOrganizationContextAssessmentFormSummary({
    params: { organizationId, caseId, timelineId },
    options: { enabled: !!(organizationId && caseId) },
  });

  const { data: userContextData, isFetching: userContextIsLoading } =
    useUserContextAssessmentFormSummary({
      params: { userId, timelineId },
      options: { enabled: !(organizationId && caseId) },
    });

  const data =
    organizationId && caseId ? organizationContextData : userContextData;

  const isLoading =
    organizationId && caseId
      ? organizationContextIsLoading
      : userContextIsLoading;

  const headerWithDay = useMemo(() => {
    if (data && data?.length > 0) {
      const [form] = data;

      const dayLength = form.questions[0].current_trend_length;
      return ` ${intl.formatMessage({
        id: 'General.last',
      })} ${dayLength} ${intl.formatMessage({
        id: 'General.days',
      })}`;
    } else return '';
  }, [data, intl]);

  const renderedColumns = useMemo(() => {
    const columnArray: JSX.Element[] = [];

    if (data && data?.length > 0) {
      const form = data[selectedIndex];

      if (form) {
        if (form.isEmptySummary) {
          return (
            <div className="me-4 text-muted mt-3 ms-3">
              <FormattedMessage id="HeaderSummary.FormsSummary.emptySummary" />
            </div>
          );
        }

        let orderQuestions = sortBySequence(
          form.questions
        ) as AssessmentSummaryQuestion[];

        orderQuestions = orderQuestions.filter(
          (p) => p.type !== AssessmentQuestionType.HEADER
        );

        orderQuestions.forEach((question, index) => {
          const element = (
            <div className="me-4" key={`${question.title}-${index}`}>
              <HeaderDetail
                noMaxWidth
                key={`questionIndex-${question.sequence}`}
                header={question.title}
                isLoading={isLoading}
                iconSrc={returnIconForQuestion(question.icon)}
                status={question.current_trend_sentiment}
                color={question.color}
              >
                {question.type !== AssessmentQuestionType.RANGE ? (
                  question.current_trend_answer?.title ?? ''
                ) : (
                  <>
                    {question.average_question_value_for_7_days_back && (
                      <>
                        <FormattedMessage id="HeaderSummary.FormsSummary.averageScore" />
                        {question.average_question_value_for_7_days_back.toFixed(
                          2
                        )}
                      </>
                    )}
                  </>
                )}
              </HeaderDetail>
            </div>
          );

          columnArray.push(element);
        });
      }
    }

    return columnArray;
  }, [data, isLoading, selectedIndex]);

  const onSelect = (index: number) => setSelectedIndex(index);
  const title = data && data?.length > 0 ? data[selectedIndex].title : '';

  const divRef = useRef<null | HTMLDivElement>(null);
  const { containerWidth } = useContainer(divRef, {
    isDayByDayScreen: false,
  });

  const isMid = MID_CONTAINER_WIDTH < containerWidth;
  const isHigh = MAX_CONTAINER_WIDTH < containerWidth;

  return (
    <div className="HeaderSummary">
      <div className="HeaderSummary__header">{title}</div>
      <div className="HeaderSummary__sub-header">{headerWithDay}</div>
      <div className="h-100 w-100 d-flex">
        <div className="w-100 h-100">
          <div
            ref={divRef}
            className={classNames('HeaderSummary__container', {
              'HeaderSummary__container--columns-2': isMid && !isHigh,
              'HeaderSummary__container--columns-3': isHigh,
            })}
          >
            {renderedColumns}
          </div>
        </div>
        {data && data?.length > 1 && (
          <div className="h-100 d-flex align-items-center">
            <CarrouselController
              activeIndex={selectedIndex}
              numberOfItems={data?.length ?? 0}
              onSelect={onSelect}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderSummary;
