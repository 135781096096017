import { FormikErrors } from 'formik';
import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import useCreateOrganizationUser from '../../../../../../../api/mutations/organizations/useCreateOrganizationUser';
import {
  isEmail,
  validateEmpty,
} from '../../../../../../../helpers/utils/validators';
import useCultures from '../../../../../../../hooks/cultures/useCultures';
import { useOrganizationPathParams } from '../../../../../../../hooks/paths/useOrganizationPathParams';
import { IAsyncFormHookValues } from '../../../../../../../interfaces/forms';
import { ICreateOrganizationUser } from '../../../../../../../interfaces/organizations';

export const useForm = (
  refetch: (() => void) | undefined,
  toggle: () => void
): IAsyncFormHookValues<ICreateOrganizationUser> => {
  const { organizationId } = useOrganizationPathParams();
  const { currentCulture } = useCultures();
  const intl = useIntl();

  const onSuccess = () => {
    refetch?.();
    toggle();
  };

  const { mutateAsync: createAsync } = useCreateOrganizationUser({
    params: { organizationId },
    options: { successFb: onSuccess },
  });

  const initialValues = useMemo((): ICreateOrganizationUser => {
    return {
      first_name: '',
      last_name: '',
      user_name: '',
      culture: currentCulture.cultureCode,
      access_profile_id: '',
      email: '',
    };
  }, [currentCulture.cultureCode]);

  const handleSubmit = useCallback(
    async (values: typeof initialValues) => {
      await createAsync({
        ...values,
        owner_organization_id: organizationId,
      });
    },
    [createAsync, organizationId]
  );

  const validation = useCallback(
    (values: typeof initialValues): FormikErrors<typeof initialValues> => {
      let errors: FormikErrors<typeof initialValues> = validateEmpty(
        values,
        intl
      );

      if (!values.email || !isEmail(values.email))
        errors.email = intl.formatMessage({ id: 'General.invalidEmail' });

      return errors;
    },
    [intl]
  );

  return {
    initialValues,
    handleSubmit,
    validation,
  };
};
