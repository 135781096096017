import checkedIcon from 'assets/images/checked_circle.svg';
import inProgressIcon from 'assets/images/circle_in_progress.svg';

import notepadIcon from 'assets/images/notepad.svg';
import { Formik } from 'formik';
import React, { useCallback, useState } from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import FlagCultureSelector from '../../../../components/CultureSelector/FlagCultureSelector';
import PillsTabContainer, {
  IPillsTabContainerItem,
} from '../../../../components/Tabs/components/PillsTabContainer';
import useCultures, {
  AvailableCulture,
} from '../../../../hooks/cultures/useCultures';
import { IProject } from '../../../../interfaces/submissions';
import ProjectModalDetailsForm from './components/ProjectModalDetailsForm';
import ProjectModalFooter from './components/ProjectModalFooter';
import ProjectModalOverviewForm from './components/ProjectModalOverviewForm';
import ProjectModalProjectLinksForm from './components/ProjectModalProjectLinksForm';
import ProjectModalRegistrationFieldsForm from './components/ProjectModalRegistrationFieldsForm';
import ProjectModalSubmissionSetupForm from './components/ProjectModalSubmissionSetupForm';
import {
  ProjectModalFormStep,
  useProjectModalForm,
} from './hooks/useProjectModalForm';

type ProjectModalProps = {
  isOpen: boolean;
  toggle: () => void;
  project?: IProject;
  projectRefetch?: () => void;
};

const pillsContainerStyles = {
  container: 'mb-3',
};

const ProjectModal = ({
  project,
  toggle,
  isOpen,
  projectRefetch,
}: ProjectModalProps) => {
  const intl = useIntl();
  const { currentCulture } = useCultures();

  const [selectedCulture, setSelectedCulture] = useState<AvailableCulture>(
    () => currentCulture
  );

  const {
    initialValues,
    translations,
    validation,
    handleSubmit,
    step,
    setStep,
  } = useProjectModalForm(project, projectRefetch, toggle);

  const modalItems = useCallback(
    (
      values: typeof initialValues,
      setValues: (values: typeof initialValues) => void
    ): IPillsTabContainerItem[] => {
      const getTabStatusIcon = (stepName: ProjectModalFormStep) => {
        if (stepName === step) return inProgressIcon;

        const stepsKeys = Object.keys(ProjectModalFormStep);

        if (stepsKeys.indexOf(stepName) < stepsKeys.indexOf(step))
          return checkedIcon;

        return undefined;
      };

      return [
        {
          header: translations.getTranslationForStep(
            ProjectModalFormStep.Overview
          ),
          component: (
            <ProjectModalOverviewForm selectedCulture={selectedCulture} />
          ),
          clickable: false,
          tabStatusIcon: getTabStatusIcon(ProjectModalFormStep.Overview),
          shouldBeRendered: step === ProjectModalFormStep.Overview,
        },
        {
          header: translations.getTranslationForStep(
            ProjectModalFormStep.Details
          ),
          component: <ProjectModalDetailsForm />,
          clickable: false,
          tabStatusIcon: getTabStatusIcon(ProjectModalFormStep.Details),
          shouldBeRendered: step === ProjectModalFormStep.Details,
        },
        {
          header: translations.getTranslationForStep(
            ProjectModalFormStep.ProjectLinks
          ),
          component: <ProjectModalProjectLinksForm />,
          clickable: false,
          tabStatusIcon: getTabStatusIcon(ProjectModalFormStep.ProjectLinks),
          shouldBeRendered: step === ProjectModalFormStep.ProjectLinks,
        },
        {
          header: translations.getTranslationForStep(
            ProjectModalFormStep.RegistrationFields
          ),
          component: <ProjectModalRegistrationFieldsForm />,
          clickable: false,
          tabStatusIcon: getTabStatusIcon(
            ProjectModalFormStep.RegistrationFields
          ),
          shouldBeRendered: step === ProjectModalFormStep.RegistrationFields,
        },
        {
          header: translations.getTranslationForStep(
            ProjectModalFormStep.SubmissionSetup
          ),
          component: (
            <ProjectModalSubmissionSetupForm
              values={values}
              setValues={setValues}
              selectedCulture={selectedCulture}
            />
          ),
          clickable: false,
          tabStatusIcon: getTabStatusIcon(ProjectModalFormStep.SubmissionSetup),
          shouldBeRendered: step === ProjectModalFormStep.SubmissionSetup,
        },
      ];
    },
    [selectedCulture, step, translations]
  );

  const onTabChange = (index: number) => {
    setStep(Object.values(ProjectModalFormStep)[index]);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg" className="ProjectModal">
      <ModalHeader
        className="ProjectModal__header ProjectModalHeader"
        toggle={toggle}
      >
        <div className="ProjectModalHeader__title">
          <SVG src={notepadIcon} title={translations.formTitle} />
          <FormattedMessage id={translations.formTitle} />
        </div>
        <div className="ProjectModalHeader__selector">
          <FlagCultureSelector
            selectedCulture={selectedCulture}
            setSelectedCulture={setSelectedCulture}
            placeholder={intl.formatMessage({
              id: 'Projects.ProjectModal.selectLanguage',
            })}
          />
        </div>
      </ModalHeader>
      <Formik
        validate={validation}
        validateOnMount={true}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isValid, values, setValues, dirty }) => {
          return (
            <>
              <ModalBody className="ProjectModal__body">
                <PillsTabContainer
                  styles={pillsContainerStyles}
                  items={modalItems(values, setValues)}
                  onTabChange={onTabChange}
                />
              </ModalBody>
              <ModalFooter className="ProjectModal__footer ProjectModalFooter">
                <ProjectModalFooter
                  dirty={dirty}
                  step={step}
                  setStep={setStep}
                  toggle={toggle}
                  isValid={isValid}
                  submitForm={submitForm}
                />
              </ModalFooter>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ProjectModal;
