import useAddCaseHistory from 'api/mutations/cases/useCaseHistoryAdd';

import useCaseHistoryDelete from 'api/mutations/cases/useCaseHistoryDelete';

//helpers
//helpers
import useCaseHistoryEdit from 'api/mutations/cases/useCaseHistoryEdit';
import useInfiniteCaseHistory from 'api/queries/cases/useInfiniteCaseHistory';

//icons
import calendarIcon from 'assets/images/calendar_icon.svg';
import emailIcon from 'assets/images/email_icon.svg';
import FieldInput from 'components/FieldInput';
import FormikErrorMessage from 'components/FormikErrorMessage';
import LoadingButton from 'components/LoadingButton';
import { isToday } from 'date-fns';
import { Field, Formik } from 'formik';
import { formatDateToLast30days } from 'helpers/dates';
import { returnSelectedDateString } from 'helpers/utils/timelineHelpers';

//interfaces
import { ICaseHistory, ICaseHistoryBody } from 'interfaces/caseHistory';
import { cloneDeep } from 'lodash';
import CategorySelect from 'modules/Cases/pages/CaseDetailsPage/views/GenericCaseDetailsView/components/CaseDetailsHeader/components/CategorySelect';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { useTimelineDetailsDateRangeObject } from 'store/reducerHooks';
import useAddMultipleCaseHistory from '../../../../api/mutations/cases/useCaseHistoryAddMultiple';
import { formatObjectToIPatch } from '../../../../helpers/utils/formatData';
import {
  GroupSelectionIdentifiers,
  IRowSelection,
} from '../../../../hooks/useRowSelection';
import SubCategorySelect from '../../pages/CaseDetailsPage/views/GenericCaseDetailsView/components/CaseDetailsHeader/components/SubCategorySelect';

import CaseHistoryForm from './components/CaseHistoryForm';

export type CaseHistoryModalFormValues = {
  text: string;
  time_start: string | Date;
  praise_level: number | null;
  share_with_patient: boolean;
  message_owner: string | null;
  sender_ids: string[];
  new_category_id?: string;
  new_status?: string;
  new_sub_category_id?: string;
  category_reference?: string;
};

const initialValuesBasic: CaseHistoryModalFormValues = {
  text: '',
  time_start: new Date().toISOString(),
  praise_level: null,
  share_with_patient: false,
  message_owner: null,
  sender_ids: [],
};

type CaseHistoryModalProps = {
  isOpen: boolean;
  hidePraise?: boolean;
  hideShare?: boolean;
  hidePredefinedMessages?: boolean;
  hideOwnerSelect?: boolean;
  toggleModal: () => void;
  extendedValues?: {
    new_category_id?: string;
    new_status?: string;
    new_sub_category_id?: string;
    category_reference?: string;
    share_with_patient?: boolean;
  };
  onSaveConfirm?: () => void;
  historyData?: ICaseHistory | null;
  selectedDate?: Date | null;
  organizationId: string;
  rowSelection?: IRowSelection<GroupSelectionIdentifiers>;
  caseId?: string;
  caseIds?: string[];
  customTitleTranslationId?: string;
};

const getSelectedDate = (selectedDate: Date | null | undefined) => {
  const nowDate = new Date().toISOString();
  if (!selectedDate) return nowDate;
  if (isToday(selectedDate)) return nowDate;
  return selectedDate;
};

const CaseHistoryModal = ({
  isOpen,
  toggleModal,
  extendedValues = {},
  onSaveConfirm,
  historyData,
  selectedDate,
  caseId = '',
  organizationId,
  caseIds = undefined,
  hidePraise = false,
  hideShare = false,
  hidePredefinedMessages = false,
  hideOwnerSelect = false,
  rowSelection = undefined,
  customTitleTranslationId,
}: CaseHistoryModalProps) => {
  const baseDate = getSelectedDate(selectedDate);

  const initialValues: CaseHistoryModalFormValues = {
    ...extendedValues,
    text: historyData?.text ?? initialValuesBasic.text,
    time_start: historyData?.time_start
      ? new Date(historyData?.time_start)
      : baseDate,
    praise_level: initialValuesBasic.praise_level,
    share_with_patient:
      extendedValues?.share_with_patient ??
      initialValuesBasic.share_with_patient,
    message_owner: initialValuesBasic.message_owner,
    sender_ids: [],
  };

  const dateRangeObject = useTimelineDetailsDateRangeObject();
  const formattedDate = useMemo(
    () => formatDateToLast30days(dateRangeObject),
    [dateRangeObject]
  );

  const { refetch: baseCaseRefetch } = useInfiniteCaseHistory({
    params: { organizationId, caseId },
    options: { enabled: false },
  });

  const selectedDates =
    formattedDate?.from && formattedDate?.to
      ? { from: formattedDate.from, to: formattedDate.to }
      : null;

  const { refetch: dateFilteredCaseRefetch } = useInfiniteCaseHistory({
    params: {
      organizationId,
      caseId,
      pageSize: 1000,
      selectedDates,
    },
    options: { enabled: false },
  });

  const successFb = async () => {
    if (caseId) await baseCaseRefetch();
    if (formattedDate && caseId) await dateFilteredCaseRefetch();

    toggleModal();

    if (rowSelection) rowSelection.resetSelection();
  };

  const {
    mutate: addHistoryEntryMutation,
    isLoading: isAddHistoryEntryLoading,
  } = useAddCaseHistory(
    {
      caseId: caseId ?? '',
      organizationId: organizationId,
    },
    {
      successFb: successFb,
    }
  );

  const {
    mutate: addHistoryEntriesMutation,
    isLoading: isAddHistoryEntriesLoading,
  } = useAddMultipleCaseHistory(
    {
      caseIds: caseIds ?? [],
      organizationId: organizationId,
    },
    {
      successFb: successFb,
    }
  );

  const { mutate: deleteHistoryEntryMutation, isLoading: isDeleteLoading } =
    useCaseHistoryDelete(
      {
        organizationId: organizationId,
        caseId: caseId ?? '',
      },
      {
        successFb: successFb,
      }
    );

  const { mutate: editHistoryEntryMutation } = useCaseHistoryEdit(
    {
      organizationId: organizationId,
      caseId: caseId ?? '',
    },
    {}
  );

  const onDelete = () => {
    if (historyData) deleteHistoryEntryMutation(historyData.id);
  };

  const isEditMode = !!historyData;
  const onSubmit = (data: typeof initialValues) => {
    let dataCopy = cloneDeep(data);

    if (isEditMode && historyData) {
      editHistoryEntryMutation({
        id: historyData.id,
        document: formatObjectToIPatch({
          ...dataCopy,
          time_start: new Date(dataCopy.time_start).toISOString(),
        }),
      });

      return;
    }

    if (onSaveConfirm) onSaveConfirm();

    const caseHistoryBody: ICaseHistoryBody = {
      new_category_id: dataCopy.new_category_id
        ? dataCopy.new_category_id
        : null,
      new_status: dataCopy.new_status ?? null,
      new_sub_category_id: dataCopy.new_sub_category_id ?? null,
      text: dataCopy.text,
      time_start: new Date(dataCopy.time_start).toISOString(),
      praise_level: dataCopy.praise_level,
      share_with_patient: dataCopy.share_with_patient,
      message_owner: dataCopy.message_owner,
      sender_ids: dataCopy.sender_ids,
    };

    if (caseId) {
      addHistoryEntryMutation(caseHistoryBody);
      return;
    }

    if (caseIds) addHistoryEntriesMutation(caseHistoryBody);
  };

  const isLoading = caseId
    ? isAddHistoryEntryLoading
    : isAddHistoryEntriesLoading;

  const renderButtons = (submitForm: () => void, isValid: boolean) => {
    let buttons = (
      <div className="d-flex w-100 justify-content-end">
        <LoadingButton
          isLoading={isLoading}
          disabled={!isValid}
          onClick={submitForm}
        >
          <FormattedMessage id="CaseCard.History.sendMessage" />
        </LoadingButton>
      </div>
    );

    if (isEditMode) {
      buttons = (
        <>
          <LoadingButton
            className="CreateEntryModal__delete"
            color="primary"
            outline
            onClick={onDelete}
            type="button"
            isLoading={isDeleteLoading}
          >
            <FormattedMessage id="CaseCard.History.deleteEntry" />
          </LoadingButton>
          <LoadingButton
            className="CreateEntryModal__edit"
            isLoading={isLoading}
            disabled={!isValid}
            onClick={submitForm}
          >
            <FormattedMessage id="CaseCard.History.editEntry" />
          </LoadingButton>
        </>
      );
    }

    return buttons;
  };

  const additionalFieldsRendered = useMemo(() => {
    const additionalFields: JSX.Element[] = [];

    if (extendedValues?.new_category_id) {
      additionalFields.push(
        <FormGroup>
          <Label htmlFor="new_category">
            <FormattedMessage id="CaseCard.History.newCategory" />
          </Label>
          <CategorySelect
            organizationId={organizationId}
            isDefaultInput={true}
            value={extendedValues.new_category_id || ''}
            id="new_category_id"
            name="new_category_id"
            disabled
          />
          <FormikErrorMessage name="new_category" />
        </FormGroup>
      );
    }

    if (
      extendedValues?.new_sub_category_id &&
      extendedValues?.category_reference
    )
      additionalFields.push(
        <FormGroup>
          <Label htmlFor="new_sub_category">
            <FormattedMessage id="CaseCard.History.newSubCategory" />
          </Label>
          <SubCategorySelect
            organizationId={organizationId}
            isDefaultInput={true}
            categoryId={extendedValues?.category_reference}
            value={extendedValues.new_sub_category_id || ''}
            disabled
            id="new_sub_category"
            name="new_sub_category"
          />
          <FormikErrorMessage name="new_sub_category" />
        </FormGroup>
      );

    if (extendedValues?.new_status)
      additionalFields.push(
        <FormGroup>
          <Label htmlFor="new_status">
            <FormattedMessage id="CaseCard.History.newStatus" />
          </Label>
          <Field
            disabled
            value={extendedValues.new_status}
            id="new_status"
            name="new_status"
            placeholder="new_status"
            component={FieldInput}
          />
          <FormikErrorMessage name="new_status" />
        </FormGroup>
      );

    return additionalFields;
  }, [
    extendedValues?.category_reference,
    extendedValues.new_category_id,
    extendedValues.new_status,
    extendedValues.new_sub_category_id,
    organizationId,
  ]);

  let messageTranslationId = 'CaseCard.History.createHeader';
  if (customTitleTranslationId) {
    messageTranslationId = customTitleTranslationId;
  } else if (isEditMode) {
    messageTranslationId = 'CaseCard.History.editHeader';
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggleModal}
      className="CreateEntryModal"
      size="lg"
    >
      <ModalHeader toggle={toggleModal}>
        <img src={emailIcon} className="me-2" width={20} alt="case_icon" />
        <FormattedMessage id={messageTranslationId} />
        {selectedDate && (
          <div className="DayDetails__date">
            <img src={calendarIcon} alt="calendar_icon" />
            {returnSelectedDateString(selectedDate)}
          </div>
        )}
      </ModalHeader>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {({ submitForm, isValid, values, setValues }) => (
          <>
            <ModalBody>
              <CaseHistoryForm
                hidePredefinedMessages={hidePredefinedMessages}
                hidePraise={hidePraise}
                hideShare={hideShare}
                hideOwnerSelect={hideOwnerSelect}
                values={values}
                extendedFields={additionalFieldsRendered}
                setValues={setValues}
                caseId={caseId ?? ''}
                caseIds={caseIds}
                organizationId={organizationId}
              />
            </ModalBody>
            <ModalFooter>{renderButtons(submitForm, isValid)}</ModalFooter>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default CaseHistoryModal;
