import { useState } from 'react';

export const useSegmentSelection = () => {
  const [selectedSegmentIndex, setSelectedSegmentIndex] = useState<
    number | undefined
  >(undefined);

  const handleSelectSegment = (_: any, index: number | undefined) => {
    setSelectedSegmentIndex(index);
  };

  const handleResetSegment = () => setSelectedSegmentIndex(undefined);

  return { selectedSegmentIndex, handleSelectSegment, handleResetSegment };
};
