import useProjectByCodes from 'api/queries/projects/useProjectByCode';
import { AVAILABLE_CULTURES } from 'components/CultureSelector/CultureSelector';
import { useEffect, useState } from 'react';
import languageDictionary from 'translations/utils/languageDictionary';
import { getCulture } from 'translations/utils/cultures';

const returnCultureBaseOnBrowserCulture = () => {
  return languageDictionary.no.includes(getCulture())
    ? AVAILABLE_CULTURES.NO
    : AVAILABLE_CULTURES.EN;
};

function useProjectLanguage(organizationCode: string, projectCode: string) {
  const [isLangInitialized, setIsLangInitialized] = useState(false);

  const [selectedCulture, setSelectedCulture] = useState<AVAILABLE_CULTURES>(
    () => AVAILABLE_CULTURES.EN
  );

  const { data, isFetched } = useProjectByCodes({
    params: { organizationCode, projectCode },
    options: { enabled: false },
  });

  useEffect(() => {
    if (isFetched && !isLangInitialized) {
      setIsLangInitialized(true);

      if (
        data?.default_culture &&
        Object.values(AVAILABLE_CULTURES).includes(
          data?.default_culture as AVAILABLE_CULTURES
        )
      ) {
        setSelectedCulture(data?.default_culture as AVAILABLE_CULTURES);
      } else setSelectedCulture(returnCultureBaseOnBrowserCulture());
    }
  }, [data, isFetched, isLangInitialized]);

  return {
    selectedCulture,
    setSelectedCulture,
    isLangInitialized,
  };
}

export default useProjectLanguage;
