import AddIcon from '@mui/icons-material/Add';
import QueryLoaded from 'components/QueryLoaded';
import React from 'react';
import useOrganizationDevices from '../../../../../../../../api/queries/organizations/useOrganizationDevices';
import Guard from '../../../../../../../../components/Guard';
import IconButton from '../../../../../../../../components/IconButton';
import TabLayout, {
  TabLayoutType,
} from '../../../../../../../../components/layouts/TabLayout';
import { useOrganizationPathParams } from '../../../../../../../../hooks/paths/useOrganizationPathParams';
import { useInitQuery } from '../../../../../../../../hooks/queries/useInitQuery';
import useOpen from '../../../../../../../../hooks/useOpen';
import { UserRoles } from '../../../../../../../../interfaces/membership';
import AddDeviceModal from './modals/AddDeviceModal';
import OtherDevicesTable from './tables/OtherDevicesTable';

const OtherDevices = () => {
  const { organizationId } = useOrganizationPathParams();

  const addDeviceModal = useOpen();

  const organizationDevices = useOrganizationDevices({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(organizationDevices);

  return (
    <TabLayout
      type={TabLayoutType.Breadcrumb}
      titlePrefix="Organizations.OtherDevices"
      actions={
        <Guard roleNeeded={UserRoles.GLOBAL_ADMIN}>
          <IconButton
            color="primary"
            variant="outlined"
            onClick={addDeviceModal.toggle}
          >
            <AddIcon />
          </IconButton>
        </Guard>
      }
    >
      <QueryLoaded query={organizationDevices}>
        {(data) => (
          <>
            <AddDeviceModal
              isOpen={addDeviceModal.isOpen}
              toggle={addDeviceModal.toggle}
            />
            <OtherDevicesTable devices={data} />
          </>
        )}
      </QueryLoaded>
    </TabLayout>
  );
};

export default OtherDevices;
