import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row } from 'reactstrap';

type TabLayoutProps = {
  children: JSX.Element;
  title: string;
  description?: string | JSX.Element;
  imageSrc?: string;
  noOffset?: boolean;
};

const TabLayout = ({
  children,
  title,
  description,
  imageSrc,
  noOffset = false,
}: TabLayoutProps) => {
  const content = noOffset ? (
    <Row>
      <Col md={{ size: 10, offset: 1 }}>{children}</Col>
    </Row>
  ) : (
    children
  );

  return (
    <div className="w-100 SettingsTab">
      <div className="SettingsTab__head d-flex align-items-center">
        {imageSrc && (
          <img
            src={imageSrc}
            alt={imageSrc}
            className="SettingsTab__head__logo"
          />
        )}
        <h3 className="SettingsTab__head__title">
          <FormattedMessage id={title} />
        </h3>
      </div>
      {description && (
        <div className="SettingsTab__description">{description}</div>
      )}
      {content}
    </div>
  );
};

export default TabLayout;
