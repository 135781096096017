import React from 'react';
import { Col } from 'reactstrap';

const EmptyLayout = ({ children }: React.PropsWithChildren) => {
  return (
    <div className="min-vh-100 w-100 d-flex align-items-center">
      <Col
        xl={{ size: 8, offset: 2 }}
        md={{ size: 10, offset: 1 }}
        sm={12}
        xs={12}
      >
        {children}
      </Col>
    </div>
  );
};

export default EmptyLayout;
