import { useCaseGroupsCommonData } from '../../../../../hooks/useCaseGroupsCommonData';
import { useInitQuery } from '../../../../../../../hooks/queries/useInitQuery';
import { AVAILABLE_CULTURES } from '../../../../../../../components/CultureSelector/CultureSelector';
import { ICategory } from '../../../../../../../interfaces/cases';
import { ISummarizedBaseCaseGroup } from '../../../../../../../interfaces/caseGroups';
import { useCallback, useMemo } from 'react';

export const useCaseCategories = (caseGroup: ISummarizedBaseCaseGroup) => {
  const { caseCategories } = useCaseGroupsCommonData();

  useInitQuery(caseCategories);

  const currentCaseCategory = useMemo(() => {
    return caseCategories.data?.find(
      (p) => p.id === caseGroup.case_category_id
    );
  }, [caseCategories.data, caseGroup.case_category_id]);

  const getCurrentCaseCategoryName = useCallback(
    (culture: AVAILABLE_CULTURES, category: ICategory) => {
      return culture === AVAILABLE_CULTURES.NO
        ? category.name_no
        : category.name_en;
    },
    []
  );

  return {
    currentCaseCategory,
    getCurrentCaseCategoryName,
  };
};
