import React, { useState } from 'react';
import { FieldProps } from 'formik';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { useIconDefinitionCheck } from '../../../ThinHeader/hooks/useIconDefinitionCheck';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SVG from 'react-inlinesvg';

export type FieldInlineSelectOptionDefinition = {
  handleChange?: () => void;
  highlight?: boolean;
  icon?: IconDefinition | string;
  title: string;
  value: any;
  disabled?: boolean;
};

interface FieldInlineSelectProps extends Partial<FieldProps> {
  options: FieldInlineSelectOptionDefinition[];
  bordered?: boolean;
  disabled?: boolean;
}

const FieldInlineSelect = ({
  form,
  field,
  disabled,
  bordered = false,
  options,
}: FieldInlineSelectProps) => {
  const isIconDefinition = useIconDefinitionCheck();

  const [optionValueSelected, setOptionValueSelected] = useState<any>(
    options.find((p) => p.value === field?.value)?.value ?? options.at(0)?.value
  );

  if (options.length === 0) return <></>;

  return (
    <div
      className={classNames({
        FieldInlineSelect: true,
        'FieldInlineSelect--disabled': disabled,
        'FieldInlineSelect--bordered': bordered,
      })}
    >
      {options.map((option) => {
        const handleSelectOption = () => {
          if (option.disabled) return;

          if (option.handleChange) {
            option.handleChange();
            return;
          }

          setOptionValueSelected(option.value);

          if (!form || !field) return;

          form.setFieldValue(field.name, option.value, true);
        };

        return (
          <div
            className={classNames({
              FieldInlineSelect__option: true,
              'FieldInlineSelect__option--selected':
                optionValueSelected === option.value &&
                !options.find((p) => p.highlight),
              'FieldInlineSelect__option--highlighted': option.highlight,
            })}
            key={option.value}
            onClick={handleSelectOption}
          >
            {option.icon && (
              <>
                {isIconDefinition(option.icon) ? (
                  <FontAwesomeIcon size="sm" icon={option.icon} />
                ) : (
                  <SVG src={option.icon} title={option.value} />
                )}
              </>
            )}
            <span>{option.title}</span>
          </div>
        );
      })}
    </div>
  );
};

export default FieldInlineSelect;
