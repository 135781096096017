import React, { RefObject } from 'react';
import { useOverlapGroups } from './hooks/useOverlapGroups';
import { ProcedureActivity } from '../../../../forms/AwareCaseDetailsForm/types';

type OverlappingGroupsProviderProps = {
  startDate: string;
  endDate: string;
  procedureEvents: ProcedureActivity[];
  divRef: RefObject<HTMLDivElement>;
  children: (groups: number[][]) => JSX.Element;
};

const OverlappingGroupsProvider = ({
  startDate,
  endDate,
  procedureEvents,
  children,
  divRef,
}: OverlappingGroupsProviderProps) => {
  if (!divRef.current?.clientWidth) return <></>;

  return (
    <CurrentDivProvider
      divRef={divRef}
      startDate={startDate}
      endDate={endDate}
      procedureEvents={procedureEvents}
    >
      {children}
    </CurrentDivProvider>
  );
};

const CurrentDivProvider = ({
  startDate,
  endDate,
  procedureEvents,
  children,
  divRef,
}: OverlappingGroupsProviderProps) => {
  const groups = useOverlapGroups(divRef, startDate, endDate, procedureEvents);
  return children(groups);
};

export default OverlappingGroupsProvider;
