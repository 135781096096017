import { useCallback } from 'react';
import { useTypedContext } from '../../../../../../../../../hooks/useTypedContext';
import { DashboardSelectionHelpers } from '../../../helpers/selection';
import { DashboardDataReducerContext } from '../../../types';
import { DashboardDataActions } from '../../DashboardDataContent/store/actions';

export const useHandleClearAll = () => {
  const [dashboardData, dispatch] = useTypedContext(
    DashboardDataReducerContext
  );

  return useCallback(() => {
    dispatch({
      type: DashboardDataActions.DashboardDataActionType.EditTemplate,
      payload: {
        ...dashboardData.template,
        filters: DashboardSelectionHelpers.ToggleAll(
          dashboardData.template.filters,
          'select'
        ),
      },
    });
  }, [dashboardData.template, dispatch]);
};
