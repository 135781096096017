import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import { useIntl } from 'react-intl';
import IconButton from '../../../../../../../../../../../../components/IconButton';
import { toDateOnly } from '../../../../../../../../../../../../helpers/dates';
import useOpen from '../../../../../../../../../../../../hooks/useOpen';
import ScheduleLayout from '../../layouts/ScheduleLayout';
import DaySchedule from './components/DaySchedule';
import AddNotificationModal from './modals/AddNotificationModal';

type NotificationScheduleProps = {
  selectedDate: string;
};

const NotificationSchedule = ({ selectedDate }: NotificationScheduleProps) => {
  const intl = useIntl();
  const addNotificationModal = useOpen();

  return (
    <>
      {addNotificationModal.isOpen && (
        <AddNotificationModal
          isOpen={addNotificationModal.isOpen}
          toggle={addNotificationModal.toggle}
          selectedDate={selectedDate}
        />
      )}
      <ScheduleLayout
        title={intl.formatMessage({ id: 'Schedules.NotificationSchedule' })}
        schedule={<DaySchedule selectedDate={selectedDate} />}
        actions={[
          <IconButton
            key="add-notification"
            color="primary"
            variant="outlined"
            disabled={selectedDate <= toDateOnly(new Date())}
            onClick={addNotificationModal.toggle}
          >
            <AddIcon />
          </IconButton>,
        ]}
      />
    </>
  );
};

export default NotificationSchedule;
