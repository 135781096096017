import { unshareGraspWithOrganizationUser } from 'api/graspManagement';
import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from 'modules/Notifications';

type MutationsUnshareParams = {
  graspId: string;
  personalityId: string;
  organizationId: string;
};

function useGraspUnshareWithPersonality(successFb?: () => void) {
  return useMutationWithResultNotification<MutationsUnshareParams, void>({
    asyncApiFunction: async ({ graspId, personalityId, organizationId }) => {
      await unshareGraspWithOrganizationUser(
        organizationId,
        personalityId,
        graspId
      );
    },
    resetOnResult: true,
    onSuccess: () => {
      displaySuccessNotification('Grasps.unlinkSuccess');
      successFb && successFb();
    },
    onError: () => {
      displayErrorNotification('Grasps.unlinkError');
    },
  });
}

export default useGraspUnshareWithPersonality;
