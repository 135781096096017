import React, { useState } from 'react';
import {
  AssetStatus,
  AssetType,
  IAsset,
  IEducationMaterial,
} from '../../../../../../../../../../../../interfaces/educationMaterials';
import { useAssetIcon } from '../../hooks/useAssetIcon';
import { textWrapEllipse } from '../../../../../../../../../../../../helpers/utils/strings';
import { Badge, Input } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { useTranslations } from '../../../../../../../../../../../../hooks/useTranslations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import { useThumbnailUpload } from './hooks/useThumbnailUpload';
import AssetCulturesSelector from '../../../../../../../../../components/AssetCulturesSelector';
import { cloneDeep } from 'lodash';

type ReadOnlyItemProps = {
  asset: IAsset;
  material: IEducationMaterial;
  setMaterial: (material: IEducationMaterial) => void;
};

const ReadOnlyItem = ({ asset, material, setMaterial }: ReadOnlyItemProps) => {
  const [uploadedThumbnail, setUploadedThumbnail] = useState<string>('');

  const { attachPrefix } = useTranslations(
    'Education.MaterialModal.MaterialModalBody.UploadAssetsStep.UploadAssetsStepItem.ReadOnlyItem'
  );

  const assetIcon = useAssetIcon(
    asset.type,
    asset.thumbnail_url ?? uploadedThumbnail ?? undefined
  );

  const onUploadThumbnail = (url: string) => {
    setUploadedThumbnail(url);
  };

  const { onThumbnailChange, isUploading, acceptType } = useThumbnailUpload(
    asset.id,
    onUploadThumbnail
  );

  const onLink = (culture: string) => {
    let materialCopy = cloneDeep(material);

    const cultureIndex = materialCopy.assets_with_cultures.findIndex(
      (p) => p.culture === culture
    );

    if (cultureIndex === -1) {
      materialCopy.assets_with_cultures.push({
        culture: culture,
        value: [asset],
      });
    } else {
      materialCopy.assets_with_cultures[cultureIndex].value.push(asset);
    }

    setMaterial({
      ...material,
      assets_with_cultures: materialCopy.assets_with_cultures,
    });
  };

  const onUnlink = (culture: string) => {
    let materialCopy = cloneDeep(material);

    const cultureIndex = materialCopy.assets_with_cultures.findIndex(
      (p) => p.culture === culture
    );

    if (cultureIndex === -1) return;

    materialCopy.assets_with_cultures[cultureIndex].value =
      materialCopy.assets_with_cultures[cultureIndex].value.filter(
        (p) => p.id !== asset.id
      );

    setMaterial({
      ...material,
      assets_with_cultures: materialCopy.assets_with_cultures,
    });
  };

  return (
    <div className="d-flex align-items-center border shadow rounded p-2">
      <div className="py-3 px-4">{assetIcon}</div>
      <div className="w-100 d-flex flex-column">
        <div className="d-flex align-items-center gap-2">
          <span>{textWrapEllipse(asset.name, 50)}</span>
          <span className="text-muted">
            {textWrapEllipse(asset.description, 50)}
          </span>
        </div>
        <div className="d-flex align-items-center gap-2">
          <Badge color="primary">
            <FormattedMessage id={attachPrefix('uploaded')} />
          </Badge>
          {asset && asset?.status === AssetStatus.Encoded ? (
            <Badge color="primary">
              <FormattedMessage id={attachPrefix('encoded')} />
            </Badge>
          ) : (
            <>
              {asset.type === AssetType.Video && (
                <Badge color="secondary">
                  <FormattedMessage id={attachPrefix('transcoding')} />
                </Badge>
              )}
            </>
          )}
          <AssetCulturesSelector
            asset={asset}
            material={material}
            onUnlink={onUnlink}
            onLink={onLink}
          />
        </div>
      </div>
      <div className="me-3 text-muted">
        <div className="image-upload">
          <label htmlFor={`${asset?.id}|thumbnail-upload`}>
            {isUploading ? (
              <FontAwesomeIcon icon={faSpinnerThird} size="lg" spin={true} />
            ) : (
              <FontAwesomeIcon icon={faImage} size="lg" />
            )}
          </label>
          <Input
            id={`${asset?.id}|thumbnail-upload`}
            className="d-none"
            type="file"
            onChange={onThumbnailChange}
            accept={acceptType}
          />
        </div>
      </div>
    </div>
  );
};

export default ReadOnlyItem;
