import { fieldsDefinitionOptions } from '../../../../../../../helpers/utils/translationObject';
import { useMemo } from 'react';
import { FieldInlineSelectOptionDefinition } from '../../../../../../../components/Fields/Formik/FieldInlineSelect';
import { useIntl } from 'react-intl';

export const useProjectModalRegistrationFieldsForm = () => {
  const intl = useIntl();

  const options = useMemo(() => {
    let _options: FieldInlineSelectOptionDefinition[] = [];

    fieldsDefinitionOptions.forEach((option) => {
      _options.push({
        value: option.value,
        title: intl.formatMessage({ id: option.translationId }),
      });
    });

    return _options;
  }, [intl]);

  return {
    options: options,
  };
};
