import { TrendVisualizationTemplate } from 'interfaces/trendVisualizations';
import { cloneDeep } from 'lodash';
import { AVAILABLE_CULTURES } from 'components/CultureSelector/CultureSelector';

export const getEmptyTrendVisualizationTemplate = (
  organizationId: string
): TrendVisualizationTemplate => {
  return {
    id: '',
    name: '',
    visualizations: [],
    owner_id: organizationId,
  };
};

export const getInitialValuesBasedOnTemplate = (
  template: TrendVisualizationTemplate
) => {
  let templateCopy = cloneDeep(template);

  templateCopy.visualizations = templateCopy.visualizations.map(
    (visualization) => {
      if (visualization.title_translations.length === 2) return visualization;

      let defaultTranslation = visualization.title_translations.find(
        (p) => p.culture === AVAILABLE_CULTURES.EN
      );

      let norwegianTranslation = visualization.title_translations.find(
        (p) => p.culture === AVAILABLE_CULTURES.NO
      );

      if (!defaultTranslation)
        defaultTranslation = {
          culture: AVAILABLE_CULTURES.EN,
          value: visualization.title,
        };

      if (!norwegianTranslation)
        norwegianTranslation = {
          culture: AVAILABLE_CULTURES.NO,
          value: visualization.title,
        };

      return {
        ...visualization,
        title_translations: [defaultTranslation, norwegianTranslation],
      };
    }
  );

  return templateCopy;
};
