import React from 'react';
import { secondsToDurationString } from '../../../../../../../../../../helpers/dates';

const SqueezeTimelineXAxisTick = (props: any) => {
  return (
    <g
      transform={`translate(${props.x},${props.y})`}
      style={{ cursor: 'pointer', zIndex: 100 }}
    >
      <text
        x={0}
        y={0}
        dy={10}
        textAnchor="middle"
        fill={
          props.index === 0 || props.index === props.visibleTicksCount - 1
            ? '#313131'
            : 'transparent'
        }
        fontSize={14}
      >
        {props.payload.value === 'auto'
          ? ''
          : secondsToDurationString(props.payload.value / 1000)}
      </text>
    </g>
  );
};

export default SqueezeTimelineXAxisTick;
