import { useTrendsChartConfig } from 'components/TrendsChart/hooks/useTrendsChartConfig';
import { getChartIntervalBasedOnDateRange } from 'helpers/dates';
import useCultures from 'hooks/cultures/useCultures';
import useMobile from 'hooks/useBreakpoint';
import { ConversationQuestion } from 'interfaces/conversations';
import { IOrganizationObservationMeaning } from 'interfaces/observationMeanings';
import { TrendVisualization } from 'interfaces/trendVisualizations';
import { useContext } from 'react';
import { ChangeCaseDashboardDateRangeContext } from '../../../../../index';
import { useAxisTicks } from './useAxisTicks';

export const useChartConfig = (
  visualization: TrendVisualization,
  questions: ConversationQuestion[],
  meanings: IOrganizationObservationMeaning[]
) => {
  const isMobile = useMobile();

  const { currentCulture, defaultCulture } = useCultures();

  const dateRangeObject = useContext(ChangeCaseDashboardDateRangeContext);

  const { initialValue, onXAxisTick } = useAxisTicks();

  let config = useTrendsChartConfig(questions, meanings);

  let visualizationTitle = visualization.title;

  if (currentCulture.cultureCode !== defaultCulture.cultureCode)
    visualizationTitle =
      visualization.title_translations.find(
        (p) => p.culture === currentCulture.cultureCode
      )?.value ?? visualization.title;

  config.header = {
    title: visualizationTitle,
    titleEnabled: true,
    subtitleEnabled: true,
  };

  config.actions = [{ type: 'download' }];

  const defaultInterval = isMobile ? 1 : 0;

  const xAxisInterval =
    dateRangeObject?.from && dateRangeObject.to
      ? getChartIntervalBasedOnDateRange({
          from: dateRangeObject.from,
          to: dateRangeObject.to,
        })
      : defaultInterval;

  config.axis = [
    {
      axis: 'x-axis',
      onTick: onXAxisTick,
      initialValue: initialValue.toISOString(),
      interval: xAxisInterval,
    },
  ];

  return config;
};
