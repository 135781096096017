import { IMutationRequest } from '../../../interfaces/request';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { IScheduleAwareSessionData } from '../../../interfaces/awareSessions';
import { scheduleAwareSessionData } from '../../awareSessions';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { IErrorDefinition } from '../../../interfaces/response';

type ScheduleAwareSessionDataParams = {
  organizationId: string;
};

const useScheduleAwareSessionData = ({
  params,
  options,
  silent,
}: IMutationRequest<ScheduleAwareSessionDataParams>) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (body: IScheduleAwareSessionData) => {
      await scheduleAwareSessionData(params.organizationId, body);
    },
    onSuccess: () => {
      if (!silent)
        displaySuccessNotification(
          'Api.Mutations.AwareSessions.ScheduleAwareSession.success'
        );

      options.successFb?.();
    },
    onErrorArray: (errors: IErrorDefinition[]) => {
      displayErrorArrayNotifications(
        errors,
        'Api.Mutations.AwareSessions.ScheduleAwareSession.failure'
      );
      options.errorFb?.();
    },
    resetOnResult: true,
  });
};

export default useScheduleAwareSessionData;
