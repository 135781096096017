import React, { createContext, PropsWithChildren, useMemo } from 'react';
import { ICaseDetails } from '../../../../../../../../interfaces/cases';
import { IAwareSessionData } from '../../../../../../../../interfaces/awareSessions';
import { IObservation } from '../../../../../../../../interfaces/timeline';
import { ConversationQuestion } from '../../../../../../../../interfaces/conversations';
import { IPersonality } from '../../../../../../../../interfaces/personalities';
import { ICaseProcedureHistory } from '../../../../../../../../interfaces/caseHistory';

type OperationalDataContextType = {
  caseDetails: ICaseDetails;
  awareSessionDetails: IAwareSessionData;
  caseProcedureHistory: ICaseProcedureHistory[];
  observations: IObservation[];
  questions: ConversationQuestion[];
  members: IPersonality[];
};

export const OperationalDataContext = createContext<
  OperationalDataContextType | undefined
>(undefined);

type OperationalDataProviderProps = {
  caseDetails: ICaseDetails;
  awareSessionDetails: IAwareSessionData;
  caseProcedureHistory: ICaseProcedureHistory[];
  observations: IObservation[];
  questions: ConversationQuestion[];
  members: IPersonality[];
};

const OperationalDataProvider = ({
  awareSessionDetails,
  caseProcedureHistory,
  caseDetails,
  observations,
  questions,
  members,
  children,
}: PropsWithChildren<OperationalDataProviderProps>) => {
  const operationalData = useMemo(() => {
    return {
      awareSessionDetails,
      caseProcedureHistory,
      caseDetails,
      observations,
      questions,
      members,
    };
  }, [
    awareSessionDetails,
    caseDetails,
    caseProcedureHistory,
    members,
    observations,
    questions,
  ]);

  return (
    <OperationalDataContext.Provider value={operationalData}>
      {children}
    </OperationalDataContext.Provider>
  );
};

export default OperationalDataProvider;
