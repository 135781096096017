import HorizontalTabContainer from 'components/Tabs/components/HorizontalTabContainer';
import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useTypedContext } from '../../../../../../../../hooks/useTypedContext';
import { OrganizationRegisterViewStepsContext } from '../../../../types';
import CreateOrganizationForm from './forms/CreateOrganizationForm';
import { useFormNavigation } from './forms/hooks';
import SignUpForm from './forms/SignUpForm';
import { FormsNavigationContext } from './forms/types';

const FormsStep = () => {
  const steps = useTypedContext(OrganizationRegisterViewStepsContext);
  const { next, prev, activeTab } = useFormNavigation(2, steps.next);

  const navigation = useMemo(() => {
    return { next, prev };
  }, [next, prev]);

  return (
    <FormsNavigationContext.Provider value={navigation}>
      <HorizontalTabContainer
        controlled
        items={[
          {
            header: <FormattedMessage id="Organizations.CreateOrganization" />,
            component: <CreateOrganizationForm />,
            shouldBeRendered: activeTab === 0,
          },
          {
            header: <FormattedMessage id="General.SignUp" />,
            component: <SignUpForm />,
            shouldBeRendered: activeTab === 1,
          },
        ]}
      />
    </FormsNavigationContext.Provider>
  );
};

export default FormsStep;
