import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import useSendFeedback from '../../../../../../../../../api/mutations/membership/useSendFeedback';
import useCultures from '../../../../../../../../../hooks/cultures/useCultures';
import { OpenHookValues } from '../../../../../../../../../hooks/useOpen';
import useUrlSearch from '../../../../../../../../../hooks/useUrlSearch';
import {
  HelpContactForm,
  HelpRequestedSubject,
  OtherTranslationId,
} from '../../../types';

export type HelpCentreFormInitialValues = {
  apple_id?: string;
  culture: string;
  user?: string;
  category?: string;
  deviceType?: string;
  topic: string;
  description?: string;
  contactForm: HelpContactForm;
  contactContext?: string;
};

export const useForm = (confirmation: OpenHookValues) => {
  const { defaultCulture } = useCultures();

  const query = useUrlSearch();
  const appleId = query.get('appleId');
  const culture = query.get('culture');
  const user = query.get('user');
  const category = query.get('category');
  const deviceType = query.get('deviceType');

  const intl = useIntl();
  const invalidFieldValue = intl.formatMessage({
    id: 'General.InvalidFieldValue',
  });

  const { mutateAsync: sendAsync } = useSendFeedback(confirmation.toggle);

  const initialValues = useMemo((): HelpCentreFormInitialValues => {
    return {
      apple_id: appleId ?? undefined,
      culture: culture ?? defaultCulture.cultureCode,
      user: user ?? undefined,
      category: category ?? undefined,
      deviceType: deviceType ?? undefined,
      topic: '',
      description: '',
      contactForm: HelpContactForm.Phone,
      contactContext: '',
    };
  }, [
    appleId,
    category,
    culture,
    defaultCulture.cultureCode,
    deviceType,
    user,
  ]);

  const validationSchema = Yup.object().shape({
    topic: Yup.string().min(1).required(invalidFieldValue),
    description: Yup.string().when('topic', {
      is: intl.formatMessage({ id: OtherTranslationId }),
      then: (schema) => schema.required(invalidFieldValue),
    }),
    contactForm: Yup.mixed<HelpContactForm>()
      .oneOf(Object.values(HelpContactForm))
      .required(),
    contactContext: Yup.string().required(invalidFieldValue),
  });

  const handleSubmit = useCallback(
    async (values: typeof initialValues) => {
      await sendAsync({
        subject: HelpRequestedSubject,
        category: 'HelpRequest',
        description: values.description ?? HelpRequestedSubject,
        email_contact:
          values.contactForm === HelpContactForm.Email
            ? values.contactContext ?? ''
            : '',
        phone_number_contact:
          values.contactForm !== HelpContactForm.Email
            ? values.contactContext ?? ''
            : '',
        importance: 'High',
        metadata: [
          {
            item: 'AppleId',
            value: values.apple_id,
          },
          {
            item: 'Culture',
            value: values.culture,
          },
          {
            item: 'User',
            value: values.user,
          },
          {
            item: 'Category',
            value: values.category,
          },
          {
            item: 'Topic',
            value: values.topic,
          },
          {
            item: 'DeviceType',
            value: values.deviceType,
          },
          {
            item: 'ContactForm',
            value: values.contactForm,
          },
        ],
      });
    },
    [sendAsync]
  );

  return {
    initialValues,
    handleSubmit,
    validationSchema,
  };
};
