import React from 'react';
import CustomTable from '../../../../components/CustomTable';
import { UseOrganizationReportsListParams } from '../../../../api/queries/reports/useOrganizationReportsList';
import { useCommonData } from '../../hooks/useCommonData';
import ReportsListItem from './components/ReportsListItem';

const headerItems = [
  'Reports.ReportsList.orderedAt',
  'Reports.ReportsList.requestedFor',
  'Reports.ReportsList.reportType',
  'Reports.ReportsList.execution',
  'Reports.ReportsList.download',
];

type ReportsListParams = {
  filters: UseOrganizationReportsListParams;
};

const ReportsList = ({ filters }: ReportsListParams) => {
  const { reports } = useCommonData(filters);

  const bodyRows = (reports.data ?? []).map((item) => (
    <ReportsListItem key={item.id} item={item} />
  ));

  return (
    <>
      <CustomTable
        isLoading={reports.isLoading}
        headerItems={headerItems}
        bodyRows={bodyRows}
      />
    </>
  );
};

export default ReportsList;
