import { TrendVisualizationTemplate } from '../../../../../../../interfaces/trendVisualizations';
import { useMemo } from 'react';

export const useQuestionIds = (
  template: TrendVisualizationTemplate | undefined
) => {
  return useMemo(() => {
    const nullableQuestionIds =
      template?.visualizations
        .flatMap((p) => p.elements)
        .map((p) => p.question_id) ?? [];
    return nullableQuestionIds.filter((p) => p !== null).map((p) => p ?? '');
  }, [template?.visualizations]);
};
