import { OrganizationQueryParams } from '../../../interfaces/queryParams';
import { IMutationProps } from '../../../interfaces/ui';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { createGraspLedLevel } from '../../graspManagement';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { ICreateGraspLedLevel } from '../../../interfaces/grasps';

const useAddGraspLedLevel = (
  params: OrganizationQueryParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (body: ICreateGraspLedLevel) => {
      await createGraspLedLevel(params.organizationId, body);
    },
    resetOnResult: true,
    onSuccess: () => {
      displaySuccessNotification('MyOrganizations.grasps.createSuccess');
      if (options.successFb) options.successFb();
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'MyOrganizations.grasps.createErrors'
      );
    },
  });
};

export default useAddGraspLedLevel;
