import FullScreenSpinner from 'components/Spinners/FullScreenSpinner';
import React from 'react';
import { DashboardTemplate } from '../../../../../../../../../../../../../../interfaces/dashboards';
import { useResolveDateSelection } from '../../../../../../../../../../resolvers/useResolveDateSelection';
import { useResolveFilters } from '../../../../../../../../../../resolvers/useResolveFilters';
import { DashboardResolvingHelpers } from '../../../../../../../../../DashboardContentContainer/helpers/resolving';
import DataNotProvided from '../../components/DataNotProvided';

type EventDataResolverProps = {
  context: any;
  template: DashboardTemplate;
  children: (resolvedTemplate: DashboardTemplate) => JSX.Element;
};

const EventDataResolver = ({
  template,
  children,
  context,
}: EventDataResolverProps) => {
  const dateSelectionResolved = useResolveDateSelection(template, {});

  if (
    !DashboardResolvingHelpers.ShouldTemplateBeResolved(dateSelectionResolved)
  )
    return children(dateSelectionResolved);

  if (context === undefined) return <DataNotProvided />;

  return (
    <EventDataContextResolver
      context={context}
      template={template}
      children={children}
    />
  );
};

const EventDataContextResolver = ({
  context,
  template,
  children,
}: Omit<EventDataResolverProps, 'context'> & {
  context: any;
}) => {
  const filtersResolved = useResolveFilters(template, context);

  const dateSelectionResolved = useResolveDateSelection(
    filtersResolved,
    context
  );

  if (DashboardResolvingHelpers.ShouldTemplateBeResolved(dateSelectionResolved))
    return <FullScreenSpinner />;

  return children(dateSelectionResolved);
};

export default EventDataResolver;
