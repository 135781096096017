import React, { useEffect, useMemo } from 'react';
import {
  BubbleType,
  instanceOfObservationBubbleData,
  ObservationSymptom,
  SingleDataBubbleValue,
} from 'interfaces/timeline';
import { FormattedMessage } from 'react-intl';
import { returnColorBaseOnSymptom } from 'helpers/colors';
import classNames from 'classnames';

//icons
import annotationIcon from 'assets/images/timeline_details/chart_annotation.svg';
import historyIcon from 'assets/images/timeline_details/chart_history.svg';
import assessment_form_good from 'assets/images/timeline_details/assessment_form_good.svg';

const getUniqueMeanings = (data: SingleDataBubbleValue[]) => {
  const observations = data.filter((el) => el.type === BubbleType.OBSERVATION);

  const uniqueMeanings: ObservationSymptom[] = [];

  for (const singleObservation of observations) {
    if (
      instanceOfObservationBubbleData(singleObservation) &&
      !uniqueMeanings.includes(singleObservation.symptom)
    ) {
      uniqueMeanings.push(singleObservation.symptom);
    }
  }

  return uniqueMeanings;
};

export type BubbleChartFilterProps = {
  data: SingleDataBubbleValue[];
  selectedMeanings: ObservationSymptom[];
  setSelectedMeanings: React.Dispatch<
    React.SetStateAction<ObservationSymptom[]>
  >;
  isAnnotationVisible: boolean;
  toggleAnnotations: () => void;
  isHistoryVisible: boolean;
  toggleHistory: () => void;
  isFormVisible: boolean;
  toggleForm: () => void;
};

const BubbleChartLegend = ({
  selectedMeanings,
  setSelectedMeanings,
  data,
  isAnnotationVisible,
  isFormVisible,
  isHistoryVisible,
  toggleAnnotations,
  toggleForm,
  toggleHistory,
}: BubbleChartFilterProps) => {
  const uniqueMeanings = useMemo(() => getUniqueMeanings(data), [data]);

  useEffect(() => {
    setSelectedMeanings(uniqueMeanings);
  }, [uniqueMeanings, setSelectedMeanings]);

  const isAllSelected =
    selectedMeanings.length === uniqueMeanings.length &&
    uniqueMeanings.every((value, index) => value === selectedMeanings[index]);

  const canFilterHistory =
    data.filter((d) => d.type === BubbleType.HISTORY_ENTRY).length > 0;
  const canFilterAnnotations =
    data.filter((d) => d.type === BubbleType.ANNOTATION).length > 0;

  const onToggleObservation = (name: ObservationSymptom) => {
    setSelectedMeanings((currentSelectedObservations) => {
      if (currentSelectedObservations.includes(name)) {
        if (isAllSelected) return [name];
        return currentSelectedObservations.filter((val) => val !== name);
      }
      return [...currentSelectedObservations, name];
    });
  };

  const renderedObservations = uniqueMeanings.map((name) => (
    <div
      key={name}
      className={classNames('BubbleChartFilter__item', 'cursor-pointer', {
        'BubbleChartFilter__item--selected':
          selectedMeanings.includes(name) && !isAllSelected,
      })}
      onClick={() => onToggleObservation(name)}
    >
      <div
        className="BubbleChartFilter__item__circle me-2"
        style={{
          background: returnColorBaseOnSymptom(name),
        }}
      />
      <FormattedMessage id={`TimelineDetails.meanings.${name}`} />
    </div>
  ));

  const onAllObservationClick = () => {
    if (isAllSelected) {
      setSelectedMeanings([]);
    } else setSelectedMeanings(uniqueMeanings);
  };

  return (
    <div className="BubbleChartFilter">
      <h3 className="BubbleChartFilter__header">
        <FormattedMessage id="TimelineDetails.dayByDayLegend.legend" />
      </h3>
      <p className="BubbleChartFilter__sub-header">
        <FormattedMessage id="TimelineDetails.dayByDayLegend.filterBySelectingType" />
      </p>
      <div className="BubbleChartFilter__item-wrapper w-100 mt-2">
        <div
          className={classNames('BubbleChartFilter__item', 'cursor-pointer', {
            'BubbleChartFilter__item--selected': isAllSelected,
          })}
          onClick={onAllObservationClick}
        >
          <FormattedMessage id="TimelineDetails.dayByDayLegend.allObservations" />
        </div>
        {renderedObservations}
      </div>
      <div className="BubbleChartFilter__item-wrapper w-100 mt-2">
        {canFilterHistory && (
          <div
            className={classNames('BubbleChartFilter__item', 'cursor-pointer', {
              'BubbleChartFilter__item--selected': isHistoryVisible,
            })}
            onClick={toggleHistory}
          >
            <img src={historyIcon} alt="history" className="me-2" />
            <FormattedMessage id="TimelineDetails.dayByDayLegend.history" />
          </div>
        )}
        {canFilterAnnotations && (
          <div
            className={classNames('BubbleChartFilter__item', 'cursor-pointer', {
              'BubbleChartFilter__item--selected': isAnnotationVisible,
            })}
            onClick={toggleAnnotations}
          >
            <img src={annotationIcon} alt="annotation" className="me-2" />
            <FormattedMessage id="TimelineDetails.dayByDayLegend.annotations" />
          </div>
        )}
        <div
          className={classNames('BubbleChartFilter__item', 'cursor-pointer', {
            'BubbleChartFilter__item--selected': isFormVisible,
          })}
          onClick={toggleForm}
        >
          <img src={assessment_form_good} alt="assessment" className="me-2" />
          <FormattedMessage id="TimelineDetails.dayByDayLegend.assessmentForms" />
        </div>
      </div>
    </div>
  );
};

export default BubbleChartLegend;
