import { cloneDeep } from 'lodash';
import {
  DashboardFilter,
  DashboardFilterType,
} from '../../../../../../../interfaces/dashboards';
import { TDashboardFilterOption, TDashboardTemplateOption } from '../types';

export namespace DashboardSelectionHelpers {
  export const SelectIncludedExcluded = <
    TDashboard extends TDashboardFilterOption,
    TSession extends TDashboardTemplateOption
  >(
    dashboardFilter: TDashboard,
    filter: TSession
  ): TDashboard => {
    if (!filter.selected_all) {
      if (filter.included.length > 0) {
        dashboardFilter.options = dashboardFilter.options.map((option) => {
          if (filter.included.includes(option.id)) {
            option.selected = true;
            return option;
          }

          return option;
        });
      }

      if (filter.excluded.length > 0) {
        dashboardFilter.options = dashboardFilter.options.map((option) => {
          option.selected = true;
          return option;
        });

        dashboardFilter.options = dashboardFilter.options.map((option) => {
          if (filter.excluded.includes(option.id)) {
            option.selected = false;
            return option;
          }

          return option;
        });
      }

      return dashboardFilter;
    }

    dashboardFilter.options = dashboardFilter.options.map((option) => {
      option.selected = true;
      return option;
    });

    return dashboardFilter;
  };

  export const IsGlobalSelected = (filters: DashboardFilter[]): boolean => {
    let globalSelected: boolean = true;

    for (const filter of filters) {
      let notSelectedOptions: boolean = false;

      switch (filter.type) {
        case DashboardFilterType.Member:
        case DashboardFilterType.Grasp:
        case DashboardFilterType.Organization:
        case DashboardFilterType.SourcePeriod:
        case DashboardFilterType.ClosedList:
          notSelectedOptions = filter.options.some((p) => !p.selected);
          break;
        case DashboardFilterType.Range:
          notSelectedOptions = filter.options !== null;
          break;
      }

      if (!notSelectedOptions) continue;

      globalSelected = false;
      break;
    }

    return globalSelected;
  };

  export const SelectionCount = (filters: DashboardFilter[]): number => {
    let selection: number = 0;

    for (const filter of filters) {
      switch (filter.type) {
        case DashboardFilterType.Member:
        case DashboardFilterType.Grasp:
        case DashboardFilterType.Organization:
        case DashboardFilterType.SourcePeriod:
        case DashboardFilterType.ClosedList:
          selection += filter.options.filter((p) => p.selected).length;
          break;
        case DashboardFilterType.Range:
          selection = filter.options !== null ? 1 : 0;
          break;
      }
    }

    return selection;
  };

  export const ToggleAll = (
    filters: DashboardFilter[],
    mode: 'select' | 'unselect'
  ) => {
    let filtersCopy = cloneDeep(filters);
    let unselectedFilters: DashboardFilter[] = [];

    for (const filter of filtersCopy) {
      switch (filter.type) {
        case DashboardFilterType.Member:
        case DashboardFilterType.Grasp:
        case DashboardFilterType.Organization:
        case DashboardFilterType.SourcePeriod:
        case DashboardFilterType.ClosedList:
          filter.options = filter.options.map((option) => ({
            ...option,
            selected: mode === 'select',
          }));
          unselectedFilters.push(filter);
          break;
        case DashboardFilterType.Range:
          filter.options = mode === 'select' ? filter.options : null;
          unselectedFilters.push(filter);
          break;
      }
    }

    return unselectedFilters;
  };
}
