import { getSubmissionForms } from 'api/submissions';
import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { UserQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

interface UseProjectAssessmentFormsParams extends UserQueryParams {
  submissionId: string;
}

function useProjectAssessmentForms({
  params,
  options,
}: IQueryRequest<UseProjectAssessmentFormsParams>) {
  const { userId, submissionId } = params;
  return useQuery({
    ...options,
    queryKey: [QueryKeys.getProjectAssessmentForms, userId, submissionId],
    queryFn: async () => {
      const { data } = await getSubmissionForms(userId, submissionId);

      return data.data;
    },
  });
}

export default useProjectAssessmentForms;
