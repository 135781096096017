import { faAngleRight, faHourglassEnd } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PersonalityImage from 'components/PersonalityImage';
import { iconColors } from 'helpers/colors';
import { IStrictPersonality } from 'interfaces/personalities';
import React, { ChangeEvent } from 'react';
import { ListGroupItem, UncontrolledTooltip } from 'reactstrap';

type PersonSelectionRowProps = {
  data: IStrictPersonality;
  onCheckPersonality: (personality: IStrictPersonality) => void;
  onCheckCase: (caseId: string, personalityId: IStrictPersonality) => void;
  isPersonalityChecked: boolean;
  casesChecked?: string[];
};

const PersonSelectionRow = ({
  casesChecked = [],
  data,
  isPersonalityChecked,
  onCheckPersonality,
  onCheckCase,
}: PersonSelectionRowProps) => {
  const isPersonalityEnabledToEdit =
    isPersonalityChecked || data.already_shared;

  const onCheckCaseHandler = (e: ChangeEvent<HTMLInputElement>) => {
    onCheckCase(e.target.name, data);
  };

  const onCheckPersonalityHandler = () => {
    onCheckPersonality(data);
  };

  const casesList = data.cases.map((strictCase) => {
    const isCaseChecked = casesChecked.includes(strictCase.id);

    const caseTargetId = `input-case-${strictCase.id}`;

    return (
      <li>
        <div className="d-flex align-items-center">
          {strictCase.already_requested && !strictCase.already_shared && (
            <UncontrolledTooltip placement="top" target={caseTargetId}>
              Already requested
            </UncontrolledTooltip>
          )}
          {strictCase.already_shared && (
            <UncontrolledTooltip placement="top" target={caseTargetId}>
              Already shared
            </UncontrolledTooltip>
          )}
          <div id={caseTargetId} className="d-flex">
            <input
              className={classNames({
                'RequestAccessModal__checkbox--shared':
                  strictCase.already_shared,
                'RequestAccessModal__checkbox--requested':
                  !strictCase.already_shared && strictCase.already_requested,
              })}
              name={strictCase.id}
              type="checkbox"
              onChange={onCheckCaseHandler}
              checked={isCaseChecked || strictCase.already_shared}
              id={`checkbox-${caseTargetId}`}
            />
            {!strictCase.already_shared && strictCase.already_requested && (
              <span className="RequestAccessModal__checkbox--requested-fake">
                <FontAwesomeIcon icon={faHourglassEnd} />
              </span>
            )}
          </div>
          <div className="ps-4 ">
            <label
              className="MedicalPersonnelAssignModal__member-item__data__name"
              htmlFor={`checkbox-${caseTargetId}`}
            >
              {strictCase.title}
            </label>
            <div className="MedicalPersonnelAssignModal__member-item__data__email">
              {strictCase.description}
            </div>
          </div>
        </div>
      </li>
    );
  });

  const hasPersonalityCases = data.cases.length > 0;

  const patientTargetId = `input-patient-${data.id}`;

  return (
    <ListGroupItem
      className={classNames('RequestAccessModal__patient-selection', {
        'RequestAccessModal__patient-selection--selected': isPersonalityChecked,
      })}
    >
      <div className="d-flex flex-wrap">
        {data.already_requested && !data.already_shared && (
          <UncontrolledTooltip placement="top" target={patientTargetId}>
            Already requested
          </UncontrolledTooltip>
        )}
        {data.already_shared && (
          <UncontrolledTooltip placement="top" target={patientTargetId}>
            Already shared
          </UncontrolledTooltip>
        )}
        <div id={patientTargetId} className="d-flex">
          <input
            type="checkbox"
            checked={isPersonalityChecked || data.already_shared}
            onChange={onCheckPersonalityHandler}
            className={classNames({
              'RequestAccessModal__checkbox--shared': data.already_shared,
              'RequestAccessModal__checkbox--requested':
                !data.already_shared && data.already_requested,
            })}
            id={`checkbox-${patientTargetId}`}
          />
          {!data.already_shared && data.already_requested && (
            <span className="RequestAccessModal__checkbox--requested-fake">
              <FontAwesomeIcon icon={faHourglassEnd} />
            </span>
          )}
        </div>
        <div className="ms-4">
          <div className="d-flex align-items-center">
            <PersonalityImage />
            <div className="MedicalPersonnelAssignModal__member-item__data ps-2">
              <label
                className="MedicalPersonnelAssignModal__member-item__data__name"
                htmlFor={`checkbox-${patientTargetId}`}
              >
                {data.first_name} {data.last_name}
              </label>
              <div className="MedicalPersonnelAssignModal__member-item__data__email">
                {data.email}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isPersonalityEnabledToEdit && hasPersonalityCases && (
        <div className="d-flex align-items-center mt-2">
          <FontAwesomeIcon
            size="2x"
            icon={faAngleRight}
            color={isPersonalityChecked ? iconColors.edit : iconColors.normal}
          />
          <div className="ms-4">
            <p className="w-100 mb-1">Case list:</p>
            <ul className="list-unstyled">{casesList}</ul>
          </div>
        </div>
      )}
    </ListGroupItem>
  );
};

export default PersonSelectionRow;
