import React from 'react';
import { ISummarizedTreatmentPhase } from '../../../../../../../../interfaces/conversations';
import ConversationSchedules from './components/ConversationSchedules';
import NotificationSchedules from './components/NotificationSchedules';
import PhaseHeader from './components/PhaseHeader';

type TreatmentPhaseProps = {
  phase: ISummarizedTreatmentPhase;
  addMode: boolean;
};

const TreatmentPhase = ({ phase, addMode }: TreatmentPhaseProps) => {
  return (
    <div className="d-flex flex-column gap-5">
      <div className="mt-4">
        <PhaseHeader phase={phase} addMode={addMode} />
      </div>
      <ConversationSchedules phase={phase} />
      <NotificationSchedules phase={phase} />
    </div>
  );
};

export default TreatmentPhase;
