import React from 'react';
import { useTypedContext } from '../../../../../../../../../../hooks/useTypedContext';
import { HandleUploadContext } from '../../../../types';
import Validation from './components/Validation';

const ConflictsStep = () => {
  const handleUpload = useTypedContext(HandleUploadContext);

  if (!handleUpload.file) return <></>;
  return <Validation file={handleUpload.file} />;
};

export default ConflictsStep;
