import { useCommonData } from '../../../hooks/useCommonData';
import useUrlSearch from '../../../../../../../../../hooks/useUrlSearch';

export const useCaseAndSessionsDetails = () => {
  const {
    caseDetails,
    awareSessionData,
    aggregatedAwareSessionData,
    caseProcedureHistory,
  } = useCommonData();

  const query = useUrlSearch();
  const aggregated = query.get('aggregated');

  let queries = [caseDetails, caseProcedureHistory, awareSessionData];

  if (aggregated) {
    queries.pop();
    queries.push(aggregatedAwareSessionData);
  }

  return queries;
};
