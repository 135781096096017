import React, { useState } from 'react';
import fallbackImage from 'assets/images/logo.svg';

type ImageProps = {
  alt: string;
  src: string;
  style?: Object;
  className?: string;
};

const Image = ({ alt, src, ...rest }: ImageProps) => {
  const [imageSrc, updateImageSrc] = useState(src);
  const handleError = () => updateImageSrc(fallbackImage);

  return <img src={imageSrc} onError={handleError} alt={alt} {...rest} />;
};

export default Image;
