import React from 'react';
import { Navigate } from 'react-router';
import { RouteDefinitions } from '../../../../helpers/routeDefinitions';
import { useBrowserState } from '../../../../store/reducerHooks';
import NotAuthorized from '../../../NotAuthorized';
import CurrentOrganizationGuard from '../../../routes/CurrentOrganizationGuard';
import Dashboard from '../Dashboard';
import DashboardTemplateContainer from './components/DashboardTemplateContainer';

const DashboardProxy = () => {
  const isBrowser = useBrowserState();

  return (
    <CurrentOrganizationGuard
      fallback={
        isBrowser ? <Navigate to={RouteDefinitions.LOGIN} /> : <NotAuthorized />
      }
    >
      <DashboardTemplateContainer>
        {(template) => <Dashboard template={template} />}
      </DashboardTemplateContainer>
    </CurrentOrganizationGuard>
  );
};

export default DashboardProxy;
