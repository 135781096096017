import classNames from 'classnames';
import { StrictDateRange } from 'interfaces/dates';
import React from 'react';
import { DateRange } from 'react-day-picker';
import { FormattedMessage } from 'react-intl';
import { useTimelineDetailsDateRangeObject } from 'store/reducerHooks';
import {
  RangeSelectionMode,
  useSelectionMode,
} from '../../hooks/useSelectionMode';
import DateRangeSelection from '../DateRangeSelection';
import PeriodRangeSelection from '../PeriodRangeSelection';

type DateSelectionProps = {
  children: (props: StrictDateRange | DateRange | null) => JSX.Element;
};

const DateSelection = ({ children }: DateSelectionProps) => {
  const { mode, handleSelectPeriod, handleSelectRange } = useSelectionMode();
  const dateRangeObject = useTimelineDetailsDateRangeObject();

  return (
    <div>
      <div className="ChangeCaseRangeSelector">
        <div className="ChangeCaseRangeSelector__select-type">
          <span
            onClick={handleSelectPeriod}
            className={classNames({
              'ChangeCaseRangeSelector__select-stage': true,
              'ChangeCaseRangeSelector__select-active':
                mode === RangeSelectionMode.PeriodRange,
            })}
          >
            <FormattedMessage id="Cases.ChangeCase.selectStage" />
          </span>
          <span
            onClick={handleSelectRange}
            className={classNames({
              'ChangeCaseRangeSelector__select-dates': true,
              'ChangeCaseRangeSelector__select-active':
                mode === RangeSelectionMode.DateRange,
            })}
          >
            <FormattedMessage id="Cases.ChangeCase.selectDates" />
          </span>
        </div>
        <div className="ChangeCaseRangeSelector__select-range">
          {mode === RangeSelectionMode.DateRange ? (
            <DateRangeSelection />
          ) : (
            <PeriodRangeSelection />
          )}
        </div>
      </div>
      <div>{children(dateRangeObject)}</div>
    </div>
  );
};

export default DateSelection;
