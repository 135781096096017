import React from 'react';
import {
  Button,
  PopoverBody,
  PopoverHeader,
  UncontrolledTooltip,
} from 'reactstrap';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { FilterInputProps } from './FilterInput';

type UncontrolledFilterInputProps = Omit<FilterInputProps, 'isOpen' | 'toggle'>;

const UncontrolledFilterInput = ({
  resetFilters,
  filterBody,
  onAcceptClick,
  displayComponent,
  targetId,
  resetFiltersButtonTranslationId = 'Projects.filter.clearAll',
  titleTranslationId,
  closeButton = false,
  popoverClassName = undefined,
  popoverBodyClassName = undefined,
  acceptButtonDisabled = false,
  resetButtonHidden = false,
}: UncontrolledFilterInputProps) => {
  const handleClose = () => {
    resetFilters?.();
  };

  return (
    <div className="FilterInput">
      {displayComponent}
      <UncontrolledTooltip
        placement="bottom"
        autohide={false}
        target={targetId}
        className={classNames(
          {
            [`${popoverClassName}`]: popoverClassName,
          },
          { FilterInput__popover: !popoverClassName }
        )}
      >
        {titleTranslationId && (
          <PopoverHeader className="FilterInput__header">
            <FormattedMessage id={titleTranslationId} />
          </PopoverHeader>
        )}
        {!resetButtonHidden && resetFilters && (
          <button
            className="FilterInput__header__clear ms-auto"
            onClick={resetFilters}
          >
            <FormattedMessage id={resetFiltersButtonTranslationId} />
          </button>
        )}
        <PopoverBody
          className={classNames({
            [`${popoverBodyClassName}`]: popoverBodyClassName,
          })}
        >
          <div
            className={classNames('mb-0', {
              'mb-3': !!onAcceptClick || !!closeButton,
            })}
          >
            {filterBody}
          </div>
          <div className="w-100 d-flex">
            {onAcceptClick && (
              <Button
                color="primary"
                className="ms-auto"
                disabled={acceptButtonDisabled}
                onClick={onAcceptClick}
              >
                <FormattedMessage id="General.accept" />
              </Button>
            )}
            {closeButton && (
              <Button
                color="primary"
                outline
                className="ms-2"
                onClick={handleClose}
              >
                <FormattedMessage id="General.close" />
              </Button>
            )}
          </div>
        </PopoverBody>
      </UncontrolledTooltip>
    </div>
  );
};

export default UncontrolledFilterInput;
