import { IQueryRequest } from '../../../interfaces/request';
import { OrganizationQueryParams } from '../../../interfaces/queryParams';
import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getDashboardTemplate } from '../../dashboards';

type DashboardTemplateQueryParams = OrganizationQueryParams & {
  templateId: string;
};

const useDashboardTemplate = ({
  params,
  options,
}: IQueryRequest<DashboardTemplateQueryParams>) => {
  const { organizationId, templateId } = params;

  return useQuery({
    ...options,
    queryKey: [QueryKeys.getDashboardTemplate, organizationId, templateId],
    queryFn: async () => {
      const { data } = await getDashboardTemplate(organizationId, templateId);
      return data.data;
    },
  });
};

export default useDashboardTemplate;
