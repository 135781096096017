import React from 'react';

const TrendsChartTooltipContent = (props: any) => {
  const {
    active,
    payload,
    label,
    labelFormatter,
    valueFormatter,
    nameFormatter,
  } = props;

  if (active && payload && payload.length) {
    return (
      <div className="d-flex flex-column gap-2 card p-3 shadow">
        <span className="text-muted text-center">
          {labelFormatter ? labelFormatter(label) : label}
        </span>
        <div className="d-flex flex-column gap-2 mt-3">
          {(payload as any[]).map((item) => (
            <div className="d-flex align-items-center gap-3">
              <div
                style={{
                  width: 15,
                  height: 15,
                  borderRadius: '100%',
                  background: item.color,
                }}
              />
              <div>{`${
                nameFormatter ? nameFormatter(item.name) : item.name
              } : ${
                valueFormatter ? valueFormatter(item.value) : item.value
              }`}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }

  return null;
};

export default TrendsChartTooltipContent;
