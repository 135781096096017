import { ICaseFilters } from 'interfaces/cases';

type CaseState = {
  caseListFilters: ICaseFilters | null;
  caseListSearchFieldValue: string;
  formsDayDetailsRefetchRequest: boolean;
  formsDayByDayRefetchRequest: boolean;
};

const initialCaseState: CaseState = {
  caseListFilters: {
    statuses: ['Open'],
  },
  caseListSearchFieldValue: '',
  formsDayDetailsRefetchRequest: false,
  formsDayByDayRefetchRequest: false,
};

export enum CaseActionName {
  CASE_SET_LIST_FILTERS = 'CASE_SET_LIST_FILTERS',
  CASE_SET_LIST_SEARCH_FIELD_VALUE = 'CASE_SET_LIST_SEARCH_FIELD_VALUE',
  CASE_SET_FORMS_DAY_DETAILS_REFETCH_REQUEST = 'CASE_SET_FORMS_DAY_DETAILS_REFETCH_REQUEST',
  CASE_SET_FORMS_DAY_BY_DAY_REFETCH_REQUEST = 'CASE_SET_FORMS_DAY_BY_DAY_REFETCH_REQUEST',
}

type CaseAction =
  | {
      type: CaseActionName.CASE_SET_LIST_FILTERS;
      filters: ICaseFilters | null;
    }
  | {
      type: CaseActionName.CASE_SET_LIST_SEARCH_FIELD_VALUE;
      fieldValue: string;
    }
  | {
      type: CaseActionName.CASE_SET_FORMS_DAY_DETAILS_REFETCH_REQUEST;
      request: boolean;
    }
  | {
      type: CaseActionName.CASE_SET_FORMS_DAY_BY_DAY_REFETCH_REQUEST;
      request: boolean;
    };

const caseReducer = (state = initialCaseState, action: CaseAction) => {
  switch (action.type) {
    case CaseActionName.CASE_SET_LIST_FILTERS:
      return {
        ...state,
        caseListFilters: action.filters,
      };
    case CaseActionName.CASE_SET_LIST_SEARCH_FIELD_VALUE:
      return {
        ...state,
        caseListSearchFieldValue: action.fieldValue,
      };
    case CaseActionName.CASE_SET_FORMS_DAY_DETAILS_REFETCH_REQUEST:
      return {
        ...state,
        formsDayDetailsRefetchRequest: action.request,
      };
    case CaseActionName.CASE_SET_FORMS_DAY_BY_DAY_REFETCH_REQUEST:
      return {
        ...state,
        formsDayByDayRefetchRequest: action.request,
      };
    default:
      return state;
  }
};

export default caseReducer;
