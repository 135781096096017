import { StepsHookValues } from 'hooks/useSteps';
import { OrganizationType } from 'interfaces/organizations';
import { createContext } from 'react';

export enum OrganizationRegisterViewSteps {
  Forms = 'Forms',
  Confirmation = 'Confirmation',
}

export const OrganizationRegisterViewStepsContext = createContext<
  StepsHookValues<OrganizationRegisterViewSteps> | undefined
>(undefined);

export const OrganizationTypeContext = createContext<
  OrganizationType | undefined
>(undefined);
