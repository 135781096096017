import {
  addDays,
  addHours,
  addMonths,
  differenceInHours,
  differenceInMinutes,
} from 'date-fns';
import { cloneDeep } from 'lodash';
import { groupByProperty } from '../../../../../../../helpers/utils/groupByProperty';
import { IAwareSessionData } from '../../../../../../../interfaces/awareSessions';
import {
  ClosedListValidConversationQuestion,
  ConversationQuestion,
  ValidConversationQuestionType,
} from '../../../../../../../interfaces/conversations';
import { IQuestionBasedDashboardTemplateSeriesSplitBy } from '../../../../../../../interfaces/dashboards';
import { StrictDateRange } from '../../../../../../../interfaces/dates';
import { IBaseOrganization } from '../../../../../../../interfaces/organizations';
import { BasePersonality } from '../../../../../../../interfaces/personalities';
import { AVAILABLE_CULTURES } from '../../../../../../CultureSelector/CultureSelector';
import {
  NamedAndSplitByValues,
  SplitByValues,
} from '../components/DashboardLayout/components/Visualizations/components/ChartView/types';
import { DashboardFormatHelpers } from './format';

export namespace DashboardSessionsHelpers {
  export const IsSessionConsideredAsFeedback = (
    session: IAwareSessionData,
    notSpecifiedTranslation: string
  ) => {
    return session.questions.some(
      (p) => p.value !== null && p.value !== notSpecifiedTranslation
    );
  };

  export const GetSessionDuration = (session: IAwareSessionData) => {
    return differenceInMinutes(
      new Date(session.end_date),
      new Date(session.start_date)
    );
  };

  export const SplitValueSessionsByProperty = <T>(
    sessions: T[],
    key: string
  ): SplitByValues<T>[] => {
    const groupByProp = groupByProperty(key);
    const groupedByProp = groupByProp(sessions) as Record<string, T[]>;

    let splitValues: SplitByValues<T>[] = [];

    Object.entries(groupedByProp).forEach(([key, val]) => {
      splitValues.push({
        id: key,
        items: val,
      });
    });

    splitValues.sort((a, b) => a.id.localeCompare(b.id));

    return splitValues;
  };

  export const SplitValueSessionsByQuestion = <T extends IAwareSessionData>(
    sessions: T[],
    splitBy: IQuestionBasedDashboardTemplateSeriesSplitBy,
    questions: ConversationQuestion[],
    notSpecified: string
  ): SplitByValues<T>[] => {
    let splitValues: SplitByValues<T>[] = [];

    sessions.forEach((session) => {
      const splitByQuestion = session.questions.find(
        (p) => p.id === splitBy.id
      );
      const answeredValue = splitByQuestion?.value;

      if (!answeredValue) return;

      if (answeredValue === notSpecified) {
        const notSpecifiedIndex = splitValues.findIndex(
          (p) => p.id === notSpecified
        );

        if (notSpecifiedIndex !== -1) {
          splitValues[notSpecifiedIndex].items.push(session);
          return;
        }

        splitValues.push({ id: notSpecified, items: [session] });
        return;
      }

      if (!splitByQuestion && !answeredValue) return;

      const existingQuestion = questions.find(
        (p) =>
          p.resource_id === splitBy.id &&
          p.content.type === ValidConversationQuestionType.ClosedList
      );

      const existingClosedList = existingQuestion?.content as
        | ClosedListValidConversationQuestion
        | undefined;

      const existingAnswer = existingClosedList?.answers.find(
        (p) => p.title === answeredValue
      );

      if (!existingAnswer) return;

      const itemIndex = splitValues.findIndex(
        (p) => p.id === existingAnswer.guid
      );

      if (itemIndex !== -1) {
        splitValues[itemIndex].items.push(session);
        return;
      }

      splitValues.push({ id: existingAnswer.guid, items: [session] });
    });

    splitValues.sort((a, b) => a.id.localeCompare(b.id));

    return splitValues;
  };

  export const SplitValueByDateRange = <T extends IAwareSessionData>(
    sessions: T[],
    dateRange: StrictDateRange
  ): SplitByValues<T>[] => {
    let splitValues: SplitByValues<T>[] = [];

    let currentDate = dateRange.from;

    while (
      new Date(currentDate).toISOString() <=
      new Date(dateRange.to).toISOString()
    ) {
      let current = cloneDeep(currentDate);
      current = EmptyDateRangeGranularity(current, dateRange);

      splitValues.push({
        id: current.toISOString(),
        items: GetSessionsForDate(sessions, current, dateRange),
      });

      currentDate = ApplyDateRangeGranularity(current, dateRange);
    }

    return splitValues;
  };

  const EmptyDateRangeGranularity = (
    current: Date,
    dateRange: StrictDateRange
  ) => {
    const hoursDiff = differenceInHours(dateRange.to, dateRange.from);
    return _emptyCurrentByHoursDiff(current, hoursDiff);
  };

  const _emptyCurrentByHoursDiff = (current: Date, hoursDiff: number) => {
    if (hoursDiff < 24 * 3) {
      current.setHours(current.getHours(), 0, 0, 0);
      return current;
    }

    if (hoursDiff < 24 * 30 * 3) {
      current.setHours(0, 0, 0, 0);
      return current;
    }

    current.setFullYear(current.getFullYear(), current.getMonth(), 1);
    current.setHours(0, 0, 0, 0);
    return current;
  };

  const ApplyDateRangeGranularity = (
    current: Date,
    dateRange: StrictDateRange
  ) => {
    return _applyCurrentByDateRange(current, dateRange);
  };

  const _applyCurrentByDateRange = (
    current: Date,
    dateRange: StrictDateRange
  ) => {
    const hoursDiff = differenceInHours(dateRange.to, dateRange.from);

    if (hoursDiff < 24 * 3) {
      current = addHours(current, 1);
      return current;
    }

    if (hoursDiff < 24 * 30 * 3) {
      current = addDays(current, 1);
      return current;
    }

    current = addMonths(current, 1);
    return current;
  };

  export const NameValueSessions = <T extends IAwareSessionData>(
    sessions: SplitByValues<T>[],
    members: BasePersonality[],
    organizations: IBaseOrganization[],
    questions: ConversationQuestion[],
    culture: AVAILABLE_CULTURES
  ): NamedAndSplitByValues<T>[] => {
    let namedSessions: NamedAndSplitByValues<T>[] = [];

    sessions.forEach((session) => {
      const member = members.find((p) => p.id === session.id);

      if (member) {
        namedSessions.push({
          ...session,
          name: DashboardFormatHelpers.FormatOrganizationMember(member),
        });
        return;
      }

      const organization = organizations.find((p) => p.id === session.id);

      if (organization) {
        namedSessions.push({
          ...session,
          name: DashboardFormatHelpers.FormatOrganization(organization),
        });
        return;
      }

      const closedListQuestionResources = questions.filter(
        (p) => p.content.type === ValidConversationQuestionType.ClosedList
      );

      const closedListQuestions = closedListQuestionResources.map(
        (p) => p.content as ClosedListValidConversationQuestion
      );

      const question = closedListQuestions.find((p) =>
        p.answers.map((q) => q.guid).includes(session.id)
      );

      if (!question) {
        namedSessions.push({ ...session, name: session.id });
        return;
      }

      const answer = question?.answers?.find((p) => p.guid === session.id);

      if (!answer) {
        namedSessions.push({ ...session, name: session.id });
        return;
      }

      namedSessions.push({
        ...session,
        name: DashboardFormatHelpers.FormatQuestionAnswer(
          question,
          session.id,
          culture
        ),
      });
    });

    return namedSessions;
  };

  const GetSessionsForDate = <T extends IAwareSessionData>(
    sessions: T[],
    current: Date,
    dateRange: StrictDateRange
  ): T[] => {
    let _sessions: T[] = [];

    sessions.forEach((session) => {
      let sessionDate = cloneDeep(new Date(session.start_date));
      sessionDate = EmptyDateRangeGranularity(sessionDate, dateRange);

      if (current.toISOString() !== sessionDate.toISOString()) return;
      _sessions.push(session);
    });

    return _sessions;
  };
}
