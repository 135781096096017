import { useTypedContext } from '../../../../../../../../../../hooks/useTypedContext';
import { useNotSpecifiedTranslation } from '../../../../hooks/useNotSpecifiedTranslation';
import {
  DashboardDataReducerContext,
  DashboardQueriesContext,
} from '../../../../types';
import { VisualizationDataContextType } from '../../components/Visualizations/types';
import { DashboardLayoutFilterHelpers } from '../../helpers/filters';

type VisualizationFiltersProviderProps = {
  data: VisualizationDataContextType;
  children: (filteredData: VisualizationDataContextType) => JSX.Element;
};

const VisualizationFiltersProvider = ({
  children,
  data,
}: VisualizationFiltersProviderProps) => {
  const [dashboardData] = useTypedContext(DashboardDataReducerContext);
  const { questions } = useTypedContext(DashboardQueriesContext);

  const { translation } = useNotSpecifiedTranslation();

  let sessions =
    DashboardLayoutFilterHelpers.ApplyNotSpecifiedToAwareSessionData(
      data.sessions,
      translation
    );

  const filteredData = DashboardLayoutFilterHelpers.ApplyFilters(
    { sessions, reports: data.reports, items: data.items },
    dashboardData.template.filters,
    questions,
    translation
  );

  return children(filteredData);
};

export default VisualizationFiltersProvider;
