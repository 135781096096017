import React, { useState } from 'react';
import { Button } from 'reactstrap';
import ObservationModal from '../ObservationModal';
import CaseHistoryModal from 'modules/Cases/modals/CaseHistoryModal';
import AnnotationModal from '../AnnotationModal';
import HoverableButton from './HoverableButton';

//helpers
import useOpen from 'hooks/useOpen';

//images
import annotationIcon from 'assets/images/timeline_details/annotation_icon.svg';
import plusIcon from 'assets/images/plus.svg';
import caseHistoryIcon from 'assets/images/timeline_details/case_history_icon.svg';
import observationIcon from 'assets/images/timeline_details/observation_icon.svg';
import notepadIcon from 'assets/images/notepad.svg';
import NonAssignmentAssessmentFormModal from '../NonAssignmentAssessmentFormModal';
import { useParams } from 'react-router';

type TimelineButtonGroupProps = {
  isOwner: boolean;
  timelineId: string;
  isTimelineUsersMaster: boolean;
  caseDedicatedTimelineId: string | undefined;
  selectedDates?: {
    from: Date;
    to: Date;
  };
  selectedDate: Date;
};

const TimelineButtonGroup = ({
  caseDedicatedTimelineId,
  isOwner,
  isTimelineUsersMaster,
  timelineId,
  selectedDates,
  selectedDate,
}: TimelineButtonGroupProps) => {
  const [areButtonsVisible, setButtonsVisible] = useState(false);
  const { caseId, organizationId } = useParams<{
    organizationId: string;
    caseId: string;
  }>();

  const {
    isOpen: isAnnotationOpen,
    close: closeAnnotation,
    open: openAnnotation,
  } = useOpen();

  const {
    isOpen: isObservationOpen,
    close: closeObservation,
    open: openObservation,
  } = useOpen();

  const {
    isOpen: isNonAssignmentModalOpen,
    close: closeNonAssignmentModal,
    open: openNonAssignmentModal,
  } = useOpen();

  const {
    isOpen: isHistoryOpen,
    close: closeHistory,
    open: openHistory,
  } = useOpen();

  let container = 'TimelineButtonGroup__container';
  if (!areButtonsVisible)
    container += ' TimelineButtonGroup__container--hidden';

  const toggleButtons = () => setButtonsVisible((val) => !val);
  const isOrganizationContext = caseId && organizationId;

  return (
    <>
      <Button
        className={`TimelineButtonGroup__toggle-button ${
          areButtonsVisible ? ' TimelineButtonGroup__toggle-button--active' : ''
        }`}
        onClick={toggleButtons}
      >
        <img src={plusIcon} alt="plus_icon" />
      </Button>
      <div className={container}>
        {isOwner && (
          <>
            <HoverableButton
              onClick={openAnnotation}
              buttonImageSrc={annotationIcon}
              actionNameTranslationId="TimelineDetails.AnnotationModal.addAnnotation"
              buttonCss="TimelineButtonGroup__button--annotation"
            />
          </>
        )}
        {isOrganizationContext && (
          <>
            <HoverableButton
              onClick={openHistory}
              buttonImageSrc={caseHistoryIcon}
              actionNameTranslationId="CaseCard.History.addCaseRecord"
              buttonCss="TimelineButtonGroup__button--history"
            />
            {caseDedicatedTimelineId && (
              <HoverableButton
                onClick={openNonAssignmentModal}
                buttonImageSrc={notepadIcon}
                actionNameTranslationId="TimelineDetails.nonAssignedAssessmentModal.addAssessmentForm"
                buttonCss="TimelineButtonGroup__button--question"
              />
            )}
          </>
        )}
        {(isOrganizationContext || !isTimelineUsersMaster) && (
          <HoverableButton
            onClick={openObservation}
            buttonImageSrc={observationIcon}
            actionNameTranslationId="TimelineDetails.ObservationModal.addObservation"
            buttonCss="TimelineButtonGroup__button--observation"
          />
        )}
      </div>
      {isOwner && (
        <AnnotationModal
          timelineId={timelineId}
          isOpen={isAnnotationOpen}
          closeModal={closeAnnotation}
        />
      )}
      {isOrganizationContext && (
        <>
          <CaseHistoryModal
            selectedDate={selectedDate}
            isOpen={isHistoryOpen}
            toggleModal={closeHistory}
            caseId={caseId}
            organizationId={organizationId}
            hidePredefinedMessages={true}
            hideOwnerSelect={true}
          />
          {selectedDates && caseDedicatedTimelineId && organizationId && (
            <NonAssignmentAssessmentFormModal
              isOpen={isNonAssignmentModalOpen}
              toggleModal={closeNonAssignmentModal}
              timelineId={caseDedicatedTimelineId}
              selectedDates={selectedDates}
              organizationId={organizationId}
            />
          )}
        </>
      )}
      {selectedDates && (
        <>
          {isOrganizationContext ? (
            <>
              {caseDedicatedTimelineId && (
                <ObservationModal
                  caseDedicatedTimelineId={caseDedicatedTimelineId}
                  timelineId={timelineId}
                  isOpen={isObservationOpen}
                  closeModal={closeObservation}
                  refetchDates={selectedDates}
                />
              )}
            </>
          ) : (
            <>
              {!isTimelineUsersMaster && (
                <ObservationModal
                  caseDedicatedTimelineId={undefined}
                  timelineId={timelineId}
                  isOpen={isObservationOpen}
                  closeModal={closeObservation}
                  refetchDates={selectedDates}
                />
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default TimelineButtonGroup;
