import { IPieChartData } from '../../../../../../../../../interfaces/charts';
import { useTranslations } from '../../../../../../../../../hooks/useTranslations';
import { useTypedContext } from '../../../../../../../../../hooks/useTypedContext';
import { AwareCaseContentDataContext } from '../../AwareCaseContent/types';

export const useFormattedData = (): IPieChartData[] => {
  const { awareSession } = useTypedContext(AwareCaseContentDataContext);

  const { attachPrefix, wrapWithIntl } = useTranslations(
    'Cases.CaseDetails.AwareCase.Squeezes'
  );

  const totalSqueezes = awareSession?.total_squeezes ?? 0;
  const alarmIncidents = awareSession?.alarm_incidents ?? 0;

  return [
    {
      name: wrapWithIntl(attachPrefix('squeezes')),
      value: totalSqueezes - alarmIncidents,
    },
    {
      name: wrapWithIntl(attachPrefix('alarms')),
      value: alarmIncidents,
    },
  ];
};
