import { RefObject, useCallback, useMemo } from 'react';
import { useMsDiffs } from '../../../hooks/useMsDiffs';
import { ProcedureActivity } from '../../../../../forms/AwareCaseDetailsForm/types';
import { useCurrentEventName } from '../../../hooks/useCurrentEventName';

export const useOverlapGroups = (
  divRef: RefObject<HTMLDivElement>,
  startDate: string,
  endDate: string,
  procedureEvents: ProcedureActivity[]
) => {
  const { getMsDiff, baseMsDiff } = useMsDiffs(startDate, endDate);

  const getCurrentEventName = useCurrentEventName();

  const eventsPositions = useMemo(() => {
    return procedureEvents.map(
      (ev) => (getMsDiff(startDate, ev.timeStart) / baseMsDiff) * 100
    );
  }, [baseMsDiff, procedureEvents, getMsDiff, startDate]);

  const isOverlapping = useCallback(
    (left: number, right: number) => {
      let leftOffset = eventsPositions[left];
      let rightOffset = eventsPositions.at(right) ?? 1000000000;

      const parentWidth = divRef.current?.clientWidth;

      if (parentWidth) {
        const additionalPaddingWidth = (5 * 100) / parentWidth;

        const currentEventName = getCurrentEventName(procedureEvents[left]);
        const additionalLabelWidth =
          (currentEventName.length * 8.43 * 100) / parentWidth;
        leftOffset = leftOffset + additionalLabelWidth + additionalPaddingWidth;
      }

      return leftOffset >= rightOffset;
    },
    [divRef, eventsPositions, getCurrentEventName, procedureEvents]
  );

  return useMemo(() => {
    let groups: number[][] = [];

    let currentIndex = 0;

    while (currentIndex < procedureEvents.length) {
      let currentGroup: number[] = [currentIndex];
      while (isOverlapping(currentIndex, currentIndex + 1)) {
        currentGroup.push(currentIndex + 1);
        currentIndex++;
      }
      groups.push(currentGroup);
      currentIndex++;
    }

    return groups;
  }, [isOverlapping, procedureEvents.length]);
};
