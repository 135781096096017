import React from 'react';
import Skeleton from 'react-loading-skeleton';

const FeedbackChartFigurePlaceholder = () => {
  return (
    <div className="d-flex flex-column gap-2 mt-4">
      <Skeleton width="100%" height={15} />
      <Skeleton width="100%" height={15} />
      <Skeleton width="100%" height={15} />
      <Skeleton width="100%" height={15} />
      <Skeleton width="100%" height={15} />
    </div>
  );
};

export default FeedbackChartFigurePlaceholder;
