import useRequiredParams from '../../../../../hooks/useRequiredParams';
import { useCallback, useMemo } from 'react';
import {
  ICaseGroup,
  ICreateCaseGroup,
} from '../../../../../interfaces/caseGroups';
import { cloneDeep } from 'lodash';
import { FormikErrors } from 'formik';
import { InputOption } from '../../../../../interfaces/ui';
import { getOrganizations } from '../../../../../helpers/utils/auth';
import useCreateCaseGroup from '../../../../../api/mutations/caseGroups/useCreateCaseGroup';
import { useCaseGroupsCommonData } from '../../../hooks/useCaseGroupsCommonData';
import { useCreateCaseGroupFormValidation } from './useCreateCaseGroupFormValidation';

export interface ICaseGroupFormValues extends ICreateCaseGroup {
  organization_id: string;
  project_id: string;
  case_category_id: string;
}

export const useCreateCaseGroupForm = (
  caseGroup?: ICaseGroup,
  refetch?: () => Promise<any>,
  toggle?: () => void
) => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const organizations = getOrganizations();

  const { groupScheduleValidation, singleFieldsValidation } =
    useCreateCaseGroupFormValidation();

  const { projects, treatmentPlans, caseCategories } =
    useCaseGroupsCommonData();

  const handleSuccess = async () => {
    if (refetch) await refetch();
    if (toggle) toggle();
  };

  const { mutateAsync: createAsync } = useCreateCaseGroup(
    {
      organizationId: organizationId,
    },
    {
      successFb: handleSuccess,
    }
  );

  const emptyInitialValues = useMemo((): ICaseGroupFormValues => {
    const isoNowDate = new Date().toISOString();

    return {
      organization_id: organizationId,
      case_category_id: '',
      project_id: '',
      group_schedule: [],
      cases: [],
      sessions: [],
      maximum_members_allowed: 0,
      starting_date: isoNowDate.substring(0, isoNowDate.indexOf('T')),
    };
  }, [organizationId]);

  const getInitialValuesBasedOnCaseGroup = useCallback(
    (group: ICaseGroup): ICaseGroupFormValues => {
      return {
        organization_id: organizationId,
        case_category_id: group.case_category_id,
        project_id: group.project_id ?? '',
        group_schedule: group.group_schedule,
        cases: group.cases.map((p) => p.id),
        sessions: group.sessions,
        starting_date: group.starting_date.substring(
          0,
          group.starting_date.indexOf('T')
        ),
        maximum_members_allowed: group.maximum_members_allowed,
      };
    },
    [organizationId]
  );

  const initialValues = useMemo(() => {
    if (!caseGroup) return cloneDeep(emptyInitialValues);
    return getInitialValuesBasedOnCaseGroup(caseGroup);
  }, [caseGroup, emptyInitialValues, getInitialValuesBasedOnCaseGroup]);

  const validation = useCallback(
    (values: typeof initialValues): FormikErrors<ICaseGroupFormValues> => {
      let errors: FormikErrors<ICaseGroupFormValues> = {};

      errors = singleFieldsValidation(values, errors);
      errors = groupScheduleValidation(values, errors);

      return errors;
    },
    [groupScheduleValidation, singleFieldsValidation]
  );

  const handleSubmit = useCallback(
    async (values: typeof initialValues) => {
      let createCaseGroupBody: ICreateCaseGroup = {
        cases: [],
        sessions: [],
        case_category_id: values.case_category_id,
        starting_date: values.starting_date,
        group_schedule: values.group_schedule,
        maximum_members_allowed: values.maximum_members_allowed,
      };

      await createAsync({
        ...createCaseGroupBody,
        project_id: values.project_id,
      });
    },
    [createAsync]
  );

  const organizationOptions = useMemo((): InputOption[] => {
    const currentOrganization = organizations?.find(
      (p) => p.id === organizationId
    );

    if (!currentOrganization) return [];

    return [
      {
        value: organizationId,
        text: currentOrganization.name,
      },
    ];
  }, [organizationId, organizations]);

  const treatmentPlansOptions = useMemo(() => {
    if (!treatmentPlans.data) return [];

    return treatmentPlans.data.map((treatmentPlan) => ({
      value: treatmentPlan.id,
      text:
        caseCategories.data?.find((p) => p.id === treatmentPlan.id)?.name_en ??
        '',
    }));
  }, [caseCategories.data, treatmentPlans.data]);

  const projectsOptions = useMemo(() => {
    if (!projects.data) return [];

    return projects.projects.map((project) => ({
      value: project.id,
      text: project.code,
    }));
  }, [projects.data, projects.projects]);

  return {
    initialValues: initialValues,
    validation: validation,
    handleSubmit: handleSubmit,
    options: {
      organization: organizationOptions,
      treatmentPlans: treatmentPlansOptions,
      projects: projectsOptions,
    },
  };
};
