import AddIcon from '@mui/icons-material/Add';
import heartOutline from 'assets/images/heart_outline.svg';
import { TrendVisualizationTemplate } from 'interfaces/trendVisualizations';
import { useCommonData } from 'modules/Organizations/views/OrganizationDetailsView/hooks/useCommonData';
import React, { useState } from 'react';
import IconButton from '../../../../../../../../components/IconButton';
import TrendVisualizationModal from '../../modals/TrendVisualizationModal';
import ItemSection from '../components/ItemSection';
import SavedViewsList from './components/SavedViewsList';

const SavedViews = () => {
  const { trendVisualizationTemplates } = useCommonData();

  const [templateToEdit, setTemplateToEdit] = useState<
    TrendVisualizationTemplate | null | undefined
  >(null);

  const handleAddTrendChart = () => {
    setTemplateToEdit(undefined);
  };

  const handleEditChart = (item: TrendVisualizationTemplate) => {
    setTemplateToEdit(item);
  };

  const closeModal = () => {
    setTemplateToEdit(null);
  };

  return (
    <div className="TrendVisualizationsTab__saved-views">
      {templateToEdit !== null && (
        <TrendVisualizationModal
          isOpen={true}
          toggle={closeModal}
          template={templateToEdit}
        />
      )}
      <div className="TrendVisualizationsSavedViews">
        <div className="TrendVisualizationsSavedViews__header">
          <ItemSection
            icon={heartOutline}
            textId={'MyOrganizations.trendVisualizations.savedViews'}
            additionalContent={
              <>
                {!trendVisualizationTemplates.isLoading && (
                  <IconButton
                    color="primary"
                    variant="outlined"
                    onClick={handleAddTrendChart}
                  >
                    <AddIcon />
                  </IconButton>
                )}
              </>
            }
          />
        </div>
        <div className="TrendVisualizationsSavedViews__list mt-4">
          <SavedViewsList handleEditChart={handleEditChart} />
        </div>
      </div>
    </div>
  );
};

export default SavedViews;
