import React, { useMemo } from 'react';
import EditButton from 'components/EditButton';
import PersonalityImage from 'components/PersonalityImage';
import { formatDate } from 'helpers/dates';
import useOpen from 'hooks/useOpen';
import { MemberWithAccess } from 'interfaces/personalities';
import MedicalPersonnelAssignModal from './MedicalPersonnelAssignModal';
import useModifyPatientMemberAccess from 'api/mutations/patients/useModifyPatientMemberAccess';
import { AccessType } from 'interfaces/enums';
import { FormattedMessage } from 'react-intl';

type PatientMedicalPersonnelProps = {
  organizationId: string;
  personalityId: string;
  patientMedicalPersonnel?: MemberWithAccess[];
  isLoading: boolean;
  refetchFunction: () => void;
};

const PatientMedicalPersonnel = ({
  organizationId,
  patientMedicalPersonnel,
  isLoading,
  personalityId,
  refetchFunction,
}: PatientMedicalPersonnelProps) => {
  const patientRecords =
    patientMedicalPersonnel?.map((el) => {
      return (
        <div
          key={`medical-personnel-${el.member_personality_id}`}
          className="MedicalPersonnel__row d-flex w-100 justify-content-between flex-wrap  align-items-center"
        >
          <div className="d-flex align-items-center">
            <PersonalityImage />
            <div className="MedicalPersonnel__row__data">
              <div className="MedicalPersonnel__row__data__name">
                {el.member_personality?.first_name}
                {el.member_personality?.last_name}
              </div>
              <div className="MedicalPersonnel__row__data__email">
                {el.member_personality?.email}
              </div>
            </div>
          </div>
          <div>
            <FormattedMessage
              id="General.since"
              values={{
                date: formatDate(el.assign_date, { ignoreTime: true }),
              }}
            />
          </div>
        </div>
      );
    }) ?? [];

  const assignedPersonalityIds = useMemo(
    () => patientMedicalPersonnel?.map((el) => el.member_personality_id) ?? [],
    [patientMedicalPersonnel]
  );
  const { isOpen, toggle } = useOpen();

  let content: null | JSX.Element[] | string = null;
  if (!isLoading && patientRecords.length === 0) content = 'No records';
  if (!isLoading) content = patientRecords;

  const onSuccess = () => {
    toggle();
    refetchFunction();
  };

  const { mutate: modifyPatientMemberAccessMutation } =
    useModifyPatientMemberAccess(organizationId, personalityId, onSuccess);

  const onUpdatePatientMedicalPersonnel = (membersPersonalityIds: string[]) => {
    const memberAccesses = membersPersonalityIds.map(
      (member_personality_id) => {
        const memberAccess: MemberWithAccess = {
          member_personality: null,
          member_personality_id,
          access_type: AccessType.GRANTED,
          assign_date: new Date().toISOString(),
          expiration_date: null,
          participant_personality_id: personalityId,
          reason: '',
        };

        return memberAccess;
      }
    );

    modifyPatientMemberAccessMutation(memberAccesses);
  };

  return (
    <div className="MedicalPersonnel">
      <MedicalPersonnelAssignModal
        isOpen={isOpen}
        toggle={toggle}
        headerTranslationId="Patient.manageMedicalPersonnel"
        organizationId={organizationId}
        assignedPersonalityIds={assignedPersonalityIds}
        submitFn={onUpdatePatientMedicalPersonnel}
        isLoading={false}
        isPatientModal={true}
      />
      <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
        <div className="cardContainer__header__title">
          <FormattedMessage id="Patient.assignedMedicalPersonnel" />
        </div>
        <EditButton onClick={toggle} />
      </div>
      {content}
    </div>
  );
};

export default PatientMedicalPersonnel;
