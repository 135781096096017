import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import { sendPatientInvite } from 'api/patients';
import {
  displayErrorArrayNotifications,
  displayErrorNotification,
  displaySuccessNotification,
} from 'modules/Notifications';

function useSendPatientInvite(organizationId: string) {
  return useMutationWithResultNotification({
    asyncApiFunction: async (email: string) => {
      await sendPatientInvite(organizationId, encodeURIComponent(email));
    },
    onSuccess: () => {
      displaySuccessNotification('Patient.inviteSuccess');
    },
    onErrorArray: (errors) => {
      if (errors.length === 0)
        displayErrorNotification('Patient.inviteErrors.default');
      else {
        displayErrorArrayNotifications(errors, 'Patient.inviteErrors');
      }
    },
    resetOnResult: true,
  });
}

export default useSendPatientInvite;
