import React from 'react';
import { Bar } from 'recharts';
import {
  GraspColors,
  graspColorsValues,
} from '../../../../../../../helpers/colors';

type BarChartFigureItemProps = {
  barKey: string;
  dataKey: string;
  stackId?: string;
  fill?: string;
  label?: string;
  hide?: boolean;
};

export const getBarChartFigureItem = ({
  barKey,
  dataKey,
  stackId,
  label,
  fill = graspColorsValues[GraspColors.BLUE],
  hide = false,
}: BarChartFigureItemProps) => {
  return [
    <Bar
      key={barKey}
      dataKey={dataKey}
      stackId={stackId}
      fill={fill}
      label={label}
      hide={hide}
    />,
  ];
};
