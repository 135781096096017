import { getCategories } from 'api/cases';
import QueryKeys from 'api/queries/queryKeys';
import { OrganizationQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { useQuery } from 'react-query';

function useCaseCategories({
  params,
  options,
}: IQueryRequest<OrganizationQueryParams>) {
  const { organizationId } = params;

  return useQuery({
    ...options,
    queryKey: [QueryKeys.getCategories, organizationId],
    queryFn: async () => {
      const responseData = await getCategories(organizationId);
      const { data } = responseData;
      return data.data;
    },
  });
}

export default useCaseCategories;
