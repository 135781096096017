import { ICreateOrganizationBody, IRegisterForm } from 'interfaces/membership';
import { createContext, Dispatch } from 'react';

export enum OrganizationRegisterStepsActionType {
  SaveCreateOrganization = 'SaveCreateOrganization',
  SaveSignUp = 'SaveSignUp',
}

export type OrganizationRegisterStepsState = {
  createOrganization: ICreateOrganizationBody;
  signUp: IRegisterForm;
};

type SaveCreateOrganizationAction = {
  type: OrganizationRegisterStepsActionType.SaveCreateOrganization;
  payload: ICreateOrganizationBody;
};

type SaveSignUpAction = {
  type: OrganizationRegisterStepsActionType.SaveSignUp;
  payload: IRegisterForm;
};

export type OrganizationRegisterStepsAction =
  | SaveCreateOrganizationAction
  | SaveSignUpAction;

export const InitialOrganizationRegisterSteps: OrganizationRegisterStepsState =
  {
    createOrganization: {} as ICreateOrganizationBody,
    signUp: {} as IRegisterForm,
  };

export const OrganizationRegisterStepsReducer = (
  state: OrganizationRegisterStepsState,
  action: OrganizationRegisterStepsAction
): OrganizationRegisterStepsState => {
  switch (action.type) {
    case OrganizationRegisterStepsActionType.SaveSignUp:
      return { ...state, signUp: action.payload };
    case OrganizationRegisterStepsActionType.SaveCreateOrganization:
      return { ...state, createOrganization: action.payload };
  }
};

export const OrganizationRegisterStepsReducerContext = createContext<
  | [OrganizationRegisterStepsState, Dispatch<OrganizationRegisterStepsAction>]
  | undefined
>(undefined);
