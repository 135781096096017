import React from 'react';
import { FieldProps } from 'formik';
import SVG from 'react-inlinesvg';

//icons
import calendar from 'assets/images/rewards_icons/calendar.svg';
import clapping from 'assets/images/rewards_icons/clapping.svg';
import crown from 'assets/images/rewards_icons/crown.svg';
import ribbon from 'assets/images/rewards_icons/ribbon.svg';
import smile from 'assets/images/rewards_icons/smile.svg';
import strength from 'assets/images/rewards_icons/strength.svg';
import trophy from 'assets/images/rewards_icons/trophy.svg';
import { wrapWithSequenceIdentified } from '../../../../../helpers/utils/data';

const iconItems = [
  { src: ribbon },
  { src: clapping },
  { src: trophy },
  { src: calendar },
  { src: crown },
  { src: strength, stroke: true },
  { src: smile },
];

const RewardField = ({ field, form }: Partial<FieldProps>) => {
  const selectedIndex = form?.values['praise_level'];

  const onSelect = (index: number) => {
    if (field && form?.setValues) {
      form?.setValues({
        ...form.values,
        [field.name]: index,
      });
    }
  };

  const renderedOptions = wrapWithSequenceIdentified(iconItems).map(
    ({ stroke, src, sequence }) => {
      let buttonClassName = 'CreateEntryModal__reward-item';

      const isActive = sequence === selectedIndex;
      if (stroke) buttonClassName += ' CreateEntryModal__reward-item--stroke';
      if (isActive)
        buttonClassName += stroke
          ? ' CreateEntryModal__reward-item--stroke-active'
          : ' CreateEntryModal__reward-item--active';

      const handleRewardClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onSelect(sequence);
      };

      return (
        <button
          key={`reward--${sequence}`}
          className={buttonClassName}
          onClick={handleRewardClick}
        >
          <SVG src={src} />
        </button>
      );
    }
  );

  return <div className="d-flex">{renderedOptions}</div>;
};

export default RewardField;
