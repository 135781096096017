import React from 'react';
import Skeleton from 'react-loading-skeleton';

const InlineDaySelectorPlaceholder = () => {
  return (
    <div className="d-flex gap-1">
      <Skeleton width={30} />
      <Skeleton width={120} />
      <Skeleton width={30} />
    </div>
  );
};

export default InlineDaySelectorPlaceholder;
