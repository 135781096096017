import { refreshOrganizationToken } from 'api/membership';
import { displayErrorNotification } from 'modules/Notifications';
import { useCallback, useEffect } from 'react';
import { useMutation } from 'react-query';
import { ILoginResponse } from 'interfaces/membership';
import { IErrorWithCode } from 'interfaces/response';
import { IMutationProps } from 'interfaces/ui';
import {
  getTokenData,
  setOrganizationFunctionalities,
} from 'helpers/utils/auth';
import { useNavigate } from 'react-router';
import { FunctionalityCode } from '../../../interfaces/functionalities';
import { OrganizationType } from '../../../interfaces/organizations';

interface ILoginResponseWithOrganization extends ILoginResponse {
  organizationId: string;
}

interface IUseRefreshOrganizationTokenProps extends IMutationProps {
  myOrganizationScreen: boolean;
}

function useRefreshOrganizationToken({
  myOrganizationScreen = false,
  successFb,
}: IUseRefreshOrganizationTokenProps) {
  const navigate = useNavigate();

  const result = useMutation<
    ILoginResponseWithOrganization,
    IErrorWithCode,
    string
  >(async (organizationId) => {
    const { data } = await refreshOrganizationToken(organizationId);

    return { ...data.data, organizationId };
  });

  const refreshTokenSuccess = useCallback(async () => {
    const { token, organizationId, organizations } =
      result.data as ILoginResponseWithOrganization;

    getTokenData(token);

    const previousOrganization = localStorage.getItem('last_organization');

    let functionalityCodes: FunctionalityCode[] = [];

    if (
      !previousOrganization ||
      (!!previousOrganization && previousOrganization !== organizationId)
    ) {
      const existingOrganization = organizations.find(
        (p) => p.id === organizationId
      );

      const organizationFunctionalities =
        existingOrganization?.functionalities ?? [];

      organizationFunctionalities.forEach((organizationFunctionality) => {
        const code = Object.entries(FunctionalityCode).find(
          ([_, val]) => val === organizationFunctionality
        );

        if (!code) return;
        const [, val] = code;

        functionalityCodes.push(val);
      });

      setOrganizationFunctionalities(functionalityCodes);
    }

    localStorage.setItem('last_organization', organizationId);

    if (myOrganizationScreen) {
      navigate(`/my-organizations/${organizationId}`);
      successFb?.();
      result.reset();
      return;
    }

    const currentOrganization = organizations.find(
      (p) => p.id === organizationId
    );

    if (currentOrganization?.type === OrganizationType.DentalClinic) {
      navigate(`/organizations/${organizationId}/dental`);
      successFb?.();
      result.reset();
      return;
    }

    if (functionalityCodes.includes(FunctionalityCode.CASE_LIST_VIEW)) {
      navigate(`/organizations/${organizationId}/cases`);
      successFb?.();
      result.reset();
      return;
    }

    navigate(`/my-organizations/${organizationId}`);

    successFb?.();
    result.reset();
  }, [myOrganizationScreen, successFb, result, navigate]);

  useEffect(() => {
    if (result.isSuccess && result.data) refreshTokenSuccess();

    if (result.isError) {
      displayErrorNotification('Errors.default');
      result.reset();
    }
  }, [result, myOrganizationScreen, successFb, navigate, refreshTokenSuccess]);

  return result;
}

export default useRefreshOrganizationToken;
