import QueryLoaded from 'components/QueryLoaded';
import { CasesHelpers } from 'helpers/data/cases';
import React, { useMemo } from 'react';
import useCaseNotificationSchedules from '../../../../../../../../../../../../../../../../api/queries/notificationSchedules/useCaseNotificationSchedules';
import { useOrganizationCasePathParams } from '../../../../../../../../../../../../../../../../hooks/paths/useOrganizationCasePathParams';
import { useInitQuery } from '../../../../../../../../../../../../../../../../hooks/queries/useInitQuery';
import { useTypedContext } from '../../../../../../../../../../../../../../../../hooks/useTypedContext';
import { CaseNotificationSchedule } from '../../../../../../../../../../../../../../../../interfaces/notificationSchedules';
import { CaseDetailsContext } from '../../../../../../../../../../../../types';
import { CaseNotificationSchedulesContext } from '../../types';

type SchedulesProviderProps = {
  selectedDate: string;
  children: (schedules: CaseNotificationSchedule[]) => JSX.Element;
};

const SchedulesProvider = ({
  selectedDate,
  children,
}: SchedulesProviderProps) => {
  const { caseId } = useOrganizationCasePathParams();

  const caseDetails = useTypedContext(CaseDetailsContext);
  const personality = CasesHelpers.GetCaseDefaultPersonality(caseDetails);

  const year = useMemo(
    () => new Date(selectedDate).getFullYear(),
    [selectedDate]
  );

  const month = useMemo(
    () => new Date(selectedDate).getMonth() + 1,
    [selectedDate]
  );

  const day = useMemo(() => new Date(selectedDate).getDate(), [selectedDate]);

  const query = useCaseNotificationSchedules({
    params: {
      userId: personality?.user_id ?? '',
      caseId,
      year,
      month,
      day,
    },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(query, !!personality?.user_id);

  return (
    <QueryLoaded query={query}>
      {(data) => (
        <CaseNotificationSchedulesContext.Provider value={data}>
          {children(data)}
        </CaseNotificationSchedulesContext.Provider>
      )}
    </QueryLoaded>
  );
};

export default SchedulesProvider;
