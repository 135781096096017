import AddIcon from '@mui/icons-material/Add';
import { useInitQuery } from 'hooks/queries/useInitQuery';
import useOpen from 'hooks/useOpen';
import { IGraspLedLevel } from 'interfaces/grasps';
import { useCommonData } from 'modules/Organizations/views/OrganizationDetailsView/hooks/useCommonData';
import React, { useState } from 'react';
import IconButton from '../../../../../../components/IconButton';
import TabLayout from '../../../../../../components/layouts/TabLayout';
import { CommonTabProps } from '../types';
import GraspGenericSettingsForm from './components/GraspGenericSettingsForm';
import GraspLedLevelsList from './components/GraspLedLevelsList';
import GraspSettingsSection from './components/GraspSettingsSection';
import AddGraspSettingsModal from './modals/AddGraspSettingsModal';

const GraspSettingsTab = ({ type }: CommonTabProps) => {
  const { graspSettings } = useCommonData();
  useInitQuery(graspSettings);

  const { isOpen: addLedLevelOpen, toggle: toggleAddLedLevel } = useOpen();

  const [modified, setModified] = useState<IGraspLedLevel | undefined>(
    undefined
  );

  const handleSuccess = async () => {
    await graspSettings.refetch();
    toggleAddLedLevel();
    setModified(undefined);
  };

  const handleSelectLevel = (level: IGraspLedLevel | undefined) => {
    setModified(level);
    toggleAddLedLevel();
  };

  return (
    <>
      {addLedLevelOpen && (
        <AddGraspSettingsModal
          isOpen={addLedLevelOpen}
          toggle={toggleAddLedLevel}
          modified={modified}
          onSuccess={handleSuccess}
        />
      )}
      <TabLayout
        type={type}
        titlePrefix="Organizations.GraspSettings"
        actions={
          <IconButton
            color="primary"
            variant="outlined"
            onClick={toggleAddLedLevel}
          >
            <AddIcon />
          </IconButton>
        }
      >
        <div className="d-flex gap-4">
          <GraspSettingsSection
            titleId="Organizations.OrganizationDetails.GraspSettings.genericSettings"
            component={<GraspGenericSettingsForm />}
          />
          <div className="w-100">
            <GraspSettingsSection
              titleId="Organizations.OrganizationDetails.GraspSettings.ledLevels"
              component={
                <GraspLedLevelsList handleSelectLevel={handleSelectLevel} />
              }
            />
          </div>
        </div>
      </TabLayout>
    </>
  );
};

export default GraspSettingsTab;
