import React, { useContext, useMemo } from 'react';
import { TrendVisualizationModalFormikContext } from '../../types';
import { FormikProps } from 'formik';
import EmptyData from './placeholders/EmptyData';
import RandomFormChart from './components/RandomFormChart';
import RelatedDataLoading from './placeholders/RelatedDataLoading';
import { useInitQuery } from 'hooks/queries/useInitQuery';
import { useQueriesLoading } from 'hooks/queries/useQueriesLoading';
import { TrendVisualizationTemplate } from 'interfaces/trendVisualizations';
import { useCommonData } from 'modules/Organizations/views/OrganizationDetailsView/hooks/useCommonData';

const FormCharts = () => {
  const { groupedConversationQuestions, organizationObservationMeanings } =
    useCommonData();

  useInitQuery(organizationObservationMeanings);
  useInitQuery(groupedConversationQuestions);

  const isLoading = useQueriesLoading([
    groupedConversationQuestions,
    organizationObservationMeanings,
  ]);

  const formikContext = useContext(
    TrendVisualizationModalFormikContext
  ) as FormikProps<TrendVisualizationTemplate>;

  const conversationQuestions = useMemo(() => {
    return (groupedConversationQuestions.data ?? []).flatMap(
      (p) => p.questions
    );
  }, [groupedConversationQuestions.data]);

  const renderVisualizations = useMemo(() => {
    if (isLoading) return <></>;

    return formikContext.values.visualizations.map((visualization) => (
      <div key={visualization.sequence} className="card p-3 shadow-sm">
        <RandomFormChart
          visualization={visualization}
          questions={conversationQuestions}
          meanings={organizationObservationMeanings.data ?? []}
        />
      </div>
    ));
  }, [
    conversationQuestions,
    formikContext.values.visualizations,
    isLoading,
    organizationObservationMeanings.data,
  ]);

  if (formikContext.values.visualizations.length === 0) return <EmptyData />;
  if (isLoading) return <RelatedDataLoading />;

  return <div className="d-flex flex-column gap-4">{renderVisualizations}</div>;
};

export default FormCharts;
