import React from 'react';
import { TreeElementActionDefinition } from '../../../../../../../../components/Tree/types/Tree';
import { faEdit, faUnlink } from '@fortawesome/pro-light-svg-icons';
import TreeFile from '../../../../../../../../components/Tree/components/TreeFile';
import { useIntl } from 'react-intl';
import {
  AssetType,
  IEducationMaterial,
} from '../../../../../../../../interfaces/educationMaterials';
import useOpen from '../../../../../../../../hooks/useOpen';
import useUnlinkEducationMaterialFromCategory from '../../../../../../../../api/mutations/educationMaterials/useUnlinkEducationMaterialFromCategory';
import useRequiredParams from '../../../../../../../../hooks/useRequiredParams';
import { useCommonData } from '../../../../../../hooks/useCommonData';
import { textWrapEllipse } from '../../../../../../../../helpers/utils/strings';
import Flag from 'react-flagkit';
import { groupByProperty } from '../../../../../../../../helpers/utils/groupByProperty';
import { useAssetIcon } from './hooks/useAssetIcon';
import MaterialModal from '../../../../../../modals/MaterialModal';

type EducationMaterialTreeItemProps = {
  categoryId: string;
  material: IEducationMaterial;
};

const EducationMaterialTreeItem = ({
  categoryId,
  material,
}: EducationMaterialTreeItemProps) => {
  const intl = useIntl();

  const { educationMaterialCategoriesTree, educationMaterialCategories } =
    useCommonData();

  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const handleSuccess = async () => {
    await educationMaterialCategories.refetch();
    await educationMaterialCategoriesTree.refetch();
  };

  const { mutateAsync, isLoading } = useUnlinkEducationMaterialFromCategory({
    params: { organizationId, categoryId },
    options: { successFb: handleSuccess },
  });

  const { isOpen, toggle } = useOpen();

  const handleUnlinkMaterial = async () => {
    await mutateAsync(material.id);
  };

  const materialActions: TreeElementActionDefinition[] = [
    {
      name: intl.formatMessage({
        id: 'Education.EducationMaterialCategoriesTab.EducationMaterialCategoriesTree.editMaterial',
      }),
      icon: faEdit,
      onExecute: toggle,
    },
    {
      name: intl.formatMessage({
        id: 'Education.EducationMaterialCategoriesTab.EducationMaterialCategoriesTree.unlinkMaterial',
      }),
      icon: faUnlink,
      onAsyncExecute: handleUnlinkMaterial,
      onAsyncLoading: isLoading,
    },
  ];

  const groupByType = groupByProperty('type');
  const materialAssetsPerType = groupByType(material.assets);

  const { getAssetIcon } = useAssetIcon();

  const materialName = (
    <div className="d-flex align-items-center gap-2">
      <Flag country="US" />
      {material.culture_details
        .filter((p) => p.culture !== 'en-US')
        .map((p) => (
          <Flag key={p.culture} country={p.culture.slice(-2).toUpperCase()} />
        ))}
      <span>{material.name}</span>
      <span className="text-muted">
        {textWrapEllipse(material.description, 50)}
      </span>
      <div className="ms-2 d-flex align-items-center gap-2">
        {Object.entries(materialAssetsPerType).map(([key, val]) => (
          <div
            key={`${categoryId}|${material.id}|${key}`}
            className="d-flex align-items-center gap-1"
          >
            {getAssetIcon(key as AssetType)}
            {`${(val as []).length}x`}
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <>
      {isOpen && (
        <MaterialModal isOpen={isOpen} toggle={toggle} material={material} />
      )}
      <TreeFile
        isLoading={
          educationMaterialCategoriesTree.isRefetching ||
          educationMaterialCategories.isRefetching
        }
        name={materialName}
        actions={materialActions}
      />
    </>
  );
};

export default EducationMaterialTreeItem;
