import { IQueryRequest } from '../../../interfaces/request';
import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { OrganizationQueryParams } from '../../../interfaces/queryParams';
import { getAwareSessionDataMapping } from '../../awareSessions';

const useAwareSessionDataMapping = ({
  params,
  options,
}: IQueryRequest<OrganizationQueryParams>) => {
  const { organizationId } = params;

  return useQuery({
    ...options,
    queryKey: [QueryKeys.getAwareSessionDataMapping, organizationId],
    queryFn: async () => {
      const { data } = await getAwareSessionDataMapping(organizationId);
      return data.data;
    },
  });
};

export default useAwareSessionDataMapping;
