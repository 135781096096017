import { useParams } from 'react-router';
import useMemberDashboardReportsWithReferencePeriod from '../../../../../../../../../../../../../api/queries/dashboards/useMemberDashboardReportsWithReferencePeriod';
import { useOrganizationPathParams } from '../../../../../../../../../../../../../hooks/paths/useOrganizationPathParams';
import { useTypedContext } from '../../../../../../../../../../../../../hooks/useTypedContext';
import { DashboardDataReducerContext } from '../../../../../../../types';

export const useDashboardReportsQuery = () => {
  const [dashboardData] = useTypedContext(DashboardDataReducerContext);

  const { organizationId } = useOrganizationPathParams();

  const { memberId } = useParams<{
    memberId: string;
  }>();

  return useMemberDashboardReportsWithReferencePeriod({
    params: {
      organizationId: organizationId,
      memberId: memberId ?? '',
      from: dashboardData.template.date_selection.date_from,
      to: dashboardData.template.date_selection.date_to,
    },
    options: { staleTime: Infinity, enabled: false },
  });
};
