import React, { useEffect, useMemo } from 'react';
import { getUserId } from '../../../helpers/utils/auth';
import { useNavigate } from 'react-router';
import formIcon from '../../../assets/images/notepad.svg';
import { FormattedMessage } from 'react-intl';
import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Spinner,
} from 'reactstrap';
import useUserCases from '../../../api/queries/cases/useUserCases';
import { useInitQuery } from '../../../hooks/queries/useInitQuery';
import { formatDate } from '../../../helpers/dates';
import { CaseStatus } from '../../../interfaces/cases';

const UserCases = () => {
  const userId = getUserId();
  const navigate = useNavigate();

  const userCases = useUserCases({
    params: { userId },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(userCases);

  useEffect(() => {
    if (!userCases.data || userCases.data.length > 1) return;
    navigate(`/users/${userId}/cases/${userCases.data[0].id}/dashboard`);
  }, [navigate, userCases.data, userId]);

  const renderMedicalRecords = useMemo(() => {
    if (!userCases.data || userCases.data.length === 1) return <></>;

    userCases.data.sort(
      (a, b) =>
        (b.status ? Object.keys(CaseStatus).indexOf(b.status) : -1) -
        (a.status ? Object.keys(CaseStatus).indexOf(a.status) : -1)
    );

    return userCases.data?.map((userCase) => {
      const redirect = () =>
        navigate(`/users/${userId}/cases/${userCase.id}/dashboard`);

      return (
        <ListGroupItem
          key={userCase.id}
          onClick={redirect}
          className={'PersonalityCaseListItem cursor-pointer'}
        >
          <div className="d-flex flex-wrap">
            <div className="d-flex flex-wrap PersonalityCaseListItem__case-data-wrapper">
              <ListGroupItemHeading className="PersonalityCaseListItem__header w-100 text-primary">
                {userCase.title}
              </ListGroupItemHeading>
              <div className="d-flex align-items-center me-4">
                <label className="PersonalityCaseListItem__label">
                  <FormattedMessage id="CaseCard.status" />
                </label>
                {userCase.status ? (
                  <>
                    <div
                      className={`PersonalityCaseListItem__status-indicator PersonalityCaseListItem__status-indicator--${userCase.status}`}
                    />
                    {userCase.status}
                  </>
                ) : (
                  '-'
                )}
              </div>
              <div className="d-flex align-items-center  me-4">
                <label className="PersonalityCaseListItem__label">Type</label>
                {userCase.type || '-'}
              </div>
              <div className="d-flex align-items-center  me-4">
                <label className="PersonalityCaseListItem__label">
                  <FormattedMessage id="General.createdAt" />
                </label>
                {userCase.created_at ? formatDate(userCase.created_at) : '-'}
              </div>
              <div className="w-100 mt-2">
                <label className="PersonalityCaseListItem__label pb-1">
                  <FormattedMessage id="General.description" />
                </label>
                <p className="overflow-hidden me-4">
                  {userCase.description || '-'}
                </p>
              </div>
            </div>
          </div>
        </ListGroupItem>
      );
    });
  }, [navigate, userCases.data, userId]);

  return (
    <>
      {userCases.data && userCases.data.length > 1 ? (
        <div className="card-container mt-4 PatientCasesList">
          <div className="d-flex justify-content-between w-100">
            <div className="d-flex align-items-center card-title">
              <img src={formIcon} alt="form_icon" />
              <FormattedMessage id="Summary.myMedicalRecords" />
            </div>
          </div>
          <div
            className="PatientCasesList__table--dashboard"
            style={{ height: 250 }}
          >
            <ListGroup>{renderMedicalRecords}</ListGroup>
          </div>
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-center">
          <Spinner color="primary" />
        </div>
      )}
    </>
  );
};

export default UserCases;
