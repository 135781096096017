import { getTimelineAnnotations } from 'api/graspManagement';
import QueryKeys from 'api/queries/queryKeys';
import { sub } from 'date-fns';
import { TimelineQueryParams } from 'interfaces/queryParams';
import { IQueryRequest, ODataParamType } from 'interfaces/request';
import { useQuery } from 'react-query';

function useTimelineAnnotations({
  params,
  options,
}: IQueryRequest<TimelineQueryParams>) {
  const { timelineId } = params;
  return useQuery({
    ...options,
    queryFn: async () => {
      const startDate = new Date();
      const endDate = sub(startDate, { years: 3 });

      const oDataParam: ODataParamType[] = [
        {
          key: 'TimeStart',
          operator: 'lt',
          value: startDate.toISOString(),
        },
        {
          key: 'TimeStart',
          operator: 'ge',
          value: endDate.toISOString(),
        },
      ];

      const { data } = await getTimelineAnnotations(timelineId, oDataParam);
      return data.data;
    },
    queryKey: [QueryKeys.getTimelineAnnotations, timelineId],
  });
}

export default useTimelineAnnotations;
