import {
  ExerciseSessionStatus,
  IBaseExerciseSession,
} from '../../../../interfaces/exerciseSessions';

export const getSessionToRestore = (
  sessions: IBaseExerciseSession[],
  caseId: string
) => {
  return sessions.find(
    (p) => p.case_id === caseId && p.status === ExerciseSessionStatus.Pending
  );
};
