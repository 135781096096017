import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useGetFeedbackMessages from 'api/queries/change/useGetFeedbackMessages';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Label,
  ListGroup,
  ListGroupItem,
} from 'reactstrap';

type PredefinedMessagesProps = {
  setPredefinedMessage: (val: string) => void;
  message: string;
};

const PredefinedMessages = ({
  setPredefinedMessage,
  message,
}: PredefinedMessagesProps) => {
  const [activeGroupName, setActiveGroupName] = useState<string | null>(null);
  const { data } = useGetFeedbackMessages();

  const stopPropagation = (ev: React.MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
  };

  const renderMessages = (messages: string[]) => {
    const onAdd =
      (messageEl: string) => (ev: React.MouseEvent<HTMLElement>) => {
        ev.preventDefault();
        stopPropagation(ev);
        setPredefinedMessage(messageEl);
      };

    return (
      <ListGroup onClick={stopPropagation} className="PredefinedMessages">
        {messages.map((messageEl) => {
          const isFilled = message === messageEl;

          return (
            <ListGroupItem
              key={messageEl}
              className="CreateEntryModal__predefined-message__item"
            >
              <div className="d-flex w-100 justify-content-between align-items-center">
                <div className="pe-4">{messageEl}</div>
                <Button
                  onClick={onAdd(messageEl)}
                  disabled={isFilled}
                  color={isFilled ? 'secondary' : 'primary'}
                >
                  {isFilled ? 'Added' : 'Add'}
                </Button>
              </div>
            </ListGroupItem>
          );
        })}
      </ListGroup>
    );
  };

  const renderedMessages = data?.map(({ group_name, messages }) => {
    const isActive = activeGroupName === group_name;

    const onCardClick = (ev: React.MouseEvent<HTMLElement>) => {
      ev.preventDefault();
      ev.stopPropagation();
      setActiveGroupName((val) => (val === group_name ? null : group_name));
    };

    return (
      <Card
        onClick={onCardClick}
        key={group_name}
        className="CreateEntryModal__predefined-message"
      >
        <CardHeader className="CreateEntryModal__predefined-message__header">
          <div className="d-flex w-100 justify-content-between">
            <div>{group_name}</div>
            <button className="btn-transparent">
              <FontAwesomeIcon icon={isActive ? faMinus : faPlus} />
            </button>
          </div>
        </CardHeader>
        {isActive && (
          <CardBody className="p-0">{renderMessages(messages)}</CardBody>
        )}
      </Card>
    );
  });

  return (
    <FormGroup>
      <Label>
        <FormattedMessage id="CaseCard.History.orChoseTemplate" />:
      </Label>
      {renderedMessages}
    </FormGroup>
  );
};

export default PredefinedMessages;
