import React, { ChangeEvent, useCallback } from 'react';
import { useCommonParams } from '../../../../../../hooks/useCommonParams';
import { useCommonData } from '../../../../../../hooks/useCommonData';
import {
  AssetType,
  IAsset,
} from '../../../../../../../../interfaces/educationMaterials';
import useUploadAssetThumbnail from '../../../../../../../../api/mutations/assets/useUploadAssetThumbnail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird, faUpload } from '@fortawesome/pro-light-svg-icons';
import { Input } from 'reactstrap';
import { useAssetAcceptType } from '../../../../../MaterialModal/components/MaterialModalBody/components/UploadAssetsStep/hooks/useAssetAcceptType';

type AssetThumbnailUploadButtonProps = {
  asset: IAsset;
};

const AssetThumbnailUploadButton = ({
  asset,
}: AssetThumbnailUploadButtonProps) => {
  const { organizationId } = useCommonParams();
  const { educationMaterials } = useCommonData();

  const assetAcceptType = useAssetAcceptType(AssetType.Image);

  const onThumbnailUpdated = useCallback(async () => {
    await educationMaterials.refetch();
  }, [educationMaterials]);

  const {
    mutateAsync: uploadAssetThumbnail,
    isLoading: uploadThumbnailLoading,
  } = useUploadAssetThumbnail(organizationId, asset.id, onThumbnailUpdated);

  const onFileChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) return;
      await uploadAssetThumbnail(e.target.files[0]);
    },
    [uploadAssetThumbnail]
  );

  return (
    <div className="image-upload">
      <label htmlFor={`${asset.id}|thumbnail-upload`}>
        {uploadThumbnailLoading ? (
          <span className="btn btn-outline-primary">
            <FontAwesomeIcon icon={faSpinnerThird} spin={true} />
          </span>
        ) : (
          <span className="btn btn-outline-primary">
            <FontAwesomeIcon icon={faUpload} />
          </span>
        )}
      </label>
      <Input
        id={`${asset.id}|thumbnail-upload`}
        className="d-none"
        type="file"
        onChange={onFileChange}
        accept={assetAcceptType}
      />
    </div>
  );
};

export default AssetThumbnailUploadButton;
