import { useCallback } from 'react';
import {
  ICreateProject,
  IProject,
} from '../../../../../interfaces/submissions';
import {
  getCultureValueBasedOnHtml,
  getHtmlBasedOnCultureValue,
} from '../../../../../helpers/utils/cultures';
import { CreateProjectFormValues } from '../types/ProjectModal';
import useCultures from '../../../../../hooks/cultures/useCultures';
import { IPatchObject } from '../../../../../interfaces/request';

export const useProjectModalTransformations = () => {
  const { currentCulture } = useCultures();

  const transformProjectToInitialValues = useCallback(
    (data: IProject): CreateProjectFormValues => {
      return {
        ...data,
        date_from:
          data.date_from === null
            ? null
            : data.date_from.substring(0, data.date_from.indexOf('T')),
        date_to:
          data.date_to === null
            ? null
            : data.date_to.substring(0, data.date_to.indexOf('T')),
        description: getCultureValueBasedOnHtml(
          data.description,
          currentCulture
        ),
        short_description: getCultureValueBasedOnHtml(
          data.short_description,
          currentCulture
        ),
        title: getCultureValueBasedOnHtml(data.title, currentCulture),
        mobile_header: getCultureValueBasedOnHtml(
          data.mobile_header,
          currentCulture
        ),
        successful_submission_message: getCultureValueBasedOnHtml(
          data.successful_submission_message,
          currentCulture
        ),
        unsuccessful_submission_message: getCultureValueBasedOnHtml(
          data.unsuccessful_submission_message,
          currentCulture
        ),
        post_register_message: getCultureValueBasedOnHtml(
          data.post_register_message,
          currentCulture
        ),
      };
    },
    [currentCulture]
  );

  const transformInitialValuesToCreateProject = useCallback(
    (values: CreateProjectFormValues): ICreateProject => {
      return {
        ...values,
        template_code:
          values.template_code === '' ? null : values.template_code,
        description: getHtmlBasedOnCultureValue<string>(values.description),
        short_description:
          getHtmlBasedOnCultureValue<string | null>(values.short_description) ??
          null,
        title: getHtmlBasedOnCultureValue<string>(values.title),
        mobile_header:
          getHtmlBasedOnCultureValue<string | null>(values.mobile_header) ??
          null,
        successful_submission_message:
          getHtmlBasedOnCultureValue<string | null>(
            values.successful_submission_message
          ) ?? null,
        unsuccessful_submission_message:
          getHtmlBasedOnCultureValue<string | null>(
            values.unsuccessful_submission_message
          ) ?? null,
        post_register_message:
          getHtmlBasedOnCultureValue<string | null>(
            values.post_register_message
          ) ?? null,
      };
    },
    []
  );

  const transformCreateProjectToPatchObject = useCallback(
    (
      createProject: ICreateProject,
      initialValues: CreateProjectFormValues
    ): IPatchObject[] => {
      let patch: IPatchObject[] = [];

      Object.entries(createProject).forEach(([key, value]) => {
        if (key === 'fields_definition') {
          patch.push({
            op: 'replace',
            path: `/${key}`,
            value: value as object,
          });

          return;
        }

        if (createProject[key] === initialValues[key]) return;

        patch.push({
          op: 'replace',
          path: `/${key}`,
          value: value as string,
        });
      });

      return patch.filter((p) => p.path !== '/organization');
    },
    []
  );

  return {
    transformProjectToInitialValues: transformProjectToInitialValues,
    transformInitialValuesToCreateProject:
      transformInitialValuesToCreateProject,
    transformCreateProjectToPatchObject: transformCreateProjectToPatchObject,
  };
};
