import { useParams } from 'react-router';

type RequiredParams<Key extends string = string> = {
  readonly [key in Key]: string;
};

const useRequiredParams = <T extends string | Record<string, string> = string>(
  requiredParamNames: string[],
  componentName?: string
): Readonly<[T] extends [string] ? RequiredParams<T> : T> => {
  const routeParams = useParams();
  const requiredParams: { [key: string]: string } = {};
  for (const paramName of requiredParamNames) {
    const parameter = routeParams[paramName];

    if (!parameter) {
      const message = `${
        componentName ? `The ${componentName}` : `This`
      } component should not be rendered on a route which does not have the ${paramName} parameter`;

      if (process.env.NODE_ENV === 'development') {
        throw new Error(message);
      } else {
        console.error(message);
      }
    }

    requiredParams[paramName] = parameter as string;
  }
  return requiredParams as [T] extends [string] ? RequiredParams<T> : T;
};

export default useRequiredParams;
