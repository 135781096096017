import { FormikProps } from 'formik';
import { cloneDeep } from 'lodash';
import { MouseEvent, useCallback } from 'react';
import { CultureEntry } from '../../../../../interfaces/cultures';

export const useHandleAdd = <T>(props: FormikProps<T>, name: string) => {
  return useCallback(
    (_: MouseEvent<HTMLButtonElement>) => {
      const currentValue = props.getFieldProps(name)
        .value as CultureEntry<string>[];

      let currentCopy = cloneDeep(currentValue);
      currentCopy.push({
        culture: '',
        value: '',
      });

      props.setFieldValue(name, currentCopy);
    },
    [name, props]
  );
};
