import React, { memo } from 'react';
import { DelimiterValidConversationTemplateItem } from '../../../../../../../../../../../../interfaces/conversations';
import { FormattedMessage } from 'react-intl';

type DelimiterItemProps = {
  item: DelimiterValidConversationTemplateItem;
};

const DelimiterItem = ({ item }: DelimiterItemProps) => {
  return (
    <div className="ConversationTemplateDelimiterItem">
      <span className="ConversationTemplateDelimiterItem__text">
        <FormattedMessage
          id={
            item.persist
              ? 'MyOrganizations.conversationTemplates.phone.persistDelimiter'
              : 'MyOrganizations.conversationTemplates.phone.delimiter'
          }
        />
      </span>
    </div>
  );
};

export default memo(DelimiterItem);
