import { TableCell, tableCellClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

const CustomTableCell = styled(TableCell)(({ theme }) => ({
  [`&`]: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.action.disabled,
    paddingBottom: '4px',
    border: 0,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

export default CustomTableCell;
