import React from 'react';
import patientActivity from 'assets/images/patient_activity.svg';
import { FormattedMessage } from 'react-intl';
import { ListGroup, ListGroupItem } from 'reactstrap';
import Calendar from 'components/Calendar';

import SkeletonPlaceholderOnLoad from 'components/SkeletonPlaceholderOnLoad';
import Skeleton from 'react-loading-skeleton';
import ActivityListItem from './components/ActivityListItem';
import { IActivity } from 'interfaces/activities';
import { DateRange } from 'react-day-picker';
import ActivitiesPerDate from './components/ActivitiesPerDate';
import EmptyInformation from 'modules/TimelineDetails/EmptyInformation';

type ActivityProps = {
  timelineId?: string;
  hideCalendar?: boolean;
  activities:
    | {
        [key: string]: IActivity[];
      }
    | undefined;
  selectedDates: DateRange | null;
  onDateChange?: (dateRange: DateRange) => void;
  isLoading: boolean;
};

const Activity = ({
  selectedDates,
  hideCalendar,
  activities,
  onDateChange,
  isLoading,
}: ActivityProps) => {
  const dates = activities ? Object.keys(activities) : [];

  const renderedRows = dates.map((date) => {
    if (activities && activities[date]) {
      const renderedActivities = activities[date].map((activity, index) => (
        <ActivityListItem
          data={activity}
          key={`${activity.activity_type}-${activity.activity_type}-${index}`}
        />
      ));

      return (
        <ActivitiesPerDate key={date} date={date}>
          {renderedActivities}
        </ActivitiesPerDate>
      );
    } else return null;
  });

  const areRowsRendered = renderedRows.length > 0;

  return (
    <div className="card-container mt-4 Activity">
      <div className="d-flex align-items-center cardContainer__header">
        <div className="cardContainer__header__title">
          <img src={patientActivity} alt="form_icon" />
          <FormattedMessage id="Summary.activity" />
        </div>
      </div>
      <div className="d-flex Activity__container">
        {!hideCalendar && (
          <div className="Activity__calendar d-flex align-items-center justify-content-center">
            <Calendar
              onDateChange={onDateChange}
              defaultValueForRange={selectedDates || undefined}
            />
          </div>
        )}
        <div className="Activity__log">
          <>
            <ListGroup className="Activity__log__list">
              {!isLoading && !activities && (
                <ListGroupItem>
                  <FormattedMessage id="General.nothingToDisplay" />
                </ListGroupItem>
              )}
              {areRowsRendered ? (
                <SkeletonPlaceholderOnLoad
                  isLoading={isLoading}
                  placeholder={
                    <Skeleton height={25} className="mb-2" count={8} />
                  }
                >
                  <>{renderedRows}</>
                </SkeletonPlaceholderOnLoad>
              ) : (
                <EmptyInformation noMargin type="range" />
              )}
            </ListGroup>
          </>
        </div>
      </div>
    </div>
  );
};

export default Activity;
