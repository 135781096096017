import { requestUsersDataDumpGeneration } from 'api/membership';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from '../../../modules/Notifications';

type UseRequestUsersDataDumpGenerationProps = {
  userId: string;
};

function useRequestUsersDataDumpGeneration(
  successFb?: () => void,
  errorFb?: () => void
) {
  return useMutationWithResultNotification({
    asyncApiFunction: async ({
      userId,
    }: UseRequestUsersDataDumpGenerationProps) => {
      await requestUsersDataDumpGeneration(userId);
    },
    resetOnResult: true,
    onSuccess: () => {
      successFb && successFb();
      displaySuccessNotification(
        'MyProfile.DataDumpGeneration.generatedSuccessfully'
      );
    },
    onError: () => {
      errorFb && errorFb();
      displayErrorNotification(
        'MyProfile.DataDumpGeneration.fileGenerationError'
      );
    },
  });
}

export default useRequestUsersDataDumpGeneration;
