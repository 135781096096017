import { CultureEntry } from '../../../interfaces/cultures';

export const buildMaterialTranslations = (
  nameTranslations: CultureEntry<string>[],
  descriptionTranslations: CultureEntry<string>[]
) => {
  let translations: CultureEntry<{
    name: string;
    description: string;
  }>[] = [];

  nameTranslations.forEach((translation) => {
    const existingTranslationIndex = translations.findIndex(
      (p) => p.culture === translation.culture
    );

    if (existingTranslationIndex === -1) {
      translations.push({
        culture: translation.culture,
        value: { name: translation.value, description: '' },
      });
      return;
    }

    translations[existingTranslationIndex].value.name = translation.value;
  });

  descriptionTranslations.forEach((translation) => {
    const existingTranslationIndex = translations.findIndex(
      (p) => p.culture === translation.culture
    );

    if (existingTranslationIndex === -1) {
      translations.push({
        culture: translation.culture,
        value: { description: translation.value, name: '' },
      });
      return;
    }

    translations[existingTranslationIndex].value.description =
      translation.value;
  });

  return translations;
};
