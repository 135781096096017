import React from 'react';
import { FormattedMessage } from 'react-intl';

type SingleDescriptionProps = {
  label: string;
  labelFor?: string;
};

const SingleDescription = ({
  label,
  children,
  labelFor,
}: React.PropsWithChildren<SingleDescriptionProps>) => {
  return (
    <div
      className="mb-3"
      style={{
        width: '100%',
      }}
    >
      <label
        className="mb-0 graspDetailsContainer__title mb-1 w-100"
        htmlFor={labelFor}
      >
        <b>
          <FormattedMessage id={label} />
        </b>
      </label>
      {children}
    </div>
  );
};

export default SingleDescription;
