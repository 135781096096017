import { IAsyncFormHookValues } from 'interfaces/forms';
import { TemplateItemType } from 'interfaces/change';
import { useContext, useMemo } from 'react';
import { FormikErrors, FormikProps } from 'formik';
import {
  TrendVisualization,
  TrendVisualizationChartType,
  TrendVisualizationTemplate,
} from 'interfaces/trendVisualizations';
import { useInitQuery } from 'hooks/queries/useInitQuery';
import { TrendVisualizationModalFormikContext } from '../../../../../../../types';
import { useTranslations } from 'hooks/useTranslations';
import { useCommonData } from 'modules/Organizations/views/OrganizationDetailsView/hooks/useCommonData';

type AddTooltipFormQuestionValues = {
  [questionId: string]: { enabled: boolean };
};

export type AddTooltipFormValues = {
  [type in TemplateItemType]: {
    enabled: boolean;
    chartType: TrendVisualizationChartType;
    questions: AddTooltipFormQuestionValues;
  };
};

export const useAddTooltipForm =
  (): IAsyncFormHookValues<AddTooltipFormValues> => {
    const { wrapWithIntl, attachPrefix } = useTranslations(
      'Organizations.OrganizationDetails.TrendVisualizations.TrendVisualizationModal.FormHeading.FormHeadingActions.AddTooltip'
    );

    const templateFormikContext = useContext(
      TrendVisualizationModalFormikContext
    ) as FormikProps<TrendVisualizationTemplate>;

    const { groupedConversationQuestions } = useCommonData();
    useInitQuery(groupedConversationQuestions);

    const initialValues = useMemo(() => {
      let _initialValues: Partial<AddTooltipFormValues> = {};

      Object.values(TemplateItemType).forEach((value) => {
        _initialValues[value] = {
          enabled: false,
          questions: {},
          chartType: TrendVisualizationChartType.LineChart,
        };
      });

      const flattenConversationQuestions = (
        groupedConversationQuestions.data ?? []
      ).flatMap((p) => p.questions);

      const conversationQuestionsMap = new Map(
        flattenConversationQuestions.map((p) => [
          p.resource_id ?? p.content?.guid,
          p,
        ])
      );
      const uniqueQuestions = [...conversationQuestionsMap.values()];

      let questionValues: AddTooltipFormQuestionValues = {};

      uniqueQuestions.forEach((question) => {
        questionValues[question.resource_id] = { enabled: false };
      });

      _initialValues[TemplateItemType.Questions] = {
        enabled: false,
        chartType: TrendVisualizationChartType.LineChart,
        questions: questionValues,
      };

      return _initialValues as AddTooltipFormValues;
    }, [groupedConversationQuestions.data]);

    const handleSubmit = async (values: AddTooltipFormValues) => {
      let visualization: TrendVisualization = {
        sequence: templateFormikContext.values.visualizations.length,
        title: '',
        title_translations: [],
        elements: [],
      };

      Object.entries(values).forEach(([key, val]) => {
        if (!val.enabled) return;

        const type = key as TemplateItemType;

        if (type !== TemplateItemType.Questions) {
          visualization.elements.push({
            type: type,
            chart_type: val.chartType,
            generate_points_nearby: false,
            question_id: null,
          });

          return;
        }

        const selectedQuestions = Object.entries(val.questions)
          .filter(([_, config]) => config.enabled)
          .map(([questionId, _]) => questionId);

        selectedQuestions.forEach((question) => {
          visualization.elements.push({
            type: type,
            chart_type: val.chartType,
            generate_points_nearby: false,
            question_id: question,
          });
        });
      });

      const questions = visualization.elements.find(
        (p) => p.type === TemplateItemType.Questions
      );

      const visualizationsLength =
        visualization.elements.length -
        visualization.elements.filter(
          (p) => p.type === TemplateItemType.Questions
        ).length +
        (questions ? 1 : 0) -
        1;

      const plusMore =
        visualizationsLength > 0
          ? wrapWithIntl(attachPrefix('plusMore'), {
              times: visualizationsLength,
            })
          : '';

      const visualizationTitle =
        visualization.elements.length === 1
          ? visualization.elements[0].type
          : `${visualization.elements[0].type} ${plusMore}`;

      visualization.title = visualizationTitle;

      visualization.title_translations = [
        {
          culture: 'en-US',
          value: visualizationTitle,
        },
        { culture: 'nb-NO', value: visualizationTitle },
      ];

      templateFormikContext.setValues({
        ...templateFormikContext.values,
        visualizations: [
          ...templateFormikContext.values.visualizations,
          visualization,
        ],
      });
    };

    const validation = (values: AddTooltipFormValues) => {
      const errors: FormikErrors<AddTooltipFormValues> = {};

      const allDisabled = !Object.values(values).some((p) => p.enabled);
      if (allDisabled) errors.AllObservations = {};

      if (values.Questions.enabled) {
        const questionsNotSelected = !Object.values(
          values.Questions.questions
        ).some((p) => p.enabled);

        if (questionsNotSelected) errors.Questions = {};
      }

      return errors;
    };

    return {
      initialValues,
      handleSubmit,
      validation,
    };
  };
