import request from 'api/utils/request';
import Axios from 'axios';
import { normalizeQueryParams } from 'helpers/utils/normalizeQueryParams';
import {
  AuthenticationScheme,
  ChangeEmailBody,
  IChangePasswordBody,
  ICreateOrganizationBody,
  ILoginResponse,
  InvitationBody,
  IOrganizationDetails,
  IOrganizationTrackingTypes,
  IRegisterBody,
  IUserDataDumpStatus,
  IUserDeletionBody,
  IUserOrganizationResponse,
  UserExternalLoginItem,
} from 'interfaces/membership';
import { IPersonality } from 'interfaces/personalities';
import { IParamsTypeInRequest } from 'interfaces/reducer';
import { IPatchObject } from 'interfaces/request';
import { IResponse, IResponseWithPagination } from 'interfaces/response';

export const register = (data: IRegisterBody) => {
  let modifiedData: Partial<IRegisterBody> = {};

  const keys = Object.keys(data);

  keys.forEach((key) => {
    if (!!data[key]) {
      modifiedData[key] = data[key];
    }
  });

  return request<Partial<IRegisterBody>, void>({
    url: '/users/register',
    method: 'POST',
    data: modifiedData,
  });
};

export const updateAccount = (userId, data: IPatchObject[]) =>
  request<IPatchObject[], IResponse<IPersonality>>({
    url: `/users/${userId}`,
    method: 'PATCH',
    data,
  });

export const requestUsersDataDumpGeneration = (userId) =>
  request<undefined, void>({
    url: `/users/${userId}/data_dumps/request`,
    method: 'POST',
  });

export const downloadUsersDataDumpFile = (userId: string, fileName: string) => {
  return request<undefined, ArrayBuffer>({
    url: `users/${userId}/data_dumps/${fileName}/download`,
    method: 'POST',
  });
};

export const deleteAccount = (userId: string, data: IUserDeletionBody) =>
  request<any, any>({
    url: `/users/${userId}`,
    method: 'DELETE',
    data,
  });

export const refreshOrganizationToken = (organizationId) =>
  request<void, IResponse<ILoginResponse>>({
    url: `/organizations/${organizationId}/current`,
    method: 'POST',
  });

export const deleteOrganizationToken = (organizationId) =>
  request<void, IResponse<ILoginResponse>>({
    url: `/organizations/${organizationId}/current`,
    method: 'DELETE',
  });

export const signIn = (data) =>
  request<any, IResponse<ILoginResponse>>({
    url: 'users/login',
    method: 'POST',
    data,
  });

export const signOut = () =>
  request({
    url: '/users/log_out',
    method: 'POST',
  });

export const resetPassword = (data) =>
  request({
    url: '/users/forgot_password',
    method: 'POST',
    data,
  });

export const changePassword = (data: IChangePasswordBody) =>
  request<IChangePasswordBody, any>({
    url: '/users/change_password',
    method: 'PUT',
    data,
  });

export const changeEmail = (userId: string, email: string, password: string) =>
  request<ChangeEmailBody, void>({
    url: `/users/${userId}/change_email`,
    method: 'POST',
    data: {
      new_email: email,
      password,
    },
  });

export const setPassword = (data) =>
  request({
    url: '/users/set_password',
    method: 'POST',
    data,
  });

export const resendEmail = (data) =>
  request({
    url: '/users/resend_confirmation_email',
    method: 'POST',
    data,
  });

export const confirmEmail = (data) =>
  request({
    url: '/users/confirm_email',
    method: 'POST',
    data,
  });

export const refreshAccessToken = () =>
  request<void, IResponse<ILoginResponse>>({
    url: `/users/new_access_token`,
    method: 'GET',
  });

export const newAccessToken = (token: string) =>
  request<void, IResponse<ILoginResponse>>({
    url: `/users/new_access_token/${token}`,
    method: 'GET',
  });

export const getUrlFromDiscoveryService = () =>
  Axios.get(process.env.REACT_APP_DISCOVERY_SERVICE || '');

export const updatePersonality = (
  personalityId: string,
  data: IPatchObject[]
) =>
  request<IPatchObject[], void>({
    url: `/personalities/${personalityId}`,
    method: 'PATCH',
    data,
  });

export const getUsersDataDumpStatus = (userId: string) =>
  request<void, IResponse<IUserDataDumpStatus>>({
    url: `/users/${userId}/data_dumps/status`,
    method: 'GET',
  });

export const getUserOrganizationsWithPaging = (
  userId: string,
  queryParams: Partial<IParamsTypeInRequest>
) =>
  request<void, IResponseWithPagination<IUserOrganizationResponse[]>>({
    url: `/users/${userId}/organizations${normalizeQueryParams(
      queryParams
    )}&$filter=${encodeURI("Role/Role eq 'OrganizationAdmin'")}`,
    method: 'GET',
  });

export const getUsersInOrganizationWithPaging = (
  organizationId: string,
  queryParams: Partial<IParamsTypeInRequest>
) =>
  request<void, IResponseWithPagination<IUserOrganizationResponse[]>>({
    url: `/organizations/${organizationId}/users${normalizeQueryParams(
      queryParams
    )}`,
    method: 'GET',
  });

export const createNewOrganization = (data: ICreateOrganizationBody) =>
  request<ICreateOrganizationBody, IResponse<IOrganizationDetails>>({
    url: `/organizations`,
    method: 'POST',
    data,
  });

export const editOrganization = (
  organizationId: string,
  data: IPatchObject[]
) =>
  request<IPatchObject[], void>({
    url: `/organizations/${organizationId}`,
    method: 'PATCH',
    data,
  });

export const getOrganizationDetails = (organizationId: string) =>
  request<void, IResponse<IOrganizationDetails>>({
    url: `/organizations/${organizationId}`,
    method: 'GET',
  });

export const getOrganizationTrackingTypes = (organizationId: string) =>
  request<void, IResponse<IOrganizationTrackingTypes>>({
    url: `/organizations/${organizationId}/tracking_types`,
    method: 'GET',
  });

export const inviteUserToOrganization = (
  organizationId: string,
  data: InvitationBody
) =>
  request<InvitationBody, void>({
    url: `/organizations/${organizationId}/access_profiles_invitations`,
    method: 'POST',
    data,
  });

export const deleteAllUserRolesInOrganization = (
  organizationId: string,
  userId: string
) =>
  request<void, void>({
    url: `/organizations/${organizationId}/users/${userId}`,
    method: 'DELETE',
  });

export const deleteSingleUserRoleInOrganization = (
  organizationId: string,
  userId: string,
  role: string
) =>
  request<void, void>({
    url: `/organizations/${organizationId}/users/${userId}/roles/${role}`,
    method: 'DELETE',
  });

export const createOrganizationLocation = (
  organizationId: string,
  name: string,
  description: string
) =>
  request<{ name: string; description: string }, void>({
    url: `/organizations/${organizationId}/locations`,
    method: 'POST',
    data: {
      name,
      description,
    },
  });

export const deleteLocationInOrganization = (
  organizationId: string,
  endpointId: string
) =>
  request<{ name: string }, void>({
    url: `/organizations/${organizationId}/locations/${endpointId}`,
    method: 'DELETE',
  });

export const editLocation = (
  organizationId: string,
  endpointId: string,
  name: string,
  description: string
) =>
  request<{ name: string; description: string }, void>({
    url: `/organizations/${organizationId}/locations/${endpointId}`,
    method: 'PUT',
    data: {
      name,
      description,
    },
  });

export const getExternalAuthenticationSchemes = () =>
  request<void, AuthenticationScheme[]>({
    url: `/users/external_authentication_schemes`,
    method: 'GET',
  });

export const getUserExternalLogins = (userId: string) =>
  request<void, IResponse<UserExternalLoginItem[]>>({
    url: `/users/${userId}/external_logins`,
    method: 'GET',
  });

export const unlinkExternalUsersProvider = (
  userId: string,
  provider: string,
  identity: string
) =>
  request<{ provider: string; identity: string }, void>({
    url: `/users/${userId}/remove_external_identity`,
    method: 'DELETE',
    data: {
      provider: provider,
      identity: identity,
    },
  });

export const linkExternalUsersProvider = (
  userId: string,
  provider: string,
  identity: string
) =>
  request<{ provider: string; identity: string }, void>({
    url: `/users/${userId}/set_external_identity`,
    method: 'POST',
    data: {
      provider: provider,
      identity: identity,
    },
  });
