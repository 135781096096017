import useReportTemplate from 'api/queries/reports/useReportTemplate';
import FieldInput from 'components/FieldInput';
import FieldMultiSelect from 'components/FieldMultiSelect';
import {
  ReportParameter,
  ReportParameterType,
  ReportParameterWithIndex,
  ReportTemplateCode,
} from 'interfaces/reports';
import React, { useEffect, useMemo } from 'react';
import { Col, Container, Row } from 'reactstrap';
import GenerateReportModalGenericInput from './GenerateReportModalGenericInput';
import { useCommonParams } from '../../hooks/useCommonParams';
import useInfiniteOrganizationPatientList from '../../../../api/queries/patients/useInfinitePatientList';

type GenerateReportFormProps = {
  fillInitialParameters: (
    parameters: ReportParameter[],
    code: ReportTemplateCode
  ) => void;
  code: ReportTemplateCode;
};

const GenerateReportForm = ({
  code,
  fillInitialParameters,
}: GenerateReportFormProps) => {
  const { organizationId } = useCommonParams();

  const { data: observationDataTemplate, isFetched } = useReportTemplate({
    params: { code },
  });

  const { patients } = useInfiniteOrganizationPatientList({
    params: { organizationId, pageSize: 5000 },
    options: { enabled: code === ReportTemplateCode.PARTICIPANT_FEEDBACK },
  });

  useEffect(() => {
    if (isFetched) {
      fillInitialParameters(
        observationDataTemplate?.parameters as ReportParameter[],
        code
      );
    }
  }, [observationDataTemplate, fillInitialParameters, code, isFetched]);

  const {
    dateTimeParams,
    multiSelectParams,
    singleSelectParams,
    trueFalseOptionParams,
  } = useMemo(() => {
    let dateTimeParams: ReportParameterWithIndex[] = [];
    let singleSelectParams: ReportParameterWithIndex[] = [];
    let multiSelectParams: ReportParameterWithIndex[] = [];
    let trueFalseOptionParams: ReportParameterWithIndex[] = [];

    observationDataTemplate?.parameters.forEach((value, index) => {
      switch (value.type) {
        case ReportParameterType.DateTime:
          dateTimeParams.push({
            ...value,
            index: index,
          });
          break;
        case ReportParameterType.SingleSelect:
          singleSelectParams.push({
            ...value,
            index: index,
          });
          break;
        case ReportParameterType.MultiSelect:
          multiSelectParams.push({
            ...value,
            index: index,
          });
          break;
        default:
          trueFalseOptionParams.push({
            ...value,
            index: index,
          });
          break;
      }
    });

    return {
      dateTimeParams,
      singleSelectParams,
      multiSelectParams,
      trueFalseOptionParams,
    };
  }, [observationDataTemplate]);

  const patientsOptions = useMemo(() => {
    return (patients ?? []).map((patient) => ({
      value: patient.id,
      label: `${patient.first_name} ${patient.last_name} (No: ${patient.number})`,
    }));
  }, [patients]);

  const renderMultiSelectParams = multiSelectParams.map((value) => {
    const options = value.options.map((option) => ({
      label: option,
      value: option,
    }));

    return (
      <GenerateReportModalGenericInput
        key={value.index}
        id={`parameters[${value.index}].value`}
        name={`parameters[${value.index}].value`}
        className="GenerateReportModal__multi-selection"
        isMulti={true}
        options={options}
        labelTranslationId={value.name}
        component={FieldMultiSelect}
      />
    );
  });

  const renderTrueFalseOptionParams = trueFalseOptionParams.map((value) => (
    <GenerateReportModalGenericInput
      key={value.index}
      id={`parameters[${value.index}].value`}
      name={`parameters[${value.index}].value`}
      type="checkbox"
      defaultChecked={false}
      labelTranslationId={value.name}
      component={FieldInput}
    />
  ));

  const renderSingleSelectParams = singleSelectParams.map((value) => {
    if (code === ReportTemplateCode.PARTICIPANT_FEEDBACK) {
      return (
        <GenerateReportModalGenericInput
          key={value.index}
          id={`parameters[${value.index}].value`}
          name={`parameters[${value.index}].value`}
          className="GenerateReportModal__multi-selection"
          isMulti={false}
          options={patientsOptions}
          labelTranslationId={value.name}
          component={FieldMultiSelect}
        />
      );
    }

    return (
      <Container key={value.index}>
        <Row>
          <span className="d-flex mb-2">{value.name}</span>
        </Row>
        <Row>
          {value.options.map((option) => (
            <Col key={option} className="d-flex justify-content-center">
              <GenerateReportModalGenericInput
                id={`parameters[${value.index}].value.option[${option}]`}
                name={`parameters[${value.index}].value`}
                type="radio"
                value={option}
                labelTranslationId={option}
                component={FieldInput}
              />
            </Col>
          ))}
        </Row>
      </Container>
    );
  });

  const renderDateTimeParams = dateTimeParams.map((value) => (
    <Col key={value.index}>
      <GenerateReportModalGenericInput
        id={`parameters[${value.index}].value`}
        name={`parameters[${value.index}].value`}
        type="date"
        labelTranslationId={value.name}
        component={FieldInput}
      />
    </Col>
  ));

  return (
    <div>
      <Row>{renderDateTimeParams}</Row>
      <div>{renderSingleSelectParams}</div>
      <div>{renderMultiSelectParams}</div>
      <div className="my-4">{renderTrueFalseOptionParams}</div>
    </div>
  );
};

export default GenerateReportForm;
