import React from 'react';
import {
  ValidConversationTemplateItem,
  ValidConversationTemplateItemType,
} from '../../../../../../../../../../interfaces/conversations';
import { Field } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/pro-light-svg-icons';
import FieldInput from '../../../../../../../../../../components/FieldInput';
import { useTranslations } from '../../hooks/useTranslations';

type ResourceIdItemEditProps = {
  item: ValidConversationTemplateItem;
  index: number;
};

const ResourceIdItemEdit = ({ item, index }: ResourceIdItemEditProps) => {
  const { providerInvariant } = useTranslations();

  return (
    <>
      {(item.type === ValidConversationTemplateItemType.Question ||
        item.type === ValidConversationTemplateItemType.Education) && (
        <Field
          required={true}
          disabled={true}
          id={`content[${index}].resource_id`}
          name={`content[${index}].resource_id`}
          addon={<FontAwesomeIcon icon={faKey} />}
          label={providerInvariant.wrapWithIntl(
            providerInvariant.attachPrefix('resourceId')
          )}
          type="text"
          component={FieldInput}
        />
      )}
      {item.type === ValidConversationTemplateItemType.Feedback && (
        <Field
          required={true}
          disabled={true}
          id={`content[${index}].group_name`}
          name={`content[${index}].group_name`}
          addon={<FontAwesomeIcon icon={faKey} />}
          label={providerInvariant.wrapWithIntl(
            providerInvariant.attachPrefix('resourceId')
          )}
          type="text"
          component={FieldInput}
        />
      )}
    </>
  );
};

export default ResourceIdItemEdit;
