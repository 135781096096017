import { DEFAULT_DEBOUNCE_TIME, MIN_SEARCH_LENGTH } from 'helpers/constants';
import React, { ChangeEvent, useState } from 'react';
import { useIntl } from 'react-intl';
import { Input } from 'reactstrap';
import { useDebouncedCallback } from 'use-debounce';
import InputFieldWithImage, {
  InputFieldWithImageColor,
} from './InputFieldWithImage';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GraspColors, graspColorsValues } from '../helpers/colors';
import { useCookie } from '../hooks/useCookie';
import { ViewTheme } from '../interfaces/ui';
import classNames from 'classnames';

interface DebouncedInputProps {
  inputIconSrc: string | IconDefinition;
  onDebounce: (value: string) => void;
  debounceTime?: number;
  labelTranslationId: string;
  hideLabel?: boolean;
  name: string;
}

const DebouncedInput = ({
  onDebounce,
  debounceTime = DEFAULT_DEBOUNCE_TIME,
  inputIconSrc,
  labelTranslationId,
  name,
  hideLabel = false,
}: DebouncedInputProps) => {
  const intl = useIntl();
  const [input, setInput] = useState<string>('');

  const theme = useCookie<ViewTheme>('theme');

  const debouncedCallback = useDebouncedCallback((name, value) => {
    onDebounce(value);
  }, debounceTime);

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);

    if (
      e.target.value.length >= MIN_SEARCH_LENGTH ||
      e.target.value.length === 0
    ) {
      debouncedCallback(name, e.target.value);
    }
  };

  const onClear = () => {
    setInput('');
    onDebounce('');
  };

  return (
    <InputFieldWithImage
      color={
        theme === ViewTheme.Dark
          ? InputFieldWithImageColor.dark
          : InputFieldWithImageColor.gray
      }
      image={
        typeof inputIconSrc === 'string' ? (
          <img src={inputIconSrc} alt="search_icon" />
        ) : (
          <FontAwesomeIcon
            icon={inputIconSrc}
            color={graspColorsValues[GraspColors.BLUE]}
          />
        )
      }
      labelTranslationId={hideLabel ? undefined : labelTranslationId}
      clearFunction={onClear}
      canClear={input.length > 0}
      inputId={name}
    >
      <Input
        id={name}
        name={name}
        className={classNames('form-control', {
          '!bg-transparent !text-neutral-50': theme === ViewTheme.Dark,
        })}
        onChange={onInputChange}
        value={input}
        placeholder={intl.formatMessage({
          id: labelTranslationId,
        })}
      />
    </InputFieldWithImage>
  );
};

export default DebouncedInput;
