import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import graspSmall from 'assets/images/grasp_small_blue.svg';

//types
import { GraspInfoMode, GraspModalActions } from 'interfaces/grasps';

//components
import ActionGraspModal from '../ActionGraspModal';
import UserGraspList from './UserGraspList';
import PatientGraspList from './PatientGraspList';
import { useBrowserState } from 'store/reducerHooks';

//personalityId is used for organization purposes
//userId is used ONLY on dashboard
type GraspsInfoProps = {
  userId?: string;
  personalityId?: string;
  mode: GraspInfoMode;
  organizationId?: string;
};

const GraspsInfo: React.FC<GraspsInfoProps> = ({
  userId,
  personalityId,
  mode,
  organizationId,
}) => {
  const [isDetailsOpen, setDetailsOpen] = useState(false);
  const isBrowser = useBrowserState();

  const toggleShareGraspModal = () => {
    if (isBrowser) {
      setDetailsOpen((state) => !state);
    } else {
      if (personalityId)
        window.dispatchEvent(
          new CustomEvent('grasp-sharing-request', {
            detail: {
              personality_id: personalityId,
              organization_id: organizationId,
            },
          })
        );
    }
  };

  return (
    <>
      {mode === GraspInfoMode.DOCTOR && personalityId && organizationId && (
        <ActionGraspModal
          action={GraspModalActions.SHARE}
          isOpen={isDetailsOpen}
          toggle={toggleShareGraspModal}
          organizationId={organizationId}
          personalityId={personalityId}
        />
      )}
      <div className="GraspInfo card-container">
        <div className="cardContainer__header d-flex flex-column flex-md-row justify-content-between">
          <div className="d-flex flex-wrap align-items-center justify-content-between w-100">
            <div className="cardContainer__header__title">
              <img src={graspSmall} alt="form_icon" />
              {mode === GraspInfoMode.DOCTOR ? (
                <FormattedMessage id="Grasps.doctorHeader" />
              ) : (
                <FormattedMessage id="Grasps.patientHeader" />
              )}
            </div>
            {mode === GraspInfoMode.DOCTOR && (
              <Button
                color="primary"
                onClick={toggleShareGraspModal}
                className="btn-action"
              >
                <FormattedMessage id="General.share" />
              </Button>
            )}
          </div>
        </div>
        {userId && <UserGraspList userId={userId} />}
        {personalityId && (
          <div className="sharedGraspsPatient__list">
            <ul className="d-flex flex-column mt-3 p-0" aria-label="grasp_list">
              <PatientGraspList patientId={personalityId} mode={mode} />
            </ul>
          </div>
        )}
      </div>
    </>
  );
};

export default GraspsInfo;
