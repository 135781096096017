import useRequiredParams from '../../../hooks/useRequiredParams';
import { useInfiniteProjects } from '../../../api/queries/projects/useInfiniteProjects';

export const useProjectsCommonData = () => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const projectsQuery = useInfiniteProjects({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  return {
    projects: projectsQuery,
  };
};
