import { useState } from 'react';
import { StepsHookValues } from '../../../../../../../../../hooks/useSteps';
import { Steps } from '../../../types';

export type HandleUploadHookValues = {
  onUpload: (file: File) => Promise<void>;
  resetUpload: () => void;
  file: string | null;
};

export const useHandleUpload = (
  steps: StepsHookValues<Steps>
): HandleUploadHookValues => {
  const [file, setFile] = useState<string | null>(null);

  const onUpload = async (file: File) => {
    const text = await file.text();
    setFile(text);
    steps.next();
  };

  const resetUpload = () => {
    setFile(null);
    steps.prev();
  };

  return { onUpload, resetUpload, file };
};
