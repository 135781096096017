import React from 'react';
import { useTabs } from './hooks/useTabs';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { useCommonData } from '../../hooks/useCommonData';
import { useInitQuery } from '../../../../hooks/queries/useInitQuery';
import CaseDashboardHeader from './components/CaseDashboardHeader';
import { useCommonParams } from '../../hooks/useCommonParams';
import { useBrowserState } from '../../../../store/reducerHooks';
import classNames from 'classnames';
import SVG from 'react-inlinesvg';
import dumbbellIcon from 'assets/images/dumbbell.svg';
import graspIcon from 'assets/images/grasp_svg_icon.svg';
import { useNavigate } from 'react-router';
import { useSyncRequested } from './hooks/useSyncRequested';
import { useSelectedDateContext } from './hooks/useSelectedDateContext';
import { useQueriesLoading } from '../../../../hooks/queries/useQueriesLoading';
import { SelectedDateContext } from './types';
import { useTriggerManualObservation } from './hooks/useTriggerManualObservation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import SimpleDefaultConversation from './components/SimpleDefaultConversation';

const CaseDashboardTabs = () => {
  const { userId, caseId } = useCommonParams();
  const { userCaseDetails } = useCommonData();

  useInitQuery(userCaseDetails);

  const isLoading = useQueriesLoading([userCaseDetails]);

  const navigate = useNavigate();

  const { CurrentTab, onTabChange, selectedTab } = useTabs();

  const isBrowser = useBrowserState();

  const isSyncRequested = useSyncRequested();

  const selectedDateContext = useSelectedDateContext(userCaseDetails);

  const { handleCreate, isCreating } = useTriggerManualObservation();

  const navigateToExerciseSession = () =>
    navigate(`/users/${userId}/cases/${caseId}/exercise`);

  if (isLoading || !userCaseDetails.data) return <></>;

  return (
    <SelectedDateContext.Provider value={selectedDateContext}>
      <div
        className={classNames('CaseDashboardTabs', {
          'mt-lg-3': isBrowser,
          CaseDashboardTabs__embedded: !isBrowser,
        })}
      >
        {!userCaseDetails.data.dashboard_as_default_conversation ? (
          <>
            <CaseDashboardHeader
              selectedTab={selectedTab}
              onTabChange={onTabChange}
              syncRequested={isSyncRequested}
            />
            <CurrentTab />
          </>
        ) : (
          <SimpleDefaultConversation />
        )}
        {userCaseDetails.data.exercises_enabled ||
          (userCaseDetails.data.triggered_observation_enabled && (
            <div className="CaseDashboardTabs__start-exercising shadow-lg">
              {userCaseDetails.data.exercises_enabled && (
                <Button
                  className="w-75 d-flex align-items-center justify-content-center gap-2"
                  color="primary"
                  onClick={navigateToExerciseSession}
                >
                  <SVG src={dumbbellIcon} />
                  <FormattedMessage id="CaseDashboard.CaseDashboardTabs.startExercising" />
                </Button>
              )}
              {userCaseDetails.data.triggered_observation_enabled && (
                <Button
                  className="w-75 d-flex align-items-center justify-content-center gap-2"
                  color="primary"
                  onClick={handleCreate}
                >
                  {isCreating ? (
                    <FontAwesomeIcon icon={faSpinnerThird} spin={true} />
                  ) : (
                    <SVG
                      className="CaseDashboardTabs__trigger-observation-icon"
                      src={graspIcon}
                    />
                  )}
                  <FormattedMessage id="CaseDashboard.CaseDashboardTabs.triggerObservation" />
                </Button>
              )}
            </div>
          ))}
      </div>
    </SelectedDateContext.Provider>
  );
};

export default CaseDashboardTabs;
