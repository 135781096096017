import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getTrendVisualizationTemplates } from '../../trendVisualizations';
import { OrganizationQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

const useTrendVisualizationTemplates = ({
  params,
  options,
}: IQueryRequest<OrganizationQueryParams>) => {
  const { organizationId } = params;
  return useQuery({
    ...options,
    queryKey: [QueryKeys.getTrendVisualizationTemplates, organizationId],
    queryFn: async () => {
      const { data } = await getTrendVisualizationTemplates(organizationId);
      return data.data;
    },
  });
};

export default useTrendVisualizationTemplates;
