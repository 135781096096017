import { IntlShape, useIntl } from 'react-intl';

export const useTranslations = (pathPrefix: string, outerIntl?: IntlShape) => {
  const innerIntl = useIntl();
  const intl = outerIntl ? outerIntl : innerIntl;

  const attachPrefix = (id: string) => `${pathPrefix}.${id}`;
  const wrapWithIntl = (id: string, values?: any) =>
    intl.formatMessage({ id: id }, values);

  return {
    attachPrefix,
    wrapWithIntl,
  };
};
