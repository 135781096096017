import { ICreateOrganizationDevice } from '../../../interfaces/organizations';
import { OrganizationQueryParams } from '../../../interfaces/queryParams';
import { IMutationRequest } from '../../../interfaces/request';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { createOrganizationDevice } from '../../organization';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

const useCreateOrganizationDevice = ({
  params,
  options,
  silent = false,
}: IMutationRequest<OrganizationQueryParams>) => {
  return useMutationWithResultNotification<ICreateOrganizationDevice, void>({
    asyncApiFunction: async (createDevice) => {
      await createOrganizationDevice(params.organizationId, createDevice);
    },
    onError: () => {
      if (!silent) {
        displayErrorNotification(
          'Api.Mutations.Organizations.CreateOrganizationDevice.failure'
        );
      }
      options.errorFb?.();
    },
    onSuccess: () => {
      if (!silent) {
        displaySuccessNotification(
          'Api.Mutations.Organizations.CreateOrganizationDevice.success'
        );
      }
      options.successFb?.();
    },
    resetOnResult: true,
  });
};

export default useCreateOrganizationDevice;
