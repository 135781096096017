import { deleteCase } from 'api/patients';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { IMutationProps } from '../../../interfaces/ui';

type useCaseDeleteParams = {
  organizationId: string;
  caseId: string;
};

const useCaseDelete = (
  params: useCaseDeleteParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification<void, void>({
    asyncApiFunction: async () => {
      await deleteCase(params.organizationId, params.caseId);
    },
    onError: () => {
      if (options.errorFb) options.errorFb();
      displayErrorNotification('CaseCard.delete.error');
    },
    onSuccess: () => {
      if (options.successFb) options.successFb();
      displaySuccessNotification('CaseCard.delete.success');
    },
    resetOnResult: true,
  });
};

export default useCaseDelete;
