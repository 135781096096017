import { useMemo } from 'react';
import { IAwareSessionData } from 'interfaces/awareSessions';
import { formatDuration, intervalToDuration } from 'date-fns';

export const useDuration = (awareSessionDetails: IAwareSessionData) => {
  return useMemo(() => {
    const timeStart = awareSessionDetails.start_date;
    const timeEnd = awareSessionDetails.end_date;

    return formatDuration(
      intervalToDuration({
        start: new Date(timeStart),
        end: new Date(timeEnd),
      })
    );
  }, [awareSessionDetails.end_date, awareSessionDetails.start_date]);
};
