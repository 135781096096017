import emptyHorizontalBarChartIcon from 'assets/images/empty_horizontal_bar_chart.svg';
import emptyLineChartIcon from 'assets/images/empty_line_chart.svg';

import emptyPieChartIcon from 'assets/images/empty_pie_chart.svg';
import emptyVerticalBarChartIcon from 'assets/images/empty_vertical_bar_chart.svg';
import React from 'react';
import SVG from 'react-inlinesvg';
import { useTypedContext } from '../../../../../../../../../../../../../../hooks/useTypedContext';
import { DashboardTemplateChartType } from '../../../../../../../../../../../../../../interfaces/dashboards';
import {
  DashboardDataReducerContext,
  DashboardTheme,
} from '../../../../../../../../types';

type ChartPlaceholderProps = {
  type: DashboardTemplateChartType;
};

const ChartPlaceholder = ({ type }: ChartPlaceholderProps) => {
  const [dashboardData] = useTypedContext(DashboardDataReducerContext);

  let content: JSX.Element = <></>;

  if (type === DashboardTemplateChartType.PieChart) {
    content = (
      <SVG
        height="80%"
        src={emptyPieChartIcon}
        style={{
          opacity: dashboardData.theme === DashboardTheme.Light ? '1' : '0.6',
        }}
        stroke={'none'}
      />
    );
  }

  if (type === DashboardTemplateChartType.LineChart) {
    content = (
      <SVG
        height="100%"
        src={emptyLineChartIcon}
        style={{
          opacity: dashboardData.theme === DashboardTheme.Light ? '1' : '0.6',
        }}
      />
    );
  }

  if (type === DashboardTemplateChartType.HorizontalBarChart) {
    content = (
      <SVG
        height="100%"
        src={emptyHorizontalBarChartIcon}
        style={{
          opacity: dashboardData.theme === DashboardTheme.Light ? '1' : '0.6',
        }}
      />
    );
  }

  if (type === DashboardTemplateChartType.VerticalBarChart) {
    content = (
      <SVG
        height="100%"
        src={emptyVerticalBarChartIcon}
        style={{
          opacity: dashboardData.theme === DashboardTheme.Light ? '1' : '0.6',
        }}
      />
    );
  }

  return <>{content}</>;
};

export default ChartPlaceholder;
