import useAddTimelineObservation from 'api/mutations/timelines/useAddTimelineObservation';
import useDeleteTimelineObservation from 'api/mutations/timelines/useDeleteTimelineObservation';
import useEditTimelineObservation from 'api/mutations/timelines/useEditTimelineObservation';
import useCaseContextTimelineObservations from 'api/queries/cases/useCaseContextTimelineObservations';

//helpers
import useTimelineSqueezeDataFetch from 'api/queries/timelines/useTimelineSqueezeDataFetch';
import useUserContextTimelineObservations from 'api/queries/timelines/useUserContextTimelineObservations';
import calendarIcon from 'assets/images/calendar_icon.svg';

//images
import bubbles from 'assets/images/timeline_details/bubbles.svg';

//components
import FieldInput from 'components/FieldInput';
import FieldSelect from 'components/FieldSelect';
import InputRange from 'components/InputRange';
import LoadingButton from 'components/LoadingButton';
import TimeInput from 'components/TimeInput';
import { isSameDay } from 'date-fns';
import { Field, Formik } from 'formik';
import { formatObjectToIPatch } from 'helpers/utils/formatData';
import { returnSelectedDateString } from 'helpers/utils/timelineHelpers';
import { meaningOptions } from 'helpers/utils/translationObject';

//helpers
import { validateEmpty } from 'helpers/utils/validators';
import useUserPathBasedBrowsingContext from 'hooks/useUserPathBasedBrowsingContext';

//types
import { IObservation, ObservationSymptom } from 'interfaces/timeline';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import {
  useTimelineDetailsDateRangeObject,
  useTimelineDetailsGranularity,
  useTimelineDetailsSelectedDate,
} from 'store/reducerHooks';

type ObservationModalProps = {
  isOpen: boolean;
  closeModal: () => void;
  caseDedicatedTimelineId?: string;
  timelineId: string;
  refetchDates: {
    from: Date;
    to: Date;
  };
  observationToEdit?: IObservation | null;
};

const predefinedInitialValues = {
  description: '',
  from: '',
  level: 5,
  symptom: 'Other',
};

const ObservationModal = ({
  isOpen,
  closeModal,
  timelineId,
  caseDedicatedTimelineId,
  refetchDates,
  observationToEdit = null,
}: ObservationModalProps) => {
  const intl = useIntl();

  const meaningWithoutCalibration = useMemo(() => {
    return meaningOptions.filter((p) => p.value !== 'Calibration');
  }, []);

  const {
    organizationId = '',
    caseId = '',
    isOrganizationContext,
  } = useUserPathBasedBrowsingContext();

  const isEditMode = !!observationToEdit;

  const selectedDate = useTimelineDetailsSelectedDate();

  let from = '';

  if (isEditMode && observationToEdit) {
    from = observationToEdit.timestamp;
  } else if (selectedDate) {
    const today = new Date();
    const isToday = isSameDay(selectedDate, today);

    from = isToday ? today.toISOString() : selectedDate?.toISOString();
  }

  const initialValues = {
    from,
    description:
      observationToEdit?.description ?? predefinedInitialValues.description,
    level: observationToEdit?.level ?? predefinedInitialValues.level,
    symptom: observationToEdit?.symptom ?? predefinedInitialValues.symptom,
  };

  const dateRangeObject = useTimelineDetailsDateRangeObject();
  const granularity = useTimelineDetailsGranularity();

  const { refetch: refetchTimelineSqueezeData } = useTimelineSqueezeDataFetch({
    params: {
      timelineId: timelineId,
      granularity: granularity,
      dateRangeObject: dateRangeObject,
    },
    options: { enabled: false },
  });

  const { refetch: refetchCaseDedicatedTimelineSqueezeData } =
    useTimelineSqueezeDataFetch({
      params: {
        timelineId: caseDedicatedTimelineId ?? '',
        granularity: granularity,
        dateRangeObject: dateRangeObject,
      },
      options: { enabled: !!caseDedicatedTimelineId },
    });

  const userContextTimelineObservations = useUserContextTimelineObservations({
    params: {
      timelineId,
      selectedDates: refetchDates,
      grouped: true,
    },
    options: { enabled: false },
  });

  const organizationContextTimelineObservations =
    useCaseContextTimelineObservations({
      params: {
        organizationId,
        caseId,
        timelineId,
        selectedDates: refetchDates,
        grouped: true,
      },
      options: { enabled: false },
    });

  const timelineObservationsQuery = isOrganizationContext
    ? organizationContextTimelineObservations
    : userContextTimelineObservations;

  const onModifySuccess = async () => {
    await timelineObservationsQuery.refetch();
    await refetchTimelineSqueezeData();
    if (caseDedicatedTimelineId !== undefined) {
      await refetchCaseDedicatedTimelineSqueezeData();
    }

    closeModal();
  };

  const { mutate: addMutation, isLoading } = useAddTimelineObservation(
    caseDedicatedTimelineId ?? timelineId,
    onModifySuccess
  );

  const { mutate: deleteMutation } = useDeleteTimelineObservation(
    timelineId,
    onModifySuccess
  );

  const { mutate: editMutation } = useEditTimelineObservation(
    timelineId,
    onModifySuccess
  );

  const onSubmit = ({
    from,
    ...restOfValues
  }: typeof predefinedInitialValues) => {
    if (isEditMode && observationToEdit) {
      const objectToPatch: Record<string, string> = {};

      const objectKeys = Object.keys(restOfValues);

      objectKeys.forEach((key) => {
        if (restOfValues[key] !== observationToEdit[key]) {
          objectToPatch[key] = restOfValues[key];
        }
      });

      if (from !== observationToEdit.timestamp) {
        objectToPatch.timestamp = from;
      }

      const patchObjects = formatObjectToIPatch(objectToPatch);

      editMutation({
        observationId: observationToEdit.id,
        patchObjects: patchObjects,
      });
    } else {
      addMutation({
        timestamp: from,
        meaning: restOfValues.symptom,
        ...restOfValues,
      });
    }
  };

  const onDeleteClick = () => {
    observationToEdit && deleteMutation(observationToEdit.id);
  };

  const renderButtons = (submitForm: () => void, isValid: boolean) => {
    if (!isEditMode)
      return (
        <LoadingButton
          isLoading={isLoading}
          className="AnnotationModal__add"
          onClick={submitForm}
          disabled={!isValid}
        >
          <FormattedMessage id="TimelineDetails.ObservationModal.addObservation" />
        </LoadingButton>
      );
    else
      return (
        <div className="d-flex justify-content-center">
          <LoadingButton
            outline
            color="primary"
            type="button"
            className="me-2"
            onClick={onDeleteClick}
          >
            <FormattedMessage id="General.delete" />
          </LoadingButton>
          <LoadingButton
            color="primary"
            type="submit"
            className="me-2"
            disabled={!isValid}
            onClick={submitForm}
          >
            <FormattedMessage id="TimelineDetails.ObservationModal.save" />
          </LoadingButton>
        </div>
      );
  };

  const validation = (values: typeof initialValues) => {
    const validationConstraints = validateEmpty<typeof initialValues>(
      values,
      intl
    );

    if (values.symptom === ObservationSymptom.NotSpecified) {
      validationConstraints.symptom = intl.formatMessage({
        id: 'TimelineDetails.ObservationModal.notSpecifiedMeaningSelected',
      });
    }

    return validationConstraints;
  };

  const modifiedMeaningOptions = isEditMode
    ? meaningWithoutCalibration.filter((p) => p.value !== 'NotSpecified')
    : meaningWithoutCalibration;

  return (
    <Modal
      isOpen={isOpen}
      toggle={closeModal}
      className="AnnotationModal"
      size="lg"
    >
      <ModalHeader toggle={closeModal}>
        <img src={bubbles} alt="observation_icon" className="me-2" />
        {isEditMode ? (
          <FormattedMessage id="TimelineDetails.ObservationModal.editObservation" />
        ) : (
          <FormattedMessage id="TimelineDetails.ObservationModal.addObservation" />
        )}
        <div className="DayDetails__date">
          <img src={calendarIcon} alt="calendar_icon" />
          {returnSelectedDateString(selectedDate)}
        </div>
      </ModalHeader>
      <Formik
        validate={validation}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {({ submitForm, isValid, values, setValues }) => (
          <>
            <ModalBody>
              <Form>
                <FormGroup>
                  <label>
                    <FormattedMessage id="TimelineDetails.AnnotationModal.dateAndTime" />
                  </label>
                  <Field
                    required
                    aria-label="from"
                    name="from"
                    type="date"
                    component={TimeInput}
                  />
                </FormGroup>
                <FormGroup>
                  <label>
                    <FormattedMessage id="TimelineDetails.AnnotationModal.meaning" />
                  </label>
                  <Field
                    required
                    aria-label="symptom"
                    name="symptom"
                    type="select"
                    options={modifiedMeaningOptions}
                    displayEmpty={true}
                    component={FieldSelect}
                  />
                </FormGroup>
                <FormGroup>
                  <label>
                    <FormattedMessage id="TimelineDetails.ObservationModal.symptomLevel" />
                  </label>
                  <div className="ObservationModal__range">
                    <InputRange
                      showSentimentScale={false}
                      legendDescriptions={[]}
                      step={1}
                      max={10}
                      min={0}
                      value={values.level}
                      onChange={(value) => {
                        setValues({
                          ...values,
                          level: value,
                        });
                      }}
                    />
                  </div>
                </FormGroup>
                <FormGroup>
                  <label htmlFor="description">
                    <FormattedMessage id="TimelineDetails.AnnotationModal.description" />
                  </label>
                  <Field
                    required
                    id="description"
                    name="description"
                    type="textarea"
                    rows={5}
                    placeholder={intl.formatMessage({
                      id: 'TimelineDetails.AnnotationModal.descriptionPlaceholder',
                    })}
                    component={FieldInput}
                  />
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>{renderButtons(submitForm, isValid)}</ModalFooter>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default ObservationModal;
