import React, { useCallback } from 'react';
import { ApplicableInputHookValues } from '../../hooks/useApplicableInput';
import EditableInput, {
  EditableInputInputProps,
} from '../../../EditableInput/EditableInput';

type ControlledApplicableInputProps = {
  control: ApplicableInputHookValues;
} & EditableInputInputProps;

const ControlledApplicableInput = ({
  control,
  ...input
}: ControlledApplicableInputProps) => {
  const handleEdit = useCallback(
    (target: string, value: string) => {
      control.onChange(target, value);
      input?.onEdit?.(target, value);
    },
    [control, input]
  );

  return (
    <EditableInput
      value={control.value}
      {...input}
      onEdit={handleEdit}
      debounceEnabled={false}
    />
  );
};

export default ControlledApplicableInput;
