import { getCaseContextTimelineObservations } from 'api/graspManagement';
import { addDays, eachDayOfInterval } from 'date-fns';
import QueryKeys from 'api/queries/queryKeys';
import { IQueryRequest, ODataParamType } from 'interfaces/request';
import { useMemo } from 'react';
import { useQuery } from 'react-query';
import {
  GetTimelineObservationsQueryParams,
  Granularity,
} from 'interfaces/timeline';
import {
  CaseQueryParams,
  TimelineRangeQueryParams,
} from 'interfaces/queryParams';

interface CaseContextTimelineObservationsParams
  extends CaseQueryParams,
    TimelineRangeQueryParams {
  grouped: boolean;
  granularity?: Granularity;
}

const useCaseContextTimelineObservations = ({
  params,
  options,
}: IQueryRequest<CaseContextTimelineObservationsParams>) => {
  const {
    organizationId,
    caseId,
    timelineId,
    selectedDates,
    grouped = true,
    granularity,
  } = params;

  const dateRange = useMemo(() => {
    if (!selectedDates) return null;

    const start = new Date(selectedDates.from);
    start.setHours(0, 0, 0, 0);

    const endWithExtraDay = addDays(new Date(selectedDates.to), 1);
    endWithExtraDay.setHours(0, 0, 0, 0);

    return eachDayOfInterval({
      start,
      end: endWithExtraDay,
    });
  }, [selectedDates]);

  const dateRangeObject = selectedDates
    ? {
        from: selectedDates.from.toISOString(),
        to: selectedDates.to.toISOString(),
      }
    : null;

  const response = useQuery({
    ...options,
    queryFn: async () => {
      let oDataQueryParams: ODataParamType[] = [];

      if (dateRangeObject) {
        oDataQueryParams = [
          {
            key: 'Timestamp',
            operator: 'ge',
            value: dateRangeObject.from,
          },
          {
            key: 'Timestamp',
            operator: 'lt',
            value: dateRangeObject.to,
          },
        ];
      }

      const queryParams: GetTimelineObservationsQueryParams = {
        grouped: grouped,
        granularity,
      };

      const { data } = await getCaseContextTimelineObservations(
        organizationId,
        caseId,
        timelineId,
        queryParams,
        oDataQueryParams
      );

      return data.data;
    },
    queryKey: [
      QueryKeys.getTimelineObservations,
      timelineId,
      dateRangeObject?.from.split('.')[0].slice(0, -2) + '00Z',
      dateRangeObject?.to.split('.')[0].slice(0, -2) + '00Z',
      grouped,
    ],
  });

  //-1 because index start at 0;
  const numberOfDaysRecords = dateRange ? dateRange.length - 1 : undefined;
  return { dateRange, numberOfDaysRecords, ...response };
};

export default useCaseContextTimelineObservations;
