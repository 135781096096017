import { isValid } from 'date-fns';
import useOpen from 'hooks/useOpen';
import { useEffect, useMemo, useState } from 'react';
import { Navigate, useParams } from 'react-router';
import { AssessmentCompletionStatus } from 'interfaces/assessmentForms';
import TimelineDetailsAssessmentProxyModal from './TimelineDetailsAssessmentProxyModal.tsx';
import { getUserId } from 'helpers/utils/auth';
import useUserContextAssessmentFormsForDate from 'api/queries/assessmentForms/forms/date/useUserContextAssessmentFormsForDate';

type TimelineDetailsAssessmentProxyPathParams = {
  timelineId?: string;
  year?: string;
  month?: string;
  day?: string;
  sequence?: string;
};

export enum TimelineDetailsAssessmentFormsErrors {
  AlreadyCompleted = 'AlreadyCompleted',
  FormNotFound = 'FormNotFound',
  InvalidDateParams = 'InvalidDateParams',
}

const TimelineDetailsAssessmentProxy = () => {
  const { timelineId, year, month, day, sequence } =
    useParams<TimelineDetailsAssessmentProxyPathParams>();
  const loggedUserId = getUserId();

  const { isOpen, toggle: toggleModal, open: openModal } = useOpen();

  const [validationCompleted, setValidationCompleted] = useState(false);

  const selectedDate = useMemo(
    () => new Date(parseInt(year!), parseInt(month!) - 1, parseInt(day!)),
    [year, month, day]
  );

  const [assessmentFormError, setAssessmentFormError] =
    useState<TimelineDetailsAssessmentFormsErrors | null>(null);

  useEffect(() => {
    if (!isValid(selectedDate)) {
      setAssessmentFormError(
        TimelineDetailsAssessmentFormsErrors.InvalidDateParams
      );
    }
  }, [selectedDate]);

  const { data, isFetched } = useUserContextAssessmentFormsForDate({
    params: {
      selectedDate,
      userId: loggedUserId,
      timelineId,
    },
  });

  useEffect(() => {
    if (isFetched) {
      if (!data || data.length === 0) {
        setAssessmentFormError(
          TimelineDetailsAssessmentFormsErrors.FormNotFound
        );
        return;
      }

      const selectedForm = data.find((p) => p.sequence === parseInt(sequence!));

      if (!selectedForm) {
        setAssessmentFormError(
          TimelineDetailsAssessmentFormsErrors.FormNotFound
        );
        return;
      }

      if (
        selectedForm.completionStatus === AssessmentCompletionStatus.COMPLETED
      ) {
        setAssessmentFormError(
          TimelineDetailsAssessmentFormsErrors.AlreadyCompleted
        );
        return;
      }

      setValidationCompleted(true);
    }
  }, [data, isFetched, sequence]);

  useEffect(() => {
    if (assessmentFormError) {
      openModal();
    }
  }, [assessmentFormError, openModal]);

  return (
    <>
      {assessmentFormError ? (
        <TimelineDetailsAssessmentProxyModal
          isOpen={isOpen}
          toggleModal={toggleModal}
          errorType={assessmentFormError}
          timelineId={timelineId!}
        />
      ) : (
        <>
          {validationCompleted && (
            <Navigate
              state={{
                assessmentForm: {
                  selectedDate: selectedDate,
                  sequence: parseInt(sequence!),
                },
              }}
              to={`/timelines/${timelineId}`}
            />
          )}
        </>
      )}
    </>
  );
};

export default TimelineDetailsAssessmentProxy;
