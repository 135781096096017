import React from 'react';
import { ITabContainerItem } from '../../types/Tabs';
import { useTabContainer } from '../../hooks/useTabContainer';
import { useTabHeaders } from 'components/Tabs/hooks/useTabHeaders';
import { useTabPanels } from 'components/Tabs/hooks/useTabPanels';
import StyledTabs from 'components/Tabs/components/VerticalTabContainer/components/StyledTabs';
import { Box } from '@mui/material';

type VerticalTabContainerProps = {
  items: ITabContainerItem[];
  onTabChange?: (index: number) => void;
};

const VerticalTabContainer = ({
  onTabChange,
  items,
}: VerticalTabContainerProps) => {
  const { activeTab, toggle, defaultSelectedItems } =
    useTabContainer<ITabContainerItem>({
      items,
      onTabChange,
    });

  const tabHeaders = useTabHeaders(defaultSelectedItems);
  const tabPanels = useTabPanels(defaultSelectedItems);

  const handleChange = (_: React.SyntheticEvent, newValue: number) =>
    toggle(newValue);

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
      }}
    >
      <StyledTabs
        orientation="vertical"
        variant="standard"
        value={activeTab}
        onChange={handleChange}
      >
        {tabHeaders}
      </StyledTabs>
      {tabPanels}
    </Box>
  );
};

export default VerticalTabContainer;
