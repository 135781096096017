import { useCallback } from 'react';
import { useTypedContext } from '../../../../../../../../../hooks/useTypedContext';
import { DashboardDatePeriod } from '../../../../../../../../../interfaces/dashboards';
import { DashboardDataReducerContext } from '../../../types';
import { DashboardDataActions } from '../../DashboardDataContent/store/actions';

export const useHandleChangeDatePeriod = () => {
  const [, dispatch] = useTypedContext(DashboardDataReducerContext);

  const _changePeriod = useCallback(
    (period: DashboardDatePeriod) => {
      dispatch({
        type: DashboardDataActions.DashboardDataActionType.EditDatePeriod,
        payload: period,
      });
    },
    [dispatch]
  );

  return useCallback(
    (_: string, value: string) => {
      _changePeriod(value as DashboardDatePeriod);
    },
    [_changePeriod]
  );
};
