import React from 'react';
import { Input, InputGroup, InputGroupText } from 'reactstrap';
import { useIntl } from 'react-intl';

//images
import filterIcon from 'assets/images/filter_icon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

export type FilterDisplayProps = {
  filterString: string;
  filtersLength: number;
  displayOnly?: boolean;
  inputClassName?: string;
  targetId: string;
};

const FilterDisplay = ({
  filterString,
  filtersLength = 0,
  displayOnly = false,
  inputClassName,
  targetId,
}: FilterDisplayProps) => {
  const intl = useIntl();

  return (
    <InputGroup className="FilterInput__display" id={targetId}>
      {!displayOnly && (
        <InputGroupText>
          <img src={filterIcon} alt="filter-icon" />
        </InputGroupText>
      )}
      <Input
        className={`${inputClassName ?? ''}`}
        disabled
        value={
          filterString.length > 0
            ? filterString
            : intl.formatMessage({
                id: 'PopoverFilter.emptySelection',
              })
        }
      />
      <InputGroupText className="p-0">
        {filtersLength > 0 && (
          <div className="FilterInput__display__filter-number">
            {filtersLength}
          </div>
        )}
      </InputGroupText>

      {!displayOnly && (
        <InputGroupText>
          <FontAwesomeIcon icon={faCaretDown} />
        </InputGroupText>
      )}
    </InputGroup>
  );
};

export default FilterDisplay;
