import React from 'react';
import { ICaseProcedureWithActivitiesLocalized } from '../../../../../../../../../../interfaces/cases';
import { FormikProps, getIn } from 'formik';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import TextField from '@mui/material/TextField';
import { AwareCaseDetailsFormValues } from '../../../../forms/AwareCaseDetailsForm/types';
import { FormattedMessage, useIntl } from 'react-intl';
import TextMaskInput from '../../../../../../../../../../components/TextMaskInput';
import { useProcedureDisabled } from './hooks/useProcedureDisabled';
import Button from '@mui/material/Button';
import { useHandleSave } from './hooks/useHandleSave';
import { FormHelperText } from '@mui/material';
import { useSaveError } from './hooks/useSaveError';
import { useTimeStart } from './hooks/useTimeStart';
import { useTimeEnd } from './hooks/useTimeEnd';
import { useProcedureType } from './hooks/useProcedureType';
import useCultures from '../../../../../../../../../../hooks/cultures/useCultures';
import { cloneDeep } from 'lodash';

type ProcedureRelatedFieldsProps = {
  procedures: ICaseProcedureWithActivitiesLocalized[];
  form: FormikProps<AwareCaseDetailsFormValues>;
  procedureIndex: number;
  toggle: () => void;
};

const ProcedureRelatedFields = ({
  procedures,
  procedureIndex,
  form,
  toggle,
}: ProcedureRelatedFieldsProps) => {
  const { currentCulture, defaultCulture } = useCultures();
  const intl = useIntl();

  const { error, handleSetError, handleReset } = useSaveError();

  const { timeStart, handleStartChange } = useTimeStart(
    procedureIndex,
    form,
    handleReset
  );

  const { timeEnd, handleEndChange } = useTimeEnd(
    procedureIndex,
    form,
    handleReset
  );

  const handleProcedureType = useProcedureType(
    form,
    procedureIndex,
    procedures
  );

  const handleSave = useHandleSave(
    timeStart,
    timeEnd,
    form,
    procedureIndex,
    handleSetError,
    toggle
  );

  const handleDelete = () => {
    handleReset();

    let selectedProcedure = cloneDeep(
      form.values.procedures.at(procedureIndex)
    );

    if (!selectedProcedure) return handleSetError('Cases.ProcedureNotFound');

    form.setFieldValue(
      'procedures',
      form.values.procedures.filter((_, idx) => idx !== procedureIndex)
    );

    toggle();
  };

  const disabled = useProcedureDisabled(form, procedureIndex);

  return (
    <div className="flex flex-col gap-4">
      <TextField
        className="mt-3"
        variant="standard"
        disabled={disabled}
        fullWidth
        select={true}
        id={`procedures[${procedureIndex}].id`}
        name={`procedures[${procedureIndex}].id`}
        value={form.values.procedures.at(procedureIndex)?.id ?? ''}
        onChange={handleProcedureType}
        onBlur={form.handleBlur}
        error={
          getIn(form.touched, `procedures[${procedureIndex}].id`) &&
          Boolean(getIn(form.errors, `procedures[${procedureIndex}].id`))
        }
        helperText={
          getIn(form.touched, `procedures[${procedureIndex}].id`) &&
          getIn(form.errors, `procedures[${procedureIndex}].id`)
        }
      >
        {procedures.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {currentCulture.cultureCode === defaultCulture.cultureCode
              ? option.name_en
              : option.name_no}
          </MenuItem>
        ))}
      </TextField>
      <div className="grid grid-cols-2 grid-rows-1 gap-2">
        <TextField
          variant="standard"
          type="text"
          id={`procedures[${procedureIndex}].timeStart`}
          name={`procedures[${procedureIndex}].timeStart`}
          placeholder={intl.formatMessage({
            id: 'General.TimeStart',
          })}
          value={timeStart}
          onChange={handleStartChange}
          InputProps={{
            inputComponent: TextMaskInput as any,
          }}
        />
        <TextField
          variant="standard"
          type="text"
          id={`procedures[${procedureIndex}].timeEnd`}
          name={`procedures[${procedureIndex}].timeEnd`}
          placeholder={intl.formatMessage({
            id: 'General.TimeEnd',
          })}
          value={timeEnd}
          onChange={handleEndChange}
          InputProps={{
            inputComponent: TextMaskInput as any,
          }}
        />
      </div>
      {error && (
        <FormHelperText error>
          <FormattedMessage id={error} />
        </FormHelperText>
      )}
      <div className="flex items-center w-full gap-3">
        <Button
          onClick={handleDelete}
          sx={{ minWidth: 120 }}
          variant="outlined"
          color="primary"
        >
          <FormattedMessage id="General.Delete" />
        </Button>
        <Button
          onClick={handleSave}
          sx={{ minWidth: 120 }}
          variant="outlined"
          color="primary"
        >
          <FormattedMessage id="General.Save" />
        </Button>
      </div>
    </div>
  );
};

export default ProcedureRelatedFields;
