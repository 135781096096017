import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import useOpen from '../../../../../../hooks/useOpen';
import useRequiredParams from '../../../../../../hooks/useRequiredParams';
import { useCaseGroupsCommonData } from '../../../../hooks/useCaseGroupsCommonData';
import useCaseEdit from '../../../../../../api/mutations/cases/useCaseEdit';
import CaseGroupCollectionItem, {
  CaseGroupCollectionItemAction,
} from '../../../../../../components/CaseGroupCollectionItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchange, faUnlink } from '@fortawesome/pro-light-svg-icons';
import MoveCaseModal from '../../../../modals/MoveCaseModal';
import SVG from 'react-inlinesvg';
import caseIcon from '../../../../../../assets/images/case_icon.svg';
import { ICase } from '../../../../../../interfaces/cases';

type CaseGroupsUnassignedCasesItemProps = {
  caseDetails: ICase;
  groupId: string | null;
};

const CaseGroupsUnassignedCasesItem = ({
  groupId,
  caseDetails,
}: CaseGroupsUnassignedCasesItemProps) => {
  const intl = useIntl();

  const { isOpen, toggle } = useOpen();

  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const { ungroupedCasesCaseGroup, caseGroups } = useCaseGroupsCommonData();

  const handleRefetch = async () => {
    await ungroupedCasesCaseGroup.refetch();
    await caseGroups.refetch();
  };

  const { mutateAsync: patchCase } = useCaseEdit(
    {
      organizationId: organizationId,
      caseId: caseDetails.id,
    },
    {
      successFb: handleRefetch,
    }
  );

  const caseInfo = useMemo(() => {
    const statusMessage = intl.formatMessage({
      id: 'CaseGroupItem.caseStatus',
    });

    const typeMessage = intl.formatMessage({
      id: 'CaseGroupItem.caseType',
    });

    return `${typeMessage}${caseDetails.type} | ${statusMessage}${caseDetails.status}`;
  }, [caseDetails.status, caseDetails.type, intl]);

  const handleUnlinkCase = useCallback(async () => {
    if (groupId === null) return;

    await patchCase([
      {
        op: 'replace',
        path: '/group_id',
        value: null,
      },
    ]);
  }, [groupId, patchCase]);

  const collectionActions = useMemo(() => {
    let actions: CaseGroupCollectionItemAction[] = [];

    if (groupId !== null)
      actions.push({
        key: 'CaseGroupCaseItem_handleUnlinkCase',
        icon: <FontAwesomeIcon icon={faUnlink} />,
        onClick: handleUnlinkCase,
      });

    actions.push({
      key: 'CaseGroupCaseItem_toggle',
      icon: <FontAwesomeIcon icon={faExchange} />,
      onClick: toggle,
    });

    return actions;
  }, [groupId, handleUnlinkCase, toggle]);

  return (
    <>
      {isOpen && (
        <MoveCaseModal isOpen={isOpen} toggle={toggle} cases={[caseDetails]} />
      )}
      <CaseGroupCollectionItem
        title={caseDetails.title}
        titleUrl={`/organizations/${organizationId}/cases/${caseDetails.id}`}
        icon={<SVG src={caseIcon} title={caseDetails.title} />}
        info={caseInfo}
        actions={collectionActions}
      />
    </>
  );
};

export default CaseGroupsUnassignedCasesItem;
