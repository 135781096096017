import { CurveType } from 'recharts/types/shape/Curve';
import {
  GraspColors,
  graspColorsValues,
  hexToRgba,
} from '../../../../../../../helpers/colors';
import React from 'react';
import { Line } from 'recharts';

type LineChartFigureItemProps = {
  lineKey: string;
  dataKey: string;
  width?: number;
  type?: CurveType;
  connectNulls?: boolean;
  stroke?: string;
  hide?: boolean;
  dot?: boolean;
};

export const getLineChartFigureItem = ({
  lineKey,
  dataKey,
  width = 3,
  type = 'monotone',
  connectNulls = true,
  stroke = hexToRgba(graspColorsValues[GraspColors.BLUE]),
  dot = true,
  hide = false,
}: LineChartFigureItemProps) => {
  return [
    <Line
      key={lineKey}
      dataKey={dataKey}
      strokeWidth={width}
      dot={dot}
      type={type}
      connectNulls={connectNulls}
      stroke={stroke}
      hide={hide}
    />,
  ];
};
