import { IAwareSessionData } from '../../../../../../../../../../../interfaces/awareSessions';
import { useCallback, useState } from 'react';
import { useOrganizationPathParams } from '../../../../../../../../../../../hooks/paths/useOrganizationPathParams';
import useDeleteAwareSessionData from '../../../../../../../../../../../api/mutations/awareSessions/useDeleteAwareSessionData';
import useAwareSessionsForPatientNumber from '../../../../../../../../../../../api/queries/awareSessions/useAwareSessionsForPatientNumber';
import useUrlSearch from '../../../../../../../../../../../hooks/useUrlSearch';

export const useSessionDelete = (refetch: () => void) => {
  const { organizationId } = useOrganizationPathParams();

  const query = useUrlSearch();
  const aggregated = query.get('aggregated');

  const [existingSession, setExistingSession] =
    useState<IAwareSessionData | null>(null);

  const { refetch: patientNumberQueryRefetch } =
    useAwareSessionsForPatientNumber({
      params: {
        organizationId,
        patientNumber: existingSession?.patient_number ?? '',
        aggregated: !!aggregated,
      },
      options: { enabled: false, staleTime: Infinity },
    });

  const handleSuccess = async () => {
    refetch();
    if (!existingSession?.patient_number) return;
    await patientNumberQueryRefetch();
  };

  const { mutateAsync } = useDeleteAwareSessionData({
    params: { organizationId },
    options: { successFb: handleSuccess },
  });

  return useCallback(
    (session: IAwareSessionData) => async () => {
      setExistingSession(session);
      await mutateAsync(session.case_id);
    },
    [mutateAsync]
  );
};
