type TranslationObjects = {
  value: string;
  translationId: string;
};

export const generateTranslationIdWithValues = (
  preKeyText: string,
  values: string[]
): TranslationObjects[] =>
  values.map((value) => ({
    value,
    translationId: `${preKeyText}.${value}`,
  }));
