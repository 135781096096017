import React from 'react';
import { IAwareSessionData } from '../../../../../../../../../../interfaces/awareSessions';
import { useQuestionColumns } from './hooks/useQuestionColumns';
import { differenceInSeconds } from 'date-fns';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { formatDate } from '../../../../../../../../../../helpers/dates';
import { FormattedMessage } from 'react-intl';
import SVG from 'react-inlinesvg';
import graspPlayIcon from '../../../../../../../../../../assets/images/grasp-play.svg';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useTheme } from '@mui/material';
import { useSessionStart } from './hooks/useSessionStart';
import { useSessionDelete } from './hooks/useSessionDelete';
import { useSessionNavigate } from './hooks/useSessionNavigate';
import { useSessionChildren } from './hooks/useSessionChildren';

type AwareCasesRowProps = {
  session: IAwareSessionData;
  onSelect: () => void;
  selected: boolean;
  refetch: () => void;
};

const AwareCasesRow = ({
  session,
  onSelect,
  selected,
  refetch,
}: AwareCasesRowProps) => {
  const theme = useTheme();
  const questionColumns = useQuestionColumns();

  const sessionDuration = (startDate: string, endDate: string) =>
    differenceInSeconds(new Date(startDate), new Date(endDate));

  const onDelete = useSessionDelete(refetch);

  const toggleable = !!session.patient_number;

  const handleStart = useSessionStart();

  const handleNavigate = useSessionNavigate();

  const rowChildren = useSessionChildren(session, selected);

  const arrowIcon = selected ? (
    <KeyboardArrowDownIcon />
  ) : (
    <KeyboardArrowRightIcon />
  );

  const handleRow = () => {
    if (!toggleable) return;
    onSelect();
  };

  return (
    <>
      <tr
        onClick={handleRow}
        key={`${session.case_id}`}
        className="cursor-pointer"
      >
        <td>
          <div className="flex items-center gap-1">
            {toggleable ? arrowIcon : <></>}
            <span>{formatDate(session.start_date)}</span>
          </div>
        </td>
        <td>
          {session.patient_number ?? (
            <FormattedMessage id="General.NotSpecified" />
          )}
        </td>
        <>{questionColumns(session)}</>
        <td>
          <div
            style={{ color: theme.palette.text.primary }}
            className="flex items-center gap-2"
          >
            {sessionDuration(session.start_date, session.end_date) === 0 ? (
              <>
                <SVG
                  style={{ fill: theme.palette.text.primary }}
                  src={graspPlayIcon}
                  onClick={handleStart(session)}
                />
                <DeleteIcon onClick={onDelete(session)} />
              </>
            ) : (
              <VisibilityIcon onClick={handleNavigate(session)} />
            )}
          </div>
        </td>
      </tr>
      {rowChildren &&
        selected &&
        rowChildren.map((child) => (
          <tr key={child.case_id}>
            <td className="!pl-9">
              <div className="flex items-center gap-1">
                <span>{formatDate(child.start_date)}</span>
              </div>
            </td>
            <td>
              {child.patient_number ?? (
                <FormattedMessage id="General.NotSpecified" />
              )}
            </td>
            <>{questionColumns(child)}</>
            <td>
              <div
                style={{ color: theme.palette.text.primary, fontSize: '1rem' }}
                className="flex items-center gap-2"
              >
                {sessionDuration(child.start_date, child.end_date) === 0 ? (
                  <>
                    <SVG
                      style={{ fill: theme.palette.text.primary }}
                      src={graspPlayIcon}
                      onClick={handleStart(child)}
                    />
                    <DeleteIcon onClick={onDelete(child)} />
                  </>
                ) : (
                  <VisibilityIcon onClick={handleNavigate(child)} />
                )}
              </div>
            </td>
          </tr>
        ))}
    </>
  );
};

export default AwareCasesRow;
