import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrows } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { ZoomModeConfig } from '../AssessmentFormChart/AssessmentFormChart';
import {
  alignDateToClosestForSecondsGranularity,
  getDifferenceOfTimestamps,
  returnIntervalBaseOnDates,
} from '../../../../../../../helpers/dates';
import { DateRange } from 'react-day-picker';
import { addHours, addSeconds, format } from 'date-fns';
import { AssessmentQuestionHistoryEntryItem } from 'interfaces/cases';
import AssessmentFormChartLegend from '../AssessmentFormChart/AssessmentFormChartLegend';
import { MAXIMUM_DIFFERENCE_TO_DISPLAY_HOURS } from '../../../../../../../hooks/useXAxisFormatting';
import { TemplateElement } from '../../../../../../../interfaces/change';
import { TrendVisualizationChartType } from '../../../../../../../interfaces/trendVisualizations';
import AssessmentFormChartTooltip from '../AssessmentFormChart/AssessmentFormChartTooltip';
import {
  ConversationQuestion,
  RangeValidConversationQuestion,
  ValidConversationQuestionType,
} from '../../../../../../../interfaces/conversations';

const initialZoomModeConfig: ZoomModeConfig = {
  left: undefined,
  right: undefined,
  refAreaLeft: undefined,
  refAreaRight: undefined,
  animation: true,
  leftClosestIndex: undefined,
  rightClosestIndex: undefined,
};

type QuestionsChartProps = {
  dateRange: DateRange | null;
  questions: AssessmentQuestionHistoryEntryItem[];
  elements: TemplateElement[];
  conversationQuestions: ConversationQuestion[];
};

const QuestionsChart = ({
  dateRange,
  questions,
  elements,
  conversationQuestions,
}: QuestionsChartProps) => {
  const [displayedQuestions, setDisplayedQuestions] = useState<string[]>([]);

  const [zoomModeConfig, setZoomModeConfig] = useState<ZoomModeConfig>(
    initialZoomModeConfig
  );

  const zoom = () => {
    if (
      zoomModeConfig.refAreaLeft !== undefined &&
      zoomModeConfig.refAreaRight !== undefined
    ) {
      let leftIndex = formattedChartData.findIndex(
        (p) => p.name === zoomModeConfig.refAreaLeft
      );

      let rightIndex = formattedChartData.findIndex(
        (p) => p.name === zoomModeConfig.refAreaRight
      );

      if (rightIndex < leftIndex) {
        const temp = rightIndex;
        rightIndex = leftIndex;
        leftIndex = temp;
      }

      if (leftIndex !== -1 && rightIndex !== -1 && leftIndex !== rightIndex) {
        const interval = returnIntervalBaseOnDates(
          formattedChartData[leftIndex].name,
          formattedChartData[rightIndex].name
        );

        const leftApproximatedDate = alignDateToClosestForSecondsGranularity(
          interval,
          formattedChartData[leftIndex].name
        );
        const rightApproximatedDate = alignDateToClosestForSecondsGranularity(
          interval,
          formattedChartData[rightIndex].name,
          true
        );

        setZoomModeConfig((prev) => ({
          ...prev,
          leftClosestIndex: leftIndex,
          rightClosestIndex: rightIndex,
          left: leftApproximatedDate.getTime(),
          right: rightApproximatedDate.getTime(),
          refAreaLeft: undefined,
          refAreaRight: undefined,
        }));
      }
    }
  };

  const handleMouseDown = (e: any) => {
    if (e && e.activeLabel) {
      setZoomModeConfig((prev) => ({
        ...prev,
        refAreaLeft: e.activeLabel,
      }));
    }
  };

  const handleMouseMove = (e: any) => {
    if (zoomModeConfig.refAreaLeft && e && e.activeLabel) {
      setZoomModeConfig((prev) => ({
        ...prev,
        refAreaRight: e.activeLabel,
      }));
    }
  };

  const handleResetZoom = () => {
    setZoomModeConfig((prev) => ({
      ...prev,
      left: undefined,
      right: undefined,
      refAreaLeft: undefined,
      refAreaRight: undefined,
      leftClosestIndex: undefined,
      rightClosestIndex: undefined,
    }));
  };

  useEffect(() => {
    setDisplayedQuestions(questions.map((q) => q.questionId));
  }, [questions]);

  const selectedConversationQuestions = useMemo(() => {
    const questionIds = questions.map((p) => p.questionId);

    return conversationQuestions.filter(
      (p) =>
        questionIds.includes(p.resource_id) &&
        p.content.type === ValidConversationQuestionType.Range
    );
  }, [conversationQuestions, questions]);

  const dateRangeInterval = useMemo(() => {
    if (dateRange && dateRange.from && dateRange.to) {
      return returnIntervalBaseOnDates(
        dateRange.from.getTime(),
        dateRange.to.getTime()
      );
    }

    return 3600;
  }, [dateRange]);

  const chartDomain = useMemo(() => {
    let domainArray: any[] = [];

    if (zoomModeConfig.left) {
      domainArray.push(zoomModeConfig.left);
    } else {
      if (dateRange && dateRange.from) {
        domainArray.push(dateRange.from.getTime());
      } else {
        domainArray.push('dataMin');
      }
    }

    if (zoomModeConfig.right) {
      domainArray.push(zoomModeConfig.right);
    } else {
      if (dateRange && dateRange.to) {
        domainArray.push(dateRange.to.getTime());
      } else {
        domainArray.push('dataMax');
      }
    }

    return domainArray;
  }, [dateRange, zoomModeConfig.left, zoomModeConfig.right]);

  const hoursRange = useMemo(() => {
    if (
      zoomModeConfig.left !== undefined &&
      zoomModeConfig.right !== undefined
    ) {
      return [zoomModeConfig.left, zoomModeConfig.right];
    }

    return [];
  }, [zoomModeConfig.left, zoomModeConfig.right]);

  const secondsInterval = useMemo(() => {
    if (hoursRange && hoursRange.length === 2) {
      return returnIntervalBaseOnDates(hoursRange[0], hoursRange[1]);
    }

    return 7200;
  }, [hoursRange]);

  const fillSeries = useCallback(
    (startDate: Date, endDate: Date, series: any[]) => {
      while (addSeconds(startDate, dateRangeInterval) <= endDate) {
        series.push({
          name: new Date(startDate).getTime(),
          '': '',
        });
        startDate = addSeconds(startDate, dateRangeInterval);
      }

      return series;
    },
    [dateRangeInterval]
  );

  const ticks = useMemo(() => {
    let ticksArray: number[] = [];

    if (zoomModeConfig && zoomModeConfig.left && zoomModeConfig.right) {
      let startDate = new Date();
      startDate.setTime(zoomModeConfig.left);

      const endDate = new Date();
      endDate.setTime(zoomModeConfig.right);

      while (addSeconds(startDate, secondsInterval) <= endDate) {
        ticksArray.push(startDate.getTime());
        startDate = addSeconds(startDate, secondsInterval);
      }

      ticksArray.push(endDate.getTime());

      return ticksArray;
    }

    if (dateRange && dateRange.from && dateRange.to) {
      const interval = returnIntervalBaseOnDates(
        dateRange.from.getTime(),
        dateRange.to.getTime()
      );
      let startDate = alignDateToClosestForSecondsGranularity(
        interval,
        dateRange.from.getTime()
      );
      let endDate = alignDateToClosestForSecondsGranularity(
        interval,
        dateRange.to.getTime(),
        true
      );

      while (addSeconds(startDate, interval) <= endDate) {
        ticksArray.push(startDate.getTime());
        startDate = addSeconds(startDate, interval);
      }

      ticksArray.push(addHours(endDate, -1).getTime());
    }

    return ticksArray;
  }, [dateRange, secondsInterval, zoomModeConfig]);

  const formattedChartData = useMemo(() => {
    let series: any[] = [];

    if (dateRange && dateRange.from && dateRange.to) {
      let startDate = alignDateToClosestForSecondsGranularity(
        dateRangeInterval,
        dateRange.from.getTime()
      );
      let endDate = alignDateToClosestForSecondsGranularity(
        dateRangeInterval,
        dateRange.to.getTime(),
        true
      );

      series = fillSeries(startDate, endDate, series);
    }

    questions
      .filter((p) => displayedQuestions.includes(p.questionId))
      .forEach((question) => {
        question.answerHistory.forEach((answer) => {
          let inverted = false;

          const info = selectedConversationQuestions.find(
            (p) =>
              p.resource_id === question.questionId &&
              p.content.type === ValidConversationQuestionType.Range
          );

          const questionContent = info?.content as
            | RangeValidConversationQuestion
            | undefined;

          if (
            questionContent?.max_number_value !== undefined &&
            questionContent?.min_number_value !== undefined &&
            questionContent.max_number_value < questionContent.min_number_value
          )
            inverted = true;

          if (answer.value) {
            series.push({
              name: new Date(answer.date).getTime(),
              [question.questionTitle]:
                inverted && info?.content
                  ? (questionContent?.min_number_value ?? 0) - answer.value
                  : answer.value,
            });
          }
        });
      });

    return series.sort((a, b) => a.name - b.name);
  }, [
    dateRange,
    dateRangeInterval,
    displayedQuestions,
    fillSeries,
    questions,
    selectedConversationQuestions,
  ]);

  const differenceInDays = getDifferenceOfTimestamps(ticks);

  const xAxisTickFormatter = (value: any) => {
    if (differenceInDays > MAXIMUM_DIFFERENCE_TO_DISPLAY_HOURS) {
      return ` ${format(value, 'dd.MM')} `;
    }
    return ` ${format(value, 'dd.MM HH:mm')} `;
  };

  const renderQuestionsTooltip = (props: TooltipProps<string, string>) => {
    const payload = props.payload;
    let question: AssessmentQuestionHistoryEntryItem | undefined = undefined;

    if (payload !== undefined && payload !== null && payload.length > 0)
      question = questions.find((p) => p.questionTitle === payload[0].dataKey);

    return <AssessmentFormChartTooltip question={question} {...props} />;
  };

  const questionNumericDomain = useMemo(() => {
    const selectedRangeQuestions = selectedConversationQuestions.map(
      (question) => ({
        ...question,
        content: question.content as RangeValidConversationQuestion,
      })
    );

    const minNumberValues = selectedRangeQuestions.map(
      (p) => p.content.min_number_value ?? 0
    );
    const maxNumberValues = selectedRangeQuestions.map(
      (p) => p.content.max_number_value ?? 100
    );

    return [
      Math.min(...[...minNumberValues, ...maxNumberValues]),
      Math.max(...[...minNumberValues, ...maxNumberValues]),
    ];
  }, [selectedConversationQuestions]);

  const renderQuestions = useMemo(() => {
    const rendered = questions.filter((p) =>
      displayedQuestions.includes(p.questionId)
    );

    return rendered.map((question) => {
      const element = elements.find(
        (p) => p.question_id === question.questionId
      );

      if (!element) return <></>;

      if (element.chart_type === TrendVisualizationChartType.LineChart)
        return (
          <Line
            key={question.questionId}
            connectNulls
            type="monotone"
            strokeWidth={3}
            dataKey={question.questionTitle}
            stroke={question.questionColor}
            activeDot={{ r: 8 }}
            yAxisId="1"
          />
        );

      return (
        <Bar
          key={question.questionId}
          dataKey={question.questionTitle}
          fill={question.questionColor}
          yAxisId="1"
        />
      );
    });
  }, [displayedQuestions, elements, questions]);

  return (
    <div className="d-flex flex-column" style={{ userSelect: 'none' }}>
      {zoomModeConfig.left !== undefined && zoomModeConfig.right !== undefined && (
        <Button
          color="primary"
          outline
          className="me-3 align-self-end"
          onClick={handleResetZoom}
        >
          <FontAwesomeIcon icon={faArrows} className="me-1" />
          <FormattedMessage id="TimelineDetails.TrendsTimeline.zoomOut" />
        </Button>
      )}
      <ResponsiveContainer
        className="AssessmentFormChart"
        width={'100%'}
        height={400}
      >
        <ComposedChart
          data={
            zoomModeConfig.leftClosestIndex && zoomModeConfig.rightClosestIndex
              ? formattedChartData.slice(
                  zoomModeConfig.leftClosestIndex,
                  zoomModeConfig.rightClosestIndex
                )
              : formattedChartData
          }
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={zoom}
        >
          <CartesianGrid
            strokeDasharray="1 1"
            className="recharts-padded-grid"
            vertical={false}
          />
          <XAxis
            allowDataOverflow={true}
            dataKey="name"
            type="number"
            domain={chartDomain}
            ticks={ticks}
            tickMargin={15}
            tickFormatter={xAxisTickFormatter}
            padding={{ left: 30, right: 30 }}
          />
          <YAxis
            type="number"
            axisLine={true}
            interval="preserveStartEnd"
            domain={[
              questionNumericDomain.at(0) ?? 'dataMin',
              questionNumericDomain.at(1) ?? 'dataMax',
            ]}
            padding={{ top: 20 }}
            yAxisId="1"
          />
          {renderQuestions}
          <Tooltip
            content={renderQuestionsTooltip}
            cursor={{ fill: '#e0e0e0' }}
          />
          <Legend
            wrapperStyle={{ paddingTop: '20px' }}
            content={
              <AssessmentFormChartLegend
                questions={questions}
                displayedQuestions={displayedQuestions}
                setDisplayedQuestions={setDisplayedQuestions}
              />
            }
          />
          {zoomModeConfig.refAreaLeft && zoomModeConfig.refAreaRight ? (
            <ReferenceArea
              yAxisId="1"
              x1={zoomModeConfig.refAreaLeft}
              x2={zoomModeConfig.refAreaRight}
              strokeOpacity={0.3}
            />
          ) : null}
        </ComposedChart>
      </ResponsiveContainer>
    </div>
  );
};

export default QuestionsChart;
