import React from 'react';
import SVG from 'react-inlinesvg';
import personIcon from '../../../../../../../../../../assets/images/person.svg';
import QueriesLoaded from '../../../../../../../../../../components/QueriesLoaded';
import { useInitQueries } from '../../../../../../../../../../hooks/queries/useInitQuery';
import { useTranslations } from '../../../../../../../../../../hooks/useTranslations';
import { ConversationQuestion } from '../../../../../../../../../../interfaces/conversations';
import { IPersonality } from '../../../../../../../../../../interfaces/personalities';
import { useCommonData } from '../../../../hooks/useCommonData';
import ItemContainer from '../../../ItemContainer';
import SummaryItems from '../SummaryItems';

const BasicSummary = () => {
  const { attachPrefix, wrapWithIntl } = useTranslations(
    'Cases.CaseDetails.AwareCase.Summary'
  );

  const { conversationQuestions, organizationMembers } = useCommonData();
  useInitQueries([conversationQuestions, organizationMembers]);

  return (
    <ItemContainer
      title={wrapWithIntl(attachPrefix('summary'))}
      icon={<SVG src={personIcon} />}
    >
      <div className="h-100">
        <QueriesLoaded queries={[conversationQuestions, organizationMembers]}>
          {(data) => (
            <SummaryItems
              members={data[1] as IPersonality[]}
              questions={data[0] as ConversationQuestion[]}
            />
          )}
        </QueriesLoaded>
      </div>
    </ItemContainer>
  );
};

export default BasicSummary;
