import React from 'react';
import { useIntl } from 'react-intl';
import { Formik, Field, Form } from 'formik';
import { FormGroup } from 'reactstrap';

// COMPONENTS:
import FieldInput from 'components/FieldInput';
import LoadingButton from 'components/LoadingButton';
import PasswordComplexity from 'components/PasswordComplexity';

type SetPasswordFormProps = {
  isFailure: boolean;
  isLoading: boolean;
  isSuccess: boolean;
  handleSubmit: (params: { password: string; passwordRepeat: string }) => void;
  initialValues: {
    password: string;
    passwordRepeat: string;
  };
};

const SetPasswordForm = ({
  isFailure,
  isLoading,
  isSuccess,
  handleSubmit,
  initialValues,
}: SetPasswordFormProps) => {
  const intl = useIntl();
  const { formatMessage } = intl || {};

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      {() => (
        <Form>
          <FormGroup>
            <Field
              required
              name="password"
              type="password"
              placeholder={formatMessage({
                id: 'SetPassword.SetPasswordForm.password',
              })}
              component={FieldInput}
            />
          </FormGroup>
          <FormGroup>
            <Field
              required
              name="passwordRepeat"
              type="password"
              placeholder={formatMessage({
                id: 'SetPassword.SetPasswordForm.passwordRepeat',
              })}
              component={FieldInput}
            />
          </FormGroup>
          <PasswordComplexity constraints={[]} />
          <div className="d-flex flex-wrap justify-content-between align-items-center">
            <div className="newMembersSection__buttonWrapper">
              <LoadingButton
                isLoading={isLoading}
                isSuccess={isSuccess}
                isFailure={isFailure}
                value="SetPassword.SetPasswordForm.buttonText"
                type="submit"
                className="btn-login"
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SetPasswordForm;
