import { UserCaseQueryParams } from '../../../interfaces/queryParams';
import { IQueryRequest } from '../../../interfaces/request';
import { useQuery } from 'react-query';
import { getUserTrendsQuestionHistoryInDay } from '../../change';
import QueryKeys from '../queryKeys';

interface UseUserQuestionHistoryInDayParams extends UserCaseQueryParams {
  date: Date;
}

const useUserQuestionHistoryInDay = ({
  params,
  options,
}: IQueryRequest<UseUserQuestionHistoryInDayParams>) => {
  const { date, userId, caseId } = params;
  return useQuery({
    ...options,
    queryFn: async () => {
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      const { data } = await getUserTrendsQuestionHistoryInDay(
        userId,
        caseId,
        year,
        month,
        day
      );

      return data.data;
    },
    queryKey: [QueryKeys.getUserQuestionHistoryInDay, userId, caseId, date],
  });
};

export default useUserQuestionHistoryInDay;
