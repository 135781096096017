import React, { useMemo } from 'react';
import { Form as FormikForm, Formik } from 'formik';
import OrganizationsTrends from './components/OrganizationsTrends';
import CaseTrends from './components/CaseTrends';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/pro-light-svg-icons';
import { useCommonData } from 'modules/Organizations/views/OrganizationDetailsView/hooks/useCommonData';
import { useInitQueries } from 'hooks/queries/useInitQuery';
import {
  CaseDefaultSetupOrigin,
  ICaseDefaultSetup,
} from 'interfaces/caseDefaultSetups';
import { IUpsertCaseDefaultSetup } from 'interfaces/trendVisualizations';
import LoadingButton from 'components/LoadingButton';
import useUpsertCaseDefaultSetups from 'api/mutations/caseDefaultSetups/useUpsertCaseDefaultSetups';

type TrendVisualizationDefaultViewsInitialValues = {
  [key: string]: string | undefined;
};

const Form = () => {
  const {
    organizationDetails,
    caseDefaultSetups,
    caseCategories,
    trendVisualizationTemplates,
    queriesRefetch,
  } = useCommonData();

  useInitQueries([
    caseDefaultSetups,
    caseCategories,
    trendVisualizationTemplates,
  ]);

  const { mutateAsync, isLoading } = useUpsertCaseDefaultSetups({
    successFb: queriesRefetch,
  });

  const initialValues = useMemo(() => {
    if (caseCategories.data && caseDefaultSetups.data) {
      let values: TrendVisualizationDefaultViewsInitialValues = {
        'organization|medical-personnel': caseDefaultSetups.data.find(
          (p) => p.origin === CaseDefaultSetupOrigin.Organization
        )?.member_trend_visualization_template?.id,
        'organization|patients': caseDefaultSetups.data.find(
          (p) => p.origin === CaseDefaultSetupOrigin.Organization
        )?.patient_trend_visualization_template?.id,
      };

      caseCategories.data.forEach((category) => {
        values[`${category.id}|medical-personnel`] =
          caseDefaultSetups.data.find(
            (p) =>
              p.origin === CaseDefaultSetupOrigin.CaseCategory &&
              p.origin_id === category.id
          )?.member_trend_visualization_template?.id;

        values[`${category.id}|patients`] = caseDefaultSetups.data.find(
          (p) =>
            p.origin === CaseDefaultSetupOrigin.CaseCategory &&
            p.origin_id === category.id
        )?.patient_trend_visualization_template?.id;

        category.sub_categories.forEach((sub_category) => {
          values[`${category.id}|${sub_category.id}|medical-personnel`] =
            caseDefaultSetups.data.find(
              (p) =>
                p.origin === CaseDefaultSetupOrigin.CaseSubCategory &&
                p.origin_id === sub_category.id
            )?.member_trend_visualization_template?.id;
        });

        category.sub_categories.forEach((sub_category) => {
          values[`${category.id}|${sub_category.id}|patients`] =
            caseDefaultSetups.data.find(
              (p) =>
                p.origin === CaseDefaultSetupOrigin.CaseSubCategory &&
                p.origin_id === sub_category.id
            )?.patient_trend_visualization_template?.id;
        });
      });

      return values;
    }

    return {};
  }, [caseCategories, caseDefaultSetups]);

  const handleSaveTrends = async (values: typeof initialValues) => {
    let updatedEntries: IUpsertCaseDefaultSetup[] = [];

    const organizationDefaultSetup = caseDefaultSetups.data?.find(
      (p) => p.origin === CaseDefaultSetupOrigin.Organization
    );

    if (!organizationDetails.data) return;

    updatedEntries.push({
      id: organizationDefaultSetup?.id ?? null,
      organization_id: organizationDetails.data.id,
      case_category_id: null,
      case_subcategory_id: null,
      member_trend_visualization_id:
        values['organization|medical-personnel'] ?? null,
      patient_trend_visualization_id: values['organization|patients'] ?? null,
    });

    Object.entries(values)
      .filter(
        ([key]) =>
          key !== 'organization|medical-personnel' &&
          key !== 'organization|patients'
      )
      .forEach(([key, value]) => {
        const ids = key.split('|');
        if (ids.length === 2) {
          const caseCategoriesEntries = getCaseCategoryEntries(
            ids,
            values,
            caseDefaultSetups.data ?? [],
            value
          );
          updatedEntries.push(...caseCategoriesEntries);
        }

        if (ids.length === 3) {
          const caseSubCategoriesEntries = getCaseSubCategoryEntries(
            ids,
            values,
            caseDefaultSetups.data ?? [],
            value
          );
          updatedEntries.push(...caseSubCategoriesEntries);
        }
      });

    await mutateAsync(updatedEntries);
  };

  const getCaseCategoryEntries = (
    ids: string[],
    initValues: TrendVisualizationDefaultViewsInitialValues,
    setups?: ICaseDefaultSetup[],
    currentValue?: string
  ) => {
    const caseCategoriesSetup = setups?.find((p) => p.origin_id === ids[0]);

    const entries: IUpsertCaseDefaultSetup[] = [];

    if (!organizationDetails.data) return [];
    if (!caseCategoriesSetup?.id && !currentValue) return [];

    entries.push({
      id: caseCategoriesSetup?.id ?? null,
      organization_id: organizationDetails.data.id,
      case_category_id: ids[0],
      case_subcategory_id: null,
      member_trend_visualization_id:
        initValues[`${ids[0]}|medical-personnel`] ?? null,
      patient_trend_visualization_id: initValues[`${ids[0]}|patients`] ?? null,
    });

    return entries;
  };

  const getCaseSubCategoryEntries = (
    ids: string[],
    initValues: TrendVisualizationDefaultViewsInitialValues,
    setups?: ICaseDefaultSetup[],
    currentValue?: string
  ) => {
    const caseSubCategoriesSetup = setups?.find((p) => p.origin_id === ids[1]);

    const entries: IUpsertCaseDefaultSetup[] = [];

    if (!organizationDetails.data) return [];
    if (!caseSubCategoriesSetup?.id && !currentValue) return [];

    entries.push({
      id: caseSubCategoriesSetup?.id ?? null,
      organization_id: organizationDetails.data.id,
      case_category_id: ids[0],
      case_subcategory_id: ids[1],
      member_trend_visualization_id:
        initValues[`${ids[0]}|${ids[1]}|medical-personnel`] ?? null,
      patient_trend_visualization_id:
        initValues[`${ids[0]}|${ids[1]}|patients`] ?? null,
    });

    return entries;
  };

  return (
    <Formik
      onSubmit={handleSaveTrends}
      enableReinitialize={true}
      initialValues={initialValues}
    >
      {({ isValid, handleSubmit }) => (
        <FormikForm onSubmit={handleSubmit}>
          <div className="TrendVisualizationsTab__organizations-trends">
            <OrganizationsTrends />
          </div>
          <div className="TrendVisualizationsTab__case-trends">
            <CaseTrends />
          </div>
          <div className="d-flex justify-content-end">
            <LoadingButton
              color="primary"
              outline={true}
              isLoading={isLoading}
              disabled={!isValid}
              type="submit"
            >
              <FontAwesomeIcon icon={faSave} />
            </LoadingButton>
          </div>
        </FormikForm>
      )}
    </Formik>
  );
};

export default Form;
