import React from 'react';
import { Spinner } from 'reactstrap';
import { GraspColors, graspColorsValues } from '../../helpers/colors';
import { FormattedMessage } from 'react-intl';

type InfiniteListLayoutLoaderProps = {
  color?: string;
  translationId?: string;
};

const InfiniteListLayoutLoader = ({
  color = graspColorsValues[GraspColors.BLUE],
  translationId,
}: InfiniteListLayoutLoaderProps) => {
  return (
    <div className="w-100 d-flex gap-2 align-items-center justify-content-center my-2">
      <Spinner color={color} aria-label="loading" />
      {translationId && <FormattedMessage id={translationId} />}
    </div>
  );
};

export default InfiniteListLayoutLoader;
