import React, { useMemo } from 'react';
import TrendsTabPlaceholder from '../components/TrendsTabPlaceholder';
import { useCommonParams } from '../../../../../hooks/useCommonParams';
import { useQueries } from './useQueries';
import useUserCaseConversationQuestions from '../../../../../../../api/queries/conversations/useUserCaseConversationQuestions';
import useUserCaseObservationMeanings from '../../../../../../../api/queries/observationMeanings/useUserCaseObservationMeanings';
import { useInitQuery } from '../../../../../../../hooks/queries/useInitQuery';
import { useQueriesLoading } from '../../../../../../../hooks/queries/useQueriesLoading';
import TrendsTabQueriesWrapper from '../components/TrendsTabQueriesWrapper';
import TrendsTabItem from '../components/TrendsTabItem';
import { Col } from 'reactstrap';
import { cloneDeep } from 'lodash';
import { filterExerciseBasedVisualizationElements } from '../helpers';
import { useCommonData } from '../../../../../hooks/useCommonData';

export const useRenderCharts = () => {
  const { userId, caseId } = useCommonParams();
  const { userCaseDetails } = useCommonData();

  const { trendVisualizationTemplatesQuery } = useQueries();

  const conversationQuestions = useUserCaseConversationQuestions({
    params: { userId, caseId },
    options: { enabled: false, staleTime: Infinity },
  });

  const observationMeanings = useUserCaseObservationMeanings({
    params: { userId, caseId },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(conversationQuestions);
  useInitQuery(observationMeanings);

  const isContextGeneratorsLoading = useQueriesLoading([
    conversationQuestions,
    observationMeanings,
  ]);

  return useMemo(() => {
    const notUserBased = !userId;

    const contextGeneratorsNotLoaded =
      isContextGeneratorsLoading ||
      !conversationQuestions.data ||
      !observationMeanings.data;

    const templateNotLoaded =
      trendVisualizationTemplatesQuery.isLoading ||
      !trendVisualizationTemplatesQuery.data;

    if (notUserBased || contextGeneratorsNotLoaded || templateNotLoaded)
      return <TrendsTabPlaceholder />;

    let template = cloneDeep(trendVisualizationTemplatesQuery.data);

    if (template && userCaseDetails.data?.exercises_enabled === false)
      template = filterExerciseBasedVisualizationElements(template);

    return (
      <TrendsTabQueriesWrapper>
        {(props) => (
          <>
            {template.visualizations.map((visualization) => (
              <Col
                key={visualization.sequence}
                md={12}
                lg={6}
                className="TrendsTab__item mb-lg-4"
              >
                <TrendsTabItem
                  visualization={visualization}
                  questions={conversationQuestions.data}
                  meanings={observationMeanings.data}
                  queries={props}
                />
              </Col>
            ))}
          </>
        )}
      </TrendsTabQueriesWrapper>
    );
  }, [
    conversationQuestions.data,
    isContextGeneratorsLoading,
    observationMeanings.data,
    trendVisualizationTemplatesQuery.data,
    trendVisualizationTemplatesQuery.isLoading,
    userCaseDetails.data?.exercises_enabled,
    userId,
  ]);
};
