import SettingsIcon from '@mui/icons-material/Settings';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, { ReactNode } from 'react';
import { useCultureEntry } from '../../../../../../../../../../../../../../hooks/cultures/useCultureEntry';
import useOpen from '../../../../../../../../../../../../../../hooks/useOpen';
import { IChartDashboardTemplateVisualization } from '../../../../../../../../../../../../../../interfaces/dashboards';
import ChartSettingsModal from '../../modals/ChartSettingsModal';

type ChartSettingsWrapperProps = {
  visualization: IChartDashboardTemplateVisualization;
  children: ReactNode;
};

const ChartSettingsWrapper = ({
  visualization,
  children,
}: ChartSettingsWrapperProps) => {
  const { isOpen, toggle } = useOpen();

  const translation = useCultureEntry(visualization.name);

  const containerStyle = visualization.container_style
    ? JSON.parse(visualization.container_style)
    : {};

  return (
    <>
      {isOpen && (
        <ChartSettingsModal
          isOpen={isOpen}
          toggle={toggle}
          visualization={visualization}
        />
      )}
      <Card
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'background.default',
          color: 'text.primary',
          position: 'relative',
          overflow: 'initial',
          ...containerStyle,
        }}
        variant="outlined"
      >
        <CardHeader
          title={
            <Typography sx={{ color: 'text.primary' }} variant="subtitle1">
              {translation ?? ''}
            </Typography>
          }
          action={
            <IconButton
              sx={{ color: 'text.primary', zIndex: 10 }}
              onClick={toggle}
              aria-label="settings"
            >
              <SettingsIcon />
            </IconButton>
          }
        />
        <CardContent
          sx={{
            width: '100%',
            height: '100%',
            paddingTop: 0,
          }}
        >
          {children}
        </CardContent>
      </Card>
    </>
  );
};

export default ChartSettingsWrapper;
