import React from 'react';
import FieldHtmlEditor from '../../../../../../../../../../components/Fields/Formik/FieldHtmlEditor';
import { Field } from 'formik';
import { useTranslations } from '../../hooks/useTranslations';
import {
  PlainTextValidConversationTemplateItem,
  ValidConversationTemplate,
} from '../../../../../../../../../../interfaces/conversations';

type BasicPlainTextItemEditProps = {
  index: number;
  isDefaultLanguage: boolean;
  values: ValidConversationTemplate;
};

const BasicPlainTextItemEdit = ({
  index,
  isDefaultLanguage,
  values,
}: BasicPlainTextItemEditProps) => {
  const { providerInvariant } = useTranslations();

  const plainTextItem = values.content[
    index
  ] as PlainTextValidConversationTemplateItem;

  const cultureBasedPlainTextPlaceholder = providerInvariant.wrapWithIntl(
    providerInvariant.attachPrefix('provideValueFor'),
    { target: plainTextItem.value.text }
  );

  const defaultPlainTextPlaceholder = providerInvariant.wrapWithIntl(
    providerInvariant.attachPrefix('titlePlaceholder')
  );

  const plainTextPlaceholder = isDefaultLanguage
    ? defaultPlainTextPlaceholder
    : cultureBasedPlainTextPlaceholder;

  return (
    <>
      <Field
        required={true}
        placeholder={plainTextPlaceholder}
        id={`content[${index}].value.text`}
        name={`content[${index}].value.${
          isDefaultLanguage ? 'text_html' : 'text_html_nb_no'
        }`}
        nonHtmlName={`content[${index}].value.${
          isDefaultLanguage ? 'text' : 'text_nb_no'
        }`}
        label={providerInvariant.wrapWithIntl(
          providerInvariant.attachPrefix('title')
        )}
        type="text"
        component={FieldHtmlEditor}
      />
    </>
  );
};

export default BasicPlainTextItemEdit;
