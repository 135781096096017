import {
  GridRenderCellParams,
  GridRenderEditCellParams,
} from '@mui/x-data-grid';
import React from 'react';
import { useGetCultureEntry } from '../../../../../../../../../../../../hooks/cultures/useGetCultureEntry';
import { DashboardTemplate } from '../../../../../../../../../../../../interfaces/dashboards';
import { ReportScheduleTableTypes } from '../../types';
import SelectCell from '../SelectCell';

type DashboardCellProps =
  GridRenderCellParams<ReportScheduleTableTypes.ReportScheduleTableEntry> & {
    templates: DashboardTemplate[];
  };

const DashboardCell = ({ templates, ...inner }: DashboardCellProps) => {
  const getCultureEntry = useGetCultureEntry();
  const template = templates.find((p) => p.id === inner.row.dashboard_id);

  return <span>{template ? getCultureEntry(template.name) ?? '' : ''}</span>;
};

type DashboardEditCellProps =
  GridRenderEditCellParams<ReportScheduleTableTypes.ReportScheduleTableEntry> & {
    templates: DashboardTemplate[];
  };

export const DashboardEditCell = ({
  templates,
  ...inner
}: DashboardEditCellProps) => {
  const getCultureEntry = useGetCultureEntry();

  const valueGetter = (template: DashboardTemplate) => template.id;
  const textGetter = (template: DashboardTemplate) =>
    getCultureEntry(template.name) ?? '';

  const valueSetter = (value: any) => {
    return value;
  };

  return (
    <SelectCell
      items={templates}
      valueGetter={valueGetter}
      textGetter={textGetter}
      valueSetter={valueSetter}
      {...inner}
    />
  );
};

export default DashboardCell;
