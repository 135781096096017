import React from 'react';
import { useTranslations } from '../../../../../hooks/useTranslations';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage } from 'react-intl';

const NotFound = () => {
  const { attachPrefix } = useTranslations('DentalOrganizationProxy.NotFound');

  return (
    <div className="d-flex flex-column align-items-center justify-content-center vh-100 vw-100 top-0 start-0 position-absolute">
      <div className="d-flex flex-column align-items-center opacity-25 text-black fs-4">
        <FontAwesomeIcon icon={faLock} size="3x" />
        <FormattedMessage id={attachPrefix('notFound')} />
      </div>
    </div>
  );
};

export default NotFound;
