import useOrganizationDetails from '../../../api/queries/organizations/useOrganizationDetails';
import useRequiredParams from '../../../hooks/useRequiredParams';
import useEducationMaterials from '../../../api/queries/educationMaterials/useEducationMaterials';
import useCaseCategories from '../../../api/queries/cases/useCaseCategories';
import useEducationMaterialCategories from '../../../api/queries/educationMaterials/useEducationMaterialCategories';
import useEducationMaterialCategoriesTree from '../../../api/queries/educationMaterials/useEducationMaterialCategoriesTree';

export const useCommonData = () => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const organizationDetailsQuery = useOrganizationDetails({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const educationMaterialsQuery = useEducationMaterials({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const educationMaterialCategoriesQuery = useEducationMaterialCategories({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const educationMaterialCategoriesTreeQuery =
    useEducationMaterialCategoriesTree({
      params: { organizationId },
      options: { enabled: false, staleTime: Infinity },
    });

  const caseCategoriesQuery = useCaseCategories({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  return {
    organization: organizationDetailsQuery,
    educationMaterials: educationMaterialsQuery,
    caseCategories: caseCategoriesQuery,
    educationMaterialCategories: educationMaterialCategoriesQuery,
    educationMaterialCategoriesTree: educationMaterialCategoriesTreeQuery,
  };
};
