import { deleteAccount } from 'api/membership';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { getUserId } from '../../../helpers/utils/auth';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { IUserDeletionBody } from '../../../interfaces/membership';

function useUserDelete(successFb?: () => void, errorFb?: () => void) {
  return useMutationWithResultNotification({
    asyncApiFunction: async (body: IUserDeletionBody) => {
      const userId = getUserId();
      await deleteAccount(userId, body);
    },
    resetOnResult: true,
    onSuccess: () => {
      successFb && successFb();
      displaySuccessNotification(
        'MyProfile.AccountDeletion.deletedSuccessfully'
      );
    },
    onError: () => {
      errorFb && errorFb();
      displayErrorNotification('MyProfile.AccountDeletion.errorDeletion');
    },
  });
}

export default useUserDelete;
