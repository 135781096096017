import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { deleteEducationMaterial } from '../../educationMaterials';
import { OrganizationQueryParams } from '../../../interfaces/queryParams';
import { IMutationRequest } from '../../../interfaces/request';

const useDeleteEducationMaterial = ({
  params,
  options,
}: IMutationRequest<OrganizationQueryParams>) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (materialId: string) => {
      await deleteEducationMaterial(params.organizationId, materialId);
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'Api.Mutations.EducationMaterials.DeleteEducationMaterial.failure'
      );
      options.errorFb?.();
    },
    onSuccess: () => {
      displaySuccessNotification(
        'Api.Mutations.EducationMaterials.DeleteEducationMaterial.success'
      );
      options.successFb?.();
    },
    resetOnResult: true,
  });
};

export default useDeleteEducationMaterial;
