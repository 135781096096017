import { IOrganizationMembers } from '../../../../../../../interfaces/organizations';
import { IMemberInOrganization } from '../types';

export namespace DashboardOrganizationHelpers {
  export const GetMembersInOrganizations = (
    organizationsMembers: IOrganizationMembers[]
  ) => {
    let membersInOrganizations: IMemberInOrganization[] = [];

    for (const organizationMember of organizationsMembers) {
      membersInOrganizations.push(
        ...organizationMember.members.map((member) => ({
          organizationId: organizationMember.organization.id,
          memberId: member.id,
        }))
      );
    }

    return membersInOrganizations;
  };
}
