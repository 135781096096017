import React from 'react';
import FieldInput from '../../../../../../../../../../../../components/FieldInput';
import { Field } from 'formik';
import { useTranslations } from '../../../../hooks/useTranslations';
import { useTypedContext } from '../../../../../../../../../../../../hooks/useTypedContext';
import { LanguageContext } from '../../../../types';
import { AVAILABLE_CULTURES } from '../../../../../../../../../../../../components/CultureSelector/CultureSelector';
import { useGeneratePlaceholder } from '../../../../hooks/useGeneratePlaceholder';
import {
  EmailValidConversationQuestion,
  QuestionValidConversationTemplateItem,
  ValidConversationTemplate,
} from '../../../../../../../../../../../../interfaces/conversations';

type EmailProps = {
  index: number;
  values: ValidConversationTemplate;
};

const Email = ({ index, values }: EmailProps) => {
  const { providerInvariant } = useTranslations();

  const language = useTypedContext(LanguageContext);
  const isDefaultCulture = language.culture === AVAILABLE_CULTURES.EN;

  const getPlaceholder = useGeneratePlaceholder(isDefaultCulture);

  const questionItem = values.content[
    index
  ] as QuestionValidConversationTemplateItem;

  const emailQuestion = questionItem.value as EmailValidConversationQuestion;

  return (
    <>
      <Field
        required={false}
        placeholder={getPlaceholder(
          'answerMaxLength',
          emailQuestion.answer_max_length
        )}
        label={providerInvariant.wrapWithIntl(
          providerInvariant.attachPrefix('answerMaxLength')
        )}
        id={`content[${index}].value.answer_max_length`}
        name={`content[${index}].value.answer_max_length`}
        type="number"
        component={FieldInput}
      />
      <Field
        required={false}
        placeholder={getPlaceholder(
          'answerPlaceholder',
          emailQuestion.answer_placeholder
        )}
        label={providerInvariant.wrapWithIntl(
          providerInvariant.attachPrefix('answerPlaceholder')
        )}
        id={`content[${index}].value.answer_placeholder`}
        name={`content[${index}].value.answer_placeholder`}
        type="text"
        component={FieldInput}
      />
    </>
  );
};

export default Email;
