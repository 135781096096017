import React, { PropsWithChildren } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronRight,
  faFolder,
} from '@fortawesome/pro-light-svg-icons';
import useOpen from '../../../../hooks/useOpen';
import classNames from 'classnames';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { TreeElementActionDefinition } from '../../types/Tree';
import SkeletonPlaceholderOnLoad from '../../../SkeletonPlaceholderOnLoad';
import Skeleton from 'react-loading-skeleton';
import TreeAction from '../TreeAction';
import { wrapWithSequenceIdentified } from '../../../../helpers/utils/data';

type TreeFolderProps = {
  name: JSX.Element;
  isLoading?: boolean;
  icon?: IconDefinition;
  iconUrl?: string;
  actions?: TreeElementActionDefinition[];
};

const TreeFolder = ({
  name,
  isLoading = false,
  icon,
  actions = [],
  iconUrl,
  children,
}: PropsWithChildren<TreeFolderProps>) => {
  const { isOpen, toggle } = useOpen();

  const onToggle = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    toggle();
  };

  const renderActions = wrapWithSequenceIdentified(actions).map((action) => (
    <TreeAction key={action.sequence} action={action} />
  ));

  return (
    <div
      className={classNames({
        Tree__folder: true,
        TreeFolder: true,
        'TreeFolder--clickable': !!children,
      })}
      onClick={onToggle}
    >
      <div className="TreeFolder__label ps-1">
        <FontAwesomeIcon icon={isOpen ? faChevronDown : faChevronRight} />
        {iconUrl ? (
          <img src={iconUrl} alt="" />
        ) : (
          <FontAwesomeIcon icon={icon ? icon : faFolder} />
        )}
        <SkeletonPlaceholderOnLoad
          isLoading={isLoading}
          placeholder={<Skeleton width={200} />}
        >
          <span>{name}</span>
        </SkeletonPlaceholderOnLoad>
        <div className="TreeFolder__actions pe-1">{renderActions}</div>
      </div>
      <div
        className={classNames({
          TreeFolder__children: true,
          'TreeFolder__children--visible': isOpen,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default TreeFolder;
