import {
  ClosedListValidConversationQuestion,
  EmailValidConversationQuestion,
  HeaderValidConversationQuestion,
  OpenValidConversationQuestion,
  QuestionValidConversationTemplateItem,
  RangeValidConversationQuestion,
  TimeValidConversationQuestion,
  ValidConversationQuestionType,
  ValidConversationTemplate,
} from '../../../../../../../../../../../interfaces/conversations';
import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { useTypedContext } from '../../../../../../../../../../../hooks/useTypedContext';
import { FormikUtilsContext } from '../../../../../types';

export const useHandleChangeType = (
  index: number,
  values: ValidConversationTemplate
) => {
  const selectedItem = values.content[index];
  const selectedQuestion =
    selectedItem as QuestionValidConversationTemplateItem;

  const formikUtils = useTypedContext(FormikUtilsContext);

  return useCallback(
    (_: string, value: string) => {
      const questionType = value as ValidConversationQuestionType;

      let valuesCopy = cloneDeep(values);

      switch (questionType) {
        case ValidConversationQuestionType.Range:
          valuesCopy.content[index] = {
            ...selectedQuestion,
            value: {
              ...selectedQuestion.value,
              type: ValidConversationQuestionType.Range,
              legend_descriptions: [],
              answer: {
                number_value: null,
                date_value: null,
              },
            } as RangeValidConversationQuestion,
          } as QuestionValidConversationTemplateItem;
          break;
        case ValidConversationQuestionType.Open:
          valuesCopy.content[index] = {
            ...selectedQuestion,
            value: {
              ...selectedQuestion.value,
              type: ValidConversationQuestionType.Open,
              answer: {
                text_value: null,
              },
            } as OpenValidConversationQuestion,
          } as QuestionValidConversationTemplateItem;
          break;
        case ValidConversationQuestionType.Time:
          valuesCopy.content[index] = {
            ...selectedQuestion,
            value: {
              ...selectedQuestion.value,
              type: ValidConversationQuestionType.Time,
              answer: {
                time_value: null,
              },
            } as TimeValidConversationQuestion,
          } as QuestionValidConversationTemplateItem;
          break;
        case ValidConversationQuestionType.Email:
          valuesCopy.content[index] = {
            ...selectedQuestion,
            value: {
              ...selectedQuestion.value,
              type: ValidConversationQuestionType.Email,
              answer: {
                text_value: null,
              },
            } as EmailValidConversationQuestion,
          } as QuestionValidConversationTemplateItem;
          break;
        case ValidConversationQuestionType.ClosedList:
          valuesCopy.content[index] = {
            ...selectedQuestion,
            value: {
              ...selectedQuestion.value,
              type: ValidConversationQuestionType.ClosedList,
              answers: [],
            } as ClosedListValidConversationQuestion,
          } as QuestionValidConversationTemplateItem;
          break;
        case ValidConversationQuestionType.Header:
          valuesCopy.content[index] = {
            ...selectedQuestion,
            value: {
              ...selectedQuestion.value,
              type: ValidConversationQuestionType.Header,
            } as HeaderValidConversationQuestion,
          } as QuestionValidConversationTemplateItem;
          break;
      }

      formikUtils.setValues(valuesCopy);
    },
    [formikUtils, index, selectedQuestion, values]
  );
};
