import React from 'react';
import { Button, FormGroup } from 'reactstrap';
import ProjectsList from './components/ProjectList';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import useOpen from '../../hooks/useOpen';
import useRequiredParams from '../../hooks/useRequiredParams';
import InfiniteListLayout from '../../components/InfiniteList/InfiniteListLayout';
import searchIcon from 'assets/images/timeline_details/day_by_day.svg';

const Projects = () => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const { isOpen, toggle } = useOpen();

  return (
    <InfiniteListLayout
      labelTranslationId={'MyOrganizations.projects.searchForProject'}
      titleTranslationId={'Projects.breadcrumbs.projects'}
      inputIconSrc={searchIcon}
      additionalRightContent={
        <FormGroup>
          <Button color="primary" outline={true} onClick={toggle}>
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </FormGroup>
      }
    >
      <ProjectsList
        organizationId={organizationId}
        isModalOpen={isOpen}
        modalToggle={toggle}
      />
    </InfiniteListLayout>
  );
};

export default Projects;
