import { ITabContainerItem } from 'components/Tabs/types/Tabs';
import React, { useMemo } from 'react';
import { wrapWithSequenceIdentified } from 'helpers/utils/data';
import TabPanel from 'components/Tabs/components/VerticalTabContainer/components/TabPanel';

export const useTabPanels = (defaultSelectedItems: ITabContainerItem[]) => {
  return useMemo(() => {
    return wrapWithSequenceIdentified(defaultSelectedItems)
      .filter((p) => p.shouldBeRendered)
      .map(({ component, sequence }, index) => (
        <TabPanel key={sequence} index={index}>
          {component}
        </TabPanel>
      ));
  }, [defaultSelectedItems]);
};
