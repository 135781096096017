import { addDays } from 'date-fns';
import { strToDateOnly } from 'helpers/dates';
import { useCallback } from 'react';
import { useCommonData } from './useCommonData';

export const useModalsUtils = () => {
  const { casePhaseHistory } = useCommonData();

  const getDateBasedOnCasePhaseHistory = useCallback(
    (day: number | null) => {
      if (day === null) return null;

      let casePhaseHistoryData = casePhaseHistory.data ?? [];
      casePhaseHistoryData.sort((a, b) => b.sequence - a.sequence);
      let currentCasePhaseHistory = casePhaseHistoryData.at(0);

      if (!currentCasePhaseHistory) return null;

      const targetDate = addDays(
        new Date(currentCasePhaseHistory.date_from),
        day - 1
      );
      return targetDate.toISOString();
    },
    [casePhaseHistory.data]
  );

  const normalizeIsoDate = useCallback((str: string) => {
    const dateStr = strToDateOnly(str);
    const date = new Date(dateStr);

    return date.toISOString();
  }, []);

  return {
    getDateBasedOnCasePhaseHistory,
    normalizeIsoDate,
  };
};
