import React, { useCallback, useMemo, useState } from 'react';
import classNames from 'classnames';
import ReactFlagsSelect from 'react-flags-select';
import {
  IFeedbackResource,
  IUpsertFeedbackResource,
} from '../../../../../interfaces/feedbackResources';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-solid-svg-icons';
import InputFieldWithImage, {
  InputFieldWithImageColor,
} from '../../../../../components/InputFieldWithImage';
import conversationIcon from '../../../../../assets/images/conversation.svg';
import { Button, Input } from 'reactstrap';
import SVG from 'react-inlinesvg';
import deleteIcon from '../../../../../assets/images/delete_icon.svg';
import { FormattedMessage } from 'react-intl';
import FeedbackResourcesPreview from './FeedbackResourcesPreview';
import { cloneDeep } from 'lodash';
import useModifyFeedbackResource from '../../../../../api/mutations/feedbackResources/useModifyFeedbackResource';
import EditableInput from '../../../../../components/EditableInput/EditableInput';
import { displayErrorNotification } from '../../../../Notifications';
import useRemoveFeedbackResource from '../../../../../api/mutations/feedbackResources/useRemoveFeedbackResource';
import { FeedbackResourcesSelectionInfo } from '../index';
import { wrapWithSequenceIdentified } from '../../../../../helpers/utils/data';
import EventPropagationConstraint from '../../../../../components/EventPropagationConstraint';

type FeedbackResourcesEditorProps = {
  organizationId: string;
  feedbackResources: any;
  selectionInfo: FeedbackResourcesSelectionInfo;
  setSelectionInfo: (info: FeedbackResourcesSelectionInfo) => void;
  refetchFeedbackResources: () => void;
};

const FeedbackResourcesEditor = ({
  organizationId,
  selectionInfo,
  setSelectionInfo,
  feedbackResources,
  refetchFeedbackResources,
}: FeedbackResourcesEditorProps) => {
  const { mutateAsync } = useModifyFeedbackResource(refetchFeedbackResources);
  const { mutateAsync: removeMutationAsync } = useRemoveFeedbackResource(
    refetchFeedbackResources
  );

  const [addFeedbackResourceMode, setAddFeedbackResourceMode] =
    useState<boolean>(false);

  const [newFeedbackResourceGroupName, setNewFeedbackResourceGroupName] =
    useState<string>('');

  const [variantToAdd, setVariantToAdd] = useState<IFeedbackResource | null>(
    null
  );

  const [cultureToAdd, setCultureToAdd] = useState<IFeedbackResource | null>(
    null
  );

  const [cultureAddingMode, setCultureAddingMode] = useState<boolean>(false);
  const [cultureName, setCultureName] = useState<string>('');

  const updateVariantItems = useCallback(async () => {
    if (
      selectionInfo.selectedCulture === null ||
      selectionInfo.selectedGroupCode === null
    )
      return;

    const upsertVariantItem: IUpsertFeedbackResource = {
      culture: selectionInfo.selectedCulture,
      items: selectionInfo.variantItems,
      sequence: selectionInfo.selectedSequence,
    };

    const isVariantAdding =
      variantToAdd !== null &&
      selectionInfo.selectedCulture === variantToAdd.culture &&
      selectionInfo.selectedGroupCode === variantToAdd.groupCode &&
      selectionInfo.selectedSequence === variantToAdd.sequence;

    if (isVariantAdding) upsertVariantItem.sequence = null;

    if (isVariantAdding && upsertVariantItem.items.length === 0) {
      displayErrorNotification('FeedbackResources.cannotAddEmptyVariant');
      return;
    }

    await mutateAsync({
      ...upsertVariantItem,
      groupCode: selectionInfo.selectedGroupCode,
      organizationId: organizationId,
    });

    if (cultureToAdd !== null)
      setSelectionInfo({
        ...selectionInfo,
        selectedCulture: null,
        selectedSequence: null,
        variantItems: [],
      });

    setVariantToAdd(null);
    setCultureToAdd(null);
    setCultureAddingMode(false);
    setCultureName('');
  }, [
    cultureToAdd,
    mutateAsync,
    organizationId,
    selectionInfo,
    setSelectionInfo,
    variantToAdd,
  ]);

  const removeVariant = useCallback(async () => {
    if (
      selectionInfo.selectedCulture === null ||
      selectionInfo.selectedGroupCode === null
    )
      return;

    const upsertVariantItem: IUpsertFeedbackResource = {
      culture: selectionInfo.selectedCulture,
      items: [],
      sequence: selectionInfo.selectedSequence,
    };

    await mutateAsync({
      ...upsertVariantItem,
      groupCode: selectionInfo.selectedGroupCode,
      organizationId: organizationId,
    });

    setSelectionInfo({
      ...selectionInfo,
      selectedCulture: null,
      selectedSequence: null,
      variantItems: [],
    });

    setVariantToAdd(null);
    setCultureToAdd(null);
    setCultureAddingMode(false);
    setCultureName('');
  }, [mutateAsync, organizationId, selectionInfo, setSelectionInfo]);

  const removeResource = useCallback(async () => {
    if (
      selectionInfo.selectedCulture === null ||
      selectionInfo.selectedGroupCode === null
    )
      return;

    await removeMutationAsync({
      organizationId: organizationId,
      culture: selectionInfo.selectedCulture,
      groupCode: selectionInfo.selectedGroupCode,
    });
  }, [
    organizationId,
    removeMutationAsync,
    selectionInfo.selectedCulture,
    selectionInfo.selectedGroupCode,
  ]);

  const renderSequence = useCallback(
    (resourceSequence: number, groupCode: string, culture: string) => {
      if (selectionInfo.selectedSequence !== resourceSequence) return <></>;

      const renderVariantItems = wrapWithSequenceIdentified(
        selectionInfo.variantItems
      ).map((item) => {
        const modifyVariant = (event: React.ChangeEvent<HTMLInputElement>) => {
          let selection = cloneDeep(selectionInfo);
          let variantItemIndex = selection.variantItems.findIndex(
            (p) => p.text === item.text
          );

          if (variantItemIndex === -1) return;

          selection.variantItems[variantItemIndex].text = event.target.value;

          setSelectionInfo(selection);
        };

        const toggleTypingEffect = () => {
          let selection = cloneDeep(selectionInfo);

          let variantItemIndex = selection.variantItems.findIndex(
            (p) => p.text === item.text
          );

          if (variantItemIndex === -1) return;

          selection.variantItems[variantItemIndex].applyTypingEffect =
            !selection.variantItems[variantItemIndex].applyTypingEffect;

          setSelectionInfo(selection);
        };

        const removeVariantItem = () => {
          setSelectionInfo({
            ...selectionInfo,
            variantItems: [
              ...selectionInfo.variantItems.filter((p) => p.text !== item.text),
            ],
          });
        };

        return (
          <div
            key={`${resourceSequence}|${groupCode}|${culture}|${item.sequence}`}
          >
            <div className="FeedbackResourcesSequenceContentList__item FeedbackResourcesSequenceContentListItem">
              <InputFieldWithImage
                block
                color={InputFieldWithImageColor.gray}
                noMargin={true}
                image={<img src={conversationIcon} alt="name_logo" />}
                inputId={item.text}
              >
                <Input
                  id={item.text}
                  value={item.text}
                  onChange={modifyVariant}
                />
              </InputFieldWithImage>
              <Button
                className="FeedbackResourcesSequenceContentListItem__remove"
                onClick={removeVariantItem}
              >
                <SVG src={deleteIcon} title="delete" />
              </Button>
            </div>
            <div className="d-flex gap-2 my-3">
              <EditableInput
                type="checkbox"
                name={`${selectionInfo.selectedCulture}|${selectionInfo.selectedSequence}|${item.text}|${item.applyTypingEffect}`}
                checked={item.applyTypingEffect}
                id={`${selectionInfo.selectedCulture}|${selectionInfo.selectedSequence}|${item.text}|${item.applyTypingEffect}`}
                onEdit={toggleTypingEffect}
                debounceEnabled={false}
              />
              <label
                htmlFor={`${selectionInfo.selectedCulture}|${selectionInfo.selectedSequence}|${item.text}|${item.applyTypingEffect}`}
              >
                <FormattedMessage id="MyOrganizations.conversationTemplates.phone.applyTypingEffect" />
              </label>
            </div>
          </div>
        );
      });

      const addNewEntry = () => {
        if (selectionInfo.variantItems.find((p) => p.text.length === 0)) return;

        setSelectionInfo({
          ...selectionInfo,
          variantItems: [
            ...selectionInfo.variantItems,
            {
              text: '',
              applyTypingEffect: false,
            },
          ],
        });
      };

      return (
        <div
          key={`${resourceSequence}|${groupCode}|${culture}`}
          className="FeedbackResourcesSequenceContentList"
        >
          {renderVariantItems}
          <span
            className="FeedbackResourcesCulturesList__add-new-entry"
            onClick={addNewEntry}
          >
            <FormattedMessage id="FeedbackResources.addNewEntry" />
          </span>
        </div>
      );
    },
    [selectionInfo, setSelectionInfo]
  );

  const renderCultures = useCallback(
    (
      cultureKey: string,
      cultureValue: IFeedbackResource[],
      feedbackResourcesObject: any
    ) => {
      if (selectionInfo.selectedCulture !== cultureKey) return <></>;

      let cultureVariants = cloneDeep(cultureValue);
      cultureVariants.sort((a, b) => a.sequence - b.sequence);

      if (variantToAdd !== null) cultureVariants.push(variantToAdd);

      const renderCultureVariantsArray = cultureVariants.map((resource) => {
        const selectSequence = () => {
          setSelectionInfo({
            ...selectionInfo,
            selectedSequence: resource.sequence,
            variantItems: resource.items,
          });
        };

        return (
          <div
            key={`${resource.culture}|${resource.sequence}|${resource.groupCode}`}
          >
            <div className="FeedbackResourcesSequencesList__header">
              <li
                className={classNames({
                  FeedbackResourcesSequencesList__item: true,
                  'FeedbackResourcesSequencesList__item--selected':
                    selectionInfo.selectedSequence === resource.sequence,
                })}
                onClick={selectSequence}
              >
                <FormattedMessage id="FeedbackResources.variant" />{' '}
                {resource.sequence}
              </li>
              {selectionInfo.selectedSequence === resource.sequence && (
                <>
                  <button
                    className="FeedbackResourcesSequencesList__apply"
                    onClick={removeVariant}
                  >
                    <SVG src={deleteIcon} />
                  </button>
                  <button
                    className="FeedbackResourcesSequencesList__apply"
                    onClick={updateVariantItems}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </button>
                </>
              )}
            </div>
            {renderSequence(
              resource.sequence,
              resource.groupCode,
              resource.culture
            )}
          </div>
        );
      });

      const addVariant = () => {
        if (variantToAdd !== null) return;

        if (
          selectionInfo.selectedGroupCode === null ||
          selectionInfo.selectedCulture === null
        )
          return;

        setVariantToAdd({
          culture: selectionInfo.selectedCulture,
          groupCode: selectionInfo.selectedGroupCode,
          items: [],
          sequence:
            feedbackResourcesObject[selectionInfo.selectedGroupCode][
              selectionInfo.selectedCulture
            ].length + 1,
        });
      };

      return (
        <>
          <ul className="FeedbackResourcesSequencesList">
            {renderCultureVariantsArray}
          </ul>
          {selectionInfo.selectedSequence === null && (
            <span
              className="FeedbackResourcesCulturesList__add-variant"
              onClick={addVariant}
            >
              <FormattedMessage id="FeedbackResources.addVariant" />
            </span>
          )}
        </>
      );
    },
    [
      removeVariant,
      renderSequence,
      selectionInfo,
      setSelectionInfo,
      updateVariantItems,
      variantToAdd,
    ]
  );

  const renderFeedbackResource = useCallback(
    (groupCodeKey: string) => {
      if (selectionInfo.selectedGroupCode !== groupCodeKey) return <></>;

      let feedbackResourceObject = cloneDeep(feedbackResources);

      if (cultureToAdd !== null && cultureToAdd.groupCode === groupCodeKey)
        feedbackResourceObject[groupCodeKey][cultureToAdd.culture] = [];

      const renderFeedbackResourceArray = Object.entries(
        feedbackResourceObject[groupCodeKey]
      ).map(([cultureKey, value]) => {
        const selectCulture = () => {
          setSelectionInfo({
            ...selectionInfo,
            selectedCulture: cultureKey,
            selectedSequence: null,
            variantItems: [],
          });
        };

        return (
          <div key={`${groupCodeKey}|${cultureKey}`}>
            <li
              className={classNames({
                FeedbackResourcesCulturesList__item: true,
                'FeedbackResourcesCulturesList__item--selected':
                  selectionInfo.selectedCulture === cultureKey,
              })}
              onClick={selectCulture}
              key={cultureKey}
              aria-label={cultureKey}
            >
              <span>
                <FormattedMessage id="FeedbackResources.culture" />
              </span>
              <EventPropagationConstraint>
                <ReactFlagsSelect
                  className="FeedbackResourcesCulturesList__flag"
                  selected={cultureKey.slice(-2)}
                  disabled={true}
                  fullWidth={false}
                  showSelectedLabel={false}
                  onSelect={() => {}}
                  selectedSize={25}
                />
              </EventPropagationConstraint>
              {selectionInfo.selectedCulture === cultureKey && (
                <Button
                  className="FeedbackResourcesSequenceContentListItem__remove ms-auto"
                  onClick={removeResource}
                >
                  <SVG src={deleteIcon} title="delete" />
                </Button>
              )}
            </li>
            {renderCultures(
              cultureKey,
              value as IFeedbackResource[],
              feedbackResourceObject
            )}
          </div>
        );
      });

      const addCulture = () => {
        setCultureAddingMode(true);
      };

      const selectCultureName = (countryCode: string) => {
        setCultureName(countryCode);
      };

      const defineCultureToAdd = () => {
        if (selectionInfo.selectedGroupCode === null) return;

        setCultureToAdd({
          groupCode: selectionInfo.selectedGroupCode,
          items: [],
          sequence: 0,
          culture: cultureName,
        });
      };

      const disableAddingMode = () => {
        setCultureAddingMode(false);
        setCultureName('');
      };

      return (
        <div key={`single-${groupCodeKey}`}>
          <ul className="FeedbackResourcesCulturesList">
            {renderFeedbackResourceArray}
          </ul>
          {cultureAddingMode && cultureToAdd === null && (
            <li className="FeedbackResourcesCulturesList__item">
              <span className="me-2">
                <FormattedMessage id="FeedbackResources.culture" />
              </span>
              <EventPropagationConstraint>
                <ReactFlagsSelect
                  selected={cultureName}
                  disabled={false}
                  fullWidth={false}
                  showSelectedLabel={true}
                  onSelect={selectCultureName}
                />
              </EventPropagationConstraint>
              {cultureName !== '' && (
                <>
                  <button
                    className="FeedbackResourcesSequencesList__apply"
                    onClick={defineCultureToAdd}
                  >
                    <FontAwesomeIcon icon={faCheck} />
                  </button>
                  <button
                    className="FeedbackResourcesSequencesList__apply"
                    onClick={disableAddingMode}
                  >
                    <FontAwesomeIcon icon={faTimes} />
                  </button>
                </>
              )}
            </li>
          )}
          {selectionInfo.selectedCulture === null && (
            <span
              className="FeedbackResourcesList__add-culture"
              onClick={addCulture}
            >
              <FormattedMessage id="FeedbackResources.addCulture" />
            </span>
          )}
        </div>
      );
    },
    [
      cultureAddingMode,
      cultureName,
      cultureToAdd,
      feedbackResources,
      removeResource,
      renderCultures,
      selectionInfo,
      setSelectionInfo,
    ]
  );

  const renderFeedbackResources = useMemo(() => {
    if (feedbackResources === null || feedbackResources === undefined)
      return <></>;

    return (
      <ul className="FeedbackResourcesList">
        {Object.keys(feedbackResources).map((key) => (
          <div key={key}>
            <li
              className={classNames({
                FeedbackResourcesList__item: true,
                'FeedbackResourcesList__item--selected':
                  selectionInfo.selectedGroupCode === key,
              })}
              onClick={() =>
                setSelectionInfo({
                  selectedGroupCode: key,
                  selectedSequence: null,
                  selectedCulture: null,
                  variantItems: [],
                })
              }
              key={key}
            >
              {key}
            </li>
            {renderFeedbackResource(key)}
          </div>
        ))}
      </ul>
    );
  }, [
    feedbackResources,
    renderFeedbackResource,
    selectionInfo.selectedGroupCode,
    setSelectionInfo,
  ]);

  const enableAddFeedbackResourceMode = () => {
    setAddFeedbackResourceMode(true);
  };

  const handleNewGroupNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewFeedbackResourceGroupName(event.target.value);
  };

  const createNewFeedbackResource = async () => {
    await mutateAsync({
      organizationId: organizationId,
      groupCode: newFeedbackResourceGroupName,
      sequence: null,
      items: [
        {
          text: '',
          applyTypingEffect: false,
        },
      ],
      culture: 'en-US',
    });

    setNewFeedbackResourceGroupName('');
    setAddFeedbackResourceMode(false);
  };

  return (
    <div className="FeedbackResources__editor FeedbackResourcesEditor">
      <div className="FeedbackResourcesEditor__resources flex-column">
        {renderFeedbackResources}
        <div className="d-flex align-items-end">
          <Button
            disabled={addFeedbackResourceMode}
            onClick={enableAddFeedbackResourceMode}
            color="primary"
          >
            <FormattedMessage id="FeedbackResources.addNewEntry" />
          </Button>
          {addFeedbackResourceMode && (
            <li className="FeedbackResourcesList__item FeedbackResourcesList__item--selected d-flex flex-nowrap pb-0">
              <input
                type="text"
                value={newFeedbackResourceGroupName}
                onChange={handleNewGroupNameChange}
              />
              <button
                disabled={newFeedbackResourceGroupName.length === 0}
                className="FeedbackResourcesSequencesList__apply"
                onClick={createNewFeedbackResource}
              >
                <FontAwesomeIcon icon={faCheck} />
              </button>
            </li>
          )}
        </div>
      </div>
      <div className="FeedbackResourcesEditor__separator"></div>
      <FeedbackResourcesPreview selectionInfo={selectionInfo} />
    </div>
  );
};

export default FeedbackResourcesEditor;
