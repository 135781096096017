import React, { Fragment, ReactElement } from 'react';

export interface ISwitchable<T> {
  value: T;
}

type SwitchProps<T> = {
  item: T;
  children: ReactElement<ISwitchable<T>, any>[];
};

type CaseProps<T> = {
  value: T;
  children: JSX.Element;
};

export const Switch = <T,>({ item, children }: SwitchProps<T>) => {
  return (
    children.find((child) => child.props.value === item) ?? (
      <Fragment></Fragment>
    )
  );
};

export const Case = <T,>({ children }: CaseProps<T>) => {
  return children;
};
