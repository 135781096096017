import AddIcon from '@mui/icons-material/Add';
import FileOpenOutlinedIcon from '@mui/icons-material/FileOpenOutlined';
import TabLayout, { TabLayoutType } from 'components/layouts/TabLayout';
import { useInitQuery } from 'hooks/queries/useInitQuery';
import React from 'react';
import IconButton from '../../../../../../../../components/IconButton';
import useOpen from '../../../../../../../../hooks/useOpen';
import ConversationTemplatesList from '../../../../../../../ConversationsAndFeedbacks/components/ConversationTemplates/components/ConversationTemplatesList';
import { useAddTemplate } from '../../../../../../../ConversationsAndFeedbacks/components/ConversationTemplates/hooks/useAddTemplate';
import ConversationTemplateModal from '../../../../../../../ConversationsAndFeedbacks/components/ConversationTemplates/modals/ConversationTemplateModal';
import ImportAndEditTemplateModal from '../../../../../../../ConversationsAndFeedbacks/components/ConversationTemplates/modals/ImportAndEditTemplateModal';
import { useCommonData } from '../../../../../../../ConversationsAndFeedbacks/hooks/useCommonData';

const FormsTab = () => {
  const { conversationTemplates } = useCommonData();
  useInitQuery(conversationTemplates);

  const {
    enableAddTemplateMode,
    toggleAddTemplateModal,
    templateToAdd,
    isAddTemplateModalOpen,
  } = useAddTemplate();

  const importAndEditTemplateModal = useOpen();

  return (
    <TabLayout
      type={TabLayoutType.Breadcrumb}
      titlePrefix="Organizations.Forms"
      actions={
        <div className="d-flex align-items-center gap-2">
          <IconButton
            color="primary"
            onClick={enableAddTemplateMode}
            variant="outlined"
          >
            <AddIcon />
          </IconButton>
          <IconButton
            color="primary"
            onClick={importAndEditTemplateModal.toggle}
            variant="outlined"
          >
            <FileOpenOutlinedIcon />
          </IconButton>
        </div>
      }
    >
      <div>
        {templateToAdd && isAddTemplateModalOpen && (
          <ConversationTemplateModal
            isOpen={isAddTemplateModalOpen}
            toggle={toggleAddTemplateModal}
            template={templateToAdd}
          />
        )}
        <ImportAndEditTemplateModal
          isOpen={importAndEditTemplateModal.isOpen}
          toggle={importAndEditTemplateModal.toggle}
        />
        <div className="mt-4">
          <ConversationTemplatesList />
        </div>
      </div>
    </TabLayout>
  );
};

export default FormsTab;
