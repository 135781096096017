import DataObjectIcon from '@mui/icons-material/DataObject';
import { Box, Paper, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

const DataNotProvided = () => {
  return (
    <Box sx={{ color: 'text.secondary' }} className="w-100 h-vh-70">
      <Paper
        className="d-flex flex-column align-items-center justify-content-center w-100 h-100"
        elevation={0}
      >
        <DataObjectIcon sx={{ fontSize: 50 }} />
        <Typography variant="h6">
          <FormattedMessage id="Dashboards.DataNotProvided" />
        </Typography>
      </Paper>
    </Box>
  );
};

export default DataNotProvided;
