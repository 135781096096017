import React from 'react';
import { TrendVisualization } from '../../../../../../../../interfaces/trendVisualizations';
import { IOrganizationObservationMeaning } from '../../../../../../../../interfaces/observationMeanings';
import TrendsChart from '../../../../../../../../components/TrendsChart';
import { useTrendsTabItemConfig } from './hooks/useTrendsTabItemConfig';
import TabItem from '../../../TabItem';
import { useTrendsChartGenericFormatters } from '../../../../../../../../components/TrendsChart/hooks/useTrendsChartGenericFormatters';
import TrendsTabPlaceholder from '../TrendsTabPlaceholder';
import { TrendsChartGenericDataQueries } from '../../../../../../../../components/TrendsChart/types';
import { useTrendsChartGenericData } from '../../../../../../../../components/TrendsChart/hooks/useTrendsChartGenericData';
import { ConversationQuestion } from '../../../../../../../../interfaces/conversations';
import { useDates } from '../../hooks/useDates';

type TrendsTabItemProps = {
  visualization: TrendVisualization;
  questions: ConversationQuestion[];
  meanings: IOrganizationObservationMeaning[];
  queries: TrendsChartGenericDataQueries;
};

const TrendsTabItem = ({
  visualization,
  questions,
  meanings,
  queries,
}: TrendsTabItemProps) => {
  const config = useTrendsTabItemConfig(questions, meanings, visualization);

  const dateRange = useDates();

  const { data, isLoading } = useTrendsChartGenericData(
    visualization,
    queries,
    dateRange
  );

  const formatters = useTrendsChartGenericFormatters(questions, meanings);

  if (isLoading) return <TrendsTabPlaceholder items={1} />;

  return (
    <TabItem>
      <TrendsChart
        elements={visualization.elements}
        formatters={formatters}
        config={config}
        data={data}
      />
    </TabItem>
  );
};

export default TrendsTabItem;
