import { useCallback, useMemo, useState } from 'react';
import { intervalToDuration } from 'date-fns';

type TickCallbackFunction = (tick: number) => void;

export const useTimer = (interval: number = 1000, moduloBased: number = -1) => {
  const [count, setCount] = useState(0);
  const [innerInterval, setInnerInterval] = useState<NodeJS.Timer | null>(null);

  const init = useCallback((initCount: number = 0) => {
    setCount(initCount);
  }, []);

  const play = useCallback(
    (tickCallback?: TickCallbackFunction) => {
      setInnerInterval(
        setInterval(() => {
          setCount((prev) => {
            const nextValue =
              moduloBased === -1 ? prev + 1 : (prev + 1) % moduloBased;
            tickCallback?.(nextValue);
            return nextValue;
          });
        }, interval)
      );
    },
    [interval, moduloBased]
  );

  const stop = useCallback(() => {
    if (innerInterval === null) return;

    clearInterval(innerInterval);
    setInnerInterval(null);
  }, [innerInterval]);

  const currentTime = useMemo(() => {
    const duration = intervalToDuration({ start: 0, end: count * 1000 });

    let _currentTime = '';

    if (duration.hours)
      _currentTime += `${duration.hours.toString().padStart(2, '0')}:`;

    _currentTime += `${(duration.minutes ?? 0).toString().padStart(2, '0')}:`;
    _currentTime += `${(duration.seconds ?? 0).toString().padStart(2, '0')}`;

    return _currentTime;
  }, [count]);

  return {
    count,
    currentTime,
    play,
    stop,
    init,
  };
};
