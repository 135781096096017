import DateTimeInput from 'components/DateTimeInput';
import FieldInput from 'components/FieldInput';
import FormikErrorMessage from 'components/FormikErrorMessage';
import { Field } from 'formik';
import { FormattedMessage } from 'react-intl';
import { Form, FormGroup, Label } from 'reactstrap';
import RewardField from './RewardField';

//icons
import PredefinedMessages from './PredefinedMessages';
import OwnerOfMessageInput from './OwnerOfMessageInput';
import FieldHtmlEditor from '../../../../../components/Fields/Formik/FieldHtmlEditor';

type CaseHistoryFormProps = {
  extendedFields?: JSX.Element[];
  values: any;
  hidePraise: boolean;
  hideShare: boolean;
  hidePredefinedMessages: boolean;
  hideOwnerSelect: boolean;
  setValues: (values: any) => void;
  organizationId: string;
  caseId: string;
  caseIds?: string[];
};

const CaseHistoryForm = ({
  extendedFields,
  values,
  hidePraise,
  hideShare,
  hideOwnerSelect,
  setValues,
  hidePredefinedMessages,
  caseId,
  caseIds = undefined,
  organizationId,
}: CaseHistoryFormProps) => {
  const setPredefinedMessage = (value: string) => {
    setValues({ ...values, text: value });
  };

  return (
    <Form>
      <FormGroup>
        <Label htmlFor="time_start" className="mb-0">
          <FormattedMessage id="TimelineDetails.AnnotationModal.dateAndTime" />
        </Label>
        <Field
          required
          aria-label="from"
          name="time_start"
          type="date"
          margin={false}
          component={DateTimeInput}
        />
      </FormGroup>
      {!hideOwnerSelect && (
        <>
          {caseId && (
            <OwnerOfMessageInput
              caseId={caseId}
              organizationId={organizationId}
              values={values}
              setValues={setValues}
            />
          )}
          {caseIds && !caseId && (
            <OwnerOfMessageInput
              caseIds={caseIds}
              organizationId={organizationId}
              values={values}
              setValues={setValues}
            />
          )}
        </>
      )}
      {!hidePraise && (
        <FormGroup>
          <Label htmlFor="praise_level" className="mb-0">
            <FormattedMessage id="CaseCard.History.rewardPatient" />
            <span className="CreateEntryModal__optional">
              (<FormattedMessage id="General.optional" />)
            </span>
          </Label>
          <Field
            id="praise_level"
            required
            name="praise_level"
            component={RewardField}
          />
          <FormikErrorMessage name="praise_level" />
        </FormGroup>
      )}
      <FormGroup>
        <Label htmlFor="entry_description" className="mb-0">
          <FormattedMessage id="CaseCard.History.typeMessage" />
        </Label>
        <Field
          id="entry_description"
          required
          name="text"
          type="textarea"
          rows={3}
          addonOnTop
          condensed={false}
          component={FieldHtmlEditor}
        />
        <FormikErrorMessage name="text" />
      </FormGroup>
      {!hidePredefinedMessages && (
        <PredefinedMessages
          setPredefinedMessage={setPredefinedMessage}
          message={values?.text ?? ''}
        />
      )}
      {extendedFields}
      {!hideShare && (
        <FormGroup check>
          <Label check>
            <Field
              type="checkbox"
              name="share_with_patient"
              checked={values.share_with_patient}
              component={FieldInput}
            />
            <FormattedMessage id="CaseCard.History.share" />
          </Label>
        </FormGroup>
      )}
    </Form>
  );
};

export default CaseHistoryForm;
