import { RefObject, useCallback } from 'react';
import { isIsoDate } from '../../../../../../../../../../../../../helpers/dates';
import { ChartViewHelpers } from '../helpers';
import { useDateRange } from './useDateRange';

export const useChartWidthAxisInterval = () => {
  const dateRange = useDateRange();

  const _getAxisInterval = useCallback(
    (chartsData: any[]) => {
      if (chartsData.some((p) => isIsoDate(p.name)))
        return ChartViewHelpers.GetChartsInterval(dateRange);

      return 0;
    },
    [dateRange]
  );

  return useCallback(
    (chartsData: any[], chartRef: RefObject<HTMLDivElement>) => {
      if (!chartRef.current?.clientWidth) return _getAxisInterval(chartsData);

      return ChartViewHelpers.GetWidthChartsInterval(
        chartsData.length,
        chartRef.current.clientWidth
      );
    },
    [_getAxisInterval]
  );
};
