import { CaseQueryParams } from '../../../interfaces/queryParams';
import { IQueryRequest } from '../../../interfaces/request';
import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getCaseAwareSession } from '../../awareSessions';
import { AwareSessionDataSource } from '../../../interfaces/awareSessions';

type CaseAwareSessionsQueryParams = CaseQueryParams & {
  source: AwareSessionDataSource;
};

const useCaseAwareSession = ({
  params,
  options,
}: IQueryRequest<CaseAwareSessionsQueryParams>) => {
  const { organizationId, caseId, source } = params;
  return useQuery({
    ...options,
    queryKey: [QueryKeys.getCaseAwareSession, organizationId, caseId, source],
    queryFn: async () => {
      const { data } = await getCaseAwareSession(
        organizationId,
        caseId,
        source === AwareSessionDataSource.AggregatedAwareSessionData
      );

      return data.data;
    },
  });
};

export default useCaseAwareSession;
