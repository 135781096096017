import { faCommentsAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { userConversationContextTypeOptions } from 'helpers/utils/translationObject';
import {
  IUserConversationHistoryEntry,
  UserConversationHistoryContextType,
} from 'interfaces/conversations';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button } from 'reactstrap';
import { useCaseParticipant } from '../../hooks/useCaseParticipant';
import { useConversationItemUtils } from './hooks/useConversationItemUtils';

type ChangeCaseConversationItemProps = {
  conversation: IUserConversationHistoryEntry;
};

const ChangeCaseConversationItem = ({
  conversation,
}: ChangeCaseConversationItemProps) => {
  const {
    conversationTemplateDisplayCode,
    formatConversationHistoryHour,
    formatConversationHistoryDate,
    handleSelectConversation,
  } = useConversationItemUtils(conversation);

  const { name } = useCaseParticipant();

  const contextTypeOption = userConversationContextTypeOptions.find(
    (p) => p.value === conversation.contextType
  );

  return (
    <tr
      key={`${conversation.hour}|${conversation.year}|${conversation.month}|${conversation.day}|${conversation.userId}`}
    >
      <td valign="middle">
        <b>{name ?? '--'}</b>
      </td>
      <td valign="middle">
        {contextTypeOption ? (
          <FormattedMessage id={contextTypeOption.translationId} />
        ) : (
          ''
        )}
      </td>
      <td valign="middle">{conversationTemplateDisplayCode}</td>
      <td valign="middle">
        {`${formatConversationHistoryDate} ${
          conversation.contextType ===
            UserConversationHistoryContextType.Schedules ||
          conversation.contextType ===
            UserConversationHistoryContextType.BasicConversations
            ? formatConversationHistoryHour(conversation.hour)
            : conversation.hour
        }`}
      </td>
      <td
        valign="middle"
        width="30px"
        align={'center'}
        onClick={handleSelectConversation}
      >
        <Button color="primary" outline={true}>
          <FontAwesomeIcon icon={faCommentsAlt} />
        </Button>
      </td>
    </tr>
  );
};

export default ChangeCaseConversationItem;
