import { Breadcrumbs } from '@mui/material';
import React, { PropsWithChildren } from 'react';
import BreadcrumbSeparator from '../../../../BreadcrumbSeparator';
import { useTabTitle } from '../../hooks/useTabTitle';
import { BreadcrumbTabLayoutHelpers } from './helpers';

type BreadcrumbTabLayoutProps = {
  titlePrefix?: string;
  actions?: JSX.Element;
};

const BreadcrumbTabLayout = ({
  titlePrefix,
  actions,
  children,
}: PropsWithChildren<BreadcrumbTabLayoutProps>) => {
  const tabTitle = useTabTitle(titlePrefix);
  const breadcrumbs = BreadcrumbTabLayoutHelpers.GetBreadcrumbs(tabTitle);

  return (
    <div className="d-flex flex-column p-4">
      <div className="d-flex align-items-center justify-content-between">
        <Breadcrumbs
          aria-label="breadcrumb"
          separator={<BreadcrumbSeparator />}
        >
          {breadcrumbs}
        </Breadcrumbs>
        <div>{actions}</div>
      </div>
      <div className="mt-4">{children}</div>
    </div>
  );
};

export default BreadcrumbTabLayout;
