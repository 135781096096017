import { signIn } from 'api/membership';
import ToastBodyWithCallback from 'components/ToastBodyWithCallback';
import { WRONG_PASSWORD_ERROR_CODE } from 'helpers/constants';
import useLoginRedirect from 'hooks/useLoginRedirect';
import { ILoginResponse, LoginMethod } from 'interfaces/membership';
import { IErrorWithCode } from 'interfaces/response';
import { resendEmailAction } from 'modules/membership/ResendEmail/actions';
import { displayErrorNotification } from 'modules/Notifications';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useDispatch } from 'react-redux';
import { useApplyUserData } from './useApplyUserData';
import { isValidEmail } from '../../../helpers/utils/strings';

type LoginParams = {
  username: string;
  password: string;
};

type UseLoginProps = {
  loginAs: LoginMethod;
  redirectEnabled?: boolean;
  onSuccess?: (data: ILoginResponse) => void;
  resetOnResult?: boolean;
};

const NOT_CONFIRMED_CODE = 'user_email_not_confirmed_error';

function useLogin({
  loginAs,
  redirectEnabled = true,
  resetOnResult = true,
  onSuccess,
}: UseLoginProps) {
  const dispatch = useDispatch();
  const redirectFunction = useLoginRedirect();
  const applyUserData = useApplyUserData();

  let usernameResend = '';

  const result = useMutation<ILoginResponse, IErrorWithCode, LoginParams>(
    async ({ username, password }) => {
      usernameResend = username;

      const { data } = await signIn({
        email: username,
        password,
        application_type: 'Web',
      });

      return data.data;
    }
  );

  useEffect(() => {
    const errors = result.error?.data?.meta?.errors ?? [];

    const handleClick = (closeToast: any) =>
      dispatch(resendEmailAction(usernameResend, closeToast));

    if (result.isError) {
      if (errors.length > 0) {
        for (const singleErr of errors) {
          switch (singleErr.code) {
            case NOT_CONFIRMED_CODE:
              const validEmail = isValidEmail(usernameResend);

              if (validEmail) {
                displayErrorNotification(
                  <ToastBodyWithCallback
                    text="Login.notifications.errors.not_confirmed_1"
                    buttonText="Login.notifications.errors.not_confirmed_2"
                    handleClick={handleClick}
                  />,
                  { autoClose: false, closeOnClick: false }
                );
                return;
              }

              displayErrorNotification(
                'Login.notifications.errors.not_confirmed_1'
              );

              break;
            case WRONG_PASSWORD_ERROR_CODE:
              displayErrorNotification(
                'Login.notifications.errors.wrong_credentials'
              );
              break;
            default:
              displayErrorNotification('Errors.default');
              break;
          }
        }
      }

      result.reset();
    }
  }, [result, dispatch, usernameResend]);

  useEffect(() => {
    if (result.isSuccess && result.data) {
      const redirectUrl = localStorage.getItem('redirectUrl');

      let filteredOrganizations = result.data.organizations.filter(
        (singleOrg) =>
          singleOrg.role.includes('grasp_admin') ||
          singleOrg.role.includes('grasp_manager') ||
          singleOrg.role.includes('organization_admin')
      );

      const hasAtLeastOneOrganization =
        filteredOrganizations && filteredOrganizations.length > 0;

      if (
        !hasAtLeastOneOrganization &&
        loginAs === LoginMethod.AsMedicalPersonnel
      ) {
        displayErrorNotification('Errors.useDifferentLoginMethod');
        resetOnResult && result.reset();
        return;
      }

      if (redirectEnabled) {
        redirectFunction(result.data, redirectUrl, loginAs);
        onSuccess?.(result.data);
        resetOnResult && result.reset();
        return;
      }

      onSuccess?.(result.data);
      resetOnResult && result.reset();
    }
  }, [
    result,
    dispatch,
    redirectFunction,
    loginAs,
    redirectEnabled,
    applyUserData,
    onSuccess,
    resetOnResult,
  ]);

  return result;
}

export default useLogin;
