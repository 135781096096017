import { faEnvelope, faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Button, FormGroup, Label } from 'reactstrap';
import caseIcon from '../../../../../../assets/images/case_icon.svg';
import InfiniteListLayout from '../../../../../../components/InfiniteList/InfiniteListLayout';
import FunctionalityRouteGuard from '../../../../../../components/routes/FunctionalityRouteGuard';
import { useOrganizationPathParams } from '../../../../../../hooks/paths/useOrganizationPathParams';
import useOpen from '../../../../../../hooks/useOpen';
import {
  GroupSelectionIdentifiers,
  useRowSelection,
} from '../../../../../../hooks/useRowSelection';
import { ICaseFilters } from '../../../../../../interfaces/cases';
import { FunctionalityCode } from '../../../../../../interfaces/functionalities';
import { useCaseListFilters } from '../../../../../../store/reducerHooks';
import CaseCardModal from '../../../../modals/CaseCardModal';
import { setCasesListFilters } from '../../../../store/actions';
import CasesTable from './components/CasesTable';
import GenericCasesFilter from './components/CasesTable/components/CasesFilter';

const CasesListView = () => {
  const filters = useCaseListFilters();
  const dispatch = useDispatch();

  const { organizationId } = useOrganizationPathParams();
  const { isOpen, toggle } = useOpen();

  const [totalItemsCount, setTotalItemsCount] = useState<number>(0);

  const rowSelection =
    useRowSelection<GroupSelectionIdentifiers>(totalItemsCount);

  const handleCasesFilter = (caseFilter: ICaseFilters | null) => {
    rowSelection.resetSelection();
    dispatch(setCasesListFilters(caseFilter));
  };

  const { isOpen: isCaseRequestModalOpen, toggle: toggleCaseRequestModal } =
    useOpen();

  return (
    <InfiniteListLayout
      labelTranslationId={'CaseCard.searchText'}
      titleTranslationId={'UserDashboard.cases'}
      inputIconSrc={caseIcon}
      additionalLeftContent={
        <FormGroup>
          <Label>
            <FormattedMessage id="Cases.filter" />
          </Label>
          <GenericCasesFilter
            organizationId={organizationId}
            filters={filters}
            setFilters={handleCasesFilter}
          />
        </FormGroup>
      }
      additionalRightContent={
        <div className="mb-3 d-flex gap-2">
          <Button
            outline={true}
            onClick={toggleCaseRequestModal}
            color="primary"
          >
            <FontAwesomeIcon icon={faSearch} />
          </Button>
          <FunctionalityRouteGuard
            code={FunctionalityCode.CASE_LIST_SEND_MESSAGE_GROUP}
          >
            <Button
              outline={true}
              color="primary"
              onClick={toggle}
              disabled={rowSelection.isEmptySelection}
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </Button>
          </FunctionalityRouteGuard>
          <React.Suspense fallback={null}>
            <CaseCardModal />
          </React.Suspense>
        </div>
      }
    >
      <CasesTable
        setTotalItemsCount={setTotalItemsCount}
        isCaseRequestModalOpen={isCaseRequestModalOpen}
        toggleCaseRequestModalOpen={toggleCaseRequestModal}
        filters={filters}
        isOpen={isOpen}
        rowSelection={rowSelection}
        toggle={toggle}
      />
    </InfiniteListLayout>
  );
};

export default CasesListView;
