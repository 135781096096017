import { IQueryRequest } from '../../../interfaces/request';
import { useQuery } from 'react-query';
import { getCaseConversationSchedules } from '../../conversationSchedules';
import QueryKeys from '../queryKeys';
import { CaseQueryParams } from '../../../interfaces/queryParams';

const useCaseGroupConversationSchedules = ({
  params,
  options,
}: IQueryRequest<CaseQueryParams>) => {
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getCaseConversationSchedules(
        params.organizationId,
        params.caseId
      );

      return data.data;
    },
    queryKey: [
      QueryKeys.getCaseConversationSchedules,
      params.organizationId,
      params.caseId,
    ],
  });
};

export default useCaseGroupConversationSchedules;
