import React, { memo } from 'react';
import QuestionFromScratchAction from './components/QuestionFromScratchAction';
import QuestionBasedOnExistingAction from './components/QuestionBasedOnExistingAction';
import UseExistingQuestionAction from './components/UseExistingQuestionAction';

type QuestionActionsProps = {
  id: string;
  onResolve: () => void;
};

const QuestionActions = ({ id, onResolve }: QuestionActionsProps) => {
  return (
    <div className="d-flex align-items-center gap-2">
      <UseExistingQuestionAction id={id} onResolve={onResolve} />
      <QuestionFromScratchAction id={id} onResolve={onResolve} />
      <QuestionBasedOnExistingAction id={id} onResolve={onResolve} />
    </div>
  );
};

export default memo(QuestionActions);
