import { useCallback, useEffect, useState } from 'react';

export const useInnerWidth = () => {
  const [width, setWidth] = useState(() => {
    return window.innerWidth;
  });

  const onResize = useCallback((e) => {
    setWidth(e.target.outerWidth);
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('resize', onResize);
      window.addEventListener('fullscreenchange', onResize);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('resize', onResize);
        window.removeEventListener('fullscreenchange', onResize);
      }
    };
  }, [onResize]);

  return width;
};
