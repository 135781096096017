import { useMemo } from 'react';
import { GraspEventItemData, GraspEventItemType } from 'interfaces/timeline';
import { formatTime } from 'helpers/dates';
import { isValid } from 'date-fns';
import SVG from 'react-inlinesvg';
import { returnIconBasedOnObservationMeaningAndType } from 'helpers/utils/icons';
import classNames from 'classnames';
import GraspEventItemChildren from './GraspEventItemChildren';
import { observationMeaningOptions } from 'helpers/utils/translationObject';
import { FormattedMessage } from 'react-intl';

type GraspEventItemProps = {
  data: GraspEventItemData;
  timelineId: string;
  selectedDates: {
    from: Date;
    to: Date;
  };
  expanded: boolean;
  expandEventItem: (id: string) => void;
};

const GraspEventItem = ({
  data,
  timelineId,
  selectedDates,
  expanded,
  expandEventItem,
}: GraspEventItemProps) => {
  let startDateText = data.beginDate ? formatTime(data.beginDate, true) : '-';

  const isDateNullable = (date: Date) =>
    !isValid(date) || date.toISOString().substring(0, 10) === '0001-01-01';

  let endDateText = '-';

  if (data.endDate) {
    endDateText = isDateNullable(data.endDate)
      ? ''
      : formatTime(data.endDate, true);
  }

  const average_level = useMemo(() => {
    let sum = data.children_observations.reduce(
      (prev, current) => (current.level ?? 0) + prev,
      0
    );
    let count = data.children_observations.length;

    if (data.level) {
      sum += data.level;
      count += 1;
    }

    return count !== 0 ? sum / count : 0;
  }, [data.children_observations, data.level]);

  const average_duration = useMemo(() => {
    let sum = data.children_observations.reduce(
      (prev, current) => (current.duration_in_seconds ?? 0) + prev,
      0
    );

    let count = data.children_observations.length;

    if (data.duration_in_seconds) {
      sum += data.duration_in_seconds;
      count += 1;
    }

    return count !== 0 ? sum / count : 0;
  }, [data.children_observations, data.duration_in_seconds]);

  const handleExpandEventItem = () => {
    expandEventItem(data.id);
  };

  const renderTitle = useMemo(() => {
    if (data.type === GraspEventItemType.ANNOTATION) {
      return (
        <span className="GraspEventItem__description__title GraspEventItem__description__title--annotation">
          <FormattedMessage id="TimelineDetails.dayDetails.annotation" />
        </span>
      );
    }

    if (data.type === GraspEventItemType.CASE_HISTORY) {
      return (
        <span className="GraspEventItem__description__title GraspEventItem__description__title--case-history">
          <FormattedMessage id="TimelineDetails.dayDetails.doctors-note" />
        </span>
      );
    }

    if (data.is_group) {
      return (
        <div>
          <FormattedMessage
            id={
              observationMeaningOptions.filter(
                (p) => p.value === data.meaning
              )[0].translationId
            }
          />
          <span className="ps-1 pe-1">|</span>
          <div
            className={classNames(
              'GraspEventItem__description__title',
              'GraspEventItem__description__title--light',
              {
                [`GraspEventItem__description__title--${data.meaning}`]:
                  data.type === GraspEventItemType.OBSERVATION,
              }
            )}
          >
            <span className="pe-1">
              {data.children_observations.length + 1}
            </span>
            <FormattedMessage id="ObservationMeaning.graspEvents" />
          </div>
        </div>
      );
    }

    return (
      <FormattedMessage
        id={
          observationMeaningOptions.filter((p) => p.value === data.meaning)[0]
            .translationId
        }
      />
    );
  }, [data]);

  return (
    <div>
      <div
        onClick={
          data.children_observations.length !== 0
            ? handleExpandEventItem
            : data.onEditClick
        }
        className="GraspEventItem"
      >
        <div className="GraspEventItem__icon">
          <div
            className={classNames(
              'GraspEventItem__icon__container',
              {
                [`GraspEventItem__icon__container--${data.meaning}`]:
                  data.type === GraspEventItemType.OBSERVATION,
              },
              {
                'GraspEventItem__icon__container--annotation':
                  data.type === GraspEventItemType.ANNOTATION,
              },
              {
                'GraspEventItem__icon__container--case-history':
                  data.type === GraspEventItemType.CASE_HISTORY,
              }
            )}
          >
            <SVG
              src={returnIconBasedOnObservationMeaningAndType(
                data.type,
                data.meaning
              )}
            />
          </div>
        </div>
        <div className="GraspEventItem__description">
          <div
            className={classNames(
              'GraspEventItem__description__title',
              {
                [`GraspEventItem__description__title--${data.meaning}`]:
                  data.type === GraspEventItemType.OBSERVATION,
              },
              {
                'GraspEventItem__description__title--annotation':
                  data.type === GraspEventItemType.ANNOTATION,
              },
              {
                'GraspEventItem__description__title--case-history':
                  data.type === GraspEventItemType.CASE_HISTORY,
              }
            )}
          >
            {renderTitle}
          </div>
          {data.type === GraspEventItemType.OBSERVATION && (
            <>
              {data.is_group ? (
                <span className="GraspEventItem__description__event-properties">
                  Avg. level: {average_level.toFixed(2)}, Avg. duration:{' '}
                  {average_duration.toFixed(2)}s
                </span>
              ) : (
                <>
                  {data.level && data.duration_in_seconds && (
                    <span className="GraspEventItem__description__event-properties">
                      Level: {data.level.toFixed(2)}, Duration:{' '}
                      {data.duration_in_seconds.toFixed(2)}s
                    </span>
                  )}
                </>
              )}
            </>
          )}
          {data.type !== GraspEventItemType.OBSERVATION && (
            <div className="GraspEventItem__description__additional-info">
              {data.text}
            </div>
          )}
        </div>
        <div className="GraspEventItem__hour-range">
          <div className="GraspEventItem__hour-range__start">
            {startDateText}
          </div>
          {data.is_group && (
            <div className="GraspEventItem__hour-range__end">{endDateText}</div>
          )}
        </div>
      </div>
      {expanded && (
        <div className="ms-3 mt-2">
          <GraspEventItemChildren
            timelineId={timelineId}
            startDateText={startDateText}
            endDateText={endDateText}
            selectedDates={selectedDates}
            children_observations={[
              ...data.children_observations,
              {
                beginDate: data.beginDate,
                endDate: data.endDate,
                id: data.id,
                type: GraspEventItemType.OBSERVATION,
                meaning: data.meaning,
                children_observations: [],
                description: data.description,
                is_group: false,
                level: data.level,
                duration_in_seconds: data.duration_in_seconds,
                text: data.text,
                onEditClick: data.onEditClick,
              },
            ]}
          />
        </div>
      )}
    </div>
  );
};

export default GraspEventItem;
