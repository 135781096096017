import { createContext } from 'react';
import { ScheduleItemDefinition } from '../../../../../../../../../../../../../../components/Schedule';
import {
  CaseNotificationSchedule,
  CaseNotificationScheduleType,
} from '../../../../../../../../../../../../../../interfaces/notificationSchedules';

export const CaseNotificationSchedulesContext = createContext<
  CaseNotificationSchedule[] | undefined
>(undefined);

export type SingleScheduleMergingGroup = {
  notification_type: CaseNotificationScheduleType;
  specific_day_of_phase: number;
  items: ScheduleItemDefinition[];
};
