import { ZoomModeConfig } from 'modules/TimelineDetails/components/tabs/TrendsSummary/components/AssessmentFormChart/AssessmentFormChart';
import { useState } from 'react';

function useChartZoom(initialZoomModeConfig: ZoomModeConfig) {
  const [zoomModeConfig, setZoomModeConfig] = useState<ZoomModeConfig>(
    initialZoomModeConfig
  );

  const zoomHandler = (formattedChartData) => {
    if (
      zoomModeConfig.refAreaLeft !== undefined &&
      zoomModeConfig.refAreaRight !== undefined
    ) {
      let leftIndex = formattedChartData.findIndex(
        (p) => p.name === zoomModeConfig.refAreaLeft
      );

      let rightIndex = formattedChartData.findIndex(
        (p) => p.name === zoomModeConfig.refAreaRight
      );

      if (rightIndex < leftIndex) {
        const temp = rightIndex;
        rightIndex = leftIndex;
        leftIndex = temp;
      }

      if (leftIndex !== -1 && rightIndex !== -1 && leftIndex !== rightIndex) {
        setZoomModeConfig((prev) => ({
          ...prev,
          refAreaLeft: undefined,
          refAreaRight: undefined,
          left: leftIndex,
          right: rightIndex,
        }));
      }
    }
  };

  const handleMouseDown = (e: any) => {
    if (e && e.activeLabel) {
      setZoomModeConfig((prev) => ({
        ...prev,
        refAreaLeft: e.activeLabel,
      }));
    }
  };

  const handleMouseMove = (e: any) => {
    if (zoomModeConfig.refAreaLeft && e && e.activeLabel) {
      setZoomModeConfig((prev) => ({
        ...prev,
        refAreaRight: e.activeLabel,
      }));
    }
  };

  const handleResetZoom = () => {
    setZoomModeConfig((prev) => ({
      ...prev,
      left: undefined,
      right: undefined,
      refAreaLeft: undefined,
      refAreaRight: undefined,
    }));
  };

  return {
    handleResetZoom,
    handleMouseDown,
    handleMouseMove,
    zoomHandler,
    zoomModeConfig,
  };
}

export default useChartZoom;
