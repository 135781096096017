import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { IErrorDefinition } from '../../../interfaces/response';
import { validateImportedConversationTemplate } from '../../conversations';
import { ITypedMutationRequest } from '../../../interfaces/request';
import { OrganizationQueryParams } from '../../../interfaces/queryParams';
import {
  ConversationTemplateValidationBody,
  ConversationTemplateValidationResult,
} from '../../../interfaces/conversations';

const useValidateImportedConversationTemplate = ({
  params,
  options,
  silent,
}: ITypedMutationRequest<
  OrganizationQueryParams,
  ConversationTemplateValidationResult
>) => {
  const mutationData = useMutationWithResultNotification({
    asyncApiFunction: async (body: ConversationTemplateValidationBody) => {
      const { data } = await validateImportedConversationTemplate(
        params.organizationId,
        body
      );

      return data.data;
    },
    onSuccess: () => {
      if (!silent) {
        displaySuccessNotification(
          'Api.Mutations.Conversations.ValidateImportedConversationTemplate.success'
        );
      }

      mutationData.data && options.successFb?.(mutationData.data);
    },
    onError: (error: any) => {
      if (!silent) {
        displayErrorNotification(
          'Api.Mutations.Conversations.ValidateImportedConversationTemplate.failure'
        );
      }

      options.errorFb?.(error?.data?.meta?.errors ?? []);
    },
    onErrorArray: (errorArray: IErrorDefinition[]) => {
      if (!silent) {
        errorArray.forEach((item) => {
          displayErrorNotification(
            item.reasons.at(0) ??
              'Api.Mutations.Conversations.ValidateImportedConversationTemplate.failure'
          );
        });
      }

      options.errorFb?.(errorArray);
    },
    resetOnResult: true,
  });

  return mutationData;
};

export default useValidateImportedConversationTemplate;
