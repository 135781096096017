import React from 'react';

type BuddyCaseSectionProps = {
  icon: JSX.Element;
  content: JSX.Element;
  title: string;
  additional?: JSX.Element;
};

const BuddyCaseSection = ({
  icon,
  title,
  content,
  additional,
}: BuddyCaseSectionProps) => {
  return (
    <div className="BuddyCaseSection">
      <div className="BuddyCaseSection__header">
        <div className="BuddyCaseSection__icon">{icon}</div>
        <span className="BuddyCaseSection__title">{title}</span>
        {!!additional && additional}
      </div>
      <div className="BuddyCaseSection__content">{content}</div>
    </div>
  );
};

export default BuddyCaseSection;
