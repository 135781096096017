import { Box, LinearProgress } from '@mui/material';
import {
  GridCallbackDetails,
  GridFilterModel,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import CustomDataGrid from 'components/CustomDataGrid';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import InfiniteListLayoutLoader from '../../../../../../../../components/InfiniteList/InfiniteListLayoutLoader';
import { useColumns } from './hooks/useColumns';
import { useGraspsData } from './hooks/useGraspsData';

const TransferGraspTable = () => {
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const {
    grasps,
    totalItemsCount,
    isLoading,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useGraspsData(searchValue);

  const columns = useColumns(refetch);

  const handleFilterModelChange = (
    model: GridFilterModel,
    _: GridCallbackDetails
  ) => {
    setSearchValue(model.quickFilterValues?.at(0) ?? undefined);
  };

  const hasMore = !isLoading && hasNextPage;

  const loadMore = async () => await fetchNextPage();

  return (
    <div style={{ height: 500, overflow: 'auto' }}>
      <InfiniteScroll
        loadMore={loadMore}
        hasMore={hasMore}
        loader={<InfiniteListLayoutLoader />}
        threshold={250}
        useWindow={false}
        initialLoad={false}
      >
        <div className="position-relative">
          <CustomDataGrid
            sx={{ '& .MuiTablePagination-displayedRows': { marginBottom: 0 } }}
            disableColumnFilter
            disableColumnSelector
            disableDensitySelector
            rowHeight={64}
            rows={grasps}
            columns={columns}
            rowCount={totalItemsCount}
            onFilterModelChange={handleFilterModelChange}
            filterMode="server"
            loading={isLoading}
            slots={{
              toolbar: QuickSearchToolbar,
              loadingOverlay: LinearProgress,
            }}
          />
        </div>
      </InfiniteScroll>
    </div>
  );
};

const QuickSearchToolbar = () => {
  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        p: 2,
        position: 'sticky',
        top: 0,
        zIndex: 1,
      }}
    >
      <GridToolbarQuickFilter
        inputProps={{ sx: { paddingTop: '0.5rem', paddingBottom: '0.5rem' } }}
        variant="outlined"
        fullWidth={true}
        debounceMs={1000}
      />
    </Box>
  );
};

export default TransferGraspTable;
