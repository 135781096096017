import { ProcedureField } from '../../../forms/AwareCaseDetailsForm/types';
import { useMemo } from 'react';
import { useMsDiffs } from '../../ProcedureEventsTimeline/hooks/useMsDiffs';
import useCultures from 'hooks/cultures/useCultures';

export type ProcedureOffset = {
  name: string;
  sequence: number;
  startOffset: number;
  width: number;
  onClick: () => void;
};

export const useProceduresOffsets = (
  startDate: string,
  endDate: string,
  procedures: ProcedureField[],
  onClick: (index: number) => void
) => {
  const { currentCulture, defaultCulture } = useCultures();
  const { baseMsDiff, getMsDiff } = useMsDiffs(startDate, endDate);

  return useMemo((): ProcedureOffset[] => {
    return procedures.map((procedure, index) => {
      const handleClick = () => onClick(index);

      return {
        sequence: index,
        name:
          currentCulture.cultureCode === defaultCulture.cultureCode
            ? procedure.nameEn
            : procedure.nameNo,
        startOffset: getMsDiff(startDate, procedure.timeStart) / baseMsDiff,
        width: getMsDiff(procedure.timeStart, procedure.timeEnd) / baseMsDiff,
        onClick: handleClick,
      };
    });
  }, [
    baseMsDiff,
    currentCulture.cultureCode,
    defaultCulture.cultureCode,
    getMsDiff,
    onClick,
    procedures,
    startDate,
  ]);
};
