import React from 'react';
import useDashboardDataQuery from '../../../../../../../../../../../../api/queries/dashboards/useDashboardDataQuery';
import { useOrganizationPathParams } from '../../../../../../../../../../../../hooks/paths/useOrganizationPathParams';
import { useInitQuery } from '../../../../../../../../../../../../hooks/queries/useInitQuery';
import { useTypedContext } from '../../../../../../../../../../../../hooks/useTypedContext';
import { DashboardTemplateDataSource } from '../../../../../../../../../../../../interfaces/dashboards';
import {
  IReferenced,
  ITotalRowsPage,
} from '../../../../../../../../../../../../interfaces/response';
import QueryLoaded from '../../../../../../../../../../../QueryLoaded';
import { DashboardDataReducerContext } from '../../../../../../types';

type QueryBasedDataProviderProps = {
  children: (items: IReferenced<ITotalRowsPage<any>>) => JSX.Element;
};

const QueryBasedDataProvider = ({ children }: QueryBasedDataProviderProps) => {
  const [dashboardData] = useTypedContext(DashboardDataReducerContext);

  if (dashboardData.data_source !== DashboardTemplateDataSource.DataQuery)
    return children({
      current: { data: [], total_rows_count: 0 },
      reference: { data: [], total_rows_count: 0 },
    });

  return <QueryBasedDataQueryProvider children={children} />;
};

const QueryBasedDataQueryProvider = ({
  children,
}: QueryBasedDataProviderProps) => {
  const { organizationId } = useOrganizationPathParams();
  const [dashboardData] = useTypedContext(DashboardDataReducerContext);

  const queryBasedData = useDashboardDataQuery({
    params: {
      organizationId,
      range: {
        from: dashboardData.template.date_selection.date_from,
        to: dashboardData.template.date_selection.date_to,
      },
      templateId: dashboardData.id,
    },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(queryBasedData);

  return (
    <QueryLoaded query={queryBasedData}>{(data) => children(data)}</QueryLoaded>
  );
};

export default QueryBasedDataProvider;
