import React, { useState } from 'react';
import { useTheme } from '@mui/material';
import {
  AwareCaseDetailsFormValues,
  ProcedureActivity,
} from '../../../../forms/AwareCaseDetailsForm/types';
import { secondsToDurationString } from '../../../../../../../../../../helpers/dates';
import { useMsDiffs } from '../../hooks/useMsDiffs';
import ManageActivityModal from '../../../../modals/ManageActivityModal';
import useOpen from '../../../../../../../../../../hooks/useOpen';
import { FormikProps } from 'formik';
import { useCurrentEventName } from '../../hooks/useCurrentEventName';

type EventPointProps = {
  startDate: string;
  sequence: number;
  procedureEvent: ProcedureActivity;
  eventsPositions: number[];
  level: number;
  form: FormikProps<AwareCaseDetailsFormValues>;
};

const EventPoint = ({
  startDate,
  procedureEvent,
  eventsPositions,
  sequence,
  level,
  form,
}: EventPointProps) => {
  const theme = useTheme();

  const getCurrentEventName = useCurrentEventName();

  const manageActivity = useOpen();

  const [activityIndex, setActivityIndex] = useState<number | undefined>(
    undefined
  );

  const { getMsDiff, baseMsDiff } = useMsDiffs(
    startDate,
    procedureEvent.timeStart
  );

  const handleActivityOpen = (event: any) => {
    event.stopPropagation();

    let _activityIndex: number | undefined = undefined;

    form.values.procedures.forEach((procedure) => {
      const idx = procedure.activities.findIndex(
        (p) => getMsDiff(startDate, p.timeStart) === baseMsDiff
      );

      if (idx === -1) return;

      _activityIndex = idx;
    });

    if (_activityIndex === undefined) return;

    setActivityIndex(_activityIndex);
    manageActivity.open();
  };

  const handleActivityToggle = () => {
    setActivityIndex(undefined);
    manageActivity.close();
  };

  return (
    <>
      {manageActivity.isOpen && activityIndex !== undefined && (
        <ManageActivityModal
          isOpen={manageActivity.isOpen}
          toggle={handleActivityToggle}
          msPoint={baseMsDiff}
          activityIndex={activityIndex}
          form={form}
        />
      )}
      <div
        className="absolute flex flex-col items-center"
        style={{
          top: '-5px',
          left: `${eventsPositions[sequence]}%`,
        }}
      >
        <div
          className="!w-3 !h-3 rounded-full border-2"
          style={{
            backgroundColor: procedureEvent.color,
            borderColor: theme.palette.background.paper,
          }}
        />
        <div
          className="relative"
          style={{
            backgroundColor: procedureEvent.color,
            width: '2px',
            height: `${level * 40}px`,
          }}
        >
          <div
            className="absolute -bottom-1 left-1 overflow-hidden flex flex-col flex-nowrap text-nowrap"
            style={{ width: 'fit-content' }}
            onClick={handleActivityOpen}
          >
            <span className="text-sm">
              {getCurrentEventName(procedureEvent)}
            </span>
            <span className="text-sm">
              {secondsToDurationString(baseMsDiff / 1000)}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventPoint;
