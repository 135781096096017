import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@mui/material';
import type { GridColDef } from '@mui/x-data-grid/models/colDef/gridColDef';
import React, { useMemo, useState } from 'react';
import { useFilterColumns } from '../CustomDataGrid/hooks/useFilterColumns';
import { CustomTableResponsiveConfig } from '../CustomTable';
import Placeholders from '../Placeholders';
import CustomTableCell from './components/CustomTableCell';
import CustomTableRow from './components/CustomTableRow';
import { CustomMaterialTableHelpers } from './helpers';
import { Order } from './types';

type CustomMaterialTableProps = {
  onRowClick?: (
    id: string
  ) => (_: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => void;
  rows: any[];
  columns: GridColDef[];
  responsiveConfig?: CustomTableResponsiveConfig[];
  rowHeight?: number;
};

const CustomMaterialTable = ({
  rows,
  columns,
  onRowClick,
  responsiveConfig,
  rowHeight = 36,
}: CustomMaterialTableProps) => {
  const [order, setOrder] = useState<Order | null>(null);
  const [orderBy, setOrderBy] = useState<string | null>(null);

  const handleRequestSort = (
    _: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isNull = orderBy === null && order === null;

    if (isNull) {
      setOrder('asc');
      setOrderBy(property);
      return;
    }

    const isAsc = orderBy === property && order === 'asc';

    if (isAsc) {
      setOrder('desc');
      setOrderBy(property);
      return;
    }

    setOrder(null);
    setOrderBy(null);
  };

  const visibleRows = useMemo(() => {
    if (orderBy === null || order === null) return rows;

    return CustomMaterialTableHelpers.StableSort(
      rows,
      CustomMaterialTableHelpers.GetComparator(order, orderBy)
    );
  }, [order, orderBy, rows]);

  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      handleRequestSort(event, property);
    };

  const filteredColumns = useFilterColumns(columns, responsiveConfig);

  return (
    <TableContainer sx={{ backgroundColor: 'transparent' }} component={Paper}>
      <Table sx={{ minWidth: 650 }}>
        <TableHead>
          <TableRow>
            {filteredColumns.map((column) => (
              <CustomTableCell
                key={column.field}
                sortDirection={
                  order && orderBy === column.field ? order : false
                }
              >
                <TableSortLabel
                  active={orderBy === column.field}
                  direction={order && orderBy === column.field ? order : 'asc'}
                  onClick={createSortHandler(column.field)}
                >
                  {column.headerName}
                </TableSortLabel>
              </CustomTableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {visibleRows.length === 0 && (
            <CustomTableRow sx={{ height: '30vh' }}>
              <CustomTableCell colSpan={filteredColumns.length}>
                <Placeholders.EmptyTable />
              </CustomTableCell>
            </CustomTableRow>
          )}
          {visibleRows.map((row) => (
            <CustomTableRow
              sx={{ height: rowHeight }}
              onClick={onRowClick?.(row.id)}
              key={row.id}
            >
              {filteredColumns.map((column) => (
                <CustomTableCell key={`${row.id}-${column.field}`}>
                  {row[column.field]}
                </CustomTableCell>
              ))}
            </CustomTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default CustomMaterialTable;
