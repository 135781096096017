import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getCaseGroupConversationSchedules } from '../../conversationSchedules';
import { OrganizationQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

type UseCaseGroupConversationSchedulesParams = OrganizationQueryParams & {
  groupId: string;
  phaseId?: string;
  dateFrom?: Date;
  dateTo?: Date;
};

const useCaseGroupConversationSchedules = ({
  params,
  options,
}: IQueryRequest<UseCaseGroupConversationSchedulesParams>) => {
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getCaseGroupConversationSchedules(
        params.organizationId,
        params.groupId,
        params.phaseId,
        params.dateFrom,
        params.dateTo
      );

      return data.data;
    },
    queryKey: [
      QueryKeys.getCaseGroupConversationSchedules,
      params.organizationId,
      params.groupId,
      params.phaseId,
      params.dateFrom,
      params.dateTo,
    ],
  });
};

export default useCaseGroupConversationSchedules;
