import { getOrganizationAssessmentFormsTemplates } from 'api/graspManagement';
import QueryKeys from 'api/queries/queryKeys';
import { GetAssessmentFormsTemplatesQueryParams } from 'interfaces/assessmentForms';
import { useQuery } from 'react-query';
import { OrganizationQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

interface UseOrganizationAssessmentFormsTemplatesParams
  extends OrganizationQueryParams {
  questionsIncludedInTrends: boolean | null;
  allowedForCaseId: string | null;
}

function UseOrganizationAssessmentFormsTemplates({
  params,
  options,
}: IQueryRequest<UseOrganizationAssessmentFormsTemplatesParams>) {
  const { allowedForCaseId, organizationId, questionsIncludedInTrends } =
    params;
  return useQuery({
    ...options,
    queryKey: [
      QueryKeys.getOrganizationAssessmentFormsTemplate,
      organizationId,
      allowedForCaseId,
      questionsIncludedInTrends,
    ],
    queryFn: async () => {
      const params: GetAssessmentFormsTemplatesQueryParams = {
        allowedForCaseId,
        questionsIncludedInTrends,
      };

      const { data: responseData } =
        await getOrganizationAssessmentFormsTemplates(organizationId, params);
      return responseData.data;
    },
  });
}

export default UseOrganizationAssessmentFormsTemplates;
