import { useCallback } from 'react';
import { useInitQuery } from '../../../../../../../../../../../hooks/queries/useInitQuery';
import { useCommonData } from '../../../../../../../hooks/useCommonData';
import { DashboardDefaults } from '../../../../../defaults';
import { DashboardColorsHelpers } from '../../../../../helpers/colors';

export const useSeriesColor = () => {
  const { organizationDetails } = useCommonData();
  useInitQuery(organizationDetails);

  const getColor = useCallback(
    (index: number, isSelected?: boolean) => {
      let colorPalette: string[] = DashboardDefaults.DEFAULT_COLOR_SCHEME;
      const organizationPalette =
        organizationDetails.data?.settings?.charts_color_palette;

      if (organizationPalette && organizationPalette.length > 0)
        colorPalette = organizationPalette;

      return DashboardColorsHelpers.GetColorForSeries(
        colorPalette,
        index,
        isSelected
      );
    },
    [organizationDetails.data?.settings?.charts_color_palette]
  );

  return { getColor };
};
