import { ISummarizedBaseCaseGroup } from '../../../../../../../interfaces/caseGroups';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

export const useNavigation = (caseGroup: ISummarizedBaseCaseGroup) => {
  const navigate = useNavigate();

  const navigateToCaseGroupDetails = useCallback(() => {
    navigate(
      `/organizations/${caseGroup.organization_id}/projects/${caseGroup.project_id}/case-groups/${caseGroup.id}`
    );
  }, [caseGroup.id, caseGroup.organization_id, caseGroup.project_id, navigate]);

  return {
    navigateToCaseGroupDetails,
  };
};
