import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { AmplitudeModalData } from 'interfaces/timeline';
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts';

type AmplitudeChartModalProps = {
  closeModal: () => void;
  data: AmplitudeModalData | null;
};

const AmplitudeChartModal = ({
  closeModal,
  data,
}: AmplitudeChartModalProps) => {
  const { amplitudes, painLevel, duration } = data || {};

  const formattedAmplitudes = useMemo(() => {
    if (!amplitudes) return [];

    const amplitudesWithZeroes = [0, ...amplitudes, 0];

    return (
      amplitudesWithZeroes?.map((val, index) => {
        return {
          name: `record-${index}`,
          val,
          negativeVal: val * -1,
        };
      }) ?? []
    );
  }, [amplitudes]);

  const isOpen = !!amplitudes;

  return (
    <Modal isOpen={isOpen} toggle={closeModal}>
      {formattedAmplitudes && formattedAmplitudes.length > 0 && (
        <>
          <ModalHeader>
            <FormattedMessage id="TimelineDetails.squeezeTime" />
            {' - '}
            {duration || 0} <FormattedMessage id="General.seconds" />
            <br />
            <FormattedMessage id="TimelineDetails.painScaleLevel" /> {' - '}
            {painLevel}
          </ModalHeader>
          <ModalBody>
            <ResponsiveContainer width={420} height={300}>
              <AreaChart data={formattedAmplitudes}>
                <YAxis tick={{ fontSize: '11px' }} />
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis tick={false} />
                <Area
                  type="monotone"
                  dataKey="val"
                  stroke="#237fc0"
                  fill="#237fc0"
                />
                <Area
                  type="monotone"
                  dataKey="negativeVal"
                  stroke="#237fc0"
                  fill="#237fc0"
                />
              </AreaChart>
            </ResponsiveContainer>
          </ModalBody>
        </>
      )}
    </Modal>
  );
};

export default AmplitudeChartModal;
