import Button from '@mui/material/Button';
import { FormikProps } from 'formik';
import React, { useCallback, useMemo } from 'react';
import { CultureHelpers } from '../../../../../../../../../../../../helpers/cultures';
import { IHelpCategoryItem } from '../../../../../../../../../../../../interfaces/help';
import { HelpCentreFormInitialValues } from '../../../../../../forms/HelpCentreForm/hooks/useForm';
import { HelpContactForm } from '../../../../../../types';

type HelpCategoryItemProps = {
  form: FormikProps<HelpCentreFormInitialValues>;
  item: IHelpCategoryItem;
};

const HelpCategoryItem = ({ item, form }: HelpCategoryItemProps) => {
  const translation = useMemo(
    () =>
      CultureHelpers.getValueAccordingToCulture(
        item.name_translations,
        form.initialValues.culture
      ),
    [form.initialValues.culture, item.name_translations]
  );

  const handleSelect = useCallback(() => {
    form.setValues((prev) => ({
      ...prev,
      topic: translation,
      contactForm: HelpContactForm.Phone,
      contactContext: '',
    }));

    form.setFieldTouched('contactContext', false, false);
  }, [form, translation]);

  return (
    <Button
      sx={{
        justifyContent: 'start',
        fontWeight: 400,
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
        textAlign: 'left',
      }}
      name="topic"
      value={translation}
      onClick={handleSelect}
      color="primary"
      variant={form.values.topic === translation ? 'contained' : 'outlined'}
    >
      {translation}
    </Button>
  );
};

export default HelpCategoryItem;
