import { NavigateFunction } from 'react-router';
import {
  formatDate,
  secondsToDurationString,
} from '../../../../../../../../../../../../helpers/dates';
import { Expressions } from '../../../../../../../../../../../../helpers/expressions';
import { DashboardTemplateColumn } from '../../../../../../../../../../../../interfaces/dashboards';
import { DashboardQueriesContextType } from '../../../../../../types';

export namespace TableViewHelpers {
  export const GetColumnValue = <T>(
    queriesData: DashboardQueriesContextType,
    item: T,
    column: DashboardTemplateColumn
  ) => {
    const scope = {
      organizations: queriesData.organizations,
      members: queriesData.members,
      questions: queriesData.questions,
      item,
      formatDate: formatDate,
      secondsToDurationString: secondsToDurationString,
    };

    return Expressions.Evaluate(column.reference, scope);
  };

  export const GetColumnClickEvent = (
    onClick: string,
    organizationId: string,
    rowId: string,
    navigate: NavigateFunction
  ) => {
    const scope = {
      organizationId,
      rowId,
      navigate,
    };

    return Expressions.Evaluate(onClick, scope);
  };
}
