import React, { useMemo, useState } from 'react';
import { ListGroup } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

//helpers
import useOpen from 'hooks/useOpen';

//components
import CaseCardModal from 'modules/Cases/modals/CaseCardModal';
import PersonalityCaseItem from './PersonalityCaseItem';
import MedicalPersonnelAssignModal from './MedicalPersonnelAssignModal';

//store
import { CaseHeaderWithMembers } from 'interfaces/cases';
import useModifyCaseMemberAccess from 'api/mutations/cases/useModifyCaseMemberAccess';
import { AccessType } from 'interfaces/enums';
import { useNavigate } from 'react-router';
import { MemberCaseAccess } from '../../../../../interfaces/caseAccesses';

type InfiniteCasesListProps = {
  organizationId: string;
  personalityId: string;
  cases?: CaseHeaderWithMembers[];
  refetchFunction: () => void;
};

const PersonalityCasesList = ({
  cases,
  organizationId,
  personalityId,
  refetchFunction,
}: InfiniteCasesListProps) => {
  const navigate = useNavigate();

  const { isOpen, toggle, open } = useOpen();
  const [assignedPersonalityIds, setAssignedPersonalityIds] = useState<
    string[]
  >([]);

  const [editedCase, setEditedCaseId] = useState<CaseHeaderWithMembers | null>(
    null
  );

  const renderCaseItems = useMemo(() => {
    if (!cases || cases.length === 0) {
      return <></>;
    }

    return cases.map((caseData) => {
      const redirectToCaseDetails = () =>
        navigate(`/organizations/${organizationId}/cases/${caseData.id}`);

      const onEditAssignment = () => {
        const personalityIds = caseData.members_with_access.map(
          (el) => el.member_personality_id
        );

        setAssignedPersonalityIds(personalityIds);
        setEditedCaseId(caseData);
        open();
      };

      return (
        <PersonalityCaseItem
          key={caseData.id}
          caseData={caseData}
          onEditAssignment={onEditAssignment}
          redirectToCaseDetails={redirectToCaseDetails}
          organizationId={organizationId}
        />
      );
    });
  }, [cases, navigate, open, organizationId]);

  const onSuccess = () => {
    toggle();
    refetchFunction();
  };

  const { mutate: modifyCaseAccessMutation, isLoading } =
    useModifyCaseMemberAccess(
      {
        organizationId: organizationId,
      },
      {
        successFb: onSuccess,
      }
    );

  const onSubmit = (personalityIds: string[]) => {
    if (editedCase) {
      const caseAccesses = personalityIds.map((member_personality_id) => {
        const caseAccess: MemberCaseAccess = {
          member_personality: null,
          member_personality_id,
          access_type: AccessType.GRANTED,
          assign_date: new Date().toISOString(),
          expiration_date: null,
          participant_personality_id: personalityId,
          reason: '',
          case_id: editedCase.id,
        };

        return caseAccess;
      });

      modifyCaseAccessMutation({
        caseId: editedCase.id,
        caseAccesses,
      });
    }
  };

  return (
    <div className="card-container cardContainer--with_bottom_margin PatientCasesList">
      <div className="cardContainer__header d-flex flex-column flex-md-row justify-content-between">
        <div className="d-flex align-items-center justify-content-between w-100">
          <div className="cardContainer__header__title">
            <FormattedMessage id="UserDashboard.cases" />
          </div>
          <CaseCardModal defaultPatientId={personalityId} />
        </div>
      </div>
      <div className="PatientCasesList__table">
        <ListGroup>{renderCaseItems}</ListGroup>
      </div>
      <MedicalPersonnelAssignModal
        organizationId={organizationId}
        assignedPersonalityIds={assignedPersonalityIds}
        headerTranslationId="Patient.manageMedicalPersonnel"
        subHeader={
          <p className="MedicalPersonnelAssignModal__sub-header">
            <FormattedMessage
              id="Patient.caseEditedTitle"
              values={{ name: editedCase?.title }}
            />
          </p>
        }
        isOpen={isOpen}
        toggle={toggle}
        isLoading={isLoading}
        submitFn={onSubmit}
      />
    </div>
  );
};

export default PersonalityCasesList;
