import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getOrganizationMembers } from '../../organization';
import { OrganizationQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

const useOrganizationMembers = ({
  params,
  options,
}: IQueryRequest<OrganizationQueryParams>) => {
  const { organizationId } = params;
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getOrganizationMembers(organizationId);
      return data.data;
    },
    queryKey: [QueryKeys.getOrganizationMemberList, organizationId],
  });
};

export default useOrganizationMembers;
