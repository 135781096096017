import { cloneDeep } from 'lodash';
import useCultures, {
  AvailableCulture,
} from '../../../../../../../../../../../../../../../hooks/cultures/useCultures';
import { IAsset } from '../../../../../../../../../../../../../../../interfaces/educationMaterials';

export const useHandleEditFields = (
  culture: AvailableCulture,
  modifiedAsset: IAsset,
  setModifiedAsset: (asset: IAsset) => void
) => {
  const { defaultCulture } = useCultures();

  const isDefaultCulture = culture.cultureCode === defaultCulture.cultureCode;

  const handleEditName = (target: string, value: string) => {
    let nameTranslationsCopy = cloneDeep(modifiedAsset.name_translations);

    const cultureIndex = nameTranslationsCopy.findIndex(
      (p) => p.culture === culture.cultureCode
    );

    if (cultureIndex !== -1) {
      nameTranslationsCopy[cultureIndex].value = value;
    } else {
      nameTranslationsCopy.push({ culture: culture.cultureCode, value: value });
    }

    setModifiedAsset({
      ...modifiedAsset,
      name: isDefaultCulture ? value : modifiedAsset.name,
      name_translations: nameTranslationsCopy,
    });
  };
  const handleEditDescription = (target: string, value: string) => {
    let descTranslationsCopy = cloneDeep(
      modifiedAsset.description_translations
    );

    const cultureIndex = descTranslationsCopy.findIndex(
      (p) => p.culture === culture.cultureCode
    );

    if (cultureIndex !== -1) {
      descTranslationsCopy[cultureIndex].value = value;
    } else {
      descTranslationsCopy.push({ culture: culture.cultureCode, value: value });
    }

    setModifiedAsset({
      ...modifiedAsset,
      description: isDefaultCulture ? value : modifiedAsset.description,
      description_translations: descTranslationsCopy,
    });
  };

  return { handleEditDescription, handleEditName };
};
