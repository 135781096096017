import { ITabContainerItem } from 'components/Tabs/types/Tabs';
import React from 'react';
import TabContainer from '../../../../../../../../components/Tabs/components/TabContainer';
import { ManagedTreatmentPhase } from '../../../../types';
import NoPhasesPlaceholder from '../../placeholders/NoPhasesPlaceholder';

type TreatmentPhaseLayoutProps = {
  phases: ManagedTreatmentPhase[];
  items: ITabContainerItem[];
  onTabChange: (idx: number) => void;
};

const TreatmentPhaseLayout = ({
  phases,
  items,
  onTabChange,
}: TreatmentPhaseLayoutProps) => {
  if (!phases.length) return <NoPhasesPlaceholder />;

  return (
    <TabContainer
      rowClassNames="mt-0"
      paneClassNames="p-3"
      items={items}
      onTabChange={onTabChange}
    />
  );
};

export default TreatmentPhaseLayout;
