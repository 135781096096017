import { createGraspForOrganization } from 'api/graspManagement';
import { GraspCreateBody } from 'interfaces/grasps';
import { IMutationProps } from 'interfaces/ui';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

function useCreateGraspForOrganization(
  organizationId: string,
  { successFb }: IMutationProps
) {
  return useMutationWithResultNotification({
    asyncApiFunction: async (body: GraspCreateBody) => {
      await createGraspForOrganization(organizationId, body);
    },
    resetOnResult: true,
    onSuccess: () => {
      successFb && successFb();
      displaySuccessNotification('MyOrganizations.grasps.createSuccess');
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'MyOrganizations.grasps.createErrors'
      );
    },
  });
}

export default useCreateGraspForOrganization;
