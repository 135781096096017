import React from 'react';
import {
  IAsset,
  IEducationMaterial,
} from '../../../../../../../../../../interfaces/educationMaterials';
import ReadOnlyItem from './components/ReadOnlyItem';
import EditModeItem from './components/EditModeItem';

type UploadAssetsStepItemProps = {
  material: IEducationMaterial;
  asset: IAsset;
  mode: UploadAssetsStepItemMode;
  setMaterial: (material: IEducationMaterial) => void;
  disable?: () => void;
};

export enum UploadAssetsStepItemMode {
  ReadOnly = 'ReadOnly',
  EditMode = 'EditMode',
}

const UploadAssetsStepItem = ({
  asset,
  material,
  mode,
  setMaterial,
  disable,
}: UploadAssetsStepItemProps) => {
  if (mode === UploadAssetsStepItemMode.ReadOnly)
    return (
      <ReadOnlyItem
        asset={asset}
        material={material}
        setMaterial={setMaterial}
      />
    );

  if (mode === UploadAssetsStepItemMode.EditMode && disable)
    return (
      <EditModeItem
        asset={asset}
        material={material}
        setMaterial={setMaterial}
        disable={disable}
      />
    );

  return <></>;
};

export default UploadAssetsStepItem;
