import useConversationQuestionValidation from '../../../../../../../../../../../api/mutations/conversations/useConversationQuestionValidation';
import { NIL } from 'uuid';
import { useCallback } from 'react';
import {
  IQuestionConditionValidation,
  ValidConversationTemplateItem,
} from '../../../../../../../../../../../interfaces/conversations';
import { useCommonParams } from '../../../../../../../../../hooks/useCommonParams';
import { useTypedContext } from '../../../../../../../../../../../hooks/useTypedContext';
import { FormikUtilsContext } from '../../../../../types';
import { useTranslations } from '../../../hooks/useTranslations';
import { IErrorDefinition } from '../../../../../../../../../../../interfaces/response';

export const useValidation = (
  index: number,
  item: ValidConversationTemplateItem
) => {
  const { organizationId } = useCommonParams();

  const { providerInvariant } = useTranslations();

  const formikUtils = useTypedContext(FormikUtilsContext);

  const setValidationError = () => {
    formikUtils.setFieldError(
      `content[${index}].condition`,
      providerInvariant.wrapWithIntl(
        providerInvariant.attachPrefix('invalidCondition')
      )
    );
  };

  const handleSuccessValidation = (
    validation: IQuestionConditionValidation
  ) => {
    if (validation.validation_result) return;
    setValidationError();
  };

  const handleErrorValidation = (_: string | IErrorDefinition[]) => {
    setValidationError();
  };

  const validation = useConversationQuestionValidation({
    params: {
      organizationId,
      questionId: NIL,
    },
    options: {
      successFb: handleSuccessValidation,
      errorFb: handleErrorValidation,
    },
    silent: true,
  });

  const validate = useCallback(async () => {
    if (!item.condition) return;
    await validation.mutateAsync(item.condition);
  }, [item.condition, validation]);

  return {
    validate,
    validating: validation.isLoading,
  };
};
