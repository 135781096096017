import { useCallback, useMemo } from 'react';
import useCultures from '../../../../../../../../../../../../../hooks/cultures/useCultures';
import { useTypedContext } from '../../../../../../../../../../../../../hooks/useTypedContext';
import { IAwareSessionData } from '../../../../../../../../../../../../../interfaces/awareSessions';
import {
  DashboardTemplateSeries,
  DashboardTemplateSeriesSplitBy,
  DateGranularity,
} from '../../../../../../../../../../../../../interfaces/dashboards';
import { StrictDateRange } from '../../../../../../../../../../../../../interfaces/dates';
import { DashboardSessionsHelpers } from '../../../../../../../helpers/sessions';
import { useNotSpecifiedTranslation } from '../../../../../../../hooks/useNotSpecifiedTranslation';
import {
  DashboardDataReducerContext,
  DashboardQueriesContext,
} from '../../../../../../../types';
import { ChartViewHelpers } from '../helpers';
import { SplitByValues, ValuesField } from '../types';

export const useNonPieChartFormattedData = (
  sessions: IAwareSessionData[],
  config: DashboardTemplateSeries[],
  dateGranularity: DateGranularity | undefined,
  filterEmpty: boolean = false
): any[] => {
  const { questions, members, organizations } = useTypedContext(
    DashboardQueriesContext
  );

  const { currentCulture } = useCultures();

  const { translation: notSpecifiedTranslation } = useNotSpecifiedTranslation();

  const [dashboardData] = useTypedContext(DashboardDataReducerContext);

  const nameValueSessions = useCallback(
    (sessions: SplitByValues<ValuesField<IAwareSessionData>>[]) => {
      return DashboardSessionsHelpers.NameValueSessions(
        sessions,
        members,
        organizations,
        questions,
        currentCulture.culture
      );
    },
    [currentCulture.culture, members, organizations, questions]
  );

  const splitValueSessions = useCallback(
    (
      sessions: ValuesField<IAwareSessionData>[],
      splitBy: DashboardTemplateSeriesSplitBy | 'Date',
      dateRange: StrictDateRange
    ) => {
      return ChartViewHelpers.SplitValueSessions(
        sessions,
        splitBy,
        dateGranularity,
        dateRange,
        questions,
        notSpecifiedTranslation
      );
    },
    [dateGranularity, notSpecifiedTranslation, questions]
  );

  return useMemo(() => {
    if (config.length === 0 || sessions.length === 0) return [];

    const valueSessions = ChartViewHelpers.ProvideValueSessions(
      sessions,
      config,
      notSpecifiedTranslation
    );

    const splitSessions = splitValueSessions(
      valueSessions,
      config[0].split_by ?? 'Date',
      {
        from: dashboardData.template.date_selection.date_from,
        to: dashboardData.template.date_selection.date_to,
      }
    );

    const namedSessions = nameValueSessions(splitSessions);

    const aggregatedSessions = ChartViewHelpers.AggregateValueSessions(
      namedSessions,
      config[0].series_split_by
    );

    if (!filterEmpty) return aggregatedSessions;

    const filteredSessions =
      ChartViewHelpers.FilterEmptyNameBasedValueSessions(aggregatedSessions);

    return ChartViewHelpers.OrderValueSessions(
      filteredSessions,
      notSpecifiedTranslation
    );
  }, [
    config,
    dashboardData.template.date_selection.date_from,
    dashboardData.template.date_selection.date_to,
    filterEmpty,
    nameValueSessions,
    notSpecifiedTranslation,
    sessions,
    splitValueSessions,
  ]);
};
