import useOrganizationMembers from '../../../../../../../api/queries/organizations/useOrganizationMembers';
import useRequiredParams from '../../../../../../../hooks/useRequiredParams';
import { useMemo } from 'react';
import { allowToChooseGroupOptions } from '../../../../../../../helpers/utils/translationObject';
import { useIntl } from 'react-intl';
import useCaseCategories from '../../../../../../../api/queries/cases/useCaseCategories';
import { InputOption } from '../../../../../../../interfaces/ui';
import useTreatmentPlans from '../../../../../../../api/queries/conversations/useTreatmentPlans';

export const useProjectModalDetailsForm = () => {
  const intl = useIntl();

  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const { data: caseCategories } = useCaseCategories({
    params: { organizationId },
  });

  const { data: treatmentPlans } = useTreatmentPlans({
    params: { organizationId },
  });

  const { data: organizationMembers } = useOrganizationMembers({
    params: { organizationId },
  });

  const organizationMembersInputOptions = useMemo((): InputOption[] => {
    if (!organizationMembers) return [];

    return organizationMembers.map((member) => ({
      value: member.id,
      text: `${member.first_name} ${member.last_name} | ${member.email}`,
    }));
  }, [organizationMembers]);

  const caseCategoriesInputOptions = useMemo((): InputOption[] => {
    if (!treatmentPlans || !caseCategories) return [];

    return treatmentPlans.map((plan) => ({
      value: plan.id,
      text: caseCategories?.find((p) => p.id === plan.id)?.name_en ?? '',
    }));
  }, [caseCategories, treatmentPlans]);

  const allowToChooseGroupInputOptions = useMemo(() => {
    return allowToChooseGroupOptions.map((option) => ({
      value: option.value,
      text: intl.formatMessage({ id: option.translationId }),
    }));
  }, [intl]);

  return {
    options: {
      organizationMembers: organizationMembersInputOptions,
      allowToChooseGroup: allowToChooseGroupInputOptions,
      caseCategories: caseCategoriesInputOptions,
    },
  };
};
