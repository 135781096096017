//icons
import arrowDown from 'assets/images/arrow_down.svg';
import arrowUp from 'assets/images/arrow_up.svg';
import minus from 'assets/images/minus.svg';
import classNames from 'classnames';
import SkeletonPlaceholderOnLoad from 'components/SkeletonPlaceholderOnLoad';
import { BaseSentiments } from 'interfaces/timeline';
import React from 'react';
import SVG from 'react-inlinesvg';
import Skeleton from 'react-loading-skeleton';

type HeaderDetailProps = {
  onDetailsClick?: () => void;
  header: string;
  isLoading?: boolean;
  children: JSX.Element | string;
  iconSrc?: string;
  imageSrc?: string;
  color?: 'blue' | string | null;
  isBig?: boolean;
  status?: BaseSentiments;
  noMaxWidth?: boolean;
  statusAnswer?: BaseSentiments;
  htmlFor?: string;
};

const DEFAULT_COLOR = '#407dbb';

const HeaderDetail = ({
  iconSrc,
  imageSrc,
  onDetailsClick,
  header,
  isLoading = false,
  children,
  color,
  isBig,
  status,
  noMaxWidth = false,
  statusAnswer,
  htmlFor,
}: HeaderDetailProps) => {
  let statusIcon: string | null;
  if (status === 'Negative') {
    statusIcon = arrowDown;
  } else if (status === 'Positive') {
    statusIcon = arrowUp;
  } else {
    statusIcon = minus;
  }

  return (
    <div className="HeaderDetail">
      <div
        className={classNames('HeaderDetail__icon-container', {
          'HeaderDetail__icon-container--big': isBig,
        })}
      >
        {imageSrc && (
          <img src={imageSrc} alt={imageSrc} width={36} height={36} />
        )}
        {iconSrc && (
          <SVG
            src={iconSrc}
            fill={color || undefined}
            preProcessor={(code) =>
              code
                .replace(/fill=".*?"/g, `fill="${color || DEFAULT_COLOR}"`)
                .replace(/stroke=".*?"/g, `stroke="${color || DEFAULT_COLOR}"`)
            }
          />
        )}
        {status && (
          <img
            src={statusIcon}
            alt={statusIcon}
            className={`HeaderDetail__status-icon`}
          />
        )}
      </div>
      <div
        className={classNames('HeaderDetail__text-container', {
          'HeaderDetail__text-container--no-max': noMaxWidth,
        })}
      >
        <label htmlFor={htmlFor} className="HeaderDetail__header">
          {header}
        </label>
        <SkeletonPlaceholderOnLoad
          isLoading={isLoading}
          placeholder={<Skeleton width={100} />}
        >
          <span
            className={classNames('HeaderDetail__description', {
              [`HeaderDetail__description--${statusAnswer?.toLowerCase()}`]:
                statusAnswer,
              'HeaderDetail__description--clickable': !!onDetailsClick,
            })}
            onClick={onDetailsClick}
          >
            {children}
          </span>
        </SkeletonPlaceholderOnLoad>
      </div>
    </div>
  );
};

export default HeaderDetail;
