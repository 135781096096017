import { AssessmentQuestion } from './assessmentForms';
import { ValueInterpretationType } from './change';
import { ConversationScheduleType } from './conversationSchedules';
import { CaseNotificationScheduleType } from './notificationSchedules';

export interface ITreatmentPlan {
  id: string;
  phases: ITreatmentPlanPhase[];
}

export interface IAddTreatmentPlan {
  name: string;
}

export interface ITreatmentPlanPhase {
  treatment_phase_id: string;
  sequence: number;
  days_duration: number;
}

export interface ITreatmentPhase {
  id: string;
  organization_id: string;
  treatment_plan_id: string;
  empty_conversation_template_id: string | null;
  content: TreatmentPhaseContentItem[];
  sequence: number;
  days_duration: number;
  next_phase_condition: string;
  is_default: boolean;
  display_code: string | null;
}

export interface ISummarizedTreatmentPhase extends ITreatmentPhase {
  users_count: number;
}

export enum TreatmentPhaseContentItemType {
  ConversationSchedule = 'ConversationSchedule',
  NotificationSchedule = 'NotificationSchedule',
}

export interface IBaseTreatmentPhaseContentItem {
  schedule_templates: IBaseConversationScheduleTemplate[];
}

export type TreatmentPhaseContentItem =
  | IConversationTreatmentPhaseContentItem
  | INotificationTreatmentPhaseContentItem;

export interface IConversationTreatmentPhaseContentItem
  extends IBaseTreatmentPhaseContentItem {
  type: TreatmentPhaseContentItemType.ConversationSchedule;
  template_id: string;
  priority: number;
}

export interface INotificationTreatmentPhaseContentItem
  extends IBaseTreatmentPhaseContentItem {
  type: TreatmentPhaseContentItemType.NotificationSchedule;
  notification_type: CaseNotificationScheduleType;
}

export interface IUpsertTreatmentPhase {
  organization_id: string;
  empty_conversation_template_id: string | null;
  content: TreatmentPhaseContentItem[];
  sequence: number;
  days_duration: number;
  next_phase_condition: string;
  is_default: boolean;
  display_code: string | null;
  code: string;
}

export interface IUserConversationHistoryEntry {
  id: string;
  userId: string;
  contextObjectId: string | null;
  contextType: UserConversationHistoryContextType;
  conversationTemplateId: string;
  year: number;
  month: number;
  day: number;
  hour: string;
  conversationContent: IFormConversationItem[];
}

export interface IFormConversation {
  userId: string;
  currentPosition: string;
  currentPhaseCode: string;
  currentConversationHour: string;
  currentConversationCode: string;
  conversationEnded: boolean;
  items: IFormConversationItem[];
}

export type FormConversationAnswer = Omit<
  IFormConversation,
  'userId' | 'currentPosition'
>;

export interface IFormConversationItem {
  resourceId: string | null;
  type: ConversationTemplateItemType;
  value: AssessmentQuestion | IConversationTemplatePlainTextItem;
  applyTypingEffect: boolean;
  senderAvatars: string[];
  condition: string | null;
}

export interface IConversationTemplate {
  id: string;
  organizationId: string;
  displayCode: string;
  context: ConversationTemplateContext | null;
  triggerType: ConversationTemplateTriggerType | null;
  content: IConversationTemplateItem[];
  lastResortTemplate: boolean;
}

export enum ConversationTemplateContext {
  UserConversation = 'UserConversation',
  Form = 'Form',
}

export enum ConversationTemplateTriggerType {
  Time = 'Time',
  PhaseLastResort = 'PhaseLastResort',
  MandatoryEducation = 'MandatoryEducation',
  SuggestedEducation = 'SuggestedEducation',
  ClinicianNotification = 'ClinicianNotification',
  ProjectSubmission = 'ProjectSubmission',
  AwareSession = 'AwareSession',
  UserSatisfactionFeedback = 'UserSatisfactionFeedback',
}

export interface IUpsertConversationTemplate extends ValidConversationTemplate {
  id?: string;
}

export interface IConversationTemplateItem {
  resourceId: string | null;
  value: any;
  type: ConversationTemplateItemType;
  persist: boolean | null;
  condition: string | null;
  applyTypingEffect: boolean;
  concernNotificationPreferences: string | null;
}

export interface IUpsertConversationTemplateItem {
  resourceId?: string | null;
  value: any;
  type: ConversationTemplateItemType;
  persist: boolean | null;
  condition: string | null;
  applyTypingEffect: boolean;
  concernNotificationPreferences: string | null;
}

export interface IConversationQuestionCondition {
  condition: string;
}

export interface IQuestionConditionValidation {
  question_id: string;
  validation_result: boolean;
}

export interface IConversationTemplateGuidItem {
  Guid: string;
}

export interface IConversationTemplatePlainTextItem
  extends IConversationTemplateGuidItem {
  [key: string]: string;
}

export interface ConversationQuestion {
  resource_id: string;
  organization_id: string;
  content: ValidConversationQuestion;
}

export interface GroupedConversationQuestion {
  template_ids: string[];
  questions: ConversationQuestion[];
}

export enum ConversationTemplateItemType {
  PlainText = 'PlainText',
  Delimiter = 'Delimiter',
  Question = 'Question',
  Feedback = 'Feedback',
  GraspUpload = 'GraspUpload',
  Education = 'Education',
  MaterialsList = 'MaterialsList',
  NotificationsList = 'NotificationsList',
}

interface IConversationScheduleTemplate {
  schedule_type: ConversationScheduleType;
}

export interface IBaseConversationScheduleTemplate
  extends IConversationScheduleTemplate {
  parts_of_a_day: IConversationScheduleTemplateItem[];
  start_day_offset: number | null;
  end_day_offset: number | null;
  recurring_interval: number | null;
  specific_day_of_phase: number | null;
}

export interface ISingleInstanceConversationScheduleTemplate
  extends IConversationScheduleTemplate {
  schedule_type: ConversationScheduleType.SingleInstance;
  parts_of_a_day: IConversationScheduleTemplateItem[];
  specific_day_of_phase: number;
}

export interface IRecurringConversationScheduleTemplate
  extends IConversationScheduleTemplate {
  schedule_type: ConversationScheduleType.Recurring;
  parts_of_a_day: IConversationScheduleTemplateItem[];
  start_day_offset: number;
  end_day_offset: number | null;
  recurring_interval: number;
}

export interface IConversationScheduleTemplateItem {
  notification_hour: string;
  available_from_hour: string;
  available_to_hour: string;
}

export interface IFormConversationTemplate {
  code: string;
  title: string;
  description: string;
  showProgressIndicator: boolean;
  showTitle: boolean;
  showOnDayByDay: boolean;
  showOnDayPlan: boolean;
  renderAsStepInForm: boolean;
  questions: (AssessmentQuestion | IConversationTemplatePlainTextItem)[];
}

export enum UserConversationHistoryContextType {
  Schedules = 'Schedules',
  Submissions = 'Submissions',
  AwareSessions = 'AwareSessions',
  BasicConversations = 'BasicConversations',
}

export type ConversationTemplateValidationBody = {
  content: string;
  conflicts: ConversationTemplateValidationConflict[];
};

export type ConversationTemplateValidationResult = {
  template: ValidConversationTemplate;
  conflicts: ConversationTemplateValidationConflict[];
};

export enum ConversationTemplateValidationConflictType {
  EmptyTemplate = 'EmptyTemplate',
  InvalidOrganization = 'InvalidOrganization',
  ExistingQuestion = 'ExistingQuestion',
  NonExistingMaterial = 'NonExistingMaterial',
  NonExistingFeedback = 'NonExistingFeedback',
}

export type ConversationTemplateValidationConflict =
  | EmptyTemplateConflict
  | InvalidOrganizationConflict
  | ExistingQuestionConflict
  | NonExistingMaterialConflict
  | NonExistingFeedbackConflict;

export type InvalidOrganizationConflict = {
  type: ConversationTemplateValidationConflictType.InvalidOrganization;
  organization_id: string;
};

export type ExistingQuestionConflict = {
  type: ConversationTemplateValidationConflictType.ExistingQuestion;
  question_id: string;
};

export type EmptyTemplateConflict = {
  type: ConversationTemplateValidationConflictType.EmptyTemplate;
};

export type NonExistingMaterialConflict = {
  type: ConversationTemplateValidationConflictType.NonExistingMaterial;
  material_id: string;
};

export type NonExistingFeedbackConflict = {
  type: ConversationTemplateValidationConflictType.NonExistingFeedback;
  group_name: string;
};

export type ConversationTemplate = ValidConversationTemplate & {
  id: string;
};

export type ValidConversationTemplate = {
  organization_id: string;
  display_code: string;
  context: ConversationTemplateContext;
  trigger_type: ConversationTemplateTriggerType;
  last_resort_template: boolean;
  content: ValidConversationTemplateItem[];
};

export enum ValidConversationTemplateItemType {
  PlainText = 'PlainText',
  Delimiter = 'Delimiter',
  Feedback = 'Feedback',
  Question = 'Question',
  Education = 'Education',
  GraspUpload = 'GraspUpload',
  MaterialsList = 'MaterialsList',
  NotificationsList = 'NotificationsList',
}

export type BaseValidConversationTemplateItem = {
  type: ValidConversationTemplateItemType;
  condition: string | null;
  apply_typing_effect: boolean;
  visible: boolean;
};

export type ITypeBaseConversationTemplateItem<T> = Omit<
  BaseValidConversationTemplateItem,
  'type'
> & {
  type: T;
};

export type PlainTextValidConversationTemplateItem =
  ITypeBaseConversationTemplateItem<ValidConversationTemplateItemType.PlainText> & {
    value: {
      text: string;
      text_nb_no?: string;
      text_html?: string;
      text_html_nb_no?: string;
    };
  };

export type DelimiterValidConversationTemplateItem =
  ITypeBaseConversationTemplateItem<ValidConversationTemplateItemType.Delimiter> & {
    persist: boolean;
  };

export type FeedbackValidConversationTemplateItem =
  ITypeBaseConversationTemplateItem<ValidConversationTemplateItemType.Feedback> & {
    group_name: string;
  };

export type QuestionValidConversationTemplateItem =
  ITypeBaseConversationTemplateItem<ValidConversationTemplateItemType.Question> & {
    resource_id: string;
    value: ValidConversationQuestion;
  };

export type EducationValidConversationTemplateItem =
  ITypeBaseConversationTemplateItem<ValidConversationTemplateItemType.Education> & {
    resource_id: string;
  };

export type GraspUploadValidConversationTemplateItem =
  ITypeBaseConversationTemplateItem<ValidConversationTemplateItemType.GraspUpload>;

export type MaterialsListValidConversationTemplateItem =
  ITypeBaseConversationTemplateItem<ValidConversationTemplateItemType.MaterialsList>;

export type NotificationsListValidConversationTemplateItem =
  ITypeBaseConversationTemplateItem<ValidConversationTemplateItemType.NotificationsList>;

export type ValidConversationTemplateItem =
  | PlainTextValidConversationTemplateItem
  | DelimiterValidConversationTemplateItem
  | FeedbackValidConversationTemplateItem
  | QuestionValidConversationTemplateItem
  | EducationValidConversationTemplateItem
  | GraspUploadValidConversationTemplateItem
  | MaterialsListValidConversationTemplateItem
  | NotificationsListValidConversationTemplateItem;

export type BaseValidConversationQuestion = {
  guid: string;
  sequence: number;
  title: string;
  title_nb_no?: string;
  title_html?: string;
  title_html_nb_no?: string;
  short_title: string;
  short_title_nb_no?: string;
  short_title_html?: string;
  short_title_html_nb_no?: string;
  description?: string;
  description_nb_no?: string;
  description_html?: string;
  description_html_nb_no?: string;
  color: string;
  included_in_trends: boolean;
  type: ValidConversationQuestionType;
  value_interpretation: ValueInterpretationType;
  show_title: boolean;
  show_description: boolean;
  required: boolean;
};

export type ClosedListValidConversationQuestion = Omit<
  BaseValidConversationQuestion,
  'type'
> & {
  type: ValidConversationQuestionType.ClosedList;
  answers: ClosedListValidConversationQuestionAnswer[];
};

export type ClosedListValidConversationQuestionAnswer = {
  guid: string;
  title: string;
  sequence: number;
  title_nb_no?: string;
  description?: string;
  description_nb_no?: string;
  is_selected: boolean;
};

export type EmailValidConversationQuestion = Omit<
  BaseValidConversationQuestion,
  'type'
> & {
  type: ValidConversationQuestionType.Email;
  answer_max_length?: number;
  answer_placeholder?: string;
  answer: {
    text_value: string | null;
  };
};

export type HeaderValidConversationQuestion = Omit<
  BaseValidConversationQuestion,
  'type'
> & {
  type: ValidConversationQuestionType.Header;
};

export type TimeValidConversationQuestion = Omit<
  BaseValidConversationQuestion,
  'type'
> & {
  type: ValidConversationQuestionType.Time;
  min_time_value?: string;
  max_time_value?: string;
  answer_max_length?: number;
  answer_placeholder?: string;
  answer: {
    time_value: string | null;
  };
};

export type OpenValidConversationQuestion = Omit<
  BaseValidConversationQuestion,
  'type'
> & {
  type: ValidConversationQuestionType.Open;
  answer_max_length?: number;
  answer_placeholder?: string;
  answer: {
    text_value: string | null;
  };
};

export type RangeValidConversationQuestion = Omit<
  BaseValidConversationQuestion,
  'type'
> & {
  type: ValidConversationQuestionType.Range;
  min_number_value?: number;
  max_number_value?: number;
  min_date_value?: number;
  max_date_value?: number;
  legend_descriptions: RangeValidConversationQuestionLegendDescription[];
  answer: RangeValidConversationQuestionAnswer;
};

type RangeValidConversationQuestionAnswer = {
  number_value: number | null;
  date_value: string | null;
};

export type RangeValidConversationQuestionLegendDescription = {
  value: number;
  label: string;
  label_nb_no?: string;
};

export type ValidConversationQuestion =
  | ClosedListValidConversationQuestion
  | EmailValidConversationQuestion
  | HeaderValidConversationQuestion
  | TimeValidConversationQuestion
  | OpenValidConversationQuestion
  | RangeValidConversationQuestion;

export enum ValidConversationQuestionType {
  ClosedList = 'ClosedList',
  Open = 'Open',
  Range = 'Range',
  Header = 'Header',
  Email = 'Email',
  Time = 'Time',
}
