import React from 'react';
import useTimelineActivities from 'api/queries/timelines/useTimelineActivities';
import ActivityListItem from 'components/Activity/components/ActivityListItem';
import { DateRange } from 'react-day-picker';
import { ListGroup } from 'reactstrap';
import ActivityHeaderIcon from 'assets/images/timeline_details/activity_header.svg';
import { FormattedMessage } from 'react-intl';
import ActivitiesPerDate from 'components/Activity/components/ActivitiesPerDate';
import EmptyInformation from 'modules/TimelineDetails/EmptyInformation';

type PatientActivityProps = {
  timelineId: string;
  dateRangeObject: DateRange | null;
};

const PatientActivity = ({
  timelineId,
  dateRangeObject,
}: PatientActivityProps) => {
  const { data: activities } = useTimelineActivities({
    params: {
      timelineId,
      dateRangeObject,
    },
  });

  const dates = activities ? Object.keys(activities) : [];
  const renderedRows = dates.map((date) => {
    if (activities && activities[date]) {
      const renderedActivities = activities[date].map((activity, index) => (
        <ActivityListItem
          data={activity}
          key={`${activity.activity_type}-${activity.activity_type}-${index}`}
        />
      ));

      return (
        <ActivitiesPerDate key={date} date={date}>
          {renderedActivities}
        </ActivitiesPerDate>
      );
    } else return null;
  });

  return (
    <div className="PatientActivity cardContainer">
      <div className="cardContainer__header__title">
        <img
          src={ActivityHeaderIcon}
          alt="patient_activity_icon"
          className="me-2"
        />
        <FormattedMessage id="General.patientActivity" />
      </div>
      {renderedRows.length ? (
        <ListGroup className="PatientActivity__list">{renderedRows}</ListGroup>
      ) : (
        <EmptyInformation type="range" />
      )}
    </div>
  );
};

export default PatientActivity;
