import React from 'react';
import { useConfig } from './hooks/useConfig';
import ReactQuill from 'react-quill';
import { usePlaceholder } from './hooks/usePlaceholder';
import { useControlledValue } from '../../../../hooks/useControlledValue';
import classNames from 'classnames';

export type FieldHtmlValue = {
  plain: string;
  html: string;
};

type FieldHtmlEditorProps = {
  className?: string;
  placeholder?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  condensed?: boolean;
  initialValue?: FieldHtmlValue;
  onModify?: (value: FieldHtmlValue) => void;
};

const FieldHtmlEditor = ({
  className = '',
  placeholder = '',
  style = {},
  disabled = false,
  condensed = false,
  initialValue,
  onModify,
}: FieldHtmlEditorProps) => {
  const { modules, ref } = useConfig();
  usePlaceholder(ref, placeholder);

  const { value, handleChange } = useControlledValue<string>(
    initialValue?.html
  );

  const handleModify = (
    _value: string,
    _delta: any,
    source: any,
    editor: ReactQuill.UnprivilegedEditor
  ) => {
    if (source === 'api') return;

    const plain = editor.getText().replaceAll('\n', '');
    const html = editor.getHTML();

    handleChange(html);
    onModify?.({ plain, html });
  };

  return (
    <ReactQuill
      ref={ref}
      modules={modules}
      className={classNames('FieldHtmlEditor', className, {
        'FieldHtmlEditor--condensed': condensed,
      })}
      style={style}
      readOnly={disabled}
      placeholder={placeholder}
      value={value ?? ''}
      onChange={handleModify}
    />
  );
};

export default FieldHtmlEditor;
