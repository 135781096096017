import { useCallback, useMemo } from 'react';
import { FormikErrors } from 'formik';
import { cloneDeep } from 'lodash';
import { IAsyncFormHookValues } from 'interfaces/forms';
import useRequiredParams from 'hooks/useRequiredParams';
import usePatchGraspSettings from 'api/mutations/grasps/usePatchGraspSettings';
import { formatObjectToIPatch } from 'helpers/utils/formatData';
import { useCommonData } from '../../../../../hooks/useCommonData';

type GraspGenericSettingsFormValues = {
  squeeze_end_signalling: boolean;
  connection_signalling: boolean;
  charging_signalling: boolean;
  strength_feedback_duration: number;
  full_battery_percentage: number;
  low_battery_percentage: number;
};

const EMPTY_FORM_VALUES = {
  squeeze_end_signalling: false,
  charging_signalling: false,
  connection_signalling: false,
  full_battery_percentage: 0,
  low_battery_percentage: 0,
  strength_feedback_duration: 0,
};

export const useGraspGenericSettingsForm =
  (): IAsyncFormHookValues<GraspGenericSettingsFormValues> => {
    const { graspSettings } = useCommonData();

    const { organizationId } = useRequiredParams<{
      organizationId: string;
    }>(['organizationId']);

    const handleSuccess = async () => {
      await graspSettings.refetch();
    };

    const { mutateAsync: patchAsync } = usePatchGraspSettings(
      {
        organizationId,
      },
      { successFb: handleSuccess }
    );

    const initialValues = useMemo((): GraspGenericSettingsFormValues => {
      if (!graspSettings.data) return cloneDeep(EMPTY_FORM_VALUES);
      return { ...graspSettings.data };
    }, [graspSettings]);

    const validation = useCallback(
      (
        values: GraspGenericSettingsFormValues
      ): FormikErrors<GraspGenericSettingsFormValues> => {
        let formikErrors: FormikErrors<GraspGenericSettingsFormValues> = {};

        if (values.strength_feedback_duration < 0) {
          formikErrors['strength_feedback_duration'] = 'invalid';
          return formikErrors;
        }

        if (
          values.full_battery_percentage < 0 ||
          values.full_battery_percentage > 100
        ) {
          formikErrors['full_battery_percentage'] = 'invalid';
          return formikErrors;
        }

        if (
          values.low_battery_percentage < 0 ||
          values.low_battery_percentage > 100
        ) {
          formikErrors['low_battery_percentage'] = 'invalid';
          return formikErrors;
        }

        return formikErrors;
      },
      []
    );

    const handleSubmit = useCallback(
      async (values: GraspGenericSettingsFormValues) => {
        if (!graspSettings.data) return;

        let patchBody = formatObjectToIPatch(values);

        patchBody = patchBody.filter((p) => p.path !== '/organization_id');
        patchBody = patchBody.filter((p) => p.path !== '/led_levels');

        await patchAsync({
          id: graspSettings.data.id,
          patchData: patchBody,
        });
      },
      [graspSettings.data, patchAsync]
    );

    return {
      initialValues,
      validation: validation,
      handleSubmit: handleSubmit,
    };
  };
