import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { Expressions } from '../../../../../../../../../../../../../../helpers/expressions';
import useCultures from '../../../../../../../../../../../../../../hooks/cultures/useCultures';
import { useOrganizationPathParams } from '../../../../../../../../../../../../../../hooks/paths/useOrganizationPathParams';

type ListItemTextGeneratorProps = {
  text: string;
  item: any;
};

const ListItemTextGenerator = ({ text, item }: ListItemTextGeneratorProps) => {
  const { currentCulture } = useCultures();
  const { organizationId } = useOrganizationPathParams();

  const { caseId, dashboardId, memberId } = useParams<{
    caseId: string;
    dashboardId: string;
    memberId: string;
  }>();

  const evaluateItemText = useMemo(() => {
    return Expressions.HandlebarsEvaluate(
      text,
      {
        item,
        organizationId,
        caseId,
        dashboardId,
        memberId,
      },
      currentCulture
    );
  }, [
    caseId,
    currentCulture,
    dashboardId,
    item,
    memberId,
    organizationId,
    text,
  ]);

  return <span>{evaluateItemText}</span>;
};

export default ListItemTextGenerator;
