import React from 'react';
import { useTranslations } from '../../../../hooks/useTranslations';
import { Field } from 'formik';
import FieldInput from '../../../../../../../../../../../../components/FieldInput';
import {
  QuestionValidConversationTemplateItem,
  TimeValidConversationQuestion,
  ValidConversationTemplate,
} from '../../../../../../../../../../../../interfaces/conversations';
import { useTypedContext } from '../../../../../../../../../../../../hooks/useTypedContext';
import { LanguageContext } from '../../../../types';
import { AVAILABLE_CULTURES } from '../../../../../../../../../../../../components/CultureSelector/CultureSelector';
import { useGeneratePlaceholder } from '../../../../hooks/useGeneratePlaceholder';

type TimeProps = {
  index: number;
  values: ValidConversationTemplate;
};

const Time = ({ index, values }: TimeProps) => {
  const { providerInvariant } = useTranslations();

  const language = useTypedContext(LanguageContext);
  const isDefaultCulture = language.culture === AVAILABLE_CULTURES.EN;

  const getPlaceholder = useGeneratePlaceholder(isDefaultCulture);

  const questionItem = values.content[
    index
  ] as QuestionValidConversationTemplateItem;

  const timeQuestion = questionItem.value as TimeValidConversationQuestion;

  return (
    <>
      <Field
        required={false}
        placeholder={getPlaceholder(
          'answerMaxLength',
          timeQuestion.answer_max_length
        )}
        label={providerInvariant.wrapWithIntl(
          providerInvariant.attachPrefix('answerMaxLength')
        )}
        id={`content[${index}].value.answer_max_length`}
        name={`content[${index}].value.answer_max_length`}
        type="number"
        component={FieldInput}
      />
      <Field
        required={false}
        placeholder={getPlaceholder(
          'answerPlaceholder',
          timeQuestion.answer_placeholder
        )}
        label={providerInvariant.wrapWithIntl(
          providerInvariant.attachPrefix('answerPlaceholder')
        )}
        id={`content[${index}].value.answer_placeholder`}
        name={`content[${index}].value.answer_placeholder`}
        type="text"
        component={FieldInput}
      />
      <Field
        required={false}
        placeholder={getPlaceholder(
          'minTimeValue',
          timeQuestion.min_time_value
        )}
        label={providerInvariant.wrapWithIntl(
          providerInvariant.attachPrefix('minTimeValue')
        )}
        id={`content[${index}].value.min_time_value`}
        name={`content[${index}].value.min_time_value`}
        type="time"
        component={FieldInput}
      />
      <Field
        required={false}
        placeholder={getPlaceholder(
          'maxTimeValue',
          timeQuestion.max_time_value
        )}
        label={providerInvariant.wrapWithIntl(
          providerInvariant.attachPrefix('maxTimeValue')
        )}
        id={`content[${index}].value.max_time_value`}
        name={`content[${index}].value.max_time_value`}
        type="time"
        component={FieldInput}
      />
    </>
  );
};

export default Time;
