import plusIcon from 'assets/images/plus.svg';
import React, { useRef, useState } from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';
import { SelectionDetailsProps } from '../index';

const DailySelectionDetails = ({ info, setInfo }: SelectionDetailsProps) => {
  const timeRef = useRef<HTMLInputElement>(null);
  const [addHourMode, setAddHourMode] = useState(false);

  const addHourToSchedule = () => {
    if (timeRef.current?.value) {
      setInfo({
        ...info,
        daily: {
          dayInterval: info.daily!.dayInterval,
          hours: [...info.daily!.hours, timeRef.current.value].filter(
            (v, i, a) => a.indexOf(v) === i
          ),
        },
      });
      setAddHourMode(false);
    }
  };

  const removeHourFromSchedule = (hour: string) => {
    setInfo({
      ...info,
      daily: {
        dayInterval: info.daily!.dayInterval,
        hours: info.daily!.hours.filter((h) => h !== hour),
      },
    });
  };

  const enableAddHourMode = () => {
    setAddHourMode(true);
  };

  const disableAddHourMode = () => {
    setAddHourMode(false);
  };

  const handleHourChangeForKeyPress = (
    e: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (e.key === 'Enter' && e.currentTarget.value.length === 5) {
      addHourToSchedule();
    }
  };

  return (
    <div className="DailySelectionDetails">
      <div className="DailySelectionDetails__frequency">
        <span>
          <FormattedMessage id="CaseCard.FormsAssignments.everyday" />
        </span>
      </div>
      <div className="DailySelectionDetails__hours">
        {info.daily!.hours.map((hour) => (
          <div className="DailySelectionDetails__hours__hour">
            <span className="DailySelectionDetails__hours__hour__text">
              {hour}
            </span>
            <button
              onClick={() => removeHourFromSchedule(hour)}
              className="DailySelectionDetails__hours__hour__btn"
            >
              &times;
            </button>
          </div>
        ))}
        {addHourMode && (
          <>
            <input
              ref={timeRef}
              type="time"
              name="add-hour"
              className="HourSelectionDetails__add-hour-input"
              onKeyDown={handleHourChangeForKeyPress}
              required
              aria-label="Add hour"
            />
            <button
              onClick={addHourToSchedule}
              className="HourSelectionDetails__add-hour-confirm"
              name="confirm"
            >
              <SVG src={plusIcon} title="confirm" />
            </button>
            <button
              onClick={disableAddHourMode}
              className="HourSelectionDetails__add-hour-reject"
            >
              &times;
            </button>
          </>
        )}
        {!addHourMode && (
          <button
            onClick={enableAddHourMode}
            className="HourSelectionDetails__add-hour-btn"
          >
            <SVG src={plusIcon} title="add" />
            <span>
              <FormattedMessage id="CaseCard.FormsAssignments.HourSelection.addHour" />
            </span>
          </button>
        )}
      </div>
    </div>
  );
};

export default DailySelectionDetails;
