import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';

//helpers
import useOpen from 'hooks/useOpen';

//components
import InfiniteListLayout from 'components/InfiniteList/InfiniteListLayout';
import ParticipantsList from './components/ParticipantsList';

//images
import patientsIcon from 'assets/images/patients_icon.svg';
import useRequiredParams from 'hooks/useRequiredParams';
import { generateOrganizationPersonalityCreateUrl } from 'helpers/routeDefinitions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch } from '@fortawesome/pro-light-svg-icons';

const Participants = () => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const { isOpen, toggle } = useOpen();

  return (
    <InfiniteListLayout
      titleTranslationId="General.patients"
      additionalRightContent={
        <div className="d-flex align-items-center gap-2 mb-3">
          <Button color="primary" outline onClick={toggle}>
            <FontAwesomeIcon icon={faSearch} />
          </Button>
          <Link
            to={generateOrganizationPersonalityCreateUrl({ organizationId })}
            color="text-white"
          >
            <Button color="primary" outline>
              <FontAwesomeIcon icon={faPlus} />
            </Button>
          </Link>
        </div>
      }
      labelTranslationId="Patient.searchText"
      inputIconSrc={patientsIcon}
    >
      <ParticipantsList
        isRequestAccessOpen={isOpen}
        toggleRequestAccess={toggle}
      />
    </InfiniteListLayout>
  );
};

export default Participants;
