import React, { memo, PropsWithChildren } from 'react';
import { ValidConversationTemplateItem } from '../../../../../../../../../../../../interfaces/conversations';
import { ItemBaseActions } from './types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import SVG from 'react-inlinesvg';
import pencilFormIcon from '../../../../../../../../../../../../assets/images/edit_icon.svg';
import trashIcon from '../../../../../../../../../../../../assets/images/delete_icon.svg';
import arrowIcon from '../../../../../../../../../../../../assets/images/arrow_icon.svg';
import { useTranslations } from '../../../../../../../../../../../../hooks/useTranslations';

type ItemBaseProps = {
  item: ValidConversationTemplateItem;
  actions: ItemBaseActions | undefined;
  actionsDisabled: boolean;
};

const ItemBase = ({
  item,
  actions,
  children,
  actionsDisabled,
}: PropsWithChildren<ItemBaseProps>) => {
  const { wrapWithIntl, attachPrefix } = useTranslations(
    'ConversationsAndFeedbacks.ConversationTemplates.ConversationTemplatePhone.Conversation.ConversationItem.ItemBase'
  );

  return (
    <div
      className={classNames({
        ConversationTemplateItem: true,
        'ConversationTemplateItem--actions': !!actions,
      })}
    >
      <div
        className={classNames({
          ConversationTemplateItem__child: true,
          'ConversationTemplateItem__child--invisible': !item.visible,
        })}
      >
        {children}
      </div>
      {item.apply_typing_effect && (
        <div className="ConversationTemplateItem__apply-typing-effect ms-1">
          <span className="ConversationTemplateItem__apply-typing-effect__text">
            <FormattedMessage id={attachPrefix('typing')} />
          </span>
        </div>
      )}
      {!!actions && !actionsDisabled && (
        <div className="ConversationTemplateItem__actions">
          {actions.onSelectItem && (
            <button
              className="ConversationTemplateItem__actions__action"
              onClick={actions.onSelectItem}
            >
              <SVG
                src={pencilFormIcon}
                title={wrapWithIntl(attachPrefix('select'))}
              />
            </button>
          )}
          {actions.onDeleteItem && (
            <button
              className="ConversationTemplateItem__actions__action"
              onClick={actions.onDeleteItem}
            >
              <SVG
                src={trashIcon}
                title={wrapWithIntl(attachPrefix('delete'))}
              />
            </button>
          )}
          {actions.onMoveItemUp && (
            <button
              className="ConversationTemplateItem__actions__action ConversationTemplateItem__actions__action--move-up"
              onClick={actions.onMoveItemUp}
            >
              <SVG
                src={arrowIcon}
                title={wrapWithIntl(attachPrefix('moveUp'))}
              />
            </button>
          )}
          {actions.onMoveItemDown && (
            <button
              className="ConversationTemplateItem__actions__action ConversationTemplateItem__actions__action--move-down"
              onClick={actions.onMoveItemDown}
            >
              <SVG
                src={arrowIcon}
                title={wrapWithIntl(attachPrefix('moveDown'))}
              />
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(ItemBase);
