import { useMemo } from 'react';
import { faEdit, faEye, faUserCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AdditionalActionsDropdown from 'components/AdditionalActionsDropdown';
import { CaseHeaderWithMembers } from 'interfaces/cases';
import { DropdownItem } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import { iconColors } from 'helpers/colors';
import MedicalRecord from 'components/MedicalRecord';
import { IPersonality } from 'interfaces/personalities';
import { useNavigate } from 'react-router';
import {
  generateParticipantCheckRoute,
  RedirectSource,
} from 'helpers/routeDefinitions';
import FunctionalityRouteGuard from 'components/routes/FunctionalityRouteGuard';
import { FunctionalityCode } from 'interfaces/functionalities';

type PersonalityCaseItemProps = {
  caseData: CaseHeaderWithMembers;
  organizationId: string;
  redirectToCaseDetails: () => void;
  onEditAssignment: () => void;
};

const PersonalityCaseItem = ({
  caseData,
  redirectToCaseDetails,
  onEditAssignment,
  organizationId,
}: PersonalityCaseItemProps) => {
  const navigate = useNavigate();

  const triggerPatientCheckModal = () =>
    navigate(
      generateParticipantCheckRoute(
        {
          caseId: caseData.id,
          organizationId,
        },
        RedirectSource.memberDetails
      )
    );

  const membersPersonalities = useMemo(() => {
    const personalities: IPersonality[] = [];

    caseData.members_with_access?.forEach((memberAccess) => {
      if (memberAccess.member_personality) {
        personalities.push(memberAccess.member_personality);
      }
    });

    return personalities;
  }, [caseData]);

  return (
    <MedicalRecord
      id={caseData.id}
      status={caseData.status}
      title={caseData.title}
      description={caseData.description}
      type={caseData.type}
      lastUpdate={caseData.last_update}
      members={membersPersonalities}
      additionalContent={
        <AdditionalActionsDropdown
          dropdownItems={
            <>
              <DropdownItem onClick={onEditAssignment} key="manage-action">
                <FontAwesomeIcon
                  icon={faEdit}
                  color={iconColors.edit}
                  className="me-2"
                />
                <FormattedMessage id="Patient.manageMedicalPersonnel" />
              </DropdownItem>
              ,
              <DropdownItem
                onClick={redirectToCaseDetails}
                key="display-action"
              >
                <FontAwesomeIcon
                  icon={faEye}
                  color={iconColors.edit}
                  className="me-2"
                />
                <FormattedMessage id="Patient.displayCaseDetails" />
              </DropdownItem>
              ,
              <FunctionalityRouteGuard
                code={FunctionalityCode.CASE_LIST_CHECK_PATIENT}
              >
                <DropdownItem
                  onClick={triggerPatientCheckModal}
                  key="patient-check"
                >
                  <FontAwesomeIcon
                    icon={faUserCheck}
                    color={iconColors.edit}
                    className="me-2"
                  />
                  <FormattedMessage id="PatientCheck.checkStatus" />
                </DropdownItem>
              </FunctionalityRouteGuard>
            </>
          }
        />
      }
    />
  );
};

export default PersonalityCaseItem;
