import { deleteTimelineObservation } from 'api/graspManagement';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

function useDeleteTimelineObservation(
  timelineId: string,
  successFb?: () => void
) {
  return useMutationWithResultNotification({
    asyncApiFunction: async (observationId: string) => {
      await deleteTimelineObservation(timelineId, observationId);
    },
    onSuccess: () => {
      successFb && successFb();
      displaySuccessNotification(
        'TimelineDetails.ObservationDelete.deleteSuccessful'
      );
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'TimelineDetails.ObservationDelete.errors'
      );
    },
    resetOnResult: true,
  });
}

export default useDeleteTimelineObservation;
