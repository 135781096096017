import { CultureEntry } from '../interfaces/cultures';

export namespace CultureHelpers {
  export const DEFAULT_CULTURE_CODE = 'en-US';
  export const UNAVAILABLE_CULTURE_VALUE =
    'Required culture value is not defined';

  export const getValueAccordingToCulture = <T>(
    entries: CultureEntry<T>[],
    code: string
  ) => {
    const codeEntry = entries.find((p) => p.culture === code);
    if (codeEntry) return codeEntry.value;

    const defaultEntry = entries.find(
      (p) => p.culture === DEFAULT_CULTURE_CODE
    );
    if (defaultEntry) return defaultEntry.value;

    throw Error(UNAVAILABLE_CULTURE_VALUE);
  };
}
