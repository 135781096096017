import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import TextField from '@mui/material/TextField';
import { Formik } from 'formik';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ModalBody, ModalFooter } from 'reactstrap';
import {
  DashboardReportDestinationType,
  DashboardReportPeriod,
} from '../../../../../../../../../../../../interfaces/dashboards';
import { BaseModalProps } from '../../../../../../../../../../../../interfaces/ui';
import { useForm } from './hooks/useForm';

type AddReportScheduleFormProps = {
  modal: BaseModalProps;
};

const AddReportScheduleForm = ({ modal }: AddReportScheduleFormProps) => {
  const formHandler = useForm(modal);

  return (
    <Formik
      initialValues={formHandler.initialValues}
      validationSchema={formHandler.validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={formHandler.handleSubmit}
    >
      {({
        values,
        setValues,
        handleChange,
        handleBlur,
        touched,
        errors,
        submitForm,
        isValid,
      }) => {
        const handleChangeDestinationType = (e) => {
          setValues({
            ...values,
            destination_type: e.target.value,
            destination_id: '',
          });
        };

        return (
          <>
            <ModalBody className="d-flex flex-column gap-3">
              <TextField
                variant="outlined"
                fullWidth
                select
                onChange={handleChange}
                id="dashboard_id"
                name="dashboard_id"
                label={<FormattedMessage id="Dashboards.DashboardTemplate" />}
                helperText={touched.dashboard_id && errors.dashboard_id}
              >
                {formHandler.dashboardTemplatesOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.text}
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                variant="outlined"
                fullWidth
                type="date"
                id="date_from"
                name="date_from"
                value={values.date_from}
                onChange={handleChange}
                onBlur={handleBlur}
              />
              <TextField
                variant="outlined"
                fullWidth
                select
                onChange={handleChange}
                id="period"
                name="period"
                label={<FormattedMessage id="General.Period" />}
                helperText={touched.period && errors.period}
              >
                {Object.entries(DashboardReportPeriod).map(([key, val]) => (
                  <MenuItem key={key} value={val}>
                    <FormattedMessage
                      id={`Enums.DashboardReportPeriod.${val}`}
                    />
                  </MenuItem>
                ))}
              </TextField>
              <TextField
                variant="outlined"
                fullWidth
                select
                onChange={handleChangeDestinationType}
                id="destination_type"
                name="destination_type"
                label={<FormattedMessage id="Dashboards.TargetType" />}
                helperText={touched.destination_type && errors.destination_type}
              >
                {Object.entries(DashboardReportDestinationType).map(
                  ([key, val]) => (
                    <MenuItem key={key} value={val}>
                      <FormattedMessage
                        id={`Enums.DashboardReportDestinationType.${val}`}
                      />
                    </MenuItem>
                  )
                )}
              </TextField>
              {values.destination_type ===
                DashboardReportDestinationType.Email && (
                <TextField
                  variant="outlined"
                  fullWidth
                  id="destination_id"
                  name="destination_id"
                  label={<FormattedMessage id="General.Email" />}
                  value={values.destination_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={
                    touched.destination_id && Boolean(errors.destination_id)
                  }
                />
              )}
              {values.destination_type ===
                DashboardReportDestinationType.Member && (
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  onChange={handleChange}
                  id="destination_id"
                  name="destination_id"
                  label={<FormattedMessage id="Organizations.Member" />}
                >
                  {formHandler.membersOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.text}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              {values.destination_type ===
                DashboardReportDestinationType.AccessProfile && (
                <TextField
                  variant="outlined"
                  fullWidth
                  select
                  onChange={handleChange}
                  id="destination_id"
                  name="destination_id"
                  label={<FormattedMessage id="Organizations.AccessProfile" />}
                >
                  {formHandler.accessProfilesOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.text}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            </ModalBody>
            <ModalFooter>
              <Button
                variant="contained"
                type="submit"
                disabled={!isValid}
                onClick={submitForm}
              >
                <FormattedMessage id="General.Save" />
              </Button>
            </ModalFooter>
          </>
        );
      }}
    </Formik>
  );
};

export default AddReportScheduleForm;
