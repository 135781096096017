import useContainer from 'hooks/useContainer';
import { IObservation } from 'interfaces/timeline';
import { useLayoutEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import { Bar, BarChart, CartesianGrid, YAxis } from 'recharts';
import { COLOR_DEFINITIONS } from './index';
import CustomYAxis from './CustomYAxis';

type MomentaryAmplitudeModalProps = {
  selectedObservations: IObservation[] | null;
  close: () => void;
};

const paddingTop = 20;
const maximumAmplitude = 255;
const voltage = 3;

export const calculateVoltage = (amplitude: number) => {
  return (amplitude / maximumAmplitude) * voltage;
};

const CHART_HEIGHT = 500;
const BAR_SIZE = 50;
const Y_AXIS_WIDTH = 60;

const MomentaryAmplitudeModal = ({
  selectedObservations,
  close,
}: MomentaryAmplitudeModalProps) => {
  const isOpen = !!selectedObservations;

  const [selectedIndex, setSelectedIndex] = useState(0);

  const selectedObservation = selectedObservations
    ? selectedObservations[selectedIndex]
    : null;

  const amplitudes = selectedObservation?.amplitudes ?? [];

  const formattedAmplitudes =
    amplitudes.map((value) => ({
      name: 'amplitude',
      value,
    })) ?? [];

  const width = formattedAmplitudes.length * BAR_SIZE;

  const ticks = [
    0,
    ...[...Array(9)].map((_val, index) => (index + 1) * 25),
    255,
  ];

  const containerRef = useRef<HTMLDivElement | null>(null);

  const { containerWidth, resizeListener } = useContainer(containerRef, {
    isDayByDayScreen: false,
  });

  const containerWidthMinusYAxis = containerWidth - 50;
  const calculatedWidth =
    width > containerWidthMinusYAxis ? width : containerWidthMinusYAxis;

  const maxAmplitude = Math.max(...amplitudes);
  const avgAmplitude =
    amplitudes.reduce((prev, current) => prev + current, 0) / amplitudes.length;

  const maxVoltage = Math.round(calculateVoltage(maxAmplitude) * 100) / 100;
  const avgVoltage = Math.round(calculateVoltage(avgAmplitude) * 100) / 100;

  const renderSelectButtons = () => {
    if (!selectedObservations || selectedObservations.length === 1) return null;

    return (
      <div className="w-100 d-flex flex-wrap align-items-center justify-content-center">
        {[...Array(selectedObservations.length)].map((_val, index) => (
          <Button
            color="primary"
            outline={index !== selectedIndex}
            className="me-2"
            onClick={() => setSelectedIndex(index)}
          >
            <FormattedMessage id="MomentarySession.observation" /> {index + 1}
          </Button>
        ))}
      </div>
    );
  };

  const onClose = () => {
    setSelectedIndex(0);
    close();
  };

  useLayoutEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        resizeListener();
      }, 100);
    }
  }, [isOpen, resizeListener]);

  const customLabel = (props) => {
    const { x, y, stroke, value, width } = props;

    return (
      <text x={x + width / 2} y={y} dy={-4} fill={stroke} textAnchor="middle">
        {value}
        {` (${Math.round(calculateVoltage(value) * 100) / 100}V)`}
      </text>
    );
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} size="xl">
      <ModalHeader toggle={onClose}>
        <FormattedMessage id="MomentarySession.observationDetails" />
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={4} className="text-center">
            <div>
              <FormattedMessage
                id="MomentarySession.squeezeTime"
                values={{
                  value: selectedObservation?.duration_in_seconds,
                }}
              />
            </div>
            <div>
              <FormattedMessage
                id="MomentarySession.frequency"
                values={{
                  value: selectedObservation?.sample_rate_in_hz,
                }}
              />
            </div>
          </Col>
          <Col md={4} className="text-center">
            <div>
              <FormattedMessage
                id="MomentarySession.maxAmplitude"
                values={{
                  value: maxAmplitude,
                }}
              />
            </div>
            <div>
              <FormattedMessage
                id="MomentarySession.avgAmplitude"
                values={{
                  value: (Math.round(avgAmplitude) * 100) / 100,
                }}
              />
            </div>
          </Col>
          <Col md={4} className="text-center">
            <div>
              <FormattedMessage
                id="MomentarySession.maxVoltage"
                values={{
                  value: maxVoltage,
                }}
              />
            </div>
            <div>
              <FormattedMessage
                id="MomentarySession.avgVoltage"
                values={{
                  value: avgVoltage,
                }}
              />
            </div>
          </Col>
        </Row>
        <div className="MomentarySessionChartWrapper" ref={containerRef}>
          <CustomYAxis
            height={CHART_HEIGHT}
            ticks={ticks}
            paddingTop={paddingTop}
            width={Y_AXIS_WIDTH}
          />
          <BarChart
            data={formattedAmplitudes}
            height={CHART_HEIGHT}
            width={calculatedWidth}
            margin={{
              top: paddingTop,
            }}
          >
            <YAxis
              dataKey="value"
              allowDataOverflow={false}
              type="number"
              domain={[0, 255]}
              tickCount={12}
              hide
            />
            <CartesianGrid vertical={false} strokeDasharray="4" />
            <Bar
              dataKey="value"
              fill={COLOR_DEFINITIONS.BLUE}
              label={customLabel}
            />
          </BarChart>
        </div>
        <div className="d-flex">{renderSelectButtons()}</div>
      </ModalBody>
    </Modal>
  );
};

export default MomentaryAmplitudeModal;
