import { useCallback, useMemo } from 'react';
import * as Yup from 'yup';
import { ConversationScheduleType } from '../../../../../../../../../../../../../../../interfaces/conversationSchedules';
import { IValidationSchemaBasedFormHookValues } from '../../../../../../../../../../../../../../../interfaces/forms';
import { CaseNotificationScheduleType } from '../../../../../../../../../../../../../../../interfaces/notificationSchedules';
import { FormValues } from '../types';
import { useHandleCreate } from './useHandleCreate';
import { useHandleDates } from './useHandleDates';

export const useForm = (
  selectedDate: string,
  toggle: () => void
): IValidationSchemaBasedFormHookValues<FormValues> => {
  const handleCreateAsync = useHandleCreate(selectedDate, toggle);

  const { getDate } = useHandleDates();

  const initialValues = useMemo((): FormValues => {
    return {
      notification_type: CaseNotificationScheduleType.GraspFlashing,
      schedule_type: ConversationScheduleType.SingleInstance,
      notification_hour: '00:00',
    };
  }, []);

  const handleSubmit = useCallback(
    async (values: typeof initialValues) => {
      const payload = {
        origin_id: undefined,
        notification_type: values.notification_type,
        schedule_type: values.schedule_type,
        recurring_interval: values.recurring_interval,
        date:
          values.schedule_type === ConversationScheduleType.SingleInstance
            ? getDate(values.specific_day_of_phase, values.notification_hour)
            : undefined,
        date_from:
          values.schedule_type === ConversationScheduleType.Recurring
            ? getDate(values.start_day_offset, values.notification_hour)
            : undefined,
        date_to:
          values.schedule_type === ConversationScheduleType.Recurring
            ? getDate(values.end_day_offset, values.notification_hour)
            : undefined,
      };

      await handleCreateAsync(payload);
    },
    [getDate, handleCreateAsync]
  );

  const validationSchema = Yup.object<FormValues>().shape({
    notification_type: Yup.mixed<CaseNotificationScheduleType>()
      .oneOf(Object.values(CaseNotificationScheduleType))
      .required(),
    schedule_type: Yup.mixed<
      | ConversationScheduleType.SingleInstance
      | ConversationScheduleType.Recurring
    >()
      .oneOf([
        ConversationScheduleType.SingleInstance,
        ConversationScheduleType.Recurring,
      ])
      .required(),
    start_day_offset: Yup.number().when('schedule_type', {
      is: ConversationScheduleType.Recurring,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    }),
    end_day_offset: Yup.number().when('schedule_type', {
      is: ConversationScheduleType.Recurring,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    }),
    recurring_interval: Yup.number().when('schedule_type', {
      is: ConversationScheduleType.Recurring,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    }),
    specific_day_of_phase: Yup.number().when('schedule_type', {
      is: ConversationScheduleType.SingleInstance,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    }),
    notification_hour: Yup.string().required(),
  });

  return {
    initialValues,
    handleSubmit,
    validationSchema,
  };
};
