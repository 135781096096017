import React, { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PageHeader from '../PageHeader';
import DebouncedInput from '../DebouncedInput';
import useOrganizationDetails from '../../api/queries/organizations/useOrganizationDetails';
import { useParams } from 'react-router';
import { IconDefinition } from '@fortawesome/pro-solid-svg-icons';

type InfiniteListLayoutProps = {
  children: JSX.Element;
  labelTranslationId: string;
  titleTranslationId: string;
  additionalRightContent?: JSX.Element;
  additionalLeftContent?: JSX.Element;
  inputIconSrc: string | IconDefinition;
  hideLabel?: boolean;
};

const InfiniteListLayout = ({
  children,
  labelTranslationId,
  titleTranslationId,
  additionalRightContent,
  additionalLeftContent,
  inputIconSrc,
  hideLabel = false,
}: InfiniteListLayoutProps) => {
  const { organizationId } = useParams<{
    organizationId: string;
  }>();

  const [filterValue, setFilterValue] = useState('');
  const onDebounce = (value: string) => setFilterValue(value);

  const { data: organizationData } = useOrganizationDetails({
    params: { organizationId: organizationId ?? '' },
    options: { enabled: !!organizationId },
  });

  const breadcrumbs = useMemo(() => {
    const lastOneEntry = {
      key: titleTranslationId,
      link: null,
      text: <FormattedMessage id={titleTranslationId} />,
    };

    if (!organizationId) return [lastOneEntry];

    return [
      {
        key: 'my-organizations',
        link: null,
        text: <FormattedMessage id="InfiniteListLayout.myOrganizations" />,
      },
      {
        key: 'current-organization',
        link: `/my-organizations/${organizationId}`,
        text: organizationData?.name ?? '',
      },
      lastOneEntry,
    ];
  }, [organizationData?.name, organizationId, titleTranslationId]);

  return (
    <>
      <PageHeader className="mb-4" breadcrumbs={breadcrumbs} />
      <div className="mb-4 align-items-end d-flex flex-nowrap">
        <div className="d-flex gap-2">
          <DebouncedInput
            inputIconSrc={inputIconSrc}
            labelTranslationId={labelTranslationId}
            name="search"
            onDebounce={onDebounce}
            hideLabel={hideLabel}
          />
          {!!additionalLeftContent && additionalLeftContent}
        </div>
        <div className="flex-grow-1 mx-auto">
          <div className="h-100 d-flex justify-content-end align-items-center">
            {additionalRightContent}
          </div>
        </div>
      </div>
      {React.cloneElement(children, {
        searchValue: filterValue,
      })}
    </>
  );
};

export default InfiniteListLayout;
