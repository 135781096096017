import { editCase } from 'api/patients';
import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import { IPatchObject } from 'interfaces/request';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from 'modules/Notifications';
import { IMutationProps } from '../../../interfaces/ui';

type useCaseEditPrams = {
  organizationId: string;
  caseId: string;
};

const useCaseEdit = (params: useCaseEditPrams, options: IMutationProps) => {
  return useMutationWithResultNotification<IPatchObject[], void>({
    asyncApiFunction: async (patchData) => {
      await editCase(params.organizationId, params.caseId, patchData);
    },
    onError: () => {
      if (options.errorFb) options.errorFb();
      displayErrorNotification('CaseCard.edit.error');
    },
    onSuccess: () => {
      if (options.successFb) options.successFb();
      displaySuccessNotification('CaseCard.edit.success');
    },
    resetOnResult: true,
  });
};

export default useCaseEdit;
