import React from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage, useIntl } from 'react-intl';

import noDataFormsPlaceholder from 'assets/images/no_information_forms.svg';

type HeaderSummaryEmptyDataPlaceholderProps = {
  titleTranslationId?: string;
};

const HeaderSummaryEmptyDataPlaceholder = ({
  titleTranslationId = undefined,
}: HeaderSummaryEmptyDataPlaceholderProps) => {
  const intl = useIntl();

  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
      <SVG
        src={noDataFormsPlaceholder}
        title={intl.formatMessage({
          id: 'General.noData',
        })}
      />
      {titleTranslationId && (
        <div className="mt-3">
          <FormattedMessage id={titleTranslationId} />
        </div>
      )}
    </div>
  );
};

export default HeaderSummaryEmptyDataPlaceholder;
