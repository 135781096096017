import { AssessmentQuestionType } from '../../interfaces/assessmentForms';
import {
  ConversationTemplate,
  ConversationTemplateItemType,
  IConversationTemplateItem,
  IUpsertConversationTemplateItem,
} from '../../interfaces/conversations';

export const lowercaseKeys = (obj) => {
  return Object.keys(obj).reduce((accumulator, key) => {
    if (accumulator[`${key[0].toLowerCase()}${key.slice(1)}`])
      return accumulator;

    accumulator[`${key[0].toLowerCase()}${key.slice(1)}`] = obj[key];
    return accumulator;
  }, {});
};

export const rebuildConversationQuestionsBody = (
  items: IUpsertConversationTemplateItem[] | IConversationTemplateItem[]
) => {
  items = items.map((item) => {
    if (item.type !== ConversationTemplateItemType.Question) return item;

    item.value = lowercaseKeys(item.value);

    if (item.value?.answers)
      item.value.answers = item.value.answers.map((answer) =>
        lowercaseKeys(answer)
      );

    if (item.value?.legendDescriptions)
      item.value.legendDescriptions = item.value.legendDescriptions.map(
        (description) => lowercaseKeys(description)
      );

    if (item.value?.answer) {
      item.value.answer['numberValue'] =
        item.value.answer['numberValue'] ?? item.value.answer['NumberValue'];
      item.value.answer['dateValue'] =
        item.value.answer['dateValue'] ?? item.value.answer['DateValue'];
      item.value.answer['textValue'] =
        item.value.answer['textValue'] ?? item.value.answer['TextValue'];
    }

    if (!isNaN(item.value.type)) {
      item.value.type = Object.values(AssessmentQuestionType)[
        Number(item.value.type)
      ];
    }

    return item;
  });

  return items;
};

export const translateTemplateIds = (
  ids: string[],
  templates: ConversationTemplate[],
  emptyTranslation: string
) => {
  if (ids.length === 0) return emptyTranslation;

  let templateNames = ids.map(
    (id) => templates.find((p) => p.id === id)?.display_code ?? ''
  );

  return templateNames.join(', ');
};
