import { AssessmentLegendDescriptions } from '../interfaces/assessmentForms';
import { AVAILABLE_CULTURES } from './CultureSelector/CultureSelector';
import { createTheme, Slider, ThemeProvider } from '@mui/material';
import RangeQuestionIconFeedbackHorizontalList from './RangeQuestionIconFeedbackHorizontalList';
import { Sentiments } from '../interfaces/timeline';
import { useState } from 'react';
import { OverridableStringUnion } from '@mui/types';
import { SliderPropsColorOverrides } from '@mui/material/Slider/Slider';

const colorDefinitions = {
  [Sentiments.NotSpecified]: '#407dbb',
  [Sentiments.Neutral]: '#407dbb',
  [Sentiments.Negative]: '#f38d8d',
  [Sentiments.Positive]: '#50ef90',
};

type InputRangeStyleConfig = {
  fromValue: number;
  toValue: number;
  color: 'primary' | 'secondary' | 'success' | 'error';
};

type InputRangeProps = {
  min?: number;
  max?: number;
  step: number;
  value: number;
  onChange: (val: number) => void;
  iconFeedbackReversed?: boolean;
  showSentimentScale?: boolean;
  culture?: AVAILABLE_CULTURES;
  styleConfig?: InputRangeStyleConfig[];
  legendDescriptions: AssessmentLegendDescriptions[];
};

type InputRangeLabelProps = {
  label: string;
};

const inputRangeTheme = createTheme({
  palette: {
    primary: {
      main: colorDefinitions[Sentiments.Neutral],
    },
    secondary: {
      main: colorDefinitions[Sentiments.NotSpecified],
    },
    success: {
      main: colorDefinitions[Sentiments.Positive],
    },
    error: {
      main: colorDefinitions[Sentiments.Negative],
    },
  },
});

const InputRange = ({
  max = 100,
  min = 0,
  onChange,
  step,
  value,
  legendDescriptions,
  iconFeedbackReversed = false,
  showSentimentScale = false,
  culture = AVAILABLE_CULTURES.EN,
  styleConfig = undefined,
}: InputRangeProps) => {
  const valueMin = min > max ? max : min;
  const valueMax = min > max ? min : max;

  const getColorBasedOnConfigAndValue = (currentValue: number) => {
    if (styleConfig === undefined) return 'primary';

    const configEntry = styleConfig.find(
      (p) => p.fromValue <= currentValue && p.toValue >= currentValue
    );

    if (!configEntry) return 'primary';

    return configEntry.color;
  };

  const [sliderColor, setSliderColor] = useState<string>(() =>
    getColorBasedOnConfigAndValue(value)
  );

  const handleChange = (_: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      onChange(newValue);
      setSliderColor(getColorBasedOnConfigAndValue(newValue));
      return;
    }

    onChange(newValue[0]);
    setSliderColor(getColorBasedOnConfigAndValue(newValue[0]));
  };

  return (
    <div className="d-flex flex-column align-items-stretch">
      {showSentimentScale && (
        <RangeQuestionIconFeedbackHorizontalList
          reversed={iconFeedbackReversed}
        />
      )}
      <ThemeProvider theme={inputRangeTheme}>
        <Slider
          color={
            sliderColor as OverridableStringUnion<
              'primary' | 'secondary',
              SliderPropsColorOverrides
            >
          }
          step={step}
          value={value}
          min={valueMin}
          max={valueMax}
          onChange={handleChange}
          valueLabelDisplay="auto"
          marks={legendDescriptions.map((description) => ({
            value: description.value,
            label: (
              <InputRangeLabel
                label={
                  (culture === AVAILABLE_CULTURES.EN
                    ? description.label
                    : description.label_NbNo) ?? ''
                }
              />
            ),
          }))}
        />
      </ThemeProvider>
    </div>
  );
};

const InputRangeLabel = ({ label }: InputRangeLabelProps) => {
  return (
    <div
      style={{
        height: '100px',
        inlineSize: '50px',
        overflow: 'hidden',
        textAlign: 'center',
        whiteSpace: 'pre-wrap',
        fontSize: '12px',
        color: '#000000',
      }}
    >
      {label}
    </div>
  );
};

export default InputRange;
