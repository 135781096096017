import React from 'react';
import EditableInput from '../../../../../../components/EditableInput/EditableInput';
import InputFieldWithoutImage from '../../../../../../components/InputFieldWithoutImage';
import { ICaseGroupFormValues } from '../../hooks/useCreateCaseGroupForm';
import { useCaseGroupsCommonData } from '../../../../hooks/useCaseGroupsCommonData';
import { useInitQuery } from '../../../../../../hooks/queries/useInitQuery';
import { useCreateCaseGroupProjectField } from './hooks/useCreateCaseGroupProjectField';

type CreateCaseGroupProjectFieldProps = {
  values: ICaseGroupFormValues;
  setValues: (
    values: React.SetStateAction<ICaseGroupFormValues>,
    shouldValidate?: boolean | undefined
  ) => void;
  translations: any;
  options: any;
};

const CreateCaseGroupProjectField = ({
  values,
  setValues,
  translations,
  options,
}: CreateCaseGroupProjectFieldProps) => {
  const { projects } = useCaseGroupsCommonData();
  useInitQuery(projects);

  const { handleChange } = useCreateCaseGroupProjectField(
    values,
    setValues,
    projects.projects
  );

  return (
    <InputFieldWithoutImage
      gray={true}
      noMargin={true}
      labelTranslationId={translations.projectLabel}
      inputId="project_id"
    >
      <EditableInput
        type="select"
        options={options.projects}
        required={true}
        placeholder={translations.projectPlaceholder}
        id="project_id"
        name="project_id"
        displayEmpty={true}
        value={values.project_id}
        onEdit={handleChange}
        debounceEnabled={false}
      />
    </InputFieldWithoutImage>
  );
};

export default CreateCaseGroupProjectField;
