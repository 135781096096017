import {
  strToDateOnly,
  toDateOnly,
} from '../../../../../../../../../../../../../../../helpers/dates';
import { ConversationScheduleType } from '../../../../../../../../../../../../../../../interfaces/conversationSchedules';
import { CaseNotificationSchedule } from '../../../../../../../../../../../../../../../interfaces/notificationSchedules';

export namespace DayScheduleFilterHelpers {
  export const DateFilter = (
    schedule: CaseNotificationSchedule,
    date: Date
  ) => {
    const selectedDateStr = toDateOnly(date);

    if (schedule.schedule_type === ConversationScheduleType.SingleInstance)
      return strToDateOnly(schedule.utc_timezone_time) === selectedDateStr;

    return (
      selectedDateStr >= strToDateOnly(schedule.utc_timezone_from) &&
      selectedDateStr <= strToDateOnly(schedule.utc_timezone_to)
    );
  };

  export const RecurringFilter = (schedule: CaseNotificationSchedule) => {
    return schedule.schedule_type !== ConversationScheduleType.Recurring;
  };
}
