import React from 'react';
import { BaseModalProps } from '../../../../../../interfaces/ui';
import { ConversationTemplate } from '../../../../../../interfaces/conversations';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useForm } from './hooks/useForm';
import { useTranslations } from '../../../../../../hooks/useTranslations';
import { Field, Formik } from 'formik';
import { useCultures } from './hooks/useCultures';
import { FormattedMessage, IntlProvider, useIntl } from 'react-intl';
import EventPropagationConstraint from '../../../../../../components/EventPropagationConstraint';
import ReactFlagsSelect from 'react-flags-select';
import { returnFormattedLang } from '../../../../../../translations/utils/cultures';
import ConversationTemplatePhone from '../../components/ConversationTemplatePhone';
import { v4 as uuid } from 'uuid';
import { useActions } from './hooks/useActions';
import ItemEdit from './components/ItemEdit';
import FieldInput from '../../../../../../components/FieldInput';
import FieldApplicableInput from '../../../../../../components/Fields/Formik/FieldApplicableInput';
import FieldSelect from '../../../../../../components/FieldSelect';
import { useOptions } from '../../../../../../hooks/useOptions';
import { useTriggerTypes } from './hooks/useTriggerTypes';
import ItemEditPlaceholder from './components/ItemEdit/placeholders/ItemEditPlaceholder';
import { IntlObjectContext } from './types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faUndo } from '@fortawesome/pro-light-svg-icons';
import LoadingButton from '../../../../../../components/LoadingButton';
import FormikUtilsContainer from './components/FormikUtilsContainer';

type ConversationTemplateModalProps = BaseModalProps & {
  template?: ConversationTemplate;
};

const ConversationTemplateModal = ({
  isOpen,
  template,
  toggle,
}: ConversationTemplateModalProps) => {
  const intl = useIntl();

  const { attachPrefix, wrapWithIntl } = useTranslations(
    'ConversationsAndFeedbacks.ConversationTemplates.ConversationTemplateModal'
  );

  const {
    flagLabels,
    flagCodes,
    selectedFlagCode,
    selectFlagCode,
    languageContext,
    messages,
  } = useCultures();

  const { initialValues, handleSubmit, validation } = useForm(template, toggle);

  const templateId = template?.id ?? uuid();

  const { getActions, selectedItemIndex } = useActions();

  const options = useOptions();

  const getTriggerTypes = useTriggerTypes();

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="GenericModal BorderlessModal NewConversationTemplateModal"
      size="lg"
    >
      <Formik
        validate={validation}
        validateOnMount={true}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ values, setValues, setFieldValue, ...rest }) => {
          const actions = getActions(values, setValues);

          const handleReset = () => rest.resetForm();

          return (
            <>
              <ModalHeader
                className="NewConversationTemplateModal__header"
                toggle={toggle}
              >
                <div className="d-flex w-100 align-items-center justify-content-between pe-2">
                  <FormattedMessage
                    tagName="span"
                    id={attachPrefix('conversationTemplateEditor')}
                    values={{ displayCode: values.display_code }}
                  />
                  <EventPropagationConstraint>
                    <ReactFlagsSelect
                      className="react-flag-select"
                      countries={flagCodes}
                      customLabels={flagLabels}
                      selected={selectedFlagCode}
                      onSelect={selectFlagCode}
                    />
                  </EventPropagationConstraint>
                </div>
              </ModalHeader>
              <FormikUtilsContainer value={{ ...rest, setValues }}>
                <ModalBody className="NewConversationTemplateModal__body">
                  <div className="d-flex flex-column">
                    <div className="d-flex justify-content-evenly align-items-center gap-3 mb-4">
                      <FieldApplicableInput
                        initialValues={initialValues}
                        setFieldValue={setFieldValue}
                        name="display_code"
                        field={
                          <Field
                            required={true}
                            placeholder={wrapWithIntl(
                              attachPrefix('displayCodePlaceholder')
                            )}
                            label={wrapWithIntl(attachPrefix('displayCode'))}
                            id="display_code"
                            name="display_code"
                            type="text"
                            component={FieldInput}
                          />
                        }
                      />
                      <FieldApplicableInput
                        initialValues={initialValues}
                        setFieldValue={setFieldValue}
                        name="context"
                        field={
                          <Field
                            required={true}
                            placeholder={wrapWithIntl(
                              attachPrefix('contextPlaceholder')
                            )}
                            label={wrapWithIntl(attachPrefix('context'))}
                            id="context"
                            name="context"
                            type="select"
                            options={options.conversationTemplateContexts()}
                            component={FieldSelect}
                          />
                        }
                      />
                      <FieldApplicableInput
                        initialValues={initialValues}
                        setFieldValue={setFieldValue}
                        name="trigger_type"
                        field={
                          <Field
                            required={true}
                            placeholder={wrapWithIntl(
                              attachPrefix('triggerTypePlaceholder')
                            )}
                            label={wrapWithIntl(attachPrefix('triggerType'))}
                            id="trigger_type"
                            name="trigger_type"
                            type="select"
                            options={getTriggerTypes(values.context)}
                            component={FieldSelect}
                          />
                        }
                      />
                    </div>
                    <div className="d-flex justify-content-between gap-1 mt-4">
                      <IntlProvider
                        locale={returnFormattedLang(
                          languageContext.cultureCode
                        )}
                        messages={messages}
                      >
                        <div className="d-flex justify-content-center align-items-start col-6">
                          <div className="pt-2">
                            <ConversationTemplatePhone
                              value={{ ...values, id: templateId }}
                              language={languageContext}
                              actions={actions}
                              demonstrative={true}
                            />
                          </div>
                        </div>
                        <div className="d-flex col-6 flex-grow-1">
                          <IntlObjectContext.Provider value={intl}>
                            {selectedItemIndex !== null ? (
                              <ItemEdit
                                index={selectedItemIndex}
                                values={values}
                                language={languageContext}
                              />
                            ) : (
                              <ItemEditPlaceholder />
                            )}
                          </IntlObjectContext.Provider>
                        </div>
                      </IntlProvider>
                    </div>
                  </div>
                </ModalBody>
                <ModalFooter className="NewConversationTemplateModal__footer">
                  <Button
                    disabled={!rest.dirty}
                    outline
                    color="primary"
                    onClick={handleReset}
                  >
                    <FontAwesomeIcon icon={faUndo} />
                  </Button>
                  <LoadingButton
                    isLoading={rest.isSubmitting}
                    disabled={!rest.isValid}
                    outline
                    color="primary"
                    onClick={rest.submitForm}
                  >
                    <FontAwesomeIcon icon={faSave} />
                  </LoadingButton>
                </ModalFooter>
              </FormikUtilsContainer>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ConversationTemplateModal;
