import { IPieChartData } from '../../../../../../../../../interfaces/charts';
import { useTranslations } from '../../../../../../../../../hooks/useTranslations';
import { IObservation } from '../../../../../../../../../interfaces/timeline';
import { useMemo } from 'react';
import { sum } from 'lodash';
import { differenceInSeconds } from 'date-fns';
import { useTypedContext } from '../../../../../../../../../hooks/useTypedContext';
import { AwareCaseContentDataContext } from '../../AwareCaseContent/types';

export const useFormattedData = (
  observations: IObservation[]
): IPieChartData[] => {
  const { awareSession } = useTypedContext(AwareCaseContentDataContext);

  const { attachPrefix, wrapWithIntl } = useTranslations(
    'Cases.CaseDetails.AwareCase.Duration'
  );

  const squeezingTime = useMemo(() => {
    return sum(observations.map((p) => p.duration_in_seconds));
  }, [observations]);

  const idleTime = useMemo(() => {
    const startDate = awareSession?.start_date;
    const endDate = awareSession?.end_date;

    if (!startDate || !endDate) return 0;

    return (
      differenceInSeconds(new Date(endDate), new Date(startDate)) -
      squeezingTime
    );
  }, [awareSession?.end_date, awareSession?.start_date, squeezingTime]);

  return [
    {
      name: wrapWithIntl(attachPrefix('idleTime')),
      value: idleTime,
    },
    {
      name: wrapWithIntl(attachPrefix('squeezingTime')),
      value: squeezingTime,
    },
  ];
};
