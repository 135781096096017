import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { UpsertTreatmentPhaseProps } from '../../../../../../../../../../../api/mutations/conversations/useModifyTreatmentPhase';
import { ISummarizedTreatmentPhase } from '../../../../../../../../../../../interfaces/conversations';
import { useTreatmentPhaseCode } from '../../../../../hooks/useTreatmentPhaseCode';
import { useHandleUpsert } from '../../../hooks/useHandleUpsert';

export const useHandleRemoveScheduleItem = (
  phase: ISummarizedTreatmentPhase
) => {
  const handleUpsert = useHandleUpsert();
  const getPhaseCode = useTreatmentPhaseCode();

  return useCallback(
    async (index: number) => {
      let contentCopy = cloneDeep(phase.content);

      contentCopy = contentCopy.filter((_, idx) => idx !== index);

      let upsert: UpsertTreatmentPhaseProps = {
        treatmentPhaseId: phase.id,
        body: {
          next_phase_condition: phase.next_phase_condition,
          display_code: phase.display_code,
          sequence: phase.sequence,
          empty_conversation_template_id: phase.empty_conversation_template_id,
          is_default: phase.is_default,
          days_duration: phase.days_duration,
          organization_id: phase.organization_id,
          content: contentCopy,
          code: getPhaseCode(phase.id),
        },
      };

      await handleUpsert(upsert);
    },
    [
      getPhaseCode,
      handleUpsert,
      phase.content,
      phase.days_duration,
      phase.display_code,
      phase.empty_conversation_template_id,
      phase.id,
      phase.is_default,
      phase.next_phase_condition,
      phase.organization_id,
      phase.sequence,
    ]
  );
};
