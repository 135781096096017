import useRequiredParams from '../../../../../../../../../../../hooks/useRequiredParams';
import { useCallback } from 'react';
import useUrlSearch from '../../../../../../../../../../../hooks/useUrlSearch';
import { useNavigate } from 'react-router';
import { IAwareSessionData } from '../../../../../../../../../../../interfaces/awareSessions';

export const useSessionNavigate = () => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const query = useUrlSearch();
  const aggregated = query.get('aggregated');

  const navigate = useNavigate();

  return useCallback(
    (session: IAwareSessionData) => () => {
      let aggregatedQuery: string = '?version=2';
      if (aggregated) aggregatedQuery = '?aggregated=true&version=2';

      navigate(
        `/organizations/${organizationId}/cases/${session.case_id}${aggregatedQuery}`
      );
    },
    [aggregated, navigate, organizationId]
  );
};
