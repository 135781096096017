import React, { memo } from 'react';
import { FeedbackValidConversationTemplateItem } from '../../../../../../../../../../../../interfaces/conversations';
import { format } from 'date-fns';
import ItemAvatar from '../ItemAvatar';
import { FormattedMessage } from 'react-intl';
import { useTranslations } from '../../../../../../../../../../../../hooks/useTranslations';

type FeedbackItemProps = {
  item: FeedbackValidConversationTemplateItem;
  avatars: string[] | undefined;
};

const FeedbackItem = ({ item, avatars }: FeedbackItemProps) => {
  const { attachPrefix } = useTranslations(
    'ConversationsAndFeedbacks.ConversationTemplates.ConversationTemplatePhone.Conversation.ConversationItem.FeedbackItem'
  );

  const currentHour = format(new Date(), 'HH:HH a');

  return (
    <div className="ConversationTemplatePlainTextItem">
      <div className="ConversationTemplatePlainTextItem__avatars me-2">
        {avatars ? (
          avatars.map((avatar) => <ItemAvatar key={avatar} url={avatar} />)
        ) : (
          <>
            <ItemAvatar />
            <ItemAvatar />
          </>
        )}
      </div>
      <div className="ConversationTemplatePlainTextItem__content">
        <div className="ConversationTemplatePlainTextItem__content__text">
          <FormattedMessage
            id={attachPrefix('feedbackFromGroup')}
            values={{ groupName: item.group_name }}
          />
        </div>
        <div className="ConversationTemplatePlainTextItem__content__hour">
          {currentHour}
        </div>
      </div>
    </div>
  );
};

export default memo(FeedbackItem);
