import { IMutationRequest } from '../../../interfaces/request';
import { OrganizationQueryParams } from '../../../interfaces/queryParams';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { deleteAsset } from '../../assets';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';

const useDeleteAsset = ({
  params,
  options,
}: IMutationRequest<OrganizationQueryParams>) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (assetId: string) => {
      await deleteAsset(params.organizationId, assetId);
    },
    resetOnResult: true,
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'Api.Mutations.Assets.DeleteAsset.failure'
      );
      options.errorFb?.();
    },
    onSuccess: () => {
      displaySuccessNotification('Api.Mutations.Assets.DeleteAsset.success');
      options.successFb?.();
    },
  });
};

export default useDeleteAsset;
