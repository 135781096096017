import {
  IMemberDashboardReport,
  ISelectableDashboardTemplateVisualization,
} from 'interfaces/dashboards';
import { useEffect } from 'react';
import { Expressions } from '../../../../../../../../../../../../../../../helpers/expressions';
import { IAwareSessionData } from '../../../../../../../../../../../../../../../interfaces/awareSessions';

export const useInitialSelectedEvent = (
  index: number | undefined,
  visualization: ISelectableDashboardTemplateVisualization,
  listViewData: IMemberDashboardReport[] | IAwareSessionData[]
) => {
  useEffect(() => {
    if (
      index === undefined ||
      !visualization.on_click ||
      !listViewData.at(index)
    )
      return;

    Expressions.Evaluate(visualization.on_click, {
      item: listViewData[index],
    });
  }, [index, listViewData, visualization.on_click]);
};
