import scheduledIcon from 'assets/images/arrow_up.svg';
import completedIcon from 'assets/images/checked_icon.svg';
import inProgressIcon from 'assets/images/pending.svg';
import classNames from 'classnames';
import { formatDate } from 'helpers/dates';
import { textWrapEllipse } from 'helpers/utils/strings';
import useMobile from 'hooks/useBreakpoint';
import { ICasePhaseHistory } from 'interfaces/cases';
import { setCasePeriodDateRange } from 'modules/TimelineDetails/actions';
import React from 'react';
import SVG from 'react-inlinesvg';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

type ChangeCasePeriodItemProps = {
  item: ICasePhaseHistory;
  index: number;
  selectedItemsBasedOnDateRange: ICasePhaseHistory[];
  itemsCount: number;
  forceActive?: boolean;
};

const PeriodRangeSelectionItem = ({
  item,
  index,
  selectedItemsBasedOnDateRange,
  itemsCount,
  forceActive = false,
}: ChangeCasePeriodItemProps) => {
  const isMobile = useMobile();
  const intl = useIntl();

  const dispatch = useDispatch();

  const isCompleted = item.date_from !== null && item.date_to !== null;
  const isInProgress = item.date_from !== null && item.date_to === null;
  const isScheduled = item.date_from === null && item.date_to === null;

  const inProgressOrScheduled = isInProgress ? inProgressIcon : scheduledIcon;
  const icon = isCompleted ? completedIcon : inProgressOrScheduled;

  const handleSelectPeriod = () => {
    if (isScheduled || item.date_from === null) return;

    const from = new Date(item.date_from);
    const to = item.date_to ? new Date(item.date_to) : new Date();

    const customDateRange = {
      from: from,
      to: to,
    };

    dispatch(setCasePeriodDateRange(customDateRange));
  };

  const isActive = selectedItemsBasedOnDateRange.some(
    (selectedItem) =>
      selectedItem.case_id === item.case_id &&
      selectedItem.date_from === item.date_from &&
      selectedItem.date_to === item.date_to &&
      selectedItem.display_code === item.display_code
  );

  return (
    <div className="ChangeCasePeriodSelector__item">
      <div
        style={{ cursor: isScheduled ? 'not-allowed' : 'pointer' }}
        className={classNames({
          ChangeCasePeriodSelector__bar: true,
          'ChangeCasePeriodSelector__bar--active': isActive || forceActive,
        })}
        onClick={handleSelectPeriod}
      >
        {isMobile ? textWrapEllipse(item.display_code, 15) : item.display_code}
        <SVG
          src={icon}
          className="ms-1"
          transform={icon === scheduledIcon ? 'rotate(90)' : 'rotate(0)'}
          title={intl.formatMessage({
            id: 'Cases.ChangeCase.ChangeCaseRangeSelector.ChangeCasePeriodRangeSelector.completionStatus',
          })}
        />
      </div>
      <div className="ChangeCasePeriodSelector__dates">
        {item.date_from && index === 0 && (
          <span className="ChangeCasePeriodSelector__label">
            {formatDate(item.date_from, { ignoreTime: true })}
          </span>
        )}
        {item.date_to && (
          <div className="ChangeCasePeriodSelector__label ChangeCasePeriodSelector__label--end">
            <div
              className={classNames({
                'ChangeCasePeriodSelector__label-end-wrapper': true,
                'ChangeCasePeriodSelector__label-end-wrapper--moved':
                  itemsCount > 1 && itemsCount - 1 !== index,
              })}
            >
              <span>{formatDate(item.date_to, { ignoreTime: true })}</span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PeriodRangeSelectionItem;
