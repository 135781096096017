import { faTimes } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLayoutEffect } from 'react';
import { createPortal } from 'react-dom';

type IFrameFullPageProps = {
  src: string;
  isOpen?: boolean;
  toggleIFrame: () => void;
};

const IFrameFullPage = ({ src, isOpen, toggleIFrame }: IFrameFullPageProps) => {
  const node = document.getElementById('iframe-overlay') as HTMLElement;

  useLayoutEffect(() => {
    const body = document.querySelector('body') as HTMLBodyElement;

    if (isOpen) {
      body.style.overflow = 'hidden';
    }

    return () => {
      body.style.overflow = 'auto';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return createPortal(
    <div className="IFrameOverlay">
      <div className="IFrameOverlay__wrapper" onClick={toggleIFrame}>
        <button onClick={toggleIFrame} className="IFrameOverlay__close">
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <iframe
          src={src}
          title="page-overlay"
          className="IFrameOverlay__iframe"
        />
      </div>
    </div>,
    node
  );
};

export default IFrameFullPage;
