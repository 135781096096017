import useInfiniteOrganizationMembers from 'api/queries/organizations/useInfiniteOrganizationMembers';
import LoadingButton from 'components/LoadingButton';
import PersonalityImage from 'components/PersonalityImage';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Alert,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

type MedicalPersonnelAssignModalProps = {
  organizationId: string;
  assignedPersonalityIds: string[];
  isOpen: boolean;
  toggle: () => void;
  headerTranslationId: string;
  subHeader?: JSX.Element;
  submitFn: (personalities: string[]) => void;
  isLoading: boolean;
  isPatientModal?: boolean;
};

const MedicalPersonnelAssignModal = ({
  organizationId,
  assignedPersonalityIds,
  headerTranslationId,
  isOpen,
  toggle,
  submitFn,
  isLoading,
  isPatientModal = false,
  subHeader,
}: MedicalPersonnelAssignModalProps) => {
  const [checkedPersonalityIds, setCheckedPersonalityIds] = useState<string[]>(
    assignedPersonalityIds
  );

  const { members: organizationMembers } = useInfiniteOrganizationMembers({
    params: { organizationId },
  });

  const assignedMedicalPersonnel: JSX.Element[] = [];
  const notAssignedMedicalPersonnel: JSX.Element[] = [];

  const onCheckInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const personalityId = e.target.name;

    setCheckedPersonalityIds((currentCheckedIds) => {
      if (currentCheckedIds.includes(personalityId)) {
        return currentCheckedIds.filter((el) => el !== personalityId);
      } else {
        return [...currentCheckedIds, personalityId];
      }
    });
  };

  organizationMembers.forEach((member) => {
    const isChecked = checkedPersonalityIds.includes(member.id);

    const memberElement = (
      <li
        key={`list-item-${member.id}`}
        className="d-flex w-100 align-items-center justify-content-between pb-2"
      >
        <div className="d-flex align-items-center">
          <PersonalityImage />
          <div className="MedicalPersonnelAssignModal__member-item__data">
            <div className="MedicalPersonnelAssignModal__member-item__data__name">
              {member.first_name} {member.last_name}
            </div>
            <div className="MedicalPersonnelAssignModal__member-item__data__email">
              {member.email}
            </div>
          </div>
        </div>
        <div>
          <Input
            name={member.id}
            type="checkbox"
            checked={isChecked}
            onChange={onCheckInput}
          />
        </div>
      </li>
    );

    if (isChecked) assignedMedicalPersonnel.push(memberElement);
    else notAssignedMedicalPersonnel.push(memberElement);
  });

  useEffect(() => {
    setCheckedPersonalityIds(assignedPersonalityIds);
  }, [assignedPersonalityIds]);

  const onSave = () => {
    submitFn(checkedPersonalityIds);
  };

  const alertInfo = useMemo(() => {
    let isMedicalPersonnelDeleted = false;
    let warningMessages: JSX.Element[] = [];

    assignedPersonalityIds.forEach((assignPersonalityId) => {
      if (!checkedPersonalityIds.includes(assignPersonalityId)) {
        isMedicalPersonnelDeleted = true;

        const personality = organizationMembers.find(
          (el) => el.id === assignPersonalityId
        );

        warningMessages.push(
          <p key={`warning-message-${assignPersonalityId}`}>
            <FormattedMessage
              id="Patient.deleteMedicalPersonnelWarning"
              values={{
                name: `${personality?.first_name} ${personality?.last_name}`,
              }}
            />
          </p>
        );
      }
    });

    return { isMedicalPersonnelDeleted, warningMessages };
  }, [checkedPersonalityIds, assignedPersonalityIds, organizationMembers]);

  return (
    <Modal size="md" toggle={toggle} isOpen={isOpen}>
      <ModalHeader toggle={toggle} className="modal-without-borders__header">
        <FormattedMessage id={headerTranslationId} />
        {subHeader}
      </ModalHeader>
      <ModalBody className="modal-without-borders__body">
        {isPatientModal && alertInfo.isMedicalPersonnelDeleted && (
          <Alert color="danger">{alertInfo.warningMessages}</Alert>
        )}
        <div className="d-flex justify-content-between">
          <div className="MedicalPersonnelAssignModal__group-header">
            <span>
              <FormattedMessage id="Patient.assigned" />
            </span>{' '}
            <FormattedMessage id="Patient.medicalPersonnel" />
          </div>
          <div>
            <FormattedMessage id="Patient.access" />
          </div>
        </div>
        <ul className="MedicalPersonnelAssignModal__member-list">
          {assignedMedicalPersonnel.length ? (
            assignedMedicalPersonnel
          ) : (
            <Alert color="warning">
              <FormattedMessage id="Patient.noAssignedMedicalPersonnel" />
            </Alert>
          )}
        </ul>
        <div className="MedicalPersonnelAssignModal__group-header mt-4">
          <FormattedMessage id="Patient.allMedicalPersonnel" />
        </div>
        <ul className="MedicalPersonnelAssignModal__member-list">
          {notAssignedMedicalPersonnel.length ? (
            notAssignedMedicalPersonnel
          ) : (
            <Alert color="light">
              <FormattedMessage id="General.noRecords" />
            </Alert>
          )}
        </ul>
      </ModalBody>
      <ModalFooter className="modal-without-borders__footer">
        <Button color="primary" outline onClick={toggle}>
          <FormattedMessage id="General.cancel" />
        </Button>
        <LoadingButton isLoading={isLoading} color="primary" onClick={onSave}>
          <FormattedMessage id="General.save" />
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
};

export default MedicalPersonnelAssignModal;
