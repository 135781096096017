import {
  ConversationScheduleType,
  ICaseGroupConversationSchedule,
  ICreateCaseGroupConversationSchedule,
  ISchedulesEditTimeFormInitialValues,
} from '../../../../../../../../../interfaces/conversationSchedules';
import { useUpdateCaseGroupConversationScheduleData } from '../../../../../../../../../api/mutations/conversationSchedules/useUpdateCaseGroupConversationSchedule';
import { ICaseGroupSchedule } from '../../../../../../../../../interfaces/caseGroups';
import { addDays } from 'date-fns';
import { useCallback } from 'react';
import { strToDateOnly } from '../../../../../../../../../helpers/dates';

export const useCaseGroupDetailsEditTimeFormPayload = () => {
  const getDateBasedOnGroupSchedule = useCallback(
    (day: number | null, groupSchedule: ICaseGroupSchedule) => {
      if (day === null) return null;
      const targetDate = addDays(new Date(groupSchedule.date_from), day);
      return targetDate.toISOString();
    },
    []
  );

  const normalizeIsoDate = useCallback((str: string) => {
    const dateStr = strToDateOnly(str);
    const date = new Date(dateStr);

    return date.toISOString();
  }, []);

  const prepareNonTimeBasedUpdatePayload = useCallback(
    (
      schedule: ICaseGroupConversationSchedule,
      values: ISchedulesEditTimeFormInitialValues
    ): useUpdateCaseGroupConversationScheduleData => {
      return {
        id: schedule.id,
        data: {
          available_from_hour: null,
          available_to_hour: null,
          date: null,
          date_from: null,
          date_to: null,
          notification_hour: null,
          priority: values.priority,
          recurring_interval: null,
        },
      };
    },
    []
  );

  const prepareSimpleSingleInstanceUpdatePayload = useCallback(
    (
      schedule: ICaseGroupConversationSchedule,
      values: ISchedulesEditTimeFormInitialValues,
      groupSchedule: ICaseGroupSchedule
    ): useUpdateCaseGroupConversationScheduleData => {
      let date = getDateBasedOnGroupSchedule(
        values.specific_day_of_phase,
        groupSchedule
      );

      if (date) date = normalizeIsoDate(date);

      return {
        id: schedule.id,
        data: {
          priority: values.priority,
          available_from_hour: values.available_from_hour,
          available_to_hour: values.available_to_hour,
          notification_hour: values.notification_hour,
          recurring_interval: null,
          date_to: null,
          date_from: null,
          date: date,
        },
      };
    },
    [getDateBasedOnGroupSchedule, normalizeIsoDate]
  );

  const prepareRecurringUpdatePayload = useCallback(
    (
      schedule: ICaseGroupConversationSchedule,
      values: ISchedulesEditTimeFormInitialValues,
      groupSchedule: ICaseGroupSchedule
    ): useUpdateCaseGroupConversationScheduleData => {
      let dateFrom = getDateBasedOnGroupSchedule(
        values.start_day_offset,
        groupSchedule
      );

      let dateTo = getDateBasedOnGroupSchedule(
        values.end_day_offset,
        groupSchedule
      );

      if (dateFrom) dateFrom = normalizeIsoDate(dateFrom);
      if (dateTo) dateTo = normalizeIsoDate(dateTo);

      return {
        id: schedule.id,
        data: {
          priority: values.priority,
          date: null,
          date_from: dateFrom,
          date_to: dateTo,
          recurring_interval: values.recurring_interval,
          available_from_hour: values.available_from_hour,
          available_to_hour: values.available_to_hour,
          notification_hour: values.notification_hour,
        },
      };
    },
    [getDateBasedOnGroupSchedule, normalizeIsoDate]
  );

  const prepareRecurringAddPayload = useCallback(
    (
      values: ISchedulesEditTimeFormInitialValues,
      groupSchedule: ICaseGroupSchedule
    ): ICreateCaseGroupConversationSchedule => {
      let dateFrom = getDateBasedOnGroupSchedule(
        values.start_day_offset,
        groupSchedule
      );

      let dateTo = getDateBasedOnGroupSchedule(
        values.end_day_offset,
        groupSchedule
      );

      if (dateFrom) dateFrom = normalizeIsoDate(dateFrom);
      if (dateTo) dateTo = normalizeIsoDate(dateTo);

      return {
        template_id: values.template_id,
        priority: values.priority,
        phase_id: groupSchedule.phase_id,
        notification_hour: values.notification_hour,
        available_from_hour: values.available_from_hour,
        available_to_hour: values.available_to_hour,
        recurring_interval: values.recurring_interval,
        date_from: dateFrom,
        date_to: dateTo,
        date: null,
        type: ConversationScheduleType.Recurring,
        recurring_origin_id: null,
        is_dirty: false,
      };
    },
    [getDateBasedOnGroupSchedule, normalizeIsoDate]
  );

  const prepareDirtySingleInstanceAddPayload = useCallback(
    (
      schedule: ICaseGroupConversationSchedule,
      values: ISchedulesEditTimeFormInitialValues,
      groupSchedule: ICaseGroupSchedule,
      selectedDate: string
    ): ICreateCaseGroupConversationSchedule => {
      let date = getDateBasedOnGroupSchedule(
        values.specific_day_of_phase,
        groupSchedule
      );

      if (!date) date = selectedDate;
      if (date) date = normalizeIsoDate(date);

      return {
        template_id: values.template_id,
        priority: values.priority,
        phase_id: groupSchedule.phase_id,
        notification_hour: values.notification_hour,
        available_from_hour: values.available_from_hour,
        available_to_hour: values.available_to_hour,
        recurring_interval: null,
        date_from: null,
        date_to: null,
        date: date,
        type: ConversationScheduleType.SingleInstance,
        recurring_origin_id: schedule.id,
        is_dirty: true,
      };
    },
    [getDateBasedOnGroupSchedule, normalizeIsoDate]
  );

  return {
    nonTimeBasedUpdatePayload: prepareNonTimeBasedUpdatePayload,
    simpleSingleInstanceUpdatePayload: prepareSimpleSingleInstanceUpdatePayload,
    recurringUpdatePayload: prepareRecurringUpdatePayload,
    recurringAddPayload: prepareRecurringAddPayload,
    dirtySingleInstanceAddPayload: prepareDirtySingleInstanceAddPayload,
  };
};
