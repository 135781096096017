import {
  ScheduleItemDefinition,
  ScheduleItemTemplatePartsOfADayDefinition,
} from 'components/Schedule';
import { addDays } from 'date-fns';
import { getRowToMergeWith as _getRowToMergeWith } from 'helpers/utils/conversationSchedules';
import {
  ICaseConversationSchedule,
  ISingleInstanceCaseConversationSchedule,
} from 'interfaces/conversationSchedules';
import React, { useCallback } from 'react';

export const usePrepareSchedulesUtils = (
  setSchedule: React.Dispatch<
    React.SetStateAction<ICaseConversationSchedule | null>
  >,
  toggle: () => void
) => {
  const getSingleInstancePartOfADay = useCallback(
    (
      current: ICaseConversationSchedule,
      recurring: ICaseConversationSchedule,
      _?: Date
    ): ScheduleItemTemplatePartsOfADayDefinition => {
      const singleInstance = current as ISingleInstanceCaseConversationSchedule;

      return {
        notification_hour: singleInstance.notification_hour,
        available_from_hour: singleInstance.available_from_hour,
        available_to_hour: singleInstance.available_to_hour,
        is_completed: singleInstance.is_completed,
        is_deleted: false,
        is_dirty: false,
        onEdit: () => {
          setSchedule(recurring);
          toggle();
        },
      };
    },
    [setSchedule, toggle]
  );

  const getRowToMergeWith = useCallback(
    (
      items: ScheduleItemDefinition[],
      currentSchedule: ICaseConversationSchedule,
      recurringSchedule: ICaseConversationSchedule
    ) => {
      return _getRowToMergeWith(
        items,
        currentSchedule,
        recurringSchedule,
        addDays(new Date(), 1),
        getSingleInstancePartOfADay
      );
    },
    [getSingleInstancePartOfADay]
  );

  return {
    getSingleInstancePartOfADay: getSingleInstancePartOfADay,
    getRowToMergeWith: getRowToMergeWith,
  };
};
