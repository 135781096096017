import { ICaseFilters, ICategory } from 'interfaces/cases';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { IBaseCaseGroup } from '../../../../../../../../../interfaces/caseGroups';

type useCaseFilterStringParams = {
  categoriesData: ICategory[] | undefined;
  groupsData: IBaseCaseGroup[] | undefined;
  filters: ICaseFilters | null;
};

export const useCaseFilterString = ({
  categoriesData,
  groupsData,
  filters,
}: useCaseFilterStringParams) => {
  const intl = useIntl();

  const { filtersLength, filterString } = useMemo(() => {
    let statusFiltersArray = [...(filters?.statuses ?? [])];

    if (filters?.categoryId && categoriesData) {
      const categoryFound = categoriesData.find(
        (category) => category.id === filters?.categoryId
      );

      const subCategoryFound = categoryFound?.sub_categories.find(
        (subCat) => subCat.id === filters.subCategoryId
      );

      statusFiltersArray.push(categoryFound?.name ?? '');
      statusFiltersArray.push(subCategoryFound?.name ?? '');
    }

    if (filters?.caseGroupId && groupsData) {
      const existingGroup = groupsData.find(
        (p) => p.id === filters.caseGroupId
      );

      statusFiltersArray.push(
        existingGroup
          ? intl.formatMessage(
              {
                id: 'Cases.CasesList.CasesFilter.groupNo',
              },
              { number: existingGroup.sequence }
            )
          : ''
      );

      if (filters.caseGroupId === 'unassigned') {
        statusFiltersArray.push(
          intl.formatMessage({
            id: 'Cases.CasesList.CasesFilter.unassigned',
          })
        );
      }
    }

    if (filters?.synchronizationStatus) {
      statusFiltersArray.push(
        `${intl.formatMessage({
          id: 'OrganizationCases.synchronization',
        })}: ${intl.formatMessage({
          id: `OrganizationCases.FeedbackStatus.${filters.synchronizationStatus}`,
        })}`
      );
    }

    if (filters?.feedbackStatus) {
      statusFiltersArray.push(
        `${intl.formatMessage({
          id: 'OrganizationCases.feedback',
        })}: ${intl.formatMessage({
          id: `OrganizationCases.FeedbackStatus.${filters.feedbackStatus}`,
        })}`
      );
    }

    statusFiltersArray = statusFiltersArray.filter((p) => p !== '');

    return {
      filtersLength: statusFiltersArray.length || 0,
      filterString: statusFiltersArray.join(', '),
    };
  }, [
    filters?.statuses,
    filters?.categoryId,
    filters?.caseGroupId,
    filters?.synchronizationStatus,
    filters?.feedbackStatus,
    filters?.subCategoryId,
    categoriesData,
    groupsData,
    intl,
  ]);

  return {
    filterString: filterString,
    filterLength: filtersLength,
  };
};
