import { useChartsData } from './useChartsData';
import { useCallback, useMemo } from 'react';
import { useQueriesLoading } from '../../../../../hooks/queries/useQueriesLoading';
import { useObservationsQuery } from './useObservationsQuery';
import { useObservationData } from './useObservationData';
import { useObservationsWithMeanings } from './useObservationsWithMeanings';
import { useInitQuery } from '../../../../../hooks/queries/useInitQuery';
import { AllSqueezesMode, AllSqueezesQueries } from '../../../types';

export const useAllSqueezesChart = (
  date: Date,
  mode: AllSqueezesMode,
  queries: AllSqueezesQueries
) => {
  const { observationsForPeriodQuery } = useObservationsQuery(date, queries);
  useInitQuery(queries.observationMeanings);

  const { observationsWithMeanings, isLoading: meaningsLoading } =
    useObservationsWithMeanings(observationsForPeriodQuery.data ?? [], queries);

  const observationsData = useObservationData(
    observationsWithMeanings,
    date,
    mode,
    queries
  );

  const { squeezesSum, totalSum, getColorForMeaning } =
    useChartsData(observationsData);

  const getVisualizationOrderForMeaning = useCallback(
    (name: string) => {
      return (
        queries.observationMeanings.data?.find(
          (p) => p.name_en === name || p.name_no === name
        )?.visualization_order ?? 0
      );
    },
    [queries.observationMeanings.data]
  );

  const formattedData = useMemo(() => {
    let _formattedData: any[] = [];

    Object.entries(squeezesSum).forEach(([key, val]) => {
      _formattedData.push({ name: key, value: val });
    });

    _formattedData.sort(
      (a, b) =>
        getVisualizationOrderForMeaning(
          ((Object.values(b)?.at(0) ?? '') as string) ?? ''
        ) -
        getVisualizationOrderForMeaning(
          ((Object.values(a)?.at(0) ?? '') as string) ?? ''
        )
    );

    return _formattedData;
  }, [getVisualizationOrderForMeaning, squeezesSum]);

  const isLoading =
    useQueriesLoading([queries.caseDetails, observationsForPeriodQuery]) ||
    meaningsLoading;

  return {
    formattedData,
    isLoading,
    totalSum,
    getColorForMeaning,
  };
};
