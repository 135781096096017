import { useCallback, useMemo } from 'react';
import { ScheduleItemDefinition } from '../../../../../../../../../../../components/Schedule';
import { OpenHookValues } from '../../../../../../../../../../../hooks/useOpen';
import {
  ISummarizedTreatmentPhase,
  TreatmentPhaseContentItemType,
} from '../../../../../../../../../../../interfaces/conversations';
import { ContentIndexHookValues } from '../../../hooks/useContentIndex';
import { useHandleRemoveScheduleItem } from './useHandleRemoveScheduleItem';

export const useNotificationSchedules = (
  initialPhase: ISummarizedTreatmentPhase,
  contentIndex: ContentIndexHookValues,
  addNotificationModal: OpenHookValues
) => {
  const handleRemoveScheduleItem = useHandleRemoveScheduleItem(initialPhase);

  const handleRemove = useCallback(
    (index: number) => async () => {
      await handleRemoveScheduleItem(index);
    },
    [handleRemoveScheduleItem]
  );

  const handleEdit = useCallback(
    (index: number) => () => {
      contentIndex.selectIndex(index);
      addNotificationModal.toggle();
    },
    [addNotificationModal, contentIndex]
  );

  return useMemo(() => {
    let _items: ScheduleItemDefinition[] = [];

    initialPhase.content.forEach((template, index) => {
      if (template.type !== TreatmentPhaseContentItemType.NotificationSchedule)
        return;

      if (!template.schedule_templates.at(0)) return;

      _items.push({
        rowId: index.toString(),
        sequence: index,
        templateId: template.notification_type,
        scheduleTemplate: {
          schedule_type: template.schedule_templates[0].schedule_type,
          parts_of_a_day: (
            template.schedule_templates[0].parts_of_a_day ?? []
          ).map((partOfADay) => ({
            available_from_hour: partOfADay.available_from_hour,
            available_to_hour: partOfADay.available_to_hour,
            notification_hour: partOfADay.notification_hour,
            is_deleted: false,
            is_dirty: false,
          })),
          end_day_offset: template.schedule_templates[0].end_day_offset,
          recurring_interval: template.schedule_templates[0].recurring_interval,
          specific_day_of_phase:
            template.schedule_templates[0].specific_day_of_phase,
          start_day_offset: template.schedule_templates[0].start_day_offset,
        },
        onRemove: handleRemove(index),
        onEdit: handleEdit(index),
      });
    });

    return _items;
  }, [handleEdit, handleRemove, initialPhase.content]);
};
