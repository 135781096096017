import React from 'react';
import { useInitQuery } from '../../../../../../../../../../../../hooks/queries/useInitQuery';
import { useTypedContext } from '../../../../../../../../../../../../hooks/useTypedContext';
import {
  DashboardTemplateDataSource,
  IMemberDashboardReport,
} from '../../../../../../../../../../../../interfaces/dashboards';
import {
  IReferenced,
  ITotalRowsPage,
} from '../../../../../../../../../../../../interfaces/response';
import QueryLoaded from '../../../../../../../../../../../QueryLoaded';
import { DashboardDataReducerContext } from '../../../../../../types';
import { useDashboardReportsQuery } from './hooks/useDashboardReportsQuery';

type DashboardReportsProviderProps = {
  children: (
    reports: IReferenced<ITotalRowsPage<IMemberDashboardReport>>
  ) => JSX.Element;
};

const DashboardReportsProvider = ({
  children,
}: DashboardReportsProviderProps) => {
  const [dashboardData] = useTypedContext(DashboardDataReducerContext);

  if (
    dashboardData.data_source !== DashboardTemplateDataSource.DashboardReports
  )
    return children({
      current: { data: [], total_rows_count: 0 },
      reference: { data: [], total_rows_count: 0 },
    });

  return <DashboardReportsQueryProvider children={children} />;
};

const DashboardReportsQueryProvider = ({
  children,
}: DashboardReportsProviderProps) => {
  const query = useDashboardReportsQuery();
  useInitQuery(query);

  return <QueryLoaded query={query}>{(data) => children(data)}</QueryLoaded>;
};

export default DashboardReportsProvider;
