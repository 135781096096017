import { useAddAccessProfileRoles } from '../../../../../../../../../../../../../api/mutations/accessProfiles/useAddAccessProfileRoles';
import { useOrganizationPathParams } from '../../../../../../../../../../../../../hooks/paths/useOrganizationPathParams';
import useOrganizationUserAccessProfiles from '../../../../../../../../../../../../../api/queries/accessProfiles/useOrganizationUserAccessProfiles';

export const useHandleAdd = () => {
  const { organizationId } = useOrganizationPathParams();

  const accessProfiles = useOrganizationUserAccessProfiles({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const { mutateAsync: addRoleAsync } = useAddAccessProfileRoles(
    { organizationId },
    { successFb: accessProfiles.refetch }
  );

  return addRoleAsync;
};
