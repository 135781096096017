import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createRootReducer from './reducers';

const enhancers: typeof compose[] = [];

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const middleware = [thunk];

const composedEnhancers = compose(
  applyMiddleware<typeof middleware>(...middleware),
  ...enhancers
);

const configureStore = (preloadedState?) =>
  createStore(
    createRootReducer(),
    preloadedState,
    //TODO: FIX this typescript ....
    composedEnhancers as any
  );

const store = configureStore();

export type RootState = ReturnType<typeof store.getState>;
export default store;
