import { getSymptoms } from 'api/symptoms';
import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { IQueryOptions } from '../../../interfaces/ui';

const useSymptoms = (options?: IQueryOptions) => {
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getSymptoms();
      return data.data;
    },
    queryKey: [QueryKeys.getSymptoms],
  });
};

export default useSymptoms;
