import React from 'react';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';

const Sending = ({ id }) => (
  <>
    <FormattedMessage id={id} />
    <FontAwesomeIcon className="ms-2" icon={faSpinner} spin />
  </>
);

export default Sending;
