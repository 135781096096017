import { useRef } from 'react';
import { isSameDay } from 'date-fns';
import useTimelineSqueezeDataFetch from '../../../../../../../api/queries/timelines/useTimelineSqueezeDataFetch';
import { Granularity } from '../../../../../../../interfaces/timeline';
import BuddyCaseMountain from './BuddyCaseMountain';
import { useTimelineDetailsDateRangeObject } from 'store/reducerHooks';

type BuddyCaseTodaySqueezesContentProps = {
  timelineId: string;
  selectedDate: Date;
  setSelectedDate: (date: Date) => void;
};

const BuddyCaseTodaySqueezesContent = ({
  timelineId,
  selectedDate,
  setSelectedDate,
}: BuddyCaseTodaySqueezesContentProps) => {
  const dateRef = useRef(selectedDate);
  const dateRangeObject = useTimelineDetailsDateRangeObject();

  const { data: dailySqueezes } = useTimelineSqueezeDataFetch({
    params: {
      timelineId: timelineId,
      granularity: Granularity.d1,
      dateRangeObject: dateRangeObject,
    },
  });

  const sumSeries = dailySqueezes?.series?.find(
    (s) => s.mode === 'SqueezesQuantity'
  );

  const values = sumSeries?.data ?? [];
  const days = dailySqueezes?.x_axis ?? [];

  const currentIdx = days.findIndex((d) =>
    isSameDay(new Date(d), selectedDate)
  );
  const initialIdx = days.findIndex((d) =>
    isSameDay(new Date(d), dateRef.current)
  );

  const pointClickCallback = (index: number) => {
    const date = new Date(days[index]);
    setSelectedDate(date);
  };

  return (
    <div className="BuddyCaseTodaySqueezesContent">
      <BuddyCaseMountain
        values={values}
        currentIdx={currentIdx}
        initialIdx={initialIdx}
        onPointClick={pointClickCallback}
      />
    </div>
  );
};

export default BuddyCaseTodaySqueezesContent;
