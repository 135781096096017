import React from 'react';
import { useApplicableInput } from '../../../ApplicableInput/hooks/useApplicableInput';
import ApplicableInput from '../../../ApplicableInput';
import ControlledApplicableInput from '../../../ApplicableInput/components/ControlledApplicableInput';
import { TrendsChartConfigHeader } from '../../types';
import { useTranslations } from '../../../../hooks/useTranslations';
import { TemplateItemType } from '../../../../interfaces/change';
import { AVAILABLE_CULTURES } from '../../../CultureSelector/CultureSelector';

type TrendsChartHeaderProps = {
  type: TemplateItemType;
  config?: TrendsChartConfigHeader;
};

const TrendsChartHeader = ({ type, config }: TrendsChartHeaderProps) => {
  const { wrapWithIntl, attachPrefix } = useTranslations(
    'TrendsChart.TrendsChartHeader'
  );

  const defaultTitle = wrapWithIntl(attachPrefix('defaultTitle'));
  const defaultSubtitle = wrapWithIntl(attachPrefix(type));

  const control = useApplicableInput(config?.title, config?.onAccept);

  const controlNo = useApplicableInput(
    config?.titleTranslation,
    config?.onAccept
  );

  const handleEdit = (_: string, value: string) =>
    config?.onEdit?.(value, AVAILABLE_CULTURES.EN);

  const handleNoEdit = (_: string, value: string) =>
    config?.onEdit?.(value, AVAILABLE_CULTURES.NO);

  return (
    <div className="TrendsChartHeader d-flex flex-column">
      <>
        {config?.onEdit || config?.onAccept ? (
          <div className="d-flex flex-column gap-2">
            <ApplicableInput control={control} undo={true} disabledForm="input">
              {(props) => (
                <ControlledApplicableInput
                  control={props.control}
                  onEdit={handleEdit}
                />
              )}
            </ApplicableInput>
            <ApplicableInput
              control={controlNo}
              undo={true}
              culture={AVAILABLE_CULTURES.NO}
              disabledForm="input"
            >
              {(props) => (
                <ControlledApplicableInput
                  control={props.control}
                  onEdit={handleNoEdit}
                />
              )}
            </ApplicableInput>
          </div>
        ) : (
          <>
            {config?.titleEnabled && (
              <span className="TrendsChartHeader__title">
                {config?.title ?? defaultTitle}
              </span>
            )}
          </>
        )}
      </>
      {config?.subtitleEnabled && (
        <span className="TrendsChartHeader__subtitle">
          {config.subtitle ?? defaultSubtitle}
        </span>
      )}
    </div>
  );
};

export default TrendsChartHeader;
