import useConversationAnswerHistory from 'api/queries/conversations/useConversationAnswerHistory';
import { useState } from 'react';
import { useCaseParticipant } from './useCaseParticipant';
import { useCommonParams } from './useCommonParams';

export const useConversationAnswerHistoryData = () => {
  const { organizationId, caseId } = useCommonParams();
  const { personality } = useCaseParticipant();

  const quickSearchState = useState<string | undefined>(undefined);
  const templateIdsState = useState<string[] | undefined>(undefined);
  const _templateIdsState = useState<string[] | undefined>(undefined);

  const [templateIds] = templateIdsState;
  const [quickSearchValue] = quickSearchState;

  const conversationHistoryQuery = useConversationAnswerHistory({
    params: {
      organizationId,
      caseId,
      personalityId: personality?.id ?? '',
      templateIds: templateIds ?? [],
      searchValue: quickSearchValue ?? '',
    },
    options: { enabled: false, staleTime: Infinity },
  });

  return {
    conversationHistory: conversationHistoryQuery,
    quickSearchState: quickSearchState,
    templateIdsState: templateIdsState,
    _templateIdsState: _templateIdsState,
  };
};
