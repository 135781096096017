import { useMemo } from 'react';
import {
  AssetType,
  IAsset,
} from '../../../../../interfaces/educationMaterials';
import ImagePreview from '../components/AssetPreview/components/ImagePreview';
import NotAvailablePreview from '../components/AssetPreview/components/NotAvailablePreview';
import VideoPreview from '../components/AssetPreview/components/VideoPreview';

export const usePreview = (asset: IAsset) => {
  return useMemo(() => {
    switch (asset.type) {
      case AssetType.Image:
        return <ImagePreview url={asset.image_url} />;
      case AssetType.Video:
        return <VideoPreview stream={asset.stream_url} />;
      case AssetType.Attachment:
        return <NotAvailablePreview />;
    }
  }, [asset.image_url, asset.stream_url, asset.type]);
};
