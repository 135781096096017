import { TrendVisualizationTemplate } from 'interfaces/trendVisualizations';
import React from 'react';
import TrendVisualizationsSelectSection from '../SelectSection';

type TrendVisualizationsSelectChildSectionProps = {
  title: string;
  description?: string;
  type: string;
  subtype: string;
  templates: TrendVisualizationTemplate[];
};

const SelectChildSection = ({
  title,
  templates,
  type,
  subtype,
  description,
}: TrendVisualizationsSelectChildSectionProps) => {
  return (
    <TrendVisualizationsSelectSection
      title={title}
      description={description}
      type={type}
      subtype={subtype}
      templates={templates}
      isChild={true}
    />
  );
};

export default SelectChildSection;
