import { useMemo } from 'react';
import { useTranslations } from '../../../../../../../hooks/useTranslations';
import { DashboardDefaults } from '../defaults';

export const useNotSpecifiedTranslation = () => {
  const { wrapWithIntl, attachPrefix } = useTranslations(
    DashboardDefaults.NOT_SPECIFIED_TRANSLATION_PATH
  );

  const translation = useMemo(
    () =>
      wrapWithIntl(
        attachPrefix(DashboardDefaults.NOT_SPECIFIED_TRANSLATION_PREFIX)
      ),
    [attachPrefix, wrapWithIntl]
  );

  const translationId = useMemo(
    () => attachPrefix(DashboardDefaults.NOT_SPECIFIED_TRANSLATION_PREFIX),
    [attachPrefix]
  );

  return { translation, translationId };
};
