import { CaseQueryParams } from '../../../interfaces/queryParams';
import { IQueryRequest } from '../../../interfaces/request';
import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getCaseProcedureHistory } from '../../cases';

const useCaseProcedureHistory = ({
  params,
  options,
}: IQueryRequest<CaseQueryParams>) => {
  const { organizationId, caseId } = params;

  return useQuery({
    ...options,
    queryKey: [QueryKeys.getCaseProcedureHistory, organizationId, caseId],
    queryFn: async () => {
      const { data } = await getCaseProcedureHistory(organizationId, caseId);

      return data.data;
    },
  });
};

export default useCaseProcedureHistory;
