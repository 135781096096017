import { useQueriesLoading } from 'hooks/queries/useQueriesLoading';
import { TrendVisualizationTemplate } from 'interfaces/trendVisualizations';
import React from 'react';
import { TrendsChartGenericDataQueries } from '../../../../../../../../../../../../../../components/TrendsChart/types';
import TrendsPlaceholder from '../TrendsPlaceholder';
import { useQueries } from './hooks/useQueries';

type TrendsQueriesWrapperProps = {
  template: TrendVisualizationTemplate;
  children: (props: TrendsChartGenericDataQueries) => JSX.Element;
};

const TrendsQueriesWrapper = ({
  template,
  children,
}: TrendsQueriesWrapperProps) => {
  const queries = useQueries(template);

  const isLoading = useQueriesLoading([
    queries.observations,
    queries.observationInTimelines,
    queries.exercises,
    queries.questionAnswerHistory,
  ]);

  if (isLoading) return <TrendsPlaceholder />;

  return <>{children(queries)}</>;
};

export default TrendsQueriesWrapper;
