import { FormattedMessage, useIntl } from 'react-intl';
import { Button, FormGroup, Label } from 'reactstrap';
import CustomDatePicker from './CustomDatePicker';
import { TimeRange } from 'interfaces/timeline';
import { DateRange } from 'react-day-picker';

//images
import Arrow from 'assets/images/single_arrow.svg';
import classNames from 'classnames';

type TimePeriodFilterProps = {
  timeRange: TimeRange | null;
  onTimeRangeChange: (timeRange: string) => void;
  selectedRange: DateRange | null;
  onNextPeriod: () => void;
  onPreviousPeriod: () => void;
  lastActiveMode?: boolean;
  toggleLastActiveMode: () => void;
  onDateRangeChange: (range: DateRange) => void;
  timePeriod: TimeRange | null;
  noMargin?: boolean;
};

const TimePeriodFilter = ({
  timeRange,
  onNextPeriod,
  onPreviousPeriod,
  toggleLastActiveMode,
  lastActiveMode,
  onTimeRangeChange,
  selectedRange,
  onDateRangeChange,
  timePeriod,
  noMargin = false,
}: TimePeriodFilterProps) => {
  const intl = useIntl();

  return (
    <div>
      <div
        className={classNames('TimelineTabsFilters', {
          'm-0': noMargin,
        })}
      >
        <FormGroup className="TimelineTabsFilters__date-select d-flex">
          <div>
            <Label>
              <FormattedMessage id="General.selectDates" />
            </Label>
            <CustomDatePicker
              hideTime
              onDateAccept={onDateRangeChange}
              selectedRange={selectedRange}
              selectedTimeRange={timeRange}
              onPredefineValueClick={onTimeRangeChange}
              onActiveModeChange={toggleLastActiveMode}
              defaultLastActive={lastActiveMode}
              oneColumn
            />
          </div>
          {timePeriod !== TimeRange.CASE_PERIOD && (
            <div className="d-flex align-self-end ms-2">
              <Button
                outline
                color="primary"
                className="TimelineTabsFilters__range-button me-2"
                onClick={onPreviousPeriod}
              >
                <img src={Arrow} alt="arrow_left1" />
                <img src={Arrow} alt="arrow_right1" className="me-2" />
                <FormattedMessage id="General.previous" />{' '}
                {timePeriod &&
                  intl.formatMessage({
                    id: `UserDashboard.SqueezeSummary.timeRange.${timePeriod}`,
                  })}
              </Button>
              <Button
                outline
                color="primary"
                className="TimelineTabsFilters__range-button"
                onClick={onNextPeriod}
              >
                <FormattedMessage id="General.next" />{' '}
                {timePeriod &&
                  intl.formatMessage({
                    id: `UserDashboard.SqueezeSummary.timeRange.${timePeriod}`,
                  })}
                <div className="invert">
                  <img src={Arrow} alt="arrow_left2" />
                  <img src={Arrow} alt="arrow_right2" className="me-2" />
                </div>
              </Button>
            </div>
          )}
        </FormGroup>
      </div>
    </div>
  );
};

export default TimePeriodFilter;
