import { useInitQuery } from 'hooks/queries/useInitQuery';
import { ReactStateTuple } from 'interfaces/ui';
import { useCallback, useEffect, useMemo } from 'react';
import { useCommonData } from '../../../../../../../hooks/useCommonData';

export const useChangeCaseConversationFilters = (
  templateIdsState: ReactStateTuple<string[] | undefined>,
  _templateIdsState: ReactStateTuple<string[] | undefined>
) => {
  const { conversationTemplatesQuery } = useCommonData();

  useInitQuery(conversationTemplatesQuery);

  const [templateIds, setTemplateIds] = templateIdsState;
  const [_templateIds, _setTemplateIds] = _templateIdsState;

  const handleAccept = useCallback(() => {
    setTemplateIds(_templateIds);
  }, [_templateIds, setTemplateIds]);

  const handleReset = useCallback(() => {
    _setTemplateIds((conversationTemplatesQuery.data ?? []).map((p) => p.id));
  }, [_setTemplateIds, conversationTemplatesQuery.data]);

  useEffect(() => {
    if (conversationTemplatesQuery.data) {
      _setTemplateIds((conversationTemplatesQuery.data ?? []).map((p) => p.id));
      setTemplateIds((conversationTemplatesQuery.data ?? []).map((p) => p.id));
    }
  }, [_setTemplateIds, conversationTemplatesQuery.data, setTemplateIds]);

  const filterString = useMemo(() => {
    if (!conversationTemplatesQuery.data) return '';

    return (templateIds ?? [])
      .map(
        (templateId) =>
          conversationTemplatesQuery.data.find((p) => p.id === templateId)
            ?.display_code ?? ''
      )
      .join(',');
  }, [conversationTemplatesQuery.data, templateIds]);

  const filtersLength = useMemo(
    () => templateIds?.length ?? 0,
    [templateIds?.length]
  );

  return {
    handleAccept,
    handleReset,
    filterString,
    filtersLength,
  };
};
