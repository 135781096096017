import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import { Button } from 'reactstrap';
import { FormHookProps } from '../../../../../../../../interfaces/forms';
import {
  MaterialModalFormValues,
  MaterialModalStepsContext,
} from '../../../../types';

type FillInfoStepProps = FormHookProps<MaterialModalFormValues>;

const FillInfoStep = ({ form }: FillInfoStepProps) => {
  const { next } = useContext(MaterialModalStepsContext);

  return (
    <>
      <Button disabled={!form.isValid} color="primary" onClick={next}>
        <FontAwesomeIcon icon={faArrowRight} />
      </Button>
    </>
  );
};

export default FillInfoStep;
