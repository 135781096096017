import MenuItem from '@mui/material/MenuItem/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import React, { useLayoutEffect, useRef } from 'react';

type SelectCellProps<
  TData extends {},
  TOption extends object
> = GridRenderEditCellParams<TData> & {
  items: TOption[];
  valueGetter: (option: TOption) => any;
  textGetter: (option: TOption) => string;
  valueSetter?: (value: any) => any;
};

const SelectCell = <TData extends {}, TOption extends object>({
  items,
  textGetter,
  valueGetter,
  valueSetter = undefined,
  ...inner
}: SelectCellProps<TData, TOption>) => {
  const apiRef = useGridApiContext();
  const ref = useRef<any>();

  useLayoutEffect(() => {
    if (!inner.hasFocus || !ref.current) return;
    ref.current.focus();
  }, [inner.hasFocus]);

  const handleValueChange = (event: SelectChangeEvent<any>) => {
    const newValue = event.target.value;
    apiRef.current.setEditCellValue({
      id: inner.row['id'],
      field: inner.field,
      value: valueSetter ? valueSetter(newValue) : newValue,
    });
  };

  return (
    <Select fullWidth={true} value={inner.value} onChange={handleValueChange}>
      {items.map((item) => (
        <MenuItem key={valueGetter(item)} value={valueGetter(item)}>
          {textGetter(item)}
        </MenuItem>
      ))}
    </Select>
  );
};

export default SelectCell;
