import { createPersonalityForOrganization } from 'api/cases';
import { CaseFormType } from 'interfaces/cases';
import { ICreatePersonalityBody } from 'interfaces/personalities';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useMutation } from 'react-query';
import { getCulture } from 'translations/utils/cultures';

export function usePatientCreate(
  organizationId: string,
  onNextStepCallback: (patientId: string) => void
) {
  const onPatientPick = async ({
    email,
    first_name,
    last_name,
    address,
    birthdate,
    ssn,
    gender,
    phone_number,
  }: CaseFormType) => {
    try {
      const personalityData: ICreatePersonalityBody = {
        first_name,
        last_name,
        email: email?.length ? email : null,
        culture: getCulture(),
        address,
        birthdate,
        ssn,
        gender,
        phone_number: phone_number || '',
        is_participant: true,
      };

      const { data: responseData } = await createPersonalityForOrganization(
        organizationId,
        personalityData
      );

      const { data: createdUser } = responseData;

      if (createdUser) {
        displaySuccessNotification('CaseCard.create.personalitySuccess');
        onNextStepCallback(createdUser.id);
      }
    } catch (error: any) {
      const errorArray = error?.data?.meta?.errors ?? [null];
      displayErrorArrayNotifications(errorArray, 'CaseCard.create.errors');
    }
  };

  const { mutate: patientPickHandler, isLoading: isPatientLoading } =
    useMutation(onPatientPick);

  return { patientPickHandler, isPatientLoading };
}
