import { faAddressCard } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputFieldWithImage from 'components/InputFieldWithImage';
import React, { ChangeEvent, useState } from 'react';
import { Col, Input, Row } from 'reactstrap';
import nameLogo from 'assets/images/register/name.svg';

type AccessSearchStepProps = {
  fullName?: string;
  setFullName: (val: string) => void;
  patientNumber?: number;
  setPatientNumber: (val: number | undefined) => void;
};

enum Filters {
  'fullName' = 'fullName',
  'patientNumber' = 'patientNumber',
}

enum inputKeys {
  fullName = 'fullName',
  patientNumber = 'patientNumber',
}

const AccessSearchStep = ({
  setFullName,
  setPatientNumber,
  fullName,
  patientNumber,
}: AccessSearchStepProps) => {
  const [selectedFilter, setSelectedFilter] = useState(Filters.fullName);

  const onFullNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFullName(e.target.value);
  };
  const onPatientNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPatientNumber(parseInt(e.target.value));
  };

  const onCheckboxClick = (e: ChangeEvent<HTMLInputElement>) => {
    const newFilter = e.target.name as unknown as Filters;
    setSelectedFilter(newFilter);

    if (newFilter === Filters.fullName) setPatientNumber(undefined);
    else setFullName('');
  };

  const isFullNameSelected = selectedFilter === Filters.fullName;

  return (
    <>
      <Row>
        <Col
          md={1}
          className="d-flex align-items-center justify-content-center"
        >
          <input
            className="d-flex align-items-center justify-content-center"
            type="checkbox"
            name={Filters.fullName.toString()}
            checked={isFullNameSelected}
            onChange={onCheckboxClick}
          />
        </Col>
        <Col md={10}>
          <InputFieldWithImage
            disabled={!isFullNameSelected}
            labelTranslationId="PatientAccess.fullName"
            image={<img src={nameLogo} alt="name_logo" />}
            inputId={inputKeys.fullName}
          >
            <Input
              id={inputKeys.fullName}
              value={fullName ?? ''}
              onChange={onFullNameChange}
            />
          </InputFieldWithImage>
        </Col>
      </Row>
      <Row>
        <Col
          md={1}
          className="d-flex align-items-center justify-content-center"
        >
          <input
            type="checkbox"
            name={Filters.patientNumber.toString()}
            checked={!isFullNameSelected}
            onChange={onCheckboxClick}
          />
        </Col>
        <Col md={10}>
          <InputFieldWithImage
            disabled={isFullNameSelected}
            labelTranslationId="PatientAccess.patientNumber"
            image={<FontAwesomeIcon icon={faAddressCard} color="#407dbb" />}
            inputId={inputKeys.patientNumber}
          >
            <Input
              type="number"
              value={patientNumber ?? ''}
              onChange={onPatientNumberChange}
              id={inputKeys.patientNumber}
            />
          </InputFieldWithImage>
        </Col>
      </Row>
    </>
  );
};

export default AccessSearchStep;
