import { useMemo } from 'react';
import { useTranslations } from '../../../../../../../../../hooks/useTranslations';
import { DashboardDatePeriod } from '../../../../../../../../../interfaces/dashboards';
import { InputOption } from '../../../../../../../../../interfaces/ui';

const DATE_PERIOD_OPTIONS = Object.values(DashboardDatePeriod);

export const useDateSelectorOptions = () => {
  const { wrapWithIntl, attachPrefix } = useTranslations(
    'Enums.DashboardDatePeriod'
  );

  return useMemo((): InputOption[] => {
    return DATE_PERIOD_OPTIONS.map((period) => ({
      text: wrapWithIntl(attachPrefix(period)),
      value: period,
    }));
  }, [attachPrefix, wrapWithIntl]);
};
