import React from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';
import warningSignIcon from '../../../../../../../../../../assets/images/warning-sign.svg';

const NoInitialPhaseSelectedPlaceholder = () => {
  return (
    <div className="TreatmentPhaseNoDefaultTemplatePlaceholder">
      <SVG
        className="TreatmentPhaseNoDefaultTemplatePlaceholder__icon"
        src={warningSignIcon}
      />
      <FormattedMessage
        tagName="span"
        id="TreatmentPlans.TreatmentPlan.TreatmentPlanContent.TreatmentPhase.TreatmentPhaseNoInitialPhaseSelectedPlaceholder.content"
      />
    </div>
  );
};

export default NoInitialPhaseSelectedPlaceholder;
