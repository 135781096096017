import useGroupedConversationQuestions from '../../../api/queries/conversations/useGroupedConversationQuestions';
import useConversationTemplates from '../../../api/queries/conversations/useConversationTemplates';
import useOrganizationDetails from '../../../api/queries/organizations/useOrganizationDetails';
import { useCommonParams } from './useCommonParams';
import useEducationMaterials from '../../../api/queries/educationMaterials/useEducationMaterials';
import useFeedbackResources from '../../../api/queries/feedbackResources/useFeedbackResources';
import useConversationQuestions from '../../../api/queries/conversations/useConversationQuestions';

export const useCommonData = () => {
  const { organizationId } = useCommonParams();

  const organizationDetailsQuery = useOrganizationDetails({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const groupedConversationQuestionsQuery = useGroupedConversationQuestions({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const conversationQuestionsQuery = useConversationQuestions({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const conversationTemplatesQuery = useConversationTemplates({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const educationMaterialsQuery = useEducationMaterials({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const feedbackResourcesQuery = useFeedbackResources({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  return {
    groupedConversationQuestions: groupedConversationQuestionsQuery,
    conversationQuestions: conversationQuestionsQuery,
    conversationTemplates: conversationTemplatesQuery,
    organizationDetails: organizationDetailsQuery,
    educationMaterials: educationMaterialsQuery,
    feedbackResources: feedbackResourcesQuery,
  };
};
