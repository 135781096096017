import { FieldProps } from 'formik';
import React from 'react';

interface FieldTextareaProps extends FieldProps {
  rows?: number;
}

const FieldTextarea = ({ field, form, ...props }: FieldTextareaProps) => (
  <textarea className="form-control" {...field} {...props}></textarea>
);

export default FieldTextarea;
