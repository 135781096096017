import { PasswordComplexityConstraints } from 'components/PasswordComplexity';
import { FormikErrors } from 'formik';

export function isEmail(value: string) {
  return /\S+@\S+\.\S+/.test(value);
}

export const arePasswordsEqual = (
  password: string | undefined | null,
  passwordRepeat: string | undefined | null
) => password !== '' && password === passwordRepeat;

export const validateEmpty = <T extends {}>(values: T, intl) => {
  const keys = Object.keys(values);
  let errors: Partial<T> = {};

  keys.forEach((key) => {
    if (values[key] === '')
      errors[key] = intl.formatMessage({
        id: 'CaseCard.create.errors.inputEmpty',
      });
  });

  return errors;
};

export const validateSubsetEmpty = <T extends {}>(
  values: T,
  included: string[],
  intl
) => {
  const keys = Object.keys(values);
  let errors: FormikErrors<T> = {};

  keys.forEach((key) => {
    if (included.includes(key) && values[key] === '')
      errors[key] = intl.formatMessage({
        id: 'CaseCard.create.errors.inputEmpty',
      });
  });

  return errors;
};

export const getPasswordConstraintsFulfilled = (password: string) => {
  const constraints: PasswordComplexityConstraints[] = [];

  if (password.length >= 6) {
    constraints.push(PasswordComplexityConstraints.SixCharacters);
  }

  const anyUpperCase = /^[^A-Z]+$/;
  const anyDigit = /^[^0-9]+$/;
  const alphanumeric = /^[A-Za-z0-9]+$/;

  if (!anyUpperCase.test(password) && password.length !== 0) {
    constraints.push(PasswordComplexityConstraints.OneUppercase);
  }

  if (!anyDigit.test(password) && password.length !== 0) {
    constraints.push(PasswordComplexityConstraints.OneDigit);
  }

  if (!alphanumeric.test(password) && password.length !== 0) {
    constraints.push(PasswordComplexityConstraints.OneNonAlphanumeric);
  }

  return constraints;
};
