import { findGrasp } from 'api/graspManagement';
import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import { displayErrorNotification } from 'modules/Notifications';
import { useIntl } from 'react-intl';

const WRONG_GRASP_MESSAGE = 'wrong_grasp';

function useFindGrasp(compareGraspId?: string) {
  const intl = useIntl();

  return useMutationWithResultNotification({
    asyncApiFunction: async (linkCode: string) => {
      const { data: responseData } = await findGrasp(linkCode);
      const { data } = responseData || {};

      if (compareGraspId && data.id !== compareGraspId)
        throw Error(`${WRONG_GRASP_MESSAGE}-${data.name}`);

      return data;
    },
    resetOnResult: false,
    onError: (err) => {
      if (err?.message?.includes(WRONG_GRASP_MESSAGE)) {
        const splittedCode = err?.message.split('-');

        displayErrorNotification(
          `${intl.formatMessage({ id: 'Grasps.errors.wrongGrasp' })}(${
            splittedCode[1]
          }). ${intl.formatMessage({
            id: 'Grasps.pleaseCanCorrect',
          })}`
        );
      } else {
        displayErrorNotification('Grasps.errors.codeNotFound');
      }
    },
  });
}

export default useFindGrasp;
