import { useCallback, useMemo } from 'react';
import useCultures from '../../../../../../../../../../../../../../../hooks/cultures/useCultures';
import { useTypedContext } from '../../../../../../../../../../../../../../../hooks/useTypedContext';
import { IAwareSessionData } from '../../../../../../../../../../../../../../../interfaces/awareSessions';
import {
  DashboardTemplateSeries,
  DashboardTemplateSeriesSplitBy,
} from '../../../../../../../../../../../../../../../interfaces/dashboards';
import { DashboardSessionsHelpers } from '../../../../../../../../../helpers/sessions';
import { useNotSpecifiedTranslation } from '../../../../../../../../../hooks/useNotSpecifiedTranslation';
import { DashboardQueriesContext } from '../../../../../../../../../types';
import { ChartViewHelpers } from '../../../helpers';
import { NamedAndSplitByValues, SplitByValues } from '../../../types';
import { PieChartHelpers } from '../helpers';
import { PieChartsData, ValueField } from '../types';

const INVALID_SPLIT_BY = `Pie chart config invalid: 'split_by' empty`;

export const useFormattedData = (
  sessions: IAwareSessionData[],
  config: DashboardTemplateSeries
) => {
  const { questions, members, organizations } = useTypedContext(
    DashboardQueriesContext
  );

  const { currentCulture } = useCultures();
  const { translation: notSpecifiedTranslation } = useNotSpecifiedTranslation();

  const splitValueSessions = useCallback(
    (
      sessions: ValueField<IAwareSessionData>[],
      splitBy: DashboardTemplateSeriesSplitBy
    ) => {
      return PieChartHelpers.SplitSessions(
        sessions,
        splitBy,
        questions,
        notSpecifiedTranslation
      );
    },
    [notSpecifiedTranslation, questions]
  );

  const nameValueSessions = useCallback(
    (
      sessions: SplitByValues<ValueField<IAwareSessionData>>[]
    ): NamedAndSplitByValues<ValueField<IAwareSessionData>>[] => {
      return DashboardSessionsHelpers.NameValueSessions(
        sessions,
        members,
        organizations,
        questions,
        currentCulture.culture
      );
    },
    [currentCulture.culture, members, organizations, questions]
  );

  return useMemo((): PieChartsData[] => {
    const valueSessions = PieChartHelpers.ProvideSessions(
      config.data_source,
      sessions,
      notSpecifiedTranslation
    );
    if (!config.split_by) throw new Error(INVALID_SPLIT_BY);

    let splitSessions = splitValueSessions(valueSessions, config.split_by);
    const namedSplitValueSessions = nameValueSessions(splitSessions);
    const aggregatedValueSessions = PieChartHelpers.AggregateSessions(
      namedSplitValueSessions,
      config.aggregate ?? 'Count'
    );
    const filteredValueSessions = PieChartHelpers.FilterEmptySessions(
      aggregatedValueSessions
    );

    return ChartViewHelpers.OrderValueSessions(
      filteredValueSessions,
      notSpecifiedTranslation
    );
  }, [
    config.aggregate,
    config.data_source,
    config.split_by,
    nameValueSessions,
    notSpecifiedTranslation,
    sessions,
    splitValueSessions,
  ]);
};
