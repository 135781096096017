import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import useModifyTreatmentPhase from '../../../../../../../../../api/mutations/conversations/useModifyTreatmentPhase';
import useRequiredParams from '../../../../../../../../../hooks/useRequiredParams';
import {
  ConversationTemplateTriggerType,
  IConversationTreatmentPhaseContentItem,
  ISummarizedTreatmentPhase,
  TreatmentPhaseContentItem,
  TreatmentPhaseContentItemType,
} from '../../../../../../../../../interfaces/conversations';
import { ConversationScheduleType } from '../../../../../../../../../interfaces/conversationSchedules';
import { useTreatmentPlanCommonData } from '../../../../../hooks/useTreatmentPlanCommonData';
import { useTreatmentPhaseCode } from '../../../hooks/useTreatmentPhaseCode';
import { useCodeVerification } from './useCodeVerification';

export const useApplyPhase = (addMode: boolean, toggleEditMode: () => void) => {
  const { organizationId, treatmentPlanId } = useRequiredParams<{
    organizationId: string;
    treatmentPlanId: string;
  }>(['organizationId', 'treatmentPlanId']);

  const { caseCategories, treatmentPhases, conversationTemplates } =
    useTreatmentPlanCommonData();

  const verifyCode = useCodeVerification();
  const getPhaseCode = useTreatmentPhaseCode();

  const onSuccess = async () => {
    toggleEditMode();
    await caseCategories.refetch();
    await treatmentPhases.refetch();
  };

  const { mutateAsync: modifyAsync, isLoading: isUpdating } =
    useModifyTreatmentPhase(organizationId, treatmentPlanId, onSuccess);

  const handleEmptyContentScheduleTemplates = useCallback(
    (content: TreatmentPhaseContentItem[]) => {
      return content
        .filter(
          (p) => p.type === TreatmentPhaseContentItemType.ConversationSchedule
        )
        .map((item) => {
          let itemCopy = cloneDeep(item);
          if (itemCopy.schedule_templates.length === 0)
            itemCopy.schedule_templates.push({
              parts_of_a_day: [],
              specific_day_of_phase: null,
              end_day_offset: null,
              start_day_offset: null,
              recurring_interval: null,
              schedule_type: ConversationScheduleType.NonTimeBased,
            });

          return itemCopy;
        });
    },
    []
  );

  const handleThinHeaderApply = useCallback(
    async (
      initialPhase: ISummarizedTreatmentPhase,
      managedPhase: ISummarizedTreatmentPhase
    ) => {
      const treatmentPhaseId = addMode ? uuid() : initialPhase.id;

      const result = verifyCode(
        managedPhase.id,
        initialPhase.id,
        managedPhase.treatment_plan_id,
        addMode
      );

      if (!result) return;

      await modifyAsync({
        treatmentPhaseId: treatmentPhaseId,
        body: {
          next_phase_condition: managedPhase.next_phase_condition,
          content: handleEmptyContentScheduleTemplates(initialPhase.content),
          empty_conversation_template_id:
            initialPhase.empty_conversation_template_id,
          sequence: managedPhase.sequence,
          display_code: managedPhase.display_code,
          is_default: managedPhase.is_default,
          organization_id: organizationId,
          days_duration: managedPhase.days_duration,
          code:
            managedPhase.id !== initialPhase.id
              ? managedPhase.id
              : getPhaseCode(initialPhase.id),
        },
      });
    },
    [
      addMode,
      getPhaseCode,
      handleEmptyContentScheduleTemplates,
      modifyAsync,
      organizationId,
      verifyCode,
    ]
  );

  const isTemplateTimeBased = useCallback(
    (templateId: string): boolean => {
      const existingTemplate = conversationTemplates.data?.find(
        (p) => p.id === templateId
      );

      if (!existingTemplate) return false;

      return (
        existingTemplate.trigger_type === ConversationTemplateTriggerType.Time
      );
    },
    [conversationTemplates.data]
  );

  const normalizeContentItem = useCallback(
    (contentItem: TreatmentPhaseContentItem[]) => {
      let _items: TreatmentPhaseContentItem[] = [];

      contentItem.forEach((item) => {
        if (item.type !== TreatmentPhaseContentItemType.ConversationSchedule)
          return;

        _items.push(
          isTemplateTimeBased(item.template_id)
            ? item
            : {
                ...item,
                schedule_templates: [],
              }
        );
      });

      return _items;
    },
    [isTemplateTimeBased]
  );

  const handleTemplateFormApply = useCallback(
    async (
      values: IConversationTreatmentPhaseContentItem,
      initialPhase: ISummarizedTreatmentPhase,
      isTemplateDefault: (templateId: string) => boolean,
      defaultTemplateMode: boolean,
      contentIndex?: number
    ) => {
      let phaseCopy = cloneDeep(initialPhase);
      let valuesCopy = cloneDeep(values);

      valuesCopy.schedule_templates = valuesCopy.schedule_templates.map(
        (template) => ({
          parts_of_a_day: template.parts_of_a_day ?? [],
          schedule_type: template.schedule_type,
          start_day_offset:
            template.start_day_offset === null
              ? null
              : template.start_day_offset - 1,
          end_day_offset:
            template.end_day_offset === null
              ? null
              : template.end_day_offset - 1,
          specific_day_of_phase:
            template.specific_day_of_phase === null
              ? null
              : template.specific_day_of_phase - 1,
          recurring_interval: template.recurring_interval,
        })
      );

      if (contentIndex !== undefined || defaultTemplateMode) {
        if (isTemplateDefault(values.template_id)) {
          await modifyAsync({
            treatmentPhaseId: initialPhase.id,
            body: {
              content: handleEmptyContentScheduleTemplates(
                initialPhase.content
              ),
              organization_id: organizationId,
              empty_conversation_template_id: values.template_id,
              sequence: initialPhase.sequence,
              next_phase_condition: initialPhase.next_phase_condition,
              is_default: initialPhase.is_default,
              days_duration: initialPhase.days_duration,
              display_code: initialPhase.display_code,
              code: getPhaseCode(initialPhase.id),
            },
          });

          return;
        }

        if (contentIndex === undefined) return;

        phaseCopy.content[contentIndex] = valuesCopy;
        phaseCopy.content = normalizeContentItem(phaseCopy.content);

        await modifyAsync({
          treatmentPhaseId: initialPhase.id,
          body: {
            content: handleEmptyContentScheduleTemplates(phaseCopy.content),
            empty_conversation_template_id:
              phaseCopy.empty_conversation_template_id,
            sequence: phaseCopy.sequence,
            next_phase_condition: phaseCopy.next_phase_condition,
            is_default: phaseCopy.is_default,
            organization_id: organizationId,
            days_duration: phaseCopy.days_duration,
            display_code: initialPhase.display_code,
            code: getPhaseCode(initialPhase.id),
          },
        });

        return;
      }

      if (isTemplateDefault(values.template_id)) {
        await modifyAsync({
          treatmentPhaseId: initialPhase.id,
          body: {
            content: handleEmptyContentScheduleTemplates(initialPhase.content),
            empty_conversation_template_id: values.template_id,
            sequence: initialPhase.sequence,
            next_phase_condition: initialPhase.next_phase_condition,
            is_default: initialPhase.is_default,
            organization_id: organizationId,
            days_duration: initialPhase.days_duration,
            display_code: initialPhase.display_code,
            code: getPhaseCode(initialPhase.id),
          },
        });

        return;
      }

      phaseCopy.content = [...phaseCopy.content, valuesCopy];
      phaseCopy.content = normalizeContentItem(phaseCopy.content);

      await modifyAsync({
        treatmentPhaseId: phaseCopy.id,
        body: {
          content: handleEmptyContentScheduleTemplates(phaseCopy.content),
          empty_conversation_template_id:
            phaseCopy.empty_conversation_template_id,
          sequence: phaseCopy.sequence,
          next_phase_condition: phaseCopy.next_phase_condition,
          is_default: phaseCopy.is_default,
          organization_id: organizationId,
          days_duration: phaseCopy.days_duration,
          display_code: initialPhase.display_code,
          code: getPhaseCode(phaseCopy.id),
        },
      });
    },
    [
      getPhaseCode,
      handleEmptyContentScheduleTemplates,
      modifyAsync,
      normalizeContentItem,
      organizationId,
    ]
  );

  return {
    thinHeader: handleThinHeaderApply,
    templateForm: handleTemplateFormApply,
    isUpdating,
  };
};
