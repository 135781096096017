import { ReactState } from '../../../../interfaces/ui';
import MultiStateButton from '../../../MultiStateButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartBar, faDumbbell } from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import { AllSqueezesMode } from 'components/AllSqueezesChart/types';

type AllSqueezesSwitchProps = {
  mode: ReactState<AllSqueezesMode>;
};

const AllSqueezesSwitch = ({ mode }: AllSqueezesSwitchProps) => {
  const toggle = () =>
    mode.setItem((prev) =>
      prev === AllSqueezesMode.AllIncludingExercises
        ? AllSqueezesMode.ExercisesOnly
        : AllSqueezesMode.AllIncludingExercises
    );

  return (
    <MultiStateButton
      items={[
        {
          key: AllSqueezesMode.AllIncludingExercises,
          icon: <FontAwesomeIcon icon={faChartBar} />,
          onClick: toggle,
        },
        {
          key: AllSqueezesMode.ExercisesOnly,
          icon: <FontAwesomeIcon icon={faDumbbell} />,
          onClick: toggle,
        },
      ]}
    />
  );
};

export default AllSqueezesSwitch;
