import { useTranslations } from '../../../../../../../../../../../hooks/useTranslations';
import { useMemo } from 'react';
import { SingleEntry } from '../../../../../types';
import { format, intervalToDuration } from 'date-fns';
import { padDate } from '../../../../../../../../../../../helpers/dates';
import { useTypedContext } from '../../../../../../../../../../../hooks/useTypedContext';
import { AwareCaseContentDataContext } from '../../../../AwareCaseContent/types';

export const useSessionInfoEntries = (): SingleEntry[] => {
  const { awareSession } = useTypedContext(AwareCaseContentDataContext);

  const { attachPrefix, wrapWithIntl } = useTranslations(
    'Cases.CaseDetails.AwareCase.SessionInfo.SessionInfoEntries'
  );

  const duration = useMemo(() => {
    const startDate = awareSession?.start_date;
    const endDate = awareSession?.end_date;

    if (!startDate || !endDate) return '';

    const duration = intervalToDuration({
      start: new Date(startDate),
      end: new Date(endDate),
    });

    return `${padDate(duration.hours ?? 0)}:${padDate(
      duration.minutes ?? 0
    )}:${padDate(duration.seconds ?? 0)}`;
  }, [awareSession?.end_date, awareSession?.start_date]);

  return useMemo(() => {
    return [
      {
        name: wrapWithIntl(attachPrefix('startDate')),
        value: awareSession?.start_date
          ? format(new Date(awareSession.start_date), 'dd.MM.yyyy')
          : '',
      },
      {
        name: wrapWithIntl(attachPrefix('startTime')),
        value: awareSession?.start_date
          ? format(new Date(awareSession.start_date), 'HH:mm')
          : '',
      },
      {
        name: wrapWithIntl(attachPrefix('duration')),
        value: duration,
      },
      {
        name: wrapWithIntl(attachPrefix('allSqueezes')),
        value: (awareSession?.total_squeezes ?? 0).toString(),
      },
      {
        name: wrapWithIntl(attachPrefix('alarmIncidents')),
        value: (awareSession?.alarm_incidents ?? 0).toString(),
      },
      {
        name: wrapWithIntl(attachPrefix('squeezesPerMinute')),
        value: (awareSession?.squeezes_per_minute ?? 0).toFixed(2),
      },
      {
        name: wrapWithIntl(attachPrefix('averageIntensity')),
        value: (awareSession?.average_intensity ?? 0).toFixed(2),
      },
    ];
  }, [
    attachPrefix,
    awareSession?.alarm_incidents,
    awareSession?.average_intensity,
    awareSession?.squeezes_per_minute,
    awareSession.start_date,
    awareSession?.total_squeezes,
    duration,
    wrapWithIntl,
  ]);
};
