import { cloneDeep } from 'lodash';
import {
  IAvailableFromTo,
  ScheduleItemDefinition,
  ScheduleItemTemplatePartsOfADayDefinition,
} from '../../components/Schedule';
import {
  ConversationScheduleType,
  ICaseConversationSchedule,
  ICaseGroupConversationSchedule,
  IConversationScheduleHours,
  ISingleInstanceCaseConversationSchedule,
  ISingleInstanceCaseGroupConversationSchedule,
} from '../../interfaces/conversationSchedules';
import { strToDateOnly, toDateOnly } from '../dates';

type ConversationSchedule =
  | ICaseConversationSchedule
  | ICaseGroupConversationSchedule;

type SingleInstanceConversationSchedule =
  | ISingleInstanceCaseConversationSchedule
  | ISingleInstanceCaseGroupConversationSchedule;

export const filterSchedulesForDate = (
  schedule: ConversationSchedule,
  date: Date
) => {
  const selectedDateStr = toDateOnly(date);

  if (schedule.date) return strToDateOnly(schedule.date) === selectedDateStr;

  if (schedule.date_from && schedule.date_to)
    return (
      selectedDateStr >= strToDateOnly(schedule.date_from) &&
      selectedDateStr <= strToDateOnly(schedule.date_from)
    );

  return (
    schedule.type === ConversationScheduleType.Once ||
    schedule.type === ConversationScheduleType.NonTimeBased
  );
};

export const getRecurringForSingleInstance = (
  schedules: ConversationSchedule[],
  currentSchedule: ConversationSchedule
) => {
  return schedules.filter(
    (p) =>
      currentSchedule.recurring_origin_id !== null &&
      p.id === currentSchedule.recurring_origin_id
  )[0];
};

const leftSideOverlap = <T extends IAvailableFromTo>(a: T, b: T) => {
  return (
    a.available_from_hour <= b.available_from_hour &&
    a.available_to_hour >= b.available_from_hour
  );
};

const rightSideOverlap = <T extends IAvailableFromTo>(a: T, b: T) => {
  return (
    b.available_from_hour <= a.available_from_hour &&
    b.available_to_hour >= a.available_from_hour
  );
};

export const itemOverlapping = <T extends IAvailableFromTo>(
  one: T,
  two: T
): boolean => {
  return (
    leftSideOverlap(one, two) ||
    leftSideOverlap(two, one) ||
    rightSideOverlap(one, two) ||
    rightSideOverlap(two, one) ||
    (one.available_from_hour >= two.available_from_hour &&
      one.available_to_hour <= two.available_to_hour) ||
    (two.available_from_hour >= one.available_from_hour &&
      two.available_to_hour <= one.available_to_hour)
  );
};

export const schedulesOverlapping = <T extends IAvailableFromTo>(
  partsOfADay: T[],
  newPartOfADay: T
): boolean => {
  let _itemsOverlapping: boolean = false;
  for (const partOfADay of partsOfADay) {
    const overlapping = itemOverlapping(partOfADay, newPartOfADay);

    if (!overlapping) continue;

    _itemsOverlapping = true;
    break;
  }

  return _itemsOverlapping;
};

export const getRowToMergeWith = <T extends ConversationSchedule>(
  items: ScheduleItemDefinition[],
  current: ConversationSchedule,
  recurring: ConversationSchedule,
  selectedDate: Date,
  partOfADayGenerator: (
    currentProvided: T,
    recurringProvided: T,
    selectedDate?: Date
  ) => ScheduleItemTemplatePartsOfADayDefinition
) => {
  const singleInstanceSchedule = current as SingleInstanceConversationSchedule;

  const existingRows = items.filter(
    (p) =>
      p.priority === singleInstanceSchedule.priority &&
      p.scheduleTemplate !== undefined
  );

  let _rowToMergeWith: ScheduleItemDefinition | null = null;

  for (const existingRow of existingRows) {
    const partsOverlapping = schedulesOverlapping(
      existingRow.scheduleTemplate?.parts_of_a_day ?? [],
      partOfADayGenerator(current as T, recurring as T, selectedDate)
    );

    if (partsOverlapping) continue;

    if (_rowToMergeWith === null) {
      _rowToMergeWith = existingRow;
      continue;
    }

    const existingRowLength = (
      existingRow.scheduleTemplate?.parts_of_a_day ?? []
    ).length;
    const rowToMergeWithLength = (
      _rowToMergeWith.scheduleTemplate?.parts_of_a_day ?? []
    ).length;

    if (existingRowLength <= rowToMergeWithLength) continue;

    _rowToMergeWith = existingRow;
  }

  return _rowToMergeWith;
};

export const mergeSingleInstanceWithRow = (
  items: ScheduleItemDefinition[],
  sequence: number,
  partOfADay: ScheduleItemTemplatePartsOfADayDefinition,
  disabled: boolean
) => {
  let itemsCopy = cloneDeep(items);
  let item = itemsCopy.filter((p) => p.sequence === sequence)[0];

  if (item.scheduleTemplate) {
    item.scheduleTemplate.parts_of_a_day.push(partOfADay);
    item.scheduleTemplate.parts_of_a_day.sort((a, b) =>
      a.notification_hour.localeCompare(b.notification_hour)
    );
  }

  item.disabled = disabled;

  itemsCopy = itemsCopy.filter((p) => p.sequence !== sequence);
  itemsCopy.push(item);

  return itemsCopy;
};

export const areEmptyConversationScheduleHours = (
  scheduleProvided: IConversationScheduleHours
) => {
  return (
    scheduleProvided.notification_hour === '00:00' ||
    scheduleProvided.available_from_hour === '00:00' ||
    scheduleProvided.available_to_hour === '00:00'
  );
};
