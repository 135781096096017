import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import TabLayout from './TabLayout';
import dataDownloadIcon from 'assets/images/download_my_data.svg';
import useUserDataDumpStatus from '../../../../api/queries/membership/useUserDataDumpStatus';
import { UserDataDumpStatusType } from '../../../../interfaces/membership';
import classNames from 'classnames';
import { formatDate } from '../../../../helpers/dates';
import useRequestUsersDataDumpGeneration from '../../../../api/mutations/membership/useRequestUsersDataDumpGeneration';
import LoadingButton from '../../../../components/LoadingButton';
import useDownloadUsersDataDumpFile from '../../../../api/mutations/membership/useDownloadUsersDataDumpFile';

type GenerateDataDumpTabProps = {
  userId: string;
};

const GenerateDataDumpTab = ({ userId }: GenerateDataDumpTabProps) => {
  const {
    data: statusData,
    refetch: refetchStatus,
    isFetched: statusIsFetched,
  } = useUserDataDumpStatus({ params: { userId } });

  const {
    data: downloadFileLink,
    refetch: refetchLink,
    isFetching,
  } = useDownloadUsersDataDumpFile(
    userId,
    statusData?.fileInfo?.fileName ?? '',
    {
      enabled: statusData?.fileInfo?.fileName !== undefined,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  useEffect(() => {
    if (statusIsFetched && statusData?.fileInfo?.fileName) {
      refetchLink();
    }
  }, [refetchLink, statusData?.fileInfo?.fileName, statusIsFetched]);

  const { mutate: userDataDumpRequestMutation } =
    useRequestUsersDataDumpGeneration(refetchStatus);

  const renderDescription = useMemo(() => {
    switch (statusData?.status) {
      case UserDataDumpStatusType.NotRequested:
        return (
          <FormattedMessage id="MyProfile.DataDumpGeneration.youCanDownloadDataAnyTime" />
        );
      case UserDataDumpStatusType.Pending:
        return (
          <FormattedMessage id="MyProfile.DataDumpGeneration.dataIsBeingCreated" />
        );
      case UserDataDumpStatusType.Active:
        return (
          <FormattedMessage id="MyProfile.DataDumpGeneration.copyIsReadyToDownload" />
        );
      case UserDataDumpStatusType.Expired:
        return (
          <FormattedMessage id="MyProfile.DataDumpGeneration.yourLinkHasExpired" />
        );
    }
  }, [statusData]);

  const handleCreateNewFile = () => {
    userDataDumpRequestMutation({
      userId: userId,
    });
  };

  return (
    <TabLayout
      title="MyProfile.Tabs.generateDataDump"
      description={renderDescription}
      imageSrc={dataDownloadIcon}
    >
      <>
        {statusData && (
          <div className="DownloadMyData">
            {statusData?.status !== UserDataDumpStatusType.NotRequested && (
              <div className="DownloadMyData__dump-status">
                <div className="DownloadMyData__dump-status-text">
                  <FormattedMessage id="General.status" />
                  <div
                    className={classNames(
                      'DownloadMyData__dump-status__indicator',
                      {
                        'DownloadMyData__dump-status__indicator--pending':
                          statusData?.status === UserDataDumpStatusType.Pending,
                        'DownloadMyData__dump-status__indicator--ready':
                          statusData?.status === UserDataDumpStatusType.Active,
                        'DownloadMyData__dump-status__indicator--expired':
                          statusData?.status === UserDataDumpStatusType.Expired,
                      }
                    )}
                  />
                  <FormattedMessage
                    id={`MyProfile.DataDumpGeneration.GenerationStatus.${statusData?.status}`}
                  />
                </div>
                {statusData?.fileInfo && (
                  <div>
                    <FormattedMessage id="MyProfile.DataDumpGeneration.requestedOn" />
                    <span>{formatDate(statusData.fileInfo.requestedAt)}</span>
                  </div>
                )}
              </div>
            )}
            <div className="DownloadMyData__actions">
              {statusData?.status !== UserDataDumpStatusType.Pending && (
                <LoadingButton
                  color="primary"
                  outline={statusData?.status === UserDataDumpStatusType.Active}
                  onClick={handleCreateNewFile}
                >
                  <FormattedMessage id="MyProfile.DataDumpGeneration.createNewFile" />
                </LoadingButton>
              )}
              {(statusData?.status === UserDataDumpStatusType.Pending ||
                statusData?.status === UserDataDumpStatusType.Active) && (
                <LoadingButton
                  color="primary"
                  isLoading={isFetching}
                  disabled={
                    statusData?.status === UserDataDumpStatusType.Pending
                  }
                >
                  {statusData?.status === UserDataDumpStatusType.Pending && (
                    <FormattedMessage id="MyProfile.DataDumpGeneration.downloadMyData" />
                  )}
                  {downloadFileLink && (
                    <a
                      className="DownloadMyData__dump-status__download-link"
                      href={downloadFileLink}
                      download={statusData.fileInfo?.fileName}
                    >
                      <FormattedMessage id="MyProfile.DataDumpGeneration.downloadMyData" />
                    </a>
                  )}
                </LoadingButton>
              )}
            </div>
          </div>
        )}
      </>
    </TabLayout>
  );
};

export default GenerateDataDumpTab;
