import { getOrganizationCasesWithPaging } from 'api/cases';
import QueryKeys from 'api/queries/queryKeys';
import {
  convertIntoObjectWithPageInfo,
  returnAllDataFromResponse,
} from 'helpers/utils/reactQueryHelpers';
import { ICaseFilters, ICaseListItem } from 'interfaces/cases';
import {
  OrganizationQueryParams,
  SearchAndPagingQueryParams,
} from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { IError, IInfiniteResult } from 'interfaces/response';
import { useInfiniteQuery } from 'react-query';

interface OrganizationCasesQueryParams
  extends OrganizationQueryParams,
    SearchAndPagingQueryParams {
  filters: ICaseFilters | null;
}

function useInfiniteOrganizationCasesList({
  params,
  options,
}: IQueryRequest<OrganizationCasesQueryParams>) {
  const { organizationId, searchValue, pageSize = 20, filters } = params;
  const { synchronizationStatus, feedbackStatus, ...otherFilters } =
    filters || {};

  const response = useInfiniteQuery<IInfiniteResult<ICaseListItem[]>, IError>({
    ...options,
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getOrganizationCasesWithPaging(
        organizationId,
        {
          page: pageParam,
          page_size: pageSize,
          search: searchValue,
          feedbackStatus,
          synchronizationStatus,
        },
        otherFilters
      );

      return convertIntoObjectWithPageInfo<ICaseListItem[]>(data);
    },
    queryKey: [
      QueryKeys.getOrganizationCases,
      organizationId,
      searchValue,
      filters,
    ],
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage;
    },
    getPreviousPageParam: (firstPage) => firstPage.previousPage,
  });

  return {
    ...response,
    totalItemsCount: response.data?.pages[0].totalItemsCount ?? 0,
    cases: returnAllDataFromResponse<ICaseListItem>(response),
  };
}

export default useInfiniteOrganizationCasesList;
