import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useToggleArchiveCaseSymptom from 'api/mutations/symptoms/useToggleArchiveCaseSymptom';
import useOrganizationSymptoms from 'api/queries/symptoms/useOrganizationSymptoms';
import classNames from 'classnames';
import SwitchController from 'components/SwitchController';
import useRequiredParams from 'hooks/useRequiredParams';
import { ICaseSymptomDefinition } from 'interfaces/symptoms';
import React from 'react';
import { Button, Col, Input, Row } from 'reactstrap';
import { isNorwegian } from 'translations/utils/cultures';

type SymptomRowProps = {
  onToggleSuccess: () => void;
  isActive?: boolean;
  caseMeaningId?: string;
  selectedObservationMeaning: string;
  symptomName: string;
  onRowChange?: (caseMeaningDefinition: ICaseSymptomDefinition) => void;
  onRowDelete?: () => void;
  isEditable: boolean;
};

const SymptomRow = ({
  onToggleSuccess,
  isActive = true,
  caseMeaningId,
  selectedObservationMeaning,
  symptomName,
  onRowChange,
  onRowDelete,
  isEditable,
}: SymptomRowProps) => {
  const { caseId, organizationId } = useRequiredParams<{
    caseId: string;
    organizationId: string;
  }>(['caseId', 'organizationId']);

  const { data: organizationObservationMeaningsData } = useOrganizationSymptoms(
    { params: { organizationId }, options: { enabled: false } }
  );

  const possibleObservationMeanings = organizationObservationMeaningsData?.map(
    (el) => el.symptom
  );

  const { mutate: toggleArchiveMutation, isLoading } =
    useToggleArchiveCaseSymptom(organizationId, caseId, onToggleSuccess);

  const isDisabled = !!caseMeaningId;

  const possibleObservationMeaningsOptions = possibleObservationMeanings?.map(
    (el) => (
      <option key={el.id} value={el.id}>
        {isNorwegian() ? el.name_no : el.name}
      </option>
    )
  );

  const onMeaningChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onRowChange) {
      onRowChange({
        symptom_id: e.target.value,
        patient_symptom: symptomName,
      });
    }
  };

  const onSymptomNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (onRowChange) {
      onRowChange({
        symptom_id: selectedObservationMeaning,
        patient_symptom: e.target.value,
      });
    }
  };

  return (
    <Row className={classNames(['p-2'])}>
      {isEditable && (
        <Col md={1}>
          {caseMeaningId ? (
            <SwitchController
              disabled={isLoading}
              isToggled={isActive}
              onToggle={() =>
                toggleArchiveMutation({
                  caseMeaningId: caseMeaningId,
                  shouldBeArchived: !isActive,
                })
              }
            />
          ) : (
            <Button color="danger" onClick={onRowDelete}>
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          )}
        </Col>
      )}
      <Col md={isEditable ? 5 : 6}>
        <Input
          type="select"
          value={selectedObservationMeaning}
          disabled={isDisabled}
          onChange={onMeaningChange}
          className={classNames({
            ObservationMeaning__inactive: caseMeaningId && !isActive,
            ObservationMeaning__active: caseMeaningId && isActive,
          })}
        >
          <option value=""></option>
          {possibleObservationMeaningsOptions}
        </Input>
      </Col>
      <Col md={6}>
        <Input
          value={symptomName}
          disabled={isDisabled}
          onChange={onSymptomNameChange}
          className={classNames({
            ObservationMeaning__inactive: caseMeaningId && !isActive,
            ObservationMeaning__active: caseMeaningId && isActive,
          })}
        />
      </Col>
    </Row>
  );
};

export default SymptomRow;
