import { useCommonParams } from '../../../../../../../../../../../hooks/useCommonParams';
import { useEffect, useState } from 'react';
import { IErrorDefinition } from '../../../../../../../../../../../../../interfaces/response';
import { ConversationTemplateValidationResult } from '../../../../../../../../../../../../../interfaces/conversations';
import useValidateImportedConversationTemplate from '../../../../../../../../../../../../../api/mutations/conversations/useValidateImportedConversationTemplate';
import { ValidationStatus } from '../types';
import { ValidationResult } from '../../../../../types';

export const useValidateTemplate = (file: string) => {
  const { organizationId } = useCommonParams();

  const [status, setStatus] = useState<ValidationStatus>(ValidationStatus.Idle);

  const [error, setError] = useState<string | IErrorDefinition[] | null>(null);

  const [response, setResponse] = useState<ValidationResult | null>(null);

  const handleSuccess = (body: ConversationTemplateValidationResult) => {
    setStatus(ValidationStatus.Success);
    setResponse({ ...body, ready_to_complete: body.conflicts.length === 0 });
  };

  const handleError = (data: string | IErrorDefinition[]) => {
    setStatus(ValidationStatus.Error);
    setError(data);
  };

  const { mutateAsync: validateAsync, isLoading: validating } =
    useValidateImportedConversationTemplate({
      params: { organizationId },
      options: { successFb: handleSuccess, errorFb: handleError },
      silent: true,
    });

  useEffect(() => {
    (async () => {
      await validateAsync({
        conflicts: [],
        content: file,
      });
    })();
  }, [file, validateAsync]);

  return {
    status,
    error,
    response,
    validating,
  };
};
