import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { ICreateCaseSubCategory } from '../../../interfaces/caseCategories';
import { createCaseSubCategory } from '../../caseCategories';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { IMutationProps } from '../../../interfaces/ui';

type useCreateCaseSubCategoryParams = {
  organizationId: string;
};

const useCreateCaseSubCategory = (
  params: useCreateCaseSubCategoryParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification<ICreateCaseSubCategory, void>({
    asyncApiFunction: async (data) => {
      await createCaseSubCategory(params.organizationId, data);
    },
    resetOnResult: true,
    onErrorArray: (errors) => {
      displayErrorArrayNotifications(
        errors,
        'MyOrganizations.caseSubCategories.create'
      );
    },
    onSuccess: () => {
      if (options.successFb) options.successFb();

      displaySuccessNotification(
        'MyOrganizations.caseSubCategories.create.success'
      );
    },
  });
};

export default useCreateCaseSubCategory;
