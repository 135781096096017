import { faCheck, faCircle, faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SubmissionStatus } from 'interfaces/submissions';
import React from 'react';

type ProjectUsersListRowStatusLabelProps = {
  status: SubmissionStatus;
};

const ProjectUsersListRowStatusLabel = ({
  status,
}: ProjectUsersListRowStatusLabelProps) => {
  const pendingIcon = status === SubmissionStatus.PENDING ? faCircle : faTimes;

  return (
    <div
      className={`ProjectUsersList__table__status ProjectUsersList__table__status--${status.toLowerCase()}`}
    >
      <div className="ProjectUsersList__table__status__icon">
        <FontAwesomeIcon
          icon={status === SubmissionStatus.APPROVED ? faCheck : pendingIcon}
        />
      </div>
      <div className="ProjectUsersList__table__status__text">{status}</div>
    </div>
  );
};

export default ProjectUsersListRowStatusLabel;
