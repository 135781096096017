import request from './utils/request';
import { IResponse } from '../interfaces/response';
import {
  normalizeQueryParams,
  QueryParamsArrayItem,
} from '../helpers/utils/normalizeQueryParams';
import { cloneDeep } from 'lodash';
import {
  ICaseConversationSchedule,
  ICaseGroupConversationSchedule,
  ICaseGroupConversationScheduleResponses,
  ICreateCaseConversationSchedule,
  ICreateCaseGroupConversationSchedule,
  IUpdateCaseConversationSchedule,
  IUpdateCaseGroupConversationSchedule,
} from '../interfaces/conversationSchedules';

export const getCaseGroupConversationSchedules = (
  organizationId: string,
  groupId: string,
  phaseId?: string,
  dateFrom?: Date,
  dateTo?: Date
) => {
  let queryParams: QueryParamsArrayItem = {};

  if (phaseId) queryParams['phase_id'] = phaseId;

  if (dateFrom) {
    let dateCopy = cloneDeep(dateFrom);
    const offset = -1 * dateCopy.getTimezoneOffset();
    dateCopy.setMinutes(dateCopy.getMinutes() + offset);

    queryParams['date_from'] = dateCopy.toISOString();
  }

  if (dateTo) {
    let dateCopy = cloneDeep(dateTo);
    const offset = -1 * dateCopy.getTimezoneOffset();
    dateCopy.setMinutes(dateCopy.getMinutes() + offset);

    queryParams['date_to'] = dateCopy.toISOString();
  }

  return request<void, IResponse<ICaseGroupConversationSchedule[]>>({
    url: `/organizations/${organizationId}/case_groups/${groupId}/conversation_schedules${normalizeQueryParams(
      queryParams
    )}`,
    method: 'GET',
  });
};

export const updateCaseGroupConversationSchedule = (
  organizationId: string,
  caseGroupId: string,
  id: string,
  body: IUpdateCaseGroupConversationSchedule
) =>
  request<IUpdateCaseGroupConversationSchedule, void>({
    url: `/organizations/${organizationId}/case_groups/${caseGroupId}/conversation_schedules/${id}`,
    method: 'PUT',
    data: body,
  });

export const createCaseGroupConversationSchedule = (
  organizationId: string,
  caseGroupId: string,
  body: ICreateCaseGroupConversationSchedule
) =>
  request<ICreateCaseGroupConversationSchedule, void>({
    url: `/organizations/${organizationId}/case_groups/${caseGroupId}/conversation_schedules`,
    method: 'POST',
    data: body,
  });

export const createMultipleCaseGroupConversationSchedule = (
  organizationId: string,
  caseGroupId: string,
  body: ICreateCaseGroupConversationSchedule[]
) =>
  request<ICreateCaseGroupConversationSchedule[], void>({
    url: `/organizations/${organizationId}/case_groups/${caseGroupId}/conversation_schedules/multiple`,
    method: 'POST',
    data: body,
  });

export const deleteCaseGroupConversationSchedule = (
  organizationId: string,
  caseGroupId: string,
  id: string
) =>
  request<void, void>({
    url: `/organizations/${organizationId}/case_groups/${caseGroupId}/conversation_schedules/${id}`,
    method: 'DELETE',
  });

export const getCaseConversationSchedules = (
  organizationId: string,
  caseId: string
) =>
  request<void, IResponse<ICaseConversationSchedule[]>>({
    url: `/organizations/${organizationId}/cases/${caseId}/conversation_schedules`,
    method: 'GET',
  });

export const getCaseGroupConversationScheduleResponses = (
  organizationId: string,
  caseGroupId: string,
  scheduleId: string
) =>
  request<void, IResponse<ICaseGroupConversationScheduleResponses>>({
    url: `organizations/${organizationId}/case_groups/${caseGroupId}/conversation_schedules/${scheduleId}/responses`,
    method: 'GET',
  });

export const createCaseConversationSchedule = (
  organizationId: string,
  caseId: string,
  body: ICreateCaseConversationSchedule
) =>
  request<ICreateCaseConversationSchedule, void>({
    url: `/organizations/${organizationId}/cases/${caseId}/conversation_schedules`,
    method: 'POST',
    data: body,
  });

export const createMultipleCaseConversationSchedule = (
  organizationId: string,
  caseId: string,
  body: ICreateCaseConversationSchedule[]
) =>
  request<ICreateCaseConversationSchedule[], void>({
    url: `/organizations/${organizationId}/cases/${caseId}/conversation_schedules/multiple`,
    method: 'POST',
    data: body,
  });

export const deleteCaseConversationSchedule = (
  organizationId: string,
  caseId: string,
  id: string
) =>
  request<void, void>({
    url: `/organizations/${organizationId}/cases/${caseId}/conversation_schedules/${id}`,
    method: 'DELETE',
  });

export const updateCaseConversationSchedule = (
  organizationId: string,
  caseId: string,
  id: string,
  body: IUpdateCaseConversationSchedule
) =>
  request<IUpdateCaseConversationSchedule, void>({
    url: `/organizations/${organizationId}/cases/${caseId}/conversation_schedules/${id}`,
    method: 'PUT',
    data: body,
  });
