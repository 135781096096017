import useModifyCaseMemberRequest from 'api/mutations/requests/useModifyCaseMemberRequest';
import { ICaseAccessRequest } from 'interfaces/personalities';
import React from 'react';
import RequestRow from './RequestRow';

type CaseAccessRequestRowProps = {
  organizationId: string;
  onSuccess: () => void;
  data: ICaseAccessRequest;
};

const CaseAccessRequestRow = ({
  data,
  onSuccess,
  organizationId,
}: CaseAccessRequestRowProps) => {
  const { mutate, isLoading } = useModifyCaseMemberRequest(
    organizationId,
    onSuccess
  );

  const casesNames = <p>{data.title}</p>;

  return (
    <RequestRow
      casesNames={casesNames}
      numberOfRequestedCases={1}
      data={data}
      isLoading={isLoading}
      mutate={mutate}
    />
  );
};

export default CaseAccessRequestRow;
