import React from 'react';
import {
  BaseModalProps,
  ViewTheme,
} from '../../../../../../../../interfaces/ui';
import classNames from 'classnames';
import { Modal, ModalBody } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { useCookie } from '../../../../../../../../hooks/useCookie';
import Button from '@mui/material/Button';
import { useHandleSave } from './hooks/useHandleSave';
import { Formik } from 'formik';
import { useForm } from './hooks/useForm';
import TextField from '@mui/material/TextField';
import QuestionFields from './components/QuestionFields';
import { ConversationTemplate } from '../../../../../../../../interfaces/conversations';
import FetchParametersField from './components/FetchParametersField';

type ScheduleSessionModalProps = BaseModalProps & {
  refetch: () => void;
  template: ConversationTemplate;
};

const ScheduleSessionModal = ({
  isOpen,
  toggle,
  refetch,
  template,
}: ScheduleSessionModalProps) => {
  const themeCookie = useCookie<ViewTheme>('theme');

  const intl = useIntl();
  const handleSave = useHandleSave(refetch, toggle);

  const { initialValues, handleSubmit, validationSchema } = useForm(
    template,
    handleSave
  );

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      size="sm"
      sx
      className={classNames(
        'GenericModal BorderlessModal ScheduleSessionModal',
        {
          'GenericModal--dark': themeCookie === ViewTheme.Dark,
        }
      )}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnMount={true}
        onSubmit={handleSubmit}
      >
        {(props) => (
          <ModalBody className="flex flex-column gap-2">
            <div className="flex flex-column gap-2">
              <div className="flex items-center mb-3">
                <div
                  className={classNames('w-full h-px', {
                    'bg-black': themeCookie !== ViewTheme.Dark,
                    'bg-white': themeCookie === ViewTheme.Dark,
                  })}
                />
                <span className="px-1">
                  <FormattedMessage id="Cases.Session" />
                </span>
                <div
                  className={classNames('w-full h-px', {
                    'bg-black': themeCookie !== ViewTheme.Dark,
                    'bg-white': themeCookie === ViewTheme.Dark,
                  })}
                />
              </div>
              <div className="flex flex-column gap-2">
                <TextField
                  variant="standard"
                  type="date"
                  name="date"
                  fullWidth={true}
                  placeholder={intl.formatMessage({
                    id: 'General.Date',
                  })}
                  value={props.values.date}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  error={props.touched.date && Boolean(props.errors.date)}
                  helperText={props.touched.date && props.errors.date}
                />
                <TextField
                  variant="standard"
                  type="time"
                  name="time"
                  fullWidth={true}
                  placeholder={intl.formatMessage({
                    id: 'General.Time',
                  })}
                  value={props.values.time}
                  onChange={props.handleChange}
                  onBlur={props.handleBlur}
                  error={props.touched.time && Boolean(props.errors.time)}
                  helperText={props.touched.time && props.errors.time}
                />
              </div>
            </div>
            <div className="d-flex flex-column gap-2">
              <div className="flex items-center mt-3">
                <div
                  className={classNames('w-full h-px', {
                    'bg-black': themeCookie !== ViewTheme.Dark,
                    'bg-white': themeCookie === ViewTheme.Dark,
                  })}
                />
                <span className="px-1">
                  <FormattedMessage id="Cases.Patient" />
                </span>
                <div
                  className={classNames('w-full h-px', {
                    'bg-black': themeCookie !== ViewTheme.Dark,
                    'bg-white': themeCookie === ViewTheme.Dark,
                  })}
                />
              </div>
              <div className="flex flex-column gap-2 mb-4">
                <div className="flex items-end gap-1">
                  <TextField
                    sx={{ width: '100%' }}
                    variant="standard"
                    type="number"
                    id="patient_number"
                    name="patient_number"
                    label={intl.formatMessage({
                      id: 'Cases.PatientNumber',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'Cases.PatientNumber',
                    })}
                    value={props.values.patient_number}
                    onChange={props.handleChange}
                    onBlur={props.handleBlur}
                    error={
                      props.touched.patient_number &&
                      Boolean(props.errors.patient_number)
                    }
                    helperText={
                      props.touched.patient_number &&
                      props.errors.patient_number
                    }
                  />
                  <FetchParametersField form={props} template={template} />
                </div>
                <QuestionFields
                  key={JSON.stringify(props.values.questions)}
                  form={props}
                  template={template}
                />
              </div>
            </div>
            <Button
              onClick={props.submitForm}
              disabled={!props.isValid}
              sx={{ minWidth: 120 }}
              variant="contained"
              color="primary"
            >
              <FormattedMessage id="General.Register" />
            </Button>
          </ModalBody>
        )}
      </Formik>
    </Modal>
  );
};

export default ScheduleSessionModal;
