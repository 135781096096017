import React, { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import SVG from 'react-inlinesvg';

// ACTION:
import { useLocation, useNavigate } from 'react-router';

import workMarkBlue from 'assets/images/work_marks/work_mark_blue.svg';

import organizationMemberLogin from 'assets/images/split_login/organization_member_login.svg';
import individualLogin from 'assets/images/split_login/individual_login.svg';
import { BsGoogle } from 'react-icons/bs';
import { VscAzure } from 'react-icons/vsc';
import MembershipLayout from 'components/layouts/MembershipLayout';
import RadioLikeCaption from './components/RadioLikeCaption';
import classNames from 'classnames';

export enum ExternalProvider {
  Google = 'Google',
  OpenIdConnect = 'OpenIdConnect',
}

enum LoginMethodSelection {
  AsOrganizationMember = 'AsOrganizationMember',
  AsIndividual = 'AsIndividual',
}

export const returnIconBasedOnExternalProvider = (
  provider: ExternalProvider
) => {
  switch (provider) {
    case ExternalProvider.Google:
      return <BsGoogle />;
    case ExternalProvider.OpenIdConnect:
      return <VscAzure />;
    default:
      return <></>;
  }
};

const Login = () => {
  const intl = useIntl();
  const navigate = useNavigate();

  const location = useLocation();

  const [loginMethodSelection, setLoginMethodSelection] =
    useState<LoginMethodSelection | null>(null);

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      const backUrl = searchParams.get('back');
      if (backUrl) {
        localStorage.setItem('redirectUrl', backUrl);
      }
    }
  }, [location.search]);

  const redirectToB2B = async () => {
    setLoginMethodSelection(LoginMethodSelection.AsOrganizationMember);
    await new Promise((r) => setTimeout(r, 200));
    navigate('/login/b2b');
  };

  const redirectToB2C = async () => {
    setLoginMethodSelection(LoginMethodSelection.AsIndividual);
    await new Promise((r) => setTimeout(r, 200));
    navigate('/login/b2c');
  };

  return (
    <MembershipLayout>
      <div className="SplitLogin newMembersSection pt-0 ">
        <div className="SplitLogin__form">
          <div className="SplitLogin__form__logo">
            <SVG
              src={workMarkBlue}
              title={intl.formatMessage({
                id: 'MyOrganizations.Logo',
              })}
            />
          </div>
          <h2 className="SplitLogin__form__login-as">
            <FormattedMessage id="Login.loginAs" />
          </h2>
          <span className="SplitLogin__form__choose-role">
            <FormattedMessage id="Login.chooseRole" />
          </span>
          <div className="SplitLogin__form__options">
            <div
              className="SplitLogin__form__options__option"
              onClick={redirectToB2B}
            >
              <div
                className={classNames({
                  SplitLogin__form__options__option__button: true,
                  'SplitLogin__form__options__option__button--organization-member':
                    true,
                  'SplitLogin__form__options__option__button--selected':
                    loginMethodSelection ===
                    LoginMethodSelection.AsOrganizationMember,
                })}
              >
                <SVG
                  src={organizationMemberLogin}
                  title={intl.formatMessage({
                    id: 'Login.asMedicalPersonnel',
                  })}
                />
              </div>
              <div className="mt-3">
                <RadioLikeCaption
                  translationId="Login.asMedicalPersonnel"
                  selected={
                    loginMethodSelection ===
                    LoginMethodSelection.AsOrganizationMember
                  }
                />
              </div>
            </div>
            <div
              className="SplitLogin__form__options__option"
              onClick={redirectToB2C}
            >
              <div
                className={classNames({
                  SplitLogin__form__options__option__button: true,
                  'SplitLogin__form__options__option__button--individual': true,
                  'SplitLogin__form__options__option__button--selected':
                    loginMethodSelection === LoginMethodSelection.AsIndividual,
                })}
              >
                <SVG
                  src={individualLogin}
                  title={intl.formatMessage({
                    id: 'Login.asPatient',
                  })}
                />
              </div>
              <div className="mt-3">
                <RadioLikeCaption
                  translationId="Login.asPatient"
                  selected={
                    loginMethodSelection === LoginMethodSelection.AsIndividual
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </MembershipLayout>
  );
};

export default Login;
