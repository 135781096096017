import { Paper } from '@mui/material';
import { Expressions } from 'helpers/expressions';
import { useCultureEntry } from 'hooks/cultures/useCultureEntry';
import React, { useMemo } from 'react';
import useCultures from '../../../../../../../../../../../../hooks/cultures/useCultures';
import { useTypedContext } from '../../../../../../../../../../../../hooks/useTypedContext';
import { ITextDashboardTemplateVisualization } from '../../../../../../../../../../../../interfaces/dashboards';
import { useObjectPalette } from '../../hooks/useObjectPalette';
import { VisualizationDataContext } from '../../types';

type TextViewProps = {
  visualization: ITextDashboardTemplateVisualization;
};

const TextView = ({ visualization }: TextViewProps) => {
  const { currentCulture } = useCultures();

  const visualizationData = useTypedContext(VisualizationDataContext);
  const objectPalette = useObjectPalette(visualization.palette);
  const translatedContentHtml = useCultureEntry(visualization.content_html);

  const evaluate = useMemo(() => {
    return Expressions.HandlebarsEvaluate(
      translatedContentHtml ?? '',
      {
        ...objectPalette,
        sessions: {
          current: visualizationData.sessions.current.data,
          reference: visualizationData.sessions.reference.data,
        },
        reports: {
          current: visualizationData.reports.current.data,
          reference: visualizationData.reports.reference.data,
        },
        items: {
          current: visualizationData.items.current.data,
          reference: visualizationData.items.reference.data,
        },
      },
      currentCulture
    );
  }, [
    currentCulture,
    objectPalette,
    translatedContentHtml,
    visualizationData.items,
    visualizationData.reports,
    visualizationData.sessions,
  ]);

  const containerStyle = useMemo(
    () =>
      visualization.container_style
        ? JSON.parse(visualization.container_style)
        : {},
    [visualization.container_style]
  );

  return (
    <Paper
      className="d-flex align-items-center justify-content-center w-100 h-100"
      elevation={0}
      sx={{
        backgroundColor: 'background.default',
        color: 'text.primary',
        textAlign: 'center',
        borderRadius: 0,
        ...containerStyle,
      }}
      dangerouslySetInnerHTML={{
        __html: evaluate,
      }}
    ></Paper>
  );
};

export default TextView;
