import { useCallback, useMemo } from 'react';
import useUserPathBasedBrowsingContext from '../../../../../hooks/useUserPathBasedBrowsingContext';
import { TimelineTabsProps } from '../../../../../interfaces/timeline';
import useCaseDetails from '../../../../../api/queries/cases/useCaseDetails';
import { Spinner } from 'reactstrap';
import { useTimelineDetailsDateRangeObject } from 'store/reducerHooks';
import useCaseRelatedTrendVisualizationTemplates from '../../../../../api/queries/trendVisualizations/useCaseRelatedTrendVisualizationTemplates';
import useTimelineRelatedTrendVisualizationTemplates from '../../../../../api/queries/trendVisualizations/useTimelineRelatedTrendVisualizationTemplates';
import {
  TemplateElement,
  TemplateItemType,
} from '../../../../../interfaces/change';
import NoAvailableSetupsChartsPlaceholder from './components/ChartPlaceholders/NoAvailableSetupsChartsPlaceholder';
import TrendsSummarySqueezeChart, {
  TrendsSummarySqueezeChartType,
} from './TrendsSummarySqueezeChart';
import TrendsSummaryQuestionChart from './TrendsSummaryQuestionChart';
import { AssessmentQuestionHistoryEntryItem } from '../../../../../interfaces/cases';
import useUserQuestionAnswerHistory from '../../../../../api/queries/change/useUserQuestionAnswerHistory';
import TrendsSummaryQuestionChartPlaceholder from './TrendsSummaryQuestionChartPlaceholder';
import { TrendVisualizationChartType } from '../../../../../interfaces/trendVisualizations';

const TrendsSummary = ({ timelineId }: TimelineTabsProps) => {
  const {
    isOrganizationContext,
    isRegularUserContext,
    organizationId = '',
    caseId = '',
    userId = '',
  } = useUserPathBasedBrowsingContext();

  const dateRangeObject = useTimelineDetailsDateRangeObject();

  const caseRelatedTrendVisualizationTemplates =
    useCaseRelatedTrendVisualizationTemplates({
      params: { organizationId, caseId },
      options: { enabled: isOrganizationContext },
    });

  const timelineRelatedTrendVisualizationTemplates =
    useTimelineRelatedTrendVisualizationTemplates({
      params: {
        userId,
        timelineId,
        dateFrom: dateRangeObject?.from
          ? dateRangeObject.from.toISOString()
          : null,
        dateTo: dateRangeObject?.to ? dateRangeObject.to.toISOString() : null,
      },
      options: { enabled: isRegularUserContext },
    });

  const templatesQuery = isOrganizationContext
    ? caseRelatedTrendVisualizationTemplates
    : timelineRelatedTrendVisualizationTemplates;

  const { data: caseData } = useCaseDetails({
    params: { organizationId, caseId },
    options: { enabled: isOrganizationContext },
  });

  const currentUserId = useMemo(() => {
    return isOrganizationContext
      ? caseData?.participants[0]?.personality?.user_id ?? ''
      : userId;
  }, [caseData?.participants, isOrganizationContext, userId]);

  const questionIds = useMemo(() => {
    if (!templatesQuery.data || !templatesQuery.isFetched) return [];

    const visualizationsWithQuestionElements =
      templatesQuery.data.visualizations.filter((p) =>
        p.elements.find((q) => q.type === TemplateItemType.Questions)
      );
    const questionContainingElements =
      visualizationsWithQuestionElements.flatMap((p) => p.elements);

    return questionContainingElements
      .filter((p) => p.question_id !== null)
      .map((p) => p.question_id) as string[];
  }, [templatesQuery.data, templatesQuery.isFetched]);

  const answerHistoryDataFetchAvailable = useMemo(() => {
    if (questionIds.length === 0) return false;
    return currentUserId !== '';
  }, [currentUserId, questionIds.length]);

  const { data: answerHistoryData } = useUserQuestionAnswerHistory({
    params: {
      userId: currentUserId,
      dateFrom: dateRangeObject?.from ?? null,
      dateTo: dateRangeObject?.to ?? null,
      questionIds,
    },
    options: {
      enabled: answerHistoryDataFetchAvailable,
    },
  });

  const renderSqueeze = useCallback(
    (
      squeezeType: TrendsSummarySqueezeChartType,
      chartType: TrendVisualizationChartType,
      timelineId: string
    ) => {
      return (
        <TrendsSummarySqueezeChart
          squeezeType={squeezeType}
          chartType={chartType}
          chartTypeName={null}
          timelineId={timelineId}
          removeChart={null}
        />
      );
    },
    []
  );

  const renderQuestion = useCallback(
    (elements: TemplateElement[]) => {
      if (!answerHistoryData) return <TrendsSummaryQuestionChartPlaceholder />;

      const elementsIds = elements
        .filter((p) => p.question_id !== null)
        .map((p) => p.question_id) as string[];

      const historyEntryItems: AssessmentQuestionHistoryEntryItem[] =
        answerHistoryData.questions.map((p) => ({
          questionId: p.question_id,
          questionTitle: p.question_title,
          questionColor: p.question_color,
          answerHistory: p.answer_history,
        }));

      const elementItems = historyEntryItems.filter((p) =>
        elementsIds.includes(p.questionId)
      );

      return (
        <TrendsSummaryQuestionChart
          organizationId={organizationId ?? ''}
          elements={elements}
          questions={elementItems}
        />
      );
    },
    [answerHistoryData, organizationId]
  );

  const renderCharts = useMemo(() => {
    if (!templatesQuery.data) return <></>;

    return templatesQuery.data.visualizations.map((visualization) => {
      const isSqueezeQuantityVisualization =
        visualization.elements.length === 1 &&
        visualization.elements[0].type === TemplateItemType.AllObservations;

      if (isSqueezeQuantityVisualization)
        return renderSqueeze(
          TrendsSummarySqueezeChartType.SqueezeQuantity,
          visualization.elements[0].chart_type,
          timelineId
        );

      return renderQuestion(visualization.elements);
    });
  }, [renderQuestion, renderSqueeze, templatesQuery.data, timelineId]);

  return (
    <div className="TrendsTimelineCharts">
      {templatesQuery.isLoading ? (
        <div className="TrendsTimelineCharts__loading">
          <Spinner color="primary" />
        </div>
      ) : (
        <>
          {templatesQuery.isError ? (
            <NoAvailableSetupsChartsPlaceholder />
          ) : (
            <>{renderCharts}</>
          )}
        </>
      )}
    </div>
  );
};

export default TrendsSummary;
