import React from 'react';
import Skeleton from 'react-loading-skeleton';

type SkeletonPlaceholderOnLoadProps = {
  isLoading: boolean;
  placeholder?: JSX.Element;
  children: JSX.Element;
  block?: boolean;
};

const BlockWrapper: React.FC = ({ children }: React.PropsWithChildren) => {
  return <div style={{ display: 'block' }}>{children}</div>;
};

const SkeletonPlaceholderOnLoad = ({
  isLoading,
  placeholder,
  children,
  block = false,
}: SkeletonPlaceholderOnLoadProps) => {
  if (!isLoading) return children;
  const wrapper = block ? BlockWrapper : undefined;

  return placeholder ? placeholder : <Skeleton wrapper={wrapper} />;
};

export default SkeletonPlaceholderOnLoad;
