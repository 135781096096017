import { differenceInHours } from 'date-fns';
import _, { cloneDeep } from 'lodash';
import { IAwareSessionData } from '../../../../../../../../../../../../interfaces/awareSessions';
import { ConversationQuestion } from '../../../../../../../../../../../../interfaces/conversations';
import {
  DashboardTemplateSeries,
  DashboardTemplateSeriesAggregate,
  DashboardTemplateSeriesDataSource,
  DashboardTemplateSeriesSplitBy,
  DashboardTemplateSplitType,
  DateGranularity,
} from '../../../../../../../../../../../../interfaces/dashboards';
import { StrictDateRange } from '../../../../../../../../../../../../interfaces/dates';
import { DashboardAggregationHelpers } from '../../../../../../helpers/aggregation';
import { DashboardSessionsHelpers } from '../../../../../../helpers/sessions';
import {
  INameBasedChartsData,
  NamedAndSplitByValues,
  SourceSplitValueField,
  SplitByValues,
  ValuesField,
} from './types';

export namespace ChartViewHelpers {
  export const ProvideValueSessions = (
    sessions: IAwareSessionData[],
    config: DashboardTemplateSeries[],
    notSpecifiedTranslation: string
  ): ValuesField<IAwareSessionData>[] => {
    let valueSessions = _wrapWithValueField(sessions, config);
    valueSessions = valueSessions.map((session) => ({
      ..._valuesBasedOnSources(session, notSpecifiedTranslation),
    }));

    return valueSessions;
  };

  export const SplitValueSessions = (
    sessions: ValuesField<IAwareSessionData>[],
    splitBy: DashboardTemplateSeriesSplitBy | 'Date',
    dateGranularity: DateGranularity | undefined,
    dateRange: StrictDateRange,
    questions: ConversationQuestion[],
    notSpecified: string
  ): SplitByValues<ValuesField<IAwareSessionData>>[] => {
    if (splitBy !== 'Date') {
      switch (splitBy.type) {
        case DashboardTemplateSplitType.Question:
          return DashboardSessionsHelpers.SplitValueSessionsByQuestion(
            sessions,
            splitBy,
            questions,
            notSpecified
          );
        case DashboardTemplateSplitType.Doctor:
          return DashboardSessionsHelpers.SplitValueSessionsByProperty(
            sessions,
            'member_id'
          );
        case DashboardTemplateSplitType.Grasp:
          return DashboardSessionsHelpers.SplitValueSessionsByProperty(
            sessions,
            'grasp_id'
          );
        case DashboardTemplateSplitType.Organization:
          return DashboardSessionsHelpers.SplitValueSessionsByProperty(
            sessions,
            'organization_id'
          );
      }
    }

    return DashboardSessionsHelpers.SplitValueByDateRange(sessions, dateRange);
  };

  export const AggregateValueSessions = <T extends INameBasedChartsData>(
    sessions: NamedAndSplitByValues<ValuesField<IAwareSessionData>>[],
    seriesSplitBy?: DashboardTemplateSeriesSplitBy
  ): T[] => {
    let data: any[] = [];

    sessions.forEach((session) => {
      data.push({
        name: session.name,
        ..._aggregateValues(session.items, seriesSplitBy),
      });
    });

    return data;
  };

  export const GetDataKeys = <T extends INameBasedChartsData>(
    data: T[]
  ): string[] => {
    let keys: string[] = [];

    data.forEach((item) =>
      keys.push(...Object.keys(item).filter((p) => p !== 'name'))
    );

    return [...new Set(keys)];
  };

  export const GetChartsInterval = (dateRange: StrictDateRange) => {
    const hoursDiff = differenceInHours(dateRange.to, dateRange.from);

    if (hoursDiff < 24) return 0;
    if (hoursDiff < 24 * 3) return 2;
    if (hoursDiff > 24 * 15 && hoursDiff < 24 * 30 * 3) return 2;
    if (hoursDiff >= 24 * 30 * 3) return 0;

    return 0;
  };

  export const GetWidthChartsInterval = (
    ticksCount: number,
    chartsWidth: number
  ) => {
    const minTickWidth: number = 50;
    const yAxisMargin: number = 30;

    let _interval = 0;
    let _ticksCount = ticksCount;
    let _chartsWidth = chartsWidth - yAxisMargin;

    while (_interval < ticksCount) {
      if (minTickWidth * _ticksCount < _chartsWidth) return _interval;
      _interval++;
      _ticksCount = _decimateArray(
        [...Array(ticksCount).map((_, index) => index)],
        1,
        _interval + 1
      ).length;
    }

    return _interval;
  };

  export const AreEmptyFormattedData = (
    data: any[],
    allowZeros: boolean = true
  ) => {
    if (data.length === 0) return true;

    const keys = data.map((p) => Object.keys(p));
    let flattenKeys = keys.flatMap((p) => p);
    flattenKeys = [...new Set(flattenKeys)];

    if (allowZeros) {
      return flattenKeys.length === 1 && flattenKeys.at(0) === 'name';
    }

    let nonNameObjects = cloneDeep(data);
    nonNameObjects = nonNameObjects.map((item) => {
      const itemCopy = cloneDeep(item);
      delete itemCopy.name;
      return itemCopy;
    });

    const values = nonNameObjects.map((p) => Object.values(p));
    let flattenValues = values.flatMap((p) => p);

    return flattenValues.every((p) => p === 0);
  };

  export const OrderValueSessions = <T extends INameBasedChartsData>(
    sessions: T[],
    notSpecified: string
  ): T[] => {
    let sessionsCopy = cloneDeep(sessions);
    sessionsCopy.sort((a, b) => a.name.localeCompare(b.name));

    const notSpecifiedIndex = sessionsCopy.findIndex(
      (p) => p.name === notSpecified
    );

    if (notSpecifiedIndex !== -1) {
      const excludedElement = sessionsCopy.splice(notSpecifiedIndex, 1);
      sessionsCopy.push(...excludedElement);
    }

    return sessionsCopy;
  };

  export const FilterEmptyNameBasedValueSessions = (
    sessions: INameBasedChartsData[]
  ): INameBasedChartsData[] => {
    return sessions.filter((p) =>
      _getNameBasedChartsDataValues(p).some((p) => p !== 0)
    );
  };

  const _decimateArray = (
    arr: any[],
    passes: number = 1,
    fidelity: number = 2
  ) => {
    let tmpArr = arr.filter((_, index) => index % fidelity === 0);
    passes--;

    if (passes) tmpArr = _decimateArray(tmpArr, passes, fidelity);
    return tmpArr;
  };

  const _getNameBasedChartsDataValues = (p: INameBasedChartsData): any[] => {
    return Object.entries(p)
      .filter(([key]) => key !== 'name')
      .map(([_, val]) => val);
  };

  const _aggregateValues = (
    items: ValuesField<IAwareSessionData>[],
    seriesSplitBy?: DashboardTemplateSeriesSplitBy
  ): Record<string, number> => {
    let dataEntry: Record<string, number> = {};

    const firstItem = items.at(0);
    if (!firstItem) return dataEntry;

    if (!seriesSplitBy) {
      return _basicAggregation(items, cloneDeep(dataEntry));
    }

    return _seriesSplitAndAggregate(items, dataEntry, seriesSplitBy);
  };

  const _basicAggregation = (
    items: ValuesField<IAwareSessionData>[],
    dataEntry: Record<string, number>
  ): Record<string, number> => {
    const firstItem = items[0];

    firstItem.values.forEach((valueItem) => {
      const aggregateMethod = valueItem.aggregation ?? 'Count';

      let valuesToAggregate = items
        .flatMap((p) => p.values)
        .filter(
          (p) =>
            p.source === valueItem.source &&
            p.aggregation === valueItem.aggregation
        )
        .map((p) => p.value);

      dataEntry[_getChartsDataName(valueItem.source, aggregateMethod)] =
        DashboardAggregationHelpers.AggregateValue(
          valuesToAggregate,
          aggregateMethod
        );
    });

    return dataEntry;
  };

  const _seriesSplitAndAggregate = (
    items: ValuesField<IAwareSessionData>[],
    dataEntry: Record<string, number>,
    seriesSplitBy: DashboardTemplateSeriesSplitBy
  ): Record<string, number> => {
    let sourceSplitItems: SourceSplitValueField[] = [];

    items.forEach((item) => {
      item.values.forEach((valueItem) => {
        sourceSplitItems.push({
          ...valueItem,
          sourceSplitValue: _getFieldBySeriesSplitBy(item, seriesSplitBy),
        });
      });
    });

    const sourceSplitGroups = _.groupBy(sourceSplitItems, 'sourceSplitValue');

    Object.entries(sourceSplitGroups).forEach(([key, value]) => {
      const aggregationGrouped = _.groupBy(
        value,
        (p) => `${p.source}|${p.aggregation ?? 'Count'}`
      );

      Object.entries(aggregationGrouped).forEach(
        ([aggregationKey, aggregationValue]) => {
          const splitKey = aggregationKey.split('|');
          const values = aggregationValue.map((p) => p.value);

          const source = splitKey[0] as DashboardTemplateSeriesDataSource;
          const aggregation = splitKey[1] as
            | DashboardTemplateSeriesAggregate
            | 'Count';

          dataEntry[_getSourceSplitChartsDataName(source, aggregation, key)] =
            DashboardAggregationHelpers.AggregateValue(values, aggregation);
        }
      );
    });

    return dataEntry;
  };

  const _getFieldBySeriesSplitBy = (
    item: ValuesField<IAwareSessionData>,
    seriesSplitBy: DashboardTemplateSeriesSplitBy
  ) => {
    switch (seriesSplitBy.type) {
      case DashboardTemplateSplitType.Organization:
        return item.organization_id;
      case DashboardTemplateSplitType.Doctor:
        return item.member_id;
      case DashboardTemplateSplitType.Grasp:
        return item.grasp_id;
      case DashboardTemplateSplitType.Question:
        return (
          item.questions.find((p) => p.id === seriesSplitBy.id)?.value ?? ''
        );
    }
  };

  const _getChartsDataName = (
    source: DashboardTemplateSeriesDataSource,
    method: DashboardTemplateSeriesAggregate | 'Count'
  ) => {
    let name = method !== 'Count' ? method : '';
    name = `${name}|${source}`;
    return name;
  };

  const _getSourceSplitChartsDataName = (
    source: DashboardTemplateSeriesDataSource,
    method: DashboardTemplateSeriesAggregate | 'Count',
    sourceSplitValue: string
  ) => {
    let name = method !== 'Count' ? method : '';
    name = `${name}|${source}|${sourceSplitValue}`;
    return name;
  };

  const _valuesBasedOnSources = (
    session: ValuesField<IAwareSessionData>,
    notSpecifiedTranslation: string
  ) => {
    session.values = session.values.map((value) => ({
      source: value.source,
      aggregation: value.aggregation ?? undefined,
      value: _valueBasedOnSource(
        session,
        value.source,
        notSpecifiedTranslation
      ),
    }));

    return session;
  };

  const _valueBasedOnSource = (
    session: ValuesField<IAwareSessionData>,
    source: DashboardTemplateSeriesDataSource,
    notSpecifiedTranslation: string
  ): number => {
    switch (source) {
      case DashboardTemplateSeriesDataSource.SessionCount:
        return 1;
      case DashboardTemplateSeriesDataSource.FeedbacksCount:
        return DashboardSessionsHelpers.IsSessionConsideredAsFeedback(
          session,
          notSpecifiedTranslation
        )
          ? 1
          : 0;
      case DashboardTemplateSeriesDataSource.AlarmsCount:
        return session.alarm_incidents;
      case DashboardTemplateSeriesDataSource.SqueezesCount:
        return session.total_squeezes;
      case DashboardTemplateSeriesDataSource.SessionDuration:
        return DashboardSessionsHelpers.GetSessionDuration(session);
    }
  };

  const _wrapWithValueField = (
    arr: IAwareSessionData[],
    config: DashboardTemplateSeries[]
  ): ValuesField<IAwareSessionData>[] => {
    return arr.map((item) => ({
      ...item,
      values: config.map((p) => ({
        source: p.data_source,
        aggregation: p.aggregate,
        value: 0,
      })),
    }));
  };
}
