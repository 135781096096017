import React from 'react';
import { ProjectModalFormStep } from '../../hooks/useProjectModalForm';
import { Button } from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import SVG from 'react-inlinesvg';
import arrowIcon from '../../../../../../assets/images/arrow_icon.svg';

type ProjectModalFooterProps = {
  step: ProjectModalFormStep;
  setStep: (step: ProjectModalFormStep) => void;
  toggle: () => void;
  submitForm: () => Promise<void>;
  isValid: boolean;
  dirty: boolean;
};

const ProjectModalFooter = ({
  step,
  setStep,
  toggle,
  submitForm,
  isValid,
  dirty,
}: ProjectModalFooterProps) => {
  const intl = useIntl();

  const footerButtons: JSX.Element[] = [];
  const stepsKeys = Object.keys(ProjectModalFormStep);

  footerButtons.push(
    <Button color="primary" outline={true} onClick={toggle}>
      <FormattedMessage id="Projects.ProjectModal.cancel" />
    </Button>
  );

  const handlePrevStep = () => {
    const indexOfCurrentStep = stepsKeys.indexOf(step);

    if (indexOfCurrentStep - 1 < 0) return;

    const prevStep = Object.values<ProjectModalFormStep>(
      ProjectModalFormStep
    ).find((val, idx) => idx === indexOfCurrentStep - 1);

    if (!prevStep) return;

    setStep(prevStep);
  };

  const handleNextStep = () => {
    const indexOfCurrentStep = stepsKeys.indexOf(step);

    if (indexOfCurrentStep + 1 >= stepsKeys.length) return;

    const nextStep = Object.values<ProjectModalFormStep>(
      ProjectModalFormStep
    ).find((val, idx) => idx === indexOfCurrentStep + 1);

    if (!nextStep) return;

    setStep(nextStep);
  };

  if (stepsKeys.indexOf(step) !== 0) {
    footerButtons.push(
      <Button
        className="ProjectModalFooter__navigation-btn"
        color="primary"
        outline={true}
        onClick={handlePrevStep}
      >
        <SVG
          src={arrowIcon}
          style={{ rotate: '180deg' }}
          title={intl.formatMessage({ id: 'Projects.ProjectModal.prev' })}
        />
        <FormattedMessage id="Projects.ProjectModal.prev" />
      </Button>
    );
  }

  if (stepsKeys.indexOf(step) !== stepsKeys.length - 1) {
    footerButtons.push(
      <Button
        className="ProjectModalFooter__navigation-btn"
        color="primary"
        onClick={handleNextStep}
      >
        <FormattedMessage id="Projects.ProjectModal.next" />
        <SVG
          src={arrowIcon}
          title={intl.formatMessage({ id: 'Projects.ProjectModal.next' })}
        />
      </Button>
    );
  }

  if (stepsKeys.indexOf(step) === stepsKeys.length - 1) {
    footerButtons.push(
      <Button
        color="primary"
        disabled={!isValid || !dirty}
        onClick={submitForm}
      >
        <FormattedMessage id="Projects.ProjectModal.save" />
      </Button>
    );
  }

  return <>{footerButtons}</>;
};

export default ProjectModalFooter;
