import React, { memo } from 'react';
import { Button } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

const GraspUploadItem = () => {
  return (
    <div className="ConversationTemplateRangeQuestionItem">
      <div className="ConversationTemplateRangeQuestionItem__question d-flex flex-column gap-2">
        <Button color="primary" block={true}>
          <FormattedMessage id="MyOrganizations.conversationTemplates.phone.uploadData" />
        </Button>
        <Button color="primary" block={true} outline={true}>
          <FormattedMessage id="MyOrganizations.conversationTemplates.phone.skipUpload" />
        </Button>
      </div>
    </div>
  );
};

export default memo(GraspUploadItem);
