import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import {
  predefinedChartModes,
  SqueezeQuantityType,
} from './SqueezeQuantityChart';

type SqueezeQuantityChartLegendProps = {
  selectedSqueezeQuantityType: SqueezeQuantityType;
  setSelectedSqueezeQuantityType: (type: SqueezeQuantityType) => void;
};

const SqueezeQuantityChartLegend = ({
  selectedSqueezeQuantityType,
  setSelectedSqueezeQuantityType,
}: SqueezeQuantityChartLegendProps) => {
  const returnLegendItems = Object.entries(SqueezeQuantityType).map(
    ([_, value], index) => {
      const handleChangeQuantityType = () => {
        setSelectedSqueezeQuantityType(value);
      };

      const getChartLegendIndicatorClassName = () => {
        switch (value) {
          case SqueezeQuantityType.SOFT_QUANTITY:
            return 'soft';
          case SqueezeQuantityType.MEDIUM_QUANTITY:
            return 'medium';
          case SqueezeQuantityType.HARD_QUANTITY:
            return 'hard';
          case SqueezeQuantityType.TOTAL_QUANTITY:
            return 'total';
        }
      };

      return (
        <li
          onClick={handleChangeQuantityType}
          className={classNames(
            { SqueezeQuantityChartLegend__item: true },
            {
              'SqueezeQuantityChartLegend__item--active':
                selectedSqueezeQuantityType === value,
            }
          )}
        >
          <div
            className={`SqueezeQuantityChartLegend__item__indicator SqueezeQuantityChartLegend__item__indicator--${getChartLegendIndicatorClassName()}`}
          ></div>
          <FormattedMessage id={predefinedChartModes[index].translationId} />
        </li>
      );
    }
  );

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="mb-2">
        <FormattedMessage id="TimelineDetails.TrendsTimeline.SqueezeQuantityChart.xAxis" />
      </div>
      <ul className="SqueezeQuantityChartLegend">{returnLegendItems}</ul>
    </div>
  );
};

export default SqueezeQuantityChartLegend;
