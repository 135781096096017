import { useCallback, useMemo } from 'react';
import { ISqueezeChartData, Meaning } from '../../../../../interfaces/timeline';

export const useSeries = (observations: ISqueezeChartData | undefined) => {
  const getSumOfCustomEntries = useCallback((entry: any) => {
    let sum: number = 0;

    for (const [, entryValue] of Object.entries(entry).filter(
      ([key, _]) => key !== 'name' && key !== Meaning.NotSpecified
    )) {
      let entryNumberValue = entryValue as number;
      sum += entryNumberValue;
    }

    return sum;
  }, []);

  const uncategorizedSeries = useMemo(() => {
    return (
      observations?.series.filter(
        (p) => p.mode === 'SqueezesQuantity' && p.meaning_id === null
      ) ?? []
    );
  }, [observations?.series]);

  const customSeries = useMemo(() => {
    return (
      observations?.series.filter(
        (p) => p.mode === 'SqueezesQuantity' && p.meaning_id !== null
      ) ?? []
    );
  }, [observations?.series]);

  const uncategorizedSeriesData = useMemo(() => {
    let uncategorizedSeriesData: any[] = [];

    uncategorizedSeries.forEach((entry) => {
      entry.data.forEach((dataEntry, index) => {
        const existingEntry = uncategorizedSeriesData.find(
          (p) => p.name === index
        );

        if (existingEntry) {
          existingEntry[entry.name] = dataEntry;
          return;
        }

        uncategorizedSeriesData.push({
          name: index,
          [entry.name]: dataEntry,
        });
      });
    });

    return uncategorizedSeriesData;
  }, [uncategorizedSeries]);

  return {
    getSumOfCustomEntries,
    uncategorizedSeries,
    customSeries,
    uncategorizedSeriesData,
  };
};
