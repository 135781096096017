import { formatDateToInputDateString } from 'helpers/dates';
import usePersonalityDetails from 'api/queries/patients/usePersonalityDetails';
import { CaseFormType } from 'interfaces/cases';
import { PatientFormKeys } from 'interfaces/personalities';
import React, { useEffect } from 'react';
import { initialValues } from '../index';
import ParticipantsFormCreateUser from '../../../../Participants/components/CreateParticipant/components/ParticipantsFormCreateUser';
import { FormikProps } from 'formik';

type PatientInfoStepProps<T> = {
  formik: FormikProps<T>;
  personalityId: string;
  setValues: (data: CaseFormType) => void;
};

const PatientInfoStep = <T,>({
  formik,
  personalityId,
  setValues,
}: PatientInfoStepProps<T>) => {
  const { data, isLoading } = usePersonalityDetails({
    params: { personalityId },
  });

  useEffect(() => {
    if (!isLoading && data) {
      setValues({
        ...initialValues,
        first_name: data.first_name,
        last_name: data.last_name,
        birthdate: data.birthdate
          ? formatDateToInputDateString(new Date(data.birthdate))
          : '',
        gender: data.gender || '',
        address: data.address || '',
        ssn: data.ssn || '',
        email: data.email,
      });
    }
  }, [data, isLoading, setValues]);

  return (
    <ParticipantsFormCreateUser
      formik={formik}
      readOnlyInputs={Object.values(PatientFormKeys)}
    />
  );
};

export default PatientInfoStep;
