import React from 'react';
import { useCaseGroupDetailsCommonData } from '../../hooks/useCaseGroupDetailsCommonData';
import SessionItemsLoading from './components/CaseGroupDetailsSessionsLoading';
import CreateCaseGroupSessionItem from './components/CreateCaseGroupDetailsSessionItem';
import CaseGroupDetailsSessionsItem from './components/CaseGroupDetailsSessionsItem';

const CaseGroupDetailsSessions = () => {
  const { caseGroup, organization } = useCaseGroupDetailsCommonData();

  if (caseGroup.data === undefined) return <></>;

  if (caseGroup.isLoading || organization.isLoading)
    return <SessionItemsLoading />;

  return (
    <div className="ManageGroupCases CaseCard__tab-container pt-5 px-3">
      <div className="d-flex justify-content-end">
        <CreateCaseGroupSessionItem />
      </div>
      <div className="ManageGroupCases__sessions-list">
        {caseGroup.data.sessions.map((session) => (
          <CaseGroupDetailsSessionsItem key={session.id} session={session} />
        ))}
      </div>
    </div>
  );
};

export default CaseGroupDetailsSessions;
