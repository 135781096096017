import React from 'react';
import TabContainer from '../../components/Tabs/components/TabContainer';
import PageHeader from '../../components/PageHeader';
import QueryLoaded from '../../components/QueryLoaded';
import { useCommonData } from './hooks/useCommonData';
import { useInitQuery } from '../../hooks/queries/useInitQuery';
import { getBreadcrumbs } from './helpers/getBreadcrumbs';
import { useTabs } from './hooks/useTabs';

export type ConversationsAndFeedbacksTabProps = {
  organizationId: string;
};

const ConversationsAndFeedbacks = () => {
  const { organizationDetails } = useCommonData();
  useInitQuery(organizationDetails);

  const { onTabChange, tabItems } = useTabs();

  return (
    <QueryLoaded query={organizationDetails}>
      {(organization) => (
        <PageHeader
          breadcrumbs={getBreadcrumbs(organization)}
          content={
            <div className="mt-4">
              <TabContainer items={tabItems} onTabChange={onTabChange} />
            </div>
          }
        />
      )}
    </QueryLoaded>
  );
};

export default ConversationsAndFeedbacks;
