import { IResponse } from 'interfaces/response';
import { TrendVisualizationTemplate } from 'interfaces/trendVisualizations';
import request from './utils/request';
import {
  getQueryParams,
  getQueryParamsType,
} from '../helpers/utils/getQueryParams';

export const getTimelineRelatedTrendVisualizationTemplates = (
  userId: string,
  timelineId: string,
  dateFrom: string | null,
  dateTo: string | null
) => {
  const queryParams: Partial<getQueryParamsType> = {};

  queryParams.currentPage = '1';

  if (dateFrom) queryParams.from = dateFrom;

  if (dateTo) queryParams.to = dateTo;

  return request<undefined, IResponse<TrendVisualizationTemplate>>({
    url: `users/${userId}/timelines/${timelineId}/trend_visualization_templates${getQueryParams(
      queryParams
    )}`,
    method: 'GET',
  });
};

export const getCaseRelatedTrendVisualizationTemplates = (
  organizationId: string,
  caseId: string
) => {
  return request<undefined, IResponse<TrendVisualizationTemplate>>({
    url: `organizations/${organizationId}/cases/${caseId}/trend_visualization_templates`,
    method: 'GET',
  });
};

export const getUserRelatedTrendVisualizationTemplates = (
  userId: string,
  caseId: string
) => {
  return request<undefined, IResponse<TrendVisualizationTemplate>>({
    url: `users/${userId}/cases/${caseId}/trend_visualization_templates`,
    method: 'GET',
  });
};

export const addTrendVisualizationTemplate = (
  organizationId: string,
  data: TrendVisualizationTemplate
) =>
  request<TrendVisualizationTemplate, IResponse<TrendVisualizationTemplate>>({
    url: `/organizations/${organizationId}/trend_visualizations_templates`,
    method: 'PUT',
    data,
  });

export const getTrendVisualizationTemplates = (organizationId: string) => {
  return request<void, IResponse<TrendVisualizationTemplate[]>>({
    url: `/organizations/${organizationId}/trend_visualizations_templates`,
    method: 'GET',
  });
};

export const deleteTrendVisualizationTemplates = (
  organizationId: string,
  templateId: string
) => {
  return request<void, void>({
    url: `/organizations/${organizationId}/trend_visualizations_templates/${templateId}`,
    method: 'DELETE',
  });
};
