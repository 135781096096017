import React from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { useApplicableInput } from 'components/ApplicableInput/hooks/useApplicableInput';
import ApplicableInput from 'components/ApplicableInput';
import ControlledApplicableInput from 'components/ApplicableInput/components/ControlledApplicableInput';
import { useHandleAccept } from './hooks/useHandleAccept';

type DeviceNameFieldProps = {
  params: GridRenderCellParams<any, string>;
};

const DeviceNameField = ({ params }: DeviceNameFieldProps) => {
  const handleAccept = useHandleAccept(params.row['grasp_id'] as string);
  const control = useApplicableInput(params.value, handleAccept);

  return (
    <div className="d-flex align-items-center gap-2">
      <ApplicableInput control={control}>
        {(props) => <ControlledApplicableInput {...props} />}
      </ApplicableInput>
    </div>
  );
};

export default DeviceNameField;
