import AddIcon from '@mui/icons-material/Add';
import { Box, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import IconButton from 'components/IconButton';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useTypedContext } from '../../../../../../hooks/useTypedContext';
import { TreatmentPlanDataContext } from '../../types';
import { useAddTreatmentPhase } from './hooks/useAddTreatmentPhase';
import { useManagedTreatmentPhases } from './hooks/useManagedTreatmentPhases';
import { useTreatmentPlansTabs } from './hooks/useTreatmentPlansTabs';
import TreatmentPhaseLayout from './layouts/TreatmentPhaseLayout';

const TreatmentPhases = () => {
  const { treatmentPhases } = useTypedContext(TreatmentPlanDataContext);

  const {
    managedTreatmentPhases,
    managedTreatmentPhasesInit,
    setManagedTreatmentPhases,
  } = useManagedTreatmentPhases(treatmentPhases);

  const { items, onTabChange, setSelectedTab } = useTreatmentPlansTabs(
    managedTreatmentPhases,
    managedTreatmentPhasesInit
  );

  const handleAdd = useAddTreatmentPhase(
    managedTreatmentPhases,
    setManagedTreatmentPhases,
    setSelectedTab
  );

  return (
    <Paper
      className="d-flex flex-column gap-5"
      sx={{ padding: '2rem', marginTop: '2rem' }}
    >
      <Box className="d-flex align-items-center justify-content-between">
        <Typography variant="h5">
          <FormattedMessage id="Treatment.TreatmentPhases" />
        </Typography>
        <Box>
          <IconButton color="primary" onClick={handleAdd} variant="outlined">
            <AddIcon />
          </IconButton>
        </Box>
      </Box>
      <Box>
        <TreatmentPhaseLayout
          phases={managedTreatmentPhases}
          items={items}
          onTabChange={onTabChange}
        />
      </Box>
    </Paper>
  );
};

export default TreatmentPhases;
