interface IConversationSchedule {
  id: string;
  origin_id: string | null;
  recurring_origin_id: string | null;
  template_id: string;
  priority: number;
  type: ConversationScheduleType;
}

interface INullableConversationScheduleHours {
  notification_hour: string | null;
  available_from_hour: string | null;
  available_to_hour: string | null;
}

export interface IConversationScheduleHours {
  notification_hour: string;
  available_from_hour: string;
  available_to_hour: string;
}

export interface ICaseConversationSchedule
  extends IConversationSchedule,
    INullableConversationScheduleHours {
  case_id: string;
  date: string | null;
  date_from: string | null;
  date_to: string | null;
  utc_date_time: string | null;
  recurring_interval: number | null;
  is_completed: boolean;
  is_dirty: boolean;
  is_deleted: boolean;
}

interface IBaseCaseConversationSchedule {
  id: string;
  case_id: string;
  template_id: string;
  origin_id: string | null;
  is_completed: boolean;
  is_dirty: boolean;
  is_deleted: boolean;
  priority: number;
}

export interface ISingleInstanceCaseConversationSchedule
  extends IBaseCaseConversationSchedule,
    IConversationScheduleHours {
  date: string;
  recurring_origin_id: string | null;
  utc_date_time: string;
}

export interface IRecurringCaseConversationSchedule
  extends IBaseCaseConversationSchedule,
    IConversationScheduleHours {
  date_from: string;
  date_to: string;
  recurring_interval: number;
}

export interface IUpdateCaseConversationSchedule
  extends INullableConversationScheduleHours {
  date: string | null;
  date_from: string | null;
  date_to: string | null;
  priority: number;
  is_completed: boolean;
  recurring_interval: number | null;
}

export interface ICreateCaseConversationSchedule
  extends IUpdateCaseConversationSchedule {
  template_id: string;
  recurring_origin_id: string | null;
  is_dirty: boolean;
  type: ConversationScheduleType;
}

export interface ICaseGroupConversationSchedule
  extends IConversationSchedule,
    INullableConversationScheduleHours {
  phase_id: string;
  case_group_id: string;
  date: string | null;
  date_from: string | null;
  date_to: string | null;
  recurring_interval: number | null;
  is_dirty: boolean | null;
  is_deleted: boolean | null;
  assigned_schedules?: number;
  completed_schedules?: number;
  completion_percent?: number;
}

interface IBaseCaseGroupConversationSchedule {
  id: string;
  case_group_id: string;
  phase_id: string;
  template_id: string;
  priority: number;
  assigned_schedules?: number;
  completed_schedules?: number;
  completion_percent?: number;
}

export interface ISingleInstanceCaseGroupConversationSchedule
  extends IBaseCaseGroupConversationSchedule,
    IConversationScheduleHours {
  date: string;
  recurring_origin_id: string | null;
  is_dirty: boolean;
  is_deleted: boolean;
}

export interface IRecurringCaseGroupConversationSchedule
  extends IBaseCaseGroupConversationSchedule,
    IConversationScheduleHours {
  date_from: string;
  date_to: string;
  recurring_interval: number;
}

export enum ConversationScheduleType {
  Once = 'Once',
  SingleInstance = 'SingleInstance',
  Recurring = 'Recurring',
  NonTimeBased = 'NonTimeBased',
}

export interface IUpdateCaseGroupConversationSchedule
  extends INullableConversationScheduleHours {
  date: string | null;
  date_from: string | null;
  date_to: string | null;
  priority: number;
  recurring_interval: number | null;
}

export interface ICreateCaseGroupConversationSchedule
  extends IUpdateCaseGroupConversationSchedule {
  phase_id: string;
  template_id: string;
  recurring_origin_id: string | null;
  is_dirty: boolean;
  type: ConversationScheduleType;
}

export enum UpdateRangeMode {
  SingleEvent = 'SingleEvent',
  AllEvents = 'AllEvents',
}

export interface IUpdateModeInitialValues {
  update_mode: UpdateRangeMode | null;
}

export interface ISchedulesEditTimeFormInitialValues
  extends IUpdateModeInitialValues {
  template_id: string;
  priority: number;
  specific_day_of_phase: number | null;
  available_from_hour: string | null;
  available_to_hour: string | null;
  notification_hour: string | null;
  start_day_offset: number | null;
  end_day_offset: number | null;
  recurring_interval: number | null;
}

export interface ICaseSchedulesEditTimeFormInitialValues
  extends ISchedulesEditTimeFormInitialValues {
  is_completed: boolean;
}

export interface ICaseGroupConversationScheduleResponses {
  schedule: ICaseGroupConversationSchedule;
  responses: ICaseGroupConversationScheduleResponse[];
}

export interface ICaseGroupConversationScheduleResponse {
  case_id: string;
  participant_first_name: string;
  participant_last_name: string;
  case_last_feedback: string | null;
  is_completed: boolean;
}
