import {
  ConversationScheduleType,
  ICaseGroupConversationSchedule,
  ISingleInstanceCaseGroupConversationSchedule,
} from '../../../../../../../../../interfaces/conversationSchedules';
import { ScheduleItemDefinition } from '../../../../../../../../../components/Schedule';
import { ICaseGroupSchedule } from '../../../../../../../../../interfaces/caseGroups';
import { useCallback } from 'react';
import { strToDateOnly } from '../../../../../../../../../helpers/dates';
import { usePrepareSchedulesPayload } from './usePrepareSchedulesPayload';
import { usePrepareSchedulesUtils } from './usePrepareSchedulesUtils';
import {
  getRecurringForSingleInstance,
  mergeSingleInstanceWithRow,
} from '../../../../../../../../../helpers/utils/conversationSchedules';

export const usePrepareSchedules = (
  setSchedule: (schedule: ICaseGroupConversationSchedule | null) => void,
  setResponsesId: (responsesId: string | null) => void,
  toggleEditModal: () => void,
  toggleResponses: () => void
) => {
  const {
    prepareOnce,
    prepareSimpleSingleInstance,
    prepareRecurredSingleInstance,
  } = usePrepareSchedulesPayload(
    setSchedule,
    setResponsesId,
    toggleEditModal,
    toggleResponses
  );

  const { getSingleInstancePartOfADay, getRowToMergeWith } =
    usePrepareSchedulesUtils(
      setSchedule,
      setResponsesId,
      toggleEditModal,
      toggleResponses
    );

  const prepareOnceSchedule = useCallback(
    (
      items: ScheduleItemDefinition[],
      currentSchedule: ICaseGroupConversationSchedule,
      selectedDate: Date,
      index: number
    ) => {
      if (
        currentSchedule.type !== ConversationScheduleType.Once &&
        currentSchedule.type !== ConversationScheduleType.NonTimeBased
      )
        return items;

      items.push(prepareOnce(currentSchedule, selectedDate, index));
      return items;
    },
    [prepareOnce]
  );

  const prepareSingleInstanceSchedule = useCallback(
    (
      items: ScheduleItemDefinition[],
      currentSchedule: ICaseGroupConversationSchedule,
      allSchedules: ICaseGroupConversationSchedule[],
      phaseGroupSchedule: ICaseGroupSchedule,
      selectedDate: Date,
      index: number
    ) => {
      if (currentSchedule.type !== ConversationScheduleType.SingleInstance)
        return items;

      const singleInstanceSchedule =
        currentSchedule as ISingleInstanceCaseGroupConversationSchedule;

      const date = strToDateOnly(singleInstanceSchedule.date);
      const selected = strToDateOnly(selectedDate.toISOString());

      if (date !== selected || singleInstanceSchedule.is_deleted) return items;

      if (singleInstanceSchedule.recurring_origin_id) {
        const recurringSchedule = getRecurringForSingleInstance(
          allSchedules,
          currentSchedule
        ) as ICaseGroupConversationSchedule;

        const singleInstancePartOfADay = getSingleInstancePartOfADay(
          currentSchedule,
          recurringSchedule,
          selectedDate
        );

        const rowToMergeWith = getRowToMergeWith(
          items,
          currentSchedule,
          recurringSchedule,
          selectedDate
        );

        if (rowToMergeWith !== null) {
          items = mergeSingleInstanceWithRow(
            items,
            rowToMergeWith.sequence,
            singleInstancePartOfADay,
            false
          );

          return items;
        }

        items.push(
          prepareRecurredSingleInstance(
            currentSchedule,
            recurringSchedule,
            phaseGroupSchedule,
            selectedDate,
            index
          )
        );

        return items;
      }

      items.push(
        prepareSimpleSingleInstance(
          currentSchedule,
          phaseGroupSchedule,
          selectedDate,
          index
        )
      );

      return items;
    },
    [
      getRowToMergeWith,
      getSingleInstancePartOfADay,
      prepareRecurredSingleInstance,
      prepareSimpleSingleInstance,
    ]
  );

  return {
    prepareOnceSchedule: prepareOnceSchedule,
    prepareSingleInstanceSchedule: prepareSingleInstanceSchedule,
  };
};
