import { AccountDeletionReason } from '../modules/membership/MyAccount/components/AccountDeletionForm';
import { SimpleAccessProfile } from './accessProfiles';
import { CaseTrackingType } from './cases';
import {
  IBaseOrganization,
  IOrganization,
  OrganizationType,
} from './organizations';
import { ICreatePersonality, IPersonality } from './personalities';
import { ITimezone } from 'react-timezone-select';

export interface IRegisterBody {
  first_name?: string;
  last_name?: string;
  email?: string;
  culture?: string;
  password: string;
  token: string | null;
  invitation_request_id?: string;
  personality?: ICreatePersonality;
  project?: string;
}

export interface IInvitationForm {
  first_name: string;
  last_name: string;
  email: string;
  address: string;
  gender: string;
  birthdate: string;
  ssn: string;
  culture: string;
  password: string;
  passwordRepeat: string;
  phone_number: string;
}

export interface IRegisterForm extends IInvitationForm {
  user_name: string;
  token: string | null;
  timezone_id: ITimezone;
  project?: IRegisterUserInProject;
  external_login_provider: string | null;
}

export interface IRegisterUserInProject {
  organization_code: string;
  project_code: string;
}

export const isOrganizationWithRole = (org: any): org is IOrganization =>
  (org as IOrganization).role !== undefined;

export interface ILoginResponse {
  organizations: IOrganization[];
  token: string;
  valid_until: string;
  user: IPersonality;
}

export enum LoginMethod {
  AsPatient = 'AsPatient',
  AsMedicalPersonnel = 'AsMedicalPersonnel',
}

export interface IChangePasswordBody {
  old_password: string;
  new_password: string;
}

export interface IOneTimePasswordResponse {
  token: string;
  valid_to: string;
}

export interface IPersonalityDetails {
  id: string;
  culture: string;
  created_at: string;
  updated_at: string;
  user_details_provided: boolean;
  email_confirmed: boolean;
  phone_number_confirmed: boolean;
  two_factor_enabled: boolean;
  lockout_end: string;
  lockout_enabled: boolean;
  access_failed_count: number;
  personality: IPersonality;
  organizations: IBaseOrganization[];
  default_organization_id: string;
}

export enum UserDataDumpStatusType {
  Expired = 'Expired',
  Active = 'Active',
  Pending = 'Pending',
  NotRequested = 'NotRequested',
}

export interface IUserDataDumpStatusFileInfo {
  fileName: string;
  requestedAt: string;
  expiringAt: string;
}

export interface IUserDeletionBody {
  deletion_reasons: AccountDeletionReason[];
  feedback: string | null;
  newsletter_sign_out: boolean;
}

export interface IUserDataDumpStatus {
  status: UserDataDumpStatusType;
  fileInfo: IUserDataDumpStatusFileInfo | null;
}

export interface ICreateOrganizationBody {
  name: string;
  code: string;
  logo_url: string;
  image_url: string;
  type: string;
  main_organization_id?: string | null;
  description?: string;
  author_id?: string;
  allow_self_grasp_provisioning: boolean;
}

export interface IUserOrganizationResponse {
  user: {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
    owner_organization_id?: string;
  };
  access_profiles: SimpleAccessProfile[];
  organization: IBaseOrganization;
  roles: string[];
}

export interface IOrganizationDetails extends IBaseOrganization {
  total_endpoints_count: number;
  main_organization_id: string | null;
  author: {
    id: string;
    first_name: string;
    last_name: string;
  };
  type: OrganizationType;
  settings: IOrganizationSettings | null;
  active_directory_tenant_id: string | null;
}

export interface IOrganizationSettings {
  charts_color_palette: string[];
}

export interface IOrganizationTrackingTypes {
  organization_id: string;
  tracking_types: CaseTrackingType[];
}

export type InvitationBody = {
  email: string;
  access_profile: string;
  culture: string;
};

export type LocationItem = {
  id: string;
  url: string;
  name: string;
  description: string;
  organization_id: string;
  grasps_count: number;
};

export type EditedEndpointItem = {
  id: string;
  name: string;
  description: string;
};

export type ChangeEmailBody = {
  new_email: string;
  password: string;
};
export interface ITokenData {
  'AspNet.Identity.SecurityStamp': string;
  'Grasp.Identity.ApplicationType': string;
  'Grasp.Identity.UserCulture': string;
  'Grasp.Identity.PersonalityId'?: string;
  aud: string;
  exp: number;
  'http://schemas.microsoft.com/ws/2008/06/identity/claims/role': UserRoles[];
  'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier': string;
  iss: string;
  jti: string;
  nbf: number;
  unique_name: string;
  'Grasp.Identity.CurrentOrganization'?: string;
  'Grasp.Identity.Organization'?: string;
}

export enum UserRoles {
  GLOBAL_ADMIN = 'GlobalAdmin',
  GRASP_ADMIN = 'GraspAdmin',
  GRASP_MANAGER = 'GraspManager',
  CASE_ADMIN = 'CaseAdmin',
  CASE_MANAGER = 'CaseManager',
  PARTICIPANT_ADMIN = 'ParticipantAdmin',
  REGULAR_USER = 'RegularUser',
  ORGANIZATION_ADMIN = 'OrganizationAdmin',
}

export enum UserRolesInToken {
  ORGANIZATION_ADMIN = 'organization_admin',
}

export enum TokenTypes {
  PERSONAL = 'personal',
  ORGANIZATION = 'organization',
}

export type AuthenticationScheme = {
  display_name: string;
  name: string;
  is_b2_b: boolean;
};

export type UserExternalLoginItem = {
  login_provider: string;
  provider_key: string;
  provider_display_name: string;
};

export interface IUserBasicInfo {
  id: string;
  first_name: string;
  last_name: string;
  email: string;
  culture: string;
  timezone: string;
  created_at: Date;
}
