import { MIN_SEARCH_LENGTH } from 'helpers/constants';
import { ActivityTypes } from 'interfaces/activities';
import { useMemo, useState } from 'react';
import { DateRange } from 'react-day-picker';
import { useDebounce } from 'use-debounce';
import EventLogFilters from './EventLogFilters';
import EventLogTable from './EventLogTable';

export type SharedEventTypeProps = {
  search: string;
  selectedEventTypes: ActivityTypes[];
  dateRange: DateRange;
  resourceId: string;
};

type EventLogScreenProps = {
  availableEventTypes: ActivityTypes[];
  resourceId?: string;
};

const EventLogScreen = ({
  availableEventTypes,
  resourceId,
}: EventLogScreenProps) => {
  const [resourceSearch, setResourceSearch] = useState('');
  const [selectedEventTypes, setSelectedEventTypes] =
    useState<ActivityTypes[]>(availableEventTypes);

  const resetEventTypes = () => {
    setSelectedEventTypes(availableEventTypes);
  };

  const [selectedDates, setSelectedDates] = useState<DateRange>({
    from: undefined,
    to: undefined,
  });

  const [value] = useDebounce(resourceSearch, 500, {
    equalityFn: (_, newValue) => {
      if (newValue.length === 0) return false;
      return newValue.length < MIN_SEARCH_LENGTH;
    },
  });

  const renderedLogContent = useMemo(() => {
    const props: SharedEventTypeProps = {
      search: value,
      selectedEventTypes: selectedEventTypes,
      dateRange: selectedDates,
      resourceId: resourceId ?? resourceSearch,
    };

    return <EventLogTable {...props} />;
  }, [value, selectedEventTypes, selectedDates, resourceId, resourceSearch]);

  return (
    <div className="pt-4">
      <EventLogFilters
        search={resourceSearch}
        setSearch={setResourceSearch}
        setSelectedEventTypes={setSelectedEventTypes}
        selectedEventTypes={selectedEventTypes}
        resetEventTypes={resetEventTypes}
        availableEventTypes={availableEventTypes}
        selectedDates={selectedDates}
        setSelectedDates={setSelectedDates}
        displaySearch={!resourceId}
      />
      {renderedLogContent}
    </div>
  );
};

export default EventLogScreen;
