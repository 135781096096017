import useOrganizationContextGroupedAssessmentFormsForDate from 'api/queries/assessmentForms/forms/date/useOrganizationContextGroupedAssessmentFormsForDate';
import useUserContextGroupedAssessmentFormsForDate from 'api/queries/assessmentForms/forms/date/useUserContextGroupedAssessmentFormsForDate';
import { getUserId } from 'helpers/utils/auth';
import useUserPathBasedBrowsingContext from 'hooks/useUserPathBasedBrowsingContext';
import { setFormsDayDetailsViewRefetchRequest } from 'modules/Cases/store/actions';
import EmptyInformation from 'modules/TimelineDetails/EmptyInformation';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Spinner } from 'reactstrap';
import SingleAssessmentForm from './SingleAssessmentForm';
import classNames from 'classnames';
import HeaderSummaryEmptyDataPlaceholder from '../../../../../../HeaderSummaryDashboard/HeaderSummaryEmptyDataPlaceholder';

export enum DisplayingComponent {
  DAY_SCHEDULE = 'DAY_SCHEDULE',
  DAY_BY_DAY = 'DAY_BY_DAY',
  OTHER = 'OTHER',
}

type DayAssessmentProps = {
  selectedDate: Date;
  selectedDates?: {
    from: Date;
    to: Date;
  };
  dashboard?: boolean;
  userId: string;
  timelineId: string;
  refetchRequest: boolean;
  displayingComponent: DisplayingComponent;
};

const AssessmentsForms = ({
  selectedDate,
  userId,
  timelineId,
  refetchRequest,
  dashboard,
  displayingComponent,
}: DayAssessmentProps) => {
  const dispatch = useDispatch();

  const loggedUserId = getUserId();

  const {
    caseId = '',
    organizationId = '',
    isOrganizationContext,
    isRegularUserContext,
  } = useUserPathBasedBrowsingContext();

  const userContextGroupedAssessmentForms =
    useUserContextGroupedAssessmentFormsForDate({
      params: { userId, timelineId, selectedDate },
      options: { enabled: isRegularUserContext },
    });

  const organizationContextGroupedAssessmentForms =
    useOrganizationContextGroupedAssessmentFormsForDate({
      params: { organizationId, caseId, timelineId, selectedDate },
      options: { enabled: isOrganizationContext },
    });

  const assessmentFormsQuery = isRegularUserContext
    ? userContextGroupedAssessmentForms
    : organizationContextGroupedAssessmentForms;

  const isEmptyData = useMemo(() => {
    return (
      assessmentFormsQuery.data === undefined ||
      assessmentFormsQuery.data.length === 0
    );
  }, [assessmentFormsQuery.data]);

  useEffect(() => {
    if (refetchRequest) {
      assessmentFormsQuery.refetch();
      dispatch(setFormsDayDetailsViewRefetchRequest(false));
    }
  }, [refetchRequest, assessmentFormsQuery, dispatch]);

  const renderedForms = useMemo(() => {
    if (assessmentFormsQuery.data === undefined) {
      return [];
    }

    let forms: JSX.Element[] = [];

    let filteredForms = assessmentFormsQuery.data.filter((p) =>
      displayingComponent === DisplayingComponent.DAY_BY_DAY
        ? p.showOnDayByDay
        : displayingComponent === DisplayingComponent.DAY_SCHEDULE
        ? p.showOnDayPlan
        : true
    );

    filteredForms.forEach((data, idx) => {
      const isOwner = data.userId === loggedUserId;

      forms.push(
        <SingleAssessmentForm
          key={idx}
          form={data}
          isOwner={isOwner}
          userId={userId}
        />
      );
    });

    return forms;
  }, [assessmentFormsQuery.data, displayingComponent, loggedUserId, userId]);

  if (assessmentFormsQuery.isLoading) {
    return (
      <div className="w-100 mt-4 d-flex justify-content-center">
        <Spinner color="primary" />
      </div>
    );
  }

  const detailsScrollableClass = classNames(
    `w-100 pt-2 DayDetails__scrollable-group`,
    {
      'w-100 pt-2 DayDetails__scrollable-group-dashboard': dashboard,
    }
  );

  if (isEmptyData) {
    if (displayingComponent === DisplayingComponent.DAY_SCHEDULE)
      return (
        <div className="HeaderSummaryDashboard">
          <HeaderSummaryEmptyDataPlaceholder titleTranslationId="UserDashboard.noActivities" />
        </div>
      );

    return <EmptyInformation type="day" />;
  }

  return <div className={detailsScrollableClass}>{renderedForms}</div>;
};

export default AssessmentsForms;
