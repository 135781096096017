import { TrendVisualizationChartType } from './trendVisualizations';

export enum TemplateItemType {
  AllObservations = 'AllObservations',
  ExerciseObservations = 'ExerciseObservations',
  NonExerciseObservations = 'NonExerciseObservations',
  Questions = 'Questions',
  ExerciseTime = 'ExerciseTime',
}

export type TemplateElement = {
  type: TemplateItemType;
  question_id: string | null;
  generate_points_nearby: boolean;
  chart_type: TrendVisualizationChartType;
};

export type AnswerHistoryQuestion = {
  question_id: string;
  question_title: string;
  question_short_title: string | null;
  question_color: string;
  value_interpretation_type: ValueInterpretationType;
  answer_history: HistoryEntryAnswer[];
};

export type SummarizedAnswerHistory = {
  user_id: string;
  questions: AnswerHistoryQuestion[];
  summaries: AnswerHistorySummary[];
};

export type AnswerHistorySummary = {
  user_id: string;
  year: number;
  month: number;
  day: number;
  questions: AnswerHistorySummaryItem[];
};

export type AnswerHistorySummaryItem = {
  question_id: string;
  answers_average_for_current_day: number;
  answers_average_for_last_3_days: number | null;
  answers_average_for_last_7_days: number | null;
};

export type AnswerHistory = {
  userId: string;
  questions: AnswerHistoryQuestion[];
};

export interface HistoryEntryAnswer {
  date: string;
  title: string | null;
  value?: number;
}

export enum ValueInterpretationType {
  NoInterpretation = 'NoInterpretation',
  TheLowerTheBetter = 'TheLowerTheBetter',
  TheHigherTheBetter = 'TheHigherTheBetter',
}

export type QuestionHistoryMutationBody = {
  dateFrom: Date | null;
  dateTo: Date | null;
  questionIds: string[];
};

export type FeedbackMessageGroup = {
  messages: string[];
  group_name: string;
};
