import { useCallback } from 'react';
import {
  IMemberDashboardFilter,
  IMemberDashboardTemplateFilter,
} from '../../../../../../../interfaces/dashboards';
import { BasePersonality } from '../../../../../../../interfaces/personalities';
import { DashboardSelectionHelpers } from '../helpers/selection';

export const useMemberOptions = (members: BasePersonality[]) => {
  const append = useCallback(
    (dashboardFilter: IMemberDashboardFilter) => {
      members.forEach((member) => {
        dashboardFilter.options.push({
          id: member.id,
          selected: false,
        });
      });

      return dashboardFilter;
    },
    [members]
  );

  return {
    append,
    select: DashboardSelectionHelpers.SelectIncludedExcluded<
      IMemberDashboardFilter,
      IMemberDashboardTemplateFilter
    >,
  };
};
