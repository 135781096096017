import React, { useState } from 'react';
import {
  IBaseCategory,
  ICategory,
} from '../../../../../../../interfaces/cases';
import CaseGroupCollectionItem, {
  CaseGroupCollectionItemAction,
  CaseGroupCollectionStyle,
} from '../../../../../../../components/CaseGroupCollectionItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil, faUndo } from '@fortawesome/pro-solid-svg-icons';
import { faFolderOpen, faSave } from '@fortawesome/pro-light-svg-icons';
import { No, Us } from 'react-flags-select';
import { cloneDeep } from 'lodash';
import EditableInput from '../../../../../../../components/EditableInput/EditableInput';
import usePatchCaseSubCategory from 'api/mutations/caseCategories/usePatchCaseSubCategory';
import { displayErrorNotification } from '../../../../../../Notifications';
import { IPatchObject } from '../../../../../../../interfaces/request';
import useCreateCaseSubCategory from '../../../../../../../api/mutations/caseCategories/useCreateCaseSubCategory';
import { QueryObserverResult } from 'react-query/types/core/types';

type CaseSubCategoryItemProps = {
  organizationId: string;
  category: ICategory;
  subCategory: IBaseCategory;
  modifiedSubCategory: IBaseCategory | null;
  setModifiedSubCategory: (subCategory: IBaseCategory | null) => void;
  disableAddSubCategoryMode: () => void;
  setIdToReopen: (id: string | null) => void;
  refetch: () => Promise<QueryObserverResult<any, any>>;
};

const CaseSubCategoryItem = ({
  organizationId,
  category,
  subCategory,
  setModifiedSubCategory,
  modifiedSubCategory,
  disableAddSubCategoryMode,
  setIdToReopen,
  refetch,
}: CaseSubCategoryItemProps) => {
  const modifiedMode = modifiedSubCategory?.id === subCategory.id;

  const [modifiedCode, setModifiedCode] = useState<string>(
    cloneDeep(subCategory).code
  );
  const [modifiedNameNo, setModifiedNameNo] = useState<string>(
    cloneDeep(subCategory).name_no
  );
  const [modifiedNameEn, setModifiedNameEn] = useState<string>(
    cloneDeep(subCategory).name_en
  );

  const handleRefetch = async () => {
    await refetch();
    setModifiedSubCategory(null);
    disableAddSubCategoryMode();
    setIdToReopen(category.id);
  };

  const { mutateAsync: addSubCategory } = useCreateCaseSubCategory(
    {
      organizationId: organizationId,
    },
    {
      successFb: handleRefetch,
    }
  );

  const { mutateAsync: patchSubCategory } = usePatchCaseSubCategory(
    {
      organizationId: organizationId,
      subCategoryId: subCategory.id,
    },
    {
      successFb: handleRefetch,
    }
  );

  const handleEditSubCategory = () => {
    setModifiedSubCategory(subCategory);
  };

  const handleSaveCategory = async () => {
    if (
      modifiedCode.length === 0 ||
      modifiedNameEn.length === 0 ||
      modifiedNameNo.length === 0
    ) {
      displayErrorNotification('MyOrganizations.caseCategories.emptyFields');
      return;
    }

    if (subCategory.id === '') {
      await addSubCategory({
        name_en: modifiedNameEn,
        name_no: modifiedNameNo,
        code: modifiedCode,
        case_category_id: category.id,
        icon: '',
      });

      return;
    }

    let patchObject: IPatchObject[] = [];

    if (modifiedCode !== subCategory.code) {
      patchObject.push({
        op: 'replace',
        path: '/code',
        value: modifiedCode,
      });
    }

    if (modifiedNameNo !== subCategory.name_no) {
      patchObject.push({
        op: 'replace',
        path: '/name_no',
        value: modifiedNameNo,
      });
    }

    if (modifiedNameEn !== subCategory.name_en) {
      patchObject.push({
        op: 'replace',
        path: '/name_en',
        value: modifiedNameEn,
      });
    }

    if (patchObject.length === 0) return;

    await patchSubCategory(patchObject);
  };

  const handleRevertSubCategory = () => {
    setModifiedSubCategory(null);
    setModifiedCode(cloneDeep(subCategory).code);
    setModifiedNameNo(cloneDeep(subCategory).name_no);
    setModifiedNameEn(cloneDeep(subCategory).name_en);

    if (subCategory.id === '') disableAddSubCategoryMode();
  };

  let subCategoryActions: CaseGroupCollectionItemAction[] = [
    {
      key: `${subCategory.code}|edit`,
      onClick: handleEditSubCategory,
      icon: <FontAwesomeIcon icon={faPencil} />,
    },
  ];

  if (modifiedSubCategory?.id === subCategory.id) {
    subCategoryActions.push({
      key: `${subCategory.code}|revert`,
      onClick: handleRevertSubCategory,
      icon: <FontAwesomeIcon icon={faUndo} />,
    });

    subCategoryActions.push({
      key: `${subCategory.code}|save`,
      onClick: handleSaveCategory,
      icon: <FontAwesomeIcon icon={faSave} />,
    });

    subCategoryActions = subCategoryActions.filter(
      (p) => p.key !== `${subCategory.code}|edit`
    );
  }

  const handleModifyNameEn = (target: string, value: string) => {
    setModifiedNameEn(value);
  };

  const handleModifyNameNo = (target: string, value: string) => {
    setModifiedNameNo(value);
  };

  const handleModifyCode = (target: string, value: string) => {
    setModifiedCode(value);
  };

  return (
    <CaseGroupCollectionItem
      style={
        modifiedSubCategory?.id === subCategory.id
          ? CaseGroupCollectionStyle.PrimaryOutline
          : CaseGroupCollectionStyle.Default
      }
      icon={<FontAwesomeIcon icon={faFolderOpen} />}
      title={
        modifiedMode ? (
          <EditableInput
            className="mb-1"
            value={modifiedCode}
            onEdit={handleModifyCode}
          />
        ) : (
          <>{modifiedCode}</>
        )
      }
      infoMonochrome={false}
      info={
        <div className="CaseCategoriesListItem">
          <div className="d-flex align-items-center gap-1">
            <Us width={30} />
            {modifiedMode ? (
              <EditableInput
                className="mb-1"
                value={modifiedNameEn}
                onEdit={handleModifyNameEn}
              />
            ) : (
              <span>{modifiedNameEn}</span>
            )}
          </div>
          <div className="d-flex align-items-center gap-1">
            <No width={30} />
            {modifiedMode ? (
              <EditableInput
                width="100%"
                className="mb-1"
                value={modifiedNameNo}
                onEdit={handleModifyNameNo}
              />
            ) : (
              <span>{modifiedNameNo}</span>
            )}
          </div>
        </div>
      }
      actions={subCategoryActions}
    />
  );
};

export default CaseSubCategoryItem;
