import { useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Spinner } from 'reactstrap';
import ChangeLanguageSelector from 'components/CultureSelector/CultureSelector';
import ProjectSubmissionLayout from 'components/layouts/ProjectSubmissionLayout';
import RegisterInProjectForm from './components/RegisterInProjectForm';
import useProjectByCode from 'api/queries/projects/useProjectByCode';
import useRequiredParams from 'hooks/useRequiredParams';
import { RegisterFormKeys } from 'interfaces/enums';
import {
  AllowToChooseGroup,
  FieldDefinition,
  ProjectFieldsDefinitions,
} from 'interfaces/submissions';
import ProjectAssessmentQuestions from './components/QualificationQuestions';
import RegistrationSuccess from './components/RegistrationSuccess';
import QualificationResult from './components/QualificationResult';
import useProjectLanguage from 'hooks/useProjectLanguage';
import CaseGroupSelection from './components/CaseGroupSelection';
import useMobile from '../../../../hooks/useBreakpoint';

const mappedFieldDefinitionToFieldKeys = {
  address: RegisterFormKeys.ADDRESS,
  birth_date: RegisterFormKeys.BIRTH_DATE,
  email: RegisterFormKeys.EMAIL,
  first_name: RegisterFormKeys.FIRST_NAME,
  timezone_id: RegisterFormKeys.TIMEZONE_ID,
  gender: RegisterFormKeys.GENDER,
  last_name: RegisterFormKeys.LAST_NAME,
  password: RegisterFormKeys.PASSWORD,
  phone_number: RegisterFormKeys.PHONE_NUMBER,
  ssn: RegisterFormKeys.SSN,
};

enum ProjectRegistrationSteps {
  QUALIFICATION,
  QUALIFICATION_SUCCESS,
  QUALIFICATION_ERROR,
  REGISTRATION,
  CASE_GROUP_SELECTION,
  REGISTRATION_SUCCESS,
}

export const returnFieldsDefinitions = (
  fieldDefinitions?: ProjectFieldsDefinitions
) => {
  const hiddenFields: RegisterFormKeys[] = [];
  const mandatoryFields: RegisterFormKeys[] = [];
  const optionalFields: RegisterFormKeys[] = [];

  if (fieldDefinitions) {
    const fieldDefinitionKeys = Object.keys(fieldDefinitions);

    fieldDefinitionKeys.forEach((definitionKey) => {
      if (fieldDefinitions[definitionKey] === FieldDefinition.Mandatory) {
        mandatoryFields.push(mappedFieldDefinitionToFieldKeys[definitionKey]);
      }

      if (fieldDefinitions[definitionKey] === FieldDefinition.Optional) {
        optionalFields.push(mappedFieldDefinitionToFieldKeys[definitionKey]);
      }
    });
  }

  return {
    hiddenFields,
    mandatoryFields,
    optionalFields,
  };
};

const ProjectSubmissionRegister = () => {
  const { organizationCode, projectCode } = useRequiredParams<{
    projectCode: string;
    organizationCode: string;
  }>(['organizationCode', 'projectCode']);

  const { selectedCulture, setSelectedCulture } = useProjectLanguage(
    organizationCode,
    projectCode
  );

  const isMobile = useMobile(992);

  const { data, isFetched, isLoading } = useProjectByCode({
    params: { organizationCode, projectCode },
  });

  const [submissionId, setSubmissionId] = useState('');

  const [isAlertOnClose, setIsAlertOnClose] = useState(false);

  const [registrationStep, setRegistrationStep] =
    useState<ProjectRegistrationSteps>(ProjectRegistrationSteps.QUALIFICATION);

  const fieldsDefinition = returnFieldsDefinitions(data?.fields_definition);

  useEffect(() => {
    if (data && !data?.template_code)
      setRegistrationStep(ProjectRegistrationSteps.REGISTRATION);
  }, [data, data?.template_code]);

  const goToQualificationSuccess = (submissionId: string) => {
    setSubmissionId(submissionId);
    setRegistrationStep(ProjectRegistrationSteps.QUALIFICATION_SUCCESS);
  };

  const goToQualificationError = () =>
    setRegistrationStep(ProjectRegistrationSteps.QUALIFICATION_ERROR);

  const goToRegistration = () => {
    setIsAlertOnClose(true);
    setRegistrationStep(ProjectRegistrationSteps.REGISTRATION);
  };

  const onRegistrationSuccess = useCallback(() => {
    if (!data) {
      setRegistrationStep(ProjectRegistrationSteps.CASE_GROUP_SELECTION);
      return;
    }

    if (data?.allow_to_choose_group === AllowToChooseGroup.No) {
      setRegistrationStep(ProjectRegistrationSteps.REGISTRATION_SUCCESS);
      return;
    }

    setRegistrationStep(ProjectRegistrationSteps.CASE_GROUP_SELECTION);
  }, [data]);

  const onCaseGroupSelectionSuccess = () =>
    setRegistrationStep(ProjectRegistrationSteps.REGISTRATION_SUCCESS);

  const renderRegisterContent = useMemo(() => {
    if (isLoading) return <Spinner />;
    if (!data) return null;

    switch (registrationStep) {
      case ProjectRegistrationSteps.QUALIFICATION:
        if (!data?.template_code) return null;

        return (
          <div className="w-100">
            <ProjectAssessmentQuestions
              code={data?.template_code}
              selectedCulture={selectedCulture}
              organizationCode={organizationCode}
              onSuccess={goToQualificationSuccess}
              onReject={goToQualificationError}
            />
          </div>
        );
      case ProjectRegistrationSteps.QUALIFICATION_SUCCESS:
        return (
          <QualificationResult
            isQualified={true}
            description={data.successful_submission_message}
            goToRegistration={goToRegistration}
            selectedCulture={selectedCulture}
          />
        );
      case ProjectRegistrationSteps.QUALIFICATION_ERROR:
        return (
          <QualificationResult
            isQualified={false}
            description={data.unsuccessful_submission_message}
            selectedCulture={selectedCulture}
          />
        );
      case ProjectRegistrationSteps.REGISTRATION:
        return (
          <RegisterInProjectForm
            organizationCode={organizationCode}
            onRegistrationSuccess={onRegistrationSuccess}
            hiddenFields={fieldsDefinition.hiddenFields}
            mandatoryFields={fieldsDefinition.mandatoryFields}
            optionalFields={fieldsDefinition.optionalFields}
            participationConsent={data?.participation_consent}
            privacyPolicy={data?.privacy_policy}
            termsOfService={data?.terms_of_service}
            selectedCulture={selectedCulture}
            isAlertOnClose={isAlertOnClose}
            submissionId={submissionId}
          />
        );
      case ProjectRegistrationSteps.CASE_GROUP_SELECTION:
        return (
          <CaseGroupSelection
            organizationCode={organizationCode}
            submissionId={submissionId}
            project={data}
            onSelectionSuccess={onCaseGroupSelectionSuccess}
          />
        );
      case ProjectRegistrationSteps.REGISTRATION_SUCCESS:
        return (
          <RegistrationSuccess
            title={data?.title}
            description={data.post_register_message}
            selectedCulture={selectedCulture}
          />
        );
    }
  }, [
    isLoading,
    data,
    registrationStep,
    organizationCode,
    selectedCulture,
    onRegistrationSuccess,
    fieldsDefinition.hiddenFields,
    fieldsDefinition.mandatoryFields,
    fieldsDefinition.optionalFields,
    isAlertOnClose,
    submissionId,
  ]);

  return (
    <ProjectSubmissionLayout
      isFetched={isFetched}
      projectId={projectCode}
      data={data}
      selectedCulture={selectedCulture}
      setSelectedCulture={setSelectedCulture}
    >
      <div className="newMembersSection newMembersSection--bg-white newMembersSection--smallPadding newMembersSection--smallPadding--project w-100 mb-4">
        <Col
          xs={12}
          sm={10}
          lg={9}
          xl={9}
          className="d-flex h-100 justify-content-center align-items-center position-relative"
        >
          <div className="flex flex-col gap-4">
            {!isMobile && (
              <div className="flex justify-end">
                <ChangeLanguageSelector
                  selectedCulture={selectedCulture}
                  setSelectedCulture={setSelectedCulture}
                />
              </div>
            )}
            {renderRegisterContent}
          </div>
        </Col>
      </div>
    </ProjectSubmissionLayout>
  );
};

export default ProjectSubmissionRegister;
