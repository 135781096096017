import useCreateGraspForOrganization from 'api/mutations/grasps/useCreateGraspForOrganization';
import FieldInput from 'components/FieldInput';
import FieldSelect from 'components/FieldSelect';
import LoadingButton from 'components/LoadingButton';
import { Field, Form, Formik } from 'formik';
import { graspColorsValues } from 'helpers/colors';
import { colorOptions } from 'helpers/utils/translationObject';
import { validateEmpty } from 'helpers/utils/validators';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

const initialValues = {
  physical_grasp_uuid: '',
  hex_color: graspColorsValues.blue,
  serial_number: '',
};

type CreateGraspModalProps = {
  isOpen: boolean;
  toggleModal: () => void;
  organizationId: string;
  refetchGrasps: () => void;
};

const ManualCreateGraspModal = ({
  isOpen,
  toggleModal,
  organizationId,
  refetchGrasps,
}: CreateGraspModalProps) => {
  const intl = useIntl();

  const onCreateSuccess = () => refetchGrasps();

  const { mutate: createGraspMutation, isLoading } =
    useCreateGraspForOrganization(organizationId, {
      successFb: onCreateSuccess,
    });

  const validation = (values: typeof initialValues) =>
    validateEmpty<typeof initialValues>(values, intl);

  const onSubmit = (values: typeof initialValues) => {
    createGraspMutation(values);
    toggleModal();
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          <FormattedMessage id="MyOrganizations.grasps.modal.headerCreate" />
        </ModalHeader>
        <Formik
          onSubmit={onSubmit}
          initialValues={initialValues}
          validate={validation}
        >
          {({ isValid, submitForm }) => (
            <>
              <ModalBody>
                <Form>
                  <FormGroup>
                    <Label htmlFor="physical_grasp_uuid">
                      <FormattedMessage id="MyOrganizations.grasps.modal.physicalGraspUuid" />
                    </Label>
                    <Field
                      required
                      id="physical_grasp_uuid"
                      name="physical_grasp_uuid"
                      type="text"
                      component={FieldInput}
                    />
                  </FormGroup>
                </Form>
                <Form>
                  <FormGroup>
                    <Label htmlFor="hex_color">
                      <FormattedMessage id="MyOrganizations.grasps.modal.color" />
                    </Label>
                    <Field
                      required
                      id="hex_color"
                      name="hex_color"
                      component={FieldSelect}
                      options={colorOptions}
                    />
                  </FormGroup>
                </Form>
                <Form>
                  <FormGroup>
                    <Label htmlFor="serial_number">
                      <FormattedMessage id="MyOrganizations.grasps.modal.serialNumber" />
                    </Label>
                    <Field
                      required
                      id="serial_number"
                      name="serial_number"
                      type="text"
                      maxLength={20}
                      component={FieldInput}
                    />
                  </FormGroup>
                </Form>
              </ModalBody>
              <ModalFooter>
                <LoadingButton
                  disabled={!isValid}
                  type="submit"
                  onClick={submitForm}
                  isLoading={isLoading}
                >
                  <FormattedMessage id="General.confirm" />
                </LoadingButton>
              </ModalFooter>
            </>
          )}
        </Formik>
      </Modal>
    </>
  );
};

export default ManualCreateGraspModal;
