import { createTheme, responsiveFontSizes, ThemeOptions } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { ViewTheme } from 'interfaces/ui';
import Cookies from 'js-cookie';
import { ThemesOptions } from './options';
import { ThemeTypes } from './types';
import { ThemeUtils } from './utils';

export namespace ThemeGenerators {
  export const BaseThemeGenerator = () => {
    return responsiveFontSizes(
      createTheme(CookieBasedThemeGenerator(ThemesOptions.baseThemeOptions))
    );
  };

  export const AwareThemeGenerator = () => {
    const awareOptions = deepmerge(
      ThemesOptions.baseThemeOptions,
      ThemesOptions.awareThemeOptions
    );
    return responsiveFontSizes(
      createTheme(CookieBasedThemeGenerator(awareOptions))
    );
  };

  export const MixedThemeGenerator = (...options: ThemeOptions[]) => {
    let merged: ThemeOptions = {};

    options.forEach((option) => {
      merged = deepmerge(merged, option);
    });

    return createTheme(createTheme(merged));
  };

  const CookieBasedThemeGenerator = (baseThemeOptions: ThemeOptions) => {
    const theme = Cookies.get('theme');

    if (theme === ViewTheme.Dark)
      return ThemeUtils.mergeVariant(
        baseThemeOptions,
        ThemeTypes.ThemeVariant.Dark
      );

    return ThemeUtils.mergeVariant(
      baseThemeOptions,
      ThemeTypes.ThemeVariant.Light
    );
  };
}
