import { TimelineTabsValues } from 'interfaces/ui';
import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { useTimelineDetailsSelectedTab } from 'store/reducerHooks';
import usePrevious from './usePrevious';

const INITIAL_WIDTH = 400;

const getDivData = (divElem: HTMLDivElement) => {
  const boundingBox = divElem.getBoundingClientRect();

  return {
    width: boundingBox.width - 20,
    containerY: boundingBox.y,
  };
};

type useContainerOptions = {
  stateChanged?: boolean;
  isDayByDayScreen: boolean;
};

function useContainer(
  divRef: MutableRefObject<HTMLDivElement | null>,
  options: useContainerOptions
) {
  const { isDayByDayScreen, stateChanged = false } = options;

  const [containerWidth, setContainerWidth] = useState(INITIAL_WIDTH);
  const [containerY, setContainerY] = useState(0);

  const previousStateChange = usePrevious(stateChanged);

  const currentTab = useTimelineDetailsSelectedTab();

  const prevTab = usePrevious(currentTab);

  const setValues = useCallback(
    (div: HTMLDivElement) => {
      const { width, containerY } = getDivData(div);

      setContainerWidth(width);
      setContainerY(containerY);
    },
    [setContainerY, setContainerWidth]
  );

  const resizeListener = useCallback(() => {
    if (divRef.current) {
      setValues(divRef.current);
    }
  }, [setValues, divRef]);

  useEffect(() => {
    if (
      divRef.current &&
      isDayByDayScreen &&
      currentTab === TimelineTabsValues.DAY_BY_DAY &&
      prevTab !== currentTab
    ) {
      setValues(divRef.current);
    }
  }, [currentTab, prevTab, setValues, divRef, isDayByDayScreen]);

  const scrollListener = useCallback(() => {
    if (divRef.current) {
      const { containerY } = getDivData(divRef.current);
      setContainerY(containerY);
    }
  }, [divRef, setContainerY]);

  useEffect(() => {
    if (
      previousStateChange !== undefined &&
      stateChanged !== previousStateChange
    ) {
      resizeListener();
    }
  }, [stateChanged, previousStateChange, resizeListener]);

  useEffect(() => {
    if (divRef.current) {
      setValues(divRef.current);
    }

    window.addEventListener('resize', resizeListener);
    window.addEventListener('scroll', scrollListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
      window.removeEventListener('scroll', scrollListener);
    };
  }, [scrollListener, resizeListener, divRef, setValues]);

  return { containerWidth, containerY, resizeListener };
}

export default useContainer;
