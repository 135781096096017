import useInfiniteOrganizationCasesList from 'api/queries/cases/useInfiniteOrganizationCasesList';
import AccessRequestModal from 'components/AccessRequestModal';
import FunctionalityRouteGuard from 'components/routes/FunctionalityRouteGuard';
import { DEFAULT_CASE_PAGE_SIZE } from 'helpers/constants';
import {
  GroupSelectionIdentifiers,
  IRowSelection,
} from 'hooks/useRowSelection';
import { FunctionalityCode } from 'interfaces/functionalities';
import { ListWithSearchComponent } from 'interfaces/ui';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useOrganizationPathParams } from '../../../../../../../../hooks/paths/useOrganizationPathParams';
import {
  ICaseFilters,
  ICaseListItem,
} from '../../../../../../../../interfaces/cases';
import CaseHistoryModal from '../../../../../../modals/CaseHistoryModal';
import CasesTableRenderer from './components/CasesTableRenderer';

interface OldCasesListProps extends ListWithSearchComponent {
  isOpen: boolean;
  toggle: () => void;
  rowSelection: IRowSelection<GroupSelectionIdentifiers>;
  filters: ICaseFilters | null;
  isCaseRequestModalOpen: boolean;
  toggleCaseRequestModalOpen: () => void;
  setTotalItemsCount: (count: number) => void;
}

const CasesTable = ({
  toggle,
  isOpen,
  searchValue,
  rowSelection,
  filters,
  isCaseRequestModalOpen,
  toggleCaseRequestModalOpen,
  setTotalItemsCount,
}: OldCasesListProps) => {
  const navigate = useNavigate();

  const { organizationId } = useOrganizationPathParams();

  const {
    cases,
    fetchNextPage,
    isLoading,
    hasNextPage,
    refetch,
    totalItemsCount,
  } = useInfiniteOrganizationCasesList({
    params: {
      organizationId,
      pageSize: DEFAULT_CASE_PAGE_SIZE,
      searchValue: searchValue,
      filters: filters,
    },
  });

  useEffect(() => {
    setTotalItemsCount(totalItemsCount);
  }, [setTotalItemsCount, totalItemsCount]);

  const allCaseIds = cases?.map((p) => p.id) ?? [];

  const selected =
    rowSelection.included.length > 0
      ? rowSelection.included.map((p) => p.id)
      : allCaseIds.filter(
          (p) => !rowSelection.excluded.map((q) => q.id).includes(p)
        );

  const onRowClick = (caseData: ICaseListItem) => {
    navigate(`/organizations/${organizationId}/cases/${caseData.id}`);
  };

  const handleToggleItem = (caseId: string) => {
    rowSelection.toggleRow({
      id: caseId,
    });
  };

  const handleMessage = (caseId: string) => {
    rowSelection.toggleRow({
      id: caseId,
    });
    toggle();
  };

  const hasMore = !isLoading && hasNextPage;

  return (
    <>
      <FunctionalityRouteGuard
        code={FunctionalityCode.CASE_LIST_SEND_MESSAGE_GROUP}
      >
        <span className="ListSelectionStatusText">
          {rowSelection.selectionStatusText}
        </span>
      </FunctionalityRouteGuard>
      <AccessRequestModal
        isOpen={isCaseRequestModalOpen}
        toggle={toggleCaseRequestModalOpen}
        organizationId={organizationId}
        refetchPatientList={refetch}
        translationIdTitle="CaseCard.searchForCase"
      />
      {cases && (
        <CasesTableRenderer
          cases={cases}
          fetchNextPage={fetchNextPage}
          isLoading={isLoading}
          hasMore={hasMore}
          onRowClick={onRowClick}
          onToggleMessage={handleMessage}
          onToggleRow={handleToggleItem}
          rowSelection={rowSelection}
        />
      )}
      {isOpen && (
        <CaseHistoryModal
          caseIds={selected}
          organizationId={organizationId}
          isOpen={isOpen}
          toggleModal={toggle}
          hidePraise={true}
          hideShare={true}
          rowSelection={rowSelection}
          extendedValues={{
            share_with_patient: true,
          }}
          customTitleTranslationId="ChangeCase.sendMessage"
        />
      )}
    </>
  );
};

export default CasesTable;
