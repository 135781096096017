import { ChartsData, TrendsChartData } from '../types';
import {
  AnswerHistoryQuestion,
  TemplateItemType,
} from '../../../interfaces/change';
import { SimpleQuestionRecord } from '../../../modules/CaseDashboard/components/CaseDashboardTabs/components/TrendsTab/components/TrendsTabItem/types/questions';
import {
  assignRecordsToChartsData,
  averageAnswersByDate,
  getSimpleQuestionRecords,
  groupAnswersByDate,
  normalizeAnswerDates,
} from '../helpers/questions';
import {
  assignEmptyDates,
  removeRedundant,
  sortByDates,
} from '../helpers/common';
import { useMemo } from 'react';
import { UseQueryResult } from 'react-query';
import { StrictDateRange } from '../../../interfaces/dates';

export const useQuestions = (
  enabled: boolean,
  questionAnswerHistory: UseQueryResult<{
    questions: AnswerHistoryQuestion[];
    userId: string;
  }>,
  dateRange: StrictDateRange
): TrendsChartData<string | number> => {
  return useMemo(() => {
    if (!enabled) return { type: TemplateItemType.Questions, data: [] };

    if (!questionAnswerHistory.data)
      return { type: TemplateItemType.Questions, data: [] };

    let _data: ChartsData<string | number>[] = [];

    let records: SimpleQuestionRecord[] = getSimpleQuestionRecords(
      questionAnswerHistory.data
    );

    records = normalizeAnswerDates(records);

    const groupedRecords = groupAnswersByDate(records);

    const averagedRecords = averageAnswersByDate(groupedRecords);

    _data = assignRecordsToChartsData(_data, averagedRecords);

    const questionIds = records.map((p) => p.id);

    questionIds.forEach((questionId) => {
      _data = removeRedundant(_data, questionId);
    });

    _data = assignEmptyDates(_data, dateRange);
    _data = sortByDates(_data);

    return { type: TemplateItemType.Questions, data: _data };
  }, [dateRange, enabled, questionAnswerHistory.data]);
};
