import React from 'react';
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExchange } from '@fortawesome/pro-solid-svg-icons';
import { ICase } from '../../../../interfaces/cases';
import { Field, Formik } from 'formik';
import FieldInput from '../../../../components/FieldInput';
import FieldSelect from '../../../../components/FieldSelect';
import { useMoveCaseModalForm } from './hooks/useMoveCaseModalForm';
import useCasesEdit from '../../../../api/mutations/cases/useCasesEdit';
import { useCaseGroupsCommonData } from '../../hooks/useCaseGroupsCommonData';
import useRequiredParams from '../../../../hooks/useRequiredParams';
import InputFieldWithoutImage from '../../../../components/InputFieldWithoutImage';

type MoveCaseModalProps = {
  isOpen: boolean;
  toggle: () => void;
  cases: ICase[];
};

const MoveCaseModal = ({ isOpen, toggle, cases }: MoveCaseModalProps) => {
  const intl = useIntl();

  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const { fieldsOptions, initialValues, validation } =
    useMoveCaseModalForm(cases);

  const { caseGroups, ungroupedCasesCaseGroup } = useCaseGroupsCommonData();

  const onSuccess = async () => {
    await caseGroups.refetch();
    await ungroupedCasesCaseGroup.refetch();
    toggle();
  };

  const { mutateAsync: patchCases } = useCasesEdit(
    {
      organizationId: organizationId,
      caseIds: cases.map((p) => p.id),
    },
    {
      successFb: onSuccess,
    }
  );

  const handleSubmit = async (values: typeof initialValues) => {
    await patchCases([
      {
        op: 'replace',
        value: values.group_id,
        path: '/group_id',
      },
    ]);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="MoveCaseModal" size="lg">
      <Formik
        validate={validation}
        validateOnMount={true}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isValid, resetForm, dirty }) => {
          const handleReset = () => {
            resetForm();
          };

          return (
            <>
              <ModalHeader className="MoveCaseModal__header" toggle={toggle}>
                <FontAwesomeIcon icon={faExchange} />
                <FormattedMessage id="CaseGroupItem.moveCaseModalTitle" />
              </ModalHeader>
              <ModalBody className="MoveCaseModal__body">
                <Form className="ProjectItemModal__form ProjectItemModalForm">
                  <FormGroup>
                    <InputFieldWithoutImage
                      gray={true}
                      noMargin={true}
                      disabled={true}
                      labelTranslationId="CaseGroupItem.case_ids"
                      inputId="case_ids"
                    >
                      <Field
                        disabled={true}
                        required={true}
                        placeholder={intl.formatMessage({
                          id: `CaseGroupItem.case_ids`,
                        })}
                        id="case_ids"
                        name="case_ids"
                        type="text"
                        component={FieldInput}
                      />
                    </InputFieldWithoutImage>
                  </FormGroup>
                  <FormGroup>
                    <InputFieldWithoutImage
                      gray={true}
                      noMargin={true}
                      labelTranslationId="CaseGroupItem.group_id"
                      inputId="group_id"
                    >
                      <Field
                        required={true}
                        displayEmpty={true}
                        defaultText={intl.formatMessage({
                          id: `CaseGroupItem.groupIdPlaceholder`,
                        })}
                        id="group_id"
                        name="group_id"
                        type="select"
                        options={fieldsOptions.caseGroups}
                        component={FieldSelect}
                      />
                    </InputFieldWithoutImage>
                  </FormGroup>
                </Form>
              </ModalBody>
              <ModalFooter className="MoveCaseModal__footer">
                <Button color="primary" outline={true} onClick={toggle}>
                  <FormattedMessage id="General.close" />
                </Button>
                <Button color="primary" outline={true} onClick={handleReset}>
                  <FormattedMessage id="General.reset" />
                </Button>
                <Button
                  disabled={!isValid || !dirty}
                  color="primary"
                  onClick={submitForm}
                >
                  <FormattedMessage id="General.save" />
                </Button>
              </ModalFooter>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default MoveCaseModal;
