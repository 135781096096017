import useCaseDetails from 'api/queries/cases/useCaseDetails';
import useCasePhaseHistory from 'api/queries/cases/useCasePhaseHistory';
import useConversationQuestions from 'api/queries/conversations/useConversationQuestions';
import useObservationMeanings from 'api/queries/observationMeanings/useObservationMeanings';
import useCaseRelatedTrendVisualizationTemplates from 'api/queries/trendVisualizations/useCaseRelatedTrendVisualizationTemplates';
import { useOrganizationCasePathParams } from '../../../../../../../../../../../hooks/paths/useOrganizationCasePathParams';

export const useCommonData = () => {
  const { organizationId, caseId } = useOrganizationCasePathParams();

  const trendVisualizationTemplate = useCaseRelatedTrendVisualizationTemplates({
    params: { organizationId, caseId },
    options: { enabled: false, staleTime: Infinity },
  });

  const caseDetails = useCaseDetails({
    params: { organizationId, caseId },
    options: { enabled: false, staleTime: Infinity },
  });

  const casePhaseHistory = useCasePhaseHistory({
    params: { organizationId, caseId },
    options: { enabled: false, staleTime: Infinity },
  });

  const conversationQuestions = useConversationQuestions({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const observationMeanings = useObservationMeanings({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  return {
    trendVisualizationTemplate,
    conversationQuestions,
    caseDetails,
    casePhaseHistory,
    observationMeanings,
  };
};
