import useRequiredParams from 'hooks/useRequiredParams';
import { IGraspLedLevel } from 'interfaces/grasps';
import { useCommonData } from 'modules/Organizations/views/OrganizationDetailsView/hooks/useCommonData';
import useRemoveGraspLedLevel from 'api/mutations/grasps/useRemoveGraspLedLevel';

export const useItemActions = (
  handleSelectLevel: (level: IGraspLedLevel | undefined) => void
) => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const { graspSettings } = useCommonData();

  const handleSuccess = async () => {
    await graspSettings.refetch();
  };

  const { mutateAsync: removeAsync } = useRemoveGraspLedLevel(
    {
      organizationId,
    },
    { successFb: handleSuccess }
  );
  const handleEdit = (item: IGraspLedLevel) => {
    handleSelectLevel(item);
  };

  const handleRemove = async (id: string) => {
    await removeAsync(id);
  };

  return {
    onEdit: handleEdit,
    onRemove: handleRemove,
  };
};
