import { requestPatientAccess } from 'api/patients';
import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import {
  displayErrorArrayNotifications,
  displayErrorNotification,
  displaySuccessNotification,
} from 'modules/Notifications';
import {
  IRequestAccessMutationBody,
  IRequestAccessRequestBody,
} from 'interfaces/request';
import { ParticipantAccessRequest } from 'interfaces/ui';
import { IErrorDefinition } from '../../../interfaces/response';

function useRequestPatientAccesses(
  organizationId: string,
  onSuccess?: () => void
) {
  return useMutationWithResultNotification<IRequestAccessMutationBody, void>({
    asyncApiFunction: async ({ access_request, ...restOfMutation }) => {
      const filteredAccessRequest: ParticipantAccessRequest =
        access_request.participant_already_shared
          ? {
              cases_id: access_request.cases_id,
            }
          : access_request;

      const requestBody: IRequestAccessRequestBody = {
        ...restOfMutation,
        access_request: filteredAccessRequest,
      };

      await requestPatientAccess(organizationId, requestBody);
    },
    onSuccess: () => {
      onSuccess && onSuccess();
      displaySuccessNotification(
        'Api.Mutations.Patients.RequestPatientAccesses.success'
      );
    },
    onError: () => {
      displayErrorNotification(
        'Api.Mutations.Patients.RequestPatientAccesses.failure'
      );
    },
    onErrorArray: (errors: IErrorDefinition[]) => {
      displayErrorArrayNotifications(
        errors,
        'Api.Mutations.Patients.RequestPatientAccesses.errors'
      );
    },
    resetOnResult: true,
  });
}

export default useRequestPatientAccesses;
