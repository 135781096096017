import { useCaseGroupDetailsCommonData } from '../../../../../hooks/useCaseGroupDetailsCommonData';
import { useTemplateCharacteristics } from '../../../../../../../../../hooks/useTemplateCharacteristics';
import { useInitQuery } from '../../../../../../../../../hooks/queries/useInitQuery';

export const useCaseGroupDetailsTemplateCharacteristics = () => {
  const { conversationTemplates } = useCaseGroupDetailsCommonData();

  useInitQuery(conversationTemplates);

  return useTemplateCharacteristics(conversationTemplates?.data ?? []);
};
