import useOrganizationContextAssessmentFormsAssignments from 'api/queries/assessmentForms/assignments/useOrganizationContextAssessmentFormsAssignments';
import useOrganizationContextGroupedAssessmentFormsForDate from 'api/queries/assessmentForms/forms/date/useOrganizationContextGroupedAssessmentFormsForDate';
import useOrganizationAssessmentFormsTemplates from 'api/queries/assessmentForms/templates/useOrganizationAssessmentFormTemplates';
import useCaseDetails from 'api/queries/cases/useCaseDetails';
import useOrganizationPatientDetails from 'api/queries/patients/useOrganizationPatientDetails';
import { isSameDay, startOfWeek } from 'date-fns';
import { addDays } from 'date-fns/esm';
import { hourSort } from 'helpers/utils/assessmentForms';
import { groupByProperty } from 'helpers/utils/groupByProperty';
import { days } from 'helpers/utils/translationObject';
import {
  CustomScheduleInfo,
  IAssessmentFormAssignment,
  IUserAssessments,
  WeeklyScheduleInfo,
} from 'interfaces/assessmentForms';
import { setAssessmentFormIdentification } from 'modules/TimelineDetails/actions';
import SingleAssessmentForm from 'modules/TimelineDetails/components/tabs/DayByDayTab/components/DayDetails/tabs/AssessmentForms/SingleAssessmentForm';
import QuestionModal from 'modules/TimelineDetails/components/tabs/DayByDayTab/components/QuestionModal';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spinner } from 'reactstrap';
import { RootState } from 'store';
import AssignmentHeaderInfo from './AssignmentHeaderInfo';
import FillOtherFormModal, { AssessmentBaseProps } from './FillOtherFormModal';
import OtherAssessmentForm from './OtherAssessmentForm';

const currentDate = new Date(
  new Date().setHours(0, new Date().getTimezoneOffset() * -1, 0, 0)
);

const currentDay = currentDate.getDay().toString();

type PatientAssessmentFormsProps = {
  caseId: string;
  organizationId: string;
  personalityId: string;
  previousClick: () => void;
  nextClick: () => void;
};

const returnGroupedAssessments = (assessmentForms: IUserAssessments[]) => {
  let groupedAssessments = groupByProperty('title')(assessmentForms) as Record<
    string,
    IUserAssessments[]
  >;

  Object.values(groupedAssessments).forEach((assessments) => {
    const cpyOfAssessments = [...assessments];
    return cpyOfAssessments.sort(hourSort);
  });

  return groupedAssessments;
};

const PatientAssessmentForms = ({
  caseId,
  organizationId,
  personalityId,
  nextClick,
  previousClick,
}: PatientAssessmentFormsProps) => {
  const selectedDate = new Date();
  const dispatch = useDispatch();

  const { data: patientDetailsData } = useOrganizationPatientDetails({
    params: { organizationId, personalityId },
    options: { enabled: false },
  });

  const { data: caseData } = useCaseDetails({
    params: { organizationId, caseId },
  });

  const defaultTimelineId =
    caseData?.timelines.find((timeline) => timeline.is_shared)?.id ?? '';

  const { data: assignmentsData } =
    useOrganizationContextAssessmentFormsAssignments({
      params: { organizationId, caseId, timelineId: defaultTimelineId },
      options: { enabled: !!defaultTimelineId },
    });

  const { data: assessmentForms } =
    useOrganizationContextGroupedAssessmentFormsForDate({
      params: {
        organizationId,
        caseId,
        timelineId: defaultTimelineId,
        selectedDate,
      },
      options: { enabled: !!defaultTimelineId },
    });

  const { data: templatesData } = useOrganizationAssessmentFormsTemplates({
    params: {
      organizationId,
      allowedForCaseId: null,
      questionsIncludedInTrends: true,
    },
  });

  const groupedAssessments = !!assessmentForms
    ? returnGroupedAssessments(assessmentForms)
    : {};
  const formTitles = Object.keys(groupedAssessments);

  const renderAssessmentForms = () => {
    if (!patientDetailsData?.user_id || !defaultTimelineId || !assessmentForms)
      return (
        <div className="w-100 text-center">
          <Spinner />
        </div>
      );

    const renderedElements: JSX.Element[] = [];

    formTitles.forEach((title) => {
      const groupedAssessment = groupedAssessments[title];
      const renderedForms = groupedAssessment.map((assessment, idx) => (
        <SingleAssessmentForm
          key={idx}
          form={assessment}
          isOwner={true}
          userId={patientDetailsData?.user_id}
          forceEdit={true}
        />
      ));

      const formCode = groupedAssessment[0]?.code;
      const assignmentInfo = assignmentsData?.find(
        (assignment) => assignment.formCode === formCode
      );

      renderedElements.push(
        <div key={`${assignmentInfo?.id}|${title}`}>
          <AssignmentHeaderInfo assignment={assignmentInfo} title={title} />
          {renderedForms}
        </div>
      );
    });

    return renderedElements;
  };

  const [otherFormProps, setOtherFormProps] =
    useState<AssessmentBaseProps | null>(null);

  const closeEditOtherModal = () => setOtherFormProps(null);

  const renderWeekly = (
    title: string | undefined,
    assignment: IAssessmentFormAssignment,
    weeklyScheduleDays: string[],
    dayIndexes: string[],
    weeklySchedule: WeeklyScheduleInfo[]
  ) => {
    const startOfTheWeek = startOfWeek(currentDate);

    const scheduleDates: Date[] = [];

    weeklyScheduleDays.forEach((scheduleDay) => {
      const newDate = addDays(startOfTheWeek, Number(scheduleDay));

      if (newDate > currentDate) {
        const reversedDays = [...dayIndexes].reverse();
        const reversedDay =
          reversedDays.findIndex((day) => day === scheduleDay) + 1;

        scheduleDates.push(addDays(startOfTheWeek, reversedDay * -1));
      } else scheduleDates.push(newDate);
    });

    const highestDate = new Date(
      Math.max(...scheduleDates.map((date) => date.getTime()))
    );
    const day = weeklyScheduleDays.find(
      (day) => day === highestDate.getDay().toString()
    ) as string;

    const scheduleInfo = weeklySchedule.find(
      (el) => el.day === days[day]
    ) as WeeklyScheduleInfo;

    const highestTime = scheduleInfo.hours[scheduleInfo.hours.length - 1];

    return (
      <>
        {title && (
          <AssignmentHeaderInfo title={title} assignment={assignment} />
        )}
        <OtherAssessmentForm
          scheduleDate={highestDate}
          formCode={assignment.formCode}
          organizationId={organizationId}
          scheduleTime={highestTime}
          setFormToEdit={setOtherFormProps}
        />
      </>
    );
  };

  const renderCustom = (
    title: string | undefined,
    assignment: IAssessmentFormAssignment,
    customScheduleInfo: CustomScheduleInfo[]
  ) => {
    const dates = customScheduleInfo.map((el) => new Date(el.date).getTime());

    const max = Math.max(...dates);
    const elementWithHighestDate = customScheduleInfo.find(
      (el) => new Date(el.date).getTime() === max
    ) as CustomScheduleInfo;

    const highestTime =
      elementWithHighestDate.hours[elementWithHighestDate.hours.length - 1];

    return (
      <>
        {title && (
          <AssignmentHeaderInfo title={title} assignment={assignment} />
        )}

        <OtherAssessmentForm
          organizationId={organizationId}
          scheduleDate={new Date(elementWithHighestDate.date)}
          scheduleTime={highestTime}
          formCode={assignment.formCode}
          setFormToEdit={setOtherFormProps}
        />
      </>
    );
  };

  const renderOtherAssignments = () => {
    if (!assignmentsData)
      return (
        <div className="w-100 text-center">
          <Spinner />
        </div>
      );

    const filteredAssignments = assignmentsData?.filter(
      (assignment) => assignment.scheduleInfo.daily === null
    );

    const renderedElements: JSX.Element[] = [];

    filteredAssignments?.forEach((assignment) => {
      const title = templatesData?.find(
        (template) => template.code === assignment.formCode
      )?.title;

      if (
        assignment.scheduleInfo.custom !== null &&
        !assignment.scheduleInfo.custom.some((el) =>
          isSameDay(new Date(el.date), currentDate)
        )
      ) {
        renderedElements.push(
          renderCustom(title, assignment, assignment.scheduleInfo.custom)
        );
      } else if (assignment.scheduleInfo.weekly !== null) {
        const dayIndexes = Object.keys(days);

        const weeklyScheduleDays = assignment.scheduleInfo.weekly.map((el) => {
          return dayIndexes.find(
            (dayIndex) => days[dayIndex] === el.day
          ) as string;
        });

        if (!weeklyScheduleDays.includes(currentDay)) {
          renderedElements.push(
            renderWeekly(
              title,
              assignment,
              weeklyScheduleDays,
              dayIndexes,
              assignment.scheduleInfo.weekly
            )
          );
        }
      }
    });

    return renderedElements;
  };

  const selectedFormIdentification = useSelector(
    (store: RootState) => store.timelineDetails.assessmentFormIdentification
  );

  const closeModal = () => {
    dispatch(setAssessmentFormIdentification(null));
  };

  return (
    <>
      {!!patientDetailsData?.user_id && !!defaultTimelineId && (
        <QuestionModal
          closeModal={closeModal}
          assessmentFormIdentification={selectedFormIdentification}
          selectedDate={selectedDate}
          userId={patientDetailsData?.user_id}
          timelineId={defaultTimelineId}
          forceEdit={true}
        />
      )}
      {!!otherFormProps && !!defaultTimelineId && !!patientDetailsData && (
        <FillOtherFormModal
          timelineId={defaultTimelineId}
          closeModal={closeEditOtherModal}
          {...otherFormProps}
          userId={patientDetailsData?.user_id}
        />
      )}
      <div className="StatusCheck__assessment-forms">
        <h3 className="StatusCheck__assessment-forms__date-title">
          <FormattedMessage id="PatientCheck.today" />
        </h3>
        {renderAssessmentForms()}
        <h3 className="StatusCheck__assessment-forms__date-title">
          <FormattedMessage id="PatientCheck.others" />
        </h3>
        {renderOtherAssignments()}
      </div>
      <div className="StatusCheck__button-container">
        <Button onClick={previousClick} color="primary" outline>
          <FormattedMessage id="General.previous" />
        </Button>
        <Button onClick={nextClick} color="primary">
          <FormattedMessage id="General.next" />
        </Button>
      </div>
    </>
  );
};

export default PatientAssessmentForms;
