import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { NavLink } from 'react-router-dom';
import { NavItem } from 'reactstrap';
import { wrapWithSequenceIdentified } from '../../../../helpers/utils/data';

const NAVIGATION_ITEMS_COUNT = 3;

const NavigationPlaceholder = () => {
  return (
    <>
      {wrapWithSequenceIdentified([...new Array(NAVIGATION_ITEMS_COUNT)]).map(
        (item) => (
          <NavItem key={item.sequence}>
            <NavLink to="">
              <Skeleton width={100} />
            </NavLink>
          </NavItem>
        )
      )}
    </>
  );
};

export default NavigationPlaceholder;
