import useGrasps from '../../../../../../../../../api/queries/grasps/useGrasps';
import { TRANSFER_GRASP_TABLE_PAGE_SIZE } from '../defaults';

export const useGraspsData = (searchValue: string | undefined) => {
  const query = useGrasps({
    params: {
      searchValue,
      pageSize: TRANSFER_GRASP_TABLE_PAGE_SIZE,
    },
    options: { enabled: true },
  });

  return {
    ...query,
  };
};
