import { useState } from 'react';
import { IUserOrganizationResponse } from '../../../../../interfaces/membership';
import { useRemoveOrganizationUserAccessProfile } from '../../../../../api/mutations/accessProfiles/useRemoveOrganizationUserAccessProfile';
import { useOrganizationPathParams } from '../../../../../hooks/paths/useOrganizationPathParams';
import { InfiniteData, QueryObserverResult } from 'react-query';
import { IError, IInfiniteResult } from '../../../../../interfaces/response';
import {
  ConfirmationHookValues,
  UserDataToRemoveAccessProfile,
} from '../types';

export const useDeleteRoleConfirmation = (
  refetch: () => Promise<
    QueryObserverResult<
      InfiniteData<IInfiniteResult<IUserOrganizationResponse[]>>,
      IError
    >
  >
): ConfirmationHookValues<UserDataToRemoveAccessProfile | null> => {
  const { organizationId } = useOrganizationPathParams();

  const [userData, setUserData] =
    useState<UserDataToRemoveAccessProfile | null>(null);

  const successFb = async () => refetch();

  const { mutateAsync: removeAsync } = useRemoveOrganizationUserAccessProfile(
    { organizationId },
    { successFb }
  );

  const onConfirm = async () => {
    if (!userData) return;

    await removeAsync({
      userId: userData.userRecord.user.id,
      accessProfileId: userData.accessProfileId,
    });

    setUserData(null);
  };

  const isOpen = !!userData;

  const close = () => setUserData(null);

  return { isOpen, close, onConfirm, item: userData, setItem: setUserData };
};
