import { getUserGrasp } from 'api/graspManagement';
import QueryKeys from 'api/queries/queryKeys';
import { UserQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { useQuery } from 'react-query';

function useUserGrasps({ params, options }: IQueryRequest<UserQueryParams>) {
  const { userId } = params;
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getUserGrasp(userId);
      return data.data;
    },
    queryKey: [QueryKeys.getUserGrasp, userId],
  });
}

export default useUserGrasps;
