import { useCallback, useMemo, useState } from 'react';
import { ITabContainerItem } from 'components/Tabs/types/Tabs';
import { FormattedMessage } from 'react-intl';
import TabSelector from '../components/TabSelector';
import { DentalOrganizationTabsValues } from '../types';
import { useHasFunctionality } from '../../../../../../../hooks/useFunctionality';
import { FunctionalityCode } from 'interfaces/functionalities';

export const useTabs = () => {
  const hasFunctionality = useHasFunctionality();

  const [selectedTab, setSelectedTab] = useState<DentalOrganizationTabsValues>(
    DentalOrganizationTabsValues.Info
  );

  const availableTabs = useMemo(() => {
    let tabs: string[] = [];

    Object.values(DentalOrganizationTabsValues).forEach((value) => {
      if (
        value === DentalOrganizationTabsValues.Info ||
        hasFunctionality(FunctionalityCode[`OrganizationTabs${value}`])
      )
        tabs.push(value);
    });

    return tabs;
  }, [hasFunctionality]);

  const tabItems: ITabContainerItem[] = useMemo(() => {
    let items: ITabContainerItem[] = [];

    availableTabs.forEach((value) => {
      if (value === DentalOrganizationTabsValues.Info) {
        items.push({
          header: (
            <FormattedMessage id="Enums.DentalOrganizationTabsValues.Info" />
          ),
          component: <TabSelector value={value} />,
          shouldBeRendered: selectedTab === value,
        });

        return;
      }

      if (!hasFunctionality(FunctionalityCode[`OrganizationTabs${value}`]))
        return;

      items.push({
        header: (
          <FormattedMessage
            id={`Enums.DentalOrganizationTabsValues.${value}`}
          />
        ),
        component: (
          <TabSelector value={value as DentalOrganizationTabsValues} />
        ),
        shouldBeRendered: selectedTab === value,
      });
    });

    return items;
  }, [availableTabs, hasFunctionality, selectedTab]);

  const onTabChange = useCallback(
    (val: number) => {
      setSelectedTab(availableTabs[val] as DentalOrganizationTabsValues);
    },
    [availableTabs]
  );

  return { tabItems, onTabChange };
};
