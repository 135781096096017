import { IQueryRequest } from '../../../interfaces/request';
import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getCaseNotificationSchedules } from '../../notificationSchedules';

type CaseNotificationSchedulesQueryParams = {
  userId: string;
  caseId: string;
  year: number;
  month: number;
  day: number;
};

const useCaseNotificationSchedules = ({
  params,
  options,
}: IQueryRequest<CaseNotificationSchedulesQueryParams>) => {
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getCaseNotificationSchedules(
        params.userId,
        params.caseId,
        params.year,
        params.month,
        params.day
      );

      return data.data;
    },
    queryKey: [
      QueryKeys.getCaseNotificationSchedules,
      params.userId,
      params.caseId,
      `${params.year}|${params.month}|${params.day}`,
    ],
  });
};

export default useCaseNotificationSchedules;
