import { useTrendsChartConfig } from '../../../../../../../../../components/TrendsChart/hooks/useTrendsChartConfig';
import { IOrganizationObservationMeaning } from '../../../../../../../../../interfaces/observationMeanings';
import { TrendVisualization } from '../../../../../../../../../interfaces/trendVisualizations';
import { cloneDeep } from 'lodash';
import { ConversationQuestion } from '../../../../../../../../../interfaces/conversations';
import useCultures from '../../../../../../../../../hooks/cultures/useCultures';
import { useDates } from '../../../hooks/useDates';
import { getChartIntervalBasedOnDateRange } from '../../../../../../../../../helpers/dates';
import useMobile from '../../../../../../../../../hooks/useBreakpoint';
import { useCommonData } from '../../../../../../../hooks/useCommonData';

export const useTrendsTabItemConfig = (
  questions: ConversationQuestion[],
  meanings: IOrganizationObservationMeaning[],
  visualization: TrendVisualization
) => {
  const isMobile = useMobile();

  const { userCaseDetails } = useCommonData();
  const { currentCulture, defaultCulture } = useCultures();

  const dateRange = useDates();

  let trendsChartConfig = useTrendsChartConfig(questions, meanings);

  let configCopy = cloneDeep(trendsChartConfig);

  let visualizationTitle = visualization.title;

  if (currentCulture.cultureCode !== defaultCulture.cultureCode)
    visualizationTitle =
      visualization.title_translations.find(
        (p) => p.culture === currentCulture.cultureCode
      )?.value ?? visualization.title;

  configCopy.header = {
    title: visualizationTitle,
    titleEnabled: true,
    subtitleEnabled: userCaseDetails.data?.exercises_enabled === true,
  };

  const xAxisInterval = !isMobile
    ? getChartIntervalBasedOnDateRange({
        from: dateRange.from,
        to: dateRange.to,
      })
    : getChartIntervalBasedOnDateRange(
        {
          from: dateRange.from,
          to: dateRange.to,
        },
        2,
        10
      );

  configCopy.axis = [
    {
      axis: 'x-axis',
      interval: xAxisInterval,
    },
  ];

  return configCopy;
};
