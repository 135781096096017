import { useCallback } from 'react';
import {
  IGraspDashboardFilter,
  IGraspDashboardTemplateFilter,
} from '../../../../../../../interfaces/dashboards';
import { IGrasp } from '../../../../../../../interfaces/grasps';
import { DashboardSelectionHelpers } from '../helpers/selection';

export const useGraspsOptions = (grasps: IGrasp[]) => {
  const append = useCallback(
    (dashboardFilter: IGraspDashboardFilter) => {
      grasps.forEach((grasp) => {
        dashboardFilter.options.push({
          id: grasp.id,
          selected: false,
        });
      });

      return dashboardFilter;
    },
    [grasps]
  );

  return {
    append,
    select: DashboardSelectionHelpers.SelectIncludedExcluded<
      IGraspDashboardFilter,
      IGraspDashboardTemplateFilter
    >,
  };
};
