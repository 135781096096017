import { ICaseDetails } from 'interfaces/cases';
import { UseQueryResult } from 'react-query';
import useCaseEdit from '../../../../../../../api/mutations/cases/useCaseEdit';
import useAwareSessionDataMapping from '../../../../../../../api/queries/awareSessions/useAwareSessionDataMapping';
import useCaseAwareSession from '../../../../../../../api/queries/awareSessions/useCaseAwareSession';
import useCaseDetails from '../../../../../../../api/queries/cases/useCaseDetails';
import useConversationQuestions from '../../../../../../../api/queries/conversations/useConversationQuestions';
import useInfiniteUserOrganizations from '../../../../../../../api/queries/organizations/useInfiniteUserOrganizations';
import useOrganizationDetails from '../../../../../../../api/queries/organizations/useOrganizationDetails';
import useOrganizationMembers from '../../../../../../../api/queries/organizations/useOrganizationMembers';
import { getUserId } from '../../../../../../../helpers/utils/auth';
import { useOrganizationCasePathParams } from '../../../../../../../hooks/paths/useOrganizationCasePathParams';
import { AwareSessionDataSource } from '../../../../../../../interfaces/awareSessions';
import useCaseProcedureHistory from '../../../../../../../api/queries/cases/useCaseProcedureHistory';

export const useCommonData = () => {
  const userId = getUserId();
  const { organizationId, caseId } = useOrganizationCasePathParams();

  const organizationDetails = useOrganizationDetails({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const caseDetails = useCaseDetails({
    params: { organizationId, caseId },
    options: { enabled: false, staleTime: Infinity },
  });

  const awareSessionDataMapping = useAwareSessionDataMapping({
    params: {
      organizationId,
    },
    options: { enabled: false, staleTime: Infinity },
  });

  const awareSessionData = useCaseAwareSession({
    params: {
      organizationId,
      caseId,
      source: AwareSessionDataSource.AwareSessionData,
    },
    options: { enabled: false, staleTime: Infinity },
  });

  const aggregatedAwareSessionData = useCaseAwareSession({
    params: {
      organizationId,
      caseId,
      source: AwareSessionDataSource.AggregatedAwareSessionData,
    },
    options: { enabled: false, staleTime: Infinity },
  });

  const userOrganizations = useInfiniteUserOrganizations({
    params: { userId, pageSize: 1000 },
    options: { enabled: false, staleTime: Infinity },
  });

  const organizationMembers = useOrganizationMembers({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const conversationQuestions = useConversationQuestions({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const caseProcedureHistory = useCaseProcedureHistory({
    params: { organizationId, caseId },
    options: { enabled: false, staleTime: Infinity },
  });

  return {
    caseDetails,
    organizationMembers,
    organizationDetails,
    conversationQuestions,
    awareSessionData,
    aggregatedAwareSessionData,
    awareSessionDataMapping,
    userOrganizations,
    caseProcedureHistory,
    getCaseEdit: useCaseEditMutation,
  };
};

const useCaseEditMutation = (
  caseDetails: UseQueryResult<ICaseDetails>,
  successFb?: () => void
) => {
  const { organizationId, caseId } = useOrganizationCasePathParams();

  const onCaseEdit = async () => {
    await caseDetails.refetch();
    successFb?.();
  };

  return useCaseEdit(
    {
      organizationId,
      caseId,
    },
    { successFb: onCaseEdit }
  );
};
