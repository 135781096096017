import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { deleteAccessProfile } from '../../accessProfiles';
import { IMutationProps } from '../../../interfaces/ui';

type useDeleteAccessProfileParams = {
  organizationId: string;
};

export const useDeleteAccessProfile = (
  params: useDeleteAccessProfileParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (accessProfileId: string) => {
      await deleteAccessProfile(params.organizationId, accessProfileId);
    },
    onSuccess: () => {
      if (options.successFb) options.successFb();
    },
    onErrorArray: (_) => {
      if (options.errorFb) options.errorFb();
    },
    resetOnResult: true,
  });
};
