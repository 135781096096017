import { GridRenderEditCellParams } from '@mui/x-data-grid';
import React from 'react';
import { OrganizationAccessProfile } from '../../../../../../../../../../../../../../interfaces/accessProfiles';
import SelectCell from '../../../SelectCell';

type AccessProfileEditCellProps = GridRenderEditCellParams & {
  accessProfiles: OrganizationAccessProfile[];
};

const AccessProfileEditCell = ({
  accessProfiles,
  ...inner
}: AccessProfileEditCellProps) => {
  const valueGetter = (item: OrganizationAccessProfile) => item.id;
  const textGetter = (item: OrganizationAccessProfile) => item.name;

  return (
    <SelectCell
      items={accessProfiles}
      valueGetter={valueGetter}
      textGetter={textGetter}
      {...inner}
    />
  );
};

export default AccessProfileEditCell;
