import { getStrictPersonalities } from 'api/patients';
import QueryKeys from 'api/queries/queryKeys';
import { OrganizationQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { useQuery } from 'react-query';

interface UseStrictOrganizationPatientListParams
  extends OrganizationQueryParams {
  fullName?: string;
  patientNumber?: number;
}

function useStrictOrganizationPatientList({
  params,
  options,
}: IQueryRequest<UseStrictOrganizationPatientListParams>) {
  const { organizationId, fullName, patientNumber } = params;
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getStrictPersonalities(
        organizationId,
        fullName,
        patientNumber
      );
      return data.data;
    },
    queryKey: [
      QueryKeys.getStrictOrganizationPersonalities,
      organizationId,
      fullName,
      patientNumber,
    ],
  });
}

export default useStrictOrganizationPatientList;
