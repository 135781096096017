import languageDictionary, { Locales } from './languageDictionary';
import { getAccessToken, getTokenData } from '../../helpers/utils/auth';

const defaultLanguage = Locales.EN;
export const usedTranslations = Object.values(Locales);

type ExtendedNavigator = Navigator & {
  userLanguage?: string;
  browserLanguage?: string;
};

export const getCulture = () => {
  const token = getAccessToken();
  const tokenData = getTokenData(token);

  if (!tokenData) return getCultureBasedOnNavigator();

  if (tokenData['Grasp.Identity.UserCulture'])
    return tokenData['Grasp.Identity.UserCulture'];

  return getCultureBasedOnNavigator();
};

const getCultureBasedOnNavigator = () => {
  if (window.navigator.languages) return window.navigator.languages[0];
  if (window.navigator.language) return window.navigator.language;

  const extendedNavigator = window.navigator as ExtendedNavigator;

  if (extendedNavigator.userLanguage) return extendedNavigator.userLanguage;

  if (extendedNavigator.browserLanguage)
    return extendedNavigator.browserLanguage;

  return languageDictionary[Locales.EN][1];
};

export const checkIfPreferredLanguage = (lang: string) => {
  let matchedTranslations = false;

  usedTranslations.forEach((uT) => {
    if (lang?.toLowerCase().includes(uT)) matchedTranslations = true;
  });

  return matchedTranslations;
};

export const returnFormattedLang = (lang: string) => {
  for (const key of Object.keys(languageDictionary)) {
    const languageDictionaryToUpper = languageDictionary[key].map((el: any) =>
      el.toUpperCase()
    );

    if (languageDictionaryToUpper.includes(lang.toUpperCase())) return key;
  }
  return lang;
};

export const getLangWithoutRegion = () => {
  const language = getCulture();
  const formattedLanguage = returnFormattedLang(language);

  return checkIfPreferredLanguage(formattedLanguage)
    ? formattedLanguage
    : defaultLanguage;
};

export const getSpecifiedLang = (lang: string) => {
  return checkIfPreferredLanguage(lang) ? lang : defaultLanguage;
};

export const isNorwegian = () => {
  return languageDictionary.no.includes(getCulture());
};
