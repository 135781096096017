import { IAwareSessionDataMapping } from '../../interfaces/awareSessions';
import { ConversationQuestion } from '../../interfaces/conversations';
import { IUserOrganizationResponse } from '../../interfaces/membership';
import { IBaseOrganization } from '../../interfaces/organizations';

export namespace AwareSessionHelpers {
  export const GetAggregatedOrganizations = (
    mapping: IAwareSessionDataMapping,
    organizations: IUserOrganizationResponse[]
  ): IBaseOrganization[] => {
    const availableUserOrganizations = organizations.filter(
      (p) => p.organization !== null
    );

    return availableUserOrganizations
      .map((p) => p.organization)
      .filter((p) => mapping.organizations.includes(p.id));
  };

  export const GetAggregatedQuestions = (
    mapping: IAwareSessionDataMapping,
    organizationId: string
  ): ConversationQuestion[] => {
    return mapping.questions.map((mappingQuestion) => ({
      resource_id: mappingQuestion.guid,
      organization_id: organizationId,
      content: mappingQuestion,
    }));
  };
}
