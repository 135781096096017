import {
  DashboardDatePeriod,
  DashboardDateSelection,
  DashboardFilter,
  IDashboardTemplateContent,
} from '../../../../../../../../../interfaces/dashboards';
import { StrictDateRange } from '../../../../../../../../../interfaces/dates';

export namespace DashboardDataActions {
  export enum DashboardDataActionType {
    EditTemplate = 'EditTemplate',
    EditDatePeriod = 'EditDatePeriod',
    EditDateRange = 'EditDateRange',
  }

  export type DashboardDataAction =
    | EditTemplateAction
    | EditDatePeriodAction
    | EditDateRangeAction;

  export type EditTemplateAction = {
    type: DashboardDataActionType.EditTemplate;
    payload: IDashboardTemplateContent<DashboardFilter, DashboardDateSelection>;
  };

  export type EditDatePeriodAction = {
    type: DashboardDataActionType.EditDatePeriod;
    payload: DashboardDatePeriod;
  };

  export type EditDateRangeAction = {
    type: DashboardDataActionType.EditDateRange;
    payload: StrictDateRange;
  };
}
