import React from 'react';
import { secondsToDurationString } from '../../../../../../../../../../helpers/dates';
import { useCookie } from '../../../../../../../../../../hooks/useCookie';
import { ViewTheme } from '../../../../../../../../../../interfaces/ui';

const IntensityTimelineXAxisTick = (props: any) => {
  const theme = useCookie<ViewTheme>('theme');
  const tickColor = theme === ViewTheme.Dark ? '#fff' : '#313131';

  return (
    <g
      transform={`translate(${props.x},${props.y})`}
      style={{ cursor: 'pointer', zIndex: 100 }}
    >
      <text
        x={0}
        y={0}
        dy={15}
        textAnchor="middle"
        fill={
          props.index === 0 || props.index === props.visibleTicksCount - 1
            ? tickColor
            : 'transparent'
        }
        fontSize={14}
      >
        {props.payload.value === 'auto'
          ? ''
          : secondsToDurationString(props.payload.value / 1000)}
      </text>
    </g>
  );
};

export default IntensityTimelineXAxisTick;
