import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

//helpers
import useTimelines from 'api/queries/timelines/useTimelines';
import useUserSharingRequests from 'api/queries/timelines/useUserSharingRequests';

//helpers
import { getUserId } from 'helpers/utils/auth';

//interfaces
import { ISharing } from 'interfaces/personalities';

//components
import PageHeader from 'components/PageHeader';
import ShareRequestModal from './components/ShareRequestModal';
import SingleSharingRequestPlaceholder from './components/SingleSharingRequestPlaceholder';
import SingleSharingRequestRow from './components/SingleSharingRequestRow';
import EditShareRequestModal from './components/EditShareRequestModal';
import CustomTable from 'components/CustomTable';

const sharingRequestHeaders = [
  'SharingRequests.organization',
  'SharingRequests.sentBy',
  'SharingRequests.name',
  'SharingRequests.status',
  'SharingRequests.sharedTimelines',
  'SharingRequests.actions',
];

const sharingRequestsPlaceholders = [...new Array(5)].map((_el, index) => (
  <SingleSharingRequestPlaceholder
    key={`sharing-request-placeholder-${index}`}
  />
));

const SharingRequests = () => {
  const userId = getUserId();

  const [acceptedRequest, setAcceptedRequest] = useState<ISharing | null>(null);
  const [editedRequest, setEditRequested] = useState<string | null>(null);

  const {
    data: requests,
    isLoading,
    refetch,
  } = useUserSharingRequests({ params: { userId } });

  const { data: timelines } = useTimelines({ params: { userId } });

  const onAcceptClick = (caseValue: ISharing) => setAcceptedRequest(caseValue);
  const onSharingModalClose = () => setAcceptedRequest(null);

  const onEditClick = (caseValue: string) => setEditRequested(caseValue);
  const onEditSharingModalClose = () => setEditRequested(null);

  const tableBody = useMemo(() => {
    if (isLoading && !timelines) {
      return sharingRequestsPlaceholders;
    } else if (!requests || requests.length === 0) {
      return [
        <tr key="request-nothing-to-display">
          <td colSpan={6} className="p-4">
            <FormattedMessage id="SharingRequests.nothingToDisplay" />
          </td>
        </tr>,
      ];
    } else {
      return requests.map((singleRequest) => (
        <SingleSharingRequestRow
          key={singleRequest.id}
          data={singleRequest}
          userId={userId}
          onAccept={onAcceptClick}
          onEdit={onEditClick}
          refetchFunction={refetch}
        />
      ));
    }
  }, [timelines, userId, requests, refetch, isLoading]);

  return (
    <>
      <PageHeader
        breadcrumbs={[
          {
            key: 'sharing-requests',
            link: null,
            text: <FormattedMessage id="TimelineDetails.sharingRequests" />,
          },
        ]}
      />
      <ShareRequestModal
        request={acceptedRequest}
        userId={userId}
        timelines={timelines}
        onClose={onSharingModalClose}
        refetchFunction={refetch}
      />
      <EditShareRequestModal
        requests={requests}
        requestId={editedRequest}
        userId={userId}
        timelines={timelines}
        onClose={onEditSharingModalClose}
        refetchFunction={refetch}
      />
      <CustomTable
        clickableRows={false}
        headerItems={sharingRequestHeaders}
        bodyRows={tableBody}
      />
    </>
  );
};

export default SharingRequests;
