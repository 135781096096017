import { MaterialModalFormValues } from '../../../../../../../types';
import { useCallback } from 'react';

export const useHandleRemoveRule = (
  index: number,
  values: MaterialModalFormValues,
  setValues: (data: MaterialModalFormValues) => void
) => {
  return useCallback(() => {
    setValues({
      ...values,
      rules: [...values.rules.filter((_, idx) => idx !== index)],
    });
  }, [index, setValues, values]);
};
