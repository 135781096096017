import React from 'react';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import {
  GraspColors,
  graspColorsValues,
  lighten,
} from '../../../../../../../helpers/colors';
import { IGroupFeedbackClosedListQuestionAnswer } from '../../../../../../../interfaces/caseGroups';

type GroupFeedbackClosedListChartProps = {
  answers: IGroupFeedbackClosedListQuestionAnswer[];
};

const GroupFeedbackClosedListChart = ({
  answers,
}: GroupFeedbackClosedListChartProps) => {
  const data: any[] = answers.map((answer) => ({
    name: `${answer.answerTitle}`,
    value: answer.groupParticipants ?? 0,
  }));

  const baseColor: string = graspColorsValues[GraspColors.BLUE];

  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    value,
  }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="white"
        fontSize={17}
        textAnchor={x > cx ? 'start' : 'end'}
        dominantBaseline="central"
      >
        {value}
      </text>
    );
  };

  return (
    <div className="p-5">
      <ResponsiveContainer width="100%" height={360}>
        <PieChart width={400} height={400}>
          <Legend layout="horizontal" verticalAlign="bottom" align="center" />
          <Pie
            dataKey="value"
            isAnimationActive={true}
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={150}
            fill="#8884d8"
            labelLine={false}
            label={renderCustomizedLabel}
          >
            {data.map((entry, index) => (
              <Cell key={entry} fill={lighten(baseColor, index * 25)} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GroupFeedbackClosedListChart;
