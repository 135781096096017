import { faUnlink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useLinkUsersExternalProvider from 'api/mutations/membership/useLinkUsersExternalProvider';
import useUnlinkUsersExternalProvider from 'api/mutations/membership/useUnlinkUsersExternalProvider';

//helpers
import useUserEdit from 'api/mutations/membership/useUserEdit';
import useExternalAuthentication from 'api/queries/membership/useExternalAuthentication';
import useUserData from 'api/queries/membership/useUserData';
import useUserExternalLogins from 'api/queries/membership/useUserExternalLogins';

//images
import profileIcon from 'assets/images/profile_icon.svg';
import CustomTable from 'components/CustomTable';
import FeedbackModal, {
  GraspFeedbackBodyImportanceWithNumber,
} from 'components/FeedbackModal';
import LoadingButton from 'components/LoadingButton';
import UserOrganizationSelectField from 'components/UserOrgnizationSelectField';
import { Formik } from 'formik';

//helpers
import { formatDateToInputDateString } from 'helpers/dates';
import { getUserId } from 'helpers/utils/auth';
import useOpen from 'hooks/useOpen';
import useUrlSearch from 'hooks/useUrlSearch';

//types
import { IPatchObject } from 'interfaces/request';
import { discoveryService } from 'modules/membership/Login/components/LoginForm';
import {
  AddressField,
  BirthdateField,
  FirstNameField,
  GenderField,
  LastNameField,
  PhoneNumberField,
} from 'modules/membership/Register/components/FieldDefinitions';
import React, { useEffect, useMemo, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router';
import { Button, Form, UncontrolledAlert } from 'reactstrap';
import { MyProfileTabs } from '../index';
import AccountDeletionModal from './AccountDeletionModal';

//components
import TabLayout from './TabLayout';

const VALIDATED_KEYS = ['first_name', 'last_name'];

const headerItems = [
  'MyProfile.ProfileSettings.providerName',
  'MyProfile.ProfileSettings.profileName',
  'MyProfile.ProfileSettings.unlink',
];

type ProfileSettingsTabProps = {
  userId: string;
  setSelectedTab: (tab: MyProfileTabs) => void;
};

const ProfileSettingsTab = ({
  userId,
  setSelectedTab,
}: ProfileSettingsTabProps) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const location = useLocation();

  const query = useUrlSearch();

  const loginErrorCode = query.get('error_code');
  const externalIdentityCode = query.get('external_identity');
  const externalProvider = query.get('external_provider');

  const { isOpen: isFeedbackModalOpen, toggle: toggleFeedbackModal } =
    useOpen();

  const {
    isOpen: isAccountDeletionModalOpen,
    toggle: toggleAccountDeletionModal,
  } = useOpen();

  const { data: externalLogins, refetch: externalLoginsRefetch } =
    useUserExternalLogins({ params: { userId } });

  const onLinkingSuccess = async () => {
    await externalLoginsRefetch();
    navigate('/settings/my-account');
  };

  const { data: userData, refetch: userDataRefetch } = useUserData({
    params: { userId },
  });

  const { mutate } = useLinkUsersExternalProvider(onLinkingSuccess);

  useEffect(() => {
    if (externalIdentityCode && externalProvider) {
      mutate({
        loginProvider: externalProvider,
        providerKey: externalIdentityCode,
        userId: userId,
      });
    }
  }, [externalIdentityCode, externalProvider, userId, mutate]);

  const { data: schemes } = useExternalAuthentication({
    enabled: true,
  });

  const formRef = useRef<HTMLFormElement | null>(null);

  const form = (
    <form
      style={{
        display: 'hidden',
      }}
      ref={formRef}
      method="post"
    ></form>
  );

  const initialValues = {
    first_name: userData?.personality?.first_name ?? '',
    last_name: userData?.personality?.last_name ?? '',
    email: userData?.personality.email ?? '',
    address: userData?.personality?.address ?? '',
    gender: userData?.personality?.gender ?? '',
    birthdate: userData?.personality?.birthdate
      ? formatDateToInputDateString(new Date(userData?.personality?.birthdate))
      : '',
    ssn: userData?.personality?.ssn ?? '',
    phone_number: userData?.personality.phone_number ?? '',
    default_organization_id: userData?.default_organization_id ?? '',
  };

  const onUserEditSuccess = async () => {
    await userDataRefetch();
  };

  const { mutate: updateUserMutation, isLoading } = useUserEdit(
    getUserId(),
    onUserEditSuccess
  );

  const onUnlinkSuccess = async () => {
    await externalLoginsRefetch();
  };

  const { mutate: unlinkUsersProviderMutation } =
    useUnlinkUsersExternalProvider(onUnlinkSuccess);

  const handleSubmit = (values: typeof initialValues) => {
    const keys = Object.keys(values);

    const pathObjects: IPatchObject[] = [];

    for (const key of keys) {
      if (values[key] !== initialValues[key]) {
        const path =
          key === 'default_organization_id'
            ? `/default_organization_id`
            : `/personality/${key}`;

        pathObjects.push({
          op: 'replace',
          path,
          value: values[key],
        });
      }
    }

    updateUserMutation(pathObjects);
  };

  const validate = (values: typeof initialValues) => {
    const errors = {};
    for (const key of VALIDATED_KEYS) {
      if (!values[key] || values[key].trim().length === 0) {
        errors[key] = intl.formatMessage({
          id: 'CaseCard.create.errors.inputEmpty',
        });
      }
    }

    return errors;
  };

  const onExternalLoginClick = (
    e: React.MouseEvent<HTMLButtonElement>,
    name: string
  ) => {
    e.preventDefault();

    discoveryService(() => {
      if (formRef?.current) {
        formRef.current.action = `${localStorage.getItem(
          'api_url'
        )}/api/users/external_identity/${name}?returnUrl=${encodeURIComponent(
          window.location.origin + location.pathname
        )}`;
        formRef.current.submit();
      }
    });
  };

  const bodyRows = externalLogins?.map((login, idx) => {
    const handleUnlinkProvider = () => {
      unlinkUsersProviderMutation({
        userId: userId,
        loginProvider: login.login_provider,
        providerKey: login.provider_key,
      });
    };

    return (
      <tr key={idx}>
        <td>{login.login_provider}</td>
        <td>{login.provider_display_name}</td>
        <td width={120} align="right">
          <Button color="primary" outline onClick={handleUnlinkProvider}>
            <FontAwesomeIcon icon={faUnlink} />
          </Button>
        </td>
      </tr>
    );
  });

  const customerSchemes = schemes?.filter((p) => p?.is_b2_b) ?? [];

  const renderSchemes = customerSchemes.map((scheme) => {
    const handleSelectScheme = (e: React.MouseEvent<HTMLButtonElement>) => {
      onExternalLoginClick(e, scheme.name);
    };

    return (
      <Button
        type="submit"
        className="btn-login btn-login--outline mb-2 me-2"
        key={scheme.name}
        onClick={handleSelectScheme}
      >
        {scheme.display_name}
      </Button>
    );
  });

  const feedbackModalInitialValues: Partial<GraspFeedbackBodyImportanceWithNumber> =
    useMemo(
      () => ({
        category: 'Bug',
        description: `${intl.formatMessage({
          id: 'Login.LoginForm.errorCode.name',
        })} ${loginErrorCode}`,
        importance: 2,
        email_contact: '',
        subject: intl.formatMessage({
          id: 'Login.LoginForm.errorCode.message',
        }),
      }),
      [loginErrorCode, intl]
    );

  const handleAccountDeletion = () => {
    toggleAccountDeletionModal();
  };

  const handleGoToDataDump = () => {
    setSelectedTab(MyProfileTabs.GENERATE_DATA_DUMP);
  };

  if (!userData?.personality) return null;
  return (
    <>
      <AccountDeletionModal
        setSelectedTab={setSelectedTab}
        isOpen={isAccountDeletionModalOpen}
        toggle={toggleAccountDeletionModal}
      />
      <FeedbackModal
        isOpen={isFeedbackModalOpen}
        toggle={toggleFeedbackModal}
        initialValues={feedbackModalInitialValues}
      />
      {loginErrorCode && (
        <UncontrolledAlert className="LoginErrorAlert mt-0" color="danger">
          <div className="LoginErrorAlert__content">
            <div className="LoginErrorAlert__content__message">
              <FormattedMessage id="Login.LoginForm.errorCode.message" />
            </div>
            <div className="LoginErrorAlert__content__code">
              <FormattedMessage id="Login.LoginForm.errorCode.name" />
              <span className="ms-1">{loginErrorCode}</span>
            </div>
            <div className="LoginErrorAlert__content__report-issue">
              <FormattedMessage id="Login.LoginForm.errorCode.reportIssue.message" />
              <div className="alert-link ms-1" onClick={toggleFeedbackModal}>
                <FormattedMessage id="Login.LoginForm.errorCode.reportIssue.clickHere" />
              </div>
            </div>
          </div>
        </UncontrolledAlert>
      )}
      <TabLayout
        title="MyProfile.ProfileSettings.profile"
        imageSrc={profileIcon}
      >
        <Formik
          enableReinitialize
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validate={validate}
        >
          {(form) => (
            <Form>
              <FirstNameField formik={form} isGray />
              <LastNameField formik={form} isGray />
              <BirthdateField formik={form} isGray />
              <AddressField formik={form} isGray />
              <PhoneNumberField formik={form} isGray />
              <GenderField formik={form} isGray displayUnspecified />
              <UserOrganizationSelectField
                name="default_organization_id"
                labelTranslationId="MyProfile.defaultOrganization"
                organizations={userData.organizations}
              />
              <div className="d-flex justify-content-start mt-2">
                <div className="d-flex flex-column">
                  <span
                    className="MyAccount__account-deletion-link"
                    onClick={handleAccountDeletion}
                  >
                    <FormattedMessage id="MyProfile.AccountDeletion.deleteImmediately" />
                  </span>
                  <span
                    className="MyAccount__account-deletion-link"
                    onClick={handleGoToDataDump}
                  >
                    <FormattedMessage id="MyProfile.DataDumpGeneration.downloadMyData" />
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-end mt-4">
                <LoadingButton
                  disabled={!form.isValid}
                  onClick={form.submitForm}
                  isLoading={isLoading}
                  type="submit"
                >
                  <FormattedMessage id="General.saveChanges" />
                </LoadingButton>
              </div>
            </Form>
          )}
        </Formik>
      </TabLayout>
      <div className="mt-4">
        <TabLayout
          title="MyProfile.ProfileSettings.externalProviders"
          imageSrc={profileIcon}
        >
          <>
            <CustomTable headerItems={headerItems} bodyRows={bodyRows ?? []} />
            {customerSchemes && (
              <div className="AuthenticationSchemes">
                {form}
                <span className="d-block AuthenticationSchemes__header mb-2">
                  Link account with external provider:
                </span>
                <div className="AuthenticationSchemes__schemes ms-2">
                  {renderSchemes}
                </div>
              </div>
            )}
          </>
        </TabLayout>
      </div>
    </>
  );
};

export default ProfileSettingsTab;
