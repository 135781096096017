import { useEffect } from 'react';
import { useTimer } from '../../modules/ExerciseSession/hooks/useTimer';

type FetchingDotsProps = {
  color: string;
  amount?: number;
  interval?: number;
};

const FetchingDots = ({
  color,
  amount = 3,
  interval = 1000,
}: FetchingDotsProps) => {
  const { count, play } = useTimer(interval, amount + 1);

  useEffect(() => {
    play();
  }, [play]);

  return (
    <span style={{ color: color }}>
      {[...new Array(count % (amount + 1))].map((_) => '.')}
    </span>
  );
};

export default FetchingDots;
