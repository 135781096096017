import { IMutationProps } from '../../../interfaces/ui';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { deleteOrganizationObservationMeaning } from '../../observationMeanings';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { IErrorDefinition } from '../../../interfaces/response';

type useRemoveObservationMeaningParams = {
  organizationId: string;
};

export const useRemoveObservationMeaning = (
  params: useRemoveObservationMeaningParams,
  options: IMutationProps
) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (meaningId: string) => {
      await deleteOrganizationObservationMeaning(
        params.organizationId,
        meaningId
      );
    },
    onSuccess: () => {
      if (options.successFb) options.successFb();

      displaySuccessNotification(
        'Api.Mutations.ObservationMeanings.RemoveObservationMeaning.success'
      );
    },
    onErrorArray: (errors: IErrorDefinition[]) => {
      if (options.errorFb) options.errorFb();

      displayErrorArrayNotifications(
        errors,
        'Api.Mutations.ObservationMeanings.RemoveObservationMeaning.failure'
      );
    },
    resetOnResult: true,
  });
};
