import React from 'react';
import {
  ConversationTemplate,
  ConversationTemplateContext,
  ConversationTemplateTriggerType,
} from '../../../../../../../../interfaces/conversations';
import useConversationTemplates from '../../../../../../../../api/queries/conversations/useConversationTemplates';
import { useOrganizationPathParams } from '../../../../../../../../hooks/paths/useOrganizationPathParams';
import { useInitQuery } from '../../../../../../../../hooks/queries/useInitQuery';
import QueryLoaded from '../../../../../../../../components/QueryLoaded';
import { FormattedMessage } from 'react-intl';

type ConversationTemplateProviderProps = {
  children: (template: ConversationTemplate) => JSX.Element;
};

const ConversationTemplateProvider = ({
  children,
}: ConversationTemplateProviderProps) => {
  const { organizationId } = useOrganizationPathParams();

  const query = useConversationTemplates({
    params: {
      organizationId,
      context: ConversationTemplateContext.Form,
      triggerType: ConversationTemplateTriggerType.AwareSession,
    },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(query);

  return (
    <QueryLoaded query={query}>
      {(templates) => (
        <>
          {templates.length !== 1 ? (
            <FormattedMessage id="QueryLoaded.Error" />
          ) : (
            children(templates[0])
          )}
        </>
      )}
    </QueryLoaded>
  );
};

export default ConversationTemplateProvider;
