import classNames from 'classnames';
import { TestIds } from 'test/testIds';

type CarrouselControllerProps = {
  numberOfItems: number;
  activeIndex: number;
  onSelect: (index: number) => void;
  rowDirection?: boolean;
  center?: boolean;
};

const CarrouselController = ({
  numberOfItems,
  activeIndex,
  onSelect,
  rowDirection,
  center,
}: CarrouselControllerProps) => {
  const renderedItems = [...new Array(numberOfItems)].map((el, index) => {
    const isActive = index === activeIndex;

    return (
      <div
        key={`carousel-item-${index}`}
        className={`CarrouselController__item ${
          isActive ? 'CarrouselController__item--active' : ''
        }`}
        onClick={() => onSelect(index)}
      />
    );
  });

  return (
    <div
      data-testid={TestIds.Carousel}
      className={classNames('CarrouselController', {
        'CarrouselController--row-direction': rowDirection,
        'CarrouselController--center': center,
      })}
    >
      {renderedItems}
    </div>
  );
};

export default CarrouselController;
