import React from 'react';
import { PopoverBody, UncontrolledPopover } from 'reactstrap';
import SVG from 'react-inlinesvg';
import { FormattedMessage, useIntl } from 'react-intl';

import chartsIcon from 'assets/images/timeline_icon.svg';
import deleteBlueIcon from 'assets/images/delete_blue_icon.svg';

type TrendVisualizationsSavedViewsListItemActionsProps = {
  targetId: string;
  onEditClick: () => void;
  onDeleteClick: () => void;
};

const SavedViewsItemActions = ({
  targetId,
  onEditClick,
  onDeleteClick,
}: TrendVisualizationsSavedViewsListItemActionsProps) => {
  const intl = useIntl();

  return (
    <UncontrolledPopover
      className="TrendVisualizationsSavedViewsListItemActions"
      placement="top"
      target={targetId}
    >
      <PopoverBody className="TrendVisualizationsSavedViewsListItemActions__actions-container">
        <div
          className="TrendVisualizationsSavedViewsListItemActions__action"
          onClick={onEditClick}
        >
          <SVG
            src={chartsIcon}
            title={intl.formatMessage({
              id: 'MyOrganizations.trendVisualizations.editSavedView',
            })}
          />
          <FormattedMessage id="MyOrganizations.trendVisualizations.editSavedView" />
        </div>
        <div
          className="TrendVisualizationsSavedViewsListItemActions__action"
          onClick={onDeleteClick}
        >
          <SVG
            src={deleteBlueIcon}
            title={intl.formatMessage({
              id: 'MyOrganizations.trendVisualizations.deleteView',
            })}
          />
          <FormattedMessage id="MyOrganizations.trendVisualizations.deleteView" />
        </div>
      </PopoverBody>
    </UncontrolledPopover>
  );
};

export default SavedViewsItemActions;
