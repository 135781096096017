import { useCallback } from 'react';
import {
  ConversationScheduleType,
  ICaseGroupConversationSchedule,
  IRecurringCaseGroupConversationSchedule,
  ISingleInstanceCaseGroupConversationSchedule,
} from '../../../../../../../../../interfaces/conversationSchedules';
import { ICaseGroupSchedule } from '../../../../../../../../../interfaces/caseGroups';
import { differenceInCalendarDays } from 'date-fns';
import { ScheduleItemDefinition } from '../../../../../../../../../components/Schedule';
import { usePrepareSchedulesUtils } from './usePrepareSchedulesUtils';
import { cloneDeep } from 'lodash';
import { getDayOffset } from '../../../../../../../../../helpers/utils/dates';
import { toDateOnly } from '../../../../../../../../../helpers/dates';

export const usePrepareSchedulesPayload = (
  setSchedule: (schedule: ICaseGroupConversationSchedule | null) => void,
  setResponsesId: (responsesId: string | null) => void,
  toggleEditModal: () => void,
  toggleResponses: () => void
) => {
  const { getSingleInstancePartOfADay } = usePrepareSchedulesUtils(
    setSchedule,
    setResponsesId,
    toggleEditModal,
    toggleResponses
  );

  const prepareOnce = useCallback(
    (
      currentSchedule: ICaseGroupConversationSchedule,
      selectedDate: Date,
      index: number
    ): ScheduleItemDefinition => {
      const onceSchedule = cloneDeep(currentSchedule);

      let onResponse: (() => void) | undefined = undefined;

      if (toDateOnly(selectedDate) < toDateOnly(new Date())) {
        onResponse = () => {
          setResponsesId(currentSchedule.id);
          toggleResponses();
        };
      }

      return {
        rowId: onceSchedule.id,
        sequence: index,
        priority: onceSchedule.priority,
        templateId: onceSchedule.template_id,
        scheduleTemplate: {
          schedule_type: currentSchedule.type,
          start_day_offset: null,
          specific_day_of_phase: null,
          parts_of_a_day: [],
          end_day_offset: null,
          recurring_interval: null,
        },
        onEdit: () => {
          setSchedule(currentSchedule);
          toggleEditModal();
        },
        onResponses: onResponse,
      };
    },
    [setResponsesId, setSchedule, toggleEditModal, toggleResponses]
  );

  const prepareSimpleSingleInstance = useCallback(
    (
      currentSchedule: ICaseGroupConversationSchedule,
      phaseGroupSchedule: ICaseGroupSchedule,
      selectedDate: Date,
      index: number
    ): ScheduleItemDefinition => {
      const singleInstanceSchedule =
        currentSchedule as ISingleInstanceCaseGroupConversationSchedule;

      const dayOffset = phaseGroupSchedule
        ? differenceInCalendarDays(
            new Date(singleInstanceSchedule.date),
            new Date(phaseGroupSchedule.date_from)
          )
        : 1;

      let onResponse: (() => void) | undefined = undefined;

      if (toDateOnly(selectedDate) < toDateOnly(new Date())) {
        onResponse = () => {
          setResponsesId(currentSchedule.id);
          toggleResponses();
        };
      }

      return {
        rowId: singleInstanceSchedule.id,
        sequence: index,
        priority: singleInstanceSchedule.priority,
        templateId: singleInstanceSchedule.template_id,
        scheduleTemplate: {
          schedule_type: ConversationScheduleType.SingleInstance,
          recurring_interval: null,
          start_day_offset: null,
          end_day_offset: null,
          specific_day_of_phase: dayOffset,
          parts_of_a_day: [
            {
              available_to_hour: singleInstanceSchedule.available_to_hour,
              available_from_hour: singleInstanceSchedule.available_from_hour,
              notification_hour: singleInstanceSchedule.notification_hour,
              completion_percent: singleInstanceSchedule.completion_percent,
              is_dirty: false,
              is_deleted: false,
              onEdit: () => {
                setSchedule(
                  singleInstanceSchedule as ICaseGroupConversationSchedule
                );
                toggleEditModal();
              },
              onResponses: onResponse,
            },
          ],
        },
      };
    },
    [setResponsesId, setSchedule, toggleEditModal, toggleResponses]
  );

  const prepareRecurredSingleInstance = useCallback(
    (
      currentSchedule: ICaseGroupConversationSchedule,
      recurringSchedule: ICaseGroupConversationSchedule,
      phaseGroupSchedule: ICaseGroupSchedule,
      selectedDate: Date,
      index: number
    ): ScheduleItemDefinition => {
      const singleInstanceSchedule =
        currentSchedule as ISingleInstanceCaseGroupConversationSchedule;

      const singleInstancePartOfADay = getSingleInstancePartOfADay(
        currentSchedule,
        recurringSchedule,
        selectedDate
      );

      const startDayOffset = getDayOffset(
        phaseGroupSchedule.date_from,
        (recurringSchedule as IRecurringCaseGroupConversationSchedule).date_from
      );

      const endDayOffset = getDayOffset(
        phaseGroupSchedule.date_from,
        (recurringSchedule as IRecurringCaseGroupConversationSchedule).date_to
      );

      return {
        rowId: singleInstanceSchedule.recurring_origin_id!,
        sequence: index,
        priority: singleInstanceSchedule.priority,
        templateId: singleInstanceSchedule.template_id,
        scheduleTemplate: {
          schedule_type: ConversationScheduleType.Recurring,
          recurring_interval: recurringSchedule.recurring_interval,
          specific_day_of_phase: null,
          parts_of_a_day: [singleInstancePartOfADay],
          start_day_offset: startDayOffset,
          end_day_offset: endDayOffset,
        },
        onAdd: () => {
          setSchedule({
            ...recurringSchedule,
            available_from_hour: '00:00',
            available_to_hour: '00:00',
            notification_hour: '00:00',
          });
          toggleEditModal();
        },
      };
    },
    [getSingleInstancePartOfADay, setSchedule, toggleEditModal]
  );

  return {
    prepareOnce: prepareOnce,
    prepareSimpleSingleInstance: prepareSimpleSingleInstance,
    prepareRecurredSingleInstance: prepareRecurredSingleInstance,
  };
};
