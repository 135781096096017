import { ICaseDetails, ICasePhaseHistory } from 'interfaces/cases';
import React, { useMemo } from 'react';
import PeriodRangeSelectionItem from '../components/PeriodRangeSelectionItem';

export const useRenderPeriodRangeItems = (
  caseDetails: ICaseDetails | undefined,
  casePhaseHistory: ICasePhaseHistory[],
  selectedHistory: ICasePhaseHistory[]
) => {
  let caseClosingDate =
    caseDetails?.status === 'Closed' ? caseDetails?.updated_at : null;

  return useMemo(() => {
    return casePhaseHistory.map((historyItem, index) => {
      if (caseClosingDate && index === casePhaseHistory.length - 1)
        return (
          <PeriodRangeSelectionItem
            itemsCount={casePhaseHistory.length}
            key={`${historyItem.display_code}|${historyItem.date_from}|${caseClosingDate}`}
            item={{
              ...historyItem,
              date_to: caseClosingDate,
            }}
            selectedItemsBasedOnDateRange={selectedHistory}
            index={index}
            forceActive={caseDetails?.status === 'Closed'}
          />
        );

      return (
        <PeriodRangeSelectionItem
          itemsCount={casePhaseHistory.length}
          key={`${historyItem.display_code}|${historyItem.date_from}|${historyItem.date_to}`}
          item={historyItem}
          selectedItemsBasedOnDateRange={selectedHistory}
          index={index}
          forceActive={caseDetails?.status === 'Closed'}
        />
      );
    });
  }, [caseClosingDate, caseDetails?.status, casePhaseHistory, selectedHistory]);
};
