import {
  ScheduleItemDefinition,
  ScheduleItemTemplatePartsOfADayDefinition,
} from 'components/Schedule';
import { strToDateOnly, toDateOnly } from 'helpers/dates';
import { getCurrentCasePhaseHistoryItems } from 'helpers/utils/casePhaseHistory';
import { getDayOffset } from 'helpers/utils/dates';
import { ICasePhaseHistory } from 'interfaces/cases';
import {
  ConversationScheduleType,
  ICaseConversationSchedule,
  IRecurringCaseConversationSchedule,
  ISingleInstanceCaseConversationSchedule,
} from 'interfaces/conversationSchedules';
import { cloneDeep } from 'lodash';
import React, { useCallback } from 'react';
import { usePrepareSchedulesUtils } from './usePrepareSchedulesUtils';

export const usePrepareSchedulesPayload = (
  setSchedule: React.Dispatch<
    React.SetStateAction<ICaseConversationSchedule | null>
  >,
  toggle: () => void
) => {
  const { getSingleInstancePartOfADay } = usePrepareSchedulesUtils(
    setSchedule,
    toggle
  );

  const prepareBasic = (
    current: ICaseConversationSchedule,
    index: number,
    disabled: boolean
  ): ScheduleItemDefinition => {
    const basicSchedule = cloneDeep(current);

    return {
      rowId: basicSchedule.id,
      sequence: index,
      priority: basicSchedule.priority,
      templateId: basicSchedule.template_id,
      isCompleted: basicSchedule.is_completed,
      scheduleTemplate: {
        schedule_type: basicSchedule.type,
        start_day_offset: null,
        specific_day_of_phase: null,
        parts_of_a_day: [],
        end_day_offset: null,
        recurring_interval: null,
      },
      onEdit: () => {
        setSchedule(current);
        toggle();
      },
      disabled: disabled,
    };
  };

  const getSimpleSingleInstanceDefinition = useCallback(
    (
      singleInstance: ISingleInstanceCaseConversationSchedule,
      dayOffset: number,
      index: number,
      disabled: boolean
    ) => {
      return {
        rowId: singleInstance.id,
        sequence: index,
        priority: singleInstance.priority,
        templateId: singleInstance.template_id,
        scheduleTemplate: {
          schedule_type: ConversationScheduleType.SingleInstance,
          recurring_interval: null,
          start_day_offset: null,
          end_day_offset: null,
          specific_day_of_phase: dayOffset,
          parts_of_a_day: [
            {
              available_to_hour: singleInstance.available_to_hour,
              available_from_hour: singleInstance.available_from_hour,
              notification_hour: singleInstance.notification_hour,
              is_completed: singleInstance.is_completed,
              is_dirty: false,
              is_deleted: false,
              onEdit: () => {
                setSchedule(singleInstance as ICaseConversationSchedule);
                toggle();
              },
              disabled: disabled,
            },
          ],
        },
        disabled: disabled,
      };
    },
    [setSchedule, toggle]
  );

  const prepareSimpleSingleInstance = useCallback(
    (
      current: ICaseConversationSchedule,
      casePhaseHistory: ICasePhaseHistory[],
      selectedDate: string,
      disabled: boolean,
      index: number
    ): ScheduleItemDefinition => {
      const singleInstance = current as ISingleInstanceCaseConversationSchedule;

      const currentCasePhaseHistoryItems = getCurrentCasePhaseHistoryItems(
        casePhaseHistory,
        selectedDate
      );

      let dayOffset: number = 1;

      if (currentCasePhaseHistoryItems.length === 0)
        return getSimpleSingleInstanceDefinition(
          singleInstance,
          dayOffset,
          index,
          disabled
        );

      if (currentCasePhaseHistoryItems.length === 1) {
        const normalizedUtc = singleInstance.utc_date_time.slice(
          0,
          singleInstance.utc_date_time.indexOf('+')
        );

        dayOffset = getDayOffset(
          strToDateOnly(currentCasePhaseHistoryItems[0].date_from),
          strToDateOnly(normalizedUtc)
        );

        return getSimpleSingleInstanceDefinition(
          singleInstance,
          dayOffset,
          index,
          disabled
        );
      }

      for (const item of currentCasePhaseHistoryItems) {
        const normalizedUtc = singleInstance.utc_date_time.slice(
          0,
          singleInstance.utc_date_time.indexOf('+')
        );

        if (
          (!item.date_to && normalizedUtc >= item.date_from) ||
          (item.date_to &&
            normalizedUtc >= item.date_from &&
            normalizedUtc <= item.date_from)
        ) {
          dayOffset = getDayOffset(
            strToDateOnly(item.date_from),
            strToDateOnly(normalizedUtc)
          );
          break;
        }
      }

      return getSimpleSingleInstanceDefinition(
        singleInstance,
        dayOffset,
        index,
        disabled
      );
    },
    [getSimpleSingleInstanceDefinition]
  );

  const getRecurredSingleInstance = useCallback(
    (
      singleInstance: ISingleInstanceCaseConversationSchedule,
      recurring: ICaseConversationSchedule,
      partOfADay: ScheduleItemTemplatePartsOfADayDefinition,
      startDayOffset: number,
      endDayOffset: number,
      index: number,
      disabled: boolean
    ) => {
      return {
        rowId: singleInstance.recurring_origin_id!,
        sequence: index,
        priority: singleInstance.priority,
        templateId: singleInstance.template_id,
        scheduleTemplate: {
          schedule_type: ConversationScheduleType.Recurring,
          recurring_interval: recurring.recurring_interval,
          specific_day_of_phase: null,
          parts_of_a_day: [partOfADay],
          start_day_offset: startDayOffset,
          end_day_offset: endDayOffset,
        },
        onAdd: () => {
          setSchedule({
            ...recurring,
            available_from_hour: '00:00',
            available_to_hour: '00:00',
            notification_hour: '00:00',
          });
          toggle();
        },
        disabled: disabled,
      };
    },
    [setSchedule, toggle]
  );

  const getRecurredDayOffsets = (
    casePhaseHistory: ICasePhaseHistory[],
    dateStr: string,
    recurring: ICaseConversationSchedule,
    utcDateTime: string
  ) => {
    let startOffset = 1;
    let endOffset = 1;

    const recurringSchedule = recurring as IRecurringCaseConversationSchedule;

    const historyFiltered = getCurrentCasePhaseHistoryItems(
      casePhaseHistory,
      dateStr
    );

    switch (historyFiltered.length) {
      case 0:
        break;
      case 1:
        startOffset = getDayOffset(
          strToDateOnly(historyFiltered[0].date_from),
          recurringSchedule.date_from
        );
        endOffset = recurringSchedule.date_to
          ? getDayOffset(
              strToDateOnly(historyFiltered[0].date_from),
              recurringSchedule.date_to
            )
          : 1;
        break;
      default:
        for (const item of historyFiltered) {
          const normalizedUtc = utcDateTime.slice(0, utcDateTime.indexOf('+'));

          if (
            (!item.date_to && normalizedUtc >= item.date_from) ||
            (item.date_to &&
              normalizedUtc >= item.date_from &&
              normalizedUtc <= item.date_from)
          ) {
            startOffset = getDayOffset(item.date_from, dateStr);
            endOffset = item.date_to ? getDayOffset(item.date_to, dateStr) : 1;
            break;
          }
        }
    }

    return {
      startOffset,
      endOffset,
    };
  };

  const prepareRecurredSingleInstance = useCallback(
    (
      current: ICaseConversationSchedule,
      recurring: ICaseConversationSchedule,
      casePhaseHistory: ICasePhaseHistory[],
      selectedDate: string,
      index: number
    ): ScheduleItemDefinition => {
      const singleInstance = current as ISingleInstanceCaseConversationSchedule;

      const singleInstancePartOfADay = getSingleInstancePartOfADay(
        current,
        recurring
      );

      let startDayOffset = getRecurredDayOffsets(
        casePhaseHistory,
        selectedDate,
        recurring,
        singleInstance.utc_date_time
      ).startOffset;

      let endDayOffset = getRecurredDayOffsets(
        casePhaseHistory,
        selectedDate,
        recurring,
        singleInstance.utc_date_time
      ).endOffset;

      return getRecurredSingleInstance(
        singleInstance,
        recurring,
        singleInstancePartOfADay,
        startDayOffset,
        endDayOffset,
        index,
        selectedDate < toDateOnly(new Date())
      );
    },
    [getRecurredSingleInstance, getSingleInstancePartOfADay]
  );

  return {
    prepareBasic: prepareBasic,
    prepareSimpleSingleInstance: prepareSimpleSingleInstance,
    prepareRecurredSingleInstance: prepareRecurredSingleInstance,
  };
};
