export namespace DashboardColorsHelpers {
  export const GetColorForSeries = (
    colorScheme: string[],
    index: number,
    isSelected?: boolean
  ) => {
    if (colorScheme.length === 0) return '#000';

    if (isSelected === undefined)
      return colorScheme[index % colorScheme.length];

    return isSelected
      ? colorScheme[index % colorScheme.length]
      : `${colorScheme[index % colorScheme.length]}66`;
  };
}
