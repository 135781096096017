import React, { useState } from 'react';
import CustomTable from 'components/CustomTable';
import { IInfiniteGraspOrganization } from 'interfaces/grasps';
import { Size } from 'interfaces/ui';
import InfiniteScroll from 'react-infinite-scroller';
import { Spinner } from 'reactstrap';
import useDeleteGraspFromOrganization from 'api/mutations/grasps/useDeleteGraspFromOrganization';
import { FormattedMessage, useIntl } from 'react-intl';
import ConfirmModal from 'components/ConfirmModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { iconColors } from 'helpers/colors';
import IndeterminateCheckbox from 'components/IndeterminateCheckbox';
import GraspLogo from 'components/GraspLogo';
import { formatDate } from 'helpers/dates';
import classNames from 'classnames';

interface GraspListInOrganizationTabProps {
  onEditClick: (editedGrasp: IInfiniteGraspOrganization) => void;
  rowSelectionProps;
  organizationId: string;
  refetch: () => void;
  hasMore: boolean;
  grasps: IInfiniteGraspOrganization[];
  fetchNextPage: () => void;
}

const GraspListInOrganizationTab = ({
  rowSelectionProps,
  onEditClick,
  organizationId,
  refetch,
  hasMore,
  grasps,
  fetchNextPage,
}: GraspListInOrganizationTabProps) => {
  const intl = useIntl();
  const [graspIdToDelete, setGraspIdToDelete] = useState<null | string>(null);
  const onCloseModal = () => setGraspIdToDelete(null);
  const onDeleteSuccess = () => refetch();

  const { mutate: deleteMutation } = useDeleteGraspFromOrganization(
    organizationId,
    {
      successFb: onDeleteSuccess,
    }
  );

  const onDeleteConfirm = () => {
    graspIdToDelete && deleteMutation(graspIdToDelete);
    onCloseModal();
  };

  const headerItems = [
    <IndeterminateCheckbox
      key={'grasp-list-in-organization-tab-checkbox'}
      checked={rowSelectionProps.isSelectAllChecked}
      indeterminate={rowSelectionProps.isSelectAllIndeterminate}
      onChange={rowSelectionProps.toggleAllRows}
    />,
    'OrganizationGrasps.color',
    'OrganizationGrasps.deviceName',
    'OrganizationGrasps.status',
    'OrganizationGrasps.prescribedTo',
    'OrganizationGrasps.prescribedDate',
    'OrganizationGrasps.createdAt',
    'OrganizationGrasps.batteryLevel',
    'OrganizationGrasps.lastBatterySync',
    'OrganizationGrasps.endpoint',
    'OrganizationGrasps.serialNumber',
    'OrganizationGrasps.physicalGraspUuid',
  ];

  const bodyRows = grasps.map((graspData) => {
    const onToggleRowInGrasp = () => {
      rowSelectionProps.toggleRow({ id: graspData.grasp_id });
    };

    const handleEditClick = () => {
      if (!onEditClick) return;
      onEditClick(graspData);
    };

    return (
      <tr key={graspData.id}>
        <td>
          <input
            type="checkbox"
            checked={rowSelectionProps.isRowSelected(graspData.grasp_id)}
            onChange={onToggleRowInGrasp}
          />
        </td>
        <td>
          <GraspLogo size={Size.SMALL} color={graspData.hex_color} width={45} />
        </td>
        <td>
          <b>{graspData.name || '--'}</b>
        </td>
        <td>
          <FormattedMessage
            id={`MyOrganizations.grasps.statuses.${graspData.status}`}
          />
        </td>
        <td>
          {' '}
          {graspData.patient
            ? `${graspData.patient.first_name} ${graspData.patient.last_name}`
            : '--'}
        </td>
        <td>
          {graspData.patient?.prescription_date
            ? formatDate(graspData.patient.prescription_date)
            : '--'}
        </td>
        <td>
          {graspData.created_at ? formatDate(graspData.created_at) : '--'}
        </td>
        <td
          className={classNames({
            'row-warning-color':
              graspData.battery_last_synchronization &&
              graspData.battery_level &&
              graspData.battery_warning_level &&
              graspData.battery_level < graspData.battery_warning_level,
          })}
        >
          {graspData.battery_level || '--'}
        </td>
        <td>
          {graspData.battery_last_synchronization
            ? formatDate(graspData.battery_last_synchronization.toString())
            : '--'}
        </td>
        <td>{graspData.location_name}</td>
        <td>{graspData.serial_number}</td>
        <td>{graspData.physical_grasp_uuid}</td>
        <td>
          <>
            <button
              aria-label="delete grasp"
              className="btn-transparent"
              onClick={() => setGraspIdToDelete(graspData.grasp_id)}
            >
              <FontAwesomeIcon icon={faTrash} color={iconColors.delete} />
            </button>
            <button
              aria-label="edit grasp"
              className="btn-transparent"
              onClick={handleEditClick}
            >
              <FontAwesomeIcon icon={faEdit} color={iconColors.edit} />
            </button>
          </>
        </td>
      </tr>
    );
  });

  const isConfirmDeleteOpen = !!graspIdToDelete;

  return (
    <>
      <ConfirmModal
        isOpen={isConfirmDeleteOpen}
        confirm={onDeleteConfirm}
        toggle={onCloseModal}
        message={intl.formatMessage({
          id: 'MyOrganizations.grasps.deleteMessage',
        })}
      />
      <InfiniteScroll
        loadMore={fetchNextPage}
        hasMore={hasMore}
        loader={<Spinner aria-label="loading" key="infinite-loader" />}
        threshold={100}
        initialLoad={false}
      >
        <CustomTable
          id="grasp-table"
          isResponsive={true}
          headerItems={headerItems}
          bodyRows={bodyRows}
          responsiveConfig={[
            {
              breakpoint: 999,
              hiddenColumns: [3, 5, 6, 7, 8, 9],
            },
            {
              breakpoint: 576,
              hiddenColumns: [3, 5, 6, 7, 8, 9, 10, 11],
            },
          ]}
        />
      </InfiniteScroll>
    </>
  );
};

export default GraspListInOrganizationTab;
