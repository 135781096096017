import { flatten } from 'flat';
import { useMemo, useState } from 'react';
import useCultures, {
  AvailableCulture,
} from '../../../../../../../../../../../../../hooks/cultures/useCultures';
import { returnFormattedLang } from '../../../../../../../../../../../../../translations/utils/cultures';
import { translations } from '../../../../../../../../../../../../../translations/utils/LanguageProvider';

export const useHandleCultures = (defaultCulture?: string) => {
  const {
    currentCulture,
    flagCodes,
    flagLabels,
    cultures,
    getAvailableCulture,
  } = useCultures();

  const selectedCulture = getAvailableCulture(
    defaultCulture ?? currentCulture.cultureCode
  );

  const [languageContext, setLanguageContext] = useState<AvailableCulture>(
    selectedCulture ?? currentCulture
  );

  const messages = useMemo(() => {
    return flatten(
      translations[returnFormattedLang(languageContext.cultureCode)]
    ) as Record<string, any>;
  }, [languageContext.cultureCode]);

  const countryCodeBasedOnContext = useMemo(() => {
    const availableCulture = cultures.find(
      (p) => p.culture === languageContext.culture
    );

    if (availableCulture === undefined) return currentCulture.upperCountryCode;

    return availableCulture.upperCountryCode;
  }, [cultures, currentCulture.upperCountryCode, languageContext.culture]);

  const handleChangeContext = (countryCode: string) => {
    const availableCulture = cultures.find(
      (p) => p.upperCountryCode === countryCode
    );

    if (availableCulture === undefined) return;

    setLanguageContext(availableCulture);
  };

  return {
    messages,
    countryCodeBasedOnContext,
    handleChangeContext,
    flagCodes,
    flagLabels,
    languageContext,
  };
};
