import { ObservationListProps } from 'interfaces/ui';
import { useParams } from 'react-router';
import CaseObservationList from './CaseObservationList';
import TimelineObservationList from './TimelineObservationList';

const ObservationList = (props: ObservationListProps) => {
  const { organizationId, caseId } = useParams<{
    organizationId: string;
    caseId: string;
  }>();

  if (organizationId && caseId)
    return (
      <CaseObservationList
        {...props}
        caseId={caseId}
        organizationId={organizationId}
      />
    );

  return <TimelineObservationList {...props} />;
};

export default ObservationList;
