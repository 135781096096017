import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { pushCasesToTreatmentPhase } from '../../conversations';

export type PushCasesToConversationPhaseData = {
  caseSubCategoryId: string;
  cases: string[];
};

const usePushCasesToTreatmentPhase = (
  organizationId: string,
  caseCategoryId: string,
  successFb?: () => void
) => {
  return useMutationWithResultNotification({
    onErrorArray: (_) => {
      displayErrorNotification(
        'Api.Mutations.TreatmentPhases.PushCasesToTreatmentPhase.failure'
      );
    },
    onSuccess: () => {
      displaySuccessNotification(
        'Api.Mutations.TreatmentPhases.PushCasesToTreatmentPhase.success'
      );

      if (!successFb) return;
      successFb();
    },
    asyncApiFunction: async (data: PushCasesToConversationPhaseData) => {
      await pushCasesToTreatmentPhase(
        organizationId,
        caseCategoryId,
        data.caseSubCategoryId,
        data.cases
      );
    },
    resetOnResult: true,
  });
};

export default usePushCasesToTreatmentPhase;
