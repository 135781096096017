import { useGetCultureIcon } from 'hooks/cultures/useGetCultureIcon';
import { InputOption } from 'interfaces/ui';
import { useMemo } from 'react';
import { AVAILABLE_CULTURES } from '../../components/CultureSelector/CultureSelector';
import { getCulture } from '../../translations/utils/cultures';
import languageDictionary from '../../translations/utils/languageDictionary';

export type AvailableCulture = {
  culture: AVAILABLE_CULTURES;
  cultureCode: string;
  countryCode: string;
  translationCode: string;
  upperCountryCode: string;
  icon: JSX.Element;
};

const invariantCultureCode = (code: string) =>
  code.replace('-', '').toLowerCase();

const useCultures = () => {
  const getIcon = useGetCultureIcon();

  const availableCultures: AvailableCulture[] = useMemo(() => {
    return [
      {
        culture: AVAILABLE_CULTURES.EN,
        cultureCode: 'en-US',
        countryCode: 'us',
        translationCode: 'en',
        upperCountryCode: 'US',
        icon: getIcon('en-US'),
      },
      {
        culture: AVAILABLE_CULTURES.NO,
        cultureCode: 'nb-NO',
        countryCode: 'no',
        translationCode: 'no',
        upperCountryCode: 'NO',
        icon: getIcon('nb-NO'),
      },
    ];
  }, [getIcon]);

  const currentCulture = useMemo(() => {
    return languageDictionary.no.includes(getCulture())
      ? availableCultures[1]
      : availableCultures[0];
  }, [availableCultures]);

  const availableFlagCodes = availableCultures.map(
    (culture) => culture.upperCountryCode
  );

  const availableFlagLabels = useMemo(() => {
    let labels: Record<string, string> = {};

    availableCultures.forEach((definition) => {
      labels[definition.upperCountryCode] = definition.cultureCode;
    });

    return labels;
  }, [availableCultures]);

  const availableCulturesLabels = useMemo(() => {
    return availableCultures.reduce(
      (obj, current) =>
        Object.defineProperty(obj, current.upperCountryCode, {
          value: current.cultureCode,
        }),
      {}
    );
  }, [availableCultures]);

  const availableCulturesOptions = useMemo((): InputOption[] => {
    return availableCultures.map((category) => {
      return {
        value: category.cultureCode,
        text: category.cultureCode,
        icon: getIcon(category.cultureCode),
      };
    });
  }, [availableCultures, getIcon]);

  const getAvailableCulture = (
    cultureCode: string
  ): AvailableCulture | undefined => {
    const preparedCultureCode = invariantCultureCode(cultureCode);

    return availableCultures.find(
      (p) => invariantCultureCode(p.cultureCode) === preparedCultureCode
    );
  };

  return {
    cultures: availableCultures,
    labels: availableCulturesLabels,
    options: availableCulturesOptions,
    currentCulture: currentCulture,
    flagCodes: availableFlagCodes,
    flagLabels: availableFlagLabels,
    defaultCulture: availableCultures[0],
    getAvailableCulture: getAvailableCulture,
  };
};

export default useCultures;
