import { getPersonalityWithId } from 'api/patients';
import QueryKeys from 'api/queries/queryKeys';
import { PersonalityQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { useQuery } from 'react-query';

function usePersonalityDetails({
  params,
  options,
}: IQueryRequest<PersonalityQueryParams>) {
  const { personalityId } = params;
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getPersonalityWithId(personalityId);
      return data;
    },
    queryKey: [QueryKeys.organizationPersonalities, personalityId],
  });
}

export default usePersonalityDetails;
