import React, { useMemo } from 'react';
import { getOrganizations } from '../../../../helpers/utils/auth';
import { useOrganizationPathParams } from '../../../../hooks/paths/useOrganizationPathParams';
import { OrganizationType } from '../../../../interfaces/organizations';
import DentalClinic from './components/DentalClinic';
import Generic from './components/Generic';

const OrganizationNavigation = () => {
  const { organizationId } = useOrganizationPathParams();

  const organizations = useMemo(() => getOrganizations() ?? [], []);

  const currentOrganization = useMemo(
    () => organizations.find((p) => p.id === organizationId),
    [organizationId, organizations]
  );

  if (!currentOrganization) return <></>;

  switch (currentOrganization.type) {
    case OrganizationType.DentalClinic:
      return <DentalClinic organization={currentOrganization} />;
    case OrganizationType.NonHealthcareBusinessOrCorporation:
    case OrganizationType.EducationalInstitute:
    case OrganizationType.HealthcareProvider:
    case OrganizationType.Other:
      return <Generic organization={currentOrganization} />;
  }
};

export default OrganizationNavigation;
