import React, { useMemo, useState } from 'react';
import { DashboardDefaults } from '../../../../../../../../../../../components/Dashboard/components/Dashboard/components/DashboardContent/defaults';
import { useTranslations } from '../../../../../../../../../../../hooks/useTranslations';
import { SingleEntry } from '../../../../../types';

export const useCarousel = (items: SingleEntry[][]) => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const notSpecifiedTranslation = useTranslations(
    DashboardDefaults.NOT_SPECIFIED_TRANSLATION_PATH
  );

  const notSpecified = useMemo(
    () =>
      notSpecifiedTranslation.wrapWithIntl(
        notSpecifiedTranslation.attachPrefix(
          DashboardDefaults.NOT_SPECIFIED_TRANSLATION_PREFIX
        )
      ),
    [notSpecifiedTranslation]
  );

  const next = () => {
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex: number) => {
    setActiveIndex(newIndex);
  };

  const slides = items.map((item, index) => {
    if (activeIndex !== index) return <></>;

    return (
      <div className="d-flex flex-column justify-content-center align-items-center gap-3 h-100">
        {item.map((element) => (
          <div
            key={element.name}
            className="d-flex flex-column align-items-center gap-1"
          >
            <span className="text-center">{element.name}</span>
            <span className="fw-bold">{element.value ?? notSpecified}</span>
          </div>
        ))}
      </div>
    );
  });

  return { next, previous, goToIndex, slides, items, activeIndex };
};
