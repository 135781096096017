import classNames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';

export interface FilterInputProps {
  filterBody: JSX.Element[] | JSX.Element | null;
  onAcceptClick: (() => void) | null;
  resetFilters: (() => void) | null;
  toggle: () => void;
  isOpen: boolean;
  displayComponent: JSX.Element;
  targetId: string;
  resetFiltersButtonTranslationId?: string;
  closeButton?: boolean;
  popoverClassName?: string;
  popoverBodyClassName?: string;
  acceptButtonDisabled?: boolean;
  resetButtonHidden?: boolean;
  titleTranslationId?: string;
}

const FilterInput = ({
  resetFilters,
  filterBody,
  onAcceptClick,
  isOpen,
  toggle,
  displayComponent,
  targetId,
  resetFiltersButtonTranslationId = 'Projects.filter.clearAll',
  titleTranslationId,
  closeButton = false,
  popoverClassName = undefined,
  popoverBodyClassName = undefined,
  acceptButtonDisabled = false,
  resetButtonHidden = false,
}: FilterInputProps) => {
  const handleClose = () => {
    resetFilters && resetFilters();
    toggle();
  };

  return (
    <div className="FilterInput">
      {displayComponent}
      <Popover
        placement="bottom"
        isOpen={isOpen}
        target={targetId}
        toggle={toggle}
        role="dialog"
        className={classNames(
          {
            [`${popoverClassName}`]: popoverClassName,
          },
          { FilterInput__popover: !popoverClassName }
        )}
      >
        {titleTranslationId && (
          <PopoverHeader className="FilterInput__header">
            <FormattedMessage id={titleTranslationId} />
          </PopoverHeader>
        )}
        {!resetButtonHidden && resetFilters && (
          <button
            className="FilterInput__header__clear ms-auto"
            onClick={resetFilters}
          >
            <FormattedMessage id={resetFiltersButtonTranslationId} />
          </button>
        )}
        <PopoverBody
          className={classNames({
            [`${popoverBodyClassName}`]: popoverBodyClassName,
          })}
        >
          <div
            className={classNames('mb-0', {
              'mb-3': !!onAcceptClick || !!closeButton,
            })}
          >
            {filterBody}
          </div>
          <div className="w-100 d-flex">
            {onAcceptClick && (
              <Button
                color="primary"
                className="ms-auto"
                disabled={acceptButtonDisabled}
                outline={true}
                onClick={onAcceptClick}
              >
                <FontAwesomeIcon icon={faCheck} />
              </Button>
            )}
            {closeButton && (
              <Button
                color="primary"
                outline
                className="ms-2"
                onClick={handleClose}
              >
                <FontAwesomeIcon icon={faTimes} />
              </Button>
            )}
          </div>
        </PopoverBody>
      </Popover>
    </div>
  );
};

export default FilterInput;
