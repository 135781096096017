import { differenceInCalendarDays } from 'date-fns';
import { cloneDeep } from 'lodash';
import { useCallback } from 'react';
import { useTypedContext } from '../../../../../../../../../../../hooks/useTypedContext';
import { DashboardDataReducerContext } from '../../../../../types';
import { DashboardDataActions } from '../../../../DashboardDataContent/store/actions';
import {
  getAnotherMonth,
  getAnotherYear,
  getPreviousMonth,
  getPreviousYear,
  isMonthSelected,
  isYearSelected,
  shiftDays,
} from '../helpers';

export const useNavigateDates = () => {
  const [dashboardData, dispatch] = useTypedContext(
    DashboardDataReducerContext
  );

  const handlePrev = useCallback(async () => {
    const currentDateRange = cloneDeep({
      from: dashboardData.template.date_selection.date_from,
      to: dashboardData.template.date_selection.date_to,
    });

    const monthSelected = isMonthSelected(currentDateRange);
    const yearSelected = isYearSelected(currentDateRange);

    if (monthSelected) {
      dispatch({
        type: DashboardDataActions.DashboardDataActionType.EditDateRange,
        payload: getPreviousMonth(currentDateRange),
      });

      return;
    }

    if (yearSelected) {
      dispatch({
        type: DashboardDataActions.DashboardDataActionType.EditDateRange,
        payload: getPreviousYear(currentDateRange),
      });

      return;
    }

    let navigateToDays =
      differenceInCalendarDays(currentDateRange.to, currentDateRange.from) * -1;

    navigateToDays--;

    dispatch({
      type: DashboardDataActions.DashboardDataActionType.EditDateRange,
      payload: shiftDays(currentDateRange, navigateToDays),
    });
  }, [
    dashboardData.template.date_selection.date_from,
    dashboardData.template.date_selection.date_to,
    dispatch,
  ]);

  const handleNext = useCallback(() => {
    const currentDateRange = cloneDeep({
      from: dashboardData.template.date_selection.date_from,
      to: dashboardData.template.date_selection.date_to,
    });

    const monthSelected = isMonthSelected(currentDateRange);
    const yearSelected = isYearSelected(currentDateRange);

    if (monthSelected) {
      dispatch({
        type: DashboardDataActions.DashboardDataActionType.EditDateRange,
        payload: getAnotherMonth(currentDateRange),
      });

      return;
    }

    if (yearSelected) {
      dispatch({
        type: DashboardDataActions.DashboardDataActionType.EditDateRange,
        payload: getAnotherYear(currentDateRange),
      });

      return;
    }

    let navigateToDays = differenceInCalendarDays(
      currentDateRange.to,
      currentDateRange.from
    );

    navigateToDays++;

    dispatch({
      type: DashboardDataActions.DashboardDataActionType.EditDateRange,
      payload: shiftDays(currentDateRange, navigateToDays),
    });
  }, [
    dashboardData.template.date_selection.date_from,
    dashboardData.template.date_selection.date_to,
    dispatch,
  ]);

  return { handleNext, handlePrev };
};
