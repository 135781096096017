import { getCaseSymptoms } from 'api/symptoms';
import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { CaseQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

const useCaseSymptoms = ({
  params,
  options,
}: IQueryRequest<CaseQueryParams>) => {
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getCaseSymptoms(
        params.organizationId,
        params.caseId
      );

      return data.data;
    },
    queryKey: [QueryKeys.getCaseSymptoms, params.organizationId, params.caseId],
  });
};

export default useCaseSymptoms;
