import { ICaseGroupFormValues } from '../../../hooks/useCreateCaseGroupForm';
import React, { useCallback, useEffect } from 'react';
import { IProject } from '../../../../../../../interfaces/submissions';
import { useGroupScheduleData } from '../../GroupSchedule/hooks/useGroupScheduleData';
import { useGenerateGroupSchedule } from '../../GroupSchedule/hooks/useGenerateGroupSchedule';

export const useCreateCaseGroupProjectField = (
  values: ICaseGroupFormValues,
  setValues: (
    values: React.SetStateAction<ICaseGroupFormValues>,
    shouldValidate?: boolean | undefined
  ) => void,
  projects: IProject[]
) => {
  const { treatmentPhases } = useGroupScheduleData(values.case_category_id);

  const generateGroupSchedule = useGenerateGroupSchedule();

  const handleProjectFieldValue = useCallback(
    async (target: string, value: string) => {
      const existingProject = projects.find((p) => p.id === value);

      if (!existingProject) return;

      setValues((prev) => ({
        ...prev,
        project_id: value,
        case_category_id: existingProject.case_category_id,
      }));
    },
    [projects, setValues]
  );

  useEffect(() => {
    setValues((prev) => ({
      ...prev,
      group_schedule: generateGroupSchedule(
        new Date(values.starting_date),
        treatmentPhases.data ?? []
      ),
    }));
  }, [
    generateGroupSchedule,
    setValues,
    treatmentPhases.data,
    values.starting_date,
  ]);

  return {
    handleChange: handleProjectFieldValue,
  };
};
