import { TemplateElement } from './change';
import { TimeRange } from './timeline';
import { CultureEntry } from './cultures';

export type TrendVisualizationTemplate = {
  id: string;
  owner_id: string;
  name: string;
  visualizations: TrendVisualization[];
};

export enum SelectionTrendVisualizationTemplateContext {
  MemberContext = 'MemberContext',
  PatientContext = 'PatientContext',
}

export type SelectionTrendVisualizationTemplateInfo = {
  template: TrendVisualizationTemplate;
  context: SelectionTrendVisualizationTemplateContext;
};

export type TrendVisualization = {
  sequence: number;
  title: string;
  title_translations: CultureEntry<string>[];
  elements: TemplateElement[];
};

export enum TrendVisualizationChartType {
  LineChart = 'LineChart',
  BarChart = 'BarChart',
}

export type TrendVisualizationFormItem = {
  form_code: string;
  questions: TrendVisualizationFormItemQuestion[];
  type: TrendVisualizationChartType;
};

export type TrendVisualizationFormItemQuestion = {
  question_id: string;
  question_title: string;
  origin_timeline_id: string | null;
};

export interface ICreateCaseDefaultSetup {
  organization_id: string;
  case_category_id: string | null;
  case_subcategory_id: string | null;
  member_trend_visualization_id: string | null;
  patient_trend_visualization_id: string | null;
  expected_feedback_frequency_in_hours: number | null;
  expected_synchronization_frequency_in_hours: number | null;
  default_visible_time_range: TimeRange | null;
}

export interface IUpsertCaseDefaultSetup {
  id: string | null;
  organization_id: string;
  case_category_id: string | null;
  case_subcategory_id: string | null;
  member_trend_visualization_id: string | null;
  patient_trend_visualization_id: string | null;
}

export interface ICreateTrendVisualizationTemplateAndLinkWithSetupBody {
  setup: {
    organization_id: string;
    case_id: string | null;
    case_category_id: string | null;
    case_subcategory_id: string | null;
    intended_for_patient: boolean;
  };
  template: TrendVisualizationTemplate;
}

export interface ICreateTrendVisualizationTemplateAndUpdateSetupBody {
  setup_id: string;
  template: TrendVisualizationTemplate;
}
