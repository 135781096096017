import { cloneDeep } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useTypedContext } from '../../../../../../../../../../../hooks/useTypedContext';
import { DashboardFilterType } from '../../../../../../../../../../../interfaces/dashboards';
import { DashboardSelectionHelpers } from '../../../../../helpers/selection';
import {
  DashboardDataReducerContext,
  DashboardSelectableOption,
} from '../../../../../types';
import { DashboardDataActions } from '../../../../DashboardDataContent/store/actions';

export const useToggleOptionItem = () => {
  const [dashboardData, dispatch] = useTypedContext(
    DashboardDataReducerContext
  );

  const globalSelected = useMemo(() => {
    return DashboardSelectionHelpers.IsGlobalSelected(
      dashboardData.template.filters
    );
  }, [dashboardData.template.filters]);

  const singleToggle = useCallback(
    (
      filterType: DashboardFilterType,
      filterId: string | undefined,
      optionId: string,
      fromUnselected: boolean = false
    ) => {
      let filtersCopy = cloneDeep(
        fromUnselected
          ? DashboardSelectionHelpers.ToggleAll(
              dashboardData.template.filters,
              'unselect'
            )
          : dashboardData.template.filters
      );

      let currentFilterIndex: number = -1;

      filtersCopy.forEach((p, idx) => {
        if (p.type === DashboardFilterType.Range) return;

        if (
          filterType === DashboardFilterType.Member &&
          p.type === DashboardFilterType.Member
        )
          currentFilterIndex = idx;

        if (
          filterType === DashboardFilterType.Grasp &&
          p.type === DashboardFilterType.Grasp
        )
          currentFilterIndex = idx;

        if (
          filterType === DashboardFilterType.Organization &&
          p.type === DashboardFilterType.Organization
        )
          currentFilterIndex = idx;

        if (
          filterType === DashboardFilterType.SourcePeriod &&
          p.type === DashboardFilterType.SourcePeriod
        )
          currentFilterIndex = idx;

        if (
          filterType === DashboardFilterType.ClosedList &&
          p.type === DashboardFilterType.ClosedList &&
          p.id === filterId
        )
          currentFilterIndex = idx;
      });

      if (currentFilterIndex === -1) return;

      const optionBasedFilter = filtersCopy[currentFilterIndex]
        .options as DashboardSelectableOption[];

      let currentOptionIndex = optionBasedFilter.findIndex(
        (p) => p.id === optionId
      );

      if (currentOptionIndex === -1) return;

      const filterSelected = (
        filtersCopy[currentFilterIndex].options as DashboardSelectableOption[]
      )[currentOptionIndex].selected;

      if (
        filterSelected &&
        DashboardSelectionHelpers.SelectionCount(filtersCopy) === 1
      ) {
        dispatch({
          type: DashboardDataActions.DashboardDataActionType.EditTemplate,
          payload: {
            ...dashboardData.template,
            filters: DashboardSelectionHelpers.ToggleAll(filtersCopy, 'select'),
          },
        });
        return;
      }

      (filtersCopy[currentFilterIndex].options as DashboardSelectableOption[])[
        currentOptionIndex
      ].selected = !(
        filtersCopy[currentFilterIndex].options as DashboardSelectableOption[]
      )[currentOptionIndex].selected;

      dispatch({
        type: DashboardDataActions.DashboardDataActionType.EditTemplate,
        payload: {
          ...dashboardData.template,
          filters: filtersCopy,
        },
      });
    },
    [dashboardData.template, dispatch]
  );

  const toggle = useCallback(
    (
      filterType: DashboardFilterType,
      filterId: string | undefined,
      optionId: string
    ) => {
      if (globalSelected) {
        singleToggle(filterType, filterId, optionId, true);
        return;
      }

      singleToggle(filterType, filterId, optionId);
    },
    [globalSelected, singleToggle]
  );

  return { toggle };
};
