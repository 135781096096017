import { modifyConversationTemplate } from 'api/conversations';
import { IUpsertConversationTemplate } from '../../../interfaces/conversations';
import { OrganizationQueryParams } from '../../../interfaces/queryParams';
import { IMutationRequest } from '../../../interfaces/request';
import { IErrorDefinition } from '../../../interfaces/response';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

const useUpsertConversationTemplate = ({
  params,
  options,
  silent,
}: IMutationRequest<OrganizationQueryParams>) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (body: IUpsertConversationTemplate) => {
      await modifyConversationTemplate(params.organizationId, body);
    },
    onSuccess: () => {
      if (!silent) {
        displaySuccessNotification(
          'Api.Mutations.Conversations.UpsertConversationTemplate.success'
        );
      }

      options.successFb?.();
    },
    onError: () => {
      if (!silent) {
        displayErrorNotification(
          'Api.Mutations.Conversations.UpsertConversationTemplate.failure'
        );
      }

      options.errorFb?.();
    },
    onErrorArray: (errorArray: IErrorDefinition[]) => {
      if (!silent) {
        errorArray.forEach((item) => {
          displayErrorNotification(
            item.reasons.at(0) ??
              'Api.Mutations.Conversations.UpsertConversationTemplate.failure'
          );
        });
      }

      options.errorFb?.();
    },
    resetOnResult: true,
  });
};

export default useUpsertConversationTemplate;
