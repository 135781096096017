import { IQueryRequest } from '../../../interfaces/request';
import { useQuery } from 'react-query';
import { getCaseGroupConversationScheduleResponses } from '../../conversationSchedules';
import QueryKeys from '../queryKeys';
import { CaseGroupQueryParams } from '../../../interfaces/queryParams';

type UseCaseGroupConversationScheduleResponsesParams = CaseGroupQueryParams & {
  scheduleId: string;
};

const useCaseGroupConversationSchedules = ({
  params,
  options,
}: IQueryRequest<UseCaseGroupConversationScheduleResponsesParams>) => {
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getCaseGroupConversationScheduleResponses(
        params.organizationId,
        params.caseGroupId,
        params.scheduleId
      );

      return data.data;
    },
    queryKey: [
      QueryKeys.getCaseGroupConversationScheduleResponses,
      params.organizationId,
      params.caseGroupId,
      params.scheduleId,
    ],
  });
};

export default useCaseGroupConversationSchedules;
