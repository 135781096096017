import { getTimelineDetails } from 'api/graspManagement';
import QueryKeys from 'api/queries/queryKeys';
import { TimelineQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { useIntl } from 'react-intl';
import { useQuery } from 'react-query';

function useTimelineDetails({
  params,
  options,
}: IQueryRequest<TimelineQueryParams>) {
  const intl = useIntl();
  const { timelineId } = params;
  return useQuery({
    ...options,
    queryKey: [QueryKeys.getTimelineDetails, timelineId],
    queryFn: async () => {
      const { data: responseData } = await getTimelineDetails(timelineId);
      const { data } = responseData || {};

      if (data?.is_master) {
        return {
          ...data,
          name: intl.formatMessage({
            id: 'General.masterTimeline',
          }),
          description: intl.formatMessage({
            id: 'General.masterTimelineDescription',
          }),
        };
      } else return data;
    },
  });
}

export default useTimelineDetails;
