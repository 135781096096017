import Avatar from '@mui/material/Avatar';
import Icon from '@mui/material/Icon';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import React, { useState } from 'react';
import { ISelectableDashboardTemplateVisualization } from '../../../../../../../../../../../../../../interfaces/dashboards';
import ForEach from '../../../../../../../../../../../../../ForEach';
import EmptyList from '../../components/EmptyList';
import ListItemTextGenerator from '../../components/ListItemTextGenerator';
import { useListViewData } from '../../hooks/useListViewData';
import { useHandleSelectIndex } from './hooks/useHandleSelectIndex';
import { useInitialSelectedEvent } from './hooks/useInitialSelectedEvent';
import { useInitialSelectedIndex } from './hooks/useInitialSelectedIndex';

type SelectableListViewProps = {
  visualization: ISelectableDashboardTemplateVisualization;
};

const SelectableListView = ({ visualization }: SelectableListViewProps) => {
  const listViewData = useListViewData();
  const initialIndex = useInitialSelectedIndex(listViewData, visualization);

  useInitialSelectedEvent(initialIndex, visualization, listViewData);

  const [selectedIndex, setSelectedIndex] = useState<number | undefined>(
    initialIndex
  );

  const handleSelectItem = useHandleSelectIndex(
    visualization,
    setSelectedIndex
  );

  const provideStyle = (styleString: string | undefined) =>
    styleString ? JSON.parse(styleString) : {};

  return (
    <>
      {listViewData.length === 0 && <EmptyList />}
      <List>
        <ForEach items={listViewData as any[]}>
          {(item, index) => (
            <ListItemButton
              sx={provideStyle(
                selectedIndex === index
                  ? visualization.selected_style
                  : visualization.style
              )}
              selected={selectedIndex === index}
              onClick={handleSelectItem(item, index)}
            >
              {visualization.start_icon && (
                <ListItemAvatar>
                  <Avatar>
                    <Icon>{visualization.start_icon}</Icon>
                  </Avatar>
                </ListItemAvatar>
              )}
              <ListItemText
                primary={
                  <ListItemTextGenerator
                    item={item}
                    text={visualization.primary_text}
                  />
                }
                secondary={
                  visualization.secondary_text ? (
                    <ListItemTextGenerator
                      item={item}
                      text={visualization.secondary_text}
                    />
                  ) : undefined
                }
              />
              {visualization.end_icon && <Icon>{visualization.end_icon}</Icon>}
            </ListItemButton>
          )}
        </ForEach>
      </List>
    </>
  );
};

export default SelectableListView;
