import React from 'react';
import { TrendsChartFormatter } from '../../../../types';
import TrendsChartTooltipContent from './components/TrendsChartTooltipContent';

type TrendsChartTooltipProps = {
  formatters: TrendsChartFormatter[];
  innerProps: any;
};

const TrendsChartTooltip = ({
  formatters,
  innerProps,
}: TrendsChartTooltipProps) => {
  const valueFormatter = formatters.find(
    (p) => p.source === 'y-axis'
  )?.callback;

  const labelFormatter = formatters.find(
    (p) => p.source === 'x-axis'
  )?.callback;

  const nameFormatter = formatters.find((p) => p.source === 'name')?.callback;

  return (
    <TrendsChartTooltipContent
      {...innerProps}
      nameFormatter={nameFormatter}
      valueFormatter={valueFormatter}
      labelFormatter={labelFormatter}
    />
  );
};

export default TrendsChartTooltip;
