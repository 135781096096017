import useOrganizationContextGroupedAssessmentFormsForDate from 'api/queries/assessmentForms/forms/date/useOrganizationContextGroupedAssessmentFormsForDate';
import React from 'react';
import QuestionModal, { QuestionModalProps } from '.';
import { CaseQueryParams } from 'interfaces/queryParams';

type OrganizationQuestionModalProps = CaseQueryParams & QuestionModalProps;

const OrganizationContextQuestionModal = ({
  organizationId,
  caseId,
  ...props
}: OrganizationQuestionModalProps) => {
  const { refetch } = useOrganizationContextGroupedAssessmentFormsForDate({
    params: {
      organizationId,
      caseId,
      timelineId: props.timelineId,
      selectedDate: props.selectedDate,
    },
    options: { enabled: false },
  });

  return <QuestionModal {...props} refetchCallback={refetch} />;
};

export default OrganizationContextQuestionModal;
