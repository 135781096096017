import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { IPatchObject } from '../../../interfaces/request';
import { updateProject } from 'api/projects';

type PatchProjectParams = {
  data: IPatchObject[];
  projectId: string;
};

const usePatchProject = (organizationId: string, successFb?: () => void) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async ({ projectId, data }: PatchProjectParams) => {
      await updateProject(organizationId, projectId, data);
    },
    resetOnResult: true,
    onSuccess: () => {
      successFb && successFb();
      displaySuccessNotification('General.projectUpdated');
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'Register.notifications.errors'
      );
    },
  });
};

export default usePatchProject;
