import { isEmpty, uniq } from 'lodash';
import { useMemo } from 'react';

export const useNonPieChartEmptyPoints = (formattedData: any[]): any[] => {
  return useMemo(() => {
    if (isEmpty(formattedData)) return formattedData;

    let allRequiredKeys: string[] = [];

    formattedData.forEach((item) => {
      allRequiredKeys.push(...Object.keys(item));
    });

    allRequiredKeys = uniq(allRequiredKeys);

    if (allRequiredKeys.length < 2) return formattedData;

    return formattedData.map((item) => {
      const objectKeys = Object.keys(item);

      allRequiredKeys.forEach((requiredKey) => {
        if (objectKeys.includes(requiredKey)) return;
        item[requiredKey] = 0;
      });

      return item;
    });
  }, [formattedData]);
};
