import { useCallback } from 'react';
import {
  DashboardReportPeriod,
  ISourcePeriodDashboardFilter,
  ISourcePeriodDashboardTemplateFilter,
} from '../../../../../../../interfaces/dashboards';
import { DashboardSelectionHelpers } from '../helpers/selection';

export const useSourcePeriodOptions = () => {
  const append = useCallback(
    (dashboardFilter: ISourcePeriodDashboardFilter) => {
      Object.keys(DashboardReportPeriod).forEach((period) => {
        dashboardFilter.options.push({
          id: period,
          selected: false,
        });
      });

      return dashboardFilter;
    },
    []
  );

  return {
    append,
    select: DashboardSelectionHelpers.SelectIncludedExcluded<
      ISourcePeriodDashboardFilter,
      ISourcePeriodDashboardTemplateFilter
    >,
  };
};
