import { TrendVisualizationTemplate } from 'interfaces/trendVisualizations';
import { useMemo } from 'react';

export const useTemplateItemTypes = (template: TrendVisualizationTemplate) => {
  return useMemo(() => {
    const templateElements = template.visualizations.flatMap((p) => p.elements);
    let elementTypes = templateElements.map((p) => p.type);

    return [...new Set(elementTypes)];
  }, [template.visualizations]);
};
