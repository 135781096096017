import { FormikErrors } from 'formik';
import { ISchedulesEditTimeFormInitialValues } from '../../../interfaces/conversationSchedules';
import { differenceInCalendarDays } from 'date-fns';

export const useEditScheduleTemplateItemValidation = () => {
  const timesValidation = (
    errors: FormikErrors<ISchedulesEditTimeFormInitialValues>,
    values: ISchedulesEditTimeFormInitialValues
  ) => {
    if (
      !values.available_from_hour ||
      !values.available_to_hour ||
      !values.notification_hour
    ) {
      errors['available_from_hour'] = 'available_from_hour';
      return errors;
    }

    if (
      values.notification_hour >= values.available_from_hour &&
      values.notification_hour <= values.available_to_hour
    )
      return errors;

    errors['notification_hour'] = 'notification_hour';
    return errors;
  };

  const singleInstanceValidation = (
    errors: FormikErrors<ISchedulesEditTimeFormInitialValues>,
    values: ISchedulesEditTimeFormInitialValues,
    phaseDateFrom: string,
    phaseDateTo: string | null
  ) => {
    if (values.priority < 0) {
      errors['priority'] = 'priority';
      return errors;
    }

    if (!phaseDateTo) {
      errors = timesValidation(errors, values);
      return errors;
    }

    const phaseDays = differenceInCalendarDays(
      new Date(phaseDateTo),
      new Date(phaseDateFrom)
    );

    if (
      values.specific_day_of_phase &&
      (values.specific_day_of_phase <= 0 ||
        values.specific_day_of_phase > phaseDays + 1)
    ) {
      errors['specific_day_of_phase'] = 'specific_day_of_phase';
      return errors;
    }

    errors = timesValidation(errors, values);
    return errors;
  };

  const outlierValidation = (
    errors: FormikErrors<ISchedulesEditTimeFormInitialValues>,
    values: ISchedulesEditTimeFormInitialValues
  ) => {
    if (values.priority < 0) {
      errors['priority'] = 'priority';
      return errors;
    }
    errors = timesValidation(errors, values);

    return errors;
  };

  const recurringValidation = (
    errors: FormikErrors<ISchedulesEditTimeFormInitialValues>,
    values: ISchedulesEditTimeFormInitialValues,
    phaseDateFrom: string,
    phaseDateTo: string | null
  ) => {
    if (values.priority < 0) {
      errors['priority'] = 'priority';
      return errors;
    }

    if (!values.start_day_offset || !values.end_day_offset) {
      errors['start_day_offset'] = 'start_day_offset';
      return errors;
    }

    if (values.end_day_offset <= values.start_day_offset) {
      errors['end_day_offset'] = 'end_day_offset';
      return errors;
    }

    if ((values.recurring_interval ?? 0) < 1) {
      errors['recurring_interval'] = 'recurring_interval';
      return errors;
    }

    if (!phaseDateTo) {
      errors = timesValidation(errors, values);
      return errors;
    }

    const daysDuration =
      differenceInCalendarDays(new Date(phaseDateTo), new Date(phaseDateFrom)) +
      1;

    if (values.end_day_offset > daysDuration) {
      errors['end_day_offset'] = 'end_day_offset';
      return errors;
    }

    errors = timesValidation(errors, values);

    return errors;
  };

  return {
    singleInstanceValidation: singleInstanceValidation,
    outlierValidation: outlierValidation,
    recurringValidation: recurringValidation,
  };
};
