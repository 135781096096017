import { useOptions } from '../../../../../../../hooks/useOptions';
import {
  ConversationTemplateContext,
  ConversationTemplateTriggerType,
} from '../../../../../../../interfaces/conversations';
import { useCallback } from 'react';

export const useTriggerTypes = () => {
  const options = useOptions();

  const conversationContextTriggerTypes: ConversationTemplateTriggerType[] = [
    ConversationTemplateTriggerType.Time,
    ConversationTemplateTriggerType.PhaseLastResort,
    ConversationTemplateTriggerType.MandatoryEducation,
    ConversationTemplateTriggerType.SuggestedEducation,
  ];

  const formContextTriggerTypes: ConversationTemplateTriggerType[] = [
    ConversationTemplateTriggerType.ProjectSubmission,
    ConversationTemplateTriggerType.AwareSession,
    ConversationTemplateTriggerType.UserSatisfactionFeedback,
  ];

  const triggerTypeOptions = options.conversationTemplateTriggerTypes();

  const conversationContextTriggerTypeOptions = triggerTypeOptions.filter((p) =>
    conversationContextTriggerTypes.includes(
      p.value as ConversationTemplateTriggerType
    )
  );

  const formContextTriggerTypeOptions = triggerTypeOptions.filter((p) =>
    formContextTriggerTypes.includes(p.value as ConversationTemplateTriggerType)
  );

  return useCallback(
    (context: ConversationTemplateContext) => {
      return context === ConversationTemplateContext.UserConversation
        ? conversationContextTriggerTypeOptions
        : formContextTriggerTypeOptions;
    },
    [conversationContextTriggerTypeOptions, formContextTriggerTypeOptions]
  );
};
