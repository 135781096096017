import React from 'react';
import { FormattedMessage } from 'react-intl';

type ToastBodyWithCallbackProps = {
  text: string;
  buttonText: string;
  handleClick: (closeToast: any) => (dispatch: any) => Promise<void>;
  //investigate on closeToast, its not used anywhere
  closeToast?: any;
};

const ToastBodyWithCallback = ({
  text,
  buttonText,
  handleClick,
  closeToast = null,
}: ToastBodyWithCallbackProps) => {
  const handleButtonClick = () => handleClick(closeToast);

  return (
    <>
      <FormattedMessage id={text} defaultMessage={text} />{' '}
      <span className="btn-link" onClick={handleButtonClick}>
        <FormattedMessage id={buttonText} defaultMessage={buttonText} />
      </span>
    </>
  );
};

export default ToastBodyWithCallback;
