import { format } from 'date-fns';
import { validateSubsetEmpty } from '../../../../../../../../../helpers/utils/validators';
import { useIntl } from 'react-intl';

type CreateCaseGroupSessionValues = {
  meetings_date: string;
  meetings_link: string;
  meetings_pin_code: string;
};

export const useCreateCaseGroupSessionForm = () => {
  const intl = useIntl();

  const addHours = (date: Date, hours: number) => {
    date.setHours(date.getHours() + hours);
    return date;
  };

  const initialValues: CreateCaseGroupSessionValues = {
    meetings_date: format(addHours(new Date(), 1), "yyyy-MM-dd'T'HH:mm:ss'"),
    meetings_link: '',
    meetings_pin_code: '',
  };

  const validation = (values: typeof initialValues) =>
    validateSubsetEmpty<typeof initialValues>(values, ['meetings_date'], intl);

  return {
    validation: validation,
    initialValues: initialValues,
  };
};
