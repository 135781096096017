import { getShareRequests } from 'api/cases';
import { CaseQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { useQuery } from 'react-query';

function useTimelineShareRequestsForCase({
  params,
  options,
}: IQueryRequest<CaseQueryParams>) {
  const { organizationId, caseId } = params;

  return useQuery({
    ...options,
    queryKey: ['getShareRequests', organizationId, caseId],
    queryFn: async () => {
      const response = await getShareRequests(organizationId, caseId);
      const { data: responseData } = response;
      return responseData.data;
    },
  });
}

export default useTimelineShareRequestsForCase;
