import React from 'react';

import EntityUpdatedItem from './EnityUpdatedItem';
import ListItemLayout from './ListItemLayout';
import { FormattedMessage, useIntl } from 'react-intl';
import { ActivityTypes, IActivity } from 'interfaces/activities';

import sharingIcon from 'assets/images/timeline_details/sharing.svg';

type ActivityListItemProps = {
  data: IActivity;
};

const ActivityListItem = ({ data }: ActivityListItemProps) => {
  const intl = useIntl();

  switch (data.activity_type) {
    case ActivityTypes.observationDataAssignedToTimeline:
      return (
        <ListItemLayout
          imageSrc={sharingIcon}
          timeStart={data.time_stamp}
          textContent={intl.formatMessage({
            id: 'UserDashboard.Activity.observationDataAssignedToTimeline',
          })}
        />
      );
    case ActivityTypes.observationDataUploaded:
      return (
        <ListItemLayout
          imageSrc={sharingIcon}
          timeStart={data.time_stamp}
          textContent={intl.formatMessage({
            id: 'UserDashboard.Activity.observationDataHasBeenUploaded',
          })}
        />
      );
    case ActivityTypes.entityUpdated:
      return (
        <EntityUpdatedItem
          activityData={data.activity_data}
          timestamp={data.time_stamp}
        />
      );
    case ActivityTypes.timelineSharingChanged:
      return (
        <ListItemLayout
          imageSrc={sharingIcon}
          timeStart={data.time_stamp}
          textContent={
            <>
              <FormattedMessage id="UserDashboard.Activity.timelineSharingChanged" />{' '}
              <FormattedMessage id="UserDashboard.Activity.for" />{' '}
              <FormattedMessage id="UserDashboard.Activity.requestFrom" />
              {' - '}
              <b>{data.activity_data.request_description} </b>
              {data.activity_data.timeline_name}{' '}
              {data.activity_data.is_shared ? (
                <FormattedMessage id="UserDashboard.Activity.isNowShared" />
              ) : (
                <FormattedMessage id="UserDashboard.Activity.isNoLongerShared" />
              )}
            </>
          }
        />
      );
    default:
      return null;
  }
};

export default ActivityListItem;
