import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { addEducationMaterial } from '../../educationMaterials';
import {
  IEducationMaterial,
  IUpsertEducationMaterial,
} from '../../../interfaces/educationMaterials';
import { OrganizationQueryParams } from '../../../interfaces/queryParams';

type AddEducationMaterialMutationRequest = {
  params: OrganizationQueryParams;
  options: {
    successFb?: (material: IEducationMaterial) => Promise<void>;
  };
};

const useAddEducationMaterial = ({
  params,
  options,
}: AddEducationMaterialMutationRequest) => {
  const mutationData = useMutationWithResultNotification({
    asyncApiFunction: async (body: IUpsertEducationMaterial) => {
      const { data } = await addEducationMaterial(params.organizationId, body);
      return data.data;
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'Api.Mutations.EducationMaterials.AddEducationMaterial.failure'
      );
    },
    onSuccess: () => {
      displaySuccessNotification(
        'Api.Mutations.EducationMaterials.AddEducationMaterial.success'
      );

      if (mutationData.data) {
        options.successFb?.({
          ...mutationData.data,
          rules: [],
          assets: [],
          assets_with_cultures: [],
          culture_details: [],
        });

        mutationData.reset();
      }
    },
    resetOnResult: false,
  });

  return mutationData;
};

export default useAddEducationMaterial;
