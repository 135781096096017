import React from 'react';
import AddScheduleTemplateModal from '../../../../../../../../../../components/AddScheduleTemplateModal';
import { ISummarizedTreatmentPhase } from '../../../../../../../../../../interfaces/conversations';
import { useAddTemplateForm } from './hooks/useAddTemplateForm';

type AddTemplateModalProps = {
  isOpen: boolean;
  toggle: () => void;
  contentIndex?: number;
  defaultTemplateMode?: boolean;
  phase: ISummarizedTreatmentPhase;
};

const AddTemplateModal = ({
  isOpen,
  toggle,
  contentIndex,
  phase,
  defaultTemplateMode = false,
}: AddTemplateModalProps) => {
  const formHandler = useAddTemplateForm(
    phase,
    toggle,
    defaultTemplateMode,
    contentIndex
  );

  return (
    <AddScheduleTemplateModal
      isOpen={isOpen}
      toggle={toggle}
      formHandler={formHandler}
      defaultTemplateMode={defaultTemplateMode}
      templateDisabled={contentIndex !== undefined}
    />
  );
};

export default AddTemplateModal;
