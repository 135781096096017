import React from 'react';
import { useCreateCaseGroupSessionForm } from './hooks/useCreateCaseGroupSessionForm';
import { useCaseGroupDetailsCommonData } from '../../../../hooks/useCaseGroupDetailsCommonData';
import useCreateCaseGroupSession from 'api/mutations/caseGroups/useCreateCaseGroupSession';
import useRequiredParams from '../../../../../../../../hooks/useRequiredParams';
import {
  Button,
  Form,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { Field, Formik } from 'formik';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsersClass } from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import FieldInput from '../../../../../../../../components/FieldInput';

type CreateCaseGroupSessionModalProps = {
  isOpen: boolean;
  toggle: () => void;
};

const CreateCaseGroupSessionModal = ({
  isOpen,
  toggle,
}: CreateCaseGroupSessionModalProps) => {
  const intl = useIntl();

  const { organizationId, projectId, groupId } = useRequiredParams<{
    organizationId: string;
    projectId: string;
    groupId: string;
  }>(['organizationId', 'projectId', 'groupId']);

  const { validation, initialValues } = useCreateCaseGroupSessionForm();
  const { caseGroup } = useCaseGroupDetailsCommonData();

  const handleSuccess = async () => {
    toggle();
    await caseGroup.refetch();
  };

  const { mutateAsync: createCaseGroupSession } = useCreateCaseGroupSession(
    {
      organizationId,
      projectId,
      groupId,
    },
    { successFb: handleSuccess }
  );

  const handleSubmit = async (values: typeof initialValues) => {
    await createCaseGroupSession({
      meetings_date: values.meetings_date,
      meetings_link: values.meetings_link,
      meetings_pin_code: values.meetings_pin_code,
      id: '',
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="CreateCaseGroupSessionModal"
      size="lg"
    >
      <Formik
        validate={validation}
        validateOnMount={true}
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ submitForm, isValid, resetForm, dirty }) => {
          const handleReset = () => {
            resetForm();
          };

          return (
            <>
              <ModalHeader
                className="CreateCaseGroupSessionModal__header"
                toggle={toggle}
              >
                <FontAwesomeIcon icon={faUsersClass} />
                <FormattedMessage id="CaseGroupItem.createCaseGroupSession" />
              </ModalHeader>
              <ModalBody className="CreateCaseGroupSessionModal__body">
                <Form className="CreateCaseGroupSessionModal__form CreateCaseGroupSessionModalForm">
                  <FormGroup className="CreateCaseGroupSessionModalForm__item">
                    <label htmlFor="CreateCaseGroupSessionModal|meetings_date">
                      <FormattedMessage id="CaseGroupItem.meetings_date" />
                    </label>
                    <Field
                      required={true}
                      placeholder={intl.formatMessage({
                        id: `CaseGroupItem.meetings_date`,
                      })}
                      id="CreateCaseGroupSessionModal|meetings_date"
                      name="meetings_date"
                      type="datetime-local"
                      component={FieldInput}
                    />
                  </FormGroup>
                  <FormGroup className="CreateCaseGroupSessionModalForm__item">
                    <label htmlFor="CreateCaseGroupSessionModal|meetings_link">
                      <FormattedMessage id="CaseGroupItem.meetings_link" />
                    </label>
                    <Field
                      required={true}
                      placeholder={intl.formatMessage({
                        id: `CaseGroupItem.meetings_link`,
                      })}
                      id="CreateCaseGroupSessionModal|meetings_link"
                      name="meetings_link"
                      type="text"
                      component={FieldInput}
                    />
                  </FormGroup>
                  <FormGroup className="CreateCaseGroupSessionModalForm__item">
                    <label htmlFor="CreateCaseGroupSessionModal|meetings_pin_code">
                      <FormattedMessage id="CaseGroupItem.meetings_pin_code" />
                    </label>
                    <Field
                      required={true}
                      placeholder={intl.formatMessage({
                        id: `CaseGroupItem.meetings_pin_code`,
                      })}
                      id="CreateCaseGroupSessionModal|meetings_pin_code"
                      name="meetings_pin_code"
                      type="password"
                      component={FieldInput}
                    />
                  </FormGroup>
                </Form>
              </ModalBody>
              <ModalFooter className="CreateCaseGroupSessionModal__footer">
                <Button color="primary" outline={true} onClick={toggle}>
                  <FormattedMessage id="General.close" />
                </Button>
                <Button color="primary" outline={true} onClick={handleReset}>
                  <FormattedMessage id="General.reset" />
                </Button>
                <Button
                  disabled={!isValid || !dirty}
                  color="primary"
                  onClick={submitForm}
                >
                  <FormattedMessage id="General.save" />
                </Button>
              </ModalFooter>
            </>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CreateCaseGroupSessionModal;
