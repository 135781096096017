import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';
import { useTranslations } from '../../../../../../../../../../../hooks/useTranslations';
import { useTypedContext } from '../../../../../../../../../../../hooks/useTypedContext';
import {
  DashboardDatePeriod,
  DashboardTemplateDatePeriod,
} from '../../../../../../../../../../../interfaces/dashboards';
import { DashboardDataReducerContext } from '../../../../../types';

export const useDateFormatting = () => {
  const { wrapWithIntl, attachPrefix } = useTranslations(
    'Organizations.OrganizationDashboard.AwareCasesDashboard.DashboardFilters.DashboardDateRangeFilter'
  );

  const [dashboardData] = useTypedContext(DashboardDataReducerContext);

  const getTodayDayFormatting = useCallback(() => {
    return wrapWithIntl(attachPrefix(DashboardTemplateDatePeriod.Today), {
      from: format(
        dashboardData.template.date_selection.date_from,
        'dd.MM.yyyy'
      ),
    });
  }, [
    attachPrefix,
    dashboardData.template.date_selection.date_from,
    wrapWithIntl,
  ]);

  const getCurrentWeekDayFormatting = useCallback(() => {
    return wrapWithIntl(attachPrefix(DashboardTemplateDatePeriod.CurrentWeek), {
      week: `${format(dashboardData.template.date_selection.date_from, 'w')}`,
      from: format(
        dashboardData.template.date_selection.date_from,
        'dd.MM.yyyy'
      ),
      to: format(dashboardData.template.date_selection.date_to, 'dd.MM.yyyy'),
      b: '<span class="fw-500">',
      bClose: '</span>',
    });
  }, [
    attachPrefix,
    dashboardData.template.date_selection.date_from,
    dashboardData.template.date_selection.date_to,
    wrapWithIntl,
  ]);

  const getDateRangeDayFormatting = useCallback(() => {
    return wrapWithIntl(attachPrefix('Range'), {
      from: format(
        dashboardData.template.date_selection.date_from,
        'dd.MM.yyyy'
      ),
      to: format(dashboardData.template.date_selection.date_to, 'dd.MM.yyyy'),
    });
  }, [
    attachPrefix,
    dashboardData.template.date_selection.date_from,
    dashboardData.template.date_selection.date_to,
    wrapWithIntl,
  ]);

  const getCurrentMonthDayFormatting = useCallback(() => {
    return wrapWithIntl(
      attachPrefix(DashboardTemplateDatePeriod.CurrentMonth),
      {
        fromDay: format(dashboardData.template.date_selection.date_from, 'dd'),
        toDay: format(dashboardData.template.date_selection.date_to, 'dd'),
        month: `${format(
          dashboardData.template.date_selection.date_from,
          'MMMM'
        )}`,
        year: format(dashboardData.template.date_selection.date_from, 'yyyy'),
        b: '<span class="fw-500">',
        bClose: '</span>',
      }
    );
  }, [
    attachPrefix,
    dashboardData.template.date_selection.date_from,
    dashboardData.template.date_selection.date_to,
    wrapWithIntl,
  ]);

  const getCurrentYearDayFormatting = useCallback(() => {
    return wrapWithIntl(attachPrefix(DashboardTemplateDatePeriod.CurrentYear), {
      year: format(dashboardData.template.date_selection.date_from, 'yyyy'),
      b: '<span class="fw-500">',
      bClose: '</span>',
    });
  }, [
    attachPrefix,
    dashboardData.template.date_selection.date_from,
    wrapWithIntl,
  ]);

  return useMemo(() => {
    switch (dashboardData.template.date_selection.date_period) {
      case DashboardDatePeriod.Today:
        return getTodayDayFormatting();
      case DashboardDatePeriod.CurrentWeek:
      case DashboardDatePeriod.PreviousWeek:
        return getCurrentWeekDayFormatting();
      case DashboardDatePeriod.Last7Days:
        return getDateRangeDayFormatting();
      case DashboardDatePeriod.CurrentMonth:
        return getCurrentMonthDayFormatting();
      case DashboardDatePeriod.Last30Days:
        return getDateRangeDayFormatting();
      case DashboardDatePeriod.CurrentYear:
        return getCurrentYearDayFormatting();
      case DashboardDatePeriod.Last12Months:
        return getDateRangeDayFormatting();
      case DashboardDatePeriod.CustomPeriod:
        return getDateRangeDayFormatting();
    }
  }, [
    dashboardData.template.date_selection.date_period,
    getCurrentMonthDayFormatting,
    getCurrentWeekDayFormatting,
    getCurrentYearDayFormatting,
    getDateRangeDayFormatting,
    getTodayDayFormatting,
  ]);
};
