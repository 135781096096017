import { useQuery } from 'react-query';
import { OrganizationQueryParams } from '../../../interfaces/queryParams';
import { IQueryRequest } from '../../../interfaces/request';
import { getDashboardReportSchedules } from '../../dashboards';
import QueryKeys from '../queryKeys';

const useDashboardReportSchedules = ({
  params,
  options,
}: IQueryRequest<OrganizationQueryParams>) => {
  return useQuery({
    ...options,
    queryKey: [QueryKeys.getDashboardReportSchedules, params.organizationId],
    queryFn: async () => {
      const { data } = await getDashboardReportSchedules(params.organizationId);
      return data.data;
    },
  });
};

export default useDashboardReportSchedules;
