import { IQueryRequest } from '../../../interfaces/request';
import {
  OrganizationQueryParams,
  SearchAndPagingQueryParams,
} from '../../../interfaces/queryParams';
import { useQuery } from 'react-query';
import { getMembersInOrganizations } from '../../organization';
import QueryKeys from '../queryKeys';

type MembersInOrganizationsQueryParams = OrganizationQueryParams &
  SearchAndPagingQueryParams & {
    organization_ids: string[];
  };

const useMembersInOrganizations = ({
  params,
  options,
}: IQueryRequest<MembersInOrganizationsQueryParams>) => {
  const { organizationId, organization_ids } = params;

  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getMembersInOrganizations(
        organizationId,
        organization_ids
      );
      return data.data;
    },
    queryKey: [
      QueryKeys.getMembersInOrganizations,
      organizationId,
      organization_ids,
    ],
  });
};

export default useMembersInOrganizations;
