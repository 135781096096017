import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { CaseDashboardTabsValues } from '../../hooks/useTabs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/pro-light-svg-icons';
import { addDays, differenceInDays, format, subDays } from 'date-fns';
import {
  GraspColors,
  graspColorsValues,
} from '../../../../../../helpers/colors';
import SVG from 'react-inlinesvg';
import graspSvgIcon from 'assets/images/grasp_svg_icon.svg';
import useBreakpoint from '../../../../../../hooks/useBreakpoint';
import { useCommonData } from '../../../../hooks/useCommonData';
import { useInitQuery } from '../../../../../../hooks/queries/useInitQuery';
import FetchingDots from '../../../../../../components/FetchingDots';
import { useTranslations } from '../../../../../../hooks/useTranslations';
import classNames from 'classnames';
import { useTypedContext } from '../../../../../../hooks/useTypedContext';
import { SelectedDateContext } from '../../types';

type CaseDashboardHeaderProps = {
  selectedTab: CaseDashboardTabsValues;
  onTabChange: (val: number) => void;
  syncRequested: boolean;
};

const CaseDashboardHeader = ({
  selectedTab,
  onTabChange,
  syncRequested,
}: CaseDashboardHeaderProps) => {
  const { wrapWithIntl, attachPrefix } = useTranslations(
    'CaseDashboard.CaseDashboardTabs.CaseDashboardHeader'
  );

  const selectedDate = useTypedContext(SelectedDateContext);

  const { userCaseDetails } = useCommonData();
  useInitQuery(userCaseDetails);

  const handleSelectTrends = () => onTabChange(1);
  const handleSelectDayDetails = () => onTabChange(0);

  const handleNextDate = () => {
    selectedDate.selected.setItem((prev) => addDays(prev, 1));
  };

  const handlePrevDate = () => {
    selectedDate.selected.setItem((prev) => subDays(prev, 1));
  };

  const isMobileSm = useBreakpoint(550);

  const renderNthDay = useMemo(() => {
    const startDateStr = userCaseDetails.data?.start_date;
    const endDateStr = userCaseDetails.data?.end_date;

    if ((!startDateStr && !endDateStr) || !startDateStr) return '';

    if (!endDateStr) {
      return wrapWithIntl(attachPrefix('nthDay'), {
        day:
          differenceInDays(selectedDate.selected.item, new Date(startDateStr)) +
          1,
      });
    }

    return wrapWithIntl(attachPrefix('nthDayOf'), {
      day:
        differenceInDays(selectedDate.selected.item, new Date(startDateStr)) +
        1,
      of: differenceInDays(new Date(endDateStr), new Date(startDateStr)) + 1,
    });
  }, [
    attachPrefix,
    selectedDate.selected.item,
    userCaseDetails.data?.end_date,
    userCaseDetails.data?.start_date,
    wrapWithIntl,
  ]);

  return (
    <div className="CaseDashboardTabs__header CaseDashboardHeader">
      <div className="CaseDashboardHeaderTabs ms-lg-2">
        <div
          onClick={handleSelectTrends}
          className={classNames({
            CaseDashboardHeaderTabs__tab: true,
            'CaseDashboardHeaderTabs__tab--active':
              selectedTab === CaseDashboardTabsValues.DayDetails,
          })}
        >
          <FormattedMessage id="CaseDashboard.CaseDashboardTabs.CaseDashboardHeader.dayDetails" />
        </div>
        <div
          onClick={handleSelectDayDetails}
          className={classNames({
            CaseDashboardHeaderTabs__tab: true,
            'CaseDashboardHeaderTabs__tab--active':
              selectedTab === CaseDashboardTabsValues.Trends,
          })}
        >
          <FormattedMessage id="CaseDashboard.CaseDashboardTabs.CaseDashboardHeader.trends" />
        </div>
      </div>
      {syncRequested && (
        <div className="CaseDashboardHeaderGrasp">
          <SVG src={graspSvgIcon} color={graspColorsValues[GraspColors.BLUE]} />
          {isMobileSm ? (
            <div style={{ width: 20 }}>
              <FetchingDots
                color={graspColorsValues[GraspColors.BLUE]}
                interval={500}
              />
            </div>
          ) : (
            <span style={{ color: graspColorsValues[GraspColors.BLUE] }}>
              <FormattedMessage id="CaseDashboard.CaseDashboardTabs.CaseDashboardHeader.syncRequested" />
            </span>
          )}
        </div>
      )}
      {selectedTab === CaseDashboardTabsValues.DayDetails && (
        <div className="CaseDashboardHeaderDate me-2">
          <FontAwesomeIcon
            icon={faChevronLeft}
            onClick={handlePrevDate}
            color={graspColorsValues[GraspColors.BLUE]}
            size="2x"
          />
          <div className="CaseDashboardHeaderDate__content CaseDashboardHeaderDateContent">
            <span className="CaseDashboardHeaderDateContent__date">
              {format(selectedDate.selected.item, 'dd.MM')}
            </span>
            <span className="CaseDashboardHeaderDateContent__day-of">
              {renderNthDay}
            </span>
          </div>
          <FontAwesomeIcon
            icon={faChevronRight}
            onClick={handleNextDate}
            color={graspColorsValues[GraspColors.BLUE]}
            size="2x"
          />
        </div>
      )}
    </div>
  );
};

export default CaseDashboardHeader;
