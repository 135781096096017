import { TrendVisualizationTemplate } from 'interfaces/trendVisualizations';
import React, { useCallback } from 'react';
import { useCommonData } from 'modules/Organizations/views/OrganizationDetailsView/hooks/useCommonData';
import { useInitQuery } from 'hooks/queries/useInitQuery';
import { useTranslations } from 'hooks/useTranslations';
import useDeleteTrendVisualizationTemplate from 'api/mutations/trendVisualizations/useDeleteTrendVisualizationTemplate';
import { displayErrorNotification } from 'modules/Notifications';
import { useCommonParams } from 'modules/Organizations/views/OrganizationDetailsView/hooks/useCommonParams';

export const useTemplateActions = (
  template: TrendVisualizationTemplate,
  handleEditChart: (template: TrendVisualizationTemplate) => void
) => {
  const { organizationId } = useCommonParams();

  const { caseDefaultSetups, queriesRefetch } = useCommonData();
  useInitQuery(caseDefaultSetups);

  const { attachPrefix } = useTranslations(
    'MyOrganizations.trendVisualizations'
  );

  const { mutate } = useDeleteTrendVisualizationTemplate(queriesRefetch);

  const handleEdit = useCallback(() => {
    handleEditChart(template);
  }, [handleEditChart, template]);

  const handleDelete = useCallback(() => {
    if (!caseDefaultSetups.data) return;

    const setupWithTemplateId = caseDefaultSetups.data.find(
      (p) =>
        p.member_trend_visualization_template?.id === template.id ||
        p.patient_trend_visualization_template?.id === template.id
    );

    if (setupWithTemplateId) {
      displayErrorNotification(attachPrefix('existingSetupConstraint'));
      return;
    }

    mutate({
      templateId: template.id,
      organizationId: organizationId,
    });
  }, [
    attachPrefix,
    caseDefaultSetups.data,
    mutate,
    organizationId,
    template.id,
  ]);

  const getHandleForRef = useCallback(
    (ref: React.RefObject<HTMLButtonElement>) => () => {
      if (!ref?.current) return;
      ref.current.click();
    },
    []
  );

  return { handleEdit, handleDelete, getHandleForRef };
};
