import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { Row } from 'reactstrap';
import useCaseDetails from '../../../../../../api/queries/cases/useCaseDetails';
import Breadcrumbs from '../../../../../../components/PageHeader/Breadcrumbs';
import {
  generateOrganizationPersonalitiesUrl,
  generatePersonalityUrl,
} from '../../../../../../helpers/routeDefinitions';
import { useOrganizationCasePathParams } from '../../../../../../hooks/paths/useOrganizationCasePathParams';
import { CaseTrackingType } from '../../../../../../interfaces/cases';
import { TimeRange } from '../../../../../../interfaces/timeline';
import { useBrowserState } from '../../../../../../store/reducerHooks';
import {
  setCasePeriodDateRange,
  setTimelineDetailsTimeRange,
} from '../../../../../TimelineDetails/actions';
import ChangeCaseHeader from '../ChangeCaseDetailsView/tabs/ChangeCaseDetailsTabs/components/ChangeCaseHeader';
import { SelectedTimelinesInfo } from '../GenericCaseDetailsView';
import BuddyCaseDaySummary from './components/BuddyCaseDaySummary';
import BuddyCaseHourByHour from './components/BuddyCaseHourByHour';
import BuddyCaseTodaySqueezes from './components/BuddyCaseTodaySqueezes';

const selectedTimelineInfoInitialData: SelectedTimelinesInfo = {
  caseAggregatedTimeline: null,
  dedicatedTimeline: null,
  selectedTimeline: null,
};

const BuddyCaseDetailsView = () => {
  const { organizationId, caseId } = useOrganizationCasePathParams();

  const dispatch = useDispatch();
  const isBrowser = useBrowserState();

  const { data, isSuccess: isCaseSuccess } = useCaseDetails({
    params: { organizationId, caseId },
    options: { enabled: false },
  });

  const [selectedTimelinesInfo, setSelectedTimelinesInfo] =
    useState<SelectedTimelinesInfo>(selectedTimelineInfoInitialData);

  const [selectedDate, setSelectedDate] = useState<Date>(
    new Date(format(new Date(), 'yyyy-MM-dd'))
  );

  const participant = data?.participants[0].personality;
  const participantName = `${participant?.first_name ?? ''} ${
    participant?.last_name ?? ''
  }`;

  const breadcrumbs = [
    {
      text: <FormattedMessage id="General.patients" />,
      link: generateOrganizationPersonalitiesUrl({ organizationId }),
      key: 'patients',
    },
    {
      text: participantName,
      link: participant
        ? generatePersonalityUrl({
            organizationId,
            personalityId: participant?.id,
          })
        : null,
      key: participantName,
    },
    {
      text: <FormattedMessage id="UserDashboard.cases" />,
      link: `/organizations/${organizationId}/cases`,
      key: 'cases',
    },
    {
      text: data?.title ?? '',
      link: null,
      key: 'title',
    },
  ];

  useEffect(() => {
    if (isCaseSuccess) {
      if (data.start_date && data.end_date) {
        const startDate = new Date(data.start_date);
        const endDate = new Date(data.end_date);
        dispatch(setCasePeriodDateRange({ from: startDate, to: endDate }));

        let initialDate = new Date();
        if (endDate < initialDate) {
          initialDate = endDate;
        } else if (startDate > initialDate) {
          initialDate = startDate;
        }
        setSelectedDate(initialDate);
      } else {
        const now = new Date();
        const last = now.getDate() - now.getDay() + 7;

        const lastDate = new Date(now.setDate(last));

        dispatch(setTimelineDetailsTimeRange(TimeRange.DAYS_7, lastDate));
      }
    }
  }, [isCaseSuccess, data?.start_date, data?.end_date, dispatch]);

  useEffect(() => {
    if (isCaseSuccess && data && data.timelines.length > 0) {
      const aggregateTimeline = data.timelines.find(
        (singleTimeline) => singleTimeline.is_master
      );

      const dedicatedTimeline = data.timelines.find(
        (timeline) => timeline.id === data?.participants[0]?.timeline?.id
      );

      const dedicatedOrFirst = dedicatedTimeline ?? data.timelines[0];

      setSelectedTimelinesInfo({
        caseAggregatedTimeline: aggregateTimeline ?? null,
        dedicatedTimeline: dedicatedTimeline ?? null,
        selectedTimeline: aggregateTimeline ?? dedicatedOrFirst,
      });
    } else setSelectedTimelinesInfo(selectedTimelineInfoInitialData);
  }, [isCaseSuccess, data, dispatch]);

  return (
    <>
      {isBrowser && (
        <Row className="d-flex align-items-center pe-3 mb-1 w-100">
          <Breadcrumbs items={breadcrumbs} autoWidth />
        </Row>
      )}
      <ChangeCaseHeader
        caseId={caseId}
        organizationId={organizationId}
        useHistory={false}
        caseTrackingType={CaseTrackingType.Buddy}
      />
      {data && selectedTimelinesInfo.selectedTimeline && (
        <div className="BuddyCaseContainer">
          <div className="d-flex flex-column gap-4 pt-4">
            <BuddyCaseTodaySqueezes
              selectedDate={selectedDate}
              timelineId={selectedTimelinesInfo.selectedTimeline.id}
              setSelectedDate={setSelectedDate}
            />
            <BuddyCaseDaySummary
              organizationId={organizationId}
              caseId={caseId}
              timelineId={selectedTimelinesInfo.selectedTimeline.id}
              selectedDate={selectedDate}
            />
            <BuddyCaseHourByHour
              organizationId={organizationId}
              caseId={caseId}
              timelineId={selectedTimelinesInfo.selectedTimeline.id}
              selectedDate={selectedDate}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default BuddyCaseDetailsView;
