import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddIcon from '@mui/icons-material/Add';
import { Chip } from '@mui/material';
import React from 'react';
import useOpen from '../../hooks/useOpen';
import EditableInput from '../EditableInput/EditableInput';
import IconButton from '../IconButton';
import { PillsTypes } from './types';

type PillsProps = {
  items: PillsTypes.IPillItem[];
  outline?: boolean;
  onDelete?: (id: string) => Promise<void>;
  onAdd?: (id: string) => Promise<void>;
  options?: PillsTypes.IPillItem[];
  size?: 'small' | 'medium';
};

const Pills = ({
  items,
  onDelete,
  onAdd,
  outline,
  options = [],
  size = 'medium',
}: PillsProps) => {
  const addMode = useOpen();
  const handleDelete = (id: string) => async () => await onDelete?.(id);

  const handleEdit = async (_: string, value: string) => {
    addMode.toggle();
    await onAdd?.(value);
  };

  return (
    <div className="d-flex align-items-center flex-wrap gap-2">
      {items.map((item) => (
        <Chip
          key={item.id}
          sx={{ backgroundColor: 'background.paper' }}
          variant={outline ? 'outlined' : 'filled'}
          color="primary"
          size={size}
          deleteIcon={<FontAwesomeIcon className="me-2 fs-6" icon={faTimes} />}
          onDelete={handleDelete(item.id)}
          label={item.name}
        />
      ))}
      {onAdd && (
        <>
          {addMode.isOpen ? (
            <div className="w-px-150">
              <EditableInput
                type="select"
                options={options.map((option) => ({
                  value: option.id,
                  text: option.name,
                }))}
                name="template"
                displayEmpty={true}
                onEdit={handleEdit}
                debounceEnabled={false}
              />
            </div>
          ) : (
            <>
              {options?.length > 0 && (
                <IconButton
                  color="primary"
                  variant="outlined"
                  size="small"
                  onClick={addMode.toggle}
                >
                  <AddIcon fontSize="inherit" />
                </IconButton>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Pills;
