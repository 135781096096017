import { signOut } from 'api/membership';
import { deleteApiUrl } from 'api/utils/request';

import { deleteUserData } from 'helpers/utils/auth';
import { displayErrorArrayNotifications } from 'modules/Notifications';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

function useLogout() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  return useMutationWithResultNotification<void, void>({
    asyncApiFunction: async () => {
      await signOut();
    },
    resetOnResult: true,
    onSuccess: () => {
      deleteUserData();
      deleteApiUrl();
      navigate('/login');
      queryClient.clear();
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(errorArray, 'Header.notifications.errors');
    },
  });
}

export default useLogout;
