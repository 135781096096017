import React from 'react';
import { Input } from 'reactstrap';

type SwitchControllerProps = {
  isToggled: boolean;
  onToggle: () => void;
  disabled?: boolean;
  id?: string;
  displayStatusText?: boolean;
};

const SwitchController = ({
  isToggled,
  onToggle,
  disabled = false,
  id,
  displayStatusText = false,
}: SwitchControllerProps) => {
  let text: JSX.Element | string = (
    <span className="switch__text__result">{isToggled ? 'ON' : 'OFF'}</span>
  );
  let sliderClassNames = 'slider';

  if (disabled) {
    text = 'Edit disabled';
    sliderClassNames = 'slider slider--disabled';
  }

  return (
    <label className="switch">
      <Input
        type="checkbox"
        checked={isToggled}
        disabled={disabled}
        onChange={onToggle}
        id={id}
      />
      <span className={sliderClassNames}></span>
      {displayStatusText && <p className="switch__text">{text}</p>}
    </label>
  );
};

export default SwitchController;
