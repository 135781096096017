import { useCallback, useEffect, useState } from 'react';

const useSyncRequestedEvent = (searchParams: URLSearchParams) => {
  const queryParams: Record<string, string> = Object.fromEntries([
    ...searchParams,
  ]);

  const [syncRequested, setSyncRequested] = useState<boolean>(
    Object.keys(queryParams).includes('synchronization_requested')
  );

  const onSyncFinished = useCallback(() => {
    setSyncRequested(false);
    window.dispatchEvent(new CustomEvent('trends-refetch-requested'));
    window.dispatchEvent(new CustomEvent('day-details-refetch-requested'));
  }, []);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('grasp-synchronization-finished', onSyncFinished);
    }

    return () => {
      if (typeof window !== 'undefined') {
        window.removeEventListener(
          'grasp-synchronization-finished',
          onSyncFinished
        );
      }
    };
  }, [onSyncFinished]);

  return syncRequested;
};

export default useSyncRequestedEvent;
