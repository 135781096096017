import {
  ConversationTemplateContext,
  ConversationTemplateTriggerType,
  QuestionValidConversationTemplateItem,
  ValidConversationTemplate,
  ValidConversationTemplateItem,
  ValidConversationTemplateItemType,
} from '../../../../../../../interfaces/conversations';
import { cloneDeep } from 'lodash';
import { MoveDirection } from '../types';
import React from 'react';

export const getEmptyConversationTemplate = (
  organizationId: string
): ValidConversationTemplate => {
  return {
    organization_id: organizationId,
    context: ConversationTemplateContext.UserConversation,
    trigger_type: ConversationTemplateTriggerType.Time,
    display_code: '',
    last_resort_template: false,
    content: [],
  };
};

export const onMoveDown = (
  index: number,
  values: ValidConversationTemplate,
  setValues: (
    values: React.SetStateAction<ValidConversationTemplate>,
    shouldValidate?: boolean | undefined
  ) => void
) => {
  const movedItem = values.content[index];

  if (
    movedItem.type !== ValidConversationTemplateItemType.Question &&
    movedItem.type !== ValidConversationTemplateItemType.Delimiter
  ) {
    moveItem(index, values, setValues, MoveDirection.Down);
    return;
  }

  if (movedItem.type === ValidConversationTemplateItemType.Question) {
    const elementsRelatedIndexes = getQuestionRelatedIndexes(
      values.content,
      movedItem
    );

    if (elementsRelatedIndexes.length === 0) {
      moveItem(index, values, setValues, MoveDirection.Down);
      return;
    }

    if (
      shouldMoveDownBasedOnIndex(elementsRelatedIndexes, values.content, index)
    )
      moveItem(index, values, setValues, MoveDirection.Down);

    return;
  }

  if (movedItem.type === ValidConversationTemplateItemType.Delimiter) {
    if (index + 1 === values.content.length - 1) return;

    const elementBelow = values.content[index + 1];

    if (elementBelow.condition === null) {
      moveItem(index, values, setValues, MoveDirection.Down);
      return;
    }

    if (
      shouldMoveDownBasedOnCondition(
        elementBelow,
        movedItem,
        index,
        values.content
      )
    )
      moveItem(index, values, setValues, MoveDirection.Down);
  }
};

export const onMoveUp = (
  index: number,
  values: ValidConversationTemplate,
  setValues: (
    values: React.SetStateAction<ValidConversationTemplate>,
    shouldValidate?: boolean | undefined
  ) => void
) => {
  const movedItem = values.content[index];

  if (
    movedItem.type === ValidConversationTemplateItemType.PlainText ||
    movedItem.type === ValidConversationTemplateItemType.Question ||
    movedItem.type === ValidConversationTemplateItemType.GraspUpload ||
    movedItem.type === ValidConversationTemplateItemType.MaterialsList ||
    movedItem.type === ValidConversationTemplateItemType.NotificationsList
  ) {
    moveItem(index, values, setValues, MoveDirection.Up);
    return;
  }

  if (
    movedItem.type === ValidConversationTemplateItemType.Feedback ||
    movedItem.type === ValidConversationTemplateItemType.Education
  ) {
    const itemConditionIncludesQuestion = movedItem.condition
      ? movedItem.condition.includes('Questions')
      : false;

    if (!itemConditionIncludesQuestion) {
      moveItem(index, values, setValues, MoveDirection.Up);
      return;
    }

    if (shouldMoveUpBasedOnCondition(movedItem, index, values.content))
      moveItem(index, values, setValues, MoveDirection.Up);

    return;
  }

  if (movedItem.type === ValidConversationTemplateItemType.Delimiter) {
    if (index - 1 === 0) return;

    const elementBelow = values.content[index + 1];

    if (elementBelow.type === ValidConversationTemplateItemType.Feedback)
      return;

    moveItem(index, values, setValues, MoveDirection.Up);
  }
};

const shouldMoveUpBasedOnCondition = (
  movedItem: ValidConversationTemplateItem,
  index: number,
  content: ValidConversationTemplateItem[]
) => {
  const subjectId = movedItem.condition?.slice(
    movedItem.condition.indexOf('[') + 1,
    movedItem.condition.indexOf(']')
  );

  if (!subjectId) return false;

  const itemsIndex = content.findIndex(
    (p) =>
      p.type === ValidConversationTemplateItemType.Question &&
      p.resource_id === subjectId
  );
  const newIndex = index - 1;

  const elementsBetween = content.slice(itemsIndex, index - 1);

  const thereIsNoDelimiterBetween = !elementsBetween.find(
    (p) => p.type === ValidConversationTemplateItemType.Delimiter
  );

  return !(itemsIndex >= newIndex || thereIsNoDelimiterBetween);
};

const shouldMoveDownBasedOnIndex = (
  elementsRelatedIndexes: number[],
  content: ValidConversationTemplateItem[],
  index: number
) => {
  const minRelatedIndex = Math.min(...elementsRelatedIndexes);
  const elementsBetween = content.slice(index + 2, minRelatedIndex + 1);
  const thereIsNoDelimiterBetween = !elementsBetween.find(
    (p) => p.type === ValidConversationTemplateItemType.Delimiter
  );

  return !thereIsNoDelimiterBetween;
};

const getQuestionRelatedIndexes = (
  content: ValidConversationTemplateItem[],
  movedItem: QuestionValidConversationTemplateItem
) => {
  return content
    .map((item, index) =>
      movedItem.resource_id && item.condition?.includes(movedItem.resource_id)
        ? index
        : -1
    )
    .filter((p) => p !== -1);
};

const shouldMoveDownBasedOnCondition = (
  elementBelow: ValidConversationTemplateItem,
  movedItem: ValidConversationTemplateItem,
  index: number,
  content: ValidConversationTemplateItem[]
) => {
  const isConditionQuestionRelated = elementBelow.condition
    ? elementBelow.condition.includes('Questions')
    : false;

  if (!isConditionQuestionRelated) return true;

  const subjectId = movedItem.condition?.slice(
    movedItem.condition.indexOf('[') + 1,
    movedItem.condition.indexOf(']')
  );

  if (!subjectId) return false;

  if (
    content.find(
      (p) =>
        p.type === ValidConversationTemplateItemType.Question &&
        p.resource_id === subjectId
    )
  )
    return false;
};

const moveItem = (
  index: number,
  values: ValidConversationTemplate,
  setValues: (values: ValidConversationTemplate) => void,
  direction: MoveDirection
) => {
  const movedItem = values.content[index];
  let valuesCopy = cloneDeep(values);
  let newContent = valuesCopy.content
    .slice(0, index)
    .concat(valuesCopy.content.slice(index + 1));

  let newIndex = index;

  if (direction === MoveDirection.Up) newIndex = index - 1;

  if (direction === MoveDirection.Down) newIndex = index + 1;

  newContent.splice(newIndex, 0, movedItem);

  setValues({
    ...values,
    content: newContent,
  });
};
