import CustomTable from 'components/CustomTable';
import { toConversationTemplate } from 'helpers/data/conversations';
import useOpen from 'hooks/useOpen';
import { IConversationTemplate } from 'interfaces/conversations';
import { BaseModalProps, ReactState } from 'interfaces/ui';
import React, { createContext, useMemo, useState } from 'react';
import ChangeCaseConversationItem from './components/ChangeCaseConversationItem';
import ChangeCaseConversationsFilters from './components/ChangeCaseConversationsFilters';
import { useCaseParticipant } from './hooks/useCaseParticipant';
import { useConversationAnswerHistoryData } from './hooks/useConversationAnswerHistoryData';
import { useConversationAnswerHistoryInitQuery } from './hooks/useConversationAnswerHistoryInitQuery';
import { useConversationAnswerHistoryLocalSearch } from './hooks/useConversationAnswerHistoryLocalSearch';
import ConversationTemplatePhoneModal from './modals/ConversationTemplatePhoneModal';

const headerItems = [
  'CaseConversationsScreen.header.participant',
  'CaseConversationsScreen.header.type',
  'CaseConversationsScreen.header.template',
  'CaseConversationsScreen.header.date',
  'CaseConversationsScreen.header.actions',
];

type ChangeCaseConversationSelection = {
  template: ReactState<IConversationTemplate | null>;
  date: ReactState<string>;
};

type ChangeCaseConversationModals = {
  phoneModal: BaseModalProps;
};

export const ChangeCaseConversationModalsContext =
  createContext<ChangeCaseConversationModals>({
    phoneModal: {
      isOpen: false,
      toggle: () => {},
    },
  });

export const ChangeCaseConversationSelectionContext =
  createContext<ChangeCaseConversationSelection>({
    date: {
      item: '',
      setItem: () => {},
    },
    template: {
      item: null,
      setItem: () => {},
    },
  });

const ConversationsTab = () => {
  const [selectedTemplate, setSelectedTemplate] =
    useState<IConversationTemplate | null>(null);

  const [selectedDate, setSelectedDate] = useState<string>('');

  const { isOpen, toggle } = useOpen();
  const { personality, name } = useCaseParticipant();
  const {
    conversationHistory,
    templateIdsState,
    _templateIdsState,
    quickSearchState,
  } = useConversationAnswerHistoryData();

  const { filterByQuickSearch } = useConversationAnswerHistoryLocalSearch();

  const [templateIds] = templateIdsState;
  const [quickSearch] = quickSearchState;

  useConversationAnswerHistoryInitQuery(conversationHistory, templateIds);

  const bodyRows = useMemo(() => {
    if (!conversationHistory.data) return [];

    let items = filterByQuickSearch(
      quickSearch ?? '',
      conversationHistory.data
    );

    return items.map((conversation) => {
      return (
        <ChangeCaseConversationItem
          key={conversation.id}
          conversation={conversation}
        />
      );
    });
  }, [conversationHistory.data, filterByQuickSearch, quickSearch]);

  const selectionContext: ChangeCaseConversationSelection = useMemo(() => {
    return {
      template: { item: selectedTemplate, setItem: setSelectedTemplate },
      date: { item: selectedDate, setItem: setSelectedDate },
    };
  }, [selectedDate, selectedTemplate]);

  const modalsContext: ChangeCaseConversationModals = useMemo(() => {
    return {
      phoneModal: { isOpen, toggle },
    };
  }, [isOpen, toggle]);

  return (
    <>
      {isOpen && selectedTemplate && (
        <ConversationTemplatePhoneModal
          date={selectedDate}
          participant={name}
          template={toConversationTemplate(selectedTemplate)}
          toggle={toggle}
          isOpen={isOpen}
          defaultCulture={personality?.culture}
        />
      )}
      <ChangeCaseConversationModalsContext.Provider value={modalsContext}>
        <ChangeCaseConversationSelectionContext.Provider
          value={selectionContext}
        >
          <div className="CaseCard__tab-container px-3 pt-4">
            <ChangeCaseConversationsFilters
              quickSearchState={quickSearchState}
              templateIdsState={templateIdsState}
              _templateIdsState={_templateIdsState}
            />
            <CustomTable
              isLoading={conversationHistory.isLoading}
              headerItems={headerItems}
              bodyRows={bodyRows}
            />
          </div>
        </ChangeCaseConversationSelectionContext.Provider>
      </ChangeCaseConversationModalsContext.Provider>
    </>
  );
};

export default ConversationsTab;
