import { useCallback } from 'react';
import { addNewItemOptions } from '../types';
import { ValidConversationTemplateItem } from '../../../../../../../../../../../interfaces/conversations';
import {
  getEmptyDelimiterItem,
  getEmptyEducationItem,
  getEmptyFeedbackItem,
  getEmptyGraspUploadItem,
  getEmptyMaterialsListItem,
  getEmptyNotificationsListItem,
  getEmptyPlainTextItem,
  getEmptyQuestionItem,
  getItemBasedOnConversationQuestion,
} from '../helpers';
import { useConversationQuestionsData } from './useConversationQuestionsData';
import { displayErrorNotification } from '../../../../../../../../../../Notifications';
import { useTranslations } from '../../../../../../../../../../../hooks/useTranslations';

export const useHandleAddItem = (
  selectedOption: string,
  selectedQuestion: string | null,
  onAdd: (item: ValidConversationTemplateItem) => void,
  toggle: () => void
) => {
  const { attachPrefix } = useTranslations(
    'ConversationsAndFeedbacks.ConversationTemplates.ConversationTemplatePhone.Conversation.AddConversationItem'
  );

  const { conversationQuestions } = useConversationQuestionsData();

  const getQuestionBasedOnExisting = useCallback(
    (id: string): ValidConversationTemplateItem => {
      const existingQuestion = conversationQuestions.find(
        (p) => p.resource_id === id
      );

      if (!existingQuestion) return getEmptyQuestionItem();
      return getItemBasedOnConversationQuestion(existingQuestion, true);
    },
    [conversationQuestions]
  );

  const getExistingQuestion = useCallback(
    (id: string): ValidConversationTemplateItem => {
      const existingQuestion = conversationQuestions.find(
        (p) => p.resource_id === id
      );

      if (!existingQuestion) return getEmptyQuestionItem();
      return getItemBasedOnConversationQuestion(existingQuestion, false);
    },
    [conversationQuestions]
  );

  return useCallback(() => {
    switch (selectedOption) {
      case addNewItemOptions[0]:
        onAdd(getEmptyPlainTextItem());
        break;
      case addNewItemOptions[1]:
        onAdd(getEmptyQuestionItem());
        break;
      case addNewItemOptions[2]:
        if (!selectedQuestion) {
          displayErrorNotification(attachPrefix('questionNotSelected'));
          break;
        }

        onAdd(getQuestionBasedOnExisting(selectedQuestion));
        break;
      case addNewItemOptions[3]:
        if (!selectedQuestion) {
          displayErrorNotification(attachPrefix('questionNotSelected'));
          break;
        }

        onAdd(getExistingQuestion(selectedQuestion));
        break;
      case addNewItemOptions[4]:
        onAdd(getEmptyGraspUploadItem());
        break;
      case addNewItemOptions[5]:
        onAdd(getEmptyNotificationsListItem());
        break;
      case addNewItemOptions[6]:
        onAdd(getEmptyFeedbackItem());
        break;
      case addNewItemOptions[7]:
        onAdd(getEmptyDelimiterItem());
        break;
      case addNewItemOptions[8]:
        onAdd(getEmptyMaterialsListItem());
        break;
      case addNewItemOptions[9]:
        onAdd(getEmptyEducationItem());
        break;
    }

    toggle();
  }, [
    attachPrefix,
    getExistingQuestion,
    getQuestionBasedOnExisting,
    onAdd,
    selectedOption,
    selectedQuestion,
    toggle,
  ]);
};
