import {
  AssessmentQuestion,
  AssessmentSummaryQuestion,
} from 'interfaces/assessmentForms';

function sortBySequence(
  questions: AssessmentSummaryQuestion[] | AssessmentQuestion[]
) {
  return questions.sort((prevQuestion, nextQuestion) => {
    if (prevQuestion.sequence > nextQuestion.sequence) return 1;
    if (prevQuestion.sequence < nextQuestion.sequence) return -1;
    else return 0;
  });
}

export default sortBySequence;
