import React, { lazy, Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler } from 'reactstrap';
import ContextSelector from '../ContextSelector';
import mobileLogo from 'assets/images/logo-horiz-white.png';
import headerLogo from 'assets/images/logo-blue-header.svg';
import useOpen from 'hooks/useOpen';
import { useBrowserState } from 'store/reducerHooks';
import { useCommonParams } from './hooks/useCommonParams';
import { generateRoute, RouteDefinitions } from 'helpers/routeDefinitions';
import UserNavigationContainer from './components/UserNavigation';
import OrganizationNavigation from './components/OrganizationNavigation';

const CreateOrganizationModal = lazy(
  () => import('modules/Organizations/modals/CreateOrganizationModal')
);

const Sidebar = lazy(() => import('../Sidebar'));

const Header = () => {
  const { organizationId } = useCommonParams();

  const sidebar = useOpen();
  const createOrganizationModal = useOpen();
  const navigate = useNavigate();
  const isBrowser = useBrowserState();

  const isOrganization = !!organizationId;

  const onLogoClick = () => {
    if (organizationId) return;
    navigate(generateRoute(RouteDefinitions.DASHBOARD));
  };

  if (!isBrowser)
    return (
      <Suspense fallback={null}>
        <CreateOrganizationModal
          isOpen={createOrganizationModal.isOpen}
          closeModal={createOrganizationModal.close}
        />
      </Suspense>
    );

  return (
    <>
      <Suspense fallback={null}>
        <Sidebar isOpen={sidebar.isOpen} toggle={sidebar.toggle} />
      </Suspense>
      <div className="Navbar">
        <Navbar light expand="lg" className="container-fluid">
          <NavbarBrand className="cursor-pointer" onClick={onLogoClick}>
            <img
              src={headerLogo}
              alt="logo"
              className="min-w-32 Navbar__logo"
            />
            <img
              src={mobileLogo}
              alt="small-logo"
              className="Navbar__small-logo"
            />
          </NavbarBrand>
          <div>
            <NavbarToggler
              onClick={sidebar.toggle}
              className="mt-2 mb-2 navbar-dark"
            />
          </div>
          <Collapse navbar>
            <Nav navbar className="Navigation">
              {isOrganization ? (
                <OrganizationNavigation />
              ) : (
                <UserNavigationContainer />
              )}
              <ContextSelector />
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    </>
  );
};

export default Header;
