import React, { useContext } from 'react';
import {
  CreatedMaterialContext,
  MaterialModalFormValues,
  MaterialModalSteps,
  MaterialModalStepsContext,
} from '../../types';
import FillInfoStep from './components/FillInfoStep';
import AddRulesStep from './components/AddRulesStep';
import UploadAssetsStep from './components/UploadAssetsStep';
import { FormikValuesProps } from '../../../../../../interfaces/forms';

type MaterialModalBodyProps = FormikValuesProps<MaterialModalFormValues> & {
  selectCategory: boolean;
};

const MaterialModalBody = ({
  values,
  setValues,
  selectCategory,
}: MaterialModalBodyProps) => {
  const { step } = useContext(MaterialModalStepsContext);

  const [createdMaterial] = useContext(CreatedMaterialContext);

  if (step === MaterialModalSteps.FillInfo)
    return (
      <FillInfoStep
        values={values}
        setValues={setValues}
        selectCategory={selectCategory}
      />
    );

  if (step === MaterialModalSteps.AddRules)
    return <AddRulesStep values={values} setValues={setValues} />;

  if (step === MaterialModalSteps.UploadAssets && createdMaterial)
    return <UploadAssetsStep material={createdMaterial} />;

  return <></>;
};

export default MaterialModalBody;
