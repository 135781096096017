import React, { useEffect, useMemo, useRef, useState } from 'react';
import SVG from 'react-inlinesvg';
import phoneIcon from '../../../../../assets/images/feedback_resource_phone.svg';
import gerdAvatar from '../../../../../assets/images/change/avatars/gerd.png';
import peroysteinAvatar from '../../../../../assets/images/change/avatars/peroystein.png';
import { format } from 'date-fns';
import InputRange from '../../../../../components/InputRange';
import { FormattedMessage, useIntl } from 'react-intl';
import { FeedbackResourcesSelectionInfo } from '../index';

type FeedbackResourcesPreviewProps = {
  selectionInfo: FeedbackResourcesSelectionInfo;
};

const FeedbackResourcesPreview = ({
  selectionInfo,
}: FeedbackResourcesPreviewProps) => {
  const intl = useIntl();
  const inputRangeChange = () => void 0;

  const phoneRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [offset, setOffset] = useState(0);

  const resetOffset = () => {
    setOffset(0);
    if (phoneRef.current) phoneRef.current.style.top = ``;
  };

  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY);

    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  useEffect(() => {
    if (phoneRef.current && containerRef.current && offset - 200 > 0)
      phoneRef.current.style.top = `${offset - 200}px`;
  }, [offset]);

  useEffect(() => {
    resetOffset();
  }, [selectionInfo.selectedGroupCode]);

  const basicLegendDescriptionSet = useMemo(() => {
    return [
      {
        value: 10,
        label: intl.formatMessage({
          id: 'FeedbackResources.notAtAll',
        }),
        label_NbNo: undefined,
      },
      {
        value: 90,
        label: intl.formatMessage({
          id: 'FeedbackResources.aLotOf',
        }),
        label_NbNo: undefined,
      },
    ];
  }, [intl]);

  const conversationEntryIcons = (
    <div className="FeedbackResourcePreviewConversation__icons">
      <div className="FeedbackResourcePreviewConversation__icon">
        <img
          src={gerdAvatar}
          alt={intl.formatMessage({
            id: 'FeedbackResources.doctor',
          })}
        />
      </div>
      <div className="FeedbackResourcePreviewConversation__icon">
        <img
          src={peroysteinAvatar}
          alt={intl.formatMessage({
            id: 'FeedbackResources.doctor',
          })}
        />
      </div>
    </div>
  );

  const conversationEntryHour = (
    <div className="FeedbackResourcePreviewConversationBubble__hour">
      {format(new Date(), 'HH:mm a')}
    </div>
  );

  return (
    <div
      className="FeedbackResourcesEditor__preview FeedbackResourcesPreview"
      ref={containerRef}
    >
      <div
        className="FeedbackResourcesPreview__phone FeedbackResourcesPreviewPhone"
        ref={phoneRef}
      >
        <SVG src={phoneIcon} title="phone" />
        <div className="FeedbackResourcesPreviewPhone__window FeedbackResourcesPreviewWindow">
          <div className="FeedbackResourcesPreviewWindow__conversation FeedbackResourcePreviewConversation">
            {conversationEntryIcons}
            <div className="FeedbackResourcePreviewConversation__bubble FeedbackResourcePreviewConversationBubble">
              <div>
                <FormattedMessage id="FeedbackResources.hi" />
              </div>
              {conversationEntryHour}
            </div>
          </div>
          {selectionInfo.selectedGroupCode && (
            <div className="FeedbackResourcesPreviewWindow__conversation FeedbackResourcePreviewConversation">
              <div className="FeedbackResourcePreviewConversation__bubble FeedbackResourcePreviewConversationBubble FeedbackResourcePreviewConversationBubble--question">
                <div>
                  {selectionInfo.selectedGroupCode}{' '}
                  <FormattedMessage id="FeedbackResources.feedbackRelatedQuestion" />
                  ?
                </div>
                {conversationEntryHour}
                <div className="mt-4">
                  <InputRange
                    legendDescriptions={basicLegendDescriptionSet}
                    key={`input-range-${selectionInfo.selectedGroupCode}`}
                    max={0}
                    min={100}
                    onChange={inputRangeChange}
                    value={28}
                    step={1}
                    showSentimentScale={false}
                    iconFeedbackReversed={false}
                  />
                </div>
              </div>
            </div>
          )}
          {selectionInfo.variantItems.map((variantItem) => (
            <div
              className="FeedbackResourcesPreviewWindow__conversation FeedbackResourcePreviewConversation"
              key={variantItem.text}
            >
              {conversationEntryIcons}
              <div className="FeedbackResourcePreviewConversation__bubble FeedbackResourcePreviewConversationBubble">
                <div>{variantItem.text}</div>
                {conversationEntryHour}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeedbackResourcesPreview;
