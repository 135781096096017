import { useObservationData } from './useObservationData';
import { useMemo } from 'react';
import { HourByHourMode } from '../../../index';
import { Meaning } from '../../../../../interfaces/timeline';
import { useSeries } from './useSeries';
import { useUpdateExistingEntry } from './useUpdateExistingEntry';
import { useObservationsBasedOnMode } from './useObservationsBasedOnMode';
import { useHourByHourChartBars } from './useHourByHourChartBars';
import { UseQueryResult } from 'react-query';
import { ICaseDetails } from '../../../../../interfaces/cases';

export const useHourByHourChart = (
  date: Date,
  mode: HourByHourMode,
  caseDetails: UseQueryResult<ICaseDetails>
) => {
  const {
    observationInTimelinesQuery,
    observationQuery,
    exercisesQuery,
    isLoading,
  } = useObservationData(date, caseDetails);

  const observations = useObservationsBasedOnMode(
    observationQuery.data,
    observationInTimelinesQuery.data ?? [],
    exercisesQuery.data ?? [],
    mode,
    date
  );

  const { uncategorizedSeriesData, customSeries, uncategorizedSeries } =
    useSeries(observations);

  const updateExistingEntry = useUpdateExistingEntry(observationQuery);

  const formattedData = useMemo(() => {
    if (customSeries.length === 0) return uncategorizedSeriesData;

    let customSeriesData: any[] = [];

    customSeries.forEach((entry) => {
      entry.data.forEach((dataEntry, index) => {
        const uncategorizedSqueezes = uncategorizedSeries?.at(0);

        let notSpecifiedEntryValue =
          uncategorizedSqueezes?.data?.at(index) ?? 0;
        let notSpecifiedDiff =
          notSpecifiedEntryValue - dataEntry < 0
            ? 0
            : notSpecifiedEntryValue - dataEntry;

        const customSeriesDataStatus = updateExistingEntry(
          customSeriesData,
          index,
          entry.name,
          dataEntry,
          uncategorizedSqueezes,
          notSpecifiedEntryValue
        );

        customSeriesData = customSeriesDataStatus.seriesData;
        if (customSeriesDataStatus.omitted) return;

        let newEntry = {
          name: index,
          [entry.name]: dataEntry,
        };

        if (!uncategorizedSqueezes) {
          customSeriesData.push(newEntry);
          return;
        }

        newEntry[Meaning.NotSpecified] = notSpecifiedDiff;
        customSeriesData.push(newEntry);
      });
    });

    return customSeriesData;
  }, [
    customSeries,
    uncategorizedSeries,
    uncategorizedSeriesData,
    updateExistingEntry,
  ]);

  const chartBars = useHourByHourChartBars(observations, formattedData);

  return { formattedData, chartBars, isLoading };
};
