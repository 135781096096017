import React from 'react';
import { useLegendText } from '../../hooks/useLegendText';

type HorizontalItemsProps = {
  payload: any[];
  lengthConstraint: number;
};

const HorizontalItems = ({
  payload,
  lengthConstraint,
}: HorizontalItemsProps) => {
  const getLegendText = useLegendText();
  const payloadSlice = payload.slice(0, lengthConstraint + 1);

  return (
    <>
      {payloadSlice.map((item, index) => (
        <div
          key={item.value}
          className="AwareCasesHorizontalLegend__item AwareCasesHorizontalLegendItem"
        >
          <div
            className="AwareCasesHorizontalLegendItem__circle"
            style={{ background: item.color }}
          />
          <span className="AwareCasesHorizontalLegendItem__title">
            {getLegendText((item.value as string) ?? '')}
          </span>
        </div>
      ))}
    </>
  );
};

export default HorizontalItems;
