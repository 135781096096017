import { useContext } from 'react';
import { ItemActionsContext } from '../../../../../types';
import { ItemBaseActions } from '../components/ItemBase/types';

export const useItemActions = (
  index: number,
  isFirst: boolean,
  isLast: boolean
): ItemBaseActions | undefined => {
  const actions = useContext(ItemActionsContext);

  if (!actions) return undefined;

  return {
    onMoveItemUp: isFirst ? undefined : () => actions?.onMoveItemUp?.(index),
    onMoveItemDown: isLast ? undefined : () => actions?.onMoveItemDown?.(index),
    onDeleteItem: () => actions?.onDeleteItem?.(index),
    onSelectItem: () => actions?.onSelectItem?.(index),
  };
};
