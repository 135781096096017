import React from 'react';
import useInfiniteCaseHistory from 'api/queries/cases/useInfiniteCaseHistory';
import { HISTORY_PAGE_SIZE } from 'helpers/constants';
import { ObservationListProps } from 'interfaces/ui';
import TimelineObservationList from './TimelineObservationList';

interface CaseObservationListProps extends ObservationListProps {
  organizationId: string;
  caseId: string;
}

const CaseObservationList = ({
  caseId,
  organizationId,
  ...props
}: CaseObservationListProps) => {
  const { history: historyData } = useInfiniteCaseHistory({
    params: {
      caseId,
      organizationId,
      pageSize: HISTORY_PAGE_SIZE,
      selectedDates: props.selectedDates,
    },
    options: { enabled: false },
  });

  return <TimelineObservationList {...props} historyFromCase={historyData} />;
};

export default CaseObservationList;
