import {
  AssessmentCompletionStatus,
  IAssessmentPeriodInfo,
} from 'interfaces/assessmentForms';
import { ICaseHistory } from 'interfaces/caseHistory';
import {
  AnnotationBubbleData,
  BubbleType,
  FormBubbleData,
  HistoryBubbleData,
  IObservation,
  ITimelineAnnotation,
  ObservationBubbleData,
  SingleDataBubbleValue,
} from 'interfaces/timeline';

const returnDateWithoutTime = (date: string) => {
  const newDate = new Date(date);
  newDate.setHours(0, 0, 0, 0);

  return newDate;
};

const returnTime = (date: Date) => {
  const timeDate = new Date(0);
  timeDate.setHours(
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds()
  );
  return timeDate.getTime();
};

const STANDARD_BUBBLE_SIZE = 5;

export function transformDataIntoBubbles(
  dateRange: {
    from: Date;
    to: Date;
  },
  observations: IObservation[] | undefined,
  annotations: ITimelineAnnotation[] | undefined,
  history: ICaseHistory[] | undefined,
  forms: IAssessmentPeriodInfo[] | undefined,
  loggedUserId: string
) {
  const data: SingleDataBubbleValue[] = [];
  const { from: minDate, to: maxDate } = dateRange;

  if (observations) {
    observations.forEach((observation) => {
      const date = new Date(observation.timestamp);
      const dateWithoutTime = returnDateWithoutTime(observation.timestamp);

      const observationBubbleData: ObservationBubbleData = {
        date,
        type: BubbleType.OBSERVATION,
        x: returnTime(date),
        y: dateWithoutTime.getTime(),
        z: observation.level,
        text: observation.description,
        amplitudes: observation.amplitudes,
        painScaleLevel: observation.level,
        symptom: observation.symptom,
        painScaleCategory: observation.level_category,
      };

      let childrenBubbleData: ObservationBubbleData[] = [];

      observation.children_observations.forEach((child) => {
        childrenBubbleData.push({
          date: new Date(child.timestamp),
          type: BubbleType.OBSERVATION,
          x: returnTime(new Date(child.timestamp)),
          y: returnDateWithoutTime(child.timestamp).getTime(),
          z: child.level,
          text: child.description,
          amplitudes: child.amplitudes,
          painScaleLevel: child.level,
          symptom: child.symptom,
          painScaleCategory: child.level_category,
        });
      });

      data.push(observationBubbleData);
      data.push(...childrenBubbleData);
    });
  }
  if (annotations) {
    const filteredAnnotations = annotations?.filter((element) => {
      const beginDate = new Date(element.time_start);

      return (
        beginDate.getTime() <= maxDate.getTime() &&
        beginDate.getTime() >= minDate.getTime()
      );
    });

    filteredAnnotations.forEach((annotation) => {
      const date = new Date(annotation.time_start);
      const endDate = new Date(annotation.time_end);
      const dateWithoutTime = returnDateWithoutTime(annotation.time_start);

      const annotationBubbleData: AnnotationBubbleData = {
        date,
        endDate,
        type: BubbleType.ANNOTATION,
        x: returnTime(date),
        y: dateWithoutTime.getTime(),
        z: STANDARD_BUBBLE_SIZE,
        text: annotation.text,
      };

      data.push(annotationBubbleData);
    });
  }
  if (history) {
    history.forEach((historyEntry) => {
      const date = new Date(historyEntry.time_start);
      const endDate = new Date(historyEntry.time_end);
      const dateWithoutTime = returnDateWithoutTime(historyEntry.time_start);

      const historyBubbleData: HistoryBubbleData = {
        date,
        endDate,
        type: BubbleType.HISTORY_ENTRY,
        x: returnTime(date),
        y: dateWithoutTime.getTime(),
        z: STANDARD_BUBBLE_SIZE,
        text: historyEntry.text,
      };

      data.push(historyBubbleData);
    });
  }
  if (forms) {
    forms
      .filter((p) => p.showOnDayByDay)
      .forEach((formItem) => {
        const [hour, seconds] = formItem.hour
          .split(':')
          .map((el) => parseFloat(el));

        const date = formItem?.fillDate
          ? new Date(formItem.fillDate)
          : new Date(
              formItem.year,
              formItem.month - 1,
              formItem.day,
              hour,
              seconds
            );

        const dateWithoutTime = new Date(date);
        dateWithoutTime.setHours(0, 0, 0, 0);

        const canEdit =
          (loggedUserId === formItem.userId ||
            formItem.isEditableByOrganizationMembers) &&
          formItem.isAvailableToCompletion;

        const isCompleted =
          formItem.completionStatus === AssessmentCompletionStatus.COMPLETED;
        const canOpen = !canEdit && isCompleted;

        const formBubbleData: FormBubbleData = {
          date,
          type: BubbleType.FORM,
          x: returnTime(date),
          y: dateWithoutTime.getTime(),
          z: STANDARD_BUBBLE_SIZE,
          text: formItem.description,
          title: formItem.title,
          isCompleted: isCompleted,
          canOpen,
          canEdit,
          contextObjectId: formItem.contextObjectId,
          contextType: formItem.contextType,
          completionStatus: formItem.completionStatus,
          sequence: formItem.sequence,
        };

        data.push(formBubbleData);
      });
  }

  return data;
}

export default transformDataIntoBubbles;
