import { IAsyncFormHookValues } from '../../../../../../../../../interfaces/forms';
import { useIntl } from 'react-intl';
import { useTypedContext } from '../../../../../../../../../hooks/useTypedContext';
import { OrganizationDetailsContext } from '../../../../../types';
import { useCallback, useMemo } from 'react';
import { FormikErrors } from 'formik';
import { validateSubsetEmpty } from '../../../../../../../../../helpers/utils/validators';
import usePatchOrganization from '../../../../../../../../../api/mutations/organizations/usePatchOrganization';
import { useCommonData } from '../../../../../hooks/useCommonData';
import { getPatchObject } from '../../../../../../../../../helpers/patchObject';
import { OrganizationInfoTabTypes } from '../types';
import { OpenHookValues } from '../../../../../../../../../hooks/useOpen';
import useUploadOrganizationImage from '../../../../../../../../../api/mutations/organizations/useUploadOrganizationImage';
import { OrganizationUploadTarget } from '../../../../../../../../../interfaces/organizations';

export const useForm = (
  editMode: OpenHookValues
): IAsyncFormHookValues<OrganizationInfoTabTypes.FormValues> => {
  const intl = useIntl();
  const organization = useTypedContext(OrganizationDetailsContext);

  const { organizationDetails } = useCommonData();

  const onSuccess = useCallback(async () => {
    await organizationDetails.refetch();
    editMode.toggle();
  }, [editMode, organizationDetails]);

  const { mutateAsync: patchAsync } = usePatchOrganization(organization.id);

  const { mutateAsync: uploadImageAsync } = useUploadOrganizationImage({
    params: { organizationId: organization.id },
    options: {},
  });

  const initialValues = useMemo((): OrganizationInfoTabTypes.FormValues => {
    return {
      name: organization.name,
      description: organization.description ?? '',
      image_url: organization.image_url,
      image: null,
      logo_url: organization.logo_url,
      logo: null,
    };
  }, [
    organization.description,
    organization.image_url,
    organization.logo_url,
    organization.name,
  ]);

  const handleSubmit = useCallback(
    async (values: typeof initialValues) => {
      let promises: Promise<any>[] = [];

      const patchObject = getPatchObject(values, initialValues, [
        'image',
        'image_url',
        'logo',
        'logo_url',
      ]);

      promises.push(patchAsync(patchObject));

      if (values.image)
        promises.push(
          uploadImageAsync({
            target: OrganizationUploadTarget.Image,
            file: values.image,
          })
        );

      if (values.logo)
        promises.push(
          uploadImageAsync({
            target: OrganizationUploadTarget.Logo,
            file: values.logo,
          })
        );

      Promise.all(promises).then(onSuccess);
    },
    [initialValues, onSuccess, patchAsync, uploadImageAsync]
  );

  const validation = useCallback(
    (values: typeof initialValues): FormikErrors<typeof initialValues> => {
      return validateSubsetEmpty(values, ['name', 'description'], intl);
    },
    [intl]
  );

  return {
    initialValues: initialValues,
    handleSubmit,
    validation,
  };
};
