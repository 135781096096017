import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React from 'react';
import SVG from 'react-inlinesvg';
import { Button } from 'reactstrap';
import { useIconDefinitionCheck } from '../../hooks/useIconDefinitionCheck';
import { ThinHeaderActionDefinition } from '../../types/ThinHeader';

type ThinHeaderActionProps = {
  action: ThinHeaderActionDefinition;
};

const ThinHeaderAction = ({ action }: ThinHeaderActionProps) => {
  const isIconDefinition = useIconDefinitionCheck();

  const handleAction = async () => {
    action.onAction?.();
    await action.onAsyncAction?.();
  };

  return (
    <Button
      outline={true}
      disabled={action.disabled ?? !action.onAction}
      color={action.color ?? 'primary'}
      key={action.key}
      className={classNames(action.className)}
      onClick={handleAction}
    >
      {isIconDefinition(action.icon) ? (
        <FontAwesomeIcon
          icon={action.icon}
          spin={action.isLoading}
          width={20}
          height={20}
        />
      ) : (
        <SVG src={action.icon} title={action.key} width={20} height={20} />
      )}
    </Button>
  );
};

export default ThinHeaderAction;
