import { useMemo, useState } from 'react';
import CarrouselController from 'components/CarrouselController';
import useUserContextAssessmentFormSummary from 'api/queries/assessmentForms/summary/useUserContextAssessmentFormSummary';
import useOrganizationContextAssessmentFormSummary from 'api/queries/assessmentForms/summary/useOrganizationContextAssessmentFormSummary';
import { Spinner } from 'reactstrap';
import HeaderSummaryEmptyDataPlaceholder from './HeaderSummaryEmptyDataPlaceholder';
import HeaderSummaryDashboardContent from './HeaderSummaryDashboardContent';

type HeaderSummaryProps = {
  title: string;
  userId: string;
  timelineId: string;
  organizationId?: string;
  caseId?: string;
};

const HeaderSummaryDashboard = ({
  userId,
  timelineId,
  organizationId = '',
  caseId = '',
}: HeaderSummaryProps) => {
  const [selectedIndex, setSelectedIndex] = useState<number>(0);

  const {
    data: organizationContextData,
    isFetching: organizationContextIsLoading,
    isFetched: organizationContextIsFetched,
  } = useOrganizationContextAssessmentFormSummary({
    params: { organizationId, caseId, timelineId },
    options: { enabled: !!(organizationId && caseId) },
  });

  const {
    data: userContextData,
    isFetching: userContextIsLoading,
    isFetched: userContextIsFetched,
  } = useUserContextAssessmentFormSummary({
    params: { userId, timelineId },
    options: { enabled: !(organizationId && caseId) },
  });

  const data =
    organizationId && caseId ? organizationContextData : userContextData;

  const isLoading =
    organizationId && caseId
      ? organizationContextIsLoading
      : userContextIsLoading;

  const isDataEmpty =
    organizationId && caseId
      ? organizationContextIsFetched && (!data || data.length === 0)
      : userContextIsFetched && data && (!data || data.length === 0);

  const numberOfItems = useMemo(() => {
    if (data?.length) return Math.ceil(data.length / 2);
    return 0;
  }, [data]);

  const onSelect = (index: number) => setSelectedIndex(index);

  if (isDataEmpty) {
    return (
      <div className="HeaderSummaryDashboard">
        <HeaderSummaryEmptyDataPlaceholder titleTranslationId="UserDashboard.noStatusInfoFor7Days" />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="HeaderSummaryDashboard">
        <div className="d-flex justify-content-center h-100">
          <div className="w-100 mt-4 d-flex justify-content-center">
            <Spinner color="primary" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="HeaderSummaryDashboard">
      <div className="d-flex flex-column gap-3 h-100 justify-content-between align-items-center">
        <HeaderSummaryDashboardContent
          isLoading={isLoading}
          selectedIndex={selectedIndex}
          data={data}
        />
        {data && (
          <div className="pb-4">
            <CarrouselController
              activeIndex={selectedIndex}
              numberOfItems={numberOfItems}
              onSelect={onSelect}
              rowDirection={true}
              center
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderSummaryDashboard;
