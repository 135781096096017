import {
  addDays,
  addHours,
  addMinutes,
  setHours,
  setMinutes,
  setSeconds,
} from 'date-fns';
import { useCallback } from 'react';
import {
  getISOStringUTCNonAware,
  strToDateOnly,
} from '../../../../../../../../../../../../../../../helpers/dates';
import { useTypedContext } from '../../../../../../../../../../../../../../../hooks/useTypedContext';
import { CasePhaseHistoryContext } from '../../../../../types';

export const useHandleDates = () => {
  const casePhaseHistory = useTypedContext(CasePhaseHistoryContext);

  const getDate = useCallback(
    (day: number | undefined, hour: string) => {
      if (!day) return undefined;

      casePhaseHistory.sort((a, b) => b.sequence - a.sequence);
      let currentCasePhaseHistory = casePhaseHistory.at(0);

      if (!currentCasePhaseHistory) return undefined;

      let targetDate = addDays(
        new Date(currentCasePhaseHistory.date_from),
        day - 1
      );

      targetDate = setHours(targetDate, 0);
      targetDate = setMinutes(targetDate, 0);
      targetDate = setSeconds(targetDate, 0);

      const hourSplit = hour.split(':');

      targetDate = addHours(targetDate, parseInt(hourSplit[0]));
      targetDate = addMinutes(targetDate, parseInt(hourSplit[1]));

      return getISOStringUTCNonAware(targetDate);
    },
    [casePhaseHistory]
  );

  const normalizeIsoDate = useCallback((str: string) => {
    const dateStr = strToDateOnly(str);
    const date = new Date(dateStr);

    return date.toISOString();
  }, []);

  return {
    getDate,
    normalizeIsoDate,
  };
};
