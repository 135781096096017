import { useMemo } from 'react';
import { buildMaterialTranslations } from '../../../../../helpers';
import { CultureEntry } from '../../../../../../../interfaces/cultures';

export const useMaterialTranslations = (
  nameTranslations: CultureEntry<string>[],
  descriptionTranslations: CultureEntry<string>[]
) => {
  return useMemo(() => {
    return buildMaterialTranslations(nameTranslations, descriptionTranslations);
  }, [descriptionTranslations, nameTranslations]);
};
