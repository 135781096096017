import { AssetType } from 'interfaces/educationMaterials';
import { useMemo } from 'react';

export const useAssetAcceptType = (type: AssetType) => {
  return useMemo(() => {
    switch (type) {
      case AssetType.Video:
        return 'video/mp4';
      case AssetType.Image:
        return 'image/png, image/jpeg';
      case AssetType.Attachment:
        return '.doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf';
    }
  }, [type]);
};
