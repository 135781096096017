import {
  QuestionValidConversationTemplateItem,
  ValidConversationQuestionType,
} from '../../../../../../../../../../../../interfaces/conversations';
import ClosedListQuestionItem from './components/ClosedListQuestionItem';
import EmailQuestionItem from './components/EmailQuestionItem';
import HeaderQuestionItem from './components/HeaderQuestionItem';
import OpenQuestionItem from './components/OpenQuestionItem';
import RangeQuestionItem from './components/RangeQuestionItem';
import TimeQuestionItem from './components/TimeQuestionItem';
import { memo } from 'react';

type QuestionItemProps = {
  item: QuestionValidConversationTemplateItem;
};

const QuestionItem = ({ item }: QuestionItemProps) => {
  switch (item.value.type) {
    case ValidConversationQuestionType.ClosedList:
      return <ClosedListQuestionItem item={item.value} />;
    case ValidConversationQuestionType.Email:
      return <EmailQuestionItem />;
    case ValidConversationQuestionType.Header:
      return <HeaderQuestionItem item={item.value} />;
    case ValidConversationQuestionType.Open:
      return <OpenQuestionItem item={item.value} />;
    case ValidConversationQuestionType.Range:
      return <RangeQuestionItem item={item.value} />;
    case ValidConversationQuestionType.Time:
      return <TimeQuestionItem />;
  }
};

export default memo(QuestionItem);
