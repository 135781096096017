import React from 'react';
import { deleteUserData, getTokenData } from '../../helpers/utils/auth';
import {
  displayErrorNotification,
  displayNotification,
} from 'modules/Notifications';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { Button } from 'reactstrap';
import { refreshAccessToken } from '../membership';
import { AxiosInstance } from 'axios';
import { deleteApiUrl, getApiUrl } from './request';
import { WRONG_PASSWORD_ERROR_CODE } from 'helpers/constants';
import history from 'helpers/history';

let timeout: null | NodeJS.Timeout = null;

const setTokenData = (accessToken: string) => {
  getTokenData(accessToken);
};

const onRefreshClick = async () => {
  const response = await refreshAccessToken();
  try {
    const { token, valid_until } = response?.data?.data ?? {};

    getTokenData(token);

    timeout = returnSetTimeoutWithToastNotification(valid_until);
  } catch (err) {
    timeout && clearTimeout(timeout);
    displayErrorNotification('General.refreshNotSuccessful');
  }
};

const displaySingOutToast = () => {
  const autoClose = process.env.REACT_APP_REFRESH_TOKEN_TOAST_AUTO_CLOSE
    ? parseInt(process.env.REACT_APP_REFRESH_TOKEN_TOAST_AUTO_CLOSE)
    : 5000;

  return displayNotification(
    <div className="signOutToast p-1">
      <FormattedMessage id="General.signOutNotification" />
      <Button
        onClick={onRefreshClick}
        color="primary"
        className="signOutToast__refreshButton mt-2"
      >
        <FormattedMessage id="General.refreshToken" />
      </Button>
    </div>,
    {
      type: toast.TYPE.DEFAULT,
      autoClose,
      closeOnClick: true,
    }
  );
};

const returnSetTimeoutWithToastNotification = (expDateString: string) => {
  if (timeout) {
    clearTimeout(timeout);
  }

  const secondsBeforeNotification = process.env
    .REACT_APP_REFRESH_TOKEN_TOAST_SECONDS_BEFORE_EXPIRATION
    ? parseInt(
        process.env.REACT_APP_REFRESH_TOKEN_TOAST_SECONDS_BEFORE_EXPIRATION
      )
    : 30;

  const MsBeforeNotification = secondsBeforeNotification * 1000;

  const timeoutTime = new Date(expDateString).getTime() - new Date().getTime();

  const differenceInMs = timeoutTime - MsBeforeNotification;

  if (expDateString && differenceInMs > 1) {
    return setTimeout(() => {
      displaySingOutToast();
    }, differenceInMs);
  } else return null;
};

export const refreshTokenInterceptor = (axiosClient: AxiosInstance) => {
  const onResponseIntercept = (response) => {
    if (response.config.url.includes('login')) {
      const { valid_until } = response?.data?.data ?? {};

      timeout = returnSetTimeoutWithToastNotification(valid_until);
    } else if (
      response.headers['x-new-access-token'] &&
      response.headers['x-new-access-token-valid-until']
    ) {
      setTokenData(response.headers['x-new-access-token']);

      timeout = returnSetTimeoutWithToastNotification(
        response.headers['x-new-access-token-valid-until']
      );
    } else {
      const cookieExpirationDateString = Cookies.get('token_expiration_date');

      if (
        !timeout &&
        cookieExpirationDateString &&
        !!Cookies.get('access_token')
      ) {
        timeout = returnSetTimeoutWithToastNotification(
          cookieExpirationDateString
        );
      }
    }

    return response;
  };

  const errorInterceptor = (error) => {
    const isLoginWrongPasswordResponse =
      error?.response?.data?.meta?.errors?.some(
        (el) => el.code === WRONG_PASSWORD_ERROR_CODE
      ) ?? false;

    if (
      !isLoginWrongPasswordResponse &&
      (error.response.status === 401 || getApiUrl() === null)
    ) {
      deleteUserData();
      deleteApiUrl();
      history.push('/login');
      timeout && clearTimeout(timeout);

      if (error?.response?.config?.url.includes('/invitation_requests/')) {
        localStorage.setItem('redirectUrl', error?.response?.config?.url);
      }
    }

    return Promise.reject(error);
  };

  axiosClient.interceptors.response.use(onResponseIntercept, errorInterceptor);
};
