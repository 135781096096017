import { IMutationRequest } from '../../../interfaces/request';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { deleteAwareSessionData } from '../../awareSessions';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { IErrorDefinition } from '../../../interfaces/response';

type DeleteAwareSessionDataParams = {
  organizationId: string;
};

const useDeleteAwareSessionData = ({
  params,
  options,
  silent,
}: IMutationRequest<DeleteAwareSessionDataParams>) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (caseId: string) => {
      await deleteAwareSessionData(params.organizationId, caseId);
    },
    onSuccess: () => {
      if (!silent)
        displaySuccessNotification(
          'Api.Mutations.AwareSessions.DeleteAwareSession.success'
        );

      options.successFb?.();
    },
    onErrorArray: (errors: IErrorDefinition[]) => {
      displayErrorArrayNotifications(
        errors,
        'Api.Mutations.AwareSessions.DeleteAwareSession.failure'
      );
      options.errorFb?.();
    },
    resetOnResult: true,
  });
};

export default useDeleteAwareSessionData;
