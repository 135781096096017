import { alpha, styled } from '@mui/material/styles';
import { DataGrid, gridClasses } from '@mui/x-data-grid';

const opacity: number = 0.2;
const hoverOpacity: number = 0.5;
const lightBlueAlternative: string = '#e0ebf4';
const gray: string = '#acacac';

const StripedDataGrid = styled(DataGrid)(({ theme }) => ({
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: alpha(lightBlueAlternative, opacity),
    '&:hover, &.Mui-hovered': {
      backgroundColor:
        theme.palette.mode === 'dark'
          ? alpha(lightBlueAlternative, opacity)
          : alpha(lightBlueAlternative, hoverOpacity),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.row}.odd`]: {
    '&:hover, &.Mui-hovered': {
      backgroundColor:
        theme.palette.mode === 'dark'
          ? 'inherit'
          : alpha(lightBlueAlternative, hoverOpacity),
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      },
    },
  },
  [`& .${gridClasses.columnHeaderTitle}`]: {
    color: gray,
  },
  [`& .${gridClasses.cell}`]: {
    border: 0,
  },
  [`& .${gridClasses.cell}:focus`]: {
    outline: 0,
  },
  [`& .${gridClasses.cell}:focus-within`]: {
    outline: 0,
  },
  [`& .${gridClasses.columnHeader}:focus`]: {
    outline: 0,
  },
  [`& .${gridClasses.columnHeaders}`]: {
    border: 0,
  },
  border: 0,
}));

export default StripedDataGrid;
