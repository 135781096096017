import React from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';
import warningSignIcon from '../../../../../../../../../../assets/images/warning-sign.svg';

const NoDefaultTemplatePlaceholder = () => {
  return (
    <div className="TreatmentPhaseNoDefaultTemplatePlaceholder">
      <SVG
        className="TreatmentPhaseNoDefaultTemplatePlaceholder__icon"
        src={warningSignIcon}
      />
      <FormattedMessage
        tagName="span"
        id="Projects.ProjectPhases.ProjectPhasesContent.ProjectPhase.ProjectPhaseNoDefaultTemplatePlaceholder.content"
      />
    </div>
  );
};

export default NoDefaultTemplatePlaceholder;
