import AddIcon from '@mui/icons-material/Add';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import useInfiniteGraspList from 'api/queries/grasps/useInfiniteGraspList';
import CustomDataGrid from 'components/CustomDataGrid';
import QueryLoaded from 'components/QueryLoaded';
import { returnAllDataFromResponseData } from 'helpers/utils/reactQueryHelpers';
import { useOrganizationPathParams } from 'hooks/paths/useOrganizationPathParams';
import { useInitQuery } from 'hooks/queries/useInitQuery';
import React from 'react';
import { CustomMaterialTableHelpers } from '../../../../../../../../components/CustomDataGrid/helpers';
import Guard from '../../../../../../../../components/Guard';
import IconButton from '../../../../../../../../components/IconButton';
import TabLayout, {
  TabLayoutType,
} from '../../../../../../../../components/layouts/TabLayout';
import useOpen from '../../../../../../../../hooks/useOpen';
import { UserRoles } from '../../../../../../../../interfaces/membership';
import AddGraspModal from '../../../../modals/AddGraspModal';
import ManualCreateGraspModal from '../../../../modals/ManualCreateGraspModal';
import TransferGraspModal from '../../../../modals/TransferGraspModal';
import UploadGraspsModal from '../../../../modals/UploadGraspsModal';
import { useColumns } from './hooks/useColumns';
import { useGraspModalMode } from './hooks/useGraspModalMode';

const Grasps = () => {
  const { organizationId } = useOrganizationPathParams();

  const {
    addGraspModal,
    createGraspModal,
    uploadGraspModal,
    handleCreate,
    handleUpload,
  } = useGraspModalMode();

  const transferGraspModal = useOpen();

  const grasps = useInfiniteGraspList({
    params: {
      organizationId,
      pageSize: 1000,
    },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(grasps);

  const columns = useColumns();

  return (
    <TabLayout
      type={TabLayoutType.Breadcrumb}
      titlePrefix="Organizations.Grasps"
      actions={
        <div className="d-flex align-items-center gap-2">
          <Guard roleNeeded={UserRoles.GLOBAL_ADMIN}>
            <IconButton
              color="primary"
              variant="outlined"
              onClick={addGraspModal.toggle}
            >
              <AddIcon />
            </IconButton>
          </Guard>
          <Guard roleNeeded={UserRoles.GLOBAL_ADMIN}>
            <IconButton
              color="primary"
              variant="outlined"
              onClick={transferGraspModal.toggle}
            >
              <SyncAltIcon />
            </IconButton>
          </Guard>
        </div>
      }
    >
      <QueryLoaded query={grasps}>
        {(data) => (
          <div className="mt-4 text-muted">
            <TransferGraspModal
              isOpen={transferGraspModal.isOpen}
              toggle={transferGraspModal.toggle}
            />
            <AddGraspModal
              selectCreateGraspMode={handleCreate}
              selectUploadGraspsMode={handleUpload}
              isOpen={addGraspModal.isOpen}
              toggleModal={addGraspModal.toggle}
            />
            <ManualCreateGraspModal
              refetchGrasps={grasps.refetch}
              isOpen={createGraspModal.isOpen}
              toggleModal={createGraspModal.toggle}
              organizationId={organizationId}
            />
            <UploadGraspsModal
              isOpen={uploadGraspModal.isOpen}
              toggleModal={uploadGraspModal.toggle}
            />
            <CustomDataGrid
              onCellDoubleClick={
                CustomMaterialTableHelpers.DefaultMuiPreventedEvent
              }
              onCellEditStart={
                CustomMaterialTableHelpers.DefaultMuiPreventedEvent
              }
              onCellEditStop={
                CustomMaterialTableHelpers.DefaultMuiPreventedEvent
              }
              onCellKeyDown={
                CustomMaterialTableHelpers.DefaultMuiPreventedEvent
              }
              onCellClick={CustomMaterialTableHelpers.DefaultMuiPreventedEvent}
              rowHeight={64}
              rows={returnAllDataFromResponseData(data)}
              columns={columns}
            />
          </div>
        )}
      </QueryLoaded>
    </TabLayout>
  );
};

export default Grasps;
