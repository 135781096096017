import React, { useMemo, useState } from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { BaseModalProps } from '../../../../interfaces/ui';
import { Formik } from 'formik';
import MaterialModalHeader from './components/MaterialModalHeader';
import {
  CreatedMaterialContext,
  MaterialModalContext,
  MaterialModalFormContext,
  MaterialModalModeContext,
  MaterialModalStepsContext,
  MaterialProps,
} from './types';
import { useForm } from './hooks/useForm';
import { useSteps } from './hooks/useSteps';
import { useModalMode } from './hooks/useModalMode';
import { IEducationMaterial } from '../../../../interfaces/educationMaterials';
import MaterialModalBody from './components/MaterialModalBody';
import MaterialModalFooter from './components/MaterialModalFooter';

type MaterialModalProps = BaseModalProps &
  MaterialProps & {
    initialStepIndex?: number;
    initialCategoryId?: string;
    selectCategory?: boolean;
  };

const MaterialModal = ({
  isOpen,
  toggle,
  material,
  initialStepIndex = 0,
  initialCategoryId,
  selectCategory = false,
}: MaterialModalProps) => {
  const steps = useSteps(material, initialStepIndex);
  const mode = useModalMode(material);

  const createdMaterial = useState<IEducationMaterial | undefined>(
    material ?? undefined
  );

  const [, setCreatedMaterial] = createdMaterial;

  const formValues = useForm({
    material,
    mode,
    steps,
    toggle,
    setCreatedMaterial,
    initialCategoryId,
  });

  const baseModalContext = useMemo(
    (): BaseModalProps => ({ isOpen, toggle }),
    [isOpen, toggle]
  );

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="GenericModal BorderlessModal AddEducationMaterialModal"
      size="lg"
    >
      <MaterialModalContext.Provider value={baseModalContext}>
        <MaterialModalModeContext.Provider value={mode}>
          <MaterialModalStepsContext.Provider value={steps}>
            <ModalHeader
              className="AddEducationMaterialModal__header"
              toggle={toggle}
            >
              <MaterialModalHeader />
            </ModalHeader>
            <CreatedMaterialContext.Provider value={createdMaterial}>
              <Formik
                validate={formValues.validation}
                validateOnMount={!material}
                initialValues={formValues.initialValues}
                enableReinitialize={true}
                onSubmit={formValues.handleSubmit}
              >
                {(props) => (
                  <MaterialModalFormContext.Provider value={formValues}>
                    <ModalBody className="AddEducationMaterialModal__body">
                      <MaterialModalBody
                        selectCategory={selectCategory}
                        values={props.values}
                        setValues={props.setValues}
                      />
                    </ModalBody>
                    <ModalFooter className="AddEducationMaterialModal__footer">
                      <MaterialModalFooter form={props} />
                    </ModalFooter>
                  </MaterialModalFormContext.Provider>
                )}
              </Formik>
            </CreatedMaterialContext.Provider>
          </MaterialModalStepsContext.Provider>
        </MaterialModalModeContext.Provider>
      </MaterialModalContext.Provider>
    </Modal>
  );
};

export default MaterialModal;
