import { createElement, useCallback } from 'react';
import { IAwareSessionData } from '../../../../../../../../../../../interfaces/awareSessions';

export const useQuestionColumns = () => {
  return useCallback((session: IAwareSessionData) => {
    return session.questions.map((question) =>
      createElement('td', {
        children: question.value ?? '-',
      })
    );
  }, []);
};
