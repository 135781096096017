import { AvailabilityStatus } from '../../../../../../../../../interfaces/educationMaterials';
import { useCallback } from 'react';
import { MaterialModalFormValues } from '../../../../../types';

export const useHandleAddRule = (
  values: MaterialModalFormValues,
  setValues: (data: MaterialModalFormValues) => void
) => {
  return useCallback(() => {
    setValues({
      ...values,
      rules: [
        ...values.rules,
        {
          rule: '',
          case_category_id: values.case_category_id ?? '',
          case_sub_category_id: '',
          availability_status: AvailabilityStatus.Mandatory,
        },
      ],
    });
  }, [setValues, values]);
};
