import React from 'react';
import {
  ReportParameter,
  ReportTemplateCode,
} from '../../../../interfaces/reports';
import GenerateReportForm from './GenerateReportForm';

type GenerateParticipantFeedbackFormProps = {
  fillInitialParameters: (
    parameters: ReportParameter[],
    code: ReportTemplateCode
  ) => void;
};

const GenerateParticipantFeedbackForm = ({
  fillInitialParameters,
}: GenerateParticipantFeedbackFormProps) => {
  return (
    <GenerateReportForm
      code={ReportTemplateCode.PARTICIPANT_FEEDBACK}
      fillInitialParameters={fillInitialParameters}
    />
  );
};

export default GenerateParticipantFeedbackForm;
