import React, { InputHTMLAttributes } from 'react';
import useCaseCategories from 'api/queries/cases/useCaseCategories';
import { Input } from 'reactstrap';
import InputWithDisabledDivDisplay from 'components/InputWithDisabledDivDisplay';
import { CategoryIdentifier } from './CategorySelect';

interface CategorySelectProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  organizationId: string;
  onEdit?: (target: string, value: string) => void;
  categoryId: string;
  name: string;
  isDefaultInput: boolean;
  value: string;
  identifier?: CategoryIdentifier;
}

const SubCategorySelect = ({
  organizationId,
  categoryId,
  onEdit,
  isDefaultInput,
  name,
  identifier = CategoryIdentifier.CategoryCode,
  ...props
}: CategorySelectProps) => {
  const { data } = useCaseCategories({
    params: { organizationId },
    options: { enabled: false },
  });

  const category = data?.find((item) =>
    identifier === CategoryIdentifier.CategoryId
      ? item.id === categoryId
      : item.code === categoryId
  );

  const subCategoriesOptions =
    category?.sub_categories?.map(({ id, code, name }) => {
      return {
        value: identifier === CategoryIdentifier.CategoryId ? id : code,
        text: name,
      };
    }) ?? [];

  if (isDefaultInput) {
    return (
      <Input {...props} type="select" name={name}>
        <option value=""></option>
        {subCategoriesOptions?.map(({ value, text }) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))}
      </Input>
    );
  } else if (onEdit) {
    return (
      <InputWithDisabledDivDisplay
        name={name}
        onEdit={onEdit}
        {...props}
        inputCssClass={props.className}
        type="select"
        options={subCategoriesOptions}
      />
    );
  } else return null;
};

export default SubCategorySelect;
