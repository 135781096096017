import { useCallback } from 'react';
import {
  IOrganizationDashboardFilter,
  IOrganizationDashboardTemplateFilter,
} from '../../../../../../../interfaces/dashboards';
import { IBaseOrganization } from '../../../../../../../interfaces/organizations';
import { DashboardSelectionHelpers } from '../helpers/selection';

export const useOrganizationOptions = (organizations: IBaseOrganization[]) => {
  const append = useCallback(
    (dashboardFilter: IOrganizationDashboardFilter) => {
      organizations.forEach((organization) => {
        dashboardFilter.options.push({
          id: organization.id,
          selected: false,
        });
      });

      return dashboardFilter;
    },
    [organizations]
  );

  return {
    append,
    select: DashboardSelectionHelpers.SelectIncludedExcluded<
      IOrganizationDashboardFilter,
      IOrganizationDashboardTemplateFilter
    >,
  };
};
