import React, { useState } from 'react';
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Row,
  Table,
} from 'reactstrap';
import { useDebounce } from 'use-debounce';
import InfiniteScroll from 'react-infinite-scroller';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch } from '@fortawesome/pro-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import useInfiniteOrganizationPatientList from 'api/queries/patients/useInfinitePatientList';
import useRequiredParams from 'hooks/useRequiredParams';

const MIN_SEARCH_LENGTH = 3;

type PatientListStepProps = {
  selectedId: string | null;
  onRowSelect: (id: string) => void;
  onCreateButtonClick: () => void;
};

const PatientListStep = ({
  selectedId,
  onRowSelect,
  onCreateButtonClick,
}: PatientListStepProps) => {
  const onInputChange = (event) => setFilterValue(event.target.value);

  const [filterValue, setFilterValue] = useState('');

  const [value] = useDebounce(filterValue, 500, {
    equalityFn: (current, newValue) => {
      if (newValue.length === 0) return false;
      return newValue.length < MIN_SEARCH_LENGTH;
    },
  });

  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const { patients, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useInfiniteOrganizationPatientList({
      params: { organizationId, searchValue: value },
    });

  const loadMore = () => fetchNextPage();

  const renderedItems = patients?.map(
    ({ id, first_name, last_name, email }) => (
      <tr
        key={id}
        className={id === selectedId ? 'selected-row' : ''}
        onClick={() => onRowSelect(id)}
      >
        <td>{first_name}</td>
        <td>{last_name}</td>
        <td>{email}</td>
      </tr>
    )
  );

  return (
    <div>
      <Row>
        <Col md={8}>
          <div className="mb-3 input-group-sm">
            <InputGroup>
              <Input placeholder="Enter Name" onChange={onInputChange} />
              <InputGroupText>
                <FontAwesomeIcon icon={faSearch} />
              </InputGroupText>
            </InputGroup>
          </div>
        </Col>
        <Col md={4}>
          <Button color="primary" onClick={onCreateButtonClick}>
            <FontAwesomeIcon icon={faPlus} className="me-2" size={'sm'} />{' '}
            <FormattedMessage id="UserDashboard.createPatient" />
          </Button>
        </Col>
      </Row>
      <InfiniteScroll
        pageStart={1}
        loader={
          <div className="loader" key={0}>
            Loading ...
          </div>
        }
        hasMore={!isFetchingNextPage && hasNextPage}
        loadMore={loadMore}
        initialLoad={true}
        style={{
          height: '400px',
          overflow: 'auto',
        }}
      >
        <Table className="p-2">
          <thead>
            <tr>
              <th>
                <FormattedMessage id="Patient.patientList.name" />
              </th>
              <th>
                <FormattedMessage id="Patient.patientList.lastName" />
              </th>
              <th>
                <FormattedMessage id="Login.LoginForm.email" />
              </th>
            </tr>
          </thead>
          <tbody
            style={{
              height: '300px',
            }}
          >
            {renderedItems}
          </tbody>
        </Table>
      </InfiniteScroll>
    </div>
  );
};

export default PatientListStep;
