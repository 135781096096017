import SkeletonPlaceholderOnLoad from 'components/SkeletonPlaceholderOnLoad';
import Skeleton from 'react-loading-skeleton';
import DefaultAvatar from 'assets/images/default_avatar.svg';
import { Link } from 'react-router-dom';
import useRequiredParams from 'hooks/useRequiredParams';
import { generatePersonalityUrl } from 'helpers/routeDefinitions';

type CaseDetailNameProps = {
  isLoading: boolean;
  userName?: string;
  caseTitle?: string;
  isHeaderHidden: boolean;
  patientId?: string;
};

const CaseDetailName = ({
  isLoading,
  userName = '',
  caseTitle = '',
  isHeaderHidden,
  patientId = '',
}: CaseDetailNameProps) => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  return (
    <div className="d-flex align-items-center">
      <SkeletonPlaceholderOnLoad
        isLoading={isLoading}
        placeholder={<Skeleton width={isHeaderHidden ? 300 : 150} />}
      >
        <>
          <Link
            to={generatePersonalityUrl({
              organizationId,
              personalityId: patientId,
            })}
            className="CaseHeader__name"
          >
            <div className="d-flex  align-items-center">
              <div className="TimelineHeader__thumbnail-container">
                <div className="TimelineHeader__thumbnail-container__thumbnail">
                  <img src={DefaultAvatar} alt="avatar" />
                </div>
              </div>
              {userName}
            </div>
          </Link>
          {isHeaderHidden ? (
            <div className="CaseHeader__title CaseHeader__title--hidden">
              {caseTitle}
            </div>
          ) : null}
        </>
      </SkeletonPlaceholderOnLoad>
    </div>
  );
};

export default CaseDetailName;
