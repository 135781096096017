import { getUserId } from '../../../helpers/utils/auth';
import { useParams } from 'react-router-dom';

export const useCommonParams = () => {
  const userId = getUserId();
  const { organizationId } = useParams<{
    organizationId: string;
  }>();

  return { userId, organizationId };
};
