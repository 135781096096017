import { ScheduleItemDefinition } from 'components/Schedule';
import { strToDateOnly, toDateOnly } from 'helpers/dates';
import {
  getRecurringForSingleInstance,
  mergeSingleInstanceWithRow,
} from 'helpers/utils/conversationSchedules';
import { ICasePhaseHistory } from 'interfaces/cases';
import {
  ConversationScheduleType,
  ICaseConversationSchedule,
  ISingleInstanceCaseConversationSchedule,
} from 'interfaces/conversationSchedules';
import React from 'react';
import { usePrepareSchedulesPayload } from './usePrepareSchedulesPayload';
import { usePrepareSchedulesUtils } from './usePrepareSchedulesUtils';

export const usePrepareSchedules = (
  setSchedule: React.Dispatch<
    React.SetStateAction<ICaseConversationSchedule | null>
  >,
  toggle: () => void
) => {
  const { getSingleInstancePartOfADay, getRowToMergeWith } =
    usePrepareSchedulesUtils(setSchedule, toggle);

  const {
    prepareBasic,
    prepareSimpleSingleInstance,
    prepareRecurredSingleInstance,
  } = usePrepareSchedulesPayload(setSchedule, toggle);

  const prepareBasicSchedule = (
    schedules: ScheduleItemDefinition[],
    current: ICaseConversationSchedule,
    date: Date,
    index: number
  ) => {
    if (
      current.type !== ConversationScheduleType.Once &&
      current.type !== ConversationScheduleType.NonTimeBased
    )
      return schedules;

    const selectedDateStr = strToDateOnly(date.toISOString());

    schedules.push(
      prepareBasic(current, index, selectedDateStr < toDateOnly(new Date()))
    );
    return schedules;
  };

  const prepareSingleInstanceSchedule = (
    schedules: ScheduleItemDefinition[],
    current: ICaseConversationSchedule,
    caseSchedules: ICaseConversationSchedule[],
    casePhaseHistory: ICasePhaseHistory[],
    date: Date,
    index: number
  ) => {
    if (current.type !== ConversationScheduleType.SingleInstance)
      return schedules;

    const singleInstanceSchedule =
      current as ISingleInstanceCaseConversationSchedule;

    const singleInstanceDateStr = strToDateOnly(singleInstanceSchedule.date);
    const selectedDateStr = strToDateOnly(date.toISOString());

    if (singleInstanceDateStr !== selectedDateStr) return schedules;

    if (singleInstanceSchedule.recurring_origin_id) {
      const recurringSchedule = getRecurringForSingleInstance(
        caseSchedules,
        current
      ) as ICaseConversationSchedule;

      const singleInstancePartOfADay = getSingleInstancePartOfADay(
        current,
        recurringSchedule
      );

      const rowToMergeWith = getRowToMergeWith(
        schedules,
        current,
        recurringSchedule
      );

      if (rowToMergeWith !== null) {
        schedules = mergeSingleInstanceWithRow(
          schedules,
          rowToMergeWith.sequence,
          singleInstancePartOfADay,
          selectedDateStr < toDateOnly(new Date())
        );

        return schedules;
      }

      schedules.push(
        prepareRecurredSingleInstance(
          current,
          recurringSchedule,
          casePhaseHistory,
          selectedDateStr,
          index
        )
      );

      return schedules;
    }

    schedules.push(
      prepareSimpleSingleInstance(
        current,
        casePhaseHistory,
        date.toISOString(),
        selectedDateStr < toDateOnly(new Date()),
        index
      )
    );

    return schedules;
  };

  return {
    prepareBasicSchedule: prepareBasicSchedule,
    prepareSingleInstanceSchedule: prepareSingleInstanceSchedule,
  };
};
