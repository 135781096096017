import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem/MenuItem';
import TextField from '@mui/material/TextField';
import { Formik } from 'formik';
import { OrganizationLicense } from 'interfaces/organizations';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import FormContainer from '../../components/FormContainer';
import { useForm } from './hooks/useForm';

const CreateOrganizationForm = () => {
  const formHandler = useForm();

  return (
    <Formik
      initialValues={formHandler.initialValues}
      validationSchema={formHandler.validationSchema}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={formHandler.handleSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        touched,
        errors,
        submitForm,
        isValid,
      }) => (
        <FormContainer
          fields={
            <>
              <TextField
                variant="standard"
                fullWidth
                id="name"
                name="name"
                label={<FormattedMessage id="General.Name" />}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
              <TextField
                variant="standard"
                fullWidth
                id="description"
                name="description"
                label={<FormattedMessage id="General.Description" />}
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.description && Boolean(errors.description)}
                helperText={touched.description && errors.description}
              />
              <TextField
                variant="standard"
                fullWidth
                select
                id="license"
                name="license"
                label={<FormattedMessage id="Organizations.License" />}
                defaultValue={OrganizationLicense.Standard}
                helperText={touched.description && errors.description}
              >
                {Object.entries(OrganizationLicense).map(([key, val]) => (
                  <MenuItem key={key} value={val}>
                    <FormattedMessage id={`Enums.OrganizationLicense.${val}`} />
                  </MenuItem>
                ))}
              </TextField>
            </>
          }
          agreement={
            <FormControlLabel
              id="tosAndPolicy"
              name="tosAndPolicy"
              label={<FormattedMessage id="General.TosAndPrivacyPolicy" />}
              checked={values.tosAndPolicy}
              onChange={handleChange}
              onBlur={handleBlur}
              control={<Checkbox />}
            />
          }
          buttons={
            <Button
              variant="contained"
              type="submit"
              disabled={!isValid}
              onClick={submitForm}
            >
              <FormattedMessage id="General.Next" />
            </Button>
          }
        />
      )}
    </Formik>
  );
};

export default CreateOrganizationForm;
