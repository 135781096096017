import React, { useState } from 'react';
import { TrendVisualizationTemplate } from 'interfaces/trendVisualizations';
import SVG from 'react-inlinesvg';
import { FormattedMessage, useIntl } from 'react-intl';

import users from 'assets/images/profile_icon.svg';
import stethoscope from 'assets/images/doctors_note.svg';
import caret from 'assets/images/caret.svg';
import chart from 'assets/images/timeline_icon.svg';

import { Field } from 'formik';
import { FormGroup } from 'reactstrap';
import FieldSelect from 'components/FieldSelect';
import TrendVisualizationsSelectChildSection from '../SelectChildSection';
import classNames from 'classnames';

export type TrendVisualizationsSelectSectionChildItem = {
  title: string;
  description?: string;
  type: string;
  subtype: string;
};

type TrendVisualizationsSelectSectionProps = {
  title: string;
  description?: string;
  hasHeader?: boolean;
  isChild?: boolean;
  type: string;
  subtype?: string;
  templates: TrendVisualizationTemplate[];
  childItems?: TrendVisualizationsSelectSectionChildItem[];
};

const SelectSection = ({
  title,
  templates,
  description,
  type,
  subtype = undefined,
  childItems = undefined,
  hasHeader = false,
  isChild = false,
}: TrendVisualizationsSelectSectionProps) => {
  const intl = useIntl();

  const templatesOptions = templates.map(({ id, name }) => ({
    value: id,
    text: name,
  }));

  const [childrenVisible, setChildrenVisible] = useState<boolean>(false);

  const toggleChildrenVisible = () => setChildrenVisible((prev) => !prev);

  return (
    <>
      <div className="TrendVisualizationsSelectSection">
        <div className="TrendVisualizationsSelectSection__item">
          <div
            className={classNames({
              TrendVisualizationsSelectSection__type: true,
              'TrendVisualizationsSelectSection__type--child': isChild,
            })}
          >
            <div className="TrendVisualizationsSelectSection__type-title">
              <span className="TrendVisualizationsSelectSection__type-name">
                {title}
                {childItems && childItems.length > 0 && (
                  <SVG
                    src={caret}
                    title={title}
                    onClick={toggleChildrenVisible}
                    transform={childrenVisible ? 'rotate(180)' : ''}
                  />
                )}
              </span>
              {description && (
                <span className="TrendVisualizationsSelectSection__type-description">
                  {description}
                </span>
              )}
            </div>
          </div>
          <div className="TrendVisualizationsSelectSection__input TrendVisualizationsSelectSection__patients-input">
            {hasHeader && (
              <div className="TrendVisualizationsSelectSection__header">
                <SVG src={users} />
                <FormattedMessage id="MyOrganizations.trendVisualizations.selectSectionPatientsDescription" />
              </div>
            )}
            <FormGroup className={classNames({ 'ps-4': isChild })}>
              <label>
                <FormattedMessage id="MyOrganizations.trendVisualizations.selectedView" />
              </label>
              <Field
                aria-label={intl.formatMessage({
                  id: 'MyOrganizations.trendVisualizations.selectedView',
                })}
                name={
                  subtype ? `${type}|${subtype}|patients` : `${type}|patients`
                }
                component={FieldSelect}
                defaultText={intl.formatMessage({
                  id: 'MyOrganizations.trendVisualizations.chooseTrendsFromList',
                })}
                addon={
                  <SVG
                    src={chart}
                    title={intl.formatMessage({
                      id: 'MyOrganizations.trendVisualizations.chooseTrendsFromList',
                    })}
                  />
                }
                options={templatesOptions}
              />
            </FormGroup>
          </div>
          <div className="TrendVisualizationsSelectSection__input TrendVisualizationsSelectSection__medical-personnel-input">
            {hasHeader && (
              <div className="TrendVisualizationsSelectSection__header">
                <SVG src={stethoscope} />
                <FormattedMessage id="MyOrganizations.trendVisualizations.selectSectionMedicalPersonnelDescription" />
              </div>
            )}
            <FormGroup className={classNames({ 'ps-4': isChild })}>
              <label>
                <FormattedMessage id="MyOrganizations.trendVisualizations.selectedView" />
              </label>
              <Field
                aria-label={intl.formatMessage({
                  id: 'MyOrganizations.trendVisualizations.selectedView',
                })}
                name={
                  subtype
                    ? `${type}|${subtype}|medical-personnel`
                    : `${type}|medical-personnel`
                }
                defaultText={intl.formatMessage({
                  id: 'MyOrganizations.trendVisualizations.chooseTrendsFromList',
                })}
                addon={
                  <SVG
                    src={chart}
                    title={intl.formatMessage({
                      id: 'MyOrganizations.trendVisualizations.chooseTrendsFromList',
                    })}
                  />
                }
                component={FieldSelect}
                options={templatesOptions}
              />
            </FormGroup>
          </div>
        </div>
        {childItems && childItems.length > 0 && childrenVisible && (
          <div className="TrendVisualizationsSelectSection__children">
            {childItems.map((item) => (
              <TrendVisualizationsSelectChildSection
                key={`${item.title}|${item.type}`}
                title={item.title}
                description={item.description}
                subtype={item.subtype}
                type={item.type}
                templates={templates}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default SelectSection;
