export enum Locales {
  EN = 'en',
  NO = 'no',
}

const languageDictionary = {
  [Locales.EN]: ['en', 'en-US', 'en-GB'],
  [Locales.NO]: ['nb-NO', 'nb', 'nn-NO', 'nn', 'se-NO', 'nb-SJ'],
};

export default languageDictionary;
