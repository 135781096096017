import { useCallback } from 'react';
import { OpenHookValues } from '../../../../../../../../../../../hooks/useOpen';
import { ContentIndexHookValues } from '../../../hooks/useContentIndex';

export const useHandleAddTemplate = (
  defaultTemplateMode: OpenHookValues,
  addTemplateModal: OpenHookValues,
  contentIndex: ContentIndexHookValues
) => {
  return useCallback(() => {
    defaultTemplateMode.close();
    contentIndex.clearIndex();
    addTemplateModal.toggle();
  }, [addTemplateModal, contentIndex, defaultTemplateMode]);
};
