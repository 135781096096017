import { register, signIn } from 'api/membership';
import { useMutationWithResultNotification } from 'api/mutations/useMutationWithResultNotification';
import QueryKeys from 'api/queries/queryKeys';
import { setUserData } from 'helpers/utils/auth';
import { arePasswordsEqual } from 'helpers/utils/validators';
import {
  IInvitationForm,
  LoginMethod,
  TokenTypes,
} from 'interfaces/membership';
import {
  displayErrorArrayNotifications,
  displayErrorNotification,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useQueryClient } from 'react-query';
import { getCulture } from 'translations/utils/cultures';

type useRegisterType = {
  organizationId: string;
  invitationId: string;
  successFb?: () => void;
};

function useRegisterInvitedUser({
  invitationId,
  organizationId,
}: useRegisterType) {
  const queryClient = useQueryClient();

  return useMutationWithResultNotification({
    asyncApiFunction: async ({
      password,
      passwordRepeat,
      address,
      email,
      gender,
      birthdate,
      last_name,
      first_name,
      ssn,
      phone_number,
    }: IInvitationForm) => {
      if (arePasswordsEqual(password, passwordRepeat)) {
        await register({
          email,
          culture: getCulture(),
          first_name,
          last_name,
          password: password,
          invitation_request_id: invitationId,
          token: null,
          personality: {
            email,
            culture: getCulture(),
            first_name,
            last_name,
            birthdate: birthdate
              ? new Date(birthdate).toISOString()
              : undefined,
            gender: gender.length ? gender : undefined,
            address: address.length ? address : undefined,
            ssn: ssn.length ? ssn : undefined,
            phone_number,
          },
        });

        const { data: responseData } = await signIn({
          email,
          password,
          application_type: 'Web',
        });

        const data = responseData?.data ?? {};

        if (data)
          setUserData({
            accessToken: data.token,
            validUntil: data.valid_until,
            email: data.user?.email,
            id: data.user?.id,
            organizations: data.organizations,
            tokenType: TokenTypes.PERSONAL,
            loginAs: LoginMethod.AsPatient,
          });

        //if login failed - 401 will be thrown
        await queryClient.refetchQueries([
          QueryKeys.getInvitationDetails,
          organizationId,
          invitationId,
        ]);
      } else throw Error('different_password');
    },
    resetOnResult: true,
    onError: (err) => {
      if (err.message === 'different_password') {
        displayErrorNotification(
          'Register.notifications.errors.different_passwords'
        );
      }
    },
    onSuccess: () => {
      displaySuccessNotification('InvitationScreen.successfulRegister');
    },
    onErrorArray: (errorArray) => {
      if (errorArray.length)
        displayErrorArrayNotifications(
          errorArray,
          'Register.notifications.errors'
        );
    },
  });
}

export default useRegisterInvitedUser;
