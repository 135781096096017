import { IObservation } from 'interfaces/timeline';
import { useCallback, useMemo } from 'react';
import { GraspColors, graspColorsValues } from '../../../../../helpers/colors';
import { groupByProperty } from '../../../../../helpers/utils/groupByProperty';

export const useChartsData = (observations: IObservation[] | undefined) => {
  const squeezesSum = useMemo((): Record<string, number> => {
    let _data: Record<string, number> = {};

    if (!observations) return _data;

    const groupByMeaning = groupByProperty('meaning_name');
    const groupedObservations = groupByMeaning(observations);

    Object.entries(groupedObservations).forEach(
      ([meaningName, observations]) => {
        if (!meaningName) return;

        _data[meaningName] = (observations as IObservation[]).length;
      }
    );

    return _data;
  }, [observations]);

  const totalSum = useMemo(() => {
    let entries = Object.entries(squeezesSum);
    let _totalSum: number = 0;

    if (entries.length === 0) return _totalSum;

    entries.forEach(([_, value]) => (_totalSum += value));
    return _totalSum;
  }, [squeezesSum]);

  const getColorForMeaning = useCallback(
    (entry: any) => {
      return (
        observations?.find((p) => p.meaning_name === entry.name)
          ?.meaning_color ?? graspColorsValues[GraspColors.BLUE]
      );
    },
    [observations]
  );

  return { squeezesSum, totalSum, getColorForMeaning };
};
