import React from 'react';
import PageHeader from '../../components/PageHeader';
import TabContainer from '../../components/Tabs/components/TabContainer';
import { useTabs } from './hooks/useTabs';
import { useBreadcrumbs } from './hooks/useBreadcrumbs';

const Education = () => {
  const { onTabChange, tabItems } = useTabs();
  const { breadcrumbs, isLoading } = useBreadcrumbs();

  return (
    <PageHeader
      breadcrumbs={breadcrumbs}
      isLoading={isLoading}
      content={
        <div className="Education">
          <TabContainer items={tabItems} onTabChange={onTabChange} />
        </div>
      }
    />
  );
};

export default Education;
