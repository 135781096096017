import React from 'react';
import { FormattedMessage } from 'react-intl';

//images
import graspSqueezing from 'assets/images/grasp_squeezing.svg';
import logoBlue from 'assets/images/logo-blue.svg';

// components:
import LoadingButton from 'components/LoadingButton';

// helpers:
import useConfirmMail from 'api/mutations/membership/useConfirmMail';
import useRequiredParams from 'hooks/useRequiredParams';
import EmptyLayout from 'components/layouts/EmptyLayout';

const ConfirmEmail = () => {
  const { id, code } = useRequiredParams<{ id: string; code: string }>([
    'id',
    'code',
  ]);
  const { mutate: confirmEmailMutation, isLoading } = useConfirmMail();

  const onClick = () => {
    confirmEmailMutation({ id, email_confirmation_code: code });
  };

  return (
    <EmptyLayout>
      <div className="ConfirmEmail">
        <div className="ConfirmEmail__logo">
          <img src={logoBlue} alt="logo_blue" />
        </div>
        <img
          className="ConfirmEmail__visualization"
          src={graspSqueezing}
          alt="grasp-squeezing"
        />
        <h2 className="ConfirmEmail__header">
          <FormattedMessage id="ConfirmEmail.activateYourAccount" />
        </h2>
        <div className="ConfirmEmail__content">
          <p>
            <FormattedMessage id="ConfirmEmail.pleaseConfirmEmail" />
          </p>
          <LoadingButton
            onClick={onClick}
            disabled={isLoading}
            isLoading={isLoading}
            value="ConfirmEmail.activateAccount"
            type="submit"
            className="btn-login"
          />
        </div>
        <div className="ConfirmEmail__footer">
          <FormattedMessage id="ConfirmEmail.wronglySent" />
        </div>
      </div>
    </EmptyLayout>
  );
};

export default ConfirmEmail;
