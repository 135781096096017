import { GroupSelectionIdentifiers } from 'hooks/useRowSelection';
import { PointComparison } from './enums';
import { IBaseOrganization } from './organizations';
import { IPersonality } from './personalities';

export enum FieldDefinition {
  Hidden = 'Hidden',
  Optional = 'Optional',
  Mandatory = 'Mandatory',
}

export type ProjectFieldsDefinitions = {
  address: FieldDefinition;
  birth_date: FieldDefinition;
  email: FieldDefinition;
  first_name: FieldDefinition;
  timezone_id: FieldDefinition;
  gender: FieldDefinition;
  last_name: FieldDefinition;
  phone_number: FieldDefinition;
  ssn: FieldDefinition;
  password: FieldDefinition;
};

export interface IProject {
  id: string;
  title: string;
  description: string;
  terms_of_service: string;
  privacy_policy: string;
  participation_consent: string;
  organization: IBaseOrganization;
  date_from: string | null;
  date_to: string | null;
  short_description: string | null;
  place: string | null;
  for_who: string | null;
  fields_definition: ProjectFieldsDefinitions;
  code: string;
  template_code: string | null;
  contact_email: string | null;
  successful_submission_message: string | null;
  unsuccessful_submission_message: string | null;
  post_register_message: string | null;
  default_culture: string | null;
  owner_personality_id: string;
  mobile_header: string | null;
  case_category_id: string;
  case_sub_category_id: string | null;
  allow_to_choose_group: AllowToChooseGroup;
}

export enum AllowToChooseGroup {
  No = 'No',
  SelectOrPostpone = 'SelectOrPostpone',
  SelectFromAvailable = 'SelectFromAvailable',
}

export type ICreateProject = Omit<IProject, 'id' | 'organization'>;

export interface IUserSubmissions {
  id: string;
  user_id: string;
  sign_up_date: string;
  date_from: string;
  date_to: string;
  project: IProject;
}

export enum SubmissionAssessmentModalModes {
  DOCTOR = 'doctor',
  USER = 'user',
}

export type AssessmentModalFormProps = {
  submissionId: string;
  sequence: number;
  selectedDate: Date;
};

export enum SubmissionStatus {
  APPROVED = 'Approved',
  REJECTED = 'Rejected',
  PENDING = 'Pending',
}

export enum MessageCommunicationChannel {
  Preferred = 'Preferred',
  AllAllowed = 'All Allowed',
}

export interface IOrganizationUserSubmission {
  date_from: string;
  date_to: string;
  id: string;
  personality: IPersonality;
  personality_id: string;
  score: number;
  sign_up_date: string;
  status: SubmissionStatus;
  user_id: string;
  project: IProject;
  completed: boolean;
}

interface GroupSelection<T> {
  all_selected: boolean;
  selected_items: GroupSelectionIdentifiers[];
  excluded_items: GroupSelectionIdentifiers[];
  filtering_criteria: T;
}

export interface ISendMessageBody<T> {
  message_title: string | null;
  message_body: string;
  selection: GroupSelection<T>;
}

export type SubmissionPointsFilter = {
  compare: PointComparison;
  value: number;
  maxValue?: number;
};

export interface IProjectSubmissionFilters {
  pointsFilter?: SubmissionPointsFilter | null;
  statuses?: string[];
  completed?: boolean;
}

export interface IProjectSubmissionFiltersWithBlindSearch
  extends IProjectSubmissionFilters {
  blind_search?: string;
}

export enum CompleteStatuses {
  Open = 'open',
  Closed = 'closed',
  ALL = 'all',
}

export type SubmissionResponse = {
  completed: boolean;
  date_from: string;
  date_to: string;
  id: string;
  personality_id: string;
  project: string;
  score: number;
  sign_up_date: string;
  status: SubmissionStatus;
  user_id: string;
};

export interface IProjectSubmission {
  id: string;
  sign_up_date: string;
  date_from: string;
  date_to?: string;
  user_id?: string;
  project: IProject | null;
  score: number;
  status: SubmissionStatus;
  personality_id?: string;
  organization_id: string;
  completed: boolean;
}
