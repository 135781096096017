import { useUpdateCaseConversationScheduleData } from 'api/mutations/conversationSchedules/useUpdateCaseConversationSchedule';
import {
  ConversationScheduleType,
  ICaseConversationSchedule,
  ICaseSchedulesEditTimeFormInitialValues,
  ICreateCaseConversationSchedule,
} from 'interfaces/conversationSchedules';
import { useCallback } from 'react';
import { useModalsUtils } from '../../../hooks/useModalsUtils';

export const useEditTimeModalFormPayload = () => {
  const { getDateBasedOnCasePhaseHistory, normalizeIsoDate } = useModalsUtils();

  const prepareBasicUpdatePayload = useCallback(
    (
      schedule: ICaseConversationSchedule,
      values: ICaseSchedulesEditTimeFormInitialValues
    ): useUpdateCaseConversationScheduleData => {
      return {
        id: schedule.id,
        data: {
          available_from_hour: null,
          available_to_hour: null,
          date: null,
          date_from: null,
          date_to: null,
          notification_hour: null,
          priority: values.priority,
          recurring_interval: null,
          is_completed: schedule.is_completed,
        },
      };
    },
    []
  );

  const prepareSimpleSingleInstanceUpdatePayload = useCallback(
    (
      schedule: ICaseConversationSchedule,
      values: ICaseSchedulesEditTimeFormInitialValues
    ): useUpdateCaseConversationScheduleData => {
      let date = getDateBasedOnCasePhaseHistory(values.specific_day_of_phase);

      if (date) date = normalizeIsoDate(date);

      return {
        id: schedule.id,
        data: {
          priority: values.priority,
          available_from_hour: values.available_from_hour,
          available_to_hour: values.available_to_hour,
          notification_hour: values.notification_hour,
          recurring_interval: null,
          date_to: null,
          date_from: null,
          date: date,
          is_completed: schedule.is_completed,
        },
      };
    },
    [getDateBasedOnCasePhaseHistory, normalizeIsoDate]
  );

  const prepareDateSimpleSingleInstanceUpdatePayload = useCallback(
    (
      schedule: ICaseConversationSchedule,
      values: ICaseSchedulesEditTimeFormInitialValues,
      selectedDate: string
    ): useUpdateCaseConversationScheduleData => {
      return {
        id: schedule.id,
        data: {
          priority: values.priority,
          available_from_hour: values.available_from_hour,
          available_to_hour: values.available_to_hour,
          notification_hour: values.notification_hour,
          recurring_interval: null,
          date_to: null,
          date_from: null,
          date: selectedDate,
          is_completed: schedule.is_completed,
        },
      };
    },
    []
  );

  const prepareRecurringUpdatePayload = useCallback(
    (
      schedule: ICaseConversationSchedule,
      values: ICaseSchedulesEditTimeFormInitialValues
    ): useUpdateCaseConversationScheduleData => {
      let dateFrom = getDateBasedOnCasePhaseHistory(values.start_day_offset);

      let dateTo = getDateBasedOnCasePhaseHistory(values.end_day_offset);

      if (dateFrom) dateFrom = normalizeIsoDate(dateFrom);
      if (dateTo) dateTo = normalizeIsoDate(dateTo);

      return {
        id: schedule.id,
        data: {
          priority: values.priority,
          date: null,
          date_from: dateFrom,
          date_to: dateTo,
          recurring_interval: values.recurring_interval,
          available_from_hour: values.available_from_hour,
          available_to_hour: values.available_to_hour,
          notification_hour: values.notification_hour,
          is_completed: schedule.is_completed,
        },
      };
    },
    [getDateBasedOnCasePhaseHistory, normalizeIsoDate]
  );

  const prepareRecurringCreatePayload = useCallback(
    (
      values: ICaseSchedulesEditTimeFormInitialValues
    ): ICreateCaseConversationSchedule => {
      let dateFrom = getDateBasedOnCasePhaseHistory(values.start_day_offset);

      let dateTo = getDateBasedOnCasePhaseHistory(values.end_day_offset);

      if (dateFrom) dateFrom = normalizeIsoDate(dateFrom);
      if (dateTo) dateTo = normalizeIsoDate(dateTo);

      return {
        template_id: values.template_id,
        priority: values.priority,
        notification_hour: values.notification_hour,
        available_from_hour: values.available_from_hour,
        available_to_hour: values.available_to_hour,
        recurring_interval: values.recurring_interval,
        date_from: dateFrom,
        date_to: dateTo,
        date: null,
        type: ConversationScheduleType.Recurring,
        recurring_origin_id: null,
        is_dirty: false,
        is_completed: false,
      };
    },
    [getDateBasedOnCasePhaseHistory, normalizeIsoDate]
  );

  const prepareDirtySingleInstanceCreatePayload = useCallback(
    (
      schedule: ICaseConversationSchedule,
      values: ICaseSchedulesEditTimeFormInitialValues,
      selectedDate: string
    ): ICreateCaseConversationSchedule => {
      let date = getDateBasedOnCasePhaseHistory(values.specific_day_of_phase);

      if (!date) date = selectedDate;
      if (date) date = normalizeIsoDate(date);

      return {
        template_id: values.template_id,
        priority: values.priority,
        notification_hour: values.notification_hour,
        available_from_hour: values.available_from_hour,
        available_to_hour: values.available_to_hour,
        recurring_interval: null,
        date_from: null,
        date_to: null,
        date: date,
        type: ConversationScheduleType.SingleInstance,
        recurring_origin_id: schedule.id,
        is_dirty: true,
        is_completed: schedule.is_completed,
      };
    },
    [getDateBasedOnCasePhaseHistory, normalizeIsoDate]
  );

  return {
    basicUpdatePayload: prepareBasicUpdatePayload,
    simpleSingleInstanceUpdatePayload: prepareSimpleSingleInstanceUpdatePayload,
    dateSimpleSingleInstanceUpdatePayload:
      prepareDateSimpleSingleInstanceUpdatePayload,
    recurringUpdatePayload: prepareRecurringUpdatePayload,
    recurringCreatePayload: prepareRecurringCreatePayload,
    dirtySingleInstanceCreatePayload: prepareDirtySingleInstanceCreatePayload,
  };
};
