import React from 'react';
import { BarChart, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { FormattedMessage } from 'react-intl';
import SVG from 'react-inlinesvg';
import stopwatch from 'assets/images/stopwatch.svg';
import useBreakpoint from '../../../../hooks/useBreakpoint';
import { HourByHourMode } from '../../index';
import { useHourByHourChart } from './hooks/useHourByHourChart';
import ItemPlaceholder from '../../../../modules/CaseDashboard/components/CaseDashboardTabs/components/ItemPlaceholder';
import { UseQueryResult } from 'react-query';
import { ICaseDetails } from '../../../../interfaces/cases';

type HourByHourChartProps = {
  date: Date;
  mode: HourByHourMode;
  caseDetails: UseQueryResult<ICaseDetails>;
};

const HourByHourChartTick = (props: any) => {
  const { x, y, payload, exercises } = props;
  const exercisesList = (exercises as string[]).map((exercise) =>
    `${exercise}`.padStart(2, '0')
  );

  const isMobile = useBreakpoint(550);
  const isOneColumn = useBreakpoint(992);

  const payloadValue = `${payload.value}`.padStart(2, '0');
  const desktopFontSize = isOneColumn ? '1.5vw' : '0.7vw';

  return (
    <>
      {exercisesList.includes(payloadValue) && (
        <g transform={`translate(${x},${y})`} overflow="auto">
          <SVG
            x={isMobile ? -4.5 : -9}
            y={15}
            width={isMobile ? 10 : 15}
            height={isMobile ? 10 : 15}
            src={stopwatch}
            title={''}
          />
        </g>
      )}
      <g transform={`translate(${x},${y})`}>
        <text
          fontSize={isMobile ? '2vw' : desktopFontSize}
          x={isMobile ? 4 : 8}
          y={10}
          dy={0}
          textAnchor="end"
          fill="#666"
        >
          {payloadValue}
        </text>
      </g>
    </>
  );
};

const HourByHourChartFigure = ({
  date,
  mode,
  caseDetails,
}: HourByHourChartProps) => {
  const { formattedData, chartBars, isLoading } = useHourByHourChart(
    date,
    mode,
    caseDetails
  );

  const chartTickWithExercises = (tickProps: any) => {
    return <HourByHourChartTick {...tickProps} exercises={[]} />;
  };

  if (isLoading) return <ItemPlaceholder title={false} height={150} />;

  if (!formattedData)
    return (
      <div className="d-flex h-100 flex-column align-items-center justify-content-center">
        <div
          style={{
            zIndex: 1,
          }}
        >
          <div className="ChangeCase__chart-placeholder__bubble" />
        </div>
        <div className="ChangeCase__chart-placeholder__text">
          <FormattedMessage id="Cases.ChangeCase.noDataYet" />
        </div>
      </div>
    );

  return (
    <div
      className="mt-4 mb-4 w-100"
      style={{
        position: 'relative',
      }}
    >
      <ResponsiveContainer className="HourByHourChart" height={150}>
        <BarChart
          data={formattedData}
          margin={{
            top: 14,
          }}
        >
          {chartBars}
          <YAxis hide />
          <XAxis
            interval={0}
            tick={chartTickWithExercises}
            stroke="#ACACAC"
            tickLine={{ fill: '#ACACAC' }}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default HourByHourChartFigure;
