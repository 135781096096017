import React, { useMemo } from 'react';
import { ScheduleItemDefinition } from '../../../../index';
import { getIconForConversationScheduleType } from '../../../../../../helpers/utils/icons';
import {
  ConversationTemplateTriggerType,
  IRecurringConversationScheduleTemplate,
  ISingleInstanceConversationScheduleTemplate,
} from '../../../../../../interfaces/conversations';
import { useIntl } from 'react-intl';
import SVG from 'react-inlinesvg';
import { useConversationTemplateDisplayNames } from './hooks/useConversationTemplateDisplayNames';
import { useSchedulesCommonData } from '../../../../hooks/useSchedulesCommonData';
import Skeleton from 'react-loading-skeleton';
import SkeletonPlaceholderOnLoad from '../../../../../SkeletonPlaceholderOnLoad';
import { ConversationScheduleType } from '../../../../../../interfaces/conversationSchedules';

type ScheduleRowLabelProps = {
  item: ScheduleItemDefinition;
};

const ScheduleRowLabel = ({ item }: ScheduleRowLabelProps) => {
  const intl = useIntl();

  const { conversationTemplates } = useSchedulesCommonData();

  const scheduleDescription = useMemo(() => {
    if (item.label) return item.label;
    if (!item.scheduleTemplate) return '';

    switch (item.scheduleTemplate.schedule_type) {
      case ConversationScheduleType.Once:
        return intl.formatMessage({
          id: `Enums.ConversationScheduleType.${ConversationScheduleType.Once}`,
        });
      case ConversationScheduleType.SingleInstance:
        const singleInstanceTemplate =
          item.scheduleTemplate as ISingleInstanceConversationScheduleTemplate;

        return intl.formatMessage(
          { id: 'Schedule.ScheduleRow.ScheduleRowLabel.nthDay' },
          { day: singleInstanceTemplate.specific_day_of_phase + 1 }
        );
      case ConversationScheduleType.Recurring:
        const recurringTemplate =
          item.scheduleTemplate as IRecurringConversationScheduleTemplate;

        if (recurringTemplate.recurring_interval === 1)
          return intl.formatMessage(
            {
              id: 'Schedule.ScheduleRow.ScheduleRowLabel.recurringEveryDayFromTo',
            },
            {
              dayFrom: recurringTemplate.start_day_offset + 1,
              dayTo: recurringTemplate.end_day_offset
                ? recurringTemplate.end_day_offset + 1
                : intl.formatMessage({
                    id: 'Schedule.ScheduleRow.ScheduleRowLabel.end',
                  }),
            }
          );

        return intl.formatMessage(
          { id: 'Schedule.ScheduleRow.ScheduleRowLabel.recurringFromTo' },
          {
            interval: recurringTemplate.recurring_interval,
            dayFrom: recurringTemplate.start_day_offset + 1,
            dayTo: recurringTemplate.end_day_offset
              ? recurringTemplate.end_day_offset + 1
              : intl.formatMessage({
                  id: 'Schedule.ScheduleRow.ScheduleRowLabel.end',
                }),
          }
        );
    }

    return '';
  }, [intl, item]);

  const { getTemplateDisplayCode, getTemplateTriggerType } =
    useConversationTemplateDisplayNames();

  const templateTriggerType = getTemplateTriggerType(item.templateId);

  const icon = getIconForConversationScheduleType(
    item.scheduleTemplate?.schedule_type,
    templateTriggerType ?? undefined
  );

  const shouldTriggerTypeBeVisible =
    templateTriggerType !== ConversationTemplateTriggerType.Time &&
    templateTriggerType !== ConversationTemplateTriggerType.PhaseLastResort;

  return (
    <div className="ScheduleRowLabel">
      <div className="ScheduleRowLabel__icon">
        {icon && (
          <SkeletonPlaceholderOnLoad
            isLoading={
              conversationTemplates.isLoading || (item.isLoading ?? false)
            }
            placeholder={<Skeleton height={32} width={32} />}
          >
            <SVG
              src={icon}
              title={item.scheduleTemplate?.schedule_type ?? ''}
            />
          </SkeletonPlaceholderOnLoad>
        )}
      </div>
      <div className="ScheduleRowLabel__description">
        <span className="ScheduleRowLabel__template">
          <SkeletonPlaceholderOnLoad
            isLoading={
              conversationTemplates.isLoading || (item.isLoading ?? false)
            }
            placeholder={<Skeleton height={15} width={150} />}
          >
            <>{getTemplateDisplayCode(item.templateId)}</>
          </SkeletonPlaceholderOnLoad>
        </span>
        {(item.label || item.scheduleTemplate) && !shouldTriggerTypeBeVisible && (
          <span className="ScheduleRowLabel__schedule">
            <SkeletonPlaceholderOnLoad
              isLoading={
                conversationTemplates.isLoading || (item.isLoading ?? false)
              }
              placeholder={<Skeleton height={15} width={70} />}
            >
              <>{scheduleDescription}</>
            </SkeletonPlaceholderOnLoad>
          </span>
        )}
        {shouldTriggerTypeBeVisible && (
          <span className="ScheduleRowLabel__schedule">
            {intl.formatMessage({
              id: `ConversationsAndFeedbacks.ConversationTemplateModal.options.triggerType.${templateTriggerType}`,
            })}
          </span>
        )}
      </div>
      {item.priority && (
        <div className="ScheduleRowLabel__priority">{item.priority}</div>
      )}
    </div>
  );
};

export default ScheduleRowLabel;
