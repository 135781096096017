import { IStreamUrl } from '../../../../../../../../interfaces/educationMaterials';
import NotAvailablePreview from '../NotAvailablePreview';
import VideoPlayer from '../../../../../../../../components/VideoPlayer';
import videojs from 'video.js';

type VideoPreviewProps = {
  stream: IStreamUrl[] | null;
};

const VideoPreview = ({ stream }: VideoPreviewProps) => {
  if (!stream?.at(0)) return <NotAvailablePreview />;

  const options: videojs.PlayerOptions = {
    sources: [
      {
        src: stream[0].url,
        type: 'application/x-mpegURL',
      },
    ],
  };

  return <VideoPlayer options={options} />;
};

export default VideoPreview;
