import React, { useCallback } from 'react';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { useLedLevelFractions } from './hooks/useLedLevelFractions';
import { useIntl } from 'react-intl';
import { useItemActions } from './hooks/useItemActions';
import { IGraspLedLevel } from 'interfaces/grasps';

type GraspSettingsListItemProps = {
  level: IGraspLedLevel;
  handleSelectLevel: (level: IGraspLedLevel | undefined) => void;
};

const GraspLedLevelListItem = ({
  level,
  handleSelectLevel,
}: GraspSettingsListItemProps) => {
  const intl = useIntl();
  const fractionEnabled = useLedLevelFractions();

  const { onEdit, onRemove } = useItemActions(handleSelectLevel);

  const renderFractionColumn = useCallback(
    (fraction: 'r' | 'g' | 'b') => {
      return intl.formatMessage({
        id: fractionEnabled(level.color, fraction)
          ? 'Organizations.OrganizationDetails.GraspSettings.GraspSettingsList.GraspSettingsListItem.fractionOn'
          : 'Organizations.OrganizationDetails.GraspSettings.GraspSettingsList.GraspSettingsListItem.fractionOff',
      });
    },
    [fractionEnabled, intl, level.color]
  );

  const handleEdit = () => {
    onEdit(level);
  };

  const handleRemove = async () => {
    await onRemove(level.id);
  };

  return (
    <tr key={level.id}>
      <td>{level.amplitude_percentage}</td>
      <td>{renderFractionColumn('r')}</td>
      <td>{renderFractionColumn('g')}</td>
      <td>{renderFractionColumn('b')}</td>
      <td className="d-flex align-items-center gap-1">
        <Button color="primary" outline={true} onClick={handleEdit}>
          <FontAwesomeIcon icon={faEdit} />
        </Button>
        <Button color="danger" outline={true} onClick={handleRemove}>
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      </td>
    </tr>
  );
};

export default GraspLedLevelListItem;
