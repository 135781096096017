import React from 'react';
import { Col, Row } from 'reactstrap';
import InputFieldWithoutImage from '../../../../../../components/InputFieldWithoutImage';
import { Field } from 'formik';
import FieldInlineSelect from '../../../../../../components/Fields/Formik/FieldInlineSelect';
import { useProjectModalRegistrationFieldsForm } from './hooks/useProjectModalRegistrationFieldsForm';

const ProjectModalRegistrationFieldsForm = () => {
  const { options } = useProjectModalRegistrationFieldsForm();

  return (
    <div className="d-flex flex-column gap-4">
      <Row>
        <Col xs={6}>
          <InputFieldWithoutImage
            gray={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.firstName"
            inputId="fields_definition.first_name"
          >
            <Field
              id="fields_definition.first_name"
              name="fields_definition.first_name"
              required={true}
              options={options}
              component={FieldInlineSelect}
            />
          </InputFieldWithoutImage>
        </Col>
        <Col xs={6}>
          <InputFieldWithoutImage
            gray={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.lastName"
            inputId="fields_definition.last_name"
          >
            <Field
              id="fields_definition.last_name"
              name="fields_definition.last_name"
              required={true}
              options={options}
              component={FieldInlineSelect}
            />
          </InputFieldWithoutImage>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <InputFieldWithoutImage
            gray={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.email"
            inputId="fields_definition.email"
          >
            <Field
              id="fields_definition.email"
              name="fields_definition.email"
              required={true}
              options={options}
              component={FieldInlineSelect}
            />
          </InputFieldWithoutImage>
        </Col>
        <Col xs={6}>
          <InputFieldWithoutImage
            gray={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.password"
            inputId="fields_definition.password"
          >
            <Field
              id="fields_definition.password"
              name="fields_definition.password"
              required={true}
              options={options}
              component={FieldInlineSelect}
            />
          </InputFieldWithoutImage>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <InputFieldWithoutImage
            gray={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.dateOfBirth"
            inputId="fields_definition.birth_date"
          >
            <Field
              id="fields_definition.birth_date"
              name="fields_definition.birth_date"
              required={true}
              options={options}
              component={FieldInlineSelect}
            />
          </InputFieldWithoutImage>
        </Col>
        <Col xs={6}>
          <InputFieldWithoutImage
            gray={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.gender"
            inputId="fields_definition.gender"
          >
            <Field
              id="fields_definition.gender"
              name="fields_definition.gender"
              required={true}
              options={options}
              component={FieldInlineSelect}
            />
          </InputFieldWithoutImage>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <InputFieldWithoutImage
            gray={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.phoneNumber"
            inputId="fields_definition.phone_number"
          >
            <Field
              id="fields_definition.phone_number"
              name="fields_definition.phone_number"
              required={true}
              options={options}
              component={FieldInlineSelect}
            />
          </InputFieldWithoutImage>
        </Col>
        <Col xs={6}>
          <InputFieldWithoutImage
            gray={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.address"
            inputId="fields_definition.address"
          >
            <Field
              id="fields_definition.address"
              name="fields_definition.address"
              required={true}
              options={options}
              component={FieldInlineSelect}
            />
          </InputFieldWithoutImage>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <InputFieldWithoutImage
            gray={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.timezone"
            inputId="fields_definition.timezone_id"
          >
            <Field
              id="fields_definition.timezone_id"
              name="fields_definition.timezone_id"
              required={true}
              options={options}
              component={FieldInlineSelect}
            />
          </InputFieldWithoutImage>
        </Col>
        <Col xs={6}>
          <InputFieldWithoutImage
            gray={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.ssn"
            inputId="fields_definition.ssn"
          >
            <Field
              id="fields_definition.ssn"
              name="fields_definition.ssn"
              required={true}
              options={options}
              component={FieldInlineSelect}
            />
          </InputFieldWithoutImage>
        </Col>
      </Row>
    </div>
  );
};

export default ProjectModalRegistrationFieldsForm;
