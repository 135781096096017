import React, { useMemo } from 'react';
import GraspLedLevelListItem from './components/GraspLedLevelListItem';
import { IGraspLedLevel } from 'interfaces/grasps';
import { useCommonData } from 'modules/Organizations/views/OrganizationDetailsView/hooks/useCommonData';
import { useInitQuery } from 'hooks/queries/useInitQuery';
import CustomTable from 'components/CustomTable';

const headerItems = [
  'Organizations.OrganizationDetails.GraspSettings.GraspSettingsList.amplitudePercentageThreshold',
  'Organizations.OrganizationDetails.GraspSettings.GraspSettingsList.redLed',
  'Organizations.OrganizationDetails.GraspSettings.GraspSettingsList.greenLed',
  'Organizations.OrganizationDetails.GraspSettings.GraspSettingsList.blueLed',
  'Organizations.OrganizationDetails.GraspSettings.GraspSettingsList.actions',
];

type GraspSettingsListProps = {
  handleSelectLevel: (level: IGraspLedLevel | undefined) => void;
};

const GraspLedLevelsList = ({ handleSelectLevel }: GraspSettingsListProps) => {
  const { graspSettings } = useCommonData();
  useInitQuery(graspSettings);

  const renderBodyRows = useMemo(() => {
    if (!graspSettings.data) return [];

    return graspSettings.data.led_levels.map((level) => {
      return (
        <GraspLedLevelListItem
          key={level.id}
          level={level}
          handleSelectLevel={handleSelectLevel}
        />
      );
    });
  }, [graspSettings.data, handleSelectLevel]);

  return (
    <CustomTable
      isLoading={graspSettings.isLoading}
      headerItems={headerItems}
      bodyRows={renderBodyRows}
    />
  );
};

export default GraspLedLevelsList;
