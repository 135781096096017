import { faSave, faUndo } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import caseIcon from 'assets/images/case_icon.svg';
import defaultAvatarIcon from 'assets/images/default_avatar.svg';
import editIcon from 'assets/images/edit_icon_transparent.svg';

import envelopeIcon from 'assets/images/icon_envelope.svg';
import { useTypedContext } from 'hooks/useTypedContext';
import React, { useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from 'reactstrap';
import useCaseEdit from '../../../../../../../../../../api/mutations/cases/useCaseEdit';
import stageIcon from '../../../../../../../../../../assets/images/change/stage_icon.png';
import statusIcon from '../../../../../../../../../../assets/images/change/status_icon.png';
import FieldInlineSelect from '../../../../../../../../../../components/Fields/Formik/FieldInlineSelect';
import HeaderDetail from '../../../../../../../../../../components/HeaderDetail';
import InputWithDisabledDivDisplay from '../../../../../../../../../../components/InputWithDisabledDivDisplay';
import {
  GraspColors,
  graspColorsValues,
} from '../../../../../../../../../../helpers/colors';
import { textWrapEllipse } from '../../../../../../../../../../helpers/utils/strings';
import { statusDefinition } from '../../../../../../../../../../helpers/utils/translationObject';
import { useSubCategoriesOptions } from '../../../../../../../../../../hooks/options/useSubCategoriesOptions';
import { CaseTrackingType } from '../../../../../../../../../../interfaces/cases';
import { IPatchObject } from '../../../../../../../../../../interfaces/request';
import { CaseDetailsContext } from '../../../../../../types';
import { useCommonData } from '../../../../hooks/useCommonData';
import { ChangeCaseTabsValues } from '../../hooks/useTabs';
import { useChangeCaseTabsOptions } from './hooks/useChangeCaseTabsOptions';

type ChangeCaseHeaderProps = {
  organizationId: string;
  caseId: string;
  onTabChange?: (val: number) => void;
  useHistory?: boolean;
  openHistoryModal?: () => void;
  selectedTab?: ChangeCaseTabsValues;
  caseTrackingType: CaseTrackingType;
};

const ChangeCaseHeader = ({
  organizationId,
  onTabChange = (_: number) => {},
  useHistory = true,
  openHistoryModal,
  caseTrackingType,
  selectedTab = ChangeCaseTabsValues.Dashboard,
}: ChangeCaseHeaderProps) => {
  const intl = useIntl();

  const [editMode, setEditMode] = useState<boolean>(false);

  const caseDetails = useTypedContext(CaseDetailsContext);

  const { casePhaseHistoryQuery, caseDetailsQuery } = useCommonData();

  const [selectedSubCategory, setSelectedSubCategory] = useState<string>(
    caseDetails.sub_category_id ?? ''
  );
  const [selectedStatus, setSelectedStatus] = useState<string>(
    caseDetails.status ?? ''
  );

  const tabsOptions = useChangeCaseTabsOptions(onTabChange, selectedTab);

  const handleRefetch = async () => {
    await caseDetailsQuery.refetch();
    await casePhaseHistoryQuery.refetch();
    setEditMode(false);
  };

  const { mutateAsync: patchCase } = useCaseEdit(
    {
      organizationId: organizationId,
      caseId: caseDetails.id,
    },
    {
      successFb: handleRefetch,
    }
  );

  const participant = useMemo(
    () =>
      caseDetails.participants?.length > 0
        ? caseDetails.participants[0].personality
        : null,
    [caseDetails.participants]
  );

  const subCategoryOptions = useSubCategoriesOptions(
    organizationId,
    caseDetails.category_id ?? ''
  );

  const toggleEditMode = () => setEditMode((prev) => !prev);

  const handleSave = async () => {
    let params: IPatchObject[] = [];

    if (selectedSubCategory !== caseDetails.sub_category_id) {
      params.push({
        path: '/sub_category_id',
        op: 'replace',
        value: selectedSubCategory,
      });
    }

    if (selectedStatus !== caseDetails.status) {
      params.push({
        path: '/status',
        op: 'replace',
        value: selectedStatus,
      });
    }

    if (params.length === 0) {
      handleReset();
      return;
    }

    await patchCase(params);
  };

  const handleReset = () => {
    setSelectedSubCategory(caseDetails.sub_category_id ?? '');
    setSelectedStatus(caseDetails.status ?? '');
    setEditMode(false);
  };

  const onEdit = (target: string, value: string) => {
    if (target === 'sub_category_id') setSelectedSubCategory(value);
    if (target === 'status') setSelectedStatus(value);
  };

  return (
    <div className="ChangeCaseHeader">
      <div className="ChangeCaseHeader__profile">
        <div className="HeaderDetail">
          <div className="HeaderDetail__icon-container HeaderDetail__icon-container--stretched">
            {participant?.avatar ? (
              <img
                style={{ width: '32px', height: '32px', borderRadius: '50%' }}
                src={participant.avatar}
                alt={intl.formatMessage({
                  id: 'Cases.ChangeCase.ChangeCaseHeader.participantAvatar',
                })}
              />
            ) : (
              <SVG src={defaultAvatarIcon} width={32} height={32} />
            )}
          </div>
          <div className="HeaderDetail__text-container">
            <label htmlFor="participant" className="HeaderDetail__header">
              <FormattedMessage id="Cases.ChangeCase.ChangeCaseHeader.participant" />
            </label>
            <span className="HeaderDetail__description">
              <div className="CaseHeader__input">{`${participant?.first_name} ${participant?.last_name}`}</div>
            </span>
          </div>
        </div>
        <div className="HeaderDetail">
          <div className="HeaderDetail__icon-container HeaderDetail__icon-container--case">
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                width: '32px',
                height: '32px',
                borderRadius: '50%',
                background: graspColorsValues[GraspColors.BLUE],
              }}
            >
              <SVG src={caseIcon} width={20} height={20} color="white" />
            </div>
          </div>
          <div className="HeaderDetail__text-container--no-max">
            <label htmlFor="participant" className="HeaderDetail__header">
              <FormattedMessage id="Cases.ChangeCase.ChangeCaseHeader.title" />
            </label>
            <span className="HeaderDetail__description">
              <div className="CaseHeader__input">
                {textWrapEllipse(caseDetails.title ?? '', 25)}
              </div>
            </span>
          </div>
        </div>
      </div>
      <div className="ChangeCaseHeader__details">
        <HeaderDetail
          imageSrc={statusIcon}
          header={intl.formatMessage({ id: 'CaseCard.status' })}
          htmlFor="status"
          noMaxWidth
        >
          <InputWithDisabledDivDisplay
            disabled={!editMode}
            type="select"
            value={selectedStatus}
            name="status"
            id="status"
            options={statusDefinition}
            onEdit={onEdit}
            inputCssClass="CaseHeader__input"
          />
        </HeaderDetail>
        <HeaderDetail
          imageSrc={stageIcon}
          header={intl.formatMessage({ id: 'ChangeCase.stage' })}
          htmlFor="sub_category_id"
          noMaxWidth
        >
          <InputWithDisabledDivDisplay
            disabled={!editMode}
            type="select"
            value={selectedSubCategory}
            name="sub_category_id"
            id="sub_category_id"
            options={subCategoryOptions}
            onEdit={onEdit}
            inputCssClass="CaseHeader__input"
          />
        </HeaderDetail>
      </div>
      {editMode ? (
        <div className="ChangeCaseHeader__actions">
          <Button
            color="primary"
            outline={true}
            className="ChangeCaseHeader__action"
            onClick={handleSave}
          >
            <FontAwesomeIcon
              icon={faSave}
              color={graspColorsValues[GraspColors.BLUE]}
              title={intl.formatMessage({
                id: 'Cases.ChangeCase.save',
              })}
            />
          </Button>
          <Button
            color="primary"
            outline={true}
            className="ChangeCaseHeader__action"
            onClick={handleReset}
          >
            <FontAwesomeIcon
              icon={faUndo}
              color={graspColorsValues[GraspColors.BLUE]}
              title={intl.formatMessage({
                id: 'Cases.ChangeCase.revert',
              })}
            />
          </Button>
        </div>
      ) : (
        <div className="ChangeCaseHeader__actions">
          {tabsOptions.length > 0 &&
            caseTrackingType === CaseTrackingType.Change && (
              <FieldInlineSelect bordered={true} options={tabsOptions} />
            )}
          {useHistory && (
            <Button
              color="primary"
              className="ChangeCaseHeader__action"
              onClick={openHistoryModal}
            >
              <SVG
                src={envelopeIcon}
                title={intl.formatMessage({
                  id: 'Cases.ChangeCase.ChangeCaseHeader.sendMessage',
                })}
              />
            </Button>
          )}
          <Button
            color="primary"
            outline={true}
            onClick={toggleEditMode}
            className="ChangeCaseHeader__action"
          >
            <SVG
              src={editIcon}
              title={intl.formatMessage({
                id: 'Cases.ChangeCase.ChangeCaseHeader.editCase',
              })}
            />
          </Button>
        </div>
      )}
    </div>
  );
};

export default ChangeCaseHeader;
