import React from 'react';
import { IAsset } from '../../../../../../../../../../../../../../interfaces/educationMaterials';
import { AvailableCulture } from '../../../../../../../../../../../../../../hooks/cultures/useCultures';
import EditableInput from '../../../../../../../../../../../../../../components/EditableInput/EditableInput';
import { Label } from 'reactstrap';
import Flag from 'react-flagkit';
import { useTranslations } from '../../../../../../../../../../../../../../hooks/useTranslations';
import { useHandleEditFields } from './hooks/useHandleEditFields';

type EditModeItemCultureProps = {
  culture: AvailableCulture;
  modifiedAsset: IAsset;
  setModifiedAsset: (asset: IAsset) => void;
  cultures: AvailableCulture[];
  setCultures: (cultures: AvailableCulture[]) => void;
};

const EditModeItemCulture = ({
  modifiedAsset,
  setModifiedAsset,
  culture,
  setCultures,
  cultures,
}: EditModeItemCultureProps) => {
  const { wrapWithIntl, attachPrefix } = useTranslations(
    'Education.MaterialModal.MaterialModalBody.UploadAssetsStep.UploadAssetsStepItem.EditModeItem.EditModeItemCulture'
  );

  const { handleEditName, handleEditDescription } = useHandleEditFields(
    culture,
    modifiedAsset,
    setModifiedAsset
  );

  const toggleCulture = () => {
    if (cultures.some((p) => p.cultureCode === culture.cultureCode)) {
      setCultures(
        cultures.filter((p) => p.cultureCode !== culture.cultureCode)
      );
      return;
    }

    setCultures([...cultures, culture]);
  };

  return (
    <div className="d-flex flex-column gap-2">
      <div className="d-flex align-items-center gap-2">
        <EditableInput
          type="checkbox"
          value={culture.cultureCode}
          checked={cultures.some((p) => p.cultureCode === culture.cultureCode)}
          onEdit={toggleCulture}
          debounceEnabled={false}
        />
        <Label className="d-flex align-items-center gap-2" check>
          <span>{culture.cultureCode}</span>
          <Flag id={culture.cultureCode} country={culture.upperCountryCode} />
        </Label>
      </div>
      <EditableInput
        disabled={!cultures.some((p) => p.cultureCode === culture.cultureCode)}
        value={
          modifiedAsset.name_translations.find(
            (p) => p.culture === culture.cultureCode
          )?.value ?? ''
        }
        placeholder={wrapWithIntl(attachPrefix('name'))}
        onEdit={handleEditName}
        debounceEnabled={false}
      />
      <EditableInput
        type="textarea"
        disabled={!cultures.some((p) => p.cultureCode === culture.cultureCode)}
        value={
          modifiedAsset.description_translations.find(
            (p) => p.culture === culture.cultureCode
          )?.value ?? ''
        }
        placeholder={wrapWithIntl(attachPrefix('description'))}
        onEdit={handleEditDescription}
        debounceEnabled={false}
      />
    </div>
  );
};

export default EditModeItemCulture;
