import useGetInfiniteOrganizationMemberRequests from '../../../../../api/queries/requests/useGetInfiniteOrganizationMemberRequests';
import { REQUEST_PAGE_SIZE } from '../../../../../helpers/constants';
import { StatusEnum } from '../../../../../interfaces/enums';
import useGetInfiniteCaseMemberRequests from '../../../../../api/queries/requests/useGetInfiniteCaseMemberRequests';
import useDashboardTemplates from '../../../../../api/queries/dashboards/useDashboardTemplates';
import { useOrganizationPathParams } from '../../../../../hooks/paths/useOrganizationPathParams';

export const useCommonData = () => {
  const { organizationId } = useOrganizationPathParams();

  const organizationMemberRequests = useGetInfiniteOrganizationMemberRequests({
    params: {
      organizationId,
      pageSize: REQUEST_PAGE_SIZE,
      searchValue: '',
      status: StatusEnum.SENT,
    },
    options: { enabled: false, staleTime: Infinity },
  });

  const caseMemberRequests = useGetInfiniteCaseMemberRequests({
    params: {
      organizationId,
      pageSize: REQUEST_PAGE_SIZE,
      searchValue: '',
      status: StatusEnum.SENT,
    },
    options: { enabled: false, staleTime: Infinity },
  });

  const dashboardTemplates = useDashboardTemplates({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  return { organizationMemberRequests, caseMemberRequests, dashboardTemplates };
};
