import { useCommonParams } from './useCommonParams';
import useUserCaseDetails from '../../../api/queries/cases/useUserCaseDetails';
import useUserCaseObservationMeanings from '../../../api/queries/observationMeanings/useUserCaseObservationMeanings';
import useUserData from '../../../api/queries/membership/useUserData';

export const useCommonData = () => {
  const { userId, caseId } = useCommonParams();

  const userData = useUserData({
    params: { userId },
    options: { enabled: false, staleTime: Infinity },
  });

  const userCaseDetails = useUserCaseDetails({
    params: { userId, caseId },
    options: { enabled: false, staleTime: Infinity },
  });

  const observationMeanings = useUserCaseObservationMeanings({
    params: { userId, caseId },
    options: { enabled: false, staleTime: Infinity },
  });

  return {
    userData,
    userCaseDetails,
    observationMeanings,
  };
};
