import { useMemo } from 'react';
import useUserQuestionAnswerHistory from '../../../../../../../api/queries/change/useUserQuestionAnswerHistory';
import useExerciseSessions from '../../../../../../../api/queries/exerciseSessions/useExerciseSessions';
import useTimelineObservationsForPeriod from '../../../../../../../api/queries/timelines/useTimelineObservationsForPeriod';
import useTimelineSqueezeDataFetch from '../../../../../../../api/queries/timelines/useTimelineSqueezeDataFetch';
import useTimelineRelatedTrendVisualizationTemplates from '../../../../../../../api/queries/trendVisualizations/useTimelineRelatedTrendVisualizationTemplates';
import {
  getCaseDedicatedTimeline,
  getCaseMasterTimeline,
} from '../../../../../../../helpers/timelines';
import useAsyncEventListen from '../../../../../../../hooks/events/useAsyncEventListen';
import { useInitQuery } from '../../../../../../../hooks/queries/useInitQuery';
import { useQueriesLoading } from '../../../../../../../hooks/queries/useQueriesLoading';
import {
  Granularity,
  SqueezeQuantityGrouping,
} from '../../../../../../../interfaces/timeline';
import { useCommonData } from '../../../../../hooks/useCommonData';
import { useCommonParams } from '../../../../../hooks/useCommonParams';
import { useDates } from './useDates';
import { useQuestionIds } from './useQuestionIds';

export const useQueries = () => {
  const { userId } = useCommonParams();
  const { userCaseDetails } = useCommonData();
  useInitQuery(userCaseDetails);

  const { from, to } = useDates();

  const caseMasterTimelineId = useMemo(
    () => getCaseMasterTimeline(userCaseDetails.data),
    [userCaseDetails.data]
  );

  const caseTimelineId = useMemo(
    () => getCaseDedicatedTimeline(userCaseDetails.data),
    [userCaseDetails.data]
  );

  const trendVisualizationTemplatesQuery =
    useTimelineRelatedTrendVisualizationTemplates({
      params: {
        userId,
        timelineId: caseTimelineId ?? '',
        dateTo: null,
        dateFrom: null,
      },
      options: { enabled: false, staleTime: Infinity },
    });

  useInitQuery(trendVisualizationTemplatesQuery, !!caseTimelineId && !!userId);

  const questionIds = useQuestionIds(trendVisualizationTemplatesQuery.data);

  const exercisesQuery = useExerciseSessions({
    params: { userId, from, to },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(exercisesQuery);

  const observationsQuery = useTimelineSqueezeDataFetch({
    params: {
      timelineId: caseMasterTimelineId ?? '',
      granularity: Granularity.d1,
      dateRangeObject: { from, to },
      groupBy: SqueezeQuantityGrouping.Meaning,
    },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(
    observationsQuery,
    caseMasterTimelineId !== undefined && userCaseDetails.data !== undefined
  );

  const observationInTimelinesQuery = useTimelineObservationsForPeriod({
    params: {
      timelineId: caseMasterTimelineId ?? '',
      from,
      to,
    },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(
    observationInTimelinesQuery,
    caseMasterTimelineId !== undefined && userCaseDetails.data !== undefined
  );

  const questionAnswerHistoryQuery = useUserQuestionAnswerHistory({
    params: {
      userId,
      dateFrom: from,
      dateTo: to,
      questionIds: questionIds,
    },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(
    questionAnswerHistoryQuery,
    trendVisualizationTemplatesQuery.data !== undefined
  );

  useAsyncEventListen(
    'trends-refetch-requested',
    async () => {
      await exercisesQuery.refetch();
      await observationsQuery.refetch();
      await questionAnswerHistoryQuery.refetch();
    },
    true
  );

  const isLoading = useQueriesLoading([
    exercisesQuery,
    observationsQuery,
    trendVisualizationTemplatesQuery,
    questionAnswerHistoryQuery,
  ]);

  return {
    trendVisualizationTemplatesQuery,
    exercisesQuery,
    observationsQuery,
    questionAnswerHistoryQuery,
    observationInTimelinesQuery,
    isLoading,
  };
};
