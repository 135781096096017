import request from './utils/request';
import { IResponse } from '../interfaces/response';
import {
  IFeedbackResource,
  IUpsertFeedbackResource,
} from '../interfaces/feedbackResources';

export const getFeedbackResources = (organizationId: string) =>
  request<void, IResponse<IFeedbackResource[]>>({
    url: `/organizations/${organizationId}/feedback_resources`,
    method: 'GET',
  });

export const modifyFeedbackResource = (
  organizationId: string,
  groupCode: string,
  body: IUpsertFeedbackResource
) =>
  request<IUpsertFeedbackResource, void>({
    url: `/organizations/${organizationId}/feedback_resources/${groupCode}`,
    method: 'PUT',
    data: body,
  });

export const removeFeedbackResource = (
  organizationId: string,
  groupCode: string,
  culture: string
) =>
  request<void, void>({
    url: `/organizations/${organizationId}/feedback_resources/${groupCode}/${culture}`,
    method: 'DELETE',
  });
