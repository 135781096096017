import useUpdateMultipleOrganizationGrasps from 'api/mutations/grasps/useUpdateMultipleOrganizationGrasps';
import FieldSelect from 'components/FieldSelect';
import LoadingButton from 'components/LoadingButton';
import { Formik } from 'formik';
import { graspStatusOptions } from 'helpers/utils/translationObject';
import { mappedStatus, SelectionModalProps } from 'interfaces/grasps';
import { LocationItem } from 'interfaces/membership';
import { IOption } from 'interfaces/ui';
import { FormattedMessage } from 'react-intl';
import {
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';

interface GraspInOrgMultipleUpdateModalProps extends SelectionModalProps {
  isOpen: boolean;
  toggleModal: () => void;
  endpoints: LocationItem[];
  refetchGrasps: () => void;
}

const initialValues = {
  locationId: '',
  status: '',
};

const GraspInOrgMultipleUpdateModal = ({
  endpoints,
  organizationId,
  excluded,
  filters,
  included,
  isAllSelected,
  isOpen,
  toggleModal,
  search,
  refetchGrasps,
}: GraspInOrgMultipleUpdateModalProps) => {
  const onSuccess = () => {
    refetchGrasps();
    toggleModal();
  };

  const { mutate, isLoading } = useUpdateMultipleOrganizationGrasps(
    organizationId,
    onSuccess
  );

  const handleSubmit = (val: typeof initialValues) => {
    mutate({
      selection: {
        excluded: excluded.map((el) => el.id),
        selected: included.map((el) => el.id),
        all_selected: isAllSelected,
        blind_search: search,
        date_from: filters.fromDate,
        date_to: filters.toDate,
        endpoint_id: filters.endpoint,
        statuses: filters.statuses,
      },
      locationId: val?.locationId ? val.locationId : undefined,
      status: val?.status ? mappedStatus[val.status] : undefined,
    });
  };

  const endpointOptions: IOption[] = endpoints.map(({ id, name }) => ({
    value: id,
    text: name,
  }));

  return (
    <Modal isOpen={isOpen} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal}>
        <FormattedMessage id="OrganizationGrasps.modifySelected" />
      </ModalHeader>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ dirty, submitForm }) => (
          <>
            <ModalBody>
              <FormGroup>
                <Label htmlFor="locationId">
                  <FormattedMessage id="OrganizationGrasps.endpoint" />
                </Label>
                <FieldSelect
                  required
                  id="locationId"
                  name="locationId"
                  defaultText=""
                  options={endpointOptions}
                />
              </FormGroup>
              <FormGroup>
                <Label htmlFor="multiple_status">
                  <FormattedMessage id="OrganizationGrasps.status" />
                </Label>
                <FieldSelect
                  required
                  id="multiple_status"
                  name="status"
                  defaultText=""
                  options={graspStatusOptions}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <LoadingButton
                type="submit"
                disabled={!dirty}
                onClick={submitForm}
                isLoading={isLoading}
              >
                <FormattedMessage id="OrganizationGrasps.update" />
              </LoadingButton>
            </ModalFooter>
          </>
        )}
      </Formik>
    </Modal>
  );
};

export default GraspInOrgMultipleUpdateModal;
