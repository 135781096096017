import { isAuthenticated } from 'helpers/utils/auth';
import React from 'react';
import { Navigate, Outlet } from 'react-router';

type ProtectedRouteProps = {
  redirectTo?: string;
};

const NonAuthRouteGuard = ({
  redirectTo = '/dashboard',
}: ProtectedRouteProps) => {
  if (!isAuthenticated()) {
    return <Outlet />;
  } else return <Navigate to={redirectTo} />;
};

export default NonAuthRouteGuard;
