import { faInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ModifyCaseMemberRequestParams } from 'api/mutations/requests/useModifyCaseMemberRequest';
import LoadingButton from 'components/LoadingButton';
import StatusInfo from 'components/StatusInfo';
import { RequestActions, StatusEnum } from 'interfaces/enums';
import {
  ICaseAccessRequest,
  IMemberAccessRequest,
  IPersonality,
} from 'interfaces/personalities';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { UncontrolledTooltip } from 'reactstrap';

type RequestRowProps = {
  data: ICaseAccessRequest | IMemberAccessRequest;
  numberOfRequestedCases: number;
  casesNames: JSX.Element[] | JSX.Element;
  isLoading: boolean;
  mutate: (params: ModifyCaseMemberRequestParams) => void;
};

function isCaseAccessRequest(
  object: ICaseAccessRequest | IMemberAccessRequest
): object is ICaseAccessRequest {
  return 'case' in object;
}

const RequestRow = ({
  data,
  numberOfRequestedCases,
  casesNames,
  isLoading,
  mutate,
}: RequestRowProps) => {
  const targetCaseNames = `cases-names-${data.id}`;
  const isActionDisabled = data.status !== StatusEnum.SENT;

  const onAccept = () => {
    mutate({
      action: RequestActions.APPROVE,
      requestId: data.id,
    });
  };

  const onReject = () => {
    mutate({
      action: RequestActions.REJECT,
      requestId: data.id,
    });
  };

  let personality: IPersonality | null;

  if (isCaseAccessRequest(data) && data.case.personalities.length > 0) {
    personality = data.case.personalities[0];
  } else {
    personality = data.participant;
  }

  return (
    <tr key={data.id}>
      <td>
        <p>
          {data.created_by.first_name} {data.created_by.last_name}
        </p>
        <p>{data.created_by.email}</p>
      </td>
      <td>
        <p>
          {personality.first_name} {personality.last_name}
        </p>
        <p>{personality.email}</p>
      </td>
      <td>
        <div className="d-flex">
          {numberOfRequestedCases}
          {numberOfRequestedCases > 0 && (
            <UncontrolledTooltip placement="top" target={targetCaseNames}>
              {casesNames}
            </UncontrolledTooltip>
          )}
          <span id={targetCaseNames} className="info ms-2">
            <FontAwesomeIcon icon={faInfo} />
          </span>
        </div>
      </td>
      <td>{data.reason || '-'}</td>
      <td>{data.type}</td>
      <td>
        <StatusInfo status={data.status} />
      </td>
      <td>
        <LoadingButton
          disabled={isActionDisabled}
          className="me-2"
          isLoading={isLoading}
          onClick={onReject}
          color="danger"
        >
          <FormattedMessage id="General.reject" />
        </LoadingButton>
        <LoadingButton
          disabled={isActionDisabled}
          isLoading={isLoading}
          onClick={onAccept}
          color="primary"
        >
          <FormattedMessage id="General.accept" />
        </LoadingButton>
      </td>
    </tr>
  );
};

export default RequestRow;
