import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';
import { useCookie } from '../../../../../hooks/useCookie';
import { ViewTheme } from '../../../../../interfaces/ui';
import { useTabTitle } from '../../hooks/useTabTitle';
import TabHeader, { TabHeaderTooltip } from './components/TabHeader';

type GenericTabLayoutProps = {
  titlePrefix?: string;
  tooltip?: TabHeaderTooltip;
  actions?: JSX.Element;
  subHeader?: boolean;
};

const GenericTabLayout = ({
  children,
  tooltip,
  actions,
  titlePrefix,
  subHeader = true,
}: PropsWithChildren<GenericTabLayoutProps>) => {
  const tabTitle = useTabTitle(titlePrefix);
  const viewTheme = useCookie<ViewTheme>('theme');

  return (
    <div
      className={classNames('CaseCard__tab-container OrganizationsTab', {
        'OrganizationsTab--dark': viewTheme === ViewTheme.Dark,
      })}
    >
      <TabHeader
        header={tabTitle.header}
        subHeader={tabTitle.subHeader}
        showSubHeader={subHeader}
        tooltip={tooltip}
        actions={actions}
      />
      <div>{children}</div>
    </div>
  );
};

export default GenericTabLayout;
