import { useCallback, useMemo } from 'react';
import { InputOption } from '../../../../../../../../../../../interfaces/ui';
import { useCommonData } from '../../../../../../../../../hooks/useCommonData';
import { useInitQuery } from '../../../../../../../../../../../hooks/queries/useInitQuery';
import { useIntl } from 'react-intl';
import { translateTemplateIds } from '../../../../../../../../../../../helpers/utils/conversationTemplates';
import { ConversationQuestion } from '../../../../../../../../../../../interfaces/conversations';

export const useConversationQuestionsData = () => {
  const intl = useIntl();

  const { groupedConversationQuestions, conversationTemplates } =
    useCommonData();

  useInitQuery(conversationTemplates);
  useInitQuery(groupedConversationQuestions);

  const generateOptions = useCallback(
    (questions: ConversationQuestion[]): InputOption[] => {
      return questions.map((question) => ({
        text: question.content.title,
        value: question.resource_id,
      }));
    },
    []
  );

  const conversationQuestionOptions = useMemo((): Record<
    string,
    InputOption[]
  > => {
    let options: Record<string, InputOption[]> = {};

    const emptyTranslation = intl.formatMessage({
      id: 'ConversationsAndFeedbacks.ConversationTemplates.ConversationTemplatePhone.ConversationTemplatePhoneAddItem.notAssignedToAnyTemplate',
    });

    if (!groupedConversationQuestions.data) return options;

    groupedConversationQuestions.data.forEach((entry) => {
      options[
        translateTemplateIds(
          entry.template_ids,
          conversationTemplates.data ?? [],
          emptyTranslation
        )
      ] = generateOptions(entry.questions);
    });

    return options;
  }, [
    conversationTemplates.data,
    generateOptions,
    groupedConversationQuestions.data,
    intl,
  ]);

  const conversationQuestions = useMemo(() => {
    return (groupedConversationQuestions.data ?? []).flatMap(
      (p) => p.questions
    );
  }, [groupedConversationQuestions.data]);

  return {
    groupOptions: conversationQuestionOptions,
    conversationQuestions: conversationQuestions,
  };
};
