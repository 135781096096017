import request from './utils/request';
import { IResponse } from '../interfaces/response';
import {
  ICaseGroup,
  ICreateCaseGroup,
  ICreateCaseGroupSession,
  IGroupFeedback,
  ISummarizedBaseCaseGroup,
} from '../interfaces/caseGroups';
import { IPatchObject } from '../interfaces/request';

export const getCaseGroupsForOrganization = (organizationId: string) => {
  return request<undefined, IResponse<ISummarizedBaseCaseGroup[]>>({
    url: `/organizations/${organizationId}/case_groups`,
    method: 'GET',
  });
};

export const getCaseGroupsForProject = (
  organizationCode: string,
  projectId: string
) => {
  return request<undefined, IResponse<ISummarizedBaseCaseGroup[]>>({
    url: `/organizations/${organizationCode}/projects/${projectId}/case_groups`,
    method: 'GET',
  });
};

export const getUngroupedCasesCaseGroup = (organizationId: string) => {
  return request<undefined, IResponse<ICaseGroup>>({
    url: `/organizations/${organizationId}/case_groups/ungrouped`,
    method: 'GET',
  });
};

export const getCaseGroup = (
  organizationId: string,
  projectId: string,
  groupId: string
) => {
  return request<undefined, IResponse<ICaseGroup>>({
    url: `/organizations/${organizationId}/projects/${projectId}/case_groups/${groupId}`,
    method: 'GET',
  });
};

export const generateGroupFeedbackData = (
  organizationId: string,
  projectId: string,
  groupId: string
) => {
  return request<undefined, IResponse<IGroupFeedback[]>>({
    url: `/organizations/${organizationId}/projects/${projectId}/case_groups/${groupId}/group-feedback`,
    method: 'GET',
  });
};

export const editCaseGroup = (
  organizationId: string,
  projectId: string,
  groupId: string,
  data: IPatchObject[]
) => {
  return request<IPatchObject[], void>({
    url: `/organizations/${organizationId}/projects/${projectId}/case_groups/${groupId}`,
    method: 'PATCH',
    data: data,
  });
};

export const editCaseGroupSession = (
  organizationId: string,
  projectId: string,
  groupId: string,
  sessionId: string,
  data: IPatchObject[]
) => {
  return request<IPatchObject[], void>({
    url: `/organizations/${organizationId}/projects/${projectId}/case_groups/${groupId}/sessions/${sessionId}`,
    method: 'PATCH',
    data: data,
  });
};

export const createCaseGroupSession = (
  organizationId: string,
  projectId: string,
  groupId: string,
  data: ICreateCaseGroupSession
) => {
  return request<ICreateCaseGroupSession, void>({
    url: `/organizations/${organizationId}/projects/${projectId}/case_groups/${groupId}/sessions`,
    method: 'POST',
    data: data,
  });
};

export const deleteCaseGroupSession = (
  organizationId: string,
  projectId: string,
  groupId: string,
  sessionId: string
) => {
  return request({
    url: `/organizations/${organizationId}/projects/${projectId}/case_groups/${groupId}/sessions/${sessionId}`,
    method: 'DELETE',
  });
};

export const deleteCaseGroup = (
  organizationId: string,
  projectId: string,
  groupId: string
) => {
  return request({
    url: `/organizations/${organizationId}/projects/${projectId}/case_groups/${groupId}`,
    method: 'DELETE',
  });
};

export const createCaseGroup = (
  organizationId: string,
  projectId: string,
  data: ICreateCaseGroup
) => {
  return request<ICreateCaseGroup, void>({
    url: `/organizations/${organizationId}/projects/${projectId}/case_groups`,
    method: 'POST',
    data: data,
  });
};

export const addSubmissionsCaseToGroup = (
  organizationCode: string,
  projectId: string,
  groupId: string,
  submissionId: string
) => {
  return request<ICreateCaseGroup, void>({
    url: `/organizations/${organizationCode}/projects/${projectId}/case_groups/${groupId}/submissions/${submissionId}`,
    method: 'PUT',
  });
};
