import { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import * as Yup from 'yup';
import useCreateOrganizationDevice from '../../../../../../../../../../../api/mutations/organizations/useCreateOrganizationDevice';
import useOrganizationDevices from '../../../../../../../../../../../api/queries/organizations/useOrganizationDevices';
import { useOrganizationPathParams } from '../../../../../../../../../../../hooks/paths/useOrganizationPathParams';
import { IValidationSchemaBasedFormHookValues } from '../../../../../../../../../../../interfaces/forms';
import {
  ICreateOrganizationDevice,
  OrganizationDeviceType,
} from '../../../../../../../../../../../interfaces/organizations';
import { BaseModalProps } from '../../../../../../../../../../../interfaces/ui';

export type AddDeviceModelFormValues = {
  name?: string;
  type?: OrganizationDeviceType;
  serial_number?: string;
  apple_id?: string;
  apple_password?: string;
  screen_lock_code?: string;
};

export const useForm = (
  props: BaseModalProps
): IValidationSchemaBasedFormHookValues<AddDeviceModelFormValues> => {
  const { organizationId } = useOrganizationPathParams();

  const intl = useIntl();
  const invalidFieldValue = intl.formatMessage({
    id: 'General.InvalidFieldValue',
  });

  const organizationDevices = useOrganizationDevices({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const handleSuccess = async () => {
    await organizationDevices.refetch();
    props.toggle();
  };

  const { mutateAsync: createAsync } = useCreateOrganizationDevice({
    params: { organizationId },
    options: { successFb: handleSuccess },
  });

  const initialValues = useMemo((): AddDeviceModelFormValues => {
    return {
      name: undefined,
      type: undefined,
      serial_number: undefined,
      apple_id: undefined,
      apple_password: undefined,
      screen_lock_code: undefined,
    };
  }, []);

  const validationSchema = useMemo(
    () =>
      Yup.object().shape({
        name: Yup.string().min(1).required(invalidFieldValue),
        type: Yup.mixed<OrganizationDeviceType>()
          .oneOf(Object.values(OrganizationDeviceType))
          .required(invalidFieldValue),
        serial_number: Yup.string().min(1).required(invalidFieldValue),
        apple_id: Yup.string().when('type', {
          is: (deviceType: OrganizationDeviceType) =>
            deviceType === OrganizationDeviceType.Ipad,
          then: (schema) => schema.required(invalidFieldValue),
          otherwise: (schema) => schema.nullable(),
        }),
        apple_password: Yup.string().when('type', {
          is: (deviceType: OrganizationDeviceType) =>
            deviceType === OrganizationDeviceType.Ipad,
          then: (schema) => schema.required(invalidFieldValue),
          otherwise: (schema) => schema.nullable(),
        }),
        screen_lock_code: Yup.string(),
      }),
    [invalidFieldValue]
  );

  const handleSubmit = useCallback(
    async (values: typeof initialValues) => {
      await createAsync(values as ICreateOrganizationDevice);
    },
    [createAsync]
  );

  return {
    initialValues,
    handleSubmit,
    validationSchema,
  };
};
