import { IOrganizationCasePathParams } from '../../interfaces/paths';
import useRequiredParams from '../useRequiredParams';

export const useOrganizationCasePathParams =
  (): IOrganizationCasePathParams => {
    const { organizationId, caseId } = useRequiredParams<{
      organizationId: string;
      caseId: string;
    }>(['organizationId', 'caseId']);

    return { organizationId, caseId };
  };
