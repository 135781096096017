import { useMemo } from 'react';
import { ConversationQuestion } from '../../../../../../../interfaces/conversations';
import { IGrasp } from '../../../../../../../interfaces/grasps';
import { IOrganizationMembers } from '../../../../../../../interfaces/organizations';
import { DashboardOrganizationHelpers } from '../helpers/organizations';
import { DashboardQueriesContextType } from '../types';

export const useQueriesData = (
  questions: ConversationQuestion[],
  grasps: IGrasp[],
  organizationsMembers: IOrganizationMembers[]
) => {
  return useMemo((): DashboardQueriesContextType => {
    return {
      questions: questions,
      grasps: grasps,
      members: organizationsMembers.flatMap((p) => p.members),
      organizations: organizationsMembers.map((p) => p.organization),
      membersInOrganizations:
        DashboardOrganizationHelpers.GetMembersInOrganizations(
          organizationsMembers
        ),
    };
  }, [grasps, organizationsMembers, questions]);
};
