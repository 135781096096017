import { IEducationMaterial } from '../../../../../interfaces/educationMaterials';
import { useTranslations } from '../../../../../hooks/useTranslations';
import AssetsItem from '../components/AssetsItem';
import React, { useMemo } from 'react';
import { cloneDeep } from 'lodash';

export const useTable = (material: IEducationMaterial) => {
  const { attachPrefix } = useTranslations(
    'Education.EducationMaterialAssetsModal'
  );

  const headerItems = [
    attachPrefix('thumbnail'),
    attachPrefix('name'),
    attachPrefix('availableIn'),
    attachPrefix('actions'),
  ];

  const uniqueAssets = useMemo(() => {
    let assets = cloneDeep(
      material.assets_with_cultures.flatMap((p) => p.value)
    );

    const map = new Map(assets.map((p) => [p.id, p]));
    return [...map.values()];
  }, [material.assets_with_cultures]);

  const bodyRows = useMemo(() => {
    let uniqueAssetsCopy = cloneDeep(uniqueAssets);
    uniqueAssetsCopy.sort((a, b) => a.name.localeCompare(b.name));

    return uniqueAssetsCopy.map((asset) => (
      <AssetsItem key={asset.id} asset={asset} material={material} />
    ));
  }, [material, uniqueAssets]);

  return {
    headerItems,
    bodyRows,
  };
};
