import React from 'react';
import useInfiniteCaseHistory from 'api/queries/cases/useInfiniteCaseHistory';
import useOpen from 'hooks/useOpen';
import { FormattedMessage } from 'react-intl';
import { Button, ListGroup } from 'reactstrap';
import CaseHistoryModal from '../../../../../../../modals/CaseHistoryModal';
import InfiniteScroll from 'react-infinite-scroller';
import ActivitiesPerDate from 'components/Activity/components/ActivitiesPerDate';
import CaseActivityListItem from 'components/Activity/components/CaseActivityListItem';

//icons
import caseHistoryIcon from 'assets/images/timeline_details/case_history_icon.svg';
import addEntryIcon from 'assets/images/timeline_details/add_entry.svg';
import useRequiredParams from 'hooks/useRequiredParams';

const CaseHistory = () => {
  const { organizationId, caseId } = useRequiredParams<{
    organizationId: string;
    caseId: string;
  }>(['organizationId', 'caseId']);

  const { isOpen, toggle: toggleModal } = useOpen();
  const { hasNextPage, isLoading, fetchNextPage, historyItemsPerDay } =
    useInfiniteCaseHistory({ params: { organizationId, caseId } });

  const dates = historyItemsPerDay ? Object.keys(historyItemsPerDay) : [];

  const loadMore = () => fetchNextPage();

  const renderedRows = dates.map((date) => {
    if (historyItemsPerDay?.hasOwnProperty(date)) {
      const renderedActivities = historyItemsPerDay[date].map((historyData) => (
        <CaseActivityListItem {...historyData} key={historyData.id} />
      ));

      return (
        <ActivitiesPerDate key={date} date={date}>
          {renderedActivities}
        </ActivitiesPerDate>
      );
    } else return null;
  });

  return (
    <>
      <CaseHistoryModal
        isOpen={isOpen}
        toggleModal={toggleModal}
        caseId={caseId}
        organizationId={organizationId}
        hidePredefinedMessages={true}
        hideOwnerSelect={true}
      />
      <div className="cardContainer CaseHistory">
        <div className="cardContainer__header">
          <div className="cardContainer__header__title">
            <img
              src={caseHistoryIcon}
              className="cardContainer__header__title__image"
              alt={caseHistoryIcon}
            />
            <FormattedMessage id="CaseCard.caseHistory" />
          </div>
          <div className="cardContainer__header__button-container">
            <Button
              color="primary"
              className="cardContainer__header__button-container__button"
              onClick={toggleModal}
            >
              <img src={addEntryIcon} alt="sharing_icon" />
            </Button>
            <div className="cardContainer__header__button-container__text">
              <FormattedMessage id="CaseCard.History.addEntry" />
            </div>
          </div>
        </div>
        <InfiniteScroll
          className="CaseHistory__infinite"
          threshold={100}
          initialLoad={true}
          hasMore={!isLoading && hasNextPage}
          loadMore={loadMore}
        >
          <ListGroup className="PatientActivity__list PatientActivity__list--no-max-height">
            {renderedRows}
          </ListGroup>
        </InfiniteScroll>
      </div>
    </>
  );
};

export default CaseHistory;
