import React from 'react';
import { NavLink } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { DropdownItem } from 'reactstrap';
import { isEmpty } from 'lodash';
import { useCommonData } from '../../../../hooks/useCommonData';
import { useInitQueries } from '../../../../../../../../hooks/queries/useInitQuery';

type AccessDropdownItemProps = {
  links: string[];
};

const AccessDropdownItem = ({ links }: AccessDropdownItemProps) => {
  const { organizationMemberRequests, caseMemberRequests } = useCommonData();
  useInitQueries([organizationMemberRequests, caseMemberRequests]);

  const hasPendingOrganizationRequests = !isEmpty(
    organizationMemberRequests.requests
  );

  const hasPendingCaseRequests = !isEmpty(caseMemberRequests.caseRequests);

  const hasNoRequests =
    !hasPendingOrganizationRequests && !hasPendingCaseRequests;

  const hasAnyRequest =
    hasPendingOrganizationRequests || hasPendingCaseRequests;

  return (
    <DropdownItem className="OrganizationNavigation__dropdown-item p-0">
      <div className="d-flex align-items-center gap-1">
        <NavLink
          to={links[1]}
          className={classNames({
            'OrganizationNavigation__dropdown-link': hasNoRequests,
          })}
        >
          <FormattedMessage id="OrganizationNavigation.settings.usersAndAccess" />
        </NavLink>
        {hasAnyRequest && (
          <div className="pending-request-indicator align-self-start" />
        )}
      </div>
    </DropdownItem>
  );
};

export default AccessDropdownItem;
