import React from 'react';
import SVG from 'react-inlinesvg';
import verticalIcon from 'assets/images/vertical.svg';
import ItemContainer from '../ItemContainer';
import { useTranslations } from '../../../../../../../../hooks/useTranslations';
import {
  Cell,
  Legend,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from 'recharts';
import {
  GraspColors,
  graspColorsValues,
} from '../../../../../../../../helpers/colors';
import IntensityTimelineXAxisTick from './components/IntensityTimelineXAxisTick';
import { useXAxisDomain } from './hooks/useXAxisDomain';
import { useIntensity } from './hooks/useIntensity';
import { useAlarmIncidents } from './hooks/useAlarmIncidents';
import IntensityTimelineTooltip from './components/IntensityTimelineTooltip';
import { useFormatters } from './hooks/useFormatters';
import { useCookie } from '../../../../../../../../hooks/useCookie';
import { AwareCaseObservationsProps } from '../AwareCaseContent/types';
import { ViewTheme } from '../../../../../../../../interfaces/ui';

const IntensityTimeline = ({ observations }: AwareCaseObservationsProps) => {
  const { attachPrefix, wrapWithIntl } = useTranslations(
    'Cases.CaseDetails.AwareCase.IntensityTimeline'
  );

  const intensityData = useIntensity(observations);
  const alarmIncidentsData = useAlarmIncidents(observations);

  const theme = useCookie<ViewTheme>('theme');

  const xAxisDomain = useXAxisDomain();

  const { nameFormatter, valueFormatter } = useFormatters();

  const intensityTimelineTooltip = (props: any) => {
    return (
      <IntensityTimelineTooltip
        {...props}
        data={[...intensityData, ...alarmIncidentsData]}
        nameFormatter={nameFormatter}
        valueFormatter={valueFormatter}
      />
    );
  };

  return (
    <ItemContainer
      title={wrapWithIntl(attachPrefix('intensityTimeline'))}
      icon={<SVG src={verticalIcon} />}
    >
      <div className="h-px-150 mt-3">
        <ResponsiveContainer width="100%" height="100%">
          <ScatterChart>
            <defs>
              <radialGradient id="colorIntensityData">
                <stop offset="10%" stopColor="#407DBB" stopOpacity={1} />
                <stop offset="30%" stopColor="#407DBB" stopOpacity={0.8} />
                <stop offset="65%" stopColor="#407DBB" stopOpacity={0.5} />
              </radialGradient>
            </defs>
            <XAxis
              dataKey="time"
              name="time"
              interval={'preserveStartEnd'}
              type="number"
              tickLine={{
                stroke: theme === ViewTheme.Dark ? '#fff' : '#313131',
              }}
              axisLine={false}
              tickCount={20}
              padding={{ left: 30, right: 30 }}
              domain={xAxisDomain}
              tick={<IntensityTimelineXAxisTick />}
            />
            <YAxis
              dataKey="session"
              tick={false}
              domain={[0, 0]}
              axisLine={false}
              tickLine={false}
              width={28}
              type="number"
              name="session"
            />
            <ZAxis
              dataKey="visibleDuration"
              range={[100, 2000]}
              type="number"
              name="visibleDuration"
            />
            <Legend />
            <Tooltip content={intensityTimelineTooltip} />
            <ReferenceLine
              y={0}
              stroke={graspColorsValues[GraspColors.BLUE]}
              strokeWidth={2}
            />
            <Scatter
              name={wrapWithIntl(attachPrefix('intensity'))}
              data={intensityData}
              fill={graspColorsValues[GraspColors.Ocean600]}
            >
              {intensityData.map((entry) => (
                <Cell
                  key={`${entry.time}|${entry.visibleDuration}|${entry.level}`}
                  fill="url(#colorIntensityData)"
                />
              ))}
            </Scatter>
            <Scatter
              name={wrapWithIntl(attachPrefix('alarmIncidents'))}
              data={alarmIncidentsData}
              fill={graspColorsValues[GraspColors.Warm600]}
            />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    </ItemContainer>
  );
};

export default IntensityTimeline;
