import { getReportTemplate } from 'api/reports';
import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { IQueryRequest } from 'interfaces/request';

interface UseReportTemplateParams {
  code: string;
}

const useReportTemplate = ({
  params,
  options,
}: IQueryRequest<UseReportTemplateParams>) => {
  return useQuery({
    ...options,
    queryKey: [QueryKeys.getReportTemplate, params.code],
    queryFn: async () => {
      const { data } = await getReportTemplate(params.code);

      return data.data;
    },
  });
};

export default useReportTemplate;
