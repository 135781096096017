import React from 'react';
import { Col, Row } from 'reactstrap';
import SVG from 'react-inlinesvg';

import graspBlue from 'assets/images/split_login/grasp_blue_brand_2.0.svg';
import topRight from 'assets/images/split_login/top_right.svg';
import graspShadow from 'assets/images/split_login/grasp_shadow.svg';

const MembershipLayout = ({ children }: React.PropsWithChildren) => {
  return (
    <div className="LoginLayout">
      <div className="LoginLayout__wrapper">
        <Row className="d-flex w-100 h-100 no-gutters">
          <Col
            xs={12}
            sm={12}
            lg={6}
            xl={6}
            className="d-none d-lg-block overflow-hidden"
          >
            <div className="LoginLayout__wrapper__sidebar">
              <div className="LoginLayout__wrapper__sidebar__right-top-corner">
                <SVG src={topRight} title="Grasp" />
              </div>
              <div className="LoginLayout__wrapper__sidebar__left-bottom-corner"></div>
              <div className="LoginSlider__slide">
                <div className="d-flex flex-column align-items-center">
                  <SVG
                    className="LoginSlider__slide__grasp-device"
                    src={graspBlue}
                    title="Grasp Device"
                  />
                  <SVG
                    className="LoginSlider__slide__grasp-shadow"
                    src={graspShadow}
                    title="Grasp Device"
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col xs={12} sm={12} lg={6} xl={6}>
            <div className="LoginLayout__wrapper__content">
              {React.Children.only(children)}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MembershipLayout;
