import { useMemo, useState } from 'react';
import useInvitationDetails from 'api/queries/membership/useInvitationDetails';
import { Form, Formik } from 'formik';
import { Alert, Button, Col, FormGroup, Input, Row } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import LoadingButton from 'components/LoadingButton';
import { formatDateToInputDateString, isSameDate } from 'helpers/dates';
import useInvitationAccept from 'api/mutations/membership/useInvitationAccept';
import useRegisterInvitedUser from 'api/mutations/membership/useRegisterInvitedUser';
import { IInvitationForm } from 'interfaces/membership';
import { IPatchObject } from 'interfaces/request';
import usePatchPersonality from 'api/mutations/patients/usePatchPersonality';
import useInvitationReject from 'api/mutations/membership/useInvitationReject';
import { Link } from 'react-router-dom';
import RegisterFields from '../Register/components/RegisterFields';
import { RegisterFormKeys } from 'interfaces/enums';
import useRequiredParams from 'hooks/useRequiredParams';
import ScrollableMembershipLayout from 'components/layouts/ScrollableMembershipLayout';

const InvitationScreen = () => {
  const { invitationId, organizationId } = useRequiredParams<{
    invitationId: string;
    organizationId: string;
  }>(['invitationId', 'organizationId']);

  const { data, isLoading, refetch } = useInvitationDetails({
    params: { organizationId, invitationId },
  });

  const refetchFallback = () => refetch();

  const {
    mutate: invitationAcceptMutation,
    isLoading: isInvitationAcceptLoading,
  } = useInvitationAccept(invitationId, refetchFallback);

  const {
    mutate: invitationRejectMutation,
    isLoading: isInvitationRejectLoading,
  } = useInvitationReject(invitationId, refetchFallback);

  const onSuccess = () => {
    invitationAcceptMutation(data?.personality?.user_id as string);
  };

  const { mutate: registerUserMutation, isLoading: isRegisterLoading } =
    useRegisterInvitedUser({
      organizationId,
      invitationId,
      successFb: refetchFallback,
    });

  const {
    mutate: patchPersonalityMutation,
    isLoading: isPatchPersonalityLoading,
  } = usePatchPersonality(onSuccess);

  const [acceptTerms, setAcceptTerms] = useState(false);

  const onRejectClick = (event) => {
    event.preventDefault();
    if (data?.personality) invitationRejectMutation(data.personality.user_id);
  };

  const initialValues = useMemo(() => {
    const keys: IInvitationForm = {
      first_name: data?.personality?.first_name ?? '',
      last_name: data?.personality?.last_name ?? '',
      email: data?.email ?? '',
      address: data?.personality?.address ?? '',
      gender: data?.personality?.gender ?? '',
      culture: data?.personality?.culture ?? '',
      birthdate: data?.personality?.birthdate
        ? formatDateToInputDateString(new Date(data?.personality?.birthdate))
        : '',
      ssn: data?.personality?.ssn ?? '',
      password: '',
      passwordRepeat: '',
      phone_number: data?.personality?.phone_number ?? '',
    };

    return keys;
  }, [data]);

  const handleSubmit = (formData: typeof initialValues) => {
    if (!data?.personality) {
      registerUserMutation(formData);
    } else {
      const patchData: IPatchObject[] = [];

      if (
        (data.personality.birthdate &&
          !isSameDate(
            new Date(data.personality.birthdate),
            new Date(formData.birthdate)
          )) ||
        (!data.personality.birthdate && formData.birthdate)
      ) {
        patchData.push({
          op: 'replace',
          path: '/birthdate',
          value: new Date(formData.birthdate).toISOString(),
        });
      }

      for (const key of [
        'email',
        'first_name',
        'last_name',
        'ssn',
        'gender',
        'address',
        'phone_number',
      ]) {
        if (data.personality[key] !== formData[key]) {
          patchData.push({
            op: 'replace',
            path: `/${key}`,
            value: formData[key],
          });
        }
      }

      if (patchData.length) {
        patchPersonalityMutation({
          personalityId: data.personality.id,
          data: patchData,
        });
      } else {
        onSuccess();
      }
    }
  };

  const isPersonality = data?.personality;
  const readOnly = !!data?.personality && !!data.personality.organization_id;

  const allFields = [
    RegisterFormKeys.FIRST_NAME,
    RegisterFormKeys.LAST_NAME,
    RegisterFormKeys.EMAIL,
    RegisterFormKeys.BIRTH_DATE,
    RegisterFormKeys.GENDER,
    RegisterFormKeys.SSN,
    RegisterFormKeys.ADDRESS,
    RegisterFormKeys.PHONE_NUMBER,
  ];

  const mandatoryFields = [
    RegisterFormKeys.FIRST_NAME,
    RegisterFormKeys.LAST_NAME,
    RegisterFormKeys.EMAIL,
  ];

  const optionFields = [
    RegisterFormKeys.ADDRESS,
    RegisterFormKeys.GENDER,
    RegisterFormKeys.SSN,
    RegisterFormKeys.GENDER,
    RegisterFormKeys.PHONE_NUMBER,
    RegisterFormKeys.BIRTH_DATE,
  ];

  if (!isPersonality) mandatoryFields.push(RegisterFormKeys.PASSWORD);

  if (isLoading) return null;
  return (
    <ScrollableMembershipLayout>
      <div className="newMembersSection newMembersSection--invitation">
        <Col xs={12} sm={12} lg={9} xl={9}>
          <h2 className="newMembersSection__header">
            <FormattedMessage id="InvitationScreen.youWereInvitedToOrganization" />
            {`: ${data?.organization_name ?? ''}`}
          </h2>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {(form) => (
              <Form className="registerForm" role="form">
                <Row>
                  {data?.status &&
                    ['Rejected', 'Approved'].includes(data.status) && (
                      <Col xl={12}>
                        {data.status === 'Approved' && (
                          <Alert color="success">
                            <FormattedMessage id="InvitationScreen.invitationAccepted" />
                          </Alert>
                        )}
                        {data.status === 'Rejected' && (
                          <Alert color="danger">
                            <FormattedMessage id="InvitationScreen.invitationRejected" />
                          </Alert>
                        )}
                      </Col>
                    )}
                </Row>
                <RegisterFields
                  formik={form}
                  disabledFields={
                    readOnly ? allFields : [RegisterFormKeys.EMAIL]
                  }
                  mandatoryFields={mandatoryFields}
                  optionalFields={optionFields}
                />
                {data?.status === 'Sent' && (
                  <Row>
                    <Col xs={12}>
                      <FormGroup
                        style={{ marginLeft: '1.38rem' }}
                        className="form-group__noMargin"
                      >
                        <Input
                          type="checkbox"
                          name="acceptTerms"
                          id="acceptTerms"
                          required
                          checked={acceptTerms}
                          onChange={() => {
                            setAcceptTerms((acceptTerms) => !acceptTerms);
                          }}
                        />
                        <label
                          className="newMembersSection__secondLink__desc"
                          htmlFor="acceptTerms"
                        >
                          <span className="me-1">
                            <FormattedMessage id="Register.readAndAgree" />
                          </span>
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://grasp.global/terms-and-conditions"
                            className="newMembersSection__secondLink"
                          >
                            <FormattedMessage id="Register.RegisterForm.termsService" />
                          </a>
                          <span className="ms-1 me-1">
                            <FormattedMessage id="General.and" />
                          </span>
                          <a
                            rel="noopener noreferrer"
                            target="_blank"
                            href="https://grasp.global/privacy-policy"
                            className="newMembersSection__secondLink"
                          >
                            <FormattedMessage id="Register.RegisterForm.privacyPolicy" />
                          </a>
                        </label>
                      </FormGroup>
                    </Col>
                  </Row>
                )}
                {data?.status === 'Sent' && (
                  <div className="d-flex align-items-end justify-content-between mb-4 mt-1">
                    {isPersonality && (
                      <LoadingButton
                        outline
                        disabled={isLoading}
                        isLoading={isInvitationRejectLoading}
                        value="InvitationScreen.rejectInvite"
                        color="danger"
                        onClick={onRejectClick}
                      />
                    )}
                    <LoadingButton
                      outline
                      disabled={!acceptTerms}
                      isLoading={
                        isInvitationAcceptLoading ||
                        isRegisterLoading ||
                        isPatchPersonalityLoading
                      }
                      value="InvitationScreen.acceptInvite"
                      type="submit"
                      className="btn btn-login m-0"
                    />
                  </div>
                )}
                {data?.status &&
                  ['Rejected', 'Approved'].includes(data.status) && (
                    <div className="w-100 mb-2 d-flex justify-content-end">
                      <Link to="/">
                        <Button className="btn btn-login m-0">
                          <FormattedMessage id="InvitationScreen.goToPersonalSpace" />
                        </Button>
                      </Link>
                    </div>
                  )}
              </Form>
            )}
          </Formik>
        </Col>
      </div>
    </ScrollableMembershipLayout>
  );
};

export default InvitationScreen;
