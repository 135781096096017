import { useIntl } from 'react-intl';

export const useTranslations = () => {
  const intl = useIntl();

  const localizationPrefix =
    'Education.EducationMaterialCategoriesTab.LinkMaterialWithCategoryModal.';

  const attachPrefix = (id: string) => `${localizationPrefix}${id}`;
  const wrapWithIntl = (id: string) => intl.formatMessage({ id: id });

  const categoryLabel = attachPrefix('categoryLabel');
  const materialLabel = attachPrefix('materialLabel');

  const categoryPlaceholder = wrapWithIntl(attachPrefix('categoryPlaceholder'));
  const materialPlaceholder = wrapWithIntl(attachPrefix('materialPlaceholder'));

  const linkMaterialWithCategory = attachPrefix('linkMaterialWithCategory');

  const invalidCategory = wrapWithIntl(attachPrefix('invalidCategory'));
  const invalidMaterial = wrapWithIntl(attachPrefix('invalidMaterial'));

  return {
    linkMaterialWithCategory,
    invalidCategory,
    invalidMaterial,
    categoryLabel,
    materialLabel,
    categoryPlaceholder,
    materialPlaceholder,
  };
};
