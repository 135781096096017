import React, { useMemo } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classNames from 'classnames';
import { ITabContainerItem } from '../../types/Tabs';
import { useTabContainer } from '../../hooks/useTabContainer';
import SVG from 'react-inlinesvg';

export interface IPillsTabContainerItem extends ITabContainerItem {
  tabStatusIcon?: string;
  tabForceHighlighted?: boolean;
  disabled?: boolean;
  clickable?: boolean;
}

export type PillsTabContainerStylesDefinition = {
  wrapper?: string;
  container?: string;
  tab?: string;
  pane?: string;
};

type PillsTabContainerProps = {
  items: IPillsTabContainerItem[];
  onTabChange?: (index: number) => void;
  styles?: PillsTabContainerStylesDefinition;
};

const PillsTabContainer = ({
  onTabChange,
  styles,
  items,
}: PillsTabContainerProps) => {
  const { activeTab, toggle, defaultSelectedItems } =
    useTabContainer<IPillsTabContainerItem>({ items, onTabChange });

  const tabs = useMemo(() => {
    let _tabs: JSX.Element[] = [];

    defaultSelectedItems.forEach((item, index) => {
      const handleToggleItem = () => {
        if (item.disabled || !item.clickable) return;
        toggle(index);
      };

      _tabs.push(
        <NavItem
          key={`header-${index}`}
          active={activeTab === index}
          className={classNames(
            'PillsTabContainer__tab',
            {
              'PillsTabContainer__tab--highlighted': item.tabForceHighlighted,
              'PillsTabContainer__tab--disabled': item.disabled,
              'PillsTabContainer__tab--space-between': item.tabStatusIcon,
            },
            styles?.tab
          )}
        >
          <NavLink
            className="PillsTabContainer__link"
            disabled={item.disabled}
            onClick={handleToggleItem}
          >
            {item.header}
            {item.tabStatusIcon && (
              <div>
                <SVG src={item.tabStatusIcon} />
              </div>
            )}
          </NavLink>
        </NavItem>
      );
    });

    return _tabs;
  }, [activeTab, defaultSelectedItems, styles?.tab, toggle]);

  const panes = useMemo(() => {
    return defaultSelectedItems.map((item, index) => (
      <TabPane
        className={classNames(styles?.pane)}
        key={`pane-${index}`}
        tabId={index}
      >
        {item.shouldBeRendered ? item.component : <></>}
      </TabPane>
    ));
  }, [defaultSelectedItems, styles?.pane]);

  return (
    <div className={classNames('PillsTabContainer', styles?.wrapper)}>
      <Nav
        pills={true}
        justified={true}
        className={classNames('PillsTabContainer__tabs', styles?.container)}
      >
        {tabs}
      </Nav>
      <TabContent activeTab={activeTab} className="w-100">
        {panes}
      </TabContent>
    </div>
  );
};

export default PillsTabContainer;
