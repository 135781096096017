import React from 'react';
import {
  DashboardPathParams,
  DashboardTemplateContainerProps,
} from '../../index';
import { useOrganizationPathParams } from '../../../../../../../../hooks/paths/useOrganizationPathParams';
import useDashboardTemplate from '../../../../../../../../api/queries/dashboards/useDashboardTemplate';
import { useInitQuery } from '../../../../../../../../hooks/queries/useInitQuery';
import QueryLoaded from '../../../../../../../QueryLoaded';

type FromPathParamsProps = DashboardTemplateContainerProps &
  DashboardPathParams;

const FromPathParam = ({
  onNotFound,
  dashboardId,
  children,
}: FromPathParamsProps) => {
  const { organizationId } = useOrganizationPathParams();

  const dashboardTemplate = useDashboardTemplate({
    params: { organizationId, templateId: dashboardId },
    options: { enabled: false, staleTime: Infinity },
  });

  useInitQuery(dashboardTemplate);

  const errorHandler = (_: any) => <>{onNotFound}</>;

  return (
    <QueryLoaded query={dashboardTemplate} onError={errorHandler}>
      {(template) => children(template)}
    </QueryLoaded>
  );
};

export default FromPathParam;
