import pencilIcon from 'assets/images/pencil.svg';
import React from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import floppyDiscIcon from '../../../../../../../../../../assets/images/floppy-disc.svg';
import revertArrowIcon from '../../../../../../../../../../assets/images/revert-arrow.svg';
import EditableInput from '../../../../../../../../../../components/EditableInput/EditableInput';
import InputFieldWithoutImage from '../../../../../../../../../../components/InputFieldWithoutImage';
import { ISinglePropertyEditModalProps } from '../../../../../../../../../../interfaces/modals';

const NextPhaseConditionModal = ({
  onEdit,
  toggle,
  isOpen,
  value,
  name,
}: ISinglePropertyEditModalProps) => {
  return (
    <Modal
      className="ProjectPhaseNextPhaseConditionEditModal"
      size="md"
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalHeader className="ProjectPhaseNextPhaseConditionEditModal__header">
        <SVG src={pencilIcon} title="Add template" width={20} height={20} />
        <FormattedMessage
          tagName="span"
          id="Projects.ProjectPhases.ProjectPhasesContent.ProjectPhase.ProjectPhaseNextPhaseConditionEditModal.nextPhaseCondition"
        />
      </ModalHeader>
      <ModalBody className="ProjectPhaseNextPhaseConditionEditModal__body">
        <InputFieldWithoutImage
          gray={true}
          noMargin={true}
          labelTranslationId="Projects.ProjectPhases.ProjectPhasesContent.ProjectPhase.ProjectPhaseNextPhaseConditionEditModal.nextPhaseCondition"
          inputId={name}
        >
          <EditableInput
            type="textarea"
            value={value}
            onEdit={onEdit}
            name={name}
            debounceEnabled={false}
          />
        </InputFieldWithoutImage>
      </ModalBody>
      <ModalFooter className="ProjectPhaseNextPhaseConditionEditModal__footer">
        <Button color="primary" onClick={toggle} outline={true}>
          <SVG src={floppyDiscIcon} title="save" />
        </Button>
        <Button color="primary" onClick={toggle} outline={true}>
          <SVG src={revertArrowIcon} title="cancel" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default NextPhaseConditionModal;
