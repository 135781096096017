import React from 'react';
import { Field, FormikProps } from 'formik';
import FieldInput from 'components/FieldInput';
import { TemplateItemType } from 'interfaces/change';
import { TrendVisualizationChartType } from 'interfaces/trendVisualizations';
import { AddTooltipFormValues } from '../../../../hooks/useAddTooltipForm';
import { useTranslations } from 'hooks/useTranslations';

type NonQuestionsItemProps = {
  type: Omit<TemplateItemType, TemplateItemType.Questions>;
  form: FormikProps<AddTooltipFormValues>;
};

const NonQuestionsItem = ({ type, form }: NonQuestionsItemProps) => {
  const { attachPrefix, wrapWithIntl } = useTranslations(
    'Organizations.OrganizationDetails.TrendVisualizations.TrendVisualizationModal.FormHeading.FormHeadingActions.AddTooltip.AddTooltipForm'
  );

  const itemType = type as TemplateItemType;

  return (
    <div className="d-flex align-items-center ms-3 gap-3">
      <Field
        id={`AddTooltipForm|${itemType}|${TrendVisualizationChartType.LineChart}`}
        checked={
          form.values[itemType].chartType ===
          TrendVisualizationChartType.LineChart
        }
        type="radio"
        name={`${type}.chartType`}
        value={TrendVisualizationChartType.LineChart}
        label={wrapWithIntl(
          attachPrefix(TrendVisualizationChartType.LineChart)
        )}
        component={FieldInput}
      />
      <Field
        id={`AddTooltipForm|${type}|${TrendVisualizationChartType.BarChart}`}
        checked={
          form.values[itemType].chartType ===
          TrendVisualizationChartType.BarChart
        }
        type="radio"
        name={`${type}.chartType`}
        value={TrendVisualizationChartType.BarChart}
        label={wrapWithIntl(attachPrefix(TrendVisualizationChartType.BarChart))}
        component={FieldInput}
      />
    </div>
  );
};

export default NonQuestionsItem;
