import React from 'react';
import { IAsset } from '../../../../../../interfaces/educationMaterials';
import CustomTable from '../../../../../../components/CustomTable';
import { useHeaderItems } from './hooks/useHeaderItems';
import AssetThumbnailUploadRow from './components/AssetThumbnailUploadRow';

type AssetThumbnailUploadProps = {
  asset: IAsset;
};

const AssetThumbnailUpload = ({ asset }: AssetThumbnailUploadProps) => {
  const { headerItems } = useHeaderItems();

  return (
    <CustomTable
      headerItems={headerItems}
      bodyRows={[<AssetThumbnailUploadRow key={asset.id} asset={asset} />]}
    />
  );
};

export default AssetThumbnailUpload;
