import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useUserSharingRequests from 'api/queries/timelines/useUserSharingRequests';
import { getUserId } from 'helpers/utils/auth';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { Alert } from 'reactstrap';

const SharingRequestsAlert = () => {
  const userId = getUserId();
  const { data } = useUserSharingRequests({ params: { userId } });

  const sentSharingRequests = data?.filter(
    (request) => request.status === 'Sent'
  );

  if (!sentSharingRequests || sentSharingRequests.length < 1) return null;

  return (
    <Link to="/sharing-requests">
      <Alert color="primary" className="InfoAlert mb-4 shadow">
        <FontAwesomeIcon icon={faInfoCircle} className="me-1" />
        <FormattedMessage id="UserDashboard.sharingRequest.youGot" />
        {` ${sentSharingRequests.length} `}
        <FormattedMessage id="UserDashboard.sharingRequest.sharingWaiting" />
      </Alert>
    </Link>
  );
};

export default SharingRequestsAlert;
