import React from 'react';
import { IBaseProjectModalFormPart } from '../../types/ProjectModal';
import { Col, Row } from 'reactstrap';
import InputFieldWithoutImage from '../../../../../../components/InputFieldWithoutImage';
import { Field } from 'formik';
import FieldHtmlEditor from '../../../../../../components/Fields/Formik/FieldHtmlEditor';
import { useProjectModalSubmissionSetupForm } from './hooks/useProjectModalSubmissionSetupForm';
import FieldSelect from '../../../../../../components/FieldSelect';
import { AvailableCulture } from '../../../../../../hooks/cultures/useCultures';
import { useIntl } from 'react-intl';

type ProjectModalSubmissionSetupFormProps = IBaseProjectModalFormPart & {
  selectedCulture: AvailableCulture;
};

const ProjectModalSubmissionSetupForm = ({
  selectedCulture,
}: ProjectModalSubmissionSetupFormProps) => {
  const intl = useIntl();

  const { options } = useProjectModalSubmissionSetupForm();

  return (
    <div className="d-flex flex-column gap-4">
      <Row>
        <Col xs={6}>
          <InputFieldWithoutImage
            gray={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.templateCode"
            inputId="template_code"
          >
            <Field
              id="template_code"
              name="template_code"
              placeholder={intl.formatMessage({
                id: 'Projects.ProjectModal.ProjectModalOverviewForm.templateCodePlaceholder',
              })}
              displayEmpty={true}
              options={options.conversationTemplates}
              component={FieldSelect}
            />
          </InputFieldWithoutImage>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <InputFieldWithoutImage
            unstyled={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.successfulSubmissionMessage"
            inputId={`successful_submission_message.${selectedCulture.cultureCode}`}
          >
            <Field
              id={`successful_submission_message.${selectedCulture.cultureCode}`}
              name={`successful_submission_message.${selectedCulture.cultureCode}`}
              placeholder={intl.formatMessage({
                id: 'Projects.ProjectModal.ProjectModalOverviewForm.textPlaceholder',
              })}
              editorClassNames="w-100 h-100"
              condensed={false}
              component={FieldHtmlEditor}
            />
          </InputFieldWithoutImage>
        </Col>
        <Col xs={6}>
          <InputFieldWithoutImage
            unstyled={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.unsuccessfulSubmissionMessage"
            inputId={`unsuccessful_submission_message.${selectedCulture.cultureCode}`}
          >
            <Field
              id={`unsuccessful_submission_message.${selectedCulture.cultureCode}`}
              name={`unsuccessful_submission_message.${selectedCulture.cultureCode}`}
              editorClassNames="w-100 h-100"
              placeholder={intl.formatMessage({
                id: 'Projects.ProjectModal.ProjectModalOverviewForm.textPlaceholder',
              })}
              condensed={false}
              component={FieldHtmlEditor}
            />
          </InputFieldWithoutImage>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <InputFieldWithoutImage
            unstyled={true}
            noMargin={true}
            labelTranslationId="Projects.ProjectModal.ProjectModalOverviewForm.postRegisterMessage"
            inputId={`post_register_message.${selectedCulture.cultureCode}`}
          >
            <Field
              id={`post_register_message.${selectedCulture.cultureCode}`}
              name={`post_register_message.${selectedCulture.cultureCode}`}
              editorClassNames="w-100 h-100"
              placeholder={intl.formatMessage({
                id: 'Projects.ProjectModal.ProjectModalOverviewForm.textPlaceholder',
              })}
              condensed={false}
              component={FieldHtmlEditor}
            />
          </InputFieldWithoutImage>
        </Col>
      </Row>
    </div>
  );
};

export default ProjectModalSubmissionSetupForm;
