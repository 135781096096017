import { ThemeOptions, ThemeProvider, useTheme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import React, { PropsWithChildren, useMemo } from 'react';
import { DashboardTemplateVisualization } from '../../../../../../../../../../../../../../interfaces/dashboards';

type VisualizationThemeWrapperProps = {
  visualization: DashboardTemplateVisualization;
};

const VisualizationThemeWrapper = ({
  visualization,
  children,
}: PropsWithChildren<VisualizationThemeWrapperProps>) => {
  const theme = useTheme();

  const primary = useMemo(
    () => visualization.palette.find((p) => p.name === 'primary')?.hex,
    [visualization.palette]
  );

  const text = useMemo(
    () => visualization.palette.find((p) => p.name === 'text')?.hex,
    [visualization.palette]
  );

  const options = useMemo(() => {
    let _options: ThemeOptions = {
      palette: {},
    };

    if (primary && _options.palette)
      _options.palette['background'] = { default: primary };

    if (text && _options.palette) _options.palette['text'] = { primary: text };

    return _options;
  }, [primary, text]);

  return (
    <ThemeProvider theme={deepmerge(theme, options)}>{children}</ThemeProvider>
  );
};

export default VisualizationThemeWrapper;
