import React from 'react';
import {
  Bar,
  BarChart,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { IGroupFeedbackRangeQuestionAnswer } from '../../../../../../../interfaces/caseGroups';
import { lighten } from '../../../../../../../helpers/colors';

type GroupFeedbackRangeChartProps = {
  questionTitle: string;
  answers: IGroupFeedbackRangeQuestionAnswer[];
};

const CustomTooltip = (props: any) => {
  if (props.active && props.payload && props.payload.length) {
    return (
      <div className="GroupFeedbackRangeChartTooltip">
        <span className="GroupFeedbackRangeChartTooltip__label">{`${props.payload[0].payload.name} : ${props.payload[0].value}`}</span>
      </div>
    );
  }

  return null;
};

const GroupFeedbackRangeChart = ({
  answers,
  questionTitle,
}: GroupFeedbackRangeChartProps) => {
  const data: any[] = answers.map((answer, index) => ({
    name: answer.displayName ?? `Participant no: ${index + 1}`,
    [questionTitle]: answer.value?.toFixed(0) ?? 0,
  }));

  const baseColor: string = '#18466A';

  const tickFormatter = (value: any, index: number) =>
    data[index]?.name ?? `Participant no: ${index + 1}`;

  return (
    <div className="p-5">
      <ResponsiveContainer width="100%" height={360}>
        <BarChart
          layout="vertical"
          barCategoryGap={3}
          width={730}
          height={250}
          data={data}
        >
          <XAxis type="number" tickMargin={10} />
          <YAxis
            type="category"
            dataKey={questionTitle}
            width={150}
            tickFormatter={tickFormatter}
          />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          <Bar dataKey={questionTitle}>
            {data.map((entry, index) => (
              <Cell key={entry} fill={lighten(baseColor, index * 20)} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GroupFeedbackRangeChart;
