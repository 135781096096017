import { ILoginResponse } from '../../../interfaces/membership';
import { ITypedMutationRequest } from '../../../interfaces/request';
import {
  displayErrorArrayNotifications,
  displayErrorNotification,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { signIn } from '../../membership';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

type LoginDataParams = {
  username: string;
  password: string;
};

const useLoginData = ({
  options,
  silent,
}: ITypedMutationRequest<any, ILoginResponse>) => {
  const mutationData = useMutationWithResultNotification({
    asyncApiFunction: async ({ username, password }: LoginDataParams) => {
      const { data } = await signIn({
        email: username,
        password,
        application_type: 'Web',
      });

      return data.data;
    },
    resetOnResult: true,
    onSuccess: () => {
      if (!silent)
        displaySuccessNotification(
          'Api.Mutations.Membership.LoginData.success'
        );

      mutationData.data && options.successFb?.(mutationData.data);
    },
    onError: (err) => {
      displayErrorNotification('Api.Mutations.Membership.LoginData.failure');
      options.errorFb?.(err);
    },
    onErrorArray: (errorArray) => {
      displayErrorArrayNotifications(
        errorArray,
        'Api.Mutations.Membership.LoginData.failure'
      );
      options.errorFb?.(errorArray);
    },
  });

  return mutationData;
};

export default useLoginData;
