import { useCallback } from 'react';
import { useTreatmentPlanCommonData } from '../../../hooks/useTreatmentPlanCommonData';

export const useTreatmentPhaseCode = () => {
  const { caseCategories } = useTreatmentPlanCommonData();

  return useCallback(
    (phaseId: string) => {
      const existingSubCategories = (caseCategories.data ?? []).flatMap(
        (p) => p.sub_categories
      );

      const existingSubCategory = existingSubCategories.find(
        (p) => p.id === phaseId
      );

      if (existingSubCategory === undefined) return phaseId;

      return existingSubCategory.code;
    },
    [caseCategories]
  );
};
