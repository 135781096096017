import { faQuestionCircle, faThumbsUp } from '@fortawesome/pro-light-svg-icons';
import { faThumbsDown, IconDefinition } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StatusEnum } from 'interfaces/enums';
import React from 'react';

type StatusInfoProps = {
  status: StatusEnum;
};

const StatusInfo: React.FC<StatusInfoProps> = ({ status }) => {
  let iconDefinition: IconDefinition;

  if (status === StatusEnum.APPROVED) {
    iconDefinition = faThumbsUp;
  } else if (status === StatusEnum.REJECTED) {
    iconDefinition = faThumbsDown;
  } else iconDefinition = faQuestionCircle;

  return (
    <div className={`d-flex align-items-center status--${status}`}>
      <FontAwesomeIcon icon={iconDefinition} className="me-1" /> {status}
    </div>
  );
};

export default StatusInfo;
