import './helpers/polyfills';

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import LanguageProvider from './translations/utils/LanguageProvider';
import store from './store';
import App from './App';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import './assets/styles/base.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-quill/dist/quill.snow.css';
import { queryClient } from 'helpers/queryClient';
import Notifications from './modules/Notifications';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from './components/ErrorFallback';
import { ThemeProvider } from '@mui/material';
import { ThemeGenerators } from './themes/generators';

const renderReactQueryDevTools = () =>
  process.env.NODE_ENV === 'development' ? (
    <ReactQueryDevtools initialIsOpen={false} />
  ) : (
    <></>
  );

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <Provider store={store}>
    <LanguageProvider>
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <ThemeProvider theme={ThemeGenerators.BaseThemeGenerator()}>
            <Notifications />
            <App />
          </ThemeProvider>
          {renderReactQueryDevTools()}
        </ErrorBoundary>
      </QueryClientProvider>
    </LanguageProvider>
  </Provider>
);
