import { useQuery } from 'react-query';
import { IQueryRequest } from '../../../interfaces/request';
import { getAwareSessionsWithReferencePeriod } from '../../awareSessions';
import QueryKeys from '../queryKeys';
import {
  applyAwareSessionParams,
  AwareSessionsQueryParams,
} from './useOrganizationAwareSessions';

const useAwareSessionsWithReferencePeriod = ({
  params,
  options,
}: IQueryRequest<AwareSessionsQueryParams>) => {
  const { organizationId, aggregated } = params;
  return useQuery({
    ...options,
    queryKey: [
      QueryKeys.getAwareSessionsWithReferencePeriod,
      organizationId,
      aggregated,
      params.dateRange.from?.toISOString(),
      params.dateRange.to?.toISOString(),
      Object.values(params.filters).join('|'),
    ],
    queryFn: async () => {
      const queryParams = applyAwareSessionParams(params);
      const { data } = await getAwareSessionsWithReferencePeriod(
        organizationId,
        queryParams
      );

      return data.data;
    },
  });
};

export default useAwareSessionsWithReferencePeriod;
