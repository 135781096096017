import { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { isSameDay } from 'date-fns';

//interfaces
import {
  GraspEventItemData,
  GraspEventItemType,
  IObservation,
  Meaning,
} from 'interfaces/timeline';
import { ICaseHistory } from 'interfaces/caseHistory';

//helpers
import useTimelineAnnotations from 'api/queries/timelines/useTimelineAnnotations';

//components
import GraspEventItem from './GraspEventItem';
import AnnotationModal from '../../../AnnotationModal';
import CaseHistoryModal from 'modules/Cases/modals/CaseHistoryModal';
import ObservationModal from '../../../ObservationModal';
import EmptyInformation from 'modules/TimelineDetails/EmptyInformation';
import { ObservationListProps } from 'interfaces/ui';
import useUserPathBasedBrowsingContext from 'hooks/useUserPathBasedBrowsingContext';
import useUserContextTimelineObservations from 'api/queries/timelines/useUserContextTimelineObservations';
import useCaseContextTimelineObservations from 'api/queries/cases/useCaseContextTimelineObservations';
import { useTimelineDetailsSelectedDate } from 'store/reducerHooks';

const TimelineObservationList = ({
  timelineId,
  selectedDates,
  isOwnerOfTimeline,
  historyFromCase,
  shouldFetchTimelineAnnotations = false,
}: ObservationListProps) => {
  const intl = useIntl();

  const selectedDate = useTimelineDetailsSelectedDate();

  const {
    organizationId = '',
    caseId = '',
    isRegularUserContext,
    isOrganizationContext,
  } = useUserPathBasedBrowsingContext();

  //edit annotation
  const [annotationIdToEdit, setAnnotationIdToEdit] = useState<null | string>(
    null
  );

  const [eventItemExpanded, setEventItemExpanded] = useState<null | string>(
    null
  );

  const isEditAnnotationModalOpen = !!annotationIdToEdit;
  const closeEditAnnotation = () => {
    setAnnotationIdToEdit(null);
  };

  //edit observation
  const [observationToEdit, setObservationToEdit] =
    useState<IObservation | null>(null);
  const isObservationModalOpen = !!observationToEdit;
  const closeObservation = () => setObservationToEdit(null);

  //edit history entry
  const closeUpdateModal = () => {
    setHistoryDataToUpdate(null);
  };

  const [historyDataToUpdate, setHistoryDataToUpdate] =
    useState<null | ICaseHistory>(null);

  const isHistoryUpdateOpen = !!historyDataToUpdate;

  //fetch data
  const userContextTimelineObservations = useUserContextTimelineObservations({
    params: {
      timelineId,
      selectedDates,
      grouped: true,
    },
    options: { enabled: isRegularUserContext },
  });

  const organizationContextTimelineObservations =
    useCaseContextTimelineObservations({
      params: {
        organizationId,
        caseId,
        timelineId,
        selectedDates,
        grouped: true,
      },
      options: { enabled: isOrganizationContext },
    });

  const timelineObservationsQuery = isRegularUserContext
    ? userContextTimelineObservations
    : organizationContextTimelineObservations;

  const { data: timelineAnnotations } = useTimelineAnnotations({
    params: { timelineId },
    options: { enabled: shouldFetchTimelineAnnotations },
  });

  const returnPredefinedText = useCallback(
    (singleObservation: IObservation) => {
      return `${intl.formatMessage({
        id: 'TimelineDetails.dayDetails.graspDeviceObservation',
      })}. ${intl.formatMessage({
        id: 'TimelineDetails.dayDetails.squeezeDuration',
      })}: ${singleObservation.duration_in_seconds} sec,\n ${intl.formatMessage(
        {
          id: 'TimelineDetails.dayDetails.painLevel',
        }
      )}: ${singleObservation.level}`;
    },
    [intl]
  );

  const sortChildrenObservations = (children: IObservation[]) =>
    children.sort((a, b) => a.timestamp.localeCompare(b.timestamp));

  const detailArray = useMemo(() => {
    const details: GraspEventItemData[] = [];

    if (!selectedDate) return details;

    if (timelineObservationsQuery.data) {
      for (const singleObservation of timelineObservationsQuery.data) {
        if (isSameDay(new Date(singleObservation.timestamp), selectedDate)) {
          const text =
            singleObservation.description ||
            returnPredefinedText(singleObservation);

          let startDate = singleObservation.timestamp;
          let endDate = singleObservation.timestamp;

          if (singleObservation.children_observations.length !== 0) {
            const observationsSortedByTimestamp = sortChildrenObservations(
              singleObservation.children_observations
            );

            startDate = observationsSortedByTimestamp[0].timestamp;
            endDate =
              observationsSortedByTimestamp[
                observationsSortedByTimestamp.length - 1
              ].timestamp;
          }

          const childrenObservations: GraspEventItemData[] =
            singleObservation.children_observations.map((child) => ({
              beginDate: new Date(child.timestamp),
              endDate: new Date(child.timestamp),
              id: child.id,
              type: GraspEventItemType.OBSERVATION,
              meaning: Meaning[child.symptom],
              children_observations: [],
              description: child.description,
              is_group: false,
              level: child.level,
              duration_in_seconds: child.duration_in_seconds,
              text,
              onEditClick: () => setObservationToEdit(child),
            }));

          details.push({
            beginDate: new Date(startDate),
            endDate: new Date(endDate),
            id: singleObservation.id,
            type: GraspEventItemType.OBSERVATION,
            meaning: Meaning[singleObservation.symptom],
            children_observations: childrenObservations,
            description: singleObservation.description,
            is_group: singleObservation.is_group,
            level: singleObservation.level,
            duration_in_seconds: singleObservation.duration_in_seconds,
            text,
            onEditClick: () => setObservationToEdit(singleObservation),
          });
        }
      }
    }

    if (historyFromCase) {
      for (const singleHistory of historyFromCase) {
        if (isSameDay(new Date(singleHistory.time_start), selectedDate)) {
          details.push({
            beginDate: new Date(singleHistory.time_start),
            endDate: new Date(singleHistory.time_end),
            id: singleHistory.id,
            type: GraspEventItemType.CASE_HISTORY,
            text: singleHistory.text,
            children_observations: [],
            description: singleHistory.text,
            is_group: false,
            onEditClick: () => setHistoryDataToUpdate(singleHistory),
          });
        }
      }
    }

    if (timelineAnnotations) {
      timelineAnnotations.forEach(
        ({ time_end, time_start, text, id, is_members }) => {
          if (
            isSameDay(new Date(time_start), selectedDate) &&
            (isRegularUserContext || (isOrganizationContext && !is_members))
          ) {
            details.push({
              beginDate: new Date(time_start),
              endDate: new Date(time_end),
              id,
              type: GraspEventItemType.ANNOTATION,
              text,
              description: text,
              children_observations: [],
              is_group: false,
              onEditClick: isOwnerOfTimeline
                ? () => setAnnotationIdToEdit(id)
                : undefined,
            });
          }
        }
      );
    }

    details.sort((prev, next) => {
      if (prev.beginDate?.getTime() > next.beginDate?.getTime()) return 1;
      if (prev.beginDate?.getTime() < next.beginDate?.getTime()) return -1;
      else return 0;
    });

    return details;
  }, [
    timelineAnnotations,
    historyFromCase,
    timelineObservationsQuery,
    isOrganizationContext,
    isRegularUserContext,
    selectedDate,
    isOwnerOfTimeline,
    returnPredefinedText,
  ]);

  const expandEventItem = (id: string) => {
    if (eventItemExpanded === id) {
      setEventItemExpanded(null);
      return;
    }

    setEventItemExpanded(id);
  };

  const renderedDetails = detailArray.map((props) => {
    return (
      <GraspEventItem
        expanded={props.id === eventItemExpanded}
        expandEventItem={expandEventItem}
        selectedDates={selectedDates}
        data={props}
        timelineId={timelineId}
        key={props.id}
      />
    );
  });

  return (
    <>
      <div className="DayDetails__details-wrapper">
        {renderedDetails.length ? (
          renderedDetails
        ) : (
          <EmptyInformation type="day" />
        )}
      </div>
      <AnnotationModal
        annotationId={annotationIdToEdit}
        timelineId={timelineId}
        isOpen={isEditAnnotationModalOpen}
        closeModal={closeEditAnnotation}
      />
      {caseId && organizationId && (
        <CaseHistoryModal
          isOpen={isHistoryUpdateOpen}
          toggleModal={closeUpdateModal}
          historyData={historyDataToUpdate}
          caseId={caseId}
          organizationId={organizationId}
          hidePredefinedMessages={true}
          hideOwnerSelect={true}
        />
      )}
      {selectedDates && (
        <ObservationModal
          timelineId={timelineId}
          isOpen={isObservationModalOpen}
          closeModal={closeObservation}
          refetchDates={selectedDates}
          observationToEdit={observationToEdit}
        />
      )}
    </>
  );
};

export default TimelineObservationList;
