import React from 'react';
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { Field, Formik } from 'formik';
import { faUsers } from '@fortawesome/pro-solid-svg-icons';
import { iconColors } from '../../../../../helpers/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FieldMultiSelect from '../../../../../components/FieldMultiSelect';
import { UserRoles } from '../../../../../interfaces/membership';
import FieldInput from '../../../../../components/FieldInput';
import LoadingButton from '../../../../../components/LoadingButton';
import { useCreateAccessProfile } from '../../../../../api/mutations/accessProfiles/useCreateAccessProfile';
import { validateEmpty } from '../../../../../helpers/utils/validators';

type AddAccessProfileModalProps = {
  isOpen: boolean;
  toggle: () => void;
  organizationId: string;
  refetch: () => void;
};

type AddAccessProfileInitialValues = {
  name: string;
  roles: UserRoles[];
};

const initialValues: AddAccessProfileInitialValues = {
  name: '',
  roles: [],
};

export const roleTypesOptions = Object.entries(UserRoles).map(([key, _]) => ({
  label: key.toLowerCase(),
  value: key.toLowerCase(),
}));

const AddAccessProfileModal = ({
  isOpen,
  toggle,
  organizationId,
  refetch,
}: AddAccessProfileModalProps) => {
  const intl = useIntl();

  const { mutateAsync, isLoading } = useCreateAccessProfile(
    {
      organizationId: organizationId,
    },
    {
      successFb: refetch,
    }
  );

  const onSubmit = async (values: AddAccessProfileInitialValues) => {
    await mutateAsync(values);
    toggle();
  };

  const validation = (values: typeof initialValues) => {
    let errors = validateEmpty<typeof initialValues>(values, intl);

    if (values.roles.length === 0) {
      errors[values.roles.toString()] = intl.formatMessage({
        id: 'CaseCard.create.errors.inputEmpty',
      });
    }

    return errors;
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="CreateEntryModal"
      size="lg"
    >
      <ModalHeader toggle={toggle}>
        <div className="d-flex align-items-center gap-3">
          <FontAwesomeIcon icon={faUsers} color={iconColors.normal} />
          <FormattedMessage id="MyOrganizations.accessProfiles.addAccessProfileMessage" />
        </div>
      </ModalHeader>
      <ModalBody>
        <Formik
          initialValues={initialValues}
          validate={validation}
          validateOnMount={true}
          onSubmit={onSubmit}
        >
          {({ submitForm, isValid }) => (
            <>
              <ModalBody>
                <div className="mb-3">
                  <Label check htmlFor={'name'}>
                    <FormattedMessage
                      id={'MyOrganizations.accessProfiles.profileNameLabel'}
                    />
                  </Label>
                  <Field
                    id="name"
                    name="name"
                    type="text"
                    required={true}
                    placeholder={intl.formatMessage({
                      id: 'MyOrganizations.accessProfiles.enterTheNameOfAccessProfile',
                    })}
                    component={FieldInput}
                  />
                </div>
                <div>
                  <Label check htmlFor={'roles'}>
                    <FormattedMessage
                      id={
                        'MyOrganizations.accessProfiles.profileRolesSelectLabel'
                      }
                    />
                  </Label>
                  <Field
                    id="roles"
                    name="roles"
                    isMulti={true}
                    options={roleTypesOptions}
                    placeholder={intl.formatMessage({
                      id: 'MyOrganizations.accessProfiles.chooseOneOfTheAccessProfileRoles',
                    })}
                    component={FieldMultiSelect}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <LoadingButton
                  disabled={!isValid}
                  type="submit"
                  onClick={submitForm}
                  isLoading={isLoading}
                >
                  <FormattedMessage id="General.confirm" />
                </LoadingButton>
                <Button outline={true} color="primary" onClick={toggle}>
                  <FormattedMessage id="General.close" />
                </Button>
              </ModalFooter>
            </>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default AddAccessProfileModal;
