import { Breadcrumb, BreadcrumbItem, Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';

//interfaces
import { AnnotationRedirectionState } from 'interfaces/timeline';
import { AssessmentFormRedirectionState } from 'interfaces/assessmentForms';

//helpers
import useLocationState from 'hooks/useLocationState';
import useRequiredParams from 'hooks/useRequiredParams';

//components
import TimelineDetailsHeader from './components/TimelineDetailsHeader';
import TimelineTabs from './components/TimelineTabs';

//queries
import useTimelineDetails from 'api/queries/timelines/useTimelineDetails';
import { useBrowserState } from 'store/reducerHooks';

type TimelineDetailsLocationState = {
  assessmentForm: AssessmentFormRedirectionState;
  annotation: AnnotationRedirectionState;
};

const TimelineDetails = () => {
  const intl = useIntl();
  const locationState = useLocationState<TimelineDetailsLocationState>();

  const { timelineId } = useRequiredParams<{
    timelineId: string;
    caseId: string;
    organizationId: string;
  }>(['timelineId']);

  const { data: timelineDetails, isLoading: isLoadingTimelineDetails } =
    useTimelineDetails({ params: { timelineId } });

  const renderBreadcrumbs = (name: string) => {
    const nameBreadcrumb = {
      text: `${name}`,
      link: null,
    };

    let breadcrumbs = [
      {
        text: intl.formatMessage({ id: 'Summary.ScreenTitle' }),
        link: `/dashboard`,
      },
      nameBreadcrumb,
    ];

    return breadcrumbs.map((item) => {
      if (item.link) {
        return (
          <BreadcrumbItem key={item.text}>
            <Link to={item.link}>
              <FormattedMessage id={item.text} defaultMessage={item.text} />
            </Link>
          </BreadcrumbItem>
        );
      }

      return (
        <BreadcrumbItem key={item.text} active>
          <FormattedMessage id={item.text} defaultMessage={item.text} />
        </BreadcrumbItem>
      );
    });
  };

  const isBrowser = useBrowserState();

  return (
    <>
      {isBrowser && (
        <Row>
          <Col>
            <Breadcrumb listClassName="bg-white ps-0 mb-0">
              {timelineDetails?.name && renderBreadcrumbs(timelineDetails.name)}
            </Breadcrumb>
          </Col>
        </Row>
      )}
      <TimelineDetailsHeader
        timelineDetails={timelineDetails}
        isLoading={isLoadingTimelineDetails}
        timelineId={timelineId}
      />
      <TimelineTabs
        timelineId={timelineId}
        formRedirectionState={locationState?.assessmentForm}
        annotationRedirectionState={locationState?.annotation}
      />
    </>
  );
};

export default TimelineDetails;
