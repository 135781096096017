import { MouseEvent, useCallback } from 'react';
import { useNavigate } from 'react-router';
import { useOrganizationPathParams } from '../../../../../../../../../../../../../hooks/paths/useOrganizationPathParams';
import { TableViewHelpers } from '../helpers';

export const useHandleRowClick = (onClick?: string) => {
  const { organizationId } = useOrganizationPathParams();
  const navigate = useNavigate();

  return useCallback(
    (id: string) => (_: MouseEvent<HTMLTableRowElement>) => {
      if (!onClick) return;

      TableViewHelpers.GetColumnClickEvent(
        onClick,
        organizationId,
        id,
        navigate
      );
    },
    [navigate, onClick, organizationId]
  );
};
