import React from 'react';
import { Spinner } from 'reactstrap';

type FullScreenSpinnerProps = {
  color?: string;
};

const FullScreenSpinner = ({ color = 'primary' }: FullScreenSpinnerProps) => {
  return (
    <div className="d-flex align-items-center justify-content-center vw-100 vh-100 position-absolute top-0 start-0">
      <Spinner color={color} />
    </div>
  );
};

export default FullScreenSpinner;
