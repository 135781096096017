import {
  IObservation,
  ISqueezeChartData,
} from '../../../../../interfaces/timeline';
import { useMemo } from 'react';
import { HourByHourMode } from '../../../index';
import { IBaseExerciseSession } from 'interfaces/exerciseSessions';
import { cloneDeep } from 'lodash';
import { addHours } from 'date-fns';
import { groupByProperty } from '../../../../../helpers/utils/groupByProperty';
import {
  filterExerciseObservations,
  getExercisesPeriods,
} from 'helpers/exercises';

const getHoursIntervalBasedOnDate = (date: Date) => {
  let startDate: Date = cloneDeep(date);
  startDate.setHours(0, 0, 0, 0);

  let endDate: Date = cloneDeep(date);
  endDate.setHours(23, 59, 59, 0);

  let hours: string[] = [];

  while (startDate <= endDate) {
    hours.push(startDate.toISOString());
    startDate = addHours(startDate, 1);
  }

  return hours;
};

export const useObservationsBasedOnMode = (
  observations: ISqueezeChartData | undefined,
  periodObservations: IObservation[],
  exercises: IBaseExerciseSession[],
  mode: HourByHourMode,
  date: Date
) => {
  return useMemo((): ISqueezeChartData | undefined => {
    if (mode === HourByHourMode.AllIncludingExercises) return observations;

    let observationsBuiltOnTimeRange: ISqueezeChartData = {
      series: (observations?.series ?? []).map((series) => ({
        ...series,
        data: [...new Array(24).fill(0)],
      })),
      x_axis: getHoursIntervalBasedOnDate(date),
      x_axis_label: '',
      y_axis_label: '',
    };

    let exercisesPeriods = getExercisesPeriods(exercises);
    let filteredObservations = filterExerciseObservations(
      exercisesPeriods,
      periodObservations
    );

    filteredObservations = filteredObservations.map((observation) => {
      let timestamp = new Date(observation.timestamp);
      timestamp.setHours(timestamp.getHours(), 0, 0, 0);

      return {
        ...observation,
        timestamp: timestamp.toISOString(),
      };
    });

    const groupByTimestamp = groupByProperty('timestamp');

    const timestampGrouped = groupByTimestamp(filteredObservations) as Record<
      string,
      IObservation[]
    >;

    Object.entries(timestampGrouped).forEach(([key, val]) => {
      const hourIndex = observationsBuiltOnTimeRange.x_axis.findIndex(
        (p) => p === key
      );

      if (hourIndex === -1) return;

      val.forEach((observation) => {
        const meaningSeries = observationsBuiltOnTimeRange.series.findIndex(
          (p) => p.meaning_name_en === observation.meaning_name
        );

        if (meaningSeries === -1) return;
        observationsBuiltOnTimeRange.series[meaningSeries].data[hourIndex]++;
      });
    });

    return observationsBuiltOnTimeRange;
  }, [date, exercises, mode, observations, periodObservations]);
};
