import { DropEvent, DropzoneOptions, FileRejection } from 'react-dropzone';

export const DEFAULT_DROP_FILE_OPTIONS: DropzoneOptions = {
  autoFocus: true,
  maxFiles: 1,
  multiple: false,
};

export const DEFAULT_DROP_FILE_TEXT_TRANSLATION = 'DropFile.dragAndDrop';
export const DEFAULT_UPLOADING_DROP_FILE_TEXT_TRANSLATION =
  'DropFile.uploading';
export const DEFAULT_UPLOADED_DROP_FILE_TEXT_TRANSLATION = 'DropFile.uploaded';
export const DEFAULT_REJECTED_DROP_FILE_TEXT_TRANSLATION = 'DropFile.rejected';

export type DropFileAcceptCallback = (
  files: File[],
  event: DropEvent
) => Promise<void>;

export type DropFileRejectCallback = (
  fileRejections: FileRejection[],
  event: DropEvent
) => Promise<void>;

export type DropFileCallback = (
  accepted: File[],
  rejections: FileRejection[],
  event: DropEvent
) => Promise<void>;
