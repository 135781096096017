import React, { useMemo } from 'react';
import { ITabContainerItem } from 'components/Tabs/types/Tabs';
import { Tab } from '@mui/material';
import { wrapWithSequenceIdentified } from 'helpers/utils/data';

export const useTabHeaders = (defaultSelectedItems: ITabContainerItem[]) => {
  return useMemo(() => {
    return wrapWithSequenceIdentified(defaultSelectedItems).map(
      ({ header, sequence }) => <Tab key={sequence} label={header} />
    );
  }, [defaultSelectedItems]);
};
