import { modifyMemberRequest } from 'api/patients';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { ModifyCaseMemberRequestParams } from './useModifyCaseMemberRequest';

function useModifyMemberRequest(
  organizationId: string,
  successFb?: () => void
) {
  return useMutationWithResultNotification({
    asyncApiFunction: async ({
      action,
      requestId,
    }: ModifyCaseMemberRequestParams) => {
      await modifyMemberRequest(organizationId, requestId, action);
    },
    resetOnResult: true,
    onSuccess: () => {
      successFb && successFb();
      displaySuccessNotification('PatientAccess.statusSuccess');
    },
    onError: () => {
      displayErrorNotification('PatientAccess.statusError');
    },
  });
}

export default useModifyMemberRequest;
