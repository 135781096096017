import React, { useState } from 'react';
import { Button, Tooltip } from 'reactstrap';
import { FormattedMessage } from 'react-intl';
import classNames from 'classnames';

//icons
import SVG from 'react-inlinesvg';
import deleteIcon from 'assets/images/delete_icon.svg';
import editIcon from 'assets/images/edit_icon_transparent.svg';
import hideIcon from 'assets/images/hide_icon.svg';

type CaseTimelineHeaderProps = {
  headerClass: string;
  isDisabled?: boolean;
  onEditClick: () => void;
  onDeleteClick: () => void;
  canEdit: boolean;
  isEdited: boolean;
  onHideClick?: () => void;
  isHidden?: boolean;
  children: JSX.Element;
};

const CaseTimelineHeader = ({
  headerClass,
  canEdit,
  isDisabled = false,
  onDeleteClick,
  onEditClick,
  isEdited,
  children,
  onHideClick,
  isHidden = false,
}: CaseTimelineHeaderProps) => {
  const deleteButton = (
    <Button
      disabled={isDisabled}
      onClick={onDeleteClick}
      className="delete-button"
      aria-label="delete button"
    >
      <SVG src={deleteIcon} />
    </Button>
  );

  const onEditClickHandler = () => {
    if (isHidden) {
      onHideClick && onHideClick();
    }
    onEditClick();
  };

  let editButton: JSX.Element | null = null;
  if (!isEdited) {
    editButton = (
      <Button
        disabled={isDisabled}
        onClick={onEditClickHandler}
        className="me-2 edit-button"
        aria-label="edit button"
      >
        <SVG src={editIcon} />
      </Button>
    );
  }

  const [tooltipDeleteOpen, setDeleteTooltipOpen] = useState(false);
  const toggleDeleteTooltip = () => setDeleteTooltipOpen((value) => !value);

  const deleteTooltip = (
    <>
      <Tooltip
        target={'deleteButton'}
        isOpen={tooltipDeleteOpen}
        toggle={toggleDeleteTooltip}
      >
        <FormattedMessage id="TimelineDetails.canNotRemoveMaster" />
      </Tooltip>
      <span id="deleteButton">{deleteButton}</span>
    </>
  );

  const [tooltipEditOpen, setEditTooltipOpen] = useState(false);
  const toggleEditTooltip = () => setEditTooltipOpen((value) => !value);
  const editTooltip = (
    <>
      <Tooltip
        target={'editButton'}
        isOpen={tooltipEditOpen}
        toggle={toggleEditTooltip}
      >
        <FormattedMessage id="TimelineDetails.canNotEditMaster" />
      </Tooltip>
      <span id="editButton">{editButton}</span>
    </>
  );

  let modificationButtons: JSX.Element | null = null;
  if (canEdit) {
    if (isDisabled) {
      modificationButtons = (
        <>
          {editTooltip}
          {deleteTooltip}
        </>
      );
    } else {
      modificationButtons = (
        <>
          {editButton}
          {deleteButton}
        </>
      );
    }
  }

  return (
    <div className={classNames('CaseTimelineHeader', headerClass)}>
      <div className="CaseTimelineHeader__modification-buttons">
        {modificationButtons}
      </div>
      {onHideClick && (
        <Button
          aria-label="hide"
          className={classNames('CaseTimelineHeader__hide-button', {
            'CaseTimelineHeader__hide-button--clicked': isHidden,
          })}
          onClick={onHideClick}
        >
          <img src={hideIcon} alt={hideIcon} />
        </Button>
      )}
      <div
        className={classNames('CaseTimelineHeader__content', {
          'CaseTimelineHeader__content--hidden': isHidden,
        })}
      >
        {children}
      </div>
    </div>
  );
};

export default CaseTimelineHeader;
