import React, { useEffect, useState } from 'react';
import { DateRange, DayModifiers, DayPicker, Matcher } from 'react-day-picker';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Alert,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { formatDateToInputDateString } from 'helpers/dates';
import { TimeRange } from 'interfaces/timeline';
import EditableInput from './EditableInput/EditableInput';
import calendarIcon from '../assets/images/calendar_icon.svg';
import { eachDayOfInterval } from 'date-fns';

//images
import { returnNextRange } from 'helpers/utils/dates';
import CustomOneColumnDatePicker from './CustomOneColumnDatePicker';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/pro-light-svg-icons';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

const defaultValueForRange = {
  from: undefined,
  to: undefined,
};

const formatSingleDate = (date: Date, timeIncluded: boolean = true) => {
  if (timeIncluded) {
    return `${date.getDate().toString().padStart(2, '0')}.${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, '0')}.${date.getFullYear()}, ${date
      .getHours()
      .toString()
      .padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  }

  return `${date.getDate().toString().padStart(2, '0')}.${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}.${date.getFullYear()}`;
};

const formatRange = (range: DateRange | null, timeIncluded: boolean) => {
  const { from, to } = range ?? {};

  let dateString = '';
  if (from) dateString += formatSingleDate(from, timeIncluded);
  if (to && from && from.getTime() !== to.getTime())
    dateString += ` - ${formatSingleDate(to, timeIncluded)}`;

  return dateString;
};

type PredefinedDatePickerProps = {
  onPredefineValueClick?: (value: string) => void;
  selectedTimeRange?: TimeRange | null;
  selectedRange: DateRange | null;
  onDateAccept: (date: DateRange) => void;
  displayPredefinedTimeRange?: boolean;
  singleDate?: boolean;
  hideTime?: boolean;
  hideSummary?: boolean;
  disableDate?: boolean;
  oneColumn?: boolean;
  defaultLastActive?: boolean;
  onActiveModeChange?: () => void;
  hideLastActive?: boolean;
  inputIcon?: boolean;
};

const CustomDatePicker = ({
  onPredefineValueClick,
  selectedTimeRange,
  selectedRange,
  onDateAccept,
  onActiveModeChange,
  displayPredefinedTimeRange = true,
  singleDate = false,
  hideTime = false,
  hideSummary = false,
  disableDate = false,
  oneColumn = false,
  defaultLastActive = false,
  hideLastActive = false,
  inputIcon = true,
}: PredefinedDatePickerProps) => {
  const intl = useIntl();
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => setDropdownOpen((val) => !val);

  const [range, setRange] = useState<DateRange>(defaultValueForRange);

  const [lastActiveDays, setLastActiveDays] =
    useState<boolean>(defaultLastActive);

  const predefinedTimeRanges = Object.values(TimeRange)
    .filter((p) => p !== TimeRange.CASE_PERIOD)
    .map((value) => {
      return {
        value,
        text: `${intl.formatMessage({
          id: 'UserDashboard.SqueezeSummary.last',
        })} ${intl.formatMessage({
          id: `UserDashboard.SqueezeSummary.timeRange.${value}`,
        })}`,
        activeText: `${intl.formatMessage({
          id: 'UserDashboard.SqueezeSummary.lastActive',
        })} ${intl.formatMessage({
          id: `UserDashboard.SqueezeSummary.timeRange.${value}`,
        })}`,
      };
    });

  const onAccept = () => {
    const acceptedRange =
      range.from && range.to ? range : { from: range.from, to: range.from };

    onDateAccept(acceptedRange);
    toggleDropdown();
  };

  const onCancel = () => {
    if (selectedRange) setRange(selectedRange);
    else setRange(defaultValueForRange);

    toggleDropdown();
  };

  const onDayClick = (pickedDate: Date) => {
    if (disableDate) return;

    if (singleDate) {
      const singleDate = new Date(pickedDate);
      singleDate.setHours(0, 0, 0, 0);

      setRange({
        from: singleDate,
        to: singleDate,
      });
    } else {
      setRange(returnNextRange(pickedDate, range));
    }
  };

  const { from, to } = range;

  const modifiers: DayModifiers = {
    start: from as Date,
    end: to as Date,
  };

  const selectedDays: Matcher = {
    from: from,
    to: to,
  };

  const onPredefineSelect = (val: string) => {
    if (onPredefineValueClick !== undefined) onPredefineValueClick(val);
    toggleDropdown();
  };

  const onDateEdit = (target: string, val: string) => {
    const newDateValue = new Date(val);

    setRange((range) => ({
      ...range,
      [target]: newDateValue,
    }));
  };

  const onTimeEdit = (target: string, val: string) => {
    const newDateValue = new Date(range.from as Date);
    const time = val.split(':');
    newDateValue.setHours(parseInt(time[0]), parseInt(time[1]));

    if (singleDate) {
      setRange({
        from: newDateValue,
        to: newDateValue,
      });
    } else {
      const key = target === 'time_start' ? 'from' : 'to';

      setRange((range) => ({
        ...range,
        [key]: newDateValue,
      }));
    }
  };

  const startDateInput = from ? formatDateToInputDateString(from) : undefined;

  const startTimeInput = from
    ? `${from.getHours().toString().padStart(2, '0')}:${from
        .getMinutes()
        .toString()
        .padStart(2, '0')}`
    : undefined;

  const toDateInput = to ? formatDateToInputDateString(to) : undefined;
  const toTimeInput = to
    ? `${to.getHours().toString().padStart(2, '0')}:${to
        .getMinutes()
        .toString()
        .padStart(2, '0')}`
    : undefined;

  useEffect(() => {
    if (selectedRange) setRange(selectedRange);
  }, [selectedRange]);

  const displayedValue = formatRange(selectedRange, false);

  const validation = (dateString: string) => {
    const dateObject = new Date(dateString);

    return (
      !isNaN(dateObject.getTime()) &&
      dateObject.getFullYear().toString().length <= 4
    );
  };

  let calendarWrapperClasses = oneColumn
    ? 'TimeFilterDropdown__calendar TimeFilterDropdown__calendar--one-column'
    : 'TimeFilterDropdown__calendar';

  let inputContainerClasses = oneColumn
    ? 'TimeFilterDropdown__date-input-container TimeFilterDropdown__date-input-container--one-column'
    : 'TimeFilterDropdown__date-input-container';

  const numberOfMonths = singleDate ? 1 : 2;

  if (singleDate) {
    calendarWrapperClasses = 'TimeFilterDropdown__calendar--single-date';
    inputContainerClasses +=
      ' TimeFilterDropdown__date-input-container--single-date';
  }

  const isRangeCorrect =
    range?.from && range?.to && range?.from.getTime() <= range?.to.getTime();

  let disabledDays: Date[] = [];
  if (disableDate && range.from) {
    const start = new Date(range.from);
    start.setDate(1);

    const end = new Date(range.from);
    end.setMonth(end.getMonth() + 1);
    end.setDate(0);

    disabledDays = eachDayOfInterval({
      start,
      end,
    });
  }

  const renderPredefinedRanges = predefinedTimeRanges.map(
    ({ text, value, activeText }) => {
      const isSelected = selectedTimeRange === value;

      return (
        <div
          key={value}
          onClick={() => onPredefineSelect(value)}
          className={`TimeFilterDropdown__predefined__item ${
            isSelected ? 'TimeFilterDropdown__predefined__selected' : ''
          }`}
        >
          {lastActiveDays ? activeText : text}
        </div>
      );
    }
  );

  const toggleLastActive = () => {
    setLastActiveDays((prev) => !prev);

    if (onActiveModeChange !== undefined) onActiveModeChange();
  };

  return (
    <Dropdown
      isOpen={isDropdownOpen}
      toggle={toggleDropdown}
      className="TimeFilterDropdown"
    >
      <DropdownToggle data-toggle="dropdown" tag="span">
        <InputGroup className="DateValueGroup">
          {inputIcon && (
            <InputGroupText>
              <img src={calendarIcon} alt="calendar_icon" />
            </InputGroupText>
          )}
          <Input
            disabled
            value={displayedValue}
            className="DateValueGroup__displayed-value"
          />
          <InputGroupText>
            <FontAwesomeIcon icon={faCaretDown} />
          </InputGroupText>
        </InputGroup>
      </DropdownToggle>
      <DropdownMenu
        className={classNames({
          TimeFilterDropdown__menu: true,
          'TimeFilterDropdown__menu--one-column': oneColumn,
          'TimeFilterDropdown__menu--single-date': singleDate,
          'TimeFilterDropdown__menu--without-predefined':
            !singleDate && !displayPredefinedTimeRange,
        })}
      >
        {oneColumn ? (
          <CustomOneColumnDatePicker
            calendarWrapperClasses={calendarWrapperClasses}
            inputContainerClasses={inputContainerClasses}
            validation={validation}
            startDateInput={startDateInput}
            startTimeInput={startTimeInput}
            disableDate={disableDate}
            hideTime={hideTime}
            toDateInput={toDateInput}
            toTimeInput={toTimeInput}
            onDateEdit={onDateEdit}
            onTimeEdit={onTimeEdit}
            onDayClick={onDayClick}
            modifiers={modifiers}
            selectedDays={selectedDays}
            displayPredefinedTimeRange={displayPredefinedTimeRange}
            hideLastActive={hideLastActive}
            disabledDays={disabledDays}
            isRangeCorrect={isRangeCorrect}
            onCancel={onCancel}
            onAccept={onAccept}
            lastActiveDays={lastActiveDays}
            toggleLastActive={toggleLastActive}
            renderPredefinedRanges={renderPredefinedRanges}
            oneColumn={oneColumn}
          />
        ) : (
          <div className="TimeFilterDropdown__container">
            <div className={calendarWrapperClasses}>
              {!hideSummary && (
                <div className="w-100 d-flex">
                  <div className={inputContainerClasses}>
                    <h4>Start date</h4>
                    <div className="TimeFilterDropdown__date-input-container__inputs">
                      <EditableInput
                        validation={validation}
                        withoutRefetch={true}
                        value={startDateInput}
                        disabled={disableDate}
                        name="from"
                        onEdit={onDateEdit}
                        debounceTime={2000}
                        type="date"
                        className="TimeFilterDropdown__date-input-container__inputs__date"
                      />
                      {!hideTime && (
                        <EditableInput
                          withoutRefetch={true}
                          value={startTimeInput}
                          onEdit={onTimeEdit}
                          name="time_start"
                          type="time"
                          className="TimeFilterDropdown__date-input-container__inputs__time"
                        />
                      )}
                    </div>
                  </div>
                  {!singleDate && (
                    <div className="TimeFilterDropdown__date-input-container">
                      <h4>End date</h4>
                      <div className="TimeFilterDropdown__date-input-container__inputs">
                        <EditableInput
                          validation={validation}
                          withoutRefetch={true}
                          value={toDateInput}
                          disabled={disableDate}
                          name="to"
                          onEdit={onDateEdit}
                          debounceTime={2000}
                          type="date"
                          className="TimeFilterDropdown__date-input-container__inputs__date"
                        />
                        {!hideTime && (
                          <EditableInput
                            withoutRefetch={true}
                            value={toTimeInput}
                            onEdit={onTimeEdit}
                            name="time_end"
                            type="time"
                            className="TimeFilterDropdown__date-input-container__inputs__time"
                          />
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
              <DayPicker
                className="Selectable"
                numberOfMonths={numberOfMonths}
                selected={selectedDays}
                modifiers={modifiers}
                onDayClick={onDayClick}
                disableNavigation={false}
                disabled={disabledDays}
              />
              {!isRangeCorrect && (
                <Alert className="m-4 text-center" color="danger">
                  <FormattedMessage id="General.wrongDate" />
                </Alert>
              )}
              <div className="w-100 pt-4 pb-2 d-flex justify-content-end gap-2">
                <Button color="primary" outline={true} onClick={onCancel}>
                  <FontAwesomeIcon icon={faTimes} />
                </Button>
                <Button
                  color="primary"
                  disabled={!isRangeCorrect}
                  onClick={onAccept}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </Button>
              </div>
            </div>
            {displayPredefinedTimeRange && (
              <div className="TimeFilterDropdown__predefined">
                <div className="TimeFilterDropdown__predefined__items-wrapper">
                  {renderPredefinedRanges}
                </div>
              </div>
            )}
          </div>
        )}
      </DropdownMenu>
    </Dropdown>
  );
};

export default CustomDatePicker;
