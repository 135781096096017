import { useContext, useMemo } from 'react';
import { TemplateItemType } from '../../../../../interfaces/change';
import { getQuestionsFigureItem } from '../components/TrendsChartFigureItem/helpers/getQuestionsFigureItem';
import {
  ChartConfigContext,
  ChartDataContext,
  ChartVisualizationContext,
  QuestionBasedTrendsChartElement,
  TrendsChartConfig,
  TrendsChartData,
} from '../../../types';
import { getNonQuestionsFigureItem } from '../components/TrendsChartFigureItem/helpers/getNonQuestionsFigureItem';
import { TrendsChartVisualizationHookValues } from '../../../hooks/useTrendsChartVisualization';
import { SelectItemsHookValues } from './useSelectItems';
import useMobile from '../../../../../hooks/useBreakpoint';

export const useRenderItems = (selection: SelectItemsHookValues) => {
  const { selected } = useContext(
    ChartVisualizationContext
  ) as TrendsChartVisualizationHookValues;

  const config = useContext(ChartConfigContext) as TrendsChartConfig;

  const mobile = useMobile();

  const selectedData = useContext(ChartDataContext) as TrendsChartData<
    string | number
  >;

  return useMemo(() => {
    if (selected.type === TemplateItemType.Questions)
      return getQuestionsFigureItem(
        selected as QuestionBasedTrendsChartElement,
        config,
        selection.isSelected,
        mobile
      );

    return getNonQuestionsFigureItem(
      selectedData,
      selected,
      config,
      selection.isSelected
    );
  }, [config, mobile, selected, selectedData, selection.isSelected]);
};
