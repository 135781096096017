import { useCallback } from 'react';
import { useTypedContext } from '../../../../../../../../../../../../../../../../../../../../../hooks/useTypedContext';
import { ReactStateTuple } from '../../../../../../../../../../../../../../../../../../../../../interfaces/ui';
import { ValidationResult } from '../../../../../../../../../../../../../types';
import { ValidationContext } from '../../../../../../../../../types';
import { cloneDeep } from 'lodash';
import { ValidConversationTemplateItemType } from '../../../../../../../../../../../../../../../../../../../../../interfaces/conversations';

export const useHandleApply = (id: string, onResolve: () => void) => {
  const [validation, setValidation] =
    useTypedContext<ReactStateTuple<ValidationResult>>(ValidationContext);

  return useCallback(
    (value: string) => {
      const validationCopy = cloneDeep(validation);

      validationCopy.template.content = validationCopy.template.content.map(
        (item) => {
          if (
            item.type !== ValidConversationTemplateItemType.Education ||
            item.resource_id !== id
          )
            return item;

          return {
            ...item,
            resource_id: value ?? '',
          };
        }
      );

      validationCopy.ready_to_complete = false;
      setValidation(validationCopy);
      onResolve();
    },
    [id, onResolve, setValidation, validation]
  );
};
