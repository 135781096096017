import React from 'react';
import SVG from 'react-inlinesvg';
import phoneHeaderAvatar from '../../../../../../../../assets/images/conversation_templates/phone_header_avatar.svg';
import { FormattedMessage } from 'react-intl';
import usersIcon from '../../../../../../../../assets/images/conversation_templates/phone_user_icon.svg';
import graspIcon from '../../../../../../../../assets/images/conversation_templates/phone_grasp_icon.svg';
import settingsIcon from '../../../../../../../../assets/images/conversation_templates/phone_settings_icon.svg';
import { useTranslations } from '../../../../../../../../hooks/useTranslations';

const Header = () => {
  const { wrapWithIntl, attachPrefix } = useTranslations(
    'ConversationsAndFeedbacks.ConversationTemplates.ConversationTemplatePhone.Header'
  );

  return (
    <>
      <div className="ConversationTemplatePhoneView__header__user">
        <div className="d-flex align-items-center gap-1">
          <div className="ConversationTemplatePhoneView__header__user__icon">
            <SVG
              src={phoneHeaderAvatar}
              title={wrapWithIntl(attachPrefix('avatar'))}
            />
          </div>
          <div className="ConversationTemplatePhoneView__header__user__info">
            <p className="ConversationTemplatePhoneView__header__user__info__header">
              <FormattedMessage id={attachPrefix('hi')} />
            </p>
            <p className="ConversationTemplatePhoneView__header__user__info__details">
              <FormattedMessage id={attachPrefix('details')} />
            </p>
          </div>
        </div>
      </div>
      <div className="ConversationTemplatePhoneView__header__options">
        <div>
          <SVG src={usersIcon} title={wrapWithIntl(attachPrefix('users'))} />
        </div>
        <div>
          <SVG src={graspIcon} title={wrapWithIntl(attachPrefix('grasp'))} />
        </div>
        <div>
          <SVG
            src={settingsIcon}
            title={wrapWithIntl(attachPrefix('settings'))}
          />
        </div>
      </div>
    </>
  );
};

export default Header;
