import React, { useMemo } from 'react';
import QueryLoaded from '../../../../../../../../components/QueryLoaded';
import FullScreenSpinner from '../../../../../../../../components/Spinners/FullScreenSpinner';
import { IAwareSessionData } from '../../../../../../../../interfaces/awareSessions';
import { useBrowserState } from '../../../../../../../../store/reducerHooks';
import { CaseDetailsProps } from '../../types';
import Duration from '../Duration';
import IntensityTimeline from '../IntensityTimeline';
import SessionInfo from '../SessionInfo';
import Squeezes from '../Squeezes';
import Summary from '../Summary';
import { useObservations } from '../../providers/ObservationsProvider/hooks/useObservations';
import {
  AwareCaseContentDataContext,
  AwareCaseObservationsProps,
} from './types';

type AwareCaseContentProps = CaseDetailsProps & {
  awareSession: IAwareSessionData;
};

const AwareCaseContent = ({ details, awareSession }: AwareCaseContentProps) => {
  const isBrowser = useBrowserState();
  const observations = useObservations(details, awareSession);

  const contextData = useMemo(() => {
    return { details, awareSession };
  }, [awareSession, details]);

  return (
    <AwareCaseContentDataContext.Provider value={contextData}>
      <QueryLoaded query={observations} onLoading={<FullScreenSpinner />}>
        {(data) =>
          isBrowser ? (
            <AwareCaseContentDesktop observations={data} />
          ) : (
            <AwareCaseContentMobile observations={data} />
          )
        }
      </QueryLoaded>
    </AwareCaseContentDataContext.Provider>
  );
};

const AwareCaseContentDesktop = ({
  observations,
}: AwareCaseObservationsProps) => {
  return (
    <div className="AwareCaseContent AwareCaseContent--desktop">
      <Summary />
      <Squeezes />
      <Duration observations={observations} />
      <SessionInfo />
      <IntensityTimeline observations={observations} />
    </div>
  );
};

const AwareCaseContentMobile = ({
  observations,
}: AwareCaseObservationsProps) => {
  return (
    <div className="AwareCaseContent AwareCaseContent--mobile mb-4">
      <Summary />
      <IntensityTimeline observations={observations} />
      <Squeezes />
      <Duration observations={observations} />
      <SessionInfo />
    </div>
  );
};

export default AwareCaseContent;
