//helpers
import useOrganizationContextAddFormAssignment from 'api/mutations/assessmentForms/assignments/useOrganizationContextAddFormAssignment';
import useOrganizationContextUpdateFormAssignment from 'api/mutations/assessmentForms/assignments/useOrganizationContextUpdateFormAssignment';

//types
import {
  ScheduleInfo,
  ScheduleInfoWithCustomOrdered,
} from 'interfaces/assessmentForms';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import CustomSelectionDetails from './components/CustomSelectionDetails';
import DailySelectionDetails from './components/DailySelectionDetails';
import DateSelector from './components/DateSelector';

//components
import WeeklySelectionDetails from './components/WeeklySelectionDetails';

export type SelectionDetailsProps = {
  info: ScheduleInfoWithCustomOrdered;
  setInfo: (info: ScheduleInfoWithCustomOrdered) => void;
};

const INIT_HOURS = 0;

type FormsAssignmentsModalProps = {
  isOpen: boolean;
  toggleModal: () => void;
  userId: string;
  caseId: string;
  organizationId: string;
  timelineId: string;
  assignmentId?: string;
  formCode: string;
  scheduleInfo?: ScheduleInfo;
  dateTo: Date | null;
  dateFrom: Date | null;
  refetch: () => void;
  isEditableByOrganizationMembersInit?: boolean;
  activeHoursBefore: number | null;
  activeHoursAfter: number | null;
};

const FormsAssignmentsModal = ({
  isOpen,
  toggleModal,
  userId,
  caseId,
  organizationId,
  timelineId,
  assignmentId,
  formCode,
  scheduleInfo,
  dateTo,
  dateFrom,
  refetch,
  isEditableByOrganizationMembersInit = false,
  activeHoursAfter: activeHoursAfterProps,
  activeHoursBefore: activeHoursBeforeProps,
}: FormsAssignmentsModalProps) => {
  const getOrderedScheduleInfo = (info: ScheduleInfo | undefined) => {
    if (!info) {
      return undefined;
    }

    return {
      daily: info.daily,
      weekly: info.weekly,
      custom:
        info.custom !== null
          ? info.custom.map((value, idx) => ({ ...value, id: idx }))
          : null,
    };
  };

  const [selectedScheduleInfo, setSelectedScheduleInfo] = useState<
    ScheduleInfoWithCustomOrdered | undefined
  >(() => getOrderedScheduleInfo(scheduleInfo));

  const [isEditableByOrganizationMembers, setIsEditableByOrganizationMembers] =
    useState<boolean>(isEditableByOrganizationMembersInit);

  const [activeHoursBefore, setActiveHoursBefore] = useState<null | number>(
    activeHoursBeforeProps || INIT_HOURS
  );
  const [activeHoursAfter, setActiveHoursAfter] = useState<null | number>(
    activeHoursAfterProps || INIT_HOURS
  );

  const toggleEditableByOrganizationMembers = () => {
    setIsEditableByOrganizationMembers((val) => !val);
  };

  const onHoursChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.name === 'available_from_hour') {
      if (!e.target.value) setActiveHoursBefore(null);
      setActiveHoursBefore(parseFloat(e.target.value));
    } else {
      if (!e.target.value) setActiveHoursAfter(null);
      setActiveHoursAfter(parseFloat(e.target.value));
    }
  };

  const [selectedDateTo, setSelectedDateTo] = useState(dateTo);
  const [selectedDateFrom, setSelectedDateFrom] = useState(
    dateFrom || new Date()
  );

  const refetchOnSuccess = () => {
    refetch();
    toggleModal();
  };

  const { mutate: updateAssignment } =
    useOrganizationContextUpdateFormAssignment(
      {
        organizationId: organizationId,
        caseId: caseId,
        timelineId: timelineId,
        userId: userId,
        assignmentId: assignmentId!,
      },
      {
        successFb: refetchOnSuccess,
      }
    );

  const { mutate: addAssignment } = useOrganizationContextAddFormAssignment(
    {
      organizationId: organizationId,
      caseId: caseId,
      timelineId: timelineId,
      userId: userId,
    },
    {
      successFb: refetchOnSuccess,
    }
  );

  const completeAssignment = () => {
    let localDateFrom = selectedDateFrom;
    let localDateTo = selectedDateTo;

    if (
      selectedScheduleInfo &&
      selectedScheduleInfo.custom &&
      selectedScheduleInfo.custom?.length > 0
    ) {
      let minDate = new Date(selectedScheduleInfo.custom[0].date);
      let maxDate = new Date(selectedScheduleInfo.custom[0].date);

      for (const customItem of selectedScheduleInfo.custom) {
        const customItemDateObj = customItem.date;
        if (customItemDateObj < minDate) minDate = customItemDateObj;
        if (customItemDateObj > maxDate) maxDate = customItemDateObj;
      }

      localDateFrom = minDate;
      localDateTo = maxDate;
    }

    if (
      selectedScheduleInfo &&
      activeHoursBefore !== null &&
      activeHoursAfter !== null
    ) {
      if (assignmentId) {
        updateAssignment({
          dateFrom: localDateFrom,
          dateTo: localDateTo,
          scheduleInfo: selectedScheduleInfo as ScheduleInfo,
          formCode: formCode,
          isEditableByOrganizationMembers: isEditableByOrganizationMembers,
          activeHoursBefore,
          activeHoursAfter,
        });
      } else {
        addAssignment({
          dateFrom: localDateFrom,
          dateTo: localDateTo,
          scheduleInfo: selectedScheduleInfo as ScheduleInfo,
          formCode: formCode,
          isEditableByOrganizationMembers: isEditableByOrganizationMembers,
          activeHoursBefore,
          activeHoursAfter,
        });
      }
    }
  };

  const setInfo = (schedule: ScheduleInfoWithCustomOrdered) => {
    setSelectedScheduleInfo(schedule);
  };

  const completeAssignmentDisabled = () => {
    if (!selectedScheduleInfo) {
      return true;
    }

    return !!(
      (selectedScheduleInfo.custom &&
        selectedScheduleInfo.custom.length === 0) ||
      (selectedScheduleInfo.weekly &&
        selectedScheduleInfo.weekly.length === 0) ||
      (selectedScheduleInfo.daily &&
        selectedScheduleInfo.daily.hours.length === 0)
    );
  };

  return (
    <Modal
      unmountOnClose={true}
      isOpen={isOpen}
      toggle={toggleModal}
      className="FormsAssignmentsModal"
      size="lg"
    >
      <ModalHeader toggle={toggleModal}>
        <FormattedMessage id="CaseCard.FormsAssignmentsModal.header" />
      </ModalHeader>
      <ModalBody className="mt-3 pt-5">
        <DateSelector
          info={selectedScheduleInfo}
          setInfo={setSelectedScheduleInfo}
          dateTo={selectedDateTo}
          dateFrom={selectedDateFrom}
          setDateTo={setSelectedDateTo}
          setDateFrom={setSelectedDateFrom}
        />
        {selectedScheduleInfo !== undefined && (
          <div className="mt-4">
            {selectedScheduleInfo.custom !== null && (
              <CustomSelectionDetails
                info={selectedScheduleInfo}
                setInfo={setInfo}
              />
            )}
            {selectedScheduleInfo.weekly !== null && (
              <WeeklySelectionDetails
                info={selectedScheduleInfo}
                setInfo={setInfo}
              />
            )}
            {selectedScheduleInfo.daily !== null && (
              <DailySelectionDetails
                info={selectedScheduleInfo}
                setInfo={setInfo}
              />
            )}
            <div className="d-flex">
              <FormGroup className="me-4">
                <Label>
                  <FormattedMessage id="CaseCard.FormsAssignmentsModal.howMuchTimeBefore" />
                  <Input
                    type="number"
                    value={activeHoursBefore || 0}
                    name="hours_before"
                    onChange={onHoursChange}
                    min={0}
                  />
                </Label>
              </FormGroup>
              <FormGroup>
                <Label>
                  <FormattedMessage id="CaseCard.FormsAssignmentsModal.howMuchTimeAfter" />
                  <Input
                    type="number"
                    value={activeHoursAfter || 0}
                    name="hours_after"
                    onChange={onHoursChange}
                    min={0}
                  />
                </Label>
              </FormGroup>
            </div>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  checked={isEditableByOrganizationMembers}
                  onClick={toggleEditableByOrganizationMembers}
                />{' '}
                <FormattedMessage id="CaseCard.FormsAssignmentsModal.doctorCanEditAssessment" />
              </Label>
            </FormGroup>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={toggleModal}
          className="FormsAssignmentsModal__cancel"
        >
          <FormattedMessage id="CaseCard.FormsAssignmentsModal.cancel" />
        </Button>
        <Button
          color="primary"
          disabled={completeAssignmentDisabled()}
          onClick={completeAssignment}
          className="FormsAssignmentsModal__accept"
        >
          <FormattedMessage id="CaseCard.FormsAssignmentsModal.accept" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FormsAssignmentsModal;
