import { faClock } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import floppyDiscIcon from 'assets/images/floppy-disc.svg';
import revertArrowIcon from 'assets/images/revert-arrow.svg';
import { Field, Formik, FormikErrors } from 'formik';
import React, { useCallback } from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import {
  IConversationTreatmentPhaseContentItem,
  TreatmentPhaseContentItemType,
} from '../../interfaces/conversations';
import { ConversationScheduleType } from '../../interfaces/conversationSchedules';
import { InputOption } from '../../interfaces/ui';
import FieldInput from '../FieldInput';
import FieldInlineSelect, {
  FieldInlineSelectOptionDefinition,
} from '../Fields/Formik/FieldInlineSelect';
import FieldSelect from '../FieldSelect';
import InputFieldWithoutImage from '../InputFieldWithoutImage';
import AddScheduleTemplateRecurringFields from './components/AddScheduleTemplateRecurringFields';
import AddScheduleTemplateSingleInstanceFields from './components/AddScheduleTemplateSingleInstanceFields';

export type AddScheduleTemplateFormHandler = {
  initialValues: IConversationTreatmentPhaseContentItem;
  validate: (
    values: IConversationTreatmentPhaseContentItem
  ) => FormikErrors<IConversationTreatmentPhaseContentItem>;
  handleSubmit: (
    values: IConversationTreatmentPhaseContentItem
  ) => Promise<void>;
  options: {
    conversationTemplates: InputOption[];
    scheduleTypes: FieldInlineSelectOptionDefinition[];
  };
  helpers: {
    isTemplateDefault: (templateId: string) => boolean;
    isTemplateTimeBased: (templateId: string) => boolean;
  };
};

type AddScheduleTemplateModalProps = {
  isOpen: boolean;
  toggle: () => void;
  templateDisabled?: boolean;
  defaultTemplateMode?: boolean;
  formHandler: AddScheduleTemplateFormHandler;
};

const AddScheduleTemplateModal = ({
  isOpen,
  toggle,
  formHandler,
  templateDisabled = false,
  defaultTemplateMode = false,
}: AddScheduleTemplateModalProps) => {
  const intl = useIntl();

  const renderTypeSpecificFields = useCallback(
    (
      values: typeof formHandler.initialValues,
      setValues: (values: typeof formHandler.initialValues) => void
    ) => {
      const scheduleType = values.schedule_templates.at(0)?.schedule_type;

      if (!scheduleType) return <></>;

      switch (scheduleType) {
        case ConversationScheduleType.Once:
          return <></>;
        case ConversationScheduleType.NonTimeBased:
          return <></>;
        case ConversationScheduleType.SingleInstance:
          return <AddScheduleTemplateSingleInstanceFields />;
        case ConversationScheduleType.Recurring:
          return (
            <AddScheduleTemplateRecurringFields
              values={values}
              setValues={setValues}
            />
          );
      }
    },
    [formHandler]
  );

  const shouldTypeSpecificFieldsBeAvailable = useCallback(
    (templateId: string) => {
      return formHandler.helpers.isTemplateTimeBased(templateId);
    },
    [formHandler.helpers]
  );

  return (
    <Formik
      initialValues={formHandler.initialValues}
      validate={formHandler.validate}
      enableReinitialize={true}
      validateOnMount={true}
      onSubmit={formHandler.handleSubmit}
    >
      {({ values, setValues, submitForm, resetForm, isValid, dirty }) => {
        const handleCancel = () => {
          resetForm();
          toggle();
        };

        return (
          <Modal
            className="TreatmentPhaseAddTemplateModal BorderlessModal"
            size="md"
            isOpen={isOpen}
            toggle={toggle}
          >
            <ModalHeader className="text-primary">
              <div className="d-flex align-items-center gap-2">
                <FontAwesomeIcon
                  icon={faClock}
                  title={intl.formatMessage({
                    id: 'Projects.ProjectPhases.ProjectPhasesContent.ProjectPhase.ProjectPhaseAddTemplateModal.addTemplate',
                  })}
                />
                <FormattedMessage id="Projects.ProjectPhases.ProjectPhasesContent.ProjectPhase.ProjectPhaseAddTemplateModal.addTemplate" />
              </div>
            </ModalHeader>
            <ModalBody className="TreatmentPhaseAddTemplateModal__body">
              <div className="TreatmentPhaseAddTemplateModal__row">
                <InputFieldWithoutImage
                  gray={true}
                  noMargin={true}
                  labelTranslationId="Projects.ProjectPhases.ProjectPhasesContent.ProjectPhase.ProjectPhaseAddTemplateModal.template"
                  inputId="template_id"
                >
                  <Field
                    id="template_id"
                    name="template_id"
                    required={true}
                    displayEmpty={!defaultTemplateMode}
                    defaultText={intl.formatMessage({
                      id: 'TreatmentPlans.TreatmentPlan.TreatmentPlanContent.TreatmentPhase.TreatmentPhaseAddTemplateModal.selectConversationTemplate',
                    })}
                    disabled={templateDisabled}
                    options={formHandler.options.conversationTemplates}
                    component={FieldSelect}
                  />
                </InputFieldWithoutImage>
                {values.type ===
                  TreatmentPhaseContentItemType.ConversationSchedule &&
                  !formHandler.helpers.isTemplateDefault(
                    values.template_id
                  ) && (
                    <InputFieldWithoutImage
                      gray={true}
                      noMargin={true}
                      labelTranslationId="Projects.ProjectPhases.ProjectPhasesContent.ProjectPhase.ProjectPhaseAddTemplateModal.priority"
                      inputId="priority"
                    >
                      <Field
                        id="priority"
                        name="priority"
                        type="number"
                        required={true}
                        component={FieldInput}
                      />
                    </InputFieldWithoutImage>
                  )}
              </div>
              {values.type ===
                TreatmentPhaseContentItemType.ConversationSchedule &&
                shouldTypeSpecificFieldsBeAvailable(values.template_id) && (
                  <>
                    <div className="TreatmentPhaseAddTemplateModal__row">
                      <InputFieldWithoutImage
                        gray={true}
                        noMargin={true}
                        block={true}
                        labelTranslationId="Projects.ProjectPhases.ProjectPhasesContent.ProjectPhase.ProjectPhaseAddTemplateModal.recurrence"
                        inputId="schedule_type"
                      >
                        <Field
                          id="schedule_type"
                          name="schedule_templates[0].schedule_type"
                          required={true}
                          options={formHandler.options.scheduleTypes}
                          component={FieldInlineSelect}
                        />
                      </InputFieldWithoutImage>
                    </div>
                    {renderTypeSpecificFields(values, setValues)}
                  </>
                )}
            </ModalBody>
            <ModalFooter className="TreatmentPhaseAddTemplateModal__footer">
              <Button
                disabled={!isValid || !dirty}
                color="primary"
                onClick={submitForm}
                outline={true}
              >
                <SVG src={floppyDiscIcon} title="save" />
              </Button>
              <Button
                disabled={!dirty}
                color="primary"
                onClick={handleCancel}
                outline={true}
              >
                <SVG src={revertArrowIcon} title="cancel" />
              </Button>
            </ModalFooter>
          </Modal>
        );
      }}
    </Formik>
  );
};

export default AddScheduleTemplateModal;
