import React, { memo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EditableInput from '../../../../../../../../../../../../../../../../../../../../components/EditableInput/EditableInput';
import { faCheck } from '@fortawesome/pro-light-svg-icons';
import { useCommonData } from '../../../../../../../../../../../../../../../../../../hooks/useCommonData';
import { useInitQuery } from '../../../../../../../../../../../../../../../../../../../../hooks/queries/useInitQuery';
import SkeletonPlaceholderOnLoad from '../../../../../../../../../../../../../../../../../../../../components/SkeletonPlaceholderOnLoad';
import { Button } from 'reactstrap';
import { useOptions } from '../../../../../../../../../../../../../../../../../../../../hooks/useOptions';
import { useControlledValue } from '../../../../../../../../../../../../../../../../../../../../hooks/useControlledValue';
import { useHandleApply } from './hooks/useHandleApply';
import Skeleton from 'react-loading-skeleton';
import { useTranslations } from '../../../../../../../../../../../../../../../../../../../../hooks/useTranslations';

type SelectMaterialActionProps = {
  id: string;
  onResolve: () => void;
};

const SelectMaterialAction = ({ id, onResolve }: SelectMaterialActionProps) => {
  const { attachPrefix, wrapWithIntl } = useTranslations(
    'ConversationsAndFeedbacks.ConversationTemplates.ImportTemplateModal.Content.ConflictsStep.Validation.Response.Conflicts.SelectMaterialAction'
  );

  const { educationMaterials } = useCommonData();
  useInitQuery(educationMaterials);

  const options = useOptions();
  const educationMaterialOptions =
    options.educationMaterials(educationMaterials);

  const { value, handleChange } = useControlledValue<string>();

  const onEdit = (_: string, value: string) => {
    handleChange(value);
  };

  const handleApply = useHandleApply(id, onResolve);
  const handleSelectMaterial = () => handleApply(value ?? '');

  return (
    <div className="d-flex align-items-center gap-2">
      <SkeletonPlaceholderOnLoad
        isLoading={educationMaterials.isLoading}
        placeholder={<Skeleton width={200} height={30} />}
      >
        <EditableInput
          type="select"
          displayEmpty={true}
          required={true}
          placeholder={wrapWithIntl(attachPrefix('selectMaterial'))}
          options={educationMaterialOptions}
          value={value ?? ''}
          onEdit={onEdit}
          debounceEnabled={false}
        />
      </SkeletonPlaceholderOnLoad>
      <Button
        disabled={!value}
        color="primary"
        outline={true}
        onClick={handleSelectMaterial}
      >
        <FontAwesomeIcon icon={faCheck} />
      </Button>
    </div>
  );
};

export default memo(SelectMaterialAction);
