import React from 'react';
import { OpenValidConversationQuestion } from '../../../../../../../../../../../../../../interfaces/conversations';
import { useTypedContext } from '../../../../../../../../../../../../../../hooks/useTypedContext';
import { LanguageContext } from '../../../../../../../../types';
import { format } from 'date-fns';
import { AVAILABLE_CULTURES } from '../../../../../../../../../../../../../../components/CultureSelector/CultureSelector';
import { useTranslations } from '../../../../../../../../../../../../../../hooks/useTranslations';

type OpenQuestionItemProps = {
  item: OpenValidConversationQuestion;
};

const OpenQuestionItem = ({ item }: OpenQuestionItemProps) => {
  const { wrapWithIntl, attachPrefix } = useTranslations(
    'ConversationsAndFeedbacks.ConversationTemplates.ConversationTemplatePhone.Conversation.ConversationItem.QuestionItem.OpenQuestionItem'
  );

  const language = useTypedContext(LanguageContext);
  const currentHour = format(new Date(), 'HH:HH a');

  const title =
    language.culture === AVAILABLE_CULTURES.EN ? item.title : item.title_nb_no;

  const titleHtml =
    language.culture === AVAILABLE_CULTURES.EN
      ? item.title_html
      : item.title_html_nb_no;

  return (
    <div className="ConversationTemplateRangeQuestionItem">
      <div className="ConversationTemplateRangeQuestionItem__question d-flex flex-column">
        {titleHtml ? (
          <span
            className="ConversationTemplatePlainTextItem__content__text"
            dangerouslySetInnerHTML={{ __html: titleHtml }}
          />
        ) : (
          <span className="ConversationTemplatePlainTextItem__content__text">
            {title}
          </span>
        )}
        <span className="ConversationTemplatePlainTextItem__content__hour">
          {currentHour}
        </span>
      </div>
      <div className="ConversationTemplateRangeQuestionItem__slider">
        <textarea
          className="ConversationTemplateQuestionItem__open-question-textarea"
          rows={3}
          placeholder={
            item.answer_placeholder ??
            wrapWithIntl(attachPrefix('answerPlaceholder'))
          }
          disabled={true}
          defaultValue={item.answer.text_value ?? ''}
        />
      </div>
    </div>
  );
};

export default OpenQuestionItem;
