import { useCallback } from 'react';
import { useTypedContext } from '../../../../../../../../../../../hooks/useTypedContext';
import { OperationalDataContext } from '../../../../../providers/OperationalDataProvider';
import { useMsDiffs } from '../../../../../components/ProcedureEventsTimeline/hooks/useMsDiffs';
import { secondsToDurationString } from '../../../../../../../../../../../helpers/dates';

const timeValueRegex = /^\d\d:\d\d:\d\d$/i;

export const useTimeValue = () => {
  const { awareSessionDetails } = useTypedContext(OperationalDataContext);

  const { getMsDiff } = useMsDiffs(
    awareSessionDetails.start_date,
    awareSessionDetails.end_date
  );

  return useCallback(
    (timeValue: string | undefined) => {
      if (!timeValue) return undefined;

      if (timeValueRegex.test(timeValue)) return timeValue;

      return secondsToDurationString(
        getMsDiff(awareSessionDetails.start_date, timeValue) / 1000
      );
    },
    [awareSessionDetails.start_date, getMsDiff]
  );
};
