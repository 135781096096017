import useOrganizationDetails from '../../../../../api/queries/organizations/useOrganizationDetails';
import useRequiredParams from '../../../../../hooks/useRequiredParams';
import useTreatmentPhases from '../../../../../api/queries/conversations/useTreatmentPhases';
import useCaseCategories from '../../../../../api/queries/cases/useCaseCategories';
import useConversationTemplates from '../../../../../api/queries/conversations/useConversationTemplates';

export const useTreatmentPlanCommonData = () => {
  const { organizationId, treatmentPlanId } = useRequiredParams<{
    organizationId: string;
    treatmentPlanId: string;
  }>(['organizationId', 'treatmentPlanId']);

  const organizationQuery = useOrganizationDetails({
    params: { organizationId },
    options: {
      enabled: false,
      staleTime: Infinity,
    },
  });

  const treatmentPhasesQuery = useTreatmentPhases({
    params: {
      organizationId,
      treatmentPlanId,
    },
    options: { enabled: false, staleTime: Infinity },
  });

  const caseCategoriesQuery = useCaseCategories({
    params: { organizationId },
    options: { enabled: false, staleTime: Infinity },
  });

  const conversationTemplatesQuery = useConversationTemplates({
    params: {
      organizationId,
    },
    options: {
      enabled: false,
      staleTime: Infinity,
    },
  });

  return {
    organization: organizationQuery,
    treatmentPhases: treatmentPhasesQuery,
    caseCategories: caseCategoriesQuery,
    conversationTemplates: conversationTemplatesQuery,
  };
};
