import React, { useContext } from 'react';
import { FormHookProps } from '../../../../../../../../interfaces/forms';
import {
  MaterialModalFormValues,
  MaterialModalMode,
  MaterialModalModeContext,
  MaterialModalStepsContext,
} from '../../../../types';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowLeft,
  faArrowRight,
  faSave,
} from '@fortawesome/pro-light-svg-icons';
import LoadingButton from '../../../../../../../../components/LoadingButton';

type AddRulesStepProps = FormHookProps<MaterialModalFormValues>;

const AddRulesStep = ({ form }: AddRulesStepProps) => {
  const { prev } = useContext(MaterialModalStepsContext);
  const mode = useContext(MaterialModalModeContext);

  return (
    <>
      <Button outline={true} color="primary" onClick={prev}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </Button>
      <LoadingButton
        isLoading={form.isSubmitting}
        disabled={!form.isValid}
        color="primary"
        onClick={form.submitForm}
      >
        <FontAwesomeIcon
          icon={mode === MaterialModalMode.AddMaterial ? faArrowRight : faSave}
        />
      </LoadingButton>
    </>
  );
};

export default AddRulesStep;
