import React from 'react';
import { useTypedContext } from '../../../../../../../../../../../../hooks/useTypedContext';
import { ITableDashboardTemplateVisualization } from '../../../../../../../../../../../../interfaces/dashboards';
import CustomMaterialTable from '../../../../../../../../../../../CustomMaterialTable';
import { VisualizationDataContext } from '../../types';
import { useColumns } from './hooks/useColumns';
import { useHandleRowClick } from './hooks/useHandleRowClick';
import { useResponsive } from './hooks/useResponsive';
import { useRows } from './hooks/useRows';

type TableViewProps = {
  visualization: ITableDashboardTemplateVisualization;
};

const TableView = ({ visualization }: TableViewProps) => {
  const visualizationData = useTypedContext(VisualizationDataContext);
  const columns = useColumns(visualization);
  const rows = useRows(visualization, visualizationData);
  const config = useResponsive(visualization);
  const handleRowClick = useHandleRowClick(visualization.on_click);

  return (
    <CustomMaterialTable
      rows={rows}
      columns={columns}
      onRowClick={handleRowClick}
      responsiveConfig={config}
    />
  );
};

export default TableView;
