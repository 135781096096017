import _ from 'lodash';
import { useCallback } from 'react';
import { IntlShape, useIntl } from 'react-intl';
import { UseQueryResult } from 'react-query';
import arrowRepeatCrossedOutIcon from '../assets/images/arrow-repeat-crossed-out.svg';
import arrowRepeatIcon from '../assets/images/arrow-repeat.svg';
import { AVAILABLE_CULTURES } from '../components/CultureSelector/CultureSelector';
import { FieldInlineSelectOptionDefinition } from '../components/Fields/Formik/FieldInlineSelect';
import { getIconForConversationScheduleType } from '../helpers/utils/icons';
import { caseStatusOptions as _caseStatusOptions } from '../helpers/utils/translationObject';
import { OrganizationAccessProfile } from '../interfaces/accessProfiles';
import { ICategory } from '../interfaces/cases';
import { ValueInterpretationType } from '../interfaces/change';
import {
  ConversationTemplateContext,
  ConversationTemplateTriggerType,
  ValidConversationQuestionType,
} from '../interfaces/conversations';
import {
  ConversationScheduleType,
  UpdateRangeMode,
} from '../interfaces/conversationSchedules';
import {
  DashboardTemplateChartType,
  DashboardTemplateSeriesAggregate,
  DashboardTemplateSeriesDataSource,
  DateGranularity,
} from '../interfaces/dashboards';
import {
  CreateRuleAvailabilityStatus,
  IBaseEducationMaterialCategory,
  IEducationMaterial,
} from '../interfaces/educationMaterials';
import { CaseNotificationScheduleType } from '../interfaces/notificationSchedules';
import { OrganizationType } from '../interfaces/organizations';
import { InputOption } from '../interfaces/ui';
import useCultures from './cultures/useCultures';
import { useTranslations } from './useTranslations';

export const getOptionsFromEnum = <T extends {}>(
  data: T,
  enumName: string,
  intl: IntlShape,
  icon?: (value: T) => string,
  disabled?: (value: T) => boolean
): InputOption[] => {
  return Object.entries(data).map(([key, val]) => ({
    value: val as any,
    text: intl.formatMessage({ id: `Enums.${enumName}.${key}` }),
    icon: icon?.(val as T),
    disabled: disabled?.(val as T),
  }));
};

const getOptionsFromQuery = <T>(
  query: UseQueryResult<T[]>,
  valueAccessor: string,
  textAccessor: string,
  icon?: (value: T) => string
): InputOption[] => {
  return (query.data ?? []).map((item) => ({
    value: _.get(item, valueAccessor),
    text: _.get(item, textAccessor),
    icon: icon?.(item),
  }));
};

export const useOptions = () => {
  const intl = useIntl();
  const { wrapWithIntl, attachPrefix } = useTranslations('Enums');
  const { currentCulture, defaultCulture } = useCultures();

  const scheduleTypesOptions = useCallback(
    (
      excludeNonTimeBased: boolean = false
    ): FieldInlineSelectOptionDefinition[] => {
      let scheduleValues = Object.values(ConversationScheduleType);

      if (excludeNonTimeBased)
        scheduleValues = scheduleValues.filter(
          (p) => p !== ConversationScheduleType.NonTimeBased
        );

      return scheduleValues.map((val) => ({
        value: val,
        title: wrapWithIntl(attachPrefix(`ConversationScheduleType.${val}`)),
        icon: getIconForConversationScheduleType(val) ?? '',
      }));
    },
    [attachPrefix, wrapWithIntl]
  );

  const updateRangeOptions = useCallback(
    (optionsDisabled: boolean = false): FieldInlineSelectOptionDefinition[] => {
      let rangeValues = Object.values(UpdateRangeMode);

      return rangeValues.map((rangeMode) => ({
        value: rangeMode,
        title: wrapWithIntl(attachPrefix(`UpdateRangeMode.${rangeMode}`)),
        icon:
          rangeMode === UpdateRangeMode.AllEvents
            ? arrowRepeatIcon
            : arrowRepeatCrossedOutIcon,
        disabled: optionsDisabled,
      }));
    },
    [attachPrefix, wrapWithIntl]
  );

  const feedbackResourcesOptions = (
    query: UseQueryResult<Record<string, any[]>>
  ): InputOption[] => {
    if (!query.data) return [];

    return Object.keys(query.data).map((key) => ({
      value: key,
      text: key,
    }));
  };

  const educationMaterialAvailabilityStatusOptions = () => {
    return getOptionsFromEnum(
      CreateRuleAvailabilityStatus,
      'CreateRuleAvailabilityStatus',
      intl
    );
  };

  const caseCategoriesOptions = useCallback(
    (query: UseQueryResult<ICategory[]>): InputOption[] => {
      if (!query.data) return [];

      return query.data.map(
        (category): InputOption => ({
          value: category.id,
          text:
            currentCulture.culture === AVAILABLE_CULTURES.NO
              ? category.name_no
              : category.name_en,
        })
      );
    },
    [currentCulture.culture]
  );

  const educationMaterialCategoriesOptions = useCallback(
    (
      query: UseQueryResult<IBaseEducationMaterialCategory[]>
    ): InputOption[] => {
      if (!query.data) return [];

      return query.data.map(
        (category): InputOption => ({
          value: category.id,
          text:
            currentCulture.culture === AVAILABLE_CULTURES.NO
              ? category.name_translations.find((p) => p.culture === 'nb-NO')
                  ?.value ?? category.name
              : category.name,
        })
      );
    },
    [currentCulture.culture]
  );

  const caseStatusOptions = useCallback((): InputOption[] => {
    const translations = _caseStatusOptions;

    return translations.map((translation) => ({
      value: translation.value,
      text: wrapWithIntl(translation.translationId),
    }));
  }, [wrapWithIntl]);

  const educationMaterialsOptions = useCallback(
    (query: UseQueryResult<IEducationMaterial[]>): InputOption[] => {
      const isDefaultCulture =
        defaultCulture.cultureCode === currentCulture.cultureCode;

      return (query.data ?? []).map((p) => ({
        value: p.id,
        text: isDefaultCulture
          ? p.name
          : p.name_translations.find(
              (q) => q.culture === currentCulture.cultureCode
            )?.value ?? p.name,
      }));
    },
    [currentCulture.cultureCode, defaultCulture.cultureCode]
  );

  const conversationTemplateContextOptions = useCallback((): InputOption[] => {
    return getOptionsFromEnum(
      ConversationTemplateContext,
      'ConversationTemplateContext',
      intl
    );
  }, [intl]);

  const conversationTemplateTriggerTypeOptions =
    useCallback((): InputOption[] => {
      return getOptionsFromEnum(
        ConversationTemplateTriggerType,
        'ConversationTemplateTriggerType',
        intl
      );
    }, [intl]);

  const conversationQuestionTypeOptions = useCallback((): InputOption[] => {
    return getOptionsFromEnum(
      ValidConversationQuestionType,
      'ValidConversationQuestionType',
      intl
    );
  }, [intl]);

  const conversationQuestionValueInterpretationOptions =
    useCallback((): InputOption[] => {
      return getOptionsFromEnum(
        ValueInterpretationType,
        'ValueInterpretationType',
        intl
      );
    }, [intl]);

  const organizationTypesOptions = useCallback((): InputOption[] => {
    return getOptionsFromEnum(OrganizationType, 'OrganizationType', intl);
  }, [intl]);

  const caseNotificationScheduleTypesOptions =
    useCallback((): InputOption[] => {
      return getOptionsFromEnum(
        CaseNotificationScheduleType,
        'CaseNotificationScheduleType',
        intl
      );
    }, [intl]);

  const accessProfilesOptions = useCallback(
    (query: UseQueryResult<OrganizationAccessProfile[]>): InputOption[] => {
      return getOptionsFromQuery(query, 'id', 'name');
    },
    []
  );

  const chartTypeOptions = useCallback((): InputOption[] => {
    return getOptionsFromEnum(
      DashboardTemplateChartType,
      'DashboardTemplateChartType',
      intl
    );
  }, [intl]);

  const seriesAggregateOptions = useCallback((): InputOption[] => {
    return getOptionsFromEnum(
      DashboardTemplateSeriesAggregate,
      'DashboardTemplateSeriesAggregate',
      intl
    );
  }, [intl]);

  const dashboardDataSourceOptions = useCallback((): InputOption[] => {
    return getOptionsFromEnum(
      DashboardTemplateSeriesDataSource,
      'DashboardTemplateSeriesDataSource',
      intl
    );
  }, [intl]);

  const dateGranularityOptions = useCallback(
    (auto: boolean = true): InputOption[] => {
      let options: InputOption[] = [];

      if (auto) {
        options.push({
          text: intl.formatMessage({ id: 'General.Auto' }),
          value: 'Auto',
        });
      }

      options.push(
        ...getOptionsFromEnum(DateGranularity, 'DateGranularity', intl)
      );

      return options;
    },
    [intl]
  );

  return {
    scheduleTypes: scheduleTypesOptions,
    updateRange: updateRangeOptions,
    feedbackResources: feedbackResourcesOptions,
    caseStatus: caseStatusOptions,
    caseCategories: caseCategoriesOptions,
    availabilityStatus: educationMaterialAvailabilityStatusOptions,
    materialCategories: educationMaterialCategoriesOptions,
    educationMaterials: educationMaterialsOptions,
    conversationTemplateContexts: conversationTemplateContextOptions,
    conversationTemplateTriggerTypes: conversationTemplateTriggerTypeOptions,
    conversationQuestionTypes: conversationQuestionTypeOptions,
    conversationQuestionValueInterpretations:
      conversationQuestionValueInterpretationOptions,
    organizationTypes: organizationTypesOptions,
    accessProfilesOptions,
    caseNotificationScheduleTypesOptions,
    chartTypeOptions,
    seriesAggregateOptions,
    dashboardDataSourceOptions,
    dateGranularityOptions,
  };
};
