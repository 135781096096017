import { getCaseHistory } from 'api/cases';
import QueryKeys from 'api/queries/queryKeys';
import { format } from 'date-fns';
import { ICaseHistory } from 'interfaces/caseHistory';
import { CaseQueryParams, PagingQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { IError } from 'interfaces/response';
import { FromToDates } from 'interfaces/ui';
import { useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { toDateOnly } from '../../../helpers/dates';

interface CaseHistoryParams extends CaseQueryParams, PagingQueryParams {
  selectedDates?: FromToDates | null;
}

type IInfiniteCaseHistory = {
  data: ICaseHistory[];
  nextPage: number | null;
  previousPage: number | null;
};

function useInfiniteCaseHistory({
  params,
  options,
}: IQueryRequest<CaseHistoryParams>) {
  const {
    organizationId,
    caseId,
    pageSize = 20,
    selectedDates = null,
  } = params;

  const response = useInfiniteQuery<IInfiniteCaseHistory, IError>({
    ...options,
    queryKey: [
      QueryKeys.getCaseHistory,
      organizationId,
      caseId,
      selectedDates?.from ? toDateOnly(selectedDates.from) : null,
      selectedDates?.to ? toDateOnly(selectedDates.to) : null,
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getCaseHistory(
        organizationId,
        caseId,
        selectedDates,
        {
          page: pageParam,
          page_size: pageSize,
        }
      );

      const responseData: IInfiniteCaseHistory = {
        data: data.data,
        nextPage: data.meta.pagination.next_page,
        previousPage: data.meta.pagination.previous_page,
      };

      return responseData;
    },
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage;
    },
    getPreviousPageParam: (firstPage) => firstPage.previousPage,
  });

  const [history, setHistory] = useState<ICaseHistory[]>([]);
  useEffect(() => {
    if (response.data) {
      const newHistory: ICaseHistory[] = [];

      response.data.pages.forEach((page) => {
        page.data.forEach((historyData) => newHistory.push(historyData));
      });

      setHistory(newHistory);
    }
  }, [response.data]);

  const historyItemsPerDay = useMemo(() => {
    const orderedArray = history.sort((prevActivity, nextActivity) => {
      if (prevActivity.time_start > nextActivity.time_start) return -1;
      if (prevActivity.time_start < nextActivity.time_start) return 1;
      return 0;
    });

    let formattedActivities: {
      [key: string]: ICaseHistory[];
    } = {};

    orderedArray.forEach((activity) => {
      const dateWithoutHours = format(
        new Date(activity.time_start),
        'dd.MM.yyyy'
      );

      const existingObject = formattedActivities[dateWithoutHours]
        ? formattedActivities[dateWithoutHours]
        : [];

      formattedActivities[dateWithoutHours] = [...existingObject, activity];
    });

    return formattedActivities;
  }, [history]);

  return { ...response, history, historyItemsPerDay };
}

export default useInfiniteCaseHistory;
