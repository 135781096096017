import { getLocalOrganizationFunctionalities } from 'helpers/utils/auth';
import { FunctionalityCode } from 'interfaces/functionalities';

type FunctionalityGuardProps = {
  code: FunctionalityCode;
  children: JSX.Element;
};

const FunctionalityRouteGuard = ({
  code,
  children,
}: FunctionalityGuardProps) => {
  const functionalities = getLocalOrganizationFunctionalities();
  if (functionalities.includes(code)) return children;
  return null;
};

export default FunctionalityRouteGuard;
