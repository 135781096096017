import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { IconButton, Paper } from '@mui/material';
import React from 'react';
import { ConversationQuestion } from '../../../../../../../../../../interfaces/conversations';
import { IPersonality } from '../../../../../../../../../../interfaces/personalities';
import { useBrowserState } from '../../../../../../../../../../store/reducerHooks';
import { useDivideSingleEntries } from '../../../../hooks/useDivideSingleEntries';
import { useCarousel } from './hooks/useCarousel';
import { useSummaryItemData } from './hooks/useSummaryItemData';

type SummaryItemsProps = {
  members: IPersonality[];
  questions: ConversationQuestion[];
};

const SummaryItems = ({ members, questions }: SummaryItemsProps) => {
  const isBrowser = useBrowserState();

  const summaryItems = useSummaryItemData(questions, members);
  const dividedSummaryItems = useDivideSingleEntries(
    summaryItems,
    isBrowser ? 5 : 7
  );
  const { next, slides, previous, items } = useCarousel(dividedSummaryItems);

  return (
    <Paper className="h-100" sx={{ position: 'relative' }}>
      {slides}
      {items.length > 1 && (
        <div
          className="d-block position-absolute"
          style={{ top: '50%', left: 0, transform: 'translateY(-50%)' }}
        >
          <IconButton aria-label="previous" onClick={previous}>
            <ArrowBackIosNewIcon />
          </IconButton>
        </div>
      )}
      {items.length > 1 && (
        <div
          className="d-block position-absolute"
          style={{ top: '50%', right: 0, transform: 'translateY(-50%)' }}
        >
          <IconButton aria-label="next" onClick={next}>
            <ArrowForwardIosIcon />
          </IconButton>
        </div>
      )}
    </Paper>
  );
};

export default SummaryItems;
