import { IQueryRequest } from '../../../interfaces/request';
import { useQuery } from 'react-query';
import QueryKeys from '../queryKeys';
import { getUserCaseDetails } from '../../cases';
import { UserCaseQueryParams } from '../../../interfaces/queryParams';

const useUserCaseDetails = ({
  params,
  options,
}: IQueryRequest<UserCaseQueryParams>) => {
  const { userId, caseId } = params;

  return useQuery({
    ...options,
    queryKey: [QueryKeys.getUserCaseDetails, userId, caseId],
    queryFn: async () => {
      const { data } = await getUserCaseDetails(userId, caseId);
      return data.data;
    },
  });
};

export default useUserCaseDetails;
