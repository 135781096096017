import { OrganizationQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { useQuery } from 'react-query';
import { getTreatmentPhases } from '../../conversations';
import QueryKeys from '../queryKeys';

interface UseTreatmentPhasesParams extends OrganizationQueryParams {
  treatmentPlanId: string;
}

const useTreatmentPhases = ({
  params,
  options,
}: IQueryRequest<UseTreatmentPhasesParams>) => {
  const { organizationId, treatmentPlanId } = params;
  return useQuery({
    ...options,
    queryFn: async () => {
      const { data } = await getTreatmentPhases(
        organizationId,
        treatmentPlanId
      );

      data.data.sort((a, b) => a.sequence - b.sequence);
      return data.data;
    },
    queryKey: [QueryKeys.getTreatmentPhases, organizationId, treatmentPlanId],
  });
};

export default useTreatmentPhases;
