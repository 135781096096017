import { CaseNotificationScheduleType } from '../../../../../../../../../../../../../../../../interfaces/notificationSchedules';

export enum FormEditMode {
  SingleEvent = 'SingleEvent',
  AllEvents = 'AllEvents',
}

export type FormValues = {
  notification_type: CaseNotificationScheduleType;
  editing_mode: FormEditMode;
  start_day_offset?: number;
  end_day_offset?: number;
  recurring_interval?: number;
  specific_day_of_phase?: number;
  notification_hour: string;
};
