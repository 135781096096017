import { ISqueezeChartData } from 'interfaces/timeline';
import React, { useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { generateTranslationIdWithValues } from 'translations/utils/translationGenerator';
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import { formatDate } from 'helpers/dates';
import { Button } from 'reactstrap';
import SqueezeQuantityChartLegend from './SqueezeQuantityChartLegend';
import SqueezeQuantityChartTooltip from './SqueezeQuantityChartTooltip';
import { ZoomModeConfig } from '../AssessmentFormChart/AssessmentFormChart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrows } from '@fortawesome/pro-solid-svg-icons';
import { TrendVisualizationChartType } from 'interfaces/trendVisualizations';
import useXAxisFormatting from 'hooks/useXAxisFormatting';
import useChartZoom from 'hooks/useChartZoom';

type SqueezeQuantityChartProps = {
  squeezeData: ISqueezeChartData;
  chartType: TrendVisualizationChartType;
};

export enum SqueezeQuantityType {
  SOFT_QUANTITY = 'SqueezesSoftQuantity',
  MEDIUM_QUANTITY = 'SqueezesMediumQuantity',
  HARD_QUANTITY = 'SqueezesHardQuantity',
  TOTAL_QUANTITY = 'SqueezesQuantity',
}

export enum SqueezeQuantityTypeColor {
  SOFT_QUANTITY = '#237fc033',
  MEDIUM_QUANTITY = '#237fc066',
  HARD_QUANTITY = '#237fc099',
  TOTAL_QUANTITY = '#237fc0',
}

export const predefinedChartModes = generateTranslationIdWithValues(
  'UserDashboard.SqueezeSummary.modes',
  [
    SqueezeQuantityType.SOFT_QUANTITY,
    SqueezeQuantityType.MEDIUM_QUANTITY,
    SqueezeQuantityType.HARD_QUANTITY,
    SqueezeQuantityType.TOTAL_QUANTITY,
  ]
);

const initialZoomModeConfig: ZoomModeConfig = {
  left: undefined,
  right: undefined,
  refAreaLeft: undefined,
  refAreaRight: undefined,
  animation: true,
};

const SqueezeQuantityChart = ({
  squeezeData,
  chartType,
}: SqueezeQuantityChartProps) => {
  const intl = useIntl();

  const [selectedSqueezeQuantityType, setSelectedSqueezeQuantityType] =
    useState<SqueezeQuantityType>(SqueezeQuantityType.TOTAL_QUANTITY);

  const selectedSqueezeQuantityTypeTranslationId = useMemo(() => {
    return (
      predefinedChartModes.find((p) => p.value === selectedSqueezeQuantityType)
        ?.translationId ?? ''
    );
  }, [selectedSqueezeQuantityType]);

  const formattedChartData = squeezeData.x_axis.map((date, index) => ({
    name: formatDate(date, {
      addSeconds: false,
    }),
    squeezesSoftQuantity: squeezeData.series.find(
      (p) => p.mode === SqueezeQuantityType.SOFT_QUANTITY
    )!.data[index],
    squeezesMediumQuantity: squeezeData.series.find(
      (p) => p.mode === SqueezeQuantityType.MEDIUM_QUANTITY
    )!.data[index],
    squeezesHardQuantity: squeezeData.series.find(
      (p) => p.mode === SqueezeQuantityType.HARD_QUANTITY
    )!.data[index],
    squeezesQuantity: squeezeData.series.find(
      (p) => p.mode === SqueezeQuantityType.TOTAL_QUANTITY
    )!.data[index],
  }));

  const formattingFunction = useXAxisFormatting(squeezeData.x_axis);

  const handleTickFormatting = (value: any) => formattingFunction(value);

  const renderSqueezeQuantityChartTooltip = (
    props: TooltipProps<string, string>
  ) => {
    return (
      <SqueezeQuantityChartTooltip
        {...props}
        translationId={selectedSqueezeQuantityTypeTranslationId}
      />
    );
  };

  const renderBars = useMemo(() => {
    return Object.entries(SqueezeQuantityType)
      .filter(([, value]) => value === selectedSqueezeQuantityType)
      .map(([key, value], index) => (
        <Bar
          key={key}
          dataKey={`${value[0].toLocaleLowerCase()}${value.substring(1)}`}
          name={intl.formatMessage({
            id: predefinedChartModes[index].translationId,
          })}
          fill={SqueezeQuantityTypeColor[key]}
          yAxisId="1"
        />
      ));
  }, [selectedSqueezeQuantityType, intl]);

  const renderLines = useMemo(() => {
    return Object.entries(SqueezeQuantityType)
      .filter(([, value]) => value === selectedSqueezeQuantityType)
      .map(([key, value], index) => (
        <Line
          key={key}
          dataKey={`${value[0].toLocaleLowerCase()}${value.substring(1)}`}
          name={intl.formatMessage({
            id: predefinedChartModes[index].translationId,
          })}
          fill={SqueezeQuantityTypeColor[key]}
          yAxisId="1"
        />
      ));
  }, [selectedSqueezeQuantityType, intl]);

  const {
    zoomModeConfig,
    handleMouseDown,
    handleMouseMove,
    handleResetZoom,
    zoomHandler,
  } = useChartZoom(initialZoomModeConfig);

  const zoom = () => zoomHandler(formattedChartData);

  return (
    <div className="d-flex flex-column" style={{ userSelect: 'none' }}>
      {zoomModeConfig.left !== undefined && zoomModeConfig.right !== undefined && (
        <Button
          color="primary"
          outline
          className="me-3 align-self-end"
          onClick={handleResetZoom}
        >
          <FontAwesomeIcon icon={faArrows} className="me-1" />
          <FormattedMessage id="TimelineDetails.TrendsTimeline.zoomOut" />
        </Button>
      )}
      <ResponsiveContainer
        className="SqueezeQuantityChart"
        width={'99%'}
        height={400}
      >
        {chartType === TrendVisualizationChartType.BarChart ? (
          <BarChart
            data={
              zoomModeConfig.left && zoomModeConfig.right
                ? formattedChartData.slice(
                    zoomModeConfig.left,
                    zoomModeConfig.right
                  )
                : formattedChartData
            }
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={zoom}
            margin={{
              left: 5,
              bottom: 5,
              right: 5,
              top: 25,
            }}
          >
            <CartesianGrid strokeDasharray="1 1" vertical={false} />
            <XAxis
              dataKey="name"
              tickFormatter={handleTickFormatting}
              minTickGap={0}
              tickMargin={15}
              tickCount={squeezeData.x_axis.length}
            />
            <YAxis allowDataOverflow={false} yAxisId="1" />
            <Tooltip
              content={renderSqueezeQuantityChartTooltip}
              cursor={{ fill: '#e0e0e0' }}
            />
            <Legend
              wrapperStyle={{ paddingTop: '20px' }}
              content={
                <SqueezeQuantityChartLegend
                  selectedSqueezeQuantityType={selectedSqueezeQuantityType}
                  setSelectedSqueezeQuantityType={
                    setSelectedSqueezeQuantityType
                  }
                />
              }
            />
            {renderBars}
            {zoomModeConfig.refAreaLeft && zoomModeConfig.refAreaRight ? (
              <ReferenceArea
                yAxisId="1"
                x1={zoomModeConfig.refAreaLeft}
                x2={zoomModeConfig.refAreaRight}
                strokeOpacity={0.3}
              />
            ) : null}
          </BarChart>
        ) : (
          <LineChart
            data={
              zoomModeConfig.left && zoomModeConfig.right
                ? formattedChartData.slice(
                    zoomModeConfig.left,
                    zoomModeConfig.right
                  )
                : formattedChartData
            }
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={zoom}
            margin={{
              left: 5,
              bottom: 5,
              right: 5,
              top: 25,
            }}
          >
            <CartesianGrid strokeDasharray="1 1" vertical={false} />
            <XAxis
              dataKey="name"
              tickFormatter={handleTickFormatting}
              minTickGap={0}
              tickMargin={15}
              tickCount={squeezeData.x_axis.length}
            />
            <YAxis allowDataOverflow={false} yAxisId="1" />
            <Tooltip
              content={renderSqueezeQuantityChartTooltip}
              cursor={{ fill: '#e0e0e0' }}
            />
            <Legend
              wrapperStyle={{ paddingTop: '20px' }}
              content={
                <SqueezeQuantityChartLegend
                  selectedSqueezeQuantityType={selectedSqueezeQuantityType}
                  setSelectedSqueezeQuantityType={
                    setSelectedSqueezeQuantityType
                  }
                />
              }
            />
            {renderLines}
            {zoomModeConfig.refAreaLeft && zoomModeConfig.refAreaRight ? (
              <ReferenceArea
                yAxisId="1"
                x1={zoomModeConfig.refAreaLeft}
                x2={zoomModeConfig.refAreaRight}
                strokeOpacity={0.3}
              />
            ) : null}
          </LineChart>
        )}
      </ResponsiveContainer>
    </div>
  );
};

export default SqueezeQuantityChart;
