import { cloneDeep } from 'lodash';
import { DashboardActionTypes } from './actionTypes';
import { DashboardState } from './state';

export namespace DashboardActionResolvers {
  export const AddTemplateResolver = (
    state: DashboardState.DashboardStateType,
    action: DashboardActionTypes.IAddTemplateDashboardAction
  ) => {
    const existingTemplate = state.templates.find(
      (p) => p.path === action.path
    );

    if (existingTemplate) return state;

    let templates = cloneDeep(state.templates);

    templates.push({
      path: action.path,
      template: action.template,
    });

    return {
      templates: templates,
    };
  };

  export const EditTemplateResolver = (
    state: DashboardState.DashboardStateType,
    action: DashboardActionTypes.IEditTemplateDashboardAction
  ) => {
    const existingTemplate = state.templates.find(
      (p) => p.path === action.path
    );

    if (!existingTemplate) return state;

    let templates = cloneDeep(state.templates);
    const templateIndex = templates.findIndex((p) => p.path === action.path);

    templates[templateIndex].template = action.template;

    return {
      templates: templates,
    };
  };
}
