import useCaseMembers from 'api/queries/cases/useCaseMembers';
import { Field } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormGroup, Input, Label } from 'reactstrap';
import useOrganizationMembers from '../../../../../api/queries/organizations/useOrganizationMembers';
import FieldMultiSelect from '../../../../../components/FieldMultiSelect';
import { OptionsType } from 'react-select/lib/types';
import { Option } from 'react-select/lib/filters';
import React, { useMemo, useState } from 'react';
import { CaseHistoryModalFormValues } from '../index';

type OwnerOfMessageInputProps = {
  organizationId: string;
  caseId?: string;
  caseIds?: string[];
  setValues: (values: CaseHistoryModalFormValues) => void;
  values: CaseHistoryModalFormValues;
};

const OwnerOfMessageInput = ({
  organizationId,
  values,
  setValues,
  caseId = '',
  caseIds = undefined,
}: OwnerOfMessageInputProps) => {
  const intl = useIntl();

  const [allAssigned, setAllAssigned] = useState<boolean>(() => false);

  const { data: caseMembers, isLoading: caseMembersLoading } = useCaseMembers({
    params: { organizationId, caseId },
    options: { enabled: !!caseId },
  });

  const { data: organizationMembers, isLoading: organizationMembersLoading } =
    useOrganizationMembers({
      params: { organizationId },
      options: { enabled: !!caseIds },
    });

  const members = useMemo(
    () => (caseId ? caseMembers : organizationMembers),
    [caseId, caseMembers, organizationMembers]
  );

  const isLoading = useMemo(
    () => (caseId ? caseMembersLoading : organizationMembersLoading),
    [caseId, caseMembersLoading, organizationMembersLoading]
  );

  const options: OptionsType<Option> = (members ?? []).map((personality) => ({
    value: personality.id,
    label: `${personality.first_name} ${personality.last_name}`,
    data: null,
  }));

  const handleChangeAllAssigned = () => {
    const newAssigned = !allAssigned;
    setAllAssigned(newAssigned);

    if (newAssigned) {
      setValues({
        ...values,
        sender_ids: (members ?? []).map((member) => member.id),
        message_owner: (members ?? []).at(0)?.user_id ?? null,
      });
    } else {
      setValues({ ...values, sender_ids: [], message_owner: null });
    }
  };

  return (
    <FormGroup>
      <Label htmlFor="message_owner" className="mb-1 d-flex align-items-center">
        <div className="d-flex align-items-center gap-2">
          <FormattedMessage tagName="span" id="ChangeCase.author" />
          <div className="d-flex align-items-center gap-1">
            <Input
              id="OwnerOfMessageInputAllAssigned"
              className="mt-0"
              type="checkbox"
              onChange={handleChangeAllAssigned}
              checked={allAssigned}
            />
            <Label htmlFor="OwnerOfMessageInputAllAssigned" className="mb-0">
              <FormattedMessage tagName="span" id="ChangeCase.allAssigned" />
            </Label>
          </div>
        </div>
      </Label>
      <Field
        disabled={isLoading || allAssigned}
        required={true}
        options={options}
        component={FieldMultiSelect}
        isMulti={true}
        displayEmpty={true}
        id="sender_ids"
        name="sender_ids"
        defaultText={intl.formatMessage({
          id: 'ChangeCase.messageOwnerPlaceholder',
        })}
      />
    </FormGroup>
  );
};

export default OwnerOfMessageInput;
