import { faUnlink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useOrganizationUserActiveGrasps from 'api/queries/grasps/useOrganizationUserActiveGrasps';
import GraspLogo from 'components/GraspLogo';
import useRequiredParams from 'hooks/useRequiredParams';
import { GraspInfoMode, GraspModalActions } from 'interfaces/grasps';
import { Size } from 'interfaces/ui';
import { useState } from 'react';
import { Button } from 'reactstrap';
import { useBrowserState } from 'store/reducerHooks';
import ActionGraspModal from '../ActionGraspModal';
import { MultipleGraspRowPlaceholders } from './GraspRowPlaceholder';

type PatientGraspListProps = {
  patientId: string;
  mode: GraspInfoMode;
};

const PatientGraspList = ({ patientId, mode }: PatientGraspListProps) => {
  const isBrowser = useBrowserState();

  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const [graspIdToCompare, setGraspIdToCompare] = useState<string | undefined>(
    undefined
  );

  const toggleModal = () => setGraspIdToCompare(undefined);
  const isOpen = !!graspIdToCompare;

  const onGraspUnlink = (graspId: string) => {
    if (isBrowser) {
      setGraspIdToCompare(graspId);
    } else {
      window.dispatchEvent(
        new CustomEvent('grasp-unsharing-request', {
          detail: {
            personality_id: patientId,
            organization_id: organizationId,
            grasp_id: graspId,
          },
        })
      );
    }
  };

  const { data: personalityGraspData, isLoading: isPersonalityGraspLoading } =
    useOrganizationUserActiveGrasps({
      params: {
        organizationId,
        personalityId: patientId,
      },
    });

  if (isPersonalityGraspLoading)
    return <MultipleGraspRowPlaceholders numberOfPlaceholders={5} />;

  return (
    <>
      {isBrowser && (
        <ActionGraspModal
          action={GraspModalActions.UNSHARE}
          isOpen={isOpen}
          toggle={toggleModal}
          organizationId={organizationId}
          personalityId={patientId}
          graspIdToCompare={graspIdToCompare}
        />
      )}
      {personalityGraspData?.map((grasp) => {
        return (
          <li key={grasp.grasp_id} className="GraspList__item">
            <GraspLogo
              color={grasp.grasp_hex_color}
              size={Size.MEDIUM}
              width={68}
              height={54}
            />
            <div>
              <div className="GraspList__item__name GraspList__item__name--margin-left">
                {grasp.grasp_name}
              </div>
              <div className="GraspList__item__description">
                {grasp.grasp_firmware_version}
              </div>
            </div>
            {mode === GraspInfoMode.DOCTOR && (
              <Button
                className="btn-transparent ms-auto"
                onClick={() => onGraspUnlink(grasp.grasp_id)}
                aria-label="unlink_grasp"
              >
                <FontAwesomeIcon color="#646464" icon={faUnlink} />
              </Button>
            )}
          </li>
        );
      })}
    </>
  );
};

export default PatientGraspList;
