import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

//helpers
import useTimelineDetails from 'api/queries/timelines/useTimelineDetails';

//helpers
import { formatDate } from 'helpers/dates';
import { getUserId } from 'helpers/utils/auth';

//tabs
import AssessmentsForms, { DisplayingComponent } from './tabs/AssessmentForms';
import ObservationList from './tabs/ObservationList';
import Summary from './tabs/Summary';

//icons
import notepadIcon from 'assets/images/notepad.svg';
import TabContainer from 'components/Tabs/components/TabContainer';
import useCaseDetails from '../../../../../../../api/queries/cases/useCaseDetails';
import useUserPathBasedBrowsingContext from '../../../../../../../hooks/useUserPathBasedBrowsingContext';
import {
  useCaseFormsDayDetailsRefetchRequest,
  useTimelineDetailsSelectedDate,
} from 'store/reducerHooks';

type DayDetailsProps = {
  timelineId: string;
  selectedDates: {
    from: Date;
    to: Date;
  };
};

const DayDetails = ({ timelineId, selectedDates }: DayDetailsProps) => {
  const {
    organizationId = '',
    caseId = '',
    isOrganizationContext,
  } = useUserPathBasedBrowsingContext();

  const loggedUserId = getUserId();

  const { data: caseDetails } = useCaseDetails({
    params: { organizationId, caseId },
    options: { enabled: isOrganizationContext },
  });

  const { data: timelineDetails } = useTimelineDetails({
    params: { timelineId },
    options: { enabled: false },
  });

  const isOwnerOfTimeline = timelineDetails?.user_id === loggedUserId;

  const refetchRequest = useCaseFormsDayDetailsRefetchRequest();
  const selectedDate = useTimelineDetailsSelectedDate();

  const participantsUserId = useMemo(() => {
    if (caseDetails && caseDetails.participants.length > 0) {
      return caseDetails.participants[0].personality?.user_id;
    }

    return undefined;
  }, [caseDetails]);

  const selectedDateString = selectedDate
    ? formatDate(selectedDate?.toISOString(), {
        ignoreTime: true,
      })
    : '';

  const tabContainerItems = [
    {
      header: <FormattedMessage id="TimelineDetails.dayDetailsTabs.summary" />,
      component:
        selectedDate && timelineId ? (
          <Summary
            selectedDate={selectedDate}
            timelineId={timelineId}
            selectedDates={selectedDates}
          />
        ) : (
          <></>
        ),
    },
    {
      header: (
        <FormattedMessage id="TimelineDetails.dayDetailsTabs.assessmentForms" />
      ),
      component: selectedDate ? (
        <AssessmentsForms
          timelineId={timelineId}
          selectedDates={selectedDates}
          selectedDate={selectedDate}
          userId={
            participantsUserId
              ? participantsUserId
              : timelineDetails?.user_id ?? loggedUserId
          }
          refetchRequest={refetchRequest}
          displayingComponent={DisplayingComponent.OTHER}
        />
      ) : (
        <></>
      ),
    },
    {
      header: (
        <FormattedMessage id="TimelineDetails.dayDetailsTabs.observationList" />
      ),
      component: (
        <ObservationList
          isOwnerOfTimeline={isOwnerOfTimeline}
          selectedDates={selectedDates}
          timelineId={timelineId}
        />
      ),
    },
  ];

  return (
    <>
      <div className="cardContainer DayDetails h-100">
        <div className="DayDetails__container">
          <div className="cardContainer__header DayDetails__header">
            <div className="cardContainer__header__title">
              <img src={notepadIcon} alt={notepadIcon} />
              <FormattedMessage id={'TimelineDetails.dayDetails.header'} />
            </div>
            <div className="DayDetails__date DayDetails__date--black">
              {selectedDateString}
            </div>
          </div>
        </div>
        <div className="DayDetails__container">
          <TabContainer
            items={tabContainerItems}
            noMargin
            tabClassNames="DayDetails__tab-item"
          />
        </div>
      </div>
    </>
  );
};

export default DayDetails;
