import React from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import SVG from 'react-inlinesvg';
import assessmentCompleted from 'assets/images/assessment_completed.svg';
import completedIndicator from 'assets/images/assessment_forms_indicators/completed_indicator_big.svg';
import { FormattedMessage } from 'react-intl';

type ProjectAssessmentCompletedModalProps = {
  isOpen: boolean;
  toggle: () => void;
  title?: string;
};

const ProjectAssessmentCompletedModal = ({
  isOpen,
  toggle,
  title,
}: ProjectAssessmentCompletedModalProps) => {
  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      toggle={toggle}
      className="ProjectAssessmentCompleted__modal"
    >
      <ModalHeader
        className="ProjectAssessmentCompleted__modal__header"
        toggle={toggle}
      >
        <FormattedMessage id="ProjectSubmissions.AssessmentCompletedModal.thankYou" />
      </ModalHeader>
      <ModalBody className="ProjectAssessmentCompleted__modal__body">
        <div className="ProjectAssessmentCompleted__modal__image">
          <div className="ProjectAssessmentCompleted__modal__image__ellipse-blue"></div>
          <div className="ProjectAssessmentCompleted__modal__image__logo">
            <SVG src={assessmentCompleted} title="Grasp logo" />
            <div className="ProjectAssessmentCompleted__modal__image__logo__ellipse-gray"></div>
          </div>
        </div>
        <div className="ProjectAssessmentCompleted__modal__desc">
          <SVG src={completedIndicator} title="completed" />
          <span>
            <FormattedMessage id="ProjectSubmissions.AssessmentCompletedModal.youHaveFilledForm" />{' '}
            {title}
          </span>
        </div>
        <Button
          className="ProjectAssessmentCompleted__modal__accept"
          color="primary"
          onClick={toggle}
        >
          <FormattedMessage id="ProjectSubmissions.AssessmentCompletedModal.ok" />
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default ProjectAssessmentCompletedModal;
