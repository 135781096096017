import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useInvalidLayout } from './hooks/useInvalidLayout';

type DashboardLayoutValidatorProps = {
  children: JSX.Element;
};

const DashboardLayoutValidator = ({
  children,
}: DashboardLayoutValidatorProps) => {
  const isLayoutInvalid = useInvalidLayout();

  if (isLayoutInvalid)
    return <FormattedMessage id="Dashboards.InvalidLayout" />;

  return children;
};

export default DashboardLayoutValidator;
