import classNames from 'classnames';
import FieldInput from 'components/FieldInput';
import FieldMultiSelect from 'components/FieldMultiSelect';
import FieldSelect from 'components/FieldSelect';
import FieldTextarea from 'components/FieldTextarea';
import FormikErrorMessage from 'components/FormikErrorMessage';
import { Field } from 'formik';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormGroup, Label } from 'reactstrap';

type GenerateReportModalGenericInputOptions = {
  text: string;
  value: any;
};

type GenerateReportModalMultiSelectOptions = {
  label: string;
  value: string;
};

type GenerateReportModalGenericInputProps = {
  labelTranslationId?: string;
  name: string;
  id: string;
  required?: boolean;
  disabled?: boolean;
  options?:
    | GenerateReportModalGenericInputOptions[]
    | GenerateReportModalMultiSelectOptions[];
  placeholderTranslationId?: string;
  type?: string;
  value?: any;
  isMulti?: boolean;
  defaultChecked?: boolean;
  isLoading?: boolean;
  className?: string;
  component:
    | typeof FieldInput
    | typeof FieldSelect
    | typeof FieldTextarea
    | typeof FieldMultiSelect;
};

const GenerateReportModalGenericInput = ({
  id,
  name,
  labelTranslationId = undefined,
  required = false,
  disabled = false,
  isMulti = false,
  isLoading = false,
  defaultChecked = false,
  options = undefined,
  placeholderTranslationId = undefined,
  type = undefined,
  value = undefined,
  className = undefined,
  component,
}: GenerateReportModalGenericInputProps) => {
  const intl = useIntl();

  return (
    <FormGroup check={type === 'radio' || type === 'checkbox'}>
      {labelTranslationId && type !== 'radio' && type !== 'checkbox' && (
        <Label htmlFor={id}>
          <FormattedMessage id={labelTranslationId} />
        </Label>
      )}
      {type !== undefined ? (
        <Field
          disabled={disabled}
          required={required}
          isMulti={isMulti}
          isLoading={isLoading}
          className={classNames(className)}
          type={type}
          defaultValue={value}
          defaultChecked={defaultChecked}
          options={options ?? []}
          id={id}
          name={name}
          placeholder={
            placeholderTranslationId !== undefined
              ? intl.formatMessage({
                  id: placeholderTranslationId,
                })
              : undefined
          }
          component={component}
        />
      ) : (
        <Field
          disabled={disabled}
          required={required}
          isMulti={isMulti}
          isLoading={isLoading}
          defaultValue={value}
          defaultChecked={defaultChecked}
          options={options ?? []}
          id={id}
          name={name}
          placeholder={
            placeholderTranslationId !== undefined
              ? intl.formatMessage({
                  id: placeholderTranslationId,
                })
              : undefined
          }
          component={component}
        />
      )}
      {labelTranslationId && (type === 'radio' || type === 'checkbox') && (
        <Label check htmlFor={id}>
          <FormattedMessage id={labelTranslationId} />
        </Label>
      )}
      <FormikErrorMessage name={name} />
    </FormGroup>
  );
};

export default GenerateReportModalGenericInput;
