import { setPassword } from 'api/membership';
import { arePasswordsEqual } from 'helpers/utils/validators';
import {
  displayErrorArrayNotifications,
  displayErrorNotification,
  displaySuccessNotification,
} from 'modules/Notifications';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

type SetMutationPasswordParams = {
  password: string;
  passwordRepeat: string;
  token: string;
};

function useSetPassword() {
  return useMutationWithResultNotification({
    asyncApiFunction: async ({
      password,
      passwordRepeat,
      token,
    }: SetMutationPasswordParams) => {
      if (arePasswordsEqual(password, passwordRepeat)) {
        await setPassword({ password, password_reset_token: token });
      } else throw Error('different_password');
    },
    resetOnResult: true,
    onSuccess: () => {
      displaySuccessNotification('SetPassword.notifications.success');
    },
    onError: (err) => {
      if (err.message === 'different_password') {
        displayErrorNotification(
          'Register.notifications.errors.different_passwords'
        );
      }
    },
    onErrorArray: (errorArray) => {
      return displayErrorArrayNotifications(
        errorArray,
        'SetPassword.notifications.errors'
      );
    },
  });
}

export default useSetPassword;
