import { useCallback, useState } from 'react';
import { useRegisterEntry } from '../../../../../store/hooks';
import { DashboardDataActionResolvers } from '../store/actionResolvers';
import { DashboardDataActions } from '../store/actions';
import { DashboardDataStates } from '../store/state';

export const useDashboardDataReducer = (
  initialDashboardDataState: DashboardDataStates.DashboardDataState
): [
  value: DashboardDataStates.DashboardDataState,
  dispatch: (action: DashboardDataActions.DashboardDataAction) => void
] => {
  const registerEntry = useRegisterEntry();

  const [stateData, setStateData] =
    useState<DashboardDataStates.DashboardDataState>(
      () => initialDashboardDataState
    );

  const dispatch = useCallback(
    (action: DashboardDataActions.DashboardDataAction) => {
      switch (action.type) {
        case DashboardDataActions.DashboardDataActionType.EditTemplate:
          setStateData((prev) =>
            DashboardDataActionResolvers.EditTemplateResolver(
              prev,
              action,
              registerEntry
            )
          );
          break;
        case DashboardDataActions.DashboardDataActionType.EditDatePeriod:
          setStateData((prev) =>
            DashboardDataActionResolvers.EditDatePeriodResolver(
              prev,
              action,
              registerEntry
            )
          );
          break;
        case DashboardDataActions.DashboardDataActionType.EditDateRange:
          setStateData((prev) =>
            DashboardDataActionResolvers.EditDateRangeResolver(
              prev,
              action,
              registerEntry
            )
          );
      }
    },
    [registerEntry]
  );

  return [stateData, dispatch];
};
