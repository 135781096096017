import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import * as d3 from 'd3';

type ChartItem = {
  value: number;
  name: string;
};

type CustomBarChartProps = {
  colors?: string[];
  items: ChartItem[];
  total: number;
  width: number;
  height: number;
  sortByBiggest?: boolean;
  isEmpty?: boolean;
  hideLabel?: boolean;
  ignoreMaximumWidthConstraint?: boolean;
};

const labelSize = 100;
const defaultColors = ['#ACACAC'];

const CustomBarChart = ({
  width,
  height,
  items,
  colors = defaultColors,
  isEmpty,
  hideLabel = false,
  total,
  ignoreMaximumWidthConstraint = true,
}: CustomBarChartProps) => {
  const svgRef = useRef<SVGSVGElement | null>(null);

  const maximumValue = useMemo(() => {
    if (total) return total;
    const values = items.map((item) => item.value);
    return Math.max(...values);
  }, [items, total]);

  const chartWidth = hideLabel ? width : width - labelSize;

  const yScaleFunction = d3
    .scaleBand()
    .range([0, height])
    .domain(
      items.map((d) => {
        return d.name;
      })
    )
    .padding(0.33);

  const xScaleFunction = d3
    .scaleLinear()
    .domain([0, maximumValue])
    .range([10, isEmpty ? 10 : chartWidth]);

  const renderChartFunction = useCallback(
    (svg: d3.Selection<SVGSVGElement, any, any, any>) => {
      svg.selectAll('*').remove();

      const specifyBarChartWidth = (d: ChartItem) => {
        if (
          ignoreMaximumWidthConstraint ||
          xScaleFunction(d.value) < width - (labelSize + 10)
        ) {
          return xScaleFunction(d.value);
        }

        return width - (labelSize + 10);
      };

      svg
        .selectAll('rect')
        .data(items)
        .enter()
        .append('rect')
        .attr('x', xScaleFunction(0))
        .attr('y', (d) => yScaleFunction(d.name) as any)
        .attr('rx', 5)
        .attr('width', specifyBarChartWidth)
        .attr('height', yScaleFunction.bandwidth())
        .attr('fill', (d, i) => colors[i]);
    },
    [
      xScaleFunction,
      yScaleFunction,
      colors,
      items,
      width,
      ignoreMaximumWidthConstraint,
    ]
  );

  const drawY = items.map((item) => {
    let scaledValue = yScaleFunction(item.name);

    const top = (scaledValue || 0) - 3 + 'px';

    return (
      <div
        className="d-flex justify-content-between"
        key={`y-axis-${item.name}`}
        style={{
          position: 'absolute',
          left: 0,
          top,
          width: `${labelSize}px`,
        }}
      >
        <span>{item.name}</span>
        <span>{item.value}</span>
      </div>
    );
  });

  useEffect(() => {
    if (svgRef.current) {
      renderChartFunction(d3.select(svgRef.current));
    }
  }, [renderChartFunction]);

  return (
    <div className="d-flex">
      {!hideLabel && (
        <div
          style={{
            width: `${labelSize}px`,
            height: height + 'px',
            position: 'relative',
            fontSize: '12px',
            textAlign: 'right',
          }}
        >
          {drawY}
        </div>
      )}
      <svg ref={svgRef} width={chartWidth} height={height} />
    </div>
  );
};

export default CustomBarChart;
