import { IGroupFeedback } from '../../../../../../../interfaces/caseGroups';
import { useCallback } from 'react';
import { ConversationTemplate } from '../../../../../../../interfaces/conversations';
import { InputOption } from '../../../../../../../interfaces/ui';

export const useTemplatesBasedOnGroupFeedbackOptions = () => {
  return useCallback(
    (
      groupFeedback: IGroupFeedback[] | undefined,
      conversationTemplates: ConversationTemplate[] | undefined
    ) => {
      if (!conversationTemplates || !groupFeedback) return [];

      let templatesOptions: InputOption[] = [];

      groupFeedback.forEach((feedback) => {
        const template = conversationTemplates.find(
          (p) => p.id === feedback.conversationTemplateId
        );

        if (template === undefined) return;

        templatesOptions.push({
          value: feedback.conversationTemplateId,
          text: template.display_code,
        });
      });

      return templatesOptions;
    },
    []
  );
};
