import React from 'react';
import { InputType } from 'reactstrap/types/lib/Input';
import { InputOption } from '../../../../interfaces/ui';
import EditableInput from '../../../EditableInput/EditableInput';
import InputFieldWithImage, {
  InputFieldWithImageColor,
} from '../../../InputFieldWithImage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/pro-solid-svg-icons';
import useOpen from '../../../../hooks/useOpen';
import { ISinglePropertyEditModalProps } from '../../../../interfaces/modals';
import { GraspColors, graspColorsValues } from '../../../../helpers/colors';

type ThinHeaderInputProps = {
  ExternalEditComponent?: React.ComponentType<ISinglePropertyEditModalProps>;
  inputType: InputType;
  inputChecked?: boolean;
  inputOptions?: InputOption[];
  name: string;
  value: any;
  onEdit: (target: string, value: string) => void;
};

const ThinHeaderInput = ({
  ExternalEditComponent,
  inputType,
  inputOptions,
  inputChecked,
  onEdit,
  value,
  name,
}: ThinHeaderInputProps) => {
  const { isOpen: externalEditOpen, toggle: toggleExternalEdit } =
    useOpen(false);

  if (ExternalEditComponent) {
    return (
      <>
        <ExternalEditComponent
          name={name}
          value={value}
          onEdit={onEdit}
          toggle={toggleExternalEdit}
          isOpen={externalEditOpen}
        />
        <InputFieldWithImage
          noMargin={true}
          color={InputFieldWithImageColor.gray}
          image={<></>}
          labelTranslationId=""
          appendGroupContent={
            <FontAwesomeIcon
              icon={faPencil}
              onClick={toggleExternalEdit}
              color={graspColorsValues[GraspColors.BLUE]}
            />
          }
          inputId={name}
        >
          <EditableInput
            type={inputType}
            options={inputOptions}
            value={value}
            onEdit={onEdit}
            name={name}
            debounceEnabled={false}
          />
        </InputFieldWithImage>
      </>
    );
  }

  return (
    <div>
      <EditableInput
        type={inputType}
        options={inputOptions}
        value={value}
        onEdit={onEdit}
        checked={inputChecked}
        name={name}
        debounceEnabled={false}
      />
    </div>
  );
};

export default ThinHeaderInput;
