import { putOrganizationPatientMembersAccess } from 'api/patients';
import { MemberWithAccess } from 'interfaces/personalities';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';

function useModifyPatientMemberAccess(
  organizationId: string,
  participantPersonalityId: string,
  successFb?: () => void
) {
  return useMutationWithResultNotification({
    asyncApiFunction: async (body: MemberWithAccess[]) => {
      await putOrganizationPatientMembersAccess(
        organizationId,
        participantPersonalityId,
        body
      );
    },
    resetOnResult: true,
    onSuccess: () => {
      successFb && successFb();
    },
  });
}

export default useModifyPatientMemberAccess;
