import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { ITabContainerItem } from '../../../components/Tabs/types/Tabs';
import EducationMaterialCategoriesTab from '../components/EducationMaterialCategoriesTab';
import EducationMaterialsTab from '../components/EducationMaterialsTab';

export enum EducationTabsValues {
  EducationMaterials = 'EducationMaterials',
  EducationMaterialsCategories = 'EducationMaterialsCategories',
}

export const mappedEducationTabsValues = [
  EducationTabsValues.EducationMaterials,
  EducationTabsValues.EducationMaterialsCategories,
];

export const useTabs = (
  innerTitle: boolean = true,
  innerSubtitle: boolean = true
) => {
  const [selectedTab, setSelectedTab] = useState<EducationTabsValues>(
    EducationTabsValues.EducationMaterials
  );

  const shouldBeRendered = useCallback(
    (tab: EducationTabsValues) => {
      return selectedTab === tab;
    },
    [selectedTab]
  );

  const tabItems: ITabContainerItem[] = useMemo(() => {
    return [
      {
        header: <FormattedMessage id="Education.educationMaterials" />,
        component: (
          <EducationMaterialsTab
            showTitle={innerTitle}
            showSubtitle={innerSubtitle}
          />
        ),
        shouldBeRendered: shouldBeRendered(
          EducationTabsValues.EducationMaterials
        ),
      },
      {
        header: <FormattedMessage id="Education.educationMaterialCategories" />,
        component: (
          <EducationMaterialCategoriesTab
            showTitle={innerTitle}
            showSubtitle={innerSubtitle}
          />
        ),
        shouldBeRendered: shouldBeRendered(
          EducationTabsValues.EducationMaterialsCategories
        ),
      },
    ];
  }, [innerSubtitle, innerTitle, shouldBeRendered]);

  const onTabChange = (val: number) => {
    setSelectedTab(mappedEducationTabsValues[val]);
  };

  return {
    onTabChange,
    tabItems,
  };
};
