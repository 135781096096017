import trendsChartPlaceholder from '../../../../../../../assets/images/timeline_details/trends_chart_placeholder.png';
import { FormattedMessage } from 'react-intl';
import React from 'react';

const BuddyCaseHourByHourChartNoDataPlaceholder = () => {
  return (
    <div className="TrendsChartPlaceholder" style={{ border: 0 }}>
      <div className="TrendsChartPlaceholder__image">
        <img src={trendsChartPlaceholder} alt="placeholder_image" />
      </div>
      <span className="TrendsChartPlaceholder__no-data">
        <FormattedMessage id="BuddyCase.chartNoData" />
      </span>
    </div>
  );
};

export default BuddyCaseHourByHourChartNoDataPlaceholder;
