import { faExclamationTriangle } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useCaseDetails from 'api/queries/cases/useCaseDetails';
import useOrganizationUserActiveGrasps from 'api/queries/grasps/useOrganizationUserActiveGrasps';
import useOrganizationPatientDetails from 'api/queries/patients/useOrganizationPatientDetails';
import GraspLogo from 'components/GraspLogo';
import PersonalityImage from 'components/PersonalityImage';
import { iconColors } from 'helpers/colors';
import { formatDate } from 'helpers/dates';
import { CasesParticipantFeedbackStatus } from 'interfaces/cases';
import { Size } from 'interfaces/ui';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { Col, Row, Spinner } from 'reactstrap';

type GraspSyncInfoProps = {
  organizationId: string;
  personalityId: string;
  caseId: string;
};

const renderSyncIcon = (status: CasesParticipantFeedbackStatus) => {
  let color = iconColors.normal;

  switch (status) {
    case CasesParticipantFeedbackStatus.Missing:
    case CasesParticipantFeedbackStatus.Overdue:
      color = iconColors.delete;
      break;
    case CasesParticipantFeedbackStatus.NotSpecified:
    case CasesParticipantFeedbackStatus.UpToDate:
    case CasesParticipantFeedbackStatus.AlmostUpToDate:
      color = iconColors.normal;
  }

  return <FontAwesomeIcon icon={faExclamationTriangle} color={color} />;
};

const GraspSyncInfo = ({
  organizationId,
  personalityId,
  caseId,
}: GraspSyncInfoProps) => {
  const { data: patientDetailsData } = useOrganizationPatientDetails({
    params: {
      organizationId,
      personalityId,
    },
  });

  const { data: caseData } = useCaseDetails({
    params: { organizationId, caseId },
    options: { enabled: false },
  });

  const { data: graspData, isLoading: isGraspLoading } =
    useOrganizationUserActiveGrasps({
      params: { organizationId, personalityId },
    });

  const renderGraspSync = useCallback(() => {
    if (graspData && !isGraspLoading && graspData.length === 0)
      return (
        <Row>
          <Col md={12} className="StatusCheck__info__no-data">
            <FormattedMessage id="PatientCheck.noGraspAssigned" />
          </Col>
        </Row>
      );

    return graspData?.map((graspData) => {
      return (
        <Row
          key={`${graspData.grasp_id}|${graspData.grasp_organization_id}|${graspData.user_id}`}
          className="mt-2"
        >
          <Col sm={12} className="d-flex justify-content-center">
            <div className="StatusCheck__info__column">
              <div className="StatusCheck__info__column__icon">
                <GraspLogo
                  color={graspData.grasp_hex_color}
                  size={Size.SMALL}
                />
              </div>
              <div className="StatusCheck__info__column__content">
                <div className="StatusCheck__info__column__content__text">
                  <FormattedMessage id="PatientCheck.graspShare" />
                </div>
                <div className="StatusCheck__info__column__content__text">
                  {graspData.grasp_name}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      );
    });
  }, [graspData, isGraspLoading]);

  if (!graspData || !patientDetailsData)
    return (
      <div className="w-100 mb-2 text-center">
        <Spinner />
      </div>
    );

  return (
    <Row className="StatusCheck__info">
      <Col sm={4} className="d-flex justify-content-center">
        <div className="StatusCheck__info__column">
          <div className="StatusCheck__info__column__icon">
            <PersonalityImage />
          </div>
          <div className="StatusCheck__info__column__content">
            <div className="StatusCheck__info__column__content__text">
              {`${patientDetailsData.first_name} ${patientDetailsData.last_name}`}
            </div>
            <div className="StatusCheck__info__column__content__text StatusCheck__info__column__content__text--number">
              #{patientDetailsData.number}
            </div>
          </div>
        </div>
      </Col>
      <Col sm={4}>{renderGraspSync()}</Col>
      <Col sm={4} className="d-flex justify-content-center">
        <div className="StatusCheck__info__column">
          <div>
            {caseData?.participant_last_synchronization_date &&
              renderSyncIcon(caseData?.participant_synchronization_status)}
          </div>
          <div className="StatusCheck__info__column__content">
            <div>
              <FormattedMessage id="PatientCheck.lastDataSynchronization" />
            </div>
            <div>
              {caseData?.participant_last_synchronization_date
                ? formatDate(caseData?.participant_last_synchronization_date)
                : ''}
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default GraspSyncInfo;
