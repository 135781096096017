import QueryKeys from 'api/queries/queryKeys';
import { getOrganizationSubmissionsWithPaging } from 'api/submissions';
import {
  convertIntoObjectWithPageInfo,
  returnAllDataFromResponse,
} from 'helpers/utils/reactQueryHelpers';
import { IError, IInfiniteResult } from 'interfaces/response';
import { useInfiniteQuery } from 'react-query';

import {
  IOrganizationUserSubmission,
  IProjectSubmissionFilters,
} from 'interfaces/submissions';
import {
  OrganizationQueryParams,
  SearchAndPagingQueryParams,
} from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

interface UseInfiniteProjectSubmissionsParams
  extends OrganizationQueryParams,
    SearchAndPagingQueryParams {
  filters: IProjectSubmissionFilters | null;
}

function useInfiniteProjectSubmissions({
  params,
  options,
}: IQueryRequest<UseInfiniteProjectSubmissionsParams>) {
  const { organizationId, searchValue, pageSize = 20, filters } = params;
  const response = useInfiniteQuery<
    IInfiniteResult<IOrganizationUserSubmission[]>,
    IError
  >({
    ...options,
    queryKey: [
      QueryKeys.getInfiniteProjectUsers,
      organizationId,
      searchValue,
      filters,
    ],
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getOrganizationSubmissionsWithPaging(
        organizationId,
        {
          page: pageParam,
          page_size: pageSize,
          search: searchValue,
        },
        filters
      );

      return convertIntoObjectWithPageInfo<IOrganizationUserSubmission[]>(data);
    },
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage;
    },
    getPreviousPageParam: (firstPage) => firstPage.previousPage,
  });

  return {
    ...response,
    totalItemsCount: response.data?.pages[0].totalItemsCount ?? 0,
    submissions:
      returnAllDataFromResponse<IOrganizationUserSubmission>(response),
  };
}

export default useInfiniteProjectSubmissions;
