import _, { cloneDeep } from 'lodash';
import { AwareSessionFilterQueryParams } from '../../../../../../../../../api/queries/awareSessions/useOrganizationAwareSessions';
import { IAwareSessionData } from '../../../../../../../../../interfaces/awareSessions';
import {
  ClosedListValidConversationQuestion,
  ConversationQuestion,
  ValidConversationQuestionType,
} from '../../../../../../../../../interfaces/conversations';
import {
  DashboardFilter,
  DashboardFilterType,
  IClosedListDashboardFilter,
  IGraspDashboardFilter,
  IMemberDashboardFilter,
  IMemberDashboardReport,
  IOrganizationDashboardFilter,
  IRangeDashboardFilter,
  ISourcePeriodDashboardFilter,
} from '../../../../../../../../../interfaces/dashboards';
import {
  IReferenced,
  ITotalRowsPage,
} from '../../../../../../../../../interfaces/response';
import { DashboardDefaults } from '../../../defaults';
import { DashboardSelectionHelpers } from '../../../helpers/selection';
import {
  IGraspRelated,
  IMemberRelated,
  IOrganizationRelated,
} from '../../../types';
import { VisualizationDataContextType } from '../components/Visualizations/types';

export namespace DashboardLayoutFilterHelpers {
  export const ApplyNotSpecifiedToAwareSessionData = (
    data: IReferenced<ITotalRowsPage<IAwareSessionData>> | undefined,
    notSpecified: string
  ): IReferenced<ITotalRowsPage<IAwareSessionData>> => {
    if (!data)
      return {
        current: { data: [], total_rows_count: 0 },
        reference: { data: [], total_rows_count: 0 },
      };

    let dataCopy = cloneDeep(data);

    dataCopy.current = _applyNotSpecified(dataCopy.current, notSpecified);
    dataCopy.reference = _applyNotSpecified(dataCopy.reference, notSpecified);

    return dataCopy;
  };

  export const ApplyFilters = (
    data: VisualizationDataContextType | undefined,
    filters: DashboardFilter[],
    questions: ConversationQuestion[],
    notSpecified: string
  ): VisualizationDataContextType => {
    if (!data) {
      return {
        sessions: _getEmptyReferencedObject(),
        reports: _getEmptyReferencedObject(),
        items: _getEmptyReferencedObject(),
      };
    }

    let _data: VisualizationDataContextType = cloneDeep(data);
    const globalSelected = DashboardSelectionHelpers.IsGlobalSelected(filters);

    if (globalSelected) return _data;

    const currentFiltersApplied = _applyFilters(
      filters,
      {
        sessions: _data.sessions.current,
        reports: _data.reports.current,
      },
      questions,
      notSpecified
    );

    _data.sessions.current = currentFiltersApplied.sessions;
    _data.reports.current = currentFiltersApplied.reports;

    const referenceFiltersApplied = _applyFilters(
      filters,
      {
        sessions: _data.sessions.reference,
        reports: _data.reports.reference,
      },
      questions,
      notSpecified
    );

    _data.sessions.reference = referenceFiltersApplied.sessions;
    _data.reports.reference = referenceFiltersApplied.reports;

    return {
      sessions: _data.sessions,
      reports: _data.reports,
      items: _data.items,
    };
  };

  const _applyFilters = (
    filters: DashboardFilter[],
    dataRow: {
      sessions: ITotalRowsPage<IAwareSessionData>;
      reports: ITotalRowsPage<IMemberDashboardReport>;
    },
    questions: ConversationQuestion[],
    notSpecified: string
  ) => {
    filters.forEach((filter) => {
      if (filter.type === DashboardFilterType.ClosedList) {
        dataRow.sessions.data = dataRow.sessions.data.filter((session) =>
          FilterUsingClosedList(session, filter, questions, notSpecified)
        );
        return;
      }

      if (filter.type === DashboardFilterType.Grasp) {
        dataRow.sessions.data = dataRow.sessions.data.filter((session) =>
          FilterUsingGrasp(session, filter)
        );
        return;
      }

      if (filter.type === DashboardFilterType.Member) {
        dataRow.sessions.data = dataRow.sessions.data.filter((session) =>
          FilterUsingMember(session, filter)
        );

        dataRow.reports.data = dataRow.reports.data.filter((report) =>
          FilterUsingMember(report, filter)
        );
        return;
      }

      if (filter.type === DashboardFilterType.Organization) {
        dataRow.sessions.data = dataRow.sessions.data.filter((session) =>
          FilterUsingOrganization(session, filter)
        );

        dataRow.reports.data = dataRow.reports.data.filter((report) =>
          FilterUsingOrganization(report, filter)
        );
        return;
      }

      if (filter.type === DashboardFilterType.SourcePeriod) {
        dataRow.reports.data = dataRow.reports.data.filter((report) =>
          FilterUsingSourcePeriod(report, filter)
        );
        return;
      }

      if (filter.type === DashboardFilterType.Range) {
        dataRow.sessions.data = dataRow.sessions.data.filter((session) =>
          FilterUsingRange(session, filter)
        );
      }
    });

    return dataRow;
  };

  const _getEmptyReferencedObject = () => {
    return cloneDeep({
      current: { data: [], total_rows_count: 0 },
      reference: { data: [], total_rows_count: 0 },
    });
  };

  const _applyNotSpecified = (
    data: ITotalRowsPage<IAwareSessionData>,
    notSpecified: string
  ) => {
    data.data = data.data.map((item) => ({
      ...item,
      questions: item.questions.map((question) => ({
        ...question,
        value:
          question.value !== null && question.value !== ''
            ? question.value
            : notSpecified,
      })),
    }));

    return data;
  };

  const FilterUsingClosedList = (
    session: IAwareSessionData,
    filter: IClosedListDashboardFilter,
    questions: ConversationQuestion[],
    notSpecified: string
  ): boolean => {
    const emptySelection =
      DashboardSelectionHelpers.SelectionCount([filter]) === 0;

    if (emptySelection) return true;

    const selectedAnswerIds = filter.options
      .filter((p) => p.selected)
      .map((p) => p.id);

    const itemsQuestion = session.questions.find((p) => p.id === filter.id);

    if (!itemsQuestion) return true;

    const existingQuestion = questions.find(
      (p) =>
        p.resource_id === filter.id &&
        p.content.type === ValidConversationQuestionType.ClosedList
    );

    const existingClosedList = existingQuestion?.content as
      | ClosedListValidConversationQuestion
      | undefined;

    let questionAnswers = cloneDeep(existingClosedList?.answers ?? []);

    questionAnswers.push({
      is_selected: false,
      title: notSpecified,
      guid: notSpecified,
      sequence: 0,
    });

    const selectedQuestionAnswers = questionAnswers.filter((p) =>
      selectedAnswerIds.includes(p.guid)
    );

    const selectedQuestionAnswerIds = selectedQuestionAnswers.map(
      (p) => p.guid
    );

    const selectedQuestionAnswerValues = selectedQuestionAnswers.map(
      (p) => p.title
    );

    return (
      selectedQuestionAnswerValues.includes(itemsQuestion.value) ||
      selectedQuestionAnswerIds.includes(itemsQuestion.value)
    );
  };

  const FilterUsingRange = (
    session: IAwareSessionData,
    filter: IRangeDashboardFilter
  ): boolean => {
    const emptySelection =
      DashboardSelectionHelpers.SelectionCount([filter]) === 0;

    if (emptySelection) return true;

    const min = filter.options?.min_number_value ?? 0;
    const max = filter.options?.max_number_value ?? 100;

    const itemsQuestion = session.questions.find((p) => p.id === filter.id);

    if (!itemsQuestion) return true;

    const numberValue = parseFloat(itemsQuestion.value);

    return numberValue >= min && numberValue <= max;
  };

  const FilterUsingGrasp = (
    entry: IGraspRelated,
    filter: IGraspDashboardFilter
  ): boolean => {
    const emptySelection =
      DashboardSelectionHelpers.SelectionCount([filter]) === 0;

    if (emptySelection) return true;

    const grasps = filter.options.filter((p) => p.selected).map((p) => p.id);
    return grasps.includes(entry.grasp_id);
  };

  const FilterUsingMember = (
    entry: IMemberRelated,
    filter: IMemberDashboardFilter
  ): boolean => {
    const emptySelection =
      DashboardSelectionHelpers.SelectionCount([filter]) === 0;

    if (emptySelection) return true;

    const selectedMembers = filter.options
      .filter((p) => p.selected)
      .map((p) => p.id);

    return selectedMembers.includes(entry.member_id);
  };

  const FilterUsingOrganization = (
    entry: IOrganizationRelated,
    filter: IOrganizationDashboardFilter
  ): boolean => {
    const emptySelection =
      DashboardSelectionHelpers.SelectionCount([filter]) === 0;
    if (emptySelection) return true;

    const selectedMembers = filter.options
      .filter((p) => p.selected)
      .map((p) => p.id);

    return selectedMembers.includes(entry.organization_id);
  };

  const FilterUsingSourcePeriod = (
    entry: IMemberDashboardReport,
    filter: ISourcePeriodDashboardFilter
  ): boolean => {
    const emptySelection =
      DashboardSelectionHelpers.SelectionCount([filter]) === 0;

    if (emptySelection) return true;

    const selectedPeriods = filter.options
      .filter((p) => p.selected)
      .map((p) => p.id);

    return selectedPeriods.includes(entry.source_period);
  };

  export const GetEmptyQueryFilters = (): AwareSessionFilterQueryParams => {
    return {
      organization_id: undefined,
      member_id: undefined,
      questions: undefined,
    };
  };

  export const GenerateFilterParams = (
    totalRows: number | undefined,
    currentFilters: AwareSessionFilterQueryParams | null,
    filters: DashboardFilter[],
    questions: ConversationQuestion[]
  ): AwareSessionFilterQueryParams => {
    if (currentFilters === null) return GetEmptyQueryFilters();

    if (totalRows === undefined) return currentFilters;

    if (DashboardSelectionHelpers.IsGlobalSelected(filters))
      return GetEmptyQueryFilters();

    if (totalRows >= DashboardDefaults.MAX_FETCHING_DATA_LIMIT)
      return generateBasedOnDashboardFilters(filters, questions);

    if (isClientFilteringEnabled(currentFilters, filters, questions))
      return currentFilters;

    return generateBasedOnDashboardFilters(filters, questions);
  };

  const isClientFilteringEnabled = (
    currentFilters: AwareSessionFilterQueryParams,
    filters: DashboardFilter[],
    questions: ConversationQuestion[]
  ): boolean => {
    const generatedFilters = generateBasedOnDashboardFilters(
      filters,
      questions
    );

    return (
      isOrganizationClientFilteringEnabled(currentFilters, generatedFilters) &&
      isMemberClientFilteringEnabled(currentFilters, generatedFilters) &&
      isQuestionClientFilteringEnabled(currentFilters, generatedFilters)
    );
  };

  const isOrganizationClientFilteringEnabled = (
    currentFilters: AwareSessionFilterQueryParams,
    generatedFilters: AwareSessionFilterQueryParams
  ): boolean => {
    if (currentFilters.organization_id === undefined) return true;
    if (generatedFilters.organization_id === undefined) return false;

    return _.isEqual(
      currentFilters.organization_id,
      generatedFilters.organization_id
    );
  };

  const isMemberClientFilteringEnabled = (
    currentFilters: AwareSessionFilterQueryParams,
    generatedFilters: AwareSessionFilterQueryParams
  ): boolean => {
    if (currentFilters.member_id === undefined) return true;
    if (generatedFilters.member_id === undefined) return false;

    return _.isEqual(currentFilters.member_id, generatedFilters.member_id);
  };

  const isQuestionClientFilteringEnabled = (
    currentFilters: AwareSessionFilterQueryParams,
    generatedFilters: AwareSessionFilterQueryParams
  ): boolean => {
    if (currentFilters.questions === undefined) return true;
    if (generatedFilters.questions === undefined) return false;

    const generatedQuestions = generatedFilters.questions.map((p) =>
      p.split(',')
    );

    const currentQuestions = currentFilters.questions.map((p) => p.split(','));

    if (generatedQuestions.length < currentQuestions.length) return false;

    for (const currentQuestion of currentQuestions) {
      const generatedData = generatedQuestions.find(
        (p) => p.at(0) === currentQuestion[0]
      );

      if (!generatedData) return false;
      if (_.isEqual(currentQuestion, generatedData)) continue;
      return false;
    }

    return true;
  };

  const generateBasedOnDashboardFilters = (
    filters: DashboardFilter[],
    questions: ConversationQuestion[]
  ): AwareSessionFilterQueryParams => {
    return {
      organization_id: generateOrganizationFilterParams(filters),
      member_id: generateMemberFilterParams(filters),
      questions: generateQuestionFilterParams(filters, questions),
    };
  };

  const generateOrganizationFilterParams = (filters: DashboardFilter[]) => {
    const filter = filters.find(
      (p) => p.type === DashboardFilterType.Organization
    );

    if (!filter) return;
    const organizationFilter = filter as IOrganizationDashboardFilter;
    const organizations = organizationFilter.options
      .filter((p) => p.selected)
      .map((p) => p.id);

    if (organizations.length === 0) return undefined;
    return organizations;
  };

  const generateMemberFilterParams = (filters: DashboardFilter[]) => {
    const filter = filters.find((p) => p.type === DashboardFilterType.Member);

    if (!filter) return;
    const memberFilter = filter as IMemberDashboardFilter;
    const members = memberFilter.options
      .filter((p) => p.selected)
      .map((p) => p.id);

    if (members.length === 0) return undefined;
    return members;
  };

  const generateQuestionFilterParams = (
    filters: DashboardFilter[],
    questions: ConversationQuestion[]
  ) => {
    const dashboardFilters = filters.filter(
      (p) => p.type === DashboardFilterType.ClosedList
    );

    const questionsItems: string[] = [];

    dashboardFilters.forEach((dashboardFilter) => {
      const closedListFilter = dashboardFilter as IClosedListDashboardFilter;

      const selectedOptions = closedListFilter.options
        .filter((p) => p.selected)
        .map((p) => p.id);

      if (selectedOptions.length === 0) return;

      const selectedOptionsNames: string[] = [];

      selectedOptions.forEach((option) => {
        const question = questions.find(
          (p) => p.resource_id === closedListFilter.id
        );
        if (
          !question ||
          question.content.type !== ValidConversationQuestionType.ClosedList
        )
          return;

        const questionOption = question.content.answers.find(
          (p) => p.guid === option
        );

        if (!questionOption) return;
        selectedOptionsNames.push(questionOption.title);
      });

      questionsItems.push(
        [closedListFilter.id, ...selectedOptionsNames].join(',')
      );
    });

    if (questionsItems.length === 0) return undefined;
    return questionsItems;
  };
}
