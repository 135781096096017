import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage } from 'react-intl';

const InvalidUrlPath = () => {
  return (
    <div className="d-flex flex-column gap-3 w-100 h-100 justify-content-center align-items-center fs-2 opacity-25">
      <FontAwesomeIcon icon={faLock} size="2x" />
      <FormattedMessage id="General.InvalidUrlPath" />
    </div>
  );
};

export default InvalidUrlPath;
