import React from 'react';
import { useIntl } from 'react-intl';
import useCultures from '../../../../../../../hooks/cultures/useCultures';
import classNames from 'classnames';
import EventPropagationConstraint from '../../../../../../../components/EventPropagationConstraint';
import ReactFlagsSelect from 'react-flags-select';
import { Field } from 'formik';
import FieldInput from '../../../../../../../components/FieldInput';
import { IEducationTranslatable } from '../../index';
import { CultureEntry } from '../../../../../../../interfaces/cultures';

type MaterialTranslationsFormItemProps<T> = {
  values: T;
  setValues: (data: any) => void;
  index: number;
  translation: CultureEntry<{ name: string; description: string }>;
};

const MaterialTranslationsFormItem = <T extends IEducationTranslatable>({
  translation,
  values,
  setValues,
  index,
}: MaterialTranslationsFormItemProps<T>) => {
  const intl = useIntl();

  const { cultures, labels, flagCodes, defaultCulture } = useCultures();

  const handleSelectCulture = (countryCode: string) => {
    const modifiedValue = values.translations.find((_, idx) => idx === index);

    if (!modifiedValue) return;

    const culture = cultures.find(
      (p) => p.countryCode === countryCode.toLowerCase()
    );

    if (!culture) return;

    setValues({
      ...values,
      translations: [
        ...values.translations.slice(0, index),
        {
          culture: culture.cultureCode,
          value: {
            name: modifiedValue.value.name,
            description: modifiedValue.value.description,
          },
        },
        ...values.translations.slice(index + 1),
      ],
    });
  };

  if (values.translations[index].culture === defaultCulture.cultureCode)
    return <></>;

  return (
    <div
      className={classNames({
        'd-flex flex-column gap-2': true,
      })}
    >
      <EventPropagationConstraint>
        <ReactFlagsSelect
          selected={
            cultures.find((p) => p.cultureCode === translation.culture)
              ?.upperCountryCode ?? ''
          }
          countries={flagCodes.filter(
            (p) => p !== defaultCulture.upperCountryCode
          )}
          customLabels={labels}
          fullWidth={true}
          showSelectedLabel={true}
          onSelect={handleSelectCulture}
        />
      </EventPropagationConstraint>
      <Field
        required={true}
        placeholder={intl.formatMessage({
          id: `ResourcesHub.educationItems.addMaterialModal.culture_name`,
        })}
        id={`translations[${index}].value.name`}
        name={`translations[${index}].value.name`}
        type="text"
        component={FieldInput}
      />
      <Field
        required={true}
        id={`translations[${index}].value.description`}
        name={`translations[${index}].value.description`}
        type="textarea"
        rows={5}
        placeholder={intl.formatMessage({
          id: 'ResourcesHub.educationItems.addMaterialModal.culture_description',
        })}
        component={FieldInput}
      />
    </div>
  );
};

export default MaterialTranslationsFormItem;
