import React, { useState } from 'react';
import { UseOrganizationReportsListParams } from '../../../api/queries/reports/useOrganizationReportsList';
import { useCommonParams } from './useCommonParams';
import { ReportExecution, ReportType } from '../../../interfaces/reports';
import { DateRange } from 'react-day-picker';

const getEmptyReportsListFilters = (
  organizationId: string
): UseOrganizationReportsListParams => {
  return {
    organizationId: organizationId,
    reportType: undefined,
    requestedAtTo: undefined,
    requestedAtFrom: undefined,
    executionStatus: undefined,
  };
};

export const useReportsFilters = () => {
  const { organizationId } = useCommonParams();

  const [selectedFilters, setSelectedFilters] =
    useState<UseOrganizationReportsListParams>(
      getEmptyReportsListFilters(organizationId)
    );

  const handleExecutionStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFilters({
      ...selectedFilters,
      executionStatus: ReportExecution[e.target.value],
    });
  };

  const handleReportTypeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFilters({
      ...selectedFilters,
      reportType: ReportType[e.target.value],
    });
  };

  const handleReportDateRange = (dateRange: DateRange) => {
    setSelectedFilters({
      ...selectedFilters,
      requestedAtFrom: dateRange.from,
      requestedAtTo: dateRange.to,
    });
  };

  return {
    filters: selectedFilters,
    handleExecutionStatus,
    handleReportTypeStatus,
    handleReportDateRange,
  };
};
