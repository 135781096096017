import { getCaseDetails } from 'api/cases';
import QueryKeys from 'api/queries/queryKeys';
import { CaseQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';
import { useQuery } from 'react-query';

function useCaseDetails({ params, options }: IQueryRequest<CaseQueryParams>) {
  const { organizationId, caseId } = params;

  return useQuery({
    ...options,
    queryKey: [QueryKeys.getCaseDetails, organizationId, caseId],
    queryFn: async () => {
      const { data } = await getCaseDetails(organizationId, caseId);
      return data.data;
    },
  });
}

export default useCaseDetails;
