import { Button } from '@mui/material';
import { FormikProps } from 'formik';
import React, { useCallback } from 'react';
import { HelpCentreFormInitialValues } from '../../../../../../forms/HelpCentreForm/hooks/useForm';
import { useOtherTranslation } from '../../../../hooks/useOtherTranslation';

type OtherItemProps = {
  form: FormikProps<HelpCentreFormInitialValues>;
};

const OtherItem = ({ form }: OtherItemProps) => {
  const otherTranslation = useOtherTranslation();

  const handleSelect = useCallback(
    () => form.setValues((prev) => ({ ...prev, topic: otherTranslation })),
    [form, otherTranslation]
  );

  return (
    <Button
      sx={{
        justifyContent: 'start',
        fontWeight: 400,
        paddingTop: '0.5rem',
        paddingBottom: '0.5rem',
      }}
      name="topic"
      value={otherTranslation}
      onClick={handleSelect}
      color="primary"
      variant={
        form.values.topic === otherTranslation ? 'contained' : 'outlined'
      }
    >
      {otherTranslation}
    </Button>
  );
};

export default OtherItem;
