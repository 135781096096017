import React, { useState } from 'react';
import { OpenHookValues } from '../../../../../../../../hooks/useOpen';
import { FormattedMessage, useIntl } from 'react-intl';
import Button from '@mui/material/Button';
import { FormikProps } from 'formik';
import { AwareCaseDetailsFormValues } from '../../forms/AwareCaseDetailsForm/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-light-svg-icons';
import AwareSessionDetailsPdf from '../../pdfs/AwareSessionDetailsPdf';
import { pdf } from '@react-pdf/renderer';
import { useTypedContext } from '../../../../../../../../hooks/useTypedContext';
import { OperationalDataContext } from '../../providers/OperationalDataProvider';
import * as htmlToImage from 'html-to-image';
import useUploadCaseDetailsPdf from '../../../../../../../../api/mutations/cases/useUploadCaseDetailsPdf';
import { useOrganizationCasePathParams } from '../../../../../../../../hooks/paths/useOrganizationCasePathParams';
import { IUploadUriResult } from '../../../../../../../../interfaces/uploading';
import { useBrowserState } from '../../../../../../../../store/reducerHooks';

type ActionProps = {
  editMode: OpenHookValues;
  form: FormikProps<AwareCaseDetailsFormValues>;
};

const Actions = ({ editMode, form }: ActionProps) => {
  const isBrowser = useBrowserState();

  const intl = useIntl();

  const { organizationId, caseId } = useOrganizationCasePathParams();
  const { awareSessionDetails } = useTypedContext(OperationalDataContext);

  const [absoluteUri, setAbsoluteUri] = useState<string | null>(null);

  const handleSuccess = (data: IUploadUriResult) => {
    setAbsoluteUri(data.absolute_uri);
  };

  const toggleEditing = () => {
    editMode.toggle();
    setAbsoluteUri(null);
  };

  const { mutateAsync, isLoading } = useUploadCaseDetailsPdf({
    params: { organizationId, caseId },
    options: { successFb: handleSuccess },
    silent: true,
  });

  const handleCanvas = async () => {
    const rootElement = document.getElementById('aware-case-details-root');

    if (!rootElement) return;

    const dataUrl = await htmlToImage.toPng(rootElement, {
      quality: 1,
      filter: (element) => element.id !== 'aware-case-details-actions',
    });

    const pdfDocument = (
      <AwareSessionDetailsPdf
        date={new Date(awareSessionDetails.start_date)}
        imageBase64={dataUrl}
        title={intl.formatMessage({ id: 'Cases.TreatmentSummary' })}
      />
    );

    const blobPdf = pdf(pdfDocument);
    blobPdf.updateContainer(pdfDocument);
    const result = await blobPdf.toBlob();
    await mutateAsync(new File([result], 'details.pdf'));
  };

  const handleFileEvent = (uri: string) => () => {
    if (isBrowser) {
      const link = document.createElement('a');
      link.href = uri;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return;
    }

    window.dispatchEvent(
      new CustomEvent('aware-case-details-pdf', {
        detail: {
          uri: uri,
        },
      })
    );
  };

  return (
    <>
      <div
        id="aware-case-details-actions"
        className="flex items-center justify-end gap-2 w-full"
      >
        {editMode.isOpen || form.dirty ? (
          <Button
            onClick={form.submitForm}
            sx={{ minWidth: 120 }}
            variant="contained"
            color="primary"
          >
            {form.isSubmitting ? (
              <FontAwesomeIcon icon={faSpinnerThird} spin={true} />
            ) : (
              <FormattedMessage id="General.Save" />
            )}
          </Button>
        ) : (
          <>
            {absoluteUri ? (
              <Button
                onClick={handleFileEvent(absoluteUri)}
                sx={{ minWidth: 120 }}
                variant="outlined"
                color="primary"
              >
                <FormattedMessage id="General.Download" />
              </Button>
            ) : (
              <Button
                onClick={handleCanvas}
                sx={{ minWidth: 120 }}
                variant="outlined"
                color="primary"
              >
                {isLoading ? (
                  <FontAwesomeIcon icon={faSpinnerThird} spin={true} />
                ) : (
                  <FormattedMessage id="General.GeneratePdf" />
                )}
              </Button>
            )}
            <Button
              onClick={toggleEditing}
              sx={{ minWidth: 120 }}
              variant="outlined"
              color="primary"
            >
              <FormattedMessage id="General.Edit" />
            </Button>
          </>
        )}
      </div>
    </>
  );
};

export default Actions;
