import React, { useMemo } from 'react';
import { useGenerateRandomFormData } from './hooks/useGenerateRandomFormData';
import { useChartConfig } from './hooks/useChartConfig';
import { ConversationQuestion } from 'interfaces/conversations';
import { TrendVisualization } from 'interfaces/trendVisualizations';
import { IOrganizationObservationMeaning } from 'interfaces/observationMeanings';
import { useTrendsChartGenericFormatters } from 'components/TrendsChart/hooks/useTrendsChartGenericFormatters';
import TrendsChart from 'components/TrendsChart';

type RandomFormChartProps = {
  visualization: TrendVisualization;
  questions: ConversationQuestion[];
  meanings: IOrganizationObservationMeaning[];
};

const RandomFormChart = ({
  visualization,
  questions,
  meanings,
}: RandomFormChartProps) => {
  const data = useGenerateRandomFormData(
    visualization.elements,
    questions,
    meanings
  );

  const config = useChartConfig(visualization, questions, meanings);
  const formatters = useTrendsChartGenericFormatters(questions, meanings);

  return useMemo(
    () => (
      <TrendsChart
        elements={visualization.elements}
        data={data}
        config={config}
        formatters={formatters}
      />
    ),
    [config, data, formatters, visualization.elements]
  );
};

export default RandomFormChart;
