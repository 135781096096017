export type getQueryParamsType = {
  currentPage: string;
  pageSize: string;
  sortBy: string;
  filterBy: string;
  query: string;
  resourceId: string;
  from: string;
  to: string;
};

export const getQueryParams = ({
  currentPage,
  pageSize,
  sortBy,
  filterBy,
  query,
  resourceId,
  from,
  to,
}: Partial<getQueryParamsType>) => {
  return `?page=${currentPage}${pageSize ? `&page_size=${pageSize}` : ''}${
    sortBy ? `&sort=${sortBy}` : ''
  }${filterBy ? `&filter_by=${filterBy}` : ''}${
    query ? `&query=${query}` : ''
  }${resourceId ? `&resource_id=${resourceId}` : ''}${
    from ? `&from=${from}` : ''
  }${to ? `&to=${to}` : ''}`;
};
