import React from 'react';
import { FormattedMessage } from 'react-intl';

type GraspSettingsSectionProps = {
  titleId: string;
  component: JSX.Element;
};

const GraspSettingsSection = ({
  titleId,
  component,
}: GraspSettingsSectionProps) => {
  return (
    <div className="GraspSettingsSection">
      <div className="GraspSettingsSection__header">
        <FormattedMessage id={titleId} />
      </div>
      <div className="GraspSettingsSection__content">{component}</div>
    </div>
  );
};

export default GraspSettingsSection;
