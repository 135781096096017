import { useInfiniteQuery } from 'react-query';
import {
  convertIntoObjectWithPageInfo,
  returnAllDataFromResponse,
} from '../../../helpers/utils/reactQueryHelpers';
import { GraspFilters, IGrasp } from '../../../interfaces/grasps';
import { SearchAndPagingQueryParams } from '../../../interfaces/queryParams';
import { IQueryRequest } from '../../../interfaces/request';
import { IError, IInfiniteResult } from '../../../interfaces/response';
import { getGrasps } from '../../graspManagement';
import QueryKeys from '../queryKeys';

type GraspsQueryParams = SearchAndPagingQueryParams & {
  filters?: GraspFilters;
};

const useGrasps = ({ params, options }: IQueryRequest<GraspsQueryParams>) => {
  const { searchValue = '', pageSize = 20, filters } = params;
  const response = useInfiniteQuery<IInfiniteResult<IGrasp[]>, IError>({
    ...options,
    queryFn: async ({ pageParam = 1 }) => {
      const { data } = await getGrasps(
        {
          page_size: pageSize,
          page: pageParam,
          search: searchValue,
        },
        filters
      );

      return convertIntoObjectWithPageInfo<IGrasp[]>(data);
    },
    queryKey: [QueryKeys.getGrasps, pageSize, searchValue, filters],
    getNextPageParam: (lastPage) => {
      return lastPage.nextPage;
    },
    getPreviousPageParam: (firstPage) => firstPage.previousPage,
  });

  return {
    grasps: returnAllDataFromResponse<IGrasp>(response),
    totalItemsCount: response.data?.pages[0].totalItemsCount ?? 0,
    ...response,
  };
};

export default useGrasps;
