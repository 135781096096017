import TextField from '@mui/material/TextField';
import { GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid';
import React, { HTMLInputTypeAttribute, useLayoutEffect, useRef } from 'react';

type TextFieldCellProps<TData extends {}> = GridRenderEditCellParams<TData> & {
  type: HTMLInputTypeAttribute;
  valueGetter?: (value: any) => any;
  valueSetter?: (value: any) => any;
};

const TextFieldCell = <TData extends {}>({
  type,
  valueGetter,
  valueSetter,
  ...inner
}: TextFieldCellProps<TData>) => {
  const apiRef = useGridApiContext();
  const ref = useRef<any>();

  useLayoutEffect(() => {
    if (!inner.hasFocus || !ref.current) return;
    ref.current.focus();
  }, [inner.hasFocus]);

  const handleValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    apiRef.current.setEditCellValue({
      id: inner.id,
      field: inner.field,
      value: valueSetter ? valueSetter(newValue) : newValue,
    });
  };

  return (
    <TextField
      fullWidth={true}
      inputRef={ref}
      type={type}
      id={inner.id.toString()}
      value={valueGetter ? valueGetter(inner.value) : inner.value}
      onChange={handleValueChange}
      variant="outlined"
    />
  );
};

export default TextFieldCell;
