import useDeleteEducationMaterial from '../../../../../../../api/mutations/educationMaterials/useDeleteEducationMaterial';
import { useCommonParams } from '../../../../../hooks/useCommonParams';
import { useCommonData } from '../../../../../hooks/useCommonData';

export const useRemoveEducationMaterial = (materialId: string) => {
  const { organizationId } = useCommonParams();

  const { educationMaterials } = useCommonData();

  const handleSuccess = async () => {
    await educationMaterials.refetch();
  };

  const { mutateAsync: deleteAsync, isLoading: isDeleting } =
    useDeleteEducationMaterial({
      params: { organizationId },
      options: { successFb: handleSuccess },
    });

  const handleDelete = async () => {
    await deleteAsync(materialId);
  };

  return { handleDelete, isDeleting };
};
