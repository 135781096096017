import TabContainer from 'components/Tabs/components/TabContainer';
import { useEffect } from 'react';
import { mappedTimelinesValues, TimelineTabsProps } from 'interfaces/timeline';
import TimelineTabsFilters from './TimelineTabsFilters';
import { useDispatch } from 'react-redux';
import { changeTab, setSelectedTrendVisualizationTemplate } from '../actions';
import { TimelineTabsValues } from 'interfaces/ui';
import { FormattedMessage } from 'react-intl';

//tabs
import OverviewSummary from './tabs/OverviewSummary';
import DayByDayTab from './tabs/DayByDayTab';
import TrendsSummary from './tabs/TrendsSummary';
import {
  useTimelineDetailsSelectedTab,
  useTimelineDetailsTabsDisabled,
} from 'store/reducerHooks';
import { ITabContainerItem } from '../../../components/Tabs/types/Tabs';

const TimelineTabs = (props: TimelineTabsProps) => {
  const dispatch = useDispatch();

  const tabsDisabled = useTimelineDetailsTabsDisabled();
  const currentTab = useTimelineDetailsSelectedTab();

  const tabContainerItems: ITabContainerItem[] = [
    {
      header: <FormattedMessage id="TimelineDetails.tabs.dayByDay" />,
      component: <DayByDayTab {...props} />,
      shouldBeRendered: currentTab === TimelineTabsValues.DAY_BY_DAY,
    },
    {
      header: <FormattedMessage id="TimelineDetails.tabs.overviewSummary" />,
      component: <OverviewSummary {...props} />,
      shouldBeRendered: currentTab === TimelineTabsValues.OVERVIEW,
    },
    {
      header: <FormattedMessage id="TimelineDetails.tabs.trendsTimeline" />,
      component: <TrendsSummary {...props} />,
      shouldBeRendered: currentTab === TimelineTabsValues.TRENDS_TIMELINE,
    },
  ];

  const onTabChange = (val: number) => {
    dispatch(changeTab(mappedTimelinesValues[val]));
  };

  useEffect(() => {
    return () => {
      dispatch(changeTab(TimelineTabsValues.DAY_BY_DAY));
      dispatch(setSelectedTrendVisualizationTemplate(null));
    };
  }, [dispatch]);

  return (
    <>
      <TabContainer
        additionalContent={
          <TimelineTabsFilters {...props} fetchTimeDetails={false} />
        }
        items={tabContainerItems}
        onTabChange={onTabChange}
        tabsDisabled={tabsDisabled}
      />
    </>
  );
};

export default TimelineTabs;
