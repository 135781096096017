import { useTheme } from '@mui/material';
import React, { useMemo } from 'react';
import { useTypedContext } from '../../../../../../../../../../../../../../hooks/useTypedContext';
import { DashboardDataReducerContext } from '../../../../../../../../types';
import { useTranslateLegend } from '../../../../hooks/useTranslateLegend';
import { TickFormatter } from '../TickFormatter';

export const ChartTooltip = (props: any) => {
  const theme = useTheme();

  const [dashboardData] = useTypedContext(DashboardDataReducerContext);
  const { getLegendTranslation } = useTranslateLegend();

  const dateRange = useMemo(() => {
    return {
      from: dashboardData.template.date_selection.date_from,
      to: dashboardData.template.date_selection.date_to,
    };
  }, [
    dashboardData.template.date_selection.date_from,
    dashboardData.template.date_selection.date_to,
  ]);

  if (!props.active || !props.payload || !props.payload.length) return <></>;

  return (
    <div
      style={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
      }}
      className="d-flex flex-column align-items-center justify-content-center p-3 shadow rounded-2"
    >
      <span>{TickFormatter(props.label, 0, dateRange)}</span>
      <div className="d-flex flex-column mt-2">
        {props.payload.map((p) => (
          <span key={p.name}>
            {getLegendTranslation(p.name)}: {p.value}
          </span>
        ))}
      </div>
    </div>
  );
};
