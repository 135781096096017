import { graspColorsValues } from 'helpers/colors';
import { CasesParticipantFeedbackStatus, CaseStatus } from 'interfaces/cases';
import { PointComparison } from 'interfaces/enums';
import { GraspStatus } from 'interfaces/grasps';
import { OrganizationStatus, OrganizationType } from 'interfaces/organizations';
import {
  AllowToChooseGroup,
  CompleteStatuses,
  FieldDefinition,
} from 'interfaces/submissions';
import { Meaning, ObservationSymptom } from 'interfaces/timeline';
import { IOption } from 'interfaces/ui';
import { IntlShape } from 'react-intl';
import { generateTranslationIdWithValues } from 'translations/utils/translationGenerator';
import {
  ConversationTemplateContext,
  ConversationTemplateTriggerType,
  UserConversationHistoryContextType,
} from '../../interfaces/conversations';
import { CaseNotificationScheduleType } from '../../interfaces/notificationSchedules';

export const genderOptions = generateTranslationIdWithValues(
  'Register.gender',
  ['Female', 'Male']
);

export const extendedGenderOptions = generateTranslationIdWithValues(
  'Register.gender',
  ['Female', 'Male', 'Unspecified']
);

export const sentimentOptions = generateTranslationIdWithValues(
  'TimelineDetails.sentiments',
  ['NotSpecified', 'Negative', 'Neutral', 'Positive']
);

export const deletionReasonsOptions = generateTranslationIdWithValues(
  'MyProfile.AccountDeletion.AccountDeletionModal.DeletionReason',
  ['ToManyNotifications', 'NotGettingValue', 'PrivacyConcern', 'Other']
);

export const observationMeaningOptions = generateTranslationIdWithValues(
  'ObservationMeaning.MeaningTypes',
  [
    'NotSpecified',
    'SymptomPain',
    'SymptomTiredness',
    'SymptomDrowsiness',
    'SymptomNausea',
    'SymptomAppetiteLack',
    'SymptomBreathShortness',
    'SymptomDepression',
    'SymptomAnxiety',
    'MicroChoiceTemptation',
    'MicroChoiceChoiceMade',
    'Other',
  ]
);

export const submissionStatusOptions = generateTranslationIdWithValues(
  'ProjectSubmissions.submissionStatus',
  ['Pending', 'Approved', 'Rejected']
);

export const caseStatusOptions = generateTranslationIdWithValues(
  'OrganizationCases.CasesList.CaseStatus',
  Object.values(CaseStatus)
);

export const messagingToolOptions = generateTranslationIdWithValues(
  'ProjectSubmissions.messagingTool',
  ['Preferred', 'AllAllowed']
);

export const communicationChannelOptions = generateTranslationIdWithValues(
  'ProjectSubmissions.communicationChannel',
  ['Email', 'SMS', 'PUSH', 'Whatsapp']
);

export const organizationStatusOptions = generateTranslationIdWithValues(
  'MyOrganizations.statuses',
  Object.values(OrganizationStatus)
);
export const organizationTypeOptions = generateTranslationIdWithValues(
  'Enums.OrganizationType',
  Object.values(OrganizationType)
);

export const meaningOptions = generateTranslationIdWithValues(
  'TimelineDetails.meanings',
  Object.values(Meaning)
);

export const observationSymptomOptions = generateTranslationIdWithValues(
  'TimelineDetails.meanings',
  Object.values(ObservationSymptom)
);

export const graspStatusOptions = generateTranslationIdWithValues(
  'MyOrganizations.grasps.statuses',
  Object.values(GraspStatus)
);

export const fieldsDefinitionOptions = generateTranslationIdWithValues(
  'FieldDefinition',
  Object.values(FieldDefinition)
);

export const allowToChooseGroupOptions = generateTranslationIdWithValues(
  'MyOrganizations.projects.allowToChooseGroup',
  Object.values(AllowToChooseGroup)
);

export const userConversationContextTypeOptions =
  generateTranslationIdWithValues(
    'Enums.UserConversationHistoryContextType',
    Object.values(UserConversationHistoryContextType)
  );

export const days = {
  0: 'Sunday',
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
};

export const colorOptions: IOption[] = [
  {
    value: graspColorsValues.blue,
    translationId: 'General.colors.blue',
  },
  {
    value: graspColorsValues.green,
    translationId: 'General.colors.green',
  },
  {
    value: graspColorsValues.orange,
    translationId: 'General.colors.orange',
  },
  {
    value: graspColorsValues.black,
    translationId: 'General.colors.black',
  },
  {
    value: graspColorsValues.white,
    translationId: 'General.colors.white',
  },
];

export const returnTimelineTypes = (intl: IntlShape) => [
  {
    text: intl.formatMessage({
      id: 'UserDashboard.timelineTypeOptions.SymptomsPain',
    }),
    value: 'SymptomsPain',
  },
  {
    text: intl.formatMessage({
      id: 'UserDashboard.timelineTypeOptions.SymptomsStress',
    }),
    value: 'SymptomsStress',
  },
  {
    text: intl.formatMessage({
      id: 'UserDashboard.timelineTypeOptions.MicroChoices',
    }),
    value: 'MicroChoices',
  },
  {
    text: intl.formatMessage({
      id: 'UserDashboard.timelineTypeOptions.Generic',
    }),
    value: 'Generic',
  },
];

export const pointsComparisonOptions = generateTranslationIdWithValues(
  'General.valueCheck',
  Object.values(PointComparison)
);

export const projectSubmissionStatuses = generateTranslationIdWithValues(
  'ProjectSubmissions.CompleteStatuses',
  Object.values(CompleteStatuses)
);

export const participantFeedbackStatuses = generateTranslationIdWithValues(
  'OrganizationCases.FeedbackStatus',
  Object.values(CasesParticipantFeedbackStatus)
);

export const conversationTemplateContextOptions =
  generateTranslationIdWithValues(
    'ConversationsAndFeedbacks.ConversationTemplateModal.options.context',
    Object.values(ConversationTemplateContext)
  );

export const conversationTemplateTriggerTypeOptions =
  generateTranslationIdWithValues(
    'ConversationsAndFeedbacks.ConversationTemplateModal.options.triggerType',
    Object.values(ConversationTemplateTriggerType)
  );

export const caseNotificationScheduleTypesOptions =
  generateTranslationIdWithValues(
    'Enums.CaseNotificationScheduleType',
    Object.values(CaseNotificationScheduleType)
  );

export const returnSubmissionCompletionStatusString = (
  isCompleted: boolean | undefined
) => {
  if (isCompleted) {
    return CompleteStatuses.Closed;
  } else if (isCompleted === false) {
    return CompleteStatuses.Open;
  } else {
    return CompleteStatuses.ALL;
  }
};

export const statusDefinition = [
  {
    value: 'Open',
    text: 'Open',
  },
  {
    value: 'Pending',
    text: 'Pending',
  },
  {
    value: 'Closed',
    text: 'Closed',
  },
];
