import React, { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import PageHeader from '../../../../components/PageHeader';
import InputFieldWithImage, {
  InputFieldWithImageColor,
} from '../../../../components/InputFieldWithImage';
import sortIcon from '../../../../assets/images/sort_icon.svg';
import { Button, Input, Label } from 'reactstrap';
import { ReportExecution, ReportType } from '../../../../interfaces/reports';
import DateRangePopover from '../../../../components/DateRangerPopover';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/pro-light-svg-icons';
import { DateRange } from 'react-day-picker';
import { UseOrganizationReportsListParams } from '../../../../api/queries/reports/useOrganizationReportsList';
import { useCommonData } from '../../hooks/useCommonData';
import { useInitQuery } from '../../../../hooks/queries/useInitQuery';

type ReportsHeaderProps = {
  filters: UseOrganizationReportsListParams;
  handleReportDateRange: (dateRange: DateRange) => void;
  handleReportTypeStatus: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleExecutionStatus: (e: React.ChangeEvent<HTMLInputElement>) => void;
  toggleGenerateReport: () => void;
};

const ReportsHeader = ({
  filters,
  handleReportDateRange,
  handleReportTypeStatus,
  handleExecutionStatus,
  toggleGenerateReport,
}: ReportsHeaderProps) => {
  const { organization } = useCommonData(filters);

  useInitQuery(organization);

  const selectedDates = useMemo((): DateRange => {
    return {
      from: filters.requestedAtFrom ?? undefined,
      to: filters.requestedAtTo ?? undefined,
    };
  }, [filters.requestedAtFrom, filters.requestedAtTo]);

  return (
    <div>
      <PageHeader
        isLoading={organization.isLoading}
        breadcrumbs={[
          {
            text: <FormattedMessage id="CaseGroups.breadcrumbs.organization" />,
            link: null,
            key: 'organization',
          },
          {
            text: organization.data?.name ?? '',
            link: `/my-organizations/${organization.data?.id}`,
            key: 'organization-name',
          },
          {
            key: 'reports',
            link: null,
            text: <FormattedMessage id="UserDashboard.reports" />,
          },
        ]}
      />
      <div className="ReportsList__filters mt-4">
        <div className="ReportsList__filters__filters-wrapper">
          <InputFieldWithImage
            color={InputFieldWithImageColor.gray}
            image={<img src={sortIcon} alt="sort_icon" />}
            labelTranslationId="Reports.ReportsList.execution"
          >
            <Input
              type="select"
              onChange={handleExecutionStatus}
              name="caseStatusSelect"
              id="caseStatusSelect"
            >
              <option key={undefined} value={undefined}>
                Choose report execution status
              </option>
              {Object.entries(ReportExecution).map(
                ([key, execution_status]) => (
                  <option key={key} value={key}>
                    {execution_status}
                  </option>
                )
              )}
            </Input>
          </InputFieldWithImage>
          <InputFieldWithImage
            color={InputFieldWithImageColor.gray}
            image={<img src={sortIcon} alt="sort_icon" />}
            labelTranslationId="Reports.ReportsList.reportType"
          >
            <Input
              type="select"
              onChange={handleReportTypeStatus}
              name="caseStatusSelect"
              id="caseStatusSelect"
            >
              <option key={undefined} value={undefined}>
                Choose report type
              </option>
              {Object.entries(ReportType).map(([key, type]) => (
                <option key={key} value={key}>
                  {type}
                </option>
              ))}
            </Input>
          </InputFieldWithImage>
          <div className="mb-3">
            <Label className="d-flex">
              <FormattedMessage id="Reports.ReportsList.creationDateRange" />
            </Label>
            <DateRangePopover
              onDateChange={handleReportDateRange}
              selectedDates={selectedDates}
            />
          </div>
        </div>
        <Button outline={true} onClick={toggleGenerateReport} color="primary">
          <FontAwesomeIcon icon={faPlus} />
        </Button>
      </div>
    </div>
  );
};

export default ReportsHeader;
