import { CultureEntry } from './cultures';
import { BootstrapBreakpoint } from './ui';

export interface IDashboardReportSchedule {
  id: string;
  dashboard_id: string;
  date_from: string;
  date_last_generated?: string;
  period: DashboardReportPeriod;
  one_time_access: boolean;
  destination_type: DashboardReportDestinationType;
}

export type DashboardReportSchedule =
  | IMemberDashboardReportSchedule
  | IAccessProfileDashboardReportSchedule
  | IEmailDashboardReportSchedule;

export interface IMemberDashboardReportSchedule
  extends IDashboardReportSchedule {
  destination_type: DashboardReportDestinationType.Member;
  member_id: string;
}

export interface IEmailDashboardReportSchedule
  extends IDashboardReportSchedule {
  destination_type: DashboardReportDestinationType.Email;
  email: string;
}

export interface IAccessProfileDashboardReportSchedule
  extends IDashboardReportSchedule {
  destination_type: DashboardReportDestinationType.AccessProfile;
  access_profile_id: string;
}

export interface IMemberDashboardReport {
  id: string;
  organization_id: string;
  member_id: string;
  name: CultureEntry<string>[];
  source_period: DashboardReportPeriod;
  date_from: Date;
  date_to: Date;
  watched: boolean;
  one_time_access: boolean;
}

export enum DashboardReportDestinationType {
  Member = 'Member',
  Email = 'Email',
  AccessProfile = 'AccessProfile',
}

export enum DashboardReportPeriod {
  Day = 'Day',
  Week = 'Week',
  Month = 'Month',
  Year = 'Year',
}

export type DashboardTemplate = IDashboardTemplate<
  DashboardTemplateFilter,
  DashboardTemplateDateSelection | undefined
>;

export interface IDashboardTemplate<TFilter, TDateSelection> {
  id: string;
  organization_id: string;
  sequence: number;
  data_source?: DashboardTemplateDataSource;
  data_source_id?: string;
  name: CultureEntry<string>[];
  path: string;
  content: IDashboardTemplateContent<TFilter, TDateSelection>;
  is_available_on_mobile: boolean;
  is_available_on_nav: boolean;
}

export interface IDashboardTemplateContent<TFilter, TDateSelection> {
  filters: TFilter[];
  date_selection: TDateSelection;
  layout: IDashboardTemplateLayout;
  visualizations: DashboardTemplateVisualization[];
}

export type DashboardTemplateDateSelection =
  | IPeriodDateSelection
  | ICustomDateSelection;

export interface IDashboardTemplateDateSelection {
  date_period: DashboardTemplateDatePeriod | DashboardDatePeriod.CustomPeriod;
  visible: boolean;
}

export interface IPeriodDateSelection extends IDashboardTemplateDateSelection {
  date_period: DashboardTemplateDatePeriod;
}

export interface ICustomDateSelection extends IDashboardTemplateDateSelection {
  date_period: DashboardDatePeriod.CustomPeriod;
  date_from: string;
  date_to: string;
}

export type DashboardFilter =
  | IClosedListDashboardFilter
  | IMemberDashboardFilter
  | IGraspDashboardFilter
  | IRangeDashboardFilter
  | IOrganizationDashboardFilter
  | ISourcePeriodDashboardFilter;

export type DashboardDateSelection = {
  date_period: DashboardDatePeriod;
  date_from: Date;
  date_to: Date;
  visible: boolean;
};

interface IDashboardFilter {
  type: DashboardFilterType;
  visible: boolean;
}

export interface IOptionsFilter {
  options: { id: string; selected: boolean }[];
}

export interface IClosedListDashboardFilter
  extends IDashboardFilter,
    IOptionsFilter {
  type: DashboardFilterType.ClosedList;
  id: string;
}

export interface INonQuestionFilter<T extends DashboardFilterType>
  extends IDashboardFilter,
    IOptionsFilter {
  type: T;
}

export interface IMemberDashboardFilter
  extends INonQuestionFilter<DashboardFilterType.Member> {}

export interface IGraspDashboardFilter
  extends INonQuestionFilter<DashboardFilterType.Grasp> {}

export interface IOrganizationDashboardFilter
  extends INonQuestionFilter<DashboardFilterType.Organization> {}

export interface ISourcePeriodDashboardFilter
  extends INonQuestionFilter<DashboardFilterType.SourcePeriod> {}

export interface IRangeDashboardFilter extends IDashboardFilter {
  type: DashboardFilterType.Range;
  id: string;
  options: { min_number_value: number; max_number_value: number } | null;
}

export type DashboardTemplateFilter =
  | IMemberDashboardTemplateFilter
  | IGraspDashboardTemplateFilter
  | IOrganizationDashboardTemplateFilter
  | IClosedListDashboardTemplateFilter
  | IRangeDashboardTemplateFilter
  | ISourcePeriodDashboardTemplateFilter;

interface IDashboardTemplateFilter {
  type: DashboardFilterType;
  selected_all: boolean;
  visible: boolean;
}

export interface IIncludedExcludedFilter {
  included: string[];
  excluded: string[];
}

export interface IMemberDashboardTemplateFilter
  extends IDashboardTemplateFilter,
    IIncludedExcludedFilter {
  type: DashboardFilterType.Member;
}

export interface IGraspDashboardTemplateFilter
  extends IDashboardTemplateFilter,
    IIncludedExcludedFilter {
  type: DashboardFilterType.Grasp;
}

export interface IOrganizationDashboardTemplateFilter
  extends IDashboardTemplateFilter,
    IIncludedExcludedFilter {
  type: DashboardFilterType.Organization;
}

export interface ISourcePeriodDashboardTemplateFilter
  extends IDashboardTemplateFilter,
    IIncludedExcludedFilter {
  type: DashboardFilterType.SourcePeriod;
}

export interface IClosedListDashboardTemplateFilter
  extends IDashboardTemplateFilter,
    IIncludedExcludedFilter {
  id: string;
  type: DashboardFilterType.ClosedList;
}

export interface IRangeDashboardTemplateFilter
  extends IDashboardTemplateFilter {
  id: string;
  selected: {
    min_number_value: number;
    max_number_value: number;
  };
  type: DashboardFilterType.Range;
}

export enum DashboardFilterType {
  ClosedList = 'ClosedList',
  Range = 'Range',
  Member = 'Member',
  Organization = 'Organization',
  Grasp = 'Grasp',
  SourcePeriod = 'SourcePeriod',
}

export interface IDashboardTemplateLayout {
  row_heights: DashboardTemplateLayoutRowHeights[];
  color_scheme: string[];
  row_gap?: number;
  column_gap?: number;
  row_style?: string;
  column_style?: string;
}

export type DashboardTemplateLayoutRowHeights = {
  breakpoint?: BootstrapBreakpoint;
  heights: number[];
};

interface IDashboardTemplateVisualization {
  sequence: number;
  name: CultureEntry<string>[];
  type: DashboardTemplateVisualizationType;
  container_style?: string;
  positions: DashboardTemplateVisualizationPosition[];
  palette: DashboardTemplateVisualizationPalette[];
}

export type DashboardTemplateVisualizationPosition = {
  breakpoint?: BootstrapBreakpoint;
  start: number[];
  end: number[];
};

export type DashboardTemplateVisualizationPalette = {
  name: string;
  hex?: string;
  hexes?: string[];
};

export type DashboardTemplateVisualization =
  | IChartDashboardTemplateVisualization
  | ITableDashboardTemplateVisualization
  | IListDashboardTemplateVisualization
  | ISelectableDashboardTemplateVisualization
  | IDashboardDashboardTemplateVisualization
  | ITextDashboardTemplateVisualization;

export interface IChartDashboardTemplateVisualization
  extends IDashboardTemplateVisualization {
  type: DashboardTemplateVisualizationType.ChartView;
  chart_type: DashboardTemplateChartType;
  series: DashboardTemplateSeries[];
  settings_available: boolean;
  date_granularity?: DateGranularity;
}

export type DashboardTemplateSeries = {
  data_source: DashboardTemplateSeriesDataSource;
  series_split_by?: DashboardTemplateSeriesSplitBy;
  split_by?: DashboardTemplateSeriesSplitBy;
  aggregate: DashboardTemplateSeriesAggregate;
  accumulative: boolean;
};

interface IDashboardTemplateSeriesSplitBy {
  type: DashboardTemplateSplitType;
}

export type DashboardTemplateSeriesSplitBy =
  | ITypeBasedDashboardTemplateSeriesSplitBy
  | IQuestionBasedDashboardTemplateSeriesSplitBy;

export interface ITypeBasedDashboardTemplateSeriesSplitBy
  extends IDashboardTemplateSeriesSplitBy {
  type:
    | DashboardTemplateSplitType.Doctor
    | DashboardTemplateSplitType.Organization
    | DashboardTemplateSplitType.Grasp;
}

export interface IQuestionBasedDashboardTemplateSeriesSplitBy
  extends IDashboardTemplateSeriesSplitBy {
  type: DashboardTemplateSplitType.Question;
  id: string;
}

export interface ITableDashboardTemplateVisualization
  extends IDashboardTemplateVisualization {
  type: DashboardTemplateVisualizationType.TableView;
  columns: DashboardTemplateColumn[];
  responsive: DashboardTemplateResponsive[];
  on_click?: string;
}

export type DashboardTemplateResponsive = {
  breakpoint: BootstrapBreakpoint;
  hidden: number[];
};

export type DashboardTemplateColumn = {
  name: CultureEntry<string>[];
  reference: string;
};

export interface IBaseListDashboardTemplateVisualization {
  primary_text: string;
  secondary_text?: string;
  start_icon?: string;
  end_icon?: string;
  style?: string;
}

export interface IListDashboardTemplateVisualization
  extends IDashboardTemplateVisualization,
    IBaseListDashboardTemplateVisualization {
  type: DashboardTemplateVisualizationType.ListView;
}

export interface ISelectableDashboardTemplateVisualization
  extends IDashboardTemplateVisualization,
    IBaseListDashboardTemplateVisualization {
  type: DashboardTemplateVisualizationType.SelectableListView;
  selected_style?: string;
  selected_first?: boolean;
  selected_last?: boolean;
  selected_index?: number;
  on_click: string;
}

export interface IDashboardDashboardTemplateVisualization
  extends IDashboardTemplateVisualization {
  type: DashboardTemplateVisualizationType.DashboardView;
  dashboard_id: string;
  fallback_message_html?: string;
}

export interface ITextDashboardTemplateVisualization
  extends IDashboardTemplateVisualization {
  type: DashboardTemplateVisualizationType.TextView;
  content_html: CultureEntry<string>[];
}

export enum DashboardTemplateSplitType {
  Question = 'Question',
  Doctor = 'Doctor',
  Organization = 'Organization',
  Grasp = 'Grasp',
}

export enum DashboardTemplateSeriesAggregate {
  Avg = 'Avg',
  Min = 'Min',
  Max = 'Max',
  Sum = 'Sum',
}

export enum DashboardTemplateSeriesDataSource {
  AlarmsCount = 'AlarmsCount',
  SqueezesCount = 'SqueezesCount',
  SessionDuration = 'SessionDuration',
  SessionCount = 'SessionCount',
  FeedbacksCount = 'FeedbacksCount',
}

export enum DashboardTemplateChartType {
  PieChart = 'PieChart',
  VerticalBarChart = 'VerticalBarChart',
  HorizontalBarChart = 'HorizontalBarChart',
  LineChart = 'LineChart',
}

export enum DashboardTemplateVisualizationType {
  ChartView = 'ChartView',
  TableView = 'TableView',
  ListView = 'ListView',
  SelectableListView = 'SelectableListView',
  DashboardView = 'DashboardView',
  TextView = 'TextView',
}

export enum DashboardTemplateDatePeriod {
  Today = 'Today',
  CurrentWeek = 'CurrentWeek',
  PreviousWeek = 'PreviousWeek',
  Last7Days = 'Last7Days',
  CurrentMonth = 'CurrentMonth',
  Last30Days = 'Last30Days',
  CurrentYear = 'CurrentYear',
  Last12Months = 'Last12Months',
}

export enum DashboardDatePeriod {
  Today = 'Today',
  CurrentWeek = 'CurrentWeek',
  PreviousWeek = 'PreviousWeek',
  Last7Days = 'Last7Days',
  CurrentMonth = 'CurrentMonth',
  Last30Days = 'Last30Days',
  CurrentYear = 'CurrentYear',
  Last12Months = 'Last12Months',
  CustomPeriod = 'CustomPeriod',
}

export enum DashboardTemplateDataSource {
  AwareSessionData = 'AwareSessionData',
  AggregatedAwareSessionData = 'AggregatedAwareSessionData',
  DashboardReports = 'DashboardReports',
  DataQuery = 'DataQuery',
}

export interface IUpsertDashboardReportSchedule {
  id?: string;
  organization_id: string;
  dashboard_id: string;
  date_from: string;
  destination_type: DashboardReportDestinationType;
  destination_id: string;
  period: DashboardReportPeriod;
  one_time_access: boolean;
}

export enum DateGranularity {
  h1 = 'h1',
  h2 = 'h2',
  h4 = 'h4',
  d1 = 'd1',
  d7 = 'd7',
  m1 = 'm1',
  y1 = 'y1',
}
