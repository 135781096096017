import { IAsyncFormHookValues } from 'interfaces/forms';
import { TrendVisualizationTemplate } from 'interfaces/trendVisualizations';
import { useCallback, useMemo } from 'react';
import { FormikErrors } from 'formik';
import {
  getEmptyTrendVisualizationTemplate,
  getInitialValuesBasedOnTemplate,
} from '../helpers';
import useAddTrendVisualizationTemplate from 'api/mutations/trendVisualizations/useAddTrendVisualizationTemplate';
import { useCommonData } from 'modules/Organizations/views/OrganizationDetailsView/hooks/useCommonData';
import { useCommonParams } from 'modules/Organizations/views/OrganizationDetailsView/hooks/useCommonParams';

export const useForm = (
  template: TrendVisualizationTemplate | undefined,
  toggle: () => void
): IAsyncFormHookValues<TrendVisualizationTemplate> => {
  const { organizationId } = useCommonParams();

  const { trendVisualizationTemplates } = useCommonData();

  const handleAddTemplate = async () => {
    await trendVisualizationTemplates.refetch();
    toggle();
  };

  const { mutateAsync: addAsync } = useAddTrendVisualizationTemplate(
    organizationId,
    handleAddTemplate
  );

  const initialValues = useMemo(() => {
    return template
      ? getInitialValuesBasedOnTemplate(template)
      : getEmptyTrendVisualizationTemplate(organizationId);
  }, [organizationId, template]);

  const validation = useCallback((values: typeof initialValues) => {
    let errors: FormikErrors<typeof initialValues> = {};

    if (values.visualizations.length === 0) errors.visualizations = [{}];

    for (const visualization of values.visualizations) {
      if (visualization.elements.length === 0) {
        errors.visualizations = [{}];
        break;
      }
    }

    return errors;
  }, []);

  const handleSubmit = useCallback(
    async (values: typeof initialValues) => {
      await addAsync(values);
    },
    [addAsync]
  );

  return {
    initialValues,
    validation,
    handleSubmit,
  };
};
