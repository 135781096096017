import React, { useMemo } from 'react';
import PageHeader from '../../components/PageHeader';
import useOrganizationDetails from '../../api/queries/organizations/useOrganizationDetails';
import TabContainer from '../../components/Tabs/components/TabContainer';
import {
  UsersAndAccessTabsValues,
  useUserAndAccessTabs,
} from './hooks/useUserAndAccessTabs';
import OrganizationUsersTab from './components/OrganizationUsersTab';
import AccessProfilesTab from './components/AccessProfilesTab';
import AccessRequestsTab from './components/AccessRequestsTab';
import { FormattedMessage } from 'react-intl';
import { ITabContainerItem } from '../../components/Tabs/types/Tabs';
import { useOrganizationPathParams } from '../../hooks/paths/useOrganizationPathParams';

const UsersAndAccess = () => {
  const { organizationId } = useOrganizationPathParams();

  const { data: organizationData } = useOrganizationDetails({
    params: { organizationId },
  });

  const { shouldBeRendered, onTabChange } = useUserAndAccessTabs();

  const breadcrumbs = useMemo(
    () => [
      {
        text: <FormattedMessage id="UsersAndAccess.breadcrumbs.organization" />,
        link: null,
        key: 'organization',
      },
      {
        text: organizationData?.name ?? '',
        link: `/my-organizations/${organizationId}`,
        key: 'organization-name',
      },
      {
        text: (
          <FormattedMessage id="UsersAndAccess.breadcrumbs.usersAndAccess" />
        ),
        link: null,
        key: 'users-and-access',
      },
    ],
    [organizationData?.name, organizationId]
  );

  const tabItems: ITabContainerItem[] = useMemo(
    () => [
      {
        header: <FormattedMessage id="UsersAndAccess.tabs.organizationUsers" />,
        component: <OrganizationUsersTab />,
        shouldBeRendered: shouldBeRendered(
          UsersAndAccessTabsValues.OrganizationUsers
        ),
      },
      {
        header: <FormattedMessage id="UsersAndAccess.tabs.accessProfiles" />,
        component: <AccessProfilesTab />,
        shouldBeRendered: shouldBeRendered(
          UsersAndAccessTabsValues.AccessProfiles
        ),
      },
      {
        header: <FormattedMessage id="UsersAndAccess.tabs.accessRequests" />,
        component: <AccessRequestsTab />,
        shouldBeRendered: shouldBeRendered(
          UsersAndAccessTabsValues.AccessRequests
        ),
      },
    ],
    [shouldBeRendered]
  );

  return (
    <>
      <PageHeader breadcrumbs={breadcrumbs} content={<span></span>} />
      <div className="mt-4">
        <TabContainer items={tabItems} onTabChange={onTabChange} />
      </div>
    </>
  );
};

export default UsersAndAccess;
