import { FormikErrors, FormikTouched } from 'formik/dist/types';
import { FieldMetaProps, FormikProps } from 'formik';
import React, { createContext } from 'react';
import * as Yup from 'yup';

export interface IFormHookValues<T> {
  initialValues: T;
  handleSubmit: (values: T) => void;
  validation: (values: T) => FormikErrors<T>;
}

export interface IValidationSchemaBasedFormHookValues<T extends {}> {
  initialValues: T;
  handleSubmit: (values: T) => void;
  validationSchema: Yup.ObjectSchema<T, Yup.AnyObject>;
}

export interface IAsyncFormHookValues<T> {
  initialValues: T;
  handleSubmit: (values: T) => Promise<void>;
  validation: (values: T) => FormikErrors<T>;
}

export type FormHookProps<T> = {
  form: FormikProps<T>;
};

export type FormikValuesProps<T> = {
  values: T;
  setValues: (values: T) => void;
};

export type FormikUtilsContextValues<T> = {
  initialValues: T;
  isValid: boolean;
  isSubmitting: boolean;
  dirty: boolean;
  touched: FormikTouched<T>;
  setFieldTouched: (
    field: string,
    isTouched?: boolean | undefined,
    shouldValidate?: boolean | undefined
  ) => void;
  getFieldMeta: <Value>(name: string) => FieldMetaProps<Value>;
  setFieldError: (field: string, message: string | undefined) => void;
  setValues: (
    values: React.SetStateAction<T>,
    shouldValidate?: boolean | undefined
  ) => void;
};

export const createFormikUtilsContext = <T extends object>() => {
  return createContext<FormikUtilsContextValues<T> | undefined>(undefined);
};
