import {
  GridRenderCellParams,
  GridRenderEditCellParams,
} from '@mui/x-data-grid';
import React from 'react';
import { formatDate } from '../../../../../../../../../../../../helpers/dates';
import { ReportScheduleTableTypes } from '../../types';
import TextFieldCell from '../TextFieldCell';

const DateFromCell = (
  props: GridRenderCellParams<ReportScheduleTableTypes.ReportScheduleTableEntry>
) => {
  return <span>{formatDate(props.row.date_from, { ignoreTime: true })}</span>;
};

export const DateFromEditCell = (
  props: GridRenderEditCellParams<ReportScheduleTableTypes.ReportScheduleTableEntry>
) => {
  return <TextFieldCell {...props} type="date" />;
};

export default DateFromCell;
