import { getTimelineDataForSqueezeChart } from 'api/graspManagement';
import QueryKeys from 'api/queries/queryKeys';
import { IErrorWithCode } from 'interfaces/response';
import {
  Granularity,
  ISqueezeChartData,
  SqueezeQuantityGrouping,
} from 'interfaces/timeline';
import { DateRange } from 'react-day-picker';
import { useQuery } from 'react-query';
import { formatDate } from '../../../helpers/dates';
import { TimelineQueryParams } from 'interfaces/queryParams';
import { IQueryRequest } from 'interfaces/request';

type useTimelineSqueezeDataFetchParams = TimelineQueryParams & {
  granularity: Granularity | null;
  dateRangeObject: DateRange | null;
  groupBy?: SqueezeQuantityGrouping;
};

const useTimelineSqueezeDataFetch = ({
  params,
  options,
}: IQueryRequest<useTimelineSqueezeDataFetchParams>) => {
  const { enabled, ...queryOptions } = options ?? { enabled: true };
  const isEnabled = enabled
    ? !!params.granularity && !!params.dateRangeObject
    : false;

  return useQuery<ISqueezeChartData, IErrorWithCode>({
    ...queryOptions,
    enabled: isEnabled,
    queryFn: async () => {
      const { data: responseData } = await getTimelineDataForSqueezeChart(
        params.timelineId,
        {
          granularity: params.granularity as Granularity,
          from: params.dateRangeObject?.from?.toISOString(),
          to: params.dateRangeObject?.to?.toISOString(),
          groupBy: params.groupBy,
        }
      );

      return {
        ...responseData.data,
        series:
          responseData?.data?.series?.map((series) => ({
            ...series,
            data: series.data,
          })) ?? [],
      };
    },
    queryKey: [
      QueryKeys.getTimelineDataForSqueezeChart,
      params.timelineId,
      params.granularity,
      params.groupBy,
      prepareQueryKey(params.dateRangeObject, params.granularity),
    ],
  });
};

const prepareQueryKey = (
  dateRange: DateRange | null,
  granularity: Granularity | null
) => {
  let key: any = {
    from: undefined,
    to: undefined,
  };

  if (!dateRange) return null;

  if (granularity === Granularity.d1) {
    key.from = prepareFromDate(dateRange.from);
    key.to = prepareToDate(dateRange.to);
  }

  if (dateRange?.from) key.from = formatDate(dateRange.from.toISOString());
  if (dateRange?.to) key.to = formatDate(dateRange.to.toISOString());

  return key;
};

const prepareFromDate = (from: Date | undefined) => {
  if (!from) return from;
  from.setHours(0, 0, 0, 0);

  return from;
};

const prepareToDate = (to: Date | undefined) => {
  if (!to) return to;
  to.setHours(23, 59, 59, 59);

  return to;
};

export default useTimelineSqueezeDataFetch;
