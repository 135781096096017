import { textWrapEllipse } from 'helpers/utils/strings';
import { useInitQuery } from 'hooks/queries/useInitQuery';
import { ReactStateTuple } from 'interfaces/ui';
import { cloneDeep } from 'lodash';
import React from 'react';
import { FormGroup, Input, Label } from 'reactstrap';
import { useCommonData } from '../../../../../../../../hooks/useCommonData';

type ConversationTemplatesFilterBodyProps = {
  templateIdsState: ReactStateTuple<string[] | undefined>;
};

const ConversationTemplatesFilterBody = ({
  templateIdsState,
}: ConversationTemplatesFilterBodyProps) => {
  const [templateIds, setTemplateIds] = templateIdsState;

  const { conversationTemplatesQuery } = useCommonData();
  useInitQuery(conversationTemplatesQuery);

  return (
    <div
      className="d-flex flex-column gap-2 pe-1 ps-1"
      style={{ maxHeight: 300, overflowY: 'scroll', overflowX: 'hidden' }}
    >
      {(conversationTemplatesQuery.data ?? []).map((template) => {
        const handleToggleTemplate = () => {
          let templatesCopy = cloneDeep(templateIds);

          if (!templatesCopy) templatesCopy = [];

          if (templatesCopy.includes(template.id)) {
            setTemplateIds(templatesCopy.filter((p) => p !== template.id));
            return;
          }

          templatesCopy.push(template.id);
          setTemplateIds(templatesCopy);
        };

        return (
          <FormGroup key={template.id} check>
            <Input
              id={`conversation-templates-filter-body-${template.id}`}
              type="checkbox"
              checked={(templateIds ?? []).includes(template.id)}
              onChange={handleToggleTemplate}
            />
            <Label
              for={`conversation-templates-filter-body-${template.id}`}
              check
            >
              {textWrapEllipse(template.display_code, 20)}
            </Label>
          </FormGroup>
        );
      })}
    </div>
  );
};

export default ConversationTemplatesFilterBody;
