import { IEducationMaterial } from '../../../../../interfaces/educationMaterials';
import { useMemo } from 'react';
import { MaterialModalMode } from '../types';

export const useModalMode = (material: IEducationMaterial | undefined) => {
  return useMemo(() => {
    return material
      ? MaterialModalMode.EditMaterial
      : MaterialModalMode.AddMaterial;
  }, [material]);
};
