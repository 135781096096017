import { styled } from '@mui/material/styles';
import { Tabs } from '@mui/material';

const StyledTabs = styled(Tabs)(({ theme }) => ({
  '&': {
    minWidth: '216px',
  },
  [`& .MuiTab-root`]: {
    background: theme.palette.background.paper,
    textTransform: 'none',
    alignItems: 'start',
    width: '200px',
    height: '48px',
    whiteSpace: 'nowrap',
    borderRadius: '5px',
  },
  [`& .Mui-selected`]: {
    minWidth: '216px',
  },
  [`& .MuiTabs-indicator`]: {
    width: '16px',
  },
  [`& .MuiTabs-flexContainerVertical`]: {
    gap: '16px',
  },
}));

export default StyledTabs;
