import React from 'react';
import { FormattedMessage } from 'react-intl';
import SVG from 'react-inlinesvg';
import verticalTrends from 'assets/images/trends_icon_vertical.svg';
import horizontalTrends from 'assets/images/trends_icon_horizontal.svg';
import { UncontrolledTooltip } from 'reactstrap';
import { useTranslations } from 'hooks/useTranslations';

const Tooltip = () => {
  const { attachPrefix, wrapWithIntl } = useTranslations(
    'MyOrganizations.trendVisualizations'
  );

  return (
    <UncontrolledTooltip
      className="TrendVisualizationsTab__tooltip-controller"
      placement="top"
      target="trendVisualizationsTitleTooltip"
    >
      <div className="TrendVisualizationsTab__tooltip-container">
        <span className="TrendVisualizationsTab__tooltip-text">
          <FormattedMessage id={attachPrefix('trendVisualizationsTooltip')} />
        </span>
        <SVG
          src={verticalTrends}
          title={wrapWithIntl(attachPrefix('trendVisualizationsTooltip'))}
        />
        <SVG
          src={horizontalTrends}
          title={wrapWithIntl(attachPrefix('trendVisualizationsTooltip'))}
        />
      </div>
    </UncontrolledTooltip>
  );
};

export default Tooltip;
