import { IMutationRequest } from '../../../interfaces/request';
import { useMutationWithResultNotification } from '../useMutationWithResultNotification';
import { IUpdateAwareCase } from '../../../interfaces/caseHistory';
import { updateAwareCase } from '../../cases';
import {
  displayErrorArrayNotifications,
  displaySuccessNotification,
} from '../../../modules/Notifications';
import { IErrorDefinition } from '../../../interfaces/response';

type UpdateAwareCaseParams = {
  organizationId: string;
  caseId: string;
};

const useUpdateAwareCase = ({
  params,
  options,
  silent,
}: IMutationRequest<UpdateAwareCaseParams>) => {
  return useMutationWithResultNotification({
    asyncApiFunction: async (body: IUpdateAwareCase) => {
      await updateAwareCase(params.organizationId, params.caseId, body);
    },
    onSuccess: () => {
      if (!silent)
        displaySuccessNotification(
          'Api.Mutations.AwareSessions.UpdateAwareSession.success'
        );
      options.successFb?.();
    },
    onErrorArray: (errors: IErrorDefinition[]) => {
      displayErrorArrayNotifications(
        errors,
        'Api.Mutations.AwareSessions.UpdateAwareSession.failure'
      );
      options.errorFb?.();
    },
    resetOnResult: true,
  });
};

export default useUpdateAwareCase;
