import useModifyMemberRequest from 'api/mutations/requests/useModifyMemberRequest';
import { IMemberAccessRequest } from 'interfaces/personalities';
import React from 'react';
import RequestRow from './RequestRow';

type MemberAccessRequestRowProps = {
  organizationId: string;
  onSuccess: () => void;
  data: IMemberAccessRequest;
};

const MemberAccessRequestRow = ({
  onSuccess,
  organizationId,
  data,
}: MemberAccessRequestRowProps) => {
  const { mutate, isLoading } = useModifyMemberRequest(
    organizationId,
    onSuccess
  );

  const childCases = data.child_requests.length;
  const casesNames = data.child_requests.map((el) => <p>{el.title}</p>);

  return (
    <RequestRow
      isLoading={isLoading}
      mutate={mutate}
      data={data}
      casesNames={casesNames}
      numberOfRequestedCases={childCases}
    />
  );
};

export default MemberAccessRequestRow;
