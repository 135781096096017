import { useCallback } from 'react';
import { ICaseGroupSchedule } from '../../../../../../../interfaces/caseGroups';
import { addDays } from 'date-fns';
import { ISummarizedTreatmentPhase } from '../../../../../../../interfaces/conversations';
import { strToDateOnly } from '../../../../../../../helpers/dates';

export const useGenerateGroupSchedule = () => {
  return useCallback(
    (startDate: Date, treatmentPhases: ISummarizedTreatmentPhase[]) => {
      let groupSchedule: ICaseGroupSchedule[] = [];

      treatmentPhases.forEach((phase, idx) => {
        const from = groupSchedule.at(idx - 1)
          ? addDays(new Date(groupSchedule[idx - 1].date_to), 1).toISOString()
          : startDate.toISOString();

        groupSchedule.push({
          phase_id: phase.id,
          date_from: strToDateOnly(from),
          date_to: strToDateOnly(
            addDays(new Date(from), phase.days_duration - 1).toISOString()
          ),
        });
      });

      return groupSchedule;
    },
    []
  );
};
