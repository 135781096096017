import useCaseCategories from 'api/queries/cases/useCaseCategories';
import FilterDisplay from 'components/Filters/FilterDisplay';

//components
import FilterInput from 'components/Filters/FilterInput';

//helpers
import { caseStatusOptions } from 'helpers/utils/translationObject';

//helpers
import useOpen from 'hooks/useOpen';

//types
import { CasesParticipantFeedbackStatus, ICaseFilters } from 'interfaces/cases';
import { cloneDeep } from 'lodash';
import CategorySelect, {
  CategoryIdentifier,
} from 'modules/Cases/pages/CaseDetailsPage/views/GenericCaseDetailsView/components/CaseDetailsHeader/components/CategorySelect';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormGroup, Input, Label } from 'reactstrap';
import { NIL } from 'uuid';
import useCaseGroups from '../../../../../../../../../api/queries/caseGroups/useCaseGroups';
import EditableInput from '../../../../../../../../../components/EditableInput/EditableInput';
import SubCategorySelect from '../../../../../../CaseDetailsPage/views/GenericCaseDetailsView/components/CaseDetailsHeader/components/SubCategorySelect';
import { useCaseFilterString } from '../hooks/useCaseFilterString';
import CaseFeedbackStatusesDropdown from './CaseFeedbackStatusesDropdown';

type CaseFilterProps = {
  organizationId: string;
  filters: ICaseFilters | null;
  setFilters: (value: ICaseFilters | null) => void;
};

const GenericCasesFilter = ({
  organizationId,
  filters,
  setFilters,
}: CaseFilterProps) => {
  const intl = useIntl();
  const { isOpen, toggle } = useOpen(false);

  //case groups
  const [caseGroupFilter, setCaseGroupFilter] = useState<string>('');
  const { data: caseGroupsData } = useCaseGroups({
    params: { organizationId },
  });

  const caseGroups = useMemo(() => {
    if (!caseGroupsData) return [];

    let groupsData = cloneDeep(caseGroupsData);
    groupsData = groupsData.filter((p) => p.id !== NIL);
    groupsData.sort((a, b) => a.sequence - b.sequence);
    return groupsData;
  }, [caseGroupsData]);

  const caseGroupsOptions = useMemo(() => {
    let options = [
      { value: '', text: '' },
      {
        value: 'unassigned',
        text: intl.formatMessage({
          id: 'Cases.CasesList.CasesFilter.unassigned',
        }),
      },
    ];

    caseGroups.forEach((group) => {
      options.push({
        value: group.id,
        text: intl.formatMessage(
          {
            id: 'Cases.CasesList.CasesFilter.groupNo',
          },
          { number: group.sequence }
        ),
      });
    });

    return options;
  }, [caseGroups, intl]);

  const onCaseGroupChange = (_: string, value: string) => {
    setCaseGroupFilter(value);
  };

  //status filters
  const [statusFilters, setStatusFilters] = useState<string[]>([]);

  const toggleFilter = (filterName: string) => {
    setStatusFilters((currentLocalFilters) => {
      if (currentLocalFilters.includes(filterName))
        return currentLocalFilters.filter((val) => val !== filterName);
      else return [...currentLocalFilters, filterName];
    });
  };

  const onChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
    toggleFilter(e.target.value);
  };

  const renderedStatusOptions = caseStatusOptions.map(
    ({ translationId, value }) => (
      <FormGroup check key={value}>
        <Input
          value={value}
          checked={statusFilters.includes(value)}
          onChange={onChangeStatus}
          id={value}
          type="checkbox"
        />{' '}
        <Label check>{intl.formatMessage({ id: translationId })}</Label>
      </FormGroup>
    )
  );

  const calculateValuesBaseOnPropFilters = useCallback(() => {
    if (filters) {
      filters.statuses &&
        filters.statuses?.length > 0 &&
        setStatusFilters(filters.statuses);

      if (filters.caseGroupId) setCaseGroupFilter(filters.caseGroupId);
    }
  }, [filters]);

  useEffect(() => {
    if (isOpen) {
      calculateValuesBaseOnPropFilters();
    }
  }, [isOpen, calculateValuesBaseOnPropFilters]);

  //category
  const { data } = useCaseCategories({ params: { organizationId } });

  const [categoryId, setCategoryId] = useState('');
  const [subCategoryId, setSubCategoryId] = useState('');

  const onCategoryChange = (e) => {
    setCategoryId(e.target.value);
    setSubCategoryId('');
  };

  const onSubCategoryChange = (e) => {
    setSubCategoryId(e.target.value);
  };

  //upload
  const [synchronizationStatusFilter, setSynchronizationStatusFilter] =
    useState<CasesParticipantFeedbackStatus | ''>('');
  const onSynchronizationStatusFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSynchronizationStatusFilter(
      e.target.value as CasesParticipantFeedbackStatus
    );
  };

  //feedback
  const [feedbackStatusFilter, setFeedbackStatusFilter] = useState<
    CasesParticipantFeedbackStatus | ''
  >('');
  const onFeedbackStatusFilterChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFeedbackStatusFilter(e.target.value as CasesParticipantFeedbackStatus);
  };

  //render

  const resetFilters = () => {
    setStatusFilters([]);
    setCategoryId('');
    setSubCategoryId('');
    setCaseGroupFilter('');
    setSynchronizationStatusFilter('');
    setFeedbackStatusFilter('');
  };

  const onAcceptClick = () => {
    setFilters({
      statuses: statusFilters,
      categoryId: categoryId !== '' ? categoryId : undefined,
      subCategoryId: subCategoryId !== '' ? subCategoryId : undefined,
      caseGroupId: caseGroupFilter !== '' ? caseGroupFilter : undefined,
      feedbackStatus:
        feedbackStatusFilter !== '' ? feedbackStatusFilter : undefined,
      synchronizationStatus:
        synchronizationStatusFilter !== ''
          ? synchronizationStatusFilter
          : undefined,
    });
    toggle();
  };

  const { filterLength, filterString } = useCaseFilterString({
    categoriesData: data,
    groupsData: caseGroupsData,
    filters: filters,
  });

  const filterBody = (
    <>
      <Label for="exampleCheckbox" className="FilterInput__group-header">
        <FormattedMessage id="General.status" />
      </Label>
      <div>{renderedStatusOptions}</div>
      <FormGroup>
        <Label className="mt-2 FilterInput__group-header">
          <FormattedMessage id="CaseCard.category" />
        </Label>
        <CategorySelect
          organizationId={organizationId}
          isDefaultInput
          value={categoryId}
          onChange={onCategoryChange}
          id="filter_category"
          name="filter_category"
          emptyValueFirst
          identifier={CategoryIdentifier.CategoryId}
        />
      </FormGroup>
      <FormGroup>
        <Label className="FilterInput__group-header">
          <FormattedMessage id="CaseCard.subCategory" />
        </Label>
        <SubCategorySelect
          organizationId={organizationId}
          categoryId={categoryId}
          isDefaultInput
          value={subCategoryId}
          onChange={onSubCategoryChange}
          name="sub-category_filter"
          identifier={CategoryIdentifier.CategoryId}
        />
      </FormGroup>
      <FormGroup>
        <Label className="FilterInput__group-header">
          <FormattedMessage id="OrganizationCases.synchronizationStatus" />
        </Label>
        <CaseFeedbackStatusesDropdown
          onChange={onSynchronizationStatusFilterChange}
          value={synchronizationStatusFilter}
          name="upload_status_filter"
        />
      </FormGroup>
      <FormGroup>
        <Label className="FilterInput__group-header">
          <FormattedMessage id="OrganizationCases.feedbackStatus" />
        </Label>
        <CaseFeedbackStatusesDropdown
          onChange={onFeedbackStatusFilterChange}
          value={feedbackStatusFilter}
          name="upload_status_filter"
        />
      </FormGroup>
      <FormGroup>
        <Label className="mt-2 FilterInput__group-header">
          <FormattedMessage id="Cases.CasesList.CasesFilter.caseGroup" />
        </Label>
        <EditableInput
          type="select"
          options={caseGroupsOptions}
          name="group_id"
          value={caseGroupFilter}
          onEdit={onCaseGroupChange}
          debounceEnabled={false}
        />
      </FormGroup>
    </>
  );

  return (
    <FilterInput
      isOpen={isOpen}
      toggle={toggle}
      targetId="cases-filter-input-target"
      resetFilters={resetFilters}
      filterBody={filterBody}
      displayComponent={
        <FilterDisplay
          targetId="cases-filter-input-target"
          filterString={filterString}
          filtersLength={filterLength}
        />
      }
      titleTranslationId="Projects.filter.filters"
      onAcceptClick={onAcceptClick}
    />
  );
};

export default GenericCasesFilter;
