import React from 'react';
import { useHandleAccept } from './hooks/useHandleAccept';
import DropFile from '../../../../../../../../../../components/DropFile';
import { DEFAULT_DROP_FILE_OPTIONS } from '../../../../../../../../../../components/DropFile/types';

const UploadStep = () => {
  const handleAccept = useHandleAccept();

  return (
    <DropFile
      options={{
        ...DEFAULT_DROP_FILE_OPTIONS,
        accept: { 'application/json': ['.json'] },
      }}
      onAccept={handleAccept}
    />
  );
};

export default UploadStep;
