//helpers
import useOrganizationContextDeleteFormAssignment from 'api/mutations/assessmentForms/assignments/useOrganizationContextDeleteFormAssignment';
import useOrganizationContextUpdateFormAssignment from 'api/mutations/assessmentForms/assignments/useOrganizationContextUpdateFormAssignment';
import calendarIcon from 'assets/images/calendar_icon.svg';
import deleteIcon from 'assets/images/delete_icon.svg';

//icons
import assessmentFormTemplate from 'assets/images/timeline_details/assessment_form_template_icon.svg';
import classNames from 'classnames';

//helpers
import { formatDate } from 'helpers/dates';
import useOpen from 'hooks/useOpen';

//types
import {
  IAssessmentFormAssignment,
  IAssessmentTemplate,
} from 'interfaces/assessmentForms';

//components
import { useEffect, useMemo, useState } from 'react';
import SVG from 'react-inlinesvg';
import { FormattedMessage } from 'react-intl';
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from 'reactstrap';
import FormsAssignmentsModal from '../modals/FormsAssignmentsModal';

const FormsAssignmentsRowDefaultProps = {
  showFieldsDescription: false,
};

type FormsAssignmentsRowProps = {
  assignment?: IAssessmentFormAssignment;
  templates: IAssessmentTemplate[];
  showFieldsDescription: boolean;
  readOnly: boolean;
  setAddAssignmentMode: (mode: boolean) => void;
  userId: string;
  caseId: string;
  organizationId: string;
  timelineId: string;
  refetch: () => void;
} & typeof FormsAssignmentsRowDefaultProps;

const formTitlePlaceholder = 'CaseCard.FormsAssignments.fillTheFormName';

const FormsAssignmentsRow = ({
  assignment,
  templates,
  showFieldsDescription,
  setAddAssignmentMode,
  userId,
  caseId,
  organizationId,
  timelineId,
  readOnly,
  refetch,
}: FormsAssignmentsRowProps) => {
  const { isOpen, toggle: toggleModal } = useOpen();

  const [selectedAssignmentTitle, setSelectedAssignmentTitle] =
    useState(formTitlePlaceholder);

  useEffect(() => {
    if (assignment) {
      const formTitle = templates?.find(
        (p) => p.code === assignment.formCode
      )?.title;

      if (formTitle) {
        setSelectedAssignmentTitle(formTitle);
      }
    }
  }, [assignment, templates]);

  const getScheduleTextByInfo = () => {
    const info = assignment?.scheduleInfo;

    if (!info) {
      return (
        <FormattedMessage id="CaseCard.FormsAssignments.fillTheFormSchedule" />
      );
    } else {
      let labelText = '';

      if (info.daily) {
        if (info.daily.dayInterval === 1) {
          labelText += `Everyday at ${info.daily.hours.map((hour) => hour)} `;
        } else {
          labelText += `Every ${
            info.daily.dayInterval
          } days at ${info.daily.hours.map((hour) => hour)} `;
        }
      }

      if (info.weekly) {
        info.weekly.forEach((weekly) => {
          labelText += `${
            weekly.day.charAt(0).toUpperCase() + weekly.day.slice(1)
          } at ${weekly.hours.map((hour) => hour)} `;
        });
      }

      if (info.custom) {
        info.custom.forEach((custom) => {
          labelText += `${formatDate(custom.date.toString(), {
            ignoreTime: true,
            addSeconds: false,
          })} at ${custom.hours.map((hour) => hour)} `;
        });
      }

      return labelText;
    }
  };

  const refetchWithAddModeDisable = () => {
    setAddAssignmentMode(false);
    refetch();
  };

  const { mutate } = useOrganizationContextDeleteFormAssignment(
    {
      caseId: caseId,
    },
    {
      successFb: refetch,
    }
  );

  const { mutate: updateAssignment } =
    useOrganizationContextUpdateFormAssignment(
      {
        organizationId: organizationId,
        caseId: caseId,
        timelineId: timelineId,
        userId: userId,
        assignmentId: assignment?.id ?? '',
      },
      {
        successFb: refetch,
      }
    );

  const handleRemoveAssignments = () => {
    if (assignment === undefined) {
      setAddAssignmentMode(false);
    } else {
      mutate(assignment.id);
      setAddAssignmentMode(false);
    }
  };

  const handleUpdateAssignmentTitle = (title: string, formCode: string) => {
    if (assignment) {
      updateAssignment({
        dateTo: assignment.dateTo,
        dateFrom: assignment.dateFrom,
        formCode: formCode,
        scheduleInfo: assignment.scheduleInfo,
        isEditableByOrganizationMembers:
          assignment.isEditableByOrganizationMembers,
        activeHoursAfter: 1,
        activeHoursBefore: 1,
      });
    }

    setSelectedAssignmentTitle(title);
  };

  const getCodeForSelectedAssessmentTitle = () =>
    templates.find((p) => p.title === selectedAssignmentTitle)?.code;

  const renderedTitle = useMemo(() => {
    if (selectedAssignmentTitle === formTitlePlaceholder)
      return <FormattedMessage id={formTitlePlaceholder} />;
    else return selectedAssignmentTitle;
  }, [selectedAssignmentTitle]);

  return (
    <>
      {isOpen && (
        <FormsAssignmentsModal
          organizationId={organizationId}
          timelineId={timelineId}
          isOpen={isOpen}
          toggleModal={toggleModal}
          userId={userId}
          caseId={caseId}
          formCode={getCodeForSelectedAssessmentTitle()!}
          assignmentId={assignment?.id}
          scheduleInfo={assignment?.scheduleInfo}
          dateFrom={assignment ? assignment.dateFrom : null}
          dateTo={assignment ? assignment.dateTo : null}
          activeHoursAfter={
            assignment?.activeHoursAfter ? assignment.activeHoursAfter : null
          }
          activeHoursBefore={
            assignment?.activeHoursBefore ? assignment.activeHoursBefore : null
          }
          refetch={refetchWithAddModeDisable}
          isEditableByOrganizationMembersInit={
            assignment?.isEditableByOrganizationMembers
          }
        />
      )}
      <Row className="FormAssignments__assignment mb-2">
        <Col md={5}>
          <UncontrolledDropdown className="FormAssignments__assignment__title">
            <DropdownToggle
              tag="div"
              caret
              className={classNames({
                FormAssignments__assignment__label: true,
                'FormAssignments__assignment__label--disabled': readOnly,
              })}
              disabled={readOnly}
            >
              {showFieldsDescription && (
                <span className="FormAssignments__assignment__label__desc">
                  Assessment Form
                </span>
              )}
              <div className="FormAssignments__assignment__label__text">
                <img
                  src={assessmentFormTemplate}
                  alt={assignment ? assignment.formCode : ''}
                />
                {renderedTitle}
              </div>
            </DropdownToggle>
            <DropdownMenu>
              {templates?.map((template) => (
                <DropdownItem
                  key={`dropdown-item-${template.code}`}
                  onClick={() =>
                    handleUpdateAssignmentTitle(template.title, template.code)
                  }
                >
                  {template.title}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </Col>
        <Col md={6}>
          <UncontrolledDropdown
            className={`FormAssignments__assignment__schedule ${
              (!getCodeForSelectedAssessmentTitle() || readOnly) &&
              'FormAssignments__assignment__schedule--disabled'
            }`}
            disabled={!getCodeForSelectedAssessmentTitle() || readOnly}
          >
            <DropdownToggle
              tag="div"
              caret
              className="FormAssignments__assignment__label"
              onClick={toggleModal}
            >
              {showFieldsDescription && (
                <span className="FormAssignments__assignment__label__desc">
                  When patient supposed to fill the form?
                </span>
              )}
              <div className="FormAssignments__assignment__label__text">
                <img
                  src={calendarIcon}
                  alt={assignment ? assignment.formCode : ''}
                />
                <span className="FormAssignments__assignment__label__schedule-info">
                  {getScheduleTextByInfo()}
                </span>
              </div>
            </DropdownToggle>
          </UncontrolledDropdown>
        </Col>
        <Col md={1} className="ps-0 d-flex">
          <Button
            className="FormAssignments__assignment__remove"
            color="danger"
            onClick={handleRemoveAssignments}
            block
            disabled={readOnly}
          >
            <SVG src={deleteIcon} title="delete" />
          </Button>
        </Col>
      </Row>
    </>
  );
};

FormsAssignmentsRow.defaultProps = FormsAssignmentsRowDefaultProps;

export default FormsAssignmentsRow;
