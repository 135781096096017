import React, { useEffect } from 'react';

type IndeterminateCheckboxProps = {
  checked: boolean;
  indeterminate: boolean;
  onChange?: () => void;
};

const IndeterminateCheckbox = ({
  checked,
  indeterminate,
  onChange,
}: IndeterminateCheckboxProps) => {
  const checkboxRef = React.useRef<HTMLInputElement | null>(null);

  const handleChange = () => {
    if (!onChange) return;
    onChange();
  };

  useEffect(() => {
    if (checkboxRef && checkboxRef.current) {
      checkboxRef.current.checked = checked;
      checkboxRef.current.indeterminate = false;
    }
  }, [checked]);

  useEffect(() => {
    if (indeterminate && checkboxRef && checkboxRef.current) {
      checkboxRef.current.checked = false;
      checkboxRef.current.indeterminate = true;
    }
  }, [indeterminate]);

  return <input ref={checkboxRef} onChange={handleChange} type="checkbox" />;
};

export default IndeterminateCheckbox;
