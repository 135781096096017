import React, { useState } from 'react';
import PageHeader from '../../../components/PageHeader';
import { FormattedMessage } from 'react-intl';
import SharingRequestsAlert from './SharingRequestsAlert';
import { Col, Row } from 'reactstrap';
import DaySchedule from './DaySchedule';
import Status from './Status';
import GraspsInfo from '../../Grasps/components/GraspsInfo';
import { GraspInfoMode } from '../../../interfaces/grasps';
import MedicalRecords from './MedicalRecords';
import Activity from '../../../components/Activity';
import { getUserId } from '../../../helpers/utils/auth';
import useGetMasterTimeline from '../../../api/queries/timelines/useGetMasterTimeline';
import { DateRange } from 'react-day-picker';
import { getTodayDateRange } from '../../../helpers/dates';
import useUserActivities from '../../../api/queries/timelines/useUserActivities';

const UserDashboard = () => {
  const userId = getUserId();

  const { data: masterTimeline, isLoading: isLoadingId } = useGetMasterTimeline(
    { params: { userId } }
  );

  const [selectedDates, setSelectedDates] = useState<DateRange>(() =>
    getTodayDateRange()
  );
  const { data, isLoading } = useUserActivities({
    params: { userId, dateRangeObject: selectedDates },
  });

  const onDateChange = (dateRange: DateRange) => setSelectedDates(dateRange);

  return (
    <>
      <PageHeader
        breadcrumbs={[
          {
            key: 'summary',
            link: null,
            text: <FormattedMessage id="Summary.ScreenTitle" />,
          },
        ]}
      />
      <SharingRequestsAlert />
      <Row xs={1} lg={2} xl={3}>
        <Col className={'ps-1 ps-md-3 ps-lg-4 ps-xl-4 mt-3'}>
          <DaySchedule
            userId={userId}
            masterTimelineId={masterTimeline?.id}
            isLoading={isLoadingId}
          />
        </Col>
        <Col className={'ps-1 ps-md-3 ps-lg-4 ps-xl-4 mt-3 '}>
          <Status
            userId={userId}
            masterTimelineId={masterTimeline?.id}
            isLoading={isLoadingId}
          />
        </Col>
        <Col className={'mt-3'}>
          <GraspsInfo userId={userId} mode={GraspInfoMode.USER} />
        </Col>
      </Row>
      <MedicalRecords />
      <Activity
        isLoading={isLoading}
        activities={data}
        selectedDates={selectedDates}
        onDateChange={onDateChange}
      />
    </>
  );
};

export default UserDashboard;
