import { useState } from 'react';
import {
  AssetStatus,
  AssetType,
  IAsset,
} from '../../../../../../../../../interfaces/educationMaterials';
import { useCommonParams } from '../../../../../../../hooks/useCommonParams';
import { cloneDeep } from 'lodash';

const getEmptyAssetToAdd = (organizationId: string, type: AssetType) => {
  return {
    id: '',
    name: '',
    description: '',
    name_translations: [],
    description_translations: [],
    type: type,
    organization_id: organizationId,
    status: AssetStatus.ReadyToUploading,
    image_url: null,
    stream_url: null,
    thumbnail_url: null,
  };
};

export const useHandleAddAsset = () => {
  const { organizationId } = useCommonParams();

  const [assetToAdd, setAssetToAdd] = useState<IAsset | undefined>(undefined);

  const handleAddImageAsset = () => {
    setAssetToAdd(
      cloneDeep(getEmptyAssetToAdd(organizationId, AssetType.Image))
    );
  };

  const handleAddVideoAsset = () => {
    setAssetToAdd(
      cloneDeep(getEmptyAssetToAdd(organizationId, AssetType.Video))
    );
  };

  const handleAddAttachmentAsset = () => {
    setAssetToAdd(
      cloneDeep(getEmptyAssetToAdd(organizationId, AssetType.Attachment))
    );
  };

  const handleRemoveAsset = () => {
    setAssetToAdd(undefined);
  };

  return {
    handleAddImageAsset,
    handleAddVideoAsset,
    handleAddAttachmentAsset,
    assetToAdd,
    handleRemoveAsset,
  };
};
