import React from 'react';
import { FormattedMessage } from 'react-intl';

import { default as classNamesFunction } from 'classnames';

type RadioLikeCaptionProps = {
  translationId?: string;
  selected: boolean;
};

const RadioLikeCaption = ({
  translationId = undefined,
  selected,
}: RadioLikeCaptionProps) => {
  return (
    <div className="RadioLikeCaption">
      <div
        className={classNamesFunction({
          RadioLikeCaption__radio: true,
          'RadioLikeCaption__radio--selected': selected,
        })}
      >
        {selected && <div className="RadioLikeCaption__radio-selection"></div>}
      </div>
      <div
        className={classNamesFunction({
          RadioLikeCaption__text: true,
          'RadioLikeCaption__text--selected': selected,
        })}
      >
        {translationId && <FormattedMessage id={translationId} />}
      </div>
    </div>
  );
};

export default RadioLikeCaption;
