import { ChangeEvent, useCallback } from 'react';
import {
  AssetType,
  IAsset,
} from '../../../../../../../../../../../../../interfaces/educationMaterials';
import useUploadAssetThumbnail from '../../../../../../../../../../../../../api/mutations/assets/useUploadAssetThumbnail';
import { useCommonParams } from '../../../../../../../../../../../hooks/useCommonParams';
import { useCommonData } from '../../../../../../../../../../../hooks/useCommonData';
import { useAssetAcceptType } from '../../../../../hooks/useAssetAcceptType';

export const useThumbnailUpload = (
  assetId: string,
  onUpload?: (url: string) => void
) => {
  const { organizationId } = useCommonParams();
  const { educationMaterials } = useCommonData();

  const handleSuccess = async (asset: IAsset) => {
    await educationMaterials.refetch();
    onUpload?.(asset.thumbnail_url ?? '');
  };

  const { mutateAsync: uploadAssetThumbnail, isLoading: isUploading } =
    useUploadAssetThumbnail(organizationId, assetId, handleSuccess);

  const acceptType = useAssetAcceptType(AssetType.Image);

  const onThumbnailChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) return;
      const firstFile = e.target.files.item(0);
      if (firstFile == null) return;
      await uploadAssetThumbnail(e.target.files[0]);
    },
    [uploadAssetThumbnail]
  );

  return { isUploading, acceptType, onThumbnailChange };
};
