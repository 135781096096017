import React from 'react';
import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

type ConfirmModalProps = {
  className?: string;
  isOpen: boolean;
  message: string | JSX.Element;
  confirm: () => void;
  toggle: () => void;
};

const ConfirmModal = ({
  isOpen,
  message,
  confirm,
  toggle,
}: ConfirmModalProps) => {
  return (
    <Modal
      centered={true}
      isOpen={isOpen}
      toggle={toggle}
      className="ConfirmModal"
      size="lg"
    >
      <ModalBody>{message}</ModalBody>
      <ModalFooter>
        <Button
          className="ConfirmModal__cancel"
          outline
          color="primary"
          onClick={toggle}
        >
          <FormattedMessage id="General.cancel" />
        </Button>
        <Button
          className="ConfirmModal__confirm"
          color="primary"
          onClick={confirm}
        >
          <FormattedMessage id="UserDashboard.confirm" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmModal;
