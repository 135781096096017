import useUnlinkGrasp from 'api/mutations/grasps/useUnlinkGrasp';
import useUserGrasps from 'api/queries/grasps/useUserGrasps';
import battery from 'assets/images/grasp_details/battery.svg';
import dataSync from 'assets/images/grasp_details/datasync.svg';
import graspIcon from 'assets/images/grasp_details/grasp.svg';
import classNames from 'classnames';
import CarrouselController from 'components/CarrouselController';
import ConfirmModal from 'components/ConfirmModal';
import GraspLogo from 'components/GraspLogo';
import { formatDateMonth } from 'helpers/dates';
import { IGraspPossession } from 'interfaces/grasps';
import { Size } from 'interfaces/ui';
import { useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import EditMyGraspModal from './EditGraspModal';
import { MultipleGraspRowPlaceholders } from './GraspRowPlaceholder';

type UserGraspListProps = {
  userId: string;
};

const DEFAULT_GRASP_FIRMWARE = '1.0.0';

const UserGraspList = ({ userId }: UserGraspListProps) => {
  const intl = useIntl();

  const {
    data: graspData,
    refetch,
    isLoading: isGraspLoading,
  } = useUserGrasps({ params: { userId } });

  const [selectedGraspIndex, setSelectedGraspIndex] = useState(0);

  //edit
  const [editGraspData, setEditGraspData] = useState<IGraspPossession | null>(
    null
  );

  const toggleUnlinkModal = () => setUnlinkGraspData(null);

  const toggleEditModal = () => setEditGraspData(null);

  const isEditModalOpen = !!editGraspData;

  //unlink
  const [unlinkGraspData, setUnlinkGraspData] =
    useState<IGraspPossession | null>(null);

  const onSuccessfulUnlink = async () => {
    await refetch();
    setUnlinkGraspData(null);
  };

  const onErrorUnlink = () => {
    setUnlinkGraspData(null);
  };

  const { mutate: unlinkGraspMutation } = useUnlinkGrasp(
    userId,
    onSuccessfulUnlink,
    onErrorUnlink
  );
  const isUnlinkModalOpen = !!unlinkGraspData;

  const confirmUnlinkGrasp = () => {
    if (unlinkGraspData) unlinkGraspMutation(unlinkGraspData.grasp_id);
  };

  const renderedSelectedGrasp = useMemo(() => {
    const graspToRender = graspData ? graspData[selectedGraspIndex] : null;

    if (!graspToRender) return null;

    const isBatteryOk =
      graspToRender.grasp_battery_current_level >
      graspToRender.grasp_battery_warning_level;

    const isNotDefaultFirmware =
      graspToRender.grasp_firmware_version !== DEFAULT_GRASP_FIRMWARE;

    return (
      <div>
        <div className="w-100 mt-4 d-flex flex-wrap">
          <div className="GraspList__item__rotate">
            <GraspLogo
              color={graspToRender.grasp_hex_color}
              width={55}
              size={Size.BIG}
            />
          </div>
          <div className="ms-4">
            <div className="GraspList__item__name">
              {graspToRender.grasp_name}
            </div>
            <div className="GraspList__item__description">
              {graspToRender.grasp_organization_name ?? '-'}
            </div>
          </div>
        </div>
        <div
          className="d-flex flex-column mt-2 p-0 w-100"
          aria-label="grasp_list"
        >
          <div className="GraspList__item GraspList__item__small_gap">
            <div
              className={classNames('GraspList__icon', {
                'GraspList__icon--positive': isBatteryOk,
                'GraspList__icon--negative': !isBatteryOk,
              })}
            >
              <img src={battery} alt="battery_icon" />
            </div>
            <div>
              Battery: {graspToRender.grasp_battery_current_level} %
              <div className="GraspList__item__description">
                Last check{' '}
                {graspToRender.grasp_data_last_synchronization
                  ? formatDateMonth(
                      graspToRender.grasp_data_last_synchronization
                    )
                  : ''}
              </div>
            </div>
          </div>
          <div className="GraspList__item GraspList__item__small_gap">
            <div
              className={classNames('GraspList__icon', {
                'GraspList__icon--positive':
                  graspToRender.grasp_data_last_synchronization &&
                  !graspToRender.grasp_data_too_long_without_synchronization,
                'GraspList__icon--negative':
                  graspToRender.grasp_data_too_long_without_synchronization,
              })}
            >
              <img src={dataSync} alt="datasync_icon" />
            </div>
            <div>
              Data synchronization
              <div className="GraspList__item__description">
                {graspToRender.grasp_data_last_synchronization
                  ? formatDateMonth(
                      graspToRender.grasp_data_last_synchronization
                    )
                  : '-'}
              </div>
            </div>
          </div>
          <div className="GraspList__item GraspList__item__small_gap">
            <div
              className={classNames('GraspList__icon', {
                'GraspList__icon--positive': isNotDefaultFirmware,
                'GraspList__icon--negative': !isNotDefaultFirmware,
              })}
            >
              <img src={graspIcon} alt="grasp_icon" />
            </div>
            <div>
              <div>Firmware version</div>
              <div className="GraspList__item__description">
                {graspToRender.grasp_firmware_version}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }, [graspData, selectedGraspIndex]);

  if (isGraspLoading)
    return <MultipleGraspRowPlaceholders numberOfPlaceholders={5} />;

  return (
    <>
      <ConfirmModal
        isOpen={isUnlinkModalOpen}
        message={intl.formatMessage({
          id: 'OrganizationGrasps.areSureUnlinkGrasp',
        })}
        toggle={toggleUnlinkModal}
        confirm={confirmUnlinkGrasp}
      />
      <EditMyGraspModal
        refetchGrasps={refetch}
        isOpen={isEditModalOpen}
        graspData={editGraspData}
        toggle={toggleEditModal}
        userId={userId}
      />
      <div className="d-flex flex-column gap-3 h-100 justify-content-between">
        {renderedSelectedGrasp}
        {graspData && graspData?.length > 1 && (
          <div className="pb-4">
            <CarrouselController
              activeIndex={selectedGraspIndex}
              numberOfItems={graspData?.length ?? 0}
              onSelect={setSelectedGraspIndex}
              center
              rowDirection
            />
          </div>
        )}
      </div>
    </>
  );
};

export default UserGraspList;
