import { useState } from 'react';
import { useTimeValue } from './useTimeValue';
import { FormikProps } from 'formik';
import { AwareCaseDetailsFormValues } from '../../../../../forms/AwareCaseDetailsForm/types';

export const useTimeStart = (
  procedureIndex: number,
  form: FormikProps<AwareCaseDetailsFormValues>,
  onChanged: () => void
) => {
  const getTimeValue = useTimeValue();

  const [timeStart, setTimeStart] = useState(
    () =>
      getTimeValue(form.values.procedures.at(procedureIndex)?.timeStart) ?? ''
  );

  const handleStartChange = (event: any) => {
    setTimeStart(event.target.value);
    onChanged();
  };

  return { timeStart, handleStartChange };
};
