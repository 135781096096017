import React from 'react';
import {
  AssetType,
  IAsset,
  IEducationMaterial,
} from '../../../../../../interfaces/educationMaterials';
import { textWrapEllipse } from '../../../../../../helpers/utils/strings';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileExcel,
  faImage,
  faPencil,
  faTrash,
  faVideo,
} from '@fortawesome/pro-light-svg-icons';
import useOpen from '../../../../../../hooks/useOpen';
import AssetModal from '../../../AssetModal';
import AssetCulturesSelector from '../../../components/AssetCulturesSelector';
import { useRemoveAsset } from './hooks/useRemoveAsset';
import LoadingButton from '../../../../../../components/LoadingButton';

type AssetItemProps = {
  asset: IAsset;
  material: IEducationMaterial;
};

const AssetsItem = ({ asset, material }: AssetItemProps) => {
  const { isOpen, toggle } = useOpen();
  const { handleRemove, isRemoving } = useRemoveAsset(asset.id);

  return (
    <>
      {isOpen && (
        <AssetModal
          isOpen={isOpen}
          toggle={toggle}
          asset={asset}
          material={material}
        />
      )}
      <tr className="AssetsModal__item AssetsItem" key={asset.id}>
        <td>
          {asset.thumbnail_url ? (
            <img
              style={{ maxWidth: '100px' }}
              className="img-thumbnail"
              src={`${asset.thumbnail_url}?${Date.now()}`}
              alt={asset.name}
            />
          ) : (
            <>
              {asset.type === AssetType.Video && (
                <FontAwesomeIcon
                  className="text-primary opacity-25"
                  size="6x"
                  icon={faVideo}
                />
              )}
              {asset.type === AssetType.Image && (
                <FontAwesomeIcon
                  className="text-primary opacity-25"
                  size="6x"
                  icon={faImage}
                />
              )}
              {asset.type === AssetType.Attachment && (
                <FontAwesomeIcon
                  className="text-primary opacity-25"
                  size="6x"
                  icon={faFileExcel}
                />
              )}
            </>
          )}
        </td>
        <td className="AssetsItem__name">
          <span className="AssetsItem__property">
            {textWrapEllipse(asset.name, 50)}
          </span>
          <span>{asset.description}</span>
        </td>
        <td>
          <div className="AssetsItem__cultures">
            <AssetCulturesSelector asset={asset} material={material} />
          </div>
        </td>
        <td>
          <div className="AssetsItem__actions">
            <Button color="primary" outline={true} onClick={toggle}>
              <FontAwesomeIcon icon={faPencil} />
            </Button>
            <LoadingButton
              isLoading={isRemoving}
              color="primary"
              outline={true}
              onClick={handleRemove}
            >
              <FontAwesomeIcon icon={faTrash} />
            </LoadingButton>
          </div>
        </td>
      </tr>
    </>
  );
};

export default AssetsItem;
