import { differenceInCalendarDays } from 'date-fns';
import { getTodayDateRange } from 'helpers/dates';
import { returnNextRange } from 'helpers/utils/dates';
import usePrevious from 'hooks/usePrevious';
import React, { useEffect, useState } from 'react';
import { DateRange, DayPicker } from 'react-day-picker';
import { useIntl } from 'react-intl';
import { Input, Label } from 'reactstrap';

type CalendarProps = {
  onDateChange?: (dateRange: DateRange) => void;
  defaultValueForRange?: DateRange;
  rangeOnly?: boolean;
};

const Calendar = ({
  onDateChange,
  defaultValueForRange = {
    from: undefined,
    to: undefined,
  },
  rangeOnly = false,
}: CalendarProps) => {
  const intl = useIntl();

  const [range, setRange] = useState<DateRange>(defaultValueForRange);
  const previousRange = usePrevious<typeof range>(range);

  const [isRange, setIsRange] = useState(() => {
    if (rangeOnly) return true;

    return defaultValueForRange.from && defaultValueForRange.to
      ? differenceInCalendarDays(
          defaultValueForRange.to,
          defaultValueForRange.from
        ) > 0
      : false;
  });

  const onRangeCheckboxClick = (_: React.ChangeEvent<HTMLInputElement>) => {
    if (isRange) {
      setRange(getTodayDateRange());
    }
    setIsRange((value) => !value);
  };

  const onDayClick = (pickedDate: Date) => {
    if (isRange) {
      setRange(returnNextRange(pickedDate, range));
    } else
      setRange({
        from: pickedDate,
        to: pickedDate,
      });
  };

  useEffect(() => {
    if (
      previousRange &&
      previousRange.from === range.from &&
      previousRange.to === range.to
    )
      return;

    if (!range.from || !range.to || !onDateChange) return;
    if (range.from === range.to) {
      const fromDate = new Date(range.from);
      fromDate.setHours(0, 0, 0);

      const toDate = new Date(range.to);
      toDate.setHours(23, 59, 59);

      onDateChange({
        to: toDate,
        from: fromDate,
      });
    } else
      onDateChange({
        to: range.to,
        from: range.from,
      });
  }, [range, onDateChange, previousRange]);

  const { from, to } = range;

  const selectedDays: DateRange = {
    from: from,
    to: to,
  };

  return (
    <div>
      <DayPicker
        className="Selectable"
        selected={selectedDays}
        onDayClick={onDayClick}
      />
      <div
        style={{
          textAlign: 'center',
        }}
      >
        {!rangeOnly && (
          <>
            <Input
              type="checkbox"
              onChange={onRangeCheckboxClick}
              checked={isRange}
            />
            <Label>
              {isRange
                ? intl.formatMessage({ id: 'Calendar.unsetRange' })
                : intl.formatMessage({ id: 'Calendar.setRange' })}
            </Label>
          </>
        )}
      </div>
    </div>
  );
};

export default Calendar;
