import React, { useState } from 'react';
import useRequiredParams from '../../../../../../../../hooks/useRequiredParams';
import useInfiniteAwareSessions from '../../../../../../../../api/queries/awareSessions/useInfiniteAwareSessions';
import { FormattedMessage } from 'react-intl';
import InfiniteScroll from 'react-infinite-scroller';
import { Spinner } from 'reactstrap';
import CustomTable from '../../../../../../../../components/CustomTable';
import FullScreenSpinner from '../../../../../../../../components/Spinners/FullScreenSpinner';
import useUrlSearch from '../../../../../../../../hooks/useUrlSearch';
import { ConversationQuestion } from '../../../../../../../../interfaces/conversations';
import { useHeaderItems } from './hooks/useHeaderItems';
import AwareCasesRow from './components/AwareCasesRow';
import ScheduleSessionModal from '../../modals/ScheduleSessionModal';
import { OpenHookValues } from '../../../../../../../../hooks/useOpen';
import ConversationTemplateProvider from '../../providers/ConversationTemplateProvider';

type AwareCasesListProps = {
  searchValue?: string;
  questions: ConversationQuestion[];
  scheduleSession: OpenHookValues;
};

const AwareCasesList = ({
  searchValue,
  questions,
  scheduleSession,
}: AwareCasesListProps) => {
  const { organizationId } = useRequiredParams<{
    organizationId: string;
  }>(['organizationId']);

  const [selectedCaseId, setSelectedCaseId] = useState<string | null>(null);

  const query = useUrlSearch();
  const aggregated = query.get('aggregated');

  const { awareSessions, fetchNextPage, isLoading, hasNextPage, refetch } =
    useInfiniteAwareSessions({
      params: {
        organizationId,
        searchValue,
        pageSize: 100,
        aggregated: !!aggregated,
      },
    });

  const headerItems = useHeaderItems(awareSessions, questions);

  const bodyRows = (awareSessions ?? []).map((session) => {
    const handleSelect = () => {
      if (selectedCaseId && selectedCaseId === session.case_id) {
        setSelectedCaseId(null);
        return;
      }

      setSelectedCaseId(session.case_id);
    };

    const selected = session.case_id === selectedCaseId;

    return (
      <AwareCasesRow
        key={session.case_id}
        session={session}
        onSelect={handleSelect}
        selected={selected}
        refetch={refetch}
      />
    );
  });

  if (isLoading) return <FullScreenSpinner />;

  const hasMore = !isLoading && hasNextPage;

  const loadMore = () => fetchNextPage();

  return (
    <ConversationTemplateProvider>
      {(template) => (
        <>
          {scheduleSession.isOpen && (
            <ScheduleSessionModal
              isOpen={scheduleSession.isOpen}
              toggle={scheduleSession.toggle}
              refetch={refetch}
              template={template}
            />
          )}
          {awareSessions?.length === 0 ? (
            <FormattedMessage id="General.nothingToDisplay" />
          ) : (
            <InfiniteScroll
              loadMore={loadMore}
              hasMore={hasMore}
              loader={<Spinner key="spinner" />}
              threshold={100}
              initialLoad={false}
            >
              <CustomTable headerItems={headerItems} bodyRows={bodyRows} />
            </InfiniteScroll>
          )}
        </>
      )}
    </ConversationTemplateProvider>
  );
};

export default AwareCasesList;
