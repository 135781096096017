import React from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { BaseModalProps } from 'interfaces/ui';
import { TrendVisualizationTemplate } from 'interfaces/trendVisualizations';
import { useForm } from './hooks/useForm';
import FormHeading from './components/FormHeading';
import FormCharts from './components/FormCharts';
import { Formik, FormikProps } from 'formik';
import {
  TrendVisualizationModalFormContext,
  TrendVisualizationModalFormikContext,
} from './types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/pro-light-svg-icons';
import { FormattedMessage } from 'react-intl';
import { useTranslations } from 'hooks/useTranslations';
import { useInitQuery } from 'hooks/queries/useInitQuery';
import { useCommonData } from 'modules/Organizations/views/OrganizationDetailsView/hooks/useCommonData';

type TrendVisualizationModalProps = BaseModalProps & {
  template?: TrendVisualizationTemplate;
};

const TrendVisualizationModal = ({
  isOpen,
  toggle,
  template,
}: TrendVisualizationModalProps) => {
  const { groupedConversationQuestions, organizationObservationMeanings } =
    useCommonData();

  useInitQuery(organizationObservationMeanings);
  useInitQuery(groupedConversationQuestions);

  const { attachPrefix } = useTranslations(
    'Organizations.OrganizationDetails.TrendVisualizations.TrendVisualizationModal'
  );

  const formHandler = useForm(template, toggle);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="GenericModal BorderlessModal"
      size="xl"
    >
      <ModalHeader toggle={toggle}>
        <FontAwesomeIcon icon={faChartLine} />
        <FormattedMessage id={attachPrefix('addTemplate')} />
      </ModalHeader>
      <ModalBody>
        <Formik
          validate={formHandler.validation}
          validateOnMount={!template}
          initialValues={formHandler.initialValues}
          enableReinitialize={true}
          onSubmit={formHandler.handleSubmit}
        >
          {(props: FormikProps<any>) => (
            <TrendVisualizationModalFormContext.Provider value={formHandler}>
              <TrendVisualizationModalFormikContext.Provider value={props}>
                <FormHeading toggle={toggle} />
                <div className="mt-4">
                  <FormCharts />
                </div>
              </TrendVisualizationModalFormikContext.Provider>
            </TrendVisualizationModalFormContext.Provider>
          )}
        </Formik>
      </ModalBody>
    </Modal>
  );
};

export default TrendVisualizationModal;
