import React, { useMemo, useState } from 'react';
import { ICase } from '../../../../../../../../interfaces/cases';
import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from 'reactstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import useProjectSubmission from '../../../../../../../../api/queries/submissions/useProjectSubmission';
import useTreatmentPhases from '../../../../../../../../api/queries/conversations/useTreatmentPhases';
import EditableInput from '../../../../../../../../components/EditableInput/EditableInput';
import { InputOption } from '../../../../../../../../interfaces/ui';
import InputFieldWithoutImage from '../../../../../../../../components/InputFieldWithoutImage';
import usePushCasesToTreatmentPhase from '../../../../../../../../api/mutations/conversations/usePushCasesToTreatmentPhase';
import { useCaseGroupDetailsCommonData } from '../../../../hooks/useCaseGroupDetailsCommonData';
import SVG from 'react-inlinesvg';
import phaseIcon from 'assets/images/phase.svg';

type MoveToTreatmentPhaseModalProps = {
  isOpen: boolean;
  toggle: () => void;
  selectedCases: ICase[];
  setSelectedCases: (cases: ICase[]) => void;
  organizationId: string;
  refetch: () => void;
};

const MoveToTreatmentPhaseModal = ({
  isOpen,
  toggle,
  selectedCases,
  setSelectedCases,
  refetch,
  organizationId,
}: MoveToTreatmentPhaseModalProps) => {
  const intl = useIntl();

  const [selectedTreatmentPhase, setSelectedTreatmentPhase] =
    useState<string>('');

  const handleSuccess = () => {
    toggle();
    setSelectedCases([]);
    refetch();
  };

  const { caseCategories } = useCaseGroupDetailsCommonData();

  const { mutateAsync } = usePushCasesToTreatmentPhase(
    organizationId,
    selectedCases[0]?.category_id ?? '',
    handleSuccess
  );

  const casesSubmissions = selectedCases
    .filter((p) => p.project_submission_id !== null)
    .map((p) => p.project_submission_id) as string[];

  const { data: submissionData, isFetched: submissionDataFetched } =
    useProjectSubmission({
      params: {
        organizationId,
        submissionId: casesSubmissions[0] ?? '',
      },
      options: {
        enabled: !!casesSubmissions[0],
        staleTime: Infinity,
      },
    });

  const { data: treatmentPhasesData } = useTreatmentPhases({
    params: {
      organizationId,
      treatmentPlanId: submissionData?.project?.case_category_id ?? '',
    },
    options: {
      enabled:
        submissionData?.project?.case_category_id !== '' &&
        submissionDataFetched,
      staleTime: Infinity,
    },
  });

  const handleMoveToConversationPhase = async () => {
    if (selectedTreatmentPhase === '') return;

    await mutateAsync({
      caseSubCategoryId: selectedTreatmentPhase,
      cases: selectedCases.map((p) => p.id),
    });
  };

  const conversationPhasesOptions: InputOption[] = useMemo(() => {
    if (!caseCategories.data || !treatmentPhasesData) return [];

    let phasesOptions: InputOption[] = [];

    treatmentPhasesData.forEach((phase) => {
      const category = caseCategories.data.find(
        (p) => p.id === phase.treatment_plan_id
      );
      const subCategory = category?.sub_categories.find(
        (p) => p.id === phase.id
      );

      phasesOptions.push({
        value: phase.id,
        text: subCategory?.name ?? '',
      });
    });

    return phasesOptions;
  }, [caseCategories.data, treatmentPhasesData]);

  const handleSelectConversationPhase = (target: string, value: string) => {
    setSelectedTreatmentPhase(value);
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} className="MoveCaseModal" size="lg">
      <ModalHeader className="MoveCaseModal__header" toggle={toggle}>
        <SVG src={phaseIcon} />
        <FormattedMessage id="Cases.CaseGroupDetails.moveToConversationPhase" />
      </ModalHeader>
      <ModalBody className="MoveCaseModal__body">
        <FormGroup>
          <InputFieldWithoutImage
            gray={true}
            disabled={true}
            labelTranslationId="Cases.CaseGroupDetails.selectedCases"
            inputId="cases"
          >
            <EditableInput
              className="px-2 py-2"
              type="text"
              value={`${selectedCases.length} selected cases`}
              name="cases"
              onEdit={() => {}}
              debounceEnabled={false}
            />
          </InputFieldWithoutImage>
        </FormGroup>
        <FormGroup>
          <label htmlFor="conversation-phase">
            <FormattedMessage id="Cases.CaseGroupDetails.selectedConversationPhase" />
          </label>
          <EditableInput
            type="select"
            value={selectedTreatmentPhase ?? ''}
            placeholder={intl.formatMessage({
              id: 'Cases.CaseGroupDetails.selectConversationPhase',
            })}
            displayEmpty={true}
            required={true}
            options={conversationPhasesOptions}
            name="conversation-phase"
            onEdit={handleSelectConversationPhase}
            debounceEnabled={false}
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter className="MoveCaseModal__footer">
        <Button color="primary" outline={true} onClick={toggle}>
          <FormattedMessage id="General.close" />
        </Button>
        <Button
          disabled={selectedTreatmentPhase === ''}
          color="primary"
          onClick={handleMoveToConversationPhase}
        >
          <FormattedMessage id="General.save" />
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MoveToTreatmentPhaseModal;
