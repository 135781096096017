import useTimelineShareRequestAccept from 'api/mutations/timelines/useTimelineShareRequestAccept';
import LoadingButton from 'components/LoadingButton';
import { ISharing } from 'interfaces/personalities';
import { ITimelineExtended } from 'interfaces/timeline';
import {
  displayErrorNotification,
  displaySuccessNotification,
} from 'modules/Notifications';
import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { QueryObserverResult, RefetchOptions } from 'react-query';
import { Modal, ModalBody, ModalFooter, ModalHeader, Table } from 'reactstrap';
import TimelinesRows from './TimelinesRows';

type ShareRequestModalProps = {
  request: ISharing | null;
  userId: string;
  timelines: ITimelineExtended[] | undefined;
  onClose: () => void;
  refetchFunction: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<ISharing[]>>;
};

const ShareRequestModal: React.FC<ShareRequestModalProps> = ({
  timelines,
  request,
  onClose,
  userId,
  refetchFunction,
}) => {
  const intl = useIntl();

  const successFb = async () => {
    displaySuccessNotification(
      `${intl.formatMessage({
        id: 'SharingRequests.approveActionFinishedSuccessfully',
      })}: ${request?.title}`
    );
    await refetchFunction();
    onClose();
  };

  const errorFb = () => {
    displayErrorNotification(
      `${intl.formatMessage({
        id: 'SharingRequests.approveActionError',
      })}: ${request?.title}`
    );
  };

  const { mutate, isLoading } = useTimelineShareRequestAccept({
    userId,
    shareRequestId: request?.id as string,
    successFb,
    errorFb,
  });

  const [timelinesChecked, setTimelinesChecked] = useState<string[]>([]);
  const onCheckboxChange = (id: string) => {
    setTimelinesChecked((timelinesCheckedState) => {
      let timelinesCheckedStateCopy = [...timelinesCheckedState];

      if (!timelinesCheckedStateCopy.includes(id))
        timelinesCheckedStateCopy.push(id);
      else {
        const indexOfId = timelinesCheckedStateCopy.findIndex(
          (timeline) => timeline === id
        );
        timelinesCheckedStateCopy.splice(indexOfId, 1);
      }

      return timelinesCheckedStateCopy;
    });
  };

  const onModalClose = () => setTimelinesChecked([]);
  const onConfirmClick = () => mutate(timelinesChecked);

  const isConfirmButtonDisabled = timelinesChecked.length === 0;
  const isOpen = !!request;

  return (
    <Modal isOpen={isOpen} toggle={onClose} onClosed={onModalClose}>
      <ModalHeader>
        <FormattedMessage id="SharingRequests.selectTimelines" />
      </ModalHeader>
      <ModalBody>
        <Table>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="SharingRequests.timelineName" />
              </th>
              <th>
                <FormattedMessage id="SharingRequests.shouldBeShared" />
              </th>
            </tr>
          </thead>
          <tbody>
            <TimelinesRows
              timelines={timelines}
              timelinesChecked={timelinesChecked}
              onTimelineChange={onCheckboxChange}
            />
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <LoadingButton
          color="success"
          disabled={isConfirmButtonDisabled}
          isLoading={isLoading}
          onClick={onConfirmClick}
        >
          <FormattedMessage id="SharingRequests.shareAccept" />
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
};

export default ShareRequestModal;
