import {
  CaseNotificationSchedule,
  ICreateNotificationSchedule,
  IUpdateNotificationSchedule,
} from '../interfaces/notificationSchedules';
import { IResponse } from '../interfaces/response';
import request from './utils/request';

export const getCaseNotificationSchedules = (
  userId: string,
  caseId: string,
  year: number,
  month: number,
  day: number
) =>
  request<void, IResponse<CaseNotificationSchedule[]>>({
    url: `/users/${userId}/cases/${caseId}/notifications/${year}/${month}/${day}`,
    method: 'GET',
  });

export const createNotificationSchedule = (
  userId: string,
  caseId: string,
  body: ICreateNotificationSchedule
) =>
  request<ICreateNotificationSchedule, void>({
    url: `/users/${userId}/cases/${caseId}/notifications`,
    method: 'POST',
    data: body,
  });

export const updateNotificationSchedule = (
  userId: string,
  caseId: string,
  scheduleId: string,
  body: IUpdateNotificationSchedule
) =>
  request<IUpdateNotificationSchedule, void>({
    url: `/users/${userId}/cases/${caseId}/notifications/${scheduleId}`,
    method: 'PUT',
    data: body,
  });
